import React from "react";
import InterestForm from "./interestForm";
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import COLORS from "./constants";

class Explainer extends React.Component {
  render() {
    return (
      <div
        css={css`
          display: ${this.props.explainerOpen ? "block" : "none"};
          padding: 77px 30px;
          font-family: "Nunito Sans", sans-serif;
          text-align: center;
        `}
      >
        <header
          css={css`
            text-align: center;
            max-width: 340px;
            margin: 0 auto;
          `}
        >
          <svg
            width="124"
            viewBox="0 0 130 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1.79492"
              y="1.49351"
              width="126.41"
              height="33.5065"
              fill={COLORS.secondary}
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M40.5625 26.4788C40.9961 26.46 41.3542 26.4034 41.637 26.3092C41.9386 26.2149 42.1648 25.9982 42.3156 25.6589C42.4852 25.3196 42.57 24.7729 42.57 24.0189V13.1896C42.57 12.6806 42.5795 12.1905 42.5983 11.7193C42.636 11.248 42.6643 10.8899 42.6831 10.6448C42.3815 10.6637 42.0045 10.6825 41.5521 10.7014C41.0997 10.7014 40.7699 10.7108 40.5625 10.7296V8.55245L48.7905 8.52417C50.6567 8.52417 52.2684 8.80692 53.6256 9.37242C55.0016 9.91908 56.1232 10.6637 56.9903 11.6062C57.8763 12.5487 58.536 13.6043 58.9696 14.773C59.4031 15.9228 59.6199 17.0821 59.6199 18.2508C59.6199 19.4006 59.4125 20.5693 58.9978 21.7569C58.602 22.9445 57.9705 24.0378 57.1034 25.0368C56.2363 26.017 55.1053 26.8181 53.7104 27.4402C52.3155 28.0434 50.6284 28.345 48.6492 28.345H40.5625V26.4788ZM46.6699 23.7079C46.6699 24.2168 46.6605 24.7069 46.6416 25.1782C46.6228 25.6494 46.5945 26.0076 46.5568 26.2526C46.7453 26.2338 46.9998 26.2338 47.3202 26.2526C47.6595 26.2526 48.0083 26.2621 48.3664 26.2809C48.7246 26.2809 49.0167 26.2809 49.2429 26.2809C50.2797 26.2809 51.1656 26.0736 51.9008 25.6589C52.6548 25.2442 53.2674 24.6881 53.7387 23.9906C54.2288 23.2743 54.5869 22.4732 54.8131 21.5873C55.0393 20.7013 55.1524 19.7871 55.1524 18.8446C55.1524 15.9605 54.5681 13.8493 53.3994 12.511C52.2495 11.1726 50.6944 10.4752 48.734 10.4186C48.2816 10.3998 47.8952 10.4469 47.5747 10.56C47.2731 10.6542 47.0469 10.871 46.8961 11.2103C46.7453 11.5496 46.6699 12.0868 46.6699 12.822V23.7079Z"
              fill="white"
            />
            <path
              d="M68.3845 10.4186C67.9698 10.4375 67.6116 10.494 67.31 10.5883C67.0084 10.6825 66.7728 10.8993 66.6032 11.2386C66.4524 11.559 66.377 12.1057 66.377 12.8785V20.6542C66.377 22.2187 66.5937 23.4346 67.0273 24.3017C67.4608 25.1499 68.0169 25.7437 68.6955 26.083C69.393 26.4223 70.1187 26.6108 70.8727 26.6485C72.4373 26.705 73.6531 26.2338 74.5202 25.2347C75.3873 24.2357 75.8208 22.69 75.8208 20.5976V13.1896C75.8208 12.6806 75.8303 12.1905 75.8491 11.7193C75.868 11.248 75.8962 10.8899 75.9339 10.6448C75.6323 10.6637 75.2459 10.6825 74.7747 10.7014C74.3223 10.7014 73.9924 10.7108 73.785 10.7296V8.55245H80.3448V10.4186C79.9301 10.4375 79.572 10.494 79.2704 10.5883C78.9688 10.6825 78.7332 10.8993 78.5635 11.2386C78.4127 11.559 78.3373 12.1057 78.3373 12.8785V19.9473C78.3373 22.1151 77.9886 23.8493 77.2911 25.1499C76.6125 26.4317 75.67 27.3648 74.4636 27.9491C73.2761 28.5146 71.9283 28.7974 70.4203 28.7974C68.8369 28.7974 67.5174 28.5712 66.4618 28.1188C65.4062 27.6664 64.5674 27.0349 63.9453 26.2244C63.3421 25.4138 62.9086 24.4807 62.6447 23.4251C62.3996 22.3695 62.2771 21.2385 62.2771 20.0321V13.1896C62.2771 12.6806 62.2865 12.1905 62.3054 11.7193C62.3431 11.248 62.3713 10.8899 62.3902 10.6448C62.0886 10.6637 61.7116 10.6825 61.2592 10.7014C60.8068 10.7014 60.4769 10.7108 60.2696 10.7296V8.55245H68.3845V10.4186Z"
              fill="white"
            />
            <path
              d="M96.6247 28.6277L87.5766 17.0632C87.3316 16.7428 87.0771 16.4035 86.8132 16.0453C86.5493 15.6872 86.2854 15.3196 86.0215 14.9426H85.9932L86.0498 23.7079C86.0498 24.2168 86.0404 24.7069 86.0215 25.1782C86.0027 25.6494 85.9744 26.0076 85.9367 26.2526C86.2383 26.2149 86.6153 26.1961 87.0677 26.1961C87.5389 26.1772 87.8688 26.1678 88.0573 26.1678V28.345H81.6106V26.4788C82.0441 26.46 82.4023 26.4034 82.685 26.3092C82.9866 26.2149 83.2128 25.9982 83.3636 25.6589C83.5333 25.3196 83.6181 24.7729 83.6181 24.0189L83.5616 13.1896C83.5616 12.6806 83.571 12.1905 83.5898 11.7193C83.6087 11.248 83.637 10.8899 83.6747 10.6448C83.3731 10.6637 82.9866 10.6825 82.5154 10.7014C82.063 10.7014 81.7331 10.7108 81.5258 10.7296V8.55245H85.965L94.6171 19.4666C95.0884 20.0698 95.4748 20.5882 95.7764 21.0218C96.0968 21.4553 96.3702 21.8323 96.5964 22.1528H96.6247V13.1896C96.6247 12.6806 96.6341 12.1905 96.6529 11.7193C96.6718 11.248 96.6906 10.8899 96.7095 10.6448C96.4079 10.6637 96.0309 10.6825 95.5785 10.7014C95.1261 10.7014 94.7962 10.7108 94.5888 10.7296V8.55245H101.036V10.4186C100.621 10.4375 100.263 10.494 99.9611 10.5883C99.6595 10.6825 99.4239 10.8993 99.2542 11.2386C99.1034 11.559 99.028 12.1057 99.028 12.8785V28.6277H96.6247Z"
              fill="white"
            />
            <path
              d="M100.861 28.345V26.394C101.388 26.394 101.813 26.2432 102.133 25.9416C102.453 25.64 102.717 25.2442 102.925 24.7541C103.151 24.264 103.368 23.7362 103.575 23.1707L109.089 8.0435H111.068L117.09 23.4534C117.204 23.7362 117.354 24.1697 117.543 24.7541C117.731 25.3196 117.844 25.8191 117.882 26.2526C118.203 26.2149 118.504 26.1961 118.787 26.1961C119.089 26.1772 119.334 26.1678 119.522 26.1678V28.345H111.916V26.4788C112.689 26.4788 113.123 26.2432 113.217 25.772C113.311 25.2819 113.226 24.6881 112.962 23.9906L112.397 22.4355L106.261 22.5486L105.809 23.8775C105.714 24.1226 105.573 24.4902 105.385 24.9803C105.215 25.4515 105.055 25.8474 104.904 26.1678C105.206 26.1301 105.564 26.1113 105.978 26.1113C106.412 26.0924 106.723 26.083 106.911 26.083V28.345H100.861ZM106.968 20.428H111.69L110.389 16.8936C110.144 16.215 109.927 15.5364 109.739 14.8578C109.55 14.1603 109.409 13.5477 109.315 13.0199H109.287C109.211 13.3404 109.098 13.821 108.947 14.4619C108.796 15.1028 108.561 15.8663 108.24 16.7522L106.968 20.428Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.8771 4.91708H5.21777V31.5764H31.8771V4.91708ZM17.3678 11.4607C17.3678 11.2633 17.478 11.1645 17.6985 11.1645C18.213 11.1645 19.2143 11.4877 20.7026 12.134C22.1908 12.7623 23.5872 13.4714 24.8917 14.2613L25.4154 13.4265C23.9271 10.7157 21.511 8.95638 18.1671 8.14852C17.3035 8.32804 16.6145 8.66016 16.1 9.14488C15.604 9.62959 15.3559 10.1951 15.3559 10.8414C15.3743 11.5236 15.6131 12.1968 16.0725 12.861C16.5502 13.5253 17.2576 14.3511 18.1946 15.3385C18.9663 16.1643 19.5267 16.7926 19.8758 17.2235L19.6829 17.412C19.1133 17.1427 18.3692 16.8644 17.4505 16.5772C15.9622 16.631 14.7588 17.1517 13.8401 18.139C12.9398 19.1085 12.4896 20.3562 12.4896 21.8821C12.4896 23.067 12.7561 24.1531 13.2889 25.1405C13.8401 26.1279 14.575 26.9088 15.4937 27.4833C16.4124 28.0578 17.4137 28.345 18.4978 28.345C19.5818 28.345 20.5648 28.0757 21.4467 27.5371C22.347 26.9806 23.0452 26.2356 23.5413 25.3021C24.0557 24.3506 24.313 23.3093 24.313 22.1783C24.313 21.0114 24.0374 19.8894 23.4862 18.8123C22.935 17.7351 22.2919 16.7926 21.557 15.9847C20.8404 15.1769 19.986 14.3062 18.9939 13.3727C18.4059 12.8161 17.9833 12.4032 17.7261 12.134C17.4872 11.8467 17.3678 11.6223 17.3678 11.4607ZM19.3246 24.8712C18.6631 25.3021 17.9741 25.5175 17.2576 25.5175C16.5961 25.5175 16.0817 25.32 15.7142 24.9251C15.3467 24.5121 15.163 23.9377 15.163 23.2016C15.163 22.4835 15.3743 21.8103 15.7969 21.182C16.2378 20.5357 16.8166 20.024 17.5332 19.647C18.2497 19.2521 19.0306 19.0456 19.8758 19.0277C20.3167 19.0277 20.7393 19.0995 21.1436 19.2431C21.4192 19.7996 21.557 20.401 21.557 21.0473C21.557 21.7834 21.3457 22.5015 20.9231 23.2016C20.5189 23.8838 19.986 24.4403 19.3246 24.8712Z"
              fill="white"
            />
          </svg>
          <hr
            css={css`
              margin: 33px auto;
              border-top-width: 0;
            `}
            color={COLORS.secondary}
            width="187px"
          />
          <div
            css={css`
              margin: 0 auto 33px;
              font-weight: 700;
              font-size: 26px;
              font-family: "Lora", serif;
            `}
          >
            Discover the beauty of the Biblical languages
          </div>

          <div
            css={css`
              font-size: 16px;
              margin: 0 auto;
              max-width: 190px;
              text-align: center;
            `}
          >
            A Bible with Text that changes as you learn.
          </div>

          <button
            css={css`
              margin: 33px auto;
              padding: 15px 33px;

              border: none;
              cursor: pointer;

              font-weight: 700;
              font-size: 14px;

              border-radius: 3px;

              background-color: ${COLORS.primary};
              color: white;
              &:hover {
                background-color: ${COLORS.highlight};
              }
            `}
            onClick={(e) => this.props.toggleExplainer(false)}
          >
            Preview Genesis
          </button>
        </header>

        <section
          css={css`
            line-height: 200%;
            margin: 0 auto 77px;
            max-width: 400px;
            text-align: left;
            font-size: 16px;
          `}
        >
          <hr
            css={css`
              margin: 0 auto 33px;
              border-top-width: 0;
            `}
            color={COLORS.secondary}
            width="187px"
          />
          <p>
            Duna gives Bible readers the ability to replace individual English
            words with original Hebrew, Aramaic, and Greek words. The result is
            a dynamic, powerful reading experience that grows with the reader’s
            understanding of the original languages - revealing design patterns,
            word play, and nuance that are many times lost in translation.
          </p>
          <p>More Text is being added soon. Get updates on progress:</p>
          <div
            css={css`
              text-align: center;
              margin-top: 40px;
            `}
          >
            <InterestForm />
          </div>
        </section>
      </div>
    );
  }
}

export default Explainer;
