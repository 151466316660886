import React from "react";
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import COLORS from "./constants";

class WordPro extends React.PureComponent {
  render() {
    let padding = "4px 3px 2px 3px";
    if (!!this.props.displayWord.match(/^[.,;:!?]/)) {
      padding = "4px 3px 5px 0px";
    }
    let highlightColor = COLORS.highlight;
    if (this.props.underline) {
      highlightColor = this.props.underlineColor;
    }
    return (
      <span
        css={css`
          position: relative;
        `}
      >
        <span
          onClick={(e) => {
            if (this.props.switchable) {
              e.stopPropagation();
              this.props.showPopup(e, this.props.index, !this.props.open);
            }
          }}
          css={css`
            display: inline-block;
            word-wrap: break-word;
            white-space: normal;
            line-height: 100%;
            padding: ${padding};
            margin-top: 7px;
            margin-bottom: 10px;
            margin-left: ${!!this.props.displayWord.match(/^[.,;:!?]/)
              ? "-3px"
              : "0"};
            ${this.props.switchable
              ? "cursor: pointer; &:hover { color: #fff; background-color: " +
                highlightColor +
                "; border-bottom: 3px solid " +
                highlightColor +
                ";};"
              : ""}
            ${this.props.open
              ? "color: #fff; background-color: " + COLORS.primary + ";"
              : ""}
            ${this.props.underline
              ? "border-bottom: 3px solid " + this.props.underlineColor + ";"
              : "border-bottom: 3px solid #fff"}
          `}
        >
          {this.props.displayWord}
        </span>
      </span>
    );
  }
}

export default WordPro;
