import React from "react";
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import COLORS from "./constants";

class InterestForm extends React.Component {
  constructor(props) {
    super();
    this.state = {
      email: null,
    };
  }

  handleSubmit(e) {
    if (!this.state.email) {
      e.preventDefault();
    }
  }

  render() {
    return (
      <form
        id="interest"
        name="interest-form"
        method="POST"
        onSubmit={(e) => this.handleSubmit(e)}
        css={css`
          display: inline-block;
        `}
      >
        <div
          css={css`
            margin: 0 auto;
            text-align: center;

            display: grid;
            grid-template-columns: auto auto;
            row-gap: 3px;
          `}
        >
          <input type="hidden" name="form-name" value="interest-form" />
          <input
            placeholder="Email"
            id="email-input"
            type="email"
            name="email"
            onChange={(e) => this.setState({ email: e.target.value })}
            css={css`
              grid-row: 1;
              grid-column: 1;
              padding: 12px 17px;

              display: inline-block;
              border: 1px solid #ccc;
              border-radius: 3px;
              box-sizing: border-box;
            `}
          />

          <button
            css={css`
              grid-row: 1;
              grid-column: 2;
              padding: 12px 10px;
              margin: 0 0 0 10px;

              border: none;
              cursor: pointer;

              font-weight: 700;
              font-size: 14px;

              border-radius: 3px;

              background-color: ${COLORS.secondary};
              color: white;
              &:hover {
                background-color: ${COLORS.highlight};
              }
            `}
            type="submit"
          >
            Get updates
          </button>
        </div>
      </form>
    );
  }
}

export default InterestForm;
