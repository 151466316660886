const Genesis = [
  [
    [
      ["In the beginning", "H7225"],
      ["God", "H430"],
      ["created", "H853 H1254", "TH8804"],
      ["the heaven", "H8064"],
      ["and", "H853"],
      ["the earth", "H776"],
      ["."],
    ],
    [
      ["And the earth", "H776"],
      ["was", "H1961", "TH8804"],
      ["without form", "H8414"],
      [","],
      ["and void", "H922"],
      [";"],
      ["and darkness", "H2822"],
      ["was", "added"],
      ["upon the face", "H6440"],
      ["of the deep", "H8415"],
      ["."],
      ["And the Spirit", "H7307"],
      ["of God", "H430"],
      ["moved", "H7363", "TH8764"],
      ["upon", "H5921"],
      ["the face", "H6440"],
      ["of the waters", "H4325"],
      ["."],
    ],
    "p",
    [
      ["And God", "H430"],
      ["said", "H559", "TH8799"],
      [","],
      ["Let there be", "H1961", "TH8799"],
      ["light", "H216"],
      [":"],
      ["and there was", "H1961", "TH8799"],
      ["light", "H216"],
      ["."],
    ],
    [
      ["And God", "H430"],
      ["saw", "H853 H7200", "TH8799"],
      ["the light", "H216"],
      [","],
      ["that", "H3588"],
      ["it was", "added"],
      ["good", "H2896"],
      [":"],
      ["and God", "H430"],
      ["divided", "H996 H914", "TH8686"],
      ["the light", "H216"],
      ["from", "H996"],
      ["the darkness", "H2822"],
      ["."],
    ],
    [
      ["And God", "H430"],
      ["called", "H7121", "TH8799"],
      ["the light", "H216"],
      ["Day", "H3117"],
      [","],
      ["and the darkness", "H2822"],
      ["he called", "H7121", "TH8804"],
      ["Night", "H3915"],
      ["."],
      ["And the evening", "H6153"],
      ["and the morning", "H1242"],
      ["were the first", "H259"],
      ["day", "H3117"],
      ["."],
    ],
    "p",
    [
      ["And God", "H430"],
      ["said", "H559", "TH8799"],
      [","],
      ["Let there be", "H1961", "TH8799"],
      ["a firmament", "H7549"],
      ["in the midst", "H8432"],
      ["of the waters", "H4325"],
      [","],
      ["and let it divide", "H914", "TH8688"],
      ["the waters", "H4325"],
      ["from the waters", "H4325"],
      ["."],
    ],
    [
      ["And God", "H430"],
      ["made", "H6213", "TH8799"],
      ["the firmament", "H7549"],
      [","],
      ["and divided", "H914", "TH8686"],
      ["the waters", "H4325"],
      ["which", "H834"],
      ["were", "added"],
      ["under", "H8478"],
      ["the firmament", "H7549"],
      ["from the waters", "H4325"],
      ["which", "H834"],
      ["were", "added"],
      ["above", "H5921"],
      ["the firmament", "H7549"],
      [":"],
      ["and it was so", "H3651"],
      ["."],
    ],
    [
      ["And God", "H430"],
      ["called", "H7121", "TH8799"],
      ["the firmament", "H7549"],
      ["Heaven", "H8064"],
      ["."],
      ["And the evening", "H6153"],
      ["and the morning", "H1242"],
      ["were the second", "H8145"],
      ["day", "H3117"],
      ["."],
    ],
    "p",
    [
      ["And God", "H430"],
      ["said", "H559", "TH8799"],
      [","],
      ["Let the waters", "H4325"],
      ["under the heaven", "H8064"],
      ["be gathered together", "H6960", "TH8735"],
      ["unto", "H413"],
      ["one", "H259"],
      ["place", "H4725"],
      [","],
      ["and let the dry", "H3004"],
      ["land", "added"],
      ["appear", "H7200", "TH8735"],
      [": and it was so."],
    ],
    [
      ["And God", "H430"],
      ["called", "H7121", "TH8799"],
      ["the dry", "H3004"],
      ["land", "added"],
      ["Earth", "H776"],
      [";"],
      ["and the gathering together", "H4723"],
      ["of the waters", "H4325"],
      ["called", "H7121", "TH8804"],
      ["he Seas", "H3220"],
      [":"],
      ["and God", "H430"],
      ["saw", "H7200", "TH8799"],
      ["that"],
      ["it was", "added"],
      ["good", "H2896"],
      ["."],
    ],
    "p",
    [
      ["And God", "H430"],
      ["said", "H559", "TH8799"],
      [","],
      ["Let the earth", "H776"],
      ["bring forth", "H1876", "TH8686"],
      ["grass", "H1877"],
      [","],
      ["the herb", "H6212"],
      ["yielding", "H2232", "TH8688"],
      ["seed", "H2233"],
      [","],
      ["and", "added"],
      ["the fruit", "H6529"],
      ["tree", "H6086"],
      ["yielding", "H6213", "TH8802"],
      ["fruit", "H6529"],
      ["after his kind", "H4327"],
      [","],
      ["whose", "H834"],
      ["seed", "H2233"],
      ["is", "added"],
      ["in itself, upon the earth", "H776"],
      [": and it was so."],
    ],
    [
      ["And the earth", "H776"],
      ["brought forth", "H3318", "TH8686"],
      ["grass", "H1877"],
      [","],
      ["and", "added"],
      ["herb", "H6212"],
      ["yielding", "H2232", "TH8688"],
      ["seed", "H2233"],
      ["after his kind", "H4327"],
      [","],
      ["and the tree", "H6086"],
      ["yielding", "H6213", "TH8802"],
      ["fruit", "H6529"],
      [","],
      ["whose seed", "H2233"],
      ["was", "added"],
      ["in itself, after his kind", "H4327"],
      [":"],
      ["and God", "H430"],
      ["saw", "H7200", "TH8799"],
      ["that"],
      ["it was", "added"],
      ["good", "H2896"],
      ["."],
    ],
    [
      ["And the evening", "H6153"],
      ["and the morning", "H1242"],
      ["were the third", "H7992"],
      ["day", "H3117"],
      ["."],
    ],
    "p",
    [
      ["And God", "H430"],
      ["said", "H559", "TH8799"],
      [","],
      ["Let there be lights", "H3974"],
      ["in the firmament", "H7549"],
      ["of the heaven", "H8064"],
      ["to divide", "H914", "TH8687"],
      ["the day", "H3117"],
      ["from the night", "H3915"],
      [";"],
      ["and let them be for signs", "H226"],
      [","],
      ["and for seasons", "H4150"],
      [","],
      ["and for days", "H3117"],
      [","],
      ["and years", "H8141"],
      [":"],
    ],
    [
      ["And let them be for lights", "H3974"],
      ["in the firmament", "H7549"],
      ["of the heaven", "H8064"],
      ["to give light", "H215", "TH8687"],
      ["upon the earth", "H776"],
      [": and it was so."],
    ],
    [
      ["And God", "H430"],
      ["made", "H6213", "TH8799"],
      ["two", "H8147"],
      ["great", "H1419"],
      ["lights", "H3974"],
      [";"],
      ["the greater", "H1419"],
      ["light", "H3974"],
      ["to rule", "H4475"],
      ["the day", "H3117"],
      [","],
      ["and the lesser", "H6996"],
      ["light", "H3974"],
      ["to rule", "H4475"],
      ["the night", "H3915"],
      [":"],
      ["he made", "added"],
      ["the stars", "H3556"],
      ["also."],
    ],
    [
      ["And God", "H430"],
      ["set", "H5414", "TH8799"],
      ["them in the firmament", "H7549"],
      ["of the heaven", "H8064"],
      ["to give light", "H215", "TH8687"],
      ["upon the earth", "H776"],
      [","],
    ],
    [
      ["And to rule", "H4910", "TH8800"],
      ["over the day", "H3117"],
      ["and over the night", "H3915"],
      [","],
      ["and to divide", "H914", "TH8687"],
      ["the light", "H216"],
      ["from the darkness", "H2822"],
      [":"],
      ["and God", "H430"],
      ["saw", "H7200", "TH8799"],
      ["that"],
      ["it was", "added"],
      ["good", "H2896"],
      ["."],
    ],
    [
      ["And the evening", "H6153"],
      ["and the morning", "H1242"],
      ["were the fourth", "H7243"],
      ["day", "H3117"],
      ["."],
    ],
    "p",
    [
      ["And God", "H430"],
      ["said", "H559", "TH8799"],
      [","],
      ["Let the waters", "H4325"],
      ["bring forth abundantly", "H8317", "TH8799"],
      ["the moving creature", "H8318"],
      ["that hath", "H5315"],
      ["life", "H2416"],
      [","],
      ["and fowl", "H5775"],
      ["that", "added"],
      ["may fly", "H5774", "TH8787"],
      ["above", "H5921"],
      ["the earth", "H776"],
      ["in the open", "H6440"],
      ["firmament", "H7549"],
      ["of heaven", "H8064"],
      ["."],
    ],
    [
      ["And God", "H430"],
      ["created", "H1254", "TH8799"],
      ["great", "H1419"],
      ["whales", "H8577"],
      [","],
      ["and every living", "H2416"],
      ["creature", "H5315"],
      ["that moveth", "H7430", "TH8802"],
      [","],
      ["which the waters", "H4325"],
      ["brought forth abundantly", "H8317", "TH8804"],
      [","],
      ["after their kind", "H4327"],
      [","],
      ["and every winged", "H3671"],
      ["fowl", "H5775"],
      ["after his kind", "H4327"],
      [":"],
      ["and God", "H430"],
      ["saw", "H7200", "TH8799"],
      ["that"],
      ["it was", "added"],
      ["good", "H2896"],
      ["."],
    ],
    [
      ["And God", "H430"],
      ["blessed", "H1288", "TH8762"],
      ["them, saying", "H559", "TH8800"],
      [","],
      ["Be fruitful", "H6509", "TH8798"],
      [","],
      ["and multiply", "H7235", "TH8798"],
      [","],
      ["and fill", "H4390", "TH8798"],
      ["the waters", "H4325"],
      ["in the seas", "H3220"],
      [","],
      ["and let fowl", "H5775"],
      ["multiply", "H7235", "TH8799"],
      ["in the earth", "H776"],
      ["."],
    ],
    [
      ["And the evening", "H6153"],
      ["and the morning", "H1242"],
      ["were the fifth", "H2549"],
      ["day", "H3117"],
      ["."],
    ],
    "p",
    [
      ["And God", "H430"],
      ["said", "H559", "TH8799"],
      [","],
      ["Let the earth", "H776"],
      ["bring forth", "H3318", "TH8686"],
      ["the living", "H2416"],
      ["creature", "H5315"],
      ["after his kind", "H4327"],
      [","],
      ["cattle", "H929"],
      [","],
      ["and creeping thing", "H7431"],
      [","],
      ["and beast", "H2416"],
      ["of the earth", "H776"],
      ["after his kind", "H4327"],
      [": and it was so."],
    ],
    [
      ["And God", "H430"],
      ["made", "H6213", "TH8799"],
      ["the beast", "H2416"],
      ["of the earth", "H776"],
      ["after his kind", "H4327"],
      [","],
      ["and cattle", "H929"],
      ["after their kind", "H4327"],
      [","],
      ["and every thing that creepeth", "H7431"],
      ["upon the earth", "H127"],
      ["after his kind", "H4327"],
      [":"],
      ["and God", "H430"],
      ["saw", "H7200", "TH8799"],
      ["that"],
      ["it was", "added"],
      ["good", "H2896"],
      ["."],
    ],
    "p",
    [
      ["And God", "H430"],
      ["said", "H559", "TH8799"],
      [","],
      ["Let us make", "H6213", "TH8799"],
      ["man", "H120"],
      ["in our image", "H6754"],
      [","],
      ["after our likeness", "H1823"],
      [":"],
      ["and let them have dominion", "H7287", "TH8799"],
      ["over the fish", "H1710"],
      ["of the sea", "H3220"],
      [","],
      ["and over the fowl", "H5775"],
      ["of the air", "H8064"],
      [","],
      ["and over the cattle", "H929"],
      [","],
      ["and over all the earth", "H776"],
      [","],
      ["and over every creeping thing", "H7431"],
      ["that creepeth", "H7430", "TH8802"],
      ["upon the earth", "H776"],
      ["."],
    ],
    "p",
    [
      ["So God", "H430"],
      ["created", "H1254", "TH8799"],
      ["man", "H120"],
      ["in his"],
      ["own", "added"],
      ["image", "H6754"],
      [","],
      ["in the image", "H6754"],
      ["of God", "H430"],
      ["created", "H1254", "TH8804"],
      ["he him; male", "H2145"],
      ["and female", "H5347"],
      ["created", "H1254", "TH8804"],
      ["he them."],
    ],
    "p",
    [
      ["And God", "H430"],
      ["blessed", "H1288", "TH8762"],
      ["them, and God", "H430"],
      ["said", "H559", "TH8799"],
      ["unto them, Be fruitful", "H6509", "TH8798"],
      [","],
      ["and multiply", "H7235", "TH8798"],
      [","],
      ["and replenish", "H4390", "TH8798"],
      ["the earth", "H776"],
      [","],
      ["and subdue it", "H3533", "TH8798"],
      [":"],
      ["and have dominion", "H7287", "TH8798"],
      ["over the fish", "H1710"],
      ["of the sea", "H3220"],
      [","],
      ["and over the fowl", "H5775"],
      ["of the air", "H8064"],
      [","],
      ["and over every living thing", "H2416"],
      ["that moveth", "H7430", "TH8802"],
      ["upon the earth", "H776"],
      ["."],
    ],
    [
      ["And God", "H430"],
      ["said", "H559", "TH8799"],
      [","],
      ["Behold", "H2009"],
      [","],
      ["I have given", "H5414", "TH8804"],
      ["you every herb", "H6212"],
      ["bearing", "H2232", "TH8802"],
      ["seed", "H2233"],
      [", which"],
      ["is", "added"],
      ["upon the face", "H6440"],
      ["of all the earth", "H776"],
      [","],
      ["and every tree", "H6086"],
      [", in the which"],
      ["is", "added"],
      ["the fruit", "H6529"],
      ["of a tree", "H6086"],
      ["yielding", "H2232", "TH8802"],
      ["seed", "H2233"],
      [";"],
      ["to you it shall be", "H1961", "TH8799"],
      ["for meat", "H402"],
      ["."],
    ],
    [
      ["And to every beast", "H2416"],
      ["of the earth", "H776"],
      [","],
      ["and to every fowl", "H5775"],
      ["of the air", "H8064"],
      [","],
      ["and to every thing that creepeth", "H7430", "TH8802"],
      ["upon the earth", "H776"],
      [", wherein"],
      ["there is", "added"],
      ["life", "H5315 H2416"],
      [","],
      ["I have given", "added"],
      ["every green", "H3418"],
      ["herb", "H6212"],
      ["for meat", "H402"],
      [": and it was so."],
    ],
    [
      ["And God", "H430"],
      ["saw", "H7200", "TH8799"],
      ["every thing that", "H834"],
      ["he had made", "H6213", "TH8804"],
      [", and, behold,"],
      ["it was", "added"],
      ["very", "H3966"],
      ["good", "H2896"],
      ["."],
      ["And the evening", "H6153"],
      ["and the morning", "H1242"],
      ["were the sixth", "H8345"],
      ["day", "H3117"],
      ["."],
    ],
    "p",
  ],
  [
    [
      ["Thus the heavens", "H8064"],
      ["and the earth", "H776"],
      ["were finished", "H3615", "TH8792"],
      [","],
      ["and all the host of them", "H6635"],
      ["."],
    ],
    [
      ["And on the seventh", "H7637"],
      ["day", "H3117"],
      ["God", "H430"],
      ["ended", "H3615", "TH8762"],
      ["his work", "H4399"],
      ["which he had made", "H6213", "TH8804"],
      [";"],
      ["and he rested", "H7673", "TH8799"],
      ["on the seventh", "H7637"],
      ["day", "H3117"],
      ["from all his work", "H4399"],
      ["which he had made", "H6213", "TH8804"],
      ["."],
    ],
    [
      ["And God", "H430"],
      ["blessed", "H1288", "TH8762"],
      ["the seventh", "H7637"],
      ["day", "H3117"],
      [","],
      ["and sanctified", "H6942", "TH8762"],
      ["it: because", "H3588"],
      ["that in it he had rested", "H7673", "TH8804"],
      ["from all his work", "H4399"],
      ["which God", "H430"],
      ["created", "H1254", "TH8804"],
      ["and made", "H6213", "TH8800"],
      ["."],
    ],
    "p",
    [
      ["These", "H428"],
      ["are", "added"],
      ["the generations", "H8435"],
      ["of the heavens", "H8064"],
      ["and of the earth", "H776"],
      ["when they were created", "H1254", "TH8736"],
      [","],
      ["in the day", "H3117"],
      ["that the", "H6213"],
      ["Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["made", "H6213", "TH8800"],
      ["the earth", "H776"],
      ["and the heavens", "H8064"],
      [","],
    ],
    "p",
    [
      ["And every plant", "H7880"],
      ["of the field", "H7704"],
      ["before", "H2962"],
      ["it was in the earth", "H776"],
      [","],
      ["and every herb", "H6212"],
      ["of the field", "H7704"],
      ["before", "H2962"],
      ["it grew", "H6779", "TH8799"],
      [":"],
      ["for", "H3588"],
      ["the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["had not", "H3808"],
      ["caused it to rain", "H4305", "TH8689"],
      ["upon the earth", "H776"],
      [", and"],
      ["there was", "added"],
      ["not", "H369"],
      ["a man", "H120"],
      ["to till", "H5647", "TH8800"],
      ["the ground", "H127"],
      ["."],
    ],
    [
      ["But there went up", "H5927", "TH8799"],
      ["a mist", "H108"],
      ["from", "H4480"],
      ["the earth", "H776"],
      [","],
      ["and watered", "H8248", "TH8689"],
      ["the whole face", "H6440"],
      ["of the ground", "H127"],
      ["."],
    ],
    [
      ["And", "H3335"],
      ["the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["formed", "H3335", "TH8799"],
      ["man", "H120"],
      ["of", "added"],
      ["the dust", "H6083"],
      ["of", "H4480"],
      ["the ground", "H127"],
      [","],
      ["and breathed", "H5301", "TH8799"],
      ["into his nostrils", "H639"],
      ["the breath", "H5397"],
      ["of life", "H2416"],
      [";"],
      ["and man", "H120"],
      ["became a living", "H2416"],
      ["soul", "H5315"],
      ["."],
    ],
    [
      ["And", "H5193"],
      ["the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["planted", "H5193", "TH8799"],
      ["a garden", "H1588"],
      ["eastward", "H6924"],
      ["in Eden", "H5731"],
      [";"],
      ["and there", "H8033"],
      ["he put", "H7760", "TH8799"],
      ["the man", "H120"],
      ["whom", "H834"],
      ["he had formed", "H3335", "TH8804"],
      ["."],
    ],
    [
      ["And out", "H4480"],
      ["of the ground", "H127"],
      ["made", "H6779", "TH8686"],
      ["the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["to grow", "H6779", "TH8686"],
      ["every tree", "H6086"],
      ["that is pleasant", "H2530", "TH8737"],
      ["to the sight", "H4758"],
      [","],
      ["and good", "H2896"],
      ["for food", "H3978"],
      [";"],
      ["the tree", "H6086"],
      ["of life", "H2416"],
      ["also in the midst", "H8432"],
      ["of the garden", "H1588"],
      [","],
      ["and the tree", "H6086"],
      ["of knowledge", "H1847"],
      ["of good", "H2896"],
      ["and evil", "H7451"],
      ["."],
    ],
    "p",
    [
      ["And a river", "H5104"],
      ["went out", "H3318", "TH8802"],
      ["of Eden", "H5731"],
      ["to water", "H8248", "TH8687"],
      ["the garden", "H1588"],
      [";"],
      ["and from thence it was parted", "H6504", "TH8735"],
      [","],
      ["and became into four", "H702"],
      ["heads", "H7218"],
      ["."],
    ],
    [
      ["The name", "H8034"],
      ["of the first", "H259"],
      ["is", "added"],
      ["Pison", "H6376"],
      [":"],
      ["that", "H1931"],
      ["is", "added"],
      ["it which compasseth", "H5437", "TH8802"],
      ["the whole land", "H776"],
      ["of Havilah", "H2341"],
      [","],
      ["where", "H834"],
      ["there is", "added"],
      ["gold", "H2091"],
      [";"],
    ],
    [
      ["And the gold", "H2091"],
      ["of that", "H1931"],
      ["land", "H776"],
      ["is", "added"],
      ["good", "H2896"],
      [": there"],
      ["is", "added"],
      ["bdellium", "H916"],
      ["and the onyx", "H7718"],
      ["stone", "H68"],
      ["."],
    ],
    [
      ["And the name", "H8034"],
      ["of the second", "H8145"],
      ["river", "H5104"],
      ["is", "added"],
      ["Gihon", "H1521"],
      [": the same"],
      ["is", "added"],
      ["it that compasseth", "H5437", "TH8802"],
      ["the whole land", "H776"],
      ["of Ethiopia", "H3568"],
      ["."],
    ],
    [
      ["And the name", "H8034"],
      ["of the third", "H7992"],
      ["river", "H5104"],
      ["is", "added"],
      ["Hiddekel", "H2313"],
      [": that"],
      ["is", "added"],
      ["it which goeth toward", "H1980", "TH8802"],
      ["the east", "H6926"],
      ["of Assyria", "H804"],
      ["."],
      ["And the fourth", "H7243"],
      ["river", "H5104"],
      ["is", "added"],
      ["Euphrates", "H6578"],
      ["."],
    ],
    "p",
    [
      ["And", "H3947"],
      ["the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["took", "H3947", "TH8799"],
      ["the man", "H120"],
      [","],
      ["and put him", "H3240", "TH8686"],
      ["into the garden", "H1588"],
      ["of Eden", "H5731"],
      ["to dress", "H5647", "TH8800"],
      ["it and to keep", "H8104", "TH8800"],
      ["it."],
    ],
    [
      ["And", "H6680"],
      ["the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["commanded", "H6680", "TH8762"],
      ["the man", "H120"],
      [","],
      ["saying", "H559", "TH8800"],
      [","],
      ["Of every tree", "H6086"],
      ["of the garden", "H1588"],
      ["thou mayest freely", "H398", "TH8800"],
      ["eat", "H398", "TH8799"],
      [":"],
    ],
    [
      ["But of the tree", "H6086"],
      ["of the knowledge", "H1847"],
      ["of good", "H2896"],
      ["and evil", "H7451"],
      [","],
      ["thou shalt not eat", "H398", "TH8799"],
      ["of it: for in the day", "H3117"],
      ["that thou eatest", "H398", "TH8800"],
      ["thereof thou shalt surely", "H4191", "TH8800"],
      ["die", "H4191", "TH8799"],
      ["."],
    ],
    "p",
    [
      ["And", "H559"],
      ["the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["said", "H559", "TH8799"],
      [","],
      ["It is", "added"],
      ["not good", "H2896"],
      ["that the man", "H120"],
      ["should be", "H1961", "TH8800"],
      ["alone; I will make", "H6213", "TH8799"],
      ["him an help meet", "H5828"],
      ["for him."],
    ],
    [
      ["And out of the ground", "H127"],
      ["the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["formed", "H3335", "TH8799"],
      ["every beast", "H2416"],
      ["of the field", "H7704"],
      [","],
      ["and every fowl", "H5775"],
      ["of the air", "H8064"],
      [";"],
      ["and brought", "H935", "TH8686"],
      ["them", "added"],
      ["unto Adam", "H120"],
      ["to see", "H7200", "TH8800"],
      ["what he would call", "H7121", "TH8799"],
      ["them: and whatsoever Adam", "H120"],
      ["called", "H7121", "TH8799"],
      ["every living", "H2416"],
      ["creature", "H5315"],
      [","],
      ["that", "H1931"],
      ["was", "added"],
      ["the name thereof", "H8034"],
      ["."],
    ],
    [
      ["And Adam", "H120"],
      ["gave", "H7121", "TH8799"],
      ["names", "H8034"],
      ["to all cattle", "H929"],
      [","],
      ["and to the fowl", "H5775"],
      ["of the air", "H8064"],
      [","],
      ["and to every beast", "H2416"],
      ["of the field", "H7704"],
      [";"],
      ["but for Adam", "H120"],
      ["there was not found", "H4672", "TH8804"],
      ["an help meet", "H5828"],
      ["for him."],
    ],
    [
      ["And the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["caused", "H5307", "TH8686"],
      ["a deep sleep", "H8639"],
      ["to fall", "H5307", "TH8686"],
      ["upon Adam", "H121"],
      [","],
      ["and he slept", "H3462", "TH8799"],
      [":"],
      ["and he took", "H3947", "TH8799"],
      ["one", "H259"],
      ["of his ribs", "H6763"],
      [","],
      ["and closed up", "H5462", "TH8799"],
      ["the flesh", "H1320"],
      ["instead thereof;"],
    ],
    [
      ["And the rib", "H6763"],
      [","],
      ["which the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["had taken", "H3947", "TH8804"],
      ["from man", "H120"],
      [","],
      ["made", "H1129", "TH8799"],
      ["he a woman", "H802"],
      [","],
      ["and brought", "H935", "TH8686"],
      ["her unto the man", "H120"],
      ["."],
    ],
    "p",
    [
      ["And Adam", "H120"],
      ["said", "H559", "TH8799"],
      [","],
      ["This", "H2063"],
      ["is", "added"],
      ["now", "H6471"],
      ["bone", "H6106"],
      ["of my bones", "H6106"],
      [","],
      ["and flesh", "H1320"],
      ["of my flesh", "H1320"],
      [":"],
      ["she", "H2063"],
      ["shall be called", "H7121", "TH8735"],
      ["Woman", "H802"],
      [","],
      ["because she", "H2063"],
      ["was taken", "H3947", "TH8795"],
      ["out of Man", "H376"],
      ["."],
    ],
    "p",
    [
      ["Therefore", "H3651"],
      ["shall a man", "H376"],
      ["leave", "H5800", "TH8799"],
      ["his father", "H1"],
      ["and his mother", "H517"],
      [","],
      ["and shall cleave", "H1692", "TH8804"],
      ["unto his wife", "H802"],
      [":"],
      ["and they shall be one", "H259"],
      ["flesh", "H1320"],
      ["."],
    ],
    [
      ["And they were both", "H8147"],
      ["naked", "H6174"],
      [","],
      ["the man", "H120"],
      ["and his wife", "H802"],
      [","],
      ["and were not ashamed", "H954", "TH8709"],
      ["."],
    ],
    "p",
  ],
  [
    [
      ["Now the serpent", "H5175"],
      ["was", "H1961", "TH8804"],
      ["more subtil", "H6175"],
      ["than any beast", "H2416"],
      ["of the field", "H7704"],
      ["which the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["had made", "H6213", "TH8804"],
      ["."],
      ["And he said", "H559", "TH8799"],
      ["unto the woman", "H802"],
      [","],
      ["Yea", "H637"],
      [","],
      ["hath God", "H430"],
      ["said", "H559", "TH8804"],
      [","],
      ["Ye shall not eat", "H398", "TH8799"],
      ["of every tree", "H6086"],
      ["of the garden", "H1588"],
      ["?"],
    ],
    [
      ["And the woman", "H802"],
      ["said", "H559", "TH8799"],
      ["unto the serpent", "H5175"],
      [","],
      ["We may eat", "H398", "TH8799"],
      ["of the fruit", "H6529"],
      ["of the trees", "H6086"],
      ["of the garden", "H1588"],
      [":"],
    ],
    [
      ["But of the fruit", "H6529"],
      ["of the tree", "H6086"],
      ["which"],
      ["is", "added"],
      ["in the midst", "H8432"],
      ["of the garden", "H1588"],
      [","],
      ["God", "H430"],
      ["hath said", "H559", "TH8804"],
      [","],
      ["Ye shall not eat", "H398", "TH8799"],
      ["of it, neither shall ye touch", "H5060", "TH8799"],
      ["it, lest", "H6435"],
      ["ye die", "H4191", "TH8799"],
      ["."],
    ],
    [
      ["And the serpent", "H5175"],
      ["said", "H559", "TH8799"],
      ["unto the woman", "H802"],
      [","],
      ["Ye shall not surely", "H4191", "TH8800"],
      ["die", "H4191", "TH8799"],
      [":"],
    ],
    [
      ["For God", "H430"],
      ["doth know", "H3045", "TH8802"],
      ["that in the day", "H3117"],
      ["ye eat", "H398", "TH8800"],
      ["thereof, then your eyes", "H5869"],
      ["shall be opened", "H6491", "TH8738"],
      [","],
      ["and ye shall be as gods", "H430"],
      [","],
      ["knowing", "H3045", "TH8802"],
      ["good", "H2896"],
      ["and evil", "H7451"],
      ["."],
    ],
    [
      ["And when the woman", "H802"],
      ["saw", "H7200", "TH8799"],
      ["that the tree", "H6086"],
      ["was", "added"],
      ["good", "H2896"],
      ["for food", "H3978"],
      [","],
      ["and that it", "H1931"],
      ["was", "added"],
      ["pleasant", "H8378"],
      ["to the eyes", "H5869"],
      [","],
      ["and a tree", "H6086"],
      ["to be desired", "H2530", "TH8737"],
      ["to make"],
      ["one", "added"],
      ["wise", "H7919", "TH8687"],
      [","],
      ["she took", "H3947", "TH8799"],
      ["of the fruit thereof", "H6529"],
      [","],
      ["and did eat", "H398", "TH8799"],
      [","],
      ["and gave", "H5414", "TH8799"],
      ["also", "H1571"],
      ["unto her husband", "H376"],
      ["with her; and he did eat", "H398", "TH8799"],
      ["."],
    ],
    [
      ["And the eyes", "H5869"],
      ["of them both", "H8147"],
      ["were opened", "H6491", "TH8735"],
      [","],
      ["and they", "H1992"],
      ["knew", "H3045", "TH8799"],
      ["that they"],
      ["were", "added"],
      ["naked", "H5903"],
      [";"],
      ["and they sewed", "H8609", "TH8799"],
      ["fig", "H8384"],
      ["leaves", "H5929"],
      ["together", "H8609", "TH8799"],
      [","],
      ["and made themselves", "H6213", "TH8799"],
      ["aprons", "H2290"],
      ["."],
    ],
    "p",
    [
      ["And they heard", "H8085", "TH8799"],
      ["the voice", "H6963"],
      ["of the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["walking", "H1980", "TH8693"],
      ["in the garden", "H1588"],
      ["in the cool", "H7307"],
      ["of the day", "H3117"],
      [":"],
      ["and Adam", "H120"],
      ["and his wife", "H802"],
      ["hid themselves", "H2244", "TH8691"],
      ["from the presence", "H6440"],
      ["of the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["amongst", "H8432"],
      ["the trees", "H6086"],
      ["of the garden", "H1588"],
      ["."],
    ],
    [
      ["And the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["called", "H7121", "TH8799"],
      ["unto Adam", "H120"],
      [","],
      ["and said", "H559", "TH8799"],
      ["unto him, Where"],
      ["art", "added"],
      ["thou", "H335"],
      ["?"],
    ],
    [
      ["And he said", "H559", "TH8799"],
      [","],
      ["I heard", "H8085", "TH8804"],
      ["thy voice", "H6963"],
      ["in the garden", "H1588"],
      [","],
      ["and I was afraid", "H3372", "TH8799"],
      [","],
      ["because I", "H595"],
      ["was", "added"],
      ["naked", "H5903"],
      [";"],
      ["and I hid myself", "H2244", "TH8735"],
      ["."],
    ],
    [
      ["And he said", "H559", "TH8799"],
      [","],
      ["Who", "H4310"],
      ["told", "H5046", "TH8689"],
      ["thee that thou"],
      ["wast", "added"],
      ["naked", "H5903"],
      ["?"],
      ["Hast thou eaten", "H398", "TH8804"],
      ["of the tree", "H6086"],
      [","],
      ["whereof I commanded thee", "H6680", "TH8765"],
      ["that thou shouldest not", "H1115"],
      ["eat", "H398", "TH8800"],
      ["?"],
    ],
    [
      ["And the man", "H120"],
      ["said", "H559", "TH8799"],
      [","],
      ["The woman", "H802"],
      ["whom thou gavest", "H5414", "TH8804"],
      ["to be", "added"],
      ["with me", "H5978"],
      [","],
      ["she", "H1931"],
      ["gave", "H5414", "TH8804"],
      ["me of the tree", "H6086"],
      [","],
      ["and I did eat", "H398", "TH8799"],
      ["."],
    ],
    [
      ["And the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["said", "H559", "TH8799"],
      ["unto the woman", "H802"],
      [", What"],
      ["is", "added"],
      ["this"],
      ["that", "added"],
      ["thou hast done", "H6213", "TH8804"],
      ["?"],
      ["And the woman", "H802"],
      ["said", "H559", "TH8799"],
      [","],
      ["The serpent", "H5175"],
      ["beguiled me", "H5377", "TH8689"],
      [","],
      ["and I did eat", "H398", "TH8799"],
      ["."],
    ],
    "p",
    [
      ["And the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["said", "H559", "TH8799"],
      ["unto the serpent", "H5175"],
      [","],
      ["Because thou", "H859"],
      ["hast done", "H6213", "TH8804"],
      ["this, thou"],
      ["art", "added"],
      ["cursed", "H779", "TH8803"],
      ["above all cattle", "H929"],
      [","],
      ["and above every beast", "H2416"],
      ["of the field", "H7704"],
      [";"],
      ["upon thy belly", "H1512"],
      ["shalt thou go", "H3212", "TH8799"],
      [","],
      ["and dust", "H6083"],
      ["shalt thou eat", "H398", "TH8799"],
      ["all the days", "H3117"],
      ["of thy life", "H2416"],
      [":"],
    ],
    [
      ["And I will put", "H7896", "TH8799"],
      ["enmity", "H342"],
      ["between thee and the woman", "H802"],
      [","],
      ["and between thy seed", "H2233"],
      ["and her seed", "H2233"],
      [";"],
      ["it shall bruise", "H7779", "TH8799"],
      ["thy head", "H7218"],
      [","],
      ["and thou shalt bruise", "H7779", "TH8799"],
      ["his heel", "H6119"],
      ["."],
    ],
    "p",
    [
      ["Unto the woman", "H802"],
      ["he said", "H559", "TH8804"],
      [","],
      ["I will greatly", "H7235", "TH8687"],
      ["multiply", "H7235", "TH8686"],
      ["thy sorrow", "H6093"],
      ["and thy conception", "H2032"],
      [";"],
      ["in sorrow", "H6089"],
      ["thou shalt bring forth", "H3205", "TH8799"],
      ["children", "H1121"],
      [";"],
      ["and thy desire", "H8669"],
      ["shall be", "added"],
      ["to thy husband", "H376"],
      [","],
      ["and he shall rule", "H4910", "TH8799"],
      ["over thee."],
    ],
    "p",
    [
      ["And unto Adam", "H121"],
      ["he said", "H559", "TH8804"],
      [","],
      ["Because thou hast hearkened", "H8085", "TH8804"],
      ["unto the voice", "H6963"],
      ["of thy wife", "H802"],
      [","],
      ["and hast eaten", "H398", "TH8799"],
      ["of the tree", "H6086"],
      [","],
      ["of which", "H834"],
      ["I commanded thee", "H6680", "TH8765"],
      [","],
      ["saying", "H559", "TH8800"],
      [","],
      ["Thou shalt not eat", "H398", "TH8799"],
      ["of it: cursed", "H779", "TH8803"],
      ["is", "added"],
      ["the ground", "H127"],
      ["for thy sake; in sorrow", "H6093"],
      ["shalt thou eat", "H398", "TH8799"],
      ["of", "added"],
      ["it all", "H3605"],
      ["the days", "H3117"],
      ["of thy life", "H2416"],
      [";"],
    ],
    [
      ["Thorns also", "H6975"],
      ["and thistles", "H1863"],
      ["shall it bring forth", "H6779", "TH8686"],
      ["to thee; and thou shalt eat", "H398", "TH8804"],
      ["the herb", "H6212"],
      ["of the field", "H7704"],
      [";"],
    ],
    [
      ["In the sweat", "H2188"],
      ["of thy face", "H639"],
      ["shalt thou eat", "H398", "TH8799"],
      ["bread", "H3899"],
      [","],
      ["till", "H5704"],
      ["thou return", "H7725", "TH8800"],
      ["unto the ground", "H127"],
      [";"],
      ["for out of it wast thou taken", "H3947", "TH8795"],
      [":"],
      ["for dust", "H6083"],
      ["thou", "H859"],
      ["art", "added"],
      [","],
      ["and unto dust", "H6083"],
      ["shalt thou return", "H7725", "TH8799"],
      ["."],
    ],
    "p",
    [
      ["And Adam", "H120"],
      ["called", "H7121", "TH8799"],
      ["his wife’s", "H802"],
      ["name", "H8034"],
      ["Eve", "H2332"],
      [";"],
      ["because she was the mother", "H517"],
      ["of all living", "H2416"],
      ["."],
    ],
    [
      ["Unto Adam", "H120"],
      ["also and to his wife", "H802"],
      ["did", "H6213"],
      ["the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["make", "H6213", "TH8799"],
      ["coats", "H3801"],
      ["of skins", "H5785"],
      [","],
      ["and clothed them", "H3847", "TH8686"],
      ["."],
    ],
    [
      ["And the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["said", "H559", "TH8799"],
      [","],
      ["Behold", "H2005"],
      [","],
      ["the man", "H120"],
      ["is become as one", "H259"],
      ["of us, to know", "H3045", "TH8800"],
      ["good", "H2896"],
      ["and evil", "H7451"],
      [":"],
      ["and now, lest he put forth", "H7971", "TH8799"],
      ["his hand", "H3027"],
      [","],
      ["and take", "H3947", "TH8804"],
      ["also of the tree", "H6086"],
      ["of life", "H2416"],
      [","],
      ["and eat", "H398", "TH8804"],
      [","],
      ["and live", "H2425", "TH8804"],
      ["for ever", "H5769"],
      [":"],
    ],
    [
      ["Therefore the Lord", "dvnNm H3068"],
      ["God", "H430"],
      ["sent him forth", "H7971", "TH8762"],
      ["from the garden", "H1588"],
      ["of Eden", "H5731"],
      [","],
      ["to till", "H5647", "TH8800"],
      ["the ground", "H127"],
      ["from whence he was taken", "H3947", "TH8795"],
      ["."],
    ],
    [
      ["So he drove out", "H1644", "TH8762"],
      ["the man", "H120"],
      [";"],
      ["and he placed", "H7931", "TH8686"],
      ["at the east", "H6924"],
      ["of the garden", "H1588"],
      ["of Eden", "H5731"],
      ["Cherubims", "H3742"],
      [","],
      ["and a flaming", "H3858"],
      ["sword", "H2719"],
      ["which turned every way", "H2015", "TH8693"],
      [","],
      ["to keep", "H8104", "TH8800"],
      ["the way", "H1870"],
      ["of the tree", "H6086"],
      ["of life", "H2416"],
      ["."],
    ],
  ],
];

export default Genesis;
