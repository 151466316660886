const Strongs = {
	"H1" : {
		"lemma" : "אָב",
		"xlit" : "ʼâb",
		"pron" : "awb",
		"derivation" : "a primitive word",
		"def" : "father, in a literal and immediate, or figurative and remote application",
		"kjv" : "chief, (fore-) father(-less), [idiom] patrimony, principal. Compare names in 'Abi-'"
	},
	"H2" : {
		"lemma" : "אַב",
		"xlit" : "ʼab",
		"pron" : "ab",
		"derivation" : "(Aramaic) corresponding to H1 (אָב)",
		"def" : "{father}",
		"kjv" : "father"
	},
	"H3" : {
		"lemma" : "אֵב",
		"xlit" : "ʼêb",
		"pron" : "abe",
		"derivation" : "from the same as H24 (אָבִיב)",
		"def" : "a green plant",
		"kjv" : "greenness, fruit"
	},
	"H4" : {
		"lemma" : "אֵב",
		"xlit" : "ʼêb",
		"pron" : "abe",
		"derivation" : "(Aramaic) corresponding to H3 (אֵב)",
		"def" : "{a green plant}",
		"kjv" : "fruit"
	},
	"H5" : {
		"lemma" : "אֲבַגְתָא",
		"xlit" : "ʼĂbagthâʼ",
		"pron" : "ab-ag-thaw'",
		"derivation" : "of foreign origin",
		"def" : "Abagtha, a eunuch of Xerxes",
		"kjv" : "Abagtha"
	},
	"H6" : {
		"lemma" : "אָבַד",
		"xlit" : "ʼâbad",
		"pron" : "aw-bad'",
		"derivation" : "a primitive root",
		"def" : "properly, to wander away, i.e. lose oneself; by implication to perish (causative, destroy)",
		"kjv" : "break, destroy(-uction), [phrase] not escape, fail, lose, (cause to, make) perish, spend, [idiom] and surely, take, be undone, [idiom] utterly, be void of, have no way to flee"
	},
	"H7" : {
		"lemma" : "אֲבַד",
		"xlit" : "ʼăbad",
		"pron" : "ab-ad'",
		"derivation" : "(Aramaic) corresponding to H6 (אָבַד)",
		"def" : "{properly, to wander away, i.e. lose oneself; by implication to perish (causative, destroy)}",
		"kjv" : "destroy, perish"
	},
	"H8" : {
		"lemma" : "אֹבֵד",
		"xlit" : "ʼôbêd",
		"pron" : "o-bade'",
		"derivation" : "active of participle of H6 (אָבַד)",
		"def" : "(concrete) wretched or (abstract) destruction",
		"kjv" : "perish"
	},
	"H9" : {
		"lemma" : "אֲבֵדָה",
		"xlit" : "ʼăbêdâh",
		"pron" : "ab-ay-daw'",
		"derivation" : "from H6 (אָבַד); Compare H10 (אֲבַדֹּה)",
		"def" : "concrete, something lost; abstract, destruction, i.e. Hades",
		"kjv" : "lost"
	},
	"H10" : {
		"lemma" : "אֲבַדֹּה",
		"xlit" : "ʼăbaddôh",
		"pron" : "ab-ad-do'",
		"derivation" : "the same as H9 (אֲבֵדָה), miswritten for H11 (אֲבַדּוֹן)",
		"def" : "a perishing",
		"kjv" : "destruction"
	},
	"H11" : {
		"lemma" : "אֲבַדּוֹן",
		"xlit" : "ʼăbaddôwn",
		"pron" : "ab-ad-done'",
		"derivation" : "intensive from H6 (אָבַד)",
		"def" : "abstract, a perishing; concrete, Hades",
		"kjv" : "destruction"
	},
	"H12" : {
		"lemma" : "אַבְדָן",
		"xlit" : "ʼabdân",
		"pron" : "ab-dawn'",
		"derivation" : "from H6 (אָבַד)",
		"def" : "a perishing",
		"kjv" : "destruction"
	},
	"H13" : {
		"lemma" : "אׇבְדַן",
		"xlit" : "ʼobdan",
		"pron" : "ob-dawn'",
		"derivation" : "from H6 (אָבַד)",
		"def" : "a perishing",
		"kjv" : "destruction"
	},
	"H14" : {
		"lemma" : "אָבָה",
		"xlit" : "ʼâbâh",
		"pron" : "aw-baw'",
		"derivation" : "a primitive root",
		"def" : "to breathe after, i.e. (figuratively) to be acquiescent",
		"kjv" : "consent, rest content will, be willing"
	},
	"H15" : {
		"lemma" : "אָבֶה",
		"xlit" : "ʼâbeh",
		"pron" : "aw-beh'",
		"derivation" : "from H14 (אָבָה)",
		"def" : "longing",
		"kjv" : "desire"
	},
	"H16" : {
		"lemma" : "אֵבֶה",
		"xlit" : "ʼêbeh",
		"pron" : "ay-beh'",
		"derivation" : "from H14 (אָבָה) (in the sense of bending toward)",
		"def" : "the papyrus",
		"kjv" : "swift"
	},
	"H17" : {
		"lemma" : "אֲבוֹי",
		"xlit" : "ʼăbôwy",
		"pron" : "ab-o'ee",
		"derivation" : "from H14 (אָבָה) (in the sense of desiring)",
		"def" : "want",
		"kjv" : "sorrow"
	},
	"H18" : {
		"lemma" : "אֵבוּס",
		"xlit" : "ʼêbûwç",
		"pron" : "ay-booce'",
		"derivation" : "from H75 (אָבַס)",
		"def" : "a manger or stall",
		"kjv" : "crib"
	},
	"H19" : {
		"lemma" : "אִבְחָה",
		"xlit" : "ʼibchâh",
		"pron" : "ib-khaw'",
		"derivation" : "from an unused root (apparently meaning to turn)",
		"def" : "brandishing of a sword",
		"kjv" : "point"
	},
	"H20" : {
		"lemma" : "אֲבַטִּיחַ",
		"xlit" : "ʼăbaṭṭîyach",
		"pron" : "ab-at-tee'-akh",
		"derivation" : "of uncertain derivation",
		"def" : "a melon (only plural)",
		"kjv" : "melon"
	},
	"H21" : {
		"lemma" : "אֲבִי",
		"xlit" : "ʼĂbîy",
		"pron" : "ab-ee'",
		"derivation" : "from H1 (אָב); fatherly",
		"def" : "Abi, Hezekiah's mother",
		"kjv" : "Abi"
	},
	"H22" : {
		"lemma" : "אֲבִיאֵל",
		"xlit" : "ʼĂbîyʼêl",
		"pron" : "ab-ee-ale'",
		"derivation" : "from H1 (אָב) and H410 (אֵל); father (i.e. possessor) of God",
		"def" : "Abiel, the name of two Israelites",
		"kjv" : "Abiel"
	},
	"H23" : {
		"lemma" : "אֲבִיאָסָף",
		"xlit" : "ʼĂbîyʼâçâph",
		"pron" : "ab-ee-aw-sawf'",
		"derivation" : "from H1 (אָב) and H622 (אָסַף); father of gathering (i.e. gatherer)",
		"def" : "Abiasaph, an Israelite",
		"kjv" : "Abiasaph"
	},
	"H24" : {
		"lemma" : "אָבִיב",
		"xlit" : "ʼâbîyb",
		"pron" : "aw-beeb'",
		"derivation" : "from an unused root (meaning to be tender)",
		"def" : "green, i.e. a young ear of grain; hence, the name of the month Abib or Nisan",
		"kjv" : "Abib, ear, green ears of corn (not maize)"
	},
	"H25" : {
		"lemma" : "אֲבִי גִבְעוֹן",
		"xlit" : "ʼĂbîy Gibʻôwn",
		"pron" : "ab-ee' ghib-one'",
		"derivation" : "from H1 (אָב) and H1391 (גִּבְעוֹן); father (i.e. founder) of Gibon",
		"def" : "Abi-Gibon, perhaps an Israelite",
		"kjv" : "father of Gibeon"
	},
	"H26" : {
		"lemma" : "אֲבִיגַיִל",
		"xlit" : "ʼĂbîygayil",
		"pron" : "ab-ee-gah'-yil",
		"derivation" : "or shorter אֲבִיגַל; from H1 (אָב) and H1524 (גִּיל); father (i.e. source) of joy",
		"def" : "Abigail or Abigal, the name of two Israelitesses",
		"kjv" : "Abigal"
	},
	"H27" : {
		"lemma" : "אֲבִידָן",
		"xlit" : "ʼĂbîydân",
		"pron" : "ab-ee-dawn'",
		"derivation" : "from H1 (אָב) and H1777 (דִּין); father of judgment (i.e. judge)",
		"def" : "Abidan, an Israelite",
		"kjv" : "Abidan"
	},
	"H28" : {
		"lemma" : "אֲבִידָע",
		"xlit" : "ʼĂbîydâʻ",
		"pron" : "ab-ee-daw'",
		"derivation" : "from H1 (אָב) and H3045 (יָדַע); father of knowledge (i.e. knowing)",
		"def" : "Abida, a son of Abraham by Keturah",
		"kjv" : "Abida, Abidah"
	},
	"H29" : {
		"lemma" : "אֲבִיָּה",
		"xlit" : "ʼĂbîyâh",
		"pron" : "ab-ee-yaw'",
		"derivation" : "or prolonged אֲבִיָּהוּ ; from H1 (אָב) and H3050 (יָהּ); father (i.e. worshipper) of Jah",
		"def" : "Abijah, the name of several Israelite men and two Israelitesses",
		"kjv" : "Abiah, Abijah"
	},
	"H30" : {
		"lemma" : "אֲבִיהוּא",
		"xlit" : "ʼĂbîyhûwʼ",
		"pron" : "ab-ee-hoo'",
		"derivation" : "from H1 (אָב) and H1931 (הוּא); father (i.e. worshipper) of Him (i.e. God)",
		"def" : "Abihu, a son of Aaron",
		"kjv" : "Abihu"
	},
	"H31" : {
		"lemma" : "אֲבִיהוּד",
		"xlit" : "ʼĂbîyhûwd",
		"pron" : "ab-ee-hood'",
		"derivation" : "from H1 (אָב) and H1935 (הוֹד); father (i.e. possessor) of renown",
		"def" : "Abihud, the name of two Israelites",
		"kjv" : "Abihud"
	},
	"H32" : {
		"lemma" : "אֲבִיהַיִל",
		"xlit" : "ʼĂbîyhayil",
		"pron" : "ab-ee-hah'-yil",
		"derivation" : "or (more correctly) אֲבִיחַיִל ; from H1 (אָב) and H2428 (חַיִל); father (i.e. possessor) of might",
		"def" : "Abihail or Abichail, the name of three Israelites and two Israelitesses",
		"kjv" : "Abihail"
	},
	"H33" : {
		"lemma" : "אֲבִי הָעֶזְרִי",
		"xlit" : "ʼĂbîy hâ-ʻEzrîy",
		"pron" : "ab-ee'-haw-ez-ree'",
		"derivation" : "from H44 (אֲבִיעֶזֶר) with the article inserted; father of the Ezrite",
		"def" : "an Abiezrite or descendant of Abiezer",
		"kjv" : "Abiezrite"
	},
	"H34" : {
		"lemma" : "אֶבְיוֹן",
		"xlit" : "ʼebyôwn",
		"pron" : "eb-yone'",
		"derivation" : "from H14 (אָבָה), in the sense of want (especially in feeling)",
		"def" : "destitute",
		"kjv" : "beggar, needy, poor (man)"
	},
	"H35" : {
		"lemma" : "אֲבִיּוֹנָה",
		"xlit" : "ʼăbîyôwnâh",
		"pron" : "ab-ee-yo-naw'",
		"derivation" : "from H14 (אָבָה)",
		"def" : "provocative of desire; the caper berry (from its stimulative taste)",
		"kjv" : "desire"
	},
	"H36" : {
		"lemma" : "אֲבִיטוּב",
		"xlit" : "ʼĂbîyṭûwb",
		"pron" : "ab-ee-toob'",
		"derivation" : "from H1 (אָב) and H2898 (טוּב); father of goodness (i.e. good)",
		"def" : "Abitub, an Israelite",
		"kjv" : "Abitub"
	},
	"H37" : {
		"lemma" : "אֲבִיטָל",
		"xlit" : "ʼĂbîyṭâl",
		"pron" : "ab-ee-tal'",
		"derivation" : "from H1 (אָב) and H2919 (טַל); father of dew (i.e. fresh)",
		"def" : "Abital, a wife of King David",
		"kjv" : "Abital"
	},
	"H38" : {
		"lemma" : "אֲבִיָּם",
		"xlit" : "ʼĂbîyâm",
		"pron" : "ab-ee-yawm'",
		"derivation" : "from H1 (אָב) and H3220 (יָם); father of (the) sea (i.e. seaman)",
		"def" : "Abijam (or Abijah), a king of Judah",
		"kjv" : "Abijam"
	},
	"H39" : {
		"lemma" : "אֲבִימָאֵל",
		"xlit" : "ʼĂbîymâʼêl",
		"pron" : "ab-ee-maw-ale'",
		"derivation" : "from H1 (אָב) and an elsewhere unused (probably foreign) word; father of Mael (apparently some Arab tribe)",
		"def" : "Abimael, a son of Joktan",
		"kjv" : "Abimael"
	},
	"H40" : {
		"lemma" : "אֲבִימֶלֶךְ",
		"xlit" : "ʼĂbîymelek",
		"pron" : "ab-ee-mel'-ek",
		"derivation" : "from H1 (אָב) and H4428 (מֶלֶךְ); father of (the) king",
		"def" : "Abimelek, the name of two Philistine kings and of two Israelites",
		"kjv" : "Abimelech"
	},
	"H41" : {
		"lemma" : "אֲבִינָדָב",
		"xlit" : "ʼĂbîynâdâb",
		"pron" : "ab-ee-naw-dawb'",
		"derivation" : "from H1 (אָב) and H5068 (נָדַב); father of generosity (i.e. liberal)",
		"def" : "Abinadab, the name of four Israelites",
		"kjv" : "Abinadab"
	},
	"H42" : {
		"lemma" : "אֲבִינֹעַם",
		"xlit" : "ʼĂbîynôʻam",
		"pron" : "ab-ee-no'-am",
		"derivation" : "from H1 (אָב) and H5278 (נֹעַם); father of pleasantness (i.e. gracious)",
		"def" : "Abinoam, an Israelite",
		"kjv" : "Abinoam"
	},
	"H43" : {
		"lemma" : "אֶבְיָסָף",
		"xlit" : "ʼEbyâçâph",
		"pron" : "eb-yaw-sawf'",
		"derivation" : "contracted from H23 (אֲבִיאָסָף)",
		"def" : "Ebjasaph, an Israelite",
		"kjv" : "Ebiasaph"
	},
	"H44" : {
		"lemma" : "אֲבִיעֶזֶר",
		"xlit" : "ʼĂbîyʻezer",
		"pron" : "ab-ee-ay'-zer",
		"derivation" : "from H1 (אָב) and H5829 (עֵזֶר); father of help (i.e. helpful)",
		"def" : "Abiezer, the name of two Israelites",
		"kjv" : "Abiezer"
	},
	"H45" : {
		"lemma" : "אֲבִי־עַלְבוֹן",
		"xlit" : "ʼĂbîy-ʻalbôwn",
		"pron" : "ab-ee-al-bone'",
		"derivation" : "from H1 (אָב) and an unused root of uncertain derivation; probably, father of strength (i.e. valiant)",
		"def" : "Abialbon, an Israelite",
		"kjv" : "Abialbon"
	},
	"H46" : {
		"lemma" : "אָבִיר",
		"xlit" : "ʼâbîyr",
		"pron" : "aw-beer'",
		"derivation" : "from H82 (אָבַר)",
		"def" : "mighty (spoken of God)",
		"kjv" : "mighty (one)"
	},
	"H47" : {
		"lemma" : "אַבִּיר",
		"xlit" : "ʼabbîyr",
		"pron" : "ab-beer'",
		"derivation" : "for H46 (אָבִיר)",
		"def" : "{mighty (spoken of God)}",
		"kjv" : "angel, bull, chiefest, mighty (one), stout(-hearted), strong (one), valiant"
	},
	"H48" : {
		"lemma" : "אֲבִירָם",
		"xlit" : "ʼĂbîyrâm",
		"pron" : "ab-ee-rawm'",
		"derivation" : "from H1 (אָב) and H7311 (רוּם); father of height (i.e. lofty)",
		"def" : "Abiram, the name of two Israelites",
		"kjv" : "Abiram"
	},
	"H49" : {
		"lemma" : "אֲבִישַׁג",
		"xlit" : "ʼĂbîyshag",
		"pron" : "ab-ee-shag'",
		"derivation" : "from H1 (אָב) and H7686 (שָׁגָה); father of error (i.e. blundering)",
		"def" : "Abishag, a concubine of David",
		"kjv" : "Abishag"
	},
	"H50" : {
		"lemma" : "אֲבִישׁוּעַ",
		"xlit" : "ʼĂbîyshûwaʻ",
		"pron" : "ab-ee-shoo'-ah",
		"derivation" : "from H1 (אָב) and H7771 (שׁוֹעַ); father of plenty (i.e. prosperous)",
		"def" : "Abishua, the name of two Israelites",
		"kjv" : "Abishua"
	},
	"H51" : {
		"lemma" : "אֲבִישׁוּר",
		"xlit" : "ʼĂbîyshûwr",
		"pron" : "ab-ee-shoor'",
		"derivation" : "from H1 (אָב) and H7791 (שׁוּר); father of (the) wall (i.e. perhaps mason)",
		"def" : "Abishur, an Israelite",
		"kjv" : "Abishur"
	},
	"H52" : {
		"lemma" : "אֲבִישַׁי",
		"xlit" : "ʼĂbîyshay",
		"pron" : "ab-ee-shah'ee",
		"derivation" : "or (shorter) אַבְשַׁי ; from H1 (אָב) and H7862 (שַׁי); father of a gift (i.e. probably generous)",
		"def" : "Abishai, an Israelite",
		"kjv" : "Abishai"
	},
	"H53" : {
		"lemma" : "אֲבִישָׁלוֹם",
		"xlit" : "ʼĂbîyshâlôwm",
		"pron" : "ab-ee-shaw-lome'",
		"derivation" : "or (shortened) אַבְשָׁלוֹם ; from H1 (אָב) and H7965 (שָׁלוֹם); father of peace (i.e. friendly)",
		"def" : "Abshalom, a son of David; also (the fuller form) a later Israelite",
		"kjv" : "Abishalom, Absalom"
	},
	"H54" : {
		"lemma" : "אֶבְיָתָר",
		"xlit" : "ʼEbyâthâr",
		"pron" : "ab-yaw-thawr'",
		"derivation" : "contracted from H1 (אָב) and H3498 (יָתַר); father of abundance (i.e. liberal)",
		"def" : "Ebjathar, an Israelite",
		"kjv" : "Abiathar"
	},
	"H55" : {
		"lemma" : "אָבַךְ",
		"xlit" : "ʼâbak",
		"pron" : "aw-bak'",
		"derivation" : "a primitive root",
		"def" : "probably to coil upward",
		"kjv" : "mount up"
	},
	"H56" : {
		"lemma" : "אָבַל",
		"xlit" : "ʼâbal",
		"pron" : "aw-bal'",
		"derivation" : "a primitive root",
		"def" : "to bewail",
		"kjv" : "lament, mourn"
	},
	"H57" : {
		"lemma" : "אָבֵל",
		"xlit" : "ʼâbêl",
		"pron" : "aw-bale'",
		"derivation" : "from H56 (אָבַל)",
		"def" : "lamenting",
		"kjv" : "mourn(-er, -ing)"
	},
	"H58" : {
		"lemma" : "אָבֵל",
		"xlit" : "ʼâbêl",
		"pron" : "aw-bale'",
		"derivation" : "from an unused root (meaning to be grassy)",
		"def" : "a meadow",
		"kjv" : "plain. Compare also the proper names beginning with Abel-"
	},
	"H59" : {
		"lemma" : "אָבֵל",
		"xlit" : "ʼÂbêl",
		"pron" : "aw-bale'",
		"derivation" : "from H58 (אָבֵל); a meadow",
		"def" : "Abel, the name of two places in Palestine",
		"kjv" : "Abel"
	},
	"H60" : {
		"lemma" : "אֵבֶל",
		"xlit" : "ʼêbel",
		"pron" : "ay'-bel",
		"derivation" : "from H56 (אָבַל)",
		"def" : "lamentation",
		"kjv" : "mourning"
	},
	"H61" : {
		"lemma" : "אֲבָל",
		"xlit" : "ʼăbâl",
		"pron" : "ab-awl'",
		"derivation" : "apparently from H56 (אָבַל) through the idea of negation",
		"def" : "nay, i.e. truly or yet",
		"kjv" : "but, indeed, nevertheless, verily"
	},
	"H62" : {
		"lemma" : "אָבֵל בֵּית־מֲעַכָה",
		"xlit" : "ʼÂbêl Bêyth-Măʻakâh",
		"pron" : "aw-bale' bayth ma-akaw'",
		"derivation" : "from H58 (אָבֵל) and H1004 (בַּיִת) and H4601 (מַעֲכָה); meadow of Beth-Maakah",
		"def" : "Abel of Beth-maakah, a place in Palestine",
		"kjv" : "Abel-beth-maachah, Abel of Beth-maachah"
	},
	"H63" : {
		"lemma" : "אָבֵל הַשִּׁטִּים",
		"xlit" : "ʼÂbêl hash-Shiṭṭîym",
		"pron" : "aw-bale' hash-shit-teem'",
		"derivation" : "from H58 (אָבֵל) and the plural of H7848 (שִׁטָּה), with the article inserted; meadow of the acacias",
		"def" : "Abel hash-Shittim, a place in Palestine",
		"kjv" : "Abelshittim"
	},
	"H64" : {
		"lemma" : "אָבֵל כְּרָמִים",
		"xlit" : "ʼÂbêl Kᵉrâmîym",
		"pron" : "aw-bale' ker-aw-meem'",
		"derivation" : "from H58 (אָבֵל) and the plural of H3754 (כֶּרֶם); meadow of vineyards",
		"def" : "Abel-Keramim, a place in Palestine",
		"kjv" : "plain of the vineyards"
	},
	"H65" : {
		"lemma" : "אָבֵל מְחוֹלָה",
		"xlit" : "ʼÂbêl Mᵉchôwlâh",
		"pron" : "aw-bale' mekh-o-law'",
		"derivation" : "from H58 (אָבֵל) and H4246 (מְחֹלָה)xlit mᵉchôwlâh corrected to mᵉchôlâh; meadow of dancing",
		"def" : "Abel-Mecholah, a place in Palestine",
		"kjv" : "Abel-meholah"
	},
	"H66" : {
		"lemma" : "אַבֵל מַיִם",
		"xlit" : "ʼAbêl Mayim",
		"pron" : "aw-bale' mah'-yim",
		"derivation" : "from H58 (אָבֵל) and H4325 (מַיִם); meadow of water",
		"def" : "Abel-Majim, a place in Palestine",
		"kjv" : "Abel-maim"
	},
	"H67" : {
		"lemma" : "אָבֵל מִצְרַיִם",
		"xlit" : "ʼÂbêl Mitsrayim",
		"pron" : "aw-bale' mits-rah'-yim",
		"derivation" : "from H58 (אָבֵל) and H4714 (מִצְרַיִם); meadow of Egypt",
		"def" : "Abel-Mitsrajim, a place in Palestine",
		"kjv" : "Abel-mizraim"
	},
	"H68" : {
		"lemma" : "אֶבֶן",
		"xlit" : "ʼeben",
		"pron" : "eh'-ben",
		"derivation" : "from the root of H1129 (בָּנָה) through the meaning to build",
		"def" : "a stone",
		"kjv" : "[phrase] carbuncle, [phrase] mason, [phrase] plummet, (chalk-, hail-, head-, sling-) stone(-ny), (divers) weight(-s)"
	},
	"H69" : {
		"lemma" : "אֶבֶן",
		"xlit" : "ʼeben",
		"pron" : "eh'-ben",
		"derivation" : "(Aramaic) corresponding to H68 (אֶבֶן)",
		"def" : "{a stone}",
		"kjv" : "stone"
	},
	"H70" : {
		"lemma" : "אֹבֶן",
		"xlit" : "ʼôben",
		"pron" : "o' ben",
		"derivation" : "from the same as H68 (אֶבֶן)",
		"def" : "a pair of stones (only dual); a potter's wheel or a midwife's stool (consisting alike of two horizontal disks with a support between)",
		"kjv" : "wheel, stool"
	},
	"H71" : {
		"lemma" : "אֲבָנָה",
		"xlit" : "ʼĂbânâh",
		"pron" : "ab-aw-naw'",
		"derivation" : "perhaps feminine of H68 (אֶבֶן); stony;  Compare H549 (אֲמָנָה)",
		"def" : "Abanah, a river near Damascus",
		"kjv" : "Abana"
	},
	"H72" : {
		"lemma" : "אֶבֶן הָעֵזֶר",
		"xlit" : "ʼEben hâ-ʻÊzer",
		"pron" : "eh'-ben haw-e'-zer",
		"derivation" : "from H68 (אֶבֶן) and H5828 (עֵזֶר) with the article inserted; stone of the help",
		"def" : "Eben-ha-Ezer, a place in Palestine",
		"kjv" : "Ebenezer"
	},
	"H73" : {
		"lemma" : "אַבְנֵט",
		"xlit" : "ʼabnêṭ",
		"pron" : "ab-nate'",
		"derivation" : "of uncertain derivation",
		"def" : "a belt",
		"kjv" : "girdle"
	},
	"H74" : {
		"lemma" : "אַבְנֵר",
		"xlit" : "ʼAbnêr",
		"pron" : "ab-nare'",
		"derivation" : "or (fully) אֲבִינֵר ; from H1 (אָב) and H5216 (נִיר); father of light (i.e. enlightening)",
		"def" : "Abner, an Israelite",
		"kjv" : "Abner"
	},
	"H75" : {
		"lemma" : "אָבַס",
		"xlit" : "ʼâbaç",
		"pron" : "aw-bas'",
		"derivation" : "a primitive root",
		"def" : "to fodder",
		"kjv" : "fatted, stalled"
	},
	"H76" : {
		"lemma" : "אֲבַעְבֻּעָה",
		"xlit" : "ʼăbaʻbuʻâh",
		"pron" : "ab-ah-boo-aw'",
		"derivation" : "(by reduplication) from an unused root (meaning to belch forth)",
		"def" : "an inflammatory pustule (as eruption)",
		"kjv" : "blains"
	},
	"H77" : {
		"lemma" : "אֶבֶץ",
		"xlit" : "ʼEbets",
		"pron" : "eh'-bets",
		"derivation" : "from an unused root probably meaning to gleam; conspicuous",
		"def" : "Ebets, a place in Palestine",
		"kjv" : "Abez"
	},
	"H78" : {
		"lemma" : "אִבְצָן",
		"xlit" : "ʼIbtsân",
		"pron" : "ib-tsawn'",
		"derivation" : "from the same as H76 (אֲבַעְבֻּעָה); splendid",
		"def" : "Ibtsan, an Israelite",
		"kjv" : "Ibzan"
	},
	"H79" : {
		"lemma" : "אָבַק",
		"xlit" : "ʼâbaq",
		"pron" : "aw-bak'",
		"derivation" : "a primitive root, probably to float away (as vapor), but used only as denominative from H80 (אָבָק)",
		"def" : "to bedust, i.e. grapple",
		"kjv" : "wrestle"
	},
	"H80" : {
		"lemma" : "אָבָק",
		"xlit" : "ʼâbâq",
		"pron" : "aw-bawk'",
		"derivation" : "from root of H79 (אָבַק)",
		"def" : "light particles (as volatile)",
		"kjv" : "(small) dust, powder"
	},
	"H81" : {
		"lemma" : "אֲבָקָה",
		"xlit" : "ʼăbâqâh",
		"pron" : "ab-aw-kaw'",
		"derivation" : "feminine of H80 (אָבָק)",
		"def" : "{light particles (as volatile)}",
		"kjv" : "powder"
	},
	"H82" : {
		"lemma" : "אָבַר",
		"xlit" : "ʼâbar",
		"pron" : "aw-bar'",
		"derivation" : "a primitive root",
		"def" : "to soar",
		"kjv" : "fly"
	},
	"H83" : {
		"lemma" : "אֵבֶר",
		"xlit" : "ʼêber",
		"pron" : "ay-ber'",
		"derivation" : "from H82 (אָבַר)",
		"def" : "a pinion",
		"kjv" : "(long-) wing(-ed)"
	},
	"H84" : {
		"lemma" : "אֶבְרָה",
		"xlit" : "ʼebrâh",
		"pron" : "eb-raw'",
		"derivation" : "feminine of H83 (אֵבֶר)",
		"def" : "{a pinion}",
		"kjv" : "feather, wing"
	},
	"H85" : {
		"lemma" : "אַבְרָהָם",
		"xlit" : "ʼAbrâhâm",
		"pron" : "ab-raw-hawm'",
		"derivation" : "contracted from H1 (אָב) and an unused root (probably meaning to be populous); father of a multitude",
		"def" : "Abraham, the later name of Abram",
		"kjv" : "Abraham"
	},
	"H86" : {
		"lemma" : "אַבְרֵךְ",
		"xlit" : "ʼabrêk",
		"pron" : "ab-rake'",
		"derivation" : "probably an Egyptian word meaning",
		"def" : "kneel",
		"kjv" : "bow the knee"
	},
	"H87" : {
		"lemma" : "אַבְרָם",
		"xlit" : "ʼAbrâm",
		"pron" : "ab-rawm'",
		"derivation" : "contracted from H48 (אֲבִירָם); high father",
		"def" : "Abram, the original name of Abraham",
		"kjv" : "Abram"
	},
	"H88" : {
		"lemma" : "אֹבֹת",
		"xlit" : "ʼôbôth",
		"pron" : "o-both'",
		"derivation" : "plural of H178 (אוֹב); water-skins",
		"def" : "Oboth, a place in the Desert",
		"kjv" : "Oboth"
	},
	"H89" : {
		"lemma" : "אָגֵא",
		"xlit" : "ʼÂgêʼ",
		"pron" : "aw-gay'",
		"derivation" : "of uncertain derivation (compare H90 (אֲגַג))",
		"def" : "Age, an Israelite",
		"kjv" : "Agee"
	},
	"H90" : {
		"lemma" : "אֲגַג",
		"xlit" : "ʼĂgag",
		"pron" : "ag-ag'",
		"derivation" : "or אֲגָג; of uncertain derivation (compare H89 (אָגֵא)); flame",
		"def" : "Agag, a title of Amalekitish kings",
		"kjv" : "Agag"
	},
	"H91" : {
		"lemma" : "אֲגָגִי",
		"xlit" : "ʼĂgâgîy",
		"pron" : "ag-aw-ghee'",
		"derivation" : "patrial or patronymic from H90 (אֲגַג)",
		"def" : "an Agagite or descendent (subject) of Agag",
		"kjv" : "Agagite"
	},
	"H92" : {
		"lemma" : "אֲגֻדָּה",
		"xlit" : "ʼăguddâh",
		"pron" : "ag-ood-daw'",
		"derivation" : "feminine passive participle of an unused root (meaning to bind)",
		"def" : "a band, bundle, knot, or arch",
		"kjv" : "bunch, burden, troop"
	},
	"H93" : {
		"lemma" : "אֱגוֹז",
		"xlit" : "ʼĕgôwz",
		"pron" : "eg-oze'",
		"derivation" : "prob of Persian origin",
		"def" : "a nut",
		"kjv" : "nut"
	},
	"H94" : {
		"lemma" : "אָגוּר",
		"xlit" : "ʼÂgûwr",
		"pron" : "aw-goor'",
		"derivation" : "passive participle of H103 (אָגַר); gathered (i.e. received among the sages)",
		"def" : "Agur, a fanciful name for Solomon",
		"kjv" : "Agur"
	},
	"H95" : {
		"lemma" : "אֲגוֹרָה",
		"xlit" : "ʼăgôwrâh",
		"pron" : "ag-o-raw'",
		"derivation" : "from the same as H94 (אָגוּר)",
		"def" : "properly, something gathered, i.e. perhaps a grain or berry; used only of a small (silver) coin",
		"kjv" : "piece (of) silver"
	},
	"H96" : {
		"lemma" : "אֶגֶל",
		"xlit" : "ʼegel",
		"pron" : "eh'-ghel",
		"derivation" : "from an unused root (meaning to flow down or together as drops)",
		"def" : "a reservoir",
		"kjv" : "drop"
	},
	"H97" : {
		"lemma" : "אֶגְלַיִם",
		"xlit" : "ʼEglayim",
		"pron" : "eg-lah'-yim",
		"derivation" : "dual of H96 (אֶגֶל).; a double pond",
		"def" : "Eglajim, a place in Moab",
		"kjv" : "Eglaim"
	},
	"H98" : {
		"lemma" : "אֲגַם",
		"xlit" : "ʼăgam",
		"pron" : "ag-am'",
		"derivation" : "from an unused root (meaning to collect as water)",
		"def" : "a marsh; hence a rush (as growing in swamps); hence a stockade of reeds",
		"kjv" : "pond, pool, standing (water)"
	},
	"H99" : {
		"lemma" : "אָגֵם",
		"xlit" : "ʼâgêm",
		"pron" : "aw-game'",
		"derivation" : "probably from the same as H98 (אֲגַם) (in the sense of stagnant water)",
		"def" : "figuratively, sad",
		"kjv" : "pond"
	},
	"H100" : {
		"lemma" : "אַגְמוֹן",
		"xlit" : "ʼagmôwn",
		"pron" : "ag-mone'",
		"derivation" : "from the same as H98 (אֲגַם); a marshy pool (others from a different root, a kettle); by implication",
		"def" : "a bulrush (as growing there); collectively a rope of bulrushes",
		"kjv" : "bulrush, caldron, hook, rush"
	},
	"H101" : {
		"lemma" : "אַגָּן",
		"xlit" : "ʼaggân",
		"pron" : "ag-gawn'",
		"derivation" : "probably from H5059 (נָגַן)",
		"def" : "a bowl (as pounded out hollow)",
		"kjv" : "basin, cup, goblet"
	},
	"H102" : {
		"lemma" : "אַגָּף",
		"xlit" : "ʼaggâph",
		"pron" : "ag-gawf'",
		"derivation" : "probably from H5062 (נָגַף) (through the idea of impending); a cover or heap; i.e",
		"def" : "(only plural) wings of an army, or crowds of troops",
		"kjv" : "bands"
	},
	"H103" : {
		"lemma" : "אָגַר",
		"xlit" : "ʼâgar",
		"pron" : "aw-gar'",
		"derivation" : "a primitive root",
		"def" : "to harvest",
		"kjv" : "gather"
	},
	"H104" : {
		"lemma" : "אִגְּרָא",
		"xlit" : "ʼiggᵉrâʼ",
		"pron" : "ig-er-aw'",
		"derivation" : "(Aramaic) of Persian origin",
		"def" : "an epistle (as carried by a state courier or postman)",
		"kjv" : "letter"
	},
	"H105" : {
		"lemma" : "אֲגַרְטָל",
		"xlit" : "ʼăgarṭâl",
		"pron" : "ag-ar-tawl'",
		"derivation" : "of uncertain derivation",
		"def" : "a basin",
		"kjv" : "charger"
	},
	"H106" : {
		"lemma" : "אֶגְרֹף",
		"xlit" : "ʼegrôph",
		"pron" : "eg-rofe'",
		"derivation" : "from H1640 (גָּרַף) (in the sense of grasping)",
		"def" : "the clenched hand",
		"kjv" : "fist"
	},
	"H107" : {
		"lemma" : "אִגֶּרֶת",
		"xlit" : "ʼiggereth",
		"pron" : "ig-eh'-reth",
		"derivation" : "feminine of H104 (אִגְּרָא)",
		"def" : "an epistle",
		"kjv" : "letter"
	},
	"H108" : {
		"lemma" : "אֵד",
		"xlit" : "ʼêd",
		"pron" : "ade",
		"derivation" : "from the same as H181 (אוּד) (in the sense of enveloping)",
		"def" : "a fog",
		"kjv" : "mist, vapor"
	},
	"H109" : {
		"lemma" : "אָדַב",
		"xlit" : "ʼâdab",
		"pron" : "aw-dab'",
		"derivation" : "a primitive root",
		"def" : "to languish",
		"kjv" : "grieve"
	},
	"H110" : {
		"lemma" : "אַדְבְּאֵל",
		"xlit" : "ʼAdbᵉʼêl",
		"pron" : "ad-beh-ale'",
		"derivation" : "probably from H109 (אָדַב) (in the sense of chastisement) and H410 (אֵל); disciplined of God",
		"def" : "Adbeel, a son of Ishmael",
		"kjv" : "Adbeel"
	},
	"H111" : {
		"lemma" : "אֲדַד",
		"xlit" : "ʼĂdad",
		"pron" : "ad-ad'",
		"derivation" : "probably an orthographical variation for H2301 (חֲדַד)",
		"def" : "Adad (or Hadad), an Edomite",
		"kjv" : "Hadad"
	},
	"H112" : {
		"lemma" : "אִדּוֹ",
		"xlit" : "ʼIddôw",
		"pron" : "id-do",
		"derivation" : "of uncertain derivation",
		"def" : "Iddo, an Israelite",
		"kjv" : "Iddo"
	},
	"H113" : {
		"lemma" : "אָדוֹן",
		"xlit" : "ʼâdôwn",
		"pron" : "aw-done'",
		"derivation" : "or (shortened) אָדֹן; from an unused root (meaning to rule)",
		"def" : "sovereign, i.e. controller (human or divine)",
		"kjv" : "lord, master, owner. Compare also names beginning with 'Adoni-'"
	},
	"H114" : {
		"lemma" : "אַדּוֹן",
		"xlit" : "ʼAddôwn",
		"pron" : "ad-done'",
		"derivation" : "probably intensive for H113 (אָדוֹן); powerful",
		"def" : "Addon, apparently an Israelite",
		"kjv" : "Addon"
	},
	"H115" : {
		"lemma" : "אֲדוֹרַיִם",
		"xlit" : "ʼĂdôwrayim",
		"pron" : "ad-o-rah'-yim",
		"derivation" : "dual from H142 (אָדַר) (in the sense of eminence); double mound",
		"def" : "Adorajim, a place in Palestine",
		"kjv" : "Adoraim"
	},
	"H116" : {
		"lemma" : "אֱדַיִן",
		"xlit" : "ʼĕdayin",
		"pron" : "ed-ah'-yin",
		"derivation" : "(Aramaic) of uncertain derivation",
		"def" : "then (of time)",
		"kjv" : "now, that time, then"
	},
	"H117" : {
		"lemma" : "אַדִּיר",
		"xlit" : "ʼaddîyr",
		"pron" : "ad-deer'",
		"derivation" : "from H142 (אָדַר)",
		"def" : "wide or (generally) large; figuratively, powerful",
		"kjv" : "excellent, famous, gallant, glorious, goodly, lordly, mighty(-ier one), noble, principal, worthy"
	},
	"H118" : {
		"lemma" : "אֲדַלְיָא",
		"xlit" : "ʼĂdalyâʼ",
		"pron" : "ad-al-yaw'",
		"derivation" : "of Persian derivation",
		"def" : "Adalja, a son of Haman",
		"kjv" : "Adalia"
	},
	"H119" : {
		"lemma" : "אָדַם",
		"xlit" : "ʼâdam",
		"pron" : "aw-dam'",
		"derivation" : "to show blood (in the face), i.e",
		"def" : "flush or turn rosy",
		"kjv" : "be (dyed, made) red (ruddy)"
	},
	"H120" : {
		"lemma" : "אָדָם",
		"xlit" : "ʼâdâm",
		"pron" : "aw-dawm'",
		"derivation" : "from H119 (אָדַם)",
		"def" : "ruddy i.e. a human being (an individual or the species, mankind, etc.)",
		"kjv" : "[idiom] another, [phrase] hypocrite, [phrase] common sort, [idiom] low, man (mean, of low degree), person"
	},
	"H121" : {
		"lemma" : "אָדָם",
		"xlit" : "ʼÂdâm",
		"pron" : "aw-dawm'",
		"derivation" : "the same as H120 (אָדָם)",
		"def" : "Adam the name of the first man, also of a place in Palestine",
		"kjv" : "Adam"
	},
	"H122" : {
		"lemma" : "אָדֹם",
		"xlit" : "ʼâdôm",
		"pron" : "aw-dome'",
		"derivation" : "from H119 (אָדַם)",
		"def" : "rosy",
		"kjv" : "red, ruddy"
	},
	"H123" : {
		"lemma" : "אֱדֹם",
		"xlit" : "ʼĔdôm",
		"pron" : "ed-ome'",
		"derivation" : "or (fully) אֱדוֹם ; from H122 (אָדֹם); red (see Genesis 25:25)",
		"def" : "Edom, the elder twin-brother of Jacob; hence the region (Idumaea) occupied by him",
		"kjv" : "Edom, Edomites, Idumea"
	},
	"H124" : {
		"lemma" : "אֹדֶם",
		"xlit" : "ʼôdem",
		"pron" : "o'-dem",
		"derivation" : "from H119 (אָדַם)",
		"def" : "redness, i.e. the ruby, garnet, or some other red gem",
		"kjv" : "sardius"
	},
	"H125" : {
		"lemma" : "אֲדַמְדָּם",
		"xlit" : "ʼădamdâm",
		"pron" : "ad-am-dawm'",
		"derivation" : "reduplicated from H119 (אָדַם)",
		"def" : "reddish",
		"kjv" : "(somewhat) reddish"
	},
	"H126" : {
		"lemma" : "אַדְמָה",
		"xlit" : "ʼAdmâh",
		"pron" : "ad-maw'",
		"derivation" : "contracted for H127 (אֲדָמָה); earthy",
		"def" : "Admah, a place near the Dead Sea",
		"kjv" : "Admah"
	},
	"H127" : {
		"lemma" : "אֲדָמָה",
		"xlit" : "ʼădâmâh",
		"pron" : "ad-aw-maw'",
		"derivation" : "from H119 (אָדַם)",
		"def" : "soil (from its general redness)",
		"kjv" : "country, earth, ground, husband(-man) (-ry), land"
	},
	"H128" : {
		"lemma" : "אֲדָמָה",
		"xlit" : "ʼĂdâmâh",
		"pron" : "ad-aw-maw'",
		"derivation" : "the same as H127 (אֲדָמָה)",
		"def" : "Adamah, a place in Palestine",
		"kjv" : "Adamah"
	},
	"H129" : {
		"lemma" : "אֲדָמִי",
		"xlit" : "ʼĂdâmîy",
		"pron" : "ad-aw-mee'",
		"derivation" : "from H127 (אֲדָמָה); earthy",
		"def" : "Adami, a place in Palestine",
		"kjv" : "Adami"
	},
	"H130" : {
		"lemma" : "אֱדֹמִי",
		"xlit" : "ʼĔdômîy",
		"pron" : "ed-o-mee'",
		"derivation" : "or (fully) אֱדוֹמִי ; patronymic from H123 (אֱדֹם); See H726 (אֲרוֹמִי)",
		"def" : "an Edomite, or descendants from (or inhabitants of) Edom",
		"kjv" : "Edomite. "
	},
	"H131" : {
		"lemma" : "אֲדֻמִּים",
		"xlit" : "ʼĂdummîym",
		"pron" : "ad-oom-meem'",
		"derivation" : "plural of H121 (אָדָם); red spots",
		"def" : "Adummim, a pass in Palestine",
		"kjv" : "Adummim"
	},
	"H132" : {
		"lemma" : "אַדְמֹנִי",
		"xlit" : "ʼadmônîy",
		"pron" : "ad-mo-nee'",
		"derivation" : "or (fully) אַדְמוֹנִי ; from H119 (אָדַם)",
		"def" : "reddish (of the hair or the complexion)",
		"kjv" : "red, ruddy"
	},
	"H133" : {
		"lemma" : "אַדְמָתָא",
		"xlit" : "ʼAdmâthâʼ",
		"pron" : "ad-maw-thaw'",
		"derivation" : "probably of Persian derivation",
		"def" : "Admatha, a Persian nobleman",
		"kjv" : "Admatha"
	},
	"H134" : {
		"lemma" : "אֶדֶן",
		"xlit" : "ʼeden",
		"pron" : "eh'-den",
		"derivation" : "from the same as H113 (אָדוֹן) (in the sense of strength)",
		"def" : "a basis (of a building, a column, etc.)",
		"kjv" : "foundation, socket"
	},
	"H135" : {
		"lemma" : "אַדָּן",
		"xlit" : "ʼAddân",
		"pron" : "ad-dawn'",
		"derivation" : "intensive from the same as H134 (אֶדֶן); firm",
		"def" : "Addan, an Israelite",
		"kjv" : "Addan"
	},
	"H136" : {
		"lemma" : "אֲדֹנָי",
		"xlit" : "ʼĂdônây",
		"pron" : "ad-o-noy'",
		"derivation" : "an emphatic form of H113 (אָדוֹן)",
		"def" : "the Lord (used as a proper name of God only)",
		"kjv" : "(my) Lord"
	},
	"H137" : {
		"lemma" : "אֲדֹנִי־בֶזֶק",
		"xlit" : "ʼĂdônîy-Bezeq",
		"pron" : "ad-o''-nee-beh'-zek",
		"derivation" : "from H113 (אָדוֹן) and H966 (בֶּזֶק); lord of Bezek",
		"def" : "Adoni-Bezek; a Canaanitish king",
		"kjv" : "Adonibezek"
	},
	"H138" : {
		"lemma" : "אֲדֹנִיָּה",
		"xlit" : "ʼĂdônîyâh",
		"pron" : "ad-o-nee-yaw'",
		"derivation" : "original (prolonged) אֲדֹנִיָּהוּ ; from H113 (אָדוֹן) and H3050 (יָהּ); lord (i.e. worshipper) of Jah",
		"def" : "Adonijah, the name of three Israelites",
		"kjv" : "Adonijah"
	},
	"H139" : {
		"lemma" : "אֲדֹנִי־צֶדֶק",
		"xlit" : "ʼĂdônîy-Tsedeq",
		"pron" : "ad-o''-nee-tseh'-dek",
		"derivation" : "from H113 (אָדוֹן) and H6664 (צֶדֶק); lord of justice",
		"def" : "Adoni-Tsedek, a Canaanitish king",
		"kjv" : "Adonizedec"
	},
	"H140" : {
		"lemma" : "אֲדֹנִיקָם",
		"xlit" : "ʼĂdônîyqâm",
		"pron" : "ad-o-nee-kawm'",
		"derivation" : "from H113 (אָדוֹן) and H6965 (קוּם); lord of rising (i.e. high)",
		"def" : "Adonikam, the name of one or two Israelites",
		"kjv" : "Adonikam"
	},
	"H141" : {
		"lemma" : "אֲדֹנִירָם",
		"xlit" : "ʼĂdônîyrâm",
		"pron" : "ad-o-nee-rawm'",
		"derivation" : "from H113 (אָדוֹן) and H7311 (רוּם); lord of height",
		"def" : "Adoniram, an Israelite",
		"kjv" : "Adoniram"
	},
	"H142" : {
		"lemma" : "אָדַר",
		"xlit" : "ʼâdar",
		"pron" : "aw-dar'",
		"derivation" : "a primitive root",
		"def" : "to expand, i.e. be great or (figuratively) magnificent",
		"kjv" : "(become) glorious, honourable"
	},
	"H143" : {
		"lemma" : "אֲדָר",
		"xlit" : "ʼĂdâr",
		"pron" : "ad-awr'",
		"derivation" : "probably of foreign derivation; perhaps meaning fire",
		"def" : "Adar, the 12th Hebrew month",
		"kjv" : "Adar"
	},
	"H144" : {
		"lemma" : "אֲדָר",
		"xlit" : "ʼĂdâr",
		"pron" : "ad-awr'",
		"derivation" : "(Aramaic) corresponding to H143 (אֲדָר)",
		"def" : "{Adar, the 12th Hebrew month}",
		"kjv" : "Adar"
	},
	"H145" : {
		"lemma" : "אֶדֶר",
		"xlit" : "ʼeder",
		"pron" : "eh'-der",
		"derivation" : "from H142 (אָדַר)",
		"def" : "amplitude, i.e. (concrete) a mantle; also (figuratively) splendor",
		"kjv" : "goodly, robe"
	},
	"H146" : {
		"lemma" : "אַדָּר",
		"xlit" : "ʼAddâr",
		"pron" : "ad-dawr'",
		"derivation" : "intensive from H142 (אָדַר); ample",
		"def" : "Addar, a place in Palestine; also an Israelite",
		"kjv" : "Addar"
	},
	"H147" : {
		"lemma" : "אִדַּר",
		"xlit" : "ʼiddar",
		"pron" : "id-dar'",
		"derivation" : "(Aramaic) intensive, from a root corresponding to H142 (אָדַר)",
		"def" : "ample, i.e. a threshing-floor",
		"kjv" : "threshingfloor"
	},
	"H148" : {
		"lemma" : "אֲדַרְגָּזֵר",
		"xlit" : "ʼădargâzêr",
		"pron" : "ad-ar''-gaw-zare'",
		"derivation" : "(Aramaic) from the same as H147 (אִדַּר), and H1505 (גְּזַר)",
		"def" : "a chief diviner, or astrologer",
		"kjv" : "judge"
	},
	"H149" : {
		"lemma" : "אַדְרַזְדָּא",
		"xlit" : "ʼadrazdâʼ",
		"pron" : "ad-raz-daw'",
		"derivation" : "(Aramaic) probably of Persian origin",
		"def" : "quickly or carefully",
		"kjv" : "diligently"
	},
	"H150" : {
		"lemma" : "אֲדַרְכֹּן",
		"xlit" : "ʼădarkôn",
		"pron" : "ad-ar-kone'",
		"derivation" : "of Persian origin",
		"def" : "a daric or Persian coin",
		"kjv" : "dram"
	},
	"H151" : {
		"lemma" : "אֲדֹרָם",
		"xlit" : "ʼĂdôrâm",
		"pron" : "ad-o-rawm'",
		"derivation" : "contracted for H141 (אֲדֹנִירָם)",
		"def" : "Adoram (or Adoniram), an Israelite",
		"kjv" : "Adoram"
	},
	"H152" : {
		"lemma" : "אֲדְרַמֶּלֶךְ",
		"xlit" : "ʼĂdrammelek",
		"pron" : "ad-ram-meh'-lek",
		"derivation" : "from H142 (אָדַר) and H4428 (מֶלֶךְ); splendor of (the) king",
		"def" : "Adrammelek, the name of an Assyrian idol, also of a son of Sennacherib",
		"kjv" : "Adrammelech"
	},
	"H153" : {
		"lemma" : "אֶדְרָע",
		"xlit" : "ʼedrâʻ",
		"pron" : "ed-raw'",
		"derivation" : "(Aramaic) an orthographical variation for H1872 (דְּרַע)",
		"def" : "an arm, i.e. (figuratively) power",
		"kjv" : "force"
	},
	"H154" : {
		"lemma" : "אֶדְרֶעִי",
		"xlit" : "ʼedreʻîy",
		"pron" : "ed-reh'-ee",
		"derivation" : "from the equivalent of H153 (אֶדְרָע); mighty",
		"def" : "Edrei, the name of two places in Palestine",
		"kjv" : "Edrei"
	},
	"H155" : {
		"lemma" : "אַדֶּרֶת",
		"xlit" : "ʼaddereth",
		"pron" : "ad-deh'-reth",
		"derivation" : "feminine of H117 (אַדִּיר); ; also the same as H145 (אֶדֶר)",
		"def" : "something ample (as a large vine, a wide dress)",
		"kjv" : "garment, glory, goodly, mantle, robe"
	},
	"H156" : {
		"lemma" : "אָדַשׁ",
		"xlit" : "ʼâdash",
		"pron" : "aw-dash'",
		"derivation" : "a primitive root",
		"def" : "to tread out (grain)",
		"kjv" : "thresh"
	},
	"H157" : {
		"lemma" : "אָהַב",
		"xlit" : "ʼâhab",
		"pron" : "aw-hab'",
		"derivation" : "or אָהֵב ; a primitive root",
		"def" : "to have affection for (sexually or otherwise)",
		"kjv" : "(be-) love(-d, -ly, -r), like, friend"
	},
	"H158" : {
		"lemma" : "אַהַב",
		"xlit" : "ʼahab",
		"pron" : "ah'-hab",
		"derivation" : "from H157 (אָהַב)",
		"def" : "affection (in a good or a bad sense)",
		"kjv" : "love(-r)"
	},
	"H159" : {
		"lemma" : "אֹהַב",
		"xlit" : "ʼôhab",
		"pron" : "o'-hab",
		"derivation" : "from H156 (אָדַשׁ); meaning the same as H158 (אַהַב)",
		"def" : "{affection (in a good or a bad sense)}",
		"kjv" : "love"
	},
	"H160" : {
		"lemma" : "אַהֲבָה",
		"xlit" : "ʼahăbâh",
		"pron" : "a-hab-aw",
		"derivation" : "feminine of H158 (אַהַב) and meaning the same",
		"def" : "{affection (in a good or a bad sense)}",
		"kjv" : "love"
	},
	"H161" : {
		"lemma" : "אֹהַד",
		"xlit" : "ʼÔhad",
		"pron" : "o'-had",
		"derivation" : "from an unused root meaning to be united; unity",
		"def" : "Ohad, an Israelite",
		"kjv" : "Ohad"
	},
	"H162" : {
		"lemma" : "אֲהָהּ",
		"xlit" : "ʼăhâhh",
		"pron" : "a-haw'",
		"derivation" : "apparently a primitive word expressing pain exclamatorily",
		"def" : "Oh!",
		"kjv" : "ah, alas"
	},
	"H163" : {
		"lemma" : "אַהֲוָא",
		"xlit" : "ʼAhăvâʼ",
		"pron" : "a-hav-aw'",
		"derivation" : "probably of foreign origin",
		"def" : "Ahava, a river of Babylonia",
		"kjv" : "Ahava"
	},
	"H164" : {
		"lemma" : "אֵהוּד",
		"xlit" : "ʼÊhûwd",
		"pron" : "ay-hood'",
		"derivation" : "from the same as H161 (אֹהַד); united",
		"def" : "Ehud, the name of two or three Israelites",
		"kjv" : "Ehud"
	},
	"H165" : {
		"lemma" : "אֱהִי",
		"xlit" : "ʼĕhîy",
		"pron" : "e-hee'",
		"derivation" : "apparently an orthographical variation for H346 (אַיֵּה)",
		"def" : "where",
		"kjv" : "I will be (Hosea 13:10,14) (which is often the rendering of the same Hebrew form from H1961 (הָיָה))"
	},
	"H166" : {
		"lemma" : "אָהַל",
		"xlit" : "ʼâhal",
		"pron" : "aw-hal'",
		"derivation" : "a primitive root",
		"def" : "to be clear",
		"kjv" : "shine"
	},
	"H167" : {
		"lemma" : "אָהַל",
		"xlit" : "ʼâhal",
		"pron" : "aw-hal'",
		"derivation" : "a denominative from H168 (אֹהֶל)",
		"def" : "to tent",
		"kjv" : "pitch (remove) a tent"
	},
	"H168" : {
		"lemma" : "אֹהֶל",
		"xlit" : "ʼôhel",
		"pron" : "o'-hel",
		"derivation" : "from H166 (אָהַל)",
		"def" : "a tent (as clearly conspicuous from a distance)",
		"kjv" : "covering, (dwelling) (place), home, tabernacle, tent"
	},
	"H169" : {
		"lemma" : "אֹהֶל",
		"xlit" : "ʼÔhel",
		"pron" : "o'-hel",
		"derivation" : "the same as H168 (אֹהֶל)",
		"def" : "Ohel, an Israelite",
		"kjv" : "Ohel"
	},
	"H170" : {
		"lemma" : "אׇהֳלָה",
		"xlit" : "ʼOhŏlâh",
		"pron" : "o-hol-aw'",
		"derivation" : "in form a feminine of H168 (אֹהֶל), but in fact for אׇהֳלָהּ; from H168 (אֹהֶל); her tent (i.e. idolatrous sanctuary)",
		"def" : "Oholah, a symbolic name for Samaria",
		"kjv" : "Aholah"
	},
	"H171" : {
		"lemma" : "אׇהֳלִיאָב",
		"xlit" : "ʼOhŏlîyʼâb",
		"pron" : "o''-hol-e-awb'",
		"derivation" : "from H168 (אֹהֶל) and H1 (אָב); tent of (his) father",
		"def" : "Oholiab, an Israelite",
		"kjv" : "Aholiab"
	},
	"H172" : {
		"lemma" : "אׇהֳלִיבָה",
		"xlit" : "ʼOhŏlîybâh",
		"pron" : "o''-hol-ee-baw'",
		"derivation" : "(similarly with H170 (אׇהֳלָה)) for אׇהֳלִיבָהּ ; from H168 (אֹהֶל); my tent (is) in her",
		"def" : "Oholibah, a symbolic name for Judah",
		"kjv" : "Aholibah"
	},
	"H173" : {
		"lemma" : "אׇהֳלִיבָמָה",
		"xlit" : "ʼOhŏlîybâmâh",
		"pron" : "o''-hol-ee-baw-maw'",
		"derivation" : "from H168 (אֹהֶל) and H1116 (בָּמָה); tent of (the) height",
		"def" : "Oholibamah, a wife of Esau",
		"kjv" : "Aholibamah"
	},
	"H174" : {
		"lemma" : "אֲהָלִים",
		"xlit" : "ʼăhâlîym",
		"pron" : "a-haw-leem'",
		"derivation" : "or (feminine) אֲהָלוֹת (only used thus in the plural); of foreign origin",
		"def" : "aloe wood (i.e. sticks)",
		"kjv" : "(tree of lign-) aloes"
	},
	"H175" : {
		"lemma" : "אַהֲרוֹן",
		"xlit" : "ʼAhărôwn",
		"pron" : "a-har-one'",
		"derivation" : "of uncertain derivation",
		"def" : "Aharon, the brother of Moses",
		"kjv" : "Aaron"
	},
	"H176" : {
		"lemma" : "אוֹ",
		"xlit" : "ʼôw",
		"pron" : "o",
		"derivation" : "presumed to be the 'constructive' or genitival form of אַו ; short for H185 (אַוָּה)",
		"def" : "desire (and so probably in Proverbs 31:4); hence (by way of alternative) or, also if",
		"kjv" : "also, and, either, if, at the least, [idiom] nor, or, otherwise, then, whether"
	},
	"H177" : {
		"lemma" : "אוּאֵל",
		"xlit" : "ʼÛwʼêl",
		"pron" : "oo-ale'",
		"derivation" : "from H176 (אוֹ) and H410 (אֵל); wish of God",
		"def" : "Uel, and Israelite",
		"kjv" : "Uel"
	},
	"H178" : {
		"lemma" : "אוֹב",
		"xlit" : "ʼôwb",
		"pron" : "obe",
		"derivation" : "from the same as H1 (אָב) (apparently through the idea of prattling a father's name)",
		"def" : "properly, a mumble, i.e. a water skin (from its hollow sound); hence a necromancer (ventriloquist, as from a jar)",
		"kjv" : "bottle, familiar spirit"
	},
	"H179" : {
		"lemma" : "אוֹבִיל",
		"xlit" : "ʼôwbîyl",
		"pron" : "o-beel'",
		"derivation" : "probably from H56 (אָבַל); mournful",
		"def" : "Obil, an Ishmaelite",
		"kjv" : "Obil"
	},
	"H180" : {
		"lemma" : "אוּבָל",
		"xlit" : "ʼûwbâl",
		"pron" : "oo-bawl'",
		"derivation" : "or (shortened) אֻבָל ; from H2986 (יָבַל) (in the sense of H2988 (יָבָל))",
		"def" : "a stream",
		"kjv" : "river"
	},
	"H181" : {
		"lemma" : "אוּד",
		"xlit" : "ʼûwd",
		"pron" : "ood",
		"derivation" : "from an unused root meaning to rake together",
		"def" : "a poker (for turning or gathering embers)",
		"kjv" : "(fire-) brand"
	},
	"H182" : {
		"lemma" : "אוֹדוֹת",
		"xlit" : "ʼôwdôwth",
		"pron" : "o-doth'",
		"derivation" : "or (shortened) אֹדוֹת (only thus in the plural); from the same as H181 (אוּד)",
		"def" : "turnings (i.e. occasions); (adverb) on account of",
		"kjv" : "(be-) cause, concerning, sake"
	},
	"H183" : {
		"lemma" : "אָוָה",
		"xlit" : "ʼâvâh",
		"pron" : "aw-vaw'",
		"derivation" : "a primitive root",
		"def" : "to wish for",
		"kjv" : "covet, (greatly) desire, be desirous, long, lust (after)"
	},
	"H184" : {
		"lemma" : "אָוָה",
		"xlit" : "ʼâvâh",
		"pron" : "aw-vaw'",
		"derivation" : "a primitive root",
		"def" : "to extend or mark out",
		"kjv" : "point out"
	},
	"H185" : {
		"lemma" : "אַוָּה",
		"xlit" : "ʼavvâh",
		"pron" : "av-vaw'",
		"derivation" : "from H183 (אָוָה)",
		"def" : "longing",
		"kjv" : "desire, lust after, pleasure"
	},
	"H186" : {
		"lemma" : "אוּזַי",
		"xlit" : "ʼÛwzay",
		"pron" : "oo-zah'-ee",
		"derivation" : "perhaps by permutation for H5813 (עֻזִּי), strong",
		"def" : "Uzai, an Israelite",
		"kjv" : "Uzai"
	},
	"H187" : {
		"lemma" : "אוּזָל",
		"xlit" : "ʼÛwzâl",
		"pron" : "oo-zawl'",
		"derivation" : "of uncertain derivation",
		"def" : "Uzal, a son of Joktan",
		"kjv" : "Uzal"
	},
	"H188" : {
		"lemma" : "אוֹי",
		"xlit" : "ʼôwy",
		"pron" : "o'-ee",
		"derivation" : "probably from H183 (אָוָה) (in the sense of crying out after)",
		"def" : "lamentation; also interjectionally Oh!",
		"kjv" : "alas, woe"
	},
	"H189" : {
		"lemma" : "אֱוִי",
		"xlit" : "ʼĔvîy",
		"pron" : "ev-ee'",
		"derivation" : "probably from H183 (אָוָה); desirous",
		"def" : "Evi, a Midianitish chief",
		"kjv" : "Evi"
	},
	"H190" : {
		"lemma" : "אוֹיָה",
		"xlit" : "ʼôwyâh",
		"pron" : "o-yaw'",
		"derivation" : "feminine of H188 (אוֹי)",
		"def" : "{lamentation; also interjectionally Oh!}",
		"kjv" : "woe"
	},
	"H191" : {
		"lemma" : "אֱוִיל",
		"xlit" : "ʼĕvîyl",
		"pron" : "ev-eel'",
		"derivation" : "from an unused root (meaning to be perverse)",
		"def" : "(figuratively) silly",
		"kjv" : "fool(-ish) (man)"
	},
	"H192" : {
		"lemma" : "אֱוִיל מְרֹדַךְ",
		"xlit" : "ʼĔvîyl Mᵉrôdak",
		"pron" : "ev-eel' mer-o-dak'",
		"derivation" : "of Aramaic derivation and probably meaning soldier of Merodak",
		"def" : "Evil-Merodak, a Babylonian king",
		"kjv" : "Evil-merodach"
	},
	"H193" : {
		"lemma" : "אוּל",
		"xlit" : "ʼûwl",
		"pron" : "ool",
		"derivation" : "from an unused root meaning to twist, i.e. (by implication) be strong",
		"def" : "the body (as being rolled together); also powerful",
		"kjv" : "mighty, strength"
	},
	"H194" : {
		"lemma" : "אוּלַי",
		"xlit" : "ʼûwlay",
		"pron" : "oo-lah'ee",
		"derivation" : "or (shortened) אֻלַי; from H176 (אוֹ)",
		"def" : "if not; hence perhaps",
		"kjv" : "if so be, may be, peradventure, unless"
	},
	"H195" : {
		"lemma" : "אוּלַי",
		"xlit" : "ʼÛwlay",
		"pron" : "oo-lah'ee",
		"derivation" : "of Persian derivation",
		"def" : "the Ulai (or Eulaeus), a river of Persia",
		"kjv" : "Ulai"
	},
	"H196" : {
		"lemma" : "אֱוִלִי",
		"xlit" : "ʼĕvilîy",
		"pron" : "ev-ee-lee'",
		"derivation" : "from H191 (אֱוִיל)",
		"def" : "silly, foolish; hence (morally) impious",
		"kjv" : "foolish"
	},
	"H197" : {
		"lemma" : "אוּלָם",
		"xlit" : "ʼûwlâm",
		"pron" : "oo-lawm'",
		"derivation" : "or (shortened), אֻלָם ; from H481 (אָלַם) (in the sense of tying)",
		"def" : "a vestibule (as bound to the building)",
		"kjv" : "porch"
	},
	"H198" : {
		"lemma" : "אוּלָם",
		"xlit" : "ʼÛwlâm",
		"pron" : "oo-lawm'",
		"derivation" : "appar, from H481 (אָלַם) (in the sense of dumbness); solitary",
		"def" : "Ulam, the name of two Israelites",
		"kjv" : "Ulam"
	},
	"H199" : {
		"lemma" : "אוּלָם",
		"xlit" : "ʼûwlâm",
		"pron" : "oo-lawm'",
		"derivation" : "apparently a variation of H194 (אוּלַי)",
		"def" : "however or on the contrary",
		"kjv" : "as for, but, howbeit, in very deed, surely, truly, wherefore"
	},
	"H200" : {
		"lemma" : "אִוֶּלֶת",
		"xlit" : "ʼivveleth",
		"pron" : "iv-veh'-leth",
		"derivation" : "from the same as H191 (אֱוִיל)",
		"def" : "silliness",
		"kjv" : "folly, foolishly(-ness)"
	},
	"H201" : {
		"lemma" : "אוֹמָר",
		"xlit" : "ʼÔwmâr",
		"pron" : "o-mawr'",
		"derivation" : "from H559 (אָמַר); talkative",
		"def" : "Omar, a grandson of Esau",
		"kjv" : "Omar"
	},
	"H202" : {
		"lemma" : "אוֹן",
		"xlit" : "ʼôwn",
		"pron" : "one",
		"derivation" : "probably from the same as H205 (אָוֶן) (in the sense of effort, but successful)",
		"def" : "ability, power, (figuratively) wealth",
		"kjv" : "force, goods, might, strength, substance"
	},
	"H203" : {
		"lemma" : "אוֹן",
		"xlit" : "ʼÔwn",
		"pron" : "one",
		"derivation" : "the same as H202 (אוֹן)",
		"def" : "On, an Israelite",
		"kjv" : "On"
	},
	"H204" : {
		"lemma" : "אוֹן",
		"xlit" : "ʼÔwn",
		"pron" : "one",
		"derivation" : "or (shortened); אֹן; of Egyptian derivation",
		"def" : "On, a city of Egypt",
		"kjv" : "On"
	},
	"H205" : {
		"lemma" : "אָוֶן",
		"xlit" : "ʼâven",
		"pron" : "aw-ven'",
		"derivation" : "from an unused root perhaps meaning properly, to pant (hence, to exert oneself, usually in vain; to come to naught)",
		"def" : "strictly nothingness; also trouble. vanity, wickedness; specifically an idol",
		"kjv" : "affliction, evil, false, idol, iniquity, mischief, mourners(-ing), naught, sorrow, unjust, unrighteous, vain, vanity, wicked(-ness). Compare H369 (אַיִן)"
	},
	"H206" : {
		"lemma" : "אָוֶן",
		"xlit" : "ʼÂven",
		"pron" : "aw'-ven",
		"derivation" : "the same as H205 (אָוֶן); idolatry",
		"def" : "Aven, the contemptuous synonym of three places, one in Coele-Syria, one in Egypt (On), and one in Palestine (Bethel)",
		"kjv" : "Aven. See also H204 (אוֹן), H1007 (בֵּית אָוֶן)"
	},
	"H207" : {
		"lemma" : "אוֹנוֹ",
		"xlit" : "ʼÔwnôw",
		"pron" : "o-no'",
		"derivation" : "or (shortened) אֹנוֹ; prolonged from H202 (אוֹן); strong",
		"def" : "Ono, a place in Palestine",
		"kjv" : "Ono"
	},
	"H208" : {
		"lemma" : "אוֹנָם",
		"xlit" : "ʼÔwnâm",
		"pron" : "o-nawm'",
		"derivation" : "a variation of H209 (אוֹנָן); strong",
		"def" : "Onam, the name of an Edomite and of an Israelite",
		"kjv" : "Onam"
	},
	"H209" : {
		"lemma" : "אוֹנָן",
		"xlit" : "ʼÔwnân",
		"pron" : "o-nawn'",
		"derivation" : "a variation of H207 (אוֹנוֹ); strong",
		"def" : "Onan, a son of Judah",
		"kjv" : "Onan"
	},
	"H210" : {
		"lemma" : "אוּפָז",
		"xlit" : "ʼÛwphâz",
		"pron" : "oo-fawz'",
		"derivation" : "perhaps a corruption of H211 (אוֹפִיר)",
		"def" : "Uphaz, a famous gold region",
		"kjv" : "Uphaz"
	},
	"H211" : {
		"lemma" : "אוֹפִיר",
		"xlit" : "ʼÔwphîyr",
		"pron" : "o-feer'",
		"derivation" : "or (shortened) אֹפִיר; and אוֹפִר; of uncertain derivation",
		"def" : "Ophir, the name of a son of Joktan, and of a gold region in the East",
		"kjv" : "Ophir"
	},
	"H212" : {
		"lemma" : "אוֹפָן",
		"xlit" : "ʼôwphân",
		"pron" : "o-fawn'",
		"derivation" : "or (shortened) אֹפָן; from an unused root meaning to revolve",
		"def" : "a wheel",
		"kjv" : "wheel"
	},
	"H213" : {
		"lemma" : "אוּץ",
		"xlit" : "ʼûwts",
		"pron" : "oots",
		"derivation" : "a primitive root",
		"def" : "to press; (by implication) to be close, hurry, withdraw",
		"kjv" : "(make) haste(-n, -y), labor, be narrow"
	},
	"H214" : {
		"lemma" : "אוֹצָר",
		"xlit" : "ʼôwtsâr",
		"pron" : "o-tsaw'",
		"derivation" : "from H686 (אָצַר)",
		"def" : "a depository",
		"kjv" : "armory, cellar, garner, store(-house), treasure(-house) (-y)"
	},
	"H215" : {
		"lemma" : "אוֹר",
		"xlit" : "ʼôwr",
		"pron" : "ore",
		"derivation" : "a primitive root",
		"def" : "to be (causative, make) luminous (literally and metaphorically)",
		"kjv" : "[idiom] break of day, glorious, kindle, (be, en-, give, show) light (-en, -ened), set on fire, shine"
	},
	"H216" : {
		"lemma" : "אוֹר",
		"xlit" : "ʼôwr",
		"pron" : "ore",
		"derivation" : "from H215 (אוֹר)",
		"def" : "illumination or (concrete) luminary (in every sense, including lightning, happiness, etc.)",
		"kjv" : "bright, clear, [phrase] day, light (-ning), morning, sun"
	},
	"H217" : {
		"lemma" : "אוּר",
		"xlit" : "ʼûwr",
		"pron" : "ore",
		"derivation" : "from H215 (אוֹר)",
		"def" : "flame; hence (in the plural) the East (as being the region of light)",
		"kjv" : "fire, light. See also H224 (אוּרִים)"
	},
	"H218" : {
		"lemma" : "אוּר",
		"xlit" : "ʼÛwr",
		"pron" : "oor",
		"derivation" : "the same as H217 (אוּר)",
		"def" : "Ur, a place in Chaldaea; also an Israelite",
		"kjv" : "Ur"
	},
	"H219" : {
		"lemma" : "אוֹרָה",
		"xlit" : "ʼôwrâh",
		"pron" : "o-raw'",
		"derivation" : "feminine of H216 (אוֹר)",
		"def" : "luminousness, i.e. (figuratively) prosperity; also a plant (as being bright)",
		"kjv" : "herb, light"
	},
	"H220" : {
		"lemma" : "אֲוֵרָה",
		"xlit" : "ʼăvêrâh",
		"pron" : "av-ay-raw'",
		"derivation" : "by transposition for H723 (אֻרְוָה)",
		"def" : "a stall",
		"kjv" : "cote"
	},
	"H221" : {
		"lemma" : "אוּרִי",
		"xlit" : "ʼÛwrîy",
		"pron" : "oo-ree'",
		"derivation" : "from H217 (אוּר); fiery",
		"def" : "Uri, the name of three Israelites",
		"kjv" : "Uri"
	},
	"H222" : {
		"lemma" : "אוּרִיאֵל",
		"xlit" : "ʼÛwrîyʼêl",
		"pron" : "oo-ree-ale'",
		"derivation" : "from H217 (אוּר) and H410 (אֵל); flame of God",
		"def" : "Uriel, the name of two Israelites",
		"kjv" : "Uriel"
	},
	"H223" : {
		"lemma" : "אוּרִיָּה",
		"xlit" : "ʼÛwrîyâh",
		"pron" : "oo-ree-yaw'",
		"derivation" : "or (prolonged) אוּרִיָּהוּ ; from H217 (אוּר) and H3050 (יָהּ); flame of Jah",
		"def" : "Urijah, the name of one Hittite and five Israelites",
		"kjv" : "Uriah, Urijah"
	},
	"H224" : {
		"lemma" : "אוּרִים",
		"xlit" : "ʼÛwrîym",
		"pron" : "oo-reem'",
		"derivation" : "plural of H217 (אוּר); lights",
		"def" : "Urim, the oracular brilliancy of the figures in the high-priest's breastplate",
		"kjv" : "Urim"
	},
	"H225" : {
		"lemma" : "אוּת",
		"xlit" : "ʼûwth",
		"pron" : "ooth",
		"derivation" : "a primitive root",
		"def" : "properly, to come, i.e. (implied) to assent",
		"kjv" : "consent"
	},
	"H226" : {
		"lemma" : "אוֹת",
		"xlit" : "ʼôwth",
		"pron" : "oth",
		"derivation" : "probably from H225 (אוּת) (in the sense of appearing)",
		"def" : "a signal (literally or figuratively), as aflag, beacon, monument, omen, prodigy, evidence, etc.",
		"kjv" : "mark, miracle, (en-) sign, token"
	},
	"H227" : {
		"lemma" : "אָז",
		"xlit" : "ʼâz",
		"pron" : "awz",
		"derivation" : "a demonstrative adverb",
		"def" : "at that time or place; also as a conjunction, therefore",
		"kjv" : "beginning, for, from, hitherto, now, of old, once, since, then, at which time, yet"
	},
	"H228" : {
		"lemma" : "אֲזָא",
		"xlit" : "ʼăzâʼ",
		"pron" : "az-zaw'",
		"derivation" : "(Aramaic) or אֲזָה (Aramaic)",
		"def" : "to kindle; (by implication) to heat",
		"kjv" : "heat, hot"
	},
	"H229" : {
		"lemma" : "אֶזְבַּי",
		"xlit" : "ʼEzbay",
		"pron" : "ez-bah'ee",
		"derivation" : "probably from H231 (אֵזוֹב); hyssop-like",
		"def" : "Ezbai, an Israelite",
		"kjv" : "Ezbai"
	},
	"H230" : {
		"lemma" : "אֲזָד",
		"xlit" : "ʼăzâd",
		"pron" : "az-zawd'",
		"derivation" : "(Aramaic) of uncertain derivation",
		"def" : "firm",
		"kjv" : "be gone"
	},
	"H231" : {
		"lemma" : "אֵזוֹב",
		"xlit" : "ʼêzôwb",
		"pron" : "ay-zobe'",
		"derivation" : "probably of foreign derivation",
		"def" : "hyssop",
		"kjv" : "hyssop"
	},
	"H232" : {
		"lemma" : "אֵזוֹר",
		"xlit" : "ʼêzôwr",
		"pron" : "ay-zore'",
		"derivation" : "from H246 (אֲזִקִּים)",
		"def" : "something girt; a belt, also a band",
		"kjv" : "girdle"
	},
	"H233" : {
		"lemma" : "אֲזַי",
		"xlit" : "ʼăzay",
		"pron" : "az-ah'ee",
		"derivation" : "probably from H227 (אָז)",
		"def" : "at that time",
		"kjv" : "then"
	},
	"H234" : {
		"lemma" : "אַזְכָּרָה",
		"xlit" : "ʼazkârâh",
		"pron" : "az-kaw-raw'",
		"derivation" : "from H2142 (זָכַר)",
		"def" : "a reminder; specifically remembrance-offering",
		"kjv" : "memorial"
	},
	"H235" : {
		"lemma" : "אָזַל",
		"xlit" : "ʼâzal",
		"pron" : "aw-zal'",
		"derivation" : "a primitive root",
		"def" : "to go away, hence, to disappear",
		"kjv" : "fail, gad about, go to and fro (but in Ezekiel 27:19 the word is rendered by many 'from Uzal,' by others 'yarn'), be gone (spent)"
	},
	"H236" : {
		"lemma" : "אֲזַל",
		"xlit" : "ʼăzal",
		"pron" : "az-al'",
		"derivation" : "(Aramaic) the same as H235 (אָזַל)",
		"def" : "to depart",
		"kjv" : "go (up)"
	},
	"H237" : {
		"lemma" : "אֶזֶל",
		"xlit" : "ʼezel",
		"pron" : "eh'-zel",
		"derivation" : "from H235 (אָזַל); departure",
		"def" : "Ezel, a memorial stone in Palestine",
		"kjv" : "Ezel"
	},
	"H238" : {
		"lemma" : "אָזַן",
		"xlit" : "ʼâzan",
		"pron" : "aw-zan'",
		"derivation" : "a primitive root; probably to expand; but used only as a denominative from H241 (אֹזֶן)",
		"def" : "to broaden out the ear (with the hand), i.e. (by implication) to listen",
		"kjv" : "give (perceive by the) ear, hear(-ken). See H239 (אָזַן)"
	},
	"H239" : {
		"lemma" : "אָזַן",
		"xlit" : "ʼâzan",
		"pron" : "aw-zan'",
		"derivation" : "a primitive root (rather identical with H238 (אָזַן) through the idea of scales as if two ears)",
		"def" : "to weigh, i.e. (figuratively) ponder",
		"kjv" : "give good heed"
	},
	"H240" : {
		"lemma" : "אָזֵן",
		"xlit" : "ʼâzên",
		"pron" : "aw-zane'",
		"derivation" : "from H238 (אָזַן)",
		"def" : "a spade or paddle (as having a broad end)",
		"kjv" : "weapon"
	},
	"H241" : {
		"lemma" : "אֹזֶן",
		"xlit" : "ʼôzen",
		"pron" : "o'-zen",
		"derivation" : "from H238 (אָזַן)",
		"def" : "broadness. i.e. (concrete) the ear (from its form in man)",
		"kjv" : "[phrase] advertise, audience, [phrase] displease, ear, hearing, [phrase] show"
	},
	"H242" : {
		"lemma" : "אֻזֵּן שֶׁאֱרָה",
		"xlit" : "ʼUzzên Sheʼĕrâh",
		"pron" : "ooz-zane' sheh-er-aw'",
		"derivation" : "from H238 (אָזַן) and H7609 (שֶׁאֱרָה); plot of Sheerah (i.e. settled by him)",
		"def" : "Uzzen-Sheerah, a place in Palestine",
		"kjv" : "Uzzen-sherah"
	},
	"H243" : {
		"lemma" : "אַזְנוֹת תָּבוֹר",
		"xlit" : "ʼAznôwth Tâbôwr",
		"pron" : "az-noth' taw-bore'",
		"derivation" : "from H238 (אָזַן) and H8396 (תָּבוֹר); flats (i.e. tops) of Tabor (i.e. situated on it)",
		"def" : "Aznoth-Tabor, a place in Palestine",
		"kjv" : "Aznoth-tabor"
	},
	"H244" : {
		"lemma" : "אׇזְנִי",
		"xlit" : "ʼOznîy",
		"pron" : "oz-nee'",
		"derivation" : "from H241 (אֹזֶן); having (quick) ears",
		"def" : "Ozni, an Israelite; also an Oznite (collectively), his descendant",
		"kjv" : "Ozni, Oznites"
	},
	"H245" : {
		"lemma" : "אֲזַנְיָה",
		"xlit" : "ʼĂzanyâh",
		"pron" : "az-an-yaw'",
		"derivation" : "from H238 (אָזַן) and H3050 (יָהּ); heard by Jah",
		"def" : "Azanjah, an Israelite",
		"kjv" : "Azaniah"
	},
	"H246" : {
		"lemma" : "אֲזִקִּים",
		"xlit" : "ʼăziqqîym",
		"pron" : "az-ik-keem'",
		"derivation" : "a variation for H2131 (זִיקָה)",
		"def" : "manacles",
		"kjv" : "chains"
	},
	"H247" : {
		"lemma" : "אָזַר",
		"xlit" : "ʼâzar",
		"pron" : "aw-zar'",
		"derivation" : "a primitive root",
		"def" : "to belt",
		"kjv" : "bind (compass) about, gird (up, with)"
	},
	"H248" : {
		"lemma" : "אֶזְרוֹעַ",
		"xlit" : "ʼezrôwaʻ",
		"pron" : "ez-ro'-a",
		"derivation" : "a variation for H2220 (זְרוֹעַ)",
		"def" : "the arm",
		"kjv" : "arm"
	},
	"H249" : {
		"lemma" : "אֶזְרָח",
		"xlit" : "ʼezrâch",
		"pron" : "ez-rawkh'",
		"derivation" : "from H2224 (זָרַח) (in the sense of springing up)",
		"def" : "a spontaneous growth, i.e. native (tree or persons)",
		"kjv" : "bay tree, (home-) born (in the land), of the (one's own) country (nation)"
	},
	"H250" : {
		"lemma" : "אֶזְרָחִי",
		"xlit" : "ʼEzrâchîy",
		"pron" : "ez-raw-khee'",
		"derivation" : "patronymic from H2246 (חֹבָב)",
		"def" : "an Ezrachite or descendant of Zerach",
		"kjv" : "Ezrahite"
	},
	"H251" : {
		"lemma" : "אָח",
		"xlit" : "ʼâch",
		"pron" : "awkh",
		"derivation" : "a primitive word",
		"def" : "a brother (used in the widest sense of literal relationship and metaphorical affinity or resemblance (like father))",
		"kjv" : "another, brother(-ly); kindred, like, other. Compare also the proper names beginning with 'Ah-' or 'Ahi-'"
	},
	"H252" : {
		"lemma" : "אַח",
		"xlit" : "ʼach",
		"pron" : "akh",
		"derivation" : "(Aramaic) corresponding to H251 (אָח)",
		"def" : "{a brother (used in the widest sense of literal relationship and metaphorical affinity or resemblance)}",
		"kjv" : "brother"
	},
	"H253" : {
		"lemma" : "אָח",
		"xlit" : "ʼâch",
		"pron" : "awkh",
		"derivation" : "a variation for H162 (אֲהָהּ)",
		"def" : "Oh! (expressive of grief or surprise)",
		"kjv" : "ah, alas"
	},
	"H254" : {
		"lemma" : "אָח",
		"xlit" : "ʼâch",
		"pron" : "awkh",
		"derivation" : "of uncertain derivation",
		"def" : "a fire-pot or chafing dish",
		"kjv" : "hearth"
	},
	"H255" : {
		"lemma" : "אֹחַ",
		"xlit" : "ʼôach",
		"pron" : "o'-akh",
		"derivation" : "probably from H253 (אָח)",
		"def" : "a howler or lonesome wild animal",
		"kjv" : "doleful creature"
	},
	"H256" : {
		"lemma" : "אַחְאָב",
		"xlit" : "ʼAchʼâb",
		"pron" : "akh-awb'",
		"derivation" : "once (by contraction) אֶחָב (Jeremiah 29:22); from H251 (אָח) and H1 (אָב); brother (i.e. friend) of (his) father",
		"def" : "Achab, the name of a king of Israel and of a prophet at Babylon",
		"kjv" : "Ahab"
	},
	"H257" : {
		"lemma" : "אַחְבָן",
		"xlit" : "ʼAchbân",
		"pron" : "akh-bawn'",
		"derivation" : "from H251 (אָח) and H995 (בִּין); brother (i.e. possessor) of understanding",
		"def" : "Achban, an Israelite",
		"kjv" : "Ahban"
	},
	"H258" : {
		"lemma" : "אָחַד",
		"xlit" : "ʼâchad",
		"pron" : "aw-khad'",
		"derivation" : "perhaps a primitive root",
		"def" : "to unify, i.e. (figuratively) collect (one's thoughts)",
		"kjv" : "go one way or other"
	},
	"H259" : {
		"lemma" : "אֶחָד",
		"xlit" : "ʼechâd",
		"pron" : "ekh-awd'",
		"derivation" : "a numeral from H258 (אָחַד)",
		"def" : "properly, united, i.e. one; or (as an ordinal) first",
		"kjv" : "a, alike, alone, altogether, and, any(-thing), apiece, a certain, (dai-) ly, each (one), [phrase] eleven, every, few, first, [phrase] highway, a man, once, one, only, other, some, together"
	},
	"H260" : {
		"lemma" : "אָחוּ",
		"xlit" : "ʼâchûw",
		"pron" : "aw'-khoo",
		"derivation" : "of uncertain (perhaps Egyptian) derivation",
		"def" : "a bulrush or any marshy grass (particularly that along the Nile)",
		"kjv" : "flag, meadow"
	},
	"H261" : {
		"lemma" : "אֵחוּד",
		"xlit" : "ʼÊchûwd",
		"pron" : "ay-khood'",
		"derivation" : "from H258 (אָחַד); united",
		"def" : "Echud, the name of three Israelites",
		"kjv" : "Ehud"
	},
	"H262" : {
		"lemma" : "אַחְוָה",
		"xlit" : "ʼachvâh",
		"pron" : "akh-vaw'",
		"derivation" : "from H2331 (חָוָה) (in the sense of H2324 (חֲוָא))",
		"def" : "an utterance",
		"kjv" : "declaration"
	},
	"H263" : {
		"lemma" : "אַחֲוָה",
		"xlit" : "ʼachăvâh",
		"pron" : "akh-av-aw'",
		"derivation" : "(Aramaic) corresponding to H262 (אַחְוָה)",
		"def" : "solution (of riddles)",
		"kjv" : "showing"
	},
	"H264" : {
		"lemma" : "אַחֲוָה",
		"xlit" : "ʼachăvâh",
		"pron" : "akh-av-aw'",
		"derivation" : "from H251 (אָח)",
		"def" : "fraternity",
		"kjv" : "brotherhood"
	},
	"H265" : {
		"lemma" : "אֲחוֹחַ",
		"xlit" : "ʼĂchôwach",
		"pron" : "akh-o'-akh",
		"derivation" : "by reduplication from H251 (אָח); brotherly",
		"def" : "Achoach, an Israelite",
		"kjv" : "Ahoah"
	},
	"H266" : {
		"lemma" : "אֲחוֹחִי",
		"xlit" : "ʼĂchôwchîy",
		"pron" : "akh-o-khee'",
		"derivation" : "patronymic from H264 (אַחֲוָה)",
		"def" : "an Achochite or descendant of Achoach",
		"kjv" : "Ahohite"
	},
	"H267" : {
		"lemma" : "אֲחוּמַי",
		"xlit" : "ʼĂchûwmay",
		"pron" : "akh-oo-mah'-ee",
		"derivation" : "perhaps from H251 (אָח) and H4325 (מַיִם); brother (i.e. neighbour) of water",
		"def" : "Achumai, an Israelite",
		"kjv" : "Ahumai"
	},
	"H268" : {
		"lemma" : "אָחוֹר",
		"xlit" : "ʼâchôwr",
		"pron" : "aw-khore'",
		"derivation" : "or (shortened) אָחֹר ; from H299 (אֲחִירַע)",
		"def" : "the hinder part; hence (adverb) behind, backward; also (as facing north) the West",
		"kjv" : "after(-ward), back (part, -side, -ward), hereafter, (be-) hind(-er part), time to come, without"
	},
	"H269" : {
		"lemma" : "אָחוֹת",
		"xlit" : "ʼâchôwth",
		"pron" : "aw-khoth'",
		"derivation" : "irregular feminine of H251 (אָח)",
		"def" : "a sister (used very widely (like brother), literally and figuratively)",
		"kjv" : "(an-) other, sister, together"
	},
	"H270" : {
		"lemma" : "אָחַז",
		"xlit" : "ʼâchaz",
		"pron" : "aw-khaz'",
		"derivation" : "a primitive root",
		"def" : "to seize (often with the accessory idea of holding in possession)",
		"kjv" : "[phrase] be affrighted, bar, (catch, lay, take) hold (back), come upon, fasten, handle, portion, (get, have or take) possess(-ion)"
	},
	"H271" : {
		"lemma" : "אָחָז",
		"xlit" : "ʼÂchâz",
		"pron" : "aw-khawz'",
		"derivation" : "from H270 (אָחַז); possessor",
		"def" : "Achaz, the name of a Jewish king and of an Israelite",
		"kjv" : "Ahaz"
	},
	"H272" : {
		"lemma" : "אֲחֻזָּה",
		"xlit" : "ʼăchuzzâh",
		"pron" : "akh-ooz-zaw'",
		"derivation" : "feminine passive participle from H270 (אָחַז)",
		"def" : "something seized, i.e. a possession (especially of land)",
		"kjv" : "possession"
	},
	"H273" : {
		"lemma" : "אַחְזַי",
		"xlit" : "ʼAchzay",
		"pron" : "akh-zah'ee",
		"derivation" : "from H270 (אָחַז); seizer",
		"def" : "Achzai, an Israelite",
		"kjv" : "Ahasai"
	},
	"H274" : {
		"lemma" : "אֲחַזְיָה",
		"xlit" : "ʼĂchazyâh",
		"pron" : "akh-az-yaw'",
		"derivation" : "or (prolonged) אֲחַזְיָהוּ ; from H270 (אָחַז) and H3050 (יָהּ); Jah has seized",
		"def" : "Achazjah, the name of a Jewish and an Israelite king",
		"kjv" : "Ahaziah"
	},
	"H275" : {
		"lemma" : "אֲחֻזָּם",
		"xlit" : "ʼĂchuzzâm",
		"pron" : "akh-ooz-zawm'",
		"derivation" : "from H270 (אָחַז); seizure",
		"def" : "Achuzzam, an Israelite",
		"kjv" : "Ahuzam"
	},
	"H276" : {
		"lemma" : "אֲחֻזַּת",
		"xlit" : "ʼĂchuzzath",
		"pron" : "akh-ooz-zath'",
		"derivation" : "a variation of H272 (אֲחֻזָּה); possession",
		"def" : "Achuzzath, a Philistine",
		"kjv" : "Ahuzzath"
	},
	"H277" : {
		"lemma" : "אֲחִי",
		"xlit" : "ʼĂchîy",
		"pron" : "akh-ee'",
		"derivation" : "from H251 (אָח); brotherly",
		"def" : "Achi, the name of two Israelites",
		"kjv" : "Ahi"
	},
	"H278" : {
		"lemma" : "אֵחִי",
		"xlit" : "ʼÊchîy",
		"pron" : "ay-khee'",
		"derivation" : "probably the same as H277 (אֲחִי)",
		"def" : "Echi, an Israelite",
		"kjv" : "Ehi"
	},
	"H279" : {
		"lemma" : "אֲחִיאָם",
		"xlit" : "ʼĂchîyʼâm",
		"pron" : "akh-ee-awm'",
		"derivation" : "from H251 (אָח) and H517 (אֵם); brother of the mother (i.e. uncle)",
		"def" : "Achiam, an Israelite",
		"kjv" : "Ahiam"
	},
	"H280" : {
		"lemma" : "אֲחִידָה",
		"xlit" : "ʼăchîydâh",
		"pron" : "akh-ee-daw'",
		"derivation" : "(Aramaic) corresponding to H2420 (חִידָה)",
		"def" : "an enigma",
		"kjv" : "hard sentence"
	},
	"H281" : {
		"lemma" : "אֲחִיָּה",
		"xlit" : "ʼĂchîyâh",
		"pron" : "akh-ee-yaw",
		"derivation" : "or (prolonged) אֲחִיָּהוּ ; from H251 (אָח) and H3050 (יָהּ); brother (i.e. worshipper) of Jah",
		"def" : "Achijah, the name of nine Israelites",
		"kjv" : "Ahiah, Ahijah"
	},
	"H282" : {
		"lemma" : "אֲחִיהוּד",
		"xlit" : "ʼĂchîyhûwd",
		"pron" : "akh-ee-hood'",
		"derivation" : "from H251 (אָח) and H1935 (הוֹד); brother (i.e. possessor) of renown",
		"def" : "Achihud, an Israelite",
		"kjv" : "Ahihud"
	},
	"H283" : {
		"lemma" : "אַחְיוֹ",
		"xlit" : "ʼAchyôw",
		"pron" : "akh-yo'",
		"derivation" : "prolonged from H251 (אָח); brotherly",
		"def" : "Achio, the name of three Israelites",
		"kjv" : "Ahio"
	},
	"H284" : {
		"lemma" : "אֲחִיחֻד",
		"xlit" : "ʼĂchîychud",
		"pron" : "akh-ee-khood'",
		"derivation" : "from H251 (אָח) and H2330 (חוּד); brother of a riddle (i.e. mysterious)",
		"def" : "Achichud, an Israelite",
		"kjv" : "Ahihud"
	},
	"H285" : {
		"lemma" : "אֲחִיטוּב",
		"xlit" : "ʼĂchîyṭûwb",
		"pron" : "akh-ee-toob'",
		"derivation" : "from H251 (אָח) and H2898 (טוּב); brother of goodness",
		"def" : "Achitub, the name of several priests",
		"kjv" : "Ahitub"
	},
	"H286" : {
		"lemma" : "אֲחִילוּד",
		"xlit" : "ʼĂchîylûwd",
		"pron" : "akh-ee-lood'",
		"derivation" : "from H251 (אָח) and H3205 (יָלַד); brother of one born",
		"def" : "Achilud, an Israelite",
		"kjv" : "Ahilud"
	},
	"H287" : {
		"lemma" : "אֲחִימוֹת",
		"xlit" : "ʼĂchîymôwth",
		"pron" : "akh-ee-moth'",
		"derivation" : "from H251 (אָח) and H4191 (מוּת); brother of death",
		"def" : "Achimoth, an Israelite",
		"kjv" : "Ahimoth"
	},
	"H288" : {
		"lemma" : "אֲחִימֶלֶךְ",
		"xlit" : "ʼĂchîymelek",
		"pron" : "akh-ee-meh'-lek",
		"derivation" : "from H251 (אָח) and H4428 (מֶלֶךְ); brother of (the) king",
		"def" : "Achimelek, the name of an Israelite and of a Hittite",
		"kjv" : "Ahimelech"
	},
	"H289" : {
		"lemma" : "אֲחִימַן",
		"xlit" : "ʼĂchîyman",
		"pron" : "akh-ee-man'",
		"derivation" : "or אֲחִימָן ; from H251 (אָח) and H4480 (מִן); brother of a portion (i.e. gift)",
		"def" : "Achiman, the name of an Anakite and of an Israelite",
		"kjv" : "Ahiman"
	},
	"H290" : {
		"lemma" : "אֲחִימַעַץ",
		"xlit" : "ʼĂchîymaʻats",
		"pron" : "akh-ee-mah'-ats",
		"derivation" : "from H251 (אָח) and the equivalent of H4619 (מַעַץ); brother of anger",
		"def" : "Achimaats, the name of three Israelites",
		"kjv" : "Ahimaaz"
	},
	"H291" : {
		"lemma" : "אַחְיָן",
		"xlit" : "ʼAchyân",
		"pron" : "akh-yawn'",
		"derivation" : "from H251 (אָח); brotherly",
		"def" : "Achjan, an Israelite",
		"kjv" : "Ahian"
	},
	"H292" : {
		"lemma" : "אֲחִינָדָב",
		"xlit" : "ʼĂchîynâdâb",
		"pron" : "akh-ee-naw-dawb'",
		"derivation" : "from H251 (אָח) and H5068 (נָדַב); brother of liberality",
		"def" : "Achinadab, an Israelite",
		"kjv" : "Ahinadab"
	},
	"H293" : {
		"lemma" : "אֲחִינֹעַם",
		"xlit" : "ʼĂchîynôʻam",
		"pron" : "akh-ee-no'-am",
		"derivation" : "from H251 (אָח) and H5278 (נֹעַם); brother of pleasantness",
		"def" : "Achinoam, the name of two Israelitesses",
		"kjv" : "Ahinoam"
	},
	"H294" : {
		"lemma" : "אֲחִיסָמָךְ",
		"xlit" : "ʼĂchîyçâmâk",
		"pron" : "akh-ee-saw-mawk'",
		"derivation" : "from H251 (אָח) and H5564 (סָמַךְ); brother of support",
		"def" : "Achisamak, an Israelite",
		"kjv" : "Ahisamach"
	},
	"H295" : {
		"lemma" : "אֲחִיעֶזֶר",
		"xlit" : "ʼĂchîyʻezer",
		"pron" : "akh-ee-eh'-zer",
		"derivation" : "from H251 (אָח) and H5828 (עֵזֶר); brother of help",
		"def" : "Achiezer, the name of two Israelites",
		"kjv" : "Ahiezer"
	},
	"H296" : {
		"lemma" : "אֲחִיקָם",
		"xlit" : "ʼĂchîyqâm",
		"pron" : "akh-ee-kawm'",
		"derivation" : "from H251 (אָח) and H6965 (קוּם); brother of rising (i.e. high)",
		"def" : "Achikam, an Israelite",
		"kjv" : "Ahikam"
	},
	"H297" : {
		"lemma" : "אֲחִירָם",
		"xlit" : "ʼĂchîyrâm",
		"pron" : "akh-ee-rawm'",
		"derivation" : "from H251 (אָח) and H7311 (רוּם); brother of height (i.e. high)",
		"def" : "Achiram, an Israelite",
		"kjv" : "Ahiram"
	},
	"H298" : {
		"lemma" : "אֲחִירָמִי",
		"xlit" : "ʼĂchîyrâmîy",
		"pron" : "akh-ee-raw-mee'",
		"derivation" : "patronymic from H297 (אֲחִירָם)",
		"def" : "an Achiramite or descendant (collectively) of Achiram",
		"kjv" : "Ahiramites"
	},
	"H299" : {
		"lemma" : "אֲחִירַע",
		"xlit" : "ʼĂchîyraʻ",
		"pron" : "akh-ee-rah'",
		"derivation" : "from H251 (אָח) and H7451 (רַע); brother of wrong",
		"def" : "Achira, an Israelite",
		"kjv" : "Ahira"
	},
	"H300" : {
		"lemma" : "אֲחִישַׁחַר",
		"xlit" : "ʼĂchîyshachar",
		"pron" : "akh-ee-shakh'-ar",
		"derivation" : "from H251 (אָח) and H7837 (שַׁחַר); brother of (the) dawn",
		"def" : "Achishachar, an Israelite",
		"kjv" : "Ahishar"
	},
	"H301" : {
		"lemma" : "אֲחִישָׁר",
		"xlit" : "ʼĂchîyshâr",
		"pron" : "akh-ee-shawr'",
		"derivation" : "from H251 (אָח) and H7891 (שִׁיר); brother of (the) singer",
		"def" : "Achishar, an Israelite",
		"kjv" : "Ahishar"
	},
	"H302" : {
		"lemma" : "אֲחִיתֹפֶל",
		"xlit" : "ʼĂchîythôphel",
		"pron" : "akh-ee-tho'-fel",
		"derivation" : "from H251 (אָח) and H8602 (תָּפֵל); brother of folly",
		"def" : "Achithophel, an Israelite",
		"kjv" : "Ahithophel"
	},
	"H303" : {
		"lemma" : "אַחְלָב",
		"xlit" : "ʼAchlâb",
		"pron" : "akh-lawb'",
		"derivation" : "from the same root as H2459 (חֶלֶב); fatness (i.e. fertile)",
		"def" : "Achlab, a place in Palestine",
		"kjv" : "Ahlab"
	},
	"H304" : {
		"lemma" : "אַחְלַי",
		"xlit" : "ʼAchlay",
		"pron" : "akh-lah'ee",
		"derivation" : "the same as H305 (אַחֲלַי); wishful",
		"def" : "Achlai, the name of an Israelitess and of an Israelite",
		"kjv" : "Ahlai"
	},
	"H305" : {
		"lemma" : "אַחֲלַי",
		"xlit" : "ʼachălay",
		"pron" : "akh-al-ah'ee",
		"derivation" : "or אַחֲלֵי; prob from H253 (אָח) and a variation of H3863 (לוּא)",
		"def" : "would that!",
		"kjv" : "O that, would God"
	},
	"H306" : {
		"lemma" : "אַחְלָמָה",
		"xlit" : "ʼachlâmâh",
		"pron" : "akh-law'-maw",
		"derivation" : "perhaps from H2492 (חָלַם) (and thus dream-stone)",
		"def" : "a gem, probably the amethyst",
		"kjv" : "amethyst"
	},
	"H307" : {
		"lemma" : "אַחְמְתָא",
		"xlit" : "ʼAchmᵉthâʼ",
		"pron" : "akh-me-thaw'",
		"derivation" : "of Persian derivation",
		"def" : "Achmetha (i.e. Ecbatana), the summer capital of Persia",
		"kjv" : "Achmetha"
	},
	"H308" : {
		"lemma" : "אֲחַסְבַּי",
		"xlit" : "ʼĂchaçbay",
		"pron" : "akh-as-bah'ee",
		"derivation" : "of uncertain derivation",
		"def" : "Achasbai, an Israelite",
		"kjv" : "Ahasbai"
	},
	"H309" : {
		"lemma" : "אָחַר",
		"xlit" : "ʼâchar",
		"pron" : "aw-khar'",
		"derivation" : "a primitive root",
		"def" : "to loiter (i.e. be behind); by implication to procrastinate",
		"kjv" : "continue, defer, delay, hinder, be late (slack), stay (there), tarry (longer)"
	},
	"H310" : {
		"lemma" : "אַחַר",
		"xlit" : "ʼachar",
		"pron" : "akh-ar'",
		"derivation" : "from H309 (אָחַר)",
		"def" : "properly, the hind part; generally used as an adverb or conjunction, after (in various senses)",
		"kjv" : "after (that, -ward), again, at, away from, back (from, -side), behind, beside, by, follow (after, -ing), forasmuch, from, hereafter, hinder end, [phrase] out (over) live, [phrase] persecute, posterity, pursuing, remnant, seeing, since, thence(-forth), when, with"
	},
	"H311" : {
		"lemma" : "אַחַר",
		"xlit" : "ʼachar",
		"pron" : "akh-ar'",
		"derivation" : "(Aramaic) corresponding to H310 (אַחַר)",
		"def" : "after",
		"kjv" : "(here-) after"
	},
	"H312" : {
		"lemma" : "אַחֵר",
		"xlit" : "ʼachêr",
		"pron" : "akh-air'",
		"derivation" : "from H309 (אָחַר)",
		"def" : "properly, hinder; generally, next, other, etc.",
		"kjv" : "(an-) other man, following, next, strange"
	},
	"H313" : {
		"lemma" : "אַחֵר",
		"xlit" : "ʼAchêr",
		"pron" : "akh-air'",
		"derivation" : "the same as H312 (אַחֵר)",
		"def" : "Acher, an Israelite",
		"kjv" : "Aher"
	},
	"H314" : {
		"lemma" : "אַחֲרוֹן",
		"xlit" : "ʼachărôwn",
		"pron" : "akh-ar-one'",
		"derivation" : "or (shortened) אַחֲרֹן; from H309 (אָחַר)",
		"def" : "hinder; generally, late or last; specifically (as facing the east) western",
		"kjv" : "after (-ward), to come, following, hind(-er, -ermost, -most), last, latter, rereward, ut(ter) most"
	},
	"H315" : {
		"lemma" : "אַחְרַח",
		"xlit" : "ʼAchrach",
		"pron" : "akh-rakh'",
		"derivation" : "from H310 (אַחַר) and H251 (אָח); after (his) brother",
		"def" : "Achrach, an Israelite",
		"kjv" : "Aharah"
	},
	"H316" : {
		"lemma" : "אֲחַרְחֵל",
		"xlit" : "ʼĂcharchêl",
		"pron" : "akh-ar-kale'",
		"derivation" : "from H310 (אַחַר) and H2426 (חֵיל); behind (the) intrenchment (i.e. safe)",
		"def" : "Acharchel, an Israelite",
		"kjv" : "Aharhel"
	},
	"H317" : {
		"lemma" : "אׇחֳרִי",
		"xlit" : "ʼochŏrîy",
		"pron" : "okh-or-ee'",
		"derivation" : "(Aramaic) from H311 (אַחַר)",
		"def" : "other",
		"kjv" : "(an-) other"
	},
	"H318" : {
		"lemma" : "אׇחֳרֵין",
		"xlit" : "ʼochŏrêyn",
		"pron" : "okh-or-ane'",
		"derivation" : "(Aramaic) or (shortened) אׇחֳרֵן; (Aramaic) from H317 (אׇחֳרִי)",
		"def" : "last",
		"kjv" : "at last"
	},
	"H319" : {
		"lemma" : "אַחֲרִית",
		"xlit" : "ʼachărîyth",
		"pron" : "akh-ar-eeth'",
		"derivation" : "from H310 (אַחַר)",
		"def" : "the last or end, hence, the future; also posterity",
		"kjv" : "(last, latter) end (time), hinder (utter) -most, length, posterity, remnant, residue, reward"
	},
	"H320" : {
		"lemma" : "אַחֲרִית",
		"xlit" : "ʼachărîyth",
		"pron" : "akh-ar-eeth'",
		"derivation" : "(Aramaic) from H311 (אַחַר); the same as H319 (אַחֲרִית)",
		"def" : "later",
		"kjv" : "latter"
	},
	"H321" : {
		"lemma" : "אׇחֳרָן",
		"xlit" : "ʼochŏrân",
		"pron" : "okh-or-awn'",
		"derivation" : "(Aramaic) from H311 (אַחַר); the same as H317 (אׇחֳרִי)",
		"def" : "other",
		"kjv" : "(an-) other"
	},
	"H322" : {
		"lemma" : "אֲחֹרַנִּית",
		"xlit" : "ʼăchôrannîyth",
		"pron" : "akh-o-ran-neeth'",
		"derivation" : "prolonged from H268 (אָחוֹר)",
		"def" : "backwards",
		"kjv" : "back (-ward, again)"
	},
	"H323" : {
		"lemma" : "אֲחַשְׁדַּרְפַּן",
		"xlit" : "ʼăchashdarpan",
		"pron" : "akh-ash-dar-pan'",
		"derivation" : "of Persian derivation",
		"def" : "a satrap or governorof amain province (of Persia)",
		"kjv" : "lieutenant"
	},
	"H324" : {
		"lemma" : "אֲחַשְׁדַּרְפַּן",
		"xlit" : "ʼăchashdarpan",
		"pron" : "akh-ash-dar-pan'",
		"derivation" : "(Aramaic) corresponding to H323 (אֲחַשְׁדַּרְפַּן)",
		"def" : "{a satrap or governorof amain province (of Persia)}",
		"kjv" : "prince"
	},
	"H325" : {
		"lemma" : "אֲחַשְׁוֵרוֹשׁ",
		"xlit" : "ʼĂchashvêrôwsh",
		"pron" : "akh-ash-vay-rosh'",
		"derivation" : "or (shortened) אַחַשְׁרֹשׁ (Esther 10:1); of Persian origin",
		"def" : "Achashverosh (i.e. Ahasuerus or Artaxerxes, but in this case Xerxes), the title (rather than name) of a Persian king",
		"kjv" : "Ahasuerus"
	},
	"H326" : {
		"lemma" : "אֲחַשְׁתָּרִי",
		"xlit" : "ʼăchashtârîy",
		"pron" : "akh-ash-taw-ree'",
		"derivation" : "probably of Persian derivation",
		"def" : "an achastarite (i.e. courier); the designation (rather than name) of an Israelite",
		"kjv" : "Haakashtari (includ. the article)"
	},
	"H327" : {
		"lemma" : "אֲחַשְׁתָּרָן",
		"xlit" : "ʼăchashtârân",
		"pron" : "akh-ash-taw-rawn'",
		"derivation" : "of Persian origin",
		"def" : "a mule",
		"kjv" : "camel"
	},
	"H328" : {
		"lemma" : "אַט",
		"xlit" : "ʼaṭ",
		"pron" : "at",
		"derivation" : "from an unused root perhaps meaning to move softly",
		"def" : "(as a noun) a necromancer (from their soft incantations), (as an adverb) gently",
		"kjv" : "charmer, gently, secret, softly"
	},
	"H329" : {
		"lemma" : "אָטָד",
		"xlit" : "ʼâṭâd",
		"pron" : "aw-tawd'",
		"derivation" : "from an unused root probably meaning to pierce or make fast",
		"def" : "a thorn-tree (especially the buckthorn)",
		"kjv" : "Atad, bramble, thorn"
	},
	"H330" : {
		"lemma" : "אֵטוּן",
		"xlit" : "ʼêṭûwn",
		"pron" : "ay-toon'",
		"derivation" : "from an unused root (probably meaning to bind)",
		"def" : "properly, twisted (yarn), i.e. tapestry",
		"kjv" : "fine linen"
	},
	"H331" : {
		"lemma" : "אָטַם",
		"xlit" : "ʼâṭam",
		"pron" : "aw-tam'",
		"derivation" : "a primitive root",
		"def" : "to close (the lips or ears); by analology to contract (a window by bevelled jambs)",
		"kjv" : "narrow, shut, stop"
	},
	"H332" : {
		"lemma" : "אָטַר",
		"xlit" : "ʼâṭar",
		"pron" : "aw-tar'",
		"derivation" : "a primitive root",
		"def" : "to close up",
		"kjv" : "shut"
	},
	"H333" : {
		"lemma" : "אָטֵר",
		"xlit" : "ʼÂṭêr",
		"pron" : "aw-tare'",
		"derivation" : "from H332 (אָטַר); maimed",
		"def" : "Ater, the name of three Israelites",
		"kjv" : "Ater"
	},
	"H334" : {
		"lemma" : "אִטֵּר",
		"xlit" : "ʼiṭṭêr",
		"pron" : "it-tare'",
		"derivation" : "from H332 (אָטַר)",
		"def" : "shut up, i.e. impeded (as to the use of the right hand)",
		"kjv" : "[phrase] left-handed"
	},
	"H335" : {
		"lemma" : "אַי",
		"xlit" : "ʼay",
		"pron" : "ah'ee",
		"derivation" : "perhaps from H370 (אַיִן)",
		"def" : "where? hence how?",
		"kjv" : "how, what, whence, where, whether, which (way)"
	},
	"H336" : {
		"lemma" : "אִי",
		"xlit" : "ʼîy",
		"pron" : "ee",
		"derivation" : "probably identical with H335 (אַי) (through the idea of a query)",
		"def" : "not",
		"kjv" : "island (Job 22:30)"
	},
	"H337" : {
		"lemma" : "אִי",
		"xlit" : "ʼîy",
		"pron" : "ee",
		"derivation" : "short from H188 (אוֹי)",
		"def" : "alas!",
		"kjv" : "woe"
	},
	"H338" : {
		"lemma" : "אִי",
		"xlit" : "ʼîy",
		"pron" : "ee",
		"derivation" : "probably identical with H337 (אִי) (through the idea of a doleful sound)",
		"def" : "a howler (used only in the plural), i.e. any solitary wild creature",
		"kjv" : "wild beast of the islands"
	},
	"H339" : {
		"lemma" : "אִי",
		"xlit" : "ʼîy",
		"pron" : "ee",
		"derivation" : "from H183 (אָוָה)",
		"def" : "properly, a habitable spot (as desirable); dry land, a coast, an island",
		"kjv" : "country, isle, island"
	},
	"H340" : {
		"lemma" : "אָיַב",
		"xlit" : "ʼâyab",
		"pron" : "aw-yab'",
		"derivation" : "a primitive root",
		"def" : "to hate (as one of an opposite tribe or party); hence to be hostile",
		"kjv" : "be an enemy"
	},
	"H341" : {
		"lemma" : "אֹיֵב",
		"xlit" : "ʼôyêb",
		"pron" : "o-yabe'",
		"derivation" : "or (fully) אוֹיֵב; active participle of H340 (אָיַב)",
		"def" : "hating; an adversary",
		"kjv" : "enemy, foe"
	},
	"H342" : {
		"lemma" : "אֵיבָה",
		"xlit" : "ʼêybâh",
		"pron" : "ay-baw'",
		"derivation" : "from H340 (אָיַב)",
		"def" : "hostility",
		"kjv" : "emnity, hatred"
	},
	"H343" : {
		"lemma" : "אֵיד",
		"xlit" : "ʼêyd",
		"pron" : "ade",
		"derivation" : "from the same as H181 (אוּד) (in the sense of bending down)",
		"def" : "oppression; by implication misfortune, ruin",
		"kjv" : "calamity, destruction"
	},
	"H344" : {
		"lemma" : "אַיָּה",
		"xlit" : "ʼayâh",
		"pron" : "ah-yaw'",
		"derivation" : "perhaps from H337 (אִי)",
		"def" : "the screamer, i.e. a hawk",
		"kjv" : "kite, vulture"
	},
	"H345" : {
		"lemma" : "אַיָּה",
		"xlit" : "ʼAyâh",
		"pron" : "ah-yaw'",
		"derivation" : "the same as H344 (אַיָּה)",
		"def" : "Ajah, the name of two Israelites",
		"kjv" : "Aiah, Ajah"
	},
	"H346" : {
		"lemma" : "אַיֵּה",
		"xlit" : "ʼayêh",
		"pron" : "ah-yay'",
		"derivation" : "prolonged from H335 (אַי)",
		"def" : "where?",
		"kjv" : "where"
	},
	"H347" : {
		"lemma" : "אִיּוֹב",
		"xlit" : "ʼÎyôwb",
		"pron" : "ee-yobe'",
		"derivation" : "from H340 (אָיַב); hated (i.e. persecuted)",
		"def" : "Ijob, the patriarch famous for his patience",
		"kjv" : "Job"
	},
	"H348" : {
		"lemma" : "אִיזֶבֶל",
		"xlit" : "ʼÎyzebel",
		"pron" : "ee-zeh'-bel",
		"derivation" : "from H336 (אִי)lemma אי missing vowel, corrected to אִי and H2083 (זְבֻל)",
		"def" : "Izebel, the wife of king Ahab",
		"kjv" : "Jezebel"
	},
	"H349" : {
		"lemma" : "אֵיךְ",
		"xlit" : "ʼêyk",
		"pron" : "ake",
		"derivation" : "also אֵיכָה ; and אֵיכָכָה ; prolonged from H335 (אַי)",
		"def" : "how? or how!; also where",
		"kjv" : "how, what"
	},
	"H350" : {
		"lemma" : "אִי־כָבוֹד",
		"xlit" : "ʼÎy-kâbôwd",
		"pron" : "ee-kaw-bode'",
		"derivation" : "from H336 (אִי)lemma אי missing vowel, corrected to אִי and H3519 (כָּבוֹד); (there is) no glory, i.e. inglorious",
		"def" : "Ikabod, a son of Phineas",
		"kjv" : "I-chabod"
	},
	"H351" : {
		"lemma" : "אֵיכֹה",
		"xlit" : "ʼêykôh",
		"pron" : "ay-ko",
		"derivation" : "probably a variation for H349 (אֵיךְ), but not as an interogative",
		"def" : "where",
		"kjv" : "where"
	},
	"H352" : {
		"lemma" : "אַיִל",
		"xlit" : "ʼayil",
		"pron" : "ah'-yil",
		"derivation" : "from the same as H193 (אוּל)",
		"def" : "properly, strength; hence, anything strong; specifically a chief (politically); also a ram (from his strength); a pilaster (as a strong support); an oak or other strong tree",
		"kjv" : "mighty (man), lintel, oak, post, ram, tree"
	},
	"H353" : {
		"lemma" : "אֱיָל",
		"xlit" : "ʼĕyâl",
		"pron" : "eh-yawl'",
		"derivation" : "a variation of H352 (אַיִל)",
		"def" : "strength",
		"kjv" : "strength"
	},
	"H354" : {
		"lemma" : "אַיָּל",
		"xlit" : "ʼayâl",
		"pron" : "ah-yawl'",
		"derivation" : "an intensive form of H352 (אַיִל) (in the sense of ram)",
		"def" : "a stag or male deer",
		"kjv" : "hart"
	},
	"H355" : {
		"lemma" : "אַיָּלָה",
		"xlit" : "ʼayâlâh",
		"pron" : "ah-yaw-law'",
		"derivation" : "feminine of H354 (אַיָּל)",
		"def" : "a doe or female deer",
		"kjv" : "hind"
	},
	"H356" : {
		"lemma" : "אֵילוֹן",
		"xlit" : "ʼÊylôwn",
		"pron" : "ay-lone'",
		"derivation" : "or (shortened) אֵלוֹן ; or אֵילֹן ; from H352 (אַיִל); oak-grove",
		"def" : "Elon, the name of a place in Palestine, and also of one Hittite, two Israelites",
		"kjv" : "Elon"
	},
	"H357" : {
		"lemma" : "אַיָּלוֹן",
		"xlit" : "ʼAyâlôwn",
		"pron" : "ah-yaw-lone'",
		"derivation" : "from H354 (אַיָּל); deer-field",
		"def" : "Ajalon, the name of five places in Palestine",
		"kjv" : "Aijalon, Ajalon"
	},
	"H358" : {
		"lemma" : "אֵילוֹן בֵּית חָנָן",
		"xlit" : "ʼÊylôwn Bêyth Chânân",
		"pron" : "ay-lone' bayth-chawnawn'",
		"derivation" : "from H356 (אֵילוֹן), H1004 (בַּיִת), and H2603 (חָנַן); oak-grove of (the) house of favor",
		"def" : "Elon of Beth-chanan, a place in Palestine",
		"kjv" : "Elon-bethhanan"
	},
	"H359" : {
		"lemma" : "אֵילוֹת",
		"xlit" : "ʼÊylôwth",
		"pron" : "ay-loth'",
		"derivation" : "or אֵילַת ; from H352 (אַיִל); trees or a grove (i.e. palms)",
		"def" : "Eloth or Elath, a place on the Red Sea",
		"kjv" : "Elath, Eloth"
	},
	"H360" : {
		"lemma" : "אֱיָלוּת",
		"xlit" : "ʼĕyâlûwth",
		"pron" : "eh-yaw-looth'",
		"derivation" : "feminine of H353 (אֱיָל)",
		"def" : "power; by implication, protection",
		"kjv" : "strength"
	},
	"H361" : {
		"lemma" : "אֵילָם",
		"xlit" : "ʼêylâm",
		"pron" : "ay-lawm'",
		"derivation" : "or (shortened) אֵלָם ; or (feminine) אֵלַמָּה ; probably from H352 (אַיִל)",
		"def" : "a pillar-space (or colonnade), i.e. a pale (or portico)",
		"kjv" : "arch"
	},
	"H362" : {
		"lemma" : "אֵילִם",
		"xlit" : "ʼÊylim",
		"pron" : "ay-leem'",
		"derivation" : "plural of H352 (אַיִל); palm-trees",
		"def" : "Elim, a place in the Desert",
		"kjv" : "Elim"
	},
	"H363" : {
		"lemma" : "אִילָן",
		"xlit" : "ʼîylân",
		"pron" : "ee-lawn'",
		"derivation" : "(Aramaic) corresponding to H356 (אֵילוֹן)",
		"def" : "a tree",
		"kjv" : "tree"
	},
	"H364" : {
		"lemma" : "אֵיל פָּארָן",
		"xlit" : "ʼÊyl Pâʼrân",
		"pron" : "ale paw-rawn'",
		"derivation" : "from H352 (אַיִל) and H6290 (פָּארָן); oak of Paran",
		"def" : "El-Paran, a portion of the district of Paran",
		"kjv" : "El-paran"
	},
	"H365" : {
		"lemma" : "אַיֶּלֶת",
		"xlit" : "ʼayeleth",
		"pron" : "ah-yeh'-leth",
		"derivation" : "the same as H355 (אַיָּלָה)",
		"def" : "a doe",
		"kjv" : "hind, Aijeleth"
	},
	"H366" : {
		"lemma" : "אָיֹם",
		"xlit" : "ʼâyôm",
		"pron" : "aw-yome'",
		"derivation" : "from an unused root (meaning to frighten)",
		"def" : "frightful",
		"kjv" : "terrible"
	},
	"H367" : {
		"lemma" : "אֵימָה",
		"xlit" : "ʼêymâh",
		"pron" : "ay-maw'",
		"derivation" : "or (shortened) אֵמָה; from the same as H366 (אָיֹם)",
		"def" : "fright; concrete, an idol (as a bugbear)",
		"kjv" : "dread, fear, horror, idol, terrible, terror"
	},
	"H368" : {
		"lemma" : "אֵימִים",
		"xlit" : "ʼÊymîym",
		"pron" : "ay-meem'",
		"derivation" : "plural of H367 (אֵימָה); terrors",
		"def" : "Emim, an early Canaanitish (or Maobitish) tribe",
		"kjv" : "Emims"
	},
	"H369" : {
		"lemma" : "אַיִן",
		"xlit" : "ʼayin",
		"pron" : "ah'-yin",
		"derivation" : "as if from a primitive root meaning to be nothing or not exist",
		"def" : "a non-entity; generally used as a negative particle",
		"kjv" : "else, except, fail, (father-) less, be gone, in(-curable), neither, never, no (where), none, nor, (any, thing), not, nothing, to nought, past, un(-searchable), well-nigh, without. Compare H370 (אַיִן)"
	},
	"H370" : {
		"lemma" : "אַיִן",
		"xlit" : "ʼayin",
		"pron" : "ah-yin'",
		"derivation" : "probably identical with H369 (אַיִן) in the sense of query (compare H336 (אִי)lemma אי missing vowel, corrected to אִי)",
		"def" : "where? (only in connection with prepositional prefix, whence)",
		"kjv" : "whence, where"
	},
	"H371" : {
		"lemma" : "אִין",
		"xlit" : "ʼîyn",
		"pron" : "een",
		"derivation" : "apparently a shortened form of H369 (אַיִן); but (like H370 (אַיִן)) an interrogative",
		"def" : "is it not?",
		"kjv" : "not"
	},
	"H372" : {
		"lemma" : "אִיעֶזֵר",
		"xlit" : "ʼÎyʻezêr",
		"pron" : "ee-eh'-zer",
		"derivation" : "from H336 (אִי)lemma אי missing vowel, corrected to אִי and H5828 (עֵזֶר); helpless",
		"def" : "Iezer, an Israelite",
		"kjv" : "Jeezer"
	},
	"H373" : {
		"lemma" : "אִיעֶזְרִי",
		"xlit" : "ʼÎyʻezrîy",
		"pron" : "ee-ez-ree'",
		"derivation" : "patronymic from H372 (אִיעֶזֵר)",
		"def" : "an Iezrite or descendant of Iezer",
		"kjv" : "Jezerite"
	},
	"H374" : {
		"lemma" : "אֵיפָה",
		"xlit" : "ʼêyphâh",
		"pron" : "ay-faw'",
		"derivation" : "or (shortened) אֵפָה ; of Egyptian derivation",
		"def" : "an ephah or measure for grain; hence, a measure in general",
		"kjv" : "ephah, (divers) measure(-s)"
	},
	"H375" : {
		"lemma" : "אֵיפֹה",
		"xlit" : "ʼêyphôh",
		"pron" : "ay-fo'",
		"derivation" : "from H335 (אַי) and H6311 (פֹּה)",
		"def" : "what place?; also (of time) when?; or (of means) how?",
		"kjv" : "what manner, where"
	},
	"H376" : {
		"lemma" : "אִישׁ",
		"xlit" : "ʼîysh",
		"pron" : "eesh",
		"derivation" : "contracted for H582 (אֱנוֹשׁ) (or perhaps rather from an unused root meaning to be extant)",
		"def" : "a man as an individual or a male person; often used as an adjunct to a more definite term (and in such cases frequently not expressed in translation)",
		"kjv" : "also, another, any (man), a certain, [phrase] champion, consent, each, every (one), fellow, (foot-, husband-) man, (good-, great, mighty) man, he, high (degree), him (that is), husband, man(-kind), [phrase] none, one, people, person, [phrase] steward, what (man) soever, whoso(-ever), worthy. Compare H802 (אִשָּׁה)"
	},
	"H377" : {
		"lemma" : "אִישׁ",
		"xlit" : "ʼîysh",
		"pron" : "eesh",
		"derivation" : "denominative from H376 (אִישׁ)",
		"def" : "to be a man, i.e. act in amanly way",
		"kjv" : "show (one) self a man"
	},
	"H378" : {
		"lemma" : "אִישׁ־בֹּשֶׁת",
		"xlit" : "ʼÎysh-Bôsheth",
		"pron" : "eesh-bo'-sheth",
		"derivation" : "from H376 (אִישׁ) and H1322 (בֹּשֶׁת); man of shame",
		"def" : "Ish-Bosheth, a son of King Saul",
		"kjv" : "Ish-bosheth"
	},
	"H379" : {
		"lemma" : "אִישְׁהוֹד",
		"xlit" : "ʼÎyshᵉhôwd",
		"pron" : "eesh-hode'",
		"derivation" : "from H376 (אִישׁ) and H1935 (הוֹד); man of renown",
		"def" : "Ishod, an Israelite",
		"kjv" : "Ishod"
	},
	"H380" : {
		"lemma" : "אִישׁוֹן",
		"xlit" : "ʼîyshôwn",
		"pron" : "ee-shone'",
		"derivation" : "diminutive from H376 (אִישׁ)",
		"def" : "the little man of the eye; the pupil or ball; hence, the middle (of night)",
		"kjv" : "apple (of the eye), black, obscure"
	},
	"H381" : {
		"lemma" : "אִישׁ־חַיִל",
		"xlit" : "ʼÎysh-Chayil",
		"pron" : "eesh-khah'-yil",
		"derivation" : "from H376 (אִישׁ) and H2428 (חַיִל); man of might; by defective transcription (2 Samuel 23:20) אִישׁ־חַיxlit ʼÎsh-Chay corrected to ʼÎysh-Chay; as if from H376 (אִישׁ) and H2416 (חַי); living man",
		"def" : "Ishchail (or Ish-chai), an Israelite",
		"kjv" : "a valiant man"
	},
	"H382" : {
		"lemma" : "אִישׁ־טוֹב",
		"xlit" : "ʼÎysh-Ṭôwb",
		"pron" : "eesh-tobe'",
		"derivation" : "from H376 (אִישׁ) and H2897 (טוֹב); man of Tob",
		"def" : "Ish-Tob, a place in Palestine",
		"kjv" : "Ish-tob"
	},
	"H383" : {
		"lemma" : "אִיתַי",
		"xlit" : "ʼîythay",
		"pron" : "ee-thah'ee",
		"derivation" : "(Aramaic) corresponding to H3426 (יֵשׁ)",
		"def" : "properly, entity; used only as aparticle of affirmation, there is",
		"kjv" : "art thou, can, do ye, have, it be, there is (are), [idiom] we will not"
	},
	"H384" : {
		"lemma" : "אִיתִיאֵל",
		"xlit" : "ʼÎythîyʼêl",
		"pron" : "eeth-ee-ale'",
		"derivation" : "perhaps from H837 (אֹשֶׁר) and H410 (אֵל); God has arrived",
		"def" : "Ithiel, the name of an Israelite, also of a symbolical person",
		"kjv" : "Ithiel"
	},
	"H385" : {
		"lemma" : "אִיתָמָר",
		"xlit" : "ʼÎythâmâr",
		"pron" : "eeth-aw-mawr'",
		"derivation" : "from H339 (אִי) and H8558 (תָּמָר); coast of the palm-tree",
		"def" : "Ithamar, a son of Aaron",
		"kjv" : "Ithamar"
	},
	"H386" : {
		"lemma" : "אֵיתָן",
		"xlit" : "ʼêythân",
		"pron" : "ay-thawn'",
		"derivation" : "or (shortened) אֵתָן ; from an unused root (meaning to continue)",
		"def" : "permanence; hence (concrete) permanent; specifically a chieftain",
		"kjv" : "hard, mighty, rough, strength, strong"
	},
	"H387" : {
		"lemma" : "אֵיתָן",
		"xlit" : "ʼÊythân",
		"pron" : "ay-thawn'",
		"derivation" : "the same as H386 (אֵיתָן); permanent",
		"def" : "Ethan, the name of four Israelites",
		"kjv" : "Ethan"
	},
	"H388" : {
		"lemma" : "אֵיתָנִים",
		"xlit" : "ʼÊythânîym",
		"pron" : "ay-thaw-neem'",
		"derivation" : "plural of H386 (אֵיתָן); always with the article; the permanent brooks",
		"def" : "Ethanim, the name of a month",
		"kjv" : "Ethanim"
	},
	"H389" : {
		"lemma" : "אַךְ",
		"xlit" : "ʼak",
		"pron" : "ak",
		"derivation" : "akin to H403 (אָכֵן)",
		"def" : "a particle of affirmation, surely; hence (by limitation) only",
		"kjv" : "also, in any wise, at least, but, certainly, even, howbeit, nevertheless, notwithstanding, only, save, surely, of a surety, truly, verily, [phrase] wherefore, yet (but)"
	},
	"H390" : {
		"lemma" : "אַכַּד",
		"xlit" : "ʼAkkad",
		"pron" : "ak-kad'",
		"derivation" : "from an unused root probably meaning to strengthen; a fortress",
		"def" : "Accad, a place in Babylon",
		"kjv" : "Accad"
	},
	"H391" : {
		"lemma" : "אַכְזָב",
		"xlit" : "ʼakzâb",
		"pron" : "ak-zawb'",
		"derivation" : "from H3576 (כָּזַב)",
		"def" : "falsehood; by implication treachery",
		"kjv" : "liar, lie"
	},
	"H392" : {
		"lemma" : "אַכְזִיב",
		"xlit" : "ʼAkzîyb",
		"pron" : "ak-zeeb'",
		"derivation" : "from H391 (אַכְזָב); deceitful (in the sense of a winter-torrent which fails in summer)",
		"def" : "Akzib, the name of two places in Palestine",
		"kjv" : "Achzib"
	},
	"H393" : {
		"lemma" : "אַכְזָר",
		"xlit" : "ʼakzâr",
		"pron" : "ak-zawr'",
		"derivation" : "from an unused root (apparently meaning to act harshly)",
		"def" : "violent; by implication deadly; also (in a good sense) brave",
		"kjv" : "cruel, fierce"
	},
	"H394" : {
		"lemma" : "אַכְזָרִי",
		"xlit" : "ʼakzârîy",
		"pron" : "ak-zawr-ree'",
		"derivation" : "from H393 (אַכְזָר)",
		"def" : "terrible",
		"kjv" : "cruel (one)"
	},
	"H395" : {
		"lemma" : "אַכְזְרִיּוּת",
		"xlit" : "ʼakzᵉrîyûwth",
		"pron" : "ak-ze-ree-ooth'",
		"derivation" : "from H394 (אַכְזָרִי)",
		"def" : "fierceness",
		"kjv" : "cruel"
	},
	"H396" : {
		"lemma" : "אֲכִילָה",
		"xlit" : "ʼăkîylâh",
		"pron" : "ak-ee-law'",
		"derivation" : "feminine from H398 (אָכַל)",
		"def" : "something eatable, i.e. food",
		"kjv" : "meat"
	},
	"H397" : {
		"lemma" : "אֲכִישׁ",
		"xlit" : "ʼĂkîysh",
		"pron" : "aw-keesh'",
		"derivation" : "of uncertain derivation",
		"def" : "Akish, a Philistine king",
		"kjv" : "Achish"
	},
	"H398" : {
		"lemma" : "אָכַל",
		"xlit" : "ʼâkal",
		"pron" : "aw-kal'",
		"derivation" : "a primitive root",
		"def" : "to eat (literally or figuratively)",
		"kjv" : "[idiom] at all, burn up, consume, devour(-er, up), dine, eat(-er, up), feed (with), food, [idiom] freely, [idiom] in...wise(-deed, plenty), (lay) meat, [idiom] quite"
	},
	"H399" : {
		"lemma" : "אֲכַל",
		"xlit" : "ʼăkal",
		"pron" : "ak-al'",
		"derivation" : "(Aramaic) corresponding to H398 (אָכַל)",
		"def" : "{to eat (literally or figuratively)}",
		"kjv" : "[phrase] accuse, devour, eat"
	},
	"H400" : {
		"lemma" : "אֹכֶל",
		"xlit" : "ʼôkel",
		"pron" : "o'-kel",
		"derivation" : "from H398 (אָכַל)",
		"def" : "food",
		"kjv" : "eating, food, meal(-time), meat, prey, victuals"
	},
	"H401" : {
		"lemma" : "אֻכָל",
		"xlit" : "ʼUkâl",
		"pron" : "oo-kawl'",
		"derivation" : "or אֻכָּל; apparently from H398 (אָכַל); devoured",
		"def" : "Ucal, a fancy name",
		"kjv" : "Ucal"
	},
	"H402" : {
		"lemma" : "אׇכְלָה",
		"xlit" : "ʼoklâh",
		"pron" : "ok-law'",
		"derivation" : "feminine of H401 (אֻכָל)",
		"def" : "food",
		"kjv" : "consume, devour, eat, food, meat"
	},
	"H403" : {
		"lemma" : "אָכֵן",
		"xlit" : "ʼâkên",
		"pron" : "aw-kane'",
		"derivation" : "from H3559 (כּוּן) (compare H3651 (כֵּן))",
		"def" : "firmly; figuratively, surely; also (advers.) but",
		"kjv" : "but, certainly, nevertheless, surely, truly, verily"
	},
	"H404" : {
		"lemma" : "אָכַף",
		"xlit" : "ʼâkaph",
		"pron" : "aw-kaf'",
		"derivation" : "a primitive root; apparently meaning to curve (as with a burden)",
		"def" : "to urge",
		"kjv" : "crave"
	},
	"H405" : {
		"lemma" : "אֶכֶף",
		"xlit" : "ʼekeph",
		"pron" : "eh'-kef",
		"derivation" : "from H404 (אָכַף)",
		"def" : "a load; by implication, a stroke (others dignity)",
		"kjv" : "hand"
	},
	"H406" : {
		"lemma" : "אִכָּר",
		"xlit" : "ʼikkâr",
		"pron" : "ik-kawr'",
		"derivation" : "from an unused root meaning to dig",
		"def" : "a farmer",
		"kjv" : "husbandman, ploughman"
	},
	"H407" : {
		"lemma" : "אַכְשָׁף",
		"xlit" : "ʼAkshâph",
		"pron" : "ak-shawf'",
		"derivation" : "from H3784 (כָּשַׁף); fascination",
		"def" : "Acshaph, a place in Palestine",
		"kjv" : "Achshaph"
	},
	"H408" : {
		"lemma" : "אַל",
		"xlit" : "ʼal",
		"pron" : "al",
		"derivation" : "a negative particle (akin to H3808 (לֹא))",
		"def" : "not (the qualified negation, used as a deprecative); once (Job 24:25) as a noun, nothing",
		"kjv" : "nay, neither, [phrase] never, no, nor, not, nothing (worth), rather than"
	},
	"H409" : {
		"lemma" : "אַל",
		"xlit" : "ʼal",
		"pron" : "al",
		"derivation" : "(Aramaic) corresponding to H408 (אַל)",
		"def" : "{not (the qualified negation, used as a deprecative); once (Job 24:25) as a noun, nothing}",
		"kjv" : "not"
	},
	"H410" : {
		"lemma" : "אֵל",
		"xlit" : "ʼêl",
		"pron" : "ale",
		"derivation" : "shortened from H352 (אַיִל)",
		"def" : "strength; as adjective, mighty; especially the Almighty (but used also of any deity)",
		"kjv" : "God (god), [idiom] goodly, [idiom] great, idol, might(-y one), power, strong. Compare names in '-el.'"
	},
	"H411" : {
		"lemma" : "אֵל",
		"xlit" : "ʼêl",
		"pron" : "ale",
		"derivation" : "a demonstrative particle (but only in a plural sense)",
		"def" : "these or those",
		"kjv" : "these, those. Compare H428 (אֵלֶּה)"
	},
	"H412" : {
		"lemma" : "אֵל",
		"xlit" : "ʼêl",
		"pron" : "ale",
		"derivation" : "(Aramaic) corresponding to H411 (אֵל)",
		"def" : "{these or those}",
		"kjv" : "these"
	},
	"H413" : {
		"lemma" : "אֵל",
		"xlit" : "ʼêl",
		"pron" : "ale",
		"derivation" : "(but only used in the shortened constructive form אֶל ); a primitive particle; properly, denoting motion towards, but occasionally used of a quiescent position, i.e",
		"def" : "near, with or among; often in general, to",
		"kjv" : "about, according to, after, against, among, as for, at, because(-fore, -side), both...and, by, concerning, for, from, [idiom] hath, in(-to), near, (out) of, over, through, to(-ward), under, unto, upon, whether, with(-in)"
	},
	"H414" : {
		"lemma" : "אֵלָא",
		"xlit" : "ʼÊlâʼ",
		"pron" : "ay-law'",
		"derivation" : "a variation of H424 (אֵלָה)lemma אִלָה first vowel, corrected to אֵלָה; oak",
		"def" : "Ela, an Israelite",
		"kjv" : "Elah"
	},
	"H415" : {
		"lemma" : "אֵל אֱלֹהֵי יִשְׂרָאֵל",
		"xlit" : "ʼÊl ʼĕlôhêy Yisrâʼêl",
		"pron" : "ale el-o-hay' yis-rawale'",
		"derivation" : "from H410 (אֵל) and H430 (אֱלֹהִים) and H3478 (יִשְׂרָאֵל); the mighty god if Jisrael",
		"def" : "El-Elohi-Jisrael, the title given to a consecrated spot by Jacob",
		"kjv" : "Elelohe-israel"
	},
	"H416" : {
		"lemma" : "אֵל בֵּית־אֵל",
		"xlit" : "ʼÊl Bêyth-ʼÊl",
		"pron" : "ale bayth-ale'",
		"derivation" : "from H410 (אֵל) and H1008 (בֵּית־אֵל); the God of Bethel",
		"def" : "El-Bethel, the title given to a consecrated spot by Jacob",
		"kjv" : "El-beth-el"
	},
	"H417" : {
		"lemma" : "אֶלְגָּבִישׁ",
		"xlit" : "ʼelgâbîysh",
		"pron" : "el-gaw-beesh'",
		"derivation" : "from H410 (אֵל) and H1378 (גָּבִישׁ)",
		"def" : "hail (as if a great pearl)",
		"kjv" : "great hail(-stones)"
	},
	"H418" : {
		"lemma" : "אַלְגּוּמִּים",
		"xlit" : "ʼalgûwmmîym",
		"pron" : "al-goom-meem'",
		"derivation" : "by transposition for H484 (אַלְמֻגִּים)",
		"def" : "sticks of algum wood",
		"kjv" : "algum (trees)"
	},
	"H419" : {
		"lemma" : "אֶלְדָּד",
		"xlit" : "ʼEldâd",
		"pron" : "el-dad'",
		"derivation" : "from H410 (אֵל) and H1730 (דּוֹד); God has loved",
		"def" : "Eldad, an Israelite",
		"kjv" : "Eldad"
	},
	"H420" : {
		"lemma" : "אֶלְדָּעָה",
		"xlit" : "ʼEldâʻâh",
		"pron" : "el-daw-aw'",
		"derivation" : "from H410 (אֵל) and H3045 (יָדַע); God of knowledge",
		"def" : "Eldaah, a son of Midian",
		"kjv" : "Eldaah"
	},
	"H421" : {
		"lemma" : "אָלָה",
		"xlit" : "ʼâlâh",
		"pron" : "aw-law'",
		"derivation" : "a primitive root (rather identical with H422 (אָלָה) through the idea of invocation)",
		"def" : "to bewail",
		"kjv" : "lament"
	},
	"H422" : {
		"lemma" : "אָלָה",
		"xlit" : "ʼâlâh",
		"pron" : "aw-law'",
		"derivation" : "a primitive root",
		"def" : "properly, to adjure, i.e. (usually in a bad sense) imprecate",
		"kjv" : "adjure, curse, swear"
	},
	"H423" : {
		"lemma" : "אָלָה",
		"xlit" : "ʼâlâh",
		"pron" : "aw-law'",
		"derivation" : "from H422 (אָלָה)",
		"def" : "an imprecation",
		"kjv" : "curse, cursing, execration, oath, swearing"
	},
	"H424" : {
		"lemma" : "אֵלָה",
		"xlit" : "ʼêlâh",
		"pron" : "ay-law'",
		"derivation" : "feminine of H352 (אַיִל)",
		"def" : "an oak or other strong tree",
		"kjv" : "elm, oak, teil-tree"
	},
	"H425" : {
		"lemma" : "אֵלָה",
		"xlit" : "ʼÊlâh",
		"pron" : "ay-law'",
		"derivation" : "the same as H424 (אֵלָה)lemma אִלָה first vowel, corrected to אֵלָה",
		"def" : "Elah, the name of an Edomite, of four Israelites, and also of a place in Palestine",
		"kjv" : "Elah"
	},
	"H426" : {
		"lemma" : "אֱלָהּ",
		"xlit" : "ʼĕlâhh",
		"pron" : "el-aw'",
		"derivation" : "(Aramaic) corresponding to H433 (אֱלוֹהַּ)",
		"def" : "God",
		"kjv" : "God, god"
	},
	"H427" : {
		"lemma" : "אַלָּה",
		"xlit" : "ʼallâh",
		"pron" : "al-law'",
		"derivation" : "A variation of H424 (אֵלָה)lemma אִלָה first vowel, corrected to אֵלָה",
		"def" : "{an oak or other strong tree}",
		"kjv" : "oak"
	},
	"H428" : {
		"lemma" : "אֵלֶּה",
		"xlit" : "ʼêl-leh",
		"pron" : "ale'-leh",
		"derivation" : "prolonged from H411 (אֵל)",
		"def" : "these or those",
		"kjv" : "an-(the) other; one sort, so, some, such, them, these (same), they, this, those, thus, which, who(-m)"
	},
	"H429" : {
		"lemma" : "אֵלֶּה",
		"xlit" : "ʼêlleh",
		"pron" : "ale'-leh",
		"derivation" : "(Aramaic) corresponding to H428 (אֵלֶּה)",
		"def" : "{these or those}",
		"kjv" : "these"
	},
	"H430" : {
		"lemma" : "אֱלֹהִים",
		"xlit" : "ʼĕlôhîym",
		"pron" : "el-o-heem'",
		"derivation" : "plural of H433 (אֱלוֹהַּ)",
		"def" : "gods in the ordinary sense; but specifically used (in the plural thus, especially with the article) of the supreme God; occasionally applied by way of deference to magistrates; and sometimes as a superlative",
		"kjv" : "angels, [idiom] exceeding, God (gods) (-dess, -ly), [idiom] (very) great, judges, [idiom] mighty"
	},
	"H431" : {
		"lemma" : "אֲלוּ",
		"xlit" : "ʼălûw",
		"pron" : "al-oo'",
		"derivation" : "(Aramaic) probably prolonged from H412 (אֵל)",
		"def" : "lo!",
		"kjv" : "behold"
	},
	"H432" : {
		"lemma" : "אִלּוּ",
		"xlit" : "ʼillûw",
		"pron" : "il-loo'",
		"derivation" : "probably from H408 (אַל)",
		"def" : "nay, i.e. (softened) if",
		"kjv" : "but if, yea though"
	},
	"H433" : {
		"lemma" : "אֱלוֹהַּ",
		"xlit" : "ʼĕlôwahh",
		"pron" : "el-o'-ah",
		"derivation" : "rarely (shortened) אֱלֹהַּ; probably prolonged (emphatic) from H410 (אֵל)",
		"def" : "a deity or the Deity",
		"kjv" : "God, god. See H430 (אֱלֹהִים)"
	},
	"H434" : {
		"lemma" : "אֱלוּל",
		"xlit" : "ʼĕlûwl",
		"pron" : "el-ool'",
		"derivation" : "for H457 (אֱלִיל)",
		"def" : "good for nothing",
		"kjv" : "thing of nought"
	},
	"H435" : {
		"lemma" : "אֱלוּל",
		"xlit" : "ʼĔlûwl",
		"pron" : "el-ool'",
		"derivation" : "probably of foreign derivation",
		"def" : "Elul, the sixth Jewish month",
		"kjv" : "Elul"
	},
	"H436" : {
		"lemma" : "אֵלוֹן",
		"xlit" : "ʼêlôwn",
		"pron" : "ay-lone'",
		"derivation" : "prolonged from H352 (אַיִל)",
		"def" : "an oak or other strong tree",
		"kjv" : "plain. See also H356 (אֵילוֹן)"
	},
	"H437" : {
		"lemma" : "אַלּוֹן",
		"xlit" : "ʼallôwn",
		"pron" : "al-lone'",
		"derivation" : "a variation of H436 (אֵלוֹן)",
		"def" : "{an oak or other strong tree}",
		"kjv" : "oak"
	},
	"H438" : {
		"lemma" : "אַלּוֹן",
		"xlit" : "ʼAllôwn",
		"pron" : "al-lone'",
		"derivation" : "the same as H437 (אַלּוֹן)",
		"def" : "Allon, an Israelite, also a place in Palestine",
		"kjv" : "Allon"
	},
	"H439" : {
		"lemma" : "אַלּוֹן בָּכוּת",
		"xlit" : "ʼAllôwn Bâkûwth",
		"pron" : "al-lone' baw-kooth'",
		"derivation" : "from H437 (אַלּוֹן) and a variation of H1068 (בְּכִית); oak of weeping",
		"def" : "Allon-Bakuth, a monumental tree",
		"kjv" : "Allon-bachuth"
	},
	"H440" : {
		"lemma" : "אֵלוֹנִי",
		"xlit" : "ʼÊlôwnîy",
		"pron" : "ay-lo-nee'",
		"derivation" : "or rather (shortened) אֵלֹנִי; patron from H438 (אַלּוֹן)",
		"def" : "an Elonite or descendant (collectively) of Elon",
		"kjv" : "Elonites"
	},
	"H441" : {
		"lemma" : "אַלּוּף",
		"xlit" : "ʼallûwph",
		"pron" : "al-loof'",
		"derivation" : "or (shortened) אַלֻּף; from H502 (אָלַף)",
		"def" : "familiar; a friend, also gentle; hence, a bullock (as being tame; applied, although masculine, to a cow); and so, a chieftain (as notable, like neat cattle)",
		"kjv" : "captain, duke, (chief) friend, governor, guide, ox"
	},
	"H442" : {
		"lemma" : "אָלוּשׁ",
		"xlit" : "ʼÂlûwsh",
		"pron" : "aw-loosh'",
		"derivation" : "of uncertain derivation",
		"def" : "Alush, a place in the Desert",
		"kjv" : "Alush"
	},
	"H443" : {
		"lemma" : "אֶלְזָבָד",
		"xlit" : "ʼElzâbâd",
		"pron" : "el-zaw-bawd'",
		"derivation" : "from H410 (אֵל) and H2064 (זָבַד); God has bestowed",
		"def" : "Elzabad, the name of two Israelites",
		"kjv" : "Elzabad"
	},
	"H444" : {
		"lemma" : "אָלַח",
		"xlit" : "ʼâlach",
		"pron" : "aw-lakh'",
		"derivation" : "a primitive root",
		"def" : "to muddle, i.e. (figuratively and intransitive) to turn (morally) corrupt",
		"kjv" : "become filthy"
	},
	"H445" : {
		"lemma" : "אֶלְחָנָן",
		"xlit" : "ʼElchânân",
		"pron" : "el-khaw-nawn'",
		"derivation" : "from H410 (אֵל) and H2603 (חָנַן); God (is) gracious",
		"def" : "Elchanan, an Israelite",
		"kjv" : "Elkanan"
	},
	"H446" : {
		"lemma" : "אֱלִיאָב",
		"xlit" : "ʼĔlîyʼâb",
		"pron" : "el-ee-awb'",
		"derivation" : "from H410 (אֵל) and H1 (אָב); God of (his) father",
		"def" : "Eliab, the name of six Israelites",
		"kjv" : "Eliab"
	},
	"H447" : {
		"lemma" : "אֱלִיאֵל",
		"xlit" : "ʼĔlîyʼêl",
		"pron" : "el-ee-ale'",
		"derivation" : "from H410 (אֵל) repeated; God of (his) God",
		"def" : "Eliel, the name of nine Israelites",
		"kjv" : "Eliel"
	},
	"H448" : {
		"lemma" : "אֱלִיאָתָה",
		"xlit" : "ʼĔlîyʼâthâh",
		"pron" : "el-ee-aw-thaw'",
		"derivation" : "or (contraction) אֱלִיָּתָה; from H410 (אֵל) and H225 (אוּת); God of (his) consent",
		"def" : "Eliathah, an Israelite",
		"kjv" : "Eliathah"
	},
	"H449" : {
		"lemma" : "אֱלִידָד",
		"xlit" : "ʼĔlîydâd",
		"pron" : "el-ee-dawd'",
		"derivation" : "from the same as H419 (אֶלְדָּד); God of (his) love",
		"def" : "Elidad, an Israelite",
		"kjv" : "Elidad"
	},
	"H450" : {
		"lemma" : "אֶלְיָדָע",
		"xlit" : "ʼElyâdâʻ",
		"pron" : "el-yaw-daw'",
		"derivation" : "from H410 (אֵל) and H3045 (יָדַע); God (is) knowing",
		"def" : "Eljada, the name of two Israelites and of an Aramaean leader",
		"kjv" : "Eliada"
	},
	"H451" : {
		"lemma" : "אַלְיָה",
		"xlit" : "ʼalyâh",
		"pron" : "al-yaw'",
		"derivation" : "from H422 (אָלָה) (in the original sense of strength)",
		"def" : "the stout part, i.e. the fat tail of the Oriental sheep",
		"kjv" : "rump"
	},
	"H452" : {
		"lemma" : "אֵלִיָּה",
		"xlit" : "ʼÊlîyâh",
		"pron" : "ay-lee-yaw'",
		"derivation" : "or prolonged אֵלִיָּהוּ; from H410 (אֵל) and H3050 (יָהּ); God of Jehovah",
		"def" : "Elijah, the name of the famous prophet and of two other Israelites",
		"kjv" : "Elijah, Eliah"
	},
	"H453" : {
		"lemma" : "אֱלִיהוּ",
		"xlit" : "ʼĔlîyhûw",
		"pron" : "el-ee-hoo'",
		"derivation" : "or (fully) אֱלִיהוּא; from H410 (אֵל) and H1931 (הוּא); God of him",
		"def" : "Elihu, the name of one of Job's friends, and of three Israelites",
		"kjv" : "Elihu"
	},
	"H454" : {
		"lemma" : "אֶלְיְהוֹעֵינַי",
		"xlit" : "ʼElyᵉhôwʻêynay",
		"pron" : "el-ye-ho-ay-nah'ee",
		"derivation" : "or (shortened) אֶלְיוֹעֵינַי; from H413 (אֵל) and H3068 (יְהֹוָה) and H5869 (עַיִן); towards Jehovah (are) my eyes",
		"def" : "Eljehoenai or Eljoenai, the name of seven Israelites",
		"kjv" : "Elihoenai, Elionai"
	},
	"H455" : {
		"lemma" : "אֶלְיַחְבָּא",
		"xlit" : "ʼElyachbâʼ",
		"pron" : "el-yakh-baw'",
		"derivation" : "from H410 (אֵל) and H2244 (חָבָא); God will hide",
		"def" : "Eljachba, an Israelite",
		"kjv" : "Eliahbah"
	},
	"H456" : {
		"lemma" : "אֱלִיחֹרֶף",
		"xlit" : "ʼĔlîychôreph",
		"pron" : "el-ee-kho'-ref",
		"derivation" : "from H410 (אֵל) and H2779 (חֹרֶף); God of autumn",
		"def" : "Elichoreph, an Israelite",
		"kjv" : "Elihoreph"
	},
	"H457" : {
		"lemma" : "אֱלִיל",
		"xlit" : "ʼĕlîyl",
		"pron" : "el-eel'",
		"derivation" : "apparently from H408 (אַל)",
		"def" : "good for nothing, by anal. vain or vanity; specifically an idol",
		"kjv" : "idol, no value, thing of nought"
	},
	"H458" : {
		"lemma" : "אֱלִימֶלֶךְ",
		"xlit" : "ʼĔlîymelek",
		"pron" : "el-ee-meh'-lek",
		"derivation" : "from H410 (אֵל) and H4428 (מֶלֶךְ); God of (the) king",
		"def" : "Elimelek, an Israelite",
		"kjv" : "Elimelech"
	},
	"H459" : {
		"lemma" : "אִלֵּין",
		"xlit" : "ʼillêyn",
		"pron" : "il-lane'",
		"derivation" : "(Aramaic) or shorter אִלֵּן; prolonged from H412 (אֵל)",
		"def" : "these",
		"kjv" : "the, these"
	},
	"H460" : {
		"lemma" : "אֶלְיָסָף",
		"xlit" : "ʼElyâçâph",
		"pron" : "el-yaw-sawf'",
		"derivation" : "from H410 (אֵל) and H3254 (יָסַף); God (is) gatherer",
		"def" : "Eljasaph, the name of two Israelites",
		"kjv" : "Eliasaph"
	},
	"H461" : {
		"lemma" : "אֱלִיעֶזֶר",
		"xlit" : "ʼĔlîyʻezer",
		"pron" : "el-ee-eh'-zer",
		"derivation" : "from H410 (אֵל) and H5828 (עֵזֶר); God of help",
		"def" : "Eliezer, the name of a Damascene and of ten Israelites",
		"kjv" : "Eliezer"
	},
	"H462" : {
		"lemma" : "אֱלִיעֵינַי",
		"xlit" : "ʼĔlîyʻêynay",
		"pron" : "el-ee-ay-nah'ee",
		"derivation" : "probably contracted for H454 (אֶלְיְהוֹעֵינַי)",
		"def" : "Elienai, an Israelite",
		"kjv" : "Elienai"
	},
	"H463" : {
		"lemma" : "אֱלִיעָם",
		"xlit" : "ʼĔlîyʻâm",
		"pron" : "el-ee-awm'",
		"derivation" : "from H410 (אֵל) and H5971 (עַם); God of (the) people",
		"def" : "Eliam, an Israelite",
		"kjv" : "Eliam"
	},
	"H464" : {
		"lemma" : "אֱלִיפַז",
		"xlit" : "ʼĔlîyphaz",
		"pron" : "el-ee-faz'",
		"derivation" : "from H410 (אֵל) and H6337 (פָּז); God of gold",
		"def" : "Eliphaz, the name of one of Job's friends, and of a son of Esau",
		"kjv" : "Eliphaz"
	},
	"H465" : {
		"lemma" : "אֱלִיפָל",
		"xlit" : "ʼĔlîyphâl",
		"pron" : "el-ee-fawl'",
		"derivation" : "from H410 (אֵל) and H6419 (פָּלַל); God of judgment",
		"def" : "Eliphal, an Israelite",
		"kjv" : "Eliphal"
	},
	"H466" : {
		"lemma" : "אֱלִיפְלֵהוּ",
		"xlit" : "ʼĔlîyphᵉlêhûw",
		"pron" : "el-ee-fe-lay'-hoo",
		"derivation" : "from H410 (אֵל) and H6395 (פָּלָה); God of his distinction",
		"def" : "Eliphelehu, an Israelite",
		"kjv" : "Elipheleh"
	},
	"H467" : {
		"lemma" : "אֱלִיפֶלֶט",
		"xlit" : "ʼĔlîypheleṭ",
		"pron" : "el-ee-feh'-let",
		"derivation" : "or (shortened) אֱלְפֶּלֶט; from H410 (אֵל) and H6405 (פַּלֵּט); God of deliverance",
		"def" : "Eliphelet or Elpelet, the name of six Israelites",
		"kjv" : "Eliphalet, Eliphelet, Elpalet"
	},
	"H468" : {
		"lemma" : "אֱלִיצוּר",
		"xlit" : "ʼĔlîytsûwr",
		"pron" : "el-ee-tsoor'",
		"derivation" : "from H410 (אֵל) and H6697 (צוּר); God of (the) rock",
		"def" : "Elitsur, an Israelite",
		"kjv" : "Elizur"
	},
	"H469" : {
		"lemma" : "אֱלִיצָפָן",
		"xlit" : "ʼĔlîytsâphân",
		"pron" : "el-ee-tsaw-fawn'",
		"derivation" : "or (shortened) אֶלְצָפָן; from H410 (אֵל) and H6845 (צָפַן); God of treasure",
		"def" : "Elitsaphan or Eltsaphan, an Israelite",
		"kjv" : "Elizaphan, Elzaphan"
	},
	"H470" : {
		"lemma" : "אֱלִיקָא",
		"xlit" : "ʼĔlîyqâʼ",
		"pron" : "el-ee-kaw'",
		"derivation" : "from H410 (אֵל) and H6958 (קוֹא); God of rejection",
		"def" : "Elika, an Israelite",
		"kjv" : "Elika"
	},
	"H471" : {
		"lemma" : "אֶלְיָקִים",
		"xlit" : "ʼElyâqîym",
		"pron" : "el-yaw-keem'",
		"derivation" : "from H410 (אֵל) and H6965 (קוּם); God of raising",
		"def" : "Eljakim, the name of four Israelites",
		"kjv" : "Eliakim"
	},
	"H472" : {
		"lemma" : "אֱלִישֶׁבַע",
		"xlit" : "ʼĔlîyshebaʻ",
		"pron" : "el-ee-sheh'-bah",
		"derivation" : "from H410 (אֵל) and H7651 (שֶׁבַע) (in the sense of H7650 (שָׁבַע)); God of (the) oath",
		"def" : "Elisheba, the wife of Aaron",
		"kjv" : "Elisheba"
	},
	"H473" : {
		"lemma" : "אֱלִישָׁה",
		"xlit" : "ʼĔlîyshâh",
		"pron" : "el-ee-shaw'",
		"derivation" : "probably of foreign derivation",
		"def" : "Elishah, a son of Javan",
		"kjv" : "Elishah"
	},
	"H474" : {
		"lemma" : "אֱלִישׁוּעַ",
		"xlit" : "ʼĔlîyshûwaʻ",
		"pron" : "el-ee-shoo'-ah",
		"derivation" : "from H410 (אֵל) and H7769 (שׁוּעַ); God of supplication (or of riches)",
		"def" : "Elishua, the son of King David",
		"kjv" : "Elishua"
	},
	"H475" : {
		"lemma" : "אֶלְיָשִׁיב",
		"xlit" : "ʼElyâshîyb",
		"pron" : "el-yaw-sheeb'",
		"derivation" : "from H410 (אֵל) and H7725 (שׁוּב); God will restore",
		"def" : "Eljashib, the name of six Israelites",
		"kjv" : "Eliashib"
	},
	"H476" : {
		"lemma" : "אֱלִישָׁמָע",
		"xlit" : "ʼĔlîyshâmâʻ",
		"pron" : "el-ee-shaw-maw'",
		"derivation" : "from H410 (אֵל) and H8085 (שָׁמַע); God of hearing",
		"def" : "Elishama, the name of seven Israelites",
		"kjv" : "Elishama"
	},
	"H477" : {
		"lemma" : "אֱלִישָׁע",
		"xlit" : "ʼĔlîyshâʻ",
		"pron" : "el-ee-shaw'",
		"derivation" : "contracted for H474 (אֱלִישׁוּעַ)",
		"def" : "Elisha, the famous prophet",
		"kjv" : "Elisha"
	},
	"H478" : {
		"lemma" : "אֱלִישָׁפָט",
		"xlit" : "ʼĔlîyshâphâṭ",
		"pron" : "el-ee-shaw-fawt'",
		"derivation" : "from H410 (אֵל) and H8199 (שָׁפַט); God of judgment",
		"def" : "Elishaphat, an Israelite",
		"kjv" : "Elishaphat"
	},
	"H479" : {
		"lemma" : "אִלֵּךְ",
		"xlit" : "ʼillêk",
		"pron" : "il-lake'",
		"derivation" : "(Aramaic) prolonged from H412 (אֵל)",
		"def" : "these",
		"kjv" : "these, those"
	},
	"H480" : {
		"lemma" : "אַלְלַי",
		"xlit" : "ʼalᵉlay",
		"pron" : "al-le-lah'ee",
		"derivation" : "by reduplication from H421 (אָלָה)",
		"def" : "alas!",
		"kjv" : "woe"
	},
	"H481" : {
		"lemma" : "אָלַם",
		"xlit" : "ʼâlam",
		"pron" : "aw-lam'",
		"derivation" : "a primitive root",
		"def" : "to tie fast; hence (of the mouth) to be tongue-tied",
		"kjv" : "bind, be dumb, put to silence"
	},
	"H482" : {
		"lemma" : "אֵלֶם",
		"xlit" : "ʼêlem",
		"pron" : "ay'-lem",
		"derivation" : "from H481 (אָלַם)",
		"def" : "silence (i.e. mute justice)",
		"kjv" : "congregation. Compare H3128 (יוֹנַת אֵלֶם רְחֹקִים)"
	},
	"H483" : {
		"lemma" : "אִלֵּם",
		"xlit" : "ʼillêm",
		"pron" : "il-lame'",
		"derivation" : "from H481 (אָלַם)",
		"def" : "speechless",
		"kjv" : "dumb (man)"
	},
	"H484" : {
		"lemma" : "אַלְמֻגִּים",
		"xlit" : "ʼalmuggîym",
		"pron" : "al-moog-gheem'",
		"derivation" : "probably of foreign derivation (used thus only in the plural)",
		"def" : "almug (i.e. probably sandle-wood) sticks",
		"kjv" : "almug trees. Compare H418 (אַלְגּוּמִּים)"
	},
	"H485" : {
		"lemma" : "אֲלֻמָּה",
		"xlit" : "ʼălummâh",
		"pron" : "al-oom-maw'",
		"derivation" : "or (masculine) אָלֻם; passive participle of H481 (אָלַם)",
		"def" : "something bound; a sheaf",
		"kjv" : "sheaf"
	},
	"H486" : {
		"lemma" : "אַלְמוֹדָד",
		"xlit" : "ʼAlmôwdâd",
		"pron" : "al-mo-dawd'",
		"derivation" : "probably of foreign derivation",
		"def" : "Almodad, a son of Joktan",
		"kjv" : "Almodad"
	},
	"H487" : {
		"lemma" : "אַלַּמֶּלֶךְ",
		"xlit" : "ʼAllammelek",
		"pron" : "al-lam-meh'-lek",
		"derivation" : "from H427 (אַלָּה) and H4428 (מֶלֶךְ); oak of (the) king",
		"def" : "Allammelek, a place in Palestine",
		"kjv" : "Alammelech"
	},
	"H488" : {
		"lemma" : "אַלְמָן",
		"xlit" : "ʼalmân",
		"pron" : "al-mawn'",
		"derivation" : "prolonged from H481 (אָלַם) in the sense of bereavement",
		"def" : "discarded (as a divorced person)",
		"kjv" : "forsaken"
	},
	"H489" : {
		"lemma" : "אַלְמֹן",
		"xlit" : "ʼalmôn",
		"pron" : "al-mone'",
		"derivation" : "from H481 (אָלַם) as in H488 (אַלְמָן)",
		"def" : "bereavement",
		"kjv" : "widowhood"
	},
	"H490" : {
		"lemma" : "אַלְמָנָה",
		"xlit" : "ʼalmânâh",
		"pron" : "al-maw-naw'",
		"derivation" : "feminine of H488 (אַלְמָן)",
		"def" : "a widow; also a desolate place",
		"kjv" : "desolate house (palace), widow"
	},
	"H491" : {
		"lemma" : "אַלְמָנוּת",
		"xlit" : "ʼalmânûwth",
		"pron" : "al-maw-nooth'",
		"derivation" : "feminine of H488 (אַלְמָן)",
		"def" : "concrete, a widow; abstract, widowhood",
		"kjv" : "widow, widowhood"
	},
	"H492" : {
		"lemma" : "אַלְמֹנִי",
		"xlit" : "ʼalmônîy",
		"pron" : "al-mo-nee'",
		"derivation" : "from H489 (אַלְמֹן) in the sense of concealment",
		"def" : "some one (i.e. so and so, without giving the name of the person or place)",
		"kjv" : "one, and such"
	},
	"H493" : {
		"lemma" : "אֶלְנַעַם",
		"xlit" : "ʼElnaʻam",
		"pron" : "el-nah'-am",
		"derivation" : "from H410 (אֵל) and H5276 (נָעֵם); God (is his) delight",
		"def" : "Elnaam, an Israelite",
		"kjv" : "Elnaam"
	},
	"H494" : {
		"lemma" : "אֶלְנָתָן",
		"xlit" : "ʼElnâthân",
		"pron" : "el-naw-thawn'",
		"derivation" : "from H410 (אֵל) and H5414 (נָתַן); God (is the) giver",
		"def" : "Elnathan, the name of four Israelites",
		"kjv" : "Elnathan"
	},
	"H495" : {
		"lemma" : "אֶלָּסָר",
		"xlit" : "ʼEllâçâr",
		"pron" : "el-law-sawr'",
		"derivation" : "probably of foreign derivation",
		"def" : "Ellasar, an early country of Asia",
		"kjv" : "Ellasar"
	},
	"H496" : {
		"lemma" : "אֶלְעָד",
		"xlit" : "ʼElʻâd",
		"pron" : "el-awd'",
		"derivation" : "from H410 (אֵל) and H5749 (עוּד); God has testified",
		"def" : "Elad, an Israelite",
		"kjv" : "Elead"
	},
	"H497" : {
		"lemma" : "אֶלְעָדָה",
		"xlit" : "ʼElʻâdâh",
		"pron" : "el-aw-daw'",
		"derivation" : "from H410 (אֵל) and H5710 (עָדָה); God has decked",
		"def" : "Eladah, an Israelite",
		"kjv" : "Eladah"
	},
	"H498" : {
		"lemma" : "אֶלְעוּזַי",
		"xlit" : "ʼElʻûwzay",
		"pron" : "el-oo-zah'ee",
		"derivation" : "from H410 (אֵל) and H5756 (עוּז) (in the sense of H5797 (עֹז)); God (is) defensive",
		"def" : "Eluzai, an Israelite",
		"kjv" : "Eluzai"
	},
	"H499" : {
		"lemma" : "אֶלְעָזָר",
		"xlit" : "ʼElʻâzâr",
		"pron" : "el-aw-zawr'",
		"derivation" : "from H410 (אֵל) and H5826 (עָזַר); God (is) helper",
		"def" : "Elazar, the name of seven Israelites",
		"kjv" : "Eleazar"
	},
	"H500" : {
		"lemma" : "אֶלְעָלֵא",
		"xlit" : "ʼElʻâlêʼ",
		"pron" : "el-aw-lay'",
		"derivation" : "or (more properly) אֶלְעָלֵה; from H410 (אֵל) and H5927 (עָלָה); God (is) going up",
		"def" : "Elale or Elaleh, a place east of the Jordan",
		"kjv" : "Elealeh"
	},
	"H501" : {
		"lemma" : "אֶלְעָשָׂה",
		"xlit" : "ʼElʻâsâh",
		"pron" : "el-aw-saw'",
		"derivation" : "from H410 (אֵל) and H6213 (עָשָׂה); God has made",
		"def" : "Elasah, the name of four Israelites",
		"kjv" : "Elasah, Eleasah"
	},
	"H502" : {
		"lemma" : "אָלַף",
		"xlit" : "ʼâlaph",
		"pron" : "aw-lof'",
		"derivation" : "a primitive root, to associate with",
		"def" : "hence, to learn (and causatively to teach)",
		"kjv" : "learn, teach, utter"
	},
	"H503" : {
		"lemma" : "אָלַף",
		"xlit" : "ʼâlaph",
		"pron" : "aw-laf'",
		"derivation" : "denominative from H505 (אֶלֶף)",
		"def" : "causative, to make athousandfold",
		"kjv" : "bring forth thousands"
	},
	"H504" : {
		"lemma" : "אֶלֶף",
		"xlit" : "ʼeleph",
		"pron" : "eh'-lef",
		"derivation" : "from H502 (אָלַף)",
		"def" : "a family; also (from the sense of yoking or taming) an ox or cow",
		"kjv" : "family, kine, oxen"
	},
	"H505" : {
		"lemma" : "אֶלֶף",
		"xlit" : "ʼeleph",
		"pron" : "eh'-lef",
		"derivation" : "prop, the same as H504 (אֶלֶף)",
		"def" : "hence (the ox's head being the first letter of the alphabet, and this eventually used as a numeral) a thousand",
		"kjv" : "thousand"
	},
	"H506" : {
		"lemma" : "אֲלַף",
		"xlit" : "ʼălaph",
		"pron" : "al-af'",
		"derivation" : "(Aramaic) or אֶלֶף; (Aramaic), corresponding to H505 (אֶלֶף)",
		"def" : "{hence (the ox's head being the first letter of the alphabet, and this eventually used as a numeral) a thousand}",
		"kjv" : "thousand"
	},
	"H507" : {
		"lemma" : "אֶלֶף",
		"xlit" : "ʼEleph",
		"pron" : "eh'-lef",
		"derivation" : "the same as H505 (אֶלֶף)",
		"def" : "Eleph, a place in Palestine",
		"kjv" : "Eleph"
	},
	"H508" : {
		"lemma" : "אֶלְפַּעַל",
		"xlit" : "ʼElpaʻal",
		"pron" : "el-pah'-al",
		"derivation" : "from H410 (אֵל) and H6466 (פָּעַל); God (is) act",
		"def" : "Elpaal, an Israelite",
		"kjv" : "Elpaal"
	},
	"H509" : {
		"lemma" : "אָלַץ",
		"xlit" : "ʼâlats",
		"pron" : "aw-lats'",
		"derivation" : "a primitive root",
		"def" : "to press",
		"kjv" : "urge"
	},
	"H510" : {
		"lemma" : "אַלְקוּם",
		"xlit" : "ʼalqûwm",
		"pron" : "al-koom'",
		"derivation" : "probably from H408 (אַל) and H6965 (קוּם)",
		"def" : "a non-rising (i.e. resistlessness)",
		"kjv" : "no rising up"
	},
	"H511" : {
		"lemma" : "אֶלְקָנָה",
		"xlit" : "ʼElqânâh",
		"pron" : "el-kaw-naw'",
		"derivation" : "from H410 (אֵל) and H7069 (קָנָה); God has obtained",
		"def" : "Elkanah, the name of several Israelites",
		"kjv" : "Elkanah"
	},
	"H512" : {
		"lemma" : "אֶלְקֹשִׁי",
		"xlit" : "ʼElqôshîy",
		"pron" : "el-ko-shee'",
		"derivation" : "patrial from a name of uncertain derivation",
		"def" : "an Elkoshite or native of Elkosh",
		"kjv" : "Elkoshite"
	},
	"H513" : {
		"lemma" : "אֶלְתּוֹלַד",
		"xlit" : "ʼEltôwlad",
		"pron" : "el-to-lad'",
		"derivation" : "probably from H410 (אֵל) and a masculine form of H8435 (תּוֹלְדָה) (compare H8434 (תּוֹלָד)); God (is) generator",
		"def" : "Eltolad, a place in Palestine",
		"kjv" : "Eltolad"
	},
	"H514" : {
		"lemma" : "אֶלְתְּקֵא",
		"xlit" : "ʼEltᵉqêʼ",
		"pron" : "el-te-kay'",
		"derivation" : "or (more properly) אֶלְתְּקֵה; of uncertain derivation",
		"def" : "Eltekeh or Elteke, a place in Palestine",
		"kjv" : "Eltekeh"
	},
	"H515" : {
		"lemma" : "אֶלְתְּקֹן",
		"xlit" : "ʼEltᵉqôn",
		"pron" : "el-te-kone'",
		"derivation" : "from H410 (אֵל) and H8626 (תָּקַן); God (is) straight",
		"def" : "Eltekon, a place in Palestine",
		"kjv" : "Eltekon"
	},
	"H516" : {
		"lemma" : "אַל תַּשְׁחֵת",
		"xlit" : "ʼAl tashchêth",
		"pron" : "al tash-kayth'",
		"derivation" : "from H408 (אַל) and H7843 (שָׁחַת)",
		"def" : "'Thou must not destroy'; probably the opening words to a popular song",
		"kjv" : "Al-taschith"
	},
	"H517" : {
		"lemma" : "אֵם",
		"xlit" : "ʼêm",
		"pron" : "ame",
		"derivation" : "a primitive word",
		"def" : "a mother (as the bond of the family); in a wide sense (both literally and figuratively (like father))",
		"kjv" : "dam, mother, [idiom] parting"
	},
	"H518" : {
		"lemma" : "אִם",
		"xlit" : "ʼim",
		"pron" : "eem",
		"derivation" : "a primitive particle",
		"def" : "used very widely as demonstrative, lo!; interrogative, whether?; or conditional, if, although; also Oh that!, when; hence, as a negative, not",
		"kjv" : "(and, can-, doubtless, if, that) (not), [phrase] but, either, [phrase] except, [phrase] more(-over if, than), neither, nevertheless, nor, oh that, or, [phrase] save (only, -ing), seeing, since, sith, [phrase] surely (no more, none, not), though, [phrase] of a truth, [phrase] unless, [phrase] verily, when, whereas, whether, while, [phrase] yet"
	},
	"H519" : {
		"lemma" : "אָמָה",
		"xlit" : "ʼâmâh",
		"pron" : "aw-maw'",
		"derivation" : "apparently a primitive word",
		"def" : "a maidservant or female slave",
		"kjv" : "(hand-) bondmaid(-woman), maid(-servant)"
	},
	"H520" : {
		"lemma" : "אַמָּה",
		"xlit" : "ʼammâh",
		"pron" : "am-maw'",
		"derivation" : "prolonged from H517 (אֵם)",
		"def" : "properly, a mother (i.e. unit of measure, or the fore-arm (below the elbow), i.e. a cubit; also a door-base (as a bond of the entrance)",
		"kjv" : "cubit, [phrase] hundred (by exchange for H3967 (מֵאָה)), measure, post"
	},
	"H521" : {
		"lemma" : "אַמָּה",
		"xlit" : "ʼammâh",
		"pron" : "am-maw'",
		"derivation" : "(Aramaic) corresponding to H520 (אַמָּה)",
		"def" : "{properly, a mother (i.e. unit of measure, or the fore-arm (below the elbow), i.e. a cubit; also a door-base (as a bond of the entrance)}",
		"kjv" : "cubit"
	},
	"H522" : {
		"lemma" : "אַמָּה",
		"xlit" : "ʼAmmâh",
		"pron" : "am-maw'",
		"derivation" : "the same as H520 (אַמָּה)",
		"def" : "Ammah, a hill in Palestine",
		"kjv" : "Ammah"
	},
	"H523" : {
		"lemma" : "אֻמַּה",
		"xlit" : "ʼummah",
		"pron" : "oom-maw'",
		"derivation" : "from the same as H517 (אֵם)",
		"def" : "a collection, i.e. community of persons",
		"kjv" : "nation, people"
	},
	"H524" : {
		"lemma" : "אֻמָּה",
		"xlit" : "ʼummâh",
		"pron" : "oom-maw'",
		"derivation" : "(Aramaic) corresponding to H523 (אֻמַּה)",
		"def" : "{a collection, i.e. community of persons}",
		"kjv" : "nation"
	},
	"H525" : {
		"lemma" : "אָמוֹן",
		"xlit" : "ʼâmôwn",
		"pron" : "aw-mone'",
		"derivation" : "from H539 (אָמַן), probably in the sense of training; like H542 (אָמָן)",
		"def" : "skilled, i.e. an architect",
		"kjv" : "one brought up"
	},
	"H526" : {
		"lemma" : "אָמוֹן",
		"xlit" : "ʼÂmôwn",
		"pron" : "aw-mone'",
		"derivation" : "the same as H525 (אָמוֹן)",
		"def" : "Amon, the name of three Israelites",
		"kjv" : "Amon"
	},
	"H527" : {
		"lemma" : "אָמוֹן",
		"xlit" : "ʼâmôwn",
		"pron" : "aw-mone'",
		"derivation" : "a variation for H1995 (הָמוֹן)",
		"def" : "a throng of people",
		"kjv" : "multitude"
	},
	"H528" : {
		"lemma" : "אָמוֹן",
		"xlit" : "ʼÂmôwn",
		"pron" : "aw-mone'",
		"derivation" : "of Egyptian derivation; used only as an adjunct of H4996 (נֹא)",
		"def" : "Amon (i.e. Ammon or Amn), a deity of Egypt",
		"kjv" : "multitude, populous"
	},
	"H529" : {
		"lemma" : "אֵמוּן",
		"xlit" : "ʼêmûwn",
		"pron" : "ay-moon'",
		"derivation" : "from H539 (אָמַן)",
		"def" : "established, i.e. (figuratively) trusty; also (abstractly) trustworthiness",
		"kjv" : "faith(-ful), truth"
	},
	"H530" : {
		"lemma" : "אֱמוּנָה",
		"xlit" : "ʼĕmûwnâh",
		"pron" : "em-oo-naw'",
		"derivation" : "or (shortened) אֱמֻנָה; feminine of H529 (אֵמוּן)",
		"def" : "literally firmness; figuratively security; morally fidelity",
		"kjv" : "faith(-ful, -ly, -ness, (man)), set office, stability, steady, truly, truth, verily"
	},
	"H531" : {
		"lemma" : "אָמוֹץ",
		"xlit" : "ʼÂmôwts",
		"pron" : "aw-mohts'",
		"derivation" : "from H553 (אָמַץ); strong",
		"def" : "Amots, an Israelite",
		"kjv" : "Amoz"
	},
	"H532" : {
		"lemma" : "אָמִי",
		"xlit" : "ʼÂmîy",
		"pron" : "aw-mee'",
		"derivation" : "an abbreviated form for H526 (אָמוֹן)",
		"def" : "Ami, an Israelite",
		"kjv" : "Ami"
	},
	"H533" : {
		"lemma" : "אַמִּיץ",
		"xlit" : "ʼammîyts",
		"pron" : "am-meets'",
		"derivation" : "or (shortened) אַמִּץ; from H553 (אָמַץ)",
		"def" : "strong or (abstractly) strength",
		"kjv" : "courageous, mighty, strong (one)"
	},
	"H534" : {
		"lemma" : "אָמִיר",
		"xlit" : "ʼâmîyr",
		"pron" : "aw-meer'",
		"derivation" : "apparently from H559 (אָמַר) (in the sense of self-exaltation)",
		"def" : "a summit (of a tree or mountain",
		"kjv" : "bough, branch"
	},
	"H535" : {
		"lemma" : "אָמַל",
		"xlit" : "ʼâmal",
		"pron" : "aw-mal'",
		"derivation" : "a primitive root",
		"def" : "to droop; by implication to be sick, to mourn",
		"kjv" : "languish, be weak, wax feeble"
	},
	"H536" : {
		"lemma" : "אֻמְלַל",
		"xlit" : "ʼumlal",
		"pron" : "oom-lal'",
		"derivation" : "from H535 (אָמַל)",
		"def" : "sick",
		"kjv" : "weak"
	},
	"H537" : {
		"lemma" : "אֲמֵלָל",
		"xlit" : "ʼămêlâl",
		"pron" : "am-ay-lawl'",
		"derivation" : "from H535 (אָמַל)",
		"def" : "languid",
		"kjv" : "feeble"
	},
	"H538" : {
		"lemma" : "אֲמָם",
		"xlit" : "ʼĂmâm",
		"pron" : "am-awm'",
		"derivation" : "from H517 (אֵם); gathering-spot",
		"def" : "Amam, a place in Palestine",
		"kjv" : "Amam"
	},
	"H539" : {
		"lemma" : "אָמַן",
		"xlit" : "ʼâman",
		"pron" : "aw-man'",
		"derivation" : "a primitive root; (Isaiah 30:21; interchangeable with H541 (אָמַן), to go to the right hand)",
		"def" : "properly, to build up or support; to foster as a parent or nurse; figuratively to render (or be) firm or faithful, to trust or believe, to be permanent or quiet; morally to be true or certain",
		"kjv" : "hence, assurance, believe, bring up, establish, [phrase] fail, be faithful (of long continuance, stedfast, sure, surely, trusty, verified), nurse, (-ing father), (put), trust, turn to the right"
	},
	"H540" : {
		"lemma" : "אֲמַן",
		"xlit" : "ʼăman",
		"pron" : "am-an'",
		"derivation" : "(Aramaic) corresponding to H539 (אָמַן)",
		"def" : "{properly, to build up or support; to foster as a parent or nurse; figuratively to render (or be) firm or faithful, to trust or believe, to be permanent or quiet; morally to be true or certain}",
		"kjv" : "believe, faithful, sure"
	},
	"H541" : {
		"lemma" : "אָמַן",
		"xlit" : "ʼâman",
		"pron" : "aw-man'",
		"derivation" : "denominative from H3225 (יָמִין)",
		"def" : "to take the right hand road",
		"kjv" : "turn to the right. See H539 (אָמַן)"
	},
	"H542" : {
		"lemma" : "אָמָן",
		"xlit" : "ʼâmân",
		"pron" : "aw-mawn'",
		"derivation" : "from H539 (אָמַן) (in the sense of training)",
		"def" : "an expert",
		"kjv" : "cunning workman"
	},
	"H543" : {
		"lemma" : "אָמֵן",
		"xlit" : "ʼâmên",
		"pron" : "aw-mane'",
		"derivation" : "from H539 (אָמַן)",
		"def" : "sure; abstract, faithfulness; adverb, truly",
		"kjv" : "Amen, so be it, truth"
	},
	"H544" : {
		"lemma" : "אֹמֶן",
		"xlit" : "ʼômen",
		"pron" : "oh-men'",
		"derivation" : "from H539 (אָמַן)",
		"def" : "verity",
		"kjv" : "truth"
	},
	"H545" : {
		"lemma" : "אׇמְנָה",
		"xlit" : "ʼomnâh",
		"pron" : "om-naw'",
		"derivation" : "feminine of H544 (אֹמֶן) (in the specific sense of training)",
		"def" : "tutelage",
		"kjv" : "brought up"
	},
	"H546" : {
		"lemma" : "אׇמְנָה",
		"xlit" : "ʼomnâh",
		"pron" : "om-naw'",
		"derivation" : "feminine form of H544 (אֹמֶן) (in its usual sense)",
		"def" : "adverb, surely",
		"kjv" : "indeed"
	},
	"H547" : {
		"lemma" : "אֹמְנָה",
		"xlit" : "ʼômᵉnâh",
		"pron" : "om-me-naw'",
		"derivation" : "feminine active participle of H544 (אֹמֶן) (in the original sense of supporting)",
		"def" : "a column",
		"kjv" : "pillar"
	},
	"H548" : {
		"lemma" : "אֲמָנָה",
		"xlit" : "ʼămânâh",
		"pron" : "am-aw-naw'",
		"derivation" : "feminine of H543 (אָמֵן)",
		"def" : "something fixed, i.e. a covenant. an allowance",
		"kjv" : "certain portion, sure"
	},
	"H549" : {
		"lemma" : "אֲמָנָה",
		"xlit" : "ʼĂmânâh",
		"pron" : "am-aw-naw'",
		"derivation" : "the same as H548 (אֲמָנָה)",
		"def" : "Amanah, a mountain near Damascus",
		"kjv" : "Amana"
	},
	"H550" : {
		"lemma" : "אַמְנוֹן",
		"xlit" : "ʼAmnôwn",
		"pron" : "am-nohn'",
		"derivation" : "or אֲמִינוֹן; from H539 (אָמַן); faithful",
		"def" : "Amnon (or Aminon), a son of David",
		"kjv" : "Amnon"
	},
	"H551" : {
		"lemma" : "אׇמְנָם",
		"xlit" : "ʼomnâm",
		"pron" : "om-nawm'",
		"derivation" : "adverb from H544 (אֹמֶן)",
		"def" : "verily",
		"kjv" : "indeed, no doubt, surely, (it is, of a) true(-ly, -th)"
	},
	"H552" : {
		"lemma" : "אֻמְנָם",
		"xlit" : "ʼumnâm",
		"pron" : "oom-nawm'",
		"derivation" : "an orthographical variation of H551 (אׇמְנָם)",
		"def" : "{verily}",
		"kjv" : "in (very) deed; of a surety"
	},
	"H553" : {
		"lemma" : "אָמַץ",
		"xlit" : "ʼâmats",
		"pron" : "aw-mats'",
		"derivation" : "a primitive root",
		"def" : "to be alert, physically (on foot) or mentally (in courage)",
		"kjv" : "confirm, be courageous (of good courage, stedfastly minded, strong, stronger), establish, fortify, harden, increase, prevail, strengthen (self), make strong (obstinate, speed)"
	},
	"H554" : {
		"lemma" : "אָמֹץ",
		"xlit" : "ʼâmôts",
		"pron" : "aw-mohts'",
		"derivation" : "probably from H553 (אָמַץ)",
		"def" : "of a strong color, i.e. red (others fleet)",
		"kjv" : "bay"
	},
	"H555" : {
		"lemma" : "אֹמֶץ",
		"xlit" : "ʼômets",
		"pron" : "o'-mets",
		"derivation" : "from H553 (אָמַץ)",
		"def" : "strength",
		"kjv" : "stronger"
	},
	"H556" : {
		"lemma" : "אַמְצָה",
		"xlit" : "ʼamtsâh",
		"pron" : "am-tsaw'",
		"derivation" : "from H553 (אָמַץ)",
		"def" : "force",
		"kjv" : "strength"
	},
	"H557" : {
		"lemma" : "אַמְצִי",
		"xlit" : "ʼAmtsîy",
		"pron" : "am-tsee'",
		"derivation" : "from H553 (אָמַץ); strong",
		"def" : "Amtsi, an Israelite",
		"kjv" : "Amzi"
	},
	"H558" : {
		"lemma" : "אֲמַצְיָה",
		"xlit" : "ʼĂmatsyâh",
		"pron" : "am-ats-yaw'",
		"derivation" : "or אֲמַצְיָהוּ; from H553 (אָמַץ) and H3050 (יָהּ); strength of Jah",
		"def" : "Amatsjah, the name of four Israelites",
		"kjv" : "Amaziah"
	},
	"H559" : {
		"lemma" : "אָמַר",
		"xlit" : "ʼâmar",
		"pron" : "aw-mar'",
		"derivation" : "a primitive root",
		"def" : "to say (used with great latitude)",
		"kjv" : "answer, appoint, avouch, bid, boast self, call, certify, challenge, charge, [phrase] (at the, give) command(-ment), commune, consider, declare, demand, [idiom] desire, determine, [idiom] expressly, [idiom] indeed, [idiom] intend, name, [idiom] plainly, promise, publish, report, require, say, speak (against, of), [idiom] still, [idiom] suppose, talk, tell, term, [idiom] that is, [idiom] think, use (speech), utter, [idiom] verily, [idiom] yet"
	},
	"H560" : {
		"lemma" : "אֲמַר",
		"xlit" : "ʼămar",
		"pron" : "am-ar'",
		"derivation" : "(Aramaic) corresponding to H559 (אָמַר)",
		"def" : "{to say (used with great latitude)}",
		"kjv" : "command, declare, say, speak, tell"
	},
	"H561" : {
		"lemma" : "אֵמֶר",
		"xlit" : "ʼêmer",
		"pron" : "ay'-mer",
		"derivation" : "from H559 (אָמַר)",
		"def" : "something said",
		"kjv" : "answer, [idiom] appointed unto him, saying, speech, word"
	},
	"H562" : {
		"lemma" : "אֹמֶר",
		"xlit" : "ʼômer",
		"pron" : "o'-mer",
		"derivation" : "the same as H561 (אֵמֶר)",
		"def" : "{something said}",
		"kjv" : "promise, speech, thing, word"
	},
	"H563" : {
		"lemma" : "אִמַּר",
		"xlit" : "ʼimmar",
		"pron" : "im-mar'",
		"derivation" : "(Aramaic) perhaps from H560 (אֲמַר) (in the sense of bringing forth)",
		"def" : "a lamb",
		"kjv" : "lamb"
	},
	"H564" : {
		"lemma" : "אִמֵּר",
		"xlit" : "ʼImmêr",
		"pron" : "im-mare'",
		"derivation" : "from H559 (אָמַר); talkative",
		"def" : "Immer, the name of five Israelites",
		"kjv" : "Immer"
	},
	"H565" : {
		"lemma" : "אִמְרָה",
		"xlit" : "ʼimrâh",
		"pron" : "im-raw'",
		"derivation" : "or אֶמְרָה; feminine of H561 (אֵמֶר), and meaning the same",
		"def" : "{something said}",
		"kjv" : "commandment, speech, word"
	},
	"H566" : {
		"lemma" : "אִמְרִי",
		"xlit" : "ʼImrîy",
		"pron" : "im-ree'",
		"derivation" : "from H564 (אִמֵּר)",
		"def" : "Imri, the name of two Israelites",
		"kjv" : "Imri"
	},
	"H567" : {
		"lemma" : "אֱמֹרִי",
		"xlit" : "ʼĔmôrîy",
		"pron" : "em-o-ree'",
		"derivation" : "probably a patronymic from an unused name derived from H559 (אָמַר) in the sense of publicity, i.e. prominence; thus, a mountaineer",
		"def" : "an Emorite, one of the Canaanitish tribes",
		"kjv" : "Amorite"
	},
	"H568" : {
		"lemma" : "אֲמַרְיָה",
		"xlit" : "ʼĂmaryâh",
		"pron" : "am-ar-yaw'",
		"derivation" : "or prolonged אֲמַרְיָהוּ; from H559 (אָמַר) and H3050 (יָהּ); Jah has said (i.e. promised)",
		"def" : "Amarjah, the name of nine Israelites",
		"kjv" : "Amariah"
	},
	"H569" : {
		"lemma" : "אַמְרָפֶל",
		"xlit" : "ʼAmrâphel",
		"pron" : "am-raw-fel'",
		"derivation" : "of uncertain (perhaps foreign) derivation",
		"def" : "Amraphel, a king of Shinar",
		"kjv" : "Amraphel"
	},
	"H570" : {
		"lemma" : "אֶמֶשׁ",
		"xlit" : "ʼemesh",
		"pron" : "eh'-mesh",
		"derivation" : "time past, i.e",
		"def" : "yesterday or last night",
		"kjv" : "former time, yesterday(-night)"
	},
	"H571" : {
		"lemma" : "אֶמֶת",
		"xlit" : "ʼemeth",
		"pron" : "eh'-meth",
		"derivation" : "contracted from H539 (אָמַן)",
		"def" : "stability; (figuratively) certainty, truth, trustworthiness",
		"kjv" : "assured(-ly), establishment, faithful, right, sure, true (-ly, -th), verity"
	},
	"H572" : {
		"lemma" : "אַמְתַּחַת",
		"xlit" : "ʼamtachath",
		"pron" : "am-takh'-ath",
		"derivation" : "from H4969 (מָתַח)",
		"def" : "properly, something expansive, i.e. a bag",
		"kjv" : "sack"
	},
	"H573" : {
		"lemma" : "אֲמִתַּי",
		"xlit" : "ʼĂmittay",
		"pron" : "am-it-tah'ee",
		"derivation" : "from H571 (אֶמֶת); veracious",
		"def" : "Amittai, an Israelite",
		"kjv" : "Amittai"
	},
	"H574" : {
		"lemma" : "אֵמְתָּנִי",
		"xlit" : "ʼêmᵉtânîy",
		"pron" : "em-taw-nee'",
		"derivation" : "(Aramaic) from a root corresponding to that of H4975 (מֹתֶן)",
		"def" : "well-loined (i.e. burly) or mighty",
		"kjv" : "terrible"
	},
	"H575" : {
		"lemma" : "אָן",
		"xlit" : "ʼân",
		"pron" : "awn",
		"derivation" : "or אָנָה; contracted from H370 (אַיִן)",
		"def" : "where?; hence, whither?, when?; also hither and thither",
		"kjv" : "[phrase] any (no) whither, now, where, whither(-soever)"
	},
	"H576" : {
		"lemma" : "אֲנָא",
		"xlit" : "ʼănâʼ",
		"pron" : "an-aw'",
		"derivation" : "(Aramaic) or אֲנָה; (Aramaic), corresponding to H589 (אֲנִי)lemma אֲנַי second vowel, corrected to אֲנִי",
		"def" : "I",
		"kjv" : "I, as for me"
	},
	"H577" : {
		"lemma" : "אָנָּא",
		"xlit" : "ʼânnâʼ",
		"pron" : "awn-naw'",
		"derivation" : "or אָנָּה; apparent contracted from H160 (אַהֲבָה) and H4994 (נָא)",
		"def" : "oh now!",
		"kjv" : "I (me) beseech (pray) thee, O"
	},
	"H578" : {
		"lemma" : "אָנָה",
		"xlit" : "ʼânâh",
		"pron" : "aw-naw'",
		"derivation" : "a primitive root",
		"def" : "to groan",
		"kjv" : "lament, mourn"
	},
	"H579" : {
		"lemma" : "אָנָה",
		"xlit" : "ʼânâh",
		"pron" : "aw-naw'",
		"derivation" : "a primitive root (perhaps rather identical with H578 (אָנָה) through the idea of contraction in anguish)",
		"def" : "to approach; hence, to meet in various senses",
		"kjv" : "befall, deliver, happen, seek a quarrel"
	},
	"H580" : {
		"lemma" : "אֲנוּ",
		"xlit" : "ʼănûw",
		"pron" : "an-oo'",
		"derivation" : "contracted for H587 (אֲנַחְנוּ)",
		"def" : "we",
		"kjv" : "we"
	},
	"H581" : {
		"lemma" : "אִנּוּן",
		"xlit" : "ʼinnûwn",
		"pron" : "in-noon'",
		"derivation" : "(Aramaic) or (feminine) אִנִּיןlemma אֵנִּין first vowel, corrected to אִנִּין; (Aramaic), corresponding to H1992 (הֵם)",
		"def" : "they",
		"kjv" : "[idiom] are, them, these"
	},
	"H582" : {
		"lemma" : "אֱנוֹשׁ",
		"xlit" : "ʼĕnôwsh",
		"pron" : "en-oshe'",
		"derivation" : "from H605 (אָנַשׁ); properly, a mortal (and thus differing from the more dignified H120 (אָדָם)); hence",
		"def" : "a man in general (singly or collectively)",
		"kjv" : "another, [idiom] (blood-) thirsty, certain, chap(-man); divers, fellow, [idiom] in the flower of their age, husband, (certain, mortal) man, people, person, servant, some ([idiom] of them), [phrase] stranger, those, [phrase] their trade. It is often unexpressed in the English versions, especially when used in apposition with another word. Compare H376 (אִישׁ)"
	},
	"H583" : {
		"lemma" : "אֱנוֹשׁ",
		"xlit" : "ʼĔnôwsh",
		"pron" : "en-ohsh'",
		"derivation" : "the same as H582 (אֱנוֹשׁ)",
		"def" : "Enosh, a son of Seth",
		"kjv" : "Enos"
	},
	"H584" : {
		"lemma" : "אָנַח",
		"xlit" : "ʼânach",
		"pron" : "aw-nakh'",
		"derivation" : "a primitive root",
		"def" : "to sigh",
		"kjv" : "groan, mourn, sigh"
	},
	"H585" : {
		"lemma" : "אֲנָחָה",
		"xlit" : "ʼănâchâh",
		"pron" : "an-aw-khaw'",
		"derivation" : "from H585 (אֲנָחָה)",
		"def" : "sighing",
		"kjv" : "groaning, mourn, sigh"
	},
	"H586" : {
		"lemma" : "אֲנַחְנָא",
		"xlit" : "ʼănachnâʼ",
		"pron" : "an-akh'-naw",
		"derivation" : "(Aramaic) or אֲנַחְנָה; (Aramaic), corresponding to H587 (אֲנַחְנוּ)",
		"def" : "we",
		"kjv" : "we"
	},
	"H587" : {
		"lemma" : "אֲנַחְנוּ",
		"xlit" : "ʼănachnûw",
		"pron" : "an-akh'-noo",
		"derivation" : "apparently from H595 (אָנֹכִי)",
		"def" : "we",
		"kjv" : "ourselves, us, we"
	},
	"H588" : {
		"lemma" : "אֲנָחֲרָת",
		"xlit" : "ʼĂnâchărâth",
		"pron" : "an-aw-kha-rawth'",
		"derivation" : "probably from the same root as H5170 (נַחַר); a gorge or narrow pass",
		"def" : "Anacharath, a place in Palestine",
		"kjv" : "Anaharath"
	},
	"H589" : {
		"lemma" : "אֲנִי",
		"xlit" : "ʼănîy",
		"pron" : "an-ee'",
		"derivation" : "contracted from H595 (אָנֹכִי)",
		"def" : "I",
		"kjv" : "I, (as for) me, mine, myself, we, [idiom] which, [idiom] who"
	},
	"H590" : {
		"lemma" : "אֳנִי",
		"xlit" : "ʼŏnîy",
		"pron" : "on-ee'",
		"derivation" : "probably from H579 (אָנָה) (in the sense of conveyance)",
		"def" : "-a ship or (collectively) a fleet",
		"kjv" : "galley, navy (of ships)"
	},
	"H591" : {
		"lemma" : "אֳנִיָּה",
		"xlit" : "ʼŏnîyâh",
		"pron" : "on-ee-yaw'",
		"derivation" : "feminine of H590 (אֳנִי)",
		"def" : "a ship",
		"kjv" : "ship(-men)"
	},
	"H592" : {
		"lemma" : "אֲנִיָּה",
		"xlit" : "ʼănîyâh",
		"pron" : "an-ee-yaw'",
		"derivation" : "from H578 (אָנָה)",
		"def" : "groaning",
		"kjv" : "lamentation, sorrow"
	},
	"H593" : {
		"lemma" : "אֲנִיעָם",
		"xlit" : "ʼĂnîyʻâm",
		"pron" : "an-ee-awm'",
		"derivation" : "from H578 (אָנָה) and H5971 (עַם); groaning of (the) people",
		"def" : "Aniam, an Israelite",
		"kjv" : "Aniam"
	},
	"H594" : {
		"lemma" : "אֲנָךְ",
		"xlit" : "ʼănâk",
		"pron" : "an-awk'",
		"derivation" : "probably from an unused root meaning to be narrow",
		"def" : "according to most a plumb-line, and to others a hook",
		"kjv" : "plumb-line"
	},
	"H595" : {
		"lemma" : "אָנֹכִי",
		"xlit" : "ʼânôkîy",
		"pron" : "aw-no-kee'",
		"derivation" : "sometimes, ; a primitive pronoun",
		"def" : "I",
		"kjv" : "I, me, [idiom] which"
	},
	"H596" : {
		"lemma" : "אָנַן",
		"xlit" : "ʼânan",
		"pron" : "aw-nan'",
		"derivation" : "a primitive root",
		"def" : "to mourn, i.e. complain",
		"kjv" : "complain"
	},
	"H597" : {
		"lemma" : "אָנַס",
		"xlit" : "ʼânaç",
		"pron" : "aw-nas'",
		"def" : "to insist",
		"kjv" : "compel"
	},
	"H598" : {
		"lemma" : "אֲנַס",
		"xlit" : "ʼănaç",
		"pron" : "an-as'",
		"derivation" : "(Aramaic) corresponding to H597 (אָנַס)",
		"def" : "figuratively, to distress",
		"kjv" : "trouble"
	},
	"H599" : {
		"lemma" : "אָנַף",
		"xlit" : "ʼânaph",
		"pron" : "aw-naf'",
		"derivation" : "a primitive root",
		"def" : "to breathe hard, i.e. be enraged",
		"kjv" : "be angry (displeased)"
	},
	"H600" : {
		"lemma" : "אֲנַף",
		"xlit" : "ʼănaph",
		"pron" : "an-af'",
		"derivation" : "(Aramaic) corresponding to H639 (אַף) (only in the plural as a singular)",
		"def" : "the face",
		"kjv" : "face, visage"
	},
	"H601" : {
		"lemma" : "אֲנָפָה",
		"xlit" : "ʼănâphâh",
		"pron" : "an-aw-faw'",
		"derivation" : "from H599 (אָנַף)",
		"def" : "an unclean bird, perhaps the parrot (from its irascibility)",
		"kjv" : "heron"
	},
	"H602" : {
		"lemma" : "אָנַק",
		"xlit" : "ʼânaq",
		"pron" : "aw-nak'",
		"derivation" : "a primitive root",
		"def" : "to shriek",
		"kjv" : "cry, groan"
	},
	"H603" : {
		"lemma" : "אֲנָקָה",
		"xlit" : "ʼănâqâh",
		"pron" : "an-aw-kaw'",
		"derivation" : "from H602 (אָנַק)",
		"def" : "shrieking",
		"kjv" : "crying out, groaning, sighing"
	},
	"H604" : {
		"lemma" : "אֲנָקָה",
		"xlit" : "ʼănâqâh",
		"pron" : "an-aw-kaw'",
		"derivation" : "the same as H603 (אֲנָקָה)",
		"def" : "some kind of lizard, probably the gecko (from its wail)",
		"kjv" : "ferret"
	},
	"H605" : {
		"lemma" : "אָנַשׁ",
		"xlit" : "ʼânash",
		"pron" : "aw-nash'",
		"derivation" : "a primitive root",
		"def" : "to be frail, feeble, or (figuratively) melancholy",
		"kjv" : "desperate(-ly wicked), incurable, sick, woeful"
	},
	"H606" : {
		"lemma" : "אֱנָשׁ",
		"xlit" : "ʼĕnâsh",
		"pron" : "en-awsh'",
		"derivation" : "(Aramaic) or אֱנַשׁ; (Aramaic), corresponding to H582 (אֱנוֹשׁ)",
		"def" : "a man",
		"kjv" : "man, [phrase] whosoever"
	},
	"H607" : {
		"lemma" : "אַנְתָּה",
		"xlit" : "ʼantâh",
		"pron" : "an-taw'",
		"derivation" : "(Aramaic) corresponding to H859 (אַתָּה)",
		"def" : "thou",
		"kjv" : "as for thee, thou"
	},
	"H608" : {
		"lemma" : "אַנְתּוּן",
		"xlit" : "ʼantûwn",
		"pron" : "an-toon'",
		"derivation" : "(Aramaic) plural of H607 (אַנְתָּה)",
		"def" : "ye",
		"kjv" : "ye"
	},
	"H609" : {
		"lemma" : "אָסָא",
		"xlit" : "ʼÂçâʼ",
		"pron" : "aw-saw'",
		"derivation" : "of uncertain derivation",
		"def" : "Asa, the name of a king and of a Levite",
		"kjv" : "Asa"
	},
	"H610" : {
		"lemma" : "אָסוּךְ",
		"xlit" : "ʼâçûwk",
		"pron" : "aw-sook'",
		"derivation" : "from H5480 (סוּךְ)",
		"def" : "anointed, i.e. an oil-flask",
		"kjv" : "pot"
	},
	"H611" : {
		"lemma" : "אָסוֹן",
		"xlit" : "ʼâçôwn",
		"pron" : "aws-sone'",
		"derivation" : "of uncertain derivation",
		"def" : "hurt",
		"kjv" : "mischief"
	},
	"H612" : {
		"lemma" : "אֵסוּר",
		"xlit" : "ʼêçûwr",
		"pron" : "ay-soor'",
		"derivation" : "from H631 (אָסַר)",
		"def" : "a bond (especially manacles of a prisoner)",
		"kjv" : "band, [phrase] prison"
	},
	"H613" : {
		"lemma" : "אֱסוּר",
		"xlit" : "ʼĕçûwr",
		"pron" : "es-oor'",
		"derivation" : "(Aramaic) corresponding to H612 (אֵסוּר)",
		"def" : "{a bond (especially manacles of a prisoner)}",
		"kjv" : "band, imprisonment"
	},
	"H614" : {
		"lemma" : "אָסִיף",
		"xlit" : "ʼâçîyph",
		"pron" : "aw-seef'",
		"derivation" : "or אָסִף; from H622 (אָסַף)",
		"def" : "gathered, i.e. (abstractly) a gathering in of crops",
		"kjv" : "ingathering"
	},
	"H615" : {
		"lemma" : "אָסִיר",
		"xlit" : "ʼâçîyr",
		"pron" : "aw-sere'",
		"derivation" : "from H631 (אָסַר)",
		"def" : "bound, i.e. a captive",
		"kjv" : "(those which are) bound, prisoner"
	},
	"H616" : {
		"lemma" : "אַסִּיר",
		"xlit" : "ʼaççîyr",
		"pron" : "as-sere'",
		"derivation" : "for H615 (אָסִיר)",
		"def" : "{bound, i.e. a captive}",
		"kjv" : "prisoner"
	},
	"H617" : {
		"lemma" : "אַסִּיר",
		"xlit" : "ʼAççîyr",
		"pron" : "as-sere'",
		"derivation" : "the same as H616 (אַסִּיר); prisoner",
		"def" : "Assir, the name of two Israelites",
		"kjv" : "Assir"
	},
	"H618" : {
		"lemma" : "אָסָם",
		"xlit" : "ʼâçâm",
		"pron" : "aw-sawm'",
		"derivation" : "from an unused root meaning to heap together",
		"def" : "a storehouse (only in the plural)",
		"kjv" : "barn, storehouse"
	},
	"H619" : {
		"lemma" : "אַסְנָה",
		"xlit" : "ʼAçnâh",
		"pron" : "as-naw'",
		"derivation" : "of uncertain derivation",
		"def" : "Asnah, one of the Nethinim",
		"kjv" : "Asnah"
	},
	"H620" : {
		"lemma" : "אׇסְנַפַּר",
		"xlit" : "ʼOçnappar",
		"pron" : "os-nap-par'",
		"derivation" : "of foreign derivation",
		"def" : "Osnappar, an Assyrian king",
		"kjv" : "Asnapper"
	},
	"H621" : {
		"lemma" : "אָסְנַת",
		"xlit" : "ʼÂçᵉnath",
		"pron" : "aw-se-nath'",
		"derivation" : "of Egyptian derivation",
		"def" : "Asenath, the wife of Joseph",
		"kjv" : "Asenath"
	},
	"H622" : {
		"lemma" : "אָסַף",
		"xlit" : "ʼâçaph",
		"pron" : "aw-saf'",
		"derivation" : "a primitive root",
		"def" : "to gather for any purpose; hence, to receive, take away, i.e. remove (destroy, leave behind, put up, restore, etc.)",
		"kjv" : "assemble, bring, consume, destroy, felch, gather (in, together, up again), [idiom] generally, get (him), lose, put all together, receive, recover (another from leprosy), (be) rereward, [idiom] surely, take (away, into, up), [idiom] utterly, withdraw"
	},
	"H623" : {
		"lemma" : "אָסָף",
		"xlit" : "ʼÂçâph",
		"pron" : "aw-sawf'",
		"derivation" : "from H622 (אָסַף); collector",
		"def" : "Asaph, the name of three Israelites, and of the family of the first",
		"kjv" : "Asaph"
	},
	"H624" : {
		"lemma" : "אָסֻף",
		"xlit" : "ʼâçuph",
		"pron" : "aw-soof'",
		"derivation" : "passive participle of H622 (אָסַף)",
		"def" : "collected (only in the plural), i.e. a collection of offerings)",
		"kjv" : "threshold, Asuppim"
	},
	"H625" : {
		"lemma" : "אֹסֶף",
		"xlit" : "ʼôçeph",
		"pron" : "o'-sef",
		"derivation" : "from H622 (אָסַף)",
		"def" : "a collection (of fruits)",
		"kjv" : "gathering"
	},
	"H626" : {
		"lemma" : "אֲסֵפָה",
		"xlit" : "ʼăçêphâh",
		"pron" : "as-ay-faw'",
		"derivation" : "from H622 (אָסַף)",
		"def" : "a collection of people (only adverbial)",
		"kjv" : "[idiom] together"
	},
	"H627" : {
		"lemma" : "אֲסֻפָּה",
		"xlit" : "ʼăçuppâh",
		"pron" : "as-up-paw'",
		"derivation" : "feminine of H624 (אָסֻף)",
		"def" : "a collection of (learned) men (only in the plural)",
		"kjv" : "assembly"
	},
	"H628" : {
		"lemma" : "אֲסְפְּסֻף",
		"xlit" : "ʼăçpᵉçuph",
		"pron" : "as-pes-oof'",
		"derivation" : "by reduplication from H624 (אָסֻף)",
		"def" : "gathered up together, i.e. a promiscuous assemblage (of people)",
		"kjv" : "mixt multitude"
	},
	"H629" : {
		"lemma" : "אׇסְפַּרְנָא",
		"xlit" : "ʼoçparnâʼ",
		"pron" : "os-par-naw'",
		"derivation" : "(Aramaic) of Persian derivation",
		"def" : "diligently",
		"kjv" : "fast, forthwith, speed(-ily)"
	},
	"H630" : {
		"lemma" : "אַסְפָּתָא",
		"xlit" : "ʼAçpâthâʼ",
		"pron" : "as-paw-thaw'",
		"derivation" : "of Persian derivation",
		"def" : "Aspatha, a son of Haman",
		"kjv" : "Aspatha"
	},
	"H631" : {
		"lemma" : "אָסַר",
		"xlit" : "ʼâçar",
		"pron" : "aw-sar'",
		"derivation" : "a primitive root",
		"def" : "to yoke or hitch; by analogy, to fasten in any sense, to join battle",
		"kjv" : "bind, fast, gird, harness, hold, keep, make ready, order, prepare, prison(-er), put in bonds, set in array, tie"
	},
	"H632" : {
		"lemma" : "אֱסָר",
		"xlit" : "ʼĕçâr",
		"pron" : "es-sawr'",
		"derivation" : "or אִסָּר; from H631 (אָסַר)",
		"def" : "an obligation or vow (of abstinence)",
		"kjv" : "binding, bond"
	},
	"H633" : {
		"lemma" : "אֱסָר",
		"xlit" : "ʼĕçâr",
		"pron" : "es-sawr'",
		"derivation" : "(Aramaic) corresponding to H632 (אֱסָר) in a legal sense",
		"def" : "an interdict",
		"kjv" : "decree"
	},
	"H634" : {
		"lemma" : "אֵסַר־חַדּוֹן",
		"xlit" : "ʼÊçar-Chaddôwn",
		"pron" : "ay-sar' Chad-dohn'",
		"derivation" : "of foreign derivation",
		"def" : "Esar-chaddon, an Assyrian king",
		"kjv" : "Esar-haddon"
	},
	"H635" : {
		"lemma" : "אֶסְתֵּר",
		"xlit" : "ʼEçtêr",
		"pron" : "es-tare'",
		"derivation" : "of Persian derivation",
		"def" : "Ester, the Jewish heroine",
		"kjv" : "Esther"
	},
	"H636" : {
		"lemma" : "אָע",
		"xlit" : "ʼâʻ",
		"pron" : "aw",
		"derivation" : "(Aramaic) corresponding to H6086 (עֵץ)",
		"def" : "a tree or wood",
		"kjv" : "timber, wood"
	},
	"H637" : {
		"lemma" : "אַף",
		"xlit" : "ʼaph",
		"pron" : "af",
		"derivation" : "a primitive particle",
		"def" : "meaning accession (used as an adverb or conjunction); also or yea; adversatively though",
		"kjv" : "also, [phrase] although, and (furthermore, yet), but, even, [phrase] how much less (more, rather than), moreover, with, yea"
	},
	"H638" : {
		"lemma" : "אַף",
		"xlit" : "ʼaph",
		"pron" : "af",
		"derivation" : "(Aramaic) corresponding to H637 (אַף)",
		"def" : "{meaning accession (used as an adverb or conjunction); also or yea; adversatively though}",
		"kjv" : "also"
	},
	"H639" : {
		"lemma" : "אַף",
		"xlit" : "ʼaph",
		"pron" : "af",
		"derivation" : "from H599 (אָנַף)",
		"def" : "properly, the nose or nostril; hence, the face, and occasionally a person; also (from the rapid breathing in passion) ire",
		"kjv" : "anger(-gry), [phrase] before, countenance, face, [phrase] forebearing, forehead, [phrase] (long-) suffering, nose, nostril, snout, [idiom] worthy, wrath"
	},
	"H640" : {
		"lemma" : "אָפַד",
		"xlit" : "ʼâphad",
		"pron" : "aw-fad'",
		"derivation" : "a primitive root (rather a denominative from H646 (אֵפוֹד))",
		"def" : "to gird on (the ephod)",
		"kjv" : "bind, gird"
	},
	"H641" : {
		"lemma" : "אֵפֹד",
		"xlit" : "ʼÊphôd",
		"pron" : "ay-fode'",
		"derivation" : "the same as H646 (אֵפוֹד) shortened",
		"def" : "Ephod, an Israelite",
		"kjv" : "Ephod"
	},
	"H642" : {
		"lemma" : "אֵפֻדָּה",
		"xlit" : "ʼêphuddâh",
		"pron" : "ay-food-daw'",
		"derivation" : "feminine of H646 (אֵפוֹד)",
		"def" : "a girding on (of the ephod); hence, generally, a plating (of metal)",
		"kjv" : "ephod, ornament"
	},
	"H643" : {
		"lemma" : "אַפֶּדֶן",
		"xlit" : "ʼappeden",
		"pron" : "ap-peh'-den",
		"derivation" : "apparently of foreign derivation",
		"def" : "a pavilion or palace-tent",
		"kjv" : "palace"
	},
	"H644" : {
		"lemma" : "אָפָה",
		"xlit" : "ʼâphâh",
		"pron" : "aw-faw'",
		"derivation" : "a primitive root",
		"def" : "to cook, especially to bake",
		"kjv" : "bake(-r, (-meats))"
	},
	"H645" : {
		"lemma" : "אֵפוֹ",
		"xlit" : "ʼêphôw",
		"pron" : "ay-fo'",
		"derivation" : "or אֵפוֹא; from H6311 (פֹּה)",
		"def" : "strictly a demonstrative particle, here; but used of time, now or then",
		"kjv" : "here, now, where?"
	},
	"H646" : {
		"lemma" : "אֵפוֹד",
		"xlit" : "ʼêphôwd",
		"pron" : "ay-fode'",
		"derivation" : "rarely אֵפֹד; probably of foreign derivation",
		"def" : "a girdle; specifically the ephod or highpriest's shoulder-piece; also generally, an image",
		"kjv" : "ephod"
	},
	"H647" : {
		"lemma" : "אֲפִיחַ",
		"xlit" : "ʼĂphîyach",
		"pron" : "af-ee'-akh",
		"derivation" : "perhaps from H6315 (פּוּחַ); breeze",
		"def" : "Aphiach, an Israelite",
		"kjv" : "Aphiah"
	},
	"H648" : {
		"lemma" : "אָפִיל",
		"xlit" : "ʼâphîyl",
		"pron" : "aw-feel'",
		"derivation" : "from the same as H651 (אָפֵל) (in the sense of weakness)",
		"def" : "unripe",
		"kjv" : "not grown up"
	},
	"H649" : {
		"lemma" : "אַפַּיִם",
		"xlit" : "ʼAppayim",
		"pron" : "ap-pah'-yim",
		"derivation" : "dual of H639 (אַף); two nostrils",
		"def" : "Appajim, an Israelite",
		"kjv" : "Appaim"
	},
	"H650" : {
		"lemma" : "אָפִיק",
		"xlit" : "ʼâphîyq",
		"pron" : "aw-feek'",
		"derivation" : "from H622 (אָסַף)",
		"def" : "properly, containing, i.e. a tube; also a bed or valley of astream; also a strong thing or a hero",
		"kjv" : "brook, channel, mighty, river, [phrase] scale, stream, strong piece"
	},
	"H651" : {
		"lemma" : "אָפֵל",
		"xlit" : "ʼâphêl",
		"pron" : "aw-fale'",
		"derivation" : "from an unused root meaning to set as the sun",
		"def" : "dusky",
		"kjv" : "very dark"
	},
	"H652" : {
		"lemma" : "אֹפֶל",
		"xlit" : "ʼôphel",
		"pron" : "o'fel",
		"derivation" : "from the same as H651 (אָפֵל)",
		"def" : "dusk",
		"kjv" : "darkness, obscurity, privily"
	},
	"H653" : {
		"lemma" : "אֲפֵלָה",
		"xlit" : "ʼăphêlâh",
		"pron" : "af-ay-law'",
		"derivation" : "feminine of H651 (אָפֵל)",
		"def" : "duskiness, figuratively, misfortune; concrete, concealment",
		"kjv" : "dark, darkness, gloominess, [idiom] thick"
	},
	"H654" : {
		"lemma" : "אֶפְלָל",
		"xlit" : "ʼEphlâl",
		"pron" : "ef-lawl'",
		"derivation" : "from H6419 (פָּלַל); judge",
		"def" : "Ephlal, an Israelite",
		"kjv" : "Ephlal"
	},
	"H655" : {
		"lemma" : "אֹפֶן",
		"xlit" : "ʼôphen",
		"pron" : "o'-fen",
		"derivation" : "from an unused root meaning to revolve",
		"def" : "a turn, i.e. a season",
		"kjv" : "[phrase] fitly"
	},
	"H656" : {
		"lemma" : "אָפֵס",
		"xlit" : "ʼâphêç",
		"pron" : "aw-face'",
		"derivation" : "a primitive root",
		"def" : "to disappear, i.e. cease",
		"kjv" : "be clean gone (at an end, brought to nought), fail"
	},
	"H657" : {
		"lemma" : "אֶפֶס",
		"xlit" : "ʼepheç",
		"pron" : "eh'-fes",
		"derivation" : "from H656 (אָפֵס); (sometimes like H6466 (פָּעַל))",
		"def" : "cessation, i.e. an end (especially of the earth); often used adverb, no further; also the ankle (in the dual), as being the extremity of the leg or foot",
		"kjv" : "ankle, but (only), end, howbeit, less than nothing, nevertheless (where), no, none (beside), not (any, -withstanding), thing of nought, save(-ing), there, uttermost part, want, without (cause)"
	},
	"H658" : {
		"lemma" : "אֶפֶס דַּמִּים",
		"xlit" : "ʼEpheç Dammîym",
		"pron" : "eh'-fes dam-meem'",
		"derivation" : "from H657 (אֶפֶס) and the plural of H1818 (דָּם); boundary of blood-drops",
		"def" : "Ephes-Dammim, a place in Palestine",
		"kjv" : "Ephes-dammim"
	},
	"H659" : {
		"lemma" : "אֵפַע",
		"xlit" : "ʼêphaʻ",
		"pron" : "eh'-fah",
		"derivation" : "from an unused root probably meaning to breathe",
		"def" : "properly, a breath, i.e. nothing",
		"kjv" : "of nought"
	},
	"H660" : {
		"lemma" : "אֶפְעֶה",
		"xlit" : "ʼephʻeh",
		"pron" : "ef-eh'",
		"derivation" : "from H659 (אֵפַע) (in the sense of hissing)",
		"def" : "an asp or other venomous serpent",
		"kjv" : "viper"
	},
	"H661" : {
		"lemma" : "אָפַף",
		"xlit" : "ʼâphaph",
		"pron" : "aw-faf'",
		"derivation" : "a primitive root",
		"def" : "to surround",
		"kjv" : "compass"
	},
	"H662" : {
		"lemma" : "אָפַק",
		"xlit" : "ʼâphaq",
		"pron" : "aw-fak'",
		"derivation" : "a primitive root",
		"def" : "to contain, i.e. (reflex.) abstain",
		"kjv" : "force (oneself), restrain"
	},
	"H663" : {
		"lemma" : "אֲפֵק",
		"xlit" : "ʼĂphêq",
		"pron" : "af-ake'",
		"derivation" : "or אֲפִיק; from H662 (אָפַק) (in the sense of strength); fortress",
		"def" : "Aphek (or Aphik), the name of three places in Palestine",
		"kjv" : "Aphek, Aphik"
	},
	"H664" : {
		"lemma" : "אֲפֵקָה",
		"xlit" : "ʼĂphêqâh",
		"pron" : "af-ay-kaw'",
		"derivation" : "feminine of H663 (אֲפֵק); fortress",
		"def" : "Aphekah, a place in Palestine",
		"kjv" : "Aphekah"
	},
	"H665" : {
		"lemma" : "אֵפֶר",
		"xlit" : "ʼêpher",
		"pron" : "ay'-fer",
		"derivation" : "from an unused root meaning to bestrew",
		"def" : "ashes",
		"kjv" : "ashes"
	},
	"H666" : {
		"lemma" : "אֲפֵר",
		"xlit" : "ʼăphêr",
		"pron" : "af-ayr'",
		"derivation" : "from the same as H665 (אֵפֶר) (in the sense of covering)",
		"def" : "a turban",
		"kjv" : "ashes"
	},
	"H667" : {
		"lemma" : "אֶפְרֹחַ",
		"xlit" : "ʼephrôach",
		"pron" : "ef-ro'-akh",
		"derivation" : "from H6524 (פָּרַח) (in the sense of bursting the shell)",
		"def" : "the brood of a bird",
		"kjv" : "young (one)"
	},
	"H668" : {
		"lemma" : "אַפִּרְיוֹן",
		"xlit" : "ʼappiryôwn",
		"pron" : "ap-pir-yone'",
		"derivation" : "probably of Egyptian derivation",
		"def" : "a palanquin",
		"kjv" : "chariot"
	},
	"H669" : {
		"lemma" : "אֶפְרַיִם",
		"xlit" : "ʼEphrayim",
		"pron" : "ef-rah'-yim",
		"derivation" : "dual of masculine form of H672 (אֶפְרָת); double fruit",
		"def" : "Ephrajim, a son of Joseph; also the tribe descended from him, and its territory",
		"kjv" : "Ephraim, Ephraimites"
	},
	"H670" : {
		"lemma" : "אֲפָרְסַי",
		"xlit" : "ʼĂphârᵉçay",
		"pron" : "af-aw-re-sah'ee",
		"derivation" : "(Aramaic) of foreign origin (only in the plural)",
		"def" : "an Apherasite or inhabitant of an unknown region of Assyria",
		"kjv" : "Apharsite"
	},
	"H671" : {
		"lemma" : "אֲפַרְסְכַי",
		"xlit" : "ʼĂpharçᵉkay",
		"pron" : "af-ar-sek-ah'ee",
		"derivation" : "(Aramaic) or אֲפַרְסַתְכַי; (Aramaic), of foreign origin (only in the plural)",
		"def" : "an Apharsekite or Apharsathkite, an unknown Assyrian tribe",
		"kjv" : "Apharsachites, Apharasthchites"
	},
	"H672" : {
		"lemma" : "אֶפְרָת",
		"xlit" : "ʼEphrâth",
		"pron" : "ef-rawth'",
		"derivation" : "or אֶפְרָתָה; from H6509 (פָּרָה); fruitfulness",
		"def" : "Ephrath, another name for Bethlehem",
		"kjv" : "once (Psalm 132:6) perhaps for Ephraim; also of an Israelitish woman; Ephrath, Ephratah"
	},
	"H673" : {
		"lemma" : "אֶפְרָתִי",
		"xlit" : "ʼEphrâthîy",
		"pron" : "ef-rawth-ee'",
		"derivation" : "patrial from H672 (אֶפְרָת)",
		"def" : "an Ephrathite or an Ephraimite",
		"kjv" : "Ephraimite, Ephrathite"
	},
	"H674" : {
		"lemma" : "אַפְּתֹם",
		"xlit" : "ʼappᵉthôm",
		"pron" : "ap-pe-thome'",
		"derivation" : "(Aramaic) of Persian origin",
		"def" : "revenue; others at the last",
		"kjv" : "revenue"
	},
	"H675" : {
		"lemma" : "אֶצְבּוֹן",
		"xlit" : "ʼEtsbôwn",
		"pron" : "ets-bone'",
		"derivation" : "or אֶצְבֹּן; of uncertain derivation",
		"def" : "Etsbon, the name of two Israelites",
		"kjv" : "Ezbon"
	},
	"H676" : {
		"lemma" : "אֶצְבַּע",
		"xlit" : "ʼetsbaʻ",
		"pron" : "ets-bah'",
		"derivation" : "from the same as H6648 (צֶבַע) (in the sense of grasping)",
		"def" : "something to sieze with, i.e. a finger; by analogy, a toe",
		"kjv" : "finger, toe"
	},
	"H677" : {
		"lemma" : "אֶצְבַּע",
		"xlit" : "ʼetsbaʻ",
		"pron" : "ets-bah'",
		"derivation" : "(Aramaic) corresponding to H676 (אֶצְבַּע)",
		"def" : "{something to sieze with, i.e. a finger; by analogy, a toe}",
		"kjv" : "finger, toe"
	},
	"H678" : {
		"lemma" : "אָצִיל",
		"xlit" : "ʼâtsîyl",
		"pron" : "aw-tseel'",
		"derivation" : "from H680 (אָצַל) (in its secondary sense of separation)",
		"def" : "an extremity (Isaiah 41:9), also a noble",
		"kjv" : "chief man, noble"
	},
	"H679" : {
		"lemma" : "אַצִּיל",
		"xlit" : "ʼatstsîyl",
		"pron" : "ats-tseel'",
		"derivation" : "from H680 (אָצַל) (in its primary sense of uniting)",
		"def" : "a joint of the hand (i.e. knuckle); also (according to some) a party-wall (Ezekiel 41:8)",
		"kjv" : "(arm) hole, great"
	},
	"H680" : {
		"lemma" : "אָצַל",
		"xlit" : "ʼâtsal",
		"pron" : "aw-tsal'",
		"derivation" : "a primitive root; properly, to join; used only as a denominative from H681 (אֵצֶל)",
		"def" : "to separate; hence, to select, refuse, contract",
		"kjv" : "keep, reserve, straiten, take"
	},
	"H681" : {
		"lemma" : "אֵצֶל",
		"xlit" : "ʼêtsel",
		"pron" : "ay'-tsel",
		"derivation" : "from H680 (אָצַל) (in the sense of joining)",
		"def" : "a side; (as a preposition) near",
		"kjv" : "at, (hard) by, (from) (beside), near (unto), toward, with. See also H1018 (בֵּית הָאֵצֶל)"
	},
	"H682" : {
		"lemma" : "אָצֵל",
		"xlit" : "ʼÂtsêl",
		"pron" : "aw-tsale'",
		"derivation" : "from H680 (אָצַל); noble",
		"def" : "Atsel, the name of an Israelite, and of a place in Palestine",
		"kjv" : "Azal, Azel"
	},
	"H683" : {
		"lemma" : "אֲצַלְיָהוּ",
		"xlit" : "ʼĂtsalyâhûw",
		"pron" : "ats-al-yaw'-hoo",
		"derivation" : "from H680 (אָצַל) and H3050 (יָהּ) prolonged; Jah has reserved",
		"def" : "Atsaljah, an Israelite",
		"kjv" : "Azaliah"
	},
	"H684" : {
		"lemma" : "אֹצֶם",
		"xlit" : "ʼÔtsem",
		"pron" : "o'-tsem",
		"derivation" : "from an unused root probably meaning to be strong; strength (i.e. strong)",
		"def" : "Otsem, the name of two Israelites",
		"kjv" : "Ozem"
	},
	"H685" : {
		"lemma" : "אֶצְעָדָה",
		"xlit" : "ʼetsʻâdâh",
		"pron" : "ets-aw-daw'",
		"derivation" : "a variation from H6807 (צְעָדָה)",
		"def" : "properly, a step-chain; by analogy, a bracelet",
		"kjv" : "bracelet, chain"
	},
	"H686" : {
		"lemma" : "אָצַר",
		"xlit" : "ʼâtsar",
		"pron" : "aw-tsar'",
		"derivation" : "a primitive root",
		"def" : "to store up",
		"kjv" : "(lay up in) store, [phrase] (make) treasure(-r)"
	},
	"H687" : {
		"lemma" : "אֶצֶר",
		"xlit" : "ʼEtser",
		"pron" : "ay'-tser",
		"derivation" : "from H686 (אָצַר); treasure",
		"def" : "Etser, an Idumaean",
		"kjv" : "Ezer"
	},
	"H688" : {
		"lemma" : "אֶקְדָּח",
		"xlit" : "ʼeqdâch",
		"pron" : "ek-dawkh'",
		"derivation" : "from H6916 (קִדָּה)",
		"def" : "burning, i.e. a carbuncle or other fiery gem",
		"kjv" : "carbuncle"
	},
	"H689" : {
		"lemma" : "אַקּוֹ",
		"xlit" : "ʼaqqôw",
		"pron" : "ak-ko'",
		"derivation" : "probably from H602 (אָנַק)",
		"def" : "slender, i.e. the ibex",
		"kjv" : "wild goat"
	},
	"H690" : {
		"lemma" : "אֲרָא",
		"xlit" : "ʼărâʼ",
		"pron" : "ar-aw'",
		"derivation" : "probably for H738 (אֲרִי); lion",
		"def" : "Ara, an Israelite",
		"kjv" : "Ara"
	},
	"H691" : {
		"lemma" : "אֶרְאֵל",
		"xlit" : "ʼerʼêl",
		"pron" : "er-ale'",
		"derivation" : "probably for H739 (אֲרִיאֵל)",
		"def" : "a hero (collectively)",
		"kjv" : "valiant one"
	},
	"H692" : {
		"lemma" : "אַרְאֵלִי",
		"xlit" : "ʼArʼêlîy",
		"pron" : "ar-ay-lee'",
		"derivation" : "from H691 (אֶרְאֵל); heroic",
		"def" : "Areli (or an Arelite, collectively), an Israelite and his descendants",
		"kjv" : "Areli, Arelites"
	},
	"H693" : {
		"lemma" : "אָרַב",
		"xlit" : "ʼârab",
		"pron" : "aw-rab'",
		"derivation" : "a primitive root",
		"def" : "to lurk",
		"kjv" : "(lie in) ambush(-ment), lay (lie in) wait"
	},
	"H694" : {
		"lemma" : "אֲרָב",
		"xlit" : "ʼĂrâb",
		"pron" : "ar-awb'",
		"derivation" : "from H693 (אָרַב); ambush",
		"def" : "Arab, a place in Palestine",
		"kjv" : "Arab"
	},
	"H695" : {
		"lemma" : "אֶרֶב",
		"xlit" : "ʼereb",
		"pron" : "eh'-reb",
		"derivation" : "from H693 (אָרַב)",
		"def" : "ambuscade",
		"kjv" : "den, lie in wait"
	},
	"H696" : {
		"lemma" : "אֹרֶב",
		"xlit" : "ʼôreb",
		"pron" : "o'-reb",
		"derivation" : "the same as H695 (אֶרֶב)",
		"def" : "{ambuscade}",
		"kjv" : "wait"
	},
	"H697" : {
		"lemma" : "אַרְבֶּה",
		"xlit" : "ʼarbeh",
		"pron" : "ar-beh'",
		"derivation" : "from H7235 (רָבָה)",
		"def" : "a locust (from its rapid increase)",
		"kjv" : "grasshopper, locust"
	},
	"H698" : {
		"lemma" : "אׇרֳבָה",
		"xlit" : "ʼorŏbâh",
		"pron" : "or-ob-aw'",
		"derivation" : "feminine of H696 (אֹרֶב) (only in the plural)",
		"def" : "ambuscades",
		"kjv" : "spoils"
	},
	"H699" : {
		"lemma" : "אֲרֻבָּה",
		"xlit" : "ʼărubbâh",
		"pron" : "ar-oob-baw'",
		"derivation" : "feminine participle passive of H693 (אָרַב) (as if for lurking)",
		"def" : "a lattice; (by implication) a window, dovecot (because of the pigeon-holes), chimney (with its apertures for smoke), sluice (with openings for water)",
		"kjv" : "chimney, window"
	},
	"H700" : {
		"lemma" : "אֲרֻבּוֹת",
		"xlit" : "ʼĂrubbôwth",
		"pron" : "ar-oob-both",
		"derivation" : "plural of H699 (אֲרֻבָּה)",
		"def" : "Arubboth, a place in Palestine",
		"kjv" : "Aruboth"
	},
	"H701" : {
		"lemma" : "אַרְבִּי",
		"xlit" : "ʼArbîy",
		"pron" : "ar-bee'",
		"derivation" : "patrial from H694 (אֲרָב)",
		"def" : "an Arbite or native of Arab",
		"kjv" : "Arbite"
	},
	"H702" : {
		"lemma" : "אַרְבַּע",
		"xlit" : "ʼarbaʻ",
		"pron" : "ar-bah'",
		"derivation" : "masculine אַרְבָּעָה; from H7251 (רָבַע)",
		"def" : "four",
		"kjv" : "four"
	},
	"H703" : {
		"lemma" : "אַרְבַּע",
		"xlit" : "ʼarbaʻ",
		"pron" : "ar-bah'",
		"derivation" : "(Aramaic) corresponding to H702 (אַרְבַּע)",
		"def" : "{four}",
		"kjv" : "four"
	},
	"H704" : {
		"lemma" : "אַרְבַּע",
		"xlit" : "ʼArbaʻ",
		"pron" : "ar-bah'",
		"derivation" : "the same as H702 (אַרְבַּע)",
		"def" : "Arba, one of the Anakim",
		"kjv" : "Arba"
	},
	"H705" : {
		"lemma" : "אַרְבָּעִים",
		"xlit" : "ʼarbâʻîym",
		"pron" : "ar-baw-eem'",
		"derivation" : "multiple of H702 (אַרְבַּע)",
		"def" : "forty",
		"kjv" : "-forty"
	},
	"H706" : {
		"lemma" : "אַרְבַּעְתַּיִם",
		"xlit" : "ʼarbaʻtayim",
		"pron" : "ar-bah-tah'-yim",
		"derivation" : "dual of H702 (אַרְבַּע)",
		"def" : "fourfold",
		"kjv" : "fourfold"
	},
	"H707" : {
		"lemma" : "אָרַג",
		"xlit" : "ʼârag",
		"pron" : "aw-rag'",
		"derivation" : "a primitive root",
		"def" : "to plait or weave",
		"kjv" : "weaver(-r)"
	},
	"H708" : {
		"lemma" : "אֶרֶג",
		"xlit" : "ʼereg",
		"pron" : "eh'-reg",
		"derivation" : "from H707 (אָרַג)",
		"def" : "a weaving; a braid; also a shuttle",
		"kjv" : "beam, weaver's shuttle"
	},
	"H709" : {
		"lemma" : "אַרְגֹּב",
		"xlit" : "ʼArgôb",
		"pron" : "ar-gobe'",
		"derivation" : "from the same as H7263 (רֶגֶב); stony",
		"def" : "Argob, a district of Palestine",
		"kjv" : "Argob"
	},
	"H710" : {
		"lemma" : "אַרְגְּוָן",
		"xlit" : "ʼargᵉvân",
		"pron" : "arg-ev-awn'",
		"derivation" : "a variation for H713 (אַרְגָּמָן)",
		"def" : "purple",
		"kjv" : "purple"
	},
	"H711" : {
		"lemma" : "אַרְגְּוָן",
		"xlit" : "ʼargᵉvân",
		"pron" : "arg-ev-awn'",
		"derivation" : "(Aramaic) corresponding to H710 (אַרְגְּוָן)",
		"def" : "{purple}",
		"kjv" : "purple"
	},
	"H712" : {
		"lemma" : "אַרְגָּז",
		"xlit" : "ʼargâz",
		"pron" : "ar-gawz'",
		"derivation" : "perhaps from H7264 (רָגַז) (in the sense of being suspended)",
		"def" : "a box (as a pannier)",
		"kjv" : "coffer"
	},
	"H713" : {
		"lemma" : "אַרְגָּמָן",
		"xlit" : "ʼargâmân",
		"pron" : "ar-gaw-mawn'",
		"derivation" : "of foreign origin",
		"def" : "purple (the color or the dyed stuff)",
		"kjv" : "purple"
	},
	"H714" : {
		"lemma" : "אַרְדְּ",
		"xlit" : "ʼArd",
		"pron" : "ard",
		"derivation" : "from an unused root probably meaning to wander; fugitive",
		"def" : "Ard, the name of two Israelites",
		"kjv" : "Ard"
	},
	"H715" : {
		"lemma" : "אַרְדּוֹן",
		"xlit" : "ʼArdôwn",
		"pron" : "ar-dohn'",
		"derivation" : "from the same as H714 (אַרְדְּ); roaming",
		"def" : "Ardon, an Israelite",
		"kjv" : "Ardon"
	},
	"H716" : {
		"lemma" : "אַרְדִּי",
		"xlit" : "ʼArdîy",
		"pron" : "ar-dee",
		"derivation" : "patronymic from H714 (אַרְדְּ)",
		"def" : "an Ardite (collectively) or descendant of Ard",
		"kjv" : "Ardites"
	},
	"H717" : {
		"lemma" : "אָרָה",
		"xlit" : "ʼârâh",
		"pron" : "aw-raw'",
		"derivation" : "a primitive root",
		"def" : "to pluck",
		"kjv" : "gather, pluck"
	},
	"H718" : {
		"lemma" : "אֲרוּ",
		"xlit" : "ʼărûw",
		"pron" : "ar-oo'",
		"derivation" : "(Aramaic) probably akin to H431 (אֲלוּ)",
		"def" : "lo!",
		"kjv" : "behold, lo"
	},
	"H719" : {
		"lemma" : "אַרְוַד",
		"xlit" : "ʼArvad",
		"pron" : "ar-vad'",
		"derivation" : "probably from H7300 (רוּד); a refuge for the roving",
		"def" : "Arvad, an island-city of Palestine",
		"kjv" : "Arvad"
	},
	"H720" : {
		"lemma" : "אֲרוֹד",
		"xlit" : "ʼĂrôwd",
		"pron" : "ar-ode'",
		"derivation" : "an orthographical variation of H719 (אַרְוַד); fugitive",
		"def" : "Arod, an Israelite",
		"kjv" : "Arod"
	},
	"H721" : {
		"lemma" : "אַרְוָדִי",
		"xlit" : "ʼArvâdîy",
		"pron" : "ar-vaw-dee'",
		"derivation" : "patrial from H719 (אַרְוַד)",
		"def" : "an Arvadite or citizen of Arvad",
		"kjv" : "Arvadite"
	},
	"H722" : {
		"lemma" : "אֲרוֹדִי",
		"xlit" : "ʼĂrôwdîy",
		"pron" : "ar-o-dee'",
		"derivation" : "patronymic from H721 (אַרְוָדִי)",
		"def" : "an Arodite or descendant of Arod",
		"kjv" : "Arodi, Arodites"
	},
	"H723" : {
		"lemma" : "אֻרְוָה",
		"xlit" : "ʼurvâh",
		"pron" : "oor-vaw'",
		"derivation" : "or אֲרָיָה; from H717 (אָרָה) (in the sense of feeding)",
		"def" : "a herding-place for an animal",
		"kjv" : "stall"
	},
	"H724" : {
		"lemma" : "אֲרוּכָה",
		"xlit" : "ʼărûwkâh",
		"pron" : "ar-oo-kaw'",
		"derivation" : "or אֲרֻכָה; feminine passive participle of H748 (אָרַךְ) (in the sense of restoring to soundness)",
		"def" : "wholeness (literally or figuratively)",
		"kjv" : "health, made up, perfected"
	},
	"H725" : {
		"lemma" : "אֲרוּמָה",
		"xlit" : "ʼĂrûwmâh",
		"pron" : "ar-oo-maw'",
		"derivation" : "a variation of H7316 (רוּמָה); height",
		"def" : "Arumah, a place in Palestine",
		"kjv" : "Arumah"
	},
	"H726" : {
		"lemma" : "אֲרוֹמִי",
		"xlit" : "ʼĂrôwmîy",
		"pron" : "ar-o-mee'",
		"derivation" : "a clerical error for H130 (אֱדֹמִי)",
		"def" : "an Edomite (as in the margin)",
		"kjv" : "Syrian"
	},
	"H727" : {
		"lemma" : "אָרוֹן",
		"xlit" : "ʼârôwn",
		"pron" : "aw-rone'",
		"derivation" : "or אָרֹן; from H717 (אָרָה) (in the sense of gathering)",
		"def" : "a box",
		"kjv" : "ark, chest, coffin"
	},
	"H728" : {
		"lemma" : "אֲרַוְנָה",
		"xlit" : "ʼĂravnâh",
		"pron" : "ar-av-naw'",
		"derivation" : "or (by transposition) אוֹרְנָה; or אַרְנִיָּהlemma אַנִיָּה missing resh, corrected to אַרְנִיָּה; all by orthographical variation for H771 (אׇרְנָן)",
		"def" : "Aravnah (or Arnijah or Ornah), a Jebusite",
		"kjv" : "Araunah"
	},
	"H729" : {
		"lemma" : "אָרַז",
		"xlit" : "ʼâraz",
		"pron" : "aw-raz'",
		"derivation" : "a primitive root; to be firm; used only in the passive participle as a denominative from H730 (אֶרֶז)",
		"def" : "of cedar",
		"kjv" : "made of cedar"
	},
	"H730" : {
		"lemma" : "אֶרֶז",
		"xlit" : "ʼerez",
		"pron" : "eh-rez'",
		"derivation" : "from H729 (אָרַז)",
		"def" : "a cedar tree (from the tenacity of its roots)",
		"kjv" : "cedar (tree)"
	},
	"H731" : {
		"lemma" : "אַרְזָה",
		"xlit" : "ʼarzâh",
		"pron" : "ar-zaw'",
		"derivation" : "feminine of H730 (אֶרֶז)",
		"def" : "cedar wainscoating",
		"kjv" : "cedar work"
	},
	"H732" : {
		"lemma" : "אָרַח",
		"xlit" : "ʼârach",
		"pron" : "aw-rakh'",
		"derivation" : "a primitive root",
		"def" : "to travel",
		"kjv" : "go, wayfaring (man)"
	},
	"H733" : {
		"lemma" : "אָרַח",
		"xlit" : "ʼÂrach",
		"pron" : "aw-rakh'",
		"derivation" : "from H732 (אָרַח); way faring",
		"def" : "Arach, the name of three Israelites",
		"kjv" : "Arah"
	},
	"H734" : {
		"lemma" : "אֹרַח",
		"xlit" : "ʼôrach",
		"pron" : "o'-rakh",
		"derivation" : "from H732 (אָרַח)",
		"def" : "a well-trodden road (literally or figuratively); also a caravan",
		"kjv" : "manner, path, race, rank, traveller, troop, (by-, high-) way"
	},
	"H735" : {
		"lemma" : "אֹרַח",
		"xlit" : "ʼôrach",
		"pron" : "o'-rakh",
		"derivation" : "(Aramaic) corresponding to H734 (אֹרַח)",
		"def" : "a road",
		"kjv" : "way"
	},
	"H736" : {
		"lemma" : "אֹרְחָה",
		"xlit" : "ʼôrᵉchâh",
		"pron" : "o-rekh-aw'",
		"derivation" : "feminine active participle of H732 (אָרַח)",
		"def" : "a caravan",
		"kjv" : "(travelling) company"
	},
	"H737" : {
		"lemma" : "אֲרֻחָה",
		"xlit" : "ʼăruchâh",
		"pron" : "ar-oo-khaw'",
		"derivation" : "feminine passive participle of H732 (אָרַח) (in the sense of appointing)",
		"def" : "a ration of food",
		"kjv" : "allowance, diet, dinner, victuals"
	},
	"H738" : {
		"lemma" : "אֲרִי",
		"xlit" : "ʼărîy",
		"pron" : "ar-ee'",
		"derivation" : "or (prolonged) []; from H717 (אָרָה) (in the sense of violence)",
		"def" : "a lion",
		"kjv" : "(young) lion, [phrase] pierce (from the margin)"
	},
	"H739" : {
		"lemma" : "אֲרִיאֵל",
		"xlit" : "ʼărîyʼêl",
		"pron" : "ar-ee-ale'",
		"derivation" : "or אֲרִאֵל; from H738 (אֲרִי) and H410 (אֵל)",
		"def" : "lion of God; i.e. heroic",
		"kjv" : "lionlike men"
	},
	"H740" : {
		"lemma" : "אֲרִיאֵל",
		"xlit" : "ʼĂrîyʼêl",
		"pron" : "ar-ee-ale'",
		"derivation" : "the same as H739 (אֲרִיאֵל)",
		"def" : "Ariel, a symbolical name for Jerusalem, also the name of an Israelite",
		"kjv" : "Ariel"
	},
	"H741" : {
		"lemma" : "אֲרִאֵיל",
		"xlit" : "ʼăriʼêyl",
		"pron" : "ar-ee-ale'",
		"derivation" : "either by transposition for H739 (אֲרִיאֵל) or, more probably, an orthographical variation for H2025 (הַרְאֵל)",
		"def" : "the altar of the temple",
		"kjv" : "altar"
	},
	"H742" : {
		"lemma" : "אֲרִידַי",
		"xlit" : "ʼĂrîyday",
		"pron" : "ar-ee-dah'-ee",
		"derivation" : "of Persian origin",
		"def" : "Aridai, a son of Haman",
		"kjv" : "Aridai"
	},
	"H743" : {
		"lemma" : "אֲרִידָתָא",
		"xlit" : "ʼĂrîydâthâʼ",
		"pron" : "ar-ee-daw-thaw'",
		"derivation" : "of Persian origin",
		"def" : "Aridatha, a son of Haman",
		"kjv" : "Aridatha"
	},
	"H744" : {
		"lemma" : "אַרְיֵה",
		"xlit" : "ʼaryêh",
		"pron" : "ar-yay'",
		"derivation" : "(Aramaic) corresponding to H738 (אֲרִי)",
		"def" : "{a lion}",
		"kjv" : "lion"
	},
	"H745" : {
		"lemma" : "אַרְיֵה",
		"xlit" : "ʼAryêh",
		"pron" : "ar-yay'",
		"derivation" : "the same as H738 (אֲרִי); lion",
		"def" : "Arjeh, an Israelite",
		"kjv" : "Arieh"
	},
	"H746" : {
		"lemma" : "אֲרְיוֹךְ",
		"xlit" : "ʼĂryôwk",
		"pron" : "ar-yoke'",
		"derivation" : "of foreign origin",
		"def" : "Arjok, the name of two Babylonians",
		"kjv" : "Arioch"
	},
	"H747" : {
		"lemma" : "אֲרִיסַי",
		"xlit" : "ʼĂrîyçay",
		"pron" : "ar-ee-sah'-ee",
		"derivation" : "of Persian origin",
		"def" : "Arisai, a son of Haman",
		"kjv" : "Arisai"
	},
	"H748" : {
		"lemma" : "אָרַךְ",
		"xlit" : "ʼârak",
		"pron" : "aw-rak'",
		"derivation" : "a primitive root",
		"def" : "to be (causative, make) long (literally or figuratively)",
		"kjv" : "defer, draw out, lengthen, (be, become, make, pro-) long, [phrase] (out-, over-) live, tarry (long)"
	},
	"H749" : {
		"lemma" : "אֲרַךְ",
		"xlit" : "ʼărak",
		"pron" : "ar-ak'",
		"derivation" : "(Aramaic) properly, corresponding to H748 (אָרַךְ), but used only in the sense of reaching to a given point",
		"def" : "to suit",
		"kjv" : "be meet"
	},
	"H750" : {
		"lemma" : "אָרֵךְ",
		"xlit" : "ʼârêk",
		"pron" : "aw-rake'",
		"derivation" : "from H748 (אָרַךְ)",
		"def" : "long",
		"kjv" : "long(-suffering, -winged), patient, slow (to anger)"
	},
	"H751" : {
		"lemma" : "אֶרֶךְ",
		"xlit" : "ʼErek",
		"pron" : "eh'-rek",
		"derivation" : "from H748 (אָרַךְ); length",
		"def" : "Erek, a place in Babylon",
		"kjv" : "Erech"
	},
	"H752" : {
		"lemma" : "אָרֹךְ",
		"xlit" : "ʼârôk",
		"pron" : "aw-roke'",
		"derivation" : "from H748 (אָרַךְ)",
		"def" : "long",
		"kjv" : "long"
	},
	"H753" : {
		"lemma" : "אֹרֶךְ",
		"xlit" : "ʼôrek",
		"pron" : "o'rek'",
		"derivation" : "from H748 (אָרַךְ)",
		"def" : "length",
		"kjv" : "[phrase] forever, length, long"
	},
	"H754" : {
		"lemma" : "אַרְכָּא",
		"xlit" : "ʼarkâʼ",
		"pron" : "ar-kaw'",
		"derivation" : "(Aramaic) or אַרְכָּה; (Aramaic), from H749 (אֲרַךְ)",
		"def" : "length",
		"kjv" : "lengthening, prolonged"
	},
	"H755" : {
		"lemma" : "אַרְכֻבָה",
		"xlit" : "ʼarkubâh",
		"pron" : "ar-koo-baw'",
		"derivation" : "(Aramaic) from an unused root corresponding to H7392 (רָכַב) (in the sense of bending the knee)",
		"def" : "the knee",
		"kjv" : "knee"
	},
	"H756" : {
		"lemma" : "אַרְכְּוַי",
		"xlit" : "ʼArkᵉvay",
		"pron" : "ar-kev-ah'ee",
		"derivation" : "(Aramaic) patrial from H751 (אֶרֶךְ)",
		"def" : "an Arkevite (collectively) or native of Erek",
		"kjv" : "Archevite"
	},
	"H757" : {
		"lemma" : "אַרְכִּי",
		"xlit" : "ʼArkîy",
		"pron" : "ar-kee'",
		"derivation" : "patrial from another place (in Palestine) of similar name with H751 (אֶרֶךְ)",
		"def" : "an Arkite or native of Erek",
		"kjv" : "Archi, Archite"
	},
	"H758" : {
		"lemma" : "אֲרָם",
		"xlit" : "ʼĂrâm",
		"pron" : "arawm'",
		"derivation" : "from the same as H759 (אַרְמוֹן); the highland",
		"def" : "Aram or Syria, and its inhabitants; also the name of the son of Shem, a grandson of Nahor, and of an Israelite",
		"kjv" : "Aram, Mesopotamia, Syria, Syrians"
	},
	"H759" : {
		"lemma" : "אַרְמוֹן",
		"xlit" : "ʼarmôwn",
		"pron" : "ar-mone'",
		"derivation" : "from an unused root (meaning to be elevated)",
		"def" : "a citadel (from its height)",
		"kjv" : "castle, palace. Compare H2038 (הַרְמוֹן)"
	},
	"H760" : {
		"lemma" : "אֲרַם צוֹבָה",
		"xlit" : "ʼĂram Tsôwbâh",
		"pron" : "ar-am' tso-baw'",
		"derivation" : "from H758 (אֲרָם) and H6678 (צוֹבָא)",
		"def" : "Aram of Tsoba (or Coele-Syria)",
		"kjv" : "Aram-zobah"
	},
	"H761" : {
		"lemma" : "אֲרַמִּי",
		"xlit" : "ʼĂrammîy",
		"pron" : "ar-am-mee'",
		"derivation" : "patrial from H758 (אֲרָם)",
		"def" : "an Aramite or Aramaean",
		"kjv" : "Syrian, Aramitess"
	},
	"H762" : {
		"lemma" : "אֲרָמִית",
		"xlit" : "ʼĂrâmîyth",
		"pron" : "ar-aw-meeth'",
		"derivation" : "feminine of H761 (אֲרַמִּי)",
		"def" : "(only adverbial) in Aramean",
		"kjv" : "in the Syrian language (tongue), in Syriac"
	},
	"H763" : {
		"lemma" : "אֲרַם נַהֲרַיִם",
		"xlit" : "ʼĂram Nahărayim",
		"pron" : "ar-am' nah-har-ah'-yim",
		"derivation" : "from H758 (אֲרָם) and the dual of H5104 (נָהָר)",
		"def" : "Aram of (the) two rivers (Euphrates and Tigris) or Mesopotamia",
		"kjv" : "Aham-naharaim, Mesopotamia"
	},
	"H764" : {
		"lemma" : "אַרְמֹנִי",
		"xlit" : "ʼArmônîy",
		"pron" : "ar-mo-nee'",
		"derivation" : "from H759 (אַרְמוֹן); palatial",
		"def" : "Armoni, an Israelite",
		"kjv" : "Armoni"
	},
	"H765" : {
		"lemma" : "אֲרָן",
		"xlit" : "ʼĂrân",
		"pron" : "ar-awn'",
		"derivation" : "from H7442 (רָנַן); stridulous",
		"def" : "Aran, an Edomite",
		"kjv" : "Aran"
	},
	"H766" : {
		"lemma" : "אֹרֶן",
		"xlit" : "ʼôren",
		"pron" : "o'-ren",
		"derivation" : "from the same as H765 (אֲרָן) (in the sense of strength)",
		"def" : "the ash tree (from its toughness)",
		"kjv" : "ash"
	},
	"H767" : {
		"lemma" : "אֹרֶן",
		"xlit" : "ʼÔren",
		"pron" : "o'-ren",
		"derivation" : "the same as H766 (אֹרֶן)",
		"def" : "Oren, an Israelite",
		"kjv" : "Oren"
	},
	"H768" : {
		"lemma" : "אַרְנֶבֶת",
		"xlit" : "ʼarnebeth",
		"pron" : "ar-neh'-beth",
		"derivation" : "of uncertain derivation",
		"def" : "the hare",
		"kjv" : "hare"
	},
	"H769" : {
		"lemma" : "אַרְנוֹן",
		"xlit" : "ʼArnôwn",
		"pron" : "ar-nohn'",
		"derivation" : "or אַרְנֹןlemma אַרְנן missing vowel, corrected to אַרְנֹן; from H7442 (רָנַן); a brawling stream",
		"def" : "the Arnon, a river east of the Jordan, also its territory",
		"kjv" : "Arnon"
	},
	"H770" : {
		"lemma" : "אַרְנָן",
		"xlit" : "ʼArnân",
		"pron" : "ar-nawn'",
		"derivation" : "probably from the same as H769 (אַרְנוֹן); noisy",
		"def" : "Arnan, an Israelite",
		"kjv" : "Arnan"
	},
	"H771" : {
		"lemma" : "אׇרְנָן",
		"xlit" : "ʼOrnân",
		"pron" : "or-nawn'",
		"derivation" : "probably from H766 (אֹרֶן); strong",
		"def" : "Ornan, a Jebusite",
		"kjv" : "Ornan. See H728 (אֲרַוְנָה)"
	},
	"H772" : {
		"lemma" : "אֲרַע",
		"xlit" : "ʼăraʻ",
		"pron" : "ar-ah'",
		"derivation" : "(Aramaic) corresponding to H776 (אֶרֶץ)",
		"def" : "the earth; by implication (figuratively) low",
		"kjv" : "earth, interior"
	},
	"H773" : {
		"lemma" : "אַרְעִית",
		"xlit" : "ʼarʻîyth",
		"pron" : "arh-eeth'",
		"derivation" : "(Aramaic) feminine of H772 (אֲרַע)",
		"def" : "the bottom",
		"kjv" : "bottom"
	},
	"H774" : {
		"lemma" : "אַרְפָּד",
		"xlit" : "ʼArpâd",
		"pron" : "ar-pawd'",
		"derivation" : "from H7502 (רָפַד); spread out",
		"def" : "Arpad, a place in Syria",
		"kjv" : "Arpad, Arphad"
	},
	"H775" : {
		"lemma" : "אַרְפַּכְשַׁד",
		"xlit" : "ʼArpakshad",
		"pron" : "ar-pak-shad'",
		"derivation" : "probably of foreign origin",
		"def" : "Arpakshad, a son of Noah; also the region settled by him",
		"kjv" : "Arphaxad"
	},
	"H776" : {
		"lemma" : "אֶרֶץ",
		"xlit" : "ʼerets",
		"pron" : "eh'-rets",
		"derivation" : "from an unused root probably meaning to be firm",
		"def" : "the earth (at large, or partitively a land)",
		"kjv" : "[idiom] common, country, earth, field, ground, land, [idiom] natins, way, [phrase] wilderness, world"
	},
	"H777" : {
		"lemma" : "אַרְצָא",
		"xlit" : "ʼartsâʼ",
		"pron" : "ar-tsaw'",
		"derivation" : "from H776 (אֶרֶץ); earthiness",
		"def" : "Artsa, an Israelite",
		"kjv" : "Arza"
	},
	"H778" : {
		"lemma" : "אֲרַק",
		"xlit" : "ʼăraq",
		"pron" : "ar-ak'",
		"derivation" : "(Aramaic) by transmutation for H772 (אֲרַע)",
		"def" : "the earth",
		"kjv" : "earth"
	},
	"H779" : {
		"lemma" : "אָרַר",
		"xlit" : "ʼârar",
		"pron" : "aw-rar'",
		"derivation" : "a primitive root",
		"def" : "to execrate",
		"kjv" : "[idiom] bitterly curse"
	},
	"H780" : {
		"lemma" : "אֲרָרַט",
		"xlit" : "ʼĂrâraṭ",
		"pron" : "ar-aw-rat'",
		"derivation" : "of foreign origin",
		"def" : "Ararat (or rather Armenia)",
		"kjv" : "Ararat, Armenia"
	},
	"H781" : {
		"lemma" : "אָרַשׂ",
		"xlit" : "ʼâras",
		"pron" : "aw-ras'",
		"derivation" : "a primitive root",
		"def" : "to engage for matrimony",
		"kjv" : "betroth, espouse"
	},
	"H782" : {
		"lemma" : "אֲרֶשֶׁת",
		"xlit" : "ʼăresheth",
		"pron" : "ar-eh'-sheth",
		"derivation" : "from H781 (אָרַשׂ) (in the sense of desiring to possess)",
		"def" : "a longing for",
		"kjv" : "request"
	},
	"H783" : {
		"lemma" : "אַרְתַּחְשַׁשְׁתָּא",
		"xlit" : "ʼArtachshashtâʼ",
		"pron" : "ar-takh-shash-taw'",
		"derivation" : "or אַרְתַּחְשַׁשְׁתְּא; or by permutation אַרְתַּחְשַׁסְתְּא; of foreign origin",
		"def" : "Artachshasta (or Artaxerxes), a title (rather than name) of several Persian kings",
		"kjv" : "Artaxerxes"
	},
	"H784" : {
		"lemma" : "אֵשׁ",
		"xlit" : "ʼêsh",
		"pron" : "aysh",
		"derivation" : "a primitive word",
		"def" : "fire (literally or figuratively)",
		"kjv" : "burning, fiery, fire, flaming, hot"
	},
	"H785" : {
		"lemma" : "אֵשׁ",
		"xlit" : "ʼêsh",
		"pron" : "aysh",
		"derivation" : "(Aramaic) corresponding to H784 (אֵשׁ)",
		"def" : "{fire (literally or figuratively)}",
		"kjv" : "flame"
	},
	"H786" : {
		"lemma" : "אִשׁ",
		"xlit" : "ʼish",
		"pron" : "eesh",
		"derivation" : "identical (in origin and formation) with H784 (אֵשׁ)",
		"def" : "entity, used only adverbially, there is or are",
		"kjv" : "are there, none can. Compare H3426 (יֵשׁ)"
	},
	"H787" : {
		"lemma" : "אֹשׁ",
		"xlit" : "ʼôsh",
		"pron" : "ohsh",
		"derivation" : "(Aramaic) corresponding (by transposition and abbreviation) to H803 (אֲשׁוּיָה)",
		"def" : "a foundation",
		"kjv" : "foundation"
	},
	"H788" : {
		"lemma" : "אַשְׁבֵּל",
		"xlit" : "ʼAshbêl",
		"pron" : "ash-bale'",
		"derivation" : "probably from the same as H7640 (שֹׁבֶל); flowing",
		"def" : "Ashbel, an Israelite",
		"kjv" : "Ashbel"
	},
	"H789" : {
		"lemma" : "אַשְׁבֵּלִי",
		"xlit" : "ʼAshbêlîy",
		"pron" : "ash-bay-lee'",
		"derivation" : "patronymic from H788 (אַשְׁבֵּל)",
		"def" : "an Ashbelite (collectively) or descendant of Ashbel",
		"kjv" : "Ashbelites"
	},
	"H790" : {
		"lemma" : "אֶשְׁבָּן",
		"xlit" : "ʼEshbân",
		"pron" : "esh-bawn'",
		"derivation" : "probably from the same as H7644 (שֶׁבְנָא); vigorous",
		"def" : "Eshban, an Idumaean",
		"kjv" : "Eshban"
	},
	"H791" : {
		"lemma" : "אַשְׁבֵּעַ",
		"xlit" : "ʼAshbêaʻ",
		"pron" : "ash-bay'-ah",
		"derivation" : "from H7650 (שָׁבַע); adjurer",
		"def" : "Asbea, an Israelite",
		"kjv" : "Ashbea"
	},
	"H792" : {
		"lemma" : "אֶשְׁבַּעַל",
		"xlit" : "ʼEshbaʻal",
		"pron" : "esh-bah'-al",
		"derivation" : "from H376 (אִישׁ) and H1168 (בַּעַל); man of Baal",
		"def" : "Eshbaal (or Ishbosheth), a son of Saul",
		"kjv" : "Eshbaal"
	},
	"H793" : {
		"lemma" : "אֶשֶׁד",
		"xlit" : "ʼeshed",
		"pron" : "eh'-shed",
		"derivation" : "from an unused root meaning to pour",
		"def" : "an outpouring",
		"kjv" : "stream"
	},
	"H794" : {
		"lemma" : "אֲשֵׁדָה",
		"xlit" : "ʼăshêdâh",
		"pron" : "ash-ay-daw'",
		"derivation" : "feminine of H793 (אֶשֶׁד)",
		"def" : "a ravine",
		"kjv" : "springs"
	},
	"H795" : {
		"lemma" : "אַשְׁדּוֹד",
		"xlit" : "ʼAshdôwd",
		"pron" : "ash-dode'",
		"derivation" : "from H7703 (שָׁדַד); ravager",
		"def" : "Ashdod, a place in Palestine",
		"kjv" : "Ahdod"
	},
	"H796" : {
		"lemma" : "אַשְׁדּוֹדִי",
		"xlit" : "ʼAshdôwdîy",
		"pron" : "ash-do-dee'",
		"derivation" : "patrial from H795 (אַשְׁדּוֹד)",
		"def" : "an Ashdodite (often collectively) or inhabitant of Asdod",
		"kjv" : "Ashdodites, of Ashdod"
	},
	"H797" : {
		"lemma" : "אַשְׁדּוֹדִית",
		"xlit" : "ʼAshdôwdîyth",
		"pron" : "ash-do-deeth'",
		"derivation" : "feminine of H796 (אַשְׁדּוֹדִי)",
		"def" : "(only adverb) in the language of Ashdod",
		"kjv" : "in the speech of Ashdod"
	},
	"H798" : {
		"lemma" : "אַשְׁדּוֹת הַפִּסְגָּה",
		"xlit" : "ʼAshdôwth hap-Piçgâh",
		"pron" : "ash-doth' hap-pisgaw'",
		"derivation" : "from the plural of H794 (אֲשֵׁדָה) and H6449 (פִּסְגָּה) with the article interposed; ravines of the Pisgah",
		"def" : "Ashdoth-Pisgah, a place east of the Jordan",
		"kjv" : "Ashdoth-pisgah"
	},
	"H799" : {
		"lemma" : "אֶשְׁדָּת",
		"xlit" : "ʼeshdâth",
		"pron" : "esh-dawth'",
		"derivation" : "from H784 (אֵשׁ) and H1881 (דָּת)",
		"def" : "a fire-law",
		"kjv" : "fiery law"
	},
	"H800" : {
		"lemma" : "אֶשָּׁה",
		"xlit" : "ʼeshshâh",
		"pron" : "esh-shaw'",
		"derivation" : "feminine of H784 (אֵשׁ)",
		"def" : "fire",
		"kjv" : "fire"
	},
	"H801" : {
		"lemma" : "אִשָּׁה",
		"xlit" : "ʼishshâh",
		"pron" : "ish-shaw'",
		"derivation" : "the same as H800 (אֶשָּׁה), but used in a liturgical sense",
		"def" : "properly, a burnt-offering; but occasionally of any sacrifice",
		"kjv" : "(offering, sacrifice), (made) by fire"
	},
	"H802" : {
		"lemma" : "אִשָּׁה",
		"xlit" : "ʼishshâh",
		"pron" : "ish-shaw'",
		"derivation" : "feminine of H376 (אִישׁ) or H582 (אֱנוֹשׁ); irregular plural, נָשִׁים;(used in the same wide sense as H582 (אֱנוֹשׁ))",
		"def" : "a woman",
		"kjv" : "(adulter) ess, each, every, female, [idiom] many, [phrase] none, one, [phrase] together, wife, woman. Often unexpressed in English"
	},
	"H803" : {
		"lemma" : "אֲשׁוּיָה",
		"xlit" : "ʼăshûwyâh",
		"pron" : "ash-oo-yah'",
		"derivation" : "feminine passive participle from an unused root meaning to found",
		"def" : "foundation",
		"kjv" : "foundation"
	},
	"H804" : {
		"lemma" : "אַשּׁוּר",
		"xlit" : "ʼAshshûwr",
		"pron" : "ash-shoor'",
		"derivation" : "or אַשֻּׁר; apparently from H833 (אָשַׁר) (in the sense of successful)",
		"def" : "Ashshur, the second son of Shem; also his descendants and the country occupied by them (i.e. Assyria), its region and its empire",
		"kjv" : "Asshur, Assur, Assyria, Assyrians. See H838 (אָשֻׁר)"
	},
	"H805" : {
		"lemma" : "אֲשׁוּרִי",
		"xlit" : "ʼĂshûwrîy",
		"pron" : "ash-oo-ree'",
		"derivation" : "or אַשּׁוּרִי; from a patrial word of the same form as H804 (אַשּׁוּר)",
		"def" : "an Ashurite (collectively) or inhabitant of Ashur, a district in Palestine",
		"kjv" : "Asshurim, Ashurites"
	},
	"H806" : {
		"lemma" : "אַשְׁחוּר",
		"xlit" : "ʼAshchûwr",
		"pron" : "ash-khoor'",
		"derivation" : "probably from H7835 (שָׁחַר); black",
		"def" : "Ashchur, an Israelite",
		"kjv" : "Ashur"
	},
	"H807" : {
		"lemma" : "אַשִׁימָא",
		"xlit" : "ʼAshîymâʼ",
		"pron" : "ash-ee-maw'",
		"derivation" : "of foreign origin",
		"def" : "Ashima, a deity of Hamath",
		"kjv" : "Ashima"
	},
	"H808" : {
		"lemma" : "אָשִׁישׁ",
		"xlit" : "ʼâshîysh",
		"pron" : "aw-sheesh'",
		"derivation" : "from the same as H784 (אֵשׁ) (in the sense of pressing down firmly; compare H803 (אֲשׁוּיָה))",
		"def" : "a (ruined) foundation",
		"kjv" : "foundation"
	},
	"H809" : {
		"lemma" : "אֲשִׁישָׁה",
		"xlit" : "ʼăshîyshâh",
		"pron" : "ash-ee-shaw'",
		"derivation" : "feminine of H808 (אָשִׁישׁ)",
		"def" : "something closely pressed together, i.e. a cake of raisins or other comfits",
		"kjv" : "flagon"
	},
	"H810" : {
		"lemma" : "אֶשֶׁךְ",
		"xlit" : "ʼeshek",
		"pron" : "eh'-shek",
		"derivation" : "from an unused root (probably meaning to bunch together)",
		"def" : "a testicle (as a lump)",
		"kjv" : "stone"
	},
	"H811" : {
		"lemma" : "אֶשְׁכּוֹל",
		"xlit" : "ʼeshkôwl",
		"pron" : "esh-kole'",
		"derivation" : "or אֶשְׁכֹּל; probably prolonged from H810 (אֶשֶׁךְ)",
		"def" : "a bunch of grapes or other fruit",
		"kjv" : "cluster (of grapes)"
	},
	"H812" : {
		"lemma" : "אֶשְׁכֹּל",
		"xlit" : "ʼEshkôl",
		"pron" : "esh-kole'",
		"derivation" : "the same as H811 (אֶשְׁכּוֹל)",
		"def" : "Eshcol, the name of an Amorite, also of a valley in Palestine",
		"kjv" : "Eshcol"
	},
	"H813" : {
		"lemma" : "אַשְׁכְּנַז",
		"xlit" : "ʼAshkᵉnaz",
		"pron" : "ash-ken-az'",
		"derivation" : "of foreign origin",
		"def" : "Ashkenaz, a Japhethite, also his descendants",
		"kjv" : "Ashkenaz"
	},
	"H814" : {
		"lemma" : "אֶשְׁכָּר",
		"xlit" : "ʼeshkâr",
		"pron" : "esh-cawr'",
		"derivation" : "for H7939 (שָׂכָר)",
		"def" : "a gratuity",
		"kjv" : "gift, present"
	},
	"H815" : {
		"lemma" : "אֵשֶׁל",
		"xlit" : "ʼêshel",
		"pron" : "ay'-shel",
		"derivation" : "from a root of uncertain signification",
		"def" : "a tamarisk tree; by extension, a grove of any kind",
		"kjv" : "grove, tree"
	},
	"H816" : {
		"lemma" : "אָשַׁם",
		"xlit" : "ʼâsham",
		"pron" : "aw-sham'",
		"derivation" : "or אָשֵׁם; a primitive root",
		"def" : "to be guilty; by implication to be punished or perish",
		"kjv" : "[idiom] certainly, be(-come, made) desolate, destroy, [idiom] greatly, be(-come, found, hold) guilty, offend (acknowledge offence), trespass"
	},
	"H817" : {
		"lemma" : "אָשָׁם",
		"xlit" : "ʼâshâm",
		"pron" : "aw-shawm'",
		"derivation" : "from H816 (אָשַׁם)",
		"def" : "guilt; by implication, a fault; also a sin-offering",
		"kjv" : "guiltiness, (offering for) sin, trespass (offering)"
	},
	"H818" : {
		"lemma" : "אָשֵׁם",
		"xlit" : "ʼâshêm",
		"pron" : "aw-shame'",
		"derivation" : "from H816 (אָשַׁם)",
		"def" : "guilty; hence, presenting asin-offering",
		"kjv" : "one which is faulty, guilty"
	},
	"H819" : {
		"lemma" : "אַשְׁמָה",
		"xlit" : "ʼashmâh",
		"pron" : "ash-maw'",
		"derivation" : "feminine of H817 (אָשָׁם)",
		"def" : "guiltiness, a fault, the presentation of asin-offering",
		"kjv" : "offend, sin, (cause of) trespass(-ing, offering)"
	},
	"H820" : {
		"lemma" : "אַשְׁמָן",
		"xlit" : "ʼashmân",
		"pron" : "ash-mawn'",
		"derivation" : "probably from H8081 (שֶׁמֶן)",
		"def" : "a fat-field",
		"kjv" : "desolate place"
	},
	"H821" : {
		"lemma" : "אַשְׁמֻרָה",
		"xlit" : "ʼashmurâh",
		"pron" : "ash-moo-raw'",
		"derivation" : "or אַשׁמוּרָה; or אַשׁמֹרֶת; (feminine) from H8104 (שָׁמַר)",
		"def" : "a night watch",
		"kjv" : "watch"
	},
	"H822" : {
		"lemma" : "אֶשְׁנָב",
		"xlit" : "ʼeshnâb",
		"pron" : "esh-nawb'",
		"derivation" : "apparently from an unused root (probably meaning to leave interstices)",
		"def" : "a latticed window",
		"kjv" : "casement, lattice"
	},
	"H823" : {
		"lemma" : "אַשְׁנָה",
		"xlit" : "ʼAshnâh",
		"pron" : "ash-naw'",
		"derivation" : "probably a variation for H3466 (יְשָׁנָה)",
		"def" : "Ashnah, the name of two places in Palestine",
		"kjv" : "Ashnah"
	},
	"H824" : {
		"lemma" : "אֶשְׁעָן",
		"xlit" : "ʼEshʻân",
		"pron" : "esh-awn'",
		"derivation" : "from H8172 (שָׁעַן); support",
		"def" : "Eshan, a place in Palestine",
		"kjv" : "Eshean"
	},
	"H825" : {
		"lemma" : "אַשָּׁף",
		"xlit" : "ʼashshâph",
		"pron" : "ash-shawf'",
		"derivation" : "from an unused root (probably meaning to lisp, i.e. practice enchantment)",
		"def" : "a conjurer",
		"kjv" : "astrologer"
	},
	"H826" : {
		"lemma" : "אַשָּׁף",
		"xlit" : "ʼashshâph",
		"pron" : "ash-shawf'",
		"derivation" : "(Aramaic) corresponding to H825 (אַשָּׁף)",
		"def" : "{a conjurer}",
		"kjv" : "astrologer"
	},
	"H827" : {
		"lemma" : "אַשְׁפָּה",
		"xlit" : "ʼashpâh",
		"pron" : "ash-paw'",
		"derivation" : "perhaps (feminine) from the same as H825 (אַשָּׁף) (in the sense of covering)",
		"def" : "a quiver or arrow-case",
		"kjv" : "quiver"
	},
	"H828" : {
		"lemma" : "אַשְׁפְּנַז",
		"xlit" : "ʼAshpᵉnaz",
		"pron" : "ash-pen-az'",
		"derivation" : "of foreign origin",
		"def" : "Ashpenaz, a Babylonian eunuch",
		"kjv" : "Ashpenaz"
	},
	"H829" : {
		"lemma" : "אֶשְׁפָּר",
		"xlit" : "ʼeshpâr",
		"pron" : "esh-pawr'",
		"derivation" : "of uncertain derivation",
		"def" : "a measured portion",
		"kjv" : "good piece (of flesh)"
	},
	"H830" : {
		"lemma" : "אַשְׁפֹּת",
		"xlit" : "ʼashpôth",
		"pron" : "ash-pohth'",
		"derivation" : "or אַשְׁפּוֹת; or שְׁפֹת; (contraction) plural of a noun of the same form as H827 (אַשְׁפָּה), from H8192 (שָׁפָה) (in the sense of scraping)",
		"def" : "a heap of rubbish or filth",
		"kjv" : "dung (hill)"
	},
	"H831" : {
		"lemma" : "אַשְׁקְלוֹן",
		"xlit" : "ʼAshqᵉlôwn",
		"pron" : "ash-kel-one'",
		"derivation" : "probably from H8254 (שָׁקַל) in the sense of weighing-place (i.e. mart)",
		"def" : "Ashkelon, a place in Palestine",
		"kjv" : "Ashkelon, Askalon"
	},
	"H832" : {
		"lemma" : "אֶשְׁקְלוֹנִי",
		"xlit" : "ʼEshqᵉlôwnîy",
		"pron" : "esh-kel-o-nee'",
		"derivation" : "patrial from H831 (אַשְׁקְלוֹן)",
		"def" : "Ashkelonite (collectively) or inhabitant of Ashkelon",
		"kjv" : "Eshkalonites"
	},
	"H833" : {
		"lemma" : "אָשַׁר",
		"xlit" : "ʼâshar",
		"pron" : "aw-shar'",
		"derivation" : "or אָשֵׁר; a primitive root",
		"def" : "to be straight (used in the widest sense, especially to be level, right, happy); figuratively, to go forward, be honest, proper",
		"kjv" : "(call, be) bless(-ed, happy), go, guide, lead, relieve"
	},
	"H834" : {
		"lemma" : "אֲשֶׁר",
		"xlit" : "ʼăsher",
		"pron" : "ash-er'",
		"derivation" : "a primitive relative pronoun (of every gender and number)",
		"def" : "who, which, what, that; also (as an adverb and a conjunction) when, where, how, because, in order that, etc.",
		"kjv" : "[idiom] after, [idiom] alike, as (soon as), because, [idiom] every, for, [phrase] forasmuch, [phrase] from whence, [phrase] how(-soever), [idiom] if, (so) that ((thing) which, wherein), [idiom] though, [phrase] until, [phrase] whatsoever, when, where ([phrase] -as, -in, -of, -on, -soever, -with), which, whilst, [phrase] whither(-soever), who(-m, -soever, -se). As it is indeclinable, it is often accompanied by the personal pronoun expletively, used to show the connection"
	},
	"H835" : {
		"lemma" : "אֶשֶׁר",
		"xlit" : "ʼesher",
		"pron" : "eh'-sher",
		"derivation" : "from H833 (אָשַׁר)",
		"def" : "happiness; only in masculine plural construction as interjection, how happy!",
		"kjv" : "blessed, happy"
	},
	"H836" : {
		"lemma" : "אָשֵׁר",
		"xlit" : "ʼÂshêr",
		"pron" : "aw-share'",
		"derivation" : "from H833 (אָשַׁר)",
		"def" : "happy; Asher, a son of Jacob, and the tribe descended from him, with its territory; also a place in Palestine",
		"kjv" : "Asher"
	},
	"H837" : {
		"lemma" : "אֹשֶׁר",
		"xlit" : "ʼôsher",
		"pron" : "o'-sher",
		"derivation" : "from H833 (אָשַׁר)",
		"def" : "happiness",
		"kjv" : "happy"
	},
	"H838" : {
		"lemma" : "אָשֻׁר",
		"xlit" : "ʼâshur",
		"pron" : "aw-shoor'",
		"derivation" : "or אַשֻּׁר; from H833 (אָשַׁר) in the sense of going",
		"def" : "a step",
		"kjv" : "going, step"
	},
	"H839" : {
		"lemma" : "אֲשֻׁר",
		"xlit" : "ʼăshur",
		"pron" : "ash-oor'",
		"derivation" : "contracted for H8391 (תְּאַשּׁוּר)",
		"def" : "the cedar tree or some other light elastic wood",
		"kjv" : "Ashurite"
	},
	"H840" : {
		"lemma" : "אֲשַׂרְאֵל",
		"xlit" : "ʼĂsarʼêl",
		"pron" : "as-ar-ale'",
		"derivation" : "by orthographical variation from H833 (אָשַׁר) and H410 (אֵל); right of God",
		"def" : "Asarel, an Israelite",
		"kjv" : "Asareel"
	},
	"H841" : {
		"lemma" : "אֲשַׂרְאֵלָה",
		"xlit" : "ʼĂsarʼêlâh",
		"pron" : "as-ar-ale'-aw",
		"derivation" : "from the same as H840 (אֲשַׂרְאֵל); right toward God",
		"def" : "Asarelah, an Israelite",
		"kjv" : "Asarelah. Compare H3480 (יְשַׂרְאֵלָה)"
	},
	"H842" : {
		"lemma" : "אֲשֵׁרָה",
		"xlit" : "ʼăshêrâh",
		"pron" : "ash-ay-raw'",
		"derivation" : "or אֲשֵׁירָה; from H833 (אָשַׁר); happy",
		"def" : "Asherah (or Astarte) a Phoenician goddess; also an image of the same",
		"kjv" : "grove. Compare H6253 (עַשְׁתֹּרֶת)"
	},
	"H843" : {
		"lemma" : "אָשֵׁרִי",
		"xlit" : "ʼÂshêrîy",
		"pron" : "aw-shay-ree'",
		"derivation" : "patronymic from H836 (אָשֵׁר)",
		"def" : "an Asherite (collectively) or descendant of Asher",
		"kjv" : "Asherites"
	},
	"H844" : {
		"lemma" : "אַשְׂרִיאֵל",
		"xlit" : "ʼAsrîyʼêl",
		"pron" : "as-ree-ale'",
		"derivation" : "an orthographical variation for H840 (אֲשַׂרְאֵל)",
		"def" : "Asriel, the name of two Israelites",
		"kjv" : "Ashriel, Asriel"
	},
	"H845" : {
		"lemma" : "אַשְׂרִאֵלִי",
		"xlit" : "ʼAsriʼêlîy",
		"pron" : "as-ree-ale-ee'",
		"derivation" : "patronymic from H844 (אַשְׂרִיאֵל)",
		"def" : "an Asrielite (collectively) or descendant of Asriel",
		"kjv" : "Asrielites"
	},
	"H846" : {
		"lemma" : "אֻשַּׁרְנָא",
		"xlit" : "ʼushsharnâʼ",
		"pron" : "oosh-ar-naw'",
		"derivation" : "(Aramaic) from a root corresponding to H833 (אָשַׁר)",
		"def" : "a wall (from its uprightness)",
		"kjv" : "wall"
	},
	"H847" : {
		"lemma" : "אֶשְׁתָּאֹל",
		"xlit" : "ʼEshtâʼôl",
		"pron" : "esh-taw-ole'",
		"derivation" : "or אֶשְׁתָּאוֹל; probably from H7592 (שָׁאַל); intreaty",
		"def" : "Eshtaol, a place in Palestine",
		"kjv" : "Eshtaol"
	},
	"H848" : {
		"lemma" : "אֶשְׁתָּאֻלִי",
		"xlit" : "ʼEshtâʼulîy",
		"pron" : "esh-taw-oo-lee'",
		"derivation" : "patrial from H847 (אֶשְׁתָּאֹל)",
		"def" : "an Eshtaolite (collectively) or inhabitant of Eshtaol",
		"kjv" : "Eshtaulites"
	},
	"H849" : {
		"lemma" : "אֶשְׁתַּדּוּר",
		"xlit" : "ʼeshtaddûwr",
		"pron" : "esh-tad-dure'",
		"derivation" : "(Aramaic) from H7712 (שְׁדַר) (in a bad sense)",
		"def" : "rebellion",
		"kjv" : "sedition"
	},
	"H850" : {
		"lemma" : "אֶשְׁתּוֹן",
		"xlit" : "ʼEshtôwn",
		"pron" : "esh-tone'",
		"derivation" : "probably from the same as H7764 (שׁוּנִי); restful",
		"def" : "Eshton, an Israelite",
		"kjv" : "Eshton"
	},
	"H851" : {
		"lemma" : "אֶשְׁתְּמֹעַ",
		"xlit" : "ʼEshtᵉmôaʻ",
		"pron" : "esh-tem-o'-ah",
		"derivation" : "or אֶשְׁתְּמוֹעַ; or אֶשְׁתְּמֹה; from H8085 (שָׁמַע) (in the sense of obedience)",
		"def" : "Eshtemoa or Eshtemoh, a place in Palestine",
		"kjv" : "Eshtemoa, Eshtemoh"
	},
	"H852" : {
		"lemma" : "אָת",
		"xlit" : "ʼâth",
		"pron" : "awth",
		"derivation" : "(Aramaic) corresponding to H226 (אוֹת)",
		"def" : "a portent",
		"kjv" : "sign"
	},
	"H853" : {
		"lemma" : "אֵת",
		"xlit" : "ʼêth",
		"pron" : "ayth",
		"derivation" : "apparent contracted from H226 (אוֹת) in the demonstrative sense of entity",
		"def" : "properly, self (but generally used to point out more definitely the object of a verb or preposition, even or namely)",
		"kjv" : "(as such unrepresented in English)"
	},
	"H854" : {
		"lemma" : "אֵת",
		"xlit" : "ʼêth",
		"pron" : "ayth",
		"derivation" : "probably from H579 (אָנָה)",
		"def" : "properly, nearness (used only as a preposition or an adverb), near; hence, generally, with, by, at, among, etc.",
		"kjv" : "against, among, before, by, for, from, in(-to), (out) of, with. Often with another prepositional prefix"
	},
	"H855" : {
		"lemma" : "אֵת",
		"xlit" : "ʼêth",
		"pron" : "ayth",
		"derivation" : "of uncertain derivation",
		"def" : "a hoe or other digging implement",
		"kjv" : "coulter, plowshare"
	},
	"H856" : {
		"lemma" : "אֶתְבַּעַל",
		"xlit" : "ʼEthbaʻal",
		"pron" : "eth-bah'-al",
		"derivation" : "from H854 (אֵת) and H1168 (בַּעַל); with Baal",
		"def" : "Ethbaal, a Phoenician king",
		"kjv" : "Ethbaal"
	},
	"H857" : {
		"lemma" : "אָתָה",
		"xlit" : "ʼâthâh",
		"pron" : "aw-thaw'",
		"derivation" : "or אָתָא; a primitive root (collateral to H225 (אוּת) contraction)",
		"def" : "to arrive",
		"kjv" : "(be-, things to) come (upon), bring"
	},
	"H858" : {
		"lemma" : "אָתָה",
		"xlit" : "ʼâthâh",
		"pron" : "aw-thaw'",
		"derivation" : "(Aramaic) or אָתָא; (Aramaic), corresponding to H857 (אָתָה)",
		"def" : "{to arrive}",
		"kjv" : "(be-) come, bring"
	},
	"H859" : {
		"lemma" : "אַתָּה",
		"xlit" : "ʼattâh",
		"pron" : "at-taw'",
		"derivation" : "or (shortened); אַתָּ; or אַת; feminine (irregular) sometimes אַתִּי; plural masculine אַתֶּם; feminine אַתֶּן; or אַתֵּנָהlemma אתֵּנָה missing vowel, corrected to אַתֵּנָה; or אַתֵּנָּה; a primitive pronoun of the second person",
		"def" : "thou and thee, or (plural) ye and you",
		"kjv" : "thee, thou, ye, you"
	},
	"H860" : {
		"lemma" : "אָתוֹן",
		"xlit" : "ʼâthôwn",
		"pron" : "aw-thone'",
		"derivation" : "probably from the same as H386 (אֵיתָן) (in the sense of patience)",
		"def" : "a female donkey (from its docility)",
		"kjv" : "(she) ass"
	},
	"H861" : {
		"lemma" : "אַתּוּן",
		"xlit" : "ʼattûwn",
		"pron" : "at-toon'",
		"derivation" : "(Aramaic) probably from the corresponding to H784 (אֵשׁ)",
		"def" : "probably a fire-place, i.e. furnace",
		"kjv" : "furnace"
	},
	"H862" : {
		"lemma" : "אַתּוּק",
		"xlit" : "ʼattûwq",
		"pron" : "at-tooke'",
		"derivation" : "or אַתִּיק; from H5423 (נָתַק) in the sense of decreasing",
		"def" : "a ledge or offset in abuilding",
		"kjv" : "gallery"
	},
	"H863" : {
		"lemma" : "אִתַּי",
		"xlit" : "ʼIttay",
		"pron" : "it-tah'ee",
		"derivation" : "or אִיתַי; from H854 (אֵת); near",
		"def" : "Ittai or Ithai, the name of a Gittite and of an Israelite",
		"kjv" : "Ithai, Ittai"
	},
	"H864" : {
		"lemma" : "אֵתָם",
		"xlit" : "ʼÊthâm",
		"pron" : "ay-thawm'",
		"derivation" : "of Egyptian derivation",
		"def" : "Etham, a place in the Desert",
		"kjv" : "Etham"
	},
	"H865" : {
		"lemma" : "אֶתְמוֹל",
		"xlit" : "ʼethmôwl",
		"pron" : "eth-mole'",
		"derivation" : "or אִתְמוֹל; or אֶתְמוּל; probably from H853 (אֵת) or H854 (אֵת) and H4136 (מוּל)",
		"def" : "heretofore; definitely yesterday",
		"kjv" : "[phrase] before (that) time, [phrase] heretofore, of late (old), [phrase] times past, yester(day)"
	},
	"H866" : {
		"lemma" : "אֶתְנָה",
		"xlit" : "ʼethnâh",
		"pron" : "eth-naw'",
		"derivation" : "from H8566 (תָּנָה)",
		"def" : "a present (as the price of harlotry)",
		"kjv" : "reward"
	},
	"H867" : {
		"lemma" : "אֶתְנִי",
		"xlit" : "ʼEthnîy",
		"pron" : "eth-nee'",
		"derivation" : "perhaps from H866 (אֶתְנָה); munificence",
		"def" : "Ethni, an Israelite",
		"kjv" : "Ethni"
	},
	"H868" : {
		"lemma" : "אֶתְנַן",
		"xlit" : "ʼethnan",
		"pron" : "eth-nan'",
		"derivation" : "the same as H866 (אֶתְנָה)",
		"def" : "a gift (as the price of harlotry or idolatry)",
		"kjv" : "hire, reward"
	},
	"H869" : {
		"lemma" : "אֶתְנַן",
		"xlit" : "ʼEthnan",
		"pron" : "eth-nan'",
		"derivation" : "the same as H868 (אֶתְנַן) in the sense of H867 (אֶתְנִי)",
		"def" : "Ethnan, an Israelite",
		"kjv" : "Ethnan"
	},
	"H870" : {
		"lemma" : "אֲתַר",
		"xlit" : "ʼăthar",
		"pron" : "ath-ar'",
		"derivation" : "(Aramaic) from a root corresponding to that of H871 (אֲתָרִים)",
		"def" : "a place; (adverb) after",
		"kjv" : "after, place"
	},
	"H871" : {
		"lemma" : "אֲתָרִים",
		"xlit" : "ʼĂthârîym",
		"pron" : "ath-aw-reem'",
		"derivation" : "plural from an unused root (probably meaning to step); places",
		"def" : "Atharim, a place near Palestine",
		"kjv" : "spies. b"
	},
	"H872" : {
		"lemma" : "בְּאָה",
		"xlit" : "bᵉʼâh",
		"pron" : "be-aw'",
		"derivation" : "from H935 (בּוֹא)",
		"def" : "an entrance to a building",
		"kjv" : "entry"
	},
	"H873" : {
		"lemma" : "בִּאוּשׁ",
		"xlit" : "biʼûwsh",
		"pron" : "be-oosh'",
		"derivation" : "(Aramaic) from H888 (בְּאֵשׁ)",
		"def" : "wicked",
		"kjv" : "bad"
	},
	"H874" : {
		"lemma" : "בָּאַר",
		"xlit" : "bâʼar",
		"pron" : "baw-ar'",
		"derivation" : "a primitive root",
		"def" : "to dig; by analogy, to engrave; figuratively, to explain",
		"kjv" : "declare, (make) plain(-ly)"
	},
	"H875" : {
		"lemma" : "בְּאֵר",
		"xlit" : "bᵉʼêr",
		"pron" : "be-ayr'",
		"derivation" : "from H874 (בָּאַר)",
		"def" : "a pit; especially a well",
		"kjv" : "pit, well"
	},
	"H876" : {
		"lemma" : "בְּאֵר",
		"xlit" : "Bᵉʼêr",
		"pron" : "be-ayr'",
		"derivation" : "the same as H875 (בְּאֵר)",
		"def" : "Beer, a place in the Desert, also one in Palestine",
		"kjv" : "Beer"
	},
	"H877" : {
		"lemma" : "בֹּאר",
		"xlit" : "bôʼr",
		"pron" : "bore",
		"derivation" : "from H874 (בָּאַר)",
		"def" : "a cistern",
		"kjv" : "cistern"
	},
	"H878" : {
		"lemma" : "בְּאֵרָא",
		"xlit" : "Bᵉʼêrâʼ",
		"pron" : "be-ay-raw'",
		"derivation" : "from H875 (בְּאֵר); a well",
		"def" : "Beera, an Israelite",
		"kjv" : "Beera"
	},
	"H879" : {
		"lemma" : "בְּאֵר אֵלִים",
		"xlit" : "Bᵉʼêr ʼÊlîym",
		"pron" : "be-ayr' ay-leem'",
		"derivation" : "from H875 (בְּאֵר) and the plural of H410 (אֵל); well of heroes",
		"def" : "Beer-Elim, a place in the Desert",
		"kjv" : "Beer-elim"
	},
	"H880" : {
		"lemma" : "בְּאֵרָה",
		"xlit" : "Bᵉʼêrâh",
		"pron" : "be-ay-raw'",
		"derivation" : "the same as H878 (בְּאֵרָא)",
		"def" : "Beerah, an Israelite",
		"kjv" : "Beerah"
	},
	"H881" : {
		"lemma" : "בְּאֵרוֹת",
		"xlit" : "Bᵉʼêrôwth",
		"pron" : "be-ay-rohth'",
		"derivation" : "feminine plural of H875 (בְּאֵר); wells",
		"def" : "Beeroth, a place in Palestine",
		"kjv" : "Beeroth"
	},
	"H882" : {
		"lemma" : "בְּאֵרִי",
		"xlit" : "Bᵉʼêrîy",
		"pron" : "be-ay-ree'",
		"derivation" : "from H875 (בְּאֵר); fountained",
		"def" : "Beeri, the name of a Hittite and of an Israelite",
		"kjv" : "Beeri"
	},
	"H883" : {
		"lemma" : "בְּאֵר לַחַי רֹאִי",
		"xlit" : "Bᵉʼêr la-Chay Rôʼîy",
		"pron" : "be-ayr' lakh-ah'ee ro-ee'",
		"derivation" : "from H875 (בְּאֵר) and H2416 (חַי) (with prefix) and H7203 (רֹאֶה); well of a living (One) my Seer",
		"def" : "Beer-Lachai-Roi, a place in the Desert",
		"kjv" : "Beer-lahai-roi"
	},
	"H884" : {
		"lemma" : "בְּאֵר שֶׁבַע",
		"xlit" : "Bᵉʼêr Shebaʻ",
		"pron" : "be-ayr' sheh'-bah",
		"derivation" : "from H875 (בְּאֵר) and H7651 (שֶׁבַע) (in the sense of H7650 (שָׁבַע)); well of an oath",
		"def" : "Beer-Sheba, a place in Palestine",
		"kjv" : "Beer-shebah"
	},
	"H885" : {
		"lemma" : "בְּאֵרֹת בְּנֵי־יַעֲקַן",
		"xlit" : "Bᵉʼêrôth Bᵉnêy-Yaʻăqan",
		"pron" : "be-ay-roth' be-nay' yah-a-can'",
		"derivation" : "from the feminine plural of H875 (בְּאֵר), and the plural contraction of H1121 (בֵּן), and H3292 (יַעֲקָן); wells of (the) sons of Jaakan",
		"def" : "Beeroth-Bene-Jaakan, a place in the Desert",
		"kjv" : "Beeroth of the children of Jaakan"
	},
	"H886" : {
		"lemma" : "בְּאֵרֹתִי",
		"xlit" : "Bᵉʼêrôthîy",
		"pron" : "be-ay-ro-thee'",
		"derivation" : "patrial from H881 (בְּאֵרוֹת)",
		"def" : "a Beerothite or inhabitant of Beeroth",
		"kjv" : "Beerothite"
	},
	"H887" : {
		"lemma" : "בָּאַשׁ",
		"xlit" : "bâʼash",
		"pron" : "baw-ash'",
		"derivation" : "a primitive root",
		"def" : "to smell bad; figuratively, to be offensive morally",
		"kjv" : "(make to) be abhorred (had in abomination, loathsome, odious), (cause a, make to) stink(-ing savour), [idiom] utterly"
	},
	"H888" : {
		"lemma" : "בְּאֵשׁ",
		"xlit" : "bᵉʼêsh",
		"pron" : "be-aysh'",
		"derivation" : "(Aramaic) corresponding to H887 (בָּאַשׁ)",
		"def" : "{to smell bad; figuratively, to be offensive morally}",
		"kjv" : "displease"
	},
	"H889" : {
		"lemma" : "בְּאֹשׁ",
		"xlit" : "bᵉʼôsh",
		"pron" : "be-oshe'",
		"derivation" : "from H877 (בֹּאר)",
		"def" : "a stench",
		"kjv" : "stink"
	},
	"H890" : {
		"lemma" : "בׇּאְשָׁה",
		"xlit" : "boʼshâh",
		"pron" : "bosh-aw'",
		"derivation" : "feminine of H889 (בְּאֹשׁ)",
		"def" : "stink-weed or any other noxious or useless plant",
		"kjv" : "cockle"
	},
	"H891" : {
		"lemma" : "בְּאֻשִׁים",
		"xlit" : "bᵉʼushîym",
		"pron" : "be-oo-sheem'",
		"derivation" : "plural of H889 (בְּאֹשׁ)",
		"def" : "poison-berries",
		"kjv" : "wild grapes"
	},
	"H892" : {
		"lemma" : "בָּבָה",
		"xlit" : "bâbâh",
		"pron" : "baw-baw'",
		"derivation" : "feminine active participle of an unused root meaning to hollow out",
		"def" : "something hollowed (as a gate), i.e. pupil of the eye",
		"kjv" : "apple (of the eye)"
	},
	"H893" : {
		"lemma" : "בֵּבַי",
		"xlit" : "Bêbay",
		"pron" : "bay-bah'ee",
		"derivation" : "probably of foreign origin",
		"def" : "Bebai, an Israelite",
		"kjv" : "Bebai"
	},
	"H894" : {
		"lemma" : "בָּבֶל",
		"xlit" : "Bâbel",
		"pron" : "baw-bel'",
		"derivation" : "from H1101 (בָּלַל); confusion",
		"def" : "Babel (i.e. Babylon), including Babylonia and the Babylonian empire",
		"kjv" : "Babel, Babylon"
	},
	"H895" : {
		"lemma" : "בַּבֶל",
		"xlit" : "Babel",
		"pron" : "baw-bel'",
		"derivation" : "(Aramaic) corresponding to H894 (בָּבֶל)",
		"def" : "{Babel (i.e. Babylon), including Babylonia and the Babylonian empire}",
		"kjv" : "Babylon"
	},
	"H896" : {
		"lemma" : "בַּבְלִי",
		"xlit" : "Bablîy",
		"pron" : "bab-lee'",
		"derivation" : "(Aramaic) patrial from H895 (בַּבֶל)",
		"def" : "a Babylonian",
		"kjv" : "Babylonia"
	},
	"H897" : {
		"lemma" : "בַּג",
		"xlit" : "bag",
		"pron" : "bag",
		"derivation" : "a Persian word",
		"def" : "food",
		"kjv" : "spoil (from the margin for H957 (בַּז).)"
	},
	"H898" : {
		"lemma" : "בָּגַד",
		"xlit" : "bâgad",
		"pron" : "baw-gad'",
		"derivation" : "a primitive root",
		"def" : "to cover (with a garment); figuratively, to act covertly; by implication, to pillage",
		"kjv" : "deal deceitfully (treacherously, unfaithfully), offend, transgress(-or), (depart), treacherous (dealer, -ly, man), unfaithful(-ly, man), [idiom] very"
	},
	"H899" : {
		"lemma" : "בֶּגֶד",
		"xlit" : "beged",
		"pron" : "behg'-ed",
		"derivation" : "from H898 (בָּגַד)",
		"def" : "a covering, i.e. clothing; also treachery or pillage",
		"kjv" : "apparel, cloth(-es, ing), garment, lap, rag, raiment, robe, [idiom] very (treacherously), vesture, wardrobe"
	},
	"H900" : {
		"lemma" : "בֹּגְדוֹת",
		"xlit" : "bôgᵉdôwth",
		"pron" : "bohg-ed-ohth",
		"derivation" : "feminine plural active participle of H898 (בָּגַד)",
		"def" : "treacheries",
		"kjv" : "treacherous"
	},
	"H901" : {
		"lemma" : "בָּגוֹד",
		"xlit" : "bâgôwd",
		"pron" : "baw-gode'",
		"derivation" : "from H898 (בָּגַד)",
		"def" : "treacherous",
		"kjv" : "treacherous"
	},
	"H902" : {
		"lemma" : "בִּגְוַי",
		"xlit" : "Bigvay",
		"pron" : "big-vah'ee",
		"derivation" : "probably of foreign origin",
		"def" : "Bigvai, an Israelite",
		"kjv" : "Bigvai"
	},
	"H903" : {
		"lemma" : "בִּגְתָא",
		"xlit" : "Bigthâʼ",
		"pron" : "big-thaw'",
		"derivation" : "of Persian derivation",
		"def" : "Bigtha, a eunuch of Xerxes",
		"kjv" : "Bigtha"
	},
	"H904" : {
		"lemma" : "בִּגְתָן",
		"xlit" : "Bigthân",
		"pron" : "big-thawn'",
		"derivation" : "or בִּגְתָנָא; of similar derivation to H903 (בִּגְתָא)",
		"def" : "Bigthan or Bigthana, a eunuch of Xerxes",
		"kjv" : "Bigthan, Bigthana"
	},
	"H905" : {
		"lemma" : "בַּד",
		"xlit" : "bad",
		"pron" : "bad",
		"derivation" : "from H909 (בָּדַד)",
		"def" : "properly, separation; by implication, a part of the body, branch of atree, bar forcarrying; figuratively, chief of a city; especially (with prepositional prefix) as an adverb, apart, only, besides",
		"kjv" : "alone, apart, bar, besides, branch, by self, of each alike, except, only, part, staff, strength"
	},
	"H906" : {
		"lemma" : "בַּד",
		"xlit" : "bad",
		"pron" : "bad",
		"derivation" : "perhaps from H909 (בָּדַד) (in the sense of divided fibres)",
		"def" : "flaxen thread or yarn; hence, a linen garment",
		"kjv" : "linen"
	},
	"H907" : {
		"lemma" : "בַּד",
		"xlit" : "bad",
		"pron" : "bad",
		"derivation" : "from H908 (בָּדָא)",
		"def" : "a brag or lie; also a liar",
		"kjv" : "liar, lie"
	},
	"H908" : {
		"lemma" : "בָּדָא",
		"xlit" : "bâdâʼ",
		"pron" : "baw-daw'",
		"derivation" : "a primitive root",
		"def" : "(figuratively) to invent",
		"kjv" : "devise, feign"
	},
	"H909" : {
		"lemma" : "בָּדַד",
		"xlit" : "bâdad",
		"pron" : "baw-dad'",
		"derivation" : "a primitive root",
		"def" : "to divide, i.e. (reflex.) be solitary",
		"kjv" : "alone"
	},
	"H910" : {
		"lemma" : "בָּדָד",
		"xlit" : "bâdâd",
		"pron" : "baw-dawd'",
		"derivation" : "from H909 (בָּדַד)",
		"def" : "separate; adverb, separately",
		"kjv" : "alone, desolate, only, solitary"
	},
	"H911" : {
		"lemma" : "בְּדַד",
		"xlit" : "Bᵉdad",
		"pron" : "bed-ad'",
		"derivation" : "from H909 (בָּדַד); separation",
		"def" : "Bedad, an Edomite",
		"kjv" : "Bedad"
	},
	"H912" : {
		"lemma" : "בֵּדְיָה",
		"xlit" : "Bêdᵉyâh",
		"pron" : "bay-de-yaw'",
		"derivation" : "probably a shortened form H5662 (עֹבַדְיָה); servant of Jehovah",
		"def" : "Bedejah, an Israelite",
		"kjv" : "Bedeiah"
	},
	"H913" : {
		"lemma" : "בְּדִיל",
		"xlit" : "bᵉdîyl",
		"pron" : "bed-eel'",
		"derivation" : "from H914 (בָּדַל)",
		"def" : "alloy (because removed by smelting); by analogy, tin",
		"kjv" : "[phrase] plummet, tin"
	},
	"H914" : {
		"lemma" : "בָּדַל",
		"xlit" : "bâdal",
		"pron" : "baw-dal'",
		"derivation" : "a primitive root",
		"def" : "to divide (in variation senses literally or figuratively, separate, distinguish, differ, select, etc.)",
		"kjv" : "(make, put) difference, divide (asunder), (make) separate (self, -ation), sever (out), [idiom] utterly"
	},
	"H915" : {
		"lemma" : "בָּדָל",
		"xlit" : "bâdâl",
		"pron" : "baw-dawl'",
		"derivation" : "from H914 (בָּדַל)",
		"def" : "a part",
		"kjv" : "piece"
	},
	"H916" : {
		"lemma" : "בְּדֹלַח",
		"xlit" : "bᵉdôlach",
		"pron" : "bed-o'-lakh",
		"derivation" : "probably from H914 (בָּדַל)",
		"def" : "something in pieces, i.e. bdellium, a (fragrant) gum (perhaps amber); others a pearl",
		"kjv" : "bdellium"
	},
	"H917" : {
		"lemma" : "בְּדָן",
		"xlit" : "Bᵉdân",
		"pron" : "bed-awn'",
		"derivation" : "probably shortened for H5658 (עַבְדוֹן); servile",
		"def" : "Bedan, the name of two Israelites",
		"kjv" : "Bedan"
	},
	"H918" : {
		"lemma" : "בָּדַק",
		"xlit" : "bâdaq",
		"pron" : "baw-dak'",
		"derivation" : "a primitive root; to gap open; used only as a denominative from H919 (בֶּדֶק)",
		"def" : "to mend a breach",
		"kjv" : "repair"
	},
	"H919" : {
		"lemma" : "בֶּדֶק",
		"xlit" : "bedeq",
		"pron" : "beh'-dek",
		"derivation" : "from H918 (בָּדַק)",
		"def" : "a gap or leak (in a building or a ship)",
		"kjv" : "breach, [phrase] calker"
	},
	"H920" : {
		"lemma" : "בִּדְקַר",
		"xlit" : "Bidqar",
		"pron" : "bid-car'",
		"derivation" : "probably from H1856 (דָּקַר) with a prepositional prefix; by stabbing, i.e. assassin",
		"def" : "Bidkar, an Israelite",
		"kjv" : "Bidkar"
	},
	"H921" : {
		"lemma" : "בְּדַר",
		"xlit" : "bᵉdar",
		"pron" : "bed-ar'",
		"derivation" : "(Aramaic) corresponding (by transposition) to H6504 (פָּרַד)",
		"def" : "to scatter",
		"kjv" : "scatter"
	},
	"H922" : {
		"lemma" : "בֹּהוּ",
		"xlit" : "bôhûw",
		"pron" : "bo'-hoo",
		"derivation" : "from an unused root (meaning to be empty)",
		"def" : "a vacuity, i.e. (superficially) an undistinguishable ruin",
		"kjv" : "emptiness, void"
	},
	"H923" : {
		"lemma" : "בַּהַט",
		"xlit" : "bahaṭ",
		"pron" : "bah'-hat",
		"derivation" : "from an unused root (probably meaning to glisten)",
		"def" : "white marble or perhaps alabaster",
		"kjv" : "red (marble)"
	},
	"H924" : {
		"lemma" : "בְּהִילוּ",
		"xlit" : "bᵉhîylûw",
		"pron" : "be-hee-loo'",
		"derivation" : "(Aramaic) from H927 (בְּהַל)",
		"def" : "a hurry; only adverb, hastily",
		"kjv" : "in haste"
	},
	"H925" : {
		"lemma" : "בָּהִיר",
		"xlit" : "bâhîyr",
		"pron" : "baw-here'",
		"derivation" : "from an unused root (meaning to be bright)",
		"def" : "shining",
		"kjv" : "bright"
	},
	"H926" : {
		"lemma" : "בָּהַל",
		"xlit" : "bâhal",
		"pron" : "baw-hal'",
		"derivation" : "a primitive root",
		"def" : "to tremble inwardly (or palpitate), i.e. (figuratively) be (causative, make) (suddenly) alarmed or agitated; by implication to hasten anxiously",
		"kjv" : "be (make) affrighted (afraid, amazed, dismayed, rash), (be, get, make) haste(-n, -y, -ily), (give) speedy(-ily), thrust out, trouble, vex"
	},
	"H927" : {
		"lemma" : "בְּהַל",
		"xlit" : "bᵉhal",
		"pron" : "be-hal'",
		"derivation" : "(Aramaic) corresponding to H926 (בָּהַל)",
		"def" : "to terrify, hasten",
		"kjv" : "in haste, trouble"
	},
	"H928" : {
		"lemma" : "בֶּהָלָה",
		"xlit" : "behâlâh",
		"pron" : "beh-haw-law'",
		"derivation" : "from H926 (בָּהַל)",
		"def" : "panic, destruction",
		"kjv" : "terror, trouble"
	},
	"H929" : {
		"lemma" : "בְּהֵמָה",
		"xlit" : "bᵉhêmâh",
		"pron" : "be-hay-maw'",
		"derivation" : "from an unused root (probably meaning to be mute)",
		"def" : "properly, a dumb beast; especially any large quadruped or animal (often collective)",
		"kjv" : "beast, cattle"
	},
	"H930" : {
		"lemma" : "בְּהֵמוֹת",
		"xlit" : "bᵉhêmôwth",
		"pron" : "be-hay-mohth'",
		"derivation" : "in form a plural or H929 (בְּהֵמָה), but really a singular of Egyptian derivation",
		"def" : "a water-ox, i.e. the hippopotamus or Nile-horse",
		"kjv" : "Behemoth"
	},
	"H931" : {
		"lemma" : "בֹּהֶן",
		"xlit" : "bôhen",
		"pron" : "bo'-hen",
		"derivation" : "from an unused root apparently meaning to be thick",
		"def" : "the thumb of the hand or great toe of the foot",
		"kjv" : "thumb, great toe"
	},
	"H932" : {
		"lemma" : "בֹּהַן",
		"xlit" : "Bôhan",
		"pron" : "bo'han",
		"derivation" : "an orthographical variation of H931 (בֹּהֶן)",
		"def" : "thumb, Bohan, an Israelite",
		"kjv" : "Bohan"
	},
	"H933" : {
		"lemma" : "בֹּהַק",
		"xlit" : "bôhaq",
		"pron" : "bo'-hak",
		"derivation" : "from an unused root meaning to be pale",
		"def" : "white scurf",
		"kjv" : "freckled spot"
	},
	"H934" : {
		"lemma" : "בֹּהֶרֶת",
		"xlit" : "bôhereth",
		"pron" : "bo-heh'-reth",
		"derivation" : "feminine active participle of the same as H925 (בָּהִיר)",
		"def" : "a whitish spot on the skin",
		"kjv" : "bright spot"
	},
	"H935" : {
		"lemma" : "בּוֹא",
		"xlit" : "bôwʼ",
		"pron" : "bo",
		"derivation" : "a primitive root",
		"def" : "to go or come (in a wide variety of applications)",
		"kjv" : "abide, apply, attain, [idiom] be, befall, [phrase] besiege, bring (forth, in, into, to pass), call, carry, [idiom] certainly, (cause, let, thing for) to come (against, in, out, upon, to pass), depart, [idiom] doubtless again, [phrase] eat, [phrase] employ, (cause to) enter (in, into, -tering, -trance, -try), be fallen, fetch, [phrase] follow, get, give, go (down, in, to war), grant, [phrase] have, [idiom] indeed, (in-) vade, lead, lift (up), mention, pull in, put, resort, run (down), send, set, [idiom] (well) stricken (in age), [idiom] surely, take (in), way"
	},
	"H936" : {
		"lemma" : "בּוּז",
		"xlit" : "bûwz",
		"pron" : "booz",
		"derivation" : "a primitive root",
		"def" : "to disrespect",
		"kjv" : "contemn, despise, [idiom] utterly"
	},
	"H937" : {
		"lemma" : "בּוּז",
		"xlit" : "bûwz",
		"pron" : "booz",
		"derivation" : "from H936 (בּוּז)",
		"def" : "disrespect",
		"kjv" : "contempt(-uously), despised, shamed"
	},
	"H938" : {
		"lemma" : "בּוּז",
		"xlit" : "Bûwz",
		"pron" : "booz",
		"derivation" : "the same as H937 (בּוּז)",
		"def" : "Buz, the name of a son of Nahor, and of an Israelite",
		"kjv" : "Buz"
	},
	"H939" : {
		"lemma" : "בּוּזָה",
		"xlit" : "bûwzâh",
		"pron" : "boo-zaw'",
		"derivation" : "feminine passive participle of H936 (בּוּז)",
		"def" : "something scorned; an object of contempt",
		"kjv" : "despised"
	},
	"H940" : {
		"lemma" : "בּוּזִי",
		"xlit" : "Bûwzîy",
		"pron" : "boo-zee'",
		"derivation" : "patronymic from H938 (בּוּז)",
		"def" : "a Buzite or descendant of Buz",
		"kjv" : "Buzite"
	},
	"H941" : {
		"lemma" : "בּוּזִי",
		"xlit" : "Bûwzîy",
		"pron" : "boo-zee'",
		"derivation" : "the same as H940 (בּוּזִי)",
		"def" : "Buzi, an Israelite",
		"kjv" : "Buzi"
	},
	"H942" : {
		"lemma" : "בַּוַּי",
		"xlit" : "Bavvay",
		"pron" : "bav-vah'ee",
		"derivation" : "probably of Persian origin",
		"def" : "Bavvai, an Israelite",
		"kjv" : "Bavai"
	},
	"H943" : {
		"lemma" : "בּוּךְ",
		"xlit" : "bûwk",
		"pron" : "book",
		"derivation" : "a primitive root",
		"def" : "to involve (literally or figuratively)",
		"kjv" : "be entangled, (perplexed)"
	},
	"H944" : {
		"lemma" : "בּוּל",
		"xlit" : "bûwl",
		"pron" : "bool",
		"derivation" : "for H2981 (יְבוּל)",
		"def" : "produce (of the earth, etc.)",
		"kjv" : "food, stock"
	},
	"H945" : {
		"lemma" : "בּוּל",
		"xlit" : "Bûwl",
		"pron" : "bool",
		"derivation" : "the same as H944 (בּוּל) (in the sense of rain)",
		"def" : "Bul, the eighth Hebrew month",
		"kjv" : "Bul"
	},
	"H946" : {
		"lemma" : "בּוּנָה",
		"xlit" : "Bûwnâh",
		"pron" : "boo-naw'",
		"derivation" : "from H995 (בִּין); discretion",
		"def" : "Bunah, an Israelite",
		"kjv" : "Bunah"
	},
	"H947" : {
		"lemma" : "בּוּס",
		"xlit" : "bûwç",
		"pron" : "boos",
		"derivation" : "a primitive root",
		"def" : "to trample (literally or figuratively)",
		"kjv" : "loath, tread (down, under (foot)), be polluted"
	},
	"H948" : {
		"lemma" : "בּוּץ",
		"xlit" : "bûwts",
		"pron" : "boots",
		"derivation" : "from an unused root (of the same form) meaning to bleach, i.e. (intransitive) be white",
		"def" : "probably cotton (of some sort)",
		"kjv" : "fine (white) linen"
	},
	"H949" : {
		"lemma" : "בּוֹצֵץ",
		"xlit" : "Bôwtsêts",
		"pron" : "bo-tsates'",
		"derivation" : "from the same as H948 (בּוּץ); shining",
		"def" : "Botsets, a rock near Michmash",
		"kjv" : "Bozez"
	},
	"H950" : {
		"lemma" : "בּוּקָה",
		"xlit" : "bûwqâh",
		"pron" : "boo-kaw'",
		"derivation" : "feminine passive participle of an unused root (meaning to be hollow)",
		"def" : "emptiness (as adjective)",
		"kjv" : "empty"
	},
	"H951" : {
		"lemma" : "בּוֹקֵר",
		"xlit" : "bôwqêr",
		"pron" : "bo-kare'",
		"derivation" : "properly, active participle from H1239 (בָּקַר) as denominative from H1241 (בָּקָר)",
		"def" : "a cattle-tender",
		"kjv" : "herdman"
	},
	"H952" : {
		"lemma" : "בּוּר",
		"xlit" : "bûwr",
		"pron" : "boor",
		"derivation" : "a primitive root",
		"def" : "to bore, i.e. (figuratively) examine",
		"kjv" : "declare"
	},
	"H953" : {
		"lemma" : "בּוֹר",
		"xlit" : "bôwr",
		"pron" : "bore",
		"derivation" : "from H952 (בּוּר) (in the sense of H877 (בֹּאר))",
		"def" : "a pit hole (especially one used as a cistern or a prison)",
		"kjv" : "cistern, dungeon, fountain, pit, well"
	},
	"H954" : {
		"lemma" : "בּוּשׁ",
		"xlit" : "bûwsh",
		"pron" : "boosh",
		"derivation" : "a primitive root",
		"def" : "properly, to pale, i.e. by implication to be ashamed; also (by implication) to be disappointed or delayed",
		"kjv" : "(be, make, bring to, cause, put to, with, a-) shamed(-d), be (put to) confounded(-fusion), become dry, delay, be long"
	},
	"H955" : {
		"lemma" : "בּוּשָׁה",
		"xlit" : "bûwshâh",
		"pron" : "boo-shaw'",
		"derivation" : "feminine participle passive of H954 (בּוּשׁ)",
		"def" : "shame",
		"kjv" : "shame"
	},
	"H956" : {
		"lemma" : "בּוּת",
		"xlit" : "bûwth",
		"pron" : "booth",
		"derivation" : "(Aramaic) apparent denominative from H1005 (בַּיִת)",
		"def" : "to lodge over night",
		"kjv" : "pass the night"
	},
	"H957" : {
		"lemma" : "בַּז",
		"xlit" : "baz",
		"pron" : "baz",
		"derivation" : "from H962 (בָּזַז)",
		"def" : "plunder",
		"kjv" : "booty, prey, spoil(-ed)"
	},
	"H958" : {
		"lemma" : "בָּזָא",
		"xlit" : "bâzâʼ",
		"pron" : "baw-zaw'",
		"derivation" : "a primitive root",
		"def" : "probably to cleave",
		"kjv" : "spoil"
	},
	"H959" : {
		"lemma" : "בָּזָה",
		"xlit" : "bâzâh",
		"pron" : "baw-zaw'",
		"derivation" : "a primitive root",
		"def" : "to disesteem",
		"kjv" : "despise, disdain, contemn(-ptible), [phrase] think to scorn, vile person"
	},
	"H960" : {
		"lemma" : "בָּזֹה",
		"xlit" : "bâzôh",
		"pron" : "baw-zo'",
		"derivation" : "from H959 (בָּזָה)",
		"def" : "scorned",
		"kjv" : "despise"
	},
	"H961" : {
		"lemma" : "בִּזָּה",
		"xlit" : "bizzâh",
		"pron" : "biz-zaw'",
		"derivation" : "feminine of H957 (בַּז)",
		"def" : "booty",
		"kjv" : "prey, spoil"
	},
	"H962" : {
		"lemma" : "בָּזַז",
		"xlit" : "bâzaz",
		"pron" : "baw-zaz'",
		"derivation" : "a primitive root",
		"def" : "to plunder",
		"kjv" : "catch, gather, (take) for a prey, rob(-ber), spoil, take (away, spoil), [idiom] utterly"
	},
	"H963" : {
		"lemma" : "בִּזָּיוֹן",
		"xlit" : "bizzâyôwn",
		"pron" : "biz-zaw-yone'",
		"derivation" : "from H959 (בָּזָה)",
		"def" : "disesteem",
		"kjv" : "contempt"
	},
	"H964" : {
		"lemma" : "בִּזְיוֹתְיָה",
		"xlit" : "bizyôwthᵉyâh",
		"pron" : "biz-yo-the-yaw'",
		"derivation" : "from H959 (בָּזָה) and H3050 (יָהּ); contempts of Jah",
		"def" : "Bizjothjah, a place in Palestine",
		"kjv" : "Bizjothjah"
	},
	"H965" : {
		"lemma" : "בָּזָק",
		"xlit" : "bâzâq",
		"pron" : "baw-zawk'",
		"derivation" : "from an unused root meaning to lighten",
		"def" : "a flash of lightning",
		"kjv" : "flash of lightning"
	},
	"H966" : {
		"lemma" : "בֶּזֶק",
		"xlit" : "Bezeq",
		"pron" : "beh'-zak",
		"derivation" : "from H965 (בָּזָק); lightning",
		"def" : "Bezek, a place in Palestine",
		"kjv" : "Bezek"
	},
	"H967" : {
		"lemma" : "בָּזַר",
		"xlit" : "bâzar",
		"pron" : "baw-zar'",
		"derivation" : "a primitive root",
		"def" : "to disperse",
		"kjv" : "scatter"
	},
	"H968" : {
		"lemma" : "בִּזְתָא",
		"xlit" : "Bizthâʼ",
		"pron" : "biz-thaw'",
		"derivation" : "of Persian origin",
		"def" : "Biztha, a eunuch of Xerxes",
		"kjv" : "Biztha"
	},
	"H969" : {
		"lemma" : "בָּחוֹן",
		"xlit" : "bâchôwn",
		"pron" : "baw-khone'",
		"derivation" : "from H974 (בָּחַן)",
		"def" : "an assayer or metals",
		"kjv" : "tower"
	},
	"H970" : {
		"lemma" : "בָּחוּר",
		"xlit" : "bâchûwr",
		"pron" : "baw-khoor'",
		"derivation" : "or בָּחֻר; participle passive of H977 (בָּחַר)",
		"def" : "properly, selected, i.e. a youth (often collective)",
		"kjv" : "(choice) young (man), chosen, [idiom] hole"
	},
	"H971" : {
		"lemma" : "בַּחִין",
		"xlit" : "bachîyn",
		"pron" : "bakh-een'",
		"derivation" : "another form of H975 (בַּחַן)",
		"def" : "a watch-tower of besiegers",
		"kjv" : "tower"
	},
	"H972" : {
		"lemma" : "בָּחִיר",
		"xlit" : "bâchîyr",
		"pron" : "baw-kheer'",
		"derivation" : "from H977 (בָּחַר)",
		"def" : "select",
		"kjv" : "choose, chosen one, elect"
	},
	"H973" : {
		"lemma" : "בָּחַל",
		"xlit" : "bâchal",
		"pron" : "baw-khal'",
		"derivation" : "a primitive root",
		"def" : "to loath",
		"kjv" : "abhor, get hastily (from the margin for H926 (בָּהַל))"
	},
	"H974" : {
		"lemma" : "בָּחַן",
		"xlit" : "bâchan",
		"pron" : "baw-khan'",
		"derivation" : "a primitive root",
		"def" : "to test (especially metals); generally and figuratively, to investigate",
		"kjv" : "examine, prove, tempt, try (trial)"
	},
	"H975" : {
		"lemma" : "בַּחַן",
		"xlit" : "bachan",
		"pron" : "bakh'-an",
		"derivation" : "from H974 (בָּחַן) (in the sense of keeping a look-out)",
		"def" : "a watch-tower",
		"kjv" : "tower"
	},
	"H976" : {
		"lemma" : "בֹּחַן",
		"xlit" : "bôchan",
		"pron" : "bo'-khan",
		"derivation" : "from H974 (בָּחַן)",
		"def" : "trial",
		"kjv" : "tried"
	},
	"H977" : {
		"lemma" : "בָּחַר",
		"xlit" : "bâchar",
		"pron" : "baw-khar'",
		"derivation" : "a primitive root",
		"def" : "properly, to try, i.e. (by implication) select",
		"kjv" : "acceptable, appoint, choose (choice), excellent, join, be rather, require"
	},
	"H978" : {
		"lemma" : "בַּחֲרוּמִי",
		"xlit" : "Bachărûwmîy",
		"pron" : "bakh-ar-oo-mee'",
		"derivation" : "patrial from H980 (בַּחֻרִים) (by transposition)",
		"def" : "a Bacharumite or inhabitant of Bachurim",
		"kjv" : "Baharumite"
	},
	"H979" : {
		"lemma" : "בְּחֻרוֹת",
		"xlit" : "bᵉchurôwth",
		"pron" : "bekh-oo-rothe'",
		"derivation" : "or בְּחוּרוֹת; feminine plural of H970 (בָּחוּר); also (masculine plural) בְּחֻרִים",
		"def" : "youth (collectively and abstractly)",
		"kjv" : "young men, youth"
	},
	"H980" : {
		"lemma" : "בַּחֻרִים",
		"xlit" : "Bachurîym",
		"pron" : "bakh-oo-reem'",
		"derivation" : "or בַּחוּרִים; masculine plural of H970 (בָּחוּר); young men",
		"def" : "Bachurim, a place in Palestine",
		"kjv" : "Bahurim"
	},
	"H981" : {
		"lemma" : "בָּטָא",
		"xlit" : "bâṭâʼ",
		"pron" : "baw-taw'",
		"derivation" : "or בָּטָה; a primitive root",
		"def" : "to babble; hence, to vociferate angrily",
		"kjv" : "pronounce, speak (unadvisedly)"
	},
	"H982" : {
		"lemma" : "בָּטַח",
		"xlit" : "bâṭach",
		"pron" : "baw-takh'",
		"derivation" : "a primitive root; properly, to hide for refuge (but not so precipitately as H2620 (חָסָה))",
		"def" : "figuratively, to trust, be confident or sure",
		"kjv" : "be bold (confident, secure, sure), careless (one, woman), put confidence, (make to) hope, (put, make to) trust"
	},
	"H983" : {
		"lemma" : "בֶּטַח",
		"xlit" : "beṭach",
		"pron" : "beh'takh",
		"derivation" : "from H982 (בָּטַח)",
		"def" : "properly, a place of refuge; abstract, safety, both the fact (security) and the feeling (trust); often (adverb with or without preposition) safely",
		"kjv" : "assurance, boldly, (without) care(-less), confidence, hope, safe(-ly, -ty), secure, surely"
	},
	"H984" : {
		"lemma" : "בֶּטַח",
		"xlit" : "Beṭach",
		"pron" : "beh'takh",
		"derivation" : "the same as H983 (בֶּטַח)",
		"def" : "Betach, a place in Syria",
		"kjv" : "Betah"
	},
	"H985" : {
		"lemma" : "בִּטְחָה",
		"xlit" : "biṭchâh",
		"pron" : "bit-khaw'",
		"derivation" : "feminine of H984 (בֶּטַח)",
		"def" : "trust",
		"kjv" : "confidence"
	},
	"H986" : {
		"lemma" : "בִּטָּחוֹן",
		"xlit" : "biṭṭâchôwn",
		"pron" : "bit-taw-khone'",
		"derivation" : "from H982 (בָּטַח)",
		"def" : "trust",
		"kjv" : "confidence, hope"
	},
	"H987" : {
		"lemma" : "בַּטֻּחוֹת",
		"xlit" : "baṭṭuchôwth",
		"pron" : "bat-too-khoth'",
		"derivation" : "feminine plural from H982 (בָּטַח)",
		"def" : "security",
		"kjv" : "secure"
	},
	"H988" : {
		"lemma" : "בָּטֵל",
		"xlit" : "bâṭêl",
		"pron" : "baw-tale'",
		"derivation" : "a primitive root",
		"def" : "to desist from labor",
		"kjv" : "cease"
	},
	"H989" : {
		"lemma" : "בְּטֵל",
		"xlit" : "bᵉṭêl",
		"pron" : "bet-ale'",
		"derivation" : "(Aramaic) corresponding to H988 (בָּטֵל)",
		"def" : "to stop",
		"kjv" : "(cause, make to), cease, hinder"
	},
	"H990" : {
		"lemma" : "בֶּטֶן",
		"xlit" : "beṭen",
		"pron" : "beh'-ten",
		"derivation" : "from an unused root probably meaning to be hollow",
		"def" : "the belly, especially the womb; also the bosom or body of anything",
		"kjv" : "belly, body, [phrase] as they be born, [phrase] within, womb"
	},
	"H991" : {
		"lemma" : "בֶּטֶן",
		"xlit" : "Beṭen",
		"pron" : "beh'-ten",
		"derivation" : "the same as H990 (בֶּטֶן)",
		"def" : "Beten. a place in Palestine",
		"kjv" : "Beten"
	},
	"H992" : {
		"lemma" : "בֹּטֶן",
		"xlit" : "bôṭen",
		"pron" : "bo'-ten",
		"derivation" : "from H990 (בֶּטֶן)",
		"def" : "(only in plural) a pistachio-nut (from its form)",
		"kjv" : "nut"
	},
	"H993" : {
		"lemma" : "בְּטֹנִים",
		"xlit" : "Bᵉṭônîym",
		"pron" : "bet-o-neem'",
		"derivation" : "probably plural from H992 (בֹּטֶן); hollows",
		"def" : "Betonim, a place in Palestine",
		"kjv" : "Betonim"
	},
	"H994" : {
		"lemma" : "בִּי",
		"xlit" : "bîy",
		"pron" : "bee",
		"derivation" : "perhaps from H1158 (בָּעָה) (in the sense of asking); properly, a request; used only adverbially (always with 'my Lord')",
		"def" : "oh that!; with leave, or if it please",
		"kjv" : "alas, O, oh"
	},
	"H995" : {
		"lemma" : "בִּין",
		"xlit" : "bîyn",
		"pron" : "bene",
		"derivation" : "a primitive root",
		"def" : "to separate mentally (or distinguish), i.e.(generally) understand",
		"kjv" : "attend, consider, be cunning, diligently, direct, discern, eloquent, feel, inform, instruct, have intelligence, know, look well to, mark, perceive, be prudent, regard, (can) skill(-full), teach, think, (cause, make to, get, give, have) understand(-ing), view, (deal) wise(-ly, man)"
	},
	"H996" : {
		"lemma" : "בֵּין",
		"xlit" : "bêyn",
		"pron" : "bane",
		"derivation" : "(sometimes in the plural masculine or feminine); properly, the constructive form of an otherwise unused noun from H995 (בִּין); a distinction; but used only as a preposition",
		"def" : "between (repeated before each noun, often with other particles); also as a conjunction, either...or",
		"kjv" : "among, asunder, at, between (-twixt...and), [phrase] from (the widest), [idiom] in, out of, whether (it be...or), within"
	},
	"H997" : {
		"lemma" : "בֵּין",
		"xlit" : "bêyn",
		"pron" : "bane",
		"derivation" : "(Aramaic) corresponding to H996 (בֵּין)",
		"def" : "{between (repeated before each noun, often with other particles); also as a conjunction, either...or}",
		"kjv" : "among, between"
	},
	"H998" : {
		"lemma" : "בִּינָה",
		"xlit" : "bîynâh",
		"pron" : "bee-naw'",
		"derivation" : "from H995 (בִּין)",
		"def" : "understanding",
		"kjv" : "knowledge, meaning, [idiom] perfectly, understanding, wisdom"
	},
	"H999" : {
		"lemma" : "בִּינָה",
		"xlit" : "bîynâh",
		"pron" : "bee-naw'",
		"derivation" : "(Aramaic) corresponding to H998 (בִּינָה)",
		"def" : "{understanding}",
		"kjv" : "knowledge"
	},
	"H1000" : {
		"lemma" : "בֵּיצָה",
		"xlit" : "bêytsâh",
		"pron" : "bay-tsaw'",
		"derivation" : "from the same as H948 (בּוּץ)",
		"def" : "an egg (from its whiteness)",
		"kjv" : "egg"
	},
	"H1001" : {
		"lemma" : "בִּירָא",
		"xlit" : "bîyrâʼ",
		"pron" : "bee-raw'",
		"derivation" : "(Aramaic) corresponding to H1002 (בִּירָה)",
		"def" : "a palace",
		"kjv" : "palace"
	},
	"H1002" : {
		"lemma" : "בִּירָה",
		"xlit" : "bîyrâh",
		"pron" : "bee-raw'",
		"derivation" : "of foreign origin",
		"def" : "a castle or palace",
		"kjv" : "palace"
	},
	"H1003" : {
		"lemma" : "בִּירָנִית",
		"xlit" : "bîyrânîyth",
		"pron" : "bee-raw-neeth'",
		"derivation" : "from H1002 (בִּירָה)",
		"def" : "a fortress",
		"kjv" : "castle"
	},
	"H1004" : {
		"lemma" : "בַּיִת",
		"xlit" : "bayith",
		"pron" : "bah'-yith",
		"derivation" : "probably from H1129 (בָּנָה) abbreviated",
		"def" : "a house (in the greatest variation of applications, especially family, etc.)",
		"kjv" : "court, daughter, door, [phrase] dungeon, family, [phrase] forth of, [idiom] great as would contain, hangings, home(born), (winter) house(-hold), inside(-ward), palace, place, [phrase] prison, [phrase] steward, [phrase] tablet, temple, web, [phrase] within(-out)"
	},
	"H1005" : {
		"lemma" : "בַּיִת",
		"xlit" : "bayith",
		"pron" : "bah-yith",
		"derivation" : "(Aramaic) corresponding to H1004 (בַּיִת)",
		"def" : "{a house (in the greatest variation of applications, especially family, etc.)}",
		"kjv" : "house"
	},
	"H1006" : {
		"lemma" : "בַּיִת",
		"xlit" : "Bayith",
		"pron" : "bah'-yith",
		"derivation" : "the same as H1004 (בַּיִת)",
		"def" : "Bajith, a place in Palestine",
		"kjv" : "Bajith"
	},
	"H1007" : {
		"lemma" : "בֵּית אָוֶן",
		"xlit" : "Bêyth ʼÂven",
		"pron" : "bayth aw'-ven",
		"derivation" : "from H1004 (בַּיִת) and H205 (אָוֶן); house of vanity",
		"def" : "Beth-Aven, a place in Palestine",
		"kjv" : "Beth-aven"
	},
	"H1008" : {
		"lemma" : "בֵּית־אֵל",
		"xlit" : "Bêyth-ʼÊl",
		"pron" : "bayth-ale'",
		"derivation" : "from H1004 (בַּיִת) and H410 (אֵל); house of God",
		"def" : "Beth-El, a place in Palestine",
		"kjv" : "Beth-el"
	},
	"H1009" : {
		"lemma" : "בֵּית אַרְבֵּאל",
		"xlit" : "Bêyth ʼArbêʼl",
		"pron" : "bayth ar-bale'",
		"derivation" : "from H1004 (בַּיִת) and H695 (אֶרֶב) and H410 (אֵל); house of God's ambush",
		"def" : "Beth-Arbel, a place in Palestine",
		"kjv" : "Beth-Arbel"
	},
	"H1010" : {
		"lemma" : "בֵּית בַּעַל מְעוֹן",
		"xlit" : "Bêyth Baʻal Mᵉʻôwn",
		"pron" : "bayth bah'-al me-own'",
		"derivation" : "from H1004 (בַּיִת) and H1168 (בַּעַל) and H4583 (מָעוֹן); house of Baal of (the) habitation of (apparently by transposition); or (shorter) בֵּית מְעוֹןlemma בֵּית מעוֹן missing vowel, corrected to בֵּית מְעוֹן; house of habitation of (Baal)",
		"def" : "Beth-Baal-Meon, a place in Palestine",
		"kjv" : "Beth-baal-meon. Compare H1186 (בַּעַל מְעוֹן) and H1194 (בְּעֹן)"
	},
	"H1011" : {
		"lemma" : "בֵּית בִּרְאִי",
		"xlit" : "Bêyth Birʼîy",
		"pron" : "bayth bir-ee'",
		"derivation" : "from H1004 (בַּיִת) and H1254 (בָּרָא); house of a creative one",
		"def" : "Beth-Biri, a place in Palestine",
		"kjv" : "Bethbirei"
	},
	"H1012" : {
		"lemma" : "בֵּית בָּרָה",
		"xlit" : "Bêyth Bârâh",
		"pron" : "bayth baw-raw'",
		"derivation" : "probably from H1004 (בַּיִת) and H5679 (עֲבָרָה); house of (the) ford",
		"def" : "Beth-Barah, a place in Palestine",
		"kjv" : "Beth-barah"
	},
	"H1013" : {
		"lemma" : "בֵּית־גָּדֵר",
		"xlit" : "Bêyth-Gâdêr",
		"pron" : "bayth-gaw-dare'",
		"derivation" : "from H1004 (בַּיִת) and H1447 (גָּדֵר); house of (the) wall",
		"def" : "Beth-Gader, a place in Palestine",
		"kjv" : "Beth-gader"
	},
	"H1014" : {
		"lemma" : "בֵּית גָּמוּל",
		"xlit" : "Bêyth Gâmûwl",
		"pron" : "bayth gaw-mool'",
		"derivation" : "from H1004 (בַּיִת) and the passive participle of H1576 (גְּמוּל); house of (the) weaned",
		"def" : "Beth-Gamul, a place East of the Jordan",
		"kjv" : "Beth-gamul"
	},
	"H1015" : {
		"lemma" : "בֵּית דִּבְלָתַיִם",
		"xlit" : "Bêyth Diblâthayim",
		"pron" : "bayth dib-law-thah'-yim",
		"derivation" : "from H1004 (בַּיִת) and the dual of H1690 (דְּבֵלָה); house of (the) two figcakes",
		"def" : "Beth-Diblathajim, a place East of the Jordan",
		"kjv" : "Beth-diblathaim"
	},
	"H1016" : {
		"lemma" : "בֵּית־דָּגוֹן",
		"xlit" : "Bêyth-Dâgôwn",
		"pron" : "bayth-daw-gohn'",
		"derivation" : "from H1004 (בַּיִת) and H1712 (דָּגוֹן); house of Dagon",
		"def" : "Beth-Dagon, the name of two places in Palestine",
		"kjv" : "Beth-dagon"
	},
	"H1017" : {
		"lemma" : "בֵּית הָאֱלִי",
		"xlit" : "Bêyth hâ-ʼĔlîy",
		"pron" : "bayth haw-el-ee'",
		"derivation" : "patrial from H1008 (בֵּית־אֵל) with the article interposed",
		"def" : "a Beth-elite, or inhabitant of Bethel",
		"kjv" : "Bethelite"
	},
	"H1018" : {
		"lemma" : "בֵּית הָאֵצֶל",
		"xlit" : "Bêyth hâʼêtsel",
		"pron" : "bayth haw-ay'-tsel",
		"derivation" : "from H1004 (בַּיִת) and H681 (אֵצֶל) with the article interposed; house of the side",
		"def" : "Beth-ha-Etsel, a place in Palestine",
		"kjv" : "Beth-ezel"
	},
	"H1019" : {
		"lemma" : "בֵּית הַגִּלְגָּל",
		"xlit" : "Bêyth hag-Gilgâl",
		"pron" : "bayth hag-gil gawl'",
		"derivation" : "from H1004 (בַּיִת) and H1537 (גִּלְגָּל) with the article interposed; house of Gilgal (or rolling)",
		"def" : "Beth-hag-Gilgal, a place in Palestine",
		"kjv" : "Beth-gilgal"
	},
	"H1020" : {
		"lemma" : "בֵּית הַיְשִׁימוֹת",
		"xlit" : "Bêyth ha-Yshîy-môwth",
		"pron" : "bayth hah-yesh-eemoth'",
		"derivation" : "from H1004 (בַּיִת) and the plural of H3451 (יְשִׁימַה) with the article interposed; house of the deserts",
		"def" : "Beth-ha-Jeshimoth, a town East of the Jordan",
		"kjv" : "Beth-jeshimoth"
	},
	"H1021" : {
		"lemma" : "בֵּית הַכֶּרֶם",
		"xlit" : "Bêyth hak-Kerem",
		"pron" : "bayth hak-keh'-rem",
		"derivation" : "from H1004 (בַּיִת) and H3754 (כֶּרֶם) with the article interposed; house of the vineyard",
		"def" : "Beth-hak-Kerem, a place in Palestine",
		"kjv" : "Beth-haccerem"
	},
	"H1022" : {
		"lemma" : "בֵּית הַלַּחְמִי",
		"xlit" : "Bêyth hal-Lachmîy",
		"pron" : "bayth hal-lakh-mee'",
		"derivation" : "patrial from H1035 (בֵּית לֶחֶם) with the article inserted",
		"def" : "a Beth-lechemite, or native of Bethlechem",
		"kjv" : "Bethlehemite"
	},
	"H1023" : {
		"lemma" : "בֵּית הַמֶּרְחָק",
		"xlit" : "Bêyth ham-Merchâq",
		"pron" : "bayth ham-mer-khawk'",
		"derivation" : "from H1004 (בַּיִת) and H4801 (מֶרְחָק) with the article interposed; house of the breadth",
		"def" : "Beth-ham-Merchak, a place in Palestine",
		"kjv" : "place that was far off"
	},
	"H1024" : {
		"lemma" : "בֵּית הַמַּרְכָּבוֹת",
		"xlit" : "Bêyth ham-Markâbôwth",
		"pron" : "bayth ham-markaw-both'",
		"derivation" : "or (shortened) בֵּית מַרְכָּבוֹת; from H1004 (בַּיִת) and the plural of H4818 (מֶרְכָּבָה) (with or without the article interposed); place of (the) chariots",
		"def" : "Beth-ham-Markaboth or Beth-Markaboth, a place in Palestine",
		"kjv" : "Bethmarcaboth"
	},
	"H1025" : {
		"lemma" : "בֵּית הָעֵמֶק",
		"xlit" : "Bêyth hâ-ʻÊmeq",
		"pron" : "bayth haw-Ay'-mek",
		"derivation" : "from H1004 (בַּיִת) and H6010 (עֵמֶק) with the article interposed; house of the valley",
		"def" : "Beth-ha-Emek, a place in Palestine",
		"kjv" : "Beth-emek"
	},
	"H1026" : {
		"lemma" : "בֵּית הָעֲרָבָה",
		"xlit" : "Bêyth hâ-ʻĂrâbâh",
		"pron" : "bayth haw-ar-aw-baw",
		"derivation" : "from H1004 (בַּיִת) and H6160 (עֲרָבָה) with the article interposed; house of the Desert",
		"def" : "Beth-ha-Arabah, a place in Palestine",
		"kjv" : "Beth-arabah"
	},
	"H1027" : {
		"lemma" : "בֵּית הָרָם",
		"xlit" : "Bêyth hâ-Râm",
		"pron" : "bayth haw-rawm'",
		"derivation" : "from H1004 (בַּיִת) and H7311 (רוּם) with the article interposed; house of the height",
		"def" : "Beth-ha-Ram, a place East of the Jordan",
		"kjv" : "Beth-aram"
	},
	"H1028" : {
		"lemma" : "בֵּית הָרָן",
		"xlit" : "Bêyth hâ-Rân",
		"pron" : "bayth haw-rawn'",
		"derivation" : "probably for H1027 (בֵּית הָרָם)",
		"def" : "Beth-ha-Ram, a place East of the Jordan",
		"kjv" : "Beth-haran"
	},
	"H1029" : {
		"lemma" : "בֵּית הַשִּׁטָּה",
		"xlit" : "Bêyth hash-Shiṭṭâh",
		"pron" : "bayth hash-shit-taw'",
		"derivation" : "from H1004 (בַּיִת) and H7848 (שִׁטָּה) with the article interposed; house of the acacia",
		"def" : "Beth-hash-Shittah, a place in Palestine",
		"kjv" : "Beth-shittah"
	},
	"H1030" : {
		"lemma" : "בֵּית הַשִּׁמְשִׁי",
		"xlit" : "Bêyth hash-Shimshîy",
		"pron" : "bayth hash-shim-shee'",
		"derivation" : "patrial from H1053 (בֵּית שֶׁמֶשׁ) with the article inserted",
		"def" : "a Beth-shimshite, or inhabitant of Bethshemesh",
		"kjv" : "Bethshemite"
	},
	"H1031" : {
		"lemma" : "בֵּית חׇגְלָה",
		"xlit" : "Bêyth Choglâh",
		"pron" : "bayth chog-law'",
		"derivation" : "from H1004 (בַּיִת) and the same as H2295 (חׇגְלָה); house of a partridge",
		"def" : "Beth-Choglah, a place in Palestine",
		"kjv" : "Beth-hoglah"
	},
	"H1032" : {
		"lemma" : "בֵּית חוֹרוֹן",
		"xlit" : "Bêyth Chôwrôwn",
		"pron" : "bayth kho-rone'",
		"derivation" : "from H1004 (בַּיִת) and H2356 (חוֹר); house of hollowness",
		"def" : "Beth-Choron, the name of two adjoining places in Palestine",
		"kjv" : "Beth-horon"
	},
	"H1033" : {
		"lemma" : "בֵּית כַּר",
		"xlit" : "Bêyth Kar",
		"pron" : "bayth kar",
		"derivation" : "from H1004 (בַּיִת) and H3733 (כַּר); house of pasture",
		"def" : "Beth-Car, a place in Palestine",
		"kjv" : "Beth-car"
	},
	"H1034" : {
		"lemma" : "בֵּית לְבָאוֹת",
		"xlit" : "Bêyth Lᵉbâʼôwth",
		"pron" : "bayth leb-aw-oth'",
		"derivation" : "from H1004 (בַּיִת) and the plural of H3833 (לָבִיא); house of lionesses",
		"def" : "Beth-Lebaoth, a place in Palestine",
		"kjv" : "Beth-lebaoth. Compare H3822 (לְבָאוֹת)"
	},
	"H1035" : {
		"lemma" : "בֵּית לֶחֶם",
		"xlit" : "Bêyth Lechem",
		"pron" : "bayth leh'-khem",
		"derivation" : "from H1004 (בַּיִת) and H3899 (לֶחֶם); house of bread",
		"def" : "Beth-Lechem, a place in Palestine",
		"kjv" : "Bethlehem"
	},
	"H1036" : {
		"lemma" : "בֵּית לְעַפְרָה",
		"xlit" : "Bêyth lᵉ-ʻAphrâh",
		"pron" : "bayth le-af-raw'",
		"derivation" : "from H1004 (בַּיִת) and the feminine of H6083 (עָפָר) (with preposition interposed); house to (i.e. of) dust",
		"def" : "Beth-le-Aphrah, a place in Palestine",
		"kjv" : "house of Aphrah"
	},
	"H1037" : {
		"lemma" : "בֵּית מִלּוֹא",
		"xlit" : "Bêyth Millôwʼ",
		"pron" : "bayth mil-lo'",
		"derivation" : "or בֵּית מִלֹּא; from H1004 (בַּיִת) and H4407 (מִלּוֹא); house of (the) rampart",
		"def" : "Beth-Millo, the name of two citadels",
		"kjv" : "house of Millo"
	},
	"H1038" : {
		"lemma" : "בֵּית מַעֲכָה",
		"xlit" : "Bêyth Maʻăkâh",
		"pron" : "bayth mah-ak-aw'",
		"derivation" : "from H1004 (בַּיִת) and H4601 (מַעֲכָה); house of Maakah",
		"def" : "Beth-Maakah, a place in Palestine",
		"kjv" : "Beth-maachah"
	},
	"H1039" : {
		"lemma" : "בֵּית נִמְרָה",
		"xlit" : "Bêyth Nimrâh",
		"pron" : "bayth nim-raw'",
		"derivation" : "from H1004 (בַּיִת) and the feminine of H5246 (נָמֵר); house of (the) leopard",
		"def" : "Beth-Nimrah, a place east of the Jordan",
		"kjv" : "Beth-Nimrah. Compare H5247 (נִמְרָה)"
	},
	"H1040" : {
		"lemma" : "בֵּית עֵדֶן",
		"xlit" : "Bêyth ʻÊden",
		"pron" : "bayth ay'-den",
		"derivation" : "from H1004 (בַּיִת) and H5730 (עֵדֶן); house of pleasure",
		"def" : "Beth-Eden, a place in Syria",
		"kjv" : "Beth-eden"
	},
	"H1041" : {
		"lemma" : "בֵּית עַזְמָוֶת",
		"xlit" : "Bêyth ʻAzmâveth",
		"pron" : "bayth az-maw'-veth",
		"derivation" : "from H1004 (בַּיִת) and H5820 (עַזְמָוֶת)",
		"def" : "house of Azmaveth, a place in Palestine",
		"kjv" : "Bethaz-maveth. Compare H5820 (עַזְמָוֶת)"
	},
	"H1042" : {
		"lemma" : "בֵּית עֲנוֹת",
		"xlit" : "Bêyth ʻĂnôwth",
		"pron" : "bayth an-oth'",
		"derivation" : "from H1004 (בַּיִת) and a plural from H6030 (עָנָה); house of replies",
		"def" : "Beth-Anoth, a place in Palestine",
		"kjv" : "Beth-anoth"
	},
	"H1043" : {
		"lemma" : "בֵּית עֲנָת",
		"xlit" : "Bêyth ʻĂnâth",
		"pron" : "bayth an-awth'",
		"derivation" : "an orthographical variation for H1042 (בֵּית עֲנוֹת)",
		"def" : "Beth-Anath, a place in Palestine",
		"kjv" : "Beth-anath"
	},
	"H1044" : {
		"lemma" : "בֵּית עֵקֶד",
		"xlit" : "Bêyth ʻÊqed",
		"pron" : "bayth ay'-ked",
		"derivation" : "from H1004 (בַּיִת) and a derivative of H6123 (עָקַד); house of (the) binding (for sheep-shearing)",
		"def" : "Beth-Eked, a place in Palestine",
		"kjv" : "shearing house"
	},
	"H1045" : {
		"lemma" : "בֵּית עַשְׁתָּרוֹת",
		"xlit" : "Bêyth ʻAshtârôwth",
		"pron" : "bayth ash-taw-roth'",
		"derivation" : "; from H1004 (בַּיִת) and H6252 (עַשְׁתָּרוֹת); house of Ashtoreths",
		"def" : "Beth-Ashtaroth, a place in Palestine",
		"kjv" : "house of Ashtaroth. Compare H1203 (בְּעֶשְׁתְּרָה), H6252 (עַשְׁתָּרוֹת)"
	},
	"H1046" : {
		"lemma" : "בֵּית פֶּלֶט",
		"xlit" : "Bêyth Peleṭ",
		"pron" : "bayth peh'-let",
		"derivation" : "from H1004 (בַּיִת) and H6412 (פָּלִיט); house of escape",
		"def" : "Beth-Palet, a place in Palestine",
		"kjv" : "Beth-palet"
	},
	"H1047" : {
		"lemma" : "בֵּית פְּעוֹר",
		"xlit" : "Bêyth Pᵉʻôwr",
		"pron" : "bayth pe-ore'",
		"derivation" : "from H1004 (בַּיִת) and H6465 (פְּעוֹר); house of Peor",
		"def" : "Beth-Peor, a place East of the Jordan",
		"kjv" : "Bethpeor"
	},
	"H1048" : {
		"lemma" : "בֵּית פַּצֵּץ",
		"xlit" : "Bêyth Patstsêts",
		"pron" : "bayth pats-tsates'",
		"derivation" : "from H1004 (בַּיִת) and a derivative from H6327 (פּוּץ); house of dispersion",
		"def" : "Beth-Patstsets, a place in Palestine",
		"kjv" : "Beth-pazzez"
	},
	"H1049" : {
		"lemma" : "בֵּית צוּר",
		"xlit" : "Bêyth Tsûwr",
		"pron" : "bayth tsoor'",
		"derivation" : "from H1004 (בַּיִת) and H6697 (צוּר); house of (the) rock",
		"def" : "Beth-Tsur, a place in Palestine",
		"kjv" : "Beth-zur"
	},
	"H1050" : {
		"lemma" : "בֵּית רְחוֹב",
		"xlit" : "Bêyth Rᵉchôwb",
		"pron" : "bayth re-khobe'",
		"derivation" : "from H1004 (בַּיִת) and H7339 (רְחֹב); house of (the) street",
		"def" : "Beth-Rechob, a place in Palestine",
		"kjv" : "Beth-rehob"
	},
	"H1051" : {
		"lemma" : "בֵּית רָפָא",
		"xlit" : "Bêyth Râphâʼ",
		"pron" : "bayth raw-faw'",
		"derivation" : "from H1004 (בַּיִת) and H7497 (רָפָא); house of (the) giant",
		"def" : "Beth-Rapha, an Israelite",
		"kjv" : "Beth-rapha"
	},
	"H1052" : {
		"lemma" : "בֵּית שְׁאָן",
		"xlit" : "Bêyth Shᵉʼân",
		"pron" : "bayth she-awn'",
		"derivation" : "or בֵּית שָׁן; from H1004 (בַּיִת) and H7599 (שָׁאַן); house of ease",
		"def" : "Beth-Shean or Beth-Shan, a place in Palestine",
		"kjv" : "Beth-shean, Beth-Shan"
	},
	"H1053" : {
		"lemma" : "בֵּית שֶׁמֶשׁ",
		"xlit" : "Bêyth Shemesh",
		"pron" : "bayth sheh'-mesh",
		"derivation" : "from H1004 (בַּיִת) and H8121 (שֶׁמֶשׁ); house of (the) sun",
		"def" : "Beth-Shemesh, a place in Palestine",
		"kjv" : "Beth-shemesh"
	},
	"H1054" : {
		"lemma" : "בֵּית תַּפּוּחַ",
		"xlit" : "Bêyth Tappûwach",
		"pron" : "bayth tap-poo'-akh",
		"derivation" : "from H1004 (בַּיִת) and H8598 (תַּפּוּחַ); house of (the) apple",
		"def" : "Beth-Tappuach, a place in Palestine",
		"kjv" : "Beth-tappuah"
	},
	"H1055" : {
		"lemma" : "בִּיתָן",
		"xlit" : "bîythân",
		"pron" : "bee-thawn'",
		"derivation" : "probably from H1004 (בַּיִת)",
		"def" : "a palace (i.e. large house)",
		"kjv" : "palace"
	},
	"H1056" : {
		"lemma" : "בָּכָא",
		"xlit" : "Bâkâʼ",
		"pron" : "baw-kaw'",
		"derivation" : "from H1058 (בָּכָה), weeping",
		"def" : "Baca, a valley in Palestine",
		"kjv" : "Baca"
	},
	"H1057" : {
		"lemma" : "בָּכָא",
		"xlit" : "bâkâʼ",
		"pron" : "baw-kaw'",
		"derivation" : "the same as H1056 (בָּכָא)",
		"def" : "the weeping tree (some gum-distilling tree, perhaps the balsam)",
		"kjv" : "mulberry tree"
	},
	"H1058" : {
		"lemma" : "בָּכָה",
		"xlit" : "bâkâh",
		"pron" : "baw-kaw'",
		"derivation" : "a primitive root",
		"def" : "to weep; generally to bemoan",
		"kjv" : "[idiom] at all, bewail, complain, make lamentation, [idiom] more, mourn, [idiom] sore, [idiom] with tears, weep"
	},
	"H1059" : {
		"lemma" : "בֶּכֶה",
		"xlit" : "bekeh",
		"pron" : "beh'-keh",
		"derivation" : "from H1058 (בָּכָה)",
		"def" : "a weeping",
		"kjv" : "[idiom] sore"
	},
	"H1060" : {
		"lemma" : "בְּכוֹר",
		"xlit" : "bᵉkôwr",
		"pron" : "bek-ore'",
		"derivation" : "from H1069 (בָּכַר)",
		"def" : "firstborn; hence, chief",
		"kjv" : "eldest (son), firstborn(-ling)"
	},
	"H1061" : {
		"lemma" : "בִּכּוּר",
		"xlit" : "bikkûwr",
		"pron" : "bik-koor'",
		"derivation" : "from H1069 (בָּכַר)",
		"def" : "the first-fruits of the crop",
		"kjv" : "first fruit (-ripe (figuratively)), hasty fruit"
	},
	"H1062" : {
		"lemma" : "בְּכוֹרָה",
		"xlit" : "bᵉkôwrâh",
		"pron" : "bek-o-raw'",
		"derivation" : "or (short) בְּכֹרָה; feminine of H1060 (בְּכוֹר)",
		"def" : "the firstling of man or beast; abstractly primogeniture",
		"kjv" : "birthright, firstborn(-ling)"
	},
	"H1063" : {
		"lemma" : "בִּכּוּרָה",
		"xlit" : "bikkûwrâh",
		"pron" : "bik-koo-raw'",
		"derivation" : "feminine of H1061 (בִּכּוּר)",
		"def" : "the early fig",
		"kjv" : "firstripe (fruit)"
	},
	"H1064" : {
		"lemma" : "בְּכוֹרַת",
		"xlit" : "Bᵉkôwrath",
		"pron" : "bek-o-rath'",
		"derivation" : "feminine of H1062 (בְּכוֹרָה); primogeniture",
		"def" : "Bekorath, an Israelite",
		"kjv" : "Bechorath"
	},
	"H1065" : {
		"lemma" : "בְּכִי",
		"xlit" : "Bᵉkîy",
		"pron" : "bek-ee'",
		"derivation" : "from H1058 (בָּכָה)",
		"def" : "a weeping; by analogy, a dripping",
		"kjv" : "overflowing, [idiom] sore, (continual) weeping, wept"
	},
	"H1066" : {
		"lemma" : "בֹּכִים",
		"xlit" : "Bôkîym",
		"pron" : "bo-keem'",
		"derivation" : "plural active participle of H1058 (בָּכָה); (with the article) the weepers",
		"def" : "Bo-kim, a place in Palestine",
		"kjv" : "Bochim"
	},
	"H1067" : {
		"lemma" : "בְּכִירָה",
		"xlit" : "bᵉkîyrâh",
		"pron" : "bek-ee-raw'",
		"derivation" : "feminine from H1069 (בָּכַר)",
		"def" : "the eldest daughter",
		"kjv" : "firstborn"
	},
	"H1068" : {
		"lemma" : "בְּכִית",
		"xlit" : "bᵉkîyth",
		"pron" : "bek-eeth'",
		"derivation" : "from H1058 (בָּכָה)",
		"def" : "a weeping",
		"kjv" : "mourning"
	},
	"H1069" : {
		"lemma" : "בָּכַר",
		"xlit" : "bâkar",
		"pron" : "baw-kar'",
		"derivation" : "a primitive root; properly, to burst the womb, i.e. (causatively) bear or make early fruit (of woman or tree); also (as denominative from H1061 (בִּכּוּר))",
		"def" : "to give the birthright",
		"kjv" : "make firstborn, be firstling, bring forth first child (new fruit)"
	},
	"H1070" : {
		"lemma" : "בֶּכֶר",
		"xlit" : "beker",
		"pron" : "beh'-ker",
		"derivation" : "from H1069 (בָּכַר) (in the sense of youth)",
		"def" : "a young camel",
		"kjv" : "dromedary"
	},
	"H1071" : {
		"lemma" : "בֶכֶר",
		"xlit" : "Beker",
		"pron" : "beh'-ker",
		"derivation" : "the same as H1070 (בֶּכֶר)",
		"def" : "Beker, the name of two Israelites",
		"kjv" : "Becher"
	},
	"H1072" : {
		"lemma" : "בִּכְרָה",
		"xlit" : "bikrâh",
		"pron" : "bik-raw'",
		"derivation" : "feminine of H1070 (בֶּכֶר)",
		"def" : "a young she-camel",
		"kjv" : "dromedary"
	},
	"H1073" : {
		"lemma" : "בַּכֻּרָה",
		"xlit" : "bakkurâh",
		"pron" : "bak-koo-raw'",
		"derivation" : "by orthographical variation for H1063 (בִּכּוּרָה)",
		"def" : "a first-ripe fig",
		"kjv" : "firstripe"
	},
	"H1074" : {
		"lemma" : "בֹּכְרוּ",
		"xlit" : "Bôkᵉrûw",
		"pron" : "bo-ker-oo'",
		"derivation" : "from H1069 (בָּכַר); first-born",
		"def" : "Bokeru, an Israelite",
		"kjv" : "Bocheru"
	},
	"H1075" : {
		"lemma" : "בִּכְרִי",
		"xlit" : "Bikrîy",
		"pron" : "bik-ree'",
		"derivation" : "from H1069 (בָּכַר); youth-ful",
		"def" : "Bikri, an Israelite",
		"kjv" : "Bichri"
	},
	"H1076" : {
		"lemma" : "בַּכְרִי",
		"xlit" : "Bakrîy",
		"pron" : "bak-ree'",
		"derivation" : "patronymically from H1071 (בֶכֶר)",
		"def" : "a Bakrite (collectively) or descendants of Beker",
		"kjv" : "Bachrites"
	},
	"H1077" : {
		"lemma" : "בַּל",
		"xlit" : "bal",
		"pron" : "bal",
		"derivation" : "from H1086 (בָּלָה)",
		"def" : "properly, a failure; by implication nothing; usually (adverb) not at all; also lest",
		"kjv" : "lest, neither, no, none (that...), not (any), nothing"
	},
	"H1078" : {
		"lemma" : "בֵּל",
		"xlit" : "Bêl",
		"pron" : "bale",
		"derivation" : "by contraction for H1168 (בַּעַל)",
		"def" : "Bel, the Baal of the Babylonians",
		"kjv" : "Bel"
	},
	"H1079" : {
		"lemma" : "בָּל",
		"xlit" : "bâl",
		"pron" : "bawl",
		"derivation" : "(Aramaic) from H1080 (בְּלָא)",
		"def" : "properly, anxiety, i.e. (by implication) the heart (as its seat)",
		"kjv" : "heart"
	},
	"H1080" : {
		"lemma" : "בְּלָא",
		"xlit" : "bᵉlâʼ",
		"pron" : "bel-aw'",
		"derivation" : "(Aramaic) corresponding to H1086 (בָּלָה) (but used only in a mental sense)",
		"def" : "to afflict",
		"kjv" : "wear out"
	},
	"H1081" : {
		"lemma" : "בַּלְאֲדָן",
		"xlit" : "Balʼădân",
		"pron" : "bal-ad-awn'",
		"derivation" : "from H1078 (בֵּל) and H113 (אָדוֹן) (contracted); Bel (is his) lord",
		"def" : "Baladan, the name of a Babylonian prince",
		"kjv" : "Baladan"
	},
	"H1082" : {
		"lemma" : "בָּלַג",
		"xlit" : "bâlag",
		"pron" : "baw-lag'",
		"derivation" : "a primitive root",
		"def" : "to break off or loose (in a favorable or unfavorable sense), i.e. desist (from grief) or invade (with destruction)",
		"kjv" : "comfort, (recover) strength(-en)"
	},
	"H1083" : {
		"lemma" : "בִּלְגַה",
		"xlit" : "Bilgah",
		"pron" : "bil-gaw'",
		"derivation" : "from H1082 (בָּלַג); desistance",
		"def" : "Bilgah, the name of two Israelites",
		"kjv" : "Bilgah"
	},
	"H1084" : {
		"lemma" : "בִּלְגַּי",
		"xlit" : "Bilgay",
		"pron" : "bil-gah'ee",
		"derivation" : "from H1082 (בָּלַג); desistant",
		"def" : "Bilgai, an Israelite",
		"kjv" : "Bilgai"
	},
	"H1085" : {
		"lemma" : "בִּלְדַּד",
		"xlit" : "Bildad",
		"pron" : "bil-dad'",
		"derivation" : "of uncertain derivation",
		"def" : "Bildad, one of Job's friends",
		"kjv" : "Bildad"
	},
	"H1086" : {
		"lemma" : "בָּלָה",
		"xlit" : "bâlâh",
		"pron" : "baw-law'",
		"derivation" : "a primitive root",
		"def" : "to fail; by implication to wear out, decay (causatively, consume, spend)",
		"kjv" : "consume, enjoy long, become (make, wax) old, spend, waste"
	},
	"H1087" : {
		"lemma" : "בָּלֶה",
		"xlit" : "bâleh",
		"pron" : "baw-leh'",
		"derivation" : "from H1086 (בָּלָה)",
		"def" : "worn out",
		"kjv" : "old"
	},
	"H1088" : {
		"lemma" : "בָּלָה",
		"xlit" : "Bâlâh",
		"pron" : "baw-law'",
		"derivation" : "feminine of H1087 (בָּלֶה); failure",
		"def" : "Balah, a place in Palestine",
		"kjv" : "Balah"
	},
	"H1089" : {
		"lemma" : "בָּלַהּ",
		"xlit" : "bâlahh",
		"pron" : "baw-lah'",
		"derivation" : "a primitive root (rather by transposition for H926 (בָּהַל))",
		"def" : "to palpitate; hence, (causatively) to terrify",
		"kjv" : "trouble"
	},
	"H1090" : {
		"lemma" : "בִּלְהָה",
		"xlit" : "Bilhâh",
		"pron" : "bil-haw'",
		"derivation" : "from H1089 (בָּלַהּ); timid",
		"def" : "Bilhah, the name of one of Jacob's concubines; also of a place in Palestine",
		"kjv" : "Bilhah"
	},
	"H1091" : {
		"lemma" : "בַּלָּהָה",
		"xlit" : "ballâhâh",
		"pron" : "bal-law-haw'",
		"derivation" : "from H1089 (בָּלַהּ)",
		"def" : "alarm; hence, destruction",
		"kjv" : "terror, trouble"
	},
	"H1092" : {
		"lemma" : "בִּלְהָן",
		"xlit" : "Bilhân",
		"pron" : "bil-hawn'",
		"derivation" : "from H1089 (בָּלַהּ); timid",
		"def" : "Bilhan, the name of an Edomite and of an Israelite",
		"kjv" : "Bilhan"
	},
	"H1093" : {
		"lemma" : "בְּלוֹ",
		"xlit" : "bᵉlôw",
		"pron" : "bel-o'",
		"derivation" : "(Aramaic) from a root corresponding to H1086 (בָּלָה)",
		"def" : "excise (on articles consumed)",
		"kjv" : "tribute"
	},
	"H1094" : {
		"lemma" : "בְּלוֹא",
		"xlit" : "bᵉlôwʼ",
		"pron" : "bel-o'",
		"derivation" : "or (fully) בְּלוֹי; from H1086 (בָּלָה)",
		"def" : "(only in plural construction) rags",
		"kjv" : "old"
	},
	"H1095" : {
		"lemma" : "בֵּלְטְשַׁאצַּר",
		"xlit" : "Bêlṭᵉshaʼtstsar",
		"pron" : "bale-tesh-ats-tsar'",
		"derivation" : "of foreign derivation",
		"def" : "Belteshatstsar, the Babylonian name of Daniel",
		"kjv" : "Belteshazzar"
	},
	"H1096" : {
		"lemma" : "בֵּלְטְשַׁאצַּר",
		"xlit" : "Bêlṭᵉshaʼtstsar",
		"pron" : "bale-tesh-ats-tsar'",
		"derivation" : "(Aramaic) corresponding to H1095 (בֵּלְטְשַׁאצַּר)",
		"def" : "{Belteshatstsar, the Babylonian name of Daniel}",
		"kjv" : "Belteshazzar"
	},
	"H1097" : {
		"lemma" : "בְּלִי",
		"xlit" : "bᵉlîy",
		"pron" : "bel-ee'",
		"derivation" : "from H1086 (בָּלָה)",
		"def" : "properly, failure, i.e. nothing or destruction; usually (with preposition) without, not yet, because not, as long as, etc.",
		"kjv" : "corruption, ig(norantly), for lack of, where no...is, so that no, none, not, un(awares), without"
	},
	"H1098" : {
		"lemma" : "בְּלִיל",
		"xlit" : "bᵉlîyl",
		"pron" : "bel-eel'",
		"derivation" : "from H1101 (בָּלַל)",
		"def" : "mixed, i.e. (specifically) feed (for cattle)",
		"kjv" : "corn, fodder, provender"
	},
	"H1099" : {
		"lemma" : "בְּלִימָה",
		"xlit" : "bᵉlîymâh",
		"pron" : "bel-ee-mah'",
		"derivation" : "from H1097 (בְּלִי) and H4100 (מָה)",
		"def" : "(as indefinitely) nothing whatever",
		"kjv" : "nothing"
	},
	"H1100" : {
		"lemma" : "בְּלִיַּעַל",
		"xlit" : "bᵉlîyaʻal",
		"pron" : "bel-e-yah'-al",
		"derivation" : "from H1097 (בְּלִי) and H3276 (יַעַל);(often in connection with H376 (אִישׁ), H802 (אִשָּׁה), H1121 (בֵּן), etc.)",
		"def" : "without profit, worthlessness; by extension, destruction, wickedness",
		"kjv" : "Belial, evil, naughty, ungodly (men), wicked"
	},
	"H1101" : {
		"lemma" : "בָּלַל",
		"xlit" : "bâlal",
		"pron" : "baw-lal'",
		"derivation" : "a primitive root; (also  denominatively from H1098 (בְּלִיל)) to fodder",
		"def" : "to overflow (specifically with oil.); by implication, to mix; to fodder",
		"kjv" : "anoint, confound, [idiom] fade, mingle, mix (self), give provender, temper"
	},
	"H1102" : {
		"lemma" : "בָּלַם",
		"xlit" : "bâlam",
		"pron" : "baw-lam'",
		"derivation" : "a primitive root",
		"def" : "to muzzle",
		"kjv" : "be held in"
	},
	"H1103" : {
		"lemma" : "בָּלַס",
		"xlit" : "bâlaç",
		"pron" : "baw-las'",
		"derivation" : "a primitive root",
		"def" : "to pinch sycamore figs (a process necessary to ripen them)",
		"kjv" : "gatherer"
	},
	"H1104" : {
		"lemma" : "בָּלַע",
		"xlit" : "bâlaʻ",
		"pron" : "baw-lah'",
		"derivation" : "a primitive root",
		"def" : "to make away with (specifically by swallowing); generally, to destroy",
		"kjv" : "cover, destroy, devour, eat up, be at end, spend up, swallow down (up)"
	},
	"H1105" : {
		"lemma" : "בֶּלַע",
		"xlit" : "belaʻ",
		"pron" : "beh'-lah",
		"derivation" : "from H1104 (בָּלַע)",
		"def" : "a gulp; figuratively, destruction",
		"kjv" : "devouring, that which he hath swallowed up"
	},
	"H1106" : {
		"lemma" : "בֶּלַע",
		"xlit" : "Belaʻ",
		"pron" : "beh'-lah",
		"derivation" : "the same as H1105 (בֶּלַע)",
		"def" : "Bela, the name of a place, also of an Edomite and of two Israelites",
		"kjv" : "Bela"
	},
	"H1107" : {
		"lemma" : "בִּלְעֲדֵי",
		"xlit" : "bilʻădêy",
		"pron" : "bil-ad-ay'",
		"derivation" : "or בַּלְעֲדֵי; constructive plural from H1077 (בַּל) and H5703 (עַד), not till, i.e. (as preposition or adverb)",
		"def" : "except, without, besides",
		"kjv" : "beside, not (in), save, without"
	},
	"H1108" : {
		"lemma" : "בַּלְעִי",
		"xlit" : "Balʻîy",
		"pron" : "bel-ee'",
		"derivation" : "patronymically from H1106 (בֶּלַע)",
		"def" : "a Belaite (collectively) or descendants of Bela",
		"kjv" : "Belaites"
	},
	"H1109" : {
		"lemma" : "בִּלְעָם",
		"xlit" : "Bilʻâm",
		"pron" : "bil-awm'",
		"derivation" : "probably from H1077 (בַּל) and H5971 (עַם); not (of the) people, i.e. foreigner",
		"def" : "Bilam, a Mesopotamian prophet; also a place in Palestine",
		"kjv" : "Balaam, Bileam"
	},
	"H1110" : {
		"lemma" : "בָּלַק",
		"xlit" : "bâlaq",
		"pron" : "baw-lak'",
		"derivation" : "a primitive root",
		"def" : "to annihilate",
		"kjv" : "(make) waste"
	},
	"H1111" : {
		"lemma" : "בָּלָק",
		"xlit" : "Bâlâq",
		"pron" : "baw-lawk'",
		"derivation" : "from H1110 (בָּלַק); waster",
		"def" : "Balak, a Moabitish king",
		"kjv" : "Balak"
	},
	"H1112" : {
		"lemma" : "בֵּלְשַׁאצַּר",
		"xlit" : "Bêlshaʼtstsar",
		"pron" : "bale-shats-tsar'",
		"derivation" : "or בֵּלְאשַׁצַּרlemma בֵּלְאשַׁצּר missing vowel, corrected to בֵּלְאשַׁצַּר; of foreign origin (compare H1095 (בֵּלְטְשַׁאצַּר))",
		"def" : "Belshatstsar, a Babylonian king",
		"kjv" : "Belshazzar"
	},
	"H1113" : {
		"lemma" : "בֵּלְשַׁאצַּר",
		"xlit" : "Bêlshaʼtstsar",
		"pron" : "bale-shats-tsar'",
		"derivation" : "(Aramaic) corresponding to H1112 (בֵּלְשַׁאצַּר)",
		"def" : "{Belshatstsar, a Babylonian king}",
		"kjv" : "Belshazzar"
	},
	"H1114" : {
		"lemma" : "בִּלְשָׁן",
		"xlit" : "Bilshân",
		"pron" : "bil-shawn'",
		"derivation" : "of uncertain derivation",
		"def" : "Bilshan, an Israelite",
		"kjv" : "Bilshan"
	},
	"H1115" : {
		"lemma" : "בִּלְתִּי",
		"xlit" : "biltîy",
		"pron" : "bil-tee'",
		"derivation" : "constructive feminine of H1086 (בָּלָה) (equivalent to H1097 (בְּלִי))",
		"def" : "properly, a failure of, i.e. (used only as a negative particle, usually with a prepositional prefix) not, except, without, unless, besides, because not, until, etc.",
		"kjv" : "because un(satiable), beside, but, [phrase] continual, except, from, lest, neither, no more, none, not, nothing, save, that no, without"
	},
	"H1116" : {
		"lemma" : "בָּמָה",
		"xlit" : "bâmâh",
		"pron" : "bam-maw'",
		"derivation" : "from an unused root (meaning to be high)",
		"def" : "an elevation",
		"kjv" : "height, high place, wave"
	},
	"H1117" : {
		"lemma" : "בָּמָה",
		"xlit" : "Bâmâh",
		"pron" : "baw-maw'",
		"derivation" : "the same as H1116 (בָּמָה)",
		"def" : "Bamah, a place in Palestine",
		"kjv" : "Bamah. See also H1120 (בָּמוֹת)"
	},
	"H1118" : {
		"lemma" : "בִּמְהָל",
		"xlit" : "Bimhâl",
		"pron" : "bim-hawl'",
		"derivation" : "probably from H4107 (מָהַל) with prepositional prefix; with pruning",
		"def" : "Bimhal, an Israelite",
		"kjv" : "Bimhal"
	},
	"H1119" : {
		"lemma" : "בְּמוֹ",
		"xlit" : "bᵉmôw",
		"pron" : "bem-o'",
		"derivation" : "prolongation for prepositional prefix",
		"def" : "in, with, by, etc.",
		"kjv" : "for, in into, through"
	},
	"H1120" : {
		"lemma" : "בָּמוֹת",
		"xlit" : "Bâmôwth",
		"pron" : "baw-moth'",
		"derivation" : "plural of H1116 (בָּמָה); heights; or (fully) בָּמוֹת בַּעַל; from the same and H1168 (בַּעַל); heights of Baal",
		"def" : "Bamoth or Bamoth-Baal, a place East of the Jordan",
		"kjv" : "Bamoth, Bamoth-baal"
	},
	"H1121" : {
		"lemma" : "בֵּן",
		"xlit" : "bên",
		"pron" : "bane",
		"derivation" : "from H1129 (בָּנָה)",
		"def" : "a son (as a builder of the family name), in the widest sense (of literal and figurative relationship, including grandson, subject, nation, quality or condition, etc., (like father or brother), etc.)",
		"kjv" : "[phrase] afflicted, age, (Ahoh-) (Ammon-) (Hachmon-) (Lev-) ite, (anoint-) ed one, appointed to, ([phrase]) arrow, (Assyr-) (Babylon-) (Egypt-) (Grec-) ian, one born, bough, branch, breed, [phrase] (young) bullock, [phrase] (young) calf, [idiom] came up in, child, colt, [idiom] common, [idiom] corn, daughter, [idiom] of first, [phrase] firstborn, foal, [phrase] very fruitful, [phrase] postage, [idiom] in, [phrase] kid, [phrase] lamb, ([phrase]) man, meet, [phrase] mighty, [phrase] nephew, old, ([phrase]) people, [phrase] rebel, [phrase] robber, [idiom] servant born, [idiom] soldier, son, [phrase] spark, [phrase] steward, [phrase] stranger, [idiom] surely, them of, [phrase] tumultuous one, [phrase] valiant(-est), whelp, worthy, young (one), youth"
	},
	"H1122" : {
		"lemma" : "בֵּן",
		"xlit" : "Bên",
		"pron" : "bane",
		"derivation" : "the same as H1121 (בֵּן)",
		"def" : "Ben, an Israelite",
		"kjv" : "Ben"
	},
	"H1123" : {
		"lemma" : "בֵּן",
		"xlit" : "bên",
		"pron" : "bane",
		"derivation" : "(Aramaic) corresponding to H1121 (בֵּן)",
		"def" : "{a son (as a builder of the family name), in the widest sense}",
		"kjv" : "child, son, young"
	},
	"H1124" : {
		"lemma" : "בְּנָא",
		"xlit" : "bᵉnâʼ",
		"pron" : "ben-aw'",
		"derivation" : "(Aramaic) or בְּנָה; (Aramaic), corresponding to H1129 (בָּנָה)",
		"def" : "to build",
		"kjv" : "build, make"
	},
	"H1125" : {
		"lemma" : "בֶּן־אֲבִינָדָב",
		"xlit" : "Ben-ʼĂbîynâdâb",
		"pron" : "ben-ab-ee''-naw-dawb'",
		"derivation" : "from H1121 (בֵּן) and H40 (אֲבִימֶלֶךְ); (the) son of Abinadab",
		"def" : "Ben-Abinadab, an Israelite",
		"kjv" : "the son of Abinadab"
	},
	"H1126" : {
		"lemma" : "בֶּן־אוֹנִי",
		"xlit" : "Ben-ʼÔwnîy",
		"pron" : "ben-o-nee'",
		"derivation" : "from H1121 (בֵּן) and H205 (אָוֶן); son of my sorrow",
		"def" : "Ben-Oni, the original name of Benjamin",
		"kjv" : "Ben-oni"
	},
	"H1127" : {
		"lemma" : "בֶּן־גֶּבֶר",
		"xlit" : "Ben-Geber",
		"pron" : "ben-gheh'-ber",
		"derivation" : "from H1121 (בֵּן) and H1397 (גֶּבֶר); son of (the) hero",
		"def" : "Ben-Geber, an Israelite",
		"kjv" : "the son of Geber"
	},
	"H1128" : {
		"lemma" : "בֶּן־דֶּקֶר",
		"xlit" : "Ben-Deqer",
		"pron" : "ben-deh'-ker",
		"derivation" : "from H1121 (בֵּן) and a derivative of H1856 (דָּקַר); son of piercing (or of a lance)",
		"def" : "Ben-Deker, an Israelite",
		"kjv" : "the son of Dekar"
	},
	"H1129" : {
		"lemma" : "בָּנָה",
		"xlit" : "bânâh",
		"pron" : "baw-naw'",
		"derivation" : "a primitive root",
		"def" : "to build (literally and figuratively)",
		"kjv" : "(begin to) build(-er), obtain children, make, repair, set (up), [idiom] surely"
	},
	"H1130" : {
		"lemma" : "בֶּן־הֲדַד",
		"xlit" : "Ben-Hădad",
		"pron" : "ben-had-ad'",
		"derivation" : "from H1121 (בֵּן) and H1908 (הֲדַד); son of Hadad",
		"def" : "Ben-Hadad, the name of several Syrian kings",
		"kjv" : "Benhadad"
	},
	"H1131" : {
		"lemma" : "בִּנּוּי",
		"xlit" : "Binnûwy",
		"pron" : "bin-noo'-ee",
		"derivation" : "from H1129 (בָּנָה); built up",
		"def" : "Binnui, an Israelite",
		"kjv" : "Binnui"
	},
	"H1132" : {
		"lemma" : "בֶּן־זוֹחֵת",
		"xlit" : "Ben-Zôwchêth",
		"pron" : "ben-zo-khayth'",
		"derivation" : "from H1121 (בֵּן) and H2105 (זוֹחֵת); son of Zocheth",
		"def" : "Ben-Zocheth, an Israelite",
		"kjv" : "Ben-zoketh"
	},
	"H1133" : {
		"lemma" : "בֶּן־חוּר",
		"xlit" : "Ben-Chûwr",
		"pron" : "ben-khoor'",
		"derivation" : "from H1121 (בֵּן) and H2354 (חוּר); son of Chur",
		"def" : "Ben-Chur, an Israelite",
		"kjv" : "the son of Hur"
	},
	"H1134" : {
		"lemma" : "בֶּן־חַיִל",
		"xlit" : "Ben-Chayil",
		"pron" : "ben-khah'-yil",
		"derivation" : "from H1121 (בֵּן) and H2428 (חַיִל); son of might",
		"def" : "Ben-Chail, an Israelite",
		"kjv" : "Ben-hail"
	},
	"H1135" : {
		"lemma" : "בֶּן־חָנָן",
		"xlit" : "Ben-Chânân",
		"pron" : "ben-khaw-nawn'",
		"derivation" : "from H1121 (בֵּן) and H2605 (חָנָן); son of Chanan",
		"def" : "Ben-Chanan, an Israelite",
		"kjv" : "Ben-hanan"
	},
	"H1136" : {
		"lemma" : "בֶּן־חֶסֶד",
		"xlit" : "Ben-Cheçed",
		"pron" : "ben-kheh'-sed",
		"derivation" : "from H1121 (בֵּן) and H2617 (חֵסֵד); son of kindness",
		"def" : "Ben-Chesed, an Israelite",
		"kjv" : "the son of Hesed"
	},
	"H1137" : {
		"lemma" : "בָּנִי",
		"xlit" : "Bânîy",
		"pron" : "baw-nee'",
		"derivation" : "from H1129 (בָּנָה); built",
		"def" : "Bani, the name of five Israelites",
		"kjv" : "Bani"
	},
	"H1138" : {
		"lemma" : "בֻּנִּי",
		"xlit" : "Bunnîy",
		"pron" : "boon-nee'",
		"derivation" : "or (fuller) בּוּנִי; from H1129 (בָּנָה); built",
		"def" : "Bunni or Buni, an Israelite",
		"kjv" : "Bunni"
	},
	"H1139" : {
		"lemma" : "בְּנֵי־בְּרַק",
		"xlit" : "Bᵉnêy-Bᵉraq",
		"pron" : "ben-ay'-ber-ak'",
		"derivation" : "from the plural construction of H1121 (בֵּן) and H1300 (בָּרָק)",
		"def" : "sons of lightning, Bene-berak, a place in Palestine",
		"kjv" : "Bene-barak"
	},
	"H1140" : {
		"lemma" : "בִּנְיָה",
		"xlit" : "binyâh",
		"pron" : "bin-yaw'",
		"derivation" : "feminine from H1129 (בָּנָה)",
		"def" : "a structure",
		"kjv" : "building"
	},
	"H1141" : {
		"lemma" : "בְּנָיָה",
		"xlit" : "Bᵉnâyâh",
		"pron" : "ben-aw-yaw'",
		"derivation" : "or בּנָיָהוּ; (prolonged) from H1129 (בָּנָה) and H3050 (יָהּ); Jah has built",
		"def" : "Benajah, the name of twelve Israelites",
		"kjv" : "Benaiah"
	},
	"H1142" : {
		"lemma" : "בְּנֵי יַעֲקָן",
		"xlit" : "Bᵉnêy Yaʻăqân",
		"pron" : "ben-ay' yah-ak-awn'",
		"derivation" : "from the plural of H1121 (בֵּן) and H3292 (יַעֲקָן); sons of Yaakan",
		"def" : "Bene-Jaakan, a place in the Desert",
		"kjv" : "Bene-jaakan"
	},
	"H1143" : {
		"lemma" : "בֵּנַיִם",
		"xlit" : "bênayim",
		"pron" : "bay-nah'-yim",
		"derivation" : "dual of H996 (בֵּין)",
		"def" : "a double interval, i.e. the space between two armies",
		"kjv" : "[phrase] champion"
	},
	"H1144" : {
		"lemma" : "בִּנְיָמִין",
		"xlit" : "Binyâmîyn",
		"pron" : "bin-yaw-mene'",
		"derivation" : "from H1121 (בֵּן) and H3225 (יָמִין); son of (the) right hand",
		"def" : "Binjamin, youngest son of Jacob; also the tribe descended from him, and its territory",
		"kjv" : "Benjamin"
	},
	"H1145" : {
		"lemma" : "בֶּן־יְמִינִי",
		"xlit" : "Ben-yᵉmîynîy",
		"pron" : "ben-yem-ee-nee'",
		"derivation" : "sometimes (with the article inserted) בֵּן־הַיּמִינִי; with H376 (אִישׁ) inserted (1 Samuel 9:1) בֶּן־אִישׁ ימִינִי; son of a man of Jemini; or shortened אִישׁ יְמִינִי; (1 Samuel 9:4; Esther 2:5) a man of Jemini, or (1 Samuel 20:1) simply יְמִינִיxlit Yᵉmînîy corrected to Yᵉmîynîy; a Jeminite; (plural) בְּנֵי יְמִינִיxlit Bᵉnîy corrected to Bᵉnêy; patron from H1144 (בִּנְיָמִין)",
		"def" : "a Benjaminite, or descendent of Benjamin",
		"kjv" : "Benjamite, of Benjamin"
	},
	"H1146" : {
		"lemma" : "בִּנְיָן",
		"xlit" : "binyân",
		"pron" : "bin-yawn'",
		"derivation" : "from H1129 (בָּנָה)",
		"def" : "an edifice",
		"kjv" : "building"
	},
	"H1147" : {
		"lemma" : "בִּנְיָן",
		"xlit" : "binyân",
		"pron" : "bin-yawn'",
		"derivation" : "(Aramaic) corresponding to H1146 (בִּנְיָן)",
		"def" : "{an edifice}",
		"kjv" : "building"
	},
	"H1148" : {
		"lemma" : "בְּנִינוּ",
		"xlit" : "Bᵉnîynûw",
		"pron" : "ben-ee-noo'",
		"derivation" : "probably from H1121 (בֵּן) with pron. suff.; our son",
		"def" : "Beninu, an Israelite",
		"kjv" : "Beninu"
	},
	"H1149" : {
		"lemma" : "בְּנַס",
		"xlit" : "bᵉnaç",
		"pron" : "ben-as'",
		"derivation" : "(Aramaic) of uncertain affinity",
		"def" : "to be enraged",
		"kjv" : "be angry"
	},
	"H1150" : {
		"lemma" : "בִּנְעָא",
		"xlit" : "Binʻâʼ",
		"pron" : "bin-aw'",
		"derivation" : "or בִּנְעָה; of uncertain derivation",
		"def" : "Bina or Binah, an Israelite",
		"kjv" : "Binea, Bineah"
	},
	"H1151" : {
		"lemma" : "בֶּן־עַמִּי",
		"xlit" : "Ben-ʻAmmîy",
		"pron" : "ben-am-mee'",
		"derivation" : "from H1121 (בֵּן) and H5971 (עַם) with pronomial suffix; son of my people",
		"def" : "Ben-Ammi, a son of Lot",
		"kjv" : "Ben-ammi"
	},
	"H1152" : {
		"lemma" : "בְּסוֹדְיָה",
		"xlit" : "Bᵉçôwdᵉyâh",
		"pron" : "bes-o-deh-yaw'",
		"derivation" : "from H5475 (סוֹד) and H3050 (יָהּ) with prepositional prefix; in (the) counsel of Jehovah",
		"def" : "Besodejah, an Israelite",
		"kjv" : "Besodeiah"
	},
	"H1153" : {
		"lemma" : "בְּסַי",
		"xlit" : "Bᵉçay",
		"pron" : "bes-ah'-ee",
		"derivation" : "from H947 (בּוּס); domineering",
		"def" : "Besai, one of the Nethinim",
		"kjv" : "Besai"
	},
	"H1154" : {
		"lemma" : "בֶּסֶר",
		"xlit" : "beçer",
		"pron" : "beh'-ser",
		"derivation" : "from an unused root meaning to be sour",
		"def" : "an immature grape",
		"kjv" : "unripe grape"
	},
	"H1155" : {
		"lemma" : "בֹּסֶר",
		"xlit" : "bôçer",
		"pron" : "bo'ser",
		"derivation" : "from the same as H1154 (בֶּסֶר)",
		"def" : "{an immature grape}",
		"kjv" : "sour grape"
	},
	"H1156" : {
		"lemma" : "בְּעָא",
		"xlit" : "bᵉʻâʼ",
		"pron" : "beh-aw'",
		"derivation" : "(Aramaic) or בְּעָה; (Aramaic), corresponding to H1158 (בָּעָה)",
		"def" : "to seek or ask",
		"kjv" : "ask, desire, make (petition), pray, request, seek"
	},
	"H1157" : {
		"lemma" : "בְּעַד",
		"xlit" : "bᵉʻad",
		"pron" : "beh-ad'",
		"derivation" : "from H5704 (עַד) with prepositional prefix",
		"def" : "in up to or over against; generally at, beside, among, behind, for, etc.",
		"kjv" : "about, at by (means of), for, over, through, up (-on), within"
	},
	"H1158" : {
		"lemma" : "בָּעָה",
		"xlit" : "bâʻâh",
		"pron" : "baw-aw'",
		"derivation" : "a primitive root",
		"def" : "to gush over, i.e. to swell; (figuratively) to desire earnestly; by implication to ask",
		"kjv" : "cause, inquire, seek up, swell out"
	},
	"H1159" : {
		"lemma" : "בָּעוּ",
		"xlit" : "bâʻûw",
		"pron" : "baw-oo'",
		"derivation" : "(Aramaic) from H1156 (בְּעָא)",
		"def" : "a request",
		"kjv" : "petition"
	},
	"H1160" : {
		"lemma" : "בְּעוֹר",
		"xlit" : "Bᵉʻôwr",
		"pron" : "beh-ore'",
		"derivation" : "from H1197 (בָּעַר) (in the sense of burning); a lamp",
		"def" : "Beor, the name of the father of an Edomitish king; also of that of Balaam",
		"kjv" : "Beor"
	},
	"H1161" : {
		"lemma" : "בִּעוּתִים",
		"xlit" : "biʻûwthîym",
		"pron" : "be-oo-theme'",
		"derivation" : "masculine plural from H1204 (בָּעַת)",
		"def" : "alarms",
		"kjv" : "terrors"
	},
	"H1162" : {
		"lemma" : "בֹּעַז",
		"xlit" : "Bôʻaz",
		"pron" : "bo'-az",
		"derivation" : "from an unused root of uncertain meaning",
		"def" : "Boaz, the ancestor of David; also the name of a pillar in front of the temple",
		"kjv" : "Boaz"
	},
	"H1163" : {
		"lemma" : "בָּעַט",
		"xlit" : "bâʻaṭ",
		"pron" : "baw-at'",
		"derivation" : "a primitive root",
		"def" : "to trample down, i.e. (figuratively) despise",
		"kjv" : "kick"
	},
	"H1164" : {
		"lemma" : "בְּעִי",
		"xlit" : "bᵉʻîy",
		"pron" : "beh-ee'",
		"derivation" : "from H1158 (בָּעָה)",
		"def" : "a prayer",
		"kjv" : "grave"
	},
	"H1165" : {
		"lemma" : "בְּעִיר",
		"xlit" : "bᵉʻîyr",
		"pron" : "beh-ere'",
		"derivation" : "from H1197 (בָּעַר) (in the sense of eating)",
		"def" : "cattle",
		"kjv" : "beast, cattle"
	},
	"H1166" : {
		"lemma" : "בָּעַל",
		"xlit" : "bâʻal",
		"pron" : "baw-al'",
		"derivation" : "a primitive root; also as denominative from H1167 (בַּעַל)",
		"def" : "to be master; hence, to marry",
		"kjv" : "have dominion (over), be husband, marry(-ried, [idiom] wife)"
	},
	"H1167" : {
		"lemma" : "בַּעַל",
		"xlit" : "baʻal",
		"pron" : "bah'-al",
		"derivation" : "from H1166 (בָּעַל)",
		"def" : "a master; hence, a husband, or (figuratively) owner (often used with another noun in modifications of this latter sense)",
		"kjv" : "[phrase] archer, [phrase] babbler, [phrase] bird, captain, chief man, [phrase] confederate, [phrase] have to do, [phrase] dreamer, those to whom it is due, [phrase] furious, those that are given to it, great, [phrase] hairy, he that hath it, have, [phrase] horseman, husband, lord, man, [phrase] married, master, person, [phrase] sworn, they of"
	},
	"H1168" : {
		"lemma" : "בַּעַל",
		"xlit" : "Baʻal",
		"pron" : "bah'-al",
		"derivation" : "the same as H1167 (בַּעַל)",
		"def" : "Baal, a Phoenician deity",
		"kjv" : "Baal, (plural) Baalim"
	},
	"H1169" : {
		"lemma" : "בְּעֵל",
		"xlit" : "bᵉʻêl",
		"pron" : "beh-ale'",
		"derivation" : "(Aramaic) corresponding to H1167 (בַּעַל)",
		"def" : "{a master; hence, a husband,}",
		"kjv" : "[phrase] chancellor"
	},
	"H1170" : {
		"lemma" : "בַּעַל בְּרִית",
		"xlit" : "Baʻal Bᵉrîyth",
		"pron" : "bah'-al ber-eeth'",
		"derivation" : "from H1168 (בַּעַל) and H1285 (בְּרִית); Baal of (the) covenant",
		"def" : "Baal-Berith, a special deity of the Shechemites",
		"kjv" : "Baal-berith"
	},
	"H1171" : {
		"lemma" : "בַּעַל גָּד",
		"xlit" : "Baʻal Gâd",
		"pron" : "bah'-al gawd",
		"derivation" : "from H1168 (בַּעַל) and H1409 (גָּד); Baal of Fortune",
		"def" : "Baal-Gad, a place in Syria",
		"kjv" : "Baal-gad"
	},
	"H1172" : {
		"lemma" : "בַּעֲלָה",
		"xlit" : "baʻălâh",
		"pron" : "bah-al-aw'",
		"derivation" : "feminine of H1167 (בַּעַל)",
		"def" : "a mistress",
		"kjv" : "that hath, mistress"
	},
	"H1173" : {
		"lemma" : "בַּעֲלָה",
		"xlit" : "Baʻălâh",
		"pron" : "bah-al-aw'",
		"derivation" : "the same as H1172 (בַּעֲלָה)",
		"def" : "Baalah, the name of three places in Palestine",
		"kjv" : "Baalah"
	},
	"H1174" : {
		"lemma" : "בַּעַל הָמוֹן",
		"xlit" : "Baʻal Hâmôwn",
		"pron" : "bah'-al haw-mone'",
		"derivation" : "from H1167 (בַּעַל) and H1995 (הָמוֹן); possessor of a multitude",
		"def" : "Baal-Hamon, a place in Palestine",
		"kjv" : "Baal-hamon"
	},
	"H1175" : {
		"lemma" : "בְּעָלוֹת",
		"xlit" : "Bᵉʻâlôwth",
		"pron" : "beh-aw-loth'",
		"derivation" : "plural of H1172 (בַּעֲלָה); mistresses",
		"def" : "Bealoth, a place in Palestine",
		"kjv" : "Bealoth, in Aloth (by mistake for a plural from H5927 (עָלָה) with prepositional prefix)"
	},
	"H1176" : {
		"lemma" : "בַּעַל זְבוּב",
		"xlit" : "Baʻal Zᵉbûwb",
		"pron" : "bah'-al zeb-oob'",
		"derivation" : "from H1168 (בַּעַל) and H2070 (זְבוּב); Baal of (the) Fly",
		"def" : "Baal-Zebub, a special deity of the Ekronites",
		"kjv" : "Baal-zebub"
	},
	"H1177" : {
		"lemma" : "בַּעַל חָנָן",
		"xlit" : "Baʻal Chânân",
		"pron" : "bah'-al khaw-nawn'",
		"derivation" : "from H1167 (בַּעַל) and H2603 (חָנַן); possessor of grace",
		"def" : "Baal-Chanan, the name of an Edomite, also of an Israelite",
		"kjv" : "Baal-hanan"
	},
	"H1178" : {
		"lemma" : "בַּעַל חָצוֹר",
		"xlit" : "Baʻal Châtsôwr",
		"pron" : "bah'-al khaw-tsore'",
		"derivation" : "from H1167 (בַּעַל) and a modification of H2691 (חָצֵר); possessor of a village",
		"def" : "Baal-Chatsor, a place in Palestine",
		"kjv" : "Baal-hazor"
	},
	"H1179" : {
		"lemma" : "בַּעַל חֶרְמוֹן",
		"xlit" : "Baʻal Chermôwn",
		"pron" : "bah'-al kher-mone'",
		"derivation" : "from H1167 (בַּעַל) and H2768 (חֶרְמוֹן); possessor of Hermon",
		"def" : "Baal-Chermon, a place in Palestine",
		"kjv" : "Baal-hermon"
	},
	"H1180" : {
		"lemma" : "בַּעֲלִי",
		"xlit" : "Baʻălîy",
		"pron" : "bah-al-ee'",
		"derivation" : "from H1167 (בַּעַל) with pron. suff.; my master",
		"def" : "Baali, a symbolical name for Jehovah",
		"kjv" : "Baali"
	},
	"H1181" : {
		"lemma" : "בַּעֲלֵי בָּמוֹת",
		"xlit" : "Baʻălêy Bâmôwth",
		"pron" : "bah-al-ay' baw-moth'",
		"derivation" : "from the plural of H1168 (בַּעַל) and the plural of H1116 (בָּמָה); Baals of (the) heights",
		"def" : "Baale-Bamoth, a place East of the Jordan",
		"kjv" : "lords of the high places"
	},
	"H1182" : {
		"lemma" : "בְּעֶלְיָדָע",
		"xlit" : "Bᵉʻelyâdâʻ",
		"pron" : "beh-el-yaw-daw'",
		"derivation" : "from H1168 (בַּעַל) and H3045 (יָדַע); Baal has known",
		"def" : "Beeljada, an Israelite",
		"kjv" : "Beeliada"
	},
	"H1183" : {
		"lemma" : "בְּעַלְיָה",
		"xlit" : "Bᵉʻalyâh",
		"pron" : "beh-al-yaw'",
		"derivation" : "from H1167 (בַּעַל) and H3050 (יָהּ); Jah (is) master",
		"def" : "Bealjah, an Israelite",
		"kjv" : "Bealiah"
	},
	"H1184" : {
		"lemma" : "בַּעֲלֵי יְהוּדָה",
		"xlit" : "Baʻălêy Yᵉhûwdâh",
		"pron" : "bah-al-ay' yeh-hoo-daw'",
		"derivation" : "from the plural of H1167 (בַּעַל) and H3063 (יְהוּדָה); masters of Judah",
		"def" : "Baale-Jehudah, a place in Palestine",
		"kjv" : "Baale of Judah"
	},
	"H1185" : {
		"lemma" : "בַּעֲלִיס",
		"xlit" : "Baʻălîyç",
		"pron" : "bah-al-ece'",
		"derivation" : "probably from a derivative of H5965 (עָלַס) with prepositional prefix; in exultation",
		"def" : "Baalis, an Ammonitish king",
		"kjv" : "Baalis"
	},
	"H1186" : {
		"lemma" : "בַּעַל מְעוֹן",
		"xlit" : "Baʻal Mᵉʻôwn",
		"pron" : "bah-al meh-one'",
		"derivation" : "from H1168 (בַּעַל) and H4583 (מָעוֹן); Baal of (the) habitation (of) (compare H1010 (בֵּית בַּעַל מְעוֹן))",
		"def" : "Baal-Meon, a place East of the Jordan",
		"kjv" : "Baal-meon"
	},
	"H1187" : {
		"lemma" : "בַּעַל פְּעוֹר",
		"xlit" : "Baʻal Pᵉʻôwr",
		"pron" : "bah'-al peh-ore'",
		"derivation" : "from H1168 (בַּעַל) and H6465 (פְּעוֹר); Baal of Peor",
		"def" : "Baal-Peor, a Moabitish deity",
		"kjv" : "Baal-peor"
	},
	"H1188" : {
		"lemma" : "בַּעַל פְּרָצִים",
		"xlit" : "Baʻal Pᵉrâtsîym",
		"pron" : "bah'-al per-aw-tseem'",
		"derivation" : "from H1167 (בַּעַל) and the plural of H6556 (פֶּרֶץ); possessor of breaches",
		"def" : "Baal-Peratsim, a place in Palestine",
		"kjv" : "Baal-perazim"
	},
	"H1189" : {
		"lemma" : "בַּעַל צְפוֹן",
		"xlit" : "Baʻal Tsᵉphôwn",
		"pron" : "bah'-al tsef-one'",
		"derivation" : "from H1168 (בַּעַל) and H6828 (צָפוֹן) (in the sense of cold) (according to others an Egyptian form of Typhon, the destroyer); Baal of winter",
		"def" : "Baal-Tsephon, a place in Eqypt",
		"kjv" : "Baal-zephon"
	},
	"H1190" : {
		"lemma" : "בַּעַל שָׁלִשָׁה",
		"xlit" : "Baʻal Shâlishâh",
		"pron" : "bah'-al shaw-lee-shaw'",
		"derivation" : "from H1168 (בַּעַל) and H8031 (שָׁלִשָׁה)",
		"def" : "Baal of Shalishah, Baal-Shalishah, a place in Palestine",
		"kjv" : "Baal-shalisha"
	},
	"H1191" : {
		"lemma" : "בַּעֲלָת",
		"xlit" : "Baʻălâth",
		"pron" : "bah-al-awth'",
		"derivation" : "a modification of H1172 (בַּעֲלָה); mistressship",
		"def" : "Baalath, a place in Palestine",
		"kjv" : "Baalath"
	},
	"H1192" : {
		"lemma" : "בַּעֲלַת בְּאֵר",
		"xlit" : "Baʻălath Bᵉʼêr",
		"pron" : "bah-al-ath' beh-ayr'",
		"derivation" : "from H1172 (בַּעֲלָה) and H875 (בְּאֵר); mistress of a well",
		"def" : "Baalath-Beer, a place in Palestine",
		"kjv" : "Baalath-beer"
	},
	"H1193" : {
		"lemma" : "בַּעַל תָּמָר",
		"xlit" : "Baʻal Tâmâr",
		"pron" : "bah'-al taw-mawr'",
		"derivation" : "from H1167 (בַּעַל) and H8558 (תָּמָר); possessor of (the) palm-tree",
		"def" : "Baal-Tamar, a place in Palestine",
		"kjv" : "Baal-tamar"
	},
	"H1194" : {
		"lemma" : "בְּעֹן",
		"xlit" : "Bᵉʻôn",
		"pron" : "beh-ohn'",
		"derivation" : "probably a contraction of H1010 (בֵּית בַּעַל מְעוֹן)",
		"def" : "Beon, a place East of the Jordan",
		"kjv" : "Beon"
	},
	"H1195" : {
		"lemma" : "בַּעֲנָא",
		"xlit" : "Baʻănâʼ",
		"pron" : "bah-an-aw'",
		"derivation" : "the same as H1196 (בַּעֲנָה)",
		"def" : "Banana, the name of four Israelite",
		"kjv" : "Baana, Baanah"
	},
	"H1196" : {
		"lemma" : "בַּעֲנָה",
		"xlit" : "Baʻănâh",
		"pron" : "bah-an-aw'",
		"derivation" : "from a derivative of H6031 (עָנָה) with prepositional prefix; in affliction",
		"def" : "Baanah, the name of four Israelites",
		"kjv" : "Baanah"
	},
	"H1197" : {
		"lemma" : "בָּעַר",
		"xlit" : "bâʻar",
		"pron" : "baw-ar'",
		"derivation" : "a primitive root; also as denominative from H1198 (בַּעַר)",
		"def" : "to kindle, i.e. consume (by fire or by eating);  to be(-come) brutish",
		"kjv" : "be brutish, bring (put, take) away, burn, (cause to) eat (up), feed, heat, kindle, set (on fire), waste"
	},
	"H1198" : {
		"lemma" : "בַּעַר",
		"xlit" : "baʻar",
		"pron" : "bah'-ar",
		"derivation" : "from H1197 (בָּעַר)",
		"def" : "properly, foot (as consumed); i.e. (by exten.) of cattle brutishness; (concretely) stupid",
		"kjv" : "brutish (person), foolish"
	},
	"H1199" : {
		"lemma" : "בָּעֲרָא",
		"xlit" : "Bâʻărâʼ",
		"pron" : "bah-ar-aw'",
		"derivation" : "from H1198 (בַּעַר); brutish",
		"def" : "Baara, an Israelitish woman",
		"kjv" : "Baara"
	},
	"H1200" : {
		"lemma" : "בְּעֵרָה",
		"xlit" : "bᵉʻêrâh",
		"pron" : "be-ay-raw'",
		"derivation" : "from H1197 (בָּעַר)",
		"def" : "a burning",
		"kjv" : "fire"
	},
	"H1201" : {
		"lemma" : "בַּעְשָׁא",
		"xlit" : "Baʻshâʼ",
		"pron" : "bah-shaw'",
		"derivation" : "from an unused root meaning to stink; offensiveness",
		"def" : "Basha, a king of Israel",
		"kjv" : "Baasha"
	},
	"H1202" : {
		"lemma" : "בַּעֲשֵׂיָה",
		"xlit" : "Baʻăsêyâh",
		"pron" : "bah-as-ay-yaw'",
		"derivation" : "from H6213 (עָשָׂה) and H3050 (יָהּ) with a prepositional prefix; in (the) work of Jah",
		"def" : "Baasejah, an Israelite",
		"kjv" : "Baaseiah"
	},
	"H1203" : {
		"lemma" : "בְּעֶשְׁתְּרָה",
		"xlit" : "Bᵉʻeshtᵉrâh",
		"pron" : "beh-esh-ter-aw'",
		"derivation" : "from H6251 (עַשְׁתְּרָה) (as singular of H6252 (עַשְׁתָּרוֹת)) with a prepositional prefix; with Ashtoreth",
		"def" : "Beeshterah, a place East of the Jordan",
		"kjv" : "Beeshterah"
	},
	"H1204" : {
		"lemma" : "בָּעַת",
		"xlit" : "bâʻath",
		"pron" : "baw-ath'",
		"derivation" : "a primitive root",
		"def" : "to fear",
		"kjv" : "affright, be (make) afraid, terrify, trouble"
	},
	"H1205" : {
		"lemma" : "בְּעָתָה",
		"xlit" : "bᵉʻâthâh",
		"pron" : "beh-aw-thaw'",
		"derivation" : "from H1204 (בָּעַת)",
		"def" : "fear",
		"kjv" : "trouble"
	},
	"H1206" : {
		"lemma" : "בֹץ",
		"xlit" : "bôts",
		"pron" : "botse",
		"derivation" : "probably the same as H948 (בּוּץ)",
		"def" : "mud (as whitish clay)",
		"kjv" : "mire"
	},
	"H1207" : {
		"lemma" : "בִּצָּה",
		"xlit" : "bitstsâh",
		"pron" : "bits-tsaw'",
		"derivation" : "intensive from H1206 (בֹץ)",
		"def" : "a swamp",
		"kjv" : "fen, mire(-ry place)"
	},
	"H1208" : {
		"lemma" : "בָּצוֹר",
		"xlit" : "bâtsôwr",
		"pron" : "baw-tsore'",
		"derivation" : "from H1219 (בָּצַר)",
		"def" : "inaccessible, i.e. lofty",
		"kjv" : "vintage (by confusion with H1210 (בָּצִיר))"
	},
	"H1209" : {
		"lemma" : "בֵּצַי",
		"xlit" : "Bêtsay",
		"pron" : "bay-tsah'-ee",
		"derivation" : "perhaps the same as H1153 (בְּסַי)",
		"def" : "Betsai, the name of two Israelites",
		"kjv" : "Bezai"
	},
	"H1210" : {
		"lemma" : "בָּצִיר",
		"xlit" : "bâtsîyr",
		"pron" : "baw-tseer'",
		"derivation" : "from H1219 (בָּצַר)",
		"def" : "clipped, i.e. the grape crop",
		"kjv" : "vintage"
	},
	"H1211" : {
		"lemma" : "בֶּצֶל",
		"xlit" : "betsel",
		"pron" : "beh'-tsel",
		"derivation" : "from an unused root apparently meaning to peel",
		"def" : "an onion",
		"kjv" : "onion"
	},
	"H1212" : {
		"lemma" : "בְּצַלְאֵל",
		"xlit" : "Bᵉtsalʼêl",
		"pron" : "bets-al-ale'",
		"derivation" : "probably from H6738 (צֵל) and H410 (אֵל) with a prepositional prefix; in (the) shadow (i.e. protection) of God",
		"def" : "Betsalel, the name of two Israelites",
		"kjv" : "Bezaleel"
	},
	"H1213" : {
		"lemma" : "בַּצְלוּת",
		"xlit" : "Batslûwth",
		"pron" : "bats-looth'",
		"derivation" : "or בַּצְלִית; from the same as H1211 (בֶּצֶל)xlit bᵉtsel corrected to betsel; a peeling",
		"def" : "Batsluth or Batslith, an Israelite",
		"kjv" : "Bazlith, Bazluth"
	},
	"H1214" : {
		"lemma" : "בָּצַע",
		"xlit" : "bâtsaʻ",
		"pron" : "baw-tsah'",
		"derivation" : "a primitive root to break off, i.e. (usually) plunder",
		"def" : "figuratively, to finish, or (intransitively) stop",
		"kjv" : "(be) covet(-ous), cut (off), finish, fulfill, gain (greedily), get, be given to (covetousness), greedy, perform, be wounded"
	},
	"H1215" : {
		"lemma" : "בֶּצַע",
		"xlit" : "betsaʻ",
		"pron" : "beh'-tsah",
		"derivation" : "from H1214 (בָּצַע)",
		"def" : "plunder; by extension, gain (usually unjust)",
		"kjv" : "covetousness, (dishonest) gain, lucre, profit"
	},
	"H1216" : {
		"lemma" : "בָּצֵק",
		"xlit" : "bâtsêq",
		"pron" : "baw-tsake'",
		"derivation" : "a primitive root",
		"def" : "perhaps to swell up, i.e. blister",
		"kjv" : "swell"
	},
	"H1217" : {
		"lemma" : "בָּצֵק",
		"xlit" : "bâtsêq",
		"pron" : "baw-tsake'",
		"derivation" : "from H1216 (בָּצֵק)",
		"def" : "dough (as swelling by fermentation)",
		"kjv" : "dough, flour"
	},
	"H1218" : {
		"lemma" : "בׇּצְקַת",
		"xlit" : "Botsqath",
		"pron" : "bots-cath'",
		"derivation" : "from H1216 (בָּצֵק); a swell of ground",
		"def" : "Botscath, a place in Palestine",
		"kjv" : "Bozcath, Boskath"
	},
	"H1219" : {
		"lemma" : "בָּצַר",
		"xlit" : "bâtsar",
		"pron" : "baw-tsar'",
		"derivation" : "a primitive root; to clip off; specifically (as denominative from H1210 (בָּצִיר))",
		"def" : "to gather grapes; also to be isolated (i.e. inaccessible by height or fortification)",
		"kjv" : "cut off, (de-) fenced, fortify, (grape) gather(-er), mighty things, restrain, strong, wall (up), withhold"
	},
	"H1220" : {
		"lemma" : "בֶּצֶר",
		"xlit" : "betser",
		"pron" : "beh'-tser",
		"derivation" : "from H1219 (בָּצַר)",
		"def" : "strictly a clipping, i.e. gold (as dug out)",
		"kjv" : "gold defence"
	},
	"H1221" : {
		"lemma" : "בֶּצֶר",
		"xlit" : "Betser",
		"pron" : "beh'-tser",
		"derivation" : "the same as H1220 (בֶּצֶר), an inaccessible spot",
		"def" : "Betser, a place in Palestine; also an Israelite",
		"kjv" : "Bezer"
	},
	"H1222" : {
		"lemma" : "בְּצַר",
		"xlit" : "bᵉtsar",
		"pron" : "bets-ar'",
		"derivation" : "another form for H1220 (בֶּצֶר)",
		"def" : "gold",
		"kjv" : "gold"
	},
	"H1223" : {
		"lemma" : "בׇּצְרָה",
		"xlit" : "botsrâh",
		"pron" : "bots-raw'",
		"derivation" : "feminine from H1219 (בָּצַר)",
		"def" : "an enclosure, i.e. sheep fold",
		"kjv" : "Bozrah"
	},
	"H1224" : {
		"lemma" : "בׇּצְרָה",
		"xlit" : "Botsrâh",
		"pron" : "bots-raw'",
		"derivation" : "the same as H1223 (בׇּצְרָה)",
		"def" : "Botsrah, a place in Edom",
		"kjv" : "Bozrah"
	},
	"H1225" : {
		"lemma" : "בִּצָּרוֹן",
		"xlit" : "bitstsârôwn",
		"pron" : "bits-tsaw-rone'",
		"derivation" : "masculine intensive from H1219 (בָּצַר)",
		"def" : "a fortress",
		"kjv" : "stronghold"
	},
	"H1226" : {
		"lemma" : "בַּצֹּרֶת",
		"xlit" : "batstsôreth",
		"pron" : "bats-tso'-reth",
		"derivation" : "feminine intensive from H1219 (בָּצַר)",
		"def" : "restraint (of rain), i.e. drought",
		"kjv" : "dearth, drought"
	},
	"H1227" : {
		"lemma" : "בַּקְבּוּק",
		"xlit" : "Baqbûwq",
		"pron" : "bak-book'",
		"derivation" : "the same as H1228 (בַּקְבֻּק)xlit baqbûk corrected to baqbuq",
		"def" : "Bakbuk, one of the Nethinim",
		"kjv" : "Bakbuk"
	},
	"H1228" : {
		"lemma" : "בַּקְבֻּק",
		"xlit" : "baqbuq",
		"pron" : "bak-book'",
		"derivation" : "from H1238 (בָּקַק)",
		"def" : "a bottle (from the gurgling in emptying)",
		"kjv" : "bottle, cruse"
	},
	"H1229" : {
		"lemma" : "בַּקְבֻּקְיָה",
		"xlit" : "Baqbuqyâh",
		"pron" : "bak-book-yaw'",
		"derivation" : "from H1228 (בַּקְבֻּק)xlit baqbûk corrected to baqbuq and H3050 (יָהּ); emptying (i.e. wasting) of Jah",
		"def" : "Bakbukjah, an Israelite",
		"kjv" : "Bakbukiah"
	},
	"H1230" : {
		"lemma" : "בַּקְבַּקַּר",
		"xlit" : "Baqbaqqar",
		"pron" : "bak-bak-kar'",
		"derivation" : "reduplicated from H1239 (בָּקַר); searcher",
		"def" : "Bakbakkar, an Israelite",
		"kjv" : "Bakbakkar"
	},
	"H1231" : {
		"lemma" : "בֻּקִּי",
		"xlit" : "Buqqîy",
		"pron" : "book-kee'",
		"derivation" : "from H1238 (בָּקַק); wasteful",
		"def" : "Bukki, the name of two Israelites",
		"kjv" : "Bukki"
	},
	"H1232" : {
		"lemma" : "בֻּקִּיָּה",
		"xlit" : "Buqqîyâh",
		"pron" : "book-kee-yaw'",
		"derivation" : "from H1238 (בָּקַק) and H3050 (יָהּ); wasting of Jah",
		"def" : "Bukkijah, an Israelite",
		"kjv" : "Bukkiah"
	},
	"H1233" : {
		"lemma" : "בְּקִיעַ",
		"xlit" : "bᵉqîyaʻ",
		"pron" : "bek-ee'-ah",
		"derivation" : "from H1234 (בָּקַע)",
		"def" : "a fissure",
		"kjv" : "breach, cleft"
	},
	"H1234" : {
		"lemma" : "בָּקַע",
		"xlit" : "bâqaʻ",
		"pron" : "baw-kah'",
		"derivation" : "a primitive root",
		"def" : "to cleave; generally, to rend, break, rip or open",
		"kjv" : "make a breach, break forth (into, out, in pieces, through, up), be ready to burst, cleave (asunder), cut out, divide, hatch, rend (asunder), rip up, tear, win"
	},
	"H1235" : {
		"lemma" : "בֶּקַע",
		"xlit" : "beqaʻ",
		"pron" : "beh'-kah",
		"derivation" : "from H1234 (בָּקַע)",
		"def" : "a section (half) of ashekel, i.e. a beka (a weight and a coin)",
		"kjv" : "bekah, half a shekel"
	},
	"H1236" : {
		"lemma" : "בִּקְעָא",
		"xlit" : "biqʻâʼ",
		"pron" : "bik-aw'",
		"derivation" : "(Aramaic) corresponding to H1237 (בִּקְעָה)",
		"def" : "{properly, a split, i.e. a wide level valley between mountains}",
		"kjv" : "plain"
	},
	"H1237" : {
		"lemma" : "בִּקְעָה",
		"xlit" : "biqʻâh",
		"pron" : "bik-aw'",
		"derivation" : "from H1234 (בָּקַע)",
		"def" : "properly, a split, i.e. a wide level valley between mountains",
		"kjv" : "plain, valley"
	},
	"H1238" : {
		"lemma" : "בָּקַק",
		"xlit" : "bâqaq",
		"pron" : "baw-kah'",
		"derivation" : "a primitive root",
		"def" : "to pour out, i.e. to empty, figuratively, to depopulate; by analogy, to spread out (as a fruitful vine)",
		"kjv" : "(make) empty (out), fail, [idiom] utterly, make void"
	},
	"H1239" : {
		"lemma" : "בָּקַר",
		"xlit" : "bâqar",
		"pron" : "baw-kar",
		"derivation" : "a primitive root",
		"def" : "properly, to plough, or (generally) break forth, i.e. (figuratively) to inspect, admire, care for, consider",
		"kjv" : "(make) inquire (-ry), (make) search, seek out"
	},
	"H1240" : {
		"lemma" : "בְּקַר",
		"xlit" : "bᵉqar",
		"pron" : "bek-ar'",
		"derivation" : "(Aramaic) corresponding to H1239 (בָּקַר)",
		"def" : "{properly, to plough, or (generally) break forth, i.e. (figuratively) to inspect, admire, care for, consider}",
		"kjv" : "inquire, make search"
	},
	"H1241" : {
		"lemma" : "בָּקָר",
		"xlit" : "bâqâr",
		"pron" : "baw-kawr'",
		"derivation" : "from H1239 (בָּקַר)",
		"def" : "beef cattle or an animal of the ox family of either gender (as used for plowing); collectively, a herd",
		"kjv" : "beeve, bull ([phrase] -ock), [phrase] calf, [phrase] cow, great (cattle), [phrase] heifer, herd, kine, ox"
	},
	"H1242" : {
		"lemma" : "בֹּקֶר",
		"xlit" : "bôqer",
		"pron" : "bo'-ker",
		"derivation" : "from H1239 (בָּקַר)",
		"def" : "properly, dawn (as the break of day); generally, morning",
		"kjv" : "([phrase]) day, early, morning, morrow"
	},
	"H1243" : {
		"lemma" : "בַּקָּרָה",
		"xlit" : "baqqârâh",
		"pron" : "bak-kaw-raw'",
		"derivation" : "intensive from H1239 (בָּקַר)",
		"def" : "a looking after",
		"kjv" : "seek out"
	},
	"H1244" : {
		"lemma" : "בִּקֹּרֶת",
		"xlit" : "biqqôreth",
		"pron" : "bik-ko-reth",
		"derivation" : "from H1239 (בָּקַר)",
		"def" : "properly, examination, i.e. (by implication) punishment",
		"kjv" : "scourged"
	},
	"H1245" : {
		"lemma" : "בָּקַשׁ",
		"xlit" : "bâqash",
		"pron" : "baw-kash'",
		"derivation" : "a primitive root",
		"def" : "to search out (by any method, specifically in worship or prayer); by implication, to strive after",
		"kjv" : "ask, beg, beseech, desire, enquire, get, make inquisition, procure, (make) request, require, seek (for)"
	},
	"H1246" : {
		"lemma" : "בַּקָּשָׁה",
		"xlit" : "baqqâshâh",
		"pron" : "bak-kaw-shaw'",
		"derivation" : "from H1245 (בָּקַשׁ)",
		"def" : "a petition",
		"kjv" : "request"
	},
	"H1247" : {
		"lemma" : "בַּר",
		"xlit" : "bar",
		"pron" : "bar",
		"derivation" : "(Aramaic) corresponding to H1121 (בֵּן)",
		"def" : "a son, grandson, etc.",
		"kjv" : "[idiom] old, son"
	},
	"H1248" : {
		"lemma" : "בַּר",
		"xlit" : "bar",
		"pron" : "bar",
		"derivation" : "borrowed (as a title) from H1247 (בַּר)",
		"def" : "the heir (apparent to the throne)",
		"kjv" : "son"
	},
	"H1249" : {
		"lemma" : "בַּר",
		"xlit" : "bar",
		"pron" : "bar",
		"derivation" : "from H1305 (בָּרַר) (in its various senses)",
		"def" : "beloved; also pure, empty",
		"kjv" : "choice, clean, clear, pure"
	},
	"H1250" : {
		"lemma" : "בָּר",
		"xlit" : "bâr",
		"pron" : "bawr",
		"derivation" : "or בַּר; from H1305 (בָּרַר) (in the sense of winnowing)",
		"def" : "grain of any kind (even while standing in the field); by extension the open country",
		"kjv" : "corn, wheat"
	},
	"H1251" : {
		"lemma" : "בַּר",
		"xlit" : "bar",
		"pron" : "bar",
		"derivation" : "(Aramaic) corresponding to H1250 (בָּר)",
		"def" : "a field",
		"kjv" : "field"
	},
	"H1252" : {
		"lemma" : "בֹּר",
		"xlit" : "bôr",
		"pron" : "bore",
		"derivation" : "from H1305 (בָּרַר)",
		"def" : "purify",
		"kjv" : "cleanness, pureness"
	},
	"H1253" : {
		"lemma" : "בֹּר",
		"xlit" : "bôr",
		"pron" : "bore",
		"derivation" : "the same as H1252 (בֹּר)",
		"def" : "vegetable lye (from its cleansing); used as a soap forwashing, or a flux formetals",
		"kjv" : "[idiom] never so, purely"
	},
	"H1254" : {
		"lemma" : "בָּרָא",
		"xlit" : "bârâʼ",
		"pron" : "baw-raw'",
		"derivation" : "a primitive root",
		"def" : "(absolutely) to create; (qualified) to cut down (a wood), select, feed (as formative processes)",
		"kjv" : "choose, create (creator), cut down, dispatch, do, make (fat)"
	},
	"H1255" : {
		"lemma" : "בְּרֹאדַךְ בַּלְאֲדָן",
		"xlit" : "Bᵉrôʼdak Balʼădân",
		"pron" : "ber-o-dak' bal-ad-awn'",
		"derivation" : "a variation of H4757 (מְרֹאדַךְ בַּלְאָדָן)",
		"def" : "Berodak-Baladan, a Babylonian king",
		"kjv" : "Berodach-baladan.Birliy"
	},
	"H1256" : {
		"lemma" : "בְּרָאיָה",
		"xlit" : "Bᵉrâʼyâh",
		"pron" : "ber-aw-yaw'",
		"derivation" : "from H1254 (בָּרָא) and H8050 (שְׁמוּאֵל); Jah has created",
		"def" : "Berajah, an Israelite",
		"kjv" : "Beraiah"
	},
	"H1257" : {
		"lemma" : "בַּרְבֻּר",
		"xlit" : "barbur",
		"pron" : "bar-boor'",
		"derivation" : "by reduplication from H1250 (בָּר)",
		"def" : "a fowl (as fattened on grain)",
		"kjv" : "fowl"
	},
	"H1258" : {
		"lemma" : "בָּרַד",
		"xlit" : "bârad",
		"pron" : "baw-rad'",
		"derivation" : "a primitive root",
		"def" : "to hail",
		"kjv" : "hail"
	},
	"H1259" : {
		"lemma" : "בָּרָד",
		"xlit" : "bârâd",
		"pron" : "baw-rawd'",
		"derivation" : "from H1258 (בָּרַד)",
		"def" : "hail",
		"kjv" : "hail(stones)"
	},
	"H1260" : {
		"lemma" : "בֶּרֶד",
		"xlit" : "Bered",
		"pron" : "beh'red",
		"derivation" : "from H1258 (בָּרַד); hail",
		"def" : "Bered, the name of a place south of Palestine, also of an Israelite",
		"kjv" : "Bered"
	},
	"H1261" : {
		"lemma" : "בָּרֹד",
		"xlit" : "bârôd",
		"pron" : "baw-rode'",
		"derivation" : "from H1258 (בָּרַד)",
		"def" : "spotted (as if with hail)",
		"kjv" : "grisled"
	},
	"H1262" : {
		"lemma" : "בָּרָה",
		"xlit" : "bârâh",
		"pron" : "baw-raw'",
		"derivation" : "a primitive root; to select; also (as denominative from H1250 (בָּר)) to feed; also (as equivalent to H1305 (בָּרַר)) to render clear (Ecclesiastes 3:18)",
		"def" : "to select; to feed; to render clear",
		"kjv" : "choose, (cause to) eat, manifest, (give) meat"
	},
	"H1263" : {
		"lemma" : "בָּרוּךְ",
		"xlit" : "Bârûwk",
		"pron" : "baw-rook'",
		"derivation" : "passive participle from H1288 (בָרַךְ); blessed",
		"def" : "Baruk, the name of three Israelites",
		"kjv" : "Baruch"
	},
	"H1264" : {
		"lemma" : "בְּרוֹם",
		"xlit" : "bᵉrôwm",
		"pron" : "ber-ome'",
		"derivation" : "probably of foreign origin",
		"def" : "damask (stuff of variegated thread)",
		"kjv" : "rich apparel"
	},
	"H1265" : {
		"lemma" : "בְּרוֹשׁ",
		"xlit" : "bᵉrôwsh",
		"pron" : "ber-osh'",
		"derivation" : "of uncertain derivation",
		"def" : "a cypress (?) tree; hence, a lance or a musical instrument (as made of that wood)",
		"kjv" : "fir (tree)"
	},
	"H1266" : {
		"lemma" : "בְּרוֹת",
		"xlit" : "bᵉrôwth",
		"pron" : "ber-oth'",
		"derivation" : "a variation of H1265 (בְּרוֹשׁ)",
		"def" : "the cypress (or some elastic tree)",
		"kjv" : "fir"
	},
	"H1267" : {
		"lemma" : "בָּרוּת",
		"xlit" : "bârûwth",
		"pron" : "baw-rooth",
		"derivation" : "from H1262 (בָּרָה)",
		"def" : "food",
		"kjv" : "meat"
	},
	"H1268" : {
		"lemma" : "בֵּרוֹתָה",
		"xlit" : "Bêrôwthâh",
		"pron" : "bay-ro-thaw'",
		"derivation" : "or בֵּרֹתַי; probably from H1266 (בְּרוֹת)lemma בֻּרוֹת first vowel, corrected to בְּרוֹת; cypress or cypresslike",
		"def" : "Berothah or Berothai, a place north of Palestine",
		"kjv" : "Berothah, Berothai"
	},
	"H1269" : {
		"lemma" : "בִּרְזוֹת",
		"xlit" : "Birzôwth",
		"pron" : "beer-zoth'",
		"derivation" : "probably feminine plural from an unused root (apparently mean to pierce); holes",
		"def" : "Birzoth, an Israelite",
		"kjv" : "Birzavith (from the marg.)"
	},
	"H1270" : {
		"lemma" : "בַּרְזֶל",
		"xlit" : "barzel",
		"pron" : "bar-zel'",
		"derivation" : "perhaps from the root of H1269 (בִּרְזוֹת)",
		"def" : "iron (as cutting); by extension, an iron implement",
		"kjv" : "(ax) head, iron"
	},
	"H1271" : {
		"lemma" : "בַּרְזִלַּי",
		"xlit" : "Barzillay",
		"pron" : "bar-zil-lah'-ee",
		"derivation" : "from H1270 (בַּרְזֶל); iron-hearted",
		"def" : "Barzillai, the name of three Israelites",
		"kjv" : "Barzillai"
	},
	"H1272" : {
		"lemma" : "בָּרַח",
		"xlit" : "bârach",
		"pron" : "baw-rakh'",
		"derivation" : "a primitive root",
		"def" : "to bolt, i.e. figuratively, to flee suddenly",
		"kjv" : "chase (away); drive away, fain, flee (away), put to flight, make haste, reach, run away, shoot"
	},
	"H1273" : {
		"lemma" : "בַּרְחֻמִי",
		"xlit" : "Barchumîy",
		"pron" : "bar-khoo-mee'",
		"derivation" : "by transposition for H978 (בַּחֲרוּמִי)",
		"def" : "a Barchumite, or native of Bachurim",
		"kjv" : "Barhumite"
	},
	"H1274" : {
		"lemma" : "בְּרִי",
		"xlit" : "bᵉrîy",
		"pron" : "ber-ee'",
		"derivation" : "from H1262 (בָּרָה)",
		"def" : "fat",
		"kjv" : "fat"
	},
	"H1275" : {
		"lemma" : "בֵּרִי",
		"xlit" : "Bêrîy",
		"pron" : "bay-ree'",
		"derivation" : "probably by contraction from H882 (בְּאֵרִי)",
		"def" : "Beri, an Israelite",
		"kjv" : "Beri"
	},
	"H1276" : {
		"lemma" : "בֵּרִי",
		"xlit" : "Bêrîy",
		"pron" : "bay-ree'",
		"derivation" : "of uncertain derivation",
		"def" : "(only in the plural and with the article) the Berites, a place in Palestine",
		"kjv" : "Berites"
	},
	"H1277" : {
		"lemma" : "בָּרִיא",
		"xlit" : "bârîyʼ",
		"pron" : "baw-ree'",
		"derivation" : "from H1254 (בָּרָא) (in the sense of H1262 (בָּרָה))",
		"def" : "fatted or plump",
		"kjv" : "fat ((fleshed), -ter), fed, firm, plenteous, rank"
	},
	"H1278" : {
		"lemma" : "בְּרִיאָה",
		"xlit" : "bᵉrîyʼâh",
		"pron" : "ber-ee-aw'",
		"derivation" : "feminine from H1254 (בָּרָא)",
		"def" : "a creation, i.e. a novelty",
		"kjv" : "new thing"
	},
	"H1279" : {
		"lemma" : "בִּרְיָה",
		"xlit" : "biryâh",
		"pron" : "beer-yaw'",
		"derivation" : "feminine from H1262 (בָּרָה)",
		"def" : "food",
		"kjv" : "meat"
	},
	"H1280" : {
		"lemma" : "בְּרִיחַ",
		"xlit" : "bᵉrîyach",
		"pron" : "ber-ee'-akh",
		"derivation" : "from H1272 (בָּרַח)",
		"def" : "a bolt",
		"kjv" : "bar, fugitive"
	},
	"H1281" : {
		"lemma" : "בָּרִיחַ",
		"xlit" : "bârîyach",
		"pron" : "baw-ree'-akh",
		"derivation" : "or בָּרִחַ; (shortened) from H1272 (בָּרַח)",
		"def" : "a fugitive, i.e. the serpent (as fleeing), and the constellation by that name",
		"kjv" : "crooked, noble, piercing"
	},
	"H1282" : {
		"lemma" : "בָּרִיחַ",
		"xlit" : "Bârîyach",
		"pron" : "baw-ree'-akh",
		"derivation" : "the same as H1281 (בָּרִיחַ)",
		"def" : "Bariach, an Israelite",
		"kjv" : "Bariah"
	},
	"H1283" : {
		"lemma" : "בְּרִיעָה",
		"xlit" : "Bᵉrîyʻâh",
		"pron" : "ber-ee'-aw",
		"derivation" : "apparently from the feminine of H7451 (רַע) with a prepositional prefix; in trouble",
		"def" : "Beriah, the name of four Israelites",
		"kjv" : "Beriah"
	},
	"H1284" : {
		"lemma" : "בְּרִיעִי",
		"xlit" : "Bᵉrîyʻîy",
		"pron" : "ber-ee-ee'",
		"derivation" : "patronymically from H1283 (בְּרִיעָה)",
		"def" : "a Beriite (collectively) or descendants of Beriah",
		"kjv" : "Beerites"
	},
	"H1285" : {
		"lemma" : "בְּרִית",
		"xlit" : "bᵉrîyth",
		"pron" : "ber-eeth'",
		"derivation" : "from H1262 (בָּרָה) (in the sense of cutting (like H1254 (בָּרָא)))",
		"def" : "a compact (because made by passing between pieces of flesh)",
		"kjv" : "confederacy, (con-) feder(-ate), covenant, league"
	},
	"H1286" : {
		"lemma" : "בְּרִית",
		"xlit" : "Bᵉrîyth",
		"pron" : "ber-eeth'",
		"derivation" : "the same as H1285 (בְּרִית)",
		"def" : "Berith, a Shechemitish deity",
		"kjv" : "Berith"
	},
	"H1287" : {
		"lemma" : "בֹּרִית",
		"xlit" : "bôrîyth",
		"pron" : "bo-reeth'",
		"derivation" : "feminine of H1253 (בֹּר)",
		"def" : "vegetable alkali",
		"kjv" : "sope"
	},
	"H1288" : {
		"lemma" : "בָרַךְ",
		"xlit" : "bârak",
		"pron" : "baw-rak'",
		"derivation" : "a primitive root",
		"def" : "to kneel; by implication to bless God (as an act of adoration), and (vice-versa) man (as a benefit); also (by euphemism) to curse (God or the king, as treason)",
		"kjv" : "[idiom] abundantly, [idiom] altogether, [idiom] at all, blaspheme, bless, congratulate, curse, [idiom] greatly, [idiom] indeed, kneel (down), praise, salute, [idiom] still, thank"
	},
	"H1289" : {
		"lemma" : "בְּרַךְ",
		"xlit" : "bᵉrak",
		"pron" : "ber-ak'",
		"derivation" : "(Aramaic) corresponding to H1288 (בָרַךְ)",
		"def" : "{to kneel; by implication to bless God (as an act of adoration), and (vice-versa) man (as a benefit); also (by euphemism) to curse (God or the king, as treason)}",
		"kjv" : "bless, kneel"
	},
	"H1290" : {
		"lemma" : "בֶּרֶךְ",
		"xlit" : "berek",
		"pron" : "beh'-rek",
		"derivation" : "from H1288 (בָרַךְ)",
		"def" : "a knee",
		"kjv" : "knee"
	},
	"H1291" : {
		"lemma" : "בֶּרֶךְ",
		"xlit" : "berek",
		"pron" : "beh'-rek",
		"derivation" : "(Aramaic) corresponding to H1290 (בֶּרֶךְ)",
		"def" : "{a knee}",
		"kjv" : "knee"
	},
	"H1292" : {
		"lemma" : "בָּרַכְאֵל",
		"xlit" : "Bârakʼêl",
		"pron" : "baw-rak-ale'",
		"derivation" : "from H1288 (בָרַךְ) and H410 (אֵל), God has blessed",
		"def" : "Barakel, the father of one of Job's friends",
		"kjv" : "Barachel"
	},
	"H1293" : {
		"lemma" : "בְּרָכָה",
		"xlit" : "Bᵉrâkâh",
		"pron" : "ber-aw-kaw'",
		"derivation" : "from H1288 (בָרַךְ)",
		"def" : "benediction; by implication prosperity",
		"kjv" : "blessing, liberal, pool, present"
	},
	"H1294" : {
		"lemma" : "בְּרָכָה",
		"xlit" : "Bᵉrâkâh",
		"pron" : "ber-aw-kaw'",
		"derivation" : "the same as H1293 (בְּרָכָה)",
		"def" : "Berakah, the name of an Israelite, and also of a valley in Palestine",
		"kjv" : "Berachah"
	},
	"H1295" : {
		"lemma" : "בְּרֵכָה",
		"xlit" : "bᵉrêkâh",
		"pron" : "ber-ay-kaw'",
		"derivation" : "from H1288 (בָרַךְ)",
		"def" : "a reservoir (at which camels kneel as a resting-place)",
		"kjv" : "(fish-) pool"
	},
	"H1296" : {
		"lemma" : "בֶּרֶכְיָה",
		"xlit" : "Berekyâh",
		"pron" : "beh-rek-yaw'",
		"derivation" : "or בֶּרֶכְיָהוּ; from H1290 (בֶּרֶךְ) and H3050 (יָהּ); knee (i.e. blessing) of Jah",
		"def" : "Berekjah, the name of six Israelites",
		"kjv" : "Berachiah, Berechiah"
	},
	"H1297" : {
		"lemma" : "בְּרַם",
		"xlit" : "bᵉram",
		"pron" : "ber-am'",
		"derivation" : "(Aramaic) perhaps from H7313 (רוּם) with a prepositional prefix",
		"def" : "properly, highly, i.e. surely; but used adversatively, however",
		"kjv" : "but, nevertheless, yet"
	},
	"H1298" : {
		"lemma" : "בֶּרַע",
		"xlit" : "Beraʻ",
		"pron" : "beh'-rah",
		"derivation" : "of uncertain derivation",
		"def" : "Bera, a Sodomitish king",
		"kjv" : "Bera"
	},
	"H1299" : {
		"lemma" : "בָּרַק",
		"xlit" : "bâraq",
		"pron" : "baw-rak'",
		"derivation" : "a primitive root",
		"def" : "to lighten (lightning)",
		"kjv" : "cast forth"
	},
	"H1300" : {
		"lemma" : "בָּרָק",
		"xlit" : "bârâq",
		"pron" : "baw-rawk'",
		"derivation" : "from H1299 (בָּרַק)",
		"def" : "lightning; by analogy, a gleam; concretely, a flashing sword",
		"kjv" : "bright, glitter(-ing sword), lightning"
	},
	"H1301" : {
		"lemma" : "בָּרָק",
		"xlit" : "Bârâq",
		"pron" : "baw-rawk'",
		"derivation" : "the same as H1300 (בָּרָק)",
		"def" : "Barak, an Israelite",
		"kjv" : "Barak"
	},
	"H1302" : {
		"lemma" : "בַּרְקוֹס",
		"xlit" : "Barqôwç",
		"pron" : "bar-kose'",
		"derivation" : "of uncertain derivation",
		"def" : "Barkos, one of the Nethimim",
		"kjv" : "Barkos"
	},
	"H1303" : {
		"lemma" : "בַּרְקָן",
		"xlit" : "barqân",
		"pron" : "bar-kwan'",
		"derivation" : "from H1300 (בָּרָק)",
		"def" : "a thorn (perhaps as burning brightly)",
		"kjv" : "brier"
	},
	"H1304" : {
		"lemma" : "בָּרֶקֶת",
		"xlit" : "bâreqeth",
		"pron" : "baw-reh'-keth",
		"derivation" : "or בָּרְקַתxlit bârᵉkath corrected to bârᵉqath; from H1300 (בָּרָק)",
		"def" : "a gem (as flashing), perhaps the emerald",
		"kjv" : "carbuncle"
	},
	"H1305" : {
		"lemma" : "בָּרַר",
		"xlit" : "bârar",
		"pron" : "baw-rar'",
		"derivation" : "a primitive root",
		"def" : "to clarify (i.e. brighten), examine, select",
		"kjv" : "make bright, choice, chosen, cleanse (be clean), clearly, polished, (shew self) pure(-ify), purge (out)"
	},
	"H1306" : {
		"lemma" : "בִּרְשַׁע",
		"xlit" : "Birshaʻ",
		"pron" : "beer-shah'",
		"derivation" : "probably from H7562 (רֶשַׁע) with a prepositional prefix; with wickedness",
		"def" : "Birsha, a king of Gomorrah",
		"kjv" : "Birsha"
	},
	"H1307" : {
		"lemma" : "בֵּרֹתִי",
		"xlit" : "Bêrôthîy",
		"pron" : "bay-ro-thee'",
		"derivation" : "patrial from H1268 (בֵּרוֹתָה)",
		"def" : "a Berothite, or inhabitant of Berothai",
		"kjv" : "Berothite"
	},
	"H1308" : {
		"lemma" : "בְּשׂוֹר",
		"xlit" : "Bᵉsôwr",
		"pron" : "bes-ore'",
		"derivation" : "from H1319 (בָּשַׂר); cheerful",
		"def" : "Besor, a stream of Palestine",
		"kjv" : "Besor"
	},
	"H1309" : {
		"lemma" : "בְּשׂוֹרָה",
		"xlit" : "bᵉsôwrâh",
		"pron" : "bes-o-raw'",
		"derivation" : "or (shortened) בְּשֹׂרָה; feminine from H1319 (בָּשַׂר)",
		"def" : "glad tidings; by implication, reward forgood news",
		"kjv" : "reward for tidings"
	},
	"H1310" : {
		"lemma" : "בָּשַׁל",
		"xlit" : "bâshal",
		"pron" : "baw-shal'",
		"derivation" : "a primitive root",
		"def" : "properly, to boil up; hence, to be done in cooking; figuratively to ripen",
		"kjv" : "bake, boil, bring forth, roast, seethe, sod (be sodden)"
	},
	"H1311" : {
		"lemma" : "בָּשֵׁל",
		"xlit" : "bâshêl",
		"pron" : "baw-shale'",
		"derivation" : "from H1310 (בָּשַׁל)",
		"def" : "boiled",
		"kjv" : "[idiom] at all, sodden"
	},
	"H1312" : {
		"lemma" : "בִּשְׁלָם",
		"xlit" : "Bishlâm",
		"pron" : "bish-lawm'",
		"derivation" : "of foreign derivation",
		"def" : "Bishlam, a Persian",
		"kjv" : "Bishlam"
	},
	"H1313" : {
		"lemma" : "בָּשָׂם",
		"xlit" : "bâsâm",
		"pron" : "baw-sawm'",
		"derivation" : "from an unused root meaning to be fragrant; (compare H5561 (סַם))",
		"def" : "the balsam plant",
		"kjv" : "spice"
	},
	"H1314" : {
		"lemma" : "בֶּשֶׂם",
		"xlit" : "besem",
		"pron" : "beh'-sem",
		"derivation" : "or בֹּשֶׂם; from the same as H1313 (בָּשָׂם)",
		"def" : "fragrance; by implication, spicery; also the balsam plant",
		"kjv" : "smell, spice, sweet (odour)"
	},
	"H1315" : {
		"lemma" : "בׇּשְׂמַת",
		"xlit" : "Bosmath",
		"pron" : "bos-math'",
		"derivation" : "feminine of H1314 (בֶּשֶׂם) (the second form); fragrance",
		"def" : "Bosmath, the name of a wife of Esau, and of a daughter of Solomon",
		"kjv" : "Bashemath, Basmath"
	},
	"H1316" : {
		"lemma" : "בָּשָׁן",
		"xlit" : "Bâshân",
		"pron" : "baw-shawn'",
		"derivation" : "of uncertain derivation",
		"def" : "Bashan (often with the article), a region East of the Jordan",
		"kjv" : "Bashan"
	},
	"H1317" : {
		"lemma" : "בׇּשְׁנָה",
		"xlit" : "boshnâh",
		"pron" : "bosh-naw'",
		"derivation" : "feminine from H954 (בּוּשׁ)",
		"def" : "shamefulness",
		"kjv" : "shame"
	},
	"H1318" : {
		"lemma" : "בָּשַׁס",
		"xlit" : "bâshaç",
		"pron" : "baw-shas'",
		"derivation" : "a primitive root",
		"def" : "to trample down",
		"kjv" : "tread"
	},
	"H1319" : {
		"lemma" : "בָּשַׂר",
		"xlit" : "bâsar",
		"pron" : "baw-sar'",
		"derivation" : "a primitive root",
		"def" : "properly, to be fresh, i.e. full (rosy, (figuratively) cheerful); to announce (glad news)",
		"kjv" : "messenger, preach, publish, shew forth, (bear, bring, carry, preach, good, tell good) tidings"
	},
	"H1320" : {
		"lemma" : "בָּשָׂר",
		"xlit" : "bâsâr",
		"pron" : "baw-sawr'",
		"derivation" : "from H1319 (בָּשַׂר)",
		"def" : "flesh (from its freshness); by extension, body, person; also (by euphemistically) the pudenda of aman",
		"kjv" : "body, (fat, lean) flesh(-ed), kin, (man-) kind, [phrase] nakedness, self, skin"
	},
	"H1321" : {
		"lemma" : "בְּשַׁר",
		"xlit" : "bᵉshar",
		"pron" : "bes-ar'",
		"derivation" : "(Aramaic) corresponding to H1320 (בָּשָׂר)",
		"def" : "{flesh (from its freshness); by extension, body, person; also (by euphemistically) the pudenda of aman}",
		"kjv" : "flesh"
	},
	"H1322" : {
		"lemma" : "בֹּשֶׁת",
		"xlit" : "bôsheth",
		"pron" : "bo'-sheth",
		"derivation" : "from H954 (בּוּשׁ)",
		"def" : "shame (the feeling and the condition, as well as its cause); by implication (specifically) an idol",
		"kjv" : "ashamed, confusion, [phrase] greatly, (put to) shame(-ful thing)"
	},
	"H1323" : {
		"lemma" : "בַּת",
		"xlit" : "bath",
		"pron" : "bath",
		"derivation" : "from H1129 (בָּנָה) (as feminine of H1121 (בֵּן))",
		"def" : "a daughter (used in the same wide sense as other terms of relationship, literally and figuratively)",
		"kjv" : "apple (of the eye), branch, company, daughter, [idiom] first, [idiom] old, [phrase] owl, town, village"
	},
	"H1324" : {
		"lemma" : "בַּת",
		"xlit" : "bath",
		"pron" : "bath",
		"derivation" : "probably from the same as H1327 (בַּתָּה)",
		"def" : "a bath or Hebrew measure (as a means of division) of liquids",
		"kjv" : "bath"
	},
	"H1325" : {
		"lemma" : "בַּת",
		"xlit" : "bath",
		"pron" : "bath",
		"derivation" : "(Aramaic) corresponding to H1324 (בַּת)",
		"def" : "{a bath or Hebrew measure (as a means of division) of liquids}",
		"kjv" : "bath"
	},
	"H1326" : {
		"lemma" : "בָּתָה",
		"xlit" : "bâthâh",
		"pron" : "baw-thaw'",
		"derivation" : "probably an orthographical variation for H1327 (בַּתָּה)",
		"def" : "desolation",
		"kjv" : "waste"
	},
	"H1327" : {
		"lemma" : "בַּתָּה",
		"xlit" : "battâh",
		"pron" : "bat-taw'",
		"derivation" : "feminine from an unused root (meaning to break in pieces)",
		"def" : "desolation",
		"kjv" : "desolate"
	},
	"H1328" : {
		"lemma" : "בְּתוּאֵל",
		"xlit" : "Bᵉthûwʼêl",
		"pron" : "beth-oo-ale'",
		"derivation" : "apparently from the same as H1326 (בָּתָה) and H410 (אֵל); destroyed of God",
		"def" : "Bethuel, the name of a nephew of Abraham, and of a place in Palestine",
		"kjv" : "Bethuel. Compare H1329 (בְּתוּל)"
	},
	"H1329" : {
		"lemma" : "בְּתוּל",
		"xlit" : "Bᵉthûwl",
		"pron" : "beth-ool'",
		"derivation" : "for H1328 (בְּתוּאֵל)",
		"def" : "Bethul (i.e. Bethuel), a place in Palestine",
		"kjv" : "Bethuel"
	},
	"H1330" : {
		"lemma" : "בְּתוּלָה",
		"xlit" : "bᵉthûwlâh",
		"pron" : "beth-oo-law'",
		"derivation" : "feminine passive participle of an unused root meaning to separate",
		"def" : "a virgin (from her privacy); sometimes (by continuation) a bride; also (figuratively) a city or state",
		"kjv" : "maid, virgin"
	},
	"H1331" : {
		"lemma" : "בְּתוּלִים",
		"xlit" : "bᵉthûwlîym",
		"pron" : "beth-oo-leem'",
		"derivation" : "masculine plural of the same as H1330 (בְּתוּלָה)",
		"def" : "(collectively and abstractly) virginity; by implication and concretely, the tokens of it",
		"kjv" : "[idiom] maid, virginity"
	},
	"H1332" : {
		"lemma" : "בִּתְיָה",
		"xlit" : "Bithyâh",
		"pron" : "bith-yaw'",
		"derivation" : "from H1323 (בַּת) and H3050 (יָהּ); daughter (i.e. worshipper) of Jah",
		"def" : "Bithjah, an Egyptian woman",
		"kjv" : "Bithiah"
	},
	"H1333" : {
		"lemma" : "בָּתַק",
		"xlit" : "bâthaq",
		"pron" : "baw-thak'",
		"derivation" : "a primitive root",
		"def" : "to cut in pieces",
		"kjv" : "thrust through"
	},
	"H1334" : {
		"lemma" : "בָּתַר",
		"xlit" : "bâthar",
		"pron" : "baw-thar'",
		"derivation" : "a primitive root",
		"def" : "to chop up",
		"kjv" : "divide"
	},
	"H1335" : {
		"lemma" : "בֶּתֶר",
		"xlit" : "bether",
		"pron" : "beh'-ther",
		"derivation" : "from H1334 (בָּתַר)",
		"def" : "a section",
		"kjv" : "part, piece"
	},
	"H1336" : {
		"lemma" : "בֶּתֶר",
		"xlit" : "Bether",
		"pron" : "beh'-ther",
		"derivation" : "the same as H1335 (בֶּתֶר)",
		"def" : "Bether, a (craggy) place in Palestine",
		"kjv" : "Bether"
	},
	"H1337" : {
		"lemma" : "בַּת רַבִּים",
		"xlit" : "Bath Rabbîym",
		"pron" : "bath rab-beem'",
		"derivation" : "from H1323 (בַּת) and a masculine plural from H7227 (רַב)",
		"def" : "the daughter (i.e. city) of Rabbah",
		"kjv" : "Bath-rabbim"
	},
	"H1338" : {
		"lemma" : "בִּתְרוֹן",
		"xlit" : "Bithrôwn",
		"pron" : "bith-rone'",
		"derivation" : "from H1334 (בָּתַר); (with the article) the craggy spot",
		"def" : "Bithron, a place East of the Jordan",
		"kjv" : "Bithron"
	},
	"H1339" : {
		"lemma" : "בַּת־שֶׁבַע",
		"xlit" : "Bath-Shebaʻ",
		"pron" : "bath-sheh'-bah",
		"derivation" : "from H1323 (בַּת) and H7651 (שֶׁבַע) (in the sense of H7650 (שָׁבַע)); daughter of an oath",
		"def" : "Bath-Sheba, the mother of Solomon",
		"kjv" : "Bath-sheba"
	},
	"H1340" : {
		"lemma" : "בַּת־שׁוּעַ",
		"xlit" : "Bath-Shûwaʻ",
		"pron" : "bath-shoo'-ah",
		"derivation" : "from H1323 (בַּת) and H7771 (שׁוֹעַ); daughter of wealth;  the same as H1339 (בַּת־שֶׁבַע)",
		"def" : "Bath-shua",
		"kjv" : "Bath-shua"
	},
	"H1341" : {
		"lemma" : "גֵּא",
		"xlit" : "gêʼ",
		"pron" : "gay'",
		"derivation" : "for H1343 (גֵּאֶה)",
		"def" : "haughty",
		"kjv" : "proud"
	},
	"H1342" : {
		"lemma" : "גָּאָה",
		"xlit" : "gâʼâh",
		"pron" : "gaw-aw'",
		"derivation" : "a primitive root",
		"def" : "to mount up; hence, in general, to rise, (figuratively) be majestic",
		"kjv" : "gloriously, grow up, increase, be risen, triumph"
	},
	"H1343" : {
		"lemma" : "גֵּאֶה",
		"xlit" : "gêʼeh",
		"pron" : "gay-eh'",
		"derivation" : "from H1342 (גָּאָה)",
		"def" : "lofty; figuratively, arrogant",
		"kjv" : "proud"
	},
	"H1344" : {
		"lemma" : "גֵּאָה",
		"xlit" : "gêʼâh",
		"pron" : "gay-aw'",
		"derivation" : "feminine from H1342 (גָּאָה)",
		"def" : "arrogance",
		"kjv" : "pride"
	},
	"H1345" : {
		"lemma" : "גְּאוּאֵל",
		"xlit" : "Gᵉʼûwʼêl",
		"pron" : "gheh-oo-ale'",
		"derivation" : "from H1342 (גָּאָה) and H410 (אֵל); majesty of God",
		"def" : "Geuel, an Israelite",
		"kjv" : "Geuel"
	},
	"H1346" : {
		"lemma" : "גַּאֲוָה",
		"xlit" : "gaʼăvâh",
		"pron" : "gah-av-aw'",
		"derivation" : "from H1342 (גָּאָה)",
		"def" : "arrogance or majesty; by implication, (concretely) ornament",
		"kjv" : "excellency, haughtiness, highness, pride, proudly, swelling"
	},
	"H1347" : {
		"lemma" : "גָּאוֹן",
		"xlit" : "gâʼôwn",
		"pron" : "gaw-ohn'",
		"derivation" : "from H1342 (גָּאָה); the same as H1346 (גַּאֲוָה)xlit gaʻăvâh corrected to gaʼăvâh",
		"def" : "{arrogance or majesty; by implication, (concretely) ornament}",
		"kjv" : "arrogancy, excellency(-lent), majesty, pomp, pride, proud, swelling"
	},
	"H1348" : {
		"lemma" : "גֵּאוּת",
		"xlit" : "gêʼûwth",
		"pron" : "gay-ooth'",
		"derivation" : "from H1342 (גָּאָה); the same as H1346 (גַּאֲוָה)xlit gaʻăvâh corrected to gaʼăvâh",
		"def" : "{arrogance or majesty; by implication, (concretely) ornament}",
		"kjv" : "excellent things, lifting up, majesty, pride, proudly, raging"
	},
	"H1349" : {
		"lemma" : "גַּאֲיוֹן",
		"xlit" : "gaʼăyôwn",
		"pron" : "gah-ah-yone'",
		"derivation" : "from H1342 (גָּאָה)",
		"def" : "haughty",
		"kjv" : "proud"
	},
	"H1350" : {
		"lemma" : "גָּאַל",
		"xlit" : "gâʼal",
		"pron" : "gaw-al'",
		"derivation" : "a primitive root, to redeem (according to the Oriental law of kinship), i.e",
		"def" : "to be the next of kin (and as such to buy back a relative's property, marry his widow, etc.)",
		"kjv" : "[idiom] in any wise, [idiom] at all, avenger, deliver, (do, perform the part of near, next) kinsfolk(-man), purchase, ransom, redeem(-er), revenger"
	},
	"H1351" : {
		"lemma" : "גָּאַל",
		"xlit" : "gâʼal",
		"pron" : "gaw-al'",
		"derivation" : "a primitive root, (rather identified with H1350 (גָּאַל), through the idea of freeing, i.e. repudiating)",
		"def" : "to soil or (figuratively) desecrate",
		"kjv" : "defile, pollute, stain"
	},
	"H1352" : {
		"lemma" : "גֹּאֶל",
		"xlit" : "gôʼel",
		"pron" : "go'-el",
		"derivation" : "from H1351 (גָּאַל)",
		"def" : "profanation",
		"kjv" : "defile"
	},
	"H1353" : {
		"lemma" : "גְּאֻלָּה",
		"xlit" : "gᵉʼullâh",
		"pron" : "gheh-ool-law'",
		"derivation" : "feminine passive participle of H1350 (גָּאַל)",
		"def" : "redemption (including the right and the object); by implication, relationship",
		"kjv" : "kindred, redeem, redemption, right"
	},
	"H1354" : {
		"lemma" : "גַב",
		"xlit" : "gab",
		"pron" : "gab",
		"derivation" : "from an unused root meaning to hollow or curve; (compare H1460 (גֵּו) and H1479 (גּוּף))",
		"def" : "the back (as rounded); by analogy, the top or rim, a boss, a vault, arch of eye, bulwarks, etc.",
		"kjv" : "back, body, boss, eminent (higher) place, (eye) brows, nave, ring"
	},
	"H1355" : {
		"lemma" : "גַּב",
		"xlit" : "gab",
		"pron" : "gab",
		"derivation" : "(Aramaic) corresponding to H1354 (גַב)",
		"def" : "{the back (as rounded); by analogy, the top or rim, a boss, a vault, arch of eye, bulwarks, etc.}",
		"kjv" : "back"
	},
	"H1356" : {
		"lemma" : "גֵּב",
		"xlit" : "gêb",
		"pron" : "gabe",
		"derivation" : "from H1461 (גּוּב)",
		"def" : "a log (as cut out); also well or cistern (as dug)",
		"kjv" : "beam, ditch, pit"
	},
	"H1357" : {
		"lemma" : "גֵּב",
		"xlit" : "gêb",
		"pron" : "gabe",
		"derivation" : "probably from H1461 (גּוּב) (compare H1462 (גּוֹב))",
		"def" : "a locust (from its cutting)",
		"kjv" : "locust"
	},
	"H1358" : {
		"lemma" : "גֹּב",
		"xlit" : "gôb",
		"pron" : "gobe",
		"derivation" : "(Aramaic) from a root corresponding to H1461 (גּוּב)",
		"def" : "a pit (for wild animals) (as cut out)",
		"kjv" : "den"
	},
	"H1359" : {
		"lemma" : "גֹּב",
		"xlit" : "Gôb",
		"pron" : "gobe",
		"derivation" : "or (fully) גּוֹב; from H1461 (גּוּב); pit",
		"def" : "Gob, a place in Palestine",
		"kjv" : "Gob"
	},
	"H1360" : {
		"lemma" : "גֶּבֶא",
		"xlit" : "gebeʼ",
		"pron" : "geh'-beh",
		"derivation" : "from an unused root meaning probably to collect",
		"def" : "a reservoir; by analogy, a marsh",
		"kjv" : "marish, pit"
	},
	"H1361" : {
		"lemma" : "גָּבַהּ",
		"xlit" : "gâbahh",
		"pron" : "gaw-bah'",
		"derivation" : "a primitive root",
		"def" : "to soar, i.e. be lofty; figuratively, to be haughty",
		"kjv" : "exalt, be haughty, be (make) high(-er), lift up, mount up, be proud, raise up great height, upward"
	},
	"H1362" : {
		"lemma" : "גָּבָהּ",
		"xlit" : "gâbâhh",
		"pron" : "gaw-bawh'",
		"derivation" : "from H1361 (גָּבַהּ)",
		"def" : "lofty (literally or figuratively)",
		"kjv" : "high, proud"
	},
	"H1363" : {
		"lemma" : "גֹּבַהּ",
		"xlit" : "gôbahh",
		"pron" : "go'-bah",
		"derivation" : "from H1361 (גָּבַהּ)",
		"def" : "elation, grandeur, arrogance",
		"kjv" : "excellency, haughty, height, high, loftiness, pride"
	},
	"H1364" : {
		"lemma" : "גָּבֹהַּ",
		"xlit" : "gâbôahh",
		"pron" : "gaw-bo'-ah",
		"derivation" : "or גָּבוֹהַּ; (fully) from H1361 (גָּבַהּ)",
		"def" : "elevated (or elated), powerful, arrogant",
		"kjv" : "haughty, height, high(-er), lofty, proud, [idiom] exceeding proudly"
	},
	"H1365" : {
		"lemma" : "גַּבְהוּת",
		"xlit" : "gabhûwth",
		"pron" : "gab-hooth'",
		"derivation" : "from H1361 (גָּבַהּ)",
		"def" : "pride",
		"kjv" : "loftiness, lofty"
	},
	"H1366" : {
		"lemma" : "גְּבוּל",
		"xlit" : "gᵉbûwl",
		"pron" : "gheb-ool'",
		"derivation" : "or גְּבֻל; (shortened) from H1379 (גָּבַל)",
		"def" : "properly, a cord (as twisted), i.e. (by implication) a boundary; by extension the territory inclosed",
		"kjv" : "border, bound, coast, [idiom] great, landmark, limit, quarter, space"
	},
	"H1367" : {
		"lemma" : "גְּבוּלָה",
		"xlit" : "gᵉbûwlâh",
		"pron" : "gheb-oo-law'",
		"derivation" : "or גְּבֻלָה; (shortened) feminine of H1366 (גְּבוּל)",
		"def" : "a boundary, region",
		"kjv" : "border, bound, coast, landmark. place"
	},
	"H1368" : {
		"lemma" : "גִּבּוֹר",
		"xlit" : "gibbôwr",
		"pron" : "ghib-bore'",
		"derivation" : "or גִּבֹּר; (shortened) intensive from the same as H1397 (גֶּבֶר)",
		"def" : "powerful; by implication, warrior, tyrant",
		"kjv" : "champion, chief, [idiom] excel, giant, man, mighty (man, one), strong (man), valiant man"
	},
	"H1369" : {
		"lemma" : "גְּבוּרָה",
		"xlit" : "gᵉbûwrâh",
		"pron" : "gheb-oo-raw'",
		"derivation" : "feminine passive participle from the same as H1368 (גִּבּוֹר)",
		"def" : "force (literally or figuratively); by implication, valor, victory",
		"kjv" : "force, mastery, might, mighty (act, power), power, strength"
	},
	"H1370" : {
		"lemma" : "גְּבוּרָה",
		"xlit" : "gᵉbûwrâh",
		"pron" : "gheb-oo-raw'",
		"derivation" : "(Aramaic) corresponding to H1369 (גְּבוּרָה)",
		"def" : "power",
		"kjv" : "might"
	},
	"H1371" : {
		"lemma" : "גִּבֵּחַ",
		"xlit" : "gibbêach",
		"pron" : "ghib-bay'-akh",
		"derivation" : "from an unused root meaning to be high (in the forehead)",
		"def" : "bald in the forehead",
		"kjv" : "forehead bald"
	},
	"H1372" : {
		"lemma" : "גַּבַּחַת",
		"xlit" : "gabbachath",
		"pron" : "gab-bakh'-ath",
		"derivation" : "from the same as H1371 (גִּבֵּחַ)",
		"def" : "baldness in the forehead; by analogy, a bare spot on the right side of cloth",
		"kjv" : "bald forehead, [idiom] without"
	},
	"H1373" : {
		"lemma" : "גַּבַּי",
		"xlit" : "Gabbay",
		"pron" : "gab-bah'ee",
		"derivation" : "from the same as H1354 (גַב); collective",
		"def" : "Gabbai, an Israelite",
		"kjv" : "Gabbai"
	},
	"H1374" : {
		"lemma" : "גֵּבִים",
		"xlit" : "Gêbîym",
		"pron" : "gay-beem'",
		"derivation" : "plural of H1356 (גֵּב); cisterns",
		"def" : "Gebim, a place in Palestine",
		"kjv" : "Gebim"
	},
	"H1375" : {
		"lemma" : "גְּבִיעַ",
		"xlit" : "gᵉbîyaʻ",
		"pron" : "gheb-ee'-ah",
		"derivation" : "from an unused root (meaning to be convex)",
		"def" : "a goblet; by analogy, the calyx of aflower",
		"kjv" : "house, cup, pot"
	},
	"H1376" : {
		"lemma" : "גְּבִיר",
		"xlit" : "gᵉbîyr",
		"pron" : "gheb-eer'",
		"derivation" : "from H1396 (גָּבַר)",
		"def" : "a master",
		"kjv" : "lord"
	},
	"H1377" : {
		"lemma" : "גְּבִירָה",
		"xlit" : "gᵉbîyrâh",
		"pron" : "gheb-ee-raw'",
		"derivation" : "feminine of H1376 (גְּבִיר)",
		"def" : "a mistress",
		"kjv" : "queen"
	},
	"H1378" : {
		"lemma" : "גָּבִישׁ",
		"xlit" : "gâbîysh",
		"pron" : "gaw-beesh'",
		"derivation" : "from an unused root (probably meaning to freeze)",
		"def" : "crystal (from its resemblance to ice)",
		"kjv" : "pearl"
	},
	"H1379" : {
		"lemma" : "גָּבַל",
		"xlit" : "gâbal",
		"pron" : "gaw-bal'",
		"derivation" : "a primitive root; also as a denominative from H1366 (גְּבוּל)",
		"def" : "properly, to twist as arope;  to bound (as by a line)",
		"kjv" : "be border, set (bounds about)"
	},
	"H1380" : {
		"lemma" : "גְּבַל",
		"xlit" : "Gᵉbal",
		"pron" : "gheb-al'",
		"derivation" : "from H1379 (גָּבַל) (in the sense of a chain of hills); a mountain",
		"def" : "Gebal, a place in Phoenicia",
		"kjv" : "Gebal"
	},
	"H1381" : {
		"lemma" : "גְּבָל",
		"xlit" : "Gᵉbâl",
		"pron" : "gheb-awl'",
		"derivation" : "the same as H1380 (גְּבַל)",
		"def" : "Gebal, a region in Idumaea",
		"kjv" : "Gebal"
	},
	"H1382" : {
		"lemma" : "גִּבְלִי",
		"xlit" : "Giblîy",
		"pron" : "ghib-lee'",
		"derivation" : "; patrial from H1380 (גְּבַל)",
		"def" : "a Gebalite, or inhabitant of Gebal",
		"kjv" : "Giblites, stone-squarer"
	},
	"H1383" : {
		"lemma" : "גַּבְלֻת",
		"xlit" : "gabluth",
		"pron" : "gab-looth'",
		"derivation" : "from H1379 (גָּבַל)",
		"def" : "a twisted chain or lace",
		"kjv" : "end"
	},
	"H1384" : {
		"lemma" : "גִּבֵּן",
		"xlit" : "gibbên",
		"pron" : "gib-bane'",
		"derivation" : "from an unused root meaning to be arched or contracted",
		"def" : "hunch-backed",
		"kjv" : "crookbackt"
	},
	"H1385" : {
		"lemma" : "גְּבִנָה",
		"xlit" : "gᵉbinâh",
		"pron" : "gheb-ee-naw'",
		"derivation" : "feminine from the same as H1384 (גִּבֵּן)",
		"def" : "curdled milk",
		"kjv" : "cheese"
	},
	"H1386" : {
		"lemma" : "גַּבְנֹן",
		"xlit" : "gabnôn",
		"pron" : "gab-nohn'",
		"derivation" : "from the same as H1384 (גִּבֵּן)",
		"def" : "a hump or peak of hills",
		"kjv" : "high"
	},
	"H1387" : {
		"lemma" : "גֶּבַע",
		"xlit" : "Gebaʻ",
		"pron" : "gheh'-bah",
		"derivation" : "from the same as H1375 (גְּבִיעַ), a hillock",
		"def" : "Geba, a place in Palestine",
		"kjv" : "Gaba, Geba, Gibeah"
	},
	"H1388" : {
		"lemma" : "גִּבְעָא",
		"xlit" : "Gibʻâʼ",
		"pron" : "ghib-aw'",
		"derivation" : "by permutation for H1389 (גִּבְעָה); a hill",
		"def" : "Giba, a place in Palestine",
		"kjv" : "Gibeah"
	},
	"H1389" : {
		"lemma" : "גִּבְעָה",
		"xlit" : "gibʻâh",
		"pron" : "ghib-aw'",
		"derivation" : "feminine from the same as H1387 (גֶּבַע)",
		"def" : "a hillock",
		"kjv" : "hill, little hill"
	},
	"H1390" : {
		"lemma" : "גִּבְעָה",
		"xlit" : "Gibʻâh",
		"pron" : "ghib-aw'",
		"derivation" : "the same as H1389 (גִּבְעָה)",
		"def" : "Gibah; the name of three places in Palestine",
		"kjv" : "Gibeah, the hill"
	},
	"H1391" : {
		"lemma" : "גִּבְעוֹן",
		"xlit" : "Gibʻôwn",
		"pron" : "ghib-ohn'",
		"derivation" : "from the same as H1387 (גֶּבַע); hilly",
		"def" : "Gibon, a place in Palestine",
		"kjv" : "Gibeon"
	},
	"H1392" : {
		"lemma" : "גִּבְעֹל",
		"xlit" : "gibʻôl",
		"pron" : "ghib-ole'",
		"derivation" : "prolonged from H1375 (גְּבִיעַ)",
		"def" : "the calyx of a flower",
		"kjv" : "bolled"
	},
	"H1393" : {
		"lemma" : "גִּבְעֹנִי",
		"xlit" : "Gibʻônîy",
		"pron" : "ghib-o-nee'",
		"derivation" : "patrial from H1391 (גִּבְעוֹן)",
		"def" : "a Gibonite, or inhabitant of Gibon",
		"kjv" : "Gibeonite"
	},
	"H1394" : {
		"lemma" : "גִּבְעַת",
		"xlit" : "Gibʻath",
		"pron" : "ghib-ath'",
		"derivation" : "from the same as H1375 (גְּבִיעַ); hilliness",
		"def" : "Gibath",
		"kjv" : "Gibeath"
	},
	"H1395" : {
		"lemma" : "גִּבְעָתִי",
		"xlit" : "Gibʻâthîy",
		"pron" : "ghib-aw-thee'",
		"derivation" : "patrial from H1390 (גִּבְעָה)",
		"def" : "a Gibathite, or inhabitant of Gibath",
		"kjv" : "Gibeathite"
	},
	"H1396" : {
		"lemma" : "גָּבַר",
		"xlit" : "gâbar",
		"pron" : "gaw-bar'",
		"derivation" : "a primitive root",
		"def" : "to be strong; by implication, to prevail, act insolently",
		"kjv" : "exceed, confirm, be great, be mighty, prevail, put to more (strength), strengthen, be stronger, be valiant"
	},
	"H1397" : {
		"lemma" : "גֶּבֶר",
		"xlit" : "geber",
		"pron" : "gheh'-ber",
		"derivation" : "from H1396 (גָּבַר)",
		"def" : "properly, a valiant man or warrior; generally, a person simply",
		"kjv" : "every one, man, [idiom] mighty"
	},
	"H1398" : {
		"lemma" : "גֶּבֶר",
		"xlit" : "Geber",
		"pron" : "gheh'-ber",
		"derivation" : "the same as H1397 (גֶּבֶר)",
		"def" : "Geber, the name of two Israelites",
		"kjv" : "Geber"
	},
	"H1399" : {
		"lemma" : "גְּבַר",
		"xlit" : "gᵉbar",
		"pron" : "gheb-ar'",
		"derivation" : "from H1396 (גָּבַר); the same as H1397 (גֶּבֶר)",
		"def" : "a person",
		"kjv" : "man"
	},
	"H1400" : {
		"lemma" : "גְּבַר",
		"xlit" : "gᵉbar",
		"pron" : "gheb-ar'",
		"derivation" : "(Aramaic) corresponding to H1399 (גְּבַר)",
		"def" : "{a person}",
		"kjv" : "certain, man"
	},
	"H1401" : {
		"lemma" : "גִּבָּר",
		"xlit" : "gibbâr",
		"pron" : "ghib-bawr'",
		"derivation" : "(Aramaic) intensive of H1400 (גְּבַר)",
		"def" : "valiant, or warrior",
		"kjv" : "mighty"
	},
	"H1402" : {
		"lemma" : "גִּבָּר",
		"xlit" : "Gibbâr",
		"pron" : "ghib-bawr'",
		"derivation" : "intensive of H1399 (גְּבַר)",
		"def" : "Gibbar, an Israelite",
		"kjv" : "Gibbar"
	},
	"H1403" : {
		"lemma" : "גַּבְרִיאֵל",
		"xlit" : "Gabrîyʼêl",
		"pron" : "gab-ree-ale'",
		"derivation" : "from H1397 (גֶּבֶר) and H410 (אֵל); man of God",
		"def" : "Gabriel, an archangel",
		"kjv" : "Garbriel"
	},
	"H1404" : {
		"lemma" : "גְּבֶרֶת",
		"xlit" : "gᵉbereth",
		"pron" : "gheb-eh'-reth",
		"derivation" : "feminine of H1376 (גְּבִיר)",
		"def" : "mistress",
		"kjv" : "lady, mistress"
	},
	"H1405" : {
		"lemma" : "גִּבְּתוֹן",
		"xlit" : "Gibbᵉthôwn",
		"pron" : "ghib-beth-one'",
		"derivation" : "intensive from H1389 (גִּבְעָה); a hilly spot",
		"def" : "Gibbethon, a place in Palestine",
		"kjv" : "Gibbethon"
	},
	"H1406" : {
		"lemma" : "גָּג",
		"xlit" : "gâg",
		"pron" : "gawg",
		"derivation" : "probably by reduplication from H1342 (גָּאָה)",
		"def" : "a roof; by analogy, the top of an altar",
		"kjv" : "roof (of the house), (house) top (of the house)"
	},
	"H1407" : {
		"lemma" : "גַּד",
		"xlit" : "gad",
		"pron" : "gad",
		"derivation" : "from H1413 (גָּדַד) (in the sense of cutting)",
		"def" : "coriander seed (from its furrows)",
		"kjv" : "coriander"
	},
	"H1408" : {
		"lemma" : "גַּד",
		"xlit" : "Gad",
		"pron" : "gad",
		"derivation" : "a variation of H1409 (גָּד)",
		"def" : "Fortune, a Babylonian deity",
		"kjv" : "that troop"
	},
	"H1409" : {
		"lemma" : "גָּד",
		"xlit" : "gâd",
		"pron" : "gawd",
		"derivation" : "from H1464 (גּוּד) (in the sense of distributing)",
		"def" : "fortune",
		"kjv" : "troop"
	},
	"H1410" : {
		"lemma" : "גָּד",
		"xlit" : "Gâd",
		"pron" : "gawd",
		"derivation" : "from H1464 (גּוּד)",
		"def" : "Gad, a son of Jacob, including his tribe and its territory; also a prophet",
		"kjv" : "Gad"
	},
	"H1411" : {
		"lemma" : "גְּדָבָר",
		"xlit" : "gᵉdâbâr",
		"pron" : "ghed-aw-bawr'",
		"derivation" : "(Aramaic) corresponding to H1489 (גִּזְבָּר)",
		"def" : "a treasurer",
		"kjv" : "treasurer"
	},
	"H1412" : {
		"lemma" : "גֻּדְגֹּדָה",
		"xlit" : "Gudgôdâh",
		"pron" : "gud-go'-daw",
		"derivation" : "by reduplication from H1413 (גָּדַד) (in the sense of cutting) cleft",
		"def" : "Gudgodah, a place in the Desert",
		"kjv" : "Gudgodah"
	},
	"H1413" : {
		"lemma" : "גָּדַד",
		"xlit" : "gâdad",
		"pron" : "gaw-dad'",
		"derivation" : "a primitive root (compare H1464 (גּוּד))",
		"def" : "to crowd; also to gash (as if by pressing into)",
		"kjv" : "assemble (selves by troops), gather (selves together, self in troops), cut selves"
	},
	"H1414" : {
		"lemma" : "גְּדַד",
		"xlit" : "gᵉdad",
		"pron" : "ghed-ad'",
		"derivation" : "(Aramaic) corresponding to H1413 (גָּדַד)",
		"def" : "to cut down",
		"kjv" : "hew down"
	},
	"H1415" : {
		"lemma" : "גָּדָה",
		"xlit" : "gâdâh",
		"pron" : "gaw-daw'",
		"derivation" : "from an unused root (meaning to cut off)",
		"def" : "a border of a river (as cut into by the stream)",
		"kjv" : "bank"
	},
	"H1416" : {
		"lemma" : "גְּדוּד",
		"xlit" : "gᵉdûwd",
		"pron" : "ghed-ood'",
		"derivation" : "from H1413 (גָּדַד)",
		"def" : "a crowd (especially of soldiers)",
		"kjv" : "army, band (of men), company, troop (of robbers)"
	},
	"H1417" : {
		"lemma" : "גְּדוּד",
		"xlit" : "gᵉdûwd",
		"pron" : "ghed-ood'",
		"derivation" : "or גְּדֻדָה; (feminine) from H1413 (גָּדַד)",
		"def" : "a furrow (as cut)",
		"kjv" : "furrow"
	},
	"H1418" : {
		"lemma" : "גְּדוּדָה",
		"xlit" : "gᵉdûwdâh",
		"pron" : "ghed-oo-daw'",
		"derivation" : "feminine participle passive of H1413 (גָּדַד)",
		"def" : "an incision",
		"kjv" : "cutting"
	},
	"H1419" : {
		"lemma" : "גָּדוֹל",
		"xlit" : "gâdôwl",
		"pron" : "gaw-dole'",
		"derivation" : "or גָּדֹל; (shortened) from H1431 (גָּדַל)",
		"def" : "great (in any sense); hence, older; also insolent",
		"kjv" : "[phrase] aloud, elder(-est), [phrase] exceeding(-ly), [phrase] far, (man of) great (man, matter, thing,-er,-ness), high, long, loud, mighty, more, much, noble, proud thing, [idiom] sore, ([idiom]) very"
	},
	"H1420" : {
		"lemma" : "גְּדוּלָה",
		"xlit" : "gᵉdûwlâh",
		"pron" : "ghed-oo-law'",
		"derivation" : "or גְּדֻלָּה; (shortened) or (less accurately) גְּדוּלָּה; feminine of H1419 (גָּדוֹל)",
		"def" : "greatness; (concretely) mighty acts",
		"kjv" : "dignity, great things(-ness), majesty"
	},
	"H1421" : {
		"lemma" : "גִּדּוּף",
		"xlit" : "giddûwph",
		"pron" : "ghid-doof'",
		"derivation" : "or (shortened) גִּדֻּף; and (feminine) גִּדּוּפָהxlit giddûphâh corrected to giddûwphâh; or גִּדֻּפָה; from H1422 (גְּדוּפָה)",
		"def" : "vilification",
		"kjv" : "reproach, reviling"
	},
	"H1422" : {
		"lemma" : "גְּדוּפָה",
		"xlit" : "gᵉdûwphâh",
		"pron" : "ghed-oo-faw'",
		"derivation" : "feminine passive participle of H1442 (גָּדַף)",
		"def" : "a revilement",
		"kjv" : "taunt"
	},
	"H1423" : {
		"lemma" : "גְּדִי",
		"xlit" : "gᵉdîy",
		"pron" : "ghed-ee'",
		"derivation" : "from the same as H1415 (גָּדָה)",
		"def" : "a young goat (from browsing)",
		"kjv" : "kid"
	},
	"H1424" : {
		"lemma" : "גָּדִי",
		"xlit" : "Gâdîy",
		"pron" : "gaw-dee'",
		"derivation" : "from H1409 (גָּד); fortunate",
		"def" : "Gadi, an Israelite",
		"kjv" : "Gadi"
	},
	"H1425" : {
		"lemma" : "גָּדִי",
		"xlit" : "Gâdîy",
		"pron" : "gaw-dee'",
		"derivation" : "patronymically from H1410 (גָּד)",
		"def" : "a Gadite (collectively) or descendants of Gad",
		"kjv" : "Gadites, children of Gad"
	},
	"H1426" : {
		"lemma" : "גַּדִּי",
		"xlit" : "Gaddîy",
		"pron" : "gad-dee'",
		"derivation" : "intensive for H1424 (גָּדִי)",
		"def" : "Gaddi, an Israelite",
		"kjv" : "Gaddi"
	},
	"H1427" : {
		"lemma" : "גַּדִּיאֵל",
		"xlit" : "Gaddîyʼêl",
		"pron" : "gad-dee-ale'",
		"derivation" : "from H1409 (גָּד) and H410 (אֵל); fortune of God",
		"def" : "Gaddiel, an Israelite",
		"kjv" : "Gaddiel"
	},
	"H1428" : {
		"lemma" : "גִּדְיָה",
		"xlit" : "gidyâh",
		"pron" : "ghid-yaw'",
		"derivation" : "or גַּדיָה; the same as H1415 (גָּדָה)",
		"def" : "a river brink",
		"kjv" : "bank"
	},
	"H1429" : {
		"lemma" : "גְּדִיָּה",
		"xlit" : "gᵉdîyâh",
		"pron" : "ghed-ee-yaw'",
		"derivation" : "feminine of H1423 (גְּדִי)",
		"def" : "a young female goat",
		"kjv" : "kid"
	},
	"H1430" : {
		"lemma" : "גָּדִישׁ",
		"xlit" : "gâdîysh",
		"pron" : "gaw-deesh'",
		"derivation" : "from an unused root (meaning to heap up)",
		"def" : "a stack of sheaves; by analogy, a tomb",
		"kjv" : "shock (stack) (of corn), tomb"
	},
	"H1431" : {
		"lemma" : "גָּדַל",
		"xlit" : "gâdal",
		"pron" : "gaw-dal'",
		"derivation" : "a primitive root; properly, to twist (compare H1434 (גְּדִל)), i.e",
		"def" : "to be (causatively make) large (in various senses, as in body, mind, estate or honor, also in pride)",
		"kjv" : "advance, boast, bring up, exceed, excellent, be(-come, do, give, make, wax), great(-er, come to... estate, [phrase] things), grow(up), increase, lift up, magnify(-ifical), be much set by, nourish (up), pass, promote, proudly (spoken), tower"
	},
	"H1432" : {
		"lemma" : "גָּדֵל",
		"xlit" : "gâdêl",
		"pron" : "gaw-dale'",
		"derivation" : "from H1431 (גָּדַל)",
		"def" : "large (literally or figuratively)",
		"kjv" : "great, grew"
	},
	"H1433" : {
		"lemma" : "גֹּדֶל",
		"xlit" : "gôdel",
		"pron" : "go'-del",
		"derivation" : "from H1431 (גָּדַל)",
		"def" : "magnitude (literally or figuratively)",
		"kjv" : "greatness, stout(-ness)"
	},
	"H1434" : {
		"lemma" : "גְּדִל",
		"xlit" : "gᵉdil",
		"pron" : "ghed-eel'",
		"derivation" : "from H1431 (גָּדַל) (in the sense of twisting)",
		"def" : "thread, i.e. a tassel or festoon",
		"kjv" : "fringe, wreath"
	},
	"H1435" : {
		"lemma" : "גִּדֵּל",
		"xlit" : "Giddêl",
		"pron" : "ghid-dale'",
		"derivation" : "from H1431 (גָּדַל); stout",
		"def" : "Giddel, the name of one of the Nethinim, also of one of 'Solomon's servants'",
		"kjv" : "Giddel"
	},
	"H1436" : {
		"lemma" : "גְּדַּלְיָה",
		"xlit" : "Gᵉdalyâh",
		"pron" : "ghed-al-yaw'",
		"derivation" : "or (prolonged) גְּדַלְיָהוּ; from H1431 (גָּדַל) and H3050 (יָהּ); Jah has become great",
		"def" : "Gedaljah, the name of five Israelites",
		"kjv" : "Gedaliah"
	},
	"H1437" : {
		"lemma" : "גִּדַּלְתִּי",
		"xlit" : "Giddaltîy",
		"pron" : "ghid-dal'-tee",
		"derivation" : "from H1431 (גָּדַל); I have made great",
		"def" : "Giddalti, an Israelite",
		"kjv" : "Giddalti"
	},
	"H1438" : {
		"lemma" : "גָּדַע",
		"xlit" : "gâdaʻ",
		"pron" : "gaw-dah'",
		"derivation" : "a primitive root",
		"def" : "to fell a tree; generally, to destroy anything",
		"kjv" : "cut (asunder, in sunder, down, off), hew down"
	},
	"H1439" : {
		"lemma" : "גִּדְעוֹן",
		"xlit" : "Gidʻôwn",
		"pron" : "ghid-ohn'",
		"derivation" : "from H1438 (גָּדַע); feller (i.e. warrior)",
		"def" : "Gidon, an Israelite",
		"kjv" : "Gideon"
	},
	"H1440" : {
		"lemma" : "גִּדְעֹם",
		"xlit" : "Gidʻôm",
		"pron" : "ghid-ohm'",
		"derivation" : "from H1438 (גָּדַע); a cutting (i.e. desolation)",
		"def" : "Gidom, a place in Palestine",
		"kjv" : "Gidom"
	},
	"H1441" : {
		"lemma" : "גִּדְעֹנִי",
		"xlit" : "Gidʻônîy",
		"pron" : "ghid-o-nee'",
		"derivation" : "from H1438 (גָּדַע); warlike (compare H1439 (גִּדְעוֹן))",
		"def" : "Gidoni, an Israelite",
		"kjv" : "Gideoni"
	},
	"H1442" : {
		"lemma" : "גָּדַף",
		"xlit" : "gâdaph",
		"pron" : "gaw-daf'",
		"derivation" : "a primitive root",
		"def" : "to hack (with words), i.e. revile",
		"kjv" : "blaspheme, reproach"
	},
	"H1443" : {
		"lemma" : "גָּדַר",
		"xlit" : "gâdar",
		"pron" : "gaw-dar'",
		"derivation" : "a primitive root",
		"def" : "to wall in or around",
		"kjv" : "close up, fence up, hedge, inclose, make up (a wall), mason, repairer"
	},
	"H1444" : {
		"lemma" : "גֶּדֶר",
		"xlit" : "geder",
		"pron" : "gheh'-der",
		"derivation" : "from H1443 (גָּדַר)",
		"def" : "a circumvallation",
		"kjv" : "wall"
	},
	"H1445" : {
		"lemma" : "גֶּדֶר",
		"xlit" : "Geder",
		"pron" : "gheh'-der",
		"derivation" : "; the same as H1444 (גֶּדֶר)",
		"def" : "Geder, a place in Palestine",
		"kjv" : "Geder"
	},
	"H1446" : {
		"lemma" : "גְדֹר",
		"xlit" : "Gᵉdôr",
		"pron" : "ghed-ore'",
		"derivation" : "or (fully) גְּדוֹר; from H1443 (גָּדַר); inclosure",
		"def" : "Gedor, a place in Palestine; also the name of three Israelites",
		"kjv" : "Gedor"
	},
	"H1447" : {
		"lemma" : "גָּדֵר",
		"xlit" : "gâdêr",
		"pron" : "gaw-dare'",
		"derivation" : "from H1443 (גָּדַר)",
		"def" : "a circumvallation; by implication, an inclosure",
		"kjv" : "fence, hedge, wall"
	},
	"H1448" : {
		"lemma" : "גְּדֵרָה",
		"xlit" : "gᵉdêrâh",
		"pron" : "ghed-ay-raw'",
		"derivation" : "feminine of H1447 (גָּדֵר)",
		"def" : "enclosure (especially for flocks)",
		"kjv" : "(sheep-) cote (fold) hedge, wall"
	},
	"H1449" : {
		"lemma" : "גְּדֵרָה",
		"xlit" : "Gᵉdêrâh",
		"pron" : "ghed-ay-raw'",
		"derivation" : "the same as H1448 (גְּדֵרָה)",
		"def" : "(with the article) Gederah, a place in Palestine",
		"kjv" : "Gederah, hedges"
	},
	"H1450" : {
		"lemma" : "גְּדֵרוֹת",
		"xlit" : "Gᵉdêrôwth",
		"pron" : "ghed-ay-rohth'",
		"derivation" : "plural of H1448 (גְּדֵרָה); walls",
		"def" : "Gederoth, a place in Palestine",
		"kjv" : "Gederoth"
	},
	"H1451" : {
		"lemma" : "גְּדֵרִי",
		"xlit" : "Gᵉdêrîy",
		"pron" : "ghed-ay-ree'",
		"derivation" : "patrial from H1445 (גֶּדֶר)",
		"def" : "a Gederite, or inhabitant of Geder",
		"kjv" : "Gederite"
	},
	"H1452" : {
		"lemma" : "גְּדֵרָתִי",
		"xlit" : "Gᵉdêrâthîy",
		"pron" : "ghed-ay-raw-thee'",
		"derivation" : "patrial from H1449 (גְּדֵרָה)",
		"def" : "a Gederathite, or inhabitant of Gederah",
		"kjv" : "Gederathite"
	},
	"H1453" : {
		"lemma" : "גְּדֵרֹתַיִם",
		"xlit" : "Gᵉdêrôthayim",
		"pron" : "ghed-ay-ro-thah'-yim",
		"derivation" : "dual of H1448 (גְּדֵרָה); double wall",
		"def" : "Gederothajim, a place in Palestine",
		"kjv" : "Gederothaim"
	},
	"H1454" : {
		"lemma" : "גֵּה",
		"xlit" : "gêh",
		"pron" : "gay",
		"derivation" : "probably a clerical error for H2088 (זֶה)",
		"def" : "this",
		"kjv" : "this"
	},
	"H1455" : {
		"lemma" : "גָּהָה",
		"xlit" : "gâhâh",
		"pron" : "gaw-haw'",
		"derivation" : "a primitive root",
		"def" : "to remove (a bandage from a wound, i.e. heal it)",
		"kjv" : "cure"
	},
	"H1456" : {
		"lemma" : "גֵּהָה",
		"xlit" : "gêhâh",
		"pron" : "gay-haw'",
		"derivation" : "from H1455 (גָּהָה)",
		"def" : "a cure",
		"kjv" : "medicine"
	},
	"H1457" : {
		"lemma" : "גָּהַר",
		"xlit" : "gâhar",
		"pron" : "gaw-har'",
		"derivation" : "a primitive root",
		"def" : "to prostrate oneself",
		"kjv" : "cast self down, stretch self"
	},
	"H1458" : {
		"lemma" : "גַּו",
		"xlit" : "gav",
		"pron" : "gav",
		"derivation" : "another form for H1460 (גֵּו)",
		"def" : "the back",
		"kjv" : "back"
	},
	"H1459" : {
		"lemma" : "גַּו",
		"xlit" : "gav",
		"pron" : "gav",
		"derivation" : "(Aramaic) corresponding to H1460 (גֵּו)",
		"def" : "the middle",
		"kjv" : "midst, same, there-(where-) in"
	},
	"H1460" : {
		"lemma" : "גֵּו",
		"xlit" : "gêv",
		"pron" : "gave",
		"derivation" : "from H1342 (גָּאָה) (corresponding to H1354 (גַב))",
		"def" : "the back; by analogy, the middle",
		"kjv" : "[phrase] among, back, body"
	},
	"H1461" : {
		"lemma" : "גּוּב",
		"xlit" : "gûwb",
		"pron" : "goob",
		"derivation" : "a primitive root",
		"def" : "to dig",
		"kjv" : "husbandman"
	},
	"H1462" : {
		"lemma" : "גּוֹב",
		"xlit" : "gôwb",
		"pron" : "gobe",
		"derivation" : "from H1461 (גּוּב)",
		"def" : "the locust (from its grubbing as a larvae)",
		"kjv" : "grasshopper, [idiom] great"
	},
	"H1463" : {
		"lemma" : "גּוֹג",
		"xlit" : "Gôwg",
		"pron" : "gohg",
		"derivation" : "of uncertain derivation",
		"def" : "Gog, the name of an Israelite, also of some nothern nation",
		"kjv" : "Gog"
	},
	"H1464" : {
		"lemma" : "גּוּד",
		"xlit" : "gûwd",
		"pron" : "goode",
		"derivation" : "a primitive root (akin to H1413 (גָּדַד))",
		"def" : "to crowd upon, i.e. attack",
		"kjv" : "invade, overcome"
	},
	"H1465" : {
		"lemma" : "גֵּוָה",
		"xlit" : "gêvâh",
		"pron" : "gay-vaw'",
		"derivation" : "feminine of H1460 (גֵּו)",
		"def" : "the back, i.e. (by extensive) the person",
		"kjv" : "body"
	},
	"H1466" : {
		"lemma" : "גֵּוָה",
		"xlit" : "gêvâh",
		"pron" : "gay-vaw'",
		"derivation" : "the same as H1465 (גֵּוָה)",
		"def" : "exaltation; (figuratively) arrogance",
		"kjv" : "lifting up, pride"
	},
	"H1467" : {
		"lemma" : "גֵּוָה",
		"xlit" : "gêvâh",
		"pron" : "gay-vaw'",
		"derivation" : "(Aramaic) corresponding to H1466 (גֵּוָה)",
		"def" : "{exaltation; (figuratively) arrogance}",
		"kjv" : "pride"
	},
	"H1468" : {
		"lemma" : "גּוּז",
		"xlit" : "gûwz",
		"pron" : "gooz",
		"derivation" : "a primitive root (compare H1494 (גַּזָז))",
		"def" : "properly, to shear off; but used only in the (figuratively) sense of passing rapidly",
		"kjv" : "bring, cut off"
	},
	"H1469" : {
		"lemma" : "גּוֹזָל",
		"xlit" : "gôwzâl",
		"pron" : "go-zawl'",
		"derivation" : "or (shortened) גֹזָלlemma גזָל missing vowel, corrected to גֹזָל; from H1497 (גָּזַל)",
		"def" : "a nestling (as being comparatively nude of feathers)",
		"kjv" : "young (pigeon)"
	},
	"H1470" : {
		"lemma" : "גּוֹזָן",
		"xlit" : "Gôwzân",
		"pron" : "go-zawn'",
		"derivation" : "probably from H1468 (גּוּז); a quarry (as a place of cutting stones)",
		"def" : "Gozan, a province of Assyria",
		"kjv" : "Gozan"
	},
	"H1471" : {
		"lemma" : "גּוֹי",
		"xlit" : "gôwy",
		"pron" : "go'-ee",
		"derivation" : "rarely (shortened) גֹּי; apparently from the same root as H1465 (גֵּוָה) (in the sense of massing)",
		"def" : "a foreign nation; hence, a Gentile; also (figuratively) a troop of animals, or a flight of locusts",
		"kjv" : "Gentile, heathen, nation, people"
	},
	"H1472" : {
		"lemma" : "גְּוִיָּה",
		"xlit" : "gᵉvîyâh",
		"pron" : "ghev-ee-yaw'",
		"derivation" : "prolonged for H1465 (גֵּוָה)",
		"def" : "a body, whether alive or dead",
		"kjv" : "(dead) body, carcase, corpse"
	},
	"H1473" : {
		"lemma" : "גּוֹלָה",
		"xlit" : "gôwlâh",
		"pron" : "go-law'",
		"derivation" : "or (shortened) גֹּלָה; active participle feminine of H1540 (גָּלָה)",
		"def" : "exile; concretely and collectively exiles",
		"kjv" : "(carried away), captive(-ity), removing"
	},
	"H1474" : {
		"lemma" : "גּוֹלָן",
		"xlit" : "Gôwlân",
		"pron" : "go-lawn'",
		"derivation" : "from H1473 (גּוֹלָה); captive",
		"def" : "Golan, a place east of the Jordan",
		"kjv" : "Golan"
	},
	"H1475" : {
		"lemma" : "גּוּמָּץ",
		"xlit" : "gûwmmâts",
		"pron" : "goom-mawts'",
		"derivation" : "of uncertain derivation",
		"def" : "a pit",
		"kjv" : "pit"
	},
	"H1476" : {
		"lemma" : "גּוּנִי",
		"xlit" : "Gûwnîy",
		"pron" : "goo-nee'",
		"derivation" : "probably from H1598 (גָּנַן); protected",
		"def" : "Guni, the name of two Israelites",
		"kjv" : "Guni"
	},
	"H1477" : {
		"lemma" : "גּוּנִי",
		"xlit" : "Gûwnîy",
		"pron" : "goo-nee'",
		"derivation" : "patronymically from H1476 (גּוּנִי)",
		"def" : "a Gunite (collectively with article prefix) or descendants of Guni",
		"kjv" : "Gunites"
	},
	"H1478" : {
		"lemma" : "גָּוַע",
		"xlit" : "gâvaʻ",
		"pron" : "gaw-vah'",
		"derivation" : "a primitive root",
		"def" : "to breathe out, i.e. (by implication) expire",
		"kjv" : "die, be dead, give up the ghost, perish"
	},
	"H1479" : {
		"lemma" : "גּוּף",
		"xlit" : "gûwph",
		"pron" : "goof",
		"derivation" : "a primitive root",
		"def" : "properly, to hollow or arch, i.e. (figuratively) close; to shut",
		"kjv" : "shut"
	},
	"H1480" : {
		"lemma" : "גּוּפָה",
		"xlit" : "gûwphâh",
		"pron" : "goo-faw'",
		"derivation" : "from H1479 (גּוּף)",
		"def" : "a corpse (as closed to sense)",
		"kjv" : "body"
	},
	"H1481" : {
		"lemma" : "גּוּר",
		"xlit" : "gûwr",
		"pron" : "goor",
		"derivation" : "a primitive root",
		"def" : "properly, to turn aside from the road (for a lodging or any other purpose), i.e. sojourn (as a guest); also to shrink, fear (as in a strange place); also to gather forhostility (as afraid)",
		"kjv" : "abide, assemble, be afraid, dwell, fear, gather (together), inhabitant, remain, sojourn, stand in awe, (be) stranger, [idiom] surely"
	},
	"H1482" : {
		"lemma" : "גּוּר",
		"xlit" : "gûwr",
		"pron" : "goor",
		"derivation" : "or (shortened) גֻּר; perhaps from H1481 (גּוּר)",
		"def" : "a cub (as still abiding in the lair), especially of the lion",
		"kjv" : "whelp, young one"
	},
	"H1483" : {
		"lemma" : "גּוּר",
		"xlit" : "Gûwr",
		"pron" : "goor",
		"derivation" : "the same as H1482 (גּוּר)",
		"def" : "Gur, a place in Palestine",
		"kjv" : "Gur"
	},
	"H1484" : {
		"lemma" : "גּוֹר",
		"xlit" : "gôwr",
		"pron" : "gore",
		"derivation" : "or (feminine) גֹּרָה; a variation of H1482 (גּוּר)",
		"def" : "{a cub (as still abiding in the lair), especially of the lion}",
		"kjv" : "whelp"
	},
	"H1485" : {
		"lemma" : "גּוּר־בַּעַל",
		"xlit" : "Gûwr-Baʻal",
		"pron" : "goor-bah'-al",
		"derivation" : "from H1481 (גּוּר) and H1168 (בַּעַל); dwelling of Baal",
		"def" : "Gur-Baal, a place in Arabia",
		"kjv" : "Gur-baal"
	},
	"H1486" : {
		"lemma" : "גּוֹרָל",
		"xlit" : "gôwrâl",
		"pron" : "go-rawl'",
		"derivation" : "or (shortened) גֹּרָל; from an unused root meaning to be rough (as stone)",
		"def" : "properly, a pebble, i.e. a lot (small stones being used for that purpose); figuratively, a portion or destiny (as if determined by lot)",
		"kjv" : "lot"
	},
	"H1487" : {
		"lemma" : "גּוּשׁ",
		"xlit" : "gûwsh",
		"pron" : "goosh",
		"derivation" : "or rather (by permutation) גִּישׁ; of uncertain derivation",
		"def" : "a mass of earth",
		"kjv" : "clod"
	},
	"H1488" : {
		"lemma" : "גֵּז",
		"xlit" : "gêz",
		"pron" : "gaze",
		"derivation" : "from H1494 (גַּזָז)",
		"def" : "a fleece (as shorn); also mown grass",
		"kjv" : "fleece, mowing, mown grass"
	},
	"H1489" : {
		"lemma" : "גִּזְבָּר",
		"xlit" : "gizbâr",
		"pron" : "ghiz-bawr'",
		"derivation" : "of foreign derivation",
		"def" : "treasurer",
		"kjv" : "treasurer"
	},
	"H1490" : {
		"lemma" : "גִּזְבָּר",
		"xlit" : "gizbâr",
		"pron" : "ghiz-bawr'",
		"derivation" : "(Aramaic) corresponding to H1489 (גִּזְבָּר)",
		"def" : "{treasurer}",
		"kjv" : "treasurer"
	},
	"H1491" : {
		"lemma" : "גָּזָה",
		"xlit" : "gâzâh",
		"pron" : "gaw-zaw'",
		"derivation" : "a primitive root (akin to H1468 (גּוּז))",
		"def" : "to cut off, i.e. portion out",
		"kjv" : "take"
	},
	"H1492" : {
		"lemma" : "גָּזַּה",
		"xlit" : "gâzzah",
		"pron" : "gaz-zaw'",
		"derivation" : "feminine from H1494 (גַּזָז)",
		"def" : "a fleece",
		"kjv" : "fleece"
	},
	"H1493" : {
		"lemma" : "גִּזוֹנִי",
		"xlit" : "Gizôwnîy",
		"pron" : "ghee-zo-nee'",
		"derivation" : "patrial from the unused name of a place apparently in Palestine",
		"def" : "a Gizonite or inhabitant of Gizoh",
		"kjv" : "Gizonite"
	},
	"H1494" : {
		"lemma" : "גַּזָז",
		"xlit" : "gazâz",
		"pron" : "gaw-zaz'",
		"derivation" : "a primitive root (akin to H1468 (גּוּז))",
		"def" : "to cut off; specifically to shear aflock or shave the hair; figuratively to destroy an enemy",
		"kjv" : "cut off (down), poll, shave, (sheep-) shear(-er)"
	},
	"H1495" : {
		"lemma" : "גָּזֵז",
		"xlit" : "Gâzêz",
		"pron" : "gaw-zaze'",
		"derivation" : "from H1494 (גַּזָז); shearer",
		"def" : "Gazez, the name of two Israelites",
		"kjv" : "Gazez"
	},
	"H1496" : {
		"lemma" : "גָּזִית",
		"xlit" : "gâzîyth",
		"pron" : "gaw-zeeth'",
		"derivation" : "from H1491 (גָּזָה)",
		"def" : "something cut, i.e. dressed stone",
		"kjv" : "hewed, hewn stone, wrought"
	},
	"H1497" : {
		"lemma" : "גָּזַל",
		"xlit" : "gâzal",
		"pron" : "gaw-zal'",
		"derivation" : "a primitive root",
		"def" : "to pluck off; specifically to flay, strip or rob",
		"kjv" : "catch, consume, exercise (robbery), pluck (off), rob, spoil, take away (by force, violence), tear"
	},
	"H1498" : {
		"lemma" : "גָּזֵל",
		"xlit" : "gâzêl",
		"pron" : "gaw-zale'",
		"derivation" : "from H1497 (גָּזַל)",
		"def" : "robbery, or (concretely) plunder",
		"kjv" : "robbery, thing taken away by violence"
	},
	"H1499" : {
		"lemma" : "גֵּזֶל",
		"xlit" : "gêzel",
		"pron" : "ghe'-zel",
		"derivation" : "from H1497 (גָּזַל)",
		"def" : "plunder, i.e. violence",
		"kjv" : "violence, violent perverting.. gozal. See H1469 (גּוֹזָל)"
	},
	"H1500" : {
		"lemma" : "גְּזֵלָה",
		"xlit" : "gᵉzêlâh",
		"pron" : "ghez-ay-law'",
		"derivation" : "feminine of H1498 (גָּזֵל) and mean the same",
		"def" : "{robbery, or (concretely) plunder}",
		"kjv" : "that (he had robbed) (which he took violently away), spoil, violence"
	},
	"H1501" : {
		"lemma" : "גָּזָם",
		"xlit" : "gâzâm",
		"pron" : "gaw-zawm'",
		"derivation" : "from an unused root meaning to devour",
		"def" : "a kind of locust",
		"kjv" : "palmer-worm"
	},
	"H1502" : {
		"lemma" : "גַּזָּם",
		"xlit" : "Gazzâm",
		"pron" : "gaz-zawm'",
		"derivation" : "from the same as H1501 (גָּזָם); devourer",
		"def" : "Gazzam, one of the Nethinim",
		"kjv" : "Gazzam"
	},
	"H1503" : {
		"lemma" : "גֶּזַע",
		"xlit" : "gezaʻ",
		"pron" : "geh'-zah",
		"derivation" : "from an unused root meaning to cut down (trees)",
		"def" : "the trunk or stump of atree (as felled or as planted)",
		"kjv" : "stem, stock"
	},
	"H1504" : {
		"lemma" : "גָּזַר",
		"xlit" : "gâzar",
		"pron" : "gaw-zar'",
		"derivation" : "a primitive root",
		"def" : "to cut down or off; (figuratively) to destroy, divide, exclude, or decide",
		"kjv" : "cut down (off), decree, divide, snatch"
	},
	"H1505" : {
		"lemma" : "גְּזַר",
		"xlit" : "gᵉzar",
		"pron" : "ghez-ar'",
		"derivation" : "(Aramaic) corresponding to H1504 (גָּזַר)",
		"def" : "to quarry; determine",
		"kjv" : "cut out, soothsayer"
	},
	"H1506" : {
		"lemma" : "גֶּזֶר",
		"xlit" : "gezer",
		"pron" : "gheh'-zer",
		"derivation" : "from H1504 (גָּזַר)",
		"def" : "something cut off; a portion",
		"kjv" : "part, piece"
	},
	"H1507" : {
		"lemma" : "גֶּזֶר",
		"xlit" : "Gezer",
		"pron" : "gheh'-zer",
		"derivation" : "the same as H1506 (גֶּזֶר)",
		"def" : "Gezer, a place in Palestine",
		"kjv" : "Gazer, Gezer"
	},
	"H1508" : {
		"lemma" : "גִּזְרָה",
		"xlit" : "gizrâh",
		"pron" : "ghiz-raw'",
		"derivation" : "feminine of H1506 (גֶּזֶר)",
		"def" : "the figure or person (as if cut out); also an inclosure (as separated)",
		"kjv" : "polishing, separate place"
	},
	"H1509" : {
		"lemma" : "גְּזֵרָה",
		"xlit" : "gᵉzêrâh",
		"pron" : "ghez-ay-raw'",
		"derivation" : "from H1504 (גָּזַר)",
		"def" : "a desert (as separated)",
		"kjv" : "not inhabited"
	},
	"H1510" : {
		"lemma" : "גְּזֵרָה",
		"xlit" : "gᵉzêrâh",
		"pron" : "ghez-ay-raw'",
		"derivation" : "(Aramaic) from H1505 (גְּזַר) (as H1504 (גָּזַר))",
		"def" : "a decree",
		"kjv" : "decree"
	},
	"H1511" : {
		"lemma" : "גִּזְרִי",
		"xlit" : "Gizrîy",
		"pron" : "ghiz-ree'",
		"derivation" : "(in the m patrial from H1507 (גֶּזֶר); a Gezerite (collectively) or inhabitants of Gezer; but better (as in the text) bytransposition גִּזְרִיxlit Girzîy corrected to Gizrîy; patrial of H1630 (גְּרִזִים)",
		"def" : "a Grizite (collectively) or member of a native tribe in Palestine",
		"kjv" : "Gezrites"
	},
	"H1512" : {
		"lemma" : "גָּחוֹן",
		"xlit" : "gâchôwn",
		"pron" : "gaw-khone'",
		"derivation" : "probably from H1518 (גִּיחַ)lemma גּיחַ missing vowel, corrected to גִּיחַ; (compare H1521 (גִּיחוֹן))",
		"def" : "the external abdomen, belly (as the source of the faetus )",
		"kjv" : "belly"
	},
	"H1513" : {
		"lemma" : "גֶּחֶל",
		"xlit" : "gechel",
		"pron" : "geh'-khel",
		"derivation" : "or (feminine) גַּחֶלֶת; from an unused root meaning to glow or kindle",
		"def" : "an ember",
		"kjv" : "(burning) coal"
	},
	"H1514" : {
		"lemma" : "גַּחַם",
		"xlit" : "Gacham",
		"pron" : "gah'-kham",
		"derivation" : "from an unused root meaning to burn; flame",
		"def" : "Gacham, a son of Nahor",
		"kjv" : "Gaham"
	},
	"H1515" : {
		"lemma" : "גַּחַר",
		"xlit" : "Gachar",
		"pron" : "gah'-khar",
		"derivation" : "from an unused root meaning to hide; lurker",
		"def" : "Gachar, one of the Nethinim",
		"kjv" : "Gahar"
	},
	"H1516" : {
		"lemma" : "גַּיְא",
		"xlit" : "gayʼ",
		"pron" : "gah'-ee",
		"derivation" : "or (shortened) גַּי; probably (by transmutation) from the same root as H1466 (גֵּוָה) (abbreviated)",
		"def" : "a gorge (from its lofty sides; hence, narrow, but not a gully or winter-torrent)",
		"kjv" : "valley"
	},
	"H1517" : {
		"lemma" : "גִּיד",
		"xlit" : "gîyd",
		"pron" : "gheed",
		"derivation" : "probably from H1464 (גּוּד)",
		"def" : "a thong (as compressing); by analogy, a tendon",
		"kjv" : "sinew"
	},
	"H1518" : {
		"lemma" : "גִּיחַ",
		"xlit" : "gîyach",
		"pron" : "ghee'-akh",
		"derivation" : "or (shortened) גֹּחַ; a primitive root",
		"def" : "to gush forth (as water), generally to issue",
		"kjv" : "break forth, labor to bring forth, come forth, draw up, take out"
	},
	"H1519" : {
		"lemma" : "גִּיחַ",
		"xlit" : "gîyach",
		"pron" : "ghee'-akh",
		"derivation" : "(Aramaic) or (shortened) גּוּחַ; (Aramaic), corresponding to H1518 (גִּיחַ)lemma גּיחַ missing vowel, corrected to גִּיחַ",
		"def" : "to rush forth",
		"kjv" : "strive"
	},
	"H1520" : {
		"lemma" : "גִּיחַ",
		"xlit" : "Gîyach",
		"pron" : "ghee'-akh",
		"derivation" : "from H1518 (גִּיחַ)lemma גּיחַ missing vowel, corrected to גִּיחַ; a fountain",
		"def" : "Giach, a place in Palestine",
		"kjv" : "Giah"
	},
	"H1521" : {
		"lemma" : "גִּיחוֹן",
		"xlit" : "Gîychôwn",
		"pron" : "ghee-khone'",
		"derivation" : "or (shortened) גִּחוֹן; from H1518 (גִּיחַ)lemma גּיחַ missing vowel, corrected to גִּיחַ; stream",
		"def" : "Gichon, a river of Paradise; also a valley (or pool) near Jerusalem",
		"kjv" : "Gihon"
	},
	"H1522" : {
		"lemma" : "גֵּיחֲזִי",
		"xlit" : "Gêychăzîy",
		"pron" : "gay-khah-zee'",
		"derivation" : "or גֵּחֲזִי; apparently from H1516 (גַּיְא) and H2372 (חָזָה); valley of a visionary",
		"def" : "Gechazi, the servant of Elisha",
		"kjv" : "Gehazi"
	},
	"H1523" : {
		"lemma" : "גִּיל",
		"xlit" : "gîyl",
		"pron" : "gheel",
		"derivation" : "or (by permutation) גּוּל; a primitive root",
		"def" : "properly, to spin round (under the influence of any violent emotion), i.e. usually rejoice, or (as cringing) fear",
		"kjv" : "be glad, joy, be joyful, rejoice"
	},
	"H1524" : {
		"lemma" : "גִּיל",
		"xlit" : "gîyl",
		"pron" : "gheel",
		"derivation" : "from H1523 (גִּיל)",
		"def" : "a revolution (of time, i.e. an age); also joy",
		"kjv" : "[idiom] exceedingly, gladness, [idiom] greatly, joy, rejoice(-ing), sort"
	},
	"H1525" : {
		"lemma" : "גִּילָה",
		"xlit" : "gîylâh",
		"pron" : "ghee-law'",
		"derivation" : "or גִּילַת; feminine of H1524 (גִּיל)",
		"def" : "joy",
		"kjv" : "joy, rejoicing"
	},
	"H1526" : {
		"lemma" : "גִּילֹנִי",
		"xlit" : "Gîylônîy",
		"pron" : "ghee-lo-nee'",
		"derivation" : "patrial from H1542 (גִּלֹה)",
		"def" : "a Gilonite or inhabitant of Giloh",
		"kjv" : "Gilonite"
	},
	"H1527" : {
		"lemma" : "גִּינַת",
		"xlit" : "Gîynath",
		"pron" : "ghee-nath'",
		"derivation" : "of uncertain derivation",
		"def" : "Ginath, an Israelite",
		"kjv" : "Ginath"
	},
	"H1528" : {
		"lemma" : "גִּיר",
		"xlit" : "gîyr",
		"pron" : "gheer",
		"derivation" : "(Aramaic) corresponding to H1615 (גִּר)",
		"def" : "lime",
		"kjv" : "plaster"
	},
	"H1529" : {
		"lemma" : "גֵּישָׁן",
		"xlit" : "Gêyshân",
		"pron" : "gay-shawn'",
		"derivation" : "from the same as H1487 (גּוּשׁ); lumpish",
		"def" : "Geshan, an Israelite",
		"kjv" : "Geshan"
	},
	"H1530" : {
		"lemma" : "גַּל",
		"xlit" : "gal",
		"pron" : "gal",
		"derivation" : "from H1556 (גָּלַל)",
		"def" : "something rolled, i.e. a heap of stone or dung (plural ruins), by analogy, a spring of water (plural waves)",
		"kjv" : "billow, heap, spring, wave"
	},
	"H1531" : {
		"lemma" : "גֹּל",
		"xlit" : "gôl",
		"pron" : "gole",
		"derivation" : "from H1556 (גָּלַל)",
		"def" : "a cup for oil (as round)",
		"kjv" : "bowl"
	},
	"H1532" : {
		"lemma" : "גַּלָּב",
		"xlit" : "gallâb",
		"pron" : "gal-lawb'",
		"derivation" : "from an unused root meaning to shave",
		"def" : "a barber",
		"kjv" : "barber"
	},
	"H1533" : {
		"lemma" : "גִּלְבֹּעַ",
		"xlit" : "Gilbôaʻ",
		"pron" : "ghil-bo'-ah",
		"derivation" : "from H1530 (גַּל) and H1158 (בָּעָה); fountain of ebullition",
		"def" : "Gilboa, a mountain of Palestine",
		"kjv" : "Gilboa"
	},
	"H1534" : {
		"lemma" : "גַּלְגַּל",
		"xlit" : "galgal",
		"pron" : "gal-gal'",
		"derivation" : "by reduplication from H1556 (גָּלַל)",
		"def" : "a wheel; by analogy, a whirlwind; also dust (as whirled)",
		"kjv" : "heaven, rolling thing, wheel"
	},
	"H1535" : {
		"lemma" : "גַּלְגַּל",
		"xlit" : "galgal",
		"pron" : "gal-gal'",
		"derivation" : "(Aramaic) corresponding to H1534 (גַּלְגַּל)",
		"def" : "a wheel",
		"kjv" : "wheel"
	},
	"H1536" : {
		"lemma" : "גִּלְגָּל",
		"xlit" : "gilgâl",
		"pron" : "ghil-gawl'",
		"derivation" : "a variation of H1534 (גַּלְגַּל)",
		"def" : "{a wheel; by analogy, a whirlwind; also dust (as whirled)}",
		"kjv" : "wheel"
	},
	"H1537" : {
		"lemma" : "גִּלְגָּל",
		"xlit" : "Gilgâl",
		"pron" : "ghil-gawl'",
		"derivation" : "the same as H1536 (גִּלְגָּל) (with the article as a properly, noun)",
		"def" : "Gilgal, the name of three places in Palestine",
		"kjv" : "Gilgal. See also H1019 (בֵּית הַגִּלְגָּל)"
	},
	"H1538" : {
		"lemma" : "גֻּלְגֹּלֶת",
		"xlit" : "gulgôleth",
		"pron" : "gul-go'-leth",
		"derivation" : "by reduplication from H1556 (גָּלַל)",
		"def" : "a skull (as round); by implication, a head (in enumeration of persons)",
		"kjv" : "head, every man, poll, skull"
	},
	"H1539" : {
		"lemma" : "גֶּלֶד",
		"xlit" : "geled",
		"pron" : "ghe'-led",
		"derivation" : "from an unused root probably meaning to polish",
		"def" : "the (human) skin (as smooth)",
		"kjv" : "skin"
	},
	"H1540" : {
		"lemma" : "גָּלָה",
		"xlit" : "gâlâh",
		"pron" : "gaw-law'",
		"derivation" : "a primitive root",
		"def" : "to denude (especially in a disgraceful sense); by implication, to exile (captives being usually stripped); figuratively, to reveal",
		"kjv" : "[phrase] advertise, appear, bewray, bring, (carry, lead, go) captive (into captivity), depart, disclose, discover, exile, be gone, open, [idiom] plainly, publish, remove, reveal, [idiom] shamelessly, shew, [idiom] surely, tell, uncover"
	},
	"H1541" : {
		"lemma" : "גְּלָה",
		"xlit" : "gᵉlâh",
		"pron" : "ghel-aw'",
		"derivation" : "(Aramaic) or גְּלָא; (Aramaic), corresponding to H1540 (גָּלָה)",
		"def" : "{to denude (especially in a disgraceful sense); by implication, to exile (captives being usually stripped); figuratively, to reveal}",
		"kjv" : "bring over, carry away, reveal"
	},
	"H1542" : {
		"lemma" : "גִּלֹה",
		"xlit" : "Gilôh",
		"pron" : "ghee-lo'",
		"derivation" : "or (fully) גִּילֹה; from H1540 (גָּלָה); open",
		"def" : "Giloh, a place in Palestine",
		"kjv" : "Giloh"
	},
	"H1543" : {
		"lemma" : "גֻּלָּה",
		"xlit" : "gullâh",
		"pron" : "gool-law'",
		"derivation" : "feminine from H1556 (גָּלַל)",
		"def" : "a fountain, bowl or globe (all as round)",
		"kjv" : "bowl, pommel, spring"
	},
	"H1544" : {
		"lemma" : "גִּלּוּל",
		"xlit" : "gillûwl",
		"pron" : "ghil-lool'",
		"derivation" : "or (shortened) גִּלֻּל; from H1556 (גָּלַל)",
		"def" : "properly, a log (as round); by implication, an idol",
		"kjv" : "idol"
	},
	"H1545" : {
		"lemma" : "גְּלוֹם",
		"xlit" : "gᵉlôwm",
		"pron" : "ghel-ome'",
		"derivation" : "from H1563 (גָּלַם)",
		"def" : "clothing (as wrapped)",
		"kjv" : "clothes"
	},
	"H1546" : {
		"lemma" : "גָּלוּת",
		"xlit" : "gâlûwth",
		"pron" : "gaw-looth'",
		"derivation" : "feminine from H1540 (גָּלָה)",
		"def" : "captivity; concretely, exiles (collectively)",
		"kjv" : "(they that are carried away) captives(-ity)"
	},
	"H1547" : {
		"lemma" : "גָּלוּת",
		"xlit" : "gâlûwth",
		"pron" : "gaw-looth'",
		"derivation" : "(Aramaic) corresponding to H1546 (גָּלוּת)",
		"def" : "{captivity; concretely, exiles (collectively)}",
		"kjv" : "captivity"
	},
	"H1548" : {
		"lemma" : "גָּלַח",
		"xlit" : "gâlach",
		"pron" : "gaw-lakh'",
		"derivation" : "a primitive root",
		"def" : "properly, to be bald, i.e. (causatively) to shave; figuratively to lay waste",
		"kjv" : "poll, shave (off)"
	},
	"H1549" : {
		"lemma" : "גִּלָּיוֹן",
		"xlit" : "gillâyôwn",
		"pron" : "ghil-law-yone'",
		"derivation" : "or גִּלְיוֹן; from H1540 (גָּלָה)",
		"def" : "a tablet for writing (as bare); by analogy, a mirror (as a plate)",
		"kjv" : "glass, roll"
	},
	"H1550" : {
		"lemma" : "גָּלִיל",
		"xlit" : "gâlîyl",
		"pron" : "gaw-leel'",
		"derivation" : "from H1556 (גָּלַל)",
		"def" : "a valve of a folding door (as turning); also a ring (as round)",
		"kjv" : "folding, ring"
	},
	"H1551" : {
		"lemma" : "גָּלִיל",
		"xlit" : "Gâlîyl",
		"pron" : "gaw-leel'",
		"derivation" : "or (prolonged) גָּלִילָה; the same as H1550 (גָּלִיל); a circle (with the article)",
		"def" : "Galil (as a special circuit) in the North of Palestine",
		"kjv" : "Galilee"
	},
	"H1552" : {
		"lemma" : "גְלִילָה",
		"xlit" : "gᵉlîylâh",
		"pron" : "ghel-ee-law'",
		"derivation" : "feminine of H1550 (גָּלִיל)",
		"def" : "a circuit or region",
		"kjv" : "border, coast, country"
	},
	"H1553" : {
		"lemma" : "גְּלִילוֹת",
		"xlit" : "Gᵉlîylôwth",
		"pron" : "ghel-ee-lowth'",
		"derivation" : "plural of H1552 (גְלִילָה); circles",
		"def" : "Geliloth, a place in Palestine",
		"kjv" : "Geliloth"
	},
	"H1554" : {
		"lemma" : "גַּלִּים",
		"xlit" : "Gallîym",
		"pron" : "gal-leem'",
		"derivation" : "plural of H1530 (גַּל); springs",
		"def" : "Gallim, a place in Palestine",
		"kjv" : "Gallim"
	},
	"H1555" : {
		"lemma" : "גׇּלְיַת",
		"xlit" : "Golyath",
		"pron" : "gol-yath'",
		"derivation" : "perhaps from H1540 (גָּלָה); exile",
		"def" : "Goljath, a Philistine",
		"kjv" : "Goliath"
	},
	"H1556" : {
		"lemma" : "גָּלַל",
		"xlit" : "gâlal",
		"pron" : "gaw-lal'",
		"derivation" : "a primitive root",
		"def" : "to roll (literally or figuratively)",
		"kjv" : "commit, remove, roll (away, down, together), run down, seek occasion, trust, wallow"
	},
	"H1557" : {
		"lemma" : "גָּלָל",
		"xlit" : "gâlâl",
		"pron" : "gaw-lawl'",
		"derivation" : "from H1556 (גָּלַל)",
		"def" : "dung (as in balls)",
		"kjv" : "dung"
	},
	"H1558" : {
		"lemma" : "גָּלָל",
		"xlit" : "gâlâl",
		"pron" : "gaw-lawl'",
		"derivation" : "from H1556 (גָּלַל)",
		"def" : "a circumstance (as rolled around); only used adverbially, on account of",
		"kjv" : "because of, for (sake)"
	},
	"H1559" : {
		"lemma" : "גָּלָל",
		"xlit" : "Gâlâl",
		"pron" : "gaw-lawl'",
		"derivation" : "from H1556 (גָּלַל), in the sense of H1560 (גְּלָל); great",
		"def" : "Galal, the name of two Israelites",
		"kjv" : "Galal"
	},
	"H1560" : {
		"lemma" : "גְּלָל",
		"xlit" : "gᵉlâl",
		"pron" : "ghel-awl'",
		"derivation" : "(Aramaic) from a root corresponding to H1556 (גָּלַל)",
		"def" : "weight or size (as if rolled)",
		"kjv" : "great"
	},
	"H1561" : {
		"lemma" : "גֵּלֶל",
		"xlit" : "gêlel",
		"pron" : "gay'-lel",
		"derivation" : "a variation of H1557 (גָּלָל)",
		"def" : "dung (plural balls of dung)",
		"kjv" : "dung"
	},
	"H1562" : {
		"lemma" : "גִּלֲלַי",
		"xlit" : "Gilălay",
		"pron" : "ghe-lal-ah'-ee",
		"derivation" : "from H1561 (גֵּלֶל); dungy",
		"def" : "Gilalai, an Israelite",
		"kjv" : "Gilalai"
	},
	"H1563" : {
		"lemma" : "גָּלַם",
		"xlit" : "gâlam",
		"pron" : "gaw-lam'",
		"derivation" : "a primitive root",
		"def" : "to fold",
		"kjv" : "wrap together"
	},
	"H1564" : {
		"lemma" : "גֹּלֶם",
		"xlit" : "gôlem",
		"pron" : "go'-lem",
		"derivation" : "from H1563 (גָּלַם)",
		"def" : "a wrapped (and unformed mass, i.e. as the embryo)",
		"kjv" : "substance yet being unperfect"
	},
	"H1565" : {
		"lemma" : "גַּלְמוּד",
		"xlit" : "galmûwd",
		"pron" : "gal-mood'",
		"derivation" : "probably by prolonged from H1563 (גָּלַם)",
		"def" : "sterile (as wrapped up too hard); figuratively, desolate",
		"kjv" : "desolate, solitary"
	},
	"H1566" : {
		"lemma" : "גָּלַע",
		"xlit" : "gâlaʻ",
		"pron" : "gaw-lah'",
		"derivation" : "a primitive root",
		"def" : "to be obstinate",
		"kjv" : "(inter-) meddle (with)"
	},
	"H1567" : {
		"lemma" : "גַּלְעֵד",
		"xlit" : "Galʻêd",
		"pron" : "gal-ade'",
		"derivation" : "from H1530 (גַּל) and H5707 (עֵד); heap of testimony",
		"def" : "Galed, a memorial cairn East of the Jordan",
		"kjv" : "Galeed"
	},
	"H1568" : {
		"lemma" : "גִּלְעָד",
		"xlit" : "Gilʻâd",
		"pron" : "ghil-awd'",
		"derivation" : "probably from H1567 (גַּלְעֵד)",
		"def" : "Gilad, a region East of the Jordan; also the name of three Israelites",
		"kjv" : "Gilead, Gileadite"
	},
	"H1569" : {
		"lemma" : "גִּלְעָדִי",
		"xlit" : "Gilʻâdîy",
		"pron" : "ghil-aw-dee'",
		"derivation" : "patronymically from H1568 (גִּלְעָד)",
		"def" : "a Giladite or descendant of Gilad",
		"kjv" : "Gileadite"
	},
	"H1570" : {
		"lemma" : "גָּלַשׁ",
		"xlit" : "gâlash",
		"pron" : "gaw-lash'",
		"derivation" : "a primitive root",
		"def" : "probably to caper (as a goat)",
		"kjv" : "appear"
	},
	"H1571" : {
		"lemma" : "גַּם",
		"xlit" : "gam",
		"pron" : "gam",
		"derivation" : "by contraction from an unused root meaning to gather",
		"def" : "properly, assemblage; used only adverbially also, even, yea, though; often repeated as correl. both...and",
		"kjv" : "again, alike, also, (so much) as (soon), both (so)...and, but, either...or, even, for all, (in) likewise (manner), moreover, nay...neither, one, then(-refore), though, what, with, yea"
	},
	"H1572" : {
		"lemma" : "גָּמָא",
		"xlit" : "gâmâʼ",
		"pron" : "gaw-maw'",
		"derivation" : "a primitive root (literally or figuratively) to absorb",
		"def" : "to absorb",
		"kjv" : "swallow, drink"
	},
	"H1573" : {
		"lemma" : "גֹּמֶא",
		"xlit" : "gômeʼ",
		"pron" : "go'-meh",
		"derivation" : "from H1572 (גָּמָא)",
		"def" : "properly, an absorbent, i.e. the bulrush (from its porosity); specifically the papyrus",
		"kjv" : "(bul-) rush"
	},
	"H1574" : {
		"lemma" : "גֹּמֶד",
		"xlit" : "gômed",
		"pron" : "go'-med",
		"derivation" : "from an unused root apparently meaning to grasp",
		"def" : "properly, a span",
		"kjv" : "cubit"
	},
	"H1575" : {
		"lemma" : "גַּמָּד",
		"xlit" : "gammâd",
		"pron" : "gam-mawd'",
		"derivation" : "from the same as H1574 (גֹּמֶד)",
		"def" : "a warrior (as grasping weapons)",
		"kjv" : "Gammadims"
	},
	"H1576" : {
		"lemma" : "גְּמוּל",
		"xlit" : "gᵉmûwl",
		"pron" : "ghem-ool'",
		"derivation" : "from H1580 (גָּמַל)",
		"def" : "treatment, i.e. an act (of good or ill); by implication, service or requital",
		"kjv" : "[phrase] as hast served, benefit, desert, deserving, that which he hath given, recompense, reward"
	},
	"H1577" : {
		"lemma" : "גָּמוּל",
		"xlit" : "gâmûwl",
		"pron" : "gaw-mool'",
		"derivation" : "passive participle of H1580 (גָּמַל); rewarded",
		"def" : "Gamul, an Israelite",
		"kjv" : "Gamul. See also H1014 (בֵּית גָּמוּל)"
	},
	"H1578" : {
		"lemma" : "גְּמוּלָה",
		"xlit" : "gᵉmûwlâh",
		"pron" : "ghem-oo-law'",
		"derivation" : "feminine of H1576 (גְּמוּל)",
		"def" : "meaning the same",
		"kjv" : "deed, recompense, such a reward"
	},
	"H1579" : {
		"lemma" : "גִּמְזוֹ",
		"xlit" : "Gimzôw",
		"pron" : "ghim-zo'",
		"derivation" : "of uncertain derivation",
		"def" : "Gimzo, a place in Palestine",
		"kjv" : "Gimzo"
	},
	"H1580" : {
		"lemma" : "גָּמַל",
		"xlit" : "gâmal",
		"pron" : "gaw-mal'",
		"derivation" : "a primitive root",
		"def" : "to treat a person (well or ill), i.e. benefit or requite; by implication (of toil), to ripen, i.e. (specifically) to wean",
		"kjv" : "bestow on, deal bountifully, do (good), recompense, requite, reward, ripen, [phrase] serve, mean, yield"
	},
	"H1581" : {
		"lemma" : "גָּמָל",
		"xlit" : "gâmâl",
		"pron" : "gaw-mawl'",
		"derivation" : "apparently from H1580 (גָּמַל) (in the sense of labor or burden-bearing)",
		"def" : "a camel",
		"kjv" : "camel"
	},
	"H1582" : {
		"lemma" : "גְּמַלִּי",
		"xlit" : "Gᵉmallîy",
		"pron" : "ghem-al-lee'",
		"derivation" : "probably from H1581 (גָּמָל); cameldriver",
		"def" : "Gemalli, an Israelite",
		"kjv" : "Gemalli"
	},
	"H1583" : {
		"lemma" : "גַּמְלִיאֵל",
		"xlit" : "Gamlîyʼêl",
		"pron" : "gam-lee-ale'",
		"derivation" : "from H1580 (גָּמַל) and H410 (אֵל); reward of God",
		"def" : "Gamliel, an Israelite",
		"kjv" : "Gamaliel"
	},
	"H1584" : {
		"lemma" : "גָּמַר",
		"xlit" : "gâmar",
		"pron" : "gaw-mar'",
		"derivation" : "a primitive root",
		"def" : "to end (in the sense of completion or failure)",
		"kjv" : "cease, come to an end, fail, perfect, perform"
	},
	"H1585" : {
		"lemma" : "גְּמַר",
		"xlit" : "gᵉmar",
		"pron" : "ghem-ar'",
		"derivation" : "(Aramaic) corresponding to H1584 (גָּמַר)",
		"def" : "{to end (in the sense of completion or failure)}",
		"kjv" : "perfect"
	},
	"H1586" : {
		"lemma" : "גֹּמֶר",
		"xlit" : "Gômer",
		"pron" : "go'-mer",
		"derivation" : "from H1584 (גָּמַר); completion",
		"def" : "Gomer, the name of a son of Japheth and of his descendants; also of a Hebrewess",
		"kjv" : "Gomer"
	},
	"H1587" : {
		"lemma" : "גְּמַרְיָה",
		"xlit" : "Gᵉmaryâh",
		"pron" : "ghem-ar-yaw'",
		"derivation" : "or גְּמַרְיָהוּ; from H1584 (גָּמַר) and H3050 (יָהּ); Jah has perfected",
		"def" : "Gemarjah, the name of two Israelites",
		"kjv" : "Gemariah"
	},
	"H1588" : {
		"lemma" : "גַּן",
		"xlit" : "gan",
		"pron" : "gan",
		"derivation" : "from H1598 (גָּנַן)",
		"def" : "a garden (as fenced)",
		"kjv" : "garden"
	},
	"H1589" : {
		"lemma" : "גָּנַב",
		"xlit" : "gânab",
		"pron" : "gaw-nab'",
		"derivation" : "a primitive root",
		"def" : "to thieve (literally or figuratively); by implication, to deceive",
		"kjv" : "carry away, [idiom] indeed, secretly bring, steal (away), get by stealth"
	},
	"H1590" : {
		"lemma" : "גַּנָּב",
		"xlit" : "gannâb",
		"pron" : "gaw-nab'",
		"derivation" : "from H1589 (גָּנַב)",
		"def" : "a stealer",
		"kjv" : "thief"
	},
	"H1591" : {
		"lemma" : "גְּנֵבָה",
		"xlit" : "gᵉnêbâh",
		"pron" : "ghen-ay-baw'",
		"derivation" : "from H1589 (גָּנַב)",
		"def" : "stealing, i.e. (concretely) something stolen",
		"kjv" : "theft"
	},
	"H1592" : {
		"lemma" : "גְּנֻבַת",
		"xlit" : "Gᵉnubath",
		"pron" : "ghen-oo-bath'",
		"derivation" : "from H1589 (גָּנַב); theft",
		"def" : "Genubath, an Edomitish prince",
		"kjv" : "Genubath"
	},
	"H1593" : {
		"lemma" : "גַּנָּה",
		"xlit" : "gannâh",
		"pron" : "gan-naw'",
		"derivation" : "feminine of H1588 (גַּן)",
		"def" : "a garden",
		"kjv" : "garden"
	},
	"H1594" : {
		"lemma" : "גִּנָּה",
		"xlit" : "ginnâh",
		"pron" : "ghin-naw'",
		"derivation" : "another form for H1593 (גַּנָּה)",
		"def" : "{a garden}",
		"kjv" : "garden"
	},
	"H1595" : {
		"lemma" : "גֶּנֶז",
		"xlit" : "genez",
		"pron" : "gheh'-nez",
		"derivation" : "from an unused root meaning to store",
		"def" : "treasure; by implication, a coffer",
		"kjv" : "chest, treasury"
	},
	"H1596" : {
		"lemma" : "גְּנַז",
		"xlit" : "gᵉnaz",
		"pron" : "ghen-az'",
		"derivation" : "(Aramaic) corresponding to H1595 (גֶּנֶז)",
		"def" : "treasure",
		"kjv" : "treasure"
	},
	"H1597" : {
		"lemma" : "גִּנְזַךְ",
		"xlit" : "ginzak",
		"pron" : "ghin-zak'",
		"derivation" : "prolonged from H1595 (גֶּנֶז)",
		"def" : "a treasury",
		"kjv" : "treasury"
	},
	"H1598" : {
		"lemma" : "גָּנַן",
		"xlit" : "gânan",
		"pron" : "gaw-nan'",
		"derivation" : "a primitive root",
		"def" : "to hedge about, i.e. (generally) protect",
		"kjv" : "defend"
	},
	"H1599" : {
		"lemma" : "גִּנְּתוֹן",
		"xlit" : "Ginnᵉthôwn",
		"pron" : "ghin-neth-one",
		"derivation" : "or גִּנְּתוֹ; from H1598 (גָּנַן); gardener",
		"def" : "Ginnethon or Ginnetho, an Israelite",
		"kjv" : "Ginnetho, Ginnethon"
	},
	"H1600" : {
		"lemma" : "גָּעָה",
		"xlit" : "gâʻâh",
		"pron" : "gaw-aw'",
		"derivation" : "a primitive root",
		"def" : "to bellow (as cattle)",
		"kjv" : "low"
	},
	"H1601" : {
		"lemma" : "גֹּעָה",
		"xlit" : "Gôʻâh",
		"pron" : "go-aw'",
		"derivation" : "feminine active participle of H1600 (גָּעָה); lowing",
		"def" : "Goah, a place near Jerusalem",
		"kjv" : "Goath"
	},
	"H1602" : {
		"lemma" : "גָּעַל",
		"xlit" : "gâʻal",
		"pron" : "gaw-al'",
		"derivation" : "a primitive root",
		"def" : "to detest; by implication, to reject",
		"kjv" : "abhor, fail, lothe, vilely cast away"
	},
	"H1603" : {
		"lemma" : "גַּעַל",
		"xlit" : "Gaʻal",
		"pron" : "gah'-al",
		"derivation" : "from H1602 (גָּעַל); loathing",
		"def" : "Gaal, an Israelite",
		"kjv" : "Gaal"
	},
	"H1604" : {
		"lemma" : "גֹּעַל",
		"xlit" : "gôʻal",
		"pron" : "go'-al",
		"derivation" : "from H1602 (גָּעַל)",
		"def" : "abhorrence",
		"kjv" : "loathing"
	},
	"H1605" : {
		"lemma" : "גָּעַר",
		"xlit" : "gâʻar",
		"pron" : "gaw-ar'",
		"derivation" : "a primitive root",
		"def" : "to chide",
		"kjv" : "corrupt, rebuke, reprove"
	},
	"H1606" : {
		"lemma" : "גְּעָרָה",
		"xlit" : "gᵉʻârâh",
		"pron" : "gheh-aw-raw'",
		"derivation" : "from H1605 (גָּעַר)",
		"def" : "a chiding",
		"kjv" : "rebuke(-ing), reproof"
	},
	"H1607" : {
		"lemma" : "גָּעַשׁ",
		"xlit" : "gâʻash",
		"pron" : "gaw-ash'",
		"derivation" : "a primitive root",
		"def" : "to agitate violently",
		"kjv" : "move, shake, toss, trouble"
	},
	"H1608" : {
		"lemma" : "גַּעַשׁ",
		"xlit" : "Gaʻash",
		"pron" : "ga'-ash",
		"derivation" : "from H1607 (גָּעַשׁ); a quaking",
		"def" : "Gaash, a hill in Palestine",
		"kjv" : "Gaash"
	},
	"H1609" : {
		"lemma" : "גַּעְתָּם",
		"xlit" : "Gaʻtâm",
		"pron" : "gah-tawm'",
		"derivation" : "of uncertain derivation",
		"def" : "Gatam, an Edomite",
		"kjv" : "Gatam"
	},
	"H1610" : {
		"lemma" : "גַּף",
		"xlit" : "gaph",
		"pron" : "gaf",
		"derivation" : "from an unused root meaning to arch",
		"def" : "the back; by extension the body or self",
		"kjv" : "[phrase] highest places, himself"
	},
	"H1611" : {
		"lemma" : "גַּף",
		"xlit" : "gaph",
		"pron" : "gaf",
		"derivation" : "(Aramaic) corresponding to H1610 (גַּף)",
		"def" : "a wing",
		"kjv" : "wing"
	},
	"H1612" : {
		"lemma" : "גֶּפֶן",
		"xlit" : "gephen",
		"pron" : "gheh'-fen",
		"derivation" : "from an unused root meaning to bend",
		"def" : "a vine (as twining), especially the grape",
		"kjv" : "vine, tree"
	},
	"H1613" : {
		"lemma" : "גֹּפֶר",
		"xlit" : "gôpher",
		"pron" : "go'-fer",
		"derivation" : "from an unused root, probably meaning to house in",
		"def" : "a kind of tree or wood (as used for building), apparently the cypress",
		"kjv" : "gopher"
	},
	"H1614" : {
		"lemma" : "גׇּפְרִית",
		"xlit" : "gophrîyth",
		"pron" : "gof-reeth'",
		"derivation" : "probably feminine of H1613 (גֹּפֶר)",
		"def" : "properly, cypress-resin; by analogy, sulphur (as equally inflammable)",
		"kjv" : "brimstone"
	},
	"H1615" : {
		"lemma" : "גִּר",
		"xlit" : "gir",
		"pron" : "gheer",
		"derivation" : "perhaps from H3564 (כּוּר)",
		"def" : "lime (from being burned in a kiln)",
		"kjv" : "chalk(-stone)"
	},
	"H1616" : {
		"lemma" : "גֵּר",
		"xlit" : "gêr",
		"pron" : "gare",
		"derivation" : "or (fully) geyr (gare); from H1481 (גּוּר)",
		"def" : "properly, a guest; by implication, a foreigner",
		"kjv" : "alien, sojourner, stranger"
	},
	"H1617" : {
		"lemma" : "גֵּרָא",
		"xlit" : "Gêrâʼ",
		"pron" : "gay-raw'",
		"derivation" : "perhaps from H1626 (גֵּרָה); a grain",
		"def" : "Gera, the name of six Israelites",
		"kjv" : "Gera"
	},
	"H1618" : {
		"lemma" : "גָּרָב",
		"xlit" : "gârâb",
		"pron" : "gaw-rawb'",
		"derivation" : "from an unused root meaning to scratch",
		"def" : "scurf (from itching)",
		"kjv" : "scab, scurvy"
	},
	"H1619" : {
		"lemma" : "גָּרֵב",
		"xlit" : "Gârêb",
		"pron" : "gaw-rabe'",
		"derivation" : "from the same as H1618 (גָּרָב); scabby",
		"def" : "Gareb, the name of an Israelite, also of a hill near Jerusalem",
		"kjv" : "Gareb"
	},
	"H1620" : {
		"lemma" : "גַּרְגַּר",
		"xlit" : "gargar",
		"pron" : "gar-gar'",
		"derivation" : "by reduplication from H1641 (גָּרַר)",
		"def" : "a berry (as if a pellet of rumination)",
		"kjv" : "berry"
	},
	"H1621" : {
		"lemma" : "גַּרְגְּרוֹת",
		"xlit" : "gargᵉrôwth",
		"pron" : "gar-gher-owth'",
		"derivation" : "feminine plural from H1641 (גָּרַר)",
		"def" : "the throat (as used in rumination)",
		"kjv" : "neck"
	},
	"H1622" : {
		"lemma" : "גִּרְגָּשִׁי",
		"xlit" : "Girgâshîy",
		"pron" : "ghir-gaw-shee'",
		"derivation" : "patrial from an unused name (of uncertain derivation)",
		"def" : "a Girgashite, one of the native tribes of Canaan",
		"kjv" : "Girgashite, Girgasite"
	},
	"H1623" : {
		"lemma" : "גָּרַד",
		"xlit" : "gârad",
		"pron" : "gaw-rad'",
		"derivation" : "a primitive root",
		"def" : "to abrade",
		"kjv" : "scrape"
	},
	"H1624" : {
		"lemma" : "גָּרָה",
		"xlit" : "gârâh",
		"pron" : "gaw-raw'",
		"derivation" : "a primitive root",
		"def" : "properly, to grate, i.e. (figuratively) to anger",
		"kjv" : "contend, meddle, stir up, strive"
	},
	"H1625" : {
		"lemma" : "גֵּרָה",
		"xlit" : "gêrâh",
		"pron" : "gay-raw'",
		"derivation" : "from H1641 (גָּרַר)",
		"def" : "the cud (as scraping the throat)",
		"kjv" : "cud"
	},
	"H1626" : {
		"lemma" : "גֵּרָה",
		"xlit" : "gêrâh",
		"pron" : "gay-raw'",
		"derivation" : "from H1641 (גָּרַר) (as in H1625 (גֵּרָה)); properly, (like H1620 (גַּרְגַּר)) a kernel (round as if scraped), i.e",
		"def" : "a gerah or small weight (and coin)",
		"kjv" : "gerah"
	},
	"H1627" : {
		"lemma" : "גָּרוֹן",
		"xlit" : "gârôwn",
		"pron" : "gaw-rone'",
		"derivation" : "or (shortened) גָּרֹן; from H1641 (גָּרַר); (compare H1621 (גַּרְגְּרוֹת))",
		"def" : "the throat (as roughened by swallowing)",
		"kjv" : "[idiom] aloud, mouth, neck, throat"
	},
	"H1628" : {
		"lemma" : "גֵּרוּת",
		"xlit" : "gêrûwth",
		"pron" : "gay-rooth'",
		"derivation" : "from H1481 (גּוּר)",
		"def" : "a (temporary) residence",
		"kjv" : "habitation"
	},
	"H1629" : {
		"lemma" : "גָּרַז",
		"xlit" : "gâraz",
		"pron" : "gaw-raz'",
		"derivation" : "a primitive root",
		"def" : "to cut off",
		"kjv" : "cut off"
	},
	"H1630" : {
		"lemma" : "גְּרִזִים",
		"xlit" : "Gᵉrizîym",
		"pron" : "gher-ee-zeem'",
		"derivation" : "plural of an unused noun from H1629 (גָּרַז) (compare H1511 (גִּזְרִי)), cut up (i.e. rocky)",
		"def" : "Gerizim, a mountain of Palestine",
		"kjv" : "Gerizim"
	},
	"H1631" : {
		"lemma" : "גַּרְזֶן",
		"xlit" : "garzen",
		"pron" : "gar-zen'",
		"derivation" : "from H1629 (גָּרַז)",
		"def" : "an axe",
		"kjv" : "ax"
	},
	"H1632" : {
		"lemma" : "גָּרֹל",
		"xlit" : "gârôl",
		"pron" : "gaw-role'",
		"derivation" : "from the same as H1486 (גּוֹרָל)",
		"def" : "harsh",
		"kjv" : "man of great (as in the margin which reads H1419 (גָּדוֹל))"
	},
	"H1633" : {
		"lemma" : "גָּרַם",
		"xlit" : "gâram",
		"pron" : "gaw-ram'",
		"derivation" : "a primitive root; to be spare or skeleton-like; used only as a denominative from H1634 (גֶּרֶם)",
		"def" : "(causative) to bone, i.e. denude (by extensive, craunch) the bones",
		"kjv" : "gnaw the bones, break"
	},
	"H1634" : {
		"lemma" : "גֶּרֶם",
		"xlit" : "gerem",
		"pron" : "gheh'-rem",
		"derivation" : "from H1633 (גָּרַם)",
		"def" : "a bone (as the skeleton of the body); hence, self, i.e. (figuratively) very",
		"kjv" : "bone, strong, top"
	},
	"H1635" : {
		"lemma" : "גֶּרֶם",
		"xlit" : "gerem",
		"pron" : "gheh'-rem",
		"derivation" : "(Aramaic) corresponding to H1634 (גֶּרֶם)",
		"def" : "a bone",
		"kjv" : "bone"
	},
	"H1636" : {
		"lemma" : "גַּרְמִי",
		"xlit" : "Garmîy",
		"pron" : "gar-mee'",
		"derivation" : "from H1634 (גֶּרֶם)",
		"def" : "bony, i.e. strong",
		"kjv" : "Garmite"
	},
	"H1637" : {
		"lemma" : "גֹּרֶן",
		"xlit" : "gôren",
		"pron" : "go'-ren",
		"derivation" : "from an unused root meaning to smooth",
		"def" : "a threshing-floor (as made even); by analogy, any open area",
		"kjv" : "(barn, corn, threshing-) floor, (threshing-, void) place"
	},
	"H1638" : {
		"lemma" : "גָּרַס",
		"xlit" : "gâraç",
		"pron" : "gaw-ras'",
		"derivation" : "a primitive root",
		"def" : "to crush; also (intransitively and figuratively) to dissolve",
		"kjv" : "break"
	},
	"H1639" : {
		"lemma" : "גָּרַע",
		"xlit" : "gâraʻ",
		"pron" : "gaw-rah'",
		"derivation" : "a primitive root",
		"def" : "to scrape off; by implication, to shave, remove, lessen, withhold",
		"kjv" : "abate, clip, (di-) minish, do (take) away, keep back, restrain, make small, withdraw"
	},
	"H1640" : {
		"lemma" : "גָּרַף",
		"xlit" : "gâraph",
		"pron" : "gaw-raf'",
		"derivation" : "a primitive root",
		"def" : "to bear off violently",
		"kjv" : "sweep away"
	},
	"H1641" : {
		"lemma" : "גָּרַר",
		"xlit" : "gârar",
		"pron" : "gaw-rar'",
		"derivation" : "a primitive root",
		"def" : "to drag off roughly; by implication, to bring up the cud (i.e. ruminate); by analogy, to saw",
		"kjv" : "catch, chew, [idiom] continuing, destroy, saw"
	},
	"H1642" : {
		"lemma" : "גְּרָר",
		"xlit" : "Gᵉrâr",
		"pron" : "gher-awr'",
		"derivation" : "probably from H1641 (גָּרַר); a rolling country",
		"def" : "Gerar, a Philistine city",
		"kjv" : "Gerar"
	},
	"H1643" : {
		"lemma" : "גֶּרֶשׂ",
		"xlit" : "geres",
		"pron" : "gheh'-res",
		"derivation" : "from an unused root meaning to husk",
		"def" : "a kernel (collectively), i.e. grain",
		"kjv" : "beaten corn"
	},
	"H1644" : {
		"lemma" : "גָּרַשׁ",
		"xlit" : "gârash",
		"pron" : "gaw-rash'",
		"derivation" : "a primitive root",
		"def" : "to drive out from a possession; especially to expatriate or divorce",
		"kjv" : "cast up (out), divorced (woman), drive away (forth, out), expel, [idiom] surely put away, trouble, thrust out"
	},
	"H1645" : {
		"lemma" : "גֶּרֶשׁ",
		"xlit" : "geresh",
		"pron" : "gheh'-resh",
		"derivation" : "from H1644 (גָּרַשׁ)",
		"def" : "produce (as if expelled)",
		"kjv" : "put forth"
	},
	"H1646" : {
		"lemma" : "גְרֻשָׁה",
		"xlit" : "gᵉrushâh",
		"pron" : "gher-oo-shaw'",
		"derivation" : "feminine passive participle of H1644 (גָּרַשׁ)",
		"def" : "(abstractly) dispossession",
		"kjv" : "exaction"
	},
	"H1647" : {
		"lemma" : "גֵּרְשֹׁם",
		"xlit" : "Gêrᵉshôm",
		"pron" : "gay-resh-ome'",
		"derivation" : "for H1648 (גֵּרְשׁוֹן)",
		"def" : "Gereshom, the name of four Israelites",
		"kjv" : "Gershom"
	},
	"H1648" : {
		"lemma" : "גֵּרְשׁוֹן",
		"xlit" : "Gêrᵉshôwn",
		"pron" : "gay-resh-one'",
		"derivation" : "or גֵּרְשׁוֹם; from H1644 (גָּרַשׁ); a refugee",
		"def" : "Gereshon or Gereshom, an Israelite",
		"kjv" : "Gershon, Gershom"
	},
	"H1649" : {
		"lemma" : "גֵּרְשֻׁנִּי",
		"xlit" : "Gêrᵉshunnîy",
		"pron" : "gay-resh-oon-nee'",
		"derivation" : "patronymically from H1648 (גֵּרְשׁוֹן)",
		"def" : "a Gereshonite or descendant of Gereshon",
		"kjv" : "Gershonite, sons of Gershon"
	},
	"H1650" : {
		"lemma" : "גְּשׁוּר",
		"xlit" : "Gᵉshûwr",
		"pron" : "ghesh-oor'",
		"derivation" : "from an unused root (meaning to join); bridge",
		"def" : "Geshur, a district of Syria",
		"kjv" : "Geshur, Geshurite"
	},
	"H1651" : {
		"lemma" : "גְּשׁוּרִי",
		"xlit" : "Gᵉshûwrîy",
		"pron" : "ghe-shoo-ree'",
		"derivation" : "patrial from H1650 (גְּשׁוּר)",
		"def" : "a Geshurite (also collectively) or inhabitants of Geshur",
		"kjv" : "Geshuri, Geshurites"
	},
	"H1652" : {
		"lemma" : "גָּשַׁם",
		"xlit" : "gâsham",
		"pron" : "gaw-sham'",
		"derivation" : "a primitive root",
		"def" : "to shower violently",
		"kjv" : "(cause to) rain"
	},
	"H1653" : {
		"lemma" : "גֶּשֶׁם",
		"xlit" : "geshem",
		"pron" : "gheh'-shem",
		"derivation" : "from H1652 (גָּשַׁם)",
		"def" : "a shower",
		"kjv" : "rain, shower"
	},
	"H1654" : {
		"lemma" : "גֶּשֶׁם",
		"xlit" : "Geshem",
		"pron" : "gheh'-shem",
		"derivation" : "or (prolonged) גַּשְׁמוּ; the same as H1653 (גֶּשֶׁם)",
		"def" : "Geshem or Gashmu, an Arabian",
		"kjv" : "Geshem, Gashmu"
	},
	"H1655" : {
		"lemma" : "גֶּשֶׁם",
		"xlit" : "geshem",
		"pron" : "gheh'-shem",
		"derivation" : "(Aramaic) apparently the same as H1653 (גֶּשֶׁם)",
		"def" : "used in a peculiar sense, the body (probably for the (figuratively) idea of a hard rain)",
		"kjv" : "body"
	},
	"H1656" : {
		"lemma" : "גֹּשֶׁם",
		"xlit" : "gôshem",
		"pron" : "go'-shem",
		"derivation" : "from H1652 (גָּשַׁם); equivalent to H1653 (גֶּשֶׁם)",
		"def" : "{a shower}",
		"kjv" : "rained upon"
	},
	"H1657" : {
		"lemma" : "גֹּשֶׁן",
		"xlit" : "Gôshen",
		"pron" : "go'-shen",
		"derivation" : "probably of Egyptian origin",
		"def" : "Goshen, the residence of the Israelites in Egypt; also a place in Palestine",
		"kjv" : "Goshen"
	},
	"H1658" : {
		"lemma" : "גִּשְׁפָּא",
		"xlit" : "Gishpâʼ",
		"pron" : "ghish-paw'",
		"derivation" : "of uncertain derivation",
		"def" : "Gishpa, an Israelite",
		"kjv" : "Gispa"
	},
	"H1659" : {
		"lemma" : "גָּשַׁשׁ",
		"xlit" : "gâshash",
		"pron" : "gaw-shash'",
		"derivation" : "a primitive root; apparently",
		"def" : "to feel about",
		"kjv" : "grope"
	},
	"H1660" : {
		"lemma" : "גַּת",
		"xlit" : "gath",
		"pron" : "gath",
		"derivation" : "probably from H5059 (נָגַן) (in the sense of treading out grapes)",
		"def" : "a wine-press (or vat for holding the grapes in pressing them)",
		"kjv" : "(wine-) press (fat)"
	},
	"H1661" : {
		"lemma" : "גַּת",
		"xlit" : "Gath",
		"pron" : "gath",
		"derivation" : "the same as H1660 (גַּת)",
		"def" : "Gath, a Philistine city",
		"kjv" : "Gath"
	},
	"H1662" : {
		"lemma" : "גַּת־הַחֵפֶר",
		"xlit" : "Gath-ha-Chêpher",
		"pron" : "gath-hah-khay'-fer",
		"derivation" : "or (abridged) גִּתָּה־חֵפֶר; from H1660 (גַּת) and H2658 (חָפַר) with the article inserted; wine-press of (the) well",
		"def" : "Gath-Chepher, a place in Palestine",
		"kjv" : "Gath-kephr, Gittah-kephr"
	},
	"H1663" : {
		"lemma" : "גִּתִּי",
		"xlit" : "Gittîy",
		"pron" : "ghit-tee'",
		"derivation" : "patrial from H1661 (גַּת)",
		"def" : "a Gittite or inhabitant of Gath",
		"kjv" : "Gittite"
	},
	"H1664" : {
		"lemma" : "גִּתַּיִם",
		"xlit" : "Gittayim",
		"pron" : "ghit-tah'-yim",
		"derivation" : "dual of H1660 (גַּת); double winepress",
		"def" : "Gittajim, a place in Palestine",
		"kjv" : "Gittaim"
	},
	"H1665" : {
		"lemma" : "גִּתִּית",
		"xlit" : "Gittîyth",
		"pron" : "ghit-teeth'",
		"derivation" : "feminine of H1663 (גִּתִּי)",
		"def" : "a Gittite harp",
		"kjv" : "Gittith"
	},
	"H1666" : {
		"lemma" : "גֶּתֶר",
		"xlit" : "Gether",
		"pron" : "gheh'-ther",
		"derivation" : "of uncertain derivation",
		"def" : "Gether, a son of Aram, and the region settled by him",
		"kjv" : "Gether"
	},
	"H1667" : {
		"lemma" : "גַּת־רִמּוֹן",
		"xlit" : "Gath-Rimmôwn",
		"pron" : "gath-rim-mone'",
		"derivation" : "from H1660 (גַּת) and H7416 (רִמּוֹן); wine-press of (the) pomegranate",
		"def" : "Gath-Rimmon, a place in Palestine",
		"kjv" : "Gath-rimmon. d"
	},
	"H1668" : {
		"lemma" : "דָּא",
		"xlit" : "dâʼ",
		"pron" : "daw",
		"derivation" : "(Aramaic) corresponding to H2088 (זֶה)",
		"def" : "this",
		"kjv" : "one..another, this"
	},
	"H1669" : {
		"lemma" : "דָּאַב",
		"xlit" : "dâʼab",
		"pron" : "daw-ab'",
		"derivation" : "a primitive root",
		"def" : "to pine",
		"kjv" : "mourn, sorrow(-ful)"
	},
	"H1670" : {
		"lemma" : "דְּאָבָה",
		"xlit" : "dᵉʼâbâh",
		"pron" : "deh-aw-baw'",
		"derivation" : "from H1669 (דָּאַב)",
		"def" : "properly, pining; by analogy, fear",
		"kjv" : "sorrow"
	},
	"H1671" : {
		"lemma" : "דְּאָבוֹן",
		"xlit" : "dᵉʼâbôwn",
		"pron" : "deh-aw-bone'",
		"derivation" : "from H1669 (דָּאַב)",
		"def" : "pining",
		"kjv" : "sorrow"
	},
	"H1672" : {
		"lemma" : "דָּאַג",
		"xlit" : "dâʼag",
		"pron" : "daw-ag'",
		"derivation" : "a primitive root",
		"def" : "be anxious",
		"kjv" : "be afraid (careful, sorry), sorrow, take thought"
	},
	"H1673" : {
		"lemma" : "דֹּאֵג",
		"xlit" : "Dôʼêg",
		"pron" : "do-ayg'",
		"derivation" : "or (fully) דּוֹאֵג; active participle of H1672 (דָּאַג); anxious",
		"def" : "Doeg, an Edomite",
		"kjv" : "Doeg"
	},
	"H1674" : {
		"lemma" : "דְּאָגָה",
		"xlit" : "dᵉʼâgâh",
		"pron" : "deh-aw-gaw'",
		"derivation" : "from H1672 (דָּאַג)",
		"def" : "anxiety",
		"kjv" : "care(-fulness), fear, heaviness, sorrow"
	},
	"H1675" : {
		"lemma" : "דָּאָה",
		"xlit" : "dâʼâh",
		"pron" : "daw-aw'",
		"derivation" : "a primitive root",
		"def" : "to dart, i.e. fly rapidly",
		"kjv" : "fly"
	},
	"H1676" : {
		"lemma" : "דָּאָה",
		"xlit" : "dâʼâh",
		"pron" : "daw-aw'",
		"derivation" : "from H1675 (דָּאָה)",
		"def" : "the kite (from its rapid flight)",
		"kjv" : "vulture. See H7201 (רָאָה)"
	},
	"H1677" : {
		"lemma" : "דֹּב",
		"xlit" : "dôb",
		"pron" : "dobe",
		"derivation" : "or (fully) דּוֹב; from H1680 (דָּבַב)",
		"def" : "the bear (as slow)",
		"kjv" : "bear"
	},
	"H1678" : {
		"lemma" : "דֹּב",
		"xlit" : "dôb",
		"pron" : "dobe",
		"derivation" : "(Aramaic) corresponding to H1677 (דֹּב)",
		"def" : "{the bear (as slow)}",
		"kjv" : "bear"
	},
	"H1679" : {
		"lemma" : "דֹּבֶא",
		"xlit" : "dôbeʼ",
		"pron" : "do'-beh",
		"derivation" : "from an unused root (compare H1680 (דָּבַב)) (probably meaning to be sluggish, i.e. restful)",
		"def" : "quiet",
		"kjv" : "strength"
	},
	"H1680" : {
		"lemma" : "דָּבַב",
		"xlit" : "dâbab",
		"pron" : "daw-bab'",
		"derivation" : "a primitive root (compare H1679 (דֹּבֶא))",
		"def" : "to move slowly, i.e. glide",
		"kjv" : "cause to speak"
	},
	"H1681" : {
		"lemma" : "דִּבָּה",
		"xlit" : "dibbâh",
		"pron" : "dib-baw'",
		"derivation" : "from H1680 (דָּבַב) (in the sense of furtive motion)",
		"def" : "slander",
		"kjv" : "defaming, evil report, infamy, slander"
	},
	"H1682" : {
		"lemma" : "דְּבוֹרָה",
		"xlit" : "dᵉbôwrâh",
		"pron" : "deb-o-raw'",
		"derivation" : "or (shortened) דְּבֹרָה; from H1696 (דָבַר) (in the sense of orderly motion)",
		"def" : "the bee (from its systematic instincts)",
		"kjv" : "bee"
	},
	"H1683" : {
		"lemma" : "דְּבּוֹרָה",
		"xlit" : "Dᵉbôwrâh",
		"pron" : "deb-o-raw'",
		"derivation" : "or (shortened) דְּבֹרָה; the same as H1682 (דְּבוֹרָה)",
		"def" : "Deborah, the name of two Hebrewesses",
		"kjv" : "Deborah"
	},
	"H1684" : {
		"lemma" : "דְּבַח",
		"xlit" : "dᵉbach",
		"pron" : "deb-akh'",
		"derivation" : "(Aramaic) corresponding to H2076 (זָבַח)",
		"def" : "to sacrifice (an animal)",
		"kjv" : "offer (sacrifice)"
	},
	"H1685" : {
		"lemma" : "דְּבַח",
		"xlit" : "dᵉbach",
		"pron" : "deb-akh'",
		"derivation" : "(Aramaic) from H1684 (דְּבַח)",
		"def" : "a sacrifice",
		"kjv" : "sacrifice"
	},
	"H1686" : {
		"lemma" : "דִּבְיוֹן",
		"xlit" : "dibyôwn",
		"pron" : "dib-yone'",
		"derivation" : "in the margin for the textual reading, חֶרְיוֹן; both, (in the plural only and) of uncertain derivation",
		"def" : "probably some cheap vegetable, perhaps a bulbous root",
		"kjv" : "dove's dung"
	},
	"H1687" : {
		"lemma" : "דְּבִיר",
		"xlit" : "dᵉbîyr",
		"pron" : "deb-eer'",
		"derivation" : "or (shortened) דְּבִר; from H1696 (דָבַר) (apparently in the sense of oracle)",
		"def" : "the shrine or innermost part of the sanctuary",
		"kjv" : "oracle"
	},
	"H1688" : {
		"lemma" : "דְּבִיר",
		"xlit" : "Dᵉbîyr",
		"pron" : "deb-eer'",
		"derivation" : "or (shortened) דְּבִר; (Joshua 13:26 (but see H3810 (לֹא דְבַר))), the same as H1687 (דְּבִיר)",
		"def" : "Debir, the name of an Amoritish king and of two places in Palestine",
		"kjv" : "Debir"
	},
	"H1689" : {
		"lemma" : "דִּבְלָה",
		"xlit" : "Diblâh",
		"pron" : "dib-law'",
		"derivation" : "probably an orthographical error for H7247 (רִבְלָה)",
		"def" : "Diblah, a place in Syria",
		"kjv" : "Diblath"
	},
	"H1690" : {
		"lemma" : "דְּבֵלָה",
		"xlit" : "dᵉbêlâh",
		"pron" : "deb-ay-law'",
		"derivation" : "from an unused root (akin to H2082 (זָבַל)) probably meaning to press together",
		"def" : "a cake of pressed figs",
		"kjv" : "cake (lump) of figs"
	},
	"H1691" : {
		"lemma" : "דִּבְלַיִם",
		"xlit" : "Diblayim",
		"pron" : "dib-lah'-yim",
		"derivation" : "dual from the masculine of H1690 (דְּבֵלָה); two cakes",
		"def" : "Diblajim, a symbolic name",
		"kjv" : "Diblaim"
	},
	"H1692" : {
		"lemma" : "דָּבַק",
		"xlit" : "dâbaq",
		"pron" : "daw-bak'",
		"derivation" : "a primitive root",
		"def" : "properly, to impinge, i.e. cling or adhere; figuratively, to catch by pursuit",
		"kjv" : "abide fast, cleave (fast together), follow close (hard after), be joined (together), keep (fast), overtake, pursue hard, stick, take"
	},
	"H1693" : {
		"lemma" : "דְּבַק",
		"xlit" : "dᵉbaq",
		"pron" : "deb-ak'",
		"derivation" : "(Aramaic) corresponding to H1692 (דָּבַק)",
		"def" : "to stick to",
		"kjv" : "cleave"
	},
	"H1694" : {
		"lemma" : "דֶּבֶק",
		"xlit" : "debeq",
		"pron" : "deh'-bek",
		"derivation" : "from H1692 (דָּבַק)",
		"def" : "a joint; by implication, solder",
		"kjv" : "joint, solder"
	},
	"H1695" : {
		"lemma" : "דָּבֵק",
		"xlit" : "dâbêq",
		"pron" : "daw-bake'",
		"derivation" : "from H1692 (דָּבַק)",
		"def" : "adhering",
		"kjv" : "cleave, joining, stick closer"
	},
	"H1696" : {
		"lemma" : "דָבַר",
		"xlit" : "dâbar",
		"pron" : "daw-bar'",
		"derivation" : "a primitive root",
		"def" : "perhaps properly, to arrange; but used figuratively (of words), to speak; rarely (in a destructive sense) to subdue",
		"kjv" : "answer, appoint, bid, command, commune, declare, destroy, give, name, promise, pronounce, rehearse, say, speak, be spokesman, subdue, talk, teach, tell, think, use (entreaties), utter, [idiom] well, [idiom] work"
	},
	"H1697" : {
		"lemma" : "דָּבָר",
		"xlit" : "dâbâr",
		"pron" : "daw-baw'",
		"derivation" : "from H1696 (דָבַר)",
		"def" : "a word; by implication, a matter (as spoken of) or thing; adverbially, a cause",
		"kjv" : "act, advice, affair, answer, [idiom] any such (thing), because of, book, business, care, case, cause, certain rate, [phrase] chronicles, commandment, [idiom] commune(-ication), [phrase] concern(-ing), [phrase] confer, counsel, [phrase] dearth, decree, deed, [idiom] disease, due, duty, effect, [phrase] eloquent, errand, (evil favoured-) ness, [phrase] glory, [phrase] harm, hurt, [phrase] iniquity, [phrase] judgment, language, [phrase] lying, manner, matter, message, (no) thing, oracle, [idiom] ought, [idiom] parts, [phrase] pertaining, [phrase] please, portion, [phrase] power, promise, provision, purpose, question, rate, reason, report, request, [idiom] (as hast) said, sake, saying, sentence, [phrase] sign, [phrase] so, some (uncleanness), somewhat to say, [phrase] song, speech, [idiom] spoken, talk, task, [phrase] that, [idiom] there done, thing (concerning), thought, [phrase] thus, tidings, what(-soever), [phrase] wherewith, which, word, work"
	},
	"H1698" : {
		"lemma" : "דֶּבֶר",
		"xlit" : "deber",
		"pron" : "deh'-ber",
		"derivation" : "from H1696 (דָבַר) (in the sense of destroying)",
		"def" : "a pestilence",
		"kjv" : "murrain, pestilence, plague"
	},
	"H1699" : {
		"lemma" : "דֹּבֶר",
		"xlit" : "dôber",
		"pron" : "do'-ber",
		"derivation" : "from H1696 (דָבַר) (in its original sense)",
		"def" : "a pasture (from its arrangement of the flock)",
		"kjv" : "fold, manner"
	},
	"H1700" : {
		"lemma" : "דִּבְרָה",
		"xlit" : "dibrâh",
		"pron" : "dib-raw'",
		"derivation" : "feminine of H1697 (דָּבָר)",
		"def" : "a reason, suit or style",
		"kjv" : "cause, end, estate, order, regard"
	},
	"H1701" : {
		"lemma" : "דִּבְרָה",
		"xlit" : "dibrâh",
		"pron" : "dib-raw'",
		"derivation" : "(Aramaic) corresponding to H1700 (דִּבְרָה)",
		"def" : "{a reason, suit or style}",
		"kjv" : "intent, sake"
	},
	"H1702" : {
		"lemma" : "דֹּבְרָה",
		"xlit" : "dôbᵉrâh",
		"pron" : "do-ber-aw'",
		"derivation" : "feminine active participle of H1696 (דָבַר) in the sense of driving (compare H1699 (דֹּבֶר))",
		"def" : "a raft",
		"kjv" : "float"
	},
	"H1703" : {
		"lemma" : "דַּבָּרָה",
		"xlit" : "dabbârâh",
		"pron" : "dab-baw-raw'",
		"derivation" : "intensive from H1696 (דָבַר)",
		"def" : "a word",
		"kjv" : "word"
	},
	"H1704" : {
		"lemma" : "דִּבְרִי",
		"xlit" : "Dibrîy",
		"pron" : "dib-ree'",
		"derivation" : "from H1697 (דָּבָר); wordy",
		"def" : "Dibri, an Israelite",
		"kjv" : "Dibri"
	},
	"H1705" : {
		"lemma" : "דֲּבְרַת",
		"xlit" : "Dăbrath",
		"pron" : "daw-ber-ath'",
		"derivation" : "from H1697 (דָּבָר) (perhaps in the sense of H1699 (דֹּבֶר))",
		"def" : "Daberath, a place in Palestine",
		"kjv" : "Dabareh, Daberath"
	},
	"H1706" : {
		"lemma" : "דְּבַשׁ",
		"xlit" : "dᵉbash",
		"pron" : "deb-ash'",
		"derivation" : "from an unused root meaning to be gummy",
		"def" : "honey (from its stickiness); by analogy, syrup",
		"kjv" : "honey(-comb)"
	},
	"H1707" : {
		"lemma" : "דַּבֶּשֶׁת",
		"xlit" : "dabbesheth",
		"pron" : "dab-beh'-sheth",
		"derivation" : "intensive from the same as H1706 (דְּבַשׁ)",
		"def" : "a sticky mass, i.e. the hump of acamel",
		"kjv" : "hunch (of a camel)"
	},
	"H1708" : {
		"lemma" : "דַּבֶּשֶׁת",
		"xlit" : "Dabbesheth",
		"pron" : "dab-beh'-sheth",
		"derivation" : "the same as H1707 (דַּבֶּשֶׁת)",
		"def" : "Dabbesheth, a place in Palestine",
		"kjv" : "Dabbesheth"
	},
	"H1709" : {
		"lemma" : "דָּג",
		"xlit" : "dâg",
		"pron" : "dawg",
		"derivation" : "or (fully) דָּאג; (Nehemiah 13:16), from H1711 (דָּגָה); a fish (as prolific); or perhaps rather from H1672 (דָּאַג) (as timid); but still better from H1672 (דָּאַג) (in the sense of squirming, i.e. moving by the vibratory action of the tail)",
		"def" : "a fish (often used collectively)",
		"kjv" : "fish"
	},
	"H1710" : {
		"lemma" : "דָּגָה",
		"xlit" : "dâgâh",
		"pron" : "daw-gaw'",
		"derivation" : "feminine of H1709 (דָּג), and meaning the same",
		"def" : "{a fish (often used collectively)}",
		"kjv" : "fish"
	},
	"H1711" : {
		"lemma" : "דָּגָה",
		"xlit" : "dâgâh",
		"pron" : "daw-gaw'",
		"derivation" : "a primitive root; to move rapidly; used only as a denominative from H1709 (דָּג)",
		"def" : "to spawn, i.e. become numerous",
		"kjv" : "grow"
	},
	"H1712" : {
		"lemma" : "דָּגוֹן",
		"xlit" : "Dâgôwn",
		"pron" : "daw-gohn'",
		"derivation" : "from H1709 (דָּג); the fish-god",
		"def" : "Dagon, a Philistine deity",
		"kjv" : "Dagon"
	},
	"H1713" : {
		"lemma" : "דָּגַל",
		"xlit" : "dâgal",
		"pron" : "daw-gal'",
		"derivation" : "a primitive root",
		"def" : "to flaunt, i.e. raise a flag; figuratively, to be conspicuous",
		"kjv" : "(set up, with) banners, chiefest"
	},
	"H1714" : {
		"lemma" : "דֶּגֶל",
		"xlit" : "degel",
		"pron" : "deh'-gel",
		"derivation" : "from H1713 (דָּגַל)",
		"def" : "a flag",
		"kjv" : "banner, standard"
	},
	"H1715" : {
		"lemma" : "דָּגָן",
		"xlit" : "dâgân",
		"pron" : "daw-gawn'",
		"derivation" : "from H1711 (דָּגָה)",
		"def" : "properly, increase, i.e. grain",
		"kjv" : "corn (floor), wheat"
	},
	"H1716" : {
		"lemma" : "דָּגַר",
		"xlit" : "dâgar",
		"pron" : "daw-gar'",
		"derivation" : "a primitive root",
		"def" : "to brood over eggs or young",
		"kjv" : "gather, sit"
	},
	"H1717" : {
		"lemma" : "דַּד",
		"xlit" : "dad",
		"pron" : "dad",
		"derivation" : "apparently from the same as H1730 (דּוֹד)",
		"def" : "the breast (as the seat of love, or from its shape)",
		"kjv" : "breast, teat"
	},
	"H1718" : {
		"lemma" : "דָּדָה",
		"xlit" : "dâdâh",
		"pron" : "daw-daw'",
		"derivation" : "a doubtful root",
		"def" : "to walk gently",
		"kjv" : "go (softly, with)"
	},
	"H1719" : {
		"lemma" : "דְּדָן",
		"xlit" : "Dᵉdân",
		"pron" : "ded-awn'",
		"derivation" : "or (prolonged) דְּדָנֶה; (Ezekiel 25:13), of uncertain derivation",
		"def" : "Dedan, the name of two Cushites and of their territory",
		"kjv" : "Dedan"
	},
	"H1720" : {
		"lemma" : "דְּדָנִים",
		"xlit" : "Dᵉdânîym",
		"pron" : "ded-aw-neem'",
		"derivation" : "plural of H1719 (דְּדָן) (as patrial)",
		"def" : "Dedanites, the descendants or inhabitants of Dedan",
		"kjv" : "Dedanim"
	},
	"H1721" : {
		"lemma" : "דֹּדָנִים",
		"xlit" : "Dôdânîym",
		"pron" : "do-daw-neem'",
		"derivation" : "or (by orthographical error) רֹדָנִים (1 Chronicles 1:7); a plural of uncertain derivation",
		"def" : "Dodanites, or descendants of a son of Javan",
		"kjv" : "Dodanim"
	},
	"H1722" : {
		"lemma" : "דְּהַב",
		"xlit" : "dᵉhab",
		"pron" : "deh-hab'",
		"derivation" : "(Aramaic) corresponding to H2091 (זָהָב)",
		"def" : "gold",
		"kjv" : "gold(-en)"
	},
	"H1723" : {
		"lemma" : "דַּהֲוָא",
		"xlit" : "Dahăvâʼ",
		"pron" : "dah-hav-aw'",
		"derivation" : "(Aramaic) of uncertain derivation",
		"def" : "Dahava, a people colonized in Samaria",
		"kjv" : "Dehavites"
	},
	"H1724" : {
		"lemma" : "דָּהַם",
		"xlit" : "dâham",
		"pron" : "daw-ham'",
		"derivation" : "a primitive root (compare H1740 (דּוּחַ))",
		"def" : "to be dumb, i.e. (figuratively) dumbfounded",
		"kjv" : "astonished"
	},
	"H1725" : {
		"lemma" : "דָּהַר",
		"xlit" : "dâhar",
		"pron" : "daw-har'",
		"derivation" : "a primitive root",
		"def" : "to curvet or move irregularly",
		"kjv" : "pause"
	},
	"H1726" : {
		"lemma" : "דַּהֲהַר",
		"xlit" : "dahăhar",
		"pron" : "dah-hah-har'",
		"derivation" : "by reduplication from H1725 (דָּהַר)",
		"def" : "a gallop",
		"kjv" : "pransing"
	},
	"H1727" : {
		"lemma" : "דּוּב",
		"xlit" : "dûwb",
		"pron" : "doob",
		"derivation" : "a primitive root",
		"def" : "to mope, i.e. (figuratively) pine",
		"kjv" : "sorrow"
	},
	"H1728" : {
		"lemma" : "דַּוָּג",
		"xlit" : "davvâg",
		"pron" : "dav-vawg'",
		"derivation" : "an orthographical variation of H1709 (דָּג) as a denominative (H1771 (דַּיָּג))",
		"def" : "a fisherman",
		"kjv" : "fisher"
	},
	"H1729" : {
		"lemma" : "דּוּגָה",
		"xlit" : "dûwgâh",
		"pron" : "doo-gaw'",
		"derivation" : "feminine from the same as H1728 (דַּוָּג)",
		"def" : "properly, fishery, i.e. a hook forfishing",
		"kjv" : "fish (hook)"
	},
	"H1730" : {
		"lemma" : "דּוֹד",
		"xlit" : "dôwd",
		"pron" : "dode",
		"derivation" : "or (shortened) דֹּד; from an unused root meaning properly, to boil, i.e",
		"def" : "(figuratively) to love; by implication, a love-token, lover, friend; specifically an uncle",
		"kjv" : "(well-) beloved, father's brother, love, uncle"
	},
	"H1731" : {
		"lemma" : "דּוּד",
		"xlit" : "dûwd",
		"pron" : "dood",
		"derivation" : "from the same as H1730 (דּוֹד)",
		"def" : "a pot (for boiling); also (by resemblance of shape) a basket",
		"kjv" : "basket, caldron, kettle, (seething) pot"
	},
	"H1732" : {
		"lemma" : "דָּוִד",
		"xlit" : "Dâvid",
		"pron" : "daw-veed'",
		"derivation" : "rarely (fully); דָּוִיד; from the same as H1730 (דּוֹד); loving",
		"def" : "David, the youngest son of Jesse",
		"kjv" : "David"
	},
	"H1733" : {
		"lemma" : "דּוֹדָה",
		"xlit" : "dôwdâh",
		"pron" : "do-daw'",
		"derivation" : "feminine of H1730 (דּוֹד)",
		"def" : "an aunt",
		"kjv" : "aunt, father's sister, uncle's wife"
	},
	"H1734" : {
		"lemma" : "דּוֹדוֹ",
		"xlit" : "Dôwdôw",
		"pron" : "do-do'",
		"derivation" : "from H1730 (דּוֹד); loving",
		"def" : "Dodo, the name of three Israelites",
		"kjv" : "Dodo"
	},
	"H1735" : {
		"lemma" : "דּוֹדָוָהוּ",
		"xlit" : "Dôwdâvâhûw",
		"pron" : "do-daw-vaw'-hoo",
		"derivation" : "from H1730 (דּוֹד) and H3050 (יָהּ); love of Jah",
		"def" : "Dodavah, an Israelite",
		"kjv" : "Dodavah"
	},
	"H1736" : {
		"lemma" : "דּוּדַי",
		"xlit" : "dûwday",
		"pron" : "doo-dah'-ee",
		"derivation" : "from H1731 (דּוּד)",
		"def" : "a boiler or basket; also the mandrake (as an aphrodisiac)",
		"kjv" : "basket, mandrake"
	},
	"H1737" : {
		"lemma" : "דּוֹדַי",
		"xlit" : "Dôwday",
		"pron" : "do-dah'ee",
		"derivation" : "formed like H1736 (דּוּדַי); amatory",
		"def" : "Dodai, an Israelite",
		"kjv" : "Dodai"
	},
	"H1738" : {
		"lemma" : "דָּוָה",
		"xlit" : "dâvâh",
		"pron" : "daw-vaw'",
		"derivation" : "a primitive root",
		"def" : "to be sick (as if in menstruation)",
		"kjv" : "infirmity"
	},
	"H1739" : {
		"lemma" : "דָּוֶה",
		"xlit" : "dâveh",
		"pron" : "daw-veh'",
		"derivation" : "from H1738 (דָּוָה)",
		"def" : "sick (especially in menstruation)",
		"kjv" : "faint, menstruous cloth, she that is sick, having sickness"
	},
	"H1740" : {
		"lemma" : "דּוּחַ",
		"xlit" : "dûwach",
		"pron" : "doo'-akh",
		"derivation" : "a primitive root",
		"def" : "to thrust away; figuratively, to cleanse",
		"kjv" : "cast out, purge, wash"
	},
	"H1741" : {
		"lemma" : "דְּוַי",
		"xlit" : "dᵉvay",
		"pron" : "dev-ah'ee",
		"derivation" : "from H1739 (דָּוֶה)",
		"def" : "sickness; figuratively, loathing",
		"kjv" : "languishing, sorrowful"
	},
	"H1742" : {
		"lemma" : "דַּוָּי",
		"xlit" : "davvây",
		"pron" : "dav-voy'",
		"derivation" : "from H1739 (דָּוֶה)",
		"def" : "sick; figuratively, troubled",
		"kjv" : "faint"
	},
	"H1743" : {
		"lemma" : "דּוּךְ",
		"xlit" : "dûwk",
		"pron" : "dook",
		"derivation" : "a primitive root",
		"def" : "to bruise in a mortar",
		"kjv" : "beat"
	},
	"H1744" : {
		"lemma" : "דּוּכִיפַת",
		"xlit" : "dûwkîyphath",
		"pron" : "doo-kee-fath'",
		"derivation" : "of uncertain derivation",
		"def" : "the hoopoe or else the grouse",
		"kjv" : "lapwing"
	},
	"H1745" : {
		"lemma" : "דּוּמָה",
		"xlit" : "dûwmâh",
		"pron" : "doo-maw'",
		"derivation" : "from an unused root meaning to be dumb (compare H1820 (דָּמָה))",
		"def" : "silence; figuratively, death",
		"kjv" : "silence"
	},
	"H1746" : {
		"lemma" : "דּוּמָה",
		"xlit" : "Dûwmâh",
		"pron" : "doo-maw'",
		"derivation" : "the same as H1745 (דּוּמָה)",
		"def" : "Dumah, a tribe and region of Arabia",
		"kjv" : "Dumah"
	},
	"H1747" : {
		"lemma" : "דּוּמִיָּה",
		"xlit" : "dûwmîyâh",
		"pron" : "doo-me-yaw'",
		"derivation" : "from H1820 (דָּמָה)",
		"def" : "stillness; adverbially, silently; abstractly quiet, trust",
		"kjv" : "silence, silent, waiteth"
	},
	"H1748" : {
		"lemma" : "דּוּמָם",
		"xlit" : "dûwmâm",
		"pron" : "doo-mawm'",
		"derivation" : "from H1826 (דָּמַם)",
		"def" : "still; adverbially, silently",
		"kjv" : "dumb, silent, quietly wait"
	},
	"H1749" : {
		"lemma" : "דּוֹנַג",
		"xlit" : "dôwnag",
		"pron" : "do-nag'",
		"derivation" : "of uncertain derivation",
		"def" : "wax",
		"kjv" : "wax"
	},
	"H1750" : {
		"lemma" : "דּוּץ",
		"xlit" : "dûwts",
		"pron" : "doots",
		"derivation" : "a primitive root",
		"def" : "to leap",
		"kjv" : "be turned"
	},
	"H1751" : {
		"lemma" : "דּוּק",
		"xlit" : "dûwq",
		"pron" : "dook",
		"derivation" : "(Aramaic) corresponding to H1854 (דָּקַק)",
		"def" : "to crumble",
		"kjv" : "be broken to pieces"
	},
	"H1752" : {
		"lemma" : "דּוּר",
		"xlit" : "dûwr",
		"pron" : "dure",
		"derivation" : "a primitive root",
		"def" : "properly, to gyrate (or move in a circle), i.e. to remain",
		"kjv" : "dwell"
	},
	"H1753" : {
		"lemma" : "דּוּר",
		"xlit" : "dûwr",
		"pron" : "dure",
		"derivation" : "(Aramaic) corresponding to H1752 (דּוּר)",
		"def" : "to reside",
		"kjv" : "dwell"
	},
	"H1754" : {
		"lemma" : "דּוּר",
		"xlit" : "dûwr",
		"pron" : "dure",
		"derivation" : "from H1752 (דּוּר)",
		"def" : "a circle, ball or pile",
		"kjv" : "ball, turn, round about"
	},
	"H1755" : {
		"lemma" : "דּוֹר",
		"xlit" : "dôwr",
		"pron" : "dore",
		"derivation" : "or (shortened) דֹּר; from H1752 (דּוּר)",
		"def" : "properly, a revolution of time, i.e. an age or generation; also a dwelling",
		"kjv" : "age, [idiom] evermore, generation, (n-) ever, posterity"
	},
	"H1756" : {
		"lemma" : "דּוֹר",
		"xlit" : "Dôwr",
		"pron" : "dore",
		"derivation" : "or (by permutation) דֹּאר; (Joshua 17:11; 1 Kings 4:11), from H1755 (דּוֹר); dwelling",
		"def" : "Dor, a place in Palestine",
		"kjv" : "Dor"
	},
	"H1757" : {
		"lemma" : "דּוּרָא",
		"xlit" : "Dûwrâʼ",
		"pron" : "doo-raw'",
		"derivation" : "(Aramaic) probably from H1753 (דּוּר); circle or dwelling",
		"def" : "Dura, a place in Babylonia",
		"kjv" : "Dura"
	},
	"H1758" : {
		"lemma" : "דּוּשׁ",
		"xlit" : "dûwsh",
		"pron" : "doosh",
		"derivation" : "or דּוֹשׁ; or דִּישׁ; a primitive root",
		"def" : "to trample or thresh",
		"kjv" : "break, tear, thresh, tread out (down), at grass (Jeremiah 50:11, by mistake for H1877 (דֶּשֶׁא))"
	},
	"H1759" : {
		"lemma" : "דּוּשׁ",
		"xlit" : "dûwsh",
		"pron" : "doosh",
		"derivation" : "(Aramaic) corresponding to H1758 (דּוּשׁ)",
		"def" : "to trample",
		"kjv" : "tread down"
	},
	"H1760" : {
		"lemma" : "דָּחָה",
		"xlit" : "dâchâh",
		"pron" : "daw-khaw'",
		"derivation" : "or דָּחַח; (Jeremiah 23:12), a primitive root",
		"def" : "to push down",
		"kjv" : "chase, drive away (on), overthrow, outcast, [idiom] sore, thrust, totter"
	},
	"H1761" : {
		"lemma" : "דַּחֲוָה",
		"xlit" : "dachăvâh",
		"pron" : "dakh-av-aw'",
		"derivation" : "(Aramaic) from the equivalent of H1760 (דָּחָה)",
		"def" : "probably a musical instrument (as being struck)",
		"kjv" : "instrument of music"
	},
	"H1762" : {
		"lemma" : "דְּחִי",
		"xlit" : "dᵉchîy",
		"pron" : "deh-khee'",
		"derivation" : "from H1760 (דָּחָה)",
		"def" : "a push, i.e. (by implication) a fall",
		"kjv" : "falling"
	},
	"H1763" : {
		"lemma" : "דְּחַל",
		"xlit" : "dᵉchal",
		"pron" : "deh-khal'",
		"derivation" : "(Aramaic) corresponding to H2119 (זָחַל)",
		"def" : "to slink, i.e. (by implication) to fear, or (causatively) be formidable",
		"kjv" : "make afraid, dreadful, fear, terrible"
	},
	"H1764" : {
		"lemma" : "דֹּחַן",
		"xlit" : "dôchan",
		"pron" : "do'-khan",
		"derivation" : "of uncertain derivation",
		"def" : "millet",
		"kjv" : "millet"
	},
	"H1765" : {
		"lemma" : "דָּחַף",
		"xlit" : "dâchaph",
		"pron" : "daw-khaf'",
		"derivation" : "a primitive root",
		"def" : "to urge, i.e. hasten",
		"kjv" : "(be) haste(-ned), pressed on"
	},
	"H1766" : {
		"lemma" : "דָּחַק",
		"xlit" : "dâchaq",
		"pron" : "daw-khak'",
		"derivation" : "a primitive root",
		"def" : "to press, i.e. oppress",
		"kjv" : "thrust, vex"
	},
	"H1767" : {
		"lemma" : "דַּי",
		"xlit" : "day",
		"pron" : "dahee",
		"derivation" : "of uncertain derivation",
		"def" : "enough (as noun or adverb), used chiefly with preposition in phrases",
		"kjv" : "able, according to, after (ability), among, as (oft as), (more than) enough, from, in, since, (much as is) sufficient(-ly), too much, very, when"
	},
	"H1768" : {
		"lemma" : "דִּי",
		"xlit" : "dîy",
		"pron" : "dee",
		"derivation" : "(Aramaic) apparently for H1668 (דָּא)",
		"def" : "that, used as relative conjunction, and especially (with a preposition) in adverbial phrases; also as preposition of",
		"kjv" : "[idiom] as, but, for(-asmuch [phrase]), [phrase] now, of, seeing, than, that, therefore, until, [phrase] what (-soever), when, which, whom, whose"
	},
	"H1769" : {
		"lemma" : "דִּיבוֹן",
		"xlit" : "Dîybôwn",
		"pron" : "dee-bome'",
		"derivation" : "or (shortened) דִּיבֹן; from H1727 (דּוּב); pining",
		"def" : "Dibon, the name of three places in Palestine",
		"kjv" : "Dibon. (Also, with H1410 (גָּד) added, Dibon-gad.)"
	},
	"H1770" : {
		"lemma" : "דִּיג",
		"xlit" : "dîyg",
		"pron" : "deeg",
		"derivation" : "denominative from H1709 (דָּג)",
		"def" : "to fish",
		"kjv" : "fish"
	},
	"H1771" : {
		"lemma" : "דַּיָּג",
		"xlit" : "dayâg",
		"pron" : "dah-yawg'",
		"derivation" : "from H1770 (דִּיג)",
		"def" : "a fisherman",
		"kjv" : "fisher"
	},
	"H1772" : {
		"lemma" : "דַּיָּה",
		"xlit" : "dayâh",
		"pron" : "dah-yaw'",
		"derivation" : "intensive from H1675 (דָּאָה)",
		"def" : "a falcon (from its rapid flight)",
		"kjv" : "vulture"
	},
	"H1773" : {
		"lemma" : "דְּיוֹ",
		"xlit" : "dᵉyôw",
		"pron" : "deh-yo'",
		"derivation" : "of uncertain derivation",
		"def" : "ink",
		"kjv" : "ink"
	},
	"H1774" : {
		"lemma" : "דִּי זָהָב",
		"xlit" : "Dîy zâhâb",
		"pron" : "dee zaw-hawb'",
		"derivation" : "as if from H1768 (דִּי) and H2091 (זָהָב); of gold",
		"def" : "Dizahab, a place in the Desert",
		"kjv" : "Dizahab"
	},
	"H1775" : {
		"lemma" : "דִּימוֹן",
		"xlit" : "Dîymôwn",
		"pron" : "dee-mone'",
		"derivation" : "perhaps for H1769 (דִּיבוֹן)",
		"def" : "Dimon, a place in Palestine",
		"kjv" : "Dimon"
	},
	"H1776" : {
		"lemma" : "דִּימוֹנָה",
		"xlit" : "Dîymôwnâh",
		"pron" : "dee-mo-naw'",
		"derivation" : "feminine of H1775 (דִּימוֹן)",
		"def" : "Dimonah, a place in Palestine",
		"kjv" : "Dimonah"
	},
	"H1777" : {
		"lemma" : "דִּין",
		"xlit" : "dîyn",
		"pron" : "deen",
		"derivation" : "or (Genesis 6:3) דּוּן; a primitive root",
		"def" : "a straight course, i.e. sail direct",
		"kjv" : "(come) with a straight course"
	},
	"H1778" : {
		"lemma" : "דִּין",
		"xlit" : "dîyn",
		"pron" : "deen",
		"derivation" : "(Aramaic) corresponding to H1777 (דִּין)",
		"def" : "to judge",
		"kjv" : "judge"
	},
	"H1779" : {
		"lemma" : "דִּין",
		"xlit" : "dîyn",
		"pron" : "deen",
		"derivation" : "or (Job 19:29) דּוּן; from H1777 (דִּין)",
		"def" : "judgement (the suit, justice, sentence or tribunal); by implication also strife",
		"kjv" : "cause, judgement, plea, strife"
	},
	"H1780" : {
		"lemma" : "דִּין",
		"xlit" : "dîyn",
		"pron" : "deen",
		"derivation" : "(Aramaic) corresponding to H1779 (דִּין)",
		"def" : "{judgement (the suit, justice, sentence or tribunal); by implication also strife}",
		"kjv" : "judgement"
	},
	"H1781" : {
		"lemma" : "דַּיָּן",
		"xlit" : "dayân",
		"pron" : "dah-yawn'",
		"derivation" : "from H1777 (דִּין)",
		"def" : "a judge or advocate",
		"kjv" : "judge"
	},
	"H1782" : {
		"lemma" : "דַּיָּן",
		"xlit" : "dayân",
		"pron" : "dah-yawn'",
		"derivation" : "(Aramaic) corresponding to H1781 (דַּיָּן)",
		"def" : "{a judge or advocate}",
		"kjv" : "judge"
	},
	"H1783" : {
		"lemma" : "דִּינָה",
		"xlit" : "Dîynâh",
		"pron" : "dee-naw'",
		"derivation" : "feminine of H1779 (דִּין); justice",
		"def" : "Dinah, the daughter of Jacob",
		"kjv" : "Dinah"
	},
	"H1784" : {
		"lemma" : "דִּינַי",
		"xlit" : "Dîynay",
		"pron" : "dee-nah'-ee",
		"derivation" : "(Aramaic) partial from uncertain primitive",
		"def" : "a Dinaite or inhabitant of some unknown Assyria province",
		"kjv" : "Dinaite"
	},
	"H1785" : {
		"lemma" : "דָּיֵק",
		"xlit" : "dâyêq",
		"pron" : "daw-yake'",
		"derivation" : "from a root corresp. to H1751 (דּוּק)",
		"def" : "a battering-tower",
		"kjv" : "fort"
	},
	"H1786" : {
		"lemma" : "דַּיִשׁ",
		"xlit" : "dayish",
		"pron" : "dah-yish'",
		"derivation" : "from H1758 (דּוּשׁ)",
		"def" : "threshing-time",
		"kjv" : "threshing"
	},
	"H1787" : {
		"lemma" : "דִּישׁוֹן",
		"xlit" : "Dîyshôwn",
		"pron" : "dee-shone'",
		"derivation" : "(Diyshon, the same as H1788 (דִּישֹׁן)",
		"def" : "Dishon, the name of two Edomites",
		"kjv" : "Dishon"
	},
	"H1788" : {
		"lemma" : "דִּישֹׁן",
		"xlit" : "dîyshôn",
		"pron" : "dee-shone'",
		"derivation" : "from H1758 (דּוּשׁ)",
		"def" : "the leaper, i.e. an antelope",
		"kjv" : "pygarg"
	},
	"H1789" : {
		"lemma" : "דִּישָׁן",
		"xlit" : "Dîyshân",
		"pron" : "dee-shawn'",
		"derivation" : "another form of H1787 (דִּישׁוֹן)",
		"def" : "Dishan, an Edomite",
		"kjv" : "Dishan, Dishon"
	},
	"H1790" : {
		"lemma" : "דַּךְ",
		"xlit" : "dak",
		"pron" : "dak",
		"derivation" : "from an unused root (compare H1794 (דָּכָה))",
		"def" : "crushed, i.e. (figuratively) injured",
		"kjv" : "afflicted, oppressed"
	},
	"H1791" : {
		"lemma" : "דֵּךְ",
		"xlit" : "dêk",
		"pron" : "dake",
		"derivation" : "(Aramaic) or דָּךְ; (Aramaic), prolonged from H1668 (דָּא)",
		"def" : "this",
		"kjv" : "the same, this"
	},
	"H1792" : {
		"lemma" : "דָּכָא",
		"xlit" : "dâkâʼ",
		"pron" : "daw-kaw'",
		"derivation" : "a primitive root (compare H1794 (דָּכָה))",
		"def" : "to crumble; transitively, to bruise (literally or figuratively)",
		"kjv" : "beat to pieces, break (in pieces), bruise, contrite, crush, destroy, humble, oppress, smite"
	},
	"H1793" : {
		"lemma" : "דַּכָּא",
		"xlit" : "dakkâʼ",
		"pron" : "dak-kaw'",
		"derivation" : "from H1792 (דָּכָא)",
		"def" : "crushed (literally powder, or figuratively, contrite)",
		"kjv" : "contrite, destruction"
	},
	"H1794" : {
		"lemma" : "דָּכָה",
		"xlit" : "dâkâh",
		"pron" : "daw-kaw'",
		"derivation" : "a primitive root (compare H1790 (דַּךְ), H1792 (דָּכָא))",
		"def" : "to collapse (phys. or mentally)",
		"kjv" : "break (sore), contrite, crouch"
	},
	"H1795" : {
		"lemma" : "דַּכָּה",
		"xlit" : "dakkâh",
		"pron" : "dak-kaw'",
		"derivation" : "from H1794 (דָּכָה) like H1793 (דַּכָּא)",
		"def" : "mutilated",
		"kjv" : "[phrase] wounded"
	},
	"H1796" : {
		"lemma" : "דֳּכִי",
		"xlit" : "dŏkîy",
		"pron" : "dok-ee'",
		"derivation" : "from H1794 (דָּכָה)",
		"def" : "a dashing of surf",
		"kjv" : "wave"
	},
	"H1797" : {
		"lemma" : "דִּכֵּן",
		"xlit" : "dikkên",
		"pron" : "dik-kane'",
		"derivation" : "(Aramaic) prolonged from H1791 (דֵּךְ)",
		"def" : "this",
		"kjv" : "same, that, this"
	},
	"H1798" : {
		"lemma" : "דְּכַר",
		"xlit" : "dᵉkar",
		"pron" : "dek-ar'",
		"derivation" : "(Aramaic) corresponding to H2145 (זָכָר)",
		"def" : "properly, a male, i.e. of sheep",
		"kjv" : "ram"
	},
	"H1799" : {
		"lemma" : "דִּכְרוֹן",
		"xlit" : "dikrôwn",
		"pron" : "dik-rone'",
		"derivation" : "(Aramaic) or דׇּכְרָן (Aramaic); corresponding to H2146 (זִכְרוֹן)",
		"def" : "a register",
		"kjv" : "record"
	},
	"H1800" : {
		"lemma" : "דַּל",
		"xlit" : "dal",
		"pron" : "dal",
		"derivation" : "from H1809 (דָּלַל)",
		"def" : "properly, dangling, i.e. (by implication) weak or thin",
		"kjv" : "lean, needy, poor (man), weaker"
	},
	"H1801" : {
		"lemma" : "דָּלַג",
		"xlit" : "dâlag",
		"pron" : "daw-lag'",
		"derivation" : "a primitive root",
		"def" : "to spring",
		"kjv" : "leap"
	},
	"H1802" : {
		"lemma" : "דָּלָה",
		"xlit" : "dâlâh",
		"pron" : "daw-law'",
		"derivation" : "a primitive root (compare H1809 (דָּלַל))",
		"def" : "properly, to dangle, i.e. to let down abucket (for drawing out water); figuratively, to deliver",
		"kjv" : "draw (out), [idiom] enough, lift up"
	},
	"H1803" : {
		"lemma" : "דַּלָּה",
		"xlit" : "dallâh",
		"pron" : "dal-law'",
		"derivation" : "from H1802 (דָּלָה)",
		"def" : "properly, something dangling, i.e. a loose thread or hair; figuratively, indigent",
		"kjv" : "hair, pining sickness, poor(-est sort)"
	},
	"H1804" : {
		"lemma" : "דָּלַח",
		"xlit" : "dâlach",
		"pron" : "daw-lakh'",
		"derivation" : "a primitive root",
		"def" : "to roil water",
		"kjv" : "trouble"
	},
	"H1805" : {
		"lemma" : "דְּלִי",
		"xlit" : "dᵉlîy",
		"pron" : "del-ee'",
		"derivation" : "or דֳּלִי; from H1802 (דָּלָה)",
		"def" : "a pail or jar (for drawing water)",
		"kjv" : "bucket"
	},
	"H1806" : {
		"lemma" : "דְּלָיָה",
		"xlit" : "Dᵉlâyâh",
		"pron" : "del-aw-yaw'",
		"derivation" : "or (prolonged) דְּלָיָהוּxlit Dᵉlâyâhhûw corrected to Dᵉlâyâhûw; from H1802 (דָּלָה) and H3050 (יָהּ); Jah has delivered",
		"def" : "Delajah, the name of five Israelites",
		"kjv" : "Dalaiah, Delaiah"
	},
	"H1807" : {
		"lemma" : "דְּלִילָה",
		"xlit" : "Dᵉlîylâh",
		"pron" : "del-ee-law'",
		"derivation" : "from H1809 (דָּלַל); languishing",
		"def" : "Delilah, a Philistine woman",
		"kjv" : "Delilah"
	},
	"H1808" : {
		"lemma" : "דָּלִיָּה",
		"xlit" : "dâlîyâh",
		"pron" : "daw-lee-yaw'",
		"derivation" : "from H1802 (דָּלָה)",
		"def" : "something dangling, i.e. a bough",
		"kjv" : "branch"
	},
	"H1809" : {
		"lemma" : "דָּלַל",
		"xlit" : "dâlal",
		"pron" : "daw-lal'",
		"derivation" : "a primitive root (compare H1802 (דָּלָה))",
		"def" : "to slacken or be feeble; figuratively, to be oppressed",
		"kjv" : "bring low, dry up, be emptied, be not equal, fail, be impoverished, be made thin"
	},
	"H1810" : {
		"lemma" : "דִּלְעָן",
		"xlit" : "Dilʻân",
		"pron" : "dil-awn'",
		"derivation" : "of uncertain derivation",
		"def" : "Dilan, a place in Palestine",
		"kjv" : "Dilean"
	},
	"H1811" : {
		"lemma" : "דָּלַף",
		"xlit" : "dâlaph",
		"pron" : "daw-laf'",
		"derivation" : "a primitive root",
		"def" : "to drip; by implication, to weep",
		"kjv" : "drop through, melt, pour out"
	},
	"H1812" : {
		"lemma" : "דֶּלֶף",
		"xlit" : "deleph",
		"pron" : "deh'-lef",
		"derivation" : "from H1811 (דָּלַף)",
		"def" : "a dripping",
		"kjv" : "dropping"
	},
	"H1813" : {
		"lemma" : "דַּלְפוֹן",
		"xlit" : "Dalphôwn",
		"pron" : "dal-fone'",
		"derivation" : "from H1811 (דָּלַף); dripping",
		"def" : "Dalphon, a son of Haman",
		"kjv" : "Dalphon"
	},
	"H1814" : {
		"lemma" : "דָּלַק",
		"xlit" : "dâlaq",
		"pron" : "daw-lak'",
		"derivation" : "a primitive root",
		"def" : "to flame (literally or figuratively)",
		"kjv" : "burning, chase, inflame, kindle, persecute(-or), pursue hotly"
	},
	"H1815" : {
		"lemma" : "דְּלַק",
		"xlit" : "dᵉlaq",
		"pron" : "del-ak'",
		"derivation" : "(Aramaic) corresponding to H1814 (דָּלַק)",
		"def" : "{to flame (literally or figuratively)}",
		"kjv" : "burn"
	},
	"H1816" : {
		"lemma" : "דַּלֶּקֶת",
		"xlit" : "dalleqeth",
		"pron" : "dal-lek'-keth",
		"derivation" : "; from H1814 (דָּלַק)",
		"def" : "a burning fever",
		"kjv" : "inflammation"
	},
	"H1817" : {
		"lemma" : "דֶּלֶת",
		"xlit" : "deleth",
		"pron" : "deh'-leth",
		"derivation" : "from H1802 (דָּלָה)",
		"def" : "something swinging, i.e. the valve of adoor",
		"kjv" : "door (two-leaved), gate, leaf, lid. (Psalm 141:3)"
	},
	"H1818" : {
		"lemma" : "דָּם",
		"xlit" : "dâm",
		"pron" : "dawm",
		"derivation" : "from H1826 (דָּמַם) (compare H119 (אָדַם))",
		"def" : "blood (as that which when shed causes death) of man or an animal; by analogy, the juice of the grape; figuratively (especially in the plural) bloodshed (i.e. drops of blood)",
		"kjv" : "blood(-y, -guiltiness, (-thirsty), [phrase] innocent"
	},
	"H1819" : {
		"lemma" : "דָּמָה",
		"xlit" : "dâmâh",
		"pron" : "daw-maw'",
		"derivation" : "a primitive root",
		"def" : "to compare; by implication, to resemble, liken, consider",
		"kjv" : "compare, devise, (be) like(-n), mean, think, use similitudes"
	},
	"H1820" : {
		"lemma" : "דָּמָה",
		"xlit" : "dâmâh",
		"pron" : "daw-mam'",
		"derivation" : "a primitive root",
		"def" : "to be dumb or silent; hence, to fail or perish; trans. to destroy",
		"kjv" : "cease, be cut down (off), destroy, be brought to silence, be undone, [idiom] utterly"
	},
	"H1821" : {
		"lemma" : "דְּמָה",
		"xlit" : "dᵉmâh",
		"pron" : "dem-aw'",
		"derivation" : "(Aramaic) corresponding to H1819 (דָּמָה)",
		"def" : "to resemble",
		"kjv" : "be like"
	},
	"H1822" : {
		"lemma" : "דֻּמָּה",
		"xlit" : "dummâh",
		"pron" : "doom-maw'",
		"derivation" : "from H1820 (דָּמָה)",
		"def" : "desolation; concretely, desolate",
		"kjv" : "destroy"
	},
	"H1823" : {
		"lemma" : "דְּמוּת",
		"xlit" : "dᵉmûwth",
		"pron" : "dem-ooth'",
		"derivation" : "from H1819 (דָּמָה)",
		"def" : "resemblance; concretely, model, shape; adverbially, like",
		"kjv" : "fashion, like (-ness, as), manner, similitude"
	},
	"H1824" : {
		"lemma" : "דְּמִי",
		"xlit" : "dᵉmîy",
		"pron" : "dem-ee'",
		"derivation" : "or דֳּמִי; from H1820 (דָּמָה)",
		"def" : "quiet",
		"kjv" : "cutting off, rest, silence"
	},
	"H1825" : {
		"lemma" : "דִּמְיוֹן",
		"xlit" : "dimyôwn",
		"pron" : "dim-yone'",
		"derivation" : "from H1819 (דָּמָה)",
		"def" : "resemblance",
		"kjv" : "[idiom] like"
	},
	"H1826" : {
		"lemma" : "דָּמַם",
		"xlit" : "dâmam",
		"pron" : "daw-man'",
		"derivation" : "a primitive root (compare H1724 (דָּהַם), H1820 (דָּמָה))",
		"def" : "to be dumb; by implication, to be astonished, to stop; also to perish",
		"kjv" : "cease, be cut down (off), forbear, hold peace, quiet self, rest, be silent, keep (put to) silence, be (stand) still, tarry, wait"
	},
	"H1827" : {
		"lemma" : "דְּמָמָה",
		"xlit" : "dᵉmâmâh",
		"pron" : "dem-aw-maw'",
		"derivation" : "feminine from H1826 (דָּמַם)",
		"def" : "quiet",
		"kjv" : "calm, silence, still"
	},
	"H1828" : {
		"lemma" : "דֹּמֶן",
		"xlit" : "dômen",
		"pron" : "do'-men",
		"derivation" : "of uncertain derivation",
		"def" : "manure",
		"kjv" : "dung"
	},
	"H1829" : {
		"lemma" : "דִּמְנָה",
		"xlit" : "Dimnâh",
		"pron" : "dim-naw'",
		"derivation" : "feminine from the same as H1828 (דֹּמֶן); a dung-heap",
		"def" : "Dimnah, a place in Palestine",
		"kjv" : "Dimnah"
	},
	"H1830" : {
		"lemma" : "דָּמַע",
		"xlit" : "dâmaʻ",
		"pron" : "daw-mah'",
		"derivation" : "a primitive root",
		"def" : "to weep",
		"kjv" : "[idiom] sore, weep"
	},
	"H1831" : {
		"lemma" : "דֶּמַע",
		"xlit" : "demaʻ",
		"pron" : "dah'-mah",
		"derivation" : "from H1830 (דָּמַע)",
		"def" : "a tear; figuratively, juice",
		"kjv" : "liquor"
	},
	"H1832" : {
		"lemma" : "דִּמְעָה",
		"xlit" : "dimʻâh",
		"pron" : "dim-aw'",
		"derivation" : "feminine of H1831 (דֶּמַע)",
		"def" : "weeping",
		"kjv" : "tears"
	},
	"H1833" : {
		"lemma" : "דְּמֶשֶׁק",
		"xlit" : "dᵉmesheq",
		"pron" : "dem-eh'-shek",
		"derivation" : "by orthographical variation from H1834 (דַּמֶּשֶׂק)",
		"def" : "damask (as a fabric of Damascus)",
		"kjv" : "in Damascus"
	},
	"H1834" : {
		"lemma" : "דַּמֶּשֶׂק",
		"xlit" : "Dammeseq",
		"pron" : "dam-meh'-sek",
		"derivation" : "or דּוּמֶשֶׂק; or דַּרְמֶשֶׂק; of foreign origin",
		"def" : "Damascus, a city of Syria",
		"kjv" : "Damascus"
	},
	"H1835" : {
		"lemma" : "דָּן",
		"xlit" : "Dân",
		"pron" : "dawn",
		"derivation" : "from H1777 (דִּין); judge",
		"def" : "Dan, one of the sons of Jacob; also the tribe descended from him, and its territory; likewise a place in Palestine colonized by them",
		"kjv" : "Daniel"
	},
	"H1836" : {
		"lemma" : "דֵּן",
		"xlit" : "dên",
		"pron" : "dane",
		"derivation" : "(Aramaic) an orthographical variation of H1791 (דֵּךְ)",
		"def" : "this",
		"kjv" : "(afore-) time, [phrase] after this manner, here (-after), one...another, such, there(-fore), these, this (matter), [phrase] thus, where(-fore), which"
	},
	"H1837" : {
		"lemma" : "דַּנָּה",
		"xlit" : "Dannâh",
		"pron" : "dan-naw'",
		"derivation" : "of uncertain derivation",
		"def" : "Dannah, a place in Palestine",
		"kjv" : "Dannah"
	},
	"H1838" : {
		"lemma" : "דִּנְהָבָה",
		"xlit" : "Dinhâbâh",
		"pron" : "din-haw-baw'",
		"derivation" : "of uncertain derivation",
		"def" : "Dinhabah, an Edomitish town",
		"kjv" : "Dinhaban"
	},
	"H1839" : {
		"lemma" : "דָּנִי",
		"xlit" : "Dânîy",
		"pron" : "daw-nee'",
		"derivation" : "patronymically from H1835 (דָּן)",
		"def" : "a Danite (often collectively) or descendants (or inhabitants) of Dan",
		"kjv" : "Danites, of Daniel"
	},
	"H1840" : {
		"lemma" : "דָנִיֵּאל",
		"xlit" : "Dânîyêʼl",
		"pron" : "daw-nee-yale'",
		"derivation" : "in Ezekiel it is; דָּנִאֵל; from H1835 (דָּן) and H410 (אֵל); judge of God",
		"def" : "Daniel or Danijel, the name of two Israelites",
		"kjv" : "Daniel"
	},
	"H1841" : {
		"lemma" : "דָּנִיֵּאל",
		"xlit" : "Dânîyêʼl",
		"pron" : "daw-nee-yale'",
		"derivation" : "(Aramaic) corresponding to H1840 (דָנִיֵּאל)",
		"def" : "Danijel, the Hebrew prophet",
		"kjv" : "Daniel"
	},
	"H1842" : {
		"lemma" : "דָּן יַעַן",
		"xlit" : "Dân Yaʻan",
		"pron" : "dawn yah'-an",
		"derivation" : "from H1835 (דָּן) and (apparently) H3282 (יַעַן); judge of purpose",
		"def" : "Dan-Jaan, a place in Palestine",
		"kjv" : "Dan-jaan"
	},
	"H1843" : {
		"lemma" : "דֵּעַ",
		"xlit" : "dêaʻ",
		"pron" : "day'-ah",
		"derivation" : "from H3045 (יָדַע)",
		"def" : "knowledge",
		"kjv" : "knowledge, opinion"
	},
	"H1844" : {
		"lemma" : "דֵּעָה",
		"xlit" : "dêʻâh",
		"pron" : "day-aw'",
		"derivation" : "feminine of H1843 (דֵּעַ)",
		"def" : "knowledge",
		"kjv" : "knowledge"
	},
	"H1845" : {
		"lemma" : "דְּעוּאֵל",
		"xlit" : "Dᵉʻûwʼêl",
		"pron" : "deh-oo-ale'",
		"derivation" : "from H3045 (יָדַע) and H410 (אֵל); known of God",
		"def" : "Deuel, an Israelite",
		"kjv" : "Deuel"
	},
	"H1846" : {
		"lemma" : "דָּעַךְ",
		"xlit" : "dâʻak",
		"pron" : "daw-ak'",
		"derivation" : "a primitive root",
		"def" : "to be extinguished; figuratively, to expire or be dried up",
		"kjv" : "be extinct, consumed, put out, quenched"
	},
	"H1847" : {
		"lemma" : "דַּעַת",
		"xlit" : "daʻath",
		"pron" : "dah'-ath",
		"derivation" : "from H3045 (יָדַע)",
		"def" : "knowledge",
		"kjv" : "cunning, (ig-) norantly, know(-ledge), (un-) awares (wittingly)"
	},
	"H1848" : {
		"lemma" : "דׇּפִי",
		"xlit" : "dophîy",
		"pron" : "dof'-ee",
		"derivation" : "from an unused root (meaning to push over)",
		"def" : "a stumbling-block",
		"kjv" : "slanderest"
	},
	"H1849" : {
		"lemma" : "דָּפַק",
		"xlit" : "dâphaq",
		"pron" : "daw-fak'",
		"derivation" : "a primitive root",
		"def" : "to knock; by analogy, to press severely",
		"kjv" : "beat, knock, overdrive"
	},
	"H1850" : {
		"lemma" : "דׇּפְקָה",
		"xlit" : "Dophqâh",
		"pron" : "dof-kaw'",
		"derivation" : "from H1849 (דָּפַק); a knock",
		"def" : "Dophkah, a place in the Desert",
		"kjv" : "Dophkah"
	},
	"H1851" : {
		"lemma" : "דַּק",
		"xlit" : "daq",
		"pron" : "dak",
		"derivation" : "from H1854 (דָּקַק)",
		"def" : "crushed, i.e. (by implication) small or thin",
		"kjv" : "dwarf, lean(-fleshed), very little thing, small, thin"
	},
	"H1852" : {
		"lemma" : "דֹּק",
		"xlit" : "dôq",
		"pron" : "doke",
		"derivation" : "from H1854 (דָּקַק)",
		"def" : "something crumbling, i.e. fine (as a thin cloth)",
		"kjv" : "curtain"
	},
	"H1853" : {
		"lemma" : "דִּקְלָה",
		"xlit" : "Diqlâh",
		"pron" : "dik-law'",
		"derivation" : "of foreign origin",
		"def" : "Diklah, a region of Arabia",
		"kjv" : "Diklah"
	},
	"H1854" : {
		"lemma" : "דָּקַק",
		"xlit" : "dâqaq",
		"pron" : "daw-kak'",
		"derivation" : "a primitive root (compare H1915 (הָדַךְ)lemma הָדךְ missing vowel, corrected to הָדַךְ)",
		"def" : "to crush (or intransitively) crumble",
		"kjv" : "beat in pieces (small), bruise, make dust, (into) [idiom] powder, (be, very) small, stamp (small)"
	},
	"H1855" : {
		"lemma" : "דְּקַק",
		"xlit" : "dᵉqaq",
		"pron" : "dek-ak'",
		"derivation" : "(Aramaic) corresponding to H1854 (דָּקַק)",
		"def" : "to crumble or (trans.) crush",
		"kjv" : "break to pieces"
	},
	"H1856" : {
		"lemma" : "דָּקַר",
		"xlit" : "dâqar",
		"pron" : "daw-kar'",
		"derivation" : "a primitive root",
		"def" : "to stab; by analogy, to starve; figuratively, to revile",
		"kjv" : "pierce, strike (thrust) through, wound"
	},
	"H1857" : {
		"lemma" : "דֶּקֶר",
		"xlit" : "Deqer",
		"pron" : "deh'-ker",
		"derivation" : "from H1856 (דָּקַר); a stab",
		"def" : "Deker, an Israelite",
		"kjv" : "Dekar"
	},
	"H1858" : {
		"lemma" : "דַּר",
		"xlit" : "dar",
		"pron" : "dar",
		"derivation" : "apparently from the same as H1865 (דְּרוֹר)",
		"def" : "properly, a pearl (from its sheen as rapidly turned); by analogy, pearl-stone, i.e. mother-of-pearl or alabaster",
		"kjv" : "[idiom] white"
	},
	"H1859" : {
		"lemma" : "דָּר",
		"xlit" : "dâr",
		"pron" : "dawr",
		"derivation" : "(Aramaic) corresponding to H1755 (דּוֹר)",
		"def" : "an age",
		"kjv" : "generation"
	},
	"H1860" : {
		"lemma" : "דְּרָאוֹן",
		"xlit" : "dᵉrâʼôwn",
		"pron" : "der-aw-one'",
		"derivation" : "or דֵּרָאוֹן; from an unused root (meaning to repulse)",
		"def" : "an object of aversion",
		"kjv" : "abhorring, contempt"
	},
	"H1861" : {
		"lemma" : "דׇּרְבוֹן",
		"xlit" : "dorbôwn",
		"pron" : "dor-bone'",
		"derivation" : "(also dor-bawn'); of uncertain derivation",
		"def" : "a goad",
		"kjv" : "goad"
	},
	"H1862" : {
		"lemma" : "דַּרְדַּע",
		"xlit" : "Dardaʻ",
		"pron" : "dar-dah'",
		"derivation" : "apparently from H1858 (דַּר) and H1843 (דֵּעַ); pearl of knowledge",
		"def" : "Darda, an Israelite",
		"kjv" : "Darda"
	},
	"H1863" : {
		"lemma" : "דַּרְדַּר",
		"xlit" : "dardar",
		"pron" : "dar-dar'",
		"derivation" : "of uncertain derivation",
		"def" : "a thorn",
		"kjv" : "thistle"
	},
	"H1864" : {
		"lemma" : "דָּרוֹם",
		"xlit" : "dârôwm",
		"pron" : "daw-rome'",
		"derivation" : "of uncertain derivation",
		"def" : "the south; poet. the south wind",
		"kjv" : "south"
	},
	"H1865" : {
		"lemma" : "דְּרוֹר",
		"xlit" : "dᵉrôwr",
		"pron" : "der-ore'",
		"derivation" : "from an unused root (meaning to move rapidly)",
		"def" : "freedom; hence, spontaneity of outflow, and so clear",
		"kjv" : "liberty, pure"
	},
	"H1866" : {
		"lemma" : "דְּרוֹר",
		"xlit" : "dᵉrôwr",
		"pron" : "der-ore'",
		"derivation" : "the same as H1865 (דְּרוֹר), applied to a bird",
		"def" : "the swift, a kind of swallow",
		"kjv" : "swallow"
	},
	"H1867" : {
		"lemma" : "דָּֽרְיָוֵשׁ",
		"xlit" : "Dârᵉyâvêsh",
		"pron" : "daw-reh-yaw-vaysh'",
		"derivation" : "of Persian origin",
		"def" : "Darejavesh, a title (rather than name) of several Persian kings",
		"kjv" : "Darius"
	},
	"H1868" : {
		"lemma" : "דָּֽרְיָוֵשׁ",
		"xlit" : "Dârᵉyâvêsh",
		"pron" : "daw-reh-yaw-vaysh'",
		"derivation" : "(Aramaic) corresponding to H1867 (דָּֽרְיָוֵשׁ)",
		"def" : "{Darejavesh, a title (rather than name) of several Persian kings}",
		"kjv" : "Darius"
	},
	"H1869" : {
		"lemma" : "דָּרַךְ",
		"xlit" : "dârak",
		"pron" : "daw-rak'",
		"derivation" : "a primitive root",
		"def" : "to tread; by implication, to walk; also to string abow (by treading on it in bending)",
		"kjv" : "archer, bend, come, draw, go (over), guide, lead (forth), thresh, tread (down), walk"
	},
	"H1870" : {
		"lemma" : "דֶּרֶךְ",
		"xlit" : "derek",
		"pron" : "deh'-rek",
		"derivation" : "from H1869 (דָּרַךְ)",
		"def" : "a road (as trodden); figuratively, a course of life or mode of action, often adverb",
		"kjv" : "along, away, because of, [phrase] by, conversation, custom, (east-) ward, journey, manner, passenger, through, toward, (high-) (path-) way(-side), whither(-soever)"
	},
	"H1871" : {
		"lemma" : "דַּרְכְּמוֹן",
		"xlit" : "darkᵉmôwn",
		"pron" : "dar-kem-one'",
		"derivation" : "of Persian origin",
		"def" : "a 'drachma', or coin",
		"kjv" : "dram"
	},
	"H1872" : {
		"lemma" : "דְּרַע",
		"xlit" : "dᵉraʻ",
		"pron" : "der-aw'",
		"derivation" : "(Aramaic) corresponding to H2220 (זְרוֹעַ)",
		"def" : "an arm",
		"kjv" : "arm"
	},
	"H1873" : {
		"lemma" : "דָּרַע",
		"xlit" : "Dâraʻ",
		"pron" : "daw-rah'",
		"derivation" : "probably a contraction from H1862 (דַּרְדַּע)",
		"def" : "Dara, an Israelite",
		"kjv" : "Dara"
	},
	"H1874" : {
		"lemma" : "דַּרְקוֹן",
		"xlit" : "Darqôwn",
		"pron" : "dar-kone'",
		"derivation" : "of uncertain derivation",
		"def" : "Darkon, one of 'Solomon's servants'",
		"kjv" : "Darkon"
	},
	"H1875" : {
		"lemma" : "דָּרַשׁ",
		"xlit" : "dârash",
		"pron" : "daw-rash'",
		"derivation" : "a primitive root",
		"def" : "properly, to tread or frequent; usually to follow (for pursuit or search); by implication, to seek or ask; specifically to worship",
		"kjv" : "ask, [idiom] at all, care for, [idiom] diligently, inquire, make inquisition, (necro-) mancer, question, require, search, seek (for, out), [idiom] surely"
	},
	"H1876" : {
		"lemma" : "דָּשָׁא",
		"xlit" : "dâshâʼ",
		"pron" : "daw-shaw'",
		"derivation" : "a primitive root",
		"def" : "to sprout",
		"kjv" : "bring forth, spring"
	},
	"H1877" : {
		"lemma" : "דֶּשֶׁא",
		"xlit" : "desheʼ",
		"pron" : "deh'-sheh",
		"derivation" : "from H1876 (דָּשָׁא)",
		"def" : "a sprout; by analogy, grass",
		"kjv" : "(tender) grass, green, (tender) herb"
	},
	"H1878" : {
		"lemma" : "דָּשֵׁן",
		"xlit" : "dâshên",
		"pron" : "daw-shane'",
		"derivation" : "a primitive root; also denominatively (from H1880 (דֶּשֶׁן))",
		"def" : "to be fat; transitively, to fatten (or regard as fat); specifically to anoint; figuratively, to satisfy;  to remove (fat) ashes (of sacrifices)",
		"kjv" : "accept, anoint, take away the (receive) ashes (from), make (wax) fat"
	},
	"H1879" : {
		"lemma" : "דָּשֵׁן",
		"xlit" : "dâshên",
		"pron" : "daw-shane'",
		"derivation" : "from H1878 (דָּשֵׁן)",
		"def" : "fat; figuratively, rich, fertile",
		"kjv" : "fat"
	},
	"H1880" : {
		"lemma" : "דֶּשֶׁן",
		"xlit" : "deshen",
		"pron" : "deh'-shen",
		"derivation" : "from H1878 (דָּשֵׁן)",
		"def" : "the fat; abstractly fatness, i.e. (figuratively) abundance; specifically the (fatty) ashes of sacrifices",
		"kjv" : "ashes, fatness"
	},
	"H1881" : {
		"lemma" : "דָּת",
		"xlit" : "dâth",
		"pron" : "dawth",
		"derivation" : "of uncertain (perhaps foreign) derivation",
		"def" : "a royal edict or statute",
		"kjv" : "commandment, commission, decree, law, manner"
	},
	"H1882" : {
		"lemma" : "דָּת",
		"xlit" : "dâth",
		"pron" : "dawth",
		"derivation" : "(Aramaic) corresponding to H1881 (דָּת)",
		"def" : "{a royal edict or statute}",
		"kjv" : "decree, law"
	},
	"H1883" : {
		"lemma" : "דֶּתֶא",
		"xlit" : "detheʼ",
		"pron" : "deh'-thay",
		"derivation" : "(Aramaic) corresponding to H1877 (דֶּשֶׁא)",
		"def" : "{a sprout; by analogy, grass}",
		"kjv" : "tender grass"
	},
	"H1884" : {
		"lemma" : "דְּתָבָר",
		"xlit" : "dᵉthâbâr",
		"pron" : "deth-aw-bawr'",
		"derivation" : "(Aramaic) of Persian origin",
		"def" : "meaning one skilled in law; a judge",
		"kjv" : "counsellor"
	},
	"H1885" : {
		"lemma" : "דָּתָן",
		"xlit" : "Dâthân",
		"pron" : "daw-thawn'",
		"derivation" : "of uncertain derivation",
		"def" : "Dathan, an Israelite",
		"kjv" : "Dathan"
	},
	"H1886" : {
		"lemma" : "דֹּתָן",
		"xlit" : "Dôthân",
		"pron" : "do'-thawn",
		"derivation" : "or (Aramaic dual) דֹּתַיִן; (Genesis 37:17), of uncertain derivation",
		"def" : "Dothan, a place in Palestine",
		"kjv" : "Dothan. h"
	},
	"H1887" : {
		"lemma" : "הֵא",
		"xlit" : "hêʼ",
		"pron" : "hay",
		"derivation" : "a primitive particle",
		"def" : "lo!",
		"kjv" : "behold, lo"
	},
	"H1888" : {
		"lemma" : "הֵא",
		"xlit" : "hêʼ",
		"pron" : "hay",
		"derivation" : "(Aramaic) or הָא; (Aramaic), corresponding to H1887 (הֵא)",
		"def" : "{lo!}",
		"kjv" : "even, lo"
	},
	"H1889" : {
		"lemma" : "הֶאָח",
		"xlit" : "heʼâch",
		"pron" : "heh-awkh'",
		"derivation" : "from H1887 (הֵא) and H253 (אָח)",
		"def" : "aha!",
		"kjv" : "ah, aha, ha"
	},
	"H1890" : {
		"lemma" : "הַבְהָב",
		"xlit" : "habhâb",
		"pron" : "hab-hawb'",
		"derivation" : "by reduplication from H3051 (יָהַב)",
		"def" : "gift (in sacrifice), i.e. holocaust",
		"kjv" : "offering"
	},
	"H1891" : {
		"lemma" : "הָבַל",
		"xlit" : "hâbal",
		"pron" : "haw-bal'",
		"derivation" : "a primitive root",
		"def" : "to be vain in act, word, or expectation; specifically to lead astray",
		"kjv" : "be (become, make) vain"
	},
	"H1892" : {
		"lemma" : "הֶבֶל",
		"xlit" : "hebel",
		"pron" : "heh'bel",
		"derivation" : "or (rarely in the abs.) הֲבֵל; from H1891 (הָבַל)",
		"def" : "emptiness or vanity; figuratively, something transitory and unsatisfactory; often used as an adverb",
		"kjv" : "[idiom] altogether, vain, vanity"
	},
	"H1893" : {
		"lemma" : "הֶבֶל",
		"xlit" : "Hebel",
		"pron" : "heh'-bel",
		"derivation" : "the same as H1892 (הֶבֶל)",
		"def" : "Hebel, the son of Adam",
		"kjv" : "Abel"
	},
	"H1894" : {
		"lemma" : "הֹבֶן",
		"xlit" : "hôben",
		"pron" : "ho'-ben",
		"derivation" : "only in plural, from an unused root meaning to be hard",
		"def" : "ebony",
		"kjv" : "ebony"
	},
	"H1895" : {
		"lemma" : "הָבַר",
		"xlit" : "hâbar",
		"pron" : "haw-bar'",
		"derivation" : "a primitive root of uncertain (perhaps foreign) derivation",
		"def" : "to be a horoscopist",
		"kjv" : "[phrase] (astro-) loger"
	},
	"H1896" : {
		"lemma" : "הֵגֵא",
		"xlit" : "Hêgêʼ",
		"pron" : "hay-gay'",
		"derivation" : "or (by permutation) הֵגַי; probably of Persian origin",
		"def" : "Hege or Hegai, a eunuch of Xerxes",
		"kjv" : "Hegai, Hege"
	},
	"H1897" : {
		"lemma" : "הָגָה",
		"xlit" : "hâgâh",
		"pron" : "daw-gaw'",
		"derivation" : "a primitive root (compare H1901 (הָגִיג))",
		"def" : "to murmur (in pleasure or anger); by implication, to ponder",
		"kjv" : "imagine, meditate, mourn, mutter, roar, [idiom] sore, speak, study, talk, utter"
	},
	"H1898" : {
		"lemma" : "הָגָה",
		"xlit" : "hâgâh",
		"pron" : "haw-gaw'",
		"derivation" : "a primitive root",
		"def" : "to remove",
		"kjv" : "stay, stay away"
	},
	"H1899" : {
		"lemma" : "הֶגֶה",
		"xlit" : "hegeh",
		"pron" : "heh'-geh",
		"derivation" : "from H1897 (הָגָה)",
		"def" : "a muttering (in sighing, thought, or as thunder)",
		"kjv" : "mourning, sound, tale"
	},
	"H1900" : {
		"lemma" : "הָגוּת",
		"xlit" : "hâgûwth",
		"pron" : "haw-gooth'",
		"derivation" : "from H1897 (הָגָה)",
		"def" : "musing",
		"kjv" : "meditation"
	},
	"H1901" : {
		"lemma" : "הָגִיג",
		"xlit" : "hâgîyg",
		"pron" : "haw-gheeg'",
		"derivation" : "from an unused root akin to H1897 (הָגָה)",
		"def" : "properly, a murmur, i.e. complaint",
		"kjv" : "meditation, musing"
	},
	"H1902" : {
		"lemma" : "הִגָּיוֹן",
		"xlit" : "higgâyôwn",
		"pron" : "hig-gaw-yone'",
		"derivation" : "intensive from H1897 (הָגָה)",
		"def" : "a murmuring sound, i.e. a musical notation (probably similar to the modern affettuoso to indicate solemnity of movement); by implication, a machination",
		"kjv" : "device, Higgaion, meditation, solemn sound"
	},
	"H1903" : {
		"lemma" : "הָגִין",
		"xlit" : "hâgîyn",
		"pron" : "haw-gheen'",
		"derivation" : "of uncertain derivation",
		"def" : "perhaps suitable or turning",
		"kjv" : "directly"
	},
	"H1904" : {
		"lemma" : "הָגָר",
		"xlit" : "Hâgâr",
		"pron" : "haw-gawr'",
		"derivation" : "of uncertain (perhaps foreign) derivation",
		"def" : "Hagar, the mother of Ishmael",
		"kjv" : "Hagar"
	},
	"H1905" : {
		"lemma" : "הַגְרִי",
		"xlit" : "Hagrîy",
		"pron" : "hag-ree'",
		"derivation" : "or (prolonged) הַגְרִיאxlit Hagrîʼ corrected to Hagrîyʼ; perhaps patronymically from H1904 (הָגָר)",
		"def" : "a Hagrite or member of a certain Arabian clan",
		"kjv" : "Hagarene, Hagarite, Haggeri"
	},
	"H1906" : {
		"lemma" : "הֵד",
		"xlit" : "hêd",
		"pron" : "hade",
		"derivation" : "for H1959 (הֵידָד)",
		"def" : "a shout",
		"kjv" : "sounding again"
	},
	"H1907" : {
		"lemma" : "הַדָּבָר",
		"xlit" : "haddâbâr",
		"pron" : "had-daw-bawr'",
		"derivation" : "(Aramaic) probably of foreign origin",
		"def" : "a vizier",
		"kjv" : "counsellor"
	},
	"H1908" : {
		"lemma" : "הֲדַד",
		"xlit" : "Hădad",
		"pron" : "had-ad'",
		"derivation" : "probably of foreign origin (compare H111 (אֲדַד))",
		"def" : "Hadad, the name of an idol, and of several kings of Edom",
		"kjv" : "Hadad"
	},
	"H1909" : {
		"lemma" : "הֲדַדְעֶזֶר",
		"xlit" : "Hădadʻezer",
		"pron" : "had-ad-eh'-zer",
		"derivation" : "from H1908 (הֲדַד) and H5828 (עֵזֶר); Hadad (is his) help",
		"def" : "Hadadezer, a Syrian king",
		"kjv" : "Hadadezer. Compare H1928 (הֲדַרְעֶזֶר)"
	},
	"H1910" : {
		"lemma" : "הֲדַדְרִמּוֹן",
		"xlit" : "Hădadrimmôwn",
		"pron" : "had-ad-rim-mone'",
		"derivation" : "from H1908 (הֲדַד) and H7417 (רִמּוֹן)",
		"def" : "Hadad-Rimmon, a place in Palestine",
		"kjv" : "Hadad-rimmon"
	},
	"H1911" : {
		"lemma" : "הָדָה",
		"xlit" : "hâdâh",
		"pron" : "haw-daw'",
		"derivation" : "a primitive root (compare H3034 (יָדָה))",
		"def" : "to stretch forth the hand",
		"kjv" : "put"
	},
	"H1912" : {
		"lemma" : "הֹדוּ",
		"xlit" : "Hôdûw",
		"pron" : "ho'-doo",
		"derivation" : "of foreign origin",
		"def" : "Hodu (i.e. Hindustan)",
		"kjv" : "India"
	},
	"H1913" : {
		"lemma" : "הֲדוֹרָם",
		"xlit" : "Hădôwrâm",
		"pron" : "had-o-rawm'",
		"derivation" : "or הֲדֹרָם; probably of foreign derivation",
		"def" : "Hadoram, a son of Joktan, and the tribe descended from him",
		"kjv" : "Hadoram"
	},
	"H1914" : {
		"lemma" : "הִדַּי",
		"xlit" : "Hidday",
		"pron" : "hid-dah'ee",
		"derivation" : "of uncertain derivation",
		"def" : "Hiddai, an Israelite",
		"kjv" : "Hiddai"
	},
	"H1915" : {
		"lemma" : "הָדַךְ",
		"xlit" : "hâdak",
		"pron" : "haw-dak'",
		"derivation" : "a primitive root (compare H1854 (דָּקַק))",
		"def" : "to crush with the foot",
		"kjv" : "tread down"
	},
	"H1916" : {
		"lemma" : "הֲדֹם",
		"xlit" : "hădôm",
		"pron" : "had-ome'",
		"derivation" : "from an unused root meaning to stamp upon",
		"def" : "a foot stool",
		"kjv" : "(foot-) stool"
	},
	"H1917" : {
		"lemma" : "הַדָּם",
		"xlit" : "haddâm",
		"pron" : "had-dawm'",
		"derivation" : "(Aramaic) from a root corresponding to that of H1916 (הֲדֹם)",
		"def" : "something stamped to pieces, i.e. a bit",
		"kjv" : "piece"
	},
	"H1918" : {
		"lemma" : "הֲדַס",
		"xlit" : "hădaç",
		"pron" : "had-as'",
		"derivation" : "of uncertain derivation",
		"def" : "the myrtle",
		"kjv" : "myrtle (tree)"
	},
	"H1919" : {
		"lemma" : "הֲדַסָּה",
		"xlit" : "Hădaççâh",
		"pron" : "had-as-saw'",
		"derivation" : "feminine of H1918 (הֲדַס)",
		"def" : "Hadassah (or Esther)",
		"kjv" : "Hadassah"
	},
	"H1920" : {
		"lemma" : "הָדַף",
		"xlit" : "Hâdaph",
		"pron" : "haw-daf'",
		"derivation" : "a primitive root",
		"def" : "to push away or down",
		"kjv" : "cast away (out), drive, expel, thrust (away)"
	},
	"H1921" : {
		"lemma" : "הָדַר",
		"xlit" : "hâdar",
		"pron" : "haw-dar'",
		"derivation" : "a primitive root",
		"def" : "to swell up (literally or figuratively, active or passive); by implication, to favor or honour, be high or proud",
		"kjv" : "countenance, crooked place, glorious, honour, put forth"
	},
	"H1922" : {
		"lemma" : "הֲדַר",
		"xlit" : "hădar",
		"pron" : "had-ar'",
		"derivation" : "(Aramaic) corresponding to H1921 (הָדַר)",
		"def" : "to magnify (figuratively)",
		"kjv" : "glorify, honour"
	},
	"H1923" : {
		"lemma" : "הֲדַר",
		"xlit" : "hădar",
		"pron" : "had-ar'",
		"derivation" : "(Aramaic) from H1922 (הֲדַר)lemma הֲדַּר extra dagesh, corrected to הֲדַר",
		"def" : "magnificence",
		"kjv" : "honour, majesty"
	},
	"H1924" : {
		"lemma" : "הֲדַר",
		"xlit" : "Hădar",
		"pron" : "had-ar'",
		"derivation" : "the same as H1926 (הָדָר)",
		"def" : "Hadar, an Edomite",
		"kjv" : "Hadar"
	},
	"H1925" : {
		"lemma" : "הֶדֶר",
		"xlit" : "heder",
		"pron" : "heh'-der",
		"derivation" : "from H1921 (הָדַר)",
		"def" : "honour; used (figuratively) for the capital city (Jerusalem)",
		"kjv" : "glory"
	},
	"H1926" : {
		"lemma" : "הָדָר",
		"xlit" : "hâdâr",
		"pron" : "haw-dawr'",
		"derivation" : "from H1921 (הָדַר)",
		"def" : "magnificence, i.e. ornament or splendor",
		"kjv" : "beauty, comeliness, excellency, glorious, glory, goodly, honour, majesty"
	},
	"H1927" : {
		"lemma" : "הֲדָרָה",
		"xlit" : "hădârâh",
		"pron" : "had-aw-raw'",
		"derivation" : "feminine of H1926 (הָדָר)",
		"def" : "decoration",
		"kjv" : "beauty, honour"
	},
	"H1928" : {
		"lemma" : "הֲדַרְעֶזֶר",
		"xlit" : "Hădarʻezer",
		"pron" : "had-ar-eh'-zer",
		"derivation" : "from H1924 (הֲדַר) and H5828 (עֵזֶר); Hadar (i.e. Hadad, H1908 (הֲדַד)) is his help; (i.e. Hadadezer, H1909 (הֲדַדְעֶזֶר))",
		"def" : "Hadarezer,  a Syrian king",
		"kjv" : "Hadarezer"
	},
	"H1929" : {
		"lemma" : "הָהּ",
		"xlit" : "hâhh",
		"pron" : "haw",
		"derivation" : "a shortened form of H162 (אֲהָהּ)",
		"def" : "ah! expressing grief",
		"kjv" : "woe worth"
	},
	"H1930" : {
		"lemma" : "הוֹ",
		"xlit" : "hôw",
		"pron" : "ho",
		"derivation" : "by permutation from H1929 (הָהּ)",
		"def" : "oh!",
		"kjv" : "alas"
	},
	"H1931" : {
		"lemma" : "הוּא",
		"xlit" : "hûwʼ",
		"pron" : "hoo",
		"derivation" : "of which the feminine (beyond the Pentateuch) is הִיא; a primitive word, the third person pronoun singular",
		"def" : "he (she or it); only expressed when emphatic or without a verb; also (intensively) self, or (especially with the article) the same; sometimes (as demonstrative) this or that; occasionally (instead of copula) as or are",
		"kjv" : "he, as for her, him(-self), it, the same, she (herself), such, that (...it), these, they, this, those, which (is), who"
	},
	"H1932" : {
		"lemma" : "הוּא",
		"xlit" : "hûwʼ",
		"pron" : "hoo",
		"derivation" : "(Aramaic) or (feminine) הִיא; (Aramaic), corresponding to H1931 (הוּא)",
		"def" : "{he (she or it); self, or (especially with the article) the same; sometimes (as demonstrative) this or that; occasionally (instead of copula) as or are}",
		"kjv" : "[idiom] are, it, this"
	},
	"H1933" : {
		"lemma" : "הָוָא",
		"xlit" : "hâvâʼ",
		"pron" : "haw-vaw'",
		"derivation" : "or הָוָה; a primitive root (compare H183 (אָוָה), H1961 (הָיָה)) supposed to mean properly, to breathe",
		"def" : "to be (in the sense of existence)",
		"kjv" : "be, [idiom] have"
	},
	"H1934" : {
		"lemma" : "הָוָא",
		"xlit" : "hâvâʼ",
		"pron" : "hav-aw'",
		"derivation" : "(Aramaic) or הָוָה; (Aramaic), corresponding to H1933 (הָוָא)",
		"def" : "to exist; used in a great variety of applications (especially in connection with other words)",
		"kjv" : "be, become, [phrase] behold, [phrase] came (to pass), [phrase] cease, [phrase] cleave, [phrase] consider, [phrase] do, [phrase] give, [phrase] have, [phrase] judge, [phrase] keep, [phrase] labour, [phrase] mingle (self), [phrase] put, [phrase] see, [phrase] seek, [phrase] set, [phrase] slay, [phrase] take heed, tremble, [phrase] walk, [phrase] would"
	},
	"H1935" : {
		"lemma" : "הוֹד",
		"xlit" : "hôwd",
		"pron" : "hode",
		"derivation" : "from an unused root",
		"def" : "grandeur (i.e. an imposing form and appearance)",
		"kjv" : "beauty, comeliness, excellency, glorious, glory, goodly, honour, majesty"
	},
	"H1936" : {
		"lemma" : "הוֹד",
		"xlit" : "Hôwd",
		"pron" : "hode",
		"derivation" : "the same as H1935 (הוֹד)",
		"def" : "Hod, an Israelite",
		"kjv" : "Hod"
	},
	"H1937" : {
		"lemma" : "הוֹדְוָה",
		"xlit" : "Hôwdᵉvâh",
		"pron" : "ho-dev-aw'",
		"derivation" : "a form of H1938 (הוֹדַוְיָה)",
		"def" : "Hodevah (or Hodevjah), an Israelite",
		"kjv" : "Hodevah"
	},
	"H1938" : {
		"lemma" : "הוֹדַוְיָה",
		"xlit" : "Hôwdavyâh",
		"pron" : "ho-dav-yaw'",
		"derivation" : "from H1935 (הוֹד) and H3050 (יָהּ); majesty of Jah",
		"def" : "Hodavjah, the name of three Israelites",
		"kjv" : "Hodaviah"
	},
	"H1939" : {
		"lemma" : "הוֹדַיְוָהוּ",
		"xlit" : "Hôwdayvâhûw",
		"pron" : "ho-dah-yeh-vaw'-hoo",
		"derivation" : "a form of H1938 (הוֹדַוְיָה)",
		"def" : "Hodajvah, an Israelite",
		"kjv" : "Hodaiah"
	},
	"H1940" : {
		"lemma" : "הוֹדִיָּה",
		"xlit" : "Hôwdîyâh",
		"pron" : "ho-dee-yaw'",
		"derivation" : "a form for the feminine of H3064 (יְהוּדִי)",
		"def" : "a Jewess",
		"kjv" : "Hodiah"
	},
	"H1941" : {
		"lemma" : "הוֹדִיָּה",
		"xlit" : "Hôwdîyâh",
		"pron" : "ho-dee-yaw'",
		"derivation" : "a form of H1938 (הוֹדַוְיָה)",
		"def" : "Hodijah, the name of three Israelites",
		"kjv" : "Hodijah"
	},
	"H1942" : {
		"lemma" : "הַוָּה",
		"xlit" : "havvâh",
		"pron" : "hav-vaw'",
		"derivation" : "from H1933 (הָוָא) (in the sense of eagerly coveting and rushing upon",
		"def" : "by implication, of falling); desire; also ruin",
		"kjv" : "calamity, iniquity, mischief, mischievous (thing), naughtiness, naughty, noisome, perverse thing, substance, very wickedness"
	},
	"H1943" : {
		"lemma" : "הֹוָה",
		"xlit" : "hôvâh",
		"pron" : "ho-vaw'",
		"derivation" : "another form for H1942 (הַוָּה)",
		"def" : "ruin",
		"kjv" : "mischief"
	},
	"H1944" : {
		"lemma" : "הוֹהָם",
		"xlit" : "Hôwhâm",
		"pron" : "ho-hawm'",
		"derivation" : "of uncertain derivation",
		"def" : "Hoham, a Canaanitish king",
		"kjv" : "Hoham"
	},
	"H1945" : {
		"lemma" : "הוֹי",
		"xlit" : "hôwy",
		"pron" : "hoh'ee",
		"derivation" : "a prolonged form of H1930 (הוֹ) (akin to H188 (אוֹי))",
		"def" : "oh!",
		"kjv" : "ah, alas, ho, O, woe"
	},
	"H1946" : {
		"lemma" : "הוּךְ",
		"xlit" : "hûwk",
		"pron" : "hook",
		"derivation" : "(Aramaic) corresponding to H1981 (הֲלַךְ)",
		"def" : "to go; causatively, to bring",
		"kjv" : "bring again, come, go (up)"
	},
	"H1947" : {
		"lemma" : "הוֹלֵלָה",
		"xlit" : "hôwlêlâh",
		"pron" : "ho-lay-law'",
		"derivation" : "feminine active participle of H1984 (הָלַל)",
		"def" : "folly",
		"kjv" : "madness"
	},
	"H1948" : {
		"lemma" : "הוֹלֵלוּת",
		"xlit" : "hôwlêlûwth",
		"pron" : "ho-lay-looth'",
		"derivation" : "from active participle of H1984 (הָלַל)",
		"def" : "folly",
		"kjv" : "madness"
	},
	"H1949" : {
		"lemma" : "הוּם",
		"xlit" : "hûwm",
		"pron" : "hoom",
		"derivation" : "a primitive root (compare H2000 (הָמַם))",
		"def" : "to make an uproar, or agitate greatly",
		"kjv" : "destroy, move, make a noise, put, ring again"
	},
	"H1950" : {
		"lemma" : "הוֹמָם",
		"xlit" : "Hôwmâm",
		"pron" : "ho-mawm'",
		"derivation" : "from H2000 (הָמַם); raging",
		"def" : "Homam, an Edomitish chieftain",
		"kjv" : "Homam. Compare H1967 (הֵימָם)"
	},
	"H1951" : {
		"lemma" : "הוּן",
		"xlit" : "hûwn",
		"pron" : "hoon",
		"derivation" : "a primitive root",
		"def" : "properly, to be naught, i.e. (figuratively) to be (causatively, act) light",
		"kjv" : "be ready"
	},
	"H1952" : {
		"lemma" : "הוֹן",
		"xlit" : "hôwn",
		"pron" : "hone",
		"derivation" : "from the same as H1951 (הוּן) in the sense of H202 (אוֹן)",
		"def" : "wealth; by implication, enough",
		"kjv" : "enough, [phrase] for nought, riches, substance, wealth"
	},
	"H1953" : {
		"lemma" : "הוֹשָׁמָע",
		"xlit" : "Hôwshâmâʻ",
		"pron" : "ho-shaw-maw'",
		"derivation" : "from H3068 (יְהֹוָה) and H8085 (שָׁמַע); Jehovah has heard",
		"def" : "Hoshama, an Israelite",
		"kjv" : "Hoshama"
	},
	"H1954" : {
		"lemma" : "הוֹשֵׁעַ",
		"xlit" : "Hôwshêaʻ",
		"pron" : "ho-shay'-ah",
		"derivation" : "from H3467 (יָשַׁע); deliverer",
		"def" : "Hoshea, the name of five Israelites",
		"kjv" : "Hosea, Hoshea, Oshea"
	},
	"H1955" : {
		"lemma" : "הוֹשַׁעְיָה",
		"xlit" : "Hôwshaʻyâh",
		"pron" : "ho-shah-yaw'",
		"derivation" : "from H3467 (יָשַׁע) and H3050 (יָהּ); Jah has saved",
		"def" : "Hoshajah, the name of two Israelites",
		"kjv" : "Hoshaiah"
	},
	"H1956" : {
		"lemma" : "הוֹתִיר",
		"xlit" : "Hôwthîyr",
		"pron" : "ho-theer'",
		"derivation" : "from H3498 (יָתַר); he has caused to remain",
		"def" : "Hothir, an Israelite",
		"kjv" : "Hothir"
	},
	"H1957" : {
		"lemma" : "הָזָה",
		"xlit" : "hâzâh",
		"pron" : "haw-zaw'",
		"derivation" : "a primitive root (compare H2372 (חָזָה))",
		"def" : "to dream",
		"kjv" : "sleep"
	},
	"H1958" : {
		"lemma" : "הִי",
		"xlit" : "hîy",
		"pron" : "he",
		"derivation" : "for H5092 (נְהִי)",
		"def" : "lamentation",
		"kjv" : "woe. (For hiyr. See H1931 (הוּא), H1932 (הוּא).)"
	},
	"H1959" : {
		"lemma" : "הֵידָד",
		"xlit" : "hêydâd",
		"pron" : "hay-dawd'",
		"derivation" : "from an unused root (meaning to shout)",
		"def" : "acclamation",
		"kjv" : "shout(-ing)"
	},
	"H1960" : {
		"lemma" : "הֻיְּדָה",
		"xlit" : "huyᵉdâh",
		"pron" : "hoo-yed-aw'",
		"derivation" : "from the same as H1959 (הֵידָד)",
		"def" : "properly, an acclaim, i.e. a choir of singers",
		"kjv" : "thanksgiving"
	},
	"H1961" : {
		"lemma" : "הָיָה",
		"xlit" : "hâyâh",
		"pron" : "haw-yaw",
		"derivation" : "a primitive root (compare H1933 (הָוָא))",
		"def" : "to exist, i.e. be or become, come to pass (always emphatic, and not a mere copula or auxiliary)",
		"kjv" : "beacon, [idiom] altogether, be(-come), accomplished, committed, like), break, cause, come (to pass), do, faint, fall, [phrase] follow, happen, [idiom] have, last, pertain, quit (one-) self, require, [idiom] use"
	},
	"H1962" : {
		"lemma" : "הַיָּה",
		"xlit" : "hayâh",
		"pron" : "hah-yaw'",
		"derivation" : "another form for H1943 (הֹוָה)",
		"def" : "ruin",
		"kjv" : "calamity"
	},
	"H1963" : {
		"lemma" : "הֵיךְ",
		"xlit" : "hêyk",
		"pron" : "hake",
		"derivation" : "another form for H349 (אֵיךְ)",
		"def" : "how?",
		"kjv" : "how"
	},
	"H1964" : {
		"lemma" : "הֵיכָל",
		"xlit" : "hêykâl",
		"pron" : "hay-kawl'",
		"derivation" : "probably from H3201 (יָכֹל) (in the sense of capacity)",
		"def" : "a large public building, such as a palace or temple",
		"kjv" : "palace, temple"
	},
	"H1965" : {
		"lemma" : "הֵיכַל",
		"xlit" : "hêykal",
		"pron" : "hay-kal'",
		"derivation" : "(Aramaic) corresponding to H1964 (הֵיכָל)",
		"def" : "{a large public building, such as a palace or temple}",
		"kjv" : "palace, temple"
	},
	"H1966" : {
		"lemma" : "הֵילֵל",
		"xlit" : "hêylêl",
		"pron" : "hay-lale'",
		"derivation" : "from H1984 (הָלַל) (in the sense of brightness)",
		"def" : "the morning-star",
		"kjv" : "lucifer"
	},
	"H1967" : {
		"lemma" : "הֵימָם",
		"xlit" : "Hêymâm",
		"pron" : "hay-mawm'",
		"derivation" : "another form for H1950 (הוֹמָם)",
		"def" : "Hemam, an Idumaean",
		"kjv" : "Hemam"
	},
	"H1968" : {
		"lemma" : "הֵימָן",
		"xlit" : "Hêymân",
		"pron" : "hay-mawn'",
		"derivation" : "probably from H539 (אָמַן); faithful",
		"def" : "Heman, the name of at least two Israelites",
		"kjv" : "Heman"
	},
	"H1969" : {
		"lemma" : "הִין",
		"xlit" : "hîyn",
		"pron" : "heen",
		"derivation" : "probably of Egyptian origin",
		"def" : "a hin or liquid measure",
		"kjv" : "hin"
	},
	"H1970" : {
		"lemma" : "הָכַר",
		"xlit" : "hâkar",
		"pron" : "haw-kar'",
		"derivation" : "a primitive root; apparently",
		"def" : "to injure",
		"kjv" : "make self strange"
	},
	"H1971" : {
		"lemma" : "הַכָּרָה",
		"xlit" : "hakkârâh",
		"pron" : "hak-kaw-raw'",
		"derivation" : "from H5234 (נָכַר)",
		"def" : "respect, i.e. partiality",
		"kjv" : "shew"
	},
	"H1972" : {
		"lemma" : "הָלָא",
		"xlit" : "hâlâʼ",
		"pron" : "haw-law'",
		"derivation" : "probably denominatively from H1973 (הָלְאָה)xlit hâlᵉâh corrected to hâlᵉʼâh",
		"def" : "to remove or be remote",
		"kjv" : "cast far off"
	},
	"H1973" : {
		"lemma" : "הָלְאָה",
		"xlit" : "hâlᵉʼâh",
		"pron" : "haw-leh-aw'",
		"derivation" : "from the primitive form of the article (hal)",
		"def" : "to the distance, i.e. far away; also (of time) thus far",
		"kjv" : "back, beyond, (hence,-) forward, hitherto, thence, forth, yonder"
	},
	"H1974" : {
		"lemma" : "הִלּוּל",
		"xlit" : "hillûwl",
		"pron" : "hil-lool'",
		"derivation" : "from H1984 (הָלַל) (in the sense of rejoicing)",
		"def" : "a celebration of thanksgiving for harvest",
		"kjv" : "merry, praise"
	},
	"H1975" : {
		"lemma" : "הַלָּז",
		"xlit" : "hallâz",
		"pron" : "hal-lawz'",
		"derivation" : "from H1976 (הַלָּזֶה)",
		"def" : "this or that",
		"kjv" : "side, that, this"
	},
	"H1976" : {
		"lemma" : "הַלָּזֶה",
		"xlit" : "hallâzeh",
		"pron" : "hal-law-zeh'",
		"derivation" : "from the article (see H1973 (הָלְאָה)xlit hâlᵉâh corrected to hâlᵉʼâh) and H2088 (זֶה)",
		"def" : "this very",
		"kjv" : "this"
	},
	"H1977" : {
		"lemma" : "הַלֵּזוּ",
		"xlit" : "hallêzûw",
		"pron" : "hal-lay-zoo'",
		"derivation" : "another form of H1976 (הַלָּזֶה)",
		"def" : "that",
		"kjv" : "this"
	},
	"H1978" : {
		"lemma" : "הָלִיךְ",
		"xlit" : "hâlîyk",
		"pron" : "haw-leek'",
		"derivation" : "from H1980 (הָלַךְ)",
		"def" : "a walk, i.e. (by implication) a step",
		"kjv" : "step"
	},
	"H1979" : {
		"lemma" : "הֲלִיכָה",
		"xlit" : "hălîykâh",
		"pron" : "hal-ee-kaw'",
		"derivation" : "feminine of H1978 (הָלִיךְ)",
		"def" : "a walking; by implication, a procession or march, a caravan",
		"kjv" : "company, going, walk, way"
	},
	"H1980" : {
		"lemma" : "הָלַךְ",
		"xlit" : "hâlak",
		"pron" : "haw-lak'",
		"derivation" : "akin to H3212 (יָלַךְ); a primitive root",
		"def" : "to walk (in a great variety of applications, literally and figuratively)",
		"kjv" : "(all) along, apace, behave (self), come, (on) continually, be conversant, depart, [phrase] be eased, enter, exercise (self), [phrase] follow, forth, forward, get, go (about, abroad, along, away, forward, on, out, up and down), [phrase] greater, grow, be wont to haunt, lead, march, [idiom] more and more, move (self), needs, on, pass (away), be at the point, quite, run (along), [phrase] send, speedily, spread, still, surely, [phrase] tale-bearer, [phrase] travel(-ler), walk (abroad, on, to and fro, up and down, to places), wander, wax, (way-) faring man, [idiom] be weak, whirl"
	},
	"H1981" : {
		"lemma" : "הֲלַךְ",
		"xlit" : "hălak",
		"pron" : "hal-ak'",
		"derivation" : "(Aramaic) corresponding to H1980 (הָלַךְ) (compare H1946 (הוּךְ))",
		"def" : "to walk",
		"kjv" : "walk"
	},
	"H1982" : {
		"lemma" : "הֵלֶךְ",
		"xlit" : "hêlek",
		"pron" : "hay'-lek",
		"derivation" : "from H1980 (הָלַךְ)",
		"def" : "properly, a journey, i.e. (by implication) a wayfarer; also a flowing",
		"kjv" : "[idiom] dropped, traveller"
	},
	"H1983" : {
		"lemma" : "הֲלָךְ",
		"xlit" : "hălâk",
		"pron" : "hal-awk'",
		"derivation" : "(Aramaic) from H1981 (הֲלַךְ)",
		"def" : "properly, a journey, i.e. (by implication) toll on goods at aroad",
		"kjv" : "custom"
	},
	"H1984" : {
		"lemma" : "הָלַל",
		"xlit" : "hâlal",
		"pron" : "haw-lal'",
		"derivation" : "a primitive root",
		"def" : "to be clear (orig. of sound, but usually of color); to shine; hence, to make ashow, to boast; and thus to be (clamorously) foolish; to rave; causatively, to celebrate; also to stultify",
		"kjv" : "(make) boast (self), celebrate, commend, (deal, make), fool(-ish, -ly), glory, give (light), be (make, feign self) mad (against), give in marriage, (sing, be worthy of) praise, rage, renowned, shine"
	},
	"H1985" : {
		"lemma" : "הִלֵּל",
		"xlit" : "Hillêl",
		"pron" : "hil-layl'",
		"derivation" : "from H1984 (הָלַל); praising (namely God)",
		"def" : "Hillel, an Israelite",
		"kjv" : "Hillel"
	},
	"H1986" : {
		"lemma" : "הָלַם",
		"xlit" : "hâlam",
		"pron" : "haw-lam'",
		"derivation" : "a primitive root",
		"def" : "to strike down; by implication, to hammer, stamp, conquer, disband",
		"kjv" : "beat (down), break (down), overcome, smite (with the hammer)"
	},
	"H1987" : {
		"lemma" : "הֶלֶם",
		"xlit" : "Helem",
		"pron" : "hay'-lem",
		"derivation" : "from H1986 (הָלַם); smiter",
		"def" : "Helem, the name of two Israelites",
		"kjv" : "Helem"
	},
	"H1988" : {
		"lemma" : "הֲלֹם",
		"xlit" : "hălôm",
		"pron" : "hal-ome'",
		"derivation" : "from the article (see H1973 (הָלְאָה)xlit hâlᵉâh corrected to hâlᵉʼâh)",
		"def" : "hither",
		"kjv" : "here, hither(-(to)), thither"
	},
	"H1989" : {
		"lemma" : "הַלְמוּת",
		"xlit" : "halmûwth",
		"pron" : "hal-mooth'",
		"derivation" : "from H1986 (הָלַם)",
		"def" : "a hammer (or mallet)",
		"kjv" : "hammer"
	},
	"H1990" : {
		"lemma" : "הָם",
		"xlit" : "Hâm",
		"pron" : "hawm",
		"derivation" : "of uncertain derivation",
		"def" : "Ham, a region of Palestine",
		"kjv" : "Ham"
	},
	"H1991" : {
		"lemma" : "הֵם",
		"xlit" : "hêm",
		"pron" : "haym",
		"derivation" : "from H1993 (הָמָה)",
		"def" : "abundance, i.e. wealth",
		"kjv" : "any of theirs"
	},
	"H1992" : {
		"lemma" : "הֵם",
		"xlit" : "hêm",
		"pron" : "haym",
		"derivation" : "or (prolonged) הֵמָּה; masculine plural from H1981 (הֲלַךְ)",
		"def" : "they (only used when emphatic)",
		"kjv" : "it, like, [idiom] (how, so) many (soever, more as) they (be), (the) same, [idiom] so, [idiom] such, their, them, these, they, those, which, who, whom, withal, ye"
	},
	"H1993" : {
		"lemma" : "הָמָה",
		"xlit" : "hâmâh",
		"pron" : "haw-maw'",
		"derivation" : "a primitive root (compare H1949 (הוּם))",
		"def" : "to make a loud sound (like English 'hum'); by implication, to be in great commotion or tumult, to rage, war, moan, clamor",
		"kjv" : "clamorous, concourse, cry aloud, be disquieted, loud, mourn, be moved, make a noise, rage, roar, sound, be troubled, make in tumult, tumultuous, be in an uproar"
	},
	"H1994" : {
		"lemma" : "הִמּוֹ",
		"xlit" : "himmôw",
		"pron" : "him-mo'",
		"derivation" : "(Aramaic) or (prolonged) הִמּוֹן; (Aramaic), corresponding to H1992 (הֵם)",
		"def" : "they",
		"kjv" : "[idiom] are, them, those"
	},
	"H1995" : {
		"lemma" : "הָמוֹן",
		"xlit" : "hâmôwn",
		"pron" : "haw-mone'",
		"derivation" : "or הָמֹן; (Ezekiel 5:7), from H1993 (הָמָה)",
		"def" : "a noise, tumult, crowd; also disquietude, wealth",
		"kjv" : "abundance, company, many, multitude, multiply, noise, riches, rumbling, sounding, store, tumult"
	},
	"H1996" : {
		"lemma" : "הֲמוֹן גּוֹג",
		"xlit" : "Hămôwn Gôwg",
		"pron" : "ham-one' gohg",
		"derivation" : "from H1995 (הָמוֹן) and H1463 (גּוֹג)",
		"def" : "the multitude of Gog; the fanciful name of an emblematic place in Palestine",
		"kjv" : "Hamogog"
	},
	"H1997" : {
		"lemma" : "הֲמוֹנָה",
		"xlit" : "Hămôwnâh",
		"pron" : "ham-o-naw'",
		"derivation" : "feminine of H1995 (הָמוֹן); multitude; , the same as H1996 (הֲמוֹן גּוֹג)",
		"def" : "Hamonah",
		"kjv" : "Hamonah"
	},
	"H1998" : {
		"lemma" : "הֶמְיָה",
		"xlit" : "hemyâh",
		"pron" : "hem-yaw'",
		"derivation" : "from H1993 (הָמָה)",
		"def" : "sound",
		"kjv" : "noise"
	},
	"H1999" : {
		"lemma" : "הֲמֻלָּה",
		"xlit" : "hămullâh",
		"pron" : "ham-ool-law'",
		"derivation" : "or (too fully) הֲמוּלָּה; (Jeremiah 11:16), feminine passive participle of an unused root meaning to rush (as rain with a windy roar)",
		"def" : "a sound",
		"kjv" : "speech, tumult"
	},
	"H2000" : {
		"lemma" : "הָמַם",
		"xlit" : "hâmam",
		"pron" : "haw-mam'",
		"derivation" : "a primitive root (compare H1949 (הוּם), H1993 (הָמָה))",
		"def" : "properly, to put in commotion; by implication, to disturb, drive, destroy",
		"kjv" : "break, consume, crush, destroy, discomfit, trouble, vex"
	},
	"H2001" : {
		"lemma" : "הָמָן",
		"xlit" : "Hâmân",
		"pron" : "haw-mawn'",
		"derivation" : "of foreign derivation",
		"def" : "Haman, a Persian vizier",
		"kjv" : "Haman"
	},
	"H2002" : {
		"lemma" : "הַמְנִיךְ",
		"xlit" : "hamnîyk",
		"pron" : "ham-neek'",
		"derivation" : "(Aramaic) but the text is הֲמוּנֵךְ; of foreign origin",
		"def" : "a necklace",
		"kjv" : "chain"
	},
	"H2003" : {
		"lemma" : "הָמָס",
		"xlit" : "hâmâç",
		"pron" : "haw-mawce'",
		"derivation" : "from an unused root apparently meaning to crackle",
		"def" : "a dry twig or brushwood",
		"kjv" : "melting"
	},
	"H2004" : {
		"lemma" : "הֵן",
		"xlit" : "hên",
		"pron" : "hane",
		"derivation" : "feminine plural from H1931 (הוּא)",
		"def" : "they (only used when emphatic)",
		"kjv" : "[idiom] in, such like, (with) them, thereby, therein, (more than) they, wherein, in which, whom, withal"
	},
	"H2005" : {
		"lemma" : "הֵן",
		"xlit" : "hên",
		"pron" : "hane",
		"derivation" : "a primitive particle",
		"def" : "lo!; also (as expressing surprise) if",
		"kjv" : "behold, if, lo, though"
	},
	"H2006" : {
		"lemma" : "הֵן",
		"xlit" : "hên",
		"pron" : "hane",
		"derivation" : "(Aramaic) corresponding to H2005 (הֵן)",
		"def" : "lo! also there(-fore), (un-) less, whether, but, if",
		"kjv" : "(that) if, or, whether"
	},
	"H2007" : {
		"lemma" : "הֵנָּה",
		"xlit" : "hênnâh",
		"pron" : "hane'-naw",
		"derivation" : "prolongation for H2004 (הֵן)",
		"def" : "themselves (often used emphatic for the copula, also in indirect relation)",
		"kjv" : "[idiom] in, [idiom] such (and such things), their, (into) them, thence, therein, these, they (had), on this side, whose, wherein"
	},
	"H2008" : {
		"lemma" : "הֵנָּה",
		"xlit" : "hênnâh",
		"pron" : "hane'-naw",
		"derivation" : "from H2004 (הֵן)",
		"def" : "hither or thither (but used both of place and time)",
		"kjv" : "here, hither(-to), now, on this (that) side, [phrase] since, this (that) way, thitherward, [phrase] thus far, to...fro, [phrase] yet"
	},
	"H2009" : {
		"lemma" : "הִנֵּה",
		"xlit" : "hinnêh",
		"pron" : "hin-nay'",
		"derivation" : "prolongation for H2005 (הֵן)",
		"def" : "lo!",
		"kjv" : "behold, lo, see"
	},
	"H2010" : {
		"lemma" : "הֲנָחָה",
		"xlit" : "hănâchâh",
		"pron" : "han-aw-khaw'",
		"derivation" : "from H5117 (נוּחַ)",
		"def" : "permission of rest, i.e. quiet",
		"kjv" : "release"
	},
	"H2011" : {
		"lemma" : "הִנֹּם",
		"xlit" : "Hinnôm",
		"pron" : "hin-nome'",
		"derivation" : "probably of foreign origin",
		"def" : "Hinnom, apparently a Jebusite",
		"kjv" : "Hinnom"
	},
	"H2012" : {
		"lemma" : "הֵנַע",
		"xlit" : "Hênaʻ",
		"pron" : "hay-nah'",
		"derivation" : "probably of foreign derivation",
		"def" : "Hena, a place apparently in Mesopotamia",
		"kjv" : "Hena"
	},
	"H2013" : {
		"lemma" : "הָסָה",
		"xlit" : "hâçâh",
		"pron" : "haw-saw'",
		"derivation" : "a primitive root",
		"def" : "to hush",
		"kjv" : "hold peace (tongue), (keep) silence, be silent, still"
	},
	"H2014" : {
		"lemma" : "הֲפֻגָה",
		"xlit" : "hăphugâh",
		"pron" : "haf-oo-gaw'",
		"derivation" : "from H6313 (פּוּג)",
		"def" : "relaxation",
		"kjv" : "intermission"
	},
	"H2015" : {
		"lemma" : "הָפַךְ",
		"xlit" : "hâphak",
		"pron" : "haw-fak'",
		"derivation" : "a primitive root",
		"def" : "to turn about or over; by implication, to change, overturn, return, pervert",
		"kjv" : "[idiom] become, change, come, be converted, give, make (a bed), overthrow (-turn), perverse, retire, tumble, turn (again, aside, back, to the contrary, every way)"
	},
	"H2016" : {
		"lemma" : "הֶפֶךְ",
		"xlit" : "hephek",
		"pron" : "heh'-fek",
		"derivation" : "; or הֵפֶךְ; from H2015 (הָפַךְ)",
		"def" : "a turn, i.e. the reverse",
		"kjv" : "contrary"
	},
	"H2017" : {
		"lemma" : "הֹפֶךְ",
		"xlit" : "hôphek",
		"pron" : "ho'-fek",
		"derivation" : "from H2015 (הָפַךְ)",
		"def" : "an upset, i.e. (abstract) perversity",
		"kjv" : "turning of things upside down"
	},
	"H2018" : {
		"lemma" : "הֲפֵכָה",
		"xlit" : "hăphêkâh",
		"pron" : "haf-ay-kaw'",
		"derivation" : "feminine of H2016 (הֶפֶךְ)",
		"def" : "destruction",
		"kjv" : "overthrow"
	},
	"H2019" : {
		"lemma" : "הֲפַכְפַּךְ",
		"xlit" : "hăphakpak",
		"pron" : "haf-ak-pak'",
		"derivation" : "by reduplication from H2015 (הָפַךְ)",
		"def" : "very perverse",
		"kjv" : "froward"
	},
	"H2020" : {
		"lemma" : "הַצָּלָה",
		"xlit" : "hatstsâlâh",
		"pron" : "hats-tsaw-law'",
		"derivation" : "from H5337 (נָצַל)",
		"def" : "rescue",
		"kjv" : "deliverance"
	},
	"H2021" : {
		"lemma" : "הֹצֶן",
		"xlit" : "hôtsen",
		"pron" : "ho'-tsen",
		"derivation" : "from an unused root meaning apparently to be sharp or strong",
		"def" : "a weapon of war",
		"kjv" : "chariot"
	},
	"H2022" : {
		"lemma" : "הַר",
		"xlit" : "har",
		"pron" : "har",
		"derivation" : "a shortened form of H2042 (הָרָר)",
		"def" : "a mountain or range of hills (sometimes used figuratively)",
		"kjv" : "hill (country), mount(-ain), [idiom] promotion"
	},
	"H2023" : {
		"lemma" : "הֹר",
		"xlit" : "Hôr",
		"pron" : "hore",
		"derivation" : "another form of H2022 (הַר); mountain",
		"def" : "Hor, the name of a peak in Idumaea and of one in Syria",
		"kjv" : "Hor"
	},
	"H2024" : {
		"lemma" : "הָרָא",
		"xlit" : "Hârâʼ",
		"pron" : "haw-raw'",
		"derivation" : "perhaps from H2022 (הַר); mountainousness",
		"def" : "Hara, a region of Media",
		"kjv" : "Hara"
	},
	"H2025" : {
		"lemma" : "הַרְאֵל",
		"xlit" : "harʼêl",
		"pron" : "har-ale'",
		"derivation" : "from H2022 (הַר) and H410 (אֵל)",
		"def" : "mount of God; figuratively, the altar of burnt-offering",
		"kjv" : "altar. Compare H739 (אֲרִיאֵל)"
	},
	"H2026" : {
		"lemma" : "הָרַג",
		"xlit" : "hârag",
		"pron" : "haw-rag'",
		"derivation" : "a primitive root",
		"def" : "to smite with deadly intent",
		"kjv" : "destroy, out of hand, kill, murder(-er), put to (death), make (slaughter), slay(-er), [idiom] surely"
	},
	"H2027" : {
		"lemma" : "הֶרֶג",
		"xlit" : "hereg",
		"pron" : "heh'-reg",
		"derivation" : "from H2026 (הָרַג)",
		"def" : "slaughter",
		"kjv" : "be slain, slaughter"
	},
	"H2028" : {
		"lemma" : "הֲרֵגָה",
		"xlit" : "hărêgâh",
		"pron" : "har-ay-gaw'",
		"derivation" : "feminine of H2027 (הֶרֶג)",
		"def" : "slaughter",
		"kjv" : "slaughter"
	},
	"H2029" : {
		"lemma" : "הָרָה",
		"xlit" : "hârâh",
		"pron" : "haw-raw'",
		"derivation" : "a primitive root",
		"def" : "to be (or become) pregnant, conceive (literally or figuratively)",
		"kjv" : "been, be with child, conceive, progenitor"
	},
	"H2030" : {
		"lemma" : "הָרֶה",
		"xlit" : "hâreh",
		"pron" : "haw-reh'",
		"derivation" : "or הָרִי; (Hosea 14:1), from H2029 (הָרָה)",
		"def" : "pregnant",
		"kjv" : "(be, woman) with child, conceive, [idiom] great"
	},
	"H2031" : {
		"lemma" : "הַרְהֹר",
		"xlit" : "harhôr",
		"pron" : "har-hor'",
		"derivation" : "(Aramaic) from a root corresponding to H2029 (הָרָה)",
		"def" : "a mental conception",
		"kjv" : "thought"
	},
	"H2032" : {
		"lemma" : "הֵרוֹן",
		"xlit" : "hêrôwn",
		"pron" : "hay-rone'",
		"derivation" : "or הֵרָיוֹן; from H2029 (הָרָה)",
		"def" : "pregnancy",
		"kjv" : "conception"
	},
	"H2033" : {
		"lemma" : "הֲרוֹרִי",
		"xlit" : "Hărôwrîy",
		"pron" : "har-o-ree'",
		"derivation" : "another form for H2043 (הֲרָרִי)",
		"def" : "a Harorite or mountaineer",
		"kjv" : "Harorite"
	},
	"H2034" : {
		"lemma" : "הֲרִיסָה",
		"xlit" : "hărîyçâh",
		"pron" : "har-ee-saw'",
		"derivation" : "from H2040 (הָרַס)",
		"def" : "something demolished",
		"kjv" : "ruin"
	},
	"H2035" : {
		"lemma" : "הֲרִיסוּת",
		"xlit" : "hărîyçûwth",
		"pron" : "har-ee-sooth'",
		"derivation" : "from H2040 (הָרַס)",
		"def" : "demolition",
		"kjv" : "destruction"
	},
	"H2036" : {
		"lemma" : "הֹרָם",
		"xlit" : "Hôrâm",
		"pron" : "ho-rawm'",
		"derivation" : "from an unused root (meaning to tower up); high",
		"def" : "Horam, a Canaanitish king",
		"kjv" : "Horam"
	},
	"H2037" : {
		"lemma" : "הָרֻם",
		"xlit" : "Hârum",
		"pron" : "haw-room'",
		"derivation" : "passive participle of the same as H2036 (הֹרָם); high",
		"def" : "Harum, an Israelite",
		"kjv" : "Harum"
	},
	"H2038" : {
		"lemma" : "הַרְמוֹן",
		"xlit" : "harmôwn",
		"pron" : "har-mone'",
		"derivation" : "from the same as H2036 (הֹרָם)",
		"def" : "a castle (from its height)",
		"kjv" : "palace"
	},
	"H2039" : {
		"lemma" : "הָרָן",
		"xlit" : "Hârân",
		"pron" : "haw-rawn'",
		"derivation" : "perhaps from H2022 (הַר); mountaineer",
		"def" : "Haran, the name of two men",
		"kjv" : "Haran"
	},
	"H2040" : {
		"lemma" : "הָרַס",
		"xlit" : "hâraç",
		"pron" : "haw-ras'",
		"derivation" : "a primitive root",
		"def" : "to pull down or in pieces, break, destroy",
		"kjv" : "beat down, break (down, through), destroy, overthrow, pluck down, pull down, ruin, throw down, [idiom] utterly"
	},
	"H2041" : {
		"lemma" : "הֶרֶס",
		"xlit" : "hereç",
		"pron" : "heh'-res",
		"derivation" : "from H2040 (הָרַס)",
		"def" : "demolition",
		"kjv" : "destruction"
	},
	"H2042" : {
		"lemma" : "הָרָר",
		"xlit" : "hârâr",
		"pron" : "haw-rawr'",
		"derivation" : "from an unused root meaning to loom up",
		"def" : "a mountain",
		"kjv" : "hill, mount(-ain)"
	},
	"H2043" : {
		"lemma" : "הֲרָרִי",
		"xlit" : "Hărârîy",
		"pron" : "hah-raw-ree'",
		"derivation" : "or הָרָרִי; (2 Samuel 23:11), or הָארָרִי; (2 Samuel 23:34, last clause [), apparently from H2042 (הָרָר); a mountaineer",
		"def" : "{Hararite}",
		"kjv" : "Hararite"
	},
	"H2044" : {
		"lemma" : "הָשֵׁם",
		"xlit" : "Hâshêm",
		"pron" : "haw-shame'",
		"derivation" : "perhaps from the same as H2828 (חָשֻׁם); wealthy",
		"def" : "Hashem, an Israelite",
		"kjv" : "Hashem"
	},
	"H2045" : {
		"lemma" : "הַשְׁמָעוּת",
		"xlit" : "hashmâʻûwth",
		"pron" : "hashmaw-ooth'",
		"derivation" : "from H8085 (שָׁמַע)",
		"def" : "announcement",
		"kjv" : "to cause to hear"
	},
	"H2046" : {
		"lemma" : "הִתּוּךְ",
		"xlit" : "hittûwk",
		"pron" : "hit-took'",
		"derivation" : "from H5413 (נָתַךְ)",
		"def" : "a melting",
		"kjv" : "is melted"
	},
	"H2047" : {
		"lemma" : "הֲתָךְ",
		"xlit" : "Hăthâk",
		"pron" : "hath-awk'",
		"derivation" : "probably of foreign origin",
		"def" : "Hathak, a Persian eunuch",
		"kjv" : "Hatach"
	},
	"H2048" : {
		"lemma" : "הָתַל",
		"xlit" : "hâthal",
		"pron" : "haw-thal'",
		"derivation" : "a primitive root",
		"def" : "to deride; by implication, to cheat",
		"kjv" : "deal deceitfully, deceive, mock"
	},
	"H2049" : {
		"lemma" : "הָתֹל",
		"xlit" : "hâthôl",
		"pron" : "haw-thole'",
		"derivation" : "from H2048 (הָתַל) (only in plural collectively)",
		"def" : "a derision",
		"kjv" : "mocker"
	},
	"H2050" : {
		"lemma" : "הָתַת",
		"xlit" : "hâthath",
		"pron" : "haw-thath'",
		"derivation" : "a primitive root",
		"def" : "properly, to break in upon, i.e. to assail",
		"kjv" : "imagine mischief. w"
	},
	"H2051" : {
		"lemma" : "וְדָן",
		"xlit" : "Vᵉdân",
		"pron" : "ved-awn'",
		"derivation" : "perhaps for H5730 (עֵדֶן)",
		"def" : "Vedan (or Aden), a place in Arabia",
		"kjv" : "Dan also"
	},
	"H2052" : {
		"lemma" : "וָהֵב",
		"xlit" : "Vâhêb",
		"pron" : "vaw-habe'",
		"derivation" : "of uncertain derivation",
		"def" : "Vaheb, a place in Moab",
		"kjv" : "what he did"
	},
	"H2053" : {
		"lemma" : "וָו",
		"xlit" : "vâv",
		"pron" : "vaw",
		"derivation" : "probably",
		"def" : "a hook (the name of the sixth Hebrew letter)",
		"kjv" : "hook"
	},
	"H2054" : {
		"lemma" : "וָזָר",
		"xlit" : "vâzâr",
		"pron" : "vaw-zawr'",
		"derivation" : "presumed to be from an unused root meaning to bear guilt",
		"def" : "crime",
		"kjv" : "[idiom] strange"
	},
	"H2055" : {
		"lemma" : "וַיְזָתָא",
		"xlit" : "Vayzâthâʼ",
		"pron" : "vah-yez-aw'-thaw",
		"derivation" : "of foreign origin",
		"def" : "Vajezatha, a son of Haman",
		"kjv" : "Vajezatha"
	},
	"H2056" : {
		"lemma" : "וָלָד",
		"xlit" : "vâlâd",
		"pron" : "vaw-lawd'",
		"derivation" : "for H3206 (יֶלֶד)",
		"def" : "a boy",
		"kjv" : "child"
	},
	"H2057" : {
		"lemma" : "וַנְיָה",
		"xlit" : "Vanyâh",
		"pron" : "van-yaw'",
		"derivation" : "perhaps for H6043 (עֲנָיָה)",
		"def" : "Vanjah, an Israelite",
		"kjv" : "Vaniah"
	},
	"H2058" : {
		"lemma" : "וׇפְסִי",
		"xlit" : "Vophçîy",
		"pron" : "vof-see'",
		"derivation" : "probably from H3254 (יָסַף); additional",
		"def" : "Vophsi, an Israelite",
		"kjv" : "Vophsi"
	},
	"H2059" : {
		"lemma" : "וַשְׁנִי",
		"xlit" : "Vashnîy",
		"pron" : "vash-nee'",
		"derivation" : "probably from H3461 (יִשְׁמְרַי); weak",
		"def" : "Vashni, an Israelite",
		"kjv" : "Vashni"
	},
	"H2060" : {
		"lemma" : "וַשְׁתִּי",
		"xlit" : "Vashtîy",
		"pron" : "vash-tee'",
		"derivation" : "of Persian origin",
		"def" : "Vashti, the queen of Xerxes",
		"kjv" : "Vashti. z"
	},
	"H2061" : {
		"lemma" : "זְאֵב",
		"xlit" : "zᵉʼêb",
		"pron" : "zeh-abe'",
		"derivation" : "from an unused root meaning to be yellow",
		"def" : "a wolf",
		"kjv" : "wolf"
	},
	"H2062" : {
		"lemma" : "זְאֵב",
		"xlit" : "Zᵉʼêb",
		"pron" : "zeh-abe'",
		"derivation" : "the same as H2061 (זְאֵב)",
		"def" : "Zeeb, a Midianitish prince",
		"kjv" : "Zeeb"
	},
	"H2063" : {
		"lemma" : "זֹאת",
		"xlit" : "zôʼth",
		"pron" : "zothe'",
		"derivation" : "irregular feminine of H2089 (זֶה)",
		"def" : "this (often used adverb)",
		"kjv" : "hereby (-in, -with), it, likewise, the one (other, same), she, so (much), such (deed), that, therefore, these, this (thing), thus"
	},
	"H2064" : {
		"lemma" : "זָבַד",
		"xlit" : "zâbad",
		"pron" : "zaw-bad'",
		"derivation" : "a primitive root",
		"def" : "to confer",
		"kjv" : "endure"
	},
	"H2065" : {
		"lemma" : "זֶבֶד",
		"xlit" : "zebed",
		"pron" : "zeh'-bed",
		"derivation" : "from H2064 (זָבַד)",
		"def" : "a gift",
		"kjv" : "dowry"
	},
	"H2066" : {
		"lemma" : "זָבָד",
		"xlit" : "Zâbâd",
		"pron" : "zaw-bawd'",
		"derivation" : "from H2064 (זָבַד); giver",
		"def" : "Zabad, the name of seven Israelites",
		"kjv" : "Zabad"
	},
	"H2067" : {
		"lemma" : "זַבְדִּי",
		"xlit" : "Zabdîy",
		"pron" : "zab-dee'",
		"derivation" : "from H2065 (זֶבֶד); giving",
		"def" : "Zabdi, the name of four Israelites",
		"kjv" : "Zabdi"
	},
	"H2068" : {
		"lemma" : "זַבְדִּיאֵל",
		"xlit" : "Zabdîyʼêl",
		"pron" : "zab-dee-ale'",
		"derivation" : "from H2065 (זֶבֶד) and H410 (אֵל)",
		"def" : "gift of God; zabdiel, the name of two Israelites",
		"kjv" : "Zabdiel"
	},
	"H2069" : {
		"lemma" : "זְבַדְיָה",
		"xlit" : "Zᵉbadyâh",
		"pron" : "zeb-ad-yaw'",
		"derivation" : "or זְבַדְיָהוּ; from H2064 (זָבַד) and H3050 (יָהּ); Jah has given",
		"def" : "Zebadjah, the name of nine Israelites",
		"kjv" : "Zebadiah"
	},
	"H2070" : {
		"lemma" : "זְבוּב",
		"xlit" : "zᵉbûwb",
		"pron" : "zeb-oob'",
		"derivation" : "from an unused root (meaning to flit)",
		"def" : "a fly (especially one of a stinging nature)",
		"kjv" : "fly"
	},
	"H2071" : {
		"lemma" : "זָבוּד",
		"xlit" : "Zâbûwd",
		"pron" : "zaw-bood'",
		"derivation" : "from H2064 (זָבַד)",
		"def" : "given, Zabud, an Israelite",
		"kjv" : "Zabud"
	},
	"H2072" : {
		"lemma" : "זַבּוּד",
		"xlit" : "Zabbûwd",
		"pron" : "zab-bood'",
		"derivation" : "a form of H2071 (זָבוּד); given",
		"def" : "Zabbud, an Israelite",
		"kjv" : "Zabbud"
	},
	"H2073" : {
		"lemma" : "זְבוּל",
		"xlit" : "zᵉbûwl",
		"pron" : "ze-bool'",
		"derivation" : "or זְבֻל; from H2082 (זָבַל)",
		"def" : "a residence",
		"kjv" : "dwell in, dwelling, habitation"
	},
	"H2074" : {
		"lemma" : "זְבוּלוּן",
		"xlit" : "Zᵉbûwlûwn",
		"pron" : "zeb-oo-loon'",
		"derivation" : "or זְבֻלוּן; or זְבוּלֻן; from H2082 (זָבַל); habitation",
		"def" : "Zebulon, a son of Jacob; also his territory and tribe",
		"kjv" : "Zebulun"
	},
	"H2075" : {
		"lemma" : "זְבוּלֹנִי",
		"xlit" : "Zᵉbûwlônîy",
		"pron" : "zeb-oo-lo-nee'",
		"derivation" : "patronymically from H2074 (זְבוּלוּן)",
		"def" : "a Zebulonite or descendant of Zebulun",
		"kjv" : "Zebulonite"
	},
	"H2076" : {
		"lemma" : "זָבַח",
		"xlit" : "zâbach",
		"pron" : "zaw-bakh'",
		"derivation" : "a primitive root",
		"def" : "to slaughter an animal (usually in sacrifice)",
		"kjv" : "kill, offer, (do) sacrifice, slay"
	},
	"H2077" : {
		"lemma" : "זֶבַח",
		"xlit" : "zebach",
		"pron" : "zeh'-bakh",
		"derivation" : "from H2076 (זָבַח)",
		"def" : "properly, a slaughter, i.e. the flesh of an animal; by implication, a sacrifice (the victim or the act)",
		"kjv" : "offer(-ing), sacrifice"
	},
	"H2078" : {
		"lemma" : "זֶבַח",
		"xlit" : "Zebach",
		"pron" : "zeh'-bakh",
		"derivation" : "the same as H2077 (זֶבַח); sacrifice",
		"def" : "Zebach, a Midianitish prince",
		"kjv" : "Zebah"
	},
	"H2079" : {
		"lemma" : "זַבַּי",
		"xlit" : "Zabbay",
		"pron" : "zab-bah'-ee",
		"derivation" : "probably by orthographical error for H2140 (זַכַּי)",
		"def" : "Zabbai (or Zaccai), an Israelite",
		"kjv" : "Zabbai"
	},
	"H2080" : {
		"lemma" : "זְבִידָה",
		"xlit" : "Zᵉbîydâh",
		"pron" : "zeb-ee-daw'",
		"derivation" : "feminine from H2064 (זָבַד); giving",
		"def" : "Zebidah, an Israelitess",
		"kjv" : "Zebudah"
	},
	"H2081" : {
		"lemma" : "זְבִינָא",
		"xlit" : "Zᵉbîynâʼ",
		"pron" : "zeb-ee-naw'",
		"derivation" : "from an unused root (meaning to purchase); gainfulness",
		"def" : "Zebina, an Israelite",
		"kjv" : "Zebina"
	},
	"H2082" : {
		"lemma" : "זָבַל",
		"xlit" : "zâbal",
		"pron" : "zaw-bal'",
		"derivation" : "a primitive root; apparently properly, to inclose, i.e",
		"def" : "to reside",
		"kjv" : "dwell with"
	},
	"H2083" : {
		"lemma" : "זְבֻל",
		"xlit" : "Zᵉbul",
		"pron" : "zeb-ool'",
		"derivation" : "the same as H2073 (זְבוּל); dwelling",
		"def" : "Zebul, an Israelite",
		"kjv" : "Zebul. Compare H2073 (זְבוּל)"
	},
	"H2084" : {
		"lemma" : "זְבַן",
		"xlit" : "zᵉban",
		"pron" : "zeb-an'",
		"derivation" : "(Aramaic) corresponding to the root of H2081 (זְבִינָא)",
		"def" : "to acquire by purchase",
		"kjv" : "gain"
	},
	"H2085" : {
		"lemma" : "זָג",
		"xlit" : "zâg",
		"pron" : "zawg",
		"derivation" : "from an unused root probably meaning to inclose",
		"def" : "the skin of a grape",
		"kjv" : "husk"
	},
	"H2086" : {
		"lemma" : "זֵד",
		"xlit" : "zêd",
		"pron" : "zade'",
		"derivation" : "from H2102 (זוּד)",
		"def" : "arrogant",
		"kjv" : "presumptuous, proud"
	},
	"H2087" : {
		"lemma" : "זָדוֹן",
		"xlit" : "zâdôwn",
		"pron" : "zaw-done'",
		"derivation" : "from H2102 (זוּד)",
		"def" : "arrogance",
		"kjv" : "presumptuously, pride, proud (man)"
	},
	"H2088" : {
		"lemma" : "זֶה",
		"xlit" : "zeh",
		"pron" : "zeh",
		"derivation" : "a primitive word",
		"def" : "the masculine demonstrative pronoun, this or that",
		"kjv" : "he, [idiom] hence, [idiom] here, it(-self), [idiom] now, [idiom] of him, the one...the other, [idiom] than the other, ([idiom] out of) the (self) same, such (a one) that, these, this (hath, man), on this side...on that side, [idiom] thus, very, which. Compare H2063 (זֹאת), H2090 (זֹה), H2097 (זוֹ), H2098 (זוּ)"
	},
	"H2089" : {
		"lemma" : "זֶה",
		"xlit" : "zeh",
		"pron" : "zeh",
		"derivation" : "(1 Samuel by permutation for H7716 (שֶׂה)",
		"def" : "a sheep",
		"kjv" : "lamb"
	},
	"H2090" : {
		"lemma" : "זֹה",
		"xlit" : "zôh",
		"pron" : "zo",
		"derivation" : "for H2088 (זֶה)",
		"def" : "this or that",
		"kjv" : "as well as another, it, this, that, thus and thus"
	},
	"H2091" : {
		"lemma" : "זָהָב",
		"xlit" : "zâhâb",
		"pron" : "zaw-hawb'",
		"derivation" : "from an unused root meaning to shimmer",
		"def" : "gold, figuratively, something gold-colored (i.e. yellow), as oil, a clear sky",
		"kjv" : "gold(-en), fair weather"
	},
	"H2092" : {
		"lemma" : "זָהַם",
		"xlit" : "zâham",
		"pron" : "zaw-ham'",
		"derivation" : "a primitive root",
		"def" : "to be rancid, i.e. (transitively) to loathe",
		"kjv" : "abhor"
	},
	"H2093" : {
		"lemma" : "זַהַם",
		"xlit" : "Zaham",
		"pron" : "zah'-ham",
		"derivation" : "from H2092 (זָהַם); loathing",
		"def" : "Zaham, an Israelite",
		"kjv" : "Zaham"
	},
	"H2094" : {
		"lemma" : "זָהַר",
		"xlit" : "zâhar",
		"pron" : "zaw-har'",
		"derivation" : "a primitive root",
		"def" : "to gleam; figuratively, to enlighten (by caution)",
		"kjv" : "admonish, shine, teach, (give) warn(-ing)"
	},
	"H2095" : {
		"lemma" : "זְהַר",
		"xlit" : "zᵉhar",
		"pron" : "zeh-har'",
		"derivation" : "(Aramaic) corresponding to H2094 (זָהַר)",
		"def" : "(passively) be admonished",
		"kjv" : "take heed"
	},
	"H2096" : {
		"lemma" : "זֹהַר",
		"xlit" : "zôhar",
		"pron" : "zo'-har",
		"derivation" : "from H2094 (זָהַר)",
		"def" : "brilliancy",
		"kjv" : "brightness"
	},
	"H2097" : {
		"lemma" : "זוֹ",
		"xlit" : "zôw",
		"pron" : "zo",
		"derivation" : "for H2088 (זֶה)",
		"def" : "this or that",
		"kjv" : "that, this"
	},
	"H2098" : {
		"lemma" : "זוּ",
		"xlit" : "zûw",
		"pron" : "zoo",
		"derivation" : "for H2088 (זֶה)",
		"def" : "this or that",
		"kjv" : "that, this, [idiom] wherein, which, whom"
	},
	"H2099" : {
		"lemma" : "זִו",
		"xlit" : "Ziv",
		"pron" : "zeev'",
		"derivation" : "probably from an unused root meaning to be prominent; properly, brightness (compare H2122 (זִיו)), i.e. (figuratively) the month of flowers",
		"def" : "Ziv (corresponding to Ijar or May)",
		"kjv" : "Zif"
	},
	"H2100" : {
		"lemma" : "זוּב",
		"xlit" : "zûwb",
		"pron" : "zoob",
		"derivation" : "a primitive root",
		"def" : "to flow freely (as water), i.e. (specifically) to have a (sexual) flux; figuratively, to waste away; also to overflow",
		"kjv" : "flow, gush out, have a (running) issue, pine away, run"
	},
	"H2101" : {
		"lemma" : "זוֹב",
		"xlit" : "zôwb",
		"pron" : "zobe",
		"derivation" : "from H2100 (זוּב)",
		"def" : "a seminal or menstrual flux",
		"kjv" : "issue"
	},
	"H2102" : {
		"lemma" : "זוּד",
		"xlit" : "zûwd",
		"pron" : "zood",
		"derivation" : "or (by permutation) זִיד; a primitive root",
		"def" : "to seethe; figuratively, to be insolent",
		"kjv" : "be proud, deal proudly, presume, (come) presumptuously, sod"
	},
	"H2103" : {
		"lemma" : "זוּד",
		"xlit" : "zûwd",
		"pron" : "zood",
		"derivation" : "(Aramaic) corresponding to H2102 (זוּד)",
		"def" : "to be proud",
		"kjv" : "in pride"
	},
	"H2104" : {
		"lemma" : "זוּזִים",
		"xlit" : "Zûwzîym",
		"pron" : "zoo-zeem'",
		"derivation" : "plural probably from the same as H2123 (זִיז); prominent",
		"def" : "Zuzites, an aboriginal tribe of Palestine",
		"kjv" : "Zuzims"
	},
	"H2105" : {
		"lemma" : "זוֹחֵת",
		"xlit" : "Zôwchêth",
		"pron" : "zo-khayth'",
		"derivation" : "of uncertain origin",
		"def" : "Zocheth, an Israelite",
		"kjv" : "Zoheth"
	},
	"H2106" : {
		"lemma" : "זָוִית",
		"xlit" : "zâvîyth",
		"pron" : "zaw-veeth'",
		"derivation" : "apparently from the same root as H2099 (זִו) (in the sense of prominence)",
		"def" : "an angle (as projecting), i.e. (by implication) a corner-column (or anta)",
		"kjv" : "corner(stone)"
	},
	"H2107" : {
		"lemma" : "זוּל",
		"xlit" : "zûwl",
		"pron" : "zool",
		"derivation" : "a primitive root (compare H2151 (זָלַל))",
		"def" : "probably to shake out, i.e. (by implication) to scatter profusely; figuratively, to treat lightly",
		"kjv" : "lavish, despise"
	},
	"H2108" : {
		"lemma" : "זוּלָה",
		"xlit" : "zûwlâh",
		"pron" : "zoo-law'",
		"derivation" : "from H2107 (זוּל)",
		"def" : "probably scattering, i.e. removal; used adverbially, except",
		"kjv" : "beside, but, only, save"
	},
	"H2109" : {
		"lemma" : "זוּן",
		"xlit" : "zûwn",
		"pron" : "zoon",
		"derivation" : "a primitive root",
		"def" : "perhaps properly, to be plump, i.e. (transitively) to nourish",
		"kjv" : "feed"
	},
	"H2110" : {
		"lemma" : "זוּן",
		"xlit" : "zûwn",
		"pron" : "zoon",
		"derivation" : "(Aramaic) corresponding to H2109 (זוּן)",
		"def" : "{perhaps properly, to be plump, i.e. (transitively) to nourish}",
		"kjv" : "feed"
	},
	"H2111" : {
		"lemma" : "זוּעַ",
		"xlit" : "zûwaʻ",
		"pron" : "zoo'-ah",
		"derivation" : "a primitive root",
		"def" : "properly, to shake off, i.e. (figuratively) to agitate (as with fear)",
		"kjv" : "move, tremble, vex"
	},
	"H2112" : {
		"lemma" : "זוּעַ",
		"xlit" : "zûwaʻ",
		"pron" : "zoo'-ah",
		"derivation" : "(Aramaic) corresponding to H2111 (זוּעַ)",
		"def" : "to shake (with fear)",
		"kjv" : "tremble"
	},
	"H2113" : {
		"lemma" : "זְוָעָה",
		"xlit" : "zᵉvâʻâh",
		"pron" : "zev-aw-aw'",
		"derivation" : "from H2111 (זוּעַ)",
		"def" : "agitation, fear",
		"kjv" : "be removed, trouble, vexation. Compare H2189 (זַעֲוָה)"
	},
	"H2114" : {
		"lemma" : "זוּר",
		"xlit" : "zûwr",
		"pron" : "zoor",
		"derivation" : "a primitive root",
		"def" : "to turn aside (especially for lodging); hence to be aforeigner, strange, profane; specifically (active participle) to commit adultery",
		"kjv" : "(come from) another (man, place), fanner, go away, (e-) strange(-r, thing, woman)"
	},
	"H2115" : {
		"lemma" : "זוּר",
		"xlit" : "zûwr",
		"pron" : "zoor",
		"derivation" : "a primitive root (compare H6695 (צוֹק))",
		"def" : "to press together, tighten",
		"kjv" : "close, rush, thrust together"
	},
	"H2116" : {
		"lemma" : "זוּרֶה",
		"xlit" : "zûwreh",
		"pron" : "zoo-reh'",
		"derivation" : "from H2115 (זוּר)",
		"def" : "trodden on",
		"kjv" : "that which is crushed"
	},
	"H2117" : {
		"lemma" : "זָזָא",
		"xlit" : "zâzâʼ",
		"pron" : "zaw-zaw'",
		"derivation" : "probably from the root of H2123 (זִיז); prominent",
		"def" : "Zaza, an Israelite",
		"kjv" : "Zaza"
	},
	"H2118" : {
		"lemma" : "זָחַח",
		"xlit" : "zâchach",
		"pron" : "zaw-khakh'",
		"derivation" : "a primitive root",
		"def" : "to shove or displace",
		"kjv" : "loose"
	},
	"H2119" : {
		"lemma" : "זָחַל",
		"xlit" : "zâchal",
		"pron" : "zaw-khal'",
		"derivation" : "a primitive root",
		"def" : "to crawl; by implication, to fear",
		"kjv" : "be afraid, serpent, worm"
	},
	"H2120" : {
		"lemma" : "זֹחֶלֶת",
		"xlit" : "Zôcheleth",
		"pron" : "zo-kheh'-leth",
		"derivation" : "feminine active participle of H2119 (זָחַל); crawling (i.e. serpent)",
		"def" : "Zocheleth, a boundary stone in. Palestine",
		"kjv" : "Zoheleth"
	},
	"H2121" : {
		"lemma" : "זֵידוֹן",
		"xlit" : "zêydôwn",
		"pron" : "zay-dohn'",
		"derivation" : "from H2102 (זוּד)",
		"def" : "boiling of water, i.e. wave",
		"kjv" : "proud"
	},
	"H2122" : {
		"lemma" : "זִיו",
		"xlit" : "zîyv",
		"pron" : "zeev",
		"derivation" : "(Aramaic) corresponding to H2099 (זִו)",
		"def" : "(figuratively) cheerfulness",
		"kjv" : "brightness, countenance"
	},
	"H2123" : {
		"lemma" : "זִיז",
		"xlit" : "zîyz",
		"pron" : "zeez",
		"derivation" : "from an unused root apparently meaning to be conspicuous",
		"def" : "fulness of the breast; also a moving creature",
		"kjv" : "abundance, wild beast"
	},
	"H2124" : {
		"lemma" : "זִיזָא",
		"xlit" : "Zîyzâʼ",
		"pron" : "zee-zaw'",
		"derivation" : "apparently from the same as H2123 (זִיז); prominence",
		"def" : "Ziza, the name of two Israelites",
		"kjv" : "Ziza"
	},
	"H2125" : {
		"lemma" : "זִיזָה",
		"xlit" : "Zîyzâh",
		"pron" : "zee-zaw'",
		"derivation" : "another form for H2124 (זִיזָא)",
		"def" : "Zizah, an Israelite",
		"kjv" : "Zizah"
	},
	"H2126" : {
		"lemma" : "זִינָא",
		"xlit" : "Zîynâʼ",
		"pron" : "zee-naw'",
		"derivation" : "from H2109 (זוּן); well-fed; or perhaps an orthographical error for H2124 (זִיזָא)",
		"def" : "Zina, an Israelite",
		"kjv" : "Zina"
	},
	"H2127" : {
		"lemma" : "זִיעַ",
		"xlit" : "Zîyaʻ",
		"pron" : "zee'-ah",
		"derivation" : "from H2111 (זוּעַ); agitation",
		"def" : "Zia, an Israelite",
		"kjv" : "Zia"
	},
	"H2128" : {
		"lemma" : "זִיף",
		"xlit" : "Zîyph",
		"pron" : "zeef",
		"derivation" : "from the same as H2203 (זֶפֶת); flowing",
		"def" : "Ziph, the name of a place in Palestine; also of an Israelite",
		"kjv" : "Ziph"
	},
	"H2129" : {
		"lemma" : "זִיפָה",
		"xlit" : "Zîyphâh",
		"pron" : "zee-faw'",
		"derivation" : "feminine of H2128 (זִיף); a flowing",
		"def" : "Ziphah, an Israelite",
		"kjv" : "Ziphah"
	},
	"H2130" : {
		"lemma" : "זִיפִי",
		"xlit" : "Zîyphîy",
		"pron" : "zee-fee'",
		"derivation" : "patrial from H2128 (זִיף)",
		"def" : "a Ziphite or inhabitant of Ziph",
		"kjv" : "Ziphim, Ziphite"
	},
	"H2131" : {
		"lemma" : "זִיקָה",
		"xlit" : "zîyqâh",
		"pron" : "zee-kaw'",
		"derivation" : "(Isaiah 50: (feminine); and זִק; or זֵק; from H2187 (זָנַק)",
		"def" : "properly, what leaps forth, i.e. flash of fire, or a burning arrow; also (from the original sense of the root) a bond",
		"kjv" : "chain, fetter, firebrand, spark"
	},
	"H2132" : {
		"lemma" : "זַיִת",
		"xlit" : "zayith",
		"pron" : "zay'-yith",
		"derivation" : "probably from an unused root (akin to H2099 (זִו))",
		"def" : "an olive (as yielding illuminating oil), the tree, the branch or the berry",
		"kjv" : "olive (tree, -yard), Olivet"
	},
	"H2133" : {
		"lemma" : "זֵיתָן",
		"xlit" : "Zêythân",
		"pron" : "zay-thawn'",
		"derivation" : "from H2132 (זַיִת); olive grove",
		"def" : "Zethan, an Israelite",
		"kjv" : "Zethan"
	},
	"H2134" : {
		"lemma" : "זַךְ",
		"xlit" : "zak",
		"pron" : "zak",
		"derivation" : "from H2141 (זָכַךְ)",
		"def" : "clear",
		"kjv" : "clean, pure"
	},
	"H2135" : {
		"lemma" : "זָכָה",
		"xlit" : "zâkâh",
		"pron" : "zaw-kaw'",
		"derivation" : "a primitive root (compare H2141 (זָכַךְ))",
		"def" : "to be translucent; figuratively, to be innocent",
		"kjv" : "be (make) clean, cleanse, be clear, count pure"
	},
	"H2136" : {
		"lemma" : "זָכוּ",
		"xlit" : "zâkûw",
		"pron" : "zaw-koo'",
		"derivation" : "(Aramaic) from a root corresponding to H2135 (זָכָה)",
		"def" : "purity",
		"kjv" : "innocency"
	},
	"H2137" : {
		"lemma" : "זְכוּכִית",
		"xlit" : "zᵉkûwkîyth",
		"pron" : "zek-oo-keeth",
		"derivation" : "from H2135 (זָכָה)",
		"def" : "properly, transparency, i.e. glass",
		"kjv" : "crystal"
	},
	"H2138" : {
		"lemma" : "זָכוּר",
		"xlit" : "zâkûwr",
		"pron" : "zaw-koor'",
		"derivation" : "properly, passive participle of H2142 (זָכַר), but used for H2145 (זָכָר)",
		"def" : "a male (of man or animals)",
		"kjv" : "males, men-children"
	},
	"H2139" : {
		"lemma" : "זַכּוּר",
		"xlit" : "Zakkûwr",
		"pron" : "zaw-koor'",
		"derivation" : "from H2142 (זָכַר); mindful",
		"def" : "Zakkur, the name of seven Israelites",
		"kjv" : "Zaccur, Zacchur"
	},
	"H2140" : {
		"lemma" : "זַכַּי",
		"xlit" : "Zakkay",
		"pron" : "zak-kah'-ee",
		"derivation" : "from H2141 (זָכַךְ); pure",
		"def" : "Zakkai, an Israelite",
		"kjv" : "Zaccai"
	},
	"H2141" : {
		"lemma" : "זָכַךְ",
		"xlit" : "zâkak",
		"pron" : "zaw-kak'",
		"derivation" : "a primitive root (compare H2135 (זָכָה))",
		"def" : "to be transparent or clean (phys. or morally)",
		"kjv" : "be (make) clean, be pure(-r)"
	},
	"H2142" : {
		"lemma" : "זָכַר",
		"xlit" : "zâkar",
		"pron" : "zaw-kar'",
		"derivation" : "a primitive root; also as denominative from H2145 (זָכָר)",
		"def" : "properly, to mark (so as to be recognized), i.e. to remember; by implication, to mention;  to be male",
		"kjv" : "[idiom] burn (incense), [idiom] earnestly, be male, (make) mention (of), be mindful, recount, record(-er), remember, make to be remembered, bring (call, come, keep, put) to (in) remembrance, [idiom] still, think on, [idiom] well"
	},
	"H2143" : {
		"lemma" : "זֵכֶר",
		"xlit" : "zêker",
		"pron" : "zay'-ker",
		"derivation" : "or זֶכֶר; from H2142 (זָכַר)",
		"def" : "a memento, abstractly recollection (rarely if ever); by implication, commemoration",
		"kjv" : "memorial, memory, remembrance, scent"
	},
	"H2144" : {
		"lemma" : "זֶכֶר",
		"xlit" : "Zeker",
		"pron" : "zeh'-ker",
		"derivation" : "the same as H2143 (זֵכֶר)",
		"def" : "Zeker, an Israelite",
		"kjv" : "Zeker"
	},
	"H2145" : {
		"lemma" : "זָכָר",
		"xlit" : "zâkâr",
		"pron" : "zaw-kawr'",
		"derivation" : "from H2142 (זָכַר)",
		"def" : "properly, remembered, i.e. a male (of man or animals, as being the most noteworthy sex)",
		"kjv" : "[idiom] him, male, man(child, -kind)"
	},
	"H2146" : {
		"lemma" : "זִכְרוֹן",
		"xlit" : "zikrôwn",
		"pron" : "zik-rone'",
		"derivation" : "from H2142 (זָכַר)",
		"def" : "a memento (or memorable thing, day or writing)",
		"kjv" : "memorial, record"
	},
	"H2147" : {
		"lemma" : "זִכְרִי",
		"xlit" : "Zikrîy",
		"pron" : "zik-ree'",
		"derivation" : "from H2142 (זָכַר); memorable",
		"def" : "Zicri, the name of twelve Israelites",
		"kjv" : "Zichri"
	},
	"H2148" : {
		"lemma" : "זְכַרְיָה",
		"xlit" : "Zᵉkaryâh",
		"pron" : "zek-ar-yaw'",
		"derivation" : "or זְכַרְיָהוּ; from H2142 (זָכַר) and H3050 (יָהּ); Jah has remembered",
		"def" : "Zecarjah, the name of twenty-nine Israelites",
		"kjv" : "Zachariah, Zechariah"
	},
	"H2149" : {
		"lemma" : "זֻלּוּת",
		"xlit" : "zullûwth",
		"pron" : "zool-looth'",
		"derivation" : "from H2151 (זָלַל)",
		"def" : "properly, a shaking, i.e. perhaps a tempest",
		"kjv" : "vilest"
	},
	"H2150" : {
		"lemma" : "זַלְזַל",
		"xlit" : "zalzal",
		"pron" : "zal-zal'",
		"derivation" : "by reduplication from H2151 (זָלַל)",
		"def" : "tremulous, i.e. a twig",
		"kjv" : "sprig"
	},
	"H2151" : {
		"lemma" : "זָלַל",
		"xlit" : "zâlal",
		"pron" : "zaw-lal'",
		"derivation" : "a primitive root (compare H2107 (זוּל))",
		"def" : "to shake (as in the wind), i.e. to quake; figuratively, to be loose morally, worthless or prodigal",
		"kjv" : "blow down, glutton, riotous (eater), vile"
	},
	"H2152" : {
		"lemma" : "זַלְעָפָה",
		"xlit" : "zalʻâphâh",
		"pron" : "zal-aw-faw'",
		"derivation" : "or זִלְעָפָהxlit zilʻâphâph corrected to zilʻâphâh; from H2196 (זָעַף)",
		"def" : "a glow (of wind or anger); also a famine (as consuming)",
		"kjv" : "horrible, horror, terrible"
	},
	"H2153" : {
		"lemma" : "זִלְפָּה",
		"xlit" : "Zilpâh",
		"pron" : "zil-paw",
		"derivation" : "; from an unused root apparently meaning to trickle, as myrrh; fragrant dropping",
		"def" : "Zilpah, Leah's maid",
		"kjv" : "Zilpah"
	},
	"H2154" : {
		"lemma" : "זִמָּה",
		"xlit" : "zimmâh",
		"pron" : "zim-maw'",
		"derivation" : "or זַמָּה; from H2161 (זָמַם)",
		"def" : "a plan, especially a bad one",
		"kjv" : "heinous crime, lewd(-ly, -ness), mischief, purpose, thought, wicked (device, mind, -ness)"
	},
	"H2155" : {
		"lemma" : "זִמָּה",
		"xlit" : "Zimmâh",
		"pron" : "zim-maw'",
		"derivation" : "the same as H2154 (זִמָּה)",
		"def" : "Zimmah, the name of two Israelites",
		"kjv" : "Zimmah"
	},
	"H2156" : {
		"lemma" : "זְמוֹרָה",
		"xlit" : "zᵉmôwrâh",
		"pron" : "zem-o-raw'",
		"derivation" : "or זְמֹרָה (feminine); and זְמֹר (masculine); from H2168 (זָמַר)",
		"def" : "a twig (as pruned)",
		"kjv" : "vine, branch, slip"
	},
	"H2157" : {
		"lemma" : "זַמְזֹם",
		"xlit" : "Zamzôm",
		"pron" : "zam-zome'",
		"derivation" : "from H2161 (זָמַם); intriguing",
		"def" : "a Zamzumite, or native tribe of Palestine",
		"kjv" : "Zamzummim"
	},
	"H2158" : {
		"lemma" : "זָמִיר",
		"xlit" : "zâmîyr",
		"pron" : "zaw-meer'",
		"derivation" : "or זָמִר; and (feminine) זְמִרָה; from H2167 (זָמַר)",
		"def" : "a song to be accompanied with instrumental music",
		"kjv" : "psalm(-ist), singing, song"
	},
	"H2159" : {
		"lemma" : "זָמִיר",
		"xlit" : "zâmîyr",
		"pron" : "zaw-meer'",
		"derivation" : "from H2168 (זָמַר)",
		"def" : "a twig (as pruned)",
		"kjv" : "branch"
	},
	"H2160" : {
		"lemma" : "זְמִירָה",
		"xlit" : "Zᵉmîyrâh",
		"pron" : "zem-ee-raw'",
		"derivation" : "feminine of H2158 (זָמִיר); song",
		"def" : "Zemirah, an Israelite",
		"kjv" : "Zemira"
	},
	"H2161" : {
		"lemma" : "זָמַם",
		"xlit" : "zâmam",
		"pron" : "zaw-mam'",
		"derivation" : "a primitive root",
		"def" : "to plan, usually in a bad sense",
		"kjv" : "consider, devise, imagine, plot, purpose, think (evil)"
	},
	"H2162" : {
		"lemma" : "זָמָם",
		"xlit" : "zâmâm",
		"pron" : "zaw-mawm'",
		"derivation" : "from H2161 (זָמַם)",
		"def" : "a plot",
		"kjv" : "wicked device"
	},
	"H2163" : {
		"lemma" : "זָמַן",
		"xlit" : "zâman",
		"pron" : "zaw-man'",
		"derivation" : "a primitive root",
		"def" : "to fix (a time)",
		"kjv" : "appoint"
	},
	"H2164" : {
		"lemma" : "זְמַן",
		"xlit" : "zᵉman",
		"pron" : "zem-an'",
		"derivation" : "(Aramaic) corresponding to H2163 (זָמַן)",
		"def" : "to agree (on a time and place)",
		"kjv" : "prepare"
	},
	"H2165" : {
		"lemma" : "זְמָן",
		"xlit" : "zᵉmân",
		"pron" : "zem-awn'",
		"derivation" : "from H2163 (זָמַן)",
		"def" : "an appointed occasion",
		"kjv" : "season, time"
	},
	"H2166" : {
		"lemma" : "זְמָן",
		"xlit" : "zᵉmân",
		"pron" : "zem-awn'",
		"derivation" : "(Aramaic) from H2165 (זְמָן); the same as H2165 (זְמָן)",
		"def" : "{an appointed occasion}",
		"kjv" : "season, time"
	},
	"H2167" : {
		"lemma" : "זָמַר",
		"xlit" : "zâmar",
		"pron" : "zaw-mar'",
		"derivation" : "a primitive root (perhaps identical with H2168 (זָמַר) through the idea of striking with the fingers); properly, to touch the strings or parts of a musical instrument, i.e",
		"def" : "play upon it; to make music, accompanied by the voice; hence to celebrate in song and music",
		"kjv" : "give praise, sing forth praises, psalms"
	},
	"H2168" : {
		"lemma" : "זָמַר",
		"xlit" : "zâmar",
		"pron" : "zaw-mar'",
		"derivation" : "a primitive root (compare H2167 (זָמַר), H5568 (סָמַר), H6785 (צֶמֶר))",
		"def" : "to trim (a vine)",
		"kjv" : "prune"
	},
	"H2169" : {
		"lemma" : "זֶמֶר",
		"xlit" : "zemer",
		"pron" : "zeh'-mer",
		"derivation" : "apparently from H2167 (זָמַר) or H2168 (זָמַר)",
		"def" : "a gazelle (from its lightly touching the ground)",
		"kjv" : "chamois"
	},
	"H2170" : {
		"lemma" : "זְמָר",
		"xlit" : "zᵉmâr",
		"pron" : "zem-awr'",
		"derivation" : "(Aramaic) from a root corresponding to H2167 (זָמַר)",
		"def" : "instrumental music",
		"kjv" : "musick"
	},
	"H2171" : {
		"lemma" : "זַמָּר",
		"xlit" : "zammâr",
		"pron" : "zam-mawr'",
		"derivation" : "(Aramaic) from the same as H2170 (זְמָר)",
		"def" : "an instrumental musician",
		"kjv" : "singer"
	},
	"H2172" : {
		"lemma" : "זִמְרָה",
		"xlit" : "zimrâh",
		"pron" : "zim-raw'",
		"derivation" : "from H2167 (זָמַר)",
		"def" : "a musical piece or song to be accompanied by an instrument",
		"kjv" : "melody, psalm"
	},
	"H2173" : {
		"lemma" : "זִמְרָה",
		"xlit" : "zimrâh",
		"pron" : "zim-raw'",
		"derivation" : "from H2168 (זָמַר)",
		"def" : "pruned (i.e. choice) fruit",
		"kjv" : "best fruit"
	},
	"H2174" : {
		"lemma" : "זִמְרִי",
		"xlit" : "Zimrîy",
		"pron" : "zim-ree'",
		"derivation" : "from H2167 (זָמַר); musical",
		"def" : "Zimri, the name of five Israelites, and of an Arabian tribe",
		"kjv" : "Zimri"
	},
	"H2175" : {
		"lemma" : "זִמְרָן",
		"xlit" : "Zimrân",
		"pron" : "zim-rawn'",
		"derivation" : "from H2167 (זָמַר); musical",
		"def" : "Zimran, a son of Abraham by Keturah",
		"kjv" : "Zimran"
	},
	"H2176" : {
		"lemma" : "זִמְרָת",
		"xlit" : "zimrâth",
		"pron" : "zim-rawth'",
		"derivation" : "from H2167 (זָמַר)",
		"def" : "instrumental music; by implication, praise",
		"kjv" : "song"
	},
	"H2177" : {
		"lemma" : "זַן",
		"xlit" : "zan",
		"pron" : "zan",
		"derivation" : "from H2109 (זוּן)",
		"def" : "properly, nourished (or fully developed), i.e. a form or sort",
		"kjv" : "divers kinds, [idiom] all manner of store"
	},
	"H2178" : {
		"lemma" : "זַן",
		"xlit" : "zan",
		"pron" : "zan",
		"derivation" : "(Aramaic) corresponding to H2177 (זַן)",
		"def" : "sort",
		"kjv" : "kind"
	},
	"H2179" : {
		"lemma" : "זָנַב",
		"xlit" : "zânab",
		"pron" : "zaw-nab'",
		"derivation" : "a primitive root meaning to wag; used only as a denominative from H2180 (זָנָב)",
		"def" : "to curtail, i.e. cut off the rear",
		"kjv" : "smite the hindmost"
	},
	"H2180" : {
		"lemma" : "זָנָב",
		"xlit" : "zânâb",
		"pron" : "zaw-nawb'",
		"derivation" : "from H2179 (זָנַב) (in the original sense of flapping)",
		"def" : "the tail (literally or figuratively)",
		"kjv" : "tail"
	},
	"H2181" : {
		"lemma" : "זָנָה",
		"xlit" : "zânâh",
		"pron" : "zaw-naw'",
		"derivation" : "a primitive root (highly-fed and therefore wanton)",
		"def" : "to commit adultery (usually of the female, and less often of simple fornication, rarely of involuntary ravishment); figuratively, to commit idolatry (the Jewish people being regarded as the spouse of Jehovah)",
		"kjv" : "(cause to) commit fornication, [idiom] continually, [idiom] great, (be an, play the) harlot, (cause to be, play the) whore, (commit, fall to) whoredom, (cause to) go a-whoring, whorish"
	},
	"H2182" : {
		"lemma" : "זָנוֹחַ",
		"xlit" : "Zânôwach",
		"pron" : "zaw-no'-akh",
		"derivation" : "from H2186 (זָנַח); rejected",
		"def" : "Zanoach, the name of two places in Palestine",
		"kjv" : "Zanoah"
	},
	"H2183" : {
		"lemma" : "זָנוּן",
		"xlit" : "zânûwn",
		"pron" : "zaw-noon'",
		"derivation" : "from H2181 (זָנָה)",
		"def" : "adultery; figuratively, idolatry",
		"kjv" : "whoredom"
	},
	"H2184" : {
		"lemma" : "זְנוּת",
		"xlit" : "zᵉnûwth",
		"pron" : "zen-ooth'",
		"derivation" : "from H2181 (זָנָה)",
		"def" : "adultery, i.e. (figuratively) infidelity, idolatry",
		"kjv" : "whoredom"
	},
	"H2185" : {
		"lemma" : "זֹנוֹת",
		"xlit" : "zônôwth",
		"pron" : "zo-noth'",
		"derivation" : "regarded by some as if from H2109 (זוּן) or an unused root, and applied to military equipments; but evidently the feminine plural active participle of H2181 (זָנָה)",
		"def" : "harlots",
		"kjv" : "armour"
	},
	"H2186" : {
		"lemma" : "זָנַח",
		"xlit" : "zânach",
		"pron" : "zaw-nakh'",
		"derivation" : "a primitive root meaning to push aside, i.e",
		"def" : "reject, forsake, fail",
		"kjv" : "cast away (off), remove far away (off)"
	},
	"H2187" : {
		"lemma" : "זָנַק",
		"xlit" : "zânaq",
		"pron" : "zaw-nak'",
		"derivation" : "a primitive root",
		"def" : "properly, to draw together the feet (as an animal about to dart upon its prey), i.e. to spring forward",
		"kjv" : "leap"
	},
	"H2188" : {
		"lemma" : "זֵעָה",
		"xlit" : "zêʻâh",
		"pron" : "zay-aw'",
		"derivation" : "from H2111 (זוּעַ) (in the sense of H3154 (יֶזַע))",
		"def" : "perspiration",
		"kjv" : "sweat"
	},
	"H2189" : {
		"lemma" : "זַעֲוָה",
		"xlit" : "zaʻăvâh",
		"pron" : "zah-av-aw'",
		"derivation" : "by transposition for H2113 (זְוָעָה)",
		"def" : "agitation, maltreatment",
		"kjv" : "[idiom] removed, trouble"
	},
	"H2190" : {
		"lemma" : "זַעֲוָן",
		"xlit" : "Zaʻăvân",
		"pron" : "zah-av-awn'",
		"derivation" : "from H2111 (זוּעַ); disquiet",
		"def" : "Zaavan, an Idumaean",
		"kjv" : "Zaavan"
	},
	"H2191" : {
		"lemma" : "זְעֵיר",
		"xlit" : "zᵉʻêyr",
		"pron" : "zeh-ayr'",
		"derivation" : "from an unused root (akin (by permutation) to H6819 (צָעַר)), meaning to dwindle",
		"def" : "small",
		"kjv" : "little"
	},
	"H2192" : {
		"lemma" : "זְעֵיר",
		"xlit" : "zᵉʻêyr",
		"pron" : "zeh-ayr'",
		"derivation" : "(Aramaic) corresponding to H2191 (זְעֵיר)xlit zeʻêyr corrected to zᵉʻêyr",
		"def" : "{small}",
		"kjv" : "little"
	},
	"H2193" : {
		"lemma" : "זָעַךְ",
		"xlit" : "zâʻak",
		"pron" : "zaw-ak'",
		"derivation" : "a primitive root",
		"def" : "to extinguish",
		"kjv" : "be extinct"
	},
	"H2194" : {
		"lemma" : "זָעַם",
		"xlit" : "zâʻam",
		"pron" : "zaw-am'",
		"derivation" : "a primitive root",
		"def" : "properly, to foam at the mouth, i.e. to be enraged",
		"kjv" : "abhor, abominable, (be) angry, defy, (have) indignation"
	},
	"H2195" : {
		"lemma" : "זַעַם",
		"xlit" : "zaʻam",
		"pron" : "zah'-am",
		"derivation" : "from H2194 (זָעַם)",
		"def" : "strictly froth at the mouth, i.e. (figuratively) fury (especially of God's displeasure with sin)",
		"kjv" : "angry, indignation, rage"
	},
	"H2196" : {
		"lemma" : "זָעַף",
		"xlit" : "zâʻaph",
		"pron" : "zaw-af'",
		"derivation" : "a primitive root",
		"def" : "properly, to boil up, i.e. (figuratively) to be peevish or angry",
		"kjv" : "fret, sad, worse liking, be wroth"
	},
	"H2197" : {
		"lemma" : "זַעַף",
		"xlit" : "zaʻaph",
		"pron" : "zah'-af",
		"derivation" : "from H2196 (זָעַף)",
		"def" : "anger",
		"kjv" : "indignation, rage(-ing), wrath"
	},
	"H2198" : {
		"lemma" : "זָעֵף",
		"xlit" : "zâʻêph",
		"pron" : "zaw-afe'",
		"derivation" : "from H2196 (זָעַף)",
		"def" : "angry",
		"kjv" : "displeased"
	},
	"H2199" : {
		"lemma" : "זָעַק",
		"xlit" : "zâʻaq",
		"pron" : "zaw-ak'",
		"derivation" : "a primitive root",
		"def" : "to shriek (from anguish or danger); by analogy, (as a herald) to announce or convene publicly",
		"kjv" : "assemble, call (together), (make a) cry (out), come with such a company, gather (together), cause to be proclaimed"
	},
	"H2200" : {
		"lemma" : "זְעִק",
		"xlit" : "zᵉʻiq",
		"pron" : "zek'-eek",
		"derivation" : "(Aramaic) corresponding to H2199 (זָעַק)",
		"def" : "to make an outcry",
		"kjv" : "cry"
	},
	"H2201" : {
		"lemma" : "זַעַק",
		"xlit" : "zaʻaq",
		"pron" : "zah'-ak",
		"derivation" : "and (feminine) זְעָקָהxlit zᵉâqâh corrected to zᵉʻâqâh; from H2199 (זָעַק)",
		"def" : "a shriek or outcry",
		"kjv" : "cry(-ing)"
	},
	"H2202" : {
		"lemma" : "זִפְרֹן",
		"xlit" : "Ziphrôn",
		"pron" : "zi-frone'",
		"derivation" : "from an unused root (meaning to be fragrant)",
		"def" : "Ziphron, a place in Palestine",
		"kjv" : "Ziphron"
	},
	"H2203" : {
		"lemma" : "זֶפֶת",
		"xlit" : "zepheth",
		"pron" : "zeh'-feth",
		"derivation" : "from an unused root (meaning to liquify)",
		"def" : "asphalt (from its tendency to soften in the sun)",
		"kjv" : "pitch"
	},
	"H2204" : {
		"lemma" : "זָקֵן",
		"xlit" : "zâqên",
		"pron" : "zaw-kane'",
		"derivation" : "a primitive root",
		"def" : "to be old",
		"kjv" : "aged man, be (wax) old (man)"
	},
	"H2205" : {
		"lemma" : "זָקֵן",
		"xlit" : "zâqên",
		"pron" : "zaw-kane'",
		"derivation" : "from H2204 (זָקֵן)",
		"def" : "old",
		"kjv" : "aged, ancient (man), elder(-est), old (man, men and...women), senator"
	},
	"H2206" : {
		"lemma" : "זָקָן",
		"xlit" : "zâqân",
		"pron" : "zaw-kawn'",
		"derivation" : "from H2204 (זָקֵן)",
		"def" : "the beard (as indicating age)",
		"kjv" : "beard"
	},
	"H2207" : {
		"lemma" : "זֹקֶן",
		"xlit" : "zôqen",
		"pron" : "zo'-ken",
		"derivation" : "from H2204 (זָקֵן)",
		"def" : "old age",
		"kjv" : "age"
	},
	"H2208" : {
		"lemma" : "זָקֻן",
		"xlit" : "zâqun",
		"pron" : "zaw-koon'",
		"derivation" : "properly, passive participle of H2204 (זָקֵן) (used only in the plural as a noun)",
		"def" : "old age",
		"kjv" : "old age"
	},
	"H2209" : {
		"lemma" : "זִקְנָה",
		"xlit" : "ziqnâh",
		"pron" : "zik-naw'",
		"derivation" : "feminine of H2205 (זָקֵן)",
		"def" : "old age",
		"kjv" : "old (age)"
	},
	"H2210" : {
		"lemma" : "זָקַף",
		"xlit" : "zâqaph",
		"pron" : "zaw-kaf'",
		"derivation" : "a primitive root",
		"def" : "to life, i.e. (figuratively) comfort",
		"kjv" : "raise (up)"
	},
	"H2211" : {
		"lemma" : "זְקַף",
		"xlit" : "zᵉqaph",
		"pron" : "zek-af'",
		"derivation" : "(Aramaic) corresponding to H2210 (זָקַף)",
		"def" : "to hang, i.e. impale",
		"kjv" : "set up"
	},
	"H2212" : {
		"lemma" : "זָקַק",
		"xlit" : "zâqaq",
		"pron" : "zaw-kak'",
		"derivation" : "a primitive root",
		"def" : "to strain, (figuratively) extract, clarify",
		"kjv" : "fine, pour down, purge, purify, refine"
	},
	"H2213" : {
		"lemma" : "זֵר",
		"xlit" : "zêr",
		"pron" : "zare",
		"derivation" : "from H2237 (זָרַר) (in the sense of scattering)",
		"def" : "a chaplet (as spread around the top), i.e. (specifically) a border moulding",
		"kjv" : "crown"
	},
	"H2214" : {
		"lemma" : "זָרָא",
		"xlit" : "zârâʼ",
		"pron" : "zaw-raw'",
		"derivation" : "from H2114 (זוּר) (in the sense of estrangement) (compare H2219 (זָרָה))",
		"def" : "disgust",
		"kjv" : "loathsome"
	},
	"H2215" : {
		"lemma" : "זָרַב",
		"xlit" : "zârab",
		"pron" : "zaw-rab'",
		"derivation" : "a primitive root",
		"def" : "to flow away",
		"kjv" : "wax warm"
	},
	"H2216" : {
		"lemma" : "זְרֻבָּבֶל",
		"xlit" : "Zᵉrubbâbel",
		"pron" : "zer-oob-baw-bel'",
		"derivation" : "from H2215 (זָרַב) and H894 (בָּבֶל); descended of (i.e. from) Babylon, i.e. born there",
		"def" : "Zerubbabel, an Israelite",
		"kjv" : "Zerubbabel"
	},
	"H2217" : {
		"lemma" : "זְרֻבָּבֶל",
		"xlit" : "Zᵉrubbâbel",
		"pron" : "zer-oob-baw-bel'",
		"derivation" : "(Aramaic) corresponding to H2216 (זְרֻבָּבֶל)",
		"def" : "{Zerubbabel, an Israelite}",
		"kjv" : "Zerubbabel"
	},
	"H2218" : {
		"lemma" : "זֶרֶד",
		"xlit" : "Zered",
		"pron" : "zeh'-red",
		"derivation" : "from an unused root meaning to be exuberant in growth; lined with shrubbery",
		"def" : "Zered, a brook East of the Dead Sea",
		"kjv" : "Zared, Zered"
	},
	"H2219" : {
		"lemma" : "זָרָה",
		"xlit" : "zârâh",
		"pron" : "zaw-raw'",
		"derivation" : "a primitive root (compare H2114 (זוּר))",
		"def" : "to toss about; by implication, to diffuse, winnow",
		"kjv" : "cast away, compass, disperse, fan, scatter (away), spread, strew, winnow"
	},
	"H2220" : {
		"lemma" : "זְרוֹעַ",
		"xlit" : "zᵉrôwaʻ",
		"pron" : "zer-o'-ah",
		"derivation" : "or (shortened) זְרֹעַ; and (feminine) זְרוֹעָה; or זְרֹעָה; from H2232 (זָרַע)",
		"def" : "the arm (as stretched out), or (of animals) the foreleg; figuratively, force",
		"kjv" : "arm, [phrase] help, mighty, power, shoulder, strength"
	},
	"H2221" : {
		"lemma" : "זֵרוּעַ",
		"xlit" : "zêrûwaʻ",
		"pron" : "zay-roo'-ah",
		"derivation" : "from H2232 (זָרַע)",
		"def" : "something sown, i.e. a plant",
		"kjv" : "sowing, thing that is sown"
	},
	"H2222" : {
		"lemma" : "זַרְזִיף",
		"xlit" : "zarzîyph",
		"pron" : "zar-zeef'",
		"derivation" : "by reduplication from an unused root meaning to flow",
		"def" : "a pouring rain",
		"kjv" : "water"
	},
	"H2223" : {
		"lemma" : "זַרְזִיר",
		"xlit" : "zarzîyr",
		"pron" : "zar-zeer'",
		"derivation" : "by reduplication from H2115 (זוּר)",
		"def" : "properly, tightly girt, i.e. probably a racer, or some fleet animal (as being slender in the waist)",
		"kjv" : "[phrase] greyhound"
	},
	"H2224" : {
		"lemma" : "זָרַח",
		"xlit" : "zârach",
		"pron" : "zaw-rakh'",
		"derivation" : "a primitive root",
		"def" : "properly, to irradiate (or shoot forth beams), i.e. to rise (as the sun); specifically, to appear (as a symptom of leprosy)",
		"kjv" : "arise, rise (up), as soon as it is up"
	},
	"H2225" : {
		"lemma" : "זֶרַח",
		"xlit" : "zerach",
		"pron" : "zeh'-rakh",
		"derivation" : "from H2224 (זָרַח)",
		"def" : "a rising of light",
		"kjv" : "rising"
	},
	"H2226" : {
		"lemma" : "זֶרַח",
		"xlit" : "Zerach",
		"pron" : "zeh'-rakh",
		"derivation" : "the same as H2225 (זֶרַח)",
		"def" : "Zerach, the name of three Israelites, also of an Idumaean and an Ethiopian prince",
		"kjv" : "Zarah, Zerah"
	},
	"H2227" : {
		"lemma" : "זַרְחִי",
		"xlit" : "Zarchîy",
		"pron" : "zar-khee'",
		"derivation" : "patronymically from H2226 (זֶרַח)",
		"def" : "a Zarchite or descendant of Zerach",
		"kjv" : "Zarchite"
	},
	"H2228" : {
		"lemma" : "זְרַחְיָה",
		"xlit" : "Zᵉrachyâh",
		"pron" : "zer-akh-yaw'",
		"derivation" : "from H2225 (זֶרַח) and H3050 (יָהּ); Jah has risen",
		"def" : "Zerachjah, the name of two Israelites",
		"kjv" : "Zerahiah"
	},
	"H2229" : {
		"lemma" : "זָרַם",
		"xlit" : "zâram",
		"pron" : "zaw-ram'",
		"derivation" : "a primitive root",
		"def" : "to gush (as water)",
		"kjv" : "carry away as with a flood, pour out"
	},
	"H2230" : {
		"lemma" : "זֶרֶם",
		"xlit" : "zerem",
		"pron" : "zeh'-rem",
		"derivation" : "from H2229 (זָרַם)",
		"def" : "a gush of water",
		"kjv" : "flood, overflowing, shower, storm, tempest"
	},
	"H2231" : {
		"lemma" : "זִרְמָה",
		"xlit" : "zirmâh",
		"pron" : "zir-maw'",
		"derivation" : "feminine of H2230 (זֶרֶם)",
		"def" : "a gushing of fluid (semen)",
		"kjv" : "issue"
	},
	"H2232" : {
		"lemma" : "זָרַע",
		"xlit" : "zâraʻ",
		"pron" : "zaw-rah'",
		"derivation" : "a primitive root",
		"def" : "to sow; figuratively, to disseminate, plant, fructify",
		"kjv" : "bear, conceive seed, set with sow(-er), yield"
	},
	"H2233" : {
		"lemma" : "זֶרַע",
		"xlit" : "zeraʻ",
		"pron" : "zeh'-rah",
		"derivation" : "from H2232 (זָרַע)",
		"def" : "seed; figuratively, fruit, plant, sowing-time, posterity",
		"kjv" : "[idiom] carnally, child, fruitful, seed(-time), sowing-time"
	},
	"H2234" : {
		"lemma" : "זְרַע",
		"xlit" : "zᵉraʻ",
		"pron" : "zer-ah'",
		"derivation" : "(Aramaic) corresponding to H2233 (זֶרַע)",
		"def" : "posterity",
		"kjv" : "seed"
	},
	"H2235" : {
		"lemma" : "זֵרֹעַ",
		"xlit" : "zêrôaʻ",
		"pron" : "zay-ro'-ah",
		"derivation" : "or זֵרָעֹן; from H2232 (זָרַע)",
		"def" : "something sown (only in the plural), i.e. a vegetable (as food)",
		"kjv" : "pulse"
	},
	"H2236" : {
		"lemma" : "זָרַק",
		"xlit" : "zâraq",
		"pron" : "zaw-rak'",
		"derivation" : "a primitive root",
		"def" : "to sprinkle (fluid or solid particles)",
		"kjv" : "be here and there, scatter, sprinkle, strew"
	},
	"H2237" : {
		"lemma" : "זָרַר",
		"xlit" : "zârar",
		"pron" : "zaw-rar'",
		"derivation" : "a primitive root (compare H2114 (זוּר))",
		"def" : "perhaps to diffuse, i.e. (specifically) to sneeze",
		"kjv" : "sneeze"
	},
	"H2238" : {
		"lemma" : "זֶרֶשׁ",
		"xlit" : "Zeresh",
		"pron" : "zeh'-resh",
		"derivation" : "of Persian origin",
		"def" : "Zeresh, Haman's wife",
		"kjv" : "Zeresh"
	},
	"H2239" : {
		"lemma" : "זֶרֶת",
		"xlit" : "zereth",
		"pron" : "zeh'-reth",
		"derivation" : "from H2219 (זָרָה)",
		"def" : "the spread of the fingers, i.e. a span",
		"kjv" : "span"
	},
	"H2240" : {
		"lemma" : "זַתּוּא",
		"xlit" : "Zattûwʼ",
		"pron" : "zat-too'",
		"derivation" : "of uncertain derivation",
		"def" : "Zattu, an Israelite",
		"kjv" : "Zattu"
	},
	"H2241" : {
		"lemma" : "זֵתָם",
		"xlit" : "Zêthâm",
		"pron" : "zay-thawm'",
		"derivation" : "apparently a variation for H2133 (זֵיתָן)",
		"def" : "Zetham, an Israelite",
		"kjv" : "Zetham"
	},
	"H2242" : {
		"lemma" : "זֵתַר",
		"xlit" : "Zêthar",
		"pron" : "zay-thar'",
		"derivation" : "of Persian origin",
		"def" : "Zethar, a eunuch of Xerxes",
		"kjv" : "Zethar. j"
	},
	"H2243" : {
		"lemma" : "חֹב",
		"xlit" : "chôb",
		"pron" : "khobe",
		"derivation" : "by contraction from H2245 (חָבַב)",
		"def" : "properly, a cherisher, i.e. the bosom",
		"kjv" : "bosom"
	},
	"H2244" : {
		"lemma" : "חָבָא",
		"xlit" : "châbâʼ",
		"pron" : "khaw-baw'",
		"derivation" : "a primitive root (compare H2245 (חָבַב))",
		"def" : "to secrete",
		"kjv" : "[idiom] held, hide (self), do secretly"
	},
	"H2245" : {
		"lemma" : "חָבַב",
		"xlit" : "châbab",
		"pron" : "khaw-bab'",
		"derivation" : "a primitive root (compare H2244 (חָבָא), H2247 (חָבָה))",
		"def" : "properly, to hide (as in the bosom), i.e. to cherish (with affection)",
		"kjv" : "love"
	},
	"H2246" : {
		"lemma" : "חֹבָב",
		"xlit" : "Chôbâb",
		"pron" : "kho-bawb'",
		"derivation" : "from H2245 (חָבַב); cherished",
		"def" : "Chobab, father-in-law of Moses",
		"kjv" : "Hobab"
	},
	"H2247" : {
		"lemma" : "חָבָה",
		"xlit" : "châbâh",
		"pron" : "khaw-bah'",
		"derivation" : "a primitive root (compare H2245 (חָבַב))",
		"def" : "to secrete",
		"kjv" : "hide (self)"
	},
	"H2248" : {
		"lemma" : "חֲבוּלָה",
		"xlit" : "chăbûwlâh",
		"pron" : "khab-oo-law'",
		"derivation" : "(Aramaic) from H2255 (חֲבַל)",
		"def" : "properly, overthrown, i.e. (morally) crime",
		"kjv" : "hurt"
	},
	"H2249" : {
		"lemma" : "חָבוֹר",
		"xlit" : "Châbôwr",
		"pron" : "khaw-bore'",
		"derivation" : "from H2266 (חָבַר); united",
		"def" : "Chabor, a river of Assyria",
		"kjv" : "Habor"
	},
	"H2250" : {
		"lemma" : "חַבּוּרָה",
		"xlit" : "chabbûwrâh",
		"pron" : "khab-boo-raw'",
		"derivation" : "or חַבֻּרָה; or חֲבֻרָה; from H2266 (חָבַר)",
		"def" : "properly, bound (with stripes), i.e. a weal (or black-and-blue mark itself)",
		"kjv" : "blueness, bruise, hurt, stripe, wound"
	},
	"H2251" : {
		"lemma" : "חָבַט",
		"xlit" : "châbaṭ",
		"pron" : "khaw-bat'",
		"derivation" : "a primitive root",
		"def" : "to knock out or off",
		"kjv" : "beat (off, out), thresh"
	},
	"H2252" : {
		"lemma" : "חֲבַיָּה",
		"xlit" : "Chăbayâh",
		"pron" : "khab-ah-yaw'",
		"derivation" : "or חֲבָיָהlemma חֲבָיה missing vowel, corrected to חֲבָיָה; from H2247 (חָבָה) and H3050 (יָהּ); Jah has hidden",
		"def" : "Chabajah, an Israelite",
		"kjv" : "Habaiah"
	},
	"H2253" : {
		"lemma" : "חֶבְיוֹן",
		"xlit" : "chebyôwn",
		"pron" : "kheb-yone'",
		"derivation" : "from H2247 (חָבָה)",
		"def" : "a concealment",
		"kjv" : "hiding"
	},
	"H2254" : {
		"lemma" : "חָבַל",
		"xlit" : "châbal",
		"pron" : "khaw-bal'",
		"derivation" : "a primitive root",
		"def" : "to wind tightly (as a rope), i.e. to bind; specifically, by a pledge; figuratively, to pervert, destroy; also to writhe in pain (especially of parturition)",
		"kjv" : "[idiom] at all, band, bring forth, (deal) corrupt(-ly), destroy, offend, lay to (take a) pledge, spoil, travail, [idiom] very, withhold"
	},
	"H2255" : {
		"lemma" : "חֲבַל",
		"xlit" : "chăbal",
		"pron" : "khab-al'",
		"derivation" : "(Aramaic) corresponding to H2254 (חָבַל)",
		"def" : "to ruin",
		"kjv" : "destroy, hurt"
	},
	"H2256" : {
		"lemma" : "חֶבֶל",
		"xlit" : "chebel",
		"pron" : "kheh'-bel",
		"derivation" : "or חֵבֶל; from H2254 (חָבַל)",
		"def" : "a rope (as twisted), especially a measuring line; by implication, a district or inheritance (as measured); or a noose (as of cords); figuratively, a company (as if tied together); also a throe (especially of parturition); also ruin",
		"kjv" : "band, coast, company, cord, country, destruction, line, lot, pain, pang, portion, region, rope, snare, sorrow, tackling"
	},
	"H2257" : {
		"lemma" : "חֲבַל",
		"xlit" : "chăbal",
		"pron" : "khab-al'",
		"derivation" : "(Aramaic) from H2255 (חֲבַל)",
		"def" : "harm (personal or pecuniary)",
		"kjv" : "damage, hurt"
	},
	"H2258" : {
		"lemma" : "חֲבֹל",
		"xlit" : "chăbôl",
		"pron" : "khab-ole'",
		"derivation" : "or (feminine) חֲבֹלָה; from H2254 (חָבַל)",
		"def" : "a pawn (as security for debt)",
		"kjv" : "pledge"
	},
	"H2259" : {
		"lemma" : "חֹבֵל",
		"xlit" : "chôbêl",
		"pron" : "kho-bale'",
		"derivation" : "active participle from H2254 (חָבַל) (in the sense of handling ropes)",
		"def" : "a sailor",
		"kjv" : "pilot, shipmaster"
	},
	"H2260" : {
		"lemma" : "חִבֵּל",
		"xlit" : "chibbêl",
		"pron" : "khib-bale'",
		"derivation" : "from H2254 (חָבַל) (in the sense of furnished with ropes)",
		"def" : "a mast",
		"kjv" : "mast"
	},
	"H2261" : {
		"lemma" : "חֲבַצֶּלֶת",
		"xlit" : "chăbatstseleth",
		"pron" : "khab-ats-tseh'-leth",
		"derivation" : "of uncertain derivation",
		"def" : "probably meadow-saffron",
		"kjv" : "rose"
	},
	"H2262" : {
		"lemma" : "חֲבַצַּנְיָה",
		"xlit" : "Chăbatstsanyâh",
		"pron" : "khab-ats-tsan-yaw'",
		"derivation" : "of uncertain derivation",
		"def" : "Chabatstsanjah, a Rechabite",
		"kjv" : "Habazaniah"
	},
	"H2263" : {
		"lemma" : "חָבַק",
		"xlit" : "châbaq",
		"pron" : "khaw-bak'",
		"derivation" : "a primitive root",
		"def" : "to clasp (the hands or in embrace)",
		"kjv" : "embrace, fold"
	},
	"H2264" : {
		"lemma" : "חִבֻּק",
		"xlit" : "chibbuq",
		"pron" : "khib-book'",
		"derivation" : "from H2263 (חָבַק)",
		"def" : "a clasping of the hands (in idleness)",
		"kjv" : "fold"
	},
	"H2265" : {
		"lemma" : "חֲבַקּוּק",
		"xlit" : "Chăbaqqûwq",
		"pron" : "khab-ak-kook'",
		"derivation" : "by reduplication from H2263 (חָבַק); embrace",
		"def" : "Chabakkuk, the prophet",
		"kjv" : "Habakkuk"
	},
	"H2266" : {
		"lemma" : "חָבַר",
		"xlit" : "châbar",
		"pron" : "khaw-bar'",
		"derivation" : "a primitive root",
		"def" : "to join (literally or figuratively); specifically (by means of spells) to fascinate",
		"kjv" : "charm(-er), be compact, couple (together), have fellowship with, heap up, join (self, together), league"
	},
	"H2267" : {
		"lemma" : "חֶבֶר",
		"xlit" : "cheber",
		"pron" : "kheh'-ber",
		"derivation" : "from H2266 (חָבַר)",
		"def" : "a society; also a spell",
		"kjv" : "[phrase] charmer(-ing), company, enchantment, [idiom] wide"
	},
	"H2268" : {
		"lemma" : "חֶבֶר",
		"xlit" : "Cheber",
		"pron" : "kheh'-ber",
		"derivation" : "the same as H2267 (חֶבֶר); community",
		"def" : "Cheber, the name of a Kenite and of three Israelites",
		"kjv" : "Heber"
	},
	"H2269" : {
		"lemma" : "חֲבַר",
		"xlit" : "chăbar",
		"pron" : "khab-ar'",
		"derivation" : "(Aramaic) from a root corresponding to H2266 (חָבַר)",
		"def" : "an associate",
		"kjv" : "companion, fellow"
	},
	"H2270" : {
		"lemma" : "חָבֵר",
		"xlit" : "châbêr",
		"pron" : "khaw-bare'",
		"derivation" : "from H2266 (חָבַר)",
		"def" : "an associate",
		"kjv" : "companion, fellow, knit together"
	},
	"H2271" : {
		"lemma" : "חַבָּר",
		"xlit" : "chabbâr",
		"pron" : "khab-bawr'",
		"derivation" : "from H2266 (חָבַר)",
		"def" : "a partner",
		"kjv" : "companion"
	},
	"H2272" : {
		"lemma" : "חֲבַרְבֻּרָה",
		"xlit" : "chăbarburâh",
		"pron" : "khab-ar-boo-raw'",
		"derivation" : "by reduplication from H2266 (חָבַר)",
		"def" : "a streak (like a line), as on the tiger",
		"kjv" : "spot"
	},
	"H2273" : {
		"lemma" : "חַבְרָה",
		"xlit" : "chabrâh",
		"pron" : "khab-raw'",
		"derivation" : "(Aramaic) feminine of H2269 (חֲבַר)",
		"def" : "an associate",
		"kjv" : "other"
	},
	"H2274" : {
		"lemma" : "חֶבְרָה",
		"xlit" : "chebrâh",
		"pron" : "kheb-raw'",
		"derivation" : "feminine of H2267 (חֶבֶר)",
		"def" : "association",
		"kjv" : "company"
	},
	"H2275" : {
		"lemma" : "חֶבְרוֹן",
		"xlit" : "Chebrôwn",
		"pron" : "kheb-rone'",
		"derivation" : "from H2267 (חֶבֶר); seat of association",
		"def" : "Chebron, a place in Palestine, also the name of two Israelites",
		"kjv" : "Hebron"
	},
	"H2276" : {
		"lemma" : "חֶבְרוֹנִי",
		"xlit" : "Chebrôwnîy",
		"pron" : "kheb-ro-nee'",
		"derivation" : "or חֶבְרֹנִי; patronymically from H2275 (חֶבְרוֹן)",
		"def" : "Chebronite (collectively), an inhabitant of Chebron",
		"kjv" : "Hebronites"
	},
	"H2277" : {
		"lemma" : "חֶבְרִי",
		"xlit" : "Chebrîy",
		"pron" : "kheb-ree'",
		"derivation" : "patronymically from H2268 (חֶבֶר)",
		"def" : "a Chebrite (collectively) or descendants of Cheber",
		"kjv" : "Heberites"
	},
	"H2278" : {
		"lemma" : "חֲבֶרֶת",
		"xlit" : "chăbereth",
		"pron" : "khab-eh'-reth",
		"derivation" : "feminine of H2270 (חָבֵר)",
		"def" : "a consort",
		"kjv" : "companion"
	},
	"H2279" : {
		"lemma" : "חֹבֶרֶת",
		"xlit" : "chôbereth",
		"pron" : "kho-beh'-reth",
		"derivation" : "feminine active participle of H2266 (חָבַר)",
		"def" : "a joint",
		"kjv" : "which coupleth, coupling"
	},
	"H2280" : {
		"lemma" : "חָבַשׁ",
		"xlit" : "châbash",
		"pron" : "khaw-bash'",
		"derivation" : "a primitive root",
		"def" : "to wrap firmly (especially a turban, compress, or saddle); figuratively, to stop, to rule",
		"kjv" : "bind (up), gird about, govern, healer, put, saddle, wrap about"
	},
	"H2281" : {
		"lemma" : "חָבֵת",
		"xlit" : "châbêth",
		"pron" : "khaw-bayth'",
		"derivation" : "from an unused root probably meaning to cook (compare H4227 (מַחֲבַת))",
		"def" : "something fried, probably a griddle-cake",
		"kjv" : "pan"
	},
	"H2282" : {
		"lemma" : "חַג",
		"xlit" : "chag",
		"pron" : "khag",
		"derivation" : "or חָג; from H2287 (חָגַג)",
		"def" : "a festival, or a victim therefor",
		"kjv" : "(solemn) feast (day), sacrifice, solemnity"
	},
	"H2283" : {
		"lemma" : "חָגָא",
		"xlit" : "châgâʼ",
		"pron" : "khaw-gaw'",
		"derivation" : "from an unused root meaning to revolve (compare H2287 (חָגַג))",
		"def" : "properly, vertigo, i.e. (figuratively) fear",
		"kjv" : "terror"
	},
	"H2284" : {
		"lemma" : "חָגָב",
		"xlit" : "châgâb",
		"pron" : "khaw-gawb'",
		"derivation" : "of uncertain derivation",
		"def" : "a locust",
		"kjv" : "locust"
	},
	"H2285" : {
		"lemma" : "חָגָב",
		"xlit" : "Châgâb",
		"pron" : "khaw-gawb'",
		"derivation" : "the same as H2284 (חָגָב); locust",
		"def" : "Chagab, one of the Nethinim",
		"kjv" : "Hagab"
	},
	"H2286" : {
		"lemma" : "חֲגָבָא",
		"xlit" : "Chăgâbâʼ",
		"pron" : "khag-aw-baw'",
		"derivation" : "or חֲגָבָה; feminine of H2285 (חָגָב); locust",
		"def" : "Chagaba or Chagabah, one of the Nethinim",
		"kjv" : "Hagaba, Hagabah"
	},
	"H2287" : {
		"lemma" : "חָגַג",
		"xlit" : "châgag",
		"pron" : "khaw-gag'",
		"derivation" : "a primitive root (compare H2283 (חָגָא), H2328 (חוּג))",
		"def" : "properly, to move in acircle, i.e. (specifically) to march in asacred procession, to observe afestival; by implication, to be giddy",
		"kjv" : "celebrate, dance, (keep, hold) a (solemn) feast (holiday), reel to and fro"
	},
	"H2288" : {
		"lemma" : "חֲגָו",
		"xlit" : "chăgâv",
		"pron" : "khag-awv'",
		"derivation" : "from an unused root meaning to take refuge",
		"def" : "a rift in rocks",
		"kjv" : "cleft"
	},
	"H2289" : {
		"lemma" : "חָגוֹר",
		"xlit" : "châgôwr",
		"pron" : "khaw-gore'",
		"derivation" : "from H2296 (חָגַר)",
		"def" : "belted",
		"kjv" : "girded with"
	},
	"H2290" : {
		"lemma" : "חֲגוֹר",
		"xlit" : "chăgôwr",
		"pron" : "khag-ore'",
		"derivation" : "or חֲגֹר; and (feminine) חֲגוֹרָה; or חֲגֹרָה; from H2296 (חָגַר)",
		"def" : "a belt (for the waist)",
		"kjv" : "apron, armour, gird(-le)"
	},
	"H2291" : {
		"lemma" : "חַגִּי",
		"xlit" : "Chaggîy",
		"pron" : "khag-ghee'",
		"derivation" : "from H2287 (חָגַג); festive",
		"def" : "Chaggi, an Israelite; also (patronymically) a Chaggite, or descendant of the same",
		"kjv" : "Haggi, Haggites"
	},
	"H2292" : {
		"lemma" : "חַגַּי",
		"xlit" : "Chaggay",
		"pron" : "khag-gah'-ee",
		"derivation" : "from H2282 (חַג); festive",
		"def" : "Chaggai, a Hebrew prophet",
		"kjv" : "Haggai"
	},
	"H2293" : {
		"lemma" : "חַגִּיָּה",
		"xlit" : "Chaggîyâh",
		"pron" : "khag-ghee-yaw'",
		"derivation" : "from H2282 (חַג) and H3050 (יָהּ); festival of Jah",
		"def" : "Chaggijah, an Israelite",
		"kjv" : "Haggiah"
	},
	"H2294" : {
		"lemma" : "חַגִּית",
		"xlit" : "Chaggîyth",
		"pron" : "khag-gheeth'",
		"derivation" : "feminine of H2291 (חַגִּי); festive",
		"def" : "Chaggith, a wife of David",
		"kjv" : "Haggith"
	},
	"H2295" : {
		"lemma" : "חׇגְלָה",
		"xlit" : "Choglâh",
		"pron" : "khog-law'",
		"derivation" : "of uncertain derivation; probably a partridge",
		"def" : "Choglah, an Israelitess",
		"kjv" : "Hoglah. See also H1031 (בֵּית חׇגְלָה)"
	},
	"H2296" : {
		"lemma" : "חָגַר",
		"xlit" : "châgar",
		"pron" : "khaw-gar'",
		"derivation" : "a primitive root",
		"def" : "to gird on (as a belt, armor, etc.)",
		"kjv" : "be able to put on, be afraid, appointed, gird, restrain, [idiom] on every side"
	},
	"H2297" : {
		"lemma" : "חַד",
		"xlit" : "chad",
		"pron" : "khad",
		"derivation" : "abridged from H259 (אֶחָד)",
		"def" : "one",
		"kjv" : "one"
	},
	"H2298" : {
		"lemma" : "חַד",
		"xlit" : "chad",
		"pron" : "khad",
		"derivation" : "(Aramaic) corresponding to H2297 (חַד)",
		"def" : "as card. one; as article single; as an ordinal, first; adverbially, at once",
		"kjv" : "a, first, one, together"
	},
	"H2299" : {
		"lemma" : "חַד",
		"xlit" : "chad",
		"pron" : "khad",
		"derivation" : "from H2300 (חָדַד)",
		"def" : "sharp",
		"kjv" : "sharp"
	},
	"H2300" : {
		"lemma" : "חָדַד",
		"xlit" : "châdad",
		"pron" : "khaw-dad'",
		"derivation" : "a primitive root",
		"def" : "to be (causatively, make) sharp or (figuratively) severe",
		"kjv" : "be fierce, sharpen"
	},
	"H2301" : {
		"lemma" : "חֲדַד",
		"xlit" : "Chădad",
		"pron" : "khad-ad'",
		"derivation" : "from H2300 (חָדַד); fierce",
		"def" : "Chadad, an Ishmaelite",
		"kjv" : "Hadad"
	},
	"H2302" : {
		"lemma" : "חָדָה",
		"xlit" : "châdâh",
		"pron" : "khaw-daw'",
		"derivation" : "a primitive root",
		"def" : "to rejoice",
		"kjv" : "make glad, be joined, rejoice"
	},
	"H2303" : {
		"lemma" : "חַדּוּד",
		"xlit" : "chaddûwd",
		"pron" : "khad-dood'",
		"derivation" : "from H2300 (חָדַד)",
		"def" : "a point",
		"kjv" : "sharp"
	},
	"H2304" : {
		"lemma" : "חֶדְוָה",
		"xlit" : "chedvâh",
		"pron" : "khed-vaw'",
		"derivation" : "from H2302 (חָדָה)",
		"def" : "rejoicing",
		"kjv" : "gladness, joy"
	},
	"H2305" : {
		"lemma" : "חֶדְוָה",
		"xlit" : "chedvâh",
		"pron" : "khed-vaw'",
		"derivation" : "(Aramaic) corresponding to H2304 (חֶדְוָה)",
		"def" : "{rejoicing}",
		"kjv" : "joy"
	},
	"H2306" : {
		"lemma" : "חֲדִי",
		"xlit" : "chădîy",
		"pron" : "khad-ee'",
		"derivation" : "(Aramaic) corresponding to H2373 (חָזֶה)",
		"def" : "a breast",
		"kjv" : "breast"
	},
	"H2307" : {
		"lemma" : "חָדִיד",
		"xlit" : "Châdîyd",
		"pron" : "khaw-deed'",
		"derivation" : "from H2300 (חָדַד); a peak",
		"def" : "Chadid, a place in Palestine",
		"kjv" : "Hadid"
	},
	"H2308" : {
		"lemma" : "חָדַל",
		"xlit" : "châdal",
		"pron" : "khaw-dal'",
		"derivation" : "a primitive root",
		"def" : "properly, to be flabby, i.e. (by implication) desist; (figuratively) be lacking or idle",
		"kjv" : "cease, end, fall, forbear, forsake, leave (off), let alone, rest, be unoccupied, want"
	},
	"H2309" : {
		"lemma" : "חֶדֶל",
		"xlit" : "chedel",
		"pron" : "kheh'-del",
		"derivation" : "from H2308 (חָדַל)",
		"def" : "rest, i.e. the state of the dead",
		"kjv" : "world"
	},
	"H2310" : {
		"lemma" : "חָדֵל",
		"xlit" : "châdêl",
		"pron" : "khaw-dale'",
		"derivation" : "from H2308 (חָדַל)",
		"def" : "vacant, i.e. ceasing or destitute",
		"kjv" : "he that forbeareth, frail, rejected"
	},
	"H2311" : {
		"lemma" : "חַדְלַי",
		"xlit" : "Chadlay",
		"pron" : "khad-lah'-ee",
		"derivation" : "from H2309 (חֶדֶל); idle",
		"def" : "Chadlai, an Israelite",
		"kjv" : "Hadlai"
	},
	"H2312" : {
		"lemma" : "חֵדֶק",
		"xlit" : "chêdeq",
		"pron" : "khay'-dek",
		"derivation" : "from an unused root meaning to sting",
		"def" : "a prickly plant",
		"kjv" : "brier, thorn"
	},
	"H2313" : {
		"lemma" : "חִדֶּקֶל",
		"xlit" : "Chiddeqel",
		"pron" : "khid-deh'-kel",
		"derivation" : "probably of foreign origin",
		"def" : "the Chiddekel (or Tigris) river",
		"kjv" : "Hiddekel"
	},
	"H2314" : {
		"lemma" : "חָדַר",
		"xlit" : "châdar",
		"pron" : "khaw-dar'",
		"derivation" : "a primitive root",
		"def" : "properly, to inclose (as a room), i.e. (by analogy,) to beset (as in a siege)",
		"kjv" : "enter a privy chamber"
	},
	"H2315" : {
		"lemma" : "חֶדֶר",
		"xlit" : "cheder",
		"pron" : "kheh'-der",
		"derivation" : "from H2314 (חָדַר)",
		"def" : "an apartment (usually literal)",
		"kjv" : "((bed) inner) chamber, innermost(-ward) part, parlour, [phrase] south, [idiom] within"
	},
	"H2316" : {
		"lemma" : "חֲדַר",
		"xlit" : "Chădar",
		"pron" : "khad-ar'",
		"derivation" : "another form for H2315 (חֶדֶר); chamber",
		"def" : "Chadar, an Ishmaelite",
		"kjv" : "Hadar"
	},
	"H2317" : {
		"lemma" : "חַדְרָךְ",
		"xlit" : "Chadrâk",
		"pron" : "khad-rawk'",
		"derivation" : "of uncertain derivation",
		"def" : "Chadrak, a Syrian deity",
		"kjv" : "Hadrach"
	},
	"H2318" : {
		"lemma" : "חָדַשׁ",
		"xlit" : "châdash",
		"pron" : "khaw-dash'",
		"derivation" : "a primitive root",
		"def" : "to be new; causatively, to rebuild",
		"kjv" : "renew, repair"
	},
	"H2319" : {
		"lemma" : "חָדָשׁ",
		"xlit" : "châdâsh",
		"pron" : "khaw-dawsh'",
		"derivation" : "from H2318 (חָדַשׁ)",
		"def" : "new",
		"kjv" : "fresh, new thing"
	},
	"H2320" : {
		"lemma" : "חֹדֶשׁ",
		"xlit" : "chôdesh",
		"pron" : "kho'-desh",
		"derivation" : "from H2318 (חָדַשׁ)",
		"def" : "the new moon; by implication, a month",
		"kjv" : "month(-ly), new moon"
	},
	"H2321" : {
		"lemma" : "חֹדֶשׁ",
		"xlit" : "Chôdesh",
		"pron" : "kho'-desh",
		"derivation" : "the same as H2320 (חֹדֶשׁ)",
		"def" : "Chodesh, an Israelitess",
		"kjv" : "Hodesh"
	},
	"H2322" : {
		"lemma" : "חֲדָשָׁה",
		"xlit" : "Chădâshâh",
		"pron" : "khad-aw-shaw'",
		"derivation" : "feminine of H2319 (חָדָשׁ); new",
		"def" : "Chadashah, a place in Palestine",
		"kjv" : "Hadashah"
	},
	"H2323" : {
		"lemma" : "חֲדָת",
		"xlit" : "chădâth",
		"pron" : "khad-ath'",
		"derivation" : "(Aramaic) corresponding to H2319 (חָדָשׁ)",
		"def" : "new",
		"kjv" : "new"
	},
	"H2324" : {
		"lemma" : "חֲוָא",
		"xlit" : "chăvâʼ",
		"pron" : "khav-aw'",
		"derivation" : "(Aramaic) corresponding to H2331 (חָוָה)",
		"def" : "to show",
		"kjv" : "shew"
	},
	"H2325" : {
		"lemma" : "חוּב",
		"xlit" : "chûwb",
		"pron" : "khoob",
		"derivation" : "also חָיַב; a primitive root",
		"def" : "properly, perhaps to tie, i.e. (figuratively and reflexively) to owe, or (by implication) to forfeit",
		"kjv" : "make endanger"
	},
	"H2326" : {
		"lemma" : "חוֹב",
		"xlit" : "chôwb",
		"pron" : "khobe",
		"derivation" : "from H2325 (חוּב)",
		"def" : "debt",
		"kjv" : "debtor"
	},
	"H2327" : {
		"lemma" : "חוֹבָה",
		"xlit" : "chôwbâh",
		"pron" : "kho-baw'",
		"derivation" : "feminine active participle of H2247 (חָבָה); hiding place",
		"def" : "Chobah, a place in Syria",
		"kjv" : "Hobah"
	},
	"H2328" : {
		"lemma" : "חוּג",
		"xlit" : "chûwg",
		"pron" : "khoog",
		"derivation" : "a primitive root (compare H2287 (חָגַג))",
		"def" : "to describe a circle",
		"kjv" : "compass"
	},
	"H2329" : {
		"lemma" : "חוּג",
		"xlit" : "chûwg",
		"pron" : "khoog",
		"derivation" : "from H2328 (חוּג)",
		"def" : "a circle",
		"kjv" : "circle, circuit, compass"
	},
	"H2330" : {
		"lemma" : "חוּד",
		"xlit" : "chûwd",
		"pron" : "khood",
		"derivation" : "a primitive root",
		"def" : "properly, to tie a knot, i.e. (figuratively) to propound a riddle",
		"kjv" : "put forth"
	},
	"H2331" : {
		"lemma" : "חָוָה",
		"xlit" : "châvâh",
		"pron" : "khaw-vah'",
		"derivation" : "a primitive root; (compare H2324 (חֲוָא), H2421 (חָיָה))",
		"def" : "properly, to live; by implication (intensively) to declare or show",
		"kjv" : "show"
	},
	"H2332" : {
		"lemma" : "חַוָּה",
		"xlit" : "Chavvâh",
		"pron" : "khav-vaw'",
		"derivation" : "causatively from H2331 (חָוָה); life-giver",
		"def" : "Chavvah (or Eve), the first woman",
		"kjv" : "Eve"
	},
	"H2333" : {
		"lemma" : "חַוָּה",
		"xlit" : "chavvâh",
		"pron" : "khav-vaw'",
		"derivation" : "properly, the same as H2332 (חַוָּה) (lifegiving, i.e. living-place)",
		"def" : "by implication, an encampment or village",
		"kjv" : "(small) town"
	},
	"H2334" : {
		"lemma" : "חַוּוֹת יָעִיר",
		"xlit" : "Chavvôwth Yâʻîyr",
		"pron" : "khav-vothe' yaw-eer'",
		"derivation" : "from the plural of H2333 (חַוָּה) and a modification of H3265 (יָעוּר)",
		"def" : "hamlets of Jair, a region of Palestine",
		"kjv" : "(Bashan-) Havoth-jair"
	},
	"H2335" : {
		"lemma" : "חוֹזַי",
		"xlit" : "Chôwzay",
		"pron" : "kho-zah'-ee",
		"derivation" : "from H2374 (חֹזֶה); visionary",
		"def" : "Chozai, an Israelite",
		"kjv" : "the seers"
	},
	"H2336" : {
		"lemma" : "חוֹחַ",
		"xlit" : "chôwach",
		"pron" : "kho'-akh",
		"derivation" : "from an unused root apparently meaning to pierce",
		"def" : "a thorn; by analogy, a ring forthe nose",
		"kjv" : "bramble, thistle, thorn"
	},
	"H2337" : {
		"lemma" : "חָוָח",
		"xlit" : "châvâch",
		"pron" : "khaw-vawkh'",
		"derivation" : "perhaps the same as H2336 (חוֹחַ)",
		"def" : "a dell or crevice (as if pierced in the earth)",
		"kjv" : "thicket"
	},
	"H2338" : {
		"lemma" : "חוּט",
		"xlit" : "chûwṭ",
		"pron" : "khoot",
		"derivation" : "(Aramaic) corresponding to the root of H2339 (חוּט), perhaps as a denominative",
		"def" : "to string together, i.e. (figuratively) to repair",
		"kjv" : "join"
	},
	"H2339" : {
		"lemma" : "חוּט",
		"xlit" : "chûwṭ",
		"pron" : "khoot",
		"derivation" : "from an unused root probably meaning to sew",
		"def" : "a string; by implication, a measuring tape",
		"kjv" : "cord, fillet, line, thread"
	},
	"H2340" : {
		"lemma" : "חִוִּי",
		"xlit" : "Chivvîy",
		"pron" : "khiv-vee'",
		"derivation" : "perhaps from H2333 (חַוָּה); a villager",
		"def" : "a Chivvite, one of the aboriginal tribes of Palestine",
		"kjv" : "Hivite"
	},
	"H2341" : {
		"lemma" : "חֲוִילָה",
		"xlit" : "Chăvîylâh",
		"pron" : "khav-ee-law'",
		"derivation" : "probably from H2342 (חוּל); circular",
		"def" : "Chavilah, the name of two or three eastern regions; also perhaps of two men",
		"kjv" : "Havilah"
	},
	"H2342" : {
		"lemma" : "חוּל",
		"xlit" : "chûwl",
		"pron" : "khool",
		"derivation" : "or חִיל; a primitive root",
		"def" : "properly, to twist or whirl (in a circular or spiral manner), i.e. (specifically) to dance, to writhe in pain (especially of parturition) or fear; figuratively, to wait, to pervert",
		"kjv" : "bear, (make to) bring forth, (make to) calve, dance, drive away, fall grievously (with pain), fear, form, great, grieve, (be) grievous, hope, look, make, be in pain, be much (sore) pained, rest, shake, shapen, (be) sorrow(-ful), stay, tarry, travail (with pain), tremble, trust, wait carefully (patiently), be wounded"
	},
	"H2343" : {
		"lemma" : "חוּל",
		"xlit" : "Chûwl",
		"pron" : "khool",
		"derivation" : "from H2342 (חוּל); a circle",
		"def" : "Chul, a son of Aram; also the region settled by him",
		"kjv" : "Hul"
	},
	"H2344" : {
		"lemma" : "חוֹל",
		"xlit" : "chôwl",
		"pron" : "khole",
		"derivation" : "from H2342 (חוּל)",
		"def" : "sand (as round or whirling particles)",
		"kjv" : "sand"
	},
	"H2345" : {
		"lemma" : "חוּם",
		"xlit" : "chûwm",
		"pron" : "khoom",
		"derivation" : "from an unused root meaning to be warm, i.e. (by implication)",
		"def" : "sunburnt or swarthy (blackish)",
		"kjv" : "brown"
	},
	"H2346" : {
		"lemma" : "חוֹמָה",
		"xlit" : "chôwmâh",
		"pron" : "kho-maw'",
		"derivation" : "feminine active participle of an unused root apparently meaning to join",
		"def" : "a wall of protection",
		"kjv" : "wall, walled"
	},
	"H2347" : {
		"lemma" : "חוּס",
		"xlit" : "chûwç",
		"pron" : "khoos",
		"derivation" : "a primitive root",
		"def" : "properly, to cover, i.e. (figuratively) to compassionate",
		"kjv" : "pity, regard, spare"
	},
	"H2348" : {
		"lemma" : "חוֹף",
		"xlit" : "chôwph",
		"pron" : "khofe",
		"derivation" : "from an unused root meaning to cover",
		"def" : "a cove (as a sheltered bay)",
		"kjv" : "coast (of the sea), haven, shore, (sea-) side"
	},
	"H2349" : {
		"lemma" : "חוּפָם",
		"xlit" : "Chûwphâm",
		"pron" : "khoo-fawm'",
		"derivation" : "from the same as H2348 (חוֹף); protection",
		"def" : "Chupham, an Israelite",
		"kjv" : "Hupham"
	},
	"H2350" : {
		"lemma" : "חוּפָמִי",
		"xlit" : "Chûwphâmîy",
		"pron" : "khoo-faw-mee'",
		"derivation" : "patronymically from H2349 (חוּפָם)",
		"def" : "a Chuphamite or descendant of Chupham",
		"kjv" : "Huphamites"
	},
	"H2351" : {
		"lemma" : "חוּץ",
		"xlit" : "chûwts",
		"pron" : "khoots",
		"derivation" : "or (shortened) חֻץ; (both forms feminine in the plural) from an unused root meaning to sever",
		"def" : "properly, separate by awall, i.e. outside, outdoors",
		"kjv" : "abroad, field, forth, highway, more, out(-side, -ward), street, without"
	},
	"H2352" : {
		"lemma" : "חוּר",
		"xlit" : "chûwr",
		"pron" : "khoor",
		"derivation" : "or (shortened) חֻר; from an unused root probably meaning to bore",
		"def" : "the crevice of a serpent; the cell of aprison",
		"kjv" : "hole"
	},
	"H2353" : {
		"lemma" : "חוּר",
		"xlit" : "chûwr",
		"pron" : "khoor",
		"derivation" : "from H2357 (חָוַר)",
		"def" : "white linen",
		"kjv" : "white"
	},
	"H2354" : {
		"lemma" : "חוּר",
		"xlit" : "Chûwr",
		"pron" : "khoor",
		"derivation" : "the same as H2353 (חוּר) or H2352 (חוּר)",
		"def" : "Chur, the name of four Israelites and one Midianite",
		"kjv" : "Hur"
	},
	"H2355" : {
		"lemma" : "חוֹר",
		"xlit" : "chôwr",
		"pron" : "khore",
		"derivation" : "the same as H2353 (חוּר)",
		"def" : "white linen",
		"kjv" : "network. Compare H2715 (חֹר)"
	},
	"H2356" : {
		"lemma" : "חוֹר",
		"xlit" : "chôwr",
		"pron" : "khore",
		"derivation" : "or (shortened) חֹר; the same as H2352 (חוּר)",
		"def" : "a cavity, socket, den",
		"kjv" : "cave, hole"
	},
	"H2357" : {
		"lemma" : "חָוַר",
		"xlit" : "châvar",
		"pron" : "khaw-var'",
		"derivation" : "a primitive root",
		"def" : "to blanch (as with shame)",
		"kjv" : "wax pale"
	},
	"H2358" : {
		"lemma" : "חִוָּר",
		"xlit" : "chivvâr",
		"pron" : "khiv-vawr'",
		"derivation" : "(Aramaic) from a root corresponding to H2357 (חָוַר)",
		"def" : "white",
		"kjv" : "white"
	},
	"H2359" : {
		"lemma" : "חוּרִי",
		"xlit" : "Chûwrîy",
		"pron" : "khoo-ree'",
		"derivation" : "probably from H2353 (חוּר); linen-worker",
		"def" : "Churi, an Israelite",
		"kjv" : "Huri"
	},
	"H2360" : {
		"lemma" : "חוּרַי",
		"xlit" : "Chûwray",
		"pron" : "khoo-rah'ee",
		"derivation" : "probably an orthographical variation for H2359 (חוּרִי)",
		"def" : "Churai, an Israelite",
		"kjv" : "Hurai"
	},
	"H2361" : {
		"lemma" : "חוּרָם",
		"xlit" : "Chûwrâm",
		"pron" : "khoo-rawm'",
		"derivation" : "probably from H2353 (חוּר); whiteness, i.e. noble",
		"def" : "Churam, the name of an Israelite and two Syrians",
		"kjv" : "Huram. Compare H2438 (חִירָם)"
	},
	"H2362" : {
		"lemma" : "חַוְרָן",
		"xlit" : "Chavrân",
		"pron" : "khav-rawn'",
		"derivation" : "apparently from H2357 (חָוַר) (in the sense of H2352 (חוּר)); cavernous",
		"def" : "Chavran, a region East of the Jordan",
		"kjv" : "Hauran"
	},
	"H2363" : {
		"lemma" : "חוּשׁ",
		"xlit" : "chûwsh",
		"pron" : "koosh",
		"derivation" : "a primitive root",
		"def" : "to hurry; figuratively, to be eager with excitement or enjoyment",
		"kjv" : "(make) haste(-n), ready"
	},
	"H2364" : {
		"lemma" : "חוּשָׁה",
		"xlit" : "Chûwshâh",
		"pron" : "khoo-shaw'",
		"derivation" : "from H2363 (חוּשׁ); haste",
		"def" : "Chushah, an Israelite",
		"kjv" : "Hushah"
	},
	"H2365" : {
		"lemma" : "חוּשַׁי",
		"xlit" : "Chûwshay",
		"pron" : "khoo-shah'-ee",
		"derivation" : "from H2363 (חוּשׁ); hasty",
		"def" : "Chushai, an Israelite",
		"kjv" : "Hushai"
	},
	"H2366" : {
		"lemma" : "חוּשִׁים",
		"xlit" : "Chûwshîym",
		"pron" : "khoo-sheem'",
		"derivation" : "or חֻשִׁים; or חֻשִׁם; plural from H2363 (חוּשׁ); hasters",
		"def" : "Chushim, the name of three Israelites",
		"kjv" : "Hushim"
	},
	"H2367" : {
		"lemma" : "חוּשָׁם",
		"xlit" : "Chûwshâm",
		"pron" : "khoo-shawm'",
		"derivation" : "or חֻשָׁם; from H2363 (חוּשׁ); hastily",
		"def" : "Chusham, an Idumaean",
		"kjv" : "Husham"
	},
	"H2368" : {
		"lemma" : "חוֹתָם",
		"xlit" : "chôwthâm",
		"pron" : "kho-thawm'",
		"derivation" : "or חֹתָם; from H2856 (חָתַם)",
		"def" : "a signature-ring",
		"kjv" : "seal, signet"
	},
	"H2369" : {
		"lemma" : "חוֹתָם",
		"xlit" : "Chôwthâm",
		"pron" : "kho-thawm'",
		"derivation" : "the same as H2368 (חוֹתָם); seal",
		"def" : "Chotham, the name of two Israelites",
		"kjv" : "Hotham, Hothan"
	},
	"H2370" : {
		"lemma" : "חֲזָא",
		"xlit" : "chăzâʼ",
		"pron" : "khaz-aw'",
		"derivation" : "(Aramaic) or חֲזָה; (Aramaic), corresponding to H2372 (חָזָה)",
		"def" : "to gaze upon; mentally to dream, be usual (i.e. seem)",
		"kjv" : "behold, have (a dream), see, be wont"
	},
	"H2371" : {
		"lemma" : "חֲזָאֵל",
		"xlit" : "Chăzâʼêl",
		"pron" : "khaz-aw-ale'",
		"derivation" : "or חֲזָהאֵל; from H2372 (חָזָה) and H410 (אֵל); God has seen",
		"def" : "Chazael, a king of Syria",
		"kjv" : "Hazael"
	},
	"H2372" : {
		"lemma" : "חָזָה",
		"xlit" : "châzâh",
		"pron" : "khaw-zaw'",
		"derivation" : "a primitive root",
		"def" : "to gaze at; mentally to perceive, contemplate (with pleasure); specifically, to have avision of",
		"kjv" : "behold, look, prophesy, provide, see"
	},
	"H2373" : {
		"lemma" : "חָזֶה",
		"xlit" : "châzeh",
		"pron" : "khaw-zeh'",
		"derivation" : "from H2372 (חָזָה)",
		"def" : "the breast (as most seen in front)",
		"kjv" : "breast"
	},
	"H2374" : {
		"lemma" : "חֹזֶה",
		"xlit" : "chôzeh",
		"pron" : "kho-zeh'",
		"derivation" : "active participle of H2372 (חָזָה)",
		"def" : "a beholder in vision; also a compact (as looked upon with approval)",
		"kjv" : "agreement, prophet, see that, seer, (star-) gazer"
	},
	"H2375" : {
		"lemma" : "חֲזוֹ",
		"xlit" : "Chăzôw",
		"pron" : "khaz-o'",
		"derivation" : "from H2372 (חָזָה); seer",
		"def" : "Chazo, a nephew of Abraham",
		"kjv" : "Hazo"
	},
	"H2376" : {
		"lemma" : "חֵזֵו",
		"xlit" : "chêzêv",
		"pron" : "khay'-zev",
		"derivation" : "(Aramaic) from H2370 (חֲזָא)",
		"def" : "a sight",
		"kjv" : "look, vision"
	},
	"H2377" : {
		"lemma" : "חָזוֹן",
		"xlit" : "châzôwn",
		"pron" : "khaw-zone'",
		"derivation" : "from H2372 (חָזָה)",
		"def" : "a sight (mentally), i.e. a dream, revelation, or oracle",
		"kjv" : "vision"
	},
	"H2378" : {
		"lemma" : "חָזוֹת",
		"xlit" : "châzôwth",
		"pron" : "khaw-zooth'",
		"derivation" : "from H2372 (חָזָה)",
		"def" : "a revelation",
		"kjv" : "vision"
	},
	"H2379" : {
		"lemma" : "חֲזוֹת",
		"xlit" : "chăzôwth",
		"pron" : "khaz-oth'",
		"derivation" : "(Aramaic) from H2370 (חֲזָא)",
		"def" : "a view",
		"kjv" : "sight"
	},
	"H2380" : {
		"lemma" : "חָזוּת",
		"xlit" : "châzûwth",
		"pron" : "khaw-zooth'",
		"derivation" : "from H2372 (חָזָה)",
		"def" : "a look; hence (figuratively) striking appearance, revelation, or (by implication) compact",
		"kjv" : "agreement, notable (one), vision"
	},
	"H2381" : {
		"lemma" : "חֲזִיאֵל",
		"xlit" : "Chăzîyʼêl",
		"pron" : "khaz-ee-ale'",
		"derivation" : "from H2372 (חָזָה) and H410 (אֵל); seen of God",
		"def" : "Chaziel, a Levite",
		"kjv" : "Haziel"
	},
	"H2382" : {
		"lemma" : "חֲזָיָה",
		"xlit" : "Chăzâyâh",
		"pron" : "khaz-aw-yaw'",
		"derivation" : "from H2372 (חָזָה) and H3050 (יָהּ); Jah has seen",
		"def" : "Chazajah, an Israelite",
		"kjv" : "Hazaiah"
	},
	"H2383" : {
		"lemma" : "חֶזְיוֹן",
		"xlit" : "Chezyôwn",
		"pron" : "khez-yone'",
		"derivation" : "from H2372 (חָזָה); vision",
		"def" : "Chezjon, a Syrian",
		"kjv" : "Hezion"
	},
	"H2384" : {
		"lemma" : "חִזָּיוֹן",
		"xlit" : "chizzâyôwn",
		"pron" : "khiz-zaw-yone'",
		"derivation" : "from H2372 (חָזָה)",
		"def" : "a revelation, expectation by dream",
		"kjv" : "vision"
	},
	"H2385" : {
		"lemma" : "חֲזִיז",
		"xlit" : "chăzîyz",
		"pron" : "khaw-zeez'",
		"derivation" : "from an unused root meaning to glare",
		"def" : "a flash of lightning",
		"kjv" : "bright cloud, lightning"
	},
	"H2386" : {
		"lemma" : "חֲזִיר",
		"xlit" : "chăzîyr",
		"pron" : "khaz-eer'",
		"derivation" : "from an unused root probably meaning to enclose",
		"def" : "a hog (perhaps as penned)",
		"kjv" : "boar, swine"
	},
	"H2387" : {
		"lemma" : "חֵזִיר",
		"xlit" : "Chêzîyr",
		"pron" : "khay-zeer'",
		"derivation" : "from the same as H2386 (חֲזִיר); perhaps protected",
		"def" : "Chezir, the name of two Israelites",
		"kjv" : "Hezir"
	},
	"H2388" : {
		"lemma" : "חָזַק",
		"xlit" : "châzaq",
		"pron" : "khaw-zak'",
		"derivation" : "a primitive root",
		"def" : "to fasten upon; hence, to seize, be strong (figuratively, courageous, causatively strengthen, cure, help, repair, fortify), obstinate; to bind, restrain, conquer",
		"kjv" : "aid, amend, [idiom] calker, catch, cleave, confirm, be constant, constrain, continue, be of good (take) courage(-ous, -ly), encourage (self), be established, fasten, force, fortify, make hard, harden, help, (lay) hold (fast), lean, maintain, play the man, mend, become (wax) mighty, prevail, be recovered, repair, retain, seize, be (wax) sore, strengthen (self), be stout, be (make, shew, wax) strong(-er), be sure, take (hold), be urgent, behave self valiantly, withstand"
	},
	"H2389" : {
		"lemma" : "חָזָק",
		"xlit" : "châzâq",
		"pron" : "khaw-zawk'",
		"derivation" : "from H2388 (חָזַק)",
		"def" : "strong (usu. in a bad sense, hard, bold, violent)",
		"kjv" : "harder, hottest, [phrase] impudent, loud, mighty, sore, stiff(-hearted), strong(-er)"
	},
	"H2390" : {
		"lemma" : "חָזֵק",
		"xlit" : "châzêq",
		"pron" : "khaw-zake'",
		"derivation" : "from H2388 (חָזַק)",
		"def" : "powerful",
		"kjv" : "[idiom] wax louder, stronger"
	},
	"H2391" : {
		"lemma" : "חֵזֶק",
		"xlit" : "chêzeq",
		"pron" : "khay'-zek",
		"derivation" : "from H2388 (חָזַק)",
		"def" : "help",
		"kjv" : "strength"
	},
	"H2392" : {
		"lemma" : "חֹזֶק",
		"xlit" : "chôzeq",
		"pron" : "kho'-zek",
		"derivation" : "from H2388 (חָזַק)",
		"def" : "power",
		"kjv" : "strength"
	},
	"H2393" : {
		"lemma" : "חֶזְקָה",
		"xlit" : "chezqâh",
		"pron" : "khez-kaw'",
		"derivation" : "feminine of H2391 (חֵזֶק)",
		"def" : "prevailing power",
		"kjv" : "strength(-en self), (was) strong"
	},
	"H2394" : {
		"lemma" : "חׇזְקָה",
		"xlit" : "chozqâh",
		"pron" : "khoz-kaw'",
		"derivation" : "feminine of H2392 (חֹזֶק)",
		"def" : "vehemence (usually in a bad sense)",
		"kjv" : "force, mightily, repair, sharply"
	},
	"H2395" : {
		"lemma" : "חִזְקִי",
		"xlit" : "Chizqîy",
		"pron" : "khiz-kee'",
		"derivation" : "from H2388 (חָזַק); strong",
		"def" : "Chizki, an Israelite",
		"kjv" : "Hezeki"
	},
	"H2396" : {
		"lemma" : "חִזְקִיָּה",
		"xlit" : "Chizqîyâh",
		"pron" : "khiz-kee-yaw'",
		"derivation" : "or חִזְקִיָּהוּ; also יְחִזְקִיָּה; or יְחִזְקִיָּהוּ; from H2388 (חָזַק) and H3050 (יָהּ); strengthened of Jah",
		"def" : "Chizkijah, a king of Judah, also the name of two other Israelites",
		"kjv" : "Hezekiah, Hizkiah, Hizkijah. Compare H3169 (יְחִזְקִיָּה)"
	},
	"H2397" : {
		"lemma" : "חָח",
		"xlit" : "châch",
		"pron" : "khawkh",
		"derivation" : "once (Ezekiel 29:4) חָחִי; from the same as H2336 (חוֹחַ)",
		"def" : "a ring for the nose (or lips)",
		"kjv" : "bracelet, chain, hook"
	},
	"H2398" : {
		"lemma" : "חָטָא",
		"xlit" : "châṭâʼ",
		"pron" : "khaw-taw'",
		"derivation" : "a primitive root",
		"def" : "properly, to miss; hence (figuratively and generally) to sin; by inference, to forfeit, lack, expiate, repent, (causatively) lead astray, condemn",
		"kjv" : "bear the blame, cleanse, commit (sin), by fault, harm he hath done, loss, miss, (make) offend(-er), offer for sin, purge, purify (self), make reconciliation, (cause, make) sin(-ful, -ness), trespass"
	},
	"H2399" : {
		"lemma" : "חֵטְא",
		"xlit" : "chêṭᵉʼ",
		"pron" : "khate",
		"derivation" : "from H2398 (חָטָא)",
		"def" : "a crime or its penalty",
		"kjv" : "fault, [idiom] grievously, offence, (punishment of) sin"
	},
	"H2400" : {
		"lemma" : "חַטָּא",
		"xlit" : "chaṭṭâʼ",
		"pron" : "khat-taw'",
		"derivation" : "intensively from H2398 (חָטָא)",
		"def" : "a criminal, or one accounted guilty",
		"kjv" : "offender, sinful, sinner"
	},
	"H2401" : {
		"lemma" : "חֲטָאָה",
		"xlit" : "chăṭâʼâh",
		"pron" : "khat-aw-aw'",
		"derivation" : "feminine of H2399 (חֵטְא)",
		"def" : "an offence, or a sacrifice forit",
		"kjv" : "sin (offering)"
	},
	"H2402" : {
		"lemma" : "חַטָּאָה",
		"xlit" : "chaṭṭâʼâh",
		"pron" : "khat-taw-aw'",
		"derivation" : "(Aramaic) corresponding to H2401 (חֲטָאָה)",
		"def" : "an offence, and the penalty or sacrifice forit",
		"kjv" : "sin (offering)"
	},
	"H2403" : {
		"lemma" : "חַטָּאָה",
		"xlit" : "chaṭṭâʼâh",
		"pron" : "khat-taw-aw'",
		"derivation" : "or חַטָּאת; from H2398 (חָטָא)",
		"def" : "an offence (sometimes habitual sinfulness), and its penalty, occasion, sacrifice, or expiation; also (concretely) an offender",
		"kjv" : "punishment (of sin), purifying(-fication for sin), sin(-ner, offering)"
	},
	"H2404" : {
		"lemma" : "חָטַב",
		"xlit" : "châṭab",
		"pron" : "khaw-tab'",
		"derivation" : "a primitive root",
		"def" : "to chop or carve wood",
		"kjv" : "cut down, hew(-er), polish"
	},
	"H2405" : {
		"lemma" : "חֲטֻבָה",
		"xlit" : "chăṭubâh",
		"pron" : "khat-oo-baw'",
		"derivation" : "feminine passive participle of H2404 (חָטַב)",
		"def" : "properly, a carving; hence, a tapestry (as figured)",
		"kjv" : "carved"
	},
	"H2406" : {
		"lemma" : "חִטָּה",
		"xlit" : "chiṭṭâh",
		"pron" : "khit-taw'",
		"derivation" : "of uncertain derivation",
		"def" : "wheat, whether the grain or the plant",
		"kjv" : "wheat(-en)"
	},
	"H2407" : {
		"lemma" : "חַטּוּשׁ",
		"xlit" : "Chaṭṭûwsh",
		"pron" : "khat-toosh'",
		"derivation" : "from an unused root of uncertain signification",
		"def" : "Chattush, the name of four or five Israelites",
		"kjv" : "Hattush"
	},
	"H2408" : {
		"lemma" : "חֲטִי",
		"xlit" : "chăṭîy",
		"pron" : "khat-ee'",
		"derivation" : "(Aramaic) from a root corresponding to H2398 (חָטָא)",
		"def" : "an offence",
		"kjv" : "sin"
	},
	"H2409" : {
		"lemma" : "חֲטָּיָא",
		"xlit" : "chăṭṭâyâʼ",
		"pron" : "khat-taw-yaw'",
		"derivation" : "(Aramaic) from the same as H2408 (חֲטִי)",
		"def" : "an expiation",
		"kjv" : "sin offering"
	},
	"H2410" : {
		"lemma" : "חֲטִיטָא",
		"xlit" : "Chăṭîyṭâʼ",
		"pron" : "khat-ee-taw'",
		"derivation" : "from an unused root apparently meaning to dig out; explorer",
		"def" : "Chatita, a temple porter",
		"kjv" : "Hatita"
	},
	"H2411" : {
		"lemma" : "חַטִּיל",
		"xlit" : "Chaṭṭîyl",
		"pron" : "khat-teel'",
		"derivation" : "from an unused root apparently meaning to wave; fluctuating",
		"def" : "Chattil, one of 'Solomon's servants'",
		"kjv" : "Hattil"
	},
	"H2412" : {
		"lemma" : "חֲטִיפָא",
		"xlit" : "Chăṭîyphâʼ",
		"pron" : "khat-ee-faw'",
		"derivation" : "from H2414 (חָטַף); robber",
		"def" : "Chatipha, one of the Nethinim",
		"kjv" : "Hatipha"
	},
	"H2413" : {
		"lemma" : "חָטַם",
		"xlit" : "châṭam",
		"pron" : "khaw-tam'",
		"derivation" : "a primitive root",
		"def" : "to stop",
		"kjv" : "refrain"
	},
	"H2414" : {
		"lemma" : "חָטַף",
		"xlit" : "châṭaph",
		"pron" : "khaw-taf'",
		"derivation" : "a primitive root",
		"def" : "to clutch; hence, to seize as aprisoner",
		"kjv" : "catch"
	},
	"H2415" : {
		"lemma" : "חֹטֵר",
		"xlit" : "chôṭêr",
		"pron" : "kho'-ter",
		"derivation" : "from an unused root of uncertain signification",
		"def" : "a twig",
		"kjv" : "rod"
	},
	"H2416" : {
		"lemma" : "חַי",
		"xlit" : "chay",
		"pron" : "khah'-ee",
		"derivation" : "from H2421 (חָיָה)",
		"def" : "alive; hence, raw (flesh); fresh (plant, water, year), strong; also (as noun, especially in the feminine singular and masculine plural) life (or living thing), whether literally or figuratively",
		"kjv" : "[phrase] age, alive, appetite, (wild) beast, company, congregation, life(-time), live(-ly), living (creature, thing), maintenance, [phrase] merry, multitude, [phrase] (be) old, quick, raw, running, springing, troop"
	},
	"H2417" : {
		"lemma" : "חַי",
		"xlit" : "chay",
		"pron" : "khah'-ee",
		"derivation" : "(Aramaic) from H2418 (חֲיָא)",
		"def" : "alive; also (as noun in plural) life",
		"kjv" : "life, that liveth, living"
	},
	"H2418" : {
		"lemma" : "חֲיָא",
		"xlit" : "chăyâʼ",
		"pron" : "khah-yaw'",
		"derivation" : "(Aramaic) or חֲיָה; (Aramaic), corresponding to H2421 (חָיָה)",
		"def" : "to live",
		"kjv" : "live, keep alive"
	},
	"H2419" : {
		"lemma" : "חִיאֵל",
		"xlit" : "Chîyʼêl",
		"pron" : "khee-ale'",
		"derivation" : "from H2416 (חַי) and H410 (אֵל); living of God",
		"def" : "Chiel, an Israelite",
		"kjv" : "Hiel"
	},
	"H2420" : {
		"lemma" : "חִידָה",
		"xlit" : "chîydâh",
		"pron" : "khee-daw'",
		"derivation" : "from H2330 (חוּד)",
		"def" : "a puzzle, hence, a trick, conundrum, sententious maxim",
		"kjv" : "dark saying (sentence, speech), hard question, proverb, riddle"
	},
	"H2421" : {
		"lemma" : "חָיָה",
		"xlit" : "châyâh",
		"pron" : "khaw-yaw'",
		"derivation" : "a primitive root (compare H2331 (חָוָה), H2421 (חָיָה))",
		"def" : "to live, whether literally or figuratively; causatively, to revive",
		"kjv" : "keep (leave, make) alive, [idiom] certainly, give (promise) life, (let, suffer to) live, nourish up, preserve (alive), quicken, recover, repair, restore (to life), revive, ([idiom] God) save (alive, life, lives), [idiom] surely, be whole"
	},
	"H2422" : {
		"lemma" : "חָיֶה",
		"xlit" : "châyeh",
		"pron" : "khaw-yeh'",
		"derivation" : "from H2421 (חָיָה)",
		"def" : "vigorous",
		"kjv" : "lively"
	},
	"H2423" : {
		"lemma" : "חֵיוָא",
		"xlit" : "chêyvâʼ",
		"pron" : "khay-vaw'",
		"derivation" : "(Aramaic) from H2418 (חֲיָא)",
		"def" : "an animal",
		"kjv" : "beast"
	},
	"H2424" : {
		"lemma" : "חַיּוּת",
		"xlit" : "chayûwth",
		"pron" : "khah-yooth'",
		"derivation" : "from H2421 (חָיָה)",
		"def" : "life",
		"kjv" : "[idiom] living"
	},
	"H2425" : {
		"lemma" : "חָיַי",
		"xlit" : "châyay",
		"pron" : "khaw-yah'-ee",
		"derivation" : "a primitive root (compare H2421 (חָיָה))",
		"def" : "to live; causatively to revive",
		"kjv" : "live, save life"
	},
	"H2426" : {
		"lemma" : "חֵיל",
		"xlit" : "chêyl",
		"pron" : "khale",
		"derivation" : "or (shortened) חֵל; a collateral form of H2428 (חַיִל)",
		"def" : "an army; also (by analogy,) an intrenchment",
		"kjv" : "army, bulwark, host, [phrase] poor, rampart, trench, wall"
	},
	"H2427" : {
		"lemma" : "חִיל",
		"xlit" : "chîyl",
		"pron" : "kheel",
		"derivation" : "and (feminine) חִילָה; from H2342 (חוּל)",
		"def" : "a throe (expectant of childbirth)",
		"kjv" : "pain, pang, sorrow"
	},
	"H2428" : {
		"lemma" : "חַיִל",
		"xlit" : "chayil",
		"pron" : "khah'-yil",
		"derivation" : "from H2342 (חוּל)",
		"def" : "probably a force, whether of men, means or other resources; an army, wealth, virtue, valor, strength",
		"kjv" : "able, activity, ([phrase]) army, band of men (soldiers), company, (great) forces, goods, host, might, power, riches, strength, strong, substance, train, ([phrase]) valiant(-ly), valour, virtuous(-ly), war, worthy(-ily)"
	},
	"H2429" : {
		"lemma" : "חַיִל",
		"xlit" : "chayil",
		"pron" : "khah'-yil",
		"derivation" : "(Aramaic) corresponding to H2428 (חַיִל)",
		"def" : "an army, or strength",
		"kjv" : "aloud, army, [idiom] most (mighty), power"
	},
	"H2430" : {
		"lemma" : "חֵילָה",
		"xlit" : "chêylâh",
		"pron" : "khay-law'",
		"derivation" : "feminine of H2428 (חַיִל)",
		"def" : "an intrenchment",
		"kjv" : "bulwark"
	},
	"H2431" : {
		"lemma" : "חֵילָם",
		"xlit" : "Chêylâm",
		"pron" : "khay-lawm'",
		"derivation" : "or חֵלָאםxlit Chêlʼâm corrected to Chêlâʼm; from H2428 (חַיִל); fortress",
		"def" : "Chelam, a place East of Palestine",
		"kjv" : "Helam"
	},
	"H2432" : {
		"lemma" : "חִילֵן",
		"xlit" : "Chîylên",
		"pron" : "khee-lane'",
		"derivation" : "from H2428 (חַיִל); fortress",
		"def" : "Chilen, a place in Palestine",
		"kjv" : "Hilen"
	},
	"H2433" : {
		"lemma" : "חִין",
		"xlit" : "chîyn",
		"pron" : "kheen",
		"derivation" : "another form for H2580 (חֵן)",
		"def" : "beauty",
		"kjv" : "comely"
	},
	"H2434" : {
		"lemma" : "חַיִץ",
		"xlit" : "chayits",
		"pron" : "khah'-yits",
		"derivation" : "another form for H2351 (חוּץ)",
		"def" : "a wall",
		"kjv" : "wall"
	},
	"H2435" : {
		"lemma" : "חִיצוֹן",
		"xlit" : "chîytsôwn",
		"pron" : "khee-tsone'",
		"derivation" : "from H2434 (חַיִץ)",
		"def" : "properly, the (outer) wall side; hence, exterior; figuratively, secular (as opposed to sacred)",
		"kjv" : "outer, outward, utter, without"
	},
	"H2436" : {
		"lemma" : "חֵיק",
		"xlit" : "chêyq",
		"pron" : "khake",
		"derivation" : "or חֵק; and חוֹק; from an unused root, apparently meaning to inclose",
		"def" : "the bosom (literally or figuratively)",
		"kjv" : "bosom, bottom, lap, midst, within"
	},
	"H2437" : {
		"lemma" : "חִירָה",
		"xlit" : "Chîyrâh",
		"pron" : "khee-raw'",
		"derivation" : "from H2357 (חָוַר) in the sense of splendor",
		"def" : "Chirah, an Adullamite",
		"kjv" : "Hirah"
	},
	"H2438" : {
		"lemma" : "חִירָם",
		"xlit" : "Chîyrâm",
		"pron" : "khee-rawm'",
		"derivation" : "or חִירוֹםlemma חִירֹם missing vav, corrected to חִירוֹם; another form of H2361 (חוּרָם)",
		"def" : "Chiram or Chirom, the name of two Tyrians",
		"kjv" : "Hiram, Huram"
	},
	"H2439" : {
		"lemma" : "חִישׁ",
		"xlit" : "chîysh",
		"pron" : "kheesh",
		"derivation" : "another form of H2363 (חוּשׁ)",
		"def" : "to hurry",
		"kjv" : "make haste"
	},
	"H2440" : {
		"lemma" : "חִישׁ",
		"xlit" : "chîysh",
		"pron" : "kheesh",
		"derivation" : "from H2439 (חִישׁ)",
		"def" : "properly, a hurry; hence (adverb) quickly",
		"kjv" : "soon"
	},
	"H2441" : {
		"lemma" : "חֵךְ",
		"xlit" : "chêk",
		"pron" : "khake",
		"derivation" : "probably from H2596 (חָנַךְ) in the sense of tasting",
		"def" : "properly, the palate or inside of the mouth; hence, the mouth itself (as the organ of speech, taste and kissing)",
		"kjv" : "(roof of the) mouth, taste"
	},
	"H2442" : {
		"lemma" : "חָכָה",
		"xlit" : "châkâh",
		"pron" : "khaw-kaw'",
		"derivation" : "a primitive root (apparently akin to H2707 (חָקָה) through the idea of piercing)",
		"def" : "properly, to adhere to; hence, to await",
		"kjv" : "long, tarry, wait"
	},
	"H2443" : {
		"lemma" : "חַכָּה",
		"xlit" : "chakkâh",
		"pron" : "khak-kaw'",
		"derivation" : "probably from H2442 (חָכָה)",
		"def" : "a hook (as adhering)",
		"kjv" : "angle, hook"
	},
	"H2444" : {
		"lemma" : "חֲכִילָה",
		"xlit" : "Chăkîylâh",
		"pron" : "khak-ee-law'",
		"derivation" : "from the same as H2447 (חַכְלִיל); dark",
		"def" : "Chakilah, a hill in Palestine",
		"kjv" : "Hachilah"
	},
	"H2445" : {
		"lemma" : "חַכִּים",
		"xlit" : "chakkîym",
		"pron" : "khak-keem'",
		"derivation" : "(Aramaic) from a root corresponding to H2449 (חָכַם)",
		"def" : "wise, i.e. a Magian",
		"kjv" : "wise"
	},
	"H2446" : {
		"lemma" : "חֲכַלְיָה",
		"xlit" : "Chăkalyâh",
		"pron" : "khak-al-yaw'",
		"derivation" : "from the base of H2447 (חַכְלִיל) and H3050 (יָהּ); darkness of Jah",
		"def" : "Chakaljah, an Israelite",
		"kjv" : "Hachaliah"
	},
	"H2447" : {
		"lemma" : "חַכְלִיל",
		"xlit" : "chaklîyl",
		"pron" : "khak-leel'",
		"derivation" : "by reduplication from an unused root apparently meaning to be dark",
		"def" : "darkly flashing (only of the eyes); in a good sense, brilliant (as stimulated by wine)",
		"kjv" : "red"
	},
	"H2448" : {
		"lemma" : "חַכְלִלוּת",
		"xlit" : "chaklilûwth",
		"pron" : "khak-lee-looth'",
		"derivation" : "from H2447 (חַכְלִיל)",
		"def" : "flash (of the eyes); in a bad sense, blearedness",
		"kjv" : "redness"
	},
	"H2449" : {
		"lemma" : "חָכַם",
		"xlit" : "châkam",
		"pron" : "khaw-kam'",
		"derivation" : "a primitive root",
		"def" : "to be wise (in mind, word or act)",
		"kjv" : "[idiom] exceeding, teach wisdom, be (make self, shew self) wise, deal (never so) wisely, make wiser"
	},
	"H2450" : {
		"lemma" : "חָכָם",
		"xlit" : "châkâm",
		"pron" : "khaw-kawm'",
		"derivation" : "from H2449 (חָכַם)",
		"def" : "wise, (i.e. intelligent, skilful or artful)",
		"kjv" : "cunning (man), subtil, (un-), wise((hearted), man)"
	},
	"H2451" : {
		"lemma" : "חׇכְמָה",
		"xlit" : "chokmâh",
		"pron" : "khok-maw'",
		"derivation" : "from H2449 (חָכַם)",
		"def" : "wisdom (in a good sense)",
		"kjv" : "skilful, wisdom, wisely, wit"
	},
	"H2452" : {
		"lemma" : "חׇכְמָה",
		"xlit" : "chokmâh",
		"pron" : "khok-maw'",
		"derivation" : "(Aramaic) corresponding to H2451 (חׇכְמָה)",
		"def" : "wisdom",
		"kjv" : "wisdom"
	},
	"H2453" : {
		"lemma" : "חַכְמוֹנִי",
		"xlit" : "Chakmôwnîy",
		"pron" : "khak-mo-nee'",
		"derivation" : "from H2449 (חָכַם); skilful",
		"def" : "Chakmoni, an Israelite",
		"kjv" : "Hachmoni, Hachmonite"
	},
	"H2454" : {
		"lemma" : "חׇכְמוֹת",
		"xlit" : "chokmôwth",
		"pron" : "khok-moth'",
		"derivation" : "or חַכְמוֹת; collateral forms of H2451 (חׇכְמָה)",
		"def" : "wisdom",
		"kjv" : "wisdom, every wise (woman)"
	},
	"H2455" : {
		"lemma" : "חֹל",
		"xlit" : "chôl",
		"pron" : "khole",
		"derivation" : "from H2490 (חָלַל)",
		"def" : "properly, exposed; hence, profane",
		"kjv" : "common, profane (place), unholy"
	},
	"H2456" : {
		"lemma" : "חָלָא",
		"xlit" : "châlâʼ",
		"pron" : "khaw-law'",
		"derivation" : "a primitive root (compare H2470 (חָלָה))",
		"def" : "to be sick",
		"kjv" : "be diseased"
	},
	"H2457" : {
		"lemma" : "חֶלְאָה",
		"xlit" : "chelʼâh",
		"pron" : "khel-aw'",
		"derivation" : "from H2456 (חָלָא)",
		"def" : "properly, disease; hence, rust",
		"kjv" : "scum"
	},
	"H2458" : {
		"lemma" : "חֶלְאָה",
		"xlit" : "Chelʼâh",
		"pron" : "khel-aw'",
		"derivation" : "the same as H2457 (חֶלְאָה)",
		"def" : "Chelah, an Israelitess",
		"kjv" : "Helah"
	},
	"H2459" : {
		"lemma" : "חֶלֶב",
		"xlit" : "cheleb",
		"pron" : "kheh'-leb",
		"derivation" : "or חֵלֶב; from an unused root meaning to be fat",
		"def" : "fat, whether literally or figuratively; hence, the richest or choice part",
		"kjv" : "[idiom] best, fat(-ness), [idiom] finest, grease, marrow"
	},
	"H2460" : {
		"lemma" : "חֵלֶב",
		"xlit" : "Chêleb",
		"pron" : "khay'-leb",
		"derivation" : "the same as H2459 (חֶלֶב); fatness",
		"def" : "Cheleb, an Israelite",
		"kjv" : "Heleb"
	},
	"H2461" : {
		"lemma" : "חָלָב",
		"xlit" : "châlâb",
		"pron" : "khaw-lawb'",
		"derivation" : "from the same as H2459 (חֶלֶב)",
		"def" : "milk (as the richness of kine)",
		"kjv" : "[phrase] cheese, milk, sucking"
	},
	"H2462" : {
		"lemma" : "חֶלְבָּה",
		"xlit" : "Chelbâh",
		"pron" : "khel-baw'",
		"derivation" : "feminine of H2459 (חֶלֶב); fertility",
		"def" : "Chelbah, a place in Palestine",
		"kjv" : "Helbah"
	},
	"H2463" : {
		"lemma" : "חֶלְבּוֹן",
		"xlit" : "Chelbôwn",
		"pron" : "khel-bone'",
		"derivation" : "from H2459 (חֶלֶב); fruitful",
		"def" : "Chelbon, a place in Syria",
		"kjv" : "Helbah"
	},
	"H2464" : {
		"lemma" : "חֶלְבְּנָה",
		"xlit" : "chelbᵉnâh",
		"pron" : "khel-ben-aw'",
		"derivation" : "from H2459 (חֶלֶב)",
		"def" : "galbanam, an odorous gum (as if fatty)",
		"kjv" : "galbanum"
	},
	"H2465" : {
		"lemma" : "חֶלֶד",
		"xlit" : "cheled",
		"pron" : "kheh'-led",
		"derivation" : "from an unused root apparently meaning to glide swiftly",
		"def" : "life (as a fleeting portion of time); hence, the world (as transient)",
		"kjv" : "age, short time, world"
	},
	"H2466" : {
		"lemma" : "חֵלֶד",
		"xlit" : "chêled",
		"pron" : "khay'-led",
		"derivation" : "the same as H2465 (חֶלֶד)",
		"def" : "Cheled, an Israelite",
		"kjv" : "Heled"
	},
	"H2467" : {
		"lemma" : "חֹלֶד",
		"xlit" : "chôled",
		"pron" : "kho'-led",
		"derivation" : "from the same as H2465 (חֶלֶד)",
		"def" : "a weasel (from its gliding motion)",
		"kjv" : "weasel"
	},
	"H2468" : {
		"lemma" : "חֻלְדָּה",
		"xlit" : "Chuldâh",
		"pron" : "khool-daw'",
		"derivation" : "feminine of H2467 (חֹלֶד)",
		"def" : "Chuldah, an Israelitess",
		"kjv" : "Huldah"
	},
	"H2469" : {
		"lemma" : "חֶלְדַּי",
		"xlit" : "Chelday",
		"pron" : "khel-dah'-ee",
		"derivation" : "from H2466 (חֵלֶד); worldliness",
		"def" : "Cheldai, the name of two Israelites",
		"kjv" : "Heldai"
	},
	"H2470" : {
		"lemma" : "חָלָה",
		"xlit" : "châlâh",
		"pron" : "khaw-law'",
		"derivation" : "a primitive root (compare H2342 (חוּל), H2470 (חָלָה), H2490 (חָלַל))",
		"def" : "properly, to be rubbed or worn; hence (figuratively) to be weak, sick, afflicted; or (causatively) to grieve, make sick; also to stroke (in flattering), entreat",
		"kjv" : "beseech, (be) diseased, (put to) grief, be grieved, (be) grievous, infirmity, intreat, lay to, put to pain, [idiom] pray, make prayer, be (fall, make) sick, sore, be sorry, make suit ([idiom] supplication), woman in travail, be (become) weak, be wounded"
	},
	"H2471" : {
		"lemma" : "חַלָּה",
		"xlit" : "challâh",
		"pron" : "khal-law'",
		"derivation" : "from H2490 (חָלַל)",
		"def" : "a cake (as usually punctured)",
		"kjv" : "cake"
	},
	"H2472" : {
		"lemma" : "חֲלוֹם",
		"xlit" : "chălôwm",
		"pron" : "khal-ome'",
		"derivation" : "or (shortened) חֲלֹם; from H2492 (חָלַם)",
		"def" : "a dream",
		"kjv" : "dream(-er)"
	},
	"H2473" : {
		"lemma" : "חֹלוֹן",
		"xlit" : "Chôlôwn",
		"pron" : "kho-lone'",
		"derivation" : "or (shortened) חֹלֹן; probably from H2344 (חוֹל); sandy",
		"def" : "Cholon, the name of two places in Palestine",
		"kjv" : "Holon"
	},
	"H2474" : {
		"lemma" : "חַלּוֹן",
		"xlit" : "challôwn",
		"pron" : "khal-lone'",
		"def" : "a window (as perforated)",
		"kjv" : "window"
	},
	"H2475" : {
		"lemma" : "חֲלוֹף",
		"xlit" : "chălôwph",
		"pron" : "khal-ofe'",
		"derivation" : "from H2498 (חָלַף)",
		"def" : "properly, surviving; by implication (collectively) orphans",
		"kjv" : "[idiom] destruction"
	},
	"H2476" : {
		"lemma" : "חֲלוּשָׁה",
		"xlit" : "chălûwshâh",
		"pron" : "khal-oo-shaw'",
		"derivation" : "feminine passive participle of H2522 (חָלַשׁ)",
		"def" : "defeat",
		"kjv" : "being overcome"
	},
	"H2477" : {
		"lemma" : "חֲלַח",
		"xlit" : "Chălach",
		"pron" : "khal-akh'",
		"derivation" : "probably of foreign origin",
		"def" : "Chalach, a region of Assyria",
		"kjv" : "Halah"
	},
	"H2478" : {
		"lemma" : "חַלְחוּל",
		"xlit" : "Chalchûwl",
		"pron" : "khal-khool'",
		"derivation" : "by reduplication from H2342 (חוּל); contorted",
		"def" : "Chalchul, a place in Palestine",
		"kjv" : "Halhul"
	},
	"H2479" : {
		"lemma" : "חַלְחָלָה",
		"xlit" : "chalchâlâh",
		"pron" : "khal-khaw-law'",
		"derivation" : "feminine from the same as H2478 (חַלְחוּל)",
		"def" : "writhing (in childbirth); by implication, terror",
		"kjv" : "(great, much) pain"
	},
	"H2480" : {
		"lemma" : "חָלַט",
		"xlit" : "châlaṭ",
		"pron" : "khaw-lat'",
		"derivation" : "a primitive root",
		"def" : "to snatch at",
		"kjv" : "catch"
	},
	"H2481" : {
		"lemma" : "חֲלִי",
		"xlit" : "chălîy",
		"pron" : "khal-ee'",
		"derivation" : "from H2470 (חָלָה)",
		"def" : "a trinket (as polished)",
		"kjv" : "jewel, ornament"
	},
	"H2482" : {
		"lemma" : "חֲלִי",
		"xlit" : "Chălîy",
		"pron" : "khal-ee'",
		"derivation" : "the same as H2481 (חֲלִי)",
		"def" : "Chali, a place in Palestine",
		"kjv" : "Hali"
	},
	"H2483" : {
		"lemma" : "חֳלִי",
		"xlit" : "chŏlîy",
		"pron" : "khol-ee'",
		"derivation" : "from H2470 (חָלָה)",
		"def" : "malady, anxiety, calamity",
		"kjv" : "disease, grief, (is) sick(-ness)"
	},
	"H2484" : {
		"lemma" : "חֶלְיָה",
		"xlit" : "chelyâh",
		"pron" : "khel-yaw'",
		"derivation" : "feminine of H2481 (חֲלִי)",
		"def" : "a trinket",
		"kjv" : "jewel"
	},
	"H2485" : {
		"lemma" : "חָלִיל",
		"xlit" : "châlîyl",
		"pron" : "khaw-leel'",
		"derivation" : "from H2490 (חָלַל)",
		"def" : "a flute (as perforated)",
		"kjv" : "pipe"
	},
	"H2486" : {
		"lemma" : "חָלִילָה",
		"xlit" : "châlîylâh",
		"pron" : "khaw-lee'-law",
		"derivation" : "or חָלִלָה; a directive from H2490 (חָלַל)",
		"def" : "literal fora profaned thing; used (interj.) far be it!",
		"kjv" : "be far, ([idiom] God) forbid"
	},
	"H2487" : {
		"lemma" : "חֲלִיפָה",
		"xlit" : "chălîyphâh",
		"pron" : "khal-ee-faw'",
		"derivation" : "from H2498 (חָלַף)",
		"def" : "alternation",
		"kjv" : "change, course"
	},
	"H2488" : {
		"lemma" : "חֲלִיצָה",
		"xlit" : "chălîytsâh",
		"pron" : "khal-ee-tsaw'",
		"derivation" : "from H2503 (חֶלֶץ)",
		"def" : "spoil",
		"kjv" : "armour"
	},
	"H2489" : {
		"lemma" : "חֵלְכָא",
		"xlit" : "chêlᵉkâʼ",
		"pron" : "khay-lek-aw'",
		"derivation" : "or חֵלְכָה; apparently from an unused root probably meaning to be dark or (figuratively) unhappy",
		"def" : "a wretch, i.e. unfortunate",
		"kjv" : "poor"
	},
	"H2490" : {
		"lemma" : "חָלַל",
		"xlit" : "châlal",
		"pron" : "khaw-lal'",
		"derivation" : "a primitive root (compare H2470 (חָלָה)); also  denominative (from H2485 (חָלִיל))",
		"def" : "properly, to bore, i.e. (by implication) to wound, to dissolve; figuratively, to profane (a person, place or thing), to break (one's word), to begin (as if by an 'opening wedge'); to play (the flute)",
		"kjv" : "begin ([idiom] men began), defile, [idiom] break, defile, [idiom] eat (as common things), [idiom] first, [idiom] gather the grape thereof, [idiom] take inheritance, pipe, player on instruments, pollute, (cast as) profane (self), prostitute, slay (slain), sorrow, stain, wound"
	},
	"H2491" : {
		"lemma" : "חָלָל",
		"xlit" : "châlâl",
		"pron" : "khaw-lawl'",
		"derivation" : "from H2490 (חָלַל)",
		"def" : "pierced (especially to death); figuratively, polluted",
		"kjv" : "kill, profane, slain (man), [idiom] slew, (deadly) wounded"
	},
	"H2492" : {
		"lemma" : "חָלַם",
		"xlit" : "châlam",
		"pron" : "khaw-lam'",
		"derivation" : "a primitive root",
		"def" : "properly, to bind firmly, i.e. (by implication) to be (causatively to make) plump; also (through the figurative sense of dumbness) to dream",
		"kjv" : "(cause to) dream(-er), be in good liking, recover"
	},
	"H2493" : {
		"lemma" : "חֵלֶם",
		"xlit" : "chêlem",
		"pron" : "khay'-lem",
		"derivation" : "(Aramaic) from a root corresponding to H2492 (חָלַם)",
		"def" : "a dream",
		"kjv" : "dream"
	},
	"H2494" : {
		"lemma" : "חֵלֶם",
		"xlit" : "Chêlem",
		"pron" : "khay'lem",
		"derivation" : "from H2492 (חָלַם); a dream",
		"def" : "Chelem, an Israelite",
		"kjv" : "Helem. Compare H2469 (חֶלְדַּי)"
	},
	"H2495" : {
		"lemma" : "חַלָּמוּת",
		"xlit" : "challâmûwth",
		"pron" : "khal-law-mooth'",
		"derivation" : "from H2492 (חָלַם) (in the sense of insipidity)",
		"def" : "probably purslain",
		"kjv" : "egg"
	},
	"H2496" : {
		"lemma" : "חַלָּמִישׁ",
		"xlit" : "challâmîysh",
		"pron" : "klal-law-meesh'",
		"derivation" : "probably from H2492 (חָלַם) (in the sense of hardness)",
		"def" : "flint",
		"kjv" : "flint(-y), rock"
	},
	"H2497" : {
		"lemma" : "חֵלֹן",
		"xlit" : "Chêlôn",
		"pron" : "khay-lone'",
		"derivation" : "from H2428 (חַיִל); strong",
		"def" : "Chelon, an Israelite",
		"kjv" : "Helon"
	},
	"H2498" : {
		"lemma" : "חָלַף",
		"xlit" : "châlaph",
		"pron" : "khaw-laf'",
		"derivation" : "a primitive root",
		"def" : "properly, to slide by, i.e. (by implication) to hasten away, pass on, spring up, pierce or change",
		"kjv" : "abolish, alter, change, cut off, go on forward, grow up, be over, pass (away, on, through), renew, sprout, strike through"
	},
	"H2499" : {
		"lemma" : "חֲלַף",
		"xlit" : "chălaph",
		"pron" : "khal-af'",
		"derivation" : "(Aramaic) corresponding to H2498 (חָלַף)",
		"def" : "to pass on (of time)",
		"kjv" : "pass"
	},
	"H2500" : {
		"lemma" : "חֵלֶף",
		"xlit" : "chêleph",
		"pron" : "klay'-lef",
		"derivation" : "from H2498 (חָלַף)",
		"def" : "properly, exchange; hence (as preposition) instead of",
		"kjv" : "[idiom] for"
	},
	"H2501" : {
		"lemma" : "חֶלֶף",
		"xlit" : "Cheleph",
		"pron" : "kheh'lef",
		"derivation" : "the same as H2500 (חֵלֶף); change",
		"def" : "Cheleph, a place in Palestine",
		"kjv" : "Heleph"
	},
	"H2502" : {
		"lemma" : "חָלַץ",
		"xlit" : "châlats",
		"pron" : "khaw-lats'",
		"derivation" : "a primitive root",
		"def" : "to pull off; hence (intensively) to strip, (reflex.) to depart; by implication, to deliver, equip (for fight); present, strengthen",
		"kjv" : "arm (self), (go, ready) armed ([idiom] man, soldier), deliver, draw out, make fat, loose, (ready) prepared, put off, take away, withdraw self"
	},
	"H2503" : {
		"lemma" : "חֶלֶץ",
		"xlit" : "Chelets",
		"pron" : "kheh'-lets",
		"derivation" : "or חֵלֶץ; from H2502 (חָלַץ); perhaps, strength",
		"def" : "Chelets, the name of two Israelites",
		"kjv" : "Helez"
	},
	"H2504" : {
		"lemma" : "חָלָץ",
		"xlit" : "châlâts",
		"pron" : "khaw-lawts'",
		"derivation" : "from H2502 (חָלַץ) (in the sense of strength); only in the dual",
		"def" : "the loins (as the seat of vigor)",
		"kjv" : "loins, reins"
	},
	"H2505" : {
		"lemma" : "חָלַק",
		"xlit" : "châlaq",
		"pron" : "khaw-lak'",
		"derivation" : "a primitive root",
		"def" : "to be smooth (figuratively); by implication (as smooth stones were used for lots) to apportion or separate",
		"kjv" : "deal, distribute, divide, flatter, give, (have, im-) part(-ner), take away a portion, receive, separate self, (be) smooth(-er)"
	},
	"H2506" : {
		"lemma" : "חֵלֶק",
		"xlit" : "chêleq",
		"pron" : "khay'lek",
		"derivation" : "from H2505 (חָלַק)",
		"def" : "properly, smoothness (of the tongue); also an allotment",
		"kjv" : "flattery, inheritance, part, [idiom] partake, portion"
	},
	"H2507" : {
		"lemma" : "חֵלֶק",
		"xlit" : "Chêleq",
		"pron" : "khay'-lek",
		"derivation" : "the same as H2506 (חֵלֶק); portion",
		"def" : "Chelek, an Israelite",
		"kjv" : "Helek"
	},
	"H2508" : {
		"lemma" : "חֲלָק",
		"xlit" : "chălâq",
		"pron" : "khal-awk'",
		"derivation" : "(Aramaic) from a root corresponding to H2505 (חָלַק)",
		"def" : "a part",
		"kjv" : "portion"
	},
	"H2509" : {
		"lemma" : "חָלָק",
		"xlit" : "châlâq",
		"pron" : "khaw-lawk'",
		"derivation" : "from H2505 (חָלַק)",
		"def" : "smooth (especially of tongue)",
		"kjv" : "flattering, smooth"
	},
	"H2510" : {
		"lemma" : "חָלָק",
		"xlit" : "Châlâq",
		"pron" : "khaw-lawk'",
		"derivation" : "the same as H2509 (חָלָק); bare",
		"def" : "Chalak, a mountain of Idumaea",
		"kjv" : "Halak"
	},
	"H2511" : {
		"lemma" : "חַלָּק",
		"xlit" : "challâq",
		"pron" : "khal-lawk'",
		"derivation" : "from H2505 (חָלַק)",
		"def" : "smooth",
		"kjv" : "smooth"
	},
	"H2512" : {
		"lemma" : "חַלֻּק",
		"xlit" : "challuq",
		"pron" : "khal-look'",
		"derivation" : "from H2505 (חָלַק)",
		"def" : "smooth",
		"kjv" : "smooth"
	},
	"H2513" : {
		"lemma" : "חֶלְקָה",
		"xlit" : "chelqâh",
		"pron" : "khel-kaw'",
		"derivation" : "feminine of H2506 (חֵלֶק)",
		"def" : "properly, smoothness; figuratively, flattery; also an allotment",
		"kjv" : "field, flattering(-ry), ground, parcel, part, piece of land (ground), plat, portion, slippery place, smooth (thing)"
	},
	"H2514" : {
		"lemma" : "חֲלַקָּה",
		"xlit" : "chălaqqâh",
		"pron" : "khal-ak-kaw'",
		"derivation" : "feminine from H2505 (חָלַק)",
		"def" : "flattery",
		"kjv" : "flattery"
	},
	"H2515" : {
		"lemma" : "חֲלֻקָּה",
		"xlit" : "chăluqqâh",
		"pron" : "khal-ook-kaw'",
		"derivation" : "feminine of H2512 (חַלֻּק)",
		"def" : "a distribution",
		"kjv" : "division"
	},
	"H2516" : {
		"lemma" : "חֶלְקִי",
		"xlit" : "Chelqîy",
		"pron" : "khel-kee'",
		"derivation" : "patronymically from H2507 (חֵלֶק)",
		"def" : "a Chelkite or descendant of Chelek",
		"kjv" : "Helkites"
	},
	"H2517" : {
		"lemma" : "חֶלְקַי",
		"xlit" : "Chelqay",
		"pron" : "khel-kah'ee",
		"derivation" : "from H2505 (חָלַק); apportioned",
		"def" : "Chelkai, an Israelite",
		"kjv" : "Helkai"
	},
	"H2518" : {
		"lemma" : "חִלְקִיָּה",
		"xlit" : "Chilqîyâh",
		"pron" : "khil-kee-yaw'",
		"derivation" : "or חִלְקִיָּהוּ; from H2506 (חֵלֶק) and H3050 (יָהּ); portion of Jah",
		"def" : "Chilhijah, the name of eight Israelites",
		"kjv" : "Hillkiah"
	},
	"H2519" : {
		"lemma" : "חֲלַקְלַקָּה",
		"xlit" : "chălaqlaqqâh",
		"pron" : "khal-ak-lak-kaw'",
		"derivation" : "by reduplication from H2505 (חָלַק)",
		"def" : "properly, something very smooth; i.e. a treacherous spot; figuratively, blandishment",
		"kjv" : "flattery, slippery"
	},
	"H2520" : {
		"lemma" : "חֶלְקַת",
		"xlit" : "Chelqath",
		"pron" : "khel-kath'",
		"derivation" : "a form of H2513 (חֶלְקָה); smoothness",
		"def" : "Chelkath, a place in Palestine",
		"kjv" : "Helkath"
	},
	"H2521" : {
		"lemma" : "חֶלְקַת הַצֻּרִים",
		"xlit" : "Chelqath hats-Tsurîym",
		"pron" : "khel-kath' hatstsoo-reem'",
		"derivation" : "from H2520 (חֶלְקַת) and the plural of H6697 (צוּר), with the article inserted; smoothness of the rocks",
		"def" : "Chelkath Hats-tsurim, a place in Palestine",
		"kjv" : "Helkath-hazzurim"
	},
	"H2522" : {
		"lemma" : "חָלַשׁ",
		"xlit" : "châlash",
		"pron" : "khaw-lash'",
		"derivation" : "a primitive root",
		"def" : "to prostrate; by implication, to overthrow, decay",
		"kjv" : "discomfit, waste away, weaken"
	},
	"H2523" : {
		"lemma" : "חַלָּשׁ",
		"xlit" : "challâsh",
		"pron" : "khal-lawsh'",
		"derivation" : "from H2522 (חָלַשׁ)",
		"def" : "frail",
		"kjv" : "weak"
	},
	"H2524" : {
		"lemma" : "חָם",
		"xlit" : "châm",
		"pron" : "khawm",
		"derivation" : "from the same as H2346 (חוֹמָה)",
		"def" : "a father-in-law (as in affinity)",
		"kjv" : "father in law"
	},
	"H2525" : {
		"lemma" : "חָם",
		"xlit" : "châm",
		"pron" : "khawm",
		"derivation" : "from H2552 (חָמַם)",
		"def" : "hot",
		"kjv" : "hot, warm"
	},
	"H2526" : {
		"lemma" : "חָם",
		"xlit" : "Châm",
		"pron" : "khawm",
		"derivation" : "the same as H2525 (חָם); hot (from the tropical habitat)",
		"def" : "Cham, a son of Noah; also (as a patronymic) his descendants or their country",
		"kjv" : "Ham"
	},
	"H2527" : {
		"lemma" : "חֹם",
		"xlit" : "chôm",
		"pron" : "khome",
		"derivation" : "from H2552 (חָמַם)",
		"def" : "heat",
		"kjv" : "heat, to be hot (warm)"
	},
	"H2528" : {
		"lemma" : "חֱמָא",
		"xlit" : "chĕmâʼ",
		"pron" : "khem-aw'",
		"derivation" : "(Aramaic) or חֲמָה; (Aramaic), corresponding to H2534 (חֵמָה)",
		"def" : "anger",
		"kjv" : "fury"
	},
	"H2529" : {
		"lemma" : "חֶמְאָה",
		"xlit" : "chemʼâh",
		"pron" : "khem-aw'",
		"derivation" : "or (shortened) חֵמָה; from the same root as H2346 (חוֹמָה)",
		"def" : "curdled milk or cheese",
		"kjv" : "butter"
	},
	"H2530" : {
		"lemma" : "חָמַד",
		"xlit" : "châmad",
		"pron" : "khaw-mad'",
		"derivation" : "a primitive root",
		"def" : "to delight in",
		"kjv" : "beauty, greatly beloved, covet, delectable thing, ([idiom] great) delight, desire, goodly, lust, (be) pleasant (thing), precious (thing)"
	},
	"H2531" : {
		"lemma" : "חֶמֶד",
		"xlit" : "chemed",
		"pron" : "kheh'-med",
		"derivation" : "from H2530 (חָמַד)",
		"def" : "delight",
		"kjv" : "desirable, pleasant"
	},
	"H2532" : {
		"lemma" : "חֶמְדָּה",
		"xlit" : "chemdâh",
		"pron" : "khem-daw'",
		"derivation" : "feminine of H2531 (חֶמֶד)",
		"def" : "delight",
		"kjv" : "desire, goodly, pleasant, precious"
	},
	"H2533" : {
		"lemma" : "חֶמְדָּן",
		"xlit" : "Chemdân",
		"pron" : "khem-dawn'",
		"derivation" : "from H2531 (חֶמֶד); pleasant",
		"def" : "Chemdan, an Idumaean",
		"kjv" : "Hemdan"
	},
	"H2534" : {
		"lemma" : "חֵמָה",
		"xlit" : "chêmâh",
		"pron" : "khay-maw'",
		"derivation" : "or (Daniel 11:44) חֵמָא; from H3179 (יָחַם)",
		"def" : "heat; figuratively, anger, poison (from its fever)",
		"kjv" : "anger, bottles, hot displeasure, furious(-ly, -ry), heat, indignation, poison, rage, wrath(-ful). See H2529 (חֶמְאָה)"
	},
	"H2535" : {
		"lemma" : "חַמָּה",
		"xlit" : "chammâh",
		"pron" : "kham-maw'",
		"derivation" : "from H2525 (חָם)",
		"def" : "heat; by implication, the sun",
		"kjv" : "heat, sun"
	},
	"H2536" : {
		"lemma" : "חַמּוּאֵל",
		"xlit" : "Chammûwʼêl",
		"pron" : "kham-moo-ale'",
		"derivation" : "from H2535 (חַמָּה) and H410 (אֵל); anger of God",
		"def" : "Chammuel, an Israelite",
		"kjv" : "Hamuel"
	},
	"H2537" : {
		"lemma" : "חֲמוּטַל",
		"xlit" : "Chămûwṭal",
		"pron" : "kham-oo-tal'",
		"derivation" : "or חֲמִיטַל; from H2524 (חָם) and H2919 (טַל); father-in-law of dew",
		"def" : "Chamutal or Chamital, an Israelitess",
		"kjv" : "Hamutal"
	},
	"H2538" : {
		"lemma" : "חָמוּל",
		"xlit" : "Châmûwl",
		"pron" : "khaw-mool'",
		"derivation" : "from H2550 (חָמַל); pitied",
		"def" : "Chamul, an Israelite",
		"kjv" : "Hamul"
	},
	"H2539" : {
		"lemma" : "חָמוּלִי",
		"xlit" : "Châmûwlîy",
		"pron" : "khaw-moo-lee'",
		"derivation" : "patronymically from H2538 (חָמוּל)",
		"def" : "a Chamulite (collectively) or descendants of Chamul",
		"kjv" : "Hamulites"
	},
	"H2540" : {
		"lemma" : "חַמּוֹן",
		"xlit" : "Chammôwn",
		"pron" : "kham-mone'",
		"derivation" : "from H2552 (חָמַם); warm spring",
		"def" : "Chammon, the name of two places in Palestine",
		"kjv" : "Hammon"
	},
	"H2541" : {
		"lemma" : "חָמוֹץ",
		"xlit" : "châmôwts",
		"pron" : "khaw-motse'",
		"derivation" : "from H2556 (חָמֵץ)",
		"def" : "properly, violent; by implication, a robber",
		"kjv" : "oppressed"
	},
	"H2542" : {
		"lemma" : "חַמּוּק",
		"xlit" : "chammûwq",
		"pron" : "kham-mook'",
		"derivation" : "from H2559 (חָמַק)",
		"def" : "a wrapping, i.e. drawers",
		"kjv" : "joints"
	},
	"H2543" : {
		"lemma" : "חֲמוֹר",
		"xlit" : "chămôwr",
		"pron" : "kham-ore'",
		"derivation" : "or (shortened) חֲמֹר; from H2560 (חָמַר)",
		"def" : "a male ass (from its dun red)",
		"kjv" : "(he) ass"
	},
	"H2544" : {
		"lemma" : "חֲמוֹר",
		"xlit" : "Chămôwr",
		"pron" : "kham-ore'",
		"derivation" : "the same as H2543 (חֲמוֹר); donkey",
		"def" : "Chamor, a Canaanite",
		"kjv" : "Hamor"
	},
	"H2545" : {
		"lemma" : "חֲמוֹת",
		"xlit" : "chămôwth",
		"pron" : "kham-oth'",
		"derivation" : "or (shortened) חֲמֹת; feminine of H2524 (חָם)",
		"def" : "a mother-in-law",
		"kjv" : "mother in law"
	},
	"H2546" : {
		"lemma" : "חֹמֶט",
		"xlit" : "chômeṭ",
		"pron" : "kho'met",
		"derivation" : "from an unused root probably meaning, to lie low",
		"def" : "a lizard (as creeping)",
		"kjv" : "snail"
	},
	"H2547" : {
		"lemma" : "חֻמְטָה",
		"xlit" : "Chumṭâh",
		"pron" : "khoom-taw'",
		"derivation" : "feminine of H2546 (חֹמֶט); low",
		"def" : "Chumtah, a place in Palestine",
		"kjv" : "Humtah"
	},
	"H2548" : {
		"lemma" : "חָמִיץ",
		"xlit" : "châmîyts",
		"pron" : "khaw-meets'",
		"derivation" : "from H2556 (חָמֵץ)",
		"def" : "seasoned, i.e. salt provender",
		"kjv" : "clean"
	},
	"H2549" : {
		"lemma" : "חֲמִישִׁי",
		"xlit" : "chămîyshîy",
		"pron" : "kham-ee-shee'",
		"derivation" : "or חֲמִשִּׁי; ordinal from H2568 (חָמֵשׁ)",
		"def" : "fifth; also a fifth",
		"kjv" : "fifth (part)"
	},
	"H2550" : {
		"lemma" : "חָמַל",
		"xlit" : "châmal",
		"pron" : "khaw-mal'",
		"derivation" : "a primitive root",
		"def" : "to commiserate; by implication, to spare",
		"kjv" : "have compassion, (have) pity, spare"
	},
	"H2551" : {
		"lemma" : "חֶמְלָה",
		"xlit" : "chemlâh",
		"pron" : "khem-law'",
		"derivation" : "from H2550 (חָמַל)",
		"def" : "commiseration",
		"kjv" : "merciful, pity"
	},
	"H2552" : {
		"lemma" : "חָמַם",
		"xlit" : "châmam",
		"pron" : "khaw-mam'",
		"derivation" : "a primitive root",
		"def" : "to be hot (literally or figuratively)",
		"kjv" : "enflame self, get (have) heat, be (wax) hot, (be, wax) warm (self, at)"
	},
	"H2553" : {
		"lemma" : "חַמָּן",
		"xlit" : "chammân",
		"pron" : "kham-mawn'",
		"derivation" : "from H2535 (חַמָּה)",
		"def" : "a sun-pillar",
		"kjv" : "idol, image"
	},
	"H2554" : {
		"lemma" : "חָמַס",
		"xlit" : "châmaç",
		"pron" : "khaw-mas'",
		"derivation" : "a primitive root",
		"def" : "to be violent; by implication, to maltreat",
		"kjv" : "make bare, shake off, violate, do violence, take away violently, wrong, imagine wrongfully"
	},
	"H2555" : {
		"lemma" : "חָמָס",
		"xlit" : "châmâç",
		"pron" : "khaw-mawce'",
		"derivation" : "from H2554 (חָמַס)",
		"def" : "violence; by implication, wrong; by meton. unjust gain",
		"kjv" : "cruel(-ty), damage, false, injustice, [idiom] oppressor, unrighteous, violence (against, done), violent (dealing), wrong"
	},
	"H2556" : {
		"lemma" : "חָמֵץ",
		"xlit" : "châmêts",
		"pron" : "khaw-mates'",
		"derivation" : "a primitive root",
		"def" : "to be pungent; i.e. in taste (sour, i.e. literally fermented, or figuratively, harsh), in color (dazzling)",
		"kjv" : "cruel (man), dyed, be grieved, leavened"
	},
	"H2557" : {
		"lemma" : "חָמֵץ",
		"xlit" : "châmêts",
		"pron" : "khaw-mates'",
		"derivation" : "from H2556 (חָמֵץ)",
		"def" : "ferment, (figuratively) extortion",
		"kjv" : "leaven, leavened (bread)"
	},
	"H2558" : {
		"lemma" : "חֹמֶץ",
		"xlit" : "chômets",
		"pron" : "kho'-mets",
		"derivation" : "from H2556 (חָמֵץ)",
		"def" : "vinegar",
		"kjv" : "vinegar"
	},
	"H2559" : {
		"lemma" : "חָמַק",
		"xlit" : "châmaq",
		"pron" : "khaw-mak'",
		"derivation" : "a primitive root",
		"def" : "properly, to wrap; hence, to depart (i.e. turn about)",
		"kjv" : "go about, withdraw self"
	},
	"H2560" : {
		"lemma" : "חָמַר",
		"xlit" : "châmar",
		"pron" : "khaw-mar'",
		"derivation" : "a primitive root; also  as denominative (from H2564 (חֵמָר))",
		"def" : "properly, to boil up; hence, to ferment (with scum); to glow (with redness); to smear with pitch",
		"kjv" : "daub, befoul, be red, trouble"
	},
	"H2561" : {
		"lemma" : "חֶמֶר",
		"xlit" : "chemer",
		"pron" : "kheh'-mer",
		"derivation" : "from H2560 (חָמַר)",
		"def" : "wine (as fermenting)",
		"kjv" : "[idiom] pure, red wine"
	},
	"H2562" : {
		"lemma" : "חֲמַר",
		"xlit" : "chămar",
		"pron" : "kham-ar'",
		"derivation" : "(Aramaic) corresponding to H2561 (חֶמֶר)",
		"def" : "wine",
		"kjv" : "wine"
	},
	"H2563" : {
		"lemma" : "חֹמֶר",
		"xlit" : "chômer",
		"pron" : "kho'mer",
		"derivation" : "from H2560 (חָמַר)",
		"def" : "properly, a bubbling up, i.e. of water, a wave; of earth, mire or clay (cement); also a heap; hence, a chomer or dry measure",
		"kjv" : "clay, heap, homer, mire, motion"
	},
	"H2564" : {
		"lemma" : "חֵמָר",
		"xlit" : "chêmâr",
		"pron" : "khay-mawr'",
		"derivation" : "from H2560 (חָמַר)",
		"def" : "bitumen (as rising to the surface)",
		"kjv" : "slime(-pit)"
	},
	"H2565" : {
		"lemma" : "חֲמֹרָה",
		"xlit" : "chămôrâh",
		"pron" : "kham-o-raw'",
		"derivation" : "from H2560 (חָמַר) (compare H2563 (חֹמֶר))",
		"def" : "a heap",
		"kjv" : "heap"
	},
	"H2566" : {
		"lemma" : "חַמְרָן",
		"xlit" : "Chamrân",
		"pron" : "kham-rawn'",
		"derivation" : "from H2560 (חָמַר); red",
		"def" : "Chamran, an Idumaean",
		"kjv" : "Amran"
	},
	"H2567" : {
		"lemma" : "חָמַשׁ",
		"xlit" : "châmash",
		"pron" : "khaw-mash'",
		"derivation" : "a denominative from H2568 (חָמֵשׁ)",
		"def" : "to tax a fifth",
		"kjv" : "take up the fifth participle"
	},
	"H2568" : {
		"lemma" : "חָמֵשׁ",
		"xlit" : "châmêsh",
		"pron" : "khaw-maysh'",
		"derivation" : "masculine חֲמִשָּׁה; a primitive numeral",
		"def" : "five",
		"kjv" : "fif(-teen), fifth, five ([idiom] apiece)"
	},
	"H2569" : {
		"lemma" : "חֹמֶשׁ",
		"xlit" : "chômesh",
		"pron" : "kho'-mesh",
		"derivation" : "from H2567 (חָמַשׁ)",
		"def" : "a fifth tax",
		"kjv" : "fifth participle"
	},
	"H2570" : {
		"lemma" : "חֹמֶשׁ",
		"xlit" : "chômesh",
		"pron" : "kho'-mesh",
		"derivation" : "from an unused root probably meaning, to be stout",
		"def" : "the abdomen (as obese)",
		"kjv" : "fifth (rib)"
	},
	"H2571" : {
		"lemma" : "חָמֻשׁ",
		"xlit" : "châmush",
		"pron" : "khaw-moosh'",
		"derivation" : "passive participle of the same as H2570 (חֹמֶשׁ)",
		"def" : "staunch, i.e. able-bodied soldiers",
		"kjv" : "armed (men), harnessed"
	},
	"H2572" : {
		"lemma" : "חֲמִשִּׁים",
		"xlit" : "chămishshîym",
		"pron" : "kham-ish-sheem'",
		"derivation" : "multiple of H2568 (חָמֵשׁ)",
		"def" : "fifty",
		"kjv" : "fifty"
	},
	"H2573" : {
		"lemma" : "חֵמֶת",
		"xlit" : "chêmeth",
		"pron" : "klay'-meth",
		"derivation" : "from the same as H2346 (חוֹמָה)",
		"def" : "a skin bottle (as tied up)",
		"kjv" : "bottle"
	},
	"H2574" : {
		"lemma" : "חֲמָת",
		"xlit" : "Chămâth",
		"pron" : "kham-awth'",
		"derivation" : "from the same as H2346 (חוֹמָה); walled",
		"def" : "Chamath, a place in Syria",
		"kjv" : "Hamath, Hemath"
	},
	"H2575" : {
		"lemma" : "חַמַּת",
		"xlit" : "Chammath",
		"pron" : "klam-math'",
		"derivation" : "a variation for the first part of H2576 (חַמֹּת דֹּאר); hot springs",
		"def" : "Chammath, a place in Palestine",
		"kjv" : "Hammath"
	},
	"H2576" : {
		"lemma" : "חַמֹּת דֹּאר",
		"xlit" : "Chammôth Dôʼr",
		"pron" : "kham-moth' dore",
		"derivation" : "from the plural of H2535 (חַמָּה) and H1756 (דּוֹר); hot springs of Dor",
		"def" : "Chammath-Dor, a place in Palestine",
		"kjv" : "Hamath-Dor"
	},
	"H2577" : {
		"lemma" : "חֲמָתִי",
		"xlit" : "Chămâthîy",
		"pron" : "kham-aw-thee'",
		"derivation" : "patrial from H2574 (חֲמָת)",
		"def" : "a Chamathite or native of Chamath",
		"kjv" : "Hamathite"
	},
	"H2578" : {
		"lemma" : "חֲמַת צוֹבָה",
		"xlit" : "Chămath Tsôwbâh",
		"pron" : "kham-ath' tso-baw'",
		"derivation" : "from H2574 (חֲמָת) and H6678 (צוֹבָא); Chamath of Tsobah; probably the same as H2574 (חֲמָת)",
		"def" : "Chamath-Tsobah",
		"kjv" : "Hamath-Zobah"
	},
	"H2579" : {
		"lemma" : "חֲמַת רַבָּה",
		"xlit" : "Chămath Rabbâh",
		"pron" : "kham-ath' rab-baw'",
		"derivation" : "from H2574 (חֲמָת) and H7237 (רַבָּה); Chamath of Rabbah; probably the same as H2574 (חֲמָת)",
		"def" : "Chamath-Rabbah"
	},
	"H2580" : {
		"lemma" : "חֵן",
		"xlit" : "chên",
		"pron" : "khane",
		"derivation" : "from H2603 (חָנַן)",
		"def" : "graciousness, i.e. subjective (kindness, favor) or objective (beauty)",
		"kjv" : "favour, grace(-ious), pleasant, precious, (well-) favoured"
	},
	"H2581" : {
		"lemma" : "חֵן",
		"xlit" : "Chên",
		"pron" : "khane",
		"derivation" : "the same as H2580 (חֵן); grace",
		"def" : "Chen, a figurative name for an Israelite",
		"kjv" : "Hen"
	},
	"H2582" : {
		"lemma" : "חֵנָדָד",
		"xlit" : "Chênâdâd",
		"pron" : "khay-naw-dawd'",
		"derivation" : "probably from H2580 (חֵן) and H1908 (הֲדַד); favor of Hadad",
		"def" : "Chenadad, an Israelite",
		"kjv" : "Henadad"
	},
	"H2583" : {
		"lemma" : "חָנָה",
		"xlit" : "chânâh",
		"pron" : "khaw-naw'",
		"derivation" : "a primitive root (compare H2603 (חָנַן))",
		"def" : "properly, to incline; by implication, to decline (of the slanting rays of evening); specifically, to pitch atent; gen. to encamp (for abode or siege)",
		"kjv" : "abide (in tents), camp, dwell, encamp, grow to an end, lie, pitch (tent), rest in tent"
	},
	"H2584" : {
		"lemma" : "חַנָּה",
		"xlit" : "Channâh",
		"pron" : "khan-naw'",
		"derivation" : "from H2603 (חָנַן); favored",
		"def" : "Channah, an Israelitess",
		"kjv" : "Hannah"
	},
	"H2585" : {
		"lemma" : "חֲנוֹךְ",
		"xlit" : "Chănôwk",
		"pron" : "khan-oke'",
		"derivation" : "from H2596 (חָנַךְ); initiated",
		"def" : "Chanok, an antediluvian patriach",
		"kjv" : "Enoch"
	},
	"H2586" : {
		"lemma" : "חָנוּן",
		"xlit" : "Chânûwn",
		"pron" : "khaw-noon'",
		"derivation" : "from H2603 (חָנַן); favored",
		"def" : "Chanun, the name of an Ammonite and of two Israelites",
		"kjv" : "Hanun"
	},
	"H2587" : {
		"lemma" : "חַנּוּן",
		"xlit" : "channûwn",
		"pron" : "khan-noon'",
		"derivation" : "from H2603 (חָנַן)",
		"def" : "gracious",
		"kjv" : "gracious"
	},
	"H2588" : {
		"lemma" : "חָנוּת",
		"xlit" : "chânûwth",
		"pron" : "khaw-nooth'",
		"derivation" : "from H2583 (חָנָה)",
		"def" : "properly, a vault or cell (with an arch); by implication, a prison",
		"kjv" : "cabin"
	},
	"H2589" : {
		"lemma" : "חַנּוֹת",
		"xlit" : "channôwth",
		"pron" : "klan-noth'",
		"derivation" : "from H2603 (חָנַן) (in the sense of prayer)",
		"def" : "supplication",
		"kjv" : "be gracious, intreated"
	},
	"H2590" : {
		"lemma" : "חָנַט",
		"xlit" : "chânaṭ",
		"pron" : "khaw-nat'",
		"derivation" : "a primitive root",
		"def" : "to spice; by implication, to embalm; also to ripen",
		"kjv" : "embalm, put forth"
	},
	"H2591" : {
		"lemma" : "חִנְטָא",
		"xlit" : "chinṭâʼ",
		"pron" : "khint-taw'",
		"derivation" : "(Aramaic) corresponding to H2406 (חִטָּה)",
		"def" : "wheat",
		"kjv" : "wheat"
	},
	"H2592" : {
		"lemma" : "חַנִּיאֵל",
		"xlit" : "Channîyʼêl",
		"pron" : "khan-nee-ale'",
		"derivation" : "from H2603 (חָנַן) and H410 (אֵל); favor of God",
		"def" : "Channiel, the name of two Israelites",
		"kjv" : "Hanniel"
	},
	"H2593" : {
		"lemma" : "חָנִיךְ",
		"xlit" : "chânîyk",
		"pron" : "kaw-neek'",
		"derivation" : "from H2596 (חָנַךְ)",
		"def" : "initiated; i.e. practiced",
		"kjv" : "trained"
	},
	"H2594" : {
		"lemma" : "חֲנִינָה",
		"xlit" : "chănîynâh",
		"pron" : "khan-ee-naw'",
		"derivation" : "from H2603 (חָנַן)",
		"def" : "graciousness",
		"kjv" : "favour"
	},
	"H2595" : {
		"lemma" : "חֲנִית",
		"xlit" : "chănîyth",
		"pron" : "khan-eeth'",
		"derivation" : "from H2583 (חָנָה)",
		"def" : "a lance (for thrusting, like pitching a tent)",
		"kjv" : "javelin, spear"
	},
	"H2596" : {
		"lemma" : "חָנַךְ",
		"xlit" : "chânak",
		"pron" : "khaw-nak'",
		"derivation" : "a primitive root; (compare H2614 (חָנַק))",
		"def" : "properly, to narrow; figuratively, to initiate or discipline",
		"kjv" : "dedicate, train up"
	},
	"H2597" : {
		"lemma" : "חֲנֻכָּא",
		"xlit" : "chănukkâʼ",
		"pron" : "chan-ook-kaw'",
		"derivation" : "(Aramaic) corresponding to H2598 (חֲנֻכָּה)",
		"def" : "consecration",
		"kjv" : "dedication"
	},
	"H2598" : {
		"lemma" : "חֲנֻכָּה",
		"xlit" : "chănukkâh",
		"pron" : "khan-ook-kaw'",
		"derivation" : "from H2596 (חָנַךְ)",
		"def" : "initiation, i.e. consecration",
		"kjv" : "dedicating(-tion)"
	},
	"H2599" : {
		"lemma" : "חֲנֹכִי",
		"xlit" : "Chănôkîy",
		"pron" : "khan-o-kee'",
		"derivation" : "patronymically from H2585 (חֲנוֹךְ)",
		"def" : "a Chanokite (collectively) or descendants of Chanok",
		"kjv" : "Hanochites"
	},
	"H2600" : {
		"lemma" : "חִנָּם",
		"xlit" : "chinnâm",
		"pron" : "khin-nawm'",
		"derivation" : "from H2580 (חֵן)",
		"def" : "gratis, i.e. devoid of cost, reason or advantage",
		"kjv" : "without a cause (cost, wages), causeless, to cost nothing, free(-ly), innocent, for nothing (nought, in vain"
	},
	"H2601" : {
		"lemma" : "חֲנַמְאֵל",
		"xlit" : "Chănamʼêl",
		"pron" : "khan-am-ale'",
		"derivation" : "probably by orthographical variation for H2606 (חֲנַנְאֵל)",
		"def" : "Chanamel, an Israelite",
		"kjv" : "Hanameel"
	},
	"H2602" : {
		"lemma" : "חֲנָמָל",
		"xlit" : "chănâmâl",
		"pron" : "khan-aw-mawl'",
		"derivation" : "of uncertain derivation",
		"def" : "perhaps the aphis or plantlouse",
		"kjv" : "frost"
	},
	"H2603" : {
		"lemma" : "חָנַן",
		"xlit" : "chânan",
		"pron" : "khaw-nan'",
		"derivation" : "a primitive root (compare H2583 (חָנָה))",
		"def" : "properly, to bend or stoop in kindness to an inferior; to favor, bestow; causatively to implore (i.e. move to favor by petition)",
		"kjv" : "beseech, [idiom] fair, (be, find, shew) favour(-able), be (deal, give, grant (gracious(-ly), intreat, (be) merciful, have (shew) mercy (on, upon), have pity upon, pray, make supplication, [idiom] very"
	},
	"H2604" : {
		"lemma" : "חֲנַן",
		"xlit" : "chănan",
		"pron" : "khan-an'",
		"derivation" : "(Aramaic) corresponding to H2603 (חָנַן)",
		"def" : "to favor or (causatively) to entreat",
		"kjv" : "shew mercy, make supplication"
	},
	"H2605" : {
		"lemma" : "חָנָן",
		"xlit" : "Chânân",
		"pron" : "khaw-nawn'",
		"derivation" : "from H2603 (חָנַן); favor",
		"def" : "Chanan, the name of seven Israelites",
		"kjv" : "Canan"
	},
	"H2606" : {
		"lemma" : "חֲנַנְאֵל",
		"xlit" : "Chănanʼêl",
		"pron" : "khan-an-ale'",
		"derivation" : "from H2603 (חָנַן) and H410 (אֵל); God has favored",
		"def" : "Chananel, probably an Israelite, from whom a tower of Jerusalem was named",
		"kjv" : "Hananeel"
	},
	"H2607" : {
		"lemma" : "חֲנָנִי",
		"xlit" : "Chănânîy",
		"pron" : "khan-aw-nee'",
		"derivation" : "from H2603 (חָנַן); gracious",
		"def" : "Chanani, the name of six Israelites",
		"kjv" : "Hanani"
	},
	"H2608" : {
		"lemma" : "חֲנַנְיָה",
		"xlit" : "Chănanyâh",
		"pron" : "khan-an-yaw'",
		"derivation" : "or חֲנַנְיָהוּ; from H2603 (חָנַן) and H3050 (יָהּ); Jah has favored",
		"def" : "Chananjah, the name of thirteen Israelites",
		"kjv" : "Hananiah"
	},
	"H2609" : {
		"lemma" : "חָנֵס",
		"xlit" : "Chânêç",
		"pron" : "khaw-nace'",
		"derivation" : "of Egyptian derivation",
		"def" : "Chanes, a place in Egypt",
		"kjv" : "Hanes"
	},
	"H2610" : {
		"lemma" : "חָנֵף",
		"xlit" : "chânêph",
		"pron" : "khaw-nafe'",
		"derivation" : "a primitive root",
		"def" : "to soil, especially in a moral sense",
		"kjv" : "corrupt, defile, [idiom] greatly, pollute, profane"
	},
	"H2611" : {
		"lemma" : "חָנֵף",
		"xlit" : "chânêph",
		"pron" : "khaw-nafe'",
		"derivation" : "from H2610 (חָנֵף)",
		"def" : "soiled (i.e. with sin), impious",
		"kjv" : "hypocrite(-ical)"
	},
	"H2612" : {
		"lemma" : "חֹנֶף",
		"xlit" : "chôneph",
		"pron" : "kho'-nef",
		"derivation" : "from H2610 (חָנֵף)",
		"def" : "moral filth, i.e. wickedness",
		"kjv" : "hypocrisy"
	},
	"H2613" : {
		"lemma" : "חֲנֻפָה",
		"xlit" : "chănuphâh",
		"pron" : "khan-oo-faw'",
		"derivation" : "feminine from H2610 (חָנֵף)",
		"def" : "impiety",
		"kjv" : "profaneness"
	},
	"H2614" : {
		"lemma" : "חָנַק",
		"xlit" : "chânaq",
		"pron" : "khaw-nak'",
		"derivation" : "a primitive root (compare H2596 (חָנַךְ))",
		"def" : "to be narrow; by implication, to throttle, or (reflex.) to choke oneself to death (by a rope)",
		"kjv" : "hang self, strangle"
	},
	"H2615" : {
		"lemma" : "חַנָּתֹן",
		"xlit" : "Channâthôn",
		"pron" : "khan-naw-thone'",
		"derivation" : "probably from H2603 (חָנַן); favored",
		"def" : "Channathon, a place in Palestine",
		"kjv" : "Hannathon"
	},
	"H2616" : {
		"lemma" : "חָסַד",
		"xlit" : "châçad",
		"pron" : "khaw-sad'",
		"derivation" : "a primitive root; (compare H2603 (חָנַן)); (compare H1288 (בָרַךְ))",
		"def" : "properly, perhaps to bow (the neck only  in courtesy to an equal), i.e. to be kind; also (by euphemistically, but rarely) to reprove",
		"kjv" : "shew self merciful, put to shame"
	},
	"H2617" : {
		"lemma" : "חֵסֵד",
		"xlit" : "chêçêd",
		"pron" : "kheh'-sed",
		"derivation" : "from H2616 (חָסַד)",
		"def" : "kindness; by implication (towards God) piety; rarely (by opposition) reproof, or (subject.) beauty",
		"kjv" : "favour, good deed(-liness, -ness), kindly, (loving-) kindness, merciful (kindness), mercy, pity, reproach, wicked thing"
	},
	"H2618" : {
		"lemma" : "חֶסֶד",
		"xlit" : "Cheçed",
		"pron" : "kheh'-sed",
		"derivation" : "the same as H2617 (חֵסֵד); favor",
		"def" : "Chesed, an Israelite",
		"kjv" : "Hesed"
	},
	"H2619" : {
		"lemma" : "חֲסַדְיָה",
		"xlit" : "Chăçadyâh",
		"pron" : "khas-ad-yaw'",
		"derivation" : "from H2617 (חֵסֵד) and H3050 (יָהּ); Jah has favored",
		"def" : "Chasadjah, an Israelite",
		"kjv" : "Hasadiah"
	},
	"H2620" : {
		"lemma" : "חָסָה",
		"xlit" : "châçâh",
		"pron" : "khaw-saw'",
		"derivation" : "a primitive root; (compare H982 (בָּטַח))",
		"def" : "to flee for protection; figuratively, to confide in",
		"kjv" : "have hope, make refuge, (put) trust"
	},
	"H2621" : {
		"lemma" : "חֹסָה",
		"xlit" : "Chôçâh",
		"pron" : "kho-saw'",
		"derivation" : "from H2620 (חָסָה); hopeful",
		"def" : "Chosah, an Israelite; also a place in Palestine",
		"kjv" : "Hosah"
	},
	"H2622" : {
		"lemma" : "חָסוּת",
		"xlit" : "châçûwth",
		"pron" : "khaw-sooth'",
		"derivation" : "from H2620 (חָסָה)",
		"def" : "confidence",
		"kjv" : "trust"
	},
	"H2623" : {
		"lemma" : "חָסִיד",
		"xlit" : "châçîyd",
		"pron" : "khaw-seed'",
		"derivation" : "from H2616 (חָסַד)",
		"def" : "properly, kind, i.e. (religiously) pious (a saint)",
		"kjv" : "godly (man), good, holy (one), merciful, saint, (un-) godly"
	},
	"H2624" : {
		"lemma" : "חֲסִידָה",
		"xlit" : "chăçîydâh",
		"pron" : "khas-ee-daw'",
		"derivation" : "feminine of H2623 (חָסִיד)",
		"def" : "the kind (maternal) bird, i.e. a stork",
		"kjv" : "[idiom] feather, stork"
	},
	"H2625" : {
		"lemma" : "חָסִיל",
		"xlit" : "châçîyl",
		"pron" : "khaw-seel'",
		"derivation" : "from H2628 (חָסַל)",
		"def" : "the ravager, i.e. a locust",
		"kjv" : "caterpillar"
	},
	"H2626" : {
		"lemma" : "חֲסִין",
		"xlit" : "chăçîyn",
		"pron" : "khas-een'",
		"derivation" : "from H2630 (חָסַן)",
		"def" : "properly, firm, i.e. (by implication) mighty",
		"kjv" : "strong"
	},
	"H2627" : {
		"lemma" : "חַסִּיר",
		"xlit" : "chaççîyr",
		"pron" : "khas-seer'",
		"derivation" : "(Aramaic) from a root corresponding to H2637 (חָסֵר)",
		"def" : "deficient",
		"kjv" : "wanting"
	},
	"H2628" : {
		"lemma" : "חָסַל",
		"xlit" : "châçal",
		"pron" : "khaw-sal'",
		"derivation" : "a primitive root",
		"def" : "to eat off",
		"kjv" : "consume"
	},
	"H2629" : {
		"lemma" : "חָסַם",
		"xlit" : "châçam",
		"pron" : "khaw-sam'",
		"derivation" : "a primitive root",
		"def" : "to muzzle; by analogy, to stop the nose",
		"kjv" : "muzzle, stop"
	},
	"H2630" : {
		"lemma" : "חָסַן",
		"xlit" : "châçan",
		"pron" : "khaw-san'",
		"derivation" : "a primitive root",
		"def" : "properly, to (be) compact; by implication, to hoard",
		"kjv" : "lay up"
	},
	"H2631" : {
		"lemma" : "חֲסַן",
		"xlit" : "chăçan",
		"pron" : "khas-an'",
		"derivation" : "(Aramaic) corresponding to H2630 (חָסַן)",
		"def" : "to hold in occupancy",
		"kjv" : "possess"
	},
	"H2632" : {
		"lemma" : "חֵסֶן",
		"xlit" : "chêçen",
		"pron" : "khay'-sen",
		"derivation" : "(Aramaic) from H2631 (חֲסַן)",
		"def" : "strength",
		"kjv" : "power"
	},
	"H2633" : {
		"lemma" : "חֹסֶן",
		"xlit" : "chôçen",
		"pron" : "kho'-sen",
		"derivation" : "from H2630 (חָסַן)",
		"def" : "wealth",
		"kjv" : "riches, strength, treasure"
	},
	"H2634" : {
		"lemma" : "חָסֹן",
		"xlit" : "châçôn",
		"pron" : "khaw-sone'",
		"derivation" : "from H2630 (חָסַן)",
		"def" : "powerful",
		"kjv" : "strong"
	},
	"H2635" : {
		"lemma" : "חֲסַף",
		"xlit" : "chăçaph",
		"pron" : "khas-af'",
		"derivation" : "(Aramaic) from a root corresponding to that of H2636 (חַסְפַּס)",
		"def" : "a clod",
		"kjv" : "clay"
	},
	"H2636" : {
		"lemma" : "חַסְפַּס",
		"xlit" : "chaçpaç",
		"pron" : "khas-pas'",
		"derivation" : "reduplicated from an unused root meaning apparently to peel",
		"def" : "a shred or scale",
		"kjv" : "round thing"
	},
	"H2637" : {
		"lemma" : "חָסֵר",
		"xlit" : "châçêr",
		"pron" : "khaw-sare'",
		"derivation" : "a primitive root",
		"def" : "to lack; by implication, to fail, want, lessen",
		"kjv" : "be abated, bereave, decrease, (cause to) fail, (have) lack, make lower, want"
	},
	"H2638" : {
		"lemma" : "חָסֵר",
		"xlit" : "châçêr",
		"pron" : "khaw-sare'",
		"derivation" : "from H2637 (חָסֵר)",
		"def" : "lacking; hence, without",
		"kjv" : "destitute, fail, lack, have need, void, want"
	},
	"H2639" : {
		"lemma" : "חֶסֶר",
		"xlit" : "cheçer",
		"pron" : "kheh'-ler",
		"derivation" : "from H2637 (חָסֵר)",
		"def" : "lack; hence, destitution",
		"kjv" : "poverty, want"
	},
	"H2640" : {
		"lemma" : "חֹסֶר",
		"xlit" : "chôçer",
		"pron" : "kho'-ser",
		"derivation" : "from H2637 (חָסֵר)",
		"def" : "poverty",
		"kjv" : "in want of"
	},
	"H2641" : {
		"lemma" : "חַסְרָה",
		"xlit" : "Chaçrâh",
		"pron" : "khas-raw'",
		"derivation" : "from H2637 (חָסֵר); want",
		"def" : "Chasrah, an Israelite",
		"kjv" : "Hasrah"
	},
	"H2642" : {
		"lemma" : "חֶסְרוֹן",
		"xlit" : "cheçrôwn",
		"pron" : "khes-rone'",
		"derivation" : "from H2637 (חָסֵר)",
		"def" : "deficiency",
		"kjv" : "wanting"
	},
	"H2643" : {
		"lemma" : "חַף",
		"xlit" : "chaph",
		"pron" : "khaf",
		"derivation" : "from H2653 (חׇפַף) (in the moral sense of covered from soil)",
		"def" : "pure",
		"kjv" : "innocent"
	},
	"H2644" : {
		"lemma" : "חָפָא",
		"xlit" : "châphâʼ",
		"pron" : "khaw-faw'",
		"derivation" : "an orthographical variation of H2645 (חָפָה)",
		"def" : "properly, to cover, i.e. (in a sinister sense) to act covertly",
		"kjv" : "do secretly"
	},
	"H2645" : {
		"lemma" : "חָפָה",
		"xlit" : "châphâh",
		"pron" : "khaw-faw'",
		"derivation" : "a primitive root (compare H2644 (חָפָא), H2653 (חׇפַף))",
		"def" : "to cover; by implication, to veil, to encase, protect",
		"kjv" : "ceil, cover, overlay"
	},
	"H2646" : {
		"lemma" : "חֻפָּה",
		"xlit" : "chuppâh",
		"pron" : "khoop-paw'",
		"derivation" : "from H2645 (חָפָה)",
		"def" : "a canopy",
		"kjv" : "chamber, closet, defence"
	},
	"H2647" : {
		"lemma" : "חֻפָּה",
		"xlit" : "Chuppâh",
		"pron" : "khoop-paw'",
		"derivation" : "the same as H2646 (חֻפָּה)",
		"def" : "Chuppah, an Israelite",
		"kjv" : "Huppah"
	},
	"H2648" : {
		"lemma" : "חָפַז",
		"xlit" : "châphaz",
		"pron" : "khaw-faz'",
		"derivation" : "a primitive root",
		"def" : "properly, to start up suddenly, i.e. (by implication) to hasten away, to fear",
		"kjv" : "(make) haste (away), tremble"
	},
	"H2649" : {
		"lemma" : "חִפָּזוֹן",
		"xlit" : "chippâzôwn",
		"pron" : "khip-paw-zone'",
		"derivation" : "from H2468 (חֻלְדָּה)",
		"def" : "hasty flight",
		"kjv" : "haste"
	},
	"H2650" : {
		"lemma" : "חֻפִּים",
		"xlit" : "Chuppîym",
		"pron" : "khoop-peem'",
		"derivation" : "plural of H2646 (חֻפָּה) (compare H2349 (חוּפָם))",
		"def" : "Chuppim, an Israelite",
		"kjv" : "Huppim"
	},
	"H2651" : {
		"lemma" : "חֹפֶן",
		"xlit" : "chôphen",
		"pron" : "kho'-fen",
		"derivation" : "from an unused root of uncertain signification",
		"def" : "a fist (only in the dual)",
		"kjv" : "fists, (both) hands, hand(-ful)"
	},
	"H2652" : {
		"lemma" : "חׇפְנִי",
		"xlit" : "Chophnîy",
		"pron" : "khof-nee'",
		"derivation" : "from H2651 (חֹפֶן); perhaps pugilist",
		"def" : "Chophni, an Israelite",
		"kjv" : "Hophni"
	},
	"H2653" : {
		"lemma" : "חׇפַף",
		"xlit" : "chophaph",
		"pron" : "khaw-faf'",
		"derivation" : "a primitive root (compare H2645 (חָפָה), H3182 (יָחֵף))",
		"def" : "to cover (in protection)",
		"kjv" : "cover"
	},
	"H2654" : {
		"lemma" : "חָפֵץ",
		"xlit" : "châphêts",
		"pron" : "khaw-fates'",
		"derivation" : "a primitive root",
		"def" : "properly, to incline to; by implication (literally but rarely) to bend; figuratively, to be pleased with, desire",
		"kjv" : "[idiom] any at all, (have, take) delight, desire, favour, like, move, be (well) pleased, have pleasure, will, would"
	},
	"H2655" : {
		"lemma" : "חָפֵץ",
		"xlit" : "châphêts",
		"pron" : "khaw-fates'",
		"derivation" : "from H2654 (חָפֵץ)",
		"def" : "pleased with",
		"kjv" : "delight in, desire, favour, please, have pleasure, whosoever would, willing, wish"
	},
	"H2656" : {
		"lemma" : "חֵפֶץ",
		"xlit" : "chêphets",
		"pron" : "khay'-fets",
		"derivation" : "from H2654 (חָפֵץ)",
		"def" : "pleasure; hence (abstractly) desire; concretely, a valuable thing; hence (by extension) a matter (as something in mind)",
		"kjv" : "acceptable, delight(-some), desire, things desired, matter, pleasant(-ure), purpose, willingly"
	},
	"H2657" : {
		"lemma" : "חֶפְצִי בָּהּ",
		"xlit" : "Chephtsîy bâhh",
		"pron" : "khef-tsee'baw",
		"derivation" : "from H2656 (חֵפֶץ) with suffixes; my delight (is) in her",
		"def" : "Cheptsi-bah, a fanciful name for Palestine",
		"kjv" : "Hephzi-bah"
	},
	"H2658" : {
		"lemma" : "חָפַר",
		"xlit" : "châphar",
		"pron" : "khaw-far'",
		"derivation" : "a primitive root",
		"def" : "properly, to pry into; by implication, to delve, to explore",
		"kjv" : "dig, paw, search out, seek"
	},
	"H2659" : {
		"lemma" : "חָפֵר",
		"xlit" : "châphêr",
		"pron" : "khaw-fare'",
		"derivation" : "a primitive root (perhaps rather the same as H2658 (חָפַר) through the idea of detection)",
		"def" : "to blush; figuratively, to be ashamed, disappointed; causatively, to shame, reproach",
		"kjv" : "be ashamed, be confounded, be brought to confusion (unto shame), come (be put to) shame, bring reproach"
	},
	"H2660" : {
		"lemma" : "חֵפֶר",
		"xlit" : "Chêpher",
		"pron" : "khay'-fer",
		"derivation" : "from H2658 (חָפַר) or H2659 (חָפֵר); a pit of shame",
		"def" : "Chepher, a place in Palestine; also the name of three Israelites",
		"kjv" : "Hepher"
	},
	"H2661" : {
		"lemma" : "חֲפֹר",
		"xlit" : "chăphôr",
		"pron" : "khaf-ore'",
		"derivation" : "from H2658 (חָפַר); a hole; only in connection with H6512 (פֵּרָה), which ought rather to be joined as one word, thus חַפַרְפֵּרָה; by reduplication from H2658 (חָפַר)",
		"def" : "a burrower, i.e. probably a rat",
		"kjv" : "[phrase] mole"
	},
	"H2662" : {
		"lemma" : "חֶפְרִי",
		"xlit" : "Chephrîy",
		"pron" : "khef-ree'",
		"derivation" : "patronymically from H2660 (חֵפֶר)",
		"def" : "a Chephrite (collectively) or descendants of Chepher",
		"kjv" : "Hepherites"
	},
	"H2663" : {
		"lemma" : "חֲפָרַיִם",
		"xlit" : "Chăphârayim",
		"pron" : "khaf-aw-rah'-yim",
		"derivation" : "dual of H2660 (חֵפֶר); double pit",
		"def" : "Chapharajim, a place in Palestine",
		"kjv" : "Haphraim"
	},
	"H2664" : {
		"lemma" : "חָפַשׂ",
		"xlit" : "châphas",
		"pron" : "khaw-fas'",
		"derivation" : "a primitive root",
		"def" : "to seek; causatively, to conceal oneself (i.e. let be sought), or mask",
		"kjv" : "change, (make) diligent (search), disquise self, hide, search (for, out)"
	},
	"H2665" : {
		"lemma" : "חֵפֶשׂ",
		"xlit" : "chêphes",
		"pron" : "khay'-fes",
		"derivation" : "from H2664 (חָפַשׂ)",
		"def" : "something covert, i.e. a trick",
		"kjv" : "search"
	},
	"H2666" : {
		"lemma" : "חָפַשׁ",
		"xlit" : "châphash",
		"pron" : "khaw-fash'",
		"derivation" : "a primitive root",
		"def" : "to spread loose; figuratively, to manumit",
		"kjv" : "be free"
	},
	"H2667" : {
		"lemma" : "חֹפֶשׁ",
		"xlit" : "Chôphesh",
		"pron" : "kho'-fesh",
		"derivation" : "from H2666 (חָפַשׁ)",
		"def" : "something spread loosely, i.e. a carpet",
		"kjv" : "precious"
	},
	"H2668" : {
		"lemma" : "חֻפְשָׁה",
		"xlit" : "chuphshâh",
		"pron" : "khoof-shaw'",
		"derivation" : "from H2666 (חָפַשׁ)",
		"def" : "liberty (from slavery)",
		"kjv" : "freedom"
	},
	"H2669" : {
		"lemma" : "חׇפְשׁוּת",
		"xlit" : "chophshûwth",
		"pron" : "khof-shooth'",
		"derivation" : "and חׇפְשִׁית; from H2666 (חָפַשׁ); (with H1004 (בַּיִת), a hospital)",
		"def" : "prostration by sickness",
		"kjv" : "several"
	},
	"H2670" : {
		"lemma" : "חׇפְשִׁי",
		"xlit" : "chophshîy",
		"pron" : "khof-shee'",
		"derivation" : "from H2666 (חָפַשׁ)",
		"def" : "exempt (from bondage, tax or care)",
		"kjv" : "free, liberty"
	},
	"H2671" : {
		"lemma" : "חֵץ",
		"xlit" : "chêts",
		"pron" : "khayts",
		"derivation" : "from H2686 (חָצַץ); also by interchange for H6086 (עֵץ)",
		"def" : "properly, a piercer, i.e. an arrow; by implication, a wound; figuratively, (of God) thunderbolt; the shaft of aspear",
		"kjv" : "[phrase] archer, arrow, dart, shaft, staff, wound"
	},
	"H2672" : {
		"lemma" : "חָצַב",
		"xlit" : "châtsab",
		"pron" : "khaw-tsab'",
		"derivation" : "or חָצֵב; a primitive root",
		"def" : "to cut or carve (wood, stone or other material); by implication, to hew, split, square, quarry, engrave",
		"kjv" : "cut, dig, divide, grave, hew (out, -er), made, mason"
	},
	"H2673" : {
		"lemma" : "חָצָה",
		"xlit" : "châtsâh",
		"pron" : "khaw-tsaw'",
		"derivation" : "a primitive root (compare H2086 (זֵד)))",
		"def" : "to cut or split in two; to halve",
		"kjv" : "divide, [idiom] live out half, reach to the midst, participle"
	},
	"H2674" : {
		"lemma" : "חָצוֹר",
		"xlit" : "Châtsôwr",
		"pron" : "khaw-tsore'",
		"derivation" : "a collective form of H2691 (חָצֵר); village",
		"def" : "Chatsor, the name (thus simply) of two places in Palestine and of one in Arabia",
		"kjv" : "Hazor"
	},
	"H2675" : {
		"lemma" : "חָצוֹר חֲדַתָּה",
		"xlit" : "Châtsôwr Chădattâh",
		"pron" : "khaw-tsore' khad-attaw'",
		"derivation" : "from H2674 (חָצוֹר) and a Aramaizing form of the feminine of H2319 (חָדָשׁ) (compare H2323 (חֲדָת))",
		"def" : "new Chatsor, a place in Palestine",
		"kjv" : "Hazor, Hadattah (as if two places)"
	},
	"H2676" : {
		"lemma" : "חָצוֹת",
		"xlit" : "châtsôwth",
		"pron" : "khaw-tsoth'",
		"derivation" : "from H2673 (חָצָה)lemma חָעָה ayin, corrected to חָצָה",
		"def" : "the middle (of the night)",
		"kjv" : "mid(-night)"
	},
	"H2677" : {
		"lemma" : "חֵצִי",
		"xlit" : "chêtsîy",
		"pron" : "khay-tsee'",
		"derivation" : "from H2673 (חָצָה)lemma חָעָה ayin, corrected to חָצָה",
		"def" : "the half or middle",
		"kjv" : "half, middle, mid(-night), midst, part, two parts"
	},
	"H2678" : {
		"lemma" : "חִצִּי",
		"xlit" : "chitstsîy",
		"pron" : "khits-tsee'",
		"derivation" : "or חֵצִי; prolongation from H2671 (חֵץ)",
		"def" : "an arrow",
		"kjv" : "arrow"
	},
	"H2679" : {
		"lemma" : "חֲצִי הַמְּנֻחוֹת",
		"xlit" : "Chătsîy ham-Mᵉnuchôwth",
		"pron" : "chat-tsee' hammen-oo-khoth'",
		"derivation" : "from H2677 (חֵצִי) and the plural of H4496 (מְנוּחָה), with the article interposed; midst of the resting-places",
		"def" : "Chatsi-ham-Menuchoth, an Israelite",
		"kjv" : "half of the Manahethites"
	},
	"H2680" : {
		"lemma" : "חֲצִי הַמְּנַחְתִּי",
		"xlit" : "Chătsîy ham-Mᵉnachtîy",
		"pron" : "khat-see' ham-menakh-tee'",
		"derivation" : "patronymically from H2679 (חֲצִי הַמְּנֻחוֹת)",
		"def" : "a Chatsi-ham-Menachtite or descendant of Chatsi-ham-Menuchoth",
		"kjv" : "half of the Manahethites"
	},
	"H2681" : {
		"lemma" : "חָצִיר",
		"xlit" : "châtsîyr",
		"pron" : "khaw-tseer'",
		"derivation" : "a collateral form of H2691 (חָצֵר)",
		"def" : "a court or abode",
		"kjv" : "court"
	},
	"H2682" : {
		"lemma" : "חָצִיר",
		"xlit" : "châtsîyr",
		"pron" : "khaw-tseer'",
		"derivation" : "perhaps originally the same as H2681 (חָצִיר), from the greenness of a courtyard",
		"def" : "grass; also a leek (collectively)",
		"kjv" : "grass, hay, herb, leek"
	},
	"H2683" : {
		"lemma" : "חֵצֶן",
		"xlit" : "chêtsen",
		"pron" : "khay'-tsen",
		"derivation" : "from an unused root meaning to hold firmly",
		"def" : "the bosom (as comprised between the arms)",
		"kjv" : "bosom"
	},
	"H2684" : {
		"lemma" : "חֹצֶן",
		"xlit" : "chôtsen",
		"pron" : "kho'tsen",
		"derivation" : "a collateral form of H2683 (חֵצֶן), and meaning the same",
		"def" : "{the bosom (as comprised between the arms)}",
		"kjv" : "arm, lap"
	},
	"H2685" : {
		"lemma" : "חֲצַף",
		"xlit" : "chătsaph",
		"pron" : "khats-af'",
		"derivation" : "(Aramaic) a primitive root",
		"def" : "properly, to shear or cut close; figuratively, to be severe",
		"kjv" : "hasty, be urgent"
	},
	"H2686" : {
		"lemma" : "חָצַץ",
		"xlit" : "châtsats",
		"pron" : "khaw-tsats'",
		"derivation" : "a primitive root (compare H2673 (חָצָה)lemma חָעָה ayin, corrected to חָצָה); also as denominative from H2671 (חֵץ)",
		"def" : "properly, to chop into, pierce or sever; hence, to curtail, to distribute (into ranks);  to shoot an arrow",
		"kjv" : "archer, [idiom] bands, cut off in the midst"
	},
	"H2687" : {
		"lemma" : "חָצָץ",
		"xlit" : "châtsâts",
		"pron" : "khaw-tsawts'",
		"derivation" : "from H2687 (חָצָץ);  also like H2671 (חֵץ)",
		"def" : "properly, something cutting; hence, gravel (as grit); an arrow",
		"kjv" : "arrow, gravel (stone)"
	},
	"H2688" : {
		"lemma" : "חַצְצוֹן תָּמָר",
		"xlit" : "Chatsᵉtsôwn Tâmâr",
		"pron" : "khats-ets-one' taw-mawr'",
		"derivation" : "or חַצֲצֹן תָּמָר; from H2686 (חָצַץ) and H8558 (תָּמָר); division (i.e. perhaps row) of (the) palm-tree",
		"def" : "Chatsetson-tamar, a place in Palestine",
		"kjv" : "Hazezon-tamar"
	},
	"H2689" : {
		"lemma" : "חֲצֹצְרָה",
		"xlit" : "chătsôtsᵉrâh",
		"pron" : "khats-o-tser-aw'",
		"derivation" : "by reduplication from H2690 (חָצַר)",
		"def" : "a trumpet (from its sundered or quavering note)",
		"kjv" : "trumpet(-er)"
	},
	"H2690" : {
		"lemma" : "חָצַר",
		"xlit" : "châtsar",
		"pron" : "khaw-tsar'",
		"derivation" : "a primitive root; properly, to surround with a stockade, and thus separate from the open country; but used only in the reduplicated form חֲצֹצֵר; or (2 Chronicles 5:12) חֲצֹרֵר; as dem. from H2689 (חֲצֹצְרָה)",
		"def" : "to trumpet, i.e. blow on that instrument",
		"kjv" : "blow, sound, trumpeter"
	},
	"H2691" : {
		"lemma" : "חָצֵר",
		"xlit" : "châtsêr",
		"pron" : "khaw-tsare'",
		"derivation" : "(masculine and feminine); from H2690 (חָצַר) in its original sense",
		"def" : "a yard (as inclosed by a fence); also a hamlet (as similarly surrounded with walls)",
		"kjv" : "court, tower, village"
	},
	"H2692" : {
		"lemma" : "חֲצַר אַדָּר",
		"xlit" : "Chătsar ʼAddâr",
		"pron" : "khats-ar' addawr'",
		"derivation" : "from H2691 (חָצֵר) and H146 (אַדָּר); (the) village of Addar",
		"def" : "Chatsar-Addar, a place in Palestine",
		"kjv" : "Hazar-addar"
	},
	"H2693" : {
		"lemma" : "חֲצַר גַּדָּה",
		"xlit" : "Chătsar Gaddâh",
		"pron" : "khats-ar' gad-daw'",
		"derivation" : "from H2691 (חָצֵר) and a feminine of H1408 (גַּד); (the) village of (female) Fortune",
		"def" : "Chatsar-Gaddah, a place in Palestine",
		"kjv" : "Hazar-gaddah"
	},
	"H2694" : {
		"lemma" : "חֲצַר הַתִּיכוֹן",
		"xlit" : "Chătsar hat-Tîykôwn",
		"pron" : "khats-ar' hat-tee-kone'",
		"derivation" : "from H2691 (חָצֵר) and H8484 (תִּיכוֹן) with the article interposed; village of the middle",
		"def" : "Chatsar-hat-Tikon, a place in Palestine",
		"kjv" : "Hazar-hatticon"
	},
	"H2695" : {
		"lemma" : "חֶצְרוֹ",
		"xlit" : "Chetsrôw",
		"pron" : "khets-ro'",
		"derivation" : "by an orthographical variation for H2696 (חֶצְרוֹן); enclosure",
		"def" : "Chetsro, an Israelite",
		"kjv" : "Hezro, Hezrai"
	},
	"H2696" : {
		"lemma" : "חֶצְרוֹן",
		"xlit" : "Chetsrôwn",
		"pron" : "khets-rone'",
		"derivation" : "from H2691 (חָצֵר); court-yard",
		"def" : "Chetsron, the name of a place in Palestine; also of two Israelites",
		"kjv" : "Hezron"
	},
	"H2697" : {
		"lemma" : "חֶצְרוֹנִי",
		"xlit" : "Chetsrôwnîy",
		"pron" : "khets-ro-nee'",
		"derivation" : "patronymically from H2696 (חֶצְרוֹן)",
		"def" : "a Chetsronite or (collectively) descendants of Chetsron",
		"kjv" : "Hezronites"
	},
	"H2698" : {
		"lemma" : "חֲצֵרוֹת",
		"xlit" : "Chătsêrôwth",
		"pron" : "khats-ay-roth'",
		"derivation" : "feminine plural of H2691 (חָצֵר); yards",
		"def" : "Chatseroth, a place in Palestine",
		"kjv" : "Hazeroth"
	},
	"H2699" : {
		"lemma" : "חֲצֵרִים",
		"xlit" : "Chătsêrîym",
		"pron" : "khats-ay-reem'",
		"derivation" : "plural masculine of H2691 (חָצֵר); yards",
		"def" : "Chatserim, a place in Palestine",
		"kjv" : "Hazerim"
	},
	"H2700" : {
		"lemma" : "חֲצַרְמָוֶת",
		"xlit" : "Chătsarmâveth",
		"pron" : "khats-ar-maw'-veth",
		"derivation" : "from H2691 (חָצֵר) and H4194 (מָוֶת); village of death",
		"def" : "Chatsarmaveth, a place in Arabia",
		"kjv" : "Hazarmaveth"
	},
	"H2701" : {
		"lemma" : "חֲצַר סוּסָה",
		"xlit" : "Chătsar Çûwçâh",
		"pron" : "khats-ar'soo-saw'",
		"derivation" : "from H2691 (חָצֵר) and H5484 (סוּסָה); village of cavalry",
		"def" : "Chatsar-Susah, a place in Palestine",
		"kjv" : "Hazar-susah"
	},
	"H2702" : {
		"lemma" : "חֲצַר סוּסִים",
		"xlit" : "Chătsar Çûwçîym",
		"pron" : "khats-ar' soo-seem'",
		"derivation" : "from H2691 (חָצֵר) and the plural of H5483 (סוּס); village of horses",
		"def" : "Chatsar-Susim, a place in Palestine",
		"kjv" : "Hazar-susim"
	},
	"H2703" : {
		"lemma" : "חֲצַר עֵינוֹן",
		"xlit" : "Chătsar ʻÊynôwn",
		"pron" : "khats-ar' ay-none'",
		"derivation" : "from H2691 (חָצֵר) and a derivative of H5869 (עַיִן); village of springs",
		"def" : "Chatsar-Enon, a place in Palestine",
		"kjv" : "Hazar-enon"
	},
	"H2704" : {
		"lemma" : "חֲצַר עֵינָן",
		"xlit" : "Chătsar ʻÊynân",
		"pron" : "khats-ar' ay-nawn'",
		"derivation" : "from H2691 (חָצֵר) and the same as H5881 (עֵינָן); village of springs",
		"def" : "Chatsar-Enan, a place in Palestine",
		"kjv" : "Hazar-enan"
	},
	"H2705" : {
		"lemma" : "חֲצַר שׁוּעָל",
		"xlit" : "Chătsar Shûwʻâl",
		"pron" : "khats-ar' shoo-awl'",
		"derivation" : "from H2691 (חָצֵר) and H7776 (שׁוּעָל); village of (the) fox",
		"def" : "Chatsar-Shual, a place in Palestine",
		"kjv" : "Hazar-shual"
	},
	"H2706" : {
		"lemma" : "חֹק",
		"xlit" : "chôq",
		"pron" : "khoke",
		"derivation" : "from H2710 (חָקַק)",
		"def" : "an enactment; hence, an appointment (of time, space, quantity, labor or usage)",
		"kjv" : "appointed, bound, commandment, convenient, custom, decree(-d), due, law, measure, [idiom] necessary, ordinance(-nary), portion, set time, statute, task"
	},
	"H2707" : {
		"lemma" : "חָקָה",
		"xlit" : "châqâh",
		"pron" : "khaw-kaw'",
		"derivation" : "a primitive root",
		"def" : "to carve; by implication, to delineate; also to entrench",
		"kjv" : "carved work, portrayed, set a print"
	},
	"H2708" : {
		"lemma" : "חֻקָּה",
		"xlit" : "chuqqâh",
		"pron" : "khook-kaw'",
		"derivation" : "feminine of H2706 (חֹק), and meaning substantially the same",
		"def" : "{an enactment; hence, an appointment (of time, space, quantity, labor or usage)}",
		"kjv" : "appointed, custom, manner, ordinance, site, statute"
	},
	"H2709" : {
		"lemma" : "חֲקוּפָא",
		"xlit" : "Chăqûwphâʼ",
		"pron" : "khah-oo-faw'",
		"derivation" : "from an unused root probably meaning to bend; crooked",
		"def" : "Chakupha, one of the Nethinim",
		"kjv" : "Hakupha"
	},
	"H2710" : {
		"lemma" : "חָקַק",
		"xlit" : "châqaq",
		"pron" : "khaw-kak'",
		"derivation" : "a primitive root",
		"def" : "properly, to hack, i.e. engrave (Judges 5:14, to be a scribe simply); by implication, to enact (laws being cut in stone or metal tablets in primitive times) or (gen.) prescribe",
		"kjv" : "appoint, decree, governor, grave, lawgiver, note, pourtray, print, set"
	},
	"H2711" : {
		"lemma" : "חֵקֶק",
		"xlit" : "chêqeq",
		"pron" : "khay'-kek",
		"derivation" : "from H2710 (חָקַק)",
		"def" : "an enactment, a resolution",
		"kjv" : "decree, thought"
	},
	"H2712" : {
		"lemma" : "חֻקֹּק",
		"xlit" : "Chuqqôq",
		"pron" : "Khook-koke'",
		"derivation" : "or (fully) חוּקֹק; from H2710 (חָקַק); appointed",
		"def" : "Chukkok or Chukok, a place in Palestine",
		"kjv" : "Hukkok, Hukok"
	},
	"H2713" : {
		"lemma" : "חָקַר",
		"xlit" : "châqar",
		"pron" : "khaw-kar'",
		"derivation" : "a primitive root",
		"def" : "properly, to penetrate; hence, to examine intimately",
		"kjv" : "find out, (make) search (out), seek (out), sound, try"
	},
	"H2714" : {
		"lemma" : "חֵקֶר",
		"xlit" : "chêqer",
		"pron" : "khay'-ker",
		"derivation" : "from H2713 (חָקַר)",
		"def" : "examination, enumeration, deliberation",
		"kjv" : "finding out, number, (un-) search(-able, -ed, out, -ing)"
	},
	"H2715" : {
		"lemma" : "חֹר",
		"xlit" : "chôr",
		"pron" : "khore",
		"derivation" : "or (fully) חוֹר; from H2787 (חָרַר); (compare H2751 (חֹרִי))",
		"def" : "properly, white or pure (from the cleansing or shining power of fire; hence (figuratively) noble (in rank)",
		"kjv" : "noble"
	},
	"H2716" : {
		"lemma" : "חֶרֶא",
		"xlit" : "chereʼ",
		"pron" : "kheh'-reh",
		"derivation" : "from an unused (and vulgar) root probably meaning to evacuate the bowels",
		"def" : "excrement",
		"kjv" : "dung. Also חֲרִי"
	},
	"H2717" : {
		"lemma" : "חָרַב",
		"xlit" : "chârab",
		"pron" : "khaw-rab'",
		"derivation" : "or חֲרֵב; a primitive root",
		"def" : "to parch (through drought) i.e. (by analogy,) to desolate, destroy, kill",
		"kjv" : "decay, (be) desolate, destroy(-er), (be) dry (up), slay, [idiom] surely, (lay, lie, make) waste"
	},
	"H2718" : {
		"lemma" : "חֲרַב",
		"xlit" : "chărab",
		"pron" : "khar-ab'",
		"derivation" : "(Aramaic) a root corresponding to H2717 (חָרַב)",
		"def" : "to demolish",
		"kjv" : "destroy"
	},
	"H2719" : {
		"lemma" : "חֶרֶב",
		"xlit" : "chereb",
		"pron" : "kheh'-reb",
		"derivation" : "from H2717 (חָרַב)",
		"def" : "drought; also a cutting instrument (from its destructive effect), as a knife, sword, or other sharp implement",
		"kjv" : "axe, dagger, knife, mattock, sword, tool"
	},
	"H2720" : {
		"lemma" : "חָרֵב",
		"xlit" : "chârêb",
		"pron" : "khaw-rabe'",
		"derivation" : "from H2717 (חָרַב)",
		"def" : "parched or ruined",
		"kjv" : "desolate, dry, waste"
	},
	"H2721" : {
		"lemma" : "חֹרֶב",
		"xlit" : "chôreb",
		"pron" : "kho'-reb",
		"derivation" : "a collaterally form of H2719 (חֶרֶב)",
		"def" : "drought or desolation",
		"kjv" : "desolation, drought, dry, heat, [idiom] utterly, waste"
	},
	"H2722" : {
		"lemma" : "חֹרֵב",
		"xlit" : "Chôrêb",
		"pron" : "kho-rabe'",
		"derivation" : "from H2717 (חָרַב); desolate",
		"def" : "Choreb, a (generic) name for the Sinaitic mountains",
		"kjv" : "Horeb"
	},
	"H2723" : {
		"lemma" : "חׇרְבָּה",
		"xlit" : "chorbâh",
		"pron" : "khor-baw'",
		"derivation" : "feminine of H2721 (חֹרֶב)",
		"def" : "properly, drought, i.e. (by implication) a desolation",
		"kjv" : "decayed place, desolate (place, -tion), destruction, (laid) waste (place)"
	},
	"H2724" : {
		"lemma" : "חָרָבָה",
		"xlit" : "chârâbâh",
		"pron" : "khaw-raw-baw'",
		"derivation" : "feminine of H2720 (חָרֵב)",
		"def" : "a desert",
		"kjv" : "dry (ground, land)"
	},
	"H2725" : {
		"lemma" : "חֲרָבוֹן",
		"xlit" : "chărâbôwn",
		"pron" : "khar-aw-bone'",
		"derivation" : "from H2717 (חָרַב)",
		"def" : "parching heat",
		"kjv" : "drought"
	},
	"H2726" : {
		"lemma" : "חַרְבוֹנָא",
		"xlit" : "Charbôwnâʼ",
		"pron" : "khar-bo-naw'",
		"derivation" : "or חַרְבוֹנָה; of Persian origin",
		"def" : "Charbona or Charbonah, a eunuch of Xerxes",
		"kjv" : "Harbona, Harbonah"
	},
	"H2727" : {
		"lemma" : "חָרַג",
		"xlit" : "chârag",
		"pron" : "khaw-rag'",
		"derivation" : "a primitive root",
		"def" : "properly, to leap suddenly, i.e. (by implication) to be dismayed",
		"kjv" : "be afraid"
	},
	"H2728" : {
		"lemma" : "חָרְגֹּל",
		"xlit" : "chârᵉgôl",
		"pron" : "khar-gole'",
		"derivation" : "from H2727 (חָרַג)",
		"def" : "the leaping insect, i.e. a locust",
		"kjv" : "beetle"
	},
	"H2729" : {
		"lemma" : "חָרַד",
		"xlit" : "chârad",
		"pron" : "khaw-rad'",
		"derivation" : "a primitive root",
		"def" : "to shudder with terror; hence, to fear; also to hasten (with anxiety)",
		"kjv" : "be (make) afraid, be careful, discomfit, fray (away), quake, tremble"
	},
	"H2730" : {
		"lemma" : "חָרֵד",
		"xlit" : "chârêd",
		"pron" : "khaw-rade'",
		"derivation" : "from H2729 (חָרַד)",
		"def" : "fearful; also reverential",
		"kjv" : "afraid, trembling"
	},
	"H2731" : {
		"lemma" : "חֲרָדָה",
		"xlit" : "chărâdâh",
		"pron" : "khar-aw-daw'",
		"derivation" : "feminine of H2730 (חָרֵד)",
		"def" : "fear, anxiety",
		"kjv" : "care, [idiom] exceedingly, fear, quaking, trembling"
	},
	"H2732" : {
		"lemma" : "חֲרָדָה",
		"xlit" : "Chărâdâh",
		"pron" : "khar-aw-daw'",
		"derivation" : "the same as H2731 (חֲרָדָה)",
		"def" : "Charadah, a place in the Desert",
		"kjv" : "Haradah"
	},
	"H2733" : {
		"lemma" : "חֲרֹדִי",
		"xlit" : "Chărôdîy",
		"pron" : "khar-o-dee'",
		"derivation" : "patrial from a derivative of H2729 (חָרַד) (compare H5878 (עֵין חֲרֹד))",
		"def" : "a Charodite, or inhabitant of Charod",
		"kjv" : "Harodite"
	},
	"H2734" : {
		"lemma" : "חָרָה",
		"xlit" : "chârâh",
		"pron" : "khaw-raw'",
		"derivation" : "a primitive root (compare H2787 (חָרַר))",
		"def" : "to glow or grow warm; figuratively (usually) to blaze up, of anger, zeal, jealousy",
		"kjv" : "be angry, burn, be displeased, [idiom] earnestly, fret self, grieve, be (wax) hot, be incensed, kindle, [idiom] very, be wroth. See H8474 (תַּחָרָה)"
	},
	"H2735" : {
		"lemma" : "חֹר הַגִּדְגָּד",
		"xlit" : "Chôr hag-Gidgâd",
		"pron" : "khore hag-ghid-gawd'",
		"derivation" : "from H2356 (חוֹר) and a collateral (masculine) form of H1412 (גֻּדְגֹּדָה), with the article interposed; hole of the cleft",
		"def" : "Chor-hag-Gidgad, a place in the Desert",
		"kjv" : "Hor-hagidgad"
	},
	"H2736" : {
		"lemma" : "חַרְהֲיָה",
		"xlit" : "Charhăyâh",
		"pron" : "khar-hah-yaw'",
		"derivation" : "; from H2734 (חָרָה) and H3050 (יָהּ); fearing Jah",
		"def" : "Charhajah, an Israelite",
		"kjv" : "Harhaiah"
	},
	"H2737" : {
		"lemma" : "חָרוּז",
		"xlit" : "chârûwz",
		"pron" : "khaw-rooz'",
		"derivation" : "from an unused root meaning to perforate",
		"def" : "properly, pierced, i.e. a bead of pearl, gems or jewels (as strung)",
		"kjv" : "chain"
	},
	"H2738" : {
		"lemma" : "חָרוּל",
		"xlit" : "chârûwl",
		"pron" : "khaw-rool'",
		"derivation" : "or (shortened) חָרֻל; apparently, a passive participle of an unused root probably meaning to be prickly",
		"def" : "properly, pointed, i.e. a bramble or other thorny weed",
		"kjv" : "nettle"
	},
	"H2739" : {
		"lemma" : "חֲרוּמַף",
		"xlit" : "chărûwmaph",
		"pron" : "khar-oo-maf'",
		"derivation" : "from passive participle of H2763 (חָרַם) and H639 (אַף); snub-nosed",
		"def" : "Charumaph, an Israelite",
		"kjv" : "Harumaph"
	},
	"H2740" : {
		"lemma" : "חָרוֹן",
		"xlit" : "chârôwn",
		"pron" : "khaw-rone'",
		"derivation" : "or (shortened) חָרֹן; from H2734 (חָרָה)",
		"def" : "a burning of anger",
		"kjv" : "sore displeasure, fierce(-ness), fury, (fierce) wrath(-ful)"
	},
	"H2741" : {
		"lemma" : "חֲרוּפִי",
		"xlit" : "Chărûwphîy",
		"pron" : "khar-oo-fee'",
		"derivation" : "a patrial from (probably) a collateral form of H2756 (חָרִיף)",
		"def" : "a Charuphite or inhabitant of Charuph (or Chariph)",
		"kjv" : "Haruphite"
	},
	"H2742" : {
		"lemma" : "חֲרוּץ",
		"xlit" : "chărûwts",
		"pron" : "khaw-roots'",
		"derivation" : "or חָרֻץ; passive participle of H2782 (חָרַץ)",
		"def" : "properly, incised or (active) incisive; hence (as noun masculine or feminine) a trench (as dug), gold (as mined), a threshing-sledge (having sharp teeth); (figuratively) determination; also eager",
		"kjv" : "decision, diligent, (fine) gold, pointed things, sharp, threshing instrument, wall"
	},
	"H2743" : {
		"lemma" : "חָרוּץ",
		"xlit" : "Chârûwts",
		"pron" : "khaw-roots'",
		"derivation" : "the same as H2742 (חֲרוּץ); earnest",
		"def" : "Charuts, an Israelite",
		"kjv" : "Haruz"
	},
	"H2744" : {
		"lemma" : "חַרְחוּר",
		"xlit" : "Charchûwr",
		"pron" : "khar-khoor'",
		"derivation" : "a fuller form of H2746 (חַרְחֻר); inflammation",
		"def" : "Charchur, one of the Nethinim",
		"kjv" : "Harhur"
	},
	"H2745" : {
		"lemma" : "חַרְחַס",
		"xlit" : "Charchaç",
		"pron" : "khar-khas'",
		"derivation" : "from the same as H2775 (חֶרֶס); perhaps shining",
		"def" : "Charchas, an Israelite",
		"kjv" : "Harhas"
	},
	"H2746" : {
		"lemma" : "חַרְחֻר",
		"xlit" : "charchur",
		"pron" : "khar-khoor'",
		"derivation" : "from H2787 (חָרַר)",
		"def" : "fever (as hot)",
		"kjv" : "extreme burning"
	},
	"H2747" : {
		"lemma" : "חֶרֶט",
		"xlit" : "chereṭ",
		"pron" : "kheh'-ret",
		"derivation" : "from a primitive root meaning to engrave",
		"def" : "a chisel or graver; also a style forwriting",
		"kjv" : "graving tool, pen"
	},
	"H2748" : {
		"lemma" : "חַרְטֹם",
		"xlit" : "charṭôm",
		"pron" : "khar-tome'",
		"derivation" : "from the same as H2747 (חֶרֶט)",
		"def" : "a horoscopist (as drawing magical lines or circles)",
		"kjv" : "magician"
	},
	"H2749" : {
		"lemma" : "חַרְטֹם",
		"xlit" : "charṭôm",
		"pron" : "khar-tome'",
		"derivation" : "(Aramaic) the same as H2748 (חַרְטֹם)",
		"def" : "{a horoscopist (as drawing magical lines or circles)}",
		"kjv" : "magician"
	},
	"H2750" : {
		"lemma" : "חֳרִי",
		"xlit" : "chŏrîy",
		"pron" : "khor-ee'",
		"derivation" : "from H2734 (חָרָה)",
		"def" : "a burning (i.e. intense) anger",
		"kjv" : "fierce, [idiom] great, heat"
	},
	"H2751" : {
		"lemma" : "חֹרִי",
		"xlit" : "chôrîy",
		"pron" : "kho-ree'",
		"derivation" : "from the same as H2353 (חוּר)",
		"def" : "white bread",
		"kjv" : "white"
	},
	"H2752" : {
		"lemma" : "חֹרִי",
		"xlit" : "Chôrîy",
		"pron" : "kho-ree'",
		"derivation" : "from H2356 (חוֹר); cave-dweller or troglodyte",
		"def" : "a Chorite or aboriginal Idumaean",
		"kjv" : "Horims, Horites"
	},
	"H2753" : {
		"lemma" : "חֹרִי",
		"xlit" : "Chôrîy",
		"pron" : "kho-ree'",
		"derivation" : "or חוֹרִי; the same as H2752 (חֹרִי)",
		"def" : "Chori, the name of two men",
		"kjv" : "Hori"
	},
	"H2754" : {
		"lemma" : "חָרִיט",
		"xlit" : "chârîyṭ",
		"pron" : "khaw-reet'",
		"derivation" : "or חָרִט; from the same as H2747 (חֶרֶט)",
		"def" : "properly, cut out (or hollow), i.e. (by implication) a pocket",
		"kjv" : "bag, crisping pin"
	},
	"H2755" : {
		"lemma" : "חֲרֵי־יוֹנִים",
		"xlit" : "chărêy-yôwnîym",
		"pron" : "khar-ay'-yo-neem'",
		"derivation" : "from the plural of H2716 (חֶרֶא) and the plural of H3123 (יוֹנָה); or perhaps rather the plural of a single word חֲרָאיוֹן, probably a kind of vegetable",
		"def" : "excrements of doves {or a vegetable}",
		"kjv" : "doves' dung"
	},
	"H2756" : {
		"lemma" : "חָרִיף",
		"xlit" : "Chârîyph",
		"pron" : "khaw-reef'",
		"derivation" : "from H2778 (חָרַף); autumnal",
		"def" : "Chariph, the name of two Israelites",
		"kjv" : "Hariph"
	},
	"H2757" : {
		"lemma" : "חָרִיץ",
		"xlit" : "chârîyts",
		"pron" : "khaw-reets'",
		"derivation" : "or חֲרִץ; from H2782 (חָרַץ); (compare H2742 (חֲרוּץ))",
		"def" : "properly, incisure or (passively) incised; hence, a threshing-sledge (with sharp teeth); also a slice (as cut)",
		"kjv" : "[phrase] cheese, harrow"
	},
	"H2758" : {
		"lemma" : "חָרִישׁ",
		"xlit" : "chârîysh",
		"pron" : "khaw-reesh'",
		"derivation" : "from H2790 (חָרַשׁ)",
		"def" : "ploughing or its season",
		"kjv" : "earing (time), ground"
	},
	"H2759" : {
		"lemma" : "חֲרִישִׁי",
		"xlit" : "chărîyshîy",
		"pron" : "khar-ee-shee'",
		"derivation" : "from H2790 (חָרַשׁ) in the sense of silence",
		"def" : "quiet, i.e. sultry (as feminine noun, the sirocco or hot east wind)",
		"kjv" : "vehement"
	},
	"H2760" : {
		"lemma" : "חָרַךְ",
		"xlit" : "chârak",
		"pron" : "khaw-rak'",
		"derivation" : "a primitive root",
		"def" : "to braid (i.e. to entangle or snare) or catch (game) in anet",
		"kjv" : "roast"
	},
	"H2761" : {
		"lemma" : "חֲרַךְ",
		"xlit" : "chărak",
		"pron" : "khar-ak'",
		"derivation" : "(Aramaic) a root probably allied to the equivalent of H2787 (חָרַר)",
		"def" : "to scorch",
		"kjv" : "singe"
	},
	"H2762" : {
		"lemma" : "חֶרֶךְ",
		"xlit" : "cherek",
		"pron" : "kheh'-rek",
		"derivation" : "from H2760 (חָרַךְ)",
		"def" : "properly, a net, i.e. (by analogy) lattice",
		"kjv" : "lattice"
	},
	"H2763" : {
		"lemma" : "חָרַם",
		"xlit" : "châram",
		"pron" : "khaw-ram'",
		"derivation" : "a primitive root",
		"def" : "to seclude; specifically (by a ban) to devote to religious uses (especially destruction); physical and reflexive, to be blunt as to the nose",
		"kjv" : "make accursed, consecrate, (utterly) destroy, devote, forfeit, have a flat nose, utterly (slay, make away)"
	},
	"H2764" : {
		"lemma" : "חֵרֶם",
		"xlit" : "chêrem",
		"pron" : "khay'-rem",
		"derivation" : "or (Zechariah 14:11) חֶרֶם; from H2763 (חָרַם)",
		"def" : "physical (as shutting in) a net (either literally or figuratively); usually a doomed object; abstractly extermination",
		"kjv" : "(ac-) curse(-d, -d thing), dedicated thing, things which should have been utterly destroyed, (appointed to) utter destruction, devoted (thing), net"
	},
	"H2765" : {
		"lemma" : "חֳרֵם",
		"xlit" : "Chŏrêm",
		"pron" : "khor-ame'",
		"derivation" : "from H2763 (חָרַם); devoted",
		"def" : "Chorem, a place in Palestine",
		"kjv" : "Horem"
	},
	"H2766" : {
		"lemma" : "חָרִם",
		"xlit" : "Chârim",
		"pron" : "khaw-reem'",
		"derivation" : "from H2763 (חָרַם); snub-nosed",
		"def" : "Charim, an Israelite",
		"kjv" : "Harim"
	},
	"H2767" : {
		"lemma" : "חׇרְמָה",
		"xlit" : "Chormâh",
		"pron" : "khor-maw'",
		"derivation" : "from H2763 (חָרַם); devoted",
		"def" : "Chormah, a place in Palestine",
		"kjv" : "Hormah"
	},
	"H2768" : {
		"lemma" : "חֶרְמוֹן",
		"xlit" : "Chermôwn",
		"pron" : "kher-mone'",
		"derivation" : "from H2763 (חָרַם); abrupt",
		"def" : "Chermon, a mount of Palestine",
		"kjv" : "Hermon"
	},
	"H2769" : {
		"lemma" : "חֶרְמוֹנִים",
		"xlit" : "Chermôwnîym",
		"pron" : "kher-mo-neem'",
		"derivation" : "plural of H2768 (חֶרְמוֹן)",
		"def" : "Hermons, i.e. its peaks",
		"kjv" : "the Hermonites"
	},
	"H2770" : {
		"lemma" : "חֶרְמֵשׁ",
		"xlit" : "chermêsh",
		"pron" : "kher-mashe'",
		"derivation" : "from H2763 (חָרַם)",
		"def" : "a sickle (as cutting)",
		"kjv" : "sickle"
	},
	"H2771" : {
		"lemma" : "חָרָן",
		"xlit" : "Chârân",
		"pron" : "kaw-rawn'",
		"derivation" : "from H2787 (חָרַר); parched",
		"def" : "Charan, the name of a man and also of a place",
		"kjv" : "Haran"
	},
	"H2772" : {
		"lemma" : "חֹרֹנִי",
		"xlit" : "Chôrônîy",
		"pron" : "kho-ro-nee'",
		"derivation" : "patrial from H2773 (חֹרֹנַיִם)",
		"def" : "a Choronite or inhabitant of Choronaim",
		"kjv" : "Horonite"
	},
	"H2773" : {
		"lemma" : "חֹרֹנַיִם",
		"xlit" : "Chôrônayim",
		"pron" : "kho-ro-nah'-yim",
		"derivation" : "dual of a derivative from H2356 (חוֹר); double cave-town",
		"def" : "Choronajim, a place in Moab",
		"kjv" : "Horonaim"
	},
	"H2774" : {
		"lemma" : "חַרְנֶפֶר",
		"xlit" : "Charnepher",
		"pron" : "khar-neh'fer",
		"derivation" : "of uncertain derivation",
		"def" : "Charnepher, an Israelite",
		"kjv" : "Harnepher"
	},
	"H2775" : {
		"lemma" : "חֶרֶס",
		"xlit" : "chereç",
		"pron" : "kheh'-res",
		"derivation" : "or (with a directive enclitic) חַרְסָה; from an unused root meaning to scrape; also perhaps from the mediating idea of H2777 (חַרְסוּת)",
		"def" : "the itch; the sun",
		"kjv" : "itch, sun"
	},
	"H2776" : {
		"lemma" : "חֶרֶס",
		"xlit" : "Chereç",
		"pron" : "kheh'-res",
		"derivation" : "the same as H2775 (חֶרֶס); shining",
		"def" : "Cheres, a mountain in Palestine",
		"kjv" : "Heres"
	},
	"H2777" : {
		"lemma" : "חַרְסוּת",
		"xlit" : "charçûwth",
		"pron" : "khar-sooth'",
		"derivation" : "from H2775 (חֶרֶס) (apparently in the sense of a red tile used for scraping)",
		"def" : "a potsherd, i.e. (by implication) a pottery; the name of a gate at Jerusalem",
		"kjv" : "east"
	},
	"H2778" : {
		"lemma" : "חָרַף",
		"xlit" : "châraph",
		"pron" : "khaw-raf'",
		"derivation" : "a primitive root; also  denominative (from H2779 (חֹרֶף))",
		"def" : "to pull off, i.e. (by implication) to expose (as by stripping); specifically, to betroth (as if a surrender); figuratively, to carp at, i.e. defame; to spend the winter",
		"kjv" : "betroth, blaspheme, defy, jeopard, rail, reproach, upbraid"
	},
	"H2779" : {
		"lemma" : "חֹרֶף",
		"xlit" : "chôreph",
		"pron" : "kho'-ref",
		"derivation" : "from H2778 (חָרַף)",
		"def" : "properly, the crop gathered, i.e. (by implication) the autumn (and winter) season; figuratively, ripeness of age",
		"kjv" : "cold, winter (-house), youth"
	},
	"H2780" : {
		"lemma" : "חָרֵף",
		"xlit" : "Chârêph",
		"pron" : "khaw-rafe'",
		"derivation" : "from H2778 (חָרַף); reproachful",
		"def" : "Chareph, an Israelite",
		"kjv" : "Hareph"
	},
	"H2781" : {
		"lemma" : "חֶרְפָּה",
		"xlit" : "cherpâh",
		"pron" : "kher-paw'",
		"derivation" : "from H2778 (חָרַף)",
		"def" : "contumely, disgrace, the pudenda",
		"kjv" : "rebuke, reproach(-fully), shame"
	},
	"H2782" : {
		"lemma" : "חָרַץ",
		"xlit" : "chârats",
		"pron" : "khaw-rats'",
		"derivation" : "a primitive root",
		"def" : "properly, to point sharply, i.e. (literally) to wound; figuratively, to be alert, to decide",
		"kjv" : "bestir self, decide, decree, determine, maim, move"
	},
	"H2783" : {
		"lemma" : "חֲרַץ",
		"xlit" : "chărats",
		"pron" : "khar-ats'",
		"derivation" : "(Aramaic) from a root corresponding to H2782 (חָרַץ) in the sense of vigor",
		"def" : "the loin (as the seat of strength)",
		"kjv" : "loin"
	},
	"H2784" : {
		"lemma" : "חַרְצֻבָּה",
		"xlit" : "chartsubbâh",
		"pron" : "khar-tsoob-baw'",
		"derivation" : "of uncertain derivation",
		"def" : "a fetter; figuratively, a pain",
		"kjv" : "band"
	},
	"H2785" : {
		"lemma" : "חַרְצַן",
		"xlit" : "chartsan",
		"pron" : "kchar-tsan'",
		"derivation" : "from H2782 (חָרַץ)",
		"def" : "a sour grape (as sharp in taste)",
		"kjv" : "kernel"
	},
	"H2786" : {
		"lemma" : "חָרַק",
		"xlit" : "châraq",
		"pron" : "khaw-rak'",
		"derivation" : "a primitive root",
		"def" : "to grate the teeth",
		"kjv" : "gnash"
	},
	"H2787" : {
		"lemma" : "חָרַר",
		"xlit" : "chârar",
		"pron" : "khaw-rar'",
		"derivation" : "a primitive root",
		"def" : "to glow, i.e. literally (to melt, burn, dry up) or figuratively (to show or incite passion)",
		"kjv" : "be angry, burn, dry, kindle"
	},
	"H2788" : {
		"lemma" : "חָרֵר",
		"xlit" : "chârêr",
		"pron" : "khaw-rare'",
		"derivation" : "from H2787 (חָרַר)",
		"def" : "arid",
		"kjv" : "parched place"
	},
	"H2789" : {
		"lemma" : "חֶרֶשׂ",
		"xlit" : "cheres",
		"pron" : "kheh'-res",
		"derivation" : "a collateral form mediating between H2775 (חֶרֶס) and H2791 (חֶרֶשׁ)",
		"def" : "a piece of pottery",
		"kjv" : "earth(-en), (pot-) sherd, [phrase] stone"
	},
	"H2790" : {
		"lemma" : "חָרַשׁ",
		"xlit" : "chârash",
		"pron" : "khaw-rash'",
		"derivation" : "a primitive root",
		"def" : "to scratch, i.e. (by implication) to engrave, plough; hence (from the use of tools) to fabricate (of any material); figuratively, to devise (in a bad sense); hence (from the idea of secrecy) to be silent, to let alone; hence (by implication) to be deaf (as an accompaniment of dumbness)",
		"kjv" : "[idiom] altogether, cease, conceal, be deaf, devise, ear, graven, imagine, leave off speaking, hold peace, plow(-er, man), be quiet, rest, practise secretly, keep silence, be silent, speak not a word, be still, hold tongue, worker"
	},
	"H2791" : {
		"lemma" : "חֶרֶשׁ",
		"xlit" : "cheresh",
		"pron" : "kheh'-resh",
		"derivation" : "from H2790 (חָרַשׁ)",
		"def" : "magical craft; also silence",
		"kjv" : "cunning, secretly"
	},
	"H2792" : {
		"lemma" : "חֶרֶשׁ",
		"xlit" : "Cheresh",
		"pron" : "kheh'-resh",
		"derivation" : "the same as H2791 (חֶרֶשׁ)",
		"def" : "Cheresh, a Levite",
		"kjv" : "Heresh"
	},
	"H2793" : {
		"lemma" : "חֹרֶשׁ",
		"xlit" : "chôresh",
		"pron" : "kho'-resh",
		"derivation" : "from H2790 (חָרַשׁ)",
		"def" : "a forest (perhaps as furnishing the material for fabric)",
		"kjv" : "bough, forest, shroud, wood"
	},
	"H2794" : {
		"lemma" : "חֹרֵשׁ",
		"xlit" : "chôrêsh",
		"pron" : "kho-rashe'",
		"derivation" : "active participle of H2790 (חָרַשׁ)",
		"def" : "a fabricator or mechanic",
		"kjv" : "artificer"
	},
	"H2795" : {
		"lemma" : "חֵרֵשׁ",
		"xlit" : "chêrêsh",
		"pron" : "khay-rashe'",
		"derivation" : "from H2790 (חָרַשׁ)",
		"def" : "deaf (whether literally or spiritual)",
		"kjv" : "deaf"
	},
	"H2796" : {
		"lemma" : "חָרָשׁ",
		"xlit" : "chârâsh",
		"pron" : "khaw-rawsh'",
		"derivation" : "from H2790 (חָרַשׁ)",
		"def" : "a fabricator or any material",
		"kjv" : "artificer, ([phrase]) carpenter, craftsman, engraver, maker, [phrase] mason, skilful, ([phrase]) smith, worker, workman, such as wrought"
	},
	"H2797" : {
		"lemma" : "חַרְשָׁא",
		"xlit" : "Charshâʼ",
		"pron" : "khar-shaw'",
		"derivation" : "from H2792 (חֶרֶשׁ); magician",
		"def" : "Charsha, one of the Nethinim",
		"kjv" : "Harsha"
	},
	"H2798" : {
		"lemma" : "חֲרָשִׁים",
		"xlit" : "Chărâshîym",
		"pron" : "khar-aw-sheem'",
		"derivation" : "plural of H2796 (חָרָשׁ)",
		"def" : "mechanics, the name of a valley in Jerusalem",
		"kjv" : "Charashim, craftsmen"
	},
	"H2799" : {
		"lemma" : "חֲרֹשֶׁת",
		"xlit" : "chărôsheth",
		"pron" : "khar-o'-sheth",
		"derivation" : "from H2790 (חָרַשׁ)",
		"def" : "mechanical work",
		"kjv" : "carving, cutting"
	},
	"H2800" : {
		"lemma" : "חֲרֹשֶׁת",
		"xlit" : "Chărôsheth",
		"pron" : "khar-o'-sheth",
		"derivation" : "the same as H2799 (חֲרֹשֶׁת)",
		"def" : "Charosheth, a place in Palestine",
		"kjv" : "Harosheth"
	},
	"H2801" : {
		"lemma" : "חָרַת",
		"xlit" : "chârath",
		"pron" : "khaw-rath'",
		"derivation" : "a primitive root",
		"def" : "to engrave",
		"kjv" : "graven"
	},
	"H2802" : {
		"lemma" : "חֶרֶת",
		"xlit" : "Chereth",
		"pron" : "kheh'-reth",
		"derivation" : "from H2801 (חָרַת) (but equivalent to H2793 (חֹרֶשׁ)); forest",
		"def" : "Chereth, a thicket in Palestine",
		"kjv" : "Hereth"
	},
	"H2803" : {
		"lemma" : "חָשַׁב",
		"xlit" : "châshab",
		"pron" : "khaw-shab'",
		"derivation" : "a primitive root",
		"def" : "properly, to plait or interpenetrate, i.e. (literally) to weave or (generally) to fabricate; figuratively, to plot or contrive (usually in a malicious sense); hence (from the mental effort) to think, regard, value, compute",
		"kjv" : "(make) account (of), conceive, consider, count, cunning (man, work, workman), devise, esteem, find out, forecast, hold, imagine, impute, invent, be like, mean, purpose, reckon(-ing be made), regard, think"
	},
	"H2804" : {
		"lemma" : "חֲשַׁב",
		"xlit" : "chăshab",
		"pron" : "khash-ab'",
		"derivation" : "(Aramaic) corresponding to H2803 (חָשַׁב)",
		"def" : "to regard",
		"kjv" : "repute"
	},
	"H2805" : {
		"lemma" : "חֵשֶׁב",
		"xlit" : "chêsheb",
		"pron" : "khay'-sheb",
		"derivation" : "from H2803 (חָשַׁב)",
		"def" : "a belt or strap (as being interlaced)",
		"kjv" : "curious girdle"
	},
	"H2806" : {
		"lemma" : "חַשְׁבַּדָּנָה",
		"xlit" : "Chashbaddânâh",
		"pron" : "khash-bad-daw'-naw",
		"derivation" : "from H2803 (חָשַׁב) and H1777 (דִּין); considerate judge",
		"def" : "Chasbaddanah, an Israelite",
		"kjv" : "Hasbadana"
	},
	"H2807" : {
		"lemma" : "חֲשֻׁבָה",
		"xlit" : "Chăshubâh",
		"pron" : "khash-oo-baw'",
		"derivation" : "from H2803 (חָשַׁב); estimation",
		"def" : "Cashubah, an Israelite",
		"kjv" : "Hashubah"
	},
	"H2808" : {
		"lemma" : "חֶשְׁבּוֹן",
		"xlit" : "cheshbôwn",
		"pron" : "khesh-bone'",
		"derivation" : "from H2803 (חָשַׁב)",
		"def" : "properly, contrivance; by implication, intelligence",
		"kjv" : "account, device, reason"
	},
	"H2809" : {
		"lemma" : "חֶשְׁבּוֹן",
		"xlit" : "Cheshbôwn",
		"pron" : "khesh-bone'",
		"derivation" : "the same as H2808 (חֶשְׁבּוֹן)",
		"def" : "Cheshbon, a place East of the Jordan",
		"kjv" : "Heshbon"
	},
	"H2810" : {
		"lemma" : "חִשָּׁבוֹן",
		"xlit" : "chishshâbôwn",
		"pron" : "khish-shaw-bone'",
		"derivation" : "from H2803 (חָשַׁב)",
		"def" : "a contrivance, i.e. actual (a warlike machine) or mental (a machination)",
		"kjv" : "engine, invention"
	},
	"H2811" : {
		"lemma" : "חֲשַׁבְיָה",
		"xlit" : "Chăshabyâh",
		"pron" : "khash-ab-yaw'",
		"derivation" : "or חֲשַׁבְיָהוּ; from H2803 (חָשַׁב) and H3050 (יָהּ); Jah has regarded",
		"def" : "Chashabjah, the name of nine Israelites",
		"kjv" : "Hashabiah"
	},
	"H2812" : {
		"lemma" : "חֲשַׁבְנָה",
		"xlit" : "Chăshabnâh",
		"pron" : "khash-ab-naw'",
		"derivation" : "feminine of H2808 (חֶשְׁבּוֹן); inventiveness",
		"def" : "Chashnah, an Israelite",
		"kjv" : "Hashabnah"
	},
	"H2813" : {
		"lemma" : "חֲשַׁבְנְיָה",
		"xlit" : "Chăshabnᵉyâh",
		"pron" : "khash-ab-neh-yaw'",
		"derivation" : "from H2808 (חֶשְׁבּוֹן) and H3050 (יָהּ); thought of Jah",
		"def" : "Chashabnejah, the name of two Israelites",
		"kjv" : "Hashabniah"
	},
	"H2814" : {
		"lemma" : "חָשָׁה",
		"xlit" : "châshâh",
		"pron" : "khaw-shaw'",
		"derivation" : "a primitive root",
		"def" : "to hush or keep quiet",
		"kjv" : "hold peace, keep silence, be silent, (be) still"
	},
	"H2815" : {
		"lemma" : "חַשּׁוּב",
		"xlit" : "Chashshûwb",
		"pron" : "khash-shoob'",
		"derivation" : "from H2803 (חָשַׁב); intelligent",
		"def" : "Chashshub, the name of two or three Israelites",
		"kjv" : "Hashub, Hasshub"
	},
	"H2816" : {
		"lemma" : "חֲשׁוֹךְ",
		"xlit" : "chăshôwk",
		"pron" : "khash-oke'",
		"derivation" : "(Aramaic) from a root corresponding to H2821 (חָשַׁךְ)",
		"def" : "the dark",
		"kjv" : "darkness"
	},
	"H2817" : {
		"lemma" : "חֲשׂוּפָא",
		"xlit" : "Chăsûwphâʼ",
		"pron" : "khas-oo-faw'",
		"derivation" : "or חֲשֻׂפָא; from H2834 (חָשַׂף); nakedness",
		"def" : "Chasupha, one of the Nethinim",
		"kjv" : "Hashupha, Hasupha"
	},
	"H2818" : {
		"lemma" : "חֲשַׁח",
		"xlit" : "chăshach",
		"pron" : "khash-akh'",
		"derivation" : "(Aramaic) a collateral root to one corresponding to H2363 (חוּשׁ) in the sense of readiness",
		"def" : "to be necessary (from the idea of convenience) or (transitively) to need",
		"kjv" : "careful, have need of"
	},
	"H2819" : {
		"lemma" : "חַשְׁחוּת",
		"xlit" : "chashchûwth",
		"pron" : "khash-khooth'",
		"derivation" : "from a root corresponding to H2818 (חֲשַׁח)",
		"def" : "necessity",
		"kjv" : "be needful"
	},
	"H2820" : {
		"lemma" : "חָשַׂךְ",
		"xlit" : "châsak",
		"pron" : "khaw-sak'",
		"derivation" : "a primitive root; also interchangeable  with H2821 (חָשַׁךְ)",
		"def" : "to restrain or (reflex.) refrain; by implication, to refuse, spare, preserve; to observe",
		"kjv" : "assuage, [idiom] darken, forbear, hinder, hold back, keep (back), punish, refrain, reserve, spare, withhold"
	},
	"H2821" : {
		"lemma" : "חָשַׁךְ",
		"xlit" : "châshak",
		"pron" : "khaw-shak'",
		"derivation" : "a primitive root",
		"def" : "to be dark (as withholding light); transitively, to darken",
		"kjv" : "be black, be (make) dark, darken, cause darkness, be dim, hide"
	},
	"H2822" : {
		"lemma" : "חֹשֶׁךְ",
		"xlit" : "chôshek",
		"pron" : "kho-shek'",
		"derivation" : "from H2821 (חָשַׁךְ)",
		"def" : "the dark; hence (literally) darkness; figuratively, misery, destruction, death, ignorance, sorrow, wickedness",
		"kjv" : "dark(-ness), night, obscurity"
	},
	"H2823" : {
		"lemma" : "חָשֹׁךְ",
		"xlit" : "châshôk",
		"pron" : "khaw-shoke'",
		"derivation" : "from H2821 (חָשַׁךְ)",
		"def" : "dark (figuratively, i.e. obscure)",
		"kjv" : "meaning"
	},
	"H2824" : {
		"lemma" : "חֶשְׁכָה",
		"xlit" : "cheshkâh",
		"pron" : "khesh-kaw'",
		"derivation" : "from H2821 (חָשַׁךְ)",
		"def" : "darkness",
		"kjv" : "dark"
	},
	"H2825" : {
		"lemma" : "חֲשֵׁכָה",
		"xlit" : "chăshêkâh",
		"pron" : "khash-ay-kaw'",
		"derivation" : "or חֲשֵׁיכָהxlit chăshêkâh corrected to chăshêykâh; from H2821 (חָשַׁךְ)",
		"def" : "darkness; figuratively, misery",
		"kjv" : "darkness"
	},
	"H2826" : {
		"lemma" : "חָשַׁל",
		"xlit" : "châshal",
		"pron" : "khaw-shal'",
		"derivation" : "a primitive root",
		"def" : "to make (intrans. be) unsteady, i.e. weak",
		"kjv" : "feeble"
	},
	"H2827" : {
		"lemma" : "חֲשַׁל",
		"xlit" : "chăshal",
		"pron" : "khash-al'",
		"derivation" : "(Aramaic) a root corresponding to H2826 (חָשַׁל)",
		"def" : "to weaken, i.e. crush",
		"kjv" : "subdue"
	},
	"H2828" : {
		"lemma" : "חָשֻׁם",
		"xlit" : "Châshum",
		"pron" : "khaw-shoom'",
		"derivation" : "from the same as H2831 (חַשְׁמַן); enriched",
		"def" : "Chashum, the name of two or three Israelites",
		"kjv" : "Hashum"
	},
	"H2829" : {
		"lemma" : "חֶשְׁמוֹן",
		"xlit" : "Cheshmôwn",
		"pron" : "klesh-mone'",
		"derivation" : "the same as H2831 (חַשְׁמַן); opulent",
		"def" : "Cheshmon, a place in Palestine",
		"kjv" : "Heshmon"
	},
	"H2830" : {
		"lemma" : "חַשְׁמַל",
		"xlit" : "chashmal",
		"pron" : "khash-mal'",
		"derivation" : "of uncertain derivation",
		"def" : "probably bronze or polished spectrum metal",
		"kjv" : "amber"
	},
	"H2831" : {
		"lemma" : "חַשְׁמַן",
		"xlit" : "chashman",
		"pron" : "khash-man'",
		"derivation" : "from an unused root (probably meaning firm or capacious in resources); apparently",
		"def" : "wealthy",
		"kjv" : "princes"
	},
	"H2832" : {
		"lemma" : "חַשְׁמֹנָה",
		"xlit" : "Chashmônâh",
		"pron" : "khash-mo-naw'",
		"derivation" : "feminine of H2831 (חַשְׁמַן); fertile",
		"def" : "Chasmonah, a place in the Desert",
		"kjv" : "Hashmonah"
	},
	"H2833" : {
		"lemma" : "חֹשֶׁן",
		"xlit" : "chôshen",
		"pron" : "kho'-shen",
		"derivation" : "from an unused root probably meaning to contain or sparkle",
		"def" : "perhaps a pocket (as holding the Urim and Thummim), or rich (as containing gems), used only of the gorget of the highpriest",
		"kjv" : "breastplate"
	},
	"H2834" : {
		"lemma" : "חָשַׂף",
		"xlit" : "châsaph",
		"pron" : "khaw-saf'",
		"derivation" : "a primitive root",
		"def" : "to strip off, i.e. generally to make naked (for exertion or in disgrace), to drain away or bail up (a liquid)",
		"kjv" : "make bare, clean, discover, draw out, take, uncover"
	},
	"H2835" : {
		"lemma" : "חָשִׂף",
		"xlit" : "châsiph",
		"pron" : "khaw-seef'",
		"derivation" : "from H2834 (חָשַׂף)",
		"def" : "properly, drawn off, i.e. separated; hence, a small company (as divided from the rest)",
		"kjv" : "little flock"
	},
	"H2836" : {
		"lemma" : "חָשַׁק",
		"xlit" : "châshaq",
		"pron" : "khaw-shak'",
		"derivation" : "a primitive root; also interchangeable for H2820 (חָשַׂךְ)",
		"def" : "to cling, i.e. join, (figuratively) to love, delight in; elliptically; to deliver",
		"kjv" : "have a delight, (have a) desire, fillet, long, set (in) love"
	},
	"H2837" : {
		"lemma" : "חֵשֶׁק",
		"xlit" : "chêsheq",
		"pron" : "khay'-shek",
		"derivation" : "from H2836 (חָשַׁק)",
		"def" : "delight",
		"kjv" : "desire, pleasure"
	},
	"H2838" : {
		"lemma" : "חָשֻׁק",
		"xlit" : "châshuq",
		"pron" : "khaw-shook'",
		"derivation" : "or חָשׁוּק; past participle of H2836 (חָשַׁק)",
		"def" : "attached, i.e. a fence-rail or rod connecting the posts or pillars",
		"kjv" : "fillet"
	},
	"H2839" : {
		"lemma" : "חִשֻּׁק",
		"xlit" : "chishshuq",
		"pron" : "khish-shook'",
		"derivation" : "from H2836 (חָשַׁק)",
		"def" : "conjoined, i.e. a wheel-spoke or rod connecting the hub with the rim",
		"kjv" : "felloe"
	},
	"H2840" : {
		"lemma" : "חִשֻּׁר",
		"xlit" : "chishshur",
		"pron" : "khish-shoor'",
		"derivation" : "from an unused root meaning to bind together",
		"def" : "combined, i.e. the nave or hub of awheel (as holding the spokes together)",
		"kjv" : "spoke"
	},
	"H2841" : {
		"lemma" : "חַשְׁרָה",
		"xlit" : "chashrâh",
		"pron" : "khash-raw'",
		"derivation" : "from the same as H2840 (חִשֻּׁר)",
		"def" : "properly, a combination or gathering, i.e. of watery clouds",
		"kjv" : "dark"
	},
	"H2842" : {
		"lemma" : "חָשַׁשׁ",
		"xlit" : "châshash",
		"pron" : "khaw-shash'",
		"derivation" : "by variation for H7179 (קַשׁ)",
		"def" : "dry grass",
		"kjv" : "chaff"
	},
	"H2843" : {
		"lemma" : "חֻשָׁתִי",
		"xlit" : "Chushâthîy",
		"pron" : "khoo-shaw-thee'",
		"derivation" : "patronymically from H2364 (חוּשָׁה)",
		"def" : "a Chushathite or descendant of Chushah",
		"kjv" : "Hushathite"
	},
	"H2844" : {
		"lemma" : "חַת",
		"xlit" : "chath",
		"pron" : "khath",
		"derivation" : "from H2865 (חָתַת)",
		"def" : "concretely, crushed; also afraid; abstractly, terror",
		"kjv" : "broken, dismayed, dread, fear"
	},
	"H2845" : {
		"lemma" : "חֵת",
		"xlit" : "Chêth",
		"pron" : "khayth",
		"derivation" : "from H2865 (חָתַת); terror",
		"def" : "Cheth, an aboriginal Canaanite",
		"kjv" : "Heth"
	},
	"H2846" : {
		"lemma" : "חָתָה",
		"xlit" : "châthâh",
		"pron" : "khaw-thaw'",
		"derivation" : "a primitive root",
		"def" : "to lay hold of; especially to pick up fire",
		"kjv" : "heap, take (away)"
	},
	"H2847" : {
		"lemma" : "חִתָּה",
		"xlit" : "chittâh",
		"pron" : "khit-taw'",
		"derivation" : "from H2865 (חָתַת)",
		"def" : "fear",
		"kjv" : "terror"
	},
	"H2848" : {
		"lemma" : "חִתּוּל",
		"xlit" : "chittûwl",
		"pron" : "khit-tool'",
		"derivation" : "from H2853 (חָתַל)",
		"def" : "swathed, i.e. a bandage",
		"kjv" : "roller"
	},
	"H2849" : {
		"lemma" : "חַתְחַת",
		"xlit" : "chathchath",
		"pron" : "khath-khath'",
		"derivation" : "from H2844 (חַת)",
		"def" : "terror",
		"kjv" : "fear"
	},
	"H2850" : {
		"lemma" : "חִתִּי",
		"xlit" : "Chittîy",
		"pron" : "khit-tee'",
		"derivation" : "patronymically from H2845 (חֵת)",
		"def" : "a Chittite, or descendant of Cheth",
		"kjv" : "Hittite, Hittities"
	},
	"H2851" : {
		"lemma" : "חִתִּית",
		"xlit" : "chittîyth",
		"pron" : "khit-teeth'",
		"derivation" : "from H2865 (חָתַת)",
		"def" : "fear",
		"kjv" : "terror"
	},
	"H2852" : {
		"lemma" : "חָתַךְ",
		"xlit" : "châthak",
		"pron" : "khaw-thak'",
		"derivation" : "a primitive root",
		"def" : "properly, to cut off, i.e. (figuratively) to decree",
		"kjv" : "determine"
	},
	"H2853" : {
		"lemma" : "חָתַל",
		"xlit" : "châthal",
		"pron" : "khaw-thal'",
		"derivation" : "a primitive root",
		"def" : "to swathe",
		"kjv" : "[idiom] at all, swaddle"
	},
	"H2854" : {
		"lemma" : "חֲתֻלָּה",
		"xlit" : "chăthullâh",
		"pron" : "khath-ool-law'",
		"derivation" : "from H2853 (חָתַל)",
		"def" : "a swathing cloth (figuratively)",
		"kjv" : "swaddling band"
	},
	"H2855" : {
		"lemma" : "חֶתְלֹן",
		"xlit" : "Chethlôn",
		"pron" : "kheth-lone'",
		"derivation" : "from H2853 (חָתַל); enswathed",
		"def" : "Chethlon, a place in Palestine",
		"kjv" : "Hethlon"
	},
	"H2856" : {
		"lemma" : "חָתַם",
		"xlit" : "châtham",
		"pron" : "khaw-tham'",
		"derivation" : "a primitive root",
		"def" : "to close up; especially to seal",
		"kjv" : "make an end, mark, seal (up), stop"
	},
	"H2857" : {
		"lemma" : "חֲתַם",
		"xlit" : "chătham",
		"pron" : "khath-am'",
		"derivation" : "(Aramaic) a root corresponding to H2856 (חָתַם)",
		"def" : "to seal",
		"kjv" : "seal"
	},
	"H2858" : {
		"lemma" : "חֹתֶמֶת",
		"xlit" : "chôthemeth",
		"pron" : "kho-the-meth",
		"derivation" : "feminine active participle of H2856 (חָתַם)",
		"def" : "a seal",
		"kjv" : "signet"
	},
	"H2859" : {
		"lemma" : "חָתַן",
		"xlit" : "châthan",
		"pron" : "khaw-than'",
		"derivation" : "a primitive root",
		"def" : "to give (a daughter) away in marriage; hence (generally) to contract affinity by marriage",
		"kjv" : "join in affinity, father in law, make marriages, mother in law, son in law"
	},
	"H2860" : {
		"lemma" : "חָתָן",
		"xlit" : "châthân",
		"pron" : "khaw-thawn'",
		"derivation" : "from H2859 (חָתַן)",
		"def" : "a relative by marriage (especially through the bride); figuratively, a circumcised child (as a species of religious espousal)",
		"kjv" : "bridegroom, husband, son in law"
	},
	"H2861" : {
		"lemma" : "חֲתֻנָּה",
		"xlit" : "chăthunnâh",
		"pron" : "khath-oon-naw'",
		"derivation" : "from H2859 (חָתַן)",
		"def" : "a wedding",
		"kjv" : "espousal"
	},
	"H2862" : {
		"lemma" : "חָתַף",
		"xlit" : "châthaph",
		"pron" : "khaw-thaf'",
		"derivation" : "a primitive root",
		"def" : "to clutch",
		"kjv" : "take away"
	},
	"H2863" : {
		"lemma" : "חֶתֶף",
		"xlit" : "chetheph",
		"pron" : "kheh'-thef",
		"derivation" : "from H2862 (חָתַף)",
		"def" : "properly, rapine; figuratively, robbery",
		"kjv" : "prey"
	},
	"H2864" : {
		"lemma" : "חָתַר",
		"xlit" : "châthar",
		"pron" : "khaw-thar'",
		"derivation" : "a primitive root",
		"def" : "to force a passage, as by burglary; figuratively, with oars",
		"kjv" : "dig (through), row"
	},
	"H2865" : {
		"lemma" : "חָתַת",
		"xlit" : "châthath",
		"pron" : "khaw-thath'",
		"derivation" : "a primitive root",
		"def" : "properly, to prostrate; hence, to break down, either (literally) by violence, or (figuratively) by confusion and fear",
		"kjv" : "abolish, affright, be (make) afraid, amaze, beat down, discourage, (cause to) dismay, go down, scare, terrify"
	},
	"H2866" : {
		"lemma" : "חֲתַת",
		"xlit" : "chăthath",
		"pron" : "khath-ath'",
		"derivation" : "from H2865 (חָתַת)",
		"def" : "dismay",
		"kjv" : "casting down"
	},
	"H2867" : {
		"lemma" : "חֲתַת",
		"xlit" : "Chăthath",
		"pron" : "khath-ath'",
		"derivation" : "the same as H2866 (חֲתַת)",
		"def" : "Chathath, an Israelite",
		"kjv" : "cathath. f"
	},
	"H2868" : {
		"lemma" : "טְאֵב",
		"xlit" : "ṭᵉʼêb",
		"pron" : "teh-abe'",
		"derivation" : "a primitive root",
		"def" : "to rejoice",
		"kjv" : "be glad"
	},
	"H2869" : {
		"lemma" : "טָב",
		"xlit" : "ṭâb",
		"pron" : "teh-abe'",
		"derivation" : "the same as H2896 (טוֹב)",
		"def" : "good",
		"kjv" : "fine, good"
	},
	"H2870" : {
		"lemma" : "טָבְאֵל",
		"xlit" : "ṭâbᵉʼêl",
		"pron" : "taw-beh-ale'",
		"derivation" : "from H2895 (טוֹב) and H410 (אֵל); pleasing (to) God",
		"def" : "Tabeel, the name of a Syrian and of a Persian",
		"kjv" : "Tabeal, Tabeel"
	},
	"H2871" : {
		"lemma" : "טָבוּל",
		"xlit" : "ṭâbûwl",
		"pron" : "taw-bool'",
		"derivation" : "passive participle of H2881 (טָבַל)",
		"def" : "properly, dyed, i.e. a turban (probably as of colored stuff)",
		"kjv" : "dyed attire"
	},
	"H2872" : {
		"lemma" : "טַבּוּר",
		"xlit" : "ṭabbûwr",
		"pron" : "tab-boor'",
		"derivation" : "from an unused root meaning to pile up",
		"def" : "properly, accumulated; i.e. (by implication) a summit",
		"kjv" : "middle, midst"
	},
	"H2873" : {
		"lemma" : "טָבַח",
		"xlit" : "ṭâbach",
		"pron" : "taw-bakh'",
		"derivation" : "a primitive root",
		"def" : "to slaughter (animals or men)",
		"kjv" : "kill, (make) slaughter, slay"
	},
	"H2874" : {
		"lemma" : "טֶבַח",
		"xlit" : "ṭebach",
		"pron" : "teh'-bakh",
		"derivation" : "from H2873 (טָבַח)",
		"def" : "properly, something slaughtered; hence, a beast (or meat, as butchered); abstractly butchery (or concretely, a place of slaughter)",
		"kjv" : "[idiom] beast, slaughter, [idiom] slay, [idiom] sore"
	},
	"H2875" : {
		"lemma" : "טֶבַח",
		"xlit" : "Ṭebach",
		"pron" : "teh'-bakh",
		"derivation" : "the same as H2874 (טֶבַח); massacre",
		"def" : "Tebach, the name of a Mesopotamian and of an Israelite",
		"kjv" : "Tebah"
	},
	"H2876" : {
		"lemma" : "טַבָּח",
		"xlit" : "ṭabbâch",
		"pron" : "tab-bawkh'",
		"derivation" : "from H2873 (טָבַח)",
		"def" : "properly, a butcher; hence, a lifeguardsman (because he was acting as an executioner); also a cook (usually slaughtering the animal for food)",
		"kjv" : "cook, guard"
	},
	"H2877" : {
		"lemma" : "טַבָּח",
		"xlit" : "ṭabbâch",
		"pron" : "tab-bawkh'",
		"derivation" : "(Aramaic) the same as H2876 (טַבָּח)",
		"def" : "a lifeguardsman",
		"kjv" : "guard"
	},
	"H2878" : {
		"lemma" : "טִבְחָה",
		"xlit" : "ṭibchâh",
		"pron" : "tib-khaw'",
		"derivation" : "feminine of H2874 (טֶבַח) and meaning the same",
		"def" : "{properly, something slaughtered; hence, a beast (or meat, as butchered); abstractly butchery (or concretely, a place of slaughter)}",
		"kjv" : "flesh, slaughter"
	},
	"H2879" : {
		"lemma" : "טַבָּחָה",
		"xlit" : "ṭabbâchâh",
		"pron" : "tab-baw-khaw'",
		"derivation" : "feminine of H2876 (טַבָּח)",
		"def" : "a female cook",
		"kjv" : "cook"
	},
	"H2880" : {
		"lemma" : "טִבְחַת",
		"xlit" : "Ṭibchath",
		"pron" : "tib-khath'",
		"derivation" : "from H2878 (טִבְחָה); slaughter",
		"def" : "Tibchath, a place in Syria",
		"kjv" : "Tibhath"
	},
	"H2881" : {
		"lemma" : "טָבַל",
		"xlit" : "ṭâbal",
		"pron" : "taw-bal'",
		"derivation" : "a primitive root",
		"def" : "to dip, to immerse",
		"kjv" : "dip, plunge"
	},
	"H2882" : {
		"lemma" : "טְבַלְיָהוּ",
		"xlit" : "Ṭᵉbalyâhûw",
		"pron" : "teb-al-yaw'-hoo",
		"derivation" : "from H2881 (טָבַל) and H3050 (יָהּ); Jah has dipped",
		"def" : "Tebaljah, an Israelite",
		"kjv" : "Tebaliah"
	},
	"H2883" : {
		"lemma" : "טָבַע",
		"xlit" : "ṭâbaʻ",
		"pron" : "taw-bah'",
		"derivation" : "a primitive root",
		"def" : "to sink",
		"kjv" : "drown, fasten, settle, sink"
	},
	"H2884" : {
		"lemma" : "טַבָּעוֹת",
		"xlit" : "Ṭabbâʻôwth",
		"pron" : "tab-baw-othe'",
		"derivation" : "plural of H2885 (טַבַּעַת); rings",
		"def" : "Tabbaoth, one of the Nethinim",
		"kjv" : "Tabaoth"
	},
	"H2885" : {
		"lemma" : "טַבַּעַת",
		"xlit" : "ṭabbaʻath",
		"pron" : "tab-bah'-ath",
		"derivation" : "from H2883 (טָבַע)",
		"def" : "properly, a seal (as sunk into the wax), i.e. signet (for sealing); hence (generally) a ring of any kind",
		"kjv" : "ring"
	},
	"H2886" : {
		"lemma" : "טַבְרִמּוֹן",
		"xlit" : "Ṭabrimmôwn",
		"pron" : "tab-rim-mone'",
		"derivation" : "from H2895 (טוֹב) and H7417 (רִמּוֹן); pleasing (to) Rimmon",
		"def" : "Tabrimmon, a Syrian",
		"kjv" : "Tabrimmon"
	},
	"H2887" : {
		"lemma" : "טֵבֶת",
		"xlit" : "Ṭêbeth",
		"pron" : "tay'-beth",
		"derivation" : "probably of foreign derivation",
		"def" : "Tebeth, the tenth Hebrew month",
		"kjv" : "Tebeth"
	},
	"H2888" : {
		"lemma" : "טַבַּת",
		"xlit" : "Ṭabbath",
		"pron" : "tab-bath'",
		"derivation" : "of uncertain derivation",
		"def" : "Tabbath, a place East of the Jordan",
		"kjv" : "Tabbath"
	},
	"H2889" : {
		"lemma" : "טָהוֹר",
		"xlit" : "ṭâhôwr",
		"pron" : "taw-hore'",
		"derivation" : "or טָהֹר; from H2891 (טָהֵר)",
		"def" : "pure (in a physical, chemical, ceremonial or moral sense)",
		"kjv" : "clean, fair, pure(-ness)"
	},
	"H2890" : {
		"lemma" : "טְהוֹר",
		"xlit" : "ṭᵉhôwr",
		"pron" : "teh-hore'",
		"derivation" : "from H2891 (טָהֵר)",
		"def" : "purity",
		"kjv" : "pureness"
	},
	"H2891" : {
		"lemma" : "טָהֵר",
		"xlit" : "ṭâhêr",
		"pron" : "taw-hare'",
		"derivation" : "a primitive root; properly, to be bright; i.e. (by implication)",
		"def" : "to be pure (physical sound, clear, unadulterated; Levitically, uncontaminated; morally, innocent or holy)",
		"kjv" : "be (make, make self, pronounce) clean, cleanse (self), purge, purify(-ier, self)"
	},
	"H2892" : {
		"lemma" : "טֹהַר",
		"xlit" : "ṭôhar",
		"pron" : "to'-har",
		"derivation" : "from H2891 (טָהֵר)",
		"def" : "literally brightness; ceremonially purification",
		"kjv" : "clearness, glory, purifying"
	},
	"H2893" : {
		"lemma" : "טׇהֳרָה",
		"xlit" : "ṭohŏrâh",
		"pron" : "toh-or-aw'",
		"derivation" : "feminine of H2892 (טֹהַר)",
		"def" : "ceremonial purification; moral purity",
		"kjv" : "[idiom] is cleansed, cleansing, purification(-fying)"
	},
	"H2894" : {
		"lemma" : "טוּא",
		"xlit" : "ṭûwʼ",
		"pron" : "too",
		"derivation" : "a primitive root",
		"def" : "to sweep away",
		"kjv" : "sweep"
	},
	"H2895" : {
		"lemma" : "טוֹב",
		"xlit" : "ṭôwb",
		"pron" : "tobe",
		"derivation" : "a primitive root",
		"def" : "to be (transitively, do or make) good (or well) in the widest sense",
		"kjv" : "be (do) better, cheer, be (do, seem) good, (make) goodly, [idiom] please, (be, do, go, play) well"
	},
	"H2896" : {
		"lemma" : "טוֹב",
		"xlit" : "ṭôwb",
		"pron" : "tobe",
		"derivation" : "from H2895 (טוֹב)",
		"def" : "good (as an adjective) in the widest sense; used likewise as a noun, both in the masculine and the feminine, the singular and the plural (good, a good or good thing, a good man or woman; the good, goods or good things, good men or women), also as an adverb (well)",
		"kjv" : "beautiful, best, better, bountiful, cheerful, at ease, [idiom] fair (word), (be in) favour, fine, glad, good (deed, -lier, -liest, -ly, -ness, -s), graciously, joyful, kindly, kindness, liketh (best), loving, merry, [idiom] most, pleasant, [phrase] pleaseth, pleasure, precious, prosperity, ready, sweet, wealth, welfare, (be) well(-favoured)"
	},
	"H2897" : {
		"lemma" : "טוֹב",
		"xlit" : "Ṭôwb",
		"pron" : "tobe",
		"derivation" : "the same as H2896 (טוֹב); good",
		"def" : "Tob, a region apparently East of the Jordan",
		"kjv" : "Tob"
	},
	"H2898" : {
		"lemma" : "טוּב",
		"xlit" : "ṭûwb",
		"pron" : "toob",
		"derivation" : "from H2895 (טוֹב)",
		"def" : "good (as a noun), in the widest sense, especially goodness (superlative concretely, the best), beauty, gladness, welfare",
		"kjv" : "fair, gladness, good(-ness, thing, -s), joy, go well with"
	},
	"H2899" : {
		"lemma" : "טוֹב אֲדֹנִיָּהוּ",
		"xlit" : "Ṭôwb ʼĂdônîyâhûw",
		"pron" : "tobe ado-nee-yah'-hoo",
		"derivation" : "from H2896 (טוֹב) and H138 (אֲדֹנִיָּה); pleasing (to) Adonijah",
		"def" : "Tob-Adonijah, an Israelite",
		"kjv" : "Tob-adonijah"
	},
	"H2900" : {
		"lemma" : "טוֹבִיָּה",
		"xlit" : "Ṭôwbîyâh",
		"pron" : "to-bee-yaw'",
		"derivation" : "or טוֹבִיָּהוּ; from H2896 (טוֹב) and H3050 (יָהּ); goodness of Jehovah",
		"def" : "Tobijah, the name of three Israelites and of one Samaritan",
		"kjv" : "Tobiah, Tobijah"
	},
	"H2901" : {
		"lemma" : "טָוָה",
		"xlit" : "ṭâvâh",
		"pron" : "taw-vaw'",
		"derivation" : "a primitive root",
		"def" : "to spin",
		"kjv" : "spin"
	},
	"H2902" : {
		"lemma" : "טוּחַ",
		"xlit" : "ṭûwach",
		"pron" : "too'-akh",
		"derivation" : "a primitive root",
		"def" : "to smear, especially with lime",
		"kjv" : "daub, overlay, plaister, smut"
	},
	"H2903" : {
		"lemma" : "טוֹפָפָה",
		"xlit" : "ṭôwphâphâh",
		"pron" : "to-faw-faw'",
		"derivation" : "from an unused root meaning to go around or bind",
		"def" : "a fillet for the forehead",
		"kjv" : "frontlet"
	},
	"H2904" : {
		"lemma" : "טוּל",
		"xlit" : "ṭûwl",
		"pron" : "tool",
		"derivation" : "a primitive root",
		"def" : "to pitch over or reel; hence (transitively) to cast down or out",
		"kjv" : "carry away, (utterly) cast (down, forth, out), send out"
	},
	"H2905" : {
		"lemma" : "טוּר",
		"xlit" : "ṭûwr",
		"pron" : "toor",
		"derivation" : "from an unused root meaning to range in a regular manner",
		"def" : "a row; hence, a wall",
		"kjv" : "row"
	},
	"H2906" : {
		"lemma" : "טוּר",
		"xlit" : "ṭûwr",
		"pron" : "toor",
		"derivation" : "(Aramaic) corresponding to H6697 (צוּר)",
		"def" : "a rock or hill",
		"kjv" : "mountain"
	},
	"H2907" : {
		"lemma" : "טוּשׂ",
		"xlit" : "ṭûws",
		"pron" : "toos",
		"derivation" : "a primitive root",
		"def" : "to pounce as a bird of prey",
		"kjv" : "haste"
	},
	"H2908" : {
		"lemma" : "טְוָת",
		"xlit" : "ṭᵉvâth",
		"pron" : "tev-awth'",
		"derivation" : "(Aramaic) from a root corresponding to H2901 (טָוָה)",
		"def" : "hunger (as twisting)",
		"kjv" : "fasting"
	},
	"H2909" : {
		"lemma" : "טָחָה",
		"xlit" : "ṭâchâh",
		"pron" : "taw-khaw'",
		"derivation" : "a primitive root",
		"def" : "to stretch a bow, as an archer",
		"kjv" : "(bow-) shot"
	},
	"H2910" : {
		"lemma" : "טֻחָה",
		"xlit" : "ṭuchâh",
		"pron" : "too-khaw'",
		"derivation" : "from H2909 (טָחָה) (or H2902 (טוּחַ)) in the sense of overlaying",
		"def" : "(in the plural only) the kidneys (as being covered); hence (figuratively) the inmost thought",
		"kjv" : "inward parts"
	},
	"H2911" : {
		"lemma" : "טְחוֹן",
		"xlit" : "ṭᵉchôwn",
		"pron" : "tekh-one'",
		"derivation" : "from H2912 (טָחַן)",
		"def" : "a hand mill; hence, a millstone",
		"kjv" : "to grind"
	},
	"H2912" : {
		"lemma" : "טָחַן",
		"xlit" : "ṭâchan",
		"pron" : "taw-khan'",
		"derivation" : "a primitive root",
		"def" : "to grind meal; hence, to be aconcubine (that being their employment)",
		"kjv" : "grind(-er)"
	},
	"H2913" : {
		"lemma" : "טַחֲנָה",
		"xlit" : "ṭachănâh",
		"pron" : "takh-an-aw'",
		"derivation" : "from H2912 (טָחַן)",
		"def" : "a hand mill; hence (figuratively) chewing",
		"kjv" : "grinding"
	},
	"H2914" : {
		"lemma" : "טְחֹר",
		"xlit" : "ṭᵉchôr",
		"pron" : "tekh-ore'",
		"derivation" : "from an unused root meaning to burn",
		"def" : "a boil or ulcer (from the inflammation), especially a tumorin the anus or pudenda (the piles)",
		"kjv" : "emerod"
	},
	"H2915" : {
		"lemma" : "טִיחַ",
		"xlit" : "ṭîyach",
		"pron" : "tee'akh",
		"derivation" : "from (the equiv. of) H2902 (טוּחַ)",
		"def" : "mortar or plaster",
		"kjv" : "daubing"
	},
	"H2916" : {
		"lemma" : "טִיט",
		"xlit" : "ṭîyṭ",
		"pron" : "teet",
		"derivation" : "from an unused root meaning apparently to be sticky (rather perb. a demonstrative); from H2894 (טוּא), through the idea of dirt to be swept away)",
		"def" : "mud or clay; figuratively, calamity",
		"kjv" : "clay, dirt, mire"
	},
	"H2917" : {
		"lemma" : "טִין",
		"xlit" : "ṭîyn",
		"pron" : "teen",
		"derivation" : "(Aramaic) perhaps, by interchange, for a word corresponding to H2916 (טִיט)",
		"def" : "clay",
		"kjv" : "miry"
	},
	"H2918" : {
		"lemma" : "טִירָה",
		"xlit" : "ṭîyrâh",
		"pron" : "tee-raw'",
		"derivation" : "feminine of (an equivalent to) H2905 (טוּר)",
		"def" : "a wall; hence, a fortress or a hamlet",
		"kjv" : "(goodly) castle, habitation, palace, row"
	},
	"H2919" : {
		"lemma" : "טַל",
		"xlit" : "ṭal",
		"pron" : "tal",
		"derivation" : "from H2926 (טָלַל)",
		"def" : "dew (as covering vegetation)",
		"kjv" : "dew"
	},
	"H2920" : {
		"lemma" : "טַל",
		"xlit" : "ṭal",
		"pron" : "tal",
		"derivation" : "(Aramaic) the same as H2919 (טַל)",
		"def" : "{dew (as covering vegetation)}",
		"kjv" : "dew"
	},
	"H2921" : {
		"lemma" : "טָלָא",
		"xlit" : "ṭâlâʼ",
		"pron" : "taw-law'",
		"derivation" : "a primitive root",
		"def" : "properly, to cover with pieces; i.e. (by implication) to spot or variegate (as tapestry)",
		"kjv" : "clouted, with divers colours, spotted"
	},
	"H2922" : {
		"lemma" : "טְלָא",
		"xlit" : "ṭᵉlâʼ",
		"pron" : "tel-aw'",
		"derivation" : "apparently from H2921 (טָלָא) in the (orig.) sense of covering (for protection); (compare H2924 (טָלֶה))",
		"def" : "a lamb",
		"kjv" : "lamb"
	},
	"H2923" : {
		"lemma" : "טְלָאִים",
		"xlit" : "Ṭᵉlâʼîym",
		"pron" : "tel-aw-eem'",
		"derivation" : "from the plural of H2922 (טְלָא); lambs",
		"def" : "Telaim, a place in Palestine",
		"kjv" : "Telaim"
	},
	"H2924" : {
		"lemma" : "טָלֶה",
		"xlit" : "ṭâleh",
		"pron" : "taw-leh'",
		"derivation" : "by variation for H2922 (טְלָא)",
		"def" : "a lamb",
		"kjv" : "lamb"
	},
	"H2925" : {
		"lemma" : "טַלְטֵלָה",
		"xlit" : "ṭalṭêlâh",
		"pron" : "tal-tay-law'",
		"derivation" : "from H2904 (טוּל)",
		"def" : "overthrow or rejection",
		"kjv" : "captivity"
	},
	"H2926" : {
		"lemma" : "טָלַל",
		"xlit" : "ṭâlal",
		"pron" : "taw-lal'",
		"derivation" : "a primitive root",
		"def" : "properly, to strew over, i.e. (by implication) to cover in or plate (with beams)",
		"kjv" : "cover"
	},
	"H2927" : {
		"lemma" : "טְלַל",
		"xlit" : "ṭᵉlal",
		"pron" : "tel-al'",
		"derivation" : "(Aramaic) corresponding to H2926 (טָלַל)",
		"def" : "to cover with shade",
		"kjv" : "have a shadow"
	},
	"H2928" : {
		"lemma" : "טֶלֶם",
		"xlit" : "Ṭelem",
		"pron" : "teh'-lem",
		"derivation" : "from an unused root meaning to break up or treat violently; oppression",
		"def" : "Telem, the name of a place in Idumaea, also of a temple doorkeeper",
		"kjv" : "Telem"
	},
	"H2929" : {
		"lemma" : "טַלְמוֹן",
		"xlit" : "Ṭalmôwn",
		"pron" : "tal-mone'",
		"derivation" : "from the same as H2728 (חָרְגֹּל); oppressive",
		"def" : "Talmon, a temple doorkeeper",
		"kjv" : "Talmon"
	},
	"H2930" : {
		"lemma" : "טָמֵא",
		"xlit" : "ṭâmêʼ",
		"pron" : "taw-may'",
		"derivation" : "a primitive root",
		"def" : "to be foul, especially in a ceremial or moral sense (contaminated)",
		"kjv" : "defile (self), pollute (self), be (make, make self, pronounce) unclean, [idiom] utterly"
	},
	"H2931" : {
		"lemma" : "טָמֵא",
		"xlit" : "ṭâmêʼ",
		"pron" : "taw-may'",
		"derivation" : "from H2930 (טָמֵא)",
		"def" : "foul in a religious sense",
		"kjv" : "defiled, [phrase] infamous, polluted(-tion), unclean"
	},
	"H2932" : {
		"lemma" : "טֻמְאָה",
		"xlit" : "ṭumʼâh",
		"pron" : "toom-aw'",
		"derivation" : "from H2930 (טָמֵא)",
		"def" : "religious impurity",
		"kjv" : "filthiness, unclean(-ness)"
	},
	"H2933" : {
		"lemma" : "טָמָה",
		"xlit" : "ṭâmâh",
		"pron" : "taw-maw'",
		"derivation" : "a collateral form of H2930 (טָמֵא)",
		"def" : "to be impure in a religious sense",
		"kjv" : "be defiled, be reputed vile"
	},
	"H2934" : {
		"lemma" : "טָמַן",
		"xlit" : "ṭâman",
		"pron" : "taw-man'",
		"derivation" : "a primitive root",
		"def" : "to hide (by covering over)",
		"kjv" : "hide, lay privily, in secret"
	},
	"H2935" : {
		"lemma" : "טֶנֶא",
		"xlit" : "ṭeneʼ",
		"pron" : "teh'-neh",
		"derivation" : "from an unused root probably meaning to weave",
		"def" : "a basket (of interlaced osiers)",
		"kjv" : "basket"
	},
	"H2936" : {
		"lemma" : "טָנַף",
		"xlit" : "ṭânaph",
		"pron" : "taw-naf'",
		"derivation" : "a primitive root",
		"def" : "to soil",
		"kjv" : "defile"
	},
	"H2937" : {
		"lemma" : "טָעָה",
		"xlit" : "ṭâʻâh",
		"pron" : "taw-aw'",
		"derivation" : "a primitive root",
		"def" : "to wander; causatively to lead astray",
		"kjv" : "seduce"
	},
	"H2938" : {
		"lemma" : "טָעַם",
		"xlit" : "ṭâʻam",
		"pron" : "taw-am'",
		"derivation" : "a primitive root",
		"def" : "to taste; figuratively, to perceive",
		"kjv" : "[idiom] but, perceive, taste"
	},
	"H2939" : {
		"lemma" : "טְעַם",
		"xlit" : "ṭᵉʻam",
		"pron" : "teh-am'",
		"derivation" : "(Aramaic) corresponding to H2938 (טָעַם)",
		"def" : "to taste; causatively to feed",
		"kjv" : "make to eat, feed"
	},
	"H2940" : {
		"lemma" : "טַעַם",
		"xlit" : "ṭaʻam",
		"pron" : "tah'-am",
		"derivation" : "from H2938 (טָעַם)",
		"def" : "properly, a taste, i.e. (figuratively) perception; by implication, intelligence; transitively, a mandate",
		"kjv" : "advice, behaviour, decree, discretion, judgment, reason, taste, understanding"
	},
	"H2941" : {
		"lemma" : "טַעַם",
		"xlit" : "ṭaʻam",
		"pron" : "tah'-am",
		"derivation" : "(Aramaic) from H2939 (טְעַם); compare H2940 (טַעַם))",
		"def" : "properly, a taste, i.e.  ajudicial sentence",
		"kjv" : "account, [idiom] to be commanded, commandment, matter"
	},
	"H2942" : {
		"lemma" : "טְעֵם",
		"xlit" : "ṭᵉʻêm",
		"pron" : "teh-ame'",
		"derivation" : "(Aramaic) from H2939 (טְעַם), and equivalent to H2941 (טַעַם)",
		"def" : "properly, flavor; figuratively, judgment (both subjective and objective); hence, account (both subjectively and objectively)",
		"kjv" : "[phrase] chancellor, [phrase] command, commandment, decree, [phrase] regard, taste, wisdom"
	},
	"H2943" : {
		"lemma" : "טָעַן",
		"xlit" : "ṭâʻan",
		"pron" : "taw-an'",
		"derivation" : "a primitive root",
		"def" : "to load a beast",
		"kjv" : "lade"
	},
	"H2944" : {
		"lemma" : "טָעַן",
		"xlit" : "ṭâʻan",
		"pron" : "taw-an'",
		"derivation" : "a primitive root",
		"def" : "to stab",
		"kjv" : "thrust through"
	},
	"H2945" : {
		"lemma" : "טַף",
		"xlit" : "ṭaph",
		"pron" : "taf",
		"derivation" : "from H2952 (טָפַף) (perhaps referring to the tripping gait of children)",
		"def" : "a family (mostly used collectively in the singular)",
		"kjv" : "(little) children (ones), families"
	},
	"H2946" : {
		"lemma" : "טָפַח",
		"xlit" : "ṭâphach",
		"pron" : "taw-fakh'",
		"derivation" : "a primitive root; or perhaps a denominative from H2947 (טֵפַח), from dandling on the palms",
		"def" : "to flatten out or extend (as a tent); figuratively, to nurse achild (as promotive of growth)",
		"kjv" : "span, swaddle"
	},
	"H2947" : {
		"lemma" : "טֵפַח",
		"xlit" : "ṭêphach",
		"pron" : "tay'-fakh",
		"derivation" : "from H2946 (טָפַח)",
		"def" : "a spread of the hand, i.e. a palm-breadth (not 'span' of the fingers); architecturally, a corbel (as a supporting palm)",
		"kjv" : "coping, hand-breadth"
	},
	"H2948" : {
		"lemma" : "טֹפַח",
		"xlit" : "ṭôphach",
		"pron" : "to'-fakh",
		"derivation" : "from H2946 (טָפַח) (the same as H2947 (טֵפַח))",
		"def" : "{a spread of the hand, i.e. a palm-breadth (not 'span' of the fingers); architecturally, a corbel (as a supporting palm)}",
		"kjv" : "hand-breadth (broad)"
	},
	"H2949" : {
		"lemma" : "טִפֻּח",
		"xlit" : "ṭippuch",
		"pron" : "tip-pookh'",
		"derivation" : "from H2946 (טָפַח)",
		"def" : "nursing",
		"kjv" : "span long"
	},
	"H2950" : {
		"lemma" : "טָפַל",
		"xlit" : "ṭâphal",
		"pron" : "taw-fal'",
		"derivation" : "a primitive root",
		"def" : "properly, to stick on as apatch; figuratively, to impute falsely",
		"kjv" : "forge(-r), sew up"
	},
	"H2951" : {
		"lemma" : "טִפְסַר",
		"xlit" : "ṭiphçar",
		"pron" : "tif-sar'",
		"derivation" : "of foreign derivation",
		"def" : "a military governor",
		"kjv" : "captain"
	},
	"H2952" : {
		"lemma" : "טָפַף",
		"xlit" : "ṭâphaph",
		"pron" : "taw-faf'",
		"derivation" : "a primitive root; apparently",
		"def" : "to trip (with short steps) coquettishly",
		"kjv" : "mince"
	},
	"H2953" : {
		"lemma" : "טְפַר",
		"xlit" : "ṭᵉphar",
		"pron" : "tef-ar'",
		"derivation" : "(Aramaic) from a root corresponding to H6852 (צָפַר), and meaning the same as H6856 (צִפֹּרֶן)",
		"def" : "a finger-nail; also a hoof or claw",
		"kjv" : "nail"
	},
	"H2954" : {
		"lemma" : "טָפַשׁ",
		"xlit" : "ṭâphash",
		"pron" : "taw-fash'",
		"derivation" : "a primitive root",
		"def" : "properly, apparently to be thick; figuratively, to be stupid",
		"kjv" : "be fat"
	},
	"H2955" : {
		"lemma" : "טָפַת",
		"xlit" : "Ṭâphath",
		"pron" : "taw-fath'",
		"derivation" : "probably from H5197 (נָטַף); a dropping (of ointment)",
		"def" : "Taphath, an Israelitess",
		"kjv" : "Taphath"
	},
	"H2956" : {
		"lemma" : "טָרַד",
		"xlit" : "ṭârad",
		"pron" : "taw-rad'",
		"derivation" : "a primitive root",
		"def" : "to drive on; figuratively, to follow close",
		"kjv" : "continual"
	},
	"H2957" : {
		"lemma" : "טְרַד",
		"xlit" : "ṭᵉrad",
		"pron" : "ter-ad'",
		"derivation" : "(Aramaic) corresponding to H2956 (טָרַד)",
		"def" : "to expel",
		"kjv" : "drive"
	},
	"H2958" : {
		"lemma" : "טְרוֹם",
		"xlit" : "ṭᵉrôwm",
		"pron" : "ter-ome'",
		"derivation" : "a variation of H2962 (טֶרֶם)",
		"def" : "not yet",
		"kjv" : "before"
	},
	"H2959" : {
		"lemma" : "טָרַח",
		"xlit" : "ṭârach",
		"pron" : "taw-rakh'",
		"derivation" : "a primitive root",
		"def" : "to overburden",
		"kjv" : "weary"
	},
	"H2960" : {
		"lemma" : "טֹרַח",
		"xlit" : "ṭôrach",
		"pron" : "to'-rakh",
		"derivation" : "from H2959 (טָרַח)",
		"def" : "a burden",
		"kjv" : "cumbrance, trouble"
	},
	"H2961" : {
		"lemma" : "טָרִי",
		"xlit" : "ṭârîy",
		"pron" : "taw-ree'",
		"derivation" : "from an unused root apparently meaning to be moist",
		"def" : "properly, dripping; hence, fresh (i.e. recently made such)",
		"kjv" : "new, putrefying"
	},
	"H2962" : {
		"lemma" : "טֶרֶם",
		"xlit" : "ṭerem",
		"pron" : "teh'-rem",
		"derivation" : "from an unused root apparently meaning to interrupt or suspend",
		"def" : "properly, non-occurrence; used adverbially, not yet or before",
		"kjv" : "before, ere, not yet"
	},
	"H2963" : {
		"lemma" : "טָרַף",
		"xlit" : "ṭâraph",
		"pron" : "taw-raf'",
		"derivation" : "a primitive root",
		"def" : "to pluck off or pull to pieces; causatively to supply with food (as in morsels)",
		"kjv" : "catch, [idiom] without doubt, feed, ravin, rend in pieces, [idiom] surely, tear (in pieces)"
	},
	"H2964" : {
		"lemma" : "טֶרֶף",
		"xlit" : "ṭereph",
		"pron" : "teh'-ref",
		"derivation" : "from H2963 (טָרַף)",
		"def" : "something torn, i.e. a fragment, e.g. a fresh leaf, prey, food",
		"kjv" : "leaf, meat, prey, spoil"
	},
	"H2965" : {
		"lemma" : "טָרָף",
		"xlit" : "ṭârâph",
		"pron" : "taw-rawf'",
		"derivation" : "from H2963 (טָרַף)",
		"def" : "recently torn off, i.e. fresh",
		"kjv" : "pluckt off"
	},
	"H2966" : {
		"lemma" : "טְרֵפָה",
		"xlit" : "ṭᵉrêphâh",
		"pron" : "ter-ay-faw'",
		"derivation" : "feminine (collectively) of H2964 (טֶרֶף)",
		"def" : "prey, i.e. flocks devoured by animals",
		"kjv" : "ravin, (that which was) torn (of beasts, in pieces)"
	},
	"H2967" : {
		"lemma" : "טַרְפְּלַי",
		"xlit" : "Ṭarpᵉlay",
		"pron" : "tar-pel-ah'-ee",
		"derivation" : "(Aramaic) from a name of foreign derivation",
		"def" : "a Tarpelite (collectively) or inhabitants of Tarpel, a place in Assyria",
		"kjv" : "Tarpelites. y"
	},
	"H2968" : {
		"lemma" : "יָאַב",
		"xlit" : "yâʼab",
		"pron" : "yaw-ab'",
		"derivation" : "a primitive root",
		"def" : "to desire",
		"kjv" : "long"
	},
	"H2969" : {
		"lemma" : "יָאָה",
		"xlit" : "yâʼâh",
		"pron" : "yaw-aw'",
		"derivation" : "a primitive root",
		"def" : "to be suitable",
		"kjv" : "appertain"
	},
	"H2970" : {
		"lemma" : "יַאֲזַנְיָה",
		"xlit" : "Yaʼăzanyâh",
		"pron" : "yah-az-an-yaw'",
		"derivation" : "or יַאֲזַנְיָהוּ; from H238 (אָזַן) and H3050 (יָהּ); heard of Jah",
		"def" : "Jaazanjah, the name of four Israelites",
		"kjv" : "Jaazaniah. Compare H3153 (יְזַנְיָה)"
	},
	"H2971" : {
		"lemma" : "יָאִיר",
		"xlit" : "Yâʼîyr",
		"pron" : "yaw-ere'",
		"derivation" : "from H215 (אוֹר); enlightener",
		"def" : "Jair, the name of four Israelites",
		"kjv" : "Jair"
	},
	"H2972" : {
		"lemma" : "יָאִרִי",
		"xlit" : "Yâʼirîy",
		"pron" : "yaw-ee-ree'",
		"derivation" : "patronymically from H2971 (יָאִיר)",
		"def" : "a Jairite or descendant of Jair",
		"kjv" : "Jairite"
	},
	"H2973" : {
		"lemma" : "יָאַל",
		"xlit" : "yâʼal",
		"pron" : "yaw-al'",
		"derivation" : "a primitive root",
		"def" : "properly, to be slack, i.e. (figuratively) to be foolish",
		"kjv" : "dote, be (become, do) foolish(-ly)"
	},
	"H2974" : {
		"lemma" : "יָאַל",
		"xlit" : "yâʼal",
		"pron" : "yaw-al'",
		"derivation" : "a primitive root (probably rather the same as H2973 (יָאַל) through the idea of mental weakness)",
		"def" : "properly, to yield, especially assent; hence (pos.) to undertake as an act of volition",
		"kjv" : "assay, begin, be content, please, take upon, [idiom] willingly, would"
	},
	"H2975" : {
		"lemma" : "יְאֹר",
		"xlit" : "yᵉʼôr",
		"pron" : "yeh-ore'",
		"derivation" : "of Egyptian origin",
		"def" : "a channel, e.g. a fosse, canal, shaft; specifically the Nile, as the one river of Egypt, including its collateral trenches; also the Tigris, as the main river of Assyria",
		"kjv" : "brook, flood, river, stream"
	},
	"H2976" : {
		"lemma" : "יָאַשׁ",
		"xlit" : "yâʼash",
		"pron" : "yaw-ash'",
		"derivation" : "a primitive root",
		"def" : "to desist, i.e. (figuratively) to despond",
		"kjv" : "(cause to) despair, one that is desperate, be no hope"
	},
	"H2977" : {
		"lemma" : "יֹאשִׁיָּה",
		"xlit" : "Yôʼshîyâh",
		"pron" : "yo-shee-yaw'",
		"derivation" : "or יֹאשִׁיָּהוּ; from the same root as H803 (אֲשׁוּיָה) and H3050 (יָהּ); founded of Jah",
		"def" : "Joshijah, the name of two Israelites",
		"kjv" : "Josiah"
	},
	"H2978" : {
		"lemma" : "יְאִתוֹן",
		"xlit" : "yᵉʼithôwn",
		"pron" : "yeh-ee-thone'",
		"derivation" : "from H857 (אָתָה)",
		"def" : "an entry",
		"kjv" : "entrance"
	},
	"H2979" : {
		"lemma" : "יְאָתְרַי",
		"xlit" : "yᵉʼâthᵉray",
		"pron" : "yeh-aw-ther-ah'ee",
		"derivation" : "from the same as H871 (אֲתָרִים); stepping",
		"def" : "Jeatherai, an Israelite",
		"kjv" : "Jeaterai"
	},
	"H2980" : {
		"lemma" : "יָבַב",
		"xlit" : "yâbab",
		"pron" : "yaw-bab",
		"derivation" : "a primitive root",
		"def" : "to bawl",
		"kjv" : "cry out"
	},
	"H2981" : {
		"lemma" : "יְבוּל",
		"xlit" : "yᵉbûwl",
		"pron" : "yeb-ool'",
		"derivation" : "from H2986 (יָבַל)",
		"def" : "produce, i.e. a crop or (figuratively) wealth",
		"kjv" : "fruit, increase"
	},
	"H2982" : {
		"lemma" : "יְבוּס",
		"xlit" : "Yᵉbûwç",
		"pron" : "yeb-oos'",
		"derivation" : "from H947 (בּוּס); trodden, i.e. threshing-place",
		"def" : "Jebus, the original name of Jerusalem",
		"kjv" : "Jebus"
	},
	"H2983" : {
		"lemma" : "יְבוּסִי",
		"xlit" : "Yᵉbûwçîy",
		"pron" : "yeb-oo-see'",
		"derivation" : "patrial from H2982 (יְבוּס)",
		"def" : "a Jebusite or inhabitant of Jebus",
		"kjv" : "Jebusite(-s)"
	},
	"H2984" : {
		"lemma" : "יִבְחַר",
		"xlit" : "Yibchar",
		"pron" : "yib-khar'",
		"derivation" : "from H977 (בָּחַר); choice",
		"def" : "Jibchar, an Israelite",
		"kjv" : "Ibhar"
	},
	"H2985" : {
		"lemma" : "יָבִין",
		"xlit" : "Yâbîyn",
		"pron" : "yaw-bene'",
		"derivation" : "from H995 (בִּין); intelligent",
		"def" : "Jabin, the name of two Canaanitish kings",
		"kjv" : "Jabin"
	},
	"H2986" : {
		"lemma" : "יָבַל",
		"xlit" : "yâbal",
		"pron" : "yaw-bal'",
		"derivation" : "a primitive root",
		"def" : "properly, to flow; causatively, to bring (especially with pomp)",
		"kjv" : "bring (forth), carry, lead (forth)"
	},
	"H2987" : {
		"lemma" : "יְבַל",
		"xlit" : "yᵉbal",
		"pron" : "yeb-al'",
		"derivation" : "(Aramaic) corresponding to H2986 (יָבַל)",
		"def" : "to bring",
		"kjv" : "bring, carry"
	},
	"H2988" : {
		"lemma" : "יָבָל",
		"xlit" : "yâbâl",
		"pron" : "yaw-bawl'",
		"derivation" : "from H2986 (יָבַל)",
		"def" : "a stream",
		"kjv" : "(water-) course, stream"
	},
	"H2989" : {
		"lemma" : "יָבָל",
		"xlit" : "Yâbâl",
		"pron" : "yaw-bawl'",
		"derivation" : "the same as H2988 (יָבָל)",
		"def" : "Jabal, an antediluvian",
		"kjv" : "Jabal"
	},
	"H2990" : {
		"lemma" : "יַבֵּל",
		"xlit" : "yabbêl",
		"pron" : "yab-bale'",
		"derivation" : "from H2986 (יָבַל)",
		"def" : "having running sores",
		"kjv" : "wen"
	},
	"H2991" : {
		"lemma" : "יִבְלְעָם",
		"xlit" : "Yiblᵉʻâm",
		"pron" : "yib-leh-awm'",
		"derivation" : "from H1104 (בָּלַע) and H5971 (עַם); devouring people",
		"def" : "Jibleam, a place in Palestine",
		"kjv" : "Ibleam"
	},
	"H2992" : {
		"lemma" : "יָבַם",
		"xlit" : "yâbam",
		"pron" : "yaw-bam'",
		"derivation" : "a primitive root of doubtful meaning; used only as a denominative from H2993 (יָבָם)",
		"def" : "to marry a (deceased) brother's widow",
		"kjv" : "perform the duty of a husband's brother, marry"
	},
	"H2993" : {
		"lemma" : "יָבָם",
		"xlit" : "yâbâm",
		"pron" : "yaw-bawm'",
		"derivation" : "from (the orig. of) H2992 (יָבַם)",
		"def" : "a brotherin-law",
		"kjv" : "husband's brother"
	},
	"H2994" : {
		"lemma" : "יְבֵמֶת",
		"xlit" : "Yᵉbêmeth",
		"pron" : "yeb-ay'-meth",
		"derivation" : "feminine participle of H2992 (יָבַם)",
		"def" : "a sister-in-law",
		"kjv" : "brother's wife, sister in law"
	},
	"H2995" : {
		"lemma" : "יַבְנְאֵל",
		"xlit" : "Yabnᵉʼêl",
		"pron" : "yab-neh-ale'",
		"derivation" : "from H1129 (בָּנָה) and H410 (אֵל); built of God",
		"def" : "Jabneel, the name of two places in Palestine",
		"kjv" : "Jabneel"
	},
	"H2996" : {
		"lemma" : "יַבְנֶה",
		"xlit" : "Yabneh",
		"pron" : "yab-neh'",
		"derivation" : "from H1129 (בָּנָה); a building",
		"def" : "Jabneh, a place in Palestine",
		"kjv" : "Jabneh"
	},
	"H2997" : {
		"lemma" : "יִבְנְיָה",
		"xlit" : "Yibnᵉyâh",
		"pron" : "yib-neh-yaw'",
		"derivation" : "from H1129 (בָּנָה) and H3050 (יָהּ); built of Jah",
		"def" : "Jibnejah, an Israelite",
		"kjv" : "Ibneiah"
	},
	"H2998" : {
		"lemma" : "יִבְנִיָּה",
		"xlit" : "Yibnîyâh",
		"pron" : "yib-nee-yaw'",
		"derivation" : "from H1129 (בָּנָה) and H3050 (יָהּ); building of Jah",
		"def" : "Jibnijah, an Israelite",
		"kjv" : "Ibnijah"
	},
	"H2999" : {
		"lemma" : "יַבֹּק",
		"xlit" : "Yabbôq",
		"pron" : "yab-boke'",
		"derivation" : "probably from H1238 (בָּקַק); pouring forth",
		"def" : "Jabbok, a river east of the Jordan",
		"kjv" : "Jabbok"
	},
	"H3000" : {
		"lemma" : "יְבֶרֶכְיָהוּ",
		"xlit" : "Yᵉberekyâhûw",
		"pron" : "yeb-eh-rek-yaw'-hoo",
		"derivation" : "from H1288 (בָרַךְ) and H3050 (יָהּ); blessed of Jah",
		"def" : "Jeberekjah, an Israelite",
		"kjv" : "Jeberechiah"
	},
	"H3001" : {
		"lemma" : "יָבֵשׁ",
		"xlit" : "yâbêsh",
		"pron" : "yaw-bashe'",
		"derivation" : "a primitive root",
		"def" : "to be ashamed, confused or disappointed; also (as failing) to dry up (as water) or wither (as herbage)",
		"kjv" : "be ashamed, clean, be confounded, (make) dry (up), (do) shame(-fully), [idiom] utterly, wither (away)"
	},
	"H3002" : {
		"lemma" : "יָבֵשׁ",
		"xlit" : "yâbêsh",
		"pron" : "yaw-bashe'",
		"derivation" : "from H3001 (יָבֵשׁ)",
		"def" : "dry",
		"kjv" : "dried (away), dry"
	},
	"H3003" : {
		"lemma" : "יָבֵשׁ",
		"xlit" : "Yâbêsh",
		"pron" : "yaw-bashe'",
		"derivation" : "the same as H3002 (יָבֵשׁ) (also יָבֵישׁ; often with the addition of H1568 (גִּלְעָד), i.e. Jabesh of Gilad)",
		"def" : "Jobesh, the name of an Israelite and of a place in Palestine",
		"kjv" : "Jobesh (-Gilead)"
	},
	"H3004" : {
		"lemma" : "יַבָּשָׁה",
		"xlit" : "yabbâshâh",
		"pron" : "yab-baw-shaw'",
		"derivation" : "from H3001 (יָבֵשׁ)",
		"def" : "dry ground",
		"kjv" : "dry (ground, land)"
	},
	"H3005" : {
		"lemma" : "יִבְשָׂם",
		"xlit" : "Yibsâm",
		"pron" : "yib-sawm'",
		"derivation" : "from the same as H1314 (בֶּשֶׂם); fragrant",
		"def" : "Jibsam, an Israelite",
		"kjv" : "Jibsam"
	},
	"H3006" : {
		"lemma" : "יַבֶּשֶׁת",
		"xlit" : "yabbesheth",
		"pron" : "yab-beh'-sheth",
		"derivation" : "a variation of H3004 (יַבָּשָׁה)",
		"def" : "dry ground",
		"kjv" : "dry land"
	},
	"H3007" : {
		"lemma" : "יַבֶּשֶׁת",
		"xlit" : "yabbesheth",
		"pron" : "yab-beh'-sheth",
		"derivation" : "(Aramaic) corresponding to H3006 (יַבֶּשֶׁת)",
		"def" : "dry land",
		"kjv" : "earth"
	},
	"H3008" : {
		"lemma" : "יִגְאָל",
		"xlit" : "Yigʼâl",
		"pron" : "yig-awl'",
		"derivation" : "from H1350 (גָּאַל); avenger",
		"def" : "Jigal, the name of three Israelites",
		"kjv" : "Igal, Igeal"
	},
	"H3009" : {
		"lemma" : "יָגַב",
		"xlit" : "yâgab",
		"pron" : "yaw-gab'",
		"derivation" : "a primitive root",
		"def" : "to dig or plow",
		"kjv" : "husbandman"
	},
	"H3010" : {
		"lemma" : "יָגֵב",
		"xlit" : "yâgêb",
		"pron" : "yaw-gabe'",
		"derivation" : "from H3009 (יָגַב)",
		"def" : "a plowed field",
		"kjv" : "field"
	},
	"H3011" : {
		"lemma" : "יׇגְבְּהָה",
		"xlit" : "Yogbᵉhâh",
		"pron" : "yog-beh-haw'",
		"derivation" : "feminine from H1361 (גָּבַהּ); hillock",
		"def" : "Jogbehah, a place East of the Jordan",
		"kjv" : "Jogbehah"
	},
	"H3012" : {
		"lemma" : "יִגְדַּלְיָהוּ",
		"xlit" : "Yigdalyâhûw",
		"pron" : "yig-dal-yaw'-hoo",
		"derivation" : "from H1431 (גָּדַל) and H3050 (יָהּ); magnified of Jah",
		"def" : "Jigdaljah, an Israelite",
		"kjv" : "Igdaliah"
	},
	"H3013" : {
		"lemma" : "יָגָה",
		"xlit" : "yâgâh",
		"pron" : "yaw-gaw'",
		"derivation" : "a primitive root",
		"def" : "to grieve",
		"kjv" : "afflict, cause grief, grieve, sorrowful, vex"
	},
	"H3014" : {
		"lemma" : "יָגָה",
		"xlit" : "yâgâh",
		"pron" : "yaw-gaw'",
		"derivation" : "a primitive root (probably rather the same as H3013 (יָגָה) through the common idea of dissatisfaction)",
		"def" : "to push away",
		"kjv" : "be removed"
	},
	"H3015" : {
		"lemma" : "יָגוֹן",
		"xlit" : "yâgôwn",
		"pron" : "yaw-gohn'",
		"derivation" : "from H3013 (יָגָה)",
		"def" : "affliction",
		"kjv" : "grief, sorrow"
	},
	"H3016" : {
		"lemma" : "יָגוֹר",
		"xlit" : "yâgôwr",
		"pron" : "yaw-gore'",
		"derivation" : "from H3025 (יָגֹר)",
		"def" : "fearful",
		"kjv" : "afraid, fearest"
	},
	"H3017" : {
		"lemma" : "יָגוּר",
		"xlit" : "Yâgûwr",
		"pron" : "yaw-goor'",
		"derivation" : "probably from H1481 (גּוּר); a lodging",
		"def" : "Jagur, a place in Palestine",
		"kjv" : "Jagur"
	},
	"H3018" : {
		"lemma" : "יְגִיעַ",
		"xlit" : "yᵉgîyaʻ",
		"pron" : "yeg-ee'-ah",
		"derivation" : "from H3021 (יָגַע)",
		"def" : "toil; hence, a work, produce, property (as the result of labor)",
		"kjv" : "labour, work"
	},
	"H3019" : {
		"lemma" : "יָגִיעַ",
		"xlit" : "yâgîyaʻ",
		"pron" : "yaw-ghee'-ah",
		"derivation" : "from H3021 (יָגַע)",
		"def" : "tired",
		"kjv" : "weary"
	},
	"H3020" : {
		"lemma" : "יׇגְלִי",
		"xlit" : "Yoglîy",
		"pron" : "yog-lee'",
		"derivation" : "from H1540 (גָּלָה); exiled",
		"def" : "Jogli, an Israelite",
		"kjv" : "Jogli"
	},
	"H3021" : {
		"lemma" : "יָגַע",
		"xlit" : "yâgaʻ",
		"pron" : "yaw-gah'",
		"derivation" : "a primitive root",
		"def" : "properly, to gasp; hence, to be exhausted, to tire, to toil",
		"kjv" : "faint, (make to) labour, (be) weary"
	},
	"H3022" : {
		"lemma" : "יָגָע",
		"xlit" : "yâgâʻ",
		"pron" : "yaw-gaw'",
		"derivation" : "from H3021 (יָגַע)",
		"def" : "earnings (as the product of toil)",
		"kjv" : "that which he laboured for"
	},
	"H3023" : {
		"lemma" : "יָגֵעַ",
		"xlit" : "yâgêaʻ",
		"pron" : "yaw-gay'-ah",
		"derivation" : "from H3021 (יָגַע)",
		"def" : "tired; hence (transitive) tiresome",
		"kjv" : "full of labour, weary"
	},
	"H3024" : {
		"lemma" : "יְגִעָה",
		"xlit" : "yᵉgiʻâh",
		"pron" : "yeg-ee-aw'",
		"derivation" : "feminine of H3019 (יָגִיעַ)",
		"def" : "fatigue",
		"kjv" : "weariness"
	},
	"H3025" : {
		"lemma" : "יָגֹר",
		"xlit" : "yâgôr",
		"pron" : "yaw-gore'",
		"derivation" : "a primitive root",
		"def" : "to fear",
		"kjv" : "be afraid, fear"
	},
	"H3026" : {
		"lemma" : "יְגַר שַׂהֲדוּתָא",
		"xlit" : "Yᵉgar Sahădûwthâʼ",
		"pron" : "yegar' sah-had-oo-thaw'",
		"derivation" : "(Aramaic) from a word derived from an unused root (meaning to gather) and a derivation of a root corresponding to H7717 (שָׂהֵד); heap of the testimony",
		"def" : "Jegar-Sahadutha, a cairn East of the Jordan",
		"kjv" : "Jegar-Sahadutha"
	},
	"H3027" : {
		"lemma" : "יָד",
		"xlit" : "yâd",
		"pron" : "yawd",
		"derivation" : "a primitive word; in distinction from H3709 (כַּף), the closed one); used (as noun, adverb, etc.) in a great variety of applications, both literally and figuratively, both proximate and remote (as follows)",
		"def" : "a hand (the open one (indicating power, means, direction, etc.)",
		"kjv" : "([phrase] be) able, [idiom] about, [phrase] armholes, at, axletree, because of, beside, border, [idiom] bounty, [phrase] broad, (broken-) handed, [idiom] by, charge, coast, [phrase] consecrate, [phrase] creditor, custody, debt, dominion, [idiom] enough, [phrase] fellowship, force, [idiom] from, hand(-staves, -y work), [idiom] he, himself, [idiom] in, labour, [phrase] large, ledge, (left-) handed, means, [idiom] mine, ministry, near, [idiom] of, [idiom] order, ordinance, [idiom] our, parts, pain, power, [idiom] presumptuously, service, side, sore, state, stay, draw with strength, stroke, [phrase] swear, terror, [idiom] thee, [idiom] by them, [idiom] themselves, [idiom] thine own, [idiom] thou, through, [idiom] throwing, [phrase] thumb, times, [idiom] to, [idiom] under, [idiom] us, [idiom] wait on, (way-) side, where, [phrase] wide, [idiom] with (him, me, you), work, [phrase] yield, [idiom] yourselves"
	},
	"H3028" : {
		"lemma" : "יַד",
		"xlit" : "yad",
		"pron" : "yad",
		"derivation" : "(Aramaic) corresponding to H3027 (יָד)",
		"def" : "{a hand (the open one (indicating power, means, direction, etc.)}",
		"kjv" : "hand, power"
	},
	"H3029" : {
		"lemma" : "יְדָא",
		"xlit" : "yᵉdâʼ",
		"pron" : "yed-aw'",
		"derivation" : "(Aramaic) corresponding to H3034 (יָדָה)",
		"def" : "to praise",
		"kjv" : "(give) thank(-s)"
	},
	"H3030" : {
		"lemma" : "יִדְאֲלָה",
		"xlit" : "Yidʼălâh",
		"pron" : "yid-al-aw'",
		"derivation" : "of uncertain derivation",
		"def" : "Jidalah, a place in Palestine",
		"kjv" : "Idalah"
	},
	"H3031" : {
		"lemma" : "יִדְבָּשׁ",
		"xlit" : "Yidbâsh",
		"pron" : "yid-bawsh'",
		"derivation" : "from the same as H1706 (דְּבַשׁ); perhaps honeyed",
		"def" : "Jidbash, an Israelite",
		"kjv" : "Idbash"
	},
	"H3032" : {
		"lemma" : "יָדַד",
		"xlit" : "yâdad",
		"pron" : "yaw-dad'",
		"derivation" : "a primitive root; (compare H3034 (יָדָה))",
		"def" : "properly, to handle, i.e. to throw, e.g. lots",
		"kjv" : "cast"
	},
	"H3033" : {
		"lemma" : "יְדִדוּת",
		"xlit" : "yᵉdidûwth",
		"pron" : "yed-ee-dooth'",
		"derivation" : "from H3039 (יְדִיד)",
		"def" : "properly, affection; concretely, a darling object",
		"kjv" : "dearly beloved"
	},
	"H3034" : {
		"lemma" : "יָדָה",
		"xlit" : "yâdâh",
		"pron" : "yaw-daw'",
		"derivation" : "a primitive root; used only as denominative from H3027 (יָד); literally, to use (i.e. hold out) the hand",
		"def" : "physically, to throw (a stone, an arrow) at or away; especially to revere or worship (with extended hands); intensively, to bemoan (by wringing the hands)",
		"kjv" : "cast (out), (make) confess(-ion), praise, shoot, (give) thank(-ful, -s, -sgiving)"
	},
	"H3035" : {
		"lemma" : "יִדּוֹ",
		"xlit" : "Yiddôw",
		"pron" : "yid-do'",
		"derivation" : "from H3034 (יָדָה); praised",
		"def" : "Jiddo, an Israelite",
		"kjv" : "Iddo"
	},
	"H3036" : {
		"lemma" : "יָדוֹן",
		"xlit" : "Yâdôwn",
		"pron" : "yaw-done'",
		"derivation" : "from H3034 (יָדָה); thankful",
		"def" : "Jadon, an Israelite",
		"kjv" : "Jadon"
	},
	"H3037" : {
		"lemma" : "יַדּוּעַ",
		"xlit" : "Yaddûwaʻ",
		"pron" : "yad-doo'-ah",
		"derivation" : "from H3045 (יָדַע); knowing",
		"def" : "Jaddua, the name of two Israelites",
		"kjv" : "Jaddua"
	},
	"H3038" : {
		"lemma" : "יְדוּתוּן",
		"xlit" : "Yᵉdûwthûwn",
		"pron" : "yed-oo-thoon'",
		"derivation" : "or יְדֻתוּן; or יְדִיתוּן; probably from H3034 (יָדָה); laudatory",
		"def" : "Jeduthun, an Israelite",
		"kjv" : "Jeduthun"
	},
	"H3039" : {
		"lemma" : "יְדִיד",
		"xlit" : "yᵉdîyd",
		"pron" : "yed-eed'",
		"derivation" : "from the same as H1730 (דּוֹד)",
		"def" : "loved",
		"kjv" : "amiable, (well-) beloved, loves"
	},
	"H3040" : {
		"lemma" : "יְדִידָה",
		"xlit" : "Yᵉdîydâh",
		"pron" : "yed-ee-daw'",
		"derivation" : "feminine of H3039 (יְדִיד); beloved",
		"def" : "Jedidah, an Israelitess",
		"kjv" : "Jedidah"
	},
	"H3041" : {
		"lemma" : "יְדִידְיָה",
		"xlit" : "Yᵉdîydᵉyâh",
		"pron" : "yed-ee-deh-yaw'",
		"derivation" : "from H3039 (יְדִיד) and H3050 (יָהּ); beloved of Jah",
		"def" : "Jedidejah, a name of Solomon",
		"kjv" : "Jedidiah"
	},
	"H3042" : {
		"lemma" : "יְדָיָה",
		"xlit" : "Yᵉdâyâh",
		"pron" : "yed-aw-yaw'",
		"derivation" : "from H3034 (יָדָה) and H3050 (יָהּ); praised of Jah",
		"def" : "Jedajah, the name of two Israelites",
		"kjv" : "Jedaiah"
	},
	"H3043" : {
		"lemma" : "יְדִיעֲאֵל",
		"xlit" : "Yᵉdîyʻăʼêl",
		"pron" : "yed-ee-ah-ale'",
		"derivation" : "from H3045 (יָדַע) and H410 (אֵל); knowing God",
		"def" : "Jediael, the name of three Israelites",
		"kjv" : "Jediael"
	},
	"H3044" : {
		"lemma" : "יִדְלָף",
		"xlit" : "Yidlâph",
		"pron" : "yid-lawf'",
		"derivation" : "from H1811 (דָּלַף); tearful",
		"def" : "Jidlaph, a Mesopotamian",
		"kjv" : "Jidlaph"
	},
	"H3045" : {
		"lemma" : "יָדַע",
		"xlit" : "yâdaʻ",
		"pron" : "yaw-dah'",
		"derivation" : "a primitive root",
		"def" : "to know (properly, to ascertain by seeing); used in a great variety of senses, figuratively, literally, euphemistically and inferentially (including observation, care, recognition; and causatively, instruction, designation, punishment, etc.)",
		"kjv" : "acknowledge, acquaintance(-ted with), advise, answer, appoint, assuredly, be aware, (un-) awares, can(-not), certainly, comprehend, consider, [idiom] could they, cunning, declare, be diligent, (can, cause to) discern, discover, endued with, familiar friend, famous, feel, can have, be (ig-) norant, instruct, kinsfolk, kinsman, (cause to let, make) know, (come to give, have, take) knowledge, have (knowledge), (be, make, make to be, make self) known, [phrase] be learned, [phrase] lie by man, mark, perceive, privy to, [idiom] prognosticator, regard, have respect, skilful, shew, can (man of) skill, be sure, of a surety, teach, (can) tell, understand, have (understanding), [idiom] will be, wist, wit, wot"
	},
	"H3046" : {
		"lemma" : "יְדַע",
		"xlit" : "yᵉdaʻ",
		"pron" : "yed-ah'",
		"derivation" : "(Aramaic) corresponding to H3045 (יָדַע)",
		"def" : "{to know (properly, to ascertain by seeing); used in a great variety of senses, figuratively, literally, euphemistically and inferentially (including observation, care, recognition; and causatively, instruction, designation, punishment, etc.)}",
		"kjv" : "certify, know, make known, teach"
	},
	"H3047" : {
		"lemma" : "יָדָע",
		"xlit" : "Yâdâʻ",
		"pron" : "yaw-daw'",
		"derivation" : "from H3045 (יָדַע); knowing",
		"def" : "Jada, an Israelite",
		"kjv" : "Jada"
	},
	"H3048" : {
		"lemma" : "יְדַעְיָה",
		"xlit" : "Yᵉdaʻyâh",
		"pron" : "yed-ah-yaw'",
		"derivation" : "from H3045 (יָדַע) and H3050 (יָהּ); Jah has known",
		"def" : "Jedajah, the name of two Israelites",
		"kjv" : "Jedaiah"
	},
	"H3049" : {
		"lemma" : "יִדְּעֹנִי",
		"xlit" : "yiddᵉʻônîy",
		"pron" : "yid-deh-o-nee'",
		"derivation" : "from H3045 (יָדַע)",
		"def" : "properly, a knowing one; specifically, a conjurer; (by impl) a ghost",
		"kjv" : "wizard"
	},
	"H3050" : {
		"lemma" : "יָהּ",
		"xlit" : "Yâhh",
		"pron" : "yaw",
		"derivation" : "contraction for H3068 (יְהֹוָה), and meaning the same",
		"def" : "Jah, the sacred name",
		"kjv" : "Jah, the Lord, most vehement. Compare names in '-iah,' '-jah.'"
	},
	"H3051" : {
		"lemma" : "יָהַב",
		"xlit" : "yâhab",
		"pron" : "yaw-hab'",
		"derivation" : "a primitive root",
		"def" : "to give (whether literal or figurative); generally, to put; imperatively (reflexive) come",
		"kjv" : "ascribe, bring, come on, give, go, set, take"
	},
	"H3052" : {
		"lemma" : "יְהַב",
		"xlit" : "yᵉhab",
		"pron" : "yeh-hab'",
		"derivation" : "(Aramaic) corresponding to H3051 (יָהַב)",
		"def" : "{to give (whether literal or figurative); generally, to put; imperatively (reflexive) come}",
		"kjv" : "deliver, give, lay, [phrase] prolong, pay, yield"
	},
	"H3053" : {
		"lemma" : "יְהָב",
		"xlit" : "yᵉhâb",
		"pron" : "ye-hawb'",
		"derivation" : "from H3051 (יָהַב)",
		"def" : "properly, what is given (by Providence), i.e. a lot",
		"kjv" : "burden"
	},
	"H3054" : {
		"lemma" : "יָהַד",
		"xlit" : "yâhad",
		"pron" : "yaw-had'",
		"derivation" : "denominative from a form corresponding to H3061 (יְהוּד)lemma יְהיּד yod, corrected to יְהוּד",
		"def" : "to Judaize, i.e. become Jewish",
		"kjv" : "become Jews"
	},
	"H3055" : {
		"lemma" : "יְהֻד",
		"xlit" : "Yᵉhud",
		"pron" : "yeh-hood'",
		"derivation" : "a briefer form of one corresponding to H3061 (יְהוּד)lemma יְהיּד yod, corrected to יְהוּד",
		"def" : "Jehud, a place in Palestine",
		"kjv" : "Jehud"
	},
	"H3056" : {
		"lemma" : "יֶהְדַי",
		"xlit" : "Yehday",
		"pron" : "yeh-dah'-ee",
		"derivation" : "perhaps from a form corresponding to H3061 (יְהוּד)lemma יְהיּד yod, corrected to יְהוּד; Judaistic",
		"def" : "Jehdai, an Israelite",
		"kjv" : "Jehdai"
	},
	"H3057" : {
		"lemma" : "יְהֻדִיָּה",
		"xlit" : "Yᵉhudîyâh",
		"pron" : "yeh-hoo-dee-yaw'",
		"derivation" : "feminine of H3064 (יְהוּדִי)",
		"def" : "Jehudijah, a Jewess",
		"kjv" : "Jehudijah"
	},
	"H3058" : {
		"lemma" : "יֵהוּא",
		"xlit" : "Yêhûwʼ",
		"pron" : "yay-hoo'",
		"derivation" : "from H3068 (יְהֹוָה) and H1931 (הוּא); Jehovah (is) He",
		"def" : "Jehu, the name of five Israelites",
		"kjv" : "Jehu"
	},
	"H3059" : {
		"lemma" : "יְהוֹאָחָז",
		"xlit" : "Yᵉhôwʼâchâz",
		"pron" : "yeh-ho-aw-khawz'",
		"derivation" : "from H3068 (יְהֹוָה) and H270 (אָחַז); Jehovah-seized",
		"def" : "Jehoachaz, the name of three Israelites",
		"kjv" : "Jehoahaz. Compare H3099 (יוֹאָחָז)"
	},
	"H3060" : {
		"lemma" : "יְהוֹאָשׁ",
		"xlit" : "Yᵉhôwʼâsh",
		"pron" : "yeh-ho-awsh'",
		"derivation" : "from H3068 (יְהֹוָה) and (perhaps) H784 (אֵשׁ); Jehovah-fired",
		"def" : "Jehoash, the name of two Israelite kings",
		"kjv" : "Jehoash. Compare H3101 (יוֹאָשׁ)"
	},
	"H3061" : {
		"lemma" : "יְהוּד",
		"xlit" : "Yᵉhûwd",
		"pron" : "yeh-hood'",
		"derivation" : "(Aramaic) contracted from a form corresponding to H3063 (יְהוּדָה)",
		"def" : "properly, Judah, hence, Judaea",
		"kjv" : "Jewry, Judah, Judea"
	},
	"H3062" : {
		"lemma" : "יְהוּדָאִי",
		"xlit" : "Yᵉhûwdâʼîy",
		"pron" : "yeh-hoo-daw-ee'",
		"derivation" : "(Aramaic) patrial from H3061 (יְהוּד)lemma יְהיּד yod, corrected to יְהוּד",
		"def" : "a Jehudaite (or Judaite), i.e. Jew",
		"kjv" : "Jew"
	},
	"H3063" : {
		"lemma" : "יְהוּדָה",
		"xlit" : "Yᵉhûwdâh",
		"pron" : "yeh-hoo-daw'",
		"derivation" : "from H3034 (יָדָה); celebrated",
		"def" : "Jehudah (or Judah), the name of five Israelites; also of the tribe descended from the first, and of its territory",
		"kjv" : "Judah"
	},
	"H3064" : {
		"lemma" : "יְהוּדִי",
		"xlit" : "Yᵉhûwdîy",
		"pron" : "yeh-hoo-dee'",
		"derivation" : "patronymically from H3063 (יְהוּדָה)",
		"def" : "a Jehudite (i.e. Judaite or Jew), or descendant of Jehudah (i.e. Judah)",
		"kjv" : "Jew"
	},
	"H3065" : {
		"lemma" : "יְהוּדִי",
		"xlit" : "Yᵉhûwdîy",
		"pron" : "yeh-hoo-dee'",
		"derivation" : "the same as H3064 (יְהוּדִי)",
		"def" : "Jehudi, an Israelite",
		"kjv" : "Jehudi"
	},
	"H3066" : {
		"lemma" : "יְהוּדִית",
		"xlit" : "Yᵉhûwdîyth",
		"pron" : "yeh-hoo-deeth'",
		"derivation" : "feminine of H3064 (יְהוּדִי)",
		"def" : "the Jewish (used adverbially) language",
		"kjv" : "in the Jews' language"
	},
	"H3067" : {
		"lemma" : "יְהוּדִית",
		"xlit" : "Yᵉhûwdîyth",
		"pron" : "yeh-ho-deeth'",
		"derivation" : "the same as H3066 (יְהוּדִית); Jewess",
		"def" : "Jehudith, a Canaanitess",
		"kjv" : "Judith"
	},
	"H3068" : {
		"lemma" : "יְהֹוָה",
		"xlit" : "Yᵉhôvâh",
		"pron" : "yeh-ho-vaw'",
		"derivation" : "from H1961 (הָיָה); (the) self-Existent or Eternal",
		"def" : "Jehovah, Jewish national name of God",
		"kjv" : "Jehovah, the Lord. Compare H3050 (יָהּ), H3069 (יְהֹוִה)"
	},
	"H3069" : {
		"lemma" : "יְהֹוִה",
		"xlit" : "Yᵉhôvih",
		"pron" : "yeh-ho-vee'",
		"derivation" : "a variation of H3068 (יְהֹוָה) (used after H136 (אֲדֹנָי), and pronounced by Jews as H430 (אֱלֹהִים), in order to prevent the repetition of the same sound, since they elsewhere pronounce H3068 (יְהֹוָה) as H136 (אֲדֹנָי))",
		"def" : "{YHWH}",
		"kjv" : "God"
	},
	"H3070" : {
		"lemma" : "יְהֹוָה יִרְאֶה",
		"xlit" : "Yᵉhôvâh yirʼeh",
		"pron" : "yeh-ho-vaw' yir-eh'",
		"derivation" : "from H3068 (יְהֹוָה) and H7200 (רָאָה); Jehovah will see (to it)",
		"def" : "Jehovah-Jireh, a symbolical name for Mount Moriah",
		"kjv" : "Jehovah-jireh"
	},
	"H3071" : {
		"lemma" : "יְהֹוָה נִסִּי",
		"xlit" : "Yᵉhôvâh niççîy",
		"pron" : "yeh-ho-vaw' nis-see'",
		"derivation" : "from H3068 (יְהֹוָה) and H5251 (נֵס) with the prononimal suffix; Jehovah (is) my banner",
		"def" : "Jehovah-Nissi, a symbolical name of an altar in the Desert",
		"kjv" : "Jehovah-nissi"
	},
	"H3072" : {
		"lemma" : "יְהֹוָה צִדְקֵנוּ",
		"xlit" : "Yᵉhôvâh tsidqênûw",
		"pron" : "ye-ho-vaw' tsid-kay'-noo",
		"derivation" : "from H3068 (יְהֹוָה) and H6664 (צֶדֶק) with pronominal suffix; Jehovah (is) our right",
		"def" : "Jehovah-Tsidkenu, a symbolical epithet of the Messiah and of Jerusalem",
		"kjv" : "the Lord our righteousness"
	},
	"H3073" : {
		"lemma" : "יְהֹוָה שָׁלוֹם",
		"xlit" : "Yᵉhôvâh shâlôwm",
		"pron" : "yeh-ho-vaw' shaw-lome'",
		"derivation" : "from H3068 (יְהֹוָה) and H7965 (שָׁלוֹם); Jehovah (is) peace",
		"def" : "Jehovah-Shalom, a symbolical name of an altar in Palestine",
		"kjv" : "Jehovah-shalom"
	},
	"H3074" : {
		"lemma" : "יְהֹוָה שָׁמָּה",
		"xlit" : "Yᵉhôvâh shâmmâh",
		"pron" : "yeh-ho-vaw' shawm'-maw",
		"derivation" : "from H3068 (יְהֹוָה) and H8033 (שָׁם) with directive enclitic; Jehovah (is) thither",
		"def" : "Jehovah-Shammah, a symbolic title of Jerusalem",
		"kjv" : "Jehovahshammah"
	},
	"H3075" : {
		"lemma" : "יְהוֹזָבָד",
		"xlit" : "Yᵉhôwzâbâd",
		"pron" : "yeh-ho-zaw-bawd'",
		"derivation" : "from H3068 (יְהֹוָה) and H2064 (זָבַד); Jehovah-endowed",
		"def" : "Jehozabad, the name of three Israelites",
		"kjv" : "Jehozabad. Compare H3107 (יוֹזָבָד)"
	},
	"H3076" : {
		"lemma" : "יְהוֹחָנָן",
		"xlit" : "Yᵉhôwchânân",
		"pron" : "yeh-ho-khaw-nawn'",
		"derivation" : "from H3068 (יְהֹוָה) and H2603 (חָנַן); Jehovah-favored",
		"def" : "Jehochanan, the name of eight Israelites",
		"kjv" : "Jehohanan, Johanan. Compare H3110 (יוֹחָנָן)"
	},
	"H3077" : {
		"lemma" : "יְהוֹיָדָע",
		"xlit" : "Yᵉhôwyâdâʻ",
		"pron" : "yeh-ho-yaw-daw'",
		"derivation" : "from H3068 (יְהֹוָה) and H3045 (יָדַע); Jehovah-known",
		"def" : "Jehojada, the name of three Israelites",
		"kjv" : "Jehoiada. Compare H3111 (יוֹיָדָע)"
	},
	"H3078" : {
		"lemma" : "יְהוֹיָכִין",
		"xlit" : "Yᵉhôwyâkîyn",
		"pron" : "yeh-ho-yaw-keen'",
		"derivation" : "from H3068 (יְהֹוָה) and H3559 (כּוּן); Jehovah will establish",
		"def" : "Jehojakin, a Jewish king",
		"kjv" : "Jehoiachin. Compare H3112 (יוֹיָכִין)"
	},
	"H3079" : {
		"lemma" : "יְהוֹיָקִים",
		"xlit" : "Yᵉhôwyâqîym",
		"pron" : "yeh-ho-yaw-keem'",
		"derivation" : "from H3068 (יְהֹוָה) abbreviated and H6965 (קוּם); Jehovah will raise",
		"def" : "Jehojakim, a Jewish king",
		"kjv" : "Jehoiakim. Compare H3113 (יוֹיָקִים)"
	},
	"H3080" : {
		"lemma" : "יְהוֹיָרִיב",
		"xlit" : "Yᵉhôwyârîyb",
		"pron" : "yeh-ho-yaw-reeb'",
		"derivation" : "from H3068 (יְהֹוָה) and H7378 (רִיב); Jehovah will contend",
		"def" : "Jehojarib, the name of two Israelites",
		"kjv" : "Jehoiarib. Compare H3114 (יוֹיָרִיב)"
	},
	"H3081" : {
		"lemma" : "יְהוּכַל",
		"xlit" : "Yᵉhûwkal",
		"pron" : "yeh-hoo-kal'",
		"derivation" : "from H3201 (יָכֹל); potent",
		"def" : "Jehukal, an Israelite",
		"kjv" : "Jehucal. Compare H3116 (יוּכַל)"
	},
	"H3082" : {
		"lemma" : "יְהוֹנָדָב",
		"xlit" : "Yᵉhôwnâdâb",
		"pron" : "yeh-ho-naw-dawb'",
		"derivation" : "from H3068 (יְהֹוָה) and H5068 (נָדַב); Jehovah-largessed",
		"def" : "Jehonadab, the name of an Israelite and of an Arab",
		"kjv" : "Jehonadab, Jonadab. Compare H3122 (יוֹנָדָב)"
	},
	"H3083" : {
		"lemma" : "יְהוֹנָתָן",
		"xlit" : "Yᵉhôwnâthân",
		"pron" : "yeh-ho-naw-thawn'",
		"derivation" : "from H3068 (יְהֹוָה) and H5414 (נָתַן); Jehovah-given",
		"def" : "Jehonathan, the name of four Israelites",
		"kjv" : "Jonathan. Compare H3129 (יוֹנָתָן)"
	},
	"H3084" : {
		"lemma" : "יְהוֹסֵף",
		"xlit" : "Yᵉhôwçêph",
		"pron" : "yeh-ho-safe'",
		"derivation" : "a fuller form of H3130 (יוֹסֵף)",
		"def" : "Jehoseph (i.e. Joseph), a son of Jacob",
		"kjv" : "Joseph"
	},
	"H3085" : {
		"lemma" : "יְהוֹעַדָּה",
		"xlit" : "Yᵉhôwʻaddâh",
		"pron" : "yeh-ho-ad-daw'",
		"derivation" : "from H3068 (יְהֹוָה) and H5710 (עָדָה); Jehovah-adorned",
		"def" : "Jehoaddah, an Israelite",
		"kjv" : "Jehoada"
	},
	"H3086" : {
		"lemma" : "יְהוֹעַדִּין",
		"xlit" : "Yᵉhôwʻaddîyn",
		"pron" : "yeh-ho-ad-deen'",
		"derivation" : "or יְהוֹעַדָּן; from H3068 (יְהֹוָה) and H5727 (עָדַן); Jehovahpleased",
		"def" : "Jehoaddin or Jehoaddan, an Israelitess",
		"kjv" : "Jehoaddan"
	},
	"H3087" : {
		"lemma" : "יְהוֹצָדָק",
		"xlit" : "Yᵉhôwtsâdâq",
		"pron" : "yeh-ho-tsaw-dawk'",
		"derivation" : "from H3068 (יְהֹוָה) and H6663 (צָדַק); Jehovah-righted",
		"def" : "Jehotsadak, an Israelite",
		"kjv" : "Jehozadek, Josedech. Compare H3136 (יוֹצָדָק)"
	},
	"H3088" : {
		"lemma" : "יְהוֹרָם",
		"xlit" : "Yᵉhôwrâm",
		"pron" : "yeh-ho-rawm'",
		"derivation" : "from H3068 (יְהֹוָה) and H7311 (רוּם); Jehovah-raised",
		"def" : "Jehoram, the name of a Syrian and of three Israelites",
		"kjv" : "Jehoram, Joram. Compare H3141 (יוֹרָם)"
	},
	"H3089" : {
		"lemma" : "יְהוֹשֶׁבַע",
		"xlit" : "Yᵉhôwshebaʻ",
		"pron" : "yeh-ho-sheh'-bah",
		"derivation" : "from H3068 (יְהֹוָה) and H7650 (שָׁבַע); Jehovah-sworn",
		"def" : "Jehosheba, an Israelitess",
		"kjv" : "Jehosheba. Compare H3090 (יְהוֹשַׁבְעַת)"
	},
	"H3090" : {
		"lemma" : "יְהוֹשַׁבְעַת",
		"xlit" : "Yᵉhôwshabʻath",
		"pron" : "yeh-ho-shab-ath'",
		"derivation" : "a form of H3089 (יְהוֹשֶׁבַע)",
		"def" : "Jehoshabath, an Israelitess",
		"kjv" : "Jehoshabeath"
	},
	"H3091" : {
		"lemma" : "יְהוֹשׁוּעַ",
		"xlit" : "Yᵉhôwshûwaʻ",
		"pron" : "yeh-ho-shoo'-ah",
		"derivation" : "or יְהוֹשֻׁעַ; from H3068 (יְהֹוָה) and H3467 (יָשַׁע); Jehovah-saved",
		"def" : "Jehoshua (i.e. Joshua), the Jewish leader",
		"kjv" : "Jehoshua, Jehoshuah, Joshua. Compare H1954 (הוֹשֵׁעַ), H3442 (יֵשׁוּעַ)"
	},
	"H3092" : {
		"lemma" : "יְהוֹשָׁפָט",
		"xlit" : "Yᵉhôwshâphâṭ",
		"pron" : "yeh-ho-shaw-fawt'",
		"derivation" : "from H3068 (יְהֹוָה) and H8199 (שָׁפַט); Jehovah-judged",
		"def" : "Jehoshaphat, the name of six Israelites; also of a valley near Jerusalem",
		"kjv" : "Jehoshaphat. Compare H3146 (יוֹשָׁפָט)"
	},
	"H3093" : {
		"lemma" : "יָהִיר",
		"xlit" : "yâhîyr",
		"pron" : "yaw-here'",
		"derivation" : "probably from the same as H2022 (הַר)",
		"def" : "elated; hence, arrogant",
		"kjv" : "haughty, proud"
	},
	"H3094" : {
		"lemma" : "יְהַלֶּלְאֵל",
		"xlit" : "Yᵉhallelʼêl",
		"pron" : "yeh-hal-lel-ale'",
		"derivation" : "from H1984 (הָלַל) and H410 (אֵל); praising God",
		"def" : "Jehallelel, the name of two Israelites",
		"kjv" : "Jehalellel, Jehalelel"
	},
	"H3095" : {
		"lemma" : "יַהֲלֹם",
		"xlit" : "yahălôm",
		"pron" : "yah-hal-ome'",
		"derivation" : "from H1986 (הָלַם) (in the sense of hardness)",
		"def" : "a precious stone, probably onyx",
		"kjv" : "diamond"
	},
	"H3096" : {
		"lemma" : "יַהַץ",
		"xlit" : "Yahats",
		"pron" : "yah'-hats",
		"derivation" : "or יַהְצָה; or (feminine) יַהְצָה; from an unused root meaning to stamp; perhaps threshing-floor",
		"def" : "Jahats or Jahtsah, a place East of the Jordan",
		"kjv" : "Jahaz, Jahazah, Jahzah"
	},
	"H3097" : {
		"lemma" : "יוֹאָב",
		"xlit" : "Yôwʼâb",
		"pron" : "yo-awb'",
		"derivation" : "from H3068 (יְהֹוָה) and H1 (אָב); Jehovah-fathered",
		"def" : "Joab, the name of three Israelites",
		"kjv" : "Joab"
	},
	"H3098" : {
		"lemma" : "יוֹאָח",
		"xlit" : "Yôwʼâch",
		"pron" : "yo-awkh'",
		"derivation" : "from H3068 (יְהֹוָה) and H251 (אָח); Jehovahbrothered",
		"def" : "Joach, the name of four Israelites",
		"kjv" : "Joah"
	},
	"H3099" : {
		"lemma" : "יוֹאָחָז",
		"xlit" : "Yôwʼâchâz",
		"pron" : "yo-aw-khawz'",
		"derivation" : "a form of H3059 (יְהוֹאָחָז)",
		"def" : "Joachaz, the name of two Israelites",
		"kjv" : "Jehoahaz, Joahaz"
	},
	"H3100" : {
		"lemma" : "יוֹאֵל",
		"xlit" : "Yôwʼêl",
		"pron" : "yo-ale'",
		"derivation" : "from H3068 (יְהֹוָה) and H410 (אֵל); Jehovah (is his) God",
		"def" : "Joel, the name of twelve Israelites",
		"kjv" : "Joel"
	},
	"H3101" : {
		"lemma" : "יוֹאָשׁ",
		"xlit" : "Yôwʼâsh",
		"pron" : "yo-awsh'",
		"derivation" : "or יֹאָשׁ; (2 Chronicles 24:1), a form of H3060 (יְהוֹאָשׁ)",
		"def" : "Joash, the name of six Israelites",
		"kjv" : "Joash"
	},
	"H3102" : {
		"lemma" : "יוֹב",
		"xlit" : "Yôwb",
		"pron" : "yobe",
		"derivation" : "perhaps a form of H3103 (יוֹבָב), but more probably by erroneous transcription for H3437 (יָשׁוּב)",
		"def" : "Job, an Israelite",
		"kjv" : "Job"
	},
	"H3103" : {
		"lemma" : "יוֹבָב",
		"xlit" : "Yôwbâb",
		"pron" : "yo-bawb'",
		"derivation" : "from H2980 (יָבַב); howler",
		"def" : "Jobab, the name of two Israelites and of three foreigners",
		"kjv" : "Jobab"
	},
	"H3104" : {
		"lemma" : "יוֹבֵל",
		"xlit" : "yôwbêl",
		"pron" : "yo-bale'",
		"derivation" : "or יֹבֵל; apparently from H2986 (יָבַל)",
		"def" : "the blast of a horn (from its continuous sound); specifically, the signal of the silver trumpets; hence, the instrument itself and the festival thus introduced",
		"kjv" : "jubile, ram's horn, trumpet"
	},
	"H3105" : {
		"lemma" : "יוּבַל",
		"xlit" : "yûwbal",
		"pron" : "yoo-bal'",
		"derivation" : "from H2986 (יָבַל)",
		"def" : "a stream",
		"kjv" : "river"
	},
	"H3106" : {
		"lemma" : "יוּבַל",
		"xlit" : "Yûwbal",
		"pron" : "yoo-bawl'",
		"derivation" : "from H2986 (יָבַל); stream",
		"def" : "Jubal, an antediluvian",
		"kjv" : "Jubal"
	},
	"H3107" : {
		"lemma" : "יוֹזָבָד",
		"xlit" : "Yôwzâbâd",
		"pron" : "yo-zaw-bawd'",
		"derivation" : "a form of H3075 (יְהוֹזָבָד)",
		"def" : "Jozabad, the name of ten Israelites",
		"kjv" : "Josabad, Jozabad"
	},
	"H3108" : {
		"lemma" : "יוֹזָכָר",
		"xlit" : "Yôwzâkâr",
		"pron" : "yo-zaw-kawr'",
		"derivation" : "from H3068 (יְהֹוָה) and H2142 (זָכַר); Jehovah-remembered",
		"def" : "Jozacar, an Israelite",
		"kjv" : "Jozachar"
	},
	"H3109" : {
		"lemma" : "יוֹחָא",
		"xlit" : "Yôwchâʼ",
		"pron" : "yo-khaw'",
		"derivation" : "probably from H3068 (יְהֹוָה) and a variation of H2421 (חָיָה); Jehovah-revived",
		"def" : "Jocha, the name of two Israelites",
		"kjv" : "Joha"
	},
	"H3110" : {
		"lemma" : "יוֹחָנָן",
		"xlit" : "Yôwchânân",
		"pron" : "yo-khaw-nawn'",
		"derivation" : "a form of H3076 (יְהוֹחָנָן)",
		"def" : "Jochanan, the name of nine Israelites",
		"kjv" : "Johanan"
	},
	"H3111" : {
		"lemma" : "יוֹיָדָע",
		"xlit" : "Yôwyâdâʻ",
		"pron" : "yo-yaw-daw'",
		"derivation" : "a form of H3077 (יְהוֹיָדָע)",
		"def" : "Jojada, the name of two Israelites",
		"kjv" : "Jehoiada, Joiada"
	},
	"H3112" : {
		"lemma" : "יוֹיָכִין",
		"xlit" : "Yôwyâkîyn",
		"pron" : "yo-yaw-keen'",
		"derivation" : "a form of H3078 (יְהוֹיָכִין)",
		"def" : "Jojakin, an Israelite king",
		"kjv" : "Jehoiachin"
	},
	"H3113" : {
		"lemma" : "יוֹיָקִים",
		"xlit" : "Yôwyâqîym",
		"pron" : "yo-yaw-keem'",
		"derivation" : "a form of H3079 (יְהוֹיָקִים)",
		"def" : "Jojakim, an Israelite",
		"kjv" : "Joiakim. Compare H3137 (יוֹקִים)"
	},
	"H3114" : {
		"lemma" : "יוֹיָרִיב",
		"xlit" : "Yôwyârîyb",
		"pron" : "yo-yaw-reeb'",
		"derivation" : "a form of H3080 (יְהוֹיָרִיב)",
		"def" : "Jojarib, the name of four Israelites",
		"kjv" : "Joiarib"
	},
	"H3115" : {
		"lemma" : "יוֹכֶבֶד",
		"xlit" : "Yôwkebed",
		"pron" : "yo-keh'-bed",
		"derivation" : "from H3068 (יְהֹוָה) contracted and H3513 (כָּבַד); Jehovah-gloried",
		"def" : "Jokebed, the mother of Moses",
		"kjv" : "Jochebed"
	},
	"H3116" : {
		"lemma" : "יוּכַל",
		"xlit" : "Yûwkal",
		"pron" : "yoo-kal'",
		"derivation" : "a form of H3081 (יְהוּכַל)",
		"def" : "Jukal, an Israelite",
		"kjv" : "Jucal"
	},
	"H3117" : {
		"lemma" : "יוֹם",
		"xlit" : "yôwm",
		"pron" : "yome",
		"derivation" : "from an unused root meaning to be hot",
		"def" : "a day (as the warm hours), whether literal (from sunrise to sunset, or from one sunset to the next), or figurative (a space of time defined by an associated term), (often used adverb)",
		"kjv" : "age, [phrase] always, [phrase] chronicals, continually(-ance), daily, ((birth-), each, to) day, (now a, two) days (agone), [phrase] elder, [idiom] end, [phrase] evening, [phrase] (for) ever(-lasting, -more), [idiom] full, life, as (so) long as (... live), (even) now, [phrase] old, [phrase] outlived, [phrase] perpetually, presently, [phrase] remaineth, [idiom] required, season, [idiom] since, space, then, (process of) time, [phrase] as at other times, [phrase] in trouble, weather, (as) when, (a, the, within a) while (that), [idiom] whole ([phrase] age), (full) year(-ly), [phrase] younger"
	},
	"H3118" : {
		"lemma" : "יוֹם",
		"xlit" : "yôwm",
		"pron" : "yome",
		"derivation" : "(Aramaic) corresponding to H3117 (יוֹם)",
		"def" : "a day",
		"kjv" : "day (by day), time"
	},
	"H3119" : {
		"lemma" : "יוֹמָם",
		"xlit" : "yôwmâm",
		"pron" : "yo-mawm'",
		"derivation" : "from H3117 (יוֹם)",
		"def" : "daily",
		"kjv" : "daily, (by, in the) day(-time)"
	},
	"H3120" : {
		"lemma" : "יָוָן",
		"xlit" : "Yâvân",
		"pron" : "yaw-vawn'",
		"derivation" : "probably from the same as H3196 (יַיִן); effervescing (i.e. hot and active)",
		"def" : "Javan, the name of a son of Joktan, and of the race (Ionians, i.e. Greeks) descended from him, with their territory; also of a place in Arabia",
		"kjv" : "Javan"
	},
	"H3121" : {
		"lemma" : "יָוֵן",
		"xlit" : "yâvên",
		"pron" : "yaw-ven'",
		"derivation" : "from the same as H3196 (יַיִן)",
		"def" : "properly, dregs (as effervescing); hence, mud",
		"kjv" : "mire, miry"
	},
	"H3122" : {
		"lemma" : "יוֹנָדָב",
		"xlit" : "Yôwnâdâb",
		"pron" : "yo-naw-dawb'",
		"derivation" : "a form of H3082 (יְהוֹנָדָב)",
		"def" : "Jonadab, the name of an Israelite and of a Rechabite",
		"kjv" : "Jonadab"
	},
	"H3123" : {
		"lemma" : "יוֹנָה",
		"xlit" : "yôwnâh",
		"pron" : "yo-naw'",
		"derivation" : "probably from the same as H3196 (יַיִן)",
		"def" : "a dove (apparently from the warmth of their mating)",
		"kjv" : "dove, pigeon"
	},
	"H3124" : {
		"lemma" : "יוֹנָה",
		"xlit" : "Yôwnâh",
		"pron" : "yo-naw'",
		"derivation" : "the same as H3123 (יוֹנָה)",
		"def" : "Jonah, an Israelite",
		"kjv" : "Jonah"
	},
	"H3125" : {
		"lemma" : "יְוָנִי",
		"xlit" : "Yᵉvânîy",
		"pron" : "yev-aw-nee'",
		"derivation" : "patronymically from H3121 (יָוֵן)",
		"def" : "a Jevanite, or descendant of Javan",
		"kjv" : "Grecian"
	},
	"H3126" : {
		"lemma" : "יוֹנֵק",
		"xlit" : "yôwnêq",
		"pron" : "yo-nake'",
		"derivation" : "active participle of H3243 (יָנַק)",
		"def" : "a sucker; hence, a twig (of a tree felled and sprouting)",
		"kjv" : "tender plant"
	},
	"H3127" : {
		"lemma" : "יוֹנֶקֶת",
		"xlit" : "yôwneqeth",
		"pron" : "yo-neh'-keth",
		"derivation" : "feminine of H3126 (יוֹנֵק)",
		"def" : "a sprout",
		"kjv" : "(tender) branch, young twig"
	},
	"H3128" : {
		"lemma" : "יוֹנַת אֵלֶם רְחֹקִים",
		"xlit" : "yôwnath ʼêlem rᵉchôqîym",
		"pron" : "yo-nath' ay'-lem rekh-o-keem'",
		"derivation" : "from H3123 (יוֹנָה) and H482 (אֵלֶם) and the plural of H7350 (רָחוֹק)",
		"def" : "dove of (the) silence (i.e. dumb Israel) of (i.e. among) distances (i.e. strangers); the title of a ditty (used for a name of its melody)",
		"kjv" : "Jonath-elem-rechokim"
	},
	"H3129" : {
		"lemma" : "יוֹנָתָן",
		"xlit" : "Yôwnâthân",
		"pron" : "yo-naw-thawn'",
		"derivation" : "a form of H3083 (יְהוֹנָתָן)",
		"def" : "Jonathan, the name of ten Israelites",
		"kjv" : "Jonathan"
	},
	"H3130" : {
		"lemma" : "יוֹסֵף",
		"xlit" : "Yôwçêph",
		"pron" : "yo-safe'",
		"derivation" : "future of H3254 (יָסַף); let him add (or perhaps simply active participle adding)",
		"def" : "Joseph, the name of seven Israelites",
		"kjv" : "Joseph. Compare H3084 (יְהוֹסֵף)"
	},
	"H3131" : {
		"lemma" : "יוֹסִפְיָה",
		"xlit" : "Yôwçiphyâh",
		"pron" : "yo-sif-yaw'",
		"derivation" : "from active participle of H3254 (יָסַף) and H3050 (יָהּ); Jah (is) adding",
		"def" : "Josiphjah, an Israelite",
		"kjv" : "Josiphiah"
	},
	"H3132" : {
		"lemma" : "יוֹעֵאלָה",
		"xlit" : "Yôwʻêʼlâh",
		"pron" : "yo-ay-law'",
		"derivation" : "perhaps feminine active participle of H3276 (יַעַל); furthermore",
		"def" : "Joelah, an Israelite",
		"kjv" : "Joelah"
	},
	"H3133" : {
		"lemma" : "יוֹעֵד",
		"xlit" : "Yôwʻêd",
		"pron" : "yo-ade'",
		"derivation" : "apparently the active participle of H3259 (יָעַד); appointer",
		"def" : "Joed, an Israelite",
		"kjv" : "Joed"
	},
	"H3134" : {
		"lemma" : "יוֹעֶזֶר",
		"xlit" : "Yôwʻezer",
		"pron" : "yo-eh'-zer",
		"derivation" : "from H3068 (יְהֹוָה) and H5828 (עֵזֶר); Jehovah (is his) help",
		"def" : "Joezer, an Israelite",
		"kjv" : "Joezer"
	},
	"H3135" : {
		"lemma" : "יוֹעָשׁ",
		"xlit" : "Yôwʻâsh",
		"pron" : "yo-awsh'",
		"derivation" : "from H3068 (יְהֹוָה) and H5789 (עוּשׁ); Jehovahhastened",
		"def" : "Joash, the name of two Israelites",
		"kjv" : "Joash"
	},
	"H3136" : {
		"lemma" : "יוֹצָדָק",
		"xlit" : "Yôwtsâdâq",
		"pron" : "yo-tsaw-dawk'",
		"derivation" : "a form of H3087 (יְהוֹצָדָק)",
		"def" : "Jotsadak, an Israelite",
		"kjv" : "Jozadak"
	},
	"H3137" : {
		"lemma" : "יוֹקִים",
		"xlit" : "Yôwqîym",
		"pron" : "yo-keem'",
		"derivation" : "a form of H3113 (יוֹיָקִים)",
		"def" : "Jokim, an Israelite",
		"kjv" : "Jokim"
	},
	"H3138" : {
		"lemma" : "יוֹרֶה",
		"xlit" : "yôwreh",
		"pron" : "yo-reh'",
		"derivation" : "active participle of H3384 (יָרָה)",
		"def" : "sprinkling; hence, a sprinkling (or autumnal showers)",
		"kjv" : "first rain, former (rain)"
	},
	"H3139" : {
		"lemma" : "יוֹרָה",
		"xlit" : "Yôwrâh",
		"pron" : "yo-raw'",
		"derivation" : "from H3384 (יָרָה); rainy",
		"def" : "Jorah, an Israelite",
		"kjv" : "Jorah"
	},
	"H3140" : {
		"lemma" : "יוֹרַי",
		"xlit" : "Yôwray",
		"pron" : "yo-rah'-ee",
		"derivation" : "from H3384 (יָרָה); rainy",
		"def" : "Jorai, an Israelite",
		"kjv" : "Jorai"
	},
	"H3141" : {
		"lemma" : "יוֹרָם",
		"xlit" : "Yôwrâm",
		"pron" : "yo-rawm'",
		"derivation" : "a form of H3088 (יְהוֹרָם)",
		"def" : "Joram, the name of three Israelites and one Syrian",
		"kjv" : "Joram"
	},
	"H3142" : {
		"lemma" : "יוּשַׁב חֶסֶד",
		"xlit" : "Yûwshab Cheçed",
		"pron" : "yoo-shab' kheh'-sed",
		"derivation" : "from H7725 (שׁוּב) and H2617 (חֵסֵד); kindness will be returned",
		"def" : "Jushab-Chesed, an Israelite",
		"kjv" : "Jushab-hesed"
	},
	"H3143" : {
		"lemma" : "יוֹשִׁבְיָה",
		"xlit" : "Yôwshibyâh",
		"pron" : "yo-shi-yaw'",
		"derivation" : "from H3427 (יָשַׁב) and H3050 (יָהּ); Jehovah will cause to dwell",
		"def" : "Josibjah, an Israelite",
		"kjv" : "Josibiah"
	},
	"H3144" : {
		"lemma" : "יוֹשָׁה",
		"xlit" : "Yôwshâh",
		"pron" : "yo-shaw'",
		"derivation" : "probably a form of H3145 (יוֹשַׁוְיָה)",
		"def" : "Joshah, an Israelite",
		"kjv" : "Joshah"
	},
	"H3145" : {
		"lemma" : "יוֹשַׁוְיָה",
		"xlit" : "Yôwshavyâh",
		"pron" : "yo-shav-yaw'",
		"derivation" : "from H3068 (יְהֹוָה) and H7737 (שָׁוָה); Jehovah-set",
		"def" : "Joshavjah, an Israelite",
		"kjv" : "Joshaviah. Compare H3144 (יוֹשָׁה)"
	},
	"H3146" : {
		"lemma" : "יוֹשָׁפָט",
		"xlit" : "Yôwshâphâṭ",
		"pron" : "yo-shaw-fawt'",
		"derivation" : "a form of H3092 (יְהוֹשָׁפָט)",
		"def" : "Joshaphat, an Israelite",
		"kjv" : "Joshaphat"
	},
	"H3147" : {
		"lemma" : "יוֹתָם",
		"xlit" : "Yôwthâm",
		"pron" : "yo-thawm'",
		"derivation" : "from H3068 (יְהֹוָה) and H8535 (תָּם); Jehovah (is) perfect",
		"def" : "Jotham, the name of three Israelites",
		"kjv" : "Jotham"
	},
	"H3148" : {
		"lemma" : "יוֹתֵר",
		"xlit" : "yôwthêr",
		"pron" : "yo-thare'",
		"derivation" : "active participle of H3498 (יָתַר)",
		"def" : "properly, redundant; hence, over and above, as adjective, noun, adverb or conjunction",
		"kjv" : "better, more(-over), over, profit"
	},
	"H3149" : {
		"lemma" : "יְזַוְאֵל",
		"xlit" : "Yᵉzavʼêl",
		"pron" : "yez-av-ale'",
		"derivation" : "from an unused root (meaning to sprinkle) and H410 (אֵל); sprinkled of God",
		"def" : "Jezavel, an Israelite",
		"kjv" : "Jeziel (from the margin)"
	},
	"H3150" : {
		"lemma" : "יִזִּיָּה",
		"xlit" : "Yizzîyâh",
		"pron" : "yiz-zee-yaw'",
		"derivation" : "from the same as the first part of H3149 (יְזַוְאֵל) and H3050 (יָהּ); sprinkled of Jah",
		"def" : "Jizzijah, an Israelite",
		"kjv" : "Jeziah"
	},
	"H3151" : {
		"lemma" : "יָזִיז",
		"xlit" : "Yâzîyz",
		"pron" : "yaw-zeez'",
		"derivation" : "from the same as H2123 (זִיז); he will make prominent",
		"def" : "Jaziz, an Israelite",
		"kjv" : "Jaziz"
	},
	"H3152" : {
		"lemma" : "יִזְלִיאָה",
		"xlit" : "Yizlîyʼâh",
		"pron" : "yiz-lee-aw'",
		"derivation" : "perhaps from an unused root (meaning to draw up); he will draw out",
		"def" : "Jizliah, an Israelite",
		"kjv" : "Jezliah"
	},
	"H3153" : {
		"lemma" : "יְזַנְיָה",
		"xlit" : "Yᵉzanyâh",
		"pron" : "yez-an-yaw'",
		"derivation" : "or יְזַנְיָהוּ; probably for H2970 (יַאֲזַנְיָה)",
		"def" : "Jezanjah, an Israelite",
		"kjv" : "Jezaniah"
	},
	"H3154" : {
		"lemma" : "יֶזַע",
		"xlit" : "yezaʻ",
		"pron" : "yeh'-zah",
		"derivation" : "from an unused root mean to ooze",
		"def" : "sweat, i.e. (by implication) a sweating dress",
		"kjv" : "any thing that causeth sweat"
	},
	"H3155" : {
		"lemma" : "יִזְרָח",
		"xlit" : "Yizrâch",
		"pron" : "yiz-rawkh'",
		"derivation" : "a variation for H250 (אֶזְרָחִי)",
		"def" : "a Jizrach (i.e. Ezrachite or Zarchite) or descendant of Zerach",
		"kjv" : "Izrahite"
	},
	"H3156" : {
		"lemma" : "יִזְרַחְיָה",
		"xlit" : "Yizrachyâh",
		"pron" : "yiz-rakh-yaw'",
		"derivation" : "from H2224 (זָרַח) and H3050 (יָהּ); Jah will shine",
		"def" : "Jizrachjah, the name of two Israelites",
		"kjv" : "Izrahiah, Jezrahiah"
	},
	"H3157" : {
		"lemma" : "יִזְרְעֵאל",
		"xlit" : "Yizrᵉʻêʼl",
		"pron" : "yiz-reh-ale'",
		"derivation" : "from H2232 (זָרַע) and H410 (אֵל); God will sow",
		"def" : "Jizreel, the name of two places in Palestine and of two Israelites",
		"kjv" : "Jezreel"
	},
	"H3158" : {
		"lemma" : "יִזְרְעֵאלִי",
		"xlit" : "Yizrᵉʻêʼlîy",
		"pron" : "yiz-reh-ay-lee'",
		"derivation" : "patronymically from H3157 (יִזְרְעֵאל)",
		"def" : "a Jizreelite or native of Jizreel",
		"kjv" : "Jezreelite"
	},
	"H3159" : {
		"lemma" : "יִזְרְעֵאלִית",
		"xlit" : "Yizrᵉʻêʼlîyth",
		"pron" : "yiz-reh-ay-leeth'",
		"derivation" : "feminine of H3158 (יִזְרְעֵאלִי)",
		"def" : "a Jezreelitess",
		"kjv" : "Jezreelitess"
	},
	"H3160" : {
		"lemma" : "יְחֻבָּה",
		"xlit" : "Yᵉchubbâh",
		"pron" : "yekh-oob-baw'",
		"derivation" : "from H2247 (חָבָה); hidden",
		"def" : "Jechubbah, an Israelite",
		"kjv" : "Jehubbah"
	},
	"H3161" : {
		"lemma" : "יָחַד",
		"xlit" : "yâchad",
		"pron" : "yaw-khad'",
		"derivation" : "a primitive root",
		"def" : "to be (or become) one",
		"kjv" : "join, unite"
	},
	"H3162" : {
		"lemma" : "יַחַד",
		"xlit" : "yachad",
		"pron" : "yakh'-ad",
		"derivation" : "from H3161 (יָחַד)",
		"def" : "properly, a unit, i.e. (adverb) unitedly",
		"kjv" : "alike, at all (once), both, likewise, only, (al-) together, withal"
	},
	"H3163" : {
		"lemma" : "יַחְדוֹ",
		"xlit" : "Yachdôw",
		"pron" : "yakh-doe'",
		"derivation" : "from H3162 (יַחַד) with pronominal suffix; his unity, i.e. (adverb) together",
		"def" : "Jachdo, an Israelite",
		"kjv" : "Jahdo"
	},
	"H3164" : {
		"lemma" : "יַחְדִּיאֵל",
		"xlit" : "Yachdîyʼêl",
		"pron" : "yakh-dee-ale'",
		"derivation" : "from H3162 (יַחַד) and H410 (אֵל); unity of God",
		"def" : "Jachdiel, an Israelite",
		"kjv" : "Jahdiel"
	},
	"H3165" : {
		"lemma" : "יֶחְדִּיָּהוּ",
		"xlit" : "Yechdîyâhûw",
		"pron" : "yekh-dee-yaw'-hoo",
		"derivation" : "from H3162 (יַחַד) and H3050 (יָהּ); unity of Jah",
		"def" : "Jechdijah, the name of two Israelites",
		"kjv" : "Jehdeiah"
	},
	"H3166" : {
		"lemma" : "יַחֲזִיאֵל",
		"xlit" : "Yachăzîyʼêl",
		"pron" : "yakh-az-ee-ale'",
		"derivation" : "from H2372 (חָזָה) and H410 (אֵל); beheld of God",
		"def" : "Jachaziel, the name of five Israelites",
		"kjv" : "Jahaziel, Jahziel"
	},
	"H3167" : {
		"lemma" : "יַחְזְיָה",
		"xlit" : "Yachzᵉyâh",
		"pron" : "yakh-zeh-yaw'",
		"derivation" : "from H2372 (חָזָה) and H3050 (יָהּ); Jah will behold",
		"def" : "Jachzejah, an Israelite",
		"kjv" : "Jahaziah"
	},
	"H3168" : {
		"lemma" : "יְחֶזְקֵאל",
		"xlit" : "Yᵉchezqêʼl",
		"pron" : "yekh-ez-kale'",
		"derivation" : "from H2388 (חָזַק) and H410 (אֵל); God will strengthen",
		"def" : "Jechezkel, the name of two Israelites",
		"kjv" : "Ezekiel, Jehezekel"
	},
	"H3169" : {
		"lemma" : "יְחִזְקִיָּה",
		"xlit" : "Yᵉchizqîyâh",
		"pron" : "yekh-iz-kee-yaw'",
		"derivation" : "or יְחִזְקִיָּהוּ; from H3388 (יְרוּשָׁא) and H3050 (יָהּ); strengthened of Jah",
		"def" : "Jechizkijah, the name of five Israelites",
		"kjv" : "Hezekiah, Jehizkiah. Compare H2396 (חִזְקִיָּה)"
	},
	"H3170" : {
		"lemma" : "יַחְזֵרָה",
		"xlit" : "Yachzêrâh",
		"pron" : "yakh-zay-raw'",
		"derivation" : "from the same as H2386 (חֲזִיר); perhaps protection",
		"def" : "Jachzerah, an Israelite",
		"kjv" : "Jahzerah"
	},
	"H3171" : {
		"lemma" : "יְחִיאֵל",
		"xlit" : "Yᵉchîyʼêl",
		"pron" : "yekh-ee-ale'",
		"derivation" : "or (2 Chronicles 29:14) יְחַוְאֵל; from H2421 (חָיָה) and H410 (אֵל); God will live",
		"def" : "Jechiel (or Jechavel), the name of eight Israelites",
		"kjv" : "Jehiel"
	},
	"H3172" : {
		"lemma" : "יְחִיאֵלִי",
		"xlit" : "Yᵉchîyʼêlîy",
		"pron" : "yekh-ee-ay-lee'",
		"derivation" : "patronymically from H3171 (יְחִיאֵל)",
		"def" : "a Jechielite or descendant of Jechiel",
		"kjv" : "Jehieli"
	},
	"H3173" : {
		"lemma" : "יָחִיד",
		"xlit" : "yâchîyd",
		"pron" : "yaw-kheed'",
		"derivation" : "from H3161 (יָחַד)",
		"def" : "properly, united, i.e. sole; by implication, beloved; also lonely; (feminine) the life (as not to be replaced)",
		"kjv" : "darling, desolate, only (child, son), solitary"
	},
	"H3174" : {
		"lemma" : "יְחִיָּה",
		"xlit" : "Yᵉchîyâh",
		"pron" : "yekh-ee-yaw'",
		"derivation" : "from H2421 (חָיָה) and H3050 (יָהּ); Jah will live",
		"def" : "Jechijah, an Israelite",
		"kjv" : "Jehiah"
	},
	"H3175" : {
		"lemma" : "יָחִיל",
		"xlit" : "yâchîyl",
		"pron" : "yaw-kheel'",
		"derivation" : "from H3176 (יָחַל)",
		"def" : "expectant",
		"kjv" : "should hope"
	},
	"H3176" : {
		"lemma" : "יָחַל",
		"xlit" : "yâchal",
		"pron" : "yaw-chal'",
		"derivation" : "a primitive root",
		"def" : "to wait; by implication, to be patient, hope",
		"kjv" : "(cause to, have, make to) hope, be pained, stay, tarry, trust, wait"
	},
	"H3177" : {
		"lemma" : "יַחְלְאֵל",
		"xlit" : "Yachlᵉʼêl",
		"pron" : "yakh-leh-ale'",
		"derivation" : "from H3176 (יָחַל) and H410 (אֵל); expectant of God",
		"def" : "Jachleel, an Israelite",
		"kjv" : "Jahleel"
	},
	"H3178" : {
		"lemma" : "יַחְלְאֵלִי",
		"xlit" : "Yachlᵉʼêlîy",
		"pron" : "yakh-leh-ay-lee'",
		"derivation" : "patronymically from H3177 (יַחְלְאֵל)",
		"def" : "a Jachleelite or descendant of Jachleel",
		"kjv" : "Jahleelites"
	},
	"H3179" : {
		"lemma" : "יָחַם",
		"xlit" : "yâcham",
		"pron" : "yaw-kham'",
		"derivation" : "a primitive root",
		"def" : "probably to be hot; figuratively, to conceive",
		"kjv" : "get heat, be hot, conceive, be warm"
	},
	"H3180" : {
		"lemma" : "יַחְמוּר",
		"xlit" : "yachmûwr",
		"pron" : "yakh-moor'",
		"derivation" : "from H2560 (חָמַר)",
		"def" : "a kind of deer",
		"kjv" : "fallow deer"
	},
	"H3181" : {
		"lemma" : "יַחְמַי",
		"xlit" : "Yachmay",
		"pron" : "yakh-mah'-ee",
		"derivation" : "probably from H3179 (יָחַם); hot",
		"def" : "Jachmai, an Israelite",
		"kjv" : "Jahmai"
	},
	"H3182" : {
		"lemma" : "יָחֵף",
		"xlit" : "yâchêph",
		"pron" : "yaw-khafe'",
		"derivation" : "from an unused root meaning to take off the shoes",
		"def" : "unsandalled",
		"kjv" : "barefoot, being unshod"
	},
	"H3183" : {
		"lemma" : "יַחְצְאֵל",
		"xlit" : "Yachtsᵉʼêl",
		"pron" : "yakh-tseh-ale'",
		"derivation" : "from H2673 (חָצָה)lemma חָעָה ayin, corrected to חָצָה and H410 (אֵל); God will allot",
		"def" : "Jachtseel, an Israelite",
		"kjv" : "Jahzeel. Compare H3185 (יַחְצִיאֵל)"
	},
	"H3184" : {
		"lemma" : "יַחְצְאֵלִי",
		"xlit" : "Yachtsᵉʼêlîy",
		"pron" : "yakh-tseh-ay-lee'",
		"derivation" : "patronymically from H3183 (יַחְצְאֵל)",
		"def" : "a Jachtseelite (collectively) or descendants of Jachtseel",
		"kjv" : "Jahzeelites"
	},
	"H3185" : {
		"lemma" : "יַחְצִיאֵל",
		"xlit" : "Yachtsîyʼêl",
		"pron" : "yakh-tsee-ale'",
		"derivation" : "from H2673 (חָצָה)lemma חָעָה ayin, corrected to חָצָה and H410 (אֵל); allotted of God",
		"def" : "Jachtsiel, an Israelite",
		"kjv" : "Jahziel. Compare H3183 (יַחְצְאֵל)"
	},
	"H3186" : {
		"lemma" : "יָחַר",
		"xlit" : "yâchar",
		"pron" : "yaw-khar'",
		"derivation" : "a primitive root",
		"def" : "to delay",
		"kjv" : "tarry longer"
	},
	"H3187" : {
		"lemma" : "יָחַשׂ",
		"xlit" : "yâchas",
		"pron" : "yaw-khas'",
		"derivation" : "a primitive root; to sprout; used only as denominative from H3188 (יַחַשׂ)",
		"def" : "to enroll by pedigree",
		"kjv" : "(number after, number throughout the) genealogy (to be reckoned), be reckoned by genealogies"
	},
	"H3188" : {
		"lemma" : "יַחַשׂ",
		"xlit" : "yachas",
		"pron" : "yakh'-as",
		"derivation" : "from H3187 (יָחַשׂ)",
		"def" : "a pedigree or family list (as growing spontaneously)",
		"kjv" : "genealogy"
	},
	"H3189" : {
		"lemma" : "יַחַת",
		"xlit" : "Yachath",
		"pron" : "yakh'-ath",
		"derivation" : "from H3161 (יָחַד); unity",
		"def" : "Jachath, the name of four Israelites",
		"kjv" : "Jahath"
	},
	"H3190" : {
		"lemma" : "יָטַב",
		"xlit" : "yâṭab",
		"pron" : "yaw-tab'",
		"derivation" : "a primitive root",
		"def" : "to be (causative) make well, literally (sound, beautiful) or figuratively (happy, successful, right)",
		"kjv" : "be accepted, amend, use aright, benefit, be (make) better, seem best, make cheerful, be comely, [phrase] be content, diligent(-ly), dress, earnestly, find favour, give, be glad, do (be, make) good(-ness), be (make) merry, please ([phrase] well), shew more (kindness), skilfully, [idiom] very small, surely, make sweet, thoroughly, tire, trim, very, be (can, deal, entreat, go, have) well (said, seen)"
	},
	"H3191" : {
		"lemma" : "יְטַב",
		"xlit" : "yᵉṭab",
		"pron" : "yet-ab'",
		"derivation" : "(Aramaic) corresponding to H3190 (יָטַב)",
		"def" : "{to be (causative) make well, literally (sound, beautiful) or figuratively (happy, successful, right)}",
		"kjv" : "seem good"
	},
	"H3192" : {
		"lemma" : "יׇטְבָה",
		"xlit" : "Yoṭbâh",
		"pron" : "yot-baw'",
		"derivation" : "from H3190 (יָטַב); pleasantness",
		"def" : "Jotbah, a place in Palestine",
		"kjv" : "Jotbah"
	},
	"H3193" : {
		"lemma" : "יׇטְבָתָה",
		"xlit" : "Yoṭbâthâh",
		"pron" : "yot-baw'-thaw",
		"derivation" : "from H3192 (יׇטְבָה)",
		"def" : "Jotbathah, a place in the Desert",
		"kjv" : "Jotbath, Jotbathah"
	},
	"H3194" : {
		"lemma" : "יֻטָּה",
		"xlit" : "Yuṭṭâh",
		"pron" : "yoo-taw'",
		"derivation" : "or יוּטָה; from H5186 (נָטָה); extended",
		"def" : "Juttah (or Jutah), a place in Palestine",
		"kjv" : "Juttah"
	},
	"H3195" : {
		"lemma" : "יְטוּר",
		"xlit" : "Yᵉṭûwr",
		"pron" : "yet-oor'",
		"derivation" : "probably from the same as H2905 (טוּר); encircled (i.e. inclosed)",
		"def" : "Jetur, a son of Ishmael",
		"kjv" : "Jetur"
	},
	"H3196" : {
		"lemma" : "יַיִן",
		"xlit" : "yayin",
		"pron" : "yah'-yin",
		"derivation" : "from an unused root meaning to effervesce",
		"def" : "wine (as fermented); by implication, intoxication",
		"kjv" : "banqueting, wine, wine(-bibber)"
	},
	"H3197" : {
		"lemma" : "יַךְ",
		"xlit" : "yak",
		"pron" : "yak",
		"derivation" : "by erroneous transcription for H3027 (יָד)",
		"def" : "a hand or side",
		"kjv" : "(way-) side"
	},
	"H3198" : {
		"lemma" : "יָכַח",
		"xlit" : "yâkach",
		"pron" : "yaw-kahh'",
		"derivation" : "a primitive root",
		"def" : "to be right (i.e. correct); reciprocal, to argue; causatively, to decide, justify or convict",
		"kjv" : "appoint, argue, chasten, convince, correct(-ion), daysman, dispute, judge, maintain, plead, reason (together), rebuke, reprove(-r), surely, in any wise"
	},
	"H3199" : {
		"lemma" : "יָכִין",
		"xlit" : "Yâkîyn",
		"pron" : "yaw-keen'",
		"derivation" : "from H3559 (כּוּן); he (or it) will establish",
		"def" : "Jakin, the name of three Israelites and of a temple pillar",
		"kjv" : "Jachin"
	},
	"H3200" : {
		"lemma" : "יָכִינִי",
		"xlit" : "Yâkîynîy",
		"pron" : "yaw-kee-nee'",
		"derivation" : "patronymically from H3199 (יָכִין)lemma יִכִין first vowel, corrected to יָכִין",
		"def" : "a Jakinite (collectively) or descendants of Jakin",
		"kjv" : "Jachinites"
	},
	"H3201" : {
		"lemma" : "יָכֹל",
		"xlit" : "yâkôl",
		"pron" : "yaw-kole'",
		"derivation" : "or (fuller) יָכוֹל; a primitive root",
		"def" : "to be able, literally (can, could) or morally (may, might)",
		"kjv" : "be able, any at all (ways), attain, can (away with, (-not)), could, endure, might, overcome, have power, prevail, still, suffer"
	},
	"H3202" : {
		"lemma" : "יְכֵל",
		"xlit" : "yᵉkêl",
		"pron" : "yek-ale'",
		"derivation" : "(Aramaic) or יְכִיל; (Aramaic), to H3201 (יָכֹל)",
		"def" : "{to be able, literally (can, could) or morally (may, might)}",
		"kjv" : "be able, can, couldest, prevail"
	},
	"H3203" : {
		"lemma" : "יְכׇלְיָה",
		"xlit" : "Yᵉkolyâh",
		"pron" : "yek-ol-yaw'",
		"derivation" : "and יְכׇלְיָהוּ; or (2 Chronicles 26:3) יְכִילְיָה; from H3201 (יָכֹל) and H3050 (יָהּ); Jah will enable",
		"def" : "Jekoljah or Jekiljah, an Israelitess",
		"kjv" : "Jecholiah, Jecoliah"
	},
	"H3204" : {
		"lemma" : "יְכׇנְיָה",
		"xlit" : "Yᵉkonyâh",
		"pron" : "yek-on-yaw'",
		"derivation" : "and יְכׇנְיָהוּ; or (Jeremiah 27:20) יְכוֹנְיָה; from H3559 (כּוּן) and H3050 (יָהּ); Jah will establish",
		"def" : "Jekonjah, a Jewish king",
		"kjv" : "Jeconiah. Compare H3659 (כׇּנְיָהוּ)"
	},
	"H3205" : {
		"lemma" : "יָלַד",
		"xlit" : "yâlad",
		"pron" : "yaw-lad'",
		"derivation" : "a primitive root",
		"def" : "to bear young; causatively, to beget; medically, to act as midwife; specifically, to show lineage",
		"kjv" : "bear, beget, birth(-day), born, (make to) bring forth (children, young), bring up, calve, child, come, be delivered (of a child), time of delivery, gender, hatch, labour, (do the office of a) midwife, declare pedigrees, be the son of, (woman in, woman that) travail(-eth, -ing woman)"
	},
	"H3206" : {
		"lemma" : "יֶלֶד",
		"xlit" : "yeled",
		"pron" : "yeh'-led",
		"derivation" : "from H3205 (יָלַד)",
		"def" : "something born, i.e. a lad or offspring",
		"kjv" : "boy, child, fruit, son, young man (one)"
	},
	"H3207" : {
		"lemma" : "יַלְדָּה",
		"xlit" : "yaldâh",
		"pron" : "yal-daw'",
		"derivation" : "feminine of H3206 (יֶלֶד)",
		"def" : "a lass",
		"kjv" : "damsel, girl"
	},
	"H3208" : {
		"lemma" : "יַלְדוּת",
		"xlit" : "yaldûwth",
		"pron" : "yal-dooth'",
		"derivation" : "abstractly from H3206 (יֶלֶד)",
		"def" : "boyhood (or girlhood)",
		"kjv" : "childhood, youth"
	},
	"H3209" : {
		"lemma" : "יִלּוֹד",
		"xlit" : "yillôwd",
		"pron" : "yil-lode'",
		"derivation" : "passive from H3205 (יָלַד)",
		"def" : "born",
		"kjv" : "born"
	},
	"H3210" : {
		"lemma" : "יָלוֹן",
		"xlit" : "Yâlôwn",
		"pron" : "yaw-lone'",
		"derivation" : "from H3885 (לוּן); lodging",
		"def" : "Jalon, an Israelite",
		"kjv" : "Jalon"
	},
	"H3211" : {
		"lemma" : "יָלִיד",
		"xlit" : "yâlîyd",
		"pron" : "yaw-leed'",
		"derivation" : "from H3205 (יָלַד)",
		"def" : "born",
		"kjv" : "(home-) born, child, son"
	},
	"H3212" : {
		"lemma" : "יָלַךְ",
		"xlit" : "yâlak",
		"pron" : "yaw-lak'",
		"derivation" : "a primitive root (compare H1980 (הָלַךְ))",
		"def" : "to walk (literally or figuratively); causatively, to carry (in various senses)",
		"kjv" : "[idiom] again, away, bear, bring, carry (away), come (away), depart, flow, [phrase] follow(-ing), get (away, hence, him), (cause to, made) go (away, -ing, -ne, one's way, out), grow, lead (forth), let down, march, prosper, [phrase] pursue, cause to run, spread, take away (-journey), vanish, (cause to) walk(-ing), wax, [idiom] be weak"
	},
	"H3213" : {
		"lemma" : "יָלַל",
		"xlit" : "yâlal",
		"pron" : "yaw-lal'",
		"derivation" : "a primitive root",
		"def" : "to howl (with a wailing tone) or yell (with a boisterous one)",
		"kjv" : "(make to) howl, be howling"
	},
	"H3214" : {
		"lemma" : "יְלֵל",
		"xlit" : "yᵉlêl",
		"pron" : "yel-ale'",
		"derivation" : "from H3213 (יָלַל)",
		"def" : "a howl",
		"kjv" : "howling"
	},
	"H3215" : {
		"lemma" : "יְלָלָה",
		"xlit" : "yᵉlâlâh",
		"pron" : "yel-aw-law'",
		"derivation" : "feminine of H3214 (יְלֵל)",
		"def" : "{a howl}",
		"kjv" : "a howling"
	},
	"H3216" : {
		"lemma" : "יָלַע",
		"xlit" : "yâlaʻ",
		"pron" : "yaw-lah'",
		"derivation" : "a primitive root",
		"def" : "to blurt or utter inconsiderately",
		"kjv" : "devour"
	},
	"H3217" : {
		"lemma" : "יַלֶּפֶת",
		"xlit" : "yallepheth",
		"pron" : "yal-leh'-feth",
		"derivation" : "from an unused root apparently meaning to stick or scrape",
		"def" : "scurf or tetter",
		"kjv" : "scabbed"
	},
	"H3218" : {
		"lemma" : "יֶלֶק",
		"xlit" : "yeleq",
		"pron" : "yeh'-lek",
		"derivation" : "from an unused root meaning to lick up",
		"def" : "a devourer; specifically, the young locust",
		"kjv" : "cankerworm, caterpillar"
	},
	"H3219" : {
		"lemma" : "יַלְקוּט",
		"xlit" : "yalqûwṭ",
		"pron" : "yal-koot'",
		"derivation" : "from H3950 (לָקַט)",
		"def" : "a travelling pouch (as if for gleanings)",
		"kjv" : "scrip"
	},
	"H3220" : {
		"lemma" : "יָם",
		"xlit" : "yâm",
		"pron" : "yawm",
		"derivation" : "from an unused root meaning to roar",
		"def" : "a sea (as breaking in noisy surf) or large body of water; specifically (with the article), the Mediterranean Sea; sometimes a large river, or an artifical basin; locally, the west, or (rarely) the south",
		"kjv" : "sea ([idiom] -faring man, (-shore)), south, west (-ern, side, -ward)"
	},
	"H3221" : {
		"lemma" : "יָם",
		"xlit" : "yâm",
		"pron" : "yawm",
		"derivation" : "(Aramaic) corresponding to H3220 (יָם)",
		"def" : "{a sea (as breaking in noisy surf) or large body of water; specifically (with the article), the Mediterranean Sea; sometimes a large river, or an artifical basin; locally, the west, or (rarely) the south}",
		"kjv" : "sea"
	},
	"H3222" : {
		"lemma" : "יֵם",
		"xlit" : "yêm",
		"pron" : "yame",
		"derivation" : "from the same as H3117 (יוֹם)",
		"def" : "a warm spring",
		"kjv" : "mule"
	},
	"H3223" : {
		"lemma" : "יְמוּאֵל",
		"xlit" : "Yᵉmûwʼêl",
		"pron" : "yem-oo-ale'",
		"derivation" : "from H3117 (יוֹם) and H410 (אֵל); day of God",
		"def" : "Jemuel, an Israelite",
		"kjv" : "Jemuel"
	},
	"H3224" : {
		"lemma" : "יְמִימָה",
		"xlit" : "Yᵉmîymâh",
		"pron" : "yem-ee-maw'",
		"derivation" : "perhaps from the same as H3117 (יוֹם); properly, warm, i.e. affectionate; hence, dove (compare H3123 (יוֹנָה))",
		"def" : "Jemimah, one of Job's daughters",
		"kjv" : "Jemimah"
	},
	"H3225" : {
		"lemma" : "יָמִין",
		"xlit" : "yâmîyn",
		"pron" : "yaw-meen'",
		"derivation" : "from H3231 (יָמַן)",
		"def" : "the right hand or side (leg, eye) of a person or other object (as the stronger and more dexterous); locally, the south",
		"kjv" : "[phrase] left-handed, right (hand, side), south"
	},
	"H3226" : {
		"lemma" : "יָמִין",
		"xlit" : "Yâmîyn",
		"pron" : "yaw-meen'",
		"derivation" : "the same as H3225 (יָמִין)",
		"def" : "Jamin, the name of three Israelites",
		"kjv" : "Jamin. See also H1144 (בִּנְיָמִין)"
	},
	"H3227" : {
		"lemma" : "יְמִינִי",
		"xlit" : "yᵉmîynîy",
		"pron" : "yem-ee-nee'",
		"derivation" : "for H3225 (יָמִין)",
		"def" : "right",
		"kjv" : "(on the) right (hand)"
	},
	"H3228" : {
		"lemma" : "יְמִינִי",
		"xlit" : "Yᵉmîynîy",
		"pron" : "yem-ee-nee'",
		"derivation" : "patronymically from H3226 (יָמִין)",
		"def" : "a Jeminite (collectively) or descendants of Jamin",
		"kjv" : "Jaminites. See also H1145 (בֶּן־יְמִינִי)"
	},
	"H3229" : {
		"lemma" : "יִמְלָא",
		"xlit" : "Yimlâʼ",
		"pron" : "yeem-law'",
		"derivation" : "or יִמְלָה; from H4390 (מָלֵא); full",
		"def" : "Jimla or Jimlah, an Israelite",
		"kjv" : "Imla, Imlah"
	},
	"H3230" : {
		"lemma" : "יַמְלֵךְ",
		"xlit" : "Yamlêk",
		"pron" : "yam-lake'",
		"derivation" : "from H4427 (מָלַךְ); he will make king",
		"def" : "Jamlek, an Israelite",
		"kjv" : "Jamlech"
	},
	"H3231" : {
		"lemma" : "יָמַן",
		"xlit" : "yâman",
		"pron" : "yaw-man'",
		"derivation" : "a primitive root; to be (physically) right (i.e. firm); but used only as denominative from H3225 (יָמִין) and transitive",
		"def" : "to be right-handed or take the right-hand side",
		"kjv" : "go (turn) to (on, use) the right hand"
	},
	"H3232" : {
		"lemma" : "יִמְנָה",
		"xlit" : "Yimnâh",
		"pron" : "yim-naw'",
		"derivation" : "from H3231 (יָמַן); prosperity (as betokened by the right hand)",
		"def" : "Jimnah, the name of two Israelites; also (with the article) of the posterity of one of them",
		"kjv" : "Imna, Imnah, Jimnah, Jimnites"
	},
	"H3233" : {
		"lemma" : "יְמָנִי",
		"xlit" : "yᵉmânîy",
		"pron" : "yem-aw-nee'",
		"derivation" : "from H3231 (יָמַן)",
		"def" : "right (i.e. at the right hand)",
		"kjv" : "(on the) right (hand)"
	},
	"H3234" : {
		"lemma" : "יִמְנָע",
		"xlit" : "Yimnâʻ",
		"pron" : "yim-naw'",
		"derivation" : "from H4513 (מָנַע); he will restrain",
		"def" : "Jimna, an Israelite",
		"kjv" : "Imna"
	},
	"H3235" : {
		"lemma" : "יָמַר",
		"xlit" : "yâmar",
		"pron" : "yaw-mar'",
		"derivation" : "a primitive root",
		"def" : "to exchange; by implication, to change places",
		"kjv" : "boast selves, change"
	},
	"H3236" : {
		"lemma" : "יִמְרָה",
		"xlit" : "Yimrâh",
		"pron" : "yim-raw'",
		"derivation" : "probably from H3235 (יָמַר); interchange",
		"def" : "Jimrah, an Israelite",
		"kjv" : "Imrah"
	},
	"H3237" : {
		"lemma" : "יָמַשׁ",
		"xlit" : "yâmash",
		"pron" : "yaw-mash'",
		"derivation" : "a primitive root",
		"def" : "to touch",
		"kjv" : "feel"
	},
	"H3238" : {
		"lemma" : "יָנָה",
		"xlit" : "yânâh",
		"pron" : "yaw-naw'",
		"derivation" : "a primitive root",
		"def" : "to rage or be violent; by implication, to suppress, to maltreat",
		"kjv" : "destroy, (thrust out by) oppress(-ing, -ion, -or), proud, vex, do violence"
	},
	"H3239" : {
		"lemma" : "יָנוֹחַ",
		"xlit" : "Yânôwach",
		"pron" : "yaw-no'-akh or (with enclitic) Yanowchah yaw-no'-khaw",
		"derivation" : "from H3240 (יָנַח); quiet",
		"def" : "Janoach or Janochah, a place in Palestine",
		"kjv" : "Janoah, Janohah"
	},
	"H3240" : {
		"lemma" : "יָנַח",
		"xlit" : "yânach",
		"pron" : "yaw-nakh'",
		"derivation" : "a primitive root",
		"def" : "to deposit; by implication, to allow to stay",
		"kjv" : "bestow, cast down, lay (down, up), leave (off), let alone (remain), pacify, place, put, set (down), suffer, withdraw, withhold. (The Hiphil forms with the dagesh are here referred to, in accordance with the older grammarians; but if any distinction of the kind is to be made, these should rather be referred to H5117 (נוּחַ), and the others here.)"
	},
	"H3241" : {
		"lemma" : "יָנִים",
		"xlit" : "Yânîym",
		"pron" : "yaw-neem'",
		"derivation" : "from H5123 (נוּם); asleep",
		"def" : "Janim, a place in Palestine",
		"kjv" : "Janum (from the margin)"
	},
	"H3242" : {
		"lemma" : "יְנִיקָה",
		"xlit" : "yᵉnîyqâh",
		"pron" : "yen-ee-kaw'",
		"derivation" : "from H3243 (יָנַק)",
		"def" : "a sucker or sapling",
		"kjv" : "young twig"
	},
	"H3243" : {
		"lemma" : "יָנַק",
		"xlit" : "yânaq",
		"pron" : "yaw-nak'",
		"derivation" : "a primitive root",
		"def" : "to suck; causatively, to give milk",
		"kjv" : "milch, nurse(-ing mother), (give, make to) suck(-ing child, -ling)"
	},
	"H3244" : {
		"lemma" : "יַנְשׁוּף",
		"xlit" : "yanshûwph",
		"pron" : "yan-shoof'",
		"derivation" : "or יַנְשׁוֹף; apparently from H5398 (נָשַׁף); (compare H5399 (נֶשֶׁף))",
		"def" : "an unclean (acquatic) bird; probably the heron (perhaps from its blowing cry, or because the nightheron is meant ))",
		"kjv" : "(great) owl.s"
	},
	"H3245" : {
		"lemma" : "יָסַד",
		"xlit" : "yâçad",
		"pron" : "yaw-sad'",
		"derivation" : "a primitive root",
		"def" : "to set (literally or figuratively); intensively, to found; reflexively, to sit down together, i.e. settle, consult",
		"kjv" : "appoint, take counsel, establish, (lay the, lay for a) found(-ation), instruct, lay, ordain, set, [idiom] sure"
	},
	"H3246" : {
		"lemma" : "יְסֻד",
		"xlit" : "yᵉçud",
		"pron" : "yes-ood'",
		"derivation" : "from H3245 (יָסַד)",
		"def" : "a foundation (figuratively, i.e. beginning)",
		"kjv" : "[idiom] began"
	},
	"H3247" : {
		"lemma" : "יְסוֹד",
		"xlit" : "yᵉçôwd",
		"pron" : "yes-ode'",
		"derivation" : "from H3245 (יָסַד)",
		"def" : "a foundation (literally or figuratively)",
		"kjv" : "bottom, foundation, repairing"
	},
	"H3248" : {
		"lemma" : "יְסוּדָה",
		"xlit" : "yᵉçûwdâh",
		"pron" : "yes-oo-daw'",
		"derivation" : "feminine of H3246 (יְסֻד)",
		"def" : "a foundation",
		"kjv" : "foundation"
	},
	"H3249" : {
		"lemma" : "יָסוּר",
		"xlit" : "yâçûwr",
		"pron" : "yaw-soor'",
		"derivation" : "from H5493 (סוּר)",
		"def" : "departing",
		"kjv" : "they that depart"
	},
	"H3250" : {
		"lemma" : "יִסּוֹר",
		"xlit" : "yiççôwr",
		"pron" : "yis-sore'",
		"derivation" : "from H3256 (יָסַר)",
		"def" : "a reprover",
		"kjv" : "instruct"
	},
	"H3251" : {
		"lemma" : "יָסַךְ",
		"xlit" : "yâçak",
		"pron" : "yaw-sak'",
		"derivation" : "a primitive root",
		"def" : "to pour (intransitive)",
		"kjv" : "be poured"
	},
	"H3252" : {
		"lemma" : "יִסְכָּה",
		"xlit" : "Yiçkâh",
		"pron" : "yis-kaw'",
		"derivation" : "from an unused root meaning to watch; observant",
		"def" : "Jiskah, sister of Lot",
		"kjv" : "Iscah"
	},
	"H3253" : {
		"lemma" : "יִסְמַכְיָהוּ",
		"xlit" : "Yiçmakyâhûw",
		"pron" : "yis-mak-yaw-hoo'",
		"derivation" : "from H5564 (סָמַךְ) and H3050 (יָהּ); Jah will sustain",
		"def" : "Jismakjah, an Israelite",
		"kjv" : "Ismachiah"
	},
	"H3254" : {
		"lemma" : "יָסַף",
		"xlit" : "yâçaph",
		"pron" : "yaw-saf'",
		"derivation" : "a primitive root",
		"def" : "to add or augment (often adverbial, to continue to do a thing)",
		"kjv" : "add, [idiom] again, [idiom] any more, [idiom] cease, [idiom] come more, [phrase] conceive again, continue, exceed, [idiom] further, [idiom] gather together, get more, give more-over, [idiom] henceforth, increase (more and more), join, [idiom] longer (bring, do, make, much, put), [idiom] (the, much, yet) more (and more), proceed (further), prolong, put, be (strong-) er, [idiom] yet, yield"
	},
	"H3255" : {
		"lemma" : "יְסַף",
		"xlit" : "yᵉçaph",
		"pron" : "yes-af'",
		"derivation" : "(Aramaic) corresponding to H3254 (יָסַף)",
		"def" : "{to add or augment (often adverbial, to continue to do a thing)}",
		"kjv" : "add"
	},
	"H3256" : {
		"lemma" : "יָסַר",
		"xlit" : "yâçar",
		"pron" : "yaw-sar'",
		"derivation" : "a primitive root",
		"def" : "to chastise, literally (with blows) or figuratively (with words); hence, to instruct",
		"kjv" : "bind, chasten, chastise, correct, instruct, punish, reform, reprove, sore, teach"
	},
	"H3257" : {
		"lemma" : "יָע",
		"xlit" : "yâʻ",
		"pron" : "yaw",
		"derivation" : "from H3261 (יָעָה)",
		"def" : "a shovel",
		"kjv" : "shovel"
	},
	"H3258" : {
		"lemma" : "יַעְבֵּץ",
		"xlit" : "Yaʻbêts",
		"pron" : "yah-bates'",
		"derivation" : "from an unused root probably meaning to grieve; sorrowful",
		"def" : "Jabets, the name of an Israelite, and also of a place in Palestine",
		"kjv" : "Jabez"
	},
	"H3259" : {
		"lemma" : "יָעַד",
		"xlit" : "yâʻad",
		"pron" : "yaw-ad'",
		"derivation" : "a primitive root",
		"def" : "to fix upon (by agreement or appointment); by implication, to meet (at a stated time), to summon (to trial), to direct (in a certain quarter or position), to engage (for marriage)",
		"kjv" : "agree,(maxke an) appoint(-ment, a time), assemble (selves), betroth, gather (selves, together), meet (together), set (a time)"
	},
	"H3260" : {
		"lemma" : "יֶעְדִּי",
		"xlit" : "Yeʻdîy",
		"pron" : "yed-ee'",
		"derivation" : "from H3259 (יָעַד); appointed",
		"def" : "Jedi, an Israelite",
		"kjv" : "Iddo (from the margin) See H3035 (יִדּוֹ)"
	},
	"H3261" : {
		"lemma" : "יָעָה",
		"xlit" : "yâʻâh",
		"pron" : "yaw-aw'",
		"derivation" : "a primitive root; apparently",
		"def" : "to brush aside",
		"kjv" : "sweep away"
	},
	"H3262" : {
		"lemma" : "יְעוּאֵל",
		"xlit" : "Yᵉʻûwʼêl",
		"pron" : "yeh-oo-ale'",
		"derivation" : "from H3261 (יָעָה) and H410 (אֵל); carried away of God",
		"def" : "Jeuel, the name of four Israelites",
		"kjv" : "Jehiel, Jeiel, Jeuel. Compare H3273 (יְעִיאֵל)"
	},
	"H3263" : {
		"lemma" : "יְעוּץ",
		"xlit" : "Yᵉʻûwts",
		"pron" : "yeh-oots'",
		"derivation" : "from H5779 (עוּץ); counsellor",
		"def" : "Jeuts, an Israelite",
		"kjv" : "Jeuz"
	},
	"H3264" : {
		"lemma" : "יָעוֹר",
		"xlit" : "yâʻôwr",
		"pron" : "yaw-ore'",
		"derivation" : "a variation of H3298 (יַעֲרֶשְׁיָה)",
		"def" : "a forest",
		"kjv" : "wood"
	},
	"H3265" : {
		"lemma" : "יָעוּר",
		"xlit" : "Yâʻûwr",
		"pron" : "yaw-oor'",
		"derivation" : "apparently the passive participle of the same as H3293 (יַעַר); wooded",
		"def" : "Jaur, an Israelite",
		"kjv" : "Jair(from the margin)"
	},
	"H3266" : {
		"lemma" : "יְעוּשׁ",
		"xlit" : "Yᵉʻûwsh",
		"pron" : "yeh-oosh'",
		"derivation" : "from H5789 (עוּשׁ); hasty",
		"def" : "Jeush, the name of an Edomite and of four Israelites",
		"kjv" : "Jehush, Jeush. Compare H3274 (יְעִישׁ)"
	},
	"H3267" : {
		"lemma" : "יָעַז",
		"xlit" : "yâʻaz",
		"pron" : "yaw-az'",
		"derivation" : "a primitive root",
		"def" : "to be bold or obstinate",
		"kjv" : "fierce"
	},
	"H3268" : {
		"lemma" : "יַעֲזִיאֵל",
		"xlit" : "Yaʻăzîyʼêl",
		"pron" : "yah-az-ee-ale'",
		"derivation" : "from H3267 (יָעַז) and H410 (אֵל); emboldened of God",
		"def" : "Jaaziel, an Israelite",
		"kjv" : "Jaaziel"
	},
	"H3269" : {
		"lemma" : "יַעֲזִיָּהוּ",
		"xlit" : "Yaʻăzîyâhûw",
		"pron" : "yah-az-ee-yaw'-hoo",
		"derivation" : "from H3267 (יָעַז) and H3050 (יָהּ); emboldened of Jah",
		"def" : "Jaazijah, an Israelite",
		"kjv" : "Jaaziah"
	},
	"H3270" : {
		"lemma" : "יַעֲזֵיר",
		"xlit" : "Yaʻăzêyr",
		"pron" : "yah-az-ayr'",
		"derivation" : "or יַעְזֵר; from H5826 (עָזַר); helpful",
		"def" : "Jaazer or Jazer, a place East of the Jordan",
		"kjv" : "Jaazer, Jazer"
	},
	"H3271" : {
		"lemma" : "יָעַט",
		"xlit" : "yâʻaṭ",
		"pron" : "yaw-at'",
		"derivation" : "a primitive root",
		"def" : "to clothe",
		"kjv" : "cover"
	},
	"H3272" : {
		"lemma" : "יְעַט",
		"xlit" : "yᵉʻaṭ",
		"pron" : "yeh-at'",
		"derivation" : "(Aramaic) corresponding to H3289 (יָעַץ)",
		"def" : "to counsel; reflexively, to consult",
		"kjv" : "counsellor, consult together"
	},
	"H3273" : {
		"lemma" : "יְעִיאֵל",
		"xlit" : "Yᵉʻîyʼêl",
		"pron" : "yeh-ee-ale'",
		"derivation" : "from H3261 (יָעָה) and H410 (אֵל); carried away of God",
		"def" : "Jeiel, the name of six Israelites",
		"kjv" : "Jeiel, Jehiel. Compare H3262 (יְעוּאֵל)"
	},
	"H3274" : {
		"lemma" : "יְעִישׁ",
		"xlit" : "Yᵉʻîysh",
		"pron" : "yeh-eesh'",
		"derivation" : "from H5789 (עוּשׁ); hasty",
		"def" : "Jeish, the name of an Edomite and of a an Israelite",
		"kjv" : "Jeush (from the margin). Compare H3266 (יְעוּשׁ)"
	},
	"H3275" : {
		"lemma" : "יַעְכָּן",
		"xlit" : "Yaʻkân",
		"pron" : "yah-kawn'",
		"derivation" : "from the same as H5912 (עָכָן); troublesome",
		"def" : "Jakan, an Israelite",
		"kjv" : "Jachan"
	},
	"H3276" : {
		"lemma" : "יַעַל",
		"xlit" : "yaʻal",
		"pron" : "yaw-al'",
		"derivation" : "a primitive root",
		"def" : "properly, to ascend; figuratively, to be valuable (objectively; useful, subjectively; benefited)",
		"kjv" : "[idiom] at all, set forward, can do good, (be, have) profit, (able)"
	},
	"H3277" : {
		"lemma" : "יָעֵל",
		"xlit" : "yâʻêl",
		"pron" : "yaw-ale'",
		"derivation" : "from H3276 (יַעַל)",
		"def" : "an ibex (as climbing)",
		"kjv" : "wild goat"
	},
	"H3278" : {
		"lemma" : "יָעֵל",
		"xlit" : "Yâʻêl",
		"pron" : "yaw-ale'",
		"derivation" : "the same as H3277 (יָעֵל)",
		"def" : "Jael, a Canaanite",
		"kjv" : "Jael"
	},
	"H3279" : {
		"lemma" : "יַעֲלָא",
		"xlit" : "Yaʻălâʼ",
		"pron" : "yah-al-aw'",
		"derivation" : "or יַעֲלָה; the same as H3280 (יַעֲלָה) or direct from H3276 (יַעַל)",
		"def" : "Jaala or Jaalah, one of the Nethinim",
		"kjv" : "Jaala, Jaalah"
	},
	"H3280" : {
		"lemma" : "יַעֲלָה",
		"xlit" : "yaʻălâh",
		"pron" : "yah-al-aw'",
		"derivation" : "feminine of H3277 (יָעֵל)",
		"def" : "{an ibex (as climbing)}",
		"kjv" : "roe"
	},
	"H3281" : {
		"lemma" : "יַעְלָם",
		"xlit" : "Yaʻlâm",
		"pron" : "yah-lawm'",
		"derivation" : "from H5956 (עָלַם); occult",
		"def" : "Jalam, an Edomite",
		"kjv" : "Jalam"
	},
	"H3282" : {
		"lemma" : "יַעַן",
		"xlit" : "yaʻan",
		"pron" : "yah'-an",
		"derivation" : "from an unused root meaning to pay attention",
		"def" : "properly, heed; by implication, purpose (sake or account); used adverbially to indicate the reason or cause",
		"kjv" : "because (that), forasmuch ([phrase] as), seeing then, [phrase] that, [phrase] wheras, [phrase] why"
	},
	"H3283" : {
		"lemma" : "יָעֵן",
		"xlit" : "yâʻên",
		"pron" : "yaw-ane'",
		"derivation" : "from the same as H3282 (יַעַן)",
		"def" : "the ostrich (probably from its answering cry",
		"kjv" : "ostrich"
	},
	"H3284" : {
		"lemma" : "יַעֲנָה",
		"xlit" : "yaʻănâh",
		"pron" : "yah-an-aw'",
		"derivation" : "feminine of H3283 (יָעֵן), and meaning the same",
		"def" : "{the ostrich (probably from its answering cry}",
		"kjv" : "[phrase] owl"
	},
	"H3285" : {
		"lemma" : "יַעֲנַי",
		"xlit" : "Yaʻănay",
		"pron" : "yah-an-ah'ee",
		"derivation" : "from the same as H3283 (יָעֵן); responsive",
		"def" : "Jaanai, an Israelite",
		"kjv" : "Jaanai"
	},
	"H3286" : {
		"lemma" : "יָעַף",
		"xlit" : "yâʻaph",
		"pron" : "yaw-af'",
		"derivation" : "a primitive root",
		"def" : "to tire (as if from wearisome flight)",
		"kjv" : "faint, cause to fly, (be) weary (self)"
	},
	"H3287" : {
		"lemma" : "יָעֵף",
		"xlit" : "yâʻêph",
		"pron" : "yaw-afe'",
		"derivation" : "from H3286 (יָעַף)",
		"def" : "fatigued; figuratively, exhausted",
		"kjv" : "faint, weary"
	},
	"H3288" : {
		"lemma" : "יְעָף",
		"xlit" : "yᵉʻâph",
		"pron" : "yeh-awf'",
		"derivation" : "from H3286 (יָעַף)",
		"def" : "fatigue (adverb, utterly exhausted)",
		"kjv" : "swiftly.e"
	},
	"H3289" : {
		"lemma" : "יָעַץ",
		"xlit" : "yâʻats",
		"pron" : "yaw-ats'",
		"derivation" : "a primitive root",
		"def" : "to advise; reflexively, to deliberate or resolve",
		"kjv" : "advertise, take advise, advise (well), consult, (give, take) counsel(-lor), determine, devise, guide, purpose"
	},
	"H3290" : {
		"lemma" : "יַעֲקֹב",
		"xlit" : "Yaʻăqôb",
		"pron" : "yah-ak-obe'",
		"derivation" : "from H6117 (עָקַב); heel-catcher (i.e. supplanter)",
		"def" : "Jaakob, the Israelitish patriarch",
		"kjv" : "Jacob"
	},
	"H3291" : {
		"lemma" : "יַעֲקֹבָה",
		"xlit" : "Yaʻăqôbâh",
		"pron" : "yah-ak-o'-baw",
		"derivation" : "from H3290 (יַעֲקֹב)",
		"def" : "Jaakobah, an Israelite",
		"kjv" : "Jaakobah"
	},
	"H3292" : {
		"lemma" : "יַעֲקָן",
		"xlit" : "Yaʻăqân",
		"pron" : "yah-ak-awn'",
		"derivation" : "from the same as H6130 (עָקָן)",
		"def" : "Jaakan, an Idumaean",
		"kjv" : "Jaakan. Compare H1142 (בְּנֵי יַעֲקָן)"
	},
	"H3293" : {
		"lemma" : "יַעַר",
		"xlit" : "yaʻar",
		"pron" : "yah'-ar",
		"derivation" : "from an unused root probably meaning to thicken with verdure",
		"def" : "a copse of bushes; hence, a forest; hence, honey in the comb (as hived in trees)",
		"kjv" : "(honey-) comb, forest, wood"
	},
	"H3294" : {
		"lemma" : "יַעְרָה",
		"xlit" : "Yaʻrâh",
		"pron" : "yah-raw'",
		"derivation" : "a form of H3295 (יַעֲרָה)",
		"def" : "Jarah, an Israelite",
		"kjv" : "Jarah"
	},
	"H3295" : {
		"lemma" : "יַעֲרָה",
		"xlit" : "yaʻărâh",
		"pron" : "yah-ar-aw'",
		"derivation" : "feminine of H3293 (יַעַר), and meaning the same",
		"def" : "{a copse of bushes; hence, a forest; hence, honey in the comb (as hived in trees)}",
		"kjv" : "(honey-) comb, forest"
	},
	"H3296" : {
		"lemma" : "יַעֲרֵי אֹרְגִים",
		"xlit" : "Yaʻărêy ʼÔrᵉgîym",
		"pron" : "yah-ar-ay' o-reg-eem'",
		"derivation" : "from the plural of H3293 (יַעַר) and the masculine plural active participle of H707 (אָרַג); woods of weavers",
		"def" : "Jaare-Oregim, an Israelite",
		"kjv" : "Jaare-oregim"
	},
	"H3297" : {
		"lemma" : "יְעָרִים",
		"xlit" : "Yᵉʻârîym",
		"pron" : "yeh-aw-reem'",
		"derivation" : "plural of H3293 (יַעַר); forests",
		"def" : "Jearim, a place in Palestine",
		"kjv" : "Jearim. Compare H7157 (קִרְיַת יְעָרִים)"
	},
	"H3298" : {
		"lemma" : "יַעֲרֶשְׁיָה",
		"xlit" : "Yaʻăreshyâh",
		"pron" : "yah-ar-esh-yaw'",
		"derivation" : "from an unused root of uncertain signification and H3050 (יָהּ)",
		"def" : "Jaareshjah, an Israelite",
		"kjv" : "Jaresiah"
	},
	"H3299" : {
		"lemma" : "יַעֲשׂוּ",
		"xlit" : "Yaʻăsûw",
		"pron" : "yah-as-oo'",
		"derivation" : "from H6213 (עָשָׂה); they will do",
		"def" : "Jaasu, an Israelite",
		"kjv" : "Jaasau"
	},
	"H3300" : {
		"lemma" : "יַעֲשִׂיאֵל",
		"xlit" : "Yaʻăsîyʼêl",
		"pron" : "yah-as-ee-ale'",
		"derivation" : "from H6213 (עָשָׂה) and H410 (אֵל); made of God",
		"def" : "Jaasiel, an Israelite",
		"kjv" : "Jaasiel, Jasiel"
	},
	"H3301" : {
		"lemma" : "יִפְדְּיָה",
		"xlit" : "Yiphdᵉyâh",
		"pron" : "yif-deh-yaw'",
		"derivation" : "from H6299 (פָּדָה) and H3050 (יָהּ); Jah will liberate",
		"def" : "Jiphdejah, an Israelite",
		"kjv" : "Iphedeiah"
	},
	"H3302" : {
		"lemma" : "יָפָה",
		"xlit" : "yâphâh",
		"pron" : "yaw-faw'",
		"derivation" : "a primitive root",
		"def" : "properly, to be bright, i.e. (by implication) beautiful",
		"kjv" : "be beautiful, be (make self) fair(-r), deck"
	},
	"H3303" : {
		"lemma" : "יָפֶה",
		"xlit" : "yâpheh",
		"pron" : "yaw-feh'",
		"derivation" : "from H3302 (יָפָה)",
		"def" : "beautiful (literally or figuratively)",
		"kjv" : "[phrase] beautiful, beauty, comely, fair(-est, one), [phrase] goodly, pleasant, well"
	},
	"H3304" : {
		"lemma" : "יְפֵה־פִיָּה",
		"xlit" : "yᵉphêh-phîyâh",
		"pron" : "yef-eh' fee-yaw'",
		"derivation" : "from H3302 (יָפָה) by reduplication",
		"def" : "very beautiful",
		"kjv" : "very fair"
	},
	"H3305" : {
		"lemma" : "יָפוֹ",
		"xlit" : "Yâphôw",
		"pron" : "yaw-fo'",
		"derivation" : "or יָפוֹא; (Ezra 3:7), from H3302 (יָפָה); beautiful",
		"def" : "Japho, a place in Palestine",
		"kjv" : "Japha, Joppa"
	},
	"H3306" : {
		"lemma" : "יָפַח",
		"xlit" : "yâphach",
		"pron" : "yaw-fakh'",
		"derivation" : "a primitive root",
		"def" : "properly, to breathe hard, i.e. (by implication) to sigh",
		"kjv" : "bewail self"
	},
	"H3307" : {
		"lemma" : "יָפֵחַ",
		"xlit" : "yâphêach",
		"pron" : "yaw-fay'-akh",
		"derivation" : "from H3306 (יָפַח)",
		"def" : "properly, puffing, i.e. (figuratively) meditating",
		"kjv" : "such as breathe out"
	},
	"H3308" : {
		"lemma" : "יֳפִי",
		"xlit" : "yŏphîy",
		"pron" : "yof-ee'",
		"derivation" : "from H3302 (יָפָה)",
		"def" : "beauty",
		"kjv" : "beauty"
	},
	"H3309" : {
		"lemma" : "יָפִיעַ",
		"xlit" : "Yâphîyaʻ",
		"pron" : "yaw-fee'-ah",
		"derivation" : "from H3313 (יָפַע); bright",
		"def" : "Japhia, the name of a Canaanite, an Israelite, and a place in Palestine",
		"kjv" : "Japhia"
	},
	"H3310" : {
		"lemma" : "יַפְלֵט",
		"xlit" : "Yaphlêṭ",
		"pron" : "yaf-late'",
		"derivation" : "from H6403 (פָּלַט); he will deliver",
		"def" : "Japhlet, an Israelite",
		"kjv" : "Japhlet"
	},
	"H3311" : {
		"lemma" : "יַפְלֵטִי",
		"xlit" : "Yaphlêṭîy",
		"pron" : "yaf-lay-tee'",
		"derivation" : "patronymically from H3310 (יַפְלֵט)",
		"def" : "a Japhletite or descendant of Japhlet",
		"kjv" : "Japhleti"
	},
	"H3312" : {
		"lemma" : "יְפֻנֶּה",
		"xlit" : "Yᵉphunneh",
		"pron" : "yef-oon-neh'",
		"derivation" : "from H6437 (פָּנָה); he will be prepared",
		"def" : "Jephunneh, the name of two Israelites",
		"kjv" : "Jephunneh"
	},
	"H3313" : {
		"lemma" : "יָפַע",
		"xlit" : "yâphaʻ",
		"pron" : "yaw-fah'",
		"derivation" : "a primitive root",
		"def" : "to shine",
		"kjv" : "be light, shew self, (cause to) shine (forth)"
	},
	"H3314" : {
		"lemma" : "יִפְעָה",
		"xlit" : "yiphʻâh",
		"pron" : "yif-aw'",
		"derivation" : "from H3313 (יָפַע)",
		"def" : "splendor or (figuratively) beauty",
		"kjv" : "brightness"
	},
	"H3315" : {
		"lemma" : "יֶפֶת",
		"xlit" : "Yepheth",
		"pron" : "yeh'-feth",
		"derivation" : "from H6601 (פָּתָה); expansion",
		"def" : "Jepheth, a son of Noah; also his posterity",
		"kjv" : "Japheth"
	},
	"H3316" : {
		"lemma" : "יִפְתָּח",
		"xlit" : "Yiphtâch",
		"pron" : "yif-tawkh'",
		"derivation" : "from H6605 (פָּתַח); he will open",
		"def" : "Jiphtach, an Israelite; also a place in Palestine",
		"kjv" : "Jephthah, Jiphtah"
	},
	"H3317" : {
		"lemma" : "יִפְתַּח־אֵל",
		"xlit" : "Yiphtach-ʼêl",
		"pron" : "yif-tach-ale'",
		"derivation" : "from H6605 (פָּתַח) and H410 (אֵל); God will open",
		"def" : "Jiphtach-el, a place in Palestine",
		"kjv" : "Jiphthah-el"
	},
	"H3318" : {
		"lemma" : "יָצָא",
		"xlit" : "yâtsâʼ",
		"pron" : "yaw-tsaw'",
		"derivation" : "a primitive root",
		"def" : "to go (causatively, bring) out, in a great variety of applications, literally and figuratively, direct and proxim",
		"kjv" : "[idiom] after, appear, [idiom] assuredly, bear out, [idiom] begotten, break out, bring forth (out, up), carry out, come (abroad, out, thereat, without), [phrase] be condemned, depart(-ing, -ure), draw forth, in the end, escape, exact, fail, fall (out), fetch forth (out), get away (forth, hence, out), (able to, cause to, let) go abroad (forth, on, out), going out, grow, have forth (out), issue out, lay (lie) out, lead out, pluck out, proceed, pull out, put away, be risen, [idiom] scarce, send with commandment, shoot forth, spread, spring out, stand out, [idiom] still, [idiom] surely, take forth (out), at any time, [idiom] to (and fro), utter"
	},
	"H3319" : {
		"lemma" : "יְצָא",
		"xlit" : "yᵉtsâʼ",
		"pron" : "yets-aw'",
		"derivation" : "(Aramaic) corresponding to H3318 (יָצָא)",
		"def" : "{to go (causatively, bring) out, in a great variety of applications, literally and figuratively, direct and proxim.}",
		"kjv" : "finish"
	},
	"H3320" : {
		"lemma" : "יָצַב",
		"xlit" : "yâtsab",
		"pron" : "yaw-tsab'",
		"derivation" : "a primitive root",
		"def" : "to place (any thing so as to stay); reflexively, to station, offer, continue",
		"kjv" : "present selves, remaining, resort, set (selves), (be able to, can, with-) stand (fast, forth, -ing, still, up)"
	},
	"H3321" : {
		"lemma" : "יְצַב",
		"xlit" : "yᵉtsab",
		"pron" : "yets-abe'",
		"derivation" : "(Aramaic) corresponding to H3320 (יָצַב)",
		"def" : "to be firm; hence, to speak surely",
		"kjv" : "truth"
	},
	"H3322" : {
		"lemma" : "יָצַג",
		"xlit" : "yâtsag",
		"pron" : "yaw-tsag'",
		"derivation" : "a primitive root",
		"def" : "to place permanently",
		"kjv" : "establish, leave, make, present, put, set, stay"
	},
	"H3323" : {
		"lemma" : "יִצְהָר",
		"xlit" : "yitshâr",
		"pron" : "yits-hawr'",
		"derivation" : "from H6671 (צָהַר)",
		"def" : "oil (as producing light); figuratively, anointing",
		"kjv" : "[phrase] anointed oil"
	},
	"H3324" : {
		"lemma" : "יִצְהָר",
		"xlit" : "Yitshâr",
		"pron" : "yits-hawr'",
		"derivation" : "the same as H3323 (יִצְהָר)",
		"def" : "Jitshar, an Israelite",
		"kjv" : "Izhar"
	},
	"H3325" : {
		"lemma" : "יִצְהָרִי",
		"xlit" : "Yitshârîy",
		"pron" : "yits-haw-ree'",
		"derivation" : "patronymically from H3324 (יִצְהָר)",
		"def" : "a Jitsharite or descendant of Jitshar",
		"kjv" : "Izeharites, Izharites"
	},
	"H3326" : {
		"lemma" : "יָצוּעַ",
		"xlit" : "yâtsûwaʻ",
		"pron" : "yaw-tsoo'-ah",
		"derivation" : "passive participle of H3331 (יַצַע)",
		"def" : "spread, i.e. a bed; (architecture) an extension, i.e. wing or lean-to (a single story or collectively)",
		"kjv" : "bed, chamber, couch"
	},
	"H3327" : {
		"lemma" : "יִצְחָק",
		"xlit" : "Yitschâq",
		"pron" : "yits-khawk'",
		"derivation" : "from H6711 (צָחַק); laughter (i.e. mochery)",
		"def" : "Jitschak (or Isaac), son of Abraham",
		"kjv" : "Isaac. Compare H3446 (יִשְׂחָק)"
	},
	"H3328" : {
		"lemma" : "יִצְחַר",
		"xlit" : "Yitschar",
		"pron" : "yits-khar'",
		"derivation" : "from the same as H6713 (צַחַר); he will shine",
		"def" : "Jitschar, an Israelite",
		"kjv" : "and Zehoar (from the margin)"
	},
	"H3329" : {
		"lemma" : "יָצִיא",
		"xlit" : "yâtsîyʼ",
		"pron" : "yaw-tsee'",
		"derivation" : "from H3318 (יָצָא)",
		"def" : "issue, i.e. offspring",
		"kjv" : "those that came forth"
	},
	"H3330" : {
		"lemma" : "יַצִּיב",
		"xlit" : "yatstsîyb",
		"pron" : "yats-tseeb'",
		"derivation" : "(Aramaic) from H3321 (יְצַב)lemma יְצב missing vowel, corrected to יְצַבxlit yᵉtsêb corrected to yᵉtsab",
		"def" : "fixed, sure; concretely, certainty",
		"kjv" : "certain(-ty), true, truth"
	},
	"H3331" : {
		"lemma" : "יַצַע",
		"xlit" : "yatsaʻ",
		"pron" : "yaw-tsah'",
		"derivation" : "a primitive root",
		"def" : "to strew as a surface",
		"kjv" : "make (one's) bed, [idiom] lie, spread"
	},
	"H3332" : {
		"lemma" : "יָצַק",
		"xlit" : "yâtsaq",
		"pron" : "yaw-tsak'",
		"derivation" : "a primitive root",
		"def" : "properly, to pour out (transitive or intransitive); by implication, to melt or cast as metal; by extension, to place firmly, to stiffen or grow hard",
		"kjv" : "cast, cleave fast, be (as) firm, grow, be hard, lay out, molten, overflow, pour (out), run out, set down, stedfast"
	},
	"H3333" : {
		"lemma" : "יְצֻקָה",
		"xlit" : "yᵉtsuqâh",
		"pron" : "yets-oo-kaw'",
		"derivation" : "passive participle feminine of H3332 (יָצַק)",
		"def" : "poured out, i.e. run into amould",
		"kjv" : "when it was cast"
	},
	"H3334" : {
		"lemma" : "יָצַר",
		"xlit" : "yâtsar",
		"pron" : "yaw-tsar'",
		"derivation" : "a primitive root",
		"def" : "to press (intransitive), i.e. be narrow; figuratively, be in distress",
		"kjv" : "be distressed, be narrow, be straitened (in straits), be vexed"
	},
	"H3335" : {
		"lemma" : "יָצַר",
		"xlit" : "yâtsar",
		"pron" : "yaw-tsar'",
		"derivation" : "probably identical with H3334 (יָצַר) (through the squeezing into shape); (compare H3331 (יַצַע))",
		"def" : "to mould into a form; especially as apotter; figuratively, to determine (i.e. form a resolution)",
		"kjv" : "[idiom] earthen, fashion, form, frame, make(-r), potter, purpose"
	},
	"H3336" : {
		"lemma" : "יֵצֶר",
		"xlit" : "yêtser",
		"pron" : "yay'-tser",
		"derivation" : "from H3335 (יָצַר)",
		"def" : "a form; figuratively, conception (i.e. purpose)",
		"kjv" : "frame, thing framed, imagination, mind, work"
	},
	"H3337" : {
		"lemma" : "יֵצֶר",
		"xlit" : "Yêtser",
		"pron" : "yay-tser",
		"derivation" : "the same as H3336 (יֵצֶר)",
		"def" : "Jetser, an Israelite",
		"kjv" : "Jezer"
	},
	"H3338" : {
		"lemma" : "יָצֻר",
		"xlit" : "yâtsur",
		"pron" : "yaw-tsoor'",
		"derivation" : "passive participle of H3335 (יָצַר)",
		"def" : "structure, i.e. limb or part",
		"kjv" : "member.ql"
	},
	"H3339" : {
		"lemma" : "יִצְרִי",
		"xlit" : "Yitsrîy",
		"pron" : "yits-ree'",
		"derivation" : "from H3335 (יָצַר); formative",
		"def" : "Jitsri, an Israelite",
		"kjv" : "Isri"
	},
	"H3340" : {
		"lemma" : "יִצְרִי",
		"xlit" : "Yitsrîy",
		"pron" : "yits-ree'",
		"derivation" : "; patronymically from H3337 (יֵצֶר)",
		"def" : "a Jitsrite (collectively) or descendants of Jetser",
		"kjv" : "Jezerites"
	},
	"H3341" : {
		"lemma" : "יָצַת",
		"xlit" : "yâtsath",
		"pron" : "yaw-tsath'",
		"derivation" : "a primitive root",
		"def" : "to burn or set on fire; figuratively, to desolate",
		"kjv" : "burn (up), be desolate, set (on) fire (fire), kindle"
	},
	"H3342" : {
		"lemma" : "יֶקֶב",
		"xlit" : "yeqeb",
		"pron" : "yeh'-keb",
		"derivation" : "from an unused root meaning to excavate",
		"def" : "a trough (as dug out); specifically, a wine-vat (whether the lower one, into which the juice drains; or the upper, in which the grapes are crushed)",
		"kjv" : "fats, presses, press-fat, wine(-press)"
	},
	"H3343" : {
		"lemma" : "יְקַבְצְאֵל",
		"xlit" : "Yᵉqabtsᵉʼêl",
		"pron" : "yek-ab-tseh-ale'",
		"derivation" : "from H6908 (קָבַץ) and H410 (אֵל); God will gather",
		"def" : "Jekabtseel, a place in Palestine",
		"kjv" : "Jekabzeel. Compare H6909 (קַבְצְאֵל)"
	},
	"H3344" : {
		"lemma" : "יָקַד",
		"xlit" : "yâqad",
		"pron" : "yaw-kad'",
		"derivation" : "a primitive root",
		"def" : "to burn",
		"kjv" : "(be) burn(-ing), [idiom] from the hearth, kindle"
	},
	"H3345" : {
		"lemma" : "יְקַד",
		"xlit" : "yᵉqad",
		"pron" : "yek-ad'",
		"derivation" : "(Aramaic) corresponding to H3344 (יָקַד)",
		"def" : "{to burn}",
		"kjv" : "burning"
	},
	"H3346" : {
		"lemma" : "יְקֵדָא",
		"xlit" : "yᵉqêdâʼ",
		"pron" : "yek-ay-daw'",
		"derivation" : "(Aramaic) from H3345 (יְקַד)",
		"def" : "a conflagration",
		"kjv" : "burning"
	},
	"H3347" : {
		"lemma" : "יׇקְדְעָם",
		"xlit" : "Yoqdᵉʻâm",
		"pron" : "yok-deh-awm'",
		"derivation" : "from H3344 (יָקַד) and H5971 (עַם); burning of (the) people",
		"def" : "Jokdeam, a place in Palestine",
		"kjv" : "Jokdeam"
	},
	"H3348" : {
		"lemma" : "יָקֶה",
		"xlit" : "Yâqeh",
		"pron" : "yaw-keh'",
		"derivation" : "from an unused root probably meaning to obey; obedient",
		"def" : "Jakeh, a symbolical name (for Solomon)",
		"kjv" : "Jakeh"
	},
	"H3349" : {
		"lemma" : "יִקָּהָה",
		"xlit" : "yiqqâhâh",
		"pron" : "yik-kaw-haw'",
		"derivation" : "from the same as H3348 (יָקֶה)",
		"def" : "obedience",
		"kjv" : "gathering, to obey"
	},
	"H3350" : {
		"lemma" : "יְקוֹד",
		"xlit" : "yᵉqôwd",
		"pron" : "yek-ode'",
		"derivation" : "from H3344 (יָקַד)",
		"def" : "a burning",
		"kjv" : "burning"
	},
	"H3351" : {
		"lemma" : "יְקוּם",
		"xlit" : "yᵉqûwm",
		"pron" : "yek-oom'",
		"derivation" : "from H6965 (קוּם)",
		"def" : "properly, standing (extant), i.e. by implication, a living thing",
		"kjv" : "(living) substance"
	},
	"H3352" : {
		"lemma" : "יָקוֹשׁ",
		"xlit" : "yâqôwsh",
		"pron" : "yaw-koshe'",
		"derivation" : "from H3369 (יָקֹשׁ)",
		"def" : "properly, entangling; hence, a snarer",
		"kjv" : "fowler"
	},
	"H3353" : {
		"lemma" : "יָקוּשׁ",
		"xlit" : "yâqûwsh",
		"pron" : "yaw-koosh'",
		"derivation" : "passive participle of H3369 (יָקֹשׁ)",
		"def" : "properly, entangled, i.e. by implication (intransitively) a snare, or (transitive) a snarer",
		"kjv" : "fowler, snare"
	},
	"H3354" : {
		"lemma" : "יְקוּתִיאֵל",
		"xlit" : "Yᵉqûwthîyʼêl",
		"pron" : "yek-ooth-ee'-ale",
		"derivation" : "from the same as H3348 (יָקֶה) and H410 (אֵל); obedience of God",
		"def" : "Jekuthiel, an Israelite",
		"kjv" : "Jekuthiel"
	},
	"H3355" : {
		"lemma" : "יׇקְטָן",
		"xlit" : "Yoqṭân",
		"pron" : "yok-tawn'",
		"derivation" : "from H6994 (קָטֹן); he will be made little",
		"def" : "Joktan, an Arabian patriarch",
		"kjv" : "Joktan"
	},
	"H3356" : {
		"lemma" : "יָקִים",
		"xlit" : "Yâqîym",
		"pron" : "yaw-keem'",
		"derivation" : "from H6965 (קוּם); he will raise",
		"def" : "Jakim, the name of two Israelites",
		"kjv" : "Jakim. Compare H3079 (יְהוֹיָקִים)"
	},
	"H3357" : {
		"lemma" : "יַקִּיר",
		"xlit" : "yaqqîyr",
		"pron" : "yak-keer'",
		"derivation" : "from H3365 (יָקַר)",
		"def" : "precious",
		"kjv" : "dear"
	},
	"H3358" : {
		"lemma" : "יַקִּיר",
		"xlit" : "yaqqîyr",
		"pron" : "yak-keer'",
		"derivation" : "(Aramaic) corresponding to H3357 (יַקִּיר)",
		"def" : "{precious}",
		"kjv" : "noble, rare"
	},
	"H3359" : {
		"lemma" : "יְקַמְיָה",
		"xlit" : "Yᵉqamyâh",
		"pron" : "yek-am-yaw'",
		"derivation" : "from H6965 (קוּם) and H3050 (יָהּ); Jah will rise",
		"def" : "Jekamjah, the name of two Israelites",
		"kjv" : "Jekamiah. Compare H3079 (יְהוֹיָקִים)"
	},
	"H3360" : {
		"lemma" : "יְקַמְעָם",
		"xlit" : "Yᵉqamʻâm",
		"pron" : "yek-am'-awm",
		"derivation" : "from H6965 (קוּם) and H5971 (עַם); (the) people will rise",
		"def" : "Jekamam, an Israelite",
		"kjv" : "Jekameam. Compare H3079 (יְהוֹיָקִים), H3361 (יׇקְמְעָם)"
	},
	"H3361" : {
		"lemma" : "יׇקְמְעָם",
		"xlit" : "Yoqmᵉʻâm",
		"pron" : "yok-meh-awm'",
		"derivation" : "from H6965 (קוּם) and H5971 (עַם); (the) people will be raised",
		"def" : "Jokmeam, a place in Palestine",
		"kjv" : "Jokmeam. Compare H3360 (יְקַמְעָם), H3362 (יׇקְנְעָם)"
	},
	"H3362" : {
		"lemma" : "יׇקְנְעָם",
		"xlit" : "Yoqnᵉʻâm",
		"pron" : "yok-neh-awm'",
		"derivation" : "from H6969 (קוּן) and H5971 (עַם); (the) people will be lamented",
		"def" : "Jokneam, a place in Palestine",
		"kjv" : "Jokneam"
	},
	"H3363" : {
		"lemma" : "יָקַע",
		"xlit" : "yâqaʻ",
		"pron" : "yaw-kah'",
		"derivation" : "a primitive root",
		"def" : "properly, to sever oneself, i.e. (by implication) to be dislocated; figuratively, to abandon; causatively, to impale (and thus allow to drop to pieces by rotting)",
		"kjv" : "be alienated, depart, hang (up), be out of joint"
	},
	"H3364" : {
		"lemma" : "יָקַץ",
		"xlit" : "yâqats",
		"pron" : "yaw-kats'",
		"derivation" : "a primitive root",
		"def" : "to awake (intransitive)",
		"kjv" : "(be) awake(-d)"
	},
	"H3365" : {
		"lemma" : "יָקַר",
		"xlit" : "yâqar",
		"pron" : "yaw-kar'",
		"derivation" : "a primitive root",
		"def" : "properly, apparently, to be heavy, i.e. (figuratively) valuable; causatively, to make rare (figuratively, to inhibit)",
		"kjv" : "be (make) precious, be prized, be set by, withdraw"
	},
	"H3366" : {
		"lemma" : "יְקָר",
		"xlit" : "yᵉqâr",
		"pron" : "yek-awr'",
		"derivation" : "from H3365 (יָקַר)",
		"def" : "value, i.e. (concretely) wealth; abstractly, costliness, dignity",
		"kjv" : "honour, precious (things), price"
	},
	"H3367" : {
		"lemma" : "יְקָר",
		"xlit" : "yᵉqâr",
		"pron" : "yek-awr'",
		"derivation" : "(Aramaic) corresponding to H3366 (יְקָר)",
		"def" : "{value, i.e. (concretely) wealth; abstractly, costliness, dignity}",
		"kjv" : "glory, honour"
	},
	"H3368" : {
		"lemma" : "יָקָר",
		"xlit" : "yâqâr",
		"pron" : "yaw-kawr'",
		"derivation" : "from H3365 (יָקַר)",
		"def" : "valuable (objectively or subjectively)",
		"kjv" : "brightness, clear, costly, excellent, fat, honourable women, precious, reputation"
	},
	"H3369" : {
		"lemma" : "יָקֹשׁ",
		"xlit" : "yâqôsh",
		"pron" : "yaw-koshe'",
		"derivation" : "a primitive root",
		"def" : "to ensnare (literally or figuratively)",
		"kjv" : "fowler (lay a) snare"
	},
	"H3370" : {
		"lemma" : "יׇקְשָׁן",
		"xlit" : "Yoqshân",
		"pron" : "yok-shawn'",
		"derivation" : "from H3369 (יָקֹשׁ); insidious",
		"def" : "Jokshan, an Arabian patriarch",
		"kjv" : "Jokshan"
	},
	"H3371" : {
		"lemma" : "יׇקְתְאֵל",
		"xlit" : "Yoqthᵉʼêl",
		"pron" : "yok-theh-ale'",
		"derivation" : "probably from the same as H3348 (יָקֶה) and H410 (אֵל); veneration of God (compare H3354 (יְקוּתִיאֵל))",
		"def" : "Joktheel, the name of a place in Palestine, and of one in Idumaea",
		"kjv" : "Joktheel"
	},
	"H3372" : {
		"lemma" : "יָרֵא",
		"xlit" : "yârêʼ",
		"pron" : "yaw-ray'",
		"derivation" : "a primitive root",
		"def" : "to fear; morally, to revere; caus. to frighten",
		"kjv" : "affright, be (make) afraid, dread(-ful), (put in) fear(-ful, -fully, -ing), (be had in) reverence(-end), [idiom] see, terrible (act, -ness, thing)"
	},
	"H3373" : {
		"lemma" : "יָרֵא",
		"xlit" : "yârêʼ",
		"pron" : "yaw-ray'",
		"derivation" : "from H3372 (יָרֵא)",
		"def" : "fearing; morally, reverent",
		"kjv" : "afraid, fear (-ful)"
	},
	"H3374" : {
		"lemma" : "יִרְאָה",
		"xlit" : "yirʼâh",
		"pron" : "yir-aw'",
		"derivation" : "feminine of H3373 (יָרֵא)",
		"def" : "fear (also used as infinitive); morally, reverence",
		"kjv" : "[idiom] dreadful, [idiom] exceedingly, fear(-fulness)"
	},
	"H3375" : {
		"lemma" : "יִרְאוֹן",
		"xlit" : "Yirʼôwn",
		"pron" : "yir-ohn'",
		"derivation" : "from H3372 (יָרֵא); fearfulness",
		"def" : "Jiron, a place in Palestine",
		"kjv" : "Iron"
	},
	"H3376" : {
		"lemma" : "יִרְאִיָּיה",
		"xlit" : "Yirʼîyâyh",
		"pron" : "yir-ee-yaw'",
		"derivation" : "from H3373 (יָרֵא) and H3050 (יָהּ); fearful of Jah",
		"def" : "Jirijah, an Israelite",
		"kjv" : "Irijah"
	},
	"H3377" : {
		"lemma" : "יָרֵב",
		"xlit" : "Yârêb",
		"pron" : "yaw-rabe'",
		"derivation" : "from H7378 (רִיב); he will contend",
		"def" : "Jareb, a symbolical name for Assyria",
		"kjv" : "Jareb. Compare H3402 (יָרִיב)"
	},
	"H3378" : {
		"lemma" : "יְרֻבַּעַל",
		"xlit" : "Yᵉrubbaʻal",
		"pron" : "yer-oob-bah'-al",
		"derivation" : "from H7378 (רִיב) and H1168 (בַּעַל); Baal will contend",
		"def" : "Jerubbaal, a symbolic name of Gideon",
		"kjv" : "Jerubbaal"
	},
	"H3379" : {
		"lemma" : "יָרׇבְעָם",
		"xlit" : "Yârobʻâm",
		"pron" : "yaw-rob-awm'",
		"derivation" : "from H7378 (רִיב) and H5971 (עַם); (the) people will contend",
		"def" : "Jarobam, the name of two Israelite kings",
		"kjv" : "Jeroboam"
	},
	"H3380" : {
		"lemma" : "יְרֻבֶּשֶׁת",
		"xlit" : "Yᵉrubbesheth",
		"pron" : "yer-oob-beh'-sheth",
		"derivation" : "from H7378 (רִיב) and H1322 (בֹּשֶׁת); shame (i.e. the idol) will contend",
		"def" : "Jerubbesheth, a symbolic name for Gideon",
		"kjv" : "Jerubbesheth"
	},
	"H3381" : {
		"lemma" : "יָרַד",
		"xlit" : "yârad",
		"pron" : "yaw-rad'",
		"derivation" : "a primitive root",
		"def" : "to descend (literally, to go downwards; or conventionally to a lower region, as the shore, a boundary, the enemy, etc.; or figuratively, to fall); causatively, to bring down (in all the above applications)",
		"kjv" : "[idiom] abundantly, bring down, carry down, cast down, (cause to) come(-ing) down, fall (down), get down, go(-ing) down(-ward), hang down, [idiom] indeed, let down, light (down), put down (off), (cause to, let) run down, sink, subdue, take down"
	},
	"H3382" : {
		"lemma" : "יֶרֶד",
		"xlit" : "Yered",
		"pron" : "yeh'-red",
		"derivation" : "from H3381 (יָרַד); a descent",
		"def" : "Jered, the name of an antediluvian, and of an Israelite",
		"kjv" : "Jared"
	},
	"H3383" : {
		"lemma" : "יַרְדֵּן",
		"xlit" : "Yardên",
		"pron" : "yar-dane'",
		"derivation" : "from H3381 (יָרַד); a descender",
		"def" : "Jarden, the principal river of Palestine",
		"kjv" : "Jordan"
	},
	"H3384" : {
		"lemma" : "יָרָה",
		"xlit" : "yârâh",
		"pron" : "yaw-raw'",
		"derivation" : "or (2 Chronicles 26:15) יָרָא; a primitive root",
		"def" : "properly, to flow as water (i.e. to rain); transitively, to lay or throw (especially an arrow, i.e. to shoot); figuratively, to point out (as if by aiming the finger), to teach",
		"kjv" : "([phrase]) archer, cast, direct, inform, instruct, lay, shew, shoot, teach(-er,-ing), through"
	},
	"H3385" : {
		"lemma" : "יְרוּאֵל",
		"xlit" : "Yᵉrûwʼêl",
		"pron" : "yer-oo-ale'",
		"derivation" : "from H3384 (יָרָה) and H410 (אֵל); founded of God",
		"def" : "Jeruel, a place in Palestine",
		"kjv" : "Jeruel"
	},
	"H3386" : {
		"lemma" : "יָרוֹחַ",
		"xlit" : "Yârôwach",
		"pron" : "yaw-ro'-akh",
		"derivation" : "perhaps denominative from H3394 (יָרֵחַ); (born at the) new moon",
		"def" : "Jaroach, an Israelite",
		"kjv" : "Jaroah"
	},
	"H3387" : {
		"lemma" : "יָרוֹק",
		"xlit" : "yârôwq",
		"pron" : "yaw-roke'",
		"derivation" : "from H3417 (יָרָק)",
		"def" : "green, i.e. an herb",
		"kjv" : "green thing"
	},
	"H3388" : {
		"lemma" : "יְרוּשָׁא",
		"xlit" : "Yᵉrûwshâʼ",
		"pron" : "yer-oo-shaw'",
		"derivation" : "or יְרוּשָׁה feminine passive participle of H3423 (יָרַשׁ); possessed",
		"def" : "Jerusha or Jerushah, as Israelitess",
		"kjv" : "Jerusha, Jerushah"
	},
	"H3389" : {
		"lemma" : "יְרוּשָׁלַ͏ִם",
		"xlit" : "Yᵉrûwshâlaim",
		"pron" : "yer-oo-shaw-lah'-im",
		"derivation" : "rarely יְרוּשָׁלַיִם; a dual (in allusion to its two main hills (the true pointing, at least of the former reading, seems to be that of H3390 (יְרוּשָׁלֵם))); probably from (the passive participle of) H3384 (יָרָה) and H7999 (שָׁלַם); founded peaceful",
		"def" : "Jerushalaim or Jerushalem, the capital city of Palestine",
		"kjv" : "Jerusalem"
	},
	"H3390" : {
		"lemma" : "יְרוּשָׁלֵם",
		"xlit" : "Yᵉrûwshâlêm",
		"pron" : "yer-oo-shaw-lame'",
		"derivation" : "(Aramaic) corresponding to H3389 (יְרוּשָׁלַ͏ִם)",
		"def" : "{Jerusalem}",
		"kjv" : "{Jerusalem}"
	},
	"H3391" : {
		"lemma" : "יֶרַח",
		"xlit" : "yerach",
		"pron" : "yeh'-rakh",
		"derivation" : "from a unused root of uncertain signification",
		"def" : "a lunation, i.e. month",
		"kjv" : "month, moon"
	},
	"H3392" : {
		"lemma" : "יֶרַח",
		"xlit" : "Yerach",
		"pron" : "yeh'-rakh",
		"derivation" : "the same as H3391 (יֶרַח)",
		"def" : "Jerach, an Arabian patriarch",
		"kjv" : "Jerah"
	},
	"H3393" : {
		"lemma" : "יְרַח",
		"xlit" : "yᵉrach",
		"pron" : "yeh-rakh'",
		"derivation" : "(Aramaic) corresponding to H3391 (יֶרַח)",
		"def" : "a month",
		"kjv" : "month"
	},
	"H3394" : {
		"lemma" : "יָרֵחַ",
		"xlit" : "yârêach",
		"pron" : "yaw-ray'-akh",
		"derivation" : "from the same as H3391 (יֶרַח)",
		"def" : "the moon",
		"kjv" : "moon. Yrechow. See H3405 (יְרִיחוֹ)"
	},
	"H3395" : {
		"lemma" : "יְרֹחָם",
		"xlit" : "Yᵉrôchâm",
		"pron" : "yer-o-khawm'",
		"derivation" : "from H7355 (רָחַם); compassionate",
		"def" : "Jerocham, the name of seven or eight Israelites",
		"kjv" : "Jeroham"
	},
	"H3396" : {
		"lemma" : "יְרַחְמְאֵל",
		"xlit" : "Yᵉrachmᵉʼêl",
		"pron" : "yer-akh-meh-ale'",
		"derivation" : "from H7355 (רָחַם) and H410 (אֵל); God will compassionate",
		"def" : "Jerachmeel, the name of three Israelites",
		"kjv" : "Jerahmeel"
	},
	"H3397" : {
		"lemma" : "יְרַחְמְאֵלִי",
		"xlit" : "Yᵉrachmᵉʼêlîy",
		"pron" : "yer-akh-meh-ay-lee'",
		"derivation" : "patronymically from H3396 (יְרַחְמְאֵל)",
		"def" : "a Jerachmeelite or descendant of Jerachmeel",
		"kjv" : "Jerahmeelites"
	},
	"H3398" : {
		"lemma" : "יַרְחָע",
		"xlit" : "Yarchâʻ",
		"pron" : "yar-khaw'",
		"derivation" : "probably of Egyptian origin",
		"def" : "Jarcha, an Egyptian",
		"kjv" : "Jarha"
	},
	"H3399" : {
		"lemma" : "יָרַט",
		"xlit" : "yâraṭ",
		"pron" : "yaw-rat'",
		"derivation" : "a primitive root",
		"def" : "to precipitate or hurl (rush) headlong; (intransitively) to be rash",
		"kjv" : "be perverse, turn over"
	},
	"H3400" : {
		"lemma" : "יְרִיאֵל",
		"xlit" : "Yᵉrîyʼêl",
		"pron" : "yer-ee-ale'",
		"derivation" : "from H3384 (יָרָה) and H410 (אֵל); thrown of God",
		"def" : "Jeriel, an Israelite",
		"kjv" : "Jeriel. Compare H3385 (יְרוּאֵל)"
	},
	"H3401" : {
		"lemma" : "יָרִיב",
		"xlit" : "yârîyb",
		"pron" : "yaw-rebe'",
		"derivation" : "from H7378 (רִיב)",
		"def" : "literally he will contend; properly, adjective, contentious; used as noun, an adversary",
		"kjv" : "that content(-eth), that strive"
	},
	"H3402" : {
		"lemma" : "יָרִיב",
		"xlit" : "Yârîyb",
		"pron" : "yaw-rebe'",
		"derivation" : "the same as H3401 (יָרִיב)",
		"def" : "Jarib, the name of three Israelites",
		"kjv" : "Jarib"
	},
	"H3403" : {
		"lemma" : "יְרִיבַי",
		"xlit" : "Yᵉrîybay",
		"pron" : "yer-eeb-ah'ee",
		"derivation" : "from H3401 (יָרִיב); contentious",
		"def" : "Jeribai, an Israelite",
		"kjv" : "Jeribai"
	},
	"H3404" : {
		"lemma" : "יְרִיָּה",
		"xlit" : "Yᵉrîyâh",
		"pron" : "yer-ee-yaw'",
		"derivation" : "or יְרִיָּהוּ; from H3384 (יָרָה) and H3050 (יָהּ); Jah will throw",
		"def" : "Jerijah, an Israelite",
		"kjv" : "Jeriah, Jerijah"
	},
	"H3405" : {
		"lemma" : "יְרִיחוֹ",
		"xlit" : "Yᵉrîychôw",
		"pron" : "yer-ee-kho'",
		"derivation" : "or יְרֵחוֹ; or variation (1 Kings 16:34) יְרִיחֹה; perhaps from H3394 (יָרֵחַ); its month; or else from H7306 (רוּחַ); fragrant",
		"def" : "Jericho or Jerecho, a place in Palestine",
		"kjv" : "Jericho"
	},
	"H3406" : {
		"lemma" : "יְרִימוֹת",
		"xlit" : "Yᵉrîymôwth",
		"pron" : "yer-ee-mohth'",
		"derivation" : "or יְרֵימוֹת; or יְרֵמוֹת; feminine plural from H7311 (רוּם); elevations",
		"def" : "Jerimoth or Jeremoth, the name of twelve Israelites",
		"kjv" : "Jermoth, Jerimoth, and Ramoth (from the margin)"
	},
	"H3407" : {
		"lemma" : "יְרִיעָה",
		"xlit" : "yᵉrîyʻâh",
		"pron" : "yer-ee-aw'",
		"derivation" : "from H3415 (יָרַע)",
		"def" : "a hanging (as tremulous)",
		"kjv" : "curtain"
	},
	"H3408" : {
		"lemma" : "יְרִיעוֹת",
		"xlit" : "Yᵉrîyʻôwth",
		"pron" : "yer-ee-ohth'",
		"derivation" : "plural of H3407 (יְרִיעָה); curtains",
		"def" : "Jerioth, an Israelitess",
		"kjv" : "Jerioth"
	},
	"H3409" : {
		"lemma" : "יָרֵךְ",
		"xlit" : "yârêk",
		"pron" : "yaw-rake'",
		"derivation" : "from an unused root meaning to be soft",
		"def" : "the thigh (from its fleshy softness); by euphemistically the generative parts; figuratively, a shank, flank, side",
		"kjv" : "[idiom] body, loins, shaft, side, thigh"
	},
	"H3410" : {
		"lemma" : "יַרְכָא",
		"xlit" : "yarkâʼ",
		"pron" : "yar-kaw'",
		"derivation" : "(Aramaic) corresponding to H3411 (יְרֵכָה)",
		"def" : "a thigh",
		"kjv" : "thigh"
	},
	"H3411" : {
		"lemma" : "יְרֵכָה",
		"xlit" : "yᵉrêkâh",
		"pron" : "yer-ay-kaw'",
		"derivation" : "feminine of H3409 (יָרֵךְ)",
		"def" : "properly, the flank; but used only figuratively, the rear or recess",
		"kjv" : "border, coast, part, quarter, side"
	},
	"H3412" : {
		"lemma" : "יַרְמוּת",
		"xlit" : "Yarmûwth",
		"pron" : "yar-mooth'",
		"derivation" : "from H7311 (רוּם); elevation",
		"def" : "Jarmuth, the name of two places in Palestine",
		"kjv" : "Jarmuth"
	},
	"H3413" : {
		"lemma" : "יְרֵמַי",
		"xlit" : "Yᵉrêmay",
		"pron" : "yer-ay-mah'-ee",
		"derivation" : "from H7311 (רוּם); elevated",
		"def" : "Jeremai, an Israelite",
		"kjv" : "Jeremai"
	},
	"H3414" : {
		"lemma" : "יִרְמְיָה",
		"xlit" : "Yirmᵉyâh",
		"pron" : "yir-meh-yaw'",
		"derivation" : "or יִרְמְיָהוּ; from H7311 (רוּם) and H3050 (יָהּ); Jah will rise",
		"def" : "Jirmejah, the name of eight or nine Israelites",
		"kjv" : "Jeremiah"
	},
	"H3415" : {
		"lemma" : "יָרַע",
		"xlit" : "yâraʻ",
		"pron" : "yaw-rah'",
		"derivation" : "a primitive root",
		"def" : "properly, to be broken up (with any violent action) i.e. (figuratively) to fear",
		"kjv" : "be grevious (only Isaiah 15:4; the rest belong to H7489 (רָעַע))"
	},
	"H3416" : {
		"lemma" : "יִרְפְּאֵל",
		"xlit" : "Yirpᵉʼêl",
		"pron" : "yir-peh-ale'",
		"derivation" : "from H7495 (רָפָא) and H410 (אֵל); God will heal",
		"def" : "Jirpeel, a place in Palestine",
		"kjv" : "Irpeel"
	},
	"H3417" : {
		"lemma" : "יָרָק",
		"xlit" : "yârâq",
		"pron" : "yaw-rak'",
		"derivation" : "a primitive root",
		"def" : "to spit",
		"kjv" : "[idiom] but, spit"
	},
	"H3418" : {
		"lemma" : "יֶרֶק",
		"xlit" : "yereq",
		"pron" : "yeh'-rek",
		"derivation" : "from H3417 (יָרָק) (in the sense of vacuity of color)",
		"def" : "properly, pallor, i.e. hence, the yellowish green of young and sickly vegetation; concretely, verdure, i.e. grass or vegetation",
		"kjv" : "grass, green (thing)"
	},
	"H3419" : {
		"lemma" : "יָרָק",
		"xlit" : "yârâq",
		"pron" : "yaw-rawk'",
		"derivation" : "from the same as H3418 (יֶרֶק)",
		"def" : "properly, green; concretely, a vegetable",
		"kjv" : "green, herbs"
	},
	"H3420" : {
		"lemma" : "יֵרָקוֹן",
		"xlit" : "yêrâqôwn",
		"pron" : "yay-raw-kone'",
		"derivation" : "from H3418 (יֶרֶק)",
		"def" : "paleness, whether of persons (from fright), or of plants (from drought)",
		"kjv" : "greenish, yellow"
	},
	"H3421" : {
		"lemma" : "יׇרְקְעָם",
		"xlit" : "Yorqᵉʻâm",
		"pron" : "yor-keh-awm'",
		"derivation" : "from H7324 (רוּק) and H5971 (עַם); people will be poured forth",
		"def" : "Jorkeam, a place in Palestine",
		"kjv" : "Jorkeam"
	},
	"H3422" : {
		"lemma" : "יְרַקְרַק",
		"xlit" : "yᵉraqraq",
		"pron" : "yer-ak-rak'",
		"derivation" : "from the same as H3418 (יֶרֶק)",
		"def" : "yellowishness",
		"kjv" : "greenish, yellow"
	},
	"H3423" : {
		"lemma" : "יָרַשׁ",
		"xlit" : "yârash",
		"pron" : "yaw-rash'",
		"derivation" : "or יָרֵשׁ; a primitive root",
		"def" : "to occupy (by driving out previous tenants, and possessing in their place); by implication, to seize, to rob, to inherit; also to expel, to impoverish, to ruin",
		"kjv" : "cast out, consume, destroy, disinherit, dispossess, drive(-ing) out, enjoy, expel, [idiom] without fail, (give to, leave for) inherit(-ance, -or) [phrase] magistrate, be (make) poor, come to poverty, (give to, make to) possess, get (have) in (take) possession, seize upon, succeed, [idiom] utterly"
	},
	"H3424" : {
		"lemma" : "יְרֵשָׁה",
		"xlit" : "yᵉrêshâh",
		"pron" : "yer-ay-shaw'",
		"derivation" : "from H3423 (יָרַשׁ)",
		"def" : "occupancy",
		"kjv" : "possession"
	},
	"H3425" : {
		"lemma" : "יְרֻשָּׁה",
		"xlit" : "yᵉrushshâh",
		"pron" : "yer-oosh-shaw'",
		"derivation" : "from H3423 (יָרַשׁ)",
		"def" : "something occupied; a conquest; also a patrimony",
		"kjv" : "heritage, inheritance, possession"
	},
	"H3426" : {
		"lemma" : "יֵשׁ",
		"xlit" : "yêsh",
		"pron" : "yaysh",
		"derivation" : "perhaps from an unused root meaning to stand out, or exist; entity; used adverbially or as a copula for the substantive verb (H1961 (הָיָה))",
		"def" : "there is or are (or any other form of the verb to be, as may suit the connection)",
		"kjv" : "(there) are, (he, it, shall, there, there may, there shall, there should) be, thou do, had, hast, (which) hath, (I, shalt, that) have, (he, it, there) is, substance, it (there) was, (there) were, ye will, thou wilt, wouldest"
	},
	"H3427" : {
		"lemma" : "יָשַׁב",
		"xlit" : "yâshab",
		"pron" : "yaw-shab'",
		"derivation" : "a primitive root",
		"def" : "properly, to sit down (specifically as judge. in ambush, in quiet); by implication, to dwell, to remain; causatively, to settle, to marry",
		"kjv" : "(make to) abide(-ing), continue, (cause to, make to) dwell(-ing), ease self, endure, establish, [idiom] fail, habitation, haunt, (make to) inhabit(-ant), make to keep (house), lurking, [idiom] marry(-ing), (bring again to) place, remain, return, seat, set(-tle), (down-) sit(-down, still, -ting down, -ting (place) -uate), take, tarry"
	},
	"H3428" : {
		"lemma" : "יֶשֶׁבְאָב",
		"xlit" : "Yeshebʼâb",
		"pron" : "yeh-sheb-awb'",
		"derivation" : "from H3427 (יָשַׁב) and H1 (אָב); seat of (his) father",
		"def" : "Jeshebab, an Israelite",
		"kjv" : "Jeshebeab"
	},
	"H3429" : {
		"lemma" : "יֹשֵׁב בַּשֶּׁבֶת",
		"xlit" : "Yôshêb bash-Shebeth",
		"pron" : "yo-shabe' bash-sheh'-beth",
		"derivation" : "from the active participle of H3427 (יָשַׁב) and H7674 (שֶׁבֶת), with a preposition and the article interposed; sitting in the seat",
		"def" : "Joshebbash-Shebeth, an Israelite",
		"kjv" : "that sat in the seat"
	},
	"H3430" : {
		"lemma" : "יִשְׁבּוֹ בְּנֹב",
		"xlit" : "Yishbôw bᵉ-Nôb",
		"pron" : "yish-bo'beh-nobe",
		"derivation" : "from H3427 (יָשַׁב) and H5011 (נֹב), with a pronominal suffix and a preposition interposed; his dwelling (is) in Nob",
		"def" : "Jishbo-be-Nob, a Philistine",
		"kjv" : "Ishbi-benob (from the margin)"
	},
	"H3431" : {
		"lemma" : "יִשְׁבַּח",
		"xlit" : "Yishbach",
		"pron" : "yish-bakh'",
		"derivation" : "from H7623 (שָׁבַח); he will praise",
		"def" : "Jishbach, an Israelite",
		"kjv" : "Ishbah"
	},
	"H3432" : {
		"lemma" : "יָשֻׁבִי",
		"xlit" : "Yâshubîy",
		"pron" : "yaw-shoo-bee'",
		"derivation" : "patronymically from H3437 (יָשׁוּב)",
		"def" : "a Jashubite, or descendant of Jashub",
		"kjv" : "Jashubites"
	},
	"H3433" : {
		"lemma" : "יָשֻׁבִי לֶחֶם",
		"xlit" : "Yâshubîy Lechem",
		"pron" : "yaw-shoo-bee' leh'-khem",
		"derivation" : "from H7725 (שׁוּב) and H3899 (לֶחֶם); returner of bread",
		"def" : "Jashubi-Lechem, an Israelite",
		"kjv" : "Jashubi-lehem. (Prob. the text should be pointed יֹשְׁבֵי לֶחֶם and rendered '(they were) inhabitants of Lechem,' i.e. of Bethlehem (by contraction). Compare H3902 (לַחְמִי))"
	},
	"H3434" : {
		"lemma" : "יָשׇׁבְעָם",
		"xlit" : "Yâshobʻâm",
		"pron" : "yaw-shob-awm'",
		"derivation" : "from H7725 (שׁוּב) and H5971 (עַם); people will return",
		"def" : "Jashobam, the name of two or three Israelites",
		"kjv" : "Jashobeam"
	},
	"H3435" : {
		"lemma" : "יִשְׁבָּק",
		"xlit" : "Yishbâq",
		"pron" : "yish-bawk'",
		"derivation" : "from an unused root corresponding to H7662 (שְׁבַק); he will leave",
		"def" : "Jishbak, a son of Abraham",
		"kjv" : "Ishbak"
	},
	"H3436" : {
		"lemma" : "יׇשְׁבְּקָשָׁה",
		"xlit" : "Yoshbᵉqâshâh",
		"pron" : "yosh-bek-aw-shaw'",
		"derivation" : "from H3427 (יָשַׁב) and H7186 (קָשֶׁה); a hard seat",
		"def" : "Joshbekashah, an Israelite",
		"kjv" : "Joshbekashah"
	},
	"H3437" : {
		"lemma" : "יָשׁוּב",
		"xlit" : "Yâshûwb",
		"pron" : "yaw-shoob'",
		"derivation" : "or יָשִׁיב; from H7725 (שׁוּב); he will return",
		"def" : "Jashub, the name of two Israelites",
		"kjv" : "Jashub"
	},
	"H3438" : {
		"lemma" : "יִשְׁוָה",
		"xlit" : "Yishvâh",
		"pron" : "yish-vaw'",
		"derivation" : "from H7737 (שָׁוָה); he will level",
		"def" : "Jishvah, an Israelite",
		"kjv" : "Ishvah, Isvah"
	},
	"H3439" : {
		"lemma" : "יְשׁוֹחָיָה",
		"xlit" : "Yᵉshôwchâyâh",
		"pron" : "yesh-o-khaw-yaw'",
		"derivation" : "from the same as H3445 (יֶשַׁח) and H3050 (יָהּ); Jah will empty",
		"def" : "Jeshochajah, an Israelite",
		"kjv" : "Jeshoaiah"
	},
	"H3440" : {
		"lemma" : "יִשְׁוִי",
		"xlit" : "Yishvîy",
		"pron" : "yish-vee'",
		"derivation" : "from H7737 (שָׁוָה); level",
		"def" : "Jishvi, the name of two Israelites",
		"kjv" : "Ishuai, Ishvi, Isui, Jesui"
	},
	"H3441" : {
		"lemma" : "יִשְׁוִי",
		"xlit" : "Yishvîy",
		"pron" : "yish-vee'",
		"derivation" : "patronymically from H3440 (יִשְׁוִי)",
		"def" : "a Jishvite (collectively) or descendants of Jishvi",
		"kjv" : "Jesuites"
	},
	"H3442" : {
		"lemma" : "יֵשׁוּעַ",
		"xlit" : "Yêshûwaʻ",
		"pron" : "yay-shoo'-ah",
		"derivation" : "for H3091 (יְהוֹשׁוּעַ); he will save",
		"def" : "Jeshua, the name of ten Israelites, also of a place in Palestine",
		"kjv" : "Jeshua"
	},
	"H3443" : {
		"lemma" : "יֵשׁוּעַ",
		"xlit" : "Yêshûwaʻ",
		"pron" : "yay-shoo'-ah",
		"derivation" : "(Aramaic) corresponding to H3442 (יֵשׁוּעַ)",
		"def" : "{Jeshua, the name of ten Israelites, also of a place in Palestine}",
		"kjv" : "Jeshua"
	},
	"H3444" : {
		"lemma" : "יְשׁוּעָה",
		"xlit" : "yᵉshûwʻâh",
		"pron" : "yesh-oo'-aw",
		"derivation" : "feminine passive participle of H3467 (יָשַׁע)",
		"def" : "something saved, i.e. (abstractly) deliverance; hence, aid, victory, prosperity",
		"kjv" : "deliverance, health, help(-ing), salvation, save, saving (health), welfare"
	},
	"H3445" : {
		"lemma" : "יֶשַׁח",
		"xlit" : "yeshach",
		"pron" : "yeh'-shakh",
		"derivation" : "from an unused root meaning to gape (as the empty stomach)",
		"def" : "hunger",
		"kjv" : "casting down"
	},
	"H3446" : {
		"lemma" : "יִשְׂחָק",
		"xlit" : "Yischâq",
		"pron" : "yis-khawk'",
		"derivation" : "from H7831 (שַׁחֲצוֹם); he will laugh",
		"def" : "Jischak, the heir of Abraham",
		"kjv" : "Isaac. Compare H3327 (יִצְחָק)"
	},
	"H3447" : {
		"lemma" : "יָשַׁט",
		"xlit" : "yâshaṭ",
		"pron" : "yaw-shat'",
		"derivation" : "a primitive root",
		"def" : "to extend",
		"kjv" : "hold out"
	},
	"H3448" : {
		"lemma" : "יִשַׁי",
		"xlit" : "Yishay",
		"pron" : "yee-shah'-ee",
		"derivation" : "by Aramaic אִישַׁי; from the same as H3426 (יֵשׁ); extant",
		"def" : "Jishai, David's father",
		"kjv" : "Jesse"
	},
	"H3449" : {
		"lemma" : "יִשִּׁיָּה",
		"xlit" : "Yishshîyâh",
		"pron" : "yish-shee-yaw'",
		"derivation" : "or יִשִּׁיָּהוּ; from H5383 (נָשָׁה) and H3050 (יָהּ); Jah will lend",
		"def" : "Jishshijah, the name of five Israelites",
		"kjv" : "Ishiah, Isshiah, Ishijah, Jesiah"
	},
	"H3450" : {
		"lemma" : "יְשִׁימָאֵל",
		"xlit" : "Yᵉshîymâʼêl",
		"pron" : "yes-eem-aw-ale'",
		"derivation" : "from H7760 (שׂוּם) and H410 (אֵל); God will place",
		"def" : "Jesimael, an Israelite",
		"kjv" : "Jesimael"
	},
	"H3451" : {
		"lemma" : "יְשִׁימַה",
		"xlit" : "yᵉshîymah",
		"pron" : "yesh-ee-maw'",
		"derivation" : "from H3456 (יָשַׁם)",
		"def" : "desolation",
		"kjv" : "let death seize (from the margin)"
	},
	"H3452" : {
		"lemma" : "יְשִׁימוֹן",
		"xlit" : "yᵉshîymôwn",
		"pron" : "yesh-ee-mone'",
		"derivation" : "from H3456 (יָשַׁם)",
		"def" : "a desolation",
		"kjv" : "desert, Jeshimon, solitary, wilderness"
	},
	"H3453" : {
		"lemma" : "יָשִׁישׁ",
		"xlit" : "yâshîysh",
		"pron" : "yaw-sheesh'",
		"derivation" : "from H3486 (יָשֵׁשׁ)",
		"def" : "an old man",
		"kjv" : "(very) aged (man), ancient, very old"
	},
	"H3454" : {
		"lemma" : "יְשִׁישָׁי",
		"xlit" : "Yᵉshîyshây",
		"pron" : "yesh-ee-shah'-ee",
		"derivation" : "from H3453 (יָשִׁישׁ); aged",
		"def" : "Jeshishai, an Israelite",
		"kjv" : "Jeshishai"
	},
	"H3455" : {
		"lemma" : "יָשַׂם",
		"xlit" : "yâsam",
		"pron" : "yaw-sam'",
		"derivation" : "a primitive root",
		"def" : "to place; intransitively, to be placed",
		"kjv" : "be put (set)"
	},
	"H3456" : {
		"lemma" : "יָשַׁם",
		"xlit" : "yâsham",
		"pron" : "yaw-sham'",
		"derivation" : "a primitive root",
		"def" : "to lie waste",
		"kjv" : "be desolate"
	},
	"H3457" : {
		"lemma" : "יִשְׁמָא",
		"xlit" : "Yishmâʼ",
		"pron" : "yish-maw'",
		"derivation" : "from H3456 (יָשַׁם); desolate",
		"def" : "Jishma, an Israelite",
		"kjv" : "Ishma"
	},
	"H3458" : {
		"lemma" : "יִשְׁמָעֵאל",
		"xlit" : "Yishmâʻêʼl",
		"pron" : "yish-maw-ale'",
		"derivation" : "from H8085 (שָׁמַע) and H410 (אֵל); God will hear",
		"def" : "Jishmael, the name of Abraham's oldest son, and of five Israelites",
		"kjv" : "Ishmael"
	},
	"H3459" : {
		"lemma" : "יִשְׁמָעֵאלִי",
		"xlit" : "Yishmâʻêʼlîy",
		"pron" : "yish-maw-ay-lee'",
		"derivation" : "patronymically from H3458 (יִשְׁמָעֵאל)",
		"def" : "a Jishmaelite or descendant of Jishmael",
		"kjv" : "Ishmaelite"
	},
	"H3460" : {
		"lemma" : "יִשְׁמַעְיָה",
		"xlit" : "Yishmaʻyâh",
		"pron" : "yish-mah-yaw'",
		"derivation" : "or יִשְׁמַעְיָהוּlemma יְשְׁמַעְיָהוּ first vowel, corrected to יִשְׁמַעְיָהוּ; from H8085 (שָׁמַע) and H3050 (יָהּ); Jah will hear",
		"def" : "Jishmajah, the name of two Israelites",
		"kjv" : "Ishmaiah"
	},
	"H3461" : {
		"lemma" : "יִשְׁמְרַי",
		"xlit" : "Yishmᵉray",
		"pron" : "yish-mer-ah'-ee",
		"derivation" : "from H8104 (שָׁמַר); preservative",
		"def" : "Jishmerai, an Israelite",
		"kjv" : "Ishmerai"
	},
	"H3462" : {
		"lemma" : "יָשֵׁן",
		"xlit" : "yâshên",
		"pron" : "yaw-shane'",
		"derivation" : "a primitive root",
		"def" : "properly, to be slack or languid, i.e. (by implication) sleep (figuratively, to die); also to grow old, stale or inveterate",
		"kjv" : "old (store), remain long, (make to) sleep"
	},
	"H3463" : {
		"lemma" : "יָשֵׁן",
		"xlit" : "yâshên",
		"pron" : "yaw-shane'",
		"derivation" : "from H3462 (יָשֵׁן)",
		"def" : "sleepy",
		"kjv" : "asleep, (one out of) sleep(-eth, -ing), slept"
	},
	"H3464" : {
		"lemma" : "יָשֵׁן",
		"xlit" : "Yâshên",
		"pron" : "yaw-shane'",
		"derivation" : "the same as H3463 (יָשֵׁן)",
		"def" : "Jashen, an Israelite",
		"kjv" : "Jashen"
	},
	"H3465" : {
		"lemma" : "יָשָׁן",
		"xlit" : "yâshân",
		"pron" : "yaw-shawn'",
		"derivation" : "from H3462 (יָשֵׁן)",
		"def" : "old",
		"kjv" : "old"
	},
	"H3466" : {
		"lemma" : "יְשָׁנָה",
		"xlit" : "Yᵉshânâh",
		"pron" : "yesh-aw-naw'",
		"derivation" : "feminine of H3465 (יָשָׁן)",
		"def" : "Jeshanah, a place in Palestine",
		"kjv" : "Jeshanah"
	},
	"H3467" : {
		"lemma" : "יָשַׁע",
		"xlit" : "yâshaʻ",
		"pron" : "yaw-shah'",
		"derivation" : "a primitive root",
		"def" : "properly, to be open, wide or free, i.e. (by implication) to be safe; causatively, to free or succor",
		"kjv" : "[idiom] at all, avenging, defend, deliver(-er), help, preserve, rescue, be safe, bring (having) salvation, save(-iour), get victory"
	},
	"H3468" : {
		"lemma" : "יֶשַׁע",
		"xlit" : "yeshaʻ",
		"pron" : "yeh'-shah",
		"derivation" : "or יֵשַׁע; from H3467 (יָשַׁע)",
		"def" : "liberty, deliverance, prosperity",
		"kjv" : "safety, salvation, saving"
	},
	"H3469" : {
		"lemma" : "יִשְׁעִי",
		"xlit" : "Yishʻîy",
		"pron" : "yish-ee'",
		"derivation" : "from H3467 (יָשַׁע); saving",
		"def" : "Jishi, the name of four Israelites",
		"kjv" : "Ishi"
	},
	"H3470" : {
		"lemma" : "יְשַׁעְיָה",
		"xlit" : "Yᵉshaʻyâh",
		"pron" : "yesh-ah-yaw'",
		"derivation" : "or יְשַׁעְיָהוּ; from H3467 (יָשַׁע) and H3050 (יָהּ); Jah has saved",
		"def" : "Jeshajah, the name of seven Israelites",
		"kjv" : "Isaiah, Jesaiah, Jeshaiah"
	},
	"H3471" : {
		"lemma" : "יָשְׁפֵה",
		"xlit" : "yâshᵉphêh",
		"pron" : "yaw-shef-ay'",
		"derivation" : "from an unused root meaning to polish",
		"def" : "a gem supposed to be jasper (from the resemblance in name)",
		"kjv" : "jasper"
	},
	"H3472" : {
		"lemma" : "יִשְׁפָּה",
		"xlit" : "Yishpâh",
		"pron" : "yish-paw'",
		"derivation" : "perhaps from H8192 (שָׁפָה); he will scratch",
		"def" : "Jishpah, an Israelite",
		"kjv" : "Ispah"
	},
	"H3473" : {
		"lemma" : "יִשְׁפָּן",
		"xlit" : "Yishpân",
		"pron" : "yish-pawn'",
		"derivation" : "probably from the same as H8227 (שָׁפָן); he will hide",
		"def" : "Jishpan, an Israelite",
		"kjv" : "Ishpan"
	},
	"H3474" : {
		"lemma" : "יָשַׁר",
		"xlit" : "yâshar",
		"pron" : "yaw-shar'",
		"derivation" : "a primitive root",
		"def" : "to be straight or even; figuratively, to be (causatively, to make) right, pleasant, prosperous",
		"kjv" : "direct, fit, seem good (meet), [phrase] please (will), be (esteem, go) right (on), bring (look, make, take the) straight (way), be upright(-ly)"
	},
	"H3475" : {
		"lemma" : "יֵשֶׁר",
		"xlit" : "Yêsher",
		"pron" : "yay'-sher",
		"derivation" : "from H3474 (יָשַׁר); the right",
		"def" : "Jesher, an Israelite",
		"kjv" : "Jesher"
	},
	"H3476" : {
		"lemma" : "יֹשֶׁר",
		"xlit" : "yôsher",
		"pron" : "yo'-sher",
		"derivation" : "from H3474 (יָשַׁר)",
		"def" : "the right",
		"kjv" : "equity, meet, right, upright(-ness)"
	},
	"H3477" : {
		"lemma" : "יָשָׁר",
		"xlit" : "yâshâr",
		"pron" : "yaw-shawr'",
		"derivation" : "from H3474 (יָשַׁר)",
		"def" : "straight (literally or figuratively)",
		"kjv" : "convenient, equity, Jasher, just, meet(-est), [phrase] pleased well right(-eous), straight, (most) upright(-ly, -ness)"
	},
	"H3478" : {
		"lemma" : "יִשְׂרָאֵל",
		"xlit" : "Yisrâʼêl",
		"pron" : "yis-raw-ale'",
		"derivation" : "from H8280 (שָׂרָה) and H410 (אֵל); he will rule as God",
		"def" : "Jisrael, a symbolical name of Jacob; also (typically) of his posterity",
		"kjv" : "Israel"
	},
	"H3479" : {
		"lemma" : "יִשְׂרָאֵל",
		"xlit" : "Yisrâʼêl",
		"pron" : "yis-raw-ale'",
		"derivation" : "(Aramaic) corresponding to H3478 (יִשְׂרָאֵל)",
		"def" : "{Jisrael, a symbolical name of Jacob; also (typically) of his posterity}",
		"kjv" : "Israel"
	},
	"H3480" : {
		"lemma" : "יְשַׂרְאֵלָה",
		"xlit" : "Yᵉsarʼêlâh",
		"pron" : "yes-ar-ale'-aw",
		"derivation" : "by variation from H3477 (יָשָׁר) and H410 (אֵל) with directive enclitic; right towards God",
		"def" : "Jesarelah, an Israelite",
		"kjv" : "Jesharelah"
	},
	"H3481" : {
		"lemma" : "יִשְׂרְאֵלִי",
		"xlit" : "Yisrᵉʼêlîy",
		"pron" : "yis-reh-ay-lee'",
		"derivation" : "patronymically from H3478 (יִשְׂרָאֵל)",
		"def" : "a Jisreelite or descendant of Jisrael",
		"kjv" : "of Israel, Israelite"
	},
	"H3482" : {
		"lemma" : "יִשְׂרְאֵלִית",
		"xlit" : "Yisrᵉʼêlîyth",
		"pron" : "yis-reh-ay-leeth'",
		"derivation" : "feminine of H3481 (יִשְׂרְאֵלִי)",
		"def" : "a Jisreelitess or female descendant of Jisrael",
		"kjv" : "Israelitish"
	},
	"H3483" : {
		"lemma" : "יִשְׁרָה",
		"xlit" : "yishrâh",
		"pron" : "yish-raw'",
		"derivation" : "feminine of H3477 (יָשָׁר)",
		"def" : "rectitude",
		"kjv" : "uprightness"
	},
	"H3484" : {
		"lemma" : "יְשֻׁרוּן",
		"xlit" : "Yᵉshurûwn",
		"pron" : "yesh-oo-roon'",
		"derivation" : "from H3474 (יָשַׁר); upright",
		"def" : "Jeshurun, a symbolic name for Israel",
		"kjv" : "Jeshurun"
	},
	"H3485" : {
		"lemma" : "יִשָּׂשכָר",
		"xlit" : "Yissâˢkâr",
		"pron" : "yis-saw-kawr'",
		"derivation" : "(strictly yis-saws-kawr'); from H5375 (נָשָׂא) and H7939 (שָׂכָר); he will bring a reward",
		"def" : "Jissaskar, a son of Jacob",
		"kjv" : "Issachar"
	},
	"H3486" : {
		"lemma" : "יָשֵׁשׁ",
		"xlit" : "yâshêsh",
		"pron" : "yaw-shaysh'",
		"derivation" : "from an unused root meaning to blanch",
		"def" : "gray-haired, i.e. an aged man",
		"kjv" : "stoop for age"
	},
	"H3487" : {
		"lemma" : "יַת",
		"xlit" : "yath",
		"pron" : "yath",
		"derivation" : "(Aramaic) corresponding to H853 (אֵת)",
		"def" : "a sign of the object of a verb",
		"kjv" : "[phrase] whom"
	},
	"H3488" : {
		"lemma" : "יְתִב",
		"xlit" : "yᵉthib",
		"pron" : "yeth-eeb'",
		"derivation" : "(Aramaic) corresponding to H3427 (יָשַׁב)",
		"def" : "to sit or dwell",
		"kjv" : "dwell, (be) set, sit"
	},
	"H3489" : {
		"lemma" : "יָתֵד",
		"xlit" : "yâthêd",
		"pron" : "yaw-thade'",
		"derivation" : "from an unused root meaning to pin through or fast",
		"def" : "a peg",
		"kjv" : "nail, paddle, pin, stake"
	},
	"H3490" : {
		"lemma" : "יָתוֹם",
		"xlit" : "yâthôwm",
		"pron" : "yaw-thome'",
		"derivation" : "from an unused root meaning to be lonely",
		"def" : "a bereaved person",
		"kjv" : "fatherless (child), orphan"
	},
	"H3491" : {
		"lemma" : "יָתוּר",
		"xlit" : "yâthûwr",
		"pron" : "yaw-thoor'",
		"derivation" : "passive participle of H3498 (יָתַר)",
		"def" : "properly, what is left, i.e. (by implication) a gleaning",
		"kjv" : "range"
	},
	"H3492" : {
		"lemma" : "יַתִּיר",
		"xlit" : "Yattîyr",
		"pron" : "yat-teer'",
		"derivation" : "from H3498 (יָתַר); redundant",
		"def" : "Jattir, a place in Palestine",
		"kjv" : "Jattir"
	},
	"H3493" : {
		"lemma" : "יַתִּיר",
		"xlit" : "yattîyr",
		"pron" : "yat-teer'",
		"derivation" : "(Aramaic) corresponding to H3492 (יַתִּיר)",
		"def" : "preeminent; as an adverb, very",
		"kjv" : "exceeding(-ly), excellent"
	},
	"H3494" : {
		"lemma" : "יִתְלָה",
		"xlit" : "Yithlâh",
		"pron" : "yith-law'",
		"derivation" : "probably from H8518 (תָּלָה); it will hang, i.e. be high",
		"def" : "Jithlah, a place in Palestine",
		"kjv" : "Jethlah"
	},
	"H3495" : {
		"lemma" : "יִתְמָה",
		"xlit" : "Yithmâh",
		"pron" : "yith-maw'",
		"derivation" : "from the same as H3490 (יָתוֹם); orphanage",
		"def" : "Jithmah, an Israelite",
		"kjv" : "Ithmah"
	},
	"H3496" : {
		"lemma" : "יַתְנִיאֵל",
		"xlit" : "Yathnîyʼêl",
		"pron" : "yath-nee-ale'",
		"derivation" : "from an unused root meaning to endure, and H410 (אֵל); continued of God",
		"def" : "Jathniel, an Israelite",
		"kjv" : "Jathniel"
	},
	"H3497" : {
		"lemma" : "יִתְנָן",
		"xlit" : "Yithnân",
		"pron" : "yith-nawn'",
		"derivation" : "from the same as H8577 (תַּנִּין); extensive",
		"def" : "Jithnan, a place in Palestine",
		"kjv" : "Ithnan"
	},
	"H3498" : {
		"lemma" : "יָתַר",
		"xlit" : "yâthar",
		"pron" : "yaw-thar'",
		"derivation" : "a primitive root",
		"def" : "to jut over or exceed; by implication, to excel; (intransitively) to remain or be left; causatively, to leave, cause to abound, preserve",
		"kjv" : "excel, leave (a remnant), left behind, too much, make plenteous, preserve, (be, let) remain(-der, -ing, -nant), reserve, residue, rest"
	},
	"H3499" : {
		"lemma" : "יֶתֶר",
		"xlit" : "yether",
		"pron" : "yeh'-ther",
		"derivation" : "from H3498 (יָתַר)",
		"def" : "properly, an overhanging, i.e. (by implication) an excess, superiority, remainder; also a small rope (as hanging free)",
		"kjv" : "[phrase] abundant, cord, exceeding, excellancy(-ent), what they leave, that hath left, plentifully, remnant, residue, rest, string, with"
	},
	"H3500" : {
		"lemma" : "יֶתֶר",
		"xlit" : "Yether",
		"pron" : "yeh'-ther",
		"derivation" : "the same as H3499 (יֶתֶר)",
		"def" : "Jether, the name of five or six Israelites and of one Midianite",
		"kjv" : "Jether, Jethro. Compare H3503 (יִתְרוֹ)"
	},
	"H3501" : {
		"lemma" : "יִתְרָא",
		"xlit" : "Yithrâʼ",
		"pron" : "yith-raw'",
		"derivation" : "by variation for H3502 (יִתְרָה)",
		"def" : "Jithra, an Israelite (or Ishmaelite)",
		"kjv" : "Ithra"
	},
	"H3502" : {
		"lemma" : "יִתְרָה",
		"xlit" : "yithrâh",
		"pron" : "yith-raw'",
		"derivation" : "feminine of H3499 (יֶתֶר)",
		"def" : "properly, excellence, i.e. (by implication) wealth",
		"kjv" : "abundance, riches"
	},
	"H3503" : {
		"lemma" : "יִתְרוֹ",
		"xlit" : "Yithrôw",
		"pron" : "yith-ro'",
		"derivation" : "from H3499 (יֶתֶר) with pron. suffix; his excellence",
		"def" : "Jethro, Moses' father-in-law",
		"kjv" : "Jethro. Compare H3500 (יֶתֶר)"
	},
	"H3504" : {
		"lemma" : "יִתְרוֹן",
		"xlit" : "yithrôwn",
		"pron" : "yith-rone'",
		"derivation" : "from H3498 (יָתַר)",
		"def" : "preeminence, gain",
		"kjv" : "better, excellency(-leth), profit(-able)"
	},
	"H3505" : {
		"lemma" : "יִתְרִי",
		"xlit" : "Yithrîy",
		"pron" : "yith-ree'",
		"derivation" : "patronymically from H3500 (יֶתֶר)",
		"def" : "a Jithrite or descendant of Jether",
		"kjv" : "Ithrite"
	},
	"H3506" : {
		"lemma" : "יִתְרָן",
		"xlit" : "Yithrân",
		"pron" : "yith-rawn'",
		"derivation" : "from H3498 (יָתַר); excellent",
		"def" : "Jithran, the name of an Edomite and of an Israelite",
		"kjv" : "Ithran"
	},
	"H3507" : {
		"lemma" : "יִתְרְעָם",
		"xlit" : "Yithrᵉʻâm",
		"pron" : "yith-reh-awm'",
		"derivation" : "from H3499 (יֶתֶר) and H5971 (עַם); excellence of people",
		"def" : "Jithream, a son of David",
		"kjv" : "Ithream"
	},
	"H3508" : {
		"lemma" : "יֹתֶרֶת",
		"xlit" : "yôthereth",
		"pron" : "yo-theh'-reth",
		"derivation" : "feminine active participle of H3498 (יָתַר)",
		"def" : "the lobe or flap of the liver (as if redundant or outhanging)",
		"kjv" : "caul"
	},
	"H3509" : {
		"lemma" : "יְתֵת",
		"xlit" : "Yᵉthêth",
		"pron" : "yeh-thayth'",
		"derivation" : "of uncertain derivation",
		"def" : "Jetheth, an Edomite",
		"kjv" : "Jetheth. k"
	},
	"H3510" : {
		"lemma" : "כָּאַב",
		"xlit" : "kâʼab",
		"pron" : "kaw-ab'",
		"derivation" : "a primitive root",
		"def" : "properly, to feel pain; by implication, to grieve; figuratively, to spoil",
		"kjv" : "grieving, mar, have pain, make sad (sore), (be) sorrowful"
	},
	"H3511" : {
		"lemma" : "כְּאֵב",
		"xlit" : "kᵉʼêb",
		"pron" : "keh-abe'",
		"derivation" : "from H3510 (כָּאַב)",
		"def" : "suffering (physical or mental), adversity",
		"kjv" : "grief, pain, sorrow"
	},
	"H3512" : {
		"lemma" : "כָּאָה",
		"xlit" : "kâʼâh",
		"pron" : "kaw-aw'",
		"derivation" : "a primitive root",
		"def" : "to despond; causatively, to deject",
		"kjv" : "broken, be grieved, make sad"
	},
	"H3513" : {
		"lemma" : "כָּבַד",
		"xlit" : "kâbad",
		"pron" : "kaw-bad'",
		"derivation" : "or כָּבֵד; a primitive root",
		"def" : "to be heavy, i.e. in a bad sense (burdensome, severe, dull) or in a good sense (numerous, rich, honorable; causatively, to make weighty (in the same two senses)",
		"kjv" : "abounding with, more grievously afflict, boast, be chargeable, [idiom] be dim, glorify, be (make) glorious (things), glory, (very) great, be grievous, harden, be (make) heavy, be heavier, lay heavily, (bring to, come to, do, get, be had in) honour (self), (be) honourable (man), lade, [idiom] more be laid, make self many, nobles, prevail, promote (to honour), be rich, be (go) sore, stop"
	},
	"H3514" : {
		"lemma" : "כֹּבֶד",
		"xlit" : "kôbed",
		"pron" : "ko'-bed",
		"derivation" : "from H3513 (כָּבַד)",
		"def" : "weight, multitude, vehemence",
		"kjv" : "grievousness, heavy, great number"
	},
	"H3515" : {
		"lemma" : "כָּבֵד",
		"xlit" : "kâbêd",
		"pron" : "kaw-bade'",
		"derivation" : "from H3513 (כָּבַד)",
		"def" : "heavy; figuratively in a good sense (numerous) or in a bad sense (severe, difficult, stupid)",
		"kjv" : "(so) great, grievous, hard(-ened), (too) heavy(-ier), laden, much, slow, sore, thick"
	},
	"H3516" : {
		"lemma" : "כָּבֵד",
		"xlit" : "kâbêd",
		"pron" : "kaw-bade'",
		"derivation" : "the same as H3515 (כָּבֵד)",
		"def" : "the liver (as the heaviest of the viscera)",
		"kjv" : "liver"
	},
	"H3517" : {
		"lemma" : "כְּבֵדֻת",
		"xlit" : "kᵉbêduth",
		"pron" : "keb-ay-dooth'",
		"derivation" : "feminine of H3515 (כָּבֵד)",
		"def" : "difficulty",
		"kjv" : "[idiom] heavily"
	},
	"H3518" : {
		"lemma" : "כָּבָה",
		"xlit" : "kâbâh",
		"pron" : "kaw-baw'",
		"derivation" : "a primitive root",
		"def" : "to expire or (causatively) to extinguish (fire, light, anger)",
		"kjv" : "go (put) out, quench"
	},
	"H3519" : {
		"lemma" : "כָּבוֹד",
		"xlit" : "kâbôwd",
		"pron" : "kaw-bode'",
		"derivation" : "rarely כָּבֹד; from H3513 (כָּבַד)",
		"def" : "properly, weight, but only figuratively in a good sense, splendor or copiousness",
		"kjv" : "glorious(-ly), glory, honour(-able)"
	},
	"H3520" : {
		"lemma" : "כְּבוּדָּה",
		"xlit" : "kᵉbûwddâh",
		"pron" : "keb-ood-daw'",
		"derivation" : "irregular feminine passive participle of H3513 (כָּבַד)",
		"def" : "weightiness, i.e. magnificence, wealth",
		"kjv" : "carriage, all glorious, stately"
	},
	"H3521" : {
		"lemma" : "כָּבוּל",
		"xlit" : "Kâbûwl",
		"pron" : "kaw-bool'",
		"derivation" : "from the same as H3525 (כֶּבֶל) in the sense of limitation; sterile",
		"def" : "Cabul, the name of two places in Palestine",
		"kjv" : "Cabul"
	},
	"H3522" : {
		"lemma" : "כַּבּוֹן",
		"xlit" : "Kabbôwn",
		"pron" : "kab-bone'",
		"derivation" : "from an unused root meaning to heap up; hilly",
		"def" : "Cabon, a place in Palestine",
		"kjv" : "Cabbon"
	},
	"H3523" : {
		"lemma" : "כְּבִיר",
		"xlit" : "kᵉbîyr",
		"pron" : "keb-eer",
		"derivation" : "from H3527 (כָּבַר) in the original sense of plaiting",
		"def" : "a matrass (of intertwined materials)",
		"kjv" : "pillow"
	},
	"H3524" : {
		"lemma" : "כַּבִּיר",
		"xlit" : "kabbîyr",
		"pron" : "kab-beer'",
		"derivation" : "from H3527 (כָּבַר)",
		"def" : "vast, whether in extent (figuratively, of power, mighty; of time, aged), or in number, many",
		"kjv" : "[phrase] feeble, mighty, most, much, strong, valiant"
	},
	"H3525" : {
		"lemma" : "כֶּבֶל",
		"xlit" : "kebel",
		"pron" : "keh'-bel",
		"derivation" : "from an unused root meaning to twine or braid together",
		"def" : "a fetter",
		"kjv" : "fetter"
	},
	"H3526" : {
		"lemma" : "כָּבַס",
		"xlit" : "kâbaç",
		"pron" : "kaw-bas'",
		"derivation" : "a primitive root",
		"def" : "to trample; hence, to wash (properly, by stamping with the feet), whether literal (including the fulling process) or figurative",
		"kjv" : "fuller, wash(-ing)"
	},
	"H3527" : {
		"lemma" : "כָּבַר",
		"xlit" : "kâbar",
		"pron" : "kaw-bar'",
		"derivation" : "a primitive root",
		"def" : "properly, to plait together, i.e. (figuratively) to augment (especially in number or quantity, to accumulate)",
		"kjv" : "in abundance, multiply"
	},
	"H3528" : {
		"lemma" : "כְּבָר",
		"xlit" : "kᵉbâr",
		"pron" : "keb-awr'",
		"derivation" : "from H3527 (כָּבַר)",
		"def" : "properly, extent of time, i.e. a great while; hence, long ago, formerly, hitherto",
		"kjv" : "already, (seeing that which), now"
	},
	"H3529" : {
		"lemma" : "כְּבָר",
		"xlit" : "Kᵉbâr",
		"pron" : "keb-awr'",
		"derivation" : "the same as H3528 (כְּבָר); length",
		"def" : "Kebar, a river of Mesopotamia",
		"kjv" : "Chebar. Compare H2249 (חָבוֹר)"
	},
	"H3530" : {
		"lemma" : "כִּבְרָה",
		"xlit" : "kibrâh",
		"pron" : "kib-raw'",
		"derivation" : "feminine of H3528 (כְּבָר)",
		"def" : "properly, length, i.e. a measure (of uncertain dimension)",
		"kjv" : "[idiom] little"
	},
	"H3531" : {
		"lemma" : "כְּבָרָה",
		"xlit" : "kᵉbârâh",
		"pron" : "keb-aw-raw'",
		"derivation" : "from H3527 (כָּבַר) in its original sense",
		"def" : "a sieve (as netted)",
		"kjv" : "sieve"
	},
	"H3532" : {
		"lemma" : "כֶּבֶשׂ",
		"xlit" : "kebes",
		"pron" : "keh-bes'",
		"derivation" : "from an unused root meaning to dominate",
		"def" : "a ram (just old enough to butt)",
		"kjv" : "lamb, sheep"
	},
	"H3533" : {
		"lemma" : "כָּבַשׁ",
		"xlit" : "kâbash",
		"pron" : "kaw-bash'",
		"derivation" : "a primitive root",
		"def" : "to tread down; hence, negatively, to disregard; positively, to conquer, subjugate, violate",
		"kjv" : "bring into bondage, force, keep under, subdue, bring into subjection"
	},
	"H3534" : {
		"lemma" : "כֶּבֶשׁ",
		"xlit" : "kebesh",
		"pron" : "keh'-besh",
		"derivation" : "from H3533 (כָּבַשׁ)",
		"def" : "a footstool (as trodden upon)",
		"kjv" : "footstool"
	},
	"H3535" : {
		"lemma" : "כִּבְשָׂה",
		"xlit" : "kibsâh",
		"pron" : "kib-saw'",
		"derivation" : "or כַּבְשָׂה; feminine of H3532 (כֶּבֶשׂ)",
		"def" : "a ewe",
		"kjv" : "(ewe) lamb"
	},
	"H3536" : {
		"lemma" : "כִּבְשָׁן",
		"xlit" : "kibshân",
		"pron" : "kib-shawn'",
		"derivation" : "from H3533 (כָּבַשׁ)",
		"def" : "a smelting furnace (as reducing metals)",
		"kjv" : "furnace"
	},
	"H3537" : {
		"lemma" : "כַּד",
		"xlit" : "kad",
		"pron" : "kad",
		"derivation" : "from an unused root meaning to deepen",
		"def" : "properly, a pail; but generally of earthenware; a jar  for domestic purposes",
		"kjv" : "barrel, pitcher"
	},
	"H3538" : {
		"lemma" : "כְּדַב",
		"xlit" : "kᵉdab",
		"pron" : "ked-ab'",
		"derivation" : "(Aramaic) from a root corresponding to H3576 (כָּזַב)",
		"def" : "false",
		"kjv" : "lying"
	},
	"H3539" : {
		"lemma" : "כַּדְכֹּד",
		"xlit" : "kadkôd",
		"pron" : "kad-kode'",
		"derivation" : "from the same as H3537 (כַּד) in the sense of striking fire from a metal forged",
		"def" : "a sparkling gem, probably the ruby",
		"kjv" : "agate"
	},
	"H3540" : {
		"lemma" : "כְּדׇרְלָעֹמֶר",
		"xlit" : "Kᵉdorlâʻômer",
		"pron" : "ked-or-law-o'-mer",
		"derivation" : "of foreign origin",
		"def" : "Kedorlaomer, an early Persian king",
		"kjv" : "Chedorlaomer"
	},
	"H3541" : {
		"lemma" : "כֹּה",
		"xlit" : "kôh",
		"pron" : "ko",
		"derivation" : "from the prefix k and H1931 (הוּא)",
		"def" : "properly, like this, i.e. by implication, (of manner) thus (or so); also (of place) here (or hither); or (of time) now",
		"kjv" : "also, here, + hitherto, like, on the other side, so (and much), such, on that manner, (on) this (manner, side, way, way and that way), + mean while, yonder"
	},
	"H3542" : {
		"lemma" : "כָּה",
		"xlit" : "kâh",
		"pron" : "kaw",
		"derivation" : "(Aramaic) corresponding to H3541 (כֹּה)",
		"def" : "{properly, like this, i.e. by implication, (of manner) thus (or so); also (of place) here (or hither); or (of time) now}",
		"kjv" : "hitherto"
	},
	"H3543" : {
		"lemma" : "כָּהָה",
		"xlit" : "kâhâh",
		"pron" : "kaw-haw'",
		"derivation" : "a primitive root",
		"def" : "to be weak, i.e. (figuratively) to despond (causatively, rebuke), or (of light, the eye) to grow dull",
		"kjv" : "darken, be dim, fail, faint, restrain, [idiom] utterly"
	},
	"H3544" : {
		"lemma" : "כֵּהֶה",
		"xlit" : "kêheh",
		"pron" : "kay-heh'",
		"derivation" : "from H3543 (כָּהָה)",
		"def" : "feeble, obscure",
		"kjv" : "somewhat dark, darkish, wax dim, heaviness, smoking"
	},
	"H3545" : {
		"lemma" : "כֵּהָה",
		"xlit" : "kêhâh",
		"pron" : "kay-haw'",
		"derivation" : "feminine of H3544 (כֵּהֶה)",
		"def" : "properly, a weakening; figuratively, alleviation, i.e. cure",
		"kjv" : "healing"
	},
	"H3546" : {
		"lemma" : "כְּהַל",
		"xlit" : "kᵉhal",
		"pron" : "keh-hal'",
		"derivation" : "(Aramaic) a root corresponding to and H3557 (כּוּל)",
		"def" : "to be able",
		"kjv" : "be able, could"
	},
	"H3547" : {
		"lemma" : "כָּהַן",
		"xlit" : "kâhan",
		"pron" : "kaw-han'",
		"derivation" : "a primitive root, apparently meaning to mediate in religious services; but used only as denominative from H3548 (כֹּהֵן)",
		"def" : "to officiate as a priest; figuratively, to put on regalia",
		"kjv" : "deck, be (do the office of a, execute the, minister in the) priest('s office)"
	},
	"H3548" : {
		"lemma" : "כֹּהֵן",
		"xlit" : "kôhên",
		"pron" : "ko-hane'",
		"derivation" : "active participle of H3547 (כָּהַן)",
		"def" : "literally one officiating, a priest; also (by courtesy) an acting priest (although a layman)",
		"kjv" : "chief ruler, [idiom] own, priest, prince, principal officer"
	},
	"H3549" : {
		"lemma" : "כָּהֵן",
		"xlit" : "kâhên",
		"pron" : "kaw-hane'",
		"derivation" : "(Aramaic) corresponding to H3548 (כֹּהֵן)",
		"def" : "{one officiating, a priest; also (by courtesy) an acting priest (although a layman)}",
		"kjv" : "priest"
	},
	"H3550" : {
		"lemma" : "כְּהֻנָּה",
		"xlit" : "kᵉhunnâh",
		"pron" : "keh-hoon-naw'",
		"derivation" : "from H3547 (כָּהַן)",
		"def" : "priesthood",
		"kjv" : "priesthood, priest's office"
	},
	"H3551" : {
		"lemma" : "כַּו",
		"xlit" : "kav",
		"pron" : "kav",
		"derivation" : "(Aramaic) from a root corresponding to H3854 (לַהַג) in the sense of piercing",
		"def" : "a window (as a perforation)",
		"kjv" : "window"
	},
	"H3552" : {
		"lemma" : "כּוּב",
		"xlit" : "Kûwb",
		"pron" : "koob",
		"derivation" : "of foreign derivation",
		"def" : "Kub, a country near Egypt",
		"kjv" : "Chub"
	},
	"H3553" : {
		"lemma" : "כּוֹבַע",
		"xlit" : "kôwbaʻ",
		"pron" : "ko'-bah",
		"derivation" : "from an unused root meaning to be high or rounded",
		"def" : "a helmet (as arched)",
		"kjv" : "helmet. Compare H6959 (קוֹבַע)"
	},
	"H3554" : {
		"lemma" : "כָּוָה",
		"xlit" : "kâvâh",
		"pron" : "kaw-vaw'",
		"derivation" : "a primitive root",
		"def" : "properly, to prick or penetrate; hence, to blister (as smarting or eating into)",
		"kjv" : "burn"
	},
	"H3555" : {
		"lemma" : "כְּוִיָּה",
		"xlit" : "kᵉvîyâh",
		"pron" : "kev-ee-yaw'",
		"derivation" : "from H3554 (כָּוָה)",
		"def" : "a branding",
		"kjv" : "burning"
	},
	"H3556" : {
		"lemma" : "כּוֹכָב",
		"xlit" : "kôwkâb",
		"pron" : "ko-kawb'",
		"derivation" : "probably from the same as H3522 (כַּבּוֹן) (in the sense of rolling) or H3554 (כָּוָה) (in the sense of blazing)",
		"def" : "a star (as round or as shining); figuratively, a prince",
		"kjv" : "star(-gazer)"
	},
	"H3557" : {
		"lemma" : "כּוּל",
		"xlit" : "kûwl",
		"pron" : "kool",
		"derivation" : "a primitive root",
		"def" : "properly, to keep in; hence, to measure; figuratively, to maintain (in various senses)",
		"kjv" : "(be able to, can) abide, bear, comprehend, contain, feed, forbearing, guide, hold(-ing in), nourish(-er), be present, make provision, receive, sustain, provide sustenance (victuals)"
	},
	"H3558" : {
		"lemma" : "כּוּמָז",
		"xlit" : "kûwmâz",
		"pron" : "koo-mawz'",
		"derivation" : "from an unused root meaning to store away",
		"def" : "a jewel (probably gold beads)",
		"kjv" : "tablet"
	},
	"H3559" : {
		"lemma" : "כּוּן",
		"xlit" : "kûwn",
		"pron" : "koon",
		"derivation" : "a primitive root",
		"def" : "properly, to be erect (i.e. stand perpendicular); hence (causatively) to set up, in a great variety of applications, whether literal (establish, fix, prepare, apply), or figurative (appoint, render sure, proper or prosperous)",
		"kjv" : "certain(-ty), confirm, direct, faithfulness, fashion, fasten, firm, be fitted, be fixed, frame, be meet, ordain, order, perfect, (make) preparation, prepare (self), provide, make provision, (be, make) ready, right, set (aright, fast, forth), be stable, (e-) stablish, stand, tarry, [idiom] very deed"
	},
	"H3560" : {
		"lemma" : "כּוּן",
		"xlit" : "Kûwn",
		"pron" : "koon",
		"derivation" : "probably from H3559 (כּוּן); established",
		"def" : "Kun, a place in Syria",
		"kjv" : "Chun"
	},
	"H3561" : {
		"lemma" : "כַּוָּן",
		"xlit" : "kavvân",
		"pron" : "kav-vawn'",
		"derivation" : "from H3559 (כּוּן)",
		"def" : "something prepared, i.e. a sacrificial wafer",
		"kjv" : "cake"
	},
	"H3562" : {
		"lemma" : "כּוֹנַנְיָהוּ",
		"xlit" : "Kôwnanyâhûw",
		"pron" : "ko-nan-yaw'-hoo",
		"derivation" : "from H3559 (כּוּן) and H3050 (יָהּ); Jah has sustained",
		"def" : "Conanjah, the name of two Israelites",
		"kjv" : "Conaniah, Cononiah. Compare H3663 (כְּנַנְיָה)"
	},
	"H3563" : {
		"lemma" : "כּוֹס",
		"xlit" : "kôwç",
		"pron" : "koce",
		"derivation" : "from an unused root meaning to hold together",
		"def" : "a cup (as a container), often figuratively, a lot (as if a potion); also some unclean bird, probably an owl (perhaps from the cup-like cavity of its eye)",
		"kjv" : "cup, (small) owl. Compare H3599 (כִּיס)"
	},
	"H3564" : {
		"lemma" : "כּוּר",
		"xlit" : "kûwr",
		"pron" : "koor",
		"derivation" : "from an unused root meaning properly, to dig through",
		"def" : "a pot or furnace (as if excavated)",
		"kjv" : "furnace. Compare H3600 (כִּיר)"
	},
	"H3565" : {
		"lemma" : "כּוֹר עָשָׁן",
		"xlit" : "Kôwr ʻÂshân",
		"pron" : "kore aw-shawn'",
		"derivation" : "from H3564 (כּוּר) and H6227 (עָשָׁן); furnace of smoke",
		"def" : "Cor-Ashan, a place in Palestine",
		"kjv" : "Chorashan"
	},
	"H3566" : {
		"lemma" : "כּוֹרֶשׁ",
		"xlit" : "Kôwresh",
		"pron" : "ko'-resh",
		"derivation" : "or (Ezra 1:1 (last time),2) כֹּרֶשׁ; from the Persians",
		"def" : "Koresh (or Cyrus), the Persian king",
		"kjv" : "Cyrus"
	},
	"H3567" : {
		"lemma" : "כּוֹרֶשׁ",
		"xlit" : "Kôwresh",
		"pron" : "ko'-resh",
		"derivation" : "(Aramaic) corresponding to H3566 (כּוֹרֶשׁ)",
		"def" : "{Koresh (or Cyrus), the Persian king}",
		"kjv" : "Cyrus"
	},
	"H3568" : {
		"lemma" : "כּוּשׁ",
		"xlit" : "Kûwsh",
		"pron" : "koosh",
		"derivation" : "probably of foreign origin",
		"def" : "Cush (or Ethiopia), the name of a son of Ham, and of his territory; also of an Israelite",
		"kjv" : "Chush, Cush, Ethiopia"
	},
	"H3569" : {
		"lemma" : "כּוּשִׁי",
		"xlit" : "Kûwshîy",
		"pron" : "koo-shee'",
		"derivation" : "patronymically from H3568 (כּוּשׁ)",
		"def" : "a Cushite, or descendant of Cush",
		"kjv" : "Cushi, Cushite, Ethiopian(-s)"
	},
	"H3570" : {
		"lemma" : "כּוּשִׁי",
		"xlit" : "Kûwshîy",
		"pron" : "koo-shee'",
		"derivation" : "the same as H3569 (כּוּשִׁי)",
		"def" : "Cushi, the name of two Israelites",
		"kjv" : "Cushi"
	},
	"H3571" : {
		"lemma" : "כּוּשִׁית",
		"xlit" : "Kûwshîyth",
		"pron" : "koo-sheeth'",
		"derivation" : "feminine of H3569 (כּוּשִׁי)",
		"def" : "a Cushite woman",
		"kjv" : "Ethiopian"
	},
	"H3572" : {
		"lemma" : "כּוּשָׁן",
		"xlit" : "Kûwshân",
		"pron" : "koo-shawn'",
		"derivation" : "perhaps from H3568 (כּוּשׁ)",
		"def" : "Cushan, a region of Arabia",
		"kjv" : "Cushan"
	},
	"H3573" : {
		"lemma" : "כּוּשַׁן רִשְׁעָתַיִם",
		"xlit" : "Kûwshan Rishʻâthayim",
		"pron" : "koo-shan' rish-awthah'-yim",
		"derivation" : "apparently from H3572 (כּוּשָׁן) and the dual of H7564 (רִשְׁעָה); Cushan of double wickedness",
		"def" : "Cushan-Rishathajim, a Mesopotamian king",
		"kjv" : "Chushan-rishathayim"
	},
	"H3574" : {
		"lemma" : "כּוֹשָׁרָה",
		"xlit" : "kôwshârâh",
		"pron" : "ko-shaw-raw'",
		"derivation" : "from H3787 (כָּשֵׁר)",
		"def" : "prosperity; in plural freedom",
		"kjv" : "[idiom] chain"
	},
	"H3575" : {
		"lemma" : "כּוּת",
		"xlit" : "Kûwth",
		"pron" : "kooth",
		"derivation" : "or (feminine) כּוּתָה; of foreign origin",
		"def" : "Cuth or Cuthah, a province of Assyria",
		"kjv" : "Cuth"
	},
	"H3576" : {
		"lemma" : "כָּזַב",
		"xlit" : "kâzab",
		"pron" : "kaw-zab'",
		"derivation" : "a primitive root",
		"def" : "to lie (i.e. deceive), literally or figuratively",
		"kjv" : "fail, (be found a, make a) liar, lie, lying, be in vain"
	},
	"H3577" : {
		"lemma" : "כָּזָב",
		"xlit" : "kâzâb",
		"pron" : "kaw-zawb'",
		"derivation" : "from H3576 (כָּזַב)",
		"def" : "falsehood; literally (untruth) or figuratively (idol)",
		"kjv" : "deceitful, false, leasing, + liar, lie, lying"
	},
	"H3578" : {
		"lemma" : "כֹּזְבָא",
		"xlit" : "Kôzᵉbâʼ",
		"pron" : "ko-zeb-aw'",
		"derivation" : "from H3576 (כָּזַב); fallacious",
		"def" : "Cozeba, a place in Palestine",
		"kjv" : "Choseba"
	},
	"H3579" : {
		"lemma" : "כֹּזְבִי",
		"xlit" : "Kôzᵉbîy",
		"pron" : "koz-bee'",
		"derivation" : "from H3576 (כָּזַב); false",
		"def" : "Cozbi, a Midianitess",
		"kjv" : "Cozbi"
	},
	"H3580" : {
		"lemma" : "כְּזִיב",
		"xlit" : "Kᵉzîyb",
		"pron" : "kez-eeb'",
		"derivation" : "from H3576 (כָּזַב); falsified",
		"def" : "Kezib, a place in Palestine",
		"kjv" : "Chezib"
	},
	"H3581" : {
		"lemma" : "כֹּחַ",
		"xlit" : "kôach",
		"pron" : "ko'-akh",
		"derivation" : "or (Daniel 11:6) כּוֹחַ; from an unused root meaning to be firm",
		"def" : "vigor, literally (force, in a good or a bad sense) or figuratively (capacity, means, produce); also (from its hardiness) a large lizard",
		"kjv" : "ability, able, chameleon, force, fruits, might, power(-ful), strength, substance, wealth"
	},
	"H3582" : {
		"lemma" : "כָּחַד",
		"xlit" : "kâchad",
		"pron" : "kaw-khad'",
		"derivation" : "a primitive root",
		"def" : "to secrete, by act or word; hence (intensively) to destroy",
		"kjv" : "conceal, cut down (off), desolate, hide"
	},
	"H3583" : {
		"lemma" : "כָּחַל",
		"xlit" : "kâchal",
		"pron" : "kaw-khal'",
		"derivation" : "a primitive root",
		"def" : "to paint (with stibium)",
		"kjv" : "paint"
	},
	"H3584" : {
		"lemma" : "כָּחַשׁ",
		"xlit" : "kâchash",
		"pron" : "kaw-khash'",
		"derivation" : "a primitive root",
		"def" : "to be untrue, in word (to lie, feign, disown) or deed (to disappoint, fail, cringe)",
		"kjv" : "deceive, deny, dissemble, fail, deal falsely, be found liars, (be-) lie, lying, submit selves"
	},
	"H3585" : {
		"lemma" : "כַּחַשׁ",
		"xlit" : "kachash",
		"pron" : "kakh'-ash",
		"derivation" : "from H3584 (כָּחַשׁ)",
		"def" : "literally a failure of flesh, i.e. emaciation; figuratively, hypocrisy",
		"kjv" : "leanness, lies, lying"
	},
	"H3586" : {
		"lemma" : "כֶּחָשׁ",
		"xlit" : "kechâsh",
		"pron" : "kekh-awsh'",
		"derivation" : "from H3584 (כָּחַשׁ)",
		"def" : "faithless",
		"kjv" : "lying"
	},
	"H3587" : {
		"lemma" : "כִּי",
		"xlit" : "kîy",
		"pron" : "kee",
		"derivation" : "from H3554 (כָּוָה)",
		"def" : "a brand or scar",
		"kjv" : "burning"
	},
	"H3588" : {
		"lemma" : "כִּי",
		"xlit" : "kîy",
		"pron" : "kee",
		"derivation" : "a primitive particle (the full form of the prepositional prefix) indicating causal relations of all kinds, antecedent or consequent",
		"def" : "(by implication) very widely used as a relative conjunction or adverb (as below); often largely modified by other particles annexed",
		"kjv" : "and, + (forasmuch, inasmuch, where-) as, assured(-ly), + but, certainly, doubtless, + else, even, + except, for, how, (because, in, so, than) that, + nevertheless, now, rightly, seeing, since, surely, then, therefore, + (al-) though, + till, truly, + until, when, whether, while, whom, yea, yet"
	},
	"H3589" : {
		"lemma" : "כִּיד",
		"xlit" : "kîyd",
		"pron" : "keed",
		"derivation" : "from a primitive root meaning to strike",
		"def" : "a crushing; figuratively, calamity",
		"kjv" : "destruction"
	},
	"H3590" : {
		"lemma" : "כִידוֹד",
		"xlit" : "kîydôwd",
		"pron" : "kee-dode'",
		"derivation" : "from the same as H3589 (כִּיד) (compare H3539 (כַּדְכֹּד))",
		"def" : "properly, something struck off, i.e. a spark (as struck)",
		"kjv" : "spark"
	},
	"H3591" : {
		"lemma" : "כִּידוֹן",
		"xlit" : "kîydôwn",
		"pron" : "kee-dohn'",
		"derivation" : "from the same as H3589 (כִּיד); (perhaps smaller than H2595 (חֲנִית)lemma חֲניִת second vowel, corrected to חֲנִית)",
		"def" : "properly, something to strike with, i.e. a dart",
		"kjv" : "lance, shield, spear, target"
	},
	"H3592" : {
		"lemma" : "כִּידוֹן",
		"xlit" : "Kîydôwn",
		"pron" : "kee-dohn'",
		"derivation" : "the same as H3591 (כִּידוֹן)",
		"def" : "Kidon, a place in Palestine",
		"kjv" : "Chidon"
	},
	"H3593" : {
		"lemma" : "כִּידוֹר",
		"xlit" : "kîydôwr",
		"pron" : "kee-dore'",
		"derivation" : "of uncertain derivation",
		"def" : "perhaps tumult",
		"kjv" : "battle"
	},
	"H3594" : {
		"lemma" : "כִּיּוּן",
		"xlit" : "Kîyûwn",
		"pron" : "kee-yoon'",
		"derivation" : "from H3559 (כּוּן)",
		"def" : "properly, a statue, i.e. idol; but used (by euphemism) forsome heathen deity (perhaps corresponding to Priapus or Baal-peor)",
		"kjv" : "Chiun"
	},
	"H3595" : {
		"lemma" : "כִּיּוֹר",
		"xlit" : "kîyôwr",
		"pron" : "kee-yore'",
		"derivation" : "or כִּיֹּר; from the same as H3564 (כּוּר)",
		"def" : "properly, something round (as excavated or bored), i.e. a chafing-dish forcoals or a caldron forcooking; hence (from similarity of form) a washbowl; also (for the same reason) a pulpit or platform",
		"kjv" : "hearth, laver, pan, scaffold"
	},
	"H3596" : {
		"lemma" : "כִּילַי",
		"xlit" : "kîylay",
		"pron" : "kee-lah'-ee",
		"derivation" : "or כֵּלַי; from H3557 (כּוּל) in the sense of withholding",
		"def" : "niggardly",
		"kjv" : "churl"
	},
	"H3597" : {
		"lemma" : "כֵּילַף",
		"xlit" : "kêylaph",
		"pron" : "kay-laf'",
		"derivation" : "from an unused root meaning to clap or strike with noise",
		"def" : "a club or sledge-hammer",
		"kjv" : "hammer"
	},
	"H3598" : {
		"lemma" : "כִּימָה",
		"xlit" : "Kîymâh",
		"pron" : "kee-maw'",
		"derivation" : "from the same as H3558 (כּוּמָז)",
		"def" : "a cluster of stars, i.e. the Pleiades",
		"kjv" : "Pleiades, seven stars"
	},
	"H3599" : {
		"lemma" : "כִּיס",
		"xlit" : "kîyç",
		"pron" : "keece",
		"derivation" : "a form for H3563 (כּוֹס)",
		"def" : "a cup; also a bag formoney or weights",
		"kjv" : "bag, cup, purse"
	},
	"H3600" : {
		"lemma" : "כִּיר",
		"xlit" : "kîyr",
		"pron" : "keer",
		"derivation" : "a form for H3564 (כּוּר) (only in the dual)",
		"def" : "a cooking range (consisting of two parallel stones, across which the boiler is set)",
		"kjv" : "ranges for pots"
	},
	"H3601" : {
		"lemma" : "כִּישׁוֹר",
		"xlit" : "kîyshôwr",
		"pron" : "kee-shore'",
		"derivation" : "from H3787 (כָּשֵׁר); compare H6418 (פֶּלֶךְ)",
		"def" : "literally a director, i.e. the spindle or shank of adistaff, by which it is twirled",
		"kjv" : "spindle"
	},
	"H3602" : {
		"lemma" : "כָּכָה",
		"xlit" : "kâkâh",
		"pron" : "kaw'-kaw",
		"derivation" : "from H3541 (כֹּה)",
		"def" : "just so, referring to the previous or following context",
		"kjv" : "after that (this) manner, this matter, (even) so, in such a case, thus"
	},
	"H3603" : {
		"lemma" : "כִּכָּר",
		"xlit" : "kikkâr",
		"pron" : "kik-kawr'",
		"derivation" : "from H3769 (כָּרַר)",
		"def" : "a circle, i.e. (by implication) a circumjacent tract or region, especially the Ghor or valley of the Jordan; also a (round) loaf; also a talent (or large [round] coin)",
		"kjv" : "loaf, morsel, piece, plain, talent"
	},
	"H3604" : {
		"lemma" : "כִּכֵּר",
		"xlit" : "kikkêr",
		"pron" : "kik-kare'",
		"derivation" : "(Aramaic) corresponding to H3603 (כִּכָּר)",
		"def" : "a talent",
		"kjv" : "talent"
	},
	"H3605" : {
		"lemma" : "כֹּל",
		"xlit" : "kôl",
		"pron" : "kole",
		"derivation" : "or (Jeremiah 33:8) כּוֹל; from H3634 (כָּלַל)",
		"def" : "properly, the whole; hence, all, any or every (in the singular only, but often in a plural sense)",
		"kjv" : "(in) all (manner, (ye)), altogether, any (manner), enough, every (one, place, thing), howsoever, as many as, (no-) thing, ought, whatsoever, (the) whole, whoso(-ever)"
	},
	"H3606" : {
		"lemma" : "כֹּל",
		"xlit" : "kôl",
		"pron" : "kole",
		"derivation" : "(Aramaic) corresponding to H3605 (כֹּל)",
		"def" : "{properly, the whole; hence, all, any or every (in the singular only, but often in a plural sense)}",
		"kjv" : "all, any, + (forasmuch) as, + be-(for this) cause, every, + no (manner, -ne), + there (where) -fore, + though, what (where, who) -soever, (the) whole"
	},
	"H3607" : {
		"lemma" : "כָּלָא",
		"xlit" : "kâlâʼ",
		"pron" : "kaw-law'",
		"derivation" : "a primitive root",
		"def" : "to restrict, by act (hold back or in) or word (prohibit)",
		"kjv" : "finish, forbid, keep (back), refrain, restrain, retain, shut up, be stayed, withhold"
	},
	"H3608" : {
		"lemma" : "כֶּלֶא",
		"xlit" : "keleʼ",
		"pron" : "keh'-leh",
		"derivation" : "from H3607 (כָּלָא)",
		"def" : "a prison",
		"kjv" : "prison. Compare H3610 (כִּלְאַיִם), H3628 (כְּלִיא)"
	},
	"H3609" : {
		"lemma" : "כִּלְאָב",
		"xlit" : "Kilʼâb",
		"pron" : "kil-awb'",
		"derivation" : "apparently from H3607 (כָּלָא) and H1 (אָב); restraint of (his) father",
		"def" : "Kilab, an Israelite",
		"kjv" : "Chileab"
	},
	"H3610" : {
		"lemma" : "כִּלְאַיִם",
		"xlit" : "kilʼayim",
		"pron" : "kil-ah'-yim",
		"derivation" : "dual of H3608 (כֶּלֶא) in the original sense of separation",
		"def" : "two heterogeneities",
		"kjv" : "divers seeds (-e kinds), mingled (seed)"
	},
	"H3611" : {
		"lemma" : "כֶּלֶב",
		"xlit" : "keleb",
		"pron" : "keh'-leb",
		"derivation" : "from an unused root means. to yelp, or else to attack",
		"def" : "a dog; hence (by euphemism) a male prostitute",
		"kjv" : "dog"
	},
	"H3612" : {
		"lemma" : "כָּלֵב",
		"xlit" : "Kâlêb",
		"pron" : "kaw-labe'",
		"derivation" : "perhaps a form of H3611 (כֶּלֶב), or else from the same root in the sense of forcible",
		"def" : "Caleb, the name of three Israelites",
		"kjv" : "Caleb"
	},
	"H3613" : {
		"lemma" : "כָּלֵב אֶפְרָתָה",
		"xlit" : "Kâlêb ʼEphrâthâh",
		"pron" : "kaw-labe' ef-raw'-thaw",
		"derivation" : "from H3612 (כָּלֵב) and H672 (אֶפְרָת)",
		"def" : "Caleb-Ephrathah, a place in Egypt (if the text is correct)",
		"kjv" : "Caleb-ephrathah"
	},
	"H3614" : {
		"lemma" : "כָּלִבּוֹ",
		"xlit" : "Kâlibbôw",
		"pron" : "kaw-lib-bo'",
		"derivation" : "probably by erroneous transcription for כָּלֵבִי; patronymically from H3612 (כָּלֵב)",
		"def" : "a Calebite or descendant of Caleb",
		"kjv" : "of the house of Caleb"
	},
	"H3615" : {
		"lemma" : "כָּלָה",
		"xlit" : "kâlâh",
		"pron" : "kaw-law'",
		"derivation" : "a primitive root",
		"def" : "to end, whether intransitive (to cease, be finished, perish) or transitived (to complete, prepare, consume)",
		"kjv" : "accomplish, cease, consume (away), determine, destroy (utterly), be (when... were) done, (be an) end (of), expire, (cause to) fail, faint, finish, fulfil, [idiom] fully, [idiom] have, leave (off), long, bring to pass, wholly reap, make clean riddance, spend, quite take away, waste"
	},
	"H3616" : {
		"lemma" : "כָּלֶה",
		"xlit" : "kâleh",
		"pron" : "kaw-leh'",
		"derivation" : "from H3615 (כָּלָה)",
		"def" : "pining",
		"kjv" : "fail"
	},
	"H3617" : {
		"lemma" : "כָּלָה",
		"xlit" : "kâlâh",
		"pron" : "kaw-law'",
		"derivation" : "from H3615 (כָּלָה)",
		"def" : "a completion; adverb, completely; also destruction",
		"kjv" : "altogether, (be, utterly) consume(-d), consummation(-ption), was determined, (full, utter) end, riddance"
	},
	"H3618" : {
		"lemma" : "כַּלָּה",
		"xlit" : "kallâh",
		"pron" : "kal-law'",
		"derivation" : "from H3634 (כָּלַל)",
		"def" : "a bride (as if perfect); hence, a son's wife",
		"kjv" : "bride, daughter-in-law, spouse"
	},
	"H3619" : {
		"lemma" : "כְּלוּב",
		"xlit" : "kᵉlûwb",
		"pron" : "kel-oob'",
		"derivation" : "from the same as H3611 (כֶּלֶב)",
		"def" : "a bird-trap (as furnished with a clap-stick or treadle to spring it); hence, a basket (as resembling a wicker cage)",
		"kjv" : "basket, cage"
	},
	"H3620" : {
		"lemma" : "כְּלוּב",
		"xlit" : "Kᵉlûwb",
		"pron" : "kel-oob'",
		"derivation" : "the same as H3619 (כְּלוּב)xlit kᵉlûb corrected to kᵉlûwb",
		"def" : "Kelub, the name of two Israelites",
		"kjv" : "Chelub"
	},
	"H3621" : {
		"lemma" : "כְּלוּבַי",
		"xlit" : "Kᵉlûwbay",
		"pron" : "kel-oo-bay'-ee",
		"derivation" : "a form of H3612 (כָּלֵב)",
		"def" : "Kelubai, an Israelite",
		"kjv" : "Chelubai"
	},
	"H3622" : {
		"lemma" : "כְּלוּהַי",
		"xlit" : "Kᵉlûwhay",
		"pron" : "kel-oo-hah'-ee",
		"derivation" : "from H3615 (כָּלָה); completed",
		"def" : "Keluhai, an Israelite",
		"kjv" : "Chelluh"
	},
	"H3623" : {
		"lemma" : "כְּלוּלָה",
		"xlit" : "kᵉlûwlâh",
		"pron" : "kel-oo-law'",
		"derivation" : "denominative passive participle from H3618 (כַּלָּה)lemma כַּלָה missing dagesh, corrected to כַּלָּה",
		"def" : "bridehood (only in the plural)",
		"kjv" : "espousal"
	},
	"H3624" : {
		"lemma" : "כֶּלַח",
		"xlit" : "kelach",
		"pron" : "keh'-lakh",
		"derivation" : "from an unused root meaning to be complete",
		"def" : "maturity",
		"kjv" : "full (old) age"
	},
	"H3625" : {
		"lemma" : "כֶּלַח",
		"xlit" : "Kelach",
		"pron" : "keh'-lakh",
		"derivation" : "the same as H3624 (כֶּלַח)",
		"def" : "Kelach, a place in Assyria",
		"kjv" : "Calah"
	},
	"H3626" : {
		"lemma" : "כׇּל־חֹזֶה",
		"xlit" : "Kol-Chôzeh",
		"pron" : "kol-kho-zeh'",
		"derivation" : "from H3605 (כֹּל) and H2374 (חֹזֶה); every seer",
		"def" : "Col-Chozeh, an Israelite",
		"kjv" : "Col-hozeh"
	},
	"H3627" : {
		"lemma" : "כְּלִי",
		"xlit" : "kᵉlîy",
		"pron" : "kel-ee'",
		"derivation" : "from H3615 (כָּלָה)",
		"def" : "something prepared, i.e. any apparatus (as an implement, utensil, dress, vessel or weapon)",
		"kjv" : "armour(-bearer), artillery, bag, carriage, [phrase] furnish, furniture, instrument, jewel, that is made of, [idiom] one from another, that which pertaineth, pot, [phrase] psaltery, sack, stuff, thing, tool, vessel, ware, weapon, [phrase] whatsoever"
	},
	"H3628" : {
		"lemma" : "כְּלִיא",
		"xlit" : "kᵉlîyʼ",
		"pron" : "kel-ee'",
		"derivation" : "or כְּלוּא; from H3627 (כְּלִי) (compare H3608 (כֶּלֶא))",
		"def" : "a prison",
		"kjv" : "prison"
	},
	"H3629" : {
		"lemma" : "כִּלְיָה",
		"xlit" : "kilyâh",
		"pron" : "kil-yaw'",
		"derivation" : "feminine of H3627 (כְּלִי) (only in the plural)",
		"def" : "a kidney (as an essential organ); figuratively, the mind (as the interior self)",
		"kjv" : "kidneys, reins"
	},
	"H3630" : {
		"lemma" : "כִּלְיוֹן",
		"xlit" : "Kilyôwn",
		"pron" : "kil-yone'",
		"derivation" : "a form of H3631 (כִּלָּיוֹן)",
		"def" : "Kiljon, an Israelite",
		"kjv" : "Chilion"
	},
	"H3631" : {
		"lemma" : "כִּלָּיוֹן",
		"xlit" : "killâyôwn",
		"pron" : "kil-law-yone'",
		"derivation" : "from H3615 (כָּלָה)",
		"def" : "pining, destruction",
		"kjv" : "consumption, failing"
	},
	"H3632" : {
		"lemma" : "כָּלִיל",
		"xlit" : "kâlîyl",
		"pron" : "kaw-leel'",
		"derivation" : "from H3634 (כָּלַל)",
		"def" : "complete; as noun, the whole (specifically, a sacrifice entirely consumed); as adverb, fully",
		"kjv" : "all, every whit, flame, perfect(-ion), utterly, whole burnt offering (sacrifice), wholly"
	},
	"H3633" : {
		"lemma" : "כַּלְכֹּל",
		"xlit" : "Kalkôl",
		"pron" : "kal-kole'",
		"derivation" : "from H3557 (כּוּל); sustenance",
		"def" : "Calcol, an Israelite",
		"kjv" : "Calcol, Chalcol"
	},
	"H3634" : {
		"lemma" : "כָּלַל",
		"xlit" : "kâlal",
		"pron" : "kaw-lal'",
		"derivation" : "a primitive root",
		"def" : "to complete",
		"kjv" : "(make) perfect"
	},
	"H3635" : {
		"lemma" : "כְּלַל",
		"xlit" : "kᵉlal",
		"pron" : "kel-al'",
		"derivation" : "(Aramaic) corresponding to H3634 (כָּלַל)",
		"def" : "to complete",
		"kjv" : "finish, make (set) up"
	},
	"H3636" : {
		"lemma" : "כְּלָל",
		"xlit" : "Kᵉlâl",
		"pron" : "kel-awl'",
		"derivation" : "from H3634 (כָּלַל); complete",
		"def" : "Kelal, an Israelite",
		"kjv" : "Chelal"
	},
	"H3637" : {
		"lemma" : "כָּלַם",
		"xlit" : "kâlam",
		"pron" : "kaw-lawm'",
		"derivation" : "a primitive root",
		"def" : "properly, to wound; but only figuratively, to taunt or insult",
		"kjv" : "be (make) ashamed, blush, be confounded, be put to confusion, hurt, reproach, (do, put to) shame"
	},
	"H3638" : {
		"lemma" : "כִּלְמָד",
		"xlit" : "Kilmâd",
		"pron" : "kil-mawd'",
		"derivation" : "of foreign derivation",
		"def" : "Kilmad, a place apparently in the Assyrian empire",
		"kjv" : "Chilmad"
	},
	"H3639" : {
		"lemma" : "כְּלִמָּה",
		"xlit" : "kᵉlimmâh",
		"pron" : "kel-im-maw'",
		"derivation" : "from H3637 (כָּלַם)",
		"def" : "disgrace",
		"kjv" : "confusion, dishonour, reproach, shame"
	},
	"H3640" : {
		"lemma" : "כְּלִמּוּת",
		"xlit" : "kᵉlimmûwth",
		"pron" : "kel-im-mooth'",
		"derivation" : "from H3639 (כְּלִמָּה)",
		"def" : "disgrace",
		"kjv" : "shame"
	},
	"H3641" : {
		"lemma" : "כַּלְנֶה",
		"xlit" : "Kalneh",
		"pron" : "kal-neh'",
		"derivation" : "or כַּלְנֵה; also כַּלְנוֹ; of foreign derivation",
		"def" : "Calneh or Calno, a place in the Assyrian empire",
		"kjv" : "Calneh, Calno. Compare H3656 (כַּנֶּה)"
	},
	"H3642" : {
		"lemma" : "כָּמַהּ",
		"xlit" : "kâmahh",
		"pron" : "kaw-mah",
		"derivation" : "a primitive root",
		"def" : "to pine after",
		"kjv" : "long"
	},
	"H3643" : {
		"lemma" : "כִּמְהָם",
		"xlit" : "Kimhâm",
		"pron" : "kim-hawm'",
		"derivation" : "from H3642 (כָּמַהּ); pining",
		"def" : "Kimham, an Israelite",
		"kjv" : "Chimham"
	},
	"H3644" : {
		"lemma" : "כְּמוֹ",
		"xlit" : "kᵉmôw",
		"pron" : "kem-o'",
		"derivation" : "or כָּמוֹ; (compare H3651 (כֵּן))",
		"def" : "a form of the prefix 'k-', but used separately  as, thus, so",
		"kjv" : "according to, (such) as (it were, well as), in comparison of, like (as, to, unto), thus, when, worth"
	},
	"H3645" : {
		"lemma" : "כְּמוֹשׁ",
		"xlit" : "Kᵉmôwsh",
		"pron" : "kem-oshe'",
		"derivation" : "or (Jeremiah 48:7) כְּמִישׁ; from an unused root meaning to subdue; the powerful",
		"def" : "Kemosh, the god of the Moabites",
		"kjv" : "Chemosh"
	},
	"H3646" : {
		"lemma" : "כַּמֹּן",
		"xlit" : "kammôn",
		"pron" : "kam-mone'",
		"derivation" : "from an unused root meaning to store up or preserve",
		"def" : "'cummin' (from its use as a condiment)",
		"kjv" : "cummin"
	},
	"H3647" : {
		"lemma" : "כָּמַס",
		"xlit" : "kâmaç",
		"pron" : "kaw-mas'",
		"derivation" : "a primitive root",
		"def" : "to store away, i.e. (figuratively) in the memory",
		"kjv" : "lay up in store"
	},
	"H3648" : {
		"lemma" : "כָּמַר",
		"xlit" : "kâmar",
		"pron" : "kaw-mar'",
		"derivation" : "a primitive root",
		"def" : "properly, to intertwine or contract, i.e. (by implication) to shrivel (as with heat); figuratively, to be deeply affected with passion (love or pity)",
		"kjv" : "be black, be kindled, yearn"
	},
	"H3649" : {
		"lemma" : "כָּמָר",
		"xlit" : "kâmâr",
		"pron" : "kaw-mawr'",
		"derivation" : "from H3648 (כָּמַר)",
		"def" : "properly, an ascetic (as if shrunk with self-maceration), i.e. an idolatrous priest (only in plural)",
		"kjv" : "Chemarims (idolatrous) priests"
	},
	"H3650" : {
		"lemma" : "כִּמְרִיר",
		"xlit" : "kimrîyr",
		"pron" : "kim-reer'",
		"derivation" : "redupl. from H3648 (כָּמַר)",
		"def" : "obscuration (as if from shrinkage of light, i.e. an eclipse (only in plural)",
		"kjv" : "blackness"
	},
	"H3651" : {
		"lemma" : "כֵּן",
		"xlit" : "kên",
		"pron" : "kane",
		"derivation" : "from H3559 (כּוּן)",
		"def" : "properly, set upright; hence (figuratively as adjective) just; but usually (as adverb or conjunction) rightly or so (in various applications to manner, time and relation; often with other particles)",
		"kjv" : "[phrase] after that (this, -ward, -wards), as... as, [phrase] (for-) asmuch as yet, [phrase] be (for which) cause, [phrase] following, howbeit, in (the) like (manner, -wise), [idiom] the more, right, (even) so, state, straightway, such (thing), surely, [phrase] there (where) -fore, this, thus, true, well, [idiom] you"
	},
	"H3652" : {
		"lemma" : "כֵּן",
		"xlit" : "kên",
		"pron" : "kane",
		"derivation" : "(Aramaic) corresponding to H3651 (כֵּן)",
		"def" : "so",
		"kjv" : "thus"
	},
	"H3653" : {
		"lemma" : "כֵּן",
		"xlit" : "kên",
		"pron" : "kane",
		"derivation" : "the same as H3651 (כֵּן), used as a noun",
		"def" : "a stand, i.e. pedestal or station",
		"kjv" : "base, estate, foot, office, place, well"
	},
	"H3654" : {
		"lemma" : "כֵּן",
		"xlit" : "kên",
		"pron" : "kane",
		"derivation" : "from H3661 (כָּנַן) in the sense of fastening; (from infixing its sting; used only in plural (and irregular in Exodus 8:17,18; Hebrews 13:14))",
		"def" : "a gnat",
		"kjv" : "lice, [idiom] manner"
	},
	"H3655" : {
		"lemma" : "כָּנָה",
		"xlit" : "kânâh",
		"pron" : "kaw-naw'",
		"derivation" : "a primitive root",
		"def" : "to address by an additional name; hence, to eulogize",
		"kjv" : "give flattering titles, surname (himself)"
	},
	"H3656" : {
		"lemma" : "כַּנֶּה",
		"xlit" : "Kanneh",
		"pron" : "kan-neh'",
		"derivation" : "for H3641 (כַּלְנֶה)",
		"def" : "Canneh, a place in Assyria",
		"kjv" : "Canneh"
	},
	"H3657" : {
		"lemma" : "כַּנָּה",
		"xlit" : "kannâh",
		"pron" : "kaw-naw'",
		"derivation" : "from H3661 (כָּנַן)",
		"def" : "a plant (as set)",
		"kjv" : "[idiom] vineyard"
	},
	"H3658" : {
		"lemma" : "כִּנּוֹר",
		"xlit" : "kinnôwr",
		"pron" : "kin-nore'",
		"derivation" : "from a unused root meaning to twang",
		"def" : "a harp",
		"kjv" : "harp"
	},
	"H3659" : {
		"lemma" : "כׇּנְיָהוּ",
		"xlit" : "Konyâhûw",
		"pron" : "kon-yaw'-hoo",
		"derivation" : "for H3204 (יְכׇנְיָה)",
		"def" : "Conjah, an Israelite king",
		"kjv" : "Coniah"
	},
	"H3660" : {
		"lemma" : "כְּנֵמָא",
		"xlit" : "kᵉnêmâʼ",
		"pron" : "ken-ay-maw'",
		"derivation" : "(Aramaic) corresponding to H3644 (כְּמוֹ)",
		"def" : "so or thus",
		"kjv" : "so, (in) this manner (sort), thus"
	},
	"H3661" : {
		"lemma" : "כָּנַן",
		"xlit" : "kânan",
		"pron" : "kaw-nan'",
		"derivation" : "a primitive root",
		"def" : "to set out, i.e. plant",
		"kjv" : "[idiom] vineyard"
	},
	"H3662" : {
		"lemma" : "כְּנָנִי",
		"xlit" : "Kᵉnânîy",
		"pron" : "ken-aw-nee'",
		"derivation" : "from H3661 (כָּנַן); planted",
		"def" : "Kenani, an Israelite",
		"kjv" : "Chenani"
	},
	"H3663" : {
		"lemma" : "כְּנַנְיָה",
		"xlit" : "Kᵉnanyâh",
		"pron" : "ken-an-yaw'",
		"derivation" : "or כְּנַנְיָהוּ; from H3661 (כָּנַן) and H3050 (יָהּ); Jah has planted",
		"def" : "Kenanjah, an Israelite",
		"kjv" : "Chenaniah"
	},
	"H3664" : {
		"lemma" : "כָּנַס",
		"xlit" : "kânaç",
		"pron" : "kaw-nas'",
		"derivation" : "a primitive root",
		"def" : "to collect; hence, to enfold",
		"kjv" : "gather (together), heap up, wrap self"
	},
	"H3665" : {
		"lemma" : "כָּנַע",
		"xlit" : "kânaʻ",
		"pron" : "kaw-nah'",
		"derivation" : "a primitive root",
		"def" : "properly, to bend the knee; hence, to humiliate, vanquish",
		"kjv" : "bring down (low), into subjection, under, humble (self), subdue"
	},
	"H3666" : {
		"lemma" : "כִּנְעָה",
		"xlit" : "kinʻâh",
		"pron" : "kin-aw'",
		"derivation" : "from H3665 (כָּנַע) in the sense of folding (compare H3664 (כָּנַס))",
		"def" : "a package",
		"kjv" : "wares"
	},
	"H3667" : {
		"lemma" : "כְּנַעַן",
		"xlit" : "Kᵉnaʻan",
		"pron" : "ken-ah'-an",
		"derivation" : "from H3665 (כָּנַע); humiliated",
		"def" : "Kenaan, a son a Ham; also the country inhabited by him",
		"kjv" : "Canaan, merchant, traffick"
	},
	"H3668" : {
		"lemma" : "כְּנַעֲנָה",
		"xlit" : "Kᵉnaʻănâh",
		"pron" : "ken-ah-an-aw'",
		"derivation" : "feminine of H3667 (כְּנַעַן)",
		"def" : "Kenaanah, the name of two Israelites",
		"kjv" : "Chenaanah"
	},
	"H3669" : {
		"lemma" : "כְּנַעַנִי",
		"xlit" : "Kᵉnaʻanîy",
		"pron" : "ken-ah-an-ee'",
		"derivation" : "patrial from H3667 (כְּנַעַן)",
		"def" : "a Kenaanite or inhabitant of Kenaan; by implication, a pedlar (the Canaanites standing for their neighbors the Ishmaelites, who conducted mercantile caravans)",
		"kjv" : "Canaanite, merchant, trafficker"
	},
	"H3670" : {
		"lemma" : "כָּנַף",
		"xlit" : "kânaph",
		"pron" : "kaw-naf'",
		"derivation" : "a primitive root",
		"def" : "properly, to project laterally, i.e. probably (reflexive) to withdraw",
		"kjv" : "be removed"
	},
	"H3671" : {
		"lemma" : "כָּנָף",
		"xlit" : "kânâph",
		"pron" : "kaw-nawf'",
		"derivation" : "from H3670 (כָּנַף)",
		"def" : "an edge or extremity; specifically (of a bird or army) a wing, (of a garment or bedclothing) a flap, (of the earth) a quarter, (of a building) a pinnacle",
		"kjv" : "[phrase] bird, border, corner, end, feather(-ed), [idiom] flying, [phrase] (one an-) other, overspreading, [idiom] quarters, skirt, [idiom] sort, uttermost part, wing(-ed)"
	},
	"H3672" : {
		"lemma" : "כִּנְּרוֹת",
		"xlit" : "Kinnᵉrôwth",
		"pron" : "kin-ner-oth'",
		"derivation" : "or כִּנֶּרֶתlemma כִּנֶרֶת missing dagesh, corrected to כִּנֶּרֶת; respectively plural and singular feminine from the same as H3658 (כִּנּוֹר); perhaps harp-shaped",
		"def" : "Kinneroth or Kinnereth, a place in Palestine",
		"kjv" : "Chinnereth, Chinneroth, Cinneroth"
	},
	"H3673" : {
		"lemma" : "כָּנַשׁ",
		"xlit" : "kânash",
		"pron" : "kaw-nash'",
		"derivation" : "(Aramaic) corresponding to H3664 (כָּנַס)",
		"def" : "to assemble",
		"kjv" : "gather together"
	},
	"H3674" : {
		"lemma" : "כְּנָת",
		"xlit" : "kᵉnâth",
		"pron" : "ken-awth'",
		"derivation" : "from H3655 (כָּנָה)",
		"def" : "a colleague (as having the same title)",
		"kjv" : "companion"
	},
	"H3675" : {
		"lemma" : "כְּנָת",
		"xlit" : "kᵉnâth",
		"pron" : "ken-awth'",
		"derivation" : "(Aramaic) corresponding to H3674 (כְּנָת)",
		"def" : "{a colleague (as having the same title)}",
		"kjv" : "companion"
	},
	"H3676" : {
		"lemma" : "כֵּס",
		"xlit" : "kêç",
		"pron" : "kace",
		"derivation" : "apparently a contraction for H3678 (כִּסֵּא), but probably by erroneous transcription for H5251 (נֵס)",
		"def" : "{a flag; also a sail; by implication, a flagstaff; generally a signal; figuratively, a token}",
		"kjv" : "sworn"
	},
	"H3677" : {
		"lemma" : "כֶּסֶא",
		"xlit" : "keçeʼ",
		"pron" : "keh'-seh",
		"derivation" : "or כֶּסֶה; apparently from H3680 (כָּסָה)",
		"def" : "properly, fulness or the full moon, i.e. its festival",
		"kjv" : "(time) appointed"
	},
	"H3678" : {
		"lemma" : "כִּסֵּא",
		"xlit" : "kiççêʼ",
		"pron" : "kis-say'",
		"derivation" : "or כִּסֵּה; from H3680 (כָּסָה)",
		"def" : "properly, covered, i.e. a throne (as canopied)",
		"kjv" : "seat, stool, throne"
	},
	"H3679" : {
		"lemma" : "כַּסְדַּי",
		"xlit" : "Kaçday",
		"pron" : "kas-dah'-ee",
		"derivation" : "for H3778 (כַּשְׂדִּי)",
		"def" : "{a Kasdite, or descendant of Kesed; by implication, a Chaldaean (as if so descended); also an astrologer (as if proverbial of that people}",
		"kjv" : "Chaldean"
	},
	"H3680" : {
		"lemma" : "כָּסָה",
		"xlit" : "kâçâh",
		"pron" : "kaw-saw'",
		"derivation" : "a primitive root",
		"def" : "properly, to plump, i.e. fill up hollows; by implication, to cover (for clothing or secrecy)",
		"kjv" : "clad self, close, clothe, conceal, cover (self), (flee to) hide, overwhelm. Compare H3780 (כָּשָׂה)"
	},
	"H3681" : {
		"lemma" : "כָּסוּי",
		"xlit" : "kâçûwy",
		"pron" : "kaw-soo'-ee",
		"derivation" : "passive participle of H3680 (כָּסָה)",
		"def" : "properly, covered, i.e. (as noun) a covering",
		"kjv" : "covering"
	},
	"H3682" : {
		"lemma" : "כְּסוּת",
		"xlit" : "kᵉçûwth",
		"pron" : "kes-ooth'",
		"derivation" : "from H3680 (כָּסָה)",
		"def" : "a cover (garment); figuratively, a veiling",
		"kjv" : "covering, raiment, vesture"
	},
	"H3683" : {
		"lemma" : "כָּסַח",
		"xlit" : "kâçach",
		"pron" : "kaw-sakh'",
		"derivation" : "a primitive root",
		"def" : "to cut off",
		"kjv" : "cut down (up)"
	},
	"H3684" : {
		"lemma" : "כְּסִיל",
		"xlit" : "kᵉçîyl",
		"pron" : "kes-eel'",
		"derivation" : "from H3688 (כָּסַל)",
		"def" : "properly, fat, i.e. (figuratively) stupid or silly",
		"kjv" : "fool(-ish)"
	},
	"H3685" : {
		"lemma" : "כְּסִיל",
		"xlit" : "Kᵉçîyl",
		"pron" : "kes-eel'",
		"derivation" : "the same as H3684 (כְּסִיל)",
		"def" : "any notable constellation; specifically Orion (as if a burly one)",
		"kjv" : "constellation, Orion"
	},
	"H3686" : {
		"lemma" : "כְּסִיל",
		"xlit" : "Kᵉçîyl",
		"pron" : "kes-eel'",
		"derivation" : "the same as H3684 (כְּסִיל)",
		"def" : "Kesil, a place in Palestine",
		"kjv" : "Chesil"
	},
	"H3687" : {
		"lemma" : "כְּסִילוּת",
		"xlit" : "kᵉçîylûwth",
		"pron" : "kes-eel-ooth'",
		"derivation" : "from H3684 (כְּסִיל)",
		"def" : "silliness",
		"kjv" : "foolish"
	},
	"H3688" : {
		"lemma" : "כָּסַל",
		"xlit" : "kâçal",
		"pron" : "kaw-sal'",
		"derivation" : "a primitive root",
		"def" : "properly, to be fat, i.e. (figuratively) silly",
		"kjv" : "be foolish"
	},
	"H3689" : {
		"lemma" : "כֶּסֶל",
		"xlit" : "keçel",
		"pron" : "keh'-sel",
		"derivation" : "from H3688 (כָּסַל)",
		"def" : "properly, fatness, i.e. by implication (literally) the loin (as the seat of the leaf fat) or (generally) the viscera; also (figuratively) silliness or (in a good sense) trust",
		"kjv" : "confidence, flank, folly, hope, loin"
	},
	"H3690" : {
		"lemma" : "כִּסְלָה",
		"xlit" : "kiçlâh",
		"pron" : "kis-law'",
		"derivation" : "feminine of H3689 (כֶּסֶל)",
		"def" : "in a good sense, trust; in a bad one, silliness",
		"kjv" : "confidence, folly"
	},
	"H3691" : {
		"lemma" : "כִּסְלֵו",
		"xlit" : "Kiçlêv",
		"pron" : "kis-lave'",
		"derivation" : "probably of foreign origin",
		"def" : "Kisleu, the 9th Hebrew month",
		"kjv" : "Chisleu"
	},
	"H3692" : {
		"lemma" : "כִּסְלוֹן",
		"xlit" : "Kiçlôwn",
		"pron" : "kis-lone'",
		"derivation" : "from H3688 (כָּסַל); hopeful",
		"def" : "Kislon, an Israelite",
		"kjv" : "Chislon"
	},
	"H3693" : {
		"lemma" : "כְּסָלוֹן",
		"xlit" : "Kᵉçâlôwn",
		"pron" : "kes-aw-lone'",
		"derivation" : "from H3688 (כָּסַל); fertile",
		"def" : "Kesalon, a place in Palestine",
		"kjv" : "Chesalon"
	},
	"H3694" : {
		"lemma" : "כְּסֻלּוֹת",
		"xlit" : "Kᵉçullôwth",
		"pron" : "kes-ool-loth'",
		"derivation" : "feminine plural of passive participle of H3688 (כָּסַל); fattened",
		"def" : "Kesulloth, a place in Palestine",
		"kjv" : "Chesulloth"
	},
	"H3695" : {
		"lemma" : "כַּסְלֻחִים",
		"xlit" : "Kaçluchîym",
		"pron" : "kas-loo'-kheem",
		"derivation" : "a plural probably of foreign derivation",
		"def" : "Casluchim, a people cognate to the Egyptians",
		"kjv" : "Casluhim"
	},
	"H3696" : {
		"lemma" : "כִּסְלֹת תָּבֹר",
		"xlit" : "Kiçlôth Tâbôr",
		"pron" : "kis-loth' taw-bore'",
		"derivation" : "from the feminine plural of H3689 (כֶּסֶל) and H8396 (תָּבוֹר); flanks of Tabor",
		"def" : "Kisloth-Tabor, a place in Palestine",
		"kjv" : "Chisloth-tabor"
	},
	"H3697" : {
		"lemma" : "כָּסַם",
		"xlit" : "kâçam",
		"pron" : "kaw-sam'",
		"derivation" : "a primitive root",
		"def" : "to shear",
		"kjv" : "[idiom] only, poll. Compare H3765 (כִּרְסֵם)"
	},
	"H3698" : {
		"lemma" : "כֻּסֶּמֶת",
		"xlit" : "kuççemeth",
		"pron" : "koos-seh'-meth",
		"derivation" : "from H3697 (כָּסַם)",
		"def" : "spelt (from its bristliness as if just shorn)",
		"kjv" : "fitches, rie"
	},
	"H3699" : {
		"lemma" : "כָּסַס",
		"xlit" : "kâçaç",
		"pron" : "kaw-sas'",
		"derivation" : "a primitive root",
		"def" : "to estimate",
		"kjv" : "make count"
	},
	"H3700" : {
		"lemma" : "כָּסַף",
		"xlit" : "kâçaph",
		"pron" : "kaw-saf'",
		"derivation" : "a primitive root",
		"def" : "properly, to become pale, i.e. (by implication) to pine after; also to fear",
		"kjv" : "(have) desire, be greedy, long, sore"
	},
	"H3701" : {
		"lemma" : "כֶּסֶף",
		"xlit" : "keçeph",
		"pron" : "keh'-sef",
		"derivation" : "from H3700 (כָּסַף)",
		"def" : "silver (from its pale color); by implication, money",
		"kjv" : "money, price, silver(-ling)"
	},
	"H3702" : {
		"lemma" : "כְּסַף",
		"xlit" : "kᵉçaph",
		"pron" : "kes-af'",
		"derivation" : "(Aramaic) corresponding to H3701 (כֶּסֶף)",
		"def" : "{silver (from its pale color); by implication, money}",
		"kjv" : "money, silver"
	},
	"H3703" : {
		"lemma" : "כָּסִפְיָא",
		"xlit" : "Kâçiphyâʼ",
		"pron" : "kaw-sif-yaw'",
		"derivation" : "perhaps from H3701 (כֶּסֶף); silvery",
		"def" : "Casiphja, a place in Babylon",
		"kjv" : "Casiphia"
	},
	"H3704" : {
		"lemma" : "כֶּסֶת",
		"xlit" : "keçeth",
		"pron" : "keh'-seth",
		"derivation" : "from H3680 (כָּסָה)",
		"def" : "a cushion or pillow (as covering a seat or bed)",
		"kjv" : "pillow"
	},
	"H3705" : {
		"lemma" : "כְּעַן",
		"xlit" : "kᵉʻan",
		"pron" : "keh-an'",
		"derivation" : "(Aramaic) probably from H3652 (כֵּן)",
		"def" : "now",
		"kjv" : "now"
	},
	"H3706" : {
		"lemma" : "כְּעֶנֶת",
		"xlit" : "kᵉʻeneth",
		"pron" : "keh-eh'-neth",
		"derivation" : "(Aramaic) or כְּעֶת; (Aramaic), feminine of H3705 (כְּעַן)",
		"def" : "thus (only in the formula 'and so forth')",
		"kjv" : "at such a time"
	},
	"H3707" : {
		"lemma" : "כַּעַס",
		"xlit" : "kaʻaç",
		"pron" : "kaw-as'",
		"derivation" : "a primitive root",
		"def" : "to trouble; by implication, to grieve, rage, be indignant",
		"kjv" : "be angry, be grieved, take indignation, provoke (to anger, unto wrath), have sorrow, vex, be wroth"
	},
	"H3708" : {
		"lemma" : "כַּעַס",
		"xlit" : "kaʻaç",
		"pron" : "kah'-as",
		"derivation" : "or (in Job) כַּעַשׂ; from H3707 (כַּעַס)",
		"def" : "vexation",
		"kjv" : "anger, angry, grief, indignation, provocation, provoking, [idiom] sore, sorrow, spite, wrath"
	},
	"H3709" : {
		"lemma" : "כַּף",
		"xlit" : "kaph",
		"pron" : "kaf",
		"derivation" : "from H3721 (כָּפַף)",
		"def" : "the hollow hand or palm (so of the paw of an animal, of the sole, and even of the bowl of a dish or sling, the handle of a bolt, the leaves of a palm-tree); figuratively, power",
		"kjv" : "branch, [phrase] foot, hand((-ful), -dle, (-led)), hollow, middle, palm, paw, power, sole, spoon"
	},
	"H3710" : {
		"lemma" : "כֵּף",
		"xlit" : "kêph",
		"pron" : "kafe",
		"derivation" : "from H3721 (כָּפַף)",
		"def" : "a hollow rock",
		"kjv" : "rock"
	},
	"H3711" : {
		"lemma" : "כָּפָה",
		"xlit" : "kâphâh",
		"pron" : "kaw-faw'",
		"derivation" : "a primitive root",
		"def" : "properly, to bend, i.e. (figuratively) to tame or subdue",
		"kjv" : "pacify"
	},
	"H3712" : {
		"lemma" : "כִּפָּה",
		"xlit" : "kippâh",
		"pron" : "kip-paw'",
		"derivation" : "feminine of H3709 (כַּף)",
		"def" : "a leaf of a palmtree",
		"kjv" : "branch"
	},
	"H3713" : {
		"lemma" : "כְּפוֹר",
		"xlit" : "kᵉphôwr",
		"pron" : "kef-ore'",
		"derivation" : "from H3722 (כָּפַר)",
		"def" : "properly, a cover, i.e. (by implication) a tankard (or covered goblet); also white frost (as covering the ground)",
		"kjv" : "bason, hoar(-y) frost"
	},
	"H3714" : {
		"lemma" : "כָּפִיס",
		"xlit" : "kâphîyç",
		"pron" : "kaw-fece'",
		"derivation" : "from an unused root meaning to connect",
		"def" : "a girder",
		"kjv" : "beam"
	},
	"H3715" : {
		"lemma" : "כְּפִיר",
		"xlit" : "kᵉphîyr",
		"pron" : "kef-eer'",
		"derivation" : "from H3722 (כָּפַר)",
		"def" : "a village (as covered in by walls); also a young lion (perhaps as covered with a mane)",
		"kjv" : "(young) lion, village. Compare H3723 (כָּפָר)"
	},
	"H3716" : {
		"lemma" : "כְּפִירָה",
		"xlit" : "Kᵉphîyrâh",
		"pron" : "kef-ee-raw'",
		"derivation" : "feminine of H3715 (כְּפִיר); the village (always with the art.)",
		"def" : "Kephirah, a place in Palestine",
		"kjv" : "Chephirah"
	},
	"H3717" : {
		"lemma" : "כָּפַל",
		"xlit" : "kâphal",
		"pron" : "kaw-fal'",
		"derivation" : "a primitive root",
		"def" : "to fold together; figuratively, to repeat",
		"kjv" : "double"
	},
	"H3718" : {
		"lemma" : "כֶּפֶל",
		"xlit" : "kephel",
		"pron" : "keh'-fel",
		"derivation" : "from H3717 (כָּפַל)",
		"def" : "a duplicate",
		"kjv" : "double"
	},
	"H3719" : {
		"lemma" : "כָּפַן",
		"xlit" : "kâphan",
		"pron" : "kaw-fan'",
		"derivation" : "a primitive root",
		"def" : "to bend",
		"kjv" : "bend"
	},
	"H3720" : {
		"lemma" : "כָּפָן",
		"xlit" : "kâphân",
		"pron" : "kaw-fawn'",
		"derivation" : "from H3719 (כָּפַן)",
		"def" : "hunger (as making to stoop with emptiness and pain)",
		"kjv" : "famine"
	},
	"H3721" : {
		"lemma" : "כָּפַף",
		"xlit" : "kâphaph",
		"pron" : "kaw-faf'",
		"derivation" : "a primitive root",
		"def" : "to curve",
		"kjv" : "bow down (self)"
	},
	"H3722" : {
		"lemma" : "כָּפַר",
		"xlit" : "kâphar",
		"pron" : "kaw-far'",
		"derivation" : "a primitive root",
		"def" : "to cover (specifically with bitumen); figuratively, to expiate or condone, to placate or cancel",
		"kjv" : "appease, make (an atonement, cleanse, disannul, forgive, be merciful, pacify, pardon, purge (away), put off, (make) reconcile(-liation)"
	},
	"H3723" : {
		"lemma" : "כָּפָר",
		"xlit" : "kâphâr",
		"pron" : "kaw-fawr'",
		"derivation" : "from H3722 (כָּפַר)",
		"def" : "a village (as protected by walls)",
		"kjv" : "village. Compare H3715 (כְּפִיר)"
	},
	"H3724" : {
		"lemma" : "כֹּפֶר",
		"xlit" : "kôpher",
		"pron" : "ko'-fer",
		"derivation" : "from H3722 (כָּפַר)",
		"def" : "properly, a cover, i.e. (literally) a village (as covered in); (specifically) bitumen (as used for coating), and the henna plant (as used for dyeing); figuratively, a redemption-price",
		"kjv" : "bribe, camphire, pitch, ransom, satisfaction, sum of money, village"
	},
	"H3725" : {
		"lemma" : "כִּפֻּר",
		"xlit" : "kippur",
		"pron" : "kip-poor'",
		"derivation" : "from H3722 (כָּפַר)",
		"def" : "expiation (only in plural)",
		"kjv" : "atonement"
	},
	"H3726" : {
		"lemma" : "כְּפַר הָעַמּוֹנִי",
		"xlit" : "Kᵉphar hâ-ʻAmmôwnîy",
		"pron" : "kef-ar' haw-am-monee'",
		"derivation" : "from H3723 (כָּפָר) and H5984 (עַמּוֹנִי), with the article interposed; village of the Ammonite",
		"def" : "Kefar-ha-Ammoni, a place in Palestine",
		"kjv" : "Chefarhaamonai"
	},
	"H3727" : {
		"lemma" : "כַּפֹּרֶת",
		"xlit" : "kappôreth",
		"pron" : "kap-po'-reth",
		"derivation" : "from H3722 (כָּפַר)",
		"def" : "a lid (used only of the cover of the sacred Ark)",
		"kjv" : "mercy seat"
	},
	"H3728" : {
		"lemma" : "כָּפַשׁ",
		"xlit" : "kâphash",
		"pron" : "kaw-fash'",
		"derivation" : "a primitive root",
		"def" : "to tread down; figuratively, to humiliate",
		"kjv" : "cover"
	},
	"H3729" : {
		"lemma" : "כְּפַת",
		"xlit" : "kᵉphath",
		"pron" : "kef-ath'",
		"derivation" : "(Aramaic) a root of uncertain correspondence",
		"def" : "to fetter",
		"kjv" : "bind"
	},
	"H3730" : {
		"lemma" : "כַּפְתֹּר",
		"xlit" : "kaphtôr",
		"pron" : "kaf-tore'",
		"derivation" : "or (Amos 9:1) כַּפְתּוֹר; probably from an unused root meaning to encircle",
		"def" : "a chaplet; but used only in an architectonic sense, i.e. the capital of acolumn, or a wreath-like button or disk on the candelabrum",
		"kjv" : "knop, (upper) lintel"
	},
	"H3731" : {
		"lemma" : "כַּפְתֹּר",
		"xlit" : "Kaphtôr",
		"pron" : "kaf-tore'",
		"derivation" : "or (Amos 9:7) כַּפְתּוֹר; apparently the same as H3730 (כַּפְתֹּר)",
		"def" : "Caphtor (i.e. a wreath-shaped island), the original seat of the Philistines",
		"kjv" : "Caphtor"
	},
	"H3732" : {
		"lemma" : "כַּפְתֹּרִי",
		"xlit" : "Kaphtôrîy",
		"pron" : "kaf-to-ree'",
		"derivation" : "patrial from H3731 (כַּפְתֹּר)",
		"def" : "a Caphtorite (collectively) or native of Caphtor",
		"kjv" : "Caphthorim, Caphtorim(-s)"
	},
	"H3733" : {
		"lemma" : "כַּר",
		"xlit" : "kar",
		"pron" : "kar",
		"derivation" : "from H3769 (כָּרַר) in the sense of plumpness",
		"def" : "a ram (as full-grown and fat), including a battering-ram (as butting); hence, a meadow (as for sheep); also a pad or camel's saddle (as puffed out)",
		"kjv" : "captain, furniture, lamb, (large) pasture, ram. See also H1033 (בֵּית כַּר), H3746 (כָּרִי)"
	},
	"H3734" : {
		"lemma" : "כֹּר",
		"xlit" : "kôr",
		"pron" : "kore",
		"derivation" : "from the same as H3564 (כּוּר)",
		"def" : "properly, a deep round vessel, i.e. (specifically) a cor or measure for things dry",
		"kjv" : "cor, measure. Aramaic the same"
	},
	"H3735" : {
		"lemma" : "כָּרָא",
		"xlit" : "Kârâʼ",
		"pron" : "kaw-raw'",
		"derivation" : "(Aramaic) probably corresponding to H3738 (כָּרָה) in the sense of piercing(figuratively)",
		"def" : "to grieve",
		"kjv" : "be grieved"
	},
	"H3736" : {
		"lemma" : "כַּרְבֵּל",
		"xlit" : "karbêl",
		"pron" : "kar-bale'",
		"derivation" : "from the same as H3525 (כֶּבֶל)",
		"def" : "to gird or clothe",
		"kjv" : "clothed"
	},
	"H3737" : {
		"lemma" : "כַּרְבְּלָא",
		"xlit" : "karbᵉlâʼ",
		"pron" : "kar-bel-aw'",
		"derivation" : "(Aramaic) from a verb corresponding to that of H3736 (כַּרְבֵּל)",
		"def" : "a mantle",
		"kjv" : "hat"
	},
	"H3738" : {
		"lemma" : "כָּרָה",
		"xlit" : "kârâh",
		"pron" : "kaw-raw'",
		"derivation" : "a primitive root",
		"def" : "properly, to dig; figuratively, to plot; generally, to bore or open",
		"kjv" : "dig, [idiom] make (a banquet), open"
	},
	"H3739" : {
		"lemma" : "כָּרָה",
		"xlit" : "kârâh",
		"pron" : "kaw-raw'",
		"derivation" : "usually assigned as a primitive root, but probably only a special application of H3738 (כָּרָה) (through the common idea of planning implied in a bargain)",
		"def" : "to purchase",
		"kjv" : "buy, prepare"
	},
	"H3740" : {
		"lemma" : "כֵּרָה",
		"xlit" : "kêrâh",
		"pron" : "kay-raw'",
		"derivation" : "from H3739 (כָּרָה)",
		"def" : "a purchase",
		"kjv" : "provision"
	},
	"H3741" : {
		"lemma" : "כָּרָה",
		"xlit" : "kârâh",
		"pron" : "kaw-raw'",
		"derivation" : "feminine of H3733 (כַּר)",
		"def" : "a meadow",
		"kjv" : "cottage"
	},
	"H3742" : {
		"lemma" : "כְּרוּב",
		"xlit" : "kᵉrûwb",
		"pron" : "ker-oob'",
		"derivation" : "of uncertain derivation",
		"def" : "a cherub or imaginary figure",
		"kjv" : "cherub, (plural) cherubims"
	},
	"H3743" : {
		"lemma" : "כְּרוּב",
		"xlit" : "Kᵉrûwb",
		"pron" : "ker-oob'",
		"derivation" : "the same as H3742 (כְּרוּב)",
		"def" : "Kerub, a place in Babylon",
		"kjv" : "Cherub"
	},
	"H3744" : {
		"lemma" : "כָּרוֹז",
		"xlit" : "kârôwz",
		"pron" : "kaw-roze'",
		"derivation" : "(Aramaic) from H3745 (כְּרַז)",
		"def" : "a herald",
		"kjv" : "herald"
	},
	"H3745" : {
		"lemma" : "כְּרַז",
		"xlit" : "kᵉraz",
		"pron" : "ker-az'",
		"derivation" : "(Aramaic) probably of Greek origin",
		"def" : "to proclaim",
		"kjv" : "make a proclamation"
	},
	"H3746" : {
		"lemma" : "כָּרִי",
		"xlit" : "kârîy",
		"pron" : "kaw-ree'",
		"derivation" : "perhaps an abridged plural of H3733 (כַּר) in the sense of leader (of the flock)",
		"def" : "a life-guardsman",
		"kjv" : "captains, Cherethites (from the margin)"
	},
	"H3747" : {
		"lemma" : "כְּרִית",
		"xlit" : "Kᵉrîyth",
		"pron" : "ker-eeth'",
		"derivation" : "from H3772 (כָּרַת); a cut",
		"def" : "Kerith, a brook of Palestine",
		"kjv" : "Cherith"
	},
	"H3748" : {
		"lemma" : "כְּרִיתוּת",
		"xlit" : "kᵉrîythûwth",
		"pron" : "ker-ee-thooth'",
		"derivation" : "from H3772 (כָּרַת)",
		"def" : "a cutting (of the matrimonial bond), i.e. divorce",
		"kjv" : "divorce(-ment)"
	},
	"H3749" : {
		"lemma" : "כַּרְכֹּב",
		"xlit" : "karkôb",
		"pron" : "kar-kobe'",
		"derivation" : "expanded from the same as H3522 (כַּבּוֹן)",
		"def" : "a rim or top margin",
		"kjv" : "compass"
	},
	"H3750" : {
		"lemma" : "כַּרְכֹּם",
		"xlit" : "karkôm",
		"pron" : "kar-kome'",
		"derivation" : "probably of foreign origin",
		"def" : "the crocus",
		"kjv" : "saffron"
	},
	"H3751" : {
		"lemma" : "כַּרְכְּמִישׁ",
		"xlit" : "Karkᵉmîysh",
		"pron" : "kar-kem-eesh'",
		"derivation" : "of foreign derivation",
		"def" : "Karkemish, a place in Syria",
		"kjv" : "Carchemish"
	},
	"H3752" : {
		"lemma" : "כַּרְכַּס",
		"xlit" : "Karkaç",
		"pron" : "kar-kas'",
		"derivation" : "of Persian origin",
		"def" : "Karkas, a eunuch of Xerxes",
		"kjv" : "Carcas"
	},
	"H3753" : {
		"lemma" : "כַּרְכָּרָה",
		"xlit" : "karkârâh",
		"pron" : "kar-kaw-raw'",
		"derivation" : "from H3769 (כָּרַר)",
		"def" : "a dromedary (from its rapid motion as if dancing)",
		"kjv" : "swift beast"
	},
	"H3754" : {
		"lemma" : "כֶּרֶם",
		"xlit" : "kerem",
		"pron" : "keh'-rem",
		"derivation" : "from an unused root of uncertain meaning",
		"def" : "a garden or vineyard",
		"kjv" : "vines, (increase of the) vineyard(-s), vintage. See also H1021 (בֵּית הַכֶּרֶם)"
	},
	"H3755" : {
		"lemma" : "כֹּרֵם",
		"xlit" : "kôrêm",
		"pron" : "ko-rame'",
		"derivation" : "active participle of an imaginary denominative from H3754 (כֶּרֶם)",
		"def" : "a vinedresser",
		"kjv" : "vine dresser (as one or two words)"
	},
	"H3756" : {
		"lemma" : "כַּרְמִי",
		"xlit" : "Karmîy",
		"pron" : "kar-mee'",
		"derivation" : "from H3754 (כֶּרֶם); gardener",
		"def" : "Karmi, the name of three Israelites",
		"kjv" : "Carmi"
	},
	"H3757" : {
		"lemma" : "כַּרְמִי",
		"xlit" : "Karmîy",
		"pron" : "kar-mee'",
		"derivation" : "patronymically from H3756 (כַּרְמִי)",
		"def" : "a Karmite or descendant of Karmi",
		"kjv" : "Carmites"
	},
	"H3758" : {
		"lemma" : "כַּרְמִיל",
		"xlit" : "karmîyl",
		"pron" : "kar-mele'",
		"derivation" : "probably of foreign origin",
		"def" : "carmine, a deep red",
		"kjv" : "crimson"
	},
	"H3759" : {
		"lemma" : "כַּרְמֶל",
		"xlit" : "karmel",
		"pron" : "kar-mel'",
		"derivation" : "from H3754 (כֶּרֶם)",
		"def" : "a planted field (garden, orchard, vineyard or park); by implication, garden produce",
		"kjv" : "full (green) ears (of corn), fruitful field (place), plentiful (field)"
	},
	"H3760" : {
		"lemma" : "כַּרְמֶל",
		"xlit" : "Karmel",
		"pron" : "kar-mel'",
		"derivation" : "the same as H3759 (כַּרְמֶל)",
		"def" : "Karmel, the name of a hill and of a town in Palestine",
		"kjv" : "Carmel, fruitful (plentiful) field, (place)"
	},
	"H3761" : {
		"lemma" : "כַּרְמְלִי",
		"xlit" : "Karmᵉlîy",
		"pron" : "kar-mel-ee'",
		"derivation" : "patron from H3760 (כַּרְמֶל)",
		"def" : "a Karmelite or inhabitant of Karmel (the town)",
		"kjv" : "Carmelite"
	},
	"H3762" : {
		"lemma" : "כַּרְמְלִית",
		"xlit" : "Karmᵉlîyth",
		"pron" : "kar-mel-eeth'",
		"derivation" : "feminine of H3761 (כַּרְמְלִי)",
		"def" : "a Karmelitess or female inhabitant of Karmel",
		"kjv" : "Carmelitess"
	},
	"H3763" : {
		"lemma" : "כְּרָן",
		"xlit" : "Kᵉrân",
		"pron" : "ker-awn'",
		"derivation" : "of uncertain derivation",
		"def" : "Keran, an aboriginal Idumaean",
		"kjv" : "Cheran"
	},
	"H3764" : {
		"lemma" : "כׇּרְסֵא",
		"xlit" : "korçêʼ",
		"pron" : "kor-say'",
		"derivation" : "(Aramaic) corresponding to H3678 (כִּסֵּא)",
		"def" : "a throne",
		"kjv" : "throne"
	},
	"H3765" : {
		"lemma" : "כִּרְסֵם",
		"xlit" : "kirçêm",
		"pron" : "kir-same'",
		"derivation" : "from H3697 (כָּסַם)",
		"def" : "to lay waste",
		"kjv" : "waste"
	},
	"H3766" : {
		"lemma" : "כָּרַע",
		"xlit" : "kâraʻ",
		"pron" : "kaw-rah'",
		"derivation" : "a primitive root",
		"def" : "to bend the knee; by implication, to sink, to prostrate",
		"kjv" : "bow (down, self), bring down (low), cast down, couch, fall, feeble, kneeling, sink, smite (stoop) down, subdue, [idiom] very"
	},
	"H3767" : {
		"lemma" : "כָּרָע",
		"xlit" : "kârâʻ",
		"pron" : "kaw-raw'",
		"derivation" : "from H3766 (כָּרַע)",
		"def" : "the leg (from the knee to the ankle) of men or locusts (only in the dual)",
		"kjv" : "leg"
	},
	"H3768" : {
		"lemma" : "כַּרְפַּס",
		"xlit" : "karpaç",
		"pron" : "kar-pas'",
		"derivation" : "of foreign origin",
		"def" : "byssus or fine vegetable wool",
		"kjv" : "green"
	},
	"H3769" : {
		"lemma" : "כָּרַר",
		"xlit" : "kârar",
		"pron" : "kaw-rar'",
		"derivation" : "a primitive root",
		"def" : "to dance (i.e. whirl)",
		"kjv" : "dance(-ing)"
	},
	"H3770" : {
		"lemma" : "כְּרֵשׂ",
		"xlit" : "kᵉrês",
		"pron" : "ker-ace'",
		"derivation" : "by variation from H7164 (קָרַס)",
		"def" : "the paunch or belly (as swelling out)",
		"kjv" : "belly"
	},
	"H3771" : {
		"lemma" : "כַּרְשְׁנָא",
		"xlit" : "Karshᵉnâʼ",
		"pron" : "kar-shen-aw'",
		"derivation" : "of foreign origin",
		"def" : "Karshena, a courtier of Xerxes",
		"kjv" : "Carshena"
	},
	"H3772" : {
		"lemma" : "כָּרַת",
		"xlit" : "kârath",
		"pron" : "kaw-rath'",
		"derivation" : "a primitive root",
		"def" : "to cut (off, down or asunder); by implication, to destroy or consume; specifically, to covenant (i.e. make an alliance or bargain, originally by cutting flesh and passing between the pieces)",
		"kjv" : "be chewed, be con-(feder-) ate, covenant, cut (down, off), destroy, fail, feller, be freed, hew (down), make a league (covenant), [idiom] lose, perish, [idiom] utterly, [idiom] want"
	},
	"H3773" : {
		"lemma" : "כָּרֻתָה",
		"xlit" : "kâruthâh",
		"pron" : "kaw-rooth-aw'",
		"derivation" : "passive participle feminine of H3772 (כָּרַת)",
		"def" : "something cut, i.e. a hewn timber",
		"kjv" : "beam"
	},
	"H3774" : {
		"lemma" : "כְּרֵתִי",
		"xlit" : "Kᵉrêthîy",
		"pron" : "ker-ay-thee'",
		"derivation" : "probably from H3772 (כָּרַת) in the sense of executioner; (compare H2876 (טַבָּח)) (only collectively in the singular as plural)",
		"def" : "a Kerethite or life-guardsman",
		"kjv" : "Cherethims, Cherethites"
	},
	"H3775" : {
		"lemma" : "כֶּשֶׂב",
		"xlit" : "keseb",
		"pron" : "keh'-seb",
		"derivation" : "apparently by transposition for H3532 (כֶּבֶשׂ)",
		"def" : "a young sheep",
		"kjv" : "lamb"
	},
	"H3776" : {
		"lemma" : "כִּשְׂבָּה",
		"xlit" : "kisbâh",
		"pron" : "kis-baw'",
		"derivation" : "feminine of H3775 (כֶּשֶׂב)",
		"def" : "a young ewe",
		"kjv" : "lamb"
	},
	"H3777" : {
		"lemma" : "כֶּשֶׂד",
		"xlit" : "Kesed",
		"pron" : "keh'-sed",
		"derivation" : "from an unused root of uncertain meaning",
		"def" : "Kesed, a relative of Abraham",
		"kjv" : "Chesed"
	},
	"H3778" : {
		"lemma" : "כַּשְׂדִּי",
		"xlit" : "Kasdîy",
		"pron" : "kas-dee'",
		"derivation" : "(occasionally with enclitic) כַּשְׂדִּימָה; towards the Kasdites into Chaldea), patronymically from H3777 (כֶּשֶׂד) (only in the plural)",
		"def" : "a Kasdite, or descendant of Kesed; by implication, a Chaldaean (as if so descended); also an astrologer (as if proverbial of that people",
		"kjv" : "Chaldeans, Chaldees, inhabitants of Chaldea"
	},
	"H3779" : {
		"lemma" : "כַּשְׂדַּי",
		"xlit" : "Kasday",
		"pron" : "kas-dah'-ee",
		"derivation" : "(Aramaic) corresponding to H3778 (כַּשְׂדִּי)",
		"def" : "a Chaldaean or inhabitant of Chaldaea; by implication, a Magian or professional astrologer",
		"kjv" : "Chaldean"
	},
	"H3780" : {
		"lemma" : "כָּשָׂה",
		"xlit" : "kâsâh",
		"pron" : "kaw-saw'",
		"derivation" : "a primitive root",
		"def" : "to grow fat (i.e. be covered with flesh)",
		"kjv" : "be covered. Compare H3680 (כָּסָה)"
	},
	"H3781" : {
		"lemma" : "כַּשִּׁיל",
		"xlit" : "kashshîyl",
		"pron" : "kash-sheel'",
		"derivation" : "from H3782 (כָּשַׁל)",
		"def" : "properly, a feller, i.e. an axe",
		"kjv" : "ax"
	},
	"H3782" : {
		"lemma" : "כָּשַׁל",
		"xlit" : "kâshal",
		"pron" : "kaw-shal'",
		"derivation" : "a primitive root",
		"def" : "to totter or waver (through weakness of the legs, especially the ankle); by implication, to falter, stumble, faint or fall",
		"kjv" : "bereave (from the margin), cast down, be decayed, (cause to) fail, (cause, make to) fall (down, -ing), feeble, be (the) ruin(-ed, of), (be) overthrown, (cause to) stumble, [idiom] utterly, be weak"
	},
	"H3783" : {
		"lemma" : "כִּשָּׁלוֹן",
		"xlit" : "kishshâlôwn",
		"pron" : "kish-shaw-lone'",
		"derivation" : "from H3782 (כָּשַׁל)",
		"def" : "properly, a tottering, i.e. ruin",
		"kjv" : "fall"
	},
	"H3784" : {
		"lemma" : "כָּשַׁף",
		"xlit" : "kâshaph",
		"pron" : "kaw-shaf'",
		"derivation" : "a primitive root",
		"def" : "properly, to whisper aspell, i.e. to inchant or practise magic",
		"kjv" : "sorcerer, (use) witch(-craft)"
	},
	"H3785" : {
		"lemma" : "כֶּשֶׁף",
		"xlit" : "kesheph",
		"pron" : "keh'-shef",
		"derivation" : "from H3784 (כָּשַׁף)",
		"def" : "magic",
		"kjv" : "sorcery, witchcraft"
	},
	"H3786" : {
		"lemma" : "כַּשָּׁף",
		"xlit" : "kashshâph",
		"pron" : "kash-shawf'",
		"derivation" : "from H3784 (כָּשַׁף)",
		"def" : "a magician",
		"kjv" : "sorcerer"
	},
	"H3787" : {
		"lemma" : "כָּשֵׁר",
		"xlit" : "kâshêr",
		"pron" : "kaw-share'",
		"derivation" : "a primitive root properly, to be straight or right",
		"def" : "by implication, to be acceptable; also to succeed or prosper",
		"kjv" : "direct, be right, prosper"
	},
	"H3788" : {
		"lemma" : "כִּשְׁרוֹן",
		"xlit" : "kishrôwn",
		"pron" : "kish-rone'",
		"derivation" : "from H3787 (כָּשֵׁר)",
		"def" : "success, advantage",
		"kjv" : "equity, good, right"
	},
	"H3789" : {
		"lemma" : "כָּתַב",
		"xlit" : "kâthab",
		"pron" : "kaw-thab'",
		"derivation" : "a primitive root",
		"def" : "to grave, by implication, to write (describe, inscribe, prescribe, subscribe)",
		"kjv" : "describe, record, prescribe, subscribe, write(-ing, -ten)"
	},
	"H3790" : {
		"lemma" : "כְּתַב",
		"xlit" : "kᵉthab",
		"pron" : "keth-ab'",
		"derivation" : "(Aramaic) corresponding to H3789 (כָּתַב)",
		"def" : "{to grave, by implication, to write (describe, inscribe, prescribe, subscribe)}",
		"kjv" : "write(-ten)"
	},
	"H3791" : {
		"lemma" : "כָּתָב",
		"xlit" : "kâthâb",
		"pron" : "kaw-thawb'",
		"derivation" : "from H3789 (כָּתַב)",
		"def" : "something written, i.e. a writing, record or book",
		"kjv" : "register, scripture, writing"
	},
	"H3792" : {
		"lemma" : "כְּתָב",
		"xlit" : "kᵉthâb",
		"pron" : "keth-awb'",
		"derivation" : "(Aramaic) corresponding to H3791 (כָּתָב)",
		"def" : "{something written, i.e. a writing, record or book}",
		"kjv" : "prescribing, writing(-ten)"
	},
	"H3793" : {
		"lemma" : "כְּתֹבֶת",
		"xlit" : "kᵉthôbeth",
		"pron" : "keth-o'-beth",
		"derivation" : "from H3789 (כָּתַב)",
		"def" : "a letter or other mark branded on the skin",
		"kjv" : "[idiom] any (mark)"
	},
	"H3794" : {
		"lemma" : "כִּתִּי",
		"xlit" : "Kittîy",
		"pron" : "kit-tee'",
		"derivation" : "or כִּתִּיִּי; patrial from an unused name denoting Cyprus (only in the plural)",
		"def" : "a Kittite or Cypriote; hence, an islander in general, i.e. the Greeks or Romans on the shores opposite Palestine",
		"kjv" : "Chittim, Kittim"
	},
	"H3795" : {
		"lemma" : "כָּתִית",
		"xlit" : "kâthîyth",
		"pron" : "kaw-theeth'",
		"derivation" : "from H3807 (כָּתַת)",
		"def" : "beaten, i.e. pure (oil)",
		"kjv" : "beaten"
	},
	"H3796" : {
		"lemma" : "כֹּתֶל",
		"xlit" : "kôthel",
		"pron" : "ko'-thel",
		"derivation" : "from an unused root meaning to compact",
		"def" : "a wall (as gathering inmates)",
		"kjv" : "wall"
	},
	"H3797" : {
		"lemma" : "כְּתַל",
		"xlit" : "kᵉthal",
		"pron" : "keth-al'",
		"derivation" : "(Aramaic) corresponding to H3796 (כֹּתֶל)",
		"def" : "{a wall (as gathering inmates)}",
		"kjv" : "wall"
	},
	"H3798" : {
		"lemma" : "כִּתְלִישׁ",
		"xlit" : "Kithlîysh",
		"pron" : "kith-leesh'",
		"derivation" : "from H3796 (כֹּתֶל) and H376 (אִישׁ); wall of a man",
		"def" : "Kithlish, a place in Palestine",
		"kjv" : "Kithlish"
	},
	"H3799" : {
		"lemma" : "כָּתַם",
		"xlit" : "kâtham",
		"pron" : "kaw-tham'",
		"derivation" : "a primitive root",
		"def" : "properly, to carve or engrave, i.e. (by implication) to inscribe indelibly",
		"kjv" : "mark"
	},
	"H3800" : {
		"lemma" : "כֶּתֶם",
		"xlit" : "kethem",
		"pron" : "keh'-them",
		"derivation" : "from H3799 (כָּתַם)",
		"def" : "properly, something carved out, i.e. ore; hence, gold (pure as originally mined)",
		"kjv" : "((most) fine, pure) gold(-en wedge)"
	},
	"H3801" : {
		"lemma" : "כְּתֹנֶת",
		"xlit" : "kᵉthôneth",
		"pron" : "keth-o'-neth",
		"derivation" : "or כֻּתֹּנֶת; from an unused root meaning to cover (compare H3802 (כָּתֵף))",
		"def" : "a shirt",
		"kjv" : "coat, garment, robe"
	},
	"H3802" : {
		"lemma" : "כָּתֵף",
		"xlit" : "kâthêph",
		"pron" : "kaw-thafe'",
		"derivation" : "from an unused root meaning to clothe",
		"def" : "the shoulder (proper, i.e. upper end of the arm; as being the spot where the garments hang); figuratively, side-piece or lateral projection of anything",
		"kjv" : "arm, corner, shoulder(-piece), side, undersetter"
	},
	"H3803" : {
		"lemma" : "כָּתַר",
		"xlit" : "kâthar",
		"pron" : "kaw-thar'",
		"derivation" : "a primitive root",
		"def" : "to enclose; hence (in a friendly sense) to crown, (in a hostile one) to besiege; also to wait (as restraining oneself)",
		"kjv" : "beset round, compass about, be crowned inclose round, suffer"
	},
	"H3804" : {
		"lemma" : "כֶּתֶר",
		"xlit" : "kether",
		"pron" : "keh'-ther",
		"derivation" : "from H3803 (כָּתַר)",
		"def" : "properly, a circlet, i.e. a diadem",
		"kjv" : "crown"
	},
	"H3805" : {
		"lemma" : "כֹתֶרֶת",
		"xlit" : "kôthereth",
		"pron" : "ko-theh'-reth",
		"derivation" : "feminine active participle of H3803 (כָּתַר)",
		"def" : "the capital of a column",
		"kjv" : "chapiter"
	},
	"H3806" : {
		"lemma" : "כָּתַשׁ",
		"xlit" : "kâthash",
		"pron" : "kaw-thash'",
		"derivation" : "a primitive root",
		"def" : "to butt or pound",
		"kjv" : "bray"
	},
	"H3807" : {
		"lemma" : "כָּתַת",
		"xlit" : "kâthath",
		"pron" : "kaw-thath'",
		"derivation" : "a primitive root",
		"def" : "to bruise or violently strike",
		"kjv" : "beat (down, to pieces), break in pieces, crushed, destroy, discomfit, smite, stamp. l"
	},
	"H3808" : {
		"lemma" : "לֹא",
		"xlit" : "lôʼ",
		"pron" : "lo",
		"derivation" : "or לוֹא; or לֹה; (Deuteronomy 3:11), a primitive particle",
		"def" : "not (the simple or abs. negation); by implication, no; often used with other particles",
		"kjv" : "[idiom] before, [phrase] or else, ere, [phrase] except, ig(-norant), much, less, nay, neither, never, no((-ne), -r, (-thing)), ([idiom] as though...,(can-), for) not (out of), of nought, otherwise, out of, [phrase] surely, [phrase] as truly as, [phrase] of a truth, [phrase] verily, for want, [phrase] whether, without"
	},
	"H3809" : {
		"lemma" : "לָא",
		"xlit" : "lâʼ",
		"pron" : "law",
		"derivation" : "(Aramaic) or לָה; (Aramaic) (Daniel 4:32), corresponding to H3808 (לֹא)",
		"def" : "{not (the simple or abs. negation); by implication, no;}",
		"kjv" : "or even, neither, no(-ne, -r), (can-) not, as nothing, without"
	},
	"H3810" : {
		"lemma" : "לֹא דְבַר",
		"xlit" : "Lôʼ Dᵉbar",
		"pron" : "lo deb-ar'",
		"derivation" : "or לוֹ דבַר; (Samuel 9:4,5), or לִדְבִר; (Joshua 13:26), (probably rather לֹדְבַר ); from H3808 (לֹא) and H1699 (דֹּבֶר); pastureless",
		"def" : "Lo-Debar, a place in Palestine",
		"kjv" : "Debir, Lodebar"
	},
	"H3811" : {
		"lemma" : "לָאָה",
		"xlit" : "lâʼâh",
		"pron" : "law-aw'",
		"derivation" : "a primitive root",
		"def" : "to tire; (figuratively) to be (or make) disgusted",
		"kjv" : "faint, grieve, lothe, (be, make) weary (selves)"
	},
	"H3812" : {
		"lemma" : "לֵאָה",
		"xlit" : "Lêʼâh",
		"pron" : "lay-aw'",
		"derivation" : "from H3811 (לָאָה); weary",
		"def" : "Leah, a wife of Jacob",
		"kjv" : "Leah"
	},
	"H3813" : {
		"lemma" : "לָאַט",
		"xlit" : "lâʼaṭ",
		"pron" : "law-at'",
		"derivation" : "a primitive root",
		"def" : "to muffle",
		"kjv" : "cover"
	},
	"H3814" : {
		"lemma" : "לָאט",
		"xlit" : "lâʼṭ",
		"pron" : "lawt",
		"derivation" : "from H3813 (לָאַט) (or perhaps for active participle of H3874 (לוּט))",
		"def" : "properly, muffled, i.e. silently",
		"kjv" : "softly"
	},
	"H3815" : {
		"lemma" : "לָאֵל",
		"xlit" : "Lâʼêl",
		"pron" : "law-ale'",
		"derivation" : "from the prepositional prefix and H410 (אֵל); (belonging) to God",
		"def" : "Lael an Israelite",
		"kjv" : "Lael"
	},
	"H3816" : {
		"lemma" : "לְאֹם",
		"xlit" : "lᵉʼôm",
		"pron" : "leh-ome'",
		"derivation" : "or לְאוֹם; from an unused root meaning to gather",
		"def" : "a community",
		"kjv" : "nation, people"
	},
	"H3817" : {
		"lemma" : "לְאֻמִּים",
		"xlit" : "Lᵉʼummîym",
		"pron" : "leh-oom-meem'",
		"derivation" : "plural of H3816 (לְאֹם); communities",
		"def" : "Leum mim, an Arabian",
		"kjv" : "Leummim"
	},
	"H3818" : {
		"lemma" : "לֹא עַמִּי",
		"xlit" : "Lôʼ ʻAmmîy",
		"pron" : "lo am-mee'",
		"derivation" : "from H3808 (לֹא) and H5971 (עַם) with pronominal suffix; not my people",
		"def" : "Lo-Ammi, the symbolic name of a son of Hosea",
		"kjv" : "Lo-ammi"
	},
	"H3819" : {
		"lemma" : "לֹא רֻחָמָה",
		"xlit" : "Lôʼ Ruchâmâh",
		"pron" : "lo roo-khaw-maw'",
		"derivation" : "from H3808 (לֹא) and H7355 (רָחַם); not pitied",
		"def" : "Lo-Ruchamah, the symbolic name of a son of Hosea",
		"kjv" : "Lo-ruhamah"
	},
	"H3820" : {
		"lemma" : "לֵב",
		"xlit" : "lêb",
		"pron" : "labe",
		"derivation" : "a form of H3824 (לֵבָב)",
		"def" : "the heart; also used (figuratively) very widely for the feelings, the will and even the intellect; likewise for the centre of anything",
		"kjv" : "[phrase] care for, comfortably, consent, [idiom] considered, courag(-eous), friend(-ly), ((broken-), (hard-), (merry-), (stiff-), (stout-), double) heart(-ed), [idiom] heed, [idiom] I, kindly, midst, mind(-ed), [idiom] regard(-ed), [idiom] themselves, [idiom] unawares, understanding, [idiom] well, willingly, wisdom"
	},
	"H3821" : {
		"lemma" : "לֵב",
		"xlit" : "lêb",
		"pron" : "labe",
		"derivation" : "(Aramaic) corresponding to H3820 (לֵב)",
		"def" : "{the heart; also used (figuratively) very widely for the feelings, the will and even the intellect; likewise for the centre of anything}",
		"kjv" : "heart"
	},
	"H3822" : {
		"lemma" : "לְבָאוֹת",
		"xlit" : "Lᵉbâʼôwth",
		"pron" : "leb-aw-oth'",
		"derivation" : "plural of H3833 (לָבִיא); lionesses",
		"def" : "Lebaoth, a place in Palestine",
		"kjv" : "Lebaoth. See also H1034 (בֵּית לְבָאוֹת)"
	},
	"H3823" : {
		"lemma" : "לָבַב",
		"xlit" : "lâbab",
		"pron" : "law-bab'",
		"derivation" : "a primitive root; properly, to be enclosed (as if with fat); by implication (as denominative from H3824 (לֵבָב));  to unheart;  also as denominative from H3834 (לָבִיבָה) i.e",
		"def" : "(in a good sense) transport (with love), or (in a bad sense) stultify; to make cakes",
		"kjv" : "make cakes, ravish, be wise"
	},
	"H3824" : {
		"lemma" : "לֵבָב",
		"xlit" : "lêbâb",
		"pron" : "lay-bawb'",
		"derivation" : "from H3823 (לָבַב); used also like H3820 (לֵב)",
		"def" : "the heart (as the most interior organ)",
		"kjv" : "[phrase] bethink themselves, breast, comfortably, courage, ((faint), (tender-) heart(-ed), midst, mind, [idiom] unawares, understanding"
	},
	"H3825" : {
		"lemma" : "לְבַב",
		"xlit" : "lᵉbab",
		"pron" : "leb-ab'",
		"derivation" : "(Aramaic) corresponding to H3824 (לֵבָב)",
		"def" : "{the heart (as the most interior organ);}",
		"kjv" : "heart"
	},
	"H3826" : {
		"lemma" : "לִבָּה",
		"xlit" : "libbâh",
		"pron" : "lib-baw'",
		"derivation" : "feminine of H3820 (לֵב)",
		"def" : "the heart",
		"kjv" : "heart"
	},
	"H3827" : {
		"lemma" : "לַבָּה",
		"xlit" : "labbâh",
		"pron" : "lab-baw'",
		"derivation" : "for H3852 (לֶהָבָה)",
		"def" : "flame",
		"kjv" : "flame"
	},
	"H3828" : {
		"lemma" : "לְבוֹנָה",
		"xlit" : "lᵉbôwnâh",
		"pron" : "leb-o-naw'",
		"derivation" : "or לְבֹנָה; from H3836 (לָבָן)",
		"def" : "frankincense (from its whiteness or perhaps that of its smoke)",
		"kjv" : "(frank-) incense"
	},
	"H3829" : {
		"lemma" : "לְבוֹנָה",
		"xlit" : "Lᵉbôwnâh",
		"pron" : "leb-o-naw'",
		"derivation" : "the same as H3828 (לְבוֹנָה)",
		"def" : "Lebonah, a place in Palestine",
		"kjv" : "Lebonah"
	},
	"H3830" : {
		"lemma" : "לְבוּשׁ",
		"xlit" : "lᵉbûwsh",
		"pron" : "leb-oosh'",
		"derivation" : "or לְבֻשׁ; from H3847 (לָבַשׁ)",
		"def" : "a garment (literally or figuratively); by implication (euphemistically) a wife",
		"kjv" : "apparel, clothed with, clothing, garment, raiment, vestment, vesture"
	},
	"H3831" : {
		"lemma" : "לְבוּשׁ",
		"xlit" : "lᵉbûwsh",
		"pron" : "leb-oosh'",
		"derivation" : "(Aramaic) corresponding to H3830 (לְבוּשׁ)",
		"def" : "{a garment (literally or figuratively); by implication (euphemistically) a wife}",
		"kjv" : "garment"
	},
	"H3832" : {
		"lemma" : "לָבַט",
		"xlit" : "lâbaṭ",
		"pron" : "law-bat'",
		"derivation" : "a primitive root",
		"def" : "to overthrow; intransposed, to fall",
		"kjv" : "fall"
	},
	"H3833" : {
		"lemma" : "לָבִיא",
		"xlit" : "lâbîyʼ",
		"pron" : "law-bee'",
		"derivation" : "or (Ezekiel 19:2) לְבִיָּא; irregular masculine plural לְבָאִים; irregular feminine plural לְבָאוֹת; from an unused root meaning;  compare H738 (אֲרִי)",
		"def" : "to roar; a lion (properly, a lioness as the fiercer (although not a roarer;))",
		"kjv" : "(great, old, stout) lion, lioness, young (lion)"
	},
	"H3834" : {
		"lemma" : "לָבִיבָה",
		"xlit" : "lâbîybâh",
		"pron" : "law-bee-baw'",
		"derivation" : "or rather לְבִבָה; from H3823 (לָבַב) in its original sense of fatness (or perhaps of folding)",
		"def" : "a cake (either as fried or turned)",
		"kjv" : "cake"
	},
	"H3835" : {
		"lemma" : "לָבַן",
		"xlit" : "lâban",
		"pron" : "law-ban'",
		"derivation" : "a primitive root; also as denominative from H3843 (לְבֵנָה)",
		"def" : "to be (or become) white;  to make bricks",
		"kjv" : "make brick, be (made, make) white(-r)"
	},
	"H3836" : {
		"lemma" : "לָבָן",
		"xlit" : "lâbân",
		"pron" : "law-bawn'",
		"derivation" : "or (Genesis 49:12) לָבֵן; from H3835 (לָבַן)",
		"def" : "white",
		"kjv" : "white"
	},
	"H3837" : {
		"lemma" : "לָבָן",
		"xlit" : "Lâbân",
		"pron" : "law-bawn'",
		"derivation" : "the same as H3836 (לָבָן)",
		"def" : "Laban, a Mesopotamian; also a place in the Desert",
		"kjv" : "Laban"
	},
	"H3838" : {
		"lemma" : "לְבָנָא",
		"xlit" : "Lᵉbânâʼ",
		"pron" : "leb-aw-naw'",
		"derivation" : "or לְבָנָה; the same as H3842 (לְבָנָה)",
		"def" : "Lebana or Lebanah, one of the Nethinim",
		"kjv" : "Lebana, Lebanah"
	},
	"H3839" : {
		"lemma" : "לִבְנֶה",
		"xlit" : "libneh",
		"pron" : "lib-neh'",
		"derivation" : "from H3835 (לָבַן)",
		"def" : "some sort of whitish tree, perhaps the storax",
		"kjv" : "poplar"
	},
	"H3840" : {
		"lemma" : "לִבְנָה",
		"xlit" : "libnâh",
		"pron" : "lib-naw'",
		"derivation" : "from H3835 (לָבַן)",
		"def" : "properly, whiteness, i.e. (by implication) transparency",
		"kjv" : "paved"
	},
	"H3841" : {
		"lemma" : "לִבְנָה",
		"xlit" : "Libnâh",
		"pron" : "lib-naw'",
		"derivation" : "the same as H3839 (לִבְנֶה)",
		"def" : "Libnah, a place in the Desert and one in Palestine",
		"kjv" : "Libnah"
	},
	"H3842" : {
		"lemma" : "לְבָנָה",
		"xlit" : "lᵉbânâh",
		"pron" : "leb-aw-naw'",
		"derivation" : "from H3835 (לָבַן)",
		"def" : "properly, (the) white, i.e. the moon",
		"kjv" : "moon. See also H3838 (לְבָנָא)"
	},
	"H3843" : {
		"lemma" : "לְבֵנָה",
		"xlit" : "lᵉbênâh",
		"pron" : "leb-ay-naw'",
		"derivation" : "from H3835 (לָבַן)",
		"def" : "a brick (from the whiteness of the clay)",
		"kjv" : "(altar of) brick, tile"
	},
	"H3844" : {
		"lemma" : "לְבָנוֹן",
		"xlit" : "Lᵉbânôwn",
		"pron" : "leb-aw-nohn'",
		"derivation" : "from H3825 (לְבַב); (the) white mountain (from its snow)",
		"def" : "Lebanon, a mountain range in Palestine",
		"kjv" : "Lebanon"
	},
	"H3845" : {
		"lemma" : "לִבְנִי",
		"xlit" : "Libnîy",
		"pron" : "lib-nee'",
		"derivation" : "from H3835 (לָבַן); white",
		"def" : "Libni, an Israelite",
		"kjv" : "Libni"
	},
	"H3846" : {
		"lemma" : "לִבְנִי",
		"xlit" : "Libnîy",
		"pron" : "lib-nee'",
		"derivation" : "patronymically from H3845 (לִבְנִי)",
		"def" : "a Libnite or descendants of Libni (collectively)",
		"kjv" : "Libnites"
	},
	"H3847" : {
		"lemma" : "לָבַשׁ",
		"xlit" : "lâbash",
		"pron" : "law-bash'",
		"derivation" : "or לָבֵשׁ; a primitive root",
		"def" : "properly, wrap around, i.e. (by implication) to put on agarment or clothe (oneself, or another), literally or figuratively",
		"kjv" : "(in) apparel, arm, array (self), clothe (self), come upon, put (on, upon), wear"
	},
	"H3848" : {
		"lemma" : "לְבַשׁ",
		"xlit" : "lᵉbash",
		"pron" : "leb-ash'",
		"derivation" : "(Aramaic) corresponding to H3847 (לָבַשׁ)",
		"def" : "{properly, wrap around, i.e. (by implication) to put on agarment or clothe (oneself, or another), literally or figuratively}",
		"kjv" : "clothe"
	},
	"H3849" : {
		"lemma" : "לֹג",
		"xlit" : "lôg",
		"pron" : "lohg",
		"derivation" : "from an unused root apparently meaning to deepen or hollow (like H3537 (כַּד))",
		"def" : "a log or measure forliquids",
		"kjv" : "log (of oil)"
	},
	"H3850" : {
		"lemma" : "לֹד",
		"xlit" : "Lôd",
		"pron" : "lode",
		"derivation" : "from an unused root of uncertain signification",
		"def" : "Lod, a place in Palestine",
		"kjv" : "Lod"
	},
	"H3851" : {
		"lemma" : "לַהַב",
		"xlit" : "lahab",
		"pron" : "lah'-hab",
		"derivation" : "from an usused root meaning to gleam",
		"def" : "a flash; figuratively, a sharply polished blade or point of aweapon",
		"kjv" : "blade, bright, flame, glittering"
	},
	"H3852" : {
		"lemma" : "לֶהָבָה",
		"xlit" : "lehâbâh",
		"pron" : "leh-aw-baw'",
		"derivation" : "or לַהֶבֶת; feminine of H3851 (לַהַב), and meaning the same",
		"def" : "{a flash; figuratively, a sharply polished blade or point of aweapon}",
		"kjv" : "flame(-ming), head (of a spear)"
	},
	"H3853" : {
		"lemma" : "לְהָבִים",
		"xlit" : "Lᵉhâbîym",
		"pron" : "leh-haw-beem'",
		"derivation" : "plural of H3851 (לַהַב); flames",
		"def" : "Lehabim, a son of Mizraim, and his descendants",
		"kjv" : "Lehabim"
	},
	"H3854" : {
		"lemma" : "לַהַג",
		"xlit" : "lahag",
		"pron" : "lah'-hag",
		"derivation" : "from an unused root meaning to be eager",
		"def" : "intense mental application",
		"kjv" : "study"
	},
	"H3855" : {
		"lemma" : "לַהַד",
		"xlit" : "Lahad",
		"pron" : "lah'-had",
		"derivation" : "from an unused root meaning to glow (compare H3851 (לַהַב)) or else to be earnest (compare H3854 (לַהַג))",
		"def" : "Lahad, an Israelite",
		"kjv" : "Lahad"
	},
	"H3856" : {
		"lemma" : "לָהַהּ",
		"xlit" : "lâhahh",
		"pron" : "law-hah'",
		"derivation" : "a primitive root meaning properly, to burn, i.e. (by implication)",
		"def" : "to be rabid (figuratively, insane); also (from the exhaustion of frenzy) to languish",
		"kjv" : "faint, mad"
	},
	"H3857" : {
		"lemma" : "לָהַט",
		"xlit" : "lâhaṭ",
		"pron" : "law-hat'",
		"derivation" : "a primitive root",
		"def" : "properly, to lick, i.e. (by implication) to blaze",
		"kjv" : "burn (up), set on fire, flaming, kindle"
	},
	"H3858" : {
		"lemma" : "לַהַט",
		"xlit" : "lahaṭ",
		"pron" : "lah'-hat",
		"derivation" : "from H3857 (לָהַט)",
		"def" : "a blaze; also (from the idea of enwrapping) magic (as covert)",
		"kjv" : "flaming, enchantment"
	},
	"H3859" : {
		"lemma" : "לָהַם",
		"xlit" : "lâham",
		"pron" : "law-ham'",
		"derivation" : "a primitive root",
		"def" : "properly, to burn in, i.e. (figuratively) to rankle",
		"kjv" : "wound"
	},
	"H3860" : {
		"lemma" : "לָהֵן",
		"xlit" : "lâhên",
		"pron" : "law-hane'",
		"derivation" : "from the prepositional prefix meaning to or for and H2005 (הֵן)",
		"def" : "popularly for if; hence, therefore",
		"kjv" : "for them (by mistake for prepositional suffix)"
	},
	"H3861" : {
		"lemma" : "לָהֵן",
		"xlit" : "lâhên",
		"pron" : "law-hane'",
		"derivation" : "(Aramaic) corresponding to H3860 (לָהֵן)",
		"def" : "therefore; also except",
		"kjv" : "but, except, save, therefore, wherefore"
	},
	"H3862" : {
		"lemma" : "לַהֲקָה",
		"xlit" : "lahăqâh",
		"pron" : "lah-hak-aw'",
		"derivation" : "probably from an unused root meaning to gather",
		"def" : "an assembly",
		"kjv" : "company"
	},
	"H3863" : {
		"lemma" : "לוּא",
		"xlit" : "lûwʼ",
		"pron" : "loo",
		"derivation" : "or לֻא; or לוּ",
		"def" : "a conditional particle; if; by implication (interj. as a wish) would that!",
		"kjv" : "if (haply), peradventure, I pray thee, though, I would, would God (that)"
	},
	"H3864" : {
		"lemma" : "לוּבִי",
		"xlit" : "Lûwbîy",
		"pron" : "loo-bee'",
		"derivation" : "or לֻבִּי; (Daniel 11:43), partrial from a name probably derived from an unused root meaning to thirst, i.e. a dry region; apparently",
		"def" : "a Libyan or inhabitant of interior Africa (only in plural)",
		"kjv" : "Lubim(-s), Libyans"
	},
	"H3865" : {
		"lemma" : "לוּד",
		"xlit" : "Lûwd",
		"pron" : "lood",
		"derivation" : "probably of foreign derivation",
		"def" : "Lud, the name of two nations",
		"kjv" : "Lud, Lydia"
	},
	"H3866" : {
		"lemma" : "לוּדִי",
		"xlit" : "Lûwdîy",
		"pron" : "loo-dee'",
		"derivation" : "or לוּדִיִּי; patrial from H3865 (לוּד)",
		"def" : "a Ludite or inhabitants of Lud (only in plural)",
		"kjv" : "Ludim. Lydians"
	},
	"H3867" : {
		"lemma" : "לָוָה",
		"xlit" : "lâvâh",
		"pron" : "law-vaw'",
		"derivation" : "a primitive root",
		"def" : "properly, to twine, i.e. (by implication) to unite, to remain; also to borrow (as a form of obligation) or (caus.) to lend",
		"kjv" : "abide with, borrow(-er), cleave, join (self), lend(-er)"
	},
	"H3868" : {
		"lemma" : "לוּז",
		"xlit" : "lûwz",
		"pron" : "looz",
		"derivation" : "a primitive root; (compare H3867 (לָוָה), H3874 (לוּט) and H3885 (לוּן))",
		"def" : "to turn aside , i.e. (literally) to depart, (figuratively) be perverse",
		"kjv" : "depart, froward, perverse(-ness)"
	},
	"H3869" : {
		"lemma" : "לוּז",
		"xlit" : "lûwz",
		"pron" : "looz",
		"derivation" : "probably of foreign origin",
		"def" : "some kind of nuttree, perhaps the almond",
		"kjv" : "hazel"
	},
	"H3870" : {
		"lemma" : "לוּז",
		"xlit" : "Lûwz",
		"pron" : "looz",
		"derivation" : "probably from H3869 (לוּז) (as growing there)",
		"def" : "Luz, the name of two places in Palestine",
		"kjv" : "Luz"
	},
	"H3871" : {
		"lemma" : "לוּחַ",
		"xlit" : "lûwach",
		"pron" : "loo'-akh",
		"derivation" : "or לֻחַ; from a primitive root",
		"def" : "probably meaning to glisten; a tablet (as polished), of stone, wood or metal",
		"kjv" : "board, plate, table"
	},
	"H3872" : {
		"lemma" : "לוּחִית",
		"xlit" : "Lûwchîyth",
		"pron" : "loo-kheeth'",
		"derivation" : "or לֻחוֹת; (Jeremiah 48:5), from the same as H3871 (לוּחַ); floored",
		"def" : "Luchith, a place East of the Jordan",
		"kjv" : "Luhith"
	},
	"H3873" : {
		"lemma" : "לוֹחֵשׁ",
		"xlit" : "Lôwchêsh",
		"pron" : "lo-khashe'",
		"derivation" : "active participle of H3907 (לָחַשׁ); (the) enchanter",
		"def" : "Lochesh, an Israelite",
		"kjv" : "Hallohesh, Haloshesh (includ. the article)"
	},
	"H3874" : {
		"lemma" : "לוּט",
		"xlit" : "lûwṭ",
		"pron" : "loot",
		"derivation" : "a primitive root",
		"def" : "to wrap up",
		"kjv" : "cast, wrap"
	},
	"H3875" : {
		"lemma" : "לוֹט",
		"xlit" : "lôwṭ",
		"pron" : "lote",
		"derivation" : "from H3874 (לוּט)",
		"def" : "a veil",
		"kjv" : "covering"
	},
	"H3876" : {
		"lemma" : "לוֹט",
		"xlit" : "Lôwṭ",
		"pron" : "lote",
		"derivation" : "the same as H3875 (לוֹט)",
		"def" : "Lot, Abraham's nephew",
		"kjv" : "Lot"
	},
	"H3877" : {
		"lemma" : "לוֹטָן",
		"xlit" : "Lôwṭân",
		"pron" : "lo-tawn'",
		"derivation" : "from H3875 (לוֹט); covering",
		"def" : "Lotan, an Idumaean",
		"kjv" : "Lotan"
	},
	"H3878" : {
		"lemma" : "לֵוִי",
		"xlit" : "Lêvîy",
		"pron" : "lay-vee'",
		"derivation" : "from H3867 (לָוָה); attached",
		"def" : "Levi, a son of Jacob",
		"kjv" : "Levi. See also H3879 (לֵוִי), H3881 (לֵוִיִּי)"
	},
	"H3879" : {
		"lemma" : "לֵוִי",
		"xlit" : "Lêvîy",
		"pron" : "lay-vee'",
		"derivation" : "(Aramaic) corresponding to H3880 (לִוְיָה)",
		"def" : "{something attached, i.e. a wreath}",
		"kjv" : "Levite"
	},
	"H3880" : {
		"lemma" : "לִוְיָה",
		"xlit" : "livyâh",
		"pron" : "liv-yaw'",
		"derivation" : "from H3867 (לָוָה)",
		"def" : "something attached, i.e. a wreath",
		"kjv" : "ornament"
	},
	"H3881" : {
		"lemma" : "לֵוִיִּי",
		"xlit" : "Lêvîyîy",
		"pron" : "lay-vee-ee'",
		"derivation" : "or לֵוִי; patronymically from H3878 (לֵוִי)",
		"def" : "a Levite or descendant of Levi",
		"kjv" : "Leviite"
	},
	"H3882" : {
		"lemma" : "לִוְיָתָן",
		"xlit" : "livyâthân",
		"pron" : "liv-yaw-thawn'",
		"derivation" : "from H3867 (לָוָה)",
		"def" : "a wreathed animal, i.e. a serpent (especially the crocodile or some other large sea-monster); figuratively, the constellation of the dragon; also as a symbol of Babylon",
		"kjv" : "leviathan, mourning"
	},
	"H3883" : {
		"lemma" : "לוּל",
		"xlit" : "lûwl",
		"pron" : "lool",
		"derivation" : "from an unused root meaning to fold back",
		"def" : "a spiral step",
		"kjv" : "winding stair. Compare H3924 (לֻלָאָה)"
	},
	"H3884" : {
		"lemma" : "לוּלֵא",
		"xlit" : "lûwlêʼ",
		"pron" : "loo-lay'",
		"derivation" : "or לוּלֵי; from H3863 (לוּא) and H3808 (לֹא)",
		"def" : "if not",
		"kjv" : "except, had not, if (...not), unless, were it not that"
	},
	"H3885" : {
		"lemma" : "לוּן",
		"xlit" : "lûwn",
		"pron" : "loon",
		"derivation" : "or לִין; a primitive root",
		"def" : "to stop (usually over night); by implication, to stay permanently; hence (in a bad sense) to be obstinate (especially in words, to complain)",
		"kjv" : "abide (all night), continue, dwell, endure, grudge, be left, lie all night, (cause to) lodge (all night, in, -ing, this night), (make to) murmur, remain, tarry (all night, that night)"
	},
	"H3886" : {
		"lemma" : "לוּעַ",
		"xlit" : "lûwaʻ",
		"pron" : "loo'-ah",
		"derivation" : "a primitive root",
		"def" : "to gulp; figuratively, to be rash",
		"kjv" : "swallow down (up)"
	},
	"H3887" : {
		"lemma" : "לוּץ",
		"xlit" : "lûwts",
		"pron" : "loots",
		"derivation" : "a primitive root",
		"def" : "properly, to make mouths at, i.e. to scoff; hence (from the effort to pronounce a foreign language) to interpret, or (generally) intercede",
		"kjv" : "ambassador, have in derision, interpreter, make a mock, mocker, scorn(-er, -ful), teacher"
	},
	"H3888" : {
		"lemma" : "לוּשׁ",
		"xlit" : "lûwsh",
		"pron" : "loosh",
		"derivation" : "a primitive root",
		"def" : "to knead",
		"kjv" : "knead"
	},
	"H3889" : {
		"lemma" : "לוּשׁ",
		"xlit" : "Lûwsh",
		"pron" : "loosh",
		"derivation" : "from H3888 (לוּשׁ); kneading",
		"def" : "Lush, a place in Palestine",
		"kjv" : "Laish (from the margin). Compare H3919 (לַיִשׁ)"
	},
	"H3890" : {
		"lemma" : "לְוָת",
		"xlit" : "lᵉvâth",
		"pron" : "lev-awth'",
		"derivation" : "(Aramaic) from a root corresponding to H3867 (לָוָה)",
		"def" : "properly, adhesion, i.e. (as preposition) with",
		"kjv" : "[idiom] thee"
	},
	"H3891" : {
		"lemma" : "לְזוּת",
		"xlit" : "lᵉzûwth",
		"pron" : "lez-ooth'",
		"derivation" : "from H3868 (לוּז)",
		"def" : "perverseness",
		"kjv" : "perverse"
	},
	"H3892" : {
		"lemma" : "לַח",
		"xlit" : "lach",
		"pron" : "lakh",
		"derivation" : "from an unused root meaning to be new",
		"def" : "fresh, i.e. unused or undried",
		"kjv" : "green, moist"
	},
	"H3893" : {
		"lemma" : "לֵחַ",
		"xlit" : "lêach",
		"pron" : "lay'-akh",
		"derivation" : "from the same as H3892 (לַח)",
		"def" : "freshness, i.e. vigor",
		"kjv" : "natural force"
	},
	"H3894" : {
		"lemma" : "לָחוּם",
		"xlit" : "lâchûwm",
		"pron" : "law-khoom'",
		"derivation" : "or לָחֻם; passive participle of H3898 (לָחַם)",
		"def" : "properly, eaten, i.e. food; also flesh, i.e. body",
		"kjv" : "while...is eating, flesh"
	},
	"H3895" : {
		"lemma" : "לְחִי",
		"xlit" : "lᵉchîy",
		"pron" : "lekh-ee'",
		"derivation" : "from an unused root meaning to be soft",
		"def" : "the cheek (from its fleshiness); hence, the jaw-bone",
		"kjv" : "cheek (bone), jaw (bone)"
	},
	"H3896" : {
		"lemma" : "לֶחִי",
		"xlit" : "Lechîy",
		"pron" : "lekh'-ee",
		"derivation" : "a form of H3895 (לְחִי)",
		"def" : "Lechi, a place in Palestine",
		"kjv" : "Lehi. Compare also H7437 (רָמַת לֶחִי)"
	},
	"H3897" : {
		"lemma" : "לָחַךְ",
		"xlit" : "lâchak",
		"pron" : "law-khak'",
		"derivation" : "a primitive root",
		"def" : "to lick",
		"kjv" : "lick (up)"
	},
	"H3898" : {
		"lemma" : "לָחַם",
		"xlit" : "lâcham",
		"pron" : "law-kham'",
		"derivation" : "a primitive root",
		"def" : "to feed on; figuratively, to consume; by implication, to battle (as destruction)",
		"kjv" : "devour, eat, [idiom] ever, fight(-ing), overcome, prevail, (make) war(-ring)"
	},
	"H3899" : {
		"lemma" : "לֶחֶם",
		"xlit" : "lechem",
		"pron" : "lekh'-em",
		"derivation" : "from H3898 (לָחַם); See also H1036 (בֵּית לְעַפְרָה)",
		"def" : "food (for man or beast), especially bread, or grain (for making it)",
		"kjv" : "(shew-) bread, [idiom] eat, food, fruit, loaf, meat, victuals"
	},
	"H3900" : {
		"lemma" : "לְחֶם",
		"xlit" : "lᵉchem",
		"pron" : "lekh-em'",
		"derivation" : "(Aramaic) corresponding to H3899 (לֶחֶם)",
		"def" : "{(for man or beast), especially bread, or grain (for making it)}",
		"kjv" : "feast"
	},
	"H3901" : {
		"lemma" : "לָחֶם",
		"xlit" : "lâchem",
		"pron" : "law-khem'",
		"derivation" : "from H3898 (לָחַם)",
		"def" : "battle",
		"kjv" : "war"
	},
	"H3902" : {
		"lemma" : "לַחְמִי",
		"xlit" : "Lachmîy",
		"pron" : "lakh-mee'",
		"derivation" : "from H3899 (לֶחֶם); foodful; (or perhaps erroneous transcription) for H1022 (בֵּית הַלַּחְמִי)",
		"def" : "Lachmi, an Israelite; or rather probably a brief form",
		"kjv" : "Lahmi. See also H3433 (יָשֻׁבִי לֶחֶם)"
	},
	"H3903" : {
		"lemma" : "לַחְמָס",
		"xlit" : "Lachmâç",
		"pron" : "lakh-maws'",
		"derivation" : "probably by erroneous transcription for לַחְמָם; from H3899 (לֶחֶם); food-like",
		"def" : "Lachmam or Lachmas, a place in Palestine",
		"kjv" : "Lahmam"
	},
	"H3904" : {
		"lemma" : "לְחֵנָה",
		"xlit" : "lᵉchênâh",
		"pron" : "lekh-ay-naw'",
		"derivation" : "(Aramaic) from an unused root of uncertain meaning",
		"def" : "a concubine",
		"kjv" : "concubine"
	},
	"H3905" : {
		"lemma" : "לָחַץ",
		"xlit" : "lâchats",
		"pron" : "law-khats'",
		"derivation" : "a primitive root",
		"def" : "properly, to press, i.e. (figuratively) to distress",
		"kjv" : "afflict, crush, force, hold fast, oppress(-or), thrust self"
	},
	"H3906" : {
		"lemma" : "לַחַץ",
		"xlit" : "lachats",
		"pron" : "lakh'-ats",
		"derivation" : "from H3905 (לָחַץ)",
		"def" : "distress",
		"kjv" : "affliction, oppression"
	},
	"H3907" : {
		"lemma" : "לָחַשׁ",
		"xlit" : "lâchash",
		"pron" : "law-khash'",
		"derivation" : "a primitive root",
		"def" : "to whisper; by implication, to mumble aspell (as a magician)",
		"kjv" : "charmer, whisper (together)"
	},
	"H3908" : {
		"lemma" : "לַחַשׁ",
		"xlit" : "lachash",
		"pron" : "lakh'-ash",
		"derivation" : "from H3907 (לָחַשׁ)",
		"def" : "properly, a whisper, i.e. by implication, (in a good sense) a private prayer, (in a bad one) an incantation; concretely, an amulet",
		"kjv" : "charmed, earring, enchantment, orator, prayer"
	},
	"H3909" : {
		"lemma" : "לָט",
		"xlit" : "lâṭ",
		"pron" : "lawt",
		"derivation" : "a form of H3814 (לָאט) or else participle from H3874 (לוּט)",
		"def" : "properly, covered, i.e. secret; by implication, incantation; also secrecy or (adverb) covertly",
		"kjv" : "enchantment, privily, secretly, softly"
	},
	"H3910" : {
		"lemma" : "לֹט",
		"xlit" : "lôṭ",
		"pron" : "lote",
		"derivation" : "probably from H3874 (לוּט)",
		"def" : "a gum (from its sticky nature), probably ladanum",
		"kjv" : "myrrh"
	},
	"H3911" : {
		"lemma" : "לְטָאָה",
		"xlit" : "lᵉṭâʼâh",
		"pron" : "let-aw-aw'",
		"derivation" : "from an unused root meaning to hide",
		"def" : "a kind of lizard (from its covert habits)",
		"kjv" : "lizard"
	},
	"H3912" : {
		"lemma" : "לְטוּשִׁם",
		"xlit" : "Lᵉṭûwshim",
		"pron" : "let-oo-sheem'",
		"derivation" : "masculine plural of passive participle of H3913 (לָטַשׁ); hammered (i.e. oppressed) ones",
		"def" : "Letushim, an Arabian tribe",
		"kjv" : "Letushim"
	},
	"H3913" : {
		"lemma" : "לָטַשׁ",
		"xlit" : "lâṭash",
		"pron" : "law-tash'",
		"derivation" : "a primitive root",
		"def" : "properly, to hammer out (an edge), i.e. to sharpen",
		"kjv" : "instructer, sharp(-en), whet"
	},
	"H3914" : {
		"lemma" : "לֹיָה",
		"xlit" : "lôyâh",
		"pron" : "lo-yaw'",
		"derivation" : "a form of H3880 (לִוְיָה)",
		"def" : "a wreath",
		"kjv" : "addition"
	},
	"H3915" : {
		"lemma" : "לַיִל",
		"xlit" : "layil",
		"pron" : "lah'-yil",
		"derivation" : "or (Isaiah 21:11) לֵיל; also לַיְלָה; from the same as H3883 (לוּל)",
		"def" : "properly, a twist (away of the light), i.e. night; figuratively, adversity",
		"kjv" : "(mid-)night (season)"
	},
	"H3916" : {
		"lemma" : "לֵילְיָא",
		"xlit" : "lêylᵉyâʼ",
		"pron" : "lay-leh-yaw'",
		"derivation" : "(Aramaic) corresponding to H3815 (לָאֵל)",
		"def" : "{Lael an Israelite}",
		"kjv" : "night"
	},
	"H3917" : {
		"lemma" : "לִילִית",
		"xlit" : "lîylîyth",
		"pron" : "lee-leeth'",
		"derivation" : "from H3915 (לַיִל)",
		"def" : "a night spectre",
		"kjv" : "screech owl"
	},
	"H3918" : {
		"lemma" : "לַיִשׁ",
		"xlit" : "layish",
		"pron" : "lah'-yish",
		"derivation" : "from H3888 (לוּשׁ) in the sense of crushing",
		"def" : "a lion (from his destructive blows)",
		"kjv" : "(old) lion"
	},
	"H3919" : {
		"lemma" : "לַיִשׁ",
		"xlit" : "Layish",
		"pron" : "lah'-yish",
		"derivation" : "the same as H3918 (לַיִשׁ)",
		"def" : "Laish, the name of two places in Palestine",
		"kjv" : "Laish. Compare H3889 (לוּשׁ)"
	},
	"H3920" : {
		"lemma" : "לָכַד",
		"xlit" : "lâkad",
		"pron" : "law-kad'",
		"derivation" : "a primitive root",
		"def" : "to catch (in a net, trap or pit); generally, to capture or occupy; also to choose (by lot); figuratively, to cohere",
		"kjv" : "[idiom] at all, catch (self), be frozen, be holden, stick together, take"
	},
	"H3921" : {
		"lemma" : "לֶכֶד",
		"xlit" : "leked",
		"pron" : "leh'ked",
		"derivation" : "from H3920 (לָכַד)",
		"def" : "something to capture with, i.e. a noose",
		"kjv" : "being taken"
	},
	"H3922" : {
		"lemma" : "לֵכָה",
		"xlit" : "lêkâh",
		"pron" : "lay-kaw'",
		"derivation" : "from H3212 (יָלַךְ); a journey",
		"def" : "Lekah, a place in Palestine",
		"kjv" : "Lecah"
	},
	"H3923" : {
		"lemma" : "לָכִישׁ",
		"xlit" : "Lâkîysh",
		"pron" : "law-keesh'",
		"derivation" : "from an unused root of uncertain meaning",
		"def" : "Lakish, a place in Palestine",
		"kjv" : "Lachish"
	},
	"H3924" : {
		"lemma" : "לֻלָאָה",
		"xlit" : "lulâʼâh",
		"pron" : "loo-law-aw'",
		"derivation" : "from the same as H3883 (לוּל)",
		"def" : "a loop",
		"kjv" : "loop"
	},
	"H3925" : {
		"lemma" : "לָמַד",
		"xlit" : "lâmad",
		"pron" : "law-mad'",
		"derivation" : "a primitive root",
		"def" : "properly, to goad, i.e. (by implication) to teach (the rod being an Oriental incentive)",
		"kjv" : "(un-) accustomed, [idiom] diligently, expert, instruct, learn, skilful, teach(-er, -ing)"
	},
	"H3926" : {
		"lemma" : "לְמוֹ",
		"xlit" : "lᵉmôw",
		"pron" : "lem-o'",
		"derivation" : "a prolonged and separable form of the prepositional prefix",
		"def" : "to or for",
		"kjv" : "at, for, to, upon"
	},
	"H3927" : {
		"lemma" : "לְמוּאֵל",
		"xlit" : "Lᵉmûwʼêl",
		"pron" : "lem-oo-ale'",
		"derivation" : "or לְמוֹאֵל; from H3926 (לְמוֹ) and H410 (אֵל); (belonging) to God",
		"def" : "Lemuel or Lemoel, a symbolic name of Solomon",
		"kjv" : "Lemuel"
	},
	"H3928" : {
		"lemma" : "לִמּוּד",
		"xlit" : "limmûwd",
		"pron" : "lim-mood'",
		"derivation" : "or לִמֻּד; from H3925 (לָמַד)",
		"def" : "instructed",
		"kjv" : "accustomed, disciple, learned, taught, used"
	},
	"H3929" : {
		"lemma" : "לֶמֶךְ",
		"xlit" : "Lemek",
		"pron" : "leh'-mek",
		"derivation" : "from an unused root of uncertain meaning",
		"def" : "Lemek, the name of two antediluvian patriarchs",
		"kjv" : "Lamech"
	},
	"H3930" : {
		"lemma" : "לֹעַ",
		"xlit" : "lôaʻ",
		"pron" : "lo'ah",
		"derivation" : "from H3886 (לוּעַ)",
		"def" : "the gullet",
		"kjv" : "throat"
	},
	"H3931" : {
		"lemma" : "לָעַב",
		"xlit" : "lâʻab",
		"pron" : "law-ab'",
		"derivation" : "a primitive root",
		"def" : "to deride",
		"kjv" : "mock"
	},
	"H3932" : {
		"lemma" : "לָעַג",
		"xlit" : "lâʻag",
		"pron" : "law-ag'",
		"derivation" : "a primitive root",
		"def" : "to deride; by implication (as if imitating a foreigner) to speak unintelligibly",
		"kjv" : "have in derision, laugh (to scorn), mock (on), stammering"
	},
	"H3933" : {
		"lemma" : "לַעַג",
		"xlit" : "laʻag",
		"pron" : "lah'-ag",
		"derivation" : "from H3932 (לָעַג)",
		"def" : "derision, scoffing",
		"kjv" : "derision, scorn (-ing)"
	},
	"H3934" : {
		"lemma" : "לָעֵג",
		"xlit" : "lâʻêg",
		"pron" : "law-ayg'",
		"derivation" : "from H3932 (לָעַג)",
		"def" : "a buffoon; also a foreigner",
		"kjv" : "mocker, stammering"
	},
	"H3935" : {
		"lemma" : "לַעְדָּה",
		"xlit" : "Laʻdâh",
		"pron" : "lah-daw'",
		"derivation" : "from an unused root of uncertain meaning",
		"def" : "Ladah, an Israelite",
		"kjv" : "Laadah"
	},
	"H3936" : {
		"lemma" : "לַעְדָּן",
		"xlit" : "Laʻdân",
		"pron" : "lah-dawn'",
		"derivation" : "from the same as H3935 (לַעְדָּה)",
		"def" : "Ladan, the name of two Israelites",
		"kjv" : "Laadan"
	},
	"H3937" : {
		"lemma" : "לָעַז",
		"xlit" : "lâʻaz",
		"pron" : "law-az'",
		"derivation" : "a primitive root",
		"def" : "to speak in a foreign tongue",
		"kjv" : "strange language"
	},
	"H3938" : {
		"lemma" : "לָעַט",
		"xlit" : "lâʻaṭ",
		"pron" : "law-at'",
		"derivation" : "a primitive root",
		"def" : "to swallow greedily; causatively, to feed",
		"kjv" : "feed"
	},
	"H3939" : {
		"lemma" : "לַעֲנָה",
		"xlit" : "laʻănâh",
		"pron" : "lah-an-aw'",
		"derivation" : "from an unused root supposed to mean to curse",
		"def" : "wormwood (regarded as poisonous, and therefore accursed)",
		"kjv" : "hemlock, wormwood"
	},
	"H3940" : {
		"lemma" : "לַפִּיד",
		"xlit" : "lappîyd",
		"pron" : "lap-peed'",
		"derivation" : "or לַפִּד; from an unused root probably meaning to shine",
		"def" : "a flambeau, lamp or flame",
		"kjv" : "(fire-) brand, (burning) lamp, lightning, torch"
	},
	"H3941" : {
		"lemma" : "לַפִּידוֹת",
		"xlit" : "Lappîydôwth",
		"pron" : "lap-pee-doth'",
		"derivation" : "feminine plural of H3940 (לַפִּיד)",
		"def" : "Lappidoth, the husband of Deborah",
		"kjv" : "Lappidoth"
	},
	"H3942" : {
		"lemma" : "לִפְנַי",
		"xlit" : "liphnay",
		"pron" : "lif-nah'ee",
		"derivation" : "from the prepositional prefix (to or for) and H6440 (פָּנִים)",
		"def" : "anterior",
		"kjv" : "before"
	},
	"H3943" : {
		"lemma" : "לָפַת",
		"xlit" : "lâphath",
		"pron" : "law-fath'",
		"derivation" : "a primitive root",
		"def" : "properly, to bend, i.e. (by implication) to clasp; also (reflexively) to turn around or aside",
		"kjv" : "take hold, turn aside (self)"
	},
	"H3944" : {
		"lemma" : "לָצוֹן",
		"xlit" : "lâtsôwn",
		"pron" : "law-tsone'",
		"derivation" : "from H3887 (לוּץ)",
		"def" : "derision",
		"kjv" : "scornful(-ning)"
	},
	"H3945" : {
		"lemma" : "לָצַץ",
		"xlit" : "lâtsats",
		"pron" : "law-tsats'",
		"derivation" : "a primitive root",
		"def" : "to deride",
		"kjv" : "scorn"
	},
	"H3946" : {
		"lemma" : "לַקּוּם",
		"xlit" : "Laqqûwm",
		"pron" : "lak-koom'",
		"derivation" : "from an unused root thought to mean to stop up by a barricade; perhaps fortification",
		"def" : "Lakkum, a place in Palestine",
		"kjv" : "Lakum"
	},
	"H3947" : {
		"lemma" : "לָקַח",
		"xlit" : "lâqach",
		"pron" : "law-kakh'",
		"derivation" : "a primitive root",
		"def" : "to take (in the widest variety of applications)",
		"kjv" : "accept, bring, buy, carry away, drawn, fetch, get, infold, [idiom] many, mingle, place, receive(-ing), reserve, seize, send for, take (away, -ing, up), use, win"
	},
	"H3948" : {
		"lemma" : "לֶקַח",
		"xlit" : "leqach",
		"pron" : "leh'-kakh",
		"derivation" : "from H3947 (לָקַח)",
		"def" : "properly, something received, i.e. (mentally) instruction (whether on the part of the teacher or hearer); also (in an active and sinister sense) inveiglement",
		"kjv" : "doctrine, learning, fair speech"
	},
	"H3949" : {
		"lemma" : "לִקְחִי",
		"xlit" : "Liqchîy",
		"pron" : "lik-khee'",
		"derivation" : "from H3947 (לָקַח); learned",
		"def" : "Likchi, an Israelite",
		"kjv" : "Likhi"
	},
	"H3950" : {
		"lemma" : "לָקַט",
		"xlit" : "lâqaṭ",
		"pron" : "law-kat'",
		"derivation" : "a primitive root",
		"def" : "properly, to pick up, i.e. (generally) to gather; specifically, to glean",
		"kjv" : "gather (up), glean"
	},
	"H3951" : {
		"lemma" : "לֶקֶט",
		"xlit" : "leqeṭ",
		"pron" : "leh'-ket",
		"derivation" : "from H3950 (לָקַט)",
		"def" : "the gleaning",
		"kjv" : "gleaning"
	},
	"H3952" : {
		"lemma" : "לָקַק",
		"xlit" : "lâqaq",
		"pron" : "law-kak'",
		"derivation" : "a primitive root",
		"def" : "to lick or lap",
		"kjv" : "lap, lick"
	},
	"H3953" : {
		"lemma" : "לָקַשׁ",
		"xlit" : "lâqash",
		"pron" : "law-kash'",
		"derivation" : "a primitive root",
		"def" : "to gather the after crop",
		"kjv" : "gather"
	},
	"H3954" : {
		"lemma" : "לֶקֶשׁ",
		"xlit" : "leqesh",
		"pron" : "leh'-kesh",
		"derivation" : "from H3953 (לָקַשׁ)",
		"def" : "the after crop",
		"kjv" : "latter growth"
	},
	"H3955" : {
		"lemma" : "לְשַׁד",
		"xlit" : "lᵉshad",
		"pron" : "lesh-ad'",
		"derivation" : "from an unused root of uncertain meaning; apparently juice, i.e",
		"def" : "(figuratively) vigor; also a sweet or fat cake",
		"kjv" : "fresh, moisture"
	},
	"H3956" : {
		"lemma" : "לָשׁוֹן",
		"xlit" : "lâshôwn",
		"pron" : "law-shone'",
		"derivation" : "or לָשֹׁן; also (in plural) feminine לְשֹׁנָה; from H3960 (לָשַׁן)",
		"def" : "the tongue (of man or animals), used literally (as the instrument of licking, eating, or speech), and figuratively (speech, an ingot, a fork of flame, a cove of water)",
		"kjv" : "[phrase] babbler, bay, [phrase] evil speaker, language, talker, tongue, wedge"
	},
	"H3957" : {
		"lemma" : "לִשְׁכָּה",
		"xlit" : "lishkâh",
		"pron" : "lish-kaw'",
		"derivation" : "from an unused root of uncertain meaning",
		"def" : "a room in a building (whether for storage, eating, or lodging)",
		"kjv" : "chamber, parlour. Compare H5393 (נִשְׁכָּה)"
	},
	"H3958" : {
		"lemma" : "לֶשֶׁם",
		"xlit" : "leshem",
		"pron" : "leh'-shem",
		"derivation" : "from an unused root of uncertain meaning",
		"def" : "a gem, perhaps the jacinth",
		"kjv" : "ligure"
	},
	"H3959" : {
		"lemma" : "לֶשֶׁם",
		"xlit" : "Leshem",
		"pron" : "leh'-shem",
		"derivation" : "the same as H3958 (לֶשֶׁם)",
		"def" : "Leshem, a place in Palestine",
		"kjv" : "Leshem"
	},
	"H3960" : {
		"lemma" : "לָשַׁן",
		"xlit" : "lâshan",
		"pron" : "law-shan'",
		"derivation" : "a primitive root; properly, to lick; but used only as a denominative from H3956 (לָשׁוֹן)",
		"def" : "to wag the tongue, i.e. to calumniate",
		"kjv" : "accuse, slander"
	},
	"H3961" : {
		"lemma" : "לִשָּׁן",
		"xlit" : "lishshân",
		"pron" : "lish-shawn'",
		"derivation" : "(Aramaic) corresponding to H3956 (לָשׁוֹן)",
		"def" : "speech, i.e. a nation",
		"kjv" : "language"
	},
	"H3962" : {
		"lemma" : "לֶשַׁע",
		"xlit" : "Leshaʻ",
		"pron" : "leh'-shah",
		"derivation" : "from an unused root thought to mean to break through; a boiling spring",
		"def" : "Lesha, a place probably East of the Jordan",
		"kjv" : "Lasha"
	},
	"H3963" : {
		"lemma" : "לֶתֶךְ",
		"xlit" : "lethek",
		"pron" : "leh'-thek",
		"derivation" : "from an unused root of uncertain meaning",
		"def" : "a measure for things dry",
		"kjv" : "half homer. m"
	},
	"H3964" : {
		"lemma" : "מָא",
		"xlit" : "mâʼ",
		"pron" : "maw",
		"derivation" : "(Aramaic) corresponding to H4100 (מָה)",
		"def" : "(as indefinitely) that",
		"kjv" : "[phrase] what"
	},
	"H3965" : {
		"lemma" : "מַאֲבוּס",
		"xlit" : "maʼăbûwç",
		"pron" : "mah-ab-ooce'",
		"derivation" : "from H75 (אָבַס)",
		"def" : "a granary",
		"kjv" : "storehouse"
	},
	"H3966" : {
		"lemma" : "מְאֹד",
		"xlit" : "mᵉʼôd",
		"pron" : "meh-ode'",
		"derivation" : "from the same as H181 (אוּד)",
		"def" : "properly, vehemence, i.e. (with or without preposition) vehemently; by implication, wholly, speedily, etc. (often with other words as an intensive or superlative; especially when repeated)",
		"kjv" : "diligently, especially, exceeding(-ly), far, fast, good, great(-ly), [idiom] louder and louder, might(-ily, -y), (so) much, quickly, (so) sore, utterly, very ([phrase] much, sore), well"
	},
	"H3967" : {
		"lemma" : "מֵאָה",
		"xlit" : "mêʼâh",
		"pron" : "may-aw'",
		"derivation" : "or מֵאיָה; properly, a primitive numeral",
		"def" : "a hundred; also as a multiplicative and a fraction",
		"kjv" : "hundred((-fold), -th), [phrase] sixscore"
	},
	"H3968" : {
		"lemma" : "מֵאָה",
		"xlit" : "Mêʼâh",
		"pron" : "may-aw'",
		"derivation" : "the same as H3967 (מֵאָה)",
		"def" : "Meah, a tower in Jerusalem",
		"kjv" : "Meah"
	},
	"H3969" : {
		"lemma" : "מְאָה",
		"xlit" : "mᵉʼâh",
		"pron" : "meh-aw'",
		"derivation" : "(Aramaic) corresponding to H3967 (מֵאָה); properly, a primitive numeral",
		"def" : "a hundred; also as a multiplicative and a fraction}",
		"kjv" : "hundred"
	},
	"H3970" : {
		"lemma" : "מַאֲוַי",
		"xlit" : "maʼăvay",
		"pron" : "mah-av-ah'ee",
		"derivation" : "from H183 (אָוָה)",
		"def" : "a desire",
		"kjv" : "desire"
	},
	"H3971" : {
		"lemma" : "מאוּם",
		"xlit" : "mʼûwm",
		"pron" : "moom",
		"derivation" : "usually מוּם; as if passive participle from an unused root probably meaning",
		"def" : "to stain; a blemish (physically or morally)",
		"kjv" : "blemish, blot, spot"
	},
	"H3972" : {
		"lemma" : "מְאוּמָה",
		"xlit" : "mᵉʼûwmâh",
		"pron" : "meh-oo'-maw",
		"derivation" : "apparently a form of H3971 (מאוּם)",
		"def" : "properly, a speck or point, i.e. (by implication) something; with negative, nothing",
		"kjv" : "fault, [phrase] no(-ught), ought, somewhat, any (no-)thing"
	},
	"H3973" : {
		"lemma" : "מָאוֹס",
		"xlit" : "mâʼôwç",
		"pron" : "maw-oce'",
		"derivation" : "from H3988 (מָאַס)",
		"def" : "refuse",
		"kjv" : "refuse"
	},
	"H3974" : {
		"lemma" : "מָאוֹר",
		"xlit" : "mâʼôwr",
		"pron" : "maw-ore'",
		"derivation" : "or מָאֹר; also (in plural) feminine מְאוֹרָה; or מְאֹרָה; from H215 (אוֹר)",
		"def" : "properly, a luminous body or luminary, i.e. (abstractly) light (as an element); figuratively, brightness, i.e.cheerfulness; specifically, a chandelier",
		"kjv" : "bright, light"
	},
	"H3975" : {
		"lemma" : "מְאוּרָה",
		"xlit" : "mᵉʼûwrâh",
		"pron" : "meh-oo-raw'",
		"derivation" : "feminine passive participle of H215 (אוֹר)",
		"def" : "something lighted, i.e. an aperture; by implication, a crevice or hole (of a serpent)",
		"kjv" : "den"
	},
	"H3976" : {
		"lemma" : "מֹאזֵן",
		"xlit" : "môʼzên",
		"pron" : "mo-zane'",
		"derivation" : "from H239 (אָזַן)",
		"def" : "(only in the dual) a pair of scales",
		"kjv" : "balances"
	},
	"H3977" : {
		"lemma" : "מֹאזֵן",
		"xlit" : "môʼzên",
		"pron" : "mo-zane'",
		"derivation" : "(Aramaic) corresponding to H3976 (מֹאזֵן)",
		"def" : "{(only in the dual) a pair of scales}",
		"kjv" : "balances"
	},
	"H3978" : {
		"lemma" : "מַאֲכָל",
		"xlit" : "maʼăkâl",
		"pron" : "mah-ak-awl'",
		"derivation" : "from H398 (אָכַל)",
		"def" : "an eatable (includ. provender, flesh and fruit)",
		"kjv" : "food, fruit, (bake-)meat(-s), victual"
	},
	"H3979" : {
		"lemma" : "מַאֲכֶלֶת",
		"xlit" : "maʼăkeleth",
		"pron" : "mah-ak-eh'-leth",
		"derivation" : "from H398 (אָכַל)",
		"def" : "something to eat with,i.e. a knife",
		"kjv" : "knife"
	},
	"H3980" : {
		"lemma" : "מַאֲכֹלֶת",
		"xlit" : "maʼăkôleth",
		"pron" : "mah-ak-o'-leth",
		"derivation" : "from H398 (אָכַל)",
		"def" : "something eaten (by fire), i.e. fuel",
		"kjv" : "fuel"
	},
	"H3981" : {
		"lemma" : "מַאֲמָץ",
		"xlit" : "maʼămâts",
		"pron" : "mah-am-awts'",
		"derivation" : "from H553 (אָמַץ)",
		"def" : "strength, i.e. (plural) resources",
		"kjv" : "force"
	},
	"H3982" : {
		"lemma" : "מַאֲמַר",
		"xlit" : "maʼămar",
		"pron" : "mah-am-ar'",
		"derivation" : "from H559 (אָמַר)",
		"def" : "something (authoritatively) said, i.e. an edict",
		"kjv" : "commandment, decree"
	},
	"H3983" : {
		"lemma" : "מֵאמַר",
		"xlit" : "mêʼmar",
		"pron" : "may-mar'",
		"derivation" : "(Aramaic) corresponding to H3982 (מַאֲמַר)",
		"def" : "{something (authoritatively) said, i.e. an edict}",
		"kjv" : "appointment, word"
	},
	"H3984" : {
		"lemma" : "מָאן",
		"xlit" : "mâʼn",
		"pron" : "mawn",
		"derivation" : "(Aramaic) probably from a root corresponding to H579 (אָנָה) in the sense of an inclosure by sides",
		"def" : "a utensil",
		"kjv" : "vessel"
	},
	"H3985" : {
		"lemma" : "מָאֵן",
		"xlit" : "mâʼên",
		"pron" : "maw-ane'",
		"derivation" : "a primitive root",
		"def" : "to refuse",
		"kjv" : "refuse, [idiom] utterly"
	},
	"H3986" : {
		"lemma" : "מָאֵן",
		"xlit" : "mâʼên",
		"pron" : "maw-ane'",
		"derivation" : "from H3985 (מָאֵן)",
		"def" : "unwilling",
		"kjv" : "refuse"
	},
	"H3987" : {
		"lemma" : "מֵאֵן",
		"xlit" : "mêʼên",
		"pron" : "may-ane'",
		"derivation" : "from H3985 (מָאֵן)",
		"def" : "refractory",
		"kjv" : "refuse"
	},
	"H3988" : {
		"lemma" : "מָאַס",
		"xlit" : "mâʼaç",
		"pron" : "maw-as'",
		"derivation" : "a primitive root",
		"def" : "to spurn; also (intransitively) to disappear",
		"kjv" : "abhor, cast away (off), contemn, despise, disdain, (become) loathe(some), melt away, refuse, reject, reprobate, [idiom] utterly, vile person"
	},
	"H3989" : {
		"lemma" : "מַאֲפֶה",
		"xlit" : "maʼăpheh",
		"pron" : "mah-af-eh'",
		"derivation" : "from H644 (אָפָה)",
		"def" : "something baked, i.e. a batch",
		"kjv" : "baken"
	},
	"H3990" : {
		"lemma" : "מַאֲפֵל",
		"xlit" : "maʼăphêl",
		"pron" : "mah-af-ale'",
		"derivation" : "from the same as H651 (אָפֵל)",
		"def" : "something opaque",
		"kjv" : "darkness"
	},
	"H3991" : {
		"lemma" : "מַאְפֵלְיָה",
		"xlit" : "maʼphêlᵉyâh",
		"pron" : "mah-af-ay-leh-yaw'",
		"derivation" : "prolonged feminine of H3990 (מַאֲפֵל)",
		"def" : "opaqueness",
		"kjv" : "darkness"
	},
	"H3992" : {
		"lemma" : "מָאַר",
		"xlit" : "mâʼar",
		"pron" : "maw-ar'",
		"derivation" : "a primitive root",
		"def" : "to be bitter or (causatively) to embitter, i.e. be painful",
		"kjv" : "fretting, picking"
	},
	"H3993" : {
		"lemma" : "מַאֲרָב",
		"xlit" : "maʼărâb",
		"pron" : "mah-ar-awb'",
		"derivation" : "from H693 (אָרַב)",
		"def" : "an ambuscade",
		"kjv" : "lie in ambush, ambushment, lurking place, lying in wait"
	},
	"H3994" : {
		"lemma" : "מְאֵרָה",
		"xlit" : "mᵉʼêrâh",
		"pron" : "meh-ay-raw'",
		"derivation" : "from H779 (אָרַר)",
		"def" : "an execration",
		"kjv" : "curse"
	},
	"H3995" : {
		"lemma" : "מִבְדָּלָה",
		"xlit" : "mibdâlâh",
		"pron" : "mib-daw-law'",
		"derivation" : "from H914 (בָּדַל)",
		"def" : "a separation, i.e. (concretely) a separate place",
		"kjv" : "separate"
	},
	"H3996" : {
		"lemma" : "מָבוֹא",
		"xlit" : "mâbôwʼ",
		"pron" : "maw-bo'",
		"derivation" : "from H935 (בּוֹא); (with or without H8121 (שֶׁמֶשׁ))",
		"def" : "an entrance (the place or the act); specifically sunset or the west; also (adverb with preposition) towards",
		"kjv" : "by which came, as cometh, in coming, as men enter into, entering, entrance into, entry, where goeth, going down, [phrase] westward. Compare H4126 (מוֹבָא)"
	},
	"H3997" : {
		"lemma" : "מְבוֹאָה",
		"xlit" : "mᵉbôwʼâh",
		"pron" : "meb-o-aw'",
		"derivation" : "feminine of H3996 (מָבוֹא)",
		"def" : "a haven",
		"kjv" : "entry"
	},
	"H3998" : {
		"lemma" : "מְבוּכָה",
		"xlit" : "mᵉbûwkâh",
		"pron" : "meb-oo-kaw'",
		"derivation" : "from H943 (בּוּךְ)",
		"def" : "perplexity",
		"kjv" : "perplexity"
	},
	"H3999" : {
		"lemma" : "מַבּוּל",
		"xlit" : "mabbûwl",
		"pron" : "mab-bool'",
		"derivation" : "from H2986 (יָבַל) in the sense of flowing",
		"def" : "a deluge",
		"kjv" : "flood"
	},
	"H4000" : {
		"lemma" : "מָבוֹן",
		"xlit" : "mâbôwn",
		"pron" : "maw-bone'",
		"derivation" : "from H995 (בִּין)",
		"def" : "instructing",
		"kjv" : "taught"
	},
	"H4001" : {
		"lemma" : "מְבוּסָה",
		"xlit" : "mᵉbûwçâh",
		"pron" : "meb-oo-saw'",
		"derivation" : "from H947 (בּוּס)",
		"def" : "a trampling",
		"kjv" : "treading (trodden) down (under foot)"
	},
	"H4002" : {
		"lemma" : "מַבּוּעַ",
		"xlit" : "mabbûwaʻ",
		"pron" : "mab-boo'-ah",
		"derivation" : "from H5042 (נָבַע)",
		"def" : "a fountain",
		"kjv" : "fountain, spring"
	},
	"H4003" : {
		"lemma" : "מְבוּקָה",
		"xlit" : "mᵉbûwqâh",
		"pron" : "meb-oo-kah'",
		"derivation" : "from the same as H950 (בּוּקָה)",
		"def" : "emptiness",
		"kjv" : "void"
	},
	"H4004" : {
		"lemma" : "מִבְחוֹר",
		"xlit" : "mibchôwr",
		"pron" : "mib-khore'",
		"derivation" : "from H977 (בָּחַר)",
		"def" : "select, i.e. well fortified",
		"kjv" : "choice"
	},
	"H4005" : {
		"lemma" : "מִבְחָר",
		"xlit" : "mibchâr",
		"pron" : "mib-khawr'",
		"derivation" : "from H977 (בָּחַר)",
		"def" : "select, i.e. best",
		"kjv" : "choice(-st), chosen"
	},
	"H4006" : {
		"lemma" : "מִבְחָר",
		"xlit" : "Mibchâr",
		"pron" : "mib-khawr'",
		"derivation" : "the same as H4005 (מִבְחָר)",
		"def" : "Mibchar, an Israelite",
		"kjv" : "Mibhar"
	},
	"H4007" : {
		"lemma" : "מַבָּט",
		"xlit" : "mabbâṭ",
		"pron" : "mab-bawt'",
		"derivation" : "or מֶבָּט; from H5027 (נָבַט)",
		"def" : "something expected, i.e. (abstractly) expectation",
		"kjv" : "expectation"
	},
	"H4008" : {
		"lemma" : "מִבְטָא",
		"xlit" : "mibṭâʼ",
		"pron" : "mib-taw'",
		"derivation" : "from H981 (בָּטָא)",
		"def" : "a rash utterance (hasty vow)",
		"kjv" : "(that which...) uttered (out of)"
	},
	"H4009" : {
		"lemma" : "מִבְטָח",
		"xlit" : "mibṭâch",
		"pron" : "mib-tawkh'",
		"derivation" : "from H982 (בָּטַח)",
		"def" : "properly, a refuge, i.e. (objective) security, or (subjective) assurance",
		"kjv" : "confidence, hope, sure, trust"
	},
	"H4010" : {
		"lemma" : "מַבְלִיגִית",
		"xlit" : "mablîygîyth",
		"pron" : "mab-leeg-eeth'",
		"derivation" : "from H1082 (בָּלַג)",
		"def" : "desistance (or rather desolation)",
		"kjv" : "comfort self"
	},
	"H4011" : {
		"lemma" : "מִבְנֶה",
		"xlit" : "mibneh",
		"pron" : "mib-neh'",
		"derivation" : "from H1129 (בָּנָה)",
		"def" : "a building",
		"kjv" : "frame"
	},
	"H4012" : {
		"lemma" : "מְבֻנַּי",
		"xlit" : "Mᵉbunnay",
		"pron" : "meb-oon-hah'-ee",
		"derivation" : "from H1129 (בָּנָה); built up",
		"def" : "Mebunnai, an Israelite",
		"kjv" : "Mebunnai"
	},
	"H4013" : {
		"lemma" : "מִבְצָר",
		"xlit" : "mibtsâr",
		"pron" : "mib-tsawr'",
		"derivation" : "also (in plural) feminine (Daniel 11:l5) מִבְצָרָה; from H1219 (בָּצַר)",
		"def" : "a fortification, castle, or fortified city; figuratively, a defender",
		"kjv" : "(de-, most) fenced, fortress, (most) strong (hold)"
	},
	"H4014" : {
		"lemma" : "מִבְצָר",
		"xlit" : "Mibtsâr",
		"pron" : "mib-tsawr'",
		"derivation" : "the same as H4013 (מִבְצָר)",
		"def" : "Mibtsar, an Idumaean",
		"kjv" : "Mibzar"
	},
	"H4015" : {
		"lemma" : "מִבְרָח",
		"xlit" : "mibrâch",
		"pron" : "mib-rawkh'",
		"derivation" : "from H1272 (בָּרַח)",
		"def" : "a refugee",
		"kjv" : "fugitive"
	},
	"H4016" : {
		"lemma" : "מָבֻשׁ",
		"xlit" : "mâbush",
		"pron" : "maw-boosh'",
		"derivation" : "from H954 (בּוּשׁ)",
		"def" : "(plural) the (male) pudenda",
		"kjv" : "secrets"
	},
	"H4017" : {
		"lemma" : "מִבְשָׂם",
		"xlit" : "Mibsâm",
		"pron" : "mib-sawm'",
		"derivation" : "from the same as H1314 (בֶּשֶׂם); fragrant",
		"def" : "Mibsam, the name of an Ishmaelite and of an Israelite",
		"kjv" : "Mibsam"
	},
	"H4018" : {
		"lemma" : "מְבַשְּׁלָה",
		"xlit" : "mᵉbashshᵉlâh",
		"pron" : "meb-ash-shel-aw'",
		"derivation" : "from H1310 (בָּשַׁל)",
		"def" : "a cooking hearth",
		"kjv" : "boiling-place"
	},
	"H4019" : {
		"lemma" : "מַגְבִּישׁ",
		"xlit" : "Magbîysh",
		"pron" : "mag-beesh'",
		"derivation" : "from the same as H1378 (גָּבִישׁ); stiffening",
		"def" : "Magbish, an Israelite, or a place in Palestine",
		"kjv" : "Magbish"
	},
	"H4020" : {
		"lemma" : "מִגְבָּלָה",
		"xlit" : "migbâlâh",
		"pron" : "mig-baw-law'",
		"derivation" : "from H1379 (גָּבַל)",
		"def" : "a border",
		"kjv" : "end"
	},
	"H4021" : {
		"lemma" : "מִגְבָּעָה",
		"xlit" : "migbâʻâh",
		"pron" : "mig-baw-aw'",
		"derivation" : "from the same as H1389 (גִּבְעָה)",
		"def" : "a cap (as hemispherical)",
		"kjv" : "bonnet"
	},
	"H4022" : {
		"lemma" : "מֶגֶד",
		"xlit" : "meged",
		"pron" : "meh'-ghed",
		"derivation" : "from an unused root probably meaning to be eminent",
		"def" : "properly, a distinguished thing; hence something valuable, as aproduct or fruit",
		"kjv" : "pleasant, precious fruit (thing)"
	},
	"H4023" : {
		"lemma" : "מְגִדּוֹן",
		"xlit" : "Mᵉgiddôwn",
		"pron" : "meg-id-done'",
		"derivation" : "(Zechariah H12 (אַבְדָן)) or מְגִדּוֹ; from H1413 (גָּדַד); rendezvous",
		"def" : "Megiddon or Megiddo, a place in Palestine",
		"kjv" : "Megiddo, Megiddon"
	},
	"H4024" : {
		"lemma" : "מִגְדּוֹל",
		"xlit" : "Migdôwl",
		"pron" : "mig-dole'",
		"derivation" : "or מִגְדֹּל; probably of Egyptian origin",
		"def" : "Migdol, a place in Egypt",
		"kjv" : "Migdol, tower"
	},
	"H4025" : {
		"lemma" : "מַגְדִּיאֵל",
		"xlit" : "Magdîyʼêl",
		"pron" : "mag-dee-ale'",
		"derivation" : "from H4022 (מֶגֶד) and H410 (אֵל); preciousness of God",
		"def" : "Magdiel, an Idumaean",
		"kjv" : "Magdiel"
	},
	"H4026" : {
		"lemma" : "מִגְדָּל",
		"xlit" : "migdâl",
		"pron" : "mig-dawl'",
		"derivation" : "also (in plural) feminine מִגְדָּלָה; from H1431 (גָּדַל)",
		"def" : "a tower (from its size or height); by analogy, a rostrum; figuratively, a (pyramidal) bed of flowers",
		"kjv" : "castle, flower, tower. Compare the names following"
	},
	"H4027" : {
		"lemma" : "מִגְדַּל־אֵל",
		"xlit" : "Migdal-ʼÊl",
		"pron" : "mig-dal-ale'",
		"derivation" : "from H4026 (מִגְדָּל) and H410 (אֵל); tower of God",
		"def" : "Migdal-El, a place in Palestine",
		"kjv" : "Migdal-el"
	},
	"H4028" : {
		"lemma" : "מִגְדַּל־גָּד",
		"xlit" : "Migdal-Gâd",
		"pron" : "migdal-gawd'",
		"derivation" : "from H4026 (מִגְדָּל) and H1408 (גַּד); tower of Fortune",
		"def" : "Migdal-Gad, a place in Palestine",
		"kjv" : "Migdal-gad"
	},
	"H4029" : {
		"lemma" : "מִגְדַּל־עֵדֶר",
		"xlit" : "Migdal-ʻÊder",
		"pron" : "mig-dal'-ay'-der",
		"derivation" : "from H4026 (מִגְדָּל) and H5739 (עֵדֶר); tower of a flock",
		"def" : "Migdal-Eder, a place in Palestine",
		"kjv" : "Migdal-eder, tower of the flock"
	},
	"H4030" : {
		"lemma" : "מִגְדָּנָה",
		"xlit" : "migdânâh",
		"pron" : "mig-daw-naw'",
		"derivation" : "from the same as H4022 (מֶגֶד)",
		"def" : "preciousness, i.e. a gem",
		"kjv" : "precious thing, present"
	},
	"H4031" : {
		"lemma" : "מָגוֹג",
		"xlit" : "Mâgôwg",
		"pron" : "maw-gogue'",
		"derivation" : "from H1463 (גּוֹג)",
		"def" : "Magog, a son of Japheth; also a barbarous northern region",
		"kjv" : "Magog"
	},
	"H4032" : {
		"lemma" : "מָגוֹר",
		"xlit" : "mâgôwr",
		"pron" : "maw-gore'",
		"derivation" : "or (Lamentations 2:22) מָגוּר; from H1481 (גּוּר) in the sense of fearing",
		"def" : "a fright (objective or subjective)",
		"kjv" : "fear, terror. Compare H4036 (מָגוֹר מִסָּבִיב)"
	},
	"H4033" : {
		"lemma" : "מָגוּר",
		"xlit" : "mâgûwr",
		"pron" : "maw-goor'",
		"derivation" : "or מָגֻר; from H1481 (גּוּר) in the sense of lodging",
		"def" : "a temporary abode; by extension, a permanent residence",
		"kjv" : "dwelling, pilgrimage, where sojourn, be a stranger. Compare H4032 (מָגוֹר)"
	},
	"H4034" : {
		"lemma" : "מְגוֹרַה",
		"xlit" : "mᵉgôwrah",
		"pron" : "meg-o-raw'",
		"derivation" : "feminine of H4032 (מָגוֹר)",
		"def" : "affright",
		"kjv" : "fear"
	},
	"H4035" : {
		"lemma" : "מְגוּרָה",
		"xlit" : "mᵉgûwrâh",
		"pron" : "meg-oo-raw'",
		"derivation" : "feminine of H4032 (מָגוֹר) or of H4033 (מָגוּר)",
		"def" : "a fright; also a granary",
		"kjv" : "barn, fear"
	},
	"H4036" : {
		"lemma" : "מָגוֹר מִסָּבִיב",
		"xlit" : "Mâgôwr miç-Çâbîyb",
		"pron" : "maw-gore' mis-saw-beeb'",
		"derivation" : "from H4032 (מָגוֹר) and H5439 (סָבִיב) with the preposition inserted; affright from around",
		"def" : "Magor-mis-Sabib, a symbolic name of Pashur",
		"kjv" : "Magormissabib"
	},
	"H4037" : {
		"lemma" : "מַגְזֵרָה",
		"xlit" : "magzêrâh",
		"pron" : "mag-zay-raw'",
		"derivation" : "from H1504 (גָּזַר)",
		"def" : "a cutting implement, i.e. a blade",
		"kjv" : "axe"
	},
	"H4038" : {
		"lemma" : "מַגָּל",
		"xlit" : "maggâl",
		"pron" : "mag-gawl'",
		"derivation" : "from an unused root meaning to reap",
		"def" : "a sickle",
		"kjv" : "sickle"
	},
	"H4039" : {
		"lemma" : "מְגִלָּה",
		"xlit" : "mᵉgillâh",
		"pron" : "meg-il-law'",
		"derivation" : "from H1556 (גָּלַל)",
		"def" : "a roll",
		"kjv" : "roll, volume"
	},
	"H4040" : {
		"lemma" : "מְגִלָּה",
		"xlit" : "mᵉgillâh",
		"pron" : "meg-il-law'",
		"derivation" : "(Aramaic) corresponding to H4039 (מְגִלָּה)",
		"def" : "{a roll}",
		"kjv" : "roll"
	},
	"H4041" : {
		"lemma" : "מְגַמָּה",
		"xlit" : "mᵉgammâh",
		"pron" : "meg-am-maw'",
		"derivation" : "from the same as H1571 (גַּם)",
		"def" : "properly, accumulation, i.e. impulse or direction",
		"kjv" : "sup up"
	},
	"H4042" : {
		"lemma" : "מָגַן",
		"xlit" : "mâgan",
		"pron" : "maw-gan'",
		"derivation" : "a denominative from H4043 (מָגֵן)",
		"def" : "properly, to shield; encompass with; figuratively, to rescue, to hand safely over (i.e. surrender)",
		"kjv" : "deliver"
	},
	"H4043" : {
		"lemma" : "מָגֵן",
		"xlit" : "mâgên",
		"pron" : "maw-gane'",
		"derivation" : "also (in plural) feminine מְגִנָּה; from H1598 (גָּנַן)",
		"def" : "a shield (i.e. the small one or buckler); figuratively, a protector; also the scaly hide of the crocodile",
		"kjv" : "[idiom] armed, buckler, defence, ruler, [phrase] scale, shield"
	},
	"H4044" : {
		"lemma" : "מְגִנָּה",
		"xlit" : "mᵉginnâh",
		"pron" : "meg-in-naw'",
		"derivation" : "from H4042 (מָגַן)",
		"def" : "a covering (in a bad sense), i.e. blindness or obduracy",
		"kjv" : "sorrow. See also H4043 (מָגֵן)"
	},
	"H4045" : {
		"lemma" : "מִגְעֶרֶת",
		"xlit" : "migʻereth",
		"pron" : "mig-eh'-reth",
		"derivation" : "from H1605 (גָּעַר)",
		"def" : "reproof (i.e. curse)",
		"kjv" : "rebuke"
	},
	"H4046" : {
		"lemma" : "מַגֵּפָה",
		"xlit" : "maggêphâh",
		"pron" : "mag-gay-faw'",
		"derivation" : "from H5062 (נָגַף)",
		"def" : "a pestilence; by analogy, defeat",
		"kjv" : "([idiom] be) plague(-d), slaughter, stroke"
	},
	"H4047" : {
		"lemma" : "מַגְפִּיעָשׁ",
		"xlit" : "Magpîyʻâsh",
		"pron" : "mag-pee-awsh'",
		"derivation" : "apparently from H1479 (גּוּף) or H5062 (נָגַף) and H6211 (עָשׁ); exterminator of (the) moth",
		"def" : "Magpiash, an Israelite",
		"kjv" : "Magpiash"
	},
	"H4048" : {
		"lemma" : "מָגַר",
		"xlit" : "mâgar",
		"pron" : "maw-gar'",
		"derivation" : "a primitive root",
		"def" : "to yield up; intensively, to precipitate",
		"kjv" : "cast down, terror"
	},
	"H4049" : {
		"lemma" : "מְגַר",
		"xlit" : "mᵉgar",
		"pron" : "meg-ar'",
		"derivation" : "(Aramaic) corresponding to H4048 (מָגַר)",
		"def" : "to overthrow",
		"kjv" : "destroy"
	},
	"H4050" : {
		"lemma" : "מְגֵרָה",
		"xlit" : "mᵉgêrâh",
		"pron" : "meg-ay-raw'",
		"derivation" : "from H1641 (גָּרַר)",
		"def" : "a saw",
		"kjv" : "axe, saw"
	},
	"H4051" : {
		"lemma" : "מִגְרוֹן",
		"xlit" : "Migrôwn",
		"pron" : "mig-rone'",
		"derivation" : "from H4048 (מָגַר); precipice",
		"def" : "Migron, a place in Palestine",
		"kjv" : "Migron"
	},
	"H4052" : {
		"lemma" : "מִגְרָעָה",
		"xlit" : "migrâʻâh",
		"pron" : "mig-raw-aw'",
		"derivation" : "from H1639 (גָּרַע)",
		"def" : "a ledge or offset",
		"kjv" : "narrowed rest"
	},
	"H4053" : {
		"lemma" : "מִגְרָפָה",
		"xlit" : "migrâphâh",
		"pron" : "mig-raw-faw'",
		"derivation" : "from H1640 (גָּרַף)",
		"def" : "something thrown off (by the spade), i.e. a clod",
		"kjv" : "clod"
	},
	"H4054" : {
		"lemma" : "מִגְרָשׁ",
		"xlit" : "migrâsh",
		"pron" : "mig-rawsh'",
		"derivation" : "also (in plural) feminine (Ezekiel 27:28) מִגְרָשָׁה; from H1644 (גָּרַשׁ)",
		"def" : "a suburb (i.e. open country whither flocks are driven from pasture); hence, the area around abuilding, or the margin of the sea",
		"kjv" : "cast out, suburb"
	},
	"H4055" : {
		"lemma" : "מַד",
		"xlit" : "mad",
		"pron" : "mad",
		"derivation" : "or מֵד; from H4058 (מָדַד)",
		"def" : "properly, extent, i.e. height; also a measure; by implication, a vesture (as measured); also a carpet",
		"kjv" : "armour, clothes, garment, judgment, measure, raiment, stature"
	},
	"H4056" : {
		"lemma" : "מַדְבַּח",
		"xlit" : "madbach",
		"pron" : "mad-bakh'",
		"derivation" : "(Aramaic) from H1684 (דְּבַח)",
		"def" : "a sacrificial altar",
		"kjv" : "altar"
	},
	"H4057" : {
		"lemma" : "מִדְבָּר",
		"xlit" : "midbâr",
		"pron" : "mid-bawr'",
		"derivation" : "from H1696 (דָבַר) in the sense of driving",
		"def" : "a pasture (i.e. open field, whither cattle are driven); by implication, a desert; also speech (including its organs)",
		"kjv" : "desert, south, speech, wilderness"
	},
	"H4058" : {
		"lemma" : "מָדַד",
		"xlit" : "mâdad",
		"pron" : "maw-dad'",
		"derivation" : "a primitive root",
		"def" : "properly, to stretch; by implication, to measure (as if by stretching a line); figuratively, to be extended",
		"kjv" : "measure, mete, stretch self"
	},
	"H4059" : {
		"lemma" : "מִדַּד",
		"xlit" : "middad",
		"pron" : "mid-dad'",
		"derivation" : "from H5074 (נָדַד)",
		"def" : "flight",
		"kjv" : "be gone"
	},
	"H4060" : {
		"lemma" : "מִדָּה",
		"xlit" : "middâh",
		"pron" : "mid-daw'",
		"derivation" : "feminine of H4055 (מַד)",
		"def" : "properly, extension, i.e. height or breadth; also a measure (including its standard); hence a portion (as measured) or a vestment; specifically, tribute (as measured)",
		"kjv" : "garment, measure(-ing, meteyard, piece, size, (great) stature, tribute, wide"
	},
	"H4061" : {
		"lemma" : "מִדָּה",
		"xlit" : "middâh",
		"pron" : "mid-daw'",
		"derivation" : "(Aramaic) or מִנְדָּה; (Aramaic), corresponding to H4060 (מִדָּה)",
		"def" : "tribute in money",
		"kjv" : "toll, tribute"
	},
	"H4062" : {
		"lemma" : "מַדְהֵבָה",
		"xlit" : "madhêbâh",
		"pron" : "mad-hay-baw'",
		"derivation" : "perhaps from the equivalent of H1722 (דְּהַב)",
		"def" : "goldmaking, i.e. exactness",
		"kjv" : "golden city"
	},
	"H4063" : {
		"lemma" : "מֶדֶו",
		"xlit" : "medev",
		"pron" : "meh'-dev",
		"derivation" : "from an unused root meaning to stretch",
		"def" : "properly, extent, i.e. measure; by implication, a dress (as measured)",
		"kjv" : "garment"
	},
	"H4064" : {
		"lemma" : "מַדְוֶה",
		"xlit" : "madveh",
		"pron" : "mad-veh'",
		"derivation" : "from H1738 (דָּוָה)",
		"def" : "sickness",
		"kjv" : "disease"
	},
	"H4065" : {
		"lemma" : "מַדּוּחַ",
		"xlit" : "maddûwach",
		"pron" : "mad-doo'akh",
		"derivation" : "from H5080 (נָדַח)",
		"def" : "seduction",
		"kjv" : "cause of banishment"
	},
	"H4066" : {
		"lemma" : "מָדוֹן",
		"xlit" : "mâdôwn",
		"pron" : "maw-dohn'",
		"derivation" : "from H1777 (דִּין)",
		"def" : "a contest or quarrel",
		"kjv" : "brawling, contention(-ous), discord, strife. Compare H4079 (מִדְיָן), H4090 (מְדָן)"
	},
	"H4067" : {
		"lemma" : "מָדוֹן",
		"xlit" : "mâdôwn",
		"pron" : "maw-dohn'",
		"derivation" : "from the same as H4063 (מֶדֶו)",
		"def" : "extensiveness, i.e. height",
		"kjv" : "stature"
	},
	"H4068" : {
		"lemma" : "מָדוֹן",
		"xlit" : "Mâdôwn",
		"pron" : "maw-dohn'",
		"derivation" : "the same as H4067 (מָדוֹן)",
		"def" : "Madon, a place in Palestine",
		"kjv" : "Madon"
	},
	"H4069" : {
		"lemma" : "מַדּוּעַ",
		"xlit" : "maddûwaʻ",
		"pron" : "mad-doo'-ah",
		"derivation" : "or מַדֻּעַ; from H4100 (מָה) and the passive participle of H3045 (יָדַע)",
		"def" : "what (is) known?; i.e. (by implication) (adverbially) why?",
		"kjv" : "how, wherefore, why"
	},
	"H4070" : {
		"lemma" : "מְדוֹר",
		"xlit" : "mᵉdôwr",
		"pron" : "med-ore'",
		"derivation" : "(Aramaic) or מְדֹר; (Aramaic), or מְדָר; (Aramaic), from H1753 (דּוּר)",
		"def" : "a dwelling",
		"kjv" : "dwelling"
	},
	"H4071" : {
		"lemma" : "מְדוּרָה",
		"xlit" : "mᵉdûwrâh",
		"pron" : "med-oo-raw'",
		"derivation" : "or מְדֻרָה; from H1752 (דּוּר) in the sense of accumulation",
		"def" : "a pile of fuel",
		"kjv" : "pile (for fire)"
	},
	"H4072" : {
		"lemma" : "מִדְחֶה",
		"xlit" : "midcheh",
		"pron" : "mid-kheh'",
		"derivation" : "from H1760 (דָּחָה)",
		"def" : "overthrow",
		"kjv" : "ruin"
	},
	"H4073" : {
		"lemma" : "מְדַחְפָה",
		"xlit" : "mᵉdachphâh",
		"pron" : "med-akh-faw'",
		"derivation" : "from H1765 (דָּחַף)",
		"def" : "a push, i.e. ruin",
		"kjv" : "overthrow"
	},
	"H4074" : {
		"lemma" : "מָדַי",
		"xlit" : "Mâday",
		"pron" : "maw-dah'-ee",
		"derivation" : "of foreign derivation",
		"def" : "Madai, a country of central Asia",
		"kjv" : "Madai, Medes, Media"
	},
	"H4075" : {
		"lemma" : "מָדַי",
		"xlit" : "Mâday",
		"pron" : "maw-dah'-ee",
		"derivation" : "patrial from H4074 (מָדַי)",
		"def" : "a Madian or native of Madai",
		"kjv" : "Mede"
	},
	"H4076" : {
		"lemma" : "מָדַי",
		"xlit" : "Mâday",
		"pron" : "maw-dah'-ee",
		"derivation" : "(Aramaic) corresponding to H4074 (מָדַי)",
		"def" : "{Madai, a country of central Asia}",
		"kjv" : "Mede(-s)"
	},
	"H4077" : {
		"lemma" : "מָדַי",
		"xlit" : "Mâday",
		"pron" : "maw-dah'-ee",
		"derivation" : "(Aramaic) corresponding to H4075 (מָדַי)",
		"def" : "{a Madian or native of Madai}",
		"kjv" : "Median"
	},
	"H4078" : {
		"lemma" : "מַדַּי",
		"xlit" : "madday",
		"pron" : "mad-dah'-ee",
		"derivation" : "from H4100 (מָה) and H1767 (דַּי)",
		"def" : "what (is) enough, i.e. sufficiently",
		"kjv" : "sufficiently"
	},
	"H4079" : {
		"lemma" : "מִדְיָן",
		"xlit" : "midyân",
		"pron" : "mid-yawn'",
		"derivation" : "a variation for H4066 (מָדוֹן)",
		"def" : "{a contest or quarrel}",
		"kjv" : "brawling, contention(-ous)"
	},
	"H4080" : {
		"lemma" : "מִדְיָן",
		"xlit" : "Midyân",
		"pron" : "mid-yawn'",
		"derivation" : "the same as H4079 (מִדְיָן)",
		"def" : "Midjan, a son of Abraham; also his country and (collectively) his descendants",
		"kjv" : "Midian, Midianite"
	},
	"H4081" : {
		"lemma" : "מִדִּין",
		"xlit" : "Middîyn",
		"pron" : "mid-deen'",
		"derivation" : "a variation for H4080 (מִדְיָן)",
		"def" : "{Midjan, a son of Abraham; also his country and (collectively) his descendants}",
		"kjv" : "Middin"
	},
	"H4082" : {
		"lemma" : "מְדִינָה",
		"xlit" : "mᵉdîynâh",
		"pron" : "med-ee-naw'",
		"derivation" : "from H1777 (דִּין)",
		"def" : "properly, a judgeship, i.e. jurisdiction; by implication, a district (as ruled by a judge); generally, a region",
		"kjv" : "([idiom] every) province"
	},
	"H4083" : {
		"lemma" : "מְדִינָה",
		"xlit" : "mᵉdîynâh",
		"pron" : "med-ee-naw'",
		"derivation" : "(Aramaic) corresponding to H4082 (מְדִינָה)",
		"def" : "{properly, a judgeship, i.e. jurisdiction; by implication, a district (as ruled by a judge); generally, a region}",
		"kjv" : "province"
	},
	"H4084" : {
		"lemma" : "מִדְיָנִי",
		"xlit" : "Midyânîy",
		"pron" : "mid-yaw-nee'",
		"derivation" : "patronymical or patrial from H4080 (מִדְיָן)",
		"def" : "a Midjanite or descendant (native) of Midjan",
		"kjv" : "Midianite. Compare H4092 (מְדָנִי)"
	},
	"H4085" : {
		"lemma" : "מְדֹכָה",
		"xlit" : "mᵉdôkâh",
		"pron" : "med-o-kaw'",
		"derivation" : "from H1743 (דּוּךְ)",
		"def" : "a mortar",
		"kjv" : "mortar"
	},
	"H4086" : {
		"lemma" : "מַדְמֵן",
		"xlit" : "Madmên",
		"pron" : "mad-mane'",
		"derivation" : "from the same as H1828 (דֹּמֶן); dunghill",
		"def" : "Madmen, a place in Palestine",
		"kjv" : "Madmen"
	},
	"H4087" : {
		"lemma" : "מַדְמֵנָה",
		"xlit" : "madmênâh",
		"pron" : "mad-may-naw'",
		"derivation" : "feminine from the same as H1828 (דֹּמֶן)",
		"def" : "a dunghill",
		"kjv" : "dunghill"
	},
	"H4088" : {
		"lemma" : "מַדְמֵנָה",
		"xlit" : "Madmênâh",
		"pron" : "mad-may-naw'",
		"derivation" : "the same as H4087 (מַדְמֵנָה)",
		"def" : "Madmenah, a place in Palestine",
		"kjv" : "Madmenah"
	},
	"H4089" : {
		"lemma" : "מַדְמַנָּה",
		"xlit" : "Madmannâh",
		"pron" : "mad-man-naw'",
		"derivation" : "a variation for H4087 (מַדְמֵנָה)",
		"def" : "Madmannah, a place in Palestine",
		"kjv" : "Madmannah"
	},
	"H4090" : {
		"lemma" : "מְדָן",
		"xlit" : "mᵉdân",
		"pron" : "med-awn'",
		"derivation" : "a form of H4066 (מָדוֹן)",
		"def" : "{a contest or quarrel}",
		"kjv" : "discord, strife"
	},
	"H4091" : {
		"lemma" : "מְדָן",
		"xlit" : "Mᵉdân",
		"pron" : "med-awn'",
		"derivation" : "the same as H4090 (מְדָן)",
		"def" : "Medan, a son of Abraham",
		"kjv" : "Medan"
	},
	"H4092" : {
		"lemma" : "מְדָנִי",
		"xlit" : "Mᵉdânîy",
		"pron" : "med-aw-nee'",
		"derivation" : "a variation of H4084 (מִדְיָנִי)",
		"def" : "{a Midjanite or descendant (native) of Midjan}",
		"kjv" : "Midianite"
	},
	"H4093" : {
		"lemma" : "מַדָּע",
		"xlit" : "maddâʻ",
		"pron" : "mad-daw'",
		"derivation" : "or מַדַּע; from H3045 (יָדַע)",
		"def" : "intelligence or consciousness",
		"kjv" : "knowledge, science, thought"
	},
	"H4094" : {
		"lemma" : "מַדְקָרָה",
		"xlit" : "madqârâh",
		"pron" : "mad-kaw-raw'",
		"derivation" : "from H1856 (דָּקַר)",
		"def" : "a wound",
		"kjv" : "piercing"
	},
	"H4095" : {
		"lemma" : "מַדְרֵגָה",
		"xlit" : "madrêgâh",
		"pron" : "mad-ray-gaw'",
		"derivation" : "from an unused root meaning to step",
		"def" : "properly, a step; by implication, a steep or inaccessible place",
		"kjv" : "stair, steep place"
	},
	"H4096" : {
		"lemma" : "מִדְרָךְ",
		"xlit" : "midrâk",
		"pron" : "mid-rawk'",
		"derivation" : "from H1869 (דָּרַךְ)",
		"def" : "a treading, i.e. a place for stepping on",
		"kjv" : "(foot-) breadth"
	},
	"H4097" : {
		"lemma" : "מִדְרָשׁ",
		"xlit" : "midrâsh",
		"pron" : "mid-rawsh'",
		"derivation" : "from H1875 (דָּרַשׁ)",
		"def" : "properly, an investigation, i.e. (by implication) a treatise or elaborate compilation",
		"kjv" : "story"
	},
	"H4098" : {
		"lemma" : "מְדֻשָּׁה",
		"xlit" : "mᵉdushshâh",
		"pron" : "med-oosh-shaw'",
		"derivation" : "from H1758 (דּוּשׁ)",
		"def" : "a threshing, i.e. (concretely and figuratively) down-trodden people",
		"kjv" : "threshing"
	},
	"H4099" : {
		"lemma" : "מְדָתָא",
		"xlit" : "Mᵉdâthâʼ",
		"pron" : "med-aw-thaw'",
		"derivation" : "of Persian origin",
		"def" : "Medatha, the father of Haman",
		"kjv" : "Hammedatha (including the article)"
	},
	"H4100" : {
		"lemma" : "מָה",
		"xlit" : "mâh",
		"pron" : "maw",
		"derivation" : "or מַה; or מָ; or מַ; also מֶה; a primitive particle",
		"def" : "properly, interrogative what? (including how? why? when?); but also exclamation, what! (including how!), or indefinitely what (including whatever, and even relatively, that which); often used with prefixes in various adverbial or conjunctive senses",
		"kjv" : "how (long, oft, (-soever)), (no-) thing, what (end, good, purpose, thing), whereby(-fore, -in, -to, -with), (for) why"
	},
	"H4101" : {
		"lemma" : "מָה",
		"xlit" : "mâh",
		"pron" : "maw",
		"derivation" : "(Aramaic) corresponding to H4100 (מָה)",
		"def" : "{properly, interrogative what? (including how? why? when?); but also exclamation, what! (including how!), or indefinitely what}",
		"kjv" : "how great (mighty), that which, what(-soever), why"
	},
	"H4102" : {
		"lemma" : "מָהַהּ",
		"xlit" : "mâhahh",
		"pron" : "maw-hah'",
		"derivation" : "apparently a denominative from H4100 (מָה)",
		"def" : "properly, to question or hesitate, i.e. (by implication) to be reluctant",
		"kjv" : "delay, linger, stay selves, tarry"
	},
	"H4103" : {
		"lemma" : "מְהוּמָה",
		"xlit" : "mᵉhûwmâh",
		"pron" : "meh-hoo-maw'",
		"derivation" : "from H1949 (הוּם)",
		"def" : "confusion or uproar",
		"kjv" : "destruction, discomfiture, trouble, tumult, vexation, vexed"
	},
	"H4104" : {
		"lemma" : "מְהוּמָן",
		"xlit" : "Mᵉhûwmân",
		"pron" : "meh-hoo-mawn'",
		"derivation" : "of Persian origin",
		"def" : "Mehuman, a eunuch of Xerxes",
		"kjv" : "Mehuman"
	},
	"H4105" : {
		"lemma" : "מְהֵיטַבְאֵל",
		"xlit" : "Mᵉhêyṭabʼêl",
		"pron" : "meh-hay-tab-ale'",
		"derivation" : "from H3190 (יָטַב) (augmented) and H410 (אֵל); bettered of God",
		"def" : "Mehetabel, the name of an Edomitish man and woman",
		"kjv" : "Mehetabeel, Mehetabel"
	},
	"H4106" : {
		"lemma" : "מָהִיר",
		"xlit" : "mâhîyr",
		"pron" : "maw-here'",
		"derivation" : "or מָהִר; from H4116 (מָהַר)",
		"def" : "quick; hence, skilful",
		"kjv" : "diligent, hasty, ready"
	},
	"H4107" : {
		"lemma" : "מָהַל",
		"xlit" : "mâhal",
		"pron" : "maw-hal'",
		"derivation" : "a primitive root",
		"def" : "properly, to cut down or reduce, i.e. by implication, to adulterate",
		"kjv" : "mixed"
	},
	"H4108" : {
		"lemma" : "מַהְלֵךְ",
		"xlit" : "mahlêk",
		"pron" : "mah-lake'",
		"derivation" : "from H1980 (הָלַךְ)",
		"def" : "a walking (plural collectively), i.e. access",
		"kjv" : "place to walk"
	},
	"H4109" : {
		"lemma" : "מַהֲלָךְ",
		"xlit" : "mahălâk",
		"pron" : "mah-hal-awk'",
		"derivation" : "from H1980 (הָלַךְ)",
		"def" : "a walk, i.e. a passage or a distance",
		"kjv" : "journey, walk"
	},
	"H4110" : {
		"lemma" : "מַהֲלָל",
		"xlit" : "mahălâl",
		"pron" : "mah-hal-awl'",
		"derivation" : "from H1984 (הָלַל)",
		"def" : "fame",
		"kjv" : "praise"
	},
	"H4111" : {
		"lemma" : "מַהֲלַלְאֵל",
		"xlit" : "Mahălalʼêl",
		"pron" : "mah-hal-al-ale'",
		"derivation" : "from H4110 (מַהֲלָל) and H410 (אֵל); praise of God",
		"def" : "Mahalalel, the name of an antediluvian patriarch and of an Israelite",
		"kjv" : "Mahalaleel"
	},
	"H4112" : {
		"lemma" : "מַהֲלֻמָּה",
		"xlit" : "mahălummâh",
		"pron" : "mah-hal-oom-maw'",
		"derivation" : "from H1986 (הָלַם)",
		"def" : "a blow",
		"kjv" : "stripe, stroke"
	},
	"H4113" : {
		"lemma" : "מַהֲמֹרָה",
		"xlit" : "mahămôrâh",
		"pron" : "mah-ham-o-raw'",
		"derivation" : "from an unused root of uncertain meaning",
		"def" : "perhaps an abyss",
		"kjv" : "deep pit"
	},
	"H4114" : {
		"lemma" : "מַהְפֵּכָה",
		"xlit" : "mahpêkâh",
		"pron" : "mah-pay-kaw'",
		"derivation" : "from H2015 (הָפַךְ)",
		"def" : "a destruction",
		"kjv" : "when...overthrew, overthrow(-n)"
	},
	"H4115" : {
		"lemma" : "מַהְפֶּכֶת",
		"xlit" : "mahpeketh",
		"pron" : "mah-peh'-keth",
		"derivation" : "from H2015 (הָפַךְ)",
		"def" : "a wrench, i.e. the stocks",
		"kjv" : "prison, stocks"
	},
	"H4116" : {
		"lemma" : "מָהַר",
		"xlit" : "mâhar",
		"pron" : "maw-har'",
		"derivation" : "a primitive root",
		"def" : "properly, to be liquid or flow easily, i.e. (by implication); to hurry (in a good or a bad sense); often used (with another verb) adverbially, promptly",
		"kjv" : "be carried headlong, fearful, (cause to make, in, make) haste(-n, -ily), (be) hasty, (fetch, make ready) [idiom] quickly, rash, [idiom] shortly, (be so) [idiom] soon, make speed, [idiom] speedily, [idiom] straightway, [idiom] suddenly, swift"
	},
	"H4117" : {
		"lemma" : "מָהַר",
		"xlit" : "mâhar",
		"pron" : "maw-har'",
		"derivation" : "a primitive root (perhaps rather the same as H4116 (מָהַר) through the idea of readiness in assent)",
		"def" : "to bargain (for a wife), i.e. to wed",
		"kjv" : "endow, [idiom] surely"
	},
	"H4118" : {
		"lemma" : "מַהֵר",
		"xlit" : "mahêr",
		"pron" : "mah-hare'",
		"derivation" : "from H4116 (מָהַר)",
		"def" : "properly, hurrying; hence (adverbially) in ahurry",
		"kjv" : "hasteth, hastily, at once, quickly, soon, speedily, suddenly"
	},
	"H4119" : {
		"lemma" : "מֹהַר",
		"xlit" : "môhar",
		"pron" : "mo'-har",
		"derivation" : "from H4117 (מָהַר)",
		"def" : "a price (for a wife)",
		"kjv" : "dowry"
	},
	"H4120" : {
		"lemma" : "מְהֵרָה",
		"xlit" : "mᵉhêrâh",
		"pron" : "meh-hay-raw'",
		"derivation" : "feminine of H4118 (מַהֵר)",
		"def" : "properly, a hurry; hence (adverbially) promptly",
		"kjv" : "hastily, quickly, shortly, soon, make (with) speed(-ily), swiftly"
	},
	"H4121" : {
		"lemma" : "מַהֲרַי",
		"xlit" : "Mahăray",
		"pron" : "mah-har-ah'-ee",
		"derivation" : "from H4116 (מָהַר); hasty",
		"def" : "Maharai, an Israelite",
		"kjv" : "Maharai"
	},
	"H4122" : {
		"lemma" : "מַהֵר שָׁלָל חָשׁ בַּז",
		"xlit" : "Mahêr Shâlâl Châsh Baz",
		"pron" : "mah-hare' shaw-lawl' khawsh baz",
		"derivation" : "from H4118 (מַהֵר) and H7998 (שָׁלָל) and H2363 (חוּשׁ) and H957 (בַּז); hasting (is he (the enemy) to the) booty, swift (to the) prey",
		"def" : "Maher-Shalal-Chash-Baz; the symbolical name of the son of Isaiah",
		"kjv" : "Maher-sha-lal-bash-baz"
	},
	"H4123" : {
		"lemma" : "מַהֲתַלָּה",
		"xlit" : "mahăthallâh",
		"pron" : "mah-hath-al-law'",
		"derivation" : "from H2048 (הָתַל)",
		"def" : "a delusion",
		"kjv" : "deceit"
	},
	"H4124" : {
		"lemma" : "מוֹאָב",
		"xlit" : "Môwʼâb",
		"pron" : "mo-awb",
		"derivation" : "from a prolonged form of the prepositional prefix m-and H1 (אָב); from (her (the mother's)) father",
		"def" : "Moab, an incestuous son of Lot; also his territory and descendants",
		"kjv" : "Moab"
	},
	"H4125" : {
		"lemma" : "מוֹאָבִי",
		"xlit" : "Môwʼâbîy",
		"pron" : "mo-aw-bee'",
		"derivation" : "feminine מוֹאָבִיָּה; or מוֹאָבִיתxlit Môwâbîyth corrected to Môwʼâbîyth; patronymical from H4124 (מוֹאָב)",
		"def" : "a Moabite or Moabitess, i.e. a descendant from Moab",
		"kjv" : "(woman) of Moab, Moabite(-ish, -ss)"
	},
	"H4126" : {
		"lemma" : "מוֹבָא",
		"xlit" : "môwbâʼ",
		"pron" : "mo-baw'",
		"derivation" : "by transp. for H3996 (מָבוֹא)",
		"def" : "an entrance",
		"kjv" : "coming"
	},
	"H4127" : {
		"lemma" : "מוּג",
		"xlit" : "mûwg",
		"pron" : "moog",
		"derivation" : "a primitive root",
		"def" : "to melt, i.e. literally (to soften, flow down, disappear), or figuratively (to fear, faint)",
		"kjv" : "consume, dissolve, (be) faint(-hearted), melt (away), make soft"
	},
	"H4128" : {
		"lemma" : "מוּד",
		"xlit" : "mûwd",
		"pron" : "mood",
		"derivation" : "a primitive root",
		"def" : "to shake",
		"kjv" : "measure"
	},
	"H4129" : {
		"lemma" : "מוֹדַע",
		"xlit" : "môwdaʻ",
		"pron" : "mo-dah'",
		"derivation" : "or rather מֹדָע; from H3045 (יָדַע)",
		"def" : "an acquaintance",
		"kjv" : "kinswoman"
	},
	"H4130" : {
		"lemma" : "מוֹדַעַת",
		"xlit" : "môwdaʻath",
		"pron" : "mo-dah'-ath",
		"derivation" : "from H3045 (יָדַע)",
		"def" : "acquaintance",
		"kjv" : "kindred"
	},
	"H4131" : {
		"lemma" : "מוֹט",
		"xlit" : "môwṭ",
		"pron" : "mote",
		"derivation" : "a primitive root",
		"def" : "to waver; by implication, to slip, shake, fall",
		"kjv" : "be carried, cast, be out of course, be fallen in decay, [idiom] exceedingly, fall(-ing down), be (re-) moved, be ready, shake, slide, slip"
	},
	"H4132" : {
		"lemma" : "מוֹט",
		"xlit" : "môwṭ",
		"pron" : "mote",
		"derivation" : "from H4131 (מוֹט)",
		"def" : "a wavering, i.e. fall; by implication, a pole (as shaking); hence, a yoke (as essentially a bent pole)",
		"kjv" : "bar, be moved, staff, yoke"
	},
	"H4133" : {
		"lemma" : "מוֹטָה",
		"xlit" : "môwṭâh",
		"pron" : "mo-taw'",
		"derivation" : "feminine of H4132 (מוֹט)",
		"def" : "a pole; by implication, an ox-bow; hence, a yoke (either literal or figurative)",
		"kjv" : "bands, heavy, staves, yoke"
	},
	"H4134" : {
		"lemma" : "מוּךְ",
		"xlit" : "mûwk",
		"pron" : "mook",
		"derivation" : "a primitive root",
		"def" : "to become thin, i.e. (figuratively) be impoverished",
		"kjv" : "be (waxen) poor(-er)"
	},
	"H4135" : {
		"lemma" : "מוּל",
		"xlit" : "mûwl",
		"pron" : "mool",
		"derivation" : "a primitive root",
		"def" : "to cut short, i.e. curtail (specifically the prepuce, i.e. to circumcise); by implication, to blunt; figuratively, to destroy",
		"kjv" : "circumcise(-ing), selves), cut down (in pieces), destroy, [idiom] must needs"
	},
	"H4136" : {
		"lemma" : "מוּל",
		"xlit" : "mûwl",
		"pron" : "mool",
		"derivation" : "or מוֹל; (Deuteronomy 1:1), or מוֹאל; (Nehemiah 12:38), or מֻל; (Numbers 22:5), from H4135 (מוּל)",
		"def" : "properly, abrupt, i.e. a precipice; by implication, the front; used only adverbially (with prepositional prefix) opposite",
		"kjv" : "(over) against, before, (fore-) front, from, (God-) ward, toward, with"
	},
	"H4137" : {
		"lemma" : "מוֹלָדָה",
		"xlit" : "Môwlâdâh",
		"pron" : "mo-law-daw'",
		"derivation" : "from H3205 (יָלַד); birth",
		"def" : "Moladah, a place in Palestine",
		"kjv" : "Moladah"
	},
	"H4138" : {
		"lemma" : "מוֹלֶדֶת",
		"xlit" : "môwledeth",
		"pron" : "mo-leh'-deth",
		"derivation" : "from H3205 (יָלַד)",
		"def" : "nativity (plural birth-place); by implication, lineage, native country; also offspring, family",
		"kjv" : "begotten, born, issue, kindred, native(-ity)"
	},
	"H4139" : {
		"lemma" : "מוּלָה",
		"xlit" : "mûwlâh",
		"pron" : "moo-law'",
		"derivation" : "from H4135 (מוּל)",
		"def" : "circumcision",
		"kjv" : "circumcision"
	},
	"H4140" : {
		"lemma" : "מוֹלִיד",
		"xlit" : "Môwlîyd",
		"pron" : "mo-leed'",
		"derivation" : "from H3205 (יָלַד); genitor",
		"def" : "Molid, an Israelite",
		"kjv" : "Molid"
	},
	"H4141" : {
		"lemma" : "מוּסָב",
		"xlit" : "mûwçâb",
		"pron" : "moo-sawb'",
		"derivation" : "from H5437 (סָבַב)",
		"def" : "a turn, i.e. circuit (of a building)",
		"kjv" : "winding about"
	},
	"H4142" : {
		"lemma" : "מוּסַבָּה",
		"xlit" : "mûwçabbâh",
		"pron" : "moo-sab-baw'",
		"derivation" : "or מֻסַבָּה; feminine of H4141 (מוּסָב)",
		"def" : "a reversal, i.e. the backside (of a gem), fold (of a double-leaved door), transmutation (of a name)",
		"kjv" : "being changed, inclosed, be set, turning"
	},
	"H4143" : {
		"lemma" : "מוּסָד",
		"xlit" : "mûwçâd",
		"pron" : "moo-sawd'",
		"derivation" : "from H3245 (יָסַד)",
		"def" : "a foundation",
		"kjv" : "foundation"
	},
	"H4144" : {
		"lemma" : "מוֹסָד",
		"xlit" : "môwçâd",
		"pron" : "mo-sawd'",
		"derivation" : "from H3245 (יָסַד)",
		"def" : "a foundation",
		"kjv" : "foundation"
	},
	"H4145" : {
		"lemma" : "מוּסָדָה",
		"xlit" : "mûwçâdâh",
		"pron" : "moo-saw-daw'",
		"derivation" : "feminine of H4143 (מוּסָד)",
		"def" : "a foundation; figuratively, an appointment",
		"kjv" : "foundation, grounded. Compare H4328 (מְיֻסָּדָה)"
	},
	"H4146" : {
		"lemma" : "מוֹסָדָה",
		"xlit" : "môwçâdâh",
		"pron" : "mo-saw-daw'",
		"derivation" : "or מֹסָדָה; feminine of H4144 (מוֹסָד)",
		"def" : "a foundation",
		"kjv" : "foundation"
	},
	"H4147" : {
		"lemma" : "מוֹסֵר",
		"xlit" : "môwçêr",
		"pron" : "mo-sare'",
		"derivation" : "also (in plural) feminine מוֹסֵרָה; or מֹסְרָה; from H3256 (יָסַר)",
		"def" : "properly, chastisement, i.e. (by implication) a halter; figuratively, restraint",
		"kjv" : "band, bond"
	},
	"H4148" : {
		"lemma" : "מוּסָר",
		"xlit" : "mûwçâr",
		"pron" : "moo-sawr'",
		"derivation" : "from H3256 (יָסַר)",
		"def" : "properly, chastisement; figuratively, reproof, warning or instruction; also restraint",
		"kjv" : "bond, chastening(-eth), chastisement, check, correction, discipline, doctrine, instruction, rebuke"
	},
	"H4149" : {
		"lemma" : "מוֹסֵרָה",
		"xlit" : "Môwçêrâh",
		"pron" : "mo-say-raw'",
		"derivation" : "or (plural) מֹסְרוֹת feminine of H4147 (מוֹסֵר); correction or corrections",
		"def" : "Moserah or Moseroth, a place in the Desert",
		"kjv" : "Mosera, Moseroth"
	},
	"H4150" : {
		"lemma" : "מוֹעֵד",
		"xlit" : "môwʻêd",
		"pron" : "mo-ade'",
		"derivation" : "or מֹעֵד; or (feminine) מוֹעָדָה; (2 Chronicles 8:13), from H3259 (יָעַד)",
		"def" : "properly, an appointment, i.e. a fixed time or season; specifically, a festival; conventionally ayear; by implication, an assembly (as convened for a definite purpose); technically the congregation; by extension, the place of meeting; also a signal (as appointed beforehand)",
		"kjv" : "appointed (sign, time), (place of, solemn) assembly, congregation, (set, solemn) feast, (appointed, due) season, solemn(-ity), synogogue, (set) time (appointed)"
	},
	"H4151" : {
		"lemma" : "מוֹעָד",
		"xlit" : "môwʻâd",
		"pron" : "mo-awd'",
		"derivation" : "from H3259 (יָעַד); (as in H4150 (מוֹעֵד))",
		"def" : "properly, an assembly  figuratively, a troop",
		"kjv" : "appointed time"
	},
	"H4152" : {
		"lemma" : "מוּעָדָה",
		"xlit" : "mûwʻâdâh",
		"pron" : "moo-aw-daw'",
		"derivation" : "from H3259 (יָעַד)",
		"def" : "an appointed place, i.e. asylum",
		"kjv" : "appointed"
	},
	"H4153" : {
		"lemma" : "מוֹעַדְיָה",
		"xlit" : "Môwʻadyâh",
		"pron" : "mo-ad-yaw'",
		"derivation" : "from H4151 (מוֹעָד) and H3050 (יָהּ); assembly of Jah",
		"def" : "Moadjah, an Israelite",
		"kjv" : "Moadiah. Compare H4573 (מַעֲדְיָה)"
	},
	"H4154" : {
		"lemma" : "מוּעֶדֶת",
		"xlit" : "mûwʻedeth",
		"pron" : "moo-ay'-deth",
		"derivation" : "feminine passive participle of H4571 (מָעַד)",
		"def" : "properly, made to slip, i.e. dislocated",
		"kjv" : "out of joint"
	},
	"H4155" : {
		"lemma" : "מוּעָף",
		"xlit" : "mûwʻâph",
		"pron" : "moo-awf'",
		"derivation" : "from H5774 (עוּף)",
		"def" : "properly, covered, i.e. dark; abstractly, obscurity, i.e. distress",
		"kjv" : "dimness"
	},
	"H4156" : {
		"lemma" : "מוֹעֵצָה",
		"xlit" : "môwʻêtsâh",
		"pron" : "mo-ay-tsaw'",
		"derivation" : "from H3289 (יָעַץ)",
		"def" : "a purpose",
		"kjv" : "counsel, device"
	},
	"H4157" : {
		"lemma" : "מוּעָקָה",
		"xlit" : "mûwʻâqâh",
		"pron" : "moo-aw-kaw'",
		"derivation" : "from H5781 (עוּק)",
		"def" : "pressure, i.e. (figuratively) distress",
		"kjv" : "affliction"
	},
	"H4158" : {
		"lemma" : "מוֹפַעַת",
		"xlit" : "Môwphaʻath",
		"pron" : "mo-fah'-ath",
		"derivation" : "(Jeremiah 48:21) or מֵיפַעַת; or מֵפַעַת; from H3313 (יָפַע); illuminative",
		"def" : "Mophaath or Mephaath, a place in Palestine",
		"kjv" : "Mephaath"
	},
	"H4159" : {
		"lemma" : "מוֹפֵת",
		"xlit" : "môwphêth",
		"pron" : "mo-faith'",
		"derivation" : "or מֹפֵת; from H3302 (יָפָה) in the sense of conspicuousness",
		"def" : "a miracle; by implication, a token or omen",
		"kjv" : "miracle, sign, wonder(-ed at)"
	},
	"H4160" : {
		"lemma" : "מוּץ",
		"xlit" : "mûwts",
		"pron" : "moots",
		"derivation" : "a primitive root",
		"def" : "to press, i.e. (figuratively) to oppress",
		"kjv" : "extortioner"
	},
	"H4161" : {
		"lemma" : "מוֹצָא",
		"xlit" : "môwtsâʼ",
		"pron" : "mo-tsaw'",
		"derivation" : "or מֹצָאxlit môtsâ corrected to môtsâʼ; from H3318 (יָצָא)",
		"def" : "a going forth, i.e. (the act) an egress, or (the place) an exit; hence, a source or product; specifically, dawn, the rising of the sun (the East), exportation, utterance, a gate, a fountain, a mine, a meadow (as producing grass)",
		"kjv" : "brought out, bud, that which came out, east, going forth, goings out, that which (thing that) is gone out, outgoing, proceeded out, spring, vein, (water-) course (springs)"
	},
	"H4162" : {
		"lemma" : "מוֹצָא",
		"xlit" : "môwtsâʼ",
		"pron" : "mo-tsaw'",
		"derivation" : "the same as H4161 (מוֹצָא)",
		"def" : "Motsa, the name of two Israelites",
		"kjv" : "Moza"
	},
	"H4163" : {
		"lemma" : "מוֹצָאָה",
		"xlit" : "môwtsâʼâh",
		"pron" : "mo-tsaw-aw'",
		"derivation" : "feminine of H4161 (מוֹצָא); (marg.; compare H6675 (צוֹאָה))",
		"def" : "a family descent; also a sewer",
		"kjv" : "draught house; going forth"
	},
	"H4164" : {
		"lemma" : "מוּצַק",
		"xlit" : "mûwtsaq",
		"pron" : "moo-tsak'",
		"derivation" : "or מוּצָק; from H3332 (יָצַק)",
		"def" : "narrowness; figuratively, distress",
		"kjv" : "anguish, is straitened, straitness"
	},
	"H4165" : {
		"lemma" : "מוּצָק",
		"xlit" : "mûwtsâq",
		"pron" : "moo-tsawk'",
		"derivation" : "from H5694 (עָגִיל)",
		"def" : "properly, fusion, i.e. literally, a casting (of metal); figuratively, a mass (of clay)",
		"kjv" : "casting, hardness"
	},
	"H4166" : {
		"lemma" : "מוּצָקָה",
		"xlit" : "mûwtsâqâh",
		"pron" : "moo-tsaw-kaw'",
		"derivation" : "or מֻצָקָה; from H3332 (יָצַק)",
		"def" : "properly, something poured out, i.e. a casting (of metal); by implication, a tube (as cast)",
		"kjv" : "when it was cast, pipe"
	},
	"H4167" : {
		"lemma" : "מוּק",
		"xlit" : "mûwq",
		"pron" : "mook",
		"derivation" : "a primitive root",
		"def" : "to jeer, i.e. (intens.) blaspheme",
		"kjv" : "be corrupt"
	},
	"H4168" : {
		"lemma" : "מוֹקֵד",
		"xlit" : "môwqêd",
		"pron" : "mo-kade'",
		"derivation" : "from H3344 (יָקַד)",
		"def" : "a fire or fuel; abstractly, a conflagration",
		"kjv" : "burning, hearth"
	},
	"H4169" : {
		"lemma" : "מוֹקְדָה",
		"xlit" : "môwqᵉdâh",
		"pron" : "mo-ked-aw'",
		"derivation" : "feminine of H4168 (מוֹקֵד)",
		"def" : "fuel",
		"kjv" : "burning"
	},
	"H4170" : {
		"lemma" : "מוֹקֵשׁ",
		"xlit" : "môwqêsh",
		"pron" : "mo-kashe'",
		"derivation" : "or מֹקֵשׁ; from H3369 (יָקֹשׁ)",
		"def" : "a noose (for catching animals) (literally or figuratively); by implication, a hook (for the nose)",
		"kjv" : "be ensnared, gin, (is) snare(-d), trap"
	},
	"H4171" : {
		"lemma" : "מוּר",
		"xlit" : "mûwr",
		"pron" : "moor",
		"derivation" : "a primitive root",
		"def" : "to alter; by implication, to barter, to dispose of",
		"kjv" : "[idiom] at all, (ex-) change, remove"
	},
	"H4172" : {
		"lemma" : "מוֹרָא",
		"xlit" : "môwrâʼ",
		"pron" : "mo-raw'",
		"derivation" : "or מֹרָא; or מוֹרָהxlit môrâh corrected to môwrâh; (Psalm 9:20), from H3372 (יָרֵא)",
		"def" : "fear; by implication, a fearful thing or deed",
		"kjv" : "dread, (that ought to be) fear(-ed), terribleness, terror"
	},
	"H4173" : {
		"lemma" : "מוֹרַג",
		"xlit" : "môwrag",
		"pron" : "mo-rag'",
		"derivation" : "or מֹרַג; from an unused root meaning to triturate",
		"def" : "a threshing sledge",
		"kjv" : "threshing instrument"
	},
	"H4174" : {
		"lemma" : "מוֹרָד",
		"xlit" : "môwrâd",
		"pron" : "mo-rawd'",
		"derivation" : "from H3381 (יָרַד)",
		"def" : "a descent; as architecture, an ornamental appendage, perhaps a festoon",
		"kjv" : "going down, steep place, thin work"
	},
	"H4175" : {
		"lemma" : "מוֹרֶה",
		"xlit" : "môwreh",
		"pron" : "mo-reh'",
		"derivation" : "from H3384 (יָרָה); (see H3138 (יוֹרֶה))",
		"def" : "an archer; also teacher or teaching; also the early rain",
		"kjv" : "(early) rain"
	},
	"H4176" : {
		"lemma" : "מוֹרֶה",
		"xlit" : "Môwreh",
		"pron" : "mo-reh'",
		"derivation" : "or מֹרֶה; the same as H4175 (מוֹרֶה)",
		"def" : "Moreh, a Canaanite; also a hill (perhaps named from him)",
		"kjv" : "Moreh"
	},
	"H4177" : {
		"lemma" : "מוֹרָה",
		"xlit" : "môwrâh",
		"pron" : "mo-raw'",
		"derivation" : "from H4171 (מוּר) in the sense of shearing",
		"def" : "a razor",
		"kjv" : "razor"
	},
	"H4178" : {
		"lemma" : "מוֹרָט",
		"xlit" : "môwrâṭ",
		"pron" : "mo-rawt'",
		"derivation" : "from H3399 (יָרַט)",
		"def" : "obstinate, i.e. independent",
		"kjv" : "peeled"
	},
	"H4179" : {
		"lemma" : "מוֹרִיָּה",
		"xlit" : "Môwrîyâh",
		"pron" : "mo-ree-yaw'",
		"derivation" : "or מֹרִיָּה; from H7200 (רָאָה) and H3050 (יָהּ); seen of Jah",
		"def" : "Morijah, a hill in Palestine",
		"kjv" : "Moriah"
	},
	"H4180" : {
		"lemma" : "מוֹרָשׁ",
		"xlit" : "môwrâsh",
		"pron" : "mo-rawsh'",
		"derivation" : "from H3423 (יָרַשׁ)",
		"def" : "a possession; figuratively, delight",
		"kjv" : "possession, thought"
	},
	"H4181" : {
		"lemma" : "מוֹרָשָׁה",
		"xlit" : "môwrâshâh",
		"pron" : "mo-raw-shaw'",
		"derivation" : "feminine of H4180 (מוֹרָשׁ)",
		"def" : "a possession",
		"kjv" : "heritage, inheritance, possession"
	},
	"H4182" : {
		"lemma" : "מוֹרֶשֶׁת גַּת",
		"xlit" : "Môwresheth Gath",
		"pron" : "mo-reh'-sheth gath",
		"derivation" : "from H3423 (יָרַשׁ) and H1661 (גַּת); possession of Gath",
		"def" : "Moresheth-Gath, a place in Palestine",
		"kjv" : "Moresheth-gath"
	},
	"H4183" : {
		"lemma" : "מוֹרַשְׁתִּי",
		"xlit" : "Môwrashtîy",
		"pron" : "mo-rash-tee'",
		"derivation" : "patrial from H4182 (מוֹרֶשֶׁת גַּת)",
		"def" : "a Morashtite or inhabitant of Moresheth-Gath",
		"kjv" : "Morashthite"
	},
	"H4184" : {
		"lemma" : "מוּשׁ",
		"xlit" : "mûwsh",
		"pron" : "moosh",
		"derivation" : "a primitive root",
		"def" : "to touch",
		"kjv" : "feel, handle"
	},
	"H4185" : {
		"lemma" : "מוּשׁ",
		"xlit" : "mûwsh",
		"pron" : "moosh",
		"derivation" : "a primitive root (perhaps rather the same as H4184 (מוּשׁ) through the idea of receding by contact)",
		"def" : "to withdraw (both literally and figuratively, whether intransitive or transitive)",
		"kjv" : "cease, depart, go back, remove, take away"
	},
	"H4186" : {
		"lemma" : "מוֹשָׁב",
		"xlit" : "môwshâb",
		"pron" : "mo-shawb'",
		"derivation" : "or מֹשָׁב; from H3427 (יָשַׁב)",
		"def" : "a seat; figuratively, a site; abstractly, a session; by extension an abode (the place or the time); by implication, population",
		"kjv" : "assembly, dwell in, dwelling(-place), wherein (that) dwelt (in), inhabited place, seat, sitting, situation, sojourning"
	},
	"H4187" : {
		"lemma" : "מוּשִׁי",
		"xlit" : "Mûwshîy",
		"pron" : "moo-shee'",
		"derivation" : "or מֻשִּׁי; from H4184 (מוּשׁ); sensitive",
		"def" : "Mushi, a Levite",
		"kjv" : "Mushi"
	},
	"H4188" : {
		"lemma" : "מוּשִׁי",
		"xlit" : "Mûwshîy",
		"pron" : "moo-shee'",
		"derivation" : "patronymical from H4187 (מוּשִׁי)",
		"def" : "a Mushite (collectively) or descendants of Mushi",
		"kjv" : "Mushites"
	},
	"H4189" : {
		"lemma" : "מוֹשְׁכָה",
		"xlit" : "môwshᵉkâh",
		"pron" : "mo-shek-aw'",
		"derivation" : "act participle feminine of H4900 (מָשַׁךְ)",
		"def" : "something drawing, i.e. (figuratively) a cord",
		"kjv" : "band"
	},
	"H4190" : {
		"lemma" : "מוֹשָׁעָה",
		"xlit" : "môwshâʻâh",
		"pron" : "mo-shaw-aw'",
		"derivation" : "from H3467 (יָשַׁע)",
		"def" : "deliverance",
		"kjv" : "salvation"
	},
	"H4191" : {
		"lemma" : "מוּת",
		"xlit" : "mûwth",
		"pron" : "mooth",
		"derivation" : "a primitive root",
		"def" : "to die (literally or figuratively); causatively, to kill",
		"kjv" : "[idiom] at all, [idiom] crying, (be) dead (body, man, one), (put to, worthy of) death, destroy(-er), (cause to, be like to, must) die, kill, necro(-mancer), [idiom] must needs, slay, [idiom] surely, [idiom] very suddenly, [idiom] in (no) wise"
	},
	"H4192" : {
		"lemma" : "מוּת",
		"xlit" : "Mûwth",
		"pron" : "mooth",
		"derivation" : "(Psalm H48 (אֲבִירָם) or מוּת לַבֵּן; from H4191 (מוּת) and H1121 (בֵּן) with the preposition and article interposed",
		"def" : "'To die for the son', probably the title of a popular song",
		"kjv" : "death, Muthlabben"
	},
	"H4193" : {
		"lemma" : "מוֹת",
		"xlit" : "môwth",
		"pron" : "mohth",
		"derivation" : "(Aramaic) corresponding to H4194 (מָוֶת)",
		"def" : "death",
		"kjv" : "death"
	},
	"H4194" : {
		"lemma" : "מָוֶת",
		"xlit" : "mâveth",
		"pron" : "maw'-veth",
		"derivation" : "from H4191 (מוּת)",
		"def" : "death (natural or violent); concretely, the dead, their place or state (hades); figuratively, pestilence, ruin",
		"kjv" : "(be) dead(-ly), death, die(-d)"
	},
	"H4195" : {
		"lemma" : "מוֹתָר",
		"xlit" : "môwthâr",
		"pron" : "mo-thar'",
		"derivation" : "from H3498 (יָתַר)",
		"def" : "literally gain; figuratively, superiority",
		"kjv" : "plenteousness, preeminence, profit"
	},
	"H4196" : {
		"lemma" : "מִזְבֵּחַ",
		"xlit" : "mizbêach",
		"pron" : "miz-bay'-akh",
		"derivation" : "from H2076 (זָבַח)",
		"def" : "an altar",
		"kjv" : "altar"
	},
	"H4197" : {
		"lemma" : "מֶזֶג",
		"xlit" : "mezeg",
		"pron" : "meh'-zeg",
		"derivation" : "from an unused root meaning to mingle (water with wine)",
		"def" : "tempered wine",
		"kjv" : "liquor"
	},
	"H4198" : {
		"lemma" : "מָזֶה",
		"xlit" : "mâzeh",
		"pron" : "maw-zeh'",
		"derivation" : "from an unused root meaning to suck out",
		"def" : "exhausted",
		"kjv" : "burnt"
	},
	"H4199" : {
		"lemma" : "מִזָּה",
		"xlit" : "Mizzâh",
		"pron" : "miz-zaw'",
		"derivation" : "probably from an unused root meaning to faint with fear; terror",
		"def" : "Mizzah, an Edomite",
		"kjv" : "Mizzah"
	},
	"H4200" : {
		"lemma" : "מֶזֶו",
		"xlit" : "mezev",
		"pron" : "meh'-zev",
		"derivation" : "probably from an unused root meaning to gather in",
		"def" : "a granary",
		"kjv" : "garner"
	},
	"H4201" : {
		"lemma" : "מְזוּזָה",
		"xlit" : "mᵉzûwzâh",
		"pron" : "mez-oo-zaw'",
		"derivation" : "or מְזֻזָה; from the same as H2123 (זִיז)",
		"def" : "a door-post (as prominent)",
		"kjv" : "(door, side) post"
	},
	"H4202" : {
		"lemma" : "מָזוֹן",
		"xlit" : "mâzôwn",
		"pron" : "maw-zone'",
		"derivation" : "from H2109 (זוּן)",
		"def" : "food",
		"kjv" : "meat, victual"
	},
	"H4203" : {
		"lemma" : "מָזוֹן",
		"xlit" : "mâzôwn",
		"pron" : "maw-zone'",
		"derivation" : "(Aramaic) corresponding to H4202 (מָזוֹן)",
		"def" : "{food}",
		"kjv" : "meat"
	},
	"H4204" : {
		"lemma" : "מָזוֹר",
		"xlit" : "mâzôwr",
		"pron" : "maw-zore'",
		"derivation" : "from H2114 (זוּר) in the sense of turning aside from truth",
		"def" : "treachery, i.e. a plot",
		"kjv" : "wound"
	},
	"H4205" : {
		"lemma" : "מָזוֹר",
		"xlit" : "mâzôwr",
		"pron" : "maw-zore'",
		"derivation" : "or מָזֹר; from H2115 (זוּר) in the sense of binding up",
		"def" : "a bandage, i.e. remedy; hence, a sore (as needing a compress)",
		"kjv" : "bound up, wound"
	},
	"H4206" : {
		"lemma" : "מָזִיחַ",
		"xlit" : "mâzîyach",
		"pron" : "maw-zee'-akh",
		"derivation" : "or מֵזַח; from H2118 (זָחַח)",
		"def" : "a belt (as movable)",
		"kjv" : "girdle, strength"
	},
	"H4207" : {
		"lemma" : "מַזְלֵג",
		"xlit" : "mazlêg",
		"pron" : "maz-layg'",
		"derivation" : "or (feminine) מִזְלָגָה; from an unused root meaning to draw up",
		"def" : "a fork",
		"kjv" : "fleshhook"
	},
	"H4208" : {
		"lemma" : "מַזָּלָה",
		"xlit" : "mazzâlâh",
		"pron" : "maz-zaw-law'",
		"derivation" : "apparently from H5140 (נָזַל) in the sense of raining",
		"def" : "a constellation, i.e. Zodiacal sign (perhaps as affecting the weather)",
		"kjv" : "planet. Compare H4216 (מַזָּרָה)"
	},
	"H4209" : {
		"lemma" : "מְזִמָּה",
		"xlit" : "mᵉzimmâh",
		"pron" : "mez-im-maw'",
		"derivation" : "from H2161 (זָמַם)",
		"def" : "a plan, usually evil (machination), sometimes good (sagacity)",
		"kjv" : "(wicked) device, discretion, intent, witty invention, lewdness, mischievous (device), thought, wickedly"
	},
	"H4210" : {
		"lemma" : "מִזְמוֹר",
		"xlit" : "mizmôwr",
		"pron" : "miz-more'",
		"derivation" : "from H2167 (זָמַר)",
		"def" : "properly, instrumental music; by implication, a poem set to notes",
		"kjv" : "psalm"
	},
	"H4211" : {
		"lemma" : "מַזְמֵרָה",
		"xlit" : "mazmêrâh",
		"pron" : "maz-may-raw'",
		"derivation" : "from H2168 (זָמַר)",
		"def" : "a pruningknife",
		"kjv" : "pruning-hook"
	},
	"H4212" : {
		"lemma" : "מְזַמְּרָה",
		"xlit" : "mᵉzammᵉrâh",
		"pron" : "mez-am-mer-aw'",
		"derivation" : "from H2168 (זָמַר)",
		"def" : "a tweezer (only in the plural)",
		"kjv" : "snuffers"
	},
	"H4213" : {
		"lemma" : "מִזְעָר",
		"xlit" : "mizʻâr",
		"pron" : "miz-awr'",
		"derivation" : "from the same as H2191 (זְעֵיר)xlit zeʻêyr corrected to zᵉʻêyr",
		"def" : "fewness; by implication, as superl. diminutiveness",
		"kjv" : "few, [idiom] very"
	},
	"H4214" : {
		"lemma" : "מִזְרֶה",
		"xlit" : "mizreh",
		"pron" : "miz-reh'",
		"derivation" : "from H2219 (זָרָה)",
		"def" : "a winnowing shovel (as scattering the chaff)",
		"kjv" : "fan"
	},
	"H4215" : {
		"lemma" : "מְזָרֶה",
		"xlit" : "mᵉzâreh",
		"pron" : "mez-aw-reh'",
		"derivation" : "apparently from H2219 (זָרָה)",
		"def" : "properly, a scatterer, i.e. the north wind (as dispersing clouds; only in plural)",
		"kjv" : "north"
	},
	"H4216" : {
		"lemma" : "מַזָּרָה",
		"xlit" : "mazzârâh",
		"pron" : "maz-zaw-raw'",
		"derivation" : "apparently from H5144 (נָזַר) in the sense of distinction",
		"def" : "some noted constellation (only in the plural), perhaps collectively, the zodiac",
		"kjv" : "Mazzoroth. Compare H4208 (מַזָּלָה)"
	},
	"H4217" : {
		"lemma" : "מִזְרָח",
		"xlit" : "mizrâch",
		"pron" : "miz-rawkh'",
		"derivation" : "from H2224 (זָרַח)",
		"def" : "sunrise, i.e. the east",
		"kjv" : "east (side, -ward), (sun-) rising (of the sun)"
	},
	"H4218" : {
		"lemma" : "מִזְרָע",
		"xlit" : "mizrâʻ",
		"pron" : "miz-raw'",
		"derivation" : "from H2232 (זָרַע)",
		"def" : "a planted field",
		"kjv" : "thing sown"
	},
	"H4219" : {
		"lemma" : "מִזְרָק",
		"xlit" : "mizrâq",
		"pron" : "miz-rawk'",
		"derivation" : "from H2236 (זָרַק)",
		"def" : "a bowl (as if for sprinkling)",
		"kjv" : "bason, bowl"
	},
	"H4220" : {
		"lemma" : "מֵחַ",
		"xlit" : "mêach",
		"pron" : "may'-akh",
		"derivation" : "from H4229 (מָחָה) in the sense of greasing",
		"def" : "fat; figuratively, rich",
		"kjv" : "fatling (one)"
	},
	"H4221" : {
		"lemma" : "מֹחַ",
		"xlit" : "môach",
		"pron" : "mo'-akh",
		"derivation" : "from the same as H4220 (מֵחַ)",
		"def" : "fat, i.e. marrow",
		"kjv" : "marrow"
	},
	"H4222" : {
		"lemma" : "מָחָא",
		"xlit" : "mâchâʼ",
		"pron" : "maw-khaw'",
		"derivation" : "a primitive root",
		"def" : "to rub or strike the hands together (in exultation)",
		"kjv" : "clap"
	},
	"H4223" : {
		"lemma" : "מְחָא",
		"xlit" : "mᵉchâʼ",
		"pron" : "mekh-aw'",
		"derivation" : "(Aramaic) corresponding to H4222 (מָחָא)",
		"def" : "to strike in pieces; also to arrest; specifically to impale",
		"kjv" : "hang, smite, stay"
	},
	"H4224" : {
		"lemma" : "מַחֲבֵא",
		"xlit" : "machăbêʼ",
		"pron" : "makh-ab-ay'",
		"derivation" : "or מַחֲבֹא; from H2244 (חָבָא)",
		"def" : "a refuge",
		"kjv" : "hiding (lurking) place"
	},
	"H4225" : {
		"lemma" : "מַחְבֶּרֶת",
		"xlit" : "machbereth",
		"pron" : "makh-beh'-reth",
		"derivation" : "from H2266 (חָבַר)",
		"def" : "a junction, i.e. seam or sewed piece",
		"kjv" : "coupling"
	},
	"H4226" : {
		"lemma" : "מְחַבְּרָה",
		"xlit" : "mᵉchabbᵉrâh",
		"pron" : "mekh-ab-ber-aw'",
		"derivation" : "from H2266 (חָבַר)",
		"def" : "a joiner, i.e. brace or cramp",
		"kjv" : "coupling, joining"
	},
	"H4227" : {
		"lemma" : "מַחֲבַת",
		"xlit" : "machăbath",
		"pron" : "makh-ab-ath'",
		"derivation" : "from the same as H2281 (חָבֵת)",
		"def" : "a pan for baking in",
		"kjv" : "pan"
	},
	"H4228" : {
		"lemma" : "מַחֲגֹרֶת",
		"xlit" : "machăgôreth",
		"pron" : "makh-ag-o'-reth",
		"derivation" : "from H2296 (חָגַר)",
		"def" : "a girdle",
		"kjv" : "girding"
	},
	"H4229" : {
		"lemma" : "מָחָה",
		"xlit" : "mâchâh",
		"pron" : "maw-khaw'",
		"derivation" : "a primitive root",
		"def" : "properly, to stroke or rub; by implication, to erase; also to smooth (as if with oil), i.e. grease or make fat; also to touch, i.e. reach to",
		"kjv" : "abolish, blot out, destroy, full of marrow, put out, reach unto, [idiom] utterly, wipe (away, out)"
	},
	"H4230" : {
		"lemma" : "מְחוּגָה",
		"xlit" : "mᵉchûwgâh",
		"pron" : "mekk-oo-gaw'",
		"derivation" : "from H2328 (חוּג)",
		"def" : "an instrument for marking a circle, i.e. compasses",
		"kjv" : "compass"
	},
	"H4231" : {
		"lemma" : "מָחוֹז",
		"xlit" : "mâchôwz",
		"pron" : "maw-khoze'",
		"derivation" : "from an unused root meaning to enclose",
		"def" : "a harbor (as shut in by the shore)",
		"kjv" : "haven"
	},
	"H4232" : {
		"lemma" : "מְחוּיָאֵל",
		"xlit" : "Mᵉchûwyâʼêl",
		"pron" : "mekh-oo-yaw-ale'",
		"derivation" : "or מְחִיּיָאֵל; from H4229 (מָחָה) and H410 (אֵל); smitten of God",
		"def" : "Mechujael or Mechijael, an antediluvian patriarch",
		"kjv" : "Mehujael"
	},
	"H4233" : {
		"lemma" : "מַחֲוִים",
		"xlit" : "Machăvîym",
		"pron" : "makh-av-eem'",
		"derivation" : "apparently a patrial, but from an unknown place (in the plural only for a singular)",
		"def" : "a Machavite or inhabitant of some place named Machaveh",
		"kjv" : "Mahavite"
	},
	"H4234" : {
		"lemma" : "מָחוֹל",
		"xlit" : "mâchôwl",
		"pron" : "maw-khole'",
		"derivation" : "from H2342 (חוּל)",
		"def" : "a (round) dance",
		"kjv" : "dance(-cing)"
	},
	"H4235" : {
		"lemma" : "מָחוֹל",
		"xlit" : "Mâchôwl",
		"pron" : "maw-khole'",
		"derivation" : "the same as H4234 (מָחוֹל); dancing",
		"def" : "Machol, an Israelite",
		"kjv" : "Mahol"
	},
	"H4236" : {
		"lemma" : "מַחֲזֶה",
		"xlit" : "machăzeh",
		"pron" : "makh-az-eh'",
		"derivation" : "from H2372 (חָזָה)",
		"def" : "a vision",
		"kjv" : "vision"
	},
	"H4237" : {
		"lemma" : "מֶחֱזָה",
		"xlit" : "mechĕzâh",
		"pron" : "mekh-ez-aw'",
		"derivation" : "from H2372 (חָזָה)",
		"def" : "a window",
		"kjv" : "light"
	},
	"H4238" : {
		"lemma" : "מַחֲזִיאוֹת",
		"xlit" : "Machăzîyʼôwth",
		"pron" : "makh-az-ee-oth'",
		"derivation" : "feminine plural from H2372 (חָזָה); visions",
		"def" : "Machazioth, an Israelite",
		"kjv" : "Mahazioth"
	},
	"H4239" : {
		"lemma" : "מְחִי",
		"xlit" : "mᵉchîy",
		"pron" : "mekh-ee'",
		"derivation" : "from H4229 (מָחָה)",
		"def" : "a stroke, i.e. battering-ram",
		"kjv" : "engines"
	},
	"H4240" : {
		"lemma" : "מְחִידָא",
		"xlit" : "Mᵉchîydâʼ",
		"pron" : "mek-ee-daw'",
		"derivation" : "from H2330 (חוּד); junction",
		"def" : "Mechida, one of the Nethinim",
		"kjv" : "Mehida"
	},
	"H4241" : {
		"lemma" : "מִחְיָה",
		"xlit" : "michyâh",
		"pron" : "mikh-yaw'",
		"derivation" : "from H2421 (חָיָה)",
		"def" : "preservation of life; hence, sustenance; also the live flesh, i.e. the quick",
		"kjv" : "preserve life, quick, recover selves, reviving, sustenance, victuals"
	},
	"H4242" : {
		"lemma" : "מְחִיר",
		"xlit" : "mᵉchîyr",
		"pron" : "mekk-eer'",
		"derivation" : "from an unused root meaning to buy",
		"def" : "price, payment, wages",
		"kjv" : "gain, hire, price, sold, worth"
	},
	"H4243" : {
		"lemma" : "מְחִיר",
		"xlit" : "Mᵉchîyr",
		"pron" : "mekh-eer'",
		"derivation" : "the same as H4242 (מְחִיר); price",
		"def" : "Mechir, an Israelite",
		"kjv" : "Mehir"
	},
	"H4244" : {
		"lemma" : "מַחְלָה",
		"xlit" : "Machlâh",
		"pron" : "makh-law'",
		"derivation" : "from H2470 (חָלָה); sickness",
		"def" : "Machlah, the name apparently of two Israelitesses",
		"kjv" : "Mahlah"
	},
	"H4245" : {
		"lemma" : "מַחֲלֶה",
		"xlit" : "machăleh",
		"pron" : "makh-al-eh'",
		"derivation" : "or (feminine) מַחֲלָה; from H2470 (חָלָה)",
		"def" : "sickness",
		"kjv" : "disease, infirmity, sickness"
	},
	"H4246" : {
		"lemma" : "מְחֹלָה",
		"xlit" : "mᵉchôlâh",
		"pron" : "mek-o-law'",
		"derivation" : "feminine of H4234 (מָחוֹל)",
		"def" : "a dance",
		"kjv" : "company, dances(-cing)"
	},
	"H4247" : {
		"lemma" : "מְחִלָּה",
		"xlit" : "mᵉchillâh",
		"pron" : "mekh-il-law'",
		"derivation" : "from H2490 (חָלַל)",
		"def" : "a cavern (as if excavated)",
		"kjv" : "cave"
	},
	"H4248" : {
		"lemma" : "מַחְלוֹן",
		"xlit" : "Machlôwn",
		"pron" : "makh-lone'",
		"derivation" : "from H2470 (חָלָה); sick",
		"def" : "Machlon, an Israelite",
		"kjv" : "Mahlon"
	},
	"H4249" : {
		"lemma" : "מַחְלִי",
		"xlit" : "Machlîy",
		"pron" : "makh-lee'",
		"derivation" : "from H2470 (חָלָה); sick",
		"def" : "Machli, the name of two Israelites",
		"kjv" : "Mahli"
	},
	"H4250" : {
		"lemma" : "מַחְלִי",
		"xlit" : "Machlîy",
		"pron" : "makh-lee'",
		"derivation" : "patronymical from H4249 (מַחְלִי)",
		"def" : "a Machlite or (collectively) descendants of Machli",
		"kjv" : "Mahlites"
	},
	"H4251" : {
		"lemma" : "מַחְלֻי",
		"xlit" : "machluy",
		"pron" : "makh-loo'-ee",
		"derivation" : "from H2470 (חָלָה)",
		"def" : "a disease",
		"kjv" : "disease"
	},
	"H4252" : {
		"lemma" : "מַחֲלָף",
		"xlit" : "machălâph",
		"pron" : "makh-al-awf'",
		"derivation" : "from H2498 (חָלַף)",
		"def" : "a (sacrificial) knife (as gliding through the flesh)",
		"kjv" : "knife"
	},
	"H4253" : {
		"lemma" : "מַחְלָפָה",
		"xlit" : "machlâphâh",
		"pron" : "makh-law-faw'",
		"derivation" : "from H2498 (חָלַף)",
		"def" : "a ringlet of hair (as gliding over each other)",
		"kjv" : "lock"
	},
	"H4254" : {
		"lemma" : "מַחֲלָצָה",
		"xlit" : "machălâtsâh",
		"pron" : "makh-al-aw-tsaw'",
		"derivation" : "from H2502 (חָלַץ)",
		"def" : "a mantle (as easily drawn off)",
		"kjv" : "changeable suit of apparel, change of raiment"
	},
	"H4255" : {
		"lemma" : "מַחְלְקָה",
		"xlit" : "machlᵉqâh",
		"pron" : "makh-lek-aw'",
		"derivation" : "(Aramaic) corresponding to H4256 (מַחֲלֹקֶת)",
		"def" : "a section (of the Levites)",
		"kjv" : "course"
	},
	"H4256" : {
		"lemma" : "מַחֲלֹקֶת",
		"xlit" : "machălôqeth",
		"pron" : "makh-al-o'-keth",
		"derivation" : "from H2505 (חָלַק)",
		"def" : "a section (of Levites, people or soldiers)",
		"kjv" : "company, course, division, portion. See also H5555 (סֶלַע הַמַּחְלְקוֹת)"
	},
	"H4257" : {
		"lemma" : "מַחֲלַת",
		"xlit" : "machălath",
		"pron" : "makh-al-ath'",
		"derivation" : "from H2470 (חָלָה); sickness",
		"def" : "'Machalath', probably the title (initial word) of a popular song",
		"kjv" : "Mahalath"
	},
	"H4258" : {
		"lemma" : "מַחֲלַת",
		"xlit" : "Machălath",
		"pron" : "makh-al-ath'",
		"derivation" : "the same as H4257 (מַחֲלַת); sickness",
		"def" : "Machalath, the name of an Ishmaelitess and of an Israelitess",
		"kjv" : "Mahalath"
	},
	"H4259" : {
		"lemma" : "מְחֹלָתִי",
		"xlit" : "Mᵉchôlâthîy",
		"pron" : "mekh-o-law-thee'",
		"derivation" : "patrial from H65 (אָבֵל מְחוֹלָה)",
		"def" : "a Mecholathite or inhabitant of Abel-Mecholah",
		"kjv" : "Mecholathite"
	},
	"H4260" : {
		"lemma" : "מַחֲמָאָה",
		"xlit" : "machămâʼâh",
		"pron" : "makh-am-aw-aw'",
		"derivation" : "a denominative from H2529 (חֶמְאָה)",
		"def" : "something buttery (i.e. unctuous and pleasant), as (figuratively) flattery",
		"kjv" : "[idiom] than butter"
	},
	"H4261" : {
		"lemma" : "מַחְמָד",
		"xlit" : "machmâd",
		"pron" : "makh-mawd'",
		"derivation" : "from H2530 (חָמַד)",
		"def" : "delightful; hence, a delight, i.e. object of affection or desire",
		"kjv" : "beloved, desire, goodly, lovely, pleasant (thing)"
	},
	"H4262" : {
		"lemma" : "מַחְמֻד",
		"xlit" : "machmud",
		"pron" : "makh-mood'",
		"derivation" : "or מַחְמוּד; from H2530 (חָמַד)",
		"def" : "desired; hence, a valuable",
		"kjv" : "pleasant thing"
	},
	"H4263" : {
		"lemma" : "מַחְמָל",
		"xlit" : "machmâl",
		"pron" : "makh-mawl'",
		"derivation" : "from H2550 (חָמַל); (by paronomasia with H4261 (מַחְמָד)) delight",
		"def" : "properly, sympathy",
		"kjv" : "pitieth"
	},
	"H4264" : {
		"lemma" : "מַחֲנֶה",
		"xlit" : "machăneh",
		"pron" : "makh-an-eh'",
		"derivation" : "from H2583 (חָנָה)",
		"def" : "an encampment (of travellers or troops); hence, an army, whether literal (of soldiers) or figurative (of dancers, angels, cattle, locusts, stars; or even the sacred courts)",
		"kjv" : "army, band, battle, camp, company, drove, host, tents"
	},
	"H4265" : {
		"lemma" : "מַחֲנֵה־דָן",
		"xlit" : "Machănêh-Dân",
		"pron" : "makh-an-ay'-dawn",
		"derivation" : "from H4264 (מַחֲנֶה) and H1835 (דָּן); camp of Dan",
		"def" : "Machaneh-Dan, a place in Palestine",
		"kjv" : "Mahaneh-dan"
	},
	"H4266" : {
		"lemma" : "מַחֲנַיִם",
		"xlit" : "Machănayim",
		"pron" : "makh-an-ah'-yim",
		"derivation" : "dual of H4264 (מַחֲנֶה); double camp",
		"def" : "Machanajim, a place in Palestine",
		"kjv" : "Mahanaim"
	},
	"H4267" : {
		"lemma" : "מַחֲנַק",
		"xlit" : "machănaq",
		"pron" : "makh-an-ak'",
		"derivation" : "from H2614 (חָנַק)",
		"def" : "choking",
		"kjv" : "strangling"
	},
	"H4268" : {
		"lemma" : "מַחֲסֶה",
		"xlit" : "machăçeh",
		"pron" : "makh-as-eh'",
		"derivation" : "or מַחְסֶה; from H2620 (חָסָה)",
		"def" : "a shelter (literally or figuratively)",
		"kjv" : "hope, (place of) refuge, shelter, trust"
	},
	"H4269" : {
		"lemma" : "מַחְסוֹם",
		"xlit" : "machçôwm",
		"pron" : "makh-sohm'",
		"derivation" : "from H2629 (חָסַם)",
		"def" : "a muzzle",
		"kjv" : "bridle"
	},
	"H4270" : {
		"lemma" : "מַחְסוֹר",
		"xlit" : "machçôwr",
		"pron" : "makh-sore'",
		"derivation" : "or מַחְסֹר; from H2637 (חָסֵר)",
		"def" : "deficiency; hence, impoverishment",
		"kjv" : "lack, need, penury, poor, poverty, want"
	},
	"H4271" : {
		"lemma" : "מַחְסֵיָה",
		"xlit" : "Machçêyâh",
		"pron" : "makh-say-yaw'",
		"derivation" : "from H4268 (מַחֲסֶה) and H3050 (יָהּ); refuge of (i.e. in) Jah",
		"def" : "Machsejah, an Israelite",
		"kjv" : "Maaseiah"
	},
	"H4272" : {
		"lemma" : "מָחַץ",
		"xlit" : "mâchats",
		"pron" : "maw-khats'",
		"derivation" : "a primitive root",
		"def" : "to dash asunder; by implication, to crush, smash or violently plunge; figuratively, to subdue or destroy",
		"kjv" : "dip, pierce (through), smite (through), strike through, wound"
	},
	"H4273" : {
		"lemma" : "מַחַץ",
		"xlit" : "machats",
		"pron" : "makh'-ats",
		"derivation" : "from H4272 (מָחַץ)",
		"def" : "a contusion",
		"kjv" : "stroke"
	},
	"H4274" : {
		"lemma" : "מַחְצֵב",
		"xlit" : "machtsêb",
		"pron" : "makh-tsabe'",
		"derivation" : "from H2672 (חָצַב)",
		"def" : "properly, a hewing; concretely, a quarry",
		"kjv" : "hewed(-n)"
	},
	"H4275" : {
		"lemma" : "מֶחֱצָה",
		"xlit" : "mechĕtsâh",
		"pron" : "mekh-ets-aw'",
		"derivation" : "from H2673 (חָצָה)lemma חָעָה ayin, corrected to חָצָה",
		"def" : "a halving",
		"kjv" : "half"
	},
	"H4276" : {
		"lemma" : "מַחֲצִית",
		"xlit" : "machătsîyth",
		"pron" : "makh-ats-eeth'",
		"derivation" : "from H2673 (חָצָה)lemma חָעָה ayin, corrected to חָצָה",
		"def" : "a halving or the middle",
		"kjv" : "half (so much), mid(-day)"
	},
	"H4277" : {
		"lemma" : "מָחַק",
		"xlit" : "mâchaq",
		"pron" : "maw-khak'",
		"derivation" : "a primitive root",
		"def" : "to crush",
		"kjv" : "smite off"
	},
	"H4278" : {
		"lemma" : "מֶחְקָר",
		"xlit" : "mechqâr",
		"pron" : "mekh-kawr'",
		"derivation" : "from H2713 (חָקַר)",
		"def" : "properly, scrutinized, i.e. (by implication) a recess",
		"kjv" : "deep place"
	},
	"H4279" : {
		"lemma" : "מָחָר",
		"xlit" : "mâchâr",
		"pron" : "maw-khar'",
		"derivation" : "probably from H309 (אָחַר)",
		"def" : "properly, deferred, i.e. the morrow; usually (adverbially) tomorrow; indefinitely, hereafter",
		"kjv" : "time to come, tomorrow"
	},
	"H4280" : {
		"lemma" : "מַחֲרָאָה",
		"xlit" : "machărâʼâh",
		"pron" : "makh-ar-aw-aw'",
		"derivation" : "from the same as H2716 (חֶרֶא)",
		"def" : "a sink",
		"kjv" : "draught house"
	},
	"H4281" : {
		"lemma" : "מַחֲרֵשָׁה",
		"xlit" : "machărêshâh",
		"pron" : "makh-ar-ay-shaw'",
		"derivation" : "from H2790 (חָרַשׁ)",
		"def" : "probably a pick-axe",
		"kjv" : "mattock"
	},
	"H4282" : {
		"lemma" : "מַחֲרֶשֶׁת",
		"xlit" : "machăresheth",
		"pron" : "makh-ar-eh'-sheth",
		"derivation" : "from H2790 (חָרַשׁ)",
		"def" : "probably a hoe",
		"kjv" : "share"
	},
	"H4283" : {
		"lemma" : "מׇחֳרָת",
		"xlit" : "mochŏrâth",
		"pron" : "mokh-or-awth'",
		"derivation" : "or מׇחֳרָתָם; (1 Samuel 30:17), feminine from the same as H4279 (מָחָר)",
		"def" : "the morrow or (adverbially) tomorrow",
		"kjv" : "morrow, next day"
	},
	"H4284" : {
		"lemma" : "מַחֲשָׁבָה",
		"xlit" : "machăshâbâh",
		"pron" : "makh-ash-aw-baw'",
		"derivation" : "or מַחֲשֶׁבֶת; from H2803 (חָשַׁב)",
		"def" : "a contrivance, i.e. (concretely) a texture, machine, or (abstractly) intention, plan (whether bad, a plot; or good, advice)",
		"kjv" : "cunning (work), curious work, device(-sed), imagination, invented, means, purpose, thought"
	},
	"H4285" : {
		"lemma" : "מַחְשָׁךְ",
		"xlit" : "machshâk",
		"pron" : "makh-shawk'",
		"derivation" : "from H2821 (חָשַׁךְ)",
		"def" : "darkness; concretely, a dark place",
		"kjv" : "dark(-ness, place)"
	},
	"H4286" : {
		"lemma" : "מַחְשֹׂף",
		"xlit" : "machsôph",
		"pron" : "makh-sofe'",
		"derivation" : "from H2834 (חָשַׂף)",
		"def" : "a peeling",
		"kjv" : "made appear"
	},
	"H4287" : {
		"lemma" : "מַחַת",
		"xlit" : "Machath",
		"pron" : "makh'-ath",
		"derivation" : "probably from H4229 (מָחָה); erasure",
		"def" : "Machath, the name of two Israelites",
		"kjv" : "Mahath"
	},
	"H4288" : {
		"lemma" : "מְחִתָּה",
		"xlit" : "mᵉchittâh",
		"pron" : "mekh-it-taw'",
		"derivation" : "from H2846 (חָתָה)",
		"def" : "properly, a dissolution; concretely, a ruin, or (abstractly) consternation",
		"kjv" : "destruction, dismaying, ruin, terror"
	},
	"H4289" : {
		"lemma" : "מַחְתָּה",
		"xlit" : "machtâh",
		"pron" : "makh-taw'",
		"derivation" : "the same as H4288 (מְחִתָּה) in the sense of removal",
		"def" : "a pan for live coals",
		"kjv" : "censer, firepan, snuffdish"
	},
	"H4290" : {
		"lemma" : "מַחְתֶּרֶת",
		"xlit" : "machtereth",
		"pron" : "makh-teh'-reth",
		"derivation" : "from H2864 (חָתַר)",
		"def" : "a burglary; figuratively, unexpected examination",
		"kjv" : "breaking up, secret search"
	},
	"H4291" : {
		"lemma" : "מְטָא",
		"xlit" : "mᵉṭâʼ",
		"pron" : "met-aw'",
		"derivation" : "(Aramaic) or מְטָה; (Aramaic), apparently corresponding to H4672 (מָצָא) in the intransitive sense of being found present",
		"def" : "to arrive, extend or happen",
		"kjv" : "come, reach"
	},
	"H4292" : {
		"lemma" : "מַטְאֲטֵא",
		"xlit" : "maṭʼăṭêʼ",
		"pron" : "mat-at-ay'",
		"derivation" : "apparently a denominative from H2916 (טִיט)",
		"def" : "a broom (as removing dirt (compare English 'to dust', i.e. remove dust))",
		"kjv" : "besom"
	},
	"H4293" : {
		"lemma" : "מַטְבֵּחַ",
		"xlit" : "maṭbêach",
		"pron" : "mat-bay'-akh",
		"derivation" : "from H2873 (טָבַח)",
		"def" : "slaughter",
		"kjv" : "slaughter"
	},
	"H4294" : {
		"lemma" : "מַטֶּה",
		"xlit" : "maṭṭeh",
		"pron" : "mat-teh'",
		"derivation" : "or (feminine) מַטָּה; from H5186 (נָטָה)",
		"def" : "a branch (as extending); figuratively, a tribe; also a rod, whether for chastising (figuratively, correction), ruling (a sceptre), throwing (a lance), or walking (a staff; figuratively, a support of life, e.g. bread)",
		"kjv" : "rod, staff, tribe"
	},
	"H4295" : {
		"lemma" : "מַטָּה",
		"xlit" : "maṭṭâh",
		"pron" : "mat'-taw",
		"derivation" : "from H5786 (עָוַר) with directive enclitic appended",
		"def" : "downward, below or beneath; often adverbially with or without prefixes",
		"kjv" : "beneath, down(-ward), less, very low, under(-neath)"
	},
	"H4296" : {
		"lemma" : "מִטָּה",
		"xlit" : "miṭṭâh",
		"pron" : "mit-taw'",
		"derivation" : "from H5186 (נָטָה)",
		"def" : "a bed (as extended) forsleeping or eating; by analogy, a sofa, litter or bier",
		"kjv" : "bed(-chamber), bier"
	},
	"H4297" : {
		"lemma" : "מֻטֶּה",
		"xlit" : "muṭṭeh",
		"pron" : "moot-teh'",
		"derivation" : "from H5186 (נָטָה)",
		"def" : "a stretching, i.e. distortion (figuratively, iniquity)",
		"kjv" : "perverseness"
	},
	"H4298" : {
		"lemma" : "מֻטָּה",
		"xlit" : "muṭṭâh",
		"pron" : "moot-taw'",
		"derivation" : "from H5186 (נָטָה)",
		"def" : "expansion",
		"kjv" : "stretching out"
	},
	"H4299" : {
		"lemma" : "מַטְוֶה",
		"xlit" : "maṭveh",
		"pron" : "mat-veh'",
		"derivation" : "from H2901 (טָוָה)",
		"def" : "something spun",
		"kjv" : "spun"
	},
	"H4300" : {
		"lemma" : "מְטִיל",
		"xlit" : "mᵉṭîyl",
		"pron" : "met-eel'",
		"derivation" : "from H2904 (טוּל) in the sense of hammering out",
		"def" : "an iron bar (as forged)",
		"kjv" : "bar"
	},
	"H4301" : {
		"lemma" : "מַטְמוֹן",
		"xlit" : "maṭmôwn",
		"pron" : "mat-mone'",
		"derivation" : "or מַטְמֹן; or מַטְמֻן; from H2934 (טָמַן)",
		"def" : "a secret storehouse; hence, a secreted valuable (buried); generally money",
		"kjv" : "hidden riches, (hid) treasure(-s)"
	},
	"H4302" : {
		"lemma" : "מַטָּע",
		"xlit" : "maṭṭâʻ",
		"pron" : "mat-taw'",
		"derivation" : "from H5193 (נָטַע)",
		"def" : "something planted, i.e. the place (a garden or vineyard), or the thing (a plant, figuratively or men); by implication, the act, planting",
		"kjv" : "plant(-ation, -ing)"
	},
	"H4303" : {
		"lemma" : "מַטְעַם",
		"xlit" : "maṭʻam",
		"pron" : "mat-am'",
		"derivation" : "or (feminine) מַטְעַמָּה; from H2938 (טָעַם)",
		"def" : "a delicacy",
		"kjv" : "dainty (meat), savoury meat"
	},
	"H4304" : {
		"lemma" : "מִטְפַּחַת",
		"xlit" : "miṭpachath",
		"pron" : "mit-pakh'-ath",
		"derivation" : "from H2946 (טָפַח)",
		"def" : "a wide cloak (for a woman)",
		"kjv" : "vail, wimple"
	},
	"H4305" : {
		"lemma" : "מָטַר",
		"xlit" : "mâṭar",
		"pron" : "maw-tar'",
		"derivation" : "a primitive root",
		"def" : "to rain",
		"kjv" : "(cause to) rain (upon)"
	},
	"H4306" : {
		"lemma" : "מָטַר",
		"xlit" : "mâṭar",
		"pron" : "maw-tawr'",
		"derivation" : "from H4305 (מָטַר)",
		"def" : "rain",
		"kjv" : "rain"
	},
	"H4307" : {
		"lemma" : "מַטָּרָא",
		"xlit" : "maṭṭârâʼ",
		"pron" : "mat-taw-raw'",
		"derivation" : "or מַטָּרָה; from H5201 (נָטַר)",
		"def" : "a jail (as a guard-house); also an aim (as being closely watched)",
		"kjv" : "mark, prison"
	},
	"H4308" : {
		"lemma" : "מַטְרֵד",
		"xlit" : "Maṭrêd",
		"pron" : "mat-rade'",
		"derivation" : "from H2956 (טָרַד); propulsive",
		"def" : "Matred, an Edomitess",
		"kjv" : "Matred"
	},
	"H4309" : {
		"lemma" : "מַטְרִי",
		"xlit" : "Maṭrîy",
		"pron" : "mat-ree'",
		"derivation" : "from H4305 (מָטַר); rainy",
		"def" : "Matri, an Israelite",
		"kjv" : "Matri"
	},
	"H4310" : {
		"lemma" : "מִי",
		"xlit" : "mîy",
		"pron" : "me",
		"derivation" : "an interrogative pronoun of persons, as H4100 (מָה) is of things",
		"def" : "who? (occasionally, by a peculiar idiom, of things); also (indefinitely) whoever; often used in oblique construction with prefix or suffix",
		"kjv" : "any (man), [idiom] he, [idiom] him, [phrase] O that! what, which, who(-m, -se, -soever), [phrase] would to God"
	},
	"H4311" : {
		"lemma" : "מֵידְבָא",
		"xlit" : "Mêydᵉbâʼ",
		"pron" : "may-deb-aw'",
		"derivation" : "from H4325 (מַיִם) and H1679 (דֹּבֶא); water of quiet",
		"def" : "Medeba, a place in Palestine",
		"kjv" : "Medeba"
	},
	"H4312" : {
		"lemma" : "מֵידָד",
		"xlit" : "Mêydâd",
		"pron" : "may-dawd'",
		"derivation" : "from H3032 (יָדַד) in the sense of loving; affectionate",
		"def" : "Medad, an Israelite",
		"kjv" : "Medad"
	},
	"H4313" : {
		"lemma" : "מֵי הַיַּרְקוֹן",
		"xlit" : "Mêy hay-Yarqôwn",
		"pron" : "may hah'-ee-yar-kone'",
		"derivation" : "from H4325 (מַיִם) and H3420 (יֵרָקוֹן) with the art. interposed; water of the yellowness",
		"def" : "Me-haj-Jarkon, a place in Palestine",
		"kjv" : "Me-jarkon"
	},
	"H4314" : {
		"lemma" : "מֵי זָהָב",
		"xlit" : "Mêy Zâhâb",
		"pron" : "may zaw-hawb'",
		"derivation" : "from H4325 (מַיִם) and H2091 (זָהָב), water of gold",
		"def" : "Me-Zahab, an Edomite",
		"kjv" : "Mezahab"
	},
	"H4315" : {
		"lemma" : "מֵיטָב",
		"xlit" : "mêyṭâb",
		"pron" : "may-tawb'",
		"derivation" : "from H3190 (יָטַב)",
		"def" : "the best part",
		"kjv" : "best"
	},
	"H4316" : {
		"lemma" : "מִיכָא",
		"xlit" : "Mîykâʼ",
		"pron" : "mee-kaw'",
		"derivation" : "a variation for H4318 (מִיכָה)",
		"def" : "Mica, the name of two Israelites",
		"kjv" : "Micha"
	},
	"H4317" : {
		"lemma" : "מִיכָאֵל",
		"xlit" : "Mîykâʼêl",
		"pron" : "me-kaw-ale'",
		"derivation" : "from H4310 (מִי) and (the prefix derivative from) H3588 (כִּי) and H410 (אֵל); who (is) like God?",
		"def" : "Mikael, the name of an archangel and of nine Israelites",
		"kjv" : "Michael"
	},
	"H4318" : {
		"lemma" : "מִיכָה",
		"xlit" : "Mîykâh",
		"pron" : "mee-kaw'",
		"derivation" : "an abbreviated form of H4320 (מִיכָיָה)",
		"def" : "Micah, the name of seven Israelites",
		"kjv" : "Micah, Micaiah, Michah"
	},
	"H4319" : {
		"lemma" : "מִיכָהוּ",
		"xlit" : "Mîykâhûw",
		"pron" : "me-kaw'-hoo",
		"derivation" : "a contr. for H4321 (מִיכָיְהוּ)",
		"def" : "Mikehu, an Israelite prophet",
		"kjv" : "Micaiah (2 Chronicles 18:8)"
	},
	"H4320" : {
		"lemma" : "מִיכָיָה",
		"xlit" : "Mîykâyâh",
		"pron" : "me-kaw-yaw'",
		"derivation" : "from H4310 (מִי) and (the prefix derivative from) H3588 (כִּי) and H3050 (יָהּ); who (is) like Jah?",
		"def" : "Micajah, the name of two Israelites",
		"kjv" : "Micah, Michaiah. Compare H4318 (מִיכָה)"
	},
	"H4321" : {
		"lemma" : "מִיכָיְהוּ",
		"xlit" : "Mîykâyᵉhûw",
		"pron" : "me-kaw-yeh-hoo'",
		"derivation" : "or מִכָיְהוּ; (Jeremiah 36:11), abbreviated form for H4322 (מִיכָיָהוּ)",
		"def" : "Mikajah, the name of three Israelites",
		"kjv" : "Micah, Micaiah, Michaiah"
	},
	"H4322" : {
		"lemma" : "מִיכָיָהוּ",
		"xlit" : "Mîykâyâhûw",
		"pron" : "me-kaw-yaw'-hoo",
		"derivation" : "for H4320 (מִיכָיָה)",
		"def" : "Mikajah, the name of an Israelite and an Israelitess",
		"kjv" : "Michaiah"
	},
	"H4323" : {
		"lemma" : "מִיכָל",
		"xlit" : "mîykâl",
		"pron" : "me-kawl'",
		"derivation" : "from H3201 (יָכֹל)",
		"def" : "properly, a container, i.e. a streamlet",
		"kjv" : "brook"
	},
	"H4324" : {
		"lemma" : "מִיכָל",
		"xlit" : "Mîykâl",
		"pron" : "me-kawl'",
		"derivation" : "apparently the same as H4323 (מִיכָל); revulet",
		"def" : "Mikal, Saul's daughter",
		"kjv" : "Michal"
	},
	"H4325" : {
		"lemma" : "מַיִם",
		"xlit" : "mayim",
		"pron" : "mah'-yim",
		"derivation" : "dual of a primitive noun (but used in a singular sense)",
		"def" : "water; figuratively, juice; by euphemism, urine, semen",
		"kjv" : "[phrase] piss, wasting, water(-ing, (-course, -flood, -spring))"
	},
	"H4326" : {
		"lemma" : "מִיָּמִן",
		"xlit" : "Mîyâmin",
		"pron" : "me-yaw-meem'",
		"derivation" : "a form for H4509 (מִנְיָמִין)",
		"def" : "Mijamin, the name of three Israelites",
		"kjv" : "Miamin, Mijamin"
	},
	"H4327" : {
		"lemma" : "מִין",
		"xlit" : "mîyn",
		"pron" : "meen",
		"derivation" : "from an unused root meaning to portion out",
		"def" : "a sort, i.e. species",
		"kjv" : "kind. Compare H4480 (מִן)"
	},
	"H4328" : {
		"lemma" : "מְיֻסָּדָה",
		"xlit" : "mᵉyuççâdâh",
		"pron" : "meh-yoos-saw-daw'",
		"derivation" : "properly, feminine passive participle of H3245 (יָסַד)",
		"def" : "something founded, i.e. a foundation",
		"kjv" : "foundation"
	},
	"H4329" : {
		"lemma" : "מֵיסָךְ",
		"xlit" : "mêyçâk",
		"pron" : "may-sawk'",
		"derivation" : "from H5526 (סָכַךְ)",
		"def" : "a portico (as covered)",
		"kjv" : "covert"
	},
	"H4330" : {
		"lemma" : "מִיץ",
		"xlit" : "mîyts",
		"pron" : "meets",
		"derivation" : "from H4160 (מוּץ)",
		"def" : "pressure",
		"kjv" : "churning, forcing, wringing"
	},
	"H4331" : {
		"lemma" : "מֵישָׁא",
		"xlit" : "Mêyshâʼ",
		"pron" : "may-shaw'",
		"derivation" : "from H4185 (מוּשׁ); departure",
		"def" : "Mesha, a place in Arabia; also an Israelite",
		"kjv" : "Mesha"
	},
	"H4332" : {
		"lemma" : "מִישָׁאֵל",
		"xlit" : "Mîyshâʼêl",
		"pron" : "mee-shaw-ale'",
		"derivation" : "from H4310 (מִי) and H410 (אֵל) with the abbreviated form insep. relatively (see H834 (אֲשֶׁר)) interposed; who (is) what God (is) ?",
		"def" : "Mishael, the name of three Israelites",
		"kjv" : "Mishael"
	},
	"H4333" : {
		"lemma" : "מִישָׁאֵל",
		"xlit" : "Mîyshâʼêl",
		"pron" : "mee-shaw-ale'",
		"derivation" : "(Aramaic) corresponding to H4332 (מִישָׁאֵל)",
		"def" : "Mishael, an Israelite",
		"kjv" : "Mishael"
	},
	"H4334" : {
		"lemma" : "מִישׁוֹר",
		"xlit" : "mîyshôwr",
		"pron" : "mee-shore'",
		"derivation" : "or מִישֹׁר; from H3474 (יָשַׁר)",
		"def" : "a level, i.e. a plain (often used (with the article prefix) as a proper name of certain districts); figuratively, concord; also straightness, i.e. (figuratively) justice (sometimes adverbially, justly)",
		"kjv" : "equity, even place, plain, right(-eously), (made) straight, uprightness"
	},
	"H4335" : {
		"lemma" : "מֵישַׁךְ",
		"xlit" : "Mêyshak",
		"pron" : "may-shak'",
		"derivation" : "borrowed from H4336 (מֵישַׁךְ)",
		"def" : "Meshak, an Israelite",
		"kjv" : "Meshak"
	},
	"H4336" : {
		"lemma" : "מֵישַׁךְ",
		"xlit" : "Mêyshak",
		"pron" : "may-shak'",
		"derivation" : "(Aramaic) of foreign origin and doubtful significance; name of H4333 (מִישָׁאֵל)",
		"def" : "Meshak, the Babylonian",
		"kjv" : "Meshak"
	},
	"H4337" : {
		"lemma" : "מֵישָׁע",
		"xlit" : "Mêyshâʻ",
		"pron" : "may-shah'",
		"derivation" : "from H3467 (יָשַׁע); safety",
		"def" : "Mesha, an Israelite",
		"kjv" : "Mesha"
	},
	"H4338" : {
		"lemma" : "מֵישַׁע",
		"xlit" : "Mêyshaʻ",
		"pron" : "may-shaw'",
		"derivation" : "a variation for H4337 (מֵישָׁע); safety",
		"def" : "Mesha, a Moabite",
		"kjv" : "Mesha"
	},
	"H4339" : {
		"lemma" : "מֵישָׁר",
		"xlit" : "mêyshâr",
		"pron" : "may-shawr'",
		"derivation" : "from H3474 (יָשַׁר)",
		"def" : "evenness, i.e. (figuratively) prosperity or concord; also straightness, i.e. (figuratively) rectitude (only in plural with singular sense; often adverbially)",
		"kjv" : "agreement, aright, that are equal, equity, (things that are) right(-eously, things), sweetly, upright(-ly, -ness)"
	},
	"H4340" : {
		"lemma" : "מֵיתָר",
		"xlit" : "mêythâr",
		"pron" : "may-thar'",
		"derivation" : "from H3498 (יָתַר); (compare H3499 (יֶתֶר))",
		"def" : "a cord (of a tent); or the string (of a bow)",
		"kjv" : "cord, string"
	},
	"H4341" : {
		"lemma" : "מַכְאֹב",
		"xlit" : "makʼôb",
		"pron" : "mak-obe'",
		"derivation" : "sometimes מַכְאוֹב; also (feminine Isaiah 53:3) מַכְאֹבָה; from H3510 (כָּאַב)",
		"def" : "anguish or (figuratively) affliction",
		"kjv" : "grief, pain, sorrow"
	},
	"H4342" : {
		"lemma" : "מַכְבִּיר",
		"xlit" : "makbîyr",
		"pron" : "mak-beer'",
		"derivation" : "transitive participle of H3527 (כָּבַר)",
		"def" : "plenty",
		"kjv" : "abundance"
	},
	"H4343" : {
		"lemma" : "מַכְבֵּנָא",
		"xlit" : "Makbênâʼ",
		"pron" : "mak-bay-naw'",
		"derivation" : "from the same as H3522 (כַּבּוֹן); knoll",
		"def" : "Macbena, a place in Palestine settled by him",
		"kjv" : "Machbenah"
	},
	"H4344" : {
		"lemma" : "מַכְבַּנַּי",
		"xlit" : "Makbannay",
		"pron" : "mak-ban-nah'-ee",
		"derivation" : "patrial from H4343 (מַכְבֵּנָא)",
		"def" : "a Macbannite or native of Macbena",
		"kjv" : "Machbanai"
	},
	"H4345" : {
		"lemma" : "מַכְבֵּר",
		"xlit" : "makbêr",
		"pron" : "mak-bare'",
		"derivation" : "from H3527 (כָּבַר) in the sense of covering (compare H3531 (כְּבָרָה))",
		"def" : "a grate",
		"kjv" : "grate"
	},
	"H4346" : {
		"lemma" : "מַכְבָּר",
		"xlit" : "makbâr",
		"pron" : "mak-bawr'",
		"derivation" : "from H3527 (כָּבַר) in the sense of covering; (compare H4345 (מַכְבֵּר))",
		"def" : "a cloth (as netted)",
		"kjv" : "thick cloth"
	},
	"H4347" : {
		"lemma" : "מַכָּה",
		"xlit" : "makkâh",
		"pron" : "mak-kaw'",
		"derivation" : "or (masculine) מַכֶּה; (plural only) from H5221 (נָכָה); a blow (in 2 Chronicles 2:10, of the flail); by implication",
		"def" : "a wound; figuratively, carnage, also pestilence",
		"kjv" : "beaten, blow, plague, slaughter, smote, [idiom] sore, stripe, stroke, wound(-ed)"
	},
	"H4348" : {
		"lemma" : "מִכְוָה",
		"xlit" : "mikvâh",
		"pron" : "mik-vaw'",
		"derivation" : "from H3554 (כָּוָה)",
		"def" : "a burn",
		"kjv" : "that burneth, burning"
	},
	"H4349" : {
		"lemma" : "מָכוֹן",
		"xlit" : "mâkôwn",
		"pron" : "maw-kone'",
		"derivation" : "from H3559 (כּוּן)",
		"def" : "properly, a fixture, i.e. a basis; generally a place, especially as an abode",
		"kjv" : "foundation, habitation, (dwelling-, settled) place"
	},
	"H4350" : {
		"lemma" : "מְכוֹנָה",
		"xlit" : "mᵉkôwnâh",
		"pron" : "mek-o-naw'",
		"derivation" : "or מְכֹנָה; feminine of H4349 (מָכוֹן)",
		"def" : "a pedestal, also a spot",
		"kjv" : "base"
	},
	"H4351" : {
		"lemma" : "מְכוּרָה",
		"xlit" : "mᵉkûwrâh",
		"pron" : "mek-oo-raw'",
		"derivation" : "or מְכֹרָה; from the same as H3564 (כּוּר) in the sense of dipping",
		"def" : "origin (as if a mine)",
		"kjv" : "birth, habitation, nativity"
	},
	"H4352" : {
		"lemma" : "מָכִי",
		"xlit" : "Mâkîy",
		"pron" : "maw-kee'",
		"derivation" : "probably from H4134 (מוּךְ); pining",
		"def" : "Maki, an Israelite",
		"kjv" : "Machi"
	},
	"H4353" : {
		"lemma" : "מָכִיר",
		"xlit" : "Mâkîyr",
		"pron" : "maw-keer'",
		"derivation" : "from H4376 (מָכַר); salesman",
		"def" : "Makir, an Israelite",
		"kjv" : "Machir"
	},
	"H4354" : {
		"lemma" : "מָכִירִי",
		"xlit" : "Mâkîyrîy",
		"pron" : "maw-kee-ree'",
		"derivation" : "patronymical from H4353 (מָכִיר)",
		"def" : "a Makirite or descendant of Makir",
		"kjv" : "of Machir"
	},
	"H4355" : {
		"lemma" : "מָכַךְ",
		"xlit" : "mâkak",
		"pron" : "maw-kak'",
		"derivation" : "a primitive root",
		"def" : "to tumble (in ruins); figuratively, to perish",
		"kjv" : "be brought low, decay"
	},
	"H4356" : {
		"lemma" : "מִכְלָאָה",
		"xlit" : "miklâʼâh",
		"pron" : "mik-law-aw'",
		"derivation" : "or מִכְלָה; from H3607 (כָּלָא)",
		"def" : "a pen (for flocks)",
		"kjv" : "(sheep-)fold. Compare H4357 (מִכְלָה)"
	},
	"H4357" : {
		"lemma" : "מִכְלָה",
		"xlit" : "miklâh",
		"pron" : "mik-law'",
		"derivation" : "from H3615 (כָּלָה)",
		"def" : "completion (in plural concrete adverbial, wholly)",
		"kjv" : "perfect. Compare H4356 (מִכְלָאָה)"
	},
	"H4358" : {
		"lemma" : "מִכְלוֹל",
		"xlit" : "miklôwl",
		"pron" : "mik-lole'",
		"derivation" : "from H3634 (כָּלַל)",
		"def" : "perfection (i.e. concrete adverbial, splendidly)",
		"kjv" : "most gorgeously, all sorts"
	},
	"H4359" : {
		"lemma" : "מִכְלָל",
		"xlit" : "miklâl",
		"pron" : "mik-lawl'",
		"derivation" : "from H3634 (כָּלַל)",
		"def" : "perfection (of beauty)",
		"kjv" : "perfection"
	},
	"H4360" : {
		"lemma" : "מִכְלֻל",
		"xlit" : "miklul",
		"pron" : "mik-lool'",
		"derivation" : "from H3634 (כָּלַל)",
		"def" : "something perfect, i.e. a splendid garment",
		"kjv" : "all sorts"
	},
	"H4361" : {
		"lemma" : "מַכֹּלֶת",
		"xlit" : "makkôleth",
		"pron" : "mak-ko'-leth",
		"derivation" : "from H398 (אָכַל)",
		"def" : "nourishment",
		"kjv" : "food"
	},
	"H4362" : {
		"lemma" : "מִכְמַן",
		"xlit" : "mikman",
		"pron" : "mik-man'",
		"derivation" : "from the same as H3646 (כַּמֹּן) in the sense of hiding",
		"def" : "treasure (as hidden)",
		"kjv" : "treasure"
	},
	"H4363" : {
		"lemma" : "מִכְמָס",
		"xlit" : "Mikmâç",
		"pron" : "mik-maws'",
		"derivation" : "(Ezra 2:2 or מִכְמָשׁ; or מִכְמַשׁ; (Nehemiah 11:31), from H3647 (כָּמַס); hidden",
		"def" : "Mikmas or Mikmash, a place in Palestine",
		"kjv" : "Mikmas, Mikmash"
	},
	"H4364" : {
		"lemma" : "מַכְמָר",
		"xlit" : "makmâr",
		"pron" : "mak-mawr'",
		"derivation" : "or מִכְמֹר; from H3648 (כָּמַר) in the sense of blackening by heat",
		"def" : "a (hunter's) net (as dark from concealment)",
		"kjv" : "net"
	},
	"H4365" : {
		"lemma" : "מִכְמֶרֶת",
		"xlit" : "mikmereth",
		"pron" : "mik-meh'-reth",
		"derivation" : "or מִכְמֹרֶת; feminine of H4364 (מַכְמָר)",
		"def" : "a (fisher's) net",
		"kjv" : "drag, net"
	},
	"H4366" : {
		"lemma" : "מִכְמְתָת",
		"xlit" : "Mikmᵉthâth",
		"pron" : "mik-meth-awth'",
		"derivation" : "apparently from an unused root meaning to hide; concealment",
		"def" : "Mikmethath, a place in Palestine",
		"kjv" : "Michmethath"
	},
	"H4367" : {
		"lemma" : "מַכְנַדְבַי",
		"xlit" : "Maknadbay",
		"pron" : "mak-nad-bah'-ee",
		"derivation" : "from H4100 (מָה) and H5068 (נָדַב) with a particle interposed; what (is) like (a) liberal (man) ?",
		"def" : "Maknadbai, an Israelite",
		"kjv" : "Machnadebai"
	},
	"H4368" : {
		"lemma" : "מְכֹנָה",
		"xlit" : "Mᵉkônâh",
		"pron" : "mek-o-naw'",
		"derivation" : "the same as H4350 (מְכוֹנָה); a base",
		"def" : "Mekonah, a place in Palestine",
		"kjv" : "Mekonah"
	},
	"H4369" : {
		"lemma" : "מְכֻנָה",
		"xlit" : "mᵉkunâh",
		"pron" : "mek-oo-naw'",
		"derivation" : "the same as H4350 (מְכוֹנָה)",
		"def" : "a spot",
		"kjv" : "base"
	},
	"H4370" : {
		"lemma" : "מִכְנָס",
		"xlit" : "miknâç",
		"pron" : "mik-nawce'",
		"derivation" : "from H3647 (כָּמַס) in the sense of hiding",
		"def" : "(only in dual) drawers (from concealing the private parts)",
		"kjv" : "breeches"
	},
	"H4371" : {
		"lemma" : "מֶכֶס",
		"xlit" : "mekeç",
		"pron" : "meh'-kes",
		"derivation" : "probably from an unused root meaning to enumerate",
		"def" : "an assessment (as based upon a census)",
		"kjv" : "tribute"
	},
	"H4372" : {
		"lemma" : "מִכְסֶה",
		"xlit" : "mikçeh",
		"pron" : "mik-seh'",
		"derivation" : "from H3680 (כָּסָה)",
		"def" : "a covering, i.e. weatherboarding",
		"kjv" : "covering"
	},
	"H4373" : {
		"lemma" : "מִכְסָה",
		"xlit" : "mikçâh",
		"pron" : "mik-saw'",
		"derivation" : "feminine of H4371 (מֶכֶס)",
		"def" : "an enumeration; by implication, a valuation",
		"kjv" : "number, worth"
	},
	"H4374" : {
		"lemma" : "מְכַסֶּה",
		"xlit" : "mᵉkaççeh",
		"pron" : "mek-as-seh'",
		"derivation" : "from H3680 (כָּסָה)",
		"def" : "a covering, i.e. garment; specifically, a coverlet (for a bed), an awning (from the sun); also the omentum (as covering the intestines)",
		"kjv" : "clothing, to cover, that which covereth"
	},
	"H4375" : {
		"lemma" : "מַכְפֵּלָה",
		"xlit" : "Makpêlâh",
		"pron" : "mak-pay-law'",
		"derivation" : "from H3717 (כָּפַל); a fold",
		"def" : "Makpelah, a place in Palestine",
		"kjv" : "Machpelah"
	},
	"H4376" : {
		"lemma" : "מָכַר",
		"xlit" : "mâkar",
		"pron" : "maw-kar'",
		"derivation" : "a primitive root",
		"def" : "to sell, literally (as merchandise, a daughter in marriage, into slavery), or figuratively (to surrender)",
		"kjv" : "[idiom] at all, sell (away, -er, self)"
	},
	"H4377" : {
		"lemma" : "מֶכֶר",
		"xlit" : "meker",
		"pron" : "meh'-ker",
		"derivation" : "from H4376 (מָכַר)",
		"def" : "merchandise; also value",
		"kjv" : "pay, price, ware"
	},
	"H4378" : {
		"lemma" : "מַכָּר",
		"xlit" : "makkâr",
		"pron" : "mak-kawr'",
		"derivation" : "from H5234 (נָכַר)",
		"def" : "an acquaintance",
		"kjv" : "acquaintance"
	},
	"H4379" : {
		"lemma" : "מִכְרֶה",
		"xlit" : "mikreh",
		"pron" : "mik-reh'",
		"derivation" : "from H3738 (כָּרָה)",
		"def" : "a pit (for salt)",
		"kjv" : "(salt-) pit"
	},
	"H4380" : {
		"lemma" : "מְכֵרָה",
		"xlit" : "mᵉkêrâh",
		"pron" : "mek-ay-raw'",
		"derivation" : "probably from the same as H3564 (כּוּר) in the sense of stabbing",
		"def" : "a sword",
		"kjv" : "habitation"
	},
	"H4381" : {
		"lemma" : "מִכְרִי",
		"xlit" : "Mikrîy",
		"pron" : "mik-ree'",
		"derivation" : "from H4376 (מָכַר); salesman",
		"def" : "Mikri, an Israelite",
		"kjv" : "Michri"
	},
	"H4382" : {
		"lemma" : "מְכֵרָתִי",
		"xlit" : "Mᵉkêrâthîy",
		"pron" : "mek-ay-raw-thee'",
		"derivation" : "patrial from an unused name (the same as H4380 (מְכֵרָה)) of a place in Palestine",
		"def" : "a Mekerathite, or inhabitant of Mekerah",
		"kjv" : "Mecherathite"
	},
	"H4383" : {
		"lemma" : "מִכְשׁוֹל",
		"xlit" : "mikshôwl",
		"pron" : "mik-shole'",
		"derivation" : "or מִכְשֹׁל; masculine from H3782 (כָּשַׁל)",
		"def" : "a stumbling-block, literally or figuratively (obstacle, enticement (specifically an idol), scruple)",
		"kjv" : "caused to fall, offence, [idiom] (no-) thing offered, ruin, stumbling-block"
	},
	"H4384" : {
		"lemma" : "מַכְשֵׁלָה",
		"xlit" : "makshêlâh",
		"pron" : "mak-shay-law'",
		"derivation" : "feminine from H3782 (כָּשַׁל)",
		"def" : "a stumbling-block, but only figuratively (fall, enticement (idol))",
		"kjv" : "ruin, stumbling-block"
	},
	"H4385" : {
		"lemma" : "מִכְתָּב",
		"xlit" : "miktâb",
		"pron" : "mik-tawb'",
		"derivation" : "from H3789 (כָּתַב)",
		"def" : "a thing written, the characters, or a document (letter, copy, edict, poem)",
		"kjv" : "writing"
	},
	"H4386" : {
		"lemma" : "מְכִתָּה",
		"xlit" : "mᵉkittâh",
		"pron" : "mek-it-taw'",
		"derivation" : "from H3807 (כָּתַת)",
		"def" : "a fracture",
		"kjv" : "bursting"
	},
	"H4387" : {
		"lemma" : "מִכְתָּם",
		"xlit" : "miktâm",
		"pron" : "mik-tawm'",
		"derivation" : "from H3799 (כָּתַם)",
		"def" : "an engraving, i.e. (techn.) a poem",
		"kjv" : "Michtam"
	},
	"H4388" : {
		"lemma" : "מַכְתֵּשׁ",
		"xlit" : "maktêsh",
		"pron" : "mak-taysh'",
		"derivation" : "from H3806 (כָּתַשׁ)",
		"def" : "a mortar; by analogy, a socket (of a tooth)",
		"kjv" : "hollow place, mortar"
	},
	"H4389" : {
		"lemma" : "מַכְתֵּשׁ",
		"xlit" : "Maktêsh",
		"pron" : "mak-taysh'",
		"derivation" : "the same as H4388 (מַכְתֵּשׁ)",
		"def" : "dell; the Maktesh, a place in Jerusalem",
		"kjv" : "Maktesh"
	},
	"H4390" : {
		"lemma" : "מָלֵא",
		"xlit" : "mâlêʼ",
		"pron" : "maw-lay'",
		"derivation" : "or מָלָא; (Esther 7:5), a primitive root",
		"def" : "to fill or (intransitively) be full of, in a wide application (literally and figuratively)",
		"kjv" : "accomplish, confirm, [phrase] consecrate, be at an end, be expired, be fenced, fill, fulfil, (be, become, [idiom] draw, give in, go) full(-ly, -ly set, tale), (over-) flow, fulness, furnish, gather (selves, together), presume, replenish, satisfy, set, space, take a (hand-) full, [phrase] have wholly"
	},
	"H4391" : {
		"lemma" : "מְלָא",
		"xlit" : "mᵉlâʼ",
		"pron" : "mel-aw'",
		"derivation" : "(Aramaic) corresponding to H4390 (מָלֵא)",
		"def" : "to fill",
		"kjv" : "fill, be full"
	},
	"H4392" : {
		"lemma" : "מָלֵא",
		"xlit" : "mâlêʼ",
		"pron" : "maw-lay'",
		"derivation" : "from H4390 (מָלֵא)",
		"def" : "full (literally or figuratively) or filling (literally); also (concretely) fulness; adverbially, fully",
		"kjv" : "[idiom] she that was with child, fill(-ed, -ed with), full(-ly), multitude, as is worth"
	},
	"H4393" : {
		"lemma" : "מְלֹא",
		"xlit" : "mᵉlôʼ",
		"pron" : "mel-o'",
		"derivation" : "rarely מְלוֹא; or מְלוֹ; (Ezekiel [41:8), from H4390 (מָלֵא)",
		"def" : "fulness (literally or figuratively)",
		"kjv" : "[idiom] all along, [idiom] all that is (there-) in, fill, ([idiom] that whereof...was) full, fulness, (hand-) full, multitude"
	},
	"H4394" : {
		"lemma" : "מִלֻּא",
		"xlit" : "milluʼ",
		"pron" : "mil-loo'",
		"derivation" : "from H4390 (מָלֵא)",
		"def" : "a fulfilling (only in plural), i.e. (literally) a setting (of gems), or (technically) consecration (also concretely, a dedicatory sacrifice)",
		"kjv" : "consecration, be set"
	},
	"H4395" : {
		"lemma" : "מְלֵאָה",
		"xlit" : "mᵉlêʼâh",
		"pron" : "mel-ay-aw'",
		"derivation" : "feminine of H4392 (מָלֵא)",
		"def" : "something fulfilled, i.e. abundance (of produce)",
		"kjv" : "(first of ripe) fruit, fulness"
	},
	"H4396" : {
		"lemma" : "מִלֻּאָה",
		"xlit" : "milluʼâh",
		"pron" : "mil-loo-aw'",
		"derivation" : "feminine of H4394 (מִלֻּא)",
		"def" : "a filling, i.e. setting (of gems)",
		"kjv" : "enclosing, setting"
	},
	"H4397" : {
		"lemma" : "מֲלְאָךְ",
		"xlit" : "mălʼâk",
		"pron" : "mal-awk'",
		"derivation" : "from an unused root meaning to despatch as a deputy",
		"def" : "a messenger; specifically, of God, i.e. an angel (also a prophet, priest or teacher)",
		"kjv" : "ambassador, angel, king, messenger"
	},
	"H4398" : {
		"lemma" : "מַלְאַךְ",
		"xlit" : "malʼak",
		"pron" : "mal-ak'",
		"derivation" : "(Aramaic) corresponding to H4397 (מֲלְאָךְ)",
		"def" : "an angel",
		"kjv" : "angel"
	},
	"H4399" : {
		"lemma" : "מְלָאכָה",
		"xlit" : "mᵉlâʼkâh",
		"pron" : "mel-aw-kaw'",
		"derivation" : "from the same as H4397 (מֲלְאָךְ)",
		"def" : "properly, deputyship, i.e. ministry; generally, employment (never servile) or work (abstractly or concretely); also property (as the result of labor)",
		"kjv" : "business, [phrase] cattle, [phrase] industrious, occupation, ([phrase] -pied), [phrase] officer, thing (made), use, (manner of) work((-man), -manship)"
	},
	"H4400" : {
		"lemma" : "מַלְאֲכוּת",
		"xlit" : "malʼăkûwth",
		"pron" : "mal-ak-ooth'",
		"derivation" : "from the same as H4397 (מֲלְאָךְ)",
		"def" : "a message",
		"kjv" : "message"
	},
	"H4401" : {
		"lemma" : "מַלְאָכִי",
		"xlit" : "Malʼâkîy",
		"pron" : "mal-aw-kee'",
		"derivation" : "from the same as H4397 (מֲלְאָךְ); ministrative",
		"def" : "Malaki, a prophet",
		"kjv" : "Malachi"
	},
	"H4402" : {
		"lemma" : "מִלֵּאת",
		"xlit" : "millêʼth",
		"pron" : "mil-layth'",
		"derivation" : "from H4390 (מָלֵא)",
		"def" : "fulness, i.e. (concretely) a plump socket (of the eye)",
		"kjv" : "[idiom] fitly"
	},
	"H4403" : {
		"lemma" : "מַלְבּוּשׁ",
		"xlit" : "malbûwsh",
		"pron" : "mal-boosh'",
		"derivation" : "or מַלְבֻּשׁ; from H3847 (לָבַשׁ)",
		"def" : "a garment, or (collectively) clothing",
		"kjv" : "apparel, raiment, vestment"
	},
	"H4404" : {
		"lemma" : "מַלְבֵּן",
		"xlit" : "malbên",
		"pron" : "mal-bane'",
		"derivation" : "from H3835 (לָבַן) (denominative)",
		"def" : "a brickkiln",
		"kjv" : "brickkiln"
	},
	"H4405" : {
		"lemma" : "מִלָּה",
		"xlit" : "millâh",
		"pron" : "mil-law'",
		"derivation" : "from H4448 (מָלַל) (plural masculine as if from מִלֶּה",
		"def" : "a word; collectively, a discourse; figuratively, a topic",
		"kjv" : "[phrase] answer, by-word, matter, any thing (what) to say, to speak(-ing), speak, talking, word"
	},
	"H4406" : {
		"lemma" : "מִלָּה",
		"xlit" : "millâh",
		"pron" : "mil-law'",
		"derivation" : "(Aramaic) corresponding to H4405 (מִלָּה)",
		"def" : "a word, command, discourse, or subject",
		"kjv" : "commandment, matter, thing. word"
	},
	"H4407" : {
		"lemma" : "מִלּוֹא",
		"xlit" : "millôwʼ",
		"pron" : "mil-lo'",
		"derivation" : "or מִלֹּא; (2 Kings 12:20), from H4390 (מָלֵא)",
		"def" : "a rampart (as filled in), i.e. the citadel",
		"kjv" : "Millo. See also H1037 (בֵּית מִלּוֹא)"
	},
	"H4408" : {
		"lemma" : "מַלּוּחַ",
		"xlit" : "mallûwach",
		"pron" : "mal-loo'-akh",
		"derivation" : "from H4414 (מָלַח)",
		"def" : "sea-purslain (from its saltness)",
		"kjv" : "mallows"
	},
	"H4409" : {
		"lemma" : "מַלּוּךְ",
		"xlit" : "Mallûwk",
		"pron" : "mal-luke'",
		"derivation" : "or מַלּוּכִי; (Nehemiah 12:14), from H4427 (מָלַךְ); remnant",
		"def" : "Malluk, the name of five Israelites",
		"kjv" : "Malluch, Melichu (from the margin)"
	},
	"H4410" : {
		"lemma" : "מְלוּכָה",
		"xlit" : "mᵉlûwkâh",
		"pron" : "mel-oo-kaw'",
		"derivation" : "feminine passive participle of H4427 (מָלַךְ)",
		"def" : "something ruled, i.e. a realm",
		"kjv" : "kingsom, king's, [idiom] royal"
	},
	"H4411" : {
		"lemma" : "מָלוֹן",
		"xlit" : "mâlôwn",
		"pron" : "maw-lone'",
		"derivation" : "from H3885 (לוּן)",
		"def" : "a lodgment, i.e. caravanserai or encampment",
		"kjv" : "inn, place where...lodge, lodging (place)"
	},
	"H4412" : {
		"lemma" : "מְלוּנָה",
		"xlit" : "mᵉlûwnâh",
		"pron" : "mel-oo-naw'",
		"derivation" : "feminine from H3885 (לוּן)",
		"def" : "a hut, a hammock",
		"kjv" : "cottage, lodge"
	},
	"H4413" : {
		"lemma" : "מַלּוֹתִי",
		"xlit" : "Mallôwthîy",
		"pron" : "mal-lo'-thee",
		"derivation" : "apparently from H4448 (מָלַל); I have talked (i.e. loquacious)",
		"def" : "Mallothi, an Israelite",
		"kjv" : "Mallothi"
	},
	"H4414" : {
		"lemma" : "מָלַח",
		"xlit" : "mâlach",
		"pron" : "maw-lakh'",
		"derivation" : "a primitive root; also as denominative from H4417 (מֶלַח)",
		"def" : "properly, to rub to pieces or pulverize; intransitively, to disappear as dust; to salt whether internally (to season with salt) or externally (to rub with salt)",
		"kjv" : "[idiom] at all, salt, season, temper together, vanish away"
	},
	"H4415" : {
		"lemma" : "מְלַח",
		"xlit" : "mᵉlach",
		"pron" : "mel-akh'",
		"derivation" : "(Aramaic) corresponding to H4414 (מָלַח)",
		"def" : "to eat salt, i.e. (generally) subsist",
		"kjv" : "[phrase] have maintenance"
	},
	"H4416" : {
		"lemma" : "מְלַח",
		"xlit" : "mᵉlach",
		"pron" : "mel-akh'",
		"derivation" : "(Aramaic) from H4415 (מְלַח)",
		"def" : "salt",
		"kjv" : "[phrase] maintenance, salt"
	},
	"H4417" : {
		"lemma" : "מֶלַח",
		"xlit" : "melach",
		"pron" : "meh'-lakh",
		"derivation" : "from H4414 (מָלַח)",
		"def" : "properly, powder, i.e. (specifically) salt (as easily pulverized and dissolved)",
		"kjv" : "salt(-pit)"
	},
	"H4418" : {
		"lemma" : "מָלָח",
		"xlit" : "mâlâch",
		"pron" : "maw-lawkh'",
		"derivation" : "from H4414 (מָלַח) in its original sense",
		"def" : "a rag or old garment",
		"kjv" : "rotten rag"
	},
	"H4419" : {
		"lemma" : "מַלָּח",
		"xlit" : "mallâch",
		"pron" : "mal-lawkh'",
		"derivation" : "from H4414 (מָלַח) in its second. sense",
		"def" : "a sailor (as following 'the salt')",
		"kjv" : "mariner"
	},
	"H4420" : {
		"lemma" : "מְלֵחָה",
		"xlit" : "mᵉlêchâh",
		"pron" : "mel-ay-khaw'",
		"derivation" : "from H4414 (מָלַח) (in its denominative sense); understood as land (H776 (אֶרֶץ))",
		"def" : "properly, salted (i.e. land), i.e. a desert",
		"kjv" : "barren land(-ness), salt (land)"
	},
	"H4421" : {
		"lemma" : "מִלְחָמָה",
		"xlit" : "milchâmâh",
		"pron" : "mil-khaw-maw'",
		"derivation" : "from H3898 (לָחַם) (in the sense of fighting)",
		"def" : "a battle (i.e. the engagement); generally, war (i.e. warfare)",
		"kjv" : "battle, fight(-ing), war(-rior)"
	},
	"H4422" : {
		"lemma" : "מָלַט",
		"xlit" : "mâlaṭ",
		"pron" : "maw-lat'",
		"derivation" : "a primitive root",
		"def" : "properly, to be smooth, i.e. (by implication) to escape (as if by slipperiness); causatively, to release or rescue; specifically, to bring forth young, emit sparks",
		"kjv" : "deliver (self), escape, lay, leap out, let alone, let go, preserve, save, [idiom] speedily, [idiom] surely"
	},
	"H4423" : {
		"lemma" : "מֶלֶט",
		"xlit" : "meleṭ",
		"pron" : "meh'-let",
		"derivation" : "from H4422 (מָלַט)",
		"def" : "cement (from its plastic smoothness)",
		"kjv" : "clay"
	},
	"H4424" : {
		"lemma" : "מְלַטְיָה",
		"xlit" : "Mᵉlaṭyâh",
		"pron" : "mel-at-yaw'",
		"derivation" : "from H4423 (מֶלֶט) and H3050 (יָהּ); (whom) Jah has delivered",
		"def" : "Melatjah, a Gibeonite",
		"kjv" : "Melatiah"
	},
	"H4425" : {
		"lemma" : "מְלִילָה",
		"xlit" : "mᵉlîylâh",
		"pron" : "mel-ee-law'",
		"derivation" : "from H4449 (מְלַל) (in the sense of cropping (compare H4135 (מוּל)))",
		"def" : "a head of grain (as cut off)",
		"kjv" : "ear"
	},
	"H4426" : {
		"lemma" : "מְלִיצָה",
		"xlit" : "mᵉlîytsâh",
		"pron" : "mel-ee-tsaw'",
		"derivation" : "from H3887 (לוּץ)",
		"def" : "an aphorism; also a satire",
		"kjv" : "interpretation, taunting"
	},
	"H4427" : {
		"lemma" : "מָלַךְ",
		"xlit" : "mâlak",
		"pron" : "maw-lak'",
		"derivation" : "a primitive root",
		"def" : "to reign; inceptively, to ascend the throne; causatively, to induct into royalty; hence (by implication) to take counsel",
		"kjv" : "consult, [idiom] indeed, be (make, set a, set up) king, be (make) queen, (begin to, make to) reign(-ing), rule, [idiom] surely"
	},
	"H4428" : {
		"lemma" : "מֶלֶךְ",
		"xlit" : "melek",
		"pron" : "meh'-lek",
		"derivation" : "from H4427 (מָלַךְ)",
		"def" : "a king",
		"kjv" : "king, royal"
	},
	"H4429" : {
		"lemma" : "מֶלֶךְ",
		"xlit" : "Melek",
		"pron" : "meh'-lek",
		"derivation" : "the same as H4428 (מֶלֶךְ); king",
		"def" : "Melek, the name of two Israelites",
		"kjv" : "Melech, Hammelech (by including the article)"
	},
	"H4430" : {
		"lemma" : "מֶלֶךְ",
		"xlit" : "melek",
		"pron" : "meh'-lek",
		"derivation" : "(Aramaic) corresponding to H4428 (מֶלֶךְ)",
		"def" : "a king",
		"kjv" : "king, royal"
	},
	"H4431" : {
		"lemma" : "מְלַךְ",
		"xlit" : "mᵉlak",
		"pron" : "mel-ak'",
		"derivation" : "(Aramaic) from a root corresponding to H4427 (מָלַךְ) in the sense of consultation",
		"def" : "advice",
		"kjv" : "counsel"
	},
	"H4432" : {
		"lemma" : "מֹלֶךְ",
		"xlit" : "Môlek",
		"pron" : "mo'-lek",
		"derivation" : "from H4427 (מָלַךְ)",
		"def" : "Molek (i.e. king), the chief deity of the Ammonites",
		"kjv" : "Molech. Compare H4445 (מַלְכָּם)"
	},
	"H4433" : {
		"lemma" : "מַלְכָּא",
		"xlit" : "malkâʼ",
		"pron" : "mal-kaw'",
		"derivation" : "(Aramaic) corresponding to H4436 (מַלְכָּה)",
		"def" : "a queen",
		"kjv" : "queen"
	},
	"H4434" : {
		"lemma" : "מַלְכֹּדֶת",
		"xlit" : "malkôdeth",
		"pron" : "mal-ko'-deth",
		"derivation" : "from H3920 (לָכַד)",
		"def" : "a snare",
		"kjv" : "trap"
	},
	"H4435" : {
		"lemma" : "מִלְכָּה",
		"xlit" : "Milkâh",
		"pron" : "mil-kaw'",
		"derivation" : "a form of H4436 (מַלְכָּה); queen",
		"def" : "Milcah, the name of a Hebrewess and of an Israelite",
		"kjv" : "Milcah"
	},
	"H4436" : {
		"lemma" : "מַלְכָּה",
		"xlit" : "malkâh",
		"pron" : "mal-kaw'",
		"derivation" : "feminine of H4428 (מֶלֶךְ)",
		"def" : "a queen",
		"kjv" : "queen"
	},
	"H4437" : {
		"lemma" : "מַלְכוּ",
		"xlit" : "malkûw",
		"pron" : "mal-koo'",
		"derivation" : "(Aramaic) corresponding to H4438 (מַלְכוּת)",
		"def" : "dominion (abstractly or concretely)",
		"kjv" : "kingdom, kingly, realm, reign"
	},
	"H4438" : {
		"lemma" : "מַלְכוּת",
		"xlit" : "malkûwth",
		"pron" : "mal-kooth'",
		"derivation" : "or מַלְכֻת; or (in plural) מַלְכֻיָּה; from H4427 (מָלַךְ)",
		"def" : "a rule; concretely, a dominion",
		"kjv" : "empire, kingdom, realm, reign, royal"
	},
	"H4439" : {
		"lemma" : "מַלְכִּיאֵל",
		"xlit" : "Malkîyʼêl",
		"pron" : "mal-kee-ale'",
		"derivation" : "from H4428 (מֶלֶךְ) and H410 (אֵל); king of (i.e. appointed by) God",
		"def" : "Malkiel, an Israelite",
		"kjv" : "Malchiel"
	},
	"H4440" : {
		"lemma" : "מַלְכִּיאֵלִי",
		"xlit" : "Malkîyʼêlîy",
		"pron" : "mal-kee-ay-lee'",
		"derivation" : "patronymical from H4439 (מַלְכִּיאֵל)",
		"def" : "a Malkielite or descendant of Malkiel",
		"kjv" : "Malchielite"
	},
	"H4441" : {
		"lemma" : "מַלְכִּיָּה",
		"xlit" : "Malkîyâh",
		"pron" : "mal-kee-yaw'",
		"derivation" : "or מַלְכִּיָּהוּlemma מַלְכִּיָהוּ missing dagesh, corrected to מַלְכִּיָּהוּ; (Jeremiah [38:6), from H4428 (מֶלֶךְ) and H3050 (יָהּ); king of (i.e. appointed by) Jah",
		"def" : "Malkijah, the name of ten Israelites",
		"kjv" : "Malchiah, Malchijah"
	},
	"H4442" : {
		"lemma" : "מַלְכִּי־צֶדֶק",
		"xlit" : "Malkîy-Tsedeq",
		"pron" : "mal-kee-tseh'-dek",
		"derivation" : "from H4428 (מֶלֶךְ) and H6664 (צֶדֶק); king of right",
		"def" : "Malki-Tsedek, an early king in Palestine",
		"kjv" : "Melchizedek"
	},
	"H4443" : {
		"lemma" : "מַלְכִּירָם",
		"xlit" : "Malkîyrâm",
		"pron" : "mal-kee-rawm'",
		"derivation" : "from H4428 (מֶלֶךְ) and H7311 (רוּם); king of a high one (i.e. of exaltation)",
		"def" : "Malkiram, an Israelite",
		"kjv" : "Malchiram"
	},
	"H4444" : {
		"lemma" : "מַלְכִּישׁוּעַ",
		"xlit" : "Malkîyshûwaʻ",
		"pron" : "mal-kee-shoo'-ah",
		"derivation" : "from H4428 (מֶלֶךְ) and H7769 (שׁוּעַ); king of wealth",
		"def" : "Malkishua, an Israelite",
		"kjv" : "Malchishua"
	},
	"H4445" : {
		"lemma" : "מַלְכָּם",
		"xlit" : "Malkâm",
		"pron" : "mal-kawm'",
		"derivation" : "or מִלְכּוֹם; from H4428 (מֶלֶךְ) for H4432 (מֹלֶךְ)",
		"def" : "Malcam or Milcom, the national idol of the Ammonites",
		"kjv" : "Malcham, Milcom"
	},
	"H4446" : {
		"lemma" : "מְלֶכֶת",
		"xlit" : "mᵉleketh",
		"pron" : "mel-eh'-keth",
		"derivation" : "from H4427 (מָלַךְ)",
		"def" : "a queen",
		"kjv" : "queen"
	},
	"H4447" : {
		"lemma" : "מֹלֶכֶת",
		"xlit" : "Môleketh",
		"pron" : "mo-leh'-keth",
		"derivation" : "feminine active participle of H4427 (מָלַךְ); queen",
		"def" : "Moleketh, an Israelitess",
		"kjv" : "Hammoleketh (including the article)"
	},
	"H4448" : {
		"lemma" : "מָלַל",
		"xlit" : "mâlal",
		"pron" : "maw-lal'",
		"derivation" : "a primitive root",
		"def" : "to speak (mostly poetical) or say",
		"kjv" : "say, speak, utter"
	},
	"H4449" : {
		"lemma" : "מְלַל",
		"xlit" : "mᵉlal",
		"pron" : "mel-al'",
		"derivation" : "(Aramaic) corresponding to H4448 (מָלַל)",
		"def" : "to speak",
		"kjv" : "say, speak(-ing)"
	},
	"H4450" : {
		"lemma" : "מִלֲלַי",
		"xlit" : "Milălay",
		"pron" : "mee-lal-ah'-ee",
		"derivation" : "from H4448 (מָלַל); talkative",
		"def" : "Milalai, an Israelite",
		"kjv" : "Milalai"
	},
	"H4451" : {
		"lemma" : "מַלְמָד",
		"xlit" : "malmâd",
		"pron" : "mal-mawd'",
		"derivation" : "from H3925 (לָמַד)",
		"def" : "a goad for oxen",
		"kjv" : "goad"
	},
	"H4452" : {
		"lemma" : "מָלַץ",
		"xlit" : "mâlats",
		"pron" : "maw-lats'",
		"derivation" : "a primitive root",
		"def" : "to be smooth, i.e. (figuratively) pleasant",
		"kjv" : "be sweet"
	},
	"H4453" : {
		"lemma" : "מֶלְצָר",
		"xlit" : "meltsâr",
		"pron" : "mel-tsawr'",
		"derivation" : "of Persian derivation",
		"def" : "the butler or other officer in the Babylonian court",
		"kjv" : "Melzar"
	},
	"H4454" : {
		"lemma" : "מָלַק",
		"xlit" : "mâlaq",
		"pron" : "maw-lak'",
		"derivation" : "a primitive root",
		"def" : "to crack a joint; by implication, to wring the neck of afowl (without separating it)",
		"kjv" : "wring off"
	},
	"H4455" : {
		"lemma" : "מַלְקוֹחַ",
		"xlit" : "malqôwach",
		"pron" : "mal-ko'-akh",
		"derivation" : "from H3947 (לָקַח)",
		"def" : "transitively (in dual) the jaws (as taking food); intransitively, spoil (and captives) (as taken)",
		"kjv" : "booty, jaws, prey"
	},
	"H4456" : {
		"lemma" : "מַלְקוֹשׁ",
		"xlit" : "malqôwsh",
		"pron" : "mal-koshe'",
		"derivation" : "from H3953 (לָקַשׁ); (compare H3954 (לֶקֶשׁ))",
		"def" : "the spring rain; figuratively, eloquence",
		"kjv" : "latter rain"
	},
	"H4457" : {
		"lemma" : "מֶלְקָח",
		"xlit" : "melqâch",
		"pron" : "mel-kawkh'",
		"derivation" : "or מַלְקָח; from H3947 (לָקַח)",
		"def" : "(only in dual) tweezers",
		"kjv" : "snuffers, tongs"
	},
	"H4458" : {
		"lemma" : "מֶלְתָּחָה",
		"xlit" : "meltâchâh",
		"pron" : "mel-taw-khaw'",
		"derivation" : "from an unused root meaning to spread out",
		"def" : "a wardrobe (i.e. room where clothing is spread)",
		"kjv" : "vestry"
	},
	"H4459" : {
		"lemma" : "מַלְתָּעָה",
		"xlit" : "maltâʻâh",
		"pron" : "mal-taw-aw'",
		"derivation" : "transp. for H4973 (מְתַלְּעָה)",
		"def" : "a grinder, i.e. back tooth",
		"kjv" : "great tooth"
	},
	"H4460" : {
		"lemma" : "מַמְּגֻרָה",
		"xlit" : "mammᵉgurâh",
		"pron" : "mam-meg-oo-raw'",
		"derivation" : "from H4048 (מָגַר) (in the sense of depositing)",
		"def" : "a granary",
		"kjv" : "barn"
	},
	"H4461" : {
		"lemma" : "מֵמַד",
		"xlit" : "mêmad",
		"pron" : "may-mad'",
		"derivation" : "from H4058 (מָדַד)",
		"def" : "a measure",
		"kjv" : "measure"
	},
	"H4462" : {
		"lemma" : "מְמוּכָן",
		"xlit" : "Mᵉmûwkân",
		"pron" : "mem-oo-kawn'",
		"derivation" : "or (transp.) מוֹמֻכָן; (Esther 1:16), of Persian derivation",
		"def" : "Memucan or Momucan, a Persian satrap",
		"kjv" : "Memucan"
	},
	"H4463" : {
		"lemma" : "מָמוֹת",
		"xlit" : "mâmôwth",
		"pron" : "maw-mothe'",
		"derivation" : "from H4191 (מוּת)",
		"def" : "a mortal disease; concretely, a corpse",
		"kjv" : "death"
	},
	"H4464" : {
		"lemma" : "מַמְזֵר",
		"xlit" : "mamzêr",
		"pron" : "mam-zare'",
		"derivation" : "from an unused root meaning to alienate",
		"def" : "a mongrel, i.e. born of aJewish father and aheathen mother",
		"kjv" : "bastard"
	},
	"H4465" : {
		"lemma" : "מִמְכָּר",
		"xlit" : "mimkâr",
		"pron" : "mim-kawr'",
		"derivation" : "from H4376 (מָכַר)",
		"def" : "merchandise; abstractly, a selling",
		"kjv" : "[idiom] ought, (that which cometh of) sale, that which...sold, ware"
	},
	"H4466" : {
		"lemma" : "מִמְכֶּרֶת",
		"xlit" : "mimkereth",
		"pron" : "mim-keh'-reth",
		"derivation" : "feminine of H4465 (מִמְכָּר)",
		"def" : "a sale",
		"kjv" : "[phrase] sold as"
	},
	"H4467" : {
		"lemma" : "מַמְלָכָה",
		"xlit" : "mamlâkâh",
		"pron" : "mam-law-kaw'",
		"derivation" : "from H4427 (מָלַךְ)",
		"def" : "dominion, i.e. (abstractly) the estate (rule) or (concretely) the country (realm)",
		"kjv" : "kingdom, king's, reign, royal"
	},
	"H4468" : {
		"lemma" : "מַמְלָכוּת",
		"xlit" : "mamlâkûwth",
		"pron" : "mam-law-kooth'",
		"derivation" : "a form of H4467 (מַמְלָכָה) and equiv. to it",
		"def" : "{dominion, i.e. (abstractly) the estate (rule) or (concretely) the country (realm)}",
		"kjv" : "kingdom, reign"
	},
	"H4469" : {
		"lemma" : "מַמְסָךְ",
		"xlit" : "mamçâk",
		"pron" : "mam-sawk'",
		"derivation" : "from H4537 (מָסַךְ)",
		"def" : "mixture, i.e. (specifically) wine mixed (with water or spices)",
		"kjv" : "drink-offering, mixed wine"
	},
	"H4470" : {
		"lemma" : "מֶמֶר",
		"xlit" : "memer",
		"pron" : "meh'-mer",
		"derivation" : "from an unused root meaning to grieve",
		"def" : "sorrow",
		"kjv" : "bitterness"
	},
	"H4471" : {
		"lemma" : "מַמְרֵא",
		"xlit" : "Mamrêʼ",
		"pron" : "mam-ray'",
		"derivation" : "from H4754 (מָרָא)(in the sense of vigor); lusty",
		"def" : "Mamre, an Amorite",
		"kjv" : "Mamre"
	},
	"H4472" : {
		"lemma" : "מַמְרֹר",
		"xlit" : "mamrôr",
		"pron" : "mam-rore'",
		"derivation" : "from H4843 (מָרַר)",
		"def" : "a bitterness, i.e. (figuratively) calamity",
		"kjv" : "bitterness"
	},
	"H4473" : {
		"lemma" : "מִמְשַׁח",
		"xlit" : "mimshach",
		"pron" : "mim-shakh'",
		"derivation" : "from H4886 (מָשַׁח), in the sense of expansion",
		"def" : "outspread (i.e. with outstretched wings)",
		"kjv" : "anointed"
	},
	"H4474" : {
		"lemma" : "מִמְשָׁל",
		"xlit" : "mimshâl",
		"pron" : "mim-shawl'",
		"derivation" : "from H4910 (מָשַׁל)",
		"def" : "a ruler or (abstractly) rule",
		"kjv" : "dominion, that ruled"
	},
	"H4475" : {
		"lemma" : "מֶמְשָׁלָה",
		"xlit" : "memshâlâh",
		"pron" : "mem-shaw-law'",
		"derivation" : "feminine of H4474 (מִמְשָׁל)",
		"def" : "rule; also (concretely in plural) a realm or a ruler",
		"kjv" : "dominion, government, power, to rule"
	},
	"H4476" : {
		"lemma" : "מִמְשָׁק",
		"xlit" : "mimshâq",
		"pron" : "mim-shawk'",
		"derivation" : "from the same as H4943 (מֶשֶׁק)",
		"def" : "a possession",
		"kjv" : "breeding"
	},
	"H4477" : {
		"lemma" : "מַמְתַּק",
		"xlit" : "mamtaq",
		"pron" : "mam-tak'",
		"derivation" : "from H4985 (מָתַק)",
		"def" : "something sweet (literally or figuratively)",
		"kjv" : "(most) sweet"
	},
	"H4478" : {
		"lemma" : "מָן",
		"xlit" : "mân",
		"pron" : "mawn",
		"derivation" : "from H4100 (מָה)",
		"def" : "literally a whatness (so to speak), i.e. manna (so called from the question about it)",
		"kjv" : "manna"
	},
	"H4479" : {
		"lemma" : "מָן",
		"xlit" : "mân",
		"pron" : "mawn",
		"derivation" : "(Aramaic) from H4101 (מָה)",
		"def" : "who or what (properly, interrogatively, hence, also indefinitely and relatively)",
		"kjv" : "what, who(-msoever, [phrase] -so)"
	},
	"H4480" : {
		"lemma" : "מִן",
		"xlit" : "min",
		"pron" : "min",
		"derivation" : "or מִנִּי; or מִנֵּי; (constructive plural) (Isaiah 30:11); for H4482 (מֵן)",
		"def" : "properly, a part of; hence (prepositionally), from or out of in many senses",
		"kjv" : "above, after, among, at, because of, by (reason of), from (among), in, [idiom] neither, [idiom] nor, (out) of, over, since, [idiom] then, through, [idiom] whether, with"
	},
	"H4481" : {
		"lemma" : "מִן",
		"xlit" : "min",
		"pron" : "min",
		"derivation" : "(Aramaic) corresponding to H4480 (מִן)",
		"def" : "{properly, a part of; hence (prepositionally), from or out of}",
		"kjv" : "according, after, [phrase] because, [phrase] before, by, for, from, [idiom] him, [idiom] more than, (out) of, part, since, [idiom] these, to, upon, [phrase] when"
	},
	"H4482" : {
		"lemma" : "מֵן",
		"xlit" : "mên",
		"pron" : "mane",
		"derivation" : "from an unused root meaning to apportion; a part; hence",
		"def" : "a musical chord (as parted into strings)",
		"kjv" : "in (the same) (Psalm 68:23), stringed instrument (Psalm 150:4), whereby (Psalm 45:8 (defective plural))"
	},
	"H4483" : {
		"lemma" : "מְנָא",
		"xlit" : "mᵉnâʼ",
		"pron" : "men-aw'",
		"derivation" : "(Aramaic) or מְנָה; (Aramaic), corresponding to H4487 (מָנָה)",
		"def" : "to count, appoint",
		"kjv" : "number, ordain, set"
	},
	"H4484" : {
		"lemma" : "מְנֵא",
		"xlit" : "mᵉnêʼ",
		"pron" : "men-ay'",
		"derivation" : "(Aramaic) passive participle of H4483 (מְנָא)",
		"def" : "numbered",
		"kjv" : "Mene"
	},
	"H4485" : {
		"lemma" : "מַנְגִּינָה",
		"xlit" : "mangîynâh",
		"pron" : "man-ghee-naw'",
		"derivation" : "from H5059 (נָגַן)",
		"def" : "a satire",
		"kjv" : "music"
	},
	"H4486" : {
		"lemma" : "מַנְדַּע",
		"xlit" : "mandaʻ",
		"pron" : "man-dah'",
		"derivation" : "(Aramaic) corresponding to H4093 (מַדָּע)",
		"def" : "wisdom or intelligence",
		"kjv" : "knowledge, reason, understanding"
	},
	"H4487" : {
		"lemma" : "מָנָה",
		"xlit" : "mânâh",
		"pron" : "maw-naw'",
		"derivation" : "a primitive root",
		"def" : "properly, to weigh out; by implication, to allot or constitute officially; also to enumerate or enroll",
		"kjv" : "appoint, count, number, prepare, set, tell"
	},
	"H4488" : {
		"lemma" : "מָנֶה",
		"xlit" : "mâneh",
		"pron" : "maw-neh'",
		"derivation" : "from H4487 (מָנָה)",
		"def" : "properly, a fixed weight or measured amount, i.e. (techn.) a maneh or mina",
		"kjv" : "maneh, pound"
	},
	"H4489" : {
		"lemma" : "מֹנֶה",
		"xlit" : "môneh",
		"pron" : "mo-neh'",
		"derivation" : "from H4487 (מָנָה)",
		"def" : "properly, something weighed out, i.e. (figuratively) a portion of time, i.e. an instance",
		"kjv" : "time"
	},
	"H4490" : {
		"lemma" : "מָנָה",
		"xlit" : "mânâh",
		"pron" : "maw-naw'",
		"derivation" : "from H4487 (מָנָה)",
		"def" : "properly, something weighed out, i.e. (generally) a division; specifically (of food) a ration; also a lot",
		"kjv" : "such things as belonged, part, portion"
	},
	"H4491" : {
		"lemma" : "מִנְהָג",
		"xlit" : "minhâg",
		"pron" : "min-hawg'",
		"derivation" : "from H5090 (נָהַג)",
		"def" : "the driving (of a chariot)",
		"kjv" : "driving"
	},
	"H4492" : {
		"lemma" : "מִנְהָרָה",
		"xlit" : "minhârâh",
		"pron" : "min-haw-raw'",
		"derivation" : "from H5102 (נָהַר)",
		"def" : "properly, a channel or fissure, i.e. (by implication) a cavern",
		"kjv" : "den"
	},
	"H4493" : {
		"lemma" : "מָנוֹד",
		"xlit" : "mânôwd",
		"pron" : "maw-node'",
		"derivation" : "from H5110 (נוּד)",
		"def" : "a nodding or toss (of the head in derision)",
		"kjv" : "shaking"
	},
	"H4494" : {
		"lemma" : "מָנוֹחַ",
		"xlit" : "mânôwach",
		"pron" : "maw-no'-akh",
		"derivation" : "from H5117 (נוּחַ)",
		"def" : "quiet, i.e. (concretely) a settled spot, or (figuratively) a home",
		"kjv" : "(place of) rest"
	},
	"H4495" : {
		"lemma" : "מָנוֹחַ",
		"xlit" : "Mânôwach",
		"pron" : "maw-no'-akh",
		"derivation" : "the same as H4494 (מָנוֹחַ); rest",
		"def" : "Manoach, an Israelite",
		"kjv" : "Manoah"
	},
	"H4496" : {
		"lemma" : "מְנוּחָה",
		"xlit" : "mᵉnûwchâh",
		"pron" : "men-oo-khaw'",
		"derivation" : "or מְנֻחָה; feminine of H4495 (מָנוֹחַ)",
		"def" : "repose or (adverbially) peacefully; figuratively, consolation (specifically, matrimony); hence (concretely) an abode",
		"kjv" : "comfortable, ease, quiet, rest(-ing place), still"
	},
	"H4497" : {
		"lemma" : "מָנוֹן",
		"xlit" : "mânôwn",
		"pron" : "maw-nohn'",
		"derivation" : "from H5125 (נוּן)",
		"def" : "a continuator, i.e. heir",
		"kjv" : "son"
	},
	"H4498" : {
		"lemma" : "מָנוֹס",
		"xlit" : "mânôwç",
		"pron" : "maw-noce'",
		"derivation" : "from H5127 (נוּס)",
		"def" : "a retreat (literally or figuratively); abstractly, a fleeing",
		"kjv" : "[idiom] apace, escape, way to flee, flight, refuge"
	},
	"H4499" : {
		"lemma" : "מְנוּסָה",
		"xlit" : "mᵉnûwçâh",
		"pron" : "men-oo-saw'",
		"derivation" : "or מְנֻסָה; feminine of H4498 (מָנוֹס)",
		"def" : "retreat",
		"kjv" : "fleeing, flight"
	},
	"H4500" : {
		"lemma" : "מָנוֹר",
		"xlit" : "mânôwr",
		"pron" : "maw-nore'",
		"derivation" : "from H5214 (נִיר)",
		"def" : "a yoke (properly, for plowing), i.e. the frame of aloom",
		"kjv" : "beam"
	},
	"H4501" : {
		"lemma" : "מְנוֹרָה",
		"xlit" : "mᵉnôwrâh",
		"pron" : "men-o-raw'",
		"derivation" : "or מְנֹרָה; feminine of H4500 (מָנוֹר) (in the original sense of H5216 (נִיר))",
		"def" : "a chandelier",
		"kjv" : "candlestick"
	},
	"H4502" : {
		"lemma" : "מִנְּזָר",
		"xlit" : "minnᵉzâr",
		"pron" : "min-ez-awr'",
		"derivation" : "from H5144 (נָזַר)",
		"def" : "a prince",
		"kjv" : "crowned"
	},
	"H4503" : {
		"lemma" : "מִנְחָה",
		"xlit" : "minchâh",
		"pron" : "min-khaw'",
		"derivation" : "from an unused root meaning to apportion, i.e. bestow",
		"def" : "a donation; euphemistically, tribute; specifically a sacrificial offering (usually bloodless and voluntary)",
		"kjv" : "gift, oblation, (meat) offering, present, sacrifice"
	},
	"H4504" : {
		"lemma" : "מִנְחָה",
		"xlit" : "minchâh",
		"pron" : "min-khaw'",
		"derivation" : "(Aramaic) corresponding to H4503 (מִנְחָה)",
		"def" : "a sacrificial offering",
		"kjv" : "oblation, meat offering"
	},
	"H4505" : {
		"lemma" : "מְנַחֵם",
		"xlit" : "Mᵉnachêm",
		"pron" : "men-akh-ame'",
		"derivation" : "from H5162 (נָחַם); comforter",
		"def" : "Menachem, an Israelite",
		"kjv" : "Menahem"
	},
	"H4506" : {
		"lemma" : "מָנַחַת",
		"xlit" : "Mânachath",
		"pron" : "maw-nakh'-ath",
		"derivation" : "from H5117 (נוּחַ); rest",
		"def" : "Manachath, the name of an Edomite and of a place in Moab",
		"kjv" : "Manahath"
	},
	"H4507" : {
		"lemma" : "מְנִי",
		"xlit" : "Mᵉnîy",
		"pron" : "men-ee'",
		"derivation" : "from H4487 (מָנָה)",
		"def" : "the Apportioner, i.e. Fate (as an idol)",
		"kjv" : "number"
	},
	"H4508" : {
		"lemma" : "מִנִּי",
		"xlit" : "Minnîy",
		"pron" : "min-nee'",
		"derivation" : "of foreign derivation",
		"def" : "Minni, an Armenian province",
		"kjv" : "Minni"
	},
	"H4509" : {
		"lemma" : "מִנְיָמִין",
		"xlit" : "Minyâmîyn",
		"pron" : "min-yaw-meen'",
		"derivation" : "from H4480 (מִן) and H3225 (יָמִין); from (the) right hand",
		"def" : "Minjamin, the name of two Israelites",
		"kjv" : "Miniamin. Compare H4326 (מִיָּמִן)"
	},
	"H4510" : {
		"lemma" : "מִנְיָן",
		"xlit" : "minyân",
		"pron" : "min-yawn'",
		"derivation" : "(Aramaic) from H4483 (מְנָא)",
		"def" : "enumeration",
		"kjv" : "number"
	},
	"H4511" : {
		"lemma" : "מִנִּית",
		"xlit" : "Minnîyth",
		"pron" : "min-neeth'",
		"derivation" : "from the same as H4482 (מֵן); enumeration",
		"def" : "Minnith, a place East of the Jordan",
		"kjv" : "Minnith"
	},
	"H4512" : {
		"lemma" : "מִנְלֶה",
		"xlit" : "minleh",
		"pron" : "min-leh'",
		"derivation" : "from H5239 (נָלָה)",
		"def" : "completion, i.e. (in produce) wealth",
		"kjv" : "perfection"
	},
	"H4513" : {
		"lemma" : "מָנַע",
		"xlit" : "mânaʻ",
		"pron" : "maw-nah'",
		"derivation" : "a primitive root",
		"def" : "to debar (negatively or positively) from benefit or injury",
		"kjv" : "deny, keep (back), refrain, restrain, withhold"
	},
	"H4514" : {
		"lemma" : "מַנְעוּל",
		"xlit" : "manʻûwl",
		"pron" : "man-ool'",
		"derivation" : "or מַנְעֻל; from H5274 (נָעַל)",
		"def" : "a bolt",
		"kjv" : "lock"
	},
	"H4515" : {
		"lemma" : "מִנְעָל",
		"xlit" : "minʻâl",
		"pron" : "man-awl'",
		"derivation" : "from H5274 (נָעַל)",
		"def" : "a bolt",
		"kjv" : "shoe"
	},
	"H4516" : {
		"lemma" : "מַנְעַם",
		"xlit" : "manʻam",
		"pron" : "man-am'",
		"derivation" : "from H5276 (נָעֵם)",
		"def" : "a delicacy",
		"kjv" : "dainty"
	},
	"H4517" : {
		"lemma" : "מְנַעְנַע",
		"xlit" : "mᵉnaʻnaʻ",
		"pron" : "men-ah-ah'",
		"derivation" : "from H5128 (נוּעַ)",
		"def" : "a sistrum (so called from its rattling sound)",
		"kjv" : "cornet"
	},
	"H4518" : {
		"lemma" : "מְנַקִּית",
		"xlit" : "mᵉnaqqîyth",
		"pron" : "men-ak-keeth'",
		"derivation" : "from H5352 (נָקָה)",
		"def" : "a sacrificial basin (for holding blood)",
		"kjv" : "bowl"
	},
	"H4519" : {
		"lemma" : "מְנַשֶּׁה",
		"xlit" : "Mᵉnashsheh",
		"pron" : "men-ash-sheh'",
		"derivation" : "from H5382 (נָשָׁה); causing to forget",
		"def" : "Menashsheh, a grandson of Jacob, also the tribe descended from him, and its territory",
		"kjv" : "Manasseh"
	},
	"H4520" : {
		"lemma" : "מְנַשִּׁי",
		"xlit" : "Mᵉnashshîy",
		"pron" : "men-ash-shee'",
		"derivation" : "from H4519 (מְנַשֶּׁה)",
		"def" : "a Menashshite or descendant of Menashsheh",
		"kjv" : "of Manasseh, Manassites"
	},
	"H4521" : {
		"lemma" : "מְנָת",
		"xlit" : "mᵉnâth",
		"pron" : "men-awth'",
		"derivation" : "from H4487 (מָנָה)",
		"def" : "an allotment (by courtesy, law or providence)",
		"kjv" : "portion"
	},
	"H4522" : {
		"lemma" : "מַס",
		"xlit" : "maç",
		"pron" : "mas",
		"derivation" : "or מִס; from H4549 (מָסַס)",
		"def" : "properly, a burden (as causing to faint), i.e. a tax in the form of forced labor",
		"kjv" : "discomfited, levy, task(-master), tribute(-tary)"
	},
	"H4523" : {
		"lemma" : "מָס",
		"xlit" : "mâç",
		"pron" : "mawce",
		"derivation" : "from H4549 (מָסַס)",
		"def" : "fainting, i.e. (figuratively) disconsolate",
		"kjv" : "is afflicted"
	},
	"H4524" : {
		"lemma" : "מֵסַב",
		"xlit" : "mêçab",
		"pron" : "may-sab'",
		"derivation" : "plural masculine מְסִבִּים; or feminine מְסִבּוֹת; from H5437 (סָבַב)",
		"def" : "a divan (as enclosing the room); abstractly (adverbial) around",
		"kjv" : "that compass about, (place) round about, at table"
	},
	"H4525" : {
		"lemma" : "מַסְגֵּר",
		"xlit" : "maçgêr",
		"pron" : "mas-gare'",
		"derivation" : "from H5462 (סָגַר)",
		"def" : "a fastener, i.e. (of a person) a smith, (of a thing) a prison",
		"kjv" : "prison, smith"
	},
	"H4526" : {
		"lemma" : "מִסְגֶּרֶת",
		"xlit" : "miçgereth",
		"pron" : "mis-gheh'-reth",
		"derivation" : "from H5462 (סָגַר)",
		"def" : "something enclosing, i.e. a margin (of a region, of a panel); concretely, a stronghold",
		"kjv" : "border, close place, hole"
	},
	"H4527" : {
		"lemma" : "מַסַּד",
		"xlit" : "maççad",
		"pron" : "mas-sad'",
		"derivation" : "from H3245 (יָסַד)",
		"def" : "a foundation",
		"kjv" : "foundation"
	},
	"H4528" : {
		"lemma" : "מִסְדְּרוֹן",
		"xlit" : "miçdᵉrôwn",
		"pron" : "mis-der-ohn'",
		"derivation" : "from the same as H5468 (סֶדֶר)",
		"def" : "a colonnade or internal portico (from its rows of pillars)",
		"kjv" : "porch"
	},
	"H4529" : {
		"lemma" : "מָסָה",
		"xlit" : "mâçâh",
		"pron" : "maw-saw'",
		"derivation" : "a primitive root",
		"def" : "to dissolve",
		"kjv" : "make to consume away, (make to) melt, water"
	},
	"H4530" : {
		"lemma" : "מִסָּה",
		"xlit" : "miççâh",
		"pron" : "mis-saw'",
		"derivation" : "from H4549 (מָסַס) (in the sense of flowing)",
		"def" : "abundance, i.e. (adverbially) liberally",
		"kjv" : "tribute"
	},
	"H4531" : {
		"lemma" : "מַסָּה",
		"xlit" : "maççâh",
		"pron" : "mas-saw'",
		"derivation" : "from H5254 (נָסָה)",
		"def" : "a testing, of men (judicial) or of God (querulous)",
		"kjv" : "temptation, trial"
	},
	"H4532" : {
		"lemma" : "מַסָּה",
		"xlit" : "Maççâh",
		"pron" : "mas-saw'",
		"derivation" : "the same as H4531 (מַסָּה)",
		"def" : "Massah, a place in the Desert",
		"kjv" : "Massah"
	},
	"H4533" : {
		"lemma" : "מַסְוֶה",
		"xlit" : "maçveh",
		"pron" : "mas-veh'",
		"derivation" : "apparently from an unused root meaning to cover",
		"def" : "a veil",
		"kjv" : "vail"
	},
	"H4534" : {
		"lemma" : "מְסוּכָה",
		"xlit" : "mᵉçûwkâh",
		"pron" : "mes-oo-kaw'",
		"derivation" : "for H4881 (מְשׂוּכָה)",
		"def" : "a hedge",
		"kjv" : "thorn hedge"
	},
	"H4535" : {
		"lemma" : "מַסָּח",
		"xlit" : "maççâch",
		"pron" : "mas-sawkh'",
		"derivation" : "from H5255 (נָסַח) in the sense of staving off",
		"def" : "a cordon, (adverbially) or (as a) military barrier",
		"kjv" : "broken down"
	},
	"H4536" : {
		"lemma" : "מִסְחָר",
		"xlit" : "miçchâr",
		"pron" : "mis-khawr'",
		"derivation" : "from H5503 (סָחַר)",
		"def" : "trade",
		"kjv" : "traffic"
	},
	"H4537" : {
		"lemma" : "מָסַךְ",
		"xlit" : "mâçak",
		"pron" : "maw-sak'",
		"derivation" : "a primitive root",
		"def" : "to mix, especially wine (with spices)",
		"kjv" : "mingle"
	},
	"H4538" : {
		"lemma" : "מֶסֶךְ",
		"xlit" : "meçek",
		"pron" : "meh'-sek",
		"derivation" : "from H4537 (מָסַךְ)",
		"def" : "a mixture, i.e. of wine with spices",
		"kjv" : "mixture"
	},
	"H4539" : {
		"lemma" : "מָסָךְ",
		"xlit" : "mâçâk",
		"pron" : "maw-sawk'",
		"derivation" : "from H5526 (סָכַךְ)",
		"def" : "a cover, i.e. veil",
		"kjv" : "covering, curtain, hanging"
	},
	"H4540" : {
		"lemma" : "מְסֻכָּה",
		"xlit" : "mᵉçukkâh",
		"pron" : "mes-ook-kaw'",
		"derivation" : "from H5526 (סָכַךְ)",
		"def" : "a covering, i.e. garniture",
		"kjv" : "covering"
	},
	"H4541" : {
		"lemma" : "מַסֵּכָה",
		"xlit" : "maççêkâh",
		"pron" : "mas-say-kaw'",
		"derivation" : "from H5258 (נָסַךְ)",
		"def" : "properly, a pouring over, i.e. fusion of metal (especially a cast image); by implication, a libation, i.e. league; concretely a coverlet (as if poured out)",
		"kjv" : "covering, molten (image), vail"
	},
	"H4542" : {
		"lemma" : "מִסְכֵּן",
		"xlit" : "miçkên",
		"pron" : "mis-kane'",
		"derivation" : "from H5531 (סִכְלוּת)",
		"def" : "indigent",
		"kjv" : "poor (man)"
	},
	"H4543" : {
		"lemma" : "מִסְכְּנָה",
		"xlit" : "miçkᵉnâh",
		"pron" : "mis-ken-aw'",
		"derivation" : "by transp. from H3664 (כָּנַס)",
		"def" : "a magazine",
		"kjv" : "store(-house), treasure"
	},
	"H4544" : {
		"lemma" : "מִסְכֵּנֻת",
		"xlit" : "miçkênuth",
		"pron" : "mis-kay-nooth'",
		"derivation" : "from H4542 (מִסְכֵּן)",
		"def" : "indigence",
		"kjv" : "scarceness"
	},
	"H4545" : {
		"lemma" : "מַסֶּכֶת",
		"xlit" : "maççeketh",
		"pron" : "mas-seh'-keth",
		"derivation" : "from H5259 (נָסַךְ) in the sense of spreading out",
		"def" : "something expanded, i.e. the warp in aloom (as stretched out to receive the woof)",
		"kjv" : "web"
	},
	"H4546" : {
		"lemma" : "מְסִלָּה",
		"xlit" : "mᵉçillâh",
		"pron" : "mes-il-law'",
		"derivation" : "from H5549 (סָלַל)",
		"def" : "a thoroughfare (as turnpiked), literally or figuratively; specifically a viaduct, a staircase",
		"kjv" : "causeway, course, highway, path, terrace"
	},
	"H4547" : {
		"lemma" : "מַסְלוּל",
		"xlit" : "maçlûwl",
		"pron" : "mas-lool'",
		"derivation" : "from H5549 (סָלַל)",
		"def" : "a thoroughfare (as turnpiked)",
		"kjv" : "highway"
	},
	"H4548" : {
		"lemma" : "מַסְמֵר",
		"xlit" : "maçmêr",
		"pron" : "mas-mare'",
		"derivation" : "or מִסְמֵר; also (feminine) מַסְמְרָה; or מִסְמְרָה; or even מַשְׂמְרָה; (Ecclesiastes 12:11), from H5568 (סָמַר)",
		"def" : "a peg (as bristling from the surface)",
		"kjv" : "nail"
	},
	"H4549" : {
		"lemma" : "מָסַס",
		"xlit" : "mâçaç",
		"pron" : "maw-sas'",
		"derivation" : "a primitive root",
		"def" : "to liquefy; figuratively, to waste (with disease), to faint (with fatigue, fear or grief)",
		"kjv" : "discourage, faint, be loosed, melt (away), refuse, [idiom] utterly"
	},
	"H4550" : {
		"lemma" : "מַסַּע",
		"xlit" : "maççaʻ",
		"pron" : "mas-sah'",
		"derivation" : "from H5265 (נָסַע)",
		"def" : "a departure (from striking the tents), i.e. march (not necessarily a single day's travel); by implication, a station (or point of departure)",
		"kjv" : "journey(-ing)"
	},
	"H4551" : {
		"lemma" : "מַסָּע",
		"xlit" : "maççâʻ",
		"pron" : "mas-saw'",
		"derivation" : "from H5265 (נָסַע) in the sense of projecting",
		"def" : "a missile (spear or arrow); also a quarry (whence stones are, as it were, ejected)",
		"kjv" : "before it was brought, dart"
	},
	"H4552" : {
		"lemma" : "מִסְעָד",
		"xlit" : "miçʻâd",
		"pron" : "mis-awd'",
		"derivation" : "' from H5582 (סָעַד)",
		"def" : "a balustrade (for stairs)",
		"kjv" : "pillar"
	},
	"H4553" : {
		"lemma" : "מִסְפֵּד",
		"xlit" : "miçpêd",
		"pron" : "mis-pade'",
		"derivation" : "from H5594 (סָפַד)",
		"def" : "a lamentation",
		"kjv" : "lamentation, one mourneth, mourning, wailing"
	},
	"H4554" : {
		"lemma" : "מִסְפּוֹא",
		"xlit" : "miçpôwʼ",
		"pron" : "mis-po'",
		"derivation" : "from an unused root meaning to collect",
		"def" : "fodder",
		"kjv" : "provender"
	},
	"H4555" : {
		"lemma" : "מִסְפָּחָה",
		"xlit" : "miçpâchâh",
		"pron" : "mis-paw-khaw'",
		"derivation" : "from H5596 (סָפַח)",
		"def" : "a veil (as spread out)",
		"kjv" : "kerchief"
	},
	"H4556" : {
		"lemma" : "מִסְפַּחַת",
		"xlit" : "miçpachath",
		"pron" : "mis-pakh'-ath",
		"derivation" : "from H5596 (סָפַח)",
		"def" : "scruf (as spreading over the surface)",
		"kjv" : "scab"
	},
	"H4557" : {
		"lemma" : "מִסְפָּר",
		"xlit" : "miçpâr",
		"pron" : "mis-pawr'",
		"derivation" : "from H5608 (סָפַר)",
		"def" : "a number, definite (arithmetical) or indefinite (large, innumerable; small, a few); also (abstractly) narration",
		"kjv" : "[phrase] abundance, account, [idiom] all, [idiom] few, (in-) finite, (certain) number(-ed), tale, telling, [phrase] time"
	},
	"H4558" : {
		"lemma" : "מִסְפָּר",
		"xlit" : "Miçpâr",
		"pron" : "mis-pawr'",
		"derivation" : "the same as H4457 (מֶלְקָח); number",
		"def" : "Mispar, an Israelite",
		"kjv" : "Mizpar. Compare H4559 (מִסְפֶּרֶת)"
	},
	"H4559" : {
		"lemma" : "מִסְפֶּרֶת",
		"xlit" : "Miçpereth",
		"pron" : "mis-peh'-reth",
		"derivation" : "feminine of H4437 (מַלְכוּ); enumeration",
		"def" : "Mispereth, an Israelite",
		"kjv" : "Mispereth. Compare H4458 (מֶלְתָּחָה)"
	},
	"H4560" : {
		"lemma" : "מָסַר",
		"xlit" : "mâçar",
		"pron" : "maw-sar'",
		"derivation" : "a primitive root",
		"def" : "to sunder, i.e. (transitively) set apart, or (reflex.) apostatize",
		"kjv" : "commit, deliver"
	},
	"H4561" : {
		"lemma" : "מֹסָר",
		"xlit" : "môçâr",
		"pron" : "mo-sawr'",
		"derivation" : "from H3256 (יָסַר)",
		"def" : "admonition",
		"kjv" : "instruction"
	},
	"H4562" : {
		"lemma" : "מָסֹרֶת",
		"xlit" : "mâçôreth",
		"pron" : "maw-so'-reth",
		"derivation" : "from H631 (אָסַר)",
		"def" : "a band",
		"kjv" : "bond"
	},
	"H4563" : {
		"lemma" : "מִסְתּוֹר",
		"xlit" : "miçtôwr",
		"pron" : "mis-tore'",
		"derivation" : "from H5641 (סָתַר)",
		"def" : "a refuge",
		"kjv" : "covert"
	},
	"H4564" : {
		"lemma" : "מַסְתֵּר",
		"xlit" : "maçtêr",
		"pron" : "mas-tare'",
		"derivation" : "from H5641 (סָתַר)",
		"def" : "properly, a hider, i.e. (abstractly) a hiding, i.e. aversion",
		"kjv" : "hid"
	},
	"H4565" : {
		"lemma" : "מִסְתָּר",
		"xlit" : "miçtâr",
		"pron" : "mis-tawr'",
		"derivation" : "from H5641 (סָתַר)",
		"def" : "properly, a concealer, i.e. a covert",
		"kjv" : "secret(-ly, place)"
	},
	"H4566" : {
		"lemma" : "מַעְבָּד",
		"xlit" : "maʻbâd",
		"pron" : "mah-bawd'",
		"derivation" : "from H5647 (עָבַד)",
		"def" : "an act",
		"kjv" : "work"
	},
	"H4567" : {
		"lemma" : "מַעְבָד",
		"xlit" : "maʻbâd",
		"pron" : "mah-bawd'",
		"derivation" : "(Aramaic) corresponding to H4566 (מַעְבָּד)",
		"def" : "an act",
		"kjv" : "work"
	},
	"H4568" : {
		"lemma" : "מַעֲבֶה",
		"xlit" : "maʻăbeh",
		"pron" : "mah-ab-eh'",
		"derivation" : "from H5666 (עָבָה)",
		"def" : "properly, compact (part of soil), i.e. loam",
		"kjv" : "clay"
	},
	"H4569" : {
		"lemma" : "מַעֲבָר",
		"xlit" : "maʻăbâr",
		"pron" : "mah-ab-awr'",
		"derivation" : "or feminine מַעֲבָרָה; from H5674 (עָבַר)",
		"def" : "a crossing-place (of a river, a ford; of a mountain, a pass); abstractly, a transit, i.e. (figuratively) overwhelming",
		"kjv" : "ford, place where...pass, passage"
	},
	"H4570" : {
		"lemma" : "מַעְגָּל",
		"xlit" : "maʻgâl",
		"pron" : "mah-gawl'",
		"derivation" : "or feminine מַעְגָּלָה; from the same as H5696 (עָגֹל)",
		"def" : "a track (literally or figuratively); also a rampart (as circular)",
		"kjv" : "going, path, trench, way(-side)"
	},
	"H4571" : {
		"lemma" : "מָעַד",
		"xlit" : "mâʻad",
		"pron" : "maw-ad'",
		"derivation" : "a primitive root",
		"def" : "to waver",
		"kjv" : "make to shake, slide, slip"
	},
	"H4572" : {
		"lemma" : "מַעֲדַי",
		"xlit" : "Maʻăday",
		"pron" : "mah-ad-ah'-ee",
		"derivation" : "from H5710 (עָדָה); ornamental",
		"def" : "Maadai, an Israelite",
		"kjv" : "Maadai"
	},
	"H4573" : {
		"lemma" : "מַעֲדְיָה",
		"xlit" : "Maʻădyâh",
		"pron" : "mah-ad-yaw'",
		"derivation" : "from H5710 (עָדָה) and H3050 (יָהּ); ornament of Jah",
		"def" : "Maadjah, an Israelite",
		"kjv" : "Maadiah. Compare H4153 (מוֹעַדְיָה)"
	},
	"H4574" : {
		"lemma" : "מַעֲדָן",
		"xlit" : "maʻădân",
		"pron" : "mah-ad-awn'",
		"derivation" : "or (feminine) מַעֲדַנָּה; from H5727 (עָדַן)",
		"def" : "a delicacy or (abstractly) pleasure (adverbially, cheerfully)",
		"kjv" : "dainty, delicately, delight"
	},
	"H4575" : {
		"lemma" : "מַעֲדַנָּה",
		"xlit" : "maʻădannâh",
		"pron" : "mah-ad-an-naw'",
		"derivation" : "by transitive from H6029 (עָנַד)",
		"def" : "a bond, i.e. group",
		"kjv" : "influence"
	},
	"H4576" : {
		"lemma" : "מַעְדֵּר",
		"xlit" : "maʻdêr",
		"pron" : "mah-dare'",
		"derivation" : "from H5737 (עֲדַר)",
		"def" : "a (weeding) hoe",
		"kjv" : "mattock"
	},
	"H4577" : {
		"lemma" : "מְעָה",
		"xlit" : "mᵉʻâh",
		"pron" : "meh-aw'",
		"derivation" : "(Aramaic) or מְעָא; (Aramaic), corresponding to H4578 (מֵעֶה)xlit mêʻâh corrected to mêʻeh",
		"def" : "only in plural the bowels",
		"kjv" : "belly"
	},
	"H4578" : {
		"lemma" : "מֵעֶה",
		"xlit" : "mêʻeh",
		"pron" : "may-aw'",
		"derivation" : "from an unused root probably meaning to be soft",
		"def" : "used only in plural the intestines, or (collectively) the abdomen, figuratively, sympathy; by implication, a vest; by extension the stomach, the uterus (or of men, the seat of generation), the heart (figuratively)",
		"kjv" : "belly, bowels, [idiom] heart, womb"
	},
	"H4579" : {
		"lemma" : "מֵעָה",
		"xlit" : "mêʻâh",
		"pron" : "may-aw'",
		"derivation" : "feminine of H4578 (מֵעֶה)xlit mêʻâh corrected to mêʻeh",
		"def" : "the belly, i.e. (figuratively) interior",
		"kjv" : "gravel"
	},
	"H4580" : {
		"lemma" : "מָעוֹג",
		"xlit" : "mâʻôwg",
		"pron" : "maw-ogue'",
		"derivation" : "from H5746 (עוּג); also with H3934 (לָעֵג) a table-buffoon, i.e",
		"def" : "a cake of bread; parasite",
		"kjv" : "cake, feast"
	},
	"H4581" : {
		"lemma" : "מָעוֹז",
		"xlit" : "mâʻôwz",
		"pron" : "maw-oze'",
		"derivation" : "(also מָעוּז ); or מָעֹז (also מָעֻז; from H5810 (עָזַז)",
		"def" : "a fortified place; figuratively, a defence",
		"kjv" : "force, fort(-ress), rock, strength(-en), ([idiom] most) strong (hold)"
	},
	"H4582" : {
		"lemma" : "מָעוֹךְ",
		"xlit" : "Mâʻôwk",
		"pron" : "maw-oke'",
		"derivation" : "from H4600 (מָעַךְ); oppressed",
		"def" : "Maok, a Philistine",
		"kjv" : "Maoch"
	},
	"H4583" : {
		"lemma" : "מָעוֹן",
		"xlit" : "mâʻôwn",
		"pron" : "maw-ohn'",
		"derivation" : "or מָעִין; (1 Chronicles 4:41), from the same as H5772 (עוֹנָה)",
		"def" : "an abode, of God (the Tabernacle or the Temple), men (their home) or animals (their lair); hence, a retreat (asylum)",
		"kjv" : "den, dwelling((-) place), habitation"
	},
	"H4584" : {
		"lemma" : "מָעוֹן",
		"xlit" : "Mâʻôwn",
		"pron" : "maw-ohn'",
		"derivation" : "the same as H4583 (מָעוֹן); a residence",
		"def" : "Maon, the name of an Israelite and of a place in Palestine",
		"kjv" : "Maon, Maonites. Compare H1010 (בֵּית בַּעַל מְעוֹן), H4586 (מְעוּנִי)lemma מְעוּנָי third vowel, corrected to מְעוּנִי"
	},
	"H4585" : {
		"lemma" : "מְעוֹנָה",
		"xlit" : "mᵉʻôwnâh",
		"pron" : "meh-o-naw'",
		"derivation" : "or מְעֹנָה; feminine of H4583 (מָעוֹן), and meaning the same",
		"def" : "{an abode, of God (the Tabernacle or the Temple), men (their home) or animals (their lair); hence, a retreat (asylum)}",
		"kjv" : "den, habitation, (dwelling) place, refuge"
	},
	"H4586" : {
		"lemma" : "מְעוּנִי",
		"xlit" : "Mᵉʻûwnîy",
		"pron" : "meh-oo-nee'",
		"derivation" : "or מְעִינִי; probably patrial from H4584 (מָעוֹן)",
		"def" : "a Meunite, or inhabitant of Maon (only in plural)",
		"kjv" : "Mehunim(-s), Meunim"
	},
	"H4587" : {
		"lemma" : "מְעוֹנֹתַי",
		"xlit" : "Mᵉʻôwnôthay",
		"pron" : "meh-o-no-thah'-ee",
		"derivation" : "plural of H4585 (מְעוֹנָה); habitative",
		"def" : "Meonothai, an Israelite",
		"kjv" : "Meonothai"
	},
	"H4588" : {
		"lemma" : "מָעוּף",
		"xlit" : "mâʻûwph",
		"pron" : "maw-off'",
		"derivation" : "from H5774 (עוּף) in the sense of covering with shade (compare H4155 (מוּעָף))",
		"def" : "darkness",
		"kjv" : "dimness"
	},
	"H4589" : {
		"lemma" : "מָעוֹר",
		"xlit" : "mâʻôwr",
		"pron" : "maw-ore'",
		"derivation" : "from H5783 (עוּר)",
		"def" : "nakedness, i.e. (in plural) the pudenda",
		"kjv" : "nakedness"
	},
	"H4590" : {
		"lemma" : "מַעַזְיָה",
		"xlit" : "Maʻazyâh",
		"pron" : "mah-az-yaw'",
		"derivation" : "or מַעַזְיָהוּ; probably from H5756 (עוּז) (in the sense of protection) and H3050 (יָהּ); rescue of Jah",
		"def" : "Maazjah, the name of two Israelites",
		"kjv" : "Maaziah"
	},
	"H4591" : {
		"lemma" : "מָעַט",
		"xlit" : "mâʻaṭ",
		"pron" : "maw-at'",
		"derivation" : "a primitive root",
		"def" : "properly, to pare off, i.e. lessen; intransitively, to be (or causatively, to make) small or few (or figuratively, ineffective)",
		"kjv" : "suffer to decrease, diminish, (be, [idiom] borrow a, give, make) few (in number, -ness), gather least (little), be (seem) little, ([idiom] give the) less, be minished, bring to nothing"
	},
	"H4592" : {
		"lemma" : "מְעַט",
		"xlit" : "mᵉʻaṭ",
		"pron" : "meh-at'",
		"derivation" : "or מְעָט; from H4591 (מָעַט)",
		"def" : "a little or few (often adverbial or compar.)",
		"kjv" : "almost (some, very) few(-er, -est), lightly, little (while), (very) small (matter, thing), some, soon, [idiom] very"
	},
	"H4593" : {
		"lemma" : "מָעֹט",
		"xlit" : "mâʻôṭ",
		"pron" : "maw-ote'",
		"derivation" : "passive adjective of H4591 (מָעַט)",
		"def" : "thinned (as to the edge), i.e. sharp",
		"kjv" : "wrapped up"
	},
	"H4594" : {
		"lemma" : "מַעֲטֶה",
		"xlit" : "maʻăṭeh",
		"pron" : "mah-at-eh'",
		"derivation" : "from H5844 (עָטָה)",
		"def" : "a vestment",
		"kjv" : "garment"
	},
	"H4595" : {
		"lemma" : "מַעֲטָפָה",
		"xlit" : "maʻăṭâphâh",
		"pron" : "mah-at-aw-faw'",
		"derivation" : "from H5848 (עָטַף)",
		"def" : "a cloak",
		"kjv" : "mantle"
	},
	"H4596" : {
		"lemma" : "מְעִי",
		"xlit" : "mᵉʻîy",
		"pron" : "meh-ee'",
		"derivation" : "from H5753 (עָוָה); (compare H5856 (עִי))",
		"def" : "a pile of rubbish (as contorted), i.e. a ruin",
		"kjv" : "heap"
	},
	"H4597" : {
		"lemma" : "מָעַי",
		"xlit" : "Mâʻay",
		"pron" : "maw-ah'-ee",
		"derivation" : "probably from H4578 (מֵעֶה)xlit mêʻâh corrected to mêʻeh; sympathetic",
		"def" : "Maai, an Israelite",
		"kjv" : "Maai"
	},
	"H4598" : {
		"lemma" : "מְעִיל",
		"xlit" : "mᵉʻîyl",
		"pron" : "meh-eel'",
		"derivation" : "from H4603 (מָעַל) in the sense of covering",
		"def" : "a robe (i.e. upper and outer garment)",
		"kjv" : "cloke, coat, mantle, robe"
	},
	"H4599" : {
		"lemma" : "מַעְיָן",
		"xlit" : "maʻyân",
		"pron" : "mah-yawn'",
		"derivation" : "or מַעְיְנוֹ; (Psalm 114:8), or (feminine) מַעְיָנָה; from H5869 (עַיִן) (as a denominative in the sense of a spring)",
		"def" : "a fountain (also collectively), figuratively, a source (of satisfaction)",
		"kjv" : "fountain, spring, well"
	},
	"H4600" : {
		"lemma" : "מָעַךְ",
		"xlit" : "mâʻak",
		"pron" : "maw-ak'",
		"derivation" : "a primitive root",
		"def" : "to press, i.e. to pierce, emasculate, handle",
		"kjv" : "bruised, stuck, be pressed"
	},
	"H4601" : {
		"lemma" : "מַעֲכָה",
		"xlit" : "Maʻăkâh",
		"pron" : "mah-ak-aw'",
		"derivation" : "or מַעֲכָת; (Joshua 13:13), from H4600 (מָעַךְ); depression",
		"def" : "Maakah (or Maakath), the name of a place in Syria, also of a Mesopotamian, of three Israelites, and of four Israelitesses and one Syrian woman",
		"kjv" : "Maachah, Maachathites. See also H1038 (בֵּית מַעֲכָה)"
	},
	"H4602" : {
		"lemma" : "מַעֲכָתִי",
		"xlit" : "Maʻăkâthîy",
		"pron" : "mah-ak-aw-thee'",
		"derivation" : "patrial from H4601 (מַעֲכָה)",
		"def" : "a Maakathite, or inhabitant of Maakah",
		"kjv" : "Maachathite"
	},
	"H4603" : {
		"lemma" : "מָעַל",
		"xlit" : "mâʻal",
		"pron" : "maw-al'",
		"derivation" : "a primitive root",
		"def" : "properly, to cover up; used only figuratively, to act covertly, i.e. treacherously",
		"kjv" : "transgress, (commit, do a) trespass(-ing)"
	},
	"H4604" : {
		"lemma" : "מַעַל",
		"xlit" : "maʻal",
		"pron" : "mah'-al",
		"derivation" : "from H4603 (מָעַל)",
		"def" : "treachery, i.e. sin",
		"kjv" : "falsehood, grievously, sore, transgression, trespass, [idiom] very"
	},
	"H4605" : {
		"lemma" : "מַעַל",
		"xlit" : "maʻal",
		"pron" : "mah'al",
		"derivation" : "from H5927 (עָלָה)",
		"def" : "properly, the upper part, used only adverbially with prefix upward, above, overhead, from the top, etc.",
		"kjv" : "above, exceeding(-ly), forward, on ([idiom] very) high, over, up(-on, -ward), very"
	},
	"H4606" : {
		"lemma" : "מֵעָל",
		"xlit" : "mêʻâl",
		"pron" : "may-awl'",
		"derivation" : "(Aramaic) from H5954 (עֲלַל)",
		"def" : "(only in plural as singular) the setting (of the sun)",
		"kjv" : "going down"
	},
	"H4607" : {
		"lemma" : "מֹעַל",
		"xlit" : "môʻal",
		"pron" : "mo'-al",
		"derivation" : "from H5927 (עָלָה)",
		"def" : "a raising (of the hands)",
		"kjv" : "lifting up"
	},
	"H4608" : {
		"lemma" : "מַעֲלֶה",
		"xlit" : "maʻăleh",
		"pron" : "mah-al-eh'",
		"derivation" : "from H5927 (עָלָה)",
		"def" : "an elevation, i.e. (concretely) acclivity or platform; abstractly (the relation or state) a rise or (figuratively) priority",
		"kjv" : "ascent, before, chiefest, cliff, that goeth up, going up, hill, mounting up, stairs"
	},
	"H4609" : {
		"lemma" : "מַעֲלָה",
		"xlit" : "maʻălâh",
		"pron" : "mah-al-aw'",
		"derivation" : "feminine of H4608 (מַעֲלֶה)",
		"def" : "elevation, i.e. the act (literally, a journey to a higher place, figuratively, a thought arising), or (concretely) the condition (literally, a step or grademark, figuratively, a superiority of station); specifically a climactic progression (in certain Psalms)",
		"kjv" : "things that come up, (high) degree, deal, go up, stair, step, story"
	},
	"H4610" : {
		"lemma" : "מַעֲלֵה עַקְרַבִּים",
		"xlit" : "Maʻălêh ʻAqrabbîym",
		"pron" : "mah-al-ay' ak-rabbeem'",
		"derivation" : "from H4608 (מַעֲלֶה) and (the plural of) H6137 (עַקְרָב)",
		"def" : "Steep of Scorpions, a place in the Desert",
		"kjv" : "Maaleh-accrabim, the ascent (going up) of Akrabbim"
	},
	"H4611" : {
		"lemma" : "מַעֲלָל",
		"xlit" : "maʻălâl",
		"pron" : "mah-al-awl'",
		"derivation" : "from H5953 (עָלַל)",
		"def" : "an act (good or bad)",
		"kjv" : "doing, endeavour, invention, work"
	},
	"H4612" : {
		"lemma" : "מַעֲמָד",
		"xlit" : "maʻămâd",
		"pron" : "mah-am-awd'",
		"derivation" : "from H5975 (עָמַד)",
		"def" : "(figuratively) a position",
		"kjv" : "attendance, office, place, state"
	},
	"H4613" : {
		"lemma" : "מׇעֳמָד",
		"xlit" : "moʻŏmâd",
		"pron" : "moh-om-awd'",
		"derivation" : "from H5975 (עָמַד)",
		"def" : "literally a foothold",
		"kjv" : "standing"
	},
	"H4614" : {
		"lemma" : "מַעֲמָסָה",
		"xlit" : "maʻămâçâh",
		"pron" : "mah-am-aw-saw'",
		"derivation" : "from H6006 (עָמַס)",
		"def" : "burdensomeness",
		"kjv" : "burdensome"
	},
	"H4615" : {
		"lemma" : "מַעֲמָק",
		"xlit" : "maʻămâq",
		"pron" : "mah-am-awk'",
		"derivation" : "from H6009 (עָמַק)",
		"def" : "a deep",
		"kjv" : "deep, depth"
	},
	"H4616" : {
		"lemma" : "מַעַן",
		"xlit" : "maʻan",
		"pron" : "mah'-an",
		"derivation" : "from H6030 (עָנָה)",
		"def" : "properly, heed, i.e. purpose; used only adverbially, on account of (as a motive or an aim), teleologically, in order that",
		"kjv" : "because of, to the end (intent) that, for (to,... 's sake), [phrase] lest, that, to"
	},
	"H4617" : {
		"lemma" : "מַעֲנֶה",
		"xlit" : "maʻăneh",
		"pron" : "mah-an-eh'",
		"derivation" : "from H6030 (עָנָה)",
		"def" : "a reply (favorable or contradictory)",
		"kjv" : "answer, [idiom] himself"
	},
	"H4618" : {
		"lemma" : "מַעֲנָה",
		"xlit" : "maʻănâh",
		"pron" : "mah-an-aw'",
		"derivation" : "from H6031 (עָנָה), in the sense of depression or tilling",
		"def" : "a furrow",
		"kjv" : "[phrase] acre, furrow"
	},
	"H4619" : {
		"lemma" : "מַעַץ",
		"xlit" : "Maʻats",
		"pron" : "mah'-ats",
		"derivation" : "from H6095 (עָצָה); closure",
		"def" : "Maats, an Israelite",
		"kjv" : "Maaz"
	},
	"H4620" : {
		"lemma" : "מַעֲצֵבָה",
		"xlit" : "maʻătsêbâh",
		"pron" : "mah-ats-ay-baw'",
		"derivation" : "from H6087 (עָצַב)",
		"def" : "anguish",
		"kjv" : "sorrow"
	},
	"H4621" : {
		"lemma" : "מַעֲצָד",
		"xlit" : "maʻătsâd",
		"pron" : "mah-ats-awd'",
		"derivation" : "from an unused root meaning to hew",
		"def" : "an axe",
		"kjv" : "ax, tongs"
	},
	"H4622" : {
		"lemma" : "מַעְצוֹר",
		"xlit" : "maʻtsôwr",
		"pron" : "mah-tsore'",
		"derivation" : "from H6113 (עָצָר)",
		"def" : "objectively, a hindrance",
		"kjv" : "restraint"
	},
	"H4623" : {
		"lemma" : "מַעְצָר",
		"xlit" : "maʻtsâr",
		"pron" : "mah-tsawr'",
		"derivation" : "from H6113 (עָצָר)",
		"def" : "subjectively, control",
		"kjv" : "rule"
	},
	"H4624" : {
		"lemma" : "מַעֲקֶה",
		"xlit" : "maʻăqeh",
		"pron" : "mah-ak-eh'",
		"derivation" : "from an unused root meaning to repress",
		"def" : "a parapet",
		"kjv" : "battlement"
	},
	"H4625" : {
		"lemma" : "מַעֲקָשׁ",
		"xlit" : "maʻăqâsh",
		"pron" : "mah-ak-awsh'",
		"derivation" : "from H6140 (עָקַשׁ)",
		"def" : "a crook (in a road)",
		"kjv" : "crooked thing"
	},
	"H4626" : {
		"lemma" : "מַעַר",
		"xlit" : "maʻar",
		"pron" : "mah'-ar",
		"derivation" : "from H6168 (עָרָה)",
		"def" : "a nude place, i.e. (literally) the pudenda, or (figuratively) a vacant space",
		"kjv" : "nakedness, proportion"
	},
	"H4627" : {
		"lemma" : "מַעֲרָב",
		"xlit" : "maʻărâb",
		"pron" : "mah-ar-awb'",
		"derivation" : "from H6148 (עָרַב), in the sense of trading",
		"def" : "traffic; by implication, mercantile goods",
		"kjv" : "market, merchandise"
	},
	"H4628" : {
		"lemma" : "מַעֲרָב",
		"xlit" : "maʻărâb",
		"pron" : "mah-ar-awb'",
		"derivation" : "or (feminine) מַעֲרָבָה; from H6150 (עָרַב), in the sense of shading",
		"def" : "the west (as a region of the evening sun)",
		"kjv" : "west"
	},
	"H4629" : {
		"lemma" : "מַעֲרֶה",
		"xlit" : "maʻăreh",
		"pron" : "mah-ar-eh'",
		"derivation" : "from H6168 (עָרָה)",
		"def" : "a nude place, i.e. a common",
		"kjv" : "meadows"
	},
	"H4630" : {
		"lemma" : "מַעֲרָה",
		"xlit" : "maʻărâh",
		"pron" : "mah-ar-aw'",
		"derivation" : "feminine of H4629 (מַעֲרֶה)",
		"def" : "an open spot",
		"kjv" : "army (from the margin)"
	},
	"H4631" : {
		"lemma" : "מְעָרָה",
		"xlit" : "mᵉʻârâh",
		"pron" : "meh-aw-raw'",
		"derivation" : "from H5783 (עוּר)",
		"def" : "a cavern (as dark)",
		"kjv" : "cave, den, hole"
	},
	"H4632" : {
		"lemma" : "מְעָרָה",
		"xlit" : "Mᵉʻârâh",
		"pron" : "meh-aw-raw'",
		"derivation" : "the same as H4631 (מְעָרָה); cave",
		"def" : "Mearah, a place in Palestine",
		"kjv" : "Mearah"
	},
	"H4633" : {
		"lemma" : "מַעֲרָךְ",
		"xlit" : "maʻărâk",
		"pron" : "mah-ar-awk'",
		"derivation" : "from H6186 (עָרַךְ)",
		"def" : "an arrangement, i.e. (figuratively) mental disposition",
		"kjv" : "preparation"
	},
	"H4634" : {
		"lemma" : "מַעֲרָכָה",
		"xlit" : "maʻărâkâh",
		"pron" : "mah-ar-aw-kaw'",
		"derivation" : "feminine of H4633 (מַעֲרָךְ)",
		"def" : "an arrangement; concretely, a pile; specifically a military array",
		"kjv" : "army, fight, be set in order, ordered place, rank, row"
	},
	"H4635" : {
		"lemma" : "מַעֲרֶכֶת",
		"xlit" : "maʻăreketh",
		"pron" : "mah-ar-eh'-keth",
		"derivation" : "from H6186 (עָרַךְ)",
		"def" : "an arrangement, i.e. (concretely) a pile (of loaves)",
		"kjv" : "row, shewbread"
	},
	"H4636" : {
		"lemma" : "מַעֲרֹם",
		"xlit" : "maʻărôm",
		"pron" : "mah-ar-ome'",
		"derivation" : "from H6191 (עָרַם), in the sense of stripping",
		"def" : "bare",
		"kjv" : "naked"
	},
	"H4637" : {
		"lemma" : "מַעֲרָצָה",
		"xlit" : "maʻărâtsâh",
		"pron" : "mah-ar-aw-tsaw'",
		"derivation" : "from H6206 (עָרַץ)",
		"def" : "violence",
		"kjv" : "terror"
	},
	"H4638" : {
		"lemma" : "מַעֲרָת",
		"xlit" : "Maʻărâth",
		"pron" : "mah-ar-awth'",
		"derivation" : "a form of H4630 (מַעֲרָה); waste",
		"def" : "Maarath, a place in Palestine",
		"kjv" : "Maarath"
	},
	"H4639" : {
		"lemma" : "מַעֲשֶׂה",
		"xlit" : "maʻăseh",
		"pron" : "mah-as-eh'",
		"derivation" : "from H6213 (עָשָׂה)",
		"def" : "an action (good or bad); generally, a transaction; abstractly, activity; by implication, a product (specifically, a poem) or (generally) property",
		"kjv" : "act, art, [phrase] bakemeat, business, deed, do(-ing), labor, thing made, ware of making, occupation, thing offered, operation, possession, [idiom] well, (handy-, needle-, net-) work(ing, -manship), wrought"
	},
	"H4640" : {
		"lemma" : "מַעֲשַׂי",
		"xlit" : "Maʻăsay",
		"pron" : "mah-as-ah'ee",
		"derivation" : "from H6213 (עָשָׂה); operative",
		"def" : "Maasai, an Israelite",
		"kjv" : "Maasiai"
	},
	"H4641" : {
		"lemma" : "מַעֲשֵׂיָה",
		"xlit" : "Maʻăsêyâh",
		"pron" : "mah-as-ay-yaw'",
		"derivation" : "or מַעֲשֵׂיָהוּ; from H4639 (מַעֲשֶׂה) and H3050 (יָהּ); work of Jah",
		"def" : "Maasejah, the name of sixteen Israelites",
		"kjv" : "Maaseiah"
	},
	"H4642" : {
		"lemma" : "מַעֲשַׁקָּה",
		"xlit" : "maʻăshaqqâh",
		"pron" : "mah-ash-ak-kaw'",
		"derivation" : "from H6231 (עָשַׁק)",
		"def" : "oppression",
		"kjv" : "oppression, [idiom] oppressor"
	},
	"H4643" : {
		"lemma" : "מַעֲשֵׂר",
		"xlit" : "maʻăsêr",
		"pron" : "mah-as-ayr'",
		"derivation" : "or מַעֲשַׂר; and (in plural) feminine מַעַשְׂרָה; from H6240 (עָשָׂר)",
		"def" : "a tenth; especially a tithe",
		"kjv" : "tenth (part), tithe(-ing)"
	},
	"H4644" : {
		"lemma" : "מֹף",
		"xlit" : "Môph",
		"pron" : "mofe",
		"derivation" : "of Egyptian origin",
		"def" : "Moph, the capital of Lower Egypt",
		"kjv" : "Memphis. Compare H5297 (נֹף)"
	},
	"H4645" : {
		"lemma" : "מִפְגָּע",
		"xlit" : "miphgâʻ",
		"pron" : "mif-gaw'",
		"derivation" : "from H6293 (פָּגַע)",
		"def" : "an object of attack",
		"kjv" : "mark"
	},
	"H4646" : {
		"lemma" : "מַפָּח",
		"xlit" : "mappâch",
		"pron" : "map-pawkh'",
		"derivation" : "from H5301 (נָפַח)",
		"def" : "a breathing out (of life), i.e. expiring",
		"kjv" : "giving up"
	},
	"H4647" : {
		"lemma" : "מַפֻּחַ",
		"xlit" : "mappuach",
		"pron" : "map-poo'-akh",
		"derivation" : "from H5301 (נָפַח)",
		"def" : "the bellows (i.e. blower) of aforge",
		"kjv" : "bellows"
	},
	"H4648" : {
		"lemma" : "מְפִיבֹשֶׁת",
		"xlit" : "Mᵉphîybôsheth",
		"pron" : "mef-ee-bo'-sheth",
		"derivation" : "or מְפִבֹשֶׁת; probably from H6284 (פָּאָה) and H1322 (בֹּשֶׁת); dispeller of shame (i.e. of Baal)",
		"def" : "Mephibosheth, the name of two Israelites",
		"kjv" : "Mephibosheth"
	},
	"H4649" : {
		"lemma" : "מֻפִּים",
		"xlit" : "Muppîym",
		"pron" : "moop-peem'",
		"derivation" : "a plural apparently from H5130 (נוּף); wavings",
		"def" : "Muppim, an Israelite",
		"kjv" : "Muppim. Compare H8206 (שֻׁפִּים)"
	},
	"H4650" : {
		"lemma" : "מֵפִיץ",
		"xlit" : "mêphîyts",
		"pron" : "may-feets'",
		"derivation" : "from H6327 (פּוּץ)",
		"def" : "a breaker, i.e. mallet",
		"kjv" : "maul"
	},
	"H4651" : {
		"lemma" : "מַפָּל",
		"xlit" : "mappâl",
		"pron" : "map-pawl'",
		"derivation" : "from H5307 (נָפַל)",
		"def" : "a falling off, i.e. chaff; also something pendulous, i.e. a flap",
		"kjv" : "flake, refuse"
	},
	"H4652" : {
		"lemma" : "מִפְלָאָה",
		"xlit" : "miphlâʼâh",
		"pron" : "mif-law-aw'",
		"derivation" : "from H6381 (פָּלָא)",
		"def" : "a miracle",
		"kjv" : "wondrous work"
	},
	"H4653" : {
		"lemma" : "מִפְלַגָּה",
		"xlit" : "miphlaggâh",
		"pron" : "mif-lag-gaw'",
		"derivation" : "from H6385 (פָּלַג)",
		"def" : "a classification",
		"kjv" : "division"
	},
	"H4654" : {
		"lemma" : "מַפָּלָה",
		"xlit" : "mappâlâh",
		"pron" : "map-paw-law'",
		"derivation" : "or מַפֵּלָה; from H5307 (נָפַל)",
		"def" : "something fallen, i.e. a ruin",
		"kjv" : "ruin(-ous)"
	},
	"H4655" : {
		"lemma" : "מִפְלָט",
		"xlit" : "miphlâṭ",
		"pron" : "mif-lawt'",
		"derivation" : "from H6403 (פָּלַט)",
		"def" : "an escape",
		"kjv" : "escape"
	},
	"H4656" : {
		"lemma" : "מִפְלֶצֶת",
		"xlit" : "miphletseth",
		"pron" : "mif-leh'-tseth",
		"derivation" : "from H6426 (פָּלַץ)",
		"def" : "a terror, i.e. an idol",
		"kjv" : "idol"
	},
	"H4657" : {
		"lemma" : "מִפְלָשׂ",
		"xlit" : "miphlâs",
		"pron" : "mif-lawce'",
		"derivation" : "from an unused root meaning to balance",
		"def" : "a poising",
		"kjv" : "balancing"
	},
	"H4658" : {
		"lemma" : "מַפֶּלֶת",
		"xlit" : "mappeleth",
		"pron" : "map-peh'-leth",
		"derivation" : "from H5307 (נָפַל)",
		"def" : "fall, i.e. decadence; concretely, a ruin; specifically a carcase",
		"kjv" : "carcase, fall, ruin"
	},
	"H4659" : {
		"lemma" : "מִפְעָל",
		"xlit" : "miphʻâl",
		"pron" : "mif-awl'",
		"derivation" : "or (feminine) מִפְעָלָה; from H6466 (פָּעַל)",
		"def" : "a performance",
		"kjv" : "work"
	},
	"H4660" : {
		"lemma" : "מַפָּץ",
		"xlit" : "mappâts",
		"pron" : "map-pawts'",
		"derivation" : "from H5310 (נָפַץ)",
		"def" : "a smiting to pieces",
		"kjv" : "slaughter"
	},
	"H4661" : {
		"lemma" : "מַפֵּץ",
		"xlit" : "mappêts",
		"pron" : "map-pates'",
		"derivation" : "from H5310 (נָפַץ)",
		"def" : "a smiter, i.e. a war club",
		"kjv" : "battle ax"
	},
	"H4662" : {
		"lemma" : "מִפְקָד",
		"xlit" : "miphqâd",
		"pron" : "mif-kawd'",
		"derivation" : "from H6485 (פָּקַד)",
		"def" : "an appointment, i.e. mandate; concretely, a designated spot; specifically, a census",
		"kjv" : "appointed place, commandment, number"
	},
	"H4663" : {
		"lemma" : "מִפְקָד",
		"xlit" : "Miphqâd",
		"pron" : "mif-kawd'",
		"derivation" : "the same as H4662 (מִפְקָד); assignment",
		"def" : "Miphkad, the name of a gate in Jerusalem",
		"kjv" : "Miphkad"
	},
	"H4664" : {
		"lemma" : "מִפְרָץ",
		"xlit" : "miphrâts",
		"pron" : "mif-rawts'",
		"derivation" : "from H6555 (פָּרַץ)lemma פּרַץ missing vowel, corrected to פָּרַץ",
		"def" : "a break (in the shore), i.e. a haven",
		"kjv" : "breach"
	},
	"H4665" : {
		"lemma" : "מִפְרֶקֶת",
		"xlit" : "miphreqeth",
		"pron" : "mif-reh'-keth",
		"derivation" : "from H6561 (פָּרַק)",
		"def" : "properly, a fracture, i.e. joint (vertebrae) of the neck",
		"kjv" : "neck"
	},
	"H4666" : {
		"lemma" : "מִפְרָשׂ",
		"xlit" : "miphrâs",
		"pron" : "mif-rawce'",
		"derivation" : "from H6566 (פָּרַשׂ)",
		"def" : "an expansion",
		"kjv" : "that which...spreadest forth, spreading"
	},
	"H4667" : {
		"lemma" : "מִפְשָׂעָה",
		"xlit" : "miphsâʻâh",
		"pron" : "mif-saw-aw'",
		"derivation" : "from H6585 (פָּשַׂע)",
		"def" : "a stride, i.e. (by euphemism) the crotch",
		"kjv" : "buttocks"
	},
	"H4668" : {
		"lemma" : "מַפְתֵּחַ",
		"xlit" : "maphtêach",
		"pron" : "maf-tay'-akh",
		"derivation" : "from H6605 (פָּתַח)",
		"def" : "an opener, i.e. a key",
		"kjv" : "key"
	},
	"H4669" : {
		"lemma" : "מִפְתָּח",
		"xlit" : "miphtâch",
		"pron" : "mif-tawkh'",
		"derivation" : "from H6605 (פָּתַח)",
		"def" : "an aperture, i.e. (figuratively) utterance",
		"kjv" : "opening"
	},
	"H4670" : {
		"lemma" : "מִפְתָּן",
		"xlit" : "miphtân",
		"pron" : "mif-tawn'",
		"derivation" : "from the same as H6620 (פֶּתֶן)",
		"def" : "a stretcher, i.e. a sill",
		"kjv" : "threshold"
	},
	"H4671" : {
		"lemma" : "מֹץ",
		"xlit" : "môts",
		"pron" : "motes",
		"derivation" : "or מוֹץ; (Zephaniah 2:2), from H4160 (מוּץ)",
		"def" : "chaff (as pressed out, i.e. winnowed or (rather) threshed loose)",
		"kjv" : "chaff"
	},
	"H4672" : {
		"lemma" : "מָצָא",
		"xlit" : "mâtsâʼ",
		"pron" : "maw-tsaw'",
		"derivation" : "a primitive root",
		"def" : "properly, to come forth to, i.e. appear or exist; transitively, to attain, i.e. find or acquire; figuratively, to occur, meet or be present",
		"kjv" : "[phrase] be able, befall, being, catch, [idiom] certainly, (cause to) come (on, to, to hand), deliver, be enough (cause to) find(-ing, occasion, out), get (hold upon), [idiom] have (here), be here, hit, be left, light (up-) on, meet (with), [idiom] occasion serve, (be) present, ready, speed, suffice, take hold on"
	},
	"H4673" : {
		"lemma" : "מַצָּב",
		"xlit" : "matstsâb",
		"pron" : "mats-tsawb'",
		"derivation" : "from H5324 (נָצַב)",
		"def" : "a fixed spot; figuratively, an office, a military post",
		"kjv" : "garrison, station, place where...stood"
	},
	"H4674" : {
		"lemma" : "מֻצָּב",
		"xlit" : "mutstsâb",
		"pron" : "moots-tsawb'",
		"derivation" : "from H5324 (נָצַב)",
		"def" : "a station, i.e. military post",
		"kjv" : "mount"
	},
	"H4675" : {
		"lemma" : "מַצָּבָה",
		"xlit" : "matstsâbâh",
		"pron" : "mats-tsaw-baw'",
		"derivation" : "or מִצָּבָה; feminine of H4673 (מַצָּב)",
		"def" : "a military guard",
		"kjv" : "army, garrison"
	},
	"H4676" : {
		"lemma" : "מַצֵּבָה",
		"xlit" : "matstsêbâh",
		"pron" : "mats-tsay-baw'",
		"derivation" : "feminine (causatively) participle of H5324 (נָצַב)",
		"def" : "something stationed, i.e. a column or (memorial stone); by analogy, an idol",
		"kjv" : "garrison, (standing) image, pillar"
	},
	"H4677" : {
		"lemma" : "מְצֹבָיָה",
		"xlit" : "Mᵉtsôbâyâh",
		"pron" : "mets-o-baw-yaw'",
		"derivation" : "apparently from H4672 (מָצָא) and H3050 (יָהּ); found of Jah",
		"def" : "Metsobajah, a place in Palestine",
		"kjv" : "Mesobaite"
	},
	"H4678" : {
		"lemma" : "מַצֶּבֶת",
		"xlit" : "matstsebeth",
		"pron" : "mats-tseh'-beth",
		"derivation" : "from H5324 (נָצַב)",
		"def" : "something stationary, i.e. a monumental stone; also the stock of atree",
		"kjv" : "pillar, substance"
	},
	"H4679" : {
		"lemma" : "מְצַד",
		"xlit" : "mᵉtsad",
		"pron" : "mets-ad'",
		"derivation" : "or מְצָד; or (feminine) מְצָדָה; from H6679 (צוּד)",
		"def" : "a fastness (as a covert of ambush)",
		"kjv" : "castle, fort, (strong) hold, munition"
	},
	"H4680" : {
		"lemma" : "מָצָה",
		"xlit" : "mâtsâh",
		"pron" : "maw-tsaw'",
		"derivation" : "a primitive root",
		"def" : "to suck out; by implication, to drain, to squeeze out",
		"kjv" : "suck, wring (out)"
	},
	"H4681" : {
		"lemma" : "מֹצָה",
		"xlit" : "Môtsâh",
		"pron" : "mo-tsaw'",
		"derivation" : "active participle feminine of H4680 (מָצָה); drained",
		"def" : "Motsah, a place in Palestine",
		"kjv" : "Mozah"
	},
	"H4682" : {
		"lemma" : "מַצָּה",
		"xlit" : "matstsâh",
		"pron" : "mats-tsaw'",
		"derivation" : "from H4711 (מָצַץ) in the sense of greedily devouring for sweetness",
		"def" : "properly, sweetness; concretely, sweet (i.e. not soured or bittered with yeast); specifically, an unfermented cake or loaf, or (elliptically) the festival of Passover (because no leaven was then used)",
		"kjv" : "unleaved (bread, cake), without leaven"
	},
	"H4683" : {
		"lemma" : "מַצָּה",
		"xlit" : "matstsâh",
		"pron" : "mats-tsaw'",
		"derivation" : "from H5327 (נָצָה)",
		"def" : "a quarrel",
		"kjv" : "contention, debate, strife"
	},
	"H4684" : {
		"lemma" : "מַצְהָלָה",
		"xlit" : "matshâlâh",
		"pron" : "mats-haw-law'",
		"derivation" : "from H6670 (צָהַל)",
		"def" : "a whinnying (through impatience for battle or lust)",
		"kjv" : "neighing"
	},
	"H4685" : {
		"lemma" : "מָצוֹד",
		"xlit" : "mâtsôwd",
		"pron" : "maw-tsode'",
		"derivation" : "or (feminine) מְצוֹדָה; or מצֹדָה; from H6679 (צוּד); also (by interchange for H4679 (מְצַד))",
		"def" : "a net (for capturing animals or fishes);  afastness or (beseiging) tower",
		"kjv" : "bulwark, hold, munition, net, snare"
	},
	"H4686" : {
		"lemma" : "מָצוּד",
		"xlit" : "mâtsûwd",
		"pron" : "maw-tsood'",
		"derivation" : "or (feminine) מְצוּדָה; or מְצֻדָה; for H4685 (מָצוֹד)",
		"def" : "a net, or (abstractly) capture; also a fastness",
		"kjv" : "castle, defense, fort(-ress), (strong) hold, be hunted, net, snare, strong place"
	},
	"H4687" : {
		"lemma" : "מִצְוָה",
		"xlit" : "mitsvâh",
		"pron" : "mits-vaw'",
		"derivation" : "from H6680 (צָוָה)",
		"def" : "a command, whether human or divine (collectively, the Law)",
		"kjv" : "(which was) commanded(-ment), law, ordinance, precept"
	},
	"H4688" : {
		"lemma" : "מְצוֹלָה",
		"xlit" : "mᵉtsôwlâh",
		"pron" : "mets-o-law'",
		"derivation" : "or מְצֹלָה; also מְצוּלָה; or מְצֻלָה; from the same as H6683 (צוּלָה)",
		"def" : "a deep place (of water or mud)",
		"kjv" : "bottom, deep, depth"
	},
	"H4689" : {
		"lemma" : "מָצוֹק",
		"xlit" : "mâtsôwq",
		"pron" : "maw-tsoke'",
		"derivation" : "from H6693 (צוּק)",
		"def" : "a narrow place, i.e. (abstractly and figuratively) confinement or disability",
		"kjv" : "anguish, distress, straitness"
	},
	"H4690" : {
		"lemma" : "מָצוּק",
		"xlit" : "mâtsûwq",
		"pron" : "maw-tsook'",
		"derivation" : "or מָצֻק; from H6693 (צוּק)",
		"def" : "something narrow, i.e. a column or hilltop",
		"kjv" : "pillar, situate"
	},
	"H4691" : {
		"lemma" : "מְצוּקָה",
		"xlit" : "mᵉtsûwqâh",
		"pron" : "mets-oo-kaw'",
		"derivation" : "or מְצֻקָה; feminine of H4690 (מָצוּק)",
		"def" : "narrowness, i.e. (figuratively) trouble",
		"kjv" : "anguish, distress"
	},
	"H4692" : {
		"lemma" : "מָצוֹר",
		"xlit" : "mâtsôwr",
		"pron" : "maw-tsore'",
		"derivation" : "or מָצוּר; from H6696 (צוּר)",
		"def" : "something hemming in, i.e. (objectively) a mound (of besiegers), (abstractly) a siege, (figuratively) distress; or (subjectively) a fastness",
		"kjv" : "besieged, bulwark, defence, fenced, fortress, siege, strong (hold), tower"
	},
	"H4693" : {
		"lemma" : "מָצוֹר",
		"xlit" : "mâtsôwr",
		"pron" : "maw-tsore'",
		"derivation" : "the same as H4692 (מָצוֹר) in the sense of a limit",
		"def" : "Egypt (as the border of Palestine)",
		"kjv" : "besieged places, defense, fortified"
	},
	"H4694" : {
		"lemma" : "מְצוּרָה",
		"xlit" : "mᵉtsûwrâh",
		"pron" : "mets-oo-raw'",
		"derivation" : "or מְצֻרָה; feminine of H4692 (מָצוֹר)",
		"def" : "a hemming in, i.e. (objectively) a mound (of siege), or (subjectively) a rampart (of protection), (abstractly) fortification",
		"kjv" : "fenced (city, fort, munition, strong hold"
	},
	"H4695" : {
		"lemma" : "מַצּוּת",
		"xlit" : "matstsûwth",
		"pron" : "mats-tsooth'",
		"derivation" : "from H5327 (נָצָה)",
		"def" : "a quarrel",
		"kjv" : "that contended"
	},
	"H4696" : {
		"lemma" : "מֵצַח",
		"xlit" : "mêtsach",
		"pron" : "may'-tsakh",
		"derivation" : "from an unused root meaning to be clear, i.e. conspicuous",
		"def" : "the forehead (as open and prominent)",
		"kjv" : "brow, forehead, [phrase] impudent"
	},
	"H4697" : {
		"lemma" : "מִצְחָה",
		"xlit" : "mitschâh",
		"pron" : "mits-khaw'",
		"derivation" : "from the same as H4696 (מֵצַח)",
		"def" : "a shinpiece of armor (as prominent), only plural",
		"kjv" : "greaves"
	},
	"H4698" : {
		"lemma" : "מְצִלָּה",
		"xlit" : "mᵉtsillâh",
		"pron" : "mets-il-law'",
		"derivation" : "from H6750 (צָלַל)",
		"def" : "a tinkler, i.e. a bell",
		"kjv" : "bell"
	},
	"H4699" : {
		"lemma" : "מְצֻלָּה",
		"xlit" : "mᵉtsullâh",
		"pron" : "mets-ool-law'",
		"derivation" : "from H6751 (צָלַל)",
		"def" : "shade",
		"kjv" : "bottom"
	},
	"H4700" : {
		"lemma" : "מְצֵלֶת",
		"xlit" : "mᵉtsêleth",
		"pron" : "mets-ay'-leth",
		"derivation" : "from H6750 (צָלַל)",
		"def" : "(only dual) double tinklers, i.e. cymbals",
		"kjv" : "cymbals"
	},
	"H4701" : {
		"lemma" : "מִצְנֶפֶת",
		"xlit" : "mitsnepheth",
		"pron" : "mits-neh'-feth",
		"derivation" : "from H6801 (צָנַף)",
		"def" : "a tiara, i.e. official turban (of a king or high priest)",
		"kjv" : "diadem, mitre"
	},
	"H4702" : {
		"lemma" : "מַצָּע",
		"xlit" : "matstsâʻ",
		"pron" : "mats-tsaw'",
		"derivation" : "from H3331 (יַצַע)",
		"def" : "a couch",
		"kjv" : "bed"
	},
	"H4703" : {
		"lemma" : "מִצְעָד",
		"xlit" : "mitsʻâd",
		"pron" : "mits-awd'",
		"derivation" : "from H6805 (צָעַד)",
		"def" : "a step; figuratively, companionship",
		"kjv" : "going, step"
	},
	"H4704" : {
		"lemma" : "מִצְּעִירָה",
		"xlit" : "mitstsᵉʻîyrâh",
		"pron" : "mits-tseh-ee-raw'",
		"derivation" : "feminine of H4705 (מִצְעָר)",
		"def" : "properly, littleness; concretely, diminutive",
		"kjv" : "little"
	},
	"H4705" : {
		"lemma" : "מִצְעָר",
		"xlit" : "mitsʻâr",
		"pron" : "mits-awr'",
		"derivation" : "from H6819 (צָעַר)",
		"def" : "petty (in size or number); adverbially, a short (time)",
		"kjv" : "little one (while), small"
	},
	"H4706" : {
		"lemma" : "מִצְעָר",
		"xlit" : "Mitsʻâr",
		"pron" : "mits-awr'",
		"derivation" : "the same as H4705 (מִצְעָר)",
		"def" : "Mitsar, a peak of Lebanon",
		"kjv" : "Mizar"
	},
	"H4707" : {
		"lemma" : "מִצְפֶּה",
		"xlit" : "mitspeh",
		"pron" : "mits-peh'",
		"derivation" : "from H6822 (צָפָה)",
		"def" : "an observatory, especially formilitary purposes",
		"kjv" : "watch tower"
	},
	"H4708" : {
		"lemma" : "מִצְפֶּה",
		"xlit" : "Mitspeh",
		"pron" : "mits-peh'",
		"derivation" : "the same as H4707 (מִצְפֶּה)",
		"def" : "Mitspeh, the name of five places in Palestine",
		"kjv" : "Mizpeh, watch tower. Compare H4709 (מִצְפָּה)"
	},
	"H4709" : {
		"lemma" : "מִצְפָּה",
		"xlit" : "Mitspâh",
		"pron" : "mits-paw'",
		"derivation" : "feminine of H4708 (מִצְפֶּה)",
		"def" : "Mitspah, the name of two places in Palestine",
		"kjv" : "Mitspah. (This seems rather to be only an orthographic variation of H4708 (מִצְפֶּה) when 'in pause'.)"
	},
	"H4710" : {
		"lemma" : "מִצְפֻּן",
		"xlit" : "mitspun",
		"pron" : "mits-poon'",
		"derivation" : "from H6845 (צָפַן)",
		"def" : "a secret (place or thing, perhaps, treasure)",
		"kjv" : "hidden thing"
	},
	"H4711" : {
		"lemma" : "מָצַץ",
		"xlit" : "mâtsats",
		"pron" : "maw-tsats'",
		"derivation" : "a primitive root",
		"def" : "to suck",
		"kjv" : "milk"
	},
	"H4712" : {
		"lemma" : "מֵצַר",
		"xlit" : "mêtsar",
		"pron" : "may-tsar'",
		"derivation" : "from H6896 (קֵבָה)",
		"def" : "something tight, i.e. (figuratively) trouble",
		"kjv" : "distress, pain, strait"
	},
	"H4713" : {
		"lemma" : "מִצְרִי",
		"xlit" : "Mitsrîy",
		"pron" : "mits-ree'",
		"derivation" : "from H4714 (מִצְרַיִם)",
		"def" : "a Mitsrite, or inhabitant of Mitsrajim",
		"kjv" : "Egyptian, of Egypt"
	},
	"H4714" : {
		"lemma" : "מִצְרַיִם",
		"xlit" : "Mitsrayim",
		"pron" : "mits-rah'-yim",
		"derivation" : "dual of H4693 (מָצוֹר)",
		"def" : "Mitsrajim, i.e. Upper and Lower Egypt",
		"kjv" : "Egypt, Egyptians, Mizraim"
	},
	"H4715" : {
		"lemma" : "מִצְרֵף",
		"xlit" : "mitsrêph",
		"pron" : "mits-rafe'",
		"derivation" : "from H6884 (צָרַף)",
		"def" : "a crucible",
		"kjv" : "fining pot"
	},
	"H4716" : {
		"lemma" : "מַק",
		"xlit" : "maq",
		"pron" : "mak",
		"derivation" : "from H4743 (מָקַק)",
		"def" : "properly, a melting, i.e. putridity",
		"kjv" : "rottenness, stink"
	},
	"H4717" : {
		"lemma" : "מַקָּבָה",
		"xlit" : "maqqâbâh",
		"pron" : "mak-kaw-baw'",
		"derivation" : "from H5344 (נָקַב)",
		"def" : "properly, a perforatrix, i.e. a hammer (as piercing)",
		"kjv" : "hammer"
	},
	"H4718" : {
		"lemma" : "מַקֶּבֶת",
		"xlit" : "maqqebeth",
		"pron" : "mak-keh'-beth",
		"derivation" : "from H5344 (נָקַב)",
		"def" : "properly, a perforator, i.e. a hammer (as piercing); also (intransitively) a perforation, i.e. a quarry",
		"kjv" : "hammer, hole"
	},
	"H4719" : {
		"lemma" : "מַקֵּדָה",
		"xlit" : "Maqqêdâh",
		"pron" : "mak-kay-daw'",
		"derivation" : "from the same as H5348 (נָקֹד) in the denominative sense of herding (compare H5349 (נֹקֵד)); fold",
		"def" : "Makkedah, a place in Palestine",
		"kjv" : "Makkedah"
	},
	"H4720" : {
		"lemma" : "מִקְדָּשׁ",
		"xlit" : "miqdâsh",
		"pron" : "mik-dawsh'",
		"derivation" : "or מִקְּדָשׁ; (Exodus 15:17), from H6942 (קָדַשׁ)",
		"def" : "a consecrated thing or place, especially, a palace, sanctuary (whether of Jehovah or of idols) or asylum",
		"kjv" : "chapel, hallowed part, holy place, sanctuary"
	},
	"H4721" : {
		"lemma" : "מַקְהֵל",
		"xlit" : "maqhêl",
		"pron" : "mak-hale'",
		"derivation" : "or (feminine) מַקְהֵלָה; from H6950 (קָהַל)",
		"def" : "an assembly",
		"kjv" : "congregation"
	},
	"H4722" : {
		"lemma" : "מַקְהֵלֹת",
		"xlit" : "Maqhêlôth",
		"pron" : "mak-hay-loth'",
		"derivation" : "plural of H4721 (מַקְהֵל) (feminine); assemblies",
		"def" : "Makheloth, a place in the Desert",
		"kjv" : "Makheloth"
	},
	"H4723" : {
		"lemma" : "מִקְוֶה",
		"xlit" : "miqveh",
		"pron" : "mik-veh'",
		"derivation" : "or מִקְוֵה; (1 Kings 10:28), or מִקְוֵאlemma מִּקְוֵא extra dagesh, corrected to מִקְוֵא; (2 Chronicles 1:16), from H6960 (קָוָה)",
		"def" : "something waited for, i.e. confidence (objective or subjective); also a collection, i.e. (of water) a pond, or (of men and horses) a caravan or drove",
		"kjv" : "abiding, gathering together, hope, linen yarn, plenty (of water), pool"
	},
	"H4724" : {
		"lemma" : "מִקְוָה",
		"xlit" : "miqvâh",
		"pron" : "mik-vaw'",
		"derivation" : "feminine of H4723 (מִקְוֶה)",
		"def" : "a collection, i.e. (of water) a reservoir",
		"kjv" : "ditch"
	},
	"H4725" : {
		"lemma" : "מָקוֹם",
		"xlit" : "mâqôwm",
		"pron" : "maw-kome'",
		"derivation" : "or מָקֹם; also (feminine) מְקוֹמָה; or מְקֹמָה; from H6965 (קוּם)",
		"def" : "properly, a standing, i.e. a spot; but used widely of a locality (general or specific); also (figuratively) of a condition (of body or mind)",
		"kjv" : "country, [idiom] home, [idiom] open, place, room, space, [idiom] whither(-soever)"
	},
	"H4726" : {
		"lemma" : "מָקוֹר",
		"xlit" : "mâqôwr",
		"pron" : "maw-kore'",
		"derivation" : "or מָקֹר; from H6979 (קוּר)",
		"def" : "properly, something dug, i.e. a (general) source (of water, even when naturally flowing; also of tears, blood (by euphemism, of the female pudenda); figuratively, of happiness, wisdom, progeny)",
		"kjv" : "fountain, issue, spring, well(-spring)"
	},
	"H4727" : {
		"lemma" : "מִקָּח",
		"xlit" : "miqqâch",
		"pron" : "mik-kawkh'",
		"derivation" : "from H3947 (לָקַח)",
		"def" : "reception",
		"kjv" : "taking"
	},
	"H4728" : {
		"lemma" : "מַקָּחָה",
		"xlit" : "maqqâchâh",
		"pron" : "mak-kaw-khaw'",
		"derivation" : "from H3947 (לָקַח)",
		"def" : "something received, i.e. merchandise (purchased)",
		"kjv" : "ware"
	},
	"H4729" : {
		"lemma" : "מִקְטָר",
		"xlit" : "miqṭâr",
		"pron" : "mik-tawr'",
		"derivation" : "from H6999 (קָטַר)",
		"def" : "something to fume (incense) on i.e. a hearth place",
		"kjv" : "to burn...upon"
	},
	"H4730" : {
		"lemma" : "מִקְטֶרֶת",
		"xlit" : "miqṭereth",
		"pron" : "mik-teh'-reth",
		"derivation" : "feminine of H4729 (מִקְטָר)",
		"def" : "something to fume (incense) in, i.e. a coal-pan",
		"kjv" : "censer"
	},
	"H4731" : {
		"lemma" : "מַקֵּל",
		"xlit" : "maqqêl",
		"pron" : "mak-kale",
		"derivation" : "or (feminine) מַקְּלָה; from an unused root meaning apparently to germinate",
		"def" : "a shoot, i.e. stick (with leaves on, or for walking, striking, guiding, divining)",
		"kjv" : "rod, (hand-)staff"
	},
	"H4732" : {
		"lemma" : "מִקְלוֹת",
		"xlit" : "Miqlôwth",
		"pron" : "mik-lohth'",
		"derivation" : "(or perhaps mik-kel-ohth') plural of (feminine) H4731 (מַקֵּל); rods",
		"def" : "Mikloth, a place in the Desert",
		"kjv" : "Mikloth"
	},
	"H4733" : {
		"lemma" : "מִקְלָט",
		"xlit" : "miqlâṭ",
		"pron" : "mik-lawt'",
		"derivation" : "from H7038 (קָלַט) in the sense of taking in",
		"def" : "an asylum (as a receptacle)",
		"kjv" : "refuge"
	},
	"H4734" : {
		"lemma" : "מִקְלַעַת",
		"xlit" : "miqlaʻath",
		"pron" : "mik-lah'-ath",
		"derivation" : "from H7049 (קָלַע)",
		"def" : "a sculpture (probably in bas-relief)",
		"kjv" : "carved (figure), carving, graving"
	},
	"H4735" : {
		"lemma" : "מִקְנֶה",
		"xlit" : "miqneh",
		"pron" : "mik-neh'",
		"derivation" : "from H7069 (קָנָה)",
		"def" : "something bought, i.e. property, but only livestock; abstractly, acquisition",
		"kjv" : "cattle, flock, herd, possession, purchase, substance"
	},
	"H4736" : {
		"lemma" : "מִקְנָה",
		"xlit" : "miqnâh",
		"pron" : "mik-naw'",
		"derivation" : "feminine of H4735 (מִקְנֶה)",
		"def" : "properly, a buying, i.e. acquisition; concretely, a piece of property (land or living); also the sum paid",
		"kjv" : "(he that is) bought, possession, piece, purchase"
	},
	"H4737" : {
		"lemma" : "מִקְנֵיָהוּ",
		"xlit" : "Miqnêyâhûw",
		"pron" : "mik-nay-yaw'-hoo",
		"derivation" : "from H4735 (מִקְנֶה) and H3050 (יָהּ); possession of Jah",
		"def" : "Miknejah, an Israelite",
		"kjv" : "Mikneiah"
	},
	"H4738" : {
		"lemma" : "מִקְסָם",
		"xlit" : "miqçâm",
		"pron" : "mik-sawn'",
		"derivation" : "from H7080 (קָסַם)",
		"def" : "an augury",
		"kjv" : "divination"
	},
	"H4739" : {
		"lemma" : "מָקַץ",
		"xlit" : "Mâqats",
		"pron" : "maw-kats'",
		"derivation" : "from H7112 (קָצַץ); end",
		"def" : "Makats, a place in Palestine",
		"kjv" : "Makaz"
	},
	"H4740" : {
		"lemma" : "מַקְצוֹעַ",
		"xlit" : "maqtsôwaʻ",
		"pron" : "mak-tso'-ah",
		"derivation" : "or מַקְצֹעַ; or (feminine) מַקְצֹעָה; from H7106 (קָצַע) in the denominative sense of bending",
		"def" : "an angle or recess",
		"kjv" : "corner, turning"
	},
	"H4741" : {
		"lemma" : "מַקְצֻעָה",
		"xlit" : "maqtsuʻâh",
		"pron" : "mak-tsoo-aw'",
		"derivation" : "from H7106 (קָצַע)",
		"def" : "a scraper, i.e. a carving chisel",
		"kjv" : "plane"
	},
	"H4742" : {
		"lemma" : "מְקֻצְעָה",
		"xlit" : "mᵉqutsʻâh",
		"pron" : "mek-oots-aw'",
		"derivation" : "from H7106 (קָצַע) in the denominative sense of bending",
		"def" : "an angle",
		"kjv" : "corner"
	},
	"H4743" : {
		"lemma" : "מָקַק",
		"xlit" : "mâqaq",
		"pron" : "maw-kak'",
		"derivation" : "' a primitive root",
		"def" : "to melt; figuratively, to flow, dwindle, vanish",
		"kjv" : "consume away, be corrupt, dissolve, pine away"
	},
	"H4744" : {
		"lemma" : "מִקְרָא",
		"xlit" : "miqrâʼ",
		"pron" : "mik-raw'",
		"derivation" : "from H7121 (קָרָא)",
		"def" : "something called out, i.e. a public meeting (the act, the persons, or the place); also a rehearsal",
		"kjv" : "assembly, calling, convocation, reading"
	},
	"H4745" : {
		"lemma" : "מִקְרֶה",
		"xlit" : "miqreh",
		"pron" : "mik-reh'",
		"derivation" : "from H7136 (קָרָה)",
		"def" : "something met with, i.e. an accident or fortune",
		"kjv" : "something befallen, befalleth, chance, event, hap(-peneth)"
	},
	"H4746" : {
		"lemma" : "מְקָרֶה",
		"xlit" : "mᵉqâreh",
		"pron" : "mek-aw-reh'",
		"derivation" : "from H7136 (קָרָה)",
		"def" : "properly, something meeting, i.e. a frame (of timbers)",
		"kjv" : "building"
	},
	"H4747" : {
		"lemma" : "מְקֵרָה",
		"xlit" : "mᵉqêrâh",
		"pron" : "mek-ay-raw'",
		"derivation" : "from the same as H7119 (קַר)",
		"def" : "a cooling off",
		"kjv" : "[idiom] summer"
	},
	"H4748" : {
		"lemma" : "מִקְשֶׁה",
		"xlit" : "miqsheh",
		"pron" : "mik-sheh'",
		"derivation" : "from H7185 (קָשָׁה) in the sense of knotting up round and hard",
		"def" : "something turned (rounded), i.e. a curl (of tresses)",
		"kjv" : "[idiom] well (set) hair"
	},
	"H4749" : {
		"lemma" : "מִקְשָׁה",
		"xlit" : "miqshâh",
		"pron" : "mik-shaw'",
		"derivation" : "feminine of H4748 (מִקְשֶׁה)",
		"def" : "rounded work, i.e. moulded by hammering (repousse)",
		"kjv" : "beaten (out of one piece, work), upright, whole piece"
	},
	"H4750" : {
		"lemma" : "מִקְשָׁה",
		"xlit" : "miqshâh",
		"pron" : "mik-shaw'",
		"derivation" : "denominative from H7180 (קִשֻּׁא)",
		"def" : "literally a cucumbered field, i.e. a cucumber patch",
		"kjv" : "garden of cucumbers"
	},
	"H4751" : {
		"lemma" : "מַר",
		"xlit" : "mar",
		"pron" : "mar",
		"derivation" : "or (feminine) מָרָה; from H4843 (מָרַר)",
		"def" : "bitter (literally or figuratively); also (as noun) bitterness, or (adverbially) bitterly",
		"kjv" : "[phrase] angry, bitter(-ly, -ness), chafed, discontented, [idiom] great, heavy"
	},
	"H4752" : {
		"lemma" : "מַר",
		"xlit" : "mar",
		"pron" : "mar",
		"derivation" : "from H4843 (מָרַר) in its original sense of distillation",
		"def" : "a drop",
		"kjv" : "drop"
	},
	"H4753" : {
		"lemma" : "מֹר",
		"xlit" : "môr",
		"pron" : "more",
		"derivation" : "or מוֹר; from H4843 (מָרַר)",
		"def" : "myrrh (as distilling in drops, and also as bitter)",
		"kjv" : "myrrh"
	},
	"H4754" : {
		"lemma" : "מָרָא",
		"xlit" : "mârâʼ",
		"pron" : "maw-raw'",
		"derivation" : "a primitive root",
		"def" : "to rebel; hence (through the idea of maltreating) to whip, i.e. lash (self with wings, as the ostrich in running)",
		"kjv" : "be filthy, lift up self"
	},
	"H4755" : {
		"lemma" : "מָרָא",
		"xlit" : "Mârâʼ",
		"pron" : "maw-raw'",
		"derivation" : "for H4751 (מַר) feminine; bitter",
		"def" : "Mara, a symbolic name of Naomi",
		"kjv" : "Mara"
	},
	"H4756" : {
		"lemma" : "מָרֵא",
		"xlit" : "mârêʼ",
		"pron" : "maw-ray'",
		"derivation" : "(Aramaic) from a root corresponding to H4754 (מָרָא) in the sense of domineering",
		"def" : "a master",
		"kjv" : "lord, Lord"
	},
	"H4757" : {
		"lemma" : "מְרֹאדַךְ בַּלְאָדָן",
		"xlit" : "Mᵉrôʼdak Balʼâdân",
		"pron" : "mer-o-dak' bal-awdawn'",
		"derivation" : "of foreign derivation",
		"def" : "Merodak-Baladan, a Babylonian king",
		"kjv" : "Merodach-baladan. Compare H4781 (מְרֹדָךְ)"
	},
	"H4758" : {
		"lemma" : "מַרְאֶה",
		"xlit" : "marʼeh",
		"pron" : "mar-eh'",
		"derivation" : "from H7200 (רָאָה)",
		"def" : "a view (the act of seeing); also an appearance (the thing seen), whether (real) a shape (especially if handsome, comeliness; often plural the looks), or (mental) a vision",
		"kjv" : "[idiom] apparently, appearance(-reth), [idiom] as soon as beautiful(-ly), countenance, fair, favoured, form, goodly, to look (up) on (to), look(-eth), pattern, to see, seem, sight, visage, vision"
	},
	"H4759" : {
		"lemma" : "מַרְאָה",
		"xlit" : "marʼâh",
		"pron" : "mar-aw'",
		"derivation" : "feminine of H4758 (מַרְאֶה)",
		"def" : "a vision; also (causatively) a mirror",
		"kjv" : "looking glass, vision"
	},
	"H4760" : {
		"lemma" : "מֻרְאָה",
		"xlit" : "murʼâh",
		"pron" : "moor-aw'",
		"derivation" : "apparently feminine passive causative participle of H7200 (רָאָה)",
		"def" : "something conspicuous, i.e. the craw of abird (from its prominence)",
		"kjv" : "crop"
	},
	"H4761" : {
		"lemma" : "מַרְאָשָׁה",
		"xlit" : "marʼâshâh",
		"pron" : "mar-aw-shaw'",
		"derivation" : "denominative from H7218 (רֹאשׁ)",
		"def" : "properly, headship, i.e. (plural for collective) dominion",
		"kjv" : "principality"
	},
	"H4762" : {
		"lemma" : "מַרְאֵשָׁה",
		"xlit" : "Marʼêshâh",
		"pron" : "mar-ay-shaw'",
		"derivation" : "or מַרֵשָׁה; formed like H4761 (מַרְאָשָׁה); summit",
		"def" : "Mareshah, the name of two Israelites and of a place in Palestine",
		"kjv" : "Mareshah"
	},
	"H4763" : {
		"lemma" : "מְרַאֲשָׁה",
		"xlit" : "mᵉraʼăshâh",
		"pron" : "mer-ah-ash-aw'",
		"derivation" : "formed like H4761 (מַרְאָשָׁה)",
		"def" : "properly, a headpiece, i.e. (plural for adverbial) at (or as) the head-rest (or pillow)",
		"kjv" : "bolster, head, pillow. Compare H4772 (מַרְגְלָה)"
	},
	"H4764" : {
		"lemma" : "מֵרָב",
		"xlit" : "Mêrâb",
		"pron" : "may-rawb'",
		"derivation" : "from H7231 (רָבַב); increase",
		"def" : "Merab, a daughter of Saul",
		"kjv" : "Merab"
	},
	"H4765" : {
		"lemma" : "מַרְבַד",
		"xlit" : "marbad",
		"pron" : "mar-bad'",
		"derivation" : "from H7234 (רָבַד)",
		"def" : "a coverlet",
		"kjv" : "covering of tapestry"
	},
	"H4766" : {
		"lemma" : "מַרְבֶה",
		"xlit" : "marbeh",
		"pron" : "mar-beh'",
		"derivation" : "from H7235 (רָבָה)",
		"def" : "properly, increasing; as noun, greatness, or (adverbially) greatly",
		"kjv" : "great, increase"
	},
	"H4767" : {
		"lemma" : "מִרְבָּה",
		"xlit" : "mirbâh",
		"pron" : "meer-baw'",
		"derivation" : "from H7235 (רָבָה)",
		"def" : "abundance, i.e. a great quantity",
		"kjv" : "much"
	},
	"H4768" : {
		"lemma" : "מַרְבִּית",
		"xlit" : "marbîyth",
		"pron" : "mar-beeth'",
		"derivation" : "from H7235 (רָבָה)",
		"def" : "a multitude; also offspring; specifically interest (on capital)",
		"kjv" : "greatest part, greatness, increase, multitude"
	},
	"H4769" : {
		"lemma" : "מַרְבֵּץ",
		"xlit" : "marbêts",
		"pron" : "mar-bates'",
		"derivation" : "from H7257 (רָבַץ)",
		"def" : "a reclining place, i.e. fold (for flocks)",
		"kjv" : "couching place, place to lie down"
	},
	"H4770" : {
		"lemma" : "מַרְבֵּק",
		"xlit" : "marbêq",
		"pron" : "mar-bake'",
		"derivation" : "from an unused root meaning to tie up",
		"def" : "a stall (for cattle)",
		"kjv" : "[idiom] fat(-ted), stall"
	},
	"H4771" : {
		"lemma" : "מַרְגּוֹעַ",
		"xlit" : "margôwaʻ",
		"pron" : "mar-go'-ah",
		"derivation" : "from H7280 (רָגַע)",
		"def" : "a resting place",
		"kjv" : "rest"
	},
	"H4772" : {
		"lemma" : "מַרְגְלָה",
		"xlit" : "margᵉlâh",
		"pron" : "mar-ghel-aw'",
		"derivation" : "denominative from H7272 (רֶגֶל)",
		"def" : "(plural for collective) a footpiece, i.e. (adverbially) at the foot, or (direct.) the foot itself",
		"kjv" : "feet. Compare H4763 (מְרַאֲשָׁה)"
	},
	"H4773" : {
		"lemma" : "מַרְגֵּמָה",
		"xlit" : "margêmâh",
		"pron" : "mar-gay-maw'",
		"derivation" : "from H7275 (רָגַם)",
		"def" : "a stone-heap",
		"kjv" : "sling"
	},
	"H4774" : {
		"lemma" : "מַרְגֵּעָה",
		"xlit" : "margêʻâh",
		"pron" : "mar-gay-aw'",
		"derivation" : "from H7280 (רָגַע)",
		"def" : "rest",
		"kjv" : "refreshing"
	},
	"H4775" : {
		"lemma" : "מָרַד",
		"xlit" : "mârad",
		"pron" : "maw-rad'",
		"derivation" : "a primitive root",
		"def" : "to rebel",
		"kjv" : "rebel(-lious)"
	},
	"H4776" : {
		"lemma" : "מְרַד",
		"xlit" : "mᵉrad",
		"pron" : "mer-ad'",
		"derivation" : "(Aramaic) from a root corresponding to H4775 (מָרַד)",
		"def" : "rebellion",
		"kjv" : "rebellion"
	},
	"H4777" : {
		"lemma" : "מֶרֶד",
		"xlit" : "mered",
		"pron" : "meh'-red",
		"derivation" : "from H4775 (מָרַד)",
		"def" : "rebellion",
		"kjv" : "rebellion"
	},
	"H4778" : {
		"lemma" : "מֶרֶד",
		"xlit" : "Mered",
		"pron" : "meh'-red",
		"derivation" : "the same as H4777 (מֶרֶד)",
		"def" : "Mered, an Israelite",
		"kjv" : "Mered"
	},
	"H4779" : {
		"lemma" : "מָרָד",
		"xlit" : "mârâd",
		"pron" : "maw-rawd'",
		"derivation" : "(Aramaic) from the same as H4776 (מְרַד)",
		"def" : "rebellious",
		"kjv" : "rebellious"
	},
	"H4780" : {
		"lemma" : "מַרְדּוּת",
		"xlit" : "mardûwth",
		"pron" : "mar-dooth'",
		"derivation" : "from H4775 (מָרַד)",
		"def" : "rebelliousness",
		"kjv" : "[idiom] rebellious"
	},
	"H4781" : {
		"lemma" : "מְרֹדָךְ",
		"xlit" : "Mᵉrôdâk",
		"pron" : "mer-o-dawk'",
		"derivation" : "of foreign derivation",
		"def" : "Merodak, a Babylonian idol",
		"kjv" : "Merodach. Compare H4757 (מְרֹאדַךְ בַּלְאָדָן)"
	},
	"H4782" : {
		"lemma" : "מׇרְדְּכַי",
		"xlit" : "Mordᵉkay",
		"pron" : "mor-dek-ah'-ee",
		"derivation" : "of foreign derivation",
		"def" : "Mordecai, an Israelite",
		"kjv" : "Mordecai"
	},
	"H4783" : {
		"lemma" : "מֻרְדָּף",
		"xlit" : "murdâph",
		"pron" : "moor-dawf'",
		"derivation" : "from H7291 (רָדַף)",
		"def" : "persecuted",
		"kjv" : "persecuted"
	},
	"H4784" : {
		"lemma" : "מָרָה",
		"xlit" : "mârâh",
		"pron" : "maw-raw'",
		"derivation" : "a primitive root",
		"def" : "to be (causatively, make) bitter (or unpleasant); (figuratively) to rebel (or resist; causatively, to provoke)",
		"kjv" : "bitter, change, be disobedient, disobey, grievously, provocation, provoke(-ing), (be) rebel (against, -lious)"
	},
	"H4785" : {
		"lemma" : "מָרָה",
		"xlit" : "Mârâh",
		"pron" : "maw-raw'",
		"derivation" : "the same as H4751 (מַר) feminine; bitter",
		"def" : "Marah, a place in the Desert",
		"kjv" : "Marah"
	},
	"H4786" : {
		"lemma" : "מֹרָה",
		"xlit" : "môrâh",
		"pron" : "mo-raw'",
		"derivation" : "from H4843 (מָרַר)",
		"def" : "bitterness, i.e. (figuratively) trouble",
		"kjv" : "grief"
	},
	"H4787" : {
		"lemma" : "מׇרָּה",
		"xlit" : "morrâh",
		"pron" : "mor-raw'",
		"derivation" : "a form of H4786 (מֹרָה)",
		"def" : "trouble",
		"kjv" : "bitterness"
	},
	"H4788" : {
		"lemma" : "מָרוּד",
		"xlit" : "mârûwd",
		"pron" : "maw-rood'",
		"derivation" : "from H7300 (רוּד) in the sense of maltreatment",
		"def" : "an outcast; (abstractly) destitution",
		"kjv" : "cast out, misery"
	},
	"H4789" : {
		"lemma" : "מֵרוֹז",
		"xlit" : "Mêrôwz",
		"pron" : "may-roze'",
		"derivation" : "of uncertain derivation",
		"def" : "Meroz, a place in Palestine",
		"kjv" : "Meroz"
	},
	"H4790" : {
		"lemma" : "מְרוֹחַ",
		"xlit" : "mᵉrôwach",
		"pron" : "mer-o-akh'",
		"derivation" : "from H4799 (מָרַח)",
		"def" : "bruised, i.e. emasculated",
		"kjv" : "broken"
	},
	"H4791" : {
		"lemma" : "מָרוֹם",
		"xlit" : "mârôwm",
		"pron" : "maw-rome'",
		"derivation" : "from H7311 (רוּם)",
		"def" : "altitude, i.e. concretely (an elevated place), abstractly (elevation, figuratively (elation), or adverbially (aloft)",
		"kjv" : "(far) above, dignity, haughty, height, (most, on) high (one, place), loftily, upward"
	},
	"H4792" : {
		"lemma" : "מֵרוֹם",
		"xlit" : "Mêrôwm",
		"pron" : "may-rome'",
		"derivation" : "formed like H4791 (מָרוֹם); height",
		"def" : "Merom, a lake in Palestine",
		"kjv" : "Merom"
	},
	"H4793" : {
		"lemma" : "מֵרוֹץ",
		"xlit" : "mêrôwts",
		"pron" : "may-rotes'",
		"derivation" : "from H7323 (רוּץ)",
		"def" : "a run (the trial of speed)",
		"kjv" : "race"
	},
	"H4794" : {
		"lemma" : "מְרוּצָה",
		"xlit" : "mᵉrûwtsâh",
		"pron" : "mer-oo-tsaw'",
		"derivation" : "or מְרֻצָה; feminine of H4793 (מֵרוֹץ)",
		"def" : "a race (the act), whether the manner or the progress",
		"kjv" : "course, running. Compare H4835 (מְרֻצָה)"
	},
	"H4795" : {
		"lemma" : "מָרוּק",
		"xlit" : "mârûwq",
		"pron" : "maw-rook'",
		"derivation" : "from H4838 (מָרַק)",
		"def" : "properly, rubbed; but used abstractly, a rubbing (with perfumery)",
		"kjv" : "purification"
	},
	"H4796" : {
		"lemma" : "מָרוֹת",
		"xlit" : "Mârôwth",
		"pron" : "maw-rohth'",
		"derivation" : "plural of H4751 (מַר) feminine; bitter springs",
		"def" : "Maroth, a place in Palestine",
		"kjv" : "Maroth"
	},
	"H4797" : {
		"lemma" : "מִרְזַח",
		"xlit" : "mirzach",
		"pron" : "meer-zakh'",
		"derivation" : "from an unused root meaning to scream",
		"def" : "a cry, i.e. (of job), a revel",
		"kjv" : "banquet"
	},
	"H4798" : {
		"lemma" : "מַרְזֵחַ",
		"xlit" : "marzêach",
		"pron" : "mar-zay'-akh",
		"derivation" : "formed like H4797 (מִרְזַח)",
		"def" : "a cry, i.e. (of grief) a lamentation",
		"kjv" : "mourning"
	},
	"H4799" : {
		"lemma" : "מָרַח",
		"xlit" : "mârach",
		"pron" : "maw-rakh'",
		"derivation" : "a primitive root",
		"def" : "properly, to soften by rubbing or pressure; hence (medicinally) to apply as an emollient",
		"kjv" : "lay for a plaister"
	},
	"H4800" : {
		"lemma" : "מֶרְחָב",
		"xlit" : "merchâb",
		"pron" : "mer-khawb'",
		"derivation" : "from H7337 (רָחַב)",
		"def" : "enlargement, either literally (an open space, usually in a good sense), or figuratively (liberty)",
		"kjv" : "breadth, large place (room)"
	},
	"H4801" : {
		"lemma" : "מֶרְחָק",
		"xlit" : "merchâq",
		"pron" : "mer-khawk'",
		"derivation" : "; from H7368 (רָחַק)",
		"def" : "remoteness, i.e. (concretely) a distant place; often (adverbially) from afar",
		"kjv" : "(a-, dwell in, very) far (country, off). See also H1023 (בֵּית הַמֶּרְחָק)"
	},
	"H4802" : {
		"lemma" : "מַרְחֶשֶׁת",
		"xlit" : "marchesheth",
		"pron" : "mar-kheh'-sheth",
		"derivation" : "from H7370 (רָחַשׁ)",
		"def" : "a stewpan",
		"kjv" : "fryingpan"
	},
	"H4803" : {
		"lemma" : "מָרַט",
		"xlit" : "mâraṭ",
		"pron" : "maw-rat'",
		"derivation" : "a primitive root",
		"def" : "to polish; by implication, to make bald (the head), to gall (the shoulder); also, to sharpen",
		"kjv" : "bright, furbish, (have his) hair (be) fallen off, peeled, pluck off (hair)"
	},
	"H4804" : {
		"lemma" : "מְרַט",
		"xlit" : "mᵉraṭ",
		"pron" : "mer-at'",
		"derivation" : "(Aramaic) corresponding to H4803 (מָרַט)",
		"def" : "to pull off",
		"kjv" : "be plucked"
	},
	"H4805" : {
		"lemma" : "מְרִי",
		"xlit" : "mᵉrîy",
		"pron" : "mer-ee'",
		"derivation" : "from H4784 (מָרָה)",
		"def" : "bitterness, i.e. (figuratively) rebellion; concretely, bitter, or rebellious",
		"kjv" : "bitter, (most) rebel(-lion, -lious)"
	},
	"H4806" : {
		"lemma" : "מְרִיא",
		"xlit" : "mᵉrîyʼ",
		"pron" : "mer-ee'",
		"derivation" : "from H4754 (מָרָא) in the sense of grossness, through the idea of domineering (compare H4756 (מָרֵא))",
		"def" : "stall-fed; often (as noun) a beeve",
		"kjv" : "fat (fed) beast (cattle, -ling)"
	},
	"H4807" : {
		"lemma" : "מְרִיב בַּעַל",
		"xlit" : "Mᵉrîyb Baʻal",
		"pron" : "mer-eeb' bah'-al",
		"derivation" : "from H7378 (רִיב) and H1168 (בַּעַל)",
		"def" : "quarreller of Baal, Merib-Baal, an epithet of Gideon",
		"kjv" : "Merib-baal. Compare H4810 (מְרִי בַעַל)"
	},
	"H4808" : {
		"lemma" : "מְרִיבָה",
		"xlit" : "mᵉrîybâh",
		"pron" : "mer-ee-baw'",
		"derivation" : "from H7378 (רִיב)",
		"def" : "quarrel",
		"kjv" : "provocation, strife"
	},
	"H4809" : {
		"lemma" : "מְרִיבָה",
		"xlit" : "Mᵉrîybâh",
		"pron" : "mer-ee-baw'",
		"derivation" : "the same as H4808 (מְרִיבָה)",
		"def" : "Meribah, the name of two places in the Desert",
		"kjv" : "Meribah"
	},
	"H4810" : {
		"lemma" : "מְרִי בַעַל",
		"xlit" : "Mᵉrîy Baʻal",
		"pron" : "mer-ee' bah'-al",
		"derivation" : "from H4805 (מְרִי) and H1168 (בַּעַל); rebellion of (i.e. against) Baal",
		"def" : "Meri-Baal, an epithet of Gideon",
		"kjv" : "Meri-baal. Compare H4807 (מְרִיב בַּעַל)"
	},
	"H4811" : {
		"lemma" : "מְרָיָה",
		"xlit" : "Mᵉrâyâh",
		"pron" : "mer-aw-yaw'",
		"derivation" : "from H4784 (מָרָה); rebellion",
		"def" : "Merajah, an Israelite",
		"kjv" : "Meraiah. Compare H3236 (יִמְרָה)"
	},
	"H4812" : {
		"lemma" : "מְרָיוֹת",
		"xlit" : "Mᵉrâyôwth",
		"pron" : "mer-aw-yohth'",
		"derivation" : "plural of H4811 (מְרָיָה); rebellious",
		"def" : "Merajoth, the name of two Israelites",
		"kjv" : "Meraioth"
	},
	"H4813" : {
		"lemma" : "מִרְיָם",
		"xlit" : "Miryâm",
		"pron" : "meer-yawm'",
		"derivation" : "from H4805 (מְרִי); rebelliously",
		"def" : "Mirjam, the name of two Israelitesses",
		"kjv" : "Miriam"
	},
	"H4814" : {
		"lemma" : "מְרִירוּת",
		"xlit" : "mᵉrîyrûwth",
		"pron" : "mer-ee-rooth'",
		"derivation" : "from H4843 (מָרַר)",
		"def" : "bitterness, i.e. (figuratively) grief",
		"kjv" : "bitterness"
	},
	"H4815" : {
		"lemma" : "מְרִירִי",
		"xlit" : "mᵉrîyrîy",
		"pron" : "mer-ee-ree'",
		"derivation" : "from H4843 (מָרַר)",
		"def" : "bitter, i.e. poisonous",
		"kjv" : "bitter"
	},
	"H4816" : {
		"lemma" : "מֹרֶךְ",
		"xlit" : "môrek",
		"pron" : "mo'-rek",
		"derivation" : "perhaps from H7401 (רָכַךְ)",
		"def" : "softness, i.e. (figuratively) fear",
		"kjv" : "faintness"
	},
	"H4817" : {
		"lemma" : "מֶרְכָּב",
		"xlit" : "merkâb",
		"pron" : "mer-kawb'",
		"derivation" : "from H7392 (רָכַב)",
		"def" : "a chariot; also a seat (in a vehicle)",
		"kjv" : "chariot, covering, saddle"
	},
	"H4818" : {
		"lemma" : "מֶרְכָּבָה",
		"xlit" : "merkâbâh",
		"pron" : "mer-kaw-baw'",
		"derivation" : "feminine of H4817 (מֶרְכָּב)",
		"def" : "a chariot",
		"kjv" : "chariot. See also H1024 (בֵּית הַמַּרְכָּבוֹת)"
	},
	"H4819" : {
		"lemma" : "מַרְכֹּלֶת",
		"xlit" : "markôleth",
		"pron" : "mar-ko'-leth",
		"derivation" : "from H7402 (רָכַל)",
		"def" : "a mart",
		"kjv" : "merchandise"
	},
	"H4820" : {
		"lemma" : "מִרְמָה",
		"xlit" : "mirmâh",
		"pron" : "meer-maw'",
		"derivation" : "from H7411 (רָמָה) in the sense of deceiving",
		"def" : "fraud",
		"kjv" : "craft, deceit(-ful, -fully), false, feigned, guile, subtilly, treachery"
	},
	"H4821" : {
		"lemma" : "מִרְמָה",
		"xlit" : "Mirmâh",
		"pron" : "meer-maw'",
		"derivation" : "the same as H4820 (מִרְמָה)",
		"def" : "Mirmah, an Israelite",
		"kjv" : "Mirma"
	},
	"H4822" : {
		"lemma" : "מְרֵמוֹת",
		"xlit" : "Mᵉrêmôwth",
		"pron" : "mer-ay-mohth'",
		"derivation" : "plural from H7311 (רוּם); heights",
		"def" : "Meremoth, the name of two Israelites",
		"kjv" : "Meremoth"
	},
	"H4823" : {
		"lemma" : "מִרְמָס",
		"xlit" : "mirmâç",
		"pron" : "meer-mawce'",
		"derivation" : "from H7429 (רָמַס)",
		"def" : "abasement (the act or the thing)",
		"kjv" : "tread (down) -ing, (to be) trodden (down) under foot"
	},
	"H4824" : {
		"lemma" : "מֵרֹנֹתִי",
		"xlit" : "Mêrônôthîy",
		"pron" : "may-ro-no-thee'",
		"derivation" : "patrial from an unused noun",
		"def" : "a Meronothite, or inhabitant of some (otherwise unknown) Meronoth",
		"kjv" : "Meronothite"
	},
	"H4825" : {
		"lemma" : "מֶרֶס",
		"xlit" : "Mereç",
		"pron" : "meh'-res",
		"derivation" : "of foreign derivation",
		"def" : "Meres, a Persian",
		"kjv" : "Meres"
	},
	"H4826" : {
		"lemma" : "מַרְסְנָא",
		"xlit" : "Marçᵉnâʼ",
		"pron" : "mar-sen-aw'",
		"derivation" : "of foreign derivation",
		"def" : "Marsena, a Persian",
		"kjv" : "Marsena"
	},
	"H4827" : {
		"lemma" : "מֵרַע",
		"xlit" : "mêraʻ",
		"pron" : "may-rah'",
		"derivation" : "from H7489 (רָעַע)",
		"def" : "used as (abstract) noun, wickedness",
		"kjv" : "do mischief"
	},
	"H4828" : {
		"lemma" : "מֵרֵעַ",
		"xlit" : "mêrêaʻ",
		"pron" : "may-ray'-ah",
		"derivation" : "from H7462 (רָעָה) in the sense of companionship",
		"def" : "a friend",
		"kjv" : "companion, friend"
	},
	"H4829" : {
		"lemma" : "מִרְעֶה",
		"xlit" : "mirʻeh",
		"pron" : "meer-eh'",
		"derivation" : "from H7462 (רָעָה) in the sense of feeding",
		"def" : "pasture (the place or the act); also the haunt of wild animals",
		"kjv" : "feeding place, pasture"
	},
	"H4830" : {
		"lemma" : "מִרְעִית",
		"xlit" : "mirʻîyth",
		"pron" : "meer-eeth'",
		"derivation" : "from H7462 (רָעָה) in the sense of feeding",
		"def" : "pasturage; concretely, a flock",
		"kjv" : "flock, pasture"
	},
	"H4831" : {
		"lemma" : "מַרְעֲלָה",
		"xlit" : "Marʻălâh",
		"pron" : "mar-al-aw'",
		"derivation" : "from H7477 (רָעַל); perhaps, earthquake",
		"def" : "Maralah, a place in Palestine",
		"kjv" : "Maralah"
	},
	"H4832" : {
		"lemma" : "מַרְפֵּא",
		"xlit" : "marpêʼ",
		"pron" : "mar-pay'",
		"derivation" : "from H7495 (רָפָא)",
		"def" : "properly, curative, i.e. literally (concretely) a medicine, or (abstractly) a cure; figuratively (concretely) deliverance, or (abstractly) placidity",
		"kjv" : "(in-)cure(-able), healing(-lth), remedy, sound, wholesome, yielding"
	},
	"H4833" : {
		"lemma" : "מִרְפָּשׂ",
		"xlit" : "mirpâs",
		"pron" : "meer-paws'",
		"derivation" : "from H7515 (רָפַשׂ)",
		"def" : "muddled water",
		"kjv" : "that which...have fouled"
	},
	"H4834" : {
		"lemma" : "מָרַץ",
		"xlit" : "mârats",
		"pron" : "maw-rats'",
		"derivation" : "a primitive root",
		"def" : "properly, to press, i.e. (figuratively) to be pungent or vehement; to irritate",
		"kjv" : "embolden, be forcible, grievous, sore"
	},
	"H4835" : {
		"lemma" : "מְרֻצָה",
		"xlit" : "mᵉrutsâh",
		"pron" : "mer-oo-tsaw'",
		"derivation" : "from H7533 (רָצַץ)",
		"def" : "oppression",
		"kjv" : "violence. See also H4794 (מְרוּצָה)"
	},
	"H4836" : {
		"lemma" : "מַרְצֵעַ",
		"xlit" : "martsêaʻ",
		"pron" : "mar-tsay'-ah",
		"derivation" : "from H7527 (רָצַע)",
		"def" : "an awl",
		"kjv" : "aul"
	},
	"H4837" : {
		"lemma" : "מַרְצֶפֶת",
		"xlit" : "martsepheth",
		"pron" : "mar-tseh'-feth",
		"derivation" : "from H7528 (רָצַף)",
		"def" : "a pavement",
		"kjv" : "pavement"
	},
	"H4838" : {
		"lemma" : "מָרַק",
		"xlit" : "mâraq",
		"pron" : "maw-rak'",
		"derivation" : "a primitive root",
		"def" : "to polish; by implication, to sharpen; also to rinse",
		"kjv" : "bright, furbish, scour"
	},
	"H4839" : {
		"lemma" : "מָרָק",
		"xlit" : "mârâq",
		"pron" : "maw-rawk'",
		"derivation" : "from H4838 (מָרַק)",
		"def" : "soup (as if a rinsing)",
		"kjv" : "broth. See also H6564 (פָּרָק)"
	},
	"H4840" : {
		"lemma" : "מֶרְקָח",
		"xlit" : "merqâch",
		"pron" : "mer-kawkh'",
		"derivation" : "from H7543 (רָקַח)",
		"def" : "a spicy herb",
		"kjv" : "[idiom] sweet"
	},
	"H4841" : {
		"lemma" : "מֶרְקָחָה",
		"xlit" : "merqâchâh",
		"pron" : "mer-kaw-khaw'",
		"derivation" : "feminine of H4840 (מֶרְקָח)",
		"def" : "abstractly, a seasoning (with spicery); concretely, an unguentkettle (for preparing spiced oil)",
		"kjv" : "pot of ointment, [idiom] well"
	},
	"H4842" : {
		"lemma" : "מִרְקַחַת",
		"xlit" : "mirqachath",
		"pron" : "meer-kakh'-ath",
		"derivation" : "from H7543 (רָקַח)",
		"def" : "an aromatic unguent; also an unguent-pot",
		"kjv" : "prepared by the apothecaries' art, compound, ointment"
	},
	"H4843" : {
		"lemma" : "מָרַר",
		"xlit" : "mârar",
		"pron" : "maw-rar'",
		"derivation" : "a primitive root; properly, to trickle (see H4752 (מַר)); but used only as a denominative from H4751 (מַר)",
		"def" : "to be (causatively, make) bitter (literally or figuratively)",
		"kjv" : "(be, be in, deal, have, make) bitter(-ly, -ness), be moved with choler, (be, have sorely, it) grieved(-eth), provoke, vex"
	},
	"H4844" : {
		"lemma" : "מְרֹר",
		"xlit" : "mᵉrôr",
		"pron" : "mer-ore'",
		"derivation" : "or מְרוֹר; from H4843 (מָרַר)",
		"def" : "a bitter herb",
		"kjv" : "bitter(-ness)"
	},
	"H4845" : {
		"lemma" : "מְרֵרָה",
		"xlit" : "mᵉrêrâh",
		"pron" : "mer-ay-raw'",
		"derivation" : "from H4843 (מָרַר)",
		"def" : "bile (from its bitterness)",
		"kjv" : "gall"
	},
	"H4846" : {
		"lemma" : "מְרֹרָה",
		"xlit" : "mᵉrôrâh",
		"pron" : "mer-o-raw'",
		"derivation" : "or מְרוֹרָה; from H4843 (מָרַר)",
		"def" : "properly, bitterness; concretely, a bitter thing; specifically bile; also venom (of a serpent)",
		"kjv" : "bitter (thing), gall"
	},
	"H4847" : {
		"lemma" : "מְרָרִי",
		"xlit" : "Mᵉrârîy",
		"pron" : "mer-aw-ree'",
		"derivation" : "from H4843 (מָרַר); bitter",
		"def" : "Merari, an Israelite",
		"kjv" : "Merari. See also H4848 (מְרָרִי)"
	},
	"H4848" : {
		"lemma" : "מְרָרִי",
		"xlit" : "Mᵉrârîy",
		"pron" : "mer-aw-ree'",
		"derivation" : "from H4847 (מְרָרִי)",
		"def" : "a Merarite (collectively), or decendants of Merari",
		"kjv" : "Merarites"
	},
	"H4849" : {
		"lemma" : "מִרְשַׁעַת",
		"xlit" : "mirshaʻath",
		"pron" : "meer-shah'-ath",
		"derivation" : "from H7561 (רָשַׁע)",
		"def" : "a female wicked doer",
		"kjv" : "wicked woman"
	},
	"H4850" : {
		"lemma" : "מְרָתַיִם",
		"xlit" : "Mᵉrâthayim",
		"pron" : "mer-aw-thah'-yim",
		"derivation" : "dual of H4751 (מַר) feminine; double bitterness",
		"def" : "Merathajim, an epithet of Babylon",
		"kjv" : "Merathaim"
	},
	"H4851" : {
		"lemma" : "מַשׁ",
		"xlit" : "Mash",
		"pron" : "mash",
		"derivation" : "of foreign derivation",
		"def" : "Mash, a son of Aram, and the people descended from him",
		"kjv" : "Mash"
	},
	"H4852" : {
		"lemma" : "מֵשָׁא",
		"xlit" : "Mêshâʼ",
		"pron" : "may-shaw'",
		"derivation" : "of foreign derivation",
		"def" : "Mesha, a place in Arabia",
		"kjv" : "Mesha"
	},
	"H4853" : {
		"lemma" : "מַשָּׂא",
		"xlit" : "massâʼ",
		"pron" : "mas-saw'",
		"derivation" : "from H5375 (נָשָׂא)",
		"def" : "a burden; specifically, tribute, or (abstractly) porterage; figuratively, an utterance, chiefly adoom, especially singing; mental, desire",
		"kjv" : "burden, carry away, prophecy, [idiom] they set, song, tribute"
	},
	"H4854" : {
		"lemma" : "מַשָּׂא",
		"xlit" : "Massâʼ",
		"pron" : "mas-saw'",
		"derivation" : "the same as H4853 (מַשָּׂא); burden",
		"def" : "Massa, a son of Ishmael",
		"kjv" : "Massa"
	},
	"H4855" : {
		"lemma" : "מַשָּׁא",
		"xlit" : "mashshâʼ",
		"pron" : "mash-shaw'",
		"derivation" : "from H5383 (נָשָׁה)",
		"def" : "a loan; by implication, interest on adebt",
		"kjv" : "exaction, usury"
	},
	"H4856" : {
		"lemma" : "מַשֹּׂא",
		"xlit" : "massôʼ",
		"pron" : "mas-so'",
		"derivation" : "from H5375 (נָשָׂא)",
		"def" : "partiality (as a lifting up)",
		"kjv" : "respect"
	},
	"H4857" : {
		"lemma" : "מַשְׁאָב",
		"xlit" : "mashʼâb",
		"pron" : "mash-awb'",
		"derivation" : "from H7579 (שָׁאַב)",
		"def" : "a trough for cattle to drink from",
		"kjv" : "place of drawing water"
	},
	"H4858" : {
		"lemma" : "מַשָּׂאָה",
		"xlit" : "massâʼâh",
		"pron" : "mas-saw-aw'",
		"derivation" : "from H5375 (נָשָׂא)",
		"def" : "a conflagration (from the rising of smoke)",
		"kjv" : "burden"
	},
	"H4859" : {
		"lemma" : "מַשָּׁאָה",
		"xlit" : "mashshâʼâh",
		"pron" : "mash-shaw-aw'",
		"derivation" : "feminine of H4855 (מַשָּׁא)",
		"def" : "a loan",
		"kjv" : "[idiom] any(-thing), debt"
	},
	"H4860" : {
		"lemma" : "מַשָּׁאוֹן",
		"xlit" : "mashshâʼôwn",
		"pron" : "mash-shaw-ohn'",
		"derivation" : "from H5377 (נָשָׁא)",
		"def" : "dissimulation",
		"kjv" : "deceit"
	},
	"H4861" : {
		"lemma" : "מִשְׁאָל",
		"xlit" : "Mishʼâl",
		"pron" : "mish-awl'",
		"derivation" : "from H7592 (שָׁאַל); request",
		"def" : "Mishal, a place in Palestine",
		"kjv" : "Mishal, Misheal. Compare H4913 (מָשָׁל)"
	},
	"H4862" : {
		"lemma" : "מִשְׁאָלָה",
		"xlit" : "mishʼâlâh",
		"pron" : "mish-aw-law'",
		"derivation" : "from H7592 (שָׁאַל)",
		"def" : "a request",
		"kjv" : "desire, petition"
	},
	"H4863" : {
		"lemma" : "מִשְׁאֶרֶת",
		"xlit" : "mishʼereth",
		"pron" : "mish-eh'-reth",
		"derivation" : "from H7604 (שָׁאַר) in the original sense of swelling",
		"def" : "a kneading-trough (in which the dough rises)",
		"kjv" : "kneading trough, store"
	},
	"H4864" : {
		"lemma" : "מַשְׂאֵת",
		"xlit" : "masʼêth",
		"pron" : "mas-ayth'",
		"derivation" : "from H5375 (נָשָׂא)",
		"def" : "properly, (abstractly) a raising (as of the hands in prayer), or rising (of flame); figuratively, an utterance; concretely, a beacon (as raised); a present (as taken), mess, or tribute; figuratively, a reproach (as a burden)",
		"kjv" : "burden, collection, sign of fire, (great) flame, gift, lifting up, mess, oblation, reward"
	},
	"H4865" : {
		"lemma" : "מִשְׁבְּצָה",
		"xlit" : "mishbᵉtsâh",
		"pron" : "mish-bets-aw'",
		"derivation" : "from H7660 (שָׁבַץ)",
		"def" : "a brocade; by analogy, a (reticulated) setting of agem",
		"kjv" : "ouch, wrought"
	},
	"H4866" : {
		"lemma" : "מִשְׁבֵּר",
		"xlit" : "mishbêr",
		"pron" : "mish-bare'",
		"derivation" : "from H7665 (שָׁבַר)",
		"def" : "the orifice of the womb (from which the fetus breaks forth)",
		"kjv" : "birth, breaking forth"
	},
	"H4867" : {
		"lemma" : "מִשְׁבָּר",
		"xlit" : "mishbâr",
		"pron" : "mish-bawr'",
		"derivation" : "from H7665 (שָׁבַר)",
		"def" : "a breaker (of the sea)",
		"kjv" : "billow, wave"
	},
	"H4868" : {
		"lemma" : "מִשְׁבָּת",
		"xlit" : "mishbâth",
		"pron" : "mish-bawth'",
		"derivation" : "from H7673 (שָׁבַת)",
		"def" : "cessation, i.e. destruction",
		"kjv" : "sabbath"
	},
	"H4869" : {
		"lemma" : "מִשְׂגָּב",
		"xlit" : "misgâb",
		"pron" : "mis-gawb'",
		"derivation" : "from H7682 (שָׂגַב); properly, a cliff (or other lofty or inaccessible place); abstractly, altitude; figuratively, a refuge",
		"def" : "defence, high fort (tower), refuge, Misgab, a place in Moab",
		"kjv" : "Misgab"
	},
	"H4870" : {
		"lemma" : "מִשְׁגֶּה",
		"xlit" : "mishgeh",
		"pron" : "mish-gay'",
		"derivation" : "from H7686 (שָׁגָה)",
		"def" : "an error",
		"kjv" : "oversight"
	},
	"H4871" : {
		"lemma" : "מָשָׁה",
		"xlit" : "mâshâh",
		"pron" : "maw-shaw'",
		"derivation" : "a primitive root",
		"def" : "to pull out (literally or figuratively)",
		"kjv" : "draw(out)"
	},
	"H4872" : {
		"lemma" : "מֹשֶׁה",
		"xlit" : "Môsheh",
		"pron" : "mo-sheh'",
		"derivation" : "from H4871 (מָשָׁה); drawing out (of the water), i.e. rescued",
		"def" : "Mosheh, the Israelite lawgiver",
		"kjv" : "Moses"
	},
	"H4873" : {
		"lemma" : "מֹשֶׁה",
		"xlit" : "Môsheh",
		"pron" : "mo-sheh'",
		"derivation" : "(Aramaic) corresponding to H4872 (מֹשֶׁה)",
		"def" : "{Mosheh, the Israelite lawgiver}",
		"kjv" : "Moses"
	},
	"H4874" : {
		"lemma" : "מַשֶּׁה",
		"xlit" : "mashsheh",
		"pron" : "mash-sheh'",
		"derivation" : "from H5383 (נָשָׁה)",
		"def" : "a debt",
		"kjv" : "[phrase] creditor"
	},
	"H4875" : {
		"lemma" : "מְשׁוֹאָה",
		"xlit" : "mᵉshôwʼâh",
		"pron" : "meh-o-aw'",
		"derivation" : "or מְשֹׁאָה; from the same as H7722 (שׁוֹא)",
		"def" : "(a) ruin, abstractly (the act) or concretely (the wreck)",
		"kjv" : "desolation, waste"
	},
	"H4876" : {
		"lemma" : "מַשּׁוּאָה",
		"xlit" : "mashshûwʼâh",
		"pron" : "mash-shoo-aw'",
		"derivation" : "or מַשֻּׁאָה; for H4875 (מְשׁוֹאָה)",
		"def" : "ruin",
		"kjv" : "desolation, destruction"
	},
	"H4877" : {
		"lemma" : "מְשׁוֹבָב",
		"xlit" : "Mᵉshôwbâb",
		"pron" : "mesh-o-bawb'",
		"derivation" : "from H7725 (שׁוּב); returned",
		"def" : "Meshobab, an Israelite",
		"kjv" : "Meshobab"
	},
	"H4878" : {
		"lemma" : "מְשׁוּבָה",
		"xlit" : "mᵉshûwbâh",
		"pron" : "mesh-oo-baw'",
		"derivation" : "or מְשֻׁבָה; from H7725 (שׁוּב)",
		"def" : "apostasy",
		"kjv" : "backsliding, turning away"
	},
	"H4879" : {
		"lemma" : "מְשׁוּגָה",
		"xlit" : "mᵉshûwgâh",
		"pron" : "mesh-oo-gaw'",
		"derivation" : "from an unused root meaning to stray",
		"def" : "mistake",
		"kjv" : "error"
	},
	"H4880" : {
		"lemma" : "מָשׁוֹט",
		"xlit" : "mâshôwṭ",
		"pron" : "maw-shote'",
		"derivation" : "or מִשּׁוֹט; from H7751 (שׁוּט)",
		"def" : "an oar",
		"kjv" : "oar"
	},
	"H4881" : {
		"lemma" : "מְשׂוּכָה",
		"xlit" : "mᵉsûwkâh",
		"pron" : "mes-oo-kaw'",
		"derivation" : "or מְשֻׂכָה; from H7753 (שׂוּךְ)",
		"def" : "a hedge",
		"kjv" : "hedge"
	},
	"H4882" : {
		"lemma" : "מְשׁוּסָה",
		"xlit" : "mᵉshûwçâh",
		"pron" : "mesh-oo-saw'",
		"derivation" : "from an unused root meaning to plunder",
		"def" : "spoilation",
		"kjv" : "spoil"
	},
	"H4883" : {
		"lemma" : "מַשּׂוֹר",
		"xlit" : "massôwr",
		"pron" : "mas-sore'",
		"derivation" : "from an unused root meaning to rasp",
		"def" : "a saw",
		"kjv" : "saw"
	},
	"H4884" : {
		"lemma" : "מְשׂוּרָה",
		"xlit" : "mᵉsûwrâh",
		"pron" : "mes-oo-raw'",
		"derivation" : "from an unused root meaning apparently to divide",
		"def" : "a measure (for liquids)",
		"kjv" : "measure"
	},
	"H4885" : {
		"lemma" : "מָשׂוֹשׂ",
		"xlit" : "mâsôws",
		"pron" : "maw-soce'",
		"derivation" : "from H7797 (שׂוּשׂ)",
		"def" : "delight, concretely (the cause or object) or abstractly (the feeling)",
		"kjv" : "joy, mirth, rejoice"
	},
	"H4886" : {
		"lemma" : "מָשַׁח",
		"xlit" : "mâshach",
		"pron" : "maw-shakh'",
		"derivation" : "a primitive root",
		"def" : "to rub with oil, i.e. to anoint; by implication, to consecrate; also to paint",
		"kjv" : "anoint, paint"
	},
	"H4887" : {
		"lemma" : "מְשַׁח",
		"xlit" : "mᵉshach",
		"pron" : "mesh-akh'",
		"derivation" : "(Aramaic) from a root corresponding to H4886 (מָשַׁח)",
		"def" : "oil",
		"kjv" : "oil"
	},
	"H4888" : {
		"lemma" : "מִשְׁחָה",
		"xlit" : "mishchâh",
		"pron" : "meesh-khaw'",
		"derivation" : "or מׇשְׁחָה; from H4886 (מָשַׁח)",
		"def" : "unction (the act); by implication, a consecratory gift",
		"kjv" : "(to be) anointed(-ing), ointment"
	},
	"H4889" : {
		"lemma" : "מַשְׁחִית",
		"xlit" : "mashchîyth",
		"pron" : "mash-kheeth'",
		"derivation" : "from H7843 (שָׁחַת)",
		"def" : "destructive, i.e. (as noun) destruction, literally (specifically a snare) or figuratively (corruption)",
		"kjv" : "corruption, (to) destroy(-ing), destruction, trap, [idiom] utterly"
	},
	"H4890" : {
		"lemma" : "מִשְׂחַק",
		"xlit" : "mischaq",
		"pron" : "mis-khawk'",
		"derivation" : "from H7831 (שַׁחֲצוֹם)",
		"def" : "a laughing-stock",
		"kjv" : "scorn"
	},
	"H4891" : {
		"lemma" : "מִשְׁחָר",
		"xlit" : "mishchâr",
		"pron" : "mish-khawr'",
		"derivation" : "from H7836 (שָׁחַר) in the sense of day breaking",
		"def" : "dawn",
		"kjv" : "morning"
	},
	"H4892" : {
		"lemma" : "מַשְׁחֵת",
		"xlit" : "mashchêth",
		"pron" : "mash-khayth'",
		"derivation" : "for H4889 (מַשְׁחִית)",
		"def" : "destruction",
		"kjv" : "destroying"
	},
	"H4893" : {
		"lemma" : "מִשְׁחָת",
		"xlit" : "mishchâth",
		"pron" : "mish-khawth'",
		"derivation" : "or מׇשְׁחָת; from H7843 (שָׁחַת)",
		"def" : "disfigurement",
		"kjv" : "corruption, marred"
	},
	"H4894" : {
		"lemma" : "מִשְׁטוֹחַ",
		"xlit" : "mishṭôwach",
		"pron" : "mish-to'-akh",
		"derivation" : "or מִשְׁטַח; from H7849 (שָׁטַח)",
		"def" : "a spreading-place",
		"kjv" : "(to) spread (forth, -ing, upon)"
	},
	"H4895" : {
		"lemma" : "מַשְׂטֵמָה",
		"xlit" : "masṭêmâh",
		"pron" : "mas-tay-maw'",
		"derivation" : "from the same as H7850 (שֹׁטֵט)",
		"def" : "enmity",
		"kjv" : "hatred"
	},
	"H4896" : {
		"lemma" : "מִשְׁטָר",
		"xlit" : "mishṭâr",
		"pron" : "mish-tawr'",
		"derivation" : "from H7860 (שֹׁטֵר)",
		"def" : "jurisdiction",
		"kjv" : "dominion"
	},
	"H4897" : {
		"lemma" : "מֶשִׁי",
		"xlit" : "meshîy",
		"pron" : "meh'-shee",
		"derivation" : "from H4871 (מָשָׁה)",
		"def" : "silk (as drawn from the cocoon)",
		"kjv" : "silk"
	},
	"H4898" : {
		"lemma" : "מְשֵׁיזַבְאֵל",
		"xlit" : "Mᵉshêyzabʼêl",
		"pron" : "mesh-ay-zab-ale'",
		"derivation" : "from an equiv. to H7804 (שְׁזַב) and H410 (אֵל); delivered of God",
		"def" : "Meshezabel, an Israelite",
		"kjv" : "Meshezabeel"
	},
	"H4899" : {
		"lemma" : "מָשִׁיחַ",
		"xlit" : "mâshîyach",
		"pron" : "maw-shee'-akh",
		"derivation" : "from H4886 (מָשַׁח)",
		"def" : "anointed; usually a consecrated person (as a king, priest, or saint); specifically, the Messiah",
		"kjv" : "anointed, Messiah"
	},
	"H4900" : {
		"lemma" : "מָשַׁךְ",
		"xlit" : "mâshak",
		"pron" : "maw-shak'",
		"derivation" : "a primitive root",
		"def" : "to draw, used in a great variety of applications (including to sow, to sound, to prolong, to develop, to march, to remove, to delay, to be tall, etc.)",
		"kjv" : "draw (along, out), continue, defer, extend, forbear, [idiom] give, handle, make (pro-, sound) long, [idiom] sow, scatter, stretch out"
	},
	"H4901" : {
		"lemma" : "מֶשֶׁךְ",
		"xlit" : "meshek",
		"pron" : "meh'shek",
		"derivation" : "from H4900 (מָשַׁךְ)",
		"def" : "a sowing; also a possession",
		"kjv" : "precious, price"
	},
	"H4902" : {
		"lemma" : "מֶשֶׁךְ",
		"xlit" : "Meshek",
		"pron" : "meh'-shek",
		"derivation" : "the same in form as H4901 (מֶשֶׁךְ), but probably of foreign derivation",
		"def" : "Meshek, a son of Japheth, and the people descended from him",
		"kjv" : "Mesech, Meshech"
	},
	"H4903" : {
		"lemma" : "מִשְׁכַּב",
		"xlit" : "mishkab",
		"pron" : "mish-kab'",
		"derivation" : "(Aramaic) corresponding to H4904 (מִשְׁכָּב)",
		"def" : "a bed",
		"kjv" : "bed"
	},
	"H4904" : {
		"lemma" : "מִשְׁכָּב",
		"xlit" : "mishkâb",
		"pron" : "mish-kawb'",
		"derivation" : "from H7901 (שָׁכַב)",
		"def" : "a bed (figuratively, a bier); abstractly, sleep; by euphemism, carnal intercourse",
		"kjv" : "bed(-chamber), couch, lieth (lying) with"
	},
	"H4905" : {
		"lemma" : "מַשְׂכִּיל",
		"xlit" : "maskîyl",
		"pron" : "mas-keel'",
		"derivation" : "from H7919 (שָׂכַל)",
		"def" : "instructive, i.e. a didactic poem",
		"kjv" : "Maschil"
	},
	"H4906" : {
		"lemma" : "מַשְׂכִּית",
		"xlit" : "maskîyth",
		"pron" : "mas-keeth'",
		"derivation" : "from the same as H7906 (שֵׂכוּ)",
		"def" : "a figure (carved on stone, the wall, or any object); figuratively, imagination",
		"kjv" : "conceit, image(-ry), picture, [idiom] wish"
	},
	"H4907" : {
		"lemma" : "מִשְׁכַּן",
		"xlit" : "mishkan",
		"pron" : "mish-kan'",
		"derivation" : "(Aramaic) corresponding to H4908 (מִשְׁכָּן)",
		"def" : "residence",
		"kjv" : "habitation"
	},
	"H4908" : {
		"lemma" : "מִשְׁכָּן",
		"xlit" : "mishkân",
		"pron" : "mish-kawn'",
		"derivation" : "from H7931 (שָׁכַן)",
		"def" : "a residence (including a shepherd's hut, the lair of animals, figuratively, the grave; also the Temple); specifically, the Tabernacle (properly, its wooden walls)",
		"kjv" : "dwelleth, dwelling (place), habitation, tabernacle, tent"
	},
	"H4909" : {
		"lemma" : "מַשְׂכֹּרֶת",
		"xlit" : "maskôreth",
		"pron" : "mas-koh'-reth",
		"derivation" : "from H7936 (שָׂכַר)",
		"def" : "wages or a reward",
		"kjv" : "reward, wages"
	},
	"H4910" : {
		"lemma" : "מָשַׁל",
		"xlit" : "mâshal",
		"pron" : "maw-shal'",
		"derivation" : "a primitive root",
		"def" : "to rule",
		"kjv" : "(have, make to have) dominion, governor, [idiom] indeed, reign, (bear, cause to, have) rule(-ing, -r), have power"
	},
	"H4911" : {
		"lemma" : "מָשַׁל",
		"xlit" : "mâshal",
		"pron" : "maw-shal'",
		"derivation" : "denominative from H4912 (מָשָׁל)",
		"def" : "to liken, i.e. (transitively) to use figurative language (an allegory, adage, song or the like); intransitively, to resemble",
		"kjv" : "be(-come) like, compare, use (as a) proverb, speak (in proverbs), utter"
	},
	"H4912" : {
		"lemma" : "מָשָׁל",
		"xlit" : "mâshâl",
		"pron" : "maw-shawl'",
		"derivation" : "apparently from H4910 (מָשַׁל) in some original sense of superiority in mental action",
		"def" : "properly, a pithy maxim, usually of metaphorical nature; hence, a simile (as an adage, poem, discourse)",
		"kjv" : "byword, like, parable, proverb"
	},
	"H4913" : {
		"lemma" : "מָשָׁל",
		"xlit" : "Mâshâl",
		"pron" : "maw-shawl'",
		"derivation" : "for H4861 (מִשְׁאָל)",
		"def" : "Mashal, a place in Palestine",
		"kjv" : "Mashal"
	},
	"H4914" : {
		"lemma" : "מְשֹׁל",
		"xlit" : "mᵉshôl",
		"pron" : "mesh-ol'",
		"derivation" : "from H4911 (מָשַׁל)",
		"def" : "a satire",
		"kjv" : "byword"
	},
	"H4915" : {
		"lemma" : "מֹשֶׁל",
		"xlit" : "môshel",
		"pron" : "mo'-shel",
		"derivation" : "(1) from H4910 (מָשַׁל); (2) from H4911 (מָשַׁל)",
		"def" : "empire; a parallel",
		"kjv" : "dominion, like"
	},
	"H4916" : {
		"lemma" : "מִשְׁלוֹחַ",
		"xlit" : "mishlôwach",
		"pron" : "mish-lo'-akh",
		"derivation" : "or מִשְׁלֹחַ; also מִשְׁלָח; from H7971 (שָׁלַח)",
		"def" : "a sending out, i.e. (abstractly) presentation (favorable), or seizure (unfavorable); also (concretely) a place of dismissal, or a business to be discharged",
		"kjv" : "to lay, to put, sending (forth), to set"
	},
	"H4917" : {
		"lemma" : "מִשְׁלַחַת",
		"xlit" : "mishlachath",
		"pron" : "mish-lakh'-ath",
		"derivation" : "feminine of H4916 (מִשְׁלוֹחַ)",
		"def" : "a mission, i.e. (abstractly and favorable) release, or (concretely and unfavorable) an army",
		"kjv" : "discharge, sending"
	},
	"H4918" : {
		"lemma" : "מְשֻׁלָּם",
		"xlit" : "Mᵉshullâm",
		"pron" : "mesh-ool-lawm'",
		"derivation" : "from H7999 (שָׁלַם); allied",
		"def" : "Meshullam, the name of seventeen Israelites",
		"kjv" : "Meshullam"
	},
	"H4919" : {
		"lemma" : "מְשִׁלֵּמוֹת",
		"xlit" : "Mᵉshillêmôwth",
		"pron" : "mesh-il-lay-mohth'",
		"derivation" : "plural from H7999 (שָׁלַם); reconciliations",
		"def" : "Meshillemoth, an Israelite",
		"kjv" : "Meshillemoth. Compare H4921 (מְשִׁלֵּמִית)"
	},
	"H4920" : {
		"lemma" : "מְשֶׁלֶמְיָה",
		"xlit" : "Mᵉshelemyâh",
		"pron" : "mesh-eh-lem-yaw'",
		"derivation" : "or מְשֶׁלֶמְיָהוּ; from H7999 (שָׁלַם) and H3050 (יָהּ); ally of Jah",
		"def" : "Meshelemjah, an Israelite",
		"kjv" : "Meshelemiah"
	},
	"H4921" : {
		"lemma" : "מְשִׁלֵּמִית",
		"xlit" : "Mᵉshillêmîyth",
		"pron" : "mesh-il-lay-meeth'",
		"derivation" : "from H7999 (שָׁלַם); reconciliation",
		"def" : "Meshillemith, an Israelite",
		"kjv" : "Meshillemith. Compare H4919 (מְשִׁלֵּמוֹת)"
	},
	"H4922" : {
		"lemma" : "מְשֻׁלֶּמֶת",
		"xlit" : "Mᵉshullemeth",
		"pron" : "mesh-ool-leh'-meth'",
		"derivation" : "feminine of H4918 (מְשֻׁלָּם)",
		"def" : "Meshullemeth, an Israelitess",
		"kjv" : "Meshullemeth"
	},
	"H4923" : {
		"lemma" : "מְשַׁמָּה",
		"xlit" : "mᵉshammâh",
		"pron" : "mesh-am-maw'",
		"derivation" : "from H8074 (שָׁמֵם)",
		"def" : "a waste or amazement",
		"kjv" : "astonishment, desolate"
	},
	"H4924" : {
		"lemma" : "מַשְׁמָן",
		"xlit" : "mashmân",
		"pron" : "mash-mawn'",
		"derivation" : "from H8080 (שָׁמַן)",
		"def" : "fat, i.e. (literally and abstractly) fatness; but usually (figuratively and concretely) a rich dish, a fertile field, a robust man",
		"kjv" : "fat (one, -ness, -test, -test place)"
	},
	"H4925" : {
		"lemma" : "מִשְׁמַנָּה",
		"xlit" : "Mishmannâh",
		"pron" : "mish-man-naw'",
		"derivation" : "from H8080 (שָׁמַן); fatness",
		"def" : "Mashmannah, an Israelite",
		"kjv" : "Mishmannah"
	},
	"H4926" : {
		"lemma" : "מִשְׁמָע",
		"xlit" : "mishmâʻ",
		"pron" : "mish-maw'",
		"derivation" : "from H8085 (שָׁמַע)",
		"def" : "a report",
		"kjv" : "hearing"
	},
	"H4927" : {
		"lemma" : "מִשְׁמָע",
		"xlit" : "Mishmâʻ",
		"pron" : "mish-maw'",
		"derivation" : "the same as H4926 (מִשְׁמָע)",
		"def" : "Mishma, the name of a son of Ishmael, and of an Israelite",
		"kjv" : "Mishma"
	},
	"H4928" : {
		"lemma" : "מִשְׁמַעַת",
		"xlit" : "mishmaʻath",
		"pron" : "mish-mah'-ath",
		"derivation" : "feminine of H4926 (מִשְׁמָע)",
		"def" : "audience, i.e. the royal court; also obedience, i.e. (concretely) a subject",
		"kjv" : "bidding, guard, obey"
	},
	"H4929" : {
		"lemma" : "מִשְׁמָר",
		"xlit" : "mishmâr",
		"pron" : "mish-mawr'",
		"derivation" : "from H8104 (שָׁמַר)",
		"def" : "a guard (the man, the post or the prison); a deposit (figuratively); also (as observed) a usage (abstractly), or an example (concretely)",
		"kjv" : "diligence, guard, office, prison, ward, watch"
	},
	"H4930" : {
		"lemma" : "מַשְׂמְרָה",
		"xlit" : "masmᵉrâh",
		"pron" : "mas-mer-aw'",
		"derivation" : "for H4548 (מַסְמֵר) fem",
		"def" : "a peg",
		"kjv" : "nail"
	},
	"H4931" : {
		"lemma" : "מִשְׁמֶרֶת",
		"xlit" : "mishmereth",
		"pron" : "mish-mer'-reth",
		"derivation" : "feminine of H4929 (מִשְׁמָר)",
		"def" : "watch, i.e. the act (custody), or (concretely) the sentry, the post; objectively preservation, or (concretely) safe; figuratively observance, i.e. (abstractly) duty or (objectively) a usage or party",
		"kjv" : "charge, keep, or to be kept, office, ordinace, safeguard, ward, watch"
	},
	"H4932" : {
		"lemma" : "מִשְׁנֶה",
		"xlit" : "mishneh",
		"pron" : "mish-neh'",
		"derivation" : "from H8138 (שָׁנָה)",
		"def" : "properly, a repetition, i.e. a duplicate (copy of a document), or a double (in amount); by implication, a second (in order, rank, age, quality or location)",
		"kjv" : "college, copy, double, fatlings, next, second (order), twice as much"
	},
	"H4933" : {
		"lemma" : "מְשִׁסָּה",
		"xlit" : "mᵉshiççâh",
		"pron" : "mesh-is-saw'",
		"derivation" : "from H8155 (שָׁסַס)",
		"def" : "plunder",
		"kjv" : "booty, spoil"
	},
	"H4934" : {
		"lemma" : "מִשְׁעוֹל",
		"xlit" : "mishʻôwl",
		"pron" : "mish-ole'",
		"derivation" : "from the same as H8168 (שֹׁעַל)",
		"def" : "a hollow, i.e. a narrow passage",
		"kjv" : "path"
	},
	"H4935" : {
		"lemma" : "מִשְׁעִי",
		"xlit" : "mishʻîy",
		"pron" : "mish-ee'",
		"derivation" : "probably from H8159 (שָׁעָה)",
		"def" : "inspection",
		"kjv" : "to supple"
	},
	"H4936" : {
		"lemma" : "מִשְׁעָם",
		"xlit" : "Mishʻâm",
		"pron" : "mish-awm'",
		"derivation" : "apparently from H8159 (שָׁעָה); inspection",
		"def" : "Misham, an Israelite",
		"kjv" : "Misham"
	},
	"H4937" : {
		"lemma" : "מִשְׁעֵן",
		"xlit" : "mishʻên",
		"pron" : "mish-ane'",
		"derivation" : "or מִשְׁעָן; from H8172 (שָׁעַן)",
		"def" : "a support (concretely), i.e. (figuratively) a protector or sustenance",
		"kjv" : "stay"
	},
	"H4938" : {
		"lemma" : "מִשְׁעֵנָה",
		"xlit" : "mishʻênâh",
		"pron" : "mish-ay-naw'",
		"derivation" : "or מִשְׁעֶנֶת; feminine of H4937 (מִשְׁעֵן)",
		"def" : "support (abstractly), i.e. (figuratively) sustenance or (concretely) a walking-stick",
		"kjv" : "staff"
	},
	"H4939" : {
		"lemma" : "מִשְׂפָּח",
		"xlit" : "mispâch",
		"pron" : "mis-pawkh'",
		"derivation" : "from H5596 (סָפַח)",
		"def" : "slaughter",
		"kjv" : "oppression"
	},
	"H4940" : {
		"lemma" : "מִשְׁפָּחָה",
		"xlit" : "mishpâchâh",
		"pron" : "mish-paw-khaw'",
		"derivation" : "from H8192 (שָׁפָה) (compare H8198 (שִׁפְחָה))",
		"def" : "a family, i.e. circle of relatives; figuratively, a class (of persons), a species (of animals) or sort (of things); by extension a tribe or people",
		"kjv" : "family, kind(-red)"
	},
	"H4941" : {
		"lemma" : "מִשְׁפָּט",
		"xlit" : "mishpâṭ",
		"pron" : "mish-pawt'",
		"derivation" : "from H8199 (שָׁפַט)",
		"def" : "properly, a verdict (favorable or unfavorable) pronounced judicially, especially a sentence or formal decree (human or (participant's) divine law, individual or collective), including the act, the place, the suit, the crime, and the penalty; abstractly, justice, including a participant's right or privilege (statutory or customary), or even a style",
		"kjv" : "[phrase] adversary, ceremony, charge, [idiom] crime, custom, desert, determination, discretion, disposing, due, fashion, form, to be judged, judgment, just(-ice, -ly), (manner of) law(-ful), manner, measure, (due) order, ordinance, right, sentence, usest, [idiom] worthy, [phrase] wrong"
	},
	"H4942" : {
		"lemma" : "מִשְׁפָּת",
		"xlit" : "mishpâth",
		"pron" : "mish-pawth'",
		"derivation" : "from H8192 (שָׁפָה)",
		"def" : "a stall for cattle (only dual)",
		"kjv" : "burden, sheepfold"
	},
	"H4943" : {
		"lemma" : "מֶשֶׁק",
		"xlit" : "mesheq",
		"pron" : "meh'-shek",
		"derivation" : "from an unused root meaning to hold",
		"def" : "possession",
		"kjv" : "[phrase] steward"
	},
	"H4944" : {
		"lemma" : "מַשָּׁק",
		"xlit" : "mashshâq",
		"pron" : "mash-shawk'",
		"derivation" : "from H8264 (שָׁקַק)",
		"def" : "a traversing, i.e. rapid motion",
		"kjv" : "running to and fro"
	},
	"H4945" : {
		"lemma" : "מַשְׁקֶה",
		"xlit" : "mashqeh",
		"pron" : "mash-keh'",
		"derivation" : "from H8248 (שָׁקָה)",
		"def" : "properly, causing to drink, i.e. a butler; by implication (intransitively), drink (itself); figuratively, a well-watered region",
		"kjv" : "butler(-ship), cupbearer, drink(-ing), fat pasture, watered"
	},
	"H4946" : {
		"lemma" : "מִשְׁקוֹל",
		"xlit" : "mishqôwl",
		"pron" : "mish-kole'",
		"derivation" : "from H8254 (שָׁקַל)",
		"def" : "weight",
		"kjv" : "weight"
	},
	"H4947" : {
		"lemma" : "מַשְׁקוֹף",
		"xlit" : "mashqôwph",
		"pron" : "mash-kofe'",
		"derivation" : "from H8259 (שָׁקַף) in its original sense of overhanging",
		"def" : "a lintel",
		"kjv" : "lintel"
	},
	"H4948" : {
		"lemma" : "מִשְׁקָל",
		"xlit" : "mishqâl",
		"pron" : "mish-kawl'",
		"derivation" : "from H8254 (שָׁקַל)",
		"def" : "weight (numerically estimated); hence, weighing (the act)",
		"kjv" : "(full) weight"
	},
	"H4949" : {
		"lemma" : "מִשְׁקֶלֶת",
		"xlit" : "mishqeleth",
		"pron" : "mish-keh'-leth",
		"derivation" : "or מִשְׁקֹלֶת; feminine of H4948 (מִשְׁקָל) or H4947 (מַשְׁקוֹף)",
		"def" : "a weight, i.e. a plummet (with line attached)",
		"kjv" : "plummet"
	},
	"H4950" : {
		"lemma" : "מִשְׁקָע",
		"xlit" : "mishqâʻ",
		"pron" : "mish-kaw'",
		"derivation" : "from H8257 (שָׁקַע)",
		"def" : "a settling place (of water), i.e. a pond",
		"kjv" : "deep"
	},
	"H4951" : {
		"lemma" : "מִשְׂרָה",
		"xlit" : "misrâh",
		"pron" : "mis-raw'",
		"derivation" : "from H8280 (שָׂרָה)",
		"def" : "empire",
		"kjv" : "government"
	},
	"H4952" : {
		"lemma" : "מִשְׁרָה",
		"xlit" : "mishrâh",
		"pron" : "mish-raw'",
		"derivation" : "from H8281 (שָׁרָה) in the sense of loosening",
		"def" : "maceration, i.e. steeped juice",
		"kjv" : "liquor"
	},
	"H4953" : {
		"lemma" : "מַשְׁרוֹקִי",
		"xlit" : "mashrôwqîy",
		"pron" : "mash-ro-kee'",
		"derivation" : "(Aramaic) from a root corresponding to H8319 (שָׁרַק)",
		"def" : "a (musical) pipe (from its whistling sound)",
		"kjv" : "flute"
	},
	"H4954" : {
		"lemma" : "מִשְׁרָעִי",
		"xlit" : "Mishrâʻîy",
		"pron" : "mish-raw-ee'",
		"derivation" : "patrial from an unused noun from an unused root; probably meaning to stretch out; extension",
		"def" : "a Mishraite, or inhabitant (collectively) of Mishra",
		"kjv" : "Mishraites"
	},
	"H4955" : {
		"lemma" : "מִשְׂרָפָה",
		"xlit" : "misrâphâh",
		"pron" : "mis-raw-faw'",
		"derivation" : "from H8313 (שָׂרַף)",
		"def" : "combustion, i.e. cremation (of a corpse), or calcination (of lime)",
		"kjv" : "burning"
	},
	"H4956" : {
		"lemma" : "מִשְׂרְפוֹת מַיִם",
		"xlit" : "Misrᵉphôwth mayim",
		"pron" : "mis-ref-ohth' mah'-yim",
		"derivation" : "from the plural of H4955 (מִשְׂרָפָה) and H4325 (מַיִם); burnings of water",
		"def" : "Misrephoth-Majim, a place in Palestine",
		"kjv" : "Misrephoth-mayim"
	},
	"H4957" : {
		"lemma" : "מַשְׂרֵקָה",
		"xlit" : "Masrêqâh",
		"pron" : "mas-ray-kaw'",
		"derivation" : "a form for H7796 (שׂוֹרֵק) used denominatively; vineyard",
		"def" : "Masrekah, a place in Idumaea",
		"kjv" : "Masrekah"
	},
	"H4958" : {
		"lemma" : "מַשְׂרֵת",
		"xlit" : "masrêth",
		"pron" : "mas-rayth'",
		"derivation" : "apparently from an unused root meaning to perforate, i.e. hollow out",
		"def" : "a pan",
		"kjv" : "pan"
	},
	"H4959" : {
		"lemma" : "מָשַׁשׁ",
		"xlit" : "mâshash",
		"pron" : "maw-shash'",
		"derivation" : "a primitive root",
		"def" : "to feel of; by implication, to grope",
		"kjv" : "feel, grope, search"
	},
	"H4960" : {
		"lemma" : "מִשְׁתֶּה",
		"xlit" : "mishteh",
		"pron" : "mish-teh'",
		"derivation" : "from H8354 (שָׁתָה)",
		"def" : "drink, by implication, drinking (the act); also (by implication) a banquet or (generally) feast",
		"kjv" : "banquet, drank, drink, feast((-ed), -ing)"
	},
	"H4961" : {
		"lemma" : "מִשְׁתֶּה",
		"xlit" : "mishteh",
		"pron" : "mish-teh'",
		"derivation" : "(Aramaic) corresponding to H4960 (מִשְׁתֶּה)",
		"def" : "a banquet",
		"kjv" : "banquet"
	},
	"H4962" : {
		"lemma" : "מַת",
		"xlit" : "math",
		"pron" : "math",
		"derivation" : "from the same as H4970 (מָתַי)",
		"def" : "properly, an adult (as of full length); by implication, a man (only in the plural)",
		"kjv" : "[phrase] few, [idiom] friends, men, persons, [idiom] small"
	},
	"H4963" : {
		"lemma" : "מַתְבֵּן",
		"xlit" : "mathbên",
		"pron" : "math-bane'",
		"derivation" : "denominative from H8401 (תֶּבֶן)",
		"def" : "straw in the heap",
		"kjv" : "straw"
	},
	"H4964" : {
		"lemma" : "מֶתֶג",
		"xlit" : "metheg",
		"pron" : "meh-theg",
		"derivation" : "from an unused root meaning to curb",
		"def" : "a bit",
		"kjv" : "bit, bridle"
	},
	"H4965" : {
		"lemma" : "מֶתֶג הָאַמָּה",
		"xlit" : "Metheg hâ-ʼAmmâh",
		"pron" : "meh'-theg haw-am-maw'",
		"derivation" : "from H4964 (מֶתֶג) and H520 (אַמָּה) with the art. interposed; bit of the metropolis",
		"def" : "Metheg-ha-Ammah, an epithet of Gath",
		"kjv" : "Metheg-ammah"
	},
	"H4966" : {
		"lemma" : "מָתוֹק",
		"xlit" : "mâthôwq",
		"pron" : "maw-thoke'",
		"derivation" : "or מָתוּק; from H4985 (מָתַק)",
		"def" : "sweet",
		"kjv" : "sweet(-er, -ness)"
	},
	"H4967" : {
		"lemma" : "מְתוּשָׁאֵל",
		"xlit" : "Mᵉthûwshâʼêl",
		"pron" : "meth-oo-shaw-ale'",
		"derivation" : "from H4962 (מַת) and H410 (אֵל), with the relative interposed; man who (is) of God",
		"def" : "Methusael, an antediluvian patriarch",
		"kjv" : "Methusael"
	},
	"H4968" : {
		"lemma" : "מְתוּשֶׁלַח",
		"xlit" : "Mᵉthûwshelach",
		"pron" : "meth-oo-sheh'-lakh",
		"derivation" : "from H4962 (מַת) and H7973 (שֶׁלַח); man of a dart",
		"def" : "Methushelach, an antediluvian patriarch",
		"kjv" : "Methuselah"
	},
	"H4969" : {
		"lemma" : "מָתַח",
		"xlit" : "mâthach",
		"pron" : "maw-thakh'",
		"derivation" : "a primitive root",
		"def" : "to stretch out",
		"kjv" : "spread out"
	},
	"H4970" : {
		"lemma" : "מָתַי",
		"xlit" : "mâthay",
		"pron" : "maw-thah'ee",
		"derivation" : "from an unused root meaning to extend",
		"def" : "properly, extent (of time); but used only adverbially (especially with other particle prefixes), when (either relative or interrogative)",
		"kjv" : "long, when"
	},
	"H4971" : {
		"lemma" : "מַתְכֹנֶת",
		"xlit" : "mathkôneth",
		"pron" : "math-ko'-neth",
		"derivation" : "or מַתְכֻנֶת; from H8505 (תָּכַן) in the transferred sense of measuring",
		"def" : "proportion (in size, number or ingredients)",
		"kjv" : "composition, measure, state, tale"
	},
	"H4972" : {
		"lemma" : "מַתְּלָאָה",
		"xlit" : "mattᵉlâʼâh",
		"pron" : "mat-tel-aw-aw'",
		"derivation" : "from H4100 (מָה) and H8513 (תְּלָאָה)",
		"def" : "what a trouble!",
		"kjv" : "what a weariness"
	},
	"H4973" : {
		"lemma" : "מְתַלְּעָה",
		"xlit" : "mᵉthallᵉʻâh",
		"pron" : "meth-al-leh-aw'",
		"derivation" : "contr. from H3216 (יָלַע)",
		"def" : "properly, a biter, i.e. a tooth",
		"kjv" : "cheek (jaw) tooth, jaw"
	},
	"H4974" : {
		"lemma" : "מְתֹם",
		"xlit" : "mᵉthôm",
		"pron" : "meth-ohm'",
		"derivation" : "from H8552 (תָּמַם)",
		"def" : "wholesomeness; also (adverb) completely",
		"kjv" : "men (by reading H4962 (מַת)), soundness"
	},
	"H4975" : {
		"lemma" : "מֹתֶן",
		"xlit" : "môthen",
		"pron" : "mo'-then",
		"derivation" : "from an unused root meaning to be slender",
		"def" : "properly, the waist or small of the back; only in plural the loins",
		"kjv" : "[phrase] greyhound, loins, side"
	},
	"H4976" : {
		"lemma" : "מַתָּן",
		"xlit" : "mattân",
		"pron" : "mat-tawn'",
		"derivation" : "from H5414 (נָתַן)",
		"def" : "a present",
		"kjv" : "gift, to give, reward"
	},
	"H4977" : {
		"lemma" : "מַתָּן",
		"xlit" : "Mattân",
		"pron" : "mat-tawn'",
		"derivation" : "the same as H4976 (מַתָּן)",
		"def" : "Mattan, the name of a priest of Baal, and of an Israelite",
		"kjv" : "Mattan"
	},
	"H4978" : {
		"lemma" : "מַתְּנָא",
		"xlit" : "mattᵉnâʼ",
		"pron" : "mat-ten-aw'",
		"derivation" : "(Aramaic) corresponding to H4979 (מַתָּנָה)",
		"def" : "{a present; specifically (in a good sense), a sacrificial offering, (in a bad sense) a bribe}",
		"kjv" : "gift"
	},
	"H4979" : {
		"lemma" : "מַתָּנָה",
		"xlit" : "mattânâh",
		"pron" : "mat-taw-naw'",
		"derivation" : "feminine of H4976 (מַתָּן)",
		"def" : "a present; specifically (in a good sense), a sacrificial offering, (in a bad sense) a bribe",
		"kjv" : "gift"
	},
	"H4980" : {
		"lemma" : "מַתָּנָה",
		"xlit" : "Mattânâh",
		"pron" : "mat-taw-naw'",
		"derivation" : "the same as H4979 (מַתָּנָה)",
		"def" : "Mattanah, a place in the Desert",
		"kjv" : "Mattanah"
	},
	"H4981" : {
		"lemma" : "מִתְנִי",
		"xlit" : "Mithnîy",
		"pron" : "mith-nee'",
		"derivation" : "probably patrial from an unused noun meaning slenderness",
		"def" : "a Mithnite, or inhabitant of Methen",
		"kjv" : "Mithnite"
	},
	"H4982" : {
		"lemma" : "מַתְּנַי",
		"xlit" : "Mattᵉnay",
		"pron" : "mat-ten-ah'ee",
		"derivation" : "from H4976 (מַתָּן); liberal",
		"def" : "Mattenai, the name of three Israelites",
		"kjv" : "Mattenai"
	},
	"H4983" : {
		"lemma" : "מַתַּנְיָה",
		"xlit" : "Mattanyâh",
		"pron" : "mat-tan-yaw'",
		"derivation" : "or מַתַּנְיָהוּ; from H4976 (מַתָּן) and H3050 (יָהּ); gift of Jah",
		"def" : "Mattanjah, the name of ten Israelites",
		"kjv" : "Mattaniah"
	},
	"H4984" : {
		"lemma" : "מִתְנַשֵּׂא",
		"xlit" : "mithnassêʼ",
		"pron" : "mith-nas-say'",
		"derivation" : "from H5375 (נָשָׂא)",
		"def" : "(used as abstractly) supreme exaltation",
		"kjv" : "exalted"
	},
	"H4985" : {
		"lemma" : "מָתַק",
		"xlit" : "mâthaq",
		"pron" : "maw-thak'",
		"derivation" : "a primitive root",
		"def" : "to suck, by implication, to relish, or (intransitively) be sweet",
		"kjv" : "be (made, [idiom] take) sweet"
	},
	"H4986" : {
		"lemma" : "מֶתֶק",
		"xlit" : "metheq",
		"pron" : "meh'-thek",
		"derivation" : "from H4985 (מָתַק)",
		"def" : "figuratively, pleasantness (of discourse)",
		"kjv" : "sweetness"
	},
	"H4987" : {
		"lemma" : "מֹתֶק",
		"xlit" : "môtheq",
		"pron" : "mo'-thek",
		"derivation" : "from H4985 (מָתַק)",
		"def" : "sweetness",
		"kjv" : "sweetness"
	},
	"H4988" : {
		"lemma" : "מָתָק",
		"xlit" : "mâthâq",
		"pron" : "maw-thawk'",
		"derivation" : "from H4985 (מָתַק)",
		"def" : "a dainty, i.e. (generally) food",
		"kjv" : "feed sweetly"
	},
	"H4989" : {
		"lemma" : "מִתְקָה",
		"xlit" : "Mithqâh",
		"pron" : "mith-kaw'",
		"derivation" : "feminine of H4987 (מֹתֶק); sweetness",
		"def" : "Mithkah, a place in the Desert",
		"kjv" : "Mithcah"
	},
	"H4990" : {
		"lemma" : "מִתְרְדָת",
		"xlit" : "Mithrᵉdâth",
		"pron" : "mith-red-awth'",
		"derivation" : "of Persian origin",
		"def" : "Mithredath, the name of two Persians",
		"kjv" : "Mithredath"
	},
	"H4991" : {
		"lemma" : "מַתָּת",
		"xlit" : "mattâth",
		"pron" : "mat-tawth'",
		"derivation" : "feminine of H4976 (מַתָּן) abbreviated form",
		"def" : "a present",
		"kjv" : "gift"
	},
	"H4992" : {
		"lemma" : "מַתַּתָּה",
		"xlit" : "Mattattâh",
		"pron" : "mat-tat-taw'",
		"derivation" : "for H4993 (מַתִּתְיָה); gift of Jah",
		"def" : "Mattattah, an Israelite",
		"kjv" : "Mattathah"
	},
	"H4993" : {
		"lemma" : "מַתִּתְיָה",
		"xlit" : "Mattithyâh",
		"pron" : "mat-tith-yaw'",
		"derivation" : "or מַתִּתְיָהוּ; from H4991 (מַתָּת) and H3050 (יָהּ); gift of Jah",
		"def" : "Mattithjah, the name of four Israelites",
		"kjv" : "Mattithiah. n"
	},
	"H4994" : {
		"lemma" : "נָא",
		"xlit" : "nâʼ",
		"pron" : "naw",
		"derivation" : "a primitive particle of incitement and entreaty, which may usually be rendered",
		"def" : "'I pray', 'now', or 'then'; added mostly to verbs (in the Imperative or Future), or to interjections, occasionally to an adverb or conjunction",
		"kjv" : "I beseech (pray) thee (you), go to, now, oh"
	},
	"H4995" : {
		"lemma" : "נָא",
		"xlit" : "nâʼ",
		"pron" : "naw",
		"derivation" : "apparently from H5106 (נוּא) in the sense of harshness from refusal",
		"def" : "properly, tough, i.e. uncooked (flesh)",
		"kjv" : "raw"
	},
	"H4996" : {
		"lemma" : "נֹא",
		"xlit" : "Nôʼ",
		"pron" : "no",
		"derivation" : "of Egyptian origin",
		"def" : "No (i.e. Thebes), the capital of Upper Egypt",
		"kjv" : "No. Compare H528 (אָמוֹן)"
	},
	"H4997" : {
		"lemma" : "נֹאד",
		"xlit" : "nôʼd",
		"pron" : "node",
		"derivation" : "or נאוֹדlemma נֹאוד vowel, corrected to נאוֹדxlit nôʼwd corrected to nʼôwd; also (feminine) נֹאדָה; from an unused root of uncertain signification",
		"def" : "a (skin or leather) bag (for fluids)",
		"kjv" : "bottle"
	},
	"H4998" : {
		"lemma" : "נָאָה",
		"xlit" : "nâʼâh",
		"pron" : "naw-aw'",
		"derivation" : "a primitive root",
		"def" : "properly, to be at home, i.e. (by implication) to be pleasant (or suitable), i.e. beautiful",
		"kjv" : "be beautiful, become, be comely"
	},
	"H4999" : {
		"lemma" : "נָאָה",
		"xlit" : "nâʼâh",
		"pron" : "naw-aw'",
		"derivation" : "from H4998 (נָאָה)",
		"def" : "a home; figuratively, a pasture",
		"kjv" : "habitation, house, pasture, pleasant place"
	},
	"H5000" : {
		"lemma" : "נָאוֶה",
		"xlit" : "nâʼveh",
		"pron" : "naw-veh'",
		"derivation" : "from H4998 (נָאָה) or H5116 (נָוֶה)",
		"def" : "suitable, or beautiful",
		"kjv" : "becometh, comely, seemly"
	},
	"H5001" : {
		"lemma" : "נָאַם",
		"xlit" : "nâʼam",
		"pron" : "naw-am'",
		"derivation" : "a primitive root",
		"def" : "properly, to whisper, i.e. (by implication) to utter as aoracle",
		"kjv" : "say"
	},
	"H5002" : {
		"lemma" : "נְאֻם",
		"xlit" : "nᵉʼum",
		"pron" : "neh-oom'",
		"derivation" : "from H5001 (נָאַם)",
		"def" : "an oracle",
		"kjv" : "(hath) said, saith"
	},
	"H5003" : {
		"lemma" : "נָאַף",
		"xlit" : "nâʼaph",
		"pron" : "naw-af'",
		"derivation" : "a primitive root",
		"def" : "to commit adultery; figuratively, to apostatize",
		"kjv" : "adulterer(-ess), commit(-ing) adultery, woman that breaketh wedlock"
	},
	"H5004" : {
		"lemma" : "נִאֻף",
		"xlit" : "niʼuph",
		"pron" : "nee-oof'",
		"derivation" : "from H5003 (נָאַף)",
		"def" : "adultery",
		"kjv" : "adultery"
	},
	"H5005" : {
		"lemma" : "נַאֲפוּף",
		"xlit" : "naʼăphûwph",
		"pron" : "nah-af-oof'",
		"derivation" : "from H5003 (נָאַף)",
		"def" : "adultery",
		"kjv" : "adultery"
	},
	"H5006" : {
		"lemma" : "נָאַץ",
		"xlit" : "nâʼats",
		"pron" : "naw-ats'",
		"derivation" : "a primitive root; in Ecclesiastes 12:5, by interchange for H5132 (נוּץ), to bloom",
		"def" : "to scorn",
		"kjv" : "abhor, (give occasion to) blaspheme, contemn, despise, flourish, [idiom] great, provoke"
	},
	"H5007" : {
		"lemma" : "נְאָצָה",
		"xlit" : "nᵉʼâtsâh",
		"pron" : "neh-aw-tsaw'",
		"derivation" : "or נֶאָצָה; from H5006 (נָאַץ)",
		"def" : "scorn",
		"kjv" : "blasphemy"
	},
	"H5008" : {
		"lemma" : "נָאַק",
		"xlit" : "nâʼaq",
		"pron" : "naw-ak'",
		"derivation" : "a primitive root",
		"def" : "to groan",
		"kjv" : "groan"
	},
	"H5009" : {
		"lemma" : "נְאָקָה",
		"xlit" : "nᵉʼâqâh",
		"pron" : "neh-aw-kaw'",
		"derivation" : "from H5008 (נָאַק)",
		"def" : "a groan",
		"kjv" : "groaning"
	},
	"H5010" : {
		"lemma" : "נָאַר",
		"xlit" : "nâʼar",
		"pron" : "naw-ar'",
		"derivation" : "a primitive root",
		"def" : "to reject",
		"kjv" : "abhor, make void"
	},
	"H5011" : {
		"lemma" : "נֹב",
		"xlit" : "Nôb",
		"pron" : "nobe",
		"derivation" : "the same as H5108 (נוֹב); fruit",
		"def" : "Nob, a place in Palestine",
		"kjv" : "Nob"
	},
	"H5012" : {
		"lemma" : "נָבָא",
		"xlit" : "nâbâʼ",
		"pron" : "naw-baw'",
		"derivation" : "a primitive root",
		"def" : "to prophesy, i.e. speak (or sing) by inspiration (in prediction or simple discourse)",
		"kjv" : "prophesy(-ing), make self a prophet"
	},
	"H5013" : {
		"lemma" : "נְבָא",
		"xlit" : "nᵉbâʼ",
		"pron" : "neb-aw'",
		"derivation" : "(Aramaic) corresponding to H5012 (נָבָא)",
		"def" : "{to prophesy, i.e. speak (or sing) by inspiration (in prediction or simple discourse) to prophesy, i.e. speak (or sing) by inspiration (in prediction or simple discourse)}",
		"kjv" : "prophesy"
	},
	"H5014" : {
		"lemma" : "נָבַב",
		"xlit" : "nâbab",
		"pron" : "naw-bab'",
		"derivation" : "a primitive root",
		"def" : "to pierce; to be hollow, or (figuratively) foolish",
		"kjv" : "hollow, vain"
	},
	"H5015" : {
		"lemma" : "נְבוֹ",
		"xlit" : "Nᵉbôw",
		"pron" : "neb-o'",
		"derivation" : "probably of foreign derivation",
		"def" : "Nebo, the name of a Babylonian deity, also of a mountain in Moab, and of a place in Palestine",
		"kjv" : "Nebo"
	},
	"H5016" : {
		"lemma" : "נְבוּאָה",
		"xlit" : "nᵉbûwʼâh",
		"pron" : "neb-oo-aw'",
		"derivation" : "from H5012 (נָבָא)",
		"def" : "a prediction (spoken or written)",
		"kjv" : "prophecy"
	},
	"H5017" : {
		"lemma" : "נְבוּאָה",
		"xlit" : "nᵉbûwʼâh",
		"pron" : "neb-oo-aw",
		"derivation" : "(Aramaic) corresponding to H5016 (נְבוּאָה)",
		"def" : "inspired teaching",
		"kjv" : "prophesying"
	},
	"H5018" : {
		"lemma" : "נְבוּזַרְאֲדָן",
		"xlit" : "Nᵉbûwzarʼădân",
		"pron" : "neb-oo-zar-ad-awn'",
		"derivation" : "of foreign origin",
		"def" : "Nebuzaradan, a Babylonian general",
		"kjv" : "Nebuzaradan"
	},
	"H5019" : {
		"lemma" : "נְבוּכַדְנֶאצַּר",
		"xlit" : "Nᵉbûwkadneʼtstsar",
		"pron" : "neb-oo-kad-nets-tsar'",
		"derivation" : "or נְבֻּכַדְנֶאצַּר; (2 Kings 24:1,10), or נְבוּכַדְנֶצַּר; (Esther 2:6; Daniel 1:18), or נְבוּכַדְרֶאצַּר; or נְבוּכַדְרֶאצּוֹר; (Ezra 2:1; Jeremiah 49:28), or foreign derivation",
		"def" : "Nebukadnetstsar (or -retstsar, or -retstsor), king of Babylon",
		"kjv" : "Nebuchadnezzar, Nebuchadrezzar"
	},
	"H5020" : {
		"lemma" : "נְבוּכַדְנֶצַּר",
		"xlit" : "Nᵉbûwkadnetstsar",
		"pron" : "neb-oo-kad-nets-tsar'",
		"derivation" : "(Aramaic) corresponding to H5019 (נְבוּכַדְנֶאצַּר)",
		"def" : "{Nebukadnetstsar (or -retstsar, or -retstsor), king of Babylon}",
		"kjv" : "Nebuchadnezzar"
	},
	"H5021" : {
		"lemma" : "נְבוּשַׁזְבָּן",
		"xlit" : "Nᵉbûwshazbân",
		"pron" : "neb-oo-shaz-bawn'",
		"derivation" : "of foreign derivation",
		"def" : "Nebushazban, Nebuchadnezzar's chief eunuch",
		"kjv" : "Nebushazban"
	},
	"H5022" : {
		"lemma" : "נָבוֹת",
		"xlit" : "Nâbôwth",
		"pron" : "naw-both'",
		"derivation" : "feminine plural from the same as H5011 (נֹב); fruits",
		"def" : "Naboth, an Israelite",
		"kjv" : "Naboth"
	},
	"H5023" : {
		"lemma" : "נְבִזְבָּה",
		"xlit" : "nᵉbizbâh",
		"pron" : "neb-iz-baw'",
		"derivation" : "(Aramaic) of uncertain derivation",
		"def" : "a largess",
		"kjv" : "reward"
	},
	"H5024" : {
		"lemma" : "נָבַח",
		"xlit" : "nâbach",
		"pron" : "naw-bakh'",
		"derivation" : "a primitive root",
		"def" : "to bark (as a dog)",
		"kjv" : "bark"
	},
	"H5025" : {
		"lemma" : "נֹבַח",
		"xlit" : "Nôbach",
		"pron" : "no'-bach",
		"derivation" : "from H5024 (נָבַח); a bark",
		"def" : "Nobach, the name of an Israelite, and of a place East of the Jordan",
		"kjv" : "Nobah"
	},
	"H5026" : {
		"lemma" : "נִבְחַז",
		"xlit" : "Nibchaz",
		"pron" : "nib-khaz'",
		"derivation" : "of foreign origin",
		"def" : "Nibchaz, a deity of the Avites",
		"kjv" : "Nibhaz"
	},
	"H5027" : {
		"lemma" : "נָבַט",
		"xlit" : "nâbaṭ",
		"pron" : "naw-bat'",
		"derivation" : "a primitive root",
		"def" : "to scan, i.e. look intently at; by implication, to regard with pleasure, favor or care",
		"kjv" : "(cause to) behold, consider, look (down), regard, have respect, see"
	},
	"H5028" : {
		"lemma" : "נְבָט",
		"xlit" : "Nᵉbâṭ",
		"pron" : "neb-awt'",
		"derivation" : "from H5027 (נָבַט); regard",
		"def" : "Nebat, the father of Jeroboam I",
		"kjv" : "Nebat"
	},
	"H5029" : {
		"lemma" : "נְבִיא",
		"xlit" : "nᵉbîyʼ",
		"pron" : "neb-ee'",
		"derivation" : "(Aramaic) corresponding to H5030 (נָבִיא)",
		"def" : "a prophet",
		"kjv" : "prophet"
	},
	"H5030" : {
		"lemma" : "נָבִיא",
		"xlit" : "nâbîyʼ",
		"pron" : "naw-bee'",
		"derivation" : "from H5012 (נָבָא)",
		"def" : "a prophet or (generally) inspired man",
		"kjv" : "prophecy, that prophesy, prophet"
	},
	"H5031" : {
		"lemma" : "נְבִיאָה",
		"xlit" : "nᵉbîyʼâh",
		"pron" : "neb-ee-yaw'",
		"derivation" : "feminine of H5030 (נָבִיא)",
		"def" : "a prophetess or (generally) inspired woman; by implication, a poetess; by association a prophet's wife",
		"kjv" : "prophetess"
	},
	"H5032" : {
		"lemma" : "נְבָיוֹת",
		"xlit" : "Nᵉbâyôwth",
		"pron" : "neb-aw-yoth'",
		"derivation" : "or נְבָיֹת; feminine plural from H5107 (נוּב); fruitfulnesses",
		"def" : "Nebajoth, a son of Ismael, and the country settled by him",
		"kjv" : "Nebaioth, Nebajoth"
	},
	"H5033" : {
		"lemma" : "נֵבֶךְ",
		"xlit" : "nêbek",
		"pron" : "nay'-bek",
		"derivation" : "from an unused root meaning to burst forth",
		"def" : "a fountain",
		"kjv" : "spring"
	},
	"H5034" : {
		"lemma" : "נָבֵל",
		"xlit" : "nâbêl",
		"pron" : "naw-bale'",
		"derivation" : "a primitive root",
		"def" : "to wilt; generally, to fall away, fail, faint; figuratively, to be foolish or (morally) wicked; causatively, to despise, disgrace",
		"kjv" : "disgrace, dishounour, lightly esteem, fade (away, -ing), fall (down, -ling, off), do foolishly, come to nought, [idiom] surely, make vile, wither"
	},
	"H5035" : {
		"lemma" : "נֶבֶל",
		"xlit" : "nebel",
		"pron" : "neh'-bel",
		"derivation" : "or נֵבֶל; from H5034 (נָבֵל)",
		"def" : "a skin-bag for liquids (from collapsing when empty); hence, a vase (as similar in shape when full); also a lyre (as having a body of like form)",
		"kjv" : "bottle, pitcher, psaltery, vessel, viol"
	},
	"H5036" : {
		"lemma" : "נָבָל",
		"xlit" : "nâbâl",
		"pron" : "naw-bawl'",
		"derivation" : "from H5034 (נָבֵל)",
		"def" : "stupid; wicked (especially impious)",
		"kjv" : "fool(-ish, -ish man, -ish woman), vile person"
	},
	"H5037" : {
		"lemma" : "נָבָל",
		"xlit" : "Nâbâl",
		"pron" : "naw-bawl'",
		"derivation" : "the same as H5036 (נָבָל); dolt",
		"def" : "Nabal, an Israelite",
		"kjv" : "Nabal"
	},
	"H5038" : {
		"lemma" : "נְבֵלָה",
		"xlit" : "nᵉbêlâh",
		"pron" : "neb-ay-law'",
		"derivation" : "from H5034 (נָבֵל)",
		"def" : "a flabby thing, i.e. a carcase or carrion (human or bestial, often collectively); figuratively, an idol",
		"kjv" : "(dead) body, (dead) carcase, dead of itself, which died, (beast) that (which) dieth of itself"
	},
	"H5039" : {
		"lemma" : "נְבָלָה",
		"xlit" : "nᵉbâlâh",
		"pron" : "neb-aw-law'",
		"derivation" : "feminine of H5036 (נָבָל)",
		"def" : "foolishness, i.e. (morally) wickedness; concretely, a crime; by extension, punishment",
		"kjv" : "folly, vile, villany"
	},
	"H5040" : {
		"lemma" : "נַבְלוּת",
		"xlit" : "nablûwth",
		"pron" : "nab-looth'",
		"derivation" : "from H5036 (נָבָל)",
		"def" : "properly, disgrace, i.e. the (female) pudenda",
		"kjv" : "lewdness"
	},
	"H5041" : {
		"lemma" : "נְבַלָּט",
		"xlit" : "Nᵉballâṭ",
		"pron" : "neb-al-lawt'",
		"derivation" : "apparently from H5036 (נָבָל) and H3909 (לָט); foolish secrecy",
		"def" : "Neballat, a place in Palestine",
		"kjv" : "Neballat"
	},
	"H5042" : {
		"lemma" : "נָבַע",
		"xlit" : "nâbaʻ",
		"pron" : "naw-bah'",
		"derivation" : "a primitive root",
		"def" : "to gush forth; figuratively, to utter (good or bad words); specifically, to emit (a foul odor)",
		"kjv" : "belch out, flowing, pour out, send forth, utter (abundantly)"
	},
	"H5043" : {
		"lemma" : "נֶבְרְשָׁא",
		"xlit" : "nebrᵉshâʼ",
		"pron" : "neb-reh-shaw'",
		"derivation" : "(Aramaic) from an unused root meaning to shine",
		"def" : "a light; plural (collectively) a chandelier",
		"kjv" : "candlestick"
	},
	"H5044" : {
		"lemma" : "נִבְשָׁן",
		"xlit" : "Nibshân",
		"pron" : "nib-shawn'",
		"derivation" : "of uncertain derivation",
		"def" : "Nibshan, a place in Palestine",
		"kjv" : "Nibshan"
	},
	"H5045" : {
		"lemma" : "נֶגֶב",
		"xlit" : "negeb",
		"pron" : "neh'-gheb",
		"derivation" : "from an unused root meaning to be parched",
		"def" : "the south (from its drought); specifically, the Negeb or southern district of Judah, occasionally, Egypt (as south to Palestine)",
		"kjv" : "south (country, side, -ward)"
	},
	"H5046" : {
		"lemma" : "נָגַד",
		"xlit" : "nâgad",
		"pron" : "naw-gad'",
		"derivation" : "a primitive root",
		"def" : "properly, to front, i.e. stand boldly out opposite; by implication (causatively), to manifest; figuratively, to announce (always by word of mouth to one present); specifically, to expose, predict, explain, praise",
		"kjv" : "bewray, [idiom] certainly, certify, declare(-ing), denounce, expound, [idiom] fully, messenger, plainly, profess, rehearse, report, shew (forth), speak, [idiom] surely, tell, utter"
	},
	"H5047" : {
		"lemma" : "נְגַד",
		"xlit" : "nᵉgad",
		"pron" : "neg-ad'",
		"derivation" : "(Aramaic) corresponding to H5046 (נָגַד)",
		"def" : "to flow (through the idea of clearing the way)",
		"kjv" : "issue"
	},
	"H5048" : {
		"lemma" : "נֶגֶד",
		"xlit" : "neged",
		"pron" : "neh'-ghed",
		"derivation" : "from H5046 (נָגַד)",
		"def" : "a front, i.e. part opposite; specifically a counterpart, or mate; usually (adverbial, especially with preposition) over against or before",
		"kjv" : "about, (over) against, [idiom] aloof, [idiom] far (off), [idiom] from, over, presence, [idiom] other side, sight, [idiom] to view"
	},
	"H5049" : {
		"lemma" : "נֶגֶד",
		"xlit" : "neged",
		"pron" : "neh'-ghed",
		"derivation" : "(Aramaic) corresponding to H5048 (נֶגֶד)",
		"def" : "opposite",
		"kjv" : "toward"
	},
	"H5050" : {
		"lemma" : "נָגַהּ",
		"xlit" : "nâgahh",
		"pron" : "naw-gah'",
		"derivation" : "a primitive root",
		"def" : "to glitter; causatively, to illuminate",
		"kjv" : "(en-) lighten, (cause to) shine"
	},
	"H5051" : {
		"lemma" : "נֹגַהּ",
		"xlit" : "nôgahh",
		"pron" : "no'-gah",
		"derivation" : "from H5050 (נָגַהּ)",
		"def" : "brilliancy (literally or figuratively)",
		"kjv" : "bright(-ness), light, (clear) shining"
	},
	"H5052" : {
		"lemma" : "נֹגַהּ",
		"xlit" : "Nôgahh",
		"pron" : "no'-gah",
		"derivation" : "the same as H5051 (נֹגַהּ)",
		"def" : "Nogah, a son of David",
		"kjv" : "Nogah"
	},
	"H5053" : {
		"lemma" : "נֹגַהּ",
		"xlit" : "nôgahh",
		"pron" : "no'-gah",
		"derivation" : "(Aramaic) corresponding to H5051 (נֹגַהּ)",
		"def" : "dawn",
		"kjv" : "morning"
	},
	"H5054" : {
		"lemma" : "נְגֹהָה",
		"xlit" : "nᵉgôhâh",
		"pron" : "neg-o-haw'",
		"derivation" : "feminine of H5051 (נֹגַהּ)",
		"def" : "splendor",
		"kjv" : "brightness"
	},
	"H5055" : {
		"lemma" : "נָגַח",
		"xlit" : "nâgach",
		"pron" : "naw-gakh'",
		"derivation" : "a primitive root",
		"def" : "to butt with the horns; figuratively, to war against",
		"kjv" : "gore, push (down, -ing)"
	},
	"H5056" : {
		"lemma" : "נַגָּח",
		"xlit" : "naggâch",
		"pron" : "nag-gawkh'",
		"derivation" : "from H5055 (נָגַח)",
		"def" : "butting, i.e. vicious",
		"kjv" : "used (wont) to push"
	},
	"H5057" : {
		"lemma" : "נָגִיד",
		"xlit" : "nâgîyd",
		"pron" : "naw-gheed'",
		"derivation" : "or נָגִד; from H5046 (נָגַד)",
		"def" : "a commander (as occupying the front), civil, military or religious; generally (abstractly, plural), honorable themes",
		"kjv" : "captain, chief, excellent thing, (chief) governor, leader, noble, prince, (chief) ruler"
	},
	"H5058" : {
		"lemma" : "נְגִינָה",
		"xlit" : "nᵉgîynâh",
		"pron" : "neg-ee-naw'",
		"derivation" : "or נְגִינַת; (Psalm 61:title), from H5059 (נָגַן)",
		"def" : "properly, instrumental music; by implication, a stringed instrument; by extension, a poem set to music; specifically, an epigram",
		"kjv" : "stringed instrument, musick, Neginoth (plural), song"
	},
	"H5059" : {
		"lemma" : "נָגַן",
		"xlit" : "nâgan",
		"pron" : "naw-gan'",
		"derivation" : "a primitive root",
		"def" : "properly, to thrum, i.e. beat atune with the fingers; expectation. to play on astringed instrument; hence (generally), to make music",
		"kjv" : "player on instruments, sing to the stringed instruments, melody, ministrel, play(-er, -ing)"
	},
	"H5060" : {
		"lemma" : "נָגַע",
		"xlit" : "nâgaʻ",
		"pron" : "naw-gah'",
		"derivation" : "a primitive root",
		"def" : "properly, to touch, i.e. lay the hand upon (for any purpose; euphemistically, to lie with a woman); by implication, to reach (figuratively, to arrive, acquire); violently, to strike (punish, defeat, destroy, etc.)",
		"kjv" : "beat, ([idiom] be able to) bring (down), cast, come (nigh), draw near (nigh), get up, happen, join, near, plague, reach (up), smite, strike, touch"
	},
	"H5061" : {
		"lemma" : "נֶגַע",
		"xlit" : "negaʻ",
		"pron" : "neh'-gah",
		"derivation" : "from H5060 (נָגַע)",
		"def" : "a blow (figuratively, infliction); also (by implication) a spot (concretely, a leprous person or dress)",
		"kjv" : "plague, sore, stricken, stripe, stroke, wound"
	},
	"H5062" : {
		"lemma" : "נָגַף",
		"xlit" : "nâgaph",
		"pron" : "naw-gaf'",
		"derivation" : "a primitive root",
		"def" : "to push, gore, defeat, stub (the toe), inflict (a disease)",
		"kjv" : "beat, dash, hurt, plague, slay, smite (down), strike, stumble, [idiom] surely, put to the worse"
	},
	"H5063" : {
		"lemma" : "נֶגֶף",
		"xlit" : "negeph",
		"pron" : "neh'-ghef",
		"derivation" : "from H5062 (נָגַף)",
		"def" : "a trip (of the foot); figuratively, an infliction (of disease)",
		"kjv" : "plague, stumbling"
	},
	"H5064" : {
		"lemma" : "נָגַר",
		"xlit" : "nâgar",
		"pron" : "naw-gar'",
		"derivation" : "a primitive root",
		"def" : "to flow; figuratively, to stretch out; causatively, to pour out or down; figuratively, to deliver over",
		"kjv" : "fall, flow away, pour down (out), run, shed, spilt, trickle down"
	},
	"H5065" : {
		"lemma" : "נָגַשׂ",
		"xlit" : "nâgas",
		"pron" : "naw-gas'",
		"derivation" : "a primitive root",
		"def" : "to drive (an animal, a workman, a debtor, an army); by implication, to tax, harass, tyrannize",
		"kjv" : "distress, driver, exact(-or), oppress(-or), [idiom] raiser of taxes, taskmaster"
	},
	"H5066" : {
		"lemma" : "נָגַשׁ",
		"xlit" : "nâgash",
		"pron" : "naw-gash'",
		"derivation" : "a primitive root",
		"def" : "to be or come (causatively, bring) near (for any purpose); euphemistically, to lie with a woman; as an enemy, to attack; religious to worship; causatively, to present; figuratively, to adduce an argument; by reversal, to stand back",
		"kjv" : "(make to) approach (nigh), bring (forth, hither, near), (cause to) come (hither, near, nigh), give place, go hard (up), (be, draw, go) near (nigh), offer, overtake, present, put, stand"
	},
	"H5067" : {
		"lemma" : "נֵד",
		"xlit" : "nêd",
		"pron" : "nade",
		"derivation" : "from H5110 (נוּד) in the sense of piling up",
		"def" : "a mound, i.e. wave",
		"kjv" : "heap"
	},
	"H5068" : {
		"lemma" : "נָדַב",
		"xlit" : "nâdab",
		"pron" : "naw-dab'",
		"derivation" : "a primitive root",
		"def" : "to impel; hence, to volunteer (as a soldier), to present spontaneously",
		"kjv" : "offer freely, be (give, make, offer self) willing(-ly)"
	},
	"H5069" : {
		"lemma" : "נְדַב",
		"xlit" : "nᵉdab",
		"pron" : "ned-ab'",
		"derivation" : "(Aramaic) corresponding to H5068 (נָדַב)",
		"def" : "be (or give) liberal(-ly)",
		"kjv" : "(be minded of...own) freewill (offering), offer freely (willingly)"
	},
	"H5070" : {
		"lemma" : "נָדָב",
		"xlit" : "Nâdâb",
		"pron" : "naw-dawb'",
		"derivation" : "from H5068 (נָדַב); liberal",
		"def" : "Nadab, the name of four Israelites",
		"kjv" : "Nadab"
	},
	"H5071" : {
		"lemma" : "נְדָבָה",
		"xlit" : "nᵉdâbâh",
		"pron" : "ned-aw-baw'",
		"derivation" : "from H5068 (נָדַב)",
		"def" : "properly (abstractly) spontaneity, or (adjectively) spontaneous; also (concretely) a spontaneous or (by inference, in plural) abundant gift",
		"kjv" : "free(-will) offering, freely, plentiful, voluntary(-ily, offering), willing(-ly), offering)"
	},
	"H5072" : {
		"lemma" : "נְדַבְיָה",
		"xlit" : "Nᵉdabyâh",
		"pron" : "ned-ab-yaw'",
		"derivation" : "from H5068 (נָדַב) and H3050 (יָהּ); largess of Jah",
		"def" : "Nedabjah, an Israelite",
		"kjv" : "Nedabiah"
	},
	"H5073" : {
		"lemma" : "נִדְבָּךְ",
		"xlit" : "nidbâk",
		"pron" : "nid-bawk'",
		"derivation" : "(Aramaic) from a root meaning to stick",
		"def" : "a layer (of building materials)",
		"kjv" : "row"
	},
	"H5074" : {
		"lemma" : "נָדַד",
		"xlit" : "nâdad",
		"pron" : "naw-dad'",
		"derivation" : "a primitive root",
		"def" : "properly, to wave to and fro (rarely to flap up and down); figuratively, to rove, flee, or (causatively) to drive away",
		"kjv" : "chase (away), [idiom] could not, depart, flee ([idiom] apace, away), (re-) move, thrust away, wander (abroad, -er, -ing)"
	},
	"H5075" : {
		"lemma" : "נְדַד",
		"xlit" : "nᵉdad",
		"pron" : "ned-ad'",
		"derivation" : "(Aramaic) corresponding to H5074 (נָדַד)",
		"def" : "to depart",
		"kjv" : "go from"
	},
	"H5076" : {
		"lemma" : "נָדֻד",
		"xlit" : "nâdud",
		"pron" : "naw-dood'",
		"derivation" : "passive participle of H5074 (נָדַד)",
		"def" : "properly, tossed; abstractly, a rolling (on the bed)",
		"kjv" : "tossing to and fro"
	},
	"H5077" : {
		"lemma" : "נָדָה",
		"xlit" : "nâdâh",
		"pron" : "naw-daw'",
		"derivation" : "or נָדָא; (2 Kings 17:21), a primitive root",
		"def" : "properly, to toss; figuratively, to exclude, i.e. banish, postpone, prohibit",
		"kjv" : "cast out, drive, put far away"
	},
	"H5078" : {
		"lemma" : "נֵדֶה",
		"xlit" : "nêdeh",
		"pron" : "nay'-deh",
		"derivation" : "from H5077 (נָדָה) in the sense of freely flinging money",
		"def" : "a bounty (for prostitution)",
		"kjv" : "gifts"
	},
	"H5079" : {
		"lemma" : "נִדָּה",
		"xlit" : "niddâh",
		"pron" : "nid-daw'",
		"derivation" : "from H5074 (נָדַד)",
		"def" : "properly, rejection; by implication, impurity, especially personal (menstruation) or moral (idolatry, incest)",
		"kjv" : "[idiom] far, filthiness, [idiom] flowers, menstruous (woman), put apart, [idiom] removed (woman), separation, set apart, unclean(-ness, thing, with filthiness)"
	},
	"H5080" : {
		"lemma" : "נָדַח",
		"xlit" : "nâdach",
		"pron" : "naw-dakh'",
		"derivation" : "a primitive root",
		"def" : "to push off; used in a great variety of applications, literally and figuratively (to expel, mislead, strike, inflict, etc.)",
		"kjv" : "banish, bring, cast down (out), chase, compel, draw away, drive (away, out, quite), fetch a stroke, force, go away, outcast, thrust away (out), withdraw"
	},
	"H5081" : {
		"lemma" : "נָדִיב",
		"xlit" : "nâdîyb",
		"pron" : "naw-deeb'",
		"derivation" : "from H5068 (נָדַב)",
		"def" : "properly, voluntary, i.e. generous; hence, magnanimous; as noun, a grandee (sometimes a tyrant)",
		"kjv" : "free, liberal (things), noble, prince, willing (hearted)"
	},
	"H5082" : {
		"lemma" : "נְדִיבָה",
		"xlit" : "nᵉdîybâh",
		"pron" : "ned-ee-baw'",
		"derivation" : "feminine of H5081 (נָדִיב)",
		"def" : "properly, nobility, i.e. reputation",
		"kjv" : "soul"
	},
	"H5083" : {
		"lemma" : "נָדָן",
		"xlit" : "nâdân",
		"pron" : "naw-dawn'",
		"derivation" : "probably from an unused root meaning to give",
		"def" : "a present (for prostitution)",
		"kjv" : "gift"
	},
	"H5084" : {
		"lemma" : "נָדָן",
		"xlit" : "nâdân",
		"pron" : "naw-dawn'",
		"derivation" : "of uncertain derivation",
		"def" : "a sheath (of a sword)",
		"kjv" : "sheath"
	},
	"H5085" : {
		"lemma" : "נִדְנֶה",
		"xlit" : "nidneh",
		"pron" : "nid-neh'",
		"derivation" : "(Aramaic) from the same as H5084 (נָדָן)",
		"def" : "a sheath; figuratively, the body (as the receptacle of the soul)",
		"kjv" : "body"
	},
	"H5086" : {
		"lemma" : "נָדַף",
		"xlit" : "nâdaph",
		"pron" : "naw-daf'",
		"derivation" : "a primitive root",
		"def" : "to shove asunder, i.e. disperse",
		"kjv" : "drive (away, to and fro), thrust down, shaken, tossed to and fro"
	},
	"H5087" : {
		"lemma" : "נָדַר",
		"xlit" : "nâdar",
		"pron" : "naw-dar'",
		"derivation" : "a primitive root",
		"def" : "to promise (pos., to do or give something to God)",
		"kjv" : "(make a) vow"
	},
	"H5088" : {
		"lemma" : "נֶדֶר",
		"xlit" : "neder",
		"pron" : "neh'-der",
		"derivation" : "or נֵדֶר; from H5087 (נָדַר)",
		"def" : "a promise (to God); also (concretely) a thing promised",
		"kjv" : "vow(-ed)"
	},
	"H5089" : {
		"lemma" : "נֹהַּ",
		"xlit" : "nôahh",
		"pron" : "no'-ah",
		"derivation" : "from an unused root meaning to lament",
		"def" : "lamentation",
		"kjv" : "wailing"
	},
	"H5090" : {
		"lemma" : "נָהַג",
		"xlit" : "nâhag",
		"pron" : "naw-hag'",
		"derivation" : "a primitive root",
		"def" : "to drive forth (a person, an animal or chariot), i.e. lead, carry away; reflexively, to proceed (i.e. impel or guide oneself); also (from the panting induced by effort), to sigh",
		"kjv" : "acquaint, bring (away), carry away, drive (away), lead (away, forth), (be) guide, lead (away, forth)"
	},
	"H5091" : {
		"lemma" : "נָהָה",
		"xlit" : "nâhâh",
		"pron" : "naw-haw'",
		"derivation" : "a primitive root",
		"def" : "to groan, i.e. bewail; hence (through the idea of crying aloud), to assemble (as if on proclamation)",
		"kjv" : "lament, wail"
	},
	"H5092" : {
		"lemma" : "נְהִי",
		"xlit" : "nᵉhîy",
		"pron" : "neh-hee'",
		"derivation" : "from H5091 (נָהָה)",
		"def" : "an elegy",
		"kjv" : "lamentation, wailing"
	},
	"H5093" : {
		"lemma" : "נִהְיָה",
		"xlit" : "nihyâh",
		"pron" : "nih-yaw'",
		"derivation" : "feminine of H5092 (נְהִי)",
		"def" : "lamentation",
		"kjv" : "doleful"
	},
	"H5094" : {
		"lemma" : "נְהִיר",
		"xlit" : "nᵉhîyr",
		"pron" : "neh-heere'",
		"derivation" : "(Aramaic) or נְהִירוּ; (Aramaic), from the same as H5105 (נְהָרָה)",
		"def" : "illumination, i.e. (figuratively) wisdom",
		"kjv" : "light"
	},
	"H5095" : {
		"lemma" : "נָהַל",
		"xlit" : "nâhal",
		"pron" : "naw-hal'",
		"derivation" : "a primitive root",
		"def" : "properly, to run with asparkle, i.e. flow; hence (transitively), to conduct, and (by inference) to protect, sustain",
		"kjv" : "carry, feed, guide, lead (gently, on)"
	},
	"H5096" : {
		"lemma" : "נַהֲלָל",
		"xlit" : "Nahălâl",
		"pron" : "nah-hal-awl'",
		"derivation" : "or נַהֲלֹל; the same as H5097 (נַהֲלֹל)",
		"def" : "Nahalal or Nahalol, a place in Palestine",
		"kjv" : "Nahalal, Nahallal, Nahalol"
	},
	"H5097" : {
		"lemma" : "נַהֲלֹל",
		"xlit" : "nahălôl",
		"pron" : "nah-hal-ole'",
		"derivation" : "from H5095 (נָהַל)",
		"def" : "pasture",
		"kjv" : "bush"
	},
	"H5098" : {
		"lemma" : "נָהַם",
		"xlit" : "nâham",
		"pron" : "naw-ham'",
		"derivation" : "a primitive root",
		"def" : "to growl",
		"kjv" : "mourn, roar(-ing)"
	},
	"H5099" : {
		"lemma" : "נַהַם",
		"xlit" : "naham",
		"pron" : "nah'-ham",
		"derivation" : "from H5098 (נָהַם)",
		"def" : "a snarl",
		"kjv" : "roaring"
	},
	"H5100" : {
		"lemma" : "נְהָמָה",
		"xlit" : "nᵉhâmâh",
		"pron" : "neh-haw-maw'",
		"derivation" : "feminine of H5099 (נַהַם)",
		"def" : "snarling",
		"kjv" : "disquietness, roaring"
	},
	"H5101" : {
		"lemma" : "נָהַק",
		"xlit" : "nâhaq",
		"pron" : "naw-hak'",
		"derivation" : "a primitive root",
		"def" : "to bray (as an ass), scream (from hunger)",
		"kjv" : "bray"
	},
	"H5102" : {
		"lemma" : "נָהַר",
		"xlit" : "nâhar",
		"pron" : "naw-har'",
		"derivation" : "a primitive root",
		"def" : "to sparkle, i.e. (figuratively) be cheerful; hence (from the sheen of a running stream) to flow, i.e. (figuratively) assemble",
		"kjv" : "flow (together), be lightened"
	},
	"H5103" : {
		"lemma" : "נְהַר",
		"xlit" : "nᵉhar",
		"pron" : "neh-har'",
		"derivation" : "(Aramaic) from a root corresponding to H5102 (נָהַר)",
		"def" : "a river, especially the Euphrates",
		"kjv" : "river, stream"
	},
	"H5104" : {
		"lemma" : "נָהָר",
		"xlit" : "nâhâr",
		"pron" : "naw-hawr'",
		"derivation" : "from H5102 (נָהַר)",
		"def" : "a stream (including the sea; expectation the Nile, Euphrates, etc.); figuratively, prosperity",
		"kjv" : "flood, river"
	},
	"H5105" : {
		"lemma" : "נְהָרָה",
		"xlit" : "nᵉhârâh",
		"pron" : "neh-haw-raw'",
		"derivation" : "from H5102 (נָהַר) in its original sense",
		"def" : "daylight",
		"kjv" : "light"
	},
	"H5106" : {
		"lemma" : "נוּא",
		"xlit" : "nûwʼ",
		"pron" : "noo",
		"derivation" : "a primitive root",
		"def" : "to refuse, forbid, dissuade, or neutralize",
		"kjv" : "break, disallow, discourage, make of none effect"
	},
	"H5107" : {
		"lemma" : "נוּב",
		"xlit" : "nûwb",
		"pron" : "noob",
		"derivation" : "a primitive root",
		"def" : "to germinate, i.e. (figuratively) to (causatively, make) flourish; also (of words), to utter",
		"kjv" : "bring forth (fruit), make cheerful, increase"
	},
	"H5108" : {
		"lemma" : "נוֹב",
		"xlit" : "nôwb",
		"pron" : "nobe",
		"derivation" : "or נֵיב; from H5107 (נוּב)",
		"def" : "produce, literally or figuratively",
		"kjv" : "fruit"
	},
	"H5109" : {
		"lemma" : "נוֹבַי",
		"xlit" : "Nôwbay",
		"pron" : "no-bah'ee",
		"derivation" : "from H5108 (נוֹב); fruitful",
		"def" : "Nobai, an Israelite",
		"kjv" : "Nebai (from the margin)"
	},
	"H5110" : {
		"lemma" : "נוּד",
		"xlit" : "nûwd",
		"pron" : "nood",
		"derivation" : "a primitive root",
		"def" : "to nod, i.e. waver; figuratively, to wander, flee, disappear; also (from shaking the head in sympathy), to console, deplore, or (from tossing the head in scorn) taunt",
		"kjv" : "bemoan, flee, get, mourn, make to move, take pity, remove, shake, skip for joy, be sorry, vagabond, way, wandering"
	},
	"H5111" : {
		"lemma" : "נוּד",
		"xlit" : "nûwd",
		"pron" : "nood",
		"derivation" : "(Aramaic) corresponding to H5116 (נָוֶה)",
		"def" : "to flee",
		"kjv" : "get away"
	},
	"H5112" : {
		"lemma" : "נוֹד",
		"xlit" : "nôwd",
		"pron" : "node",
		"derivation" : "(only defective נֹד ); from H5110 (נוּד)",
		"def" : "exile",
		"kjv" : "wandering"
	},
	"H5113" : {
		"lemma" : "נוֹד",
		"xlit" : "Nôwd",
		"pron" : "node",
		"derivation" : "the same as H5112 (נוֹד)lemma נוֹדּ extra dagesh, corrected to נוֹד; vagrancy",
		"def" : "Nod, the land of Cain",
		"kjv" : "Nod"
	},
	"H5114" : {
		"lemma" : "נוֹדָב",
		"xlit" : "Nôwdâb",
		"pron" : "no-dawb'",
		"derivation" : "from H5068 (נָדַב); noble",
		"def" : "Nodab, an Arab tribe",
		"kjv" : "Nodab"
	},
	"H5115" : {
		"lemma" : "נָוָה",
		"xlit" : "nâvâh",
		"pron" : "naw-vaw'",
		"derivation" : "a primitive root; (compare H5116 (נָוֶה))",
		"def" : "to rest (as at home); causatively (through the implied idea of beauty ), to celebrate (with praises)",
		"kjv" : "keept at home, prepare an habitation"
	},
	"H5116" : {
		"lemma" : "נָוֶה",
		"xlit" : "nâveh",
		"pron" : "naw-veh'",
		"derivation" : "or (feminine) נָוָה; from H5115 (נָוָה)",
		"def" : "(adjectively) at home; hence (by implication of satisfaction) lovely; also (noun) a home, of God (temple), men (residence), flocks (pasture), or wild animals (den)",
		"kjv" : "comely, dwelling (place), fold, habitation, pleasant place, sheepcote, stable, tarried"
	},
	"H5117" : {
		"lemma" : "נוּחַ",
		"xlit" : "nûwach",
		"pron" : "noo'-akh",
		"derivation" : "a primitive root",
		"def" : "to rest, i.e. settle down; used in a great variety of applications, literal and figurative, intransitive, transitive and causative (to dwell, stay, let fall, place, let alone, withdraw, give comfort, etc.)",
		"kjv" : "cease, be confederate, lay, let down, (be) quiet, remain, (cause to, be at, give, have, make to) rest, set down. Compare H3241 (יָנִים)"
	},
	"H5118" : {
		"lemma" : "נוּחַ",
		"xlit" : "nûwach",
		"pron" : "noo'-akh",
		"derivation" : "or נוֹחַ; from H5117 (נוּחַ)",
		"def" : "quiet",
		"kjv" : "rest(-ed, -ing place)"
	},
	"H5119" : {
		"lemma" : "נוֹחָה",
		"xlit" : "Nôwchâh",
		"pron" : "no-chaw'",
		"derivation" : "feminine of H5118 (נוּחַ); quietude",
		"def" : "Nochah, an Israelite",
		"kjv" : "Nohah"
	},
	"H5120" : {
		"lemma" : "נוּט",
		"xlit" : "nûwṭ",
		"pron" : "noot",
		"def" : "to quake",
		"kjv" : "be moved"
	},
	"H5121" : {
		"lemma" : "נָוִית",
		"xlit" : "Nâvîyth",
		"pron" : "naw-veeth'",
		"derivation" : "from H5115 (נָוָה); residence",
		"def" : "Navith, a place in Palestine",
		"kjv" : "Naioth (from the margin)"
	},
	"H5122" : {
		"lemma" : "נְוָלוּ",
		"xlit" : "nᵉvâlûw",
		"pron" : "nev-aw-loo'",
		"derivation" : "(Aramaic) or נְוָלִי; (Aramaic), from an unused root probably meaning to be foul",
		"def" : "a sink",
		"kjv" : "dunghill"
	},
	"H5123" : {
		"lemma" : "נוּם",
		"xlit" : "nûwm",
		"pron" : "noom",
		"derivation" : "a primitive root",
		"def" : "to slumber (from drowsiness)",
		"kjv" : "sleep, slumber"
	},
	"H5124" : {
		"lemma" : "נוּמָה",
		"xlit" : "nûwmâh",
		"pron" : "noo-maw'",
		"derivation" : "from H5123 (נוּם)",
		"def" : "sleepiness",
		"kjv" : "drowsiness"
	},
	"H5125" : {
		"lemma" : "נוּן",
		"xlit" : "nûwn",
		"pron" : "noon",
		"derivation" : "a primitive root",
		"def" : "to resprout, i.e. propagate by shoots; figuratively, to be perpetual",
		"kjv" : "be continued"
	},
	"H5126" : {
		"lemma" : "נוּן",
		"xlit" : "Nûwn",
		"pron" : "noon",
		"derivation" : "or נוֹן; (1 Chronicles 7:27), from H5125 (נוּן); perpetuity",
		"def" : "Nun or Non, the father of Joshua",
		"kjv" : "Non, Nun"
	},
	"H5127" : {
		"lemma" : "נוּס",
		"xlit" : "nûwç",
		"pron" : "noos",
		"derivation" : "a primitive root",
		"def" : "to flit, i.e. vanish away (subside, escape; causatively, chase, impel, deliver)",
		"kjv" : "[idiom] abate, away, be displayed, (make to) flee (away, -ing), put to flight, [idiom] hide, lift up a standard"
	},
	"H5128" : {
		"lemma" : "נוּעַ",
		"xlit" : "nûwaʻ",
		"pron" : "noo'-ah",
		"derivation" : "a primitive root",
		"def" : "to waver, in a great variety of applications, literally and figuratively (as subjoined)",
		"kjv" : "continually, fugitive, [idiom] make, to (go) up and down, be gone away, (be) move(-able, -d), be promoted, reel, remove, scatter, set, shake, sift, stagger, to and fro, be vagabond, wag, (make) wander (up and down)"
	},
	"H5129" : {
		"lemma" : "נוֹעַדְיָה",
		"xlit" : "Nôwʻadyâh",
		"pron" : "no-ad-yaw'",
		"derivation" : "from H3259 (יָעַד) and H3050 (יָהּ); convened of Jah",
		"def" : "Noadjah, the name of an Israelite, and a false prophetess",
		"kjv" : "Noadiah"
	},
	"H5130" : {
		"lemma" : "נוּף",
		"xlit" : "nûwph",
		"pron" : "noof",
		"derivation" : "a primitive root",
		"def" : "to quiver (i.e. vibrate up and down, or rock to and fro); used in a great variety of applications (including sprinkling, beckoning, rubbing, bastinadoing, sawing, waving, etc.)",
		"kjv" : "lift up, move, offer, perfume, send, shake, sift, strike, wave"
	},
	"H5131" : {
		"lemma" : "נוֹף",
		"xlit" : "nôwph",
		"pron" : "nofe",
		"derivation" : "from H5130 (נוּף)",
		"def" : "elevation",
		"kjv" : "situation. Compare H5297 (נֹף)"
	},
	"H5132" : {
		"lemma" : "נוּץ",
		"xlit" : "nûwts",
		"pron" : "noots",
		"derivation" : "a primitive root",
		"def" : "properly, to flash; hence, to blossom (from the brilliancy of color); also, to fly away (from the quickness of motion)",
		"kjv" : "flee away, bud (forth)"
	},
	"H5133" : {
		"lemma" : "נוֹצָה",
		"xlit" : "nôwtsâh",
		"pron" : "no-tsaw'",
		"derivation" : "or נֹצָה; feminine active participle of H5327 (נָצָה) in the sense of flying",
		"def" : "a pinion (or wing feather); often (collectively) plumage",
		"kjv" : "feather(-s), ostrich"
	},
	"H5134" : {
		"lemma" : "נוּק",
		"xlit" : "nûwq",
		"pron" : "nook",
		"derivation" : "a primitive root",
		"def" : "to suckle",
		"kjv" : "nurse"
	},
	"H5135" : {
		"lemma" : "נוּר",
		"xlit" : "nûwr",
		"pron" : "noor",
		"derivation" : "(Aramaic) from an unused root (corresponding to that of H5216 (נִיר)) meaning to shine",
		"def" : "fire",
		"kjv" : "fiery, fire"
	},
	"H5136" : {
		"lemma" : "נוּשׁ",
		"xlit" : "nûwsh",
		"pron" : "noosh",
		"derivation" : "a primitive root",
		"def" : "to be sick, i.e. (figuratively) distressed",
		"kjv" : "be full of heaviness"
	},
	"H5137" : {
		"lemma" : "נָזָה",
		"xlit" : "nâzâh",
		"pron" : "naw-zaw'",
		"derivation" : "a primitive root",
		"def" : "to spirt, i.e. besprinkle (especially in expiation)",
		"kjv" : "sprinkle"
	},
	"H5138" : {
		"lemma" : "נָזִיד",
		"xlit" : "nâzîyd",
		"pron" : "naw-zeed'",
		"derivation" : "from H2102 (זוּד)",
		"def" : "something boiled, i.e. soup",
		"kjv" : "pottage"
	},
	"H5139" : {
		"lemma" : "נָזִיר",
		"xlit" : "nâzîyr",
		"pron" : "naw-zeer'",
		"derivation" : "or נָזִר; from H5144 (נָזַר)",
		"def" : "separate, i.e. consecrated (as prince, a Nazirite); hence (figuratively from the latter) an unpruned vine (like an unshorn Nazirite)",
		"kjv" : "Nazarite (by a false alliteration with Nazareth), separate(-d), vine undressed"
	},
	"H5140" : {
		"lemma" : "נָזַל",
		"xlit" : "nâzal",
		"pron" : "naw-zal'",
		"derivation" : "a primitive root",
		"def" : "to drip, or shed by trickling",
		"kjv" : "distil, drop, flood, (cause to) flow(-ing), gush out, melt, pour (down), running water, stream"
	},
	"H5141" : {
		"lemma" : "נֶזֶם",
		"xlit" : "nezem",
		"pron" : "neh'-zem",
		"derivation" : "from an unused root of uncertain meaning",
		"def" : "a nose-ring",
		"kjv" : "earring, jewel"
	},
	"H5142" : {
		"lemma" : "נְזַק",
		"xlit" : "nᵉzaq",
		"pron" : "nez-ak'",
		"derivation" : "(Aramaic) corresponding to the root of H5143 (נֵזֶק)",
		"def" : "to suffer (causatively, inflict) loss",
		"kjv" : "have (en-) damage, hurt(-ful)"
	},
	"H5143" : {
		"lemma" : "נֵזֶק",
		"xlit" : "nêzeq",
		"pron" : "nay'zek",
		"derivation" : "from an unused root meaning to injure",
		"def" : "loss",
		"kjv" : "damage"
	},
	"H5144" : {
		"lemma" : "נָזַר",
		"xlit" : "nâzar",
		"pron" : "naw-zar'",
		"derivation" : "a primitive root",
		"def" : "to hold aloof, i.e. (intransitivey) abstain (from food and drink, from impurity, and even from divine worship (i.e. apostatize)); specifically, to set apart (to sacred purposes), i.e. devote",
		"kjv" : "consecrate, separate(-ing, self)"
	},
	"H5145" : {
		"lemma" : "נֶזֶר",
		"xlit" : "nezer",
		"pron" : "neh'-zer",
		"derivation" : "or נֵזֶר; from H5144 (נָזַר)",
		"def" : "properly, something set apart, i.e. (abstractly) dedication (of a priet or Nazirite); hence (concretely) unshorn locks; also (by implication) a chaplet (especially of royalty)",
		"kjv" : "consecration, crown, hair, separation"
	},
	"H5146" : {
		"lemma" : "נֹחַ",
		"xlit" : "Nôach",
		"pron" : "no'-akh",
		"derivation" : "the same as H5118 (נוּחַ); rest",
		"def" : "Noach, the patriarch of the flood",
		"kjv" : "Noah"
	},
	"H5147" : {
		"lemma" : "נַחְבִּי",
		"xlit" : "Nachbîy",
		"pron" : "nakh-bee'",
		"derivation" : "from H2247 (חָבָה); occult",
		"def" : "Nachbi, an Israelite",
		"kjv" : "Nakbi"
	},
	"H5148" : {
		"lemma" : "נָחָה",
		"xlit" : "nâchâh",
		"pron" : "naw-khaw'",
		"derivation" : "a primitive root",
		"def" : "to guide; by implication, to transport (into exile, or as colonists)",
		"kjv" : "bestow, bring, govern, guide, lead (forth), put, straiten"
	},
	"H5149" : {
		"lemma" : "נְחוּם",
		"xlit" : "Nᵉchûwm",
		"pron" : "neh-khoom'",
		"derivation" : "from H5162 (נָחַם); comforted",
		"def" : "Nechum, an Israelite",
		"kjv" : "Nehum"
	},
	"H5150" : {
		"lemma" : "נִחוּם",
		"xlit" : "nichûwm",
		"pron" : "nee-khoom'",
		"derivation" : "or נִחֻם; from H5162 (נָחַם)",
		"def" : "properly, consoled; abstractly, solace",
		"kjv" : "comfort(-able), repenting"
	},
	"H5151" : {
		"lemma" : "נַחוּם",
		"xlit" : "Nachûwm",
		"pron" : "nakh-oom'",
		"derivation" : "from H5162 (נָחַם); comfortable",
		"def" : "Nachum, an Israelite prophet",
		"kjv" : "Nahum"
	},
	"H5152" : {
		"lemma" : "נָחוֹר",
		"xlit" : "Nâchôwr",
		"pron" : "naw-khore'",
		"derivation" : "from the same as H5170 (נַחַר); snorer",
		"def" : "Nochor, the name of the grandfather and a brother of Abraham",
		"kjv" : "Nahor"
	},
	"H5153" : {
		"lemma" : "נָחוּשׁ",
		"xlit" : "nâchûwsh",
		"pron" : "naw-khoosh'",
		"derivation" : "apparently passive participle of H5172 (נָחַשׁ) (perhaps in the sense of ringing), i.e. bell-metal; or from the red color of the throat of a serpent (H5175 (נָחָשׁ), as denominative) when hissing)",
		"def" : "coppery, i.e. (figuratively) hard",
		"kjv" : "of brass"
	},
	"H5154" : {
		"lemma" : "נְחוּשָׁה",
		"xlit" : "nᵉchûwshâh",
		"pron" : "nekh-oo-shaw'",
		"derivation" : "or נְחֻשָׁה; feminine of H5153 (נָחוּשׁ)",
		"def" : "copper",
		"kjv" : "brass, steel. Compare H5176 (נָחָשׁ)"
	},
	"H5155" : {
		"lemma" : "נְחִילָה",
		"xlit" : "nᵉchîylâh",
		"pron" : "nekh-ee-law'",
		"derivation" : "probably denominative from H2485 (חָלִיל)",
		"def" : "a flute",
		"kjv" : "(plural) Nehiloth"
	},
	"H5156" : {
		"lemma" : "נְחִיר",
		"xlit" : "nᵉchîyr",
		"pron" : "nekh-eer'",
		"derivation" : "from the same as H5170 (נַחַר)",
		"def" : "a nostril",
		"kjv" : "(dual) nostrils"
	},
	"H5157" : {
		"lemma" : "נָחַל",
		"xlit" : "nâchal",
		"pron" : "naw-khal'",
		"derivation" : "a primitive root",
		"def" : "to inherit (as a (figurative) mode of descent), or (generally) to occupy; causatively, to bequeath, or (generally) distribute, instate",
		"kjv" : "divide, have (inheritance), take as a heritage, (cause to, give to, make to) inherit, (distribute for, divide (for, for an, by), give for, have, leave for, take (for)) inheritance, (have in, cause to, be made to) possess(-ion)"
	},
	"H5158" : {
		"lemma" : "נַחַל",
		"xlit" : "nachal",
		"pron" : "nakh'-al",
		"derivation" : "or (feminine) נַחְלָה; (Psalm 124:4), or נַחֲלָה; (Ezekiel 47:19; Ezekiel 48:28), from H5157 (נָחַל) in its original sense",
		"def" : "a stream, especially a winter torrent; (by implication) a (narrow) valley (in which a brook runs); also a shaft (of a mine)",
		"kjv" : "brook, flood, river, stream, valley"
	},
	"H5159" : {
		"lemma" : "נַחֲלָה",
		"xlit" : "nachălâh",
		"pron" : "nakh-al-aw'",
		"derivation" : "from H5157 (נָחַל) (in its usual sense)",
		"def" : "properly, something inherited, i.e. (abstractly) occupancy, or (concretely) an heirloom; generally an estate, patrimony or portion",
		"kjv" : "heritage, to inherit, inheritance, possession. Compare H5158 (נַחַל)"
	},
	"H5160" : {
		"lemma" : "נַחֲלִיאֵל",
		"xlit" : "Nachălîyʼêl",
		"pron" : "nakh-al-ee-ale'",
		"derivation" : "from H5158 (נַחַל) and H410 (אֵל); valley of God",
		"def" : "Nachaliel, a place in the Desert",
		"kjv" : "Nahaliel"
	},
	"H5161" : {
		"lemma" : "נֶחֱלָמִי",
		"xlit" : "Nechĕlâmîy",
		"pron" : "nekh-el-aw-mee'",
		"derivation" : "apparently a patronymic from an unused name (apparently passive participle of H2492 (חָלַם)); dreamed",
		"def" : "a Nechelamite, or descendant of Nechlam",
		"kjv" : "Nehelamite"
	},
	"H5162" : {
		"lemma" : "נָחַם",
		"xlit" : "nâcham",
		"pron" : "naw-kham'",
		"derivation" : "a primitive root",
		"def" : "properly, to sigh, i.e. breathe strongly; by implication, to be sorry, i.e. (in a favorable sense) to pity, console or (reflexively) rue; or (unfavorably) to avenge (oneself)",
		"kjv" : "comfort (self), ease (one's self), repent(-er,-ing, self)"
	},
	"H5163" : {
		"lemma" : "נַחַם",
		"xlit" : "Nacham",
		"pron" : "nakh'-am",
		"derivation" : "from H5162 (נָחַם); consolation",
		"def" : "Nacham, an Israelite",
		"kjv" : "Naham"
	},
	"H5164" : {
		"lemma" : "נֹחַם",
		"xlit" : "nôcham",
		"pron" : "no'-kham",
		"derivation" : "from H5162 (נָחַם)",
		"def" : "ruefulness, i.e. desistance",
		"kjv" : "repentance"
	},
	"H5165" : {
		"lemma" : "נֶחָמָה",
		"xlit" : "nechâmâh",
		"pron" : "nekh-aw-maw'",
		"derivation" : "from H5162 (נָחַם)",
		"def" : "consolation",
		"kjv" : "comfort"
	},
	"H5166" : {
		"lemma" : "נְחֶמְיָה",
		"xlit" : "Nᵉchemyâh",
		"pron" : "nekh-em-yaw'",
		"derivation" : "from H5162 (נָחַם) and H3050 (יָהּ); consolation of Jah",
		"def" : "Nechemjah, the name of three Israelites",
		"kjv" : "Nehemiah"
	},
	"H5167" : {
		"lemma" : "נַחֲמָנִי",
		"xlit" : "Nachămânîy",
		"pron" : "nakh-am-aw-nee'",
		"derivation" : "from H5162 (נָחַם); consolatory",
		"def" : "Nachamani, an Israelite",
		"kjv" : "Nahamani"
	},
	"H5168" : {
		"lemma" : "נַחְנוּ",
		"xlit" : "nachnûw",
		"pron" : "nakh-noo'",
		"derivation" : "for H587 (אֲנַחְנוּ)",
		"def" : "we",
		"kjv" : "we"
	},
	"H5169" : {
		"lemma" : "נָחַץ",
		"xlit" : "nâchats",
		"pron" : "naw-khats'",
		"derivation" : "a primitive root",
		"def" : "to be urgent",
		"kjv" : "require haste"
	},
	"H5170" : {
		"lemma" : "נַחַר",
		"xlit" : "nachar",
		"pron" : "nakh'-ar",
		"derivation" : "and (feminine) נַחֲרָה; from an unused root meaning to snort or snore",
		"def" : "a snorting",
		"kjv" : "nostrils, snorting"
	},
	"H5171" : {
		"lemma" : "נַחֲרַי",
		"xlit" : "Nachăray",
		"pron" : "nakh-ar-ah'-ee",
		"derivation" : "or נַחְרַי; from the same as H5170 (נַחַר); snorer",
		"def" : "Nacharai or Nachrai, an Israelite",
		"kjv" : "Naharai, Nahari"
	},
	"H5172" : {
		"lemma" : "נָחַשׁ",
		"xlit" : "nâchash",
		"pron" : "naw-khash'",
		"derivation" : "a primitive root",
		"def" : "properly, to hiss, i.e. whisper a (magic) spell; generally, to prognosticate",
		"kjv" : "[idiom] certainly, divine, enchanter, (use) [idiom] enchantment, learn by experience, [idiom] indeed, diligently observe"
	},
	"H5173" : {
		"lemma" : "נַחַשׁ",
		"xlit" : "nachash",
		"pron" : "nakh'-ash",
		"derivation" : "from H5172 (נָחַשׁ)",
		"def" : "an incantation or augury",
		"kjv" : "enchantment"
	},
	"H5174" : {
		"lemma" : "נְחָשׁ",
		"xlit" : "nᵉchâsh",
		"pron" : "nekh-awsh'",
		"derivation" : "(Aramaic) corresponding to H5154 (נְחוּשָׁה)",
		"def" : "copper",
		"kjv" : "brass"
	},
	"H5175" : {
		"lemma" : "נָחָשׁ",
		"xlit" : "nâchâsh",
		"pron" : "naw-khawsh'",
		"derivation" : "from H5172 (נָחַשׁ)",
		"def" : "a snake (from its hiss)",
		"kjv" : "serpent"
	},
	"H5176" : {
		"lemma" : "נָחָשׁ",
		"xlit" : "Nâchâsh",
		"pron" : "naw-khawsh'",
		"derivation" : "the same as H5175 (נָחָשׁ)",
		"def" : "Nachash, the name of two persons apparently non-Israelite",
		"kjv" : "Nahash"
	},
	"H5177" : {
		"lemma" : "נַחְשׁוֹן",
		"xlit" : "Nachshôwn",
		"pron" : "nakh-shone'",
		"derivation" : "from H5172 (נָחַשׁ); enchanter",
		"def" : "Nachshon, an Israelite",
		"kjv" : "Naashon, Nahshon"
	},
	"H5178" : {
		"lemma" : "נְחֹשֶׁת",
		"xlit" : "nᵉchôsheth",
		"pron" : "nekh-o'-sheth",
		"derivation" : "for H5154 (נְחוּשָׁה)",
		"def" : "copper, hence, something made of that metal, i.e. coin, a fetter; figuratively, base (as compared with gold or silver)",
		"kjv" : "brasen, brass, chain, copper, fetter (of brass), filthiness, steel"
	},
	"H5179" : {
		"lemma" : "נְחֻשְׁתָּא",
		"xlit" : "Nᵉchushtâʼ",
		"pron" : "nekh-oosh-taw'",
		"derivation" : "from H5178 (נְחֹשֶׁת); copper",
		"def" : "Nechushta, an Israelitess",
		"kjv" : "Nehushta"
	},
	"H5180" : {
		"lemma" : "נְחֻשְׁתָּן",
		"xlit" : "Nᵉchushtân",
		"pron" : "nekh-oosh-tawn'",
		"derivation" : "from H5178 (נְחֹשֶׁת)",
		"def" : "something made of copper, i.e. the copper serpent of the Desert",
		"kjv" : "Nehushtan"
	},
	"H5181" : {
		"lemma" : "נָחַת",
		"xlit" : "nâchath",
		"pron" : "naw-khath'",
		"derivation" : "a primitive root",
		"def" : "to sink, i.e. descend; causatively, to press or lead down",
		"kjv" : "be broken, (cause to) come down, enter, go down, press sore, settle, stick fast"
	},
	"H5182" : {
		"lemma" : "נְחַת",
		"xlit" : "nᵉchath",
		"pron" : "nekh-ath'",
		"derivation" : "(Aramaic) corresponding to H5181 (נָחַת)",
		"def" : "to descend; causatively, to bring away, deposit, depose",
		"kjv" : "carry, come down, depose, lay up, place"
	},
	"H5183" : {
		"lemma" : "נַחַת",
		"xlit" : "Nachath",
		"pron" : "nakh'-ath",
		"derivation" : "from H5182 (נְחַת); also (intransitively; perhaps from H5117 (נוּחַ))",
		"def" : "a descent, i.e. imposition, unfavorable (punishment) or favorable (food); restfulness",
		"kjv" : "lighting down, quiet(-ness), to rest, be set on"
	},
	"H5184" : {
		"lemma" : "נַחַת",
		"xlit" : "Nachath",
		"pron" : "nakh'-ath",
		"derivation" : "the same as H5183 (נַחַת); quiet",
		"def" : "Nachath, the name of an Edomite and of two Israelites",
		"kjv" : "Nahath"
	},
	"H5185" : {
		"lemma" : "נָחֵת",
		"xlit" : "nâchêth",
		"pron" : "naw-khayth'",
		"derivation" : "from H5181 (נָחַת)",
		"def" : "descending",
		"kjv" : "come down"
	},
	"H5186" : {
		"lemma" : "נָטָה",
		"xlit" : "nâṭâh",
		"pron" : "naw-taw'",
		"derivation" : "a primitive root",
		"def" : "to stretch or spread out; by implication, to bend away (including moral deflection); used in a great variety of application",
		"kjv" : "[phrase] afternoon, apply, bow (down, -ing), carry aside, decline, deliver, extend, go down, be gone, incline, intend, lay, let down, offer, outstretched, overthrown, pervert, pitch, prolong, put away, shew, spread (out), stretch (forth, out), take (aside), turn (aside, away), wrest, cause to yield"
	},
	"H5187" : {
		"lemma" : "נְטִיל",
		"xlit" : "nᵉṭîyl",
		"pron" : "net-eel'",
		"derivation" : "from H5190 (נָטַל)",
		"def" : "laden",
		"kjv" : "that bear"
	},
	"H5188" : {
		"lemma" : "נְטִיפָה",
		"xlit" : "nᵉṭîyphâh",
		"pron" : "net-ee-faw'",
		"derivation" : "from H5197 (נָטַף)",
		"def" : "a pendant for the ears (especially of pearls)",
		"kjv" : "chain, collar"
	},
	"H5189" : {
		"lemma" : "נְטִישָׁה",
		"xlit" : "nᵉṭîyshâh",
		"pron" : "net-ee-shaw'",
		"derivation" : "from H5203 (נָטַשׁ)",
		"def" : "a tendril (as an offshoot)",
		"kjv" : "battlement, branch, plant"
	},
	"H5190" : {
		"lemma" : "נָטַל",
		"xlit" : "nâṭal",
		"pron" : "naw-tal'",
		"derivation" : "a primitive root",
		"def" : "to lift; by implication, to impose",
		"kjv" : "bear, offer, take up"
	},
	"H5191" : {
		"lemma" : "נְטַל",
		"xlit" : "nᵉṭal",
		"pron" : "net-al'",
		"derivation" : "(Aramaic) corresponding to H5190 (נָטַל)",
		"def" : "to raise",
		"kjv" : "take up"
	},
	"H5192" : {
		"lemma" : "נֵטֶל",
		"xlit" : "nêṭel",
		"pron" : "nay'-tel",
		"derivation" : "; from H5190 (נָטַל)",
		"def" : "a burden",
		"kjv" : "weighty"
	},
	"H5193" : {
		"lemma" : "נָטַע",
		"xlit" : "nâṭaʻ",
		"pron" : "naw-tah'",
		"derivation" : "a primitive root",
		"def" : "properly, to strike in, i.e. fix; specifically, to plant (literally or figuratively)",
		"kjv" : "fastened, plant(-er)"
	},
	"H5194" : {
		"lemma" : "נֶטַע",
		"xlit" : "neṭaʻ",
		"pron" : "neh'-tah",
		"derivation" : "from H5193 (נָטַע)",
		"def" : "a plant; collectively, a plantation; abstractly, a planting",
		"kjv" : "plant"
	},
	"H5195" : {
		"lemma" : "נָטִיעַ",
		"xlit" : "nâṭîyaʻ",
		"pron" : "naw-tee'-ah",
		"derivation" : "from H5193 (נָטַע)",
		"def" : "a plant",
		"kjv" : "plant"
	},
	"H5196" : {
		"lemma" : "נְטָעִים",
		"xlit" : "Nᵉṭâʻîym",
		"pron" : "net-aw-eem'",
		"derivation" : "plural of H5194 (נֶטַע)",
		"def" : "Netaim, a place in Palestine",
		"kjv" : "plants"
	},
	"H5197" : {
		"lemma" : "נָטַף",
		"xlit" : "nâṭaph",
		"pron" : "naw-taf'",
		"derivation" : "a primitive root",
		"def" : "to ooze, i.e. distil gradually; by implication, to fall in drops; figuratively, to speak by inspiration",
		"kjv" : "drop(-ping), prophesy(-et)"
	},
	"H5198" : {
		"lemma" : "נָטָף",
		"xlit" : "nâṭâph",
		"pron" : "naw-tawf'",
		"derivation" : "from H5197 (נָטַף)",
		"def" : "a drop; specifically, an aromatic gum (probably stacte)",
		"kjv" : "drop, stacte"
	},
	"H5199" : {
		"lemma" : "נְטֹפָה",
		"xlit" : "Nᵉṭôphâh",
		"pron" : "net-o-faw'",
		"derivation" : "from H5197 (נָטַף); distillation",
		"def" : "Netophah, a place in Palestine",
		"kjv" : "Netophah"
	},
	"H5200" : {
		"lemma" : "נְטֹפָתִי",
		"xlit" : "Nᵉṭôphâthîy",
		"pron" : "net-o-faw-thee'",
		"derivation" : "patronymic from H5199 (נְטֹפָה)",
		"def" : "a Netophathite, or inhabitant of Netophah",
		"kjv" : "Netophathite"
	},
	"H5201" : {
		"lemma" : "נָטַר",
		"xlit" : "nâṭar",
		"pron" : "naw-tar'",
		"derivation" : "a primitive root",
		"def" : "to guard; figuratively, to cherish (anger)",
		"kjv" : "bear grudge, keep(-er), reserve"
	},
	"H5202" : {
		"lemma" : "נְטַר",
		"xlit" : "nᵉṭar",
		"pron" : "net-ar'",
		"derivation" : "(Aramaic) corresponding to H5201 (נָטַר)",
		"def" : "to retain",
		"kjv" : "keep"
	},
	"H5203" : {
		"lemma" : "נָטַשׁ",
		"xlit" : "nâṭash",
		"pron" : "naw-tash'",
		"derivation" : "a primitive root",
		"def" : "properly, to pound, i.e. smite; by implication (as if beating out, and thus expanding) to disperse; also, to thrust off, down, out or upon (inclusively, reject, let alone, permit, remit, etc.)",
		"kjv" : "cast off, drawn, let fall, forsake, join (battle), leave (off), lie still, loose, spread (self) abroad, stretch out, suffer"
	},
	"H5204" : {
		"lemma" : "נִי",
		"xlit" : "nîy",
		"pron" : "nee",
		"derivation" : "a doubtful word; apparently from H5091 (נָהָה)",
		"def" : "lamentation",
		"kjv" : "wailing"
	},
	"H5205" : {
		"lemma" : "נִיד",
		"xlit" : "nîyd",
		"pron" : "need",
		"derivation" : "from H5110 (נוּד)",
		"def" : "motion (of the lips in speech)",
		"kjv" : "moving"
	},
	"H5206" : {
		"lemma" : "נִידָה",
		"xlit" : "nîydâh",
		"pron" : "nee-daw'",
		"derivation" : "feminine of H5205 (נִיד)",
		"def" : "removal, i.e. exile",
		"kjv" : "removed"
	},
	"H5207" : {
		"lemma" : "נִיחוֹחַ",
		"xlit" : "nîychôwach",
		"pron" : "nee-kho'-akh",
		"derivation" : "or נִיחֹחַ; from H5117 (נוּחַ)",
		"def" : "properly, restful, i.e. pleasant; abstractly, delight",
		"kjv" : "sweet (odour)"
	},
	"H5208" : {
		"lemma" : "נִיחוֹחַ",
		"xlit" : "nîychôwach",
		"pron" : "nee-kho'-akh",
		"derivation" : "(Aramaic) or נִיחֹחַ; (shorter) (Aramaic), corresponding to H5207 (נִיחוֹחַ)",
		"def" : "pleasure",
		"kjv" : "sweet odour (savour)"
	},
	"H5209" : {
		"lemma" : "נִין",
		"xlit" : "nîyn",
		"pron" : "neen",
		"derivation" : "from H5125 (נוּן)",
		"def" : "progeny",
		"kjv" : "son"
	},
	"H5210" : {
		"lemma" : "נִינְוֵה",
		"xlit" : "Nîynᵉvêh",
		"pron" : "nee-nev-ay'",
		"derivation" : "of foreign origin",
		"def" : "Nineveh, the capital of Assyria",
		"kjv" : "Nineveh"
	},
	"H5211" : {
		"lemma" : "נִיס",
		"xlit" : "nîyç",
		"pron" : "neece",
		"derivation" : "from H5127 (נוּס)",
		"def" : "fugitive",
		"kjv" : "that fleeth"
	},
	"H5212" : {
		"lemma" : "נִיסָן",
		"xlit" : "Nîyçân",
		"pron" : "nee-sawn'",
		"derivation" : "probably of foreign origin",
		"def" : "Nisan, the first month of the Jewish sacred year",
		"kjv" : "Nisan"
	},
	"H5213" : {
		"lemma" : "נִיצוֹץ",
		"xlit" : "nîytsôwts",
		"pron" : "nee-tsotes'",
		"derivation" : "from H5340 (נָצַץ)",
		"def" : "a spark",
		"kjv" : "spark"
	},
	"H5214" : {
		"lemma" : "נִיר",
		"xlit" : "nîyr",
		"pron" : "neer",
		"derivation" : "a root probably identical with that of H5216 (נִיר), through the idea of the gleam of a fresh furrow",
		"def" : "to till the soil",
		"kjv" : "break up"
	},
	"H5215" : {
		"lemma" : "נִיר",
		"xlit" : "nîyr",
		"pron" : "neer",
		"derivation" : "or נִר; from H5214 (נִיר)",
		"def" : "properly, plowing, i.e. (concretely) freshly plowed land",
		"kjv" : "fallow ground, plowing, tillage"
	},
	"H5216" : {
		"lemma" : "נִיר",
		"xlit" : "nîyr",
		"pron" : "neer",
		"derivation" : "or נִר; also נֵיר; or נֵר; or (feminine) נֵרָה; from a primitive root (see H5214 (נִיר); H5135 (נוּר)) properly, meaning to glisten",
		"def" : "a lamp (i.e. the burner) or light (literally or figuratively)",
		"kjv" : "candle, lamp, light"
	},
	"H5217" : {
		"lemma" : "נָכָא",
		"xlit" : "nâkâʼ",
		"pron" : "naw-kaw'",
		"derivation" : "a primitive root",
		"def" : "to smite, i.e. drive away",
		"kjv" : "be viler"
	},
	"H5218" : {
		"lemma" : "נָכֵא",
		"xlit" : "nâkêʼ",
		"pron" : "naw-kay'",
		"derivation" : "or נָכָא; from H5217 (נָכָא)",
		"def" : "smitten, i.e. (figuratively) afflicted",
		"kjv" : "broken, stricken, wounded"
	},
	"H5219" : {
		"lemma" : "נְכֹאת",
		"xlit" : "nᵉkôʼth",
		"pron" : "nek-ohth'",
		"derivation" : "from H5218 (נָכֵא)",
		"def" : "properly, a smiting, i.e. (concretely) an aromatic gum (perhaps styrax) (as powdered)",
		"kjv" : "spicery(-ces)"
	},
	"H5220" : {
		"lemma" : "נֶכֶד",
		"xlit" : "neked",
		"pron" : "neh'-ked",
		"derivation" : "from an unused root meaning to propagate",
		"def" : "offspring",
		"kjv" : "nephew, son's son"
	},
	"H5221" : {
		"lemma" : "נָכָה",
		"xlit" : "nâkâh",
		"pron" : "naw-kaw'",
		"derivation" : "a primitive root",
		"def" : "to strike (lightly or severely, literally or figuratively)",
		"kjv" : "beat, cast forth, clap, give (wounds), [idiom] go forward, [idiom] indeed, kill, make (slaughter), murderer, punish, slaughter, slay(-er, -ing), smite(-r, -ing), strike, be stricken, (give) stripes, [idiom] surely, wound"
	},
	"H5222" : {
		"lemma" : "נֵכֶה",
		"xlit" : "nêkeh",
		"pron" : "nay-keh'",
		"derivation" : "from H5221 (נָכָה)",
		"def" : "a smiter, i.e. (figuratively) traducer",
		"kjv" : "abject"
	},
	"H5223" : {
		"lemma" : "נָכֶה",
		"xlit" : "nâkeh",
		"pron" : "naw-keh'",
		"derivation" : "",
		"def" : "smitten, i.e. (literally) maimed, or (figuratively) dejected",
		"kjv" : "contrite, lame"
	},
	"H5224" : {
		"lemma" : "נְכוֹ",
		"xlit" : "Nᵉkôw",
		"pron" : "nek-o'",
		"derivation" : "probably of Egyptian origin",
		"def" : "Neko, an Egyptian king",
		"kjv" : "Necho. Compare H6549 (פַּרְעֹה נְכֹה)"
	},
	"H5225" : {
		"lemma" : "נָכוֹן",
		"xlit" : "Nâkôwn",
		"pron" : "naw-kone'",
		"derivation" : "from H3559 (כּוּן); prepared",
		"def" : "Nakon, probably an Israelite",
		"kjv" : "Nachon"
	},
	"H5226" : {
		"lemma" : "נֵכַח",
		"xlit" : "nêkach",
		"pron" : "nay'-kakh",
		"derivation" : "from an unused root meaning to be straightforward",
		"def" : "properly, the fore part; used adverbially, opposite",
		"kjv" : "before, over against"
	},
	"H5227" : {
		"lemma" : "נֹכַח",
		"xlit" : "nôkach",
		"pron" : "no'-kakh",
		"derivation" : "from the same as H5226 (נֵכַח)",
		"def" : "properly, the front part; used adverbially (especially with preposition), opposite, in front of, forward, in behalf of",
		"kjv" : "(over) against, before, direct(-ly), for, right (on)"
	},
	"H5228" : {
		"lemma" : "נָכֹחַ",
		"xlit" : "nâkôach",
		"pron" : "naw-ko'-akh",
		"derivation" : "from the same as H5226 (נֵכַח)",
		"def" : "straightforward, i.e. (figuratively), equitable, correct, or (abstractly), integrity",
		"kjv" : "plain, right, uprightness"
	},
	"H5229" : {
		"lemma" : "נְכֹחָה",
		"xlit" : "nᵉkôchâh",
		"pron" : "nek-o-khaw'",
		"derivation" : "feminine of H5228 (נָכֹחַ)",
		"def" : "properly, straightforwardness, i.e. (figuratively) integrity, or (concretely) a truth",
		"kjv" : "equity, right (thing), uprightness"
	},
	"H5230" : {
		"lemma" : "נָכַל",
		"xlit" : "nâkal",
		"pron" : "naw-kal'",
		"derivation" : "a primitive root",
		"def" : "to defraud, i.e. act treacherously",
		"kjv" : "beguile, conspire, deceiver, deal subtilly"
	},
	"H5231" : {
		"lemma" : "נֵכֶל",
		"xlit" : "nêkel",
		"pron" : "nay'-kel",
		"derivation" : "from H5230 (נָכַל)",
		"def" : "deceit",
		"kjv" : "wile"
	},
	"H5232" : {
		"lemma" : "נְכַס",
		"xlit" : "nᵉkaç",
		"pron" : "nek-as'",
		"derivation" : "(Aramaic) corresponding to H5233 (נֶכֶס)",
		"def" : "{treasure}",
		"kjv" : "goods"
	},
	"H5233" : {
		"lemma" : "נֶכֶס",
		"xlit" : "nekeç",
		"pron" : "neh'-kes",
		"derivation" : "from an unused root meaning to accumulate",
		"def" : "treasure",
		"kjv" : "riches, wealth"
	},
	"H5234" : {
		"lemma" : "נָכַר",
		"xlit" : "nâkar",
		"pron" : "naw-kar'",
		"derivation" : "a primitive root",
		"def" : "properly, to scrutinize, i.e. look intently at; hence (with recognition implied), to acknowledge, be acquainted with, care for, respect, revere, or (with suspicion implied), to disregard, ignore, be strange toward, reject, resign, dissimulate (as if ignorant or disowning)",
		"kjv" : "acknowledge, [idiom] could, deliver, discern, dissemble, estrange, feign self to be another, know, take knowledge (notice), perceive, regard, (have) respect, behave (make) self strange(-ly)"
	},
	"H5235" : {
		"lemma" : "נֶכֶר",
		"xlit" : "neker",
		"pron" : "neh'-ker",
		"derivation" : "or נֹכֶר; from H5234 (נָכַר)",
		"def" : "something strange, i.e. unexpected calamity",
		"kjv" : "strange"
	},
	"H5236" : {
		"lemma" : "נֵכָר",
		"xlit" : "nêkâr",
		"pron" : "nay-kawr'",
		"derivation" : "' from H5234 (נָכַר)",
		"def" : "foreign, or (concretely) a foreigner, or (abstractly) heathendom",
		"kjv" : "alien, strange ([phrase] -er)"
	},
	"H5237" : {
		"lemma" : "נׇכְרִי",
		"xlit" : "nokrîy",
		"pron" : "nok-ree'",
		"derivation" : "from H5235 (נֶכֶר) (second form)",
		"def" : "strange, in a variety of degrees and applications (foreign, non-relative, adulterous, different, wonderful)",
		"kjv" : "alien, foreigner, outlandish, strange(-r, woman)"
	},
	"H5238" : {
		"lemma" : "נְכֹת",
		"xlit" : "nᵉkôth",
		"pron" : "nek-oth'",
		"derivation" : "probably for H5219 (נְכֹאת)",
		"def" : "spicery, i.e. (generally) valuables",
		"kjv" : "precious things"
	},
	"H5239" : {
		"lemma" : "נָלָה",
		"xlit" : "nâlâh",
		"pron" : "naw-law'",
		"derivation" : "apparently a primitive root",
		"def" : "to complete",
		"kjv" : "make an end"
	},
	"H5240" : {
		"lemma" : "נְמִבְזֶה",
		"xlit" : "nᵉmibzeh",
		"pron" : "nem-ib-zeh'",
		"derivation" : "from H959 (בָּזָה)",
		"def" : "despised",
		"kjv" : "vile"
	},
	"H5241" : {
		"lemma" : "נְמוּאֵל",
		"xlit" : "Nᵉmûwʼêl",
		"pron" : "nem-oo-ale'",
		"derivation" : "apparently for H3223 (יְמוּאֵל)",
		"def" : "Nemuel, the name of two Israelites",
		"kjv" : "Nemuel"
	},
	"H5242" : {
		"lemma" : "נְמוּאֵלִי",
		"xlit" : "Nᵉmûwʼêlîy",
		"pron" : "nem-oo-ay-lee'",
		"derivation" : "from H5241 (נְמוּאֵל)",
		"def" : "a Nemuelite, or descendant of Nemuel",
		"kjv" : "Nemuelite"
	},
	"H5243" : {
		"lemma" : "נָמַל",
		"xlit" : "nâmal",
		"pron" : "naw-mal'",
		"derivation" : "a primitive root",
		"def" : "to become clipped or (specifically) circumcised",
		"kjv" : "(branch to) be cut down (off), circumcise"
	},
	"H5244" : {
		"lemma" : "נְמָלָה",
		"xlit" : "nᵉmâlâh",
		"pron" : "nem-aw-law'",
		"derivation" : "feminine from H5243 (נָמַל)",
		"def" : "an ant (probably from its almost bisected form)",
		"kjv" : "ant"
	},
	"H5245" : {
		"lemma" : "נְמַר",
		"xlit" : "nᵉmar",
		"pron" : "nem-ar'",
		"derivation" : "(Aramaic) corresponding to H5246 (נָמֵר)",
		"def" : "{a leopard (from its stripes)}",
		"kjv" : "leopard"
	},
	"H5246" : {
		"lemma" : "נָמֵר",
		"xlit" : "nâmêr",
		"pron" : "naw-mare'",
		"derivation" : "from an unused root meaning properly, to filtrate, i.e. be limpid (comp H5247 (נִמְרָה) and H5249 (נִמְרִים)); and thus to spot or stain as if by dripping",
		"def" : "a leopard (from its stripes)",
		"kjv" : "leopard"
	},
	"H5247" : {
		"lemma" : "נִמְרָה",
		"xlit" : "Nimrâh",
		"pron" : "nim-raw'",
		"derivation" : "from the same as H5246 (נָמֵר); clear water",
		"def" : "Nimrah, a place East of the Jordan",
		"kjv" : "Nimrah. See also H1039 (בֵּית נִמְרָה), H5249 (נִמְרִים)"
	},
	"H5248" : {
		"lemma" : "נִמְרוֹד",
		"xlit" : "Nimrôwd",
		"pron" : "nim-rode'",
		"derivation" : "or נִמְרֹד; probably of foreign origin",
		"def" : "Nimrod, a son of Cush",
		"kjv" : "Nimrod"
	},
	"H5249" : {
		"lemma" : "נִמְרִים",
		"xlit" : "Nimrîym",
		"pron" : "nim-reem'",
		"derivation" : "plural of a masculine corresponding to H5247 (נִמְרָה); clear waters",
		"def" : "Nimrim, a place East of the Jordan",
		"kjv" : "Nimrim. Compare H1039 (בֵּית נִמְרָה)"
	},
	"H5250" : {
		"lemma" : "נִמְשִׁי",
		"xlit" : "Nimshîy",
		"pron" : "nim-shee'",
		"derivation" : "probably from H4871 (מָשָׁה); extricated",
		"def" : "Nimshi, the (grand-) father of Jehu",
		"kjv" : "Nimshi"
	},
	"H5251" : {
		"lemma" : "נֵס",
		"xlit" : "nêç",
		"pron" : "nace",
		"derivation" : "from H5264 (נָסַס)",
		"def" : "a flag; also a sail; by implication, a flagstaff; generally a signal; figuratively, a token",
		"kjv" : "banner, pole, sail, (en-) sign, standard"
	},
	"H5252" : {
		"lemma" : "נְסִבָּה",
		"xlit" : "nᵉçibbâh",
		"pron" : "nes-ib-baw'",
		"derivation" : "feminine participle passive of H5437 (סָבַב)",
		"def" : "properly, an environment, i.e. circumstance or turn of affairs",
		"kjv" : "cause"
	},
	"H5253" : {
		"lemma" : "נָסַג",
		"xlit" : "nâçag",
		"pron" : "naw-sag'",
		"derivation" : "a primitive root",
		"def" : "to retreat",
		"kjv" : "departing away, remove, take (hold), turn away"
	},
	"H5254" : {
		"lemma" : "נָסָה",
		"xlit" : "nâçâh",
		"pron" : "naw-saw'",
		"derivation" : "a primitive root",
		"def" : "to test; by implication, to attempt",
		"kjv" : "adventure, assay, prove, tempt, try"
	},
	"H5255" : {
		"lemma" : "נָסַח",
		"xlit" : "nâçach",
		"pron" : "naw-sakh'",
		"derivation" : "a primitive root",
		"def" : "to tear away",
		"kjv" : "destroy, pluck, root"
	},
	"H5256" : {
		"lemma" : "נְסַח",
		"xlit" : "nᵉçach",
		"pron" : "nes-akh'",
		"derivation" : "(Aramaic) corresponding to H5255 (נָסַח)",
		"def" : "{to tear away}",
		"kjv" : "pull down"
	},
	"H5257" : {
		"lemma" : "נְסִיךְ",
		"xlit" : "nᵉçîyk",
		"pron" : "nes-eek'",
		"derivation" : "from H5258 (נָסַךְ)",
		"def" : "properly, something poured out, i.e. a libation; also a molten image; by implication, a prince (as anointed)",
		"kjv" : "drink offering, duke, prince(-ipal)"
	},
	"H5258" : {
		"lemma" : "נָסַךְ",
		"xlit" : "nâçak",
		"pron" : "naw-sak'",
		"derivation" : "a primitive root",
		"def" : "to pour out, especially a libation, or to cast (metal); by analogy, to anoint aking",
		"kjv" : "cover, melt, offer, (cause to) pour (out), set (up)"
	},
	"H5259" : {
		"lemma" : "נָסַךְ",
		"xlit" : "nâçak",
		"pron" : "naw-sak'",
		"derivation" : "a primitive root (probably identical with H5258 (נָסַךְ) through the idea of fusion)",
		"def" : "to interweave, i.e. (figuratively) to overspread",
		"kjv" : "that is spread"
	},
	"H5260" : {
		"lemma" : "נְסַךְ",
		"xlit" : "nᵉçak",
		"pron" : "nes-ak'",
		"derivation" : "(Aramaic) corresponding to H5258 (נָסַךְ)",
		"def" : "to pour out a libation",
		"kjv" : "offer"
	},
	"H5261" : {
		"lemma" : "נְסַךְ",
		"xlit" : "nᵉçak",
		"pron" : "nes-ak'",
		"derivation" : "(Aramaic) corresponding to H5262 (נֶסֶךְ)",
		"def" : "a libation",
		"kjv" : "drink offering"
	},
	"H5262" : {
		"lemma" : "נֶסֶךְ",
		"xlit" : "neçek",
		"pron" : "neh'-sek",
		"derivation" : "or נֵסֶךְ; from H5258 (נָסַךְ)",
		"def" : "a libation; also a cast idol",
		"kjv" : "cover, drink offering, molten image"
	},
	"H5263" : {
		"lemma" : "נָסַס",
		"xlit" : "nâçaç",
		"pron" : "naw-sas'",
		"derivation" : "a primitive root",
		"def" : "to wane, i.e. be sick",
		"kjv" : "faint"
	},
	"H5264" : {
		"lemma" : "נָסַס",
		"xlit" : "nâçaç",
		"pron" : "naw-sas'",
		"derivation" : "a primitive root; to gleam from afar, i.e. to be conspicuous as a signal; or rather perhaps a denominative from H5251 (נֵס) (and identical with H5263 (נָסַס), through the idea of a flag as fluttering in the wind)",
		"def" : "to raise a beacon",
		"kjv" : "lift up as an ensign"
	},
	"H5265" : {
		"lemma" : "נָסַע",
		"xlit" : "nâçaʻ",
		"pron" : "naw-sah'",
		"derivation" : "a primitive root",
		"def" : "properly, to pull up, especially the tent-pins, i.e. start on ajourney",
		"kjv" : "cause to blow, bring, get, (make to) go (away, forth, forward, onward, out), (take) journey, march, remove, set aside (forward), [idiom] still, be on his (go their) way"
	},
	"H5266" : {
		"lemma" : "נָסַק",
		"xlit" : "nâçaq",
		"pron" : "naw-sak'",
		"derivation" : "a primitive root",
		"def" : "to go up",
		"kjv" : "ascend"
	},
	"H5267" : {
		"lemma" : "נְסַק",
		"xlit" : "nᵉçaq",
		"pron" : "nes-ak'",
		"derivation" : "(Aramaic) corresponding to H5266 (נָסַק)",
		"def" : "{to go up}",
		"kjv" : "take up"
	},
	"H5268" : {
		"lemma" : "נִסְרֹךְ",
		"xlit" : "Niçrôk",
		"pron" : "nis-roke'",
		"derivation" : "of foreign origin",
		"def" : "Nisrok, a Babylonian idol",
		"kjv" : "Nisroch"
	},
	"H5269" : {
		"lemma" : "נֵעָה",
		"xlit" : "Nêʻâh",
		"pron" : "nay-aw'",
		"derivation" : "from H5128 (נוּעַ); motion",
		"def" : "Neah, a place in Palestine",
		"kjv" : "Neah"
	},
	"H5270" : {
		"lemma" : "נֹעָה",
		"xlit" : "Nôʻâh",
		"pron" : "no-aw'",
		"derivation" : "from H5128 (נוּעַ); movement",
		"def" : "Noah, an Israelitess",
		"kjv" : "Noah"
	},
	"H5271" : {
		"lemma" : "נָעוּר",
		"xlit" : "nâʻûwr",
		"pron" : "naw-oor'",
		"derivation" : "or נָעֻר; and (feminine) נְעֻרָה; properly, passive participle from H5288 (נַעַר) as denominative",
		"def" : "(only in plural collectively or emphatic form) youth, the state (juvenility) or the persons (young people)",
		"kjv" : "childhood, youth"
	},
	"H5272" : {
		"lemma" : "נְעִיאֵל",
		"xlit" : "Nᵉʻîyʼêl",
		"pron" : "neh-ee-ale'",
		"derivation" : "from H5128 (נוּעַ) and H410 (אֵל); moved of God",
		"def" : "Neiel, a place in Palestine",
		"kjv" : "Neiel"
	},
	"H5273" : {
		"lemma" : "נָעִים",
		"xlit" : "nâʻîym",
		"pron" : "naw-eem'",
		"derivation" : "from H5276 (נָעֵם)",
		"def" : "delightful (objective or subjective, literal or figurative)",
		"kjv" : "pleasant(-ure), sweet"
	},
	"H5274" : {
		"lemma" : "נָעַל",
		"xlit" : "nâʻal",
		"pron" : "naw-al'",
		"derivation" : "a primitive root; also denominative from H5275 (נַעַל)",
		"def" : "properly, to fasten up, i.e. with abar or cord; hence, to sandal, i.e. furnish with slippers",
		"kjv" : "bolt, inclose, lock, shoe, shut up"
	},
	"H5275" : {
		"lemma" : "נַעַל",
		"xlit" : "naʻal",
		"pron" : "nah'-al",
		"derivation" : "or (feminine) נַעֲלָה; from H5274 (נָעַל)",
		"def" : "properly, a sandal tongue; by extension a sandal or slipper (sometimes as a symbol of occupancy, a refusal to marry, or of something valueless)",
		"kjv" : "dryshod, (pair of) shoe((-latchet), -s)"
	},
	"H5276" : {
		"lemma" : "נָעֵם",
		"xlit" : "nâʻêm",
		"pron" : "naw-ame'",
		"derivation" : "a primitive root",
		"def" : "to be agreeable (literally or figuratively)",
		"kjv" : "pass in beauty, be delight, be pleasant, be sweet"
	},
	"H5277" : {
		"lemma" : "נַעַם",
		"xlit" : "Naʻam",
		"pron" : "nah'-am",
		"derivation" : "from H5276 (נָעֵם); pleasure",
		"def" : "Naam, an Israelite",
		"kjv" : "Naam"
	},
	"H5278" : {
		"lemma" : "נֹעַם",
		"xlit" : "nôʻam",
		"pron" : "no'-am",
		"derivation" : "from H5276 (נָעֵם)",
		"def" : "agreeableness, i.e. delight, suitableness, splendor or grace",
		"kjv" : "beauty, pleasant(-ness)"
	},
	"H5279" : {
		"lemma" : "נַעֲמָה",
		"xlit" : "Naʻămâh",
		"pron" : "nah-am-aw'",
		"derivation" : "feminine of H5277 (נַעַם); pleasantness",
		"def" : "Naamah, the name of an antediluvian woman, of an Ammonitess, and of a place in Palestine",
		"kjv" : "Naamah"
	},
	"H5280" : {
		"lemma" : "נַעֲמִי",
		"xlit" : "Naʻămîy",
		"pron" : "nah-am-ee'",
		"derivation" : "patronymic from H5283 (נַעֲמָן)",
		"def" : "a Naamanite, or descendant of Naaman (collectively)",
		"kjv" : "Naamites"
	},
	"H5281" : {
		"lemma" : "נׇעֳמִי",
		"xlit" : "Noʻŏmîy",
		"pron" : "no-om-ee'",
		"derivation" : "from H5278 (נֹעַם); pleasant",
		"def" : "Noomi, an Israelitess",
		"kjv" : "Naomi"
	},
	"H5282" : {
		"lemma" : "נַעֲמָן",
		"xlit" : "naʻămân",
		"pron" : "nah-am-awn'",
		"derivation" : "from H5276 (נָעֵם)",
		"def" : "pleasantness (plural as concrete)",
		"kjv" : "pleasant"
	},
	"H5283" : {
		"lemma" : "נַעֲמָן",
		"xlit" : "Naʻămân",
		"pron" : "nah-am-awn'",
		"derivation" : "the same as H5282 (נַעֲמָן)",
		"def" : "Naaman, the name of an Israelite and of a Damascene",
		"kjv" : "Naaman"
	},
	"H5284" : {
		"lemma" : "נַעֲמָתִי",
		"xlit" : "Naʻămâthîy",
		"pron" : "nah-am-aw-thee'",
		"derivation" : "patrial from a place corresponding in name (but not identical) with H5279 (נַעֲמָה)",
		"def" : "a Naamathite, or inhabitant of Naamah",
		"kjv" : "Naamathite"
	},
	"H5285" : {
		"lemma" : "נַעֲצוּץ",
		"xlit" : "naʻătsûwts",
		"pron" : "nah-ats-oots'",
		"derivation" : "from an unused root meaning to prick",
		"def" : "probably a brier; by implication, a thicket of thorny bushes",
		"kjv" : "thorn"
	},
	"H5286" : {
		"lemma" : "נָעַר",
		"xlit" : "nâʻar",
		"pron" : "naw-ar'",
		"derivation" : "a primitive root",
		"def" : "to growl",
		"kjv" : "yell"
	},
	"H5287" : {
		"lemma" : "נָעַר",
		"xlit" : "nâʻar",
		"pron" : "naw-ar'",
		"derivation" : "a primitive root (probably identical with H5286 (נָעַר), through the idea of the rustling of mane, which usually accompanies the lion's roar)",
		"def" : "to tumble about",
		"kjv" : "shake (off, out, self), overthrow, toss up and down"
	},
	"H5288" : {
		"lemma" : "נַעַר",
		"xlit" : "naʻar",
		"pron" : "nah'-ar",
		"derivation" : "from H5287 (נָעַר)",
		"def" : "(concretely) a boy (as active), from the age of infancy to adolescence; by implication, a servant; also (by interch. of sex), a girl (of similar latitude in age)",
		"kjv" : "babe, boy, child, damsel (from the margin), lad, servant, young (man)"
	},
	"H5289" : {
		"lemma" : "נַעַר",
		"xlit" : "naʻar",
		"pron" : "nah'-ar",
		"derivation" : "from H5287 (נָעַר) in its derivative sense of tossing about",
		"def" : "a wanderer",
		"kjv" : "young one"
	},
	"H5290" : {
		"lemma" : "נֹעַר",
		"xlit" : "nôʻar",
		"pron" : "no'-ar",
		"derivation" : "from H5287 (נָעַר); (compare H5288 (נַעַר))",
		"def" : "(abstractly) boyhood",
		"kjv" : "child, youth"
	},
	"H5291" : {
		"lemma" : "נַעֲרָה",
		"xlit" : "naʻărâh",
		"pron" : "nah-ar-aw'",
		"derivation" : "feminine of H5288 (נַעַר)",
		"def" : "a girl (from infancy to adolescence)",
		"kjv" : "damsel, maid(-en), young (woman)"
	},
	"H5292" : {
		"lemma" : "נַעֲרָה",
		"xlit" : "Naʻărâh",
		"pron" : "nah-ar-aw'",
		"derivation" : "the same as H5291 (נַעֲרָה)",
		"def" : "Naarah, the name of an Israelitess, and of a place in Palestine",
		"kjv" : "Naarah, Naarath"
	},
	"H5293" : {
		"lemma" : "נַעֲרַי",
		"xlit" : "Naʻăray",
		"pron" : "nah-ar-ah'-ee",
		"derivation" : "from H5288 (נַעַר); youthful",
		"def" : "Naarai, an Israelite",
		"kjv" : "Naarai"
	},
	"H5294" : {
		"lemma" : "נְעַרְיָה",
		"xlit" : "Nᵉʻaryâh",
		"pron" : "neh-ar-yaw'",
		"derivation" : "from H5288 (נַעַר) and H3050 (יָהּ); servant of Jah",
		"def" : "Nearjah, the name of two Israelites",
		"kjv" : "Neariah"
	},
	"H5295" : {
		"lemma" : "נַעֲרָן",
		"xlit" : "Naʻărân",
		"pron" : "nah-ar-awn'",
		"derivation" : "from H5288 (נַעַר); juvenile",
		"def" : "Naaran, a place in Palestine",
		"kjv" : "Naaran"
	},
	"H5296" : {
		"lemma" : "נְעֹרֶת",
		"xlit" : "nᵉʻôreth",
		"pron" : "neh-o'-reth",
		"derivation" : "from H5287 (נָעַר)",
		"def" : "something shaken out, i.e. tow (as the refuse of flax)",
		"kjv" : "tow"
	},
	"H5297" : {
		"lemma" : "נֹף",
		"xlit" : "Nôph",
		"pron" : "nofe",
		"derivation" : "a variation of H4644 (מֹף)",
		"def" : "Noph, the capital of Upper Egypt",
		"kjv" : "Noph"
	},
	"H5298" : {
		"lemma" : "נֶפֶג",
		"xlit" : "Nepheg",
		"pron" : "neh'-feg",
		"derivation" : "from an unused root probably meaning to spring forth; a sprout",
		"def" : "Nepheg, the name of two Israelites",
		"kjv" : "Nepheg"
	},
	"H5299" : {
		"lemma" : "נָפָה",
		"xlit" : "nâphâh",
		"pron" : "naw-faw'",
		"derivation" : "from H5130 (נוּף) in the sense of lifting",
		"def" : "a height; also a sieve",
		"kjv" : "border, coast, region, sieve"
	},
	"H5300" : {
		"lemma" : "נְפוּשְׁסִים",
		"xlit" : "Nᵉphûwshᵉçîym",
		"pron" : "nef-oo-shes-eem'",
		"derivation" : "for H5304 (נְפִיסִים)",
		"def" : "Nephushesim, a Temple-servant",
		"kjv" : "Nephisesim (from the margin)"
	},
	"H5301" : {
		"lemma" : "נָפַח",
		"xlit" : "nâphach",
		"pron" : "naw-fakh'",
		"derivation" : "a primitive root",
		"def" : "to puff, in various applications (literally, to inflate, blow hard, scatter, kindle, expire; figuratively, to disesteem)",
		"kjv" : "blow, breath, give up, cause to lose (life), seething, snuff"
	},
	"H5302" : {
		"lemma" : "נֹפַח",
		"xlit" : "Nôphach",
		"pron" : "no'-fakh",
		"derivation" : "from H5301 (נָפַח); a gust",
		"def" : "Nophach, a place in Moab",
		"kjv" : "Nophah"
	},
	"H5303" : {
		"lemma" : "נְפִיל",
		"xlit" : "nᵉphîyl",
		"pron" : "nef-eel'",
		"derivation" : "or נְפִל; from H5307 (נָפַל)",
		"def" : "properly, a feller, i.e. a bully or tyrant",
		"kjv" : "giant"
	},
	"H5304" : {
		"lemma" : "נְפִיסִים",
		"xlit" : "Nᵉphîyçîym",
		"pron" : "nef-ee-seem'",
		"derivation" : "plural from an unused root meaning to scatter; expansions",
		"def" : "Nephisim, a Temple-servant",
		"kjv" : "Nephusim (from the margin)"
	},
	"H5305" : {
		"lemma" : "נָפִישׁ",
		"xlit" : "Nâphîysh",
		"pron" : "naw-feesh'",
		"derivation" : "from H5314 (נָפַשׁ); refreshed",
		"def" : "Naphish, a son of Ishmael, and his posterity",
		"kjv" : "Naphish"
	},
	"H5306" : {
		"lemma" : "נֹפֶךְ",
		"xlit" : "nôphek",
		"pron" : "no'-fek",
		"derivation" : "from an unused root meaning to glisten",
		"def" : "shining; a gem, probably the garnet",
		"kjv" : "emerald"
	},
	"H5307" : {
		"lemma" : "נָפַל",
		"xlit" : "nâphal",
		"pron" : "naw-fal'",
		"derivation" : "a primitive root",
		"def" : "to fall, in a great variety of applications (intransitive or causative, literal or figurative)",
		"kjv" : "be accepted, cast (down, self, (lots), out), cease, die, divide (by lot), (let) fail, (cause to, let, make, ready to) fall (away, down, -en, -ing), fell(-ing), fugitive, have (inheritance), inferior, be judged (by mistake for H6419 (פָּלַל)), lay (along), (cause to) lie down, light (down), be ([idiom] hast) lost, lying, overthrow, overwhelm, perish, present(-ed, -ing), (make to) rot, slay, smite out, [idiom] surely, throw down"
	},
	"H5308" : {
		"lemma" : "נְפַל",
		"xlit" : "nᵉphal",
		"pron" : "nef-al'",
		"derivation" : "(Aramaic) corresponding to H5307 (נָפַל)",
		"def" : "{to fall, in a great variety of applications (intransitive or causative, literal or figurative)}",
		"kjv" : "fall (down), have occasion"
	},
	"H5309" : {
		"lemma" : "נֶפֶל",
		"xlit" : "nephel",
		"pron" : "neh'-fel",
		"derivation" : "or נֵפֶל; from H5307 (נָפַל)",
		"def" : "something fallen, i.e. an abortion",
		"kjv" : "untimely birth"
	},
	"H5310" : {
		"lemma" : "נָפַץ",
		"xlit" : "nâphats",
		"pron" : "naw-fats'",
		"derivation" : "a primitive root",
		"def" : "to dash to pieces, or scatter",
		"kjv" : "be beaten in sunder, break (in pieces), broken, dash (in pieces), cause to be discharged, dispersed, be overspread, scatter"
	},
	"H5311" : {
		"lemma" : "נֶפֶץ",
		"xlit" : "nephets",
		"pron" : "neh'-fets",
		"derivation" : "from H5310 (נָפַץ)",
		"def" : "a storm (as dispersing)",
		"kjv" : "scattering"
	},
	"H5312" : {
		"lemma" : "נְפַק",
		"xlit" : "nᵉphaq",
		"pron" : "nef-ak'",
		"derivation" : "(Aramaic) a primitive root",
		"def" : "to issue; causatively, to bring out",
		"kjv" : "come (go, take) forth (out)"
	},
	"H5313" : {
		"lemma" : "נִפְקָא",
		"xlit" : "niphqâʼ",
		"pron" : "nif-kaw'",
		"derivation" : "(Aramaic) from H5312 (נְפַק)",
		"def" : "an outgo, i.e. expense",
		"kjv" : "expense"
	},
	"H5314" : {
		"lemma" : "נָפַשׁ",
		"xlit" : "nâphash",
		"pron" : "naw-fash'",
		"derivation" : "a primitive root",
		"def" : "to breathe; passively, to be breathed upon , i.e. (figuratively) refreshed (as if by a current of air)",
		"kjv" : "(be) refresh selves (-ed)"
	},
	"H5315" : {
		"lemma" : "נֶפֶשׁ",
		"xlit" : "nephesh",
		"pron" : "neh'-fesh",
		"derivation" : "from H5314 (נָפַשׁ)",
		"def" : "properly, a breathing creature, i.e. animal of (abstractly) vitality; used very widely in a literal, accommodated or figurative sense (bodily or mental)",
		"kjv" : "any, appetite, beast, body, breath, creature, [idiom] dead(-ly), desire, [idiom] (dis-) contented, [idiom] fish, ghost, [phrase] greedy, he, heart(-y), (hath, [idiom] jeopardy of) life ([idiom] in jeopardy), lust, man, me, mind, mortally, one, own, person, pleasure, (her-, him-, my-, thy-) self, them (your) -selves, [phrase] slay, soul, [phrase] tablet, they, thing, ([idiom] she) will, [idiom] would have it"
	},
	"H5316" : {
		"lemma" : "נֶפֶת",
		"xlit" : "nepheth",
		"pron" : "neh'-feth",
		"derivation" : "for H5299 (נָפָה)",
		"def" : "a height",
		"kjv" : "country"
	},
	"H5317" : {
		"lemma" : "נֹפֶת",
		"xlit" : "nôpheth",
		"pron" : "no'-feth",
		"derivation" : "from H5130 (נוּף) in the sense of shaking to pieces",
		"def" : "a dripping i.e. of honey (from the comb)",
		"kjv" : "honeycomb"
	},
	"H5318" : {
		"lemma" : "נֶפְתּוֹחַ",
		"xlit" : "Nephtôwach",
		"pron" : "nef-to'-akh",
		"derivation" : "from H6605 (פָּתַח); opened, i.e. a spring",
		"def" : "Nephtoach, a place in Palestine",
		"kjv" : "Neptoah"
	},
	"H5319" : {
		"lemma" : "נַפְתּוּל",
		"xlit" : "naphtûwl",
		"pron" : "naf-tool'",
		"derivation" : "from H6617 (פָּתַל)",
		"def" : "properly, wrestled; but used (in the plural) transitively, a struggle",
		"kjv" : "wrestling"
	},
	"H5320" : {
		"lemma" : "נַפְתֻּחִים",
		"xlit" : "Naphtuchîym",
		"pron" : "naf-too-kheem",
		"derivation" : "plural of foreign origin",
		"def" : "Naphtuchim, an Egyptian tribe",
		"kjv" : "Naptuhim"
	},
	"H5321" : {
		"lemma" : "נַפְתָּלִי",
		"xlit" : "Naphtâlîy",
		"pron" : "naf-taw-lee'",
		"derivation" : "from H6617 (פָּתַל); my wrestling",
		"def" : "Naphtali, a son of Jacob, with the tribe descended from him, and its territory",
		"kjv" : "Naphtali"
	},
	"H5322" : {
		"lemma" : "נֵץ",
		"xlit" : "nêts",
		"pron" : "nayts",
		"derivation" : "from H5340 (נָצַץ)",
		"def" : "a flower (from its brilliancy); also a hawk (from it flashing speed)",
		"kjv" : "blossom, hawk"
	},
	"H5323" : {
		"lemma" : "נָצָא",
		"xlit" : "nâtsâʼ",
		"pron" : "naw-tsaw'",
		"derivation" : "a primitive root",
		"def" : "to go away",
		"kjv" : "flee"
	},
	"H5324" : {
		"lemma" : "נָצַב",
		"xlit" : "nâtsab",
		"pron" : "naw-tsab'",
		"derivation" : "a primitive root",
		"def" : "to station, in various applications (literally or figuratively)",
		"kjv" : "appointed, deputy, erect, establish, [idiom] Huzzah (by mistake for a proper name), lay, officer, pillar, present, rear up, set (over, up), settle, sharpen, establish, (make to) stand(-ing, still, up, upright), best state"
	},
	"H5325" : {
		"lemma" : "נִצָּב",
		"xlit" : "nitstsâb",
		"pron" : "nits-twawb'",
		"derivation" : "passive participle of H5324 (נָצַב)",
		"def" : "fixed, i.e. a handle",
		"kjv" : "haft"
	},
	"H5326" : {
		"lemma" : "נִצְבָּה",
		"xlit" : "nitsbâh",
		"pron" : "nits-baw'",
		"derivation" : "(Aramaic) from a root corresponding to H5324 (נָצַב)",
		"def" : "fixedness, i.e. firmness",
		"kjv" : "strength"
	},
	"H5327" : {
		"lemma" : "נָצָה",
		"xlit" : "nâtsâh",
		"pron" : "naw-tsaw'",
		"derivation" : "a primitive root",
		"def" : "properly, to go forth, i.e. (by implication) to be expelled, and (consequently) desolate; causatively, to lay waste; also (specifically), to quarrel",
		"kjv" : "be laid waste, runinous, strive (together)"
	},
	"H5328" : {
		"lemma" : "נִצָּה",
		"xlit" : "nitstsâh",
		"pron" : "nits-tsaw'",
		"derivation" : "feminine of H5322 (נֵץ)",
		"def" : "a blossom",
		"kjv" : "flower"
	},
	"H5329" : {
		"lemma" : "נָצַח",
		"xlit" : "nâtsach",
		"pron" : "naw-tsakh'",
		"derivation" : "a primitive root;  also as denominative from H5331 (נֶצַח)",
		"def" : "properly, to glitter from afar, i.e. to be eminent (as a superintendent, especially of the Temple services and its music); to be permanent",
		"kjv" : "excel, chief musician (singer), oversee(-r), set forward"
	},
	"H5330" : {
		"lemma" : "נְצַח",
		"xlit" : "nᵉtsach",
		"pron" : "nets-akh'",
		"derivation" : "(Aramaic) corresponding to H5329 (נָצַח)",
		"def" : "to become chief",
		"kjv" : "be preferred"
	},
	"H5331" : {
		"lemma" : "נֶצַח",
		"xlit" : "netsach",
		"pron" : "neh'-tsakh",
		"derivation" : "or נֵצַח; from H5329 (נָצַח)",
		"def" : "properly, a goal, i.e. the bright object at adistance travelled towards; hence (figuratively), splendor, or (subjectively) truthfulness, or (objectively) confidence; but usually (adverbially), continually (i.e. to the most distant point of view)",
		"kjv" : "alway(-s), constantly, end, ([phrase] n-) ever(more), perpetual, strength, victory"
	},
	"H5332" : {
		"lemma" : "נֵצַח",
		"xlit" : "Nêtsach",
		"pron" : "nay'-tsakh",
		"derivation" : "probably identical with H5331 (נֶצַח), through the idea of brilliancy of color",
		"def" : "juice of the grape (as blood red)",
		"kjv" : "blood, strength"
	},
	"H5333" : {
		"lemma" : "נְצִיב",
		"xlit" : "nᵉtsîyb",
		"pron" : "nets-eeb'",
		"derivation" : "or נְצִב; from H5324 (נָצַב)",
		"def" : "something stationary, i.e. a prefect, a military post, a statue",
		"kjv" : "garrison, officer, pillar"
	},
	"H5334" : {
		"lemma" : "נְצִיב",
		"xlit" : "Nᵉtsîyb",
		"pron" : "nets-eeb'",
		"derivation" : "the same as H5333 (נְצִיב); station",
		"def" : "Netsib, a place in Palestine",
		"kjv" : "Nezib"
	},
	"H5335" : {
		"lemma" : "נְצִיחַ",
		"xlit" : "nᵉtsîyach",
		"pron" : "nets-ee'-akh",
		"derivation" : "from H5329 (נָצַח); conspicuous",
		"def" : "Netsiach, a Temple-servant",
		"kjv" : "Neziah"
	},
	"H5336" : {
		"lemma" : "נָצִיר",
		"xlit" : "nâtsîyr",
		"pron" : "naw-tsere'",
		"derivation" : "from H5341 (נָצַר)",
		"def" : "properly, conservative; but used passively, delivered",
		"kjv" : "preserved"
	},
	"H5337" : {
		"lemma" : "נָצַל",
		"xlit" : "nâtsal",
		"pron" : "naw-tsal'",
		"derivation" : "a primitive root",
		"def" : "to snatch away, whether in a good or a bad sense",
		"kjv" : "[idiom] at all, defend, deliver (self), escape, [idiom] without fail, part, pluck, preserve, recover, rescue, rid, save, spoil, strip, [idiom] surely, take (out)"
	},
	"H5338" : {
		"lemma" : "נְצַל",
		"xlit" : "nᵉtsal",
		"pron" : "nets-al'",
		"derivation" : "(Aramaic) corresponding to H5337 (נָצַל)",
		"def" : "to extricate",
		"kjv" : "deliver, rescue"
	},
	"H5339" : {
		"lemma" : "נִצָּן",
		"xlit" : "nitstsân",
		"pron" : "nits-tsawn'",
		"derivation" : "from H5322 (נֵץ)",
		"def" : "a blossom",
		"kjv" : "flower"
	},
	"H5340" : {
		"lemma" : "נָצַץ",
		"xlit" : "nâtsats",
		"pron" : "naw-tsats'",
		"derivation" : "a primitive root",
		"def" : "to glare, i.e. be bright-colored",
		"kjv" : "sparkle"
	},
	"H5341" : {
		"lemma" : "נָצַר",
		"xlit" : "nâtsar",
		"pron" : "naw-tsar'",
		"derivation" : "a primitive root",
		"def" : "to guard, in a good sense (to protect, maintain, obey, etc.) or a bad one (to conceal, etc.)",
		"kjv" : "besieged, hidden thing, keep(-er, -ing), monument, observe, preserve(-r), subtil, watcher(-man)"
	},
	"H5342" : {
		"lemma" : "נֵצֶר",
		"xlit" : "nêtser",
		"pron" : "nay'-tser",
		"derivation" : "from H5341 (נָצַר) in the sense of greenness as a striking color",
		"def" : "a shoot; figuratively, a descendant",
		"kjv" : "branch"
	},
	"H5343" : {
		"lemma" : "נְקֵא",
		"xlit" : "nᵉqêʼ",
		"pron" : "nek-ay'",
		"derivation" : "(Aramaic) from a root corresponding to H5352 (נָקָה)",
		"def" : "clean",
		"kjv" : "pure"
	},
	"H5344" : {
		"lemma" : "נָקַב",
		"xlit" : "nâqab",
		"pron" : "naw-kab'",
		"derivation" : "a primitive root",
		"def" : "to puncture, literally (to perforate, with more or less violence) or figuratively (to specify, designate, libel)",
		"kjv" : "appoint, blaspheme, bore, curse, express, with holes, name, pierce, strike through"
	},
	"H5345" : {
		"lemma" : "נֶקֶב",
		"xlit" : "neqeb",
		"pron" : "neh'keb",
		"def" : "a bezel (for a gem)",
		"kjv" : "pipe"
	},
	"H5346" : {
		"lemma" : "נֶקֶב",
		"xlit" : "Neqeb",
		"pron" : "neh'-keb",
		"derivation" : "the same as H5345 (נֶקֶב); dell",
		"def" : "Nekeb, a place in Palestine",
		"kjv" : "Nekeb"
	},
	"H5347" : {
		"lemma" : "נְקֵבָה",
		"xlit" : "nᵉqêbâh",
		"pron" : "nek-ay-baw'",
		"derivation" : "from H5344 (נָקַב)",
		"def" : "female (from the sexual form)",
		"kjv" : "female"
	},
	"H5348" : {
		"lemma" : "נָקֹד",
		"xlit" : "nâqôd",
		"pron" : "naw-kode'",
		"derivation" : "from an unused root meaning to mark (by puncturing or branding)",
		"def" : "spotted",
		"kjv" : "speckled"
	},
	"H5349" : {
		"lemma" : "נֹקֵד",
		"xlit" : "nôqêd",
		"pron" : "no-kade'",
		"derivation" : "active participle from the same as H5348 (נָקֹד)",
		"def" : "a spotter (of sheep or cattle), i.e. the owner or tender (who thus marks them)",
		"kjv" : "herdman, sheepmaster"
	},
	"H5350" : {
		"lemma" : "נִקֻּד",
		"xlit" : "niqqud",
		"pron" : "nik-kood'",
		"derivation" : "from the same as H5348 (נָקֹד)",
		"def" : "a crumb (as broken to spots); also a biscuit (as pricked)",
		"kjv" : "cracknel, mouldy"
	},
	"H5351" : {
		"lemma" : "נְקֻדָּה",
		"xlit" : "nᵉquddâh",
		"pron" : "ned-ood-daw'",
		"derivation" : "feminine of H5348 (נָקֹד)",
		"def" : "a boss",
		"kjv" : "stud"
	},
	"H5352" : {
		"lemma" : "נָקָה",
		"xlit" : "nâqâh",
		"pron" : "naw-kaw'",
		"derivation" : "a primitive root",
		"def" : "to be (or make) clean (literally or figuratively); by implication (in an adverse sense) to be bare, i.e. extirpated",
		"kjv" : "acquit [idiom] at all, [idiom] altogether, be blameless, cleanse, (be) clear(-ing), cut off, be desolate, be free, be (hold) guiltless, be (hold) innocent, [idiom] by no means, be quit, be (leave) unpunished, [idiom] utterly, [idiom] wholly"
	},
	"H5353" : {
		"lemma" : "נְקוֹדָא",
		"xlit" : "Nᵉqôwdâʼ",
		"pron" : "nek-o-daw'",
		"derivation" : "feminine of H5348 (נָקֹד) (in the figurative sense of marked); distinction",
		"def" : "Nekoda, a Temple-servant",
		"kjv" : "Nekoda"
	},
	"H5354" : {
		"lemma" : "נָקַט",
		"xlit" : "nâqaṭ",
		"pron" : "naw-kat'",
		"derivation" : "a primitive root",
		"def" : "to loathe",
		"kjv" : "weary"
	},
	"H5355" : {
		"lemma" : "נָקִי",
		"xlit" : "nâqîy",
		"pron" : "naw-kee'",
		"derivation" : "or נָקִיא; (Joel 4:19; Jonah [1:14), from H5352 (נָקָה)",
		"def" : "innocent",
		"kjv" : "blameless, clean, clear, exempted, free, guiltless, innocent, quit"
	},
	"H5356" : {
		"lemma" : "נִקָּיוֹן",
		"xlit" : "niqqâyôwn",
		"pron" : "nik-kaw-yone'",
		"derivation" : "or נִקָּיֹן; from H5352 (נָקָה)",
		"def" : "clearness (literally or figuratively)",
		"kjv" : "cleanness, innocency"
	},
	"H5357" : {
		"lemma" : "נָקִיק",
		"xlit" : "nâqîyq",
		"pron" : "naw-keek'",
		"derivation" : "from an unused root meaning to bore",
		"def" : "a cleft",
		"kjv" : "hole"
	},
	"H5358" : {
		"lemma" : "נָקַם",
		"xlit" : "nâqam",
		"pron" : "naw-kam'",
		"derivation" : "a primitive root",
		"def" : "to grudge, i.e. avenge or punish",
		"kjv" : "avenge(-r, self), punish, revenge (self), [idiom] surely, take vengeance"
	},
	"H5359" : {
		"lemma" : "נָקָם",
		"xlit" : "nâqâm",
		"pron" : "naw-kawm'",
		"derivation" : "from H5358 (נָקַם)",
		"def" : "revenge",
		"kjv" : "[phrase] avenged, quarrel, vengeance"
	},
	"H5360" : {
		"lemma" : "נְקָמָה",
		"xlit" : "nᵉqâmâh",
		"pron" : "nek-aw-maw'",
		"derivation" : "feminine of H5359 (נָקָם)",
		"def" : "avengement, whether the act of the passion",
		"kjv" : "[phrase] avenge, revenge(-ing), vengeance"
	},
	"H5361" : {
		"lemma" : "נָקַע",
		"xlit" : "nâqaʻ",
		"pron" : "naw-kah'",
		"derivation" : "a primitive root",
		"def" : "to feel aversion",
		"kjv" : "be alienated"
	},
	"H5362" : {
		"lemma" : "נָקַף",
		"xlit" : "nâqaph",
		"pron" : "naw-kaf'",
		"derivation" : "a primitive root",
		"def" : "to strike with more or less violence (beat, fell, corrode); by implication (of attack) to knock together, i.e. surround or circulate",
		"kjv" : "compass (about, -ing), cut down, destroy, go round (about), inclose, round"
	},
	"H5363" : {
		"lemma" : "נֹקֶף",
		"xlit" : "nôqeph",
		"pron" : "no'-kef",
		"derivation" : "from H5362 (נָקַף)",
		"def" : "a threshing (of olives)",
		"kjv" : "shaking"
	},
	"H5364" : {
		"lemma" : "נִקְפָּה",
		"xlit" : "niqpâh",
		"pron" : "nik-paw'",
		"derivation" : "from H5362 (נָקַף)",
		"def" : "probably a rope (as encircling)",
		"kjv" : "rent"
	},
	"H5365" : {
		"lemma" : "נָקַר",
		"xlit" : "nâqar",
		"pron" : "naw-kar'",
		"derivation" : "a primitive root",
		"def" : "to bore (penetrate, quarry)",
		"kjv" : "dig, pick out, pierce, put (thrust) out"
	},
	"H5366" : {
		"lemma" : "נְקָרָה",
		"xlit" : "nᵉqârâh",
		"pron" : "nek-aw-raw'",
		"derivation" : "from H5365 (נָקַר), a fissure",
		"def" : "{to bore (penetrate, quarry)}",
		"kjv" : "cleft, clift"
	},
	"H5367" : {
		"lemma" : "נָקַשׁ",
		"xlit" : "nâqash",
		"pron" : "naw-kash'",
		"derivation" : "a primitive root",
		"def" : "to entrap (with a noose), literally or figuratively",
		"kjv" : "catch (lay a) snare"
	},
	"H5368" : {
		"lemma" : "נְקַשׁ",
		"xlit" : "nᵉqash",
		"pron" : "nek-ash'",
		"derivation" : "(Aramaic) corresponding to H5367 (נָקַשׁ); used in the sense of H5362 (נָקַף)",
		"def" : "to knock",
		"kjv" : "smote"
	},
	"H5369" : {
		"lemma" : "נֵר",
		"xlit" : "Nêr",
		"pron" : "nare",
		"derivation" : "the same as H5216 (נִיר); lamp",
		"def" : "Ner, an Israelite",
		"kjv" : "Ner"
	},
	"H5370" : {
		"lemma" : "נֵרְגַּל",
		"xlit" : "Nêrᵉgal",
		"pron" : "nare-gal'",
		"derivation" : "of foreign origin",
		"def" : "Nergal, a Cuthite deity",
		"kjv" : "Nergal"
	},
	"H5371" : {
		"lemma" : "נֵרְגַּל שַׁרְאֶצֶר",
		"xlit" : "Nêrᵉgal Sharʼetser",
		"pron" : "nare-gal' shar-eh'-tser",
		"derivation" : "from H5370 (נֵרְגַּל) and H8272 (שַׁרְאֶצֶר)",
		"def" : "Nergal-Sharetser, the name of two Babylonians",
		"kjv" : "Nergal-sharezer"
	},
	"H5372" : {
		"lemma" : "נִרְגָּן",
		"xlit" : "nirgân",
		"pron" : "neer-gawn'",
		"derivation" : "from an unused root meaning to roll to pieces",
		"def" : "a slanderer",
		"kjv" : "talebearer, whisperer"
	},
	"H5373" : {
		"lemma" : "נֵרְדְּ",
		"xlit" : "nêrd",
		"pron" : "nayrd",
		"derivation" : "of foreign origin",
		"def" : "nard, an aromatic",
		"kjv" : "spikenard"
	},
	"H5374" : {
		"lemma" : "נֵרִיָּה",
		"xlit" : "Nêrîyâh",
		"pron" : "nay-ree-yaw'",
		"derivation" : "or נֵרִיָּהוּ; from H5216 (נִיר) and H3050 (יָהּ); light of Jah",
		"def" : "Nerijah, an Israelite",
		"kjv" : "Neriah"
	},
	"H5375" : {
		"lemma" : "נָשָׂא",
		"xlit" : "nâsâʼ",
		"pron" : "naw-saw'",
		"derivation" : "or נָסָה; (Psalm 4:6 (H7 (אֲבַד))), a primitive root",
		"def" : "to lift, in a great variety of applications, literal and figurative, absolute and relative",
		"kjv" : "accept, advance, arise, (able to, (armor), suffer to) bear(-er, up), bring (forth), burn, carry (away), cast, contain, desire, ease, exact, exalt (self), extol, fetch, forgive, furnish, further, give, go on, help, high, hold up, honorable ([phrase] man), lade, lay, lift (self) up, lofty, marry, magnify, [idiom] needs, obtain, pardon, raise (up), receive, regard, respect, set (up), spare, stir up, [phrase] swear, take (away, up), [idiom] utterly, wear, yield"
	},
	"H5376" : {
		"lemma" : "נְשָׂא",
		"xlit" : "nᵉsâʼ",
		"pron" : "nes-aw'",
		"derivation" : "(Aramaic) corresponding to H5375 (נָשָׂא)",
		"def" : "{to lift}",
		"kjv" : "carry away, make insurrection, take"
	},
	"H5377" : {
		"lemma" : "נָשָׁא",
		"xlit" : "nâshâʼ",
		"pron" : "naw-shaw'",
		"derivation" : "a primitive root",
		"def" : "to lead astray, i.e. (mentally) to delude, or (morally) to seduce",
		"kjv" : "beguile, deceive, [idiom] greatly, [idiom] utterly"
	},
	"H5378" : {
		"lemma" : "נָשָׁא",
		"xlit" : "nâshâʼ",
		"pron" : "naw-shaw'",
		"derivation" : "a primitive root (perhaps identical with H5377 (נָשָׁא), through the idea of imposition)",
		"def" : "to lend on interest; by implication, to dun fordebt",
		"kjv" : "[idiom] debt, exact, giver of usury"
	},
	"H5379" : {
		"lemma" : "נִשֵּׂאת",
		"xlit" : "nissêʼth",
		"pron" : "nis-sayth'",
		"derivation" : "passive participle feminine of H5375 (נָשָׂא)",
		"def" : "something taken, i.e. a present",
		"kjv" : "gift"
	},
	"H5380" : {
		"lemma" : "נָשַׁב",
		"xlit" : "nâshab",
		"pron" : "naw-shab'",
		"derivation" : "a primitive root",
		"def" : "to blow; by implication, to disperse",
		"kjv" : "(cause to) blow, drive away"
	},
	"H5381" : {
		"lemma" : "נָשַׂג",
		"xlit" : "nâsag",
		"pron" : "naw-sag'",
		"derivation" : "a primitive root",
		"def" : "to reach (literally or figuratively)",
		"kjv" : "ability, be able, attain (unto), (be able to, can) get, lay at, put, reach, remove, wax rich, [idiom] surely, (over-) take (hold of, on, upon)"
	},
	"H5382" : {
		"lemma" : "נָשָׁה",
		"xlit" : "nâshâh",
		"pron" : "naw-shaw'",
		"derivation" : "a primitive root",
		"def" : "to forget; figuratively, to neglect; causatively, to remit, remove",
		"kjv" : "forget, deprive, exact"
	},
	"H5383" : {
		"lemma" : "נָשָׁה",
		"xlit" : "nâshâh",
		"pron" : "naw-shaw'",
		"derivation" : "a primitive root (rather identical with H5382 (נָשָׁה), in the sense of H5378 (נָשָׁא))",
		"def" : "to lend or (by reciprocity) borrow on security or interest",
		"kjv" : "creditor, exact, extortioner, lend, usurer, lend on (taker on) usury"
	},
	"H5384" : {
		"lemma" : "נָשֶׁה",
		"xlit" : "nâsheh",
		"pron" : "naw-sheh'",
		"derivation" : "from H5382 (נָשָׁה), in the sense of failure",
		"def" : "rheumatic or crippled (from the incident to Jacob)",
		"kjv" : "which shrank"
	},
	"H5385" : {
		"lemma" : "נְשׂוּאָה",
		"xlit" : "nᵉsûwʼâh",
		"pron" : "nes-oo-aw'",
		"derivation" : "or rather, נְשֻׂאָה; feminine. passive participle of H5375 (נָשָׂא)",
		"def" : "something borne, i.e. a load",
		"kjv" : "carriage"
	},
	"H5386" : {
		"lemma" : "נְשִׁי",
		"xlit" : "nᵉshîy",
		"pron" : "nesh-ee'",
		"derivation" : "from H5383 (נָשָׁה)",
		"def" : "a debt",
		"kjv" : "debt"
	},
	"H5387" : {
		"lemma" : "נָשִׂיא",
		"xlit" : "nâsîyʼ",
		"pron" : "naw-see'",
		"derivation" : "or נָשִׂא; from H5375 (נָשָׂא)",
		"def" : "properly, an exalted one, i.e. a king or sheik; also a rising mist",
		"kjv" : "captain, chief, cloud, governor, prince, ruler, vapour"
	},
	"H5388" : {
		"lemma" : "נְשִׁיָּה",
		"xlit" : "nᵉshîyâh",
		"pron" : "nesh-ee-yaw'",
		"derivation" : "from H5382 (נָשָׁה)",
		"def" : "oblivion",
		"kjv" : "forgetfulness"
	},
	"H5389" : {
		"lemma" : "נָשִׁין",
		"xlit" : "nâshîyn",
		"pron" : "naw-sheen'",
		"derivation" : "(Aramaic) irregular plural feminine of H606 (אֱנָשׁ)",
		"def" : "{a man}",
		"kjv" : "women"
	},
	"H5390" : {
		"lemma" : "נְשִׁיקָה",
		"xlit" : "nᵉshîyqâh",
		"pron" : "nesh-ee-kaw'",
		"derivation" : "from H5401 (נָשַׁק)",
		"def" : "a kiss",
		"kjv" : "kiss"
	},
	"H5391" : {
		"lemma" : "נָשַׁךְ",
		"xlit" : "nâshak",
		"pron" : "naw-shak'",
		"derivation" : "a primitive root",
		"def" : "to strike with a sting (as a serpent); figuratively, to oppress with interest on a loan",
		"kjv" : "bite, lend upon usury"
	},
	"H5392" : {
		"lemma" : "נֶשֶׁךְ",
		"xlit" : "neshek",
		"pron" : "neh'-shek",
		"derivation" : "from H5391 (נָשַׁךְ)",
		"def" : "interest on a debt",
		"kjv" : "usury"
	},
	"H5393" : {
		"lemma" : "נִשְׁכָּה",
		"xlit" : "nishkâh",
		"pron" : "nish-kaw'",
		"derivation" : "for H3957 (לִשְׁכָּה)",
		"def" : "a cell",
		"kjv" : "chamber"
	},
	"H5394" : {
		"lemma" : "נָשַׁל",
		"xlit" : "nâshal",
		"pron" : "naw-shal'",
		"derivation" : "a primitive root",
		"def" : "to pluck off, i.e. divest, eject or drop",
		"kjv" : "cast (out), drive, loose, put off (out), slip"
	},
	"H5395" : {
		"lemma" : "נָשַׁם",
		"xlit" : "nâsham",
		"pron" : "naw-sham'",
		"derivation" : "a primitive root",
		"def" : "properly, to blow away, i.e. destroy",
		"kjv" : "destroy"
	},
	"H5396" : {
		"lemma" : "נִשְׁמָא",
		"xlit" : "nishmâʼ",
		"pron" : "nish-maw'",
		"derivation" : "(Aramaic) corresponding to H5397 (נְשָׁמָה)",
		"def" : "vital breath",
		"kjv" : "breath"
	},
	"H5397" : {
		"lemma" : "נְשָׁמָה",
		"xlit" : "nᵉshâmâh",
		"pron" : "nesh-aw-maw'",
		"derivation" : "from H5395 (נָשַׁם)",
		"def" : "a puff, i.e. wind, angry or vital breath, divine inspiration, intellect. or (concretely) an animal",
		"kjv" : "blast, (that) breath(-eth), inspiration, soul, spirit"
	},
	"H5398" : {
		"lemma" : "נָשַׁף",
		"xlit" : "nâshaph",
		"pron" : "naw-shaf'",
		"derivation" : "a primitive root",
		"def" : "to breeze, i.e. blow up fresh (as the wind)",
		"kjv" : "blow"
	},
	"H5399" : {
		"lemma" : "נֶשֶׁף",
		"xlit" : "nesheph",
		"pron" : "neh'-shef",
		"derivation" : "from H5398 (נָשַׁף)",
		"def" : "properly, a breeze, i.e. (by implication) dusk (when the evening breeze prevails)",
		"kjv" : "dark, dawning of the day (morning), night, twilight"
	},
	"H5400" : {
		"lemma" : "נָשַׂק",
		"xlit" : "nâsaq",
		"pron" : "naw-sak'",
		"derivation" : "a primitive root",
		"def" : "to catch fire",
		"kjv" : "burn, kindle"
	},
	"H5401" : {
		"lemma" : "נָשַׁק",
		"xlit" : "nâshaq",
		"pron" : "naw-shak'",
		"derivation" : "a primitive root (identical with H5400 (נָשַׂק), through the idea of fastening up; compare H2388 (חָזַק), H2836 (חָשַׁק))",
		"def" : "to kiss, literally or figuratively (touch); also (as a mode of attachment), to equip with weapons",
		"kjv" : "armed (men), rule, kiss, that touched"
	},
	"H5402" : {
		"lemma" : "נֶשֶׁק",
		"xlit" : "nesheq",
		"pron" : "neh'-shek",
		"derivation" : "or נֵשֶׁק; from H5401 (נָשַׁק)",
		"def" : "military equipment, i.e. (collectively) arms (offensive or defensive), or (concretely) an arsenal",
		"kjv" : "armed men, armour(-y), battle, harness, weapon"
	},
	"H5403" : {
		"lemma" : "נְשַׁר",
		"xlit" : "nᵉshar",
		"pron" : "nesh-ar'",
		"derivation" : "(Aramaic) corresponding to H5404 (נֶשֶׁר)",
		"def" : "an eagle",
		"kjv" : "eagle"
	},
	"H5404" : {
		"lemma" : "נֶשֶׁר",
		"xlit" : "nesher",
		"pron" : "neh'-sher",
		"derivation" : "from an unused root meaning to lacerate",
		"def" : "the eagle (or other large bird of prey)",
		"kjv" : "eagle"
	},
	"H5405" : {
		"lemma" : "נָשַׁת",
		"xlit" : "nâshath",
		"pron" : "naw-shath'",
		"derivation" : "a primitive root",
		"def" : "properly, to eliminate, i.e. (intransitively) to dry up",
		"kjv" : "fail"
	},
	"H5406" : {
		"lemma" : "נִשְׁתְּוָן",
		"xlit" : "nishtᵉvân",
		"pron" : "nish-tev-awn'",
		"derivation" : "probably of Persian origin",
		"def" : "an epistle",
		"kjv" : "letter"
	},
	"H5407" : {
		"lemma" : "נִשְׁתְּוָן",
		"xlit" : "nishtᵉvân",
		"pron" : "nish-tev-awn'",
		"derivation" : "(Aramaic) corresponding to H5406 (נִשְׁתְּוָן)",
		"def" : "{an epistle}",
		"kjv" : "letter"
	},
	"H5408" : {
		"lemma" : "נָתַח",
		"xlit" : "nâthach",
		"pron" : "naw-thakh'",
		"derivation" : "a primitive root",
		"def" : "to dismember",
		"kjv" : "cut (in pieces), divide, hew in pieces"
	},
	"H5409" : {
		"lemma" : "נֵתַח",
		"xlit" : "nêthach",
		"pron" : "nay'-thakh",
		"derivation" : "from H5408 (נָתַח)",
		"def" : "a fragment",
		"kjv" : "part, piece"
	},
	"H5410" : {
		"lemma" : "נָתִיב",
		"xlit" : "nâthîyb",
		"pron" : "naw-theeb'",
		"derivation" : "or (feminine) נְתִיבָה; or נְתִבָה; (Jeremiah 6:16), from an unused root meaning to tramp",
		"def" : "a (beaten) track",
		"kjv" : "path(-way), [idiom] travel(-ler), way"
	},
	"H5411" : {
		"lemma" : "נָתִין",
		"xlit" : "Nâthîyn",
		"pron" : "naw-theen'",
		"derivation" : "or נָתוּן (Ezra 8:17), (the proper form as passive participle), from H5414 (נָתַן); one given, i.e. (in the plural only)",
		"def" : "the Nethinim, or Temple-servants (as given to that duty)",
		"kjv" : "Nethinims"
	},
	"H5412" : {
		"lemma" : "נְתִין",
		"xlit" : "Nᵉthîyn",
		"pron" : "netheen'",
		"derivation" : "(Aramaic) corresponding to H5411 (נָתִין)",
		"def" : "{the Nethinim, or Temple-servants (as given to that duty)}",
		"kjv" : "Nethinims"
	},
	"H5413" : {
		"lemma" : "נָתַךְ",
		"xlit" : "nâthak",
		"pron" : "naw-thak'",
		"derivation" : "a primitive root",
		"def" : "to flow forth (literally or figuratively); by implication, to liquify",
		"kjv" : "drop, gather (together), melt, pour (forth, out)"
	},
	"H5414" : {
		"lemma" : "נָתַן",
		"xlit" : "nâthan",
		"pron" : "naw-than'",
		"derivation" : "a primitive root",
		"def" : "to give, used with greatest latitude of application (put, make, etc.)",
		"kjv" : "add, apply, appoint, ascribe, assign, [idiom] avenge, [idiom] be (healed), bestow, bring (forth, hither), cast, cause, charge, come, commit, consider, count, [phrase] cry, deliver (up), direct, distribute, do, [idiom] doubtless, [idiom] without fail, fasten, frame, [idiom] get, give (forth, over, up), grant, hang (up), [idiom] have, [idiom] indeed, lay (unto charge, up), (give) leave, lend, let (out), [phrase] lie, lift up, make, [phrase] O that, occupy, offer, ordain, pay, perform, place, pour, print, [idiom] pull, put (forth), recompense, render, requite, restore, send (out), set (forth), shew, shoot forth (up), [phrase] sing, [phrase] slander, strike, (sub-) mit, suffer, [idiom] surely, [idiom] take, thrust, trade, turn, utter, [phrase] weep, [phrase] willingly, [phrase] withdraw, [phrase] would (to) God, yield"
	},
	"H5415" : {
		"lemma" : "נְתַן",
		"xlit" : "nᵉthan",
		"pron" : "neth-an'",
		"derivation" : "(Aramaic) corresponding to H5414 (נָתַן)",
		"def" : "give",
		"kjv" : "bestow, give pay"
	},
	"H5416" : {
		"lemma" : "נָתָן",
		"xlit" : "Nâthân",
		"pron" : "naw-thawn'",
		"derivation" : "from H5414 (נָתַן); given",
		"def" : "Nathan, the name of five Israelites",
		"kjv" : "Nathan"
	},
	"H5417" : {
		"lemma" : "נְתַנְאֵל",
		"xlit" : "Nᵉthanʼêl",
		"pron" : "neth-an-ale'",
		"derivation" : "from H5414 (נָתַן) and H410 (אֵל); given of God",
		"def" : "Nethanel, the name of ten Israelites",
		"kjv" : "Nethaneel"
	},
	"H5418" : {
		"lemma" : "נְתַנְיָה",
		"xlit" : "Nᵉthanyâh",
		"pron" : "neth-an-yaw'",
		"derivation" : "or נְתַנְיָהוּ; from H5414 (נָתַן) and H3050 (יָהּ); given of Jah",
		"def" : "Nethanjah, the name of four Israelites",
		"kjv" : "Nethaniah"
	},
	"H5419" : {
		"lemma" : "נְתַן־מֶלֶךְ",
		"xlit" : "Nᵉthan-Melek",
		"pron" : "neth-an' meh'-lek",
		"derivation" : "from H5414 (נָתַן) and H4428 (מֶלֶךְ); given of (the) king",
		"def" : "Nethan-Melek, an Israelite",
		"kjv" : "Nathanmelech"
	},
	"H5420" : {
		"lemma" : "נָתָס",
		"xlit" : "nâthâç",
		"pron" : "naw-thas'",
		"derivation" : "a primitive root",
		"def" : "to tear up",
		"kjv" : "mar"
	},
	"H5421" : {
		"lemma" : "נָתַע",
		"xlit" : "nâthaʻ",
		"pron" : "naw-thah'",
		"derivation" : "for H5422 (נָתַץ)",
		"def" : "to tear out",
		"kjv" : "break"
	},
	"H5422" : {
		"lemma" : "נָתַץ",
		"xlit" : "nâthats",
		"pron" : "naw-thats'",
		"derivation" : "a primitive root",
		"def" : "to tear down",
		"kjv" : "beat down, break down (out), cast down, destroy, overthrow, pull down, throw down"
	},
	"H5423" : {
		"lemma" : "נָתַק",
		"xlit" : "nâthaq",
		"pron" : "naw-thak'",
		"derivation" : "a primitive root",
		"def" : "to tear off",
		"kjv" : "break (off), burst, draw (away), lift up, pluck (away, off), pull (out), root out"
	},
	"H5424" : {
		"lemma" : "נֶתֶק",
		"xlit" : "netheq",
		"pron" : "neh'-thek",
		"derivation" : "from H5423 (נָתַק)",
		"def" : "scurf",
		"kjv" : "(dry) scall"
	},
	"H5425" : {
		"lemma" : "נָתַר",
		"xlit" : "nâthar",
		"pron" : "naw-thar'",
		"derivation" : "a primitive root",
		"def" : "to jump, i.e. be violently agitated; causatively, to terrify, shake off, untie",
		"kjv" : "drive asunder, leap, (let) loose, [idiom] make, move, undo"
	},
	"H5426" : {
		"lemma" : "נְתַר",
		"xlit" : "nᵉthar",
		"pron" : "neth-ar'",
		"derivation" : "(Aramaic) corresponding to H5425 (נָתַר)",
		"def" : "{to jump, i.e. be violently agitated; causatively, to terrify, shake off, untie}",
		"kjv" : "shake off"
	},
	"H5427" : {
		"lemma" : "נֶתֶר",
		"xlit" : "nether",
		"pron" : "neh'-ther",
		"derivation" : "from H5425 (נָתַר)",
		"def" : "mineral potash (so called from effervescing with acid)",
		"kjv" : "nitre"
	},
	"H5428" : {
		"lemma" : "נָתַשׁ",
		"xlit" : "nâthash",
		"pron" : "naw-thash'",
		"derivation" : "a primitive root",
		"def" : "to tear away",
		"kjv" : "destroy, forsake, pluck (out, up, by the roots), pull up, root out (up), [idiom] utterly. s"
	},
	"H5429" : {
		"lemma" : "סְאָה",
		"xlit" : "çᵉʼâh",
		"pron" : "seh-aw'",
		"derivation" : "from an unused root meaning to define",
		"def" : "a seah, or certain measure (as determinative) forgrain",
		"kjv" : "measure"
	},
	"H5430" : {
		"lemma" : "סְאוֹן",
		"xlit" : "çᵉʼôwn",
		"pron" : "seh-own'",
		"derivation" : "from H5431 (סָאַן)",
		"def" : "perhaps a military boot (as aprotection from mud",
		"kjv" : "battle"
	},
	"H5431" : {
		"lemma" : "סָאַן",
		"xlit" : "çâʼan",
		"pron" : "saw-an'",
		"derivation" : "a primitive root; to be miry; used only as denominative from H5430 (סְאוֹן)",
		"def" : "to shoe, i.e. (active participle) a soldier shod",
		"kjv" : "warrior"
	},
	"H5432" : {
		"lemma" : "סַאסְּאָה",
		"xlit" : "çaʼçᵉʼâh",
		"pron" : "sah-seh-aw'",
		"derivation" : "for H5429 (סְאָה)",
		"def" : "measurement, i.e. moderation",
		"kjv" : "measure"
	},
	"H5433" : {
		"lemma" : "סָבָא",
		"xlit" : "çâbâʼ",
		"pron" : "saw-baw'",
		"derivation" : "a primitive root",
		"def" : "to quaff to satiety, i.e. become tipsy",
		"kjv" : "drunkard, fill self, Sabean, (wine-) bibber"
	},
	"H5434" : {
		"lemma" : "סְבָא",
		"xlit" : "Çᵉbâʼ",
		"pron" : "seb-aw'",
		"derivation" : "of foreign origin",
		"def" : "Seba, a son of Cush, and the country settled by him",
		"kjv" : "Seba"
	},
	"H5435" : {
		"lemma" : "סֹבֶא",
		"xlit" : "çôbeʼ",
		"pron" : "so'-beh",
		"derivation" : "from H5433 (סָבָא)",
		"def" : "potation, concretely (wine), or abstractly (carousal)",
		"kjv" : "drink, drunken, wine"
	},
	"H5436" : {
		"lemma" : "סְבָאִי",
		"xlit" : "Çᵉbâʼîy",
		"pron" : "seb-aw-ee'",
		"derivation" : "patrial from H5434 (סְבָא)",
		"def" : "a Sebaite, or inhabitant of Seba",
		"kjv" : "Sabean"
	},
	"H5437" : {
		"lemma" : "סָבַב",
		"xlit" : "çâbab",
		"pron" : "saw-bab'",
		"derivation" : "a primitive root",
		"def" : "to revolve, surround, or border; used in various applications, literally and figuratively",
		"kjv" : "bring, cast, fetch, lead, make, walk, [idiom] whirl, [idiom] round about, be about on every side, apply, avoid, beset (about), besiege, bring again, carry (about), change, cause to come about, [idiom] circuit, (fetch a) compass (about, round), drive, environ, [idiom] on every side, beset (close, come, compass, go, stand) round about, inclose, remove, return, set, sit down, turn (self) (about, aside, away, back)"
	},
	"H5438" : {
		"lemma" : "סִבָּה",
		"xlit" : "çibbâh",
		"pron" : "sib-baw'",
		"derivation" : "from H5437 (סָבַב)",
		"def" : "a (providential) turn (of affairs)",
		"kjv" : "cause"
	},
	"H5439" : {
		"lemma" : "סָבִיב",
		"xlit" : "çâbîyb",
		"pron" : "saw-beeb'",
		"derivation" : "or (feminine) סְבִיבָה; from H5437 (סָבַב)",
		"def" : "(as noun) a circle, neighbour, or environs; but chiefly (as adverb, with or without preposition) around",
		"kjv" : "(place, round) about, circuit, compass, on every side"
	},
	"H5440" : {
		"lemma" : "סָבַךְ",
		"xlit" : "çâbak",
		"pron" : "saw-bak'",
		"derivation" : "a primitive root",
		"def" : "to entwine",
		"kjv" : "fold together, wrap"
	},
	"H5441" : {
		"lemma" : "סֹבֶךְ",
		"xlit" : "çôbek",
		"pron" : "so'-bek",
		"derivation" : "from H5440 (סָבַךְ)",
		"def" : "a copse",
		"kjv" : "thicket"
	},
	"H5442" : {
		"lemma" : "סְבָךְ",
		"xlit" : "çᵉbâk",
		"pron" : "seb-awk'",
		"derivation" : "from H5440 (סָבַךְ)",
		"def" : "a copse",
		"kjv" : "thick(-et)"
	},
	"H5443" : {
		"lemma" : "סַבְּכָא",
		"xlit" : "çabbᵉkâʼ",
		"pron" : "sab-bek-aw'",
		"derivation" : "(Aramaic) or שַׂבְּכָא; (Aramaic), from a root corresponding to H5440 (סָבַךְ)",
		"def" : "a lyre",
		"kjv" : "sackbut"
	},
	"H5444" : {
		"lemma" : "סִבְּכַי",
		"xlit" : "Çibbᵉkay",
		"pron" : "sib-bek-ah'-ee",
		"derivation" : "from H5440 (סָבַךְ); copse-like",
		"def" : "Sibbecai, an Israelite",
		"kjv" : "Sibbecai, Sibbechai"
	},
	"H5445" : {
		"lemma" : "סָבַל",
		"xlit" : "çâbal",
		"pron" : "saw-bal'",
		"derivation" : "a primitive root",
		"def" : "to carry (literally or figuratively), or (reflexively) be burdensome; specifically, to be gravid",
		"kjv" : "bear, be a burden, carry, strong to labour"
	},
	"H5446" : {
		"lemma" : "סְבַל",
		"xlit" : "çᵉbal",
		"pron" : "seb-al'",
		"derivation" : "(Aramaic) corresponding to H5445 (סָבַל)",
		"def" : "to erect",
		"kjv" : "strongly laid"
	},
	"H5447" : {
		"lemma" : "סֵבֶל",
		"xlit" : "çêbel",
		"pron" : "say'-bel",
		"derivation" : "from H5445 (סָבַל)",
		"def" : "a load (literally or figuratively)",
		"kjv" : "burden, charge"
	},
	"H5448" : {
		"lemma" : "סֹבֶל",
		"xlit" : "çôbel",
		"pron" : "so'-bel",
		"derivation" : "(only in the form סֻבָּל; from H5445 (סָבַל)",
		"def" : "a load (figuratively)",
		"kjv" : "burden"
	},
	"H5449" : {
		"lemma" : "סַבָּל",
		"xlit" : "çabbâl",
		"pron" : "sab-bawl'",
		"derivation" : "from H5445 (סָבַל)",
		"def" : "a porter",
		"kjv" : "(to bear, bearer of) burden(-s)"
	},
	"H5450" : {
		"lemma" : "סְבָלָה",
		"xlit" : "çᵉbâlâh",
		"pron" : "seb-aw-law'",
		"derivation" : "from H5447 (סֵבֶל)",
		"def" : "porterage",
		"kjv" : "burden"
	},
	"H5451" : {
		"lemma" : "סִבֹּלֶת",
		"xlit" : "çibbôleth",
		"pron" : "sib-bo'-leth",
		"derivation" : "for H7641 (שִׁבֹּל)",
		"def" : "an ear of grain",
		"kjv" : "Sibboleth"
	},
	"H5452" : {
		"lemma" : "סְבַר",
		"xlit" : "çᵉbar",
		"pron" : "seb-ar'",
		"derivation" : "(Aramaic) a primitive root",
		"def" : "to bear in mind, i.e. hope",
		"kjv" : "think"
	},
	"H5453" : {
		"lemma" : "סִבְרַיִם",
		"xlit" : "Çibrayim",
		"pron" : "sib-rah'-yim",
		"derivation" : "dual from a root corresponding to H5452 (סְבַר); double hope",
		"def" : "Sibrajim, a place in Syria",
		"kjv" : "Sibraim"
	},
	"H5454" : {
		"lemma" : "סַבְתָּא",
		"xlit" : "Çabtâʼ",
		"pron" : "sab-taw'",
		"derivation" : "or סַבְתָּה; probably of foreign derivation",
		"def" : "Sabta or Sabtah, the name of a son of Cush, and the country occupied by his posterity",
		"kjv" : "Sabta, Sabtah"
	},
	"H5455" : {
		"lemma" : "סַבְתְּכָא",
		"xlit" : "Çabtᵉkâʼ",
		"pron" : "sab-tek-aw'",
		"derivation" : "probably of foreign derivation",
		"def" : "Sabteca, the name of a son of Cush, and the region settled by him",
		"kjv" : "Sabtecha, Sabtechah"
	},
	"H5456" : {
		"lemma" : "סָגַד",
		"xlit" : "çâgad",
		"pron" : "saw-gad'",
		"derivation" : "a primitive root",
		"def" : "to prostrate oneself (in homage)",
		"kjv" : "fall down"
	},
	"H5457" : {
		"lemma" : "סְגִד",
		"xlit" : "çᵉgid",
		"pron" : "seg-eed'",
		"derivation" : "(Aramaic) corresponding to H5456 (סָגַד)",
		"def" : "{to prostrate oneself (in homage)}",
		"kjv" : "worship"
	},
	"H5458" : {
		"lemma" : "סְגוֹר",
		"xlit" : "çᵉgôwr",
		"pron" : "seg-ore'",
		"derivation" : "from H5462 (סָגַר)",
		"def" : "properly, shut up, i.e. the breast (as enclosing the heart); also gold (as generally shut up safely)",
		"kjv" : "caul, gold"
	},
	"H5459" : {
		"lemma" : "סְגֻלָּה",
		"xlit" : "çᵉgullâh",
		"pron" : "seg-ool-law'",
		"derivation" : "feminine passive participle of an unused root meaning to shut up",
		"def" : "wealth (as closely shut up)",
		"kjv" : "jewel, peculiar (treasure), proper good, special"
	},
	"H5460" : {
		"lemma" : "סְגַן",
		"xlit" : "çᵉgan",
		"pron" : "seg-an'",
		"derivation" : "(Aramaic) corresponding to H5461 (סָגָן)",
		"def" : "{a prefect of aprovince}",
		"kjv" : "governor"
	},
	"H5461" : {
		"lemma" : "סָגָן",
		"xlit" : "çâgân",
		"pron" : "saw-gawn'",
		"derivation" : "from an unused root meaning to superintend",
		"def" : "a prefect of a province",
		"kjv" : "prince, ruler"
	},
	"H5462" : {
		"lemma" : "סָגַר",
		"xlit" : "çâgar",
		"pron" : "saw-gar'",
		"derivation" : "a primitive root",
		"def" : "to shut up; figuratively, to surrender",
		"kjv" : "close up, deliver (up), give over (up), inclose, [idiom] pure, repair, shut (in, self, out, up, up together), stop, [idiom] straitly"
	},
	"H5463" : {
		"lemma" : "סְגַר",
		"xlit" : "çᵉgar",
		"pron" : "seg-ar'",
		"derivation" : "(Aramaic) corresponding to H5462 (סָגַר)",
		"def" : "{to shut up; figuratively, to surrender}",
		"kjv" : "shut up"
	},
	"H5464" : {
		"lemma" : "סַגְרִיד",
		"xlit" : "çagrîyd",
		"pron" : "sag-reed'",
		"derivation" : "probably from H5462 (סָגַר) in the sense of sweeping away",
		"def" : "a pouring rain",
		"kjv" : "very rainy"
	},
	"H5465" : {
		"lemma" : "סַד",
		"xlit" : "çad",
		"pron" : "sad",
		"derivation" : "from an unused root meaning to estop",
		"def" : "the stocks",
		"kjv" : "stocks"
	},
	"H5466" : {
		"lemma" : "סָדִין",
		"xlit" : "çâdîyn",
		"pron" : "saw-deen'",
		"derivation" : "from an unused root meaning to envelop",
		"def" : "a wrapper, i.e. shirt",
		"kjv" : "fine linen, sheet"
	},
	"H5467" : {
		"lemma" : "סְדֹם",
		"xlit" : "Çᵉdôm",
		"pron" : "sed-ome'",
		"derivation" : "from an unused root meaning to scorch; burnt (i.e. volcanic or bituminous) district",
		"def" : "Sedom, a place near the Dead Sea",
		"kjv" : "Sodom"
	},
	"H5468" : {
		"lemma" : "סֶדֶר",
		"xlit" : "çeder",
		"pron" : "seh'-der",
		"derivation" : "from an unused root meaning to arrange",
		"def" : "order",
		"kjv" : "order"
	},
	"H5469" : {
		"lemma" : "סַהַר",
		"xlit" : "çahar",
		"pron" : "cah'-har",
		"derivation" : "from an unused root meaning to be round",
		"def" : "roundness",
		"kjv" : "round"
	},
	"H5470" : {
		"lemma" : "סֹהַר",
		"xlit" : "çôhar",
		"pron" : "so'-har",
		"derivation" : "from the same as H5469 (סַהַר)",
		"def" : "a dungeon (as surrounded by walls)",
		"kjv" : "prison"
	},
	"H5471" : {
		"lemma" : "סוֹא",
		"xlit" : "Çôwʼ",
		"pron" : "so",
		"derivation" : "of foreign derivation",
		"def" : "So, an Egyptian king",
		"kjv" : "So"
	},
	"H5472" : {
		"lemma" : "סוּג",
		"xlit" : "çûwg",
		"pron" : "soog",
		"derivation" : "a primitive root",
		"def" : "properly, to flinch, i.e. (by implication) to go back, literally (to retreat) or figuratively (to apostatize)",
		"kjv" : "backslider, drive, go back, turn (away, back)"
	},
	"H5473" : {
		"lemma" : "סוּג",
		"xlit" : "çûwg",
		"pron" : "soog",
		"derivation" : "a primitive root (probably rather identical with H5472 (סוּג) through the idea of shrinking from a hedge; compare H7735 (שׂוּג))",
		"def" : "to hem in, i.e. bind",
		"kjv" : "set about"
	},
	"H5474" : {
		"lemma" : "סוּגַר",
		"xlit" : "çûwgar",
		"pron" : "soo-gar'",
		"derivation" : "from H5462 (סָגַר)",
		"def" : "an inclosure, i.e. cage (for an animal)",
		"kjv" : "ward"
	},
	"H5475" : {
		"lemma" : "סוֹד",
		"xlit" : "çôwd",
		"pron" : "sode",
		"derivation" : "from H3245 (יָסַד)",
		"def" : "a session, i.e. company of persons (in close deliberation); by implication, intimacy, consultation, a secret",
		"kjv" : "assembly, consel, inward, secret (counsel)"
	},
	"H5476" : {
		"lemma" : "סוֹדִי",
		"xlit" : "Çôwdîy",
		"pron" : "so-dee'",
		"derivation" : "from H5475 (סוֹד); a confidant",
		"def" : "Sodi, an Israelite",
		"kjv" : "Sodi"
	},
	"H5477" : {
		"lemma" : "סוּחַ",
		"xlit" : "Çûwach",
		"pron" : "soo'-akh",
		"derivation" : "from an unused root meaning to wipe away; sweeping",
		"def" : "Suach, an Israelite",
		"kjv" : "Suah"
	},
	"H5478" : {
		"lemma" : "סוּחָה",
		"xlit" : "çûwchâh",
		"pron" : "soo-khaw'",
		"derivation" : "from the same as H5477 (סוּחַ)",
		"def" : "something swept away, i.e. filth",
		"kjv" : "torn"
	},
	"H5479" : {
		"lemma" : "סוֹטַי",
		"xlit" : "Çôwṭay",
		"pron" : "so-tah'-ee",
		"derivation" : "from H7750 (שׂוּט); roving",
		"def" : "Sotai, one of the Nethinim",
		"kjv" : "Sotai"
	},
	"H5480" : {
		"lemma" : "סוּךְ",
		"xlit" : "çûwk",
		"pron" : "sook",
		"derivation" : "a primitive root",
		"def" : "properly, to smear over (with oil), i.e. anoint",
		"kjv" : "anoint (self), [idiom] at all"
	},
	"H5481" : {
		"lemma" : "סוּמְפּוֹנְיָה",
		"xlit" : "çûwmᵉpôwnᵉyâh",
		"pron" : "soom-po-neh-yaw'",
		"derivation" : "(Aramaic) or סוּמְפֹּנְיָה; (Aramaic), or סִיפֹנְיָא; (Daniel 3:10) (Aramaic), of Greek origin",
		"def" : "a bagpipe (with a double pipe)",
		"kjv" : "dulcimer"
	},
	"H5482" : {
		"lemma" : "סְוֵנֵה",
		"xlit" : "Çᵉvênêh",
		"pron" : "sev-ay-nay'",
		"derivation" : "(rather to be written סְוֵנָה; for סְוֵן; i.e to Seven); of Egyptian derivation",
		"def" : "Seven, a place in Upper Egypt",
		"kjv" : "Syene"
	},
	"H5483" : {
		"lemma" : "סוּס",
		"xlit" : "çûwç",
		"pron" : "soos",
		"derivation" : "or סֻס; from an unused root meaning to skip (properly, for joy)",
		"def" : "a horse (as leaping); also a swallow (from its rapid flight)",
		"kjv" : "crane, horse (-back, -hoof). Compare H6571 (פָּרָשׁ)"
	},
	"H5484" : {
		"lemma" : "סוּסָה",
		"xlit" : "çûwçâh",
		"pron" : "soo-saw'",
		"derivation" : "feminine of H5483 (סוּס)",
		"def" : "a mare",
		"kjv" : "company of horses"
	},
	"H5485" : {
		"lemma" : "סוּסִי",
		"xlit" : "Çûwçîy",
		"pron" : "soo-see'",
		"derivation" : "from H5483 (סוּס); horse-like",
		"def" : "Susi, an Israelite",
		"kjv" : "Susi"
	},
	"H5486" : {
		"lemma" : "סוּף",
		"xlit" : "çûwph",
		"pron" : "soof",
		"derivation" : "a primitive root",
		"def" : "to snatch away, i.e. terminate",
		"kjv" : "consume, have an end, perish, [idiom] be utterly"
	},
	"H5487" : {
		"lemma" : "סוּף",
		"xlit" : "çûwph",
		"pron" : "soof",
		"derivation" : "(Aramaic) corresponding to H5486 (סוּף)",
		"def" : "to come to an end",
		"kjv" : "consume, fulfill"
	},
	"H5488" : {
		"lemma" : "סוּף",
		"xlit" : "çûwph",
		"pron" : "soof",
		"derivation" : "probably of Egyptian origin",
		"def" : "a reed, especially the papyrus",
		"kjv" : "flag, Red (sea), weed. Compare H5489 (סוּף)"
	},
	"H5489" : {
		"lemma" : "סוּף",
		"xlit" : "Çûwph",
		"pron" : "soof",
		"derivation" : "for H5488 (סוּף) (by ellipsis of H3220 (יָם))",
		"def" : "the Reed (Sea)",
		"kjv" : "Red Sea"
	},
	"H5490" : {
		"lemma" : "סוֹף",
		"xlit" : "çôwph",
		"pron" : "sofe",
		"derivation" : "from H5486 (סוּף)",
		"def" : "a termination",
		"kjv" : "conclusion, end, hinder participle"
	},
	"H5491" : {
		"lemma" : "סוֹף",
		"xlit" : "çôwph",
		"pron" : "sofe",
		"derivation" : "(Aramaic) corresponding to H5490 (סוֹף)",
		"def" : "{a termination}",
		"kjv" : "end"
	},
	"H5492" : {
		"lemma" : "סוּפָה",
		"xlit" : "çûwphâh",
		"pron" : "soo-faw'",
		"derivation" : "from H5486 (סוּף)",
		"def" : "a hurricane",
		"kjv" : "Red Sea, storm, tempest, whirlwind, Red sea"
	},
	"H5493" : {
		"lemma" : "סוּר",
		"xlit" : "çûwr",
		"pron" : "soor",
		"derivation" : "or שׂוּר; (Hosea 9:12), a primitive root",
		"def" : "to turn off (literal or figurative)",
		"kjv" : "be(-head), bring, call back, decline, depart, eschew, get (you), go (aside), [idiom] grievous, lay away (by), leave undone, be past, pluck away, put (away, down), rebel, remove (to and fro), revolt, [idiom] be sour, take (away, off), turn (aside, away, in), withdraw, be without"
	},
	"H5494" : {
		"lemma" : "סוּר",
		"xlit" : "çûwr",
		"pron" : "soor",
		"derivation" : "probably passive participle of H5493 (סוּר)",
		"def" : "turned off, i.e. deteriorated",
		"kjv" : "degenerate"
	},
	"H5495" : {
		"lemma" : "סוּר",
		"xlit" : "Çûwr",
		"pron" : "soor",
		"derivation" : "the same as H5494 (סוּר)",
		"def" : "Sur, a gate of the temple",
		"kjv" : "Sur"
	},
	"H5496" : {
		"lemma" : "סוּת",
		"xlit" : "çûwth",
		"pron" : "sooth",
		"derivation" : "perhaps denominative from H7898 (שַׁיִת)",
		"def" : "properly, to prick, i.e. (figuratively) stimulate; by implication, to seduce",
		"kjv" : "entice, move, persuade, provoke, remove, set on, stir up, take away"
	},
	"H5497" : {
		"lemma" : "סוּת",
		"xlit" : "çûwth",
		"pron" : "sooth",
		"derivation" : "probably from the same root as H4533 (מַסְוֶה)",
		"def" : "covering, i.e. clothing",
		"kjv" : "clothes"
	},
	"H5498" : {
		"lemma" : "סָחַב",
		"xlit" : "çâchab",
		"pron" : "saw-khab'",
		"derivation" : "a primitive root",
		"def" : "to trail along",
		"kjv" : "draw (out), tear"
	},
	"H5499" : {
		"lemma" : "סְחָבָה",
		"xlit" : "çᵉchâbâh",
		"pron" : "seh-khaw-baw'",
		"derivation" : "from H5498 (סָחַב)",
		"def" : "a rag",
		"kjv" : "cast clout"
	},
	"H5500" : {
		"lemma" : "סָחָה",
		"xlit" : "çâchâh",
		"pron" : "saw-khaw'",
		"derivation" : "a primitive root",
		"def" : "to sweep away",
		"kjv" : "scrape"
	},
	"H5501" : {
		"lemma" : "סְחִי",
		"xlit" : "çᵉchîy",
		"pron" : "seh-khee'",
		"derivation" : "from H5500 (סָחָה)",
		"def" : "refuse (as swept off)",
		"kjv" : "offscouring"
	},
	"H5502" : {
		"lemma" : "סָחַף",
		"xlit" : "çâchaph",
		"pron" : "saw-khaf'",
		"derivation" : "a primitive root",
		"def" : "to scrape off",
		"kjv" : "sweep (away)"
	},
	"H5503" : {
		"lemma" : "סָחַר",
		"xlit" : "çâchar",
		"pron" : "saw-khar'",
		"derivation" : "a primitive root",
		"def" : "to travel round (specifically as a pedlar); intensively, to palpitate",
		"kjv" : "go about, merchant(-man), occupy with, pant, trade, traffick"
	},
	"H5504" : {
		"lemma" : "סַחַר",
		"xlit" : "çachar",
		"pron" : "sakh'-ar",
		"derivation" : "from H5503 (סָחַר)",
		"def" : "profit (from trade)",
		"kjv" : "merchandise"
	},
	"H5505" : {
		"lemma" : "סָחַר",
		"xlit" : "çâchar",
		"pron" : "saw-khar'",
		"derivation" : "from H5503 (סָחַר)",
		"def" : "an emporium; abstractly, profit (from trade)",
		"kjv" : "mart, merchandise"
	},
	"H5506" : {
		"lemma" : "סְחֹרָה",
		"xlit" : "çᵉchôrâh",
		"pron" : "sekh-o-raw'",
		"derivation" : "from H5503 (סָחַר)",
		"def" : "traffic",
		"kjv" : "merchandise"
	},
	"H5507" : {
		"lemma" : "סֹחֵרָה",
		"xlit" : "çôchêrâh",
		"pron" : "so-khay-raw'",
		"derivation" : "properly, active participle feminine of H5503 (סָחַר)",
		"def" : "something surrounding the person, i.e. a shield",
		"kjv" : "buckler"
	},
	"H5508" : {
		"lemma" : "סֹחֵרֶת",
		"xlit" : "çôchêreth",
		"pron" : "so-kheh'-reth",
		"derivation" : "similar to H5507 (סֹחֵרָה)",
		"def" : "probably a (black) tile (or tessara) forlaying borders with",
		"kjv" : "black marble"
	},
	"H5509" : {
		"lemma" : "סִיג",
		"xlit" : "çîyg",
		"pron" : "seeg",
		"derivation" : "or סוּג; (Ezekiel 22:18), from H5472 (סוּג) in the sense of refuse",
		"def" : "scoria",
		"kjv" : "dross"
	},
	"H5510" : {
		"lemma" : "סִיוָן",
		"xlit" : "Çîyvân",
		"pron" : "see-vawn'",
		"derivation" : "probably of Persian origin",
		"def" : "Sivan, the third Hebrew month",
		"kjv" : "Sivan"
	},
	"H5511" : {
		"lemma" : "סִיחוֹן",
		"xlit" : "Çîychôwn",
		"pron" : "see-khone'",
		"derivation" : "or סִיחֹן; from the same as H5477 (סוּחַ); tempestuous",
		"def" : "Sichon, an Amoritish king",
		"kjv" : "Sihon"
	},
	"H5512" : {
		"lemma" : "סִין",
		"xlit" : "Çîyn",
		"pron" : "seen",
		"derivation" : "of uncertain derivation",
		"def" : "Sin the name of an Egyptian town and (probably) desert adjoining",
		"kjv" : "Sin"
	},
	"H5513" : {
		"lemma" : "סִינִי",
		"xlit" : "Çîynîy",
		"pron" : "see-nee'",
		"derivation" : "from an otherwise unknown name of a man",
		"def" : "a Sinite, or descendant of one of the sons of Canaan",
		"kjv" : "Sinite"
	},
	"H5514" : {
		"lemma" : "סִינַי",
		"xlit" : "Çîynay",
		"pron" : "see-nah'-ee",
		"derivation" : "of uncertain derivation",
		"def" : "Sinai, mountain of Arabia",
		"kjv" : "Sinai"
	},
	"H5515" : {
		"lemma" : "סִינִים",
		"xlit" : "Çîynîym",
		"pron" : "see-neem'",
		"derivation" : "plural of an otherwise unknown name",
		"def" : "Sinim, a distant Oriental region",
		"kjv" : "Sinim"
	},
	"H5516" : {
		"lemma" : "סִיסְרָא",
		"xlit" : "Çîyçᵉrâʼ",
		"pron" : "see-ser-aw'",
		"derivation" : "of uncertain derivation",
		"def" : "Sisera, the name of a Canaanitish king and of one of the Nethinim",
		"kjv" : "Sisera"
	},
	"H5517" : {
		"lemma" : "סִיעָא",
		"xlit" : "Çîyʻâʼ",
		"pron" : "see-ah'",
		"derivation" : "or סִיעֲהָא; from an unused root meaning to converse; congregation",
		"def" : "Sia or Siaha, one of the Nethinim",
		"kjv" : "Sia, Siaha"
	},
	"H5518" : {
		"lemma" : "סִיר",
		"xlit" : "çîyr",
		"pron" : "seer",
		"derivation" : "or (feminine) סִירָה; or סִרָה; (Jeremiah 52:18), from a primitive root meaning to boil up",
		"def" : "a pot; also a thorn (as springing up rapidly); by implication, a hook",
		"kjv" : "caldron, fishhook, pan, (wash-)pot, thorn"
	},
	"H5519" : {
		"lemma" : "סָךְ",
		"xlit" : "çâk",
		"pron" : "sawk",
		"derivation" : "from H5526 (סָכַךְ)",
		"def" : "properly, a thicket of men, i.e. a crowd",
		"kjv" : "multitude"
	},
	"H5520" : {
		"lemma" : "סֹךְ",
		"xlit" : "çôk",
		"pron" : "soke",
		"derivation" : "from H5526 (סָכַךְ)",
		"def" : "a hut (as of entwined boughs); also a lair",
		"kjv" : "covert, den, pavilion, tabernacle"
	},
	"H5521" : {
		"lemma" : "סֻכָּה",
		"xlit" : "çukkâh",
		"pron" : "sook-kaw'",
		"derivation" : "feminine of H5520 (סֹךְ)",
		"def" : "a hut or lair",
		"kjv" : "booth, cottage, covert, pavilion, tabernacle, tent"
	},
	"H5522" : {
		"lemma" : "סִכּוּת",
		"xlit" : "çikkûwth",
		"pron" : "sik-kooth'",
		"derivation" : "feminine of H5519 (סָךְ)",
		"def" : "an (idolatrous) booth",
		"kjv" : "tabernacle"
	},
	"H5523" : {
		"lemma" : "סֻכּוֹת",
		"xlit" : "Çukkôwth",
		"pron" : "sook-kohth'",
		"derivation" : "or סֻכֹּת; plural of H5521 (סֻכָּה); booths",
		"def" : "Succoth, the name of a place in Egypt and of three in Palestine",
		"kjv" : "Succoth"
	},
	"H5524" : {
		"lemma" : "סֻכּוֹת בְּנוֹת",
		"xlit" : "Çukkôwth bᵉnôwth",
		"pron" : "sook-kohth' ben-ohth'",
		"derivation" : "from H5523 (סֻכּוֹת) and the (irregular) plural of H1323 (בַּת)",
		"def" : "booths of (the) daughters; brothels, i.e. idoalatrous tents forimpure purpose",
		"kjv" : "Succothbenoth"
	},
	"H5525" : {
		"lemma" : "סֻכִּי",
		"xlit" : "Çukkîy",
		"pron" : "sook-kee'",
		"derivation" : "patrial from an unknown name (perhaps H5520 (סֹךְ))",
		"def" : "a Sukkite, or inhabitant of some place near Egypt (i.e. hut-dwellers)",
		"kjv" : "Sukkiims"
	},
	"H5526" : {
		"lemma" : "סָכַךְ",
		"xlit" : "çâkak",
		"pron" : "saw-kak'",
		"derivation" : "or שָׂכַךְ; (Exodus 33:22), a primitive root",
		"def" : "properly, to entwine as ascreen; by implication, to fence in, cover over, (figuratively) protect",
		"kjv" : "cover, defence, defend, hedge in, join together, set, shut up"
	},
	"H5527" : {
		"lemma" : "סְכָכָה",
		"xlit" : "Çᵉkâkâh",
		"pron" : "sek-aw-kaw'",
		"derivation" : "from H5526 (סָכַךְ); inclosure",
		"def" : "Secacah, a place in Palestine",
		"kjv" : "Secacah"
	},
	"H5528" : {
		"lemma" : "סָכַל",
		"xlit" : "çâkal",
		"pron" : "saw-kal'",
		"derivation" : "for H3688 (כָּסַל)",
		"def" : "to be silly",
		"kjv" : "do (make, play the, turn into) fool(-ish, -ishly, -ishness)"
	},
	"H5529" : {
		"lemma" : "סֶכֶל",
		"xlit" : "çekel",
		"pron" : "seh'-kal",
		"derivation" : "from H5528 (סָכַל)",
		"def" : "silliness; concretely and collectively, dolts",
		"kjv" : "folly"
	},
	"H5530" : {
		"lemma" : "סָכָל",
		"xlit" : "çâkâl",
		"pron" : "saw-kawl'",
		"derivation" : "from H5528 (סָכַל)",
		"def" : "silly",
		"kjv" : "fool(-ish), sottish"
	},
	"H5531" : {
		"lemma" : "סִכְלוּת",
		"xlit" : "çiklûwth",
		"pron" : "sik-looth'",
		"derivation" : "or שִׂכְלוּת; (Ecclesiastes 1:17), from H5528 (סָכַל)",
		"def" : "silliness",
		"kjv" : "folly, foolishness"
	},
	"H5532" : {
		"lemma" : "סָכַן",
		"xlit" : "çâkan",
		"pron" : "saw-kan'",
		"derivation" : "a primitive root",
		"def" : "to be familiar with; by implication, to minister to, be serviceable to, be customary",
		"kjv" : "acquaint (self), be advantage, [idiom] ever, (be, (un-)) profit(-able), treasurer, be wont"
	},
	"H5533" : {
		"lemma" : "סָכַן",
		"xlit" : "çâkan",
		"pron" : "saw-kan'",
		"derivation" : "probably a denominative from H7915 (שַׂכִּין)",
		"def" : "properly, to cut, i.e. damage; also to grow (causatively, make) poor",
		"kjv" : "endanger, impoverish"
	},
	"H5534" : {
		"lemma" : "סָכַר",
		"xlit" : "çâkar",
		"pron" : "saw-kar'",
		"derivation" : "a primitive root",
		"def" : "to shut up; by implication, to surrender",
		"kjv" : "stop, give over. See also H5462 (סָגַר), H7936 (שָׂכַר)"
	},
	"H5535" : {
		"lemma" : "סָכַת",
		"xlit" : "çâkath",
		"pron" : "saw-kath'",
		"derivation" : "a primitive root to be silent",
		"def" : "by implication, to observe quietly",
		"kjv" : "take heed"
	},
	"H5536" : {
		"lemma" : "סַל",
		"xlit" : "çal",
		"pron" : "sal",
		"derivation" : "from H5549 (סָלַל)",
		"def" : "properly, a willow twig (as pendulous), i.e. an osier; but only as woven into abasket",
		"kjv" : "basket"
	},
	"H5537" : {
		"lemma" : "סָלָא",
		"xlit" : "çâlâʼ",
		"pron" : "saw-law'",
		"derivation" : "a primitive root",
		"def" : "to suspend in a balance, i.e. weigh",
		"kjv" : "compare"
	},
	"H5538" : {
		"lemma" : "סִלָּא",
		"xlit" : "Çillâʼ",
		"pron" : "sil-law'",
		"derivation" : "from H5549 (סָלַל); an embankment",
		"def" : "Silla, a place in Jerusalem",
		"kjv" : "Silla"
	},
	"H5539" : {
		"lemma" : "סָלַד",
		"xlit" : "çâlad",
		"pron" : "saw-lad'",
		"derivation" : "a primitive root",
		"def" : "probably to leap (with joy), i.e. exult",
		"kjv" : "harden self"
	},
	"H5540" : {
		"lemma" : "סֶלֶד",
		"xlit" : "Çeled",
		"pron" : "seh'-led",
		"derivation" : "from H5539 (סָלַד); exultation",
		"def" : "Seled, an Israelite",
		"kjv" : "Seled"
	},
	"H5541" : {
		"lemma" : "סָלָה",
		"xlit" : "çâlâh",
		"pron" : "saw-law'",
		"derivation" : "a primitive root",
		"def" : "to hang up, i.e. weigh, or (figuratively) contemn",
		"kjv" : "tread down (under foot), value"
	},
	"H5542" : {
		"lemma" : "סֶלָה",
		"xlit" : "çelâh",
		"pron" : "seh'-law",
		"derivation" : "from H5541 (סָלָה)",
		"def" : "suspension (of music), i.e. pause",
		"kjv" : "Selah"
	},
	"H5543" : {
		"lemma" : "סַלּוּ",
		"xlit" : "Çallûw",
		"pron" : "sal-loo'",
		"derivation" : "or סַלּוּא; or סָלוּא; or סַלַּי; from H5541 (סָלָה); weighed",
		"def" : "Sallu or Sallai, the name of two Israelites",
		"kjv" : "Sallai, Sallu, Salu"
	},
	"H5544" : {
		"lemma" : "סִלּוֹן",
		"xlit" : "çillôwn",
		"pron" : "sil-lone'",
		"derivation" : "or סַלּוֹן; from H5541 (סָלָה)",
		"def" : "a prickle (as if pendulous)",
		"kjv" : "brier, thorn"
	},
	"H5545" : {
		"lemma" : "סָלַח",
		"xlit" : "çâlach",
		"pron" : "saw-lakh'",
		"derivation" : "a primitive root",
		"def" : "to forgive",
		"kjv" : "forgive, pardon, spare"
	},
	"H5546" : {
		"lemma" : "סַלָּח",
		"xlit" : "çallâch",
		"pron" : "saw-lawkh'",
		"derivation" : "from H5545 (סָלַח)",
		"def" : "placable",
		"kjv" : "ready to forgive"
	},
	"H5547" : {
		"lemma" : "סְלִיחָה",
		"xlit" : "çᵉlîychâh",
		"pron" : "sel-ee-khaw'",
		"derivation" : "from H5545 (סָלַח)",
		"def" : "pardon",
		"kjv" : "forgiveness, pardon"
	},
	"H5548" : {
		"lemma" : "סַלְכָה",
		"xlit" : "Çalkâh",
		"pron" : "sal-kaw'",
		"derivation" : "from an unused root. meaning to walk; walking",
		"def" : "Salcah, a place East of the Jordan",
		"kjv" : "Salcah, Salchah"
	},
	"H5549" : {
		"lemma" : "סָלַל",
		"xlit" : "çâlal",
		"pron" : "saw-lal'",
		"derivation" : "a primitive root",
		"def" : "to mound up (especially a turnpike); figurative, to exalt; reflexively, to oppose (as by a dam)",
		"kjv" : "cast up, exalt (self), extol, make plain, raise up"
	},
	"H5550" : {
		"lemma" : "סֹלְלָה",
		"xlit" : "çôlᵉlâh",
		"pron" : "so-lel-aw'",
		"derivation" : "or סוֹלְלָה; active participle feminine of H5549 (סָלַל), but used passively",
		"def" : "a military mound, i.e. rampart of besiegers",
		"kjv" : "bank, mount"
	},
	"H5551" : {
		"lemma" : "סֻלָּם",
		"xlit" : "çullâm",
		"pron" : "sool-lawm'",
		"derivation" : "from H5549 (סָלַל)",
		"def" : "a stair-case",
		"kjv" : "ladder"
	},
	"H5552" : {
		"lemma" : "סַלְסִלָּה",
		"xlit" : "çalçillâh",
		"pron" : "sal-sil-law'",
		"derivation" : "from H5541 (סָלָה)",
		"def" : "a twig (as pendulous)",
		"kjv" : "basket"
	},
	"H5553" : {
		"lemma" : "סֶלַע",
		"xlit" : "çelaʻ",
		"pron" : "seh'-lah",
		"derivation" : "from an unused root meaning to be lofty",
		"def" : "a craggy rock, literally or figuratively (a fortress)",
		"kjv" : "(ragged) rock, stone(-ny), strong hold"
	},
	"H5554" : {
		"lemma" : "סֶלַע",
		"xlit" : "Çelaʻ",
		"pron" : "seh'-lah",
		"derivation" : "the same as H5553 (סֶלַע)",
		"def" : "Sela, the rock-city of Idumaea",
		"kjv" : "rock, Sela(-h)"
	},
	"H5555" : {
		"lemma" : "סֶלַע הַמַּחְלְקוֹת",
		"xlit" : "Çelaʻ ham-machlᵉqôwth",
		"pron" : "seh'-lah hammakh-lek-oth'",
		"derivation" : "from H5553 (סֶלַע) and the plural of H4256 (מַחֲלֹקֶת) with the article interposed; rock of the divisions",
		"def" : "Sela-ham-Machlekoth, a place in Palestine",
		"kjv" : "Sela-hammalekoth"
	},
	"H5556" : {
		"lemma" : "סׇלְעָם",
		"xlit" : "çolʻâm",
		"pron" : "sol-awm'",
		"derivation" : "apparently from the same as H5553 (סֶלַע) in the sense of crushing as with a rock, i.e. consuming",
		"def" : "a kind of locust (from its destructiveness)",
		"kjv" : "bald locust"
	},
	"H5557" : {
		"lemma" : "סָלַף",
		"xlit" : "çâlaph",
		"pron" : "saw-laf'",
		"derivation" : "a primitive root",
		"def" : "properly, to wrench, i.e. (figuratively) to subvert",
		"kjv" : "overthrow, pervert"
	},
	"H5558" : {
		"lemma" : "סֶלֶף",
		"xlit" : "çeleph",
		"pron" : "seh'-lef",
		"derivation" : "from H5557 (סָלַף)",
		"def" : "distortion, i.e. (figuratively) viciousness",
		"kjv" : "perverseness"
	},
	"H5559" : {
		"lemma" : "סְלִק",
		"xlit" : "çᵉliq",
		"pron" : "sel-eek'",
		"derivation" : "(Aramaic) a primitive root",
		"def" : "to ascend",
		"kjv" : "come (up)"
	},
	"H5560" : {
		"lemma" : "סֹלֶת",
		"xlit" : "çôleth",
		"pron" : "so'-leth",
		"derivation" : "from an unused root meaning to strip",
		"def" : "flour (as chipped off)",
		"kjv" : "(fine) flour, meal"
	},
	"H5561" : {
		"lemma" : "סַם",
		"xlit" : "çam",
		"pron" : "sam",
		"derivation" : "from an unused root meaning to smell sweet",
		"def" : "an aroma",
		"kjv" : "sweet (spice)"
	},
	"H5562" : {
		"lemma" : "סַמְגַּר נְבוֹ",
		"xlit" : "Çamgar Nᵉbôw",
		"pron" : "sam-gar' neb-o'",
		"derivation" : "of foreign origin",
		"def" : "Samgar-Nebo, a Babylonian general",
		"kjv" : "Samgar-nebo"
	},
	"H5563" : {
		"lemma" : "סְמָדַר",
		"xlit" : "çᵉmâdar",
		"pron" : "sem-aw-dar'",
		"derivation" : "of uncertain derivation",
		"def" : "a vine blossom; used also adverbially, abloom",
		"kjv" : "tender grape"
	},
	"H5564" : {
		"lemma" : "סָמַךְ",
		"xlit" : "çâmak",
		"pron" : "saw-mak'",
		"derivation" : "a primitive root",
		"def" : "to prop (literally or figuratively); reflexively, to lean upon or take hold of (in a favorable or unfavorable sense)",
		"kjv" : "bear up, establish, (up-) hold, lay, lean, lie hard, put, rest self, set self, stand fast, stay (self), sustain"
	},
	"H5565" : {
		"lemma" : "סְמַכְיָהוּ",
		"xlit" : "Çᵉmakyâhûw",
		"pron" : "sem-ak-yaw'-hoo",
		"derivation" : "from H5564 (סָמַךְ) and H3050 (יָהּ); supported of Jah",
		"def" : "Semakjah, an Israelite",
		"kjv" : "Semachiah"
	},
	"H5566" : {
		"lemma" : "סֶמֶל",
		"xlit" : "çemel",
		"pron" : "seh'-mel",
		"derivation" : "or סֵמֶל; from an unused root meaning to resemble",
		"def" : "a likeness",
		"kjv" : "figure, idol, image"
	},
	"H5567" : {
		"lemma" : "סָמַן",
		"xlit" : "çâman",
		"pron" : "saw-man'",
		"derivation" : "a primitive root",
		"def" : "to designate",
		"kjv" : "appointed"
	},
	"H5568" : {
		"lemma" : "סָמַר",
		"xlit" : "çâmar",
		"pron" : "saw-mar'",
		"derivation" : "a primitive root",
		"def" : "to be erect, i.e. bristle as hair",
		"kjv" : "stand up, tremble"
	},
	"H5569" : {
		"lemma" : "סָמָר",
		"xlit" : "çâmâr",
		"pron" : "saw-mar'",
		"derivation" : "from H5568 (סָמַר)",
		"def" : "bristling, i.e. shaggy",
		"kjv" : "rough"
	},
	"H5570" : {
		"lemma" : "סְנָאָה",
		"xlit" : "Çᵉnâʼâh",
		"pron" : "sen-aw-aw'",
		"derivation" : "from an unused root meaning to prick; thorny",
		"def" : "Senaah, a place in Palestine",
		"kjv" : "Senaah, Hassenaah (with the article)"
	},
	"H5571" : {
		"lemma" : "סַנְבַלַּט",
		"xlit" : "Çanballaṭ",
		"pron" : "san-bal-lat'",
		"derivation" : "of foreign origin",
		"def" : "Sanballat, a Persian satrap of Samaria",
		"kjv" : "Sanballat"
	},
	"H5572" : {
		"lemma" : "סְנֶה",
		"xlit" : "çᵉneh",
		"pron" : "sen-eh'",
		"derivation" : "from an unused root meaning to prick",
		"def" : "a bramble",
		"kjv" : "bush"
	},
	"H5573" : {
		"lemma" : "סֶנֶה",
		"xlit" : "Çeneh",
		"pron" : "seh-neh'",
		"derivation" : "the same as H5572 (סְנֶה); thorn",
		"def" : "Seneh, a crag in Palestine",
		"kjv" : "Seneh"
	},
	"H5574" : {
		"lemma" : "סְנוּאָה",
		"xlit" : "Çᵉnûwʼâh",
		"pron" : "sen-oo-aw'",
		"derivation" : "or סְנֻאָה from the same as H5570 (סְנָאָה)",
		"def" : "pointed; (used with the article as a proper name) Senuah, the name of two Israelites",
		"kjv" : "Hasenuah (including the art), Senuah"
	},
	"H5575" : {
		"lemma" : "סַנְוֵר",
		"xlit" : "çanvêr",
		"pron" : "san-vare'",
		"derivation" : "of uncertain derivation",
		"def" : "(in plural) blindness",
		"kjv" : "blindness"
	},
	"H5576" : {
		"lemma" : "סַנְחֵרִיב",
		"xlit" : "Çanchêrîyb",
		"pron" : "san-khay-reeb'",
		"derivation" : "of foreign origin",
		"def" : "Sancherib, an Assyrian king",
		"kjv" : "Sennacherib"
	},
	"H5577" : {
		"lemma" : "סַנְסִן",
		"xlit" : "çançin",
		"pron" : "san-seen'",
		"derivation" : "from an unused root meaning to be pointed",
		"def" : "a twig (as tapering)",
		"kjv" : "bough"
	},
	"H5578" : {
		"lemma" : "סַנְסַנָּה",
		"xlit" : "Çançannâh",
		"pron" : "san-san-naw'",
		"derivation" : "feminine of a form of H5577 (סַנְסִן); a bough",
		"def" : "Sansannah, a place in Palestine",
		"kjv" : "Sansannah"
	},
	"H5579" : {
		"lemma" : "סְנַפִּיר",
		"xlit" : "çᵉnappîyr",
		"pron" : "sen-ap-peer'",
		"derivation" : "of uncertain derivation",
		"def" : "a fin (collectively)",
		"kjv" : "fins"
	},
	"H5580" : {
		"lemma" : "סָס",
		"xlit" : "çâç",
		"pron" : "sawce",
		"derivation" : "from the same as H5483 (סוּס)",
		"def" : "a moth (from the agility of the fly)",
		"kjv" : "moth"
	},
	"H5581" : {
		"lemma" : "סִסְמַי",
		"xlit" : "Çiçmay",
		"pron" : "sis-mah'-ee",
		"derivation" : "of uncertain derivation",
		"def" : "Sismai, an Israelite",
		"kjv" : "Sisamai"
	},
	"H5582" : {
		"lemma" : "סָעַד",
		"xlit" : "çâʻad",
		"pron" : "saw-ad'",
		"derivation" : "a primitive root",
		"def" : "to suport (mostly figurative)",
		"kjv" : "comfort, establish, hold up, refresh self, strengthen, be upholden"
	},
	"H5583" : {
		"lemma" : "סְעַד",
		"xlit" : "çᵉʻad",
		"pron" : "seh-ad'",
		"derivation" : "(Aramaic) corresponding to H5582 (סָעַד)",
		"def" : "to aid",
		"kjv" : "helping"
	},
	"H5584" : {
		"lemma" : "סָעָה",
		"xlit" : "çâʻâh",
		"pron" : "saw-aw'",
		"derivation" : "a primitive root",
		"def" : "to rush",
		"kjv" : "storm"
	},
	"H5585" : {
		"lemma" : "סָעִיף",
		"xlit" : "çâʻîyph",
		"pron" : "saw-eef'",
		"derivation" : "from H5586 (סָעַף)",
		"def" : "a fissure (of rocks); also a bough (as subdivided)",
		"kjv" : "(outmost) branch, clift, top"
	},
	"H5586" : {
		"lemma" : "סָעַף",
		"xlit" : "çâʻaph",
		"pron" : "saw-af'",
		"derivation" : "a primitive root; properly, to divide up; but used only as denominative from H5585 (סָעִיף)",
		"def" : "to disbranch (a tree)",
		"kjv" : "top"
	},
	"H5587" : {
		"lemma" : "סָעִף",
		"xlit" : "çâʻiph",
		"pron" : "saw-eef'",
		"derivation" : "or שָׂעִף; from H5586 (סָעַף)",
		"def" : "divided (in mind), i.e. (abstractly) a sentiment",
		"kjv" : "opinion"
	},
	"H5588" : {
		"lemma" : "סֵעֵף",
		"xlit" : "çêʻêph",
		"pron" : "say-afe'",
		"derivation" : "from H5586 (סָעַף)",
		"def" : "divided (in mind), i.e. (concretely) a skeptic",
		"kjv" : "thought"
	},
	"H5589" : {
		"lemma" : "סְעַפָּה",
		"xlit" : "çᵉʻappâh",
		"pron" : "seh-ap-paw'",
		"derivation" : "feminine of H5585 (סָעִיף)",
		"def" : "a twig",
		"kjv" : "bough, branch. Compare H5634 (סַרְעַפָּה)"
	},
	"H5590" : {
		"lemma" : "סָעַר",
		"xlit" : "çâʻar",
		"pron" : "saw-ar'",
		"derivation" : "a primitive root",
		"def" : "to rush upon; by implication, to toss (transitive or intransitive, literal or figurative)",
		"kjv" : "be (toss with) tempest(-uous), be sore, troubled, come out as a (drive with the, scatter with a) whirlwind"
	},
	"H5591" : {
		"lemma" : "סַעַר",
		"xlit" : "çaʻar",
		"pron" : "sah'-ar",
		"derivation" : "or (feminine) סְעָרָה; from H5590 (סָעַר)",
		"def" : "a hurricane",
		"kjv" : "storm(-y), tempest, whirlwind"
	},
	"H5592" : {
		"lemma" : "סַף",
		"xlit" : "çaph",
		"pron" : "saf",
		"derivation" : "from H5605 (סָפַף), in its original sense of containing",
		"def" : "a vestibule (as a limit); also a dish (for holding blood or wine)",
		"kjv" : "bason, bowl, cup, door (post), gate, post, threshold"
	},
	"H5593" : {
		"lemma" : "סַף",
		"xlit" : "Çaph",
		"pron" : "saf",
		"derivation" : "the same as H5592 (סַף)",
		"def" : "Saph, a Philistine",
		"kjv" : "Saph. Compare H5598 (סִפַּי)"
	},
	"H5594" : {
		"lemma" : "סָפַד",
		"xlit" : "çâphad",
		"pron" : "saw-fad'",
		"derivation" : "a primitive root",
		"def" : "properly, to tear the hair and beat the breasts (as Orientals do in grief); generally to lament; by implication, to wail",
		"kjv" : "lament, mourn(-er), wail"
	},
	"H5595" : {
		"lemma" : "סָפָה",
		"xlit" : "çâphâh",
		"pron" : "saw-faw'",
		"derivation" : "a primitive root",
		"def" : "properly, to scrape (literally, to shave; but usually figuratively) together (i.e. to accumulate or increase) or away (i.e. to scatter, remove, or ruin; intransitively, to perish)",
		"kjv" : "add, augment, consume, destroy, heap, join, perish, put"
	},
	"H5596" : {
		"lemma" : "סָפַח",
		"xlit" : "çâphach",
		"pron" : "saw-fakh'",
		"derivation" : "or שָׂפַח; (Isaiah 3:17), a primitive root",
		"def" : "properly, to scrape out, but in certain peculiar senses (of removal or association)",
		"kjv" : "abiding, gather together, cleave, smite with the scab"
	},
	"H5597" : {
		"lemma" : "סַפַּחַת",
		"xlit" : "çappachath",
		"pron" : "sap-pakh'-ath",
		"derivation" : "from H5596 (סָפַח)",
		"def" : "the mange (as making the hair fall off)",
		"kjv" : "scab"
	},
	"H5598" : {
		"lemma" : "סִפַּי",
		"xlit" : "Çippay",
		"pron" : "sip-pah'-ee",
		"derivation" : "from H5592 (סַף); bason-like",
		"def" : "Sippai, a Philistine",
		"kjv" : "Sippai. Compare H5593 (סַף)"
	},
	"H5599" : {
		"lemma" : "סָפִיחַ",
		"xlit" : "çâphîyach",
		"pron" : "saw-fee'-akh",
		"derivation" : "from H5596 (סָפַח)",
		"def" : "something (spontaneously) falling off, i.e. a self-sown crop; figuratively, a freshet",
		"kjv" : "(such) things as (which) grow (of themselves), which groweth of its own accord (itself)"
	},
	"H5600" : {
		"lemma" : "סְפִינָה",
		"xlit" : "çᵉphîynâh",
		"pron" : "sef-ee-naw'",
		"derivation" : "from H5603 (סָפַן)",
		"def" : "a (sea-going) vessel (as ceiled with a deck)",
		"kjv" : "ship"
	},
	"H5601" : {
		"lemma" : "סַפִּיר",
		"xlit" : "çappîyr",
		"pron" : "sap-peer'",
		"derivation" : "from H5608 (סָפַר)",
		"def" : "a gem (perhaps used for scratching other substances), probably the sapphire",
		"kjv" : "sapphire"
	},
	"H5602" : {
		"lemma" : "סֵפֶל",
		"xlit" : "çêphel",
		"pron" : "say'-fel",
		"derivation" : "from an unused root meaning to depress",
		"def" : "a basin (as deepened out)",
		"kjv" : "bowl, dish"
	},
	"H5603" : {
		"lemma" : "סָפַן",
		"xlit" : "çâphan",
		"pron" : "saw-fan'",
		"derivation" : "a primitive root",
		"def" : "to hide by covering; specifically, to roof (passive participle as noun, a roof) or wainscot; figuratively, to reserve",
		"kjv" : "cieled, cover, seated"
	},
	"H5604" : {
		"lemma" : "סִפֻּן",
		"xlit" : "çippun",
		"pron" : "sip-poon'",
		"derivation" : "from H5603 (סָפַן)",
		"def" : "a wainscot",
		"kjv" : "cieling"
	},
	"H5605" : {
		"lemma" : "סָפַף",
		"xlit" : "çâphaph",
		"pron" : "saw-faf'",
		"derivation" : "a primitive root; properly, to snatch away, i.e. terminate; but used only as denominative from H5592 (סַף) (in the sense of a vestibule)",
		"def" : "to wait at the threshold",
		"kjv" : "be a doorkeeper"
	},
	"H5606" : {
		"lemma" : "סָפַק",
		"xlit" : "çâphaq",
		"pron" : "saw-fak'",
		"derivation" : "or שָׂפַק; (1 Kings 20:10; Job 27:23; Isaiah 2:6), a primitive root",
		"def" : "to clap the hands (in token of compact, derision, grief, indignation, or punishment); by implication of satisfaction, to be enough; by implication of excess, to vomit",
		"kjv" : "clap, smite, strike, suffice, wallow"
	},
	"H5607" : {
		"lemma" : "סֵפֶק",
		"xlit" : "çêpheq",
		"pron" : "say'-fek",
		"derivation" : "or שֶׂפֶק; (Job 20:22; Job 36:18), from H5606 (סָפַק)",
		"def" : "chastisement; also satiety",
		"kjv" : "stroke, sufficiency"
	},
	"H5608" : {
		"lemma" : "סָפַר",
		"xlit" : "çâphar",
		"pron" : "saw-far'",
		"derivation" : "a primitive root",
		"def" : "properly, to score with a mark as a tally or record, i.e. (by implication) to inscribe, and also to enumerate; intensively, to recount, i.e. celebrate",
		"kjv" : "commune, (ac-) count; declare, number, [phrase] penknife, reckon, scribe, shew forth, speak, talk, tell (out), writer"
	},
	"H5609" : {
		"lemma" : "סְפַר",
		"xlit" : "çᵉphar",
		"pron" : "sef-ar'",
		"derivation" : "(Aramaic) from a root corresponding to H5608 (סָפַר)",
		"def" : "a book",
		"kjv" : "book, roll"
	},
	"H5610" : {
		"lemma" : "סְפָר",
		"xlit" : "çᵉphâr",
		"pron" : "sef-awr'",
		"derivation" : "from H5608 (סָפַר)",
		"def" : "a census",
		"kjv" : "numbering"
	},
	"H5611" : {
		"lemma" : "סְפָר",
		"xlit" : "Çᵉphâr",
		"pron" : "sef-awr'",
		"derivation" : "the same as H5610 (סְפָר)",
		"def" : "Sephar, a place in Arabia",
		"kjv" : "Sephar"
	},
	"H5612" : {
		"lemma" : "סֵפֶר",
		"xlit" : "çêpher",
		"pron" : "say'-fer",
		"derivation" : "or (feminine) סִפְרָה; (Psalm 56:8 (H9 (אֲבֵדָה))), from H5608 (סָפַר)",
		"def" : "properly, writing (the art or a document); by implication, a book",
		"kjv" : "bill, book, evidence, [idiom] learn(-ed) (-ing), letter, register, scroll"
	},
	"H5613" : {
		"lemma" : "סָפֵר",
		"xlit" : "çâphêr",
		"pron" : "saw-fare'",
		"derivation" : "(Aramaic) from the same as H5609 (סְפַר)",
		"def" : "a scribe (secular or sacred)",
		"kjv" : "scribe"
	},
	"H5614" : {
		"lemma" : "סְפָרָד",
		"xlit" : "Çᵉphârâd",
		"pron" : "sef-aw-rawd'",
		"derivation" : "of foreign derivation",
		"def" : "Sepharad, a region of Assyria",
		"kjv" : "Sepharad"
	},
	"H5615" : {
		"lemma" : "סְפֹרָה",
		"xlit" : "çᵉphôrâh",
		"pron" : "sef-o-raw'",
		"derivation" : "from H5608 (סָפַר)",
		"def" : "a numeration",
		"kjv" : "number"
	},
	"H5616" : {
		"lemma" : "סְפַרְוִי",
		"xlit" : "Çᵉpharvîy",
		"pron" : "sef-ar-vee'",
		"derivation" : "patrial from H5617 (סְפַרְוַיִם)",
		"def" : "a Sepharvite or inhabitant of Sepharvain",
		"kjv" : "Sepharvite"
	},
	"H5617" : {
		"lemma" : "סְפַרְוַיִם",
		"xlit" : "Çᵉpharvayim",
		"pron" : "sef-ar-vah'-yim",
		"derivation" : "(dual)",
		"def" : "Sepharvites"
	},
	"H5618" : {
		"lemma" : "סֹפֶרֶת",
		"xlit" : "Çôphereth",
		"pron" : "so-feh'-reth",
		"derivation" : "feminine active participle of H5608 (סָפַר); a scribe (properly, female)",
		"def" : "Sophereth, a temple servant",
		"kjv" : "Sophereth"
	},
	"H5619" : {
		"lemma" : "סָקַל",
		"xlit" : "çâqal",
		"pron" : "saw-kal'",
		"derivation" : "a primitive root",
		"def" : "properly, to be weighty; but used only in the sense of lapidation or its contrary (as if a delapidation)",
		"kjv" : "(cast, gather out, throw) stone(-s), [idiom] surely"
	},
	"H5620" : {
		"lemma" : "סַר",
		"xlit" : "çar",
		"pron" : "sar",
		"derivation" : "contracted from H5637 (סָרַר)",
		"def" : "peevish",
		"kjv" : "heavy, sad"
	},
	"H5621" : {
		"lemma" : "סָרָב",
		"xlit" : "çârâb",
		"pron" : "saw-rawb'",
		"derivation" : "from an unused root meaning to sting",
		"def" : "a thistle",
		"kjv" : "brier"
	},
	"H5622" : {
		"lemma" : "סַרְבַּל",
		"xlit" : "çarbal",
		"pron" : "sar-bal'",
		"derivation" : "(Aramaic) of uncertain derivation",
		"def" : "a cloak",
		"kjv" : "coat"
	},
	"H5623" : {
		"lemma" : "סַרְגּוֹן",
		"xlit" : "Çargôwn",
		"pron" : "sar-gone'",
		"derivation" : "of foreign derivation",
		"def" : "Sargon, an Assyrian king",
		"kjv" : "Sargon"
	},
	"H5624" : {
		"lemma" : "סֶרֶד",
		"xlit" : "Çered",
		"pron" : "seh'-red",
		"derivation" : "from a primitive root meaning to tremble; trembling",
		"def" : "Sered, an Israelite",
		"kjv" : "Sered"
	},
	"H5625" : {
		"lemma" : "סַרְדִּי",
		"xlit" : "Çardîy",
		"pron" : "sar-dee'",
		"derivation" : "patron from H5624 (סֶרֶד)",
		"def" : "a Seredite (collectively) or descendants of Sered",
		"kjv" : "Sardites"
	},
	"H5626" : {
		"lemma" : "סִרָה",
		"xlit" : "Çirâh",
		"pron" : "see-raw'",
		"derivation" : "from H5493 (סוּר); departure",
		"def" : "Sirah, a cistern so-called",
		"kjv" : "Sirah. See also H5518 (סִיר)"
	},
	"H5627" : {
		"lemma" : "סָרָה",
		"xlit" : "çârâh",
		"pron" : "saw-raw'",
		"derivation" : "from H5493 (סוּר)",
		"def" : "apostasy, crime; figuratively, remission",
		"kjv" : "[idiom] continual, rebellion, revolt(-ed), turn away, wrong"
	},
	"H5628" : {
		"lemma" : "סָרַח",
		"xlit" : "çârach",
		"pron" : "saw-rakh'",
		"derivation" : "a primitive root",
		"def" : "to extend (even to excess)",
		"kjv" : "exceeding, hand, spread, stretch self, banish"
	},
	"H5629" : {
		"lemma" : "סֶרַח",
		"xlit" : "çerach",
		"pron" : "seh'-rakh",
		"derivation" : "from H5628 (סָרַח)",
		"def" : "a redundancy",
		"kjv" : "remnant"
	},
	"H5630" : {
		"lemma" : "סִרְיֹן",
		"xlit" : "çiryôn",
		"pron" : "sir-yone'",
		"derivation" : "for H8302 (שִׁרְיוֹן)",
		"def" : "a coat of mail",
		"kjv" : "brigandine"
	},
	"H5631" : {
		"lemma" : "סָרִיס",
		"xlit" : "çârîyç",
		"pron" : "saw-reece'",
		"derivation" : "or סָרִס; from an unused root meaning to castrate",
		"def" : "a eunuch; by implication, valet (especially of the female apartments), and thus, a minister of state",
		"kjv" : "chamberlain, eunuch, officer. Compare H7249 (רַב־סָרִיס)"
	},
	"H5632" : {
		"lemma" : "סָרֵךְ",
		"xlit" : "çârêk",
		"pron" : "saw-rake'",
		"derivation" : "(Aramaic) of foreign origin",
		"def" : "an emir",
		"kjv" : "president"
	},
	"H5633" : {
		"lemma" : "סֶרֶן",
		"xlit" : "çeren",
		"pron" : "seh'-ren",
		"derivation" : "from an unused root of uncertain meaning",
		"def" : "an axle; figuratively, a peer",
		"kjv" : "lord, plate"
	},
	"H5634" : {
		"lemma" : "סַרְעַפָּה",
		"xlit" : "çarʻappâh",
		"pron" : "sar-ap-paw'",
		"derivation" : "for H5589 (סְעַפָּה)",
		"def" : "a twig",
		"kjv" : "bough"
	},
	"H5635" : {
		"lemma" : "סָרַף",
		"xlit" : "çâraph",
		"pron" : "saw-raf'",
		"derivation" : "a primitive root",
		"def" : "to cremate, i.e. to be (near) of kin (such being privileged to kindle the pyre)",
		"kjv" : "burn"
	},
	"H5636" : {
		"lemma" : "סַרְפָּד",
		"xlit" : "çarpâd",
		"pron" : "sar-pawd'",
		"derivation" : "from H5635 (סָרַף)",
		"def" : "a nettle (as stinging like a burn)",
		"kjv" : "brier"
	},
	"H5637" : {
		"lemma" : "סָרַר",
		"xlit" : "çârar",
		"pron" : "saw-rar'",
		"derivation" : "a primitive root",
		"def" : "to turn away, i.e. (morally) be refractory",
		"kjv" : "[idiom] away, backsliding, rebellious, revolter(-ing), slide back, stubborn, withdrew"
	},
	"H5638" : {
		"lemma" : "סְתָו",
		"xlit" : "çᵉthâv",
		"pron" : "seth-awv'",
		"derivation" : "from an unused root meaning to hide",
		"def" : "winter (as the dark season)",
		"kjv" : "winter"
	},
	"H5639" : {
		"lemma" : "סְתוּר",
		"xlit" : "Çᵉthûwr",
		"pron" : "seth-oor'",
		"derivation" : "from H5641 (סָתַר); hidden",
		"def" : "Sethur, an Israelite",
		"kjv" : "Sethur"
	},
	"H5640" : {
		"lemma" : "סָתַם",
		"xlit" : "çâtham",
		"pron" : "saw-tham'",
		"derivation" : "or שָׂתַם; (Numbers 24:15), a primitive root",
		"def" : "to stop up; by implication, to repair; figuratively, to keep secret",
		"kjv" : "closed up, hidden, secret, shut out (up), stop"
	},
	"H5641" : {
		"lemma" : "סָתַר",
		"xlit" : "çâthar",
		"pron" : "saw-thar'",
		"derivation" : "a primitive root",
		"def" : "to hide (by covering), literally or figuratively",
		"kjv" : "be absent, keep close, conceal, hide (self), (keep) secret, [idiom] surely"
	},
	"H5642" : {
		"lemma" : "סְתַר",
		"xlit" : "çᵉthar",
		"pron" : "seth-ar'",
		"derivation" : "(Aramaic) corresponding to H5641 (סָתַר)",
		"def" : "to conceal; figuratively, to demolish",
		"kjv" : "destroy, secret thing"
	},
	"H5643" : {
		"lemma" : "סֵתֶר",
		"xlit" : "çêther",
		"pron" : "say'-ther",
		"derivation" : "or (feminine) סִתְרָה; (Deuteronomy [32:38), from H5641 (סָתַר)",
		"def" : "a cover (in a good or a bad, a literal or a figurative sense)",
		"kjv" : "backbiting, covering, covert, [idiom] disguise(-th), hiding place, privily, protection, secret(-ly, place)"
	},
	"H5644" : {
		"lemma" : "סִתְרִי",
		"xlit" : "Çithrîy",
		"pron" : "sith-ree'",
		"derivation" : "from H5643 (סֵתֶר); protective",
		"def" : "Sithri, an Israelite",
		"kjv" : "Zithri. ["
	},
	"H5645" : {
		"lemma" : "עָב",
		"xlit" : "ʻâb",
		"pron" : "awb",
		"derivation" : "(masculine and feminine); from H5743 (עוּב)",
		"def" : "properly, an envelope, i.e. darkness (or density, 2 Chronicles 4:17); specifically, a (scud) cloud; also a copse",
		"kjv" : "clay, (thick) cloud, [idiom] thick, thicket. Compare H5672 (עֲבִי)"
	},
	"H5646" : {
		"lemma" : "עָב",
		"xlit" : "ʻâb",
		"pron" : "awb",
		"derivation" : "or עֹב; from an unused root meaning to cover; properly, equivalent to H5645 (עָב); but used only as an architectural term",
		"def" : "an architrave (as shading the pillars)",
		"kjv" : "thick (beam, plant)"
	},
	"H5647" : {
		"lemma" : "עָבַד",
		"xlit" : "ʻâbad",
		"pron" : "aw-bad'",
		"derivation" : "a primitive root",
		"def" : "to work (in any sense); by implication, to serve, till, (causatively) enslave, etc.",
		"kjv" : "[idiom] be, keep in bondage, be bondmen, bond-service, compel, do, dress, ear, execute, [phrase] husbandman, keep, labour(-ing man, bring to pass, (cause to, make to) serve(-ing, self), (be, become) servant(-s), do (use) service, till(-er), transgress (from margin), (set a) work, be wrought, worshipper"
	},
	"H5648" : {
		"lemma" : "עֲבַד",
		"xlit" : "ʻăbad",
		"pron" : "ab-bad'",
		"derivation" : "(Aramaic) corresponding to H5647 (עָבַד)",
		"def" : "to do, make, prepare, keep, etc.",
		"kjv" : "[idiom] cut, do, execute, go on, make, move, work"
	},
	"H5649" : {
		"lemma" : "עֲבַד",
		"xlit" : "ʻăbad",
		"pron" : "ab-bad'",
		"derivation" : "(Aramaic) from H5648 (עֲבַד)",
		"def" : "a servant",
		"kjv" : "servant"
	},
	"H5650" : {
		"lemma" : "עֶבֶד",
		"xlit" : "ʻebed",
		"pron" : "eh'-bed",
		"derivation" : "from H5647 (עָבַד)",
		"def" : "a servant",
		"kjv" : "[idiom] bondage, bondman, (bond-) servant, (man-) servant"
	},
	"H5651" : {
		"lemma" : "עֶבֶד",
		"xlit" : "ʻEbed",
		"pron" : "eh'-bed",
		"derivation" : "the same as H5650 (עֶבֶד)",
		"def" : "Ebed, the name of two Israelites",
		"kjv" : "Ebed"
	},
	"H5652" : {
		"lemma" : "עֲבָד",
		"xlit" : "ʻăbâd",
		"pron" : "ab-awd'",
		"derivation" : "from H5647 (עָבַד)",
		"def" : "a deed",
		"kjv" : "work"
	},
	"H5653" : {
		"lemma" : "עַבְדָּא",
		"xlit" : "ʻAbdâʼ",
		"pron" : "ab-daw'",
		"derivation" : "from H5647 (עָבַד); work",
		"def" : "Abda, the name of two Israelites",
		"kjv" : "Abda"
	},
	"H5654" : {
		"lemma" : "עֹבֵד אֱדוֹם",
		"xlit" : "ʻÔbêd ʼĔdôwm",
		"pron" : "o-bade' ed-ome'",
		"derivation" : "from the active participle of H5647 (עָבַד) and H123 (אֱדֹם); worker of Edom",
		"def" : "Obed-Edom, the name of five Israelites",
		"kjv" : "Obed-edom"
	},
	"H5655" : {
		"lemma" : "עַבְדְּאֵל",
		"xlit" : "ʻAbdᵉʼêl",
		"pron" : "ab-deh-ale'",
		"derivation" : "from H5647 (עָבַד) and H410 (אֵל); serving God",
		"def" : "Abdeel, an Israelite",
		"kjv" : "Abdeel. Compare H5661 (עַבְדִיאֵל)"
	},
	"H5656" : {
		"lemma" : "עֲבֹדָה",
		"xlit" : "ʻăbôdâh",
		"pron" : "ab-o-daw'",
		"derivation" : "or עֲבוֹדָה; from H5647 (עָבַד)",
		"def" : "work of any kind",
		"kjv" : "act, bondage, [phrase] bondservant, effect, labour, ministering(-try), office, service(-ile, -itude), tillage, use, work, [idiom] wrought"
	},
	"H5657" : {
		"lemma" : "עֲבֻדָּה",
		"xlit" : "ʻăbuddâh",
		"pron" : "ab-ood-daw'",
		"derivation" : "passive participle of H5647 (עָבַד)",
		"def" : "something wrought, i.e. (concretely) service",
		"kjv" : "household, store of servants"
	},
	"H5658" : {
		"lemma" : "עַבְדוֹן",
		"xlit" : "ʻAbdôwn",
		"pron" : "ab-dohn'",
		"derivation" : "from H5647 (עָבַד); servitude",
		"def" : "Abdon, the name of a place in Palestine and of four Israelites",
		"kjv" : "Abdon. Compare H5683 (עֶבְרֹן)"
	},
	"H5659" : {
		"lemma" : "עַבְדוּת",
		"xlit" : "ʻabdûwth",
		"pron" : "ab-dooth'",
		"derivation" : "from H5647 (עָבַד)",
		"def" : "servitude",
		"kjv" : "bondage"
	},
	"H5660" : {
		"lemma" : "עַבְדִּי",
		"xlit" : "ʻAbdîy",
		"pron" : "ab-dee'",
		"derivation" : "from H5647 (עָבַד); serviceable",
		"def" : "Abdi, the name of two Israelites",
		"kjv" : "Abdi"
	},
	"H5661" : {
		"lemma" : "עַבְדִיאֵל",
		"xlit" : "ʻAbdîyʼêl",
		"pron" : "ab-dee-ale'",
		"derivation" : "from H5650 (עֶבֶד) and H410 (אֵל); servant of God",
		"def" : "Abdiel, an Israelite",
		"kjv" : "Abdiel. Compare H5655 (עַבְדְּאֵל)"
	},
	"H5662" : {
		"lemma" : "עֹבַדְיָה",
		"xlit" : "ʻÔbadyâh",
		"pron" : "o-bad-yaw'",
		"derivation" : "or עֹבַדְיָהוּ; active participle of H5647 (עָבַד) and H3050 (יָהּ); serving Jah",
		"def" : "Obadjah, the name of thirteen Israelites",
		"kjv" : "Obadiah"
	},
	"H5663" : {
		"lemma" : "עֶבֶד מֶלֶךְ",
		"xlit" : "ʻEbed Melek",
		"pron" : "eh'-bed meh'-lek",
		"derivation" : "from H5650 (עֶבֶד) and H4428 (מֶלֶךְ); servant of a king",
		"def" : "Ebed-Melek, a eunuch of Zedekeah",
		"kjv" : "Ebed-melech"
	},
	"H5664" : {
		"lemma" : "עֲבֵד נְגוֹ",
		"xlit" : "ʻĂbêd Nᵉgôw",
		"pron" : "ab-ade' neg-o'",
		"derivation" : "the same as H5665 (עֲבֵד נְגוֹא)",
		"def" : "Abed-Nego, the Babylonian name of one of Daniel's companions",
		"kjv" : "Abed-nego"
	},
	"H5665" : {
		"lemma" : "עֲבֵד נְגוֹא",
		"xlit" : "ʻĂbêd Nᵉgôwʼ",
		"pron" : "ab-ade' neg-o'",
		"derivation" : "(Aramaic) of foreign origin",
		"def" : "Abed-Nego, the name of Azariah",
		"kjv" : "Abed-nego"
	},
	"H5666" : {
		"lemma" : "עָבָה",
		"xlit" : "ʻâbâh",
		"pron" : "aw-baw'",
		"derivation" : "a primitive root",
		"def" : "to be dense",
		"kjv" : "be (grow) thick(-er)"
	},
	"H5667" : {
		"lemma" : "עֲבוֹט",
		"xlit" : "ʻăbôwṭ",
		"pron" : "ab-ote'",
		"derivation" : "or עֲבֹט; from H5670 (עָבַט)",
		"def" : "a pawn",
		"kjv" : "pledge"
	},
	"H5668" : {
		"lemma" : "עָבוּר",
		"xlit" : "ʻâbûwr",
		"pron" : "aw-boor'",
		"derivation" : "or עָבֻר; passive participle of H5674 (עָבַר)",
		"def" : "properly, crossed, i.e. (abstractly) transit; used only adverbially, on account of, in order that",
		"kjv" : "because of, for (...'s sake), (intent) that, to"
	},
	"H5669" : {
		"lemma" : "עָבוּר",
		"xlit" : "ʻâbûwr",
		"pron" : "aw-boor'",
		"derivation" : "the same as H5668 (עָבוּר)",
		"def" : "passed, i.e. kept over; used only of stored grain",
		"kjv" : "old corn"
	},
	"H5670" : {
		"lemma" : "עָבַט",
		"xlit" : "ʻâbaṭ",
		"pron" : "aw-bat'",
		"derivation" : "a primitive root",
		"def" : "to pawn; causatively, to lend (on security); figuratively, to entangle",
		"kjv" : "borrow, break (ranks), fetch (a pledge), lend, [idiom] surely"
	},
	"H5671" : {
		"lemma" : "עַבְטִיט",
		"xlit" : "ʻabṭîyṭ",
		"pron" : "ab-teet'",
		"derivation" : "from H5670 (עָבַט)",
		"def" : "something pledged, i.e. (collectively) pawned goods",
		"kjv" : "thick clay (by a false etym.)"
	},
	"H5672" : {
		"lemma" : "עֲבִי",
		"xlit" : "ʻăbîy",
		"pron" : "ab-ee'",
		"derivation" : "or עֳבִי]; from H5666 (עָבָה)",
		"def" : "density, i.e. depth or width",
		"kjv" : "thick(-ness). Compare H5645 (עָב)"
	},
	"H5673" : {
		"lemma" : "עֲבִידָה",
		"xlit" : "ʻăbîydâh",
		"pron" : "ab-ee-daw'",
		"derivation" : "(Aramaic) from H5648 (עֲבַד)",
		"def" : "labor or business",
		"kjv" : "affairs, service, work"
	},
	"H5674" : {
		"lemma" : "עָבַר",
		"xlit" : "ʻâbar",
		"pron" : "aw-bar'",
		"derivation" : "a primitive root",
		"def" : "to cross over; used very widely of any transition (literal or figurative; transitive, intransitive, intensive, causative); specifically, to cover (in copulation)",
		"kjv" : "alienate, alter, [idiom] at all, beyond, bring (over, through), carry over, (over-) come (on, over), conduct (over), convey over, current, deliver, do away, enter, escape, fail, gender, get over, (make) go (away, beyond, by, forth, his way, in, on, over, through), have away (more), lay, meddle, overrun, make partition, (cause to, give, make to, over) pass(-age, along, away, beyond, by, -enger, on, out, over, through), (cause to, make) [phrase] proclaim(-amation), perish, provoke to anger, put away, rage, [phrase] raiser of taxes, remove, send over, set apart, [phrase] shave, cause to (make) sound, [idiom] speedily, [idiom] sweet smelling, take (away), (make to) transgress(-or), translate, turn away, (way-) faring man, be wrath"
	},
	"H5675" : {
		"lemma" : "עֲבַר",
		"xlit" : "ʻăbar",
		"pron" : "ab-ar'",
		"derivation" : "(Aramaic) corresponding to H5676 (עֵבֶר)",
		"def" : "{properly, a region across; but used only adverbially (with or without a preposition) on the opposite side (especially of the Jordan; ususally meaning the east)}",
		"kjv" : "beyond, this side"
	},
	"H5676" : {
		"lemma" : "עֵבֶר",
		"xlit" : "ʻêber",
		"pron" : "ay'-ber",
		"derivation" : "from H5674 (עָבַר)",
		"def" : "properly, a region across; but used only adverbially (with or without a preposition) on the opposite side (especially of the Jordan; ususally meaning the east)",
		"kjv" : "[idiom] against, beyond, by, [idiom] from, over, passage, quarter, (other, this) side, straight"
	},
	"H5677" : {
		"lemma" : "עֵבֵר",
		"xlit" : "ʻÊbêr",
		"pron" : "ay'-ber",
		"derivation" : "the same as H5676 (עֵבֶר)",
		"def" : "Eber, the name of two patriarchs and four Israelites",
		"kjv" : "Eber, Heber"
	},
	"H5678" : {
		"lemma" : "עֶבְרָה",
		"xlit" : "ʻebrâh",
		"pron" : "eb-raw'",
		"derivation" : "feminine of H5676 (עֵבֶר)",
		"def" : "an outburst of passion",
		"kjv" : "anger, rage, wrath"
	},
	"H5679" : {
		"lemma" : "עֲבָרָה",
		"xlit" : "ʻăbârâh",
		"pron" : "ab-aw-raw'",
		"derivation" : "from H5674 (עָבַר)",
		"def" : "a crossing-place",
		"kjv" : "ferry, plain (from the margin)"
	},
	"H5680" : {
		"lemma" : "עִבְרִי",
		"xlit" : "ʻIbrîy",
		"pron" : "ib-ree'",
		"derivation" : "patronymic from H5677 (עֵבֵר)",
		"def" : "an Eberite (i.e. Hebrew) or descendant of Eber",
		"kjv" : "Hebrew(-ess, woman)"
	},
	"H5681" : {
		"lemma" : "עִבְרִי",
		"xlit" : "ʻIbrîy",
		"pron" : "ib-ree'",
		"derivation" : "the same as H5680 (עִבְרִי)",
		"def" : "Ibri, an Israelite",
		"kjv" : "Ibri"
	},
	"H5682" : {
		"lemma" : "עֲבָרִים",
		"xlit" : "ʻĂbârîym",
		"pron" : "ab-aw-reem'",
		"derivation" : "plural of H5676 (עֵבֶר); regions beyond",
		"def" : "Abarim, a place in Palestine",
		"kjv" : "Abarim, passages"
	},
	"H5683" : {
		"lemma" : "עֶבְרֹן",
		"xlit" : "ʻEbrôn",
		"pron" : "eb-rone'",
		"derivation" : "from H5676 (עֵבֶר); transitional",
		"def" : "Ebron, a place in Palestine",
		"kjv" : "Hebron. Perhaps a clerical error for H5658 (עַבְדוֹן)"
	},
	"H5684" : {
		"lemma" : "עֶבְרֹנָה",
		"xlit" : "ʻEbrônâh",
		"pron" : "eb-raw-naw'",
		"derivation" : "feminine of H5683 (עֶבְרֹן)",
		"def" : "Ebronah, place in the Desert",
		"kjv" : "Ebronah"
	},
	"H5685" : {
		"lemma" : "עָבַשׁ",
		"xlit" : "ʻâbash",
		"pron" : "aw-bash'",
		"derivation" : "a primitive root",
		"def" : "to dry up",
		"kjv" : "be rotten"
	},
	"H5686" : {
		"lemma" : "עָבַת",
		"xlit" : "ʻâbath",
		"pron" : "aw-bath'",
		"derivation" : "a primitive root",
		"def" : "to interlace, i.e. (figuratively) to pervert",
		"kjv" : "wrap up"
	},
	"H5687" : {
		"lemma" : "עָבֹת",
		"xlit" : "ʻâbôth",
		"pron" : "aw-both'",
		"derivation" : "or עָבוֹת; from H5686 (עָבַת)",
		"def" : "intwined, i.e. dense",
		"kjv" : "thick"
	},
	"H5688" : {
		"lemma" : "עֲבֹת",
		"xlit" : "ʻăbôth",
		"pron" : "ab-oth'",
		"derivation" : "or עֲבוֹת; or (feminine) עֲבֹתָה; the same as H5687 (עָבֹת)",
		"def" : "something intwined, i.e. a string, wreath or foliage",
		"kjv" : "band, cord, rope, thick bough (branch), wreathen (chain)"
	},
	"H5689" : {
		"lemma" : "עֲגַב",
		"xlit" : "ʻăgab",
		"pron" : "aw-gab'",
		"derivation" : "a primitive root",
		"def" : "to breathe after, i.e. to love (sensually)",
		"kjv" : "dote, lover"
	},
	"H5690" : {
		"lemma" : "עֶגֶב",
		"xlit" : "ʻegeb",
		"pron" : "eh'-gheb",
		"derivation" : "from H5689 (עֲגַב)",
		"def" : "love (concretely), i.e. amative words",
		"kjv" : "much love, very lovely"
	},
	"H5691" : {
		"lemma" : "עֲגָבָה",
		"xlit" : "ʻăgâbâh",
		"pron" : "ag-aw-baw'",
		"derivation" : "from H5689 (עֲגַב)",
		"def" : "love (abstractly), i.e. amorousness",
		"kjv" : "inordinate love"
	},
	"H5692" : {
		"lemma" : "עֻגָּה",
		"xlit" : "ʻuggâh",
		"pron" : "oog-gaw'",
		"derivation" : "from H5746 (עוּג)",
		"def" : "an ash-cake (as round)",
		"kjv" : "cake (upon the hearth)"
	},
	"H5693" : {
		"lemma" : "עָגוּר",
		"xlit" : "ʻâgûwr",
		"pron" : "aw-goor'",
		"derivation" : "passive part (but with active sense) of an unused root meaning to twitter",
		"def" : "probably the swallow",
		"kjv" : "swallow"
	},
	"H5694" : {
		"lemma" : "עָגִיל",
		"xlit" : "ʻâgîyl",
		"pron" : "aw-gheel'",
		"derivation" : "from the same as H5696 (עָגֹל)",
		"def" : "something round, i.e. a ring (for the ears)",
		"kjv" : "earring"
	},
	"H5695" : {
		"lemma" : "עֵגֶל",
		"xlit" : "ʻêgel",
		"pron" : "ay-ghel",
		"derivation" : "from the same as H5696 (עָגֹל)",
		"def" : "a (male) calf (as frisking round), especially one nearly grown (i.e. a steer)",
		"kjv" : "bullock, calf"
	},
	"H5696" : {
		"lemma" : "עָגֹל",
		"xlit" : "ʻâgôl",
		"pron" : "aw-gole'",
		"derivation" : "or עָגוֹל; from an unused root meaning to revolve",
		"def" : "circular",
		"kjv" : "round"
	},
	"H5697" : {
		"lemma" : "עֶגְלָה",
		"xlit" : "ʻeglâh",
		"pron" : "eg-law'",
		"derivation" : "feminine of H5695 (עֵגֶל)",
		"def" : "a (female) calf, especially one nearly grown (i.e. a heifer)",
		"kjv" : "calf, cow, heifer"
	},
	"H5698" : {
		"lemma" : "עֶגְלָה",
		"xlit" : "ʻEglâh",
		"pron" : "eg-law'",
		"derivation" : "the same as H5697 (עֶגְלָה)",
		"def" : "Eglah, a wife of David",
		"kjv" : "Eglah"
	},
	"H5699" : {
		"lemma" : "עֲגָלָה",
		"xlit" : "ʻăgâlâh",
		"pron" : "ag-aw-law'",
		"derivation" : "from the same as H5696 (עָגֹל)",
		"def" : "something revolving, i.e. a wheeled vehicle",
		"kjv" : "cart, chariot, wagon"
	},
	"H5700" : {
		"lemma" : "עֶגְלוֹן",
		"xlit" : "ʻEglôwn",
		"pron" : "eg-lawn'",
		"derivation" : "from H5695 (עֵגֶל); vituline",
		"def" : "Eglon, the name of a place in Palestine and of a Moabitish king",
		"kjv" : "Eglon"
	},
	"H5701" : {
		"lemma" : "עָגַם",
		"xlit" : "ʻâgam",
		"pron" : "aw-gam'",
		"derivation" : "a primitive root",
		"def" : "to be sad",
		"kjv" : "grieve"
	},
	"H5702" : {
		"lemma" : "עָגַן",
		"xlit" : "ʻâgan",
		"pron" : "aw-gan'",
		"derivation" : "a primitive root",
		"def" : "to debar, i.e. from marriage",
		"kjv" : "stay"
	},
	"H5703" : {
		"lemma" : "עַד",
		"xlit" : "ʻad",
		"pron" : "ad",
		"derivation" : "from H5710 (עָדָה)",
		"def" : "properly, a (peremptory) terminus, i.e. (by implication) duration, in the sense of advance or perpetuity (substantially as a noun, either with or without a preposition)",
		"kjv" : "eternity, ever(-lasting, -more), old, perpetually, [phrase] world without end"
	},
	"H5704" : {
		"lemma" : "עַד",
		"xlit" : "ʻad",
		"pron" : "ad",
		"derivation" : "properly, the same as H5703 (עַד) (used as a preposition, adverb or conjunction; especially with a preposition)",
		"def" : "as far (or long, or much) as, whether of space (even unto) or time (during, while, until) or degree (equally with)",
		"kjv" : "against, and, as, at, before, by (that), even (to), for(-asmuch as), (hither-) to, [phrase] how long, into, as long (much) as, (so) that, till, toward, until, when, while, ([phrase] as) yet"
	},
	"H5705" : {
		"lemma" : "עַד",
		"xlit" : "ʻad",
		"pron" : "ad",
		"derivation" : "(Aramaic) corresponding to H5704 (עַד)",
		"def" : "{as far (or long, or much) as, whether of space (even unto) or time (during, while, until) or degree (equally with)}",
		"kjv" : "[idiom] and, at, for, (hither-) to, on till, (un-) to, until, within"
	},
	"H5706" : {
		"lemma" : "עַד",
		"xlit" : "ʻad",
		"pron" : "ad",
		"derivation" : "the same as H5703 (עַד) in the sense of the aim of an attack",
		"def" : "booty",
		"kjv" : "prey"
	},
	"H5707" : {
		"lemma" : "עֵד",
		"xlit" : "ʻêd",
		"pron" : "ayd",
		"derivation" : "contracted from H5749 (עוּד)",
		"def" : "concretely, a witness; abstractly, testimony; specifically, a recorder, i.e. prince",
		"kjv" : "witness"
	},
	"H5708" : {
		"lemma" : "עֵד",
		"xlit" : "ʻêd",
		"pron" : "ayd",
		"derivation" : "from an unused root meaning to set a period (compare H5710 (עָדָה), H5749 (עוּד))",
		"def" : "the menstrual flux (as periodical); by implication (in plural) soiling",
		"kjv" : "filthy"
	},
	"H5709" : {
		"lemma" : "עֲדָא",
		"xlit" : "ʻădâʼ",
		"pron" : "ad-aw'",
		"derivation" : "(Aramaic) or עֲדָה; (Aramaic), corresponding to H5710 (עָדָה)",
		"def" : "{to advance, i.e. pass on or continue; causatively, to remove; specifically, to bedeck (i.e. bring an ornament upon)}",
		"kjv" : "alter, depart, pass (away), remove, take (away)"
	},
	"H5710" : {
		"lemma" : "עָדָה",
		"xlit" : "ʻâdâh",
		"pron" : "aw-daw'",
		"derivation" : "a primitive root",
		"def" : "to advance, i.e. pass on or continue; causatively, to remove; specifically, to bedeck (i.e. bring an ornament upon)",
		"kjv" : "adorn, deck (self), pass by, take away"
	},
	"H5711" : {
		"lemma" : "עָדָה",
		"xlit" : "ʻÂdâh",
		"pron" : "aw-daw'",
		"derivation" : "from H5710 (עָדָה); ornament",
		"def" : "Adah, the name of two women",
		"kjv" : "Adah"
	},
	"H5712" : {
		"lemma" : "עֵדָה",
		"xlit" : "ʻêdâh",
		"pron" : "ay-daw'",
		"derivation" : "feminine of H5707 (עֵד) in the original sense of fixture",
		"def" : "a stated assemblage (specifically, a concourse, or generally, a family or crowd)",
		"kjv" : "assembly, company, congregation, multitude, people, swarm. Compare H5713 (עֵדָה)"
	},
	"H5713" : {
		"lemma" : "עֵדָה",
		"xlit" : "ʻêdâh",
		"pron" : "ay-daw'",
		"derivation" : "feminine of H5707 (עֵד) in its techn. sense",
		"def" : "testimony",
		"kjv" : "testimony, witness. Compare H5712 (עֵדָה)"
	},
	"H5714" : {
		"lemma" : "עִדּוֹ",
		"xlit" : "ʻIddôw",
		"pron" : "id-do'",
		"derivation" : "or עִדּוֹא; or עִדִּיא; from H5710 (עָדָה); timely",
		"def" : "Iddo (or Iddi), the name of five Israelites",
		"kjv" : "Iddo. Compare H3035 (יִדּוֹ), H3260 (יֶעְדִּי)"
	},
	"H5715" : {
		"lemma" : "עֵדוּת",
		"xlit" : "ʻêdûwth",
		"pron" : "ay-dooth'",
		"derivation" : "feminine of H5707 (עֵד)",
		"def" : "testimony",
		"kjv" : "testimony, witness"
	},
	"H5716" : {
		"lemma" : "עֲדִי",
		"xlit" : "ʻădîy",
		"pron" : "ad-ee'",
		"derivation" : "from H5710 (עָדָה) in the sense of trappings",
		"def" : "finery; generally an outfit; specifically, a headstall",
		"kjv" : "[idiom] excellent, mouth, ornament"
	},
	"H5717" : {
		"lemma" : "עֲדִיאֵל",
		"xlit" : "ʻĂdîyʼêl",
		"pron" : "ad-ee-ale'",
		"derivation" : "from H5716 (עֲדִי) and H410 (אֵל); ornament of God",
		"def" : "Adiel, the name of three Israelites",
		"kjv" : "Adiel"
	},
	"H5718" : {
		"lemma" : "עֲדָיָה",
		"xlit" : "ʻĂdâyâh",
		"pron" : "ad-aw-yaw'",
		"derivation" : "or עֲדָיָהוּ; from H5710 (עָדָה) and H3050 (יָהּ); Jah has adorned",
		"def" : "Adajah, the name of eight Israelites",
		"kjv" : "Adaiah"
	},
	"H5719" : {
		"lemma" : "עָדִין",
		"xlit" : "ʻâdîyn",
		"pron" : "aw-deen'",
		"derivation" : "from H5727 (עָדַן)",
		"def" : "voluptuous",
		"kjv" : "given to pleasures"
	},
	"H5720" : {
		"lemma" : "עָדִין",
		"xlit" : "ʻÂdîyn",
		"pron" : "aw-deen'",
		"derivation" : "the same as H5719 (עָדִין)",
		"def" : "Adin, the name of two Israelites",
		"kjv" : "Adin"
	},
	"H5721" : {
		"lemma" : "עֲדִינָא",
		"xlit" : "ʻĂdîynâʼ",
		"pron" : "ad-ee-naw'",
		"derivation" : "from H5719 (עָדִין); effeminacy",
		"def" : "Adina, an Israelite",
		"kjv" : "Adina"
	},
	"H5722" : {
		"lemma" : "עֲדִינוֹ",
		"xlit" : "ʻădîynôw",
		"pron" : "ad-ee-no'",
		"derivation" : "probably from H5719 (עָדִין) in the original sense of slender (i.e. a spear)",
		"def" : "his spear",
		"kjv" : "Adino"
	},
	"H5723" : {
		"lemma" : "עֲדִיתַיִם",
		"xlit" : "ʻĂdîythayim",
		"pron" : "ad-ee-thah'-yim",
		"derivation" : "dual of a feminine of H5706 (עַד); double prey",
		"def" : "Adithajim, a place in Palestine",
		"kjv" : "Adithaim"
	},
	"H5724" : {
		"lemma" : "עַדְלַי",
		"xlit" : "ʻAdlay",
		"pron" : "ad-lah'-ee",
		"derivation" : "probably from an unused root of uncertain meaning",
		"def" : "Adlai, an Israelite",
		"kjv" : "Adlai"
	},
	"H5725" : {
		"lemma" : "עֲדֻלָּם",
		"xlit" : "ʻĂdullâm",
		"pron" : "ad-ool-lawm'",
		"derivation" : "probably from the passive participle of the same as H5724 (עַדְלַי)",
		"def" : "Adullam, a place in Palestine",
		"kjv" : "Adullam"
	},
	"H5726" : {
		"lemma" : "עֲדֻלָּמִי",
		"xlit" : "ʻĂdullâmîy",
		"pron" : "ad-ool-law-mee'",
		"derivation" : "patrial from H5725 (עֲדֻלָּם)",
		"def" : "an Adullamite or native of Adullam",
		"kjv" : "Adullamite"
	},
	"H5727" : {
		"lemma" : "עָדַן",
		"xlit" : "ʻâdan",
		"pron" : "aw-dan'",
		"derivation" : "a primitive root",
		"def" : "to be soft or pleasant; figuratively and reflexively, to live voluptuously",
		"kjv" : "delight self"
	},
	"H5728" : {
		"lemma" : "עֲדֶן",
		"xlit" : "ʻăden",
		"pron" : "ad-en'",
		"derivation" : "or עֲדֶנָּה; from H5704 (עַד) and H2004 (הֵן)",
		"def" : "till now",
		"kjv" : "yet"
	},
	"H5729" : {
		"lemma" : "עֶדֶן",
		"xlit" : "ʻEden",
		"pron" : "eh'-den",
		"derivation" : "from H5727 (עָדַן); pleasure",
		"def" : "Eden, a place in Mesopotamia",
		"kjv" : "Eden"
	},
	"H5730" : {
		"lemma" : "עֵדֶן",
		"xlit" : "ʻêden",
		"pron" : "ay'-den",
		"derivation" : "or (feminine) עֶדְנָה; from H5727 (עָדַן)",
		"def" : "pleasure",
		"kjv" : "delicate, delight, pleasure. See also H1040 (בֵּית עֵדֶן)"
	},
	"H5731" : {
		"lemma" : "עֵדֶן",
		"xlit" : "ʻÊden",
		"pron" : "ay'-den",
		"derivation" : "the same as H5730 (עֵדֶן) (masculine)",
		"def" : "Eden, the region of Adam's home",
		"kjv" : "Eden"
	},
	"H5732" : {
		"lemma" : "עִדָּן",
		"xlit" : "ʻiddân",
		"pron" : "id-dawn'",
		"derivation" : "(Aramaic) from a root corresponding to that of H5708 (עֵד)",
		"def" : "a set time; technically, a year",
		"kjv" : "time"
	},
	"H5733" : {
		"lemma" : "עַדְנָא",
		"xlit" : "ʻAdnâʼ",
		"pron" : "ad-naw'",
		"derivation" : "from H5727 (עָדַן); pleasure",
		"def" : "Adna, the name of two Israelites",
		"kjv" : "Adna"
	},
	"H5734" : {
		"lemma" : "עַדְנָה",
		"xlit" : "ʻAdnâh",
		"pron" : "ad-naw'",
		"derivation" : "from H5727 (עָדַן); pleasure",
		"def" : "Adnah, the name of two Israelites",
		"kjv" : "Adnah"
	},
	"H5735" : {
		"lemma" : "עֲדְעָדָה",
		"xlit" : "ʻĂdʻâdâh",
		"pron" : "ad-aw-daw'",
		"derivation" : "from H5712 (עֵדָה); festival",
		"def" : "Adadah, a place in Palestine",
		"kjv" : "Adadah"
	},
	"H5736" : {
		"lemma" : "עֲדַף",
		"xlit" : "ʻădaph",
		"pron" : "aw-daf'",
		"derivation" : "a primitive root",
		"def" : "to be (causatively, have) redundant",
		"kjv" : "be more, odd number, be (have) over (and above), overplus, remain"
	},
	"H5737" : {
		"lemma" : "עֲדַר",
		"xlit" : "ʻădar",
		"pron" : "aw-dar'",
		"derivation" : "a primitive root",
		"def" : "to arrange as a battle, a vineyard (to hoe); hence, to muster and so to miss (or find wanting)",
		"kjv" : "dig, fail, keep (rank), lack"
	},
	"H5738" : {
		"lemma" : "עֶדֶר",
		"xlit" : "ʻEder",
		"pron" : "eh'-der",
		"derivation" : "from H5737 (עֲדַר); an arrangement (i.e. drove)",
		"def" : "Eder, an Israelite",
		"kjv" : "Ader"
	},
	"H5739" : {
		"lemma" : "עֵדֶר",
		"xlit" : "ʻêder",
		"pron" : "ay'-der",
		"derivation" : "from H5737 (עֲדַר)",
		"def" : "an arrangement, i.e. muster (of animals)",
		"kjv" : "drove, flock, herd"
	},
	"H5740" : {
		"lemma" : "עֵדֶר",
		"xlit" : "ʻÊder",
		"pron" : "ay'-der",
		"derivation" : "the same as H5739 (עֵדֶר)",
		"def" : "Eder, the name of an Israelite and of two places in Palestine",
		"kjv" : "Edar, Eder"
	},
	"H5741" : {
		"lemma" : "עַדְרִיאֵל",
		"xlit" : "ʻAdrîyʼêl",
		"pron" : "ad-ree-ale'",
		"derivation" : "from H5739 (עֵדֶר) and H410 (אֵל); flock of God",
		"def" : "Adriel, an Israelite",
		"kjv" : "Adriel"
	},
	"H5742" : {
		"lemma" : "עָדָשׁ",
		"xlit" : "ʻâdâsh",
		"pron" : "aw-dawsh'",
		"derivation" : "from an unused root of uncertain meaning",
		"def" : "a lentil",
		"kjv" : "lentile"
	},
	"H5743" : {
		"lemma" : "עוּב",
		"xlit" : "ʻûwb",
		"pron" : "oob",
		"derivation" : "a primitive root",
		"def" : "to be dense or dark, i.e. to becloud",
		"kjv" : "cover with a cloud"
	},
	"H5744" : {
		"lemma" : "עוֹבֵד",
		"xlit" : "ʻÔwbêd",
		"pron" : "o-bade'",
		"derivation" : "active participle of H5647 (עָבַד); serving",
		"def" : "Obed, the name of five Israelites",
		"kjv" : "Obed"
	},
	"H5745" : {
		"lemma" : "עוֹבָל",
		"xlit" : "ʻÔwbâl",
		"pron" : "o-bawl'",
		"derivation" : "of foreign derivation",
		"def" : "Obal, a son of Joktan",
		"kjv" : "Obal"
	},
	"H5746" : {
		"lemma" : "עוּג",
		"xlit" : "ʻûwg",
		"pron" : "oog",
		"derivation" : "a primitive root; properly, to gyrate; but used only as a denominative from H5692 (עֻגָּה)",
		"def" : "to bake (round cakes on the hearth)",
		"kjv" : "bake"
	},
	"H5747" : {
		"lemma" : "עוֹג",
		"xlit" : "ʻÔwg",
		"pron" : "ogue",
		"derivation" : "probably from H5746 (עוּג); round",
		"def" : "Og, a king of Bashan",
		"kjv" : "Og"
	},
	"H5748" : {
		"lemma" : "עוּגָב",
		"xlit" : "ʻûwgâb",
		"pron" : "oo-gawb'",
		"derivation" : "or עֻגָּב; from H5689 (עֲגַב) in the original sense of breathing",
		"def" : "a reed-instrument of music",
		"kjv" : "organ"
	},
	"H5749" : {
		"lemma" : "עוּד",
		"xlit" : "ʻûwd",
		"pron" : "ood",
		"derivation" : "a primitive root",
		"def" : "to duplicate or repeat; by implication, to protest, testify (as by reiteration); intensively, to encompass, restore (as a sort of reduplication)",
		"kjv" : "admonish, charge, earnestly, lift up, protest, call (take) to record, relieve, rob, solemnly, stand upright, testify, give warning, (bear, call to, give, take to) witness"
	},
	"H5750" : {
		"lemma" : "עוֹד",
		"xlit" : "ʻôwd",
		"pron" : "ode",
		"derivation" : "or עֹד; from H5749 (עוּד)",
		"def" : "properly, iteration or continuance; used only adverbially (with or without preposition), again, repeatedly, still, more",
		"kjv" : "again, [idiom] all life long, at all, besides, but, else, further(-more), henceforth, (any) longer, (any) more(-over), [idiom] once, since, (be) still, when, (good, the) while (having being), (as, because, whether, while) yet (within)"
	},
	"H5751" : {
		"lemma" : "עוֹד",
		"xlit" : "ʻôwd",
		"pron" : "ode",
		"derivation" : "(Aramaic) corresponding to H5750 (עוֹד)",
		"def" : "{properly, iteration or continuance; used only adverbially (with or without preposition), again, repeatedly, still, more}",
		"kjv" : "while"
	},
	"H5752" : {
		"lemma" : "עוֹדֵד",
		"xlit" : "ʻÔwdêd",
		"pron" : "o-dade'",
		"derivation" : "or עֹדֵד; from H5749 (עוּד); reiteration",
		"def" : "Oded, the name of two Israelites",
		"kjv" : "Oded"
	},
	"H5753" : {
		"lemma" : "עָוָה",
		"xlit" : "ʻâvâh",
		"pron" : "aw-vaw'",
		"derivation" : "a primitive root",
		"def" : "to crook, literally or figuratively",
		"kjv" : "do amiss, bow down, make crooked, commit iniquity, pervert, (do) perverse(-ly), trouble, [idiom] turn, do wickedly, do wrong"
	},
	"H5754" : {
		"lemma" : "עַוָּה",
		"xlit" : "ʻavvâh",
		"pron" : "av-vaw'",
		"derivation" : "intensive from H5753 (עָוָה) abbreviated",
		"def" : "overthrow",
		"kjv" : "[idiom] overturn"
	},
	"H5755" : {
		"lemma" : "עִוָּה",
		"xlit" : "ʻIvvâh",
		"pron" : "iv-vaw'",
		"derivation" : "or עַוָּא; (2 Kings 17:24), for H5754 (עַוָּה)",
		"def" : "Ivvah or Avva, a region of Assyria",
		"kjv" : "Ava, Ivah"
	},
	"H5756" : {
		"lemma" : "עוּז",
		"xlit" : "ʻûwz",
		"pron" : "ooz",
		"derivation" : "a primitive root",
		"def" : "to be strong; causatively, to stregthen, i.e. (figuratively) to save (by flight)",
		"kjv" : "gather (self, self to flee), retire"
	},
	"H5757" : {
		"lemma" : "עַוִּי",
		"xlit" : "ʻAvvîy",
		"pron" : "av-vee'",
		"derivation" : "patrial from H5755 (עִוָּה)",
		"def" : "an Avvite or native of Avvah (only plural)",
		"kjv" : "Avims, Avites"
	},
	"H5758" : {
		"lemma" : "עִוְיָא",
		"xlit" : "ʻivyâʼ",
		"pron" : "iv-yaw'",
		"derivation" : "(Aramaic) from a root corresponding to H5753 (עָוָה)",
		"def" : "perverseness",
		"kjv" : "iniquity"
	},
	"H5759" : {
		"lemma" : "עֲוִיל",
		"xlit" : "ʻăvîyl",
		"pron" : "av-eel'",
		"derivation" : "from H5764 (עוּל)",
		"def" : "a babe",
		"kjv" : "young child, little one"
	},
	"H5760" : {
		"lemma" : "עֲוִיל",
		"xlit" : "ʻăvîyl",
		"pron" : "av-eel'",
		"derivation" : "from H5765 (עֲוַל)",
		"def" : "perverse (morally)",
		"kjv" : "ungodly"
	},
	"H5761" : {
		"lemma" : "עַוִּים",
		"xlit" : "ʻAvvîym",
		"pron" : "av-veem'",
		"derivation" : "plural of H5757 (עַוִּי)",
		"def" : "Avvim (as inhabited by Avvites), a place in Palestine (with the article prefix)",
		"kjv" : "Avim"
	},
	"H5762" : {
		"lemma" : "עֲוִית",
		"xlit" : "ʻĂvîyth",
		"pron" : "av-veeth'",
		"derivation" : "or perhaps (עַיּוֹת (as if plural of H5857 (עַי)) (עַיוּת; from H5753 (עָוָה); ruin",
		"def" : "Avvith (or Avvoth), a place in Palestine",
		"kjv" : "Avith"
	},
	"H5763" : {
		"lemma" : "עוּל",
		"xlit" : "ʻûwl",
		"pron" : "ool",
		"derivation" : "a primitive root",
		"def" : "to suckle, i.e. give milk",
		"kjv" : "milch, (ewe great) with young"
	},
	"H5764" : {
		"lemma" : "עוּל",
		"xlit" : "ʻûwl",
		"pron" : "ool",
		"derivation" : "from H5763 (עוּל)",
		"def" : "a babe",
		"kjv" : "sucking child, infant"
	},
	"H5765" : {
		"lemma" : "עֲוַל",
		"xlit" : "ʻăval",
		"pron" : "aw-val'",
		"derivation" : "a primitive root",
		"def" : "to distort (morally)",
		"kjv" : "deal unjustly, unrighteous"
	},
	"H5766" : {
		"lemma" : "עֶוֶל",
		"xlit" : "ʻevel",
		"pron" : "eh'-vel",
		"derivation" : "or עָוֶל; and (feminine) עַוְלָה; or עוֹלָה; or עֹלָה; from H5765 (עֲוַל)",
		"def" : "(moral) evil",
		"kjv" : "iniquity, perverseness, unjust(-ly), unrighteousness(-ly); wicked(-ness)"
	},
	"H5767" : {
		"lemma" : "עַוָּל",
		"xlit" : "ʻavvâl",
		"pron" : "av-vawl'",
		"derivation" : "intensive from H5765 (עֲוַל)",
		"def" : "evil (morally)",
		"kjv" : "unjust, unrighteous, wicked"
	},
	"H5768" : {
		"lemma" : "עוֹלֵל",
		"xlit" : "ʻôwlêl",
		"pron" : "o-lale'",
		"derivation" : "or עֹלָל; from H5763 (עוּל)",
		"def" : "a suckling",
		"kjv" : "babe, (young) child, infant, little one"
	},
	"H5769" : {
		"lemma" : "עוֹלָם",
		"xlit" : "ʻôwlâm",
		"pron" : "o-lawm'",
		"derivation" : "or עֹלָם; from H5956 (עָלַם)",
		"def" : "properly, concealed, i.e. the vanishing point; generally, time out of mind (past or future), i.e. (practically) eternity; frequentatively, adverbial (especially with prepositional prefix) always",
		"kjv" : "alway(-s), ancient (time), any more, continuance, eternal, (for, (n-)) ever(-lasting, -more, of old), lasting, long (time), (of) old (time), perpetual, at any time, (beginning of the) world ([phrase] without end). Compare H5331 (נֶצַח), H5703 (עַד)"
	},
	"H5770" : {
		"lemma" : "עָוַן",
		"xlit" : "ʻâvan",
		"pron" : "aw-van'",
		"derivation" : "denominative from H5869 (עַיִן)",
		"def" : "to watch (with jealosy)",
		"kjv" : "eye"
	},
	"H5771" : {
		"lemma" : "עָוֺן",
		"xlit" : "ʻâvôn",
		"pron" : "aw-vone'",
		"derivation" : "or עָווֹן; (2 Kings 7:9; Psalm 51:5 (H7 (אֲבַד))), from H5753 (עָוָה)",
		"def" : "perversity, i.e. (moral) evil",
		"kjv" : "fault, iniquity, mischeif, punishment (of iniquity), sin"
	},
	"H5772" : {
		"lemma" : "עוֹנָה",
		"xlit" : "ʻôwnâh",
		"pron" : "o-naw'",
		"derivation" : "from an unused root apparently meaning to dwell together",
		"def" : "sexual (cohabitation)",
		"kjv" : "duty of marriage"
	},
	"H5773" : {
		"lemma" : "עַוְעֶה",
		"xlit" : "ʻavʻeh",
		"pron" : "av-eh'",
		"derivation" : "from H5753 (עָוָה)",
		"def" : "perversity",
		"kjv" : "[idiom] perverse"
	},
	"H5774" : {
		"lemma" : "עוּף",
		"xlit" : "ʻûwph",
		"pron" : "oof",
		"derivation" : "a primitive root; to cover (with wings or obscurity); hence (as denominative from H5775 (עוֹף))",
		"def" : "to fly; also (by implication of dimness) to faint (from the darkness of swooning)",
		"kjv" : "brandish, be (wax) faint, flee away, fly (away), [idiom] set, shine forth, weary"
	},
	"H5775" : {
		"lemma" : "עוֹף",
		"xlit" : "ʻôwph",
		"pron" : "ofe",
		"derivation" : "from H5774 (עוּף)",
		"def" : "a bird (as covered with feathers, or rather as covering with wings), often collectively",
		"kjv" : "bird, that flieth, flying, fowl"
	},
	"H5776" : {
		"lemma" : "עוֹף",
		"xlit" : "ʻôwph",
		"pron" : "ofe",
		"derivation" : "(Aramaic) corresponding to H5775 (עוֹף)",
		"def" : "{a bird (as covered with feathers, or rather as covering with wings), often collectively}",
		"kjv" : "fowl"
	},
	"H5777" : {
		"lemma" : "עוֹפֶרֶת",
		"xlit" : "ʻôwphereth",
		"pron" : "o-feh'-reth",
		"derivation" : "or עֹפֶרֶת; feminine participle active of H6080 (עָפַר)",
		"def" : "lead (from its dusty color)",
		"kjv" : "lead"
	},
	"H5778" : {
		"lemma" : "עוֹפַי",
		"xlit" : "ʻÔwphay",
		"pron" : "o-fah'-ee",
		"derivation" : "from H5775 (עוֹף); birdlike",
		"def" : "Ephai, an Israelite",
		"kjv" : "Ephai (from margin)"
	},
	"H5779" : {
		"lemma" : "עוּץ",
		"xlit" : "ʻûwts",
		"pron" : "oots",
		"derivation" : "a primitive root",
		"def" : "to consult",
		"kjv" : "take advice ((counsel) together)"
	},
	"H5780" : {
		"lemma" : "עוּץ",
		"xlit" : "ʻÛwts",
		"pron" : "oots",
		"derivation" : "apparently from H5779 (עוּץ); consultation",
		"def" : "Uts, a son of Aram, also a Seirite, and the regions settled by them",
		"kjv" : "Uz"
	},
	"H5781" : {
		"lemma" : "עוּק",
		"xlit" : "ʻûwq",
		"pron" : "ook",
		"derivation" : "a primitive root",
		"def" : "to pack",
		"kjv" : "be pressed"
	},
	"H5782" : {
		"lemma" : "עוּר",
		"xlit" : "ʻûwr",
		"pron" : "oor",
		"derivation" : "a primitive root (rather identical with through the idea of opening the eyes)",
		"def" : "to wake (literally or figuratively)",
		"kjv" : "(a-) wake(-n, up), lift up (self), [idiom] master, raise (up), stir up (self)"
	},
	"H5783" : {
		"lemma" : "עוּר",
		"xlit" : "ʻûwr",
		"pron" : "oor",
		"derivation" : "a primitive root",
		"def" : "to (be) bare",
		"kjv" : "be made naked"
	},
	"H5784" : {
		"lemma" : "עוּר",
		"xlit" : "ʻûwr",
		"pron" : "oor",
		"derivation" : "(Aramaic)",
		"def" : "chaff (as the naked husk)",
		"kjv" : "chaff"
	},
	"H5785" : {
		"lemma" : "עוֹר",
		"xlit" : "ʻôwr",
		"pron" : "ore",
		"derivation" : "from H5783 (עוּר)",
		"def" : "skin (as naked); by implication, hide, leather",
		"kjv" : "hide, leather, skin"
	},
	"H5786" : {
		"lemma" : "עָוַר",
		"xlit" : "ʻâvar",
		"pron" : "aw-var'",
		"derivation" : "a primitive root (rather denominatively from H5785 (עוֹר) through the idea of a film over the eyes)",
		"def" : "to blind",
		"kjv" : "blind, put out. See also H5895 (עַיִר)"
	},
	"H5787" : {
		"lemma" : "עִוֵּר",
		"xlit" : "ʻivvêr",
		"pron" : "iv-vare'",
		"derivation" : "intensive from H5786 (עָוַר)",
		"def" : "blind (literally or figuratively)",
		"kjv" : "blind (men, people)"
	},
	"H5788" : {
		"lemma" : "עִוָּרוֹן",
		"xlit" : "ʻivvârôwn",
		"pron" : "iv-vaw-rone'",
		"derivation" : "and (feminine) עַוֶּרֶת; from H5787 (עִוֵּר)",
		"def" : "blindness",
		"kjv" : "blind(-ness)"
	},
	"H5789" : {
		"lemma" : "עוּשׁ",
		"xlit" : "ʻûwsh",
		"pron" : "oosh",
		"derivation" : "a primitive root",
		"def" : "to hasten",
		"kjv" : "assemble self"
	},
	"H5790" : {
		"lemma" : "עוּת",
		"xlit" : "ʻûwth",
		"pron" : "ooth",
		"derivation" : "for H5789 (עוּשׁ)",
		"def" : "to hasten, i.e. succor",
		"kjv" : "speak in season"
	},
	"H5791" : {
		"lemma" : "עָוַת",
		"xlit" : "ʻâvath",
		"pron" : "aw-vath'",
		"derivation" : "a primitive root",
		"def" : "to wrest",
		"kjv" : "bow self, (make) crooked., falsifying, overthrow, deal perversely, pervert, subvert, turn upside down"
	},
	"H5792" : {
		"lemma" : "עַוָּתָה",
		"xlit" : "ʻavvâthâh",
		"pron" : "av-vaw-thaw'",
		"derivation" : "from H5791 (עָוַת)",
		"def" : "oppression",
		"kjv" : "wrong"
	},
	"H5793" : {
		"lemma" : "עוּתַי",
		"xlit" : "ʻÛwthay",
		"pron" : "oo-thah'-ee",
		"derivation" : "from H5790 (עוּת); succoring",
		"def" : "Uthai, the name of two Israelites",
		"kjv" : "Uthai"
	},
	"H5794" : {
		"lemma" : "עַז",
		"xlit" : "ʻaz",
		"pron" : "az",
		"derivation" : "from H5810 (עָזַז)",
		"def" : "strong, vehement, harsh",
		"kjv" : "fierce, [phrase] greedy, mighty, power, roughly, strong"
	},
	"H5795" : {
		"lemma" : "עֵז",
		"xlit" : "ʻêz",
		"pron" : "aze",
		"derivation" : "from H5810 (עָזַז)",
		"def" : "a she-goat (as strong), but masculine in plural (which also is used elliptically for goat's hair)",
		"kjv" : "(she) goat, kid"
	},
	"H5796" : {
		"lemma" : "עֵז",
		"xlit" : "ʻêz",
		"pron" : "aze",
		"derivation" : "(Aramaic) corresponding to H5795 (עֵז)",
		"def" : "{a she-goat (as strong), but masculine in plural (which also is used elliptically for goat's hair)}",
		"kjv" : "goat"
	},
	"H5797" : {
		"lemma" : "עֹז",
		"xlit" : "ʻôz",
		"pron" : "oze",
		"derivation" : "or (fully) עוֹז; from H5810 (עָזַז)",
		"def" : "strength in various applications (force, security, majesty, praise)",
		"kjv" : "boldness, loud, might, power, strength, strong"
	},
	"H5798" : {
		"lemma" : "עֻזָּא",
		"xlit" : "ʻUzzâʼ",
		"pron" : "ooz-zaw'",
		"derivation" : "or עֻזָּה; feminine of H5797 (עֹז); strength",
		"def" : "Uzza or Uzzah, the name of five Israelites",
		"kjv" : "Uzza, Uzzah"
	},
	"H5799" : {
		"lemma" : "עֲזָאזֵל",
		"xlit" : "ʻăzâʼzêl",
		"pron" : "az-aw-zale'",
		"derivation" : "from H5795 (עֵז) and H235 (אָזַל)",
		"def" : "goat of departure; the scapegoat",
		"kjv" : "scapegoat"
	},
	"H5800" : {
		"lemma" : "עָזַב",
		"xlit" : "ʻâzab",
		"pron" : "aw-zab'",
		"derivation" : "a primitive root",
		"def" : "to loosen, i.e. relinquish, permit, etc.",
		"kjv" : "commit self, fail, forsake, fortify, help, leave (destitute, off), refuse, [idiom] surely"
	},
	"H5801" : {
		"lemma" : "עִזָּבוֹן",
		"xlit" : "ʻizzâbôwn",
		"pron" : "iz-zaw-bone'",
		"derivation" : "from H5800 (עָזַב) in the sense of letting go (for a price, i.e. selling)",
		"def" : "trade, i.e. the place (mart) or the payment (revenue)",
		"kjv" : "fair, ware"
	},
	"H5802" : {
		"lemma" : "עַזְבּוּק",
		"xlit" : "ʻAzbûwq",
		"pron" : "az-book'",
		"derivation" : "from H5794 (עַז) and the root of H950 (בּוּקָה); stern depopulator",
		"def" : "Azbuk, an Israelite",
		"kjv" : "Azbuk"
	},
	"H5803" : {
		"lemma" : "עַזְגָּד",
		"xlit" : "ʻAzgâd",
		"pron" : "az-gawd'",
		"derivation" : "from H5794 (עַז) and H1409 (גָּד); stern troop",
		"def" : "Azgad, an Israelite",
		"kjv" : "Azgad"
	},
	"H5804" : {
		"lemma" : "עַזָּה",
		"xlit" : "ʻAzzâh",
		"pron" : "az-zaw'",
		"derivation" : "feminine of H5794 (עַז); strong",
		"def" : "Azzah, a place in Palestine",
		"kjv" : "Azzah, Gaza"
	},
	"H5805" : {
		"lemma" : "עֲזוּבָה",
		"xlit" : "ʻăzûwbâh",
		"pron" : "az-oo-baw'",
		"derivation" : "feminine passive participle of H5800 (עָזַב)",
		"def" : "desertion (of inhabitants)",
		"kjv" : "forsaking"
	},
	"H5806" : {
		"lemma" : "עֲזוּבָה",
		"xlit" : "ʻĂzûwbâh",
		"pron" : "az-oo-baw'",
		"derivation" : "the same as H5805 (עֲזוּבָה)",
		"def" : "Azubah, the name of two Israelitesses",
		"kjv" : "Azubah"
	},
	"H5807" : {
		"lemma" : "עֱזוּז",
		"xlit" : "ʻĕzûwz",
		"pron" : "ez-ooz'",
		"derivation" : "from H5810 (עָזַז)",
		"def" : "forcibleness",
		"kjv" : "might, strength"
	},
	"H5808" : {
		"lemma" : "עִזּוּז",
		"xlit" : "ʻizzûwz",
		"pron" : "iz-zooz'",
		"derivation" : "from H5810 (עָזַז)",
		"def" : "forcible; collectively and concretely, an army",
		"kjv" : "power, strong"
	},
	"H5809" : {
		"lemma" : "עַזּוּר",
		"xlit" : "ʻAzzûwr",
		"pron" : "az-zoor'",
		"derivation" : "or עַזֻּר; from H5826 (עָזַר); helpful",
		"def" : "Azzur, the name of three Israelites",
		"kjv" : "Azur, Azzur"
	},
	"H5810" : {
		"lemma" : "עָזַז",
		"xlit" : "ʻâzaz",
		"pron" : "aw-zaz'",
		"derivation" : "a primitive root",
		"def" : "to be stout (literally or figuratively)",
		"kjv" : "harden, impudent, prevail, strengthen (self), be strong"
	},
	"H5811" : {
		"lemma" : "עָזָז",
		"xlit" : "ʻÂzâz",
		"pron" : "aw-zawz'",
		"derivation" : "from H5810 (עָזַז); strong",
		"def" : "Azaz, an Israelite",
		"kjv" : "Azaz"
	},
	"H5812" : {
		"lemma" : "עֲזַזְיָהוּ",
		"xlit" : "ʻĂzazyâhûw",
		"pron" : "az-az-yaw'-hoo",
		"derivation" : "from H5810 (עָזַז) and H3050 (יָהּ); Jah has strengthened",
		"def" : "Azazjah, the name of three Israelites",
		"kjv" : "Azaziah"
	},
	"H5813" : {
		"lemma" : "עֻזִּי",
		"xlit" : "ʻUzzîy",
		"pron" : "ooz-zee'",
		"derivation" : "from H5810 (עָזַז); forceful",
		"def" : "Uzzi, the name of six Israelites",
		"kjv" : "Uzzi"
	},
	"H5814" : {
		"lemma" : "עֻזִּיָּא",
		"xlit" : "ʻUzzîyâʼ",
		"pron" : "ooz-zee-yaw'",
		"derivation" : "perhaps for H5818 (עֻזִּיָּה)",
		"def" : "Uzzija, an Israelite",
		"kjv" : "Uzzia"
	},
	"H5815" : {
		"lemma" : "עֲזִיאֵל",
		"xlit" : "ʻĂzîyʼêl",
		"pron" : "az-ee-ale'",
		"derivation" : "from H5756 (עוּז) and H410 (אֵל); strengthened of God",
		"def" : "Aziel, an Israelite",
		"kjv" : "Aziel. Compare H3268 (יַעֲזִיאֵל)"
	},
	"H5816" : {
		"lemma" : "עֻזִּיאֵל",
		"xlit" : "ʻUzzîyʼêl",
		"pron" : "ooz-zee-ale'",
		"derivation" : "from H5797 (עֹז) and H410 (אֵל); strength of God",
		"def" : "Uzziel, the name of six Israelites",
		"kjv" : "Uzziel"
	},
	"H5817" : {
		"lemma" : "עׇזִּיאֵלִי",
		"xlit" : "ʻOzzîyʼêlîy",
		"pron" : "oz-zee-ay-lee'",
		"derivation" : "patronymically from H5816 (עֻזִּיאֵל)",
		"def" : "an Uzzielite (collectively) or descendants of Uzziel",
		"kjv" : "Uzzielites"
	},
	"H5818" : {
		"lemma" : "עֻזִּיָּה",
		"xlit" : "ʻUzzîyâh",
		"pron" : "ooz-zee-yaw'",
		"derivation" : "or עֻזִּיָּהוּ; from H5797 (עֹז) and H3050 (יָהּ); strength of Jah",
		"def" : "Uzzijah, the name of five Israelites",
		"kjv" : "Uzziah"
	},
	"H5819" : {
		"lemma" : "עֲזִיזָא",
		"xlit" : "ʻĂzîyzâʼ",
		"pron" : "az-ee-zaw'",
		"derivation" : "from H5756 (עוּז); strengthfulness",
		"def" : "Aziza, an Israelite",
		"kjv" : "Aziza"
	},
	"H5820" : {
		"lemma" : "עַזְמָוֶת",
		"xlit" : "ʻAzmâveth",
		"pron" : "az-maw'-veth",
		"derivation" : "from H5794 (עַז) and H4194 (מָוֶת); strong one of death",
		"def" : "Azmaveth, the name of three Israelites and of a place in Palestine",
		"kjv" : "Azmaveth. See also H1041 (בֵּית עַזְמָוֶת)"
	},
	"H5821" : {
		"lemma" : "עַזָּן",
		"xlit" : "ʻAzzân",
		"pron" : "az-zawn'",
		"derivation" : "from H5794 (עַז); strong one",
		"def" : "Azzan, an Israelite",
		"kjv" : "Azzan"
	},
	"H5822" : {
		"lemma" : "עׇזְנִיָּה",
		"xlit" : "ʻoznîyâh",
		"pron" : "oz-nee-yaw'",
		"derivation" : "probably feminine of H5797 (עֹז)",
		"def" : "probably the sea-eagle (from its strength)",
		"kjv" : "ospray"
	},
	"H5823" : {
		"lemma" : "עָזַק",
		"xlit" : "ʻâzaq",
		"pron" : "aw-zak'",
		"derivation" : "a primitive root",
		"def" : "to grub over",
		"kjv" : "fence about"
	},
	"H5824" : {
		"lemma" : "עִזְקָא",
		"xlit" : "ʻizqâʼ",
		"pron" : "iz-kaw'",
		"derivation" : "(Aramaic) from a root corresponding to H5823 (עָזַק)",
		"def" : "a signet-ring (as engraved)",
		"kjv" : "signet"
	},
	"H5825" : {
		"lemma" : "עֲזֵקָה",
		"xlit" : "ʻĂzêqâh",
		"pron" : "az-ay-kaw'",
		"derivation" : "from H5823 (עָזַק); tilled",
		"def" : "Azekah, a place in Palestine",
		"kjv" : "Azekah"
	},
	"H5826" : {
		"lemma" : "עָזַר",
		"xlit" : "ʻâzar",
		"pron" : "aw-zar'",
		"derivation" : "a primitive root",
		"def" : "to surround, i.e. protect or aid",
		"kjv" : "help, succour"
	},
	"H5827" : {
		"lemma" : "עֶזֶר",
		"xlit" : "ʻEzer",
		"pron" : "eh'-zer",
		"derivation" : "from H5826 (עָזַר); help",
		"def" : "Ezer, the name of two Israelites",
		"kjv" : "Ezer. Compare H5829 (עֵזֶר)"
	},
	"H5828" : {
		"lemma" : "עֵזֶר",
		"xlit" : "ʻêzer",
		"pron" : "ay'-zer",
		"derivation" : "from H5826 (עָזַר)",
		"def" : "aid",
		"kjv" : "help"
	},
	"H5829" : {
		"lemma" : "עֵזֶר",
		"xlit" : "ʻÊzer",
		"pron" : "ay'-zer",
		"derivation" : "the same as H5828 (עֵזֶר)",
		"def" : "Ezer, the name of four Israelites",
		"kjv" : "Ezer. Compare H5827 (עֶזֶר)"
	},
	"H5830" : {
		"lemma" : "עֶזְרָא",
		"xlit" : "ʻEzrâʼ",
		"pron" : "ez-raw'",
		"derivation" : "a variation of H5833 (עֶזְרָה)",
		"def" : "Ezra, an Israelite",
		"kjv" : "Ezra"
	},
	"H5831" : {
		"lemma" : "עֶזְרָא",
		"xlit" : "ʻEzrâʼ",
		"pron" : "ez-raw'",
		"derivation" : "(Aramaic) corresponding to H5830 (עֶזְרָא)",
		"def" : "Ezra, an Israelite",
		"kjv" : "Ezra"
	},
	"H5832" : {
		"lemma" : "עֲזַרְאֵל",
		"xlit" : "ʻĂzarʼêl",
		"pron" : "az-ar-ale'",
		"derivation" : "from H5826 (עָזַר) and H410 (אֵל); God has helped",
		"def" : "Azarel, the name of five Israelites",
		"kjv" : "Azarael, Azareel"
	},
	"H5833" : {
		"lemma" : "עֶזְרָה",
		"xlit" : "ʻezrâh",
		"pron" : "ez-raw'",
		"derivation" : "or עֶזְרָת; (Psalm 60:11 (13); Psalm 108:12 (13)), feminine of H5828 (עֵזֶר)",
		"def" : "aid",
		"kjv" : "help(-ed, -er)"
	},
	"H5834" : {
		"lemma" : "עֶזְרָה",
		"xlit" : "ʻEzrâh",
		"pron" : "ez-raw'",
		"derivation" : "the same as H5833 (עֶזְרָה)",
		"def" : "Ezrah, an Israelite",
		"kjv" : "Ezrah"
	},
	"H5835" : {
		"lemma" : "עֲזָרָה",
		"xlit" : "ʻăzârâh",
		"pron" : "az-aw-raw'",
		"derivation" : "from H5826 (עָזַר) in its original meaning of surrounding",
		"def" : "an inclosure; also a border",
		"kjv" : "court, settle"
	},
	"H5836" : {
		"lemma" : "עֶזְרִי",
		"xlit" : "ʻEzrîy",
		"pron" : "ez-ree'",
		"derivation" : "from H5828 (עֵזֶר); helpful",
		"def" : "Ezri, an Israelite",
		"kjv" : "Ezri"
	},
	"H5837" : {
		"lemma" : "עַזְרִיאֵל",
		"xlit" : "ʻAzrîyʼêl",
		"pron" : "az-ree-ale'",
		"derivation" : "from H5828 (עֵזֶר) and H410 (אֵל); help of God",
		"def" : "Azriel, the name of three Israelites",
		"kjv" : "Azriel"
	},
	"H5838" : {
		"lemma" : "עֲזַרְיָה",
		"xlit" : "ʻĂzaryâh",
		"pron" : "az-ar-yaw'",
		"derivation" : "or עֲזַרְיָהוּ; from H5826 (עָזַר) and H3050 (יָהּ); Jah has helped",
		"def" : "Azarjah, the name of nineteen Israelites",
		"kjv" : "Azariah"
	},
	"H5839" : {
		"lemma" : "עֲזַרְיָה",
		"xlit" : "ʻĂzaryâh",
		"pron" : "az-ar-yaw'",
		"derivation" : "(Aramaic) corresponding to H5838 (עֲזַרְיָה)",
		"def" : "Azarjah, one of Daniel's companions",
		"kjv" : "Azariah"
	},
	"H5840" : {
		"lemma" : "עַזְרִיקָם",
		"xlit" : "ʻAzrîyqâm",
		"pron" : "az-ree-kawm'",
		"derivation" : "from H5828 (עֵזֶר) and active participle of H6965 (קוּם); help of an enemy",
		"def" : "Azrikam, the name of four Israelites",
		"kjv" : "Azrikam"
	},
	"H5841" : {
		"lemma" : "עַזָּתִי",
		"xlit" : "ʻAzzâthîy",
		"pron" : "az-zaw-thee'",
		"derivation" : "patrial from H5804 (עַזָּה)",
		"def" : "an Azzathite or inhabitant of Azzah",
		"kjv" : "Gazathite, Gazite"
	},
	"H5842" : {
		"lemma" : "עֵט",
		"xlit" : "ʻêṭ",
		"pron" : "ate",
		"derivation" : "from H5860 (עִיט) (contracted) in the sense of swooping, i.e. side-long stroke",
		"def" : "a stylus or marking stick",
		"kjv" : "pen"
	},
	"H5843" : {
		"lemma" : "עֵטָא",
		"xlit" : "ʻêṭâʼ",
		"pron" : "ay-taw'",
		"derivation" : "(Aramaic) from H3272 (יְעַט)",
		"def" : "prudence",
		"kjv" : "counsel"
	},
	"H5844" : {
		"lemma" : "עָטָה",
		"xlit" : "ʻâṭâh",
		"pron" : "aw-taw'",
		"derivation" : "a primitive root",
		"def" : "to wrap, i.e. cover, veil, cloth, or roll",
		"kjv" : "array self, be clad, (put a) cover (-ing, self), fill, put on, [idiom] surely, turn aside"
	},
	"H5845" : {
		"lemma" : "עֲטִין",
		"xlit" : "ʻăṭîyn",
		"pron" : "at-een'",
		"derivation" : "from an unused root meaning apparently to contain",
		"def" : "a receptacle (for milk, i.e. pail; figuratively, breast)",
		"kjv" : "breast"
	},
	"H5846" : {
		"lemma" : "עֲטִישָׁה",
		"xlit" : "ʻăṭîyshâh",
		"pron" : "at-ee-shaw'",
		"derivation" : "from an unused root meaning to sneeze",
		"def" : "sneezing",
		"kjv" : "sneezing"
	},
	"H5847" : {
		"lemma" : "עֲטַלֵּף",
		"xlit" : "ʻăṭallêph",
		"pron" : "at-al-lafe'",
		"derivation" : "of uncertain derivation",
		"def" : "a bat",
		"kjv" : "bat"
	},
	"H5848" : {
		"lemma" : "עָטַף",
		"xlit" : "ʻâṭaph",
		"pron" : "aw-taf'",
		"derivation" : "a primitive root",
		"def" : "to shroud, i.e. clothe (whether transitive or reflex.); hence (from the idea of darkness) to languish",
		"kjv" : "cover (over), fail, faint, feebler, hide self, be overwhelmed, swoon"
	},
	"H5849" : {
		"lemma" : "עָטַר",
		"xlit" : "ʻâṭar",
		"pron" : "aw-tar'",
		"derivation" : "a primitive root",
		"def" : "to encircle (for attack or protection); especially to crown (literally or figuratively)",
		"kjv" : "compass, crown"
	},
	"H5850" : {
		"lemma" : "עֲטָרָה",
		"xlit" : "ʻăṭârâh",
		"pron" : "at-aw-raw'",
		"derivation" : "from H5849 (עָטַר)",
		"def" : "a crown",
		"kjv" : "crown"
	},
	"H5851" : {
		"lemma" : "עֲטָרָה",
		"xlit" : "ʻĂṭârâh",
		"pron" : "at-aw-raw'",
		"derivation" : "the same as H5850 (עֲטָרָה)",
		"def" : "Atarah, an Israelitess",
		"kjv" : "Atarah"
	},
	"H5852" : {
		"lemma" : "עֲטָרוֹת",
		"xlit" : "ʻĂṭârôwth",
		"pron" : "at-aw-roth'",
		"derivation" : "or עֲטָרֹת; plural of H5850 (עֲטָרָה)",
		"def" : "Ataroth, the name (thus simply) of two places in Palestine",
		"kjv" : "Ataroth"
	},
	"H5853" : {
		"lemma" : "עַטְרוֹת אַדָּר",
		"xlit" : "ʻAṭrôwth ʼAddâr",
		"pron" : "at-roth' ad-dawr'",
		"derivation" : "from the same as H5852 (עֲטָרוֹת) and H146 (אַדָּר); crowns of Addar",
		"def" : "Atroth-Addar, a place in Palestine",
		"kjv" : "Ataroth-adar(-addar)"
	},
	"H5854" : {
		"lemma" : "עַטְרוֹת בֵּית יוֹאָב",
		"xlit" : "ʻAṭrôwth bêyth Yôwʼâb",
		"pron" : "at-roth' bayth yoawb'",
		"derivation" : "from the same as H5852 (עֲטָרוֹת) and H1004 (בַּיִת) and H3097 (יוֹאָב); crowns of the house of Joab",
		"def" : "Atroth-beth-Joab, a place in Palestine",
		"kjv" : "Ataroth the house of Joab"
	},
	"H5855" : {
		"lemma" : "עַטְרוֹת שׁוֹפָן",
		"xlit" : "ʻAṭrôwth Shôwphân",
		"pron" : "at-roth' sho-fawn'",
		"derivation" : "from the same as H5852 (עֲטָרוֹת) and a name otherwise unused (being from the same as H8226 (שָׂפַן)) meaning hidden; crowns of Shophan",
		"def" : "Atroth-Shophan, a place in Palestine",
		"kjv" : "Atroth, Shophan (as if two places)"
	},
	"H5856" : {
		"lemma" : "עִי",
		"xlit" : "ʻîy",
		"pron" : "ee",
		"derivation" : "from H5753 (עָוָה)",
		"def" : "a ruin (as if overturned)",
		"kjv" : "heap"
	},
	"H5857" : {
		"lemma" : "עַי",
		"xlit" : "ʻAy",
		"pron" : "ah'ee",
		"derivation" : "or (feminine) עַיָּא; (Nehemiah 11:31), or עַיָּת; (Isaiah 10:28), for H5856 (עִי)",
		"def" : "Ai, Aja or Ajath, a place in Palestine",
		"kjv" : "Ai, Aija, Aijath, Hai"
	},
	"H5858" : {
		"lemma" : "עֵיבָל",
		"xlit" : "ʻÊybâl",
		"pron" : "ay-bawl'",
		"derivation" : "perhaps from an unused root probably meaning to be bald; bare",
		"def" : "Ebal, a mountain of Palestine",
		"kjv" : "Ebal"
	},
	"H5859" : {
		"lemma" : "עִיּוֹן",
		"xlit" : "ʻÎyôwn",
		"pron" : "ee-yone'",
		"derivation" : "from H5856 (עִי); ruin",
		"def" : "Ijon, a place in Palestine",
		"kjv" : "Ijon"
	},
	"H5860" : {
		"lemma" : "עִיט",
		"xlit" : "ʻîyṭ",
		"pron" : "eet",
		"derivation" : "a primitive root",
		"def" : "to swoop down upon (literally or figuratively)",
		"kjv" : "fly, rail"
	},
	"H5861" : {
		"lemma" : "עַיִט",
		"xlit" : "ʻayiṭ",
		"pron" : "ah'-yit",
		"derivation" : "from H5860 (עִיט)",
		"def" : "a hawk or other bird of prey",
		"kjv" : "bird, fowl, ravenous (bird)"
	},
	"H5862" : {
		"lemma" : "עֵיטָם",
		"xlit" : "ʻÊyṭâm",
		"pron" : "ay-tawm'",
		"derivation" : "from H5861 (עַיִט); hawk-ground",
		"def" : "Etam, a place in Palestine",
		"kjv" : "Etam"
	},
	"H5863" : {
		"lemma" : "עִיֵּי הָעֲבָרִים",
		"xlit" : "ʻÎyêy hâ-ʻĂbârîym",
		"pron" : "ee-yay' haw-ab-aw-reem'",
		"derivation" : "from the plural of H5856 (עִי) and the plural of the active participle of H5674 (עָבַר) with the article interposed; ruins of the passers",
		"def" : "Ije-ha-Abarim, a place near Palestine",
		"kjv" : "Ije-abarim"
	},
	"H5864" : {
		"lemma" : "עִיִּים",
		"xlit" : "ʻÎyîym",
		"pron" : "ee-yeem'",
		"derivation" : "plural of H5856 (עִי); ruins",
		"def" : "Ijim, a place in the Desert",
		"kjv" : "Iim"
	},
	"H5865" : {
		"lemma" : "עֵילוֹם",
		"xlit" : "ʻêylôwm",
		"pron" : "ay-lome'",
		"derivation" : "for H5769 (עוֹלָם)",
		"def" : "{concealed, i.e. the vanishing point; generally, time out of mind (past or future), i.e. (practically) eternity; frequentatively, adverbial (especially with prepositional prefix) always}",
		"kjv" : "ever"
	},
	"H5866" : {
		"lemma" : "עִילַי",
		"xlit" : "ʻÎylay",
		"pron" : "ee-lah'-ee",
		"derivation" : "from H5927 (עָלָה); elevated",
		"def" : "Ilai, an Israelite",
		"kjv" : "Ilai"
	},
	"H5867" : {
		"lemma" : "עֵילָם",
		"xlit" : "ʻÊylâm",
		"pron" : "ay-lawm'",
		"derivation" : "or עוֹלָם; (Ezra 10:2; Jeremiah 49:36), probably from H5956 (עָלַם); hidden, i.e. distant",
		"def" : "Elam, a son of Shem and his descendants, with their country; also of six Israelites",
		"kjv" : "Elam"
	},
	"H5868" : {
		"lemma" : "עֲיָם",
		"xlit" : "ʻăyâm",
		"pron" : "ah-yawm'",
		"derivation" : "of doubtful origin and authenticity",
		"def" : "probably meaning strength",
		"kjv" : "mighty"
	},
	"H5869" : {
		"lemma" : "עַיִן",
		"xlit" : "ʻayin",
		"pron" : "ah'-yin",
		"derivation" : "probably a primitive word",
		"def" : "an eye (literally or figuratively); by analogy, a fountain (as the eye of the landscape)",
		"kjv" : "affliction, outward appearance, [phrase] before, [phrase] think best, colour, conceit, [phrase] be content, countenance, [phrase] displease, eye((-brow), (-d), -sight), face, [phrase] favour, fountain, furrow (from the margin), [idiom] him, [phrase] humble, knowledge, look, ([phrase] well), [idiom] me, open(-ly), [phrase] (not) please, presence, [phrase] regard, resemblance, sight, [idiom] thee, [idiom] them, [phrase] think, [idiom] us, well, [idiom] you(-rselves)"
	},
	"H5870" : {
		"lemma" : "עַיִן",
		"xlit" : "ʻayin",
		"pron" : "ah'-yin",
		"derivation" : "(Aramaic) corresponding to H5869 (עַיִן)",
		"def" : "an eye",
		"kjv" : "eye"
	},
	"H5871" : {
		"lemma" : "עַיִן",
		"xlit" : "ʻAyin",
		"pron" : "ah'-yin",
		"derivation" : "the same as H5869 (עַיִן); fountain",
		"def" : "Ajin, the name (thus simply) of two places in Palestine",
		"kjv" : "Ain"
	},
	"H5872" : {
		"lemma" : "עֵין גֶּדִי",
		"xlit" : "ʻÊyn Gedîy",
		"pron" : "ane geh'-dee",
		"derivation" : "from H5869 (עַיִן) and H1423 (גְּדִי); fountain of a kid",
		"def" : "En-Gedi, a place in Palestine",
		"kjv" : "En-gedi"
	},
	"H5873" : {
		"lemma" : "עֵין גַּנִּים",
		"xlit" : "ʻÊyn Gannîym",
		"pron" : "ane gan-neem'",
		"derivation" : "from H5869 (עַיִן) and the plural of H1588 (גַּן); fountain of gardens",
		"def" : "En-Gannim, a place in Palestine",
		"kjv" : "En-gannim"
	},
	"H5874" : {
		"lemma" : "עֵין־דֹּאר",
		"xlit" : "ʻÊyn-Dôʼr",
		"pron" : "ane-dore'",
		"derivation" : "or עֵין דּוֹר; or עֵין־דֹּר; from H5869 (עַיִן) and H1755 (דּוֹר); fountain of dwelling",
		"def" : "En-Dor, a place in Palestine",
		"kjv" : "En-dor"
	},
	"H5875" : {
		"lemma" : "עֵין הַקּוֹרֵא",
		"xlit" : "ʻÊyn haq-Qôwrêʼ",
		"pron" : "ane-hak-ko-ray'",
		"derivation" : "from H5869 (עַיִן) and the active participle of H7121 (קָרָא); fountain of One calling",
		"def" : "En-hak-Kore, a place near Palestine",
		"kjv" : "En-hakhore"
	},
	"H5876" : {
		"lemma" : "עֵין חַדָּה",
		"xlit" : "ʻÊyn Chaddâh",
		"pron" : "ane khad-daw'",
		"derivation" : "from H5869 (עַיִן) and the feminine of a derivative from H2300 (חָדַד); fountain of sharpness",
		"def" : "En-Chaddah, a place in Palestine",
		"kjv" : "En-haddah"
	},
	"H5877" : {
		"lemma" : "עֵין חָצוֹר",
		"xlit" : "ʻÊyn Châtsôwr",
		"pron" : "ane khaw-tsore'",
		"derivation" : "from H5869 (עַיִן) and the same as H2674 (חָצוֹר); fountain of a village",
		"def" : "En-Chatsor, a place in Palestine",
		"kjv" : "En-hazor"
	},
	"H5878" : {
		"lemma" : "עֵין חֲרֹד",
		"xlit" : "ʻÊyn Chărôd",
		"pron" : "ane khar-ode'",
		"derivation" : "from H5869 (עַיִן) and a derivative of H2729 (חָרַד); fountain of trembling",
		"def" : "En-Charod, a place in Palestine",
		"kjv" : "well of Harod"
	},
	"H5879" : {
		"lemma" : "עֵינַיִם",
		"xlit" : "ʻÊynayim",
		"pron" : "ay-nah'-yim",
		"derivation" : "or עֵינָם; dual of H5869 (עַיִן); double fountain",
		"def" : "Enajim or Enam, a place in Palestine",
		"kjv" : "Enaim, openly (Genesis 38:21)"
	},
	"H5880" : {
		"lemma" : "עֵין מִשְׁפָּט",
		"xlit" : "ʻÊyn Mishpâṭ",
		"pron" : "ane mish-pawt'",
		"derivation" : "from H5869 (עַיִן) and H4941 (מִשְׁפָּט); fountain of judgment",
		"def" : "En-Mishpat, a place near Palestine",
		"kjv" : "En-mishpat"
	},
	"H5881" : {
		"lemma" : "עֵינָן",
		"xlit" : "ʻÊynân",
		"pron" : "ay-nawn'",
		"derivation" : "from H5869 (עַיִן); having eyes",
		"def" : "Enan, an Israelite",
		"kjv" : "Enan. Compare H2704 (חֲצַר עֵינָן)"
	},
	"H5882" : {
		"lemma" : "עֵין עֶגְלַיִם",
		"xlit" : "ʻÊyn ʻEglayim",
		"pron" : "ane eg-lah'-yim",
		"derivation" : "עַיִן and the dual of H5695 (עֵגֶל); fountain of two calves",
		"def" : "En-Eglajim, a place in Palestine",
		"kjv" : "En-eglaim"
	},
	"H5883" : {
		"lemma" : "עֵין רֹגֵל",
		"xlit" : "ʻÊyn Rôgêl",
		"pron" : "ane ro-gale'",
		"derivation" : "from H5869 (עַיִן) and the active participle of H7270 (רָגַל); fountain of a traveller",
		"def" : "En-Rogel, a place near Jerusalem",
		"kjv" : "En-rogel"
	},
	"H5884" : {
		"lemma" : "עֵין רִמּוֹן",
		"xlit" : "ʻÊyn Rimmôwn",
		"pron" : "ane rim-mone'",
		"derivation" : "from H5869 (עַיִן) and H7416 (רִמּוֹן); fountain of a pomegranate",
		"def" : "En-Rimmon, a place in Palestine",
		"kjv" : "En-rimmon"
	},
	"H5885" : {
		"lemma" : "עֵין שֶׁמֶשׁ",
		"xlit" : "ʻÊyn Shemesh",
		"pron" : "ane sheh'-mesh",
		"derivation" : "from H5869 (עַיִן) and H8121 (שֶׁמֶשׁ); fountain of the sun",
		"def" : "En-Shemesh, a place in Palestine",
		"kjv" : "Enshemesh"
	},
	"H5886" : {
		"lemma" : "עֵין תַּנִּים",
		"xlit" : "ʻÊyn Tannîym",
		"pron" : "ane tan-neem'",
		"derivation" : "from H5869 (עַיִן) and the plural of H8565 (תַּן); fountain of jackals",
		"def" : "En-Tannim, a pool near Jerusalem",
		"kjv" : "dragon well"
	},
	"H5887" : {
		"lemma" : "עֵין תַּפּוּחַ",
		"xlit" : "ʻÊyn Tappûwach",
		"pron" : "ane tap-poo'-akh",
		"derivation" : "from H5869 (עַיִן) and H8598 (תַּפּוּחַ); fountain of an apple-tree",
		"def" : "En-Tappuach, a place in Palestine",
		"kjv" : "En-tappuah"
	},
	"H5888" : {
		"lemma" : "עָיֵף",
		"xlit" : "ʻâyêph",
		"pron" : "aw-yafe'",
		"derivation" : "a primitive root",
		"def" : "to languish",
		"kjv" : "be wearied"
	},
	"H5889" : {
		"lemma" : "עָיֵף",
		"xlit" : "ʻâyêph",
		"pron" : "aw-yafe'",
		"derivation" : "from H5888 (עָיֵף)",
		"def" : "languid",
		"kjv" : "faint, thirsty, weary"
	},
	"H5890" : {
		"lemma" : "עֵיפָה",
		"xlit" : "ʻêyphâh",
		"pron" : "ay-faw'",
		"derivation" : "feminine from H5774 (עוּף)",
		"def" : "obscurity (as if from covering)",
		"kjv" : "darkness"
	},
	"H5891" : {
		"lemma" : "עֵיפָה",
		"xlit" : "ʻÊyphâh",
		"pron" : "ay-faw'",
		"derivation" : "the same as H5890 (עֵיפָה)",
		"def" : "Ephah, the name of a son of Midian, and of the region settled by him; also of an Israelite and of an Israelitess",
		"kjv" : "Ephah"
	},
	"H5892" : {
		"lemma" : "עִיר",
		"xlit" : "ʻîyr",
		"pron" : "eer",
		"derivation" : "or (in the plural) עָר; or עָיַר; (Judges 10:4), from H5782 (עוּר)",
		"def" : "a city (a place guarded by waking or a watch) in the widest sense (even of a mere encampment or post)",
		"kjv" : "Ai (from margin), city, court (from margin), town"
	},
	"H5893" : {
		"lemma" : "עִיר",
		"xlit" : "ʻÎyr",
		"pron" : "eer",
		"derivation" : "the same as H5892 (עִיר)",
		"def" : "Ir, an Israelite",
		"kjv" : "Ir"
	},
	"H5894" : {
		"lemma" : "עִיר",
		"xlit" : "ʻîyr",
		"pron" : "eer",
		"derivation" : "(Aramaic) from a root corresponding to H5782 (עוּר)",
		"def" : "a watcher, i.e. an angel (as guardian)",
		"kjv" : "watcher"
	},
	"H5895" : {
		"lemma" : "עַיִר",
		"xlit" : "ʻayir",
		"pron" : "ah'-yeer",
		"derivation" : "from H5782 (עוּר) in the sense of raising (i.e. bearing a burden)",
		"def" : "properly, a young ass (as just broken to a load); hence an ass-colt",
		"kjv" : "(ass) colt, foal, young ass"
	},
	"H5896" : {
		"lemma" : "עִירָא",
		"xlit" : "ʻÎyrâʼ",
		"pron" : "ee-raw'",
		"derivation" : "from H5782 (עוּר); wakefulness",
		"def" : "Ira, the name of three Israelites",
		"kjv" : "Ira"
	},
	"H5897" : {
		"lemma" : "עִירָד",
		"xlit" : "ʻÎyrâd",
		"pron" : "ee-rawd'",
		"derivation" : "from the same as H6166 (עֲרָד); fugitive",
		"def" : "Irad, an antediluvian",
		"kjv" : "Irad"
	},
	"H5898" : {
		"lemma" : "עִיר הַמֶּלַח",
		"xlit" : "ʻÎyr ham-Melach",
		"pron" : "eer ham-meh'-lakh",
		"derivation" : "from H5892 (עִיר) and H4417 (מֶלַח) with the article of substance interp.; city of (the) salt",
		"def" : "Irham-Melach, a place near Palestine",
		"kjv" : "the city of salt"
	},
	"H5899" : {
		"lemma" : "עִיר הַתְּמָרִים",
		"xlit" : "ʻÎyr hat-Tᵉmârîym",
		"pron" : "eer hat-tem-aw-reem'",
		"derivation" : "from H5892 (עִיר) and the plural of H8558 (תָּמָר) with the article interpolated; city of the palmtrees",
		"def" : "Ir-hat-Temarim, a place in Palestine",
		"kjv" : "the city of palmtrees"
	},
	"H5900" : {
		"lemma" : "עִירוּ",
		"xlit" : "ʻÎyrûw",
		"pron" : "ee-roo'",
		"derivation" : "from H5892 (עִיר); a citizen",
		"def" : "Iru, an Israelite",
		"kjv" : "Iru"
	},
	"H5901" : {
		"lemma" : "עִירִי",
		"xlit" : "ʻÎyrîy",
		"pron" : "ee-ree'",
		"derivation" : "from H5892 (עִיר); urbane",
		"def" : "Iri, an Israelite",
		"kjv" : "Iri"
	},
	"H5902" : {
		"lemma" : "עִירָם",
		"xlit" : "ʻÎyrâm",
		"pron" : "ee-rawm'",
		"derivation" : "from H5892 (עִיר); city-wise",
		"def" : "Iram, an Idumaean",
		"kjv" : "Iram"
	},
	"H5903" : {
		"lemma" : "עֵירֹם",
		"xlit" : "ʻêyrôm",
		"pron" : "ay-rome'",
		"derivation" : "or עֵרֹם; from H6191 (עָרַם)",
		"def" : "nudity",
		"kjv" : "naked(-ness)"
	},
	"H5904" : {
		"lemma" : "עִיר נָחָשׁ",
		"xlit" : "ʻÎyr Nâchâsh",
		"pron" : "eer naw-khawsh'",
		"derivation" : "from H5892 (עִיר) and H5175 (נָחָשׁ); city of a serpent",
		"def" : "Ir-Nachash, a place in Palestine",
		"kjv" : "Irnahash"
	},
	"H5905" : {
		"lemma" : "עִיר שֶׁמֶשׁ",
		"xlit" : "ʻÎyr Shemesh",
		"pron" : "eer sheh'-mesh",
		"derivation" : "from H5892 (עִיר) and H8121 (שֶׁמֶשׁ); city of the sun",
		"def" : "Ir-Shemesh, a place in Palestine",
		"kjv" : "Irshemesh"
	},
	"H5906" : {
		"lemma" : "עַיִשׁ",
		"xlit" : "ʻAyish",
		"pron" : "ah'-yish",
		"derivation" : "or עָשׁ; from H5789 (עוּשׁ)",
		"def" : "the constellation of the Great Bear (perhaps from its migration through the heavens)",
		"kjv" : "Arcturus"
	},
	"H5907" : {
		"lemma" : "עַכְבּוֹר",
		"xlit" : "ʻAkbôwr",
		"pron" : "ak-bore'",
		"derivation" : "probably for H5909 (עַכְבָּר)",
		"def" : "Akbor, the name of an Idumaean and of two Israelites",
		"kjv" : "Achbor"
	},
	"H5908" : {
		"lemma" : "עַכָּבִישׁ",
		"xlit" : "ʻakkâbîysh",
		"pron" : "ak-kaw-beesh'",
		"derivation" : "probably from an unused root in the literal sense of entangling",
		"def" : "a spider (as weaving a network)",
		"kjv" : "spider"
	},
	"H5909" : {
		"lemma" : "עַכְבָּר",
		"xlit" : "ʻakbâr",
		"pron" : "ak-bawr'",
		"derivation" : "probably from the same as H5908 (עַכָּבִישׁ) in the secondary sense of attacking",
		"def" : "a mouse (as nibbling)",
		"kjv" : "mouse"
	},
	"H5910" : {
		"lemma" : "עַכּוֹ",
		"xlit" : "ʻAkkôw",
		"pron" : "ak-ko'",
		"derivation" : "apparently from an unused root meaning to hem in",
		"def" : "Akko (from its situation on a bay)",
		"kjv" : "Accho"
	},
	"H5911" : {
		"lemma" : "עָכוֹר",
		"xlit" : "ʻÂkôwr",
		"pron" : "aw-kore'",
		"derivation" : "from H5916 (עָכַר); troubled",
		"def" : "Akor, the name of a place in Palestine",
		"kjv" : "Achor"
	},
	"H5912" : {
		"lemma" : "עָכָן",
		"xlit" : "ʻÂkân",
		"pron" : "aw-kawn'",
		"derivation" : "from an unused root meaning to trouble; troublesome",
		"def" : "Akan, an Israelite",
		"kjv" : "Achan. Compare H5917 (עָכָר)"
	},
	"H5913" : {
		"lemma" : "עָכַס",
		"xlit" : "ʻâkaç",
		"pron" : "aw-kas'",
		"derivation" : "a primitive root; properly, to tie, specifically, with fetters; but used only as denominative from H5914 (עֶכֶס)",
		"def" : "to put on anklets",
		"kjv" : "make a tinkling ornament"
	},
	"H5914" : {
		"lemma" : "עֶכֶס",
		"xlit" : "ʻekeç",
		"pron" : "eh'-kes",
		"derivation" : "from H5913 (עָכַס)",
		"def" : "a fetter; hence, an anklet",
		"kjv" : "stocks, tinkling ornament"
	},
	"H5915" : {
		"lemma" : "עַכְסָה",
		"xlit" : "ʻAkçâh",
		"pron" : "ak-saw'",
		"derivation" : "feminine of H5914 (עֶכֶס); anklet",
		"def" : "Aksah, an Israelitess",
		"kjv" : "Achsah"
	},
	"H5916" : {
		"lemma" : "עָכַר",
		"xlit" : "ʻâkar",
		"pron" : "aw-kar'",
		"derivation" : "a primitive root",
		"def" : "properly, to roil water; figuratively, to disturb or affict",
		"kjv" : "trouble, stir"
	},
	"H5917" : {
		"lemma" : "עָכָר",
		"xlit" : "ʻÂkâr",
		"pron" : "aw-kawr'",
		"derivation" : "from H5916 (עָכַר); troublesome",
		"def" : "Akar, an Israelite",
		"kjv" : "Achar. Compare H5912 (עָכָן)"
	},
	"H5918" : {
		"lemma" : "עׇכְרָן",
		"xlit" : "ʻOkrân",
		"pron" : "ok-rawn'",
		"derivation" : "from H5916 (עָכַר); muddler",
		"def" : "Okran, an Israelite",
		"kjv" : "Ocran"
	},
	"H5919" : {
		"lemma" : "עַכְשׁוּב",
		"xlit" : "ʻakshûwb",
		"pron" : "ak-shoob'",
		"derivation" : "probably from an unused root meaning to coil",
		"def" : "an asp (from lurking coiled up)",
		"kjv" : "adder"
	},
	"H5920" : {
		"lemma" : "עַל",
		"xlit" : "ʻal",
		"pron" : "al",
		"derivation" : "from H5927 (עָלָה)",
		"def" : "properly, the top; specifically, the highest (i.e. God); also (adverb) aloft, to Jehovah",
		"kjv" : "above, high, most High"
	},
	"H5921" : {
		"lemma" : "עַל",
		"xlit" : "ʻal",
		"pron" : "al",
		"derivation" : "properly, the same as H5920 (עַל) used as a preposition (in the singular or plural often with prefix, or as conjunction with a particle following)",
		"def" : "above, over, upon, or against (yet always in this last relation with a downward aspect) in a great variety of applications",
		"kjv" : "above, according to(-ly), after, (as) against, among, and, [idiom] as, at, because of, beside (the rest of), between, beyond the time, [idiom] both and, by (reason of), [idiom] had the charge of, concerning for, in (that), (forth, out) of, (from) (off), (up-) on, over, than, through(-out), to, touching, [idiom] with"
	},
	"H5922" : {
		"lemma" : "עַל",
		"xlit" : "ʻal",
		"pron" : "al",
		"derivation" : "(Aramaic) corresponding to H5921 (עַל)",
		"def" : "{above, over, upon, or against (yet always in this last relation with a downward aspect) in a great variety of applications}",
		"kjv" : "about, against, concerning, for, (there-) fore, from, in, [idiom] more, of, (there-, up-) on, (in-) to, [phrase] why with"
	},
	"H5923" : {
		"lemma" : "עֹל",
		"xlit" : "ʻôl",
		"pron" : "ole",
		"derivation" : "or עוֹל; from H5953 (עָלַל)",
		"def" : "a yoke (as imposed on the neck), literally or figuratively",
		"kjv" : "yoke"
	},
	"H5924" : {
		"lemma" : "עֵלָּא",
		"xlit" : "ʻêllâʼ",
		"pron" : "ale-law'",
		"derivation" : "(Aramaic) from H5922 (עַל)",
		"def" : "above",
		"kjv" : "over"
	},
	"H5925" : {
		"lemma" : "עֻלָּא",
		"xlit" : "ʻUllâʼ",
		"pron" : "ool-law'",
		"derivation" : "feminine of H5923 (עֹל); burden",
		"def" : "Ulla, an Israelite",
		"kjv" : "Ulla"
	},
	"H5926" : {
		"lemma" : "עִלֵּג",
		"xlit" : "ʻillêg",
		"pron" : "il-layg'",
		"derivation" : "from an unused root meaning to stutter",
		"def" : "stuttering",
		"kjv" : "stammerer"
	},
	"H5927" : {
		"lemma" : "עָלָה",
		"xlit" : "ʻâlâh",
		"pron" : "aw-law'",
		"derivation" : "a primitive root",
		"def" : "to ascend, intransitively (be high) or actively (mount); used in a great variety of senses, primary and secondary, literal and figurative",
		"kjv" : "arise (up), (cause to) ascend up, at once, break (the day) (up), bring (up), (cause to) burn, carry up, cast up, [phrase] shew, climb (up), (cause to, make to) come (up), cut off, dawn, depart, exalt, excel, fall, fetch up, get up, (make to) go (away, up); grow (over) increase, lay, leap, levy, lift (self) up, light, (make) up, [idiom] mention, mount up, offer, make to pay, [phrase] perfect, prefer, put (on), raise, recover, restore, (make to) rise (up), scale, set (up), shoot forth (up), (begin to) spring (up), stir up, take away (up), work"
	},
	"H5928" : {
		"lemma" : "עֲלָה",
		"xlit" : "ʻălâh",
		"pron" : "al-law'",
		"derivation" : "(Aramaic) corresponding to H5930 (עֹלָה)",
		"def" : "a holocaust",
		"kjv" : "burnt offering"
	},
	"H5929" : {
		"lemma" : "עָלֶה",
		"xlit" : "ʻâleh",
		"pron" : "aw-leh'",
		"derivation" : "from H5927 (עָלָה)",
		"def" : "a leaf (as coming up on a tree); collectively, foliage",
		"kjv" : "branch, leaf"
	},
	"H5930" : {
		"lemma" : "עֹלָה",
		"xlit" : "ʻôlâh",
		"pron" : "o-law'",
		"derivation" : "or עוֹלָה; feminine active participle of H5927 (עָלָה)",
		"def" : "a step or (collectively, stairs, as ascending); usually a holocaust (as going up in smoke)",
		"kjv" : "ascent, burnt offering (sacrifice), go up to. See also H5766 (עֶוֶל)"
	},
	"H5931" : {
		"lemma" : "עִלָּה",
		"xlit" : "ʻillâh",
		"pron" : "il-law'",
		"derivation" : "(Aramaic) feminine from a root corresponding to H5927 (עָלָה)",
		"def" : "a pretext (as arising artificially)",
		"kjv" : "occasion"
	},
	"H5932" : {
		"lemma" : "עַלְוָה",
		"xlit" : "ʻalvâh",
		"pron" : "al-vaw'",
		"derivation" : "for H5766 (עֶוֶל)",
		"def" : "moral perverseness",
		"kjv" : "iniquity"
	},
	"H5933" : {
		"lemma" : "עַלְוָה",
		"xlit" : "ʻAlvâh",
		"pron" : "al-vaw'",
		"derivation" : "or עַליָה; the same as H5932 (עַלְוָה)",
		"def" : "Alvah or Aljah, an Idumaean",
		"kjv" : "Aliah, Alvah"
	},
	"H5934" : {
		"lemma" : "עָלוּם",
		"xlit" : "ʻâlûwm",
		"pron" : "aw-loom'",
		"derivation" : "passive participle of H5956 (עָלַם) in the denominative sense of H5958 (עֶלֶם)",
		"def" : "(only in plural as abstract) adolescence; figuratively, vigor",
		"kjv" : "youth"
	},
	"H5935" : {
		"lemma" : "עַלְוָן",
		"xlit" : "ʻAlvân",
		"pron" : "al-vawn'",
		"derivation" : "or עַלְיָן; from H5927 (עָלָה); lofty",
		"def" : "Alvan or Aljan, an Idumaean",
		"kjv" : "Alian, Alvan"
	},
	"H5936" : {
		"lemma" : "עֲלוּקָה",
		"xlit" : "ʻălûwqâh",
		"pron" : "al-oo-kaw'",
		"derivation" : "feminine passive participle of an unused root meaning to suck",
		"def" : "the leech",
		"kjv" : "horse-leech"
	},
	"H5937" : {
		"lemma" : "עָלַז",
		"xlit" : "ʻâlaz",
		"pron" : "aw-laz'",
		"derivation" : "a primitive root",
		"def" : "to jump for joy, i.e. exult",
		"kjv" : "be joyful, rejoice, triumph"
	},
	"H5938" : {
		"lemma" : "עָלֵז",
		"xlit" : "ʻâlêz",
		"pron" : "aw-laze'",
		"derivation" : "from H5937 (עָלַז)",
		"def" : "exultant",
		"kjv" : "that rejoiceth"
	},
	"H5939" : {
		"lemma" : "עֲלָטָה",
		"xlit" : "ʻălâṭâh",
		"pron" : "al-aw-taw'",
		"derivation" : "feminine from an unused root meaning to cover",
		"def" : "dusk",
		"kjv" : "dark, twilight"
	},
	"H5940" : {
		"lemma" : "עֱלִי",
		"xlit" : "ʻĕlîy",
		"pron" : "el-ee'",
		"derivation" : "from H5927 (עָלָה)",
		"def" : "a pestle (as lifted)",
		"kjv" : "pestle"
	},
	"H5941" : {
		"lemma" : "עֵלִי",
		"xlit" : "ʻÊlîy",
		"pron" : "ay-lee'",
		"derivation" : "from H5927 (עָלָה); lofty",
		"def" : "Eli, an Israelite highpriest",
		"kjv" : "Eli"
	},
	"H5942" : {
		"lemma" : "עִלִּי",
		"xlit" : "ʻillîy",
		"pron" : "il-lee'",
		"derivation" : "from H5927 (עָלָה)",
		"def" : "high; i.e. comparative",
		"kjv" : "upper"
	},
	"H5943" : {
		"lemma" : "עִלַּי",
		"xlit" : "ʻillay",
		"pron" : "il-lah'-ee",
		"derivation" : "(Aramaic) corresponding to H5942 (עִלִּי)",
		"def" : "supreme (i.e. God)",
		"kjv" : "(most) high"
	},
	"H5944" : {
		"lemma" : "עֲלִיָּה",
		"xlit" : "ʻălîyâh",
		"pron" : "al-ee-yaw'",
		"derivation" : "feminine from H5927 (עָלָה)",
		"def" : "something lofty, i.e. a stair-way; also a second-story room (or even one on the roof); figuratively, the sky",
		"kjv" : "ascent, (upper) chamber, going up, loft, parlour"
	},
	"H5945" : {
		"lemma" : "עֶלְיוֹן",
		"xlit" : "ʻelyôwn",
		"pron" : "el-yone'",
		"derivation" : "from H5927 (עָלָה)",
		"def" : "an elevation, i.e. (adj.) lofty (compar.); as title, the Supreme",
		"kjv" : "(Most, on) high(-er, -est), upper(-most)"
	},
	"H5946" : {
		"lemma" : "עֶלְיוֹן",
		"xlit" : "ʻelyôwn",
		"pron" : "el-yone'",
		"derivation" : "(Aramaic) corresponding to H5945 (עֶלְיוֹן)",
		"def" : "the Supreme",
		"kjv" : "Most high"
	},
	"H5947" : {
		"lemma" : "עַלִּיז",
		"xlit" : "ʻallîyz",
		"pron" : "al-leez'",
		"derivation" : "from H5937 (עָלַז)",
		"def" : "exultant",
		"kjv" : "joyous, (that) rejoice(-ing)"
	},
	"H5948" : {
		"lemma" : "עֲלִיל",
		"xlit" : "ʻălîyl",
		"pron" : "al-eel'",
		"derivation" : "from H5953 (עָלַל) in the sense of completing",
		"def" : "probably a crucible (as working over the metal)",
		"kjv" : "furnace"
	},
	"H5949" : {
		"lemma" : "עֲלִילָה",
		"xlit" : "ʻălîylâh",
		"pron" : "al-ee-law'",
		"derivation" : "or עֲלִלָה; from H5953 (עָלַל) in the sense of effecting",
		"def" : "an exploit (of God), or a performance (of man, often in a bad sense); by implication, an opportunity",
		"kjv" : "act(-ion), deed, doing, invention, occasion, work"
	},
	"H5950" : {
		"lemma" : "עֲלִילִיָּה",
		"xlit" : "ʻălîylîyâh",
		"pron" : "al-ee-lee-yaw'",
		"derivation" : "for H5949 (עֲלִילָה)",
		"def" : "(miraculous) execution",
		"kjv" : "work"
	},
	"H5951" : {
		"lemma" : "עֲלִיצוּת",
		"xlit" : "ʻălîytsûwth",
		"pron" : "al-ee-tsooth'",
		"derivation" : "from H5970 (עָלַץ)",
		"def" : "exultation",
		"kjv" : "rejoicing"
	},
	"H5952" : {
		"lemma" : "עַלִּית",
		"xlit" : "ʻallîyth",
		"pron" : "al-leeth'",
		"derivation" : "from H5927 (עָלָה)",
		"def" : "a second-story room",
		"kjv" : "chamber. Compare H5944 (עֲלִיָּה)"
	},
	"H5953" : {
		"lemma" : "עָלַל",
		"xlit" : "ʻâlal",
		"pron" : "aw-lal'",
		"derivation" : "a primitive root",
		"def" : "to effect thoroughly; specifically, to glean (also figuratively); by implication (in a bad sense) to overdo, i.e. maltreat, be saucy to, pain, impose (also literal)",
		"kjv" : "abuse, affect, [idiom] child, defile, do, glean, mock, practise, thoroughly, work (wonderfully)"
	},
	"H5954" : {
		"lemma" : "עֲלַל",
		"xlit" : "ʻălal",
		"pron" : "al-al'",
		"derivation" : "(Aramaic) corresponding to H5953 (עָלַל) (in the sense of thrusting oneself in)",
		"def" : "to enter; causatively, to introduce",
		"kjv" : "bring in, come in, go in"
	},
	"H5955" : {
		"lemma" : "עֹלֵלָה",
		"xlit" : "ʻôlêlâh",
		"pron" : "o-lay-law'",
		"derivation" : "feminine active participle of H5953 (עָלַל)",
		"def" : "only in plural gleanings; by extension gleaning-time",
		"kjv" : "(gleaning) (of the) grapes, grapegleanings"
	},
	"H5956" : {
		"lemma" : "עָלַם",
		"xlit" : "ʻâlam",
		"pron" : "aw-lam'",
		"derivation" : "a primitive root",
		"def" : "to veil from sight, i.e. conceal (literally or figuratively)",
		"kjv" : "[idiom] any ways, blind, dissembler, hide (self), secret (thing)"
	},
	"H5957" : {
		"lemma" : "עָלַם",
		"xlit" : "ʻâlam",
		"pron" : "aw-lam'",
		"derivation" : "(Aramaic) corresponding to H5769 (עוֹלָם)",
		"def" : "remote time, i.e. the future or past indefinitely; often adverb, forever",
		"kjv" : "for (n-)ever (lasting), old"
	},
	"H5958" : {
		"lemma" : "עֶלֶם",
		"xlit" : "ʻelem",
		"pron" : "eh'-lem",
		"derivation" : "from H5956 (עָלַם); (compare H5959 (עַלְמָה))",
		"def" : "properly, something kept out of sight, i.e. a lad",
		"kjv" : "young man, stripling"
	},
	"H5959" : {
		"lemma" : "עַלְמָה",
		"xlit" : "ʻalmâh",
		"pron" : "al-maw'",
		"derivation" : "feminine of H5958 (עֶלֶם)",
		"def" : "a lass (as veiled or private)",
		"kjv" : "damsel, maid, virgin"
	},
	"H5960" : {
		"lemma" : "עַלְמוֹן",
		"xlit" : "ʻAlmôwn",
		"pron" : "al-mone'",
		"derivation" : "from H5956 (עָלַם); hidden; See also H5963 (עַלְמֹן דִּבְלָתָיְמָה)",
		"def" : "Almon, a place in Palestine"
	},
	"H5961" : {
		"lemma" : "עֲלָמוֹת",
		"xlit" : "ʻĂlâmôwth",
		"pron" : "al-aw-moth'",
		"derivation" : "plural of H5959 (עַלְמָה)",
		"def" : "properly, girls, i.e. the soprano or female voice, perhaps falsetto",
		"kjv" : "Alamoth"
	},
	"H5962" : {
		"lemma" : "עַלְמִי",
		"xlit" : "ʻAlmîy",
		"pron" : "al-mee'",
		"derivation" : "(Aramaic) patrial from a name corresponding to H5867 (עֵילָם) contracted",
		"def" : "an Elamite or inhabitant of Elam",
		"kjv" : "Elamite"
	},
	"H5963" : {
		"lemma" : "עַלְמֹן דִּבְלָתָיְמָה",
		"xlit" : "ʻAlmôn Diblâthâyᵉmâh",
		"pron" : "al-mone' dib-lawthaw'-yem-aw",
		"derivation" : "from the same as H5960 (עַלְמוֹן) and the dual of H1960 (הֻיְּדָה) (compare H1015 (בֵּית דִּבְלָתַיִם)) with enclitic of direction; Almon towards Diblathajim",
		"def" : "Almon-Diblathajemah, a place in Moab",
		"kjv" : "Almondilathaim"
	},
	"H5964" : {
		"lemma" : "עָלֶמֶת",
		"xlit" : "ʻÂlemeth",
		"pron" : "aw-leh'-meth",
		"derivation" : "from H5956 (עָלַם); a covering",
		"def" : "Alemeth, the name of a place in Palestine and of two Israelites",
		"kjv" : "Alameth, Alemeth"
	},
	"H5965" : {
		"lemma" : "עָלַס",
		"xlit" : "ʻâlaç",
		"pron" : "aw-las'",
		"derivation" : "a primitive root",
		"def" : "to leap for joy, i.e. exult, wave joyously",
		"kjv" : "[idiom] peacock, rejoice, solace self"
	},
	"H5966" : {
		"lemma" : "עָלַע",
		"xlit" : "ʻâlaʻ",
		"pron" : "aw-lah'",
		"derivation" : "a primitive root",
		"def" : "to sip up",
		"kjv" : "suck up"
	},
	"H5967" : {
		"lemma" : "עֲלַע",
		"xlit" : "ʻălaʻ",
		"pron" : "al-ah'",
		"derivation" : "(Aramaic) corresponding to H6763 (צֵלָע)",
		"def" : "a rib",
		"kjv" : "rib"
	},
	"H5968" : {
		"lemma" : "עָלַף",
		"xlit" : "ʻâlaph",
		"pron" : "aw-laf'",
		"derivation" : "a primitive root",
		"def" : "to veil or cover; figuratively, to be languid",
		"kjv" : "faint, overlaid, wrap self"
	},
	"H5969" : {
		"lemma" : "עֻלְפֶּה",
		"xlit" : "ʻulpeh",
		"pron" : "ool-peh'",
		"derivation" : "from H5968 (עָלַף)",
		"def" : "an envelope, i.e. (figuratively) mourning",
		"kjv" : "fainted"
	},
	"H5970" : {
		"lemma" : "עָלַץ",
		"xlit" : "ʻâlats",
		"pron" : "aw-lats'",
		"derivation" : "a primitive root",
		"def" : "to jump for joy, i.e. exult",
		"kjv" : "be joyful, rejoice, triumph"
	},
	"H5971" : {
		"lemma" : "עַם",
		"xlit" : "ʻam",
		"pron" : "am",
		"derivation" : "from H6004 (עָמַם)",
		"def" : "a people (as a congregated unit); specifically, a tribe (as those of Israel); hence (collectively) troops or attendants; figuratively, a flock",
		"kjv" : "folk, men, nation, people"
	},
	"H5972" : {
		"lemma" : "עַם",
		"xlit" : "ʻam",
		"pron" : "am",
		"derivation" : "(Aramaic) corresponding to H5971 (עַם)",
		"def" : "{a people (as a congregated unit); specifically, a tribe (as those of Israel); hence (collectively) troops or attendants; figuratively, a flock}",
		"kjv" : "people"
	},
	"H5973" : {
		"lemma" : "עִם",
		"xlit" : "ʻim",
		"pron" : "eem",
		"derivation" : "from H6004 (עָמַם)",
		"def" : "adverb or preposition, with (i.e. in conjunction with), in varied applications; specifically, equally with; often with prepositional prefix (and then usually unrepresented in English)",
		"kjv" : "accompanying, against, and, as ([idiom] long as), before, beside, by (reason of), for all, from (among, between), in, like, more than, of, (un-) to, with(-al)"
	},
	"H5974" : {
		"lemma" : "עִם",
		"xlit" : "ʻim",
		"pron" : "eem",
		"derivation" : "(Aramaic) corresponding to H5973 (עִם)",
		"def" : "{adverb or preposition, with (i.e. in conjunction with), in varied applications; specifically, equally with; often with prepositional prefix (and then usually unrepresented in English)}",
		"kjv" : "by, from, like, to(-ward), with"
	},
	"H5975" : {
		"lemma" : "עָמַד",
		"xlit" : "ʻâmad",
		"pron" : "aw-mad'",
		"derivation" : "a primitive root",
		"def" : "to stand, in various relations (literal and figurative, intransitive and transitive)",
		"kjv" : "abide (behind), appoint, arise, cease, confirm, continue, dwell, be employed, endure, establish, leave, make, ordain, be (over), place, (be) present (self), raise up, remain, repair, [phrase] serve, set (forth, over, -tle, up), (make to, make to be at a, with-) stand (by, fast, firm, still, up), (be at a) stay (up), tarry"
	},
	"H5976" : {
		"lemma" : "עָמַד",
		"xlit" : "ʻâmad",
		"pron" : "aw-mad'",
		"derivation" : "for H4571 (מָעַד)",
		"def" : "to shake",
		"kjv" : "be at a stand"
	},
	"H5977" : {
		"lemma" : "עֹמֶד",
		"xlit" : "ʻômed",
		"pron" : "o'-med",
		"derivation" : "from H5975 (עָמַד)",
		"def" : "a spot (as being fixed)",
		"kjv" : "place, ([phrase] where) stood, upright"
	},
	"H5978" : {
		"lemma" : "עִמָּד",
		"xlit" : "ʻimmâd",
		"pron" : "im-mawd'",
		"derivation" : "prolonged for H5973 (עִם)",
		"def" : "along with",
		"kjv" : "against, by, from, [phrase] me, [phrase] mine, of, [phrase] that I take, unto, upon, with(-in.)"
	},
	"H5979" : {
		"lemma" : "עֶמְדָּה",
		"xlit" : "ʻemdâh",
		"pron" : "em-daw'",
		"derivation" : "from H5975 (עָמַד)",
		"def" : "a station, i.e. domicile",
		"kjv" : "standing"
	},
	"H5980" : {
		"lemma" : "עֻמָּה",
		"xlit" : "ʻummâh",
		"pron" : "oom-maw'",
		"derivation" : "from H6004 (עָמַם)",
		"def" : "conjunction, i.e. society; mostly adverb or preposition (with prepositional prefix), near, beside, along with",
		"kjv" : "(over) against, at, beside, hard by, in points"
	},
	"H5981" : {
		"lemma" : "עֻמָּה",
		"xlit" : "ʻUmmâh",
		"pron" : "oom-maw'",
		"derivation" : "the same as H5980 (עֻמָּה); association",
		"def" : "Ummah, a place in Palestine",
		"kjv" : "Ummah"
	},
	"H5982" : {
		"lemma" : "עַמּוּד",
		"xlit" : "ʻammûwd",
		"pron" : "am-mood'",
		"derivation" : "or עַמֻּד; from H5975 (עָמַד)",
		"def" : "a column (as standing); also a stand, i.e. platform",
		"kjv" : "[idiom] apiece, pillar"
	},
	"H5983" : {
		"lemma" : "עַמּוֹן",
		"xlit" : "ʻAmmôwn",
		"pron" : "am-mone'",
		"derivation" : "from H5971 (עַם); tribal, i.e. inbred",
		"def" : "Ammon, a son of Lot; also his posterity and their country",
		"kjv" : "Ammon, Ammonites"
	},
	"H5984" : {
		"lemma" : "עַמּוֹנִי",
		"xlit" : "ʻAmmôwnîy",
		"pron" : "am-mo-nee'",
		"derivation" : "patronymically from H5983 (עַמּוֹן)",
		"def" : "an Ammonite or (the adjective) Ammonitish",
		"kjv" : "Ammonite(-s)"
	},
	"H5985" : {
		"lemma" : "עַמּוֹנִית",
		"xlit" : "ʻAmmôwnîyth",
		"pron" : "am-mo-neeth'",
		"derivation" : "feminine of H5984 (עַמּוֹנִי)",
		"def" : "an Ammonitess",
		"kjv" : "Ammonite(-ss)"
	},
	"H5986" : {
		"lemma" : "עָמוֹס",
		"xlit" : "ʻÂmôwç",
		"pron" : "aw-moce'",
		"derivation" : "from H6006 (עָמַס); burdensome",
		"def" : "Amos, an Israelite prophet",
		"kjv" : "Amos"
	},
	"H5987" : {
		"lemma" : "עָמוֹק",
		"xlit" : "ʻÂmôwq",
		"pron" : "aw-moke'",
		"derivation" : "from H6009 (עָמַק); deep",
		"def" : "Amok, an Israelite",
		"kjv" : "Amok"
	},
	"H5988" : {
		"lemma" : "עַמִּיאֵל",
		"xlit" : "ʻAmmîyʼêl",
		"pron" : "am-mee-ale'",
		"derivation" : "from H5971 (עַם) and H410 (אֵל); people of God",
		"def" : "Ammiel, the name of three or four Israelites",
		"kjv" : "Ammiel"
	},
	"H5989" : {
		"lemma" : "עַמִּיהוּד",
		"xlit" : "ʻAmmîyhûwd",
		"pron" : "am-mee-hood'",
		"derivation" : "from H5971 (עַם) and H1935 (הוֹד); people of splendor",
		"def" : "Ammihud, the name of three Israelites",
		"kjv" : "Ammihud"
	},
	"H5990" : {
		"lemma" : "עַמִּיזָבָד",
		"xlit" : "ʻAmmîyzâbâd",
		"pron" : "am-mee-zaw-bawd'",
		"derivation" : "from H5971 (עַם) and H2064 (זָבַד); people of endowment",
		"def" : "Ammizabad, an Israelite",
		"kjv" : "Ammizabad"
	},
	"H5991" : {
		"lemma" : "עַמִּיחוּר",
		"xlit" : "ʻAmmîychûwr",
		"pron" : "am-mee-khoor'",
		"derivation" : "from H5971 (עַם) and H2353 (חוּר); people of nobility",
		"def" : "Ammichur, a Syrian prince",
		"kjv" : "Ammihud (from the margin)"
	},
	"H5992" : {
		"lemma" : "עַמִּינָדָב",
		"xlit" : "ʻAmmîynâdâb",
		"pron" : "am-mee-naw-dawb'",
		"derivation" : "from H5971 (עַם) and H5068 (נָדַב); people of liberality",
		"def" : "Amminadab, the name of four Israelites",
		"kjv" : "Amminadab"
	},
	"H5993" : {
		"lemma" : "עַמִּי נָדִיב",
		"xlit" : "ʻAmmîy Nâdîyb",
		"pron" : "am-mee' naw-deeb'",
		"derivation" : "from H5971 (עַם) and H5081 (נָדִיב); my people (is) liberal",
		"def" : "Ammi-Nadib, probably an Israelite",
		"kjv" : "Amminadib"
	},
	"H5994" : {
		"lemma" : "עֲמִיק",
		"xlit" : "ʻămîyq",
		"pron" : "am-eek'",
		"derivation" : "(Aramaic) corresponding to H6012 (עָמֵק)",
		"def" : "profound, i.e. unsearchable",
		"kjv" : "deep"
	},
	"H5995" : {
		"lemma" : "עָמִיר",
		"xlit" : "ʻâmîyr",
		"pron" : "aw-meer'",
		"derivation" : "from H6014 (עָמַר)",
		"def" : "a bunch of grain",
		"kjv" : "handful, sheaf"
	},
	"H5996" : {
		"lemma" : "עַמִּישַׁדַּי",
		"xlit" : "ʻAmmîyshadday",
		"pron" : "am-mee-shad-dah'ee",
		"derivation" : "from H5971 (עַם) and H7706 (שַׁדַּי); people of (the) Almighty",
		"def" : "Ammishaddai, an Israelite",
		"kjv" : "Ammishaddai"
	},
	"H5997" : {
		"lemma" : "עָמִית",
		"xlit" : "ʻâmîyth",
		"pron" : "aw-meeth'",
		"derivation" : "from a primitive root meaning to associate",
		"def" : "companionship; hence (concretely) a comrade or kindred man",
		"kjv" : "another, fellow, neighbour"
	},
	"H5998" : {
		"lemma" : "עָמַל",
		"xlit" : "ʻâmal",
		"pron" : "aw-mal'",
		"derivation" : "a primitive root",
		"def" : "to toil, i.e. work severely and with irksomeness",
		"kjv" : "(take) labour (in)"
	},
	"H5999" : {
		"lemma" : "עָמָל",
		"xlit" : "ʻâmâl",
		"pron" : "aw-mawl'",
		"derivation" : "from H5998 (עָמַל)",
		"def" : "toil, i.e. wearing effort; hence, worry, wheth. of body or mind",
		"kjv" : "grievance(-vousness), iniquity, labour, mischief, miserable(-sery), pain(-ful), perverseness, sorrow, toil, travail, trouble, wearisome, wickedness"
	},
	"H6000" : {
		"lemma" : "עָמָל",
		"xlit" : "ʻÂmâl",
		"pron" : "aw-mawl'",
		"derivation" : "the same as H5999 (עָמָל)",
		"def" : "Amal, an Israelite",
		"kjv" : "Amal"
	},
	"H6001" : {
		"lemma" : "עָמֵל",
		"xlit" : "ʻâmêl",
		"pron" : "aw-male'",
		"derivation" : "from H5998 (עָמַל)",
		"def" : "toiling; concretely, a laborer; figuratively, sorrowful",
		"kjv" : "that laboureth, that is a misery, had taken (labour), wicked, workman"
	},
	"H6002" : {
		"lemma" : "עֲמָלֵק",
		"xlit" : "ʻĂmâlêq",
		"pron" : "am-aw-lake'",
		"derivation" : "probably of foreign origin",
		"def" : "Amalek, a descendant of Esau; also his posterity and their country",
		"kjv" : "Amalek"
	},
	"H6003" : {
		"lemma" : "עֲמָלֵקִי",
		"xlit" : "ʻĂmâlêqîy",
		"pron" : "am-aw-lay-kee'",
		"derivation" : "patronymically from H6002 (עֲמָלֵק)",
		"def" : "an Amalekite (or collectively the Amalekites) or descendants of Amalek",
		"kjv" : "Amalekite(-s)"
	},
	"H6004" : {
		"lemma" : "עָמַם",
		"xlit" : "ʻâmam",
		"pron" : "aw-mam'",
		"derivation" : "a primitive root",
		"def" : "to associate; by implication, to overshadow (by huddling together)",
		"kjv" : "become dim, hide"
	},
	"H6005" : {
		"lemma" : "עִמָּנוּאֵל",
		"xlit" : "ʻImmânûwʼêl",
		"pron" : "im-maw-noo-ale'",
		"derivation" : "from H5973 (עִם) and with a pronominal suffix inserted; with us (is) God",
		"def" : "Immanuel, a type name of Isaiah's son",
		"kjv" : "Immanuel"
	},
	"H6006" : {
		"lemma" : "עָמַס",
		"xlit" : "ʻâmaç",
		"pron" : "aw-mas'",
		"derivation" : "or עָמַשׂ; a primitive root",
		"def" : "to load, i.e. impose aburden (or figuratively, infliction)",
		"kjv" : "be borne, (heavy) burden (self), lade, load, put"
	},
	"H6007" : {
		"lemma" : "עֲמַסְיָה",
		"xlit" : "ʻĂmaçyâh",
		"pron" : "am-as-yaw'",
		"derivation" : "from H6006 (עָמַס) and H3050 (יָהּ); Jah has loaded",
		"def" : "Amasjah, an Israelite",
		"kjv" : "Amasiah"
	},
	"H6008" : {
		"lemma" : "עַמְעָד",
		"xlit" : "ʻAmʻâd",
		"pron" : "am-awd'",
		"derivation" : "from H5971 (עַם) and H5703 (עַד); people of time",
		"def" : "Amad, a place in Palestine",
		"kjv" : "Amad"
	},
	"H6009" : {
		"lemma" : "עָמַק",
		"xlit" : "ʻâmaq",
		"pron" : "aw-mak'",
		"derivation" : "a primitive root",
		"def" : "to be (causatively, make) deep (literally or figuratively)",
		"kjv" : "(be, have, make, seek) deep(-ly), depth, be profound"
	},
	"H6010" : {
		"lemma" : "עֵמֶק",
		"xlit" : "ʻêmeq",
		"pron" : "ay'-mek",
		"derivation" : "from H6009 (עָמַק)",
		"def" : "a vale (i.e. broad depression)",
		"kjv" : "dale, vale, valley (often used as a part of proper names). See also H1025 (בֵּית הָעֵמֶק)"
	},
	"H6011" : {
		"lemma" : "עֹמֶק",
		"xlit" : "ʻômeq",
		"pron" : "o'-mek",
		"derivation" : "from H6009 (עָמַק)",
		"def" : "depth",
		"kjv" : "depth"
	},
	"H6012" : {
		"lemma" : "עָמֵק",
		"xlit" : "ʻâmêq",
		"pron" : "aw-make'",
		"derivation" : "from H6009 (עָמַק)",
		"def" : "deep (literally or figuratively)",
		"kjv" : "deeper, depth, strange"
	},
	"H6013" : {
		"lemma" : "עָמֹק",
		"xlit" : "ʻâmôq",
		"pron" : "aw-moke'",
		"derivation" : "from H6009 (עָמַק)",
		"def" : "deep (literally or figuratively)",
		"kjv" : "([idiom] exceeding) deep (thing)"
	},
	"H6014" : {
		"lemma" : "עָמַר",
		"xlit" : "ʻâmar",
		"pron" : "aw-mar'",
		"derivation" : "a primitive root; properly, apparently to heap; figuratively, to chastise (as if piling blows); specifically (as denominative from H6016 (עֹמֶר))",
		"def" : "to gather grain",
		"kjv" : "bind sheaves, make merchandise of"
	},
	"H6015" : {
		"lemma" : "עֲמַר",
		"xlit" : "ʻămar",
		"pron" : "am-ar'",
		"derivation" : "(Aramaic) corresponding to H6785 (צֶמֶר)",
		"def" : "wool",
		"kjv" : "wool"
	},
	"H6016" : {
		"lemma" : "עֹמֶר",
		"xlit" : "ʻômer",
		"pron" : "o'-mer",
		"derivation" : "from H6014 (עָמַר)",
		"def" : "properly, a heap, i.e. a sheaf; also an omer, as a dry measure",
		"kjv" : "omer, sheaf"
	},
	"H6017" : {
		"lemma" : "עֲמֹרָה",
		"xlit" : "ʻĂmôrâh",
		"pron" : "am-o-raw'",
		"derivation" : "from H6014 (עָמַר); a (ruined) heap",
		"def" : "Amorah, a place in Palestine",
		"kjv" : "Gomorrah"
	},
	"H6018" : {
		"lemma" : "עׇמְרִי",
		"xlit" : "ʻOmrîy",
		"pron" : "om-ree'",
		"derivation" : "from H6014 (עָמַר); heaping",
		"def" : "Omri, an Israelite",
		"kjv" : "Omri"
	},
	"H6019" : {
		"lemma" : "עַמְרָם",
		"xlit" : "ʻAmrâm",
		"pron" : "am-rawm'",
		"derivation" : "probably from H5971 (עַם) and H7311 (רוּם); high people",
		"def" : "Amram, the name of two Israelites",
		"kjv" : "Amram"
	},
	"H6020" : {
		"lemma" : "עַמְרָמִי",
		"xlit" : "ʻAmrâmîy",
		"pron" : "am-raw-mee'",
		"derivation" : "from H6019 (עַמְרָם)",
		"def" : "an Amramite or descendant of Amram",
		"kjv" : "Amramite"
	},
	"H6021" : {
		"lemma" : "עֲמָשָׂא",
		"xlit" : "ʻĂmâsâʼ",
		"pron" : "am-aw-saw'",
		"derivation" : "from H6006 (עָמַס); burden",
		"def" : "Amasa, the name of two Israelites",
		"kjv" : "Amasa"
	},
	"H6022" : {
		"lemma" : "עֲמָשַׂי",
		"xlit" : "ʻĂmâsay",
		"pron" : "am-aw-sah'-ee",
		"derivation" : "from H6006 (עָמַס); burdensome",
		"def" : "Amasai, the name of three Israelites",
		"kjv" : "Amasai"
	},
	"H6023" : {
		"lemma" : "עֲמַשְׁסַי",
		"xlit" : "ʻĂmashçay",
		"pron" : "am-ash-sah'-ee",
		"derivation" : "probably from H6006 (עָמַס); burdensome",
		"def" : "Amashsay, an Israelite",
		"kjv" : "Amashai"
	},
	"H6024" : {
		"lemma" : "עֲנָב",
		"xlit" : "ʻĂnâb",
		"pron" : "an-awb'",
		"derivation" : "from the same as H6025 (עֵנָב); fruit",
		"def" : "Anab, a place in Palestine",
		"kjv" : "Anab"
	},
	"H6025" : {
		"lemma" : "עֵנָב",
		"xlit" : "ʻênâb",
		"pron" : "ay-nawb'",
		"derivation" : "from an unused root probably meaning to bear fruit",
		"def" : "a grape",
		"kjv" : "(ripe) grape, wine"
	},
	"H6026" : {
		"lemma" : "עָנַג",
		"xlit" : "ʻânag",
		"pron" : "aw-nag'",
		"derivation" : "a primitive root",
		"def" : "to be soft or pliable, i.e. (figuratively) effeminate or luxurious",
		"kjv" : "delicate(-ness), (have) delight (self), sport self"
	},
	"H6027" : {
		"lemma" : "עֹנֶג",
		"xlit" : "ʻôneg",
		"pron" : "o'-neg",
		"derivation" : "from H6026 (עָנַג)",
		"def" : "luxury",
		"kjv" : "delight, pleasant"
	},
	"H6028" : {
		"lemma" : "עָנֹג",
		"xlit" : "ʻânôg",
		"pron" : "aw-nogue'",
		"derivation" : "from H6026 (עָנַג)",
		"def" : "luxurious",
		"kjv" : "delicate"
	},
	"H6029" : {
		"lemma" : "עָנַד",
		"xlit" : "ʻânad",
		"pron" : "aw-nad'",
		"derivation" : "a primitive root",
		"def" : "to lace fast",
		"kjv" : "bind, tie"
	},
	"H6030" : {
		"lemma" : "עָנָה",
		"xlit" : "ʻânâh",
		"pron" : "aw-naw'",
		"derivation" : "a primitive root",
		"def" : "properly, to eye or (generally) to heed, i.e. pay attention; by implication, to respond; by extension to begin to speak; specifically to sing, shout, testify, announce",
		"kjv" : "give account, afflict (by mistake for H6031 (עָנָה)), (cause to, give) answer, bring low (by mistake for H6031 (עָנָה)), cry, hear, Leannoth, lift up, say, [idiom] scholar, (give a) shout, sing (together by course), speak, testify, utter, (bear) witness. See also H1042 (בֵּית עֲנוֹת), H1043 (בֵּית עֲנָת)"
	},
	"H6031" : {
		"lemma" : "עָנָה",
		"xlit" : "ʻânâh",
		"pron" : "aw-naw'",
		"derivation" : "a primitive root (possibly rather identical with H6030 (עָנָה) through the idea of looking down or browbeating)",
		"def" : "to depress literally or figuratively, transitive or intransitive (in various applications, as follows)",
		"kjv" : "abase self, afflict(-ion, self), answer (by mistake for H6030 (עָנָה)), chasten self, deal hardly with, defile, exercise, force, gentleness, humble (self), hurt, ravish, sing (by mistake for H6030 (עָנָה)), speak (by mistake for H6030 (עָנָה)), submit self, weaken, [idiom] in any wise"
	},
	"H6032" : {
		"lemma" : "עֲנָה",
		"xlit" : "ʻănâh",
		"pron" : "an-aw'",
		"derivation" : "(Aramaic) corresponding to H6030 (עָנָה)",
		"def" : "{properly, to eye or (generally) to heed, i.e. pay attention; by implication, to respond; by extension to begin to speak; specifically to sing, shout, testify, announce}",
		"kjv" : "answer, speak"
	},
	"H6033" : {
		"lemma" : "עֲנָה",
		"xlit" : "ʻănâh",
		"pron" : "an-aw'",
		"derivation" : "(Aramaic) corresponding to H6031 (עָנָה)",
		"def" : "{to depress literally or figuratively, transitive or intransitive (in various applications, as follows)}",
		"kjv" : "poor"
	},
	"H6034" : {
		"lemma" : "עֲנָה",
		"xlit" : "ʻĂnâh",
		"pron" : "an-aw'",
		"derivation" : "probably from H6030 (עָנָה); an answer",
		"def" : "Anah, the name of two Edomites and one Edomitess",
		"kjv" : "Anah"
	},
	"H6035" : {
		"lemma" : "עָנָו",
		"xlit" : "ʻânâv",
		"pron" : "aw-nawv'",
		"derivation" : "or (by intermixture with H6041 (עָנִי)) עָנָיו; from H6031 (עָנָה)",
		"def" : "depressed (figuratively), in mind (gentle) or circumstances (needy, especially saintly)",
		"kjv" : "humble, lowly, meek, poor. Compare H6041 (עָנִי)"
	},
	"H6036" : {
		"lemma" : "עָנוּב",
		"xlit" : "ʻÂnûwb",
		"pron" : "aw-noob'",
		"derivation" : "passive participle from the same as H6025 (עֵנָב); borne (as fruit)",
		"def" : "Anub, an Israelite",
		"kjv" : "Anub"
	},
	"H6037" : {
		"lemma" : "עַנְוָה",
		"xlit" : "ʻanvâh",
		"pron" : "an-vaw'",
		"derivation" : "feminine of H6035 (עָנָו)",
		"def" : "mildness (royal); also (concretely) oppressed",
		"kjv" : "gentleness, meekness"
	},
	"H6038" : {
		"lemma" : "עֲנָוָה",
		"xlit" : "ʻănâvâh",
		"pron" : "an-aw-vaw'",
		"derivation" : "from H6035 (עָנָו)",
		"def" : "condescension, human and subjective (modesty), or divine and objective (clemency)",
		"kjv" : "gentleness, humility, meekness"
	},
	"H6039" : {
		"lemma" : "עֱנוּת",
		"xlit" : "ʻĕnûwth",
		"pron" : "en-ooth'",
		"derivation" : "from H6031 (עָנָה)",
		"def" : "affliction",
		"kjv" : "affliction"
	},
	"H6040" : {
		"lemma" : "עֳנִי",
		"xlit" : "ʻŏnîy",
		"pron" : "on-ee'",
		"derivation" : "from H6031 (עָנָה)",
		"def" : "depression, i.e. misery",
		"kjv" : "afflicted(-ion), trouble"
	},
	"H6041" : {
		"lemma" : "עָנִי",
		"xlit" : "ʻânîy",
		"pron" : "aw-nee'",
		"derivation" : "from H6031 (עָנָה); (practically the same as H6035 (עָנָו), although the margin constantly disputes this, making H6035 (עָנָו) subjective and objective)",
		"def" : "depressed, in mind or circumstances",
		"kjv" : "afflicted, humble, lowly, needy, poor"
	},
	"H6042" : {
		"lemma" : "עֻנִּי",
		"xlit" : "ʻUnnîy",
		"pron" : "oon-nee'",
		"derivation" : "from H6031 (עָנָה); afflicted",
		"def" : "Unni, the name of two Israelites",
		"kjv" : "Unni"
	},
	"H6043" : {
		"lemma" : "עֲנָיָה",
		"xlit" : "ʻĂnâyâh",
		"pron" : "an-aw-yaw'",
		"derivation" : "from H6030 (עָנָה); Jah has answered",
		"def" : "Anajah, the name of two Israelites",
		"kjv" : "Anaiah"
	},
	"H6044" : {
		"lemma" : "עָנִים",
		"xlit" : "ʻÂnîym",
		"pron" : "aw-neem'",
		"derivation" : "for plural of H5869 (עַיִן); fountains",
		"def" : "Anim, a place in Palestine",
		"kjv" : "Anim"
	},
	"H6045" : {
		"lemma" : "עִנְיָן",
		"xlit" : "ʻinyân",
		"pron" : "in-yawn'",
		"derivation" : "from H6031 (עָנָה)",
		"def" : "ado, i.e. (generally) employment or (specifically) an affair",
		"kjv" : "business, travail"
	},
	"H6046" : {
		"lemma" : "עָנֵם",
		"xlit" : "ʻÂnêm",
		"pron" : "aw-name'",
		"derivation" : "from the dual of H5869 (עַיִן); two fountains",
		"def" : "Anem, a place in Palestine",
		"kjv" : "Anem"
	},
	"H6047" : {
		"lemma" : "עֲנָמִים",
		"xlit" : "ʻĂnâmîym",
		"pron" : "an-aw-meem'",
		"derivation" : "as if plural of some Egyptian word",
		"def" : "Anamim, a son of Mizraim and his descendants, with their country",
		"kjv" : "Anamim"
	},
	"H6048" : {
		"lemma" : "עֲנַמֶּלֶךְ",
		"xlit" : "ʻĂnammelek",
		"pron" : "an-am-meh'-lek",
		"derivation" : "of foreign origin",
		"def" : "Anammelek, an Assyrian deity",
		"kjv" : "Anammelech"
	},
	"H6049" : {
		"lemma" : "עָנַן",
		"xlit" : "ʻânan",
		"pron" : "aw-nan'",
		"derivation" : "a primitive root; to cover; used only as a denominative from H6051 (עָנָן), to cloud over",
		"def" : "figuratively, to act covertly, i.e. practise magic",
		"kjv" : "[idiom] bring, enchanter, Meonemin, observe(-r of) times, soothsayer, sorcerer"
	},
	"H6050" : {
		"lemma" : "עֲנַן",
		"xlit" : "ʻănan",
		"pron" : "an-an'",
		"derivation" : "(Aramaic) corresponding to H6051 (עָנָן)",
		"def" : "{a cloud (as covering the sky), i.e. the nimbus or thunder-cloud}",
		"kjv" : "cloud"
	},
	"H6051" : {
		"lemma" : "עָנָן",
		"xlit" : "ʻânân",
		"pron" : "aw-nawn'",
		"derivation" : "from H6049 (עָנַן)",
		"def" : "a cloud (as covering the sky), i.e. the nimbus or thunder-cloud",
		"kjv" : "cloud(-y)"
	},
	"H6052" : {
		"lemma" : "עָנָן",
		"xlit" : "ʻÂnân",
		"pron" : "aw-nawn'",
		"derivation" : "the same as H6051 (עָנָן); cloud",
		"def" : "Anan, an Israelite",
		"kjv" : "Anan"
	},
	"H6053" : {
		"lemma" : "עֲנָנָה",
		"xlit" : "ʻănânâh",
		"pron" : "an-aw-naw'",
		"derivation" : "feminine of H6051 (עָנָן)",
		"def" : "cloudiness",
		"kjv" : "cloud"
	},
	"H6054" : {
		"lemma" : "עֲנָנִי",
		"xlit" : "ʻĂnânîy",
		"pron" : "an-aw-nee'",
		"derivation" : "from H6051 (עָנָן); cloudy",
		"def" : "Anani, an Israelite",
		"kjv" : "Anani"
	},
	"H6055" : {
		"lemma" : "עֲנַנְיָה",
		"xlit" : "ʻĂnanyâh",
		"pron" : "an-an-yaw'",
		"derivation" : "from H6049 (עָנַן) and H3050 (יָהּ); Jah has covered",
		"def" : "Ananjah, the name of an Israelite and of a place in Palestine",
		"kjv" : "Ananiah"
	},
	"H6056" : {
		"lemma" : "עֲנַף",
		"xlit" : "ʻănaph",
		"pron" : "an-af'",
		"derivation" : "(Aramaic) or עֶנֶף; (Aramaic), corresponding to H6057 (עָנָף)",
		"def" : "{a twig (as covering the limbs)}",
		"kjv" : "bough, branch"
	},
	"H6057" : {
		"lemma" : "עָנָף",
		"xlit" : "ʻânâph",
		"pron" : "aw-nawf'",
		"derivation" : "from an unused root meaning to cover",
		"def" : "a twig (as covering the limbs)",
		"kjv" : "bough, branch"
	},
	"H6058" : {
		"lemma" : "עָנֵף",
		"xlit" : "ʻânêph",
		"pron" : "aw-nafe'",
		"derivation" : "from the same as H6057 (עָנָף)",
		"def" : "branching",
		"kjv" : "full of branches"
	},
	"H6059" : {
		"lemma" : "עָנַק",
		"xlit" : "ʻânaq",
		"pron" : "aw-nak'",
		"derivation" : "a primitive root; properly, to choke; used only as denominative from H6060 (עָנָק)",
		"def" : "to collar, i.e. adorn with anecklace; figuratively, to fit out with supplies",
		"kjv" : "compass about as a chain, furnish, liberally"
	},
	"H6060" : {
		"lemma" : "עָנָק",
		"xlit" : "ʻânâq",
		"pron" : "aw-nawk'",
		"derivation" : "from H6059 (עָנַק)",
		"def" : "a necklace (as if strangling)",
		"kjv" : "chain"
	},
	"H6061" : {
		"lemma" : "עָנָק",
		"xlit" : "ʻÂnâq",
		"pron" : "aw-nawk'",
		"derivation" : "the same as H6060 (עָנָק)",
		"def" : "Anak, a Canaanite",
		"kjv" : "Anak"
	},
	"H6062" : {
		"lemma" : "עֲנָקִי",
		"xlit" : "ʻĂnâqîy",
		"pron" : "an-aw-kee'",
		"derivation" : "patronymically from H6061 (עָנָק)",
		"def" : "an Anakite or descendant of Anak",
		"kjv" : "Anakim"
	},
	"H6063" : {
		"lemma" : "עָנֵר",
		"xlit" : "ʻÂnêr",
		"pron" : "aw-nare'",
		"derivation" : "probably for H5288 (נַעַר)",
		"def" : "Aner, a Amorite, also a place in Palestine",
		"kjv" : "Aner"
	},
	"H6064" : {
		"lemma" : "עָנַשׁ",
		"xlit" : "ʻânash",
		"pron" : "aw-nash'",
		"derivation" : "a primitive root",
		"def" : "properly, to urge; by implication, to inflict apenalty, specifically, to fine",
		"kjv" : "amerce, condemn, punish, [idiom] surely"
	},
	"H6065" : {
		"lemma" : "עֲנַשׁ",
		"xlit" : "ʻănash",
		"pron" : "an-ash'",
		"derivation" : "(Aramaic) corresponding to H6066 (עֹנֶשׁ)",
		"def" : "a mulct",
		"kjv" : "confiscation"
	},
	"H6066" : {
		"lemma" : "עֹנֶשׁ",
		"xlit" : "ʻônesh",
		"pron" : "o'-nesh",
		"derivation" : "from H6064 (עָנַשׁ)",
		"def" : "a fine",
		"kjv" : "punishment, tribute"
	},
	"H6067" : {
		"lemma" : "עֲנָת",
		"xlit" : "ʻĂnâth",
		"pron" : "an-awth'",
		"derivation" : "from H6030 (עָנָה); answer",
		"def" : "Anath, an Israelite",
		"kjv" : "Anath"
	},
	"H6068" : {
		"lemma" : "עֲנָתוֹת",
		"xlit" : "ʻĂnâthôwth",
		"pron" : "an-aw-thoth'",
		"derivation" : "plural of H6067 (עֲנָת)",
		"def" : "Anathoth, the name of two Israelites, also of a place in Pal",
		"kjv" : "Anathoth"
	},
	"H6069" : {
		"lemma" : "עַנְתֹתִי",
		"xlit" : "ʻAnthôthîy",
		"pron" : "an-tho-thee'",
		"derivation" : "or עַנְּתוֹתִי; patrial from H6068 (עֲנָתוֹת)",
		"def" : "a Antothite or inhabitant of Anathoth",
		"kjv" : "of Anathoth, Anethothite, Anetothite, Antothite"
	},
	"H6070" : {
		"lemma" : "עַנְתֹתִיָּה",
		"xlit" : "ʻAnthôthîyâh",
		"pron" : "an-tho-thee-yaw'",
		"derivation" : "from the same as H6068 (עֲנָתוֹת) and H3050 (יָהּ); answers of Jah",
		"def" : "Anthothijah, an Israelite",
		"kjv" : "Antothijah"
	},
	"H6071" : {
		"lemma" : "עָסִיס",
		"xlit" : "ʻâçîyç",
		"pron" : "aw-sees'",
		"derivation" : "from H6072 (עָסַס)",
		"def" : "must or fresh grape-juice (as just trodden out)",
		"kjv" : "juice, new (sweet) wine"
	},
	"H6072" : {
		"lemma" : "עָסַס",
		"xlit" : "ʻâçaç",
		"pron" : "aw-sas'",
		"derivation" : "a primitive root",
		"def" : "to squeeze out juice; figuratively, to trample",
		"kjv" : "tread down"
	},
	"H6073" : {
		"lemma" : "עֳפֶא",
		"xlit" : "ʻŏpheʼ",
		"pron" : "of-eh'",
		"derivation" : "from an unused root meaning to cover",
		"def" : "a bough (as covering the tree)",
		"kjv" : "branch"
	},
	"H6074" : {
		"lemma" : "עֳפִי",
		"xlit" : "ʻŏphîy",
		"pron" : "of-ee'",
		"derivation" : "(Aramaic) corresponding to H6073 (עֳפֶא)",
		"def" : "a twig; bough, i.e. (collectively) foliage",
		"kjv" : "leaves"
	},
	"H6075" : {
		"lemma" : "עָפַל",
		"xlit" : "ʻâphal",
		"pron" : "aw-fal'",
		"derivation" : "a primitive root",
		"def" : "to swell; figuratively, be elated",
		"kjv" : "be lifted up, presume"
	},
	"H6076" : {
		"lemma" : "עֹפֶל",
		"xlit" : "ʻôphel",
		"pron" : "o'-fel",
		"derivation" : "from H6075 (עָפַל)",
		"def" : "a tumor; also a mound, i.e. fortress",
		"kjv" : "emerod, fort, strong hold, tower"
	},
	"H6077" : {
		"lemma" : "עֹפֶל",
		"xlit" : "ʻÔphel",
		"pron" : "o'-fel",
		"derivation" : "the same as H6076 (עֹפֶל)",
		"def" : "Ophel, a ridge in Jerusalem",
		"kjv" : "Ophel"
	},
	"H6078" : {
		"lemma" : "עׇפְנִי",
		"xlit" : "ʻOphnîy",
		"pron" : "of-nee'",
		"derivation" : "from an unused noun (denoting a place in Palestine; from an unused root of uncertain meaning)",
		"def" : "an Ophnite (collectively) or inhabitants of Ophen",
		"kjv" : "Ophni"
	},
	"H6079" : {
		"lemma" : "עַפְעַף",
		"xlit" : "ʻaphʻaph",
		"pron" : "af-af'",
		"derivation" : "from H5774 (עוּף)",
		"def" : "an eyelash (as fluttering); figuratively, morning ray",
		"kjv" : "dawning, eye-lid"
	},
	"H6080" : {
		"lemma" : "עָפַר",
		"xlit" : "ʻâphar",
		"pron" : "aw-far'",
		"derivation" : "; a primitive root; meaning either to be gray or perhaps rather to pulverize; used only as denominative from H6083 (עָפָר)",
		"def" : "to be dust",
		"kjv" : "cast (dust)"
	},
	"H6081" : {
		"lemma" : "עֵפֶר",
		"xlit" : "ʻÊpher",
		"pron" : "ay'-fer",
		"derivation" : "probably a variation of H6082 (עֹפֶר); gazelle",
		"def" : "Epher, the name of an Arabian and of two Israelites",
		"kjv" : "Epher"
	},
	"H6082" : {
		"lemma" : "עֹפֶר",
		"xlit" : "ʻôpher",
		"pron" : "o'-fer",
		"derivation" : "from H6080 (עָפַר)",
		"def" : "a fawn (from the dusty color)",
		"kjv" : "young roe (hart)"
	},
	"H6083" : {
		"lemma" : "עָפָר",
		"xlit" : "ʻâphâr",
		"pron" : "aw-fawr'",
		"derivation" : "from H6080 (עָפַר)",
		"def" : "dust (as powdered or gray); hence, clay, earth, mud",
		"kjv" : "ashes, dust, earth, ground, morter, powder, rubbish"
	},
	"H6084" : {
		"lemma" : "עׇפְרָה",
		"xlit" : "ʻOphrâh",
		"pron" : "of-raw'",
		"derivation" : "feminine of H6082 (עֹפֶר); female fawn",
		"def" : "Ophrah, the name of an Israelite and of two places in Palestine",
		"kjv" : "Ophrah"
	},
	"H6085" : {
		"lemma" : "עֶפְרוֹן",
		"xlit" : "ʻEphrôwn",
		"pron" : "ef-rone'",
		"derivation" : "from the same as H6081 (עֵפֶר); fawn-like",
		"def" : "Ephron, the name of a Canaanite and of two places in Palestine",
		"kjv" : "Ephron, Ephrain (from the margin)"
	},
	"H6086" : {
		"lemma" : "עֵץ",
		"xlit" : "ʻêts",
		"pron" : "ates",
		"derivation" : "from H6095 (עָצָה)",
		"def" : "a tree (from its firmness); hence, wood (plural sticks)",
		"kjv" : "[phrase] carpenter, gallows, helve, [phrase] pine, plank, staff, stalk, stick, stock, timber, tree, wood"
	},
	"H6087" : {
		"lemma" : "עָצַב",
		"xlit" : "ʻâtsab",
		"pron" : "aw-tsab'",
		"derivation" : "a primitive root",
		"def" : "properly, to carve, i.e. fabricate or fashion; hence (in a bad sense) to worry, pain or anger",
		"kjv" : "displease, grieve, hurt, make, be sorry, vex, worship, wrest"
	},
	"H6088" : {
		"lemma" : "עֲצַב",
		"xlit" : "ʻătsab",
		"pron" : "ats-ab'",
		"derivation" : "(Aramaic) corresponding to H6087 (עָצַב)",
		"def" : "to afflict",
		"kjv" : "lamentable"
	},
	"H6089" : {
		"lemma" : "עֶצֶב",
		"xlit" : "ʻetseb",
		"pron" : "eh'-tseb",
		"derivation" : "from H6087 (עָצַב)",
		"def" : "an earthen vessel; usually (painful) toil; also a pang (whether of body or mind)",
		"kjv" : "grievous, idol, labor, sorrow"
	},
	"H6090" : {
		"lemma" : "עֹצֶב",
		"xlit" : "ʻôtseb",
		"pron" : "o'-tseb",
		"derivation" : "a variation of H6089 (עֶצֶב)",
		"def" : "an idol (as fashioned); also pain (bodily or mental)",
		"kjv" : "idol, sorrow, [idiom] wicked"
	},
	"H6091" : {
		"lemma" : "עָצָב",
		"xlit" : "ʻâtsâb",
		"pron" : "aw-tsawb'",
		"derivation" : "from H6087 (עָצַב)",
		"def" : "an (idolatrous) image",
		"kjv" : "idol, image"
	},
	"H6092" : {
		"lemma" : "עָצֵב",
		"xlit" : "ʻâtsêb",
		"pron" : "aw-tsabe'",
		"derivation" : "from H6087 (עָצַב)",
		"def" : "a (hired) workman",
		"kjv" : "labour"
	},
	"H6093" : {
		"lemma" : "עִצָּבוֹן",
		"xlit" : "ʻitstsâbôwn",
		"pron" : "its-tsaw-bone'",
		"derivation" : "from H6087 (עָצַב)",
		"def" : "worrisomeness, i.e. labor or pain",
		"kjv" : "sorrow, toil"
	},
	"H6094" : {
		"lemma" : "עַצֶּבֶת",
		"xlit" : "ʻatstsebeth",
		"pron" : "ats-tseh'-beth",
		"derivation" : "from H6087 (עָצַב)",
		"def" : "a idol; also, a pain or wound",
		"kjv" : "sorrow, wound"
	},
	"H6095" : {
		"lemma" : "עָצָה",
		"xlit" : "ʻâtsâh",
		"pron" : "aw-tsaw'",
		"derivation" : "a primitive root",
		"def" : "properly, to fasten (or make firm), i.e. to close (the eyes)",
		"kjv" : "shut"
	},
	"H6096" : {
		"lemma" : "עָצֶה",
		"xlit" : "ʻâtseh",
		"pron" : "aw-tseh'",
		"derivation" : "from H6095 (עָצָה)",
		"def" : "the spine (as giving firmness to the body)",
		"kjv" : "backbone"
	},
	"H6097" : {
		"lemma" : "עֵצָה",
		"xlit" : "ʻêtsâh",
		"pron" : "ay-tsaw'",
		"derivation" : "feminine of H6086 (עֵץ)",
		"def" : "timber",
		"kjv" : "trees"
	},
	"H6098" : {
		"lemma" : "עֵצָה",
		"xlit" : "ʻêtsâh",
		"pron" : "ay-tsaw'",
		"derivation" : "from H3289 (יָעַץ)",
		"def" : "advice; by implication, plan; also prudence",
		"kjv" : "advice, advisement, counsel(l-(or)), purpose"
	},
	"H6099" : {
		"lemma" : "עָצוּם",
		"xlit" : "ʻâtsûwm",
		"pron" : "aw-tsoom'",
		"derivation" : "or עָצֻם; passive participle of H6105 (עָצַם)",
		"def" : "powerful (specifically, a paw); by implication, numerous",
		"kjv" : "[phrase] feeble, great, mighty, must, strong"
	},
	"H6100" : {
		"lemma" : "עֶצְיוֹן גֶּבֶר",
		"xlit" : "ʻEtsyôwn Geber",
		"pron" : "ets-yone' gheh'ber",
		"derivation" : "from H6096 (עָצֶה) and H1397 (גֶּבֶר); backbone-like of a man",
		"def" : "Etsjon-Geber, a place on the Red Sea",
		"kjv" : "Ezion-geber"
	},
	"H6101" : {
		"lemma" : "עָצַל",
		"xlit" : "ʻâtsal",
		"pron" : "aw-tsal'",
		"derivation" : "a primitive root",
		"def" : "to lean idly, i.e. to be indolent or slack",
		"kjv" : "be slothful"
	},
	"H6102" : {
		"lemma" : "עָצֵל",
		"xlit" : "ʻâtsêl",
		"pron" : "aw-tsale'",
		"derivation" : "from H6101 (עָצַל)",
		"def" : "indolent",
		"kjv" : "slothful, sluggard"
	},
	"H6103" : {
		"lemma" : "עַצְלָה",
		"xlit" : "ʻatslâh",
		"pron" : "ats-law'",
		"derivation" : "feminine of H6102 (עָצֵל)",
		"def" : "(as abstractly) indolence",
		"kjv" : "slothfulness"
	},
	"H6104" : {
		"lemma" : "עַצְלוּת",
		"xlit" : "ʻatslûwth",
		"pron" : "ats-looth'",
		"derivation" : "from H6101 (עָצַל)",
		"def" : "indolence",
		"kjv" : "idleness"
	},
	"H6105" : {
		"lemma" : "עָצַם",
		"xlit" : "ʻâtsam",
		"pron" : "aw-tsam'",
		"derivation" : "a primitive root; also denominatively (from H6106 (עֶצֶם))",
		"def" : "to bind fast, i.e. close (the eyes); intransitively, to be (causatively, make) powerful or numerous; to crunch the bones",
		"kjv" : "break the bones, close, be great, be increased, be (wax) mighty(-ier), be more, shut, be(-come, make) strong(-er)"
	},
	"H6106" : {
		"lemma" : "עֶצֶם",
		"xlit" : "ʻetsem",
		"pron" : "eh'tsem",
		"derivation" : "from H6105 (עָצַם)",
		"def" : "a bone (as strong); by extension, the body; figuratively, the substance, i.e. (as pron.) selfsame",
		"kjv" : "body, bone, [idiom] life, (self-) same, strength, [idiom] very"
	},
	"H6107" : {
		"lemma" : "עֶצֶם",
		"xlit" : "ʻEtsem",
		"pron" : "eh'-tsem",
		"derivation" : "the same as H6106 (עֶצֶם); bone",
		"def" : "Etsem, a place in Palestine",
		"kjv" : "Azem, Ezem"
	},
	"H6108" : {
		"lemma" : "עֹצֶם",
		"xlit" : "ʻôtsem",
		"pron" : "o'-tsem",
		"derivation" : "from H6105 (עָצַם)",
		"def" : "power; hence, body",
		"kjv" : "might, strong, substance"
	},
	"H6109" : {
		"lemma" : "עׇצְמָה",
		"xlit" : "ʻotsmâh",
		"pron" : "ots-maw'",
		"derivation" : "feminine of H6108 (עֹצֶם)",
		"def" : "powerfulness; by extension, numerousness",
		"kjv" : "abundance, strength"
	},
	"H6110" : {
		"lemma" : "עַצֻּמָה",
		"xlit" : "ʻatstsumâh",
		"pron" : "ats-tsoo-maw'",
		"derivation" : "feminine of H6099 (עָצוּם)",
		"def" : "a bulwark, i.e. (figuratively) argument",
		"kjv" : "strong"
	},
	"H6111" : {
		"lemma" : "עַצְמוֹן",
		"xlit" : "ʻAtsmôwn",
		"pron" : "ats-mone'",
		"derivation" : "or עַצְמֹן; from H6107 (עֶצֶם); bone-like",
		"def" : "Atsmon, a place near Palestine",
		"kjv" : "Azmon"
	},
	"H6112" : {
		"lemma" : "עֵצֶן",
		"xlit" : "ʻêtsen",
		"pron" : "ay'-tsen",
		"derivation" : "from an unused root meaning to be sharp or strong",
		"def" : "a spear",
		"kjv" : "Eznite (from the margin)"
	},
	"H6113" : {
		"lemma" : "עָצָר",
		"xlit" : "ʻâtsâr",
		"pron" : "aw-tsar'",
		"derivation" : "a primitive root",
		"def" : "to inclose; by analogy, to hold back; also to maintain, rule, assemble",
		"kjv" : "[idiom] be able, close up, detain, fast, keep (self close, still), prevail, recover, refrain, [idiom] reign, restrain, retain, shut (up), slack, stay, stop, withhold (self)"
	},
	"H6114" : {
		"lemma" : "עֶצֶר",
		"xlit" : "ʻetser",
		"pron" : "eh'-tser",
		"derivation" : "from H6113 (עָצָר)",
		"def" : "restraint",
		"kjv" : "[phrase] magistrate"
	},
	"H6115" : {
		"lemma" : "עֹצֶר",
		"xlit" : "ʻôtser",
		"pron" : "o'-tser",
		"derivation" : "from H6113 (עָצָר)",
		"def" : "closure; also constraint",
		"kjv" : "[idiom] barren, oppression, [idiom] prison"
	},
	"H6116" : {
		"lemma" : "עֲצָרָה",
		"xlit" : "ʻătsârâh",
		"pron" : "ats-aw-raw'",
		"derivation" : "or עֲצֶרֶת; from H6113 (עָצָר)",
		"def" : "an assembly, especially on afestival or holiday",
		"kjv" : "(solemn) assembly (meeting)"
	},
	"H6117" : {
		"lemma" : "עָקַב",
		"xlit" : "ʻâqab",
		"pron" : "aw-kab'",
		"derivation" : "a primitive root; properly, to swell out or up; used only as denominative from H6119 (עָקֵב)",
		"def" : "to seize by the heel; figuratively, to circumvent (as if tripping up the heels); also to restrain (as if holding by the heel)",
		"kjv" : "take by the heel, stay, supplant, [idiom] utterly"
	},
	"H6118" : {
		"lemma" : "עֵקֶב",
		"xlit" : "ʻêqeb",
		"pron" : "ay'-keb",
		"derivation" : "from H6117 (עָקַב) in the sense of H6119 (עָקֵב)",
		"def" : "a heel, i.e. (figuratively) the last of anything (used adverbially, for ever); also result, i.e. compensation; and so (adverb with preposition or relatively) on account of",
		"kjv" : "[idiom] because, by, end, for, if, reward"
	},
	"H6119" : {
		"lemma" : "עָקֵב",
		"xlit" : "ʻâqêb",
		"pron" : "aw-kabe'",
		"derivation" : "or (feminine) עִקְּבָה; from H6117 (עָקַב)",
		"def" : "a heel (as protuberant); hence, a track; figuratively, the rear (of an army)",
		"kjv" : "heel, (horse-) hoof, last, lier in wait (by mistake for H6120 (עָקֵב)), (foot-) step"
	},
	"H6120" : {
		"lemma" : "עָקֵב",
		"xlit" : "ʻâqêb",
		"pron" : "aw-kabe'",
		"derivation" : "from H6117 (עָקַב) in its denominative sense",
		"def" : "a lier in wait",
		"kjv" : "heel (by mistake for H6119 (עָקֵב))"
	},
	"H6121" : {
		"lemma" : "עָקֹב",
		"xlit" : "ʻâqôb",
		"pron" : "aw-kobe'",
		"derivation" : "from H6117 (עָקַב); in the original sense, a knoll (as swelling up); in the denominative sense",
		"def" : "(transitive) fraudulent or (intransitive) tracked",
		"kjv" : "crooked, deceitful, polluted"
	},
	"H6122" : {
		"lemma" : "עׇקְבָה",
		"xlit" : "ʻoqbâh",
		"pron" : "ok-baw'",
		"derivation" : "feminine of an unused form from H6117 (עָקַב) meaning a trick",
		"def" : "trickery",
		"kjv" : "subtilty"
	},
	"H6123" : {
		"lemma" : "עָקַד",
		"xlit" : "ʻâqad",
		"pron" : "aw-kad'",
		"derivation" : "a primitive root",
		"def" : "to tie with thongs",
		"kjv" : "bind"
	},
	"H6124" : {
		"lemma" : "עָקֹד",
		"xlit" : "ʻâqôd",
		"pron" : "aw-kode'",
		"derivation" : "from H6123 (עָקַד)",
		"def" : "striped (with bands)",
		"kjv" : "ring straked"
	},
	"H6125" : {
		"lemma" : "עָקָה",
		"xlit" : "ʻâqâh",
		"pron" : "aw-kaw'",
		"derivation" : "from H5781 (עוּק)",
		"def" : "constraint",
		"kjv" : "oppression"
	},
	"H6126" : {
		"lemma" : "עַקּוּב",
		"xlit" : "ʻAqqûwb",
		"pron" : "ak-koob'",
		"derivation" : "from H6117 (עָקַב); insidious",
		"def" : "Akkub, the name of five Israelites",
		"kjv" : "Akkub"
	},
	"H6127" : {
		"lemma" : "עָקַל",
		"xlit" : "ʻâqal",
		"pron" : "aw-kal'",
		"derivation" : "a primitive root",
		"def" : "to wrest",
		"kjv" : "wrong"
	},
	"H6128" : {
		"lemma" : "עֲקַלְקַל",
		"xlit" : "ʻăqalqal",
		"pron" : "ak-al-kal'",
		"derivation" : "from H6127 (עָקַל)",
		"def" : "winding",
		"kjv" : "by(-way), crooked way"
	},
	"H6129" : {
		"lemma" : "עֲקַלָּתוֹן",
		"xlit" : "ʻăqallâthôwn",
		"pron" : "ak-al-law-thone'",
		"derivation" : "from H6127 (עָקַל)",
		"def" : "tortuous",
		"kjv" : "crooked"
	},
	"H6130" : {
		"lemma" : "עָקָן",
		"xlit" : "ʻÂqân",
		"pron" : "aw-kawn'",
		"derivation" : "from an unused root meaning to twist; tortuous",
		"def" : "Akan, an Idummaean",
		"kjv" : "Akan. Compare H3292 (יַעֲקָן)"
	},
	"H6131" : {
		"lemma" : "עָקַר",
		"xlit" : "ʻâqar",
		"pron" : "aw-kar'",
		"derivation" : "a primitive root",
		"def" : "to pluck up (especially by the roots); specifically, to hamstring; figuratively, to exterminate",
		"kjv" : "dig down, hough, pluck up, root up"
	},
	"H6132" : {
		"lemma" : "עֲקַר",
		"xlit" : "ʻăqar",
		"pron" : "ak-ar'",
		"derivation" : "(Aramaic) corresponding to H6131 (עָקַר)",
		"def" : "{to pluck up (especially by the roots); specifically, to hamstring; figuratively, to exterminate}",
		"kjv" : "pluck up by the roots"
	},
	"H6133" : {
		"lemma" : "עֵקֶר",
		"xlit" : "ʻêqer",
		"pron" : "ay'-ker",
		"derivation" : "from H6131 (עָקַר)",
		"def" : "figuratively, a transplanted person, i.e. naturalized citizen",
		"kjv" : "stock"
	},
	"H6134" : {
		"lemma" : "עֵקֶר",
		"xlit" : "ʻÊqer",
		"pron" : "ay'-ker",
		"derivation" : "the same as H6133 (עֵקֶר)",
		"def" : "Eker, an Israelite",
		"kjv" : "Eker"
	},
	"H6135" : {
		"lemma" : "עָקָר",
		"xlit" : "ʻâqâr",
		"pron" : "aw-kawr'",
		"derivation" : "from H6131 (עָקַר)",
		"def" : "sterile (as if extirpated in the generative organs)",
		"kjv" : "([idiom] male or female) barren (woman)"
	},
	"H6136" : {
		"lemma" : "עִקַּר",
		"xlit" : "ʻiqqar",
		"pron" : "ik-kar'",
		"derivation" : "(Aramaic) from H6132 (עֲקַר)",
		"def" : "a stock",
		"kjv" : "stump"
	},
	"H6137" : {
		"lemma" : "עַקְרָב",
		"xlit" : "ʻaqrâb",
		"pron" : "ak-rawb'",
		"derivation" : "of uncertain derivation",
		"def" : "a scorpion; figuratively, a scourge or knotted whip",
		"kjv" : "scorpion"
	},
	"H6138" : {
		"lemma" : "עֶקְרוֹן",
		"xlit" : "ʻEqrôwn",
		"pron" : "ek-rone'",
		"derivation" : "from H6131 (עָקַר); eradication",
		"def" : "Ekron, a place in Palestine",
		"kjv" : "Ekron"
	},
	"H6139" : {
		"lemma" : "עֶקְרוֹנִי",
		"xlit" : "ʻEqrôwnîy",
		"pron" : "ek-ro-nee'",
		"derivation" : "or עֶקְרֹנִי; patrial from H6138 (עֶקְרוֹן)",
		"def" : "an Ekronite or inhabitant of Ekron",
		"kjv" : "Ekronite"
	},
	"H6140" : {
		"lemma" : "עָקַשׁ",
		"xlit" : "ʻâqash",
		"pron" : "aw-kash'",
		"derivation" : "a primitive root",
		"def" : "to knot or distort; figuratively, to pervert (act or declare perverse)",
		"kjv" : "make crooked, (prove, that is) perverse(-rt)"
	},
	"H6141" : {
		"lemma" : "עִקֵּשׁ",
		"xlit" : "ʻiqqêsh",
		"pron" : "ik-kashe'",
		"derivation" : "from H6140 (עָקַשׁ)",
		"def" : "distorted; hence, false",
		"kjv" : "crooked, froward, perverse"
	},
	"H6142" : {
		"lemma" : "עִקֵּשׁ",
		"xlit" : "ʻIqqêsh",
		"pron" : "ik-kashe'",
		"derivation" : "the same as H6141 (עִקֵּשׁ); perverse",
		"def" : "Ikkesh, an Israelite",
		"kjv" : "Ikkesh"
	},
	"H6143" : {
		"lemma" : "עִקְּשׁוּת",
		"xlit" : "ʻiqqᵉshûwth",
		"pron" : "ik-kesh-ooth'",
		"derivation" : "from H6141 (עִקֵּשׁ)",
		"def" : "perversity",
		"kjv" : "[idiom] froward"
	},
	"H6144" : {
		"lemma" : "עָר",
		"xlit" : "ʻÂr",
		"pron" : "awr",
		"derivation" : "the same as H5892 (עִיר); a city",
		"def" : "Ar, a place in Moab",
		"kjv" : "Ar"
	},
	"H6145" : {
		"lemma" : "עָר",
		"xlit" : "ʻâr",
		"pron" : "awr",
		"derivation" : "from H5782 (עוּר)",
		"def" : "a foe (as watchful for mischief)",
		"kjv" : "enemy"
	},
	"H6146" : {
		"lemma" : "עָר",
		"xlit" : "ʻâr",
		"pron" : "awr",
		"derivation" : "(Aramaic) corresponding to H6145 (עָר)",
		"def" : "{a foe (as watchful for mischief)}",
		"kjv" : "enemy"
	},
	"H6147" : {
		"lemma" : "עֵר",
		"xlit" : "ʻÊr",
		"pron" : "ayr",
		"derivation" : "from H5782 (עוּר); watchful",
		"def" : "Er, the name of two Israelites",
		"kjv" : "Er"
	},
	"H6148" : {
		"lemma" : "עָרַב",
		"xlit" : "ʻârab",
		"pron" : "aw-rab'",
		"derivation" : "a primitive root",
		"def" : "to braid, i.e. intermix; technically, to traffic (as if by barter); also or give to be security (as a kind of exchange)",
		"kjv" : "engage, (inter-) meddle (with), mingle (self), mortgage, occupy, give pledges, be(-come, put in) surety, undertake"
	},
	"H6149" : {
		"lemma" : "עָרֵב",
		"xlit" : "ʻârêb",
		"pron" : "aw-rabe'",
		"derivation" : "a primitive root (rather identical with H6148 (עָרַב) through the idea of close association)",
		"def" : "to be agreeable",
		"kjv" : "be pleasant(-ing), take pleasure in, be sweet"
	},
	"H6150" : {
		"lemma" : "עָרַב",
		"xlit" : "ʻârab",
		"pron" : "aw-rab'",
		"derivation" : "a primitive root (rather identical with H6148 (עָרַב) through the idea of covering with a texture)",
		"def" : "to grow dusky at sundown",
		"kjv" : "be darkened, (toward) evening"
	},
	"H6151" : {
		"lemma" : "עֲרַב",
		"xlit" : "ʻărab",
		"pron" : "ar-ab'",
		"derivation" : "(Aramaic) corresponding to H6148 (עָרַב)",
		"def" : "to commingle",
		"kjv" : "mingle (self), mix"
	},
	"H6152" : {
		"lemma" : "עֲרָב",
		"xlit" : "ʻĂrâb",
		"pron" : "ar-awb'",
		"derivation" : "or עֲרַב; from H6150 (עָרַב) in the figurative sense of sterility",
		"def" : "Arab (i.e. Arabia), a country East of Palestine",
		"kjv" : "Arabia"
	},
	"H6153" : {
		"lemma" : "עֶרֶב",
		"xlit" : "ʻereb",
		"pron" : "eh'-reb",
		"derivation" : "from H6150 (עָרַב)",
		"def" : "dusk",
		"kjv" : "[phrase] day, even(-ing, tide), night"
	},
	"H6154" : {
		"lemma" : "עֵרֶב",
		"xlit" : "ʻêreb",
		"pron" : "ay'-reb",
		"derivation" : "or עֶרֶב; (1 Kings 10:15), (with the article [prefix), from H6148 (עָרַב)",
		"def" : "the web (or transverse threads of cloth); also a mixture, (or mongrel race)",
		"kjv" : "Arabia, mingled people, mixed (multitude), woof"
	},
	"H6155" : {
		"lemma" : "עָרָב",
		"xlit" : "ʻârâb",
		"pron" : "aw-rawb'",
		"derivation" : "from H6148 (עָרַב)",
		"def" : "a willow (from the use of osiers as wattles)",
		"kjv" : "willow"
	},
	"H6156" : {
		"lemma" : "עָרֵב",
		"xlit" : "ʻârêb",
		"pron" : "aw-rabe'",
		"derivation" : "from H6149 (עָרֵב)",
		"def" : "pleasant",
		"kjv" : "sweet"
	},
	"H6157" : {
		"lemma" : "עָרֹב",
		"xlit" : "ʻârôb",
		"pron" : "aw-robe'",
		"derivation" : "from H6148 (עָרַב)",
		"def" : "a mosquito (from its swarming)",
		"kjv" : "divers sorts of flies, swarm"
	},
	"H6158" : {
		"lemma" : "עֹרֵב",
		"xlit" : "ʻôrêb",
		"pron" : "o-rabe'",
		"derivation" : "or עוֹרֵב; from H6150 (עָרַב)",
		"def" : "a raven (from its dusky hue)",
		"kjv" : "raven"
	},
	"H6159" : {
		"lemma" : "עֹרֵב",
		"xlit" : "ʻÔrêb",
		"pron" : "o-rabe'",
		"derivation" : "or עוֹרֵב; the same as H6158 (עֹרֵב)",
		"def" : "Oreb, the name of a Midianite and of the cliff near the Jordan",
		"kjv" : "Oreb"
	},
	"H6160" : {
		"lemma" : "עֲרָבָה",
		"xlit" : "ʻărâbâh",
		"pron" : "ar-aw-baw'",
		"derivation" : "from H6150 (עָרַב) (in the sense of sterility)",
		"def" : "a desert; especially (with the article prefix) the (generally) sterile valley of the Jordan and its continuation to the Red Sea",
		"kjv" : "Arabah, champaign, desert, evening, heaven, plain, wilderness. See also H1026 (בֵּית הָעֲרָבָה)"
	},
	"H6161" : {
		"lemma" : "עֲרֻבָּה",
		"xlit" : "ʻărubbâh",
		"pron" : "ar-oob-baw'",
		"derivation" : "feminine passive participle of H6048 (עֲנַמֶּלֶךְ) in the sense of a bargain or exchange",
		"def" : "something given as security, i.e. (literally) a token (of safety) or (metaphorically) a bondsman",
		"kjv" : "pledge, surety"
	},
	"H6162" : {
		"lemma" : "עֲרָבוֹן",
		"xlit" : "ʻărâbôwn",
		"pron" : "ar-aw-bone'",
		"derivation" : "from H6148 (עָרַב) (in the sense of exchange)",
		"def" : "a pawn (given as security)",
		"kjv" : "pledge"
	},
	"H6163" : {
		"lemma" : "עֲרָבִי",
		"xlit" : "ʻĂrâbîy",
		"pron" : "ar-aw-bee'",
		"derivation" : "or עַרְבִי; patrial from H6152 (עֲרָב)",
		"def" : "an Arabian or inhabitant of Arab (i.e. Arabia)",
		"kjv" : "Arabian"
	},
	"H6164" : {
		"lemma" : "עַרְבָתִי",
		"xlit" : "ʻArbâthîy",
		"pron" : "ar-baw-thee'",
		"derivation" : "patrial from H1026 (בֵּית הָעֲרָבָה)",
		"def" : "an Arbathite or inhabitant of (Beth-) Arabah",
		"kjv" : "Arbahite"
	},
	"H6165" : {
		"lemma" : "עָרַג",
		"xlit" : "ʻârag",
		"pron" : "aw-rag'",
		"derivation" : "a primitive root",
		"def" : "to long for",
		"kjv" : "cry, pant"
	},
	"H6166" : {
		"lemma" : "עֲרָד",
		"xlit" : "ʻĂrâd",
		"pron" : "ar-awd'",
		"derivation" : "from an unused root meaning to sequester itself; fugitive",
		"def" : "Arad, the name of a place near Palestine, also of a Canaanite and an Israelite",
		"kjv" : "Arad"
	},
	"H6167" : {
		"lemma" : "עֲרָד",
		"xlit" : "ʻărâd",
		"pron" : "ar-awd'",
		"derivation" : "(Aramaic) corresponding to H6171 (עָרוֹד)",
		"def" : "an onager",
		"kjv" : "wild ass"
	},
	"H6168" : {
		"lemma" : "עָרָה",
		"xlit" : "ʻârâh",
		"pron" : "aw-raw'",
		"derivation" : "a primitive root",
		"def" : "to be (causatively, make) bare; hence, to empty, pour out, demolish",
		"kjv" : "leave destitute, discover, empty, make naked, pour (out), rase, spread self, uncover"
	},
	"H6169" : {
		"lemma" : "עָרָה",
		"xlit" : "ʻârâh",
		"pron" : "aw-raw'",
		"derivation" : "feminine from H6168 (עָרָה)",
		"def" : "a naked (i.e. level) plot",
		"kjv" : "paper reed"
	},
	"H6170" : {
		"lemma" : "עֲרוּגָה",
		"xlit" : "ʻărûwgâh",
		"pron" : "ar-oo-gaw'",
		"derivation" : "or עֲרֻגָה; feminine passive participle of H6165 (עָרַג)",
		"def" : "something piled up (as if (figuratively) raised by mental aspiration), i.e. a paterre",
		"kjv" : "bed, furrow"
	},
	"H6171" : {
		"lemma" : "עָרוֹד",
		"xlit" : "ʻârôwd",
		"pron" : "aw-rode'",
		"derivation" : "from the same as H6166 (עֲרָד)",
		"def" : "an onager (from his lonesome habits)",
		"kjv" : "wild ass"
	},
	"H6172" : {
		"lemma" : "עֶרְוָה",
		"xlit" : "ʻervâh",
		"pron" : "er-vaw'",
		"derivation" : "from H6168 (עָרָה)",
		"def" : "nudity, literally (especially the pudenda) or figuratively (disgrace, blemish)",
		"kjv" : "nakedness, shame, unclean(-ness)"
	},
	"H6173" : {
		"lemma" : "עַרְוָה",
		"xlit" : "ʻarvâh",
		"pron" : "ar-vaw'",
		"derivation" : "(Aramaic) corresponding to H6172 (עֶרְוָה)",
		"def" : "nakedness, i.e. (figuratively) impoverishment",
		"kjv" : "dishonor"
	},
	"H6174" : {
		"lemma" : "עָרוֹם",
		"xlit" : "ʻârôwm",
		"pron" : "aw-rome'",
		"derivation" : "or עָרֹם; from H6191 (עָרַם) (in its original sense)",
		"def" : "nude, either partially or totally",
		"kjv" : "naked"
	},
	"H6175" : {
		"lemma" : "עָרוּם",
		"xlit" : "ʻârûwm",
		"pron" : "aw-room'",
		"derivation" : "passive participle of H6191 (עָרַם)",
		"def" : "cunning (usually in a bad sense)",
		"kjv" : "crafty, prudent, subtil"
	},
	"H6176" : {
		"lemma" : "עֲרוֹעֵר",
		"xlit" : "ʻărôwʻêr",
		"pron" : "ar-o-ayr'",
		"derivation" : "or עַרְעָר; from H6209 (עָרַר) reduplicated",
		"def" : "a juniper (from its nudity of situation)",
		"kjv" : "health"
	},
	"H6177" : {
		"lemma" : "עֲרוֹעֵר",
		"xlit" : "ʻĂrôwʻêr",
		"pron" : "ar-o-ayr'",
		"derivation" : "or עֲרֹעֵר; or עַרְעוֹר; the same as H6176 (עֲרוֹעֵר); nudity of situation",
		"def" : "Aroer, the name of three places in or near Palestine",
		"kjv" : "Aroer"
	},
	"H6178" : {
		"lemma" : "עָרוּץ",
		"xlit" : "ʻârûwts",
		"pron" : "aw-roots'",
		"derivation" : "passive participle of H6206 (עָרַץ)",
		"def" : "feared, i.e. (concretely) a horrible place or chasm",
		"kjv" : "cliffs"
	},
	"H6179" : {
		"lemma" : "עֵרִי",
		"xlit" : "ʻÊrîy",
		"pron" : "ay-ree'",
		"derivation" : "from H5782 (עוּר); watchful",
		"def" : "Eri, an Israelite",
		"kjv" : "Eri"
	},
	"H6180" : {
		"lemma" : "עֵרִי",
		"xlit" : "ʻÊrîy",
		"pron" : "ay-ree'",
		"derivation" : "patronymically of H6179 (עֵרִי)",
		"def" : "a Erite (collectively) or descendants of Eri",
		"kjv" : "Erites"
	},
	"H6181" : {
		"lemma" : "עֶרְיָה",
		"xlit" : "ʻeryâh",
		"pron" : "er-yaw'",
		"derivation" : "for H6172 (עֶרְוָה)",
		"def" : "nudity",
		"kjv" : "bare, naked, [idiom] quite"
	},
	"H6182" : {
		"lemma" : "עֲרִיסָה",
		"xlit" : "ʻărîyçâh",
		"pron" : "ar-ee-saw'",
		"derivation" : "from an unused root meaning to comminute",
		"def" : "meal",
		"kjv" : "dough"
	},
	"H6183" : {
		"lemma" : "עָרִיף",
		"xlit" : "ʻârîyph",
		"pron" : "aw-reef'",
		"derivation" : "from H6201 (עָרַף)",
		"def" : "the sky (as dropping at the horizon)",
		"kjv" : "heaven"
	},
	"H6184" : {
		"lemma" : "עָרִיץ",
		"xlit" : "ʻârîyts",
		"pron" : "aw-reets'",
		"derivation" : "from H6206 (עָרַץ)",
		"def" : "fearful, i.e. powerful or tyrannical",
		"kjv" : "mighty, oppressor, in great power, strong, terrible, violent"
	},
	"H6185" : {
		"lemma" : "עֲרִירִי",
		"xlit" : "ʻărîyrîy",
		"pron" : "ar-e-ree'",
		"derivation" : "from H6209 (עָרַר)",
		"def" : "bare, i.e. destitute (of children)",
		"kjv" : "childless"
	},
	"H6186" : {
		"lemma" : "עָרַךְ",
		"xlit" : "ʻârak",
		"pron" : "aw-rak'",
		"derivation" : "a primitive root",
		"def" : "to set in a row, i.e. arrange, put in order (in a very wide variety of applications)",
		"kjv" : "put (set) (the battle, self) in array, compare, direct, equal, esteem, estimate, expert (in war), furnish, handle, join (battle), ordain, (lay, put, reckon up, set) (in) order, prepare, tax, value"
	},
	"H6187" : {
		"lemma" : "עֵרֶךְ",
		"xlit" : "ʻêrek",
		"pron" : "eh'rek",
		"derivation" : "from H6186 (עָרַךְ)",
		"def" : "a pile, equipment, estimate",
		"kjv" : "equal, estimation, (things that are set in) order, price, proportion, [idiom] set at, suit, taxation, [idiom] valuest"
	},
	"H6188" : {
		"lemma" : "עָרֵל",
		"xlit" : "ʻârêl",
		"pron" : "aw-rale'",
		"derivation" : "a primitive root; properly, to strip; but used as denominative from H6189 (עָרֵל)",
		"def" : "to expose or remove the prepuce, whether literal (to go naked) or figurative (to refrain from using)",
		"kjv" : "count uncircumcised, foreskin to be uncovered"
	},
	"H6189" : {
		"lemma" : "עָרֵל",
		"xlit" : "ʻârêl",
		"pron" : "aw-rale'",
		"derivation" : "from H6188 (עָרֵל); properly, exposed, i.e. projecting loose (as to the prepuce); used only technically",
		"def" : "uncircumcised (i.e. still having the prepuce uncurtailed)",
		"kjv" : "uncircumcised (person)"
	},
	"H6190" : {
		"lemma" : "עׇרְלָה",
		"xlit" : "ʻorlâh",
		"pron" : "or-law'",
		"derivation" : "feminine of H6189 (עָרֵל)",
		"def" : "the prepuce",
		"kjv" : "foreskin, [phrase] uncircumcised"
	},
	"H6191" : {
		"lemma" : "עָרַם",
		"xlit" : "ʻâram",
		"pron" : "aw-ram'",
		"derivation" : "a primitive root",
		"def" : "properly, to be (or make) bare; but used only in the derivative sense (through the idea perhaps of smoothness) to be cunning (usually in a bad sense)",
		"kjv" : "[idiom] very, beware, take crafty (counsel), be prudent, deal subtilly"
	},
	"H6192" : {
		"lemma" : "עָרַם",
		"xlit" : "ʻâram",
		"pron" : "aw-ram'",
		"derivation" : "a primitive root",
		"def" : "to pile up",
		"kjv" : "gather together"
	},
	"H6193" : {
		"lemma" : "עֹרֶם",
		"xlit" : "ʻôrem",
		"pron" : "o'-rem",
		"derivation" : "from H6191 (עָרַם)",
		"def" : "a stratagem",
		"kjv" : "craftiness"
	},
	"H6194" : {
		"lemma" : "עָרֵם",
		"xlit" : "ʻârêm",
		"pron" : "aw-rame'",
		"derivation" : "(Jeremiah 50:26) or (feminine) עֲרֵמָה; from H6192 (עָרַם)",
		"def" : "a heap; specifically, a sheaf",
		"kjv" : "heap (of corn), sheaf"
	},
	"H6195" : {
		"lemma" : "עׇרְמָה",
		"xlit" : "ʻormâh",
		"pron" : "or-maw'",
		"derivation" : "feminine of H6193 (עֹרֶם)",
		"def" : "trickery; or (in a good sense) discretion",
		"kjv" : "guile, prudence, subtilty, wilily, wisdom"
	},
	"H6196" : {
		"lemma" : "עַרְמוֹן",
		"xlit" : "ʻarmôwn",
		"pron" : "ar-mone'",
		"derivation" : "probably from H6191 (עָרַם)",
		"def" : "the plane tree (from its smooth and shed bark)",
		"kjv" : "chestnut tree"
	},
	"H6197" : {
		"lemma" : "עֵרָן",
		"xlit" : "ʻÊrân",
		"pron" : "ay-rawn'",
		"derivation" : "probably from H5782 (עוּר); watchful",
		"def" : "Eran, an Israelite",
		"kjv" : "Eran"
	},
	"H6198" : {
		"lemma" : "עֵרָנִי",
		"xlit" : "ʻÊrânîy",
		"pron" : "ay-raw-nee'",
		"derivation" : "patronymically from H6197 (עֵרָן)",
		"def" : "an Eranite or descendant (collectively) of Eran",
		"kjv" : "Eranites"
	},
	"H6199" : {
		"lemma" : "עַרעָר",
		"xlit" : "ʻarʻâr",
		"pron" : "ar-awr'",
		"derivation" : "from H6209 (עָרַר)",
		"def" : "naked, i.e. (figuratively) poor",
		"kjv" : "destitute. See also H6176 (עֲרוֹעֵר)"
	},
	"H6200" : {
		"lemma" : "עֲרֹעֵרִי",
		"xlit" : "ʻĂrôʻêrîy",
		"pron" : "ar-o-ay-ree'",
		"derivation" : "patronymically from H6177 (עֲרוֹעֵר)",
		"def" : "an Aroerite or inhabitant of Aroer",
		"kjv" : "Aroerite"
	},
	"H6201" : {
		"lemma" : "עָרַף",
		"xlit" : "ʻâraph",
		"pron" : "aw-raf'",
		"derivation" : "a primitive root",
		"def" : "to droop; hence, to drip",
		"kjv" : "drop (down)"
	},
	"H6202" : {
		"lemma" : "עָרַף",
		"xlit" : "ʻâraph",
		"pron" : "aw-raf'",
		"derivation" : "a primitive root (identical with through the idea of sloping); properly, to bend downward; but used only as a denominative from H6203 (עֹרֶף)",
		"def" : "to break the neck; hence (figuratively) to destroy",
		"kjv" : "that is beheaded, break down, break (cut off, strike off) neck"
	},
	"H6203" : {
		"lemma" : "עֹרֶף",
		"xlit" : "ʻôreph",
		"pron" : "o-ref'",
		"derivation" : "from H6202 (עָרַף)",
		"def" : "the nape or back of the neck (as declining); hence, the back generally (whether literal or figurative)",
		"kjv" : "back ((stiff-) neck((-ed)"
	},
	"H6204" : {
		"lemma" : "עׇרְפָּה",
		"xlit" : "ʻOrpâh",
		"pron" : "or-paw'",
		"derivation" : "feminine of H6203 (עֹרֶף); mane",
		"def" : "Orpah, a Moabites",
		"kjv" : "Orpah"
	},
	"H6205" : {
		"lemma" : "עֲרָפֶל",
		"xlit" : "ʻărâphel",
		"pron" : "ar-aw-fel'",
		"derivation" : "probably from H6201 (עָרַף)",
		"def" : "gloom (as of a lowering sky)",
		"kjv" : "(gross, thick) dark (cloud, -ness)"
	},
	"H6206" : {
		"lemma" : "עָרַץ",
		"xlit" : "ʻârats",
		"pron" : "aw-rats'",
		"derivation" : "a primitive root",
		"def" : "to awe or (intransitive) to dread; hence, to harass",
		"kjv" : "be affrighted (afraid, dread, feared, terrified), break, dread, fear, oppress, prevail, shake terribly"
	},
	"H6207" : {
		"lemma" : "עָרַק",
		"xlit" : "ʻâraq",
		"pron" : "aw-rak'",
		"derivation" : "a primitive root",
		"def" : "to gnaw, i.e. (figuratively) eat (by hyberbole); also (participle) a pain",
		"kjv" : "fleeing, sinew"
	},
	"H6208" : {
		"lemma" : "עַרְקִי",
		"xlit" : "ʻArqîy",
		"pron" : "ar-kee'",
		"derivation" : "patrial from an unused name meaning a tush",
		"def" : "an Arkite or inhabitant of Erek",
		"kjv" : "Arkite"
	},
	"H6209" : {
		"lemma" : "עָרַר",
		"xlit" : "ʻârar",
		"pron" : "aw-rar'",
		"derivation" : "a primitive root",
		"def" : "to bare; figuratively, to demolish",
		"kjv" : "make bare, break, raise up (perhaps by clerical error for raze), [idiom] utterly"
	},
	"H6210" : {
		"lemma" : "עֶרֶשׂ",
		"xlit" : "ʻeres",
		"pron" : "eh'res",
		"derivation" : "from an unused root meaning perhaps to arch",
		"def" : "a couch (properly, with a canopy)",
		"kjv" : "bed(-stead), couch"
	},
	"H6211" : {
		"lemma" : "עָשׁ",
		"xlit" : "ʻâsh",
		"pron" : "awsh",
		"derivation" : "from H6244 (עָשֵׁשׁ)",
		"def" : "a moth",
		"kjv" : "moth. See also H5906 (עַיִשׁ)"
	},
	"H6212" : {
		"lemma" : "עֶשֶׂב",
		"xlit" : "ʻeseb",
		"pron" : "eh'seb",
		"derivation" : "from an unused root meaning to glisten (or be green)",
		"def" : "grass (or any tender shoot)",
		"kjv" : "grass, herb"
	},
	"H6213" : {
		"lemma" : "עָשָׂה",
		"xlit" : "ʻâsâh",
		"pron" : "aw-saw'",
		"derivation" : "a primitive root",
		"def" : "to do or make, in the broadest sense and widest application",
		"kjv" : "accomplish, advance, appoint, apt, be at, become, bear, bestow, bring forth, bruise, be busy, [idiom] certainly, have the charge of, commit, deal (with), deck, [phrase] displease, do, (ready) dress(-ed), (put in) execute(-ion), exercise, fashion, [phrase] feast, (fight-) ing man, [phrase] finish, fit, fly, follow, fulfill, furnish, gather, get, go about, govern, grant, great, [phrase] hinder, hold (a feast), [idiom] indeed, [phrase] be industrious, [phrase] journey, keep, labour, maintain, make, be meet, observe, be occupied, offer, [phrase] officer, pare, bring (come) to pass, perform, pracise, prepare, procure, provide, put, requite, [idiom] sacrifice, serve, set, shew, [idiom] sin, spend, [idiom] surely, take, [idiom] thoroughly, trim, [idiom] very, [phrase] vex, be (warr-) ior, work(-man), yield, use"
	},
	"H6214" : {
		"lemma" : "עֲשָׂהאֵל",
		"xlit" : "ʻĂsâhʼêl",
		"pron" : "as-aw-ale'",
		"derivation" : "from H6213 (עָשָׂה) and H410 (אֵל); God has made",
		"def" : "Asahel, the name of four Israelites",
		"kjv" : "Asahel"
	},
	"H6215" : {
		"lemma" : "עֵשָׂו",
		"xlit" : "ʻÊsâv",
		"pron" : "ay-sawv'",
		"derivation" : "apparently a form of the passive participle of H6213 (עָשָׂה) in the original sense of handling; rough (i.e. sensibly felt)",
		"def" : "Esav, a son of Isaac, including his posterity",
		"kjv" : "Esau"
	},
	"H6216" : {
		"lemma" : "עָשׁוֹק",
		"xlit" : "ʻâshôwq",
		"pron" : "aw-shoke'",
		"derivation" : "from H6231 (עָשַׁק)",
		"def" : "oppressive (as noun, a tyrant)",
		"kjv" : "oppressor"
	},
	"H6217" : {
		"lemma" : "עָשׁוּק",
		"xlit" : "ʻâshûwq",
		"pron" : "aw-shook'",
		"derivation" : "or עָשֻׁק; passive participle of H6231 (עָשַׁק)",
		"def" : "used in plural masculine as abstractly, tyranny",
		"kjv" : "oppressed(-ion). (Doubtful.)"
	},
	"H6218" : {
		"lemma" : "עָשׂוֹר",
		"xlit" : "ʻâsôwr",
		"pron" : "aw-sore'",
		"derivation" : "or עָשֹׂר; from H6235 (עֶשֶׂר)",
		"def" : "ten; by abbreviated form ten strings, and so a decachord",
		"kjv" : "(instrument of) ten (strings, -th)"
	},
	"H6219" : {
		"lemma" : "עָשׁוֹת",
		"xlit" : "ʻâshôwth",
		"pron" : "aw-shoth'",
		"derivation" : "from H6245 (עָשַׁת)",
		"def" : "shining, i.e. polished",
		"kjv" : "bright"
	},
	"H6220" : {
		"lemma" : "עַשְׁוָת",
		"xlit" : "ʻAshvâth",
		"pron" : "ash-vawth'",
		"derivation" : "for H6219 (עָשׁוֹת); bright",
		"def" : "Ashvath, an Israelite",
		"kjv" : "Ashvath"
	},
	"H6221" : {
		"lemma" : "עֲשִׂיאֵל",
		"xlit" : "ʻĂsîyʼêl",
		"pron" : "as-ee-ale'",
		"derivation" : "from H6213 (עָשָׂה) and H410 (אֵל); made of God",
		"def" : "Asiel, an Israelite",
		"kjv" : "Asiel"
	},
	"H6222" : {
		"lemma" : "עֲשָׂיָה",
		"xlit" : "ʻĂsâyâh",
		"pron" : "aw-saw-yaw'",
		"derivation" : "from H6213 (עָשָׂה) and H3050 (יָהּ); Jah has made",
		"def" : "Asajah, the name of three or four Israelites",
		"kjv" : "Asaiah"
	},
	"H6223" : {
		"lemma" : "עָשִׁיר",
		"xlit" : "ʻâshîyr",
		"pron" : "aw-sheer'",
		"derivation" : "from H6238 (עָשַׁר)",
		"def" : "rich, whether literal or figurative (noble)",
		"kjv" : "rich (man)"
	},
	"H6224" : {
		"lemma" : "עֲשִׂירִי",
		"xlit" : "ʻăsîyrîy",
		"pron" : "as-ee-ree'",
		"derivation" : "from H6235 (עֶשֶׂר)",
		"def" : "tenth; by abbreviation, tenth month or (feminine) part",
		"kjv" : "tenth (part)"
	},
	"H6225" : {
		"lemma" : "עָשַׁן",
		"xlit" : "ʻâshan",
		"pron" : "aw-shan'",
		"derivation" : "a primitive root",
		"def" : "to smoke, whether literal or figurative",
		"kjv" : "be angry (be on a) smoke"
	},
	"H6226" : {
		"lemma" : "עָשֵׁן",
		"xlit" : "ʻâshên",
		"pron" : "aw-shane'",
		"derivation" : "from H6225 (עָשַׁן)",
		"def" : "smoky",
		"kjv" : "smoking"
	},
	"H6227" : {
		"lemma" : "עָשָׁן",
		"xlit" : "ʻâshân",
		"pron" : "aw-shawn'",
		"derivation" : "from H6225 (עָשַׁן)",
		"def" : "smoke, literally or figuratively (vapor, dust, anger)",
		"kjv" : "smoke(-ing)"
	},
	"H6228" : {
		"lemma" : "עָשָׁן",
		"xlit" : "ʻÂshân",
		"pron" : "aw-shawn'",
		"derivation" : "the same as H6227 (עָשָׁן)",
		"def" : "Ashan, a place in Palestine",
		"kjv" : "Ashan"
	},
	"H6229" : {
		"lemma" : "עָשַׂק",
		"xlit" : "ʻâsaq",
		"pron" : "aw-sak",
		"derivation" : "a primitive root (identical with H6231 (עָשַׁק))",
		"def" : "to press upon, i.e. quarrel",
		"kjv" : "strive with"
	},
	"H6230" : {
		"lemma" : "עֵשֶׂק",
		"xlit" : "ʻêseq",
		"pron" : "ay'sek",
		"derivation" : "from H6229 (עָשַׂק)",
		"def" : "strife",
		"kjv" : "Esek"
	},
	"H6231" : {
		"lemma" : "עָשַׁק",
		"xlit" : "ʻâshaq",
		"pron" : "aw-shak'",
		"derivation" : "a primitive root (compare H6229 (עָשַׂק))",
		"def" : "to press upon, i.e. oppress, defraud, violate, overflow",
		"kjv" : "get deceitfully, deceive, defraud, drink up, (use) oppress(-ion), -or), do violence (wrong)"
	},
	"H6232" : {
		"lemma" : "עֵשֶׁק",
		"xlit" : "ʻÊsheq",
		"pron" : "ay-shek'",
		"derivation" : "from H6231 (עָשַׁק); oppression",
		"def" : "Eshek, an Israelite",
		"kjv" : "Eshek"
	},
	"H6233" : {
		"lemma" : "עֹשֶׁק",
		"xlit" : "ʻôsheq",
		"pron" : "o'-shek",
		"derivation" : "from H6231 (עָשַׁק)",
		"def" : "injury, fraud, (subjectively) distress, (concretely) unjust gain",
		"kjv" : "cruelly, extortion, oppression, thing (deceitfully gotten)"
	},
	"H6234" : {
		"lemma" : "עׇשְׁקָה",
		"xlit" : "ʻoshqâh",
		"pron" : "osh-kaw'",
		"derivation" : "feminine of H6233 (עֹשֶׁק)",
		"def" : "anguish",
		"kjv" : "oppressed"
	},
	"H6235" : {
		"lemma" : "עֶשֶׂר",
		"xlit" : "ʻeser",
		"pron" : "eh'ser",
		"derivation" : "masculine of term עֲשָׂרָה; from H6237 (עָשַׂר)",
		"def" : "ten (as an accumulation to the extent of the digits)",
		"kjv" : "ten, (fif-, seven-) teen"
	},
	"H6236" : {
		"lemma" : "עֲשַׂר",
		"xlit" : "ʻăsar",
		"pron" : "as-ar'",
		"derivation" : "(Aramaic) masculine עֲשְׂרָה (Aramaic); corresponding to H6235 (עֶשֶׂר)",
		"def" : "ten",
		"kjv" : "ten, [phrase] twelve"
	},
	"H6237" : {
		"lemma" : "עָשַׂר",
		"xlit" : "ʻâsar",
		"pron" : "aw-sar'",
		"derivation" : "a primitive root (identical with H6238 (עָשַׁר)); to accumulate; but used only as denominative from H6235 (עֶשֶׂר)",
		"def" : "to tithe, i.e. to take or give atenth",
		"kjv" : "[idiom] surely, give (take) the tenth, (have, take) tithe(-ing, -s), [idiom] truly"
	},
	"H6238" : {
		"lemma" : "עָשַׁר",
		"xlit" : "ʻâshar",
		"pron" : "aw-shar'",
		"derivation" : "a primitive root",
		"def" : "properly, to accumulate; chiefly (specifically) to grow (causatively, make) rich",
		"kjv" : "be(-come, en-, make, make self, wax) rich, make (1 Kings 22:48 marg). See H6240 (עָשָׂר)"
	},
	"H6239" : {
		"lemma" : "עֹשֶׁר",
		"xlit" : "ʻôsher",
		"pron" : "o'-sher",
		"derivation" : "from H6238 (עָשַׁר)",
		"def" : "wealth",
		"kjv" : "[idiom] far (richer), riches"
	},
	"H6240" : {
		"lemma" : "עָשָׂר",
		"xlit" : "ʻâsâr",
		"pron" : "aw-sawr'",
		"derivation" : "for H6235 (עֶשֶׂר)",
		"def" : "ten (only in combination), i.e. -teen; also (ordinal) -teenth",
		"kjv" : "(eigh-, fif-, four-, nine-, seven-, six-, thir-) teen(-th), [phrase] eleven(-th), [phrase] sixscore thousand, [phrase] twelve(-th)"
	},
	"H6241" : {
		"lemma" : "עִשָּׂרוֹן",
		"xlit" : "ʻissârôwn",
		"pron" : "is-saw-rone'",
		"derivation" : "or עִשָּׂרֹן; from H6235 (עֶשֶׂר)",
		"def" : "(fractional) a tenth part",
		"kjv" : "tenth deal"
	},
	"H6242" : {
		"lemma" : "עֶשְׂרִים",
		"xlit" : "ʻesrîym",
		"pron" : "es-reem'",
		"derivation" : "from H6235 (עֶשֶׂר)",
		"def" : "twenty; also (ordinal) twentieth",
		"kjv" : "(six-) score, twenty(-ieth)"
	},
	"H6243" : {
		"lemma" : "עֶשְׂרִין",
		"xlit" : "ʻesrîyn",
		"pron" : "es-reen'",
		"derivation" : "(Aramaic) corresponding to H6242 (עֶשְׂרִים)",
		"def" : "{twenty; also (ordinal) twentieth}",
		"kjv" : "twenty"
	},
	"H6244" : {
		"lemma" : "עָשֵׁשׁ",
		"xlit" : "ʻâshêsh",
		"pron" : "aw-shaysh'",
		"derivation" : "a primitive root",
		"def" : "probably to shrink, i.e. fail",
		"kjv" : "be consumed"
	},
	"H6245" : {
		"lemma" : "עָשַׁת",
		"xlit" : "ʻâshath",
		"pron" : "aw-shath'",
		"derivation" : "a primitive root",
		"def" : "probably to be sleek, i.e. glossy; hence (through the idea of polishing) to excogitate (as if forming in the mind)",
		"kjv" : "shine, think"
	},
	"H6246" : {
		"lemma" : "עֲשִׁת",
		"xlit" : "ʻăshith",
		"pron" : "ash-eeth'",
		"derivation" : "(Aramaic) corresponding to H6245 (עָשַׁת)",
		"def" : "to purpose",
		"kjv" : "think"
	},
	"H6247" : {
		"lemma" : "עֶשֶׁת",
		"xlit" : "ʻesheth",
		"pron" : "eh'-sheth",
		"derivation" : "from H6245 (עָשַׁת)",
		"def" : "a fabric",
		"kjv" : "bright"
	},
	"H6248" : {
		"lemma" : "עַשְׁתּוּת",
		"xlit" : "ʻashtûwth",
		"pron" : "ash-tooth'",
		"derivation" : "from H6245 (עָשַׁת)",
		"def" : "cogitation",
		"kjv" : "thought"
	},
	"H6249" : {
		"lemma" : "עַשְׁתֵּי",
		"xlit" : "ʻashtêy",
		"pron" : "ash-tay'",
		"derivation" : "apparently masculine plural construction of H6247 (עֶשֶׁת) in the sense of an afterthought (used only in connection with H6240 (עָשָׂר) in lieu of H259 (אֶחָד))",
		"def" : "eleven or (ordinal) eleventh",
		"kjv" : "[phrase] eleven(-th)"
	},
	"H6250" : {
		"lemma" : "עֶשְׁתֹּנָה",
		"xlit" : "ʻeshtônâh",
		"pron" : "esh-to-naw'",
		"derivation" : "from H6245 (עָשַׁת)",
		"def" : "thinking",
		"kjv" : "thought"
	},
	"H6251" : {
		"lemma" : "עַשְׁתְּרָה",
		"xlit" : "ʻashtᵉrâh",
		"pron" : "ash-ter-aw'",
		"derivation" : "probably from H6238 (עָשַׁר)",
		"def" : "increase",
		"kjv" : "flock"
	},
	"H6252" : {
		"lemma" : "עַשְׁתָּרוֹת",
		"xlit" : "ʻAshtârôwth",
		"pron" : "ash-taw-roth'",
		"derivation" : "or עַשְׁתָּרֹת; plural of H6251 (עַשְׁתְּרָה)",
		"def" : "Ashtaroth, the name of a Sidonian deity, and of a place East of the Jordan",
		"kjv" : "Asharoth, Astaroth. See also H1045 (בֵּית עַשְׁתָּרוֹת), H6253 (עַשְׁתֹּרֶת), H6255 (עַשְׁתְּרֹת קַרְנַיִם)"
	},
	"H6253" : {
		"lemma" : "עַשְׁתֹּרֶת",
		"xlit" : "ʻAshtôreth",
		"pron" : "ash-to'reth",
		"derivation" : "probably for H6251 (עַשְׁתְּרָה)",
		"def" : "Ashtoreth, the Phoenician goddess of love (and increase)",
		"kjv" : "Ashtoreth"
	},
	"H6254" : {
		"lemma" : "עַשְׁתְּרָתִי",
		"xlit" : "ʻAshtᵉrâthîy",
		"pron" : "ash-ter-aw-thee'",
		"derivation" : "patrial from H6252 (עַשְׁתָּרוֹת)",
		"def" : "an Ashterathite or inhabitant of Ashtaroth",
		"kjv" : "Ashterathite"
	},
	"H6255" : {
		"lemma" : "עַשְׁתְּרֹת קַרְנַיִם",
		"xlit" : "ʻAshtᵉrôth Qarnayim",
		"pron" : "ash-ter-oth' kar-nah'-yim",
		"derivation" : "from H6252 (עַשְׁתָּרוֹת) and the dual of H7161 (קֶרֶן); Ashtaroth of (the) double horns (a symbol of the deity)",
		"def" : "Ashteroth-Karnaim, a place East of the Jordan",
		"kjv" : "Ashtoreth Karnaim"
	},
	"H6256" : {
		"lemma" : "עֵת",
		"xlit" : "ʻêth",
		"pron" : "ayth",
		"derivation" : "from H5703 (עַד)",
		"def" : "time, especially (adverb with preposition) now, when, etc.",
		"kjv" : "[phrase] after, (al-) ways, [idiom] certain, [phrase] continually, [phrase] evening, long, (due) season, so (long) as, (even-, evening-, noon-) tide, (meal-), what) time, when"
	},
	"H6257" : {
		"lemma" : "עָתַד",
		"xlit" : "ʻâthad",
		"pron" : "aw-thad'",
		"derivation" : "a primitive root",
		"def" : "to prepare",
		"kjv" : "make fit, be ready to become"
	},
	"H6258" : {
		"lemma" : "עַתָּה",
		"xlit" : "ʻattâh",
		"pron" : "at-taw'",
		"derivation" : "from H6256 (עֵת)",
		"def" : "at this time, whether adverb, conjunction or expletive",
		"kjv" : "henceforth, now, straightway, this time, whereas"
	},
	"H6259" : {
		"lemma" : "עָתוּד",
		"xlit" : "ʻâthûwd",
		"pron" : "aw-thood'",
		"derivation" : "passive participle of H6257 (עָתַד)",
		"def" : "prepared",
		"kjv" : "ready"
	},
	"H6260" : {
		"lemma" : "עַתּוּד",
		"xlit" : "ʻattûwd",
		"pron" : "at-tood'",
		"derivation" : "or עַתֻּד; from H6257 (עָתַד)",
		"def" : "prepared, i.e. full grown; spoken only (in plural) of he-goats, or (figuratively) leaders of the people",
		"kjv" : "chief one, (he) goat, ram"
	},
	"H6261" : {
		"lemma" : "עִתִּי",
		"xlit" : "ʻittîy",
		"pron" : "it-tee'",
		"derivation" : "from H6256 (עֵת)",
		"def" : "timely",
		"kjv" : "fit"
	},
	"H6262" : {
		"lemma" : "עַתַּי",
		"xlit" : "ʻAttay",
		"pron" : "at-tah'ee",
		"derivation" : "for H6261 (עִתִּי)",
		"def" : "Attai, the name of three Israelites",
		"kjv" : "Attai"
	},
	"H6263" : {
		"lemma" : "עֲתִיד",
		"xlit" : "ʻăthîyd",
		"pron" : "ath-eed'",
		"derivation" : "(Aramaic) corresponding to H6264 (עָתִיד)",
		"def" : "prepared",
		"kjv" : "ready"
	},
	"H6264" : {
		"lemma" : "עָתִיד",
		"xlit" : "ʻâthîyd",
		"pron" : "aw-theed'",
		"derivation" : "from H6257 (עָתַד)",
		"def" : "prepared; by implication, skilful; feminine plural the future; also treasure",
		"kjv" : "things that shall come, ready, treasures"
	},
	"H6265" : {
		"lemma" : "עֲתָיָה",
		"xlit" : "ʻĂthâyâh",
		"pron" : "ath-aw-yaw'",
		"derivation" : "from H5790 (עוּת) and H3050 (יָהּ); Jah has helped",
		"def" : "Athajah, an Israelite",
		"kjv" : "Athaiah"
	},
	"H6266" : {
		"lemma" : "עָתִיק",
		"xlit" : "ʻâthîyq",
		"pron" : "aw-theek'",
		"derivation" : "from H6275 (עָתַק)",
		"def" : "properly, antique, i.e. venerable or splendid",
		"kjv" : "durable"
	},
	"H6267" : {
		"lemma" : "עַתִּיק",
		"xlit" : "ʻattîyq",
		"pron" : "at-teek'",
		"derivation" : "from H6275 (עָתַק)",
		"def" : "removed, i.e. weaned; also antique",
		"kjv" : "ancient, drawn"
	},
	"H6268" : {
		"lemma" : "עַתִּיק",
		"xlit" : "ʻattîyq",
		"pron" : "at-teek'",
		"derivation" : "(Aramaic) corresponding to H6267 (עַתִּיק)",
		"def" : "venerable",
		"kjv" : "ancient"
	},
	"H6269" : {
		"lemma" : "עֲתָךְ",
		"xlit" : "ʻĂthâk",
		"pron" : "ath-awk'",
		"derivation" : "from an unused root meaning to sojourn; lodging",
		"def" : "Athak, a place in Palestine",
		"kjv" : "Athach"
	},
	"H6270" : {
		"lemma" : "עַתְלַי",
		"xlit" : "ʻAthlay",
		"pron" : "ath-lah'ee",
		"derivation" : "from an unused root meaning to compress; constringent",
		"def" : "Athlai, an Israelite",
		"kjv" : "Athlai"
	},
	"H6271" : {
		"lemma" : "עֲתַלְיָה",
		"xlit" : "ʻĂthalyâh",
		"pron" : "ath-al-yaw'",
		"derivation" : "or עֲתַלְיָהוּ; from the same as H6270 (עַתְלַי) and H3050 (יָהּ); Jah has constrained",
		"def" : "Athaljah, the name of an Israelitess and two Israelites",
		"kjv" : "Athaliah"
	},
	"H6272" : {
		"lemma" : "עָתַם",
		"xlit" : "ʻâtham",
		"pron" : "aw-tham",
		"derivation" : "a primitive root",
		"def" : "probably to glow, i.e. (figuratively) be desolated",
		"kjv" : "be darkened"
	},
	"H6273" : {
		"lemma" : "עׇתְנִי",
		"xlit" : "ʻOthnîy",
		"pron" : "oth-nee'",
		"derivation" : "from an unused root meaning to force; forcible",
		"def" : "Othni, an Israelite",
		"kjv" : "Othni"
	},
	"H6274" : {
		"lemma" : "עׇתְנִיאֵל",
		"xlit" : "ʻOthnîyʼêl",
		"pron" : "oth-nee-ale'",
		"derivation" : "from the same as H6273 (עׇתְנִי) and H410 (אֵל); force of God",
		"def" : "Othniel, an Israelite",
		"kjv" : "Othniel"
	},
	"H6275" : {
		"lemma" : "עָתַק",
		"xlit" : "ʻâthaq",
		"pron" : "aw-thak'",
		"derivation" : "a primitive root",
		"def" : "to remove (intransitive or transitive) figuratively, to grow old; specifically, to transcribe",
		"kjv" : "copy out, leave off, become (wax) old, remove"
	},
	"H6276" : {
		"lemma" : "עָתֵק",
		"xlit" : "ʻâthêq",
		"pron" : "aw-thake'",
		"derivation" : "from H6275 (עָתַק)",
		"def" : "antique, i.e. valued",
		"kjv" : "durable"
	},
	"H6277" : {
		"lemma" : "עָתָק",
		"xlit" : "ʻâthâq",
		"pron" : "aw-thawk'",
		"derivation" : "from H6275 (עָתַק) in the sense of license",
		"def" : "impudent",
		"kjv" : "arrogancy, grievous (hard) things, stiff"
	},
	"H6278" : {
		"lemma" : "עֵת קָצִין",
		"xlit" : "ʻÊth Qâtsîyn",
		"pron" : "ayth kaw-tseen'",
		"derivation" : "from H6256 (עֵת) and H7011 (קַיָּם); time of a judge",
		"def" : "Eth-Katsin, a place in Palestine",
		"kjv" : "Ittahkazin (by including directive enclitic)"
	},
	"H6279" : {
		"lemma" : "עָתַר",
		"xlit" : "ʻâthar",
		"pron" : "aw-thar'",
		"derivation" : "a primitive root (rather denominative from H6281 (עֶתֶר))",
		"def" : "to burn incense in worship, i.e. intercede (reciprocally, listen to prayer)",
		"kjv" : "intreat, (make) pray(-er)"
	},
	"H6280" : {
		"lemma" : "עָתַר",
		"xlit" : "ʻâthar",
		"pron" : "aw-thar'",
		"derivation" : "a primitive root",
		"def" : "to be (causatively, make) abundant",
		"kjv" : "deceitful, multiply"
	},
	"H6281" : {
		"lemma" : "עֶתֶר",
		"xlit" : "ʻEther",
		"pron" : "eh'ther",
		"derivation" : "from H6280 (עָתַר); abundance",
		"def" : "Ether, a place in Palestine",
		"kjv" : "Ether"
	},
	"H6282" : {
		"lemma" : "עָתָר",
		"xlit" : "ʻâthâr",
		"pron" : "aw-thawr'",
		"derivation" : "from H6280 (עָתַר); also compare H6279 (עָתַר)",
		"def" : "incense (as increasing to a volume of smoke); hence  a worshipper",
		"kjv" : "suppliant, thick"
	},
	"H6283" : {
		"lemma" : "עֲתֶרֶת",
		"xlit" : "ʻăthereth",
		"pron" : "ath-eh'-reth",
		"derivation" : "from H6280 (עָתַר)",
		"def" : "copiousness",
		"kjv" : "abundance. p"
	},
	"H6284" : {
		"lemma" : "פָּאָה",
		"xlit" : "pâʼâh",
		"pron" : "paw-aw'",
		"derivation" : "a primitive root",
		"def" : "to puff, i.e. blow away",
		"kjv" : "scatter into corners"
	},
	"H6285" : {
		"lemma" : "פֵּאָה",
		"xlit" : "pêʼâh",
		"pron" : "pay-aw'",
		"derivation" : "feminine of H6311 (פֹּה)",
		"def" : "properly, mouth in a figurative sense, i.e. direction, region, extremity",
		"kjv" : "corner, end, quarter, side"
	},
	"H6286" : {
		"lemma" : "פָּאַר",
		"xlit" : "pâʼar",
		"pron" : "paw-ar'",
		"derivation" : "a primitive root; also denominative from H6288 (פְּאֹרָה)",
		"def" : "to gleam, i.e. (causatively) embellish; figuratively, to boast; also to explain (i.e. make clear) oneself; to shake a tree",
		"kjv" : "beautify, boast self, go over the boughs, glorify (self), glory, vaunt self"
	},
	"H6287" : {
		"lemma" : "פְּאֵר",
		"xlit" : "pᵉʼêr",
		"pron" : "peh-ayr'",
		"derivation" : "from H6286 (פָּאַר)",
		"def" : "an embellishment, i.e. fancy head-dress",
		"kjv" : "beauty, bonnet, goodly, ornament, tire"
	},
	"H6288" : {
		"lemma" : "פְּאֹרָה",
		"xlit" : "pᵉʼôrâh",
		"pron" : "peh-o-raw'",
		"derivation" : "or פֹּרָאה; or פֻּארָה; from H6286 (פָּאַר)",
		"def" : "properly, ornamentation, i.e. (plural) foliage (including the limbs) as bright green",
		"kjv" : "bough, branch, sprig"
	},
	"H6289" : {
		"lemma" : "פָּארוּר",
		"xlit" : "pâʼrûwr",
		"pron" : "paw-roor'",
		"derivation" : "from H6286 (פָּאַר)",
		"def" : "properly, illuminated, i.e. a glow; as noun, a flush (of anxiety)",
		"kjv" : "blackness"
	},
	"H6290" : {
		"lemma" : "פָּארָן",
		"xlit" : "Pâʼrân",
		"pron" : "paw-rawn'",
		"derivation" : "from H6286 (פָּאַר); ornamental",
		"def" : "Paran, a desert of Arabia",
		"kjv" : "Paran"
	},
	"H6291" : {
		"lemma" : "פַּג",
		"xlit" : "pag",
		"pron" : "pag",
		"derivation" : "from an unused root meaning to be torpid, i.e",
		"def" : "crude; an unripe fig",
		"kjv" : "green figuratively"
	},
	"H6292" : {
		"lemma" : "פִּגּוּל",
		"xlit" : "piggûwl",
		"pron" : "pig-gool'",
		"derivation" : "or פִּגֻּל; from an unused root meaning to stink",
		"def" : "properly, fetid, i.e. (figuratively) unclean (ceremonially)",
		"kjv" : "abominable(-tion, thing)"
	},
	"H6293" : {
		"lemma" : "פָּגַע",
		"xlit" : "pâgaʻ",
		"pron" : "paw-gah'",
		"derivation" : "a primitive root",
		"def" : "to impinge, by accident or violence, or (figuratively) by importunity",
		"kjv" : "come (betwixt), cause to entreat, fall (upon), make intercession, intercessor, intreat, lay, light (upon), meet (together), pray, reach, run"
	},
	"H6294" : {
		"lemma" : "פֶּגַע",
		"xlit" : "pegaʻ",
		"pron" : "peh'-gah",
		"derivation" : "from H6293 (פָּגַע)",
		"def" : "impact (casual)",
		"kjv" : "chance, occurent"
	},
	"H6295" : {
		"lemma" : "פַּגְעִיאֵל",
		"xlit" : "Pagʻîyʼêl",
		"pron" : "pag-ee-ale'",
		"derivation" : "from H6294 (פֶּגַע) and H410 (אֵל); accident of God",
		"def" : "Pagiel, an Israelite",
		"kjv" : "Pagiel"
	},
	"H6296" : {
		"lemma" : "פָּגַר",
		"xlit" : "pâgar",
		"pron" : "paw-gar'",
		"derivation" : "a primitive root",
		"def" : "to relax, i.e. become exhausted",
		"kjv" : "be faint"
	},
	"H6297" : {
		"lemma" : "פֶּגֶר",
		"xlit" : "peger",
		"pron" : "peh'gher",
		"derivation" : "from H6296 (פָּגַר)",
		"def" : "a carcase (as limp), whether of man or beast; figuratively, an idolatrous image",
		"kjv" : "carcase, corpse, dead body"
	},
	"H6298" : {
		"lemma" : "פָּגַשׁ",
		"xlit" : "pâgash",
		"pron" : "paw-gash'",
		"derivation" : "a primitive root",
		"def" : "to come in contact with, whether by accident or violence; figuratively, to concur",
		"kjv" : "meet (with, together)"
	},
	"H6299" : {
		"lemma" : "פָּדָה",
		"xlit" : "pâdâh",
		"pron" : "paw-daw'",
		"derivation" : "a primitive root",
		"def" : "to sever, i.e. ransom; gener. to release, preserve",
		"kjv" : "[idiom] at all, deliver, [idiom] by any means, ransom, (that are to be, let be) redeem(-ed), rescue, [idiom] surely"
	},
	"H6300" : {
		"lemma" : "פְּדַהְאֵל",
		"xlit" : "Pᵉdahʼêl",
		"pron" : "ped-ah-ale'",
		"derivation" : "from H6299 (פָּדָה) and H410 (אֵל); God has ransomed",
		"def" : "Pedahel, an Israelite",
		"kjv" : "Pedahel"
	},
	"H6301" : {
		"lemma" : "פְּדָהצוּר",
		"xlit" : "Pᵉdâhtsûwr",
		"pron" : "ped-aw-tsoor'",
		"derivation" : "from H6299 (פָּדָה) and H6697 (צוּר); a rock (i.e. God) has ransomed",
		"def" : "Pedahtsur, an Israelite",
		"kjv" : "Pedahzur"
	},
	"H6302" : {
		"lemma" : "פָּדוּי",
		"xlit" : "pâdûwy",
		"pron" : "paw-doo'ee",
		"derivation" : "passive participle of H6299 (פָּדָה). ransomed (and so occurring under H6299 (פָּדָה))",
		"def" : "as abstractly (in plural masculine) a ransom",
		"kjv" : "(that are) to be (that were) redeemed"
	},
	"H6303" : {
		"lemma" : "פָּדוֹן",
		"xlit" : "Pâdôwn",
		"pron" : "paw-done'",
		"derivation" : "from H6299 (פָּדָה); ransom",
		"def" : "Padon, one of the Nethinim",
		"kjv" : "Padon"
	},
	"H6304" : {
		"lemma" : "פְּדוּת",
		"xlit" : "pᵉdûwth",
		"pron" : "ped-ooth'",
		"derivation" : "or פְּדֻת; from H6929 (קֵדְמָה)lemma קדְמָה missing vowel, corrected to קֵדְמָה",
		"def" : "distinction; also deliverance",
		"kjv" : "division, redeem, redemption"
	},
	"H6305" : {
		"lemma" : "פְּדָיָה",
		"xlit" : "Pᵉdâyâh",
		"pron" : "ped-aw-yaw'",
		"derivation" : "or פְּדָיָהוּ; from H6299 (פָּדָה) and H3050 (יָהּ); Jah has ransomed",
		"def" : "Pedajah, the name of six Israelites",
		"kjv" : "Pedaiah"
	},
	"H6306" : {
		"lemma" : "פִּדְיוֹם",
		"xlit" : "pidyôwm",
		"pron" : "pid-yome'",
		"derivation" : "or פִּדְיֹם; also פִּדְיוֹן; or פִּדְיֹן; from H6299 (פָּדָה)",
		"def" : "a ransom",
		"kjv" : "ransom, that were redeemed, redemption"
	},
	"H6307" : {
		"lemma" : "פַּדָּן",
		"xlit" : "Paddân",
		"pron" : "pad-dawn'",
		"derivation" : "from an unused root meaning to extend; a plateau; or פַּדַּן אֲרָם; from the same and H758 (אֲרָם); the table-land of Aram",
		"def" : "Paddan or Paddan-Aram, a region of Syria",
		"kjv" : "Padan, Padan-aram"
	},
	"H6308" : {
		"lemma" : "פָּדַע",
		"xlit" : "pâdaʻ",
		"pron" : "paw-dah'",
		"derivation" : "a primitive root",
		"def" : "to retrieve",
		"kjv" : "deliver"
	},
	"H6309" : {
		"lemma" : "פֶּדֶר",
		"xlit" : "peder",
		"pron" : "peh'der",
		"derivation" : "from an unused root meaning to be greasy",
		"def" : "suet",
		"kjv" : "fat"
	},
	"H6310" : {
		"lemma" : "פֶּה",
		"xlit" : "peh",
		"pron" : "peh",
		"derivation" : "from H6284 (פָּאָה)",
		"def" : "the mouth (as the means of blowing), whether literal or figurative (particularly speech); specifically edge, portion or side; adverbially (with preposition) according to",
		"kjv" : "accord(-ing as, -ing to), after, appointment, assent, collar, command(-ment), [idiom] eat, edge, end, entry, [phrase] file, hole, [idiom] in, mind, mouth, part, portion, [idiom] (should) say(-ing), sentence, skirt, sound, speech, [idiom] spoken, talk, tenor, [idiom] to, [phrase] two-edged, wish, word"
	},
	"H6311" : {
		"lemma" : "פֹּה",
		"xlit" : "pôh",
		"pron" : "po",
		"derivation" : "or פֹּא; (Job 38:11), or פּוֹ; probably from a primitive inseparable particle 'p' (of demonstrative force) and H1931 (הוּא)",
		"def" : "this place (French ici), i.e. here or hence",
		"kjv" : "here, hither, the one (other, this, that) side"
	},
	"H6312" : {
		"lemma" : "פּוּאָה",
		"xlit" : "Pûwʼâh",
		"pron" : "poo-aw'",
		"derivation" : "or פֻּוָּה; from H6284 (פָּאָה); a blast",
		"def" : "Puah or Puvvah, the name of two Israelites",
		"kjv" : "Phuvah, Pua, Puah"
	},
	"H6313" : {
		"lemma" : "פּוּג",
		"xlit" : "pûwg",
		"pron" : "poog",
		"derivation" : "a primitive root",
		"def" : "to be sluggish",
		"kjv" : "cease, be feeble, faint, be slacked"
	},
	"H6314" : {
		"lemma" : "פּוּגָה",
		"xlit" : "pûwgâh",
		"pron" : "poo-gaw'",
		"derivation" : "from H6313 (פּוּג)",
		"def" : "intermission",
		"kjv" : "rest"
	},
	"H6315" : {
		"lemma" : "פּוּחַ",
		"xlit" : "pûwach",
		"pron" : "poo'akh",
		"derivation" : "a primitive root",
		"def" : "to puff, i.e. blow with the breath or air; hence, to fan (as a breeze), to utter, to kindle (a fire), to scoff",
		"kjv" : "blow (upon), break, puff, bring into a snare, speak, utter"
	},
	"H6316" : {
		"lemma" : "פּוּט",
		"xlit" : "Pûwṭ",
		"pron" : "poot",
		"derivation" : "of foreign origin",
		"def" : "Put, a son of Ham, also the name of his descendants or their region, and of a Persian tribe",
		"kjv" : "Phut, Put"
	},
	"H6317" : {
		"lemma" : "פּוּטִיאֵל",
		"xlit" : "Pûwṭîyʼêl",
		"pron" : "poo-tee-ale'",
		"derivation" : "from an unused root (probably meaning to disparage) and H410 (אֵל); contempt of God",
		"def" : "Putiel, an Israelite",
		"kjv" : "Putiel"
	},
	"H6318" : {
		"lemma" : "פּוֹטִיפַר",
		"xlit" : "Pôwṭîyphar",
		"pron" : "po-tee-far'",
		"derivation" : "of Egyptian derivation",
		"def" : "Potiphar, an Egyptian",
		"kjv" : "Potiphar"
	},
	"H6319" : {
		"lemma" : "פּוֹטִי פֶרַע",
		"xlit" : "Pôwṭîy Pheraʻ",
		"pron" : "po-tee feh'-rah",
		"derivation" : "of Egyptian derivation",
		"def" : "Poti-Phera, an Egyptian",
		"kjv" : "Poti-pherah"
	},
	"H6320" : {
		"lemma" : "פּוּךְ",
		"xlit" : "pûwk",
		"pron" : "pook",
		"derivation" : "from an unused root meaning to paint",
		"def" : "dye (specifically, stibium for the eyes)",
		"kjv" : "fair colours, glistering, paint(-ed) (-ing)"
	},
	"H6321" : {
		"lemma" : "פּוֹל",
		"xlit" : "pôwl",
		"pron" : "pole",
		"derivation" : "from an unused root meaning to be thick",
		"def" : "a bean (as plump)",
		"kjv" : "beans"
	},
	"H6322" : {
		"lemma" : "פּוּל",
		"xlit" : "Pûwl",
		"pron" : "pool",
		"derivation" : "of foreign origin",
		"def" : "Pul, the name of an Assyrian king and of an Ethiopian tribe",
		"kjv" : "Pul"
	},
	"H6323" : {
		"lemma" : "פּוּן",
		"xlit" : "pûwn",
		"pron" : "poon",
		"derivation" : "a primitive root meaning to turn, i.e",
		"def" : "be perplexed",
		"kjv" : "be distracted"
	},
	"H6324" : {
		"lemma" : "פּוּנִי",
		"xlit" : "Pûwnîy",
		"pron" : "poo-nee'",
		"derivation" : "patronymically from an unused name meaning a turn",
		"def" : "a Punite (collectively) or descendants of an unknown Pun",
		"kjv" : "Punites"
	},
	"H6325" : {
		"lemma" : "פּוּנֹן",
		"xlit" : "Pûwnôn",
		"pron" : "poo-none'",
		"derivation" : "from H6323 (פּוּן); perplexity",
		"def" : "Punon, a place in the Desert",
		"kjv" : "Punon"
	},
	"H6326" : {
		"lemma" : "פּוּעָה",
		"xlit" : "Pûwʻâh",
		"pron" : "poo-aw'",
		"derivation" : "from an unused root meaning to glitter; brilliancy",
		"def" : "Puah, an Israelitess",
		"kjv" : "Puah"
	},
	"H6327" : {
		"lemma" : "פּוּץ",
		"xlit" : "pûwts",
		"pron" : "poots",
		"derivation" : "a primitive root",
		"def" : "to dash in pieces, literally or figuratively (especially to disperse)",
		"kjv" : "break (dash, shake) in (to) pieces, cast (abroad), disperse (selves), drive, retire, scatter (abroad), spread abroad"
	},
	"H6328" : {
		"lemma" : "פּוּק",
		"xlit" : "pûwq",
		"pron" : "pook",
		"derivation" : "a primitive root",
		"def" : "to waver",
		"kjv" : "stumble, move"
	},
	"H6329" : {
		"lemma" : "פּוּק",
		"xlit" : "pûwq",
		"pron" : "pook",
		"derivation" : "a primitive root (identical with H6328 (פּוּק) through the idea of dropping out; compare H5312 (נְפַק))",
		"def" : "to issue, i.e. furnish; causatively, to secure; figuratively, to succeed",
		"kjv" : "afford, draw out, further, get, obtain"
	},
	"H6330" : {
		"lemma" : "פּוּקָה",
		"xlit" : "pûwqâh",
		"pron" : "poo-kaw'",
		"derivation" : "from H6328 (פּוּק)",
		"def" : "a stumbling-block",
		"kjv" : "grief"
	},
	"H6331" : {
		"lemma" : "פּוּר",
		"xlit" : "pûwr",
		"pron" : "poor",
		"derivation" : "a primitive root",
		"def" : "to crush",
		"kjv" : "break, bring to nought, [idiom] utterly take"
	},
	"H6332" : {
		"lemma" : "פּוּר",
		"xlit" : "Pûwr",
		"pron" : "poor",
		"derivation" : "also (plural) פּוּרִים; or פֻּרִים; from H6331 (פּוּר)",
		"def" : "a lot (as by means of a broken piece)",
		"kjv" : "Pur, Purim"
	},
	"H6333" : {
		"lemma" : "פּוּרָה",
		"xlit" : "pûwrâh",
		"pron" : "poo-raw'",
		"derivation" : "from H6331 (פּוּר)",
		"def" : "a wine-press (as crushing the grapes)",
		"kjv" : "winepress"
	},
	"H6334" : {
		"lemma" : "פּוֹרָתָא",
		"xlit" : "Pôwrâthâʼ",
		"pron" : "po-raw-thaw'",
		"derivation" : "of Persian origin",
		"def" : "Poratha, a son of Haman",
		"kjv" : "Poratha"
	},
	"H6335" : {
		"lemma" : "פּוּשׁ",
		"xlit" : "pûwsh",
		"pron" : "poosh",
		"derivation" : "a primitive root",
		"def" : "to spread; figuratively, act proudly",
		"kjv" : "grow up, be grown fat, spread selves, be scattered"
	},
	"H6336" : {
		"lemma" : "פּוּתִי",
		"xlit" : "Pûwthîy",
		"pron" : "poo-thee'",
		"derivation" : "patronymically from an unused name meaning a hinge",
		"def" : "a Puthite (collectively) or descendants of an unknown Puth",
		"kjv" : "Puhites (as if from H6312 (פּוּאָה))"
	},
	"H6337" : {
		"lemma" : "פָּז",
		"xlit" : "pâz",
		"pron" : "pawz",
		"derivation" : "from H6338 (פָּזַז)",
		"def" : "pure (gold); hence, gold itself (as refined)",
		"kjv" : "fine (pure) gold"
	},
	"H6338" : {
		"lemma" : "פָּזַז",
		"xlit" : "pâzaz",
		"pron" : "paw-zaz'",
		"derivation" : "a primitive root",
		"def" : "to refine (gold)",
		"kjv" : "best (gold)"
	},
	"H6339" : {
		"lemma" : "פָּזַז",
		"xlit" : "pâzaz",
		"pron" : "paw-zaz'",
		"derivation" : "a primitive root (identical with H6338 (פָּזַז))",
		"def" : "to solidify (as if by refining); also to spring (as if separating the limbs)",
		"kjv" : "leap, be made strong"
	},
	"H6340" : {
		"lemma" : "פָּזַר",
		"xlit" : "pâzar",
		"pron" : "paw-zar'",
		"derivation" : "a primitive root",
		"def" : "to scatter, whether in enmity or bounty",
		"kjv" : "disperse, scatter (abroad)"
	},
	"H6341" : {
		"lemma" : "פַּח",
		"xlit" : "pach",
		"pron" : "pakh",
		"derivation" : "from H6351 (פָּחַח)",
		"def" : "a (metallic) sheet (as pounded thin); also a spring net (as spread out like a lamina)",
		"kjv" : "gin, (thin) plate, snare"
	},
	"H6342" : {
		"lemma" : "פָּחַד",
		"xlit" : "pâchad",
		"pron" : "paw-kkad'",
		"derivation" : "a primitive root",
		"def" : "to be startled (by a sudden alarm); hence, to fear in general",
		"kjv" : "be afraid, stand in awe, (be in) fear, make to shake"
	},
	"H6343" : {
		"lemma" : "פַּחַד",
		"xlit" : "pachad",
		"pron" : "pakh'-ad",
		"derivation" : "from H6342 (פָּחַד)",
		"def" : "a (sudden) alarm (properly, the object feared, by implication, the feeling)",
		"kjv" : "dread(-ful), fear, (thing) great (fear, -ly feared), terror"
	},
	"H6344" : {
		"lemma" : "פַּחַד",
		"xlit" : "pachad",
		"pron" : "pakh'-ad",
		"derivation" : "the same as H6343 (פַּחַד)",
		"def" : "a testicle (as a cause of shame akin to fear)",
		"kjv" : "stone"
	},
	"H6345" : {
		"lemma" : "פַּחְדָּה",
		"xlit" : "pachdâh",
		"pron" : "pakh-daw'",
		"derivation" : "feminine of H6343 (פַּחַד)",
		"def" : "alarm (i.e. awe)",
		"kjv" : "fear"
	},
	"H6346" : {
		"lemma" : "פֶּחָה",
		"xlit" : "pechâh",
		"pron" : "peh-khaw'",
		"derivation" : "of foreign origin",
		"def" : "a prefect (of a city or small district)",
		"kjv" : "captain, deputy, governor"
	},
	"H6347" : {
		"lemma" : "פֶּחָה",
		"xlit" : "pechâh",
		"pron" : "peh-khaw'",
		"derivation" : "(Aramaic) corresponding to H6346 (פֶּחָה)",
		"def" : "{a prefect (of a city or small district)}",
		"kjv" : "captain, governor"
	},
	"H6348" : {
		"lemma" : "פָּחַז",
		"xlit" : "pâchaz",
		"pron" : "paw-khaz'",
		"derivation" : "a primitive root",
		"def" : "to bubble up or froth (as boiling water), i.e. (figuratively) to be unimportant",
		"kjv" : "light"
	},
	"H6349" : {
		"lemma" : "פַּחַז",
		"xlit" : "pachaz",
		"pron" : "pakh'-az",
		"derivation" : "from H6348 (פָּחַז)",
		"def" : "ebullition, i.e. froth (figuratively, lust)",
		"kjv" : "unstable"
	},
	"H6350" : {
		"lemma" : "פַּחֲזוּת",
		"xlit" : "pachăzûwth",
		"pron" : "pakh-az-ooth'",
		"derivation" : "from H6348 (פָּחַז)",
		"def" : "frivolity",
		"kjv" : "lightness"
	},
	"H6351" : {
		"lemma" : "פָּחַח",
		"xlit" : "pâchach",
		"pron" : "paw-khakh'",
		"derivation" : "a primitive root; to batter out; but used only as denominative from H6341 (פַּח)",
		"def" : "to spread a net",
		"kjv" : "be snared"
	},
	"H6352" : {
		"lemma" : "פֶּחָם",
		"xlit" : "pechâm",
		"pron" : "peh-khawm'",
		"derivation" : "perhaps from an unused root probably meaning to be black",
		"def" : "a coal, whether charred or live",
		"kjv" : "coals"
	},
	"H6353" : {
		"lemma" : "פֶּחָר",
		"xlit" : "pechâr",
		"pron" : "peh-khawr'",
		"derivation" : "(Aramaic) from an unused root probably meaning to fashion",
		"def" : "a potter",
		"kjv" : "potter"
	},
	"H6354" : {
		"lemma" : "פַּחַת",
		"xlit" : "pachath",
		"pron" : "pakh'-ath",
		"derivation" : "probably from an unused root apparently meaning to dig",
		"def" : "a pit, especially forcatching animals",
		"kjv" : "hole, pit, snare"
	},
	"H6355" : {
		"lemma" : "פַּחַת מוֹאָב",
		"xlit" : "Pachath Môwʼâb",
		"pron" : "pakh'-ath mo-awb'",
		"derivation" : "from H6354 (פַּחַת) and H4124 (מוֹאָב); pit of Moab",
		"def" : "Pachath-Moab, an Israelite",
		"kjv" : "Pahathmoab"
	},
	"H6356" : {
		"lemma" : "פְּחֶתֶת",
		"xlit" : "pᵉchetheth",
		"pron" : "pekh-eh'-theth",
		"derivation" : "from the same as H6354 (פַּחַת)",
		"def" : "a hole (by mildew in a garment)",
		"kjv" : "fret inward"
	},
	"H6357" : {
		"lemma" : "פִּטְדָה",
		"xlit" : "piṭdâh",
		"pron" : "pit-daw'",
		"derivation" : "of foreign derivation",
		"def" : "a gem, probably the topaz",
		"kjv" : "topaz"
	},
	"H6358" : {
		"lemma" : "פָּטוּר",
		"xlit" : "pâṭûwr",
		"pron" : "paw-toor'",
		"derivation" : "passive participle of H6362 (פָּטַר)",
		"def" : "opened, i.e. (as noun) a bud",
		"kjv" : "open"
	},
	"H6359" : {
		"lemma" : "פָּטִיר",
		"xlit" : "pâṭîyr",
		"pron" : "paw-teer'",
		"derivation" : "from H6362 (פָּטַר)",
		"def" : "open, i.e. unoccupied",
		"kjv" : "free"
	},
	"H6360" : {
		"lemma" : "פַּטִּישׁ",
		"xlit" : "paṭṭîysh",
		"pron" : "pat-teesh'",
		"derivation" : "intensively from an unused root meaning to pound",
		"def" : "a hammer",
		"kjv" : "hammer"
	},
	"H6361" : {
		"lemma" : "פַּטִּישׁ",
		"xlit" : "paṭṭîysh",
		"pron" : "pat-teesh'",
		"derivation" : "(Aramaic) from a root corresponding to that of H6360 (פַּטִּישׁ)",
		"def" : "a gown (as if hammered out wide)",
		"kjv" : "hose"
	},
	"H6362" : {
		"lemma" : "פָּטַר",
		"xlit" : "pâṭar",
		"pron" : "paw-tar'",
		"derivation" : "a primitive root",
		"def" : "to cleave or burst through, i.e. (causatively) to emit, whether literal or figurative (gape)",
		"kjv" : "dismiss, free, let (shoot) out, slip away"
	},
	"H6363" : {
		"lemma" : "פֶּטֶר",
		"xlit" : "peṭer",
		"pron" : "peh'-ter",
		"derivation" : "or פִּטְרָה; from H6362 (פָּטַר)",
		"def" : "a fissure, i.e. (concretely) firstling (as opening the matrix)",
		"kjv" : "firstling, openeth, such as open"
	},
	"H6364" : {
		"lemma" : "פִּי־בֶסֶת",
		"xlit" : "Pîy-Beçeth",
		"pron" : "pee beh'-seth",
		"derivation" : "of Egyptian origin",
		"def" : "Pi-Beseth, a place in Egypt",
		"kjv" : "Pi-beseth"
	},
	"H6365" : {
		"lemma" : "פִּיד",
		"xlit" : "pîyd",
		"pron" : "peed",
		"derivation" : "from an unused root probably meaning to pierce",
		"def" : "(figuratively) misfortune",
		"kjv" : "destruction, ruin"
	},
	"H6366" : {
		"lemma" : "פֵּיָה",
		"xlit" : "pêyâh",
		"pron" : "pay-aw'",
		"derivation" : "or פִּיָּה; feminine of H6310 (פֶּה)",
		"def" : "an edge",
		"kjv" : "(two-) edge(-d)"
	},
	"H6367" : {
		"lemma" : "פִּי הַחִירֹת",
		"xlit" : "Pîy ha-Chîyrôth",
		"pron" : "pee hah-khee-roth'",
		"derivation" : "from H6310 (פֶּה) and the feminine plural of a noun (from the same root as H2356 (חוֹר)), with the article interpolated; mouth of the gorges",
		"def" : "Pi-ha-Chiroth, a place in Egypt",
		"kjv" : "Pi-hahiroth. (In Numbers 14:19 without Pi-.)"
	},
	"H6368" : {
		"lemma" : "פִּיחַ",
		"xlit" : "pîyach",
		"pron" : "pee'-akh",
		"derivation" : "from H6315 (פּוּחַ)",
		"def" : "a powder (as easily puffed away), i.e. ashes or dust",
		"kjv" : "ashes"
	},
	"H6369" : {
		"lemma" : "פִּיכֹל",
		"xlit" : "Pîykôl",
		"pron" : "pee-kole'",
		"derivation" : "apparently from H6310 (פֶּה) and H3605 (כֹּל); mouth of all",
		"def" : "Picol, a Philistine",
		"kjv" : "Phichol"
	},
	"H6370" : {
		"lemma" : "פִּילֶגֶשׁ",
		"xlit" : "pîylegesh",
		"pron" : "pee-leh'-ghesh",
		"derivation" : "or פִּלֶגֶשׁ; of uncertain derivation",
		"def" : "a concubine; also (masculine) a paramour",
		"kjv" : "concubine, paramour"
	},
	"H6371" : {
		"lemma" : "פִּימָה",
		"xlit" : "pîymâh",
		"pron" : "pee-maw'",
		"derivation" : "probably from an unused root meaning to be plump",
		"def" : "obesity",
		"kjv" : "collops"
	},
	"H6372" : {
		"lemma" : "פִּינְחָס",
		"xlit" : "Pîynᵉchâç",
		"pron" : "pee-nekh-aws'",
		"derivation" : "apparently from H6310 (פֶּה) and a variation of H5175 (נָחָשׁ); mouth of a serpent",
		"def" : "Pinechas, the name of three Israelites",
		"kjv" : "Phinehas"
	},
	"H6373" : {
		"lemma" : "פִּינֹן",
		"xlit" : "pîynôn",
		"pron" : "pee-none'",
		"derivation" : "probably the same as H6325 (פּוּנֹן)",
		"def" : "Pinon, an Idumaean",
		"kjv" : "Pinon"
	},
	"H6374" : {
		"lemma" : "פִּיפִיָּה",
		"xlit" : "pîyphîyâh",
		"pron" : "pee-fee-yaw'",
		"derivation" : "for H6366 (פֵּיָה)",
		"def" : "an edge or tooth",
		"kjv" : "tooth, [idiom] two-edged"
	},
	"H6375" : {
		"lemma" : "פִּיק",
		"xlit" : "pîyq",
		"pron" : "peek",
		"derivation" : "from H6329 (פּוּק)",
		"def" : "a tottering",
		"kjv" : "smite together"
	},
	"H6376" : {
		"lemma" : "פִּישׁוֹן",
		"xlit" : "Pîyshôwn",
		"pron" : "pee-shone'",
		"derivation" : "from H6335 (פּוּשׁ); dispersive",
		"def" : "Pishon, a river of Eden",
		"kjv" : "Pison"
	},
	"H6377" : {
		"lemma" : "פִּיתוֹן",
		"xlit" : "Pîythôwn",
		"pron" : "pee-thone'",
		"derivation" : "probably from the same as H6596 (פֹּת); expansive",
		"def" : "Pithon, an Israelite",
		"kjv" : "Pithon"
	},
	"H6378" : {
		"lemma" : "פַּךְ",
		"xlit" : "pak",
		"pron" : "pak",
		"derivation" : "from H6379 (פָּכָה)",
		"def" : "a flask (from which a liquid may flow)",
		"kjv" : "box, vial"
	},
	"H6379" : {
		"lemma" : "פָּכָה",
		"xlit" : "pâkâh",
		"pron" : "paw-kaw'",
		"derivation" : "a primitive root",
		"def" : "to pour",
		"kjv" : "run out"
	},
	"H6380" : {
		"lemma" : "פֹּכֶרֶת צְבָיִים",
		"xlit" : "Pôkereth Tsᵉbâyîym",
		"pron" : "po-keh'-reth tseb-awyeem'",
		"derivation" : "from the active participle (of the same form as the first word) feminine of an unused root (meaning to entrap) and plural of H6643 (צְבִי); trap of gazelles",
		"def" : "Pokereth-Tsebajim, one of the 'servants of Solomon'",
		"kjv" : "Pochereth of Zebaim"
	},
	"H6381" : {
		"lemma" : "פָּלָא",
		"xlit" : "pâlâʼ",
		"pron" : "paw-law'",
		"derivation" : "a primitive root",
		"def" : "properly, perhaps to separate, i.e. distinguish (literally or figuratively); by implication, to be (causatively, make) great, difficult, wonderful",
		"kjv" : "accomplish, (arise...too, be too) hard, hidden, things too high, (be, do, do a, shew) marvelous(-ly, -els, things, work), miracles, perform, separate, make singular, (be, great, make) wonderful(-ers, -ly, things, works), wondrous (things, works, -ly)"
	},
	"H6382" : {
		"lemma" : "פֶּלֶא",
		"xlit" : "peleʼ",
		"pron" : "peh'-leh",
		"derivation" : "from H6381 (פָּלָא)",
		"def" : "a miracle",
		"kjv" : "marvellous thing, wonder(-ful, -fully)"
	},
	"H6383" : {
		"lemma" : "פִּלְאִי",
		"xlit" : "pilʼîy",
		"pron" : "pil-ee'",
		"derivation" : "or פָּלִיא; from H6381 (פָּלָא)",
		"def" : "remarkable",
		"kjv" : "secret, wonderful"
	},
	"H6384" : {
		"lemma" : "פַּלֻּאִי",
		"xlit" : "Palluʼîy",
		"pron" : "pal-loo-ee'",
		"derivation" : "patronymically from H6396 (פַּלּוּא)",
		"def" : "a Palluite (collectively) or descendants of Pallu",
		"kjv" : "Palluites"
	},
	"H6385" : {
		"lemma" : "פָּלַג",
		"xlit" : "pâlag",
		"pron" : "paw-lag'",
		"derivation" : "a primitive root",
		"def" : "to split (literally or figuratively)",
		"kjv" : "divide"
	},
	"H6386" : {
		"lemma" : "פְּלַג",
		"xlit" : "pᵉlag",
		"pron" : "pel-ag'",
		"derivation" : "(Aramaic) corresponding to H6385 (פָּלַג)",
		"def" : "{to split (literally or figuratively)}",
		"kjv" : "divided"
	},
	"H6387" : {
		"lemma" : "פְּלַג",
		"xlit" : "pᵉlag",
		"pron" : "pel-ag'",
		"derivation" : "(Aramaic) from H6386 (פְּלַג)",
		"def" : "a half",
		"kjv" : "dividing"
	},
	"H6388" : {
		"lemma" : "פֶּלֶג",
		"xlit" : "peleg",
		"pron" : "peh'-leg",
		"derivation" : "from H6385 (פָּלַג)",
		"def" : "a rill (i.e. small channel of water, as in irrigation)",
		"kjv" : "river, stream"
	},
	"H6389" : {
		"lemma" : "פֶּלֶג",
		"xlit" : "Peleg",
		"pron" : "peh'-leg",
		"derivation" : "the same as H6388 (פֶּלֶג); earthquake",
		"def" : "Peleg, a son of Shem",
		"kjv" : "Peleg"
	},
	"H6390" : {
		"lemma" : "פְּלַגָּה",
		"xlit" : "pᵉlaggâh",
		"pron" : "pel-ag-gaw'",
		"derivation" : "from H6385 (פָּלַג)",
		"def" : "a runlet, i.e. gully",
		"kjv" : "division, river"
	},
	"H6391" : {
		"lemma" : "פְּלֻגָּה",
		"xlit" : "pᵉluggâh",
		"pron" : "pel-oog-gaw'",
		"derivation" : "from H6385 (פָּלַג)",
		"def" : "a section",
		"kjv" : "division"
	},
	"H6392" : {
		"lemma" : "פְּלֻגָּה",
		"xlit" : "pᵉluggâh",
		"pron" : "pel-oog-gaw'",
		"derivation" : "(Aramaic) corresponding to H6391 (פְּלֻגָּה)",
		"def" : "{a section}",
		"kjv" : "division"
	},
	"H6393" : {
		"lemma" : "פְּלָדָה",
		"xlit" : "pᵉlâdâh",
		"pron" : "pel-aw-daw'",
		"derivation" : "from an unused root meaning to divide",
		"def" : "a cleaver, i.e. iron armature (of a chariot)",
		"kjv" : "torch"
	},
	"H6394" : {
		"lemma" : "פִּלְדָּשׁ",
		"xlit" : "Pildâsh",
		"pron" : "pil-dawsh'",
		"derivation" : "of uncertain derivation",
		"def" : "Pildash, a relative of Abraham",
		"kjv" : "Pildash"
	},
	"H6395" : {
		"lemma" : "פָּלָה",
		"xlit" : "pâlâh",
		"pron" : "paw-law'",
		"derivation" : "a primitive root",
		"def" : "to distinguish (literally or figuratively)",
		"kjv" : "put a difference, show marvellous, separate, set apart, sever, make wonderfully"
	},
	"H6396" : {
		"lemma" : "פַּלּוּא",
		"xlit" : "Pallûwʼ",
		"pron" : "pal-loo'",
		"derivation" : "from H6395 (פָּלָה); distinguished",
		"def" : "Pallu, an Israelite",
		"kjv" : "Pallu, Phallu"
	},
	"H6397" : {
		"lemma" : "פְּלוֹנִי",
		"xlit" : "Pᵉlôwnîy",
		"pron" : "pel-o-nee'",
		"derivation" : "patronymically from an unused name (from H6395 (פָּלָה)) meaning separate",
		"def" : "a Pelonite or inhabitant of an unknown Palon",
		"kjv" : "Pelonite"
	},
	"H6398" : {
		"lemma" : "פָּלַח",
		"xlit" : "pâlach",
		"pron" : "paw-lakh'",
		"derivation" : "a primitive root",
		"def" : "to slice, i.e. break open or pierce",
		"kjv" : "bring forth, cleave, cut, shred, strike through"
	},
	"H6399" : {
		"lemma" : "פְּלַח",
		"xlit" : "pᵉlach",
		"pron" : "pel-akh'",
		"derivation" : "(Aramaic) corresponding to H6398 (פָּלַח)",
		"def" : "to serve or worship",
		"kjv" : "minister, serve"
	},
	"H6400" : {
		"lemma" : "פֶּלַח",
		"xlit" : "pelach",
		"pron" : "peh'-lakh",
		"derivation" : "from H6398 (פָּלַח)",
		"def" : "a slice",
		"kjv" : "piece"
	},
	"H6401" : {
		"lemma" : "פִּלְחָא",
		"xlit" : "Pilchâʼ",
		"pron" : "pil-khaw'",
		"derivation" : "from H6400 (פֶּלַח); slicing",
		"def" : "Pilcha, an Israelite",
		"kjv" : "Pilcha"
	},
	"H6402" : {
		"lemma" : "פׇּלְחָן",
		"xlit" : "polchân",
		"pron" : "pol-khawn'",
		"derivation" : "(Aramaic) from H6399 (פְּלַח)",
		"def" : "worship",
		"kjv" : "service"
	},
	"H6403" : {
		"lemma" : "פָּלַט",
		"xlit" : "pâlaṭ",
		"pron" : "paw-lat'",
		"derivation" : "a primitive root",
		"def" : "to slip out, i.e. escape; causatively, to deliver",
		"kjv" : "calve, carry away safe, deliver, (cause to) escape"
	},
	"H6404" : {
		"lemma" : "פֶּלֶט",
		"xlit" : "Peleṭ",
		"pron" : "peh'-let",
		"derivation" : "from H6403 (פָּלַט); escape",
		"def" : "Pelet, the name of two Israelites",
		"kjv" : "Pelet. See also H1046 (בֵּית פֶּלֶט)"
	},
	"H6405" : {
		"lemma" : "פַּלֵּט",
		"xlit" : "pallêṭ",
		"pron" : "pal-late'",
		"derivation" : "from H6403 (פָּלַט)",
		"def" : "escape",
		"kjv" : "deliverance, escape"
	},
	"H6406" : {
		"lemma" : "פַּלְטִי",
		"xlit" : "Palṭîy",
		"pron" : "pal-tee'",
		"derivation" : "from H6403 (פָּלַט); delivered",
		"def" : "Palti, the name of two Israelites",
		"kjv" : "Palti, Phalti"
	},
	"H6407" : {
		"lemma" : "פַּלְטִי",
		"xlit" : "Palṭîy",
		"pron" : "pal-tee'",
		"derivation" : "patronymically from H6406 (פַּלְטִי)",
		"def" : "a Paltite or descendant of Palti",
		"kjv" : "Paltite"
	},
	"H6408" : {
		"lemma" : "פִּלְטַי",
		"xlit" : "Pilṭay",
		"pron" : "pil-tah'-ee",
		"derivation" : "for H6407 (פַּלְטִי)",
		"def" : "Piltai, an Israelite",
		"kjv" : "Piltai"
	},
	"H6409" : {
		"lemma" : "פַּלְטִיאֵל",
		"xlit" : "Palṭîyʼêl",
		"pron" : "pal-tee-ale'",
		"derivation" : "from the same as H6404 (פֶּלֶט) and H410 (אֵל); deliverance of God",
		"def" : "Paltiel, the name of two Israelites",
		"kjv" : "Paltiel, Phaltiel"
	},
	"H6410" : {
		"lemma" : "פְּלַטְיָה",
		"xlit" : "Pᵉlaṭyâh",
		"pron" : "pel-at-yaw'",
		"derivation" : "or פְּלַטְיָהוּ; from H6403 (פָּלַט) and H3050 (יָהּ); Jah has delivered",
		"def" : "Pelatjah, the name of four Israelites",
		"kjv" : "Pelatiah"
	},
	"H6411" : {
		"lemma" : "פְּלָיָה",
		"xlit" : "Pᵉlâyâh",
		"pron" : "pel-aw-yaw'",
		"derivation" : "or פְּלָאיָה; from H6381 (פָּלָא) and H3050 (יָהּ); Jah has distinguished",
		"def" : "Pelajah, the name of three Israelites",
		"kjv" : "Pelaiah"
	},
	"H6412" : {
		"lemma" : "פָּלִיט",
		"xlit" : "pâlîyṭ",
		"pron" : "paw-leet'",
		"derivation" : "or פָּלֵיט; or פָּלֵט; from H6403 (פָּלַט)",
		"def" : "a refugee",
		"kjv" : "(that have) escape(-d, -th), fugitive"
	},
	"H6413" : {
		"lemma" : "פְּלֵיטָה",
		"xlit" : "pᵉlêyṭâh",
		"pron" : "pel-ay-taw'",
		"derivation" : "or פְּלֵטָה; feminine of H6412 (פָּלִיט)",
		"def" : "deliverance; concretely, an escaped portion",
		"kjv" : "deliverance, (that is) escape(-d), remnant"
	},
	"H6414" : {
		"lemma" : "פָּלִיל",
		"xlit" : "pâlîyl",
		"pron" : "paw-leel'",
		"derivation" : "from H6419 (פָּלַל)",
		"def" : "a magistrate",
		"kjv" : "judge"
	},
	"H6415" : {
		"lemma" : "פְּלִילָה",
		"xlit" : "pᵉlîylâh",
		"pron" : "pel-ee-law'",
		"derivation" : "feminine of H6414 (פָּלִיל)",
		"def" : "justice",
		"kjv" : "judgment"
	},
	"H6416" : {
		"lemma" : "פְּלִילִי",
		"xlit" : "pᵉlîylîy",
		"pron" : "pel-ee-lee'",
		"derivation" : "from H6414 (פָּלִיל)",
		"def" : "judicial",
		"kjv" : "judge"
	},
	"H6417" : {
		"lemma" : "פְּלִילִיָּה",
		"xlit" : "pᵉlîylîyâh",
		"pron" : "pel-ee-lee-yaw'",
		"derivation" : "feminine of H6416 (פְּלִילִי)",
		"def" : "judicature",
		"kjv" : "judgment"
	},
	"H6418" : {
		"lemma" : "פֶּלֶךְ",
		"xlit" : "pelek",
		"pron" : "peh'-lek",
		"derivation" : "from an unused root meaning to be round",
		"def" : "a circuit (i.e. district); also a spindle (as whirled); hence, a crutch",
		"kjv" : "(di-) staff, participle"
	},
	"H6419" : {
		"lemma" : "פָּלַל",
		"xlit" : "pâlal",
		"pron" : "paw-lal'",
		"derivation" : "a primitive root",
		"def" : "to judge (officially or mentally); by extension, to intercede, pray",
		"kjv" : "intreat, judge(-ment), (make) pray(-er, -ing), make supplication"
	},
	"H6420" : {
		"lemma" : "פָּלָל",
		"xlit" : "Pâlâl",
		"pron" : "paw-lawl'",
		"derivation" : "from H6419 (פָּלַל); judge",
		"def" : "Palal, an Israelite",
		"kjv" : "Palal"
	},
	"H6421" : {
		"lemma" : "פְּלַלְיָה",
		"xlit" : "Pᵉlalyâh",
		"pron" : "pel-al-yaw'",
		"derivation" : "from H6419 (פָּלַל) and H3050 (יָהּ); Jah has judged",
		"def" : "Pelaljah, an Israelite",
		"kjv" : "Pelaliah"
	},
	"H6422" : {
		"lemma" : "פַּלְמוֹנִי",
		"xlit" : "palmôwnîy",
		"pron" : "pal-mo-nee'",
		"derivation" : "probably for H6423 (פְּלֹנִי)",
		"def" : "a certain one, i.e. so-and-so",
		"kjv" : "certain"
	},
	"H6423" : {
		"lemma" : "פְּלֹנִי",
		"xlit" : "pᵉlônîy",
		"pron" : "pel-o-nee'",
		"derivation" : "from H6395 (פָּלָה)",
		"def" : "such a one, i.e. a specified person",
		"kjv" : "such"
	},
	"H6424" : {
		"lemma" : "פָּלַס",
		"xlit" : "pâlaç",
		"pron" : "paw-las'",
		"derivation" : "a primitive root",
		"def" : "properly, to roll flat, i.e. prepare (a road); also to revolve, i.e. weigh (mentally)",
		"kjv" : "make, ponder, weigh"
	},
	"H6425" : {
		"lemma" : "פֶּלֶס",
		"xlit" : "peleç",
		"pron" : "peh'-les",
		"derivation" : "from H6424 (פָּלַס)",
		"def" : "a balance",
		"kjv" : "scales, weight"
	},
	"H6426" : {
		"lemma" : "פָּלַץ",
		"xlit" : "pâlats",
		"pron" : "paw-lats'",
		"derivation" : "a primitive root",
		"def" : "properly, perhaps to rend, i.e. (by implication) to quiver",
		"kjv" : "tremble"
	},
	"H6427" : {
		"lemma" : "פַּלָּצוּת",
		"xlit" : "pallâtsûwth",
		"pron" : "pal-law-tsooth'",
		"derivation" : "from H6426 (פָּלַץ)",
		"def" : "affright",
		"kjv" : "fearfulness, horror, trembling"
	},
	"H6428" : {
		"lemma" : "פָּלַשׁ",
		"xlit" : "pâlash",
		"pron" : "paw-lash'",
		"derivation" : "a primitive root",
		"def" : "to roll (in dust)",
		"kjv" : "roll (wallow) self"
	},
	"H6429" : {
		"lemma" : "פְּלֶשֶׁת",
		"xlit" : "Pᵉlesheth",
		"pron" : "pel-eh'-sheth",
		"derivation" : "from H6428 (פָּלַשׁ); rolling, i.e. migratory",
		"def" : "Pelesheth, a region of Syria",
		"kjv" : "Palestina, Palestine, Philistia, Philistines"
	},
	"H6430" : {
		"lemma" : "פְּלִשְׁתִּי",
		"xlit" : "Pᵉlishtîy",
		"pron" : "pel-ish-tee'",
		"derivation" : "patrial from H6429 (פְּלֶשֶׁת)",
		"def" : "a Pelishtite or inhabitant of Pelesheth",
		"kjv" : "Philistine"
	},
	"H6431" : {
		"lemma" : "פֶּלֶת",
		"xlit" : "Peleth",
		"pron" : "peh'-leth",
		"derivation" : "from an unused root meaning to flee; swiftness",
		"def" : "Peleth, the name of two Israelites",
		"kjv" : "Peleth"
	},
	"H6432" : {
		"lemma" : "פְּלֵתִי",
		"xlit" : "Pᵉlêthîy",
		"pron" : "pel-ay-thee'",
		"derivation" : "from the same form as H6431 (פֶּלֶת)",
		"def" : "a courier (collectively) or official messenger",
		"kjv" : "Pelethites"
	},
	"H6433" : {
		"lemma" : "פֻּם",
		"xlit" : "pum",
		"pron" : "poom",
		"derivation" : "(Aramaic) probably for H6310 (פֶּה)",
		"def" : "the mouth (literally or figuratively)",
		"kjv" : "mouth"
	},
	"H6434" : {
		"lemma" : "פֵּן",
		"xlit" : "pên",
		"pron" : "pane",
		"derivation" : "from an unused root meaning to turn",
		"def" : "an angle (of a street or wall)",
		"kjv" : "corner"
	},
	"H6435" : {
		"lemma" : "פֵּן",
		"xlit" : "pên",
		"pron" : "pane",
		"derivation" : "from H6437 (פָּנָה)",
		"def" : "properly, removal; used only (in the construction) adverb as conjunction, lest",
		"kjv" : "(lest) (peradventure), that...not"
	},
	"H6436" : {
		"lemma" : "פַּנַּג",
		"xlit" : "pannag",
		"pron" : "pan-nag'",
		"derivation" : "of uncertain derivation",
		"def" : "probably pastry",
		"kjv" : "Pannag"
	},
	"H6437" : {
		"lemma" : "פָּנָה",
		"xlit" : "pânâh",
		"pron" : "paw-naw'",
		"derivation" : "a primitive root",
		"def" : "to turn; by implication, to face, i.e. appear, look, etc.",
		"kjv" : "appear, at (even-) tide, behold, cast out, come on, [idiom] corner, dawning, empty, go away, lie, look, mark, pass away, prepare, regard, (have) respect (to), (re-) turn (aside, away, back, face, self), [idiom] right (early)"
	},
	"H6438" : {
		"lemma" : "פִּנָּה",
		"xlit" : "pinnâh",
		"pron" : "pin-naw'",
		"derivation" : "feminine of H6434 (פֵּן)",
		"def" : "an angle; by implication, a pinnacle; figuratively, a chieftain",
		"kjv" : "bulwark, chief, corner, stay, tower"
	},
	"H6439" : {
		"lemma" : "פְּנוּאֵל",
		"xlit" : "Pᵉnûwʼêl",
		"pron" : "pen-oo-ale'",
		"derivation" : "or (more properly,) פְּנִיאֵל; from H6437 (פָּנָה) and H410 (אֵל); face of God",
		"def" : "Penuel or Peniel, a place East of Jordan; also (as Penuel) the name of two Israelites",
		"kjv" : "Peniel, Penuel"
	},
	"H6440" : {
		"lemma" : "פָּנִים",
		"xlit" : "pânîym",
		"pron" : "paw-neem'",
		"derivation" : "plural (but always as singular) of an unused noun פָּנֶה; from H6437 (פָּנָה))",
		"def" : "the face (as the part that turns); used in a great variety of applications (literally and figuratively); also (with prepositional prefix) as a preposition (before, etc.)",
		"kjv" : "[phrase] accept, a-(be-) fore(-time), against, anger, [idiom] as (long as), at, [phrase] battle, [phrase] because (of), [phrase] beseech, countenance, edge, [phrase] employ, endure, [phrase] enquire, face, favour, fear of, for, forefront(-part), form(-er time, -ward), from, front, heaviness, [idiom] him(-self), [phrase] honourable, [phrase] impudent, [phrase] in, it, look(-eth) (-s), [idiom] me, [phrase] meet, [idiom] more than, mouth, of, off, (of) old (time), [idiom] on, open, [phrase] out of, over against, the partial, person, [phrase] please, presence, propect, was purposed, by reason of, [phrase] regard, right forth, [phrase] serve, [idiom] shewbread, sight, state, straight, [phrase] street, [idiom] thee, [idiom] them(-selves), through ([phrase] -out), till, time(-s) past, (un-) to(-ward), [phrase] upon, upside ([phrase] down), with(-in, [phrase] -stand), [idiom] ye, [idiom] you"
	},
	"H6441" : {
		"lemma" : "פְּנִימָה",
		"xlit" : "pᵉnîymâh",
		"pron" : "pen-ee'-maw",
		"derivation" : "from H6440 (פָּנִים) with directive enclitic",
		"def" : "faceward, i.e. indoors",
		"kjv" : "(with-) in(-ner part, -ward)"
	},
	"H6442" : {
		"lemma" : "פְּנִימִי",
		"xlit" : "pᵉnîymîy",
		"pron" : "pen-ee-mee'",
		"derivation" : "from H6440 (פָּנִים)",
		"def" : "interior",
		"kjv" : "(with-) in(-ner, -ward)"
	},
	"H6443" : {
		"lemma" : "פָּנִין",
		"xlit" : "pânîyn",
		"pron" : "paw-neen'",
		"derivation" : "or פָּנִי; from the same as H6434 (פֵּן)",
		"def" : "probably a pearl (as round)",
		"kjv" : "ruby"
	},
	"H6444" : {
		"lemma" : "פְּנִנָּה",
		"xlit" : "Pᵉninnâh",
		"pron" : "pen-in-naw'",
		"derivation" : "probably feminine from H6443 (פָּנִין) contr",
		"def" : "Peninnah, an Israelitess",
		"kjv" : "Peninnah"
	},
	"H6445" : {
		"lemma" : "פָּנַק",
		"xlit" : "pânaq",
		"pron" : "paw-nak'",
		"derivation" : "a primitive root",
		"def" : "to enervate",
		"kjv" : "bring up"
	},
	"H6446" : {
		"lemma" : "פַּס",
		"xlit" : "paç",
		"pron" : "pas",
		"derivation" : "from H6461 (פָּסַס); properly, the palm (of the hand) or sole (of the foot) (compare H6447 (פַּס)); by implication (plural)",
		"def" : "a long and sleeved tunic (perhaps simply a wide one; from the original sense of the root, i.e. of many breadths)",
		"kjv" : "(divers) colours"
	},
	"H6447" : {
		"lemma" : "פַּס",
		"xlit" : "paç",
		"pron" : "pas",
		"derivation" : "(Aramaic) from a root corresponding to H6461 (פָּסַס)",
		"def" : "the palm (of the hand, as being spread out)",
		"kjv" : "participle"
	},
	"H6448" : {
		"lemma" : "פָּסַג",
		"xlit" : "pâçag",
		"pron" : "paw-sag'",
		"derivation" : "a primitive root",
		"def" : "to cut up, i.e. (figuratively) contemplate",
		"kjv" : "consider"
	},
	"H6449" : {
		"lemma" : "פִּסְגָּה",
		"xlit" : "Piçgâh",
		"pron" : "pis-gaw'",
		"derivation" : "from H6448 (פָּסַג); a cleft",
		"def" : "Pisgah, a Mountain East of Jordan",
		"kjv" : "Pisgah"
	},
	"H6450" : {
		"lemma" : "פַּס דַּמִּים",
		"xlit" : "Paç Dammîym",
		"pron" : "pas dam-meem'",
		"derivation" : "from H6446 (פַּס) and the plural of H1818 (דָּם); palm (i.e. dell) of bloodshed",
		"def" : "Pas-Dammim, a place in Palestine",
		"kjv" : "Pas-dammim. Compare H658 (אֶפֶס דַּמִּים)"
	},
	"H6451" : {
		"lemma" : "פִּסָּה",
		"xlit" : "piççâh",
		"pron" : "pis-saw'",
		"derivation" : "from H6461 (פָּסַס)",
		"def" : "expansion, i.e. abundance",
		"kjv" : "handful"
	},
	"H6452" : {
		"lemma" : "פָּסַח",
		"xlit" : "pâçach",
		"pron" : "paw-sakh'",
		"derivation" : "a primitive root",
		"def" : "to hop, i.e. (figuratively) skip over (or spare); by implication, to hesitate; also (literally) to limp, to dance",
		"kjv" : "halt, become lame, leap, pass over"
	},
	"H6453" : {
		"lemma" : "פֶּסַח",
		"xlit" : "peçach",
		"pron" : "peh'-sakh",
		"derivation" : "from H6452 (פָּסַח)",
		"def" : "a pretermission, i.e. exemption; used only techically of the Jewish Passover (the festival or the victim)",
		"kjv" : "passover (offering)"
	},
	"H6454" : {
		"lemma" : "פָּסֵחַ",
		"xlit" : "Pâçêach",
		"pron" : "paw-say'-akh",
		"derivation" : "from H6452 (פָּסַח); limping",
		"def" : "Paseach, the name of two Israelites",
		"kjv" : "Paseah, Phaseah"
	},
	"H6455" : {
		"lemma" : "פִּסֵּחַ",
		"xlit" : "piççêach",
		"pron" : "pis-say'-akh",
		"derivation" : "from H6452 (פָּסַח)",
		"def" : "lame",
		"kjv" : "lame"
	},
	"H6456" : {
		"lemma" : "פְּסִיל",
		"xlit" : "pᵉçîyl",
		"pron" : "pes-eel'",
		"derivation" : "from H6458 (פָּסַל)",
		"def" : "an idol",
		"kjv" : "carved (graven) image, quarry"
	},
	"H6457" : {
		"lemma" : "פָּסַךְ",
		"xlit" : "Pâçak",
		"pron" : "paw-sak'",
		"derivation" : "from an unused root meaning to divide; divider",
		"def" : "Pasak, an Israelite",
		"kjv" : "Pasach"
	},
	"H6458" : {
		"lemma" : "פָּסַל",
		"xlit" : "pâçal",
		"pron" : "paw-sal'",
		"derivation" : "a primitive root",
		"def" : "to carve, whether wood or stone",
		"kjv" : "grave, hew"
	},
	"H6459" : {
		"lemma" : "פֶּסֶל",
		"xlit" : "peçel",
		"pron" : "peh'-sel",
		"derivation" : "from H6458 (פָּסַל)",
		"def" : "an idol",
		"kjv" : "carved (graven) image"
	},
	"H6460" : {
		"lemma" : "פְּסַנְטֵרִין",
		"xlit" : "pᵉçanṭêrîyn",
		"pron" : "pes-an-tay-reen'",
		"derivation" : "(Aramaic) or פְּסַנְתֵּרִין; a transliteration of the Greek",
		"def" : "psalterion; a lyre",
		"kjv" : "psaltery"
	},
	"H6461" : {
		"lemma" : "פָּסַס",
		"xlit" : "pâçaç",
		"pron" : "paw-sas'",
		"derivation" : "a primitive root",
		"def" : "probably to disperse, i.e. (intransitive) disappear",
		"kjv" : "cease"
	},
	"H6462" : {
		"lemma" : "פִּסְפָּה",
		"xlit" : "Piçpâh",
		"pron" : "pis-paw'",
		"derivation" : "perhaps from H6461 (פָּסַס); dispersion",
		"def" : "Pispah, an Israelite",
		"kjv" : "Pispah"
	},
	"H6463" : {
		"lemma" : "פָּעָה",
		"xlit" : "pâʻâh",
		"pron" : "paw-aw'",
		"derivation" : "a primitive root",
		"def" : "to scream",
		"kjv" : "cry"
	},
	"H6464" : {
		"lemma" : "פָּעוּ",
		"xlit" : "Pâʻûw",
		"pron" : "paw-oo'",
		"derivation" : "or פָּעִי; from H6463 (פָּעָה); screaming",
		"def" : "Pau or Pai, a place in Edom",
		"kjv" : "Pai, Pau"
	},
	"H6465" : {
		"lemma" : "פְּעוֹר",
		"xlit" : "Pᵉʻôwr",
		"pron" : "peh-ore'",
		"derivation" : "from H6473 (פָּעַר); a gap; also H1187 (בַּעַל פְּעוֹר))",
		"def" : "Peor, a mountain East of Jordan; Baal Peor,  (for a deity worshipped there",
		"kjv" : "Peor. See also H1047 (בֵּית פְּעוֹר)"
	},
	"H6466" : {
		"lemma" : "פָּעַל",
		"xlit" : "pâʻal",
		"pron" : "paw-al'",
		"derivation" : "a primitive root",
		"def" : "to do or make (systematically and habitually), especially to practise",
		"kjv" : "commit, (evil-) do(-er), make(-r), ordain, work(-er)"
	},
	"H6467" : {
		"lemma" : "פֹּעַל",
		"xlit" : "pôʻal",
		"pron" : "po'-al",
		"derivation" : "from H6466 (פָּעַל)",
		"def" : "an act or work (concretely)",
		"kjv" : "act, deed, do, getting, maker, work"
	},
	"H6468" : {
		"lemma" : "פְּעֻלָּה",
		"xlit" : "pᵉʻullâh",
		"pron" : "peh-ool-law'",
		"derivation" : "feminine passive participle of H6466 (פָּעַל)",
		"def" : "(abstractly) work",
		"kjv" : "labour, reward, wages, work"
	},
	"H6469" : {
		"lemma" : "פְּעֻלְּתַי",
		"xlit" : "Pᵉʻullᵉthay",
		"pron" : "peh-ool-leh-thah'-ee",
		"derivation" : "from H6468 (פְּעֻלָּה); laborious",
		"def" : "Peullethai, an Israelite",
		"kjv" : "Peulthai"
	},
	"H6470" : {
		"lemma" : "פָּעַם",
		"xlit" : "pâʻam",
		"pron" : "paw-am'",
		"derivation" : "a primitive root",
		"def" : "to tap, i.e. beat regularly; hence (generally) to impel or agitate",
		"kjv" : "move, trouble"
	},
	"H6471" : {
		"lemma" : "פַּעַם",
		"xlit" : "paʻam",
		"pron" : "pah'-am",
		"derivation" : "or (feminine) פַּעֲמָה; from H6470 (פָּעַם)",
		"def" : "a stroke, literally or figuratively (in various applications, as follow)",
		"kjv" : "anvil, corner, foot(-step), going, (hundred-) fold, [idiom] now, (this) [phrase] once, order, rank, step, [phrase] thrice, (often-), second, this, two) time(-s), twice, wheel"
	},
	"H6472" : {
		"lemma" : "פַּעֲמֹן",
		"xlit" : "paʻămôn",
		"pron" : "pah-am-one'",
		"derivation" : "from H6471 (פַּעַם)",
		"def" : "a bell (as struck)",
		"kjv" : "bell"
	},
	"H6473" : {
		"lemma" : "פָּעַר",
		"xlit" : "pâʻar",
		"pron" : "paw-ar'",
		"derivation" : "a primitive root",
		"def" : "to yawn, i.e. open wide (literally or figuratively)",
		"kjv" : "gape, open (wide)"
	},
	"H6474" : {
		"lemma" : "פַּעֲרַי",
		"xlit" : "Paʻăray",
		"pron" : "pah-ar-ah'-ee",
		"derivation" : "from H6473 (פָּעַר); yawning",
		"def" : "Paarai, an Israelite",
		"kjv" : "Paarai"
	},
	"H6475" : {
		"lemma" : "פָּצָה",
		"xlit" : "pâtsâh",
		"pron" : "paw-tsaw'",
		"derivation" : "a primitive root",
		"def" : "to rend, i.e. open (especially the mouth)",
		"kjv" : "deliver, gape, open, rid, utter"
	},
	"H6476" : {
		"lemma" : "פָּצַח",
		"xlit" : "pâtsach",
		"pron" : "paw-tsakh'",
		"derivation" : "a primitive root",
		"def" : "to break out (in joyful sound)",
		"kjv" : "break (forth, forth into joy), make a loud noise"
	},
	"H6477" : {
		"lemma" : "פְּצִירָה",
		"xlit" : "pᵉtsîyrâh",
		"pron" : "pets-ee-raw'",
		"derivation" : "from H6484 (פָּצַר)",
		"def" : "bluntness",
		"kjv" : "[phrase] file"
	},
	"H6478" : {
		"lemma" : "פָּצַל",
		"xlit" : "pâtsal",
		"pron" : "paw-tsal'",
		"derivation" : "a primitive root",
		"def" : "to peel",
		"kjv" : "pill"
	},
	"H6479" : {
		"lemma" : "פְּצָלָה",
		"xlit" : "pᵉtsâlâh",
		"pron" : "pets-aw-law'",
		"derivation" : "from H6478 (פָּצַל)",
		"def" : "a peeling",
		"kjv" : "strake"
	},
	"H6480" : {
		"lemma" : "פָּצַם",
		"xlit" : "pâtsam",
		"pron" : "paw-tsam'",
		"derivation" : "a primitive root",
		"def" : "to rend (by earthquake)",
		"kjv" : "break"
	},
	"H6481" : {
		"lemma" : "פָּצַע",
		"xlit" : "pâtsaʻ",
		"pron" : "paw-tsah'",
		"derivation" : "a primitive root",
		"def" : "to split, i.e. wound",
		"kjv" : "wound"
	},
	"H6482" : {
		"lemma" : "פֶּצַע",
		"xlit" : "petsaʻ",
		"pron" : "peh'-tsah",
		"derivation" : "from H6481 (פָּצַע)",
		"def" : "a wound",
		"kjv" : "wound(-ing)"
	},
	"H6483" : {
		"lemma" : "פִּצֵּץ",
		"xlit" : "Pitstsêts",
		"pron" : "pits-tsates'",
		"derivation" : "from an unused root meaning to dissever; dispersive",
		"def" : "Pitstsets, a priest",
		"kjv" : "Apses (including the article)"
	},
	"H6484" : {
		"lemma" : "פָּצַר",
		"xlit" : "pâtsar",
		"pron" : "paw-tsar'",
		"derivation" : "a primitive root",
		"def" : "to peck at, i.e. (figuratively) stun or dull",
		"kjv" : "press, urge, stubbornness"
	},
	"H6485" : {
		"lemma" : "פָּקַד",
		"xlit" : "pâqad",
		"pron" : "paw-kad'",
		"derivation" : "a primitive root",
		"def" : "to visit (with friendly or hostile intent); by analogy, to oversee, muster, charge, care for, miss, deposit, etc.",
		"kjv" : "appoint, [idiom] at all, avenge, bestow, (appoint to have the, give a) charge, commit, count, deliver to keep, be empty, enjoin, go see, hurt, do judgment, lack, lay up, look, make, [idiom] by any means, miss, number, officer, (make) overseer, have (the) oversight, punish, reckon, (call to) remember(-brance), set (over), sum, [idiom] surely, visit, want"
	},
	"H6486" : {
		"lemma" : "פְּקֻדָּה",
		"xlit" : "pᵉquddâh",
		"pron" : "pek-ood-daw'",
		"derivation" : "feminine passive participle of H6485 (פָּקַד)",
		"def" : "visitation (in many senses, chiefly official)",
		"kjv" : "account, (that have the) charge, custody, that which...laid up, numbers, office(-r), ordering, oversight, [phrase] prison, reckoning, visitation"
	},
	"H6487" : {
		"lemma" : "פִּקָּדוֹן",
		"xlit" : "piqqâdôwn",
		"pron" : "pik-kaw-done'",
		"derivation" : "from H6485 (פָּקַד)",
		"def" : "a deposit",
		"kjv" : "that which was delivered (to keep), store"
	},
	"H6488" : {
		"lemma" : "פְּקִדֻת",
		"xlit" : "pᵉqiduth",
		"pron" : "pek-ee-dooth'",
		"derivation" : "from H6496 (פָּקִיד)",
		"def" : "supervision",
		"kjv" : "ward"
	},
	"H6489" : {
		"lemma" : "פְּקוֹד",
		"xlit" : "Pᵉqôwd",
		"pron" : "pek-ode'",
		"derivation" : "from H6485 (פָּקַד); punishment",
		"def" : "Pekod, a symbolic name for Babylon",
		"kjv" : "Pekod"
	},
	"H6490" : {
		"lemma" : "פִּקּוּד",
		"xlit" : "piqqûwd",
		"pron" : "pik-kood'",
		"derivation" : "or פִּקֻּד; from H6485 (פָּקַד)",
		"def" : "properly, appointed, i.e. a mandate (of God; plural only, collectively, for the Law)",
		"kjv" : "commandment, precept, statute"
	},
	"H6491" : {
		"lemma" : "פָּקַח",
		"xlit" : "pâqach",
		"pron" : "paw-kakh'",
		"derivation" : "a primitive root",
		"def" : "to open (the senses, especially the eyes); figuratively, to be observant",
		"kjv" : "open"
	},
	"H6492" : {
		"lemma" : "פֶּקַח",
		"xlit" : "Peqach",
		"pron" : "peh'-kakh",
		"derivation" : "from H6491 (פָּקַח); watch",
		"def" : "Pekach, an Israelite king",
		"kjv" : "Pekah"
	},
	"H6493" : {
		"lemma" : "פִּקֵּחַ",
		"xlit" : "piqqêach",
		"pron" : "pik-kay'-akh",
		"derivation" : "from H6491 (פָּקַח)",
		"def" : "clear-sighted; figuratively, intelligent",
		"kjv" : "seeing, wise"
	},
	"H6494" : {
		"lemma" : "פְּקַחְיָה",
		"xlit" : "Pᵉqachyâh",
		"pron" : "pek-akh-yaw'",
		"derivation" : "from H6491 (פָּקַח) and H3050 (יָהּ); Jah has observed",
		"def" : "Pekachjah, an Israelite king",
		"kjv" : "Pekahiah"
	},
	"H6495" : {
		"lemma" : "פְּקַח־קוֹחַ",
		"xlit" : "pᵉqach-qôwach",
		"pron" : "pek-akh-ko'-akh",
		"derivation" : "from H6491 (פָּקַח) redoubled",
		"def" : "opening (of a dungeon), i.e. jail-delivery (figuratively, salvation for sin)",
		"kjv" : "opening of the prison"
	},
	"H6496" : {
		"lemma" : "פָּקִיד",
		"xlit" : "pâqîyd",
		"pron" : "paw-keed'",
		"derivation" : "from H6485 (פָּקַד)",
		"def" : "a superintendent (civil, military or religious)",
		"kjv" : "which had the charge, governor, office, overseer, (that) was set"
	},
	"H6497" : {
		"lemma" : "פֶּקַע",
		"xlit" : "peqaʻ",
		"pron" : "peh'-kah",
		"derivation" : "from an unused root meaning to burst; only used as an architectural term of an ornament similar to H6498 (פַּקֻּעָה)",
		"def" : "a semi-globe",
		"kjv" : "knop"
	},
	"H6498" : {
		"lemma" : "פַּקֻּעָה",
		"xlit" : "paqquʻâh",
		"pron" : "pak-koo-aw'",
		"derivation" : "from the same as H6497 (פֶּקַע)",
		"def" : "the wild cucumber (from splitting open to shed its seeds)",
		"kjv" : "gourd"
	},
	"H6499" : {
		"lemma" : "פַּר",
		"xlit" : "par",
		"pron" : "par",
		"derivation" : "or פָּר; from H6565 (פָּרַר)",
		"def" : "a bullock (apparently as breaking forth in wild strength, or perhaps as dividing the hoof)",
		"kjv" : "([phrase] young) bull(-ock), calf, ox"
	},
	"H6500" : {
		"lemma" : "פָּרָא",
		"xlit" : "pârâʼ",
		"pron" : "paw-raw'",
		"derivation" : "a primitive root",
		"def" : "to bear fruit",
		"kjv" : "be fruitful"
	},
	"H6501" : {
		"lemma" : "פֶּרֶא",
		"xlit" : "pereʼ",
		"pron" : "peh'-reh",
		"derivation" : "or פֶּרֶה; (Jeremiah 2:24), from H6500 (פָּרָא) in the secondary sense of running wild",
		"def" : "the onager",
		"kjv" : "wild (ass)"
	},
	"H6502" : {
		"lemma" : "פִּרְאָם",
		"xlit" : "Pirʼâm",
		"pron" : "pir-awm'",
		"derivation" : "from H6501 (פֶּרֶא); wildly",
		"def" : "Piram, a Canaanite",
		"kjv" : "Piram"
	},
	"H6503" : {
		"lemma" : "פַּרְבָּר",
		"xlit" : "Parbâr",
		"pron" : "par-bawr'",
		"derivation" : "or פַּרְוָר; of foreign origin",
		"def" : "Parbar or Parvar, a quarter of Jerusalem",
		"kjv" : "Parbar, suburb"
	},
	"H6504" : {
		"lemma" : "פָּרַד",
		"xlit" : "pârad",
		"pron" : "paw-rad'",
		"derivation" : "a primitive root",
		"def" : "to break through, i.e. spread or separate (oneself)",
		"kjv" : "disperse, divide, be out of joint, part, scatter (abroad), separate (self), sever self, stretch, sunder"
	},
	"H6505" : {
		"lemma" : "פֶּרֶד",
		"xlit" : "pered",
		"pron" : "peh'-red",
		"derivation" : "from H6504 (פָּרַד)",
		"def" : "a mule (perhaps from his lonely habits)",
		"kjv" : "mule"
	},
	"H6506" : {
		"lemma" : "פִּרְדָּה",
		"xlit" : "pirdâh",
		"pron" : "pir-daw'",
		"derivation" : "feminine of H6505 (פֶּרֶד)",
		"def" : "a she-mule",
		"kjv" : "mule"
	},
	"H6507" : {
		"lemma" : "פְּרֻדָה",
		"xlit" : "pᵉrudâh",
		"pron" : "per-oo-daw'",
		"derivation" : "feminine passive participle of H6504 (פָּרַד)",
		"def" : "something separated, i.e. a kernel",
		"kjv" : "seed"
	},
	"H6508" : {
		"lemma" : "פַּרְדֵּס",
		"xlit" : "pardêç",
		"pron" : "par-dace'",
		"derivation" : "of foreign origin",
		"def" : "a park",
		"kjv" : "forest, orchard"
	},
	"H6509" : {
		"lemma" : "פָּרָה",
		"xlit" : "pârâh",
		"pron" : "paw-raw'",
		"derivation" : "a primitive root",
		"def" : "to bear fruit (literally or figuratively)",
		"kjv" : "bear, bring forth (fruit), (be, cause to be, make) fruitful, grow, increase"
	},
	"H6510" : {
		"lemma" : "פָּרָה",
		"xlit" : "pârâh",
		"pron" : "paw-raw'",
		"derivation" : "feminine of H6499 (פַּר)",
		"def" : "a heifer",
		"kjv" : "cow, heifer, kine"
	},
	"H6511" : {
		"lemma" : "פָּרָה",
		"xlit" : "Pârâh",
		"pron" : "paw-raw'",
		"derivation" : "the same as H6510 (פָּרָה)",
		"def" : "Parah, a place in Palestine",
		"kjv" : "Parah"
	},
	"H6512" : {
		"lemma" : "פֵּרָה",
		"xlit" : "pêrâh",
		"pron" : "pay-raw'",
		"derivation" : "from H6331 (פּוּר)",
		"def" : "a hole (as broken, i.e. dug)",
		"kjv" : "[phrase] mole. Compare H2661 (חֲפֹר)"
	},
	"H6513" : {
		"lemma" : "פֻּרָה",
		"xlit" : "Purâh",
		"pron" : "poo-raw'",
		"derivation" : "for H6288 (פְּאֹרָה); foliage",
		"def" : "Purah, an Israelite",
		"kjv" : "Phurah"
	},
	"H6514" : {
		"lemma" : "פְּרוּדָא",
		"xlit" : "Pᵉrûwdâʼ",
		"pron" : "per-oo-daw'",
		"derivation" : "or פְּרִידָא; from H6504 (פָּרַד); dispersion",
		"def" : "Peruda or Perida, one of 'Solomon's servants'",
		"kjv" : "Perida, Peruda"
	},
	"H6515" : {
		"lemma" : "פָּרוּחַ",
		"xlit" : "Pârûwach",
		"pron" : "paw-roo'-akh",
		"derivation" : "passive participle of H6524 (פָּרַח); blossomed",
		"def" : "Paruach, an Israelite",
		"kjv" : "Paruah"
	},
	"H6516" : {
		"lemma" : "פַּרְוַיִם",
		"xlit" : "Parvayim",
		"pron" : "par-vah'-yim",
		"derivation" : "of foreign origin",
		"def" : "Parvajim, an Oriental region",
		"kjv" : "Parvaim"
	},
	"H6517" : {
		"lemma" : "פָּרוּר",
		"xlit" : "pârûwr",
		"pron" : "paw-roor'",
		"derivation" : "passive participle of H6565 (פָּרַר) in the sense of spreading out (compare H6524 (פָּרַח))",
		"def" : "a skillet (as flat or deep)",
		"kjv" : "pan, pot"
	},
	"H6518" : {
		"lemma" : "פָּרָז",
		"xlit" : "pârâz",
		"pron" : "paw-rawz'",
		"derivation" : "from an unused root meaning to separate, i.e",
		"def" : "decide; a chieftain",
		"kjv" : "village"
	},
	"H6519" : {
		"lemma" : "פְּרָזָה",
		"xlit" : "pᵉrâzâh",
		"pron" : "per-aw-zaw'",
		"derivation" : "from the same as H6518 (פָּרָז)",
		"def" : "an open country",
		"kjv" : "(unwalled) town (without walls), unwalled village"
	},
	"H6520" : {
		"lemma" : "פְּרָזוֹן",
		"xlit" : "pᵉrâzôwn",
		"pron" : "per-aw-zone'",
		"derivation" : "from the same as H6518 (פָּרָז)",
		"def" : "magistracy, i.e. leadership (also concretely, chieftains)",
		"kjv" : "village"
	},
	"H6521" : {
		"lemma" : "פְּרָזִי",
		"xlit" : "pᵉrâzîy",
		"pron" : "per-aw-zee'",
		"derivation" : "or פְּרוֹזִי; from H6519 (פְּרָזָה)",
		"def" : "a rustic",
		"kjv" : "village"
	},
	"H6522" : {
		"lemma" : "פְּרִזִּי",
		"xlit" : "Pᵉrizzîy",
		"pron" : "per-iz-zee'",
		"derivation" : "for H6521 (פְּרָזִי); inhabitant of the open country",
		"def" : "a Perizzite, one of the Canaanitish tribes",
		"kjv" : "Perizzite"
	},
	"H6523" : {
		"lemma" : "פַּרְזֶל",
		"xlit" : "parzel",
		"pron" : "par-zel'",
		"derivation" : "(Aramaic) corresponding to H1270 (בַּרְזֶל)",
		"def" : "iron",
		"kjv" : "iron"
	},
	"H6524" : {
		"lemma" : "פָּרַח",
		"xlit" : "pârach",
		"pron" : "paw-rakh'",
		"derivation" : "a primitive root",
		"def" : "to break forth as a bud, i.e. bloom; generally, to spread; specifically, to fly (as extending the wings); figuratively, to flourish",
		"kjv" : "[idiom] abroad, [idiom] abundantly, blossom, break forth (out), bud, flourish, make fly, grow, spread, spring (up)"
	},
	"H6525" : {
		"lemma" : "פֶּרַח",
		"xlit" : "perach",
		"pron" : "peh'-rakh",
		"derivation" : "from H6524 (פָּרַח)",
		"def" : "a calyx (natural or artificial); generally, bloom",
		"kjv" : "blossom, bud, flower"
	},
	"H6526" : {
		"lemma" : "פִּרְחַח",
		"xlit" : "pirchach",
		"pron" : "pir-khakh'",
		"derivation" : "from H6524 (פָּרַח)",
		"def" : "progeny, i.e. a brood",
		"kjv" : "youth"
	},
	"H6527" : {
		"lemma" : "פָּרַט",
		"xlit" : "pâraṭ",
		"pron" : "paw-rat'",
		"derivation" : "a primitive root",
		"def" : "to scatter words, i.e. prate (or hum)",
		"kjv" : "chant"
	},
	"H6528" : {
		"lemma" : "פֶּרֶט",
		"xlit" : "pereṭ",
		"pron" : "peh'-ret",
		"derivation" : "from H6527 (פָּרַט)",
		"def" : "a stray or single berry",
		"kjv" : "grape"
	},
	"H6529" : {
		"lemma" : "פְּרִי",
		"xlit" : "pᵉrîy",
		"pron" : "per-ee'",
		"derivation" : "from H6509 (פָּרָה)",
		"def" : "fruit (literally or figuratively)",
		"kjv" : "bough, (first-)fruit(-ful), reward"
	},
	"H6530" : {
		"lemma" : "פְּרִיץ",
		"xlit" : "pᵉrîyts",
		"pron" : "per-eets'",
		"derivation" : "from H6555 (פָּרַץ)lemma פּרַץ missing vowel, corrected to פָּרַץ",
		"def" : "violent, i.e. a tyrant",
		"kjv" : "destroyer, ravenous, robber"
	},
	"H6531" : {
		"lemma" : "פֶּרֶךְ",
		"xlit" : "perek",
		"pron" : "peh'-rek",
		"derivation" : "from an unused root meaning to break apart",
		"def" : "fracture, i.e. severity",
		"kjv" : "cruelty, rigour"
	},
	"H6532" : {
		"lemma" : "פֹּרֶכֶת",
		"xlit" : "pôreketh",
		"pron" : "po-reh'-keth",
		"derivation" : "feminine active participle of the same as H6531 (פֶּרֶךְ)",
		"def" : "a separatrix, i.e. (the sacred) screen",
		"kjv" : "vail"
	},
	"H6533" : {
		"lemma" : "פָּרַם",
		"xlit" : "pâram",
		"pron" : "paw-ram'",
		"derivation" : "a primitive root",
		"def" : "to tear",
		"kjv" : "rend"
	},
	"H6534" : {
		"lemma" : "פַּרְמַשְׁתָּא",
		"xlit" : "Parmashtâʼ",
		"pron" : "par-mash-taw'",
		"derivation" : "of Persian origin",
		"def" : "Parmashta, a son of Haman",
		"kjv" : "Parmasta"
	},
	"H6535" : {
		"lemma" : "פַּרְנַךְ",
		"xlit" : "Parnak",
		"pron" : "par-nak'",
		"derivation" : "of uncertain derivation",
		"def" : "Parnak, an Israelite",
		"kjv" : "Parnach"
	},
	"H6536" : {
		"lemma" : "פָּרַס",
		"xlit" : "pâraç",
		"pron" : "paw-ras'",
		"derivation" : "a primitive root",
		"def" : "to break in pieces, i.e. (usually without violence) to split, distribute",
		"kjv" : "deal, divide, have hoofs, part, tear"
	},
	"H6537" : {
		"lemma" : "פְּרַס",
		"xlit" : "pᵉraç",
		"pron" : "per-as'",
		"derivation" : "(Aramaic) corresponding to H6536 (פָּרַס)",
		"def" : "to split up",
		"kjv" : "divide, (U-) pharsin"
	},
	"H6538" : {
		"lemma" : "פֶּרֶס",
		"xlit" : "pereç",
		"pron" : "peh'-res",
		"derivation" : "from H6536 (פָּרַס)",
		"def" : "a claw; also a kind of eagle",
		"kjv" : "claw, ossifrage"
	},
	"H6539" : {
		"lemma" : "פָּרַס",
		"xlit" : "Pâraç",
		"pron" : "paw-ras'",
		"derivation" : "of foreign origin",
		"def" : "Paras (i.e. Persia), an Eastern country, including its inhabitants",
		"kjv" : "Persia, Persians"
	},
	"H6540" : {
		"lemma" : "פָּרַס",
		"xlit" : "Pâraç",
		"pron" : "paw-ras'",
		"derivation" : "(Aramaic) corresponding to H6539 (פָּרַס)",
		"def" : "{Paras (i.e. Persia), an Eastern country, including its inhabitants}",
		"kjv" : "Persia, Persians"
	},
	"H6541" : {
		"lemma" : "פַּרְסָה",
		"xlit" : "parçâh",
		"pron" : "par-saw'",
		"derivation" : "feminine of H6538 (פֶּרֶס)",
		"def" : "a claw or split hoof",
		"kjv" : "claw, (cloven-) footed, hoof"
	},
	"H6542" : {
		"lemma" : "פַּרְסִי",
		"xlit" : "Parçîy",
		"pron" : "par-see'",
		"derivation" : "patrial from H6539 (פָּרַס)",
		"def" : "a Parsite (i.e. Persian), or inhabitant of Peres",
		"kjv" : "Persian"
	},
	"H6543" : {
		"lemma" : "פַּרְסִי",
		"xlit" : "Parçîy",
		"pron" : "par-see'",
		"derivation" : "(Aramaic) corresponding to H6542 (פַּרְסִי)",
		"def" : "{a Parsite (i.e. Persian), or inhabitant of Peres}",
		"kjv" : "Persian"
	},
	"H6544" : {
		"lemma" : "פָּרַע",
		"xlit" : "pâraʻ",
		"pron" : "paw-rah'",
		"derivation" : "a primitive root",
		"def" : "to loosen; by implication, to expose, dismiss; figuratively, absolve, begin",
		"kjv" : "avenge, avoid, bare, go back, let, (make) naked, set at nought, perish, refuse, uncover"
	},
	"H6545" : {
		"lemma" : "פֶּרַע",
		"xlit" : "peraʻ",
		"pron" : "peh'-rah",
		"derivation" : "from H6544 (פָּרַע)",
		"def" : "the hair (as dishevelled)",
		"kjv" : "locks"
	},
	"H6546" : {
		"lemma" : "פַּרְעָה",
		"xlit" : "parʻâh",
		"pron" : "par-aw'",
		"derivation" : "feminine of H6545 (פֶּרַע) (in the sense of beginning)",
		"def" : "leadership (plural concretely, leaders)",
		"kjv" : "[phrase] avenging, revenge"
	},
	"H6547" : {
		"lemma" : "פַּרְעֹה",
		"xlit" : "Parʻôh",
		"pron" : "par-o'",
		"derivation" : "of Egyptian derivation",
		"def" : "Paroh, a general title of Egyptian kings",
		"kjv" : "Pharaoh"
	},
	"H6548" : {
		"lemma" : "פַּרְעֹה חׇפְרַע",
		"xlit" : "Parʻôh Chophraʻ",
		"pron" : "par-o' khof-rah'",
		"derivation" : "of Egyptian derivation",
		"def" : "Paroh-Chophra, an Egyptian king",
		"kjv" : "Pharaoh-hophra"
	},
	"H6549" : {
		"lemma" : "פַּרְעֹה נְכֹה",
		"xlit" : "Parʻôh Nᵉkôh",
		"pron" : "par-o' nek-o'",
		"derivation" : "or פַּרְעֹה נְכוֹ; of Egyptian derivation",
		"def" : "Paroh-Nekoh (or -Neko), an Egyptian king",
		"kjv" : "Pharaoh-necho, Pharaohnechoh"
	},
	"H6550" : {
		"lemma" : "פַּרְעֹשׁ",
		"xlit" : "parʻôsh",
		"pron" : "par-oshe'",
		"derivation" : "probably from H6544 (פָּרַע) and H6211 (עָשׁ)",
		"def" : "a flea (as the isolated insect)",
		"kjv" : "flea"
	},
	"H6551" : {
		"lemma" : "פַּרְעֹשׁ",
		"xlit" : "Parʻôsh",
		"pron" : "par-oshe'",
		"derivation" : "the same as H6550 (פַּרְעֹשׁ)",
		"def" : "Parosh, the name of our Israelite",
		"kjv" : "Parosh, Pharosh"
	},
	"H6552" : {
		"lemma" : "פִּרְעָתוֹן",
		"xlit" : "Pirʻâthôwn",
		"pron" : "pir-aw-thone'",
		"derivation" : "from H6546 (פַּרְעָה); chieftaincy",
		"def" : "Pirathon, a place in Palestine",
		"kjv" : "Pirathon"
	},
	"H6553" : {
		"lemma" : "פִּרְעָתוֹנִי",
		"xlit" : "Pirʻâthôwnîy",
		"pron" : "pir-aw-tho-nee'",
		"derivation" : "or פִּרְעָתֹנִי; patrial from H6552 (פִּרְעָתוֹן)",
		"def" : "a Pirathonite or inhabitant of Pirathon",
		"kjv" : "Pirathonite"
	},
	"H6554" : {
		"lemma" : "פַּרְפַּר",
		"xlit" : "Parpar",
		"pron" : "par-par'",
		"derivation" : "probably from H6565 (פָּרַר) in the sense of rushing; rapid",
		"def" : "Parpar, a river of Syria",
		"kjv" : "Pharpar"
	},
	"H6555" : {
		"lemma" : "פָּרַץ",
		"xlit" : "pârats",
		"pron" : "paw-rats'",
		"derivation" : "a primitive root",
		"def" : "to break out (in many applications, direct and indirect, literal and figurative)",
		"kjv" : "[idiom] abroad, (make a) breach, break (away, down, -er, forth, in, up), burst out, come (spread) abroad, compel, disperse, grow, increase, open, press, scatter, urge"
	},
	"H6556" : {
		"lemma" : "פֶּרֶץ",
		"xlit" : "perets",
		"pron" : "peh'-rets",
		"derivation" : "from H6555 (פָּרַץ)lemma פּרַץ missing vowel, corrected to פָּרַץ",
		"def" : "a break (literally or figuratively)",
		"kjv" : "breach, breaking forth (in), [idiom] forth, gap"
	},
	"H6557" : {
		"lemma" : "פֶּרֶץ",
		"xlit" : "Perets",
		"pron" : "peh'-rets",
		"derivation" : "the same as H6556 (פֶּרֶץ)",
		"def" : "Perets, the name of two Israelites",
		"kjv" : "Perez, Pharez"
	},
	"H6558" : {
		"lemma" : "פַּרְצִי",
		"xlit" : "Partsîy",
		"pron" : "par-tsee'",
		"derivation" : "patronymically from H6557 (פֶּרֶץ)",
		"def" : "a Partsite (collectively) or descendants of Perets",
		"kjv" : "Pharzites"
	},
	"H6559" : {
		"lemma" : "פְּרָצִים",
		"xlit" : "pᵉrâtsîym",
		"pron" : "per-aw-tseem'",
		"derivation" : "plural of H6556 (פֶּרֶץ); breaks",
		"def" : "Peratsim, a mountain in Palestine",
		"kjv" : "Perazim"
	},
	"H6560" : {
		"lemma" : "פֶּרֶץ עֻזָּא",
		"xlit" : "Perets ʻUzzâʼ",
		"pron" : "peh'-rets ooz-zaw'",
		"derivation" : "from H6556 (פֶּרֶץ) and H5798 (עֻזָּא); break of Uzza",
		"def" : "Perets-Uzza, a place in Palestine",
		"kjv" : "Perezuzza"
	},
	"H6561" : {
		"lemma" : "פָּרַק",
		"xlit" : "pâraq",
		"pron" : "paw-rak'",
		"derivation" : "a primitive root",
		"def" : "to break off or crunch; figuratively, to deliver",
		"kjv" : "break (off), deliver, redeem, rend (in pieces), tear in pieces"
	},
	"H6562" : {
		"lemma" : "פְּרַק",
		"xlit" : "pᵉraq",
		"pron" : "per-ak'",
		"derivation" : "(Aramaic) corresponding to H6561 (פָּרַק)",
		"def" : "to discontinue",
		"kjv" : "break off"
	},
	"H6563" : {
		"lemma" : "פֶּרֶק",
		"xlit" : "pereq",
		"pron" : "peh'-rek",
		"derivation" : "from H6561 (פָּרַק)",
		"def" : "rapine; also a fork (in roads)",
		"kjv" : "crossway, robbery"
	},
	"H6564" : {
		"lemma" : "פָּרָק",
		"xlit" : "pârâq",
		"pron" : "paw-rawk'",
		"derivation" : "from H6561 (פָּרַק)",
		"def" : "soup (as full of crumbed meat)",
		"kjv" : "broth. See also H4832 (מַרְפֵּא)"
	},
	"H6565" : {
		"lemma" : "פָּרַר",
		"xlit" : "pârar",
		"pron" : "paw-rar'",
		"derivation" : "a primitive root",
		"def" : "to break up (usually figuratively), i.e. to violate, frustrate",
		"kjv" : "[idiom] any ways, break (asunder), cast off, cause to cease, [idiom] clean, defeat, disannul, disappoint, dissolve, divide, make of none effect, fail, frustrate, bring (come) to nought, [idiom] utterly, make void"
	},
	"H6566" : {
		"lemma" : "פָּרַשׂ",
		"xlit" : "pâras",
		"pron" : "paw-ras'",
		"derivation" : "a primitive root",
		"def" : "to break apart, disperse, etc.",
		"kjv" : "break, chop in pieces, lay open, scatter, spread (abroad, forth, selves, out), stretch (forth, out)"
	},
	"H6567" : {
		"lemma" : "פָּרָשׁ",
		"xlit" : "pârâsh",
		"pron" : "paw-rash'",
		"derivation" : "a primitive root",
		"def" : "to separate, literally (to disperse) or figuratively (to specify); also (by implication) to wound",
		"kjv" : "scatter, declare, distinctly, shew, sting"
	},
	"H6568" : {
		"lemma" : "פְּרַשׁ",
		"xlit" : "pᵉrash",
		"pron" : "per-ash'",
		"derivation" : "(Aramaic) corresponding to H6567 (פָּרָשׁ)",
		"def" : "to specify",
		"kjv" : "distinctly"
	},
	"H6569" : {
		"lemma" : "פֶּרֶשׁ",
		"xlit" : "peresh",
		"pron" : "peh'-resh",
		"derivation" : "from H6567 (פָּרָשׁ)",
		"def" : "excrement (as eliminated)",
		"kjv" : "dung"
	},
	"H6570" : {
		"lemma" : "פֶּרֶשׁ",
		"xlit" : "Peresh",
		"pron" : "peh'-resh",
		"derivation" : "the same as H6569 (פֶּרֶשׁ)",
		"def" : "Peresh, an Israelite",
		"kjv" : "Peresh"
	},
	"H6571" : {
		"lemma" : "פָּרָשׁ",
		"xlit" : "pârâsh",
		"pron" : "paw-rawsh'",
		"derivation" : "from H6567 (פָּרָשׁ); (compare H5483 (סוּס))",
		"def" : "a steed (as stretched out to a vehicle, not single nor for mounting ); also (by implication) a driver (in a chariot), i.e. (collectively) cavalry",
		"kjv" : "horseman"
	},
	"H6572" : {
		"lemma" : "פַּרְשֶׁגֶן",
		"xlit" : "parshegen",
		"pron" : "par-sheh'-ghen",
		"derivation" : "or פַּתְשֶׁגֶן; of foreign origin",
		"def" : "a transcript",
		"kjv" : "copy"
	},
	"H6573" : {
		"lemma" : "פַּרְשֶׁגֶן",
		"xlit" : "parshegen",
		"pron" : "par-sheh'-ghen",
		"derivation" : "(Aramaic) corresponding to H6572 (פַּרְשֶׁגֶן)",
		"def" : "{a transcript}",
		"kjv" : "copy"
	},
	"H6574" : {
		"lemma" : "פַּרְשְׁדֹן",
		"xlit" : "parshᵉdôn",
		"pron" : "par-shed-one'",
		"derivation" : "perhaps by compounding H6567 (פָּרָשׁ) and H6504 (פָּרַד) (in the sense of straddling) (compare H6576 (פַּרְשֵׁז))",
		"def" : "the crotch (or anus)",
		"kjv" : "dirt"
	},
	"H6575" : {
		"lemma" : "פָּרָשָׁה",
		"xlit" : "pârâshâh",
		"pron" : "paw-raw-shaw'",
		"derivation" : "from H6567 (פָּרָשׁ)",
		"def" : "exposition",
		"kjv" : "declaration, sum"
	},
	"H6576" : {
		"lemma" : "פַּרְשֵׁז",
		"xlit" : "parshêz",
		"pron" : "par-shaze'",
		"derivation" : "a root apparently formed by compounding H6567 (פָּרָשׁ) and that of H6518 (פָּרָז) (compare H6574 (פַּרְשְׁדֹן))",
		"def" : "to expand",
		"kjv" : "spread"
	},
	"H6577" : {
		"lemma" : "פַּרְשַׁנְדָּתָא",
		"xlit" : "Parshandâthâʼ",
		"pron" : "par-shan-daw-thaw'",
		"derivation" : "of Persian origin",
		"def" : "Parshandatha, a son of Haman",
		"kjv" : "Parshandatha"
	},
	"H6578" : {
		"lemma" : "פְּרָת",
		"xlit" : "Pᵉrâth",
		"pron" : "per-awth'",
		"derivation" : "from an unused root meaning to break forth; rushing",
		"def" : "Perath (i.e. Euphrates), a river of the East",
		"kjv" : "Euphrates"
	},
	"H6579" : {
		"lemma" : "פַּרְתַּם",
		"xlit" : "partam",
		"pron" : "par-tam'",
		"derivation" : "of Persian origin",
		"def" : "a grandee",
		"kjv" : "(most) noble, prince"
	},
	"H6580" : {
		"lemma" : "פַּשׁ",
		"xlit" : "pash",
		"pron" : "pash",
		"derivation" : "probably from an unused root meaning to disintegrate",
		"def" : "stupidity (as a result of grossness or of degeneracy)",
		"kjv" : "extremity"
	},
	"H6581" : {
		"lemma" : "פָּשָׂה",
		"xlit" : "pâsâh",
		"pron" : "paw-saw'",
		"derivation" : "a primitive root",
		"def" : "to spread",
		"kjv" : "spread"
	},
	"H6582" : {
		"lemma" : "פָּשַׁח",
		"xlit" : "pâshach",
		"pron" : "paw-shakh'",
		"derivation" : "a primitive root",
		"def" : "to tear in pieces",
		"kjv" : "pull in pieces"
	},
	"H6583" : {
		"lemma" : "פַּשְׁחוּר",
		"xlit" : "Pashchûwr",
		"pron" : "pash-khoor'",
		"derivation" : "probably from H6582 (פָּשַׁח); liberation",
		"def" : "Pashchur, the name of four Israelites",
		"kjv" : "Pashur"
	},
	"H6584" : {
		"lemma" : "פָּשַׁט",
		"xlit" : "pâshaṭ",
		"pron" : "paw-shat'",
		"derivation" : "a primitive root",
		"def" : "to spread out (i.e. deploy in hostile array); by analogy, to strip (i.e. unclothe, plunder, flay, etc.)",
		"kjv" : "fall upon, flay, invade, make an invasion, pull off, put off, make a road, run upon, rush, set, spoil, spread selves (abroad), strip (off, self)"
	},
	"H6585" : {
		"lemma" : "פָּשַׂע",
		"xlit" : "pâsaʻ",
		"pron" : "paw-sah'",
		"derivation" : "a primitive root",
		"def" : "to stride (from spreading the legs), i.e. rush upon",
		"kjv" : "go"
	},
	"H6586" : {
		"lemma" : "פָּשַׁע",
		"xlit" : "pâshaʻ",
		"pron" : "paw-shah'",
		"derivation" : "a primitive root (identical with through the idea of expansion)",
		"def" : "to break away (from just authority), i.e. trespass, apostatize, quarrel",
		"kjv" : "offend, rebel, revolt, transgress(-ion, -or)"
	},
	"H6587" : {
		"lemma" : "פֶּשַׂע",
		"xlit" : "pesaʻ",
		"pron" : "peh'-sah",
		"derivation" : "from H6585 (פָּשַׂע)",
		"def" : "a stride",
		"kjv" : "step"
	},
	"H6588" : {
		"lemma" : "פֶּשַׁע",
		"xlit" : "peshaʻ",
		"pron" : "peh'-shah",
		"derivation" : "from H6586 (פָּשַׁע)",
		"def" : "a revolt (national, moral or religious)",
		"kjv" : "rebellion, sin, transgression, trespass"
	},
	"H6589" : {
		"lemma" : "פָּשַׂק",
		"xlit" : "pâsaq",
		"pron" : "paw-sak'",
		"derivation" : "a primitive root",
		"def" : "to dispart (the feet or lips), i.e. become licentious",
		"kjv" : "open (wide)"
	},
	"H6590" : {
		"lemma" : "פְּשַׁר",
		"xlit" : "pᵉshar",
		"pron" : "pesh-ar'",
		"derivation" : "(Aramaic) corresponding to H6622 (פָּתַר)",
		"def" : "to interpret",
		"kjv" : "make (interpretations), interpreting"
	},
	"H6591" : {
		"lemma" : "פְּשַׁר",
		"xlit" : "pᵉshar",
		"pron" : "pesh-ar'",
		"derivation" : "(Aramaic) from H6590 (פְּשַׁר)",
		"def" : "an interpretation",
		"kjv" : "interpretation"
	},
	"H6592" : {
		"lemma" : "פֵּשֶׁר",
		"xlit" : "pêsher",
		"pron" : "pay'-sher",
		"derivation" : "corresponding to H6591 (פְּשַׁר)",
		"def" : "{an interpretation}",
		"kjv" : "interpretation"
	},
	"H6593" : {
		"lemma" : "פִּשְׁתֶּה",
		"xlit" : "pishteh",
		"pron" : "pish-teh'",
		"derivation" : "from the same as H6580 (פַּשׁ) as in the sense of comminuting",
		"def" : "linen (i.e. the thread, as carded)",
		"kjv" : "flax, linen"
	},
	"H6594" : {
		"lemma" : "פִּשְׁתָּה",
		"xlit" : "pishtâh",
		"pron" : "pish-taw'",
		"derivation" : "feminine of H6593 (פִּשְׁתֶּה)",
		"def" : "flax; by implication, a wick",
		"kjv" : "flax, tow"
	},
	"H6595" : {
		"lemma" : "פַּת",
		"xlit" : "path",
		"pron" : "path",
		"derivation" : "from H6626 (פָּתַת)",
		"def" : "a bit",
		"kjv" : "meat, morsel, piece"
	},
	"H6596" : {
		"lemma" : "פֹּת",
		"xlit" : "pôth",
		"pron" : "pohth",
		"derivation" : "or פֹּתָה; (Ezekiel 13:19), from an unused root meaning to open",
		"def" : "a hole, i.e. hinge or the female pudenda",
		"kjv" : "hinge, secret participle"
	},
	"H6597" : {
		"lemma" : "פִּתְאוֹם",
		"xlit" : "pithʼôwm",
		"pron" : "pith-ome'",
		"derivation" : "or פִּתְאֹם; from H6621 (פֶּתַע)",
		"def" : "instantly",
		"kjv" : "straightway, sudden(-ly)"
	},
	"H6598" : {
		"lemma" : "פַּתְבַּג",
		"xlit" : "pathbag",
		"pron" : "pathbag'",
		"derivation" : "of Persian origin",
		"def" : "a dainty",
		"kjv" : "portion (provision) of meat"
	},
	"H6599" : {
		"lemma" : "פִּתְגָּם",
		"xlit" : "pithgâm",
		"pron" : "pith-gawm'",
		"derivation" : "of Persian origin",
		"def" : "a (judicial) sentence",
		"kjv" : "decree, sentence"
	},
	"H6600" : {
		"lemma" : "פִּתְגָּם",
		"xlit" : "pithgâm",
		"pron" : "pith-gawm'",
		"derivation" : "(Aramaic) corresponding to H6599 (פִּתְגָּם)",
		"def" : "a word, answer, letter or decree",
		"kjv" : "answer, letter, matter, word"
	},
	"H6601" : {
		"lemma" : "פָּתָה",
		"xlit" : "pâthâh",
		"pron" : "paw-thaw'",
		"derivation" : "a primitive root",
		"def" : "to open, i.e. be (causatively, make) roomy; usually figuratively (in a mental or moral sense) to be (causatively, make) simple or (in a sinister way) delude",
		"kjv" : "allure, deceive, enlarge, entice, flatter, persuade, silly (one)"
	},
	"H6602" : {
		"lemma" : "פְּתוּאֵל",
		"xlit" : "Pᵉthûwʼêl",
		"pron" : "peth-oo-ale'",
		"derivation" : "from H6601 (פָּתָה) and H410 (אֵל); enlarged of God",
		"def" : "Pethuel, an Israelite",
		"kjv" : "Pethuel"
	},
	"H6603" : {
		"lemma" : "פִּתּוּחַ",
		"xlit" : "pittûwach",
		"pron" : "pit-too'-akh",
		"derivation" : "or פִּתֻּחַ ; passive participle of H6605 (פָּתַח)",
		"def" : "sculpture (in low or high relief or even intaglio)",
		"kjv" : "carved (work) (are, en-) grave(-ing, -n)"
	},
	"H6604" : {
		"lemma" : "פְּתוֹר",
		"xlit" : "Pᵉthôwr",
		"pron" : "peth-ore'",
		"derivation" : "of foreign origin",
		"def" : "Pethor, a place in Mesopotamia",
		"kjv" : "Pethor"
	},
	"H6605" : {
		"lemma" : "פָּתַח",
		"xlit" : "pâthach",
		"pron" : "paw-thakh'",
		"derivation" : "a primitive root",
		"def" : "to open wide (literally or figuratively); specifically, to loosen, begin, plough, carve",
		"kjv" : "appear, break forth, draw (out), let go free, (en-) grave(-n), loose (self), (be, be set) open(-ing), put off, ungird, unstop, have vent"
	},
	"H6606" : {
		"lemma" : "פְּתַח",
		"xlit" : "pᵉthach",
		"pron" : "peth-akh'",
		"derivation" : "(Aramaic) corresponding to H6605 (פָּתַח)",
		"def" : "to open",
		"kjv" : "open"
	},
	"H6607" : {
		"lemma" : "פֶּתַח",
		"xlit" : "pethach",
		"pron" : "peh'-thakh",
		"derivation" : "from H6605 (פָּתַח)",
		"def" : "an opening (literally), i.e. door (gate) or entrance way",
		"kjv" : "door, entering (in), entrance (-ry), gate, opening, place"
	},
	"H6608" : {
		"lemma" : "פֵּתַח",
		"xlit" : "pêthach",
		"pron" : "pay'-thakh",
		"derivation" : "from H6605 (פָּתַח)",
		"def" : "opening (figuratively) i.e. disclosure",
		"kjv" : "entrance"
	},
	"H6609" : {
		"lemma" : "פְּתִחָה",
		"xlit" : "pᵉthichâh",
		"pron" : "peth-ee-khaw'",
		"derivation" : "from H6605 (פָּתַח)",
		"def" : "something opened, i.e. a drawn sword",
		"kjv" : "drawn sword"
	},
	"H6610" : {
		"lemma" : "פִּתְחוֹן",
		"xlit" : "pithchôwn",
		"pron" : "pith-khone'",
		"derivation" : "from H6605 (פָּתַח)",
		"def" : "opening (the act)",
		"kjv" : "open(-ing)"
	},
	"H6611" : {
		"lemma" : "פְּתַחְיָה",
		"xlit" : "Pᵉthachyâh",
		"pron" : "peth-akh-yaw'",
		"derivation" : "from H6605 (פָּתַח) and H3050 (יָהּ); Jah has opened",
		"def" : "Pethachjah, the name of four Israelites",
		"kjv" : "Pethakiah"
	},
	"H6612" : {
		"lemma" : "פְּתִי",
		"xlit" : "pᵉthîy",
		"pron" : "peth-ee'",
		"derivation" : "or פֶּתִי; or פְּתָאִי; from H6601 (פָּתָה)",
		"def" : "silly (i.e. seducible)",
		"kjv" : "foolish, simple(-icity, one)"
	},
	"H6613" : {
		"lemma" : "פְּתַי",
		"xlit" : "pᵉthay",
		"pron" : "peth-ah'-ee",
		"derivation" : "(Aramaic) from a root corresponding to H6601 (פָּתָה)",
		"def" : "open, i.e. (as noun) width",
		"kjv" : "breadth"
	},
	"H6614" : {
		"lemma" : "פְּתִיגִיל",
		"xlit" : "pᵉthîygîyl",
		"pron" : "peth-eeg-eel'",
		"derivation" : "of uncertain derivation",
		"def" : "probably a figured mantle forholidays",
		"kjv" : "stomacher"
	},
	"H6615" : {
		"lemma" : "פְּתַיּוּת",
		"xlit" : "pᵉthayûwth",
		"pron" : "peth-ah-yooth'",
		"derivation" : "from H6612 (פְּתִי)",
		"def" : "silliness (i.e. seducibility)",
		"kjv" : "simple"
	},
	"H6616" : {
		"lemma" : "פָּתִיל",
		"xlit" : "pâthîyl",
		"pron" : "paw-theel'",
		"derivation" : "from H6617 (פָּתַל)",
		"def" : "twine",
		"kjv" : "bound, bracelet, lace, line, ribband, thread, wire"
	},
	"H6617" : {
		"lemma" : "פָּתַל",
		"xlit" : "pâthal",
		"pron" : "paw-thal'",
		"derivation" : "a primitive root",
		"def" : "to twine, i.e. (literally) to struggle or (figuratively) be (morally) tortuous",
		"kjv" : "(shew self) froward, shew self unsavoury, wrestle"
	},
	"H6618" : {
		"lemma" : "פְּתַלְתֹּל",
		"xlit" : "pᵉthaltôl",
		"pron" : "peth-al-tole'",
		"derivation" : "from H6617 (פָּתַל)",
		"def" : "tortuous (i.e. crafty)",
		"kjv" : "crooked"
	},
	"H6619" : {
		"lemma" : "פִּתֹם",
		"xlit" : "Pithôm",
		"pron" : "pee-thome'",
		"derivation" : "of Egyptian derivation",
		"def" : "Pithom, a place in Egypt",
		"kjv" : "Pithom"
	},
	"H6620" : {
		"lemma" : "פֶּתֶן",
		"xlit" : "pethen",
		"pron" : "peh'-then",
		"derivation" : "from an unused root meaning to twist",
		"def" : "an asp (from its contortions)",
		"kjv" : "adder"
	},
	"H6621" : {
		"lemma" : "פֶּתַע",
		"xlit" : "pethaʻ",
		"pron" : "peh'-thah",
		"derivation" : "from an unused root meaning to open (the eyes); (compare H6597 (פִּתְאוֹם))",
		"def" : "a wink, i.e. moment(used only (with or without preposition) adverbially, quickly or unexpectedly",
		"kjv" : "at an instant, suddenly, [idiom] very"
	},
	"H6622" : {
		"lemma" : "פָּתַר",
		"xlit" : "pâthar",
		"pron" : "paw-thar'",
		"derivation" : "a primitive root",
		"def" : "to open up, i.e. (figuratively) interpret (a dream)",
		"kjv" : "interpret(-ation, -er)"
	},
	"H6623" : {
		"lemma" : "פִּתְרוֹן",
		"xlit" : "pithrôwn",
		"pron" : "pith-rone'",
		"derivation" : "or פִּתְרֹן; from H6622 (פָּתַר)",
		"def" : "interpretation (of a dream)",
		"kjv" : "interpretation"
	},
	"H6624" : {
		"lemma" : "פַּתְרוֹס",
		"xlit" : "Pathrôwç",
		"pron" : "path-roce'",
		"derivation" : "of Egyptian derivation",
		"def" : "Pathros, a part of Egypt",
		"kjv" : "Pathros"
	},
	"H6625" : {
		"lemma" : "פַּתְרֻסִי",
		"xlit" : "Pathruçîy",
		"pron" : "path-roo-see'",
		"derivation" : "patrial from H6624 (פַּתְרוֹס)",
		"def" : "a Pathrusite, or inhabitant of Pathros",
		"kjv" : "Pathrusim"
	},
	"H6626" : {
		"lemma" : "פָּתַת",
		"xlit" : "pâthath",
		"pron" : "paw-thath'",
		"derivation" : "a primitive root",
		"def" : "to open, i.e. break",
		"kjv" : "participle x"
	},
	"H6627" : {
		"lemma" : "צָאָה",
		"xlit" : "tsâʼâh",
		"pron" : "tsaw-aw'",
		"derivation" : "from H3318 (יָצָא)",
		"def" : "issue, i.e. (human) excrement",
		"kjv" : "that (which) cometh from (out)"
	},
	"H6628" : {
		"lemma" : "צֶאֶל",
		"xlit" : "tseʼel",
		"pron" : "tseh'-el",
		"derivation" : "from an unused root meaning to be slender",
		"def" : "the lotus tree",
		"kjv" : "shady tree"
	},
	"H6629" : {
		"lemma" : "צֹאן",
		"xlit" : "tsôʼn",
		"pron" : "tsone",
		"derivation" : "or צאוֹן; (Psalm 144:13), from an unused root meaning to migrate",
		"def" : "a collective name for a flock (of sheep or goats); also figuratively (of men)",
		"kjv" : "(small) cattle, flock ([phrase] -s), lamb ([phrase] -s), sheep(-cote, -fold, -shearer, -herds)"
	},
	"H6630" : {
		"lemma" : "צַאֲנָן",
		"xlit" : "Tsaʼănân",
		"pron" : "tsah-an-awn'",
		"derivation" : "from the same as H6629 (צֹאן) used denominatively; sheep pasture",
		"def" : "Zaanan, a place in Palestine",
		"kjv" : "Zaanan"
	},
	"H6631" : {
		"lemma" : "צֶאֱצָא",
		"xlit" : "tseʼĕtsâʼ",
		"pron" : "tseh-ets-aw'",
		"derivation" : "from H3318 (יָצָא)",
		"def" : "issue, i.e. produce, children",
		"kjv" : "that which cometh forth (out), offspring"
	},
	"H6632" : {
		"lemma" : "צָב",
		"xlit" : "tsâb",
		"pron" : "tsawb",
		"derivation" : "from an unused root meaning to establish",
		"def" : "a palanquin or canopy (as a fixture); also a species of lizard (probably as clinging fast)",
		"kjv" : "covered, litter, tortoise"
	},
	"H6633" : {
		"lemma" : "צָבָא",
		"xlit" : "tsâbâʼ",
		"pron" : "tsaw-baw'",
		"derivation" : "a primitive root",
		"def" : "to mass (an army or servants)",
		"kjv" : "assemble, fight, perform, muster, wait upon, war"
	},
	"H6634" : {
		"lemma" : "צְבָא",
		"xlit" : "tsᵉbâʼ",
		"pron" : "tseb-aw'",
		"derivation" : "(Aramaic) corresponding to H6623 (פִּתְרוֹן) in the figurative sense of summoning one's wishes",
		"def" : "to please",
		"kjv" : "will, would"
	},
	"H6635" : {
		"lemma" : "צָבָא",
		"xlit" : "tsâbâʼ",
		"pron" : "tsaw-baw'",
		"derivation" : "or (feminine) צְבָאָה; from H6633 (צָבָא)",
		"def" : "a mass of persons (or figuratively, things), especially reg. organized forwar (an army); by implication, a campaign, literally or figuratively (specifically, hardship, worship)",
		"kjv" : "appointed time, ([phrase]) army, ([phrase]) battle, company, host, service, soldiers, waiting upon, war(-fare)"
	},
	"H6636" : {
		"lemma" : "צְבֹאִים",
		"xlit" : "Tsᵉbôʼîym",
		"pron" : "tseb-o-eem'",
		"derivation" : "or (more correctly) צְבִיִּים; or צְבִיִּם; plural of H6643 (צְבִי); gazelles",
		"def" : "Tseboim or Tsebijim, a place in Palestine",
		"kjv" : "Zeboiim, Zeboim"
	},
	"H6637" : {
		"lemma" : "צֹבֵבָה",
		"xlit" : "Tsôbêbâh",
		"pron" : "tso-bay-baw'",
		"derivation" : "feminine active participle of the same as H6632 (צָב); the canopier (with the article)",
		"def" : "Tsobebah, an Israelitess",
		"kjv" : "Zobebah"
	},
	"H6638" : {
		"lemma" : "צָבָה",
		"xlit" : "tsâbâh",
		"pron" : "tsaw-baw'",
		"derivation" : "a primitive root",
		"def" : "to amass, i.e. grow turgid; specifically, to array an army against",
		"kjv" : "fight swell"
	},
	"H6639" : {
		"lemma" : "צָבֶה",
		"xlit" : "tsâbeh",
		"pron" : "tsaw-beh'",
		"derivation" : "from H6638 (צָבָה)",
		"def" : "turgid",
		"kjv" : "swell"
	},
	"H6640" : {
		"lemma" : "צְבוּ",
		"xlit" : "tsᵉbûw",
		"pron" : "tseb-oo'",
		"derivation" : "(Aramaic) from H6634 (צְבָא)",
		"def" : "properly, will; concretely, an affair (as a matter of determination)",
		"kjv" : "purpose"
	},
	"H6641" : {
		"lemma" : "צָבוּעַ",
		"xlit" : "tsâbûwaʻ",
		"pron" : "tsaw-boo'-ah",
		"derivation" : "passive participle of the same as H6648 (צֶבַע)",
		"def" : "dyed (in stripes), i.e. the hyena",
		"kjv" : "speckled"
	},
	"H6642" : {
		"lemma" : "צָבַט",
		"xlit" : "tsâbaṭ",
		"pron" : "tsaw-bat'",
		"derivation" : "a primitive root",
		"def" : "to grasp, i.e. hand out",
		"kjv" : "reach"
	},
	"H6643" : {
		"lemma" : "צְבִי",
		"xlit" : "tsᵉbîy",
		"pron" : "tseb-ee'",
		"derivation" : "from H6638 (צָבָה) in the sense of prominence",
		"def" : "splendor (as conspicuous); also a gazelle (as beautiful)",
		"kjv" : "beautiful(-ty), glorious (-ry), goodly, pleasant, roe(-buck)"
	},
	"H6644" : {
		"lemma" : "צִבְיָא",
		"xlit" : "Tsibyâʼ",
		"pron" : "tsib-yaw'",
		"derivation" : "for H6645 (צִבְיָּה)",
		"def" : "Tsibja, an Israelite",
		"kjv" : "Zibia"
	},
	"H6645" : {
		"lemma" : "צִבְיָּה",
		"xlit" : "Tsibyâh",
		"pron" : "tsib-yaw'",
		"derivation" : "for H6646 (צְבִיָּה)",
		"def" : "Tsibjah, an Israelite",
		"kjv" : "Zibiah"
	},
	"H6646" : {
		"lemma" : "צְבִיָּה",
		"xlit" : "tsᵉbîyâh",
		"pron" : "tseb-ee-yaw'",
		"derivation" : "feminine of H6643 (צְבִי)",
		"def" : "a female gazelle",
		"kjv" : "roe"
	},
	"H6647" : {
		"lemma" : "צְבַע",
		"xlit" : "tsᵉbaʻ",
		"pron" : "tseb-ah'",
		"derivation" : "(Aramaic) a root corresponding to that of H6648 (צֶבַע)",
		"def" : "to dip",
		"kjv" : "wet"
	},
	"H6648" : {
		"lemma" : "צֶבַע",
		"xlit" : "tsebaʻ",
		"pron" : "tseh'-bah",
		"derivation" : "from an unused root meaning to dip (into coloring fluid)",
		"def" : "a dye",
		"kjv" : "divers, colours"
	},
	"H6649" : {
		"lemma" : "צִבְעוֹן",
		"xlit" : "Tsibʻôwn",
		"pron" : "tsib-one'",
		"derivation" : "from the same as H6648 (צֶבַע); variegated",
		"def" : "Tsibon, an Idumaean",
		"kjv" : "Zibeon"
	},
	"H6650" : {
		"lemma" : "צְבֹעִים",
		"xlit" : "Tsᵉbôʻîym",
		"pron" : "tseb-o-eem'",
		"derivation" : "plural of H6641 (צָבוּעַ); hyenas",
		"def" : "Tseboim, a place in Palestine",
		"kjv" : "Zeboim"
	},
	"H6651" : {
		"lemma" : "צָבַר",
		"xlit" : "tsâbar",
		"pron" : "tsaw-bar'",
		"derivation" : "a primitive root",
		"def" : "to aggregate",
		"kjv" : "gather (together), heap (up), lay up"
	},
	"H6652" : {
		"lemma" : "צִבֻּר",
		"xlit" : "tsibbur",
		"pron" : "tsib-boor'",
		"derivation" : "from H6551 (פַּרְעֹשׁ)",
		"def" : "a pile",
		"kjv" : "heap"
	},
	"H6653" : {
		"lemma" : "צֶבֶת",
		"xlit" : "tsebeth",
		"pron" : "tseh'-beth",
		"derivation" : "from an unused root apparently meaning to grip",
		"def" : "a lock of stalks",
		"kjv" : "handful"
	},
	"H6654" : {
		"lemma" : "צַד",
		"xlit" : "tsad",
		"pron" : "tsad",
		"derivation" : "contr. from an unused root meaning to sidle off",
		"def" : "a side; figuratively, an adversary",
		"kjv" : "(be-) side"
	},
	"H6655" : {
		"lemma" : "צַד",
		"xlit" : "tsad",
		"pron" : "tsad",
		"derivation" : "(Aramaic) corresponding to H6654 (צַד)",
		"def" : "used adverbially (with preposition) at or upon the side of",
		"kjv" : "against, concerning"
	},
	"H6656" : {
		"lemma" : "צְדָא",
		"xlit" : "tsᵉdâʼ",
		"pron" : "tsed-aw'",
		"derivation" : "(Aramaic) from an unused root corresponding to H6658 (צָדָה) in the sense of intentness",
		"def" : "a (sinister) design",
		"kjv" : "true"
	},
	"H6657" : {
		"lemma" : "צְדָד",
		"xlit" : "Tsᵉdâd",
		"pron" : "tsed-awd'",
		"derivation" : "from the same as H6654 (צַד); a siding",
		"def" : "Tsedad, a place near Palestine",
		"kjv" : "Zedad"
	},
	"H6658" : {
		"lemma" : "צָדָה",
		"xlit" : "tsâdâh",
		"pron" : "tsaw-daw'",
		"derivation" : "a primitive root",
		"def" : "to chase; by implication, to desolate",
		"kjv" : "destroy, hunt, lie in wait"
	},
	"H6659" : {
		"lemma" : "צָדוֹק",
		"xlit" : "Tsâdôwq",
		"pron" : "tsaw-doke'",
		"derivation" : "from H6663 (צָדַק); just",
		"def" : "Tsadok, the name of eight or nine Israelites",
		"kjv" : "Zadok"
	},
	"H6660" : {
		"lemma" : "צְדִיָּה",
		"xlit" : "tsᵉdîyâh",
		"pron" : "tsed-ee-yaw'",
		"derivation" : "from H6658 (צָדָה); (compare H6656 (צְדָא))",
		"def" : "design",
		"kjv" : "lying in wait"
	},
	"H6661" : {
		"lemma" : "צִדִּים",
		"xlit" : "Tsiddîym",
		"pron" : "tsid-deem'",
		"derivation" : "plural of H6654 (צַד); sides",
		"def" : "Tsiddim (with the article), a place in Palestine",
		"kjv" : "Ziddim"
	},
	"H6662" : {
		"lemma" : "צַדִּיק",
		"xlit" : "tsaddîyq",
		"pron" : "tsad-deek'",
		"derivation" : "from H6663 (צָדַק)",
		"def" : "just",
		"kjv" : "just, lawful, righteous (man)"
	},
	"H6663" : {
		"lemma" : "צָדַק",
		"xlit" : "tsâdaq",
		"pron" : "tsaw-dak'",
		"derivation" : "a primitive root",
		"def" : "to be (causatively, make) right (in a moral or forensic sense)",
		"kjv" : "cleanse, clear self, (be, do) just(-ice, -ify, -ify self), (be turn to) righteous(-ness)"
	},
	"H6664" : {
		"lemma" : "צֶדֶק",
		"xlit" : "tsedeq",
		"pron" : "tseh'-dek",
		"derivation" : "from H6663 (צָדַק)",
		"def" : "the right (natural, moral or legal); also (abstractly) equity or (figuratively) prosperity",
		"kjv" : "[idiom] even, ([idiom] that which is altogether) just(-ice), (un-)right(-eous) (cause, -ly, -ness)"
	},
	"H6665" : {
		"lemma" : "צִדְקָה",
		"xlit" : "tsidqâh",
		"pron" : "tsid-kaw'",
		"derivation" : "(Aramaic) corresponding to H6666 (צְדָקָה)",
		"def" : "beneficence",
		"kjv" : "righteousness"
	},
	"H6666" : {
		"lemma" : "צְדָקָה",
		"xlit" : "tsᵉdâqâh",
		"pron" : "tsed-aw-kaw'",
		"derivation" : "from H6663 (צָדַק)",
		"def" : "rightness (abstractly), subjectively (rectitude), objectively (justice), morally (virtue) or figuratively (prosperity)",
		"kjv" : "justice, moderately, right(-eous) (act, -ly, -ness)"
	},
	"H6667" : {
		"lemma" : "צִדְקִיָּה",
		"xlit" : "Tsidqîyâh",
		"pron" : "tsid-kee-yaw'",
		"derivation" : "or צִדְקִיָּהוּ; from H6664 (צֶדֶק) and H3050 (יָהּ); right of Jah",
		"def" : "Tsidkijah, the name of six Israelites",
		"kjv" : "Zedekiah, Zidkijah"
	},
	"H6668" : {
		"lemma" : "צָהַב",
		"xlit" : "tsâhab",
		"pron" : "tsaw-hab'",
		"derivation" : "a primitive root",
		"def" : "to glitter, i.e. be golden in color",
		"kjv" : "[idiom] fine"
	},
	"H6669" : {
		"lemma" : "צָהֹב",
		"xlit" : "tsâhôb",
		"pron" : "tsaw-obe'",
		"derivation" : "from H6668 (צָהַב)",
		"def" : "golden in color",
		"kjv" : "yellow"
	},
	"H6670" : {
		"lemma" : "צָהַל",
		"xlit" : "tsâhal",
		"pron" : "tsaw-hal'",
		"derivation" : "a primitive root",
		"def" : "to gleam, i.e. (figuratively) be cheerful; by transference to sound clear (of various animal or human expressions)",
		"kjv" : "bellow, cry aloud (out), lift up, neigh, rejoice, make to shine, shout"
	},
	"H6671" : {
		"lemma" : "צָהַר",
		"xlit" : "tsâhar",
		"pron" : "tsaw-har'",
		"derivation" : "a primitive root; to glisten; used only as denominative from H3323 (יִצְהָר)",
		"def" : "to press out oil",
		"kjv" : "make oil"
	},
	"H6672" : {
		"lemma" : "צֹהַר",
		"xlit" : "tsôhar",
		"pron" : "tso'-har",
		"derivation" : "from H6671 (צָהַר)",
		"def" : "a light (i.e. window); dual double light, i.e. noon",
		"kjv" : "midday, noon(-day, -tide), window"
	},
	"H6673" : {
		"lemma" : "צַו",
		"xlit" : "tsav",
		"pron" : "tsav",
		"derivation" : "or צָו; from H6680 (צָוָה)",
		"def" : "an injunction",
		"kjv" : "commandment, precept"
	},
	"H6674" : {
		"lemma" : "צוֹא",
		"xlit" : "tsôwʼ",
		"pron" : "tso",
		"derivation" : "or צֹא; from an unused root meaning to issue",
		"def" : "soiled (as if excrementitious)",
		"kjv" : "filthy"
	},
	"H6675" : {
		"lemma" : "צוֹאָה",
		"xlit" : "tsôwʼâh",
		"pron" : "tso-aw'",
		"derivation" : "or צֹאָה ; feminine of H6674 (צוֹא)",
		"def" : "excrement; generally, dirt; figuratively, pollution",
		"kjv" : "dung, filth(-iness). Marg. for H2716 (חֶרֶא)"
	},
	"H6676" : {
		"lemma" : "צַוַּאר",
		"xlit" : "tsavvaʼr",
		"pron" : "tsav-var'",
		"derivation" : "(Aramaic) corresponding to H6677 (צַוָּאר)",
		"def" : "{the back of the neck (as that on which burdens are bound)}",
		"kjv" : "neck"
	},
	"H6677" : {
		"lemma" : "צַוָּאר",
		"xlit" : "tsavvâʼr",
		"pron" : "tsav-vawr'",
		"derivation" : "or צַוָּר; (Nehemiah 3:5), or צַוָּרֹןlemma צַוָּרן missing vowel, corrected to צַוָּרֹן; (Song of Solomon 4:9), or (feminine) צַוָּארָה; (Micah 2:3), intensively from H6696 (צוּר) in the sense of binding",
		"def" : "the back of the neck (as that on which burdens are bound)",
		"kjv" : "neck"
	},
	"H6678" : {
		"lemma" : "צוֹבָא",
		"xlit" : "Tsôwbâʼ",
		"pron" : "tso-baw'",
		"derivation" : "or צוֹבָה; or צֹבָה; from an unused root meaning to station; a station",
		"def" : "Zoba or Zobah, a region of Syria",
		"kjv" : "Zoba, Zobah"
	},
	"H6679" : {
		"lemma" : "צוּד",
		"xlit" : "tsûwd",
		"pron" : "tsood",
		"derivation" : "a primitive root; also denominative from H6718 (צַיִד)",
		"def" : "to lie alongside (i.e. in wait); by implication, to catch an animal (figuratively, men); to victual (for a journey)",
		"kjv" : "chase, hunt, sore, take (provision)"
	},
	"H6680" : {
		"lemma" : "צָוָה",
		"xlit" : "tsâvâh",
		"pron" : "tsaw-vaw'",
		"derivation" : "a primitive root",
		"def" : "(intensively) to constitute, enjoin",
		"kjv" : "appoint, (for-) bid, (give a) charge, (give a, give in, send with) command(-er, -ment), send a messenger, put, (set) in order"
	},
	"H6681" : {
		"lemma" : "צָוַח",
		"xlit" : "tsâvach",
		"pron" : "tsaw-vakh'",
		"derivation" : "a primitive root",
		"def" : "to screech (exultingly)",
		"kjv" : "shout"
	},
	"H6682" : {
		"lemma" : "צְוָחָה",
		"xlit" : "tsᵉvâchâh",
		"pron" : "tsev-aw-khaw'",
		"derivation" : "from H6681 (צָוַח)",
		"def" : "a screech (of anguish)",
		"kjv" : "cry(-ing)"
	},
	"H6683" : {
		"lemma" : "צוּלָה",
		"xlit" : "tsûwlâh",
		"pron" : "tsoo-law'",
		"derivation" : "from an unused root meaning to sink",
		"def" : "an abyss (of the sea)",
		"kjv" : "deep"
	},
	"H6684" : {
		"lemma" : "צוּם",
		"xlit" : "tsûwm",
		"pron" : "tsoom",
		"derivation" : "a primitive root",
		"def" : "to cover over (the mouth), i.e. to fast",
		"kjv" : "[idiom] at all, fast"
	},
	"H6685" : {
		"lemma" : "צוֹם",
		"xlit" : "tsôwm",
		"pron" : "tsome",
		"derivation" : "or צֹם; from H6684 (צוּם)",
		"def" : "a fast",
		"kjv" : "fast(-ing)"
	},
	"H6686" : {
		"lemma" : "צוּעָר",
		"xlit" : "Tsûwʻâr",
		"pron" : "tsoo-awr'",
		"derivation" : "from H6819 (צָעַר); small",
		"def" : "Tsuar, an Israelite",
		"kjv" : "Zuar"
	},
	"H6687" : {
		"lemma" : "צוּף",
		"xlit" : "tsûwph",
		"pron" : "tsoof",
		"derivation" : "a primitive root",
		"def" : "to overflow",
		"kjv" : "(make to over-) flow, swim"
	},
	"H6688" : {
		"lemma" : "צוּף",
		"xlit" : "tsûwph",
		"pron" : "tsoof",
		"derivation" : "from H6687 (צוּף)",
		"def" : "comb of honey (from dripping)",
		"kjv" : "honeycomb"
	},
	"H6689" : {
		"lemma" : "צוּף",
		"xlit" : "Tsûwph",
		"pron" : "tsoof",
		"derivation" : "or צוֹפַי; or צִיף; from H6688 (צוּף); honey-comb",
		"def" : "Tsuph or Tsophai or Tsiph, the name of an Israelite and of a place in Palestine",
		"kjv" : "Zophai, Zuph"
	},
	"H6690" : {
		"lemma" : "צוֹפַח",
		"xlit" : "Tsôwphach",
		"pron" : "tso-fakh'",
		"derivation" : "from an unused root meaning to expand, breadth",
		"def" : "Tsophach, an Israelite",
		"kjv" : "Zophah"
	},
	"H6691" : {
		"lemma" : "צוֹפַר",
		"xlit" : "Tsôwphar",
		"pron" : "tso-far'",
		"derivation" : "from H6852 (צָפַר); departing",
		"def" : "Tsophar, a friend of Job",
		"kjv" : "Zophar"
	},
	"H6692" : {
		"lemma" : "צוּץ",
		"xlit" : "tsûwts",
		"pron" : "tsoots",
		"derivation" : "a primitive root",
		"def" : "to twinkle, i.e. glance; by analogy, to blossom (figuratively, flourish)",
		"kjv" : "bloom, blossom, flourish, shew self"
	},
	"H6693" : {
		"lemma" : "צוּק",
		"xlit" : "tsûwq",
		"pron" : "tsook",
		"derivation" : "a primitive root",
		"def" : "to compress, i.e. (figuratively) oppress, distress",
		"kjv" : "constrain, distress, lie sore, (op-) press(-or), straiten"
	},
	"H6694" : {
		"lemma" : "צוּק",
		"xlit" : "tsûwq",
		"pron" : "tsook",
		"derivation" : "a primitive root (identical with through the idea of narrowness (of orifice))",
		"def" : "to pour out, i.e. (figuratively) smelt, utter",
		"kjv" : "be molten, pour"
	},
	"H6695" : {
		"lemma" : "צוֹק",
		"xlit" : "tsôwq",
		"pron" : "tsoke",
		"derivation" : "or (feminine) צוּקָה; from H6693 (צוּק)",
		"def" : "a strait, i.e. (figuratively) distress",
		"kjv" : "anguish, [idiom] troublous"
	},
	"H6696" : {
		"lemma" : "צוּר",
		"xlit" : "tsûwr",
		"pron" : "tsoor",
		"derivation" : "a primitive root",
		"def" : "to cramp, i.e. confine (in many applications, literally and figuratively, formative or hostile)",
		"kjv" : "adversary, assault, beset, besiege, bind (up), cast, distress, fashion, fortify, inclose, lay siege, put up in bags"
	},
	"H6697" : {
		"lemma" : "צוּר",
		"xlit" : "tsûwr",
		"pron" : "tsoor",
		"derivation" : "or צֻר; from H6696 (צוּר)",
		"def" : "properly, a cliff (or sharp rock, as compressed); generally, a rock or boulder; figuratively, a refuge; also an edge (as precipitous)",
		"kjv" : "edge, [idiom] (mighty) God (one), rock, [idiom] sharp, stone, [idiom] strength, [idiom] strong. See also H1049 (בֵּית צוּר)"
	},
	"H6698" : {
		"lemma" : "צוּר",
		"xlit" : "Tsûwr",
		"pron" : "tsoor",
		"derivation" : "the same as H6697 (צוּר); rock",
		"def" : "Tsur, the name of a Midianite and of an Israelite",
		"kjv" : "Zur"
	},
	"H6699" : {
		"lemma" : "צוּרָה",
		"xlit" : "tsûwrâh",
		"pron" : "tsoo-raw'",
		"derivation" : "feminine of H6697 (צוּר)",
		"def" : "a rock (Job 28:10); also a form (as if pressed out)",
		"kjv" : "form, rock"
	},
	"H6700" : {
		"lemma" : "צוּרִיאֵל",
		"xlit" : "Tsûwrîyʼêl",
		"pron" : "tsoo-ree-ale'",
		"derivation" : "from H6697 (צוּר) and H410 (אֵל); rock of God",
		"def" : "Tsuriel, an Israelite",
		"kjv" : "Zuriel"
	},
	"H6701" : {
		"lemma" : "צוּרִישַׁדַּי",
		"xlit" : "Tsûwrîyshadday",
		"pron" : "tsoo-ree-shad-dah'-ee",
		"derivation" : "from H6697 (צוּר) and H7706 (שַׁדַּי); rock of (the) Almighty",
		"def" : "Tsurishaddai, an Israelite",
		"kjv" : "Zurishaddai"
	},
	"H6702" : {
		"lemma" : "צוּת",
		"xlit" : "tsûwth",
		"pron" : "tsooth",
		"derivation" : "a primitive root",
		"def" : "to blaze",
		"kjv" : "burn"
	},
	"H6703" : {
		"lemma" : "צַח",
		"xlit" : "tsach",
		"pron" : "tsakh",
		"derivation" : "from H6705 (צָחַח)",
		"def" : "dazzling, i.e. sunny, bright, (figuratively) evident",
		"kjv" : "clear, dry, plainly, white"
	},
	"H6704" : {
		"lemma" : "צִחֶה",
		"xlit" : "tsicheh",
		"pron" : "tsee-kheh'",
		"derivation" : "from an unused root meaning to glow",
		"def" : "parched",
		"kjv" : "dried up"
	},
	"H6705" : {
		"lemma" : "צָחַח",
		"xlit" : "tsâchach",
		"pron" : "tsaw-khakh'",
		"derivation" : "a primitive root",
		"def" : "to glare, i.e. be dazzling white",
		"kjv" : "be whiter"
	},
	"H6706" : {
		"lemma" : "צְחִיחַ",
		"xlit" : "tsᵉchîyach",
		"pron" : "tsekh-ee'-akh",
		"derivation" : "from H6705 (צָחַח)",
		"def" : "glaring, i.e. exposed to the bright sun",
		"kjv" : "higher place, top"
	},
	"H6707" : {
		"lemma" : "צְחִיחָה",
		"xlit" : "tsᵉchîychâh",
		"pron" : "tsekh-ee-khaw'",
		"derivation" : "feminine of H6706 (צְחִיחַ)",
		"def" : "a parched region, i.e. the desert",
		"kjv" : "dry land"
	},
	"H6708" : {
		"lemma" : "צְחִיחִי",
		"xlit" : "tsᵉchîychîy",
		"pron" : "tsekh-ee-khee'",
		"derivation" : "from H6706 (צְחִיחַ)",
		"def" : "bare spot, i.e. in the glaring sun",
		"kjv" : "higher place"
	},
	"H6709" : {
		"lemma" : "צַחֲנָה",
		"xlit" : "tsachănâh",
		"pron" : "tsakh-an-aw'",
		"derivation" : "from an unused root meaning to putrefy",
		"def" : "stench",
		"kjv" : "ill savour"
	},
	"H6710" : {
		"lemma" : "צַחְצָחָה",
		"xlit" : "tsachtsâchâh",
		"pron" : "tsakh-tsaw-khaw'",
		"derivation" : "from H6705 (צָחַח)",
		"def" : "a dry place, i.e. desert",
		"kjv" : "drought"
	},
	"H6711" : {
		"lemma" : "צָחַק",
		"xlit" : "tsâchaq",
		"pron" : "tsaw-khak'",
		"derivation" : "a primitive root",
		"def" : "to laugh outright (in merriment or scorn); by implication, to sport",
		"kjv" : "laugh, mock, play, make sport"
	},
	"H6712" : {
		"lemma" : "צְחֹק",
		"xlit" : "tsᵉchôq",
		"pron" : "tsekh-oke'",
		"derivation" : "from H6711 (צָחַק)",
		"def" : "laughter (in pleasure or derision)",
		"kjv" : "laugh(-ed to scorn)"
	},
	"H6713" : {
		"lemma" : "צַחַר",
		"xlit" : "tsachar",
		"pron" : "tsakh'-ar",
		"derivation" : "from an unused root meaning to dazzle",
		"def" : "sheen, i.e. whiteness",
		"kjv" : "white"
	},
	"H6714" : {
		"lemma" : "צֹחַר",
		"xlit" : "Tsôchar",
		"pron" : "tso'-khar",
		"derivation" : "from the same as H6713 (צַחַר); whiteness",
		"def" : "Tsochar, the name of a Hittite and of an Israelite",
		"kjv" : "Zohar. Compare H3328 (יִצְחַר)"
	},
	"H6715" : {
		"lemma" : "צָחֹר",
		"xlit" : "tsâchôr",
		"pron" : "tsaw-khore'",
		"derivation" : "from the same as H6713 (צַחַר)",
		"def" : "white",
		"kjv" : "white"
	},
	"H6716" : {
		"lemma" : "צִי",
		"xlit" : "tsîy",
		"pron" : "tsee",
		"derivation" : "from H6680 (צָוָה)",
		"def" : "a ship (as a fixture)",
		"kjv" : "ship"
	},
	"H6717" : {
		"lemma" : "צִיבָא",
		"xlit" : "Tsîybâʼ",
		"pron" : "tsee-baw'",
		"derivation" : "from the same as H6678 (צוֹבָא); station",
		"def" : "Tsiba, an Israelite",
		"kjv" : "Ziba"
	},
	"H6718" : {
		"lemma" : "צַיִד",
		"xlit" : "tsayid",
		"pron" : "tsah'-yid",
		"derivation" : "from a form of H6679 (צוּד) and meaning the same",
		"def" : "the chase; also game (thus taken); (generally) lunch (especially for a journey)",
		"kjv" : "[idiom] catcheth, food, [idiom] hunter, (that which he took in) hunting, venison, victuals"
	},
	"H6719" : {
		"lemma" : "צַיָּד",
		"xlit" : "tsayâd",
		"pron" : "tsah'-yawd",
		"derivation" : "from the same as H6718 (צַיִד)",
		"def" : "a huntsman",
		"kjv" : "hunter"
	},
	"H6720" : {
		"lemma" : "צֵידָה",
		"xlit" : "tsêydâh",
		"pron" : "tsay-daw'",
		"derivation" : "or צֵדָה; feminine of H6718 (צַיִד)",
		"def" : "food",
		"kjv" : "meat, provision, venison, victuals"
	},
	"H6721" : {
		"lemma" : "צִידוֹן",
		"xlit" : "Tsîydôwn",
		"pron" : "tsee-done'",
		"derivation" : "or צִידֹן; from H6679 (צוּד) in the sense of catching fish; fishery",
		"def" : "Tsidon, the name of a son of Canaan, and of a place in Palestine",
		"kjv" : "Sidon, Zidon"
	},
	"H6722" : {
		"lemma" : "צִידֹנִי",
		"xlit" : "Tsîydônîy",
		"pron" : "tsee-do-nee'",
		"derivation" : "patrial from H6721 (צִידוֹן)",
		"def" : "a Tsidonian or inhabitant of Tsidon",
		"kjv" : "Sidonian, of Sidon, Zidonian"
	},
	"H6723" : {
		"lemma" : "צִיָּה",
		"xlit" : "tsîyâh",
		"pron" : "tsee-yaw'",
		"derivation" : "from an unused root meaning to parch",
		"def" : "aridity; concretely, a desert",
		"kjv" : "barren, drought, dry (land, place), solitary place, wilderness"
	},
	"H6724" : {
		"lemma" : "צִיוֹן",
		"xlit" : "tsîyôwn",
		"pron" : "tsee-yone'",
		"derivation" : "from the same as H6723 (צִיָּה)",
		"def" : "a desert",
		"kjv" : "dry place"
	},
	"H6725" : {
		"lemma" : "צִיּוּן",
		"xlit" : "tsîyûwn",
		"pron" : "tsee-yoon'",
		"derivation" : "from the same as H6723 (צִיָּה) in the sense of conspicuousness (compare H5329 (נָצַח))",
		"def" : "a monumental or guiding pillar",
		"kjv" : "sign, title, waymark"
	},
	"H6726" : {
		"lemma" : "צִיּוֹן",
		"xlit" : "Tsîyôwn",
		"pron" : "tsee-yone'",
		"derivation" : "the same (regularly) as H6725 (צִיּוּן)lemma צִיוּן missing dagesh, corrected to צִיּוּן",
		"def" : "Tsijon (as a permanent capital), a mountain of Jerusalem",
		"kjv" : "Zion"
	},
	"H6727" : {
		"lemma" : "צִיחָא",
		"xlit" : "Tsîychâʼ",
		"pron" : "tsee-khaw'",
		"derivation" : "or צִחָא; as if feminine of H6704 (צִחֶה); drought",
		"def" : "Tsicha, the name of two Nethinim",
		"kjv" : "Ziha"
	},
	"H6728" : {
		"lemma" : "צִיִּי",
		"xlit" : "tsîyîy",
		"pron" : "tsee-ee'",
		"derivation" : "from the same as H6723 (צִיָּה)",
		"def" : "a desert-dweller, i.e. nomad or wild beast",
		"kjv" : "wild beast of the desert, that dwell in (inhabiting) the wilderness"
	},
	"H6729" : {
		"lemma" : "צִינֹק",
		"xlit" : "tsîynôq",
		"pron" : "tsee-noke'",
		"derivation" : "from an unused root meaning to confine",
		"def" : "the pillory",
		"kjv" : "stocks"
	},
	"H6730" : {
		"lemma" : "צִיעֹר",
		"xlit" : "Tsîyʻôr",
		"pron" : "tsee-ore'",
		"derivation" : "from H6819 (צָעַר); small",
		"def" : "Tsior, a place in Palestine",
		"kjv" : "Zior"
	},
	"H6731" : {
		"lemma" : "צִיץ",
		"xlit" : "tsîyts",
		"pron" : "tseets",
		"derivation" : "or צִץ; from H6692 (צוּץ)",
		"def" : "properly, glistening, i.e. a burnished plate; also a flower (as bright-colored); a wing (as gleaming in the air)",
		"kjv" : "blossom, flower, plate, wing"
	},
	"H6732" : {
		"lemma" : "צִיץ",
		"xlit" : "Tsîyts",
		"pron" : "tseets",
		"derivation" : "the same as H6731 (צִיץ); bloom",
		"def" : "Tsits, a place in Palestine",
		"kjv" : "Ziz"
	},
	"H6733" : {
		"lemma" : "צִיצָה",
		"xlit" : "tsîytsâh",
		"pron" : "tsee-tsaw'",
		"derivation" : "feminine of H6731 (צִיץ)",
		"def" : "a flower",
		"kjv" : "flower"
	},
	"H6734" : {
		"lemma" : "צִיצִת",
		"xlit" : "tsîytsith",
		"pron" : "tsee-tseeth'",
		"derivation" : "feminine of H6731 (צִיץ)",
		"def" : "a floral or wing-like projection, i.e. a forelock of hair, a tassel",
		"kjv" : "fringe, lock"
	},
	"H6735" : {
		"lemma" : "צִיר",
		"xlit" : "tsîyr",
		"pron" : "tseer",
		"derivation" : "from H6696 (צוּר)",
		"def" : "a hinge (as pressed in turning); also a throe (as a phys. or mental pressure); also a herald or erranddoer (as constrained by the principal)",
		"kjv" : "ambassador, hinge, messenger, pain, pang, sorrow. Compare H6736 (צִיר)"
	},
	"H6736" : {
		"lemma" : "צִיר",
		"xlit" : "tsîyr",
		"pron" : "tseer",
		"derivation" : "the same as H6735 (צִיר)",
		"def" : "a form (of beauty; as if pressed out, i.e. carved); hence, an (idolatrous) image",
		"kjv" : "beauty, idol"
	},
	"H6737" : {
		"lemma" : "צָיַר",
		"xlit" : "tsâyar",
		"pron" : "tsaw-yar'",
		"derivation" : "a denominative from H6735 (צִיר) in the sense of ambassador",
		"def" : "to make an errand, i.e. betake oneself",
		"kjv" : "make as if...had been ambassador"
	},
	"H6738" : {
		"lemma" : "צֵל",
		"xlit" : "tsêl",
		"pron" : "tsale",
		"derivation" : "from H6751 (צָלַל)",
		"def" : "shade, whether literal or figurative",
		"kjv" : "defence, shade(-ow)"
	},
	"H6739" : {
		"lemma" : "צְלָא",
		"xlit" : "tsᵉlâʼ",
		"pron" : "tsel-aw'",
		"derivation" : "(Aramaic) probably corresponding to H6760 (צָלַע) in the sense of bowing",
		"def" : "pray",
		"kjv" : "pray"
	},
	"H6740" : {
		"lemma" : "צָלָה",
		"xlit" : "tsâlâh",
		"pron" : "tsaw-law'",
		"derivation" : "a primitive root",
		"def" : "to roast",
		"kjv" : "roast"
	},
	"H6741" : {
		"lemma" : "צִלָּה",
		"xlit" : "Tsillâh",
		"pron" : "tsil-law'",
		"derivation" : "feminine of H6738 (צֵל)",
		"def" : "Tsillah, an antediluvian woman",
		"kjv" : "Zillah"
	},
	"H6742" : {
		"lemma" : "צְלוּל",
		"xlit" : "tsᵉlûwl",
		"pron" : "tsel-ool'",
		"derivation" : "from H6749 (צָלַל) in the sense of rolling",
		"def" : "a (round or flattened) cake",
		"kjv" : "cake"
	},
	"H6743" : {
		"lemma" : "צָלַח",
		"xlit" : "tsâlach",
		"pron" : "tsaw-lakh'",
		"derivation" : "or צָלֵחַ; a primitive root",
		"def" : "to push forward, in various senses (literal or figurative, transitive or intransitive)",
		"kjv" : "break out, come (mightily), go over, be good, be meet, be profitable, (cause to, effect, make to, send) prosper(-ity, -ous, -ously)"
	},
	"H6744" : {
		"lemma" : "צְלַח",
		"xlit" : "tsᵉlach",
		"pron" : "tsel-akh'",
		"derivation" : "(Aramaic) corresponding to H6743 (צָלַח)",
		"def" : "to advance (transitive or intransitive)",
		"kjv" : "promote, prosper"
	},
	"H6745" : {
		"lemma" : "צֵלָחָה",
		"xlit" : "tsêlâchâh",
		"pron" : "tsay-law-khaw'",
		"derivation" : "from H6743 (צָלַח)",
		"def" : "something protracted or flattened out, i.e. a platter",
		"kjv" : "pan"
	},
	"H6746" : {
		"lemma" : "צְלֹחִית",
		"xlit" : "tsᵉlôchîyth",
		"pron" : "tsel-o-kheeth'",
		"derivation" : "from H6743 (צָלַח)",
		"def" : "something prolonged or tall, i.e. a vial or salt-cellar",
		"kjv" : "cruse"
	},
	"H6747" : {
		"lemma" : "צַלַּחַת",
		"xlit" : "tsallachath",
		"pron" : "tsal-lakh'-ath",
		"derivation" : "from H6743 (צָלַח)",
		"def" : "something advanced or deep, i.e. a bowl; figuratively, the bosom",
		"kjv" : "bosom, dish"
	},
	"H6748" : {
		"lemma" : "צָלִי",
		"xlit" : "tsâlîy",
		"pron" : "tsaw-lee'",
		"derivation" : "passive participle of H6740 (צָלָה)",
		"def" : "roasted",
		"kjv" : "roast"
	},
	"H6749" : {
		"lemma" : "צָלַל",
		"xlit" : "tsâlal",
		"pron" : "tsaw-lal'",
		"derivation" : "a primitive root",
		"def" : "properly, to tumble down, i.e. settle by awaving motion",
		"kjv" : "sink. Compare H6750 (צָלַל), H6751 (צָלַל)"
	},
	"H6750" : {
		"lemma" : "צָלַל",
		"xlit" : "tsâlal",
		"pron" : "tsaw-lal'",
		"derivation" : "a primitive root (identical with through the idea of vibration)",
		"def" : "to tinkle, i.e. rattle together (as the ears in reddening with shame, or the teeth in chattering with fear)",
		"kjv" : "quiver, tingle"
	},
	"H6751" : {
		"lemma" : "צָלַל",
		"xlit" : "tsâlal",
		"pron" : "tsaw-lal'",
		"derivation" : "a primitive root (identical with through the idea of hovering over (compare H6754 (צֶלֶם)))",
		"def" : "to shade, as twilight or an opaque object",
		"kjv" : "begin to be dark, shadowing"
	},
	"H6752" : {
		"lemma" : "צֵלֶל",
		"xlit" : "tsêlel",
		"pron" : "tsay'-lel",
		"derivation" : "from H6751 (צָלַל)",
		"def" : "shade",
		"kjv" : "shadow"
	},
	"H6753" : {
		"lemma" : "צְלֶלְפּוֹנִי",
		"xlit" : "Tsᵉlelpôwnîy",
		"pron" : "tsel-el-po-nee'",
		"derivation" : "from H6752 (צֵלֶל) and the active participle of H6437 (פָּנָה); shade-facing",
		"def" : "Tselelponi, an Israelitess",
		"kjv" : "Hazelelponi (including the article)"
	},
	"H6754" : {
		"lemma" : "צֶלֶם",
		"xlit" : "tselem",
		"pron" : "tseh'-lem",
		"derivation" : "from an unused root meaning to shade",
		"def" : "a phantom, i.e. (figuratively) illusion, resemblance; hence, a representative figure, especially an idol",
		"kjv" : "image, vain shew"
	},
	"H6755" : {
		"lemma" : "צֶלֶם",
		"xlit" : "tselem",
		"pron" : "tseh'-lem",
		"derivation" : "(Aramaic) or צְלֶם; (Aramaic), corresponding to H6754 (צֶלֶם)",
		"def" : "an idolatrous figure",
		"kjv" : "form, image"
	},
	"H6756" : {
		"lemma" : "צַלְמוֹן",
		"xlit" : "Tsalmôwn",
		"pron" : "tsal-mone'",
		"derivation" : "from H6754 (צֶלֶם); shady",
		"def" : "Tsalmon, the name of a place in Palestine and of an Israelite",
		"kjv" : "Zalmon"
	},
	"H6757" : {
		"lemma" : "צַלְמָוֶת",
		"xlit" : "tsalmâveth",
		"pron" : "tsal-maw'-veth",
		"derivation" : "from H6738 (צֵל) and H4194 (מָוֶת)",
		"def" : "shade of death, i.e. the grave (figuratively, calamity)",
		"kjv" : "shadow of death"
	},
	"H6758" : {
		"lemma" : "צַלְמֹנָה",
		"xlit" : "Tsalmônâh",
		"pron" : "tsal-mo-naw'",
		"derivation" : "feminine of H6757 (צַלְמָוֶת); shadiness",
		"def" : "Tsalmonah, a place in the Desert",
		"kjv" : "Zalmonah"
	},
	"H6759" : {
		"lemma" : "צַלְמֻנָּע",
		"xlit" : "Tsalmunnâʻ",
		"pron" : "tsal-moon-naw'",
		"derivation" : "from H6738 (צֵל) and H4513 (מָנַע); shade has been denied",
		"def" : "Tsalmunna, a Midianite",
		"kjv" : "Zalmunna"
	},
	"H6760" : {
		"lemma" : "צָלַע",
		"xlit" : "tsâlaʻ",
		"pron" : "tsaw-lah'",
		"derivation" : "a primitive root; probably to curve; used only as denominative from H6763 (צֵלָע)",
		"def" : "to limp (as if one-sided)",
		"kjv" : "halt"
	},
	"H6761" : {
		"lemma" : "צֶלַע",
		"xlit" : "tselaʻ",
		"pron" : "tseh'-lah",
		"derivation" : "from H6760 (צָלַע)",
		"def" : "a limping or full (figuratively)",
		"kjv" : "adversity, halt(-ing)"
	},
	"H6762" : {
		"lemma" : "צֶלַע",
		"xlit" : "Tselaʻ",
		"pron" : "tseh'-lah",
		"derivation" : "the same as H6761 (צֶלַע)",
		"def" : "Tsela, a place in Palestine",
		"kjv" : "Zelah"
	},
	"H6763" : {
		"lemma" : "צֵלָע",
		"xlit" : "tsêlâʻ",
		"pron" : "tsay-law'",
		"derivation" : "or (feminine) צַלְעָה; from H6760 (צָלַע)",
		"def" : "a rib (as curved), literally (of the body) or figuratively (of a door, i.e. leaf); hence, a side, literally (of a person) or figuratively (of an object or the sky, i.e. quarter); architecturally, a (especially floor or ceiling) timber or plank (single or collective, i.e. a flooring)",
		"kjv" : "beam, board, chamber, corner, leaf, plank, rib, side (chamber)"
	},
	"H6764" : {
		"lemma" : "צָלָף",
		"xlit" : "Tsâlâph",
		"pron" : "tsaw-lawf'",
		"derivation" : "from an unused root of unknown meaning",
		"def" : "Tsalaph, an Israelite",
		"kjv" : "Zalaph"
	},
	"H6765" : {
		"lemma" : "צְלׇפְחָד",
		"xlit" : "Tsᵉlophchâd",
		"pron" : "tsel-of-chawd'",
		"derivation" : "from the same as H6764 (צָלָף) and H259 (אֶחָד)",
		"def" : "Tselophchad, an Israelite",
		"kjv" : "Zelophehad"
	},
	"H6766" : {
		"lemma" : "צֶלְצַח",
		"xlit" : "Tseltsach",
		"pron" : "tsel-tsakh'",
		"derivation" : "from H6738 (צֵל) and H6703 (צַח); clear shade",
		"def" : "Tseltsach, a place in Palestine",
		"kjv" : "Zelzah"
	},
	"H6767" : {
		"lemma" : "צְלָצַל",
		"xlit" : "tsᵉlâtsal",
		"pron" : "tsel-aw-tsal'",
		"derivation" : "from H6750 (צָלַל) reduplicated",
		"def" : "a clatter, i.e. (abstractly) whirring (of wings); (concretely) a cricket; also a harpoon (as rattling), a cymbal (as clanging)",
		"kjv" : "cymbal, locust, shadowing, spear"
	},
	"H6768" : {
		"lemma" : "צֶלֶק",
		"xlit" : "Tseleq",
		"pron" : "tseh'-lek",
		"derivation" : "from an unused root meaning to split; fissure",
		"def" : "Tselek, an Israelite",
		"kjv" : "Zelek"
	},
	"H6769" : {
		"lemma" : "צִלְּתַי",
		"xlit" : "Tsillᵉthay",
		"pron" : "tsil-leth-ah'-ee",
		"derivation" : "from the feminine of H6738 (צֵל); shady",
		"def" : "Tsillethai, the name of two Israelites",
		"kjv" : "Zilthai"
	},
	"H6770" : {
		"lemma" : "צָמֵא",
		"xlit" : "tsâmêʼ",
		"pron" : "tsaw-may'",
		"derivation" : "a primitive root",
		"def" : "to thirst (literally or figuratively)",
		"kjv" : "(be a-, suffer) thirst(-y)"
	},
	"H6771" : {
		"lemma" : "צָמֵא",
		"xlit" : "tsâmêʼ",
		"pron" : "tsaw-may'",
		"derivation" : "from H6770 (צָמֵא)",
		"def" : "thirsty (literally or figuratively)",
		"kjv" : "(that) thirst(-eth, -y)"
	},
	"H6772" : {
		"lemma" : "צָמָא",
		"xlit" : "tsâmâʼ",
		"pron" : "tsaw-maw'",
		"derivation" : "from H6770 (צָמֵא)",
		"def" : "thirst (literally or figuratively)",
		"kjv" : "thirst(-y)"
	},
	"H6773" : {
		"lemma" : "צִמְאָה",
		"xlit" : "tsimʼâh",
		"pron" : "tsim-aw'",
		"derivation" : "feminine of H6772 (צָמָא)",
		"def" : "thirst (figuratively, of libidinousnes)",
		"kjv" : "thirst"
	},
	"H6774" : {
		"lemma" : "צִמָּאוֹן",
		"xlit" : "tsimmâʼôwn",
		"pron" : "tsim-maw-one'",
		"derivation" : "from H6771 (צָמֵא)",
		"def" : "a thirsty place, i.e. desert",
		"kjv" : "drought, dry ground, thirsty land"
	},
	"H6775" : {
		"lemma" : "צָמַד",
		"xlit" : "tsâmad",
		"pron" : "tsaw-mad'",
		"derivation" : "a primitive root",
		"def" : "to link, i.e. gird; figuratively, to serve, (mentally) contrive",
		"kjv" : "fasten, frame, join (self)"
	},
	"H6776" : {
		"lemma" : "צֶמֶד",
		"xlit" : "tsemed",
		"pron" : "tseh'-med",
		"derivation" : "a yoke or team (i.e. pair)",
		"def" : "hence, an acre (i.e. day's task for a yoke of cattle to plough)",
		"kjv" : "acre, couple, [idiom] together, two (donkeys), yoke (of oxen)"
	},
	"H6777" : {
		"lemma" : "צַמָּה",
		"xlit" : "tsammâh",
		"pron" : "tsam-maw'",
		"derivation" : "from an unused root meaning to fasten on",
		"def" : "a veil",
		"kjv" : "locks"
	},
	"H6778" : {
		"lemma" : "צַמּוּק",
		"xlit" : "tsammûwq",
		"pron" : "tsam-mook'",
		"derivation" : "from H6784 (צָמַק)",
		"def" : "a cake of dried grapes",
		"kjv" : "bunch (cluster) of raisins"
	},
	"H6779" : {
		"lemma" : "צָמַח",
		"xlit" : "tsâmach",
		"pron" : "tsaw-makh'",
		"derivation" : "a primitive root",
		"def" : "to sprout (transitive or intransitive, literal or figurative)",
		"kjv" : "bear, bring forth, (cause to, make to) bud (forth), (cause to, make to) grow (again, up), (cause to) spring (forth, up)"
	},
	"H6780" : {
		"lemma" : "צֶמַח",
		"xlit" : "tsemach",
		"pron" : "tseh'-makh",
		"derivation" : "from H6779 (צָמַח)",
		"def" : "a sprout (usually concrete), literal or figurative",
		"kjv" : "branch, bud, that which (where) grew (upon), spring(-ing)"
	},
	"H6781" : {
		"lemma" : "צָמִיד",
		"xlit" : "tsâmîyd",
		"pron" : "tsaw-meed'",
		"derivation" : "or צָמִד; from H6775 (צָמַד)",
		"def" : "a bracelet or arm-clasp; generally, a lid",
		"kjv" : "bracelet, covering"
	},
	"H6782" : {
		"lemma" : "צַמִּים",
		"xlit" : "tsammîym",
		"pron" : "tsam-meem'",
		"derivation" : "from the same as H6777 (צַמָּה)",
		"def" : "a noose (as fastening); figuratively, destruction",
		"kjv" : "robber"
	},
	"H6783" : {
		"lemma" : "צְמִיתֻת",
		"xlit" : "tsᵉmîythuth",
		"pron" : "tsem-ee-thooth'",
		"derivation" : "or צְמִתֻת; from H6789 (צָמַת)",
		"def" : "excision, i.e. destruction; used only (adverbially) with prepositional prefix to extinction, i.e. perpetually",
		"kjv" : "ever"
	},
	"H6784" : {
		"lemma" : "צָמַק",
		"xlit" : "tsâmaq",
		"pron" : "tsaw-mak'",
		"derivation" : "a primitive root",
		"def" : "to dry up",
		"kjv" : "dry"
	},
	"H6785" : {
		"lemma" : "צֶמֶר",
		"xlit" : "tsemer",
		"pron" : "tseh'-mer",
		"derivation" : "from an unused root probably meaning to be shaggy",
		"def" : "wool",
		"kjv" : "wool(-len)"
	},
	"H6786" : {
		"lemma" : "צְמָרִי",
		"xlit" : "Tsᵉmârîy",
		"pron" : "tsem-aw-ree'",
		"derivation" : "patrial from an unused name of a place in Palestine",
		"def" : "a Tsemarite or branch of the Canaanites",
		"kjv" : "Zemarite"
	},
	"H6787" : {
		"lemma" : "צְמָרַיִם",
		"xlit" : "Tsᵉmârayim",
		"pron" : "tsem-aw-rah'-yim",
		"derivation" : "dual of H6785 (צֶמֶר); double fleece",
		"def" : "Tsemarajim, a place in Palestine",
		"kjv" : "Zemaraim"
	},
	"H6788" : {
		"lemma" : "צַמֶּרֶת",
		"xlit" : "tsammereth",
		"pron" : "tsam-meh'-reth",
		"derivation" : "from the same as H6785 (צֶמֶר)",
		"def" : "fleeciness, i.e. foliage",
		"kjv" : "highest branch, top"
	},
	"H6789" : {
		"lemma" : "צָמַת",
		"xlit" : "tsâmath",
		"pron" : "tsaw-math'",
		"derivation" : "a primitive root",
		"def" : "to extirpate (literally or figuratively)",
		"kjv" : "consume, cut off, destroy, vanish"
	},
	"H6790" : {
		"lemma" : "צִן",
		"xlit" : "Tsin",
		"pron" : "tseen",
		"derivation" : "from an unused root meaning to prick; a crag",
		"def" : "Tsin, a part of the Desert",
		"kjv" : "Zin"
	},
	"H6791" : {
		"lemma" : "צֵן",
		"xlit" : "tsên",
		"pron" : "tsane",
		"derivation" : "from an unused root meaning to be prickly",
		"def" : "a thorn; hence, a cactus-hedge",
		"kjv" : "thorn"
	},
	"H6792" : {
		"lemma" : "צֹנֵא",
		"xlit" : "tsônêʼ",
		"pron" : "tso-nay'",
		"derivation" : "or צֹנֶה; for H6629 (צֹאן)",
		"def" : "a flock",
		"kjv" : "sheep"
	},
	"H6793" : {
		"lemma" : "צִנָּה",
		"xlit" : "tsinnâh",
		"pron" : "tsin-naw'",
		"derivation" : "feminine of H6791 (צֵן)",
		"def" : "a hook (as pointed); also a (large) shield (as if guarding by prickliness); also cold (as piercing)",
		"kjv" : "buckler, cold, hook, shield, target"
	},
	"H6794" : {
		"lemma" : "צִנּוּר",
		"xlit" : "tsinnûwr",
		"pron" : "tsin-noor'",
		"derivation" : "from an unused root perhaps meaning to be hollow",
		"def" : "a culvert",
		"kjv" : "gutter, water-spout"
	},
	"H6795" : {
		"lemma" : "צָנַח",
		"xlit" : "tsânach",
		"pron" : "tsaw-nakh'",
		"derivation" : "a primitive root",
		"def" : "to alight; (transitive) to cause to descend, i.e. drive down",
		"kjv" : "fasten, light (from off)"
	},
	"H6796" : {
		"lemma" : "צָנִין",
		"xlit" : "tsânîyn",
		"pron" : "tsaw-neen'",
		"derivation" : "or צָנִן; from the same as H6791 (צֵן)",
		"def" : "a thorn",
		"kjv" : "thorn"
	},
	"H6797" : {
		"lemma" : "צָנִיף",
		"xlit" : "tsânîyph",
		"pron" : "tsaw-neef'",
		"derivation" : "or צָנוֹף; or (feminine) צָנִיפָה; from H6801 (צָנַף)",
		"def" : "a head-dress (i.e. piece of cloth wrapped around)",
		"kjv" : "diadem, hood, mitre"
	},
	"H6798" : {
		"lemma" : "צָנַם",
		"xlit" : "tsânam",
		"pron" : "tsaw-nam'",
		"derivation" : "a primitive root",
		"def" : "to blast or shrink",
		"kjv" : "withered"
	},
	"H6799" : {
		"lemma" : "צְנָן",
		"xlit" : "Tsᵉnân",
		"pron" : "tsen-awn'",
		"derivation" : "probably for H6630 (צַאֲנָן)",
		"def" : "Tsenan, a place near Palestine",
		"kjv" : "Zenan"
	},
	"H6800" : {
		"lemma" : "צָנַע",
		"xlit" : "tsânaʻ",
		"pron" : "tsaw-nah'",
		"derivation" : "a primitive root",
		"def" : "to humiliate",
		"kjv" : "humbly, lowly"
	},
	"H6801" : {
		"lemma" : "צָנַף",
		"xlit" : "tsânaph",
		"pron" : "tsaw-naf'",
		"derivation" : "a primitive root",
		"def" : "to wrap, i.e. roll or dress",
		"kjv" : "be attired, [idiom] surely, violently turn"
	},
	"H6802" : {
		"lemma" : "צְנֵפָה",
		"xlit" : "tsᵉnêphâh",
		"pron" : "tsen-ay-faw'",
		"derivation" : "from H6801 (צָנַף)",
		"def" : "a ball",
		"kjv" : "[idiom] toss"
	},
	"H6803" : {
		"lemma" : "צִנְצֶנֶת",
		"xlit" : "tsintseneth",
		"pron" : "tsin-tseh'-neth",
		"derivation" : "from the same as H6791 (צֵן)",
		"def" : "a vase (probably a vial tapering at the top)",
		"kjv" : "pot"
	},
	"H6804" : {
		"lemma" : "צַנְתָּרָה",
		"xlit" : "tsantârâh",
		"pron" : "tsan-taw-raw'",
		"derivation" : "probably from the same as H6794 (צִנּוּר)",
		"def" : "a tube",
		"kjv" : "pipe"
	},
	"H6805" : {
		"lemma" : "צָעַד",
		"xlit" : "tsâʻad",
		"pron" : "tsaw-ad'",
		"derivation" : "a primitive root",
		"def" : "to pace, i.e. step regularly; (upward) to mount; (along) to march; (down and causatively) to hurl",
		"kjv" : "bring, go, march (through), run over"
	},
	"H6806" : {
		"lemma" : "צַעַד",
		"xlit" : "tsaʻad",
		"pron" : "tsah'-ad",
		"derivation" : "from H6804 (צַנְתָּרָה)",
		"def" : "a pace or regular step",
		"kjv" : "pace, step"
	},
	"H6807" : {
		"lemma" : "צְעָדָה",
		"xlit" : "tsᵉʻâdâh",
		"pron" : "tseh-aw-daw'",
		"derivation" : "feminine of H6806 (צַעַד)",
		"def" : "a march; (concretely) an (ornamental) ankle-chain",
		"kjv" : "going, ornament of the legs"
	},
	"H6808" : {
		"lemma" : "צָעָה",
		"xlit" : "tsâʻâh",
		"pron" : "tsaw-aw'",
		"derivation" : "a primitive root",
		"def" : "to tip over (for the purpose of spilling or pouring out), i.e. (figuratively) depopulate; by implication, to imprison or conquer; (reflexive) to lie down (for coitus)",
		"kjv" : "captive exile, travelling, (cause to) wander(-er)"
	},
	"H6809" : {
		"lemma" : "צָעִיף",
		"xlit" : "tsâʻîyph",
		"pron" : "tsaw-eef'",
		"derivation" : "from an unused root meaning to wrap over",
		"def" : "a veil",
		"kjv" : "vail"
	},
	"H6810" : {
		"lemma" : "צָעִיר",
		"xlit" : "tsâʻîyr",
		"pron" : "tsaw-eer'",
		"derivation" : "or צָעוֹר; from H6819 (צָעַר)",
		"def" : "little; (in number) few; (in age) young, (in value) ignoble",
		"kjv" : "least, little (one), small (one), [phrase] young(-er, -est)"
	},
	"H6811" : {
		"lemma" : "צָעִיר",
		"xlit" : "Tsâʻîyr",
		"pron" : "tsaw-eer'",
		"derivation" : "the same as H6810 (צָעִיר)",
		"def" : "Tsair, a place in Idumaea",
		"kjv" : "Zair"
	},
	"H6812" : {
		"lemma" : "צְעִירָה",
		"xlit" : "tsᵉʻîyrâh",
		"pron" : "tseh-ee-raw'",
		"derivation" : "feminine of H6810 (צָעִיר)",
		"def" : "smallness (of age), i.e. juvenility",
		"kjv" : "youth"
	},
	"H6813" : {
		"lemma" : "צָעַן",
		"xlit" : "tsâʻan",
		"pron" : "tsaw-an'",
		"derivation" : "a primitive root",
		"def" : "to load up (beasts), i.e. to migrate",
		"kjv" : "be taken down"
	},
	"H6814" : {
		"lemma" : "צֹעַן",
		"xlit" : "Tsôʻan",
		"pron" : "tso'-an",
		"derivation" : "of Egyptian derivation",
		"def" : "Tsoan, a place in Egypt",
		"kjv" : "Zoan"
	},
	"H6815" : {
		"lemma" : "צַעֲנַנִּים",
		"xlit" : "Tsaʻănannîym",
		"pron" : "tsah-an-an-neem'",
		"derivation" : "or (dual) צַעֲנַיִם; plural from H6813 (צָעַן); removals",
		"def" : "Tsaanannim or Tsaanajim, a place in Palestine",
		"kjv" : "Zaannannim, Zaanaim"
	},
	"H6816" : {
		"lemma" : "צַעְצֻעַ",
		"xlit" : "tsaʻtsuaʻ",
		"pron" : "tsah-tsoo'-ah",
		"derivation" : "from an unused root meaning to bestrew with carvings",
		"def" : "sculpture",
		"kjv" : "image (work)"
	},
	"H6817" : {
		"lemma" : "צָעַק",
		"xlit" : "tsâʻaq",
		"pron" : "tsaw-ak'",
		"derivation" : "a primitive root",
		"def" : "to shriek; (by implication) to proclaim (an assembly)",
		"kjv" : "[idiom] at all, call together, cry (out), gather (selves) (together)"
	},
	"H6818" : {
		"lemma" : "צַעֲקָה",
		"xlit" : "tsaʻăqâh",
		"pron" : "tsah-ak-aw'",
		"derivation" : "from H6817 (צָעַק)",
		"def" : "a shriek",
		"kjv" : "cry(-ing)"
	},
	"H6819" : {
		"lemma" : "צָעַר",
		"xlit" : "tsâʻar",
		"pron" : "tsaw-ar'",
		"derivation" : "a primitive root",
		"def" : "to be small, i.e. (figuratively) ignoble",
		"kjv" : "be brought low, little one, be small"
	},
	"H6820" : {
		"lemma" : "צֹעַר",
		"xlit" : "Tsôʻar",
		"pron" : "tso'ar",
		"derivation" : "from H6819 (צָעַר); little",
		"def" : "Tsoar, a place East of the Jordan",
		"kjv" : "Zoar"
	},
	"H6821" : {
		"lemma" : "צָפַד",
		"xlit" : "tsâphad",
		"pron" : "tsaw-fad'",
		"derivation" : "a primitive root",
		"def" : "to adhere",
		"kjv" : "cleave"
	},
	"H6822" : {
		"lemma" : "צָפָה",
		"xlit" : "tsâphâh",
		"pron" : "tsaw-faw'",
		"derivation" : "a primitive root",
		"def" : "properly, to lean forward, i.e. to peer into the distance; by implication, to observe, await",
		"kjv" : "behold, espy, look up (well), wait for, (keep the) watch(-man)"
	},
	"H6823" : {
		"lemma" : "צָפָה",
		"xlit" : "tsâphâh",
		"pron" : "tsaw-faw'",
		"derivation" : "a primitive root (probably identical with H6822 (צָפָה) through the idea of expansion in outlook, transferring to action)",
		"def" : "to sheet over (especially with metal)",
		"kjv" : "cover, overlay"
	},
	"H6824" : {
		"lemma" : "צָפָה",
		"xlit" : "tsâphâh",
		"pron" : "tsaw-faw'",
		"derivation" : "from H6823 (צָפָה)",
		"def" : "an inundation (as covering)",
		"kjv" : "[idiom] swimmest"
	},
	"H6825" : {
		"lemma" : "צְפוֹ",
		"xlit" : "Tsᵉphôw",
		"pron" : "tsef-o'",
		"derivation" : "or צְפִי; from H6822 (צָפָה); observant",
		"def" : "Tsepho or Tsephi, an Idumaean",
		"kjv" : "Zephi, Zepho"
	},
	"H6826" : {
		"lemma" : "צִפּוּי",
		"xlit" : "tsippûwy",
		"pron" : "tsip-poo'-ee",
		"derivation" : "from H6823 (צָפָה)",
		"def" : "encasement (with metal)",
		"kjv" : "covering, overlaying"
	},
	"H6827" : {
		"lemma" : "צְפוֹן",
		"xlit" : "Tsᵉphôwn",
		"pron" : "tsef-one'",
		"derivation" : "probably for H6837 (צִפְיוֹן)",
		"def" : "Tsephon, an Israelite",
		"kjv" : "Zephon"
	},
	"H6828" : {
		"lemma" : "צָפוֹן",
		"xlit" : "tsâphôwn",
		"pron" : "tsaw-fone'",
		"derivation" : "or צָפֹן; from H6845 (צָפַן)",
		"def" : "properly, hidden, i.e. dark; used only of the north as aquarter (gloomy and unknown)",
		"kjv" : "north(-ern, side, -ward, wind)"
	},
	"H6829" : {
		"lemma" : "צָפוֹן",
		"xlit" : "Tsâphôwn",
		"pron" : "tsaw-fone'",
		"derivation" : "the same as H6828 (צָפוֹן); boreal",
		"def" : "Tsaphon, a place in Palestine",
		"kjv" : "Zaphon"
	},
	"H6830" : {
		"lemma" : "צְפוֹנִי",
		"xlit" : "tsᵉphôwnîy",
		"pron" : "tsef-o-nee'",
		"derivation" : "from H6828 (צָפוֹן)",
		"def" : "northern",
		"kjv" : "northern"
	},
	"H6831" : {
		"lemma" : "צְפוֹנִי",
		"xlit" : "Tsᵉphôwnîy",
		"pron" : "tsef-o-nee'",
		"derivation" : "patronymically from H6827 (צְפוֹן)",
		"def" : "a Tsephonite, or (collectively) descendants of Tsephon",
		"kjv" : "Zephonites"
	},
	"H6832" : {
		"lemma" : "צְפוּעַ",
		"xlit" : "tsᵉphûwaʻ",
		"pron" : "tsef-oo'-ah",
		"derivation" : "from the same as H6848 (צֶפַע)",
		"def" : "excrement (as protruded)",
		"kjv" : "dung"
	},
	"H6833" : {
		"lemma" : "צִפּוֹר",
		"xlit" : "tsippôwr",
		"pron" : "tsip-pore'",
		"derivation" : "or צִפֹּר; from H6852 (צָפַר)",
		"def" : "a little bird (as hopping)",
		"kjv" : "bird, fowl, sparrow"
	},
	"H6834" : {
		"lemma" : "צִפּוֹר",
		"xlit" : "Tsippôwr",
		"pron" : "tsip-pore'",
		"derivation" : "the same as H6833 (צִפּוֹר)",
		"def" : "Tsippor, a Moabite",
		"kjv" : "Zippor"
	},
	"H6835" : {
		"lemma" : "צַפַּחַת",
		"xlit" : "tsappachath",
		"pron" : "tsap-pakh'-ath",
		"derivation" : "from an unused root meaning to expand",
		"def" : "a saucer (as flat)",
		"kjv" : "cruse"
	},
	"H6836" : {
		"lemma" : "צְפִיָּה",
		"xlit" : "tsᵉphîyâh",
		"pron" : "tsef-ee-yaw'",
		"derivation" : "from H6822 (צָפָה)",
		"def" : "watchfulness",
		"kjv" : "watching"
	},
	"H6837" : {
		"lemma" : "צִפְיוֹן",
		"xlit" : "Tsiphyôwn",
		"pron" : "tsif-yone'",
		"derivation" : "from H6822 (צָפָה); watch-tower",
		"def" : "Tsiphjon, an Israelite",
		"kjv" : "Ziphion. Compare H6827 (צְפוֹן)"
	},
	"H6838" : {
		"lemma" : "צַפִּיחִת",
		"xlit" : "tsappîychith",
		"pron" : "tsap-pee-kheeth'",
		"derivation" : "from the same as H6835 (צַפַּחַת)",
		"def" : "a flat thin cake",
		"kjv" : "wafer"
	},
	"H6839" : {
		"lemma" : "צֹפִים",
		"xlit" : "Tsôphîym",
		"pron" : "tso-feem'",
		"derivation" : "plural of active participle of H6822 (צָפָה); watchers",
		"def" : "Tsophim, a place East of the Jordan",
		"kjv" : "Zophim"
	},
	"H6840" : {
		"lemma" : "צָפִין",
		"xlit" : "tsâphîyn",
		"pron" : "tsaw-feen'",
		"derivation" : "from H6845 (צָפַן)",
		"def" : "a treasure (as hidden)",
		"kjv" : "hid"
	},
	"H6841" : {
		"lemma" : "צְפִיר",
		"xlit" : "tsᵉphîyr",
		"pron" : "tsef-eer'",
		"derivation" : "(Aramaic) corresponding to H6842 (צָפִיר)",
		"def" : "a he-goat",
		"kjv" : "he (goat)"
	},
	"H6842" : {
		"lemma" : "צָפִיר",
		"xlit" : "tsâphîyr",
		"pron" : "tsaw-feer'",
		"derivation" : "from H6852 (צָפַר)",
		"def" : "a male goat (as prancing)",
		"kjv" : "(he) goat"
	},
	"H6843" : {
		"lemma" : "צְפִירָה",
		"xlit" : "tsᵉphîyrâh",
		"pron" : "tsef-ee-raw'",
		"derivation" : "feminine formed like H6842 (צָפִיר)",
		"def" : "a crown (as encircling the head); also a turn of affairs (i.e. mishap)",
		"kjv" : "diadem, morning"
	},
	"H6844" : {
		"lemma" : "צָפִית",
		"xlit" : "tsâphîyth",
		"pron" : "tsaw-feeth'",
		"derivation" : "from H6822 (צָפָה)",
		"def" : "a sentry",
		"kjv" : "watchtower"
	},
	"H6845" : {
		"lemma" : "צָפַן",
		"xlit" : "tsâphan",
		"pron" : "tsaw-fan'",
		"derivation" : "a primitive root",
		"def" : "to hide (by covering over); by implication, to hoard or reserve; figuratively to deny; specifically (favorably) to protect, (unfavorably) to lurk",
		"kjv" : "esteem, hide(-den one, self), lay up, lurk (be set) privily, (keep) secret(-ly, place)"
	},
	"H6846" : {
		"lemma" : "צְפַנְיָה",
		"xlit" : "Tsᵉphanyâh",
		"pron" : "tsef-an-yaw'",
		"derivation" : "or צְפַנְיָהוּ; from H6845 (צָפַן) and H3050 (יָהּ); Jah has secreted",
		"def" : "Tsephanjah, the name of four Israelites",
		"kjv" : "Zephaniah"
	},
	"H6847" : {
		"lemma" : "צׇפְנַת פַּעְנֵחַ",
		"xlit" : "Tsophnath Paʻnêach",
		"pron" : "tsof-nath' pah-nay'-akh",
		"derivation" : "of Egyptian derivation",
		"def" : "Tsophnath-Paneach, Joseph's Egyptian name",
		"kjv" : "Zaphnath-paaneah"
	},
	"H6848" : {
		"lemma" : "צֶפַע",
		"xlit" : "tsephaʻ",
		"pron" : "tseh'-fah or tsiphoniy tsif-o-nee'",
		"derivation" : "from an unused root meaning to extrude",
		"def" : "a viper (as thrusting out the tongue, i.e. hissing)",
		"kjv" : "adder, cockatrice"
	},
	"H6849" : {
		"lemma" : "צְפִעָה",
		"xlit" : "tsᵉphiʻâh",
		"pron" : "tsef-ee-aw'",
		"derivation" : "feminine from the same as H6848 (צֶפַע)",
		"def" : "an outcast thing",
		"kjv" : "issue"
	},
	"H6850" : {
		"lemma" : "צָפַף",
		"xlit" : "tsâphaph",
		"pron" : "tsaw-faf'",
		"derivation" : "a primitive root",
		"def" : "to coo or chirp (as a bird)",
		"kjv" : "chatter, peep, whisper"
	},
	"H6851" : {
		"lemma" : "צַפְצָפָה",
		"xlit" : "tsaphtsâphâh",
		"pron" : "tsaf-tsaw-faw'",
		"derivation" : "from H6687 (צוּף)",
		"def" : "a willow (as growing in overflowed places)",
		"kjv" : "willow tree"
	},
	"H6852" : {
		"lemma" : "צָפַר",
		"xlit" : "tsâphar",
		"pron" : "tsaw-far'",
		"derivation" : "a primitive root",
		"def" : "to skip about, i.e. return",
		"kjv" : "depart early"
	},
	"H6853" : {
		"lemma" : "צְפַר",
		"xlit" : "tsᵉphar",
		"pron" : "tsef-ar'",
		"derivation" : "(Aramaic) corresponding to H6833 (צִפּוֹר)",
		"def" : "a bird",
		"kjv" : "bird"
	},
	"H6854" : {
		"lemma" : "צְפַרְדֵּעַ",
		"xlit" : "tsᵉphardêaʻ",
		"pron" : "tsef-ar-day'-ah",
		"derivation" : "from H6852 (צָפַר) and a word elsewhere unused meaning a swamp",
		"def" : "a marsh-leaper, i.e. frog",
		"kjv" : "frog"
	},
	"H6855" : {
		"lemma" : "צִפֹּרָה",
		"xlit" : "Tsippôrâh",
		"pron" : "tsip-po-raw'",
		"derivation" : "feminine of H6833 (צִפּוֹר); bird",
		"def" : "Tsipporah, Moses' wife",
		"kjv" : "Zipporah"
	},
	"H6856" : {
		"lemma" : "צִפֹּרֶן",
		"xlit" : "tsippôren",
		"pron" : "tsip-po'-ren",
		"derivation" : "from H6852 (צָפַר) (in the denominative sense (from H6833 (צִפּוֹר)) of scratching)",
		"def" : "properly, a claw, i.e. (human) nail; also the point of astyle (or pen, tipped with adamant)",
		"kjv" : "nail, point"
	},
	"H6857" : {
		"lemma" : "צְפַת",
		"xlit" : "Tsᵉphath",
		"pron" : "tsef-ath'",
		"derivation" : "from H6822 (צָפָה); watch-tower",
		"def" : "Tsephath, a place in Palestine",
		"kjv" : "Zephath"
	},
	"H6858" : {
		"lemma" : "צֶפֶת",
		"xlit" : "tsepheth",
		"pron" : "tseh'-feth",
		"derivation" : "from an unused root meaning to encircle",
		"def" : "a capital of a column",
		"kjv" : "chapiter"
	},
	"H6859" : {
		"lemma" : "צְפָתָה",
		"xlit" : "Tsᵉphâthâh",
		"pron" : "tsef-aw'-thaw",
		"derivation" : "the same as H6857 (צְפַת)",
		"def" : "Tsephathah, a place in Palestine",
		"kjv" : "Zephathah"
	},
	"H6860" : {
		"lemma" : "צִקְלַג",
		"xlit" : "Tsiqlag",
		"pron" : "tsik-lag'",
		"derivation" : "or צִיקלַג; (1 Chronicles 12:1,20), of uncertain derivation",
		"def" : "Tsiklag or Tsikelag, a place in Palestine",
		"kjv" : "Ziklag"
	},
	"H6861" : {
		"lemma" : "צִקְלֹן",
		"xlit" : "tsiqlôn",
		"pron" : "tsik-lone'",
		"derivation" : "from an unused root meaning to wind",
		"def" : "a sack (as tied at the mouth)",
		"kjv" : "husk"
	},
	"H6862" : {
		"lemma" : "צַר",
		"xlit" : "tsar",
		"pron" : "tsar",
		"derivation" : "or צָר; from H6887 (צָרַר); compare as in H6864 (צֹר)",
		"def" : "narrow; (as a noun) a tight place (usually figuratively, i.e. trouble); also a pebble ; (transitive) an opponent (as crowding)",
		"kjv" : "adversary, afflicted(-tion), anguish, close, distress, enemy, flint, foe, narrow, small, sorrow, strait, tribulation, trouble"
	},
	"H6863" : {
		"lemma" : "צֵר",
		"xlit" : "Tsêr",
		"pron" : "tsare",
		"derivation" : "from H6887 (צָרַר); rock",
		"def" : "Tser, a place in Palestine",
		"kjv" : "Zer"
	},
	"H6864" : {
		"lemma" : "צֹר",
		"xlit" : "tsôr",
		"pron" : "tsore",
		"derivation" : "from H6696 (צוּר)",
		"def" : "a stone (as if pressed hard or to a point); (by implication, of use) a knife",
		"kjv" : "flint, sharp stone"
	},
	"H6865" : {
		"lemma" : "צֹר",
		"xlit" : "Tsôr",
		"pron" : "tsore",
		"derivation" : "or צוֹר; the same as H6864 (צֹר); a rock",
		"def" : "Tsor, a place in Palestine",
		"kjv" : "Tyre, Tyrus"
	},
	"H6866" : {
		"lemma" : "צָרַב",
		"xlit" : "tsârab",
		"pron" : "tsaw-rab'",
		"derivation" : "a primitive root",
		"def" : "to burn",
		"kjv" : "burn"
	},
	"H6867" : {
		"lemma" : "צָרֶבֶת",
		"xlit" : "tsârebeth",
		"pron" : "tsaw-reh'-beth",
		"derivation" : "from H6686 (צוּעָר)",
		"def" : "conflagration (of fire or disease)",
		"kjv" : "burning, inflammation"
	},
	"H6868" : {
		"lemma" : "צְרֵדָה",
		"xlit" : "Tsᵉrêdâh",
		"pron" : "tser-ay-daw'",
		"derivation" : "or צְרֵדָתָה; apparently from an unused root meaning to pierce; puncture",
		"def" : "Tseredah, a place in Palestine",
		"kjv" : "Zereda, Zeredathah"
	},
	"H6869" : {
		"lemma" : "צָרָה",
		"xlit" : "tsârâh",
		"pron" : "tsaw-raw'",
		"derivation" : "feminine of H6862 (צַר)",
		"def" : "tightness (i.e. figuratively, trouble); transitively, a female rival",
		"kjv" : "adversary, adversity, affliction, anguish, distress, tribulation, trouble"
	},
	"H6870" : {
		"lemma" : "צְרוּיָה",
		"xlit" : "Tsᵉrûwyâh",
		"pron" : "tser-oo-yaw'",
		"derivation" : "feminine passive participle from the same as H6875 (צְרִי); wounded",
		"def" : "Tserujah, an Israelitess",
		"kjv" : "Zeruiah"
	},
	"H6871" : {
		"lemma" : "צְרוּעָה",
		"xlit" : "Tsᵉrûwʻâh",
		"pron" : "tser-oo-aw'",
		"derivation" : "feminine passive participle of H6879 (צָרַע); leprous",
		"def" : "Tseruah, an Israelitess",
		"kjv" : "Zeruah"
	},
	"H6872" : {
		"lemma" : "צְרוֹר",
		"xlit" : "tsᵉrôwr",
		"pron" : "tser-ore'",
		"derivation" : "or (shorter) צְרֹר; from H6887 (צָרַר)",
		"def" : "a parcel (as packed up); also a kernel or particle (as if a package)",
		"kjv" : "bag, [idiom] bendeth, bundle, least grain, small stone"
	},
	"H6873" : {
		"lemma" : "צָרַח",
		"xlit" : "tsârach",
		"pron" : "tsaw-rakh'",
		"derivation" : "a primitive root",
		"def" : "to be clear (in tone, i.e. shrill), i.e. to whoop",
		"kjv" : "cry, roar"
	},
	"H6874" : {
		"lemma" : "צְרִי",
		"xlit" : "Tsᵉrîy",
		"pron" : "tser-ee'",
		"derivation" : "the same as H6875 (צְרִי)",
		"def" : "Tseri, an Israelite",
		"kjv" : "Zeri. Compare H3340 (יִצְרִי)"
	},
	"H6875" : {
		"lemma" : "צְרִי",
		"xlit" : "tsᵉrîy",
		"pron" : "tser-ee'",
		"derivation" : "or צֳרִי; from an unused root meaning to crack (as by pressure), hence, to leak",
		"def" : "distillation, i.e. balsam",
		"kjv" : "balm"
	},
	"H6876" : {
		"lemma" : "צֹרִי",
		"xlit" : "Tsôrîy",
		"pron" : "tso-ree'",
		"derivation" : "patrial from H6865 (צֹר)",
		"def" : "a Tsorite or inhabitant of Tsor (i.e. Syrian)",
		"kjv" : "(man) of Tyre"
	},
	"H6877" : {
		"lemma" : "צְרִיחַ",
		"xlit" : "tsᵉrîyach",
		"pron" : "tser-ee'-akh",
		"derivation" : "from H6873 (צָרַח) in the sense of clearness of vision",
		"def" : "a citadel",
		"kjv" : "high place, hold"
	},
	"H6878" : {
		"lemma" : "צֹרֶךְ",
		"xlit" : "tsôrek",
		"pron" : "tso'-rek",
		"derivation" : "from an unused root meaning to need",
		"def" : "need",
		"kjv" : "need"
	},
	"H6879" : {
		"lemma" : "צָרַע",
		"xlit" : "tsâraʻ",
		"pron" : "tsaw-rah'",
		"derivation" : "a primitive root",
		"def" : "to scourge, i.e. (intransitive and figurative) to be stricken with leprosy",
		"kjv" : "leper, leprous"
	},
	"H6880" : {
		"lemma" : "צִרְעָה",
		"xlit" : "tsirʻâh",
		"pron" : "tsir-aw'",
		"derivation" : "from H6879 (צָרַע)",
		"def" : "a wasp (as stinging)",
		"kjv" : "hornet"
	},
	"H6881" : {
		"lemma" : "צׇרְעָה",
		"xlit" : "Tsorʻâh",
		"pron" : "tsor-aw'",
		"derivation" : "apparently another form for H6880 (צִרְעָה)",
		"def" : "Tsorah, a place in Palestine",
		"kjv" : "Zareah, Zorah, Zoreah"
	},
	"H6882" : {
		"lemma" : "צׇרְעִי",
		"xlit" : "Tsorʻîy",
		"pron" : "tsor-ee'",
		"derivation" : "or צׇרְעָתִי; patrial from H6881 (צׇרְעָה)",
		"def" : "a Tsorite or Tsorathite, i.e. inhabitants of Tsorah",
		"kjv" : "Zorites, Zareathites, Zorathites"
	},
	"H6883" : {
		"lemma" : "צָרַעַת",
		"xlit" : "tsâraʻath",
		"pron" : "tsaw-rah'-ath",
		"derivation" : "from H6879 (צָרַע)",
		"def" : "leprosy",
		"kjv" : "leprosy"
	},
	"H6884" : {
		"lemma" : "צָרַף",
		"xlit" : "tsâraph",
		"pron" : "tsaw-raf'",
		"derivation" : "a primitive root",
		"def" : "to fuse (metal), i.e. refine (literally or figuratively)",
		"kjv" : "cast, (re-) fine(-er), founder, goldsmith, melt, pure, purge away, try"
	},
	"H6885" : {
		"lemma" : "צֹרְפִי",
		"xlit" : "Tsôrᵉphîy",
		"pron" : "tso-ref-ee'",
		"derivation" : "from H6884 (צָרַף); refiner",
		"def" : "Tsorephi (with the article), an Israelite",
		"kjv" : "goldsmith's"
	},
	"H6886" : {
		"lemma" : "צָרְפַת",
		"xlit" : "Tsârᵉphath",
		"pron" : "tsaq-ref-ath'",
		"derivation" : "from H6884 (צָרַף); refinement",
		"def" : "Tsarephath, a place in Palestine",
		"kjv" : "Zarephath"
	},
	"H6887" : {
		"lemma" : "צָרַר",
		"xlit" : "tsârar",
		"pron" : "tsaw-rar'",
		"derivation" : "a primitive root",
		"def" : "to cramp, literally or figuratively, transitive or intransitive",
		"kjv" : "adversary, (be in) afflict(-ion), beseige, bind (up), (be in, bring) distress, enemy, narrower, oppress, pangs, shut up, be in a strait (trouble), vex"
	},
	"H6888" : {
		"lemma" : "צְרֵרָה",
		"xlit" : "Tsᵉrêrâh",
		"pron" : "tser-ay-raw'",
		"derivation" : "apparently by erroneous transcription for H6868 (צְרֵדָה)",
		"def" : "Tsererah for Tseredah",
		"kjv" : "Zererath"
	},
	"H6889" : {
		"lemma" : "צֶרֶת",
		"xlit" : "Tsereth",
		"pron" : "tseh'-reth",
		"derivation" : "perhaps from H6671 (צָהַר); splendor",
		"def" : "Tsereth, an Israelite",
		"kjv" : "Zereth"
	},
	"H6890" : {
		"lemma" : "צֶרֶת הַשַּׁחַר",
		"xlit" : "Tsereth hash-Shachar",
		"pron" : "tseh'-reth hash-shakh'-ar",
		"derivation" : "from the same as H6889 (צֶרֶת) and H7837 (שַׁחַר) with the article interposed; splendor of the dawn",
		"def" : "Tsereth-hash-Shachar, a place in Palestine",
		"kjv" : "Zareth-shahar"
	},
	"H6891" : {
		"lemma" : "צָרְתָן",
		"xlit" : "Tsârᵉthân",
		"pron" : "tsaw-reth-awn'",
		"derivation" : "perhaps for H6868 (צְרֵדָה)",
		"def" : "Tsarethan, a place in Palestine",
		"kjv" : "Zarthan. q"
	},
	"H6892" : {
		"lemma" : "קֵא",
		"xlit" : "qêʼ",
		"pron" : "kay",
		"derivation" : "or קִיא; from H6958 (קוֹא)",
		"def" : "vomit",
		"kjv" : "vomit"
	},
	"H6893" : {
		"lemma" : "קָאַת",
		"xlit" : "qâʼath",
		"pron" : "kaw-ath'",
		"derivation" : "from H6958 (קוֹא)",
		"def" : "probably the pelican (from vomiting)",
		"kjv" : "cormorant"
	},
	"H6894" : {
		"lemma" : "קַב",
		"xlit" : "qab",
		"pron" : "kab",
		"derivation" : "from H6895 (קָבַב)",
		"def" : "a hollow, i.e. vessel used as a (dry) measure",
		"kjv" : "cab"
	},
	"H6895" : {
		"lemma" : "קָבַב",
		"xlit" : "qâbab",
		"pron" : "kaw-bab'",
		"derivation" : "a primitive root",
		"def" : "to scoop out, i.e. (figuratively) to malign or execrate (i.e. stab with words)",
		"kjv" : "[idiom] at all, curse"
	},
	"H6896" : {
		"lemma" : "קֵבָה",
		"xlit" : "qêbâh",
		"pron" : "kay-baw'",
		"derivation" : "from H6895 (קָבַב)",
		"def" : "the paunch (as a cavity) or first stomach of ruminants",
		"kjv" : "maw"
	},
	"H6897" : {
		"lemma" : "קֹבָה",
		"xlit" : "qôbâh",
		"pron" : "ko'-baw",
		"derivation" : "from H6895 (קָבַב)",
		"def" : "the abdomen (as a cavity)",
		"kjv" : "belly"
	},
	"H6898" : {
		"lemma" : "קֻבָּה",
		"xlit" : "qubbâh",
		"pron" : "koob-baw'",
		"derivation" : "from H6895 (קָבַב)",
		"def" : "a pavilion (as a domed cavity)",
		"kjv" : "tent"
	},
	"H6899" : {
		"lemma" : "קִבּוּץ",
		"xlit" : "qibbûwts",
		"pron" : "kib-boots'",
		"derivation" : "from H6908 (קָבַץ)",
		"def" : "a throng",
		"kjv" : "company"
	},
	"H6900" : {
		"lemma" : "קְבוּרָה",
		"xlit" : "qᵉbûwrâh",
		"pron" : "keb-oo-raw'",
		"derivation" : "or קְבֻרָה; feminine passive participle of H6912 (קָבַר)",
		"def" : "sepulture; (concretely) a sepulchre",
		"kjv" : "burial, burying place, grave, sepulchre"
	},
	"H6901" : {
		"lemma" : "קָבַל",
		"xlit" : "qâbal",
		"pron" : "kaw-bal'",
		"derivation" : "a primitive root",
		"def" : "to admit, i.e. take (literally or figuratively)",
		"kjv" : "choose, (take) hold, receive, (under-) take"
	},
	"H6902" : {
		"lemma" : "קְבַל",
		"xlit" : "qᵉbal",
		"pron" : "keb-al'",
		"derivation" : "(Aramaic) corresponding to H6901 (קָבַל)",
		"def" : "to acquire",
		"kjv" : "receive, take"
	},
	"H6903" : {
		"lemma" : "קְבֵל",
		"xlit" : "qᵉbêl",
		"pron" : "keb-ale'",
		"derivation" : "(Aramaic) or קֳבֵל; (Aramaic), (corresponding to H6905 (קָבָל)",
		"def" : "(adverbially) in front of; usually (with other particles) on account of, so as, since, hence",
		"kjv" : "[phrase] according to, [phrase] as, [phrase] because, before, [phrase] for this cause, [phrase] forasmuch as, [phrase] by this means, over against, by reason of, [phrase] that, [phrase] therefore, [phrase] though, [phrase] wherefore"
	},
	"H6904" : {
		"lemma" : "קֹבֶל",
		"xlit" : "qôbel",
		"pron" : "ko'-bel",
		"derivation" : "from H6901 (קָבַל) in the sense of confronting (as standing opposite in order to receive)",
		"def" : "a battering-ram",
		"kjv" : "war"
	},
	"H6905" : {
		"lemma" : "קָבָל",
		"xlit" : "qâbâl",
		"pron" : "kaw-bawl'",
		"derivation" : "from H6901 (קָבַל) in the sense of opposite (see H6904 (קֹבֶל))",
		"def" : "the presence, i.e. (adverbially) in front of",
		"kjv" : "before"
	},
	"H6906" : {
		"lemma" : "קָבַע",
		"xlit" : "qâbaʻ",
		"pron" : "kaw-bah'",
		"derivation" : "a primitive root",
		"def" : "to cover, i.e. (figuratively) defraud",
		"kjv" : "rob, spoil"
	},
	"H6907" : {
		"lemma" : "קֻבַּעַת",
		"xlit" : "qubbaʻath",
		"pron" : "koob-bah'-ath",
		"derivation" : "from H6906 (קָבַע)",
		"def" : "a goblet (as deep like a cover)",
		"kjv" : "dregs"
	},
	"H6908" : {
		"lemma" : "קָבַץ",
		"xlit" : "qâbats",
		"pron" : "kaw-bats'",
		"derivation" : "a primitive root",
		"def" : "to grasp, i.e. collect",
		"kjv" : "assemble (selves), gather (bring) (together, selves together, up), heap, resort, [idiom] surely, take up"
	},
	"H6909" : {
		"lemma" : "קַבְצְאֵל",
		"xlit" : "Qabtsᵉʼêl",
		"pron" : "kab-tseh-ale'",
		"derivation" : "from H6908 (קָבַץ) and H410 (אֵל); God has gathered",
		"def" : "Kabtseel, a place in Palestine",
		"kjv" : "Kabzeel. Compare H3343 (יְקַבְצְאֵל)"
	},
	"H6910" : {
		"lemma" : "קְבֻצָה",
		"xlit" : "qᵉbutsâh",
		"pron" : "keb-oo-tsaw'",
		"derivation" : "feminine passive participle of H6908 (קָבַץ)",
		"def" : "a hoard",
		"kjv" : "[idiom] gather"
	},
	"H6911" : {
		"lemma" : "קִבְצַיִם",
		"xlit" : "Qibtsayim",
		"pron" : "kib-tsah'-yim",
		"derivation" : "dual from H6908 (קָבַץ); a double heap",
		"def" : "Kibtsajim, a place in Palestine",
		"kjv" : "Kibzaim"
	},
	"H6912" : {
		"lemma" : "קָבַר",
		"xlit" : "qâbar",
		"pron" : "kaw-bar'",
		"derivation" : "a primitive root",
		"def" : "to inter",
		"kjv" : "[idiom] in any wise, bury(-ier)"
	},
	"H6913" : {
		"lemma" : "קֶבֶר",
		"xlit" : "qeber",
		"pron" : "keh'-ber",
		"derivation" : "or (feminine) קִבְרָה; from H6912 (קָבַר)",
		"def" : "a sepulchre",
		"kjv" : "burying place, grave, sepulchre"
	},
	"H6914" : {
		"lemma" : "קִבְרוֹת הַתַּאֲוָה",
		"xlit" : "Qibrôwth hat-Taʼă-vâh",
		"pron" : "kib-roth' hat-tahav-aw'",
		"derivation" : "from the feminine plural of H6913 (קֶבֶר) and H8378 (תַּאֲוָה) with the article interposed; graves of the longing",
		"def" : "Kibroth-hat-Taavh, a place in the Desert",
		"kjv" : "Kibroth-hattaavah"
	},
	"H6915" : {
		"lemma" : "קָדַד",
		"xlit" : "qâdad",
		"pron" : "kaw-dad'",
		"derivation" : "a primitive root",
		"def" : "to shrivel up, i.e. contract or bend the body (or neck) in deference",
		"kjv" : "bow (down) (the) head, stoop"
	},
	"H6916" : {
		"lemma" : "קִדָּה",
		"xlit" : "qiddâh",
		"pron" : "kid-daw'",
		"derivation" : "from H6915 (קָדַד)",
		"def" : "cassia bark (as in shrivelled rolls)",
		"kjv" : "cassia"
	},
	"H6917" : {
		"lemma" : "קָדוּם",
		"xlit" : "qâdûwm",
		"pron" : "kaw-doom'",
		"derivation" : "passive participle of H6923 (קָדַם)",
		"def" : "a pristine hero",
		"kjv" : "ancient"
	},
	"H6918" : {
		"lemma" : "קָדוֹשׁ",
		"xlit" : "qâdôwsh",
		"pron" : "kaw-doshe'",
		"derivation" : "or קָדֹשׁ; from H6942 (קָדַשׁ)",
		"def" : "sacred (ceremonially or morally); (as noun) God (by eminence), an angel, a saint, a sanctuary",
		"kjv" : "holy (One), saint"
	},
	"H6919" : {
		"lemma" : "קָדַח",
		"xlit" : "qâdach",
		"pron" : "kaw-dakh'",
		"derivation" : "a primitive root to inflame",
		"def" : "to inflame",
		"kjv" : "burn, kindle"
	},
	"H6920" : {
		"lemma" : "קַדַּחַת",
		"xlit" : "qaddachath",
		"pron" : "kad-dakh'-ath",
		"derivation" : "from H6919 (קָדַח)",
		"def" : "inflammation, i.e. febrile disease",
		"kjv" : "burning ague, fever"
	},
	"H6921" : {
		"lemma" : "קָדִים",
		"xlit" : "qâdîym",
		"pron" : "kaw-deem'",
		"derivation" : "or קָדִם; from H6923 (קָדַם)",
		"def" : "the fore or front part; hence (by orientation) the East (often adverbially, eastward, for brevity the east wind)",
		"kjv" : "east(-ward, wind)"
	},
	"H6922" : {
		"lemma" : "קַדִּישׁ",
		"xlit" : "qaddîysh",
		"pron" : "kad-deesh'",
		"derivation" : "(Aramaic) corresponding to H6918 (קָדוֹשׁ)",
		"def" : "{sacred (ceremonially or morally); (as noun) God (by eminence), an angel, a saint, a sanctuary}",
		"kjv" : "holy (One), saint"
	},
	"H6923" : {
		"lemma" : "קָדַם",
		"xlit" : "qâdam",
		"pron" : "kaw-dam'",
		"derivation" : "a primitive root",
		"def" : "to project (one self), i.e. precede; hence, to anticipate, hasten, meet (usually for help)",
		"kjv" : "come (go, (flee)) before, [phrase] disappoint, meet, prevent"
	},
	"H6924" : {
		"lemma" : "קֶדֶם",
		"xlit" : "qedem",
		"pron" : "keh'-dem",
		"derivation" : "or קֵדְמָה; from H6923 (קָדַם)",
		"def" : "the front, of place (absolutely, the fore part, relatively the East) or time (antiquity); often used adverbially (before, anciently, eastward)",
		"kjv" : "aforetime, ancient (time), before, east (end, part, side, -ward), eternal, [idiom] ever(-lasting), forward, old, past. Compare H6926 (קִדְמָה)"
	},
	"H6925" : {
		"lemma" : "קֳדָם",
		"xlit" : "qŏdâm",
		"pron" : "kod-awm'",
		"derivation" : "(Aramaic) or קְדָם; (Aramaic) (Daniel 7:13), corresponding to H6924 (קֶדֶם)",
		"def" : "before",
		"kjv" : "before, [idiom] from, [idiom] I (thought), [idiom] me, [phrase] of, [idiom] it pleased, presence"
	},
	"H6926" : {
		"lemma" : "קִדְמָה",
		"xlit" : "qidmâh",
		"pron" : "kid-maw'",
		"derivation" : "feminine of H6924 (קֶדֶם)",
		"def" : "the forward part (or relatively) East (often adverbially, on the east or in front)",
		"kjv" : "east(-ward)"
	},
	"H6927" : {
		"lemma" : "קַדְמָה",
		"xlit" : "qadmâh",
		"pron" : "kad-maw'",
		"derivation" : "from H6923 (קָדַם)",
		"def" : "priority (in time); also used adverbially (before)",
		"kjv" : "afore, antiquity, former (old) estate"
	},
	"H6928" : {
		"lemma" : "קַדְמָה",
		"xlit" : "qadmâh",
		"pron" : "kad-maw'",
		"derivation" : "(Aramaic) corresponding to H6927 (קַדְמָה)",
		"def" : "former time",
		"kjv" : "afore(-time), ago"
	},
	"H6929" : {
		"lemma" : "קֵדְמָה",
		"xlit" : "Qêdᵉmâh",
		"pron" : "kayd'-maw",
		"derivation" : "from H6923 (קָדַם); precedence",
		"def" : "Kedemah, a son of Ishmael",
		"kjv" : "Kedemah"
	},
	"H6930" : {
		"lemma" : "קַדְמוֹן",
		"xlit" : "qadmôwn",
		"pron" : "kad-mone'",
		"derivation" : "from H6923 (קָדַם)",
		"def" : "eastern",
		"kjv" : "east"
	},
	"H6931" : {
		"lemma" : "קַדְמוֹנִי",
		"xlit" : "qadmôwnîy",
		"pron" : "kad-mo-nee'",
		"derivation" : "or קַדְמֹנִי; from H6930 (קַדְמוֹן)",
		"def" : "(of time) anterior or (of place) oriental",
		"kjv" : "ancient, they that went before, east, (thing of) old"
	},
	"H6932" : {
		"lemma" : "קְדֵמוֹת",
		"xlit" : "Qᵉdêmôwth",
		"pron" : "ked-ay-mothe'",
		"derivation" : "from H6923 (קָדַם); beginnings",
		"def" : "Kedemoth, a place in eastern Palestine",
		"kjv" : "Kedemoth"
	},
	"H6933" : {
		"lemma" : "קַדְמַי",
		"xlit" : "qadmay",
		"pron" : "kad-mah'-ee",
		"derivation" : "(Aramaic) from a root corresponding to H6923 (קָדַם)",
		"def" : "first",
		"kjv" : "first"
	},
	"H6934" : {
		"lemma" : "קַדְמִיאֵל",
		"xlit" : "Qadmîyʼêl",
		"pron" : "kad-mee-ale'",
		"derivation" : "from H6924 (קֶדֶם) and H410 (אֵל); presence of God",
		"def" : "Kadmiel, the name of three Israelites",
		"kjv" : "Kadmiel"
	},
	"H6935" : {
		"lemma" : "קַדְמֹנִי",
		"xlit" : "Qadmônîy",
		"pron" : "kad-mo-nee'",
		"derivation" : "the same as H6931 (קַדְמוֹנִי); ancient, i.e. aboriginal",
		"def" : "Kadmonite (collectively), the name of a tribe in Palestine",
		"kjv" : "Kadmonites"
	},
	"H6936" : {
		"lemma" : "קׇדְקֹד",
		"xlit" : "qodqôd",
		"pron" : "kod-kode'",
		"derivation" : "from H6915 (קָדַד)",
		"def" : "the crown of the head (as the part most bowed)",
		"kjv" : "crown (of the head), pate, scalp, top of the head"
	},
	"H6937" : {
		"lemma" : "קָדַר",
		"xlit" : "qâdar",
		"pron" : "kaw-dar'",
		"derivation" : "a primitive root",
		"def" : "to be ashy, i.e. darkcolored; by implication, to mourn (in sackcloth or sordid garments)",
		"kjv" : "be black(-ish), be (make) dark(-en), [idiom] heavily, (cause to) mourn"
	},
	"H6938" : {
		"lemma" : "קֵדָר",
		"xlit" : "Qêdâr",
		"pron" : "kay-dawr'",
		"derivation" : "from H6937 (קָדַר); dusky (of the skin or the tent)",
		"def" : "Kedar, a son of Ishmael; also (collectively) Bedouin (as his descendants or representatives)",
		"kjv" : "Kedar"
	},
	"H6939" : {
		"lemma" : "קִדְרוֹן",
		"xlit" : "Qidrôwn",
		"pron" : "kid-rone'",
		"derivation" : "from H6937 (קָדַר); dusky place",
		"def" : "Kidron, a brook near Jerusalem",
		"kjv" : "Kidron"
	},
	"H6940" : {
		"lemma" : "קַדְרוּת",
		"xlit" : "qadrûwth",
		"pron" : "kad-rooth'",
		"derivation" : "from H6937 (קָדַר)",
		"def" : "duskiness",
		"kjv" : "blackness"
	},
	"H6941" : {
		"lemma" : "קְדֹרַנִּית",
		"xlit" : "qᵉdôrannîyth",
		"pron" : "ked-o-ran-neeth'",
		"derivation" : "adverb from H6937 (קָדַר)",
		"def" : "blackish ones (i.e. in sackcloth); used adverbially, in mourning weeds",
		"kjv" : "mournfully"
	},
	"H6942" : {
		"lemma" : "קָדַשׁ",
		"xlit" : "qâdash",
		"pron" : "kaw-dash'",
		"derivation" : "a primitive root",
		"def" : "to be (causatively, make, pronounce or observe as) clean (ceremonially or morally)",
		"kjv" : "appoint, bid, consecrate, dedicate, defile, hallow, (be, keep) holy(-er, place), keep, prepare, proclaim, purify, sanctify(-ied one, self), [idiom] wholly"
	},
	"H6943" : {
		"lemma" : "קֶדֶשׁ",
		"xlit" : "Qedesh",
		"pron" : "keh'-desh",
		"derivation" : "from H6942 (קָדַשׁ); a sanctum",
		"def" : "Kedesh, the name of four places in Palestine",
		"kjv" : "Kedesh"
	},
	"H6944" : {
		"lemma" : "קֹדֶשׁ",
		"xlit" : "qôdesh",
		"pron" : "ko'-desh",
		"derivation" : "from H6942 (קָדַשׁ)",
		"def" : "a sacred place or thing; rarely abstract, sanctity",
		"kjv" : "consecrated (thing), dedicated (thing), hallowed (thing), holiness, ([idiom] most) holy ([idiom] day, portion, thing), saint, sanctuary"
	},
	"H6945" : {
		"lemma" : "קָדֵשׁ",
		"xlit" : "qâdêsh",
		"pron" : "kaw-dashe'",
		"derivation" : "from H6942 (קָדַשׁ)",
		"def" : "a (quasi) sacred person, i.e. (technically) a (male) devotee (by prostitution) to licentious idolatry",
		"kjv" : "sodomite, unclean"
	},
	"H6946" : {
		"lemma" : "קָדֵשׁ",
		"xlit" : "Qâdêsh",
		"pron" : "kaw-dashe'",
		"derivation" : "the same as H6945 (קָדֵשׁ); sanctuary",
		"def" : "Kadesh, a place in the Desert",
		"kjv" : "Kadesh. Compare H6947 (קָדֵשׁ בַּרְנֵעַ)"
	},
	"H6947" : {
		"lemma" : "קָדֵשׁ בַּרְנֵעַ",
		"xlit" : "Qâdêsh Barnêaʻ",
		"pron" : "kaw-dashe' bar-nay'-ah",
		"derivation" : "from the same as H6946 (קָדֵשׁ) and an otherwise unused word (apparently compounded of a correspondent to H1251 (בַּר) and a derivative of H5128 (נוּעַ)) meaning desert of a fugitive; Kadesh of (the) Wilderness of Wandering",
		"def" : "Kadesh-Barnea, a place in the Desert",
		"kjv" : "Kadeshbarnea"
	},
	"H6948" : {
		"lemma" : "קְדֵשָׁה",
		"xlit" : "qᵉdêshâh",
		"pron" : "ked-ay-shaw'",
		"derivation" : "feminine of H6945 (קָדֵשׁ)",
		"def" : "a female devotee (i.e. prostitute)",
		"kjv" : "harlot, whore"
	},
	"H6949" : {
		"lemma" : "קָהָה",
		"xlit" : "qâhâh",
		"pron" : "kaw-haw'",
		"derivation" : "a primitive root",
		"def" : "to be dull",
		"kjv" : "be set on edge, be blunt"
	},
	"H6950" : {
		"lemma" : "קָהַל",
		"xlit" : "qâhal",
		"pron" : "'kaw-hal'",
		"derivation" : "a primitive root",
		"def" : "to convoke",
		"kjv" : "assemble (selves) (together), gather (selves) (together)"
	},
	"H6951" : {
		"lemma" : "קָהָל",
		"xlit" : "qâhâl",
		"pron" : "kaw-hawl'",
		"derivation" : "from H6950 (קָהַל)",
		"def" : "assemblage (usually concretely)",
		"kjv" : "assembly, company, congregation, multitude"
	},
	"H6952" : {
		"lemma" : "קְהִלָּה",
		"xlit" : "qᵉhillâh",
		"pron" : "keh-hil-law'",
		"derivation" : "from H6950 (קָהַל)",
		"def" : "an assemblage",
		"kjv" : "assembly, congregation"
	},
	"H6953" : {
		"lemma" : "קֹהֶלֶת",
		"xlit" : "qôheleth",
		"pron" : "ko-heh'-leth",
		"derivation" : "feminine of active participle from H6950 (קָהַל)",
		"def" : "a (female) assembler (i.e. lecturer); abstractly, preaching (used as a 'nom de plume', Koheleth)",
		"kjv" : "preacher"
	},
	"H6954" : {
		"lemma" : "קְהֵלָתָה",
		"xlit" : "Qᵉhêlâthâh",
		"pron" : "keh-hay-law'-thaw",
		"derivation" : "from H6950 (קָהַל); convocation",
		"def" : "Kehelathah, a place in the Desert",
		"kjv" : "Kehelathah"
	},
	"H6955" : {
		"lemma" : "קְהָת",
		"xlit" : "Qᵉhâth",
		"pron" : "keh-hawth'",
		"derivation" : "from an unused root meaning to ally oneself; allied",
		"def" : "Kehath, an Israelite",
		"kjv" : "Kohath"
	},
	"H6956" : {
		"lemma" : "קֳהָתִי",
		"xlit" : "Qŏhâthîy",
		"pron" : "ko-haw-thee'",
		"derivation" : "patronymically from H6955 (קְהָת)",
		"def" : "a Kohathite (collectively) or descendants of Kehath",
		"kjv" : "Kohathites"
	},
	"H6957" : {
		"lemma" : "קַו",
		"xlit" : "qav",
		"pron" : "kav",
		"derivation" : "or קָו; from H6960 (קָוָה) (compare H6961 (קָוֶה))",
		"def" : "a cord (as connecting), especially formeasuring; figuratively, a rule; also a rim, a musical string or accord",
		"kjv" : "line. Compare H6978 (קַו־קַו)lemma קַו־קַי yod, corrected to קַו־קַו"
	},
	"H6958" : {
		"lemma" : "קוֹא",
		"xlit" : "qôwʼ",
		"pron" : "ko",
		"derivation" : "or קָיָה; (Jeremiah 25:27), a primitive root",
		"def" : "to vomit",
		"kjv" : "spue (out), vomit (out, up, up again)"
	},
	"H6959" : {
		"lemma" : "קוֹבַע",
		"xlit" : "qôwbaʻ",
		"pron" : "ko'-bah or ko-bah'",
		"derivation" : "a form collateral to H3553 (כּוֹבַע)",
		"def" : "a helmet",
		"kjv" : "helmet"
	},
	"H6960" : {
		"lemma" : "קָוָה",
		"xlit" : "qâvâh",
		"pron" : "kaw-vaw'",
		"derivation" : "a primitive root",
		"def" : "to bind together (perhaps by twisting), i.e. collect; (figuratively) to expect",
		"kjv" : "gather (together), look, patiently, tarry, wait (for, on, upon)"
	},
	"H6961" : {
		"lemma" : "קָוֶה",
		"xlit" : "qâveh",
		"pron" : "kaw-veh'",
		"derivation" : "from H6960 (קָוָה)",
		"def" : "a (measuring) cord (as if for binding)",
		"kjv" : "line"
	},
	"H6962" : {
		"lemma" : "קוּט",
		"xlit" : "qûwṭ",
		"pron" : "koot",
		"derivation" : "a primitive root",
		"def" : "properly, to cut off, i.e. (figuratively) detest",
		"kjv" : "begrieved, loathe self"
	},
	"H6963" : {
		"lemma" : "קוֹל",
		"xlit" : "qôwl",
		"pron" : "kole",
		"derivation" : "or קֹל; from an unused root meaning to call aloud",
		"def" : "a voice or sound",
		"kjv" : "[phrase] aloud, bleating, crackling, cry ([phrase] out), fame, lightness, lowing, noise, [phrase] hold peace, (pro-) claim, proclamation, [phrase] sing, sound, [phrase] spark, thunder(-ing), voice, [phrase] yell"
	},
	"H6964" : {
		"lemma" : "קוֹלָיָה",
		"xlit" : "Qôwlâyâh",
		"pron" : "ko-law-yaw'",
		"derivation" : "from H6963 (קוֹל) and H3050 (יָהּ); voice of Jah",
		"def" : "Kolajah, the name of two Israelites",
		"kjv" : "Kolaiah"
	},
	"H6965" : {
		"lemma" : "קוּם",
		"xlit" : "qûwm",
		"pron" : "koom",
		"derivation" : "a primitive root",
		"def" : "to rise (in various applications, literal, figurative, intensive and causative)",
		"kjv" : "abide, accomplish, [idiom] be clearer, confirm, continue, decree, [idiom] be dim, endure, [idiom] enemy, enjoin, get up, make good, help, hold, (help to) lift up (again), make, [idiom] but newly, ordain, perform, pitch, raise (up), rear (up), remain, (a-) rise (up) (again, against), rouse up, set (up), (e-) stablish, (make to) stand (up), stir up, strengthen, succeed, (as-, make) sure(-ly), (be) up(-hold, -rising)"
	},
	"H6966" : {
		"lemma" : "קוּם",
		"xlit" : "qûwm",
		"pron" : "koom",
		"derivation" : "(Aramaic) corresponding to H6965 (קוּם)",
		"def" : "{to rise (in various applications, literal, figurative, intensive and causative)}",
		"kjv" : "appoint, establish, make, raise up self, (a-) rise (up), (make to) stand, set (up)"
	},
	"H6967" : {
		"lemma" : "קוֹמָה",
		"xlit" : "qôwmâh",
		"pron" : "ko-maw'",
		"derivation" : "from H6965 (קוּם)",
		"def" : "height",
		"kjv" : "[idiom] along, height, high, stature, tall"
	},
	"H6968" : {
		"lemma" : "קוֹמְמִיּוּת",
		"xlit" : "qôwmᵉmîyûwth",
		"pron" : "ko-mem-ee-yooth'",
		"derivation" : "from H6965 (קוּם)",
		"def" : "elevation, i.e. (adverbially) erectly (figuratively)",
		"kjv" : "upright"
	},
	"H6969" : {
		"lemma" : "קוּן",
		"xlit" : "qûwn",
		"pron" : "koon",
		"derivation" : "a primitive root",
		"def" : "to strike a musical note, i.e. chant or wail (at a funeral)",
		"kjv" : "lament, mourning woman"
	},
	"H6970" : {
		"lemma" : "קוֹעַ",
		"xlit" : "Qôwaʻ",
		"pron" : "ko'-ah",
		"derivation" : "probably from H6972 (קוּץ) in the original sense of cutting off; curtailment",
		"def" : "Koa, a region of Babylon",
		"kjv" : "Koa"
	},
	"H6971" : {
		"lemma" : "קוֹף",
		"xlit" : "qôwph",
		"pron" : "kofe",
		"derivation" : "or קֹף; of foreign origin",
		"def" : "a monkey",
		"kjv" : "ape"
	},
	"H6972" : {
		"lemma" : "קוּץ",
		"xlit" : "qûwts",
		"pron" : "koots",
		"derivation" : "a primitive root; to clip off; used only as denominative from H7019 (קַיִץ)",
		"def" : "to spend the harvest season",
		"kjv" : "summer"
	},
	"H6973" : {
		"lemma" : "קוּץ",
		"xlit" : "qûwts",
		"pron" : "koots",
		"derivation" : "a primitive root (identical with through the idea of severing oneself from (compare H6962 (קוּט)))",
		"def" : "to be (causatively, make) disgusted or anxious",
		"kjv" : "abhor, be distressed, be grieved, loathe, vex, be weary"
	},
	"H6974" : {
		"lemma" : "קוּץ",
		"xlit" : "qûwts",
		"pron" : "koots",
		"derivation" : "a primitive root (identical with through the idea of abruptness in starting up from sleep (compare H3364 (יָקַץ)))",
		"def" : "to awake (literally or figuratively)",
		"kjv" : "arise, (be) (a-) wake, watch"
	},
	"H6975" : {
		"lemma" : "קוֹץ",
		"xlit" : "qôwts",
		"pron" : "kotse",
		"derivation" : "or קֹץ; from H6972 (קוּץ) (in the sense of pricking)",
		"def" : "a thorn",
		"kjv" : "thorn"
	},
	"H6976" : {
		"lemma" : "קוֹץ",
		"xlit" : "Qôwts",
		"pron" : "kotse",
		"derivation" : "the same as H6975 (קוֹץ)",
		"def" : "Kots, the name of two Israelites",
		"kjv" : "Koz, Hakkoz (including the article)"
	},
	"H6977" : {
		"lemma" : "קְוֻצָּה",
		"xlit" : "qᵉvutstsâh",
		"pron" : "kev-oots-tsaw'",
		"derivation" : "feminine passive participle of H6972 (קוּץ) in its original sense",
		"def" : "a forelock (as shorn)",
		"kjv" : "lock"
	},
	"H6978" : {
		"lemma" : "קַו־קַו",
		"xlit" : "qav-qav",
		"pron" : "kav-kav'",
		"derivation" : "from H6957 (קַו) (in the sense of a fastening)",
		"def" : "stalwart",
		"kjv" : "[idiom] meted out"
	},
	"H6979" : {
		"lemma" : "קוּר",
		"xlit" : "qûwr",
		"pron" : "koor",
		"derivation" : "a primitive root; also denominative from H7023 (קִיר)",
		"def" : "to trench; by implication, to throw forth;  to wall up, whether literal (to build a wall) or figurative (to estop)",
		"kjv" : "break down, cast out, destroy, dig"
	},
	"H6980" : {
		"lemma" : "קוּר",
		"xlit" : "qûwr",
		"pron" : "koor",
		"derivation" : "from H6979 (קוּר)",
		"def" : "(only plural) trenches, i.e. a web (as if so formed)",
		"kjv" : "web"
	},
	"H6981" : {
		"lemma" : "קוֹרֵא",
		"xlit" : "Qôwrêʼ",
		"pron" : "ko-ray'",
		"derivation" : "or קֹרֵא; (1 Chronicles 26:1), active participle of H7121 (קָרָא); crier",
		"def" : "Kore, the name of two Israelites",
		"kjv" : "Kore"
	},
	"H6982" : {
		"lemma" : "קוֹרָה",
		"xlit" : "qôwrâh",
		"pron" : "ko-raw'",
		"derivation" : "or קֹרָה; from H6979 (קוּר)",
		"def" : "a rafter (forming trenches as it were); by implication, a roof",
		"kjv" : "beam, roof"
	},
	"H6983" : {
		"lemma" : "קוֹשׁ",
		"xlit" : "qôwsh",
		"pron" : "koshe",
		"derivation" : "a primitive root; to bend; used only as denominative for H3369 (יָקֹשׁ)",
		"def" : "to set a trap",
		"kjv" : "lay a snare"
	},
	"H6984" : {
		"lemma" : "קוּשָׁיָהוּ",
		"xlit" : "qûwshâyâhûw",
		"pron" : "koo-shaw-yaw'-hoo",
		"derivation" : "from the passive participle of H6983 (קוֹשׁ) and H3050 (יָהּ); entrapped of Jah",
		"def" : "Kushajah, an Israelite",
		"kjv" : "Kushaiah"
	},
	"H6985" : {
		"lemma" : "קַט",
		"xlit" : "qaṭ",
		"pron" : "kat",
		"derivation" : "from H6990 (קָטַט) in the sense of abbreviation",
		"def" : "a little, i.e. (adverbially) merely",
		"kjv" : "very"
	},
	"H6986" : {
		"lemma" : "קֶטֶב",
		"xlit" : "qeṭeb",
		"pron" : "keh'-teb",
		"derivation" : "from an unused root meaning to cut off",
		"def" : "ruin",
		"kjv" : "destroying, destruction"
	},
	"H6987" : {
		"lemma" : "קֹטֶב",
		"xlit" : "qôṭeb",
		"pron" : "ko'-teb",
		"derivation" : "from the same as H6986 (קֶטֶב)",
		"def" : "extermination",
		"kjv" : "destruction"
	},
	"H6988" : {
		"lemma" : "קְטוֹרָה",
		"xlit" : "qᵉṭôwrâh",
		"pron" : "ket-o-raw'",
		"derivation" : "from H6999 (קָטַר)",
		"def" : "perfume",
		"kjv" : "incense"
	},
	"H6989" : {
		"lemma" : "קְטוּרָה",
		"xlit" : "Qᵉṭûwrâh",
		"pron" : "ket-oo-raw'",
		"derivation" : "feminine passive participle of H6999 (קָטַר); perfumed",
		"def" : "Keturah, a wife of Abraham",
		"kjv" : "Keturah"
	},
	"H6990" : {
		"lemma" : "קָטַט",
		"xlit" : "qâṭaṭ",
		"pron" : "kaw-tat'",
		"derivation" : "a primitive root",
		"def" : "to clip off, i.e. (figuratively) destroy",
		"kjv" : "be cut off"
	},
	"H6991" : {
		"lemma" : "קָטַל",
		"xlit" : "qâṭal",
		"pron" : "kaw-tal'",
		"derivation" : "a primitive root",
		"def" : "properly, to cut off, i.e. (figuratively) put to death",
		"kjv" : "kill, slay"
	},
	"H6992" : {
		"lemma" : "קְטַל",
		"xlit" : "qᵉṭal",
		"pron" : "ket-al'",
		"derivation" : "(Aramaic) corresponding to H6991 (קָטַל)",
		"def" : "to kill",
		"kjv" : "slay"
	},
	"H6993" : {
		"lemma" : "קֶטֶל",
		"xlit" : "qeṭel",
		"pron" : "keh'-tel",
		"derivation" : "from H6991 (קָטַל)",
		"def" : "a violent death",
		"kjv" : "slaughter"
	},
	"H6994" : {
		"lemma" : "קָטֹן",
		"xlit" : "qâṭôn",
		"pron" : "kaw-tone'",
		"derivation" : "a primitive root (rather denominative from H6996 (קָטָן))",
		"def" : "to diminish, i.e. be (causatively, make) diminutive or (figuratively) of no account",
		"kjv" : "be a (make) small (thing), be not worthy"
	},
	"H6995" : {
		"lemma" : "קֹטֶן",
		"xlit" : "qôṭen",
		"pron" : "ko'-ten",
		"derivation" : "from H6994 (קָטֹן)",
		"def" : "a pettiness, i.e. the little finger",
		"kjv" : "little finger"
	},
	"H6996" : {
		"lemma" : "קָטָן",
		"xlit" : "qâṭân",
		"pron" : "kaw-tawn'",
		"derivation" : "or קָטֹן; from H6962 (קוּט)",
		"def" : "abbreviated, i.e. diminutive, literally (in quantity, size or number) or figuratively (in age or importance)",
		"kjv" : "least, less(-er), little (one), small(-est, one, quantity, thing), young(-er, -est)"
	},
	"H6997" : {
		"lemma" : "קָטָן",
		"xlit" : "Qâṭân",
		"pron" : "kaw-tawn'",
		"derivation" : "the same as H6996 (קָטָן); small",
		"def" : "Katan, an Israelite",
		"kjv" : "Hakkatan (including the article)"
	},
	"H6998" : {
		"lemma" : "קָטַף",
		"xlit" : "qâṭaph",
		"pron" : "kaw-taf'",
		"derivation" : "a primitive root",
		"def" : "to strip off",
		"kjv" : "crop off, cut down (up), pluck"
	},
	"H6999" : {
		"lemma" : "קָטַר",
		"xlit" : "qâṭar",
		"pron" : "kaw-tar'",
		"derivation" : "a primitive root (identical with through the idea of fumigation in a close place and perhaps thus driving out the occupants)",
		"def" : "to smoke, i.e. turn into fragrance by fire (especially as an act of worship)",
		"kjv" : "burn (incense, sacrifice) (upon), (altar for) incense, kindle, offer (incense, a sacrifice)"
	},
	"H7000" : {
		"lemma" : "קָטַר",
		"xlit" : "qâṭar",
		"pron" : "kaw-tar'",
		"derivation" : "a primitive root",
		"def" : "to inclose",
		"kjv" : "join"
	},
	"H7001" : {
		"lemma" : "קְטַר",
		"xlit" : "qᵉṭar",
		"pron" : "ket-ar'",
		"derivation" : "(Aramaic) from a root corresponding to H7000 (קָטַר)",
		"def" : "a knot (as tied up), i.e. (figuratively) a riddle; also a vertebra (as if a knot)",
		"kjv" : "doubt, joint"
	},
	"H7002" : {
		"lemma" : "קִטֵּר",
		"xlit" : "qiṭṭêr",
		"pron" : "kit-tare'",
		"derivation" : "from H6999 (קָטַר)",
		"def" : "perfume",
		"kjv" : "incense"
	},
	"H7003" : {
		"lemma" : "קִטְרוֹן",
		"xlit" : "Qiṭrôwn",
		"pron" : "kit-rone'",
		"derivation" : "from H6999 (קָטַר); fumigative",
		"def" : "Kitron, a place in Palestine",
		"kjv" : "Kitron"
	},
	"H7004" : {
		"lemma" : "קְטֹרֶת",
		"xlit" : "qᵉṭôreth",
		"pron" : "ket-o'-reth",
		"derivation" : "from H6999 (קָטַר)",
		"def" : "a fumigation",
		"kjv" : "(sweet) incense, perfume"
	},
	"H7005" : {
		"lemma" : "קַטָּת",
		"xlit" : "Qaṭṭâth",
		"pron" : "kat-tawth'",
		"derivation" : "from H6996 (קָטָן); littleness",
		"def" : "Kattath, a place in Palestine",
		"kjv" : "Kattath"
	},
	"H7006" : {
		"lemma" : "קָיָה",
		"xlit" : "qâyâh",
		"pron" : "kaw-yaw'",
		"derivation" : "a primitive root",
		"def" : "to vomit",
		"kjv" : "spue"
	},
	"H7007" : {
		"lemma" : "קַיִט",
		"xlit" : "qayiṭ",
		"pron" : "kah'-yit",
		"derivation" : "(Aramaic) corresponding to H7019 (קַיִץ)",
		"def" : "harvest",
		"kjv" : "summer"
	},
	"H7008" : {
		"lemma" : "קִיטוֹר",
		"xlit" : "qîyṭôwr",
		"pron" : "kee-tore'",
		"derivation" : "or קִיטֹר; from H6999 (קָטַר)",
		"def" : "a fume, i.e. cloud",
		"kjv" : "smoke, vapour"
	},
	"H7009" : {
		"lemma" : "קִים",
		"xlit" : "qîym",
		"pron" : "keem",
		"derivation" : "from H6965 (קוּם)",
		"def" : "an opponent (as rising against one), i.e. (collectively) enemies",
		"kjv" : "substance"
	},
	"H7010" : {
		"lemma" : "קְיָם",
		"xlit" : "qᵉyâm",
		"pron" : "keh-yawm'",
		"derivation" : "(Aramaic) from H6966 (קוּם)",
		"def" : "an edict (as arising in law)",
		"kjv" : "decree, statute"
	},
	"H7011" : {
		"lemma" : "קַיָּם",
		"xlit" : "qayâm",
		"pron" : "kah-yawm'",
		"derivation" : "(Aramaic) from H6966 (קוּם)",
		"def" : "permanent (as rising firmly)",
		"kjv" : "stedfast, sure"
	},
	"H7012" : {
		"lemma" : "קִימָה",
		"xlit" : "qîymâh",
		"pron" : "kee-maw'",
		"derivation" : "from H6965 (קוּם)",
		"def" : "an arising",
		"kjv" : "rising up"
	},
	"H7013" : {
		"lemma" : "קַיִן",
		"xlit" : "qayin",
		"pron" : "kah'-yin",
		"derivation" : "from H6969 (קוּן) in the original sense of fixity",
		"def" : "a lance (as striking fast)",
		"kjv" : "spear"
	},
	"H7014" : {
		"lemma" : "קַיִן",
		"xlit" : "Qayin",
		"pron" : "kah'-yin",
		"derivation" : "the same as H7013 (קַיִן) (with a play upon the affinity to H7069 (קָנָה))",
		"def" : "Kajin, the name of the first child, also of a place in Palestine, and of an Oriental tribe",
		"kjv" : "Cain, Kenite(-s)"
	},
	"H7015" : {
		"lemma" : "קִינָה",
		"xlit" : "qîynâh",
		"pron" : "kee-naw'",
		"derivation" : "from H6969 (קוּן)",
		"def" : "a dirge (as accompanied by beating the breasts or on instruments)",
		"kjv" : "lamentation"
	},
	"H7016" : {
		"lemma" : "קִינָה",
		"xlit" : "Qîynâh",
		"pron" : "kee-naw'",
		"derivation" : "the same as H7015 (קִינָה)",
		"def" : "Kinah, a place in Palestine",
		"kjv" : "Kinah"
	},
	"H7017" : {
		"lemma" : "קֵינִי",
		"xlit" : "Qêynîy",
		"pron" : "kay-nee'",
		"derivation" : "or קִינִי; (1 Chronicles 2:55), patronymic from H7014 (קַיִן)",
		"def" : "a Kenite or member of the tribe of Kajin",
		"kjv" : "Kenite"
	},
	"H7018" : {
		"lemma" : "קֵינָן",
		"xlit" : "Qêynân",
		"pron" : "kay-nawn'",
		"derivation" : "from the same as H7064 (קֵן); fixed",
		"def" : "Kenan, an antediluvian",
		"kjv" : "Cainan, Kenan"
	},
	"H7019" : {
		"lemma" : "קַיִץ",
		"xlit" : "qayits",
		"pron" : "kah'-yits",
		"derivation" : "from H6972 (קוּץ)",
		"def" : "harvest (as the crop), whether the product (grain or fruit) or the (dry) season",
		"kjv" : "summer (fruit, house)"
	},
	"H7020" : {
		"lemma" : "קִיצוֹן",
		"xlit" : "qîytsôwn",
		"pron" : "kee-tsone'",
		"derivation" : "from H6972 (קוּץ)",
		"def" : "terminal",
		"kjv" : "out-(utter-) most"
	},
	"H7021" : {
		"lemma" : "קִיקָיוֹן",
		"xlit" : "qîyqâyôwn",
		"pron" : "kee-kaw-yone'",
		"derivation" : "perhaps from H7006 (קָיָה)",
		"def" : "the gourd (as nauseous)",
		"kjv" : "gourd"
	},
	"H7022" : {
		"lemma" : "קִיקָלוֹן",
		"xlit" : "qîyqâlôwn",
		"pron" : "kee-kaw-lone'",
		"derivation" : "from H7036 (קָלוֹן)",
		"def" : "intense disgrace",
		"kjv" : "shameful spewing"
	},
	"H7023" : {
		"lemma" : "קִיר",
		"xlit" : "qîyr",
		"pron" : "keer",
		"derivation" : "or קִר; (Isaiah 22:5), or (feminine) קִירָה; from H6979 (קוּר)",
		"def" : "a wall (as built in a trench)",
		"kjv" : "[phrase] mason, side, town, [idiom] very, wall"
	},
	"H7024" : {
		"lemma" : "קִיר",
		"xlit" : "Qîyr",
		"pron" : "keer",
		"derivation" : "the same as H7023 (קִיר); fortress",
		"def" : "Kir, a place in Assyrian; also one in Moab",
		"kjv" : "Kir. Compare H7025 (קִיר חֶרֶשׂ)"
	},
	"H7025" : {
		"lemma" : "קִיר חֶרֶשׂ",
		"xlit" : "Qîyr Cheres",
		"pron" : "keer kheh'-res",
		"derivation" : "or (feminine of the latter word) קִיר חֲרֶשֶׂת; from H7023 (קִיר) and H2789 (חֶרֶשׂ); fortress of earthenware",
		"def" : "Kir-Cheres or Kir-Chareseth, a place in Moab",
		"kjv" : "Kir-haraseth, Kir-hareseth, Kirharesh, Kir-heres"
	},
	"H7026" : {
		"lemma" : "קֵירֹס",
		"xlit" : "Qêyrôç",
		"pron" : "kay-roce'",
		"derivation" : "or קֵרֹס; from the same as H7166 (קַרְסֹל); ankled",
		"def" : "Keros, one of the Nethinim",
		"kjv" : "Keros"
	},
	"H7027" : {
		"lemma" : "קִישׁ",
		"xlit" : "Qîysh",
		"pron" : "keesh",
		"derivation" : "from H6983 (קוֹשׁ); a bow",
		"def" : "Kish, the name of five Israelites",
		"kjv" : "Kish"
	},
	"H7028" : {
		"lemma" : "קִישׁוֹן",
		"xlit" : "Qîyshôwn",
		"pron" : "kee-shone'",
		"derivation" : "from H6983 (קוֹשׁ); winding",
		"def" : "Kishon, a river of Palestine",
		"kjv" : "Kishon, Kison"
	},
	"H7029" : {
		"lemma" : "קִישִׁי",
		"xlit" : "Qîyshîy",
		"pron" : "kee-shee'",
		"derivation" : "from H6983 (קוֹשׁ); bowed",
		"def" : "Kishi, an Israelite",
		"kjv" : "Kishi"
	},
	"H7030" : {
		"lemma" : "קִיתָרֹס",
		"xlit" : "qîythârôç",
		"pron" : "kee-thaw-roce'",
		"derivation" : "(Aramaic) of Greek origin",
		"def" : "a lyre",
		"kjv" : "harp"
	},
	"H7031" : {
		"lemma" : "קַל",
		"xlit" : "qal",
		"pron" : "kal",
		"derivation" : "contracted from H7043 (קָלַל)",
		"def" : "light; (by implication) rapid (also adverbial)",
		"kjv" : "light, swift(-ly)"
	},
	"H7032" : {
		"lemma" : "קָל",
		"xlit" : "qâl",
		"pron" : "kawl",
		"derivation" : "(Aramaic) corresponding to H6963 (קוֹל)",
		"def" : "{a voice or sound}",
		"kjv" : "sound, voice"
	},
	"H7033" : {
		"lemma" : "קָלָה",
		"xlit" : "qâlâh",
		"pron" : "kaw-law'",
		"derivation" : "a primitive root (rather identical with H7034 (קָלָה) through the idea of shrinkage by heat)",
		"def" : "to toast, i.e. scorch partially or slowly",
		"kjv" : "dried, loathsome, parch, roast"
	},
	"H7034" : {
		"lemma" : "קָלָה",
		"xlit" : "qâlâh",
		"pron" : "kaw-law'",
		"derivation" : "a primitive root",
		"def" : "to be light (as implied in rapid motion), but figuratively, only (be (causatively, hold) in contempt)",
		"kjv" : "base, contemn, despise, lightly esteem, set light, seem vile"
	},
	"H7035" : {
		"lemma" : "קָלַהּ",
		"xlit" : "qâlahh",
		"pron" : "kaw-lah'",
		"derivation" : "for H6950 (קָהַל)",
		"def" : "to assemble",
		"kjv" : "gather together"
	},
	"H7036" : {
		"lemma" : "קָלוֹן",
		"xlit" : "qâlôwn",
		"pron" : "kaw-lone'",
		"derivation" : "from H7034 (קָלָה)",
		"def" : "disgrace; (by implication) the pudenda",
		"kjv" : "confusion, dishonour, ignominy, reproach, shame"
	},
	"H7037" : {
		"lemma" : "קַלַּחַת",
		"xlit" : "qallachath",
		"pron" : "kal-lakh'-ath",
		"derivation" : "apparently but a form for H6747 (צַלַּחַת)",
		"def" : "a kettle",
		"kjv" : "caldron"
	},
	"H7038" : {
		"lemma" : "קָלַט",
		"xlit" : "qâlaṭ",
		"pron" : "kaw-lat'",
		"derivation" : "a primitive root",
		"def" : "to maim",
		"kjv" : "lacking in his parts"
	},
	"H7039" : {
		"lemma" : "קָלִי",
		"xlit" : "qâlîy",
		"pron" : "kaw-lee'",
		"derivation" : "or קָלִיא; from H7033 (קָלָה)",
		"def" : "roasted ears of grain",
		"kjv" : "parched corn"
	},
	"H7040" : {
		"lemma" : "קַלַּי",
		"xlit" : "Qallay",
		"pron" : "kal-lah'-ee",
		"derivation" : "from H7043 (קָלַל); frivolous",
		"def" : "Kallai, an Israelite",
		"kjv" : "Kallai"
	},
	"H7041" : {
		"lemma" : "קֵלָיָה",
		"xlit" : "Qêlâyâh",
		"pron" : "kay-law-yaw'",
		"derivation" : "from H7034 (קָלָה); insignificance",
		"def" : "Kelajah, an Israelite",
		"kjv" : "Kelaiah"
	},
	"H7042" : {
		"lemma" : "קְלִיטָא",
		"xlit" : "Qᵉlîyṭâʼ",
		"pron" : "kel-ee-taw'",
		"derivation" : "from H7038 (קָלַט); maiming",
		"def" : "Kelita, the name of three Israelites",
		"kjv" : "Kelita"
	},
	"H7043" : {
		"lemma" : "קָלַל",
		"xlit" : "qâlal",
		"pron" : "kaw-lal'",
		"derivation" : "a primitive root",
		"def" : "to be (causatively, make) light, literally (swift, small, sharp, etc.) or figuratively (easy, trifling, vile, etc.)",
		"kjv" : "abate, make bright, bring into contempt, (ac-) curse, despise, (be) ease(-y, -ier), (be a, make, make somewhat, move, seem a, set) light(-en, -er, -ly, -ly afflict, -ly esteem, thing), [idiom] slight(-ly), be swift(-er), (be, be more, make, re-) vile, whet"
	},
	"H7044" : {
		"lemma" : "קָלָל",
		"xlit" : "qâlâl",
		"pron" : "kaw-lawl'",
		"derivation" : "from H7043 (קָלַל)",
		"def" : "brightened (as if sharpened)",
		"kjv" : "burnished, polished"
	},
	"H7045" : {
		"lemma" : "קְלָלָה",
		"xlit" : "qᵉlâlâh",
		"pron" : "kel-aw-law'",
		"derivation" : "from H7043 (קָלַל)",
		"def" : "vilification",
		"kjv" : "(ac-) curse(-d, -ing)"
	},
	"H7046" : {
		"lemma" : "קָלַס",
		"xlit" : "qâlaç",
		"pron" : "kaw-las'",
		"derivation" : "a primitive root",
		"def" : "to disparage, i.e. ridicule",
		"kjv" : "mock, scoff, scorn"
	},
	"H7047" : {
		"lemma" : "קֶלֶס",
		"xlit" : "qeleç",
		"pron" : "keh'-les",
		"derivation" : "from H7046 (קָלַס)",
		"def" : "a laughing-stock",
		"kjv" : "derision"
	},
	"H7048" : {
		"lemma" : "קַלָּסָה",
		"xlit" : "qallâçâh",
		"pron" : "kal-law-saw'",
		"derivation" : "intensive from H7046 (קָלַס)",
		"def" : "ridicule",
		"kjv" : "mocking"
	},
	"H7049" : {
		"lemma" : "קָלַע",
		"xlit" : "qâlaʻ",
		"pron" : "kaw-lah'",
		"derivation" : "a primitive root",
		"def" : "to sling; also to carve (as if a circular motion, or into light forms)",
		"kjv" : "carve, sling (out)"
	},
	"H7050" : {
		"lemma" : "קֶלַע",
		"xlit" : "qelaʻ",
		"pron" : "keh'-lah",
		"derivation" : "from H7049 (קָלַע)",
		"def" : "a sling; also a (door) screen (as if slung across), or the valve (of the door) itself",
		"kjv" : "hanging, leaf, sling"
	},
	"H7051" : {
		"lemma" : "קַלָּע",
		"xlit" : "qallâʻ",
		"pron" : "kal-law'",
		"derivation" : "intensive from H7049 (קָלַע)",
		"def" : "a slinger",
		"kjv" : "slinger"
	},
	"H7052" : {
		"lemma" : "קְלֹקֵל",
		"xlit" : "qᵉlôqêl",
		"pron" : "kel-o-kale'",
		"derivation" : "from H7043 (קָלַל)",
		"def" : "insubstantial",
		"kjv" : "light"
	},
	"H7053" : {
		"lemma" : "קִלְּשׁוֹן",
		"xlit" : "qillᵉshôwn",
		"pron" : "kil-lesh-one'",
		"derivation" : "from an unused root meaning to prick",
		"def" : "a prong, i.e. hay-fork",
		"kjv" : "fork"
	},
	"H7054" : {
		"lemma" : "קָמָה",
		"xlit" : "qâmâh",
		"pron" : "kuw-maw'",
		"derivation" : "feminine of active participle of H6965 (קוּם)",
		"def" : "something that rises, i.e. a stalk of grain",
		"kjv" : "(standing) corn, grown up, stalk"
	},
	"H7055" : {
		"lemma" : "קְמוּאֵל",
		"xlit" : "Qᵉmûwʼêl",
		"pron" : "kem-oo-ale'",
		"derivation" : "from H6965 (קוּם) and H410 (אֵל); raised of God",
		"def" : "Kemuel, the name of a relative of Abraham, and of two Israelites",
		"kjv" : "Kemuel"
	},
	"H7056" : {
		"lemma" : "קָמוֹן",
		"xlit" : "Qâmôwn",
		"pron" : "kaw-mone'",
		"derivation" : "from H6965 (קוּם); an elevation",
		"def" : "Kamon, a place East of the Jordan",
		"kjv" : "Camon"
	},
	"H7057" : {
		"lemma" : "קִמּוֹשׁ",
		"xlit" : "qimmôwsh",
		"pron" : "kim-moshe'",
		"derivation" : "or קִימוֹשׁ; from an unused root meaning to sting",
		"def" : "a prickly plant",
		"kjv" : "nettle. Compare H7063 (קִמָּשׁוֹן)"
	},
	"H7058" : {
		"lemma" : "קֶמַח",
		"xlit" : "qemach",
		"pron" : "keh'-makh",
		"derivation" : "from an unused root probably meaning to grind",
		"def" : "flour",
		"kjv" : "flour, meal"
	},
	"H7059" : {
		"lemma" : "קָמַט",
		"xlit" : "qâmaṭ",
		"pron" : "kaw-mat'",
		"derivation" : "a primitive root",
		"def" : "to pluck, i.e. destroy",
		"kjv" : "cut down, fill with wrinkles"
	},
	"H7060" : {
		"lemma" : "קָמַל",
		"xlit" : "qâmal",
		"pron" : "kaw-mal'",
		"derivation" : "a primitive root",
		"def" : "to wither",
		"kjv" : "hew down, wither"
	},
	"H7061" : {
		"lemma" : "קָמַץ",
		"xlit" : "qâmats",
		"pron" : "kaw-mats'",
		"derivation" : "a primitive root",
		"def" : "to grasp with the hand",
		"kjv" : "take an handful"
	},
	"H7062" : {
		"lemma" : "קֹמֶץ",
		"xlit" : "qômets",
		"pron" : "ko'mets",
		"derivation" : "from H7061 (קָמַץ)",
		"def" : "a grasp, i.e. handful",
		"kjv" : "handful"
	},
	"H7063" : {
		"lemma" : "קִמָּשׁוֹן",
		"xlit" : "qimmâshôwn",
		"pron" : "kim-maw-shone'",
		"derivation" : "from the same as H7057 (קִמּוֹשׁ)",
		"def" : "a prickly plant",
		"kjv" : "thorn"
	},
	"H7064" : {
		"lemma" : "קֵן",
		"xlit" : "qên",
		"pron" : "kane",
		"derivation" : "contracted from H7077 (קָנַן)",
		"def" : "a nest (as fixed), sometimes including the nestlings; figuratively, a chamber or dwelling",
		"kjv" : "nest, room"
	},
	"H7065" : {
		"lemma" : "קָנָא",
		"xlit" : "qânâʼ",
		"pron" : "kaw-naw'",
		"derivation" : "a primitive root",
		"def" : "to be (causatively, make) zealous, i.e. (in a bad sense) jealous or envious",
		"kjv" : "(be) envy(-ious), be (move to, provoke to) jealous(-y), [idiom] very, (be) zeal(-ous)"
	},
	"H7066" : {
		"lemma" : "קְנָא",
		"xlit" : "qᵉnâʼ",
		"pron" : "ken-aw'",
		"derivation" : "(Aramaic) corresponding to H7069 (קָנָה)",
		"def" : "to purchase",
		"kjv" : "buy"
	},
	"H7067" : {
		"lemma" : "קַנָּא",
		"xlit" : "qannâʼ",
		"pron" : "kan-naw'",
		"derivation" : "from H7065 (קָנָא)",
		"def" : "jealous",
		"kjv" : "jealous. Compare H7072 (קַנּוֹא)"
	},
	"H7068" : {
		"lemma" : "קִנְאָה",
		"xlit" : "qinʼâh",
		"pron" : "kin-aw'",
		"derivation" : "from H7065 (קָנָא)",
		"def" : "jealousy or envy",
		"kjv" : "envy(-ied), jealousy, [idiom] sake, zeal"
	},
	"H7069" : {
		"lemma" : "קָנָה",
		"xlit" : "qânâh",
		"pron" : "kaw-naw'",
		"derivation" : "a primitive root",
		"def" : "to erect, i.e. create; by extension, to procure, especially by purchase (causatively, sell); by implication to own",
		"kjv" : "attain, buy(-er), teach to keep cattle, get, provoke to jealousy, possess(-or), purchase, recover, redeem, [idiom] surely, [idiom] verily"
	},
	"H7070" : {
		"lemma" : "קָנֶה",
		"xlit" : "qâneh",
		"pron" : "kaw-neh'",
		"derivation" : "from H7069 (קָנָה)",
		"def" : "a reed (as erect); by resemblance a rod (especially for measuring), shaft, tube, stem, the radius (of the arm), beam (of a steelyard)",
		"kjv" : "balance, bone, branch, calamus, cane, reed, [idiom] spearman, stalk"
	},
	"H7071" : {
		"lemma" : "קָנָה",
		"xlit" : "Qânâh",
		"pron" : "kaw-naw'",
		"derivation" : "feminine of H7070 (קָנֶה); reediness",
		"def" : "Kanah, the name of a stream and of a place in Palestine",
		"kjv" : "Kanah"
	},
	"H7072" : {
		"lemma" : "קַנּוֹא",
		"xlit" : "qannôwʼ",
		"pron" : "kan-no'",
		"derivation" : "for H7067 (קַנָּא)",
		"def" : "jealous or angry",
		"kjv" : "jealous"
	},
	"H7073" : {
		"lemma" : "קְנַז",
		"xlit" : "Qᵉnaz",
		"pron" : "ken-az'",
		"derivation" : "probably from an unused root meaning to hunt; hunter",
		"def" : "Kenaz, the name of an Edomite and of two Israelites",
		"kjv" : "Kenaz"
	},
	"H7074" : {
		"lemma" : "קְנִזִּי",
		"xlit" : "Qᵉnizzîy",
		"pron" : "ken-iz-zee'",
		"derivation" : "patronymic from H7073 (קְנַז)",
		"def" : "a Kenizzite or descendant of Kenaz",
		"kjv" : "Kenezite, Kenizzites"
	},
	"H7075" : {
		"lemma" : "קִנְיָן",
		"xlit" : "qinyân",
		"pron" : "kin-yawn'",
		"derivation" : "from H7069 (קָנָה)",
		"def" : "creation, i.e. (concretely) creatures; also acquisition, purchase, wealth",
		"kjv" : "getting, goods, [idiom] with money, riches, substance"
	},
	"H7076" : {
		"lemma" : "קִנָּמוֹן",
		"xlit" : "qinnâmôwn",
		"pron" : "kin-naw-mone'",
		"derivation" : "from an unused root (meaning to erect)",
		"def" : "cinnamon bark (as in upright rolls)",
		"kjv" : "cinnamon"
	},
	"H7077" : {
		"lemma" : "קָנַן",
		"xlit" : "qânan",
		"pron" : "kaw-nan'",
		"derivation" : "a primitive root; to erect; but used only as denominative from H7064 (קֵן)",
		"def" : "to nestle, i.e. build or occupy as anest",
		"kjv" : "make...nest"
	},
	"H7078" : {
		"lemma" : "קֶנֶץ",
		"xlit" : "qenets",
		"pron" : "keh'-nets",
		"derivation" : "from an unused root probably meaning to wrench",
		"def" : "perversion",
		"kjv" : "end"
	},
	"H7079" : {
		"lemma" : "קְנָת",
		"xlit" : "Qᵉnâth",
		"pron" : "ken-awth'",
		"derivation" : "from H7069 (קָנָה); possession",
		"def" : "Kenath, a place East of the Jordan",
		"kjv" : "Kenath"
	},
	"H7080" : {
		"lemma" : "קָסַם",
		"xlit" : "qâçam",
		"pron" : "kaw-sam'",
		"derivation" : "a primitive root",
		"def" : "properly, to distribute, i.e. determine by lot or magical scroll; by implication, to divine",
		"kjv" : "divine(-r, -ation), prudent, soothsayer, use (divination)"
	},
	"H7081" : {
		"lemma" : "קֶסֶם",
		"xlit" : "qeçem",
		"pron" : "keh'-sem",
		"derivation" : "from H7080 (קָסַם)",
		"def" : "a lot; also divination (including its fee), oracle",
		"kjv" : "(reward of) divination, divine sentence, witchcraft"
	},
	"H7082" : {
		"lemma" : "קָסַס",
		"xlit" : "qâçaç",
		"pron" : "kaw-sas'",
		"derivation" : "a primitive root",
		"def" : "to lop off",
		"kjv" : "cut off"
	},
	"H7083" : {
		"lemma" : "קֶסֶת",
		"xlit" : "qeçeth",
		"pron" : "keh'-seth",
		"derivation" : "from the same as H3563 (כּוֹס) (or as H7185 (קָשָׁה))",
		"def" : "properly, a cup, i.e. an ink-stand",
		"kjv" : "inkhorn"
	},
	"H7084" : {
		"lemma" : "קְעִילָה",
		"xlit" : "Qᵉʻîylâh",
		"pron" : "keh-ee-law'",
		"derivation" : "perhaps from H7049 (קָלַע) in the sense of enclosing; citadel",
		"def" : "Keilah, a place in Palestine",
		"kjv" : "Keilah"
	},
	"H7085" : {
		"lemma" : "קַעֲקַע",
		"xlit" : "qaʻăqaʻ",
		"pron" : "kah-ak-ah'",
		"derivation" : "from the same as H6970 (קוֹעַ)",
		"def" : "an incision or gash",
		"kjv" : "[phrase] mark"
	},
	"H7086" : {
		"lemma" : "קְעָרָה",
		"xlit" : "qᵉʻârâh",
		"pron" : "keh-aw-raw'",
		"derivation" : "probably from H7167 (קָרַע)",
		"def" : "a bowl (as cut out hollow)",
		"kjv" : "charger, dish"
	},
	"H7087" : {
		"lemma" : "קָפָא",
		"xlit" : "qâphâʼ",
		"pron" : "kaw-faw'",
		"derivation" : "a primitive root",
		"def" : "to shrink, i.e. thicken (as unracked wine, curdled milk, clouded sky, frozen water)",
		"kjv" : "congeal, curdle, dark, settle"
	},
	"H7088" : {
		"lemma" : "קָפַד",
		"xlit" : "qâphad",
		"pron" : "kaw-fad'",
		"derivation" : "a primitive root",
		"def" : "to contract, i.e. roll together",
		"kjv" : "cut off"
	},
	"H7089" : {
		"lemma" : "קְפָדָה",
		"xlit" : "qᵉphâdâh",
		"pron" : "kef-aw-daw'",
		"derivation" : "from H7088 (קָפַד)",
		"def" : "shrinking, i.e., terror",
		"kjv" : "destruction"
	},
	"H7090" : {
		"lemma" : "קִפּוֹד",
		"xlit" : "qippôwd",
		"pron" : "kip-pode'",
		"derivation" : "or קִפֹּדlemma קִכֹּד kaf, corrected to קִפֹּד; from H7088 (קָפַד)",
		"def" : "a species of bird, perhaps the bittern (from its contracted form)",
		"kjv" : "bittern"
	},
	"H7091" : {
		"lemma" : "קִפּוֹז",
		"xlit" : "qippôwz",
		"pron" : "kip-poze'",
		"derivation" : "from an unused root meaning to contract, i.e",
		"def" : "spring forward; an arrow-snake (as darting on its prey)",
		"kjv" : "great owl"
	},
	"H7092" : {
		"lemma" : "קָפַץ",
		"xlit" : "qâphats",
		"pron" : "kaw-fats'",
		"derivation" : "a primitive root",
		"def" : "to draw together, i.e. close; by implication, to leap (by contracting the limbs); specifically, to die (from gathering up the feet)",
		"kjv" : "shut (up), skip, stop, take out of the way"
	},
	"H7093" : {
		"lemma" : "קֵץ",
		"xlit" : "qêts",
		"pron" : "kates",
		"derivation" : "contracted from H7112 (קָצַץ)",
		"def" : "an extremity; adverbially (with prepositional prefix) after",
		"kjv" : "[phrase] after, (utmost) border, end, (in-) finite, [idiom] process"
	},
	"H7094" : {
		"lemma" : "קָצַב",
		"xlit" : "qâtsab",
		"pron" : "kaw-tsab'",
		"derivation" : "a primitive root",
		"def" : "to clip, or (generally) chop",
		"kjv" : "cut down, shorn"
	},
	"H7095" : {
		"lemma" : "קֶצֶב",
		"xlit" : "qetseb",
		"pron" : "keh'-tseb",
		"derivation" : "from H7094 (קָצַב)",
		"def" : "shape (as if cut out); base (as if there cut off)",
		"kjv" : "bottom, size"
	},
	"H7096" : {
		"lemma" : "קָצָה",
		"xlit" : "qâtsâh",
		"pron" : "kaw-tsaw'",
		"derivation" : "a primitive root",
		"def" : "to cut off; (figuratively) to destroy; (partially) to scrape off",
		"kjv" : "cut off, cut short, scrape (off)"
	},
	"H7097" : {
		"lemma" : "קָצֶה",
		"xlit" : "qâtseh",
		"pron" : "kaw-tseh'",
		"derivation" : "or (negative only) קֵצֶה; from H7096 (קָצָה); (used in a great variety of applications and idioms; compare H7093 (קֵץ))",
		"def" : "an extremity",
		"kjv" : "[idiom] after, border, brim, brink, edge, end, (in-) finite, frontier, outmost coast, quarter, shore, (out-) side, [idiom] some, ut(-ter-) most (part)"
	},
	"H7098" : {
		"lemma" : "קָצָה",
		"xlit" : "qâtsâh",
		"pron" : "kaw-tsaw'",
		"derivation" : "feminine of H7097 (קָצֶה); (used like H7097 (קָצֶה))",
		"def" : "a termination",
		"kjv" : "coast, corner, (selv-) edge, lowest, (uttermost) participle"
	},
	"H7099" : {
		"lemma" : "קֶצֶו",
		"xlit" : "qetsev",
		"pron" : "keh'-tsev",
		"derivation" : "and (feminine) קִצְוָה; from H7096 (קָצָה); (used like H7097 (קָצֶה), but with less variety)",
		"def" : "a limit",
		"kjv" : "end, edge, uttermost participle"
	},
	"H7100" : {
		"lemma" : "קֶצַח",
		"xlit" : "qetsach",
		"pron" : "keh'-tsakh",
		"derivation" : "from an unused root apparently meaning to incise",
		"def" : "fennelflower (from its pungency)",
		"kjv" : "fitches"
	},
	"H7101" : {
		"lemma" : "קָצִין",
		"xlit" : "qâtsîyn",
		"pron" : "kaw-tseen'",
		"derivation" : "from H7096 (קָצָה) in the sense of determining",
		"def" : "a magistrate (as deciding) or other leader",
		"kjv" : "captain, guide, prince, ruler. Compare H6278 (עֵת קָצִין)"
	},
	"H7102" : {
		"lemma" : "קְצִיעָה",
		"xlit" : "qᵉtsîyʻâh",
		"pron" : "kets-ee-aw'",
		"derivation" : "from H7106 (קָצַע)",
		"def" : "cassia (as peeled; plural the bark)",
		"kjv" : "cassia"
	},
	"H7103" : {
		"lemma" : "קְצִיעָה",
		"xlit" : "Qᵉtsîyʻâh",
		"pron" : "kets-ee-aw'",
		"derivation" : "the same as H7102 (קְצִיעָה)",
		"def" : "Ketsiah, a daughter of Job",
		"kjv" : "Kezia"
	},
	"H7104" : {
		"lemma" : "קְצִיץ",
		"xlit" : "Qᵉtsîyts",
		"pron" : "kets-eets'",
		"derivation" : "from H7112 (קָצַץ); abrupt",
		"def" : "Keziz, a valley in Palestine",
		"kjv" : "Keziz"
	},
	"H7105" : {
		"lemma" : "קָצִיר",
		"xlit" : "qâtsîyr",
		"pron" : "kaw-tseer'",
		"derivation" : "from H7114 (קָצַר)",
		"def" : "severed, i.e. harvest (as reaped), the crop, the time, the reaper, or figuratively; also a limb (of a tree, or simply foliage)",
		"kjv" : "bough, branch, harvest (man)"
	},
	"H7106" : {
		"lemma" : "קָצַע",
		"xlit" : "qâtsaʻ",
		"pron" : "kaw-tsah'",
		"derivation" : "a primitive root",
		"def" : "to strip off, i.e. (partially) scrape; by implication, to segregate (as an angle)",
		"kjv" : "cause to scrape, corner"
	},
	"H7107" : {
		"lemma" : "קָצַף",
		"xlit" : "qâtsaph",
		"pron" : "kaw-tsaf'",
		"derivation" : "a primitive root",
		"def" : "to crack off, i.e. (figuratively) burst out in rage",
		"kjv" : "(be) anger(-ry), displease, fret self, (provoke to) wrath (come), be wroth"
	},
	"H7108" : {
		"lemma" : "קְצַף",
		"xlit" : "qᵉtsaph",
		"pron" : "kets-af'",
		"derivation" : "(Aramaic) corresponding to H7107 (קָצַף)",
		"def" : "to become enraged",
		"kjv" : "be furious"
	},
	"H7109" : {
		"lemma" : "קְצַף",
		"xlit" : "qᵉtsaph",
		"pron" : "kets-af'",
		"derivation" : "(Aramaic) from H7108 (קְצַף)",
		"def" : "rage",
		"kjv" : "wrath"
	},
	"H7110" : {
		"lemma" : "קֶצֶף",
		"xlit" : "qetseph",
		"pron" : "keh'-tsef",
		"derivation" : "from H7107 (קָצַף)",
		"def" : "a splinter (as chipped off); figuratively, rage or strife",
		"kjv" : "foam, indignation, [idiom] sore, wrath"
	},
	"H7111" : {
		"lemma" : "קְצָפָה",
		"xlit" : "qᵉtsâphâh",
		"pron" : "kets-aw-faw'",
		"derivation" : "from H7107 (קָצַף)",
		"def" : "a fragment",
		"kjv" : "bark(-ed)"
	},
	"H7112" : {
		"lemma" : "קָצַץ",
		"xlit" : "qâtsats",
		"pron" : "kaw-tsats'",
		"derivation" : "a primitive root",
		"def" : "to chop off (literally or figuratively)",
		"kjv" : "cut (asunder, in pieces, in sunder, off), [idiom] utmost"
	},
	"H7113" : {
		"lemma" : "קְצַץ",
		"xlit" : "qᵉtsats",
		"pron" : "kets-ats'",
		"derivation" : "(Aramaic) corresponding to H7112 (קָצַץ)",
		"def" : "{to chop off (literally or figuratively)}",
		"kjv" : "cut off"
	},
	"H7114" : {
		"lemma" : "קָצַר",
		"xlit" : "qâtsar",
		"pron" : "kaw-tsar'",
		"derivation" : "a primitive root",
		"def" : "to dock off, i.e. curtail (transitive or intransitive, literal or figurative); especially to harvest (grass or grain)",
		"kjv" : "[idiom] at all, cut down, much discouraged, grieve, harvestman, lothe, mourn, reap(-er), (be, wax) short(-en, -er), straiten, trouble, vex"
	},
	"H7115" : {
		"lemma" : "קֹצֶר",
		"xlit" : "qôtser",
		"pron" : "ko'-tser",
		"derivation" : "from H7114 (קָצַר)",
		"def" : "shortness (of spirit), i.e. impatience",
		"kjv" : "anguish"
	},
	"H7116" : {
		"lemma" : "קָצֵר",
		"xlit" : "qâtsêr",
		"pron" : "kaw-tsare'",
		"derivation" : "from H7114 (קָצַר)",
		"def" : "short (whether in size, number, life, strength or temper)",
		"kjv" : "few, hasty, small, soon"
	},
	"H7117" : {
		"lemma" : "קְצָת",
		"xlit" : "qᵉtsâth",
		"pron" : "kets-awth'",
		"derivation" : "from H7096 (קָצָה)",
		"def" : "a termination (literally or figuratively); also (by implication) a portion; adverbially (with prepositional prefix) after",
		"kjv" : "end, part, [idiom] some"
	},
	"H7118" : {
		"lemma" : "קְצָת",
		"xlit" : "qᵉtsâth",
		"pron" : "kets-awth'",
		"derivation" : "(Aramaic) corresponding to H7117 (קְצָת)",
		"def" : "{a termination (literally or figuratively); also (by implication) a portion; adverbially (with prepositional prefix) after}",
		"kjv" : "end, partly"
	},
	"H7119" : {
		"lemma" : "קַר",
		"xlit" : "qar",
		"pron" : "kar",
		"derivation" : "contracted from an unused root meaning to chill",
		"def" : "cool; figuratively, quiet",
		"kjv" : "cold, excellent (from the margin)"
	},
	"H7120" : {
		"lemma" : "קֹר",
		"xlit" : "qôr",
		"pron" : "kore",
		"derivation" : "from the same as H7119 (קַר)",
		"def" : "cold",
		"kjv" : "cold"
	},
	"H7121" : {
		"lemma" : "קָרָא",
		"xlit" : "qârâʼ",
		"pron" : "kaw-raw'",
		"derivation" : "a primitive root (rather identical with H7122 (קָרָא) through the idea of accosting a person met)",
		"def" : "to call out to (i.e. properly, address by name, but used in a wide variety of applications)",
		"kjv" : "bewray (self), that are bidden, call (for, forth, self, upon), cry (unto), (be) famous, guest, invite, mention, (give) name, preach, (make) proclaim(-ation), pronounce, publish, read, renowned, say"
	},
	"H7122" : {
		"lemma" : "קָרָא",
		"xlit" : "qârâʼ",
		"pron" : "kaw-raw'",
		"derivation" : "a primitive root",
		"def" : "to encounter, whether accidentally or in a hostile manner",
		"kjv" : "befall, (by) chance, (cause to) come (upon), fall out, happen, meet"
	},
	"H7123" : {
		"lemma" : "קְרָא",
		"xlit" : "qᵉrâʼ",
		"pron" : "ker-aw'",
		"derivation" : "(Aramaic) corresponding to H7121 (קָרָא)",
		"def" : "{to call out to (i.e. properly, address by name, but used in a wide variety of applications)}",
		"kjv" : "call, cry, read"
	},
	"H7124" : {
		"lemma" : "קֹרֵא",
		"xlit" : "qôrêʼ",
		"pron" : "ko-ray'",
		"derivation" : "properly, active participle of H7121 (קָרָא)",
		"def" : "a caller, i.e. partridge (from its cry)",
		"kjv" : "partridge. See also H6981 (קוֹרֵא)"
	},
	"H7125" : {
		"lemma" : "קִרְאָה",
		"xlit" : "qirʼâh",
		"pron" : "keer-aw'",
		"derivation" : "from H7122 (קָרָא)",
		"def" : "an encountering, accidental, friendly or hostile (also adverbially, opposite)",
		"kjv" : "[idiom] against (he come), help, meet, seek, [idiom] to, [idiom] in the way"
	},
	"H7126" : {
		"lemma" : "קָרַב",
		"xlit" : "qârab",
		"pron" : "kaw-rab'",
		"derivation" : "a primitive root",
		"def" : "to approach (causatively, bring near) for whatever purpose",
		"kjv" : "(cause to) approach, (cause to) bring (forth, near), (cause to) come (near, nigh), (cause to) draw near (nigh), go (near), be at hand, join, be near, offer, present, produce, make ready, stand, take"
	},
	"H7127" : {
		"lemma" : "קְרֵב",
		"xlit" : "qᵉrêb",
		"pron" : "ker-abe'",
		"derivation" : "(Aramaic) corresponding to H7126 (קָרַב)",
		"def" : "{to approach (causatively, bring near) for whatever purpose}",
		"kjv" : "approach, come (near, nigh), draw near"
	},
	"H7128" : {
		"lemma" : "קְרָב",
		"xlit" : "qᵉrâb",
		"pron" : "ker-awb'",
		"derivation" : "from H7126 (קָרַב)",
		"def" : "hostile encounter",
		"kjv" : "battle, war"
	},
	"H7129" : {
		"lemma" : "קְרָב",
		"xlit" : "qᵉrâb",
		"pron" : "ker-awb'",
		"derivation" : "(Aramaic) corresponding to H7128 (קְרָב)",
		"def" : "{hostile encounter}",
		"kjv" : "war"
	},
	"H7130" : {
		"lemma" : "קֶרֶב",
		"xlit" : "qereb",
		"pron" : "keh'-reb",
		"derivation" : "from H7126 (קָרַב)",
		"def" : "properly, the nearest part, i.e. the center, whether literal, figurative or adverbial (especially with preposition)",
		"kjv" : "[idiom] among, [idiom] before, bowels, [idiom] unto charge, [phrase] eat (up), [idiom] heart, [idiom] him, [idiom] in, inward ([idiom] -ly, part, -s, thought), midst, [phrase] out of, purtenance, [idiom] therein, [idiom] through, [idiom] within self"
	},
	"H7131" : {
		"lemma" : "קָרֵב",
		"xlit" : "qârêb",
		"pron" : "kaw-rabe'",
		"derivation" : "from H7126 (קָרַב)",
		"def" : "near",
		"kjv" : "approach, come (near, nigh), draw near"
	},
	"H7132" : {
		"lemma" : "קְרָבָה",
		"xlit" : "qᵉrâbâh",
		"pron" : "ker-aw-baw'",
		"derivation" : "from H7126 (קָרַב)",
		"def" : "approach",
		"kjv" : "approaching, draw near"
	},
	"H7133" : {
		"lemma" : "קׇרְבָּן",
		"xlit" : "qorbân",
		"pron" : "kor-bawn'",
		"derivation" : "or קֻרְבָּן; from H7126 (קָרַב)",
		"def" : "something brought near the altar, i.e. a sacrificial present",
		"kjv" : "oblation, that is offered, offering"
	},
	"H7134" : {
		"lemma" : "קַרְדֹּם",
		"xlit" : "qardôm",
		"pron" : "kar-dome'",
		"derivation" : "perhaps from H6923 (קָדַם) in the sense of striking upon",
		"def" : "an axe",
		"kjv" : "ax"
	},
	"H7135" : {
		"lemma" : "קָרָה",
		"xlit" : "qârâh",
		"pron" : "kaw-raw'",
		"derivation" : "feminine of H7119 (קַר)",
		"def" : "coolness",
		"kjv" : "cold"
	},
	"H7136" : {
		"lemma" : "קָרָה",
		"xlit" : "qârâh",
		"pron" : "kaw-raw'",
		"derivation" : "a primitive root",
		"def" : "to light upon (chiefly by accident); causatively, to bring about; specifically, to impose timbers (for roof or floor)",
		"kjv" : "appoint, lay (make) beams, befall, bring, come (to pass unto), floor, (hap) was, happen (unto), meet, send good speed"
	},
	"H7137" : {
		"lemma" : "קָרֶה",
		"xlit" : "qâreh",
		"pron" : "kaw-reh'",
		"derivation" : "from H7136 (קָרָה)",
		"def" : "an (unfortunate) occurrence, i.e. some accidental (ceremonial) disqualification",
		"kjv" : "uncleanness that chanceth"
	},
	"H7138" : {
		"lemma" : "קָרוֹב",
		"xlit" : "qârôwb",
		"pron" : "kaw-robe'",
		"derivation" : "or קָרֹב; from H7126 (קָרַב)",
		"def" : "near (in place, kindred or time)",
		"kjv" : "allied, approach, at hand, [phrase] any of kin, kinsfold(-sman), (that is) near (of kin), neighbour, (that is) next, (them that come) nigh (at hand), more ready, short(-ly)"
	},
	"H7139" : {
		"lemma" : "קָרַח",
		"xlit" : "qârach",
		"pron" : "kaw-rakh'",
		"derivation" : "a primitive root",
		"def" : "to depilate",
		"kjv" : "make (self) bald"
	},
	"H7140" : {
		"lemma" : "קֶרַח",
		"xlit" : "qerach",
		"pron" : "keh'-rakh",
		"derivation" : "or קֹרַח; from H7139 (קָרַח)",
		"def" : "ice (as if bald, i.e. smooth); hence, hail; by resemblance, rock crystal",
		"kjv" : "crystal, frost, ice"
	},
	"H7141" : {
		"lemma" : "קֹרַח",
		"xlit" : "Qôrach",
		"pron" : "ko'rakh",
		"derivation" : "from H7139 (קָרַח); ice",
		"def" : "Korach, the name of two Edomites and three Israelites",
		"kjv" : "Korah"
	},
	"H7142" : {
		"lemma" : "קֵרֵחַ",
		"xlit" : "qêrêach",
		"pron" : "kay-ray'-akh",
		"derivation" : "from H7139 (קָרַח)",
		"def" : "bald (on the back of the head)",
		"kjv" : "bald (head)"
	},
	"H7143" : {
		"lemma" : "קָרֵחַ",
		"xlit" : "Qârêach",
		"pron" : "kaw-ray'-akh",
		"derivation" : "from H7139 (קָרַח); bald",
		"def" : "Kareach, an Israelite",
		"kjv" : "Careah, Kareah"
	},
	"H7144" : {
		"lemma" : "קׇרְחָה",
		"xlit" : "qorchâh",
		"pron" : "kor-khaw'",
		"derivation" : "or קׇרְחָא; (Ezekiel 27:31), from H7139 (קָרַח)",
		"def" : "baldness",
		"kjv" : "bald(-ness), [idiom] utterly"
	},
	"H7145" : {
		"lemma" : "קׇרְחִי",
		"xlit" : "Qorchîy",
		"pron" : "kor-khee'",
		"derivation" : "patronymic from H7141 (קֹרַח)",
		"def" : "a Korchite (collectively) or descendants of Korach",
		"kjv" : "Korahite, Korathite, sons of Kore, Korhite"
	},
	"H7146" : {
		"lemma" : "קָרַחַת",
		"xlit" : "qârachath",
		"pron" : "kaw-rakh'-ath",
		"derivation" : "from H7139 (קָרַח)",
		"def" : "a bald spot (on the back of the head); figuratively, a threadbare spot (on the back side of the cloth)",
		"kjv" : "bald head, bare within"
	},
	"H7147" : {
		"lemma" : "קְרִי",
		"xlit" : "qᵉrîy",
		"pron" : "ker-ee'",
		"derivation" : "from H7136 (קָרָה)",
		"def" : "hostile encounter",
		"kjv" : "contrary"
	},
	"H7148" : {
		"lemma" : "קָרִיא",
		"xlit" : "qârîyʼ",
		"pron" : "kaw-ree'",
		"derivation" : "from H7121 (קָרָא)",
		"def" : "called, i.e. select",
		"kjv" : "famous, renowned"
	},
	"H7149" : {
		"lemma" : "קִרְיָא",
		"xlit" : "qiryâʼ",
		"pron" : "keer-yaw'",
		"derivation" : "(Aramaic) or קִרְיָה; (Aramaic), corresponding to H7151 (קִרְיָה)",
		"def" : "{building; a city}",
		"kjv" : "city"
	},
	"H7150" : {
		"lemma" : "קְרִיאָה",
		"xlit" : "qᵉrîyʼâh",
		"pron" : "ker-ee-aw'",
		"derivation" : "from H7121 (קָרָא)",
		"def" : "a proclamation",
		"kjv" : "preaching"
	},
	"H7151" : {
		"lemma" : "קִרְיָה",
		"xlit" : "qiryâh",
		"pron" : "kir-yaw'",
		"derivation" : "from H7136 (קָרָה) in the sense of flooring, i.e",
		"def" : "building; a city",
		"kjv" : "city"
	},
	"H7152" : {
		"lemma" : "קְרִיּוֹת",
		"xlit" : "Qᵉrîyôwth",
		"pron" : "ker-ee-yoth'",
		"derivation" : "plural of H7151 (קִרְיָה); buildings",
		"def" : "Kerioth, the name of two places in Palestine",
		"kjv" : "Kerioth, Kirioth"
	},
	"H7153" : {
		"lemma" : "קִרְיַת אַרְבַּע",
		"xlit" : "Qiryath ʼArbaʻ",
		"pron" : "keer-yath' ar-bah'",
		"derivation" : "or (with the article interposed) קִרְיַת הָאַרְבַּע; (Nehemiah 11:25), from H7151 (קִרְיָה) and H704 (אַרְבַּע) or H702 (אַרְבַּע); city of Arba, or city of the four (giants)",
		"def" : "Kirjath-Arba or Kirjath-ha-Arba, a place in Palestine",
		"kjv" : "Kirjath-arba"
	},
	"H7154" : {
		"lemma" : "קִרְיַת בַּעַל",
		"xlit" : "Qiryath Baʻal",
		"pron" : "keer-yath' bah'-al",
		"derivation" : "from H7151 (קִרְיָה) and H1168 (בַּעַל); city of Baal",
		"def" : "Kirjath-Baal, a place in Palestine",
		"kjv" : "Kirjathbaal"
	},
	"H7155" : {
		"lemma" : "קִרְיַת חֻצוֹת",
		"xlit" : "Qiryath Chutsôwth",
		"pron" : "keer-yath' khoo-tsoth'",
		"derivation" : "from H7151 (קִרְיָה) and the feminine plural of H2351 (חוּץ); city of streets",
		"def" : "Kirjath-Chutsoth, a place in Moab",
		"kjv" : "Kirjath-huzoth"
	},
	"H7156" : {
		"lemma" : "קִרְיָתַיִם",
		"xlit" : "Qiryâthayim",
		"pron" : "keer-yaw-thah'-yim",
		"derivation" : "dual of H7151 (קִרְיָה); double city",
		"def" : "Kirjathaim, the name of two placed in Palestine",
		"kjv" : "Kiriathaim, Kirjathaim"
	},
	"H7157" : {
		"lemma" : "קִרְיַת יְעָרִים",
		"xlit" : "Qiryath Yᵉʻârîym",
		"pron" : "keer-yath' yeh-aw-reem'",
		"derivation" : "or (Jeremiah 26:20) with the article interposed; or (Joshua 18:28) simply the former part of the word; or קִרְיַת עָרִים; from H7151 (קִרְיָה) and the plural of H3293 (יַעַר) or H5892 (עִיר); city of forests, or city of towns",
		"def" : "Kirjath-Jearim or Kirjath-Arim, a place in Palestine",
		"kjv" : "Kirjath, Kirjath-jearim, Kirjath-arim"
	},
	"H7158" : {
		"lemma" : "קִרְיַת סַנָּה",
		"xlit" : "Qiryath Çannâh",
		"pron" : "keer-yath' san-naw'",
		"derivation" : "or קִרְיַת סֵפֶר; from H7151 (קִרְיָה) and a simpler feminine from the same as H5577 (סַנְסִן), or (for the latter name) H5612 (סֵפֶר); city of branches, or of a book",
		"def" : "Kirjath-Sannah or Kirjath-Sepher, a place in Palestine",
		"kjv" : "Kirjath-sannah, Kirjath-sepher"
	},
	"H7159" : {
		"lemma" : "קָרַם",
		"xlit" : "qâram",
		"pron" : "kaw-ram'",
		"derivation" : "a primitive root",
		"def" : "to cover",
		"kjv" : "cover"
	},
	"H7160" : {
		"lemma" : "קָרַן",
		"xlit" : "qâran",
		"pron" : "kaw-ran'",
		"derivation" : "a primitive root; to push or gore; used only as denominative from H7161 (קֶרֶן)",
		"def" : "to shoot out horns; figuratively, rays",
		"kjv" : "have horns, shine"
	},
	"H7161" : {
		"lemma" : "קֶרֶן",
		"xlit" : "qeren",
		"pron" : "keh'-ren",
		"derivation" : "from H7160 (קָרַן)",
		"def" : "a horn (as projecting); by implication, a flask, cornet; by resemblance. an elephant's tooth (i.e. ivory), a corner (of the altar), a peak (of a mountain), a ray (of light); figuratively, power",
		"kjv" : "[idiom] hill, horn"
	},
	"H7162" : {
		"lemma" : "קֶרֶן",
		"xlit" : "qeren",
		"pron" : "keh'-ren",
		"derivation" : "(Aramaic) corresponding to H7161 (קֶרֶן)",
		"def" : "a horn (literally or for sound)",
		"kjv" : "horn, cornet"
	},
	"H7163" : {
		"lemma" : "קֶרֶן הַפּוּךְ",
		"xlit" : "qeren hap-pûwk",
		"pron" : "keh'-ren hap-pook'",
		"derivation" : "from H7161 (קֶרֶן) and H6320 (פּוּךְ); horn of cosmetic",
		"def" : "Keren-hap-Puk, one of Job's daughters",
		"kjv" : "Keren-happuch"
	},
	"H7164" : {
		"lemma" : "קָרַס",
		"xlit" : "qâraç",
		"pron" : "kaw-ras'",
		"derivation" : "a primitive root; properly, to protrude; used only as denominative from H7165 (קֶרֶס) (for alliteration with H7167 (קָרַע))",
		"def" : "to hunch, i.e. be hump-backed",
		"kjv" : "stoop"
	},
	"H7165" : {
		"lemma" : "קֶרֶס",
		"xlit" : "qereç",
		"pron" : "keh'-res",
		"derivation" : "from H7164 (קָרַס)",
		"def" : "a knob or belaying-pin (from its swelling form)",
		"kjv" : "tache"
	},
	"H7166" : {
		"lemma" : "קַרְסֹל",
		"xlit" : "qarçôl",
		"pron" : "kar-sole'",
		"derivation" : "from H7164 (קָרַס)",
		"def" : "an ankle (as a protuberance or joint)",
		"kjv" : "foot"
	},
	"H7167" : {
		"lemma" : "קָרַע",
		"xlit" : "qâraʻ",
		"pron" : "kaw-rah'",
		"derivation" : "a primitive root",
		"def" : "to rend, literally or figuratively (revile, paint the eyes, as if enlarging them)",
		"kjv" : "cut out, rend, [idiom] surely, tear"
	},
	"H7168" : {
		"lemma" : "קֶרַע",
		"xlit" : "qeraʻ",
		"pron" : "keh'-rah",
		"derivation" : "from H7167 (קָרַע)",
		"def" : "a rag",
		"kjv" : "piece, rag"
	},
	"H7169" : {
		"lemma" : "קָרַץ",
		"xlit" : "qârats",
		"pron" : "kaw-rats'",
		"derivation" : "a primitive root",
		"def" : "to pinch, i.e. (partially) to bite the lips, blink the eyes (as a gesture of malice), or (fully) to squeeze off (a piece of clay in order to mould a vessel from it)",
		"kjv" : "form, move, wink"
	},
	"H7170" : {
		"lemma" : "קְרַץ",
		"xlit" : "qᵉrats",
		"pron" : "ker-ats'",
		"derivation" : "(Aramaic) corresponding to H7171 (קֶרֶץ) in the sense of a bit (to 'eat the morsels of' any one, i.e",
		"def" : "chew him up (figuratively) by slander",
		"kjv" : "[phrase] accuse"
	},
	"H7171" : {
		"lemma" : "קֶרֶץ",
		"xlit" : "qerets",
		"pron" : "keh'-rets",
		"derivation" : "from H7169 (קָרַץ)",
		"def" : "extirpation (as if by constriction)",
		"kjv" : "destruction"
	},
	"H7172" : {
		"lemma" : "קַרְקַע",
		"xlit" : "qarqaʻ",
		"pron" : "kar-kah'",
		"derivation" : "from H7167 (קָרַע)",
		"def" : "floor (as if a pavement of pieces or tesseroe), of abuilding or the sea",
		"kjv" : "bottom, ([idiom] one side of the) floor"
	},
	"H7173" : {
		"lemma" : "קַרְקַע",
		"xlit" : "Qarqaʻ",
		"pron" : "kar-kah'",
		"derivation" : "the same as H7172 (קַרְקַע); ground-floor",
		"def" : "Karka (with the article prefix), a place in Palestine",
		"kjv" : "Karkaa"
	},
	"H7174" : {
		"lemma" : "קַרְקֹר",
		"xlit" : "Qarqôr",
		"pron" : "kar-kore'",
		"derivation" : "from H6979 (קוּר); foundation",
		"def" : "Karkor, a place East of the Jordan",
		"kjv" : "Karkor"
	},
	"H7175" : {
		"lemma" : "קֶרֶשׁ",
		"xlit" : "qeresh",
		"pron" : "keh'-resh",
		"derivation" : "from an unused root meaning to split off",
		"def" : "a slab or plank; by implication, a deck of aship",
		"kjv" : "bench, board"
	},
	"H7176" : {
		"lemma" : "קֶרֶת",
		"xlit" : "qereth",
		"pron" : "keh'-reth",
		"derivation" : "from H7136 (קָרָה) in the sense of building",
		"def" : "a city",
		"kjv" : "city"
	},
	"H7177" : {
		"lemma" : "קַרְתָּה",
		"xlit" : "Qartâh",
		"pron" : "kar-taw'",
		"derivation" : "from H7176 (קֶרֶת); city",
		"def" : "Kartah, a place in Palestine",
		"kjv" : "Kartah"
	},
	"H7178" : {
		"lemma" : "קַרְתָּן",
		"xlit" : "Qartân",
		"pron" : "kar-tawn'",
		"derivation" : "from H7176 (קֶרֶת); city-plot",
		"def" : "Kartan, a place in Palestine",
		"kjv" : "Kartan"
	},
	"H7179" : {
		"lemma" : "קַשׁ",
		"xlit" : "qash",
		"pron" : "kash",
		"derivation" : "from H7197 (קָשַׁשׁ)",
		"def" : "straw (as dry)",
		"kjv" : "stubble"
	},
	"H7180" : {
		"lemma" : "קִשֻּׁא",
		"xlit" : "qishshuʼ",
		"pron" : "kish-shoo'",
		"derivation" : "from an unused root (meaning to be hard)",
		"def" : "a cucumber (from the difficulty of digestion)",
		"kjv" : "cucumber"
	},
	"H7181" : {
		"lemma" : "קָשַׁב",
		"xlit" : "qâshab",
		"pron" : "kaw-shab'",
		"derivation" : "a primitive root",
		"def" : "to prick up the ears, i.e. hearken",
		"kjv" : "attend, (cause to) hear(-ken), give heed, incline, mark (well), regard"
	},
	"H7182" : {
		"lemma" : "קֶשֶׁב",
		"xlit" : "qesheb",
		"pron" : "keh'-sheb",
		"derivation" : "from H7181 (קָשַׁב)",
		"def" : "a hearkening",
		"kjv" : "[idiom] diligently, hearing, much heed, that regarded"
	},
	"H7183" : {
		"lemma" : "קַשָּׁב",
		"xlit" : "qashshâb",
		"pron" : "kash-shawb'",
		"derivation" : "or קַשֻּׁב; from H7181 (קָשַׁב)",
		"def" : "hearkening",
		"kjv" : "attent(-ive)"
	},
	"H7184" : {
		"lemma" : "קָשָׂה",
		"xlit" : "qâsâh",
		"pron" : "kaw-saw'",
		"derivation" : "or קַשְׂוָה; from an unused root meaning to be round",
		"def" : "a jug (from its shape)",
		"kjv" : "cover, cup"
	},
	"H7185" : {
		"lemma" : "קָשָׁה",
		"xlit" : "qâshâh",
		"pron" : "kaw-shaw'",
		"derivation" : "a primitive root",
		"def" : "properly, to be dense, i.e. tough or severe (in various applications)",
		"kjv" : "be cruel, be fiercer, make grievous, be ((ask a), be in, have, seem, would) hard(-en, (labour), -ly, thing), be sore, (be, make) stiff(-en, (-necked))"
	},
	"H7186" : {
		"lemma" : "קָשֶׁה",
		"xlit" : "qâsheh",
		"pron" : "kaw-sheh'",
		"derivation" : "from H7185 (קָשָׁה)",
		"def" : "severe (in various applications)",
		"kjv" : "churlish, cruel, grievous, hard((-hearted), thing), heavy, [phrase] impudent, obstinate, prevailed, rough(-ly), sore, sorrowful, stiff(necked), stubborn, [phrase] in trouble"
	},
	"H7187" : {
		"lemma" : "קְשׁוֹט",
		"xlit" : "qᵉshôwṭ",
		"pron" : "kesh-ote'",
		"derivation" : "(Aramaic) or קשֹׁט; (Aramaic), corresponding to H7189 (קֹשֶׁט)",
		"def" : "fidelity",
		"kjv" : "truth"
	},
	"H7188" : {
		"lemma" : "קָשַׁח",
		"xlit" : "qâshach",
		"pron" : "kaw-shakh'",
		"derivation" : "a primitive root",
		"def" : "to be (causatively, make) unfeeling",
		"kjv" : "harden"
	},
	"H7189" : {
		"lemma" : "קֹשֶׁט",
		"xlit" : "qôsheṭ",
		"pron" : "ko'-shet",
		"derivation" : "or קֹשְׁטְ; from an unused root meaning to balance",
		"def" : "equity (as evenly weighed), i.e. reality",
		"kjv" : "truth"
	},
	"H7190" : {
		"lemma" : "קְשִׁי",
		"xlit" : "qᵉshîy",
		"pron" : "kesh-ee'",
		"derivation" : "from H7185 (קָשָׁה)",
		"def" : "obstinacy",
		"kjv" : "stubbornness"
	},
	"H7191" : {
		"lemma" : "קִשְׁיוֹן",
		"xlit" : "Qishyôwn",
		"pron" : "kish-yone'",
		"derivation" : "from H7190 (קְשִׁי); hard ground",
		"def" : "Kishjon, a place in Palestine",
		"kjv" : "Kishion, Keshon"
	},
	"H7192" : {
		"lemma" : "קְשִׂיטָה",
		"xlit" : "qᵉsîyṭâh",
		"pron" : "kes-ee-taw'",
		"derivation" : "from an unused root (probably meaning to weigh out)",
		"def" : "an ingot (as definitely estimated and stamped for a coin)",
		"kjv" : "piece of money (silver)"
	},
	"H7193" : {
		"lemma" : "קַשְׂקֶשֶׂת",
		"xlit" : "qasqeseth",
		"pron" : "kas-keh'-seth",
		"derivation" : "by reduplication from an unused root meaning to shale off as bark",
		"def" : "a scale (of a fish); hence a coat of mail (as composed of or covered with jointed plates of metal)",
		"kjv" : "mail, scale"
	},
	"H7194" : {
		"lemma" : "קָשַׁר",
		"xlit" : "qâshar",
		"pron" : "kaw-shar'",
		"derivation" : "a primitive root",
		"def" : "to tie, physically (gird, confine, compact) or mentally (in love, league)",
		"kjv" : "bind (up), (make a) conspire(-acy, -ator), join together, knit, stronger, work (treason)"
	},
	"H7195" : {
		"lemma" : "קֶשֶׁר",
		"xlit" : "qesher",
		"pron" : "keh'-sher",
		"derivation" : "from H7194 (קָשַׁר)",
		"def" : "an (unlawful) alliance",
		"kjv" : "confederacy, conspiracy, treason"
	},
	"H7196" : {
		"lemma" : "קִשֻּׁר",
		"xlit" : "qishshur",
		"pron" : "kish-shoor'",
		"derivation" : "from H7194 (קָשַׁר)",
		"def" : "an (ornamental) girdle (for women)",
		"kjv" : "attire, headband"
	},
	"H7197" : {
		"lemma" : "קָשַׁשׁ",
		"xlit" : "qâshash",
		"pron" : "kaw-shash'",
		"derivation" : "a primitive root; to become sapless through drought; used only as denominative from H7179 (קַשׁ)",
		"def" : "to forage for straw, stubble or wood; figuratively, to assemble",
		"kjv" : "gather (selves) (together)"
	},
	"H7198" : {
		"lemma" : "קֶשֶׁת",
		"xlit" : "qesheth",
		"pron" : "keh'-sheth",
		"derivation" : "from H7185 (קָשָׁה) in the original sense (of H6983 (קוֹשׁ)) of bending",
		"def" : "a bow, forshooting (hence, figuratively, strength) or the iris",
		"kjv" : "[idiom] arch(-er), [phrase] arrow, bow(-man, -shot)"
	},
	"H7199" : {
		"lemma" : "קַשָּׁת",
		"xlit" : "qashshâth",
		"pron" : "kash-shawth'",
		"derivation" : "intensive (as denominative) from H7198 (קֶשֶׁת)",
		"def" : "a bowman",
		"kjv" : "[idiom] archer. r"
	},
	"H7200" : {
		"lemma" : "רָאָה",
		"xlit" : "râʼâh",
		"pron" : "raw-aw'",
		"derivation" : "a primitive root",
		"def" : "to see, literally or figuratively (in numerous applications, direct and implied, transitive, intransitive and causative)",
		"kjv" : "advise self, appear, approve, behold, [idiom] certainly, consider, discern, (make to) enjoy, have experience, gaze, take heed, [idiom] indeed, [idiom] joyfully, lo, look (on, one another, one on another, one upon another, out, up, upon), mark, meet, [idiom] be near, perceive, present, provide, regard, (have) respect, (fore-, cause to, let) see(-r, -m, one another), shew (self), [idiom] sight of others, (e-) spy, stare, [idiom] surely, [idiom] think, view, visions"
	},
	"H7201" : {
		"lemma" : "רָאָה",
		"xlit" : "râʼâh",
		"pron" : "raw-aw'",
		"derivation" : "from H7200 (רָאָה)",
		"def" : "a bird of prey (probably the vulture, from its sharp sight)",
		"kjv" : "glede. Compare H1676 (דָּאָה)"
	},
	"H7202" : {
		"lemma" : "רָאֶה",
		"xlit" : "râʼeh",
		"pron" : "raw-eh'",
		"derivation" : "from H7200 (רָאָה)",
		"def" : "seeing, i.e. experiencing",
		"kjv" : "see"
	},
	"H7203" : {
		"lemma" : "רֹאֶה",
		"xlit" : "rôʼeh",
		"pron" : "ro-eh'",
		"derivation" : "active participle of H7200 (רָאָה)",
		"def" : "a seer (as often rendered); but also (abstractly) a vision",
		"kjv" : "vision"
	},
	"H7204" : {
		"lemma" : "רֹאֵה",
		"xlit" : "Rôʼêh",
		"pron" : "ro-ay'",
		"derivation" : "for H7203 (רֹאֶה); prophet",
		"def" : "Roeh, an Israelite",
		"kjv" : "Haroeh (including the article)"
	},
	"H7205" : {
		"lemma" : "רְאוּבֵן",
		"xlit" : "Rᵉʼûwbên",
		"pron" : "reh-oo-bane'",
		"derivation" : "from the imperative of H7200 (רָאָה) and H1121 (בֵּן); see ye a son",
		"def" : "Reuben, a son of Jacob",
		"kjv" : "Reuben"
	},
	"H7206" : {
		"lemma" : "רְאוּבֵנִי",
		"xlit" : "Rᵉʼûwbênîy",
		"pron" : "reh-oob-ay-nee'",
		"derivation" : "patronymic from H7205 (רְאוּבֵן)",
		"def" : "a Reubenite or descendant of Reuben",
		"kjv" : "children of Reuben, Reubenites"
	},
	"H7207" : {
		"lemma" : "רַאֲוָה",
		"xlit" : "raʼăvâh",
		"pron" : "rah-av-aw'",
		"derivation" : "from H7200 (רָאָה)",
		"def" : "sight, i.e. satisfaction",
		"kjv" : "behold"
	},
	"H7208" : {
		"lemma" : "רְאוּמָה",
		"xlit" : "Rᵉʼûwmâh",
		"pron" : "reh-oo-maw'",
		"derivation" : "feminine passive participle of H7213 (רָאַם); raised",
		"def" : "Reumah, a Syrian woman",
		"kjv" : "Reumah"
	},
	"H7209" : {
		"lemma" : "רְאִי",
		"xlit" : "rᵉʼîy",
		"pron" : "reh-ee'",
		"derivation" : "from H7200 (רָאָה)",
		"def" : "a mirror (as seen)",
		"kjv" : "looking glass"
	},
	"H7210" : {
		"lemma" : "רֳאִי",
		"xlit" : "rŏʼîy",
		"pron" : "ro-ee'",
		"derivation" : "from H7200 (רָאָה)",
		"def" : "sight, whether abstractly (vision) or concretely (a spectacle)",
		"kjv" : "gazingstock, look to, (that) see(-th)"
	},
	"H7211" : {
		"lemma" : "רְאָיָה",
		"xlit" : "Rᵉʼâyâh",
		"pron" : "reh-aw-yaw'",
		"derivation" : "from H7200 (רָאָה) and H3050 (יָהּ); Jah has seen",
		"def" : "Reajah, the name of three Israelites",
		"kjv" : "Reaia, Reaiah"
	},
	"H7212" : {
		"lemma" : "רְאִית",
		"xlit" : "rᵉʼîyth",
		"pron" : "reh-eeth'",
		"derivation" : "from H7200 (רָאָה)",
		"def" : "sight",
		"kjv" : "beholding"
	},
	"H7213" : {
		"lemma" : "רָאַם",
		"xlit" : "râʼam",
		"pron" : "raw-am'",
		"derivation" : "a primitive root",
		"def" : "to rise",
		"kjv" : "be lifted up"
	},
	"H7214" : {
		"lemma" : "רְאֵם",
		"xlit" : "rᵉʼêm",
		"pron" : "reh-ame'",
		"derivation" : "or רְאֵיםlemma רְאיֵם second vowel, corrected to רְאֵים; or רֵים; or רֵם; from H7213 (רָאַם)",
		"def" : "a wild bull (from its conspicuousness)",
		"kjv" : "unicorn"
	},
	"H7215" : {
		"lemma" : "רָאמָה",
		"xlit" : "râʼmâh",
		"pron" : "raw-maw'",
		"derivation" : "from H7213 (רָאַם)",
		"def" : "something high in value, i.e. perhaps coral",
		"kjv" : "coral"
	},
	"H7216" : {
		"lemma" : "רָאמוֹת",
		"xlit" : "Râʼmôwth",
		"pron" : "raw-moth'",
		"derivation" : "or רָאמֹתxlit Râmôth corrected to Râʼmôth; plural of H7215 (רָאמָה); heights",
		"def" : "Ramoth, the name of two places in Palestine",
		"kjv" : "Ramoth"
	},
	"H7217" : {
		"lemma" : "רֵאשׁ",
		"xlit" : "rêʼsh",
		"pron" : "raysh",
		"derivation" : "(Aramaic) corresponding to H7218 (רֹאשׁ)",
		"def" : "the head; figuratively, the sum",
		"kjv" : "chief, head, sum"
	},
	"H7218" : {
		"lemma" : "רֹאשׁ",
		"xlit" : "rôʼsh",
		"pron" : "roshe",
		"derivation" : "from an unused root apparently meaning to shake",
		"def" : "the head (as most easily shaken), whether literal or figurative (in many applications, of place, time, rank, itc.)",
		"kjv" : "band, beginning, captain, chapiter, chief(-est place, man, things), company, end, [idiom] every (man), excellent, first, forefront, (be-)head, height, (on) high(-est part, (priest)), [idiom] lead, [idiom] poor, principal, ruler, sum, top"
	},
	"H7219" : {
		"lemma" : "רֹאשׁ",
		"xlit" : "rôʼsh",
		"pron" : "roshe",
		"derivation" : "or רוֹשׁ; (Deuteronomy 32:32), apparently the same as H7218 (רֹאשׁ)",
		"def" : "a poisonous plant, probably the poppy (from its conspicuous head); generally poison (even of serpents)",
		"kjv" : "gall, hemlock, poison, venom"
	},
	"H7220" : {
		"lemma" : "רֹאשׁ",
		"xlit" : "Rôʼsh",
		"pron" : "roshe",
		"derivation" : "probably the same as H7218 (רֹאשׁ)",
		"def" : "Rosh, the name of an Israelite and of a foreign nation",
		"kjv" : "Rosh"
	},
	"H7221" : {
		"lemma" : "רִאשָׁה",
		"xlit" : "riʼshâh",
		"pron" : "ree-shaw'",
		"derivation" : "from the same as H7218 (רֹאשׁ)",
		"def" : "a beginning",
		"kjv" : "beginning"
	},
	"H7222" : {
		"lemma" : "רֹאשָׁה",
		"xlit" : "rôʼshâh",
		"pron" : "ro-shaw'",
		"derivation" : "feminine of H7218 (רֹאשׁ)",
		"def" : "the head",
		"kjv" : "head(-stone)"
	},
	"H7223" : {
		"lemma" : "רִאשׁוֹן",
		"xlit" : "riʼshôwn",
		"pron" : "ree-shone'",
		"derivation" : "or רִאשֹׁן; from H7221 (רִאשָׁה)",
		"def" : "first, in place, time or rank (as adjective or noun)",
		"kjv" : "ancestor, (that were) before(-time), beginning, eldest, first, fore(-father) (-most), former (thing), of old time, past"
	},
	"H7224" : {
		"lemma" : "רִאשֹׁנִי",
		"xlit" : "riʼshônîy",
		"pron" : "ree-sho-nee'",
		"derivation" : "from H7223 (רִאשׁוֹן)",
		"def" : "first",
		"kjv" : "first"
	},
	"H7225" : {
		"lemma" : "רֵאשִׁית",
		"xlit" : "rêʼshîyth",
		"pron" : "ray-sheeth'",
		"derivation" : "from the same as H7218 (רֹאשׁ)",
		"def" : "the first, in place, time, order or rank (specifically, a firstfruit)",
		"kjv" : "beginning, chief(-est), first(-fruits, part, time), principal thing"
	},
	"H7226" : {
		"lemma" : "רַאֲשֹׁת",
		"xlit" : "raʼăshôth",
		"pron" : "rah-ash-oth'",
		"derivation" : "from H7218 (רֹאשׁ)",
		"def" : "a pillow (being for the head)",
		"kjv" : "bolster"
	},
	"H7227" : {
		"lemma" : "רַב",
		"xlit" : "rab",
		"pron" : "rab",
		"derivation" : "by contracted from H7231 (רָבַב)",
		"def" : "abundant (in quantity, size, age, number, rank, quality)",
		"kjv" : "(in) abound(-undance, -ant, -antly), captain, elder, enough, exceedingly, full, great(-ly, man, one), increase, long (enough, (time)), (do, have) many(-ifold, things, a time), (ship-)master, mighty, more, (too, very) much, multiply(-tude), officer, often(-times), plenteous, populous, prince, process (of time), suffice(-lent)"
	},
	"H7228" : {
		"lemma" : "רַב",
		"xlit" : "rab",
		"pron" : "rab",
		"derivation" : "by contraction from H7232 (רָבַב); (or perhaps the same as H7227 (רַב))",
		"def" : "an archer",
		"kjv" : "archer"
	},
	"H7229" : {
		"lemma" : "רַב",
		"xlit" : "rab",
		"pron" : "rab",
		"derivation" : "(Aramaic) corresponding to H7227 (רַב)",
		"def" : "{abundant}",
		"kjv" : "captain, chief, great, lord, master, stout"
	},
	"H7230" : {
		"lemma" : "רֹב",
		"xlit" : "rôb",
		"pron" : "robe",
		"derivation" : "from H7231 (רָבַב)",
		"def" : "abundance (in any respect)",
		"kjv" : "abundance(-antly), all, [idiom] common (sort), excellent, great(-ly, -ness, number), huge, be increased, long, many, more in number, most, much, multitude, plenty(-ifully), [idiom] very (age)"
	},
	"H7231" : {
		"lemma" : "רָבַב",
		"xlit" : "râbab",
		"pron" : "raw-bab'",
		"derivation" : "a primitive root; (compare H7241 (רָבִיב)); also as denominative from H7233 (רְבָבָה)",
		"def" : "properly, to cast together , i.e. increase, especially in number; to multiply by the myriad",
		"kjv" : "increase, be many(-ifold), be more, multiply, ten thousands"
	},
	"H7232" : {
		"lemma" : "רָבַב",
		"xlit" : "râbab",
		"pron" : "raw-bab'",
		"derivation" : "a primitive root (rather identical with H7231 (רָבַב) through the idea of projection)",
		"def" : "to shoot an arrow",
		"kjv" : "shoot"
	},
	"H7233" : {
		"lemma" : "רְבָבָה",
		"xlit" : "rᵉbâbâh",
		"pron" : "reb-aw-baw'",
		"derivation" : "from H7231 (רָבַב)",
		"def" : "abundance (in number), i.e. (specifically) a myriad (whether definite or indefinite)",
		"kjv" : "many, million, [idiom] multiply, ten thousand"
	},
	"H7234" : {
		"lemma" : "רָבַד",
		"xlit" : "râbad",
		"pron" : "raw-bad'",
		"derivation" : "a primitive root",
		"def" : "to spread",
		"kjv" : "deck"
	},
	"H7235" : {
		"lemma" : "רָבָה",
		"xlit" : "râbâh",
		"pron" : "raw-baw'",
		"derivation" : "a primitive root",
		"def" : "to increase (in whatever respect)",
		"kjv" : "(bring in) abundance ([idiom] -antly), [phrase] archer (by mistake for H7232 (רָבַב)), be in authority, bring up, [idiom] continue, enlarge, excel, exceeding(-ly), be full of, (be, make) great(-er, -ly, [idiom] -ness), grow up, heap, increase, be long, (be, give, have, make, use) many (a time), (any, be, give, give the, have) more (in number), (ask, be, be so, gather, over, take, yield) much (greater, more), (make to) multiply, nourish, plenty(-eous), [idiom] process (of time), sore, store, thoroughly, very"
	},
	"H7236" : {
		"lemma" : "רְבָה",
		"xlit" : "rᵉbâh",
		"pron" : "reb-aw'",
		"derivation" : "(Aramaic) corresponding to H7235 (רָבָה)",
		"def" : "{to increase (in whatever respect)}",
		"kjv" : "make a great man, grow"
	},
	"H7237" : {
		"lemma" : "רַבָּה",
		"xlit" : "Rabbâh",
		"pron" : "rab-baw'",
		"derivation" : "feminine of H7227 (רַב); great",
		"def" : "Rabbah, the name of two places in Palestine, East and West",
		"kjv" : "Rabbah, Rabbath"
	},
	"H7238" : {
		"lemma" : "רְבוּ",
		"xlit" : "rᵉbûw",
		"pron" : "reb-oo'",
		"derivation" : "(Aramaic) from a root corresponding to H7235 (רָבָה)",
		"def" : "increase (of dignity)",
		"kjv" : "greatness, majesty"
	},
	"H7239" : {
		"lemma" : "רִבּוֹ",
		"xlit" : "ribbôw",
		"pron" : "rib-bo'",
		"derivation" : "from H7231 (רָבַב); or רִבּוֹא from H7231 (רָבַב)",
		"def" : "a myriad, i.e. indefinitely, large number",
		"kjv" : "great things, ten ((eight) -een, (for) -ty, [phrase] sixscore, [phrase] threescore, [idiom] twenty, (twen) -ty) thousand"
	},
	"H7240" : {
		"lemma" : "רִבּוֹ",
		"xlit" : "ribbôw",
		"pron" : "rib-bo'",
		"derivation" : "(Aramaic) corresponding to H7239 (רִבּוֹ)",
		"def" : "{a myriad, i.e. indefinitely, large number}",
		"kjv" : "[idiom] ten thousand times ten thousand"
	},
	"H7241" : {
		"lemma" : "רָבִיב",
		"xlit" : "râbîyb",
		"pron" : "raw-beeb'",
		"derivation" : "from H7231 (רָבַב)",
		"def" : "a rain (as an accumulation of drops)",
		"kjv" : "shower"
	},
	"H7242" : {
		"lemma" : "רָבִיד",
		"xlit" : "râbîyd",
		"pron" : "raw-beed'",
		"derivation" : "from H7234 (רָבַד)",
		"def" : "a collar (as spread around the neck)",
		"kjv" : "chain"
	},
	"H7243" : {
		"lemma" : "רְבִיעִי",
		"xlit" : "rᵉbîyʻîy",
		"pron" : "reb-ee-ee'",
		"derivation" : "or רְבִעִי; from H7251 (רָבַע)",
		"def" : "fourth; also (fractionally) a fourth",
		"kjv" : "foursquare, fourth (part)"
	},
	"H7244" : {
		"lemma" : "רְבִיעַי",
		"xlit" : "rᵉbîyʻay",
		"pron" : "reb-ee-ah'-ee",
		"derivation" : "(Aramaic) corresponding to H7243 (רְבִיעִי)",
		"def" : "{fourth; also (fractionally) a fourth}",
		"kjv" : "fourth"
	},
	"H7245" : {
		"lemma" : "רַבִּית",
		"xlit" : "Rabbîyth",
		"pron" : "rab-beeth'",
		"derivation" : "from H7231 (רָבַב); multitude",
		"def" : "Rabbith, a place in Palestine",
		"kjv" : "Rabbith"
	},
	"H7246" : {
		"lemma" : "רָבַךְ",
		"xlit" : "râbak",
		"pron" : "raw-bak'",
		"derivation" : "a primitive root",
		"def" : "to soak (bread in oil)",
		"kjv" : "baken, (that which is) fried"
	},
	"H7247" : {
		"lemma" : "רִבְלָה",
		"xlit" : "Riblâh",
		"pron" : "rib-law'",
		"derivation" : "from an unused root meaning to be fruitful; fertile",
		"def" : "Riblah, a place in Syria",
		"kjv" : "Riblah"
	},
	"H7248" : {
		"lemma" : "רַב־מָג",
		"xlit" : "Rab-Mâg",
		"pron" : "rab-mawg'",
		"derivation" : "from H7227 (רַב) and a foreign word for a Magian; chief Magian",
		"def" : "Rab-Mag, a Babylonian official",
		"kjv" : "Rab-mag"
	},
	"H7249" : {
		"lemma" : "רַב־סָרִיס",
		"xlit" : "Rab-Çârîyç",
		"pron" : "rab-saw-reece'",
		"derivation" : "from H7227 (רַב) and a foreign word for a eunuch; chief chamberlain",
		"def" : "Rab-Saris, a Babylonian official",
		"kjv" : "Rab-saris"
	},
	"H7250" : {
		"lemma" : "רָבַע",
		"xlit" : "râbaʻ",
		"pron" : "raw-bah'",
		"derivation" : "a primitive root",
		"def" : "to squat or lie out flat, i.e. (specifically) in copulation",
		"kjv" : "let gender, lie down"
	},
	"H7251" : {
		"lemma" : "רָבַע",
		"xlit" : "râbaʻ",
		"pron" : "raw-bah'",
		"derivation" : "a primitive root (rather identical with H7250 (רָבַע) through the idea of sprawling 'at all fours' (or possibly the reverse is the order of deriv.); compare H702 (אַרְבַּע)); properly, to be four (sided); used only as denominative of H7253 (רֶבַע)",
		"def" : "to be quadrate",
		"kjv" : "(four-) square(-d)"
	},
	"H7252" : {
		"lemma" : "רֶבַע",
		"xlit" : "rebaʻ",
		"pron" : "reh'-bah",
		"derivation" : "from H7250 (רָבַע)",
		"def" : "prostration (for sleep)",
		"kjv" : "lying down"
	},
	"H7253" : {
		"lemma" : "רֶבַע",
		"xlit" : "rebaʻ",
		"pron" : "reh'-bah",
		"derivation" : "from H7251 (רָבַע)",
		"def" : "a fourth (part or side)",
		"kjv" : "fourth part, side, square"
	},
	"H7254" : {
		"lemma" : "רֶבַע",
		"xlit" : "Rebaʻ",
		"pron" : "reh'-bah",
		"derivation" : "the same as H7253 (רֶבַע)",
		"def" : "Reba, a Midianite",
		"kjv" : "Reba"
	},
	"H7255" : {
		"lemma" : "רֹבַע",
		"xlit" : "rôbaʻ",
		"pron" : "ro'-bah",
		"derivation" : "from H7251 (רָבַע)",
		"def" : "a quarter",
		"kjv" : "fourth participle"
	},
	"H7256" : {
		"lemma" : "רִבֵּעַ",
		"xlit" : "ribbêaʻ",
		"pron" : "rib-bay'-ah",
		"derivation" : "from H7251 (רָבַע)",
		"def" : "a descendant of the fourth generation, i.e. great great grandchild",
		"kjv" : "fourth"
	},
	"H7257" : {
		"lemma" : "רָבַץ",
		"xlit" : "râbats",
		"pron" : "raw-bats'",
		"derivation" : "a primitive root",
		"def" : "to crouch (on all four legs folded, like a recumbent animal); be implication, to recline, repose, brood, lurk, imbed",
		"kjv" : "crouch (down), fall down, make a fold, lay, (cause to, make to) lie (down), make to rest, sit"
	},
	"H7258" : {
		"lemma" : "רֵבֶץ",
		"xlit" : "rêbets",
		"pron" : "reh'-bets",
		"derivation" : "from H7257 (רָבַץ)",
		"def" : "a couch or place of repose",
		"kjv" : "where each lay, lie down in, resting place"
	},
	"H7259" : {
		"lemma" : "רִבְקָה",
		"xlit" : "Ribqâh",
		"pron" : "rib-kaw'",
		"derivation" : "from an unused root probably meaning to clog by tying up the fetlock; fettering (by beauty)",
		"def" : "Ribkah, the wife of Isaac",
		"kjv" : "Rebekah"
	},
	"H7260" : {
		"lemma" : "רַבְרַב",
		"xlit" : "rabrab",
		"pron" : "rab-rab'",
		"derivation" : "(Aramaic) from H7229 (רַב)",
		"def" : "huge (in size); domineering (in character)",
		"kjv" : "(very) great (things)"
	},
	"H7261" : {
		"lemma" : "רַבְרְבָן",
		"xlit" : "rabrᵉbân",
		"pron" : "rab-reb-awn'",
		"derivation" : "(Aramaic) from H7260 (רַבְרַב)",
		"def" : "a magnate",
		"kjv" : "lord, prince"
	},
	"H7262" : {
		"lemma" : "רַבְשָׁקֵה",
		"xlit" : "Rabshâqêh",
		"pron" : "rab-shaw-kay'",
		"derivation" : "from H7227 (רַב) and H8248 (שָׁקָה); chief butler",
		"def" : "Rabshakeh, a Babylonian official",
		"kjv" : "Rabshakeh"
	},
	"H7263" : {
		"lemma" : "רֶגֶב",
		"xlit" : "regeb",
		"pron" : "reh'-gheb",
		"derivation" : "from an unused root meaning to pile together",
		"def" : "a lump of clay",
		"kjv" : "clod"
	},
	"H7264" : {
		"lemma" : "רָגַז",
		"xlit" : "râgaz",
		"pron" : "raw-gaz'",
		"derivation" : "a primitive root",
		"def" : "to quiver (with any violent emotion, especially anger or fear)",
		"kjv" : "be afraid, stand in awe, disquiet, fall out, fret, move, provoke, quake, rage, shake, tremble, trouble, be wroth"
	},
	"H7265" : {
		"lemma" : "רְגַז",
		"xlit" : "rᵉgaz",
		"pron" : "reg-az'",
		"derivation" : "(Aramaic) corresponding to H7264 (רָגַז)",
		"def" : "{to quiver (with any violent emotion, especially anger or fear)}",
		"kjv" : "provoke unto wrath"
	},
	"H7266" : {
		"lemma" : "רְגַז",
		"xlit" : "rᵉgaz",
		"pron" : "reg-az'",
		"derivation" : "(Aramaic) from H7265 (רְגַז)",
		"def" : "violent anger",
		"kjv" : "rage"
	},
	"H7267" : {
		"lemma" : "רֹגֶז",
		"xlit" : "rôgez",
		"pron" : "ro'-ghez",
		"derivation" : "from H7264 (רָגַז)",
		"def" : "commotion, restlessness (of a horse), crash (of thunder), disquiet, anger",
		"kjv" : "fear, noise, rage, trouble(-ing), wrath"
	},
	"H7268" : {
		"lemma" : "רַגָּז",
		"xlit" : "raggâz",
		"pron" : "rag-gawz'",
		"derivation" : "intensive from H7264 (רָגַז)",
		"def" : "timid",
		"kjv" : "trembling"
	},
	"H7269" : {
		"lemma" : "רׇגְזָה",
		"xlit" : "rogzâh",
		"pron" : "rog-zaw'",
		"derivation" : "feminine of H7267 (רֹגֶז)",
		"def" : "trepidation",
		"kjv" : "trembling"
	},
	"H7270" : {
		"lemma" : "רָגַל",
		"xlit" : "râgal",
		"pron" : "raw-gal'",
		"derivation" : "a primitive root;  also as denominative from H7272 (רֶגֶל)",
		"def" : "to walk along; but only in specifically, applications, to reconnoiter, to be atale-bearer (i.e. slander); to lead about",
		"kjv" : "backbite, search, slander, (e-) spy (out), teach to go, view"
	},
	"H7271" : {
		"lemma" : "רְגַל",
		"xlit" : "rᵉgal",
		"pron" : "reg-al'",
		"derivation" : "(Aramaic) corresponding to H7272 (רֶגֶל)",
		"def" : "{a foot, a step; by euphemistically the pudenda}",
		"kjv" : "foot"
	},
	"H7272" : {
		"lemma" : "רֶגֶל",
		"xlit" : "regel",
		"pron" : "reh'-gel",
		"derivation" : "from H7270 (רָגַל)",
		"def" : "a foot (as used in walking); by implication, a step; by euphemistically the pudenda",
		"kjv" : "[idiom] be able to endure, [idiom] according as, [idiom] after, [idiom] coming, [idiom] follow, (broken-)foot(-ed, -stool), [idiom] great toe, [idiom] haunt, [idiom] journey, leg, [phrase] piss, [phrase] possession, time"
	},
	"H7273" : {
		"lemma" : "רַגְלִי",
		"xlit" : "raglîy",
		"pron" : "rag-lee'",
		"derivation" : "from H7272 (רֶגֶל)",
		"def" : "a footman (soldier)",
		"kjv" : "(on) foot(-man)"
	},
	"H7274" : {
		"lemma" : "רֹגְלִים",
		"xlit" : "Rôgᵉlîym",
		"pron" : "ro-gel-eem'",
		"derivation" : "plural of active participle of H7270 (רָגַל); fullers (as tramping the cloth in washing)",
		"def" : "Rogelim, a place East of the Jordan",
		"kjv" : "Rogelim"
	},
	"H7275" : {
		"lemma" : "רָגַם",
		"xlit" : "râgam",
		"pron" : "raw-gam'",
		"derivation" : "a primitive root (compare H7263 (רֶגֶב), H7321 (רוּעַ), H7551 (רָקַם))",
		"def" : "to cast together (stones), i.e. to lapidate",
		"kjv" : "[idiom] certainly, stone"
	},
	"H7276" : {
		"lemma" : "רֶגֶם",
		"xlit" : "Regem",
		"pron" : "reh'-gem",
		"derivation" : "from H7275 (רָגַם); stoneheap",
		"def" : "Regem, an Israelite",
		"kjv" : "Regem"
	},
	"H7277" : {
		"lemma" : "רִגְמָה",
		"xlit" : "rigmâh",
		"pron" : "rig-maw'",
		"derivation" : "feminine of the same as H7276 (רֶגֶם)",
		"def" : "a pile (of stones), i.e. (figuratively) a throng",
		"kjv" : "council"
	},
	"H7278" : {
		"lemma" : "רֶגֶם מֶלֶךְ",
		"xlit" : "Regem Melek",
		"pron" : "reh'-gem meh'-lek",
		"derivation" : "from H7276 (רֶגֶם) and H4428 (מֶלֶךְ); king's heap",
		"def" : "Regem-Melek, an Israelite",
		"kjv" : "Regem-melech"
	},
	"H7279" : {
		"lemma" : "רָגַן",
		"xlit" : "râgan",
		"pron" : "raw-gan'",
		"derivation" : "a primitive root",
		"def" : "to grumble, i.e. rebel",
		"kjv" : "murmur"
	},
	"H7280" : {
		"lemma" : "רָגַע",
		"xlit" : "râgaʻ",
		"pron" : "raw-gah'",
		"derivation" : "a primitive root",
		"def" : "properly, to toss violently and suddenly (the sea with waves, the skin with boils); figuratively (in a favorable manner) to settle, i.e. quiet; specifically, to wink (from the motion of the eye-lids)",
		"kjv" : "break, divide, find ease, be a moment, (cause, give, make to) rest, make suddenly"
	},
	"H7281" : {
		"lemma" : "רֶגַע",
		"xlit" : "regaʻ",
		"pron" : "reh'-gah",
		"derivation" : "from H7280 (רָגַע)",
		"def" : "a wink (of the eyes), i.e. a very short space of time",
		"kjv" : "instant, moment, space, suddenly"
	},
	"H7282" : {
		"lemma" : "רָגֵעַ",
		"xlit" : "râgêaʻ",
		"pron" : "raw-gay'-ah",
		"derivation" : "from H7280 (רָגַע)",
		"def" : "restful, i.e. peaceable",
		"kjv" : "that are quiet"
	},
	"H7283" : {
		"lemma" : "רָגַשׁ",
		"xlit" : "râgash",
		"pron" : "raw-gash'",
		"derivation" : "a primitive root",
		"def" : "to be tumultuous",
		"kjv" : "rage"
	},
	"H7284" : {
		"lemma" : "רְגַשׁ",
		"xlit" : "rᵉgash",
		"pron" : "reg-ash'",
		"derivation" : "(Aramaic) corresponding to H7283 (רָגַשׁ)",
		"def" : "to gather tumultuously",
		"kjv" : "assemble (together)"
	},
	"H7285" : {
		"lemma" : "רֶגֶשׁ",
		"xlit" : "regesh",
		"pron" : "reh'-ghesh",
		"derivation" : "or (feminine) רִגְשָׁה; from H7283 (רָגַשׁ)",
		"def" : "a tumultuous crowd",
		"kjv" : "company, insurrection"
	},
	"H7286" : {
		"lemma" : "רָדַד",
		"xlit" : "râdad",
		"pron" : "raw-dad'",
		"derivation" : "a primitive root",
		"def" : "to tread in pieces, i.e. (figuratively) to conquer, or (specifically) to overlay",
		"kjv" : "spend, spread, subdue"
	},
	"H7287" : {
		"lemma" : "רָדָה",
		"xlit" : "râdâh",
		"pron" : "raw-daw'",
		"derivation" : "a primitive root",
		"def" : "to tread down, i.e. subjugate; specifically, to crumble off",
		"kjv" : "(come to, make to) have dominion, prevail against, reign, (bear, make to) rule,(-r, over), take"
	},
	"H7288" : {
		"lemma" : "רַדַּי",
		"xlit" : "Radday",
		"pron" : "rad-dah'-ee",
		"derivation" : "intensive from H7287 (רָדָה); domineering",
		"def" : "Raddai, an Israelite",
		"kjv" : "Raddai"
	},
	"H7289" : {
		"lemma" : "רָדִיד",
		"xlit" : "râdîyd",
		"pron" : "raw-deed'",
		"derivation" : "from H7286 (רָדַד) in the sense of spreading",
		"def" : "a veil (as expanded)",
		"kjv" : "vail, veil"
	},
	"H7290" : {
		"lemma" : "רָדַם",
		"xlit" : "râdam",
		"pron" : "raw-dam'",
		"derivation" : "a primitive root",
		"def" : "to stun, i.e. stupefy (with sleep or death)",
		"kjv" : "(be fast a-, be in a deep, cast into a dead, that) sleep(-er, -eth)"
	},
	"H7291" : {
		"lemma" : "רָדַף",
		"xlit" : "râdaph",
		"pron" : "raw-daf'",
		"derivation" : "a primitive root",
		"def" : "to run after (usually with hostile intent; figuratively (of time) gone by)",
		"kjv" : "chase, put to flight, follow (after, on), hunt, (be under) persecute(-ion, -or), pursue(-r)"
	},
	"H7292" : {
		"lemma" : "רָהַב",
		"xlit" : "râhab",
		"pron" : "raw-hab'",
		"derivation" : "a primitive root",
		"def" : "to urge severely, i.e. (figuratively) importune, embolden, capture, act insolently",
		"kjv" : "overcome, behave self proudly, make sure, strengthen"
	},
	"H7293" : {
		"lemma" : "רַהַב",
		"xlit" : "rahab",
		"pron" : "rah'-hab",
		"derivation" : "from H7292 (רָהַב)",
		"def" : "bluster(-er)",
		"kjv" : "proud, strength"
	},
	"H7294" : {
		"lemma" : "רַהַב",
		"xlit" : "Rahab",
		"pron" : "rah'-hab",
		"derivation" : "the same as H7293 (רַהַב)",
		"def" : "Rahab (i.e. boaster), an epithet of Egypt",
		"kjv" : "Rahab"
	},
	"H7295" : {
		"lemma" : "רָהָב",
		"xlit" : "râhâb",
		"pron" : "raw-hawb'",
		"derivation" : "from H7292 (רָהַב)",
		"def" : "insolent",
		"kjv" : "proud"
	},
	"H7296" : {
		"lemma" : "רֹהָב",
		"xlit" : "rôhâb",
		"pron" : "ro'-hab",
		"derivation" : "from H7292 (רָהַב)",
		"def" : "pride",
		"kjv" : "strength"
	},
	"H7297" : {
		"lemma" : "רָהָה",
		"xlit" : "râhâh",
		"pron" : "raw-haw'",
		"derivation" : "a primitive root",
		"def" : "to fear",
		"kjv" : "be afraid"
	},
	"H7298" : {
		"lemma" : "רַהַט",
		"xlit" : "rahaṭ",
		"pron" : "rah'-hat",
		"derivation" : "from an unused root apparently meaning to hollow out",
		"def" : "a channel or watering-box; by resemblance a ringlet of hair (as forming parallel lines)",
		"kjv" : "gallery, gutter, trough"
	},
	"H7299" : {
		"lemma" : "רֵו",
		"xlit" : "rêv",
		"pron" : "rave",
		"derivation" : "(Aramaic) from a root corresponding to H7200 (רָאָה)",
		"def" : "aspect",
		"kjv" : "form"
	},
	"H7300" : {
		"lemma" : "רוּד",
		"xlit" : "rûwd",
		"pron" : "rood",
		"derivation" : "a primitive root",
		"def" : "to tramp about, i.e. ramble (free or disconsolate)",
		"kjv" : "have the dominion, be lord, mourn, rule"
	},
	"H7301" : {
		"lemma" : "רָוָה",
		"xlit" : "râvâh",
		"pron" : "raw-vaw'",
		"derivation" : "a primitive root",
		"def" : "to slake the thirst (occasionally of other appetites)",
		"kjv" : "bathe, make drunk, (take the) fill, satiate, (abundantly) satisfy, soak, water (abundantly)"
	},
	"H7302" : {
		"lemma" : "רָוֶה",
		"xlit" : "râveh",
		"pron" : "raw-veh'",
		"derivation" : "from H7301 (רָוָה)",
		"def" : "sated (with drink)",
		"kjv" : "drunkenness, watered"
	},
	"H7303" : {
		"lemma" : "רוֹהֲגָה",
		"xlit" : "Rôwhăgâh",
		"pron" : "ro-hag-aw'",
		"derivation" : "from an unused root probably meaning to cry out; outcry",
		"def" : "Rohagah, an Israelite",
		"kjv" : "Rohgah"
	},
	"H7304" : {
		"lemma" : "רָוַח",
		"xlit" : "râvach",
		"pron" : "raw-vakh'",
		"derivation" : "a primitive root (identical with H7306 (רוּחַ))",
		"def" : "properly, to breathe freely, i.e. revive; by implication, to have ample room",
		"kjv" : "be refreshed, large"
	},
	"H7305" : {
		"lemma" : "רֶוַח",
		"xlit" : "revach",
		"pron" : "reh'-vakh",
		"derivation" : "from H7304 (רָוַח)",
		"def" : "room, literally (an interval) or figuratively (deliverance)",
		"kjv" : "enlargement, space"
	},
	"H7306" : {
		"lemma" : "רוּחַ",
		"xlit" : "rûwach",
		"pron" : "roo'-akh",
		"derivation" : "a primitive root",
		"def" : "properly, to blow, i.e. breathe; only (literally) to smell or (by implication, perceive (figuratively, to anticipate, enjoy)",
		"kjv" : "accept, smell, [idiom] touch, make of quick understanding"
	},
	"H7307" : {
		"lemma" : "רוּחַ",
		"xlit" : "rûwach",
		"pron" : "roo'-akh",
		"derivation" : "from H7306 (רוּחַ)",
		"def" : "wind; by resemblance breath, i.e. a sensible (or even violent) exhalation; figuratively, life, anger, unsubstantiality; by extension, a region of the sky; by resemblance spirit, but only of a rational being (including its expression and functions)",
		"kjv" : "air, anger, blast, breath, [idiom] cool, courage, mind, [idiom] quarter, [idiom] side, spirit(-ual), tempest, [idiom] vain, (whirl-) wind(-y)"
	},
	"H7308" : {
		"lemma" : "רוּחַ",
		"xlit" : "rûwach",
		"pron" : "roo'-akh",
		"derivation" : "(Aramaic) corresponding to H7307 (רוּחַ)",
		"def" : "{wind; by resemblance breath, i.e. a sensible (or even violent) exhalation; figuratively, life, anger, unsubstantiality; by extension, a region of the sky; by resemblance spirit, but only of a rational being (including its expression and functions)}",
		"kjv" : "mind, spirit, wind"
	},
	"H7309" : {
		"lemma" : "רְוָחָה",
		"xlit" : "rᵉvâchâh",
		"pron" : "rev-aw-khaw'",
		"derivation" : "feminine of H7305 (רֶוַח)",
		"def" : "relief",
		"kjv" : "breathing, respite"
	},
	"H7310" : {
		"lemma" : "רְוָיָה",
		"xlit" : "rᵉvâyâh",
		"pron" : "rev-aw-yaw'",
		"derivation" : "from H7301 (רָוָה)",
		"def" : "satisfaction",
		"kjv" : "runneth over, wealthy"
	},
	"H7311" : {
		"lemma" : "רוּם",
		"xlit" : "rûwm",
		"pron" : "room",
		"derivation" : "a primitive root",
		"def" : "to be high actively, to rise or raise (in various applications, literally or figuratively)",
		"kjv" : "bring up, exalt (self), extol, give, go up, haughty, heave (up), (be, lift up on, make on, set up on, too) high(-er, one), hold up, levy, lift(-er) up, (be) lofty, ([idiom] a-) loud, mount up, offer (up), [phrase] presumptuously, (be) promote(-ion), proud, set up, tall(-er), take (away, off, up), breed worms"
	},
	"H7312" : {
		"lemma" : "רוּם",
		"xlit" : "rûwm",
		"pron" : "room",
		"derivation" : "or רֻם; from H7311 (רוּם)",
		"def" : "(literally) elevation or (figuratively) elation",
		"kjv" : "haughtiness, height, [idiom] high"
	},
	"H7313" : {
		"lemma" : "רוּם",
		"xlit" : "rûwm",
		"pron" : "room",
		"derivation" : "(Aramaic) corresponding to H7311 (רוּם)",
		"def" : "{to be high actively, to rise or raise (in various applications, literally or figuratively)}",
		"kjv" : "extol, lift up (self), set up"
	},
	"H7314" : {
		"lemma" : "רוּם",
		"xlit" : "rûwm",
		"pron" : "room",
		"derivation" : "(Aramaic) from H7313 (רוּם)",
		"def" : "(literally) altitude",
		"kjv" : "height"
	},
	"H7315" : {
		"lemma" : "רוֹם",
		"xlit" : "rôwm",
		"pron" : "rome",
		"derivation" : "from H7311 (רוּם)",
		"def" : "elevation, i.e. (adverbially) aloft",
		"kjv" : "on high"
	},
	"H7316" : {
		"lemma" : "רוּמָה",
		"xlit" : "Rûwmâh",
		"pron" : "roo-maw'",
		"derivation" : "from H7311 (רוּם); height",
		"def" : "Rumah, a place in Palestine",
		"kjv" : "Rumah"
	},
	"H7317" : {
		"lemma" : "רוֹמָה",
		"xlit" : "rôwmâh",
		"pron" : "ro-maw'",
		"derivation" : "feminine of H7315 (רוֹם)",
		"def" : "elation, i.e. (adverbially) proudly",
		"kjv" : "haughtily"
	},
	"H7318" : {
		"lemma" : "רוֹמָם",
		"xlit" : "rôwmâm",
		"pron" : "ro-mawm'",
		"derivation" : "from H7426 (רָמַם)",
		"def" : "exaltation, i.e. (figuratively and specifically) praise",
		"kjv" : "be extolled"
	},
	"H7319" : {
		"lemma" : "רוֹמְמָה",
		"xlit" : "rôwmᵉmâh",
		"pron" : "ro-mem-aw'",
		"derivation" : "feminine active participle of H7426 (רָמַם)",
		"def" : "exaltation, i.e. praise",
		"kjv" : "high"
	},
	"H7320" : {
		"lemma" : "רוֹמַמְתִּי עֶזֶר",
		"xlit" : "Rôwmamtîy ʻEzer",
		"pron" : "ro-mam'-tee eh'-zer",
		"derivation" : "(or Romam from H7311 (רוּם) and H5828 (עֵזֶר); I have raised up a help",
		"def" : "Romamti-Ezer, an Israelite",
		"kjv" : "Romamti-ezer"
	},
	"H7321" : {
		"lemma" : "רוּעַ",
		"xlit" : "rûwaʻ",
		"pron" : "roo-ah'",
		"derivation" : "a primitive root",
		"def" : "to mar (especially by breaking); figuratively, to split the ears (with sound), i.e. shout (for alarm or joy)",
		"kjv" : "blow an alarm, cry (alarm, aloud, out), destroy, make a joyful noise, smart, shout (for joy), sound an alarm, triumph"
	},
	"H7322" : {
		"lemma" : "רוּף",
		"xlit" : "rûwph",
		"pron" : "roof",
		"derivation" : "a primitive root",
		"def" : "properly, to triturate (in a mortar), i.e. (figuratively) to agitate (by concussion)",
		"kjv" : "tremble"
	},
	"H7323" : {
		"lemma" : "רוּץ",
		"xlit" : "rûwts",
		"pron" : "roots",
		"derivation" : "a primitive root",
		"def" : "to run (for whatever reason, especially to rush)",
		"kjv" : "break down, divide speedily, footman, guard, bring hastily, (make) run (away, through), post"
	},
	"H7324" : {
		"lemma" : "רוּק",
		"xlit" : "rûwq",
		"pron" : "rook",
		"derivation" : "a primitive root",
		"def" : "to pour out (literally or figuratively), i.e. empty",
		"kjv" : "[idiom] arm, cast out, draw (out), (make) empty, pour forth (out)"
	},
	"H7325" : {
		"lemma" : "רוּר",
		"xlit" : "rûwr",
		"pron" : "roor",
		"derivation" : "a primitive root",
		"def" : "to slaver (with spittle), i.e. (by analogy) to emit afluid (ulcerous or natural)",
		"kjv" : "run"
	},
	"H7326" : {
		"lemma" : "רוּשׁ",
		"xlit" : "rûwsh",
		"pron" : "roosh",
		"derivation" : "a primitive root",
		"def" : "to be destitute",
		"kjv" : "lack, needy, (make self) poor (man)"
	},
	"H7327" : {
		"lemma" : "רוּת",
		"xlit" : "Rûwth",
		"pron" : "rooth",
		"derivation" : "probably for H7468 (רְעוּת); friend",
		"def" : "Ruth, a Moabitess",
		"kjv" : "Ruth"
	},
	"H7328" : {
		"lemma" : "רָז",
		"xlit" : "râz",
		"pron" : "rawz",
		"derivation" : "(Aramaic) from an unused root probably meaning",
		"def" : "to attenuate, i.e. (figuratively) hide; a mystery",
		"kjv" : "secret"
	},
	"H7329" : {
		"lemma" : "רָזָה",
		"xlit" : "râzâh",
		"pron" : "raw-zaw'",
		"derivation" : "a primitive root",
		"def" : "to emaciate, i.e. make (become) thin (literally or figuratively)",
		"kjv" : "famish, wax lean"
	},
	"H7330" : {
		"lemma" : "רָזֶה",
		"xlit" : "râzeh",
		"pron" : "raw-zeh'",
		"derivation" : "from H7329 (רָזָה)",
		"def" : "thin",
		"kjv" : "lean"
	},
	"H7331" : {
		"lemma" : "רְזוֹן",
		"xlit" : "Rᵉzôwn",
		"pron" : "rez-one'",
		"derivation" : "from H7336 (רָזַן); prince",
		"def" : "Rezon, a Syrian",
		"kjv" : "Rezon"
	},
	"H7332" : {
		"lemma" : "רָזוֹן",
		"xlit" : "râzôwn",
		"pron" : "raw-zone'",
		"derivation" : "from H7329 (רָזָה)",
		"def" : "thinness",
		"kjv" : "leanness, [idiom] scant"
	},
	"H7333" : {
		"lemma" : "רָזוֹן",
		"xlit" : "râzôwn",
		"pron" : "raw-zone'",
		"derivation" : "from H7336 (רָזַן)",
		"def" : "a dignitary",
		"kjv" : "prince"
	},
	"H7334" : {
		"lemma" : "רָזִי",
		"xlit" : "râzîy",
		"pron" : "raw-zee'",
		"derivation" : "from H7329 (רָזָה)",
		"def" : "thinness",
		"kjv" : "leanness"
	},
	"H7335" : {
		"lemma" : "רָזַם",
		"xlit" : "râzam",
		"pron" : "raw-zam'",
		"derivation" : "a primitive root",
		"def" : "to twinkle the eye (in mockery)",
		"kjv" : "wink"
	},
	"H7336" : {
		"lemma" : "רָזַן",
		"xlit" : "râzan",
		"pron" : "raw-zan'",
		"derivation" : "a primitive root",
		"def" : "probably to be heavy, i.e. (figuratively) honorable",
		"kjv" : "prince, ruler"
	},
	"H7337" : {
		"lemma" : "רָחַב",
		"xlit" : "râchab",
		"pron" : "raw-khab'",
		"derivation" : "a primitive root",
		"def" : "to broaden (intransitive or transitive, literal or figurative)",
		"kjv" : "be an en-(make) large(-ing), make room, make (open) wide"
	},
	"H7338" : {
		"lemma" : "רַחַב",
		"xlit" : "rachab",
		"pron" : "rakh'-ab",
		"derivation" : "from H7337 (רָחַב)",
		"def" : "a width",
		"kjv" : "breadth, broad place"
	},
	"H7339" : {
		"lemma" : "רְחֹב",
		"xlit" : "rᵉchôb",
		"pron" : "rekh-obe'",
		"derivation" : "or רְחוֹב; from H7337 (רָחַב)",
		"def" : "a width, i.e. (concretely) avenue or area",
		"kjv" : "broad place (way), street. See also H1050 (בֵּית רְחוֹב)"
	},
	"H7340" : {
		"lemma" : "רְחֹב",
		"xlit" : "Rᵉchôb",
		"pron" : "rekh-obe'",
		"derivation" : "or רְחוֹב; the same as H7339 (רְחֹב)",
		"def" : "Rechob, the name of a place in Syria, also of a Syrian and an Israelite",
		"kjv" : "Rehob"
	},
	"H7341" : {
		"lemma" : "רֹחַב",
		"xlit" : "rôchab",
		"pron" : "ro'-khab",
		"derivation" : "from H7337 (רָחַב)",
		"def" : "width (literally or figuratively)",
		"kjv" : "breadth, broad, largeness, thickness, wideness"
	},
	"H7342" : {
		"lemma" : "רָחָב",
		"xlit" : "râchâb",
		"pron" : "raw-khawb'",
		"derivation" : "from H7337 (רָחַב)",
		"def" : "roomy, in any (or every) direction, literally or figuratively",
		"kjv" : "broad, large, at liberty, proud, wide"
	},
	"H7343" : {
		"lemma" : "רָחָב",
		"xlit" : "Râchâb",
		"pron" : "raw-khawb'",
		"derivation" : "the same as H7342 (רָחָב); proud",
		"def" : "Rachab, a Canaanitess",
		"kjv" : "Rahab"
	},
	"H7344" : {
		"lemma" : "רְחֹבוֹת",
		"xlit" : "Rᵉchôbôwth",
		"pron" : "rekh-o-both'",
		"derivation" : "or רְחֹבֹת; plural of H7339 (רְחֹב); streets",
		"def" : "Rechoboth, a place in Assyria and one in Palestine",
		"kjv" : "Rehoboth"
	},
	"H7345" : {
		"lemma" : "רְחַבְיָה",
		"xlit" : "Rᵉchabyâh",
		"pron" : "rekh-ab-yaw'",
		"derivation" : "or רְחַבְיָהוּ; from H7337 (רָחַב) and H3050 (יָהּ); Jah has enlarged",
		"def" : "Rechabjah, an Israelite",
		"kjv" : "Rehabiah"
	},
	"H7346" : {
		"lemma" : "רְחַבְעָם",
		"xlit" : "Rᵉchabʻâm",
		"pron" : "rekh-ab-awm'",
		"derivation" : "from H7337 (רָחַב) and H5971 (עַם); a people has enlarged",
		"def" : "Rechabam, an Israelite king",
		"kjv" : "Rehoboam"
	},
	"H7347" : {
		"lemma" : "רֵחֶה",
		"xlit" : "rêcheh",
		"pron" : "ray-kheh'",
		"derivation" : "from an unused root meaning to pulverize",
		"def" : "a mill-stone",
		"kjv" : "mill (stone)"
	},
	"H7348" : {
		"lemma" : "רְחוּם",
		"xlit" : "Rᵉchûwm",
		"pron" : "rekh-oom'",
		"derivation" : "a form of H7349 (רַחוּם)",
		"def" : "Rechum, the name of a Persian and of three Israelites",
		"kjv" : "Rehum"
	},
	"H7349" : {
		"lemma" : "רַחוּם",
		"xlit" : "rachûwm",
		"pron" : "rakh-oom'",
		"derivation" : "from H7355 (רָחַם)",
		"def" : "compassionate",
		"kjv" : "full of compassion, merciful"
	},
	"H7350" : {
		"lemma" : "רָחוֹק",
		"xlit" : "râchôwq",
		"pron" : "raw-khoke'",
		"derivation" : "or רָחֹק; from H7368 (רָחַק)",
		"def" : "remote, literally or figuratively, of place or time; specifically, precious; often used adverbially (with preposition)",
		"kjv" : "(a-) far (abroad, off), long ago, of old, space, great while to come"
	},
	"H7351" : {
		"lemma" : "רְחִיט",
		"xlit" : "rᵉchîyṭ",
		"pron" : "rekh-eet'",
		"derivation" : "from the same as H7298 (רַהַט)",
		"def" : "a panel (as resembling a trough)",
		"kjv" : "rafter"
	},
	"H7352" : {
		"lemma" : "רַחִיק",
		"xlit" : "rachîyq",
		"pron" : "rakh-eek'",
		"derivation" : "(Aramaic) corresponding to H7350 (רָחוֹק)",
		"def" : "{remote, literally or figuratively, of place or time; specifically, precious; often used adverbially (with preposition)}",
		"kjv" : "far"
	},
	"H7353" : {
		"lemma" : "רָחֵל",
		"xlit" : "râchêl",
		"pron" : "raw-kale'",
		"derivation" : "from an unused root meaning to journey",
		"def" : "a ewe (the females being the predominant element of a flock) (as a good traveller)",
		"kjv" : "ewe, sheep"
	},
	"H7354" : {
		"lemma" : "רָחֵל",
		"xlit" : "Râchêl",
		"pron" : "raw-khale'",
		"derivation" : "the same as H7353 (רָחֵל)",
		"def" : "Rachel, a wife of Jacob",
		"kjv" : "Rachel"
	},
	"H7355" : {
		"lemma" : "רָחַם",
		"xlit" : "râcham",
		"pron" : "raw-kham'",
		"derivation" : "a primitive root",
		"def" : "to fondle; by implication, to love, especially to compassionate",
		"kjv" : "have compassion (on, upon), love, (find, have, obtain, shew) mercy(-iful, on, upon), (have) pity, Ruhamah, [idiom] surely"
	},
	"H7356" : {
		"lemma" : "רַחַם",
		"xlit" : "racham",
		"pron" : "rakh'-am",
		"derivation" : "from H7355 (רָחַם)",
		"def" : "compassion (in the plural); by extension, the womb (as cherishing the fetus); by implication, a maiden",
		"kjv" : "bowels, compassion, damsel, tender love, (great, tender) mercy, pity, womb"
	},
	"H7357" : {
		"lemma" : "רַחַם",
		"xlit" : "Racham",
		"pron" : "rakh'-am",
		"derivation" : "the same as H7356 (רַחַם); pity",
		"def" : "Racham, an Israelite",
		"kjv" : "Raham"
	},
	"H7358" : {
		"lemma" : "רֶחֶם",
		"xlit" : "rechem",
		"pron" : "rekh'-em",
		"derivation" : "from H7355 (רָחַם); (compare H7356 (רַחַם))",
		"def" : "the womb",
		"kjv" : "matrix, womb"
	},
	"H7359" : {
		"lemma" : "רְחֵם",
		"xlit" : "rᵉchêm",
		"pron" : "rekh-ame'",
		"derivation" : "(Aramaic) corresponding to H7356 (רַחַם)",
		"def" : "(plural) pity",
		"kjv" : "mercy"
	},
	"H7360" : {
		"lemma" : "רָחָם",
		"xlit" : "râchâm",
		"pron" : "raw-khawm'",
		"derivation" : "or (feminine) רָחָמָה; from H7355 (רָחַם)",
		"def" : "a kind of vulture (supposed to be tender towards its young)",
		"kjv" : "gier-eagle"
	},
	"H7361" : {
		"lemma" : "רַחֲמָה",
		"xlit" : "rachămâh",
		"pron" : "rakh-am-aw'",
		"derivation" : "feminine of H7356 (רַחַם)",
		"def" : "a maiden",
		"kjv" : "damsel"
	},
	"H7362" : {
		"lemma" : "רַחְמָנִי",
		"xlit" : "rachmânîy",
		"pron" : "rakh-maw-nee'",
		"derivation" : "from H7355 (רָחַם)",
		"def" : "compassionate",
		"kjv" : "pitiful"
	},
	"H7363" : {
		"lemma" : "רָחַף",
		"xlit" : "râchaph",
		"pron" : "raw-khaf'",
		"derivation" : "a primitive root",
		"def" : "to brood; by implication, to be relaxed",
		"kjv" : "flutter, move, shake"
	},
	"H7364" : {
		"lemma" : "רָחַץ",
		"xlit" : "râchats",
		"pron" : "raw-khats'",
		"derivation" : "a primitive root",
		"def" : "to lave (the whole or a part of a thing)",
		"kjv" : "bathe (self), wash (self)"
	},
	"H7365" : {
		"lemma" : "רְחַץ",
		"xlit" : "rᵉchats",
		"pron" : "rekh-ats'",
		"derivation" : "(Aramaic) corresponding to H7364 (רָחַץ) (probably through the accessory idea of ministering as a servant at the bath)",
		"def" : "to attend upon",
		"kjv" : "trust"
	},
	"H7366" : {
		"lemma" : "רַחַץ",
		"xlit" : "rachats",
		"pron" : "rakh'-ats",
		"derivation" : "from H7364 (רָחַץ)",
		"def" : "a bath",
		"kjv" : "wash(-pot)"
	},
	"H7367" : {
		"lemma" : "רַחְצָה",
		"xlit" : "rachtsâh",
		"pron" : "rakh-tsaw'",
		"derivation" : "feminine of H7366 (רַחַץ)",
		"def" : "a bathing place",
		"kjv" : "washing"
	},
	"H7368" : {
		"lemma" : "רָחַק",
		"xlit" : "râchaq",
		"pron" : "raw-khak'",
		"derivation" : "a primitive root",
		"def" : "to widen (in any direction), i.e. (intransitively) recede or (transitively) remove (literally or figuratively, of place or relation)",
		"kjv" : "(a-, be, cast, drive, get, go, keep (self), put, remove, be too, (wander), withdraw) far (away, off), loose, [idiom] refrain, very, (be) a good way (off)"
	},
	"H7369" : {
		"lemma" : "רָחֵק",
		"xlit" : "râchêq",
		"pron" : "raw-khake'",
		"derivation" : "from H7368 (רָחַק)",
		"def" : "remote",
		"kjv" : "that are far"
	},
	"H7370" : {
		"lemma" : "רָחַשׁ",
		"xlit" : "râchash",
		"pron" : "raw-khash'",
		"derivation" : "a primitive root",
		"def" : "to gush",
		"kjv" : "indite"
	},
	"H7371" : {
		"lemma" : "רַחַת",
		"xlit" : "rachath",
		"pron" : "rakh'-ath",
		"derivation" : "from H7306 (רוּחַ)",
		"def" : "a winnowing-fork (as blowing the chaff away)",
		"kjv" : "shovel"
	},
	"H7372" : {
		"lemma" : "רָטַב",
		"xlit" : "râṭab",
		"pron" : "raw-tab'",
		"derivation" : "a primitive root",
		"def" : "to be moist",
		"kjv" : "be wet"
	},
	"H7373" : {
		"lemma" : "רָטֹב",
		"xlit" : "râṭôb",
		"pron" : "raw-tobe'",
		"derivation" : "from H7372 (רָטַב)",
		"def" : "moist (with sap)",
		"kjv" : "green"
	},
	"H7374" : {
		"lemma" : "רֶטֶט",
		"xlit" : "reṭeṭ",
		"pron" : "reh'-tet",
		"derivation" : "from an unused root meaning to tremble",
		"def" : "terror",
		"kjv" : "fear"
	},
	"H7375" : {
		"lemma" : "רֻטֲפַשׁ",
		"xlit" : "ruṭăphash",
		"pron" : "roo-taf-ash'",
		"derivation" : "a root compounded from H7373 (רָטֹב) and H2954 (טָפַשׁ)",
		"def" : "to be rejuvenated",
		"kjv" : "be fresh"
	},
	"H7376" : {
		"lemma" : "רָטָשׁ",
		"xlit" : "râṭâsh",
		"pron" : "raw-tash'",
		"derivation" : "a primitive root",
		"def" : "to dash down",
		"kjv" : "dash (in pieces)"
	},
	"H7377" : {
		"lemma" : "רִי",
		"xlit" : "rîy",
		"pron" : "ree",
		"derivation" : "from H7301 (רָוָה)",
		"def" : "irrigation, i.e. a shower",
		"kjv" : "watering"
	},
	"H7378" : {
		"lemma" : "רִיב",
		"xlit" : "rîyb",
		"pron" : "reeb",
		"derivation" : "or רוּב; a primitive root",
		"def" : "properly, to toss, i.e. grapple; mostly figuratively, to wrangle, i.e. hold a controversy; (by implication) to defend",
		"kjv" : "adversary, chide, complain, contend, debate, [idiom] ever, [idiom] lay wait, plead, rebuke, strive, [idiom] thoroughly"
	},
	"H7379" : {
		"lemma" : "רִיב",
		"xlit" : "rîyb",
		"pron" : "reeb",
		"derivation" : "or רִב; from H7378 (רִיב)",
		"def" : "a contest (personal or legal)",
		"kjv" : "[phrase] adversary, cause, chiding, contend(-tion), controversy, multitude (from the margin), pleading, strife, strive(-ing), suit"
	},
	"H7380" : {
		"lemma" : "רִיבַי",
		"xlit" : "Rîybay",
		"pron" : "ree-bah'-ee",
		"derivation" : "from H7378 (רִיב); contentious",
		"def" : "Ribai, an Israelite",
		"kjv" : "Ribai"
	},
	"H7381" : {
		"lemma" : "רֵיחַ",
		"xlit" : "rêyach",
		"pron" : "ray'-akh",
		"derivation" : "from H7306 (רוּחַ)",
		"def" : "odor (as if blown)",
		"kjv" : "savour, scent, smell"
	},
	"H7382" : {
		"lemma" : "רֵיחַ",
		"xlit" : "rêyach",
		"pron" : "ray'-akh",
		"derivation" : "(Aramaic) corresponding to H7381 (רֵיחַ)",
		"def" : "{odor (as if blown)}",
		"kjv" : "smell"
	},
	"H7383" : {
		"lemma" : "רִיפָה",
		"xlit" : "rîyphâh",
		"pron" : "ree-faw'",
		"derivation" : "or רִפָה; from H7322 (רוּף)",
		"def" : "(only plural), grits (as pounded)",
		"kjv" : "ground corn, wheat"
	},
	"H7384" : {
		"lemma" : "רִיפַת",
		"xlit" : "Rîyphath",
		"pron" : "ree-fath'",
		"derivation" : "or (probably by orthographical error) דִּיפַת; of foreign origin",
		"def" : "Riphath, a grandson of Japheth and his descendants",
		"kjv" : "Riphath"
	},
	"H7385" : {
		"lemma" : "רִיק",
		"xlit" : "rîyq",
		"pron" : "reek",
		"derivation" : "from H7324 (רוּק)",
		"def" : "emptiness; figuratively, a worthless thing; adverbially, in vain",
		"kjv" : "empty, to no purpose, (in) vain (thing), vanity"
	},
	"H7386" : {
		"lemma" : "רֵיק",
		"xlit" : "rêyq",
		"pron" : "rake",
		"derivation" : "or (shorter) רֵק; from H7324 (רוּק)",
		"def" : "empty; figuratively, worthless",
		"kjv" : "emptied(-ty), vain (fellow, man)"
	},
	"H7387" : {
		"lemma" : "רֵיקָם",
		"xlit" : "rêyqâm",
		"pron" : "ray-kawm'",
		"derivation" : "from H7386 (רֵיק)",
		"def" : "emptily; figuratively (objective) ineffectually, (subjective) undeservedly",
		"kjv" : "without cause, empty, in vain, void"
	},
	"H7388" : {
		"lemma" : "רִיר",
		"xlit" : "rîyr",
		"pron" : "reer",
		"derivation" : "from H7325 (רוּר)",
		"def" : "saliva; by resemblance, broth",
		"kjv" : "spittle, white (of an egg)"
	},
	"H7389" : {
		"lemma" : "רֵישׁ",
		"xlit" : "rêysh",
		"pron" : "raysh",
		"derivation" : "or רֵאשׁ; or רִישׁ; from H7326 (רוּשׁ)",
		"def" : "poverty",
		"kjv" : "poverty"
	},
	"H7390" : {
		"lemma" : "רַךְ",
		"xlit" : "rak",
		"pron" : "rak",
		"derivation" : "from H7401 (רָכַךְ)",
		"def" : "tender (literally or figuratively); by implication, weak",
		"kjv" : "faint((-hearted), soft, tender ((-hearted), one), weak"
	},
	"H7391" : {
		"lemma" : "רֹךְ",
		"xlit" : "rôk",
		"pron" : "roke",
		"derivation" : "from H7401 (רָכַךְ)",
		"def" : "softness (figuratively)",
		"kjv" : "tenderness"
	},
	"H7392" : {
		"lemma" : "רָכַב",
		"xlit" : "râkab",
		"pron" : "raw-kab'",
		"derivation" : "a primitive root",
		"def" : "to ride (on an animal or in a vehicle); causatively, to place upon (for riding or generally), to despatch",
		"kjv" : "bring (on (horse-) back), carry, get (oneself) up, on (horse-) back, put, (cause to, make to) ride (in a chariot, on, -r), set"
	},
	"H7393" : {
		"lemma" : "רֶכֶב",
		"xlit" : "rekeb",
		"pron" : "reh'-keb",
		"derivation" : "from H7392 (רָכַב)",
		"def" : "a vehicle; by implication, a team; by extension, cavalry; by analogy a rider, i.e. the upper millstone",
		"kjv" : "chariot, (upper) millstone, multitude (from the margin), wagon"
	},
	"H7394" : {
		"lemma" : "רֵכָב",
		"xlit" : "Rêkâb",
		"pron" : "ray-kawb'",
		"derivation" : "from H7392 (רָכַב); rider",
		"def" : "Rekab, the name of two Arabs and of two Israelites",
		"kjv" : "Rechab"
	},
	"H7395" : {
		"lemma" : "רַכָּב",
		"xlit" : "rakkâb",
		"pron" : "rak-kawb'",
		"derivation" : "from H7392 (רָכַב)",
		"def" : "a charioteer",
		"kjv" : "chariot man, driver of a chariot, horseman"
	},
	"H7396" : {
		"lemma" : "רִכְבָּה",
		"xlit" : "rikbâh",
		"pron" : "rik-baw'",
		"derivation" : "feminine of H7393 (רֶכֶב)",
		"def" : "a chariot (collectively)",
		"kjv" : "chariots"
	},
	"H7397" : {
		"lemma" : "רֵכָה",
		"xlit" : "Rêkâh",
		"pron" : "ray-kaw'",
		"derivation" : "probably feminine from H7401 (רָכַךְ); softness",
		"def" : "Rekah, a place in Palestine",
		"kjv" : "Rechah"
	},
	"H7398" : {
		"lemma" : "רְכוּב",
		"xlit" : "rᵉkûwb",
		"pron" : "rek-oob'",
		"derivation" : "from passive participle of H7392 (רָכַב)",
		"def" : "a vehicle (as ridden on)",
		"kjv" : "chariot"
	},
	"H7399" : {
		"lemma" : "רְכוּשׁ",
		"xlit" : "rᵉkûwsh",
		"pron" : "rek-oosh'",
		"derivation" : "or רְכֻשׁ; from passive participle of H7408 (רָכַשׁ)",
		"def" : "property (as gathered)",
		"kjv" : "good, riches, substance"
	},
	"H7400" : {
		"lemma" : "רָכִיל",
		"xlit" : "râkîyl",
		"pron" : "raw-keel'",
		"derivation" : "from H7402 (רָכַל)",
		"def" : "a scandal-monger (as travelling about)",
		"kjv" : "slander, carry tales, talebearer"
	},
	"H7401" : {
		"lemma" : "רָכַךְ",
		"xlit" : "râkak",
		"pron" : "raw-kak'",
		"derivation" : "a primitive root",
		"def" : "to soften (intransitively or transitively), used figuratively",
		"kjv" : "(be) faint(-hearted), mollify, (be, make) soft(-er), be tender"
	},
	"H7402" : {
		"lemma" : "רָכַל",
		"xlit" : "râkal",
		"pron" : "raw-kal'",
		"derivation" : "a primitive root",
		"def" : "to travel for trading",
		"kjv" : "(spice) merchant"
	},
	"H7403" : {
		"lemma" : "רָכָל",
		"xlit" : "Râkâl",
		"pron" : "raw-kawl'",
		"derivation" : "from H7402 (רָכַל); merchant",
		"def" : "Rakal, a place in Palestine",
		"kjv" : "Rachal"
	},
	"H7404" : {
		"lemma" : "רְכֻלָּה",
		"xlit" : "rᵉkullâh",
		"pron" : "rek-ool-law'",
		"derivation" : "feminine passive participle of H7402 (רָכַל)",
		"def" : "trade (as peddled)",
		"kjv" : "merchandise, traffic"
	},
	"H7405" : {
		"lemma" : "רָכַס",
		"xlit" : "râkaç",
		"pron" : "raw-kas'",
		"derivation" : "a primitive root",
		"def" : "to tie",
		"kjv" : "bind"
	},
	"H7406" : {
		"lemma" : "רֶכֶס",
		"xlit" : "rekeç",
		"pron" : "reh'-kes",
		"derivation" : "from H7405 (רָכַס)",
		"def" : "a mountain ridge (as of tied summits)",
		"kjv" : "rough place"
	},
	"H7407" : {
		"lemma" : "רֹכֶס",
		"xlit" : "rôkeç",
		"pron" : "ro'-kes",
		"derivation" : "from H7405 (רָכַס)",
		"def" : "a snare (as of tied meshes)",
		"kjv" : "pride"
	},
	"H7408" : {
		"lemma" : "רָכַשׁ",
		"xlit" : "râkash",
		"pron" : "raw-kash'",
		"derivation" : "a primitive root",
		"def" : "to lay up, i.e. collect",
		"kjv" : "gather, get"
	},
	"H7409" : {
		"lemma" : "רֶכֶשׁ",
		"xlit" : "rekesh",
		"pron" : "reh'-kesh",
		"derivation" : "from H7408 (רָכַשׁ)",
		"def" : "a relay of animals on a post-route (as stored up for that purpose); by implication, a courser",
		"kjv" : "dromedary, mule, swift beast"
	},
	"H7410" : {
		"lemma" : "רָם",
		"xlit" : "Râm",
		"pron" : "rawm",
		"derivation" : "active participle of H7311 (רוּם); high",
		"def" : "Ram, the name of an Arabian and of an Israelite",
		"kjv" : "Ram. See also H1027 (בֵּית הָרָם)"
	},
	"H7411" : {
		"lemma" : "רָמָה",
		"xlit" : "râmâh",
		"pron" : "raw-maw'",
		"derivation" : "a primitive root",
		"def" : "to hurl; specifically, to shoot; figuratively, to delude or betray (as if causing to fall)",
		"kjv" : "beguile, betray, (bow-) man, carry, deceive, throw"
	},
	"H7412" : {
		"lemma" : "רְמָה",
		"xlit" : "rᵉmâh",
		"pron" : "rem-aw'",
		"derivation" : "(Aramaic) corresponding to H7411 (רָמָה)",
		"def" : "to throw, set, (figuratively) assess",
		"kjv" : "cast (down), impose"
	},
	"H7413" : {
		"lemma" : "רָמָה",
		"xlit" : "râmâh",
		"pron" : "raw-maw'",
		"derivation" : "feminine active participle of H7311 (רוּם)",
		"def" : "a height (as a seat of idolatry)",
		"kjv" : "high place"
	},
	"H7414" : {
		"lemma" : "רָמָה",
		"xlit" : "Râmâh",
		"pron" : "raw-maw'",
		"derivation" : "the same as H7413 (רָמָה)",
		"def" : "Ramah, the name of four places in Palestine",
		"kjv" : "Ramah"
	},
	"H7415" : {
		"lemma" : "רִמָּה",
		"xlit" : "rimmâh",
		"pron" : "rim-maw'",
		"derivation" : "from H7426 (רָמַם) in the sense of breading (compare H7311 (רוּם))",
		"def" : "a maggot (as rapidly bred), literally or figuratively",
		"kjv" : "worm"
	},
	"H7416" : {
		"lemma" : "רִמּוֹן",
		"xlit" : "rimmôwn",
		"pron" : "rim-mone'",
		"derivation" : "or רִמֹּן; from H7426 (רָמַם)",
		"def" : "a pomegranate, the tree (from its upright growth) or the fruit (also an artificial ornament)",
		"kjv" : "pomegranate"
	},
	"H7417" : {
		"lemma" : "רִמּוֹן",
		"xlit" : "Rimmôwn",
		"pron" : "rim-mone'",
		"derivation" : "or (shorter) (רִמֹּן; or רִמּוֹנוֹ; (1 Chronicles 6:62 (H77 (אֶבֶץ))), the same as H7416 (רִמּוֹן); in Joshua 19:13: הַמְּתֹאָר is mistaken for part of a name; article with the passive participle of H8388 (תָּאַר); the (one) marked off, i.e. which pertains)",
		"def" : "Rimmon, the name of a Syrian deity, also of five places in Palestine",
		"kjv" : "Remmon, Rimmon"
	},
	"H7418" : {
		"lemma" : "רָמוֹת",
		"xlit" : "Râmôwth",
		"pron" : "raw-moth",
		"derivation" : "or רָמַת נֶגֶב; from the plural or construct form of H7413 (רָמָה) and H5045 (נֶגֶב); heights (or height) of the South",
		"def" : "Ramoth-Negeb or Ramath-Negeb, a place in Palestine",
		"kjv" : "south Ramoth, Ramath of the south"
	},
	"H7419" : {
		"lemma" : "רָמוּת",
		"xlit" : "râmûwth",
		"pron" : "raw-mooth'",
		"derivation" : "from H7311 (רוּם)",
		"def" : "a heap (of carcases)",
		"kjv" : "height"
	},
	"H7420" : {
		"lemma" : "רֹמַח",
		"xlit" : "rômach",
		"pron" : "ro'-makh",
		"derivation" : "from an unused root meaning to hurl",
		"def" : "a lance (as thrown); especially the iron point",
		"kjv" : "buckler, javelin, lancet, spear"
	},
	"H7421" : {
		"lemma" : "רַמִּי",
		"xlit" : "rammîy",
		"pron" : "ram-mee'",
		"derivation" : "for H761 (אֲרַמִּי)",
		"def" : "a Ramite, i.e. Aramaean",
		"kjv" : "Syrian"
	},
	"H7422" : {
		"lemma" : "רַמְיָה",
		"xlit" : "Ramyâh",
		"pron" : "ram-yaw'",
		"derivation" : "from H7311 (רוּם) and H3050 (יָהּ); Jah has raised",
		"def" : "Ramjah, an Israelite",
		"kjv" : "Ramiah"
	},
	"H7423" : {
		"lemma" : "רְמִיָּה",
		"xlit" : "rᵉmîyâh",
		"pron" : "rem-ee-yaw'",
		"derivation" : "from H7411 (רָמָה)",
		"def" : "remissness, treachery",
		"kjv" : "deceit(-ful, -fully), false, guile, idle, slack, slothful"
	},
	"H7424" : {
		"lemma" : "רַמָּךְ",
		"xlit" : "rammâk",
		"pron" : "ram-mawk'",
		"derivation" : "of foreign origin",
		"def" : "a brood mare",
		"kjv" : "dromedary"
	},
	"H7425" : {
		"lemma" : "רְמַלְיָהוּ",
		"xlit" : "Rᵉmalyâhûw",
		"pron" : "rem-al-yaw'-hoo",
		"derivation" : "from an unused root and H3050 (יָהּ) (perhaps meaning to deck); Jah has bedecked",
		"def" : "Remaljah, an Israelite",
		"kjv" : "Remaliah"
	},
	"H7426" : {
		"lemma" : "רָמַם",
		"xlit" : "râmam",
		"pron" : "raw-mam'",
		"derivation" : "a primitive root",
		"def" : "to rise (literally or figuratively)",
		"kjv" : "exalt, get (oneself) up, lift up (self), mount up"
	},
	"H7427" : {
		"lemma" : "רֹמֵמֻת",
		"xlit" : "rômêmuth",
		"pron" : "ro-may-mooth'",
		"derivation" : "from the active participle of H7426 (רָמַם)",
		"def" : "exaltation",
		"kjv" : "lifting up of self"
	},
	"H7428" : {
		"lemma" : "רִמֹּן פֶּרֶץ",
		"xlit" : "Rimmôn Perets",
		"pron" : "rim-mone' peh'-rets",
		"derivation" : "from H7416 (רִמּוֹן) and H6556 (פֶּרֶץ); pomegranate of the breach",
		"def" : "Rimmon-Perets, a place in the Desert",
		"kjv" : "Rimmon-parez"
	},
	"H7429" : {
		"lemma" : "רָמַס",
		"xlit" : "râmaç",
		"pron" : "raw-mas'",
		"derivation" : "a primitive root",
		"def" : "to tread upon (as a potter, in walking or abusively)",
		"kjv" : "oppressor, stamp upon, trample (under feet), tread (down, upon)"
	},
	"H7430" : {
		"lemma" : "רָמַשׂ",
		"xlit" : "râmas",
		"pron" : "raw-mas'",
		"derivation" : "a primitive root",
		"def" : "properly, to glide swiftly, i.e. to crawl or move with short steps; by analogy to swarm",
		"kjv" : "creep, move"
	},
	"H7431" : {
		"lemma" : "רֶמֶשׂ",
		"xlit" : "remes",
		"pron" : "reh'-mes",
		"derivation" : "from H7430 (רָמַשׂ)",
		"def" : "a reptile or any other rapidly moving animal",
		"kjv" : "that creepeth, creeping (moving) thing"
	},
	"H7432" : {
		"lemma" : "רֶמֶת",
		"xlit" : "Remeth",
		"pron" : "reh'-meth",
		"derivation" : "from H7411 (רָמָה); height",
		"def" : "Remeth, a place in Palestine",
		"kjv" : "Remeth"
	},
	"H7433" : {
		"lemma" : "רָמֹת גִּלעָד",
		"xlit" : "Râmôth Gilʻâd",
		"pron" : "raw-moth' gil-awd'",
		"derivation" : "or רָמוֹת גִּלעָד; (2 Chronicles 22:5), from the plural of H7413 (רָמָה) and H1568 (גִּלְעָד); heights of Gilad",
		"def" : "Ramoth-Gilad, a place East of the Jordan",
		"kjv" : "Ramoth-gilead, Ramoth in Gilead. See also H7216 (רָאמוֹת)"
	},
	"H7434" : {
		"lemma" : "רָמַת הַמִּצְפֶּה",
		"xlit" : "Râmath ham-Mit-speh",
		"pron" : "raw-math' ham-mitspeh'",
		"derivation" : "from H7413 (רָמָה) and H4707 (מִצְפֶּה) with the article interpolated; height of the watch-tower",
		"def" : "Ramath-ham-Mitspeh, a place in Palestine",
		"kjv" : "Ramath-mizpeh"
	},
	"H7435" : {
		"lemma" : "רָמָתִי",
		"xlit" : "Râmâthîy",
		"pron" : "raw-maw-thee'",
		"derivation" : "patronymic of H7414 (רָמָה)",
		"def" : "a Ramathite or inhabitant of Ramah",
		"kjv" : "Ramathite"
	},
	"H7436" : {
		"lemma" : "רָמָתַיִם צוֹפִים",
		"xlit" : "Râmâthayim Tsôwphîym",
		"pron" : "raw-maw-thah'-yim tso-feem'",
		"derivation" : "from the dual of H7413 (רָמָה) and the plural of the active participle of H6822 (צָפָה); double height of watchers",
		"def" : "Ramathajim-Tsophim, a place in Palestine",
		"kjv" : "Ramathaimzophim"
	},
	"H7437" : {
		"lemma" : "רָמַת לֶחִי",
		"xlit" : "Râmath Lechîy",
		"pron" : "raw'-math lekh'-ee",
		"derivation" : "from H7413 (רָמָה) and H3895 (לְחִי); height of a jaw-bone",
		"def" : "Ramath-Lechi, a place in Palestine",
		"kjv" : "Ramath-lehi"
	},
	"H7438" : {
		"lemma" : "רֹן",
		"xlit" : "rôn",
		"pron" : "rone",
		"derivation" : "from H7442 (רָנַן)",
		"def" : "a shout (of deliverance)",
		"kjv" : "song"
	},
	"H7439" : {
		"lemma" : "רָנָה",
		"xlit" : "rânâh",
		"pron" : "raw-naw'",
		"derivation" : "a primitive root",
		"def" : "to whiz",
		"kjv" : "rattle"
	},
	"H7440" : {
		"lemma" : "רִנָּה",
		"xlit" : "rinnâh",
		"pron" : "rin-naw'",
		"derivation" : "from H7442 (רָנַן)",
		"def" : "properly, a creaking (or shrill sound), i.e. shout (of joy or grief)",
		"kjv" : "cry, gladness, joy, proclamation, rejoicing, shouting, sing(-ing), triumph"
	},
	"H7441" : {
		"lemma" : "רִנָּה",
		"xlit" : "Rinnâh",
		"pron" : "rin-naw'",
		"derivation" : "the same as H7440 (רִנָּה)",
		"def" : "Rinnah, an Israelite",
		"kjv" : "Rinnah"
	},
	"H7442" : {
		"lemma" : "רָנַן",
		"xlit" : "rânan",
		"pron" : "raw-nan'",
		"derivation" : "a primitive root",
		"def" : "properly, to creak (or emit a stridulous sound), i.e. to shout (usually for joy)",
		"kjv" : "aloud for joy, cry out, be joyful (greatly, make to) rejoice, (cause to) shout (for joy), (cause to) sing (aloud, for joy, out), triumph"
	},
	"H7443" : {
		"lemma" : "רֶנֶן",
		"xlit" : "renen",
		"pron" : "reh'-nen",
		"derivation" : "from H7442 (רָנַן)",
		"def" : "an ostrich (from its wail)",
		"kjv" : "[idiom] goodly"
	},
	"H7444" : {
		"lemma" : "רַנֵּן",
		"xlit" : "rannên",
		"pron" : "ran-nane'",
		"derivation" : "intensive from H7442 (רָנַן)",
		"def" : "shouting (for joy)",
		"kjv" : "singing"
	},
	"H7445" : {
		"lemma" : "רְנָנָה",
		"xlit" : "rᵉnânâh",
		"pron" : "ren-aw-naw'",
		"derivation" : "from H7442 (רָנַן)",
		"def" : "a shout (for joy)",
		"kjv" : "joyful (voice), singing, triumphing"
	},
	"H7446" : {
		"lemma" : "רִסָּה",
		"xlit" : "Riççâh",
		"pron" : "ris-saw'",
		"derivation" : "from H7450 (רָסַס); a ruin (as dripping to pieces)",
		"def" : "Rissah, a place in the Desert",
		"kjv" : "Rissah"
	},
	"H7447" : {
		"lemma" : "רָסִיס",
		"xlit" : "râçîyç",
		"pron" : "raw-sees'",
		"derivation" : "from H7450 (רָסַס)",
		"def" : "properly, dripping to pieces, i.e. a ruin; also a dew-drop",
		"kjv" : "breach, drop"
	},
	"H7448" : {
		"lemma" : "רֶסֶן",
		"xlit" : "reçen",
		"pron" : "reh'-sen",
		"derivation" : "from an unused root meaning to curb",
		"def" : "a halter (as restraining); by implication, the jaw",
		"kjv" : "bridle"
	},
	"H7449" : {
		"lemma" : "רֶסֶן",
		"xlit" : "Reçen",
		"pron" : "reh'-sen",
		"derivation" : "the same as H7448 (רֶסֶן)",
		"def" : "Resen, a place in Assyrian",
		"kjv" : "Resen"
	},
	"H7450" : {
		"lemma" : "רָסַס",
		"xlit" : "râçaç",
		"pron" : "raw-sas'",
		"derivation" : "a primitive root; to comminute; used only as denominative from H7447 (רָסִיס)",
		"def" : "to moisten (with drops)",
		"kjv" : "temper"
	},
	"H7451" : {
		"lemma" : "רַע",
		"xlit" : "raʻ",
		"pron" : "rah",
		"derivation" : "from H7489 (רָעַע)",
		"def" : "bad or (as noun) evil (natural or moral)",
		"kjv" : "adversity, affliction, bad, calamity, [phrase] displease(-ure), distress, evil((-favouredness), man, thing), [phrase] exceedingly, [idiom] great, grief(-vous), harm, heavy, hurt(-ful), ill (favoured), [phrase] mark, mischief(-vous), misery, naught(-ty), noisome, [phrase] not please, sad(-ly), sore, sorrow, trouble, vex, wicked(-ly, -ness, one), worse(-st), wretchedness, wrong. (Incl. feminine raaah; as adjective or noun.)"
	},
	"H7452" : {
		"lemma" : "רֵעַ",
		"xlit" : "rêaʻ",
		"pron" : "ray'-ah",
		"derivation" : "from H7321 (רוּעַ)",
		"def" : "a crash (of thunder), noise (of war), shout (of joy)",
		"kjv" : "[idiom] aloud, noise, shouted"
	},
	"H7453" : {
		"lemma" : "רֵעַ",
		"xlit" : "rêaʻ",
		"pron" : "ray'-ah",
		"derivation" : "or רֵיעַ; from H7462 (רָעָה)",
		"def" : "an associate (more or less close)",
		"kjv" : "brother, companion, fellow, friend, husband, lover, neighbour, [idiom] (an-) other"
	},
	"H7454" : {
		"lemma" : "רֵעַ",
		"xlit" : "rêaʻ",
		"pron" : "ray'-ah",
		"derivation" : "from H7462 (רָעָה)",
		"def" : "a thought (as association of ideas)",
		"kjv" : "thought"
	},
	"H7455" : {
		"lemma" : "רֹעַ",
		"xlit" : "rôaʻ",
		"pron" : "ro'-ah",
		"derivation" : "from H7489 (רָעַע)",
		"def" : "badness (as marring), physically or morally",
		"kjv" : "[idiom] be so bad, badness, ([idiom] be so) evil, naughtiness, sadness, sorrow, wickedness"
	},
	"H7456" : {
		"lemma" : "רָעֵב",
		"xlit" : "râʻêb",
		"pron" : "raw-abe'",
		"derivation" : "a primitive root",
		"def" : "to hunger",
		"kjv" : "(suffer to) famish, (be, have, suffer, suffer to) hunger(-ry)"
	},
	"H7457" : {
		"lemma" : "רָעֵב",
		"xlit" : "râʻêb",
		"pron" : "raw-abe'",
		"derivation" : "from H7456 (רָעֵב)",
		"def" : "hungry (more or less intensely)",
		"kjv" : "hunger bitten, hungry"
	},
	"H7458" : {
		"lemma" : "רָעָב",
		"xlit" : "râʻâb",
		"pron" : "raw-awb'",
		"derivation" : "from H7456 (רָעֵב)",
		"def" : "hunger (more or less extensive)",
		"kjv" : "dearth, famine, [phrase] famished, hunger"
	},
	"H7459" : {
		"lemma" : "רְעָבוֹן",
		"xlit" : "rᵉʻâbôwn",
		"pron" : "reh-aw-bone'",
		"derivation" : "from H7456 (רָעֵב)",
		"def" : "famine",
		"kjv" : "famine"
	},
	"H7460" : {
		"lemma" : "רָעַד",
		"xlit" : "râʻad",
		"pron" : "raw-ad'",
		"derivation" : "a primitive root",
		"def" : "to shudder (more or less violently)",
		"kjv" : "tremble"
	},
	"H7461" : {
		"lemma" : "רַעַד",
		"xlit" : "raʻad",
		"pron" : "rah'-ad",
		"derivation" : "or (feminine) רְעָדָהxlit rᵉâdâh corrected to rᵉʻâdâh; from H7460 (רָעַד)",
		"def" : "a shudder",
		"kjv" : "trembling"
	},
	"H7462" : {
		"lemma" : "רָעָה",
		"xlit" : "râʻâh",
		"pron" : "raw-aw'",
		"derivation" : "a primitive root",
		"def" : "to tend a flock; i.e. pasture it; intransitively, to graze (literally or figuratively); generally to rule; by extension, to associate with (as a friend)",
		"kjv" : "[idiom] break, companion, keep company with, devour, eat up, evil entreat, feed, use as a friend, make friendship with, herdman, keep (sheep) (-er), pastor, [phrase] shearing house, shepherd, wander, waste"
	},
	"H7463" : {
		"lemma" : "רֵעֶה",
		"xlit" : "rêʻeh",
		"pron" : "ray-eh'",
		"derivation" : "from H7462 (רָעָה)",
		"def" : "a (male) companion",
		"kjv" : "friend"
	},
	"H7464" : {
		"lemma" : "רֵעָה",
		"xlit" : "rêʻâh",
		"pron" : "ray'-aw",
		"derivation" : "feminine of H7453 (רֵעַ)",
		"def" : "a female associate",
		"kjv" : "companion, fellow"
	},
	"H7465" : {
		"lemma" : "רֹעָה",
		"xlit" : "rôʻâh",
		"pron" : "ro-aw'",
		"derivation" : "for H7455 (רֹעַ)",
		"def" : "breakage",
		"kjv" : "broken, utterly"
	},
	"H7466" : {
		"lemma" : "רְעוּ",
		"xlit" : "Rᵉʻûw",
		"pron" : "reh-oo'",
		"derivation" : "for H7471 (רְעִי) in the sense of H7453 (רֵעַ); friend",
		"def" : "Reu, a postdiluvian patriarch",
		"kjv" : "Reu"
	},
	"H7467" : {
		"lemma" : "רְעוּאֵל",
		"xlit" : "Rᵉʻûwʼêl",
		"pron" : "reh-oo-ale'",
		"derivation" : "from the same as H7466 (רְעוּ) and H410 (אֵל); friend of God",
		"def" : "Reuel, the name of Moses' father-in-law, also of an Edomite and an Israelite",
		"kjv" : "Raguel, Reuel"
	},
	"H7468" : {
		"lemma" : "רְעוּת",
		"xlit" : "rᵉʻûwth",
		"pron" : "reh-ooth'",
		"derivation" : "from H7462 (רָעָה) in the sense of H7453 (רֵעַ)",
		"def" : "a female associate; generally an additional one",
		"kjv" : "[phrase] another, mate, neighbour"
	},
	"H7469" : {
		"lemma" : "רְעוּת",
		"xlit" : "rᵉʻûwth",
		"pron" : "reh-ooth'",
		"derivation" : "probably from H7462 (רָעָה)",
		"def" : "a feeding upon, i.e. grasping after",
		"kjv" : "vexation"
	},
	"H7470" : {
		"lemma" : "רְעוּת",
		"xlit" : "rᵉʻûwth",
		"pron" : "reh-ooth'",
		"derivation" : "(Aramaic) corresponding to H7469 (רְעוּת)",
		"def" : "desire",
		"kjv" : "pleasure, will"
	},
	"H7471" : {
		"lemma" : "רְעִי",
		"xlit" : "rᵉʻîy",
		"pron" : "reh-ee'",
		"derivation" : "from H7462 (רָעָה)",
		"def" : "pasture",
		"kjv" : "pasture"
	},
	"H7472" : {
		"lemma" : "רֵעִי",
		"xlit" : "Rêʻîy",
		"pron" : "ray-ee'",
		"derivation" : "from H7453 (רֵעַ); social",
		"def" : "Rei, an Israelite",
		"kjv" : "Rei"
	},
	"H7473" : {
		"lemma" : "רֹעִי",
		"xlit" : "rôʻîy",
		"pron" : "ro-ee'",
		"derivation" : "from active participle of H7462 (רָעָה)",
		"def" : "pastoral; as noun, a shepherd",
		"kjv" : "shipherd"
	},
	"H7474" : {
		"lemma" : "רַעְיָה",
		"xlit" : "raʻyâh",
		"pron" : "rah-yaw'",
		"derivation" : "feminine of H7453 (רֵעַ)",
		"def" : "a female associate",
		"kjv" : "fellow, love"
	},
	"H7475" : {
		"lemma" : "רַעְיוֹן",
		"xlit" : "raʻyôwn",
		"pron" : "rah-yone'",
		"derivation" : "from H7462 (רָעָה) in the sense of H7469 (רְעוּת)",
		"def" : "desire",
		"kjv" : "vexation"
	},
	"H7476" : {
		"lemma" : "רַעְיוֹן",
		"xlit" : "raʻyôwn",
		"pron" : "rah-yone'",
		"derivation" : "(Aramaic) corresponding to H7475 (רַעְיוֹן)",
		"def" : "a grasp. i.e. (figuratively) mental conception",
		"kjv" : "cogitation, thought"
	},
	"H7477" : {
		"lemma" : "רָעַל",
		"xlit" : "râʻal",
		"pron" : "raw-al'",
		"derivation" : "a primitive root",
		"def" : "to reel, i.e. (figuratively) to brandish",
		"kjv" : "terribly shake"
	},
	"H7478" : {
		"lemma" : "רַעַל",
		"xlit" : "raʻal",
		"pron" : "rah'-al",
		"derivation" : "from H7477 (רָעַל)",
		"def" : "a reeling (from intoxication)",
		"kjv" : "trembling"
	},
	"H7479" : {
		"lemma" : "רַעֲלָה",
		"xlit" : "raʻălâh",
		"pron" : "rah-al-aw'",
		"derivation" : "feminine of H7478 (רַעַל)",
		"def" : "a long veil (as fluttering)",
		"kjv" : "muffler"
	},
	"H7480" : {
		"lemma" : "רְעֵלָיָה",
		"xlit" : "Rᵉʻêlâyâh",
		"pron" : "reh-ay-law-yaw'",
		"derivation" : "from H7477 (רָעַל) and H3050 (יָהּ); made to tremble (i.e. fearful) of Jah",
		"def" : "Reelajah, an Israelite",
		"kjv" : "Reeliah"
	},
	"H7481" : {
		"lemma" : "רָעַם",
		"xlit" : "râʻam",
		"pron" : "raw-am'",
		"derivation" : "a primitive root",
		"def" : "to tumble, i.e. be violently agitated; specifically, to crash (of thunder); figuratively, to irritate (with anger)",
		"kjv" : "make to fret, roar, thunder, trouble"
	},
	"H7482" : {
		"lemma" : "רַעַם",
		"xlit" : "raʻam",
		"pron" : "rah'am",
		"derivation" : "from H7481 (רָעַם)",
		"def" : "a peal of thunder",
		"kjv" : "thunder"
	},
	"H7483" : {
		"lemma" : "רַעְמָה",
		"xlit" : "raʻmâh",
		"pron" : "rah-maw'",
		"derivation" : "feminine of H7482 (רַעַם)",
		"def" : "the mane of a horse (as quivering in the wind)",
		"kjv" : "thunder"
	},
	"H7484" : {
		"lemma" : "רַעְמָה",
		"xlit" : "Raʻmâh",
		"pron" : "rah-maw'",
		"derivation" : "the same as H7483 (רַעְמָה)",
		"def" : "Ramah, the name of a grandson of Ham, and of a place (perhaps founded by him)",
		"kjv" : "Raamah"
	},
	"H7485" : {
		"lemma" : "רַעַמְיָה",
		"xlit" : "Raʻamyâh",
		"pron" : "rah-am-yaw'",
		"derivation" : "from H7481 (רָעַם) and H3050 (יָהּ); Jah has shaken",
		"def" : "Raamjah, an Israelite",
		"kjv" : "Raamiah"
	},
	"H7486" : {
		"lemma" : "רַעְמְסֵס",
		"xlit" : "Raʻmᵉçêç",
		"pron" : "rah-mes-ace'",
		"derivation" : "or רַעַמְסֵס; of Egyptian origin",
		"def" : "Rameses or Raamses, a place in Egypt",
		"kjv" : "Raamses, Rameses"
	},
	"H7487" : {
		"lemma" : "רַעֲנַן",
		"xlit" : "raʻănan",
		"pron" : "rah-aw-nan'",
		"derivation" : "(Aramaic) corresponding to H7488 (רַעֲנָן)",
		"def" : "green, i.e. (figuratively) prosperous",
		"kjv" : "flourishing"
	},
	"H7488" : {
		"lemma" : "רַעֲנָן",
		"xlit" : "raʻănân",
		"pron" : "rah-an-awn'",
		"derivation" : "from an unused root meaning to be green",
		"def" : "verdant; by analogy, new; figuratively, prosperous",
		"kjv" : "green, flourishing"
	},
	"H7489" : {
		"lemma" : "רָעַע",
		"xlit" : "râʻaʻ",
		"pron" : "raw-ah'",
		"derivation" : "a primitive root",
		"def" : "properly, to spoil (literally, by breaking to pieces); figuratively, to make (or be) good fornothing, i.e. bad (physically, socially or morally)",
		"kjv" : "afflict, associate selves (by mistake for H7462 (רָעָה)), break (down, in pieces), [phrase] displease, (be, bring, do) evil (doer, entreat, man), show self friendly (by mistake for H7462 (רָעָה)), do harm, (do) hurt, (behave self, deal) ill, [idiom] indeed, do mischief, punish, still, vex, (do) wicked (doer, -ly), be (deal, do) worse"
	},
	"H7490" : {
		"lemma" : "רְעַע",
		"xlit" : "rᵉʻaʻ",
		"pron" : "reh-ah'",
		"derivation" : "(Aramaic) corresponding to H7489 (רָעַע)",
		"def" : "{properly, to spoil (literally, by breaking to pieces); figuratively, to make (or be) good fornothing, i.e. bad (physically, socially or morally)}",
		"kjv" : "break, bruise"
	},
	"H7491" : {
		"lemma" : "רָעַף",
		"xlit" : "râʻaph",
		"pron" : "raw-af'",
		"derivation" : "a primitive root",
		"def" : "to drip",
		"kjv" : "distil, drop (down)"
	},
	"H7492" : {
		"lemma" : "רָעַץ",
		"xlit" : "râʻats",
		"pron" : "raw-ats'",
		"derivation" : "a primitive root",
		"def" : "to break in pieces; figuratively, harass",
		"kjv" : "dash in pieces, vex"
	},
	"H7493" : {
		"lemma" : "רָעַשׁ",
		"xlit" : "râʻash",
		"pron" : "raw-ash",
		"derivation" : "a primitive root",
		"def" : "to undulate (as the earth, the sky, etc.; also a field of grain), particularly through fear; specifically, to spring (as a locust)",
		"kjv" : "make afraid, (re-) move, quake, (make to) shake, (make to) tremble"
	},
	"H7494" : {
		"lemma" : "רַעַשׁ",
		"xlit" : "raʻash",
		"pron" : "rah'-ash",
		"derivation" : "from H7493 (רָעַשׁ)",
		"def" : "vibration, bounding, uproar",
		"kjv" : "commotion, confused noise, earthquake, fierceness, quaking, rattling, rushing, shaking"
	},
	"H7495" : {
		"lemma" : "רָפָא",
		"xlit" : "râphâʼ",
		"pron" : "raw-faw'",
		"derivation" : "or רָפָה; a primitive root",
		"def" : "properly, to mend (by stitching), i.e. (figuratively) to cure",
		"kjv" : "cure, (cause to) heal, physician, repair, [idiom] thoroughly, make whole. See H7503 (רָפָה)"
	},
	"H7496" : {
		"lemma" : "רָפָא",
		"xlit" : "râphâʼ",
		"pron" : "raw-faw'",
		"derivation" : "from H7495 (רָפָא) in the sense of H7503 (רָפָה)",
		"def" : "properly, lax, i.e. (figuratively) a ghost (as dead; in plural only)",
		"kjv" : "dead, deceased"
	},
	"H7497" : {
		"lemma" : "רָפָא",
		"xlit" : "râphâʼ",
		"pron" : "raw-faw'",
		"derivation" : "or רָפָה; from H7495 (רָפָא) in the sense of invigorating",
		"def" : "a giant",
		"kjv" : "giant, Rapha, Rephaim(-s). See also H1051 (בֵּית רָפָא)"
	},
	"H7498" : {
		"lemma" : "רָפָא",
		"xlit" : "Râphâʼ",
		"pron" : "raw-faw'",
		"derivation" : "or רָפָה; probably the same as H7497 (רָפָא); giant",
		"def" : "Rapha or Raphah, the name of two Israelites",
		"kjv" : "Rapha"
	},
	"H7499" : {
		"lemma" : "רְפֻאָה",
		"xlit" : "rᵉphuʼâh",
		"pron" : "ref-oo-aw'",
		"derivation" : "feminine passive participle of H7495 (רָפָא)",
		"def" : "a medicament",
		"kjv" : "heal(-ed), medicine"
	},
	"H7500" : {
		"lemma" : "רִפְאוּת",
		"xlit" : "riphʼûwth",
		"pron" : "rif-ooth'",
		"derivation" : "from H7495 (רָפָא)",
		"def" : "a cure",
		"kjv" : "health"
	},
	"H7501" : {
		"lemma" : "רְפָאֵל",
		"xlit" : "Rᵉphâʼêl",
		"pron" : "ref-aw-ale'",
		"derivation" : "from H7495 (רָפָא) and H410 (אֵל); God has cured",
		"def" : "Rephael, an Israelite",
		"kjv" : "Rephael"
	},
	"H7502" : {
		"lemma" : "רָפַד",
		"xlit" : "râphad",
		"pron" : "raw-fad'",
		"derivation" : "a primitive root",
		"def" : "to spread (a bed); by implication, to refresh",
		"kjv" : "comfort, make (a bed), spread"
	},
	"H7503" : {
		"lemma" : "רָפָה",
		"xlit" : "râphâh",
		"pron" : "raw-faw'",
		"derivation" : "a primitive root",
		"def" : "to slacken (in many applications, literal or figurative)",
		"kjv" : "abate, cease, consume, draw (toward evening), fail, (be) faint, be (wax) feeble, forsake, idle, leave, let alone (go, down), (be) slack, stay, be still, be slothful, (be) weak(-en). See H7495 (רָפָא)"
	},
	"H7504" : {
		"lemma" : "רָפֶה",
		"xlit" : "râpheh",
		"pron" : "raw-feh'",
		"derivation" : "from H7503 (רָפָה)",
		"def" : "slack (in body or mind)",
		"kjv" : "weak. raphah, Raphah. See H7497 (רָפָא), H7498 (רָפָא). riphah. See H7383 (רִיפָה)"
	},
	"H7505" : {
		"lemma" : "רָפוּא",
		"xlit" : "Râphûwʼ",
		"pron" : "raw-foo'",
		"derivation" : "passive participle of H7495 (רָפָא); cured",
		"def" : "Raphu, an Israelite",
		"kjv" : "Raphu"
	},
	"H7506" : {
		"lemma" : "רֶפַח",
		"xlit" : "Rephach",
		"pron" : "reh'-fakh",
		"derivation" : "from an unused root apparently meaning to sustain; support",
		"def" : "Rephach, an Israelite",
		"kjv" : "Rephah"
	},
	"H7507" : {
		"lemma" : "רְפִידָה",
		"xlit" : "rᵉphîydâh",
		"pron" : "ref-ee-daw'",
		"derivation" : "from H7502 (רָפַד)",
		"def" : "a railing (as spread along)",
		"kjv" : "bottom"
	},
	"H7508" : {
		"lemma" : "רְפִידִים",
		"xlit" : "Rᵉphîydîym",
		"pron" : "ref-ee-deem'",
		"derivation" : "plural of the masculine of the same as H7507 (רְפִידָה); ballusters",
		"def" : "Rephidim, a place in the Desert",
		"kjv" : "Rephidim"
	},
	"H7509" : {
		"lemma" : "רְפָיָה",
		"xlit" : "Rᵉphâyâh",
		"pron" : "ref-aw-yaw'",
		"derivation" : "from H7495 (רָפָא) and H3050 (יָהּ); Jah has cured",
		"def" : "Rephajah, the name of five Israelites",
		"kjv" : "Rephaiah"
	},
	"H7510" : {
		"lemma" : "רִפְיוֹן",
		"xlit" : "riphyôwn",
		"pron" : "rif-yone'",
		"derivation" : "from H7503 (רָפָה)",
		"def" : "slackness",
		"kjv" : "feebleness"
	},
	"H7511" : {
		"lemma" : "רָפַס",
		"xlit" : "râphaç",
		"pron" : "raw-fas'",
		"derivation" : "a primitive root",
		"def" : "to trample, i.e. prostrate",
		"kjv" : "humble self, submit self"
	},
	"H7512" : {
		"lemma" : "רְפַס",
		"xlit" : "rᵉphaç",
		"pron" : "ref-as'",
		"derivation" : "(Aramaic) corresponding to H7511 (רָפַס)",
		"def" : "{to trample, i.e. prostrate}",
		"kjv" : "stamp"
	},
	"H7513" : {
		"lemma" : "רַפְסֹדָה",
		"xlit" : "raphçôdâh",
		"pron" : "raf-so-daw'",
		"derivation" : "from H7511 (רָפַס)",
		"def" : "a raft (as flat on the water)",
		"kjv" : "flote"
	},
	"H7514" : {
		"lemma" : "רָפַק",
		"xlit" : "râphaq",
		"pron" : "raw-fak'",
		"derivation" : "a primitive root",
		"def" : "to recline",
		"kjv" : "lean"
	},
	"H7515" : {
		"lemma" : "רָפַשׂ",
		"xlit" : "râphas",
		"pron" : "raw-fas'",
		"derivation" : "a primitive root",
		"def" : "to trample, i.e. roil water",
		"kjv" : "foul, trouble"
	},
	"H7516" : {
		"lemma" : "רֶפֶשׁ",
		"xlit" : "rephesh",
		"pron" : "reh'-fesh",
		"derivation" : "from H7515 (רָפַשׂ)",
		"def" : "mud (as roiled)",
		"kjv" : "mire"
	},
	"H7517" : {
		"lemma" : "רֶפֶת",
		"xlit" : "repheth",
		"pron" : "reh'-feth",
		"derivation" : "probably from H7503 (רָפָה)",
		"def" : "a stall for cattle (from their resting there)",
		"kjv" : "stall"
	},
	"H7518" : {
		"lemma" : "רַץ",
		"xlit" : "rats",
		"pron" : "rats",
		"derivation" : "contracted from H7533 (רָצַץ)",
		"def" : "a fragment",
		"kjv" : "piece"
	},
	"H7519" : {
		"lemma" : "רָצָא",
		"xlit" : "râtsâʼ",
		"pron" : "raw-tsaw'",
		"derivation" : "a primitive root",
		"def" : "to run; also to delight in",
		"kjv" : "accept, run"
	},
	"H7520" : {
		"lemma" : "רָצַד",
		"xlit" : "râtsad",
		"pron" : "raw-tsad'",
		"derivation" : "a primitive root",
		"def" : "probably to look askant, i.e. (figuratively) be jealous",
		"kjv" : "leap"
	},
	"H7521" : {
		"lemma" : "רָצָה",
		"xlit" : "râtsâh",
		"pron" : "raw-tsaw'",
		"derivation" : "a primitive root",
		"def" : "to be pleased with; specifically, to satisfy adebt",
		"kjv" : "(be) accept(-able), accomplish, set affection, approve, consent with, delight (self), enjoy, (be, have a) favour(-able), like, observe, pardon, (be, have, take) please(-ure), reconcile self"
	},
	"H7522" : {
		"lemma" : "רָצוֹן",
		"xlit" : "râtsôwn",
		"pron" : "raw-tsone'",
		"derivation" : "or רָצֹן; from H7521 (רָצָה)",
		"def" : "delight (especially as shown)",
		"kjv" : "(be) acceptable(-ance, -ed), delight, desire, favour, (good) pleasure, (own, self, voluntary) will, as...(what) would"
	},
	"H7523" : {
		"lemma" : "רָצַח",
		"xlit" : "râtsach",
		"pron" : "raw-tsakh'",
		"derivation" : "a primitive root",
		"def" : "properly, to dash in pieces, i.e. kill (a human being), especially to murder",
		"kjv" : "put to death, kill, (man-) slay(-er), murder(-er)"
	},
	"H7524" : {
		"lemma" : "רֶצַח",
		"xlit" : "retsach",
		"pron" : "reh-tsakh",
		"derivation" : "from H7523 (רָצַח)",
		"def" : "a crushing; specifically, a murder-cry",
		"kjv" : "slaughter, sword"
	},
	"H7525" : {
		"lemma" : "רִצְיָא",
		"xlit" : "Ritsyâʼ",
		"pron" : "rits-yaw'",
		"derivation" : "from H7521 (רָצָה); delight",
		"def" : "Ritsjah, an Israelite",
		"kjv" : "Rezia"
	},
	"H7526" : {
		"lemma" : "רְצִין",
		"xlit" : "Rᵉtsîyn",
		"pron" : "rets-een'",
		"derivation" : "probably for H7522 (רָצוֹן)",
		"def" : "Retsin, the name of a Syrian and of an Israelite",
		"kjv" : "Rezin"
	},
	"H7527" : {
		"lemma" : "רָצַע",
		"xlit" : "râtsaʻ",
		"pron" : "raw-tsah'",
		"derivation" : "a primitive root",
		"def" : "to pierce",
		"kjv" : "bore"
	},
	"H7528" : {
		"lemma" : "רָצַף",
		"xlit" : "râtsaph",
		"pron" : "raw-tsaf'",
		"derivation" : "a denominative from H7529 (רֶצֶף)",
		"def" : "to tessellate, i.e. embroider (as if with bright stones)",
		"kjv" : "pave"
	},
	"H7529" : {
		"lemma" : "רֶצֶף",
		"xlit" : "retseph",
		"pron" : "reh'-tsef",
		"derivation" : "for H7565 (רֶשֶׁף)",
		"def" : "a red-hot stone (for baking)",
		"kjv" : "coal"
	},
	"H7530" : {
		"lemma" : "רֶצֶף",
		"xlit" : "Retseph",
		"pron" : "reh'-tsef",
		"derivation" : "the same as H7529 (רֶצֶף)",
		"def" : "Retseph, a place in Assyrian",
		"kjv" : "Rezeph"
	},
	"H7531" : {
		"lemma" : "רִצְפָּה",
		"xlit" : "ritspâh",
		"pron" : "rits-paw'",
		"derivation" : "feminine of H7529 (רֶצֶף)",
		"def" : "a hot stone; also a tessellated pavement",
		"kjv" : "live coal, pavement"
	},
	"H7532" : {
		"lemma" : "רִצְפָּה",
		"xlit" : "Ritspâh",
		"pron" : "rits-paw'",
		"derivation" : "the same as H7531 (רִצְפָּה)",
		"def" : "Ritspah, an Israelitess",
		"kjv" : "Rizpah"
	},
	"H7533" : {
		"lemma" : "רָצַץ",
		"xlit" : "râtsats",
		"pron" : "raw-tsats'",
		"derivation" : "a primitive root",
		"def" : "to crack in pieces, literally or figuratively",
		"kjv" : "break, bruise, crush, discourage, oppress, struggle together"
	},
	"H7534" : {
		"lemma" : "רַק",
		"xlit" : "raq",
		"pron" : "rak",
		"derivation" : "from H7556 (רָקַק) in its original sense",
		"def" : "emaciated (as if flattened out)",
		"kjv" : "lean(-fleshed), thin"
	},
	"H7535" : {
		"lemma" : "רַק",
		"xlit" : "raq",
		"pron" : "rak",
		"derivation" : "the same as H7534 (רַק) as a noun",
		"def" : "properly, leanness, i.e. (figuratively) limitation; only adverbial, merely, or conjunctional, although",
		"kjv" : "but, even, except, howbeit howsoever, at the least, nevertheless, nothing but, notwithstanding, only, save, so (that), surely, yet (so), in any wise"
	},
	"H7536" : {
		"lemma" : "רֹק",
		"xlit" : "rôq",
		"pron" : "roke",
		"derivation" : "from H7556 (רָקַק)",
		"def" : "spittle",
		"kjv" : "spit(-ting, -tle)"
	},
	"H7537" : {
		"lemma" : "רָקַב",
		"xlit" : "râqab",
		"pron" : "raw-kab'",
		"derivation" : "a primitive root",
		"def" : "to decay (as by worm-eating)",
		"kjv" : "rot"
	},
	"H7538" : {
		"lemma" : "רָקָב",
		"xlit" : "râqâb",
		"pron" : "raw-kawb'",
		"derivation" : "from H7537 (רָקַב)",
		"def" : "decay (by caries)",
		"kjv" : "rottenness (thing)"
	},
	"H7539" : {
		"lemma" : "רִקָּבוֹן",
		"xlit" : "riqqâbôwn",
		"pron" : "rik-kaw-bone'",
		"derivation" : "from H7538 (רָקָב)",
		"def" : "decay (by caries)",
		"kjv" : "rotten"
	},
	"H7540" : {
		"lemma" : "רָקַד",
		"xlit" : "râqad",
		"pron" : "raw-kad'",
		"derivation" : "a primitive root",
		"def" : "properly, to stamp, i.e. to spring about (wildly or for joy)",
		"kjv" : "dance, jump, leap, skip"
	},
	"H7541" : {
		"lemma" : "רַקָּה",
		"xlit" : "raqqâh",
		"pron" : "rak-kaw'",
		"derivation" : "feminine of H7534 (רַק)",
		"def" : "properly, thinness, i.e. the side of the head",
		"kjv" : "temple"
	},
	"H7542" : {
		"lemma" : "רַקּוֹן",
		"xlit" : "Raqqôwn",
		"pron" : "rak-kone'",
		"derivation" : "from H7534 (רַק); thinness",
		"def" : "Rakkon, a place in Palestine",
		"kjv" : "Rakkon"
	},
	"H7543" : {
		"lemma" : "רָקַח",
		"xlit" : "râqach",
		"pron" : "raw-kakh'",
		"derivation" : "a primitive root",
		"def" : "to perfume",
		"kjv" : "apothecary, compound, make (ointment), prepare, spice"
	},
	"H7544" : {
		"lemma" : "רֶקַח",
		"xlit" : "reqach",
		"pron" : "reh'-kakh",
		"derivation" : "from H7543 (רָקַח)",
		"def" : "properly, perfumery, i.e. (by implication) spicery (for flavor)",
		"kjv" : "spiced"
	},
	"H7545" : {
		"lemma" : "רֹקַח",
		"xlit" : "rôqach",
		"pron" : "ro'-kakh",
		"derivation" : "from H7542 (רַקּוֹן)",
		"def" : "an aromatic",
		"kjv" : "confection, ointment"
	},
	"H7546" : {
		"lemma" : "רַקָּח",
		"xlit" : "raqqâch",
		"pron" : "rak-kawkh'",
		"derivation" : "from H7543 (רָקַח)",
		"def" : "a male perfumer",
		"kjv" : "apothecary"
	},
	"H7547" : {
		"lemma" : "רַקֻּחַ",
		"xlit" : "raqquach",
		"pron" : "rak-koo'-akh",
		"derivation" : "from H7543 (רָקַח)",
		"def" : "a scented substance",
		"kjv" : "perfume"
	},
	"H7548" : {
		"lemma" : "רַקָּחָה",
		"xlit" : "raqqâchâh",
		"pron" : "rak-kaw-khaw'",
		"derivation" : "feminine of H7547 (רַקֻּחַ)",
		"def" : "a female perfumer",
		"kjv" : "confectioner"
	},
	"H7549" : {
		"lemma" : "רָקִיעַ",
		"xlit" : "râqîyaʻ",
		"pron" : "raw-kee'-ah",
		"derivation" : "from H7554 (רָקַע)",
		"def" : "properly, an expanse, i.e. the firmament or (apparently) visible arch of the sky",
		"kjv" : "firmament"
	},
	"H7550" : {
		"lemma" : "רָקִיק",
		"xlit" : "râqîyq",
		"pron" : "raw-keek'",
		"derivation" : "from, H7556 (רָקַק) in its original sense",
		"def" : "a thin cake",
		"kjv" : "cake, wafer"
	},
	"H7551" : {
		"lemma" : "רָקַם",
		"xlit" : "râqam",
		"pron" : "raw-kam'",
		"derivation" : "a primitive root",
		"def" : "to variegate color, i.e. embroider; by implication, to fabricate",
		"kjv" : "embroiderer, needlework, curiously work"
	},
	"H7552" : {
		"lemma" : "רֶקֶם",
		"xlit" : "Reqem",
		"pron" : "reh'-kem",
		"derivation" : "from H7551 (רָקַם); versi-color",
		"def" : "Rekem, the name of a place in Palestine, also of a Midianite and an Israelite",
		"kjv" : "Rekem"
	},
	"H7553" : {
		"lemma" : "רִקְמָה",
		"xlit" : "riqmâh",
		"pron" : "rik-maw'",
		"derivation" : "from H7551 (רָקַם)",
		"def" : "variegation of color; specifically, embroidery",
		"kjv" : "broidered (work), divers colours, (raiment of) needlework (on both sides)"
	},
	"H7554" : {
		"lemma" : "רָקַע",
		"xlit" : "râqaʻ",
		"pron" : "raw-kah'",
		"derivation" : "a primitive root",
		"def" : "to pound the earth (as a sign of passion); by analogy to expand (by hammering); by implication, to overlay (with thin sheets of metal)",
		"kjv" : "beat, make broad, spread abroad (forth, over, out, into plates), stamp, stretch"
	},
	"H7555" : {
		"lemma" : "רִקֻּעַ",
		"xlit" : "riqquaʻ",
		"pron" : "rik-koo'-ah",
		"derivation" : "from H7554 (רָקַע)",
		"def" : "beaten out, i.e. a (metallic) plate",
		"kjv" : "broad"
	},
	"H7556" : {
		"lemma" : "רָקַק",
		"xlit" : "râqaq",
		"pron" : "raw-kak'",
		"derivation" : "a primitive root",
		"def" : "to spit",
		"kjv" : "spit"
	},
	"H7557" : {
		"lemma" : "רַקַּת",
		"xlit" : "Raqqath",
		"pron" : "rak-kath'",
		"derivation" : "from H7556 (רָקַק) in its original sense of diffusing; a beach (as expanded shingle)",
		"def" : "Rakkath, a place in Palestine",
		"kjv" : "Rakkath"
	},
	"H7558" : {
		"lemma" : "רִשְׁיוֹן",
		"xlit" : "rishyôwn",
		"pron" : "rish-yone'",
		"derivation" : "from an unused root meaning to have leave",
		"def" : "a permit",
		"kjv" : "grant"
	},
	"H7559" : {
		"lemma" : "רָשַׁם",
		"xlit" : "râsham",
		"pron" : "raw-sham'",
		"derivation" : "a primitive root",
		"def" : "to record",
		"kjv" : "note"
	},
	"H7560" : {
		"lemma" : "רְשַׁם",
		"xlit" : "rᵉsham",
		"pron" : "resh-am'",
		"derivation" : "(Aramaic) corresponding to H7559 (רָשַׁם)",
		"def" : "{to record}",
		"kjv" : "sign, write"
	},
	"H7561" : {
		"lemma" : "רָשַׁע",
		"xlit" : "râshaʻ",
		"pron" : "raw-shah'",
		"derivation" : "a primitive root",
		"def" : "to be (causatively, do or declare) wrong; by implication, to disturb, violate",
		"kjv" : "condemn, make trouble, vex, be (commit, deal, depart, do) wicked(-ly, -ness)"
	},
	"H7562" : {
		"lemma" : "רֶשַׁע",
		"xlit" : "reshaʻ",
		"pron" : "reh'-shah",
		"derivation" : "from H7561 (רָשַׁע)",
		"def" : "a wrong (especially moral)",
		"kjv" : "iniquity, wicked(-ness)"
	},
	"H7563" : {
		"lemma" : "רָשָׁע",
		"xlit" : "râshâʻ",
		"pron" : "raw-shaw'",
		"derivation" : "from H7561 (רָשַׁע)",
		"def" : "morally wrong; concretely, an (actively) bad person",
		"kjv" : "[phrase] condemned, guilty, ungodly, wicked (man), that did wrong"
	},
	"H7564" : {
		"lemma" : "רִשְׁעָה",
		"xlit" : "rishʻâh",
		"pron" : "rish-aw'",
		"derivation" : "feminine of H7562 (רֶשַׁע)",
		"def" : "wrong (especially moral)",
		"kjv" : "fault, wickedly(-ness)"
	},
	"H7565" : {
		"lemma" : "רֶשֶׁף",
		"xlit" : "resheph",
		"pron" : "reh'-shef",
		"derivation" : "from H8313 (שָׂרַף)",
		"def" : "a live coal; by analogy lightning; figuratively, an arrow, (as flashing through the air); specifically, fever",
		"kjv" : "arrow, (burning) coal, burning heat, [phrase] spark, hot thunderbolt"
	},
	"H7566" : {
		"lemma" : "רֶשֶׁף",
		"xlit" : "Resheph",
		"pron" : "reh'-shef",
		"derivation" : "the same as H7565 (רֶשֶׁף)",
		"def" : "Resheph, an Israelite",
		"kjv" : "Resheph"
	},
	"H7567" : {
		"lemma" : "רָשַׁשׁ",
		"xlit" : "râshash",
		"pron" : "raw-shash'",
		"derivation" : "a primitive root",
		"def" : "to demolish",
		"kjv" : "impoverish"
	},
	"H7568" : {
		"lemma" : "רֶשֶׁת",
		"xlit" : "resheth",
		"pron" : "reh'-sheth",
		"derivation" : "from H3423 (יָרַשׁ)",
		"def" : "a net (as catching animals)",
		"kjv" : "net(-work)"
	},
	"H7569" : {
		"lemma" : "רַתּוֹק",
		"xlit" : "rattôwq",
		"pron" : "rat-toke'",
		"derivation" : "from H7576 (רָתַק)",
		"def" : "a chain",
		"kjv" : "chain"
	},
	"H7570" : {
		"lemma" : "רָתַח",
		"xlit" : "râthach",
		"pron" : "raw-thakh'",
		"derivation" : "a primitive root",
		"def" : "to boil",
		"kjv" : "boil"
	},
	"H7571" : {
		"lemma" : "רֶתַח",
		"xlit" : "rethach",
		"pron" : "reh'-thakh",
		"derivation" : "from H7570 (רָתַח)",
		"def" : "a boiling",
		"kjv" : "[idiom] (boil) well"
	},
	"H7572" : {
		"lemma" : "רַתִּיקָה",
		"xlit" : "rattîyqâh",
		"pron" : "rat-tee-kaw'",
		"derivation" : "from H7576 (רָתַק)",
		"def" : "a chain",
		"kjv" : "chain"
	},
	"H7573" : {
		"lemma" : "רָתַם",
		"xlit" : "râtham",
		"pron" : "raw-tham'",
		"derivation" : "a primitive root",
		"def" : "to yoke up (to the pole of a vehicle)",
		"kjv" : "bind"
	},
	"H7574" : {
		"lemma" : "רֶתֶם",
		"xlit" : "rethem",
		"pron" : "reh'-them",
		"derivation" : "or רֹתֶם; from H7573 (רָתַם)",
		"def" : "the Spanish broom (from its pole-like stems)",
		"kjv" : "juniper (tree)"
	},
	"H7575" : {
		"lemma" : "רִתְמָה",
		"xlit" : "Rithmâh",
		"pron" : "rith-maw'",
		"derivation" : "feminine of H7574 (רֶתֶם)",
		"def" : "Rithmah, a place in the Desert",
		"kjv" : "Rithmah"
	},
	"H7576" : {
		"lemma" : "רָתַק",
		"xlit" : "râthaq",
		"pron" : "raw-thak'",
		"derivation" : "a primitive root",
		"def" : "to fasten",
		"kjv" : "bind"
	},
	"H7577" : {
		"lemma" : "רְתֻקָה",
		"xlit" : "rᵉthuqâh",
		"pron" : "reth-oo-kaw'",
		"derivation" : "feminine passive participle of H7576 (רָתַק)",
		"def" : "something fastened, i.e. a chain",
		"kjv" : "chain"
	},
	"H7578" : {
		"lemma" : "רְתֵת",
		"xlit" : "rᵉthêth",
		"pron" : "reth-ayth'",
		"derivation" : "for H7374 (רֶטֶט)",
		"def" : "terror",
		"kjv" : "trembling. �"
	},
	"H7579" : {
		"lemma" : "שָׁאַב",
		"xlit" : "shâʼab",
		"pron" : "sahw-ab'",
		"derivation" : "a primitive root",
		"def" : "to bale up water",
		"kjv" : "(woman to) draw(-er, water)"
	},
	"H7580" : {
		"lemma" : "שָׁאַג",
		"xlit" : "shâʼag",
		"pron" : "shaw-ag'",
		"derivation" : "a primitive root",
		"def" : "to rumble or moan",
		"kjv" : "[idiom] mightily, roar"
	},
	"H7581" : {
		"lemma" : "שְׁאָגָה",
		"xlit" : "shᵉʼâgâh",
		"pron" : "sheh-aw-gaw'",
		"derivation" : "from H7580 (שָׁאַג)",
		"def" : "a rumbling or moan",
		"kjv" : "roaring"
	},
	"H7582" : {
		"lemma" : "שָׁאָה",
		"xlit" : "shâʼâh",
		"pron" : "shaw-aw'",
		"derivation" : "a primitive root",
		"def" : "to rush; by implication, to desolate",
		"kjv" : "be desolate, (make a) rush(-ing), (lay) waste"
	},
	"H7583" : {
		"lemma" : "שָׁאָה",
		"xlit" : "shâʼâh",
		"pron" : "shaw-aw'",
		"derivation" : "a primitive root (identical with through the idea of whirling to giddiness)",
		"def" : "to stun, i.e. (intransitively) be astonished",
		"kjv" : "wonder"
	},
	"H7584" : {
		"lemma" : "שַׁאֲוָה",
		"xlit" : "shaʼăvâh",
		"pron" : "shah-av-aw'",
		"derivation" : "from H7582 (שָׁאָה)",
		"def" : "a tempest (as rushing)",
		"kjv" : "desolation"
	},
	"H7585" : {
		"lemma" : "שְׁאוֹל",
		"xlit" : "shᵉʼôwl",
		"pron" : "sheh-ole'",
		"derivation" : "or שְׁאֹל; from H7592 (שָׁאַל)",
		"def" : "Hades or the world of the dead (as if a subterranean retreat), including its accessories and inmates",
		"kjv" : "grave, hell, pit"
	},
	"H7586" : {
		"lemma" : "שָׁאוּל",
		"xlit" : "Shâʼûwl",
		"pron" : "shaw-ool'",
		"derivation" : "passive participle of H7592 (שָׁאַל); asked",
		"def" : "Shaul, the name of an Edomite and two Israelites",
		"kjv" : "Saul, Shaul"
	},
	"H7587" : {
		"lemma" : "שָׁאוּלִי",
		"xlit" : "Shâʼûwlîy",
		"pron" : "shaw-oo-lee'",
		"derivation" : "patronymic from H7856 (שִׂטְנָה)",
		"def" : "a Shaulite or descendant of Shaul",
		"kjv" : "Shaulites"
	},
	"H7588" : {
		"lemma" : "שָׁאוֹן",
		"xlit" : "shâʼôwn",
		"pron" : "shaw-one'",
		"derivation" : "from H7582 (שָׁאָה)",
		"def" : "uproar (as of rushing); by implication, destruction",
		"kjv" : "[idiom] horrible, noise, pomp, rushing, tumult ([idiom] -uous)"
	},
	"H7589" : {
		"lemma" : "שְׁאָט",
		"xlit" : "shᵉʼâṭ",
		"pron" : "sheh-awt'",
		"derivation" : "from an unused root meaning to push aside",
		"def" : "contempt",
		"kjv" : "despite(-ful)"
	},
	"H7590" : {
		"lemma" : "שָׁאט",
		"xlit" : "shâʼṭ",
		"pron" : "shawt",
		"derivation" : "for active part of H7750 (שׂוּט) (compare H7589 (שְׁאָט))",
		"def" : "one contemning",
		"kjv" : "that (which) despise(-d)"
	},
	"H7591" : {
		"lemma" : "שְׁאִיָּה",
		"xlit" : "shᵉʼîyâh",
		"pron" : "sheh-ee-yaw'",
		"derivation" : "from H7582 (שָׁאָה)",
		"def" : "desolation",
		"kjv" : "destruction"
	},
	"H7592" : {
		"lemma" : "שָׁאַל",
		"xlit" : "shâʼal",
		"pron" : "shaw-al'",
		"derivation" : "or שָׁאֵל; a primitive root",
		"def" : "to inquire; by implication, to request; by extension, to demand",
		"kjv" : "ask (counsel, on), beg, borrow, lay to charge, consult, demand, desire, [idiom] earnestly, enquire, [phrase] greet, obtain leave, lend, pray, request, require, [phrase] salute, [idiom] straitly, [idiom] surely, wish"
	},
	"H7593" : {
		"lemma" : "שְׁאֵל",
		"xlit" : "shᵉʼêl",
		"pron" : "sheh-ale'",
		"derivation" : "(Aramaic) corresponding to H7592 (שָׁאַל)",
		"def" : "{to inquire; by implication, to request; by extension, to demand}",
		"kjv" : "ask, demand, require"
	},
	"H7594" : {
		"lemma" : "שְׁאָל",
		"xlit" : "Shᵉʼâl",
		"pron" : "sheh-awl'",
		"derivation" : "from H7592 (שָׁאַל); request",
		"def" : "Sheal, an Israelite",
		"kjv" : "Sheal"
	},
	"H7595" : {
		"lemma" : "שְׁאֵלָא",
		"xlit" : "shᵉʼêlâʼ",
		"pron" : "sheh-ay-law'",
		"derivation" : "(Aramaic) from H7593 (שְׁאֵל)",
		"def" : "properly, a question (at law), i.e. judicial decision or mandate",
		"kjv" : "demand"
	},
	"H7596" : {
		"lemma" : "שְׁאֵלָה",
		"xlit" : "shᵉʼêlâh",
		"pron" : "sheh-ay-law'",
		"derivation" : "or שֵׁלָה; (1 Samuel 1:17), from H7592 (שָׁאַל)",
		"def" : "a petition; by implication, a loan",
		"kjv" : "loan, petition, request"
	},
	"H7597" : {
		"lemma" : "שְׁאַלְתִּיאֵל",
		"xlit" : "Shᵉʼaltîyʼêl",
		"pron" : "sheh-al-tee-ale'",
		"derivation" : "or שַׁלְתִּיאֵל; from H7592 (שָׁאַל) and H410 (אֵל); I have asked God",
		"def" : "Shealtiel, an Israelite",
		"kjv" : "Shalthiel, Shealtiel"
	},
	"H7598" : {
		"lemma" : "שְׁאַלְתִּיאֵל",
		"xlit" : "Shᵉʼaltîyʼêl",
		"pron" : "sheh-al-tee-ale'",
		"derivation" : "(Aramaic) corresponding to H7597 (שְׁאַלְתִּיאֵל)",
		"def" : "{Shealtiel, an Israelite}",
		"kjv" : "Shealtiel"
	},
	"H7599" : {
		"lemma" : "שָׁאַן",
		"xlit" : "shâʼan",
		"pron" : "shaw-an'",
		"derivation" : "a primitive root",
		"def" : "to loll, i.e. be peaceful",
		"kjv" : "be at ease, be quiet, rest. See also H1052 (בֵּית שְׁאָן)"
	},
	"H7600" : {
		"lemma" : "שַׁאֲנָן",
		"xlit" : "shaʼănân",
		"pron" : "shah-an-awn'",
		"derivation" : "from H7599 (שָׁאַן)",
		"def" : "secure; in a bad sense, haughty",
		"kjv" : "that is at ease, quiet, tumult. Compare H7946 (שַׁלְאֲנָן)"
	},
	"H7601" : {
		"lemma" : "שָׁאַס",
		"xlit" : "shâʼaç",
		"pron" : "shaw-as'",
		"derivation" : "a primitive root",
		"def" : "to plunder",
		"kjv" : "spoil"
	},
	"H7602" : {
		"lemma" : "שָׁאַף",
		"xlit" : "shâʼaph",
		"pron" : "shaw-af'",
		"derivation" : "a primitive root",
		"def" : "to inhale eagerly; figuratively, to cover; by implication, to be angry; also to hasten",
		"kjv" : "desire (earnestly), devour, haste, pant, snuff up, swallow up"
	},
	"H7603" : {
		"lemma" : "שְׂאֹר",
		"xlit" : "sᵉʼôr",
		"pron" : "seh-ore'",
		"derivation" : "from H7604 (שָׁאַר)",
		"def" : "barm or yeast-cake (as swelling by fermentation)",
		"kjv" : "leaven"
	},
	"H7604" : {
		"lemma" : "שָׁאַר",
		"xlit" : "shâʼar",
		"pron" : "shaw-ar'",
		"derivation" : "a primitive root",
		"def" : "properly, to swell up, i.e. be (causatively, make) redundant",
		"kjv" : "leave, (be) left, let, remain, remnant, reserve, the rest"
	},
	"H7605" : {
		"lemma" : "שְׁאָר",
		"xlit" : "shᵉʼâr",
		"pron" : "sheh-awr'",
		"derivation" : "from H7604 (שָׁאַר)",
		"def" : "a remainder",
		"kjv" : "[idiom] other, remnant, residue, rest"
	},
	"H7606" : {
		"lemma" : "שְׁאָר",
		"xlit" : "shᵉʼâr",
		"pron" : "sheh-awr'",
		"derivation" : "(Aramaic) corresponding to H7605 (שְׁאָר)",
		"def" : "{a remainder}",
		"kjv" : "[idiom] whatsoever more, residue, rest"
	},
	"H7607" : {
		"lemma" : "שְׁאֵר",
		"xlit" : "shᵉʼêr",
		"pron" : "sheh-ayr'",
		"derivation" : "from H7604 (שָׁאַר)",
		"def" : "flesh (as swelling out), as living or forfood; generally food of any kind; figuratively, kindred by blood",
		"kjv" : "body, flesh, food, (near) kin(-sman, -swoman), near (nigh) (of kin)"
	},
	"H7608" : {
		"lemma" : "שַׁאֲרָה",
		"xlit" : "shaʼărâh",
		"pron" : "shah-ar-aw'",
		"derivation" : "feminine of H7607 (שְׁאֵר)",
		"def" : "female kindred by blood",
		"kjv" : "near kinswomen"
	},
	"H7609" : {
		"lemma" : "שֶׁאֱרָה",
		"xlit" : "Sheʼĕrâh",
		"pron" : "sheh-er-aw'",
		"derivation" : "the same as H7608 (שַׁאֲרָה)",
		"def" : "Sheerah, an Israelitess",
		"kjv" : "Sherah"
	},
	"H7610" : {
		"lemma" : "שְׁאָר יָשׁוּב",
		"xlit" : "Shᵉʼâr Yâshûwb",
		"pron" : "sheh-awr'yaw-shoob'",
		"derivation" : "from H7605 (שְׁאָר) and H7725 (שׁוּב); a remnant will return",
		"def" : "Shear-Jashub, the symbolic name of one of Isaiah's sons",
		"kjv" : "Shear-jashub"
	},
	"H7611" : {
		"lemma" : "שְׁאֵרִית",
		"xlit" : "shᵉʼêrîyth",
		"pron" : "sheh-ay-reeth'",
		"derivation" : "from H7604 (שָׁאַר)",
		"def" : "a remainder or residual (surviving, final) portion",
		"kjv" : "that had escaped, be left, posterity, remain(-der), remnant, residue, rest"
	},
	"H7612" : {
		"lemma" : "שֵׁאת",
		"xlit" : "shêʼth",
		"pron" : "shayth",
		"derivation" : "from H7582 (שָׁאָה)",
		"def" : "devastation",
		"kjv" : "desolation"
	},
	"H7613" : {
		"lemma" : "שְׂאֵת",
		"xlit" : "sᵉʼêth",
		"pron" : "seh-ayth'",
		"derivation" : "from H5375 (נָשָׂא)",
		"def" : "an elevation or leprous scab; figuratively, elation or cheerfulness; exaltation in rank or character",
		"kjv" : "be accepted, dignity, excellency, highness, raise up self, rising"
	},
	"H7614" : {
		"lemma" : "שְׁבָא",
		"xlit" : "Shᵉbâʼ",
		"pron" : "sheb-aw'",
		"derivation" : "of foreign origin",
		"def" : "Sheba, the name of three early progenitors of tribes and of an Ethiopian district",
		"kjv" : "Sheba, Sabeans"
	},
	"H7615" : {
		"lemma" : "שְׁבָאִי",
		"xlit" : "Shᵉbâʼîy",
		"pron" : "sheb-aw-ee'",
		"derivation" : "patronymic from H7614 (שְׁבָא)",
		"def" : "a Shebaite or descendant of Sheba",
		"kjv" : "Sabean"
	},
	"H7616" : {
		"lemma" : "שָׁבָב",
		"xlit" : "shâbâb",
		"pron" : "shaw-bawb'",
		"derivation" : "from an unused root meaning to break up",
		"def" : "a fragment, i.e. ruin",
		"kjv" : "broken in pieces"
	},
	"H7617" : {
		"lemma" : "שָׁבָה",
		"xlit" : "shâbâh",
		"pron" : "shaw-baw'",
		"derivation" : "a primitive root",
		"def" : "to transport into captivity",
		"kjv" : "(bring away, carry, carry away, lead, lead away, take) captive(-s), drive (take) away"
	},
	"H7618" : {
		"lemma" : "שְׁבוּ",
		"xlit" : "shᵉbûw",
		"pron" : "sheb-oo'",
		"derivation" : "from an unused root (probably identical with that of H7617 (שָׁבָה) through the idea of subdivision into flashes or streamers (compare H7632 (שָׁבִיב)) meaning to flame",
		"def" : "a gem (from its sparkle), probably the agate",
		"kjv" : "agate"
	},
	"H7619" : {
		"lemma" : "שְׁבוּאֵל",
		"xlit" : "Shᵉbûwʼêl",
		"pron" : "sheb-oo-ale'",
		"derivation" : "or שׁוּבָאֵל; from H7617 (שָׁבָה) (abbreviated form) or H7725 (שׁוּב) and H410 (אֵל); captive (or returned) of God",
		"def" : "Shebuel or Shubael, the name of two Israelites",
		"kjv" : "Shebuel, Shubael"
	},
	"H7620" : {
		"lemma" : "שָׁבוּעַ",
		"xlit" : "shâbûwaʻ",
		"pron" : "shaw-boo'-ah",
		"derivation" : "or שָׁבֻעַ; also (feminine) שְׁבֻעָה; properly, passive participle of H7650 (שָׁבַע) as a denominative of H7651 (שֶׁבַע)",
		"def" : "literally, sevened, i.e. a week (specifically, of years)",
		"kjv" : "seven, week"
	},
	"H7621" : {
		"lemma" : "שְׁבוּעָה",
		"xlit" : "shᵉbûwʻâh",
		"pron" : "sheb-oo-aw'",
		"derivation" : "feminine passive participle of H7650 (שָׁבַע)",
		"def" : "properly, something sworn, i.e. an oath",
		"kjv" : "curse, oath, [idiom] sworn"
	},
	"H7622" : {
		"lemma" : "שְׁבוּת",
		"xlit" : "shᵉbûwth",
		"pron" : "sheb-ooth'",
		"derivation" : "or שְׁבִית; from H7617 (שָׁבָה)",
		"def" : "exile, concretely, prisoners; figuratively, a former state of prosperity",
		"kjv" : "captive(-ity)"
	},
	"H7623" : {
		"lemma" : "שָׁבַח",
		"xlit" : "shâbach",
		"pron" : "shaw-bakh'",
		"derivation" : "a primitive root",
		"def" : "properly, to address in aloud tone, i.e. (specifically) loud; figuratively, to pacify (as if by words)",
		"kjv" : "commend, glory, keep in, praise, still, triumph"
	},
	"H7624" : {
		"lemma" : "שְׁבַח",
		"xlit" : "shᵉbach",
		"pron" : "sheb-akh'",
		"derivation" : "(Aramaic) corresponding to H7623 (שָׁבַח)",
		"def" : "to adulate, i.e. adore",
		"kjv" : "praise"
	},
	"H7625" : {
		"lemma" : "שְׁבַט",
		"xlit" : "shᵉbaṭ",
		"pron" : "sheb-at'",
		"derivation" : "(Aramaic) corresponding to H7626 (שֵׁבֶט)",
		"def" : "a clan",
		"kjv" : "tribe"
	},
	"H7626" : {
		"lemma" : "שֵׁבֶט",
		"xlit" : "shêbeṭ",
		"pron" : "shay'-bet",
		"derivation" : "from an unused root probably meaning to branch off",
		"def" : "a scion, i.e. (literally) a stick (for punishing, writing, fighting, ruling, walking, etc.) or (figuratively) a clan",
		"kjv" : "[idiom] correction, dart, rod, sceptre, staff, tribe"
	},
	"H7627" : {
		"lemma" : "שְׁבָט",
		"xlit" : "Shᵉbâṭ",
		"pron" : "sheb-awt'",
		"derivation" : "of foreign origin",
		"def" : "Shebat, a Jewish month",
		"kjv" : "Sebat"
	},
	"H7628" : {
		"lemma" : "שְׁבִי",
		"xlit" : "shᵉbîy",
		"pron" : "sheb-ee'",
		"derivation" : "from H7618 (שְׁבוּ)",
		"def" : "exiled; captured; as noun, exile (abstractly or concretely and collectively); by extension, booty",
		"kjv" : "captive(-ity), prisoners, [idiom] take away, that was taken"
	},
	"H7629" : {
		"lemma" : "שֹׁבִי",
		"xlit" : "Shôbîy",
		"pron" : "sho-bee'",
		"derivation" : "from H7617 (שָׁבָה); captor",
		"def" : "Shobi, an Ammonite",
		"kjv" : "Shobi"
	},
	"H7630" : {
		"lemma" : "שֹׁבַי",
		"xlit" : "Shôbay",
		"pron" : "sho-bah'-ee",
		"derivation" : "for H7629 (שֹׁבִי)",
		"def" : "Shobai, an Israelite",
		"kjv" : "Shobai"
	},
	"H7631" : {
		"lemma" : "שְׂבִיב",
		"xlit" : "sᵉbîyb",
		"pron" : "seb-eeb'",
		"derivation" : "(Aramaic) corresponding to H7632 (שָׁבִיב)",
		"def" : "{flame (as split into tongues)}",
		"kjv" : "flame"
	},
	"H7632" : {
		"lemma" : "שָׁבִיב",
		"xlit" : "shâbîyb",
		"pron" : "shaw-beeb'",
		"derivation" : "from the same as H7616 (שָׁבָב)",
		"def" : "flame (as split into tongues)",
		"kjv" : "spark"
	},
	"H7633" : {
		"lemma" : "שִׁבְיָה",
		"xlit" : "shibyâh",
		"pron" : "shib-yaw'",
		"derivation" : "feminine of H7628 (שְׁבִי)",
		"def" : "exile (abstractly or concretely and collectively)",
		"kjv" : "captives(-ity)"
	},
	"H7634" : {
		"lemma" : "שׇׁבְיָה",
		"xlit" : "Shobyâh",
		"pron" : "shob-yaw'",
		"derivation" : "feminine of the same as H7629 (שֹׁבִי); captivation",
		"def" : "Shobjah, an Israelite",
		"kjv" : "Shachia (from the margin)"
	},
	"H7635" : {
		"lemma" : "שָׁבִיל",
		"xlit" : "shâbîyl",
		"pron" : "shaw-beel'",
		"derivation" : "from the same as H7640 (שֹׁבֶל)",
		"def" : "a track or passage-way (as if flowing along)",
		"kjv" : "path"
	},
	"H7636" : {
		"lemma" : "שָׁבִיס",
		"xlit" : "shâbîyç",
		"pron" : "shaw-beece'",
		"derivation" : "from an unused root meaning to interweave",
		"def" : "a netting for the hair",
		"kjv" : "caul"
	},
	"H7637" : {
		"lemma" : "שְׁבִיעִי",
		"xlit" : "shᵉbîyʻîy",
		"pron" : "sheb-ee-ee'",
		"derivation" : "or שְׁבִעִי; ordinal from H7657 (שִׁבְעִים)",
		"def" : "seventh",
		"kjv" : "seventh (time)"
	},
	"H7638" : {
		"lemma" : "שָׂבָךְ",
		"xlit" : "sâbâk",
		"pron" : "saw-bawk'",
		"derivation" : "from an unused root meaning to intwine",
		"def" : "a netting (ornament to the capital of a column)",
		"kjv" : "net"
	},
	"H7639" : {
		"lemma" : "שְׂבָכָה",
		"xlit" : "sᵉbâkâh",
		"pron" : "seb-aw-kaw'",
		"derivation" : "feminine of H7638 (שָׂבָךְ)",
		"def" : "a net-work, i.e. (in hunting) a snare, (in architecture) a ballustrade; also a reticulated ornament to apillar",
		"kjv" : "checker, lattice, network, snare, wreath(-enwork)"
	},
	"H7640" : {
		"lemma" : "שֹׁבֶל",
		"xlit" : "shôbel",
		"pron" : "show'-bel",
		"derivation" : "from an unused root meaning to flow",
		"def" : "a lady's train (as trailing after her)",
		"kjv" : "leg"
	},
	"H7641" : {
		"lemma" : "שִׁבֹּל",
		"xlit" : "shibbôl",
		"pron" : "shib-bole",
		"derivation" : "or (feminine) שִׁבֹּלֶת; from the same as H7640 (שֹׁבֶל)",
		"def" : "a stream (as flowing); also an ear of grain (as growing out); by analogy, a branch",
		"kjv" : "branch, channel, ear (of corn), (water-)flood, Shibboleth. Compare H5451 (סִבֹּלֶת)"
	},
	"H7642" : {
		"lemma" : "שַׁבְלוּל",
		"xlit" : "shablûwl",
		"pron" : "shab-lool'",
		"derivation" : "from the same as H7640 (שֹׁבֶל)",
		"def" : "a snail (as if floating in its own slime)",
		"kjv" : "snail"
	},
	"H7643" : {
		"lemma" : "שְׂבָם",
		"xlit" : "Sᵉbâm",
		"pron" : "seb-awm'",
		"derivation" : "or (feminine) שִׂבְמָה; probably from H1313 (בָּשָׂם); spice",
		"def" : "Sebam or Sibmah, a place in Moab",
		"kjv" : "Shebam, Shibmah, Sibmah"
	},
	"H7644" : {
		"lemma" : "שֶׁבְנָא",
		"xlit" : "Shebnâʼ",
		"pron" : "sheb-naw'",
		"derivation" : "or שֶׁבְנָה; from an unused root meaning to grow; growth",
		"def" : "Shebna or Shebnah, an Israelite",
		"kjv" : "Shebna, Shebnah"
	},
	"H7645" : {
		"lemma" : "שְׁבַנְיָה",
		"xlit" : "Shᵉbanyâh",
		"pron" : "sheb-an-yaw'",
		"derivation" : "or שְׁבַנְיָהוּ; from the same as H7644 (שֶׁבְנָא) and H3050 (יָהּ); Jah has grown (i.e. prospered)",
		"def" : "Shebanjah, the name of three or four Israelites",
		"kjv" : "Shebaniah"
	},
	"H7646" : {
		"lemma" : "שָׂבַע",
		"xlit" : "sâbaʻ",
		"pron" : "saw-bah'",
		"derivation" : "or שָׂבֵעַ; a primitive root",
		"def" : "to sate, i.e. fill to satisfaction (literally or figuratively)",
		"kjv" : "have enough, fill (full, self, with), be (to the) full (of), have plenty of, be satiate, satisfy (with), suffice, be weary of"
	},
	"H7647" : {
		"lemma" : "שָׂבָע",
		"xlit" : "sâbâʻ",
		"pron" : "saw-baw'",
		"derivation" : "from H7646 (שָׂבַע)",
		"def" : "copiousness",
		"kjv" : "abundance, plenteous(-ness, -ly)"
	},
	"H7648" : {
		"lemma" : "שֹׂבַע",
		"xlit" : "sôbaʻ",
		"pron" : "so'-bah",
		"derivation" : "from H7646 (שָׂבַע)",
		"def" : "satisfaction (of food or (figuratively) joy)",
		"kjv" : "fill, full(-ness), satisfying, be satisfied"
	},
	"H7649" : {
		"lemma" : "שָׂבֵעַ",
		"xlit" : "sâbêaʻ",
		"pron" : "saw-bay'-ah",
		"derivation" : "from H7646 (שָׂבַע)",
		"def" : "satiated (in a pleasant or disagreeable sense)",
		"kjv" : "full (of), satisfied (with)"
	},
	"H7650" : {
		"lemma" : "שָׁבַע",
		"xlit" : "shâbaʻ",
		"pron" : "shaw-bah'",
		"derivation" : "a primitive root; properly to be complete, but used only as a denominative from H7651 (שֶׁבַע)",
		"def" : "to seven oneself, i.e. swear (as if by repeating a declaration seven times)",
		"kjv" : "adjure, charge (by an oath, with an oath), feed to the full (by mistake for H7646 (שָׂבַע)), take an oath, [idiom] straitly, (cause to, make to) swear"
	},
	"H7651" : {
		"lemma" : "שֶׁבַע",
		"xlit" : "shebaʻ",
		"pron" : "sheh'-bah",
		"derivation" : "or (masculine) (שִׁבְעָה); from H7650 (שָׁבַע); a primitive cardinal number",
		"def" : "seven (as the sacred full one); also (adverbially) seven times; by implication, a week; by extension, an indefinite number",
		"kjv" : "([phrase] by) seven(-fold),-s, (-teen, -teenth), -th, times). Compare H7658 (שִׁבְעָנָה)"
	},
	"H7652" : {
		"lemma" : "שֶׁבַע",
		"xlit" : "shebaʻ",
		"pron" : "sheh'-bah",
		"derivation" : "the same as H7651 (שֶׁבַע); seven",
		"def" : "Sheba, the name of a place in Palestine, and of two Israelites",
		"kjv" : "Sheba"
	},
	"H7653" : {
		"lemma" : "שִׂבְעָה",
		"xlit" : "sibʻâh",
		"pron" : "sib-aw'",
		"derivation" : "feminine of H7647 (שָׂבָע)",
		"def" : "satiety",
		"kjv" : "fulness"
	},
	"H7654" : {
		"lemma" : "שׇׂבְעָה",
		"xlit" : "sobʻâh",
		"pron" : "sob-aw'",
		"derivation" : "feminine of H7648 (שֹׂבַע)",
		"def" : "satiety",
		"kjv" : "(to have) enough, [idiom] till...be full, (un-) satiable, satisfy, [idiom] sufficiently"
	},
	"H7655" : {
		"lemma" : "שִׁבְעָה",
		"xlit" : "shibʻâh",
		"pron" : "shib-aw'",
		"derivation" : "(Aramaic) corresponding to H7651 (שֶׁבַע)",
		"def" : "{seven (as the sacred full one); also (adverbially) seven times; by implication, a week; by extension, an indefinite number}",
		"kjv" : "seven (times)"
	},
	"H7656" : {
		"lemma" : "שִׁבְעָה",
		"xlit" : "Shibʻâh",
		"pron" : "shib-aw'",
		"derivation" : "masculine of H7651 (שֶׁבַע); seven(-th)",
		"def" : "Shebah, a well in Palestine",
		"kjv" : "Shebah"
	},
	"H7657" : {
		"lemma" : "שִׁבְעִים",
		"xlit" : "shibʻîym",
		"pron" : "shib-eem'",
		"derivation" : "multiple of H7651 (שֶׁבַע)",
		"def" : "seventy",
		"kjv" : "seventy, threescore and ten ([phrase] -teen)"
	},
	"H7658" : {
		"lemma" : "שִׁבְעָנָה",
		"xlit" : "shibʻânâh",
		"pron" : "shib-aw-naw'",
		"derivation" : "prolonged for the masculine of H7651 (שֶׁבַע)",
		"def" : "seven",
		"kjv" : "seven"
	},
	"H7659" : {
		"lemma" : "שִׁבְעָתַיִם",
		"xlit" : "shibʻâthayim",
		"pron" : "shib-aw-thah'-yim",
		"derivation" : "dual (adverbially) of H7651 (שֶׁבַע)",
		"def" : "seven-times",
		"kjv" : "seven(-fold, times)"
	},
	"H7660" : {
		"lemma" : "שָׁבַץ",
		"xlit" : "shâbats",
		"pron" : "shaw-bats'",
		"derivation" : "a primitive root",
		"def" : "to interweave (colored) threads in squares; by implication (of reticulation) to inchase gems in gold",
		"kjv" : "embroider, set"
	},
	"H7661" : {
		"lemma" : "שָׁבָץ",
		"xlit" : "shâbâts",
		"pron" : "shaw-bawts'",
		"derivation" : "from H7660 (שָׁבַץ)",
		"def" : "entanglement, i.e. (figuratively) perplexity",
		"kjv" : "anguish"
	},
	"H7662" : {
		"lemma" : "שְׁבַק",
		"xlit" : "shᵉbaq",
		"pron" : "sheb-ak'",
		"derivation" : "(Aramaic) corresponding to the root of H7733 (שׁוֹבֵק)",
		"def" : "to quit, i.e. allow to remain",
		"kjv" : "leave, let alone"
	},
	"H7663" : {
		"lemma" : "שָׂבַר",
		"xlit" : "sâbar",
		"pron" : "saw-bar'",
		"derivation" : "erroneously שָׁבַר; (Nehemiah 2:13,15), a primitive root",
		"def" : "to scrutinize; by implication (of watching) to expect (with hope and patience)",
		"kjv" : "hope, tarry, view, wait"
	},
	"H7664" : {
		"lemma" : "שֵׂבֶר",
		"xlit" : "sêber",
		"pron" : "say'-ber",
		"derivation" : "from H7663 (שָׂבַר)",
		"def" : "expectation",
		"kjv" : "hope"
	},
	"H7665" : {
		"lemma" : "שָׁבַר",
		"xlit" : "shâbar",
		"pron" : "shaw-bar'",
		"derivation" : "a primitive root",
		"def" : "to burst (literally or figuratively)",
		"kjv" : "break (down, off, in pieces, up), broken (-hearted), bring to the birth, crush, destroy, hurt, quench, [idiom] quite, tear, view (by mistake for H7663 (שָׂבַר))"
	},
	"H7666" : {
		"lemma" : "שָׁבַר",
		"xlit" : "shâbar",
		"pron" : "shaw-bar'",
		"derivation" : "denominative from H7668 (שֶׁבֶר)",
		"def" : "to deal in grain",
		"kjv" : "buy, sell"
	},
	"H7667" : {
		"lemma" : "שֶׁבֶר",
		"xlit" : "sheber",
		"pron" : "sheh'-ber",
		"derivation" : "or שֵׁבֶר; from H7665 (שָׁבַר)",
		"def" : "a fracture, figuratively, ruin; specifically, a solution (of a dream)",
		"kjv" : "affliction, breach, breaking, broken(-footed, -handed), bruise, crashing, destruction, hurt, interpretation, vexation"
	},
	"H7668" : {
		"lemma" : "שֶׁבֶר",
		"xlit" : "sheber",
		"pron" : "sheh'-ber",
		"derivation" : "the same as H7667 (שֶׁבֶר)",
		"def" : "grain (as if broken into kernels)",
		"kjv" : "corn, victuals"
	},
	"H7669" : {
		"lemma" : "שֶׁבֶר",
		"xlit" : "Sheber",
		"pron" : "sheh'-ber",
		"derivation" : "the same as H7667 (שֶׁבֶר)",
		"def" : "Sheber, an Israelite",
		"kjv" : "Sheber"
	},
	"H7670" : {
		"lemma" : "שִׁבְרוֹן",
		"xlit" : "shibrôwn",
		"pron" : "shib-rone'",
		"derivation" : "from H7665 (שָׁבַר)",
		"def" : "rupture, i.e. a pang; figuratively, ruin",
		"kjv" : "breaking, destruction"
	},
	"H7671" : {
		"lemma" : "שְׁבָרִים",
		"xlit" : "Shᵉbârîym",
		"pron" : "sheb-aw-reem'",
		"derivation" : "plural of H7667 (שֶׁבֶר); ruins",
		"def" : "Shebarim, a place in Palestine",
		"kjv" : "Shebarim"
	},
	"H7672" : {
		"lemma" : "שְׁבַשׁ",
		"xlit" : "shᵉbash",
		"pron" : "sheb-ash'",
		"derivation" : "(Aramaic) corresponding to H7660 (שָׁבַץ)",
		"def" : "to entangle, i.e. perplex",
		"kjv" : "be astonished"
	},
	"H7673" : {
		"lemma" : "שָׁבַת",
		"xlit" : "shâbath",
		"pron" : "shaw-bath'",
		"derivation" : "a primitive root",
		"def" : "to repose, i.e. desist from exertion; used in many implied relations (causative, figurative or specific)",
		"kjv" : "(cause to, let, make to) cease, celebrate, cause (make) to fail, keep (sabbath), suffer to be lacking, leave, put away (down), (make to) rest, rid, still, take away"
	},
	"H7674" : {
		"lemma" : "שֶׁבֶת",
		"xlit" : "shebeth",
		"pron" : "sheh'-beth",
		"derivation" : "from H7673 (שָׁבַת)",
		"def" : "rest, interruption, cessation",
		"kjv" : "cease, sit still, loss of time"
	},
	"H7675" : {
		"lemma" : "שֶׁבֶת",
		"xlit" : "shebeth",
		"pron" : "sheh'-beth",
		"derivation" : "infinitive of H3427 (יָשַׁב)",
		"def" : "properly, session; but used also concretely, an abode or locality",
		"kjv" : "place, seat. Compare H3429 (יֹשֵׁב בַּשֶּׁבֶת)"
	},
	"H7676" : {
		"lemma" : "שַׁבָּת",
		"xlit" : "shabbâth",
		"pron" : "shab-bawth'",
		"derivation" : "intensive from H7673 (שָׁבַת)",
		"def" : "intermission, i.e (specifically) the Sabbath",
		"kjv" : "([phrase] every) sabbath"
	},
	"H7677" : {
		"lemma" : "שַׁבָּתוֹן",
		"xlit" : "shabbâthôwn",
		"pron" : "shab-baw-thone'",
		"derivation" : "from H7676 (שַׁבָּת)",
		"def" : "a sabbatism or special holiday",
		"kjv" : "rest, sabbath"
	},
	"H7678" : {
		"lemma" : "שַׁבְּתַי",
		"xlit" : "Shabbᵉthay",
		"pron" : "shab-beth-ah'-ee",
		"derivation" : "from H7676 (שַׁבָּת); restful",
		"def" : "Shabbethai, the name of three Israelites",
		"kjv" : "Shabbethai"
	},
	"H7679" : {
		"lemma" : "שָׂגָא",
		"xlit" : "sâgâʼ",
		"pron" : "saw-gaw'",
		"derivation" : "; a primitive root",
		"def" : "to grow, i.e. (causatively) to enlarge, (figuratively) laud",
		"kjv" : "increase, magnify"
	},
	"H7680" : {
		"lemma" : "שְׂגָא",
		"xlit" : "sᵉgâʼ",
		"pron" : "seg-aw'",
		"derivation" : "(Aramaic) corresponding to H7679 (שָׂגָא)",
		"def" : "to increase",
		"kjv" : "grow, be multiplied"
	},
	"H7681" : {
		"lemma" : "שָׁגֶא",
		"xlit" : "Shâgeʼ",
		"pron" : "shaw-gay'",
		"derivation" : "probably from H7686 (שָׁגָה); erring",
		"def" : "Shage, an Israelite",
		"kjv" : "Shage"
	},
	"H7682" : {
		"lemma" : "שָׂגַב",
		"xlit" : "sâgab",
		"pron" : "saw-gab'",
		"derivation" : "a primitive root",
		"def" : "to be (causatively, make) lofty, especially inaccessible; by implication, safe, strong; used literally and figuratively",
		"kjv" : "defend, exalt, be excellent, (be, set on) high, lofty, be safe, set up (on high), be too strong"
	},
	"H7683" : {
		"lemma" : "שָׁגַג",
		"xlit" : "shâgag",
		"pron" : "shaw-gag'",
		"derivation" : "a primitive root",
		"def" : "to stray, i.e. (figuratively) sin (with more or less apology)",
		"kjv" : "[idiom] also for that, deceived, err, go astray, sin ignorantly"
	},
	"H7684" : {
		"lemma" : "שְׁגָגָה",
		"xlit" : "shᵉgâgâh",
		"pron" : "sheg-aw-gaw'",
		"derivation" : "from H7683 (שָׁגַג)",
		"def" : "a mistake or inadvertent transgression",
		"kjv" : "error, ignorance, at unawares; unwittingly"
	},
	"H7685" : {
		"lemma" : "שָׂגָה",
		"xlit" : "sâgâh",
		"pron" : "saw-gaw'",
		"derivation" : "a primitive root",
		"def" : "to enlarge (especially upward, also figuratively)",
		"kjv" : "grow (up), increase"
	},
	"H7686" : {
		"lemma" : "שָׁגָה",
		"xlit" : "shâgâh",
		"pron" : "shaw-gaw'",
		"derivation" : "a primitive root",
		"def" : "to stray (causatively, mislead), usually (figuratively) to mistake, especially (morally) to transgress; by extension (through the idea of intoxication) to reel, (figuratively) be enraptured",
		"kjv" : "(cause to) go astray, deceive, err, be ravished, sin through ignorance, (let, make to) wander"
	},
	"H7687" : {
		"lemma" : "שְׂגוּב",
		"xlit" : "Sᵉgûwb",
		"pron" : "seg-oob'",
		"derivation" : "from H7682 (שָׂגַב); aloft",
		"def" : "Segub, the name of two Israelites",
		"kjv" : "Segub"
	},
	"H7688" : {
		"lemma" : "שָׁגַח",
		"xlit" : "shâgach",
		"pron" : "shaw-gakh'",
		"derivation" : "a primitive root",
		"def" : "to peep, i.e. glance sharply at",
		"kjv" : "look (narrowly)"
	},
	"H7689" : {
		"lemma" : "שַׂגִּיא",
		"xlit" : "saggîyʼ",
		"pron" : "sag-ghee'",
		"derivation" : "from H7679 (שָׂגָא)",
		"def" : "(superlatively) mighty",
		"kjv" : "excellent, great"
	},
	"H7690" : {
		"lemma" : "שַׂגִּיא",
		"xlit" : "saggîyʼ",
		"pron" : "sag-ghee'",
		"derivation" : "(Aramaic) corresponding to H7689 (שַׂגִּיא)",
		"def" : "large (in size, quantity or number, also adverbial)",
		"kjv" : "exceeding, great(-ly); many, much, sore, very"
	},
	"H7691" : {
		"lemma" : "שְׁגִיאָה",
		"xlit" : "shᵉgîyʼâh",
		"pron" : "sheg-ee-aw'",
		"derivation" : "from H7686 (שָׁגָה)",
		"def" : "a moral mistake",
		"kjv" : "error"
	},
	"H7692" : {
		"lemma" : "שִׁגָּיוֹן",
		"xlit" : "shiggâyôwn",
		"pron" : "shig-gaw-yone'",
		"derivation" : "or שִׁגָּיֹנָה; from H7686 (שָׁגָה)",
		"def" : "properly, aberration, i.e. (technically) a dithyramb or rambling poem",
		"kjv" : "Shiggaion, Shigionoth"
	},
	"H7693" : {
		"lemma" : "שָׁגַל",
		"xlit" : "shâgal",
		"pron" : "shaw-gal'",
		"derivation" : "a primitive root",
		"def" : "to copulate with",
		"kjv" : "lie with, ravish"
	},
	"H7694" : {
		"lemma" : "שֵׁגָל",
		"xlit" : "shêgâl",
		"pron" : "shay-gawl'",
		"derivation" : "from H7693 (שָׁגַל)",
		"def" : "a queen (from cohabitation)",
		"kjv" : "queen"
	},
	"H7695" : {
		"lemma" : "שֵׁגָל",
		"xlit" : "shêgâl",
		"pron" : "shay-gawl'",
		"derivation" : "(Aramaic) corresponding to H7694 (שֵׁגָל)",
		"def" : "a (legitimate) queen",
		"kjv" : "wife"
	},
	"H7696" : {
		"lemma" : "שָׁגַע",
		"xlit" : "shâgaʻ",
		"pron" : "shaw-gah'",
		"derivation" : "a primitive root",
		"def" : "to rave through insanity",
		"kjv" : "(be, play the) mad (man)"
	},
	"H7697" : {
		"lemma" : "שִׁגָּעוֹן",
		"xlit" : "shiggâʻôwn",
		"pron" : "shig-gaw-yone'",
		"derivation" : "from H7696 (שָׁגַע)",
		"def" : "craziness",
		"kjv" : "furiously, madness"
	},
	"H7698" : {
		"lemma" : "שֶׁגֶר",
		"xlit" : "sheger",
		"pron" : "sheh'-ger",
		"derivation" : "from an unused root probably meaning to eject",
		"def" : "the fetus (as finally expelled)",
		"kjv" : "that cometh of, increase"
	},
	"H7699" : {
		"lemma" : "שַׁד",
		"xlit" : "shad",
		"pron" : "shad",
		"derivation" : "or שֹׁד; probably from H7736 (שׁוּד) (in its original sense) contracted",
		"def" : "the breast of a woman or animal (as bulging)",
		"kjv" : "breast, pap, teat"
	},
	"H7700" : {
		"lemma" : "שֵׁד",
		"xlit" : "shêd",
		"pron" : "shade",
		"derivation" : "from H7736 (שׁוּד)",
		"def" : "a doemon (as malignant)",
		"kjv" : "devil"
	},
	"H7701" : {
		"lemma" : "שֹׁד",
		"xlit" : "shôd",
		"pron" : "shode",
		"derivation" : "or שׁוֹד; (Job 5:21), from H7736 (שׁוּד)",
		"def" : "violence, ravage",
		"kjv" : "desolation, destruction, oppression, robbery, spoil(-ed, -er, -ing), wasting"
	},
	"H7702" : {
		"lemma" : "שָׂדַד",
		"xlit" : "sâdad",
		"pron" : "saw-dad'",
		"derivation" : "a primitive root",
		"def" : "to abrade, i.e. harrow afield",
		"kjv" : "break clods, harrow"
	},
	"H7703" : {
		"lemma" : "שָׁדַד",
		"xlit" : "shâdad",
		"pron" : "shaw-dad'",
		"derivation" : "a primitive root",
		"def" : "properly, to be burly, i.e. (figuratively) powerful (passively, impregnable); by implication, to ravage",
		"kjv" : "dead, destroy(-er), oppress, robber, spoil(-er), [idiom] utterly, (lay) waste"
	},
	"H7704" : {
		"lemma" : "שָׂדֶה",
		"xlit" : "sâdeh",
		"pron" : "saw-deh'",
		"derivation" : "or שָׂדַי; from an unused root meaning to spread out",
		"def" : "a field (as flat)",
		"kjv" : "country, field, ground, land, soil, [idiom] wild"
	},
	"H7705" : {
		"lemma" : "שִׁדָּה",
		"xlit" : "shiddâh",
		"pron" : "shid-dah'",
		"derivation" : "from H7703 (שָׁדַד)",
		"def" : "a wife (as mistress of the house)",
		"kjv" : "[idiom] all sorts, musical instrument"
	},
	"H7706" : {
		"lemma" : "שַׁדַּי",
		"xlit" : "Shadday",
		"pron" : "shad-dah'-ee",
		"derivation" : "from H7703 (שָׁדַד)",
		"def" : "the Almighty",
		"kjv" : "Almighty"
	},
	"H7707" : {
		"lemma" : "שְׁדֵיאוּר",
		"xlit" : "Shᵉdêyʼûwr",
		"pron" : "shed-ay-oor'",
		"derivation" : "from the same as H7704 (שָׂדֶה) and H217 (אוּר); spreader of light",
		"def" : "Shedejur, an Israelite",
		"kjv" : "Shedeur"
	},
	"H7708" : {
		"lemma" : "שִׂדִּים",
		"xlit" : "Siddîym",
		"pron" : "sid-deem'",
		"derivation" : "plural from the same as H7704 (שָׂדֶה); flats",
		"def" : "Siddim, a valley in Palestine",
		"kjv" : "Siddim"
	},
	"H7709" : {
		"lemma" : "שְׁדֵמָה",
		"xlit" : "shᵉdêmâh",
		"pron" : "shed-ay-maw'",
		"derivation" : "apparently from H7704 (שָׂדֶה)",
		"def" : "a cultivated field",
		"kjv" : "blasted, field"
	},
	"H7710" : {
		"lemma" : "שָׁדַף",
		"xlit" : "shâdaph",
		"pron" : "shaw-daf'",
		"derivation" : "a primitive root",
		"def" : "to scorch",
		"kjv" : "blast"
	},
	"H7711" : {
		"lemma" : "שְׁדֵפָה",
		"xlit" : "shᵉdêphâh",
		"pron" : "shed-ay-faw'",
		"derivation" : "or שִׁדָּפוֹן; from H7710 (שָׁדַף)",
		"def" : "blight",
		"kjv" : "blasted(-ing)"
	},
	"H7712" : {
		"lemma" : "שְׁדַר",
		"xlit" : "shᵉdar",
		"pron" : "shed-ar'",
		"derivation" : "(Aramaic) a primitive root",
		"def" : "to endeavor",
		"kjv" : "labour"
	},
	"H7713" : {
		"lemma" : "שְׂדֵרָה",
		"xlit" : "sᵉdêrâh",
		"pron" : "sed-ay-raw'",
		"derivation" : "from an unused root meaning to regulate",
		"def" : "a row, i.e. rank (of soldiers), story (of rooms)",
		"kjv" : "board, range"
	},
	"H7714" : {
		"lemma" : "שַׁדְרַךְ",
		"xlit" : "Shadrak",
		"pron" : "shad-rak'",
		"derivation" : "probably of foreign origin",
		"def" : "Shadrak, the Babylonian name of one of Daniel's companions",
		"kjv" : "Shadrach"
	},
	"H7715" : {
		"lemma" : "שַׁדְרַךְ",
		"xlit" : "Shadrak",
		"pron" : "shad-rak'",
		"derivation" : "(Aramaic) the same as H7714 (שַׁדְרַךְ)",
		"def" : "{Shadrak, the Babylonian name of one of Daniel's companions}",
		"kjv" : "Shadrach"
	},
	"H7716" : {
		"lemma" : "שֶׂה",
		"xlit" : "seh",
		"pron" : "seh",
		"derivation" : "or שֵׂי; probably from H7582 (שָׁאָה) through the idea of pushing out to graze",
		"def" : "a member of a flock, i.e. a sheep or goat",
		"kjv" : "(lesser, small) cattle, ewe, goat, lamb, sheep. Compare H2089 (זֶה)"
	},
	"H7717" : {
		"lemma" : "שָׂהֵד",
		"xlit" : "sâhêd",
		"pron" : "saw-hade'",
		"derivation" : "from an unused root meaning to testify",
		"def" : "a witness",
		"kjv" : "record"
	},
	"H7718" : {
		"lemma" : "שֹׁהַם",
		"xlit" : "shôham",
		"pron" : "sho'-ham",
		"derivation" : "from an unused root probably mean to blanch",
		"def" : "a gem, probably the beryl (from its pale green color)",
		"kjv" : "onyx"
	},
	"H7719" : {
		"lemma" : "שֹׁהַם",
		"xlit" : "Shôham",
		"pron" : "sho'-ham",
		"derivation" : "the same as H7718 (שֹׁהַם)",
		"def" : "Shoham, an Israelite",
		"kjv" : "Shoham"
	},
	"H7720" : {
		"lemma" : "שַׂהֲרֹן",
		"xlit" : "sahărôn",
		"pron" : "sah-har-one'",
		"derivation" : "from the same as H5469 (סַהַר)",
		"def" : "a round pendant for the neck",
		"kjv" : "ornament, round tire like the moon"
	},
	"H7721" : {
		"lemma" : "שׂוֹא",
		"xlit" : "sôwʼ",
		"pron" : "so",
		"derivation" : "from an unused root (akin to H5375 (נָשָׂא) and H7722 (שׁוֹא)) meaning to rise",
		"def" : "a rising",
		"kjv" : "arise"
	},
	"H7722" : {
		"lemma" : "שׁוֹא",
		"xlit" : "shôwʼ",
		"pron" : "sho",
		"derivation" : "or (feminine) שׁוֹאָה; or שֹׁאָה; from an unused root meaning to rush over",
		"def" : "a tempest; by implication, devastation",
		"kjv" : "desolate(-ion), destroy, destruction, storm, wasteness"
	},
	"H7723" : {
		"lemma" : "שָׁוְא",
		"xlit" : "shâvᵉʼ",
		"pron" : "shawv",
		"derivation" : "or שַׁו; from the same as H7722 (שׁוֹא) in the sense of desolating",
		"def" : "evil (as destructive), literally (ruin) or morally (especially guile); figuratively idolatry (as false, subjective), uselessness (as deceptive, objective; also adverbially, in vain)",
		"kjv" : "false(-ly), lie, lying, vain, vanity"
	},
	"H7724" : {
		"lemma" : "שְׁוָא",
		"xlit" : "Shᵉvâʼ",
		"pron" : "shev-aw'",
		"derivation" : "from the same as H7723 (שָׁוְא); false",
		"def" : "Sheva, an Israelite",
		"kjv" : "Sheva"
	},
	"H7725" : {
		"lemma" : "שׁוּב",
		"xlit" : "shûwb",
		"pron" : "shoob",
		"derivation" : "a primitive root",
		"def" : "to turn back (hence, away) transitively or intransitively, literally or figuratively (not necessarily with the idea of return to the starting point); generally to retreat; often adverbial, again",
		"kjv" : "((break, build, circumcise, dig, do anything, do evil, feed, lay down, lie down, lodge, make, rejoice, send, take, weep)) [idiom] again, (cause to) answer ([phrase] again), [idiom] in any case (wise), [idiom] at all, averse, bring (again, back, home again), call (to mind), carry again (back), cease, [idiom] certainly, come again (back), [idiom] consider, [phrase] continually, convert, deliver (again), [phrase] deny, draw back, fetch home again, [idiom] fro, get (oneself) (back) again, [idiom] give (again), go again (back, home), (go) out, hinder, let, (see) more, [idiom] needs, be past, [idiom] pay, pervert, pull in again, put (again, up again), recall, recompense, recover, refresh, relieve, render (again), requite, rescue, restore, retrieve, (cause to, make to) return, reverse, reward, [phrase] say nay, send back, set again, slide back, still, [idiom] surely, take back (off), (cause to, make to) turn (again, self again, away, back, back again, backward, from, off), withdraw"
	},
	"H7726" : {
		"lemma" : "שׁוֹבָב",
		"xlit" : "shôwbâb",
		"pron" : "sho-bawb'",
		"derivation" : "from H7725 (שׁוּב)",
		"def" : "apostate, i.e. idolatrous",
		"kjv" : "backsliding, frowardly, turn away (from margin)"
	},
	"H7727" : {
		"lemma" : "שׁוֹבָב",
		"xlit" : "Shôwbâb",
		"pron" : "sho-bawb'",
		"derivation" : "the same as H7726 (שׁוֹבָב); rebellious",
		"def" : "Shobab, the name of two Israelites",
		"kjv" : "Shobab"
	},
	"H7728" : {
		"lemma" : "שׁוֹבֵב",
		"xlit" : "shôwbêb",
		"pron" : "sho-babe'",
		"derivation" : "from H7725 (שׁוּב)",
		"def" : "apostate, i.e. heathenish or (actually) heathen",
		"kjv" : "backsliding"
	},
	"H7729" : {
		"lemma" : "שׁוּבָה",
		"xlit" : "shûwbâh",
		"pron" : "shoo-baw'",
		"derivation" : "from H7725 (שׁוּב)",
		"def" : "a return",
		"kjv" : "returning"
	},
	"H7730" : {
		"lemma" : "שׂוֹבֶךְ",
		"xlit" : "sôwbek",
		"pron" : "so'-bek",
		"derivation" : "for H5441 (סֹבֶךְ)",
		"def" : "a thicket, i.e. interlaced branches",
		"kjv" : "thick boughs"
	},
	"H7731" : {
		"lemma" : "שׁוֹבָךְ",
		"xlit" : "Shôwbâk",
		"pron" : "sho-bawk'",
		"derivation" : "perhaps for H7730 (שׂוֹבֶךְ)",
		"def" : "Shobak, a Syrian",
		"kjv" : "Shobach"
	},
	"H7732" : {
		"lemma" : "שׁוֹבָל",
		"xlit" : "Shôwbâl",
		"pron" : "sho-bawl'",
		"derivation" : "from the same as H7640 (שֹׁבֶל); overflowing",
		"def" : "Shobal, the name of an Edomite and two Israelites",
		"kjv" : "Shobal"
	},
	"H7733" : {
		"lemma" : "שׁוֹבֵק",
		"xlit" : "Shôwbêq",
		"pron" : "sho-bake'",
		"derivation" : "active participle from a primitive root meaning to leave (compare H7662 (שְׁבַק)); forsaking",
		"def" : "Shobek, an Israelite",
		"kjv" : "Shobek"
	},
	"H7734" : {
		"lemma" : "שׂוּג",
		"xlit" : "sûwg",
		"pron" : "soog",
		"derivation" : "a primitive root",
		"def" : "to retreat",
		"kjv" : "turn back"
	},
	"H7735" : {
		"lemma" : "שׂוּג",
		"xlit" : "sûwg",
		"pron" : "soog",
		"derivation" : "a primitive root",
		"def" : "to hedge in",
		"kjv" : "make to grow"
	},
	"H7736" : {
		"lemma" : "שׁוּד",
		"xlit" : "shûwd",
		"pron" : "shood",
		"derivation" : "a primitive root",
		"def" : "properly, to swell up, i.e. figuratively (by implication of insolence) to devastate",
		"kjv" : "waste"
	},
	"H7737" : {
		"lemma" : "שָׁוָה",
		"xlit" : "shâvâh",
		"pron" : "shaw-vaw'",
		"derivation" : "a primitive root",
		"def" : "properly, to level, i.e. equalize; figuratively, to resemble; by implication, to adjust (i.e. counterbalance, be suitable, compose, place, yield, etc.)",
		"kjv" : "avail, behave, bring forth, compare, countervail, (be, make) equal, lay, be (make, a-) like, make plain, profit, reckon"
	},
	"H7738" : {
		"lemma" : "שָׁוָה",
		"xlit" : "shâvâh",
		"pron" : "shaw-vaw'",
		"derivation" : "a primitive root",
		"def" : "to destroy",
		"kjv" : "[idiom] substance (from the margin)"
	},
	"H7739" : {
		"lemma" : "שְׁוָה",
		"xlit" : "shᵉvâh",
		"pron" : "shev-aw'",
		"derivation" : "(Aramaic); corresponding to H7737 (שָׁוָה)",
		"def" : "to resemble",
		"kjv" : "make like"
	},
	"H7740" : {
		"lemma" : "שָׁוֵה",
		"xlit" : "Shâvêh",
		"pron" : "shaw-vay'",
		"derivation" : "from H7737 (שָׁוָה); plain",
		"def" : "Shaveh, a place in Palestine",
		"kjv" : "Shaveh"
	},
	"H7741" : {
		"lemma" : "שָׁוֵה קִרְיָתַיִם",
		"xlit" : "Shâvêh Qiryâthayim",
		"pron" : "shaw-vay' kir-yawthah'-yim",
		"derivation" : "from the same as H7740 (שָׁוֵה) and the dual of H7151 (קִרְיָה); plain of a double city",
		"def" : "Shaveh-Kirjathajim, a place East of the Jordan",
		"kjv" : "Shaveh Kiriathaim"
	},
	"H7742" : {
		"lemma" : "שׂוּחַ",
		"xlit" : "sûwach",
		"pron" : "soo'-akh",
		"derivation" : "a primitive root",
		"def" : "to muse pensively",
		"kjv" : "meditate"
	},
	"H7743" : {
		"lemma" : "שׁוּחַ",
		"xlit" : "shûwach",
		"pron" : "shoo'-akh",
		"derivation" : "a primitive root",
		"def" : "to sink, literally or figuratively",
		"kjv" : "bow down, incline, humble"
	},
	"H7744" : {
		"lemma" : "שׁוּחַ",
		"xlit" : "Shûwach",
		"pron" : "shoo'-akh",
		"derivation" : "from H7743 (שׁוּחַ); dell",
		"def" : "Shuach, a son of Abraham",
		"kjv" : "Shuah"
	},
	"H7745" : {
		"lemma" : "שׁוּחָה",
		"xlit" : "shûwchâh",
		"pron" : "shoo-khaw'",
		"derivation" : "from H7743 (שׁוּחַ)",
		"def" : "a chasm",
		"kjv" : "ditch, pit"
	},
	"H7746" : {
		"lemma" : "שׁוּחָה",
		"xlit" : "Shûwchâh",
		"pron" : "shoo-khaw'",
		"derivation" : "the same as H7745 (שׁוּחָה)",
		"def" : "Shuchah, an Israelite",
		"kjv" : "Shuah"
	},
	"H7747" : {
		"lemma" : "שׁוּחִי",
		"xlit" : "Shûwchîy",
		"pron" : "shoo-khee'",
		"derivation" : "patronymic from H7744 (שׁוּחַ)",
		"def" : "a Shuchite or descendant of Shuach",
		"kjv" : "Shuhite"
	},
	"H7748" : {
		"lemma" : "שׁוּחָם",
		"xlit" : "Shûwchâm",
		"pron" : "shoo-khawm'",
		"derivation" : "from H7743 (שׁוּחַ); humbly",
		"def" : "Shucham, an Israelite",
		"kjv" : "Shuham"
	},
	"H7749" : {
		"lemma" : "שׁוּחָמִי",
		"xlit" : "Shûwchâmîy",
		"pron" : "shoo-khaw-mee'",
		"derivation" : "patronymic from H7748 (שׁוּחָם)",
		"def" : "a Shuchamite (collectively)",
		"kjv" : "Shuhamites"
	},
	"H7750" : {
		"lemma" : "שׂוּט",
		"xlit" : "sûwṭ",
		"pron" : "soot",
		"derivation" : "or (by permutation) סוּט; a primitive root",
		"def" : "to detrude, i.e. (intransitively and figuratively) become derelict (wrongly practise; namely, idolatry)",
		"kjv" : "turn aside to"
	},
	"H7751" : {
		"lemma" : "שׁוּט",
		"xlit" : "shûwṭ",
		"pron" : "shoot",
		"derivation" : "a primitive root",
		"def" : "properly, to push forth; (but used only figuratively) to lash, i.e. (the sea with oars) to row; by implication, to travel",
		"kjv" : "go (about, through, to and fro), mariner, rower, run to and fro"
	},
	"H7752" : {
		"lemma" : "שׁוֹט",
		"xlit" : "shôwṭ",
		"pron" : "shote",
		"derivation" : "from H7751 (שׁוּט)",
		"def" : "a lash (literally or figuratively)",
		"kjv" : "scourge, whip"
	},
	"H7753" : {
		"lemma" : "שׂוּךְ",
		"xlit" : "sûwk",
		"pron" : "sook",
		"derivation" : "a primitive root",
		"def" : "to entwine, i.e. shut in (for formation, protection or restraint)",
		"kjv" : "fence, (make an) hedge (up)"
	},
	"H7754" : {
		"lemma" : "שׂוֹךְ",
		"xlit" : "sôwk",
		"pron" : "soke",
		"derivation" : "or (feminine) שׂוֹכָה; from H7753 (שׂוּךְ)",
		"def" : "a branch (as interleaved)",
		"kjv" : "bough"
	},
	"H7755" : {
		"lemma" : "שׂוֹכֹה",
		"xlit" : "Sôwkôh",
		"pron" : "so-ko'",
		"derivation" : "or שֹׂכֹה; or שׂוֹכוֹ; from H7753 (שׂוּךְ)",
		"def" : "Sokoh or Soko, the name of two places in Palestine",
		"kjv" : "Shocho, Shochoh, Sochoh, Soco, Socoh"
	},
	"H7756" : {
		"lemma" : "שׂוּכָתִי",
		"xlit" : "Sûwkâthîy",
		"pron" : "soo-kaw-thee'",
		"derivation" : "probably patronymic from a name corresponding to H7754 (שׂוֹךְ) (feminine)",
		"def" : "a Sukathite or descendant of an unknown Israelite named Sukah",
		"kjv" : "Suchathite"
	},
	"H7757" : {
		"lemma" : "שׁוּל",
		"xlit" : "shûwl",
		"pron" : "shool",
		"derivation" : "from an unused root meaning to hang down",
		"def" : "a skirt; by implication, a bottom edge",
		"kjv" : "hem, skirt, train"
	},
	"H7758" : {
		"lemma" : "שׁוֹלָל",
		"xlit" : "shôwlâl",
		"pron" : "sho-lawl'",
		"derivation" : "or שֵׁילָל; (Micah 1:8), from H7997 (שָׁלַל)",
		"def" : "nude (especially bare-foot); by implication, captive",
		"kjv" : "spoiled, stripped"
	},
	"H7759" : {
		"lemma" : "שׁוּלַמִּית",
		"xlit" : "Shûwlammîyth",
		"pron" : "shoo-lam-meeth'",
		"derivation" : "from H7999 (שָׁלַם)",
		"def" : "peaceful (with the article always prefixed, making it a pet name); the Shulammith, an epithet of Solomon's queen",
		"kjv" : "Shulamite"
	},
	"H7760" : {
		"lemma" : "שׂוּם",
		"xlit" : "sûwm",
		"pron" : "soom",
		"derivation" : "or שִׂים; a primitive root",
		"def" : "to put (used in a great variety of applications, literal, figurative, inferentially, and elliptically)",
		"kjv" : "[idiom] any wise, appoint, bring, call (a name), care, cast in, change, charge, commit, consider, convey, determine, [phrase] disguise, dispose, do, get, give, heap up, hold, impute, lay (down, up), leave, look, make (out), mark, [phrase] name, [idiom] on, ordain, order, [phrase] paint, place, preserve, purpose, put (on), [phrase] regard, rehearse, reward, (cause to) set (on, up), shew, [phrase] stedfastly, take, [idiom] tell, [phrase] tread down, (over-)turn, [idiom] wholly, work"
	},
	"H7761" : {
		"lemma" : "שׂוּם",
		"xlit" : "sûwm",
		"pron" : "soom",
		"derivation" : "(Aramaic) corresponding to H7760 (שׂוּם)",
		"def" : "{to put (used in a great variety of applications, literal, figurative, inferentially, and elliptically)}",
		"kjv" : "[phrase] command, give, lay, make, [phrase] name, [phrase] regard, set"
	},
	"H7762" : {
		"lemma" : "שׁוּם",
		"xlit" : "shûwm",
		"pron" : "shoom",
		"derivation" : "from an unused root meaning to exhale",
		"def" : "garlic (from its rank odor)",
		"kjv" : "garlic"
	},
	"H7763" : {
		"lemma" : "שׁוֹמֵר",
		"xlit" : "Shôwmêr",
		"pron" : "sho-mare'",
		"derivation" : "or שֹׁמֵר; active participle of H8104 (שָׁמַר); keeper",
		"def" : "Shomer, the name of two Israelites",
		"kjv" : "Shomer"
	},
	"H7764" : {
		"lemma" : "שׁוּנִי",
		"xlit" : "Shûwnîy",
		"pron" : "shoo-nee'",
		"derivation" : "from an unused root meaning to rest; quiet",
		"def" : "Shuni, an Israelite",
		"kjv" : "Shuni"
	},
	"H7765" : {
		"lemma" : "שׁוּנִי",
		"xlit" : "Shûwnîy",
		"pron" : "shoo-nee'",
		"derivation" : "patronymic from H7764 (שׁוּנִי)",
		"def" : "a Shunite (collectively) or descendants of Shuni",
		"kjv" : "Shunites"
	},
	"H7766" : {
		"lemma" : "שׁוּנֵם",
		"xlit" : "Shûwnêm",
		"pron" : "shoo-name'",
		"derivation" : "probably from the same as H7764 (שׁוּנִי); quietly",
		"def" : "Shunem, a place in Pal",
		"kjv" : "Shunem"
	},
	"H7767" : {
		"lemma" : "שׁוּנַמִּית",
		"xlit" : "Shûwnammîyth",
		"pron" : "shoo-nam-meeth'",
		"derivation" : "patrial from H7766 (שׁוּנֵם)",
		"def" : "a Shunammitess, or female inhabitant of Shunem",
		"kjv" : "Shunamite"
	},
	"H7768" : {
		"lemma" : "שָׁוַע",
		"xlit" : "shâvaʻ",
		"pron" : "shaw-vah'",
		"derivation" : "a primitive root",
		"def" : "properly, to be free; but used only causatively and reflexively, to halloo (for help, i.e. freedom from some trouble)",
		"kjv" : "cry (aloud, out), shout"
	},
	"H7769" : {
		"lemma" : "שׁוּעַ",
		"xlit" : "shûwaʻ",
		"pron" : "shoo'-ah",
		"derivation" : "from H7768 (שָׁוַע)",
		"def" : "a halloo",
		"kjv" : "cry, riches"
	},
	"H7770" : {
		"lemma" : "שׁוּעַ",
		"xlit" : "Shûwaʻ",
		"pron" : "shoo'-ah",
		"derivation" : "the same as H7769 (שׁוּעַ)",
		"def" : "Shua, a Canaanite",
		"kjv" : "Shua, Shuah"
	},
	"H7771" : {
		"lemma" : "שׁוֹעַ",
		"xlit" : "shôwaʻ",
		"pron" : "sho'-ah",
		"derivation" : "from H7768 (שָׁוַע) in the original sense of freedom",
		"def" : "a noble, i.e. liberal, opulent; also (as noun in the derived sense) a halloo",
		"kjv" : "bountiful, crying, rich"
	},
	"H7772" : {
		"lemma" : "שׁוֹעַ",
		"xlit" : "Shôwaʻ",
		"pron" : "sho'-ah",
		"derivation" : "the same as H7771 (שׁוֹעַ); rich",
		"def" : "Shoa, an Oriental people",
		"kjv" : "Shoa"
	},
	"H7773" : {
		"lemma" : "שֶׁוַע",
		"xlit" : "shevaʻ",
		"pron" : "sheh'-vah",
		"derivation" : "from H7768 (שָׁוַע)",
		"def" : "a halloo",
		"kjv" : "cry"
	},
	"H7774" : {
		"lemma" : "שׁוּעָא",
		"xlit" : "Shûwʻâʼ",
		"pron" : "shoo-aw'",
		"derivation" : "from H7768 (שָׁוַע); wealth",
		"def" : "Shua, an Israelitess",
		"kjv" : "Shua"
	},
	"H7775" : {
		"lemma" : "שַׁוְעָה",
		"xlit" : "shavʻâh",
		"pron" : "shav-aw'",
		"derivation" : "feminine of H7773 (שֶׁוַע)",
		"def" : "a hallooing",
		"kjv" : "crying"
	},
	"H7776" : {
		"lemma" : "שׁוּעָל",
		"xlit" : "shûwʻâl",
		"pron" : "shoo-awl'",
		"derivation" : "or שֻׁעָל; from the same as H8168 (שֹׁעַל)",
		"def" : "a jackal (as a burrower)",
		"kjv" : "fox"
	},
	"H7777" : {
		"lemma" : "שׁוּעָל",
		"xlit" : "Shûwʻâl",
		"pron" : "shoo-awl'",
		"derivation" : "the same as H7776 (שׁוּעָל)",
		"def" : "Shual, the name of an Israelite and of a place in Palestine",
		"kjv" : "Shual"
	},
	"H7778" : {
		"lemma" : "שׁוֹעֵר",
		"xlit" : "shôwʻêr",
		"pron" : "sho-are'",
		"derivation" : "or שֹׁעֵר active participle of H8176 (שָׁעַר) (as denominative from H8179 (שַׁעַר))",
		"def" : "a janitor",
		"kjv" : "doorkeeper, porter"
	},
	"H7779" : {
		"lemma" : "שׁוּף",
		"xlit" : "shûwph",
		"pron" : "shoof",
		"derivation" : "a primitive root",
		"def" : "properly, to gape, i.e. snap at; figuratively, to overwhelm",
		"kjv" : "break, bruise, cover"
	},
	"H7780" : {
		"lemma" : "שׁוֹפָךְ",
		"xlit" : "Shôwphâk",
		"pron" : "sho-fawk'",
		"derivation" : "from H8210 (שָׁפַךְ); poured",
		"def" : "Shophak, a Syrian",
		"kjv" : "Shophach"
	},
	"H7781" : {
		"lemma" : "שׁוּפָמִי",
		"xlit" : "Shûwphâmîy",
		"pron" : "shoo-faw-mee'",
		"derivation" : "patronymic from H8197 (שְׁפוּפָם)",
		"def" : "a Shuphamite (collectively) or descendants of Shephupham",
		"kjv" : "Shuphamite"
	},
	"H7782" : {
		"lemma" : "שׁוֹפָר",
		"xlit" : "shôwphâr",
		"pron" : "sho-far'",
		"derivation" : "or שֹׁפָר; from H8231 (שָׁפַר) in the original sense of incising",
		"def" : "a cornet (as giving a clear sound) or curved horn",
		"kjv" : "cornet, trumpet"
	},
	"H7783" : {
		"lemma" : "שׁוּק",
		"xlit" : "shûwq",
		"pron" : "shook",
		"derivation" : "a primitive root",
		"def" : "to run after or over, i.e. overflow",
		"kjv" : "overflow, water"
	},
	"H7784" : {
		"lemma" : "שׁוּק",
		"xlit" : "shûwq",
		"pron" : "shook",
		"derivation" : "from H7783 (שׁוּק)",
		"def" : "a street (as run over)",
		"kjv" : "street"
	},
	"H7785" : {
		"lemma" : "שׁוֹק",
		"xlit" : "shôwq",
		"pron" : "shoke",
		"derivation" : "from H7783 (שׁוּק)",
		"def" : "the (lower) leg (as a runner)",
		"kjv" : "hip, leg, shoulder, thigh"
	},
	"H7786" : {
		"lemma" : "שׂוּר",
		"xlit" : "sûwr",
		"pron" : "soor",
		"derivation" : "a primitive root",
		"def" : "properly, to vanquish; by implication, to rule (causatively, crown)",
		"kjv" : "make princes, have power, reign. See H5493 (סוּר)"
	},
	"H7787" : {
		"lemma" : "שׂוּר",
		"xlit" : "sûwr",
		"pron" : "soor",
		"derivation" : "a primitive root (identical with H7786 (שׂוּר) through the idea of reducing to pieces; compare H4883 (מַשּׂוֹר))",
		"def" : "to saw",
		"kjv" : "cut"
	},
	"H7788" : {
		"lemma" : "שׁוּר",
		"xlit" : "shûwr",
		"pron" : "shoor",
		"derivation" : "a primitive root",
		"def" : "properly, to turn, i.e. travel about (as a harlot or a merchant)",
		"kjv" : "go, singular See also H7891 (שִׁיר)"
	},
	"H7789" : {
		"lemma" : "שׁוּר",
		"xlit" : "shûwr",
		"pron" : "shoor",
		"derivation" : "a primitive root (identical with through the idea of going round for inspection)",
		"def" : "to spy out, i.e. (generally) survey, (for evil) lurk for, (for good) care for",
		"kjv" : "behold, lay wait, look, observe, perceive, regard, see"
	},
	"H7790" : {
		"lemma" : "שׁוּר",
		"xlit" : "shûwr",
		"pron" : "shoor",
		"derivation" : "from H7889 (שִׁימוֹן)",
		"def" : "a foe (as lying in wait)",
		"kjv" : "enemy"
	},
	"H7791" : {
		"lemma" : "שׁוּר",
		"xlit" : "shûwr",
		"pron" : "shoor",
		"derivation" : "from H7788 (שׁוּר)",
		"def" : "a wall (as going about)",
		"kjv" : "wall"
	},
	"H7792" : {
		"lemma" : "שׁוּר",
		"xlit" : "shûwr",
		"pron" : "shoor",
		"derivation" : "(Aramaic) corresponding to H7791 (שׁוּר)",
		"def" : "{a wall (as going about)}",
		"kjv" : "wall"
	},
	"H7793" : {
		"lemma" : "שׁוּר",
		"xlit" : "Shûwr",
		"pron" : "shoor",
		"derivation" : "the same as H7791 (שׁוּר)",
		"def" : "Shur, a region of the Desert",
		"kjv" : "Shur"
	},
	"H7794" : {
		"lemma" : "שׁוֹר",
		"xlit" : "shôwr",
		"pron" : "shore",
		"derivation" : "from H7788 (שׁוּר)",
		"def" : "a bullock (as a traveller)",
		"kjv" : "bull(-ock), cow, ox, wall (by mistake for H7791 (שׁוּר))"
	},
	"H7795" : {
		"lemma" : "שׂוֹרָה",
		"xlit" : "sôwrâh",
		"pron" : "so-raw'",
		"derivation" : "from H7786 (שׂוּר) in the primitive sense of H5493 (סוּר)",
		"def" : "properly, a ring, i.e. (by analogy) a row (adverbially)",
		"kjv" : "principal"
	},
	"H7796" : {
		"lemma" : "שׂוֹרֵק",
		"xlit" : "Sôwrêq",
		"pron" : "so-rake'",
		"derivation" : "the same as H8321 (שֹׂרֵק); a vine",
		"def" : "Sorek, a valley in Palestine",
		"kjv" : "Sorek"
	},
	"H7797" : {
		"lemma" : "שׂוּשׂ",
		"xlit" : "sûws",
		"pron" : "soos",
		"derivation" : "or שִׂישׂ; a primitive root",
		"def" : "to be bright, i.e. cheerful",
		"kjv" : "be glad, [idiom] greatly, joy, make mirth, rejoice"
	},
	"H7798" : {
		"lemma" : "שַׁוְשָׁא",
		"xlit" : "Shavshâʼ",
		"pron" : "shav-shaw'",
		"derivation" : "from H7797 (שׂוּשׂ); joyful",
		"def" : "Shavsha, an Israelite",
		"kjv" : "Shavsha"
	},
	"H7799" : {
		"lemma" : "שׁוּשַׁן",
		"xlit" : "shûwshan",
		"pron" : "shoo-shan'",
		"derivation" : "or שׁוֹשָׁן; or שֹׁשָׁן; and (feminine) שׁוֹשַׁנָּה; from H7797 (שׂוּשׂ)",
		"def" : "a lily (from its whiteness), as aflower of architectural ornament; also a (straight) trumpet (from the tubular shape)",
		"kjv" : "lily, Shoshannim"
	},
	"H7800" : {
		"lemma" : "שׁוּשַׁן",
		"xlit" : "Shûwshan",
		"pron" : "shoo-shan'",
		"derivation" : "the same as H7799 (שׁוּשַׁן)",
		"def" : "Shushan, a place in Persia",
		"kjv" : "Shushan"
	},
	"H7801" : {
		"lemma" : "שׁוּשַׁנְכִי",
		"xlit" : "Shûwshankîy",
		"pron" : "shoo-shan-kee'",
		"derivation" : "(Aramaic) of foreign origin",
		"def" : "a Shushankite (collectively) or inhabitants of some unknown place in Assyrian",
		"kjv" : "Susanchites"
	},
	"H7802" : {
		"lemma" : "שׁוּשַׁן עֵדוּת",
		"xlit" : "Shûwshan ʻÊdûwth",
		"pron" : "shoo-shan' ay-dooth'",
		"derivation" : "or (plural of former) שׁוֹשַׁנִּים עֵדוּתlemma שׁוֹשַׁנִּיס עֵדוּת samekh, corrected to שׁוֹשַׁנִּים עֵדוּת; from H7799 (שׁוּשַׁן) and H5715 (עֵדוּת); lily (or trumpet) of assemblage",
		"def" : "Shushan-Eduth or Shoshannim-Eduth, the title of a popular song",
		"kjv" : "Shoshannim-Eduth, Shushan-eduth"
	},
	"H7803" : {
		"lemma" : "שׁוּתֶלַח",
		"xlit" : "Shûwthelach",
		"pron" : "shoo-theh'-lakh",
		"derivation" : "probably from H7582 (שָׁאָה) and the same as H8520 (תֶּלַח); crash of breakage",
		"def" : "Shuthelach, the name of two Israelites",
		"kjv" : "Shuthelah"
	},
	"H7804" : {
		"lemma" : "שְׁזַב",
		"xlit" : "shᵉzab",
		"pron" : "shez-ab'",
		"derivation" : "(Aramaic) corresponding to H5800 (עָזַב)",
		"def" : "to leave, i.e. (causatively) free",
		"kjv" : "deliver"
	},
	"H7805" : {
		"lemma" : "שָׁזַף",
		"xlit" : "shâzaph",
		"pron" : "shaw-zaf'",
		"derivation" : "a primitive root",
		"def" : "to tan (by sunburning); figuratively (as if by a piercing ray) to scan",
		"kjv" : "look up, see"
	},
	"H7806" : {
		"lemma" : "שָׁזַר",
		"xlit" : "shâzar",
		"pron" : "shaw-zar'",
		"derivation" : "a primitive root",
		"def" : "to twist (a thread of straw)",
		"kjv" : "twine"
	},
	"H7807" : {
		"lemma" : "שַׁח",
		"xlit" : "shach",
		"pron" : "shakh",
		"derivation" : "from H7817 (שָׁחַח)",
		"def" : "sunk, i.e. downcast",
		"kjv" : "[phrase] humble"
	},
	"H7808" : {
		"lemma" : "שֵׂחַ",
		"xlit" : "sêach",
		"pron" : "say'-akh",
		"derivation" : "for H7879 (שִׂיחַ)",
		"def" : "communion, i.e. (reflexively) meditation",
		"kjv" : "thought"
	},
	"H7809" : {
		"lemma" : "שָׁחַד",
		"xlit" : "shâchad",
		"pron" : "shaw-khad'",
		"derivation" : "a primitive root",
		"def" : "to donate, i.e. bribe",
		"kjv" : "hire, give a reward"
	},
	"H7810" : {
		"lemma" : "שַׁחַד",
		"xlit" : "shachad",
		"pron" : "shakh'-ad",
		"derivation" : "from H7809 (שָׁחַד)",
		"def" : "a donation (venal or redemptive)",
		"kjv" : "bribe(-ry), gift, present, reward"
	},
	"H7811" : {
		"lemma" : "שָׂחָה",
		"xlit" : "sâchâh",
		"pron" : "saw-khaw'",
		"derivation" : "a primitive root",
		"def" : "to swim; causatively, to inundate",
		"kjv" : "(make to) swim"
	},
	"H7812" : {
		"lemma" : "שָׁחָה",
		"xlit" : "shâchâh",
		"pron" : "shaw-khaw'",
		"derivation" : "a primitive root",
		"def" : "to depress, i.e. prostrate (especially reflexive, in homage to royalty or God)",
		"kjv" : "bow (self) down, crouch, fall down (flat), humbly beseech, do (make) obeisance, do reverence, make to stoop, worship"
	},
	"H7813" : {
		"lemma" : "שָׂחוּ",
		"xlit" : "sâchûw",
		"pron" : "saw'-khoo",
		"derivation" : "from H7811 (שָׂחָה)",
		"def" : "a pond (for swimming)",
		"kjv" : "to swim in"
	},
	"H7814" : {
		"lemma" : "שְׂחוֹק",
		"xlit" : "sᵉchôwq",
		"pron" : "sekh-oke'",
		"derivation" : "or שְׂחֹק; from H7832 (שָׂחַק)",
		"def" : "laughter (in merriment or defiance)",
		"kjv" : "derision, laughter(-ed to scorn, -ing), mocked, sport"
	},
	"H7815" : {
		"lemma" : "שְׁחוֹר",
		"xlit" : "shᵉchôwr",
		"pron" : "shekh-ore'",
		"derivation" : "from H7835 (שָׁחַר)",
		"def" : "dinginess, i.e. perhaps soot",
		"kjv" : "coal"
	},
	"H7816" : {
		"lemma" : "שְׁחוּת",
		"xlit" : "shᵉchûwth",
		"pron" : "shekh-ooth'",
		"derivation" : "from H7812 (שָׁחָה)",
		"def" : "pit",
		"kjv" : "pit"
	},
	"H7817" : {
		"lemma" : "שָׁחַח",
		"xlit" : "shâchach",
		"pron" : "shaw-khakh'",
		"derivation" : "a primitive root",
		"def" : "to sink or depress (reflexive or causative)",
		"kjv" : "bend, bow (down), bring (cast) down, couch, humble self, be (bring) low, stoop"
	},
	"H7818" : {
		"lemma" : "שָׂחַט",
		"xlit" : "sâchaṭ",
		"pron" : "saw-khat'",
		"derivation" : "a primitive root",
		"def" : "to tread out, i.e. squeeze (grapes)",
		"kjv" : "press"
	},
	"H7819" : {
		"lemma" : "שָׁחַט",
		"xlit" : "shâchaṭ",
		"pron" : "shaw-khat'",
		"derivation" : "a primitive root",
		"def" : "to slaughter (in sacrifice or massacre)",
		"kjv" : "kill, offer, shoot out, slay, slaughter"
	},
	"H7820" : {
		"lemma" : "שָׁחַט",
		"xlit" : "shâchaṭ",
		"pron" : "shaw-khat'",
		"derivation" : "a primitive root (identical with H7819 (שָׁחַט) through the idea of striking)",
		"def" : "to hammer out",
		"kjv" : "beat"
	},
	"H7821" : {
		"lemma" : "שְׁחִיטָה",
		"xlit" : "shᵉchîyṭâh",
		"pron" : "shekh-ee-taw'",
		"derivation" : "from H7819 (שָׁחַט)",
		"def" : "slaughter",
		"kjv" : "killing"
	},
	"H7822" : {
		"lemma" : "שְׁחִין",
		"xlit" : "shᵉchîyn",
		"pron" : "shekh-een'",
		"derivation" : "from an unused root probably meaning to burn",
		"def" : "inflammation, i.e. an ulcer",
		"kjv" : "boil, botch"
	},
	"H7823" : {
		"lemma" : "שָׁחִיס",
		"xlit" : "shâchîyç",
		"pron" : "shaw-khece'",
		"derivation" : "or סָחִישׁ; from an unused root apparently meaning to sprout",
		"def" : "aftergrowth",
		"kjv" : "(that) which springeth of the same"
	},
	"H7824" : {
		"lemma" : "שָׁחִיף",
		"xlit" : "shâchîyph",
		"pron" : "shaw-kheef'",
		"derivation" : "from the same as H7828 (שַׁחַף)",
		"def" : "a board (as chipped thin)",
		"kjv" : "cieled with"
	},
	"H7825" : {
		"lemma" : "שְׁחִית",
		"xlit" : "shᵉchîyth",
		"pron" : "shekh-eeth'",
		"derivation" : "from H7812 (שָׁחָה)",
		"def" : "a pit-fall (literally or figuratively)",
		"kjv" : "destruction, pit"
	},
	"H7826" : {
		"lemma" : "שַׁחַל",
		"xlit" : "shachal",
		"pron" : "shakh'-al",
		"derivation" : "from an unused root probably meaning to roar",
		"def" : "a lion (from his characteristic roar)",
		"kjv" : "(fierce) lion"
	},
	"H7827" : {
		"lemma" : "שְׁחֵלֶת",
		"xlit" : "shᵉchêleth",
		"pron" : "shekh-ay'-leth",
		"derivation" : "apparently from the same as H7826 (שַׁחַל) through some obscure idea, perhaps that of peeling off by concussion of sound",
		"def" : "a scale or shell, i.e. the aromatic mussel",
		"kjv" : "onycha"
	},
	"H7828" : {
		"lemma" : "שַׁחַף",
		"xlit" : "shachaph",
		"pron" : "shakh'-af",
		"derivation" : "from an unused root meaning to peel, i.e. emaciate",
		"def" : "the gull (as thin)",
		"kjv" : "cuckoo"
	},
	"H7829" : {
		"lemma" : "שַׁחֶפֶת",
		"xlit" : "shachepheth",
		"pron" : "shakh-eh'-feth",
		"derivation" : "from the same as H7828 (שַׁחַף)",
		"def" : "emaciation",
		"kjv" : "consumption"
	},
	"H7830" : {
		"lemma" : "שַׁחַץ",
		"xlit" : "shachats",
		"pron" : "shakh'-ats",
		"derivation" : "from an unused root apparently meaning to strut",
		"def" : "haughtiness (as evinced by the attitude)",
		"kjv" : "[idiom] lion, pride"
	},
	"H7831" : {
		"lemma" : "שַׁחֲצוֹם",
		"xlit" : "Shachătsôwm",
		"pron" : "shakh-ats-ome'",
		"derivation" : "from the same as H7830 (שַׁחַץ); proudly",
		"def" : "Shachatsom, a place in Palestine",
		"kjv" : "Shahazimah (from the margin)"
	},
	"H7832" : {
		"lemma" : "שָׂחַק",
		"xlit" : "sâchaq",
		"pron" : "saw-khak'",
		"derivation" : "a primitive root",
		"def" : "to laugh (in pleasure or detraction); by implication, to play",
		"kjv" : "deride, have in derision, laugh, make merry, mock(-er), play, rejoice, (laugh to) scorn, be in (make) sport"
	},
	"H7833" : {
		"lemma" : "שָׁחַק",
		"xlit" : "shâchaq",
		"pron" : "shaw-khak'",
		"derivation" : "a primitive root",
		"def" : "to comminate (by trituration or attrition)",
		"kjv" : "beat, wear"
	},
	"H7834" : {
		"lemma" : "שַׁחַק",
		"xlit" : "shachaq",
		"pron" : "shakh'-ak",
		"derivation" : "from H7833 (שָׁחַק)",
		"def" : "a powder (as beaten small); by analogy, a thin vapor; by extension, the firmament",
		"kjv" : "cloud, small dust, heaven, sky"
	},
	"H7835" : {
		"lemma" : "שָׁחַר",
		"xlit" : "shâchar",
		"pron" : "shaw-khar'",
		"derivation" : "a primitive root (identical with H7836 (שָׁחַר) through the idea of the duskiness of early dawn)",
		"def" : "to be dim or dark (in color)",
		"kjv" : "be black"
	},
	"H7836" : {
		"lemma" : "שָׁחַר",
		"xlit" : "shâchar",
		"pron" : "shaw-khar'",
		"derivation" : "a primitive root",
		"def" : "properly, to dawn, i.e. (figuratively) be (up) early at any task (with the implication of earnestness); by extension, to search for (with painstaking)",
		"kjv" : "(do something) betimes, enquire early, rise (seek) betimes, seek diligently) early, in the morning)"
	},
	"H7837" : {
		"lemma" : "שַׁחַר",
		"xlit" : "shachar",
		"pron" : "shakh'-ar",
		"derivation" : "from H7836 (שָׁחַר)",
		"def" : "dawn (literal, figurative or adverbial)",
		"kjv" : "day(-spring), early, light, morning, whence riseth"
	},
	"H7838" : {
		"lemma" : "שָׁחֹר",
		"xlit" : "shâchôr",
		"pron" : "shaw-khore'",
		"derivation" : "or שָׁחוֹר; from H7835 (שָׁחַר)",
		"def" : "properly, dusky, but also (absol.) jetty",
		"kjv" : "black"
	},
	"H7839" : {
		"lemma" : "שַׁחֲרוּת",
		"xlit" : "shachărûwth",
		"pron" : "shakh-ar-ooth'",
		"derivation" : "from H7836 (שָׁחַר)",
		"def" : "a dawning, i.e. (figuratively) juvenescence",
		"kjv" : "youth"
	},
	"H7840" : {
		"lemma" : "שְׁחַרְחֹרֶת",
		"xlit" : "shᵉcharchôreth",
		"pron" : "shekh-ar-kho'-reth",
		"derivation" : "from H7835 (שָׁחַר)",
		"def" : "swarthy",
		"kjv" : "black"
	},
	"H7841" : {
		"lemma" : "שְׁחַרְיָה",
		"xlit" : "Shᵉcharyâh",
		"pron" : "shekh-ar-yaw'",
		"derivation" : "from H7836 (שָׁחַר) and H3050 (יָהּ); Jah has sought",
		"def" : "Shecharjah, an Israelite",
		"kjv" : "Shehariah"
	},
	"H7842" : {
		"lemma" : "שַׁחֲרַיִם",
		"xlit" : "Shachărayim",
		"pron" : "shakh-ar-ah'-yim",
		"derivation" : "dual of H7837 (שַׁחַר); double dawn",
		"def" : "Shacharajim, an Israelite",
		"kjv" : "Shaharaim"
	},
	"H7843" : {
		"lemma" : "שָׁחַת",
		"xlit" : "shâchath",
		"pron" : "shaw-khath'",
		"derivation" : "a primitive root",
		"def" : "to decay, i.e. (causatively) ruin (literally or figuratively)",
		"kjv" : "batter, cast off, corrupt(-er, thing), destroy(-er, -uction), lose, mar, perish, spill, spoiler, [idiom] utterly, waste(-r)"
	},
	"H7844" : {
		"lemma" : "שְׁחַת",
		"xlit" : "shᵉchath",
		"pron" : "shekh-ath'",
		"derivation" : "(Aramaic) corresponding to H7843 (שָׁחַת)",
		"def" : "{to decay, i.e. (causatively) ruin (literally or figuratively)}",
		"kjv" : "corrupt, fault"
	},
	"H7845" : {
		"lemma" : "שַׁחַת",
		"xlit" : "shachath",
		"pron" : "shakh'-ath",
		"derivation" : "from H7743 (שׁוּחַ)",
		"def" : "a pit (especially as a trap); figuratively, destruction",
		"kjv" : "corruption, destruction, ditch, grave, pit"
	},
	"H7846" : {
		"lemma" : "שֵׂט",
		"xlit" : "sêṭ",
		"pron" : "sayte",
		"derivation" : "or סֵט; from H7750 (שׂוּט)",
		"def" : "a departure from right, i.e. sin",
		"kjv" : "revolter, that turn aside"
	},
	"H7847" : {
		"lemma" : "שָׂטָה",
		"xlit" : "sâṭâh",
		"pron" : "saw-taw'",
		"derivation" : "a primitive root",
		"def" : "to deviate from duty",
		"kjv" : "decline, go aside, turn"
	},
	"H7848" : {
		"lemma" : "שִׁטָּה",
		"xlit" : "shiṭṭâh",
		"pron" : "shit-taw'",
		"derivation" : "feminine of a derivative (only in the plural שִׁטִּים; meaning the sticks of wood) from the same as H7850 (שֹׁטֵט)",
		"def" : "the acacia (from its scourging thorns)",
		"kjv" : "shittah, shittim. See also H1029 (בֵּית הַשִּׁטָּה)"
	},
	"H7849" : {
		"lemma" : "שָׁטַח",
		"xlit" : "shâṭach",
		"pron" : "shaw-takh'",
		"derivation" : "a primitive root",
		"def" : "to expand",
		"kjv" : "all abroad, enlarge, spread, stretch out"
	},
	"H7850" : {
		"lemma" : "שֹׁטֵט",
		"xlit" : "shôṭêṭ",
		"pron" : "sho-tate'",
		"derivation" : "active participle of an otherwise unused root meaning (properly, to pierce; but only as a denominative from H7752 (שׁוֹט))",
		"def" : "to flog; a goad",
		"kjv" : "scourge"
	},
	"H7851" : {
		"lemma" : "שִׁטִּים",
		"xlit" : "Shiṭṭîym",
		"pron" : "shit-teem'",
		"derivation" : "the same as the plural of H7848 (שִׁטָּה); acacia trees",
		"def" : "Shittim, a place East of the Jordan",
		"kjv" : "Shittim"
	},
	"H7852" : {
		"lemma" : "שָׂטַם",
		"xlit" : "sâṭam",
		"pron" : "saw-tam'",
		"derivation" : "a primitive root",
		"def" : "properly, to lurk for, i.e. persecute",
		"kjv" : "hate, oppose self against"
	},
	"H7853" : {
		"lemma" : "שָׂטַן",
		"xlit" : "sâṭan",
		"pron" : "saw-tan'",
		"derivation" : "a primitive root",
		"def" : "to attack, (figuratively) accuse",
		"kjv" : "(be an) adversary, resist"
	},
	"H7854" : {
		"lemma" : "שָׂטָן",
		"xlit" : "sâṭân",
		"pron" : "saw-tawn'",
		"derivation" : "from H7853 (שָׂטַן)",
		"def" : "an opponent; especially (with the article prefixed) Satan, the arch-enemy of good",
		"kjv" : "adversary, Satan, withstand"
	},
	"H7855" : {
		"lemma" : "שִׂטְנָה",
		"xlit" : "siṭnâh",
		"pron" : "sit-naw'",
		"derivation" : "from H7853 (שָׂטַן)",
		"def" : "opposition (by letter)",
		"kjv" : "accusation"
	},
	"H7856" : {
		"lemma" : "שִׂטְנָה",
		"xlit" : "Siṭnâh",
		"pron" : "sit-naw'",
		"derivation" : "the same as H7855 (שִׂטְנָה)",
		"def" : "Sitnah, the name of a well in Pal",
		"kjv" : "Sitnah"
	},
	"H7857" : {
		"lemma" : "שָׁטַף",
		"xlit" : "shâṭaph",
		"pron" : "shaw-taf'",
		"derivation" : "a primitive root",
		"def" : "to gush; by implication, to inundate, cleanse; by analogy, to gallop, conquer",
		"kjv" : "drown, (over-) flow(-whelm, rinse, run, rush, (throughly) wash (away)"
	},
	"H7858" : {
		"lemma" : "שֶׁטֶף",
		"xlit" : "sheṭeph",
		"pron" : "sheh'-tef",
		"derivation" : "or שֵׁטֶף; from H7857 (שָׁטַף)",
		"def" : "a deluge (literally or figuratively)",
		"kjv" : "flood, outrageous, overflowing"
	},
	"H7859" : {
		"lemma" : "שְׁטַר",
		"xlit" : "shᵉṭar",
		"pron" : "shet-ar'",
		"derivation" : "(Aramaic) of uncertain derivation",
		"def" : "a side",
		"kjv" : "side"
	},
	"H7860" : {
		"lemma" : "שֹׁטֵר",
		"xlit" : "shôṭêr",
		"pron" : "sho-tare'",
		"derivation" : "active participle of an otherwise unused root probably meaning to write",
		"def" : "properly, a scribe, i.e. (by analogy or implication) an official superintendent or magistrate",
		"kjv" : "officer, overseer, ruler"
	},
	"H7861" : {
		"lemma" : "שִׁטְרַי",
		"xlit" : "Shiṭray",
		"pron" : "shit-rah'-ee",
		"derivation" : "from the same as H7860 (שֹׁטֵר); magisterial",
		"def" : "Shitrai, an Israelite",
		"kjv" : "Shitrai"
	},
	"H7862" : {
		"lemma" : "שַׁי",
		"xlit" : "shay",
		"pron" : "shah'-ee",
		"derivation" : "probably from H7737 (שָׁוָה)",
		"def" : "a gift (as available)",
		"kjv" : "present"
	},
	"H7863" : {
		"lemma" : "שִׂיא",
		"xlit" : "sîyʼ",
		"pron" : "see",
		"derivation" : "from the same as H7721 (שׂוֹא) by permutation",
		"def" : "elevation",
		"kjv" : "excellency"
	},
	"H7864" : {
		"lemma" : "שְׁיָא",
		"xlit" : "Shᵉyâʼ",
		"pron" : "sheh-yaw'",
		"derivation" : "for H7724 (שְׁוָא)",
		"def" : "Sheja, an Israelite",
		"kjv" : "Sheva (from the margin)"
	},
	"H7865" : {
		"lemma" : "שִׂיאֹן",
		"xlit" : "Sîyʼôn",
		"pron" : "see-ohn'",
		"derivation" : "from H7863 (שִׂיא); peak",
		"def" : "Sion, the summit of Mount Hermon",
		"kjv" : "Sion"
	},
	"H7866" : {
		"lemma" : "שִׁיאוֹן",
		"xlit" : "Shîyʼôwn",
		"pron" : "shee-ohn'",
		"derivation" : "from the same as H7722 (שׁוֹא); ruin",
		"def" : "Shijon, a place in Palestine",
		"kjv" : "Shihon"
	},
	"H7867" : {
		"lemma" : "שִׂיב",
		"xlit" : "sîyb",
		"pron" : "seeb",
		"derivation" : "a primitive root",
		"def" : "properly, to become aged, i.e. (by implication) to grow gray",
		"kjv" : "(be) grayheaded"
	},
	"H7868" : {
		"lemma" : "שִׂיב",
		"xlit" : "sîyb",
		"pron" : "seeb",
		"derivation" : "(Aramaic) corresponding to H7867 (שִׂיב)",
		"def" : "{properly, to become aged, i.e. (by implication) to grow gray}",
		"kjv" : "elder"
	},
	"H7869" : {
		"lemma" : "שֵׂיב",
		"xlit" : "sêyb",
		"pron" : "sabe",
		"derivation" : "from H7867 (שִׂיב)",
		"def" : "old age",
		"kjv" : "age"
	},
	"H7870" : {
		"lemma" : "שִׁיבָה",
		"xlit" : "shîybâh",
		"pron" : "shee-baw'",
		"derivation" : "by permutation from H7725 (שׁוּב)",
		"def" : "a return (of property)",
		"kjv" : "captivity"
	},
	"H7871" : {
		"lemma" : "שִׁיבָה",
		"xlit" : "shîybâh",
		"pron" : "shee-baw'",
		"derivation" : "from H3427 (יָשַׁב)",
		"def" : "residence",
		"kjv" : "while...lay"
	},
	"H7872" : {
		"lemma" : "שֵׂיבָה",
		"xlit" : "sêybâh",
		"pron" : "say-baw'",
		"derivation" : "feminine of H7869 (שֵׂיב)",
		"def" : "old age",
		"kjv" : "(be) gray (grey hoar,-y) hairs (head,-ed), old age"
	},
	"H7873" : {
		"lemma" : "שִׂיג",
		"xlit" : "sîyg",
		"pron" : "seeg",
		"derivation" : "from H7734 (שׂוּג)",
		"def" : "a withdrawal (into a private place)",
		"kjv" : "pursuing"
	},
	"H7874" : {
		"lemma" : "שִׂיד",
		"xlit" : "sîyd",
		"pron" : "seed",
		"derivation" : "a primitive root probably meaning to boil up (compare H7736 (שׁוּד)); used only as denominative from H7875 (שִׂיד)",
		"def" : "to plaster",
		"kjv" : "plaister"
	},
	"H7875" : {
		"lemma" : "שִׂיד",
		"xlit" : "sîyd",
		"pron" : "seed",
		"derivation" : "from H7874 (שִׂיד)lemma שׂיד missing vowel, corrected to שִׂיד",
		"def" : "lime (as boiling when slacked)",
		"kjv" : "lime, plaister"
	},
	"H7876" : {
		"lemma" : "שָׁיָה",
		"xlit" : "shâyâh",
		"pron" : "shaw-yaw'",
		"derivation" : "a primitive root",
		"def" : "to keep in memory",
		"kjv" : "be unmindful. (Render Deuteronomy 32:18, 'A Rock bore thee, thou must recollect; and (yet) thou hast forgotten,' etc.)"
	},
	"H7877" : {
		"lemma" : "שִׁיזָא",
		"xlit" : "Shîyzâʼ",
		"pron" : "shee-zaw'",
		"derivation" : "of unknown derivation",
		"def" : "Shiza, an Israelite",
		"kjv" : "Shiza"
	},
	"H7878" : {
		"lemma" : "שִׂיחַ",
		"xlit" : "sîyach",
		"pron" : "see'-akh",
		"derivation" : "a primitive root",
		"def" : "to ponder, i.e. (by implication) converse (with oneself, and hence, aloud) or (transitively) utter",
		"kjv" : "commune, complain, declare, meditate, muse, pray, speak, talk (with)"
	},
	"H7879" : {
		"lemma" : "שִׂיחַ",
		"xlit" : "sîyach",
		"pron" : "see'-akh",
		"derivation" : "from H7878 (שִׂיחַ)",
		"def" : "a contemplation; by implication, an utterance",
		"kjv" : "babbling, communication, complaint, meditation, prayer, talk"
	},
	"H7880" : {
		"lemma" : "שִׂיחַ",
		"xlit" : "sîyach",
		"pron" : "see'-akh",
		"derivation" : "from H7878 (שִׂיחַ)",
		"def" : "a shoot (as if uttered or put forth), i.e. (generally) shrubbery",
		"kjv" : "bush, plant, shrub"
	},
	"H7881" : {
		"lemma" : "שִׂיחָה",
		"xlit" : "sîychâh",
		"pron" : "see-khaw'",
		"derivation" : "feminine of H7879 (שִׂיחַ)",
		"def" : "reflection; be extension, devotion",
		"kjv" : "meditation, prayer"
	},
	"H7882" : {
		"lemma" : "שִׁיחָה",
		"xlit" : "shîychâh",
		"pron" : "shee-khaw'",
		"derivation" : "for H7745 (שׁוּחָה)",
		"def" : "a pit-fall",
		"kjv" : "pit"
	},
	"H7883" : {
		"lemma" : "שִׁיחוֹר",
		"xlit" : "Shîychôwr",
		"pron" : "shee-khore'",
		"derivation" : "or שִׁחוֹר; or שִׁחֹר; probably from H7835 (שָׁחַר); dark, i.e. turbid",
		"def" : "Shichor, a stream of Egypt",
		"kjv" : "Shihor, Sihor"
	},
	"H7884" : {
		"lemma" : "שִׁיחוֹר לִבְנָת",
		"xlit" : "Shîychôwr Libnâth",
		"pron" : "shee-khore' lib-nawth'",
		"derivation" : "from the same as H7883 (שִׁיחוֹר) and H3835 (לָבַן); darkish whiteness",
		"def" : "Shichor-Libnath, a stream of Palestine",
		"kjv" : "Shihor-libnath"
	},
	"H7885" : {
		"lemma" : "שַׁיִט",
		"xlit" : "shayiṭ",
		"pron" : "shay'-yit",
		"derivation" : "from H7751 (שׁוּט); also compare H7752 (שׁוֹט)",
		"def" : "an oar; a scourge (figuratively)",
		"kjv" : "oar, scourge"
	},
	"H7886" : {
		"lemma" : "שִׁילֹה",
		"xlit" : "Shîylôh",
		"pron" : "shee-lo'",
		"derivation" : "from H7951 (שָׁלָה); tranquil",
		"def" : "Shiloh, an epithet of the Messiah",
		"kjv" : "Shiloh"
	},
	"H7887" : {
		"lemma" : "שִׁילֹה",
		"xlit" : "Shîylôh",
		"pron" : "shee-lo'",
		"derivation" : "or שִׁלֹה; or שִׁילוֹ; or שִׁלוֹ; from the same as H7886 (שִׁילֹה)",
		"def" : "Shiloh, a place in Palestine",
		"kjv" : "Shiloh"
	},
	"H7888" : {
		"lemma" : "שִׁילוֹנִי",
		"xlit" : "Shîylôwnîy",
		"pron" : "shee-lo-nee'",
		"derivation" : "or שִׁילֹנִי; or שִׁלֹנִי; from H7887 (שִׁילֹה)",
		"def" : "a Shilonite or inhabitant of Shiloh",
		"kjv" : "Shilonite"
	},
	"H7889" : {
		"lemma" : "שִׁימוֹן",
		"xlit" : "Shîymôwn",
		"pron" : "shee-mone'",
		"derivation" : "apparently for H3452 (יְשִׁימוֹן); desert",
		"def" : "Shimon, an Israelite",
		"kjv" : "Shimon"
	},
	"H7890" : {
		"lemma" : "שַׁיִן",
		"xlit" : "shayin",
		"pron" : "shah'-yin",
		"derivation" : "from an unused root meaning to urinate",
		"def" : "urine",
		"kjv" : "piss"
	},
	"H7891" : {
		"lemma" : "שִׁיר",
		"xlit" : "shîyr",
		"pron" : "sheer",
		"derivation" : "or (the original form) שׁוּר; (1 Samuel 18:6), a primitive root (rather identical with H7788 (שׁוּר) through the idea of strolling minstrelsy)",
		"def" : "to sing",
		"kjv" : "behold (by mistake for H7789 (שׁוּר)), sing(-er, -ing man, -ing woman)"
	},
	"H7892" : {
		"lemma" : "שִׁיר",
		"xlit" : "shîyr",
		"pron" : "sheer",
		"derivation" : "or feminine שִׁירָה; from H7891 (שִׁיר)",
		"def" : "a song; abstractly, singing",
		"kjv" : "musical(-ick), [idiom] sing(-er, -ing), song"
	},
	"H7893" : {
		"lemma" : "שַׁיִשׁ",
		"xlit" : "shayish",
		"pron" : "shah'-yish",
		"derivation" : "from an unused root meaning to bleach, i.e. whiten;  See H8336 (שֵׁשׁ)",
		"def" : "white, i.e. marble"
	},
	"H7894" : {
		"lemma" : "שִׁישָׁא",
		"xlit" : "Shîyshâʼ",
		"pron" : "shee-shaw'",
		"derivation" : "from the same as H7893 (שַׁיִשׁ); whiteness",
		"def" : "Shisha, an Israelite",
		"kjv" : "Shisha"
	},
	"H7895" : {
		"lemma" : "שִׁישַׁק",
		"xlit" : "Shîyshaq",
		"pron" : "shee-shak'",
		"derivation" : "or שׁוּשַׁק; of Egyptian derivation",
		"def" : "Shishak, an Egyptian king",
		"kjv" : "Shishak"
	},
	"H7896" : {
		"lemma" : "שִׁית",
		"xlit" : "shîyth",
		"pron" : "sheeth",
		"derivation" : "a primitive root",
		"def" : "to place (in a very wide application)",
		"kjv" : "apply, appoint, array, bring, consider, lay (up), let alone, [idiom] look, make, mark, put (on), [phrase] regard, set, shew, be stayed, [idiom] take"
	},
	"H7897" : {
		"lemma" : "שִׁית",
		"xlit" : "shîyth",
		"pron" : "sheeth",
		"derivation" : "from H7896 (שִׁית)",
		"def" : "a dress (as put on)",
		"kjv" : "attire"
	},
	"H7898" : {
		"lemma" : "שַׁיִת",
		"xlit" : "shayith",
		"pron" : "shah'-yith",
		"derivation" : "from H7896 (שִׁית)",
		"def" : "scrub or trash, i.e. wild growth of weeds or briers (as if put on the field)",
		"kjv" : "thorns"
	},
	"H7899" : {
		"lemma" : "שֵׂךְ",
		"xlit" : "sêk",
		"pron" : "sake",
		"derivation" : "from H5526 (סָכַךְ) in the sense of H7753 (שׂוּךְ)",
		"def" : "a brier (as of a hedge)",
		"kjv" : "prick"
	},
	"H7900" : {
		"lemma" : "שֹׂךְ",
		"xlit" : "sôk",
		"pron" : "soke",
		"derivation" : "from H5526 (סָכַךְ) in the sense of H7753 (שׂוּךְ)",
		"def" : "a booth (as interlaced)",
		"kjv" : "tabernacle"
	},
	"H7901" : {
		"lemma" : "שָׁכַב",
		"xlit" : "shâkab",
		"pron" : "shaw-kab'",
		"derivation" : "a primitive root",
		"def" : "to lie down (for rest, sexual connection, decease or any other purpose)",
		"kjv" : "[idiom] at all, cast down, (lover-)lay (self) (down), (make to) lie (down, down to sleep, still with), lodge, ravish, take rest, sleep, stay"
	},
	"H7902" : {
		"lemma" : "שְׁכָבָה",
		"xlit" : "shᵉkâbâh",
		"pron" : "shek-aw-baw'",
		"derivation" : "from H7901 (שָׁכַב)",
		"def" : "a lying down (of dew, or for the sexual act)",
		"kjv" : "[idiom] carnally, copulation, [idiom] lay, seed"
	},
	"H7903" : {
		"lemma" : "שְׁכֹבֶת",
		"xlit" : "shᵉkôbeth",
		"pron" : "shek-o'-beth",
		"derivation" : "from H7901 (שָׁכַב)",
		"def" : "a (sexual) lying with",
		"kjv" : "[idiom] lie"
	},
	"H7904" : {
		"lemma" : "שָׁכָה",
		"xlit" : "shâkâh",
		"pron" : "shaw-kaw'",
		"derivation" : "a primitive root",
		"def" : "to roam (through lust)",
		"kjv" : "in the morning (by mistake for H7925 (שָׁכַם))"
	},
	"H7905" : {
		"lemma" : "שֻׂכָּה",
		"xlit" : "sukkâh",
		"pron" : "sook-kaw'",
		"derivation" : "feminine of H7900 (שֹׂךְ) in the sense of H7899 (שֵׂךְ)",
		"def" : "a dart (as pointed like a thorn)",
		"kjv" : "barbed iron"
	},
	"H7906" : {
		"lemma" : "שֵׂכוּ",
		"xlit" : "Sêkûw",
		"pron" : "say'-koo",
		"derivation" : "from an unused root apparently meaning to surmount; an observatory (with the article)",
		"def" : "Seku, a place in Palestine",
		"kjv" : "Sechu"
	},
	"H7907" : {
		"lemma" : "שֶׂכְוִי",
		"xlit" : "sekvîy",
		"pron" : "sek-vee'",
		"derivation" : "from the same as H7906 (שֵׂכוּ)",
		"def" : "observant, i.e. (concretely) the mind",
		"kjv" : "heart"
	},
	"H7908" : {
		"lemma" : "שְׁכוֹל",
		"xlit" : "shᵉkôwl",
		"pron" : "shek-ole'",
		"derivation" : "infinitive of H7921 (שָׁכֹל)",
		"def" : "bereavement",
		"kjv" : "loss of children, spoiling"
	},
	"H7909" : {
		"lemma" : "שַׁכּוּל",
		"xlit" : "shakkûwl",
		"pron" : "shak-kool'",
		"derivation" : "or שַׁכֻּל; from H7921 (שָׁכֹל)",
		"def" : "bereaved",
		"kjv" : "barren, bereaved (robbed) of children (whelps)"
	},
	"H7910" : {
		"lemma" : "שִׁכּוֹר",
		"xlit" : "shikkôwr",
		"pron" : "shik-kore'",
		"derivation" : "or שִׁכֹּר; from H7937 (שָׁכַר)",
		"def" : "intoxicated, as astate or a habit",
		"kjv" : "drunk(-ard, -en, -en man)"
	},
	"H7911" : {
		"lemma" : "שָׁכַח",
		"xlit" : "shâkach",
		"pron" : "shaw-kakh'",
		"derivation" : "or שָׁכֵחַ; a primitive root",
		"def" : "to mislay, i.e. to be oblivious of, from want of memory or attention",
		"kjv" : "[idiom] at all, (cause to) forget"
	},
	"H7912" : {
		"lemma" : "שְׁכַח",
		"xlit" : "shᵉkach",
		"pron" : "shek-akh'",
		"derivation" : "(Aramaic) corresponding to H7911 (שָׁכַח) through the idea of disclosure of a covered or forgotten thing",
		"def" : "to discover (literally or figuratively)",
		"kjv" : "find"
	},
	"H7913" : {
		"lemma" : "שָׁכֵחַ",
		"xlit" : "shâkêach",
		"pron" : "shaw-kay'-akh",
		"derivation" : "from H7911 (שָׁכַח)",
		"def" : "oblivious",
		"kjv" : "forget"
	},
	"H7914" : {
		"lemma" : "שְׂכִיָּה",
		"xlit" : "sᵉkîyâh",
		"pron" : "sek-ee-yaw'",
		"derivation" : "feminine from the same as H7906 (שֵׂכוּ)",
		"def" : "a conspicuous object",
		"kjv" : "picture"
	},
	"H7915" : {
		"lemma" : "שַׂכִּין",
		"xlit" : "sakkîyn",
		"pron" : "sak-keen'",
		"derivation" : "intensive perhaps from the same as H7906 (שֵׂכוּ) in the sense of H7753 (שׂוּךְ)",
		"def" : "a knife (as pointed or edged)",
		"kjv" : "knife"
	},
	"H7916" : {
		"lemma" : "שָׂכִיר",
		"xlit" : "sâkîyr",
		"pron" : "saw-keer'",
		"derivation" : "from H7936 (שָׂכַר)",
		"def" : "a man at wages  by the day or year",
		"kjv" : "hired (man, servant), hireling"
	},
	"H7917" : {
		"lemma" : "שְׂכִירָה",
		"xlit" : "sᵉkîyrâh",
		"pron" : "sek-ee-raw'",
		"derivation" : "feminine of H7916 (שָׂכִיר)",
		"def" : "a hiring",
		"kjv" : "that is hired"
	},
	"H7918" : {
		"lemma" : "שָׁכַךְ",
		"xlit" : "shâkak",
		"pron" : "shaw-kak'",
		"derivation" : "a primitive root",
		"def" : "to weave (i.e. lay) a trap; figuratively, (through the idea of secreting) to allay (passions; physically, abate a flood)",
		"kjv" : "appease, assuage, make to cease, pacify, set"
	},
	"H7919" : {
		"lemma" : "שָׂכַל",
		"xlit" : "sâkal",
		"pron" : "saw-kal'",
		"derivation" : "a primitive root",
		"def" : "to be (causatively, make or act) circumspect and hence, intelligent",
		"kjv" : "consider, expert, instruct, prosper, (deal) prudent(-ly), (give) skill(-ful), have good success, teach, (have, make to) understand(-ing), wisdom, (be, behave self, consider, make) wise(-ly), guide wittingly"
	},
	"H7920" : {
		"lemma" : "שְׂכַל",
		"xlit" : "sᵉkal",
		"pron" : "sek-al'",
		"derivation" : "(Aramaic) corresponding to H7919 (שָׂכַל)",
		"def" : "{to be (causatively, make or act) circumspect and hence, intelligent}",
		"kjv" : "consider"
	},
	"H7921" : {
		"lemma" : "שָׁכֹל",
		"xlit" : "shâkôl",
		"pron" : "shaw-kole'",
		"derivation" : "a primitive root",
		"def" : "properly, to miscarry, i.e. suffer abortion; by analogy, to bereave (literally or figuratively)",
		"kjv" : "bereave (of children), barren, cast calf (fruit, young), be (make) childless, deprive, destroy, [idiom] expect, lose children, miscarry, rob of children, spoil"
	},
	"H7922" : {
		"lemma" : "שֶׂכֶל",
		"xlit" : "sekel",
		"pron" : "seh'-kel",
		"derivation" : "or שֵׂכֶל; from H7919 (שָׂכַל)",
		"def" : "intelligence; by implication, success",
		"kjv" : "discretion, knowledge, policy, prudence, sense, understanding, wisdom, wise"
	},
	"H7923" : {
		"lemma" : "שִׁכֻּלִים",
		"xlit" : "shikkulîym",
		"pron" : "shik-koo-leem'",
		"derivation" : "plural from H7921 (שָׁכֹל)",
		"def" : "childlessness (by continued bereavements)",
		"kjv" : "to have after loss of others"
	},
	"H7924" : {
		"lemma" : "שׇׂכְלְתָנוּ",
		"xlit" : "soklᵉthânûw",
		"pron" : "sok-leth-aw-noo'",
		"derivation" : "(Aramaic) from H7920 (שְׂכַל)",
		"def" : "intelligence",
		"kjv" : "understanding"
	},
	"H7925" : {
		"lemma" : "שָׁכַם",
		"xlit" : "shâkam",
		"pron" : "shaw-kam'",
		"derivation" : "a primitive root; properly, to incline (the shoulder to a burden); but used only as denominative from H7926 (שְׁכֶם)",
		"def" : "literally, to load up (on the back of man or beast), i.e. to start early in the morning",
		"kjv" : "(arise, be up, get (oneself) up, rise up) early (betimes), morning"
	},
	"H7926" : {
		"lemma" : "שְׁכֶם",
		"xlit" : "shᵉkem",
		"pron" : "shek-em'",
		"derivation" : "from H7925 (שָׁכַם)",
		"def" : "the neck (between the shoulders) as the place of burdens; figuratively, the spur of ahill",
		"kjv" : "back, [idiom] consent, portion, shoulder"
	},
	"H7927" : {
		"lemma" : "שְׁכֶם",
		"xlit" : "Shᵉkem",
		"pron" : "shek-em'",
		"derivation" : "the same as H7926 (שְׁכֶם); ridge",
		"def" : "Shekem, a place in Palestine",
		"kjv" : "Shechem"
	},
	"H7928" : {
		"lemma" : "שֶׁכֶם",
		"xlit" : "Shekem",
		"pron" : "sheh'-kem",
		"derivation" : "for H7926 (שְׁכֶם)",
		"def" : "Shekem, the name of a Hivite and two Israelites",
		"kjv" : "Shechem"
	},
	"H7929" : {
		"lemma" : "שִׁכְמָה",
		"xlit" : "shikmâh",
		"pron" : "shik-maw'",
		"derivation" : "feminine of H7926 (שְׁכֶם)",
		"def" : "the shoulderbone",
		"kjv" : "shoulder blade"
	},
	"H7930" : {
		"lemma" : "שִׁכְמִי",
		"xlit" : "Shikmîy",
		"pron" : "shik-mee'",
		"derivation" : "patronymic from H7928 (שֶׁכֶם)",
		"def" : "a Shikmite (collectively), or descendants of Shekem",
		"kjv" : "Shichemites"
	},
	"H7931" : {
		"lemma" : "שָׁכַן",
		"xlit" : "shâkan",
		"pron" : "shaw-kan'",
		"derivation" : "a primitive root (apparently akin (by transmission) to H7901 (שָׁכַב) through the idea of lodging; compare H5531 (סִכְלוּת), H7925 (שָׁכַם))",
		"def" : "to reside or permanently stay (literally or figuratively)",
		"kjv" : "abide, continue, (cause to, make to) dwell(-er), have habitation, inhabit, lay, place, (cause to) remain, rest, set (up)"
	},
	"H7932" : {
		"lemma" : "שְׁכַן",
		"xlit" : "shᵉkan",
		"pron" : "shek-an'",
		"derivation" : "(Aramaic) corresponding to H7931 (שָׁכַן)",
		"def" : "{to reside or permanently stay (literally or figuratively)}",
		"kjv" : "cause to dwell, have habitation"
	},
	"H7933" : {
		"lemma" : "שֶׁכֶן",
		"xlit" : "sheken",
		"pron" : "sheh'-ken",
		"derivation" : "from H7931 (שָׁכַן)",
		"def" : "a residence",
		"kjv" : "habitation"
	},
	"H7934" : {
		"lemma" : "שָׁכֵן",
		"xlit" : "shâkên",
		"pron" : "shaw-kane'",
		"derivation" : "from H7931 (שָׁכַן)",
		"def" : "a resident; by extension, a fellow-citizen",
		"kjv" : "inhabitant, neighbour, nigh"
	},
	"H7935" : {
		"lemma" : "שְׁכַנְיָה",
		"xlit" : "Shᵉkanyâh",
		"pron" : "shek-an-yaw'",
		"derivation" : "or (prolonged) שְׁכַנְיָהוּ; from H7931 (שָׁכַן) and H3050 (יָהּ); Jah has dwelt",
		"def" : "Shekanjah, the name of nine Israelites",
		"kjv" : "Shecaniah, Shechaniah"
	},
	"H7936" : {
		"lemma" : "שָׂכַר",
		"xlit" : "sâkar",
		"pron" : "saw-kar'",
		"derivation" : "or (by permutation) סָכַר; (Ezra 4:5), a primitive root (apparently akin (by prosthesis) to H3739 (כָּרָה) through the idea of temporary purchase; compare H7937 (שָׁכַר))",
		"def" : "to hire",
		"kjv" : "earn wages, hire (out self), reward, [idiom] surely"
	},
	"H7937" : {
		"lemma" : "שָׁכַר",
		"xlit" : "shâkar",
		"pron" : "shaw-kar'",
		"derivation" : "a primitive root",
		"def" : "to become tipsy; in a qualified sense, to satiate with astimulating drink or (figuratively) influence",
		"kjv" : "(be filled with) drink (abundantly), (be, make) drunk(-en), be merry. (Superlative of H8248 (שָׁקָה).)"
	},
	"H7938" : {
		"lemma" : "שֶׂכֶר",
		"xlit" : "seker",
		"pron" : "seh'-ker",
		"derivation" : "from H7936 (שָׂכַר)",
		"def" : "wages",
		"kjv" : "reward, sluices"
	},
	"H7939" : {
		"lemma" : "שָׂכָר",
		"xlit" : "sâkâr",
		"pron" : "saw-kawr'",
		"derivation" : "from H7936 (שָׂכַר)",
		"def" : "payment of contract; concretely, salary, fare, maintenance; by implication, compensation, benefit",
		"kjv" : "hire, price, reward(-ed), wages, worth"
	},
	"H7940" : {
		"lemma" : "שָׂכָר",
		"xlit" : "Sâkâr",
		"pron" : "saw-kar'",
		"derivation" : "the same as H7939 (שָׂכָר); recompense",
		"def" : "Sakar, the name of two Israelites",
		"kjv" : "Sacar"
	},
	"H7941" : {
		"lemma" : "שֵׁכָר",
		"xlit" : "shêkâr",
		"pron" : "shay-kawr'",
		"derivation" : "from H7937 (שָׁכַר)",
		"def" : "an intoxicant, i.e. intensely alcoholic liquor",
		"kjv" : "strong drink, [phrase] drunkard, strong wine"
	},
	"H7942" : {
		"lemma" : "שִׁכְּרוֹן",
		"xlit" : "Shikkᵉrôwn",
		"pron" : "shik-ker-one'",
		"derivation" : "for H7943 (שִׁכָּרוֹן)",
		"def" : "drunkenness, Shikkeron, a place in Palestine",
		"kjv" : "Shicron"
	},
	"H7943" : {
		"lemma" : "שִׁכָּרוֹן",
		"xlit" : "shikkârôwn",
		"pron" : "shik-kaw-rone'",
		"derivation" : "from H7937 (שָׁכַר)",
		"def" : "intoxication",
		"kjv" : "(be) drunken(-ness)"
	},
	"H7944" : {
		"lemma" : "שַׁל",
		"xlit" : "shal",
		"pron" : "shal",
		"derivation" : "from H7952 (שָׁלָה) abbreviated form",
		"def" : "a fault",
		"kjv" : "error"
	},
	"H7945" : {
		"lemma" : "שֶׁל",
		"xlit" : "shel",
		"pron" : "shel",
		"derivation" : "for the rel. H834 (אֲשֶׁר); used with prepositional prefix, and often followed by some pronominal affix",
		"def" : "on account of, whatsoever, whichsoever",
		"kjv" : "cause, sake"
	},
	"H7946" : {
		"lemma" : "שַׁלְאֲנָן",
		"xlit" : "shalʼănân",
		"pron" : "shal-an-awn'",
		"derivation" : "for H7600 (שַׁאֲנָן)",
		"def" : "tranquil",
		"kjv" : "being at ease"
	},
	"H7947" : {
		"lemma" : "שָׁלַב",
		"xlit" : "shâlab",
		"pron" : "shaw-lab'",
		"derivation" : "a primitive root",
		"def" : "to space off; intensive (evenly) to make equidistant",
		"kjv" : "equally distant, set in order"
	},
	"H7948" : {
		"lemma" : "שָׁלָב",
		"xlit" : "shâlâb",
		"pron" : "shaw-lawb'",
		"derivation" : "from H7947 (שָׁלַב)",
		"def" : "a spacer or raised interval, i.e. the stile in aframe or panel",
		"kjv" : "ledge"
	},
	"H7949" : {
		"lemma" : "שָׁלַג",
		"xlit" : "shâlag",
		"pron" : "shaw-lag'",
		"derivation" : "a primitive root; properly, meaning to be white; used only as denominative from H7950 (שֶׁלֶג)",
		"def" : "to be snow-white (with the linen clothing of the slain)",
		"kjv" : "be as snow"
	},
	"H7950" : {
		"lemma" : "שֶׁלֶג",
		"xlit" : "sheleg",
		"pron" : "sheh'-leg",
		"derivation" : "from H7949 (שָׁלַג)",
		"def" : "snow (probably from its whiteness)",
		"kjv" : "snow(-y)"
	},
	"H7951" : {
		"lemma" : "שָׁלָה",
		"xlit" : "shâlâh",
		"pron" : "shaw-law'",
		"derivation" : "or שָׁלַו; (Job 3:26), a primitive root",
		"def" : "to be tranquil, i.e. secure or successful",
		"kjv" : "be happy, prosper, be in safety"
	},
	"H7952" : {
		"lemma" : "שָׁלָה",
		"xlit" : "shâlâh",
		"pron" : "shaw-law'",
		"derivation" : "a primitive root (probably identical with H7953 (שָׁלָה) through the idea of educing)",
		"def" : "to mislead",
		"kjv" : "deceive, be negligent"
	},
	"H7953" : {
		"lemma" : "שָׁלָה",
		"xlit" : "shâlâh",
		"pron" : "shaw-law'",
		"derivation" : "a primitive root (rather cognate (by contraction) to the base of H5394 (נָשַׁל), H7997 (שָׁלַל) and their congeners through the idea of extracting)",
		"def" : "to draw out or off, i.e. remove (the soul by death)",
		"kjv" : "take away"
	},
	"H7954" : {
		"lemma" : "שְׁלָה",
		"xlit" : "shᵉlâh",
		"pron" : "shel-aw'",
		"derivation" : "(Aramaic) corresponding to H7951 (שָׁלָה)",
		"def" : "to be secure",
		"kjv" : "at rest"
	},
	"H7955" : {
		"lemma" : "שָׁלָה",
		"xlit" : "shâlâh",
		"pron" : "shaw-law'",
		"derivation" : "(Aramaic) from a root corresponding to H7952 (שָׁלָה)",
		"def" : "a wrong",
		"kjv" : "thing amiss"
	},
	"H7956" : {
		"lemma" : "שֵׁלָה",
		"xlit" : "Shêlâh",
		"pron" : "shay-law'",
		"derivation" : "the same as H7596 (שְׁאֵלָה) (shortened); request",
		"def" : "Shelah, the name of a postdiluvian patriarch and of an Israelite",
		"kjv" : "Shelah"
	},
	"H7957" : {
		"lemma" : "שַׁלְהֶבֶת",
		"xlit" : "shalhebeth",
		"pron" : "shal-heh'-beth",
		"derivation" : "from the same as H3851 (לַהַב) with sibilant prefix",
		"def" : "a flare of fire",
		"kjv" : "(flaming) flame"
	},
	"H7958" : {
		"lemma" : "שְׂלָו",
		"xlit" : "sᵉlâv",
		"pron" : "sel-awv'",
		"derivation" : "or שְׂלָיו; by orthographical variation from H7951 (שָׁלָה) through the idea of sluggishness",
		"def" : "the quail collectively (as slow in flight from its weight)",
		"kjv" : "quails"
	},
	"H7959" : {
		"lemma" : "שֶׁלֶו",
		"xlit" : "shelev",
		"pron" : "sheh'-lev",
		"derivation" : "from H7951 (שָׁלָה)",
		"def" : "security",
		"kjv" : "prosperity"
	},
	"H7960" : {
		"lemma" : "שָׁלוּ",
		"xlit" : "shâlûw",
		"pron" : "shaw-loo'",
		"derivation" : "(Aramaic) or שָׁלוּת; (Aramaic), from the same as H7955 (שָׁלָה)",
		"def" : "a fault",
		"kjv" : "error, [idiom] fail, thing amiss"
	},
	"H7961" : {
		"lemma" : "שָׁלֵו",
		"xlit" : "shâlêv",
		"pron" : "shaw-lave'",
		"derivation" : "or שָׁלֵיו; feminine שְׁלֵוָה; from H7951 (שָׁלָה)",
		"def" : "tranquil; (in a bad sense) careless; abstractly, security",
		"kjv" : "(being) at ease, peaceable, (in) prosper(-ity), quiet(-ness), wealthy"
	},
	"H7962" : {
		"lemma" : "שַׁלְוָה",
		"xlit" : "shalvâh",
		"pron" : "shal-vaw'",
		"derivation" : "from H7951 (שָׁלָה)",
		"def" : "security (genuine or false)",
		"kjv" : "abundance, peace(-ably), prosperity, quietness"
	},
	"H7963" : {
		"lemma" : "שְׁלֵוָה",
		"xlit" : "shᵉlêvâh",
		"pron" : "shel-ay-vaw'",
		"derivation" : "(Aramaic) corresponding to H7962 (שַׁלְוָה)",
		"def" : "safety",
		"kjv" : "tranquillity. See also H7961 (שָׁלֵו)"
	},
	"H7964" : {
		"lemma" : "שִׁלּוּחַ",
		"xlit" : "shillûwach",
		"pron" : "shil-loo'-akh",
		"derivation" : "or שִׁלֻּחַ; from H7971 (שָׁלַח)",
		"def" : "(only in plural) a dismissal, i.e. (of a wife) divorce (especially the document); also (of a daughter) dower",
		"kjv" : "presents, have sent back"
	},
	"H7965" : {
		"lemma" : "שָׁלוֹם",
		"xlit" : "shâlôwm",
		"pron" : "shaw-lome'",
		"derivation" : "or שָׁלֹם; from H7999 (שָׁלַם)",
		"def" : "safe, i.e. (figuratively) well, happy, friendly; also (abstractly) welfare, i.e. health, prosperity, peace",
		"kjv" : "[idiom] do, familiar, [idiom] fare, favour, [phrase] friend, [idiom] great, (good) health, ([idiom] perfect, such as be at) peace(-able, -ably), prosper(-ity, -ous), rest, safe(-ty), salute, welfare, ([idiom] all is, be) well, [idiom] wholly"
	},
	"H7966" : {
		"lemma" : "שִׁלּוּם",
		"xlit" : "shillûwm",
		"pron" : "shil-loom'",
		"derivation" : "or שִׁלֻּם; from H7999 (שָׁלַם)",
		"def" : "a requital, i.e. (secure) retribution, (venal) a fee",
		"kjv" : "recompense, reward"
	},
	"H7967" : {
		"lemma" : "שַׁלּוּם",
		"xlit" : "Shallûwm",
		"pron" : "shal-loom'",
		"derivation" : "or (shorter) שַׁלֻּם; the same as H7966 (שִׁלּוּם)",
		"def" : "Shallum, the name of fourteen Israelites",
		"kjv" : "Shallum"
	},
	"H7968" : {
		"lemma" : "שַׁלּוּן",
		"xlit" : "Shallûwn",
		"pron" : "shal-loon'",
		"derivation" : "probably for H7967 (שַׁלּוּם)",
		"def" : "Shallun, an Israelite",
		"kjv" : "Shallum"
	},
	"H7969" : {
		"lemma" : "שָׁלוֹשׁ",
		"xlit" : "shâlôwsh",
		"pron" : "shaw-loshe'",
		"derivation" : "or שָׁלֹשׁ; masculine שְׁלוֹשָׁה; or שְׁלֹשָׁה; a primitive number",
		"def" : "three; occasionally (ordinal) third, or (multiple) thrice",
		"kjv" : "[phrase] fork, [phrase] often(-times), third, thir(-teen, -teenth), three, [phrase] thrice. Compare H7991 (שָׁלִישׁ)"
	},
	"H7970" : {
		"lemma" : "שְׁלוֹשִׁים",
		"xlit" : "shᵉlôwshîym",
		"pron" : "shel-o-sheem'",
		"derivation" : "or שְׁלֹשִׁים; multiple of H7969 (שָׁלוֹשׁ)",
		"def" : "thirty; or (ordinal) thirtieth",
		"kjv" : "thirty, thirtieth. Compare H7991 (שָׁלִישׁ)"
	},
	"H7971" : {
		"lemma" : "שָׁלַח",
		"xlit" : "shâlach",
		"pron" : "shaw-lakh'",
		"derivation" : "a primitive root",
		"def" : "to send away, for, or out (in a great variety of applications)",
		"kjv" : "[idiom] any wise, appoint, bring (on the way), cast (away, out), conduct, [idiom] earnestly, forsake, give (up), grow long, lay, leave, let depart (down, go, loose), push away, put (away, forth, in, out), reach forth, send (away, forth, out), set, shoot (forth, out), sow, spread, stretch forth (out)"
	},
	"H7972" : {
		"lemma" : "שְׁלַח",
		"xlit" : "shᵉlach",
		"pron" : "shel-akh'",
		"derivation" : "(Aramaic) corresponding to H7971 (שָׁלַח)",
		"def" : "{to send away, for, or out (in a great variety of applications)}",
		"kjv" : "put, send"
	},
	"H7973" : {
		"lemma" : "שֶׁלַח",
		"xlit" : "shelach",
		"pron" : "sheh'-lakh",
		"derivation" : "from H7971 (שָׁלַח)",
		"def" : "a missile of attack, i.e. spear; also (figuratively) a shoot of growth; i.e. branch",
		"kjv" : "dart, plant, [idiom] put off, sword, weapon"
	},
	"H7974" : {
		"lemma" : "שֶׁלַח",
		"xlit" : "Shelach",
		"pron" : "sheh'-lakh",
		"derivation" : "the same as H7973 (שֶׁלַח)",
		"def" : "Shelach, a postdiluvian patriarch",
		"kjv" : "Salah, Shelah. Compare H7975 (שִׁלֹחַ)lemma שִׁלֹּחַ extra dagesh, corrected to שִׁלֹחַ"
	},
	"H7975" : {
		"lemma" : "שִׁלֹחַ",
		"xlit" : "Shilôach",
		"pron" : "shee-lo'-akh",
		"derivation" : "or (in imitation of H7974 (שֶׁלַח)) שֶׁלַח; (Nehemiah 3:15), from H7971 (שָׁלַח); rill",
		"def" : "Shiloach, a fountain of Jerusalem",
		"kjv" : "Shiloah, Siloah"
	},
	"H7976" : {
		"lemma" : "שִׁלֻּחָה",
		"xlit" : "shilluchâh",
		"pron" : "shil-loo-khaw'",
		"derivation" : "feminine of H7964 (שִׁלּוּחַ)",
		"def" : "a shoot",
		"kjv" : "branch"
	},
	"H7977" : {
		"lemma" : "שִׁלְחִי",
		"xlit" : "Shilchîy",
		"pron" : "shil-khee'",
		"derivation" : "from H7973 (שֶׁלַח); missive, i.e. armed",
		"def" : "Shilchi, an Israelite",
		"kjv" : "Shilhi"
	},
	"H7978" : {
		"lemma" : "שִׁלְחִים",
		"xlit" : "Shilchîym",
		"pron" : "shil-kheem'",
		"derivation" : "plural of H7973 (שֶׁלַח); javelins or sprouts",
		"def" : "Shilchim, a place in Palestine",
		"kjv" : "Shilhim"
	},
	"H7979" : {
		"lemma" : "שֻׁלְחָן",
		"xlit" : "shulchân",
		"pron" : "shool-khawn'",
		"derivation" : "from H7971 (שָׁלַח)",
		"def" : "a table (as spread out); by implication, a meal",
		"kjv" : "table"
	},
	"H7980" : {
		"lemma" : "שָׁלַט",
		"xlit" : "shâlaṭ",
		"pron" : "shaw-lat'",
		"derivation" : "a primitive root",
		"def" : "to dominate, i.e. govern; by implication, to permit",
		"kjv" : "(bear, have) rule, have dominion, give (have) power"
	},
	"H7981" : {
		"lemma" : "שְׁלֵט",
		"xlit" : "shᵉlêṭ",
		"pron" : "shel-ate'",
		"derivation" : "(Aramaic) corresponding to H7980 (שָׁלַט)",
		"def" : "{to dominate, i.e. govern; by implication, to permit}",
		"kjv" : "have the mastery, have power, bear rule, be (make) ruler"
	},
	"H7982" : {
		"lemma" : "שֶׁלֶט",
		"xlit" : "sheleṭ",
		"pron" : "sheh'-let",
		"derivation" : "from H7980 (שָׁלַט)",
		"def" : "probably a shield (as controlling, i.e. protecting the person)",
		"kjv" : "shield"
	},
	"H7983" : {
		"lemma" : "שִׁלְטוֹן",
		"xlit" : "shilṭôwn",
		"pron" : "shil-tone'",
		"derivation" : "(Aramaic) from H7980 (שָׁלַט)",
		"def" : "{power.}",
		"kjv" : "power"
	},
	"H7984" : {
		"lemma" : "שִׁלְטוֹן",
		"xlit" : "shilṭôwn",
		"pron" : "shil-tone'",
		"derivation" : "(Aramaic) or שִׁלְטֹןlemma שִׁלְטן missing vowel, corrected to שִׁלְטֹן; corresponding to H7983 (שִׁלְטוֹן)",
		"def" : "{a potentate}",
		"kjv" : "ruler"
	},
	"H7985" : {
		"lemma" : "שׇׁלְטָן",
		"xlit" : "sholṭân",
		"pron" : "shol-tawn'",
		"derivation" : "(Aramaic) from H7981 (שְׁלֵט)",
		"def" : "empire (abstractly or concretely)",
		"kjv" : "dominion"
	},
	"H7986" : {
		"lemma" : "שַׁלֶּטֶת",
		"xlit" : "shalleṭeth",
		"pron" : "shal-leh'-teth",
		"derivation" : "feminine from H7980 (שָׁלַט)",
		"def" : "a vixen",
		"kjv" : "imperious"
	},
	"H7987" : {
		"lemma" : "שְׁלִי",
		"xlit" : "shᵉlîy",
		"pron" : "shel-ee'",
		"derivation" : "from H7951 (שָׁלָה)",
		"def" : "privacy",
		"kjv" : "[phrase] quietly"
	},
	"H7988" : {
		"lemma" : "שִׁלְיָה",
		"xlit" : "shilyâh",
		"pron" : "shil-yaw'",
		"derivation" : "feminine from H7953 (שָׁלָה)",
		"def" : "a fetus or babe (as extruded in birth)",
		"kjv" : "young one"
	},
	"H7989" : {
		"lemma" : "שַׁלִּיט",
		"xlit" : "shallîyṭ",
		"pron" : "shal-leet'",
		"derivation" : "from H7980 (שָׁלַט)",
		"def" : "potent; concretely, a prince or warrior",
		"kjv" : "governor, mighty, that hath power, ruler"
	},
	"H7990" : {
		"lemma" : "שַׁלִּיט",
		"xlit" : "shallîyṭ",
		"pron" : "shal-leet'",
		"derivation" : "(Aramaic) corresponding to H7989 (שַׁלִּיט)",
		"def" : "mighty; abstractly, permission; concretely, a premier",
		"kjv" : "captain, be lawful, rule(-r)"
	},
	"H7991" : {
		"lemma" : "שָׁלִישׁ",
		"xlit" : "shâlîysh",
		"pron" : "shaw-leesh'",
		"derivation" : "or שָׁלוֹשׁ; (1 Chronicles 11:11; 1 Chronicles 12:18), or שָׁלֹשׁ; (2 Samuel 23:13), from H7969 (שָׁלוֹשׁ)",
		"def" : "a triple, i.e. (as a musical instrument) a triangle (or perhaps rather threestringed lute); also (as an indefinite, great quantity) a three-fold measure (perhaps a treble ephah); also (as an officer) a general of the third rank (upward, i.e. the highest)",
		"kjv" : "captain, instrument of musick, (great) lord, (great) measure, prince, three (from the margin)"
	},
	"H7992" : {
		"lemma" : "שְׁלִישִׁי",
		"xlit" : "shᵉlîyshîy",
		"pron" : "shel-ee-shee'",
		"derivation" : "ordinal from H7969 (שָׁלוֹשׁ)",
		"def" : "third; feminine athird (part); by extension, a third (day, year or time); specifically, a third-story cell)",
		"kjv" : "third (part, rank, time), three (years old)"
	},
	"H7993" : {
		"lemma" : "שָׁלַךְ",
		"xlit" : "shâlak",
		"pron" : "shaw-lak",
		"derivation" : "a primitive root",
		"def" : "to throw out, down or away (literally or figuratively)",
		"kjv" : "adventure, cast (away, down, forth, off, out), hurl, pluck, throw"
	},
	"H7994" : {
		"lemma" : "שָׁלָךְ",
		"xlit" : "shâlâk",
		"pron" : "shaw-lawk'",
		"derivation" : "from H7993 (שָׁלַךְ)",
		"def" : "bird of prey, usually thought to be the pelican (from casting itself into the sea)",
		"kjv" : "cormorant"
	},
	"H7995" : {
		"lemma" : "שַׁלֶּכֶת",
		"xlit" : "shalleketh",
		"pron" : "shal-leh'-keth",
		"derivation" : "from H7993 (שָׁלַךְ)",
		"def" : "a felling (of trees)",
		"kjv" : "when cast"
	},
	"H7996" : {
		"lemma" : "שַׁלֶּכֶת",
		"xlit" : "Shalleketh",
		"pron" : "shal-leh'-keth",
		"derivation" : "the same as H7995 (שַׁלֶּכֶת)",
		"def" : "Shalleketh, a gate in Jerusalem",
		"kjv" : "Shalleketh"
	},
	"H7997" : {
		"lemma" : "שָׁלַל",
		"xlit" : "shâlal",
		"pron" : "shaw-lal'",
		"derivation" : "a primitive root",
		"def" : "to drop or strip; by implication, to plunder",
		"kjv" : "let fall, make self a prey, [idiom] of purpose, (make a, (take)) spoil"
	},
	"H7998" : {
		"lemma" : "שָׁלָל",
		"xlit" : "shâlâl",
		"pron" : "shaw-lawl'",
		"derivation" : "from H7997 (שָׁלַל)",
		"def" : "booty",
		"kjv" : "prey, spoil"
	},
	"H7999" : {
		"lemma" : "שָׁלַם",
		"xlit" : "shâlam",
		"pron" : "shaw-lam'",
		"derivation" : "a primitive root",
		"def" : "to be safe (in mind, body or estate); figuratively, to be (causatively, make) completed; by implication, to be friendly; by extension, to reciprocate (in various applications)",
		"kjv" : "make amends, (make an) end, finish, full, give again, make good, (re-) pay (again), (make) (to) (be at) peace(-able), that is perfect, perform, (make) prosper(-ous), recompense, render, requite, make restitution, restore, reward, [idiom] surely"
	},
	"H8000" : {
		"lemma" : "שְׁלַם",
		"xlit" : "shᵉlam",
		"pron" : "shel-am'",
		"derivation" : "(Aramaic) corresponding to H7999 (שָׁלַם)",
		"def" : "to complete, to restore",
		"kjv" : "deliver, finish"
	},
	"H8001" : {
		"lemma" : "שְׁלָם",
		"xlit" : "shᵉlâm",
		"pron" : "shel-awm'",
		"derivation" : "(Aramaic) corresponding to H7965 (שָׁלוֹם)",
		"def" : "prosperity",
		"kjv" : "peace"
	},
	"H8002" : {
		"lemma" : "שֶׁלֶם",
		"xlit" : "shelem",
		"pron" : "sheh'-lem",
		"derivation" : "from H7999 (שָׁלַם)",
		"def" : "properly, requital, i.e. a (voluntary) sacrifice in thanks",
		"kjv" : "peace offering"
	},
	"H8003" : {
		"lemma" : "שָׁלֵם",
		"xlit" : "shâlêm",
		"pron" : "shaw-lame'",
		"derivation" : "from H7999 (שָׁלַם)",
		"def" : "complete (literally or figuratively); especially friendly",
		"kjv" : "full, just, made ready, peaceable, perfect(-ed), quiet, Shalem (by mistake for a name), whole"
	},
	"H8004" : {
		"lemma" : "שָׁלֵם",
		"xlit" : "Shâlêm",
		"pron" : "shaw-lame'",
		"derivation" : "the same as H8003 (שָׁלֵם); peaceful",
		"def" : "Shalem, an early name of Jerusalem",
		"kjv" : "Salem"
	},
	"H8005" : {
		"lemma" : "שִׁלֵּם",
		"xlit" : "shillêm",
		"pron" : "shil-lame'",
		"derivation" : "from H7999 (שָׁלַם)",
		"def" : "requital",
		"kjv" : "recompense"
	},
	"H8006" : {
		"lemma" : "שִׁלֵּם",
		"xlit" : "Shillêm",
		"pron" : "shil-lame'",
		"derivation" : "the same as H8005 (שִׁלֵּם)",
		"def" : "Shillem, an Israelite",
		"kjv" : "Shillem"
	},
	"H8007" : {
		"lemma" : "שַׂלְמָא",
		"xlit" : "Salmâʼ",
		"pron" : "sal-maw'",
		"derivation" : "probably for H8008 (שַׂלְמָה); clothing",
		"def" : "Salma, the name of two Israelites",
		"kjv" : "Salma"
	},
	"H8008" : {
		"lemma" : "שַׂלְמָה",
		"xlit" : "salmâh",
		"pron" : "sal-maw'",
		"derivation" : "transp. for H8071 (שִׂמְלָה)",
		"def" : "a dress",
		"kjv" : "clothes, garment, raiment"
	},
	"H8009" : {
		"lemma" : "שַׂלְמָה",
		"xlit" : "Salmâh",
		"pron" : "sal-maw'",
		"derivation" : "the same as H8008 (שַׂלְמָה); clothing",
		"def" : "Salmah, an Israelite",
		"kjv" : "Salmon. Compare H8012 (שַׂלְמוֹן)"
	},
	"H8010" : {
		"lemma" : "שְׁלֹמֹה",
		"xlit" : "Shᵉlômôh",
		"pron" : "shel-o-mo'",
		"derivation" : "from H7965 (שָׁלוֹם); peaceful",
		"def" : "Shelomah, David's successor",
		"kjv" : "Solomon"
	},
	"H8011" : {
		"lemma" : "שִׁלֻּמָה",
		"xlit" : "shillumâh",
		"pron" : "shil-loo-maw'",
		"derivation" : "feminine of H7966 (שִׁלּוּם)",
		"def" : "retribution",
		"kjv" : "recompense"
	},
	"H8012" : {
		"lemma" : "שַׂלְמוֹן",
		"xlit" : "Salmôwn",
		"pron" : "sal-mone'",
		"derivation" : "from H8008 (שַׂלְמָה); investiture",
		"def" : "Salmon, an Israelite",
		"kjv" : "Salmon. Compare H8009 (שַׂלְמָה)"
	},
	"H8013" : {
		"lemma" : "שְׁלֹמוֹת",
		"xlit" : "Shᵉlômôwth",
		"pron" : "shel-o-moth'",
		"derivation" : "feminine plural of H7965 (שָׁלוֹם); pacifications",
		"def" : "Shelomoth, the name of two Israelites",
		"kjv" : "Shelomith (from the margin), Shelomoth. Compare H8019 (שְׁלֹמִית)"
	},
	"H8014" : {
		"lemma" : "שַׂלְמַי",
		"xlit" : "Salmay",
		"pron" : "sal-mah'-ee",
		"derivation" : "from H8008 (שַׂלְמָה); clothed",
		"def" : "Salmai, an Israelite",
		"kjv" : "Shalmai"
	},
	"H8015" : {
		"lemma" : "שְׁלֹמִי",
		"xlit" : "Shᵉlômîy",
		"pron" : "shel-o-mee'",
		"derivation" : "from H7965 (שָׁלוֹם); peaceable",
		"def" : "Shelomi, an Israelite",
		"kjv" : "Shelomi"
	},
	"H8016" : {
		"lemma" : "שִׁלֵּמִי",
		"xlit" : "Shillêmîy",
		"pron" : "shil-lay-mee'",
		"derivation" : "patronymically from H8006 (שִׁלֵּם)",
		"def" : "a Shilemite (collectively) or descendants of Shillem",
		"kjv" : "Shillemites"
	},
	"H8017" : {
		"lemma" : "שְׁלֻמִיאֵל",
		"xlit" : "Shᵉlumîyʼêl",
		"pron" : "shel-oo-mee-ale'",
		"derivation" : "from H7965 (שָׁלוֹם) and H410 (אֵל); peace of God",
		"def" : "Shelumiel, an Israelite",
		"kjv" : "Shelumiel"
	},
	"H8018" : {
		"lemma" : "שֶׁלֶמְיָה",
		"xlit" : "Shelemyâh",
		"pron" : "shel-em-yaw'",
		"derivation" : "or שֶׁלֶמְיָהוּ; from H8002 (שֶׁלֶם) and H3050 (יָהּ); thankoffering of Jah",
		"def" : "Shelemjah, the name of nine Israelites",
		"kjv" : "Shelemiah"
	},
	"H8019" : {
		"lemma" : "שְׁלֹמִית",
		"xlit" : "Shᵉlômîyth",
		"pron" : "shel-o-meeth'",
		"derivation" : "or שׁלוֹמִית; (Ezra 8:10), from H7965 (שָׁלוֹם); peaceableness",
		"def" : "Shelomith, the name of five Israelites and three Israelitesses",
		"kjv" : "Shelomith"
	},
	"H8020" : {
		"lemma" : "שַׁלְמַן",
		"xlit" : "Shalman",
		"pron" : "shal-man'",
		"derivation" : "of foreign derivation",
		"def" : "Shalman, a king apparently of Assyria",
		"kjv" : "Shalman. Compare H8022 (שַׁלְמַנְאֶסֶר)"
	},
	"H8021" : {
		"lemma" : "שַׁלְמֹן",
		"xlit" : "shalmôn",
		"pron" : "shal-mone'",
		"derivation" : "from H7999 (שָׁלַם)",
		"def" : "a bribe",
		"kjv" : "reward"
	},
	"H8022" : {
		"lemma" : "שַׁלְמַנְאֶסֶר",
		"xlit" : "Shalmanʼeçer",
		"pron" : "shal-man-eh'-ser",
		"derivation" : "of foreign derivation",
		"def" : "Shalmaneser, an Assyrian king",
		"kjv" : "Shalmaneser. Comp H8020 (שַׁלְמַן)"
	},
	"H8023" : {
		"lemma" : "שִׁלֹנִי",
		"xlit" : "Shilônîy",
		"pron" : "shee-lo-nee'",
		"derivation" : "the same as H7888 (שִׁילוֹנִי)",
		"def" : "Shiloni, an Israelite",
		"kjv" : "Shiloni"
	},
	"H8024" : {
		"lemma" : "שֵׁלָנִי",
		"xlit" : "Shêlânîy",
		"pron" : "shay-law-nee'",
		"derivation" : "from H7956 (שֵׁלָה)",
		"def" : "a Shelanite (collectively), or descendants of Shelah",
		"kjv" : "Shelanites"
	},
	"H8025" : {
		"lemma" : "שָׁלַף",
		"xlit" : "shâlaph",
		"pron" : "saw-laf'",
		"derivation" : "a primitive root",
		"def" : "to pull out, up or off",
		"kjv" : "draw (off), grow up, pluck off"
	},
	"H8026" : {
		"lemma" : "שֶׁלֶף",
		"xlit" : "sheleph",
		"pron" : "sheh'-lef",
		"derivation" : "from H8025 (שָׁלַף); extract",
		"def" : "Sheleph, a son of Jokthan",
		"kjv" : "Sheleph"
	},
	"H8027" : {
		"lemma" : "שָׁלַשׁ",
		"xlit" : "shâlash",
		"pron" : "shaw-lash'",
		"derivation" : "a primitive root perhaps originally to intensify, i.e. treble; but apparently used only as denominative from H7969 (שָׁלוֹשׁ)",
		"def" : "to be (causatively, make) triplicate (by restoration, in portions, strands, days or years)",
		"kjv" : "do the third time, (divide into, stay) three (days, -fold, parts, years old)"
	},
	"H8028" : {
		"lemma" : "שֶׁלֶשׁ",
		"xlit" : "Shelesh",
		"pron" : "sheh'-lesh",
		"derivation" : "from H8027 (שָׁלַשׁ); triplet",
		"def" : "Shelesh, an Israelite",
		"kjv" : "Shelesh"
	},
	"H8029" : {
		"lemma" : "שִׁלֵּשׁ",
		"xlit" : "shillêsh",
		"pron" : "shil-laysh'",
		"derivation" : "from H8027 (שָׁלַשׁ)",
		"def" : "a descendant of the third degree, i.e. great grandchild",
		"kjv" : "third (generation)"
	},
	"H8030" : {
		"lemma" : "שִׁלְשָׁה",
		"xlit" : "Shilshâh",
		"pron" : "shil-shaw'",
		"derivation" : "feminine from the same as H8028 (שֶׁלֶשׁ); triplication",
		"def" : "Shilshah, an Israelite",
		"kjv" : "Shilshah"
	},
	"H8031" : {
		"lemma" : "שָׁלִשָׁה",
		"xlit" : "Shâlishâh",
		"pron" : "shaw-lee-shaw'",
		"derivation" : "feminine from H8027 (שָׁלַשׁ); trebled land",
		"def" : "Shalishah, a place in Palestine",
		"kjv" : "Shalisha"
	},
	"H8032" : {
		"lemma" : "שִׁלְשׁוֹם",
		"xlit" : "shilshôwm",
		"pron" : "shil-shome'",
		"derivation" : "or שִׁלְשֹׁם; from the same as H8028 (שֶׁלֶשׁ)",
		"def" : "trebly, i.e. (in time) day before yesterday",
		"kjv" : "[phrase] before (that time, -time), excellent things (from the margin), [phrase] heretofore, three days, [phrase] time past"
	},
	"H8033" : {
		"lemma" : "שָׁם",
		"xlit" : "shâm",
		"pron" : "shawm",
		"derivation" : "a primitive particle (rather from the relative pronoun, H834 (אֲשֶׁר))",
		"def" : "there (transferring to time) then; often thither, or thence",
		"kjv" : "in it, [phrase] thence, there (-in, [phrase] of, [phrase] out), [phrase] thither, [phrase] whither"
	},
	"H8034" : {
		"lemma" : "שֵׁם",
		"xlit" : "shêm",
		"pron" : "shame",
		"derivation" : "a primitive word (perhaps rather from through the idea of definite and conspicuous position; compare H8064 (שָׁמַיִם))",
		"def" : "an appellation, as amark or memorial of individuality; by implication honor, authority, character",
		"kjv" : "[phrase] base, (in-) fame(-ous), named(-d), renown, report"
	},
	"H8035" : {
		"lemma" : "שֵׁם",
		"xlit" : "Shêm",
		"pron" : "shame",
		"derivation" : "the same as H8034 (שֵׁם); name",
		"def" : "Shem, a son of Noah (often includ. his posterity)",
		"kjv" : "Sem, Shem"
	},
	"H8036" : {
		"lemma" : "שֻׁם",
		"xlit" : "shum",
		"pron" : "shoom",
		"derivation" : "(Aramaic) corresponding to H8034 (שֵׁם)",
		"def" : "{an appellation, as amark or memorial of individuality; by implication honor, authority, character}",
		"kjv" : "name"
	},
	"H8037" : {
		"lemma" : "שַׁמָּא",
		"xlit" : "Shammâʼ",
		"pron" : "sham-maw'",
		"derivation" : "from H8074 (שָׁמֵם); desolation",
		"def" : "Shamma, an Israelite",
		"kjv" : "Shamma"
	},
	"H8038" : {
		"lemma" : "שֶׁמְאֵבֶר",
		"xlit" : "Shemʼêber",
		"pron" : "shem-ay'-ber",
		"derivation" : "apparently from H8034 (שֵׁם) and H83 (אֵבֶר); name of pinion, i.e. illustrious",
		"def" : "Shemeber, a king of Zeboim",
		"kjv" : "Shemeber"
	},
	"H8039" : {
		"lemma" : "שִׁמְאָה",
		"xlit" : "Shimʼâh",
		"pron" : "shim-aw'",
		"derivation" : "perhaps for H8093 (שִׁמְעָה)",
		"def" : "Shimah, an Israelite",
		"kjv" : "Shimah. Compare H8043 (שִׁמְאָם)"
	},
	"H8040" : {
		"lemma" : "שְׂמֹאול",
		"xlit" : "sᵉmôʼwl",
		"pron" : "sem-ole'",
		"derivation" : "or שְׂמֹאל; a primitive word (rather perhaps from the same as H8071 (שִׂמְלָה) (by insertion of the aleph) through the idea of wrapping up)",
		"def" : "properly, dark (as enveloped), i.e. the north; hence (by orientation), the left hand",
		"kjv" : "left (hand, side)"
	},
	"H8041" : {
		"lemma" : "שָׂמַאל",
		"xlit" : "sâmaʼl",
		"pron" : "saw-mal'",
		"derivation" : "a primitive root (denominative from H8040 (שְׂמֹאול))",
		"def" : "to use the left hand or pass in that direction)",
		"kjv" : "(go, turn) (on the, to the) left"
	},
	"H8042" : {
		"lemma" : "שְׂמָאלִי",
		"xlit" : "sᵉmâʼlîy",
		"pron" : "sem-aw-lee'",
		"derivation" : "from H8040 (שְׂמֹאול)",
		"def" : "situated on the left side",
		"kjv" : "left"
	},
	"H8043" : {
		"lemma" : "שִׁמְאָם",
		"xlit" : "Shimʼâm",
		"pron" : "shim-awm'",
		"derivation" : "for H8039 (שִׁמְאָה) (compare H38 (אֲבִיָּם))",
		"def" : "Shimam, an Israelite",
		"kjv" : "Shimeam"
	},
	"H8044" : {
		"lemma" : "שַׁמְגַּר",
		"xlit" : "Shamgar",
		"pron" : "sham-gar'",
		"derivation" : "of uncertain derivation",
		"def" : "Shamgar, an Israelite judge",
		"kjv" : "Shamgar"
	},
	"H8045" : {
		"lemma" : "שָׁמַד",
		"xlit" : "shâmad",
		"pron" : "shaw-mad'",
		"derivation" : "a primitive root",
		"def" : "to desolate",
		"kjv" : "destory(-uction), bring to nought, overthrow, perish, pluck down, [idiom] utterly"
	},
	"H8046" : {
		"lemma" : "שְׁמַד",
		"xlit" : "shᵉmad",
		"pron" : "shem-ad'",
		"derivation" : "(Aramaic) corresponding to H8045 (שָׁמַד)",
		"def" : "{to desolate}",
		"kjv" : "consume"
	},
	"H8047" : {
		"lemma" : "שַׁמָּה",
		"xlit" : "shammâh",
		"pron" : "sham-maw'",
		"derivation" : "from H8074 (שָׁמֵם)",
		"def" : "ruin; by implication, consternation",
		"kjv" : "astonishment, desolate(-ion), waste, wonderful thing"
	},
	"H8048" : {
		"lemma" : "שַׁמָּה",
		"xlit" : "Shammâh",
		"pron" : "sham-maw'",
		"derivation" : "the same as H8047 (שַׁמָּה)",
		"def" : "Shammah, the name of an Edomite and four Israelites",
		"kjv" : "Shammah"
	},
	"H8049" : {
		"lemma" : "שַׁמְהוּת",
		"xlit" : "Shamhûwth",
		"pron" : "sham-hooth'",
		"derivation" : "for H8048 (שַׁמָּה); desolation",
		"def" : "Shamhuth, an Israelite",
		"kjv" : "Shamhuth"
	},
	"H8050" : {
		"lemma" : "שְׁמוּאֵל",
		"xlit" : "Shᵉmûwʼêl",
		"pron" : "sehm-oo-ale'",
		"derivation" : "from the passive participle of H8085 (שָׁמַע) and H410 (אֵל); heard of God",
		"def" : "Shemuel, the name of three Israelites",
		"kjv" : "Samuel, Shemuel"
	},
	"H8051" : {
		"lemma" : "שַׁמּוּעַ",
		"xlit" : "Shammûwaʻ",
		"pron" : "sham-moo'-ah",
		"derivation" : "from H8074 (שָׁמֵם); renowned",
		"def" : "Shammua, the name of four Israelites",
		"kjv" : "Shammua, Shammuah"
	},
	"H8052" : {
		"lemma" : "שְׁמוּעָה",
		"xlit" : "shᵉmûwʻâh",
		"pron" : "sehm-oo-aw'",
		"derivation" : "feminine passive participle of H8074 (שָׁמֵם)",
		"def" : "something heard, i.e. an announcement",
		"kjv" : "bruit, doctrine, fame, mentioned, news, report, rumor, tidings"
	},
	"H8053" : {
		"lemma" : "שָׁמוּר",
		"xlit" : "Shâmûwr",
		"pron" : "shaw-moor'",
		"derivation" : "passive participle of H8103 (שִׁמְצָה); observed",
		"def" : "Shamur, an Israelite",
		"kjv" : "Shamir (from the margin)"
	},
	"H8054" : {
		"lemma" : "שַׁמּוֹת",
		"xlit" : "Shammôwth",
		"pron" : "sham-moth'",
		"derivation" : "plural of H8047 (שַׁמָּה); ruins",
		"def" : "Shammoth, an Israelite",
		"kjv" : "Shamoth"
	},
	"H8055" : {
		"lemma" : "שָׂמַח",
		"xlit" : "sâmach",
		"pron" : "saw-makh'",
		"derivation" : "a primitive root",
		"def" : "probably to brighten up, i.e. (figuratively) be (causatively, make) blithe or gleesome",
		"kjv" : "cheer up, be (make) glad, (have, make) joy(-ful), be (make) merry, (cause to, make to) rejoice, [idiom] very"
	},
	"H8056" : {
		"lemma" : "שָׂמֵחַ",
		"xlit" : "sâmêach",
		"pron" : "saw-may'-akh",
		"derivation" : "from H8055 (שָׂמַח)",
		"def" : "blithe or gleeful",
		"kjv" : "(be) glad, joyful, (making) merry((-hearted), -ily), rejoice(-ing)"
	},
	"H8057" : {
		"lemma" : "שִׂמְחָה",
		"xlit" : "simchâh",
		"pron" : "sim-khaw'",
		"derivation" : "from H8056 (שָׂמֵחַ)",
		"def" : "blithesomeness or glee, (religious or festival)",
		"kjv" : "[idiom] exceeding(-ly), gladness, joy(-fulness), mirth, pleasure, rejoice(-ing)"
	},
	"H8058" : {
		"lemma" : "שָׁמַט",
		"xlit" : "shâmaṭ",
		"pron" : "shaw-mat'",
		"derivation" : "a primitive root",
		"def" : "to fling down; incipiently to jostle; figuratively, to let alone, desist, remit",
		"kjv" : "discontinue, overthrow, release, let rest, shake, stumble, throw down"
	},
	"H8059" : {
		"lemma" : "שְׁמִטָּה",
		"xlit" : "shᵉmiṭṭâh",
		"pron" : "shem-it-taw'",
		"derivation" : "from H8058 (שָׁמַט)",
		"def" : "remission (of debt) or suspension of labor)",
		"kjv" : "release"
	},
	"H8060" : {
		"lemma" : "שַׁמַּי",
		"xlit" : "Shammay",
		"pron" : "sham-mah'-ee",
		"derivation" : "from H8073 (שַׁמְלַי); destructive",
		"def" : "Shammai, the name of three Israelites",
		"kjv" : "Shammai"
	},
	"H8061" : {
		"lemma" : "שְׁמִידָע",
		"xlit" : "Shᵉmîydâʻ",
		"pron" : "shem-ee-daw'",
		"derivation" : "apparently from H8034 (שֵׁם) and H3045 (יָדַע); name of knowing",
		"def" : "Shemida, an Israelite",
		"kjv" : "Shemida, Shemidah"
	},
	"H8062" : {
		"lemma" : "שְׁמִידָעִי",
		"xlit" : "Shᵉmîydâʻîy",
		"pron" : "shem-ee-daw-ee'",
		"derivation" : "patronymically from H8061 (שְׁמִידָע)",
		"def" : "a Shemidaite (collectively) or descendants of Shemida",
		"kjv" : "Shemidaites"
	},
	"H8063" : {
		"lemma" : "שְׂמִיכָה",
		"xlit" : "sᵉmîykâh",
		"pron" : "sem-ee-kaw'",
		"derivation" : "from H5564 (סָמַךְ)",
		"def" : "a run (as sustaining the Oriental sitter)",
		"kjv" : "mantle"
	},
	"H8064" : {
		"lemma" : "שָׁמַיִם",
		"xlit" : "shâmayim",
		"pron" : "shaw-mah'-yim",
		"derivation" : "dual of an unused singular שָׁמֶה; from an unused root meaning to be lofty",
		"def" : "the sky (as aloft; the dual perhaps alluding to the visible arch in which the clouds move, as well as to the higher ether where the celestial bodies revolve)",
		"kjv" : "air, [idiom] astrologer, heaven(-s)"
	},
	"H8065" : {
		"lemma" : "שָׁמַיִן",
		"xlit" : "shâmayin",
		"pron" : "shaw-mah'-yin",
		"derivation" : "(Aramaic) corresponding to H8064 (שָׁמַיִם)",
		"def" : "{the sky (as aloft; the dual perhaps alluding to the visible arch in which the clouds move, as well as to the higher ether where the celestial bodies revolve)}",
		"kjv" : "heaven"
	},
	"H8066" : {
		"lemma" : "שְׁמִינִי",
		"xlit" : "shᵉmîynîy",
		"pron" : "shem-ee-nee'",
		"derivation" : "from H8083 (שְׁמֹנֶה)",
		"def" : "eight",
		"kjv" : "eight"
	},
	"H8067" : {
		"lemma" : "שְׁמִינִית",
		"xlit" : "shᵉmîynîyth",
		"pron" : "shem-ee-neeth'",
		"derivation" : "feminine of H8066 (שְׁמִינִי)",
		"def" : "probably an eight-stringed lyre",
		"kjv" : "Sheminith"
	},
	"H8068" : {
		"lemma" : "שָׁמִיר",
		"xlit" : "shâmîyr",
		"pron" : "shaw-meer'",
		"derivation" : "from H8104 (שָׁמַר) in the original sense of pricking",
		"def" : "a thorn; also (from its keenness for scratching) a gem, probably the diamond",
		"kjv" : "adamant (stone), brier, diamond"
	},
	"H8069" : {
		"lemma" : "שָׁמִיר",
		"xlit" : "Shâmîyr",
		"pron" : "shaw-meer'",
		"derivation" : "the same as H8068 (שָׁמִיר)",
		"def" : "Shamir, the name of two places in Palestine",
		"kjv" : "Shamir. Compare H8053 (שָׁמוּר)"
	},
	"H8070" : {
		"lemma" : "שְׁמִירָמוֹת",
		"xlit" : "Shᵉmîyrâmôwth",
		"pron" : "shem-ee-raw-moth'",
		"derivation" : "or שְׁמָרִימוֹת; probably from H8034 (שֵׁם) and plural of H7413 (רָמָה); name of heights",
		"def" : "Shemiramoth, the name of two Israelites",
		"kjv" : "Shemiramoth"
	},
	"H8071" : {
		"lemma" : "שִׂמְלָה",
		"xlit" : "simlâh",
		"pron" : "sim-law'",
		"derivation" : "perhaps by permutation for the feminine of H5566 (סֶמֶל) (through the idea of a cover assuming the shape of the object beneath)",
		"def" : "a dress, especially a mantle",
		"kjv" : "apparel, cloth(-es, -ing), garment, raiment. Compare H8008 (שַׂלְמָה)"
	},
	"H8072" : {
		"lemma" : "שַׂמְלָה",
		"xlit" : "Samlâh",
		"pron" : "sam-law'",
		"derivation" : "probably for the same as H8071 (שִׂמְלָה)",
		"def" : "Samlah, an Edomite",
		"kjv" : "Samlah"
	},
	"H8073" : {
		"lemma" : "שַׁמְלַי",
		"xlit" : "Shamlay",
		"pron" : "sham-lah'-ee",
		"derivation" : "for H8014 (שַׂלְמַי)",
		"def" : "Shamlai, one of the Nethinim",
		"kjv" : "Shalmai (from the margin)"
	},
	"H8074" : {
		"lemma" : "שָׁמֵם",
		"xlit" : "shâmêm",
		"pron" : "shaw-mame'",
		"derivation" : "a primitive root",
		"def" : "to stun (or intransitively, grow numb), i.e. devastate or (figuratively) stupefy (both usually in a passive sense)",
		"kjv" : "make amazed, be astonied, (be an) astonish(-ment), (be, bring into, unto, lay, lie, make) desolate(-ion, places), be destitute, destroy (self), (lay, lie, make) waste, wonder"
	},
	"H8075" : {
		"lemma" : "שְׁמַם",
		"xlit" : "shᵉmam",
		"pron" : "shem-am'",
		"derivation" : "(Aramaic) corresponding to H8074 (שָׁמֵם)",
		"def" : "{to stun (or intransitively, grow numb), i.e. devastate or (figuratively) stupefy (both usually in a passive sense)}",
		"kjv" : "be astonied"
	},
	"H8076" : {
		"lemma" : "שָׁמֵם",
		"xlit" : "shâmêm",
		"pron" : "shaw-mame'",
		"derivation" : "from H8074 (שָׁמֵם)",
		"def" : "ruined",
		"kjv" : "desolate"
	},
	"H8077" : {
		"lemma" : "שְׁמָמָה",
		"xlit" : "shᵉmâmâh",
		"pron" : "shem-aw-maw'",
		"derivation" : "or שִׁמָמָה; feminine of H8076 (שָׁמֵם)",
		"def" : "devastation; figuratively, astonishment",
		"kjv" : "(laid, [idiom] most) desolate(-ion), waste"
	},
	"H8078" : {
		"lemma" : "שִׁמָּמוֹן",
		"xlit" : "shimmâmôwn",
		"pron" : "shim-maw-mone'",
		"derivation" : "from H8074 (שָׁמֵם)",
		"def" : "stupefaction",
		"kjv" : "astonishment"
	},
	"H8079" : {
		"lemma" : "שְׂמָמִית",
		"xlit" : "sᵉmâmîyth",
		"pron" : "sem-aw-meeth'",
		"derivation" : "probably from H8074 (שָׁמֵם) (in the sense of poisoning)",
		"def" : "a lizard (from the superstition of its noxiousness)",
		"kjv" : "spider"
	},
	"H8080" : {
		"lemma" : "שָׁמַן",
		"xlit" : "shâman",
		"pron" : "shaw-man'",
		"derivation" : "a primitive root",
		"def" : "to shine, i.e. (by analogy) be (causatively, make) oily or gross",
		"kjv" : "become (make, wax) fat"
	},
	"H8081" : {
		"lemma" : "שֶׁמֶן",
		"xlit" : "shemen",
		"pron" : "sheh'-men",
		"derivation" : "from H8080 (שָׁמַן)",
		"def" : "grease, especially liquid (as from the olive, often perfumed); figuratively, richness",
		"kjv" : "anointing, [idiom] fat (things), [idiom] fruitful, oil(-ed), ointment, olive, [phrase] pine"
	},
	"H8082" : {
		"lemma" : "שָׁמֵן",
		"xlit" : "shâmên",
		"pron" : "shaw-mane'",
		"derivation" : "from H8080 (שָׁמַן)",
		"def" : "greasy, i.e. gross; figuratively, rich",
		"kjv" : "fat, lusty, plenteous"
	},
	"H8083" : {
		"lemma" : "שְׁמֹנֶה",
		"xlit" : "shᵉmôneh",
		"pron" : "shem-o-neh'",
		"derivation" : "or שְׁמוֹנֶה; feminine שְׁמֹנָה; or שְׁמוֹנָה; apparently from H8082 (שָׁמֵן) through the idea of plumpness",
		"def" : "a cardinal number, eight (as if a surplus above the 'perfect' seven); also (as ordinal) eighth",
		"kjv" : "eight(-een, -eenth), eighth"
	},
	"H8084" : {
		"lemma" : "שְׁמֹנִים",
		"xlit" : "shᵉmônîym",
		"pron" : "shem-o-neem'",
		"derivation" : "or שְׁמוֹנִים; multiple from H8083 (שְׁמֹנֶה)",
		"def" : "eighty, also eightieth",
		"kjv" : "eighty(-ieth), fourscore"
	},
	"H8085" : {
		"lemma" : "שָׁמַע",
		"xlit" : "shâmaʻ",
		"pron" : "shaw-mah'",
		"derivation" : "a primitive root",
		"def" : "to hear intelligently (often with implication of attention, obedience, etc.; causatively, to tell, etc.)",
		"kjv" : "[idiom] attentively, call (gather) together, [idiom] carefully, [idiom] certainly, consent, consider, be content, declare, [idiom] diligently, discern, give ear, (cause to, let, make to) hear(-ken, tell), [idiom] indeed, listen, make (a) noise, (be) obedient, obey, perceive, (make a) proclaim(-ation), publish, regard, report, shew (forth), (make a) sound, [idiom] surely, tell, understand, whosoever (heareth), witness"
	},
	"H8086" : {
		"lemma" : "שְׁמַע",
		"xlit" : "shᵉmaʻ",
		"pron" : "shem-ah'",
		"derivation" : "(Aramaic) corresponding to H8085 (שָׁמַע)",
		"def" : "{to hear intelligently (often with implication of attention, obedience, etc.; causatively, to tell, etc.)}",
		"kjv" : "hear, obey"
	},
	"H8087" : {
		"lemma" : "שֶׁמַע",
		"xlit" : "Shemaʻ",
		"pron" : "sheh'-mah",
		"derivation" : "for the same as H8088 (שֵׁמַע)",
		"def" : "Shema, the name of a place in Palestine and of four Israelites",
		"kjv" : "Shema"
	},
	"H8088" : {
		"lemma" : "שֵׁמַע",
		"xlit" : "shêmaʻ",
		"pron" : "shay'-mah",
		"derivation" : "from H8085 (שָׁמַע)",
		"def" : "something heard, i.e. a sound, rumor, announcement; abstractly, audience",
		"kjv" : "bruit, fame, hear(-ing), loud, report, speech, tidings"
	},
	"H8089" : {
		"lemma" : "שֹׁמַע",
		"xlit" : "shômaʻ",
		"pron" : "sho'-mah",
		"derivation" : "from H8085 (שָׁמַע)",
		"def" : "a report",
		"kjv" : "fame"
	},
	"H8090" : {
		"lemma" : "שְׁמָע",
		"xlit" : "Shᵉmâʻ",
		"pron" : "shem-aw'",
		"derivation" : "for H8087 (שֶׁמַע)",
		"def" : "Shema, a place in Palestine",
		"kjv" : "Shema"
	},
	"H8091" : {
		"lemma" : "שָׁמָע",
		"xlit" : "Shâmâʻ",
		"pron" : "shaw-maw'",
		"derivation" : "from H8085 (שָׁמַע); obedient",
		"def" : "Shama, an Israelite",
		"kjv" : "Shama"
	},
	"H8092" : {
		"lemma" : "שִׁמְעָא",
		"xlit" : "Shimʻâʼ",
		"pron" : "shim-aw'",
		"derivation" : "for H8093 (שִׁמְעָה)",
		"def" : "Shima, the name of four Israelites",
		"kjv" : "Shimea, Shimei, Shamma"
	},
	"H8093" : {
		"lemma" : "שִׁמְעָה",
		"xlit" : "Shimʻâh",
		"pron" : "shim-aw'",
		"derivation" : "feminine of H8088 (שֵׁמַע); annunciation",
		"def" : "Shimah, an Israelite",
		"kjv" : "Shimeah"
	},
	"H8094" : {
		"lemma" : "שְׁמָעָה",
		"xlit" : "Shᵉmâʻâh",
		"pron" : "shem-aw-aw'",
		"derivation" : "for H8093 (שִׁמְעָה)",
		"def" : "Shemaah, an Israelite",
		"kjv" : "Shemaah"
	},
	"H8095" : {
		"lemma" : "שִׁמְעוֹן",
		"xlit" : "Shimʻôwn",
		"pron" : "shim-one'",
		"derivation" : "from H8085 (שָׁמַע); hearing",
		"def" : "Shimon, one of Jacob's sons, also the tribe descended from him",
		"kjv" : "Simeon"
	},
	"H8096" : {
		"lemma" : "שִׁמְעִי",
		"xlit" : "Shimʻîy",
		"pron" : "shim-ee'",
		"derivation" : "from H8088 (שֵׁמַע); famous",
		"def" : "Shimi, the name of twenty Israelites",
		"kjv" : "Shimeah (from the margin), Shimei, Shimhi, Shimi"
	},
	"H8097" : {
		"lemma" : "שִׁמְעִי",
		"xlit" : "Shimʻîy",
		"pron" : "shim-ee'",
		"derivation" : "patronymically from H8096 (שִׁמְעִי)",
		"def" : "a Shimite (collectively) or descendants of Shimi",
		"kjv" : "of Shimi, Shimites"
	},
	"H8098" : {
		"lemma" : "שְׁמַעְיָה",
		"xlit" : "Shᵉmaʻyâh",
		"pron" : "shem-aw-yaw'",
		"derivation" : "or שְׁמַעְיָהוּ; from H8085 (שָׁמַע) and H3050 (יָהּ); Jah has heard",
		"def" : "Shemajah, the name of twenty-five Israelites",
		"kjv" : "Shemaiah"
	},
	"H8099" : {
		"lemma" : "שִׁמְעֹנִי",
		"xlit" : "Shimʻônîy",
		"pron" : "shim-o-nee'",
		"derivation" : "patronymically from H8095 (שִׁמְעוֹן)",
		"def" : "a Shimonite (collectively) or descendants of Shimon",
		"kjv" : "tribe of Simeon, Simeonites"
	},
	"H8100" : {
		"lemma" : "שִׁמְעַת",
		"xlit" : "Shimʻath",
		"pron" : "shim-awth'",
		"derivation" : "feminine of H8088 (שֵׁמַע); annunciation",
		"def" : "Shimath, an Ammonitess",
		"kjv" : "Shimath"
	},
	"H8101" : {
		"lemma" : "שִׁמְעָתִי",
		"xlit" : "Shimʻâthîy",
		"pron" : "shim-aw-thee'",
		"derivation" : "patronymically from H8093 (שִׁמְעָה)",
		"def" : "a Shimathite (collectively) or descendants of Shimah",
		"kjv" : "Shimeathites"
	},
	"H8102" : {
		"lemma" : "שֶׁמֶץ",
		"xlit" : "shemets",
		"pron" : "sheh'-mets",
		"derivation" : "from an unused root meaning to emit a sound",
		"def" : "an inkling",
		"kjv" : "a little"
	},
	"H8103" : {
		"lemma" : "שִׁמְצָה",
		"xlit" : "shimtsâh",
		"pron" : "shim-tsaw'",
		"derivation" : "feminine of H8102 (שֶׁמֶץ)",
		"def" : "scornful whispering (of hostile spectators)",
		"kjv" : "shame"
	},
	"H8104" : {
		"lemma" : "שָׁמַר",
		"xlit" : "shâmar",
		"pron" : "shaw-mar'",
		"derivation" : "a primitive root",
		"def" : "properly, to hedge about (as with thorns), i.e. guard; generally, to protect, attend to, etc.",
		"kjv" : "beward, be circumspect, take heed (to self), keep(-er, self), mark, look narrowly, observe, preserve, regard, reserve, save (self), sure, (that lay) wait (for), watch(-man)"
	},
	"H8105" : {
		"lemma" : "שֶׁמֶר",
		"xlit" : "shemer",
		"pron" : "sheh'-mer",
		"derivation" : "from H8104 (שָׁמַר)",
		"def" : "something preserved, i.e. the settlings (plural only) of wine",
		"kjv" : "dregs, (wines on the) lees"
	},
	"H8106" : {
		"lemma" : "שֶׁמֶר",
		"xlit" : "Shemer",
		"pron" : "sheh'-mer",
		"derivation" : "the same as H8105 (שֶׁמֶר)",
		"def" : "Shemer, the name of three Israelites",
		"kjv" : "Shamer, Shemer"
	},
	"H8107" : {
		"lemma" : "שִׁמֻּר",
		"xlit" : "shimmur",
		"pron" : "shim-moor'",
		"derivation" : "from H8104 (שָׁמַר)",
		"def" : "an observance",
		"kjv" : "[idiom] be (much) observed"
	},
	"H8108" : {
		"lemma" : "שׇׁמְרָה",
		"xlit" : "shomrâh",
		"pron" : "shom-raw'",
		"derivation" : "feminine of an unused noun from H8104 (שָׁמַר) meaning a guard",
		"def" : "watchfulness",
		"kjv" : "watch"
	},
	"H8109" : {
		"lemma" : "שְׁמֻרָה",
		"xlit" : "shᵉmurâh",
		"pron" : "shem-oo-raw'",
		"derivation" : "feminine of passive participle of H8104 (שָׁמַר)",
		"def" : "something guarded, i.e. an eye-lid",
		"kjv" : "waking"
	},
	"H8110" : {
		"lemma" : "שִׁמְרוֹן",
		"xlit" : "Shimrôwn",
		"pron" : "shim-rone'",
		"derivation" : "from H8105 (שֶׁמֶר) in its original sense; guardianship",
		"def" : "Shimron, the name of an Israelite and of a place in Palestine",
		"kjv" : "Shimron"
	},
	"H8111" : {
		"lemma" : "שֹׁמְרוֹן",
		"xlit" : "Shômᵉrôwn",
		"pron" : "sho-mer-one'",
		"derivation" : "from the active participle of H8104 (שָׁמַר); watch-station",
		"def" : "Shomeron, a place in Palestine",
		"kjv" : "Samaria"
	},
	"H8112" : {
		"lemma" : "שִׁמְרוֹן מְראוֹן",
		"xlit" : "Shimrôwn Mᵉrʼôwn",
		"pron" : "shim-rone' mer-one'",
		"derivation" : "from H8110 (שִׁמְרוֹן) and a derivative of H4754 (מָרָא); guard of lashing",
		"def" : "Shimron-Meron, a place in Palestine",
		"kjv" : "Shimon-meron"
	},
	"H8113" : {
		"lemma" : "שִׁמְרִי",
		"xlit" : "Shimrîy",
		"pron" : "shim-ree'",
		"derivation" : "from H8105 (שֶׁמֶר) in its original sense; watchful",
		"def" : "Shimri, the name of four Israelites",
		"kjv" : "Shimri"
	},
	"H8114" : {
		"lemma" : "שְׁמַרְיָה",
		"xlit" : "Shᵉmaryâh",
		"pron" : "shem-ar-yaw'",
		"derivation" : "or שְׁמַרְיָהוּ; from H8104 (שָׁמַר) and H3050 (יָהּ); Jah has guarded",
		"def" : "Shemarjah, the name of four Israelites",
		"kjv" : "Shamariah, Shemariah"
	},
	"H8115" : {
		"lemma" : "שׇׁמְרַיִן",
		"xlit" : "Shomrayin",
		"pron" : "shom-rah'-yin",
		"derivation" : "(Aramaic) corresponding to H8111 (שֹׁמְרוֹן)",
		"def" : "Shomrain, a place in Palestine",
		"kjv" : "Samaria"
	},
	"H8116" : {
		"lemma" : "שִׁמְרִית",
		"xlit" : "Shimrîyth",
		"pron" : "shim-reeth'",
		"derivation" : "feminine of H8113 (שִׁמְרִי); female guard",
		"def" : "Shimrith, a Moabitess",
		"kjv" : "Shimrith"
	},
	"H8117" : {
		"lemma" : "שִׁמְרֹנִי",
		"xlit" : "Shimrônîy",
		"pron" : "shim-ro-nee'",
		"derivation" : "patronymically from H8110 (שִׁמְרוֹן)",
		"def" : "a Shimronite (collectively) or descendants of Shimron",
		"kjv" : "Shimronites"
	},
	"H8118" : {
		"lemma" : "שֹׁמרֹנִי",
		"xlit" : "Shômrônîy",
		"pron" : "sho-mer-o-nee'",
		"derivation" : "patrial from H8111 (שֹׁמְרוֹן)",
		"def" : "a Shomeronite (collectively) or inhabitants of Shomeron",
		"kjv" : "Samaritans"
	},
	"H8119" : {
		"lemma" : "שִׁמְרָת",
		"xlit" : "Shimrâth",
		"pron" : "shim-rawth'",
		"derivation" : "from H8104 (שָׁמַר); guardship",
		"def" : "Shimrath, an Israelite",
		"kjv" : "Shimrath"
	},
	"H8120" : {
		"lemma" : "שְׁמַשׁ",
		"xlit" : "shᵉmash",
		"pron" : "shem-ash'",
		"derivation" : "(Aramaic) corresponding to the root of H8121 (שֶׁמֶשׁ) through the idea of activity implied in day-light",
		"def" : "to serve",
		"kjv" : "minister"
	},
	"H8121" : {
		"lemma" : "שֶׁמֶשׁ",
		"xlit" : "shemesh",
		"pron" : "sheh'-mesh",
		"derivation" : "from an unused root meaning to be brilliant",
		"def" : "the sun; by implication, the east; figuratively, a ray, i.e. (architectural) a notched battlement",
		"kjv" : "[phrase] east side(-ward), sun (rising), [phrase] west(-ward), window. See also H1053 (בֵּית שֶׁמֶשׁ)"
	},
	"H8122" : {
		"lemma" : "שֶׁמֶשׁ",
		"xlit" : "shemesh",
		"pron" : "sheh'-mesh",
		"derivation" : "(Aramaic) corresponding to H8121 (שֶׁמֶשׁ)",
		"def" : "the sun",
		"kjv" : "sun"
	},
	"H8123" : {
		"lemma" : "שִׁמְשׁוֹן",
		"xlit" : "Shimshôwn",
		"pron" : "shim-shone'",
		"derivation" : "from H8121 (שֶׁמֶשׁ); sunlight",
		"def" : "Shimshon, an Israelite",
		"kjv" : "Samson"
	},
	"H8124" : {
		"lemma" : "שִׁמְשַׁי",
		"xlit" : "Shimshay",
		"pron" : "shim-shah'-ee",
		"derivation" : "(Aramaic) from H8122 (שֶׁמֶשׁ); sunny",
		"def" : "Shimshai, a Samaritan",
		"kjv" : "Shimshai"
	},
	"H8125" : {
		"lemma" : "שַׁמְשְׁרַי",
		"xlit" : "Shamshᵉray",
		"pron" : "sham-sher-ah'-ee",
		"derivation" : "apparently from H8121 (שֶׁמֶשׁ); sunlike",
		"def" : "Shamsherai, an Israelite",
		"kjv" : "Shamsherai"
	},
	"H8126" : {
		"lemma" : "שֻׁמָתִי",
		"xlit" : "Shumâthîy",
		"pron" : "shoo-maw-thee'",
		"derivation" : "patronymically from an unused name from H7762 (שׁוּם) probably meaning garlic-smell",
		"def" : "a Shumathite (collectively) or descendants of Shumah",
		"kjv" : "Shumathites"
	},
	"H8127" : {
		"lemma" : "שֵׁן",
		"xlit" : "shên",
		"pron" : "shane",
		"derivation" : "from H8150 (שָׁנַן); compare  H8143 (שֶׁנְהַבִּים)",
		"def" : "a tooth (as sharp); specifically  ivory; figuratively, a cliff",
		"kjv" : "crag, [idiom] forefront, ivory, [idiom] sharp, tooth"
	},
	"H8128" : {
		"lemma" : "שֵׁן",
		"xlit" : "shên",
		"pron" : "shane",
		"derivation" : "(Aramaic) corresponding to H8127 (שֵׁן)",
		"def" : "a tooth",
		"kjv" : "tooth"
	},
	"H8129" : {
		"lemma" : "שֵׁן",
		"xlit" : "Shên",
		"pron" : "shane",
		"derivation" : "the same as H8127 (שֵׁן); crag",
		"def" : "Shen, a place in Palestine",
		"kjv" : "Shen"
	},
	"H8130" : {
		"lemma" : "שָׂנֵא",
		"xlit" : "sânêʼ",
		"pron" : "saw-nay'",
		"derivation" : "a primitive root",
		"def" : "to hate (personally)",
		"kjv" : "enemy, foe, (be) hate(-ful, -r), odious, [idiom] utterly"
	},
	"H8131" : {
		"lemma" : "שְׂנֵא",
		"xlit" : "sᵉnêʼ",
		"pron" : "sen-ay'",
		"derivation" : "(Aramaic) corresponding to H8130 (שָׂנֵא)",
		"def" : "{to hate (personally)}",
		"kjv" : "hate"
	},
	"H8132" : {
		"lemma" : "שָׁנָא",
		"xlit" : "shânâʼ",
		"pron" : "shaw-naw'",
		"derivation" : "a primitive root",
		"def" : "to alter",
		"kjv" : "change"
	},
	"H8133" : {
		"lemma" : "שְׁנָא",
		"xlit" : "shᵉnâʼ",
		"pron" : "shen-aw'",
		"derivation" : "(Aramaic) corresponding to H8132 (שָׁנָא)",
		"def" : "{to alter}",
		"kjv" : "alter, change, (be) diverse"
	},
	"H8134" : {
		"lemma" : "שִׁנְאָב",
		"xlit" : "Shinʼâb",
		"pron" : "shin-awb'",
		"derivation" : "probably from H8132 (שָׁנָא) and H1 (אָב); a father has turned",
		"def" : "Shinab, a Canaanite",
		"kjv" : "Shinab"
	},
	"H8135" : {
		"lemma" : "שִׂנְאָה",
		"xlit" : "sinʼâh",
		"pron" : "sin-aw'",
		"derivation" : "from H8130 (שָׂנֵא)",
		"def" : "hate",
		"kjv" : "[phrase] exceedingly, hate(-ful, -red)"
	},
	"H8136" : {
		"lemma" : "שִׁנְאָן",
		"xlit" : "shinʼân",
		"pron" : "shin-awn'",
		"derivation" : "from H8132 (שָׁנָא)",
		"def" : "change, i.e. repetition",
		"kjv" : "[idiom] angels"
	},
	"H8137" : {
		"lemma" : "שֶׁנְאַצַּר",
		"xlit" : "Shenʼatstsar",
		"pron" : "shen-ats-tsar'",
		"derivation" : "apparently of Babylonian origin",
		"def" : "Shenatstsar, an Israelite",
		"kjv" : "Senazar"
	},
	"H8138" : {
		"lemma" : "שָׁנָה",
		"xlit" : "shânâh",
		"pron" : "shaw-naw'",
		"derivation" : "a primitive root",
		"def" : "to fold, i.e. duplicate (literally or figuratively); by implication, to transmute (transitive or intransitive)",
		"kjv" : "do (speak, strike) again, alter, double, (be given to) change, disguise, (be) diverse, pervert, prefer, repeat, return, do the second time"
	},
	"H8139" : {
		"lemma" : "שְׁנָה",
		"xlit" : "shᵉnâh",
		"pron" : "shen-aw'",
		"derivation" : "(Aramaic) corresponding to H8142 (שֵׁנָה)",
		"def" : "{sleep}",
		"kjv" : "sleep"
	},
	"H8140" : {
		"lemma" : "שְׁנָה",
		"xlit" : "shᵉnâh",
		"pron" : "shen-aw'",
		"derivation" : "(Aramaic) corresponding to H8141 (שָׁנֶה)",
		"def" : "{a year (as a revolution of time)}",
		"kjv" : "year"
	},
	"H8141" : {
		"lemma" : "שָׁנֶה",
		"xlit" : "shâneh",
		"pron" : "shaw-neh'",
		"derivation" : "(in plural or (feminine) שָׁנָה; from H8138 (שָׁנָה)",
		"def" : "a year (as a revolution of time)",
		"kjv" : "[phrase] whole age, [idiom] long, [phrase] old, year([idiom] -ly)"
	},
	"H8142" : {
		"lemma" : "שֵׁנָה",
		"xlit" : "shênâh",
		"pron" : "shay-naw'",
		"derivation" : "or שֵׁנָא; (Psalm 127:2), from H3462 (יָשֵׁן)",
		"def" : "sleep",
		"kjv" : "sleep"
	},
	"H8143" : {
		"lemma" : "שֶׁנְהַבִּים",
		"xlit" : "shenhabbîym",
		"pron" : "shen-hab-beem'",
		"derivation" : "from H8127 (שֵׁן) and the plural apparently of a foreign word",
		"def" : "probably, tooth of elephants, i.e. ivory tusk",
		"kjv" : "ivory"
	},
	"H8144" : {
		"lemma" : "שָׁנִי",
		"xlit" : "shânîy",
		"pron" : "shaw-nee'",
		"derivation" : "of uncertain derivation",
		"def" : "crimson, properly, the insect or its color, also stuff dyed with it",
		"kjv" : "crimson, scarlet (thread)"
	},
	"H8145" : {
		"lemma" : "שֵׁנִי",
		"xlit" : "shênîy",
		"pron" : "shay-nee'",
		"derivation" : "from H8138 (שָׁנָה)",
		"def" : "properly, double, i.e. second; also adverbially, again",
		"kjv" : "again, either (of them), (an-) other, second (time)"
	},
	"H8146" : {
		"lemma" : "שָׂנִיא",
		"xlit" : "sânîyʼ",
		"pron" : "saw-nee'",
		"derivation" : "from H8130 (שָׂנֵא)",
		"def" : "hated",
		"kjv" : "hated"
	},
	"H8147" : {
		"lemma" : "שְׁנַיִם",
		"xlit" : "shᵉnayim",
		"pron" : "shen-ah'-yim",
		"derivation" : "dual of H8145 (שֵׁנִי); feminine שְׁתַּיִם",
		"def" : "two; also (as ordinal) twofold",
		"kjv" : "both, couple, double, second, twain, [phrase] twelfth, [phrase] twelve, [phrase] twenty (sixscore) thousand, twice, two"
	},
	"H8148" : {
		"lemma" : "שְׁנִינָה",
		"xlit" : "shᵉnîynâh",
		"pron" : "shen-ee-naw'",
		"derivation" : "from H8150 (שָׁנַן)",
		"def" : "something pointed, i.e. a gibe",
		"kjv" : "byword, taunt"
	},
	"H8149" : {
		"lemma" : "שְׁנִיר",
		"xlit" : "Shᵉnîyr",
		"pron" : "shen-eer'",
		"derivation" : "or שְׂנִיר; from an unused root meaning to be pointed; peak",
		"def" : "Shenir or Senir, a summit of Lebanon",
		"kjv" : "Senir, Shenir"
	},
	"H8150" : {
		"lemma" : "שָׁנַן",
		"xlit" : "shânan",
		"pron" : "shaw-nan'",
		"derivation" : "a primitive root",
		"def" : "to point (transitive or intransitive); intensively, to pierce; figuratively, to inculcate",
		"kjv" : "prick, sharp(-en), teach diligently, whet"
	},
	"H8151" : {
		"lemma" : "שָׁנַס",
		"xlit" : "shânaç",
		"pron" : "shaw-nas'",
		"derivation" : "a primitive root",
		"def" : "to compress (with a belt)",
		"kjv" : "gird up"
	},
	"H8152" : {
		"lemma" : "שִׁנְעָר",
		"xlit" : "Shinʻâr",
		"pron" : "shin-awr'",
		"derivation" : "probably of foreign derivation",
		"def" : "Shinar, a plain in Babylonia",
		"kjv" : "Shinar"
	},
	"H8153" : {
		"lemma" : "שְׁנָת",
		"xlit" : "shᵉnâth",
		"pron" : "shen-awth'",
		"derivation" : "from H3462 (יָשֵׁן)",
		"def" : "sleep",
		"kjv" : "sleep"
	},
	"H8154" : {
		"lemma" : "שָׁסָה",
		"xlit" : "shâçâh",
		"pron" : "shaw-saw'",
		"derivation" : "or שָׁשָׂה; (Isaiah 10:13), a primitive root",
		"def" : "to plunder",
		"kjv" : "destroyer, rob, spoil(-er)"
	},
	"H8155" : {
		"lemma" : "שָׁסַס",
		"xlit" : "shâçaç",
		"pron" : "shaw-sas'",
		"derivation" : "a primitive root",
		"def" : "to plunder",
		"kjv" : "rifle, spoil"
	},
	"H8156" : {
		"lemma" : "שָׁסַע",
		"xlit" : "shâçaʻ",
		"pron" : "shaw-sah'",
		"derivation" : "a primitive root",
		"def" : "to split or tear; figuratively, to upbraid",
		"kjv" : "cleave, (be) cloven (footed), rend, stay"
	},
	"H8157" : {
		"lemma" : "שֶׁסַע",
		"xlit" : "sheçaʻ",
		"pron" : "sheh'-sah",
		"derivation" : "from H8156 (שָׁסַע)",
		"def" : "a fissure",
		"kjv" : "cleft, clovenfooted"
	},
	"H8158" : {
		"lemma" : "שָׁסַף",
		"xlit" : "shâçaph",
		"pron" : "shaw-saf'",
		"derivation" : "a primitive root",
		"def" : "to cut in pieces, i.e. slaughter",
		"kjv" : "hew in pieces"
	},
	"H8159" : {
		"lemma" : "שָׁעָה",
		"xlit" : "shâʻâh",
		"pron" : "shaw-aw'",
		"derivation" : "a primitive root",
		"def" : "to gaze at or about (properly, for help); by implication, to inspect, consider, compassionate, be nonplussed (as looking around in amazement) or bewildered",
		"kjv" : "depart, be dim, be dismayed, look (away), regard, have respect, spare, turn"
	},
	"H8160" : {
		"lemma" : "שָׁעָה",
		"xlit" : "shâʻâh",
		"pron" : "shaw-aw'",
		"derivation" : "(Aramaic) from a root corresponding to H8159 (שָׁעָה)",
		"def" : "properly, a look, i.e. a moment",
		"kjv" : "hour"
	},
	"H8161" : {
		"lemma" : "שַׁעֲטָה",
		"xlit" : "shaʻăṭâh",
		"pron" : "shah'-at-aw",
		"derivation" : "feminine from an unused root meaning to stamp",
		"def" : "a clatter (of hoofs)",
		"kjv" : "stamping"
	},
	"H8162" : {
		"lemma" : "שַׁעַטְנֵז",
		"xlit" : "shaʻaṭnêz",
		"pron" : "shah-at-naze'",
		"derivation" : "probably of foreign derivation",
		"def" : "linsey-woolsey, i.e. cloth of linen and wool carded and spun together",
		"kjv" : "garment of divers sorts, linen and wollen"
	},
	"H8163" : {
		"lemma" : "שָׂעִיר",
		"xlit" : "sâʻîyr",
		"pron" : "saw-eer'",
		"derivation" : "or שָׂעִר; from H8175 (שָׂעַר)",
		"def" : "shaggy; as noun, a he-goat; by analogy, a faun",
		"kjv" : "devil, goat, hairy, kid, rough, satyr"
	},
	"H8164" : {
		"lemma" : "שָׂעִיר",
		"xlit" : "sâʻîyr",
		"pron" : "saw-eer'",
		"derivation" : "formed the same as H8163 (שָׂעִיר)",
		"def" : "a shower (as tempestuous)",
		"kjv" : "small rain"
	},
	"H8165" : {
		"lemma" : "שֵׂעִיר",
		"xlit" : "Sêʻîyr",
		"pron" : "say-eer'",
		"derivation" : "formed like H8163 (שָׂעִיר); rough",
		"def" : "Seir, a mountain of Idumaea and its aboriginal occupants, also one in Palestine",
		"kjv" : "Seir"
	},
	"H8166" : {
		"lemma" : "שְׂעִירָה",
		"xlit" : "sᵉʻîyrâh",
		"pron" : "seh-ee-raw'",
		"derivation" : "feminine of H8163 (שָׂעִיר)",
		"def" : "a she-goat",
		"kjv" : "kid"
	},
	"H8167" : {
		"lemma" : "שְׂעִירָה",
		"xlit" : "Sᵉʻîyrâh",
		"pron" : "seh-ee-raw'",
		"derivation" : "formed as H8166 (שְׂעִירָה); roughness",
		"def" : "Seirah, a place in Palestine",
		"kjv" : "Seirath"
	},
	"H8168" : {
		"lemma" : "שֹׁעַל",
		"xlit" : "shôʻal",
		"pron" : "sho'-al",
		"derivation" : "from an unused root meaning to hollow out",
		"def" : "the palm; by extension, a handful",
		"kjv" : "handful, hollow of the hand"
	},
	"H8169" : {
		"lemma" : "שַׁעַלְבִים",
		"xlit" : "Shaʻalbîym",
		"pron" : "shah-al-beem'",
		"derivation" : "or שַׁעֲלַבִּין; plural from H7776 (שׁוּעָל); fox-holes",
		"def" : "Shaalbim or Shaalabbin, a place in Palestine",
		"kjv" : "Shaalabbin, Shaalbim"
	},
	"H8170" : {
		"lemma" : "שַׁעַלְבֹנִי",
		"xlit" : "Shaʻalbônîy",
		"pron" : "shah-al-bo-nee'",
		"derivation" : "patrial from H8169 (שַׁעַלְבִים)",
		"def" : "a Shaalbonite or inhabitant of Shaalbin",
		"kjv" : "Shaalbonite"
	},
	"H8171" : {
		"lemma" : "שַׁעֲלִים",
		"xlit" : "Shaʻălîym",
		"pron" : "shah-al-eem'",
		"derivation" : "plural of H7776 (שׁוּעָל); foxes",
		"def" : "Shaalim, a place in Palestine",
		"kjv" : "Shalim"
	},
	"H8172" : {
		"lemma" : "שָׁעַן",
		"xlit" : "shâʻan",
		"pron" : "shaw-an'",
		"derivation" : "a primitive root",
		"def" : "to support one's self",
		"kjv" : "lean, lie, rely, rest (on, self), stay"
	},
	"H8173" : {
		"lemma" : "שָׁעַע",
		"xlit" : "shâʻaʻ",
		"pron" : "shaw-ah'",
		"derivation" : "a primitive root",
		"def" : "(in a good acceptation) to look upon (with complacency), i.e. fondle, please or amuse (self); (in a bad one) to look about (in dismay), i.e. stare",
		"kjv" : "cry (out) (by confusion with H7768 (שָׁוַע)), dandle, delight (self), play, shut"
	},
	"H8174" : {
		"lemma" : "שַׁעַף",
		"xlit" : "Shaʻaph",
		"pron" : "shah'-af",
		"derivation" : "from H5586 (סָעַף); fluctuation",
		"def" : "Shaaph, the name of two Israelites",
		"kjv" : "Shaaph"
	},
	"H8175" : {
		"lemma" : "שָׂעַר",
		"xlit" : "sâʻar",
		"pron" : "saw-ar'",
		"derivation" : "a primitive root",
		"def" : "to storm; by implication, to shiver, i.e. fear",
		"kjv" : "be (horribly) afraid, fear, hurl as a storm, be tempestuous, come like (take away as with) a whirlwind"
	},
	"H8176" : {
		"lemma" : "שָׁעַר",
		"xlit" : "shâʻar",
		"pron" : "shaw-ar'",
		"derivation" : "a primitive root; to split or open, i.e. (literally, but only as denominative from H8179 (שַׁעַר)); compare see H7778 (שׁוֹעֵר)",
		"def" : "to act as gatekeeper; (figuratively) to estimate",
		"kjv" : "think"
	},
	"H8177" : {
		"lemma" : "שְׂעַר",
		"xlit" : "sᵉʻar",
		"pron" : "seh-ar'",
		"derivation" : "(Aramaic) corresponding to H8181 (שֵׂעָר)",
		"def" : "hair",
		"kjv" : "hair"
	},
	"H8178" : {
		"lemma" : "שַׂעַר",
		"xlit" : "saʻar",
		"pron" : "sah'-ar",
		"derivation" : "from H8175 (שָׂעַר)",
		"def" : "a tempest; also a terror",
		"kjv" : "affrighted, [idiom] horribly, [idiom] sore, storm. See H8181 (שֵׂעָר)"
	},
	"H8179" : {
		"lemma" : "שַׁעַר",
		"xlit" : "shaʻar",
		"pron" : "shah'-ar",
		"derivation" : "from H8176 (שָׁעַר) in its original sense",
		"def" : "an opening, i.e. door or gate",
		"kjv" : "city, door, gate, port ([idiom] -er)"
	},
	"H8180" : {
		"lemma" : "שַׁעַר",
		"xlit" : "shaʻar",
		"pron" : "shah'-ar",
		"derivation" : "from H8176 (שָׁעַר)",
		"def" : "a measure (as a section)",
		"kjv" : "(hundred-) fold"
	},
	"H8181" : {
		"lemma" : "שֵׂעָר",
		"xlit" : "sêʻâr",
		"pron" : "say-awr'",
		"derivation" : "or שַׂעַר; (Isaiah 7:20), from H8175 (שָׂעַר) in the sense of dishevelling",
		"def" : "hair (as if tossed or bristling)",
		"kjv" : "hair(-y), [idiom] rough"
	},
	"H8182" : {
		"lemma" : "שֹׁעָר",
		"xlit" : "shôʻâr",
		"pron" : "sho-awr'",
		"derivation" : "from H8176 (שָׁעַר)",
		"def" : "harsh or horrid, i.e. offensive",
		"kjv" : "vile"
	},
	"H8183" : {
		"lemma" : "שְׂעָרָה",
		"xlit" : "sᵉʻârâh",
		"pron" : "seh-aw-raw'",
		"derivation" : "feminine of H8178 (שַׂעַר)",
		"def" : "a hurricane",
		"kjv" : "storm, tempest"
	},
	"H8184" : {
		"lemma" : "שְׂעֹרָה",
		"xlit" : "sᵉʻôrâh",
		"pron" : "seh-o-raw'",
		"derivation" : "or שְׂעוֹרָה (feminine meaning the plant); and (masculine meaning the grain); also שְׂעֹר; or שְׂעוֹר; from H8175 (שָׂעַר) in the sense of roughness",
		"def" : "barley (as villose)",
		"kjv" : "barley"
	},
	"H8185" : {
		"lemma" : "שַׂעֲרָה",
		"xlit" : "saʻărâh",
		"pron" : "sah-ar-aw'",
		"derivation" : "feminine of H8181 (שֵׂעָר)",
		"def" : "hairiness",
		"kjv" : "hair"
	},
	"H8186" : {
		"lemma" : "שַׁעֲרוּרָה",
		"xlit" : "shaʻărûwrâh",
		"pron" : "shah-ar-oo-raw'",
		"derivation" : "or שַׁעֲרִירִיָּה; or שַׁעֲרֻרִת; feminine from H8176 (שָׁעַר) in the sense of H8175 (שָׂעַר)",
		"def" : "something fearful",
		"kjv" : "horrible thing"
	},
	"H8187" : {
		"lemma" : "שְׁעַרְיָה",
		"xlit" : "Shᵉʻaryâh",
		"pron" : "sheh-ar-yaw'",
		"derivation" : "from H8176 (שָׁעַר) and H3050 (יָהּ); Jah has stormed",
		"def" : "Shearjah, an Israelite",
		"kjv" : "Sheariah"
	},
	"H8188" : {
		"lemma" : "שְׂעֹרִים",
		"xlit" : "Sᵉʻôrîym",
		"pron" : "seh-o-reem'",
		"derivation" : "masculine plural of H8184 (שְׂעֹרָה); barley grains",
		"def" : "Seorim, an Israelite",
		"kjv" : "Seorim"
	},
	"H8189" : {
		"lemma" : "שַׁעֲרַיִם",
		"xlit" : "Shaʻărayim",
		"pron" : "shah-ar-ah'-yim",
		"derivation" : "dual of H8179 (שַׁעַר); double gates",
		"def" : "Shaarajim, a place in Palestine",
		"kjv" : "Shaaraim"
	},
	"H8190" : {
		"lemma" : "שַׁעַשְׁגַּז",
		"xlit" : "Shaʻashgaz",
		"pron" : "shah-ash-gaz'",
		"derivation" : "of Persian derivation",
		"def" : "Shaashgaz, a eunuch of Xerxes",
		"kjv" : "Shaashgaz"
	},
	"H8191" : {
		"lemma" : "שַׁעְשֻׁעַ",
		"xlit" : "shaʻshuaʻ",
		"pron" : "shah-shoo'-ah",
		"derivation" : "from H8173 (שָׁעַע)",
		"def" : "enjoyment",
		"kjv" : "delight, pleasure"
	},
	"H8192" : {
		"lemma" : "שָׁפָה",
		"xlit" : "shâphâh",
		"pron" : "shaw-faw'",
		"derivation" : "a primitive root",
		"def" : "to abrade, i.e. bare",
		"kjv" : "high, stick out"
	},
	"H8193" : {
		"lemma" : "שָׂפָה",
		"xlit" : "sâphâh",
		"pron" : "saw-faw'",
		"derivation" : "or (in dual and plural) שֶׂפֶת; probably from H5595 (סָפָה) or H8192 (שָׁפָה) through the idea of termination (compare H5490 (סוֹף))",
		"def" : "the lip (as a natural boundary); by implication, language; by analogy, a margin (of a vessel, water, cloth, etc.)",
		"kjv" : "band, bank, binding, border, brim, brink, edge, language, lip, prating, (sea-)shore, side, speech, talk, (vain) words"
	},
	"H8194" : {
		"lemma" : "שָׁפָה",
		"xlit" : "shâphâh",
		"pron" : "shaw-faw'",
		"derivation" : "from H8192 (שָׁפָה) in the sense of clarifying",
		"def" : "a cheese (as strained from the whey)",
		"kjv" : "cheese"
	},
	"H8195" : {
		"lemma" : "שְׁפוֹ",
		"xlit" : "Shᵉphôw",
		"pron" : "shef-o'",
		"derivation" : "or שְׁפִי; from H8192 (שָׁפָה); baldness (compare H8205 (שְׁפִי))",
		"def" : "Shepho or Shephi, an Idumaean",
		"kjv" : "Shephi, Shepho"
	},
	"H8196" : {
		"lemma" : "שְׁפוֹט",
		"xlit" : "shᵉphôwṭ",
		"pron" : "shef-ote'",
		"derivation" : "or שְׁפוּט; from H8199 (שָׁפַט)",
		"def" : "a judicial sentence, i.e. punishment",
		"kjv" : "judgment"
	},
	"H8197" : {
		"lemma" : "שְׁפוּפָם",
		"xlit" : "Shᵉphûwphâm",
		"pron" : "shef-oo-fawm'",
		"derivation" : "or שְׁפוּפָן; from the same as H8207 (שְׁפִיפֹן); serpent-like",
		"def" : "Shephupham or Shephuphan, an Israelite",
		"kjv" : "Shephuphan, Shupham"
	},
	"H8198" : {
		"lemma" : "שִׁפְחָה",
		"xlit" : "shiphchâh",
		"pron" : "shif-khaw'",
		"derivation" : "feminine from an unused root meaning to spread out (as a family; see H4940 (מִשְׁפָּחָה))",
		"def" : "a female slave (as a member of the household)",
		"kjv" : "(bond-, hand-) maid(-en, -servant), wench, bondwoman, womanservant"
	},
	"H8199" : {
		"lemma" : "שָׁפַט",
		"xlit" : "shâphaṭ",
		"pron" : "shaw-fat'",
		"derivation" : "a primitive root",
		"def" : "to judge, i.e. pronounce sentence (for or against); by implication, to vindicate or punish; by extenssion, to govern; passively, to litigate (literally or figuratively)",
		"kjv" : "[phrase] avenge, [idiom] that condemn, contend, defend, execute (judgment), (be a) judge(-ment), [idiom] needs, plead, reason, rule"
	},
	"H8200" : {
		"lemma" : "שְׁפַט",
		"xlit" : "shᵉphaṭ",
		"pron" : "shef-at'",
		"derivation" : "(Aramaic) corresponding to H8199 (שָׁפַט)",
		"def" : "to judge",
		"kjv" : "magistrate"
	},
	"H8201" : {
		"lemma" : "שֶׁפֶט",
		"xlit" : "shepheṭ",
		"pron" : "sheh'-fet",
		"derivation" : "from H8199 (שָׁפַט)",
		"def" : "a sentence, i.e. infliction",
		"kjv" : "judgment"
	},
	"H8202" : {
		"lemma" : "שָׁפָט",
		"xlit" : "Shâphâṭ",
		"pron" : "shaw-fawt'",
		"derivation" : "from H8199 (שָׁפַט); judge",
		"def" : "Shaphat, the name of four Israelites",
		"kjv" : "Shaphat"
	},
	"H8203" : {
		"lemma" : "שְׁפַטְיָה",
		"xlit" : "Shᵉphaṭyâh",
		"pron" : "shef-at-yaw'",
		"derivation" : "or שְׁפַטְיָהוּ; from H8199 (שָׁפַט) and H3050 (יָהּ); Jah has judged",
		"def" : "Shephatjah, the name of ten Israelites",
		"kjv" : "Shephatiah"
	},
	"H8204" : {
		"lemma" : "שִׁפְטָן",
		"xlit" : "Shiphṭân",
		"pron" : "shif-tawn'",
		"derivation" : "from H8199 (שָׁפַט); judge-like",
		"def" : "Shiphtan, an Israelite",
		"kjv" : "Shiphtan"
	},
	"H8205" : {
		"lemma" : "שְׁפִי",
		"xlit" : "shᵉphîy",
		"pron" : "shef-ee'",
		"derivation" : "from H8192 (שָׁפָה)",
		"def" : "bareness; concretely, a bare hill or plain",
		"kjv" : "high place, stick out"
	},
	"H8206" : {
		"lemma" : "שֻׁפִּים",
		"xlit" : "Shuppîym",
		"pron" : "shoop-peem'",
		"derivation" : "plural of an unused noun from the same as H8207 (שְׁפִיפֹן) and meaning the same; serpents",
		"def" : "Shuppim, an Israelite",
		"kjv" : "Shuppim"
	},
	"H8207" : {
		"lemma" : "שְׁפִיפֹן",
		"xlit" : "shᵉphîyphôn",
		"pron" : "shef-ee-fone'",
		"derivation" : "from an unused root meaning the same as H7779 (שׁוּף)",
		"def" : "a kind of serpent (as snapping), probably the cerastes or horned adder",
		"kjv" : "adder"
	},
	"H8208" : {
		"lemma" : "שָׁפִיר",
		"xlit" : "Shâphîyr",
		"pron" : "shaf-eer'",
		"derivation" : "from H8231 (שָׁפַר); beautiful",
		"def" : "Shaphir, a place in Palestine",
		"kjv" : "Saphir"
	},
	"H8209" : {
		"lemma" : "שַׁפִּיר",
		"xlit" : "shappîyr",
		"pron" : "shap-peer'",
		"derivation" : "(Aramaic) intensive of a form corresponding to H8208 (שָׁפִיר)",
		"def" : "beautiful",
		"kjv" : "fair"
	},
	"H8210" : {
		"lemma" : "שָׁפַךְ",
		"xlit" : "shâphak",
		"pron" : "shaw-fak'",
		"derivation" : "a primitive root",
		"def" : "to spill forth (blood, a libation, liquid metal; or even a solid, i.e. to mound up); also (figuratively) to expend (life, soul, complaint, money, etc.); intensively, to sprawl out",
		"kjv" : "cast (up), gush out, pour (out), shed(-der, out), slip"
	},
	"H8211" : {
		"lemma" : "שֶׁפֶךְ",
		"xlit" : "shephek",
		"pron" : "sheh'-fek",
		"derivation" : "from H8210 (שָׁפַךְ)",
		"def" : "an emptying place, e.g. an ash-heap",
		"kjv" : "are poured out"
	},
	"H8212" : {
		"lemma" : "שׇׁפְכָה",
		"xlit" : "shophkâh",
		"pron" : "shof-kaw'",
		"derivation" : "feminine of a derivative from H8210 (שָׁפַךְ)",
		"def" : "a pipe (for pouring forth, e.g. wine), i.e. the penis",
		"kjv" : "privy member"
	},
	"H8213" : {
		"lemma" : "שָׁפֵל",
		"xlit" : "shâphêl",
		"pron" : "shaw-fale'",
		"derivation" : "a primitive root",
		"def" : "to depress or sink (expectation figuratively, to humiliate, intransitive or transitive)",
		"kjv" : "abase, bring (cast, put) down, debase, humble (self), be (bring, lay, make, put) low(-er)"
	},
	"H8214" : {
		"lemma" : "שְׁפַל",
		"xlit" : "shᵉphal",
		"pron" : "shef-al'",
		"derivation" : "(Aramaic) corresponding to H8213 (שָׁפֵל)",
		"def" : "{to depress or sink (expectation figuratively, to humiliate, intransitive or transitive)}",
		"kjv" : "abase, humble, put down, subdue"
	},
	"H8215" : {
		"lemma" : "שְׁפַל",
		"xlit" : "shᵉphal",
		"pron" : "shef-al'",
		"derivation" : "(Aramaic) from H8214 (שְׁפַל)",
		"def" : "low",
		"kjv" : "basest"
	},
	"H8216" : {
		"lemma" : "שֶׁפֶל",
		"xlit" : "shephel",
		"pron" : "shay'-fel",
		"derivation" : "from H8213 (שָׁפֵל)",
		"def" : "an humble rank",
		"kjv" : "low estate (place)"
	},
	"H8217" : {
		"lemma" : "שָׁפָל",
		"xlit" : "shâphâl",
		"pron" : "shaw-fawl'",
		"derivation" : "from H8213 (שָׁפֵל)",
		"def" : "depressed, literally or figuratively",
		"kjv" : "base(-st), humble, low(-er, -ly)"
	},
	"H8218" : {
		"lemma" : "שִׁפְלָה",
		"xlit" : "shiphlâh",
		"pron" : "shif-law'",
		"derivation" : "feminine of H8216 (שֶׁפֶל)",
		"def" : "depression",
		"kjv" : "low place"
	},
	"H8219" : {
		"lemma" : "שְׁפֵלָה",
		"xlit" : "shᵉphêlâh",
		"pron" : "shef-ay-law'",
		"derivation" : "from H8213 (שָׁפֵל)",
		"def" : "Lowland, i.e. (with the article) the maritime slope of Palestine",
		"kjv" : "low country, (low) plain, vale(-ley)"
	},
	"H8220" : {
		"lemma" : "שִׁפְלוּת",
		"xlit" : "shiphlûwth",
		"pron" : "shif-looth'",
		"derivation" : "from H8213 (שָׁפֵל)",
		"def" : "remissness",
		"kjv" : "idleness"
	},
	"H8221" : {
		"lemma" : "שְׁפָם",
		"xlit" : "Shᵉphâm",
		"pron" : "shef-awm'",
		"derivation" : "probably from H8192 (שָׁפָה); bare spot",
		"def" : "Shepham, a place in or near Palestine",
		"kjv" : "Shepham"
	},
	"H8222" : {
		"lemma" : "שָׂפָם",
		"xlit" : "sâphâm",
		"pron" : "saw-fawm'",
		"derivation" : "from H8193 (שָׂפָה)",
		"def" : "the beard (as a lippiece)",
		"kjv" : "beard, (upper) lip"
	},
	"H8223" : {
		"lemma" : "שָׁפָם",
		"xlit" : "Shâphâm",
		"pron" : "shaw-fawm'",
		"derivation" : "formed like H8221 (שְׁפָם); baldly",
		"def" : "Shapham, an Israelite",
		"kjv" : "Shapham"
	},
	"H8224" : {
		"lemma" : "שִׂפְמוֹת",
		"xlit" : "Siphmôwth",
		"pron" : "sif-moth'",
		"derivation" : "feminine plural of H8221 (שְׁפָם)",
		"def" : "Siphmoth, a place in Palestine",
		"kjv" : "Siphmoth"
	},
	"H8225" : {
		"lemma" : "שִׁפְמִי",
		"xlit" : "Shiphmîy",
		"pron" : "shif-mee'",
		"derivation" : "patrial from H8221 (שְׁפָם)",
		"def" : "a Shiphmite or inhabitant of Shepham",
		"kjv" : "Shiphmite"
	},
	"H8226" : {
		"lemma" : "שָׂפַן",
		"xlit" : "sâphan",
		"pron" : "saw-fan'",
		"derivation" : "a primitive root",
		"def" : "to conceal (as a valuable)",
		"kjv" : "treasure"
	},
	"H8227" : {
		"lemma" : "שָׁפָן",
		"xlit" : "shâphân",
		"pron" : "shaw-fawn'",
		"derivation" : "from H8226 (שָׂפַן)",
		"def" : "a species of rockrabbit (from its hiding), i.e. probably the hyrax",
		"kjv" : "coney"
	},
	"H8228" : {
		"lemma" : "שֶׁפַע",
		"xlit" : "shephaʻ",
		"pron" : "sheh'-fah",
		"derivation" : "from an unused root meaning to abound",
		"def" : "resources",
		"kjv" : "abundance"
	},
	"H8229" : {
		"lemma" : "שִׁפְעָה",
		"xlit" : "shiphʻâh",
		"pron" : "shif-aw'",
		"derivation" : "feminine of H8228 (שֶׁפַע)",
		"def" : "copiousness",
		"kjv" : "abundance, company, multitude"
	},
	"H8230" : {
		"lemma" : "שִׁפְעִי",
		"xlit" : "Shiphʻîy",
		"pron" : "shif-ee'",
		"derivation" : "from H8228 (שֶׁפַע); copious",
		"def" : "Shiphi, an Israelite",
		"kjv" : "Shiphi"
	},
	"H8231" : {
		"lemma" : "שָׁפַר",
		"xlit" : "shâphar",
		"pron" : "shaw-far'",
		"derivation" : "a primitive root",
		"def" : "to glisten, i.e. (figuratively) be (causatively, make) fair",
		"kjv" : "[idiom] goodly"
	},
	"H8232" : {
		"lemma" : "שְׁפַר",
		"xlit" : "shᵉphar",
		"pron" : "shef-ar'",
		"derivation" : "(Aramaic) corresponding to H8231 (שָׁפַר)",
		"def" : "to be beautiful",
		"kjv" : "be acceptable, please, [phrase] think good"
	},
	"H8233" : {
		"lemma" : "שֶׁפֶר",
		"xlit" : "shepher",
		"pron" : "sheh'-fer",
		"derivation" : "from H8231 (שָׁפַר)",
		"def" : "beauty",
		"kjv" : "[idiom] goodly"
	},
	"H8234" : {
		"lemma" : "שֶׁפֶר",
		"xlit" : "Shepher",
		"pron" : "sheh'-fer",
		"derivation" : "the same as H8233 (שֶׁפֶר)",
		"def" : "Shepher, a place in the Desert",
		"kjv" : "Shapper"
	},
	"H8235" : {
		"lemma" : "שִׁפְרָה",
		"xlit" : "shiphrâh",
		"pron" : "shif-raw'",
		"derivation" : "from H8231 (שָׁפַר)",
		"def" : "brightness",
		"kjv" : "garnish"
	},
	"H8236" : {
		"lemma" : "שִׁפְרָה",
		"xlit" : "Shiphrâh",
		"pron" : "shif-raw'",
		"derivation" : "the same as H8235 (שִׁפְרָה)",
		"def" : "Shiphrah, an Israelitess",
		"kjv" : "Shiphrah"
	},
	"H8237" : {
		"lemma" : "שַׁפְרוּר",
		"xlit" : "shaphrûwr",
		"pron" : "shaf-roor'",
		"derivation" : "from H8231 (שָׁפַר)",
		"def" : "splendid, i.e. a tapestry or canopy",
		"kjv" : "royal pavilion"
	},
	"H8238" : {
		"lemma" : "שְׁפַרְפַר",
		"xlit" : "shᵉpharphar",
		"pron" : "shef-ar-far'",
		"derivation" : "(Aramaic) from H8231 (שָׁפַר)",
		"def" : "the dawn (as brilliant with aurora)",
		"kjv" : "[idiom] very early in the morning"
	},
	"H8239" : {
		"lemma" : "שָׁפַת",
		"xlit" : "shâphath",
		"pron" : "shaw-fath'",
		"derivation" : "a primitive root",
		"def" : "to locate, i.e. (generally) hang on or (figuratively) establish, reduce",
		"kjv" : "bring, ordain, set on"
	},
	"H8240" : {
		"lemma" : "שָׁפָת",
		"xlit" : "shâphâth",
		"pron" : "shaw-fawth'",
		"derivation" : "from H8239 (שָׁפַת)",
		"def" : "a (double) stall (for cattle); also a (two-pronged) hook (for flaying animals on)",
		"kjv" : "hook, pot"
	},
	"H8241" : {
		"lemma" : "שֶׁצֶף",
		"xlit" : "shetseph",
		"pron" : "sheh'-tsef",
		"derivation" : "from H7857 (שָׁטַף) (for alliteration with H7110 (קֶצֶף))",
		"def" : "an outburst (of anger)",
		"kjv" : "little"
	},
	"H8242" : {
		"lemma" : "שַׂק",
		"xlit" : "saq",
		"pron" : "sak",
		"derivation" : "from H8264 (שָׁקַק)",
		"def" : "properly, a mesh (as allowing a liquid to run through), i.e. coarse loose cloth or sacking (used in mourning and for bagging); hence, a bag (for grain, etc.)",
		"kjv" : "sack(-cloth, -clothes)"
	},
	"H8243" : {
		"lemma" : "שָׁק",
		"xlit" : "shâq",
		"pron" : "shawk",
		"derivation" : "(Aramaic) corresponding to H7785 (שׁוֹק)",
		"def" : "the leg",
		"kjv" : "leg"
	},
	"H8244" : {
		"lemma" : "שָׂקַד",
		"xlit" : "sâqad",
		"pron" : "saw-kad'",
		"derivation" : "a primitive root",
		"def" : "to fasten",
		"kjv" : "bind"
	},
	"H8245" : {
		"lemma" : "שָׁקַד",
		"xlit" : "shâqad",
		"pron" : "shaw-kad'",
		"derivation" : "a primitive root",
		"def" : "to be alert, i.e. sleepless; hence to be on the lookout (whether for good or ill)",
		"kjv" : "hasten, remain, wake, watch (for)"
	},
	"H8246" : {
		"lemma" : "שָׁקַד",
		"xlit" : "shâqad",
		"pron" : "shaw-kad'",
		"derivation" : "a denominative from H8247 (שָׁקֵד)",
		"def" : "to be (intensively, make) almond-shaped",
		"kjv" : "make like (unto, after the fashion of) almonds"
	},
	"H8247" : {
		"lemma" : "שָׁקֵד",
		"xlit" : "shâqêd",
		"pron" : "shaw-kade'",
		"derivation" : "from H8245 (שָׁקַד)",
		"def" : "the almond (tree or nut; as being the earliest in bloom)",
		"kjv" : "almond (tree)"
	},
	"H8248" : {
		"lemma" : "שָׁקָה",
		"xlit" : "shâqâh",
		"pron" : "shaw-kaw'",
		"derivation" : "a primitive root",
		"def" : "to quaff, i.e. (causatively) to irrigate or furnish apotion to",
		"kjv" : "cause to (give, give to, let, make to) drink, drown, moisten, water. See H7937 (שָׁכַר), H8354 (שָׁתָה)"
	},
	"H8249" : {
		"lemma" : "שִׁקֻּו",
		"xlit" : "shiqquv",
		"pron" : "shif-koov'",
		"derivation" : "from H8248 (שָׁקָה)",
		"def" : "(plural collective) a draught",
		"kjv" : "drink"
	},
	"H8250" : {
		"lemma" : "שִׁקּוּי",
		"xlit" : "shiqqûwy",
		"pron" : "shik-koo'-ee",
		"derivation" : "from H8248 (שָׁקָה)",
		"def" : "a beverage; moisture, i.e. (figuratively) refreshment",
		"kjv" : "drink, marrow"
	},
	"H8251" : {
		"lemma" : "שִׁקּוּץ",
		"xlit" : "shiqqûwts",
		"pron" : "shik-koots'",
		"derivation" : "or שִׁקֻּץ; from H8262 (שָׁקַץ)",
		"def" : "disgusting, i.e. filthy; especially idolatrous or (concretely) an idol",
		"kjv" : "abominable filth (idol, -ation), detestable (thing)"
	},
	"H8252" : {
		"lemma" : "שָׁקַט",
		"xlit" : "shâqaṭ",
		"pron" : "shaw-kat'",
		"derivation" : "a primitive root",
		"def" : "to repose (usually figurative)",
		"kjv" : "appease, idleness, (at, be at, be in, give) quiet(-ness), (be at, be in, give, have, take) rest, settle, be still"
	},
	"H8253" : {
		"lemma" : "שֶׁקֶט",
		"xlit" : "sheqeṭ",
		"pron" : "sheh'-ket",
		"derivation" : "from H8252 (שָׁקַט)",
		"def" : "tranquillity",
		"kjv" : "quietness"
	},
	"H8254" : {
		"lemma" : "שָׁקַל",
		"xlit" : "shâqal",
		"pron" : "shaw-kal'",
		"derivation" : "a primitive root",
		"def" : "to suspend or poise (especially in trade)",
		"kjv" : "pay, receive(-r), spend, [idiom] throughly, weigh"
	},
	"H8255" : {
		"lemma" : "שֶׁקֶל",
		"xlit" : "sheqel",
		"pron" : "sheh'-kel",
		"derivation" : "from H8254 (שָׁקַל)",
		"def" : "probably a weight; used as a commercial standard",
		"kjv" : "shekel"
	},
	"H8256" : {
		"lemma" : "שִׁקְמָה",
		"xlit" : "shiqmâh",
		"pron" : "shik-maw'",
		"derivation" : "or (feminine) שִׁקְמָה; of uncertain derivation",
		"def" : "a sycamore (usually the tree)",
		"kjv" : "sycamore (fruit, tree)"
	},
	"H8257" : {
		"lemma" : "שָׁקַע",
		"xlit" : "shâqaʻ",
		"pron" : "shaw-kah'",
		"derivation" : "(abbreviated Amos 8:8); a primitive root",
		"def" : "to subside; by implication, to be overflowed, cease; causatively, to abate, subdue",
		"kjv" : "make deep, let down, drown, quench, sink"
	},
	"H8258" : {
		"lemma" : "שְׁקַעְרוּרָה",
		"xlit" : "shᵉqaʻrûwrâh",
		"pron" : "shek-ah-roo-raw'",
		"derivation" : "from H8257 (שָׁקַע)",
		"def" : "a depression",
		"kjv" : "hollow strake"
	},
	"H8259" : {
		"lemma" : "שָׁקַף",
		"xlit" : "shâqaph",
		"pron" : "shaw-kaf'",
		"derivation" : "a primitive root",
		"def" : "properly, to lean out (of a window), i.e. (by implication) peep or gaze (passively, be a spectacle)",
		"kjv" : "appear, look (down, forth, out)"
	},
	"H8260" : {
		"lemma" : "שֶׁקֶף",
		"xlit" : "sheqeph",
		"pron" : "sheh'-kef",
		"derivation" : "from H8259 (שָׁקַף)",
		"def" : "a loophole (for looking out), to admit light and air",
		"kjv" : "window"
	},
	"H8261" : {
		"lemma" : "שָׁקֻף",
		"xlit" : "shâquph",
		"pron" : "shaw-koof'",
		"derivation" : "passive participle of H8259 (שָׁקַף); (compare H8260 (שֶׁקֶף))",
		"def" : "an embrasure or opening  with bevelled jam",
		"kjv" : "light, window"
	},
	"H8262" : {
		"lemma" : "שָׁקַץ",
		"xlit" : "shâqats",
		"pron" : "shaw-kats'",
		"derivation" : "a primitive root",
		"def" : "to be filthy, i.e. (intensively) to loathe, pollute",
		"kjv" : "abhor, make abominable, have in abomination, detest, [idiom] utterly"
	},
	"H8263" : {
		"lemma" : "שֶׁקֶץ",
		"xlit" : "sheqets",
		"pron" : "sheh'-kets",
		"derivation" : "from H8262 (שָׁקַץ)",
		"def" : "filth, i.e. (figuratively and specifically) an idolatrous object",
		"kjv" : "abominable(-tion)"
	},
	"H8264" : {
		"lemma" : "שָׁקַק",
		"xlit" : "shâqaq",
		"pron" : "shaw-kak'",
		"derivation" : "a primitive root",
		"def" : "to course (like a beast of prey); by implication, to seek greedily",
		"kjv" : "have appetite, justle one against another, long, range, run (to and fro)"
	},
	"H8265" : {
		"lemma" : "שָׂקַר",
		"xlit" : "sâqar",
		"pron" : "saw-kar'",
		"derivation" : "a primitive root",
		"def" : "to ogle, i.e. blink coquettishly",
		"kjv" : "wanton"
	},
	"H8266" : {
		"lemma" : "שָׁקַר",
		"xlit" : "shâqar",
		"pron" : "shaw-kar'",
		"derivation" : "a primitive root",
		"def" : "to cheat, i.e. be untrue (usually in words)",
		"kjv" : "fail, deal falsely, lie"
	},
	"H8267" : {
		"lemma" : "שֶׁקֶר",
		"xlit" : "sheqer",
		"pron" : "sheh'-ker",
		"derivation" : "from H8266 (שָׁקַר)",
		"def" : "an untruth; by implication, a sham (often adverbial)",
		"kjv" : "without a cause, deceit(-ful), false(-hood, -ly), feignedly, liar, [phrase] lie, lying, vain (thing), wrongfully"
	},
	"H8268" : {
		"lemma" : "שֹׁקֶת",
		"xlit" : "shôqeth",
		"pron" : "sho'-keth",
		"derivation" : "from H8248 (שָׁקָה)",
		"def" : "a trough (for watering)",
		"kjv" : "trough"
	},
	"H8269" : {
		"lemma" : "שַׂר",
		"xlit" : "sar",
		"pron" : "sar",
		"derivation" : "from H8323 (שָׂרַר)",
		"def" : "a head person (of any rank or class)",
		"kjv" : "captain (that had rule), chief (captain), general, governor, keeper, lord,(-task-)master, prince(-ipal), ruler, steward"
	},
	"H8270" : {
		"lemma" : "שֹׁר",
		"xlit" : "shôr",
		"pron" : "shore",
		"derivation" : "from H8324 (שָׁרַר); (compare H8306 (שָׁרִיר))",
		"def" : "a string (as twisted ), i.e. (specifically) the umbilical cord (also figuratively, as the centre of strength)",
		"kjv" : "navel"
	},
	"H8271" : {
		"lemma" : "שְׁרֵא",
		"xlit" : "shᵉrêʼ",
		"pron" : "sher-ay'",
		"derivation" : "(Aramaic) a root corresponding to that of H8293 (שֵׁרוּת)",
		"def" : "to free, separate; figuratively, to unravel, commence; by implication (of unloading beasts) to reside",
		"kjv" : "begin, dissolve, dwell, loose"
	},
	"H8272" : {
		"lemma" : "שַׁרְאֶצֶר",
		"xlit" : "Sharʼetser",
		"pron" : "shar-eh'-tser",
		"derivation" : "of foreign derivation",
		"def" : "Sharetser, the name of an Assyrian and an Israelite",
		"kjv" : "Sharezer"
	},
	"H8273" : {
		"lemma" : "שָׁרָב",
		"xlit" : "shârâb",
		"pron" : "shaw-rawb'",
		"derivation" : "from an unused root meaning to glare",
		"def" : "quivering glow (of the air), expectation. the mirage",
		"kjv" : "heat, parched ground"
	},
	"H8274" : {
		"lemma" : "שֵׁרֵבְיָה",
		"xlit" : "Shêrêbᵉyâh",
		"pron" : "shay-rayb-yaw'",
		"derivation" : "from H8273 (שָׁרָב) and H3050 (יָהּ); Jah has brought heat",
		"def" : "Sherebjah, the name of two Israelites",
		"kjv" : "Sherebiah"
	},
	"H8275" : {
		"lemma" : "שַׁרְבִיט",
		"xlit" : "sharbîyṭ",
		"pron" : "shar-beet'",
		"derivation" : "for H7626 (שֵׁבֶט)",
		"def" : "a rod of empire",
		"kjv" : "sceptre"
	},
	"H8276" : {
		"lemma" : "שָׂרַג",
		"xlit" : "sârag",
		"pron" : "saw-rag'",
		"derivation" : "a primitive root",
		"def" : "to intwine",
		"kjv" : "wrap together, wreath"
	},
	"H8277" : {
		"lemma" : "שָׂרַד",
		"xlit" : "sârad",
		"pron" : "saw-rad'",
		"derivation" : "a primitive root; (compare H8279 (שֶׂרֶד))",
		"def" : "properly, to puncture, i.e. (figuratively through the idea of slipping out) to escape or survive",
		"kjv" : "remain"
	},
	"H8278" : {
		"lemma" : "שְׂרָד",
		"xlit" : "sᵉrâd",
		"pron" : "ser-awd'",
		"derivation" : "from H8277 (שָׂרַד)",
		"def" : "stitching (as pierced with a needle)",
		"kjv" : "service"
	},
	"H8279" : {
		"lemma" : "שֶׂרֶד",
		"xlit" : "sered",
		"pron" : "seh'-red",
		"derivation" : "from H8277 (שָׂרַד)",
		"def" : "a (carpenter's) scribing-awl (for pricking or scratching measurements)",
		"kjv" : "line"
	},
	"H8280" : {
		"lemma" : "שָׂרָה",
		"xlit" : "sârâh",
		"pron" : "saw-raw'",
		"derivation" : "a primitive root",
		"def" : "to prevail",
		"kjv" : "have power (as a prince)"
	},
	"H8281" : {
		"lemma" : "שָׁרָה",
		"xlit" : "shârâh",
		"pron" : "shaw-raw'",
		"derivation" : "a primitive root",
		"def" : "to free",
		"kjv" : "direct"
	},
	"H8282" : {
		"lemma" : "שָׂרָה",
		"xlit" : "sârâh",
		"pron" : "saw-raw'",
		"derivation" : "feminine of H8269 (שַׂר)",
		"def" : "a mistress, i.e. female noble",
		"kjv" : "lady, princess, queen"
	},
	"H8283" : {
		"lemma" : "שָׂרָה",
		"xlit" : "Sârâh",
		"pron" : "saw-raw'",
		"derivation" : "the same as H8282 (שָׂרָה)",
		"def" : "Sarah, Abraham's wife",
		"kjv" : "Sarah"
	},
	"H8284" : {
		"lemma" : "שָׁרָה",
		"xlit" : "shârâh",
		"pron" : "shaw-raw'",
		"derivation" : "probably feminine of H7791 (שׁוּר)",
		"def" : "a fortification (literally or figuratively)",
		"kjv" : "sing (by mistake for H7891 (שִׁיר)), wall"
	},
	"H8285" : {
		"lemma" : "שֵׁרָה",
		"xlit" : "shêrâh",
		"pron" : "shay-raw'",
		"derivation" : "from H8324 (שָׁרַר) in its original sense of pressing",
		"def" : "a wrist-band (as compact or clasping)",
		"kjv" : "bracelet"
	},
	"H8286" : {
		"lemma" : "שְׂרוּג",
		"xlit" : "Sᵉrûwg",
		"pron" : "ser-oog'",
		"derivation" : "from H8276 (שָׂרַג); tendril",
		"def" : "Serug, a postdiluvian patriarch",
		"kjv" : "Serug"
	},
	"H8287" : {
		"lemma" : "שָׁרוּחֶן",
		"xlit" : "Shârûwchen",
		"pron" : "shaw-roo-khen'",
		"derivation" : "probably from H8281 (שָׁרָה) (in the sense of dwelling (compare H8271 (שְׁרֵא)) and H2580 (חֵן); abode of pleasure",
		"def" : "Sharuchen, a place in Palestine",
		"kjv" : "Sharuhen"
	},
	"H8288" : {
		"lemma" : "שְׂרוֹךְ",
		"xlit" : "sᵉrôwk",
		"pron" : "ser-oke'",
		"derivation" : "from H8308 (שָׂרַךְ)",
		"def" : "a thong (as laced or tied)",
		"kjv" : "(shoe-)latchet"
	},
	"H8289" : {
		"lemma" : "שָׁרוֹן",
		"xlit" : "Shârôwn",
		"pron" : "shaw-rone'",
		"derivation" : "probably abridged from H3474 (יָשַׁר)",
		"def" : "plain, Sharon, the name of a place in Palestine",
		"kjv" : "Lasharon, Sharon"
	},
	"H8290" : {
		"lemma" : "שָׁרוֹנִי",
		"xlit" : "Shârôwnîy",
		"pron" : "shaw-ro-nee'",
		"derivation" : "patrial from H8289 (שָׁרוֹן)",
		"def" : "a Sharonite or inhabitant of Sharon",
		"kjv" : "Sharonite"
	},
	"H8291" : {
		"lemma" : "שָׂרוּק",
		"xlit" : "sârûwq",
		"pron" : "sar-ook'",
		"derivation" : "passive participle from the same as H8321 (שֹׂרֵק)",
		"def" : "a grapevine",
		"kjv" : "principal plant. See H8320 (שָׂרֻק), H8321 (שֹׂרֵק)"
	},
	"H8292" : {
		"lemma" : "שְׁרוּקָה",
		"xlit" : "shᵉrûwqâh",
		"pron" : "sher-oo-kaw'",
		"derivation" : "or (by permutation) שְׁרִיקָה; feminine passive participle of H8319 (שָׁרַק)",
		"def" : "a whistling (in scorn); by analogy, a piping",
		"kjv" : "bleating, hissing"
	},
	"H8293" : {
		"lemma" : "שֵׁרוּת",
		"xlit" : "shêrûwth",
		"pron" : "shay-rooth'",
		"derivation" : "from H8281 (שָׁרָה) abbreviated",
		"def" : "freedom",
		"kjv" : "remnant"
	},
	"H8294" : {
		"lemma" : "שֶׂרַח",
		"xlit" : "Serach",
		"pron" : "seh'-rakh",
		"derivation" : "by permutation for H5629 (סֶרַח); superfluity",
		"def" : "Serach, an Israelitess",
		"kjv" : "Sarah, Serah"
	},
	"H8295" : {
		"lemma" : "שָׂרַט",
		"xlit" : "sâraṭ",
		"pron" : "saw-rat'",
		"derivation" : "a primitive root",
		"def" : "to gash",
		"kjv" : "cut in pieces, make (cuttings) pieces"
	},
	"H8296" : {
		"lemma" : "שֶׂרֶט",
		"xlit" : "sereṭ",
		"pron" : "seh'-ret",
		"derivation" : "and שָׂרֶטֶת; from H8295 (שָׂרַט)",
		"def" : "an incision",
		"kjv" : "cutting"
	},
	"H8297" : {
		"lemma" : "שָׂרַי",
		"xlit" : "Sâray",
		"pron" : "saw-rah'-ee",
		"derivation" : "from H8269 (שַׂר); dominative",
		"def" : "Sarai, the wife of Abraham",
		"kjv" : "Sarai"
	},
	"H8298" : {
		"lemma" : "שָׁרַי",
		"xlit" : "Shâray",
		"pron" : "shaw-rah'-ee",
		"derivation" : "probably from H8324 (שָׁרַר); hostile",
		"def" : "Sharay, an Israelite",
		"kjv" : "Sharai"
	},
	"H8299" : {
		"lemma" : "שָׂרִיג",
		"xlit" : "sârîyg",
		"pron" : "saw-reeg'",
		"derivation" : "from H8276 (שָׂרַג)",
		"def" : "a tendril (as entwining)",
		"kjv" : "branch"
	},
	"H8300" : {
		"lemma" : "שָׂרִיד",
		"xlit" : "sârîyd",
		"pron" : "saw-reed'",
		"derivation" : "from H8277 (שָׂרַד)",
		"def" : "a survivor",
		"kjv" : "[idiom] alive, left, remain(-ing), remnant, rest"
	},
	"H8301" : {
		"lemma" : "שָׂרִיד",
		"xlit" : "Sârîyd",
		"pron" : "suw-reed'",
		"derivation" : "the same as H8300 (שָׂרִיד)",
		"def" : "Sarid, a place in Palestine",
		"kjv" : "Sarid"
	},
	"H8302" : {
		"lemma" : "שִׁרְיוֹן",
		"xlit" : "shiryôwn",
		"pron" : "shir-yone'",
		"derivation" : "or שִׁרְיֹן; and שִׁרְיָן; also (feminine) שִׁרְיָה; and שִׁרְיֹנָה; from H8281 (שָׁרָה) in the original sense of turning",
		"def" : "a corslet (as if twisted)",
		"kjv" : "breastplate, coat of mail, habergeon, harness. See H5630 (סִרְיֹן)"
	},
	"H8303" : {
		"lemma" : "שִׁרְיוֹן",
		"xlit" : "Shiryôwn",
		"pron" : "shir-yone'",
		"derivation" : "and שִׂרְיֹן; the same as H8304 (שְׂרָיָה) (i.e. sheeted with snow)",
		"def" : "Shirjon or Sirjon, a peak of the Lebanon",
		"kjv" : "Sirion"
	},
	"H8304" : {
		"lemma" : "שְׂרָיָה",
		"xlit" : "Sᵉrâyâh",
		"pron" : "ser-aw-yaw'",
		"derivation" : "or שְׂרָיָהוּ; from H8280 (שָׂרָה) and H3050 (יָהּ); Jah has prevailed",
		"def" : "Serajah, the name of nine Israelites",
		"kjv" : "Seraiah"
	},
	"H8305" : {
		"lemma" : "שְׂרִיקָה",
		"xlit" : "sᵉrîyqâh",
		"pron" : "ser-ee-kaw'",
		"derivation" : "from the same as H8321 (שֹׂרֵק) in the original sense of piercing",
		"def" : "hetchelling (or combing flax), i.e. (concretely) tow (by extension, linen cloth)",
		"kjv" : "fine"
	},
	"H8306" : {
		"lemma" : "שָׁרִיר",
		"xlit" : "shârîyr",
		"pron" : "shaw-reer'",
		"derivation" : "from H8324 (שָׁרַר) in the original sense as in H8270 (שֹׁר) (compare H8326 (שֹׁרֶר))",
		"def" : "a cord, i.e. (by analogy) sinew",
		"kjv" : "navel"
	},
	"H8307" : {
		"lemma" : "שְׁרִירוּת",
		"xlit" : "shᵉrîyrûwth",
		"pron" : "sher-ee-rooth'",
		"derivation" : "from H8324 (שָׁרַר) in the sense of twisted, i.e. firm",
		"def" : "obstinacy",
		"kjv" : "imagination, lust"
	},
	"H8308" : {
		"lemma" : "שָׂרַךְ",
		"xlit" : "sârak",
		"pron" : "saw-rak'",
		"derivation" : "a primitive root",
		"def" : "to interlace",
		"kjv" : "traverse"
	},
	"H8309" : {
		"lemma" : "שְׁרֵמָה",
		"xlit" : "shᵉrêmâh",
		"pron" : "sher-ay-maw'",
		"derivation" : "probably by an orthographical error for H7709 (שְׁדֵמָה)",
		"def" : "a common",
		"kjv" : "field"
	},
	"H8310" : {
		"lemma" : "שַׂרְסְכִים",
		"xlit" : "Sarçᵉkîym",
		"pron" : "sar-seh-keem'",
		"derivation" : "of foreign derivation",
		"def" : "Sarsekim, a Babylonian general",
		"kjv" : "Sarsechim"
	},
	"H8311" : {
		"lemma" : "שָׂרַע",
		"xlit" : "sâraʻ",
		"pron" : "saw-rah'",
		"derivation" : "a primitive root",
		"def" : "to prolong, i.e. (reflex) be deformed by excess of members",
		"kjv" : "stretch out self, (have any) superfluous thing"
	},
	"H8312" : {
		"lemma" : "שַׂרְעַף",
		"xlit" : "sarʻaph",
		"pron" : "sar-af'",
		"derivation" : "for H5587 (סָעִף)",
		"def" : "cogitation",
		"kjv" : "thought"
	},
	"H8313" : {
		"lemma" : "שָׂרַף",
		"xlit" : "sâraph",
		"pron" : "saw-raf'",
		"derivation" : "a primitive root",
		"def" : "to be (causatively, set) on fire",
		"kjv" : "(cause to, make a) burn((-ing), up) kindle, [idiom] utterly"
	},
	"H8314" : {
		"lemma" : "שָׂרָף",
		"xlit" : "sârâph",
		"pron" : "saw-rawf'",
		"derivation" : "from H8313 (שָׂרַף)",
		"def" : "burning, i.e. (figuratively) poisonous (serpent); specifically, a saraph or symbolical creature (from their copper color)",
		"kjv" : "fiery (serpent), seraph"
	},
	"H8315" : {
		"lemma" : "שָׂרָף",
		"xlit" : "Sârâph",
		"pron" : "saw-raf'",
		"derivation" : "the same as H8314 (שָׂרָף)",
		"def" : "Saraph, an Israelite",
		"kjv" : "Saraph"
	},
	"H8316" : {
		"lemma" : "שְׂרֵפָה",
		"xlit" : "sᵉrêphâh",
		"pron" : "ser-ay-faw'",
		"derivation" : "from H8313 (שָׂרַף)",
		"def" : "cremation",
		"kjv" : "burning"
	},
	"H8317" : {
		"lemma" : "שָׁרַץ",
		"xlit" : "shârats",
		"pron" : "shaw-rats'",
		"derivation" : "a primitive root",
		"def" : "to wriggle, i.e. (by implication) swarm or abound",
		"kjv" : "breed (bring forth, increase) abundantly (in abundance), creep, move"
	},
	"H8318" : {
		"lemma" : "שֶׁרֶץ",
		"xlit" : "sherets",
		"pron" : "sheh'-rets",
		"derivation" : "from H8317 (שָׁרַץ)",
		"def" : "a swarm, i.e. active mass of minute animals",
		"kjv" : "creep(-ing thing), move(-ing creature)"
	},
	"H8319" : {
		"lemma" : "שָׁרַק",
		"xlit" : "shâraq",
		"pron" : "shaw-rak'",
		"derivation" : "a primitive root",
		"def" : "properly, to be shrill, i.e. to whistle or hiss (as a call or in scorn)",
		"kjv" : "hiss"
	},
	"H8320" : {
		"lemma" : "שָׂרֻק",
		"xlit" : "sâruq",
		"pron" : "saw-rook'",
		"derivation" : "from H8319 (שָׁרַק)",
		"def" : "bright red (as piercing to the sight), i.e. bay",
		"kjv" : "speckled. See H8291 (שָׂרוּק)"
	},
	"H8321" : {
		"lemma" : "שֹׂרֵק",
		"xlit" : "sôrêq",
		"pron" : "so-rake'",
		"derivation" : "or שׂוֹרֵק; and (feminine) שֹׂרֵקָה; from H8319 (שָׁרַק) in the sense of redness (compare H8320 (שָׂרֻק))",
		"def" : "a vine stock (properly, one yielding purple grapes, the richest variety)",
		"kjv" : "choice(-st, noble) wine. Compare H8291 (שָׂרוּק)"
	},
	"H8322" : {
		"lemma" : "שְׁרֵקָה",
		"xlit" : "shᵉrêqâh",
		"pron" : "sher-ay-kaw'",
		"derivation" : "from H8319 (שָׁרַק)",
		"def" : "a derision",
		"kjv" : "hissing"
	},
	"H8323" : {
		"lemma" : "שָׂרַר",
		"xlit" : "sârar",
		"pron" : "saw-rar'",
		"derivation" : "a primitive root",
		"def" : "to have (transitively, exercise; reflexively, get) dominion",
		"kjv" : "[idiom] altogether, make self a prince, (bear) rule"
	},
	"H8324" : {
		"lemma" : "שָׁרַר",
		"xlit" : "shârar",
		"pron" : "shaw-rar'",
		"derivation" : "a primitive root",
		"def" : "to be hostile (only active participle an opponent)",
		"kjv" : "enemy"
	},
	"H8325" : {
		"lemma" : "שָׁרַר",
		"xlit" : "Shârar",
		"pron" : "shaw-rawr'",
		"derivation" : "from H8324 (שָׁרַר); hostile",
		"def" : "Sharar, an Israelite",
		"kjv" : "Sharar"
	},
	"H8326" : {
		"lemma" : "שֹׁרֶר",
		"xlit" : "shôrer",
		"pron" : "sho'-rer",
		"derivation" : "from H8324 (שָׁרַר) in the sense of twisting (compare H8270 (שֹׁר))",
		"def" : "the umbilical cord, i.e. (by extension) a bodice",
		"kjv" : "navel"
	},
	"H8327" : {
		"lemma" : "שָׁרַשׁ",
		"xlit" : "shârash",
		"pron" : "shaw-rash'",
		"derivation" : "a primitive root",
		"def" : "to root, i.e. strike into the soil, or (by implication) to pluck from it",
		"kjv" : "(take, cause to take) root (out)"
	},
	"H8328" : {
		"lemma" : "שֶׁרֶשׁ",
		"xlit" : "sheresh",
		"pron" : "sheh'-resh",
		"derivation" : "from H8327 (שָׁרַשׁ)",
		"def" : "a root (literally or figuratively)",
		"kjv" : "bottom, deep, heel, root"
	},
	"H8329" : {
		"lemma" : "שֶׁרֶשׁ",
		"xlit" : "Sheresh",
		"pron" : "sheh'-resh",
		"derivation" : "the same as H8328 (שֶׁרֶשׁ)",
		"def" : "Sheresh, an Israelite",
		"kjv" : "Sharesh"
	},
	"H8330" : {
		"lemma" : "שֹׁרֶשׁ",
		"xlit" : "shôresh",
		"pron" : "sho'-resh",
		"derivation" : "(Aramaic) corresponding to H8328 (שֶׁרֶשׁ)",
		"def" : "{a root (literally or figuratively)}",
		"kjv" : "root"
	},
	"H8331" : {
		"lemma" : "שַׁרְשָׁה",
		"xlit" : "sharshâh",
		"pron" : "shar-shaw'",
		"derivation" : "from H8327 (שָׁרַשׁ)",
		"def" : "a chain (as rooted, i.e. linked)",
		"kjv" : "chain. Compare H8333 (שַׁרְשְׁרָה)"
	},
	"H8332" : {
		"lemma" : "שְׁרֹשׁוּ",
		"xlit" : "shᵉrôshûw",
		"pron" : "sher-o-shoo'",
		"derivation" : "(Aramaic) from a root corresponding to H8327 (שָׁרַשׁ)",
		"def" : "eradication, i.e. (figuratively) exile",
		"kjv" : "banishment"
	},
	"H8333" : {
		"lemma" : "שַׁרְשְׁרָה",
		"xlit" : "sharshᵉrâh",
		"pron" : "shar-sher-aw'",
		"derivation" : "from H8327 (שָׁרַשׁ) (compare H8331 (שַׁרְשָׁה))",
		"def" : "a chain; (architectural) probably a garland",
		"kjv" : "chain"
	},
	"H8334" : {
		"lemma" : "שָׁרַת",
		"xlit" : "shârath",
		"pron" : "shaw-rath'",
		"derivation" : "a primitive root",
		"def" : "to attend as a menial or worshipper; figuratively, to contribute to",
		"kjv" : "minister (unto), (do) serve(-ant, -ice, -itor), wait on"
	},
	"H8335" : {
		"lemma" : "שָׁרֵת",
		"xlit" : "shârêth",
		"pron" : "shaw-rayth'",
		"derivation" : "infinitive of H8334 (שָׁרַת)",
		"def" : "service (in the Temple)",
		"kjv" : "minister(-ry)"
	},
	"H8336" : {
		"lemma" : "שֵׁשׁ",
		"xlit" : "shêsh",
		"pron" : "shaysh",
		"derivation" : "or (for alliteration with H4897 (מֶשִׁי)) שְׁשִׁי; for H7893 (שַׁיִשׁ)",
		"def" : "bleached stuff, i.e. white linen or (by analogy) marble",
		"kjv" : "[idiom] blue, fine (twined) linen, marble, silk"
	},
	"H8337" : {
		"lemma" : "שֵׁשׁ",
		"xlit" : "shêsh",
		"pron" : "shaysh",
		"derivation" : "masculine שִׁשָּׁה; a primitive number; compare H7797 (שׂוּשׂ)",
		"def" : "six (as an overplus  beyond five or the fingers of the hand); as ord. sixth",
		"kjv" : "six(-teen, -teenth), sixth"
	},
	"H8338" : {
		"lemma" : "שָׁשָׁא",
		"xlit" : "shâshâʼ",
		"pron" : "shaw-shaw'",
		"derivation" : "a primitive root; apparently",
		"def" : "to annihilate",
		"kjv" : "leave by the sixth part (by confusion with H8341 (שָׁשָׁה))"
	},
	"H8339" : {
		"lemma" : "שֵׁשְׁבַּצַּר",
		"xlit" : "Shêshᵉbatstsar",
		"pron" : "shaysh-bats-tsar'",
		"derivation" : "of foreign derivation",
		"def" : "Sheshbatstsar, Zerubbabel's Persian name",
		"kjv" : "Sheshbazzar"
	},
	"H8340" : {
		"lemma" : "שֵׁשְׁבַּצַּר",
		"xlit" : "Shêshᵉbatstsar",
		"pron" : "shaysh-bats-tsar'",
		"derivation" : "(Aramaic) corresponding to H8339 (שֵׁשְׁבַּצַּר)",
		"def" : "{Sheshbatstsar, Zerubbabel's Persian name}",
		"kjv" : "Sheshbazzar"
	},
	"H8341" : {
		"lemma" : "שָׁשָׁה",
		"xlit" : "shâshâh",
		"pron" : "shaw-shaw'",
		"derivation" : "a denominative from H8337 (שֵׁשׁ)",
		"def" : "to sixth or divide into sixths",
		"kjv" : "give the sixth participle"
	},
	"H8342" : {
		"lemma" : "שָׂשׂוֹן",
		"xlit" : "sâsôwn",
		"pron" : "saw-sone'",
		"derivation" : "or שָׂשֹׂן; from H7797 (שׂוּשׂ)",
		"def" : "cheerfulness; specifically, welcome",
		"kjv" : "gladness, joy, mirth, rejoicing"
	},
	"H8343" : {
		"lemma" : "שָׁשַׁי",
		"xlit" : "Shâshay",
		"pron" : "shaw-shah'-ee",
		"derivation" : "perhaps from H8336 (שֵׁשׁ); whitish",
		"def" : "Shashai, an Israelite",
		"kjv" : "Shashai"
	},
	"H8344" : {
		"lemma" : "שֵׁשַׁי",
		"xlit" : "Shêshay",
		"pron" : "shay-shah'-ee",
		"derivation" : "probably for H8343 (שָׁשַׁי)",
		"def" : "Sheshai, a Canaanite",
		"kjv" : "Sheshai"
	},
	"H8345" : {
		"lemma" : "שִׁשִּׁי",
		"xlit" : "shishshîy",
		"pron" : "shish-shee'",
		"derivation" : "from H8337 (שֵׁשׁ)",
		"def" : "sixth, ord. or (feminine) fractional",
		"kjv" : "sixth (part)"
	},
	"H8346" : {
		"lemma" : "שִׁשִּׁים",
		"xlit" : "shishshîym",
		"pron" : "shish-sheem'",
		"derivation" : "multiple of H8337 (שֵׁשׁ)",
		"def" : "sixty",
		"kjv" : "sixty, three score"
	},
	"H8347" : {
		"lemma" : "שֵׁשַׁךְ",
		"xlit" : "Shêshak",
		"pron" : "shay-shak'",
		"derivation" : "of foreign derivation",
		"def" : "Sheshak, a symbolic name of Babylon",
		"kjv" : "Sheshach"
	},
	"H8348" : {
		"lemma" : "שֵׁשָׁן",
		"xlit" : "Shêshân",
		"pron" : "shay-shawn'",
		"derivation" : "perhaps for H7799 (שׁוּשַׁן); lily",
		"def" : "Sheshan, an Israelite",
		"kjv" : "Sheshan"
	},
	"H8349" : {
		"lemma" : "שָׁשַׁק",
		"xlit" : "Shâshaq",
		"pron" : "shaw-shak'",
		"derivation" : "probably from the base of H7785 (שׁוֹק); pedestrian",
		"def" : "Shashak, an Israelite",
		"kjv" : "Shashak"
	},
	"H8350" : {
		"lemma" : "שָׁשַׁר",
		"xlit" : "shâshar",
		"pron" : "shaw-shar'",
		"derivation" : "perhaps from the base of H8324 (שָׁרַר) in the sense of that of H8320 (שָׂרֻק)",
		"def" : "red ochre (from its piercing color)",
		"kjv" : "vermillion"
	},
	"H8351" : {
		"lemma" : "שֵׁת",
		"xlit" : "shêth",
		"pron" : "shayth",
		"derivation" : "(Numbers from H7582 (שָׁאָה)",
		"def" : "tumult",
		"kjv" : "Sheth"
	},
	"H8352" : {
		"lemma" : "שֵׁת",
		"xlit" : "Shêth",
		"pron" : "shayth",
		"derivation" : "from H7896 (שִׁית); put, i.e. substituted",
		"def" : "Sheth, third son of Adam",
		"kjv" : "Seth, Sheth"
	},
	"H8353" : {
		"lemma" : "שֵׁת",
		"xlit" : "shêth",
		"pron" : "shayth",
		"derivation" : "(Aramaic) or שִׁת; (Aramaic), corresponding to H8337 (שֵׁשׁ)",
		"def" : "{six}",
		"kjv" : "six(-th)"
	},
	"H8354" : {
		"lemma" : "שָׁתָה",
		"xlit" : "shâthâh",
		"pron" : "shaw-thaw'",
		"derivation" : "a primitive root",
		"def" : "to imbibe (literally or figuratively)",
		"kjv" : "[idiom] assuredly, banquet, [idiom] certainly, drink(-er, -ing), drunk ([idiom] -ard), surely. (Prop. intensive of H8248 (שָׁקָה).)"
	},
	"H8355" : {
		"lemma" : "שְׁתָה",
		"xlit" : "shᵉthâh",
		"pron" : "sheth-aw'",
		"derivation" : "(Aramaic) corresponding to H8354 (שָׁתָה)",
		"def" : "{to imbibe (literally or figuratively)}",
		"kjv" : "drink"
	},
	"H8356" : {
		"lemma" : "שָׁתָה",
		"xlit" : "shâthâh",
		"pron" : "shaw-thaw'",
		"derivation" : "from H7896 (שִׁית)",
		"def" : "a basis, i.e. (figuratively) political or moral support",
		"kjv" : "foundation, purpose"
	},
	"H8357" : {
		"lemma" : "שֵׁתָה",
		"xlit" : "shêthâh",
		"pron" : "shay-thaw'",
		"derivation" : "from H7896 (שִׁית)",
		"def" : "the seat (of the person)",
		"kjv" : "buttock"
	},
	"H8358" : {
		"lemma" : "שְׁתִי",
		"xlit" : "shᵉthîy",
		"pron" : "sheth-ee'",
		"derivation" : "from H8354 (שָׁתָה)",
		"def" : "intoxicaion",
		"kjv" : "drunkenness"
	},
	"H8359" : {
		"lemma" : "שְׁתִי",
		"xlit" : "shᵉthîy",
		"pron" : "sheth-ee'",
		"derivation" : "from H7896 (שִׁית)",
		"def" : "a fixture, i.e. the warp in weaving",
		"kjv" : "warp"
	},
	"H8360" : {
		"lemma" : "שְׁתִיָּה",
		"xlit" : "shᵉthîyâh",
		"pron" : "sheth-ee-yaw'",
		"derivation" : "feminine of H8358 (שְׁתִי)",
		"def" : "potation",
		"kjv" : "drinking"
	},
	"H8361" : {
		"lemma" : "שִׁתִּין",
		"xlit" : "shittîyn",
		"pron" : "shit-teen'",
		"derivation" : "(Aramaic) corresponding to H8346 (שִׁשִּׁים) (compare H8353 (שֵׁת))",
		"def" : "sixty",
		"kjv" : "threescore"
	},
	"H8362" : {
		"lemma" : "שָׁתַל",
		"xlit" : "shâthal",
		"pron" : "shaw-thal'",
		"derivation" : "a primitive root",
		"def" : "to transplant",
		"kjv" : "plant"
	},
	"H8363" : {
		"lemma" : "שְׁתִל",
		"xlit" : "shᵉthil",
		"pron" : "sheth-eel'",
		"derivation" : "from H8362 (שָׁתַל)",
		"def" : "a sprig (as if transplanted), i.e. sucker",
		"kjv" : "plant"
	},
	"H8364" : {
		"lemma" : "שֻׁתַלְחִי",
		"xlit" : "Shuthalchîy",
		"pron" : "shoo-thal-kee'",
		"derivation" : "patronymically from H7803 (שׁוּתֶלַח)",
		"def" : "a Shuthalchite (collectively) or descendants of Shuthelach",
		"kjv" : "Shuthalhites"
	},
	"H8365" : {
		"lemma" : "שָׁתַם",
		"xlit" : "shâtham",
		"pron" : "shaw-tham'",
		"derivation" : "a primitive root",
		"def" : "to unveil (figuratively)",
		"kjv" : "be open"
	},
	"H8366" : {
		"lemma" : "שָׁתַן",
		"xlit" : "shâthan",
		"pron" : "shaw-than'",
		"derivation" : "a primitive root",
		"def" : "(causatively) to make water, i.e. urinate",
		"kjv" : "piss"
	},
	"H8367" : {
		"lemma" : "שָׁתַק",
		"xlit" : "shâthaq",
		"pron" : "shaw-thak'",
		"derivation" : "a primitive root",
		"def" : "to subside",
		"kjv" : "be calm, cease, be quiet"
	},
	"H8368" : {
		"lemma" : "שָׂתַר",
		"xlit" : "sâthar",
		"pron" : "saw-thar'",
		"derivation" : "a primitive root",
		"def" : "to break out (as an eruption)",
		"kjv" : "have in (one's) secret parts"
	},
	"H8369" : {
		"lemma" : "שֵׁתָר",
		"xlit" : "Shêthâr",
		"pron" : "shay-thawr'",
		"derivation" : "of foreign derivation",
		"def" : "Shethar, a Persian satrap",
		"kjv" : "Shethar"
	},
	"H8370" : {
		"lemma" : "שְׁתַר בּוֹזְנַי",
		"xlit" : "Shᵉthar Bôwzᵉnay",
		"pron" : "sheth-ar' bo-zen-ah'-ee",
		"derivation" : "of foreign derivation",
		"def" : "Shethar-Bozenai, a Persian officer",
		"kjv" : "Shetharboznai"
	},
	"H8371" : {
		"lemma" : "שָׁתַת",
		"xlit" : "shâthath",
		"pron" : "shaw-thath'",
		"derivation" : "a primitive root",
		"def" : "to place, i.e. array; reflex. to lie",
		"kjv" : "be laid, set. t"
	},
	"H8372" : {
		"lemma" : "תָּא",
		"xlit" : "tâʼ",
		"pron" : "taw",
		"derivation" : "and (feminine) תָּאָה; (Ezekiel 40:12), from (the base of) H8376 (תָּאָה)",
		"def" : "a room (as circumscribed)",
		"kjv" : "(little) chamber"
	},
	"H8373" : {
		"lemma" : "תָּאַב",
		"xlit" : "tâʼab",
		"pron" : "taw-ab'",
		"derivation" : "a primitive root",
		"def" : "to desire",
		"kjv" : "long"
	},
	"H8374" : {
		"lemma" : "תָּאַב",
		"xlit" : "tâʼab",
		"pron" : "taw-ab'",
		"derivation" : "a primitive root (probably identical with H8373 (תָּאַב) through the idea of puffing disdainfully at; compare H340 (אָיַב))",
		"def" : "to loathe (morally)",
		"kjv" : "abhor"
	},
	"H8375" : {
		"lemma" : "תַּאֲבָה",
		"xlit" : "taʼăbâh",
		"pron" : "tah-ab-aw'",
		"derivation" : "from H8374 (תָּאַב) (compare H15 (אָבֶה))",
		"def" : "desire",
		"kjv" : "longing"
	},
	"H8376" : {
		"lemma" : "תָּאָה",
		"xlit" : "tâʼâh",
		"pron" : "taw-aw'",
		"derivation" : "a primitive root",
		"def" : "to mark off, i.e. (intensively) designate",
		"kjv" : "point out"
	},
	"H8377" : {
		"lemma" : "תְּאוֹ",
		"xlit" : "tᵉʼôw",
		"pron" : "teh-o'",
		"derivation" : "and תּוֹא; (the original form), from H8376 (תָּאָה)",
		"def" : "a species of antelope (probably from the white stripe on the cheek)",
		"kjv" : "wild bull (ox)"
	},
	"H8378" : {
		"lemma" : "תַּאֲוָה",
		"xlit" : "taʼăvâh",
		"pron" : "tah-av-aw'",
		"derivation" : "from H183 (אָוָה) (abbreviated)",
		"def" : "a longing; by implication, a delight (subjectively, satisfaction, objectively, a charm)",
		"kjv" : "dainty, desire, [idiom] exceedingly, [idiom] greedily, lust(ing), pleasant. See also H6914 (קִבְרוֹת הַתַּאֲוָה)"
	},
	"H8379" : {
		"lemma" : "תַּאֲוָה",
		"xlit" : "taʼăvâh",
		"pron" : "tah-av-aw'",
		"derivation" : "from H8376 (תָּאָה)",
		"def" : "a limit, i.e. full extent",
		"kjv" : "utmost bound"
	},
	"H8380" : {
		"lemma" : "תָּאוֹם",
		"xlit" : "tâʼôwm",
		"pron" : "taw-ome'",
		"derivation" : "or תָּאֹם; from H8382 (תָּאַם)",
		"def" : "a twin (in plural only), literally or figuratively",
		"kjv" : "twins"
	},
	"H8381" : {
		"lemma" : "תַּאֲלָה",
		"xlit" : "taʼălâh",
		"pron" : "tah-al-aw'",
		"derivation" : "from H422 (אָלָה)",
		"def" : "an imprecation",
		"kjv" : "curse"
	},
	"H8382" : {
		"lemma" : "תָּאַם",
		"xlit" : "tâʼam",
		"pron" : "taw-am'",
		"derivation" : "a primitive root; to be complete; but used only as denominative from H8380 (תָּאוֹם)",
		"def" : "to be (causatively, make) twinned, i.e. (figuratively) duplicate or (architectural) jointed",
		"kjv" : "coupled (together), bear twins"
	},
	"H8383" : {
		"lemma" : "תְּאֻן",
		"xlit" : "tᵉʼun",
		"pron" : "teh-oon'",
		"derivation" : "from H205 (אָוֶן)",
		"def" : "naughtiness, i.e. toil",
		"kjv" : "lie"
	},
	"H8384" : {
		"lemma" : "תְּאֵן",
		"xlit" : "tᵉʼên",
		"pron" : "teh-ane'",
		"derivation" : "or (in the singular, feminine) תְּאֵנָה; perhaps of foreign derivation",
		"def" : "the fig (tree or fruit)",
		"kjv" : "fig (tree)"
	},
	"H8385" : {
		"lemma" : "תַּאֲנָה",
		"xlit" : "taʼănâh",
		"pron" : "tah-an-aw'",
		"derivation" : "or תֹּאֲנָה; from H579 (אָנָה)",
		"def" : "an opportunity or (subjectively) purpose",
		"kjv" : "occasion"
	},
	"H8386" : {
		"lemma" : "תַּאֲנִיָּה",
		"xlit" : "taʼănîyâh",
		"pron" : "tah-an-ee-yaw'",
		"derivation" : "from H578 (אָנָה)",
		"def" : "lamentation",
		"kjv" : "heaviness, mourning"
	},
	"H8387" : {
		"lemma" : "תַּאֲנַת שִׁלֹה",
		"xlit" : "Taʼănath Shilôh",
		"pron" : "tah-an-ath' shee-lo'",
		"derivation" : "from H8385 (תַּאֲנָה) and H7887 (שִׁילֹה); approach of Shiloh",
		"def" : "Taanath-Shiloh, a place in Palestine",
		"kjv" : "Taanath-shiloh"
	},
	"H8388" : {
		"lemma" : "תָּאַר",
		"xlit" : "tâʼar",
		"pron" : "taw-ar'",
		"derivation" : "a primitive root",
		"def" : "to delineate; reflex. to extend",
		"kjv" : "be drawn, mark out, (Rimmon-) methoar (by union with H7417 (רִמּוֹן))"
	},
	"H8389" : {
		"lemma" : "תֹּאַר",
		"xlit" : "tôʼar",
		"pron" : "to'-ar",
		"derivation" : "from H8388 (תָּאַר)",
		"def" : "outline, i.e. figure or appearance",
		"kjv" : "[phrase] beautiful, [idiom] comely, countenance, [phrase] fair, [idiom] favoured, form, [idiom] goodly, [idiom] resemble, visage"
	},
	"H8390" : {
		"lemma" : "תַּאֲרֵעַ",
		"xlit" : "Taʼărêaʻ",
		"pron" : "tah-ar-ay'-ah",
		"derivation" : "perhaps from H772 (אֲרַע)",
		"def" : "Taarea, an Israelite",
		"kjv" : "Tarea. See H8475 (תַּחְרֵעַ)"
	},
	"H8391" : {
		"lemma" : "תְּאַשּׁוּר",
		"xlit" : "tᵉʼashshûwr",
		"pron" : "teh-ash-shoor'",
		"derivation" : "from H833 (אָשַׁר)",
		"def" : "a species of cedar (from its erectness)",
		"kjv" : "box (tree)"
	},
	"H8392" : {
		"lemma" : "תֵּבָה",
		"xlit" : "têbâh",
		"pron" : "tay-baw'",
		"derivation" : "perhaps of foreign derivation",
		"def" : "a box",
		"kjv" : "ark"
	},
	"H8393" : {
		"lemma" : "תְּבוּאָה",
		"xlit" : "tᵉbûwʼâh",
		"pron" : "teb-oo-aw'",
		"derivation" : "from H935 (בּוֹא)",
		"def" : "income, i.e. produce (literally or figuratively)",
		"kjv" : "fruit, gain, increase, revenue"
	},
	"H8394" : {
		"lemma" : "תָּבוּן",
		"xlit" : "tâbûwn",
		"pron" : "taw-boon'",
		"derivation" : "and (feminine) תְּבוּנָה; or תּוֹבֻנָה; from H995 (בִּין)",
		"def" : "intelligence; by implication, an argument; by extension, caprice",
		"kjv" : "discretion, reason, skilfulness, understanding, wisdom"
	},
	"H8395" : {
		"lemma" : "תְּבוּסָה",
		"xlit" : "tᵉbûwçâh",
		"pron" : "teb-oo-saw'",
		"derivation" : "from H947 (בּוּס)",
		"def" : "a treading down, i.e. ruin",
		"kjv" : "destruction"
	},
	"H8396" : {
		"lemma" : "תָּבוֹר",
		"xlit" : "Tâbôwr",
		"pron" : "taw-bore'",
		"derivation" : "from a root corresponding to H8406 (תְּבַר); broken region",
		"def" : "Tabor, a mountain in Palestine, also a city adjacent",
		"kjv" : "Tabor"
	},
	"H8397" : {
		"lemma" : "תֶּבֶל",
		"xlit" : "tebel",
		"pron" : "teh'-bel",
		"derivation" : "apparently from H1101 (בָּלַל)",
		"def" : "mixture, i.e. unnatural bestiality",
		"kjv" : "confusion"
	},
	"H8398" : {
		"lemma" : "תֵּבֵל",
		"xlit" : "têbêl",
		"pron" : "tay-bale'",
		"derivation" : "from H2986 (יָבַל)",
		"def" : "the earth (as moist and therefore inhabited); by extension, the globe; by implication, its inhabitants; specifically, a particular land, as Babylonia, Palestine",
		"kjv" : "habitable part, world"
	},
	"H8399" : {
		"lemma" : "תַּבְלִית",
		"xlit" : "tablîyth",
		"pron" : "tab-leeth'",
		"derivation" : "from H1086 (בָּלָה)",
		"def" : "consumption",
		"kjv" : "destruction"
	},
	"H8400" : {
		"lemma" : "תְּבַּלֻּל",
		"xlit" : "tᵉballul",
		"pron" : "teb-al-lool'",
		"derivation" : "from H1101 (בָּלַל) in the original sense of flowing",
		"def" : "a cataract (in the eye)",
		"kjv" : "blemish"
	},
	"H8401" : {
		"lemma" : "תֶּבֶן",
		"xlit" : "teben",
		"pron" : "teh'-ben",
		"derivation" : "probably from H1129 (בָּנָה)",
		"def" : "properly, material, i.e. (specifically) refuse haum or stalks of grain (as chopped in threshing and used for fodder)",
		"kjv" : "chaff, straw, stubble"
	},
	"H8402" : {
		"lemma" : "תִּבְנִי",
		"xlit" : "Tibnîy",
		"pron" : "tib-nee'",
		"derivation" : "from H8401 (תֶּבֶן); strawy",
		"def" : "Tibni, an Israelite",
		"kjv" : "Tibni"
	},
	"H8403" : {
		"lemma" : "תַּבְנִית",
		"xlit" : "tabnîyth",
		"pron" : "tab-neeth'",
		"derivation" : "from H1129 (בָּנָה)",
		"def" : "structure; by implication, a model, resemblance",
		"kjv" : "figure, form, likeness, pattern, similitude"
	},
	"H8404" : {
		"lemma" : "תַּבְעֵרָה",
		"xlit" : "Tabʻêrâh",
		"pron" : "tab-ay-raw'",
		"derivation" : "from H1197 (בָּעַר); burning",
		"def" : "Taberah, a place in the Desert",
		"kjv" : "Taberah"
	},
	"H8405" : {
		"lemma" : "תֵּבֵץ",
		"xlit" : "Têbêts",
		"pron" : "tay-bates'",
		"derivation" : "from the same as H948 (בּוּץ); whiteness",
		"def" : "Tebets, a place in Palestine",
		"kjv" : "Thebez"
	},
	"H8406" : {
		"lemma" : "תְּבַר",
		"xlit" : "tᵉbar",
		"pron" : "teb-ar'",
		"derivation" : "(Aramaic) corresponding to H7665 (שָׁבַר)",
		"def" : "to be fragile (figuratively)",
		"kjv" : "broken"
	},
	"H8407" : {
		"lemma" : "תִּגְלַת פִּלְאֶסֶר",
		"xlit" : "Tiglath Pilʼeçer",
		"pron" : "tig-lath' pil-eh'-ser",
		"derivation" : "or תִּגְלַת פְּלֶסֶר; or תִּלְגַּת פִּלְנְאֶסֶר or תִּלְגַּת פִּלְנֶסֶר; of foreign derivation",
		"def" : "Tiglath-Pileser or Tilgath-pilneser, an Assyryrian king",
		"kjv" : "Tiglath-pileser, Tilgath-pilneser"
	},
	"H8408" : {
		"lemma" : "תַּגְמוּל",
		"xlit" : "tagmûwl",
		"pron" : "tag-mool'",
		"derivation" : "from H1580 (גָּמַל)",
		"def" : "a bestowment",
		"kjv" : "benefit"
	},
	"H8409" : {
		"lemma" : "תִּגְרָה",
		"xlit" : "tigrâh",
		"pron" : "tig-raw'",
		"derivation" : "from H1624 (גָּרָה)",
		"def" : "strife, i.e. infliction",
		"kjv" : "blow"
	},
	"H8410" : {
		"lemma" : "תִּדְהָר",
		"xlit" : "tidhâr",
		"pron" : "tid-hawr'",
		"derivation" : "apparently from H1725 (דָּהַר)",
		"def" : "enduring; a species of hard-wood or lasting tree (perhaps oak)",
		"kjv" : "pine (tree)"
	},
	"H8411" : {
		"lemma" : "תְּדִירָא",
		"xlit" : "tᵉdîyrâʼ",
		"pron" : "ted-ee-raw'",
		"derivation" : "(Aramaic) from H1753 (דּוּר) in the original sense of enduring",
		"def" : "permanence, i.e. (adverb) constantly",
		"kjv" : "continually"
	},
	"H8412" : {
		"lemma" : "תַּדְמֹר",
		"xlit" : "Tadmôr",
		"pron" : "tad-more'",
		"derivation" : "or תַּמֹּר; (1 Kings 9:18), apparently from H8558 (תָּמָר); palm-city",
		"def" : "Tadmor, a place near Palestine",
		"kjv" : "Tadmor"
	},
	"H8413" : {
		"lemma" : "תִּדְעָל",
		"xlit" : "Tidʻâl",
		"pron" : "tid-awl'",
		"derivation" : "perhaps from H1763 (דְּחַל); fearfulness",
		"def" : "Tidal, a Canaanite",
		"kjv" : "Tidal"
	},
	"H8414" : {
		"lemma" : "תֹּהוּ",
		"xlit" : "tôhûw",
		"pron" : "to'-hoo",
		"derivation" : "from an unused root meaning to lie waste",
		"def" : "a desolation (of surface), i.e. desert; figuratively, a worthless thing; adverbially, in vain",
		"kjv" : "confusion, empty place, without form, nothing, (thing of) nought, vain, vanity, waste, wilderness"
	},
	"H8415" : {
		"lemma" : "תְּהוֹם",
		"xlit" : "tᵉhôwm",
		"pron" : "teh-home'",
		"derivation" : "or תְּהֹם; (usually feminine) from H1949 (הוּם)",
		"def" : "an abyss (as a surging mass of water), especially the deep (the main sea or the subterranean watersupply)",
		"kjv" : "deep (place), depth"
	},
	"H8416" : {
		"lemma" : "תְּהִלָּה",
		"xlit" : "tᵉhillâh",
		"pron" : "teh-hil-law'",
		"derivation" : "from H1984 (הָלַל)",
		"def" : "laudation; specifically (concretely) a hymn",
		"kjv" : "praise"
	},
	"H8417" : {
		"lemma" : "תׇּהֳלָה",
		"xlit" : "tohŏlâh",
		"pron" : "to-hol-aw'",
		"derivation" : "feminine of an unused noun (apparently from H1984 (הָלַל)) meaning bluster",
		"def" : "braggadocio, i.e. (by implication) fatuity",
		"kjv" : "folly"
	},
	"H8418" : {
		"lemma" : "תַּהֲלֻכָה",
		"xlit" : "tahălukâh",
		"pron" : "tah-hal-oo-kaw'",
		"derivation" : "from H1980 (הָלַךְ)",
		"def" : "a procession",
		"kjv" : "[idiom] went"
	},
	"H8419" : {
		"lemma" : "תַּהְפֻּכָה",
		"xlit" : "tahpukâh",
		"pron" : "tah-poo-kaw'",
		"derivation" : "from H2015 (הָפַךְ)",
		"def" : "a perversity or fraud",
		"kjv" : "(very) froward(-ness, thing), perverse thing"
	},
	"H8420" : {
		"lemma" : "תָּו",
		"xlit" : "tâv",
		"pron" : "tawv",
		"derivation" : "from H8427 (תָּוָה)",
		"def" : "a mark; by implication, a signature",
		"kjv" : "desire, mark"
	},
	"H8421" : {
		"lemma" : "תּוּב",
		"xlit" : "tûwb",
		"pron" : "toob",
		"derivation" : "(Aramaic) corresponding to H7725 (שׁוּב), to come back",
		"def" : "specifically (transitive and ellip.) to reply",
		"kjv" : "answer, restore, return (an answer)"
	},
	"H8422" : {
		"lemma" : "תּוּבַל",
		"xlit" : "Tûwbal",
		"pron" : "too-bal'",
		"derivation" : "or תֻּבַל; probably of foreign derivation",
		"def" : "Tubal, a postdiluvian patriarch and his posterity",
		"kjv" : "Tubal"
	},
	"H8423" : {
		"lemma" : "תּוּבַל קַיִן",
		"xlit" : "Tûwbal Qayin",
		"pron" : "too-bal' kah'-yin",
		"derivation" : "apparently from H2986 (יָבַל) (compare H2981 (יְבוּל)) and H7014 (קַיִן); offspring of Cain",
		"def" : "Tubal-Kajin, an antidiluvian patriarch",
		"kjv" : "Tubal-cain"
	},
	"H8424" : {
		"lemma" : "תּוּגָה",
		"xlit" : "tûwgâh",
		"pron" : "too-gaw'",
		"derivation" : "from H3013 (יָגָה)",
		"def" : "depression (of spirits); concretely a grief",
		"kjv" : "heaviness, sorrow"
	},
	"H8425" : {
		"lemma" : "תּוֹגַרְמָה",
		"xlit" : "Tôwgarmâh",
		"pron" : "to-gar-maw'",
		"derivation" : "or תֹּגַרְמָה; probably of foreign derivation",
		"def" : "Togarmah, a son of Gomer and his posterity",
		"kjv" : "Togarmah"
	},
	"H8426" : {
		"lemma" : "תּוֹדָה",
		"xlit" : "tôwdâh",
		"pron" : "to-daw'",
		"derivation" : "from H3034 (יָדָה)",
		"def" : "properly, an extension of the hand, i.e. (by implication) avowal, or (usually) adoration; specifically, a choir of worshippers",
		"kjv" : "confession, (sacrifice of) praise, thanks(-giving, offering)"
	},
	"H8427" : {
		"lemma" : "תָּוָה",
		"xlit" : "tâvâh",
		"pron" : "taw-vaw'",
		"derivation" : "a primitive root",
		"def" : "to mark out, i.e. (primitive) scratch or (definite) imprint",
		"kjv" : "scrabble, set (a mark)"
	},
	"H8428" : {
		"lemma" : "תָּוָה",
		"xlit" : "tâvâh",
		"pron" : "taw-vaw'",
		"derivation" : "a primitive root (or perhaps identical with H8427 (תָּוָה) through a similar idea from scraping to pieces)",
		"def" : "to grieve",
		"kjv" : "limit (by confusion with H8427 (תָּוָה))"
	},
	"H8429" : {
		"lemma" : "תְּוַהּ",
		"xlit" : "tᵉvahh",
		"pron" : "tev-ah'",
		"derivation" : "(Aramaic) corresponding to H8539 (תָּמַהּ) or perhaps to H7582 (שָׁאָה) through the idea of sweeping to ruin (compare H8428 (תָּוָה))",
		"def" : "to amaze, i.e. (reflex. by implication) take alarm",
		"kjv" : "be astonied"
	},
	"H8430" : {
		"lemma" : "תּוֹחַ",
		"xlit" : "Tôwach",
		"pron" : "to'-akh",
		"derivation" : "from an unused root meaning to depress; humble",
		"def" : "Toach, an Israelite",
		"kjv" : "Toah"
	},
	"H8431" : {
		"lemma" : "תּוֹחֶלֶת",
		"xlit" : "tôwcheleth",
		"pron" : "to-kheh'-leth",
		"derivation" : "from H3176 (יָחַל)",
		"def" : "expectation",
		"kjv" : "hope"
	},
	"H8432" : {
		"lemma" : "תָּוֶךְ",
		"xlit" : "tâvek",
		"pron" : "taw'-vek",
		"derivation" : "from an unused root meaning to sever",
		"def" : "a bisection, i.e. (by implication) the centre",
		"kjv" : "among(-st), [idiom] between, half, [idiom] (there-, where-), in(-to), middle, mid(-night), midst (among), [idiom] out (of), [idiom] through, [idiom] with(-in)"
	},
	"H8433" : {
		"lemma" : "תּוֹכֵחָה",
		"xlit" : "tôwkêchâh",
		"pron" : "to-kay-khaw'",
		"derivation" : "and תּוֹכַחַת; from H3198 (יָכַח)",
		"def" : "chastisement; figuratively (by words) correction, refutation, proof (even in defence)",
		"kjv" : "argument, [idiom] chastened, correction, reasoning, rebuke, reproof, [idiom] be (often) reproved"
	},
	"H8434" : {
		"lemma" : "תּוֹלָד",
		"xlit" : "Tôwlâd",
		"pron" : "to-lawd'",
		"derivation" : "from H3205 (יָלַד); posterity",
		"def" : "Tolad, a place in Palestine",
		"kjv" : "Tolad. Compare H513 (אֶלְתּוֹלַד)"
	},
	"H8435" : {
		"lemma" : "תּוֹלְדָה",
		"xlit" : "tôwlᵉdâh",
		"pron" : "to-led-aw'",
		"derivation" : "or תֹּלְדָה; from H3205 (יָלַד)",
		"def" : "(plural only) descent, i.e. family; (figuratively) history",
		"kjv" : "birth, generations"
	},
	"H8436" : {
		"lemma" : "תּוּלוֹן",
		"xlit" : "Tûwlôwn",
		"pron" : "too-lone'",
		"derivation" : "from H8524 (תָּלַל); suspension",
		"def" : "Tulon, an Israelite",
		"kjv" : "Tilon (from the margin)"
	},
	"H8437" : {
		"lemma" : "תּוֹלָל",
		"xlit" : "tôwlâl",
		"pron" : "to-lawl'",
		"derivation" : "from H3213 (יָלַל)",
		"def" : "causing to howl, i.e. an oppressor",
		"kjv" : "that wasted"
	},
	"H8438" : {
		"lemma" : "תּוֹלָע",
		"xlit" : "tôwlâʻ",
		"pron" : "to-law'",
		"derivation" : "and (feminine) תּוֹלֵעָה; or תּוֹלַעַת; or תֹּלַעַת; from H3216 (יָלַע); a maggot (as voracious); specifically (often with ellipsis of H8144 (שָׁנִי))",
		"def" : "the crimson-grub, but used only (in this connection) of the colorfrom it, and cloths dyed therewith",
		"kjv" : "crimson, scarlet, worm"
	},
	"H8439" : {
		"lemma" : "תּוֹלָע",
		"xlit" : "Tôwlâʻ",
		"pron" : "to-law'",
		"derivation" : "the same as H8438 (תּוֹלָע); worm",
		"def" : "Tola, the name of two Israelites",
		"kjv" : "Tola"
	},
	"H8440" : {
		"lemma" : "תּוֹלָעִי",
		"xlit" : "Tôwlâʻîy",
		"pron" : "to-law-ee'",
		"derivation" : "patronymically from H8439 (תּוֹלָע)",
		"def" : "a Tolaite (collectively) or descendants of Tola",
		"kjv" : "Tolaites"
	},
	"H8441" : {
		"lemma" : "תּוֹעֵבַה",
		"xlit" : "tôwʻêbah",
		"pron" : "to-ay-baw'",
		"derivation" : "or תֹּעֵבַה; feminine active participle of H8581 (תַּעָב)",
		"def" : "properly, something disgusting (morally), i.e. (as noun) an abhorrence; especially idolatry or (concretely) an idol",
		"kjv" : "abominable (custom, thing), abomination"
	},
	"H8442" : {
		"lemma" : "תּוֹעָה",
		"xlit" : "tôwʻâh",
		"pron" : "to-aw'",
		"derivation" : "feminine active participle of H8582 (תָּעָה)",
		"def" : "mistake, i.e. (morally) impiety, or (political) injury",
		"kjv" : "error, hinder"
	},
	"H8443" : {
		"lemma" : "תּוֹעָפָה",
		"xlit" : "tôwʻâphâh",
		"pron" : "to-aw-faw'",
		"derivation" : "from H3286 (יָעַף)",
		"def" : "(only in plural collective) weariness, i.e. (by implication) toil (treasure so obtained) or speed",
		"kjv" : "plenty, strength"
	},
	"H8444" : {
		"lemma" : "תּוֹצָאָה",
		"xlit" : "tôwtsâʼâh",
		"pron" : "to-tsaw-aw'",
		"derivation" : "or תֹּצָאָה; from H3318 (יָצָא)",
		"def" : "(only in plural collective) exit, i.e. (geographical) boundary, or (figuratively) deliverance, (actively) source",
		"kjv" : "border(-s), going(-s) forth (out), issues, outgoings"
	},
	"H8445" : {
		"lemma" : "תּוֹקַהַת",
		"xlit" : "Tôwqahath",
		"pron" : "to-kah'-ath",
		"derivation" : "from the same as H3349 (יִקָּהָה); obedience",
		"def" : "Tokahath, an Israelite",
		"kjv" : "Tikvath (by correction for H8616 (תִּקְוָה))"
	},
	"H8446" : {
		"lemma" : "תּוּר",
		"xlit" : "tûwr",
		"pron" : "toor",
		"derivation" : "a primitive root",
		"def" : "to meander (causatively, guide) about, especially fortrade or reconnoitring",
		"kjv" : "chap(-man), sent to descry, be excellent, merchant(-man), search (out), seek, (e-) spy (out)"
	},
	"H8447" : {
		"lemma" : "תּוֹר",
		"xlit" : "tôwr",
		"pron" : "tore",
		"derivation" : "or תֹּר; from H8446 (תּוּר)",
		"def" : "a succession, i.e. a string or (abstractly) order",
		"kjv" : "border, row, turn"
	},
	"H8448" : {
		"lemma" : "תּוֹר",
		"xlit" : "tôwr",
		"pron" : "tore",
		"derivation" : "probably the same as H8447 (תּוֹר)",
		"def" : "a manner (as a sort of turn)",
		"kjv" : "estate"
	},
	"H8449" : {
		"lemma" : "תּוֹר",
		"xlit" : "tôwr",
		"pron" : "tore",
		"derivation" : "or תֹּר; probably the same as H8447 (תּוֹר)",
		"def" : "a ring-dove, often (figuratively) as a term of endearment",
		"kjv" : "(turtle) dove"
	},
	"H8450" : {
		"lemma" : "תּוֹר",
		"xlit" : "tôwr",
		"pron" : "tore",
		"derivation" : "(Aramaic) corresponding (by permutation) to H7794 (שׁוֹר)",
		"def" : "a bull",
		"kjv" : "bullock, ox"
	},
	"H8451" : {
		"lemma" : "תּוֹרָה",
		"xlit" : "tôwrâh",
		"pron" : "to-raw'",
		"derivation" : "or תֹּרָה; from H3384 (יָרָה)",
		"def" : "a precept or statute, especially the Decalogue or Pentateuch",
		"kjv" : "law"
	},
	"H8452" : {
		"lemma" : "תּוֹרָה",
		"xlit" : "tôwrâh",
		"pron" : "to-raw'",
		"derivation" : "probably feminine of H8448 (תּוֹר)",
		"def" : "a custom",
		"kjv" : "manner"
	},
	"H8453" : {
		"lemma" : "תּוֹשָׁב",
		"xlit" : "tôwshâb",
		"pron" : "to-shawb'",
		"derivation" : "or תֹּשָׁב; (1 Kings 17:1), from H3427 (יָשַׁב); a dweller (but not outlandish (H5237 (נׇכְרִי))); especially (as distinguished from a native citizen (active participle of H3427 (יָשַׁב)) and a temporary inmate (H1616 (גֵּר)) or mere lodger (H3885 (לוּן)))",
		"def" : "resident alien",
		"kjv" : "foreigner, inhabitant, sojourner, stranger"
	},
	"H8454" : {
		"lemma" : "תּוּשִׁיָּה",
		"xlit" : "tûwshîyâh",
		"pron" : "too-shee-yaw'",
		"derivation" : "or תֻּשִׁיָּה; from an unused root probably meaning to substantiate",
		"def" : "support or (by implication) ability, i.e. (direct) help, (in purpose) an undertaking, (intellectual) understanding",
		"kjv" : "enterprise, that which (thing as it) is, substance, (sound) wisdom, working"
	},
	"H8455" : {
		"lemma" : "תּוֹתָח",
		"xlit" : "tôwthâch",
		"pron" : "to-thawkh'",
		"derivation" : "from an unused root meaning to smite",
		"def" : "a club",
		"kjv" : "darts"
	},
	"H8456" : {
		"lemma" : "תָּזַז",
		"xlit" : "tâzaz",
		"pron" : "taw-zaz'",
		"derivation" : "a primitive root",
		"def" : "to lop off",
		"kjv" : "cut down"
	},
	"H8457" : {
		"lemma" : "תַּזְנוּת",
		"xlit" : "taznûwth",
		"pron" : "taz-nooth'",
		"derivation" : "or תַּזְנֻת; from H2181 (זָנָה)",
		"def" : "harlotry, i.e. (figuratively) idolatry",
		"kjv" : "fornication, whoredom"
	},
	"H8458" : {
		"lemma" : "תַּחְבֻּלָה",
		"xlit" : "tachbulâh",
		"pron" : "takh-boo-law'",
		"derivation" : "or תַּחְבּוּלָה; from H2254 (חָבַל) as denominative from H2256 (חֶבֶל)",
		"def" : "(only in plural) properly, steerage (as a management of ropes), i.e. (figuratively) guidance or (by implication) a plan",
		"kjv" : "good advice, (wise) counsels"
	},
	"H8459" : {
		"lemma" : "תֹּחוּ",
		"xlit" : "Tôchûw",
		"pron" : "to'-khoo",
		"derivation" : "from an unused root meaning to depress; abasement",
		"def" : "Tochu, an Israelite",
		"kjv" : "Tohu"
	},
	"H8460" : {
		"lemma" : "תְּחוֹת",
		"xlit" : "tᵉchôwth",
		"pron" : "tekh-oth'",
		"derivation" : "(Aramaic) or תּחֹת; (Aramaic), corresponding to H8478 (תַּחַת)",
		"def" : "beneath",
		"kjv" : "under"
	},
	"H8461" : {
		"lemma" : "תַּחְכְּמֹנִי",
		"xlit" : "Tachkᵉmônîy",
		"pron" : "takh-kem-o-nee'",
		"derivation" : "probably for H2453 (חַכְמוֹנִי); sagacious",
		"def" : "Tachkemoni, an Israelite",
		"kjv" : "Tachmonite"
	},
	"H8462" : {
		"lemma" : "תְּחִלָּה",
		"xlit" : "tᵉchillâh",
		"pron" : "tekh-il-law'",
		"derivation" : "from H2490 (חָלַל) in the sense of opening",
		"def" : "a commencement; rel. original (adverb, -ly)",
		"kjv" : "begin(-ning), first (time)"
	},
	"H8463" : {
		"lemma" : "תַּחֲלוּא",
		"xlit" : "tachălûwʼ",
		"pron" : "takh-al-oo'",
		"derivation" : "or תַּחֲלֻא; from H2456 (חָלָא)",
		"def" : "a malady",
		"kjv" : "disease, [idiom] grievous, (that are) sick(-ness)"
	},
	"H8464" : {
		"lemma" : "תַּחְמָס",
		"xlit" : "tachmâç",
		"pron" : "takh-mawce'",
		"derivation" : "from H2554 (חָמַס)",
		"def" : "a species of unclean bird (from its violence), perhaps an owl",
		"kjv" : "night hawk"
	},
	"H8465" : {
		"lemma" : "תַּחַן",
		"xlit" : "Tachan",
		"pron" : "takh'-an",
		"derivation" : "probably from H2583 (חָנָה); station",
		"def" : "Tachan, the name of two Israelites",
		"kjv" : "Tahan"
	},
	"H8466" : {
		"lemma" : "תַּחֲנָה",
		"xlit" : "tachănâh",
		"pron" : "takh-an-aw'",
		"derivation" : "from H2583 (חָנָה)",
		"def" : "(only plural collectively) an encampment",
		"kjv" : "camp"
	},
	"H8467" : {
		"lemma" : "תְּחִנָּה",
		"xlit" : "tᵉchinnâh",
		"pron" : "tekh-in-naw'",
		"derivation" : "from H2603 (חָנַן)",
		"def" : "graciousness; causatively, entreaty",
		"kjv" : "favour, grace, supplication"
	},
	"H8468" : {
		"lemma" : "תְּחִנָּה",
		"xlit" : "Tᵉchinnâh",
		"pron" : "tekh-in-naw'",
		"derivation" : "the same as H8467 (תְּחִנָּה)",
		"def" : "Techinnah, an Israelite",
		"kjv" : "Tehinnah"
	},
	"H8469" : {
		"lemma" : "תַּחֲנוּן",
		"xlit" : "tachănûwn",
		"pron" : "takh-an-oon'",
		"derivation" : "or (feminine) תַּחֲנוּנָה; from H2603 (חָנַן)",
		"def" : "earnest prayer",
		"kjv" : "intreaty, supplication"
	},
	"H8470" : {
		"lemma" : "תַּחֲנִי",
		"xlit" : "Tachănîy",
		"pron" : "takh-an-ee'",
		"derivation" : "patronymically from H8465 (תַּחַן)",
		"def" : "a Tachanite (collectively) or descendants of Tachan",
		"kjv" : "Tahanites"
	},
	"H8471" : {
		"lemma" : "תַּחְפַּנְחֵס",
		"xlit" : "Tachpanchêç",
		"pron" : "takh-pan-khace'",
		"derivation" : "or תְּחַפְנְחֵס; (Ezekiel 30:18), or תַּחְפְּנֵס; (Jeremiah 2:16), of Egyptian derivation",
		"def" : "Tachpanches, Techaphneches or Tachpenes, a place in Egypt",
		"kjv" : "Tahapanes, Tahpanhes, Tehaphnehes"
	},
	"H8472" : {
		"lemma" : "תַּחְפְּנֵיס",
		"xlit" : "Tachpᵉnêyç",
		"pron" : "takh-pen-ace'",
		"derivation" : "of Egyptian derivation",
		"def" : "Tachpenes, an Egyptian woman",
		"kjv" : "Tahpenes"
	},
	"H8473" : {
		"lemma" : "תַּחֲרָא",
		"xlit" : "tachărâʼ",
		"pron" : "takh-ar-aw'",
		"derivation" : "from H2734 (חָרָה) in the original sense of H2352 (חוּר) or H2353 (חוּר)",
		"def" : "a linen corslet (as white or hollow)",
		"kjv" : "habergeon"
	},
	"H8474" : {
		"lemma" : "תַּחָרָה",
		"xlit" : "tachârâh",
		"pron" : "takh-aw-raw'",
		"derivation" : "a facitious root from H2734 (חָרָה) through the idea of the heat of jealousy",
		"def" : "to vie with a rival",
		"kjv" : "close, contend"
	},
	"H8475" : {
		"lemma" : "תַּחְרֵעַ",
		"xlit" : "Tachrêaʻ",
		"pron" : "takh-ray'-ah",
		"derivation" : "for H8390 (תַּאֲרֵעַ)",
		"def" : "Tachrea, an Israelite",
		"kjv" : "Tahrea"
	},
	"H8476" : {
		"lemma" : "תַּחַשׁ",
		"xlit" : "tachash",
		"pron" : "takh'-ash",
		"derivation" : "probably of foreign derivation",
		"def" : "a (clean) animal with fur, probably a species of antelope",
		"kjv" : "badger"
	},
	"H8477" : {
		"lemma" : "תַּחַשׁ",
		"xlit" : "Tachash",
		"pron" : "takh'-ash",
		"derivation" : "the same as H8476 (תַּחַשׁ)",
		"def" : "Tachash, a relative of Abraham",
		"kjv" : "Thahash"
	},
	"H8478" : {
		"lemma" : "תַּחַת",
		"xlit" : "tachath",
		"pron" : "takh'-ath",
		"derivation" : "from the same as H8430 (תּוֹחַ)",
		"def" : "the bottom (as depressed); only adverbially, below (often with prepositional prefix underneath), in lieu of, etc.",
		"kjv" : "as, beneath, [idiom] flat, in(-stead), (same) place (where...is), room, for...sake, stead of, under, [idiom] unto, [idiom] when...was mine, whereas, (where-) fore, with"
	},
	"H8479" : {
		"lemma" : "תַּחַת",
		"xlit" : "tachath",
		"pron" : "takh'-ath",
		"derivation" : "(Aramaic) corresponding to H8478 (תַּחַת)",
		"def" : "{the bottom (as depressed); only adverbially, below (often with prepositional prefix underneath), in lieu of, etc.}",
		"kjv" : "under"
	},
	"H8480" : {
		"lemma" : "תַּחַת",
		"xlit" : "Tachath",
		"pron" : "takh'-ath",
		"derivation" : "the same as H8478 (תַּחַת)",
		"def" : "Tachath, the name of a place in the Desert, also of three Israelites",
		"kjv" : "Tahath"
	},
	"H8481" : {
		"lemma" : "תַּחְתּוֹן",
		"xlit" : "tachtôwn",
		"pron" : "takh-tone'",
		"derivation" : "or תַּחְתֹּן; from H8478 (תַּחַת)",
		"def" : "bottommost",
		"kjv" : "lower(-est), nether(-most)"
	},
	"H8482" : {
		"lemma" : "תַּחְתִּי",
		"xlit" : "tachtîy",
		"pron" : "takh-tee'",
		"derivation" : "from H8478 (תַּחַת)",
		"def" : "lowermost; as noun (feminine plural) the depths (figuratively, a pit, the womb)",
		"kjv" : "low (parts, -er, -er parts, -est), nether (part)"
	},
	"H8483" : {
		"lemma" : "תַּחְתִּים חׇדְשִׁי",
		"xlit" : "Tachtîym Chodshîy",
		"pron" : "takh-teem' khod-shee'",
		"derivation" : "apparently from the plural masculine of H8482 (תַּחְתִּי) or H8478 (תַּחַת) and H2320 (חֹדֶשׁ); lower (ones) monthly",
		"def" : "Tachtim-Chodshi, a place in Palestine",
		"kjv" : "Tahtim-hodshi"
	},
	"H8484" : {
		"lemma" : "תִּיכוֹן",
		"xlit" : "tîykôwn",
		"pron" : "tee-kone'",
		"derivation" : "or תִּיכֹן; from H8432 (תָּוֶךְ)",
		"def" : "central",
		"kjv" : "middle(-most), midst"
	},
	"H8485" : {
		"lemma" : "תֵּימָא",
		"xlit" : "Têymâʼ",
		"pron" : "tay-maw'",
		"derivation" : "or תֵּמָא; probably of foreign derivation",
		"def" : "Tema, a son of Ishmael, and the region settled by him",
		"kjv" : "Tema"
	},
	"H8486" : {
		"lemma" : "תֵּימָן",
		"xlit" : "têymân",
		"pron" : "tay-mawn'",
		"derivation" : "or תֵּמָן; denominative from H3225 (יָמִין)",
		"def" : "the south (as being on the right hand of a person facing the east)",
		"kjv" : "south (side, -ward, wind)"
	},
	"H8487" : {
		"lemma" : "תֵּימָן",
		"xlit" : "Têymân",
		"pron" : "tay-mawn'",
		"derivation" : "or תֵּמָן; the same as H8486 (תֵּימָן)",
		"def" : "Teman, the name of two Edomites, and of the region and descendant of one of them",
		"kjv" : "south, Teman"
	},
	"H8488" : {
		"lemma" : "תֵּימְנִי",
		"xlit" : "Têymᵉnîy",
		"pron" : "tay-men-ee'",
		"derivation" : "probably for H8489 (תֵּימָנִי)",
		"def" : "Temeni, an Israelite",
		"kjv" : "Temeni"
	},
	"H8489" : {
		"lemma" : "תֵּימָנִי",
		"xlit" : "Têymânîy",
		"pron" : "tay-maw-nee'",
		"derivation" : "patronymically from H8487 (תֵּימָן)",
		"def" : "a Temanite or descendant of Teman",
		"kjv" : "Temani, Temanite"
	},
	"H8490" : {
		"lemma" : "תִּימָרָה",
		"xlit" : "tîymârâh",
		"pron" : "tee-maw-raw'",
		"derivation" : "or תִּמָרָה; from the same as H8558 (תָּמָר)",
		"def" : "a column, i.e. cloud",
		"kjv" : "pillar"
	},
	"H8491" : {
		"lemma" : "תִּיצִי",
		"xlit" : "Tîytsîy",
		"pron" : "tee-tsee'",
		"derivation" : "patrial or patronymically from an unused noun of uncertain meaning",
		"def" : "a Titsite or descendant or inhabitant of an unknown Tits",
		"kjv" : "Tizite"
	},
	"H8492" : {
		"lemma" : "תִּירוֹשׁ",
		"xlit" : "tîyrôwsh",
		"pron" : "tee-roshe'",
		"derivation" : "or תִּירֹשׁ; from H3423 (יָרַשׁ) in the sense of expulsion",
		"def" : "must or fresh grape-juice (as just squeezed out); by implication (rarely) fermented wine",
		"kjv" : "(new, sweet) wine"
	},
	"H8493" : {
		"lemma" : "תִּירְיָא",
		"xlit" : "Tîyrᵉyâʼ",
		"pron" : "tee-reh-yaw'",
		"derivation" : "probably from H3372 (יָרֵא)",
		"def" : "fearful, Tirja, an Israelite",
		"kjv" : "Tiria"
	},
	"H8494" : {
		"lemma" : "תִּירָס",
		"xlit" : "Tîyrâç",
		"pron" : "tee-rawce'",
		"derivation" : "probably of foreign derivation",
		"def" : "Tiras, a son of Japheth",
		"kjv" : "Tiras"
	},
	"H8495" : {
		"lemma" : "תַּיִשׁ",
		"xlit" : "tayish",
		"pron" : "tah'-yeesh",
		"derivation" : "from an unused root meaning to butt",
		"def" : "a buck or he-goat (as given to butting)",
		"kjv" : "he goat"
	},
	"H8496" : {
		"lemma" : "תֹּךְ",
		"xlit" : "tôk",
		"pron" : "toke",
		"derivation" : "or תּוֹך; (Psalm 72:14), from the same base as H8432 (תָּוֶךְ) (in the sense of cutting to pieces)",
		"def" : "oppression",
		"kjv" : "deceit, fraud"
	},
	"H8497" : {
		"lemma" : "תָּכָה",
		"xlit" : "tâkâh",
		"pron" : "taw-kaw'",
		"derivation" : "a primitive root",
		"def" : "to strew, i.e. encamp",
		"kjv" : "sit down"
	},
	"H8498" : {
		"lemma" : "תְּכוּנָה",
		"xlit" : "tᵉkûwnâh",
		"pron" : "tek-oo-naw'",
		"derivation" : "feminine passive participle of H8505 (תָּכַן)",
		"def" : "adjustment, i.e. structure; by implication, equipage",
		"kjv" : "fashion, store"
	},
	"H8499" : {
		"lemma" : "תְּכוּנָה",
		"xlit" : "tᵉkûwnâh",
		"pron" : "tek-oo-naw'",
		"derivation" : "from H3559 (כּוּן); or probably identical with H8498 (תְּכוּנָה)",
		"def" : "something arranged or fixed, i.e. a place",
		"kjv" : "seat"
	},
	"H8500" : {
		"lemma" : "תֻּכִּי",
		"xlit" : "tukkîy",
		"pron" : "took-kee'",
		"derivation" : "or תּוּכִּי",
		"def" : "probably of foreign derivation; some imported creature, probably a peacock",
		"kjv" : "peacock"
	},
	"H8501" : {
		"lemma" : "תָּכָךְ",
		"xlit" : "tâkâk",
		"pron" : "taw-kawk'",
		"derivation" : "from an unused root meaning",
		"def" : "to dissever, i.e. crush",
		"kjv" : "deceitful"
	},
	"H8502" : {
		"lemma" : "תִּכְלָה",
		"xlit" : "tiklâh",
		"pron" : "tik-law'",
		"derivation" : "from H3615 (כָּלָה)",
		"def" : "completeness",
		"kjv" : "perfection"
	},
	"H8503" : {
		"lemma" : "תַּכְלִית",
		"xlit" : "taklîyth",
		"pron" : "tak-leeth'",
		"derivation" : "from H3615 (כָּלָה)",
		"def" : "completion; by implication, an extremity",
		"kjv" : "end, perfect(-ion)"
	},
	"H8504" : {
		"lemma" : "תְּכֵלֶת",
		"xlit" : "tᵉkêleth",
		"pron" : "tek-ay'-leth",
		"derivation" : "probably for H7827 (שְׁחֶלֶת)",
		"def" : "the cerulean mussel, i.e. the color (violet) obtained therefrom or stuff dyed therewith",
		"kjv" : "blue"
	},
	"H8505" : {
		"lemma" : "תָּכַן",
		"xlit" : "tâkan",
		"pron" : "taw-kan'",
		"derivation" : "a primitive root",
		"def" : "to balance, i.e. measure out (by weight or dimension); figuratively, arrange, equalize, through the idea of levelling (ment. estimate, test)",
		"kjv" : "bear up, direct, be (un-)equal, mete, ponder, tell, weigh"
	},
	"H8506" : {
		"lemma" : "תֹּכֶן",
		"xlit" : "tôken",
		"pron" : "to'-ken",
		"derivation" : "from H8505 (תָּכַן)",
		"def" : "a fixed quantity",
		"kjv" : "measure, tale"
	},
	"H8507" : {
		"lemma" : "תֹּכֶן",
		"xlit" : "Tôken",
		"pron" : "to'-ken",
		"derivation" : "the same as H8506 (תֹּכֶן)",
		"def" : "Token, a place in Palestine",
		"kjv" : "Tochen"
	},
	"H8508" : {
		"lemma" : "תׇּכְנִית",
		"xlit" : "toknîyth",
		"pron" : "tok-neeth'",
		"derivation" : "from H8506 (תֹּכֶן)",
		"def" : "admeasurement, i.e. consummation",
		"kjv" : "pattern, sum"
	},
	"H8509" : {
		"lemma" : "תַּכְרִיךְ",
		"xlit" : "takrîyk",
		"pron" : "tak-reek'",
		"derivation" : "apparently from an unused root meaning to encompass",
		"def" : "a wrapper or robe",
		"kjv" : "garment"
	},
	"H8510" : {
		"lemma" : "תֵּל",
		"xlit" : "têl",
		"pron" : "tale",
		"derivation" : "by contraction from H8524 (תָּלַל)",
		"def" : "a mound",
		"kjv" : "heap, [idiom] strength"
	},
	"H8511" : {
		"lemma" : "תָּלָא",
		"xlit" : "tâlâʼ",
		"pron" : "taw-law'",
		"derivation" : "a primitive root",
		"def" : "to suspend; figuratively (through hesitation) to be uncertain; by implication (of mental dependence) to habituate",
		"kjv" : "be bent, hang (in doubt)"
	},
	"H8512" : {
		"lemma" : "תֵּל אָבִיב",
		"xlit" : "Têl ʼÂbîyb",
		"pron" : "tale aw-beeb'",
		"derivation" : "from H8510 (תֵּל) and H24 (אָבִיב); mound of green growth",
		"def" : "Tel-Abib, a place in Chaldaea",
		"kjv" : "Tel-abib"
	},
	"H8513" : {
		"lemma" : "תְּלָאָה",
		"xlit" : "tᵉlâʼâh",
		"pron" : "tel-aw-aw'",
		"derivation" : "from H3811 (לָאָה)",
		"def" : "distress",
		"kjv" : "travail, travel, trouble"
	},
	"H8514" : {
		"lemma" : "תַּלְאוּבָה",
		"xlit" : "talʼûwbâh",
		"pron" : "tal-oo-baw'",
		"derivation" : "from H3851 (לַהַב)",
		"def" : "desiccation",
		"kjv" : "great drought"
	},
	"H8515" : {
		"lemma" : "תְּלַאשַּׂר",
		"xlit" : "Tᵉlaʼssar",
		"pron" : "tel-as-sar'",
		"derivation" : "or תְּלַשַּׂר; of foreign derivation",
		"def" : "Telassar, a region of Assyria",
		"kjv" : "Telassar"
	},
	"H8516" : {
		"lemma" : "תַּלְבֹּשֶׁת",
		"xlit" : "talbôsheth",
		"pron" : "tal-bo'-sheth",
		"derivation" : "from H3847 (לָבַשׁ)",
		"def" : "a garment",
		"kjv" : "clothing"
	},
	"H8517" : {
		"lemma" : "תְּלַג",
		"xlit" : "tᵉlag",
		"pron" : "tel-ag'",
		"derivation" : "(Aramaic) corresponding to H7950 (שֶׁלֶג)",
		"def" : "snow",
		"kjv" : "snow"
	},
	"H8518" : {
		"lemma" : "תָּלָה",
		"xlit" : "tâlâh",
		"pron" : "taw-law'",
		"derivation" : "a primitive root",
		"def" : "to suspend (especially to gibbet)",
		"kjv" : "hang (up)"
	},
	"H8519" : {
		"lemma" : "תְּלוּנָה",
		"xlit" : "tᵉlûwnâh",
		"pron" : "tel-oo-naw'",
		"derivation" : "or תּלֻנָּה; from H3885 (לוּן) in the sense of obstinacy",
		"def" : "a grumbling",
		"kjv" : "murmuring"
	},
	"H8520" : {
		"lemma" : "תֶּלַח",
		"xlit" : "Telach",
		"pron" : "teh'-lakh",
		"derivation" : "probably from an unused root meaning to dissever; breach",
		"def" : "Telach, an Israelite",
		"kjv" : "Telah"
	},
	"H8521" : {
		"lemma" : "תֵּל חַרְשָׁא",
		"xlit" : "Têl Charshâʼ",
		"pron" : "tale khar-shaw'",
		"derivation" : "from H8510 (תֵּל) and the feminine of H2798 (חֲרָשִׁים); mound of workmanship",
		"def" : "Tel-Charsha, a place in Babylonia",
		"kjv" : "Tel-haresha, Tel-harsa"
	},
	"H8522" : {
		"lemma" : "תְּלִי",
		"xlit" : "tᵉlîy",
		"pron" : "tel-ee'",
		"derivation" : "probably from H8518 (תָּלָה)",
		"def" : "a quiver (as slung)",
		"kjv" : "quiver"
	},
	"H8523" : {
		"lemma" : "תְּלִיתַי",
		"xlit" : "tᵉlîythay",
		"pron" : "tel-ee-thah'-ee",
		"derivation" : "(Aramaic) or תַּלְתִּי; (Aramaic), ordinal from H8532 (תְּלָת)",
		"def" : "third",
		"kjv" : "third"
	},
	"H8524" : {
		"lemma" : "תָּלַל",
		"xlit" : "tâlal",
		"pron" : "taw-lal'",
		"derivation" : "a primitive root",
		"def" : "to pile up, i.e. elevate",
		"kjv" : "eminent. Compare H2048 (הָתַל)"
	},
	"H8525" : {
		"lemma" : "תֶּלֶם",
		"xlit" : "telem",
		"pron" : "teh'-lem",
		"derivation" : "from an unused root meaning to accumulate",
		"def" : "a bank or terrace",
		"kjv" : "furrow, ridge"
	},
	"H8526" : {
		"lemma" : "תַּלְמַי",
		"xlit" : "Talmay",
		"pron" : "tal-mah'-ee",
		"derivation" : "from H8525 (תֶּלֶם); ridged",
		"def" : "Talmai, the name of a Canaanite and a Syrian",
		"kjv" : "Talmai"
	},
	"H8527" : {
		"lemma" : "תַּלְמִיד",
		"xlit" : "talmîyd",
		"pron" : "tal-meed'",
		"derivation" : "from H3925 (לָמַד)",
		"def" : "a pupil",
		"kjv" : "scholar"
	},
	"H8528" : {
		"lemma" : "תֵּל מֶלַח",
		"xlit" : "Têl Melach",
		"pron" : "tale meh'-lakh",
		"derivation" : "from H8510 (תֵּל) and H4417 (מֶלַח); mound of salt",
		"def" : "Tel-Melach, a place in Babylonia",
		"kjv" : "Tel-melah"
	},
	"H8529" : {
		"lemma" : "תָּלַע",
		"xlit" : "tâlaʻ",
		"pron" : "taw-law'",
		"derivation" : "a denominative from H8438 (תּוֹלָע)",
		"def" : "to crimson, i.e. dye that color",
		"kjv" : "[idiom] scarlet"
	},
	"H8530" : {
		"lemma" : "תַּלְפִּיָּה",
		"xlit" : "talpîyâh",
		"pron" : "tal-pee-yaw'",
		"derivation" : "feminine from an unused root meaning to tower",
		"def" : "something tall, i.e. (plural collective) slenderness",
		"kjv" : "armoury"
	},
	"H8531" : {
		"lemma" : "תְּלַת",
		"xlit" : "tᵉlath",
		"pron" : "tel-ath'",
		"derivation" : "(Aramaic) from H8532 (תְּלָת)",
		"def" : "a tertiary rank",
		"kjv" : "third"
	},
	"H8532" : {
		"lemma" : "תְּלָת",
		"xlit" : "tᵉlâth",
		"pron" : "tel-awth'",
		"derivation" : "(Aramaic) masculine תְּלָתָה; (Aramaic), or תְּלָתָא; (Aramaic), corresponding to H7969 (שָׁלוֹשׁ)",
		"def" : "three or third",
		"kjv" : "third, three"
	},
	"H8533" : {
		"lemma" : "תְּלָתִין",
		"xlit" : "tᵉlâthîyn",
		"pron" : "tel-aw-theen'",
		"derivation" : "(Aramaic) multiple of H8532 (תְּלָת)",
		"def" : "ten times three",
		"kjv" : "thirty"
	},
	"H8534" : {
		"lemma" : "תַּלְתַּל",
		"xlit" : "taltal",
		"pron" : "tal-tal'",
		"derivation" : "by reduplication, from H8524 (תָּלַל) through the idea of vibration",
		"def" : "a trailing bough (as pendulous)",
		"kjv" : "bushy"
	},
	"H8535" : {
		"lemma" : "תָּם",
		"xlit" : "tâm",
		"pron" : "tawm",
		"derivation" : "from H8552 (תָּמַם)",
		"def" : "complete; usually (morally) pious; specifically, gentle, dear",
		"kjv" : "coupled together, perfect, plain, undefiled, upright"
	},
	"H8536" : {
		"lemma" : "תָּם",
		"xlit" : "tâm",
		"pron" : "tawm",
		"derivation" : "(Aramaic) corresponding to H8033 (שָׁם)",
		"def" : "there",
		"kjv" : "[idiom] thence, there, [idiom] where"
	},
	"H8537" : {
		"lemma" : "תֹּם",
		"xlit" : "tôm",
		"pron" : "tome",
		"derivation" : "from H8552 (תָּמַם)",
		"def" : "completeness; figuratively, prosperity; usually (morally) innocence",
		"kjv" : "full, integrity, perfect(-ion), simplicity, upright(-ly, -ness), at a venture. See H8550 (תֻּמִּים)"
	},
	"H8538" : {
		"lemma" : "תֻּמָּה",
		"xlit" : "tummâh",
		"pron" : "toom-maw'",
		"derivation" : "feminine of H8537 (תֹּם)",
		"def" : "innocence",
		"kjv" : "integrity"
	},
	"H8539" : {
		"lemma" : "תָּמַהּ",
		"xlit" : "tâmahh",
		"pron" : "taw-mah'",
		"derivation" : "a primitive root",
		"def" : "to be in consternation",
		"kjv" : "be amazed, be astonished, marvel(-lously), wonder"
	},
	"H8540" : {
		"lemma" : "תְּמַהּ",
		"xlit" : "tᵉmahh",
		"pron" : "tem-ah'",
		"derivation" : "(Aramaic) from a root corresponding to H8539 (תָּמַהּ)",
		"def" : "a miracle",
		"kjv" : "wonder"
	},
	"H8541" : {
		"lemma" : "תִּמָּהוֹן",
		"xlit" : "timmâhôwn",
		"pron" : "tim-maw-hone'",
		"derivation" : "from H8539 (תָּמַהּ)",
		"def" : "consternation",
		"kjv" : "astonishment"
	},
	"H8542" : {
		"lemma" : "תַּמּוּז",
		"xlit" : "Tammûwz",
		"pron" : "tam-mooz'",
		"derivation" : "of uncertain derivation",
		"def" : "Tammuz, a Phoenician deity",
		"kjv" : "Tammuz"
	},
	"H8543" : {
		"lemma" : "תְּמוֹל",
		"xlit" : "tᵉmôwl",
		"pron" : "tem-ole'",
		"derivation" : "or תְּמֹל; probably for H865 (אֶתְמוֹל); sometimes with  H8032 (שִׁלְשׁוֹם)",
		"def" : "properly, ago, i.e. a (short or long) time since; especially yesterday, or  day before yesterday",
		"kjv" : "[phrase] before (-time), [phrase] these (three) days, [phrase] heretofore, [phrase] time past, yesterday"
	},
	"H8544" : {
		"lemma" : "תְּמוּנָה",
		"xlit" : "tᵉmûwnâh",
		"pron" : "tem-oo-naw'",
		"derivation" : "or תְּמֻנָה; from H4327 (מִין)",
		"def" : "something portioned (i.e. fashioned) out, as ashape, i.e. (indefinitely) phantom, or (specifically) embodiment, or (figuratively) manifestation (of favor)",
		"kjv" : "image, likeness, similitude"
	},
	"H8545" : {
		"lemma" : "תְּמוּרָה",
		"xlit" : "tᵉmûwrâh",
		"pron" : "tem-oo-raw'",
		"derivation" : "from H4171 (מוּר)",
		"def" : "barter, compensation",
		"kjv" : "(ex-) change(-ing), recompense, restitution"
	},
	"H8546" : {
		"lemma" : "תְּמוּתָה",
		"xlit" : "tᵉmûwthâh",
		"pron" : "tem-oo-thaw'",
		"derivation" : "from H4191 (מוּת)",
		"def" : "execution (as a doom)",
		"kjv" : "death, die"
	},
	"H8547" : {
		"lemma" : "תֶּמַח",
		"xlit" : "Temach",
		"pron" : "teh'-makh",
		"derivation" : "of uncertain derivation",
		"def" : "Temach, one of the Nethinim",
		"kjv" : "Tamah, Thamah"
	},
	"H8548" : {
		"lemma" : "תָּמִיד",
		"xlit" : "tâmîyd",
		"pron" : "taw-meed'",
		"derivation" : "from an unused root meaning to stretch",
		"def" : "properly, continuance (as indefinite extension); but used only (attributively as adjective) constant (or adverbially, constantly); ellipt. the regular (daily) sacrifice",
		"kjv" : "alway(-s), continual (employment, -ly), daily, (n-)ever(-more), perpetual"
	},
	"H8549" : {
		"lemma" : "תָּמִים",
		"xlit" : "tâmîym",
		"pron" : "taw-meem'",
		"derivation" : "from H8552 (תָּמַם)",
		"def" : "entire (literally, figuratively or morally); also (as noun) integrity, truth",
		"kjv" : "without blemish, complete, full, perfect, sincerely (-ity), sound, without spot, undefiled, upright(-ly), whole"
	},
	"H8550" : {
		"lemma" : "תֻּמִּים",
		"xlit" : "Tummîym",
		"pron" : "toom-meem'",
		"derivation" : "plural of H8537 (תֹּם)",
		"def" : "perfections, i.e. (techn.) one of the epithets of the objects in the high-priest's breastplate as an emblem of complete Truth",
		"kjv" : "Thummim"
	},
	"H8551" : {
		"lemma" : "תָּמַךְ",
		"xlit" : "tâmak",
		"pron" : "taw-mak'",
		"derivation" : "a primitive root",
		"def" : "to sustain; by implication, to obtain, keep fast; figuratively, to help, follow close",
		"kjv" : "(take, up-) hold (up), maintain, retain, stay (up)"
	},
	"H8552" : {
		"lemma" : "תָּמַם",
		"xlit" : "tâmam",
		"pron" : "taw-mam'",
		"derivation" : "a primitive root",
		"def" : "to complete, in a good or a bad sense, literal, or figurative, transitive or intransitive",
		"kjv" : "accomplish, cease, be clean (pass-) ed, consume, have done, (come to an, have an, make an) end, fail, come to the full, be all gone, [idiom] be all here, be (make) perfect, be spent, sum, be (shew self) upright, be wasted, whole"
	},
	"H8553" : {
		"lemma" : "תִּמְנָה",
		"xlit" : "Timnâh",
		"pron" : "tim-naw'",
		"derivation" : "from H4487 (מָנָה); a portion assigned",
		"def" : "Timnah, the name of two places in Palestine",
		"kjv" : "Timnah, Timnath, Thimnathah"
	},
	"H8554" : {
		"lemma" : "תִּמְנִי",
		"xlit" : "Timnîy",
		"pron" : "tim-nee'",
		"derivation" : "patrial from H8553 (תִּמְנָה)",
		"def" : "a Timnite or inhabitant of Timnah",
		"kjv" : "Timnite"
	},
	"H8555" : {
		"lemma" : "תִּמְנָע",
		"xlit" : "Timnâʻ",
		"pron" : "tim-naw'",
		"derivation" : "from H4513 (מָנַע); restraint",
		"def" : "Timna, the name of two Edomites",
		"kjv" : "Timna, Timnah"
	},
	"H8556" : {
		"lemma" : "תִּמְנַת חֶרֶס",
		"xlit" : "Timnath Chereç",
		"pron" : "tim-nath kheh'-res",
		"derivation" : "or תִּמְנַת סֶרַח; from H8553 (תִּמְנָה) and H2775 (חֶרֶס); portion of (the) sun",
		"def" : "Timnath-Cheres, a place in Palestine",
		"kjv" : "Timnath-heres, Timnath-serah"
	},
	"H8557" : {
		"lemma" : "תֶּמֶס",
		"xlit" : "temeç",
		"pron" : "teh'-mes",
		"derivation" : "from H4529 (מָסָה)",
		"def" : "liquefaction, i.e. disappearance",
		"kjv" : "melt"
	},
	"H8558" : {
		"lemma" : "תָּמָר",
		"xlit" : "tâmâr",
		"pron" : "taw-mawr'",
		"derivation" : "from an unused root meaning to be erect",
		"def" : "a palm tree",
		"kjv" : "palm (tree)"
	},
	"H8559" : {
		"lemma" : "תָּמָר",
		"xlit" : "Tâmâr",
		"pron" : "taw-mawr'",
		"derivation" : "the same as H8558 (תָּמָר)",
		"def" : "Tamar, the name of three women and a place",
		"kjv" : "Tamar"
	},
	"H8560" : {
		"lemma" : "תֹּמֶר",
		"xlit" : "tômer",
		"pron" : "to'-mer",
		"derivation" : "from the same root as H8558 (תָּמָר)",
		"def" : "a palm trunk",
		"kjv" : "palm tree"
	},
	"H8561" : {
		"lemma" : "תִּמֹּר",
		"xlit" : "timmôr",
		"pron" : "tim-more'",
		"derivation" : "(plural or (feminine) תִּמֹּרָה; (singular and plural), from the same root as H8558 (תָּמָר)",
		"def" : "(architectural) a palm-like pilaster (i.e. umbellate)",
		"kjv" : "palm tree"
	},
	"H8562" : {
		"lemma" : "תַּמְרוּק",
		"xlit" : "tamrûwq",
		"pron" : "tam-rook'",
		"derivation" : "or תַּמְרֻק; or תַּמְרִיק; from H4838 (מָרַק)",
		"def" : "properly, a scouring, i.e. soap or perfumery forthe bath; figuratively, a detergent",
		"kjv" : "[idiom] cleanse, (thing for) purification(-fying)"
	},
	"H8563" : {
		"lemma" : "תַּמְרוּר",
		"xlit" : "tamrûwr",
		"pron" : "tam-roor'",
		"derivation" : "from H4843 (מָרַר)",
		"def" : "bitterness (plural as collective)",
		"kjv" : "[idiom] most bitter(-ly)"
	},
	"H8564" : {
		"lemma" : "תַּמְרוּר",
		"xlit" : "tamrûwr",
		"pron" : "tam-roor'",
		"derivation" : "from the same root as H8558 (תָּמָר)",
		"def" : "an erection, i.e. pillar (probably for a guide-board)",
		"kjv" : "high heap"
	},
	"H8565" : {
		"lemma" : "תַּן",
		"xlit" : "tan",
		"pron" : "tan",
		"derivation" : "from an unused root probably meaning to elongate",
		"def" : "a monster (as preternaturally formed), i.e. a sea-serpent (or other huge marine animal); also a jackal (or other hideous land animal)",
		"kjv" : "dragon, whale. Compare H8577 (תַּנִּין)"
	},
	"H8566" : {
		"lemma" : "תָּנָה",
		"xlit" : "tânâh",
		"pron" : "taw-naw'",
		"derivation" : "a primitive root",
		"def" : "to present (a mercenary inducement), i.e. bargain with (a harlot)",
		"kjv" : "hire"
	},
	"H8567" : {
		"lemma" : "תָּנָה",
		"xlit" : "tânâh",
		"pron" : "taw-naw'",
		"derivation" : "a primitive root (identical with through the idea of attributing honor)",
		"def" : "to ascribe (praise), i.e. celebrate, commemorate",
		"kjv" : "lament, rehearse"
	},
	"H8568" : {
		"lemma" : "תַּנָּה",
		"xlit" : "tannâh",
		"pron" : "tan-naw'",
		"derivation" : "probably feminine of H8565 (תַּן)",
		"def" : "a female jackal",
		"kjv" : "dragon"
	},
	"H8569" : {
		"lemma" : "תְּנוּאָה",
		"xlit" : "tᵉnûwʼâh",
		"pron" : "ten-oo-aw'",
		"derivation" : "from H5106 (נוּא)",
		"def" : "alienation; by implication, enmity",
		"kjv" : "breach of promise, occasion"
	},
	"H8570" : {
		"lemma" : "תְּנוּבָה",
		"xlit" : "tᵉnûwbâh",
		"pron" : "ten-oo-baw'",
		"derivation" : "from H5107 (נוּב)",
		"def" : "produce",
		"kjv" : "fruit, increase"
	},
	"H8571" : {
		"lemma" : "תְּנוּךְ",
		"xlit" : "tᵉnûwk",
		"pron" : "ten-ook'",
		"derivation" : "perhaps from the same as H594 (אֲנָךְ) through the idea of protraction",
		"def" : "a pinnacle, i.e. extremity",
		"kjv" : "tip"
	},
	"H8572" : {
		"lemma" : "תְּנוּמָה",
		"xlit" : "tᵉnûwmâh",
		"pron" : "ten-oo-maw'",
		"derivation" : "from H5123 (נוּם)",
		"def" : "drowsiness, i.e. sleep",
		"kjv" : "slumber(-ing)"
	},
	"H8573" : {
		"lemma" : "תְּנוּפָה",
		"xlit" : "tᵉnûwphâh",
		"pron" : "ten-oo-faw'",
		"derivation" : "from H5130 (נוּף)",
		"def" : "a brandishing (in threat); by implication, tumult; specifically, the official undulation of sacrificial offerings",
		"kjv" : "offering, shaking, wave (offering)"
	},
	"H8574" : {
		"lemma" : "תַּנּוּר",
		"xlit" : "tannûwr",
		"pron" : "tan-noor'",
		"derivation" : "from H5216 (נִיר)",
		"def" : "a fire-pot",
		"kjv" : "furnace, oven"
	},
	"H8575" : {
		"lemma" : "תַּנְחוּם",
		"xlit" : "tanchûwm",
		"pron" : "tan-khoom'",
		"derivation" : "or תַּנְחֻם; and (feminine) תַּנְחוּמָה; from H5162 (נָחַם)",
		"def" : "compassion, solace",
		"kjv" : "comfort, consolation"
	},
	"H8576" : {
		"lemma" : "תַּנְחֻמֶת",
		"xlit" : "Tanchumeth",
		"pron" : "tan-khoo'-meth",
		"derivation" : "for H8575 (תַּנְחוּם) (feminine)",
		"def" : "Tanchumeth, an Israelite",
		"kjv" : "Tanhumeth"
	},
	"H8577" : {
		"lemma" : "תַּנִּין",
		"xlit" : "tannîyn",
		"pron" : "tan-neen'",
		"derivation" : "or תַּנִּים; (Ezekiel 29:3), intensive from the same as H8565 (תַּן)",
		"def" : "a marine or land monster, i.e. sea-serpent or jackal",
		"kjv" : "dragon, sea-monster, serpent, whale"
	},
	"H8578" : {
		"lemma" : "תִּנְיָן",
		"xlit" : "tinyân",
		"pron" : "tin-yawn'",
		"derivation" : "(Aramaic) corresponding to H8147 (שְׁנַיִם)",
		"def" : "second",
		"kjv" : "second"
	},
	"H8579" : {
		"lemma" : "תִּנְיָנוּת",
		"xlit" : "tinyânûwth",
		"pron" : "tin-yaw-nooth'",
		"derivation" : "(Aramaic) from H8578 (תִּנְיָן)",
		"def" : "a second time",
		"kjv" : "again"
	},
	"H8580" : {
		"lemma" : "תַּנְשֶׁמֶת",
		"xlit" : "tanshemeth",
		"pron" : "tan-sheh'-meth",
		"derivation" : "from H5395 (נָשַׁם)",
		"def" : "properly, a hard breather, i.e. the name of two unclean creatures, a lizard and abird (both perhaps from changing color through their irascibility), probably the tree-toad and the water-hen",
		"kjv" : "mole, swan"
	},
	"H8581" : {
		"lemma" : "תַּעָב",
		"xlit" : "taʻâb",
		"pron" : "taw-ab'",
		"derivation" : "a primitive root",
		"def" : "to loathe, i.e. (morally) detest",
		"kjv" : "(make to be) abhor(-red), (be, commit more, do) abominable(-y), [idiom] utterly"
	},
	"H8582" : {
		"lemma" : "תָּעָה",
		"xlit" : "tâʻâh",
		"pron" : "taw-aw'",
		"derivation" : "a primitive root",
		"def" : "to vacillate, i.e. reel or stray (literally or figuratively); also causative of both",
		"kjv" : "(cause to) go astray, deceive, dissemble, (cause to, make to) err, pant, seduce, (make to) stagger, (cause to) wander, be out of the way"
	},
	"H8583" : {
		"lemma" : "תֹּעוּ",
		"xlit" : "Tôʻûw",
		"pron" : "to'-oo",
		"derivation" : "or תֹּעִי; from H8582 (תָּעָה); error",
		"def" : "Tou or Toi, a Syrian king",
		"kjv" : "Toi, Tou"
	},
	"H8584" : {
		"lemma" : "תְּעוּדָה",
		"xlit" : "tᵉʻûwdâh",
		"pron" : "teh-oo-daw'",
		"derivation" : "from H5749 (עוּד)",
		"def" : "attestation, i.e. a precept, usage",
		"kjv" : "testimony"
	},
	"H8585" : {
		"lemma" : "תְּעָלָה",
		"xlit" : "tᵉʻâlâh",
		"pron" : "teh-aw-law'",
		"derivation" : "from H5927 (עָלָה)",
		"def" : "a channel (into which water is raised for irrigation); also a bandage or plaster (as placed upon a wound)",
		"kjv" : "conduit, cured, healing, little river, trench, watercourse"
	},
	"H8586" : {
		"lemma" : "תַּעֲלוּל",
		"xlit" : "taʻălûwl",
		"pron" : "tah-al-ool'",
		"derivation" : "from H5953 (עָלַל)",
		"def" : "caprice (as a fit coming on), i.e. vexation; concretely a tyrant",
		"kjv" : "babe, delusion"
	},
	"H8587" : {
		"lemma" : "תַּעֲלֻמָּה",
		"xlit" : "taʻălummâh",
		"pron" : "tah-al-oom-maw'",
		"derivation" : "from H5956 (עָלַם)",
		"def" : "a secret",
		"kjv" : "thing that is hid, secret"
	},
	"H8588" : {
		"lemma" : "תַּעֲנוּג",
		"xlit" : "taʻănûwg",
		"pron" : "tah-an-oog'",
		"derivation" : "or תַּעֲנֻג; and (feminine) תַּעֲנֻגָה; from H6026 (עָנַג)",
		"def" : "luxury",
		"kjv" : "delicate, delight, pleasant"
	},
	"H8589" : {
		"lemma" : "תַּעֲנִית",
		"xlit" : "taʻănîyth",
		"pron" : "tah-an-eeth'",
		"derivation" : "from H6031 (עָנָה)",
		"def" : "affliction (of self), i.e. fasting",
		"kjv" : "heaviness"
	},
	"H8590" : {
		"lemma" : "תַּעֲנָךְ",
		"xlit" : "Taʻănâk",
		"pron" : "tah-an-awk'",
		"derivation" : "or תַּעְנָךְ; of uncertain derivation",
		"def" : "Taanak or Tanak, a place in Palestine",
		"kjv" : "Taanach, Tanach"
	},
	"H8591" : {
		"lemma" : "תָּעַע",
		"xlit" : "tâʻaʻ",
		"pron" : "taw-ah'",
		"derivation" : "a primitive root",
		"def" : "to cheat; by analogy, to maltreat",
		"kjv" : "deceive, misuse"
	},
	"H8592" : {
		"lemma" : "תַּעֲצֻמָה",
		"xlit" : "taʻătsumâh",
		"pron" : "tah-ats-oo-maw'",
		"derivation" : "from H6105 (עָצַם)",
		"def" : "might (plural collective)",
		"kjv" : "power"
	},
	"H8593" : {
		"lemma" : "תַּעַר",
		"xlit" : "taʻar",
		"pron" : "tah'-ar",
		"derivation" : "from H6168 (עָרָה)",
		"def" : "a knife or razor (as making bare); also a scabbard (as being bare, i.e. empty)",
		"kjv" : "(pen-) knife, razor, scabbard, shave, sheath"
	},
	"H8594" : {
		"lemma" : "תַּעֲרֻבָה",
		"xlit" : "taʻărubâh",
		"pron" : "tah-ar-oo-baw'",
		"derivation" : "from H6148 (עָרַב)",
		"def" : "suretyship, i.e. (concretely) a pledge",
		"kjv" : "[phrase] hostage"
	},
	"H8595" : {
		"lemma" : "תַּעְתֻּעַ",
		"xlit" : "taʻtuaʻ",
		"pron" : "tah-too'-ah",
		"derivation" : "from H8591 (תָּעַע)",
		"def" : "a fraud",
		"kjv" : "error"
	},
	"H8596" : {
		"lemma" : "תֹּף",
		"xlit" : "tôph",
		"pron" : "tofe",
		"derivation" : "from H8608 (תָּפַף) contracted",
		"def" : "a tambourine",
		"kjv" : "tabret, timbrel"
	},
	"H8597" : {
		"lemma" : "תִּפְאָרָה",
		"xlit" : "tiphʼârâh",
		"pron" : "tif-aw-raw'",
		"derivation" : "or תִּפְאֶרֶת; from H6286 (פָּאַר)",
		"def" : "ornament (abstractly or concretely, literally or figuratively)",
		"kjv" : "beauty(-iful), bravery, comely, fair, glory(-ious), honour, majesty"
	},
	"H8598" : {
		"lemma" : "תַּפּוּחַ",
		"xlit" : "tappûwach",
		"pron" : "tap-poo'-akh",
		"derivation" : "from H5301 (נָפַח)",
		"def" : "an apple (from its fragrance), i.e. the fruit or the tree (probably includ. others of the pome order, as the quince, the orange, etc.)",
		"kjv" : "apple (tree). See also H1054 (בֵּית תַּפּוּחַ)"
	},
	"H8599" : {
		"lemma" : "תַּפּוּחַ",
		"xlit" : "Tappûwach",
		"pron" : "tap-poo'-akh",
		"derivation" : "the same as H8598 (תַּפּוּחַ)",
		"def" : "Tappuach, the name of two places in Palestine, also of an Israelite",
		"kjv" : "Tappuah"
	},
	"H8600" : {
		"lemma" : "תְּפוֹצָה",
		"xlit" : "tᵉphôwtsâh",
		"pron" : "tef-o-tsaw'",
		"derivation" : "from H6327 (פּוּץ)",
		"def" : "a dispersal",
		"kjv" : "dispersion"
	},
	"H8601" : {
		"lemma" : "תֻּפִין",
		"xlit" : "tuphîyn",
		"pron" : "too-feen'",
		"derivation" : "from H644 (אָפָה)",
		"def" : "cookery, i.e. (concretely) a cake",
		"kjv" : "baked piece"
	},
	"H8602" : {
		"lemma" : "תָּפֵל",
		"xlit" : "tâphêl",
		"pron" : "taw-fale'",
		"derivation" : "from an unused root meaning to smear",
		"def" : "plaster (as gummy) or slime; (figuratively) frivolity",
		"kjv" : "foolish things, unsavoury, untempered"
	},
	"H8603" : {
		"lemma" : "תֹּפֶל",
		"xlit" : "Tôphel",
		"pron" : "to'-fel",
		"derivation" : "from the same as H8602 (תָּפֵל); quagmire",
		"def" : "Tophel, a place near the Desert",
		"kjv" : "Tophel"
	},
	"H8604" : {
		"lemma" : "תִּפְלָה",
		"xlit" : "tiphlâh",
		"pron" : "tif-law'",
		"derivation" : "from the same as H8602 (תָּפֵל)",
		"def" : "frivolity",
		"kjv" : "folly, foolishly"
	},
	"H8605" : {
		"lemma" : "תְּפִלָּה",
		"xlit" : "tᵉphillâh",
		"pron" : "tef-il-law'",
		"derivation" : "from H6419 (פָּלַל)",
		"def" : "intercession, supplication; by implication, a hymn",
		"kjv" : "prayer"
	},
	"H8606" : {
		"lemma" : "תִּפְלֶצֶת",
		"xlit" : "tiphletseth",
		"pron" : "tif-leh'-tseth",
		"derivation" : "from H6426 (פָּלַץ)",
		"def" : "fearfulness",
		"kjv" : "terrible"
	},
	"H8607" : {
		"lemma" : "תִּפְסַח",
		"xlit" : "Tiphçach",
		"pron" : "tif-sakh'",
		"derivation" : "from H6452 (פָּסַח); ford",
		"def" : "Tiphsach, a place in Mesopotamia",
		"kjv" : "Tipsah"
	},
	"H8608" : {
		"lemma" : "תָּפַף",
		"xlit" : "tâphaph",
		"pron" : "taw-faf'",
		"derivation" : "a primitive root",
		"def" : "to drum, i.e. play (as) on the tambourine",
		"kjv" : "taber, play with timbrels"
	},
	"H8609" : {
		"lemma" : "תָּפַר",
		"xlit" : "tâphar",
		"pron" : "taw-far'",
		"derivation" : "a primitive root",
		"def" : "to sew",
		"kjv" : "(women that) sew (together)"
	},
	"H8610" : {
		"lemma" : "תָּפַשׂ",
		"xlit" : "tâphas",
		"pron" : "taw-fas'",
		"derivation" : "a primitive root",
		"def" : "to manipulate, i.e. seize; chiefly to capture, wield, specifically, to overlay; figuratively, to use unwarrantably",
		"kjv" : "catch, handle, (lay, take) hold (on, over), stop, [idiom] surely, surprise, take"
	},
	"H8611" : {
		"lemma" : "תֹּפֶת",
		"xlit" : "tôpheth",
		"pron" : "to'-feth",
		"derivation" : "from the base of H8608 (תָּפַף)",
		"def" : "a smiting, i.e. (figuratively) contempt",
		"kjv" : "tabret"
	},
	"H8612" : {
		"lemma" : "תֹּפֶת",
		"xlit" : "Tôpheth",
		"pron" : "to'-feth",
		"derivation" : "the same as H8611 (תֹּפֶת)",
		"def" : "Topheth, a place near Jerusalem",
		"kjv" : "Tophet, Topheth"
	},
	"H8613" : {
		"lemma" : "תׇּפְתֶּה",
		"xlit" : "Tophteh",
		"pron" : "tof-teh'",
		"derivation" : "probably a form of H8612 (תֹּפֶת)",
		"def" : "Tophteh, a place of cremation",
		"kjv" : "Tophet"
	},
	"H8614" : {
		"lemma" : "תִּפְתַּי",
		"xlit" : "tiphtay",
		"pron" : "tif-tah'-ee",
		"derivation" : "(Aramaic) perhaps from H8199 (שָׁפַט)",
		"def" : "judicial, i.e. a lawyer",
		"kjv" : "sheriff"
	},
	"H8615" : {
		"lemma" : "תִּקְוָה",
		"xlit" : "tiqvâh",
		"pron" : "tik-vaw'",
		"derivation" : "from H6960 (קָוָה); (compare H6961 (קָוֶה))",
		"def" : "literally a cord (as an attachment); figuratively, expectancy",
		"kjv" : "expectation(-ted), hope, live, thing that I long for"
	},
	"H8616" : {
		"lemma" : "תִּקְוָה",
		"xlit" : "Tiqvâh",
		"pron" : "tik-vaw'",
		"derivation" : "the same as H8615 (תִּקְוָה)",
		"def" : "Tikvah, the name of two Israelites",
		"kjv" : "Tikvah"
	},
	"H8617" : {
		"lemma" : "תְּקוּמָה",
		"xlit" : "tᵉqûwmâh",
		"pron" : "tek-oo-maw'",
		"derivation" : "from H6965 (קוּם)",
		"def" : "resistfulness",
		"kjv" : "power to stand"
	},
	"H8618" : {
		"lemma" : "תְּקוֹמֵם",
		"xlit" : "tᵉqôwmêm",
		"pron" : "tek-o-mame'",
		"derivation" : "from H6965 (קוּם)",
		"def" : "an opponent",
		"kjv" : "rise up against"
	},
	"H8619" : {
		"lemma" : "תָּקוֹעַ",
		"xlit" : "tâqôwaʻ",
		"pron" : "taw-ko'-ah",
		"derivation" : "from H8628 (תָּקַע) (in the musical sense)",
		"def" : "a trumpet",
		"kjv" : "trumpet"
	},
	"H8620" : {
		"lemma" : "תְּקוֹעַ",
		"xlit" : "Tᵉqôwaʻ",
		"pron" : "tek-o'-ah",
		"derivation" : "a form of H8619 (תָּקוֹעַ)",
		"def" : "Tekoa, a place in Palestine",
		"kjv" : "Tekoa, Tekoah"
	},
	"H8621" : {
		"lemma" : "תְּקוֹעִי",
		"xlit" : "Tᵉqôwʻîy",
		"pron" : "tek-o-ee'",
		"derivation" : "or תְּקֹעִי; patronymically from H8620 (תְּקוֹעַ)",
		"def" : "a Tekoite or inhabitant of Tekoah",
		"kjv" : "Tekoite"
	},
	"H8622" : {
		"lemma" : "תְּקוּפָה",
		"xlit" : "tᵉqûwphâh",
		"pron" : "tek-oo-faw'",
		"derivation" : "or תְּקֻפָה; from H5362 (נָקַף)",
		"def" : "a revolution, i.e. (of the sun) course, (of time) lapse",
		"kjv" : "circuit, come about, end"
	},
	"H8623" : {
		"lemma" : "תַּקִּיף",
		"xlit" : "taqqîyph",
		"pron" : "tak-keef'",
		"derivation" : "from H8630 (תָּקַף)",
		"def" : "powerful",
		"kjv" : "mightier"
	},
	"H8624" : {
		"lemma" : "תַּקִּיף",
		"xlit" : "taqqîyph",
		"pron" : "tak-keef'",
		"derivation" : "(Aramaic) corresponding to H8623 (תַּקִּיף)",
		"def" : "{powerful}",
		"kjv" : "mighty, strong"
	},
	"H8625" : {
		"lemma" : "תְּקַל",
		"xlit" : "tᵉqal",
		"pron" : "tek-al'",
		"derivation" : "(Aramaic) corresponding to H8254 (שָׁקַל)",
		"def" : "to balance",
		"kjv" : "Tekel, be weighed"
	},
	"H8626" : {
		"lemma" : "תָּקַן",
		"xlit" : "tâqan",
		"pron" : "taw-kan'",
		"derivation" : "a primitive root",
		"def" : "to equalize, i.e. straighten (intransitive or transitive); figuratively, to compose",
		"kjv" : "set in order, make straight"
	},
	"H8627" : {
		"lemma" : "תְּקַן",
		"xlit" : "tᵉqan",
		"pron" : "tek-an'",
		"derivation" : "(Aramaic) corresponding to H8626 (תָּקַן)",
		"def" : "to straighten up, i.e. confirm",
		"kjv" : "establish"
	},
	"H8628" : {
		"lemma" : "תָּקַע",
		"xlit" : "tâqaʻ",
		"pron" : "taw-kah'",
		"derivation" : "a primitive root",
		"def" : "to clatter, i.e. slap (the hands together), clang (an instrument); by analogy, to drive (a nail or tent-pin, a dart, etc.); by implication, to become bondsman by handclasping)",
		"kjv" : "blow (a trumpet), cast, clap, fasten, pitch (tent), smite, sound, strike, [idiom] suretiship, thrust"
	},
	"H8629" : {
		"lemma" : "תֵּקַע",
		"xlit" : "têqaʻ",
		"pron" : "tay-kah'",
		"derivation" : "from H8628 (תָּקַע)",
		"def" : "a blast of a trumpet",
		"kjv" : "sound"
	},
	"H8630" : {
		"lemma" : "תָּקַף",
		"xlit" : "tâqaph",
		"pron" : "taw-kaf'",
		"derivation" : "a primitive root",
		"def" : "to overpower",
		"kjv" : "prevail (against)"
	},
	"H8631" : {
		"lemma" : "תְּקֵף",
		"xlit" : "tᵉqêph",
		"pron" : "tek-afe'",
		"derivation" : "(Aramaic) corresponding to H8630 (תָּקַף)",
		"def" : "to become (causatively, make) mighty or (figuratively) obstinate",
		"kjv" : "make firm, harden, be(-come) strong"
	},
	"H8632" : {
		"lemma" : "תְּקֹף",
		"xlit" : "tᵉqôph",
		"pron" : "tek-ofe'",
		"derivation" : "(Aramaic) corresponding to H8633 (תֹּקֶף)",
		"def" : "power",
		"kjv" : "might, strength"
	},
	"H8633" : {
		"lemma" : "תֹּקֶף",
		"xlit" : "tôqeph",
		"pron" : "to'-kef",
		"derivation" : "from H8630 (תָּקַף)",
		"def" : "might or (figuratively) positiveness",
		"kjv" : "authority, power, strength"
	},
	"H8634" : {
		"lemma" : "תַּרְאֲלָה",
		"xlit" : "Tarʼălâh",
		"pron" : "tar-al-aw'",
		"derivation" : "probably for H8653 (תַּרְעֵלָה); a reeling",
		"def" : "Taralah, a place in Palestine",
		"kjv" : "Taralah"
	},
	"H8635" : {
		"lemma" : "תַּרְבּוּת",
		"xlit" : "tarbûwth",
		"pron" : "tar-booth'",
		"derivation" : "from H7235 (רָבָה)",
		"def" : "multiplication, i.e. progeny",
		"kjv" : "increase"
	},
	"H8636" : {
		"lemma" : "תַּרְבִּית",
		"xlit" : "tarbîyth",
		"pron" : "tar-beeth'",
		"derivation" : "from H7235 (רָבָה)",
		"def" : "multiplication, i.e. percentage or bonus in addition to principal",
		"kjv" : "increase, unjust gain"
	},
	"H8637" : {
		"lemma" : "תִּרְגַּל",
		"xlit" : "tirgal",
		"pron" : "teer-gal'",
		"derivation" : "a denominative from H7270 (רָגַל)",
		"def" : "to cause to walk",
		"kjv" : "teach to go"
	},
	"H8638" : {
		"lemma" : "תִּרְגַּם",
		"xlit" : "tirgam",
		"pron" : "teer-gam'",
		"derivation" : "a denominative from H7275 (רָגַם) in the sense of throwing over",
		"def" : "to transfer, i.e. translate",
		"kjv" : "interpret"
	},
	"H8639" : {
		"lemma" : "תַּרְדֵּמָה",
		"xlit" : "tardêmâh",
		"pron" : "tar-day-maw'",
		"derivation" : "from H7290 (רָדַם)",
		"def" : "a lethargy or (by implication) trance",
		"kjv" : "deep sleep"
	},
	"H8640" : {
		"lemma" : "תִּרְהָקָה",
		"xlit" : "Tirhâqâh",
		"pron" : "teer-haw'-kaw",
		"derivation" : "of foreign derivation",
		"def" : "Tirhakah, a king of Kush",
		"kjv" : "Tirhakah"
	},
	"H8641" : {
		"lemma" : "תְּרוּמָה",
		"xlit" : "tᵉrûwmâh",
		"pron" : "ter-oo-maw'",
		"derivation" : "or תְּרֻמָה; (Deuteronomy 12:11), from H7311 (רוּם)",
		"def" : "a present (as offered up), especially in sacrifice or as tribute",
		"kjv" : "gift, heave offering (shoulder), oblation, offered(-ing)"
	},
	"H8642" : {
		"lemma" : "תְּרוּמִיָּה",
		"xlit" : "tᵉrûwmîyâh",
		"pron" : "ter-oo-mee-yaw'",
		"derivation" : "formed as H8641 (תְּרוּמָה)",
		"def" : "a sacrificial offering",
		"kjv" : "oblation"
	},
	"H8643" : {
		"lemma" : "תְּרוּעָה",
		"xlit" : "tᵉrûwʻâh",
		"pron" : "ter-oo-aw'",
		"derivation" : "from H7321 (רוּעַ)",
		"def" : "clamor, i.e. acclamation of joy or a battle-cry; especially clangorof trumpets, as an alarum",
		"kjv" : "alarm, blow(-ing) (of, the) (trumpets), joy, jubile, loud noise, rejoicing, shout(-ing), (high, joyful) sound(-ing)"
	},
	"H8644" : {
		"lemma" : "תְּרוּפָה",
		"xlit" : "tᵉrûwphâh",
		"pron" : "ter-oo-faw'",
		"derivation" : "from H7322 (רוּף) in the sense of its congener H7495 (רָפָא)",
		"def" : "a remedy",
		"kjv" : "medicine"
	},
	"H8645" : {
		"lemma" : "תִּרְזָה",
		"xlit" : "tirzâh",
		"pron" : "teer-zaw'",
		"derivation" : "probably from H7329 (רָזָה)",
		"def" : "a species of tree (apparently from its slenderness), perhaps the cypress",
		"kjv" : "cypress"
	},
	"H8646" : {
		"lemma" : "תֶּרַח",
		"xlit" : "Terach",
		"pron" : "teh'-rakh",
		"derivation" : "of uncertain derivation",
		"def" : "Terach, the father of Abraham; also a place in the Desert",
		"kjv" : "Tarah, Terah"
	},
	"H8647" : {
		"lemma" : "תִּרְחֲנָה",
		"xlit" : "Tirchănâh",
		"pron" : "teer-khan-aw'",
		"derivation" : "of uncertain derivation",
		"def" : "Tirchanah, an Israelite",
		"kjv" : "Tirhanah"
	},
	"H8648" : {
		"lemma" : "תְּרֵין",
		"xlit" : "tᵉrêyn",
		"pron" : "ter-ane'",
		"derivation" : "(Aramaic) feminine תַּרְתֵּין; corresponding to H8147 (שְׁנַיִם)",
		"def" : "two",
		"kjv" : "second, [phrase] twelve, two"
	},
	"H8649" : {
		"lemma" : "תׇּרְמָה",
		"xlit" : "tormâh",
		"pron" : "tor-maw'",
		"derivation" : "and תַּרְמוּת; or תַּרְמִית; from H7411 (רָמָה)",
		"def" : "fraud",
		"kjv" : "deceit(-ful), privily"
	},
	"H8650" : {
		"lemma" : "תֹּרֶן",
		"xlit" : "tôren",
		"pron" : "to'-ren",
		"derivation" : "probably for H766 (אֹרֶן)",
		"def" : "a pole (as a mast or flagstaff)",
		"kjv" : "beacon, mast"
	},
	"H8651" : {
		"lemma" : "תְּרַע",
		"xlit" : "tᵉraʻ",
		"pron" : "ter-ah'",
		"derivation" : "(Aramaic) corresponding to H8179 (שַׁעַר)",
		"def" : "a door; by implication, a palace",
		"kjv" : "gate mouth"
	},
	"H8652" : {
		"lemma" : "תָּרָע",
		"xlit" : "târâʻ",
		"pron" : "taw-raw'",
		"derivation" : "(Aramaic) from H8651 (תְּרַע)",
		"def" : "a doorkeeper",
		"kjv" : "porter"
	},
	"H8653" : {
		"lemma" : "תַּרְעֵלָה",
		"xlit" : "tarʻêlâh",
		"pron" : "tar-ay-law'",
		"derivation" : "from H7477 (רָעַל)",
		"def" : "reeling",
		"kjv" : "astonishment, trembling"
	},
	"H8654" : {
		"lemma" : "תִּרְעָתִי",
		"xlit" : "Tirʻâthîy",
		"pron" : "teer-aw-thee'",
		"derivation" : "patrial from an unused name meaning gate",
		"def" : "a Tirathite or inhabitant of an unknown Tirah",
		"kjv" : "Tirathite"
	},
	"H8655" : {
		"lemma" : "תְּרָפִים",
		"xlit" : "tᵉrâphîym",
		"pron" : "ter-aw-feme'",
		"derivation" : "plural from H7495 (רָפָא); a healer",
		"def" : "Teraphim (singular or plural) a family idol",
		"kjv" : "idols(-atry), images, teraphim"
	},
	"H8656" : {
		"lemma" : "תִּרְצָה",
		"xlit" : "Tirtsâh",
		"pron" : "teer-tsaw'",
		"derivation" : "from H7521 (רָצָה); delightsomeness",
		"def" : "Tirtsah, a place in Palestine; also an Israelitess",
		"kjv" : "Tirzah"
	},
	"H8657" : {
		"lemma" : "תֶּרֶשׁ",
		"xlit" : "Teresh",
		"pron" : "teh'-resh",
		"derivation" : "of foreign derivation",
		"def" : "Teresh, a eunuch of Xerxes",
		"kjv" : "Teresh"
	},
	"H8658" : {
		"lemma" : "תַּרְשִׁישׁ",
		"xlit" : "tarshîysh",
		"pron" : "tar-sheesh'",
		"derivation" : "probably of foreign derivation (compare H8659 (תַּרְשִׁישׁ))",
		"def" : "a gem, perhaps the topaz",
		"kjv" : "beryl"
	},
	"H8659" : {
		"lemma" : "תַּרְשִׁישׁ",
		"xlit" : "Tarshîysh",
		"pron" : "tar-sheesh'",
		"derivation" : "probably the same as H8658 (תַּרְשִׁישׁ) (as the region of the stone, or the reverse)",
		"def" : "Tarshish, a place on the Mediterranean, hence, the ephithet of a merchant vessel (as if for or from that port); also the name of a Persian and of an Israelite",
		"kjv" : "Tarshish, Tharshish"
	},
	"H8660" : {
		"lemma" : "תִּרְשָׁתָא",
		"xlit" : "Tirshâthâʼ",
		"pron" : "teer-shaw-thaw'",
		"derivation" : "of foreign derivation",
		"def" : "Tirshatha, the title of a Persian deputy or governor",
		"kjv" : "Tirshatha"
	},
	"H8661" : {
		"lemma" : "תַּרְתָּן",
		"xlit" : "Tartân",
		"pron" : "tar-tawn'",
		"derivation" : "of foreign derivation",
		"def" : "Tartan, an Assyrian",
		"kjv" : "Tartan"
	},
	"H8662" : {
		"lemma" : "תַּרְתָּק",
		"xlit" : "Tartâq",
		"pron" : "tar-tawk'",
		"derivation" : "of foreign derivation",
		"def" : "Tartak, a deity of the Avvites",
		"kjv" : "Tartak"
	},
	"H8663" : {
		"lemma" : "תְּשֻׁאָה",
		"xlit" : "tᵉshuʼâh",
		"pron" : "tesh-oo-aw'",
		"derivation" : "from H7722 (שׁוֹא)",
		"def" : "a crashing or loud clamor",
		"kjv" : "crying, noise, shouting, stir"
	},
	"H8664" : {
		"lemma" : "תִּשְׁבִּי",
		"xlit" : "Tishbîy",
		"pron" : "tish-bee'",
		"derivation" : "patrial from an unused name meaning recourse",
		"def" : "a Tishbite or inhabitant of Tishbeh (in Gilead)",
		"kjv" : "Tishbite"
	},
	"H8665" : {
		"lemma" : "תַּשְׁבֵּץ",
		"xlit" : "tashbêts",
		"pron" : "tash-bates'",
		"derivation" : "from H7660 (שָׁבַץ)",
		"def" : "checkered stuff (as reticulated)",
		"kjv" : "broidered"
	},
	"H8666" : {
		"lemma" : "תְּשׁוּבָה",
		"xlit" : "tᵉshûwbâh",
		"pron" : "tesh-oo-baw'",
		"derivation" : "or תְּשֻׁבָה; from H7725 (שׁוּב)",
		"def" : "a recurrence (of time or place); a reply (as returned)",
		"kjv" : "answer, be expired, return"
	},
	"H8667" : {
		"lemma" : "תְּשׂוּמֶת",
		"xlit" : "tᵉsûwmeth",
		"pron" : "tes-oo-meth'",
		"derivation" : "from H7760 (שׂוּם)",
		"def" : "a deposit, i.e. pledging",
		"kjv" : "[phrase] fellowship"
	},
	"H8668" : {
		"lemma" : "תְּשׁוּעָה",
		"xlit" : "tᵉshûwʻâh",
		"pron" : "tesh-oo-aw'",
		"derivation" : "or תְּשֻׁעָה; from H7768 (שָׁוַע) in the sense of H3467 (יָשַׁע)",
		"def" : "rescue (literal or figurative, persons, national or spiritual)",
		"kjv" : "deliverance, help, safety, salvation, victory"
	},
	"H8669" : {
		"lemma" : "תְּשׁוּקָה",
		"xlit" : "tᵉshûwqâh",
		"pron" : "tesh-oo-kaw'",
		"derivation" : "from H7783 (שׁוּק) in the original sense of stretching out after",
		"def" : "a longing",
		"kjv" : "desire"
	},
	"H8670" : {
		"lemma" : "תְּשׁוּרָה",
		"xlit" : "tᵉshûwrâh",
		"pron" : "tesh-oo-raw'",
		"derivation" : "from H7788 (שׁוּר) in the sense of arrival",
		"def" : "a gift",
		"kjv" : "present"
	},
	"H8671" : {
		"lemma" : "תְּשִׁיעִי",
		"xlit" : "tᵉshîyʻîy",
		"pron" : "tesh-ee-ee'",
		"derivation" : "ordinal from H8672 (תֵּשַׁע)",
		"def" : "ninth",
		"kjv" : "ninth"
	},
	"H8672" : {
		"lemma" : "תֵּשַׁע",
		"xlit" : "têshaʻ",
		"pron" : "tay'-shah",
		"derivation" : "or (masculine) תִּשְׁעָה; perhaps from H8159 (שָׁעָה) through the idea of a turn to the next or full number ten",
		"def" : "nine or (ordinal) ninth",
		"kjv" : "nine ([phrase] -teen, [phrase] -teenth, -th)"
	},
	"H8673" : {
		"lemma" : "תִּשְׁעִים",
		"xlit" : "tishʻîym",
		"pron" : "tish-eem'",
		"derivation" : "multiple from H8672 (תֵּשַׁע)",
		"def" : "ninety",
		"kjv" : "ninety"
	},
	"H8674" : {
		"lemma" : "תַּתְּנַי",
		"xlit" : "Tattᵉnay",
		"pron" : "tat-ten-ah'-ee",
		"derivation" : "of foreign derivation",
		"def" : "Tattenai, a Persian",
		"kjv" : "Tatnai"
	},
	"G1615" : {
		"def" : "to complete fully",
		"derivation" : "from G1537 (ἐκ) and G5055 (τελέω)",
		"translit" : "ekteléō",
		"lemma" : "ἐκτελέω",
		"kjv" : "finish"
	},
	"G2274" : {
		"def" : "to make worse, i.e. vanquish (literally or figuratively); by implication, to rate lower",
		"derivation" : "from the same as G2276 (ἥττον)",
		"translit" : "hēttáō",
		"lemma" : "ἡττάω",
		"kjv" : "be inferior, overcome"
	},
	"G4533" : {
		"derivation" : "of Hebrew origin ()",
		"def" : "Salmon, an Israelite",
		"kjv" : "Salmon",
		"translit" : "Salmṓn",
		"lemma" : "Σαλμών"
	},
	"G5073" : {
		"lemma" : "τετραπλόος",
		"translit" : "tetraplóos",
		"kjv" : "fourfold",
		"def" : "quadruple",
		"derivation" : "from G5064 (τέσσαρες) and a derivative of the base of G4118 (πλεῖστος)"
	},
	"G3892" : {
		"def" : "transgression",
		"derivation" : "from the same as G3891 (παρανομέω)",
		"translit" : "paranomía",
		"lemma" : "παρανομία",
		"kjv" : "iniquity"
	},
	"G2928" : {
		"lemma" : "κρύπτω",
		"translit" : "krýptō",
		"kjv" : "hide (self), keep secret, secret(-ly)",
		"def" : "to conceal (properly, by covering)",
		"derivation" : "a primary verb"
	},
	"G1966" : {
		"kjv" : "following, next",
		"translit" : "epioûsa",
		"lemma" : "ἐπιοῦσα",
		"derivation" : "feminine singular participle of a comparative of G1909 (ἐπί) and (to go)",
		"def" : "supervening, i.e. (G2250 (ἡμέρα) or G3571 (νύξ) being expressed or implied) the ensuing day or night"
	},
	"G1716" : {
		"kjv" : "spit (upon)",
		"translit" : "emptýō",
		"lemma" : "ἐμπτύω",
		"derivation" : "from G1722 (ἐν) and G4429 (πτύω)",
		"def" : "to spit at or on"
	},
	"G4641" : {
		"translit" : "sklērokardía",
		"lemma" : "σκληροκαρδία",
		"kjv" : "hardness of heart",
		"def" : "hard-heartedness, i.e. (specially), destitution of (spiritual) perception",
		"derivation" : "feminine of a compound of G4642 (σκληρός) and G2588 (καρδία)"
	},
	"G506" : {
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G5293 (ὑποτάσσω)",
		"def" : "unsubdued, i.e. insubordinate (in fact or temper)",
		"kjv" : "disobedient, that is not put under, unruly",
		"translit" : "anypótaktos",
		"lemma" : "ἀνυπότακτος"
	},
	"G4181" : {
		"derivation" : "adverb from a compound of G4183 (πολύς) and G3313 (μέρος)",
		"def" : "in many portions, i.e. variously as to time and agency (piecemeal)",
		"kjv" : "at sundry times",
		"lemma" : "πολυμερῶς",
		"translit" : "polymerōs"
	},
	"G2577" : {
		"derivation" : "apparently a primary verb",
		"def" : "properly, to toil, i.e. (by implication) to tire (figuratively, faint, sicken)",
		"kjv" : "faint, sick, be wearied",
		"lemma" : "κάμνω",
		"translit" : "kámnō"
	},
	"G3634" : {
		"kjv" : "so (as), such as, what (manner of), which",
		"lemma" : "οἷος",
		"translit" : "hoîos",
		"derivation" : "probably akin to G3588 (ὁ), G3739 (ὅς), and G3745 (ὅσος)",
		"def" : "such or what sort of (as a correlation or exclamation); especially the neuter (adverbially) with negative, not so"
	},
	"G605" : {
		"derivation" : "from G600 (ἀποκαθίστημι)",
		"def" : "reconstitution",
		"kjv" : "restitution",
		"lemma" : "ἀποκατάστασις",
		"translit" : "apokatástasis"
	},
	"G5125" : {
		"translit" : "toútois",
		"lemma" : "τούτοις",
		"kjv" : "such, them, there(-in, -with), these, this, those",
		"def" : "to (for, in, with or by) these (persons or things)",
		"derivation" : "dative case plural masculine or neuter of G3778 (οὗτος)"
	},
	"G2258" : {
		"kjv" : "+ agree, be, X have (+ charge of), hold, use, was(-t), were",
		"lemma" : "ἦν",
		"translit" : "ēn",
		"derivation" : "imperfect of G1510 (εἰμί)",
		"def" : "I (thou, etc.) was (wast or were)"
	},
	"G2704" : {
		"translit" : "kataphtheírō",
		"lemma" : "καταφθείρω",
		"kjv" : "--corrupt, utterly perish",
		"def" : "to spoil entirely, i.e. (literally) to destroy; or (figuratively) to deprave; ",
		"derivation" : "from G2596 (κατά) and G5351 (φθείρω)"
	},
	"G424" : {
		"derivation" : "from G303 (ἀνά) and G2064 (ἔρχομαι)",
		"def" : "to ascend",
		"kjv" : "go up",
		"lemma" : "ἀνέρχομαι",
		"translit" : "anérchomai"
	},
	"G5309" : {
		"kjv" : "be highminded",
		"translit" : "hypsēlophronéō",
		"lemma" : "ὑψηλοφρονέω",
		"derivation" : "from a compound of G5308 (ὑψηλός) and G5424 (φρήν)",
		"def" : "to be lofty in mind, i.e. arrogant"
	},
	"G1484" : {
		"kjv" : "Gentile, heathen, nation, people",
		"lemma" : "ἔθνος",
		"translit" : "éthnos",
		"derivation" : "probably from G1486 (ἔθω)",
		"def" : "a race (as of the same habit), i.e. a tribe; specially, a foreign (non-Jewish) one (usually, by implication, pagan)"
	},
	"G2256" : {
		"translit" : "hēmiṓrion",
		"lemma" : "ἡμιώριον",
		"kjv" : "half an hour",
		"def" : "a half-hour",
		"derivation" : "from the base of G2255 (ἥμισυ) and G5610 (ὥρα)"
	},
	"G3691" : {
		"def" : "keen; by analogy, rapid",
		"derivation" : "probably akin to the base of G188 (ἀκμήν) (\"acid\")",
		"translit" : "oxýs",
		"lemma" : "ὀξύς",
		"kjv" : "sharp, swift"
	},
	"G908" : {
		"lemma" : "βάπτισμα",
		"translit" : "báptisma",
		"kjv" : "baptism",
		"def" : "baptism (technically or figuratively)",
		"derivation" : "from G907 (βαπτίζω)"
	},
	"G1604" : {
		"def" : "completion",
		"derivation" : "from G1603 (ἐκπληρόω)",
		"lemma" : "ἐκπλήρωσις",
		"translit" : "ekplḗrōsis",
		"kjv" : "accomplishment"
	},
	"G2594" : {
		"def" : "to be strong, i.e. (figuratively) steadfast (patient)",
		"derivation" : "from a derivative of G2904 (κράτος) (transposed)",
		"translit" : "karteréō",
		"lemma" : "καρτερέω",
		"kjv" : "endure"
	},
	"G13" : {
		"lemma" : "Ἄγαβος",
		"translit" : "Ágabos",
		"kjv" : "Agabus",
		"def" : "Agabus, an Israelite",
		"derivation" : "of Hebrew origin (compare H2285)"
	},
	"G2660" : {
		"kjv" : "prick",
		"lemma" : "κατανύσσω",
		"translit" : "katanýssō",
		"derivation" : "from G2596 (κατά) and G3572 (νύσσω)",
		"def" : "to pierce thoroughly, i.e. (figuratively) to agitate violently (\"sting to the quick\")"
	},
	"G5508" : {
		"translit" : "Chíos",
		"lemma" : "Χίος",
		"kjv" : "Chios",
		"def" : "Chios, an island in the Mediterranean",
		"derivation" : "of uncertain derivation"
	},
	"G4270" : {
		"lemma" : "προγράφω",
		"translit" : "prográphō",
		"kjv" : "before ordain, evidently set forth, write (afore, aforetime)",
		"def" : "to write previously; figuratively, to announce, prescribe",
		"derivation" : "from G4253 (πρό) and G1125 (γράφω)"
	},
	"G3712" : {
		"translit" : "orguiá",
		"lemma" : "ὀργυιά",
		"kjv" : "fathom",
		"def" : "a stretch of the arms, i.e. a fathom",
		"derivation" : "from G3713 (ὀρέγομαι)"
	},
	"G968" : {
		"def" : "a step, i.e. foot-breath; by implication, a rostrum, i.e. a tribunal",
		"derivation" : "from the base of G939 (βάσις)",
		"translit" : "bēma",
		"lemma" : "βῆμα",
		"kjv" : "judgment-seat, set (foot) on, throne"
	},
	"G3546" : {
		"derivation" : "from G3543 (νομίζω)",
		"def" : "what is reckoned as of value (after the Latin numisma), i.e. current coin",
		"kjv" : "money",
		"lemma" : "νόμισμα",
		"translit" : "nómisma"
	},
	"G4237" : {
		"def" : "a garden plot, i.e. (by implication, of regular beds) a row (repeated in plural by Hebraism, to indicate an arrangement)",
		"derivation" : "perhaps from (a leek, and so an onion-patch)",
		"lemma" : "πρασιά",
		"translit" : "prasiá",
		"kjv" : "in ranks"
	},
	"G2583" : {
		"lemma" : "κανών",
		"translit" : "kanṓn",
		"kjv" : "line, rule",
		"def" : "a rule (\"canon\"), i.e. (figuratively) a standard (of faith and practice); by implication, a boundary, i.e. (figuratively) a sphere (of activity)",
		"derivation" : "from (a straight reed, i.e. rod)"
	},
	"G1588" : {
		"kjv" : "chosen, elect",
		"translit" : "eklektós",
		"lemma" : "ἐκλεκτός",
		"derivation" : "from G1586 (ἐκλέγομαι)",
		"def" : "select; by implication, favorite"
	},
	"G5134" : {
		"derivation" : "from the base of (to wound",
		"def" : "akin to the base of G2352 (θραύω), G5147 (τρίβος), G5149 (τρίζω), etc.); a wound",
		"kjv" : "wound",
		"translit" : "traûma",
		"lemma" : "τραῦμα"
	},
	"G1179" : {
		"def" : "the ten-city region; the Decapolis, a district in Syria",
		"derivation" : "from G1176 (δέκα) and G4172 (πόλις)",
		"translit" : "Dekápolis",
		"lemma" : "Δεκάπολις",
		"kjv" : "Decapolis"
	},
	"G453" : {
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G3539 (νοιέω)",
		"def" : "unintelligent; by implication, sensual",
		"kjv" : "fool(-ish), unwise",
		"translit" : "anóētos",
		"lemma" : "ἀνόητος"
	},
	"G2949" : {
		"derivation" : "from (to swell (with young), i.e. bend, curve)",
		"def" : "a billow (as bursting or toppling)",
		"kjv" : "wave",
		"lemma" : "κῦμα",
		"translit" : "kŷma"
	},
	"G3782" : {
		"derivation" : "from G3784 (ὀφείλω)",
		"def" : "indebtedness, i.e. (concretely) a sum owed; figuratively, obligation, i.e. (conjugal) duty",
		"kjv" : "debt, due",
		"translit" : "opheilḗ",
		"lemma" : "ὀφειλή"
	},
	"G4131" : {
		"lemma" : "πλήκτης",
		"translit" : "plḗktēs",
		"kjv" : "striker",
		"def" : "a smiter, i.e. pugnacious (quarrelsome)",
		"derivation" : "from G4141 (πλήσσω)"
	},
	"G527" : {
		"def" : "soft",
		"derivation" : "of uncertain derivation",
		"translit" : "apalós",
		"lemma" : "ἀπαλός",
		"kjv" : "tender"
	},
	"G3094" : {
		"translit" : "Magdalēnḗ",
		"lemma" : "Μαγδαληνή",
		"kjv" : "Magdalene",
		"def" : "a female Magdalene, i.e. inhabitant of Magdala",
		"derivation" : "feminine of a derivative of G3093 (Μαγδαλά)"
	},
	"G4831" : {
		"kjv" : "follower together",
		"translit" : "symmimētḗs",
		"lemma" : "συμμιμητής",
		"derivation" : "from a presumed compound of G4862 (σύν) and G3401 (μιμέομαι)",
		"def" : "a co-imitator, i.e. fellow votary"
	},
	"G5385" : {
		"derivation" : "from G5386 (φιλόσοφος)",
		"def" : "\"philosophy\", i.e. (specially), Jewish sophistry",
		"kjv" : "philosophy",
		"lemma" : "φιλοσοφία",
		"translit" : "philosophía"
	},
	"G3779" : {
		"kjv" : "after that, after (in) this manner, as, even (so), for all that, like(-wise), no more, on this fashion(-wise), so (in like manner), thus, what",
		"lemma" : "οὕτω",
		"translit" : "hoútō",
		"derivation" : "adverb from G3778 (οὗτος)",
		"def" : "in this way (referring to what precedes or follows)"
	},
	"G4403" : {
		"derivation" : "feminine of (hindmost)",
		"def" : "the stern of a ship",
		"kjv" : "hinder part, stern",
		"lemma" : "πρύμνα",
		"translit" : "prýmna"
	},
	"G2448" : {
		"kjv" : "Judah",
		"translit" : "Ioudá",
		"lemma" : "Ἰουδά",
		"derivation" : "of Hebrew origin (H3063 or perhaps H3194)",
		"def" : "Judah (i.e. Jehudah or Juttah), a part of (or place in) Palestine"
	},
	"G4209" : {
		"derivation" : "of Latin origin",
		"def" : "the \"purple\" mussel, i.e. (by implication) the red-blue color itself, and finally a garment dyed with it",
		"kjv" : "purple",
		"lemma" : "πορφύρα",
		"translit" : "porphýra"
	},
	"G5614" : {
		"kjv" : "hosanna",
		"lemma" : "ὡσαννά",
		"translit" : "hōsanná",
		"derivation" : "of Hebrew origin (H3467 and H4994)",
		"def" : "oh save!; hosanna (i.e. hoshia-na), an exclamation of adoration"
	},
	"G4974" : {
		"derivation" : "neuter of a presumed derivative probably of the same as (a ball, \"sphere\"",
		"def" : "compare the feminine , a hammer); the ankle (as globular)",
		"kjv" : "ancle bone",
		"lemma" : "σφυρόν",
		"translit" : "sphyrón"
	},
	"G698" : {
		"kjv" : "Areopagite",
		"translit" : "Areopagítēs",
		"lemma" : "Ἀρεοπαγίτης",
		"derivation" : "from G697 (Ἄρειος Πάγος)",
		"def" : "an Areopagite or member of the court held on Mars' Hill"
	},
	"G3840" : {
		"kjv" : "on every side, round about",
		"translit" : "pántothen",
		"lemma" : "πάντοθεν",
		"derivation" : "adverb (of source) from G3956 (πᾶς)",
		"def" : "from (i.e. on) all sides"
	},
	"G4833" : {
		"kjv" : "make conformable unto",
		"translit" : "symmorphóō",
		"lemma" : "συμμορφόω",
		"derivation" : "from G4832 (σύμμορφος)",
		"def" : "to render like, i.e. (figuratively) to assimilate"
	},
	"G1145" : {
		"translit" : "dakrýō",
		"lemma" : "δακρύω",
		"kjv" : "weep",
		"def" : "to shed tears",
		"derivation" : "from G1144 (δάκρυ)"
	},
	"G2018" : {
		"translit" : "epiphérō",
		"lemma" : "ἐπιφέρω",
		"kjv" : "add, bring (against), take",
		"def" : "to bear upon (or further), i.e. adduce (personally or judicially (accuse, inflict)), superinduce",
		"derivation" : "from G1909 (ἐπί) and G5342 (φέρω)"
	},
	"G3106" : {
		"translit" : "makarízō",
		"lemma" : "μακαρίζω",
		"kjv" : "call blessed, count happy",
		"def" : "to beatify, i.e. pronounce (or esteem) fortunate",
		"derivation" : "from G3107 (μακάριος)"
	},
	"G2776" : {
		"def" : "the head (as the part most readily taken hold of), literally or figuratively",
		"derivation" : "from the primary (in the sense of seizing)",
		"lemma" : "κεφαλή",
		"translit" : "kephalḗ",
		"kjv" : "head"
	},
	"G3871" : {
		"def" : "to cover alongside, i.e. veil (figuratively)",
		"derivation" : "from G3844 (παρά) and G2572 (καλύπτω)",
		"translit" : "parakalýptō",
		"lemma" : "παρακαλύπτω",
		"kjv" : "hide"
	},
	"G4438" : {
		"def" : "to box (with the fist), i.e. contend (as a boxer) at the games (figuratively)",
		"derivation" : "from a derivative of the same as G4435 (πυγμή)",
		"translit" : "pyktéō",
		"lemma" : "πυκτέω",
		"kjv" : "fight"
	},
	"G3699" : {
		"lemma" : "ὅπου",
		"translit" : "hópou",
		"kjv" : "in what place, where(-as, -soever), whither (+ soever)",
		"def" : "what(-ever) where, i.e. at whichever spot",
		"derivation" : "from G3739 (ὅς) and G4225 (πού)"
	},
	"G18" : {
		"lemma" : "ἀγαθός",
		"translit" : "agathós",
		"kjv" : "benefit, good(-s, things), well",
		"def" : "\"good\" (in any sense, often as noun)",
		"derivation" : "a primary word"
	},
	"G2426" : {
		"kjv" : "sufficiency",
		"translit" : "hikanótēs",
		"lemma" : "ἱκανότης",
		"derivation" : "from G2425 (ἱκανός)",
		"def" : "ability"
	},
	"G457" : {
		"kjv" : "X open",
		"lemma" : "ἄνοιξις",
		"translit" : "ánoixis",
		"derivation" : "from G455 (ἀνοίγω)",
		"def" : "opening (throat)"
	},
	"G4249" : {
		"def" : "to saw in two",
		"derivation" : "a strengthened form of a primary prio (to saw)",
		"translit" : "prízō",
		"lemma" : "πρίζω",
		"kjv" : "saw asunder"
	},
	"G4285" : {
		"def" : "to lead the way for others, i.e. show deference",
		"derivation" : "from G4253 (πρό) and G2233 (ἡγέομαι)",
		"translit" : "proēgéomai",
		"lemma" : "προηγέομαι",
		"kjv" : "prefer"
	},
	"G1324" : {
		"derivation" : "prolongation from G1364 (δίς)",
		"def" : "double, i.e. twin; Didymus, a Christian",
		"kjv" : "Didymus",
		"lemma" : "Δίδυμος",
		"translit" : "Dídymos"
	},
	"G3683" : {
		"derivation" : "from a derivative of G3685 (ὀνίνημι) and G5411 (φόρος)",
		"def" : "profit-bearer; Onesiphorus, a Christian",
		"kjv" : "Onespiphorus",
		"lemma" : "Ὀνησίφορος",
		"translit" : "Onēsíphoros"
	},
	"G4786" : {
		"def" : "to commingle, i.e. (figuratively) to combine or assimilate",
		"derivation" : "from G4862 (σύν) and G2767 (κεράννυμι)",
		"lemma" : "συγκεράννυμι",
		"translit" : "synkeránnymi",
		"kjv" : "mix with, temper together"
	},
	"G4204" : {
		"def" : "a strumpet; figuratively, an idolater",
		"derivation" : "feminine of G4205 (πόρνος)",
		"lemma" : "πόρνη",
		"translit" : "pórnē",
		"kjv" : "harlot, whore"
	},
	"G4568" : {
		"derivation" : "of Hebrew origin (H5429)",
		"def" : "a certain measure for things dry",
		"kjv" : "measure",
		"lemma" : "σάτον",
		"translit" : "sáton"
	},
	"G4603" : {
		"kjv" : "(of) iron",
		"lemma" : "σιδήρεος",
		"translit" : "sidḗreos",
		"derivation" : "from G4604 (σίδηρος)",
		"def" : "made of iron"
	},
	"G1533" : {
		"kjv" : "bring (in), lead into",
		"lemma" : "εἰσφέρω",
		"translit" : "eisphérō",
		"derivation" : "from G1519 (εἰς) and G5342 (φέρω)",
		"def" : "to carry inward (literally or figuratively)"
	},
	"G3028" : {
		"lemma" : "λῆμψις",
		"translit" : "lēmpsis",
		"kjv" : "receiving",
		"def" : "receipt (the act)",
		"derivation" : "from G2983 (λαμβάνω)"
	},
	"G1750" : {
		"lemma" : "ἐνειλέω",
		"translit" : "eneiléō",
		"kjv" : "wrap in",
		"def" : "to enwrap",
		"derivation" : "from G1772 (ἔννομος) and the base of G1507 (εἱλίσσω)"
	},
	"G2876" : {
		"translit" : "kórax",
		"lemma" : "κόραξ",
		"kjv" : "raven",
		"def" : "a crow (from its voracity)",
		"derivation" : "perhaps from G2880 (κορέννυμι)"
	},
	"G2779" : {
		"kjv" : "garden",
		"translit" : "kēpos",
		"lemma" : "κῆπος",
		"derivation" : "of uncertain affinity",
		"def" : "a garden"
	},
	"G925" : {
		"kjv" : "overcharge",
		"lemma" : "βαρύνω",
		"translit" : "barýnō",
		"derivation" : "from G926 (βαρύς)",
		"def" : "to burden (figuratively)"
	},
	"G5109" : {
		"derivation" : "another form of G5038 (τεῖχος)",
		"def" : "a wall",
		"kjv" : "wall",
		"translit" : "toîchos",
		"lemma" : "τοῖχος"
	},
	"G2817" : {
		"def" : "heirship, i.e. (concretely) a patrimony or (genitive case) a possession",
		"derivation" : "from G2818 (κληρονόμος)",
		"translit" : "klēronomía",
		"lemma" : "κληρονομία",
		"kjv" : "inheritance"
	},
	"G3832" : {
		"lemma" : "πανοικί",
		"translit" : "panoikí",
		"kjv" : "with all his house",
		"def" : "with the whole family",
		"derivation" : "adverb from G3956 (πᾶς) and G3624 (οἶκος)"
	},
	"G2055" : {
		"kjv" : "goat",
		"lemma" : "ἐρίφιον",
		"translit" : "eríphion",
		"derivation" : "from G2056 (ἔριφος)",
		"def" : "a kidling, i.e. (genitive case) goat (symbolically, wicked person)"
	},
	"G4925" : {
		"kjv" : "build together",
		"translit" : "synoikodoméō",
		"lemma" : "συνοικοδομέω",
		"derivation" : "from G4862 (σύν) and G3618 (οἰκοδομέω)",
		"def" : "to construct, i.e. (passively) to compose (in company with other Christians, figuratively)"
	},
	"G4356" : {
		"kjv" : "receiving",
		"translit" : "próslēpsis",
		"lemma" : "πρόσληψις",
		"derivation" : "from G4355 (προσλαμβάνω)",
		"def" : "admission"
	},
	"G5503" : {
		"derivation" : "feminine of a presumed derivative apparently from the base of G5490 (χάσμα) through the idea of deficiency",
		"def" : "a widow (as lacking a husband), literally or figuratively",
		"kjv" : "widow",
		"translit" : "chḗra",
		"lemma" : "χήρα"
	},
	"G2820" : {
		"kjv" : "obtain an inheritance",
		"lemma" : "κληρόω",
		"translit" : "klēróō",
		"derivation" : "from G2819 (κλῆρος)",
		"def" : "to allot, i.e. (figuratively) to assign (a privilege)"
	},
	"G91" : {
		"translit" : "adikéō",
		"lemma" : "ἀδικέω",
		"kjv" : "hurt, injure, be an offender, be unjust, (do, suffer, take) wrong",
		"def" : "to be unjust, i.e. (actively) do wrong (morally, socially or physically)",
		"derivation" : "from G94 (ἄδικος)"
	},
	"G1077" : {
		"def" : "birthday ceremonies",
		"derivation" : "neuter plural of a derivative of G1078 (γένεσις)",
		"lemma" : "γενέσια",
		"translit" : "genésia",
		"kjv" : "birthday"
	},
	"G42" : {
		"lemma" : "ἁγιωσύνη",
		"translit" : "hagiōsýnē",
		"kjv" : "holiness",
		"def" : "sacredness (i.e. properly, the quality)",
		"derivation" : "from G40 (ἅγιος)"
	},
	"G4368" : {
		"derivation" : "feminine of a derivative of G4291 (προΐστημι)",
		"def" : "a patroness, i.e. assistant",
		"kjv" : "succourer",
		"translit" : "prostátis",
		"lemma" : "προστάτις"
	},
	"G2700" : {
		"kjv" : "thrust through",
		"lemma" : "κατατοξεύω",
		"translit" : "katatoxeúō",
		"derivation" : "from G2596 (κατά) and a derivative of G5115 (τόξον)",
		"def" : "to shoot down with an arrow or other missile"
	},
	"G2164" : {
		"derivation" : "from G2095 (εὖ) and G5409 (φορέω)",
		"def" : "to bear well, i.e. be fertile",
		"kjv" : "bring forth abundantly",
		"translit" : "euphoréō",
		"lemma" : "εὐφορέω"
	},
	"G5279" : {
		"kjv" : "put in mind, remember, bring to (put in) remembrance",
		"translit" : "hypomimnḗskō",
		"lemma" : "ὑπομιμνήσκω",
		"derivation" : "from G5259 (ὑπό) and G3403 (μιμνήσκω)",
		"def" : "to remind quietly, i.e. suggest to the (middle voice, one's own) memory"
	},
	"G5077" : {
		"def" : "to incinerate, i.e. consume",
		"derivation" : "from (ashes)",
		"lemma" : "τεφρόω",
		"translit" : "tephróō",
		"kjv" : "turn to ashes"
	},
	"G1456" : {
		"kjv" : "dedication",
		"lemma" : "ἐγκαίνια",
		"translit" : "enkaínia",
		"derivation" : "neuter plural of a presumed compound from G1722 (ἐν) and G2537 (καινός)",
		"def" : "innovatives, i.e. (specially) renewal (of religious services after the Antiochian interruption)"
	},
	"G4693" : {
		"kjv" : "cave, den",
		"lemma" : "σπήλαιον",
		"translit" : "spḗlaion",
		"derivation" : "neuter of a presumed derivative of (a grotto)",
		"def" : "a cavern; by implication, a hiding-place or resort"
	},
	"G5437" : {
		"translit" : "phygḗ",
		"lemma" : "φυγή",
		"kjv" : "flight",
		"def" : "a fleeing, i.e. escape",
		"derivation" : "from G5343 (φεύγω)"
	},
	"G670" : {
		"def" : "to unload",
		"derivation" : "from G575 (ἀπό) and the middle voice of G5412 (φορτίζω)",
		"lemma" : "ἀποφορτίζομαι",
		"translit" : "apophortízomai",
		"kjv" : "unlade"
	},
	"G4133" : {
		"def" : "moreover (besides), i.e. albeit, save that, rather, yet",
		"derivation" : "from G4119 (πλείων)",
		"translit" : "plḗn",
		"lemma" : "πλήν",
		"kjv" : "but (rather), except, nevertheless, notwithstanding, save, than"
	},
	"G4191" : {
		"def" : "more evil",
		"derivation" : "comparative of G4190 (πονηρός)",
		"translit" : "ponēróteros",
		"lemma" : "πονηρότερος",
		"kjv" : "more wicked"
	},
	"G4378" : {
		"derivation" : "from a comparative of G4314 (πρός) and (to pour)",
		"def" : "a shedding forth, i.e. affusion",
		"kjv" : "sprinkling",
		"lemma" : "πρόσχυσις",
		"translit" : "próschysis"
	},
	"G4259" : {
		"kjv" : "porch",
		"lemma" : "προαύλιον",
		"translit" : "proaúlion",
		"derivation" : "neuter of a presumed compound of G4253 (πρό) and G833 (αὐλή)",
		"def" : "a forecourt, i.e. vestibule (alley-way)"
	},
	"G359" : {
		"derivation" : "from G360 (ἀναλύω)",
		"def" : "departure",
		"kjv" : "departure",
		"translit" : "análysis",
		"lemma" : "ἀνάλυσις"
	},
	"G5546" : {
		"kjv" : "Christian",
		"translit" : "Christianós",
		"lemma" : "Χριστιανός",
		"derivation" : "from G5547 (Χριστός)",
		"def" : "a Christian, i.e. follower of Christ"
	},
	"G2867" : {
		"derivation" : "from (dust",
		"def" : "by analogy, lime); to whitewash",
		"kjv" : "whiten",
		"lemma" : "κονιάω",
		"translit" : "koniáō"
	},
	"G2494" : {
		"lemma" : "Ἰωνάν",
		"translit" : "Iōnán",
		"kjv" : "Jonan",
		"def" : "Jonan, an Israelite",
		"derivation" : "probably for G2491 (Ἰωάννης) or G2495 (Ἰωνᾶς)"
	},
	"G2743" : {
		"lemma" : "καυτηριάζω",
		"translit" : "kautēriázō",
		"kjv" : "sear with a hot iron",
		"def" : "to brand (\"cauterize\"), i.e. (by implication) to render unsensitive (figuratively)",
		"derivation" : "from a derivative of G2545 (καίω)"
	},
	"G5343" : {
		"def" : "to run away (literally or figuratively); by implication, to shun; by analogy, to vanish",
		"derivation" : "apparently a primary verb",
		"translit" : "pheúgō",
		"lemma" : "φεύγω",
		"kjv" : "escape, flee (away)"
	},
	"G5475" : {
		"def" : "copper (the substance, or some implement or coin made of it)",
		"derivation" : "perhaps from G5465 (χαλάω) through the idea of hollowing out as a vessel (this metal being chiefly used for that purpose)",
		"lemma" : "χαλκός",
		"translit" : "chalkós",
		"kjv" : "brass, money"
	},
	"G783" : {
		"derivation" : "from G782 (ἀσπάζομαι)",
		"def" : "a greeting (in person or by letter)",
		"kjv" : "greeting, salutation",
		"translit" : "aspasmós",
		"lemma" : "ἀσπασμός"
	},
	"G5351" : {
		"kjv" : "corrupt (self), defile, destroy",
		"lemma" : "φθείρω",
		"translit" : "phtheírō",
		"derivation" : "probably strengthened from (to pine or waste)",
		"def" : "properly, to shrivel or wither, i.e. to spoil (by any process) or (generally) to ruin (especially figuratively, by moral influences, to deprave)"
	},
	"G5431" : {
		"lemma" : "φροντίζω",
		"translit" : "phrontízō",
		"kjv" : "be careful",
		"def" : "to exercise thought, i.e. be anxious",
		"derivation" : "from a derivative of G5424 (φρήν)"
	},
	"G4856" : {
		"derivation" : "from G4859 (σύμφωνος)",
		"def" : "to be harmonious, i.e. (figuratively) to accord (be suitable, concur) or stipulate (by compact)",
		"kjv" : "agree (together, with)",
		"lemma" : "συμφωνέω",
		"translit" : "symphōnéō"
	},
	"G1856" : {
		"kjv" : "drive out, thrust in",
		"translit" : "exōthéō",
		"lemma" : "ἐξωθέω",
		"derivation" : "from G1537 (ἐκ) and (to push)",
		"def" : "to expel; by implication, to propel"
	},
	"G531" : {
		"def" : "not passing away, i.e. untransferable (perpetual)",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G3845 (παραβαίνω)",
		"lemma" : "ἀπαράβατος",
		"translit" : "aparábatos",
		"kjv" : "unchangeable"
	},
	"G4652" : {
		"kjv" : "dark, full of darkness",
		"lemma" : "σκοτεινός",
		"translit" : "skoteinós",
		"derivation" : "from G4655 (σκότος)",
		"def" : "opaque, i.e. (figuratively) benighted"
	},
	"G1887" : {
		"derivation" : "from G1909 (ἐπί) and G839 (αὔριον)",
		"def" : "occurring on the succeeding day, i.e. (G2250 (ἡμέρα) being implied) to-morrow",
		"kjv" : "day following, morrow, next day (after)",
		"translit" : "epaúrion",
		"lemma" : "ἐπαύριον"
	},
	"G1234" : {
		"derivation" : "from G1223 (διά) and G1111 (γογγύζω)",
		"def" : "to complain throughout a crowd",
		"kjv" : "murmur",
		"lemma" : "διαγογγύζω",
		"translit" : "diagongýzō"
	},
	"G100" : {
		"kjv" : "abundance",
		"lemma" : "ἁδρότης",
		"translit" : "hadrótēs",
		"derivation" : "from (stout)",
		"def" : "plumpness, i.e. (figuratively) liberality"
	},
	"G899" : {
		"kjv" : "deep(-ness, things), depth",
		"lemma" : "βάθος",
		"translit" : "báthos",
		"derivation" : "from the same as G901 (βαθύς)",
		"def" : "profundity, i.e. (by implication) extent; (figuratively) mystery"
	},
	"G1089" : {
		"def" : "to taste; by implication, to eat; figuratively, to experience (good or ill)",
		"derivation" : "a primary verb",
		"translit" : "geúomai",
		"lemma" : "γεύομαι",
		"kjv" : "eat, taste"
	},
	"G3176" : {
		"kjv" : "exceeding great",
		"translit" : "mégistos",
		"lemma" : "μέγιστος",
		"derivation" : "superlative of G3173 (μέγας)",
		"def" : "greatest or very great"
	},
	"G4273" : {
		"def" : "a surrender",
		"derivation" : "from G4272 (προδίδωμι) (in the sense of giving forward into another's (the enemy's) hands)",
		"lemma" : "προδότης",
		"translit" : "prodótēs",
		"kjv" : "betrayer, traitor"
	},
	"G2025" : {
		"kjv" : "anoint",
		"translit" : "epichríō",
		"lemma" : "ἐπιχρίω",
		"derivation" : "from G1909 (ἐπί) and G5548 (χρίω)",
		"def" : "to smear over"
	},
	"G2408" : {
		"derivation" : "of Hebrew origin (H3414)",
		"def" : "Hieremias (i.e. Jermijah), an Israelite",
		"kjv" : "Jeremiah",
		"lemma" : "Ἱερεμίας",
		"translit" : "Hieremías"
	},
	"G784" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G4695 (σπιλόω)",
		"def" : "unblemished (physically or morally)",
		"kjv" : "without spot, unspotted",
		"translit" : "áspilos",
		"lemma" : "ἄσπιλος"
	},
	"G4549" : {
		"translit" : "Saoúl",
		"lemma" : "Σαούλ",
		"kjv" : "Saul",
		"def" : "Saul (i.e. Shaul), the Jewish name of Paul",
		"derivation" : "of Hebrew origin (H7586)"
	},
	"G4481" : {
		"kjv" : "Remphan",
		"translit" : "Rhemphán",
		"lemma" : "Ῥεμφάν",
		"derivation" : "by incorrect transliteration for a word of Hebrew origin (H3594)",
		"def" : "Remphan (i.e. Kijun), an Egyptian idol"
	},
	"G3717" : {
		"kjv" : "straight, upright",
		"translit" : "orthós",
		"lemma" : "ὀρθός",
		"derivation" : "probably from the base of G3735 (ὄρος)",
		"def" : "right (as rising), i.e. (perpendicularly) erect (figuratively, honest), or (horizontally) level or direct"
	},
	"G4869" : {
		"derivation" : "from G4862 (σύν) and G164 (αἰχμάλωτος)",
		"def" : "a co-captive",
		"kjv" : "fellowprisoner",
		"translit" : "synaichmálōtos",
		"lemma" : "συναιχμάλωτος"
	},
	"G3171" : {
		"kjv" : "greatly",
		"translit" : "megálōs",
		"lemma" : "μεγάλως",
		"derivation" : "adverb from G3173 (μέγας)",
		"def" : "much"
	},
	"G4284" : {
		"kjv" : "be better",
		"lemma" : "προέχομαι",
		"translit" : "proéchomai",
		"derivation" : "middle voice from G4253 (πρό) and G2192 (ἔχω)",
		"def" : "to hold oneself before others, i.e. (figuratively) to excel"
	},
	"G87" : {
		"lemma" : "ἀδιάκριτος",
		"translit" : "adiákritos",
		"kjv" : "without partiality",
		"def" : "properly, undistinguished, i.e. (actively) impartial",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G1252 (διακρίνω)"
	},
	"G343" : {
		"def" : "to unveil",
		"derivation" : "from G303 (ἀνά) (in the sense of reversal) and G2572 (καλύπτω)",
		"lemma" : "ἀνακαλύπτω",
		"translit" : "anakalýptō",
		"kjv" : "open, (un-)taken away"
	},
	"G5283" : {
		"derivation" : "from G5282 (ὑπονοέω)",
		"def" : "suspicion",
		"kjv" : "surmising",
		"lemma" : "ὑπόνοια",
		"translit" : "hypónoia"
	},
	"G4648" : {
		"kjv" : "consider, take heed, look at (on), mark",
		"lemma" : "σκοπέω",
		"translit" : "skopéō",
		"derivation" : "from G4649 (σκοπός)",
		"def" : "to take aim at (spy), i.e. (figuratively) regard"
	},
	"G2778" : {
		"derivation" : "of Latin origin",
		"def" : "properly, an enrollment (\"census\"), i.e. (by implication) a tax",
		"kjv" : "tribute",
		"lemma" : "κῆνσος",
		"translit" : "kēnsos"
	},
	"G980" : {
		"derivation" : "from G979 (βίος)",
		"def" : "to spend existence",
		"kjv" : "live",
		"lemma" : "βιόω",
		"translit" : "bióō"
	},
	"G820" : {
		"translit" : "átimos",
		"lemma" : "ἄτιμος",
		"kjv" : "despised, without honour, less honourable (comparative degree)",
		"def" : "(negatively) unhonoured or (positively) dishonoured",
		"derivation" : "from G1 (Α) (as a negative particle) and G5092 (τιμή)"
	},
	"G3836" : {
		"kjv" : "from every quarter",
		"lemma" : "πανταχόθεν",
		"translit" : "pantachóthen",
		"derivation" : "adverb (of source) from G3837 (πανταχοῦ)",
		"def" : "from all directions"
	},
	"G1025" : {
		"kjv" : "babe, (young) child, infant",
		"translit" : "bréphos",
		"lemma" : "βρέφος",
		"derivation" : "of uncertain affinity",
		"def" : "an infant (properly, unborn) literally or figuratively"
	},
	"G4491" : {
		"derivation" : "apparently a primary word",
		"def" : "a \"root\" (literally or figuratively)",
		"kjv" : "root",
		"translit" : "rhíza",
		"lemma" : "ῥίζα"
	},
	"G5064" : {
		"derivation" : "a plural number",
		"def" : "four",
		"kjv" : "four",
		"lemma" : "τέσσαρες",
		"translit" : "téssares"
	},
	"G242" : {
		"kjv" : "leap, spring up",
		"lemma" : "ἅλλομαι",
		"translit" : "hállomai",
		"derivation" : "middle voice of apparently a primary verb",
		"def" : "to jump; figuratively, to gush"
	},
	"G1109" : {
		"def" : "a knower",
		"derivation" : "from G1097 (γινώσκω)",
		"translit" : "gnṓstēs",
		"lemma" : "γνώστης",
		"kjv" : "expert"
	},
	"G1318" : {
		"kjv" : "taught, which … teacheth",
		"translit" : "didaktós",
		"lemma" : "διδακτός",
		"derivation" : "from G1321 (διδάσκω)",
		"def" : "(subjectively) instructed, or (objectively) communicated by teaching"
	},
	"G2473" : {
		"derivation" : "from G2470 (ἴσος) and G5590 (ψυχή)",
		"def" : "of similar spirit",
		"kjv" : "likeminded",
		"translit" : "isópsychos",
		"lemma" : "ἰσόψυχος"
	},
	"G938" : {
		"kjv" : "queen",
		"lemma" : "βασίλισσα",
		"translit" : "basílissa",
		"derivation" : "feminine from G936 (βασιλεύω)",
		"def" : "a queen"
	},
	"G4986" : {
		"derivation" : "from the base of G4982 (σώζω) and G3962 (πατήρ)",
		"def" : "of a safe father; Sopatrus, a Christian",
		"kjv" : "Sopater",
		"translit" : "Sṓpatros",
		"lemma" : "Σώπατρος"
	},
	"G2226" : {
		"derivation" : "neuter of a derivative of G2198 (ζάω)",
		"def" : "a live thing, i.e. an animal",
		"kjv" : "beast",
		"lemma" : "ζῶον",
		"translit" : "zōon"
	},
	"G5302" : {
		"def" : "to be later, i.e. (by implication) to be inferior; generally, to fall short (be deficient)",
		"derivation" : "from G5306 (ὕστερος)",
		"lemma" : "ὑστερέω",
		"translit" : "hysteréō",
		"kjv" : "come behind (short), be destitute, fail, lack, suffer need, (be in) want, be the worse"
	},
	"G2264" : {
		"derivation" : "compound of (a \"hero\") and G1491 (εἶδος)",
		"def" : "heroic; Herod, the name of four Jewish kings",
		"kjv" : "Herod",
		"translit" : "Hērṓdēs",
		"lemma" : "Ἡρώδης"
	},
	"G185" : {
		"translit" : "akéraios",
		"lemma" : "ἀκέραιος",
		"kjv" : "harmless, simple",
		"def" : "unmixed, i.e. (figuratively) innocent",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G2767 (κεράννυμι)"
	},
	"G2892" : {
		"kjv" : "watch",
		"translit" : "koustōdía",
		"lemma" : "κουστωδία",
		"derivation" : "of Latin origin",
		"def" : "\"custody\", i.e. a Roman sentry"
	},
	"G2935" : {
		"derivation" : "from G2932 (κτάομαι)",
		"def" : "an owner",
		"kjv" : "possessor",
		"lemma" : "κτήτωρ",
		"translit" : "ktḗtōr"
	},
	"G4308" : {
		"kjv" : "foresee, see before",
		"lemma" : "προοράω",
		"translit" : "prooráō",
		"derivation" : "from G4253 (πρό) and G3708 (ὁράω)",
		"def" : "to behold in advance, i.e. (actively) to notice (another) previously, or (middle voice) to keep in (one's own) view"
	},
	"G1730" : {
		"lemma" : "ἔνδειγμα",
		"translit" : "éndeigma",
		"kjv" : "manifest token",
		"def" : "an indication (concretely)",
		"derivation" : "from G1731 (ἐνδείκνυμι)"
	},
	"G1085" : {
		"lemma" : "γένος",
		"translit" : "génos",
		"kjv" : "born, country(-man), diversity, generation, kind(-red), nation, offspring, stock",
		"def" : "\"kin\" (abstract or concrete, literal or figurative, individual or collective)",
		"derivation" : "from G1096 (γίνομαι)"
	},
	"G371" : {
		"kjv" : "unworthily",
		"lemma" : "ἀναξίως",
		"translit" : "anaxíōs",
		"derivation" : "adverb from G370 (ἀνάξιος)",
		"def" : "irreverently"
	},
	"G3590" : {
		"kjv" : "eighth",
		"translit" : "ógdoos",
		"lemma" : "ὄγδοος",
		"derivation" : "from G3638 (ὀκτώ)",
		"def" : "the eighth"
	},
	"G2544" : {
		"kjv" : "nevertheless, though",
		"lemma" : "καίτοιγε",
		"translit" : "kaítoige",
		"derivation" : "from G2543 (καίτοι) and G1065 (γέ)",
		"def" : "and yet indeed, i.e. although really"
	},
	"G4165" : {
		"def" : "to tend as a shepherd of (figuratively, superviser)",
		"derivation" : "from G4166 (ποιμήν)",
		"translit" : "poimaínō",
		"lemma" : "ποιμαίνω",
		"kjv" : "feed (cattle), rule"
	},
	"G680" : {
		"kjv" : "touch",
		"lemma" : "ἅπτομαι",
		"translit" : "háptomai",
		"derivation" : "reflexive of G681 (ἅπτω)",
		"def" : "properly, to attach oneself to, i.e. to touch (in many implied relations)"
	},
	"G1711" : {
		"kjv" : "merchandise",
		"lemma" : "ἐμπορία",
		"translit" : "emporía",
		"derivation" : "feminine from G1713 (ἔμπορος)",
		"def" : "traffic"
	},
	"G2146" : {
		"kjv" : "make a fair show",
		"translit" : "euprosōpéō",
		"lemma" : "εὐπροσωπέω",
		"derivation" : "from a compound of G2095 (εὖ) and G4383 (πρόσωπον)",
		"def" : "to be of good countenance, i.e. (figuratively) to make a display"
	},
	"G1686" : {
		"def" : "to whelm on, i.e. wet (a part of the person, etc.) by contact with a fluid",
		"derivation" : "from G1722 (ἐν) and G911 (βάπτω)",
		"lemma" : "ἐμβάπτω",
		"translit" : "embáptō",
		"kjv" : "dip"
	},
	"G4942" : {
		"kjv" : "dissemble with",
		"translit" : "synypokrínomai",
		"lemma" : "συνυποκρίνομαι",
		"derivation" : "from G4862 (σύν) and G5271 (ὑποκρίνομαι)",
		"def" : "to act hypocritically in concert with"
	},
	"G1738" : {
		"def" : "in the right, i.e. equitable",
		"derivation" : "from G1722 (ἐν) and G1349 (δίκη)",
		"translit" : "éndikos",
		"lemma" : "ἔνδικος",
		"kjv" : "just"
	},
	"G3954" : {
		"def" : "all out-spokenness, i.e. frankness, bluntness, publicity; by implication, assurance",
		"derivation" : "from G3956 (πᾶς) and a derivative of G4483 (ῥέω)",
		"lemma" : "παῤῥησία",
		"translit" : "parrhēsía",
		"kjv" : "bold (X -ly, -ness, -ness of speech), confidence, X freely, X openly, X plainly(-ness)"
	},
	"G4471" : {
		"kjv" : "Rama",
		"lemma" : "Ῥαμᾶ",
		"translit" : "Rhamâ",
		"derivation" : "of Hebrew origin (H7414)",
		"def" : "Rama (i.e. Ramah), a place in Palestine"
	},
	"G1942" : {
		"lemma" : "ἐπικάλυμα",
		"translit" : "epikályma",
		"kjv" : "cloke",
		"def" : "a covering, i.e. (figuratively) pretext",
		"derivation" : "from G1943 (ἐπικαλύπτω)"
	},
	"G4485" : {
		"derivation" : "from G4486 (ῥήγνυμι)",
		"def" : "something torn, i.e. a fragment (by implication and abstractly, a fall)",
		"kjv" : "ruin",
		"translit" : "rhēgma",
		"lemma" : "ῥῆγμα"
	},
	"G4775" : {
		"kjv" : "sit with",
		"lemma" : "συγκάθημαι",
		"translit" : "synkáthēmai",
		"derivation" : "from G4862 (σύν) and G2521 (κάθημαι)",
		"def" : "to seat oneself in company with"
	},
	"G1764" : {
		"def" : "to place on hand, i.e. (reflexively) impend, (participle) be instant",
		"derivation" : "from G1722 (ἐν) and G2476 (ἵστημι)",
		"lemma" : "ἐνίστημι",
		"translit" : "enístēmi",
		"kjv" : "come, be at hand, present"
	},
	"G127" : {
		"def" : "bashfulness, i.e. (towards men), modesty or (towards God) awe",
		"derivation" : "perhaps from G1 (Α) (as a negative particle) and G1492 (εἴδω) (through the idea of downcast eyes)",
		"lemma" : "αἰδώς",
		"translit" : "aidṓs",
		"kjv" : "reverence, shamefacedness"
	},
	"G5610" : {
		"def" : "an \"hour\" (literally or figuratively)",
		"derivation" : "apparently a primary word",
		"translit" : "hṓra",
		"lemma" : "ὥρα",
		"kjv" : "day, hour, instant, season, X short, (even-)tide, (high) time"
	},
	"G2299" : {
		"translit" : "theá",
		"lemma" : "θεά",
		"kjv" : "goddess",
		"def" : "a female deity",
		"derivation" : "feminine of G2316 (θεός)"
	},
	"G2345" : {
		"derivation" : "a prolonged form of an obsolete primary (to finger)",
		"def" : "to manipulate, i.e. have to do with; by implication, to injure",
		"kjv" : "handle, touch",
		"translit" : "thingánō",
		"lemma" : "θιγγάνω"
	},
	"G106" : {
		"kjv" : "unleavened (bread)",
		"lemma" : "ἄζυμος",
		"translit" : "ázymos",
		"derivation" : "from G1 (Α) (as a negative particle) and G2219 (ζύμη)",
		"def" : "unleavened, i.e. (figuratively) uncorrupted; (in the neutral plural) specially (by implication) the Passover week"
	},
	"G1169" : {
		"def" : "timid, i.e. (by implication) faithless",
		"derivation" : "from (dread)",
		"lemma" : "δειλός",
		"translit" : "deilós",
		"kjv" : "fearful"
	},
	"G1370" : {
		"derivation" : "from a derivative of G1364 (δίς) and G4714 (στάσις)",
		"def" : "disunion, i.e. (figuratively) dissension",
		"kjv" : "division, sedition",
		"translit" : "dichostasía",
		"lemma" : "διχοστασία"
	},
	"G1147" : {
		"def" : "a finger",
		"derivation" : "probably from G1176 (δέκα)",
		"translit" : "dáktylos",
		"lemma" : "δάκτυλος",
		"kjv" : "finger"
	},
	"G4862" : {
		"lemma" : "σύν",
		"translit" : "sýn",
		"kjv" : "beside, with",
		"def" : "with or together (but much closer than G3326 (μετά) or G3844 (παρά)), i.e. by association, companionship, process, resemblance, possession, instrumentality, addition, etc.",
		"derivation" : "a primary preposition denoting union"
	},
	"G1704" : {
		"lemma" : "ἐμπεριπατέω",
		"translit" : "emperipatéō",
		"kjv" : "walk in",
		"def" : "to perambulate on a place, i.e. (figuratively) to be occupied among persons",
		"derivation" : "from G1722 (ἐν) and G4043 (περιπατέω)"
	},
	"G4031" : {
		"kjv" : "+ come by",
		"translit" : "perikratḗs",
		"lemma" : "περικρατής",
		"derivation" : "from G4012 (περί) and G2904 (κράτος)",
		"def" : "strong all around, i.e. a master (manager)"
	},
	"G1578" : {
		"kjv" : "avoid, eschew, go out of the way",
		"lemma" : "ἐκκλίνω",
		"translit" : "ekklínō",
		"derivation" : "from G1537 (ἐκ) and G2827 (κλίνω)",
		"def" : "to deviate, i.e. (absolutely) to shun (literally or figuratively), or (relatively) to decline (from piety)"
	},
	"G533" : {
		"translit" : "aparnéomai",
		"lemma" : "ἀπαρνέομαι",
		"kjv" : "deny",
		"def" : "to deny utterly, i.e. disown, abstain",
		"derivation" : "from G575 (ἀπό) and G720 (ἀρνέομαι)"
	},
	"G1884" : {
		"def" : "to avail for, i.e. help",
		"derivation" : "from G1909 (ἐπί) and G714 (ἀρκέω)",
		"translit" : "eparkéō",
		"lemma" : "ἐπαρκέω",
		"kjv" : "relieve"
	},
	"G5589" : {
		"kjv" : "crumb",
		"lemma" : "ψιχίον",
		"translit" : "psichíon",
		"derivation" : "diminutive from a derivative of the base of G5567 (ψάλλω) (meaning a crumb)",
		"def" : "a little bit or morsel"
	},
	"G3424" : {
		"derivation" : "from G3425 (μόγις) and G2980 (λαλέω)",
		"def" : "hardly talking, i.e. dumb (tongue-tied)",
		"kjv" : "having an impediment in his speech",
		"translit" : "mogilálos",
		"lemma" : "μογιλάλος"
	},
	"G652" : {
		"lemma" : "ἀπόστολος",
		"translit" : "apóstolos",
		"kjv" : "apostle, messenger, he that is sent",
		"def" : "a delegate; specially, an ambassador of the Gospel; officially a commissioner of Christ (\"apostle\") (with miraculous powers)",
		"derivation" : "from G649 (ἀποστέλλω)"
	},
	"G3715" : {
		"kjv" : "lust",
		"lemma" : "ὄρεξις",
		"translit" : "órexis",
		"derivation" : "from G3713 (ὀρέγομαι)",
		"def" : "excitement of the mind, i.e. longing after"
	},
	"G3636" : {
		"kjv" : "grievous, slothful",
		"translit" : "oknērós",
		"lemma" : "ὀκνηρός",
		"derivation" : "from G3635 (ὀκνέω)",
		"def" : "tardy, i.e. indolent; (figuratively) irksome"
	},
	"G4185" : {
		"derivation" : "from G4183 (πολύς) and G5056 (τέλος)",
		"def" : "extremely expensive",
		"kjv" : "costly, very precious, of great price",
		"translit" : "polytelḗs",
		"lemma" : "πολυτελής"
	},
	"G4281" : {
		"derivation" : "from G4253 (πρό) and G2064 (ἔρχομαι) (including its alternate)",
		"def" : "to go onward, precede (in place or time)",
		"kjv" : "go before (farther, forward), outgo, pass on",
		"lemma" : "προέρχομαι",
		"translit" : "proérchomai"
	},
	"G1535" : {
		"kjv" : "if, or, whether",
		"lemma" : "εἴτε",
		"translit" : "eíte",
		"derivation" : "from G1487 (εἰ) and G5037 (τέ)",
		"def" : "if too"
	},
	"G2456" : {
		"def" : "Julia, a Christian woman",
		"derivation" : "feminine of the same as G2457 (Ἰούλιος)",
		"translit" : "Ioulía",
		"lemma" : "Ἰουλία",
		"kjv" : "Julia"
	},
	"G1955" : {
		"derivation" : "from G1956 (ἐπιλύω)",
		"def" : "explanation, i.e. application",
		"kjv" : "interpretation",
		"lemma" : "ἐπίλυσις",
		"translit" : "epílysis"
	},
	"G2994" : {
		"translit" : "Laodikeús",
		"lemma" : "Λαοδικεύς",
		"kjv" : "Laodicean",
		"def" : "a Laodicean, i.e. inhabitant of Laodicia",
		"derivation" : "from G2993 (Λαοδίκεια)"
	},
	"G2198" : {
		"def" : "to live (literally or figuratively)",
		"derivation" : "a primary verb",
		"lemma" : "ζάω",
		"translit" : "záō",
		"kjv" : "life(-time), (a-)live(-ly), quick"
	},
	"G1571" : {
		"kjv" : "purge (out)",
		"lemma" : "ἐκκαθαίρω",
		"translit" : "ekkathaírō",
		"derivation" : "from G1537 (ἐκ) and G2508 (καθαίρω)",
		"def" : "to cleanse thoroughly"
	},
	"G3370" : {
		"kjv" : "Mede",
		"translit" : "Mēdos",
		"lemma" : "Μῆδος",
		"derivation" : "of foreign origin (compare H4074)",
		"def" : "a Median, or inhabitant of Media"
	},
	"G4685" : {
		"def" : "to draw",
		"derivation" : "a primary verb",
		"lemma" : "σπάω",
		"translit" : "spáō",
		"kjv" : "draw (out)"
	},
	"G3542" : {
		"def" : "pasture, i.e. (the act) feeding (figuratively, spreading of a gangrene), or (the food) pasturage",
		"derivation" : "feminine from the same as G3551 (νόμος)",
		"translit" : "nomḗ",
		"lemma" : "νομή",
		"kjv" : "X eat, pasture"
	},
	"G5024" : {
		"translit" : "tautá",
		"lemma" : "ταὐτά",
		"kjv" : "even thus, (manner) like, so",
		"def" : "in the same way",
		"derivation" : "neuter plural of G3588 (ὁ) and G846 (αὐτός) as adverb"
	},
	"G2141" : {
		"def" : "(intransitively) to be good for passing through, i.e. (figuratively) have pecuniary means",
		"derivation" : "from a compound of G2090 (ἑτοιμάζω) and the base of G4197 (πορεία)",
		"lemma" : "εὐπορέω",
		"translit" : "euporéō",
		"kjv" : "ability"
	},
	"G3982" : {
		"def" : "to convince (by argument, true or false); by analogy, to pacify or conciliate (by other fair means); reflexively or passively, to assent (to evidence or authority), to rely (by inward certainty)",
		"derivation" : "a primary verb",
		"translit" : "peíthō",
		"lemma" : "πείθω",
		"kjv" : "agree, assure, believe, have confidence, be (wax) conflent, make friend, obey, persuade, trust, yield"
	},
	"G2597" : {
		"def" : "to descend (literally or figuratively)",
		"derivation" : "from G2596 (κατά) and the base of G939 (βάσις)",
		"lemma" : "καταβαίνω",
		"translit" : "katabaínō",
		"kjv" : "come (get, go, step) down, fall (down)"
	},
	"G11" : {
		"kjv" : "Abraham",
		"translit" : "Abraám",
		"lemma" : "Ἀβραάμ",
		"derivation" : "of Hebrew origin (H85)",
		"def" : "Abraham, the Hebrew patriarch"
	},
	"G2441" : {
		"def" : "clothing",
		"derivation" : "from G2439 (ἱματίζω)",
		"lemma" : "ἱματισμός",
		"translit" : "himatismós",
		"kjv" : "apparel (X -led), array, raiment, vesture"
	},
	"G5240" : {
		"derivation" : "from G5228 (ὑπέρ) and the alternate form of G1632 (ἐκχέω)",
		"def" : "to pour out over, i.e. (passively) to overflow",
		"kjv" : "run over",
		"lemma" : "ὑπερεκχύνω",
		"translit" : "hyperekchýnō"
	},
	"G4187" : {
		"kjv" : "in divers manners",
		"lemma" : "πολυτρόπως",
		"translit" : "polytrópōs",
		"derivation" : "adverb from a compound of G4183 (πολύς) and G5158 (τρόπος)",
		"def" : "in many ways, i.e. variously as to method or form"
	},
	"G3975" : {
		"translit" : "pachýnō",
		"lemma" : "παχύνω",
		"kjv" : "wax gross",
		"def" : "to thicken, i.e. (by implication) to fatten (figuratively, stupefy or render callous)",
		"derivation" : "from a derivative of G4078 (πήγνυμι) (meaning thick)"
	},
	"G3743" : {
		"def" : "piously",
		"derivation" : "adverb from G3741 (ὅσιος)",
		"translit" : "hosíōs",
		"lemma" : "ὁσίως",
		"kjv" : "holily"
	},
	"G1881" : {
		"derivation" : "middle voice from G1909 (ἐπί) and G450 (ἀνίστημι)",
		"def" : "to stand up on, i.e. (figuratively) to attack",
		"kjv" : "rise up against",
		"lemma" : "ἐπανίσταμαι",
		"translit" : "epanístamai"
	},
	"G3166" : {
		"kjv" : "boast great things",
		"lemma" : "μεγαλαυχέω",
		"translit" : "megalauchéō",
		"derivation" : "from a compound of G3173 (μέγας) and (to boast",
		"def" : "akin to G837 (αὐξάνω) and G2744 (καυχάομαι)); to talk big, i.e. be grandiloquent (arrogant, egotistic)"
	},
	"G4728" : {
		"lemma" : "στενός",
		"translit" : "stenós",
		"kjv" : "strait",
		"def" : "narrow (from obstacles standing close about)",
		"derivation" : "probably from the base of G2476 (ἵστημι)"
	},
	"G1132" : {
		"kjv" : "nakedness",
		"lemma" : "γυμνότης",
		"translit" : "gymnótēs",
		"derivation" : "from G1131 (γυμνός)",
		"def" : "nudity (absolute or comparative)"
	},
	"G1781" : {
		"lemma" : "ἐντέλλομαι",
		"translit" : "entéllomai",
		"kjv" : "(give) charge, (give) command(-ments), injoin",
		"def" : "to enjoin",
		"derivation" : "from G1722 (ἐν) and the base of G5056 (τέλος)"
	},
	"G376" : {
		"translit" : "anápēros",
		"lemma" : "ἀνάπηρος",
		"kjv" : "maimed",
		"def" : "crippled",
		"derivation" : "from G303 (ἀνά) (in the sense of intensity) and (maimed)"
	},
	"G3065" : {
		"derivation" : "contracted from Latin Lucanus",
		"def" : "Lucas, a Christian",
		"kjv" : "Lucas, Luke",
		"translit" : "Loukâs",
		"lemma" : "Λουκᾶς"
	},
	"G5588" : {
		"derivation" : "from the same as G5587 (ψιθυρισμός)",
		"def" : "a secret calumniator",
		"kjv" : "whisperer",
		"lemma" : "ψιθυριστής",
		"translit" : "psithyristḗs"
	},
	"G5190" : {
		"translit" : "Tychikós",
		"lemma" : "Τυχικός",
		"kjv" : "Tychicus",
		"def" : "fortuitous, i.e. fortunate; Tychicus, a Christian",
		"derivation" : "from a derivative of G5177 (τυγχάνω)"
	},
	"G2787" : {
		"derivation" : "of uncertain derivation",
		"def" : "a box, i.e. the sacred ark and that of Noah",
		"kjv" : "ark",
		"translit" : "kibōtós",
		"lemma" : "κιβωτός"
	},
	"G1431" : {
		"derivation" : "from G1435 (δῶρον)",
		"def" : "a gratuity",
		"kjv" : "gift",
		"lemma" : "δωρεά",
		"translit" : "dōreá"
	},
	"G1984" : {
		"kjv" : "the office of a \"bishop\", bishoprick, visitation",
		"translit" : "episkopḗ",
		"lemma" : "ἐπισκοπή",
		"derivation" : "from G1980 (ἐπισκέπτομαι)",
		"def" : "inspection (for relief); by implication, superintendence; specially, the Christian \"episcopate\""
	},
	"G1243" : {
		"kjv" : "difference, diversity",
		"translit" : "diaíresis",
		"lemma" : "διαίρεσις",
		"derivation" : "from G1244 (διαιρέω)",
		"def" : "a distinction or (concretely) variety"
	},
	"G79" : {
		"def" : "a sister (naturally or ecclesiastically)",
		"derivation" : "feminine of G80 (ἀδελφός)",
		"translit" : "adelphḗ",
		"lemma" : "ἀδελφή",
		"kjv" : "sister"
	},
	"G256" : {
		"derivation" : "of Hebrew origin (compare H2501)",
		"def" : "Alphæus, an Israelite",
		"kjv" : "Alpheus",
		"translit" : "Alphaîos",
		"lemma" : "Ἀλφαῖος"
	},
	"G2803" : {
		"lemma" : "Κλαυδία",
		"translit" : "Klaudía",
		"kjv" : "Claudia",
		"def" : "Claudia, a Christian woman",
		"derivation" : "feminine of G2804 (Κλαύδιος)"
	},
	"G5229" : {
		"translit" : "hyperaíromai",
		"lemma" : "ὑπεραίρομαι",
		"kjv" : "exalt self, be exalted above measure",
		"def" : "to raise oneself over, i.e. (figuratively) to become haughty",
		"derivation" : "middle voice from G5228 (ὑπέρ) and G142 (αἴρω)"
	},
	"G3846" : {
		"kjv" : "arrive, compare",
		"translit" : "parabállō",
		"lemma" : "παραβάλλω",
		"derivation" : "from G3844 (παρά) and G906 (βάλλω)",
		"def" : "to throw alongside, i.e. (reflexively) to reach a place, or (figuratively) to liken"
	},
	"G835" : {
		"derivation" : "middle voice from G833 (αὐλή)",
		"def" : "to pass the night (properly, in the open air)",
		"kjv" : "abide, lodge",
		"lemma" : "αὐλίζομαι",
		"translit" : "aulízomai"
	},
	"G2231" : {
		"def" : "government, i.e. (in time) official term",
		"derivation" : "from G2232 (ἡγεμών)",
		"translit" : "hēgemonía",
		"lemma" : "ἡγεμονία",
		"kjv" : "reign"
	},
	"G2045" : {
		"kjv" : "search",
		"lemma" : "ἐρευνάω",
		"translit" : "ereunáō",
		"derivation" : "apparently from G2046 (ἐρέω) (through the idea of inquiry)",
		"def" : "to seek, i.e. (figuratively) to investigate"
	},
	"G4318" : {
		"translit" : "prosagōgḗ",
		"lemma" : "προσαγωγή",
		"kjv" : "access",
		"def" : "admission",
		"derivation" : "from G4317 (προσάγω) (compare G72 (ἀγωγή))"
	},
	"G2107" : {
		"kjv" : "desire, good pleasure (will), X seem good",
		"translit" : "eudokía",
		"lemma" : "εὐδοκία",
		"derivation" : "from a presumed compound of G2095 (εὖ) and the base of G1380 (δοκέω)",
		"def" : "satisfaction, i.e. (subjectively) delight, or (objectively) kindness, wish, purpose"
	},
	"G4894" : {
		"derivation" : "from G4862 (σύν) and G1492 (εἴδω)",
		"def" : "to see completely; used (like its primary) only in two past tenses, respectively meaning to understand or become aware, and to be conscious or (clandestinely) informed of",
		"kjv" : "consider, know, be privy, be ware of",
		"translit" : "syneídō",
		"lemma" : "συνείδω"
	},
	"G3454" : {
		"kjv" : "fable",
		"lemma" : "μῦθος",
		"translit" : "mŷthos",
		"derivation" : "perhaps from the same as G3453 (μυέω) (through the idea of tuition)",
		"def" : "a tale, i.e. fiction (\"myth\")"
	},
	"G1467" : {
		"kjv" : "can(-not) contain, be temperate",
		"translit" : "enkrateúomai",
		"lemma" : "ἐγκρατεύομαι",
		"derivation" : "middle voice from G1468 (ἐγκρατής)",
		"def" : "to exercise self-restraint (in diet and chastity)"
	},
	"G3864" : {
		"derivation" : "from G3844 (παρά) and G2281 (θάλασσα)",
		"def" : "along the sea, i.e. maritime (lacustrine)",
		"kjv" : "upon the sea coast",
		"lemma" : "παραθαλάσσιος",
		"translit" : "parathalássios"
	},
	"G711" : {
		"def" : "best counselling; Aristoboulus, a Christian",
		"derivation" : "from the same as G712 (ἄριστον) and G1012 (βουλή)",
		"lemma" : "Ἀριστόβουλος",
		"translit" : "Aristóboulos",
		"kjv" : "Aristobulus"
	},
	"G609" : {
		"derivation" : "from G575 (ἀπό) and G2875 (κόπτω)",
		"def" : "to amputate; reflexively (by irony) to mutilate (the privy parts)",
		"kjv" : "cut off",
		"translit" : "apokóptō",
		"lemma" : "ἀποκόπτω"
	},
	"G2718" : {
		"kjv" : "come (down), depart, descend, go down, land",
		"lemma" : "κατέρχομαι",
		"translit" : "katérchomai",
		"derivation" : "from G2596 (κατά) and G2064 (ἔρχομαι) (including its alternate)",
		"def" : "to come (or go) down (literally or figuratively)"
	},
	"G4649" : {
		"def" : "perhaps akin to G4626 (σκάπτω) through the idea of concealment; compare G4629 (σκέπασμα)); a watch (sentry or scout), i.e. (by implication) a goal",
		"derivation" : "from (to peer about (\"skeptic\")",
		"lemma" : "σκοπός",
		"translit" : "skopós",
		"kjv" : "mark"
	},
	"G2335" : {
		"derivation" : "from the same as G2334 (θεωρέω)",
		"def" : "spectatorship, i.e. (concretely) a spectacle",
		"kjv" : "sight",
		"translit" : "theōría",
		"lemma" : "θεωρία"
	},
	"G2665" : {
		"kjv" : "vail",
		"translit" : "katapétasma",
		"lemma" : "καταπέτασμα",
		"derivation" : "from a compound of G2596 (κατά) and a congener of G4072 (πέτομαι)",
		"def" : "something spread thoroughly, i.e. (specially) the door screen (to the Most Holy Place) in the Jewish Temple"
	},
	"G5544" : {
		"lemma" : "χρηστότης",
		"translit" : "chrēstótēs",
		"kjv" : "gentleness, good(-ness), kindness",
		"def" : "usefulness, i.e. morally, excellence (in character or demeanor)",
		"derivation" : "from G5543 (χρηστός)"
	},
	"G1451" : {
		"translit" : "engýs",
		"lemma" : "ἐγγύς",
		"kjv" : "from , at hand, near, nigh (at hand, unto), ready",
		"def" : "akin to the base of G43 (ἀγκάλη)); near (literally or figuratively, of place or time)",
		"derivation" : "from a primary verb (to squeeze or throttle"
	},
	"G4357" : {
		"kjv" : "abide still, be with, cleave unto, continue in (with)",
		"translit" : "prosménō",
		"lemma" : "προσμένω",
		"derivation" : "from G4314 (πρός) and G3306 (μένω)",
		"def" : "to stay further, i.e. remain in a place, with a person; figuratively, to adhere to, persevere in"
	},
	"G5587" : {
		"translit" : "psithyrismós",
		"lemma" : "ψιθυρισμός",
		"kjv" : "whispering",
		"def" : "by implication, a slander; probably akin to G5574 (ψεύδομαι)); whispering, i.e. secret detraction",
		"derivation" : "from a derivative of (a whisper"
	},
	"G1991" : {
		"derivation" : "from G1909 (ἐπί) and G4741 (στηρίζω)",
		"def" : "to support further, i.e. reestablish",
		"kjv" : "confirm, strengthen",
		"lemma" : "ἐπιστηρίζω",
		"translit" : "epistērízō"
	},
	"G5044" : {
		"kjv" : "bring up children",
		"translit" : "teknotrophéō",
		"lemma" : "τεκνοτροφέω",
		"derivation" : "from a compound of G5043 (τέκνον) and G5142 (τρέφω)",
		"def" : "to be a childrearer, i.e. fulfil the duties of a female parent"
	},
	"G4750" : {
		"kjv" : "edge, face, mouth",
		"translit" : "stóma",
		"lemma" : "στόμα",
		"derivation" : "probably strengthened from a presumed derivative of the base of G5114 (τομώτερος)",
		"def" : "the mouth (as if a gash in the face); by implication, language (and its relations); figuratively, an opening (in the earth); specially, the front or edge (of a weapon)"
	},
	"G2078" : {
		"lemma" : "ἔσχατος",
		"translit" : "éschatos",
		"kjv" : "ends of, last, latter end, lowest, uttermost",
		"def" : "farthest, final (of place or time)",
		"derivation" : "a superlative probably from G2192 (ἔχω) (in the sense of contiguity)"
	},
	"G2177" : {
		"kjv" : "leap on",
		"translit" : "ephállomai",
		"lemma" : "ἐφάλλομαι",
		"derivation" : "from G1909 (ἐπί) and G242 (ἅλλομαι)",
		"def" : "to spring upon"
	},
	"G3033" : {
		"kjv" : "Libya",
		"translit" : "Libýē",
		"lemma" : "Λιβύη",
		"derivation" : "probably from G3047 (λίψ)",
		"def" : "Libye, a region of Africa"
	},
	"G2019" : {
		"def" : "to call at something, i.e. exclaim",
		"derivation" : "from G1909 (ἐπί) and G5455 (φωνέω)",
		"lemma" : "ἐπιφωνέω",
		"translit" : "epiphōnéō",
		"kjv" : "cry (against), give a shout"
	},
	"G402" : {
		"lemma" : "ἀναχωρέω",
		"translit" : "anachōréō",
		"kjv" : "depart, give place, go (turn) aside, withdraw self",
		"def" : "to retire",
		"derivation" : "from G303 (ἀνά) and G5562 (χωρέω)"
	},
	"G3498" : {
		"kjv" : "dead",
		"translit" : "nekrós",
		"lemma" : "νεκρός",
		"derivation" : "from an apparently primary (a corpse)",
		"def" : "dead (literally or figuratively; also as noun)"
	},
	"G2224" : {
		"lemma" : "ζώννυμι",
		"translit" : "zṓnnymi",
		"kjv" : "gird",
		"def" : "to bind about (especially with a belt)",
		"derivation" : "from G2223 (ζώνη)"
	},
	"G5040" : {
		"kjv" : "little children",
		"translit" : "tekníon",
		"lemma" : "τεκνίον",
		"derivation" : "diminutive of G5043 (τέκνον)",
		"def" : "an infant, i.e. (plural figuratively) darlings (Christian converts)"
	},
	"G293" : {
		"lemma" : "ἀμφίβληστρον",
		"translit" : "amphíblēstron",
		"kjv" : "net",
		"def" : "a (fishing) net (as thrown about the fish)",
		"derivation" : "from a compound of the base of G297 (ἀμφότερος) and G906 (βάλλω)"
	},
	"G3655" : {
		"translit" : "ómbros",
		"lemma" : "ὄμβρος",
		"kjv" : "shower",
		"def" : "a thunder storm",
		"derivation" : "of uncertain affinity"
	},
	"G499" : {
		"derivation" : "neuter of a compound of G473 (ἀντί) and G5179 (τύπος)",
		"def" : "corresponding (\"antitype\"), i.e. a representative, counterpart",
		"kjv" : "(like) figure (whereunto)",
		"lemma" : "ἀντίτυπον",
		"translit" : "antítypon"
	},
	"G599" : {
		"def" : "to die off (literally or figuratively)",
		"derivation" : "from G575 (ἀπό) and G2348 (θνήσκω)",
		"lemma" : "ἀποθνήσκω",
		"translit" : "apothnḗskō",
		"kjv" : "be dead, death, die, lie a-dying, be slain (X with)"
	},
	"G4205" : {
		"derivation" : "from (to sell",
		"def" : "akin to the base of G4097 (πιπράσκω)); a (male) prostitute (as venal), i.e. (by analogy) a debauchee (libertine)",
		"kjv" : "fornicator, whoremonger",
		"translit" : "pórnos",
		"lemma" : "πόρνος"
	},
	"G1274" : {
		"derivation" : "from G1223 (διά) and (to effect)",
		"def" : "to accomplish thoroughly",
		"kjv" : "finish",
		"translit" : "dianýō",
		"lemma" : "διανύω"
	},
	"G219" : {
		"derivation" : "from G220 (ἀλέκτωρ) and G5456 (φωνή)",
		"def" : "cock-crow, i.e. the third night-watch",
		"kjv" : "cockcrowing",
		"translit" : "alektorophōnía",
		"lemma" : "ἀλεκτοροφωνία"
	},
	"G3455" : {
		"kjv" : "roar",
		"translit" : "mykáomai",
		"lemma" : "μυκάομαι",
		"derivation" : "from a presumed derivative of ( to \"moo\")",
		"def" : "to bellow (roar)"
	},
	"G687" : {
		"derivation" : "a form of G686 (ἄρα), denoting an interrogation to which a negative answer is presumed",
		"kjv" : "therefore",
		"lemma" : "ἆρα",
		"translit" : "âra"
	},
	"G811" : {
		"kjv" : "riotous",
		"translit" : "asṓtōs",
		"lemma" : "ἀσώτως",
		"derivation" : "adverb from the same as G810 (ἀσωτία)",
		"def" : "dissolutely"
	},
	"G444" : {
		"translit" : "ánthrōpos",
		"lemma" : "ἄνθρωπος",
		"kjv" : "certain, man",
		"def" : "from G3700 (ὀπτάνομαι)); man-faced, i.e. a human being",
		"derivation" : "from G435 (ἀνήρ) and (the countenance"
	},
	"G2947" : {
		"def" : "compare G2945 (κύκλῳ), G1507 (εἱλίσσω)); to roll about",
		"derivation" : "from the base of G2949 (κῦμα) (through the idea of circularity",
		"lemma" : "κυλιόω",
		"translit" : "kylióō",
		"kjv" : "wallow"
	},
	"G2316" : {
		"lemma" : "θεός",
		"translit" : "theós",
		"kjv" : "X exceeding, God, god(-ly, -ward)",
		"def" : "figuratively, a magistrate; by Hebraism, very",
		"derivation" : "of uncertain affinity; a deity, especially (with G3588 (ὁ)) the supreme Divinity"
	},
	"G1339" : {
		"kjv" : "go further, be parted, after the space of",
		"translit" : "diḯstēmi",
		"lemma" : "διΐστημι",
		"derivation" : "from G1223 (διά) and G2476 (ἵστημι)",
		"def" : "to stand apart, i.e. (reflexively) to remove, intervene"
	},
	"G5521" : {
		"def" : "\"gall\" or bile, i.e. (by analogy) poison or an anodyne (wormwood, poppy, etc.)",
		"derivation" : "feminine of an equivalent perhaps akin to the same as 5514 (from the greenish hue)",
		"lemma" : "χολή",
		"translit" : "cholḗ",
		"kjv" : "gall"
	},
	"G2154" : {
		"def" : "well indicated, i.e. (figuratively) significant",
		"derivation" : "from G2095 (εὖ) and the base of G4591 (σημαίνω)",
		"lemma" : "εὔσημος",
		"translit" : "eúsēmos",
		"kjv" : "easy to be understood"
	},
	"G4238" : {
		"def" : "to \"practise\", i.e. perform repeatedly or habitually (thus differing from G4160 (ποιέω), which properly refers to a single act); by implication, to execute, accomplish, etc.; specially, to collect (dues), fare (personally)",
		"derivation" : "a primary verb",
		"translit" : "prássō",
		"lemma" : "πράσσω",
		"kjv" : "commit, deeds, do, exact, keep, require, use arts"
	},
	"G4592" : {
		"kjv" : "miracle, sign, token, wonder",
		"translit" : "sēmeîon",
		"lemma" : "σημεῖον",
		"derivation" : "neuter of a presumed derivative of the base of G4591 (σημαίνω)",
		"def" : "an indication, especially ceremonially or supernaturally"
	},
	"G4756" : {
		"kjv" : "host",
		"lemma" : "στρατιά",
		"translit" : "stratiá",
		"derivation" : "feminine of a derivative of (an army",
		"def" : "from the base of G4766 (στρώννυμι), as encamped); camp-likeness, i.e. an army, i.e. (figuratively) the angels, the celestial luminaries"
	},
	"G4579" : {
		"def" : "to rock (vibrate, properly, sideways or to and fro), i.e. (generally) to agitate (in any direction; cause to tremble); figuratively, to throw into a tremor (of fear or concern)",
		"derivation" : "apparently a primary verb",
		"lemma" : "σείω",
		"translit" : "seíō",
		"kjv" : "move, quake, shake"
	},
	"G73" : {
		"derivation" : "from G71 (ἄγω)",
		"def" : "properly, a place of assembly (as if led), i.e. (by implication) a contest (held there); figuratively, an effort or anxiety",
		"kjv" : "conflict, contention, fight, race",
		"lemma" : "ἀγών",
		"translit" : "agṓn"
	},
	"G2390" : {
		"kjv" : "heal, make whole",
		"lemma" : "ἰάομαι",
		"translit" : "iáomai",
		"derivation" : "middle voice of apparently a primary verb",
		"def" : "to cure (literally or figuratively)"
	},
	"G5601" : {
		"def" : "Obed, an Israelite",
		"derivation" : "of Hebrew origin (H5744)",
		"translit" : "Ōbḗd",
		"lemma" : "Ὠβήδ",
		"kjv" : "Obed"
	},
	"G555" : {
		"def" : "divestment",
		"derivation" : "from G554 (ἀπεκδύομαι)",
		"translit" : "apékdysis",
		"lemma" : "ἀπέκδυσις",
		"kjv" : "putting off"
	},
	"G5405" : {
		"kjv" : "Phenice",
		"translit" : "Phoînix",
		"lemma" : "Φοῖνιξ",
		"derivation" : "probably the same as G5404 (φοῖνιξ)",
		"def" : "Phœnix, a place in Crete"
	},
	"G4575" : {
		"derivation" : "from G4573 (σεβάζομαι)",
		"def" : "venerable (august), i.e. (as noun) a title of the Roman Emperor, or (as adjective) imperial",
		"kjv" : "Augustus(-')",
		"translit" : "sebastós",
		"lemma" : "σεβαστός"
	},
	"G2311" : {
		"lemma" : "θεμελιόω",
		"translit" : "themelióō",
		"kjv" : "(lay the) found(- ation), ground, settle",
		"def" : "to lay a basis for, i.e. (literally) erect, or (figuratively) consolidate",
		"derivation" : "from G2310 (θεμέλιος)"
	},
	"G740" : {
		"def" : "bread (as raised) or a loaf",
		"derivation" : "from G142 (αἴρω)",
		"lemma" : "ἄρτος",
		"translit" : "ártos",
		"kjv" : "(shew-)bread, loaf"
	},
	"G5202" : {
		"kjv" : "drink water",
		"lemma" : "ὑδροποτέω",
		"translit" : "hydropotéō",
		"derivation" : "from a compound of G5204 (ὕδωρ) and a derivative of G4095 (πίνω)",
		"def" : "to be a water-drinker, i.e. to abstain from vinous beverages"
	},
	"G1477" : {
		"def" : "a support, i.e. (figuratively) basis",
		"derivation" : "from a derivative of G1476 (ἑδραῖος)",
		"translit" : "hedraíōma",
		"lemma" : "ἑδραίωμα",
		"kjv" : "ground"
	},
	"G5533" : {
		"def" : "a loan-ower, i.e. indebted person",
		"derivation" : "from a derivative of G5531 (χράω) and G3781 (ὀφειλέτης)",
		"translit" : "chreōpheilétēs",
		"lemma" : "χρεωφειλέτης",
		"kjv" : "debtor"
	},
	"G487" : {
		"kjv" : "ransom",
		"translit" : "antílytron",
		"lemma" : "ἀντίλυτρον",
		"derivation" : "from G473 (ἀντί) and G3083 (λύτρον)",
		"def" : "a redemption-price"
	},
	"G3116" : {
		"def" : "with long (enduring) temper, i.e. leniently",
		"derivation" : "adverb of a compound of G3117 (μακρός) and G2372 (θυμός)",
		"translit" : "makrothymṓs",
		"lemma" : "μακροθυμώς",
		"kjv" : "patiently"
	},
	"G3613" : {
		"derivation" : "neuter of a presumed derivative of G3611 (οἰκέω) (equivalent to G3612 (οἴκημα))",
		"def" : "a residence (literally or figuratively)",
		"kjv" : "habitation, house",
		"translit" : "oikētḗrion",
		"lemma" : "οἰκητήριον"
	},
	"G220" : {
		"kjv" : "cock",
		"translit" : "aléktōr",
		"lemma" : "ἀλέκτωρ",
		"derivation" : "from (to ward off)",
		"def" : "a cock or male fowl"
	},
	"G5615" : {
		"translit" : "hōsaútōs",
		"lemma" : "ὡσαύτως",
		"kjv" : "even so, likewise, after the same (in like) manner",
		"def" : "as thus, i.e. in the same way",
		"derivation" : "from G5613 (ὡς) and an adverb from G846 (αὐτός)"
	},
	"G597" : {
		"def" : "to treasure away",
		"derivation" : "from G575 (ἀπό) and G2343 (θησαυρίζω)",
		"translit" : "apothēsaurízō",
		"lemma" : "ἀποθησαυρίζω",
		"kjv" : "lay up in store"
	},
	"G5115" : {
		"def" : "a bow (apparently as the simplest fabric)",
		"derivation" : "from the base of G5088 (τίκτω)",
		"lemma" : "τόξον",
		"translit" : "tóxon",
		"kjv" : "bow"
	},
	"G631" : {
		"derivation" : "middle voice from G575 (ἀπό) and (to squeeze, knead, smear)",
		"def" : "to scrape away",
		"kjv" : "wipe off",
		"translit" : "apomássomai",
		"lemma" : "ἀπομάσσομαι"
	},
	"G4740" : {
		"kjv" : "stedfastness",
		"translit" : "stērigmós",
		"lemma" : "στηριγμός",
		"derivation" : "from G4741 (στηρίζω)",
		"def" : "stability (figuratively)"
	},
	"G5582" : {
		"translit" : "pseûsma",
		"lemma" : "ψεῦσμα",
		"kjv" : "lie",
		"def" : "a fabrication, i.e. falsehood",
		"derivation" : "from G5574 (ψεύδομαι)"
	},
	"G3993" : {
		"translit" : "pénēs",
		"lemma" : "πένης",
		"kjv" : "poor",
		"def" : "starving, i.e. indigent",
		"derivation" : "from a primary (to toil for daily subsistence)"
	},
	"G2887" : {
		"def" : "orderly, i.e. decorous",
		"derivation" : "from G2889 (κόσμος) (in its primary sense)",
		"translit" : "kósmios",
		"lemma" : "κόσμιος",
		"kjv" : "of good behaviour, modest"
	},
	"G2170" : {
		"def" : "well favored, i.e. (by implication) grateful",
		"derivation" : "from G2095 (εὖ) and a derivative of G5483 (χαρίζομαι)",
		"translit" : "eucháristos",
		"lemma" : "εὐχάριστος",
		"kjv" : "thankful"
	},
	"G4115" : {
		"kjv" : "make broad, enlarge",
		"translit" : "platýnō",
		"lemma" : "πλατύνω",
		"derivation" : "from G4116 (πλατύς)",
		"def" : "to widen (literally or figuratively)"
	},
	"G4850" : {
		"lemma" : "συμπρεσβύτερος",
		"translit" : "sympresbýteros",
		"kjv" : "presbyter, also an elder",
		"def" : "a co-presbyter",
		"derivation" : "from G4862 (σύν) and G4245 (πρεσβύτερος)"
	},
	"G2848" : {
		"def" : "a kernel of seed",
		"derivation" : "apparently a primary word",
		"translit" : "kókkos",
		"lemma" : "κόκκος",
		"kjv" : "corn, grain"
	},
	"G3807" : {
		"def" : "a boy-leader, i.e. a servant whose office it was to take the children to school; (by implication, (figuratively) a tutor (\"pædagogue\"))",
		"derivation" : "from G3816 (παῖς) and a reduplicated form of G71 (ἄγω)",
		"translit" : "paidagōgós",
		"lemma" : "παιδαγωγός",
		"kjv" : "instructor, schoolmaster"
	},
	"G3944" : {
		"lemma" : "παροίχομαι",
		"translit" : "paroíchomai",
		"kjv" : "past",
		"def" : "to escape along, i.e. be gone",
		"derivation" : "from G3844 (παρά) and (to depart)"
	},
	"G936" : {
		"def" : "to rule (literally or figuratively)",
		"derivation" : "from G935 (βασιλεύς)",
		"lemma" : "βασιλεύω",
		"translit" : "basileúō",
		"kjv" : "king, reign"
	},
	"G165" : {
		"def" : "properly, an age; by extension, perpetuity (also past); by implication, the world; specially (Jewish) a Messianic period (present or future)",
		"derivation" : "from the same as G104 (ἀεί)",
		"lemma" : "αἰών",
		"translit" : "aiṓn",
		"kjv" : "age, course, eternal, (for) ever(-more), (n-)ever, (beginning of the , while the) world (began, without end)"
	},
	"G4600" : {
		"lemma" : "σιαγών",
		"translit" : "siagṓn",
		"kjv" : "cheek",
		"def" : "the jaw-bone, i.e. (by implication) the cheek or side of the face",
		"derivation" : "of uncertain derivation"
	},
	"G1323" : {
		"def" : "a double drachma (didrachm)",
		"derivation" : "from G1364 (δίς) and G1406 (δραχμή)",
		"translit" : "dídrachmon",
		"lemma" : "δίδραχμον",
		"kjv" : "tribute"
	},
	"G4109" : {
		"lemma" : "πλάξ",
		"translit" : "pláx",
		"kjv" : "table",
		"def" : "a moulding-board, i.e. flat surface (\"plate\", or tablet, literally or figuratively)",
		"derivation" : "from G4111 (πλάσσω)"
	},
	"G2287" : {
		"def" : "death-bearing, i.e. fatal",
		"derivation" : "from (the feminine form of) G2288 (θάνατος) and G5342 (φέρω)",
		"lemma" : "θανατήφορος",
		"translit" : "thanatḗphoros",
		"kjv" : "deadly"
	},
	"G1360" : {
		"translit" : "dióti",
		"lemma" : "διότι",
		"kjv" : "because (that), for, therefore",
		"def" : "on the very account that, or inasmuch as",
		"derivation" : "from G1223 (διά) and G3754 (ὅτι)"
	},
	"G1259" : {
		"kjv" : "reconcile",
		"translit" : "diallássō",
		"lemma" : "διαλλάσσω",
		"derivation" : "from G1223 (διά) and G236 (ἀλλάσσω)",
		"def" : "to change thoroughly, i.e. (mentally) to conciliate"
	},
	"G2933" : {
		"def" : "an acquirement, i.e. estate",
		"derivation" : "from G2932 (κτάομαι)",
		"translit" : "ktēma",
		"lemma" : "κτῆμα",
		"kjv" : "possession"
	},
	"G469" : {
		"kjv" : "reward",
		"translit" : "antapódosis",
		"lemma" : "ἀνταπόδοσις",
		"derivation" : "from G467 (ἀνταποδίδωμι)",
		"def" : "requital (properly, the act)"
	},
	"G3834" : {
		"kjv" : "(cunning) craftiness, subtilty",
		"translit" : "panourgía",
		"lemma" : "πανουργία",
		"derivation" : "from G3835 (πανοῦργος)",
		"def" : "adroitness, i.e. (in a bad sense) trickery or sophistry"
	},
	"G5078" : {
		"def" : "art (as productive), i.e. (specially), a trade, or (generally) skill",
		"derivation" : "from the base of G5088 (τίκτω)",
		"translit" : "téchnē",
		"lemma" : "τέχνη",
		"kjv" : "art, craft, occupation"
	},
	"G4212" : {
		"derivation" : "multiplicative from G4214 (πόσος)",
		"def" : "how many times",
		"kjv" : "how oft(-en)",
		"translit" : "posákis",
		"lemma" : "ποσάκις"
	},
	"G3951" : {
		"kjv" : "stir up",
		"translit" : "parotrýnō",
		"lemma" : "παροτρύνω",
		"derivation" : "from G3844 (παρά) and (to spur)",
		"def" : "to urge along, i.e. stimulate (to hostility)"
	},
	"G1099" : {
		"def" : "sweet (i.e. not bitter nor salt)",
		"derivation" : "of uncertain affinity",
		"translit" : "glykýs",
		"lemma" : "γλυκύς",
		"kjv" : "sweet, fresh"
	},
	"G866" : {
		"def" : "unavaricious",
		"derivation" : "from G1 (Α) (as a negative particle) and G5366 (φιλάργυρος)",
		"translit" : "aphilárgyros",
		"lemma" : "ἀφιλάργυρος",
		"kjv" : "without covetousness, not greedy of filthy lucre"
	},
	"G4612" : {
		"def" : "a semicinctium or half-girding, i.e. narrow covering (apron)",
		"derivation" : "of Latin origin",
		"translit" : "simikínthion",
		"lemma" : "σιμικίνθιον",
		"kjv" : "apron"
	},
	"G308" : {
		"translit" : "anablépō",
		"lemma" : "ἀναβλέπω",
		"kjv" : "look (up), see, receive sight",
		"def" : "to look up; by implication, to recover sight",
		"derivation" : "from G303 (ἀνά) and G991 (βλέπω)"
	},
	"G4289" : {
		"translit" : "próthymos",
		"lemma" : "πρόθυμος",
		"kjv" : "ready, willing",
		"def" : "forward in spirit, i.e. predisposed; neuter (as noun) alacrity",
		"derivation" : "from G4253 (πρό) and G2372 (θυμός)"
	},
	"G1957" : {
		"lemma" : "ἐπιμαρτυρέω",
		"translit" : "epimartyréō",
		"kjv" : "testify",
		"def" : "to attest further, i.e. corroborate",
		"derivation" : "from G1909 (ἐπί) and G3140 (μαρτυρέω)"
	},
	"G4611" : {
		"derivation" : "of Hebrew origin (H7975)",
		"def" : "Siloam (i.e. Shiloach), a pool of Jerusalem",
		"kjv" : "Siloam",
		"lemma" : "Σιλωάμ",
		"translit" : "Silōám"
	},
	"G5212" : {
		"translit" : "hyméteros",
		"lemma" : "ὑμέτερος",
		"kjv" : "your (own)",
		"def" : "yours, i.e. pertaining to you",
		"derivation" : "from G5210 (ὑμεῖς)"
	},
	"G4565" : {
		"lemma" : "Σάρων",
		"translit" : "Sárōn",
		"kjv" : "Saron",
		"def" : "Saron (i.e. Sharon), a district of Palestine",
		"derivation" : "of Hebrew origin (H8289)"
	},
	"G1649" : {
		"translit" : "élenxis",
		"lemma" : "ἔλεγξις",
		"kjv" : "rebuke",
		"def" : "refutation, i.e. reproof",
		"derivation" : "from G1651 (ἐλέγχω)"
	},
	"G4011" : {
		"kjv" : "Perga",
		"translit" : "Pérgē",
		"lemma" : "Πέργη",
		"derivation" : "probably from the same as G4010 (Πέργαμος)",
		"def" : "a tower; Perga, a place in Asia Minor"
	},
	"G2706" : {
		"lemma" : "καταφρονέω",
		"translit" : "kataphronéō",
		"kjv" : "despise",
		"def" : "to think against, i.e. disesteem",
		"derivation" : "from G2596 (κατά) and G5426 (φρονέω)"
	},
	"G4297" : {
		"lemma" : "προκοπή",
		"translit" : "prokopḗ",
		"kjv" : "furtherance, profit",
		"def" : "progress, i.e. advancement (subjectively or objectively)",
		"derivation" : "from G4298 (προκόπτω)"
	},
	"G5243" : {
		"derivation" : "from G5244 (ὑπερήφανος)",
		"def" : "haughtiness",
		"kjv" : "pride",
		"lemma" : "ὑπερηφανία",
		"translit" : "hyperēphanía"
	},
	"G4459" : {
		"translit" : "pōs",
		"lemma" : "πῶς",
		"kjv" : "how, after (by) what manner (means), that",
		"def" : "an interrogative particle of manner; in what way? (sometimes the question is indirect, how?); also as exclamation, how much!",
		"derivation" : "adverb from the base of G4226 (ποῦ)"
	},
	"G5455" : {
		"kjv" : "call (for), crow, cry",
		"lemma" : "φωνέω",
		"translit" : "phōnéō",
		"derivation" : "from G5456 (φωνή)",
		"def" : "to emit a sound (animal, human or instrumental); by implication, to address in words or by name, also in imitation"
	},
	"G1499" : {
		"def" : "if also (or even)",
		"derivation" : "from G1487 (εἰ) and G2532 (καί)",
		"translit" : "ei kaí",
		"lemma" : "εἰ καί",
		"kjv" : "if (that), though"
	},
	"G4262" : {
		"derivation" : "from G4263 (πρόβατον)",
		"def" : "relating to sheep, i.e. (a gate) through which they were led into Jerusalem",
		"kjv" : "sheep (market)",
		"translit" : "probatikós",
		"lemma" : "προβατικός"
	},
	"G3007" : {
		"lemma" : "λείπω",
		"translit" : "leípō",
		"kjv" : "be destitute (wanting), lack",
		"def" : "to leave, i.e. (intransitively or passively) to fail or be absent",
		"derivation" : "a primary verb"
	},
	"G3891" : {
		"def" : "to be opposed to law, i.e. to transgress",
		"derivation" : "from a compound of G3844 (παρά) and G3551 (νόμος)",
		"lemma" : "παρανομέω",
		"translit" : "paranoméō",
		"kjv" : "contrary to law"
	},
	"G3756" : {
		"derivation" : "a primary word",
		"def" : "the absolute negative (compare G3361 (μή)) adverb; no or not",
		"kjv" : "+ long, nay, neither, never, no (X man), none, (can-)not, + nothing, + special, un(-worthy), when, + without, + yet but",
		"lemma" : "οὐ",
		"translit" : "ou"
	},
	"G861" : {
		"def" : "incorruptibility; genitive, unending existence; (figuratively) genuineness",
		"derivation" : "from G862 (ἄφθαρτος)",
		"lemma" : "ἀφθαρσία",
		"translit" : "aphtharsía",
		"kjv" : "immortality, incorruption, sincerity"
	},
	"G1326" : {
		"kjv" : "arise, awake, raise, stir up",
		"translit" : "diegeírō",
		"lemma" : "διεγείρω",
		"derivation" : "from G1223 (διά) and G1453 (ἐγείρω)",
		"def" : "to wake fully; i.e. arouse (literally or figuratively)"
	},
	"G2197" : {
		"def" : "Zacharias (i.e. Zechariah), the name of two Israelites",
		"derivation" : "of Hebrew origin (H2148)",
		"translit" : "Zacharías",
		"lemma" : "Ζαχαρίας",
		"kjv" : "Zacharias"
	},
	"G2548" : {
		"kjv" : "and him (other, them), even he, him also, them (also), (and) they",
		"translit" : "kakeînos",
		"lemma" : "κἀκεῖνος",
		"derivation" : "from G2532 (καί) and G1565 (ἐκεῖνος)",
		"def" : "likewise that (or those)"
	},
	"G2989" : {
		"def" : "to beam, i.e. radiate brilliancy (literally or figuratively)",
		"derivation" : "a primary verb",
		"lemma" : "λάμπω",
		"translit" : "lámpō",
		"kjv" : "give light, shine"
	},
	"G2932" : {
		"kjv" : "obtain, possess, provide, purchase",
		"lemma" : "κτάομαι",
		"translit" : "ktáomai",
		"derivation" : "a primary verb",
		"def" : "to get, i.e. acquire (by any means; own)"
	},
	"G471" : {
		"derivation" : "from G473 (ἀντί) and G2036 (ἔπω)",
		"def" : "to refute or deny",
		"kjv" : "gainsay, say against",
		"lemma" : "ἀντέπω",
		"translit" : "antépō"
	},
	"G5458" : {
		"lemma" : "φωστήρ",
		"translit" : "phōstḗr",
		"kjv" : "light",
		"def" : "an illuminator, i.e. (concretely) a luminary, or (abstractly) brilliancy",
		"derivation" : "from G5457 (φῶς)"
	},
	"G1123" : {
		"def" : "inscribed (figuratively)",
		"derivation" : "from G1125 (γράφω)",
		"lemma" : "γραπτός",
		"translit" : "graptós",
		"kjv" : "written"
	},
	"G2680" : {
		"translit" : "kataskeuázō",
		"lemma" : "κατασκευάζω",
		"kjv" : "build, make, ordain, prepare",
		"def" : "to prepare thoroughly (properly, by external equipment; whereas G2090 (ἑτοιμάζω) refers rather to internal fitness); by implication, to construct, create",
		"derivation" : "from G2596 (κατά) and a derivative of G4632 (σκεῦος)"
	},
	"G5386" : {
		"derivation" : "from G5384 (φίλος) and G4680 (σοφός)",
		"def" : "fond of wise things, i.e. a \"philosopher\"",
		"kjv" : "philosopher",
		"lemma" : "φιλόσοφος",
		"translit" : "philósophos"
	},
	"G4432" : {
		"derivation" : "from G4433 (πτωχεύω)",
		"def" : "beggary, i.e. indigence (literally or figuratively)",
		"kjv" : "poverty",
		"translit" : "ptōcheía",
		"lemma" : "πτωχεία"
	},
	"G5057" : {
		"kjv" : "publican",
		"translit" : "telṓnēs",
		"lemma" : "τελώνης",
		"derivation" : "from G5056 (τέλος) and G5608 (ὠνέομαι)",
		"def" : "a tax-farmer, i.e. collector of public revenue"
	},
	"G4158" : {
		"kjv" : "garment down to the foot",
		"lemma" : "ποδήρης",
		"translit" : "podḗrēs",
		"derivation" : "from G4228 (πούς) and another element of uncertain affinity",
		"def" : "a dress (G2066 (ἐσθής) implied) reaching the ankles"
	},
	"G3669" : {
		"lemma" : "ὁμοίωσις",
		"translit" : "homoíōsis",
		"kjv" : "similitude",
		"def" : "assimilation, i.e. resemblance",
		"derivation" : "from G3666 (ὁμοιόω)"
	},
	"G1723" : {
		"derivation" : "from G1722 (ἐν) and a derivative of G43 (ἀγκάλη)",
		"def" : "to take in one's arms, i.e. embrace",
		"kjv" : "take up in arms",
		"translit" : "enankalízomai",
		"lemma" : "ἐναγκαλίζομαι"
	},
	"G199" : {
		"def" : "exactly",
		"derivation" : "adverb from the same as G196 (ἀκριβέστατος)",
		"lemma" : "ἀκριβῶς",
		"translit" : "akribōs",
		"kjv" : "circumspectly, diligently, perfect(-ly)"
	},
	"G4835" : {
		"derivation" : "from G4841 (συμπάσχω)",
		"def" : "having a fellow-feeling (\"sympathetic\"), i.e. (by implication) mutually commiserative",
		"kjv" : "having compassion one of another",
		"lemma" : "συμπαθής",
		"translit" : "sympathḗs"
	},
	"G2036" : {
		"derivation" : "a primary verb (used only in the definite past tense, the others being borrowed from G2046 (ἐρέω), G4483 (ῥέω), and G5346 (φημί))",
		"def" : "to speak or say (by word or writing)",
		"kjv" : "answer, bid, bring word, call, command, grant, say (on), speak, tell",
		"translit" : "épō",
		"lemma" : "ἔπω"
	},
	"G2618" : {
		"derivation" : "from G2596 (κατά) and G2545 (καίω)",
		"def" : "to burn down (to the ground), i.e. consume wholly",
		"kjv" : "burn (up, utterly)",
		"translit" : "katakaíō",
		"lemma" : "κατακαίω"
	},
	"G205" : {
		"def" : "properly (in the plural) the top of the heap, i.e. (by implication) best of the booty",
		"derivation" : "from G206 (ἄκρον) and (a heap)",
		"lemma" : "ἀκροθίνιον",
		"translit" : "akrothínion",
		"kjv" : "spoils"
	},
	"G4462" : {
		"derivation" : "of Chaldee origin",
		"def" : "corresponding to G4461 (ῥαββί)",
		"kjv" : "Lord, Rabboni",
		"lemma" : "ῥαββονί",
		"translit" : "rhabboní"
	},
	"G264" : {
		"kjv" : "for your faults, offend, sin, trespass",
		"translit" : "hamartánō",
		"lemma" : "ἁμαρτάνω",
		"derivation" : "perhaps from G1 (Α) (as a negative particle) and the base of G3313 (μέρος)",
		"def" : "properly, to miss the mark (and so not share in the prize), i.e. (figuratively) to err, especially (morally) to sin"
	},
	"G30" : {
		"lemma" : "ἀγγεῖον",
		"translit" : "angeîon",
		"kjv" : "vessel",
		"def" : "compare the base of G43 (ἀγκάλη)); a receptacle",
		"derivation" : "from (a pail, perhaps as bent"
	},
	"G724" : {
		"derivation" : "from G726 (ἁρπάζω)",
		"def" : "pillage (properly abstract)",
		"kjv" : "extortion, ravening, spoiling",
		"lemma" : "ἁρπαγή",
		"translit" : "harpagḗ"
	},
	"G5472" : {
		"translit" : "chalkēdṓn",
		"lemma" : "χαλκηδών",
		"kjv" : "chalcedony",
		"def" : "copper-like, i.e. \"chalcedony\"",
		"derivation" : "from G5475 (χαλκός) and perhaps G1491 (εἶδος)"
	},
	"G1053" : {
		"lemma" : "Γαλατία",
		"translit" : "Galatía",
		"kjv" : "Galatia",
		"def" : "Galatia, a region of Asia",
		"derivation" : "of foreign origin"
	},
	"G2252" : {
		"derivation" : "a prolonged form of G2258 (ἦν)",
		"def" : "I was",
		"kjv" : "be, was",
		"lemma" : "ἤμην",
		"translit" : "ḗmēn"
	},
	"G5184" : {
		"derivation" : "of Hebrew origin (H6865): Tyrus (i.e. Tsor), a place in Palestine",
		"kjv" : "Tyre",
		"translit" : "Týros",
		"lemma" : "Τύρος"
	},
	"G855" : {
		"def" : "non-manifested, i.e. invisible",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G5316 (φαίνω)",
		"lemma" : "ἄφαντος",
		"translit" : "áphantos",
		"kjv" : "vanished out of sight"
	},
	"G170" : {
		"kjv" : "lack opportunity",
		"lemma" : "ἀκαιρέομαι",
		"translit" : "akairéomai",
		"derivation" : "from a compound of G1 (Α) (as a negative particle) and G2540 (καιρός) (meaning unseasonable)",
		"def" : "to be inopportune (for oneself), i.e. to fail of a proper occasion"
	},
	"G1718" : {
		"derivation" : "from G1717 (ἐμφανής)",
		"def" : "to exhibit (in person) or disclose (by words)",
		"kjv" : "appear, declare (plainly), inform, (will) manifest, shew, signify",
		"lemma" : "ἐμφανίζω",
		"translit" : "emphanízō"
	},
	"G537" : {
		"def" : "absolutely all or (singular) every one",
		"derivation" : "from G1 (Α) (as a particle of union) and G3956 (πᾶς)",
		"lemma" : "ἅπας",
		"translit" : "hápas",
		"kjv" : "all (things), every (one), whole"
	},
	"G398" : {
		"lemma" : "ἀναφαίνω",
		"translit" : "anaphaínō",
		"kjv" : "(should) appear, discover",
		"def" : "to show, i.e. (reflexively) appear, or (passively) to have pointed out",
		"derivation" : "from G303 (ἀνά) and G5316 (φαίνω)"
	},
	"G4415" : {
		"translit" : "prōtotókia",
		"lemma" : "πρωτοτόκια",
		"kjv" : "birthright",
		"def" : "primogeniture (as a privilege)",
		"derivation" : "from G4416 (πρωτότοκος)"
	},
	"G5413" : {
		"derivation" : "diminutive of G5414 (φόρτος)",
		"def" : "an invoice (as part of freight), i.e. (figuratively) a task or service",
		"kjv" : "burden",
		"translit" : "phortíon",
		"lemma" : "φορτίον"
	},
	"G4007" : {
		"derivation" : "from the base of G4008 (πέραν)",
		"def" : "an enclitic particle significant of abundance (thoroughness), i.e. emphasis; much, very or ever",
		"kjv" : "(whom-)soever",
		"lemma" : "περ",
		"translit" : "per"
	},
	"G2646" : {
		"kjv" : "guestchamber, inn",
		"lemma" : "κατάλυμα",
		"translit" : "katályma",
		"derivation" : "from G2647 (καταλύω)",
		"def" : "properly, a dissolution (breaking up of a journey), i.e. (by implication) a lodging-place"
	},
	"G4029" : {
		"derivation" : "from G4012 (περί) and G2749 (κεῖμαι)",
		"def" : "to lie all around, i.e. inclose, encircle, hamper (literally or figuratively)",
		"kjv" : "be bound (compassed) with, hang about",
		"translit" : "períkeimai",
		"lemma" : "περίκειμαι"
	},
	"G1713" : {
		"kjv" : "merchant",
		"translit" : "émporos",
		"lemma" : "ἔμπορος",
		"derivation" : "from G1722 (ἐν) and the base of G4198 (πορεύομαι)",
		"def" : "a (wholesale) tradesman"
	},
	"G2823" : {
		"def" : "an earthen pot used for baking in",
		"derivation" : "of uncertain derivation",
		"translit" : "klíbanos",
		"lemma" : "κλίβανος",
		"kjv" : "oven"
	},
	"G4938" : {
		"def" : "concussion or utter fracture (properly, concretely), i.e. complete ruin",
		"derivation" : "from G4937 (συντρίβω)",
		"lemma" : "σύντριμμα",
		"translit" : "sýntrimma",
		"kjv" : "destruction"
	},
	"G5605" : {
		"def" : "to experience the pains of parturition (literally or figuratively)",
		"derivation" : "from G5604 (ὠδίν)",
		"translit" : "ōdínō",
		"lemma" : "ὠδίνω",
		"kjv" : "travail in (birth)"
	},
	"G485" : {
		"kjv" : "contradiction, gainsaying, strife",
		"lemma" : "ἀντιλογία",
		"translit" : "antilogía",
		"derivation" : "from a derivative of G483 (ἀντιλέγω)",
		"def" : "dispute, disobedience"
	},
	"G4292" : {
		"kjv" : "provoke",
		"translit" : "prokaléomai",
		"lemma" : "προκαλέομαι",
		"derivation" : "middle voice from G4253 (πρό) and G2564 (καλέω)",
		"def" : "to call forth to oneself (challenge), i.e. (by implication) to irritate"
	},
	"G1142" : {
		"kjv" : "devil",
		"lemma" : "δαίμων",
		"translit" : "daímōn",
		"derivation" : "from (to distribute fortunes)",
		"def" : "a dæmon or supernatural spirit (of a bad nature)"
	},
	"G1775" : {
		"kjv" : "unity",
		"lemma" : "ἑνότης",
		"translit" : "henótēs",
		"derivation" : "from G1520 (εἷς)",
		"def" : "oneness, i.e. (figuratively) unanimity"
	},
	"G684" : {
		"kjv" : "damnable(-nation), destruction, die, perdition, X perish, pernicious ways, waste",
		"lemma" : "ἀπώλεια",
		"translit" : "apṓleia",
		"derivation" : "from a presumed derivative of G622 (ἀπόλλυμι)",
		"def" : "ruin or loss (physical, spiritual or eternal)"
	},
	"G2979" : {
		"def" : "to recalcitrate",
		"derivation" : "from adverb (heelwise)",
		"translit" : "laktízō",
		"lemma" : "λακτίζω",
		"kjv" : "kick"
	},
	"G1087" : {
		"lemma" : "γερουσία",
		"translit" : "gerousía",
		"kjv" : "senate",
		"def" : "the eldership, i.e. (collectively) the Jewish Sanhedrin",
		"derivation" : "from G1088 (γέρων)"
	},
	"G5100" : {
		"kjv" : "a (kind of), any (man, thing, thing at all), certain (thing), divers, he (every) man, one (X thing), ought, + partly, some (man, -body, - thing, -what), (+ that no-)thing, what(-soever), X wherewith, whom(-soever), whose(-soever)",
		"lemma" : "τὶς",
		"translit" : "tìs",
		"derivation" : "an enclitic indefinite pronoun",
		"def" : "some or any person or object"
	},
	"G2839" : {
		"lemma" : "κοινός",
		"translit" : "koinós",
		"kjv" : "common, defiled, unclean, unholy",
		"def" : "common, i.e. (literally) shared by all or several, or (ceremonially) profane",
		"derivation" : "probably from G4862 (σύν)"
	},
	"G5215" : {
		"derivation" : "apparently from a simpler (obsolete) form of (to celebrate",
		"def" : "probably akin to G103 (ᾄδω); compare H5667); a \"hymn\" or religious ode (one of the Psalms)",
		"kjv" : "hymn",
		"translit" : "hýmnos",
		"lemma" : "ὕμνος"
	},
	"G5204" : {
		"kjv" : "water",
		"lemma" : "ὕδωρ",
		"translit" : "hýdōr",
		"derivation" : "from the base of G5205 (ὑετός)",
		"def" : "water (as if rainy) literally or figuratively"
	},
	"G5488" : {
		"def" : "Charrhan (i.e. Charan), a place in Mesopotamia",
		"derivation" : "of Hebrew origin (H2771)",
		"translit" : "Charrhán",
		"lemma" : "Χαῤῥάν",
		"kjv" : "Charran"
	},
	"G2904" : {
		"derivation" : "perhaps a primary word",
		"def" : "vigor (\"great\") (literally or figuratively)",
		"kjv" : "dominion, might(-ily), power, strength",
		"lemma" : "κράτος",
		"translit" : "krátos"
	},
	"G841" : {
		"kjv" : "contentment, sufficiency",
		"lemma" : "αὐτάρκεια",
		"translit" : "autárkeia",
		"derivation" : "from G842 (αὐτάρκης)",
		"def" : "self-satisfaction, i.e. (abstractly) contentedness, or (concretely) a competence"
	},
	"G3724" : {
		"def" : "to mark out or bound (\"horizon\"), i.e. (figuratively) to appoint, decree, specify",
		"derivation" : "from G3725 (ὅριον)",
		"translit" : "horízō",
		"lemma" : "ὁρίζω",
		"kjv" : "declare, determine, limit, ordain"
	},
	"G1258" : {
		"kjv" : "language, tongue",
		"lemma" : "διάλεκτος",
		"translit" : "diálektos",
		"derivation" : "from G1256 (διαλέγομαι)",
		"def" : "a (mode of) discourse, i.e. \"dialect\""
	},
	"G3108" : {
		"def" : "beatification, i.e. attribution of good fortune",
		"derivation" : "from G3106 (μακαρίζω)",
		"lemma" : "μακαρισμός",
		"translit" : "makarismós",
		"kjv" : "blessedness"
	},
	"G1478" : {
		"kjv" : "Ezekias",
		"translit" : "Ezekías",
		"lemma" : "Ἐζεκίας",
		"derivation" : "of Hebrew origin (H2396)",
		"def" : "Ezekias (i.e. Hezekeiah), an Israelite"
	},
	"G3863" : {
		"kjv" : "provoke to emulation (jealousy)",
		"lemma" : "παραζηλόω",
		"translit" : "parazēlóō",
		"derivation" : "from G3844 (παρά) and G2206 (ζηλόω)",
		"def" : "to stimulate alongside, i.e. excite to rivalry"
	},
	"G619" : {
		"translit" : "apólausis",
		"lemma" : "ἀπόλαυσις",
		"kjv" : "enjoy(-ment)",
		"def" : "full enjoyment",
		"derivation" : "from a comparative of G575 (ἀπό) and (to enjoy)"
	},
	"G2885" : {
		"kjv" : "adorn, garnish, trim",
		"lemma" : "κοσμέω",
		"translit" : "kosméō",
		"derivation" : "from G2889 (κόσμος)",
		"def" : "to put in proper order, i.e. decorate (literally or figuratively); specially, to snuff (a wick)"
	},
	"G4670" : {
		"lemma" : "Σόδομα",
		"translit" : "Sódoma",
		"kjv" : "Sodom",
		"def" : "Sodoma (i.e. Sedom), a place in Palestine",
		"derivation" : "plural of Hebrew origin (H5467)"
	},
	"G5292" : {
		"kjv" : "subjection",
		"lemma" : "ὑποταγή",
		"translit" : "hypotagḗ",
		"derivation" : "from G5293 (ὑποτάσσω)",
		"def" : "subordination"
	},
	"G5435" : {
		"def" : "Phrygia, a region of Asia Minor",
		"derivation" : "probably of foreign origin",
		"lemma" : "Φρυγία",
		"translit" : "Phrygía",
		"kjv" : "Phrygia"
	},
	"G1668" : {
		"def" : "an ulcer (as if drawn together)",
		"derivation" : "probably from G1670 (ἑλκύω)",
		"lemma" : "ἕλκος",
		"translit" : "hélkos",
		"kjv" : "sore"
	},
	"G1888" : {
		"def" : "in theft itself, i.e. (by analogy) in actual crime",
		"derivation" : "from G1909 (ἐπί) and G846 (αὐτός) and (the dative case singular of) a derivative of (a thief)",
		"translit" : "epautophṓrōi",
		"lemma" : "ἐπαυτοφώρῳ",
		"kjv" : "in the very act"
	},
	"G1403" : {
		"derivation" : "from G1209 (δέχομαι)",
		"def" : "a reception, i.e. convivial entertainment",
		"kjv" : "feast",
		"translit" : "dochḗ",
		"lemma" : "δοχή"
	},
	"G3897" : {
		"derivation" : "neuter of a compound of G3844 (παρά) and the base of G4139 (πλησίον) (as adverb)",
		"def" : "close by, i.e. (figuratively) almost",
		"kjv" : "nigh unto",
		"lemma" : "παραπλήσιον",
		"translit" : "paraplḗsion"
	},
	"G883" : {
		"lemma" : "Ἀχαϊκός",
		"translit" : "Achaïkós",
		"kjv" : "Achaicus",
		"def" : "an Achaian; Achaicus, a Christian",
		"derivation" : "from G882 (Ἀχαΐα)"
	},
	"G1006" : {
		"derivation" : "a prolonged form of a primary verb (compare G977 (βιβρώσκω), G1016 (βοῦς))",
		"def" : "to pasture; by extension to, fodder; reflexively, to graze",
		"kjv" : "feed, keep",
		"translit" : "bóskō",
		"lemma" : "βόσκω"
	},
	"G514" : {
		"derivation" : "probably from G71 (ἄγω)",
		"def" : "deserving, comparable or suitable (as if drawing praise)",
		"kjv" : "due reward, meet, (un-)worthy",
		"lemma" : "ἄξιος",
		"translit" : "áxios"
	},
	"G4313" : {
		"def" : "to precede (as guide or herald)",
		"derivation" : "from G4253 (πρό) and G4198 (πορεύομαι)",
		"translit" : "proporeúomai",
		"lemma" : "προπορεύομαι",
		"kjv" : "go before"
	},
	"G1189" : {
		"kjv" : "beseech, pray (to), make request",
		"lemma" : "δέομαι",
		"translit" : "déomai",
		"derivation" : "middle voice of G1210 (δέω)",
		"def" : "to beg (as binding oneself), i.e. petition"
	},
	"G1136" : {
		"def" : "Gog, a symbolic name for some future Antichrist",
		"derivation" : "of Hebrew origin (H1463)",
		"lemma" : "Γώγ",
		"translit" : "Gṓg",
		"kjv" : "Gog"
	},
	"G3126" : {
		"kjv" : "mammon",
		"lemma" : "μαμμωνᾶς",
		"translit" : "mammōnâs",
		"derivation" : "of Chaldee origin (confidence, i.e. wealth, personified)",
		"def" : "mammonas, i.e. avarice (deified)"
	},
	"G1344" : {
		"translit" : "dikaióō",
		"lemma" : "δικαιόω",
		"kjv" : "free, justify(-ier), be righteous",
		"def" : "to render (i.e. show or regard as) just or innocent",
		"derivation" : "from G1342 (δίκαιος)"
	},
	"G3994" : {
		"kjv" : "mother in law, wife's mother",
		"lemma" : "πενθερά",
		"translit" : "pentherá",
		"derivation" : "feminine of G3995 (πενθερός)",
		"def" : "a wife's mother"
	},
	"G5552" : {
		"translit" : "chrýseos",
		"lemma" : "χρύσεος",
		"kjv" : "of gold, golden",
		"def" : "made of gold",
		"derivation" : "from G5557 (χρυσός)"
	},
	"G1224" : {
		"translit" : "diabaínō",
		"lemma" : "διαβαίνω",
		"kjv" : "come over, pass (through)",
		"def" : "to cross",
		"derivation" : "from G1223 (διά) and the base of G939 (βάσις)"
	},
	"G2038" : {
		"kjv" : "commit, do, labor for, minister about, trade (by), work",
		"lemma" : "ἐργάζομαι",
		"translit" : "ergázomai",
		"derivation" : "middle voice from G2041 (ἔργον)",
		"def" : "to toil (as a task, occupation, etc.), (by implication) effect, be engaged in or with, etc."
	},
	"G1666" : {
		"lemma" : "Ἐλισσαῖος",
		"translit" : "Elissaîos",
		"kjv" : "Elissæus",
		"def" : "Elissæus, an Israelite",
		"derivation" : "of Hebrew origin (H477)"
	},
	"G3977" : {
		"kjv" : "plain",
		"lemma" : "πεδινός",
		"translit" : "pedinós",
		"derivation" : "from a derivative of G4228 (πούς) (meaning the ground)",
		"def" : "level (as easy for the feet)"
	},
	"G1481" : {
		"def" : "the governor (not king) of a district",
		"derivation" : "from G1484 (ἔθνος) and G746 (ἀρχή)",
		"lemma" : "ἐθνάρχης",
		"translit" : "ethnárchēs",
		"kjv" : "ethnarch"
	},
	"G3202" : {
		"kjv" : "complainer",
		"translit" : "mempsímoiros",
		"lemma" : "μεμψίμοιρος",
		"derivation" : "from a presumed derivative of G3201 (μέμφομαι) and (fate",
		"def" : "akin to the base of G3313 (μέρος)); blaming fate, i.e. querulous (discontented)"
	},
	"G1506" : {
		"translit" : "eilikrinḗs",
		"lemma" : "εἰλικρινής",
		"kjv" : "pure, sincere",
		"def" : "judged by sunlight, i.e. tested as genuine (figuratively)",
		"derivation" : "from (the sun's ray) and G2919 (κρίνω)"
	},
	"G2515" : {
		"kjv" : "seat",
		"lemma" : "καθέδρα",
		"translit" : "kathédra",
		"derivation" : "from G2596 (κατά) and the same as G1476 (ἑδραῖος)",
		"def" : "a bench (literally or figuratively)"
	},
	"G1195" : {
		"translit" : "desmeúō",
		"lemma" : "δεσμεύω",
		"kjv" : "bind",
		"def" : "to be a binder (captor), i.e. to enchain (a prisoner), to tie on (a load)",
		"derivation" : "from a (presumed) derivative of G1196 (δεσμέω)"
	},
	"G1480" : {
		"translit" : "ethízō",
		"lemma" : "ἐθίζω",
		"kjv" : "custom",
		"def" : "to accustom, i.e. (neuter passive participle) customary",
		"derivation" : "from G1485 (ἔθος)"
	},
	"G4060" : {
		"kjv" : "bestow upon, hedge round about, put about (on, upon), set about",
		"translit" : "peritíthēmi",
		"lemma" : "περιτίθημι",
		"derivation" : "from G4012 (περί) and G5087 (τίθημι)",
		"def" : "to place around; by implication, to present"
	},
	"G3888" : {
		"kjv" : "comfort",
		"translit" : "paramythéomai",
		"lemma" : "παραμυθέομαι",
		"derivation" : "from G3844 (παρά) and the middle voice of a derivative of G3454 (μῦθος)",
		"def" : "to relate near, i.e. (by implication) encourage, console"
	},
	"G3190" : {
		"derivation" : "of uncertain origin",
		"def" : "Meleas, an Israelite",
		"kjv" : "Meleas",
		"lemma" : "Μελεᾶς",
		"translit" : "Meleâs"
	},
	"G5555" : {
		"lemma" : "χρυσόλιθος",
		"translit" : "chrysólithos",
		"kjv" : "chrysolite",
		"def" : "gold-stone, i.e. a yellow gem (\"chrysolite\")",
		"derivation" : "from G5557 (χρυσός) and G3037 (λίθος)"
	},
	"G2314" : {
		"def" : "an opponent of deity",
		"derivation" : "from G2316 (θεός) and G3164 (μάχομαι)",
		"translit" : "theomáchos",
		"lemma" : "θεομάχος",
		"kjv" : "to fight against God"
	},
	"G3438" : {
		"derivation" : "from G3306 (μένω)",
		"def" : "a staying, i.e. residence (the act or the place)",
		"kjv" : "abode, mansion",
		"lemma" : "μονή",
		"translit" : "monḗ"
	},
	"G3837" : {
		"def" : "universally",
		"derivation" : "genitive case (as adverb of place) of a presumed derivative of G3956 (πᾶς)",
		"translit" : "pantachoû",
		"lemma" : "πανταχοῦ",
		"kjv" : "in all places, everywhere"
	},
	"G2826" : {
		"kjv" : "bed",
		"lemma" : "κλινίδιον",
		"translit" : "klinídion",
		"derivation" : "neuter of a presumed derivative of G2825 (κλίνη)",
		"def" : "a pallet or little couch"
	},
	"G5054" : {
		"kjv" : "death",
		"lemma" : "τελευτή",
		"translit" : "teleutḗ",
		"derivation" : "from G5053 (τελευτάω)",
		"def" : "decease"
	},
	"G1988" : {
		"derivation" : "from G1909 (ἐπί) and a presumed derivative of G2476 (ἵστημι)",
		"def" : "an appointee over, i.e. commander (teacher)",
		"kjv" : "master",
		"lemma" : "ἐπιστάτης",
		"translit" : "epistátēs"
	},
	"G3476" : {
		"kjv" : "Naasson",
		"lemma" : "Ναασσών",
		"translit" : "Naassṓn",
		"derivation" : "of Hebrew origin (H5177)",
		"def" : "Naasson (i.e. Nachshon), an Israelite"
	},
	"G1842" : {
		"derivation" : "from G1537 (ἐκ) and G3645 (ὀλοθρεύω)",
		"def" : "to extirpate",
		"kjv" : "destroy",
		"lemma" : "ἐξολοθρεύω",
		"translit" : "exolothreúō"
	},
	"G121" : {
		"translit" : "áthōos",
		"lemma" : "ἄθωος",
		"kjv" : "innocent",
		"def" : "not guilty",
		"derivation" : "from G1 (Α) (as a negative particle) and probably a derivative of G5087 (τίθημι) (meaning a penalty)"
	},
	"G2926" : {
		"def" : "a hidden place, i.e. cellar (\"crypt\")",
		"derivation" : "feminine of G2927 (κρυπτός)",
		"lemma" : "κρύπτη",
		"translit" : "krýptē",
		"kjv" : "secret"
	},
	"G5414" : {
		"def" : "something carried, i.e. the cargo of a ship",
		"derivation" : "from G5342 (φέρω)",
		"translit" : "phórtos",
		"lemma" : "φόρτος",
		"kjv" : "lading"
	},
	"G863" : {
		"derivation" : "from G575 (ἀπό) and (to send",
		"def" : "an intensive form of , to go); to send forth, in various applications (as follow)",
		"kjv" : "cry, forgive, forsake, lay aside, leave, let (alone, be, go, have), omit, put (send) away, remit, suffer, yield up",
		"translit" : "aphíēmi",
		"lemma" : "ἀφίημι"
	},
	"G3102" : {
		"lemma" : "μαθήτρια",
		"translit" : "mathḗtria",
		"kjv" : "disciple",
		"def" : "a female pupil",
		"derivation" : "feminine from G3101 (μαθητής)"
	},
	"G5316" : {
		"derivation" : "prolongation for the base of G5457 (φῶς)",
		"def" : "to lighten (shine), i.e. show (transitive or intransitive, literal or figurative)",
		"kjv" : "appear, seem, be seen, shine, X think",
		"translit" : "phaínō",
		"lemma" : "φαίνω"
	},
	"G3552" : {
		"translit" : "noséō",
		"lemma" : "νοσέω",
		"kjv" : "dote",
		"def" : "to be sick, i.e. (by implication, of a diseased appetite) to hanker after (figuratively, to harp upon)",
		"derivation" : "from G3554 (νόσος)"
	},
	"G2797" : {
		"def" : "Cis (i.e. Kish), an Israelite",
		"derivation" : "of Hebrew origin (H7027)",
		"lemma" : "Κίς",
		"translit" : "Kís",
		"kjv" : "Cis"
	},
	"G2538" : {
		"derivation" : "from G2537 (καινός)",
		"def" : "renewal (figuratively)",
		"kjv" : "newness",
		"lemma" : "καινότης",
		"translit" : "kainótēs"
	},
	"G3154" : {
		"lemma" : "ματαιόω",
		"translit" : "mataióō",
		"kjv" : "become vain",
		"def" : "to render (passively, become) foolish, i.e. (morally) wicked or (specially), idolatrous",
		"derivation" : "from G3152 (μάταιος)"
	},
	"G184" : {
		"def" : "corresponding to H2506 and H1818); Akeldama, a place near Jerusalem",
		"derivation" : "of Chaldee origin (meaning field of blood",
		"translit" : "Akeldamá",
		"lemma" : "Ἀκελδαμά",
		"kjv" : "Aceldama"
	},
	"G2681" : {
		"translit" : "kataskēnóō",
		"lemma" : "κατασκηνόω",
		"kjv" : "lodge, rest",
		"def" : "to camp down, i.e. haunt; figuratively, to remain",
		"derivation" : "from G2596 (κατά) and G4637 (σκηνόω)"
	},
	"G2492" : {
		"def" : "Job (i.e. Ijob), a patriarch",
		"derivation" : "of Hebrew origin (H347)",
		"translit" : "Iṓb",
		"lemma" : "Ἰώβ",
		"kjv" : "Job"
	},
	"G5065" : {
		"kjv" : "fourteenth",
		"lemma" : "τεσσαρεσκαιδέκατος",
		"translit" : "tessareskaidékatos",
		"derivation" : "from G5064 (τέσσαρες) and G2532 (καί) and G1182 (δέκατος)",
		"def" : "fourteenth"
	},
	"G3679" : {
		"derivation" : "from G3681 (ὄνειδος)",
		"def" : "to defame, i.e. rail at, chide, taunt",
		"kjv" : "cast in teeth, (suffer) reproach, revile, upbraid",
		"translit" : "oneidízō",
		"lemma" : "ὀνειδίζω"
	},
	"G1510" : {
		"derivation" : "the first person singular present indicative; a prolonged form of a primary and defective verb",
		"def" : "I exist (used only when emphatic)",
		"kjv" : "am, have been, X it is I, was",
		"translit" : "eimí",
		"lemma" : "εἰμί"
	},
	"G3363" : {
		"derivation" : "i.e. G2443 (ἵνα) and G3361 (μή)",
		"def" : "in order (or so) that not",
		"kjv" : "albeit not, lest, that, no(-t, (-thing))",
		"lemma" : "ἵνα μή",
		"translit" : "hína mḗ"
	},
	"G540" : {
		"def" : "fatherless, i.e. of unrecorded paternity",
		"derivation" : "from G1 (Α) (as a negative particle) and G3962 (πατήρ)",
		"translit" : "apátōr",
		"lemma" : "ἀπάτωρ",
		"kjv" : "without father"
	},
	"G2476" : {
		"def" : "to stand (transitively or intransitively), used in various applications (literally or figuratively)",
		"derivation" : "a prolonged form of a primary (of the same meaning, and used for it in certain tenses)",
		"lemma" : "ἵστημι",
		"translit" : "hístēmi",
		"kjv" : "abide, appoint, bring, continue, covenant, establish, hold up, lay, present, set (up), stanch, stand (by, forth, still, up)"
	},
	"G5238" : {
		"derivation" : "from G5228 (ὑπέρ) and the neuter plural of G1565 (ἐκεῖνος)",
		"def" : "above those parts, i.e. still farther",
		"kjv" : "beyond",
		"lemma" : "ὑπερέκεινα",
		"translit" : "hyperékeina"
	},
	"G5609" : {
		"derivation" : "apparently a primary word",
		"def" : "an \"egg\"",
		"kjv" : "egg",
		"lemma" : "ὠόν",
		"translit" : "ōón"
	},
	"G4540" : {
		"lemma" : "Σαμάρεια",
		"translit" : "Samáreia",
		"kjv" : "Samaria",
		"def" : "Samaria (i.e. Shomeron), a city and region of Palestine",
		"derivation" : "of Hebrew origin (H8111)"
	},
	"G563" : {
		"derivation" : "adverb from a compound of G1 (Α) (as a negative participle) and a presumed derivative of G4049 (περισπάω)",
		"def" : "undistractedly, i.e. free from (domestic) solicitude",
		"kjv" : "without distraction",
		"lemma" : "ἀπερισπάστως",
		"translit" : "aperispástōs"
	},
	"G5162" : {
		"def" : "a nourisher, i.e. nurse",
		"derivation" : "from G5142 (τρέφω)",
		"translit" : "trophós",
		"lemma" : "τροφός",
		"kjv" : "nurse"
	},
	"G1459" : {
		"derivation" : "from G1722 (ἐν) and G2641 (καταλείπω)",
		"def" : "to leave behind in some place, i.e. (in a good sense) let remain over, or (in a bad sense) to desert",
		"kjv" : "forsake, leave",
		"lemma" : "ἐγκαταλείπω",
		"translit" : "enkataleípō"
	},
	"G1496" : {
		"kjv" : "idolater",
		"translit" : "eidōlolátrēs",
		"lemma" : "εἰδωλολάτρης",
		"derivation" : "from G1497 (εἴδωλον) and the base of G3000 (λατρεύω)",
		"def" : "an image- (servant or) worshipper (literally or figuratively)"
	},
	"G3440" : {
		"def" : "merely",
		"derivation" : "neuter of G3441 (μόνος) as adverb",
		"translit" : "mónon",
		"lemma" : "μόνον",
		"kjv" : "alone, but, only"
	},
	"G2785" : {
		"kjv" : "whale",
		"translit" : "kētos",
		"lemma" : "κῆτος",
		"derivation" : "probably from the base of G5490 (χάσμα)",
		"def" : "a huge fish (as gaping for prey)"
	},
	"G1697" : {
		"derivation" : "of Hebrew origin (H2544)",
		"def" : "Emmor (i.e. Chamor), a Canaanite",
		"kjv" : "Emmor",
		"translit" : "Emmór",
		"lemma" : "Ἐμμόρ"
	},
	"G4468" : {
		"kjv" : "mischief",
		"translit" : "rhaidiourgía",
		"lemma" : "ῥᾳδιουργία",
		"derivation" : "from the same as G4467 (ῥᾳδιούργημα)",
		"def" : "recklessness, i.e. (by extension) malignity"
	},
	"G1293" : {
		"lemma" : "διαστολή",
		"translit" : "diastolḗ",
		"kjv" : "difference, distinction",
		"def" : "a variation",
		"derivation" : "from G1291 (διαστέλλομαι)"
	},
	"G4347" : {
		"derivation" : "from G4314 (πρός) and G2853 (κολλάω)",
		"def" : "to glue to, i.e. (figuratively) to adhere",
		"kjv" : "cleave, join (self)",
		"lemma" : "προσκολλάω",
		"translit" : "proskolláō"
	},
	"G1355" : {
		"def" : "on which very account",
		"derivation" : "from G1352 (διό) and G4007 (περ)",
		"translit" : "dióper",
		"lemma" : "διόπερ",
		"kjv" : "wherefore"
	},
	"G2697" : {
		"kjv" : "possession",
		"lemma" : "κατάσχεσις",
		"translit" : "katáschesis",
		"derivation" : "from G2722 (κατέχω)",
		"def" : "a holding down, i.e. occupancy"
	},
	"G3997" : {
		"derivation" : "strengthened from the alternate of G3958 (πάσχω)",
		"def" : "grief",
		"kjv" : "mourning, sorrow",
		"translit" : "pénthos",
		"lemma" : "πένθος"
	},
	"G4020" : {
		"derivation" : "from G4012 (περί) and G2038 (ἐργάζομαι)",
		"def" : "to work all around, i.e. bustle about (meddle)",
		"kjv" : "be a busybody",
		"translit" : "periergázomai",
		"lemma" : "περιεργάζομαι"
	},
	"G1118" : {
		"def" : "a parent",
		"derivation" : "from the base of G1096 (γίνομαι)",
		"lemma" : "γονεύς",
		"translit" : "goneús",
		"kjv" : "parent"
	},
	"G1044" : {
		"def" : "an ulcer (\"gangrene\")",
		"derivation" : "from (to gnaw)",
		"lemma" : "γάγγραινα",
		"translit" : "gángraina",
		"kjv" : "canker"
	},
	"G2895" : {
		"derivation" : "probably of foreign origin",
		"def" : "a mattress",
		"kjv" : "bed",
		"lemma" : "κράββατος",
		"translit" : "krábbatos"
	},
	"G4837" : {
		"derivation" : "from G4862 (σύν) and G3870 (παρακαλέω)",
		"def" : "to console jointly",
		"kjv" : "comfort together",
		"translit" : "symparakaléō",
		"lemma" : "συμπαρακαλέω"
	},
	"G949" : {
		"lemma" : "βέβαιος",
		"translit" : "bébaios",
		"kjv" : "firm, of force, stedfast, sure",
		"def" : "stable (literally or figuratively)",
		"derivation" : "from the base of G939 (βάσις) (through the idea of basality)"
	},
	"G63" : {
		"kjv" : "abide in the field",
		"lemma" : "ἀγραυλέω",
		"translit" : "agrauléō",
		"derivation" : "from G68 (ἀγρός) and G832 (αὐλέω) (in the sense of G833 (αὐλή))",
		"def" : "to camp out"
	},
	"G3924" : {
		"derivation" : "from G3844 (παρά) and G1622 (ἐκτός)",
		"def" : "near outside, i.e. besides",
		"kjv" : "except, saving, without",
		"lemma" : "παρεκτός",
		"translit" : "parektós"
	},
	"G3069" : {
		"def" : "Lydda (i.e. Lod), a place in Palestine",
		"derivation" : "of Hebrew origin (H3850)",
		"translit" : "Lýdda",
		"lemma" : "Λύδδα",
		"kjv" : "Lydda"
	},
	"G1004" : {
		"kjv" : "mire",
		"lemma" : "βόρβορος",
		"translit" : "bórboros",
		"derivation" : "of uncertain derivation",
		"def" : "mud"
	},
	"G68" : {
		"lemma" : "ἀγρός",
		"translit" : "agrós",
		"kjv" : "country, farm, piece of ground, land",
		"def" : "a field (as a drive for cattle); genitive case, the country; specially, a farm, i.e. hamlet",
		"derivation" : "from G71 (ἄγω)"
	},
	"G4326" : {
		"def" : "to require additionally, i.e. want further",
		"derivation" : "from G4314 (πρός) and G1189 (δέομαι)",
		"translit" : "prosdéomai",
		"lemma" : "προσδέομαι",
		"kjv" : "need"
	},
	"G2499" : {
		"kjv" : "Jose",
		"lemma" : "Ἰωσή",
		"translit" : "Iōsḗ",
		"derivation" : "genitive case of G2500 (Ἰωσῆς)",
		"def" : "Jose, an Israelite"
	},
	"G1980" : {
		"def" : "to inspect, i.e. (by implication) to select; by extension, to go to see, relieve",
		"derivation" : "middle voice from G1909 (ἐπί) and the base of G4649 (σκοπός)",
		"translit" : "episképtomai",
		"lemma" : "ἐπισκέπτομαι",
		"kjv" : "look out, visit"
	},
	"G5374" : {
		"lemma" : "Φιλιππήσιος",
		"translit" : "Philippḗsios",
		"kjv" : "Philippian",
		"def" : "a Philippesian (Philippian), i.e. native of Philippi",
		"derivation" : "from G5375 (Φίλιπποι)"
	},
	"G5075" : {
		"kjv" : "(be) tetrarch",
		"translit" : "tetrarchéō",
		"lemma" : "τετραρχέω",
		"derivation" : "from G5076 (τετράρχης)",
		"def" : "to be a tetrarch"
	},
	"G5081" : {
		"kjv" : "clearly",
		"translit" : "tēlaugōs",
		"lemma" : "τηλαυγῶς",
		"derivation" : "adverb from a compound of a derivative of G5056 (τέλος) and G827 (αὐγή)",
		"def" : "in a far-shining manner, i.e. plainly"
	},
	"G4329" : {
		"derivation" : "from G4328 (προσδοκάω)",
		"def" : "apprehension (of evil); by implication, infliction anticipated",
		"kjv" : "expectation, looking after",
		"lemma" : "προσδοκία",
		"translit" : "prosdokía"
	},
	"G3752" : {
		"lemma" : "ὅταν",
		"translit" : "hótan",
		"kjv" : "as long (soon) as, that, + till, when(-soever), while",
		"def" : "whenever (implying hypothesis or more or less uncertainty); also causatively (conjunctionally) inasmuch as",
		"derivation" : "from G3753 (ὅτε) and G302 (ἄν)"
	},
	"G4811" : {
		"translit" : "sykophantéō",
		"lemma" : "συκοφαντέω",
		"kjv" : "accuse falsely, take by false accusation",
		"def" : "to be a fig-informer (reporter of the law forbidding the exportation of figs from Greece), \"sycophant\", i.e. (genitive and by extension) to defraud (exact unlawfully, extort)",
		"derivation" : "from a compound of G4810 (σῦκον) and a derivative of G5316 (φαίνω)"
	},
	"G3311" : {
		"translit" : "merismós",
		"lemma" : "μερισμός",
		"kjv" : "dividing asunder, gift",
		"def" : "a separation or distribution",
		"derivation" : "from G3307 (μερίζω)"
	},
	"G3414" : {
		"derivation" : "of Latin origin",
		"def" : "a mna (i.e. mina), a certain weight",
		"kjv" : "pound",
		"translit" : "mnâ",
		"lemma" : "μνᾶ"
	},
	"G663" : {
		"def" : "(figuratively) decisiveness, i.e. rigor",
		"derivation" : "from the base of G664 (ἀποτόμως)",
		"lemma" : "ἀποτομία",
		"translit" : "apotomía",
		"kjv" : "severity"
	},
	"G4331" : {
		"kjv" : "come nigh",
		"translit" : "prosengízō",
		"lemma" : "προσεγγίζω",
		"derivation" : "from G4314 (πρός) and G1448 (ἐγγίζω)",
		"def" : "to approach near"
	},
	"G4967" : {
		"kjv" : "slaughter",
		"lemma" : "σφαγή",
		"translit" : "sphagḗ",
		"derivation" : "from G4969 (σφάζω)",
		"def" : "butchery (of animals for food or sacrifice, or (figuratively) of men (destruction))"
	},
	"G1413" : {
		"kjv" : "of great authority, mighty, potentate",
		"translit" : "dynástēs",
		"lemma" : "δυνάστης",
		"derivation" : "from G1410 (δύναμαι)",
		"def" : "a ruler or officer"
	},
	"G2615" : {
		"kjv" : "bring into bondage",
		"translit" : "katadoulóō",
		"lemma" : "καταδουλόω",
		"derivation" : "from G2596 (κατά) and G1402 (δουλόω)",
		"def" : "to enslave utterly"
	},
	"G3672" : {
		"kjv" : "without controversy",
		"lemma" : "ὁμολογουμένως",
		"translit" : "homologouménōs",
		"derivation" : "adverb of present passive participle of G3670 (ὁμολογέω)",
		"def" : "confessedly"
	},
	"G2271" : {
		"kjv" : "quietness, silence",
		"translit" : "hēsychía",
		"lemma" : "ἡσυχία",
		"derivation" : "feminine of G2272 (ἡσύχιος)",
		"def" : "(as noun) stillness, i.e. desistance from bustle or language"
	},
	"G1225" : {
		"kjv" : "accuse",
		"lemma" : "διαβάλλω",
		"translit" : "diabállō",
		"derivation" : "from G1223 (διά) and G906 (βάλλω)",
		"def" : "(figuratively) to traduce"
	},
	"G1051" : {
		"translit" : "gála",
		"lemma" : "γάλα",
		"kjv" : "milk",
		"def" : "milk (figuratively)",
		"derivation" : "of uncertain affinity"
	},
	"G762" : {
		"def" : "not extinguished, i.e. (by implication) perpetual",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G4570 (σβέννυμι)",
		"translit" : "ásbestos",
		"lemma" : "ἄσβεστος",
		"kjv" : "not to be quenched, unquenchable"
	},
	"G4496" : {
		"kjv" : "cast (down, out), scatter abroad, throw",
		"translit" : "rhíptō",
		"lemma" : "ῥίπτω",
		"derivation" : "a primary verb (perhaps rather akin to the base of G4474 (ῥαπίζω), through the idea of sudden motion)",
		"def" : "to fling (properly, with a quick toss, thus differing from G906 (βάλλω), which denotes a deliberate hurl; and from (see in G1614 (ἐκτείνω)), which indicates an extended projection); by qualification, to deposit (as if a load); by extension, to disperse"
	},
	"G305" : {
		"derivation" : "from G303 (ἀνά) and the base of G939 (βάσις)",
		"def" : "to go up (literally or figuratively)",
		"kjv" : "arise, ascend (up), climb (go, grow, rise, spring) up, come (up)",
		"translit" : "anabaínō",
		"lemma" : "ἀναβαίνω"
	},
	"G3421" : {
		"derivation" : "from a derivative of G3420 (μνήμη)",
		"def" : "to exercise memory, i.e. recollect; by implication, to punish; also to rehearse",
		"kjv" : "make mention; be mindful, remember",
		"translit" : "mnēmoneúō",
		"lemma" : "μνημονεύω"
	},
	"G782" : {
		"derivation" : "from G1 (Α) (as a particle of union) and a presumed form of G4685 (σπάω)",
		"def" : "to enfold in the arms, i.e. (by implication) to salute, (figuratively) to welcome",
		"kjv" : "embrace, greet, salute, take leave",
		"lemma" : "ἀσπάζομαι",
		"translit" : "aspázomai"
	},
	"G4461" : {
		"kjv" : "Master, Rabbi",
		"translit" : "rhabbí",
		"lemma" : "ῥαββί",
		"derivation" : "of Hebrew origin (H7227 with pronominal suffix)",
		"def" : "my master, i.e Rabbi, as an official title of honor"
	},
	"G4141" : {
		"derivation" : "apparently another form of G4111 (πλάσσω) (through the idea of flattening out)",
		"def" : "to pound, i.e. (figuratively) to inflict with (calamity)",
		"kjv" : "smite",
		"translit" : "plḗssō",
		"lemma" : "πλήσσω"
	},
	"G4544" : {
		"translit" : "Sámos",
		"lemma" : "Σάμος",
		"kjv" : "Samos",
		"def" : "Samus, an island of the Mediterranean",
		"derivation" : "of uncertain affinity"
	},
	"G1439" : {
		"translit" : "eáō",
		"lemma" : "ἐάω",
		"kjv" : "commit, leave, let (alone), suffer",
		"def" : "to let be, i.e. permit or leave alone",
		"derivation" : "of uncertain affinity"
	},
	"G4547" : {
		"lemma" : "σανδάλιον",
		"translit" : "sandálion",
		"kjv" : "sandal",
		"def" : "of uncertain origin); a slipper or sole-pad",
		"derivation" : "neuter of a derivative of (a \"sandal\""
	},
	"G4088" : {
		"kjv" : "bitterness",
		"lemma" : "πικρία",
		"translit" : "pikría",
		"derivation" : "from G4089 (πικρός)",
		"def" : "acridity (especially poison), literally or figuratively"
	},
	"G1019" : {
		"kjv" : "be slack, tarry",
		"translit" : "bradýnō",
		"lemma" : "βραδύνω",
		"derivation" : "from G1021 (βραδύς)",
		"def" : "to delay"
	},
	"G5014" : {
		"kjv" : "humiliation, be made low, low estate, vile",
		"translit" : "tapeínōsis",
		"lemma" : "ταπείνωσις",
		"derivation" : "from G5013 (ταπεινόω)",
		"def" : "depression (in rank or feeling)"
	},
	"G1369" : {
		"def" : "to make apart, i.e. sunder (figuratively, alienate)",
		"derivation" : "from a derivative of G1364 (δίς)",
		"lemma" : "διχάζω",
		"translit" : "dicházō",
		"kjv" : "set at variance"
	},
	"G1341" : {
		"kjv" : "righteous judgment",
		"lemma" : "δικαιοκρισία",
		"translit" : "dikaiokrisía",
		"derivation" : "from G1342 (δίκαιος) and G2920 (κρίσις)",
		"def" : "a just sentence"
	},
	"G162" : {
		"derivation" : "from G164 (αἰχμάλωτος)",
		"def" : "to capture (like G163 (αἰχμαλωτίζω))",
		"kjv" : "lead captive",
		"translit" : "aichmalōteúō",
		"lemma" : "αἰχμαλωτεύω"
	},
	"G2595" : {
		"derivation" : "from (to wither)",
		"def" : "a dry twig or straw",
		"kjv" : "mote",
		"lemma" : "κάρφος",
		"translit" : "kárphos"
	},
	"G1209" : {
		"kjv" : "accept, receive, take",
		"translit" : "déchomai",
		"lemma" : "δέχομαι",
		"derivation" : "middle voice of a primary verb",
		"def" : "to receive (in various applications, literally or figuratively)"
	},
	"G3893" : {
		"derivation" : "from G3844 (παρά) and G4087 (πικραίνω)",
		"def" : "to embitter alongside, i.e. (figuratively) to exasperate",
		"kjv" : "provoke",
		"translit" : "parapikraínō",
		"lemma" : "παραπικραίνω"
	},
	"G718" : {
		"def" : "to joint, i.e. (figuratively) to woo (reflexively, to betroth)",
		"derivation" : "from G719 (ἁρμός)",
		"lemma" : "ἁρμόζω",
		"translit" : "harmózō",
		"kjv" : "espouse"
	},
	"G5402" : {
		"def" : "probably akin to the base of G5457 (φῶς)); Phœbe, a Christian woman",
		"derivation" : "feminine of (bright",
		"lemma" : "Φοίβη",
		"translit" : "Phoíbē",
		"kjv" : "Phebe"
	},
	"G4558" : {
		"translit" : "Sárepta",
		"lemma" : "Σάρεπτα",
		"kjv" : "Sarepta",
		"def" : "Sarepta (i.e. Tsarephath), a place in Palestine",
		"derivation" : "of Hebrew origin (H6886)"
	},
	"G3940" : {
		"def" : "foreign residence",
		"derivation" : "from G3941 (πάροικος)",
		"lemma" : "παροικία",
		"translit" : "paroikía",
		"kjv" : "sojourning, X as strangers"
	},
	"G4244" : {
		"lemma" : "πρεσβυτέριον",
		"translit" : "presbytérion",
		"kjv" : "(estate of) elder(-s), presbytery",
		"def" : "the order of elders, i.e. (specially), Israelite Sanhedrin or Christian \"presbytery\"",
		"derivation" : "neuter of a presumed derivative of G4245 (πρεσβύτερος)"
	},
	"G3768" : {
		"kjv" : "hitherto not, (no…) as yet, not yet",
		"lemma" : "οὔπω",
		"translit" : "oúpō",
		"derivation" : "from G3756 (οὐ) and G4452 (-πω)",
		"def" : "not yet"
	},
	"G2833" : {
		"translit" : "knḗthō",
		"lemma" : "κνήθω",
		"kjv" : "X itching",
		"def" : "to scratch, i.e. (by implication) to tickle",
		"derivation" : "from a primary (to scrape)"
	},
	"G1869" : {
		"kjv" : "exalt self, poise (lift, take) up",
		"lemma" : "ἐπαίρω",
		"translit" : "epaírō",
		"derivation" : "from G1909 (ἐπί) and G142 (αἴρω)",
		"def" : "to raise up (literally or figuratively)"
	},
	"G2194" : {
		"lemma" : "Ζαβουλών",
		"translit" : "Zaboulṓn",
		"kjv" : "Zabulon",
		"def" : "Zabulon (i.e. Zebulon), a region of Palestine",
		"derivation" : "of Hebrew origin (H2074)"
	},
	"G206" : {
		"lemma" : "ἄκρον",
		"translit" : "ákron",
		"kjv" : "one end… other, tip, top, uttermost participle ",
		"def" : "the extremity",
		"derivation" : "neuter of an adjective probably akin to the base of G188 (ἀκμήν)"
	},
	"G4520" : {
		"def" : "a \"sabbatism\", i.e. (figuratively) the repose of Christianity (as a type of heaven)",
		"derivation" : "from a derivative of G4521 (σάββατον)",
		"lemma" : "σαββατισμός",
		"translit" : "sabbatismós",
		"kjv" : "rest"
	},
	"G4207" : {
		"kjv" : "afar off",
		"translit" : "pórrhōthen",
		"lemma" : "πόῤῥωθεν",
		"derivation" : "from G4206 (πόῤῥω) with adverbial enclitic of source",
		"def" : "from far, or (by implication) at a distance, i.e. distantly"
	},
	"G5096" : {
		"derivation" : "from G5092 (τιμή)",
		"def" : "valuable; Timon, a Christian",
		"kjv" : "Timon",
		"lemma" : "Τίμων",
		"translit" : "Tímōn"
	},
	"G3092" : {
		"def" : "Maath, an Israelite",
		"derivation" : "probably of Hebrew origin",
		"lemma" : "Μαάθ",
		"translit" : "Maáth",
		"kjv" : "Maath"
	},
	"G5256" : {
		"lemma" : "ὑπηρετέω",
		"translit" : "hypēretéō",
		"kjv" : "minister (unto), serve",
		"def" : "to be a subordinate, i.e. (by implication) subserve",
		"derivation" : "from G5257 (ὑπηρέτης)"
	},
	"G2074" : {
		"kjv" : "Esrom",
		"translit" : "Esrṓm",
		"lemma" : "Ἐσρώμ",
		"derivation" : "of Hebrew origin (H2696)",
		"def" : "Esrom (i.e. Chetsron), an Israelite"
	},
	"G4409" : {
		"def" : "to be first (in rank or influence)",
		"derivation" : "from G4413 (πρῶτος)",
		"lemma" : "πρωτεύω",
		"translit" : "prōteúō",
		"kjv" : "have the preeminence"
	},
	"G1035" : {
		"def" : "(abstractly) eating (literally or figuratively); by extension (concretely) food (literally or figuratively)",
		"derivation" : "from the base of G977 (βιβρώσκω)",
		"translit" : "brōsis",
		"lemma" : "βρῶσις",
		"kjv" : "eating, food, meat"
	},
	"G771" : {
		"def" : "a scruple of conscience",
		"derivation" : "from G770 (ἀσθενέω)",
		"lemma" : "ἀσθένημα",
		"translit" : "asthénēma",
		"kjv" : "infirmity"
	},
	"G1600" : {
		"derivation" : "from G1537 (ἐκ) and a form of G4072 (πέτομαι)",
		"def" : "to fly out, i.e. (by analogy) to extend",
		"kjv" : "stretch forth",
		"translit" : "ekpetánnymi",
		"lemma" : "ἐκπετάννυμι"
	},
	"G1733" : {
		"kjv" : "eleven",
		"translit" : "héndeka",
		"lemma" : "ἕνδεκα",
		"derivation" : "from (the neuter of) G1520 (εἷς) and G1176 (δέκα)",
		"def" : "one and ten, i.e. eleven"
	},
	"G1454" : {
		"translit" : "égersis",
		"lemma" : "ἔγερσις",
		"kjv" : "resurrection",
		"def" : "a resurgence (from death)",
		"derivation" : "from G1453 (ἐγείρω)"
	},
	"G1345" : {
		"translit" : "dikaíōma",
		"lemma" : "δικαίωμα",
		"kjv" : "judgment, justification, ordinance, righteousness",
		"def" : "an equitable deed; by implication, a statute or decision",
		"derivation" : "from G1344 (δικαιόω)"
	},
	"G2016" : {
		"translit" : "epiphanḗs",
		"lemma" : "ἐπιφανής",
		"kjv" : "notable",
		"def" : "conspicuous, i.e. (figuratively) memorable",
		"derivation" : "from G2014 (ἐπιφαίνω)"
	},
	"G987" : {
		"kjv" : "(speak) blaspheme(-er, -mously, -my), defame, rail on, revile, speak evil",
		"translit" : "blasphēméō",
		"lemma" : "βλασφημέω",
		"derivation" : "from G989 (βλάσφημος)",
		"def" : "to vilify; specially, to speak impiously"
	},
	"G5547" : {
		"derivation" : "from G5548 (χρίω)",
		"def" : "anointed, i.e. the Messiah, an epithet of Jesus",
		"kjv" : "Christ",
		"translit" : "Christós",
		"lemma" : "Χριστός"
	},
	"G1753" : {
		"kjv" : "operation, strong, (effectual) working",
		"lemma" : "ἐνέργεια",
		"translit" : "enérgeia",
		"derivation" : "from G1756 (ἐνεργής)",
		"def" : "efficiency (\"energy\")"
	},
	"G2521" : {
		"derivation" : "from G2596 (κατά)",
		"def" : "and (to sit; akin to the base of G1476 (ἑδραῖος)); to sit down; figuratively, to remain, reside",
		"kjv" : "dwell, sit (by, down)",
		"lemma" : "κάθημαι",
		"translit" : "káthēmai"
	},
	"G1301" : {
		"derivation" : "from G1223 (διά) and G5083 (τηρέω)",
		"def" : "to watch thoroughly, i.e. (positively and transitively) to observe strictly, or (negatively and reflexively) to avoid wholly",
		"kjv" : "keep",
		"translit" : "diatēréō",
		"lemma" : "διατηρέω"
	},
	"G859" : {
		"kjv" : "deliverance, forgiveness, liberty, remission",
		"translit" : "áphesis",
		"lemma" : "ἄφεσις",
		"derivation" : "from G863 (ἀφίημι)",
		"def" : "freedom; (figuratively) pardon"
	},
	"G1146" : {
		"def" : "a finger-ring",
		"derivation" : "from G1147 (δάκτυλος)",
		"translit" : "daktýlios",
		"lemma" : "δακτύλιος",
		"kjv" : "ring"
	},
	"G2042" : {
		"derivation" : "from a presumed prolonged form of G2054 (ἔρις)",
		"def" : "to stimulate (especially to anger)",
		"kjv" : "provoke",
		"translit" : "erethízō",
		"lemma" : "ἐρεθίζω"
	},
	"G346" : {
		"def" : "to sum up",
		"derivation" : "from G303 (ἀνά) and G2775 (κεφαλαιόω) (in its original sense)",
		"translit" : "anakephalaíomai",
		"lemma" : "ἀνακεφαλαίομαι",
		"kjv" : "briefly comprehend, gather together in one"
	},
	"G2869" : {
		"translit" : "kopázō",
		"lemma" : "κοπάζω",
		"kjv" : "cease",
		"def" : "to tire, i.e. (figuratively) to relax",
		"derivation" : "from G2873 (κόπος)"
	},
	"G1217" : {
		"translit" : "dēmiourgós",
		"lemma" : "δημιουργός",
		"kjv" : "maker",
		"def" : "a worker for the people, i.e. mechanic (spoken of the Creator)",
		"derivation" : "from G1218 (δῆμος) and G2041 (ἔργον)"
	},
	"G2449" : {
		"derivation" : "feminine of G2453 (Ἰουδαῖος) (with G1093 (γῆ) implied)",
		"def" : "the Judæan land (i.e. Judæa), a region of Palestine",
		"kjv" : "Judæa",
		"lemma" : "Ἰουδαία",
		"translit" : "Ioudaía"
	},
	"G625" : {
		"derivation" : "probably from the same as G624 (Ἀπολλωνία)",
		"def" : "Apollos, an Israelite",
		"kjv" : "Apollos",
		"translit" : "Apollōs",
		"lemma" : "Ἀπολλῶς"
	},
	"G2693" : {
		"def" : "to strew down, i.e. (by implication) to prostrate (slay)",
		"derivation" : "from G2596 (κατά) and G4766 (στρώννυμι)",
		"translit" : "katastrṓnnymi",
		"lemma" : "καταστρώννυμι",
		"kjv" : "overthrow"
	},
	"G2329" : {
		"kjv" : "heat",
		"lemma" : "θέρμη",
		"translit" : "thérmē",
		"derivation" : "from the base of G2330 (θέρος)",
		"def" : "warmth"
	},
	"G1651" : {
		"kjv" : "convict, convince, tell a fault, rebuke, reprove",
		"lemma" : "ἐλέγχω",
		"translit" : "elénchō",
		"derivation" : "of uncertain affinity",
		"def" : "to confute, admonish"
	},
	"G1390" : {
		"derivation" : "from the base of G1325 (δίδωμι)",
		"def" : "a present",
		"kjv" : "gift",
		"translit" : "dóma",
		"lemma" : "δόμα"
	},
	"G2591" : {
		"translit" : "Kárpos",
		"lemma" : "Κάρπος",
		"kjv" : "Carpus",
		"def" : "Carpus, probably a Christian",
		"derivation" : "perhaps for G2590 (καρπός)"
	},
	"G2044" : {
		"kjv" : "utter",
		"translit" : "ereúgomai",
		"lemma" : "ἐρεύγομαι",
		"derivation" : "of uncertain affinity",
		"def" : "to belch, i.e. (figuratively) to speak out"
	},
	"G3517" : {
		"translit" : "Nēreús",
		"lemma" : "Νηρεύς",
		"kjv" : "Nereus",
		"def" : "Nereus, a Christian",
		"derivation" : "apparently from a derivative of the base of G3491 (ναῦς) (meaning wet)"
	},
	"G283" : {
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G3392 (μιαίνω)",
		"def" : "unsoiled, i.e. (figuratively) pure",
		"kjv" : "undefiled",
		"translit" : "amíantos",
		"lemma" : "ἀμίαντος"
	},
	"G3589" : {
		"def" : "ten times eight",
		"derivation" : "from G3590 (ὄγδοος)",
		"translit" : "ogdoḗkonta",
		"lemma" : "ὀγδοήκοντα",
		"kjv" : "fourscore"
	},
	"G3403" : {
		"translit" : "mimnḗskō",
		"lemma" : "μιμνήσκω",
		"kjv" : "be mindful, remember",
		"def" : "to remind, i.e. (middle voice) to recall to mind",
		"derivation" : "a prolonged form of G3415 (μνάομαι) (from which some of the tenses are borrowed)"
	},
	"G1669" : {
		"translit" : "helkóō",
		"lemma" : "ἑλκόω",
		"kjv" : "full of sores",
		"def" : "to cause to ulcerate, i.e. (passively) be ulcerous",
		"derivation" : "from G1668 (ἕλκος)"
	},
	"G1117" : {
		"lemma" : "γόμος",
		"translit" : "gómos",
		"kjv" : "burden, merchandise",
		"def" : "a load (as filling), i.e. (specially) a cargo, or (by extension) wares",
		"derivation" : "from G1073 (γέμω)"
	},
	"G1857" : {
		"def" : "exterior",
		"derivation" : "comparative of G1854 (ἔξω)",
		"translit" : "exṓteros",
		"lemma" : "ἐξώτερος",
		"kjv" : "outer"
	},
	"G135" : {
		"def" : "an obscure saying (\"enigma\"), i.e. (abstractly) obscureness",
		"derivation" : "from a derivative of G136 (αἶνος) (in its primary sense)",
		"lemma" : "αἴνιγμα",
		"translit" : "aínigma",
		"kjv" : "X darkly"
	},
	"G2058" : {
		"derivation" : "from the same as G2059 (ἑρμηνεύω)",
		"def" : "translation",
		"kjv" : "interpretation",
		"translit" : "hermēneía",
		"lemma" : "ἑρμηνεία"
	},
	"G1071" : {
		"def" : "laughter (as a mark of gratification)",
		"derivation" : "from G1070 (γελάω)",
		"lemma" : "γέλως",
		"translit" : "gélōs",
		"kjv" : "laughter"
	},
	"G1601" : {
		"derivation" : "from G1537 (ἐκ) and G4098 (πίπτω)",
		"def" : "to drop away; specially, be driven out of one's course; figuratively, to lose, become inefficient",
		"kjv" : "be cast, fail, fall (away, off), take none effect",
		"translit" : "ekpíptō",
		"lemma" : "ἐκπίπτω"
	},
	"G544" : {
		"kjv" : "not believe, disobedient, obey not, unbelieving",
		"translit" : "apeithéō",
		"lemma" : "ἀπειθέω",
		"derivation" : "from G545 (ἀπειθής)",
		"def" : "to disbelieve (wilfully and perversely)"
	},
	"G564" : {
		"kjv" : "uncircumcised",
		"translit" : "aperítmētos",
		"lemma" : "ἀπερίτμητος",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G4059 (περιτέμνω)",
		"def" : "uncircumcised (figuratively)"
	},
	"G4355" : {
		"kjv" : "receive, take (unto)",
		"lemma" : "προσλαμβάνω",
		"translit" : "proslambánō",
		"derivation" : "from G4314 (πρός) and G2983 (λαμβάνω)",
		"def" : "to take to oneself, i.e. use (food), lead (aside), admit (to friendship or hospitality)"
	},
	"G54" : {
		"lemma" : "ἁγνότης",
		"translit" : "hagnótēs",
		"kjv" : "pureness",
		"def" : "cleanness (the state), i.e. (figuratively) blamelessness",
		"derivation" : "from G53 (ἁγνός)"
	},
	"G4790" : {
		"derivation" : "from G4862 (σύν) and G2841 (κοινωνέω)",
		"def" : "to share in company with, i.e. co-participate in",
		"kjv" : "communicate (have fellowship) with, be partaker of",
		"lemma" : "συγκοινωνέω",
		"translit" : "synkoinōnéō"
	},
	"G4488" : {
		"derivation" : "probably of Hebrew origin (apparently for H7509)",
		"def" : "Resa (i.e. Rephajah), an Israelite",
		"kjv" : "Rhesa",
		"lemma" : "Ῥησά",
		"translit" : "Rhēsá"
	},
	"G441" : {
		"def" : "man-courting, i.e. fawning",
		"derivation" : "from G444 (ἄνθρωπος) and G700 (ἀρέσκω)",
		"translit" : "anthrōpáreskos",
		"lemma" : "ἀνθρωπάρεσκος",
		"kjv" : "men-pleaser"
	},
	"G354" : {
		"def" : "ascension",
		"derivation" : "from G353 (ἀναλαμβάνω)",
		"translit" : "análēpsis",
		"lemma" : "ἀνάληψις",
		"kjv" : "taking up"
	},
	"G4047" : {
		"def" : "acquisition (the act or the thing); by extension, preservation",
		"derivation" : "from G4046 (περιποιέομαι)",
		"lemma" : "περιποίησις",
		"translit" : "peripoíēsis",
		"kjv" : "obtain(-ing), peculiar, purchased, possession, saving"
	},
	"G3990" : {
		"kjv" : "behead",
		"translit" : "pelekízō",
		"lemma" : "πελεκίζω",
		"derivation" : "from a derivative of G4141 (πλήσσω) (meaning an axe)",
		"def" : "to chop off (the head), i.e. truncate"
	},
	"G4412" : {
		"def" : "firstly (in time, place, order, or importance)",
		"derivation" : "neuter of G4413 (πρῶτος) as adverb (with or without G3588 (ὁ))",
		"lemma" : "πρῶτον",
		"translit" : "prōton",
		"kjv" : "before, at the beginning, chiefly (at, at the) first (of all)"
	},
	"G2998" : {
		"def" : "to quarry",
		"derivation" : "from the same as the first part of G2991 (λαξευτός) and the base of G5114 (τομώτερος)",
		"lemma" : "λατομέω",
		"translit" : "latoméō",
		"kjv" : "hew"
	},
	"G574" : {
		"kjv" : "--liberally",
		"lemma" : "ἁπλῶς",
		"translit" : "haplōs",
		"derivation" : "adverb from G573 (ἁπλοῦς) (in the objective sense of G572 (ἁπλότης))",
		"def" : "bountifully; "
	},
	"G1870" : {
		"derivation" : "from G1909 (ἐπί) and G153 (αἰσχύνομαι)",
		"def" : "to feel shame for something",
		"kjv" : "be ashamed",
		"lemma" : "ἐπαισχύνομαι",
		"translit" : "epaischýnomai"
	},
	"G3566" : {
		"lemma" : "νυμφίος",
		"translit" : "nymphíos",
		"kjv" : "bridegroom",
		"def" : "a bride-groom (literally or figuratively)",
		"derivation" : "from G3565 (νύμφη)"
	},
	"G2656" : {
		"def" : "to nod down (towards), i.e. (by analogy) to make signs to",
		"derivation" : "from G2596 (κατά) and G3506 (νεύω)",
		"translit" : "kataneúō",
		"lemma" : "κατανεύω",
		"kjv" : "beckon"
	},
	"G2022" : {
		"kjv" : "--to pour upon:--pour in",
		"translit" : "epichéō",
		"lemma" : "ἐπιχέω",
		"derivation" : "from G1909 (ἐπί) and (to pour)",
		"def" : ""
	},
	"G5135" : {
		"kjv" : "wound",
		"lemma" : "τραυματίζω",
		"translit" : "traumatízō",
		"derivation" : "from G5134 (τραῦμα)",
		"def" : "to inflict a wound"
	},
	"G4499" : {
		"kjv" : "Rhodes",
		"translit" : "Rhódos",
		"lemma" : "Ῥόδος",
		"derivation" : "probably from (a rose)",
		"def" : "Rhodus, an island of the Mediterranean"
	},
	"G3104" : {
		"def" : "Mainan, an Israelite",
		"derivation" : "probably of Hebrew origin",
		"translit" : "Maïnán",
		"lemma" : "Μαϊνάν",
		"kjv" : "Mainan"
	},
	"G5543" : {
		"def" : "employed, i.e. (by implication) useful (in manner or morals)",
		"derivation" : "from G5530 (χράομαι)",
		"lemma" : "χρηστός",
		"translit" : "chrēstós",
		"kjv" : "better, easy, good(-ness), gracious, kind"
	},
	"G258" : {
		"kjv" : "fox",
		"lemma" : "ἀλώπηξ",
		"translit" : "alṓpēx",
		"derivation" : "of uncertain derivation",
		"def" : "a fox, i.e. (figuratively) a cunning person"
	},
	"G3442" : {
		"kjv" : "with one eye",
		"lemma" : "μονόφθαλμος",
		"translit" : "monóphthalmos",
		"derivation" : "from G3441 (μόνος) and G3788 (ὀφθαλμός)",
		"def" : "one-eyed"
	},
	"G1868" : {
		"def" : "laudation; concretely, a commendable thing",
		"derivation" : "from G1909 (ἐπί) and the base of G134 (αἰνέω)",
		"lemma" : "ἔπαινος",
		"translit" : "épainos",
		"kjv" : "praise"
	},
	"G1356" : {
		"def" : "sky-fallen (i.e. an aerolite)",
		"derivation" : "from the alternate of G2203 (Ζεύς) and the alternate of G4098 (πίπτω)",
		"lemma" : "διοπετής",
		"translit" : "diopetḗs",
		"kjv" : "which fell down from Jupiter"
	},
	"G1693" : {
		"lemma" : "ἐμμαίνομαι",
		"translit" : "emmaínomai",
		"kjv" : "be mad against",
		"def" : "to rave on, i.e. rage at",
		"derivation" : "from G1722 (ἐν) and G3105 (μαίνομαι)"
	},
	"G3503" : {
		"translit" : "neótēs",
		"lemma" : "νεότης",
		"kjv" : "youth",
		"def" : "newness, i.e. youthfulness",
		"derivation" : "from G3501 (νέος)"
	},
	"G4867" : {
		"derivation" : "from G4862 (σύν) and (to hoard)",
		"def" : "to convene",
		"kjv" : "call (gather) together",
		"lemma" : "συναθροίζω",
		"translit" : "synathroízō"
	},
	"G1795" : {
		"def" : "to enstamp, i.e. engrave",
		"derivation" : "from G1722 (ἐν) and a derivative of G5179 (τύπος)",
		"lemma" : "ἐντυπόω",
		"translit" : "entypóō",
		"kjv" : "engrave"
	},
	"G2568" : {
		"translit" : "Kaloì Liménes",
		"lemma" : "Καλοὶ Λιμένες",
		"kjv" : "fair havens",
		"def" : "Good Harbors, i.e. Fairhaven, a bay of Crete",
		"derivation" : "plural of G2570 (καλός) and G3040 (λιμήν)"
	},
	"G1705" : {
		"def" : "to fill in (up), i.e. (by implication) to satisfy (literally or figuratively)",
		"derivation" : "from G1722 (ἐν) and the base of G4118 (πλεῖστος)",
		"translit" : "empíplēmi",
		"lemma" : "ἐμπίπλημι",
		"kjv" : "fill"
	},
	"G384" : {
		"translit" : "anaskeuázō",
		"lemma" : "ἀνασκευάζω",
		"kjv" : "subvert",
		"def" : "properly, to pack up (baggage), i.e. (by implication, and figuratively) to upset",
		"derivation" : "from G303 (ἀνά) (in the sense of reversal) and a derivative of G4632 (σκεῦος)"
	},
	"G3854" : {
		"def" : "to become near, i.e. approach (have arrived); by implication, to appear publicly",
		"derivation" : "from G3844 (παρά) and G1096 (γίνομαι)",
		"translit" : "paragínomai",
		"lemma" : "παραγίνομαι",
		"kjv" : "come, go, be present"
	},
	"G4034" : {
		"derivation" : "from G4012 (περί) and G2989 (λάμπω)",
		"def" : "to illuminate all around, i.e. invest with a halo",
		"kjv" : "shine round about",
		"lemma" : "περιλάμπω",
		"translit" : "perilámpō"
	},
	"G2878" : {
		"lemma" : "κορβᾶν",
		"translit" : "korbân",
		"kjv" : "Corban, treasury",
		"def" : "a votive offering and the offering; a consecrated present (to the Temple fund); by extension (the latter term) the Treasury itself, i.e. the room where the contribution boxes stood",
		"derivation" : "of Hebrew and Chaldee origin respectively (H7133)"
	},
	"G192" : {
		"kjv" : "excess, incontinency",
		"translit" : "akrasía",
		"lemma" : "ἀκρασία",
		"derivation" : "from G193 (ἀκρατής)",
		"def" : "want of self-restraint"
	},
	"G139" : {
		"def" : "properly, a choice, i.e. (specially) a party or (abstractly) disunion",
		"derivation" : "from G138 (αἱρέομαι)",
		"translit" : "haíresis",
		"lemma" : "αἵρεσις",
		"kjv" : "heresy (which is the Greek word itself), sect"
	},
	"G1511" : {
		"translit" : "eînai",
		"lemma" : "εἶναι",
		"kjv" : "am, was",
		"def" : "to exist",
		"derivation" : "present infinitive from G1510 (εἰμί)"
	},
	"G2627" : {
		"lemma" : "κατακλυσμός",
		"translit" : "kataklysmós",
		"kjv" : "flood",
		"def" : "an inundation",
		"derivation" : "from G2626 (κατακλύζω)"
	},
	"G228" : {
		"def" : "truthful",
		"derivation" : "from G227 (ἀληθής)",
		"lemma" : "ἀληθινός",
		"translit" : "alēthinós",
		"kjv" : "true"
	},
	"G3818" : {
		"kjv" : "Pacatiana",
		"translit" : "Pakatianḗ",
		"lemma" : "Πακατιανή",
		"derivation" : "feminine of an adjective of uncertain derivation",
		"def" : "Pacatianian, a section of Phrygia"
	},
	"G3898" : {
		"derivation" : "adverb from the same as G3897 (παραπλήσιον)",
		"def" : "in a manner near by, i.e. (figuratively) similarly",
		"kjv" : "likewise",
		"translit" : "paraplēsíōs",
		"lemma" : "παραπλησίως"
	},
	"G4945" : {
		"lemma" : "συνωμοσία",
		"translit" : "synōmosía",
		"kjv" : "comspiracy",
		"def" : "a swearing together, i.e. (by implication) a plot",
		"derivation" : "from a compound of G4862 (σύν) and G3660 (ὀμνύω)"
	},
	"G4252" : {
		"derivation" : "diminutive of G4251 (Πρίσκα)",
		"def" : "Priscilla (i.e. little Prisca), a Christian woman",
		"kjv" : "Priscilla",
		"translit" : "Prískilla",
		"lemma" : "Πρίσκιλλα"
	},
	"G973" : {
		"def" : "a forcer, i.e. (figuratively) energetic",
		"derivation" : "from G971 (βιάζω)",
		"lemma" : "βιαστής",
		"translit" : "biastḗs",
		"kjv" : "violent"
	},
	"G2425" : {
		"derivation" : "from ( or , akin to G2240 (ἥκω)) (to arrive)",
		"def" : "competent (as if coming in season), i.e. ample (in amount) or fit (in character)",
		"kjv" : "able, + content, enough, good, great, large, long (while), many, meet, much, security, sore, sufficient, worthy",
		"translit" : "hikanós",
		"lemma" : "ἱκανός"
	},
	"G884" : {
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G5483 (χαρίζομαι)",
		"def" : "thankless, i.e. ungrateful",
		"kjv" : "unthankful",
		"translit" : "acháristos",
		"lemma" : "ἀχάριστος"
	},
	"G3063" : {
		"lemma" : "λοιπόν",
		"translit" : "loipón",
		"kjv" : "besides, finally, furthermore, (from) henceforth, moreover, now, + it remaineth, then",
		"def" : "something remaining (adverbially)",
		"derivation" : "neuter singular of the same as G3062 (λοιποί)"
	},
	"G3540" : {
		"lemma" : "νόημα",
		"translit" : "nóēma",
		"kjv" : "device, mind, thought",
		"def" : "a perception, i.e. purpose, or (by implication) the intellect, disposition, itself",
		"derivation" : "from G3539 (νοιέω)"
	},
	"G5599" : {
		"derivation" : "a primary interjection",
		"def" : "as a sign of the vocative case, O; as a note of exclamation, oh",
		"kjv" : "O",
		"translit" : "ō",
		"lemma" : "ὦ"
	},
	"G3099" : {
		"translit" : "Madián",
		"lemma" : "Μαδιάν",
		"kjv" : "Madian",
		"def" : "Madian (i.e. Midian), a region of Arabia",
		"derivation" : "of Hebrew origin (H4080)"
	},
	"G5250" : {
		"kjv" : "be exceeding abundant",
		"lemma" : "ὑπερπλεονάζω",
		"translit" : "hyperpleonázō",
		"derivation" : "from G5228 (ὑπέρ) and G4121 (πλεονάζω)",
		"def" : "to superabound"
	},
	"G3967" : {
		"derivation" : "from G3962 (πατήρ)",
		"def" : "paternal, i.e. ancestral",
		"kjv" : "of fathers",
		"translit" : "patrikós",
		"lemma" : "πατρικός"
	},
	"G2263" : {
		"lemma" : "ἤρεμος",
		"translit" : "ḗremos",
		"kjv" : "quiet",
		"def" : "tranquil",
		"derivation" : "perhaps by transposition from G2048 (ἔρημος) (through the idea of stillness)"
	},
	"G4667" : {
		"derivation" : "the same as G4666 (σμύρνα)",
		"def" : "Smyrna, a place in Asia Minor",
		"kjv" : "Smyrna",
		"lemma" : "Σμύρνα",
		"translit" : "Smýrna"
	},
	"G4390" : {
		"def" : "to run forward, i.e. outstrip, precede",
		"derivation" : "from G4253 (πρό) and G5143 (τρέχω) (including its alternate)",
		"translit" : "protréchō",
		"lemma" : "προτρέχω",
		"kjv" : "outrun, run before"
	},
	"G4954" : {
		"derivation" : "from G4862 (σύν) and G4983 (σῶμα)",
		"def" : "of a joint body, i.e. (figuratively) a fellow-member of the Christian community",
		"kjv" : "of the same body",
		"lemma" : "σύσσωμος",
		"translit" : "sýssōmos"
	},
	"G2037" : {
		"derivation" : "from (to love)",
		"def" : "beloved; Erastus, a Christian",
		"kjv" : "Erastus",
		"lemma" : "Ἔραστος",
		"translit" : "Érastos"
	},
	"G953" : {
		"kjv" : "profane",
		"translit" : "bebēlóō",
		"lemma" : "βεβηλόω",
		"derivation" : "from G952 (βέβηλος)",
		"def" : "to desecrate"
	},
	"G3019" : {
		"def" : "a Levite, i.e. descendant of Levi",
		"derivation" : "from G3017 (Λευΐ)",
		"translit" : "Leuḯtēs",
		"lemma" : "Λευΐτης",
		"kjv" : "Levite"
	},
	"G4524" : {
		"kjv" : "Sadoc",
		"lemma" : "Σαδώκ",
		"translit" : "Sadṓk",
		"derivation" : "of Hebrew origin (H6659)",
		"def" : "Sadoc (i.e. Tsadok), an Israelite"
	},
	"G1068" : {
		"derivation" : "of Chaldee origin (compare H1660 and H8081)",
		"def" : "oil-press; Gethsemane, a garden near Jerusalem",
		"kjv" : "Gethsemane",
		"lemma" : "Γεθσημανῆ",
		"translit" : "Gethsēmanē"
	},
	"G627" : {
		"def" : "a plea (\"apology\")",
		"derivation" : "from the same as G626 (ἀπολογέομαι)",
		"lemma" : "ἀπολογία",
		"translit" : "apología",
		"kjv" : "answer (for self), clearing of self, defence"
	},
	"G5372" : {
		"lemma" : "Φίλητος",
		"translit" : "Phílētos",
		"kjv" : "Philetus",
		"def" : "amiable; Philetus, an opposer of Christianity",
		"derivation" : "from G5368 (φιλέω)"
	},
	"G3825" : {
		"kjv" : "again",
		"lemma" : "πάλιν",
		"translit" : "pálin",
		"derivation" : "probably from the same as G3823 (πάλη) (through the idea of oscillatory repetition)",
		"def" : "(adverbially) anew, i.e. (of place) back, (of time) once more, or (conjunctionally) furthermore or on the other hand"
	},
	"G3436" : {
		"lemma" : "μολυσμός",
		"translit" : "molysmós",
		"kjv" : "filthiness",
		"def" : "a stain; i.e. (figuratively) immorality",
		"derivation" : "from G3435 (μολύνω)"
	},
	"G3939" : {
		"lemma" : "παροικέω",
		"translit" : "paroikéō",
		"kjv" : "sojourn in, be a stranger",
		"def" : "to dwell near, i.e. reside as a foreigner",
		"derivation" : "from G3844 (παρά) and G3611 (οἰκέω)"
	},
	"G848" : {
		"translit" : "hautoû",
		"lemma" : "αὑτοῦ",
		"kjv" : "her (own), (of) him(-self), his (own), of it, thee, their (own), them(-selves), they",
		"def" : "self (in some oblique case or reflexively, relation)",
		"derivation" : "contracted for G1438 (ἑαυτοῦ)"
	},
	"G5524" : {
		"def" : "to be a dance-leader, i.e. (generally) to furnish",
		"derivation" : "from a compound of G5525 (χορός) and G71 (ἄγω)",
		"lemma" : "χορηγέω",
		"translit" : "chorēgéō",
		"kjv" : "give, minister"
	},
	"G3528" : {
		"kjv" : "conquer, overcome, prevail, get the victory",
		"lemma" : "νικάω",
		"translit" : "nikáō",
		"derivation" : "from G3529 (νίκη)",
		"def" : "to subdue (literally or figuratively)"
	},
	"G1719" : {
		"derivation" : "from G1722 (ἐν) and G5401 (φόβος)",
		"def" : "in fear, i.e. alarmed",
		"kjv" : "affrighted, afraid, tremble",
		"translit" : "émphobos",
		"lemma" : "ἔμφοβος"
	},
	"G5239" : {
		"def" : "to extend inordinately",
		"derivation" : "from G5228 (ὑπέρ) and G1614 (ἐκτείνω)",
		"lemma" : "ὑπερεκτείνω",
		"translit" : "hyperekteínō",
		"kjv" : "stretch beyond"
	},
	"G3610" : {
		"derivation" : "from G3611 (οἰκέω)",
		"def" : "a fellow resident, i.e. menial domestic",
		"kjv" : "(household) servant",
		"lemma" : "οἰκέτης",
		"translit" : "oikétēs"
	},
	"G3148" : {
		"kjv" : "plague, scourging",
		"translit" : "mástix",
		"lemma" : "μάστιξ",
		"derivation" : "probably from the base of G3145 (μασσάομαι) (through the idea of contact)",
		"def" : "a whip (literally, the Roman flagellum for criminals; figuratively, a disease)"
	},
	"G3380" : {
		"def" : "not yet",
		"derivation" : "from G3361 (μή) and G4452 (-πω)",
		"translit" : "mḗpō",
		"lemma" : "μήπω",
		"kjv" : "not yet"
	},
	"G3433" : {
		"kjv" : "hardly, scarce(-ly), + with much work",
		"lemma" : "μόλις",
		"translit" : "mólis",
		"derivation" : "probably by variation for G3425 (μόγις)",
		"def" : "with difficulty"
	},
	"G2676" : {
		"translit" : "katártisis",
		"lemma" : "κατάρτισις",
		"kjv" : "perfection",
		"def" : "thorough equipment (subjectively)",
		"derivation" : "from G2675 (καταρτίζω)"
	},
	"G4818" : {
		"derivation" : "from G4862 (σύν) and G3076 (λυπέω)",
		"def" : "to afflict jointly, i.e. (passive) sorrow at (on account of) someone",
		"kjv" : "be grieved",
		"lemma" : "συλλυπέω",
		"translit" : "syllypéō"
	},
	"G2276" : {
		"kjv" : "less, worse",
		"lemma" : "ἥττον",
		"translit" : "hḗtton",
		"derivation" : "neuter of comparative of (slightly) used for that of G2556 (κακός)",
		"def" : "worse (as noun); by implication, less (as adverb)"
	},
	"G4947" : {
		"def" : "Syria (i.e. Tsyria or Tyre), a region of Asia",
		"derivation" : "probably of Hebrew origin (H6865)",
		"translit" : "Syría",
		"lemma" : "Συρία",
		"kjv" : "Syria"
	},
	"G5280" : {
		"def" : "a reminding or (reflexively) recollection",
		"derivation" : "from G5279 (ὑπομιμνήσκω)",
		"translit" : "hypómnēsis",
		"lemma" : "ὑπόμνησις",
		"kjv" : "remembrance"
	},
	"G2738" : {
		"translit" : "kaûma",
		"lemma" : "καῦμα",
		"kjv" : "heat",
		"def" : "properly, a burn (concretely), but used (abstractly) of a glow",
		"derivation" : "from G2545 (καίω)"
	},
	"G4190" : {
		"translit" : "ponērós",
		"lemma" : "πονηρός",
		"kjv" : "bad, evil, grievous, harm, lewd, malicious, wicked(-ness)",
		"def" : "hurtful, i.e. evil (properly, in effect or influence, and thus differing from G2556 (κακός), which refers rather to essential character, as well as from G4550 (σαπρός), which indicates degeneracy from original virtue); figuratively, calamitous; also (passively) ill, i.e. diseased; but especially (morally) culpable, i.e. derelict, vicious, facinorous; neuter (singular) mischief, malice, or (plural) guilt; masculine (singular) the devil, or (plural) sinners",
		"derivation" : "from a derivative of G4192 (πόνος)"
	},
	"G5368" : {
		"def" : "to be a friend to (fond of (an individual or an object)), i.e. have affection for (denoting personal attachment, as a matter of sentiment or feeling; while G25 (ἀγαπάω) is wider, embracing especially the judgment and the deliberate assent of the will as a matter of principle, duty and propriety: the two thus stand related very much as G2309 (θέλω) and G1014 (βούλομαι), or as G2372 (θυμός) and G3563 (νοῦς) respectively; the former being chiefly of the heart and the latter of the head); specially, to kiss (as a mark of tenderness)",
		"derivation" : "from G5384 (φίλος)",
		"lemma" : "φιλέω",
		"translit" : "philéō",
		"kjv" : "kiss, love"
	},
	"G1033" : {
		"derivation" : "from the base of G977 (βιβρώσκω)",
		"def" : "food (literally or figuratively), especially (ceremonially) articles allowed or forbidden by the Jewish law",
		"kjv" : "meat, victuals",
		"lemma" : "βρῶμα",
		"translit" : "brōma"
	},
	"G243" : {
		"def" : "\"else,\" i.e. different (in many applications)",
		"derivation" : "a primary word",
		"lemma" : "ἄλλος",
		"translit" : "állos",
		"kjv" : "more, one (another), (an-, some an-)other(-s, -wise)"
	},
	"G1319" : {
		"def" : "instruction (the function or the information)",
		"derivation" : "from G1320 (διδάσκαλος)",
		"translit" : "didaskalía",
		"lemma" : "διδασκαλία",
		"kjv" : "doctrine, learning, teaching"
	},
	"G4136" : {
		"def" : "entire confidence",
		"derivation" : "from G4135 (πληροφορέω)",
		"lemma" : "πληροφορία",
		"translit" : "plērophoría",
		"kjv" : "(full) assurance"
	},
	"G1277" : {
		"kjv" : "sail over",
		"lemma" : "διαπλέω",
		"translit" : "diapléō",
		"derivation" : "from G1223 (διά) and G4126 (πλέω)",
		"def" : "to sail through"
	},
	"G4319" : {
		"translit" : "prosaitéō",
		"lemma" : "προσαιτέω",
		"kjv" : "beg",
		"def" : "to ask repeatedly (importune), i.e. solicit",
		"derivation" : "from G4314 (πρός) and G154 (αἰτέω)"
	},
	"G2727" : {
		"def" : "to sound down into the ears, i.e. (by implication) to indoctrinate (\"catechize\") or (genitive case) to apprise of",
		"derivation" : "from G2596 (κατά) and G2279 (ἦχος)",
		"translit" : "katēchéō",
		"lemma" : "κατηχέω",
		"kjv" : "inform, instruct, teach"
	},
	"G4247" : {
		"def" : "an old woman",
		"derivation" : "feminine of G4246 (πρεσβύτης)",
		"lemma" : "πρεσβῦτις",
		"translit" : "presbŷtis",
		"kjv" : "aged woman"
	},
	"G3393" : {
		"derivation" : "from G3392 (μιαίνω) (\"miasma\")",
		"def" : "(morally) foulness (properly, the effect)",
		"kjv" : "pollution",
		"translit" : "míasma",
		"lemma" : "μίασμα"
	},
	"G1192" : {
		"derivation" : "from G1194 (δέρω)",
		"def" : "a hide",
		"kjv" : "skin",
		"lemma" : "δέρμα",
		"translit" : "dérma"
	},
	"G3361" : {
		"def" : "(adverb) not, (conjunction) lest; also (as an interrogative implying a negative answer (whereas G3756 (οὐ) expects an affirmative one)) whether",
		"derivation" : "a primary particle of qualified negation (whereas G3756 (οὐ) expresses an absolute denial)",
		"translit" : "mḗ",
		"lemma" : "μή",
		"kjv" : "any but (that), X forbear, + God forbid, + lack, lest, neither, never, no (X wise in), none, nor, (can-)not, nothing, that not, un(-taken), without"
	},
	"G281" : {
		"kjv" : "amen, verily",
		"lemma" : "ἀμήν",
		"translit" : "amḗn",
		"derivation" : "of Hebrew origin (H543)",
		"def" : "properly, firm, i.e. (figuratively) trustworthy; adverbially, surely (often as interjection, so be it)"
	},
	"G3504" : {
		"kjv" : "novice",
		"translit" : "neóphytos",
		"lemma" : "νεόφυτος",
		"derivation" : "from G3501 (νέος) and a derivative of G5453 (φύω)",
		"def" : "newly planted, i.e. (figuratively) a young convert (\"neophyte\")"
	},
	"G2151" : {
		"kjv" : "show piety, worship",
		"translit" : "eusebéō",
		"lemma" : "εὐσεβέω",
		"derivation" : "from G2152 (εὐσεβής)",
		"def" : "to be pious, i.e. (towards God) to worship, or (towards parents) to respect (support)"
	},
	"G2508" : {
		"kjv" : "purge",
		"lemma" : "καθαίρω",
		"translit" : "kathaírō",
		"derivation" : "from G2513 (καθαρός)",
		"def" : "to cleanse, i.e. (specially) to prune; figuratively, to expiate"
	},
	"G225" : {
		"translit" : "alḗtheia",
		"lemma" : "ἀλήθεια",
		"kjv" : "true, X truly, truth, verity",
		"def" : "truth",
		"derivation" : "from G227 (ἀληθής)"
	},
	"G4933" : {
		"derivation" : "from G4862 (σύν) and G5083 (τηρέω)",
		"def" : "to keep closely together, i.e. (by implication) to conserve (from ruin); mentally, to remember (and obey)",
		"kjv" : "keep, observe, preserve",
		"translit" : "syntēréō",
		"lemma" : "συντηρέω"
	},
	"G3952" : {
		"derivation" : "from the present participle of G3918 (πάρειμι)",
		"def" : "a being near, i.e. advent (often, return; specially, of Christ to punish Jerusalem, or finally the wicked); (by implication) physically, aspect",
		"kjv" : "coming, presence",
		"translit" : "parousía",
		"lemma" : "παρουσία"
	},
	"G618" : {
		"def" : "to receive (specially, in full, or as a host); also to take aside",
		"derivation" : "from G575 (ἀπό) and G2983 (λαμβάνω)",
		"lemma" : "ἀπολαμβάνω",
		"translit" : "apolambánō",
		"kjv" : "receive, take"
	},
	"G638" : {
		"derivation" : "from G575 (ἀπό) and G4155 (πνίγω)",
		"def" : "to stifle (by drowning or overgrowth)",
		"kjv" : "choke",
		"lemma" : "ἀποπνίγω",
		"translit" : "apopnígō"
	},
	"G1492" : {
		"derivation" : "a primary verb",
		"def" : "used only in certain past tenses, the others being borrowed from the equivalent G3700 (ὀπτάνομαι) and G3708 (ὁράω); properly, to see (literally or figuratively); by implication, (in the perfect tense only) to know",
		"kjv" : "be aware, behold, X can (+ not tell), consider, (have) know(-ledge), look (on), perceive, see, be sure, tell, understand, wish, wot",
		"lemma" : "εἴδω",
		"translit" : "eídō"
	},
	"G4067" : {
		"kjv" : "offscouring",
		"lemma" : "περίψωμα",
		"translit" : "perípsōma",
		"derivation" : "from a comparative of G4012 (περί) and (to rub)",
		"def" : "something brushed all around, i.e. off-scrapings (figuratively, scum)"
	},
	"G933" : {
		"lemma" : "βασίλειον",
		"translit" : "basíleion",
		"kjv" : "king's court",
		"def" : "a palace",
		"derivation" : "neuter of G934 (βασίλειος)"
	},
	"G1162" : {
		"def" : "a petition",
		"derivation" : "from G1189 (δέομαι)",
		"translit" : "déēsis",
		"lemma" : "δέησις",
		"kjv" : "prayer, request, supplication"
	},
	"G1989" : {
		"translit" : "epistéllō",
		"lemma" : "ἐπιστέλλω",
		"kjv" : "write (a letter, unto)",
		"def" : "to enjoin (by writing), i.e. (genitive case) to communicate by letter (for any purpose)",
		"derivation" : "from G1909 (ἐπί) and G4724 (στέλλω)"
	},
	"G183" : {
		"def" : "unrestrainable",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G2722 (κατέχω)",
		"translit" : "akatáschetos",
		"lemma" : "ἀκατάσχετος",
		"kjv" : "unruly"
	},
	"G5083" : {
		"def" : "perhaps akin to G2334 (θεωρέω)); to guard (from loss or injury, properly, by keeping the eye upon; and thus differing from G5442 (φυλάσσω), which is properly to prevent escaping; and from G2892 (κουστωδία), which implies a fortress or full military lines of apparatus), i.e. to note (a prophecy; figuratively, to fulfil a command); by implication, to detain (in custody; figuratively, to maintain); by extension, to withhold (for personal ends; figuratively, to keep unmarried); by extension, to withhold (for personal ends; figuratively, to keep unmarried)",
		"derivation" : "from (a watch",
		"lemma" : "τηρέω",
		"translit" : "tēréō",
		"kjv" : "hold fast, keep(- er), (pre-, re-)serve, watch"
	},
	"G982" : {
		"lemma" : "βιωτικός",
		"translit" : "biōtikós",
		"kjv" : "of (pertaining to, things that pertain to) this life",
		"def" : "relating to the present existence",
		"derivation" : "from a derivative of G980 (βιόω)"
	},
	"G3750" : {
		"kjv" : "smelling",
		"translit" : "ósphrēsis",
		"lemma" : "ὄσφρησις",
		"derivation" : "from a derivative of G3605 (ὄζω)",
		"def" : "smell (the sense)"
	},
	"G1517" : {
		"kjv" : "make peace",
		"lemma" : "εἰρηνοποιέω",
		"translit" : "eirēnopoiéō",
		"derivation" : "from G1518 (εἰρηνοποιός)",
		"def" : "to be a peace-maker, i.e. (figuratively) to harmonize"
	},
	"G2364" : {
		"derivation" : "apparently a primary word (compare \"daughter\")",
		"def" : "a female child, or (by Hebraism) descendant (or inhabitant)",
		"kjv" : "daughter",
		"translit" : "thygátēr",
		"lemma" : "θυγάτηρ"
	},
	"G2899" : {
		"derivation" : "of uncertain derivation",
		"def" : "a margin, i.e. (specially), a fringe or tassel",
		"kjv" : "border, hem",
		"lemma" : "κράσπεδον",
		"translit" : "kráspedon"
	},
	"G1406" : {
		"lemma" : "δραχμή",
		"translit" : "drachmḗ",
		"kjv" : "piece (of silver)",
		"def" : "a drachma or (silver) coin (as handled)",
		"derivation" : "from G1405 (δράσσομαι)"
	},
	"G4283" : {
		"lemma" : "προευαγγελίζομαι",
		"translit" : "proeuangelízomai",
		"kjv" : "preach before the gospel",
		"def" : "to announce glad news in advance",
		"derivation" : "middle voice from G4253 (πρό) and G2097 (εὐαγγελίζω)"
	},
	"G948" : {
		"def" : "to be disgusted, i.e. (by implication) detest (especially of idolatry)",
		"derivation" : "from a (presumed) derivative of (to stink)",
		"translit" : "bdelýssō",
		"lemma" : "βδελύσσω",
		"kjv" : "abhor, abominable"
	},
	"G3531" : {
		"derivation" : "from G3532 (Νικόλαος)",
		"def" : "a Nicolaite, i.e. adherent of Nicolaus",
		"kjv" : "Nicolaitane",
		"translit" : "Nikolaḯtēs",
		"lemma" : "Νικολαΐτης"
	},
	"G5362" : {
		"def" : "fond of man, i.e. affectionate as a wife",
		"derivation" : "from G5384 (φίλος) and G435 (ἀνήρ)",
		"lemma" : "φίλανδρος",
		"translit" : "phílandros",
		"kjv" : "love their husbands"
	},
	"G4019" : {
		"def" : "to bind around one, i.e. enwrap",
		"derivation" : "from G4012 (περί) and G1210 (δέω)",
		"lemma" : "περιδέω",
		"translit" : "peridéō",
		"kjv" : "bind about"
	},
	"G2977" : {
		"kjv" : "privily, secretly",
		"translit" : "láthra",
		"lemma" : "λάθρα",
		"derivation" : "adverb from G2990 (λανθάνω)",
		"def" : "privately"
	},
	"G5000" : {
		"derivation" : "of Chaldee origin (compare H6646)",
		"def" : "the gazelle; Tabitha (i.e. Tabjetha), a Christian female",
		"kjv" : "Tabitha",
		"lemma" : "Ταβιθά",
		"translit" : "Tabithá"
	},
	"G4665" : {
		"kjv" : "emerald",
		"lemma" : "σμάραγδος",
		"translit" : "smáragdos",
		"derivation" : "of uncertain derivation",
		"def" : "the emerald or green gem so called"
	},
	"G4423" : {
		"def" : "alarm",
		"derivation" : "from G4422 (πτοέω)",
		"translit" : "ptóēsis",
		"lemma" : "πτόησις",
		"kjv" : "amazement"
	},
	"G3164" : {
		"kjv" : "fight, strive",
		"translit" : "máchomai",
		"lemma" : "μάχομαι",
		"derivation" : "middle voice of an apparently primary verb",
		"def" : "to war, i.e. (figuratively) to quarrel, dispute"
	},
	"G4102" : {
		"def" : "persuasion, i.e. credence; moral conviction (of religious truth, or the truthfulness of God or a religious teacher), especially reliance upon Christ for salvation; abstractly, constancy in such profession; by extension, the system of religious (Gospel) truth itself",
		"derivation" : "from G3982 (πείθω)",
		"translit" : "pístis",
		"lemma" : "πίστις",
		"kjv" : "assurance, belief, believe, faith, fidelity"
	},
	"G3979" : {
		"kjv" : "a- (on) foot",
		"translit" : "pezēi",
		"lemma" : "πεζῇ",
		"derivation" : "dative case feminine of a derivative of G4228 (πούς) (as adverb)",
		"def" : "foot-wise, i.e. by walking"
	},
	"G3346" : {
		"kjv" : "carry over, change, remove, translate, turn",
		"lemma" : "μετατίθημι",
		"translit" : "metatíthēmi",
		"derivation" : "from G3326 (μετά) and G5087 (τίθημι)",
		"def" : "to transfer, i.e. (literally) transport, (by implication) exchange, (reflexively) change sides, or (figuratively) pervert"
	},
	"G4149" : {
		"def" : "wealth (as fulness), i.e. (literally) money, possessions, or (figuratively) abundance, richness, (specially), valuable bestowment",
		"derivation" : "from the base of G4130 (πλήθω)",
		"translit" : "ploûtos",
		"lemma" : "πλοῦτος",
		"kjv" : "riches"
	},
	"G3002" : {
		"kjv" : "Lebbæus",
		"translit" : "Lebbaîos",
		"lemma" : "Λεββαῖος",
		"derivation" : "of uncertain origin",
		"def" : "Lebbæus, a Christian"
	},
	"G4303" : {
		"def" : "to be a witness in advance i.e. predict",
		"derivation" : "from G4253 (πρό) and G3143 (μαρτύρομαι)",
		"lemma" : "προμαρτύρομαι",
		"translit" : "promartýromai",
		"kjv" : "testify beforehand"
	},
	"G5495" : {
		"def" : "the hand (literally or figuratively (power); especially (by Hebraism) a means or instrument)",
		"derivation" : "perhaps from the base of G5494 (χειμών) in the sense of its congener the base of G5490 (χάσμα) (through the idea of hollowness for grasping)",
		"lemma" : "χείρ",
		"translit" : "cheír",
		"kjv" : "hand"
	},
	"G1889" : {
		"def" : "Epaphras, a Christian",
		"derivation" : "contracted from G1891 (Ἐπαφρόδιτος)",
		"translit" : "Epaphrâs",
		"lemma" : "Ἐπαφρᾶς",
		"kjv" : "Epaphras"
	},
	"G226" : {
		"kjv" : "speak (tell) the truth",
		"translit" : "alētheúō",
		"lemma" : "ἀληθεύω",
		"derivation" : "from G227 (ἀληθής)",
		"def" : "to be true (in doctrine and profession)"
	},
	"G3560" : {
		"lemma" : "νουθετέω",
		"translit" : "nouthetéō",
		"kjv" : "admonish, warn",
		"def" : "to put in mind, i.e. (by implication) to caution or reprove gently",
		"derivation" : "from the same as G3559 (νουθεσία)"
	},
	"G4071" : {
		"derivation" : "neuter of a derivative of G4072 (πέτομαι)",
		"def" : "a flying animal, i.e. bird",
		"kjv" : "bird, fowl",
		"lemma" : "πετεινόν",
		"translit" : "peteinón"
	},
	"G435" : {
		"lemma" : "ἀνήρ",
		"translit" : "anḗr",
		"kjv" : "fellow, husband, man, sir",
		"def" : "a man (properly as an individual male)",
		"derivation" : "a primary word (compare G444 (ἄνθρωπος))"
	},
	"G500" : {
		"def" : "an opponent of the Messiah",
		"derivation" : "from G473 (ἀντί) and G5547 (Χριστός)",
		"translit" : "antíchristos",
		"lemma" : "ἀντίχριστος",
		"kjv" : "antichrist"
	},
	"G3781" : {
		"def" : "an ower, i.e. person indebted; figuratively, a delinquent; morally, a transgressor (against God)",
		"derivation" : "from G3784 (ὀφείλω)",
		"translit" : "opheilétēs",
		"lemma" : "ὀφειλέτης",
		"kjv" : "debtor, which owed, sinner"
	},
	"G2120" : {
		"def" : "a favorable occasion",
		"derivation" : "from G2121 (εὔκαιρος)",
		"translit" : "eukairía",
		"lemma" : "εὐκαιρία",
		"kjv" : "opportunity"
	},
	"G581" : {
		"translit" : "apogenómenos",
		"lemma" : "ἀπογενόμενος",
		"kjv" : "being dead",
		"def" : "absent, i.e. deceased (figuratively, renounced)",
		"derivation" : "past participle of a compound of G575 (ἀπό) and G1096 (γίνομαι)"
	},
	"G4260" : {
		"derivation" : "from G4253 (πρό) and the base of G939 (βάσις)",
		"def" : "to walk forward, i.e. advance (literally, or in years)",
		"kjv" : "+ be of a great age, go farther (on), be well stricken",
		"lemma" : "προβαίνω",
		"translit" : "probaínō"
	},
	"G1683" : {
		"lemma" : "ἐμαυτοῦ",
		"translit" : "emautoû",
		"kjv" : "me, mine own (self), myself",
		"def" : "of myself so likewise the dative case , and accusative case ",
		"derivation" : "genitive case compound of G1700 (ἐμοῦ) and G846 (αὐτός)"
	},
	"G5348" : {
		"def" : "to be beforehand, i.e. anticipate or precede; by extension, to have arrived at",
		"derivation" : "apparently a primary verb",
		"lemma" : "φθάνω",
		"translit" : "phthánō",
		"kjv" : "(already) attain, come, prevent"
	},
	"G1251" : {
		"derivation" : "middle voice from G1223 (διά) and G191 (ἀκούω)",
		"def" : "to hear throughout, i.e. patiently listen (to a prisoner's plea)",
		"kjv" : "hear",
		"lemma" : "διακούομαι",
		"translit" : "diakoúomai"
	},
	"G4454" : {
		"kjv" : "colt",
		"translit" : "pōlos",
		"lemma" : "πῶλος",
		"derivation" : "apparently a primary word",
		"def" : "a \"foal\" or \"filly\", i.e. (specially), a young ass"
	},
	"G3995" : {
		"def" : "a wife's father",
		"derivation" : "of uncertain affinity",
		"lemma" : "πενθερός",
		"translit" : "pentherós",
		"kjv" : "father in law"
	},
	"G4768" : {
		"derivation" : "from the same as G4767 (στυγνητός)",
		"def" : "to render gloomy, i.e. (by implication) glower (be overcast with clouds, or sombreness of speech)",
		"kjv" : "lower, be sad",
		"translit" : "stygnázō",
		"lemma" : "στυγνάζω"
	},
	"G69" : {
		"translit" : "agrypnéō",
		"lemma" : "ἀγρυπνέω",
		"kjv" : "watch",
		"def" : "to be sleepless, i.e. keep awake",
		"derivation" : "ultimately from G1 (Α) (as negative particle) and G5258 (ὕπνος)"
	},
	"G4322" : {
		"kjv" : "supply",
		"lemma" : "προσαναπληρόω",
		"translit" : "prosanaplēróō",
		"derivation" : "from G4314 (πρός) and G378 (ἀναπληρόω)",
		"def" : "to fill up further, i.e. furnish fully"
	},
	"G299" : {
		"translit" : "ámōmos",
		"lemma" : "ἄμωμος",
		"kjv" : "without blame (blemish, fault, spot), faultless, unblamable",
		"def" : "unblemished (literally or figuratively)",
		"derivation" : "from G1 (Α) (as a negative particle) and G3470 (μῶμος)"
	},
	"G3011" : {
		"derivation" : "from a derivative of G2992 (λαός) and G2041 (ἔργον)",
		"def" : "a public servant, i.e. a functionary in the Temple or Gospel, or (genitive case) a worshipper (of God) or benefactor (of man)",
		"kjv" : "minister(-ed)",
		"lemma" : "λειτουργός",
		"translit" : "leitourgós"
	},
	"G5254" : {
		"derivation" : "from G5259 (ὑπό) and G2192 (ἔχω)",
		"def" : "to hold oneself under, i.e. endure with patience",
		"kjv" : "suffer",
		"translit" : "hypéchō",
		"lemma" : "ὑπέχω"
	},
	"G3425" : {
		"derivation" : "adverb from a primary (toil)",
		"def" : "with difficulty",
		"kjv" : "hardly",
		"translit" : "mógis",
		"lemma" : "μόγις"
	},
	"G138" : {
		"kjv" : "choose",
		"translit" : "hairéomai",
		"lemma" : "αἱρέομαι",
		"derivation" : "probably akin to G142 (αἴρω)",
		"def" : "to take for oneself, i.e. to prefer"
	},
	"G377" : {
		"def" : "to fall back, i.e. lie down, lean back",
		"derivation" : "from G303 (ἀνά) and G4098 (πίπτω)",
		"translit" : "anapíptō",
		"lemma" : "ἀναπίπτω",
		"kjv" : "lean, sit down (to meat)"
	},
	"G3157" : {
		"derivation" : "of Hebrew origin (H4977)",
		"def" : "Matthan (i.e. Mattan), an Israelite",
		"kjv" : "Matthan",
		"lemma" : "Ματθάν",
		"translit" : "Matthán"
	},
	"G1829" : {
		"lemma" : "ἐξέραμα",
		"translit" : "exérama",
		"kjv" : "vomit",
		"def" : "vomit, i.e. food disgorged",
		"derivation" : "from a comparative of G1537 (ἐκ) and a presumed (to spue)"
	},
	"G4159" : {
		"def" : "from which (as interrogative) or what (as relative) place, state, source or cause",
		"derivation" : "from the base of G4213 (πόσις) with enclitic adverb of origin",
		"translit" : "póthen",
		"lemma" : "πόθεν",
		"kjv" : "whence"
	},
	"G5594" : {
		"derivation" : "a primary verb",
		"def" : "to breathe (voluntarily but gently, thus differing on the one hand from G4154 (πνέω), which denotes properly a forcible respiration; and on the other from the base of G109 (ἀήρ), which refers properly to an inanimate breeze), i.e. (by implication, of reduction of temperature by evaporation) to chill (figuratively)",
		"kjv" : "wax cold",
		"lemma" : "ψύχω",
		"translit" : "psýchō"
	},
	"G3034" : {
		"kjv" : "stone",
		"lemma" : "λιθάζω",
		"translit" : "litházō",
		"derivation" : "from G3037 (λίθος)",
		"def" : "to lapidate"
	},
	"G3894" : {
		"def" : "irritation",
		"derivation" : "from G3893 (παραπικραίνω)",
		"lemma" : "παραπικρασμός",
		"translit" : "parapikrasmós",
		"kjv" : "provocation"
	},
	"G3622" : {
		"kjv" : "dispensation, stewardship",
		"translit" : "oikonomía",
		"lemma" : "οἰκονομία",
		"derivation" : "from G3623 (οἰκονόμος)",
		"def" : "administration (of a household or estate); specially, a (religious) \"economy\""
	},
	"G3847" : {
		"def" : "violation",
		"derivation" : "from G3845 (παραβαίνω)",
		"translit" : "parábasis",
		"lemma" : "παράβασις",
		"kjv" : "breaking, transgression"
	},
	"G2811" : {
		"translit" : "kléos",
		"lemma" : "κλέος",
		"kjv" : "glory",
		"def" : "renown (as if being called)",
		"derivation" : "from a shorter form of G2564 (καλέω)"
	},
	"G1452" : {
		"lemma" : "ἐγγύτερον",
		"translit" : "engýteron",
		"kjv" : "nearer",
		"def" : "nearer",
		"derivation" : "neuter of the comparative of G1451 (ἐγγύς)"
	},
	"G1140" : {
		"translit" : "daimónion",
		"lemma" : "δαιμόνιον",
		"kjv" : "devil, god",
		"def" : "a dæmonic being; by extension a deity",
		"derivation" : "neuter of a derivative of G1142 (δαίμων)"
	},
	"G3792" : {
		"translit" : "ochlopoiéō",
		"lemma" : "ὀχλοποιέω",
		"kjv" : "gather a company",
		"def" : "to make a crowd, i.e. raise a public disturbance",
		"derivation" : "from G3793 (ὄχλος) and G4160 (ποιέω)"
	},
	"G3650" : {
		"kjv" : "all, altogether, every whit, + throughout, whole",
		"lemma" : "ὅλος",
		"translit" : "hólos",
		"derivation" : "a primary word",
		"def" : "\"whole\" or \"all\", i.e. complete (in extent, amount, time or degree), especially (neuter) as noun or adverb"
	},
	"G3146" : {
		"def" : "to flog (literally or figuratively)",
		"derivation" : "from G3148 (μάστιξ)",
		"lemma" : "μαστιγόω",
		"translit" : "mastigóō",
		"kjv" : "scourge"
	},
	"G245" : {
		"derivation" : "from G243 (ἄλλος)",
		"def" : "another's, i.e. not one's own; by extension foreign, not akin, hostile",
		"kjv" : "alien, (an-)other (man's, men's), strange(-r)",
		"lemma" : "ἀλλότριος",
		"translit" : "allótrios"
	},
	"G2573" : {
		"derivation" : "adverb from G2570 (καλός)",
		"def" : "well (usually morally)",
		"kjv" : "(in a) good (place), honestly, + recover, (full) well",
		"lemma" : "καλῶς",
		"translit" : "kalōs"
	},
	"G3643" : {
		"derivation" : "from a compound of G3641 (ὀλίγος) and (\"care\")",
		"def" : "to have little regard for, i.e. to disesteem",
		"kjv" : "despise",
		"lemma" : "ὀλιγωρέω",
		"translit" : "oligōréō"
	},
	"G4330" : {
		"derivation" : "from G4314 (πρός) and G1439 (ἐάω)",
		"def" : "to permit further progress",
		"kjv" : "suffer",
		"translit" : "proseáō",
		"lemma" : "προσεάω"
	},
	"G1557" : {
		"kjv" : "(a-, re-)venge(-ance), punishment",
		"translit" : "ekdíkēsis",
		"lemma" : "ἐκδίκησις",
		"derivation" : "from G1556 (ἐκδικέω)",
		"def" : "vindication, retribution"
	},
	"G2173" : {
		"derivation" : "from G2095 (εὖ) and G5543 (χρηστός)",
		"def" : "easily used, i.e. useful",
		"kjv" : "profitable, meet for use",
		"lemma" : "εὔχρηστος",
		"translit" : "eúchrēstos"
	},
	"G3386" : {
		"kjv" : "how much more",
		"translit" : "mḗtige",
		"lemma" : "μήτιγε",
		"derivation" : "from G3385 (μήτι) and G1065 (γέ)",
		"def" : "not at all then, i.e. not to say (the rather still)"
	},
	"G4012" : {
		"lemma" : "περί",
		"translit" : "perí",
		"kjv" : "(there-)about, above, against, at, on behalf of, X and his company, which concern, (as) concerning, for, X how it will go with, ((there-, where-)) of, on, over, pertaining (to), for sake, X (e-)state, (as) touching, (where-)by (in), with",
		"def" : "properly, through (all over), i.e. around; figuratively with respect to; used in various applications, of place, cause or time (with the genitive case denoting the subject or occasion or superlative point; with the accusative case the locality, circuit, matter, circumstance or general period)",
		"derivation" : "from the base of G4008 (πέραν)"
	},
	"G1001" : {
		"derivation" : "from G1002 (βολίς)",
		"def" : "to heave the lead",
		"kjv" : "sound",
		"translit" : "bolízō",
		"lemma" : "βολίζω"
	},
	"G2601" : {
		"translit" : "katabibázō",
		"lemma" : "καταβιβάζω",
		"kjv" : "bring (thrust) down",
		"def" : "to cause to go down, i.e. precipitate",
		"derivation" : "from G2596 (κατά) and a derivative of the base of G939 (βάσις)"
	},
	"G2638" : {
		"lemma" : "καταλαμβάνω",
		"translit" : "katalambánō",
		"kjv" : "apprehend, attain, come upon, comprehend, find, obtain, perceive, (over-)take",
		"def" : "to take eagerly, i.e. seize, possess, etc. (literally or figuratively)",
		"derivation" : "from G2596 (κατά) and G2983 (λαμβάνω)"
	},
	"G3802" : {
		"lemma" : "παγιδεύω",
		"translit" : "pagideúō",
		"kjv" : "entangle",
		"def" : "to ensnare (figuratively)",
		"derivation" : "from G3803 (παγίς)"
	},
	"G2679" : {
		"derivation" : "from G2596 (κατά) and G4626 (σκάπτω)",
		"def" : "to undermine, i.e. (by implication) destroy",
		"kjv" : "dig down, ruin",
		"translit" : "kataskáptō",
		"lemma" : "κατασκάπτω"
	},
	"G5612" : {
		"def" : "to \"roar\"",
		"derivation" : "middle voice of an apparently primary verb",
		"lemma" : "ὠρύομαι",
		"translit" : "ōrýomai",
		"kjv" : "roar"
	},
	"G796" : {
		"kjv" : "lightning, bright shining",
		"translit" : "astrapḗ",
		"lemma" : "ἀστραπή",
		"derivation" : "from G797 (ἀστράπτω)",
		"def" : "lightning; by analogy, glare"
	},
	"G2985" : {
		"derivation" : "from G2989 (λάμπω)",
		"def" : "a \"lamp\" or flambeau",
		"kjv" : "lamp, light, torch",
		"lemma" : "λαμπάς",
		"translit" : "lampás"
	},
	"G2012" : {
		"translit" : "epítropos",
		"lemma" : "ἐπίτροπος",
		"kjv" : "steward, tutor",
		"def" : "a commissioner, i.e. domestic manager, guardian",
		"derivation" : "from G1909 (ἐπί) and G5158 (τρόπος) (in the sense of G2011 (ἐπιτροπή))"
	},
	"G1458" : {
		"def" : "to call in (as a debt or demand), i.e. bring to account (charge, criminate, etc.)",
		"derivation" : "from G1722 (ἐν) and G2564 (καλέω)",
		"lemma" : "ἐγκαλέω",
		"translit" : "enkaléō",
		"kjv" : "accuse, call in question, implead, lay to the charge"
	},
	"G4453" : {
		"def" : "to barter (as a pedlar), i.e. to sell",
		"derivation" : "probably ultimately from (to be busy, to trade)",
		"translit" : "pōléō",
		"lemma" : "πωλέω",
		"kjv" : "sell, whatever is sold"
	},
	"G2383" : {
		"def" : "Jairus (i.e. Jair), an Israelite",
		"derivation" : "of Hebrew origin (H2971)",
		"translit" : "Iáeiros",
		"lemma" : "Ἰάειρος",
		"kjv" : "Jairus"
	},
	"G5462" : {
		"derivation" : "from G5461 (φωτίζω)",
		"def" : "illumination (figuratively)",
		"kjv" : "light",
		"translit" : "phōtismós",
		"lemma" : "φωτισμός"
	},
	"G3642" : {
		"def" : "little-spirited, i.e. faint-hearted",
		"derivation" : "from G3641 (ὀλίγος) and G6590",
		"translit" : "oligópsychos",
		"lemma" : "ὀλιγόψυχος",
		"kjv" : "feebleminded"
	},
	"G1073" : {
		"kjv" : "be full",
		"translit" : "gémō",
		"lemma" : "γέμω",
		"derivation" : "a primary verb",
		"def" : "to swell out, i.e. be full"
	},
	"G2210" : {
		"translit" : "zēmióō",
		"lemma" : "ζημιόω",
		"kjv" : "be cast away, receive damage, lose, suffer loss",
		"def" : "to injure, i.e. (reflexively or passively) to experience detriment",
		"derivation" : "from G2209 (ζημία)"
	},
	"G4934" : {
		"translit" : "syntíthemai",
		"lemma" : "συντίθεμαι",
		"kjv" : "agree, assent, covenant",
		"def" : "to place jointly, i.e. (figuratively) to consent (bargain, stipulate), concur",
		"derivation" : "middle voice from G4862 (σύν) and G5087 (τίθημι)"
	},
	"G2421" : {
		"def" : "Jessæ (i.e. Jishai), an Israelite",
		"derivation" : "of Hebrew origin (H3448)",
		"lemma" : "Ἰεσσαί",
		"translit" : "Iessaí",
		"kjv" : "Jesse"
	},
	"G851" : {
		"kjv" : "cut (smite) off, take away",
		"lemma" : "ἀφαιρέω",
		"translit" : "aphairéō",
		"derivation" : "from G575 (ἀπό) and G138 (αἱρέομαι)",
		"def" : "to remove (literally or figuratively)"
	},
	"G2893" : {
		"lemma" : "κουφίζω",
		"translit" : "kouphízō",
		"kjv" : "lighten",
		"def" : "to unload",
		"derivation" : "from (light in weight)"
	},
	"G601" : {
		"kjv" : "reveal",
		"lemma" : "ἀποκαλύπτω",
		"translit" : "apokalýptō",
		"derivation" : "from G575 (ἀπό) and G2572 (καλύπτω)",
		"def" : "to take off the cover, i.e. disclose"
	},
	"G1330" : {
		"kjv" : "come, depart, go (about, abroad, everywhere, over, through, throughout), pass (by, over, through, throughout), pierce through, travel, walk through",
		"lemma" : "διέρχομαι",
		"translit" : "diérchomai",
		"derivation" : "from G1223 (διά) and G2064 (ἔρχομαι)",
		"def" : "to traverse (literally)"
	},
	"G4883" : {
		"derivation" : "from G4862 (σύν) and a derivative of a compound of G719 (ἁρμός) and G3004 (λέγω) (in its original sense of laying)",
		"def" : "to render close-jointed together, i.e. organize compactly",
		"kjv" : "be fitly framed (joined) together",
		"translit" : "synarmologéō",
		"lemma" : "συναρμολογέω"
	},
	"G5569" : {
		"translit" : "pseudádelphos",
		"lemma" : "ψευδάδελφος",
		"kjv" : "false brethren",
		"def" : "a spurious brother, i.e. pretended associate",
		"derivation" : "from G5571 (ψευδής) and G80 (ἀδελφός)"
	},
	"G2724" : {
		"lemma" : "κατηγορία",
		"translit" : "katēgoría",
		"kjv" : "accusation (X -ed)",
		"def" : "a complaint (\"category\"), i.e. criminal charge",
		"derivation" : "from G2725 (κατήγορος)"
	},
	"G5339" : {
		"derivation" : "of uncertain affinity",
		"def" : "to be chary of, i.e. (subjectively) to abstain or (objectively) to treat leniently",
		"kjv" : "forbear, spare",
		"lemma" : "φείδομαι",
		"translit" : "pheídomai"
	},
	"G4628" : {
		"lemma" : "σκέλος",
		"translit" : "skélos",
		"kjv" : "leg",
		"def" : "through the idea of leanness); the leg (as lank)",
		"derivation" : "apparently from (to parch"
	},
	"G4699" : {
		"def" : "a \"sponge\"",
		"derivation" : "perhaps of foreign origin",
		"lemma" : "σπόγγος",
		"translit" : "spóngos",
		"kjv" : "spunge"
	},
	"G4502" : {
		"translit" : "Rhoubḗn",
		"lemma" : "Ῥουβήν",
		"kjv" : "Reuben",
		"def" : "Ruben (i.e. Reuben), an Israelite",
		"derivation" : "of Hebrew origin (H7205)"
	},
	"G2639" : {
		"def" : "to lay down, i.e. (figuratively) to enrol",
		"derivation" : "from G2596 (κατά) and G3004 (λέγω) (in its original meaning)",
		"translit" : "katalégō",
		"lemma" : "καταλέγω",
		"kjv" : "take into the number"
	},
	"G5511" : {
		"kjv" : "robe",
		"translit" : "chlamýs",
		"lemma" : "χλαμύς",
		"derivation" : "of uncertain derivation",
		"def" : "a military cloak"
	},
	"G2934" : {
		"lemma" : "κτῆνος",
		"translit" : "ktēnos",
		"kjv" : "beast",
		"def" : "property, i.e. (specially) a domestic animal",
		"derivation" : "from G2932 (κτάομαι)"
	},
	"G294" : {
		"derivation" : "from the base of G297 (ἀμφότερος) and (to invest)",
		"def" : "to enrobe",
		"kjv" : "clothe",
		"translit" : "amphiénnymi",
		"lemma" : "ἀμφιέννυμι"
	},
	"G53" : {
		"kjv" : "chaste, clean, pure",
		"lemma" : "ἁγνός",
		"translit" : "hagnós",
		"derivation" : "from the same as G40 (ἅγιος)",
		"def" : "properly, clean, i.e. (figuratively) innocent, modest, perfect"
	},
	"G729" : {
		"def" : "unsewed, i.e. of a single piece",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of the same as G4476 (ῥαφίς)",
		"lemma" : "ἄῤῥαφος",
		"translit" : "árrhaphos",
		"kjv" : "without seam"
	},
	"G1394" : {
		"lemma" : "δόσις",
		"translit" : "dósis",
		"kjv" : "gift, giving",
		"def" : "a giving; by implication, (concretely) a gift",
		"derivation" : "from the base of G1325 (δίδωμι)"
	},
	"G4782" : {
		"derivation" : "from G4862 (σύν) and G2597 (καταβαίνω)",
		"def" : "to descend in company with",
		"kjv" : "go down with",
		"lemma" : "συγκαταβαίνω",
		"translit" : "synkatabaínō"
	},
	"G259" : {
		"lemma" : "ἅλωσις",
		"translit" : "hálōsis",
		"def" : "capture, be taken. ",
		"derivation" : "from a collateral form of G138 (αἱρέομαι)"
	},
	"G255" : {
		"kjv" : "unprofitable",
		"lemma" : "ἀλυσιτελής",
		"translit" : "alysitelḗs",
		"derivation" : "from G1 (Α) (as a negative particle) and the base of G3081 (λυσιτελεῖ)",
		"def" : "gainless, i.e. (by implication) pernicious"
	},
	"G4073" : {
		"def" : "a (mass of) rock (literally or figuratively)",
		"derivation" : "feminine of the same as G4074 (Πέτρος)",
		"lemma" : "πέτρα",
		"translit" : "pétra",
		"kjv" : "rock"
	},
	"G1388" : {
		"derivation" : "from an obsolete primary verb, (probably meaning to decoy",
		"def" : "compare G1185 (δελεάζω)); a trick (bait), i.e. (figuratively) wile",
		"kjv" : "craft, deceit, guile, subtilty",
		"lemma" : "δόλος",
		"translit" : "dólos"
	},
	"G189" : {
		"def" : "hearing (the act, the sense or the thing heard)",
		"derivation" : "from G191 (ἀκούω)",
		"lemma" : "ἀκοή",
		"translit" : "akoḗ",
		"kjv" : "audience, ear, fame, which ye heard, hearing, preached, report, rumor"
	},
	"G2983" : {
		"translit" : "lambánō",
		"lemma" : "λαμβάνω",
		"kjv" : "accept, + be amazed, assay, attain, bring, X when I call, catch, come on (X unto), + forget, have, hold, obtain, receive (X after), take (away, up)",
		"def" : "while G138 (αἱρέομαι) is more violent, to seize or remove))",
		"derivation" : "a prolonged form of a primary verb, which is use only as an alternate in certain tenses; to take (in very many applications, literally and figuratively (properly objective or active, to get hold of; whereas G1209 (δέχομαι) is rather subjective or passive, to have offered to one"
	},
	"G964" : {
		"derivation" : "of Chaldee origin (compare H1004 and H2617)",
		"def" : "house of kindness; Beth-esda, a pool in Jerusalem",
		"kjv" : "Bethesda",
		"lemma" : "Βηθεσδά",
		"translit" : "Bēthesdá"
	},
	"G1709" : {
		"kjv" : "breathe",
		"lemma" : "ἐμπνέω",
		"translit" : "empnéō",
		"derivation" : "from G1722 (ἐν) and G4154 (πνέω)",
		"def" : "to inhale, i.e. (figuratively) to be animated by (bent upon)"
	},
	"G2773" : {
		"lemma" : "κερματιστής",
		"translit" : "kermatistḗs",
		"kjv" : "changer of money",
		"def" : "a handler of coins, i.e. money-broker",
		"derivation" : "from a derivative of G2772 (κέρμα)"
	},
	"G2156" : {
		"kjv" : "decently, honestly",
		"lemma" : "εὐσχημόνως",
		"translit" : "euschēmónōs",
		"derivation" : "adverb from G2158 (εὐσχήμων)",
		"def" : "decorously"
	},
	"G2458" : {
		"kjv" : "Junias",
		"translit" : "Iouniâs",
		"lemma" : "Ἰουνιᾶς",
		"derivation" : "of Latin origin",
		"def" : "Junias, a Christian"
	},
	"G2871" : {
		"kjv" : "slaughter",
		"lemma" : "κοπή",
		"translit" : "kopḗ",
		"derivation" : "from G2875 (κόπτω)",
		"def" : "cutting, i.e. carnage"
	},
	"G635" : {
		"def" : "to lead astray (figuratively); passively, to stray (from truth)",
		"derivation" : "from G575 (ἀπό) and G4105 (πλανάω)",
		"translit" : "apoplanáō",
		"lemma" : "ἀποπλανάω",
		"kjv" : "err, seduce"
	},
	"G2760" : {
		"def" : "a centurion, i.e. captain of one hundred soldiers",
		"derivation" : "of Latin origin",
		"lemma" : "κεντυρίων",
		"translit" : "kentyríōn",
		"kjv" : "centurion"
	},
	"G5426" : {
		"def" : "to exercise the mind, i.e. entertain or have a sentiment or opinion; by implication, to be (mentally) disposed (more or less earnestly in a certain direction); intensively, to interest oneself in (with concern or obedience)",
		"derivation" : "from G5424 (φρήν)",
		"lemma" : "φρονέω",
		"translit" : "phronéō",
		"kjv" : "set the affection on, (be) care(-ful), (be like-, + be of one, + be of the same, + let this) mind(-ed), regard, savour, think"
	},
	"G2917" : {
		"kjv" : "avenge, condemned, condemnation, damnation, + go to law, judgment",
		"lemma" : "κρίμα",
		"translit" : "kríma",
		"derivation" : "from G2919 (κρίνω)",
		"def" : "a decision (the function or the effect, for or against (\"crime\"))"
	},
	"G2519" : {
		"def" : "a guide, i.e. (figuratively) a teacher",
		"derivation" : "from a compound of G2596 (κατά) and G2233 (ἡγέομαι)",
		"lemma" : "καθηγητής",
		"translit" : "kathēgētḗs",
		"kjv" : "master"
	},
	"G2212" : {
		"derivation" : "of uncertain affinity",
		"def" : "to seek (literally or figuratively); specially, (by Hebraism) to worship (God), or (in a bad sense) to plot (against life)",
		"kjv" : "be (go) about, desire, endeavour, enquire (for), require, (X will) seek (after, for, means)",
		"lemma" : "ζητέω",
		"translit" : "zētéō"
	},
	"G4179" : {
		"kjv" : "manifold more",
		"translit" : "pollaplasíōn",
		"lemma" : "πολλαπλασίων",
		"derivation" : "from G4183 (πολύς) and probably a derivative of G4120 (πλέκω)",
		"def" : "manifold, i.e. (neuter as noun) very much more"
	},
	"G2010" : {
		"translit" : "epitrépō",
		"lemma" : "ἐπιτρέπω",
		"kjv" : "give leave (liberty, license), let, permit, suffer",
		"def" : "to turn over (transfer), i.e. allow",
		"derivation" : "from G1909 (ἐπί) and the base of G5157 (τροπή)"
	},
	"G4859" : {
		"lemma" : "σύμφωνος",
		"translit" : "sýmphōnos",
		"kjv" : "consent",
		"def" : "sounding together (alike), i.e. (figuratively) accordant (neuter as noun, agreement)",
		"derivation" : "from G4862 (σύν) and G5456 (φωνή)"
	},
	"G2410" : {
		"lemma" : "Ἱεριχώ",
		"translit" : "Hierichṓ",
		"kjv" : "Jericho",
		"def" : "Jericho, a place in Palestine",
		"derivation" : "of Hebrew origin (H3405)"
	},
	"G4413" : {
		"kjv" : "before, beginning, best, chief(-est), first (of all), former",
		"translit" : "prōtos",
		"lemma" : "πρῶτος",
		"derivation" : "contracted superlative of G4253 (πρό)",
		"def" : "foremost (in time, place, order or importance)"
	},
	"G1346" : {
		"lemma" : "δικαίως",
		"translit" : "dikaíōs",
		"kjv" : "justly, (to) righteously(-ness)",
		"def" : "equitably",
		"derivation" : "adverb from G1342 (δίκαιος)"
	},
	"G5403" : {
		"lemma" : "Φοινίκη",
		"translit" : "Phoiníkē",
		"kjv" : "Phenice, Phenicia",
		"def" : "palm-country; Phœnice (or Phœnicia), a region of Palestine",
		"derivation" : "from G5404 (φοῖνιξ)"
	},
	"G4983" : {
		"kjv" : "bodily, body, slave",
		"lemma" : "σῶμα",
		"translit" : "sōma",
		"derivation" : "from G4982 (σώζω)",
		"def" : "the body (as a sound whole), used in a very wide application, literally or figuratively"
	},
	"G4605" : {
		"derivation" : "of Hebrew origin (H6721)",
		"def" : "Sidon (i.e. Tsidon), a place in Palestine",
		"kjv" : "Sidon",
		"translit" : "Sidṓn",
		"lemma" : "Σιδών"
	},
	"G5150" : {
		"kjv" : "three months",
		"translit" : "trímēnon",
		"lemma" : "τρίμηνον",
		"derivation" : "neuter of a compound of G5140 (τρεῖς) and G3376 (μήν) as noun",
		"def" : "a three months' space"
	},
	"G2880" : {
		"lemma" : "κορέννυμι",
		"translit" : "korénnymi",
		"kjv" : "eat enough, full",
		"def" : "to cram, i.e. glut or sate",
		"derivation" : "a primary verb"
	},
	"G1687" : {
		"derivation" : "from G1722 (ἐν) and a presumed derivative of the base of G939 (βάσις)",
		"def" : "equivalent to G1684 (ἐμβαίνω); to intrude on (figuratively)",
		"kjv" : "intrude into",
		"lemma" : "ἐμβατεύω",
		"translit" : "embateúō"
	},
	"G261" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G3129 (μανθάνω)",
		"def" : "ignorant",
		"kjv" : "unlearned",
		"translit" : "amathḗs",
		"lemma" : "ἀμαθής"
	},
	"G1918" : {
		"kjv" : "marry",
		"lemma" : "ἐπιγαμβρεύω",
		"translit" : "epigambreúō",
		"derivation" : "from G1909 (ἐπί) and a derivative of G1062 (γάμος)",
		"def" : "to form affinity with, i.e. (specially) in a levirate way"
	},
	"G803" : {
		"derivation" : "from G804 (ἀσφαλής)",
		"def" : "security (literally or figuratively)",
		"kjv" : "certainty, safety",
		"lemma" : "ἀσφάλεια",
		"translit" : "aspháleia"
	},
	"G3016" : {
		"lemma" : "λεπτόν",
		"translit" : "leptón",
		"kjv" : "mite",
		"def" : "something scaled (light), i.e. a small coin",
		"derivation" : "neuter of a derivative of the same as G3013 (λεπίς)"
	},
	"G758" : {
		"lemma" : "ἄρχων",
		"translit" : "árchōn",
		"kjv" : "chief (ruler), magistrate, prince, ruler",
		"def" : "a first (in rank or power)",
		"derivation" : "present participle of G757 (ἄρχω)"
	},
	"G3845" : {
		"translit" : "parabaínō",
		"lemma" : "παραβαίνω",
		"kjv" : "(by) transgress(-ion)",
		"def" : "to go contrary to, i.e. violate a command",
		"derivation" : "from G3844 (παρά) and the base of G939 (βάσις)"
	},
	"G3594" : {
		"def" : "to show the way (literally or figuratively (teach))",
		"derivation" : "from G3595 (ὁδηγός)",
		"lemma" : "ὁδηγέω",
		"translit" : "hodēgéō",
		"kjv" : "guide, lead"
	},
	"G447" : {
		"lemma" : "ἀνίημι",
		"translit" : "aníēmi",
		"kjv" : "forbear, leave, loose",
		"def" : "to let up, i.e. (literally) slacken or (figuratively) desert, desist from",
		"derivation" : "from G303 (ἀνά) and (to send)"
	},
	"G4663" : {
		"derivation" : "of uncertain derivation",
		"def" : "a grub, maggot or earth-worm",
		"kjv" : "worm",
		"lemma" : "σκώληξ",
		"translit" : "skṓlēx"
	},
	"G5301" : {
		"kjv" : "hyssop",
		"translit" : "hýssōpos",
		"lemma" : "ὕσσωπος",
		"derivation" : "of foreign origin (H231)",
		"def" : "\"hyssop\""
	},
	"G1367" : {
		"kjv" : "two thousand",
		"lemma" : "δισχίλιοι",
		"translit" : "dischílioi",
		"derivation" : "from G1364 (δίς) and G5507 (χίλιοι)",
		"def" : "two thousand"
	},
	"G240" : {
		"lemma" : "ἀλλήλων",
		"translit" : "allḗlōn",
		"kjv" : "each other, mutual, one another, (the other), (them-, your-)selves, (selves) together (sometimes with G3326 (μετά) or G4314 (πρός))",
		"def" : "one another",
		"derivation" : "Genitive plural from G243 (ἄλλος) reduplicated"
	},
	"G309" : {
		"lemma" : "ἀνάβλεψις",
		"translit" : "anáblepsis",
		"kjv" : "recovery of sight",
		"def" : "restoration of sight",
		"derivation" : "from G308 (ἀναβλέπω)"
	},
	"G4907" : {
		"def" : "a mental putting together, i.e. intelligence or (concretely) the intellect",
		"derivation" : "from G4920 (συνίημι)",
		"translit" : "sýnesis",
		"lemma" : "σύνεσις",
		"kjv" : "knowledge, understanding"
	},
	"G1476" : {
		"derivation" : "from a derivative of (to sit)",
		"def" : "sedentary, i.e. (by implication) immovable",
		"kjv" : "settled, stedfast",
		"lemma" : "ἑδραῖος",
		"translit" : "hedraîos"
	},
	"G3304" : {
		"derivation" : "from G3203 and G3767 (οὖν) and G1065 (γέ)",
		"def" : "so then at least",
		"kjv" : "nay but, yea doubtless (rather, verily)",
		"lemma" : "μενοῦνγε",
		"translit" : "menoûnge"
	},
	"G706" : {
		"def" : "a number (as reckoned up)",
		"derivation" : "from G142 (αἴρω)",
		"lemma" : "ἀριθμός",
		"translit" : "arithmós",
		"kjv" : "number"
	},
	"G237" : {
		"derivation" : "from G243 (ἄλλος)",
		"def" : "from elsewhere",
		"kjv" : "some other way",
		"lemma" : "ἀλλαχόθεν",
		"translit" : "allachóthen"
	},
	"G1083" : {
		"def" : "nativity",
		"derivation" : "from G1080 (γεννάω)",
		"translit" : "génnēsis",
		"lemma" : "γέννησις",
		"kjv" : "birth"
	},
	"G1188" : {
		"kjv" : "right (hand, side)",
		"lemma" : "δεξιός",
		"translit" : "dexiós",
		"derivation" : "from G1209 (δέχομαι)",
		"def" : "the right side or (feminine) hand (as that which usually takes)"
	},
	"G2802" : {
		"kjv" : "Clauda",
		"translit" : "Klaúdē",
		"lemma" : "Κλαύδη",
		"derivation" : "of uncertain derivation",
		"def" : "Claude, an island near Crete"
	},
	"G4875" : {
		"derivation" : "middle from G4862 (σύν) and G373 (ἀναπαύω)",
		"def" : "to recruit oneself in company with",
		"kjv" : "refresh with",
		"translit" : "synanapaúomai",
		"lemma" : "συναναπαύομαι"
	},
	"G3533" : {
		"kjv" : "Nicopolis",
		"lemma" : "Νικόπολις",
		"translit" : "Nikópolis",
		"derivation" : "from G3534 (νῖκος) and G4172 (πόλις)",
		"def" : "victorious city; Nicopolis, a place in Macedonia"
	},
	"G1151" : {
		"def" : "a heifer (as tame)",
		"derivation" : "probably from the base of G1150 (δαμάζω)",
		"lemma" : "δάμαλις",
		"translit" : "dámalis",
		"kjv" : "heifer"
	},
	"G156" : {
		"translit" : "aitía",
		"lemma" : "αἰτία",
		"kjv" : "accusation, case, cause, crime, fault, (wh-)ere(-fore)",
		"def" : "a cause (as if asked for), i.e. (logical) reason (motive, matter), (legal) crime (alleged or proved)",
		"derivation" : "from the same as G154 (αἰτέω)"
	},
	"G4097" : {
		"translit" : "pipráskō",
		"lemma" : "πιπράσκω",
		"kjv" : "sell",
		"def" : "from the base of G4008 (πέραν)); to traffic (by travelling), i.e. dispose of as merchandise or into slavery (literally or figuratively)",
		"derivation" : "contracted from (to traverse"
	},
	"G1429" : {
		"translit" : "dōdekáphylon",
		"lemma" : "δωδεκάφυλον",
		"kjv" : "twelve tribes",
		"def" : "the commonwealth of Israel",
		"derivation" : "from G1427 (δώδεκα) and G5443 (φυλή)"
	},
	"G5449" : {
		"lemma" : "φύσις",
		"translit" : "phýsis",
		"kjv" : "(man-)kind, nature(-al)",
		"def" : "growth (by germination or expansion), i.e. (by implication) natural production (lineal descent); by extension, a genus or sort; figuratively, native disposition, constitution or usage",
		"derivation" : "from G5453 (φύω)"
	},
	"G2443" : {
		"derivation" : "probably from the same as the former part of G1438 (ἑαυτοῦ) (through the demonstrative idea",
		"def" : "compare G3588 (ὁ)); in order that (denoting the purpose or the result)",
		"kjv" : "albeit, because, to the intent (that), lest, so as, (so) that, (for) to",
		"translit" : "hína",
		"lemma" : "ἵνα"
	},
	"G880" : {
		"kjv" : "dumb, without signification",
		"lemma" : "ἄφωνος",
		"translit" : "áphōnos",
		"derivation" : "from G1 (Α) (as a negative particle) and G5456 (φωνή)",
		"def" : "voiceless, i.e. mute (by nature or choice); figuratively, unmeaning"
	},
	"G917" : {
		"translit" : "baréōs",
		"lemma" : "βαρέως",
		"kjv" : "dull",
		"def" : "heavily (figuratively)",
		"derivation" : "adverb from G926 (βαρύς)"
	},
	"G524" : {
		"lemma" : "ἀπαλγέω",
		"translit" : "apalgéō",
		"kjv" : "be past feeling",
		"def" : "to grieve out, i.e. become apathetic",
		"derivation" : "from G575 (ἀπό) and (to smart)"
	},
	"G4736" : {
		"kjv" : "Stephen",
		"translit" : "Stéphanos",
		"lemma" : "Στέφανος",
		"derivation" : "the same as G4735 (στέφανος)",
		"def" : "Stephanus, a Christian"
	},
	"G5129" : {
		"lemma" : "τούτῳ",
		"translit" : "toútōi",
		"kjv" : "here(-by, -in), him, one, the same, there(-in), this",
		"def" : "to (in, with or by) this (person or thing)",
		"derivation" : "dative case singular masculine or neuter of G3778 (οὗτος)"
	},
	"G1249" : {
		"derivation" : "probably from an obsolete (to run on errands; compare G1377 (διώκω))",
		"def" : "an attendant, i.e. (genitive case) a waiter (at table or in other menial duties); specially, a Christian teacher and pastor (technically, a deacon or deaconess)",
		"kjv" : "deacon, minister, servant",
		"translit" : "diákonos",
		"lemma" : "διάκονος"
	},
	"G55" : {
		"translit" : "hagnōs",
		"lemma" : "ἁγνῶς",
		"kjv" : "sincerely",
		"def" : "purely, i.e. honestly",
		"derivation" : "adverb from G53 (ἁγνός)"
	},
	"G4400" : {
		"translit" : "procheirízomai",
		"lemma" : "προχειρίζομαι",
		"kjv" : "choose, make",
		"def" : "to handle for oneself in advance, i.e. (figuratively) to purpose",
		"derivation" : "middle voice from G4253 (πρό) and a derivative of G5495 (χείρ)"
	},
	"G4861" : {
		"kjv" : "like-minded",
		"translit" : "sýmpsychos",
		"lemma" : "σύμψυχος",
		"derivation" : "from G4862 (σύν) and G5590 (ψυχή)",
		"def" : "co-spirited, i.e. similar in sentiment"
	},
	"G4866" : {
		"derivation" : "from G4862 (σύν) and G118 (ἀθλέω)",
		"def" : "to wrestle in company with, i.e. (figuratively) to seek jointly",
		"kjv" : "labour with, strive together for",
		"lemma" : "συναθλέω",
		"translit" : "synathléō"
	},
	"G3791" : {
		"derivation" : "from G3793 (ὄχλος)",
		"def" : "to mob, i.e. (by implication) to harass",
		"kjv" : "vex",
		"lemma" : "ὀχλέω",
		"translit" : "ochléō"
	},
	"G5439" : {
		"translit" : "phylakízō",
		"lemma" : "φυλακίζω",
		"kjv" : "imprison",
		"def" : "to incarcerate",
		"derivation" : "from G5441 (φύλαξ)"
	},
	"G897" : {
		"kjv" : "Babylon",
		"translit" : "Babylṓn",
		"lemma" : "Βαβυλών",
		"derivation" : "of Hebrew origin (H894)",
		"def" : "Babylon, the capitol of Chaldæa (literally or figuratively (as a type of tyranny))"
	},
	"G1331" : {
		"kjv" : "make enquiry for",
		"lemma" : "διερωτάω",
		"translit" : "dierōtáō",
		"derivation" : "from G1223 (διά) and G2065 (ἐρωτάω)",
		"def" : "to question throughout, i.e. ascertain by interrogation"
	},
	"G3738" : {
		"kjv" : "dance",
		"lemma" : "ὀρχέομαι",
		"translit" : "orchéomai",
		"derivation" : "middle voice from (a row or ring)",
		"def" : "to dance (from the ranklike or regular motion)"
	},
	"G1389" : {
		"kjv" : "handle deceitfully",
		"lemma" : "δολόω",
		"translit" : "dolóō",
		"derivation" : "from G1388 (δόλος)",
		"def" : "to ensnare, i.e. (figuratively) adulterate"
	},
	"G3992" : {
		"derivation" : "apparently a primary verb",
		"def" : "to dispatch (from the subjective view or point of departure, whereas (as a stronger form of ) refers rather to the objective point or terminus ad quem, and G4724 (στέλλω) denotes properly, the orderly motion involved), especially on a temporary errand; also to transmit, bestow, or wield",
		"kjv" : "send, thrust in",
		"lemma" : "πέμπω",
		"translit" : "pémpō"
	},
	"G3923" : {
		"translit" : "pareisphérō",
		"lemma" : "παρεισφέρω",
		"kjv" : "give",
		"def" : "to bear in alongside, i.e. introduce simultaneously",
		"derivation" : "from G3844 (παρά) and G1533 (εἰσφέρω)"
	},
	"G2400" : {
		"derivation" : "second person singular imperative middle voice of G1492 (εἴδω)",
		"def" : "used as imperative lo!; ",
		"kjv" : "--behold, lo, see",
		"translit" : "idoú",
		"lemma" : "ἰδού"
	},
	"G4369" : {
		"lemma" : "προστίθημι",
		"translit" : "prostíthēmi",
		"kjv" : "add, again, give more, increase, lay unto, proceed further, speak to any more",
		"def" : "to place additionally, i.e. lay beside, annex, repeat",
		"derivation" : "from G4314 (πρός) and G5087 (τίθημι)"
	},
	"G1159" : {
		"kjv" : "be at charges, consume, spend",
		"lemma" : "δαπανάω",
		"translit" : "dapanáō",
		"derivation" : "from G1160 (δαπάνη)",
		"def" : "to expend, i.e. (in a good sense) to incur cost, or (in a bad one) to waste"
	},
	"G4320" : {
		"kjv" : "go up",
		"translit" : "prosanabaínō",
		"lemma" : "προσαναβαίνω",
		"derivation" : "from G4314 (πρός) and G305 (ἀναβαίνω)",
		"def" : "to ascend farther, i.e. be promoted (take an upper (more honorable) seat)"
	},
	"G331" : {
		"kjv" : "accused, anathema, curse, X great",
		"translit" : "anáthema",
		"lemma" : "ἀνάθεμα",
		"derivation" : "from G394 (ἀνατίθεμαι)",
		"def" : "a (religious) ban or (concretely) excommunicated (thing or person)"
	},
	"G5408" : {
		"kjv" : "murder, + be slain with, slaughter",
		"translit" : "phónos",
		"lemma" : "φόνος",
		"derivation" : "from an obsolete primary (to slay)",
		"def" : "murder"
	},
	"G3603" : {
		"kjv" : "called, which is (make), that is (to say)",
		"translit" : "hó esti",
		"lemma" : "ὅ ἐστι",
		"derivation" : "from the neuter of G3739 (ὅς) and the third person singular present indicative of G1510 (εἰμί)",
		"def" : "which is"
	},
	"G81" : {
		"derivation" : "from G80 (ἀδελφός)",
		"def" : "brotherhood (properly, the feeling of brotherliness), i.e. the (Christian) fraternity",
		"kjv" : "brethren, brotherhood",
		"lemma" : "ἀδελφότης",
		"translit" : "adelphótēs"
	},
	"G4874" : {
		"derivation" : "from G4862 (σύν) and a compound of G303 (ἀνά) and G3396 (μίγνυμι)",
		"def" : "to mix up together, i.e. (figurative) associate with",
		"kjv" : "(have, keep) company (with)",
		"lemma" : "συναναμίγνυμι",
		"translit" : "synanamígnymi"
	},
	"G4841" : {
		"lemma" : "συμπάσχω",
		"translit" : "sympáschō",
		"kjv" : "suffer with",
		"def" : "to experience pain jointly or of the same kind (specially, persecution; to \"sympathize\")",
		"derivation" : "from G4862 (σύν) and G3958 (πάσχω) (including its alternate)"
	},
	"G3180" : {
		"kjv" : "wile, lie in wait",
		"translit" : "methodeía",
		"lemma" : "μεθοδεία",
		"derivation" : "from a compound of G3326 (μετά) and G3593 (ὁδεύω) (compare \"method\")",
		"def" : "travelling over, i.e. travesty (trickery)"
	},
	"G4636" : {
		"derivation" : "from G4633 (σκηνή)",
		"def" : "a hut or temporary residence, i.e. (figuratively) the human body (as the abode of the spirit)",
		"kjv" : "tabernacle",
		"translit" : "skēnos",
		"lemma" : "σκῆνος"
	},
	"G1483" : {
		"def" : "as a Gentile",
		"derivation" : "adverb from G1482 (ἐθνικός)",
		"translit" : "ethnikōs",
		"lemma" : "ἐθνικῶς",
		"kjv" : "after the manner of Gentiles"
	},
	"G2842" : {
		"kjv" : "(to) communicate(-ation), communion, (contri-)distribution, fellowship",
		"lemma" : "κοινωνία",
		"translit" : "koinōnía",
		"derivation" : "from G2844 (κοινωνός)",
		"def" : "partnership, i.e. (literally) participation, or (social) intercourse, or (pecuniary) benefaction"
	},
	"G1548" : {
		"kjv" : "give in marriage",
		"lemma" : "ἐκγαμίσκω",
		"translit" : "ekgamískō",
		"derivation" : "from G1537 (ἐκ) and G1061 (γαμίσκω)",
		"def" : "the same as 1547"
	},
	"G979" : {
		"derivation" : "a primary word",
		"def" : "life, i.e. (literally) the present state of existence; by implication, the means of livelihood",
		"kjv" : "good, life, living",
		"lemma" : "βίος",
		"translit" : "bíos"
	},
	"G2990" : {
		"kjv" : "be hid, be ignorant of, unawares",
		"lemma" : "λανθάνω",
		"translit" : "lanthánō",
		"derivation" : "a prolonged form of a primary verb, which is used only as an alternate in certain tenses",
		"def" : "to lie hid (literally or figuratively); often used adverbially, unwittingly"
	},
	"G4906" : {
		"kjv" : "eat with",
		"translit" : "synesthíō",
		"lemma" : "συνεσθίω",
		"derivation" : "from G4862 (σύν) and G2068 (ἐσθίω) (including its alternate)",
		"def" : "to take food in company with"
	},
	"G5410" : {
		"def" : "a forum or market-place; only in comparative with G675 (Ἄππιος); a station on the Appian road",
		"derivation" : "of Latin origin",
		"lemma" : "Φόρον",
		"translit" : "Phóron",
		"kjv" : "forum"
	},
	"G1504" : {
		"kjv" : "image",
		"translit" : "eikṓn",
		"lemma" : "εἰκών",
		"derivation" : "from G1503 (εἴκω)",
		"def" : "a likeness, i.e. (literally) statue, profile, or (figuratively) representation, resemblance"
	},
	"G1762" : {
		"kjv" : "be, (there) is",
		"translit" : "éni",
		"lemma" : "ἔνι",
		"derivation" : "contraction for the third person singular present indicative of G1751 (ἔνειμι)",
		"def" : "impersonally, there is in or among"
	},
	"G275" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G3308 (μέριμνα)",
		"def" : "not anxious",
		"kjv" : "without care(-fulness), secure",
		"lemma" : "ἀμέριμνος",
		"translit" : "amérimnos"
	},
	"G2517" : {
		"derivation" : "from G2596 (κατά) and G1836 (ἑξῆς)",
		"def" : "thereafter, i.e. consecutively; as a noun (by ellipsis of noun) a subsequent person or time",
		"kjv" : "after(-ward), by (in) order",
		"translit" : "kathexēs",
		"lemma" : "καθεξῆς"
	},
	"G105" : {
		"kjv" : "eagle",
		"translit" : "aetós",
		"lemma" : "ἀετός",
		"derivation" : "from the same as G109 (ἀήρ)",
		"def" : "an eagle (from its wind-like flight)"
	},
	"G1954" : {
		"translit" : "epíloipos",
		"lemma" : "ἐπίλοιπος",
		"kjv" : "rest",
		"def" : "left over, i.e. remaining",
		"derivation" : "from G1909 (ἐπί) and G3062 (λοιποί)"
	},
	"G4118" : {
		"derivation" : "irregular superlative of G4183 (πολύς)",
		"def" : "the largest number or very large",
		"kjv" : "very great, most",
		"translit" : "pleîstos",
		"lemma" : "πλεῖστος"
	},
	"G507" : {
		"def" : "upward or on the top",
		"derivation" : "adverb from G473 (ἀντί)",
		"translit" : "ánō",
		"lemma" : "ἄνω",
		"kjv" : "above, brim, high, up"
	},
	"G5337" : {
		"derivation" : "apparently a primary word",
		"def" : "\"foul\" or \"flawy\", i.e. (figuratively) wicked",
		"kjv" : "evil",
		"translit" : "phaûlos",
		"lemma" : "φαῦλος"
	},
	"G612" : {
		"def" : "a response",
		"derivation" : "from G611 (ἀποκρίνομαι)",
		"lemma" : "ἀπόκρισις",
		"translit" : "apókrisis",
		"kjv" : "answer"
	},
	"G3037" : {
		"translit" : "líthos",
		"lemma" : "λίθος",
		"kjv" : "(mill-, stumbling-)stone",
		"def" : "a stone (literally or figuratively)",
		"derivation" : "apparently a primary word"
	},
	"G2272" : {
		"kjv" : "peaceable, quiet",
		"translit" : "hēsýchios",
		"lemma" : "ἡσύχιος",
		"derivation" : "a prolonged form of a compound probably of a derivative of the base of G1476 (ἑδραῖος) and perhaps G2192 (ἔχω)",
		"def" : "properly, keeping one's seat (sedentary), i.e. (by implication) still (undisturbed, undisturbing)"
	},
	"G1938" : {
		"derivation" : "from G1937 (ἐπιθυμέω)",
		"def" : "a craver",
		"kjv" : "+ lust after",
		"lemma" : "ἐπιθυμητής",
		"translit" : "epithymētḗs"
	},
	"G2661" : {
		"kjv" : "(ac-)count worthy",
		"lemma" : "καταξιόω",
		"translit" : "kataxióō",
		"derivation" : "from G2596 (κατά) and G515 (ἀξιόω)",
		"def" : "to deem entirely deserving"
	},
	"G1772" : {
		"kjv" : "lawful, under law",
		"lemma" : "ἔννομος",
		"translit" : "énnomos",
		"derivation" : "from G1722 (ἐν) and G3551 (νόμος)",
		"def" : "(subjectively) legal, or (objectively) subject to"
	},
	"G5225" : {
		"lemma" : "ὑπάρχω",
		"translit" : "hypárchō",
		"kjv" : "after, behave, live",
		"def" : "to begin under (quietly), i.e. come into existence (be present or at hand); expletively, to exist (as copula or subordinate to an adjective, participle, adverb or preposition, or as an auxiliary to a principal (verb)",
		"derivation" : "from G5259 (ὑπό) and G756 (ἄρχομαι)"
	},
	"G455" : {
		"kjv" : "open",
		"lemma" : "ἀνοίγω",
		"translit" : "anoígō",
		"derivation" : "from G303 (ἀνά) and (to open)",
		"def" : "to open up (literally or figuratively, in various applications)"
	},
	"G5288" : {
		"lemma" : "ὑποστέλλω",
		"translit" : "hypostéllō",
		"kjv" : "draw (keep) back, shun, withdraw",
		"def" : "to withhold under (out of sight), i.e. (reflexively) to cower or shrink, (figuratively) to conceal (reserve)",
		"derivation" : "from G5259 (ὑπό) and G4724 (στέλλω)"
	},
	"G2394" : {
		"derivation" : "future active participle masculine of G2390 (ἰάομαι)",
		"def" : "about to cure; Jason, a Christian",
		"kjv" : "Jason",
		"translit" : "Iásōn",
		"lemma" : "Ἰάσων"
	},
	"G5494" : {
		"derivation" : "from a derivative of (to pour",
		"def" : "akin to the base of 5490 through the idea of a channel), meaning a storm (as pouring rain); by implication, the rainy season, i.e. winter",
		"kjv" : "tempest, foul weather, winter",
		"lemma" : "χειμών",
		"translit" : "cheimṓn"
	},
	"G4160" : {
		"derivation" : "apparently a prolonged form of an obsolete primary",
		"def" : "to make or do (in a very wide application, more or less direct)",
		"kjv" : "abide, + agree, appoint, X avenge, + band together, be, bear, + bewray, bring (forth), cast out, cause, commit, + content, continue, deal, + without any delay, (would) do(-ing), execute, exercise, fulfil, gain, give, have, hold, X journeying, keep, + lay wait, + lighten the ship, make, X mean, + none of these things move me, observe, ordain, perform, provide, + have purged, purpose, put, + raising up, X secure, shew, X shoot out, spend, take, tarry, + transgress the law, work, yield",
		"translit" : "poiéō",
		"lemma" : "ποιέω"
	},
	"G2907" : {
		"derivation" : "perhaps a primary word",
		"def" : "(butcher's) meat",
		"kjv" : "flesh",
		"translit" : "kréas",
		"lemma" : "κρέας"
	},
	"G2205" : {
		"lemma" : "ζῆλος",
		"translit" : "zēlos",
		"kjv" : "emulation, envy(-ing), fervent mind, indignation, jealousy, zeal",
		"def" : "properly, heat, i.e. (figuratively) \"zeal\" (in a favorable sense, ardor; in an unfavorable one, jealousy, as of a husband (figuratively, of God), or an enemy, malice)",
		"derivation" : "from G2204 (ζέω)"
	},
	"G3516" : {
		"kjv" : "babe, child (+ -ish)",
		"lemma" : "νήπιος",
		"translit" : "nḗpios",
		"derivation" : "from an obsolete particle (implying negation) and G2031 (ἔπος)",
		"def" : "not speaking, i.e. an infant (minor); figuratively, a simple-minded person, an immature Christian"
	},
	"G1218" : {
		"lemma" : "δῆμος",
		"translit" : "dēmos",
		"kjv" : "people",
		"def" : "the public (as bound together socially)",
		"derivation" : "from G1210 (δέω)"
	},
	"G3687" : {
		"kjv" : "call, name",
		"lemma" : "ὀνομάζω",
		"translit" : "onomázō",
		"derivation" : "from G3686 (ὄνομα)",
		"def" : "to name, i.e. assign an appellation; by extension, to utter, mention, profess"
	},
	"G154" : {
		"kjv" : "ask, beg, call for, crave, desire, require",
		"translit" : "aitéō",
		"lemma" : "αἰτέω",
		"derivation" : "of uncertain derivation",
		"def" : "to ask (in genitive case)"
	},
	"G2750" : {
		"def" : "a swathe, i.e. winding-sheet",
		"derivation" : "of uncertain affinity",
		"lemma" : "κειρία",
		"translit" : "keiría",
		"kjv" : "graveclothes"
	},
	"G2808" : {
		"translit" : "kleíō",
		"lemma" : "κλείω",
		"kjv" : "shut (up)",
		"def" : "to close (literally or figuratively)",
		"derivation" : "a primary verb"
	},
	"G4267" : {
		"translit" : "proginṓskō",
		"lemma" : "προγινώσκω",
		"kjv" : "foreknow (ordain), know (before)",
		"def" : "to know beforehand, i.e. foresee",
		"derivation" : "from G4253 (πρό) and G1097 (γινώσκω)"
	},
	"G3324" : {
		"lemma" : "μεστός",
		"translit" : "mestós",
		"kjv" : "full",
		"def" : "replete (literally or figuratively)",
		"derivation" : "of uncertain derivation"
	},
	"G4586" : {
		"translit" : "semnós",
		"lemma" : "σεμνός",
		"kjv" : "grave, honest",
		"def" : "venerable, i.e. honorable",
		"derivation" : "from G4576 (σέβομαι)"
	},
	"G3876" : {
		"lemma" : "παρακοή",
		"translit" : "parakoḗ",
		"kjv" : "disobedience",
		"def" : "inattention, i.e. (by implication) disobedience",
		"derivation" : "from G3878 (παρακούω)"
	},
	"G222" : {
		"kjv" : "of Alexandria",
		"lemma" : "Ἀλεξανδρῖνος",
		"translit" : "Alexandrînos",
		"derivation" : "from the same as G221 (Ἀλεξανδρεύς)",
		"def" : "Alexandrine, or belonging to Alexandria"
	},
	"G1802" : {
		"kjv" : "Enoch",
		"translit" : "Enṓch",
		"lemma" : "Ἐνώχ",
		"derivation" : "of Hebrew origin (H2585)",
		"def" : "Enoch (i.e. Chanok), an antediluvian"
	},
	"G4880" : {
		"translit" : "synapothnḗskō",
		"lemma" : "συναποθνήσκω",
		"kjv" : "be dead (die) with",
		"def" : "to decease (literally) in company with, or (figuratively), similarly to",
		"derivation" : "from G4862 (σύν) and G599 (ἀποθνήσκω)"
	},
	"G4417" : {
		"def" : "to trip, i.e. (figuratively) to err, sin, fail (of salvation)",
		"derivation" : "a form of G4098 (πίπτω)",
		"translit" : "ptaíō",
		"lemma" : "πταίω",
		"kjv" : "fall, offend, stumble"
	},
	"G1213" : {
		"lemma" : "δηλόω",
		"translit" : "dēlóō",
		"kjv" : "declare, shew, signify",
		"def" : "to make plain (by words)",
		"derivation" : "from G1212 (δῆλος)"
	},
	"G1935" : {
		"def" : "doomed to death",
		"derivation" : "from G1909 (ἐπί) and G2288 (θάνατος)",
		"translit" : "epithanátios",
		"lemma" : "ἐπιθανάτιος",
		"kjv" : "appointed to death"
	},
	"G3860" : {
		"def" : "to surrender, i.e yield up, intrust, transmit",
		"derivation" : "from G3844 (παρά) and G1325 (δίδωμι)",
		"lemma" : "παραδίδωμι",
		"translit" : "paradídōmi",
		"kjv" : "betray, bring forth, cast, commit, deliver (up), give (over, up), hazard, put in prison, recommend"
	},
	"G2356" : {
		"def" : "ceremonial observance",
		"derivation" : "from a derivative of G2357 (θρησκός)",
		"translit" : "thrēskeía",
		"lemma" : "θρησκεία",
		"kjv" : "religion, worshipping"
	},
	"G4801" : {
		"def" : "to yoke together, i.e. (figuratively) conjoin (in marriage)",
		"derivation" : "from G4862 (σύν) and the base of G2201 (ζεῦγος)",
		"translit" : "syzeúgnymi",
		"lemma" : "συζεύγνυμι",
		"kjv" : "join together"
	},
	"G1343" : {
		"derivation" : "from G1342 (δίκαιος)",
		"def" : "equity (of character or act); specially (Christian) justification",
		"kjv" : "righteousness",
		"lemma" : "δικαιοσύνη",
		"translit" : "dikaiosýnē"
	},
	"G2507" : {
		"def" : "to lower (or with violence) demolish (literally or figuratively)",
		"derivation" : "from G2596 (κατά) and G138 (αἱρέομαι) (including its alternate)",
		"translit" : "kathairéō",
		"lemma" : "καθαιρέω",
		"kjv" : "cast (pull, put, take) down, destroy"
	},
	"G4393" : {
		"derivation" : "from G4253 (πρό) and G5342 (φέρω)",
		"def" : "to bear forward, i.e. produce",
		"kjv" : "bring forth",
		"lemma" : "προφέρω",
		"translit" : "prophérō"
	},
	"G4904" : {
		"kjv" : "companion in labour, (fellow-)helper(-labourer, -worker), labourer together with, workfellow",
		"lemma" : "συνεργός",
		"translit" : "synergós",
		"derivation" : "from a presumed compound of G4862 (σύν) and the base of G2041 (ἔργον)",
		"def" : "a co-laborer, i.e. coadjutor"
	},
	"G565" : {
		"def" : "to go off (i.e. depart), aside (i.e. apart) or behind (i.e. follow), literally or figuratively",
		"derivation" : "from G575 (ἀπό) and G2064 (ἔρχομαι)",
		"lemma" : "ἀπέρχομαι",
		"translit" : "apérchomai",
		"kjv" : "come, depart, go (aside, away, back, out, … ways), pass away, be past"
	},
	"G1448" : {
		"def" : "to make near, i.e. (reflexively) approach",
		"derivation" : "from G1451 (ἐγγύς)",
		"lemma" : "ἐγγίζω",
		"translit" : "engízō",
		"kjv" : "approach, be at hand, come (draw) near, be (come, draw) nigh"
	},
	"G926" : {
		"derivation" : "from the same as G922 (βάρος)",
		"def" : "weighty, i.e. (fig) burdensome, grave",
		"kjv" : "grievous, heavy, weightier",
		"lemma" : "βαρύς",
		"translit" : "barýs"
	},
	"G4476" : {
		"lemma" : "ῥαφίς",
		"translit" : "rhaphís",
		"kjv" : "needle",
		"def" : "perhaps rather akin to the base of G4474 (ῥαπίζω) through the idea of puncturing); a needle",
		"derivation" : "from a primary (to sew"
	},
	"G3394" : {
		"kjv" : "uncleanness",
		"lemma" : "μιασμός",
		"translit" : "miasmós",
		"derivation" : "from G3392 (μιαίνω)",
		"def" : "(morally) contamination (properly, the act)"
	},
	"G4146" : {
		"def" : "copiously",
		"derivation" : "adverb from G4145 (πλούσιος)",
		"lemma" : "πλουσίως",
		"translit" : "plousíōs",
		"kjv" : "abundantly, richly"
	},
	"G1661" : {
		"kjv" : "of ivory",
		"lemma" : "ἐλεφάντινος",
		"translit" : "elephántinos",
		"derivation" : "from (an \"elephant\")",
		"def" : "elephantine, i.e. (by implication) composed of ivory"
	},
	"G521" : {
		"def" : "uninstructed, i.e. (figuratively) stupid",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G3811 (παιδεύω)",
		"lemma" : "ἀπαίδευτος",
		"translit" : "apaídeutos",
		"kjv" : "unlearned"
	},
	"G3447" : {
		"kjv" : "make a calf",
		"lemma" : "μοσχοποιέω",
		"translit" : "moschopoiéō",
		"derivation" : "from G3448 (μόσχος) and G4160 (ποιέω)",
		"def" : "to fabricate the image of a bullock"
	},
	"G4590" : {
		"derivation" : "of Hebrew origin (H8035)",
		"def" : "Sem (i.e. Shem), a patriarch",
		"kjv" : "Sem",
		"lemma" : "Σήμ",
		"translit" : "Sḗm"
	},
	"G1822" : {
		"def" : "to finish out (time); figuratively, to equip fully (a teacher)",
		"derivation" : "from G1537 (ἐκ) and a derivative of G739 (ἄρτιος)",
		"lemma" : "ἐξαρτίζω",
		"translit" : "exartízō",
		"kjv" : "accomplish, thoroughly furnish"
	},
	"G2084" : {
		"lemma" : "ἑτερόγλωσσος",
		"translit" : "heteróglōssos",
		"kjv" : "man of other tongue",
		"def" : "other- tongued, i.e. a foreigner",
		"derivation" : "from G2087 (ἕτερος) and G1100 (γλῶσσα)"
	},
	"G4200" : {
		"lemma" : "πορισμός",
		"translit" : "porismós",
		"kjv" : "gain",
		"def" : "furnishing (procuring), i.e. (by implication) money-getting (acquisition)",
		"derivation" : "from a derivative of (a way, i.e. means)"
	},
	"G5595" : {
		"kjv" : "(bestow to) feed",
		"lemma" : "ψωμίζω",
		"translit" : "psōmízō",
		"derivation" : "from the base of G5596 (ψωμίον)",
		"def" : "to supply with bits, i.e. (generally) to nourish"
	},
	"G1672" : {
		"kjv" : "Gentile, Greek",
		"translit" : "Héllēn",
		"lemma" : "Ἕλλην",
		"derivation" : "from G1671 (Ἑλλάς)",
		"def" : "a Hellen (Grecian) or inhabitant of Hellas; by extension a Greek-speaking person, especially a non-Jew"
	},
	"G5104" : {
		"kjv" : "(used only with other particles in the comparative, as G2544 (καίτοιγε), G3305 (μέντοι), G5105 (τοιγαροῦν), G5106 (τοίνυν), etc.",
		"translit" : "toí",
		"lemma" : "τοί",
		"derivation" : "probably for the dative case of G3588 (ὁ)",
		"def" : "an enclitic particle of asseveration by way of contrast; in sooth"
	},
	"G5502" : {
		"def" : "\"cherubim\" (i.e. cherubs or kerubim)",
		"derivation" : "plural of Hebrew origin (H3742)",
		"lemma" : "χερουβίμ",
		"translit" : "cheroubím",
		"kjv" : "cherubims"
	},
	"G310" : {
		"def" : "to halloo",
		"derivation" : "from G303 (ἀνά) and G994 (βοάω)",
		"lemma" : "ἀναβοάω",
		"translit" : "anaboáō",
		"kjv" : "cry (aloud, out)"
	},
	"G70" : {
		"translit" : "agrypnía",
		"lemma" : "ἀγρυπνία",
		"kjv" : "watch",
		"def" : "sleeplessness, i.e. a keeping awake",
		"derivation" : "from G69 (ἀγρυπνέω)"
	},
	"G2358" : {
		"kjv" : "(cause) to triumph (over)",
		"lemma" : "θριαμβεύω",
		"translit" : "thriambeúō",
		"derivation" : "from a prolonged compound of the base of G2360 (θροέω)",
		"def" : "and a derivative of G680 (ἅπτομαι) (meaning a noisy iambus, sung in honor of Bacchus); to make an acclamatory procession, i.e. (figuratively) to conquer or (by Hebraism) to give victory"
	},
	"G878" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G5424 (φρήν)",
		"def" : "properly, mindless, i.e. stupid, (by implication) ignorant, (specially) egotistic, (practically) rash, or (morally) unbelieving",
		"kjv" : "fool(-ish), unwise",
		"translit" : "áphrōn",
		"lemma" : "ἄφρων"
	},
	"G2255" : {
		"kjv" : "half",
		"translit" : "hḗmisy",
		"lemma" : "ἥμισυ",
		"derivation" : "neuter of a derivative from an inseparable prefix akin to G260 (ἅμα) (through the idea of partition involved in connection) and meaning semi-",
		"def" : "(as noun) half"
	},
	"G691" : {
		"def" : "to be idle, i.e. (figuratively) to delay",
		"derivation" : "from G692 (ἀργός)",
		"lemma" : "ἀργέω",
		"translit" : "argéō",
		"kjv" : "linger"
	},
	"G1635" : {
		"kjv" : "willingly",
		"lemma" : "ἑκών",
		"translit" : "hekṓn",
		"derivation" : "of uncertain affinity",
		"def" : "voluntary"
	},
	"G2965" : {
		"lemma" : "κύων",
		"translit" : "kýōn",
		"kjv" : "dog",
		"def" : "a dog (\"hound\") (literally or figuratively)",
		"derivation" : "a primary word"
	},
	"G1628" : {
		"derivation" : "from G1537 (ἐκ) and G5343 (φεύγω)",
		"def" : "to flee out",
		"kjv" : "escape, flee",
		"translit" : "ekpheúgō",
		"lemma" : "ἐκφεύγω"
	},
	"G1354" : {
		"def" : "reveller; Dionysius, an Athenian",
		"derivation" : "from (Bacchus)",
		"translit" : "Dionýsios",
		"lemma" : "Διονύσιος",
		"kjv" : "Dionysius"
	},
	"G4091" : {
		"lemma" : "Πιλᾶτος",
		"translit" : "Pilâtos",
		"kjv" : "Pilate",
		"def" : "close-pressed, i.e. firm; Pilatus, a Roman",
		"derivation" : "of Latin origin"
	},
	"G4455" : {
		"derivation" : "from G4452 (-πω) and G4218 (ποτέ)",
		"def" : "at any time, i.e. (with negative particle) at no time",
		"kjv" : "at any time, + never (…to any man), + yet, never man",
		"lemma" : "πώποτε",
		"translit" : "pṓpote"
	},
	"G1744" : {
		"translit" : "endýnō",
		"lemma" : "ἐνδύνω",
		"kjv" : "creep",
		"def" : "to sink (by implication, wrap (compare G1746 (ἐνδύω)) on, i.e. (figuratively) sneak",
		"derivation" : "from G1772 (ἔννομος) and G1416 (δύνω)"
	},
	"G1580" : {
		"kjv" : "carry out",
		"lemma" : "ἐκκομίζω",
		"translit" : "ekkomízō",
		"derivation" : "from G1537 (ἐκ) and G2865 (κομίζω)",
		"def" : "to bear forth (to burial)"
	},
	"G1078" : {
		"derivation" : "from the same as G1074 (γενεά)",
		"def" : "nativity; figuratively, nature",
		"kjv" : "generation, nature(-ral)",
		"lemma" : "γένεσις",
		"translit" : "génesis"
	},
	"G450" : {
		"def" : "to stand up (literal or figurative, transitive or intransitive)",
		"derivation" : "from G303 (ἀνά) and G2476 (ἵστημι)",
		"lemma" : "ἀνίστημι",
		"translit" : "anístēmi",
		"kjv" : "arise, lift up, raise up (again), rise (again), stand up(-right)"
	},
	"G2493" : {
		"derivation" : "of Hebrew origin (H3100)",
		"def" : "Joel, an Israelite",
		"kjv" : "Joel",
		"lemma" : "Ἰωήλ",
		"translit" : "Iōḗl"
	},
	"G3125" : {
		"def" : "a grandmother",
		"derivation" : "of natural origin (\"mammy\")",
		"translit" : "mámmē",
		"lemma" : "μάμμη",
		"kjv" : "grandmother"
	},
	"G232" : {
		"lemma" : "ἁλιεύω",
		"translit" : "halieúō",
		"kjv" : "go a-fishing",
		"def" : "to be a fisher, i.e. (by implication) to fish",
		"derivation" : "from G231 (ἁλιεύς)"
	},
	"G1956" : {
		"lemma" : "ἐπιλύω",
		"translit" : "epilýō",
		"kjv" : "determine, expound",
		"def" : "to solve further, i.e. (figuratively) to explain, decide",
		"derivation" : "from G1909 (ἐπί) and G3089 (λύω)"
	},
	"G4373" : {
		"def" : "recently",
		"derivation" : "adverb from G4372 (πρόσφατος)",
		"lemma" : "προσφάτως",
		"translit" : "prosphátōs",
		"kjv" : "lately"
	},
	"G4377" : {
		"translit" : "prosphōnéō",
		"lemma" : "προσφωνέω",
		"kjv" : "call unto, speak (un-)to",
		"def" : "to sound towards, i.e. address, exclaim, summon",
		"derivation" : "from G4314 (πρός) and G5455 (φωνέω)"
	},
	"G4806" : {
		"derivation" : "from G4862 (σύν) and G2227 (ζωοποιέω)",
		"def" : "to reanimate conjointly with (figuratively)",
		"kjv" : "quicken together with",
		"translit" : "syzōopoiéō",
		"lemma" : "συζωοποιέω"
	},
	"G3196" : {
		"def" : "a limb or part of the body",
		"derivation" : "of uncertain affinity",
		"translit" : "mélos",
		"lemma" : "μέλος",
		"kjv" : "member"
	},
	"G2254" : {
		"lemma" : "ἡμῖν",
		"translit" : "hēmîn",
		"kjv" : "our, (for) us, we",
		"def" : "to (or for, with, by) us",
		"derivation" : "dative case plural of G1473 (ἐγώ)"
	},
	"G358" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G251 (ἅλς)",
		"def" : "saltless, i.e. insipid",
		"kjv" : "X lose saltness",
		"translit" : "ánalos",
		"lemma" : "ἄναλος"
	},
	"G1222" : {
		"derivation" : "from G1211 (δή) and G4225 (πού)",
		"def" : "a particle of asseveration; indeed doubtless",
		"kjv" : "verily",
		"translit" : "dḗpou",
		"lemma" : "δήπου"
	},
	"G4372" : {
		"def" : "previously (recently) slain (fresh), i.e. (figuratively) lately made",
		"derivation" : "from G4253 (πρό) and a derivative of G4969 (σφάζω)",
		"lemma" : "πρόσφατος",
		"translit" : "prósphatos",
		"kjv" : "new"
	},
	"G1840" : {
		"derivation" : "from G1537 (ἐκ) and G2480 (ἰσχύω)",
		"def" : "to have full strength, i.e. be entirely competent",
		"kjv" : "be able",
		"lemma" : "ἐξισχύω",
		"translit" : "exischýō"
	},
	"G1427" : {
		"kjv" : "twelve",
		"lemma" : "δώδεκα",
		"translit" : "dṓdeka",
		"derivation" : "from G1417 (δύο) and G1176 (δέκα)",
		"def" : "two and ten, i.e. a dozen"
	},
	"G554" : {
		"kjv" : "put off, spoil",
		"lemma" : "ἀπεκδύομαι",
		"translit" : "apekdýomai",
		"derivation" : "middle voice from G575 (ἀπό) and G1562 (ἐκδύω)",
		"def" : "to divest wholly oneself, or (for oneself) despoil"
	},
	"G2986" : {
		"translit" : "lamprós",
		"lemma" : "λαμπρός",
		"kjv" : "bright, clear, gay, goodly, gorgeous, white",
		"def" : "radiant; by analogy, limpid; figuratively, magnificent or sumptuous (in appearance)",
		"derivation" : "from the same as G2985 (λαμπάς)"
	},
	"G5172" : {
		"def" : "effeminacy, i.e. luxury or debauchery",
		"derivation" : "from (to break up or (figuratively) enfeeble, especially the mind and body by indulgence)",
		"translit" : "tryphḗ",
		"lemma" : "τρυφή",
		"kjv" : "delicately, riot"
	},
	"G3158" : {
		"translit" : "Matthát",
		"lemma" : "Ματθάτ",
		"kjv" : "Mathat",
		"def" : "Matthat (i.e. Mattithjah), the name of two Israelites",
		"derivation" : "probably a shortened form of G3161 (Ματταθίας)"
	},
	"G5289" : {
		"lemma" : "ὑποστολή",
		"translit" : "hypostolḗ",
		"kjv" : "draw back",
		"def" : "shrinkage (timidity), i.e. (by implication) apostasy",
		"derivation" : "from G5288 (ὑποστέλλω)"
	},
	"G4718" : {
		"derivation" : "probably from the base of G4735 (στέφανος)",
		"def" : "a cluster of grapes (as if intertwined)",
		"kjv" : "grapes",
		"lemma" : "σταφυλή",
		"translit" : "staphylḗ"
	},
	"G2433" : {
		"kjv" : "be merciful, make reconciliation for",
		"translit" : "hiláskomai",
		"lemma" : "ἱλάσκομαι",
		"derivation" : "middle voice from the same as G2436 (ἵλεως)",
		"def" : "to conciliate, i.e. (transitively) to atone for (sin), or (intransitively) be propitious"
	},
	"G5574" : {
		"kjv" : "falsely, lie",
		"translit" : "pseúdomai",
		"lemma" : "ψεύδομαι",
		"derivation" : "middle voice of an apparently primary verb",
		"def" : "to utter an untruth or attempt to deceive by falsehood"
	},
	"G5324" : {
		"kjv" : "sight",
		"translit" : "phantázō",
		"lemma" : "φαντάζω",
		"derivation" : "from a derivative of G5316 (φαίνω)",
		"def" : "to make apparent, i.e. (passively) to appear (neuter participle as noun, a spectacle)"
	},
	"G3918" : {
		"kjv" : "come, X have, be here, + lack, (be here) present",
		"translit" : "páreimi",
		"lemma" : "πάρειμι",
		"derivation" : "from G3844 (παρά) and G1510 (εἰμί) (including its various forms)",
		"def" : "to be near, i.e. at hand; neuter present participle (singular) time being, or (plural) property"
	},
	"G2617" : {
		"def" : "to shame down, i.e. disgrace or (by implication) put to the blush",
		"derivation" : "from G2596 (κατά) and G153 (αἰσχύνομαι)",
		"translit" : "kataischýnō",
		"lemma" : "καταισχύνω",
		"kjv" : "confound, dishonour, (be a-, make a-)shame(-d)"
	},
	"G4057" : {
		"derivation" : "adverb from G4053 (περισσός)",
		"def" : "superabundantly",
		"kjv" : "exceedingly, out of measure, the more",
		"translit" : "perissōs",
		"lemma" : "περισσῶς"
	},
	"G244" : {
		"def" : "overseeing others' affairs, i.e. a meddler (specially, in Gentile customs)",
		"derivation" : "from G245 (ἀλλότριος) and G1985 (ἐπίσκοπος)",
		"translit" : "allotriepískopos",
		"lemma" : "ἀλλοτριεπίσκοπος",
		"kjv" : "busybody in other men's matters"
	},
	"G3759" : {
		"derivation" : "a primary exclamation of grief",
		"def" : "\"woe\"",
		"kjv" : "alas, woe",
		"translit" : "ouaí",
		"lemma" : "οὐαί"
	},
	"G3555" : {
		"kjv" : "brood",
		"lemma" : "νοσσιά",
		"translit" : "nossiá",
		"derivation" : "from G3502 (νεοσσός)",
		"def" : "a brood (of chickens)"
	},
	"G2497" : {
		"translit" : "Iōreím",
		"lemma" : "Ἰωρείμ",
		"kjv" : "Jorim",
		"def" : "Jorim, an Israelite",
		"derivation" : "perhaps for G2496 (Ἰωράμ)"
	},
	"G2650" : {
		"kjv" : "abide",
		"translit" : "kataménō",
		"lemma" : "καταμένω",
		"derivation" : "from G2596 (κατά) and G3306 (μένω)",
		"def" : "to stay fully, i.e. reside"
	},
	"G5578" : {
		"def" : "a spurious prophet, i.e. pretended foreteller or religious impostor",
		"derivation" : "from G5571 (ψευδής) and G4396 (προφήτης)",
		"lemma" : "ψευδοπροφήτης",
		"translit" : "pseudoprophḗtēs",
		"kjv" : "false prophet"
	},
	"G3703" : {
		"lemma" : "ὀπώρα",
		"translit" : "opṓra",
		"kjv" : "fruit",
		"def" : "properly, even-tide of the (summer) season (dog-days), i.e. (by implication) ripe fruit",
		"derivation" : "apparently from the base of G3796 (ὀψέ) and G5610 (ὥρα)"
	},
	"G989" : {
		"def" : "scurrilious, i.e. calumnious (against men), or (specially) impious (against God)",
		"derivation" : "from a derivative of G984 (βλάπτω) and G5345 (φήμη)",
		"lemma" : "βλάσφημος",
		"translit" : "blásphēmos",
		"kjv" : "blasphemer(-mous), railing"
	},
	"G4726" : {
		"lemma" : "στεναγμός",
		"translit" : "stenagmós",
		"kjv" : "groaning",
		"def" : "a sigh",
		"derivation" : "from G4727 (στενάζω)"
	},
	"G2260" : {
		"lemma" : "ἤπερ",
		"translit" : "ḗper",
		"kjv" : "than",
		"def" : "than at all (or than perhaps, than indeed)",
		"derivation" : "from G2228 (ἤ) and G4007 (περ)"
	},
	"G1834" : {
		"lemma" : "ἐξηγέομαι",
		"translit" : "exēgéomai",
		"kjv" : "declare, tell",
		"def" : "to consider out (aloud), i.e. rehearse, unfold",
		"derivation" : "from G1537 (ἐκ) and G2233 (ἡγέομαι)"
	},
	"G749" : {
		"derivation" : "from G746 (ἀρχή) and G2409 (ἱερεύς)",
		"def" : "the high-priest (literally, of the Jews, typically, Christ); by extension a chief priest",
		"kjv" : "chief (high) priest, chief of the priests",
		"lemma" : "ἀρχιερεύς",
		"translit" : "archiereús"
	},
	"G178" : {
		"def" : "without (legal) trial",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G2632 (κατακρίνω)",
		"lemma" : "ἀκατάκριτος",
		"translit" : "akatákritos",
		"kjv" : "uncondemned"
	},
	"G4825" : {
		"translit" : "sýmboulos",
		"lemma" : "σύμβουλος",
		"kjv" : "counsellor",
		"def" : "a consultor, i.e. adviser",
		"derivation" : "from G4862 (σύν) and G1012 (βουλή)"
	},
	"G4747" : {
		"translit" : "stoicheîon",
		"lemma" : "στοιχεῖον",
		"kjv" : "element, principle, rudiment",
		"def" : "something orderly in arrangement, i.e. (by implication) a serial (basal, fundamental, initial) constituent (literally), proposition (figuratively)",
		"derivation" : "neuter of a presumed derivative of the base of G4748 (στοιχέω)"
	},
	"G3946" : {
		"kjv" : "like",
		"translit" : "parómoios",
		"lemma" : "παρόμοιος",
		"derivation" : "from G3844 (παρά) and G3664 (ὅμοιος)",
		"def" : "alike nearly, i.e. similar"
	},
	"G5517" : {
		"lemma" : "χοϊκός",
		"translit" : "choïkós",
		"kjv" : "earthy",
		"def" : "dusty or dirty (soil-like), i.e. (by implication) terrene",
		"derivation" : "from G5522 (χόος)"
	},
	"G2123" : {
		"def" : "better for toil, i.e. more facile",
		"derivation" : "comparative of a compound of G2095 (εὖ) and G2873 (κόπος)",
		"translit" : "eukopṓteros",
		"lemma" : "εὐκοπώτερος",
		"kjv" : "easier"
	},
	"G2389" : {
		"derivation" : "of Egyptian origin",
		"def" : "Jannes, an Egyptian",
		"kjv" : "Jannes",
		"translit" : "Iannēs",
		"lemma" : "Ἰαννῆς"
	},
	"G1745" : {
		"lemma" : "ἔνδυσις",
		"translit" : "éndysis",
		"kjv" : "putting on",
		"def" : "investment with clothing",
		"derivation" : "from G1746 (ἐνδύω)"
	},
	"G3133" : {
		"kjv" : "fade away",
		"translit" : "maraínō",
		"lemma" : "μαραίνω",
		"derivation" : "of uncertain affinity",
		"def" : "to extinguish (as fire), i.e. (figuratively and passively) to pass away"
	},
	"G1625" : {
		"lemma" : "ἐκτρέφω",
		"translit" : "ektréphō",
		"kjv" : "bring up, nourish",
		"def" : "to rear up to maturity, i.e. (genitive case) to cherish or train",
		"derivation" : "from G1537 (ἐκ) and G5142 (τρέφω)"
	},
	"G72" : {
		"derivation" : "reduplicated from G71 (ἄγω)",
		"def" : "a bringing up, i.e. mode of living",
		"kjv" : "manner of life",
		"translit" : "agōgḗ",
		"lemma" : "ἀγωγή"
	},
	"G4916" : {
		"translit" : "syntháptō",
		"lemma" : "συνθάπτω",
		"kjv" : "bury with",
		"def" : "to inter in company with, i.e. (figuratively) to assimilate spiritually (to Christ by a sepulture as to sin)",
		"derivation" : "from G4862 (σύν) and G2290 (θάπτω)"
	},
	"G4671" : {
		"kjv" : "thee, thine own, thou, thy",
		"translit" : "soí",
		"lemma" : "σοί",
		"derivation" : "dative case of G4771 (σύ)",
		"def" : "to thee"
	},
	"G3983" : {
		"lemma" : "πεινάω",
		"translit" : "peináō",
		"kjv" : "be an hungered",
		"def" : "\"pine\"); to famish (absolutely or comparatively); figuratively, to crave",
		"derivation" : "from the same as G3993 (πένης) (through the idea of pinching toil"
	},
	"G4307" : {
		"derivation" : "from G4306 (προνοέω)",
		"def" : "forethought, i.e. provident care or supply",
		"kjv" : "providence, provision",
		"translit" : "prónoia",
		"lemma" : "πρόνοια"
	},
	"G1993" : {
		"def" : "to put something over the mouth, i.e. (figuratively) to silence",
		"derivation" : "from G1909 (ἐπί) and G4750 (στόμα)",
		"lemma" : "ἐπιστομίζω",
		"translit" : "epistomízō",
		"kjv" : "stop mouths"
	},
	"G3938" : {
		"lemma" : "πάροδος",
		"translit" : "párodos",
		"kjv" : "way",
		"def" : "a by-road, i.e. (actively) a route",
		"derivation" : "from G3844 (παρά) and G3598 (ὁδός)"
	},
	"G4014" : {
		"def" : "to remove all around, i.e. unveil, cast off (anchor); figuratively, to expiate",
		"derivation" : "from G4012 (περί) and G138 (αἱρέομαι) (including its alternate)",
		"lemma" : "περιαιρέω",
		"translit" : "periairéō",
		"kjv" : "take away (up)"
	},
	"G4220" : {
		"derivation" : "neuter of a comparative of the base of G4226 (ποῦ)",
		"def" : "interrogative as adverb, which (of two), i.e. is it this or that",
		"kjv" : "whether",
		"lemma" : "πότερον",
		"translit" : "póteron"
	},
	"G3909" : {
		"derivation" : "from G3844 (παρά) and G5177 (τυγχάνω)",
		"def" : "to chance near, i.e. fall in with",
		"kjv" : "meet with",
		"translit" : "paratynchánō",
		"lemma" : "παρατυγχάνω"
	},
	"G1645" : {
		"def" : "light, i.e. easy",
		"derivation" : "probably akin to G1643 (ἐλαύνω) and the base of G1640 (ἐλάσσων)",
		"lemma" : "ἐλαφρός",
		"translit" : "elaphrós",
		"kjv" : "light"
	},
	"G1037" : {
		"lemma" : "βυθός",
		"translit" : "bythós",
		"kjv" : "deep",
		"def" : "depth, i.e. (by implication) the sea",
		"derivation" : "a variation of G899 (βάθος)"
	},
	"G573" : {
		"derivation" : "probably from G1 (Α) (as a particle of union) and the base of G4120 (πλέκω)",
		"def" : "properly, folded together, i.e. single (figuratively, clear)",
		"kjv" : "single",
		"lemma" : "ἁπλοῦς",
		"translit" : "haploûs"
	},
	"G4444" : {
		"translit" : "pýrgos",
		"lemma" : "πύργος",
		"kjv" : "tower",
		"def" : "a tower or castle",
		"derivation" : "apparently a primary word (\"burgh\")"
	},
	"G5011" : {
		"translit" : "tapeinós",
		"lemma" : "ταπεινός",
		"kjv" : "base, cast down, humble, of low degree (estate), lowly",
		"def" : "depressed, i.e. (figuratively) humiliated (in circumstances or disposition)",
		"derivation" : "of uncertain derivation"
	},
	"G1219" : {
		"derivation" : "from G1218 (δῆμος)",
		"def" : "public; (feminine singular dative case as adverb) in public",
		"kjv" : "common, openly, publickly",
		"lemma" : "δημόσιος",
		"translit" : "dēmósios"
	},
	"G4555" : {
		"derivation" : "from the same as G4556 (σάρδιος)",
		"def" : "sardine (G3037 (λίθος) being implied), i.e. a gem, so called",
		"kjv" : "sardine",
		"translit" : "sárdinos",
		"lemma" : "σάρδινος"
	},
	"G433" : {
		"kjv" : "convenient, be fit",
		"translit" : "anḗkō",
		"lemma" : "ἀνήκω",
		"derivation" : "from G303 (ἀνά) and G2240 (ἥκω)",
		"def" : "to attain to, i.e. (figuratively) be proper"
	},
	"G128" : {
		"kjv" : "Ethiopian",
		"translit" : "Aithíops",
		"lemma" : "Αἰθίοψ",
		"derivation" : "from (to scorch) and (the face, from G3700 (ὀπτάνομαι))",
		"def" : "an Æthiopian (as a blackamoor)"
	},
	"G3895" : {
		"lemma" : "παραπίπτω",
		"translit" : "parapíptō",
		"kjv" : "fall away",
		"def" : "to fall aside, i.e. (figuratively) to apostatize",
		"derivation" : "from G3844 (παρά) and G4098 (πίπτω)"
	},
	"G2223" : {
		"kjv" : "girdle, purse",
		"lemma" : "ζώνη",
		"translit" : "zṓnē",
		"derivation" : "probably akin to the base of G2218 (ζυγός)",
		"def" : "a belt; by implication, a pocket"
	},
	"G2059" : {
		"def" : "to translate",
		"derivation" : "from a presumed derivative of G2060 (Ἑρμῆς) (as the god of language)",
		"translit" : "hermēneúō",
		"lemma" : "ἑρμηνεύω",
		"kjv" : "interpret"
	},
	"G5047" : {
		"lemma" : "τελειότης",
		"translit" : "teleiótēs",
		"kjv" : "perfection(-ness)",
		"def" : "(the state) completeness (mentally or morally)",
		"derivation" : "from G5046 (τέλειος)"
	},
	"G3593" : {
		"lemma" : "ὁδεύω",
		"translit" : "hodeúō",
		"kjv" : "journey",
		"def" : "to travel",
		"derivation" : "from G3598 (ὁδός)"
	},
	"G1547" : {
		"derivation" : "from G1537 (ἐκ) and a form of G1061 (γαμίσκω) (compare G1548 (ἐκγαμίσκω))",
		"def" : "to marry off a daughter",
		"kjv" : "give in marriage",
		"translit" : "ekgamízō",
		"lemma" : "ἐκγαμίζω"
	},
	"G3052" : {
		"translit" : "lógios",
		"lemma" : "λόγιος",
		"kjv" : "eloquent",
		"def" : "fluent, i.e. an orator",
		"derivation" : "from G3056 (λόγος)"
	},
	"G1471" : {
		"def" : "swelling inside, i.e. pregnant",
		"derivation" : "from G1722 (ἐν) and the base of G2949 (κῦμα)",
		"lemma" : "ἔγκυος",
		"translit" : "énkyos",
		"kjv" : "great with child"
	},
	"G5424" : {
		"kjv" : "understanding",
		"translit" : "phrḗn",
		"lemma" : "φρήν",
		"derivation" : "probably from an obsolete (to rein in or curb; compare G5420 (φράσσω))",
		"def" : "the midrif (as a partition of the body), i.e. (figuratively and by implication, of sympathy) the feelings (or sensitive nature; by extension (also in the plural) the mind or cognitive faculties)"
	},
	"G1939" : {
		"lemma" : "ἐπιθυμία",
		"translit" : "epithymía",
		"kjv" : "concupiscence, desire, lust (after)",
		"def" : "a longing (especially for what is forbidden)",
		"derivation" : "from G1937 (ἐπιθυμέω)"
	},
	"G4314" : {
		"lemma" : "πρός",
		"translit" : "prós",
		"kjv" : "about, according to , against, among, at, because of, before, between, (where-)by, for, X at thy house, in, for intent, nigh unto, of, which pertain to, that, to (the end that), X together, to (you) -ward, unto, with(-in)",
		"def" : "a preposition of direction; forward to, i.e. toward (with the genitive case, the side of, i.e. pertaining to; with the dative case, by the side of, i.e. near to; usually with the accusative case, the place, time, occasion, or respect, which is the destination of the relation, i.e. whither or for which it is predicated)",
		"derivation" : "a strengthened form of G4253 (πρό)"
	},
	"G379" : {
		"translit" : "anapológētos",
		"lemma" : "ἀναπολόγητος",
		"kjv" : "without an excuse, inexcusable",
		"def" : "indefensible",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G626 (ἀπολογέομαι)"
	},
	"G107" : {
		"derivation" : "of Hebrew origin (compare H5809)",
		"def" : "Azor, an Israelite",
		"kjv" : "Azorigin ",
		"translit" : "Azṓr",
		"lemma" : "Ἀζώρ"
	},
	"G791" : {
		"def" : "urbane, i.e. (by implication) handsome",
		"derivation" : "from (a city)",
		"lemma" : "ἀστεῖος",
		"translit" : "asteîos",
		"kjv" : "fair"
	},
	"G5581" : {
		"kjv" : "falsely so called",
		"lemma" : "ψευδώνυμος",
		"translit" : "pseudṓnymos",
		"derivation" : "from G5571 (ψευδής) and G3686 (ὄνομα)",
		"def" : "untruly named"
	},
	"G5329" : {
		"def" : "Phares (i.e. Perets), an Israelite",
		"derivation" : "of Hebrew origin (H6557)",
		"lemma" : "Φάρες",
		"translit" : "Pháres",
		"kjv" : "Phares"
	},
	"G3755" : {
		"def" : "during which same time, i.e. whilst",
		"derivation" : "for the genitive case of G3748 (ὅστις) (as adverb)",
		"translit" : "hótou",
		"lemma" : "ὅτου",
		"kjv" : "whiles"
	},
	"G290" : {
		"def" : "a vineyard",
		"derivation" : "from G288 (ἄμπελος)",
		"translit" : "ampelṓn",
		"lemma" : "ἀμπελών",
		"kjv" : "vineyard"
	},
	"G1636" : {
		"def" : "an olive (the tree or the fruit)",
		"derivation" : "feminine of a presumed derivative from an obsolete primary",
		"lemma" : "ἐλαία",
		"translit" : "elaía",
		"kjv" : "olive (berry, tree)"
	},
	"G3439" : {
		"derivation" : "from G3441 (μόνος) and G1096 (γίνομαι)",
		"def" : "only-born, i.e. sole",
		"kjv" : "only (begotten, child)",
		"translit" : "monogenḗs",
		"lemma" : "μονογενής"
	},
	"G4977" : {
		"def" : "to split or sever (literally or figuratively)",
		"derivation" : "apparently a primary verb",
		"lemma" : "σχίζω",
		"translit" : "schízō",
		"kjv" : "break, divide, open, rend, make a rent"
	},
	"G2315" : {
		"translit" : "theópneustos",
		"lemma" : "θεόπνευστος",
		"kjv" : "given by inspiration of God",
		"def" : "divinely breathed in",
		"derivation" : "from G2316 (θεός) and a presumed derivative of G4154 (πνέω)"
	},
	"G2357" : {
		"lemma" : "θρησκός",
		"translit" : "thrēskós",
		"kjv" : "religious",
		"def" : "ceremonious in worship (as demonstrative), i.e. pious",
		"derivation" : "probably from the base of G2360 (θροέω)"
	},
	"G2455" : {
		"derivation" : "of Hebrew origin (H3063)",
		"def" : "Judas (i.e. Jehudah), the name of ten Israelites; also of the posterity of one of them and its region",
		"kjv" : "Juda(-h, -s); Jude",
		"lemma" : "Ἰούδας",
		"translit" : "Ioúdas"
	},
	"G2837" : {
		"kjv" : "(be a-, fall a-, fall on) sleep, be dead",
		"lemma" : "κοιμάω",
		"translit" : "koimáō",
		"derivation" : "from G2749 (κεῖμαι)",
		"def" : "to put to sleep, i.e. (passively or reflexively) to slumber; figuratively, to decease"
	},
	"G5561" : {
		"kjv" : "coast, county, fields, ground, land, region",
		"lemma" : "χώρα",
		"translit" : "chṓra",
		"derivation" : "feminine of a derivative of the base of G5490 (χάσμα) through the idea of empty expanse",
		"def" : "room, i.e. a space of territory (more or less extensive; often including its inhabitants)"
	},
	"G4104" : {
		"kjv" : "assure of",
		"lemma" : "πιστόω",
		"translit" : "pistóō",
		"derivation" : "from G4103 (πιστός)",
		"def" : "to assure"
	},
	"G828" : {
		"kjv" : "Augustus",
		"translit" : "Augoûstos",
		"lemma" : "Αὐγοῦστος",
		"derivation" : "from Latin (\"august\")",
		"def" : "Augustus, a title of the Roman emperor"
	},
	"G1603" : {
		"derivation" : "from G1537 (ἐκ) and G4137 (πληρόω)",
		"def" : "to accomplish entirely",
		"kjv" : "fulfill",
		"lemma" : "ἐκπληρόω",
		"translit" : "ekplēróō"
	},
	"G3551" : {
		"translit" : "nómos",
		"lemma" : "νόμος",
		"kjv" : "law",
		"def" : "law (through the idea of prescriptive usage), genitive case (regulation), specially, (of Moses (including the volume); also of the Gospel), or figuratively (a principle)",
		"derivation" : "from a primary (to parcel out, especially food or grazing to animals)"
	},
	"G2407" : {
		"def" : "to be a priest, i.e. perform his functions",
		"derivation" : "prolongation from G2409 (ἱερεύς)",
		"translit" : "hierateúō",
		"lemma" : "ἱερατεύω",
		"kjv" : "execute the priest's office"
	},
	"G3784" : {
		"derivation" : "probably from the base of G3786 (ὄφελος) (through the idea of accruing)",
		"def" : "to owe (pecuniarily); figuratively, to be under obligation (ought, must, should); morally, to fail in duty",
		"kjv" : "behove, be bound, (be) debt(-or), (be) due(-ty), be guilty (indebted), (must) need(-s), ought, owe, should",
		"lemma" : "ὀφείλω",
		"translit" : "opheílō"
	},
	"G3042" : {
		"def" : "a scarcity of food",
		"derivation" : "probably from G3007 (λείπω) (through the idea of destitution)",
		"translit" : "limós",
		"lemma" : "λιμός",
		"kjv" : "dearth, famine, hunger"
	},
	"G3639" : {
		"kjv" : "destruction",
		"lemma" : "ὄλεθρος",
		"translit" : "ólethros",
		"derivation" : "from a primary (to destroy",
		"def" : "a prolonged form); ruin, i.e. death, punishment"
	},
	"G3155" : {
		"derivation" : "accusative case of a derivative of the base of G3145 (μασσάομαι) (through the idea of tentative manipulation, i.e. unsuccessful search, or else of punishment)",
		"def" : "folly, i.e. (adverbially) to no purpose",
		"kjv" : "in vain",
		"lemma" : "μάτην",
		"translit" : "mátēn"
	},
	"G721" : {
		"def" : "a lambkin",
		"derivation" : "diminutive from G704 (ἀρήν)",
		"lemma" : "ἀρνίον",
		"translit" : "arníon",
		"kjv" : "lamb"
	},
	"G2800" : {
		"def" : "fracture (the act)",
		"derivation" : "from G2806 (κλάω)",
		"lemma" : "κλάσις",
		"translit" : "klásis",
		"kjv" : "breaking"
	},
	"G2105" : {
		"derivation" : "feminine from G2095 (εὖ) and the alternate of G2203 (Ζεύς) (as the god of the weather)",
		"def" : "a clear sky, i.e. fine weather",
		"kjv" : "fair weather",
		"translit" : "eudía",
		"lemma" : "εὐδία"
	},
	"G4062" : {
		"translit" : "peritrépō",
		"lemma" : "περιτρέπω",
		"kjv" : "+ make mad",
		"def" : "to turn around, i.e. (mentally) to craze",
		"derivation" : "from G4012 (περί) and the base of G5157 (τροπή)"
	},
	"G5399" : {
		"def" : "to frighten, i.e. (passively) to be alarmed; by analogy, to be in awe of, i.e. revere",
		"derivation" : "from G5401 (φόβος)",
		"lemma" : "φοβέω",
		"translit" : "phobéō",
		"kjv" : "be (+ sore) afraid, fear (exceedingly), reverence"
	},
	"G2021" : {
		"lemma" : "ἐπιχειρέω",
		"translit" : "epicheiréō",
		"kjv" : "go about, take in hand (upon)",
		"def" : "to put the hand upon, i.e. undertake",
		"derivation" : "from G1909 (ἐπί) and G5495 (χείρ)"
	},
	"G4294" : {
		"def" : "to prepare in advance",
		"derivation" : "from G4253 (πρό) and G2675 (καταρτίζω)",
		"translit" : "prokatartízō",
		"lemma" : "προκαταρτίζω",
		"kjv" : "make up beforehand"
	},
	"G4766" : {
		"derivation" : "prolongation from a still simpler , (used only as an alternate in certain tenses) (probably akin to G4731 (στερεός) through the idea of positing)",
		"def" : "to \"strew,\" i.e. spread (as a carpet or couch)",
		"kjv" : "make bed, furnish, spread, strew",
		"translit" : "strṓnnymi",
		"lemma" : "στρώννυμι"
	},
	"G4881" : {
		"kjv" : "perish with",
		"lemma" : "συναπόλλυμι",
		"translit" : "synapóllymi",
		"derivation" : "from G4862 (σύν) and G622 (ἀπόλλυμι)",
		"def" : "to destroy (middle voice or passively, be slain) in company with"
	},
	"G2686" : {
		"def" : "to be crafty against, i.e. circumvent",
		"derivation" : "middle voice from G2596 (κατά) and G4679 (σοφίζω)",
		"lemma" : "κατασοφίζομαι",
		"translit" : "katasophízomai",
		"kjv" : "deal subtilly with"
	},
	"G1340" : {
		"translit" : "diïschyrízomai",
		"lemma" : "διϊσχυρίζομαι",
		"kjv" : "confidently (constantly) affirm",
		"def" : "to stout it through, i.e. asservate",
		"derivation" : "from G1223 (διά) and a derivative of G2478 (ἰσχυρός)"
	},
	"G4008" : {
		"def" : "through (as adverb or preposition), i.e. across",
		"derivation" : "apparently accusative case of an obsolete derivative of (to \"pierce\")",
		"lemma" : "πέραν",
		"translit" : "péran",
		"kjv" : "beyond, farther (other) side, over"
	},
	"G2992" : {
		"derivation" : "apparently a primary word",
		"def" : "a people (in general; thus differing from G1218 (δῆμος), which denotes one's own populace)",
		"kjv" : "people",
		"lemma" : "λαός",
		"translit" : "laós"
	},
	"G1779" : {
		"derivation" : "from a compound of G1722 (ἐν) and G5028 (τάφος)",
		"def" : "to inswathe with cerements for interment",
		"kjv" : "bury",
		"lemma" : "ἐνταφιάζω",
		"translit" : "entaphiázō"
	},
	"G2245" : {
		"def" : "as big as, i.e. (interjectively) how much",
		"derivation" : "from (a comrade, i.e. one of the same age)",
		"translit" : "hēlíkos",
		"lemma" : "ἡλίκος",
		"kjv" : "how (what) great"
	},
	"G4124" : {
		"derivation" : "from G4123 (πλεονέκτης)",
		"def" : "avarice, i.e. (by implication) fraudulency, extortion",
		"kjv" : "covetous(-ness) practices, greediness",
		"translit" : "pleonexía",
		"lemma" : "πλεονεξία"
	},
	"G5168" : {
		"translit" : "trymaliá",
		"lemma" : "τρυμαλιά",
		"kjv" : "eye",
		"def" : "akin to the base of G5134 (τραῦμα), G5147 (τρίβος) and G5176 (τρώγω)); an orifice, i.e. needle's eye",
		"derivation" : "from a derivative of (to wear away"
	},
	"G3657" : {
		"derivation" : "from G3658 (ὅμιλος)",
		"def" : "companionship (\"homily\"), i.e. (by implication) intercourse",
		"kjv" : "communication",
		"lemma" : "ὁμιλία",
		"translit" : "homilía"
	},
	"G1016" : {
		"kjv" : "ox",
		"translit" : "boûs",
		"lemma" : "βοῦς",
		"derivation" : "probably from the base of G1006 (βόσκω)",
		"def" : "an ox (as grazing), i.e. an animal of that species (\"beef\")"
	},
	"G4383" : {
		"def" : "the front (as being towards view), i.e. the countenance, aspect, appearance, surface; by implication, presence, person",
		"derivation" : "from G4314 (πρός) and (the visage, from G3700 (ὀπτάνομαι))",
		"translit" : "prósōpon",
		"lemma" : "πρόσωπον",
		"kjv" : "(outward) appearance, X before, countenance, face, fashion, (men's) person, presence"
	},
	"G1575" : {
		"translit" : "ekkláō",
		"lemma" : "ἐκκλάω",
		"kjv" : "break off",
		"def" : "to exscind",
		"derivation" : "from G1537 (ἐκ) and G2806 (κλάω)"
	},
	"G2085" : {
		"def" : "to instruct differently",
		"derivation" : "from G2087 (ἕτερος) and G1320 (διδάσκαλος)",
		"lemma" : "ἑτεροδιδασκαλέω",
		"translit" : "heterodidaskaléō",
		"kjv" : "teach other doctrine(-wise)"
	},
	"G4609" : {
		"derivation" : "contraction for G4610 (Σιλουανός)",
		"def" : "Silas, a Christian",
		"kjv" : "Silas",
		"translit" : "Sílas",
		"lemma" : "Σίλας"
	},
	"G340" : {
		"lemma" : "ἀνακαινίζω",
		"translit" : "anakainízō",
		"kjv" : "renew",
		"def" : "to restore",
		"derivation" : "from G303 (ἀνά) and a derivative of G2537 (καινός)"
	},
	"G4224" : {
		"derivation" : "from the alternate of G4095 (πίνω)",
		"def" : "a drinking-bout or carousal",
		"kjv" : "banqueting",
		"translit" : "pótos",
		"lemma" : "πότος"
	},
	"G1072" : {
		"def" : "to fill entirely",
		"derivation" : "transitive from G1073 (γέμω)",
		"lemma" : "γεμίζω",
		"translit" : "gemízō",
		"kjv" : "fill (be) full"
	},
	"G3010" : {
		"kjv" : "ministering",
		"translit" : "leitourgikós",
		"lemma" : "λειτουργικός",
		"derivation" : "from the same as G3008 (λειτουργέω)",
		"def" : "functional publicly (\"liturgic\"); i.e. beneficient"
	},
	"G2278" : {
		"translit" : "ēchéō",
		"lemma" : "ἠχέω",
		"kjv" : "roar, sound",
		"def" : "to make a loud noise, i.e. reverberate",
		"derivation" : "from G2279 (ἦχος)"
	},
	"G3795" : {
		"kjv" : "fish",
		"translit" : "opsárion",
		"lemma" : "ὀψάριον",
		"derivation" : "neuter of a presumed derivative of the base of G3702 (ὀπτός)",
		"def" : "a relish to other food (as if cooked sauce), i.e. (specially), fish (presumably salted and dried as a condiment)"
	},
	"G3945" : {
		"lemma" : "παρομοιάζω",
		"translit" : "paromoiázō",
		"kjv" : "be like unto",
		"def" : "to resemble",
		"derivation" : "from G3946 (παρόμοιος)"
	},
	"G432" : {
		"lemma" : "ἄνηθον",
		"translit" : "ánēthon",
		"kjv" : "anise",
		"def" : "dill",
		"derivation" : "probably of foreign origin"
	},
	"G996" : {
		"def" : "aid; specially, a rope or chain for frapping a vessel",
		"derivation" : "from G998 (βοηθός)",
		"lemma" : "βοήθεια",
		"translit" : "boḗtheia",
		"kjv" : "help"
	},
	"G5290" : {
		"derivation" : "from G5259 (ὑπό) and G4762 (στρέφω)",
		"def" : "to turn under (behind), i.e. to return (literally or figuratively)",
		"kjv" : "come again, return (again, back again), turn back (again)",
		"translit" : "hypostréphō",
		"lemma" : "ὑποστρέφω"
	},
	"G720" : {
		"def" : "to contradict, i.e. disavow, reject, abnegate",
		"derivation" : "perhaps from G1 (Α) (as a negative particle) and the middle voice of G4483 (ῥέω)",
		"translit" : "arnéomai",
		"lemma" : "ἀρνέομαι",
		"kjv" : "deny, refuse"
	},
	"G3529" : {
		"def" : "conquest (abstractly), i.e. (figuratively) the means of success",
		"derivation" : "apparently a primary word",
		"lemma" : "νίκη",
		"translit" : "níkē",
		"kjv" : "victory"
	},
	"G459" : {
		"def" : "lawless, i.e. (negatively) not subject to (the Jewish) law; (by implication, a Gentile), or (positively) wicked",
		"derivation" : "from G1 (Α) (as a negative particle) and G3551 (νόμος)",
		"translit" : "ánomos",
		"lemma" : "ἄνομος",
		"kjv" : "without law, lawless, transgressor, unlawful, wicked"
	},
	"G2269" : {
		"derivation" : "of Hebrew origin (H6215)",
		"def" : "Esau, an Edomite",
		"kjv" : "Esau",
		"lemma" : "Ἠσαῦ",
		"translit" : "Ēsaû"
	},
	"G1021" : {
		"def" : "slow; figuratively, dull",
		"derivation" : "of uncertain affinity",
		"lemma" : "βραδύς",
		"translit" : "bradýs",
		"kjv" : "slow"
	},
	"G1911" : {
		"translit" : "epibállō",
		"lemma" : "ἐπιβάλλω",
		"kjv" : "beat into, cast (up-)on, fall, lay (on), put (unto), stretch forth, think on",
		"def" : "to throw upon (literal or figurative, transitive or reflexive; usually with more or less force); specially (with G1438 (ἑαυτοῦ) implied) to reflect; impersonally, to belong to",
		"derivation" : "from G1909 (ἐπί) and G906 (βάλλω)"
	},
	"G3514" : {
		"translit" : "nḗthō",
		"lemma" : "νήθω",
		"kjv" : "spin",
		"def" : "to spin",
		"derivation" : "from (of like meaning)"
	},
	"G4044" : {
		"kjv" : "pierce through",
		"translit" : "peripeírō",
		"lemma" : "περιπείρω",
		"derivation" : "from G4012 (περί) and the base of G4008 (πέραν)",
		"def" : "to penetrate entirely, i.e. transfix (figuratively)"
	},
	"G3592" : {
		"lemma" : "ὅδε",
		"translit" : "hóde",
		"kjv" : "he, she, such, these, thus",
		"def" : "the same, i.e. this or that one (plural these or those); often used as a personal pronoun",
		"derivation" : "from G3588 (ὁ) and G1161 (δέ)"
	},
	"G236" : {
		"def" : "to make different",
		"derivation" : "from G243 (ἄλλος)",
		"lemma" : "ἀλλάσσω",
		"translit" : "allássō",
		"kjv" : "change"
	},
	"G2112" : {
		"translit" : "euthéōs",
		"lemma" : "εὐθέως",
		"kjv" : "anon, as soon as, forthwith, immediately, shortly, straightway",
		"def" : "directly, i.e. at once or soon",
		"derivation" : "adverb from G2117 (εὐθύς)"
	},
	"G3960" : {
		"kjv" : "smite, strike",
		"lemma" : "πατάσσω",
		"translit" : "patássō",
		"derivation" : "probably prolongation from G3817 (παίω)",
		"def" : "to knock (gently or with a weapon or fatally)"
	},
	"G367" : {
		"derivation" : "of Hebrew origin (H2608)",
		"def" : "Ananias, the name of three Israelites",
		"kjv" : "Ananias",
		"lemma" : "Ἀνανίας",
		"translit" : "Ananías"
	},
	"G2691" : {
		"def" : "to become voluptuous against",
		"derivation" : "from G2596 (κατά) and G4763 (στρηνιάω)",
		"lemma" : "καταστρηνιάω",
		"translit" : "katastrēniáō",
		"kjv" : "begin to wax wanton against"
	},
	"G2355" : {
		"derivation" : "from the base of G2360 (θροέω)",
		"def" : "wailing",
		"kjv" : "lamentation",
		"lemma" : "θρῆνος",
		"translit" : "thrēnos"
	},
	"G3368" : {
		"lemma" : "μηδέποτε",
		"translit" : "mēdépote",
		"kjv" : "never",
		"def" : "not even ever",
		"derivation" : "from G3366 (μηδέ) and G4218 (ποτέ)"
	},
	"G2083" : {
		"def" : "a comrade",
		"derivation" : "from (a clansman)",
		"lemma" : "ἑταῖρος",
		"translit" : "hetaîros",
		"kjv" : "fellow, friend"
	},
	"G3937" : {
		"kjv" : "Parmenas",
		"translit" : "Parmenâs",
		"lemma" : "Παρμενᾶς",
		"derivation" : "probably by contraction for (a derivative of a compound of G3844 (παρά) and G3306 (μένω))",
		"def" : "constant; Parmenas, a Christian"
	},
	"G4210" : {
		"def" : "purpureal, i.e. bluish red",
		"derivation" : "from G4209 (πορφύρα)",
		"lemma" : "πορφυροῦς",
		"translit" : "porphyroûs",
		"kjv" : "purple"
	},
	"G3765" : {
		"derivation" : "from G3756 (οὐ) and G2089 (ἔτι)",
		"def" : "not yet, no longer",
		"kjv" : "after that (not), (not) any more, henceforth (hereafter) not, no longer (more), not as yet (now), now no more (not), yet (not)",
		"translit" : "oukéti",
		"lemma" : "οὐκέτι"
	},
	"G5130" : {
		"kjv" : "such, their, these (things), they, this sort, those",
		"lemma" : "τούτων",
		"translit" : "toútōn",
		"derivation" : "genitive case plural masculine or neuter of G3778 (οὗτος)",
		"def" : "of (from or concerning) these (persons or things)"
	},
	"G681" : {
		"def" : "properly, to fasten to, i.e. (specially) to set on fire",
		"derivation" : "a primary verb",
		"translit" : "háptō",
		"lemma" : "ἅπτω",
		"kjv" : "kindle, light"
	},
	"G3988" : {
		"derivation" : "from a presumed derivative of G3982 (πείθω)",
		"def" : "persuadableness, i.e. credulity",
		"kjv" : "persuasion",
		"lemma" : "πεισμονή",
		"translit" : "peismonḗ"
	},
	"G623" : {
		"lemma" : "Ἀπολλύων",
		"translit" : "Apollýōn",
		"kjv" : "Apollyon",
		"def" : "a destroyer (i.e. Satan)",
		"derivation" : "active participle of G622 (ἀπόλλυμι)"
	},
	"G3577" : {
		"def" : "the back",
		"derivation" : "of uncertain affinity",
		"lemma" : "νῶτος",
		"translit" : "nōtos",
		"kjv" : "back"
	},
	"G88" : {
		"def" : "unintermitted, i.e. permanent",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of a compound of G1223 (διά) and G3007 (λείπω)",
		"translit" : "adiáleiptos",
		"lemma" : "ἀδιάλειπτος",
		"kjv" : "without ceasing, continual"
	},
	"G1749" : {
		"translit" : "énedron",
		"lemma" : "ἔνεδρον",
		"kjv" : "lying in wait",
		"def" : "an ambush, i.e. (figuratively) murderous design",
		"derivation" : "neuter of the same as G1747 (ἐνέδρα)"
	},
	"G5167" : {
		"kjv" : "turtle-dove",
		"translit" : "trygṓn",
		"lemma" : "τρυγών",
		"derivation" : "from (to murmur",
		"def" : "akin to G5149 (τρίζω), but denoting a duller sound); a turtle-dove (as cooing)"
	},
	"G180" : {
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G2664 (καταπαύω)",
		"def" : "unrefraining",
		"kjv" : "that cannot cease",
		"translit" : "akatápaustos",
		"lemma" : "ἀκατάπαυστος"
	},
	"G4621" : {
		"derivation" : "of uncertain derivation",
		"def" : "grain, especially wheat",
		"kjv" : "corn, wheat",
		"lemma" : "σῖτος",
		"translit" : "sîtos"
	},
	"G5060" : {
		"kjv" : "Tertius",
		"translit" : "Tértios",
		"lemma" : "Τέρτιος",
		"derivation" : "of Latin origin",
		"def" : "third; Tertius, a Christian"
	},
	"G3474" : {
		"kjv" : "fool(-ish, X -ishness)",
		"lemma" : "μωρός",
		"translit" : "mōrós",
		"derivation" : "probably from the base of G3466 (μυστήριον)",
		"def" : "dull or stupid (as if shut up), i.e. heedless, (morally) blockhead, (apparently) absurd"
	},
	"G1187" : {
		"lemma" : "δεξιολάβος",
		"translit" : "dexiolábos",
		"kjv" : "spearman",
		"def" : "a guardsman (as if taking the right) or light-armed soldier",
		"derivation" : "from G1188 (δεξιός) and G2983 (λαμβάνω)"
	},
	"G1027" : {
		"kjv" : "thunder(-ing)",
		"lemma" : "βροντή",
		"translit" : "brontḗ",
		"derivation" : "akin to (to roar)",
		"def" : "thunder"
	},
	"G2578" : {
		"derivation" : "apparently a primary verb",
		"def" : "to bend",
		"kjv" : "bow",
		"lemma" : "κάμπτω",
		"translit" : "kámptō"
	},
	"G467" : {
		"lemma" : "ἀνταποδίδωμι",
		"translit" : "antapodídōmi",
		"kjv" : "recompense, render, repay",
		"def" : "to requite (good or evil)",
		"derivation" : "from G473 (ἀντί) and G591 (ἀποδίδωμι)"
	},
	"G725" : {
		"kjv" : "robbery",
		"translit" : "harpagmós",
		"lemma" : "ἁρπαγμός",
		"derivation" : "from G726 (ἁρπάζω)",
		"def" : "plunder (properly concrete)"
	},
	"G3412" : {
		"lemma" : "Μιτυλήνη",
		"translit" : "Mitylḗnē",
		"kjv" : "Mitylene",
		"def" : "Mitylene (or Mytilene), a town on the island of Lesbos",
		"derivation" : "for (abounding in shellfish)"
	},
	"G5463" : {
		"def" : "to be \"cheer\"ful, i.e. calmly happy or well-off; impersonally, especially as salutation (on meeting or parting), be well",
		"derivation" : "a primary verb",
		"lemma" : "χαίρω",
		"translit" : "chaírō",
		"kjv" : "farewell, be glad, God speed, greeting, hall, joy(- fully), rejoice"
	},
	"G2645" : {
		"def" : "left down (behind), i.e remaining (plural the rest)",
		"derivation" : "from G2596 (κατά) and G3062 (λοιποί)",
		"translit" : "katáloipos",
		"lemma" : "κατάλοιπος",
		"kjv" : "residue"
	},
	"G5220" : {
		"def" : "in subjection under a man, i.e. a married woman",
		"derivation" : "from G5259 (ὑπό) and G435 (ἀνήρ)",
		"lemma" : "ὕπανδρος",
		"translit" : "hýpandros",
		"kjv" : "which hath an husband"
	},
	"G484" : {
		"derivation" : "from G482 (ἀντιλαμβάνομαι)",
		"def" : "relief",
		"kjv" : "help",
		"lemma" : "ἀντίληψις",
		"translit" : "antílēpsis"
	},
	"G5571" : {
		"def" : "untrue, i.e. erroneous, deceitful, wicked",
		"derivation" : "from G5574 (ψεύδομαι)",
		"lemma" : "ψευδής",
		"translit" : "pseudḗs",
		"kjv" : "false, liar"
	},
	"G1612" : {
		"def" : "to pervert (figuratively)",
		"derivation" : "from G1537 (ἐκ) and G4762 (στρέφω)",
		"lemma" : "ἐκστρέφω",
		"translit" : "ekstréphō",
		"kjv" : "subvert"
	},
	"G492" : {
		"derivation" : "from G473 (ἀντί) and G3928 (παρέρχομαι)",
		"def" : "to go along opposite",
		"kjv" : "pass by on the other side",
		"lemma" : "ἀντιπαρέρχομαι",
		"translit" : "antiparérchomai"
	},
	"G4578" : {
		"kjv" : "earthquake, tempest",
		"lemma" : "σεισμός",
		"translit" : "seismós",
		"derivation" : "from G4579 (σείω)",
		"def" : "a commotion, i.e. (of the air) a gale, (of the ground) an earthquake"
	},
	"G2451" : {
		"kjv" : "Jewish",
		"lemma" : "Ἰουδαϊκός",
		"translit" : "Ioudaïkós",
		"derivation" : "from G2453 (Ἰουδαῖος)",
		"def" : "Judaïc, i.e. resembling a Judæan"
	},
	"G3727" : {
		"kjv" : "oath",
		"lemma" : "ὅρκος",
		"translit" : "hórkos",
		"derivation" : "from (a fence",
		"def" : "perhaps akin to G3725 (ὅριον)); a limit, i.e. (sacred) restraint (specially, an oath)"
	},
	"G1375" : {
		"translit" : "diōgmós",
		"lemma" : "διωγμός",
		"kjv" : "persecution",
		"def" : "persecution",
		"derivation" : "from G1377 (διώκω)"
	},
	"G2447" : {
		"translit" : "iós",
		"lemma" : "ἰός",
		"kjv" : "poison, rust",
		"def" : "rust (as if emitted by metals); also venom (as emitted by serpents)",
		"derivation" : "perhaps from (to go) or (to send)"
	},
	"G208" : {
		"def" : "to invalidate",
		"derivation" : "from G1 (Α) (as a negative particle) and G2964 (κυρόω)",
		"translit" : "akyróō",
		"lemma" : "ἀκυρόω",
		"kjv" : "disannul, make of none effect"
	},
	"G1793" : {
		"derivation" : "from G1722 (ἐν) and G5177 (τυγχάνω)",
		"def" : "to chance upon, i.e. (by implication) confer with; by extension to entreat (in favor or against)",
		"kjv" : "deal with, make intercession",
		"translit" : "entynchánō",
		"lemma" : "ἐντυγχάνω"
	},
	"G814" : {
		"translit" : "atáktōs",
		"lemma" : "ἀτάκτως",
		"kjv" : "disorderly",
		"derivation" : "adverb from G813 (ἄτακτος), irregularly (morally)"
	},
	"G5600" : {
		"derivation" : "the subjunctive of G1510 (εἰμί)",
		"def" : "(may, might, can, could, would, should, must, etc.; also with G1487 (εἰ) and its comparative, as well as with other particles) be",
		"kjv" : "+ appear, are, (may, might, should) be, X have, is, + pass the flower of her age, should stand, were",
		"translit" : "ō",
		"lemma" : "ὦ"
	},
	"G4263" : {
		"derivation" : "probably neuter of a presumed derivative of G4260 (προβαίνω)",
		"def" : "something that walks forward (a quadruped), i.e. (specially), a sheep (literally or figuratively)",
		"kjv" : "sheep(-fold)",
		"lemma" : "πρόβατον",
		"translit" : "próbaton"
	},
	"G788" : {
		"def" : "more nearly, i.e. very near",
		"derivation" : "neuter comparative of the base of G1451 (ἐγγύς)",
		"lemma" : "ἆσσον",
		"translit" : "âsson",
		"kjv" : "close"
	},
	"G4498" : {
		"def" : "Rode, a servant girl",
		"derivation" : "probably for rhode (a rose)",
		"lemma" : "Ῥόδη",
		"translit" : "Rhódē",
		"kjv" : "Rhoda"
	},
	"G4391" : {
		"def" : "to exist before, i.e. (adverbially) to be or do something previously",
		"derivation" : "from G4253 (πρό) and G5225 (ὑπάρχω)",
		"lemma" : "προϋπάρχω",
		"translit" : "proÿpárchō",
		"kjv" : "+ be before(-time)"
	},
	"G2723" : {
		"translit" : "katēgoréō",
		"lemma" : "κατηγορέω",
		"kjv" : "accuse, object",
		"def" : "to be a plaintiff, i.e. to charge with some offence",
		"derivation" : "from G2725 (κατήγορος)"
	},
	"G1175" : {
		"kjv" : "superstition",
		"lemma" : "δεισιδαιμονία",
		"translit" : "deisidaimonía",
		"derivation" : "from the same as G1174 (δεισιδαιμονέστερος)",
		"def" : "religion"
	},
	"G3452" : {
		"kjv" : "marrow",
		"lemma" : "μυελός",
		"translit" : "myelós",
		"derivation" : "perhaps a primary word",
		"def" : "the marrow"
	},
	"G2161" : {
		"def" : "well- fated, i.e. fortunate; Eutychus, a young man",
		"derivation" : "from G2095 (εὖ) and a derivative of G5177 (τυγχάνω)",
		"translit" : "Eútychos",
		"lemma" : "Εὔτυχος",
		"kjv" : "Eutychus"
	},
	"G2374" : {
		"kjv" : "door, gate",
		"translit" : "thýra",
		"lemma" : "θύρα",
		"derivation" : "apparently a primary word (compare \"door\")",
		"def" : "a portal or entrance (the opening or the closure, literally or figuratively)"
	},
	"G3961" : {
		"derivation" : "from a derivative probably of G3817 (παίω) (meaning a \"path\")",
		"def" : "to trample (literally or figuratively)",
		"kjv" : "tread (down, under foot)",
		"lemma" : "πατέω",
		"translit" : "patéō"
	},
	"G677" : {
		"def" : "actively, inoffensive, i.e. not leading into sin; passively, faultless, i.e. not led into sin",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G4350 (προσκόπτω)",
		"lemma" : "ἀπρόσκοπος",
		"translit" : "apróskopos",
		"kjv" : "none (void of, without) offence"
	},
	"G321" : {
		"kjv" : "bring (again, forth, up again), depart, launch (forth), lead (up), loose, offer, sail, set forth, take up",
		"translit" : "anágō",
		"lemma" : "ἀνάγω",
		"derivation" : "from G303 (ἀνά) and G71 (ἄγω)",
		"def" : "to lead up; by extension to bring out; specially, to sail away"
	},
	"G1316" : {
		"kjv" : "depart",
		"lemma" : "διαχωρίζομαι",
		"translit" : "diachōrízomai",
		"derivation" : "from G1223 (διά) and the middle voice of G5563 (χωρίζω)",
		"def" : "to remove (oneself) wholly, i.e. retire"
	},
	"G4537" : {
		"derivation" : "from G4536 (σάλπιγξ)",
		"def" : "to trumpet, i.e. sound a blast (literally or figuratively)",
		"kjv" : "(which are yet to) sound (a trumpet)",
		"lemma" : "σαλπίζω",
		"translit" : "salpízō"
	},
	"G836" : {
		"def" : "a flute (as blown)",
		"derivation" : "from the same as G109 (ἀήρ)",
		"lemma" : "αὐλός",
		"translit" : "aulós",
		"kjv" : "pipe"
	},
	"G3674" : {
		"kjv" : "together",
		"lemma" : "ὁμοῦ",
		"translit" : "homoû",
		"derivation" : "genitive case of (the same",
		"def" : "akin to G260 (ἅμα)) as adverb; at the same place or time"
	},
	"G3091" : {
		"lemma" : "Λώτ",
		"translit" : "Lṓt",
		"kjv" : "Lot",
		"def" : "Lot, a patriarch",
		"derivation" : "of Hebrew origin (H3876)"
	},
	"G2889" : {
		"kjv" : "adorning, world",
		"translit" : "kósmos",
		"lemma" : "κόσμος",
		"derivation" : "probably from the base of G2865 (κομίζω)",
		"def" : "orderly arrangement, i.e. decoration; by implication, the world (in a wide or narrow sense, including its inhabitants, literally or figuratively (morally))"
	},
	"G5535" : {
		"def" : "to make (i.e. have) necessity, i.e. be in want of",
		"derivation" : "from G5532 (χρεία)",
		"translit" : "chrḗizō",
		"lemma" : "χρῄζω",
		"kjv" : "(have) need"
	},
	"G5321" : {
		"def" : "exhibition, i.e. (figuratively) expression, (by extension) a bestowment",
		"derivation" : "from G5319 (φανερόω)",
		"lemma" : "φανέρωσις",
		"translit" : "phanérōsis",
		"kjv" : "manifestation"
	},
	"G2193" : {
		"lemma" : "ἕως",
		"translit" : "héōs",
		"kjv" : "even (until, unto), (as) far (as), how long, (un-)til(-l), (hither-, un-, up) to, while(-s)",
		"def" : "a conjunction, preposition and adverb of continuance, until (of time and place)",
		"derivation" : "of uncertain affinity"
	},
	"G113" : {
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G5087 (τίθημι) (in the sense of enacting)",
		"def" : "lawless, i.e. (by implication) criminal",
		"kjv" : "wicked",
		"translit" : "áthesmos",
		"lemma" : "ἄθεσμος"
	},
	"G5110" : {
		"derivation" : "from the base of G5088 (τίκτω)",
		"def" : "interest on money loaned (as a produce)",
		"kjv" : "usury",
		"lemma" : "τόκος",
		"translit" : "tókos"
	},
	"G921" : {
		"kjv" : "Barnabas",
		"lemma" : "Βαρνάβας",
		"translit" : "Barnábas",
		"derivation" : "of Chaldee origin (H1247 and H5029)",
		"def" : "son of Nabas (i.e. prophecy); Barnabas, an Israelite"
	},
	"G1491" : {
		"derivation" : "from G1492 (εἴδω)",
		"def" : "a view, i.e. form (literally or figuratively)",
		"kjv" : "appearance, fashion, shape, sight",
		"lemma" : "εἶδος",
		"translit" : "eîdos"
	},
	"G2403" : {
		"kjv" : "Jezabel",
		"lemma" : "Ἰεζαβήλ",
		"translit" : "Iezabḗl",
		"derivation" : "of Hebrew origin (H348)",
		"def" : "Jezabel (i.e. Jezebel), a Tyrian woman (used as a synonym of a termagant or false teacher)"
	},
	"G3444" : {
		"derivation" : "perhaps from the base of G3313 (μέρος) (through the idea of adjustment of parts)",
		"def" : "shape; figuratively, nature",
		"kjv" : "form",
		"translit" : "morphḗ",
		"lemma" : "μορφή"
	},
	"G187" : {
		"kjv" : "be fully ripe",
		"translit" : "akmázō",
		"lemma" : "ἀκμάζω",
		"derivation" : "from the same as G188 (ἀκμήν)",
		"def" : "to make a point, i.e. (figuratively) mature"
	},
	"G2057" : {
		"derivation" : "probably from G2060 (Ἑρμῆς)",
		"def" : "Hermas, a Christian",
		"kjv" : "Hermas",
		"lemma" : "Ἑρμᾶς",
		"translit" : "Hermâs"
	},
	"G2148" : {
		"def" : "a storm from the East (or southeast), i.e. (in modern phrase) a Levanter",
		"derivation" : "from (the east wind) and G2830 (κλύδων)",
		"lemma" : "Εὐροκλύδων",
		"translit" : "Euroklýdōn",
		"kjv" : "Euroklydon"
	},
	"G5338" : {
		"kjv" : "light",
		"lemma" : "φέγγος",
		"translit" : "phéngos",
		"derivation" : "probably akin to the base of G5457 (φῶς) (compare G5350 (φθέγγομαι))",
		"def" : "brilliancy"
	},
	"G1058" : {
		"def" : "Gallion (i.e. Gallio), a Roman officer",
		"derivation" : "of Latin origin",
		"translit" : "Gallíōn",
		"lemma" : "Γαλλίων",
		"kjv" : "Gallio"
	},
	"G1309" : {
		"kjv" : "escape",
		"lemma" : "διαφεύγω",
		"translit" : "diapheúgō",
		"derivation" : "from G1223 (διά) and G5343 (φεύγω)",
		"def" : "to flee through, i.e. escape"
	},
	"G3188" : {
		"derivation" : "neuter of G3189 (μέλας) as noun",
		"def" : "ink",
		"kjv" : "ink",
		"lemma" : "μέλαν",
		"translit" : "mélan"
	},
	"G2859" : {
		"kjv" : "bosom, creek",
		"lemma" : "κόλπος",
		"translit" : "kólpos",
		"derivation" : "apparently a primary word",
		"def" : "the bosom; by analogy, a bay"
	},
	"G5539" : {
		"derivation" : "from G5540 (χρῆσις)",
		"def" : "serviceable",
		"kjv" : "profit",
		"lemma" : "χρήσιμος",
		"translit" : "chrḗsimos"
	},
	"G1702" : {
		"derivation" : "from G1722 (ἐν) and G3815 (παίζω)",
		"def" : "to jeer at, i.e. deride",
		"kjv" : "mock",
		"translit" : "empaízō",
		"lemma" : "ἐμπαίζω"
	},
	"G2090" : {
		"kjv" : "prepare, provide, make ready",
		"lemma" : "ἑτοιμάζω",
		"translit" : "hetoimázō",
		"derivation" : "from G2092 (ἕτοιμος)",
		"def" : "to prepare"
	},
	"G4797" : {
		"def" : "to commingle promiscuously, i.e. (figuratively) to throw (an assembly) into disorder, to perplex (the mind)",
		"derivation" : "from G4862 (σύν) and (to pour) or its alternate",
		"translit" : "synchéō",
		"lemma" : "συγχέω",
		"kjv" : "confound, confuse, stir up, be in an uproar"
	},
	"G3194" : {
		"derivation" : "of uncertain origin",
		"def" : "Melita, an island in the Mediterranean",
		"kjv" : "Melita",
		"translit" : "Melítē",
		"lemma" : "Μελίτη"
	},
	"G3996" : {
		"translit" : "penthéō",
		"lemma" : "πενθέω",
		"kjv" : "mourn, (be-)wail",
		"def" : "to grieve (the feeling or the act)",
		"derivation" : "from G3997 (πένθος)"
	},
	"G4127" : {
		"derivation" : "from G4141 (πλήσσω)",
		"def" : "a stroke; by implication, a wound; figuratively, a calamity",
		"kjv" : "plague, stripe, wound(-ed)",
		"translit" : "plēgḗ",
		"lemma" : "πληγή"
	},
	"G2742" : {
		"derivation" : "from G2741 (καυσόω)",
		"def" : "a glare",
		"kjv" : "(burning) heat",
		"lemma" : "καύσων",
		"translit" : "kaúsōn"
	},
	"G1680" : {
		"def" : "expectation (abstractly or concretely) or confidence",
		"derivation" : "from a primary (to anticipate, usually with pleasure)",
		"translit" : "elpís",
		"lemma" : "ἐλπίς",
		"kjv" : "faith, hope"
	},
	"G4362" : {
		"def" : "to fasten to, i.e. (specially), to impale (on a cross)",
		"derivation" : "from G4314 (πρός) and G4078 (πήγνυμι)",
		"lemma" : "προσπήγνυμι",
		"translit" : "prospḗgnymi",
		"kjv" : "crucify"
	},
	"G1751" : {
		"lemma" : "ἔνειμι",
		"translit" : "éneimi",
		"kjv" : "such things as … have",
		"def" : "to be within (neuter participle plural)",
		"derivation" : "from G1772 (ἔννομος) and G1510 (εἰμί)"
	},
	"G1968" : {
		"kjv" : "fall into (on, upon) lie on, press upon",
		"lemma" : "ἐπιπίπτω",
		"translit" : "epipíptō",
		"derivation" : "from G1909 (ἐπί) and G4098 (πίπτω)",
		"def" : "to embrace (with affection) or seize (with more or less violence; literally or figuratively)"
	},
	"G3001" : {
		"derivation" : "from (to dig)",
		"def" : "a vegetable",
		"kjv" : "herb",
		"translit" : "láchanon",
		"lemma" : "λάχανον"
	},
	"G336" : {
		"kjv" : "death",
		"translit" : "anaíresis",
		"lemma" : "ἀναίρεσις",
		"derivation" : "from G337 (ἀναιρέω)",
		"def" : "(the act of) killing"
	},
	"G1644" : {
		"derivation" : "from G1645 (ἐλαφρός)",
		"def" : "levity (figuratively), i.e. fickleness",
		"kjv" : "lightness",
		"lemma" : "ἐλαφρία",
		"translit" : "elaphría"
	},
	"G3723" : {
		"kjv" : "plain, right(-ly)",
		"translit" : "orthōs",
		"lemma" : "ὀρθῶς",
		"derivation" : "adverb from G3717 (ὀρθός)",
		"def" : "in a straight manner, i.e. (figuratively) correctly (also morally)"
	},
	"G2450" : {
		"lemma" : "Ἰουδαΐζω",
		"translit" : "Ioudaḯzō",
		"kjv" : "live as the Jews",
		"def" : "to become a Judæan, i.e. \"Judaize\"",
		"derivation" : "from G2453 (Ἰουδαῖος)"
	},
	"G4125" : {
		"derivation" : "of uncertain affinity",
		"def" : "a rib, i.e. (by extension) side",
		"kjv" : "side",
		"translit" : "pleurá",
		"lemma" : "πλευρά"
	},
	"G1106" : {
		"derivation" : "from G1097 (γινώσκω)",
		"def" : "cognition, i.e. (subjectively) opinion, or (objectively) resolve (counsel, consent, etc.)",
		"kjv" : "advice, + agree, judgment, mind, purpose, will",
		"lemma" : "γνώμη",
		"translit" : "gnṓmē"
	},
	"G3604" : {
		"kjv" : "Ozias",
		"lemma" : "Ὀζίας",
		"translit" : "Ozías",
		"derivation" : "of Hebrew origin (H5818)",
		"def" : "Ozias (i.e. Uzzijah), an Israelite"
	},
	"G126" : {
		"kjv" : "eternal, everlasting",
		"lemma" : "ἀΐδιος",
		"translit" : "aḯdios",
		"derivation" : "from G104 (ἀεί)",
		"def" : "everduring (forward and backward, or forward only)"
	},
	"G3377" : {
		"lemma" : "μηνύω",
		"translit" : "mēnýō",
		"kjv" : "shew, tell",
		"def" : "to disclose (through the idea of mental effort and thus calling to mind), i.e. report, declare, intimate",
		"derivation" : "probably from the same base as G3145 (μασσάομαι) and G3415 (μνάομαι) (i.e. , to strive)"
	},
	"G5161" : {
		"def" : "nutritive; Trophimus, a Christian",
		"derivation" : "from G5160 (τροφή)",
		"lemma" : "Τρόφιμος",
		"translit" : "Tróphimos",
		"kjv" : "Trophimus"
	},
	"G4921" : {
		"lemma" : "συνιστάω",
		"translit" : "synistáō",
		"kjv" : "approve, commend, consist, make, stand (with)",
		"def" : "to set together, i.e. (by implication) to introduce (favorably), or (figuratively) to exhibit; intransitively, to stand near, or (figuratively) to constitute",
		"derivation" : "from G4862 (σύν) and G2476 (ἵστημι) (including its collateral forms)"
	},
	"G3971" : {
		"def" : "paternal, i.e. hereditary",
		"derivation" : "from G3962 (πατήρ)",
		"lemma" : "πατρῷος",
		"translit" : "patrōios",
		"kjv" : "of fathers"
	},
	"G3656" : {
		"lemma" : "ὁμιλέω",
		"translit" : "homiléō",
		"kjv" : "commune, talk",
		"def" : "to be in company with, i.e. (by implication) to converse",
		"derivation" : "from G3658 (ὅμιλος)"
	},
	"G2620" : {
		"derivation" : "from G2596 (κατά) and G2744 (καυχάομαι)",
		"def" : "to exult against (i.e. over)",
		"kjv" : "boast (against), glory, rejoice against",
		"translit" : "katakaucháomai",
		"lemma" : "κατακαυχάομαι"
	},
	"G529" : {
		"def" : "a (friendly) encounter",
		"derivation" : "from G528 (ἀπαντάω)",
		"lemma" : "ἀπάντησις",
		"translit" : "apántēsis",
		"kjv" : "meet"
	},
	"G2365" : {
		"def" : "a daughterling",
		"derivation" : "from G2364 (θυγάτηρ)",
		"lemma" : "θυγάτριον",
		"translit" : "thygátrion",
		"kjv" : "little (young) daughter"
	},
	"G2402" : {
		"def" : "perspiration",
		"derivation" : "a strengthened form of a primary idos (sweat)",
		"translit" : "hidrṓs",
		"lemma" : "ἱδρώς",
		"kjv" : "sweat"
	},
	"G1240" : {
		"def" : "a successor in office",
		"derivation" : "from G1237 (διαδέχομαι)",
		"translit" : "diádochos",
		"lemma" : "διάδοχος",
		"kjv" : "room"
	},
	"G661" : {
		"kjv" : "repay",
		"translit" : "apotínō",
		"lemma" : "ἀποτίνω",
		"derivation" : "from G575 (ἀπό) and G5099 (τίνω)",
		"def" : "to pay in full"
	},
	"G730" : {
		"translit" : "árrhēn",
		"lemma" : "ἄῤῥην",
		"kjv" : "male, man",
		"def" : "male (as stronger for lifting)",
		"derivation" : "probably from G142 (αἴρω)"
	},
	"G4798" : {
		"lemma" : "συγχράομαι",
		"translit" : "synchráomai",
		"kjv" : "have dealings with",
		"def" : "to use jointly, i.e. (by implication) to hold intercourse in common",
		"derivation" : "from G4862 (σύν) and G5530 (χράομαι)"
	},
	"G3308" : {
		"def" : "solicitude",
		"derivation" : "from G3307 (μερίζω) (through the idea of distraction)",
		"lemma" : "μέριμνα",
		"translit" : "mérimna",
		"kjv" : "care"
	},
	"G870" : {
		"translit" : "aphóbōs",
		"lemma" : "ἀφόβως",
		"kjv" : "without fear",
		"def" : "fearlessly",
		"derivation" : "adverb from a compound of G1 (Α) (as a negative particle) and G5401 (φόβος)"
	},
	"G2300" : {
		"derivation" : "a prolonged form of a primary verb",
		"def" : "to look closely at, i.e. (by implication) perceive (literally or figuratively); by extension to visit",
		"kjv" : "behold, look (upon), see",
		"translit" : "theáomai",
		"lemma" : "θεάομαι"
	},
	"G3586" : {
		"lemma" : "ξύλον",
		"translit" : "xýlon",
		"kjv" : "staff, stocks, tree, wood",
		"def" : "timber (as fuel or material); by implication, a stick, club or tree or other wooden article or substance",
		"derivation" : "from another form of the base of G3582 (ξέστης)"
	},
	"G2175" : {
		"derivation" : "from a compound of G2095 (εὖ) and a derivative of G3605 (ὄζω)",
		"def" : "good-scentedness, i.e. fragrance",
		"kjv" : "sweet savour (smell, -smelling)",
		"lemma" : "εὐωδία",
		"translit" : "euōdía"
	},
	"G617" : {
		"derivation" : "from G575 (ἀπό) and G2947 (κυλιόω)",
		"def" : "to roll away",
		"kjv" : "roll away (back)",
		"translit" : "apokylíō",
		"lemma" : "ἀποκυλίω"
	},
	"G4521" : {
		"def" : "the Sabbath (i.e. Shabbath), or day of weekly repose from secular avocations (also the observance or institution itself); by extension, a se'nnight, i.e. the interval between two Sabbaths; likewise the plural in all the above applications",
		"derivation" : "of Hebrew origin (H7676)",
		"lemma" : "σάββατον",
		"translit" : "sábbaton",
		"kjv" : "sabbath (day), week"
	},
	"G4530" : {
		"derivation" : "probably from the same as G4531 (σαλεύω)",
		"def" : "Salim, a place in Palestine",
		"kjv" : "Salim",
		"lemma" : "Σαλείμ",
		"translit" : "Saleím"
	},
	"G3998" : {
		"kjv" : "poor",
		"translit" : "penichrós",
		"lemma" : "πενιχρός",
		"derivation" : "prolongation from the base of G3993 (πένης)",
		"def" : "necessitous"
	},
	"G1049" : {
		"def" : "a treasure-house, i.e. a court in the temple for the collection-boxes",
		"derivation" : "from G1047 (γάζα) and G5438 (φυλακή)",
		"lemma" : "γαζοφυλάκιον",
		"translit" : "gazophylákion",
		"kjv" : "treasury"
	},
	"G1995" : {
		"kjv" : "conversion",
		"translit" : "epistrophḗ",
		"lemma" : "ἐπιστροφή",
		"derivation" : "from G1994 (ἐπιστρέφω)",
		"def" : "reversion, i.e. morally, revolution"
	},
	"G4407" : {
		"def" : "pertaining to the dawn, i.e. matutinal",
		"derivation" : "from G4404 (πρωΐ)",
		"lemma" : "πρωϊνός",
		"translit" : "prōïnós",
		"kjv" : "morning"
	},
	"G3926" : {
		"def" : "to harass further, i.e. annoy",
		"derivation" : "from G3844 (παρά) and G1776 (ἐνοχλέω)",
		"translit" : "parenochléō",
		"lemma" : "παρενοχλέω",
		"kjv" : "trouble"
	},
	"G1211" : {
		"def" : "a particle of emphasis or explicitness; now, then, etc.",
		"derivation" : "probably akin to G1161 (δέ)",
		"translit" : "dḗ",
		"lemma" : "δή",
		"kjv" : "also, and, doubtless, now, therefore"
	},
	"G2831" : {
		"lemma" : "κλυδωνίζομαι",
		"translit" : "klydōnízomai",
		"kjv" : "toss to and fro",
		"def" : "to surge, i.e. (figuratively) to fluctuate",
		"derivation" : "middle voice from G2830 (κλύδων)"
	},
	"G4272" : {
		"kjv" : "first give",
		"translit" : "prodídōmi",
		"lemma" : "προδίδωμι",
		"derivation" : "from G4253 (πρό) and G1325 (δίδωμι)",
		"def" : "to give before the other party has given"
	},
	"G5138" : {
		"derivation" : "perhaps strengthened from the base of G4486 (ῥήγνυμι) (as if jagged by rents)",
		"def" : "uneven, rocky (reefy)",
		"kjv" : "rock, rough",
		"lemma" : "τραχύς",
		"translit" : "trachýs"
	},
	"G2089" : {
		"def" : "\"yet,\" still (of time or degree)",
		"derivation" : "perhaps akin to G2094 (ἔτος)",
		"translit" : "éti",
		"lemma" : "ἔτι",
		"kjv" : "after that, also, ever, (any) further, (t-)henceforth (more), hereafter, (any) longer, (any) more(-one), now, still, yet"
	},
	"G3879" : {
		"kjv" : "look (into), stoop down",
		"lemma" : "παρακύπτω",
		"translit" : "parakýptō",
		"derivation" : "from G3844 (παρά) and G2955 (κύπτω)",
		"def" : "to bend beside, i.e. lean over (so as to peer within)"
	},
	"G5505" : {
		"def" : "one thousand (\"chiliad\")",
		"derivation" : "from G5507 (χίλιοι)",
		"translit" : "chiliás",
		"lemma" : "χιλιάς",
		"kjv" : "thousand"
	},
	"G188" : {
		"translit" : "akmḗn",
		"lemma" : "ἀκμήν",
		"kjv" : "yet",
		"def" : "adverbially, just now, i.e. still",
		"derivation" : "accusative case of a noun (\"acme\") akin to (a point) and meaning the same"
	},
	"G821" : {
		"derivation" : "from G820 (ἄτιμος)",
		"def" : "used like G818 (ἀτιμάζω), to maltreat",
		"kjv" : "handle shamefully",
		"lemma" : "ἀτιμόω",
		"translit" : "atimóō"
	},
	"G951" : {
		"derivation" : "from G950 (βεβαιόω)",
		"def" : "stabiliment",
		"kjv" : "confirmation",
		"translit" : "bebaíōsis",
		"lemma" : "βεβαίωσις"
	},
	"G3829" : {
		"translit" : "pandocheîon",
		"lemma" : "πανδοχεῖον",
		"kjv" : "inn",
		"def" : "all-receptive, i.e. a public lodging-place (caravanserai or khan)",
		"derivation" : "neuter of a presumed compound of G3956 (πᾶς) and a derivative of G1209 (δέχομαι)"
	},
	"G2067" : {
		"def" : "clothing (concretely)",
		"derivation" : "from a derivative of G2066 (ἐσθής)",
		"lemma" : "ἔσθησις",
		"translit" : "ésthēsis",
		"kjv" : "government"
	},
	"G1003" : {
		"derivation" : "of Hebrew origin (H1162)",
		"def" : "Booz, (i.e. Boaz), an Israelite",
		"kjv" : "Booz",
		"translit" : "Boóz",
		"lemma" : "Βοόζ"
	},
	"G3401" : {
		"kjv" : "follow",
		"lemma" : "μιμέομαι",
		"translit" : "miméomai",
		"derivation" : "middle voice from (a \"mimic\")",
		"def" : "to imitate"
	},
	"G2034" : {
		"derivation" : "adverb from G2033 (ἑπτά)",
		"def" : "seven times",
		"kjv" : "seven times",
		"lemma" : "ἑπτάκις",
		"translit" : "heptákis"
	},
	"G4296" : {
		"def" : "to herald (i.e. proclaim) in advance",
		"derivation" : "from G4253 (πρό) and G2784 (κηρύσσω)",
		"lemma" : "προκηρύσσω",
		"translit" : "prokērýssō",
		"kjv" : "before (first) preach"
	},
	"G4065" : {
		"translit" : "periphronéō",
		"lemma" : "περιφρονέω",
		"kjv" : "despise",
		"def" : "to think beyond, i.e. depreciate (contemn)",
		"derivation" : "from G4012 (περί) and G5426 (φρονέω)"
	},
	"G198" : {
		"def" : "to be exact, i.e. ascertain",
		"derivation" : "from the same as G196 (ἀκριβέστατος)",
		"translit" : "akribóō",
		"lemma" : "ἀκριβόω",
		"kjv" : "enquire diligently"
	},
	"G2312" : {
		"kjv" : "taught of God",
		"translit" : "theodídaktos",
		"lemma" : "θεοδίδακτος",
		"derivation" : "from G2316 (θεός) and G1321 (διδάσκω)",
		"def" : "divinely instructed"
	},
	"G5295" : {
		"def" : "to run under, i.e. (specially), to sail past",
		"derivation" : "from G5259 (ὑπό) and G5143 (τρέχω) (including its alternate)",
		"translit" : "hypotréchō",
		"lemma" : "ὑποτρέχω",
		"kjv" : "run under"
	},
	"G5058" : {
		"def" : "a tax-gatherer's place of business",
		"derivation" : "neuter of a presumed derivative of G5057 (τελώνης)",
		"lemma" : "τελώνιον",
		"translit" : "telṓnion",
		"kjv" : "receipt of custom"
	},
	"G5035" : {
		"kjv" : "lightly, quickly",
		"translit" : "tachý",
		"lemma" : "ταχύ",
		"derivation" : "neuter singular of G5036 (ταχύς) (as adverb)",
		"def" : "shortly, i.e. without delay, soon, or (by surprise) suddenly, or (by implication, of ease) readily"
	},
	"G77" : {
		"def" : "and G1160 (δαπάνη); costless, i.e. gratuitous",
		"derivation" : "from G1 (Α) (as negative particle)",
		"lemma" : "ἀδάπανος",
		"translit" : "adápanos",
		"kjv" : "without expense"
	},
	"G137" : {
		"kjv" : "Ænon",
		"lemma" : "Αἰνών",
		"translit" : "Ainṓn",
		"derivation" : "of Hebrew origin (a derivative of G5869, place of springs)",
		"def" : "Ænon, a place in Palestine"
	},
	"G2846" : {
		"kjv" : "+ chamberlain",
		"lemma" : "κοιτών",
		"translit" : "koitṓn",
		"derivation" : "from G2845 (κοίτη)",
		"def" : "a bedroom"
	},
	"G4539" : {
		"def" : "Salome (i.e. Shelomah), an Israelitess",
		"derivation" : "probably of Hebrew origin (feminine from H7965)",
		"lemma" : "Σαλώμη",
		"translit" : "Salṓmē",
		"kjv" : "Salome"
	},
	"G3147" : {
		"kjv" : "scourge",
		"translit" : "mastízō",
		"lemma" : "μαστίζω",
		"derivation" : "from G3149 (μαστός)",
		"def" : "to whip (literally)"
	},
	"G3505" : {
		"derivation" : "of Latin origin",
		"def" : "Neron (i.e. Nero), a Roman emperor",
		"kjv" : "Nero",
		"lemma" : "Νέρων",
		"translit" : "Nérōn"
	},
	"G2746" : {
		"translit" : "kaúchēsis",
		"lemma" : "καύχησις",
		"kjv" : "boasting, whereof I may glory, glorying, rejoicing",
		"def" : "boasting (properly, the act; by implication, the object), in a good or a bad sense",
		"derivation" : "from G2744 (καυχάομαι)"
	},
	"G662" : {
		"derivation" : "from G575 (ἀπό) and G5111 (τολμάω)",
		"def" : "to venture plainly",
		"kjv" : "be very bold",
		"lemma" : "ἀποτολμάω",
		"translit" : "apotolmáō"
	},
	"G654" : {
		"def" : "to turn away or back (literally or figuratively)",
		"derivation" : "from G575 (ἀπό) and G4762 (στρέφω)",
		"lemma" : "ἀποστρέφω",
		"translit" : "apostréphō",
		"kjv" : "bring again, pervert, turn away (from)"
	},
	"G940" : {
		"derivation" : "akin to G5335 (φάσκω)",
		"def" : "to malign, i.e. (by extension) to fascinate (by false representations)",
		"kjv" : "bewitch",
		"lemma" : "βασκαίνω",
		"translit" : "baskaínō"
	},
	"G633" : {
		"translit" : "aponíptō",
		"lemma" : "ἀπονίπτω",
		"kjv" : "wash",
		"def" : "to wash off (reflexively, one's own hands symbolically)",
		"derivation" : "from G575 (ἀπό) and G3538 (νίπτω)"
	},
	"G4077" : {
		"def" : "a fount (literally or figuratively), i.e. source or supply (of water, blood, enjoyment) (not necessarily the original spring)",
		"derivation" : "probably from G4078 (πήγνυμι) (through the idea of gushing plumply)",
		"translit" : "pēgḗ",
		"lemma" : "πηγή",
		"kjv" : "fountain, well"
	},
	"G4447" : {
		"derivation" : "from G4443 (πυρά)",
		"def" : "fiery, i.e. (by implication) flaming",
		"kjv" : "of fire",
		"translit" : "pýrinos",
		"lemma" : "πύρινος"
	},
	"G5487" : {
		"derivation" : "from G5485 (χάρις)",
		"def" : "to grace, i.e. indue with special honor",
		"kjv" : "make accepted, be highly favoured",
		"translit" : "charitóō",
		"lemma" : "χαριτόω"
	},
	"G1976" : {
		"derivation" : "from G1909 (ἐπί) and the base of G4476 (ῥαφίς)",
		"def" : "to stitch upon, i.e. fasten with the needle",
		"kjv" : "sew on",
		"translit" : "epirrháptō",
		"lemma" : "ἐπιῤῥάπτω"
	},
	"G3761" : {
		"kjv" : "neither (indeed), never, no (more, nor, not), nor (yet), (also, even, then) not (even, so much as), + nothing, so much as",
		"lemma" : "οὐδέ",
		"translit" : "oudé",
		"derivation" : "from G3756 (οὐ) and G1161 (δέ)",
		"def" : "not however, i.e. neither, nor, not even"
	},
	"G4809" : {
		"def" : "the \"sycamore\"-fig tree",
		"derivation" : "from G4810 (σῦκον) and (the mulberry)",
		"lemma" : "συκομωραία",
		"translit" : "sykomōraía",
		"kjv" : "sycamore tree"
	},
	"G4710" : {
		"derivation" : "from G4692 (σπεύδω)",
		"def" : "\"speed\", i.e. (by implication) despatch, eagerness, earnestness",
		"kjv" : "business, (earnest) care(-fulness), diligence, forwardness, haste",
		"translit" : "spoudḗ",
		"lemma" : "σπουδή"
	},
	"G2781" : {
		"derivation" : "diminutive from (wax)",
		"def" : "a cell for honey, i.e. (collectively) the comb",
		"kjv" : "(honey-)comb",
		"translit" : "kēríon",
		"lemma" : "κηρίον"
	},
	"G2598" : {
		"translit" : "katabállō",
		"lemma" : "καταβάλλω",
		"kjv" : "cast down, lay",
		"def" : "to throw down",
		"derivation" : "from G2596 (κατά) and G906 (βάλλω)"
	},
	"G3686" : {
		"def" : "a \"name\" (literally or figuratively) (authority, character)",
		"derivation" : "from a presumed derivative of the base of G1097 (γινώσκω) (compare G3685 (ὀνίνημι))",
		"translit" : "ónoma",
		"lemma" : "ὄνομα",
		"kjv" : "called, (+ sur-)name(-d)"
	},
	"G3707" : {
		"kjv" : "visible",
		"lemma" : "ὁρατός",
		"translit" : "horatós",
		"derivation" : "from G3708 (ὁράω)",
		"def" : "gazed at, i.e. (by implication) capable of being seen"
	},
	"G218" : {
		"def" : "to oil (with perfume)",
		"derivation" : "from G1 (Α) (as particle of union) and the base of G3045 (λιπαρός)",
		"translit" : "aleíphō",
		"lemma" : "ἀλείφω",
		"kjv" : "anoint"
	},
	"G1138" : {
		"kjv" : "David",
		"lemma" : "Δαβίδ",
		"translit" : "Dabíd",
		"derivation" : "of Hebrew origin (H1732)",
		"def" : "Dabid (i.e. David), the Israelite king"
	},
	"G4919" : {
		"lemma" : "συνθρύπτω",
		"translit" : "synthrýptō",
		"kjv" : "break",
		"def" : "to crush together, i.e. (figuratively) to dispirit",
		"derivation" : "from G4862 (σύν) and (to crumble)"
	},
	"G3096" : {
		"kjv" : "use sorcery",
		"lemma" : "μαγεύω",
		"translit" : "mageúō",
		"derivation" : "from G3097 (μάγος)",
		"def" : "to practice magic"
	},
	"G5010" : {
		"def" : "regular arrangement, i.e. (in time) fixed succession (of rank or character), official dignity",
		"derivation" : "from G5021 (τάσσω)",
		"lemma" : "τάξις",
		"translit" : "táxis",
		"kjv" : "order"
	},
	"G3877" : {
		"translit" : "parakolouthéō",
		"lemma" : "παρακολουθέω",
		"kjv" : "attain, follow, fully know, have understanding",
		"def" : "to follow near, i.e. (figuratively) attend (as a result), trace out, conform to",
		"derivation" : "from G3844 (παρά) and G190 (ἀκολουθέω)"
	},
	"G2214" : {
		"derivation" : "from G2212 (ζητέω)",
		"def" : "a searching (properly, the act), i.e. a dispute or its theme",
		"kjv" : "question",
		"translit" : "zḗtēsis",
		"lemma" : "ζήτησις"
	},
	"G1090" : {
		"def" : "to till (the soil)",
		"derivation" : "from G1092 (γεωργός)",
		"lemma" : "γεωργέω",
		"translit" : "geōrgéō",
		"kjv" : "dress"
	},
	"G408" : {
		"kjv" : "Adronicus",
		"translit" : "Andrónikos",
		"lemma" : "Ἀνδρόνικος",
		"derivation" : "from G435 (ἀνήρ) and G3534 (νῖκος)",
		"def" : "man of victory; Andronicos, an Israelite"
	},
	"G1690" : {
		"translit" : "embrimáomai",
		"lemma" : "ἐμβριμάομαι",
		"kjv" : "straitly charge, groan, murmur against",
		"def" : "to have indignation on, i.e. (transitively) to blame, (intransitively) to sigh with chagrin, (specially) to sternly enjoin",
		"derivation" : "from G1722 (ἐν) and (to snort with anger)"
	},
	"G4280" : {
		"kjv" : "foretell, say (speak, tell) before",
		"lemma" : "προερέω",
		"translit" : "proeréō",
		"derivation" : "from G4253 (πρό) and G2046 (ἐρέω)",
		"def" : "used as alternate of G4277 (προέπω); to say already, predict"
	},
	"G3763" : {
		"kjv" : "neither at any time, never, nothing at any time",
		"lemma" : "οὐδέποτε",
		"translit" : "oudépote",
		"derivation" : "from G3761 (οὐδέ) and G4218 (ποτέ)",
		"def" : "not even at any time, i.e. never at all"
	},
	"G4741" : {
		"kjv" : "fix, (e-)stablish, stedfastly set, strengthen",
		"translit" : "stērízō",
		"lemma" : "στηρίζω",
		"derivation" : "from a presumed derivative of G2476 (ἵστημι) (like G4731 (στερεός))",
		"def" : "to set fast, i.e. (literally) to turn resolutely in a certain direction, or (figuratively) to confirm"
	},
	"G1648" : {
		"lemma" : "Ἐλεάζαρ",
		"translit" : "Eleázar",
		"kjv" : "Eleazar",
		"def" : "Eleazar, an Israelite",
		"derivation" : "of Hebrew origin (H499)"
	},
	"G1904" : {
		"def" : "to supervene, i.e. arrive, occur, impend, attack, (figuratively) influence",
		"derivation" : "from G1909 (ἐπί) and G2064 (ἔρχομαι)",
		"lemma" : "ἐπέρχομαι",
		"translit" : "epérchomai",
		"kjv" : "come (in, upon)"
	},
	"G49" : {
		"translit" : "hagnismós",
		"lemma" : "ἁγνισμός",
		"kjv" : "purification",
		"def" : "a cleansing (the act), i.e. (ceremonially) lustration",
		"derivation" : "from G48 (ἁγνίζω)"
	},
	"G3726" : {
		"derivation" : "from G3727 (ὅρκος)",
		"def" : "to put on oath, i.e. make swear; by analogy, to solemnly enjoin",
		"kjv" : "adjure, charge",
		"translit" : "horkízō",
		"lemma" : "ὁρκίζω"
	},
	"G3049" : {
		"lemma" : "λογίζομαι",
		"translit" : "logízomai",
		"kjv" : "conclude, (ac-)count (of), + despise, esteem, impute, lay, number, reason, reckon, suppose, think (on)",
		"def" : "to take an inventory, i.e. estimate (literally or figuratively)",
		"derivation" : "middle voice from G3056 (λόγος)"
	},
	"G4257" : {
		"def" : "to hear already,i.e. anticipate",
		"derivation" : "from G4253 (πρό) and G191 (ἀκούω)",
		"translit" : "proakoúō",
		"lemma" : "προακούω",
		"kjv" : "hear before"
	},
	"G461" : {
		"kjv" : "lift (set) up, make straight",
		"lemma" : "ἀνορθόω",
		"translit" : "anorthóō",
		"derivation" : "from G303 (ἀνά) and a derivative of the base of G3717 (ὀρθός)",
		"def" : "to straighten up"
	},
	"G266" : {
		"kjv" : "offence, sin(-ful)",
		"translit" : "hamartía",
		"lemma" : "ἁμαρτία",
		"derivation" : "from G264 (ἁμαρτάνω)",
		"def" : "a sin (properly abstract)"
	},
	"G4871" : {
		"def" : "to accumulate, i.e. convene",
		"derivation" : "from G4862 (σύν) and (to throng)",
		"translit" : "synalízō",
		"lemma" : "συναλίζω",
		"kjv" : "assemble together"
	},
	"G4265" : {
		"def" : "to look out beforehand, i.e. furnish in advance",
		"derivation" : "from G4253 (πρό) and G991 (βλέπω)",
		"lemma" : "προβλέπω",
		"translit" : "problépō",
		"kjv" : "provide"
	},
	"G3364" : {
		"derivation" : "i.e. G3756 (οὐ) and G3361 (μή)",
		"def" : "a double negative strengthening the denial; not at all",
		"kjv" : "any more, at all, by any (no) means, neither, never, no (at all), in no case (wise), nor ever, not (at all, in any wise)",
		"lemma" : "οὐ μή",
		"translit" : "ou mḗ"
	},
	"G2966" : {
		"kjv" : "carcase",
		"translit" : "kōlon",
		"lemma" : "κῶλον",
		"derivation" : "from the base of G2849 (κολάζω)",
		"def" : "a limb of the body (as if lopped)"
	},
	"G2186" : {
		"kjv" : "--assault, come (in, to, unto, upon), be at hand (instant), present, stand (before, by, over)",
		"translit" : "ephístēmi",
		"lemma" : "ἐφίστημι",
		"derivation" : "from G1909 (ἐπί) and G2476 (ἵστημι)",
		"def" : "to stand upon, i.e. be present (in various applications, friendly or otherwise, usually literal); "
	},
	"G116" : {
		"translit" : "Athēnai",
		"lemma" : "Ἀθῆναι",
		"kjv" : "Athens",
		"def" : "Athenæ, the capitol of Greece",
		"derivation" : "plural of (the goddess of wisdom, who was reputed to have founded the city)"
	},
	"G5327" : {
		"derivation" : "properly, strengthened from the base of G4008 (πέραν) or rather of G4486 (ῥήγνυμι)",
		"def" : "a gap or chasm, i.e. ravine (winter-torrent)",
		"kjv" : "valley",
		"lemma" : "φάραγξ",
		"translit" : "pháranx"
	},
	"G4348" : {
		"def" : "a stub, i.e. (figuratively) occasion of apostasy",
		"derivation" : "from G4350 (προσκόπτω)",
		"lemma" : "πρόσκομμα",
		"translit" : "próskomma",
		"kjv" : "offence, stumbling(-block, (-stone))"
	},
	"G667" : {
		"translit" : "apophérō",
		"lemma" : "ἀποφέρω",
		"kjv" : "bring, carry (away)",
		"def" : "to bear off (literally or relatively)",
		"derivation" : "from G575 (ἀπό) and G5342 (φέρω)"
	},
	"G3615" : {
		"kjv" : "they (them) of (his own) household",
		"translit" : "oikiakós",
		"lemma" : "οἰκιακός",
		"derivation" : "from G3614 (οἰκία)",
		"def" : "familiar, i.e. (as noun) relatives"
	},
	"G2187" : {
		"translit" : "Ephraím",
		"lemma" : "Ἐφραίμ",
		"kjv" : "Ephraim",
		"def" : "Ephraim, a place in Palestine",
		"derivation" : "of Hebrew origin (H669 or better H6085)"
	},
	"G546" : {
		"kjv" : "threaten",
		"translit" : "apeiléō",
		"lemma" : "ἀπειλέω",
		"derivation" : "of uncertain derivation",
		"def" : "to menace; by implication, to forbid"
	},
	"G1445" : {
		"derivation" : "from G1443 (Ἐβέρ)",
		"def" : "a Hebræan (i.e. Hebrew) or Jew",
		"kjv" : "Hebrew",
		"lemma" : "Ἑβραῖος",
		"translit" : "Hebraîos"
	},
	"G268" : {
		"derivation" : "from G264 (ἁμαρτάνω)",
		"def" : "sinful, i.e. a sinner",
		"kjv" : "sinful, sinner",
		"lemma" : "ἁμαρτωλός",
		"translit" : "hamartōlós"
	},
	"G3612" : {
		"kjv" : "prison",
		"translit" : "oíkēma",
		"lemma" : "οἴκημα",
		"derivation" : "from G3611 (οἰκέω)",
		"def" : "a tenement, i.e. (specially), a jail"
	},
	"G3187" : {
		"def" : "larger (literally or figuratively, specially, in age)",
		"derivation" : "irregular comparative of G3173 (μέγας)",
		"lemma" : "μείζων",
		"translit" : "meízōn",
		"kjv" : "elder, greater(-est), more"
	},
	"G2712" : {
		"def" : "utterly idolatrous",
		"derivation" : "from G2596 (κατά) (intensively) and G1497 (εἴδωλον)",
		"translit" : "kateídōlos",
		"lemma" : "κατείδωλος",
		"kjv" : "wholly given to idolatry"
	},
	"G642" : {
		"lemma" : "ἀπορφανίζω",
		"translit" : "aporphanízō",
		"kjv" : "take",
		"def" : "to bereave wholly, i.e. (figuratively) separate (from intercourse)",
		"derivation" : "from G575 (ἀπό) and a derivative of G3737 (ὀρφανός)"
	},
	"G2534" : {
		"def" : "and at least (or even, indeed)",
		"derivation" : "from G2532 (καί) and G1065 (γέ)",
		"translit" : "kaíge",
		"lemma" : "καίγε",
		"kjv" : "and, at least"
	},
	"G5529" : {
		"kjv" : "Chuza",
		"translit" : "Chouzâs",
		"lemma" : "Χουζᾶς",
		"derivation" : "of uncertain origin",
		"def" : "Chuzas, an officer of Herod"
	},
	"G927" : {
		"translit" : "barýtimos",
		"lemma" : "βαρύτιμος",
		"kjv" : "very precious",
		"def" : "highly valuable",
		"derivation" : "from G926 (βαρύς) and G5092 (τιμή)"
	},
	"G3139" : {
		"lemma" : "μάρμαρος",
		"translit" : "mármaros",
		"kjv" : "marble",
		"def" : "marble (as sparkling white)",
		"derivation" : "from (to glisten)"
	},
	"G2220" : {
		"kjv" : "leaven",
		"lemma" : "ζυμόω",
		"translit" : "zymóō",
		"derivation" : "from G2219 (ζύμη)",
		"def" : "to cause to ferment"
	},
	"G5314" : {
		"lemma" : "φάγος",
		"translit" : "phágos",
		"kjv" : "gluttonous",
		"def" : "a glutton",
		"derivation" : "from G5315 (φάγω)"
	},
	"G3732" : {
		"def" : "a birdling",
		"derivation" : "neuter of a presumed derivative of G3733 (ὄρνις)",
		"lemma" : "ὄρνεον",
		"translit" : "órneon",
		"kjv" : "bird, fowl"
	},
	"G2827" : {
		"def" : "to slant or slope, i.e. incline or recline (literally or figuratively)",
		"derivation" : "a primary verb",
		"translit" : "klínō",
		"lemma" : "κλίνω",
		"kjv" : "bow (down), be far spent, lay, turn to flight, wear away"
	},
	"G4998" : {
		"def" : "safe (sound) in mind, i.e. self-controlled (moderate as to opinion or passion)",
		"derivation" : "from the base of G4982 (σώζω) and that of G5424 (φρήν)",
		"translit" : "sṓphrōn",
		"lemma" : "σώφρων",
		"kjv" : "discreet, sober, temperate"
	},
	"G3607" : {
		"derivation" : "of uncertain affinity",
		"def" : "a linen cloth, i.e. (especially) a sail",
		"kjv" : "sheet",
		"lemma" : "ὀθόνη",
		"translit" : "othónē"
	},
	"G3786" : {
		"kjv" : "advantageth, profit",
		"lemma" : "ὄφελος",
		"translit" : "óphelos",
		"derivation" : "from (to heap up, i.e. accumulate or benefit)",
		"def" : "gain"
	},
	"G36" : {
		"lemma" : "ἀγενής",
		"translit" : "agenḗs",
		"kjv" : "base things",
		"def" : "properly, without kin, i.e. (of unknown descent, and by implication) ignoble",
		"derivation" : "from G1 (Α) (as negative particle) and G1085 (γένος)"
	},
	"G4683" : {
		"kjv" : "wrap in swaddling clothes",
		"translit" : "sparganóō",
		"lemma" : "σπαργανόω",
		"derivation" : "from (a strip",
		"def" : "from a derivative of the base of G4682 (σπαράσσω) meaning to strap or wrap with strips); to swathe (an infant after the Oriental custom)"
	},
	"G1181" : {
		"def" : "a tenth, i.e. as a percentage or (technically) tithe",
		"derivation" : "feminine of G1182 (δέκατος)",
		"lemma" : "δεκάτη",
		"translit" : "dekátē",
		"kjv" : "tenth (part), tithe"
	},
	"G1515" : {
		"derivation" : "probably from a primary verb (to join)",
		"def" : "peace (literally or figuratively); by implication, prosperity",
		"kjv" : "one, peace, quietness, rest, + set at one again",
		"translit" : "eirḗnē",
		"lemma" : "εἰρήνη"
	},
	"G2295" : {
		"translit" : "thaûma",
		"lemma" : "θαῦμα",
		"kjv" : "admiration",
		"def" : "wonder (properly concrete; but by implication, abstract)",
		"derivation" : "apparently from a form of G2300 (θεάομαι)"
	},
	"G3086" : {
		"def" : "a redeemer (figuratively)",
		"derivation" : "from G3084 (λυτρόω)",
		"lemma" : "λυτρωτής",
		"translit" : "lytrōtḗs",
		"kjv" : "deliverer"
	},
	"G988" : {
		"def" : "vilification (especially against God)",
		"derivation" : "from G989 (βλάσφημος)",
		"translit" : "blasphēmía",
		"lemma" : "βλασφημία",
		"kjv" : "blasphemy, evil speaking, railing"
	},
	"G3904" : {
		"translit" : "paraskeuḗ",
		"lemma" : "παρασκευή",
		"kjv" : "preparation",
		"def" : "readiness",
		"derivation" : "as if from G3903 (παρασκευάζω)"
	},
	"G425" : {
		"translit" : "ánesis",
		"lemma" : "ἄνεσις",
		"kjv" : "eased, liberty, rest",
		"def" : "relaxation or (figuratively) relief",
		"derivation" : "from G447 (ἀνίημι)"
	},
	"G407" : {
		"def" : "to act manly",
		"derivation" : "middle voice from G435 (ἀνήρ)",
		"lemma" : "ἀνδρίζομαι",
		"translit" : "andrízomai",
		"kjv" : "quit like men"
	},
	"G4650" : {
		"def" : "to dissipate, i.e. (figuratively) put to flight, waste, be liberal",
		"derivation" : "apparently from the same as G4651 (σκορπίος) (through the idea of penetrating)",
		"translit" : "skorpízō",
		"lemma" : "σκορπίζω",
		"kjv" : "disperse abroad, scatter (abroad)"
	},
	"G999" : {
		"derivation" : "akin to G900 (βαθύνω)",
		"def" : "a hole (in the ground); specially, a cistern",
		"kjv" : "ditch, pit",
		"translit" : "bóthynos",
		"lemma" : "βόθυνος"
	},
	"G2794" : {
		"kjv" : "peril",
		"translit" : "kíndynos",
		"lemma" : "κίνδυνος",
		"derivation" : "of uncertain derivation",
		"def" : "danger"
	},
	"G337" : {
		"lemma" : "ἀναιρέω",
		"translit" : "anairéō",
		"kjv" : "put to death, kill, slay, take away, take up",
		"def" : "to take up, i.e. adopt; by implication, to take away (violently), i.e. abolish, murder",
		"derivation" : "from G303 (ἀνά) and (the active of) G138 (αἱρέομαι)"
	},
	"G2049" : {
		"translit" : "erēmóō",
		"lemma" : "ἐρημόω",
		"kjv" : "(bring to, make) desolate(-ion), come to nought",
		"def" : "to lay waste (literally or figuratively)",
		"derivation" : "from G2048 (ἔρημος)"
	},
	"G4618" : {
		"def" : "grain-fed, i.e. fattened",
		"derivation" : "from a derivative of G4621 (σῖτος)",
		"lemma" : "σιτευτός",
		"translit" : "siteutós",
		"kjv" : "fatted"
	},
	"G2366" : {
		"kjv" : "tempest",
		"lemma" : "θύελλα",
		"translit" : "thýella",
		"derivation" : "from G2380 (θύω) (in the sense of blowing) a storm"
	},
	"G95" : {
		"kjv" : "wrongfully",
		"translit" : "adíkōs",
		"lemma" : "ἀδίκως",
		"derivation" : "adverb from G94 (ἄδικος)",
		"def" : "unjustly"
	},
	"G2121" : {
		"lemma" : "εὔκαιρος",
		"translit" : "eúkairos",
		"kjv" : "convenient, in time of need",
		"def" : "well-timed, i.e. opportune",
		"derivation" : "from G2095 (εὖ) and G2540 (καιρός)"
	},
	"G2950" : {
		"def" : "a \"cymbal\" (as hollow)",
		"derivation" : "from a derivative of the base of G2949 (κῦμα)",
		"translit" : "kýmbalon",
		"lemma" : "κύμβαλον",
		"kjv" : "cymbal"
	},
	"G2739" : {
		"derivation" : "from G2738 (καῦμα)",
		"def" : "to burn",
		"kjv" : "scorch",
		"lemma" : "καυματίζω",
		"translit" : "kaumatízō"
	},
	"G2798" : {
		"lemma" : "κλάδος",
		"translit" : "kládos",
		"kjv" : "branch",
		"def" : "a twig or bough (as if broken off)",
		"derivation" : "from G2806 (κλάω)"
	},
	"G330" : {
		"def" : "to revive",
		"derivation" : "from G303 (ἀνά) and (to flourish)",
		"translit" : "anathállō",
		"lemma" : "ἀναθάλλω",
		"kjv" : "flourish again"
	},
	"G3917" : {
		"derivation" : "feminine of (a panther)",
		"def" : "a leopard",
		"kjv" : "leopard",
		"lemma" : "πάρδαλις",
		"translit" : "párdalis"
	},
	"G5258" : {
		"kjv" : "sleep",
		"lemma" : "ὕπνος",
		"translit" : "hýpnos",
		"derivation" : "from an obsolete primary (perhaps akin to G5259 (ὑπό) through the idea of subsilience)",
		"def" : "sleep, i.e. (figuratively) spiritual torpor"
	},
	"G4353" : {
		"def" : "an adorer",
		"derivation" : "from G4352 (προσκυνέω)",
		"translit" : "proskynētḗs",
		"lemma" : "προσκυνητής",
		"kjv" : "worshipper"
	},
	"G3647" : {
		"translit" : "holoklēría",
		"lemma" : "ὁλοκληρία",
		"kjv" : "perfect soundness",
		"def" : "integrity, i.e. physical wholeness",
		"derivation" : "from G3648 (ὁλόκληρος)"
	},
	"G5416" : {
		"derivation" : "neuter of a derivative from the base of G5417 (φραγελλόω)",
		"def" : "a whip, i.e. Roman lash as a public punishment",
		"kjv" : "scourge",
		"lemma" : "φραγέλλιον",
		"translit" : "phragéllion"
	},
	"G1894" : {
		"derivation" : "from G1893 (ἐπεί) and G1211 (δή)",
		"def" : "since now, i.e. (of time) when, or (of cause) whereas",
		"kjv" : "after that, because, for (that, -asmuch as), seeing, since",
		"translit" : "epeidḗ",
		"lemma" : "ἐπειδή"
	},
	"G1576" : {
		"kjv" : "exclude",
		"lemma" : "ἐκκλείω",
		"translit" : "ekkleíō",
		"derivation" : "from G1537 (ἐκ) and G2808 (κλείω)",
		"def" : "to shut out (literally or figuratively)"
	},
	"G900" : {
		"lemma" : "βαθύνω",
		"translit" : "bathýnō",
		"kjv" : "deep",
		"def" : "to deepen",
		"derivation" : "from G901 (βαθύς)"
	},
	"G5222" : {
		"derivation" : "from G5221 (ὑπαντάω)",
		"def" : "an encounter or concurrence (with G1519 (εἰς) for infinitive, in order to fall in with)",
		"kjv" : "meeting",
		"translit" : "hypántēsis",
		"lemma" : "ὑπάντησις"
	},
	"G4838" : {
		"def" : "to take along in company",
		"derivation" : "from G4862 (σύν) and G3880 (παραλαμβάνω)",
		"translit" : "symparalambánō",
		"lemma" : "συμπαραλαμβάνω",
		"kjv" : "take with"
	},
	"G1101" : {
		"derivation" : "from G1100 (γλῶσσα) and the base of G2889 (κόσμος)",
		"def" : "properly, a case (to keep mouthpieces of wind-instruments in) i.e. (by extension) a casket or (specially) purse",
		"kjv" : "bag",
		"lemma" : "γλωσσόκομον",
		"translit" : "glōssókomon"
	},
	"G1442" : {
		"derivation" : "ordinal from G2033 (ἑπτά)",
		"def" : "seventh",
		"kjv" : "seventh",
		"translit" : "hébdomos",
		"lemma" : "ἕβδομος"
	},
	"G4828" : {
		"derivation" : "from G4862 (σύν) and G3140 (μαρτυρέω)",
		"def" : "to testify jointly, i.e. corroborate by (concurrent) evidence",
		"kjv" : "testify unto, (also) bear witness (with)",
		"translit" : "symmartyréō",
		"lemma" : "συμμαρτυρέω"
	},
	"G3515" : {
		"def" : "to act as a babe, i.e. (figuratively) innocently",
		"derivation" : "from G3516 (νήπιος)",
		"lemma" : "νηπιάζω",
		"translit" : "nēpiázō",
		"kjv" : "be a child"
	},
	"G2659" : {
		"kjv" : "slumber",
		"lemma" : "κατάνυξις",
		"translit" : "katányxis",
		"derivation" : "from G2660 (κατανύσσω)",
		"def" : "a prickling (sensation, as of the limbs asleep), i.e. (by implication, (perhaps by some confusion with G3506 (νεύω) or even with G3571 (νύξ))) stupor (lethargy)"
	},
	"G4456" : {
		"kjv" : "blind, harden",
		"lemma" : "πωρόω",
		"translit" : "pōróō",
		"derivation" : "apparently from (a kind of stone)",
		"def" : "to petrify, i.e. (figuratively) to indurate (render stupid or callous)"
	},
	"G2813" : {
		"translit" : "kléptō",
		"lemma" : "κλέπτω",
		"kjv" : "steal",
		"def" : "to filch",
		"derivation" : "a primary verb"
	},
	"G4781" : {
		"translit" : "synkámptō",
		"lemma" : "συγκάμπτω",
		"kjv" : "bow down",
		"def" : "to bend together, i.e. (figuratively) to afflict",
		"derivation" : "from G4862 (σύν) and G2578 (κάμπτω)"
	},
	"G2653" : {
		"lemma" : "καταναθεματίζω",
		"translit" : "katanathematízō",
		"kjv" : "curse",
		"def" : "to imprecate",
		"derivation" : "from G2596 (κατά) (intensive) and G332 (ἀναθεματίζω)"
	},
	"G4006" : {
		"derivation" : "from the perfect of the alternate of G3958 (πάσχω)",
		"def" : "reliance",
		"kjv" : "confidence, trust",
		"translit" : "pepoíthēsis",
		"lemma" : "πεποίθησις"
	},
	"G904" : {
		"derivation" : "of Hebrew origin (H1111)",
		"def" : "Balak, a Moabite",
		"kjv" : "Balac",
		"translit" : "Balák",
		"lemma" : "Βαλάκ"
	},
	"G2576" : {
		"translit" : "kammýō",
		"lemma" : "καμμύω",
		"kjv" : "close",
		"def" : "to shut down, i.e. close the eyes",
		"derivation" : "from a compound of G2596 (κατά) and the base of G3466 (μυστήριον)"
	},
	"G4830" : {
		"translit" : "symmétochos",
		"lemma" : "συμμέτοχος",
		"kjv" : "partaker",
		"def" : "a co-participant",
		"derivation" : "from G4862 (σύν) and G3353 (μέτοχος)"
	},
	"G1114" : {
		"def" : "properly, a wizard (as muttering spells), i.e. (by implication) an imposter",
		"derivation" : "from (to wail)",
		"translit" : "góēs",
		"lemma" : "γόης",
		"kjv" : "seducer"
	},
	"G4923" : {
		"kjv" : "company",
		"lemma" : "συνοδία",
		"translit" : "synodía",
		"derivation" : "from a compound of G4862 (σύν) and G3598 (ὁδός) (\"synod\")",
		"def" : "companionship on a journey, i.e. (by implication), a caravan"
	},
	"G3822" : {
		"derivation" : "from G3820 (παλαιός)",
		"def" : "to make (passively, become) worn out, or declare obsolete",
		"kjv" : "decay, make (wax) old",
		"lemma" : "παλαιόω",
		"translit" : "palaióō"
	},
	"G1598" : {
		"lemma" : "ἐκπειράζω",
		"translit" : "ekpeirázō",
		"kjv" : "tempt",
		"def" : "to test thoroughly",
		"derivation" : "from G1537 (ἐκ) and G3985 (πειράζω)"
	},
	"G3420" : {
		"def" : "memory",
		"derivation" : "from G3403 (μιμνήσκω)",
		"translit" : "mnḗmē",
		"lemma" : "μνήμη",
		"kjv" : "remembrance"
	},
	"G4388" : {
		"def" : "to place before, i.e. (for oneself) to exhibit; (to oneself) to propose (determine)",
		"derivation" : "middle voice from G4253 (πρό) and G5087 (τίθημι)",
		"lemma" : "προτίθεμαι",
		"translit" : "protíthemai",
		"kjv" : "purpose, set forth"
	},
	"G3466" : {
		"kjv" : "mystery",
		"lemma" : "μυστήριον",
		"translit" : "mystḗrion",
		"derivation" : "from a derivative of (to shut the mouth)",
		"def" : "a secret or \"mystery\" (through the idea of silence imposed by initiation into religious rites)"
	},
	"G4000" : {
		"def" : "five times a thousand",
		"derivation" : "from G3999 (πεντάκις) and G5507 (χίλιοι)",
		"translit" : "pentakischílioi",
		"lemma" : "πεντακισχίλιοι",
		"kjv" : "five thousand"
	},
	"G2877" : {
		"derivation" : "neuter of a presumed derivative of (a maiden)",
		"def" : "a (little) girl",
		"kjv" : "damsel, maid",
		"translit" : "korásion",
		"lemma" : "κοράσιον"
	},
	"G5311" : {
		"translit" : "hýpsos",
		"lemma" : "ὕψος",
		"kjv" : "be exalted, height, (on) high",
		"def" : "elevation, i.e. (abstractly) altitude, (specially), the sky, or (figuratively) dignity",
		"derivation" : "from a derivative of G5228 (ὑπέρ)"
	},
	"G3152" : {
		"translit" : "mátaios",
		"lemma" : "μάταιος",
		"kjv" : "vain, vanity",
		"def" : "empty, i.e. (literally) profitless, or (specially), an idol",
		"derivation" : "from the base of G3155 (μάτην)"
	},
	"G3850" : {
		"lemma" : "παραβολή",
		"translit" : "parabolḗ",
		"kjv" : "comparison, figure, parable, proverb",
		"def" : "a similitude (\"parable\"), i.e. (symbolic) fictitious narrative (of common life conveying a moral), apothegm or adage",
		"derivation" : "from G3846 (παραβάλλω)"
	},
	"G3556" : {
		"kjv" : "chicken",
		"lemma" : "νοσσίον",
		"translit" : "nossíon",
		"derivation" : "diminutive of G3502 (νεοσσός)",
		"def" : "a birdling"
	},
	"G2126" : {
		"def" : "taking well (carefully), i.e. circumspect (religiously, pious)",
		"derivation" : "from G2095 (εὖ) and G2983 (λαμβάνω)",
		"lemma" : "εὐλαβής",
		"translit" : "eulabḗs",
		"kjv" : "devout"
	},
	"G1401" : {
		"kjv" : "bond(-man), servant",
		"translit" : "doûlos",
		"lemma" : "δοῦλος",
		"derivation" : "from G1210 (δέω)",
		"def" : "a slave (literal or figurative, involuntary or voluntary; frequently, therefore in a qualified sense of subjection or subserviency)"
	},
	"G2852" : {
		"derivation" : "from a derivative of the base of G2849 (κολάζω)",
		"def" : "to rap with the fist",
		"kjv" : "buffet",
		"lemma" : "κολαφίζω",
		"translit" : "kolaphízō"
	},
	"G4081" : {
		"def" : "clay",
		"derivation" : "perhaps a primary word",
		"lemma" : "πηλός",
		"translit" : "pēlós",
		"kjv" : "clay"
	},
	"G3549" : {
		"translit" : "nomothetéō",
		"lemma" : "νομοθετέω",
		"kjv" : "establish, receive the law",
		"def" : "to legislate, i.e. (passively) to have (the Mosaic) enactments injoined, be sanctioned (by them)",
		"derivation" : "from G3550 (νομοθέτης)"
	},
	"G5580" : {
		"def" : "a spurious Messiah",
		"derivation" : "from G5571 (ψευδής) and G5547 (Χριστός)",
		"translit" : "pseudóchristos",
		"lemma" : "ψευδόχριστος",
		"kjv" : "false Christ"
	},
	"G965" : {
		"def" : "Bethleem (i.e. Beth-lechem), a place in Palestine",
		"derivation" : "of Hebrew origin (H1036)",
		"translit" : "Bēthleém",
		"lemma" : "Βηθλεέμ",
		"kjv" : "Bethlehem"
	},
	"G860" : {
		"def" : "probably a ligament (as fastening)",
		"derivation" : "from G680 (ἅπτομαι)",
		"translit" : "haphḗ",
		"lemma" : "ἁφή",
		"kjv" : "joint"
	},
	"G5577" : {
		"def" : "untrue testimony",
		"derivation" : "from G5575 (ψευδομάρτυρ)",
		"lemma" : "ψευδομαρτυρία",
		"translit" : "pseudomartyría",
		"kjv" : "false witness"
	},
	"G1780" : {
		"translit" : "entaphiasmós",
		"lemma" : "ἐνταφιασμός",
		"kjv" : "burying",
		"def" : "preparation for interment",
		"derivation" : "from G1779 (ἐνταφιάζω)"
	},
	"G142" : {
		"kjv" : "away with, bear (up), carry, lift up, loose, make to doubt, put away, remove, take (away, up)",
		"lemma" : "αἴρω",
		"translit" : "aírō",
		"derivation" : "a primary root",
		"def" : "to lift up; by implication, to take up or away; figuratively, to raise (the voice), keep in suspense (the mind), specially, to sail away (i.e. weigh anchor); by Hebraism (compare H5375) to expiate sin"
	},
	"G4101" : {
		"translit" : "pistikós",
		"lemma" : "πιστικός",
		"kjv" : "spike-(nard)",
		"def" : "trustworthy, i.e. genuine (unadulterated)",
		"derivation" : "from G4102 (πίστις)"
	},
	"G4066" : {
		"kjv" : "country (round) about, region (that lieth) round about",
		"lemma" : "περίχωρος",
		"translit" : "períchōros",
		"derivation" : "from G4012 (περί) and G5561 (χώρα)",
		"def" : "around the region, i.e. circumjacent (as noun, with G1093 (γῆ) implied vicinity)"
	},
	"G2731" : {
		"kjv" : "dwelling",
		"lemma" : "κατοίκησις",
		"translit" : "katoíkēsis",
		"derivation" : "from G2730 (κατοικέω)",
		"def" : "residence (properly, the act; but by implication, concretely, the mansion)"
	},
	"G2292" : {
		"derivation" : "another form for G2293 (θαρσέω)",
		"def" : "to exercise courage",
		"kjv" : "be bold, X boldly, have confidence, be confident",
		"translit" : "tharrhéō",
		"lemma" : "θαῤῥέω"
	},
	"G5522" : {
		"kjv" : "dust",
		"lemma" : "χόος",
		"translit" : "chóos",
		"derivation" : "from the base of G5494 (χειμών)",
		"def" : "a heap (as poured out), i.e. rubbish; loose dirt"
	},
	"G5262" : {
		"translit" : "hypódeigma",
		"lemma" : "ὑπόδειγμα",
		"kjv" : "en-(ex-)ample, pattern",
		"def" : "an exhibit for imitation or warning (figuratively, specimen, adumbration)",
		"derivation" : "from G5263 (ὑποδείκνυμι)"
	},
	"G465" : {
		"derivation" : "from a compound of G473 (ἀντί) and G236 (ἀλλάσσω)",
		"def" : "an equivalent or ransom",
		"kjv" : "in exchange",
		"lemma" : "ἀντάλλαγμα",
		"translit" : "antállagma"
	},
	"G1677" : {
		"kjv" : "impute, put on account",
		"lemma" : "ἐλλογέω",
		"translit" : "ellogéō",
		"derivation" : "from G1722 (ἐν) and G3056 (λόγος) (in the sense of account)",
		"def" : "to reckon in, i.e. attribute"
	},
	"G2420" : {
		"translit" : "hierōsýnē",
		"lemma" : "ἱερωσύνη",
		"kjv" : "priesthood",
		"def" : "sacredness, i.e. (by implication) the priestly office",
		"derivation" : "from G2413 (ἱερός)"
	},
	"G1879" : {
		"derivation" : "middle voice from G1909 (ἐπί) and G373 (ἀναπαύω)",
		"def" : "to settle on; literally (remain) or figuratively (rely)",
		"kjv" : "rest in (upon)",
		"lemma" : "ἐπαναπαύομαι",
		"translit" : "epanapaúomai"
	},
	"G1489" : {
		"kjv" : "if (so be that, yet)",
		"lemma" : "εἴγε",
		"translit" : "eíge",
		"derivation" : "from G1487 (εἰ) and G1065 (γέ)",
		"def" : "if indeed, seeing that, unless, (with negative) otherwise"
	},
	"G5149" : {
		"translit" : "trízō",
		"lemma" : "τρίζω",
		"kjv" : "gnash",
		"def" : "to creak (squeak), i.e. (by analogy) to grate the teeth (in frenzy)",
		"derivation" : "apparently a primary verb"
	},
	"G5173" : {
		"translit" : "Tryphōsa",
		"lemma" : "Τρυφῶσα",
		"kjv" : "Tryphosa",
		"def" : "luxuriating; Tryphosa, a Christian female",
		"derivation" : "from G5172 (τρυφή)"
	},
	"G3789" : {
		"lemma" : "ὄφις",
		"translit" : "óphis",
		"kjv" : "serpent",
		"def" : "a snake, figuratively, (as a type of sly cunning) an artful malicious person, especially Satan",
		"derivation" : "probably from G3700 (ὀπτάνομαι) (through the idea of sharpness of vision)"
	},
	"G203" : {
		"derivation" : "from G206 (ἄκρον) and probably a modified form of (the penis or male sexual organ)",
		"def" : "the prepuce; by implication, an uncircumcised (i.e. gentile, figuratively, unregenerate) state or person",
		"kjv" : "not circumcised, uncircumcised (with G2192 (ἔχω)), uncircumcision",
		"translit" : "akrobystía",
		"lemma" : "ἀκροβυστία"
	},
	"G4478" : {
		"lemma" : "Ῥαχήλ",
		"translit" : "Rhachḗl",
		"kjv" : "Rachel",
		"def" : "Rachel, the wife of Jacob",
		"derivation" : "of Hebrew origin (H7354)"
	},
	"G2516" : {
		"kjv" : "sit",
		"lemma" : "καθέζομαι",
		"translit" : "kathézomai",
		"derivation" : "from G2596 (κατά) and the base of G1476 (ἑδραῖος)",
		"def" : "to sit down"
	},
	"G4493" : {
		"kjv" : "twinkling",
		"lemma" : "ῥιπή",
		"translit" : "rhipḗ",
		"derivation" : "from G4496 (ῥίπτω)",
		"def" : "a jerk (of the eye, i.e. (by analogy) an instant)"
	},
	"G5244" : {
		"def" : "appearing above others (conspicuous), i.e. (figuratively) haughty",
		"derivation" : "from G5228 (ὑπέρ) and G5316 (φαίνω)",
		"lemma" : "ὑπερήφανος",
		"translit" : "hyperḗphanos",
		"kjv" : "proud"
	},
	"G3713" : {
		"translit" : "orégomai",
		"lemma" : "ὀρέγομαι",
		"kjv" : "covet after, desire",
		"def" : "to stretch oneself, i.e. reach out after (long for)",
		"derivation" : "middle voice of apparently a prolonged form of an obsolete primary (compare G3735 (ὄρος))"
	},
	"G29" : {
		"derivation" : "of foreign origin (compare H104)",
		"def" : "properly, to be a courier, i.e. (by implication) to press into public service",
		"kjv" : "compel (to go)",
		"lemma" : "ἀγγαρεύω",
		"translit" : "angareúō"
	},
	"G985" : {
		"lemma" : "βλαστάνω",
		"translit" : "blastánō",
		"kjv" : "bring forth, bud, spring (up)",
		"def" : "to germinate; by implication, to yield fruit",
		"derivation" : "from (a sprout)"
	},
	"G2061" : {
		"derivation" : "from G2060 (Ἑρμῆς) and G1096 (γίνομαι)",
		"def" : "born of Hermes; Hermogenes, an apostate Christian",
		"kjv" : "Hermogenes",
		"lemma" : "Ἑρμογένης",
		"translit" : "Hermogénēs"
	},
	"G2769" : {
		"def" : "something horned, i.e. (specially) the pod of the carob-tree",
		"derivation" : "neuter of a presumed derivative of G2768 (κέρας)",
		"translit" : "kerátion",
		"lemma" : "κεράτιον",
		"kjv" : "husk"
	},
	"G4744" : {
		"lemma" : "στίλβω",
		"translit" : "stílbō",
		"kjv" : "shining",
		"def" : "to gleam, i.e. flash intensely",
		"derivation" : "apparently a primary verb"
	},
	"G5441" : {
		"translit" : "phýlax",
		"lemma" : "φύλαξ",
		"kjv" : "keeper",
		"def" : "a watcher or sentry",
		"derivation" : "from G5442 (φυλάσσω)"
	},
	"G4963" : {
		"def" : "a twisting together, i.e. (figuratively) a secret coalition, riotous crowd",
		"derivation" : "from G4962 (συστρέφω)",
		"lemma" : "συστροφή",
		"translit" : "systrophḗ",
		"kjv" : "+ band together, concourse"
	},
	"G3322" : {
		"def" : "to form the middle, i.e. (in point of time), to be half-way over",
		"derivation" : "from G3319 (μέσος)",
		"translit" : "mesóō",
		"lemma" : "μεσόω",
		"kjv" : "be about the midst"
	},
	"G316" : {
		"translit" : "anankaîos",
		"lemma" : "ἀναγκαῖος",
		"kjv" : "near, necessary, necessity, needful",
		"def" : "necessary; by implication, close (of kin)",
		"derivation" : "from G318 (ἀνάγκη)"
	},
	"G329" : {
		"def" : "to re-enkindle",
		"derivation" : "from G303 (ἀνά) and a compound of the base of G2226 (ζῶον) and G4442 (πῦρ)",
		"lemma" : "ἀναζωπυρέω",
		"translit" : "anazōpyréō",
		"kjv" : "stir up"
	},
	"G512" : {
		"translit" : "anōphelḗs",
		"lemma" : "ἀνωφελής",
		"kjv" : "unprofitable(-ness)",
		"def" : "useless or (neuter) inutility",
		"derivation" : "from G1 (Α) (as a negative particle) and the base of G5624 (ὠφέλιμος)"
	},
	"G2393" : {
		"derivation" : "probably of foreign origin (see H3471)",
		"def" : "\"jasper\", a gem",
		"kjv" : "jasper",
		"lemma" : "ἴασπις",
		"translit" : "íaspis"
	},
	"G5440" : {
		"translit" : "phylaktḗrion",
		"lemma" : "φυλακτήριον",
		"kjv" : "phylactery",
		"def" : "a guard-case, i.e. \"phylactery\" for wearing slips of Scripture texts",
		"derivation" : "neuter of a derivative of G5442 (φυλάσσω)"
	},
	"G515" : {
		"kjv" : "desire, think good, count (think) worthy",
		"lemma" : "ἀξιόω",
		"translit" : "axióō",
		"derivation" : "from G514 (ἄξιος)",
		"def" : "to deem entitled or fit"
	},
	"G2734" : {
		"def" : "to mirror oneself, i.e. to see reflected (figuratively)",
		"derivation" : "middle voice from a compound of G2596 (κατά) and a derivative of G3700 (ὀπτάνομαι) (compare G2072 (ἔσοπτρον))",
		"lemma" : "κατοπτρίζομαι",
		"translit" : "katoptrízomai",
		"kjv" : "behold as in a glass"
	},
	"G1349" : {
		"translit" : "díkē",
		"lemma" : "δίκη",
		"kjv" : "judgment, punish, vengeance",
		"def" : "right (as self-evident), i.e. justice (the principle, a decision, or its execution)",
		"derivation" : "probably from G1166 (δεικνύω)"
	},
	"G1967" : {
		"def" : "tomorrow's; but more probably from G1909 (ἐπί) and a derivative of the present participle feminine of G1510 (εἰμί); for subsistence, i.e. needful",
		"derivation" : "perhaps from the same as G1966 (ἐπιοῦσα)",
		"lemma" : "ἐπιούσιος",
		"translit" : "epioúsios",
		"kjv" : "daily"
	},
	"G4095" : {
		"derivation" : "a prolonged form of ; which (together with another form) ; occurs only as an alternate in certain tenses",
		"def" : "to imbibe (literally or figuratively)",
		"kjv" : "drink",
		"translit" : "pínō",
		"lemma" : "πίνω"
	},
	"G353" : {
		"translit" : "analambánō",
		"lemma" : "ἀναλαμβάνω",
		"kjv" : "receive up, take (in, unto, up)",
		"def" : "to take up",
		"derivation" : "from G303 (ἀνά) and G2983 (λαμβάνω)"
	},
	"G2007" : {
		"kjv" : "add unto, lade, lay upon, put (up) on, set on (up), + surname, X wound",
		"lemma" : "ἐπιτίθημι",
		"translit" : "epitíthēmi",
		"derivation" : "from G1909 (ἐπί) and G5087 (τίθημι)",
		"def" : "to impose (in a friendly or hostile sense)"
	},
	"G2372" : {
		"def" : "passion (as if breathing hard)",
		"derivation" : "from G2380 (θύω)",
		"lemma" : "θυμός",
		"translit" : "thymós",
		"kjv" : "fierceness, indignation, wrath"
	},
	"G2611" : {
		"kjv" : "bind up",
		"lemma" : "καταδέω",
		"translit" : "katadéō",
		"derivation" : "from G2596 (κατά) and G1210 (δέω)",
		"def" : "to tie down, i.e. bandage (a wound)"
	},
	"G2572" : {
		"kjv" : "cover, hide",
		"lemma" : "καλύπτω",
		"translit" : "kalýptō",
		"derivation" : "akin to G2813 (κλέπτω) and G2928 (κρύπτω)",
		"def" : "to cover up (literally or figuratively)"
	},
	"G5218" : {
		"derivation" : "from G5219 (ὑπακούω)",
		"def" : "attentive hearkening, i.e. (by implication) compliance or submission",
		"kjv" : "obedience, (make) obedient, obey(-ing)",
		"lemma" : "ὑπακοή",
		"translit" : "hypakoḗ"
	},
	"G5500" : {
		"kjv" : "choose, ordain",
		"lemma" : "χειροτονέω",
		"translit" : "cheirotonéō",
		"derivation" : "from a comparative of G5495 (χείρ) and (to stretch)",
		"def" : "to be a hand-reacher or voter (by raising the hand), i.e. (generally) to select or appoint"
	},
	"G1728" : {
		"derivation" : "from G1722 (ἐν) and G756 (ἄρχομαι)",
		"def" : "to commence on",
		"kjv" : "rule (by mistake for G757 (ἄρχω))",
		"translit" : "enárchomai",
		"lemma" : "ἐνάρχομαι"
	},
	"G1619" : {
		"kjv" : "fervently",
		"lemma" : "ἐκτενῶς",
		"translit" : "ektenōs",
		"derivation" : "adverb from G1618 (ἐκτενής)",
		"def" : "intently"
	},
	"G2866" : {
		"derivation" : "neuter comparative of a derivative of the base of 2865 (meaning, properly, well dressed, i.e. nice)",
		"def" : "figuratively, convalescent",
		"kjv" : "+ began to amend",
		"translit" : "kompsóteron",
		"lemma" : "κομψότερον"
	},
	"G3856" : {
		"translit" : "paradeigmatízō",
		"lemma" : "παραδειγματίζω",
		"kjv" : "make a public example, put to an open shame",
		"def" : "to show alongside (the public), i.e. expose to infamy",
		"derivation" : "from G3844 (παρά) and G1165 (δειγματίζω)"
	},
	"G2310" : {
		"kjv" : "foundation",
		"translit" : "themélios",
		"lemma" : "θεμέλιος",
		"derivation" : "from a derivative of G5087 (τίθημι)",
		"def" : "something put down, i.e. a substruction (of a building, etc.), (literally or figuratively)"
	},
	"G4090" : {
		"def" : "bitterly, i.e. (figuratively) violently",
		"derivation" : "adverb from G4089 (πικρός)",
		"translit" : "pikrōs",
		"lemma" : "πικρῶς",
		"kjv" : "bitterly"
	},
	"G4087" : {
		"lemma" : "πικραίνω",
		"translit" : "pikraínō",
		"kjv" : "be (make) bitter",
		"def" : "to embitter (literally or figuratively)",
		"derivation" : "from G4089 (πικρός)"
	},
	"G3991" : {
		"derivation" : "from G4002 (πέντε)",
		"def" : "fifth",
		"kjv" : "fifth",
		"lemma" : "πέμπτος",
		"translit" : "pémptos"
	},
	"G5124" : {
		"def" : "that thing",
		"derivation" : "neuter singular nominative or accusative case of G3778 (οὗτος)",
		"translit" : "toûto",
		"lemma" : "τοῦτο",
		"kjv" : "here (-unto), it, partly, self(-same), so, that (intent), the same, there(-fore, -unto), this, thus, where(-fore)"
	},
	"G2943" : {
		"derivation" : "adverb from the same as G2945 (κύκλῳ)",
		"def" : "from the circle, i.e. all around",
		"kjv" : "(round) about",
		"lemma" : "κυκλόθεν",
		"translit" : "kyklóthen"
	},
	"G937" : {
		"derivation" : "from G935 (βασιλεύς)",
		"def" : "regal (in relation), i.e. (literally) belonging to (or befitting) the sovereign (as land, dress, or a courtier), or (figuratively) preeminent",
		"kjv" : "king's, nobleman, royal",
		"translit" : "basilikós",
		"lemma" : "βασιλικός"
	},
	"G905" : {
		"lemma" : "βαλάντιον",
		"translit" : "balántion",
		"kjv" : "bag, purse",
		"def" : "a pouch (for money)",
		"derivation" : "probably remotely from G906 (βάλλω) (as a depository)"
	},
	"G2462" : {
		"def" : "a horse",
		"derivation" : "of uncertain affinity",
		"lemma" : "ἵππος",
		"translit" : "híppos",
		"kjv" : "horse"
	},
	"G3986" : {
		"def" : "a putting to proof (by experiment (of good), experience (of evil), solicitation, discipline or provocation); by implication, adversity",
		"derivation" : "from G3985 (πειράζω)",
		"lemma" : "πειρασμός",
		"translit" : "peirasmós",
		"kjv" : "temptation, X try"
	},
	"G4293" : {
		"def" : "to anounce beforehand, i.e. predict, promise",
		"derivation" : "from G4253 (πρό) and G2605 (καταγγέλλω)",
		"translit" : "prokatangéllō",
		"lemma" : "προκαταγγέλλω",
		"kjv" : "foretell, have notice, (shew) before"
	},
	"G4380" : {
		"derivation" : "from G4381 (προσωπολήπτης)",
		"def" : "to favor an individual, i.e. show partiality",
		"kjv" : "have respect to persons",
		"translit" : "prosōpolēptéō",
		"lemma" : "προσωποληπτέω"
	},
	"G2325" : {
		"derivation" : "from G2330 (θέρος) (in the sense of the crop)",
		"def" : "to harvest",
		"kjv" : "reap",
		"translit" : "therízō",
		"lemma" : "θερίζω"
	},
	"G1221" : {
		"kjv" : "(what-)soever",
		"translit" : "dḗpote",
		"lemma" : "δήποτε",
		"derivation" : "from G1211 (δή) and G4218 (ποτέ)",
		"def" : "a particle of generalization; indeed, at any time"
	},
	"G5604" : {
		"def" : "a pang or throe, especially of childbirth",
		"derivation" : "akin to G3601 (ὀδύνη)",
		"lemma" : "ὠδίν",
		"translit" : "ōdín",
		"kjv" : "pain, sorrow, travail"
	},
	"G493" : {
		"derivation" : "contracted for a compound of G473 (ἀντί) and a derivative of G3962 (πατήρ)",
		"def" : "Antipas, a Christian",
		"kjv" : "Antipas",
		"lemma" : "Ἀντίπας",
		"translit" : "Antípas"
	},
	"G4364" : {
		"kjv" : "make as though",
		"translit" : "prospoiéomai",
		"lemma" : "προσποιέομαι",
		"derivation" : "middle voice from G4314 (πρός) and G4160 (ποιέω)",
		"def" : "to do forward for oneself, i.e. pretend (as if about to do a thing)"
	},
	"G3718" : {
		"kjv" : "rightly divide",
		"translit" : "orthotoméō",
		"lemma" : "ὀρθοτομέω",
		"derivation" : "from a compound of G3717 (ὀρθός) and the base of G5114 (τομώτερος), to make a straight cut, i.e. (figuratively) to dissect (expound) correctly (the divine message)"
	},
	"G3008" : {
		"kjv" : "minister",
		"lemma" : "λειτουργέω",
		"translit" : "leitourgéō",
		"derivation" : "from G3011 (λειτουργός)",
		"def" : "to be a public servant, i.e. (by analogy) to perform religious or charitable functions (worship, obey, relieve)"
	},
	"G1502" : {
		"lemma" : "εἴκω",
		"translit" : "eíkō",
		"kjv" : "give place",
		"def" : "properly, to be weak, i.e. yield",
		"derivation" : "apparently a primary verb"
	},
	"G735" : {
		"def" : "prompt; Artemis, the name of a Grecian goddess borrowed by the Asiatics for one of their deities",
		"derivation" : "probably from the same as G736 (ἀρτέμων)",
		"translit" : "Ártemis",
		"lemma" : "Ἄρτεμις",
		"kjv" : "Diana"
	},
	"G3511" : {
		"lemma" : "νεωκόρος",
		"translit" : "neōkóros",
		"kjv" : "worshipper",
		"def" : "a temple-servant, i.e. (by implication) a votary",
		"derivation" : "from a form of G3485 (ναός) and (to sweep)"
	},
	"G1050" : {
		"derivation" : "of Latin origin",
		"def" : "Gaius (i.e. Caius), a Christian",
		"kjv" : "Gaius",
		"translit" : "Gáïos",
		"lemma" : "Γάϊος"
	},
	"G2182" : {
		"translit" : "epheuretḗs",
		"lemma" : "ἐφευρετής",
		"kjv" : "inventor",
		"def" : "a discoverer, i.e. contriver",
		"derivation" : "from a compound of G1909 (ἐπί) and G2147 (εὑρίσκω)"
	},
	"G3688" : {
		"derivation" : "apparently a primary word",
		"def" : "a donkey",
		"kjv" : "an ass",
		"lemma" : "ὄνος",
		"translit" : "ónos"
	},
	"G1602" : {
		"derivation" : "from G1537 (ἐκ) and G4126 (πλέω)",
		"def" : "to depart by ship",
		"kjv" : "sail (away, thence)",
		"lemma" : "ἐκπλέω",
		"translit" : "ekpléō"
	},
	"G1808" : {
		"kjv" : "put (take) away",
		"translit" : "exaírō",
		"lemma" : "ἐξαίρω",
		"derivation" : "from G1537 (ἐκ) and G142 (αἴρω)",
		"def" : "to remove"
	},
	"G3701" : {
		"def" : "visuality, i.e. (concretely) an apparition",
		"derivation" : "from a presumed derivative of G3700 (ὀπτάνομαι)",
		"translit" : "optasía",
		"lemma" : "ὀπτασία",
		"kjv" : "vision"
	},
	"G4876" : {
		"derivation" : "from G4862 (σύν) and a derivative of G473 (ἀντί)",
		"def" : "to meet with; figuratively, to occur",
		"kjv" : "befall, meet",
		"translit" : "synantáō",
		"lemma" : "συναντάω"
	},
	"G1923" : {
		"derivation" : "from G1924 (ἐπιγράφω)",
		"def" : "an inscription",
		"kjv" : "superscription",
		"lemma" : "ἐπιγραφή",
		"translit" : "epigraphḗ"
	},
	"G1314" : {
		"def" : "to guard thoroughly, i.e. protect",
		"derivation" : "from G1223 (διά) and G5442 (φυλάσσω)",
		"translit" : "diaphylássō",
		"lemma" : "διαφυλάσσω",
		"kjv" : "keep"
	},
	"G4815" : {
		"lemma" : "συλλαμβάνω",
		"translit" : "syllambánō",
		"kjv" : "catch, conceive, help, take",
		"def" : "to clasp, i.e. seize (arrest, capture); specially, to conceive (literally or figuratively); by implication, to aid",
		"derivation" : "from G4862 (σύν) and G2983 (λαμβάνω)"
	},
	"G4892" : {
		"kjv" : "council",
		"translit" : "synédrion",
		"lemma" : "συνέδριον",
		"derivation" : "neuter of a presumed derivative of a compound of G4862 (σύν) and the base of G1476 (ἑδραῖος)",
		"def" : "a joint session, i.e. (specially), the Jewish Sanhedrin; by analogy, a subordinate tribunal"
	},
	"G2957" : {
		"lemma" : "Κυρήνη",
		"translit" : "Kyrḗnē",
		"kjv" : "Cyrene",
		"def" : "Cyrene, a region of Africa",
		"derivation" : "of uncertain derivation"
	},
	"G2690" : {
		"kjv" : "overthrow",
		"lemma" : "καταστρέφω",
		"translit" : "katastréphō",
		"derivation" : "from G2596 (κατά) and G4762 (στρέφω)",
		"def" : "to turn upside down, i.e. upset"
	},
	"G2829" : {
		"derivation" : "from G2813 (κλέπτω)",
		"def" : "stealing",
		"kjv" : "theft",
		"lemma" : "κλοπή",
		"translit" : "klopḗ"
	},
	"G3123" : {
		"def" : "(adverbially) more (in a greater degree)) or rather",
		"derivation" : "neuter of the comparative of the same as G3122 (μάλιστα)",
		"lemma" : "μᾶλλον",
		"translit" : "mâllon",
		"kjv" : "+ better, X far, (the) more (and more), (so) much (the more), rather"
	},
	"G2984" : {
		"derivation" : "of Hebrew origin (H3929)",
		"def" : "Lamech (i.e. Lemek), a patriarch",
		"kjv" : "Lamech",
		"translit" : "Lámech",
		"lemma" : "Λάμεχ"
	},
	"G3562" : {
		"translit" : "nounechōs",
		"lemma" : "νουνεχῶς",
		"kjv" : "discreetly",
		"def" : "in a mind-having way, i.e. prudently",
		"derivation" : "adverb from a comparative of the accusative case of G3563 (νοῦς) and G2192 (ἔχω)"
	},
	"G640" : {
		"def" : "a (state of) quandary",
		"derivation" : "from the same as G639 (ἀπορέω)",
		"translit" : "aporía",
		"lemma" : "ἀπορία",
		"kjv" : "perplexity"
	},
	"G2624" : {
		"derivation" : "from G2596 (κατά) and a derivative of a compound of G2819 (κλῆρος) and G1325 (δίδωμι)",
		"def" : "to be a giver of lots to each, i.e. (by implication) to apportion an estate",
		"kjv" : "divide by lot",
		"translit" : "kataklērodotéō",
		"lemma" : "κατακληροδοτέω"
	},
	"G1460" : {
		"translit" : "enkatoikéō",
		"lemma" : "ἐγκατοικέω",
		"kjv" : "dwell among",
		"def" : "to settle down in a place, i.e. reside",
		"derivation" : "from G1722 (ἐν) and G2730 (κατοικέω)"
	},
	"G641" : {
		"derivation" : "from G575 (ἀπό) and G4496 (ῥίπτω)",
		"def" : "to hurl off, i.e. precipitate (oneself)",
		"kjv" : "cast",
		"translit" : "aporrhíptō",
		"lemma" : "ἀποῤῥίπτω"
	},
	"G3963" : {
		"lemma" : "Πάτμος",
		"translit" : "Pátmos",
		"kjv" : "Patmos",
		"def" : "Patmus, an islet in the Mediterranean",
		"derivation" : "of uncertain derivation"
	},
	"G807" : {
		"kjv" : "behave self uncomely (unseemly)",
		"translit" : "aschēmonéō",
		"lemma" : "ἀσχημονέω",
		"derivation" : "from G809 (ἀσχήμων)",
		"def" : "to be (i.e. act) unbecoming"
	},
	"G1282" : {
		"kjv" : "cut (to the heart)",
		"lemma" : "διαπρίω",
		"translit" : "diapríō",
		"derivation" : "from G1223 (διά) and the base of G4249 (πρίζω)",
		"def" : "to saw asunder, i.e. (figuratively) to exasperate"
	},
	"G772" : {
		"derivation" : "from G1 (Α) (as a negative particle) and the base of G4599 (σθενόω)",
		"def" : "strengthless (in various applications, literal, figurative and moral)",
		"kjv" : "more feeble, impotent, sick, without strength, weak(-er, -ness, thing)",
		"lemma" : "ἀσθενής",
		"translit" : "asthenḗs"
	},
	"G2270" : {
		"kjv" : "cease, hold peace, be quiet, rest",
		"lemma" : "ἡσυχάζω",
		"translit" : "hēsycházō",
		"derivation" : "from the same as G2272 (ἡσύχιος)",
		"def" : "to keep still (intransitively), i.e. refrain from labor, meddlesomeness or speech"
	},
	"G842" : {
		"derivation" : "from G846 (αὐτός) and G714 (ἀρκέω)",
		"def" : "self-complacent, i.e. contented",
		"kjv" : "content",
		"translit" : "autárkēs",
		"lemma" : "αὐτάρκης"
	},
	"G1919" : {
		"kjv" : "earthly, in earth, terrestrial",
		"lemma" : "ἐπίγειος",
		"translit" : "epígeios",
		"derivation" : "from G1909 (ἐπί) and G1093 (γῆ)",
		"def" : "worldly (physically or morally)"
	},
	"G1951" : {
		"translit" : "epilégomai",
		"lemma" : "ἐπιλέγομαι",
		"kjv" : "call, choose",
		"def" : "to surname, select",
		"derivation" : "middle voice from G1909 (ἐπί) and G3004 (λέγω)"
	},
	"G3740" : {
		"kjv" : "as oft(-en) as",
		"translit" : "hosákis",
		"lemma" : "ὁσάκις",
		"derivation" : "multiple adverb from G3739 (ὅς)",
		"def" : "how (i.e. with G302 (ἄν), so) many times as"
	},
	"G4211" : {
		"def" : "a female trader in purple cloth",
		"derivation" : "feminine of a compound of G4209 (πορφύρα) and G4453 (πωλέω)",
		"lemma" : "πορφυρόπωλις",
		"translit" : "porphyrópōlis",
		"kjv" : "seller of purple"
	},
	"G4839" : {
		"kjv" : "continue with",
		"lemma" : "συμπαραμένω",
		"translit" : "symparaménō",
		"derivation" : "from G4862 (σύν) and G3887 (παραμένω)",
		"def" : "to remain in company, i.e. still live"
	},
	"G3716" : {
		"kjv" : "walk uprightly",
		"translit" : "orthopodéō",
		"lemma" : "ὀρθοποδέω",
		"derivation" : "from a compound of G3717 (ὀρθός) and G4228 (πούς)",
		"def" : "to be straight-footed, i.e. (figuratively) to go directly forward"
	},
	"G1424" : {
		"def" : "the sun-set, i.e. (by implication) the western region",
		"derivation" : "from G1416 (δύνω)",
		"translit" : "dysmḗ",
		"lemma" : "δυσμή",
		"kjv" : "west"
	},
	"G2068" : {
		"lemma" : "ἐσθίω",
		"translit" : "esthíō",
		"kjv" : "devour, eat, live",
		"def" : "used only in certain tenses, the rest being supplied by G5315 (φάγω); to eat (usually literal)",
		"derivation" : "strengthened for a primary (to eat)"
	},
	"G3496" : {
		"derivation" : "from G3501 (νέος) and G4172 (πόλις)",
		"def" : "new town; Neapolis, a place in Macedonia",
		"kjv" : "Neapolis",
		"lemma" : "Νεάπολις",
		"translit" : "Neápolis"
	},
	"G4785" : {
		"kjv" : "number with",
		"lemma" : "συγκαταψηφίζω",
		"translit" : "synkatapsēphízō",
		"derivation" : "from G4862 (σύν) and a compound of G2596 (κατά) and G5585 (ψηφίζω)",
		"def" : "to count down in company with, i.e. enroll among"
	},
	"G1885" : {
		"kjv" : "province",
		"lemma" : "ἐπαρχία",
		"translit" : "eparchía",
		"derivation" : "from a compound of G1909 (ἐπί) and G757 (ἄρχω) (meaning a governor of a district, \"eparch\")",
		"def" : "a special region of government, i.e. a Roman præfecture"
	},
	"G679" : {
		"def" : "not stumbling, i.e. (figuratively) without sin",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G4417 (πταίω)",
		"translit" : "áptaistos",
		"lemma" : "ἄπταιστος",
		"kjv" : "from falling"
	},
	"G4147" : {
		"derivation" : "from G4148 (πλουτίζω)",
		"def" : "to be (or become) wealthy (literally or figuratively)",
		"kjv" : "be increased with goods, (be made, wax) rich",
		"lemma" : "πλουτέω",
		"translit" : "ploutéō"
	},
	"G5199" : {
		"lemma" : "ὑγιής",
		"translit" : "hygiḗs",
		"kjv" : "sound, whole",
		"def" : "healthy, i.e. well (in body); figuratively, true (in doctrine)",
		"derivation" : "from the base of G837 (αὐξάνω)"
	},
	"G615" : {
		"kjv" : "put to death, kill, slay",
		"translit" : "apokteínō",
		"lemma" : "ἀποκτείνω",
		"derivation" : "from G575 (ἀπό) and (to slay)",
		"def" : "to kill outright; figuratively, to destroy"
	},
	"G207" : {
		"def" : "Akulas, an Israelite",
		"derivation" : "probably for Latin aquila (an eagle)",
		"translit" : "Akýlas",
		"lemma" : "Ἀκύλας",
		"kjv" : "Aquila"
	},
	"G3900" : {
		"lemma" : "παράπτωμα",
		"translit" : "paráptōma",
		"kjv" : "fall, fault, offence, sin, trespass",
		"def" : "a side-slip (lapse or deviation), i.e. (unintentional) error or (wilful) transgression",
		"derivation" : "from G3895 (παραπίπτω)"
	},
	"G445" : {
		"lemma" : "ἀνθυπατεύω",
		"translit" : "anthypateúō",
		"kjv" : "be the deputy",
		"def" : "to act as proconsul",
		"derivation" : "from G446 (ἀνθύπατος)"
	},
	"G4123" : {
		"kjv" : "covetous",
		"lemma" : "πλεονέκτης",
		"translit" : "pleonéktēs",
		"derivation" : "from G4119 (πλείων) and G2192 (ἔχω)",
		"def" : "holding (desiring) more, i.e. eager for gain (avaricious, hence a defrauder)"
	},
	"G5393" : {
		"def" : "blazing; Phlegon, a Christian",
		"derivation" : "active participle of the base of G5395 (φλόξ)",
		"lemma" : "Φλέγων",
		"translit" : "Phlégōn",
		"kjv" : "Phlegon"
	},
	"G1901" : {
		"lemma" : "ἐπεκτείνομαι",
		"translit" : "epekteínomai",
		"kjv" : "reach forth",
		"def" : "to stretch (oneself) forward upon",
		"derivation" : "middle voice from G1909 (ἐπί) and G1614 (ἐκτείνω)"
	},
	"G5400" : {
		"kjv" : "fearful sight",
		"lemma" : "φόβητρον",
		"translit" : "phóbētron",
		"derivation" : "neuter of a derivative of G5399 (φοβέω)",
		"def" : "a frightening thing, i.e. terrific portent"
	},
	"G21" : {
		"translit" : "agalliáō",
		"lemma" : "ἀγαλλιάω",
		"kjv" : "be (exceeding) glad, with exceeding joy, rejoice (greatly)",
		"def" : "properly, to jump for joy, i.e. exult",
		"derivation" : "from agan (much) and G242 (ἅλλομαι)"
	},
	"G3518" : {
		"def" : "Neri (i.e. Nerijah), an Israelite",
		"derivation" : "of Hebrew origin (H5374)",
		"lemma" : "Νηρί",
		"translit" : "Nērí",
		"kjv" : "Neri"
	},
	"G3570" : {
		"lemma" : "νυνί",
		"translit" : "nyní",
		"kjv" : "now",
		"def" : "just now",
		"derivation" : "a prolonged form of G3568 (νῦν) for emphasis"
	},
	"G3798" : {
		"kjv" : "even(-ing, (-tide))",
		"translit" : "ópsios",
		"lemma" : "ὄψιος",
		"derivation" : "from G3796 (ὀψέ)",
		"def" : "late; feminine (as noun) afternoon (early eve) or nightfall (later eve)"
	},
	"G4268" : {
		"lemma" : "πρόγνωσις",
		"translit" : "prógnōsis",
		"kjv" : "foreknowledge",
		"def" : "forethought",
		"derivation" : "from G4267 (προγινώσκω)"
	},
	"G2916" : {
		"kjv" : "barley",
		"lemma" : "κρίθινος",
		"translit" : "kríthinos",
		"derivation" : "from G2915 (κριθή)",
		"def" : "consisting of barley"
	},
	"G392" : {
		"derivation" : "from G303 (ἀνά) and the middle voice of G5021 (τάσσω)",
		"def" : "to arrange",
		"kjv" : "set in order",
		"translit" : "anatássomai",
		"lemma" : "ἀνατάσσομαι"
	},
	"G3378" : {
		"kjv" : "neither (followed by no), + never, not",
		"lemma" : "μὴ οὐκ",
		"translit" : "mḕ ouk",
		"derivation" : "i.e. G3361 (μή) and G3756 (οὐ)",
		"def" : "as interrogative and negative, is it not that?"
	},
	"G5401" : {
		"kjv" : "be afraid, + exceedingly, fear, terror",
		"translit" : "phóbos",
		"lemma" : "φόβος",
		"derivation" : "from a primary (to be put in fear)",
		"def" : "alarm or fright"
	},
	"G854" : {
		"kjv" : "vanish away",
		"lemma" : "ἀφανισμός",
		"translit" : "aphanismós",
		"derivation" : "from G853 (ἀφανίζω)",
		"def" : "disappearance, i.e. (figuratively) abrogation"
	},
	"G2247" : {
		"derivation" : "of uncertain affinity",
		"def" : "a stud, i.e. spike",
		"kjv" : "nail",
		"lemma" : "ἧλος",
		"translit" : "hēlos"
	},
	"G789" : {
		"derivation" : "probably of foreign origin",
		"def" : "Assus, a city of Asia MInor",
		"kjv" : "Assos",
		"translit" : "Ássos",
		"lemma" : "Ἄσσος"
	},
	"G3885" : {
		"translit" : "paralytikós",
		"lemma" : "παραλυτικός",
		"kjv" : "that had (sick of) the palsy",
		"def" : "as if dissolved, i.e. \"paralytic\"",
		"derivation" : "from a derivative of G3886 (παραλύω)"
	},
	"G923" : {
		"def" : "son of Sabas (or Tsaba); Bar-sabas, the name of two Israelites",
		"derivation" : "of Chaldee origin (H1247 and probably H6634)",
		"translit" : "Barsabâs",
		"lemma" : "Βαρσαβᾶς",
		"kjv" : "Barsabas"
	},
	"G2101" : {
		"derivation" : "from G2095 (εὖ) and G701 (ἀρεστός)",
		"def" : "fully agreeable",
		"kjv" : "acceptable(-ted), wellpleasing",
		"lemma" : "εὐάρεστος",
		"translit" : "euárestos"
	},
	"G34" : {
		"derivation" : "from G71 (ἄγω) (compare G32 (ἄγγελος))",
		"def" : "a drove",
		"kjv" : "herd",
		"translit" : "agélē",
		"lemma" : "ἀγέλη"
	},
	"G3395" : {
		"def" : "a compound",
		"derivation" : "from G3396 (μίγνυμι)",
		"lemma" : "μίγμα",
		"translit" : "mígma",
		"kjv" : "mixture"
	},
	"G2834" : {
		"def" : "Cnidus, a place in Asia Minor",
		"derivation" : "probably of foreign origin",
		"lemma" : "Κνίδος",
		"translit" : "Knídos",
		"kjv" : "Cnidus"
	},
	"G3805" : {
		"derivation" : "from the same as G3804 (πάθημα)",
		"def" : "liable (i.e. doomed) to experience pain",
		"kjv" : "suffer",
		"translit" : "pathētós",
		"lemma" : "παθητός"
	},
	"G1962" : {
		"kjv" : "consent",
		"lemma" : "ἐπινεύω",
		"translit" : "epineúō",
		"derivation" : "from G1909 (ἐπί) and G3506 (νεύω)",
		"def" : "to nod at, i.e. (by implication) to assent"
	},
	"G755" : {
		"kjv" : "governor (ruler) of the feast",
		"lemma" : "ἀρχιτρίκλινος",
		"translit" : "architríklinos",
		"derivation" : "from G746 (ἀρχή) and a compound of G5140 (τρεῖς) and G2827 (κλίνω) (a dinner-bed, because composed of three couches)",
		"def" : "director of the entertainment"
	},
	"G2733" : {
		"def" : "residence (properly, the condition; but by implication, the abode itself)",
		"kjv" : "habitation",
		"lemma" : "κατοικία",
		"translit" : "katoikía"
	},
	"G1008" : {
		"lemma" : "βοτάνη",
		"translit" : "botánē",
		"kjv" : "herb",
		"def" : "herbage (as if for grazing)",
		"derivation" : "from G1006 (βόσκω)"
	},
	"G249" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G3056 (λόγος)",
		"def" : "irrational",
		"kjv" : "brute, unreasonable",
		"translit" : "álogos",
		"lemma" : "ἄλογος"
	},
	"G4419" : {
		"lemma" : "πτερύγιον",
		"translit" : "pterýgion",
		"kjv" : "pinnacle",
		"def" : "a winglet, i.e. (figuratively) extremity (top corner)",
		"derivation" : "neuter of a presumed derivative of G4420 (πτέρυξ)"
	},
	"G583" : {
		"kjv" : "tax, write",
		"lemma" : "ἀπογράφω",
		"translit" : "apográphō",
		"derivation" : "from G575 (ἀπό) and G1125 (γράφω)",
		"def" : "to write off (a copy or list), i.e. enrol"
	},
	"G2807" : {
		"derivation" : "from G2808 (κλείω)",
		"def" : "a key (as shutting a lock), literally or figuratively",
		"kjv" : "key",
		"lemma" : "κλείς",
		"translit" : "kleís"
	},
	"G3344" : {
		"def" : "to turn across, i.e. transmute or (figuratively) corrupt",
		"derivation" : "from G3326 (μετά) and G4762 (στρέφω)",
		"translit" : "metastréphō",
		"lemma" : "μεταστρέφω",
		"kjv" : "pervert, turn"
	},
	"G4425" : {
		"derivation" : "from G4429 (πτύω)",
		"def" : "a winnowing-fork (as scattering like spittle)",
		"kjv" : "fan",
		"lemma" : "πτύον",
		"translit" : "ptýon"
	},
	"G2670" : {
		"translit" : "katapontízō",
		"lemma" : "καταποντίζω",
		"kjv" : "drown, sink",
		"def" : "to plunge down, i.e. submerge",
		"derivation" : "from G2596 (κατά) and a derivative of the same as G4195 (Πόντος)"
	},
	"G3867" : {
		"def" : "to mispraise, i.e. recommend or advise (a different course)",
		"derivation" : "from G3844 (παρά) and G134 (αἰνέω)",
		"lemma" : "παραινέω",
		"translit" : "parainéō",
		"kjv" : "admonish, exhort"
	},
	"G959" : {
		"lemma" : "Βερνίκη",
		"translit" : "Berníkē",
		"kjv" : "Bernice",
		"def" : "victorious; Bernice, a member of the Herodian family",
		"derivation" : "from a provincial form of G5342 (φέρω) and G3529 (νίκη)"
	},
	"G151" : {
		"derivation" : "from G150 (αἰσχρός)",
		"def" : "shamefulness, i.e. obscenity",
		"kjv" : "filthiness",
		"lemma" : "αἰσχρότης",
		"translit" : "aischrótēs"
	},
	"G2522" : {
		"kjv" : "daily",
		"translit" : "kathēmerinós",
		"lemma" : "καθημερινός",
		"derivation" : "from G2596 (κατά) and G2250 (ἡμέρα)",
		"def" : "quotidian"
	},
	"G1096" : {
		"def" : "to cause to be (\"gen\"-erate), i.e. (reflexively) to become (come into being), used with great latitude (literal, figurative, intensive, etc.)",
		"derivation" : "a prolongation and middle voice form of a primary verb",
		"translit" : "gínomai",
		"lemma" : "γίνομαι",
		"kjv" : "arise, be assembled, be(-come, -fall, -have self), be brought (to pass), (be) come (to pass), continue, be divided, draw, be ended, fall, be finished, follow, be found, be fulfilled, + God forbid, grow, happen, have, be kept, be made, be married, be ordained to be, partake, pass, be performed, be published, require, seem, be showed, X soon as it was, sound, be taken, be turned, use, wax, will, would, be wrought"
	},
	"G5456" : {
		"translit" : "phōnḗ",
		"lemma" : "φωνή",
		"kjv" : "noise, sound, voice",
		"def" : "a tone (articulate, bestial or artificial); by implication, an address (for any purpose), saying or language",
		"derivation" : "probably akin to G5316 (φαίνω) through the idea of disclosure"
	},
	"G646" : {
		"derivation" : "feminine of the same as G647 (ἀποστάσιον)",
		"def" : "defection from truth (properly, the state) (\"apostasy\")",
		"kjv" : "falling away, forsake",
		"translit" : "apostasía",
		"lemma" : "ἀποστασία"
	},
	"G2429" : {
		"kjv" : "moisture",
		"lemma" : "ἱκμάς",
		"translit" : "hikmás",
		"derivation" : "of uncertain affinity",
		"def" : "dampness"
	},
	"G657" : {
		"def" : "literally, to say adieu (by departing or dismissing); figuratively, to renounce",
		"derivation" : "middle voice from G575 (ἀπό) and G5021 (τάσσω)",
		"lemma" : "ἀποτάσσομαι",
		"translit" : "apotássomai",
		"kjv" : "bid farewell, forsake, take leave, send away"
	},
	"G4708" : {
		"lemma" : "σπουδαιοτέρως",
		"translit" : "spoudaiotérōs",
		"kjv" : "more carefully",
		"def" : "more speedily, i.e. sooner than otherwise",
		"derivation" : "adverb from G4707 (σπουδαιότερος)"
	},
	"G4458" : {
		"derivation" : "adverb from the base of G4225 (πού)",
		"def" : "an enclitic particle of indefiniteness of manner; somehow or anyhow; used only in composition",
		"kjv" : "haply, by any (some) means, perhaps",
		"translit" : "-pṓs",
		"lemma" : "-πώς"
	},
	"G4783" : {
		"kjv" : "agreement",
		"translit" : "synkatáthesis",
		"lemma" : "συγκατάθεσις",
		"derivation" : "from G4784 (συγκατατίθεμαι)",
		"def" : "a deposition (of sentiment) in company with, i.e. (figuratively) accord with"
	},
	"G2474" : {
		"def" : "Israel (i.e. Jisrael), the adopted name of Jacob, including his descendants (literally or figuratively)",
		"derivation" : "of Hebrew origin (H3478)",
		"lemma" : "Ἰσραήλ",
		"translit" : "Israḗl",
		"kjv" : "Israel"
	},
	"G1933" : {
		"lemma" : "ἐπιεικής",
		"translit" : "epieikḗs",
		"kjv" : "gentle, moderation, patient",
		"def" : "appropriate, i.e. (by implication) mild",
		"derivation" : "from G1909 (ἐπί) and G1503 (εἴκω)"
	},
	"G1770" : {
		"def" : "to nod at, i.e. beckon or communicate by gesture",
		"derivation" : "from G1722 (ἐν) and G3506 (νεύω)",
		"lemma" : "ἐννεύω",
		"translit" : "enneúō",
		"kjv" : "make signs"
	},
	"G2" : {
		"kjv" : "Aaron",
		"lemma" : "Ἀαρών",
		"translit" : "Aarṓn",
		"derivation" : "of Hebrew origin (H175)",
		"def" : "Aaron, the brother of Moses"
	},
	"G385" : {
		"translit" : "anaspáō",
		"lemma" : "ἀνασπάω",
		"kjv" : "draw up, pull out",
		"def" : "to take up or extricate",
		"derivation" : "from G303 (ἀνά) and G4685 (σπάω)"
	},
	"G1116" : {
		"def" : "Gomorrha (i.e. Amorah), a place near the Dead Sea",
		"derivation" : "of Hebrew origin (H6017)",
		"translit" : "Gómorrha",
		"lemma" : "Γόμοῤῥα",
		"kjv" : "Gomorrha"
	},
	"G171" : {
		"def" : "inopportunely",
		"derivation" : "adverb from the same as G170 (ἀκαιρέομαι)",
		"translit" : "akaírōs",
		"lemma" : "ἀκαίρως",
		"kjv" : "out of season"
	},
	"G2894" : {
		"derivation" : "of uncertain derivation",
		"def" : "a (small) basket",
		"kjv" : "basket",
		"lemma" : "κόφινος",
		"translit" : "kóphinos"
	},
	"G1886" : {
		"kjv" : "habitation",
		"lemma" : "ἔπαυλις",
		"translit" : "épaulis",
		"derivation" : "from G1909 (ἐπί) and an equivalent of G833 (αὐλή)",
		"def" : "a hut over the head, i.e. a dwelling"
	},
	"G4769" : {
		"kjv" : "pillar",
		"translit" : "stŷlos",
		"lemma" : "στῦλος",
		"derivation" : "from (to stiffen",
		"def" : "properly akin to the base of G2476 (ἵστημι)); a post (\"style\"), i.e. (figuratively) support"
	},
	"G4166" : {
		"def" : "a shepherd (literally or figuratively)",
		"derivation" : "of uncertain affinity",
		"translit" : "poimḗn",
		"lemma" : "ποιμήν",
		"kjv" : "shepherd, pastor"
	},
	"G3777" : {
		"kjv" : "neither, none, nor (yet), (no, yet) not, nothing",
		"translit" : "oúte",
		"lemma" : "οὔτε",
		"derivation" : "from G3756 (οὐ) and G5037 (τέ)",
		"def" : "not too, i.e. neither or nor; by analogy, not even"
	},
	"G1664" : {
		"def" : "God of majesty; Eliud, an Israelite",
		"derivation" : "of Hebrew origin (H410 and H1935)",
		"translit" : "Elioúd",
		"lemma" : "Ἐλιούδ",
		"kjv" : "Eliud"
	},
	"G2113" : {
		"translit" : "euthydroméō",
		"lemma" : "εὐθυδρομέω",
		"kjv" : "(come) with a straight course",
		"def" : "to lay a straight course, i.e. sail direct",
		"derivation" : "from G2117 (εὐθύς) and G1408 (δρόμος)"
	},
	"G3312" : {
		"derivation" : "from G3307 (μερίζω)",
		"def" : "an apportioner (administrator)",
		"kjv" : "divider",
		"translit" : "meristḗs",
		"lemma" : "μεριστής"
	},
	"G4349" : {
		"def" : "a stumbling, i.e. (figuratively and concretely) occasion of sin",
		"derivation" : "from G4350 (προσκόπτω)",
		"translit" : "proskopḗ",
		"lemma" : "προσκοπή",
		"kjv" : "offence"
	},
	"G4116" : {
		"derivation" : "from G4111 (πλάσσω)",
		"def" : "spread out \"flat\" (\"plot\"), i.e. broad",
		"kjv" : "wide",
		"lemma" : "πλατύς",
		"translit" : "platýs"
	},
	"G1531" : {
		"derivation" : "from G1519 (εἰς) and G4198 (πορεύομαι)",
		"def" : "to enter (literally or figuratively)",
		"kjv" : "come (enter) in, go into",
		"translit" : "eisporeúomai",
		"lemma" : "εἰσπορεύομαι"
	},
	"G5182" : {
		"kjv" : "trouble",
		"lemma" : "τυρβάζω",
		"translit" : "tyrbázō",
		"derivation" : "from (Latin turba, a crowd",
		"def" : "akin to G2351 (θόρυβος)); to make \"turbid\", i.e. disturb"
	},
	"G4647" : {
		"lemma" : "σκόλοψ",
		"translit" : "skólops",
		"kjv" : "thorn",
		"def" : "withered at the front, i.e. a point or prickle (figuratively, a bodily annoyance or disability)",
		"derivation" : "perhaps from the base of G4628 (σκέλος) and G3700 (ὀπτάνομαι)"
	},
	"G2486" : {
		"kjv" : "fish",
		"lemma" : "ἰχθύς",
		"translit" : "ichthýs",
		"derivation" : "of uncertain affinity",
		"def" : "a fish"
	},
	"G2284" : {
		"kjv" : "amaze, astonish",
		"lemma" : "θαμβέω",
		"translit" : "thambéō",
		"derivation" : "from G2285 (θάμβος)",
		"def" : "to stupefy (with surprise), i.e. astound"
	},
	"G341" : {
		"translit" : "anakainóō",
		"lemma" : "ἀνακαινόω",
		"kjv" : "renew",
		"def" : "to renovate",
		"derivation" : "from G303 (ἀνά) and a derivative of G2537 (καινός)"
	},
	"G3949" : {
		"translit" : "parorgízō",
		"lemma" : "παροργίζω",
		"kjv" : "anger, provoke to wrath",
		"def" : "to anger alongside, i.e. enrage",
		"derivation" : "from G3844 (παρά) and G3710 (ὀργίζω)"
	},
	"G2385" : {
		"derivation" : "the same as G2384 (Ἰακώβ) Græcized",
		"def" : "Jacobus, the name of three Israelites",
		"kjv" : "James",
		"lemma" : "Ἰάκωβος",
		"translit" : "Iákōbos"
	},
	"G4171" : {
		"derivation" : "from (to bustle)",
		"def" : "warfare (literally or figuratively; a single encounter or a series)",
		"kjv" : "battle, fight, war",
		"translit" : "pólemos",
		"lemma" : "πόλεμος"
	},
	"G1867" : {
		"def" : "to applaud",
		"derivation" : "from G1909 (ἐπί) and G134 (αἰνέω)",
		"translit" : "epainéō",
		"lemma" : "ἐπαινέω",
		"kjv" : "commend, laud, praise"
	},
	"G2664" : {
		"lemma" : "καταπαύω",
		"translit" : "katapaúō",
		"kjv" : "cease, (give) rest(-rain)",
		"def" : "to settle down, i.e. (literally) to colonize, or (figuratively) to (cause to) desist",
		"derivation" : "from G2596 (κατά) and G3973 (παύω)"
	},
	"G961" : {
		"kjv" : "of Berea",
		"translit" : "Beroiaîos",
		"lemma" : "Βεροιαῖος",
		"derivation" : "from G960 (Βέροια)",
		"def" : "a Berœoean or native of Berœa"
	},
	"G606" : {
		"lemma" : "ἀπόκειμαι",
		"translit" : "apókeimai",
		"kjv" : "be appointed, (be) laid up",
		"def" : "to be reserved; figuratively, to await",
		"derivation" : "from G575 (ἀπό) and G2749 (κεῖμαι)"
	},
	"G1380" : {
		"lemma" : "δοκέω",
		"translit" : "dokéō",
		"kjv" : "be accounted, (of own) please(-ure), be of reputation, seem (good), suppose, think, trow",
		"def" : "compare the base of G1166 (δεικνύω)) of the same meaning; to think; by implication, to seem (truthfully or uncertainly)",
		"derivation" : "a prolonged form of a primary verb, (used only in an alternate in certain tenses"
	},
	"G5041" : {
		"def" : "to be a child-bearer, i.e. parent (mother)",
		"derivation" : "from a compound of G5043 (τέκνον) and the base of G1096 (γίνομαι)",
		"lemma" : "τεκνογονέω",
		"translit" : "teknogonéō",
		"kjv" : "bear children"
	},
	"G4092" : {
		"def" : "to fire, i.e. burn (figuratively and passively, become inflamed with fever)",
		"derivation" : "a reduplicated and prolonged form of a primary ; which occurs only as an alternate in certain tenses)",
		"lemma" : "πίμπρημι",
		"translit" : "pímprēmi",
		"kjv" : "be (X should have) swollen"
	},
	"G140" : {
		"kjv" : "choose",
		"lemma" : "αἱρετίζω",
		"translit" : "hairetízō",
		"derivation" : "from a derivative of G138 (αἱρέομαι)",
		"def" : "to make a choice"
	},
	"G280" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G3358 (μέτρον)",
		"def" : "immoderate",
		"kjv" : "(thing) without measure",
		"translit" : "ámetros",
		"lemma" : "ἄμετρος"
	},
	"G5540" : {
		"derivation" : "from G5530 (χράομαι)",
		"def" : "employment, i.e. (specially), sexual intercourse (as an occupation of the body)",
		"kjv" : "use",
		"lemma" : "χρῆσις",
		"translit" : "chrēsis"
	},
	"G3758" : {
		"kjv" : "ah",
		"lemma" : "οὐά",
		"translit" : "ouá",
		"derivation" : "a primary exclamation of surprise",
		"def" : "\"ah\""
	},
	"G297" : {
		"derivation" : "comparative of (around)",
		"def" : "(in plural) both",
		"kjv" : "both",
		"translit" : "amphóteros",
		"lemma" : "ἀμφότερος"
	},
	"G5461" : {
		"def" : "to shed rays, i.e. to shine or (transitively) to brighten up (literally or figuratively)",
		"derivation" : "from G5457 (φῶς)",
		"lemma" : "φωτίζω",
		"translit" : "phōtízō",
		"kjv" : "enlighten, illuminate, (bring to, give) light, make to see"
	},
	"G3585" : {
		"def" : "wooden",
		"derivation" : "from G3586 (ξύλον)",
		"translit" : "xýlinos",
		"lemma" : "ξύλινος",
		"kjv" : "of wood"
	},
	"G3629" : {
		"kjv" : "merciful, of tender mercy",
		"translit" : "oiktírmōn",
		"lemma" : "οἰκτίρμων",
		"derivation" : "from G3627 (οἰκτείρω)",
		"def" : "compassionate"
	},
	"G4704" : {
		"translit" : "spoudázō",
		"lemma" : "σπουδάζω",
		"kjv" : "do (give) diligence, be diligent (forward), endeavour, labour, study",
		"def" : "to use speed, i.e. to make effort, be prompt or earnest",
		"derivation" : "from G4710 (σπουδή)"
	},
	"G3138" : {
		"derivation" : "of Latin origin",
		"def" : "Marcus, a Christian",
		"kjv" : "Marcus, Mark",
		"translit" : "Márkos",
		"lemma" : "Μάρκος"
	},
	"G1447" : {
		"def" : "Hebraistically or in the Jewish (Chaldee) language",
		"derivation" : "adverb from G1446 (Ἑβραΐς)",
		"translit" : "Hebraïstí",
		"lemma" : "Ἑβραϊστί",
		"kjv" : "in (the) Hebrew (tongue)"
	},
	"G2972" : {
		"kjv" : "Cos",
		"translit" : "Kṓs",
		"lemma" : "Κώς",
		"derivation" : "of uncertain origin",
		"def" : "Cos, an island in the Mediterranean"
	},
	"G4796" : {
		"lemma" : "συγχαίρω",
		"translit" : "synchaírō",
		"kjv" : "rejoice in (with)",
		"def" : "to sympathize in gladness, congratulate",
		"derivation" : "from G4862 (σύν) and G5463 (χαίρω)"
	},
	"G4712" : {
		"kjv" : "furlong, race",
		"lemma" : "στάδιον",
		"translit" : "stádion",
		"derivation" : "from the base of G2476 (ἵστημι) (as fixed)",
		"def" : "a stade or certain measure of distance; by implication, a stadium or race-course"
	},
	"G2969" : {
		"kjv" : "town",
		"translit" : "kōmópolis",
		"lemma" : "κωμόπολις",
		"derivation" : "from G2968 (κώμη) and G4172 (πόλις)",
		"def" : "an unwalled city"
	},
	"G4703" : {
		"def" : "a scattering (of seed), i.e. (concretely) seed (as sown)",
		"derivation" : "from G4687 (σπείρω)",
		"translit" : "spóros",
		"lemma" : "σπόρος",
		"kjv" : "seed (X sown)"
	},
	"G130" : {
		"derivation" : "from G129 (αἷμα) and a derivative of G1632 (ἐκχέω)",
		"def" : "an effusion of blood",
		"kjv" : "shedding of blood",
		"lemma" : "αἱματεκχυσία",
		"translit" : "haimatekchysía"
	},
	"G2155" : {
		"def" : "well compassioned, i.e. sympathetic",
		"derivation" : "from G2095 (εὖ) and G4698 (σπλάγχνον)",
		"translit" : "eúsplanchnos",
		"lemma" : "εὔσπλαγχνος",
		"kjv" : "pitiful, tender-hearted"
	},
	"G5573" : {
		"derivation" : "from G5571 (ψευδής) and G3004 (λέγω)",
		"def" : "mendacious, i.e. promulgating erroneous Christian doctrine",
		"kjv" : "speaking lies",
		"translit" : "pseudológos",
		"lemma" : "ψευδολόγος"
	},
	"G4870" : {
		"def" : "to accompany",
		"derivation" : "from G4862 (σύν) and G190 (ἀκολουθέω)",
		"translit" : "synakolouthéō",
		"lemma" : "συνακολουθέω",
		"kjv" : "follow"
	},
	"G3950" : {
		"kjv" : "wrath",
		"lemma" : "παροργισμός",
		"translit" : "parorgismós",
		"derivation" : "from G3949 (παροργίζω)",
		"def" : "rage"
	},
	"G4846" : {
		"lemma" : "συμπνίγω",
		"translit" : "sympnígō",
		"kjv" : "choke, throng",
		"def" : "to strangle completely, i.e. (literally) to drown, or (figuratively) to crowd",
		"derivation" : "from G4862 (σύν) and G4155 (πνίγω)"
	},
	"G2585" : {
		"lemma" : "καπηλεύω",
		"translit" : "kapēleúō",
		"kjv" : "corrupt",
		"def" : "to retail, i.e. (by implication) to adulterate (figuratively)",
		"derivation" : "from (a huckster)"
	},
	"G695" : {
		"translit" : "argyrokópos",
		"lemma" : "ἀργυροκόπος",
		"kjv" : "silversmith",
		"def" : "a beater (i.e. worker) of silver",
		"derivation" : "from G696 (ἄργυρος) and G2875 (κόπτω)"
	},
	"G4962" : {
		"def" : "to twist together, i.e. collect (a bundle, a crowd)",
		"derivation" : "from G4862 (σύν) and G4762 (στρέφω)",
		"lemma" : "συστρέφω",
		"translit" : "systréphō",
		"kjv" : "gather"
	},
	"G2446" : {
		"derivation" : "of Hebrew origin (H3383)",
		"def" : "the Jordanes (i.e. Jarden), a river of Palestine",
		"kjv" : "Jordan",
		"lemma" : "Ἰορδάνης",
		"translit" : "Iordánēs"
	},
	"G5241" : {
		"derivation" : "from G5228 (ὑπέρ) and G1793 (ἐντυγχάνω)",
		"def" : "to intercede in behalf of",
		"kjv" : "make intercession for",
		"lemma" : "ὑπερεντυγχάνω",
		"translit" : "hyperentynchánō"
	},
	"G3648" : {
		"translit" : "holóklēros",
		"lemma" : "ὁλόκληρος",
		"kjv" : "entire, whole",
		"def" : "complete in every part, i.e. perfectly sound (in body)",
		"derivation" : "from G3650 (ὅλος) and G2819 (κλῆρος)"
	},
	"G3601" : {
		"lemma" : "ὀδύνη",
		"translit" : "odýnē",
		"kjv" : "sorrow",
		"def" : "grief (as dejecting)",
		"derivation" : "from G1416 (δύνω)"
	},
	"G4674" : {
		"kjv" : "thine (own), thy (friend)",
		"lemma" : "σός",
		"translit" : "sós",
		"derivation" : "from G4771 (σύ)",
		"def" : "thine"
	},
	"G3469" : {
		"def" : "to carp at, i.e. censure (discredit)",
		"derivation" : "from G3470 (μῶμος)",
		"lemma" : "μωμάομαι",
		"translit" : "mōmáomai",
		"kjv" : "blame"
	},
	"G675" : {
		"derivation" : "of Latin origin",
		"def" : "(in the genitive, i.e. possessive case) of Appius, the name of a Roman",
		"kjv" : "Appii",
		"lemma" : "Ἄππιος",
		"translit" : "Áppios"
	},
	"G4991" : {
		"derivation" : "feminine of a derivative of G4990 (σωτήρ) as (properly, abstract) noun",
		"def" : "rescue or safety (physically or morally)",
		"kjv" : "deliver, health, salvation, save, saving",
		"translit" : "sōtēría",
		"lemma" : "σωτηρία"
	},
	"G3675" : {
		"kjv" : "of one mind",
		"lemma" : "ὁμόφρων",
		"translit" : "homóphrōn",
		"derivation" : "from the base of G3674 (ὁμοῦ) and G5424 (φρήν)",
		"def" : "like-minded, i.e. harmonious"
	},
	"G4100" : {
		"derivation" : "from G4102 (πίστις)",
		"def" : "to have faith (in, upon, or with respect to, a person or thing), i.e. credit; by implication, to entrust (especially one's spiritual well-being to Christ)",
		"kjv" : "believe(-r), commit (to trust), put in trust with",
		"translit" : "pisteúō",
		"lemma" : "πιστεύω"
	},
	"G920" : {
		"kjv" : "Bar-jona",
		"translit" : "Bariōnâs",
		"lemma" : "Βαριωνᾶς",
		"derivation" : "of Chaldee origin (H1247 and H3124)",
		"def" : "son of Jonas (or Jonah); Bar-jonas, an Israelite"
	},
	"G1307" : {
		"def" : "appearing through, i.e. \"diaphanous\"",
		"derivation" : "from G1223 (διά) and G5316 (φαίνω)",
		"translit" : "diaphanḗs",
		"lemma" : "διαφανής",
		"kjv" : "transparent"
	},
	"G3003" : {
		"translit" : "legeṓn",
		"lemma" : "λεγεών",
		"kjv" : "legion",
		"def" : "a \"legion\", i.e. Roman regiment (figuratively)",
		"derivation" : "of Latin origin"
	},
	"G5278" : {
		"def" : "to stay under (behind), i.e. remain; figuratively, to undergo, i.e. bear (trials), have fortitude, persevere",
		"derivation" : "from G5259 (ὑπό) and G3306 (μένω)",
		"translit" : "hypoménō",
		"lemma" : "ὑπομένω",
		"kjv" : "abide, endure, (take) patient(-ly), suffer, tarry behind"
	},
	"G2812" : {
		"derivation" : "from G2813 (κλέπτω)",
		"def" : "a stealer (literally or figuratively)",
		"kjv" : "thief",
		"translit" : "kléptēs",
		"lemma" : "κλέπτης"
	},
	"G1457" : {
		"translit" : "enkainízō",
		"lemma" : "ἐγκαινίζω",
		"kjv" : "consecrate, dedicate",
		"def" : "to renew, i.e. inaugurate",
		"derivation" : "from G1456 (ἐγκαίνια)"
	},
	"G5318" : {
		"def" : "shining, i.e. apparent (literally or figuratively); neuter (as adverb) publicly, externally",
		"derivation" : "from G5316 (φαίνω)",
		"translit" : "phanerós",
		"lemma" : "φανερός",
		"kjv" : "abroad, + appear, known, manifest, open (+ -ly), outward (+ -ly)"
	},
	"G716" : {
		"lemma" : "ἅρμα",
		"translit" : "hárma",
		"kjv" : "chariot",
		"def" : "a chariot (as raised or fitted together (compare G719 (ἁρμός)))",
		"derivation" : "probably from G142 (αἴρω) (perhaps with G1 (Α) (as a particle of union) prefixed)"
	},
	"G1801" : {
		"derivation" : "middle voice from a compound of G1722 (ἐν) and G3775 (οὖς)",
		"def" : "to take in one's ear, i.e. to listen",
		"kjv" : "hearken",
		"lemma" : "ἐνωτίζομαι",
		"translit" : "enōtízomai"
	},
	"G2391" : {
		"lemma" : "Ἰάρεδ",
		"translit" : "Iáred",
		"kjv" : "Jared",
		"def" : "Jared (i.e. Jered), an antediluvian",
		"derivation" : "of Hebrew origin (H3382)"
	},
	"G4645" : {
		"kjv" : "harden",
		"translit" : "sklērýnō",
		"lemma" : "σκληρύνω",
		"derivation" : "from G4642 (σκληρός)",
		"def" : "to indurate, i.e. (figuratively) render stubborn"
	},
	"G5299" : {
		"derivation" : "from a compound of G5259 (ὑπό) and a derivative of G3700 (ὀπτάνομαι)",
		"def" : "to hit under the eye (buffet or disable an antagonist as a pugilist), i.e. (figuratively) to tease or annoy (into compliance), subdue (one's passions)",
		"kjv" : "keep under, weary",
		"lemma" : "ὑπωπιάζω",
		"translit" : "hypōpiázō"
	},
	"G3313" : {
		"lemma" : "μέρος",
		"translit" : "méros",
		"kjv" : "behalf, course, coast, craft, particular (+ -ly), part (+ -ly), piece, portion, respect, side, some sort(-what)",
		"def" : "a division or share (literally or figuratively, in a wide application)",
		"derivation" : "from an obsolete but more primary form of (to get as a section or allotment)"
	},
	"G3105" : {
		"derivation" : "middle voice from a primary (to long for",
		"def" : "through the idea of insensate craving); to rave as a \"maniac\"",
		"kjv" : "be beside self (mad)",
		"translit" : "maínomai",
		"lemma" : "μαίνομαι"
	},
	"G4354" : {
		"lemma" : "προσλαλέω",
		"translit" : "proslaléō",
		"kjv" : "speak to (with)",
		"def" : "to talk to, i.e. converse with",
		"derivation" : "from G4314 (πρός) and G2980 (λαλέω)"
	},
	"G2092" : {
		"kjv" : "prepared, (made) ready(-iness, to our hand)",
		"lemma" : "ἕτοιμος",
		"translit" : "hétoimos",
		"derivation" : "from an old noun (fitness)",
		"def" : "adjusted, i.e. ready"
	},
	"G4035" : {
		"def" : "to leave all around, i.e. (passively) survive",
		"derivation" : "from G4012 (περί) and G3007 (λείπω)",
		"lemma" : "περιλείπω",
		"translit" : "perileípō",
		"kjv" : "remain"
	},
	"G2682" : {
		"translit" : "kataskḗnōsis",
		"lemma" : "κατασκήνωσις",
		"kjv" : "nest",
		"def" : "an encamping, i.e. (figuratively) a perch",
		"derivation" : "from G2681 (κατασκηνόω)"
	},
	"G3538" : {
		"lemma" : "νίπτω",
		"translit" : "níptō",
		"kjv" : "wash",
		"def" : "to cleanse (especially the hands or the feet or the face); ceremonially, to perform ablution"
	},
	"G747" : {
		"derivation" : "from G746 (ἀρχή) and G71 (ἄγω)",
		"def" : "a chief leader",
		"kjv" : "author, captain, prince",
		"translit" : "archēgós",
		"lemma" : "ἀρχηγός"
	},
	"G5026" : {
		"lemma" : "ταύτῃ",
		"translit" : "taútēi",
		"kjv" : "her, + hereof, it, that, + thereby, the (same), this (same)",
		"def" : "(towards or of) this",
		"derivation" : "dative case, accusative case and genitive case respectively of the feminine singular of G3778 (οὗτος)"
	},
	"G3664" : {
		"kjv" : "like, + manner",
		"translit" : "hómoios",
		"lemma" : "ὅμοιος",
		"derivation" : "from the base of G3674 (ὁμοῦ)",
		"def" : "similar (in appearance or character)"
	},
	"G3753" : {
		"lemma" : "ὅτε",
		"translit" : "hóte",
		"kjv" : "after (that), as soon as, that, when, while",
		"def" : "at which (thing) too, i.e. when",
		"derivation" : "from G3739 (ὅς) and G5037 (τέ)"
	},
	"G3167" : {
		"def" : "magnificent, i.e. (neuter, plural as noun) a conspicuous favor, or (subjectively) perfection",
		"derivation" : "from G3173 (μέγας)",
		"lemma" : "μεγαλεῖος",
		"translit" : "megaleîos",
		"kjv" : "great things, wonderful works"
	},
	"G2910" : {
		"lemma" : "κρεμάννυμι",
		"translit" : "kremánnymi",
		"kjv" : "hang",
		"def" : "to hang",
		"derivation" : "a prolonged form of a primary verb"
	},
	"G429" : {
		"def" : "to find out",
		"derivation" : "from G303 (ἀνά) and G2147 (εὑρίσκω)",
		"lemma" : "ἀνευρίσκω",
		"translit" : "aneurískō",
		"kjv" : "find"
	},
	"G1761" : {
		"kjv" : "device, thought",
		"translit" : "enthýmēsis",
		"lemma" : "ἐνθύμησις",
		"derivation" : "from G1760 (ἐνθυμέομαι)",
		"def" : "deliberation"
	},
	"G3821" : {
		"def" : "antiquatedness",
		"derivation" : "from G3820 (παλαιός)",
		"translit" : "palaiótēs",
		"lemma" : "παλαιότης",
		"kjv" : "oldness"
	},
	"G3145" : {
		"derivation" : "from a primary (to handle or squeeze)",
		"def" : "to chew",
		"kjv" : "gnaw",
		"translit" : "massáomai",
		"lemma" : "μασσάομαι"
	},
	"G2708" : {
		"def" : "to pour down (out)",
		"derivation" : "from G2596 (κατά) and cheo (to pour)",
		"lemma" : "καταχέω",
		"translit" : "katachéō",
		"kjv" : "pour"
	},
	"G5219" : {
		"def" : "to hear under (as a subordinate), i.e. to listen attentively; by implication, to heed or conform to a command or authority",
		"derivation" : "from G5259 (ὑπό) and G191 (ἀκούω)",
		"lemma" : "ὑπακούω",
		"translit" : "hypakoúō",
		"kjv" : "hearken, be obedient to, obey"
	},
	"G2157" : {
		"lemma" : "εὐσχημοσύνη",
		"translit" : "euschēmosýnē",
		"kjv" : "comeliness",
		"def" : "decorousness",
		"derivation" : "from G2158 (εὐσχήμων)"
	},
	"G5617" : {
		"def" : "Hosee (i.e. Hoshea), an Israelite",
		"derivation" : "of Hebrew origin (H1954)",
		"lemma" : "Ὡσηέ",
		"translit" : "Hōsēé",
		"kjv" : "Osee"
	},
	"G4360" : {
		"kjv" : "be grieved at",
		"lemma" : "προσοχθίζω",
		"translit" : "prosochthízō",
		"derivation" : "from G4314 (πρός) and a form of (to be vexed with something irksome)",
		"def" : "to feel indignant at"
	},
	"G3075" : {
		"def" : "properly, to soil, i.e. (figuratively) insult (maltreat)",
		"derivation" : "middle voice from a probably derivative of G3089 (λύω) (meaning filth)",
		"lemma" : "λυμαίνομαι",
		"translit" : "lymaínomai",
		"kjv" : "make havock of"
	},
	"G2001" : {
		"lemma" : "ἐπισχύω",
		"translit" : "epischýō",
		"kjv" : "be the more fierce",
		"def" : "to avail further, i.e. (figuratively) insist stoutly",
		"derivation" : "from G1909 (ἐπί) and G2480 (ἰσχύω)"
	},
	"G3409" : {
		"derivation" : "from G3408 (μισθός)",
		"def" : "to let out for wages, i.e. (middle voice) to hire",
		"kjv" : "hire",
		"lemma" : "μισθόω",
		"translit" : "misthóō"
	},
	"G2110" : {
		"kjv" : "benefactor",
		"lemma" : "εὐεργέτης",
		"translit" : "euergétēs",
		"derivation" : "from G2095 (εὖ) and the base of G2041 (ἔργον)",
		"def" : "a worker of good, i.e. (specially) a philanthropist"
	},
	"G608" : {
		"translit" : "apokleíō",
		"lemma" : "ἀποκλείω",
		"kjv" : "shut up",
		"def" : "to close fully",
		"derivation" : "from G575 (ἀπό) and G2808 (κλείω)"
	},
	"G812" : {
		"derivation" : "from G813 (ἄτακτος)",
		"def" : "to be (i.e. act) irregular",
		"kjv" : "behave self disorderly",
		"lemma" : "ἀτακτέω",
		"translit" : "ataktéō"
	},
	"G454" : {
		"def" : "stupidity; by implication, rage",
		"derivation" : "from a compound of G1 (Α) (as a negative particle) and G3563 (νοῦς)",
		"translit" : "ánoia",
		"lemma" : "ἄνοια",
		"kjv" : "folly, madness"
	},
	"G4310" : {
		"kjv" : "suffer before",
		"translit" : "propáschō",
		"lemma" : "προπάσχω",
		"derivation" : "from G4253 (πρό) and G3958 (πάσχω)",
		"def" : "to undergo hardship previously"
	},
	"G3660" : {
		"translit" : "omnýō",
		"lemma" : "ὀμνύω",
		"kjv" : "swear",
		"def" : "to swear, i.e. take (or declare on) oath",
		"derivation" : "a prolonged form of a primary, but obsolete , for which another prolonged form is used in certain tenses"
	},
	"G3955" : {
		"lemma" : "παῤῥησιάζομαι",
		"translit" : "parrhēsiázomai",
		"kjv" : "be (wax) bold, (preach, speak) boldly",
		"def" : "to be frank in utterance, or confident in spirit and demeanor",
		"derivation" : "middle voice from G3954 (παῤῥησία)"
	},
	"G437" : {
		"derivation" : "from G473 (ἀντί) and the middle voice of G3670 (ὁμολογέω)",
		"def" : "to confess in turn, i.e. respond in praise",
		"kjv" : "give thanks",
		"lemma" : "ἀνθομολογέομαι",
		"translit" : "anthomologéomai"
	},
	"G282" : {
		"translit" : "amḗtōr",
		"lemma" : "ἀμήτωρ",
		"kjv" : "without mother",
		"def" : "motherless, i.e. of unknown maternity",
		"derivation" : "from G1 (Α) (as a negative particle) and G3384 (μήτηρ)"
	},
	"G4344" : {
		"kjv" : "pillow",
		"lemma" : "προσκεφάλαιον",
		"translit" : "proskephálaion",
		"derivation" : "neuter of a presumed compound of G4314 (πρός) and G2776 (κεφαλή)",
		"def" : "something for the head, i.e. a cushion"
	},
	"G4213" : {
		"kjv" : "drink",
		"translit" : "pósis",
		"lemma" : "πόσις",
		"derivation" : "from the alternate of G4095 (πίνω)",
		"def" : "a drinking (the act), i.e. (concretely) a draught"
	},
	"G4689" : {
		"lemma" : "σπένδω",
		"translit" : "spéndō",
		"kjv" : "(be ready to) be offered",
		"def" : "to pour out as a libation, i.e. (figuratively) to devote (one's life or blood, as a sacrifice) (\"spend\")",
		"derivation" : "apparently a primary verb"
	},
	"G2692" : {
		"derivation" : "from G2690 (καταστρέφω)",
		"def" : "an overturn (\"catastrophe\"), i.e. demolition; figuratively, apostasy",
		"kjv" : "overthrow, subverting",
		"lemma" : "καταστροφή",
		"translit" : "katastrophḗ"
	},
	"G5608" : {
		"def" : "to purchase (synonymous with the earlier G4092 (πίμπρημι))",
		"derivation" : "middle voice from an apparently primary (a sum or price)",
		"lemma" : "ὠνέομαι",
		"translit" : "ōnéomai",
		"kjv" : "buy"
	},
	"G1798" : {
		"derivation" : "from G1722 (ἐν) and G5258 (ὕπνος)",
		"def" : "something seen in sleep, i.e. a dream (vision in a dream)",
		"kjv" : "dream",
		"translit" : "enýpnion",
		"lemma" : "ἐνύπνιον"
	},
	"G5043" : {
		"kjv" : "child, daughter, son",
		"lemma" : "τέκνον",
		"translit" : "téknon",
		"derivation" : "from the base of G5098 (τιμωρία)",
		"def" : "a child (as produced)"
	},
	"G4725" : {
		"translit" : "stémma",
		"lemma" : "στέμμα",
		"kjv" : "garland",
		"def" : "a wreath for show",
		"derivation" : "from the base of G4735 (στέφανος)"
	},
	"G558" : {
		"derivation" : "from G575 (ἀπό) and G1658 (ἐλεύθερος)",
		"def" : "one freed away, i.e. a freedman",
		"kjv" : "freeman",
		"translit" : "apeleútheros",
		"lemma" : "ἀπελεύθερος"
	},
	"G436" : {
		"kjv" : "resist, withstand",
		"translit" : "anthístēmi",
		"lemma" : "ἀνθίστημι",
		"derivation" : "from G473 (ἀντί) and G2476 (ἵστημι)",
		"def" : "to stand against, i.e. oppose"
	},
	"G4434" : {
		"def" : "akin to G4422 (πτοέω) and the alternate of G4098 (πίπτω)); a beggar (as cringing), i.e. pauper (strictly denoting absolute or public mendicancy, although also used in a qualified or relative sense; whereas G3993 (πένης) properly means only straitened circumstances in private), literally (often as noun) or figuratively (distressed)",
		"derivation" : "from (to crouch)",
		"lemma" : "πτωχός",
		"translit" : "ptōchós",
		"kjv" : "beggar(-ly), poor"
	},
	"G3595" : {
		"translit" : "hodēgós",
		"lemma" : "ὁδηγός",
		"kjv" : "guide, leader",
		"def" : "a conductor (literally or figuratively (teacher))",
		"derivation" : "from G3598 (ὁδός) and G2233 (ἡγέομαι)"
	},
	"G2275" : {
		"kjv" : "diminishing, fault",
		"lemma" : "ἥττημα",
		"translit" : "hḗttēma",
		"derivation" : "from G2274 (ἡττάω)",
		"def" : "a deterioration, i.e. (objectively) failure or (subjectively) loss"
	},
	"G5459" : {
		"kjv" : "day star",
		"translit" : "phōsphóros",
		"lemma" : "φωσφόρος",
		"derivation" : "from G5457 (φῶς) and G5342 (φέρω)",
		"def" : "light-bearing (\"phosphorus\"), i.e. (specially), the morning-star (figuratively)"
	},
	"G4969" : {
		"def" : "to butcher (especially an animal for food or in sacrifice) or (generally) to slaughter, or (specially), to maim (violently)",
		"derivation" : "a primary verb",
		"lemma" : "σφάζω",
		"translit" : "spházō",
		"kjv" : "kill, slay, wound"
	},
	"G3523" : {
		"lemma" : "νῆστις",
		"translit" : "nēstis",
		"kjv" : "fasting",
		"def" : "not eating, i.e. abstinent from food (religiously)",
		"derivation" : "from the inseparable negative particle (not) and G2068 (ἐσθίω)"
	},
	"G1737" : {
		"def" : "to invest (with a garment)",
		"derivation" : "a prolonged form of G1746 (ἐνδύω)",
		"lemma" : "ἐνδιδύσκω",
		"translit" : "endidýskō",
		"kjv" : "clothe in, wear"
	},
	"G4336" : {
		"derivation" : "from G4314 (πρός) and G2172 (εὔχομαι)",
		"def" : "to pray to God, i.e. supplicate, worship",
		"kjv" : "pray (X earnestly, for), make prayer",
		"translit" : "proseúchomai",
		"lemma" : "προσεύχομαι"
	},
	"G2131" : {
		"derivation" : "from G2095 (εὖ) and G3529 (νίκη)",
		"def" : "victorious; Eunice, a Jewess",
		"kjv" : "Eunice",
		"lemma" : "Εὐνίκη",
		"translit" : "Euníkē"
	},
	"G4148" : {
		"kjv" : "en- (make) rich",
		"translit" : "ploutízō",
		"lemma" : "πλουτίζω",
		"derivation" : "from G4149 (πλοῦτος)",
		"def" : "to make wealthy (figuratively)"
	},
	"G2763" : {
		"translit" : "kerameús",
		"lemma" : "κεραμεύς",
		"kjv" : "potter",
		"def" : "a potter",
		"derivation" : "from G2766 (κέραμος)"
	},
	"G4765" : {
		"def" : "a little sparrow",
		"derivation" : "diminutive of (a sparrow)",
		"translit" : "strouthíon",
		"lemma" : "στρουθίον",
		"kjv" : "sparrow"
	},
	"G4584" : {
		"derivation" : "of Hebrew origin (H8096)",
		"def" : "Semei (i.e. Shimi), an Israelite",
		"kjv" : "Semei",
		"lemma" : "Σεμεΐ",
		"translit" : "Semeḯ"
	},
	"G3565" : {
		"translit" : "nýmphē",
		"lemma" : "νύμφη",
		"kjv" : "bride, daughter in law",
		"def" : "compare Latin \"nupto,\" to marry); a young married woman (as veiled), including a betrothed girl; by implication, a son's wife",
		"derivation" : "from a primary but obsolete verb (to veil as a bride"
	},
	"G1859" : {
		"def" : "a festival",
		"derivation" : "of uncertain affinity",
		"lemma" : "ἑορτή",
		"translit" : "heortḗ",
		"kjv" : "feast, holyday"
	},
	"G666" : {
		"derivation" : "from the participle of G548 (ἄπειμι)",
		"def" : "a being away",
		"kjv" : "absence",
		"translit" : "apousía",
		"lemma" : "ἀπουσία"
	},
	"G2076" : {
		"kjv" : "are, be(-long), call, X can(-not), come, consisteth, X dure for a while, + follow, X have, (that) is (to say), make, meaneth, X must needs, + profit, + remaineth, + wrestle",
		"translit" : "estí",
		"lemma" : "ἐστί",
		"derivation" : "third person singular present indicative of G1510 (εἰμί)",
		"def" : "he (she or it) is; also (with neuter plural) they are"
	},
	"G2291" : {
		"derivation" : "of Hebrew origin (H8646)",
		"def" : "Thara (i.e. Terach), the father of Abraham",
		"kjv" : "Thara",
		"lemma" : "Θάρα",
		"translit" : "Thára"
	},
	"G2698" : {
		"derivation" : "from G2596 (κατά) and G5087 (τίθημι)",
		"def" : "to place down, i.e. deposit (literally or figuratively)",
		"kjv" : "do, lay, shew",
		"translit" : "katatíthēmi",
		"lemma" : "κατατίθημι"
	},
	"G1986" : {
		"kjv" : "become uncircumcised",
		"lemma" : "ἐπισπάομαι",
		"translit" : "epispáomai",
		"derivation" : "from G1909 (ἐπί) and G4685 (σπάω)",
		"def" : "to draw over, i.e. (with G203 (ἀκροβυστία) implied) efface the mark of circumcision (by recovering with the foreskin)"
	},
	"G4633" : {
		"def" : "a tent or cloth hut (literally or figuratively)",
		"derivation" : "apparently akin to G4632 (σκεῦος) and G4639 (σκιά)",
		"translit" : "skēnḗ",
		"lemma" : "σκηνή",
		"kjv" : "habitation, tabernacle"
	},
	"G5269" : {
		"derivation" : "from G5259 (ὑπό) and G2224 (ζώννυμι)",
		"def" : "to gird under, i.e. frap (a vessel with cables across the keel, sides and deck)",
		"kjv" : "undergirt",
		"lemma" : "ὑποζώννυμι",
		"translit" : "hypozṓnnymi"
	},
	"G4397" : {
		"derivation" : "from G4396 (προφήτης)",
		"def" : "pertaining to a foreteller (\"prophetic\")",
		"kjv" : "of prophecy, of the prophets",
		"translit" : "prophētikós",
		"lemma" : "προφητικός"
	},
	"G3767" : {
		"lemma" : "οὖν",
		"translit" : "oûn",
		"kjv" : "and (so, truly), but, now (then), so (likewise then), then, therefore, verily, wherefore",
		"def" : "(adverbially) certainly, or (conjunctionally) accordingly",
		"derivation" : "apparently a primary word"
	},
	"G2587" : {
		"kjv" : "Cappadocia",
		"translit" : "Kappadokía",
		"lemma" : "Καππαδοκία",
		"derivation" : "of foreign origin",
		"def" : "Cappadocia, a region of Asia Minor"
	},
	"G995" : {
		"def" : "a halloo, i.e. call (for aid, etc.)",
		"derivation" : "from G994 (βοάω)",
		"lemma" : "βοή",
		"translit" : "boḗ",
		"kjv" : "cry"
	},
	"G3082" : {
		"derivation" : "of uncertain origin",
		"def" : "Lystra, a place in Asia Minor",
		"kjv" : "Lystra",
		"lemma" : "Λύστρα",
		"translit" : "Lýstra"
	},
	"G511" : {
		"lemma" : "ἀνώτερος",
		"translit" : "anṓteros",
		"kjv" : "above, higher",
		"def" : "upper, i.e. (neuter as adverb) to a more conspicuous place, in a former part of the book",
		"derivation" : "comparative degree of G507 (ἄνω)"
	},
	"G2713" : {
		"derivation" : "from G2596 (κατά) and G1725 (ἔναντι)",
		"def" : "directly opposite",
		"kjv" : "before, over against",
		"lemma" : "κατέναντι",
		"translit" : "katénanti"
	},
	"G5217" : {
		"def" : "to lead (oneself) under, i.e. withdraw or retire (as if sinking out of sight), literally or figuratively",
		"derivation" : "from G5259 (ὑπό) and G71 (ἄγω)",
		"translit" : "hypágō",
		"lemma" : "ὑπάγω",
		"kjv" : "depart, get hence, go (a-)way"
	},
	"G4448" : {
		"kjv" : "burn, fiery, be on fire, try",
		"translit" : "pyróō",
		"lemma" : "πυρόω",
		"derivation" : "from G4442 (πῦρ)",
		"def" : "to kindle, i.e. (passively) to be ignited, glow (literally), be refined (by implication), or (figuratively) to be inflamed (with anger, grief, lust)"
	},
	"G1422" : {
		"kjv" : "hard",
		"lemma" : "δύσκολος",
		"translit" : "dýskolos",
		"derivation" : "from G1418 (δυσ-) and (food)",
		"def" : "properly, fastidious about eating (peevish), i.e. (genitive case) impracticable"
	},
	"G1573" : {
		"derivation" : "from G1537 (ἐκ) and G2556 (κακός)",
		"def" : "to be (bad or) weak, i.e. (by implication) to fail (in heart)",
		"kjv" : "faint, be weary",
		"lemma" : "ἐκκακέω",
		"translit" : "ekkakéō"
	},
	"G3582" : {
		"def" : "by implication, (of friction) to boil or heat); a vessel (as fashioned or for cooking) (or perhaps by corruption from the Latin sextarius, the sixth of a modius, i.e. about a pint), i.e. (specially), a measure for liquids or solids, (by analogy, a pitcher)",
		"derivation" : "as if from (properly, to smooth",
		"lemma" : "ξέστης",
		"translit" : "xéstēs",
		"kjv" : "pot"
	},
	"G4117" : {
		"kjv" : "broidered hair",
		"lemma" : "πλέγμα",
		"translit" : "plégma",
		"derivation" : "from G4120 (πλέκω)",
		"def" : "a plait (of hair)"
	},
	"G1608" : {
		"def" : "to be utterly unchaste",
		"derivation" : "from G1537 (ἐκ) and G4203 (πορνεύω)",
		"translit" : "ekporneúō",
		"lemma" : "ἐκπορνεύω",
		"kjv" : "give self over to fornication"
	},
	"G4203" : {
		"derivation" : "from G4204 (πόρνη)",
		"def" : "to act the harlot, i.e. (literally) indulge unlawful lust (of either sex), or (figuratively) practise idolatry",
		"kjv" : "commit (fornication)",
		"translit" : "porneúō",
		"lemma" : "πορνεύω"
	},
	"G873" : {
		"kjv" : "divide, separate, sever",
		"translit" : "aphorízō",
		"lemma" : "ἀφορίζω",
		"derivation" : "from G575 (ἀπό) and G3724 (ὁρίζω)",
		"def" : "to set off by boundary, i.e. (figuratively) limit, exclude, appoint, etc."
	},
	"G4581" : {
		"translit" : "Seleúkeia",
		"lemma" : "Σελεύκεια",
		"kjv" : "Seleucia",
		"def" : "Seleuceia, a place in Syria",
		"derivation" : "from (Seleucus, a Syrian king)"
	},
	"G2873" : {
		"def" : "a cut, i.e. (by analogy) toil (as reducing the strength), literally or figuratively; by implication, pains",
		"derivation" : "from G2875 (κόπτω)",
		"translit" : "kópos",
		"lemma" : "κόπος",
		"kjv" : "labour, + trouble, weariness"
	},
	"G5176" : {
		"derivation" : "probably strengthened from a collateral form of the base of G5134 (τραῦμα) and G5147 (τρίβος) through the idea of corrosion or wear",
		"def" : "or perhaps rather of a base of G5167 (τρυγών) and G5149 (τρίζω) through the idea of a craunching sound; to gnaw or chew, i.e. (generally) to eat",
		"kjv" : "eat",
		"lemma" : "τρώγω",
		"translit" : "trṓgō"
	},
	"G4884" : {
		"derivation" : "from G4862 (σύν) and G726 (ἁρπάζω)",
		"def" : "to snatch together, i.e. seize",
		"kjv" : "catch",
		"lemma" : "συναρπάζω",
		"translit" : "synarpázō"
	},
	"G5106" : {
		"lemma" : "τοίνυν",
		"translit" : "toínyn",
		"kjv" : "then, therefore",
		"def" : "truly now, i.e. accordingly",
		"derivation" : "from G5104 (τοί) and G3568 (νῦν)"
	},
	"G3481" : {
		"translit" : "Nathán",
		"lemma" : "Ναθάν",
		"kjv" : "Nathan",
		"def" : "Nathan, an Israelite",
		"derivation" : "of Hebrew origin (H5416)"
	},
	"G4784" : {
		"derivation" : "middle from G4862 (σύν) and G2698 (κατατίθημι)",
		"def" : "to deposit (one's vote or opinion) in company with, i.e. (figuratively) to accord with",
		"kjv" : "consent",
		"translit" : "synkatatíthemai",
		"lemma" : "συγκατατίθεμαι"
	},
	"G3140" : {
		"def" : "to be a witness, i.e. testify (literally or figuratively)",
		"derivation" : "from G3144 (μάρτυς)",
		"lemma" : "μαρτυρέω",
		"translit" : "martyréō",
		"kjv" : "charge, give (evidence), bear record, have (obtain, of) good (honest) report, be well reported of, testify, give (have) testimony, (be, bear, give, obtain) witness"
	},
	"G1558" : {
		"lemma" : "ἔκδικος",
		"translit" : "ékdikos",
		"kjv" : "a (re-)venger",
		"def" : "carrying justice out, i.e. a punisher",
		"derivation" : "from G1537 (ἐκ) and G1349 (δίκη)"
	},
	"G1543" : {
		"derivation" : "from G1540 (ἑκατόν) and G757 (ἄρχω)",
		"def" : "the captain of one hundred men",
		"kjv" : "centurion",
		"lemma" : "ἑκατοντάρχης",
		"translit" : "hekatontárchēs"
	},
	"G1972" : {
		"derivation" : "from G1971 (ἐπιποθέω)",
		"def" : "a longing for",
		"kjv" : "earnest (vehement) desire",
		"translit" : "epipóthēsis",
		"lemma" : "ἐπιπόθησις"
	},
	"G911" : {
		"translit" : "báptō",
		"lemma" : "βάπτω",
		"kjv" : "dip",
		"def" : "to whelm, i.e. cover wholly with a fluid; in the New Testament only in a qualified or special sense, i.e. (literally) to moisten (a part of one's person), or (by implication) to stain (as with dye)",
		"derivation" : "a primary verb"
	},
	"G4827" : {
		"kjv" : "fellow disciple",
		"lemma" : "συμμαθητής",
		"translit" : "symmathētḗs",
		"derivation" : "from a compound of G4862 (σύν) and G3129 (μανθάνω)",
		"def" : "a co-learner (of Christianity)"
	},
	"G5442" : {
		"def" : "to watch, i.e. be on guard (literally of figuratively); by implication, to preserve, obey, avoid",
		"derivation" : "probably from G5443 (φυλή) through the idea of isolation",
		"lemma" : "φυλάσσω",
		"translit" : "phylássō",
		"kjv" : "beward, keep (self), observe, save"
	},
	"G2134" : {
		"def" : "to castrate (figuratively, live unmarried)",
		"derivation" : "from G2135 (εὐνοῦχος)",
		"lemma" : "εὐνουχίζω",
		"translit" : "eunouchízō",
		"kjv" : "make…eunuch"
	},
	"G508" : {
		"def" : "above the ground, i.e. (properly) the second floor of a building; used for a dome or a balcony on the upper story",
		"derivation" : "from G507 (ἄνω) and G1093 (γῆ)",
		"translit" : "anṓgeon",
		"lemma" : "ἀνώγεον",
		"kjv" : "upper room"
	},
	"G3776" : {
		"def" : "substance, i.e. property (possessions)",
		"derivation" : "from the feminine of G5607 (ὤν)",
		"translit" : "ousía",
		"lemma" : "οὐσία",
		"kjv" : "goods, substance"
	},
	"G4681" : {
		"kjv" : "Spain",
		"lemma" : "Σπανία",
		"translit" : "Spanía",
		"derivation" : "probably of foreign origin",
		"def" : "Spania, a region of Europe"
	},
	"G1321" : {
		"kjv" : "teach",
		"translit" : "didáskō",
		"lemma" : "διδάσκω",
		"derivation" : "a prolonged (causative) form of a primary verb (to learn)",
		"def" : "to teach (in the same broad application)"
	},
	"G5246" : {
		"derivation" : "from G5228 (ὑπέρ) and G3591 (ὄγκος)",
		"def" : "bulging over, i.e. (figuratively) insolent",
		"kjv" : "great swelling",
		"lemma" : "ὑπέρογκος",
		"translit" : "hypéronkos"
	},
	"G1973" : {
		"derivation" : "from G1909 (ἐπί) and a derivative of the latter part of G1971 (ἐπιποθέω)",
		"def" : "yearned upon, i.e. greatly loved",
		"kjv" : "longed for",
		"lemma" : "ἐπιπόθητος",
		"translit" : "epipóthētos"
	},
	"G93" : {
		"def" : "(legal) injustice (properly, the quality, by implication, the act); morally, wrongfulness (of character, life or act)",
		"derivation" : "from G94 (ἄδικος)",
		"lemma" : "ἀδικία",
		"translit" : "adikía",
		"kjv" : "iniquity, unjust, unrighteousness, wrong"
	},
	"G4804" : {
		"derivation" : "from G4802 (συζητέω)",
		"def" : "a disputant, i.e. sophist",
		"kjv" : "disputer",
		"translit" : "syzētētḗs",
		"lemma" : "συζητητής"
	},
	"G2267" : {
		"derivation" : "from G2264 (Ἡρώδης)",
		"def" : "Herodion, a Christian",
		"kjv" : "Herodion",
		"translit" : "Hērōdíōn",
		"lemma" : "Ἡρωδίων"
	},
	"G745" : {
		"translit" : "Archélaos",
		"lemma" : "Ἀρχέλαος",
		"kjv" : "Archelaus",
		"def" : "people-ruling; Archelaus, a Jewish king",
		"derivation" : "from G757 (ἄρχω) and G2994 (Λαοδικεύς)"
	},
	"G4566" : {
		"kjv" : "Satan",
		"translit" : "Satân",
		"lemma" : "Σατᾶν",
		"derivation" : "of Hebrew origin (H7854)",
		"def" : "Satan, i.e. the devil"
	},
	"G4655" : {
		"translit" : "skótos",
		"lemma" : "σκότος",
		"kjv" : "darkness",
		"def" : "shadiness, i.e. obscurity (literally or figuratively)",
		"derivation" : "from the base of G4639 (σκιά)"
	},
	"G2304" : {
		"translit" : "theîos",
		"lemma" : "θεῖος",
		"kjv" : "divine, godhead",
		"def" : "godlike (neuter as noun, divinity)",
		"derivation" : "from G2316 (θεός)"
	},
	"G5185" : {
		"translit" : "typhlós",
		"lemma" : "τυφλός",
		"kjv" : "blind",
		"def" : "opaque (as if smoky), i.e. (by analogy) blind (physically or mentally)",
		"derivation" : "from, G5187 (τυφόω)"
	},
	"G366" : {
		"translit" : "ananḗphō",
		"lemma" : "ἀνανήφω",
		"kjv" : "recover self",
		"def" : "to become sober again, i.e. (figuratively) regain (one's) senses",
		"derivation" : "from G303 (ἀνά) and G3525 (νήφω)"
	},
	"G4949" : {
		"kjv" : "Syrophenician",
		"lemma" : "Συροφοίνισσα",
		"translit" : "Syrophoínissa",
		"derivation" : "feminine of a compound of G4948 (Σύρος) and the same as G5403 (Φοινίκη)",
		"def" : "a Syro-phœnician woman, i.e. a female native of Phœnicia in Syria"
	},
	"G4072" : {
		"kjv" : "fly(-ing)",
		"lemma" : "πέτομαι",
		"translit" : "pétomai",
		"derivation" : "middle voice of a primary verb",
		"def" : "to fly"
	},
	"G2281" : {
		"translit" : "thálassa",
		"lemma" : "θάλασσα",
		"kjv" : "sea",
		"def" : "the sea (genitive case or specially)",
		"derivation" : "probably prolonged from G251 (ἅλς)"
	},
	"G4016" : {
		"kjv" : "array, cast about, clothe(-d me), put on",
		"translit" : "peribállō",
		"lemma" : "περιβάλλω",
		"derivation" : "from G4012 (περί) and G906 (βάλλω)",
		"def" : "to throw all around, i.e. invest (with a palisade or with clothing)"
	},
	"G858" : {
		"kjv" : "singleness",
		"translit" : "aphelótēs",
		"lemma" : "ἀφελότης",
		"derivation" : "from a compound of G1 (Α) (as a negative particle) and (in the sense of a stone as stubbing the foot)",
		"def" : "smoothness, i.e. (figuratively) simplicity"
	},
	"G2879" : {
		"kjv" : "Core",
		"lemma" : "Κορέ",
		"translit" : "Koré",
		"derivation" : "of Hebrew origin (H7141)",
		"def" : "Core (i.e. Korach), an Israelite"
	},
	"G5015" : {
		"kjv" : "trouble",
		"lemma" : "ταράσσω",
		"translit" : "tarássō",
		"derivation" : "of uncertain affinity",
		"def" : "to stir or agitate (roil water)"
	},
	"G1462" : {
		"def" : "an accusation, i.e. offence alleged",
		"derivation" : "from G1458 (ἐγκαλέω)",
		"lemma" : "ἔγκλημα",
		"translit" : "énklēma",
		"kjv" : "crime laid against, laid to charge"
	},
	"G1248" : {
		"translit" : "diakonía",
		"lemma" : "διακονία",
		"kjv" : "(ad-)minister(-ing, -tration, -try), office, relief, service(-ing)",
		"def" : "attendance (as a servant, etc.); figuratively (eleemosynary) aid, (official) service (especially of the Christian teacher, or technically of the diaconate)",
		"derivation" : "from G1249 (διάκονος)"
	},
	"G4847" : {
		"def" : "a native of the same town, i.e. (figuratively) co-religionist (fellow-Christian)",
		"derivation" : "from G4862 (σύν) and G4177 (πολίτης)",
		"translit" : "sympolítēs",
		"lemma" : "συμπολίτης",
		"kjv" : "fellow- citizen"
	},
	"G389" : {
		"kjv" : "sigh deeply",
		"translit" : "anastenázō",
		"lemma" : "ἀναστενάζω",
		"derivation" : "from G303 (ἀνά) and G4727 (στενάζω)",
		"def" : "to sigh deeply"
	},
	"G1045" : {
		"def" : "Gad, a tribe of Israelites",
		"derivation" : "of Hebrew origin (H1410)",
		"translit" : "Gád",
		"lemma" : "Γάδ",
		"kjv" : "Gad"
	},
	"G1514" : {
		"kjv" : "be at (have, live in) peace, live peaceably",
		"lemma" : "εἰρηνεύω",
		"translit" : "eirēneúō",
		"derivation" : "from G1515 (εἰρήνη)",
		"def" : "to be (act) peaceful"
	},
	"G2064" : {
		"translit" : "érchomai",
		"lemma" : "ἔρχομαι",
		"kjv" : "accompany, appear, bring, come, enter, fall out, go, grow, X light, X next, pass, resort, be set",
		"def" : "to come or go (in a great variety of applications, literally and figuratively)",
		"derivation" : "middle voice of a primary verb (used only in the present and imperfect tenses, the others being supplied by a kindred (middle voice) , or (active) , which do not otherwise occur)"
	},
	"G2349" : {
		"def" : "liable to die",
		"derivation" : "from G2348 (θνήσκω)",
		"lemma" : "θνητός",
		"translit" : "thnētós",
		"kjv" : "mortal(-ity)"
	},
	"G2298" : {
		"kjv" : "marvel(-lous)",
		"translit" : "thaumastós",
		"lemma" : "θαυμαστός",
		"derivation" : "from G2296 (θαυμάζω)",
		"def" : "wondered at, i.e. (by implication) wonderful"
	},
	"G875" : {
		"def" : "to froth at the mouth (in epilepsy)",
		"derivation" : "from G876 (ἀφρός)",
		"translit" : "aphrízō",
		"lemma" : "ἀφρίζω",
		"kjv" : "foam"
	},
	"G665" : {
		"def" : "to deflect, i.e. (reflexively) avoid",
		"derivation" : "from G575 (ἀπό) and the base of G5157 (τροπή)",
		"lemma" : "ἀποτρέπω",
		"translit" : "apotrépō",
		"kjv" : "turn away"
	},
	"G44" : {
		"def" : "a hook (as bent)",
		"derivation" : "from the same as G43 (ἀγκάλη)",
		"lemma" : "ἄγκιστρον",
		"translit" : "ánkistron",
		"kjv" : "hook"
	},
	"G2581" : {
		"translit" : "Kananítēs",
		"lemma" : "Κανανίτης",
		"kjv" : "Canaanite (by mistake for a derivative from G5477 (Χαναάν))",
		"def" : "zealous; Cananites, an epithet",
		"derivation" : "of Chaldee origin (compare H7067)"
	},
	"G3513" : {
		"lemma" : "νή",
		"translit" : "nḗ",
		"kjv" : "I protest by",
		"def" : "a particle of attestation (accompanied by the object invoked or appealed to in confirmation); as sure as",
		"derivation" : "probably an intensive form of G3483 (ναί)"
	},
	"G307" : {
		"kjv" : "draw",
		"lemma" : "ἀναβιβάζω",
		"translit" : "anabibázō",
		"derivation" : "from G303 (ἀνά) and a derivative of the base of G939 (βάσις)",
		"def" : "to cause to go up, i.e. haul (a net)"
	},
	"G5355" : {
		"derivation" : "probably akin to the base of G5351 (φθείρω)",
		"def" : "ill-will (as detraction), i.e. jealousy (spite)",
		"kjv" : "envy",
		"translit" : "phthónos",
		"lemma" : "φθόνος"
	},
	"G4374" : {
		"def" : "to bear towards, i.e. lead to, tender (especially to God), treat",
		"derivation" : "from G4314 (πρός) and G5342 (φέρω) (including its alternate)",
		"translit" : "prosphérō",
		"lemma" : "προσφέρω",
		"kjv" : "bring (to, unto), deal with, do, offer (unto, up), present unto, put to"
	},
	"G1947" : {
		"def" : "assistance",
		"derivation" : "from a compound of G1909 (ἐπί) and a (prolonged) form of the base of G2877 (κοράσιον) (in the sense of servant)",
		"translit" : "epikouría",
		"lemma" : "ἐπικουρία",
		"kjv" : "help"
	},
	"G1047" : {
		"derivation" : "of foreign origin",
		"def" : "a treasure",
		"kjv" : "treasure",
		"lemma" : "γάζα",
		"translit" : "gáza"
	},
	"G300" : {
		"derivation" : "of Hebrew origin (H526)",
		"def" : "Amon, an Israelite",
		"kjv" : "Amon",
		"lemma" : "Ἀμών",
		"translit" : "Amṓn"
	},
	"G1707" : {
		"translit" : "emplékō",
		"lemma" : "ἐμπλέκω",
		"kjv" : "entangle (in, self with)",
		"def" : "to entwine, i.e. (figuratively) involve with",
		"derivation" : "from G1722 (ἐν) and G4120 (πλέκω)"
	},
	"G4467" : {
		"derivation" : "from a comparative of (easy, i.e. reckless) and G2041 (ἔργον)",
		"def" : "easy-going behavior, i.e. (by extension) a crime",
		"kjv" : "lewdness",
		"lemma" : "ῥᾳδιούργημα",
		"translit" : "rhaidioúrgēma"
	},
	"G2424" : {
		"kjv" : "Jesus",
		"translit" : "Iēsoûs",
		"lemma" : "Ἰησοῦς",
		"derivation" : "of Hebrew origin (H3091)",
		"def" : "Jesus (i.e. Jehoshua), the name of our Lord and two (three) other Israelites"
	},
	"G2981" : {
		"def" : "talk",
		"derivation" : "from G2980 (λαλέω)",
		"lemma" : "λαλιά",
		"translit" : "laliá",
		"kjv" : "saying, speech"
	},
	"G3130" : {
		"derivation" : "from G3105 (μαίνομαι)",
		"def" : "craziness",
		"kjv" : "(+ make) X mad",
		"translit" : "manía",
		"lemma" : "μανία"
	},
	"G1695" : {
		"translit" : "Emmaoús",
		"lemma" : "Ἐμμαούς",
		"kjv" : "Emmaus",
		"def" : "Emmaus, a place in Palestine",
		"derivation" : "probably of Hebrew origin (compare H3222)"
	},
	"G4063" : {
		"def" : "to run around, i.e. traverse",
		"derivation" : "from G4012 (περί) and G5143 (τρέχω) (including its alternate)",
		"translit" : "peritréchō",
		"lemma" : "περιτρέχω",
		"kjv" : "run through"
	},
	"G4905" : {
		"kjv" : "accompany, assemble (with), come (together), come (company, go) with, resort",
		"lemma" : "συνέρχομαι",
		"translit" : "synérchomai",
		"derivation" : "from G4862 (σύν) and G2064 (ἔρχομαι)",
		"def" : "to convene, depart in company with, associate with, or (specially), cohabit (conjugally)"
	},
	"G5485" : {
		"def" : "graciousness (as gratifying), of manner or act (abstract or concrete; literal, figurative or spiritual; especially the divine influence upon the heart, and its reflection in the life; including gratitude)",
		"derivation" : "from G5463 (χαίρω)",
		"translit" : "cháris",
		"lemma" : "χάρις",
		"kjv" : "acceptable, benefit, favour, gift, grace(- ious), joy, liberality, pleasure, thank(-s, -worthy)"
	},
	"G4631" : {
		"lemma" : "σκευή",
		"translit" : "skeuḗ",
		"kjv" : "tackling",
		"def" : "furniture, i.e. spare tackle",
		"derivation" : "from G4632 (σκεῦος)"
	},
	"G2054" : {
		"def" : "a quarrel, i.e. (by implication) wrangling",
		"derivation" : "of uncertain affinity",
		"translit" : "éris",
		"lemma" : "ἔρις",
		"kjv" : "contention, debate, strife, variance"
	},
	"G2900" : {
		"derivation" : "from G2904 (κράτος)",
		"def" : "powerful",
		"kjv" : "mighty",
		"lemma" : "κραταιός",
		"translit" : "krataiós"
	},
	"G2213" : {
		"derivation" : "from G2212 (ζητέω)",
		"def" : "a search (properly concretely), i.e. (in words) a debate",
		"kjv" : "question",
		"lemma" : "ζήτημα",
		"translit" : "zḗtēma"
	},
	"G3746" : {
		"def" : "who especially",
		"derivation" : "from G3739 (ὅς) and G4007 (περ)",
		"lemma" : "ὅσπερ",
		"translit" : "hósper",
		"kjv" : "whomsoever"
	},
	"G770" : {
		"def" : "to be feeble (in any sense)",
		"derivation" : "from G772 (ἀσθενής)",
		"lemma" : "ἀσθενέω",
		"translit" : "asthenéō",
		"kjv" : "be diseased, impotent folk (man), (be) sick, (be, be made) weak"
	},
	"G1928" : {
		"def" : "to appoint besides, i.e. supplement (as a codicil)",
		"derivation" : "middle voice from G1909 (ἐπί) and G1299 (διατάσσω)",
		"translit" : "epidiatássomai",
		"lemma" : "ἐπιδιατάσσομαι",
		"kjv" : "add to"
	},
	"G4098" : {
		"derivation" : "probably akin to G4072 (πέτομαι) through the idea of alighting",
		"def" : "to fall (literally or figuratively)",
		"kjv" : "fail, fall (down), light on",
		"lemma" : "πίπτω",
		"translit" : "píptō"
	},
	"G4593" : {
		"derivation" : "from G4592 (σημεῖον)",
		"def" : "to distinguish, i.e. mark (for avoidance)",
		"kjv" : "note",
		"lemma" : "σημειόω",
		"translit" : "sēmeióō"
	},
	"G3373" : {
		"kjv" : "grow up",
		"translit" : "mēkýnō",
		"lemma" : "μηκύνω",
		"derivation" : "from G3372 (μῆκος)",
		"def" : "to lengthen, i.e. (middle voice) to enlarge"
	},
	"G1732" : {
		"derivation" : "from G1731 (ἐνδείκνυμι)",
		"def" : "indication (abstractly)",
		"kjv" : "declare, evident token, proof",
		"lemma" : "ἔνδειξις",
		"translit" : "éndeixis"
	},
	"G4900" : {
		"derivation" : "from G4862 (σύν) and G1643 (ἐλαύνω)",
		"def" : "to drive together, i.e. (figuratively) exhort (to reconciliation)",
		"kjv" : "+ set at one again",
		"lemma" : "συνελαύνω",
		"translit" : "synelaúnō"
	},
	"G2031" : {
		"kjv" : "X say",
		"lemma" : "ἔπος",
		"translit" : "épos",
		"derivation" : "from G2036 (ἔπω)",
		"def" : "a word"
	},
	"G2725" : {
		"lemma" : "κατήγορος",
		"translit" : "katḗgoros",
		"kjv" : "accuser",
		"def" : "against one in the assembly, i.e. a complainant at law; specially, Satan",
		"derivation" : "from G2596 (κατά) and G58 (ἀγορά)"
	},
	"G2728" : {
		"translit" : "katióō",
		"lemma" : "κατιόω",
		"kjv" : "canker",
		"def" : "to rust down, i.e. corrode",
		"derivation" : "from G2596 (κατά) and a derivative of G2447 (ἰός)"
	},
	"G452" : {
		"lemma" : "Ἄννας",
		"translit" : "Ánnas",
		"kjv" : "Annas",
		"def" : "Annas (i.e. G367 (Ἀνανίας)), an Israelite",
		"derivation" : "of Hebrew origin (H2608)"
	},
	"G2532" : {
		"lemma" : "καί",
		"translit" : "kaí",
		"kjv" : "and, also, both, but, even, for, if, or, so, that, then, therefore, when, yet",
		"def" : "and, also, even, so then, too, etc.; often used in connection (or composition) with other particles or small words",
		"derivation" : "apparently, a primary particle, having a copulative and sometimes also a cumulative force"
	},
	"G4748" : {
		"translit" : "stoichéō",
		"lemma" : "στοιχέω",
		"kjv" : "walk (orderly)",
		"def" : "to march in (military) rank (keep step), i.e. (figuratively) to conform to virtue and piety",
		"derivation" : "from a derivative of (to range in regular line)"
	},
	"G924" : {
		"kjv" : "Bartimæus",
		"translit" : "Bartimaîos",
		"lemma" : "Βαρτιμαῖος",
		"derivation" : "of Chaldee origin (H1247 and H2931)",
		"def" : "son of Timæus (or the unclean); Bar-timæus, an Israelite"
	},
	"G5334" : {
		"def" : "a saying, i.e. report",
		"derivation" : "from G5346 (φημί) (not the same as \"phase\", which is from G5316 (φαίνω))",
		"translit" : "phásis",
		"lemma" : "φάσις",
		"kjv" : "tidings"
	},
	"G1443" : {
		"kjv" : "Eber",
		"lemma" : "Ἐβέρ",
		"translit" : "Ebér",
		"derivation" : "of Hebrew origin (H5677)",
		"def" : "Eber, a patriarch"
	},
	"G2125" : {
		"def" : "to be circumspect, i.e. (by implication) to be apprehensive; religiously, to reverence",
		"derivation" : "middle voice from G2126 (εὐλαβής)",
		"lemma" : "εὐλαβέομαι",
		"translit" : "eulabéomai",
		"kjv" : "(moved with) fear"
	},
	"G110" : {
		"derivation" : "from a compound of G1 (Α) (as a negative particle) and G2288 (θάνατος)",
		"def" : "deathlessness",
		"kjv" : "immortality",
		"translit" : "athanasía",
		"lemma" : "ἀθανασία"
	},
	"G2840" : {
		"derivation" : "from G2839 (κοινός)",
		"def" : "to make (or consider) profane (ceremonially)",
		"kjv" : "call common, defile, pollute, unclean",
		"lemma" : "κοινόω",
		"translit" : "koinóō"
	},
	"G2809" : {
		"translit" : "klémma",
		"lemma" : "κλέμμα",
		"kjv" : "theft",
		"def" : "stealing (properly, the thing stolen, but used of the act)",
		"derivation" : "from G2813 (κλέπτω)"
	},
	"G2215" : {
		"derivation" : "of uncertain origin",
		"def" : "darnel or false grain",
		"kjv" : "tares",
		"translit" : "zizánion",
		"lemma" : "ζιζάνιον"
	},
	"G595" : {
		"kjv" : "putting away (off)",
		"lemma" : "ἀπόθεσις",
		"translit" : "apóthesis",
		"derivation" : "from G659 (ἀποτίθημι)",
		"def" : "a laying aside (literally or figuratively)"
	},
	"G3958" : {
		"derivation" : "apparently a primary verb",
		"def" : "to experience a sensation or impression (usually painful)",
		"kjv" : "feel, passion, suffer, vex",
		"lemma" : "πάσχω",
		"translit" : "páschō"
	},
	"G4261" : {
		"kjv" : "put forward, shoot forth",
		"translit" : "probállō",
		"lemma" : "προβάλλω",
		"derivation" : "from G4253 (πρό) and G906 (βάλλω)",
		"def" : "to throw forward, i.e. push to the front, germinate"
	},
	"G2452" : {
		"lemma" : "Ἰουδαϊκῶς",
		"translit" : "Ioudaïkōs",
		"kjv" : "as do the Jews",
		"def" : "Judaïcally or in a manner resembling a Judæan",
		"derivation" : "adverb from G2451 (Ἰουδαϊκός)"
	},
	"G2048" : {
		"derivation" : "of uncertain affinity; lonesome, i.e. (by implication) waste (usually as a noun, G5561 (χώρα) being implied)",
		"kjv" : "desert, desolate, solitary, wilderness",
		"translit" : "érēmos",
		"lemma" : "ἔρημος"
	},
	"G2770" : {
		"derivation" : "from G2771 (κέρδος)",
		"def" : "to gain (literally or figuratively)",
		"kjv" : "(get) gain, win",
		"lemma" : "κερδαίνω",
		"translit" : "kerdaínō"
	},
	"G1920" : {
		"translit" : "epigínomai",
		"lemma" : "ἐπιγίνομαι",
		"kjv" : "blow",
		"def" : "to arrive upon, i.e. spring up (as a wind)",
		"derivation" : "from G1909 (ἐπί) and G1096 (γίνομαι)"
	},
	"G1658" : {
		"derivation" : "probably from the alternate of G2064 (ἔρχομαι)",
		"def" : "unrestrained (to go at pleasure), i.e. (as a citizen) not a slave (whether freeborn or manumitted), or (genitive case) exempt (from obligation or liability)",
		"kjv" : "free (man, woman), at liberty",
		"translit" : "eleútheros",
		"lemma" : "ἐλεύθερος"
	},
	"G2386" : {
		"kjv" : "healing",
		"lemma" : "ἴαμα",
		"translit" : "íama",
		"derivation" : "from G2390 (ἰάομαι)",
		"def" : "a cure (the effect)"
	},
	"G5551" : {
		"def" : "to be a time-wearer, i.e. to procrastinate (linger)",
		"derivation" : "from a presumed compound of G5550 (χρόνος) and the base of G5147 (τρίβος)",
		"translit" : "chronotribéō",
		"lemma" : "χρονοτριβέω",
		"kjv" : "spend time"
	},
	"G3048" : {
		"translit" : "logía",
		"lemma" : "λογία",
		"kjv" : "collection, gathering",
		"def" : "a contribution",
		"derivation" : "from G3056 (λόγος) (in the commercial sense)"
	},
	"G3343" : {
		"derivation" : "from G3326 (μετά) and G3992 (πέμπω)",
		"def" : "to send from elsewhere, i.e. (middle voice) to summon or invite",
		"kjv" : "call (send) for",
		"lemma" : "μεταπέμπω",
		"translit" : "metapémpō"
	},
	"G1508" : {
		"def" : "if not",
		"derivation" : "from G1487 (εἰ) and G3361 (μή)",
		"lemma" : "εἰ μή",
		"translit" : "ei mḗ",
		"kjv" : "but, except (that), if not, more than, save (only) that, saving, till"
	},
	"G1924" : {
		"def" : "to inscribe (physically or mentally)",
		"derivation" : "from G1909 (ἐπί) and G1125 (γράφω)",
		"lemma" : "ἐπιγράφω",
		"translit" : "epigráphō",
		"kjv" : "inscription, write in (over, thereon)"
	},
	"G5046" : {
		"def" : "complete (in various applications of labor, growth, mental and moral character, etc.); neuter (as noun, with G3588 (ὁ)) completeness",
		"derivation" : "from G5056 (τέλος)",
		"lemma" : "τέλειος",
		"translit" : "téleios",
		"kjv" : "of full age, man, perfect"
	},
	"G2854" : {
		"lemma" : "κολλούριον",
		"translit" : "kolloúrion",
		"kjv" : "eyesalve",
		"def" : "prob akin to the base of G2853 (κολλάω)); properly, a poultice (as made of or in the form of crackers), i.e. (by analogy) a plaster",
		"derivation" : "neuter of a presumed derivative of (a cake"
	},
	"G25" : {
		"derivation" : "perhaps from (much) (or compare G5689)",
		"def" : "to love (in a social or moral sense)",
		"kjv" : "(be-)love(-ed)",
		"lemma" : "ἀγαπάω",
		"translit" : "agapáō"
	},
	"G1827" : {
		"def" : "to convict fully, i.e. (by implication) to punish",
		"derivation" : "from G1537 (ἐκ) and G1651 (ἐλέγχω)",
		"lemma" : "ἐξελέγχω",
		"translit" : "exelénchō",
		"kjv" : "convince"
	},
	"G3858" : {
		"kjv" : "receive",
		"lemma" : "παραδέχομαι",
		"translit" : "paradéchomai",
		"derivation" : "from G3844 (παρά) and G1209 (δέχομαι)",
		"def" : "to accept near, i.e. admit or (by implication) delight in"
	},
	"G2323" : {
		"derivation" : "from the same as G2324 (θεράπων)",
		"def" : "to wait upon menially, i.e. (figuratively) to adore (God), or (specially) to relieve (of disease)",
		"kjv" : "cure, heal, worship",
		"lemma" : "θεραπεύω",
		"translit" : "therapeúō"
	},
	"G2464" : {
		"kjv" : "Isaac",
		"translit" : "Isaák",
		"lemma" : "Ἰσαάκ",
		"derivation" : "of Hebrew origin (H3327)",
		"def" : "Isaac (i.e. Jitschak), the son of Abraham"
	},
	"G2360" : {
		"derivation" : "from to wail",
		"def" : "to clamor, i.e. (by implication) to frighten",
		"kjv" : "trouble",
		"translit" : "throéō",
		"lemma" : "θροέω"
	},
	"G443" : {
		"kjv" : "murderer",
		"translit" : "anthrōpoktónos",
		"lemma" : "ἀνθρωποκτόνος",
		"derivation" : "from G444 (ἄνθρωπος) and (to kill)",
		"def" : "a manslayer"
	},
	"G4550" : {
		"kjv" : "bad, corrupt",
		"translit" : "saprós",
		"lemma" : "σαπρός",
		"derivation" : "from G4595 (σήπω)",
		"def" : "rotten, i.e. worthless (literally or morally)"
	},
	"G4450" : {
		"derivation" : "from G4442 (πῦρ)",
		"def" : "fire-like, i.e. (specially), flame- colored",
		"kjv" : "red",
		"lemma" : "πυῤῥός",
		"translit" : "pyrrhós"
	},
	"G4132" : {
		"lemma" : "πλήμμυρα",
		"translit" : "plḗmmyra",
		"kjv" : "flood",
		"def" : "flood-tide, i.e. (by analogy) a freshet",
		"derivation" : "prolonged from G4130 (πλήθω)"
	},
	"G3014" : {
		"translit" : "lépra",
		"lemma" : "λέπρα",
		"kjv" : "leprosy",
		"def" : "scaliness, i.e. \"leprosy\"",
		"derivation" : "from the same as G3013 (λεπίς)"
	},
	"G4451" : {
		"def" : "ignition, i.e. (specially), smelting (figuratively, conflagration, calamity as a test)",
		"derivation" : "from G4448 (πυρόω)",
		"lemma" : "πύρωσις",
		"translit" : "pýrōsis",
		"kjv" : "burning, trial"
	},
	"G278" : {
		"def" : "irrevocable",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G3338 (μεταμέλλομαι)",
		"lemma" : "ἀμεταμέλητος",
		"translit" : "ametamélētos",
		"kjv" : "without repentance, not to be repented of"
	},
	"G648" : {
		"translit" : "apostegázō",
		"lemma" : "ἀποστεγάζω",
		"kjv" : "uncover",
		"def" : "to unroof",
		"derivation" : "from G575 (ἀπό) and a derivative of G4721 (στέγη)"
	},
	"G4822" : {
		"derivation" : "from G4862 (σύν) and (to force",
		"def" : "causative (by reduplication) of the base of G939 (βάσις)); to drive together, i.e. unite (in association or affection), (mentally) to infer, show, teach",
		"kjv" : "compact, assuredly gather, intrust, knit together, prove",
		"translit" : "symbibázō",
		"lemma" : "συμβιβάζω"
	},
	"G1647" : {
		"kjv" : "less than the least",
		"lemma" : "ἐλαχιστότερος",
		"translit" : "elachistóteros",
		"derivation" : "comparative of G1646 (ἐλάχιστος)",
		"def" : "far less"
	},
	"G3076" : {
		"derivation" : "from G3077 (λύπη)",
		"def" : "to distress; reflexively or passively, to be sad",
		"kjv" : "cause grief, grieve, be in heaviness, (be) sorrow(-ful), be (make) sorry",
		"lemma" : "λυπέω",
		"translit" : "lypéō"
	},
	"G404" : {
		"lemma" : "ἀναψύχω",
		"translit" : "anapsýchō",
		"kjv" : "refresh",
		"def" : "properly, to cool off, i.e. (figuratively) relieve",
		"derivation" : "from G303 (ἀνά) and G5594 (ψύχω)"
	},
	"G2886" : {
		"translit" : "kosmikós",
		"lemma" : "κοσμικός",
		"kjv" : "worldly",
		"def" : "terrene (\"cosmic\"), literally (mundane) or figuratively (corrupt)",
		"derivation" : "from G2889 (κόσμος) (in its secondary sense)"
	},
	"G1952" : {
		"derivation" : "from G1909 (ἐπί) and G3007 (λείπω)",
		"def" : "to leave upon, i.e. (figuratively) to be insufficient for",
		"kjv" : "fail",
		"translit" : "epileípō",
		"lemma" : "ἐπιλείπω"
	},
	"G3579" : {
		"def" : "to be a host (passively, a guest); by implication, be (make, appear) strange",
		"derivation" : "from G3581 (ξένος)",
		"lemma" : "ξενίζω",
		"translit" : "xenízō",
		"kjv" : "entertain, lodge, (think it) strange"
	},
	"G4572" : {
		"def" : "of (with, to) thyself",
		"derivation" : "genitive case from G4571 (σέ) and G846 (αὐτός), also dative case of the same, , and accusative case , likewise contracted , , and , respectively",
		"translit" : "seautoû",
		"lemma" : "σεαυτοῦ",
		"kjv" : "thee, thine own self, (thou) thy(-self)"
	},
	"G2953" : {
		"kjv" : "of Cyprus",
		"translit" : "Kýprios",
		"lemma" : "Κύπριος",
		"derivation" : "from G2954 (Κύπρος)",
		"def" : "a Cyprian (Cypriot), i.e. inhabitant of Cyprus"
	},
	"G4059" : {
		"lemma" : "περιτέμνω",
		"translit" : "peritémnō",
		"kjv" : "circumcise",
		"def" : "to cut around, i.e. (specially) to circumcise",
		"derivation" : "from G4012 (περί) and the base of G5114 (τομώτερος)"
	},
	"G3814" : {
		"def" : "a girl, i.e. (specially), a female slave or servant",
		"derivation" : "feminine diminutive of G3816 (παῖς)",
		"translit" : "paidískē",
		"lemma" : "παιδίσκη",
		"kjv" : "bondmaid(-woman), damsel, maid(-en)"
	},
	"G792" : {
		"kjv" : "star",
		"translit" : "astḗr",
		"lemma" : "ἀστήρ",
		"derivation" : "probably from the base of G4766 (στρώννυμι)",
		"def" : "a star (as strown over the sky), literally or figuratively"
	},
	"G2318" : {
		"def" : "reverent of God, i.e. pious",
		"derivation" : "from G2316 (θεός) and G4576 (σέβομαι)",
		"lemma" : "θεοσεβής",
		"translit" : "theosebḗs",
		"kjv" : "worshipper of God"
	},
	"G2729" : {
		"kjv" : "prevail (against)",
		"lemma" : "κατισχύω",
		"translit" : "katischýō",
		"derivation" : "from G2596 (κατά) and G2480 (ἰσχύω)",
		"def" : "to overpower"
	},
	"G4941" : {
		"lemma" : "Συντύχη",
		"translit" : "Syntýchē",
		"kjv" : "Syntyche",
		"def" : "an accident; Syntyche, a Christian female",
		"derivation" : "from G4940 (συντυγχάνω)"
	},
	"G3625" : {
		"lemma" : "οἰκουμένη",
		"translit" : "oikouménē",
		"kjv" : "earth, world",
		"def" : "land, i.e. the (terrene part of the) globe; specially, the Roman empire",
		"derivation" : "feminine participle present passive of G3611 (οἰκέω) (as noun, by implication, of G1093 (γῆ))"
	},
	"G1597" : {
		"kjv" : "of a long time, of old",
		"lemma" : "ἔκπαλαι",
		"translit" : "ékpalai",
		"derivation" : "from G1537 (ἐκ) and G3819 (πάλαι)",
		"def" : "long ago, for a long while"
	},
	"G1040" : {
		"kjv" : "fine linen",
		"translit" : "býssos",
		"lemma" : "βύσσος",
		"derivation" : "of Hebrew origin (H948)",
		"def" : "white linen"
	},
	"G3356" : {
		"def" : "to be moderate in passion, i.e. gentle (to treat indulgently)",
		"derivation" : "from a compound of the base of G3357 (μετρίως) and G3806 (πάθος)",
		"translit" : "metriopathéō",
		"lemma" : "μετριοπαθέω",
		"kjv" : "have compassion"
	},
	"G1200" : {
		"kjv" : "jailor, keeper of the prison",
		"lemma" : "δεσμοφύλαξ",
		"translit" : "desmophýlax",
		"derivation" : "from G1199 (δεσμόν) and G5441 (φύλαξ)",
		"def" : "a jailer (as guarding the prisoners)"
	},
	"G2416" : {
		"derivation" : "from G2417 (ἱερόσυλος)",
		"def" : "to be a temple-robber (figuratively)",
		"kjv" : "commit sacrilege",
		"lemma" : "ἱεροσυλέω",
		"translit" : "hierosyléō"
	},
	"G2273" : {
		"derivation" : "from G2228 (ἤ) and G5104 (τοί)",
		"def" : "either indeed",
		"kjv" : "whether",
		"translit" : "ḗtoi",
		"lemma" : "ἤτοι"
	},
	"G1521" : {
		"kjv" : "bring in(-to), (+ was to) lead into",
		"translit" : "eiságō",
		"lemma" : "εἰσάγω",
		"derivation" : "from G1519 (εἰς) and G71 (ἄγω)",
		"def" : "to introduce (literally or figuratively)"
	},
	"G600" : {
		"translit" : "apokathístēmi",
		"lemma" : "ἀποκαθίστημι",
		"kjv" : "restore (again)",
		"def" : "to reconstitute (in health, home or organization)",
		"derivation" : "from G575 (ἀπό) and G2525 (καθίστημι)"
	},
	"G1247" : {
		"kjv" : "(ad-)minister (unto), serve, use the office of a deacon",
		"translit" : "diakonéō",
		"lemma" : "διακονέω",
		"derivation" : "from G1249 (διάκονος)",
		"def" : "to be an attendant, i.e. wait upon (menially or as a host, friend, or (figuratively) teacher); technically, to act as a Christian deacon"
	},
	"G2379" : {
		"derivation" : "from a derivative of G2378 (θυσία)",
		"def" : "a place of sacrifice, i.e. an altar (special or genitive case, literal or figurative)",
		"kjv" : "altar",
		"translit" : "thysiastḗrion",
		"lemma" : "θυσιαστήριον"
	},
	"G4519" : {
		"lemma" : "σαβαώθ",
		"translit" : "sabaṓth",
		"kjv" : "sabaoth",
		"def" : "armies; sabaoth (i.e. tsebaoth), a military epithet of God",
		"derivation" : "of Hebrew origin (H6635 in feminine plural)"
	},
	"G4745" : {
		"lemma" : "στοά",
		"translit" : "stoá",
		"kjv" : "porch",
		"def" : "a colonnade or interior piazza",
		"derivation" : "probably from G2476 (ἵστημι)"
	},
	"G634" : {
		"kjv" : "fall",
		"translit" : "apopíptō",
		"lemma" : "ἀποπίπτω",
		"derivation" : "from G575 (ἀπό) and G4098 (πίπτω)",
		"def" : "to fall off"
	},
	"G2505" : {
		"lemma" : "καθά",
		"translit" : "kathá",
		"kjv" : "as",
		"def" : "according to which things, i.e. just as",
		"derivation" : "from G2596 (κατά) and the neuter plural of G3739 (ὅς)"
	},
	"G319" : {
		"kjv" : "be made known",
		"lemma" : "ἀναγνωρίζομαι",
		"translit" : "anagnōrízomai",
		"derivation" : "middle voice from G303 (ἀνά) and G1107 (γνωρίζω)",
		"def" : "to make (oneself) known"
	},
	"G998" : {
		"derivation" : "from G995 (βοή) and theo (to run)",
		"def" : "a succorer",
		"kjv" : "helper",
		"translit" : "boēthós",
		"lemma" : "βοηθός"
	},
	"G1046" : {
		"kjv" : "Gadarene",
		"lemma" : "Γαδαρηνός",
		"translit" : "Gadarēnós",
		"derivation" : "from (a town east of the Jordan)",
		"def" : "a Gadarene or inhabitant of Gadara"
	},
	"G2353" : {
		"def" : "stock (as raised on a farm)",
		"derivation" : "from G5142 (τρέφω)",
		"translit" : "thrémma",
		"lemma" : "θρέμμα",
		"kjv" : "cattle"
	},
	"G4844" : {
		"derivation" : "from G4862 (σύν) and G4095 (πίνω)",
		"def" : "to partake a beverage in company",
		"kjv" : "drink with",
		"lemma" : "συμπίνω",
		"translit" : "sympínō"
	},
	"G5094" : {
		"derivation" : "from G5093 (τίμιος)",
		"def" : "expensiveness, i.e. (by implication) magnificence",
		"kjv" : "costliness",
		"translit" : "timiótēs",
		"lemma" : "τιμιότης"
	},
	"G853" : {
		"kjv" : "corrupt, disfigure, perish, vanish away",
		"translit" : "aphanízō",
		"lemma" : "ἀφανίζω",
		"derivation" : "from G852 (ἀφανής)",
		"def" : "to render unapparent, i.e. (actively) consume (becloud), or (passively) disappear (be destroyed)"
	},
	"G3571" : {
		"lemma" : "νύξ",
		"translit" : "nýx",
		"kjv" : "(mid-)night",
		"def" : "\"night\" (literally or figuratively)",
		"derivation" : "a primary word"
	},
	"G1950" : {
		"kjv" : "(be) forget(-ful of)",
		"lemma" : "ἐπιλανθάνομαι",
		"translit" : "epilanthánomai",
		"derivation" : "middle voice from G1909 (ἐπί) and G2990 (λανθάνω)",
		"def" : "to lose out of mind; by implication, to neglect"
	},
	"G2883" : {
		"derivation" : "of Latin origin",
		"def" : "Cornelius, a Roman",
		"kjv" : "Cornelius",
		"lemma" : "Κορνήλιος",
		"translit" : "Kornḗlios"
	},
	"G4688" : {
		"def" : "a speculator, i.e. military scout (spy or (by extension) life-guardsman)",
		"derivation" : "of Latin origin",
		"translit" : "spekoulátōr",
		"lemma" : "σπεκουλάτωρ",
		"kjv" : "executioner"
	},
	"G263" : {
		"kjv" : "that fadeth not away",
		"lemma" : "ἀμάραντος",
		"translit" : "amárantos",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G3133 (μαραίνω)",
		"def" : "unfading, i.e. (by implication) perpetual"
	},
	"G3737" : {
		"derivation" : "of uncertain affinity",
		"def" : "bereaved (\"orphan\"), i.e. parentless",
		"kjv" : "comfortless, fatherless",
		"translit" : "orphanós",
		"lemma" : "ὀρφανός"
	},
	"G1900" : {
		"derivation" : "from G1909 (ἐπί) and (the accusative case plural neuter of) G1565 (ἐκεῖνος)",
		"def" : "upon those parts of, i.e. on the further side of",
		"kjv" : "beyond",
		"lemma" : "ἐπέκεινα",
		"translit" : "epékeina"
	},
	"G5491" : {
		"lemma" : "χεῖλος",
		"translit" : "cheîlos",
		"kjv" : "lip, shore",
		"def" : "a lip (as a pouring place); figuratively, a margin (of water)",
		"derivation" : "from a form of the same as G5490 (χάσμα)"
	},
	"G2564" : {
		"def" : "to \"call\" (properly, aloud, but used in a variety of applications, directly or otherwise)",
		"derivation" : "akin to the base of G2753 (κελεύω)",
		"lemma" : "καλέω",
		"translit" : "kaléō",
		"kjv" : "bid, call (forth), (whose, whose sur-)name (was (called))"
	},
	"G3198" : {
		"lemma" : "Μελχισεδέκ",
		"translit" : "Melchisedék",
		"kjv" : "Melchisedec",
		"def" : "Melchisedek (i.e. Malkitsedek), a patriarch",
		"derivation" : "of Hebrew origin (H4442)"
	},
	"G147" : {
		"derivation" : "adverb from G146 (αἰσχροκερδής)",
		"def" : "sordidly",
		"kjv" : "for filthy lucre's sake",
		"lemma" : "αἰσχροκερδῶς",
		"translit" : "aischrokerdōs"
	},
	"G396" : {
		"derivation" : "from G303 (ἀνά) and the base of G5157 (τροπή)",
		"def" : "to overturn (figuratively)",
		"kjv" : "overthrow, subvert",
		"lemma" : "ἀνατρέπω",
		"translit" : "anatrépō"
	},
	"G2540" : {
		"kjv" : "X always, opportunity, (convenient, due) season, (due, short, while) time, a while",
		"lemma" : "καιρός",
		"translit" : "kairós",
		"derivation" : "of uncertain affinity",
		"def" : "an occasion, i.e. set or proper time"
	},
	"G4910" : {
		"lemma" : "συνευωχέω",
		"translit" : "syneuōchéō",
		"kjv" : "feast with",
		"def" : "to entertain sumptuously in company with, i.e. (middle voice or passive) to revel together",
		"derivation" : "from G4862 (σύν) and a derivative of a presumed compound of G2095 (εὖ) and a derivative of G2192 (ἔχω) (meaning to be in good condition, i.e. (by implication) to fare well, or feast)"
	},
	"G1254" : {
		"kjv" : "forbid",
		"translit" : "diakōlýō",
		"lemma" : "διακωλύω",
		"derivation" : "from G1223 (διά) and G2967 (κωλύω)",
		"def" : "to hinder altogether, i.e. utterly prohibit"
	},
	"G3310" : {
		"def" : "a portion, i.e. province, share or (abstractly) participation",
		"derivation" : "feminine of G3313 (μέρος)",
		"lemma" : "μερίς",
		"translit" : "merís",
		"kjv" : "part (X -akers)"
	},
	"G5271" : {
		"derivation" : "middle voice from G5259 (ὑπό) and G2919 (κρίνω)",
		"def" : "to decide (speak or act) under a false part, i.e. (figuratively) dissemble (pretend)",
		"kjv" : "feign",
		"lemma" : "ὑποκρίνομαι",
		"translit" : "hypokrínomai"
	},
	"G5102" : {
		"derivation" : "of Latin origin",
		"def" : "a titulus or \"title\" (placard)",
		"kjv" : "title",
		"lemma" : "τίτλος",
		"translit" : "títlos"
	},
	"G415" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G1655 (ἐλεήμων)",
		"def" : "merciless",
		"kjv" : "unmerciful",
		"translit" : "aneleḗmōn",
		"lemma" : "ἀνελεήμων"
	},
	"G2124" : {
		"def" : "properly, caution, i.e. (religiously) reverence (piety); by implication, dread (concretely)",
		"derivation" : "from G2126 (εὐλαβής)",
		"lemma" : "εὐλάβεια",
		"translit" : "eulábeia",
		"kjv" : "fear(-ed)"
	},
	"G413" : {
		"def" : "not left out, i.e. (by implication) inexhaustible",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G1587 (ἐκλείπω)",
		"lemma" : "ἀνέκλειπτος",
		"translit" : "anékleiptos",
		"kjv" : "that faileth not"
	},
	"G1825" : {
		"kjv" : "raise up",
		"translit" : "exegeírō",
		"lemma" : "ἐξεγείρω",
		"derivation" : "from G1537 (ἐκ) and G1453 (ἐγείρω)",
		"def" : "to rouse fully, i.e. (figuratively) to resuscitate (from death), release (from infliction)"
	},
	"G5285" : {
		"translit" : "hypopnéō",
		"lemma" : "ὑποπνέω",
		"kjv" : "blow softly",
		"def" : "to breathe gently, i.e. breeze",
		"derivation" : "from G5259 (ὑπό) and G4154 (πνέω)"
	},
	"G248" : {
		"def" : "to tread out grain",
		"derivation" : "from the same as G257 (ἅλων)",
		"translit" : "aloáō",
		"lemma" : "ἀλοάω",
		"kjv" : "thresh, tread out the corn"
	},
	"G2805" : {
		"kjv" : "wailing, weeping, X wept",
		"translit" : "klauthmós",
		"lemma" : "κλαυθμός",
		"derivation" : "from G2799 (κλαίω)",
		"def" : "lamentation"
	},
	"G1828" : {
		"def" : "to drag forth, i.e. (figuratively) to entice (to sin)",
		"derivation" : "from G1537 (ἐκ) and G1670 (ἑλκύω)",
		"lemma" : "ἐξέλκω",
		"translit" : "exélkō",
		"kjv" : "draw away"
	},
	"G5022" : {
		"derivation" : "apparently a primary word (compare H8450, \"steer\")",
		"def" : "a bullock",
		"kjv" : "bull, ox",
		"translit" : "taûros",
		"lemma" : "ταῦρος"
	},
	"G753" : {
		"def" : "a chief constructor, i.e. \"architect\"",
		"derivation" : "from G746 (ἀρχή) and G5045 (τέκτων)",
		"translit" : "architéktōn",
		"lemma" : "ἀρχιτέκτων",
		"kjv" : "masterbuilder"
	},
	"G4560" : {
		"kjv" : "fleshly",
		"translit" : "sárkinos",
		"lemma" : "σάρκινος",
		"derivation" : "from G4561 (σάρξ)",
		"def" : "similar to flesh, i.e. (by analogy) soft"
	},
	"G5249" : {
		"kjv" : "beyond measure",
		"translit" : "hyperperissōs",
		"lemma" : "ὑπερπερισσῶς",
		"derivation" : "from G5228 (ὑπέρ) and G4057 (περισσῶς)",
		"def" : "superabundantly, i.e. exceedingly"
	},
	"G3484" : {
		"def" : "Nain, a place in Palestine",
		"derivation" : "probably of Hebrew origin (compare H4999)",
		"translit" : "Naḯn",
		"lemma" : "Ναΐν",
		"kjv" : "Nain"
	},
	"G1031" : {
		"lemma" : "βρύχω",
		"translit" : "brýchō",
		"kjv" : "gnash",
		"def" : "to grate the teeth (in pain or rage)",
		"derivation" : "a primary verb"
	},
	"G3764" : {
		"translit" : "oudépō",
		"lemma" : "οὐδέπω",
		"kjv" : "as yet not, never before (yet), (not) yet",
		"def" : "not even yet",
		"derivation" : "from G3761 (οὐδέ) and G4452 (-πω)"
	},
	"G865" : {
		"kjv" : "despiser of those that are good",
		"translit" : "aphilágathos",
		"lemma" : "ἀφιλάγαθος",
		"derivation" : "from G1 (Α) (as a negative particle) and G5358 (φιλάγαθος)",
		"def" : "hostile to virtue"
	},
	"G2754" : {
		"translit" : "kenodoxía",
		"lemma" : "κενοδοξία",
		"kjv" : "vain-glory",
		"def" : "empty glorying, i.e. self-conceit",
		"derivation" : "from G2755 (κενόδοξος)"
	},
	"G2196" : {
		"derivation" : "of Hebrew origin (H2226)",
		"def" : "Zara, (i.e. Zerach), an Israelite",
		"kjv" : "Zara",
		"lemma" : "Ζαρά",
		"translit" : "Zará"
	},
	"G4753" : {
		"lemma" : "στράτευμα",
		"translit" : "stráteuma",
		"kjv" : "army, soldier, man of war",
		"def" : "an armament, i.e. (by implication) a body of troops (more or less extensive or systematic)",
		"derivation" : "from G4754 (στρατεύομαι)"
	},
	"G4552" : {
		"def" : "a \"sapphire\" or lapis-lazuli gem",
		"derivation" : "of Hebrew origin (H5601)",
		"lemma" : "σάπφειρος",
		"translit" : "sáppheiros",
		"kjv" : "sapphire"
	},
	"G211" : {
		"kjv" : "(alabaster) box",
		"lemma" : "ἀλάβαστρον",
		"translit" : "alábastron",
		"derivation" : "neuter of (of uncertain derivation), the name of a stone",
		"def" : "properly, an \"alabaster\" box, i.e. (by extension) a perfume vase (of any material)"
	},
	"G4805" : {
		"def" : "co-yoked, i.e. (figuratively) as noun, a colleague; probably rather as a proper name; Syzygus, a Christian",
		"derivation" : "from G4801 (συζεύγνυμι)",
		"translit" : "sýzygos",
		"lemma" : "σύζυγος",
		"kjv" : "yokefellow"
	},
	"G1062" : {
		"def" : "nuptials",
		"derivation" : "of uncertain affinity",
		"translit" : "gámos",
		"lemma" : "γάμος",
		"kjv" : "marriage, wedding"
	},
	"G3567" : {
		"def" : "the bridal room",
		"derivation" : "from G3565 (νύμφη)",
		"translit" : "nymphṓn",
		"lemma" : "νυμφών",
		"kjv" : "bridechamber"
	},
	"G3912" : {
		"lemma" : "παραφρονέω",
		"translit" : "paraphronéō",
		"kjv" : "as a fool",
		"def" : "to misthink, i.e. be insane (silly)",
		"derivation" : "from G3844 (παρά) and G5426 (φρονέω)"
	},
	"G4154" : {
		"def" : "to breathe hard, i.e. breeze",
		"derivation" : "a primary word",
		"translit" : "pnéō",
		"lemma" : "πνέω",
		"kjv" : "blow"
	},
	"G1655" : {
		"translit" : "eleḗmōn",
		"lemma" : "ἐλεήμων",
		"kjv" : "merciful",
		"def" : "compassionate (actively)",
		"derivation" : "from G1653 (ἐλεέω)"
	},
	"G3803" : {
		"lemma" : "παγίς",
		"translit" : "pagís",
		"kjv" : "snare",
		"def" : "a trap (as fastened by a noose or notch); figuratively, a trick or statagem (temptation)",
		"derivation" : "from G4078 (πήγνυμι)"
	},
	"G1245" : {
		"def" : "to cleanse perfectly, i.e. (specially) winnow",
		"derivation" : "from G1223 (διά) and G2511 (καθαρίζω)",
		"lemma" : "διακαθαρίζω",
		"translit" : "diakatharízō",
		"kjv" : "thoroughly purge"
	},
	"G5515" : {
		"def" : "greenish, i.e. verdant, dun-colored",
		"derivation" : "from the same as G5514 (Χλόη)",
		"translit" : "chlōrós",
		"lemma" : "χλωρός",
		"kjv" : "green, pale"
	},
	"G3100" : {
		"def" : "intransitively, to become a pupil; transitively, to disciple, i.e. enrol as scholar",
		"derivation" : "from G3101 (μαθητής)",
		"translit" : "mathēteúō",
		"lemma" : "μαθητεύω",
		"kjv" : "be disciple, instruct, teach"
	},
	"G3051" : {
		"lemma" : "λόγιον",
		"translit" : "lógion",
		"kjv" : "oracle",
		"def" : "an utterance (of God)",
		"derivation" : "neuter of G3052 (λόγιος)"
	},
	"G816" : {
		"def" : "to gaze intently",
		"derivation" : "from a compound of G1 (Α) (as a particle of union) and (to stretch)",
		"lemma" : "ἀτενίζω",
		"translit" : "atenízō",
		"kjv" : "behold earnestly (stedfastly), fasten (eyes), look (earnestly, stedfastly, up stedfastly), set eyes"
	},
	"G3974" : {
		"def" : "Paphus, a place in Cyprus",
		"derivation" : "of uncertain derivation",
		"translit" : "Páphos",
		"lemma" : "Πάφος",
		"kjv" : "Paphos"
	},
	"G689" : {
		"translit" : "Arám",
		"lemma" : "Ἀράμ",
		"kjv" : "Aram",
		"def" : "Aram (i.e. Ram), an Israelite",
		"derivation" : "of Hebrew origin (H7410)"
	},
	"G217" : {
		"kjv" : "salt",
		"lemma" : "ἅλας",
		"translit" : "hálas",
		"derivation" : "from G251 (ἅλς)",
		"def" : "salt; figuratively, prudence"
	},
	"G4895" : {
		"derivation" : "from G4862 (σύν) and G1510 (εἰμί) (including its various inflections)",
		"def" : "to be in company with, i.e. present at the time",
		"kjv" : "be with",
		"translit" : "sýneimi",
		"lemma" : "σύνειμι"
	},
	"G1279" : {
		"def" : "to travel through",
		"derivation" : "from G1223 (διά) and G4198 (πορεύομαι)",
		"lemma" : "διαπορεύομαι",
		"translit" : "diaporeúomai",
		"kjv" : "go through, journey in, pass by"
	},
	"G3889" : {
		"kjv" : "comfort",
		"lemma" : "παραμυθία",
		"translit" : "paramythía",
		"derivation" : "from G3888 (παραμυθέομαι)",
		"def" : "consolation (properly, abstract)"
	},
	"G4328" : {
		"def" : "to anticipate (in thought, hope or fear); by implication, to await",
		"derivation" : "from G4314 (πρός) and dokeuo (to watch)",
		"lemma" : "προσδοκάω",
		"translit" : "prosdokáō",
		"kjv" : "(be in) expect(-ation), look (for), when looked, tarry, wait for"
	},
	"G1561" : {
		"lemma" : "ἐκδοχή",
		"translit" : "ekdochḗ",
		"kjv" : "looking for",
		"def" : "expectation",
		"derivation" : "from G1551 (ἐκδέχομαι)"
	},
	"G4580" : {
		"kjv" : "Secundus",
		"lemma" : "Σεκοῦνδος",
		"translit" : "Sekoûndos",
		"derivation" : "of Latin origin",
		"def" : "\"second\"; Secundus, a Christian"
	},
	"G5513" : {
		"lemma" : "χλιαρός",
		"translit" : "chliarós",
		"kjv" : "lukewarm",
		"def" : "tepid",
		"derivation" : "from (to warm)"
	},
	"G1199" : {
		"derivation" : "neuter and masculine respectively from G1210 (δέω)",
		"def" : "a band, i.e. ligament (of the body) or shackle (of a prisoner); figuratively, an impediment or disability",
		"kjv" : "band, bond, chain, string",
		"lemma" : "δεσμόν",
		"translit" : "desmón"
	},
	"G2346" : {
		"def" : "to crowd (literally or figuratively)",
		"derivation" : "akin to the base of G5147 (τρίβος)",
		"lemma" : "θλίβω",
		"translit" : "thlíbō",
		"kjv" : "afflict, narrow, throng, suffer tribulation, trouble"
	},
	"G1978" : {
		"kjv" : "notable, of note",
		"translit" : "epísēmos",
		"lemma" : "ἐπίσημος",
		"derivation" : "from G1909 (ἐπί) and some form of the base of G4591 (σημαίνω)",
		"def" : "remarkable, i.e. (figuratively) eminent"
	},
	"G3537" : {
		"kjv" : "bason",
		"lemma" : "νιπτήρ",
		"translit" : "niptḗr",
		"derivation" : "from G3538 (νίπτω)",
		"def" : "a ewer"
	},
	"G2218" : {
		"kjv" : "pair of balances, yoke",
		"lemma" : "ζυγός",
		"translit" : "zygós",
		"derivation" : "from the root of (to join, especially by a \"yoke\")",
		"def" : "a coupling, i.e. (figuratively) servitude (a law or obligation); also (literally) the beam of the balance (as connecting the scales)"
	},
	"G4975" : {
		"derivation" : "neuter of a presumed derivative of the alternate of G2192 (ἔχω) as adverb",
		"def" : "nigh, i.e. nearly",
		"kjv" : "almost",
		"translit" : "schedón",
		"lemma" : "σχεδόν"
	},
	"G4529" : {
		"derivation" : "probably from G4535 (σάλος) (from the surge on the shore)",
		"def" : "Salamis, a place in Cyprus",
		"kjv" : "Salamis",
		"lemma" : "Σαλαμίς",
		"translit" : "Salamís"
	},
	"G1061" : {
		"def" : "to espouse (a daughter to a husband)",
		"derivation" : "from G1062 (γάμος)",
		"lemma" : "γαμίσκω",
		"translit" : "gamískō",
		"kjv" : "give in marriage"
	},
	"G645" : {
		"derivation" : "from G575 (ἀπό) and G4685 (σπάω)",
		"def" : "to drag forth, i.e. (literally) unsheathe (a sword), or relatively (with a degree of force implied) retire (personally or factiously)",
		"kjv" : "(with-)draw (away), after we were gotten from",
		"translit" : "apospáō",
		"lemma" : "ἀποσπάω"
	},
	"G3680" : {
		"kjv" : "reproach",
		"translit" : "oneidismós",
		"lemma" : "ὀνειδισμός",
		"derivation" : "from G3679 (ὀνειδίζω)",
		"def" : "contumely"
	},
	"G3372" : {
		"lemma" : "μῆκος",
		"translit" : "mēkos",
		"derivation" : "probably akin to G3173 (μέγας)",
		"def" : "length (literally or figuratively) length. "
	},
	"G2277" : {
		"kjv" : "let … be",
		"lemma" : "ἤτω",
		"translit" : "ḗtō",
		"derivation" : "third person singular imperative of G1510 (εἰμί)",
		"def" : "let him (or it) be"
	},
	"G5263" : {
		"derivation" : "from G5259 (ὑπό) and G1166 (δεικνύω)",
		"def" : "to exhibit under the eyes, i.e. (figuratively) to exemplify (instruct, admonish)",
		"kjv" : "show, (fore-)warn",
		"lemma" : "ὑποδείκνυμι",
		"translit" : "hypodeíknymi"
	},
	"G4770" : {
		"kjv" : "Stoick",
		"lemma" : "Στωϊκός",
		"translit" : "Stōïkós",
		"derivation" : "from G4745 (στοά)",
		"def" : "a \"Stoic\" (as occupying a particular porch in Athens), i.e. adherent of a certin philosophy"
	},
	"G3920" : {
		"derivation" : "from G3919 (παρεισάγω)",
		"def" : "smuggled in",
		"kjv" : "unawares brought in",
		"translit" : "pareísaktos",
		"lemma" : "παρείσακτος"
	},
	"G1863" : {
		"derivation" : "from G1909 (ἐπί) and G71 (ἄγω)",
		"def" : "to superinduce, i.e. inflict (an evil), charge (a crime)",
		"kjv" : "bring upon",
		"lemma" : "ἐπάγω",
		"translit" : "epágō"
	},
	"G19" : {
		"kjv" : "goodness",
		"lemma" : "ἀγαθωσύνη",
		"translit" : "agathōsýnē",
		"derivation" : "from G18 (ἀγαθός)",
		"def" : "goodness, i.e. virtue or beneficence"
	},
	"G5525" : {
		"lemma" : "χορός",
		"translit" : "chorós",
		"kjv" : "dancing",
		"def" : "a ring, i.e. round dance (\"choir\"",
		"derivation" : "of uncertain derivation"
	},
	"G3574" : {
		"def" : "a day-and-night, i.e. full day of twenty-four hours",
		"derivation" : "from G3571 (νύξ) and G2250 (ἡμέρα)",
		"lemma" : "νυχθήμερον",
		"translit" : "nychthḗmeron",
		"kjv" : "night and day"
	},
	"G2334" : {
		"lemma" : "θεωρέω",
		"translit" : "theōréō",
		"kjv" : "behold, consider, look on, perceive, see",
		"def" : "to be a spectator of, i.e. discern, (literally, figuratively (experience) or intensively (acknowledge))",
		"derivation" : "from a derivative of G2300 (θεάομαι) (perhaps by addition of G3708 (ὁράω))"
	},
	"G639" : {
		"lemma" : "ἀπορέω",
		"translit" : "aporéō",
		"kjv" : "(stand in) doubt, be perplexed",
		"def" : "to have no way out, i.e. be at a loss (mentally)",
		"derivation" : "from a compound of G1 (Α) (as a negative particle) and the base of G4198 (πορεύομαι)"
	},
	"G200" : {
		"translit" : "akrís",
		"lemma" : "ἀκρίς",
		"kjv" : "locust",
		"def" : "a locust (as pointed, or as lighting on the top of vegetation)",
		"derivation" : "apparently from the same as G206 (ἄκρον)"
	},
	"G983" : {
		"derivation" : "from G984 (βλάπτω)",
		"def" : "injurious",
		"kjv" : "hurtful",
		"lemma" : "βλαβερός",
		"translit" : "blaberós"
	},
	"G2071" : {
		"kjv" : "shall (should) be (have), (shall) come (to pass), X may have, X fall, what would follow, X live long, X sojourn",
		"lemma" : "ἔσομαι",
		"translit" : "ésomai",
		"derivation" : "future of G1510 (εἰμί)",
		"def" : "will be"
	},
	"G2367" : {
		"lemma" : "θύϊνος",
		"translit" : "thýïnos",
		"kjv" : "thyine",
		"def" : "denoting a certain fragrant tree); made of citron-wood",
		"derivation" : "from a derivative of G2380 (θύω) (in the sense of blowing"
	},
	"G823" : {
		"kjv" : "moment",
		"translit" : "átomos",
		"lemma" : "ἄτομος",
		"derivation" : "from G1 (Α) (as a negative particle) and the base of G5114 (τομώτερος)",
		"def" : "uncut, i.e. (by implication) indivisible (an \"atom\" of time)"
	},
	"G3330" : {
		"lemma" : "μεταδίδωμι",
		"translit" : "metadídōmi",
		"kjv" : "give, impart",
		"def" : "to give over, i.e. share",
		"derivation" : "from G3326 (μετά) and G1325 (δίδωμι)"
	},
	"G1523" : {
		"lemma" : "εἰσδέχομαι",
		"translit" : "eisdéchomai",
		"kjv" : "receive",
		"def" : "to take into one's favor",
		"derivation" : "from G1519 (εἰς) and G1209 (δέχομαι)"
	},
	"G2445" : {
		"def" : "Joppe (i.e. Japho), a place in Palestine",
		"derivation" : "of Hebrew origin (H3305)",
		"lemma" : "Ἰόππη",
		"translit" : "Ióppē",
		"kjv" : "Joppa"
	},
	"G1398" : {
		"kjv" : "be in bondage, (do) serve(-ice)",
		"lemma" : "δουλεύω",
		"translit" : "douleúō",
		"derivation" : "from G1401 (δοῦλος)",
		"def" : "to be a slave to (literal or figurative, involuntary or voluntary)"
	},
	"G4084" : {
		"kjv" : "apprehend, catch, lay hand on, take",
		"translit" : "piázō",
		"lemma" : "πιάζω",
		"derivation" : "probably another form of G971 (βιάζω)",
		"def" : "to squeeze, i.e. seize (gently by the hand (press), or officially (arrest), or in hunting (capture))"
	},
	"G4070" : {
		"kjv" : "+ a year ago",
		"translit" : "pérysi",
		"lemma" : "πέρυσι",
		"derivation" : "adverb from G4009 (πέρας)",
		"def" : "the by-gone, i.e. (as noun) last year"
	},
	"G5369" : {
		"derivation" : "from G5384 (φίλος) and G2237 (ἡδονή)",
		"def" : "fond of pleasure, i.e. voluptuous",
		"kjv" : "lover of pleasure",
		"lemma" : "φιλήδονος",
		"translit" : "philḗdonos"
	},
	"G3772" : {
		"derivation" : "perhaps from the same as G3735 (ὄρος) (through the idea of elevation)",
		"def" : "the sky; by extension, heaven (as the abode of God); by implication, happiness, power, eternity; specially, the Gospel (Christianity)",
		"kjv" : "air, heaven(-ly), sky",
		"lemma" : "οὐρανός",
		"translit" : "ouranós"
	},
	"G3935" : {
		"derivation" : "from G3844 (παρά) and hiemi (to send)",
		"def" : "to let by, i.e. relax",
		"kjv" : "hang down",
		"translit" : "paríēmi",
		"lemma" : "παρίημι"
	},
	"G2955" : {
		"translit" : "kýptō",
		"lemma" : "κύπτω",
		"kjv" : "stoop (down)",
		"def" : "to bend forward",
		"derivation" : "probably from the base of G2949 (κῦμα)"
	},
	"G2406" : {
		"derivation" : "from G2407 (ἱερατεύω)",
		"def" : "the priestly fraternity, i.e. sacerdotal order (figuratively)",
		"kjv" : "priesthood",
		"translit" : "hieráteuma",
		"lemma" : "ἱεράτευμα"
	},
	"G4779" : {
		"translit" : "synkaléō",
		"lemma" : "συγκαλέω",
		"kjv" : "call together",
		"def" : "to convoke",
		"derivation" : "from G4862 (σύν) and G2564 (καλέω)"
	},
	"G41" : {
		"def" : "sanctity (i.e. properly, the state)",
		"derivation" : "from G40 (ἅγιος)",
		"translit" : "hagiótēs",
		"lemma" : "ἁγιότης",
		"kjv" : "holiness"
	},
	"G3168" : {
		"translit" : "megaleiótēs",
		"lemma" : "μεγαλειότης",
		"kjv" : "magnificence, majesty, mighty power",
		"def" : "superbness, i.e. glory or splendor",
		"derivation" : "from G3167 (μεγαλεῖος)"
	},
	"G5213" : {
		"derivation" : "irregular dative case of G5210 (ὑμεῖς)",
		"def" : "to (with or by) you",
		"kjv" : "ye, you, your(-selves)",
		"translit" : "hymîn",
		"lemma" : "ὑμῖν"
	},
	"G4350" : {
		"kjv" : "beat upon, dash, stumble (at)",
		"lemma" : "προσκόπτω",
		"translit" : "proskóptō",
		"derivation" : "from G4314 (πρός) and G2875 (κόπτω)",
		"def" : "to strike at, i.e. surge against (as water); specially, to stub on, i.e. trip up (literally or figuratively)"
	},
	"G3490" : {
		"kjv" : "owner of a ship",
		"lemma" : "ναύκληρος",
		"translit" : "naúklēros",
		"derivation" : "from G3491 (ναῦς) and G2819 (κλῆρος) (\"clerk\")",
		"def" : "a captain"
	},
	"G4635" : {
		"def" : "a manufacturer of tents",
		"derivation" : "from G4633 (σκηνή) and G4160 (ποιέω)",
		"lemma" : "σκηνοποιός",
		"translit" : "skēnopoiós",
		"kjv" : "tent-maker"
	},
	"G1433" : {
		"lemma" : "δωρέομαι",
		"translit" : "dōréomai",
		"kjv" : "give",
		"def" : "to bestow gratuitously",
		"derivation" : "middle voice from G1435 (δῶρον)"
	},
	"G572" : {
		"def" : "singleness, i.e. (subjectively) sincerity (without dissimulation or self-seeking), or (objectively) generosity (copious bestowal)",
		"derivation" : "from G573 (ἁπλοῦς)",
		"translit" : "haplótēs",
		"lemma" : "ἁπλότης",
		"kjv" : "bountifulness, liberal(-ity), simplicity, singleness"
	},
	"G4345" : {
		"lemma" : "προσκληρόω",
		"translit" : "prosklēróō",
		"kjv" : "consort with",
		"def" : "to give a common lot to, i.e. (figuratively) to associate with",
		"derivation" : "from G4314 (πρός) and G2820 (κληρόω)"
	},
	"G3060" : {
		"def" : "abusive, i.e. a blackguard",
		"derivation" : "from (mischief)",
		"lemma" : "λοίδορος",
		"translit" : "loídoros",
		"kjv" : "railer, reviler"
	},
	"G1679" : {
		"derivation" : "from G1680 (ἐλπίς)",
		"def" : "to expect or confide",
		"kjv" : "(have, thing) hope(-d) (for), trust",
		"translit" : "elpízō",
		"lemma" : "ἐλπίζω"
	},
	"G651" : {
		"kjv" : "apostleship",
		"translit" : "apostolḗ",
		"lemma" : "ἀποστολή",
		"derivation" : "from G649 (ἀποστέλλω)",
		"def" : "commission, i.e. (specially) apostolate"
	},
	"G3942" : {
		"def" : "apparently a state alongside of supposition, i.e. (concretely) an adage; specially, an enigmatical or fictitious illustration",
		"derivation" : "from a compound of G3844 (παρά) and perhaps a derivative of G3633 (οἴομαι)",
		"translit" : "paroimía",
		"lemma" : "παροιμία",
		"kjv" : "parable, proverb"
	},
	"G3345" : {
		"lemma" : "μετασχηματίζω",
		"translit" : "metaschēmatízō",
		"kjv" : "transfer, transform (self)",
		"def" : "to transfigure or disguise; figuratively, to apply (by accommodation)",
		"derivation" : "from G3326 (μετά) and a derivative of G4976 (σχῆμα)"
	},
	"G738" : {
		"kjv" : "new born",
		"translit" : "artigénnētos",
		"lemma" : "ἀρτιγέννητος",
		"derivation" : "from G737 (ἄρτι) and G1084 (γεννητός)",
		"def" : "just born, i.e. (figuratively) a young convert"
	},
	"G5312" : {
		"derivation" : "from G5311 (ὕψος)",
		"def" : "to elevate (literally or figuratively)",
		"kjv" : "exalt, lift up",
		"translit" : "hypsóō",
		"lemma" : "ὑψόω"
	},
	"G1585" : {
		"derivation" : "middle voice from G1537 (ἐκ) and G2990 (λανθάνω)",
		"def" : "to be utterly oblivious of",
		"kjv" : "forget",
		"translit" : "eklanthánomai",
		"lemma" : "ἐκλανθάνομαι"
	},
	"G4836" : {
		"def" : "to be present together, i.e. to convene; by implication, to appear in aid",
		"derivation" : "from G4862 (σύν) and G3854 (παραγίνομαι)",
		"lemma" : "συμπαραγίνομαι",
		"translit" : "symparagínomai",
		"kjv" : "come together, stand with"
	},
	"G517" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G3707 (ὁρατός)",
		"def" : "invisible",
		"kjv" : "invisible (thing)",
		"translit" : "aóratos",
		"lemma" : "ἀόρατος"
	},
	"G5376" : {
		"derivation" : "from G5384 (φίλος) and G2462 (ἵππος)",
		"def" : "fond of horses; Philippus, the name of four Israelites",
		"kjv" : "Philip",
		"translit" : "Phílippos",
		"lemma" : "Φίλιππος"
	},
	"G3165" : {
		"kjv" : "I, me, my",
		"translit" : "mé",
		"lemma" : "μέ",
		"derivation" : "a shorter (and probably original) form of G1691 (ἐμέ)",
		"def" : "me"
	},
	"G4746" : {
		"derivation" : "from a primary (to \"step\" or \"stamp\")",
		"def" : "a spread (as if tramped flat) of loose materials for a couch, i.e. (by implication) a bough of a tree so employed",
		"kjv" : "branch",
		"lemma" : "στοιβάς",
		"translit" : "stoibás"
	},
	"G1173" : {
		"derivation" : "from the same as G1160 (δαπάνη)",
		"def" : "dinner, i.e. the chief meal (usually in the evening)",
		"kjv" : "feast, supper",
		"translit" : "deîpnon",
		"lemma" : "δεῖπνον"
	},
	"G4410" : {
		"derivation" : "from G4413 (πρῶτος) and G2515 (καθέδρα)",
		"def" : "a sitting first (in the front row), i.e. preeminence in council",
		"kjv" : "chief (highest, uppermost) seat",
		"translit" : "prōtokathedría",
		"lemma" : "πρωτοκαθεδρία"
	},
	"G1563" : {
		"translit" : "ekeî",
		"lemma" : "ἐκεῖ",
		"kjv" : "there, thither(-ward), (to) yonder (place)",
		"def" : "there; by extension, thither",
		"derivation" : "of uncertain affinity"
	},
	"G4033" : {
		"kjv" : "compass round",
		"translit" : "perikyklóō",
		"lemma" : "περικυκλόω",
		"derivation" : "from G4012 (περί) and G2944 (κυκλόω)",
		"def" : "to encircle all around, i.e. blockade completely"
	},
	"G3044" : {
		"derivation" : "perhaps from G3043 (λίνον)",
		"def" : "Linus, a Christian",
		"kjv" : "Linus",
		"lemma" : "Λίνος",
		"translit" : "Línos"
	},
	"G4475" : {
		"kjv" : "(+ strike with the) palm of the hand, smite with the hand",
		"lemma" : "ῥάπισμα",
		"translit" : "rhápisma",
		"derivation" : "from G4474 (ῥαπίζω)",
		"def" : "a slap"
	},
	"G2810" : {
		"translit" : "Kleópas",
		"lemma" : "Κλεόπας",
		"kjv" : "Cleopas",
		"def" : "Cleopas, a Christian",
		"derivation" : "probably contracted from (compound of G2811 (κλέος) and G3962 (πατήρ))"
	},
	"G124" : {
		"translit" : "Aigýptios",
		"lemma" : "Αἰγύπτιος",
		"kjv" : "Egyptian",
		"def" : "an Ægyptian or inhabitant of Ægyptus",
		"derivation" : "from G125 (Αἴγυπτος)"
	},
	"G4457" : {
		"def" : "stupidity or callousness",
		"derivation" : "from G4456 (πωρόω)",
		"lemma" : "πώρωσις",
		"translit" : "pṓrōsis",
		"kjv" : "blindness, hardness"
	},
	"G5417" : {
		"lemma" : "φραγελλόω",
		"translit" : "phragellóō",
		"kjv" : "scourge",
		"def" : "to whip, i.e. lash as a public punishment",
		"derivation" : "from a presumed equivalent of the Latin flagellum"
	},
	"G4416" : {
		"def" : "first-born (usually as noun, literally or figuratively)",
		"derivation" : "from G4413 (πρῶτος) and the alternate of G5088 (τίκτω)",
		"translit" : "prōtótokos",
		"lemma" : "πρωτότοκος",
		"kjv" : "firstbegotten(-born)"
	},
	"G3509" : {
		"def" : "a cloud",
		"derivation" : "apparently a primary word",
		"translit" : "néphos",
		"lemma" : "νέφος",
		"kjv" : "cloud"
	},
	"G4597" : {
		"translit" : "sḗs",
		"lemma" : "σής",
		"kjv" : "moth",
		"def" : "a moth",
		"derivation" : "apparently of Hebrew origin (H5580)"
	},
	"G2945" : {
		"kjv" : "round about",
		"translit" : "kýklōi",
		"lemma" : "κύκλῳ",
		"derivation" : "as if dative case of (a ring, \"cycle\"",
		"def" : "akin to G2947 (κυλιόω)); i.e. in a circle (by implication, of G1722 (ἐν)), i.e. (adverbially) all around"
	},
	"G3177" : {
		"kjv" : "(by) interpret(-ation)",
		"lemma" : "μεθερμηνεύω",
		"translit" : "methermēneúō",
		"derivation" : "from G3326 (μετά) and G2059 (ἑρμηνεύω)",
		"def" : "to explain over, i.e. translate"
	},
	"G4227" : {
		"kjv" : "Pudens",
		"lemma" : "Πούδης",
		"translit" : "Poúdēs",
		"derivation" : "of Latin origin",
		"def" : "modest; Pudes (i.e. Pudens), a Christian"
	},
	"G5224" : {
		"kjv" : "goods, that which one has, things which (one) possesseth, substance, that hast",
		"lemma" : "ὑπάρχοντα",
		"translit" : "hypárchonta",
		"derivation" : "neuter plural of present participle active of G5225 (ὑπάρχω) as noun",
		"def" : "things extant or in hand, i.e. property or possessions"
	},
	"G67" : {
		"kjv" : "Agrippa",
		"lemma" : "Ἀγρίππας",
		"translit" : "Agríppas",
		"derivation" : "apparently from G66 (ἄγριος) and G2462 (ἵππος)",
		"def" : "wild-horse tamer; Agrippas, one of the Herods"
	},
	"G2404" : {
		"lemma" : "Ἱεράπολις",
		"translit" : "Hierápolis",
		"kjv" : "Hierapolis",
		"def" : "holy city; Hierapolis, a place in Asia Minor",
		"derivation" : "from G2413 (ἱερός) and G4172 (πόλις)"
	},
	"G2241" : {
		"derivation" : "of Hebrew origin (H410 with pronominal suffix)",
		"def" : "my God",
		"kjv" : "Eli",
		"translit" : "ēlí",
		"lemma" : "ἠλί"
	},
	"G1710" : {
		"translit" : "emporeúomai",
		"lemma" : "ἐμπορεύομαι",
		"kjv" : "buy and sell, make merchandise",
		"def" : "to travel in (a country as a pedlar), i.e. (by implication) to trade",
		"derivation" : "from G1722 (ἐν) and G4198 (πορεύομαι)"
	},
	"G3017" : {
		"translit" : "Leuḯ",
		"lemma" : "Λευΐ",
		"kjv" : "Levi",
		"def" : "Levi, the name of three Israelites",
		"derivation" : "of Hebrew origin (H3878)"
	},
	"G167" : {
		"derivation" : "from G169 (ἀκάθαρτος)",
		"def" : "impurity (the quality), physically or morally",
		"kjv" : "uncleanness",
		"translit" : "akatharsía",
		"lemma" : "ἀκαθαρσία"
	},
	"G3025" : {
		"kjv" : "winepress",
		"translit" : "lēnós",
		"lemma" : "ληνός",
		"derivation" : "apparently a primary word",
		"def" : "a trough, i.e. wine-vat"
	},
	"G1215" : {
		"def" : "to be a people-gatherer, i.e. to address a public assembly",
		"derivation" : "from a compound of G1218 (δῆμος) and G58 (ἀγορά)",
		"lemma" : "δημηγορέω",
		"translit" : "dēmēgoréō",
		"kjv" : "make an oration"
	},
	"G857" : {
		"kjv" : "neglecting",
		"lemma" : "ἀφειδία",
		"translit" : "apheidía",
		"derivation" : "from a compound of G1 (Α) (as a negative particle) and G5339 (φείδομαι)",
		"def" : "unsparingness, i.e. austerity (asceticism)"
	},
	"G4853" : {
		"kjv" : "countryman",
		"translit" : "symphylétēs",
		"lemma" : "συμφυλέτης",
		"derivation" : "from G4862 (σύν) and a derivative of G5443 (φυλή)",
		"def" : "a co-tribesman, i.e. native of the same country"
	},
	"G1642" : {
		"lemma" : "ἐλαττόω",
		"translit" : "elattóō",
		"kjv" : "decrease, make lower",
		"def" : "to lessen (in rank or influence)",
		"derivation" : "from G1640 (ἐλάσσων)"
	},
	"G4632" : {
		"kjv" : "goods, sail, stuff, vessel",
		"lemma" : "σκεῦος",
		"translit" : "skeûos",
		"derivation" : "of uncertain affinity",
		"def" : "a vessel, implement, equipment or apparatus (literally or figuratively [specially, a wife as contributing to the usefulness of the husband])"
	},
	"G4242" : {
		"def" : "seniority (eldership), i.e. (by implication) an embassy (concretely, ambassadors)",
		"derivation" : "from G4243 (πρεσβεύω)",
		"lemma" : "πρεσβεία",
		"translit" : "presbeía",
		"kjv" : "ambassage, message"
	},
	"G1549" : {
		"def" : "a descendant, i.e. (specially) grandchild",
		"derivation" : "neuter of a derivative of a compound of G1537 (ἐκ) and G1096 (γίνομαι)",
		"lemma" : "ἔκγονον",
		"translit" : "ékgonon",
		"kjv" : "nephew"
	},
	"G5322" : {
		"lemma" : "φανός",
		"translit" : "phanós",
		"kjv" : "lantern",
		"def" : "a lightener, i.e. light; lantern",
		"derivation" : "from G5316 (φαίνω)"
	},
	"G1818" : {
		"translit" : "exapatáō",
		"lemma" : "ἐξαπατάω",
		"kjv" : "beguile, deceive",
		"def" : "to seduce wholly",
		"derivation" : "from G1537 (ἐκ) and G538 (ἀπατάω)"
	},
	"G2744" : {
		"derivation" : "from some (obsolete) base akin to that of (to boast) and G2172 (εὔχομαι)",
		"def" : "to vaunt (in a good or a bad sense)",
		"kjv" : "(make) boast, glory, joy, rejoice",
		"translit" : "kaucháomai",
		"lemma" : "καυχάομαι"
	},
	"G161" : {
		"translit" : "aichmalōsía",
		"lemma" : "αἰχμαλωσία",
		"kjv" : "captivity",
		"def" : "captivity",
		"derivation" : "from G164 (αἰχμάλωτος)"
	},
	"G2467" : {
		"lemma" : "ἴσημι",
		"translit" : "ísēmi",
		"kjv" : "know",
		"def" : "to know",
		"derivation" : "assumed by some as the base of certain irregular forms of G1492 (εἴδω)"
	},
	"G3965" : {
		"translit" : "patriá",
		"lemma" : "πατριά",
		"kjv" : "family, kindred, lineage",
		"def" : "paternal descent, i.e. (concretely) a group of families or a whole race (nation)",
		"derivation" : "as if feminine of a derivative of G3962 (πατήρ)"
	},
	"G5255" : {
		"kjv" : "obedient",
		"translit" : "hypḗkoos",
		"lemma" : "ὑπήκοος",
		"derivation" : "from G5219 (ὑπακούω)",
		"def" : "attentively listening, i.e. (by implication) submissive"
	},
	"G2290" : {
		"lemma" : "θάπτω",
		"translit" : "tháptō",
		"kjv" : "bury",
		"def" : "to celebrate funeral rites, i.e. inter",
		"derivation" : "a primary verb"
	},
	"G209" : {
		"derivation" : "adverb from a compound of G1 (Α) (as a negative particle) and a derivative of G2967 (κωλύω)",
		"def" : "in an unhindered manner, i.e. freely",
		"kjv" : "no man forbidding him",
		"lemma" : "ἀκωλύτως",
		"translit" : "akōlýtōs"
	},
	"G1875" : {
		"def" : "a particle of indefinite contemporaneousness; whenever, as soon as",
		"derivation" : "from G1909 (ἐπί) and G302 (ἄν)",
		"lemma" : "ἐπάν",
		"translit" : "epán",
		"kjv" : "when"
	},
	"G4194" : {
		"kjv" : "Pontius",
		"lemma" : "Πόντιος",
		"translit" : "Póntios",
		"derivation" : "of Latin origin",
		"def" : "apparently bridged; Pontius, a Roman"
	},
	"G1659" : {
		"def" : "to liberate, i.e. (figuratively) to exempt (from moral, ceremonial or mortal liability)",
		"derivation" : "from G1658 (ἐλεύθερος)",
		"translit" : "eleutheróō",
		"lemma" : "ἐλευθερόω",
		"kjv" : "deliver, make free"
	},
	"G2097" : {
		"derivation" : "from G2095 (εὖ) and G32 (ἄγγελος)",
		"def" : "to announce good news (\"evangelize\") especially the gospel",
		"kjv" : "declare, bring (declare, show) glad (good) tidings, preach (the gospel)",
		"translit" : "euangelízō",
		"lemma" : "εὐαγγελίζω"
	},
	"G751" : {
		"kjv" : "Archippus",
		"lemma" : "Ἄρχιππος",
		"translit" : "Árchippos",
		"derivation" : "from G746 (ἀρχή) and G2462 (ἵππος)",
		"def" : "horse-ruler; Archippus, a Christian"
	},
	"G2550" : {
		"derivation" : "from a compound of G2556 (κακός) and G2239 (ἦθος)",
		"def" : "bad character, i.e. (specially) mischievousness",
		"kjv" : "malignity",
		"lemma" : "κακοήθεια",
		"translit" : "kakoḗtheia"
	},
	"G3641" : {
		"lemma" : "ὀλίγος",
		"translit" : "olígos",
		"kjv" : "+ almost, brief(-ly), few, (a) little, + long, a season, short, small, a while",
		"def" : "puny (in extent, degree, number, duration or value); especially neuter (adverbially) somewhat",
		"derivation" : "of uncertain affinity"
	},
	"G159" : {
		"derivation" : "from the same as G154 (αἰτέω)",
		"def" : "causative, i.e. (concretely) a causer",
		"kjv" : "author",
		"translit" : "aítios",
		"lemma" : "αἴτιος"
	},
	"G981" : {
		"translit" : "bíōsis",
		"lemma" : "βίωσις",
		"kjv" : "manner of life",
		"def" : "living (properly, the act, by implication, the mode)",
		"derivation" : "from G980 (βιόω)"
	},
	"G1815" : {
		"lemma" : "ἐξανάστασις",
		"translit" : "exanástasis",
		"kjv" : "resurrection",
		"def" : "a rising from death",
		"derivation" : "from G1817 (ἐξανίστημι)"
	},
	"G2471" : {
		"translit" : "isótēs",
		"lemma" : "ἰσότης",
		"kjv" : "equal(-ity)",
		"def" : "likeness (in condition or proportion); by implication, equity"
	},
	"G590" : {
		"lemma" : "ἀπόδημος",
		"translit" : "apódēmos",
		"kjv" : "taking a far journey",
		"def" : "absent from one's own people, i.e. a foreign traveller",
		"derivation" : "from G575 (ἀπό) and G1218 (δῆμος)"
	},
	"G2952" : {
		"lemma" : "κυνάριον",
		"translit" : "kynárion",
		"kjv" : "dog",
		"def" : "a puppy",
		"derivation" : "neuter of a presumed derivative of G2965 (κύων)"
	},
	"G1435" : {
		"def" : "a present; specially, a sacrifice",
		"kjv" : "gift, offering",
		"translit" : "dōron",
		"lemma" : "δῶρον"
	},
	"G991" : {
		"kjv" : "behold, beware, lie, look (on, to), perceive, regard, see, sight, take heed",
		"translit" : "blépō",
		"lemma" : "βλέπω",
		"derivation" : "a primary verb",
		"def" : "to look at (literally or figuratively)"
	},
	"G5383" : {
		"def" : "to be fond of being first, i.e. ambitious of distinction",
		"derivation" : "from a compound of G5384 (φίλος) and G4413 (πρῶτος)",
		"translit" : "philoprōteúō",
		"lemma" : "φιλοπρωτεύω",
		"kjv" : "love to have the preeminence"
	},
	"G3422" : {
		"derivation" : "from G3421 (μνημονεύω)",
		"def" : "a reminder (memorandum), i.e. record",
		"kjv" : "memorial",
		"translit" : "mnēmósynon",
		"lemma" : "μνημόσυνον"
	},
	"G822" : {
		"derivation" : "from the same as G109 (ἀήρ)",
		"def" : "mist",
		"kjv" : "vapour",
		"lemma" : "ἀτμίς",
		"translit" : "atmís"
	},
	"G5536" : {
		"def" : "something useful or needed, i.e. wealth, price",
		"kjv" : "money, riches",
		"translit" : "chrēma",
		"lemma" : "χρῆμα"
	},
	"G4359" : {
		"def" : "to be indebted additionally",
		"derivation" : "from G4314 (πρός) and G3784 (ὀφείλω)",
		"translit" : "prosopheílō",
		"lemma" : "προσοφείλω",
		"kjv" : "over besides"
	},
	"G4145" : {
		"kjv" : "rich",
		"lemma" : "πλούσιος",
		"translit" : "ploúsios",
		"derivation" : "from G4149 (πλοῦτος)",
		"def" : "wealthy; figuratively, abounding with"
	},
	"G5326" : {
		"translit" : "phántasma",
		"lemma" : "φάντασμα",
		"kjv" : "spirit",
		"def" : "(properly concrete) a (mere) show (\"phantasm\"), i.e. spectre",
		"derivation" : "from G5324 (φαντάζω)"
	},
	"G5214" : {
		"translit" : "hymnéō",
		"lemma" : "ὑμνέω",
		"kjv" : "sing a hymn (praise unto)",
		"def" : "to hymn, i.e. sing a religious ode; by implication, to celebrate (God) in song",
		"derivation" : "from G5215 (ὕμνος)"
	},
	"G4562" : {
		"def" : "Saruch (i.e. Serug), a patriarch",
		"derivation" : "of Hebrew origin (H8286)",
		"lemma" : "Σαρούχ",
		"translit" : "Saroúch",
		"kjv" : "Saruch"
	},
	"G2967" : {
		"kjv" : "forbid, hinder, keep from, let, not suffer, withstand",
		"lemma" : "κωλύω",
		"translit" : "kōlýō",
		"derivation" : "from the base of G2849 (κολάζω)",
		"def" : "to estop, i.e. prevent (by word or act)"
	},
	"G1322" : {
		"def" : "instruction (the act or the matter)",
		"derivation" : "from G1321 (διδάσκω)",
		"lemma" : "διδαχή",
		"translit" : "didachḗ",
		"kjv" : "doctrine, hath been taught"
	},
	"G2960" : {
		"kjv" : "Lord's",
		"translit" : "kyriakós",
		"lemma" : "κυριακός",
		"derivation" : "from G2962 (κύριος)",
		"def" : "belonging to the Lord (Jehovah or Jesus)"
	},
	"G1468" : {
		"def" : "strong in a thing (masterful), i.e. (figuratively and reflexively) self-controlled (in appetite, etc.)",
		"derivation" : "from G1722 (ἐν) and G2904 (κράτος)",
		"translit" : "enkratḗs",
		"lemma" : "ἐγκρατής",
		"kjv" : "temperate"
	},
	"G754" : {
		"kjv" : "chief among the publicans",
		"lemma" : "ἀρχιτελώνης",
		"translit" : "architelṓnēs",
		"derivation" : "from G746 (ἀρχή) and G5057 (τελώνης)",
		"def" : "a principle tax-gatherer"
	},
	"G4275" : {
		"derivation" : "from G4253 (πρό) and G1492 (εἴδω)",
		"def" : "foresee",
		"kjv" : "foresee, saw before",
		"lemma" : "προείδω",
		"translit" : "proeídō"
	},
	"G164" : {
		"derivation" : "from (a spear) and a derivative of the same as G259 (ἅλωσις)",
		"def" : "properly, a prisoner of war, i.e. (genitive case) a captive",
		"kjv" : "captive",
		"lemma" : "αἰχμάλωτος",
		"translit" : "aichmálōtos"
	},
	"G778" : {
		"derivation" : "probably from the same as G4632 (σκεῦος)",
		"def" : "to elaborate, i.e. (figuratively) train (by implication, strive)",
		"kjv" : "exercise",
		"translit" : "askéō",
		"lemma" : "ἀσκέω"
	},
	"G2701" : {
		"kjv" : "run down",
		"lemma" : "κατατρέχω",
		"translit" : "katatréchō",
		"derivation" : "from G2596 (κατά) and G5143 (τρέχω)",
		"def" : "to run down, i.e. hasten from a tower"
	},
	"G3321" : {
		"kjv" : "midst of heaven",
		"lemma" : "μεσουράνημα",
		"translit" : "mesouránēma",
		"derivation" : "from a presumed compound of G3319 (μέσος) and G3772 (οὐρανός)",
		"def" : "mid-sky"
	},
	"G4819" : {
		"kjv" : "be(-fall), happen (unto)",
		"translit" : "symbaínō",
		"lemma" : "συμβαίνω",
		"derivation" : "from G4862 (σύν) and the base of G939 (βάσις)",
		"def" : "to walk (figuratively, transpire) together, i.e. concur (take place)"
	},
	"G4311" : {
		"lemma" : "προπέμπω",
		"translit" : "propémpō",
		"kjv" : "accompany, bring (forward) on journey (way), conduct forth",
		"def" : "to send forward, i.e. escort or aid in travel",
		"derivation" : "from G4253 (πρό) and G3992 (πέμπω)"
	},
	"G3999" : {
		"kjv" : "five times",
		"translit" : "pentákis",
		"lemma" : "πεντάκις",
		"derivation" : "multiplicative adverb from G4002 (πέντε)",
		"def" : "five times"
	},
	"G3981" : {
		"lemma" : "πειθός",
		"translit" : "peithós",
		"kjv" : "enticing",
		"def" : "persuasive",
		"derivation" : "from G3982 (πείθω)"
	},
	"G3673" : {
		"kjv" : "of the same craft",
		"translit" : "homótechnos",
		"lemma" : "ὁμότεχνος",
		"derivation" : "from the base of G3674 (ὁμοῦ) and G5078 (τέχνη)",
		"def" : "a fellow-artificer"
	},
	"G1305" : {
		"lemma" : "διατροφή",
		"translit" : "diatrophḗ",
		"kjv" : "food",
		"def" : "nourishment",
		"derivation" : "from a compound of G1223 (διά) and G5142 (τρέφω)"
	},
	"G824" : {
		"translit" : "átopos",
		"lemma" : "ἄτοπος",
		"kjv" : "amiss, harm, unreasonable",
		"def" : "out of place, i.e. (figuratively) improper, injurious, wicked",
		"derivation" : "from G1 (Α) (as a negative particle) and G5117 (τόπος)"
	},
	"G4108" : {
		"kjv" : "--deceiver, seducing",
		"translit" : "plános",
		"lemma" : "πλάνος",
		"derivation" : "of uncertain affinity",
		"def" : "roving (as a tramp), i.e. (by implication) an impostor or misleader; "
	},
	"G2138" : {
		"kjv" : "easy to be intreated",
		"translit" : "eupeithḗs",
		"lemma" : "εὐπειθής",
		"derivation" : "from G2095 (εὖ) and G3982 (πείθω)",
		"def" : "good for persuasion, i.e. (intransitively) complaint"
	},
	"G5575" : {
		"derivation" : "from G5571 (ψευδής) and a kindred form of G3144 (μάρτυς)",
		"def" : "a spurious witness, i.e. bearer of untrue testimony",
		"kjv" : "false witness",
		"translit" : "pseudomártyr",
		"lemma" : "ψευδομάρτυρ"
	},
	"G994" : {
		"derivation" : "apparently a prolonged form of a primary verb",
		"def" : "to halloo, i.e. shout (for help or in a tumultuous way)",
		"kjv" : "cry",
		"translit" : "boáō",
		"lemma" : "βοάω"
	},
	"G1771" : {
		"derivation" : "from a compound of G1722 (ἐν) and G3563 (νοῦς)",
		"def" : "thoughtfulness, i.e. moral understanding",
		"kjv" : "intent, mind",
		"translit" : "énnoia",
		"lemma" : "ἔννοια"
	},
	"G4208" : {
		"translit" : "porrhōtérō",
		"lemma" : "ποῤῥωτέρω",
		"kjv" : "farther",
		"def" : "further, i.e. a greater distance",
		"derivation" : "adverb comparative of G4206 (πόῤῥω)"
	},
	"G399" : {
		"translit" : "anaphérō",
		"lemma" : "ἀναφέρω",
		"kjv" : "bear, bring (carry, lead) up, offer (up)",
		"def" : "to take up (literally or figuratively)",
		"derivation" : "from G303 (ἀνά) and G5342 (φέρω)"
	},
	"G277" : {
		"def" : "immovable",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G3334 (μετακινέω)",
		"translit" : "ametakínētos",
		"lemma" : "ἀμετακίνητος",
		"kjv" : "unmovable"
	},
	"G2643" : {
		"derivation" : "from G2644 (καταλλάσσω)",
		"def" : "exchange (figuratively, adjustment), i.e. restoration to (the divine) favor",
		"kjv" : "atonement, reconciliation(-ing)",
		"lemma" : "καταλλαγή",
		"translit" : "katallagḗ"
	},
	"G4625" : {
		"def" : "a trap-stick (bent sapling), i.e. snare (figuratively, cause of displeasure or sin)",
		"derivation" : "(\"scandal\"); probably from a derivative of G2578 (κάμπτω)",
		"lemma" : "σκάνδαλον",
		"translit" : "skándalon",
		"kjv" : "occasion to fall (of stumbling), offence, thing that offends, stumblingblock"
	},
	"G5394" : {
		"kjv" : "set on fire",
		"lemma" : "φλογίζω",
		"translit" : "phlogízō",
		"derivation" : "from G5395 (φλόξ)",
		"def" : "to cause a blaze, i.e. ignite (figuratively, to inflame with passion)"
	},
	"G2580" : {
		"kjv" : "Cana",
		"lemma" : "Κανᾶ",
		"translit" : "Kanâ",
		"derivation" : "of Hebrew origin (compare H7071)",
		"def" : "Cana, a place in Palestine"
	},
	"G2667" : {
		"translit" : "katapíptō",
		"lemma" : "καταπίπτω",
		"kjv" : "fall (down)",
		"def" : "to fall down",
		"derivation" : "from G2596 (κατά) and G4098 (πίπτω)"
	},
	"G276" : {
		"def" : "unchangeable, or (neuter as abstract) unchangeability",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G3346 (μετατίθημι)",
		"lemma" : "ἀμετάθετος",
		"translit" : "ametáthetos",
		"kjv" : "immutable(-ility)"
	},
	"G3174" : {
		"derivation" : "from G3173 (μέγας)",
		"def" : "magnitude (figuratively)",
		"kjv" : "greatness",
		"lemma" : "μέγεθος",
		"translit" : "mégethos"
	},
	"G2563" : {
		"kjv" : "pen, reed",
		"lemma" : "κάλαμος",
		"translit" : "kálamos",
		"derivation" : "of uncertain affinity",
		"def" : "a reed (the plant or its stem, or that of a similar plant); by implication, a pen"
	},
	"G2000" : {
		"derivation" : "from a compound of G1909 (ἐπί) and (to trip)",
		"def" : "figuratively, insecure",
		"kjv" : "dangerous",
		"translit" : "episphalḗs",
		"lemma" : "ἐπισφαλής"
	},
	"G2253" : {
		"lemma" : "ἡμιθανής",
		"translit" : "hēmithanḗs",
		"kjv" : "half dead",
		"def" : "half dead, i.e. entirely exhausted",
		"derivation" : "from a presumed compound of the base of G2255 (ἥμισυ) and G2348 (θνήσκω)"
	},
	"G3933" : {
		"derivation" : "of unknown origin",
		"def" : "a maiden; by implication, an unmarried daughter",
		"kjv" : "virgin",
		"translit" : "parthénos",
		"lemma" : "παρθένος"
	},
	"G1250" : {
		"kjv" : "two hundred",
		"translit" : "diakósioi",
		"lemma" : "διακόσιοι",
		"derivation" : "from G1364 (δίς) and G1540 (ἑκατόν)",
		"def" : "two hundred"
	},
	"G3385" : {
		"kjv" : "not (the particle usually not expressed, except by the form of the question)",
		"lemma" : "μήτι",
		"translit" : "mḗti",
		"derivation" : "from G3361 (μή) and the neuter of G5100 (τὶς)",
		"def" : "whether at all"
	},
	"G5450" : {
		"derivation" : "from G5448 (φυσιόω)",
		"def" : "inflation, i.e. (figuratively) haughtiness",
		"kjv" : "swelling",
		"lemma" : "φυσίωσις",
		"translit" : "physíōsis"
	},
	"G686" : {
		"derivation" : "probably from G142 (αἴρω) (through the idea of drawing a conclusion)",
		"def" : "a particle denoting an inference more or less decisive (as follows)",
		"kjv" : "haply, (what) manner (of man), no doubt, perhaps, so be, then, therefore, truly, wherefore",
		"translit" : "ára",
		"lemma" : "ἄρα"
	},
	"G141" : {
		"kjv" : "heretic (the Greek word itself)",
		"lemma" : "αἱρετικός",
		"translit" : "hairetikós",
		"derivation" : "from the same as G140 (αἱρετίζω)",
		"def" : "a schismatic"
	},
	"G4762" : {
		"derivation" : "strengthened from the base of G5157 (τροπή)",
		"def" : "to twist, i.e. turn quite around or reverse (literally or figuratively)",
		"kjv" : "convert, turn (again, back again, self, self about)",
		"lemma" : "στρέφω",
		"translit" : "stréphō"
	},
	"G5501" : {
		"derivation" : "irregular comparative of G2556 (κακός)",
		"def" : "from an obsolete equivalent (of uncertain derivation); more evil or aggravated (physically, mentally or morally)",
		"kjv" : "sorer, worse",
		"translit" : "cheírōn",
		"lemma" : "χείρων"
	},
	"G5306" : {
		"def" : "later",
		"derivation" : "comparative from G5259 (ὑπό) (in the sense of behind)",
		"translit" : "hýsteros",
		"lemma" : "ὕστερος",
		"kjv" : "latter"
	},
	"G1226" : {
		"kjv" : "affirm constantly",
		"translit" : "diabebaióomai",
		"lemma" : "διαβεβαιόομαι",
		"derivation" : "middle voice of a compound of G1223 (διά) and G950 (βεβαιόω)",
		"def" : "to confirm thoroughly (by words), i.e. asseverate"
	},
	"G827" : {
		"derivation" : "of uncertain derivation",
		"def" : "a ray of light, i.e. (by implication) radiance, dawn",
		"kjv" : "break of day",
		"translit" : "augḗ",
		"lemma" : "αὐγή"
	},
	"G655" : {
		"translit" : "apostygéō",
		"lemma" : "ἀποστυγέω",
		"kjv" : "abhor",
		"def" : "to detest utterly",
		"derivation" : "from G575 (ἀπό) and the base of G4767 (στυγνητός)"
	},
	"G5061" : {
		"derivation" : "of uncertain derivation",
		"def" : "Tertullus, a Roman",
		"kjv" : "Tertullus",
		"translit" : "Tértyllos",
		"lemma" : "Τέρτυλλος"
	},
	"G3173" : {
		"def" : "big (literally or figuratively, in a very wide application)",
		"derivation" : "(including the prolonged forms, feminine , plural , etc.; compare also G3176 (μέγιστος), G3187 (μείζων))",
		"lemma" : "μέγας",
		"translit" : "mégas",
		"kjv" : "(+ fear) exceedingly, great(-est), high, large, loud, mighty, + (be) sore (afraid), strong, X to years"
	},
	"G3392" : {
		"def" : "to sully or taint, i.e. contaminate (ceremonially or morally)",
		"derivation" : "perhaps a primary verb",
		"translit" : "miaínō",
		"lemma" : "μιαίνω",
		"kjv" : "defile"
	},
	"G470" : {
		"lemma" : "ἀνταποκρίνομαι",
		"translit" : "antapokrínomai",
		"kjv" : "answer again, reply against",
		"def" : "to contradict or dispute",
		"derivation" : "from G473 (ἀντί) and G611 (ἀποκρίνομαι)"
	},
	"G2554" : {
		"kjv" : "do(ing) evil",
		"translit" : "kakopoiéō",
		"lemma" : "κακοποιέω",
		"derivation" : "from G2555 (κακοποιός)",
		"def" : "to be a bad-doer, i.e. (objectively) to injure, or (genitive) to sin"
	},
	"G3801" : {
		"translit" : "ho ṑn kaí ho ēn kaí ho erchómenos",
		"lemma" : "ὁ ὢν καί ὁ ἦν καί ὁ ἐρχόμενος",
		"kjv" : "which art (is, was), and (which) wast (is, was), and art (is) to come (shalt be)",
		"def" : "the one being and the one that was and the one coming, i.e. the Eternal, as a divine epithet of Christ",
		"derivation" : "a phrase combining G3588 (ὁ) with the present participle and imperfect of G1510 (εἰμί) and the present participle of G2064 (ἔρχομαι) by means of G2532 (καί)"
	},
	"G5562" : {
		"kjv" : "come, contain, go, have place, (can, be room to) receive",
		"translit" : "chōréō",
		"lemma" : "χωρέω",
		"derivation" : "from G5561 (χώρα)",
		"def" : "to be in (give) space, i.e. (intransitively) to pass, enter, or (transitively) to hold, admit (literally or figuratively)"
	},
	"G1376" : {
		"def" : "a persecutor",
		"derivation" : "from G1377 (διώκω)",
		"translit" : "diṓktēs",
		"lemma" : "διώκτης",
		"kjv" : "persecutor"
	},
	"G1756" : {
		"kjv" : "effectual, powerful",
		"translit" : "energḗs",
		"lemma" : "ἐνεργής",
		"derivation" : "from G1722 (ἐν) and G2041 (ἔργον)",
		"def" : "active, operative"
	},
	"G1621" : {
		"derivation" : "from G1537 (ἐκ) and tinasso (to swing)",
		"def" : "to shake violently",
		"kjv" : "shake (off)",
		"translit" : "ektinássō",
		"lemma" : "ἐκτινάσσω"
	},
	"G4772" : {
		"def" : "relationship, i.e. (concretely) relatives",
		"derivation" : "from G4773 (συγγενής)",
		"lemma" : "συγγένεια",
		"translit" : "syngéneia",
		"kjv" : "kindred"
	},
	"G3457" : {
		"def" : "belonging to a mill",
		"derivation" : "from G3458 (μύλος)",
		"translit" : "mylikós",
		"lemma" : "μυλικός",
		"kjv" : "mill(-stone)"
	},
	"G1631" : {
		"kjv" : "put forth",
		"translit" : "ekphýō",
		"lemma" : "ἐκφύω",
		"derivation" : "from G1537 (ἐκ) and G5453 (φύω)",
		"def" : "to sprout up"
	},
	"G1982" : {
		"kjv" : "overshadow",
		"lemma" : "ἐπισκιάζω",
		"translit" : "episkiázō",
		"derivation" : "from G1909 (ἐπί) and a derivative of G4639 (σκιά)",
		"def" : "to cast a shade upon, i.e. (by analogy) to envelop in a haze of brilliancy; figuratively, to invest with preternatural influence"
	},
	"G1387" : {
		"translit" : "dolióō",
		"lemma" : "δολιόω",
		"kjv" : "use deceit",
		"def" : "to be guileful",
		"derivation" : "from G1386 (δόλιος)"
	},
	"G2722" : {
		"derivation" : "from G2596 (κατά) and G2192 (ἔχω)",
		"def" : "to hold down (fast), in various applications (literally or figuratively)",
		"kjv" : "have, hold (fast), keep (in memory), let, X make toward, possess, retain, seize on, stay, take, withhold",
		"translit" : "katéchō",
		"lemma" : "κατέχω"
	},
	"G4965" : {
		"kjv" : "Sychar",
		"translit" : "Sychár",
		"lemma" : "Συχάρ",
		"derivation" : "of Hebrew origin (H7941)",
		"def" : "Sychar (i.e. Shekar), a place in Palestine"
	},
	"G5361" : {
		"kjv" : "love as brethren",
		"lemma" : "φιλάδελφος",
		"translit" : "philádelphos",
		"derivation" : "from G5384 (φίλος) and G80 (ἀδελφός)",
		"def" : "fond of brethren, i.e. fraternal"
	},
	"G1198" : {
		"translit" : "désmios",
		"lemma" : "δέσμιος",
		"kjv" : "in bonds, prisoner",
		"def" : "a captive (as bound)",
		"derivation" : "from G1199 (δεσμόν)"
	},
	"G1899" : {
		"derivation" : "from G1909 (ἐπί) and G1534 (εἶτα)",
		"def" : "thereafter",
		"kjv" : "after that(-ward), then",
		"translit" : "épeita",
		"lemma" : "ἔπειτα"
	},
	"G4599" : {
		"def" : "probably akin to the base of G2476 (ἵστημι)); to strengthen, i.e. (figuratively) confirm (in spiritual knowledge and power)",
		"derivation" : "from (bodily vigor",
		"lemma" : "σθενόω",
		"translit" : "sthenóō",
		"kjv" : "strengthen"
	},
	"G2500" : {
		"kjv" : "Joses",
		"lemma" : "Ἰωσῆς",
		"translit" : "Iōsēs",
		"derivation" : "perhaps for G2501 (Ἰωσήφ)",
		"def" : "Joses, the name of two Israelites"
	},
	"G4387" : {
		"derivation" : "comparative of G4253 (πρό)",
		"def" : "prior or previous",
		"kjv" : "former",
		"lemma" : "πρότερος",
		"translit" : "próteros"
	},
	"G1203" : {
		"lemma" : "δεσπότης",
		"translit" : "despótēs",
		"kjv" : "Lord, master",
		"def" : "an absolute ruler (\"despot\")",
		"derivation" : "perhaps from G1210 (δέω) and (a husband)"
	},
	"G438" : {
		"kjv" : "flower",
		"lemma" : "ἄνθος",
		"translit" : "ánthos",
		"derivation" : "a primary word",
		"def" : "a blossom"
	},
	"G5143" : {
		"lemma" : "τρέχω",
		"translit" : "tréchō",
		"kjv" : "have course, run",
		"def" : "compare G2359 (θρίξ)); which uses (the base of G1408 (δρόμος)) as alternate in certain tenses; to run or walk hastily (literally or figuratively)",
		"derivation" : "apparently a primary verb (properly, "
	},
	"G5153" : {
		"kjv" : "three thousand",
		"translit" : "trischílioi",
		"lemma" : "τρισχίλιοι",
		"derivation" : "from G5151 (τρίς) and G5507 (χίλιοι)",
		"def" : "three times a thousand"
	},
	"G797" : {
		"def" : "to flash as lightning",
		"derivation" : "probably from G792 (ἀστήρ)",
		"lemma" : "ἀστράπτω",
		"translit" : "astráptō",
		"kjv" : "lighten, shine"
	},
	"G1364" : {
		"lemma" : "δίς",
		"translit" : "dís",
		"kjv" : "again, twice",
		"def" : "twice",
		"derivation" : "adverb from G1417 (δύο)"
	},
	"G5087" : {
		"derivation" : "a prolonged form of a primary (which is used only as alternate in certain tenses)",
		"def" : "to place (in the widest application, literally and figuratively; properly, in a passive or horizontal posture, and thus different from G2476 (ἵστημι), which properly denotes an upright and active position, while G2749 (κεῖμαι) is properly reflexive and utterly prostrate)",
		"kjv" : "+ advise, appoint, bow, commit, conceive, give, X kneel down, lay (aside, down, up), make, ordain, purpose, put, set (forth), settle, sink down",
		"translit" : "títhēmi",
		"lemma" : "τίθημι"
	},
	"G570" : {
		"kjv" : "unbelief",
		"translit" : "apistía",
		"lemma" : "ἀπιστία",
		"derivation" : "from G571 (ἄπιστος)",
		"def" : "faithlessness, i.e. (negatively) disbelief (lack of Christian faith), or (positively) unfaithfulness (disobedience)"
	},
	"G3697" : {
		"derivation" : "from G3739 (ὅς) and G4169 (ποῖος)",
		"def" : "of what kind that, i.e. how (as) great (excellent) (specially, as an indefinite correlative to the definite antecedent G5108 (τοιοῦτος) of quality)",
		"kjv" : "what manner (sort) of, such as whatsoever",
		"lemma" : "ὁποῖος",
		"translit" : "hopoîos"
	},
	"G4737" : {
		"translit" : "stephanóō",
		"lemma" : "στεφανόω",
		"kjv" : "crown",
		"def" : "to adorn with an honorary wreath (literally or figuratively)",
		"derivation" : "from G4735 (στέφανος)"
	},
	"G66" : {
		"def" : "wild (as pertaining to the country), literally (natural) or figuratively (fierce)",
		"derivation" : "from G68 (ἀγρός)",
		"lemma" : "ἄγριος",
		"translit" : "ágrios",
		"kjv" : "wild, raging"
	},
	"G1912" : {
		"def" : "to be heavy upon, i.e. (pecuniarily) to be expensive to; figuratively, to be severe towards",
		"derivation" : "from G1909 (ἐπί) and G916 (βαρέω)",
		"translit" : "epibaréō",
		"lemma" : "ἐπιβαρέω",
		"kjv" : "be chargeable to, overcharge"
	},
	"G2242" : {
		"derivation" : "of Hebrew origin (H5941)",
		"def" : "Heli (i.e. Eli), an Israelite",
		"kjv" : "Heli",
		"lemma" : "Ἡλί",
		"translit" : "Hēlí"
	},
	"G2047" : {
		"lemma" : "ἐρημία",
		"translit" : "erēmía",
		"kjv" : "desert, wilderness",
		"def" : "solitude (concretely)",
		"derivation" : "from G2048 (ἔρημος)"
	},
	"G4469" : {
		"kjv" : "Raca",
		"translit" : "rhaká",
		"lemma" : "ῥακά",
		"derivation" : "of Chaldee origin (compare H7386)",
		"def" : "O empty one, i.e. thou worthless (as a term of utter vilification)"
	},
	"G3327" : {
		"derivation" : "from G3326 (μετά) and the base of G939 (βάσις)",
		"def" : "to change place",
		"kjv" : "depart, go, pass, remove",
		"translit" : "metabaínō",
		"lemma" : "μεταβαίνω"
	},
	"G871" : {
		"derivation" : "from G575 (ἀπό) and G3666 (ὁμοιόω)",
		"def" : "to assimilate closely",
		"kjv" : "make like",
		"translit" : "aphomoióō",
		"lemma" : "ἀφομοιόω"
	},
	"G4184" : {
		"def" : "extremely compassionate",
		"derivation" : "from G4183 (πολύς) and G4698 (σπλάγχνον) (figuratively)",
		"lemma" : "πολύσπλαγχνος",
		"translit" : "polýsplanchnos",
		"kjv" : "very pitiful"
	},
	"G439" : {
		"derivation" : "from G440 (ἄνθραξ)",
		"def" : "a bed of burning coals",
		"kjv" : "fire of coals",
		"translit" : "anthrakiá",
		"lemma" : "ἀνθρακιά"
	},
	"G1197" : {
		"def" : "a bundle",
		"derivation" : "from G1196 (δεσμέω)",
		"lemma" : "δέσμη",
		"translit" : "désmē",
		"kjv" : "bundle"
	},
	"G1787" : {
		"translit" : "entós",
		"lemma" : "ἐντός",
		"kjv" : "within",
		"def" : "inside (adverb or noun)",
		"derivation" : "from G1722 (ἐν)"
	},
	"G1494" : {
		"lemma" : "εἰδωλόθυτον",
		"translit" : "eidōlóthyton",
		"kjv" : "(meat, thing that is) offered (in sacrifice, sacrificed) to (unto) idols",
		"def" : "an image-sacrifice, i.e. part of an idolatrous offering",
		"derivation" : "neuter of a compound of G1497 (εἴδωλον) and a presumed derivative of G2380 (θύω)"
	},
	"G624" : {
		"derivation" : "from the pagan deity (i.e. the sun",
		"def" : "from G622 (ἀπόλλυμι)); Apollonia, a place in Macedonia",
		"kjv" : "Apollonia",
		"lemma" : "Ἀπολλωνία",
		"translit" : "Apollōnía"
	},
	"G890" : {
		"kjv" : "unprofitable",
		"translit" : "áchrēstos",
		"lemma" : "ἄχρηστος",
		"derivation" : "from G1 (Α) (as a negative particle) and G5543 (χρηστός)",
		"def" : "inefficient, i.e. (by implication) detrimental"
	},
	"G4810" : {
		"lemma" : "σῦκον",
		"translit" : "sŷkon",
		"kjv" : "fig",
		"def" : "a fig",
		"derivation" : "apparently a primary word"
	},
	"G3733" : {
		"def" : "a bird (as rising in the air), i.e. (specially), a hen (or female domestic fowl)",
		"derivation" : "probably from a prolonged form of the base of G3735 (ὄρος)",
		"lemma" : "ὄρνις",
		"translit" : "órnis",
		"kjv" : "hen"
	},
	"G338" : {
		"kjv" : "blameless, guiltless",
		"lemma" : "ἀναίτιος",
		"translit" : "anaítios",
		"derivation" : "from G1 (Α) (as a negative particle) and G159 (αἴτιος) (in the sense of G156 (αἰτία))",
		"def" : "innocent"
	},
	"G148" : {
		"derivation" : "from G150 (αἰσχρός) and G3056 (λόγος)",
		"def" : "vile conversation",
		"kjv" : "filthy communication",
		"lemma" : "αἰσχρολογία",
		"translit" : "aischrología"
	},
	"G3978" : {
		"kjv" : "go afoot",
		"lemma" : "πεζεύω",
		"translit" : "pezeúō",
		"derivation" : "from the same as G3979 (πεζῇ)",
		"def" : "to foot a journey, i.e. travel by land"
	},
	"G4536" : {
		"lemma" : "σάλπιγξ",
		"translit" : "sálpinx",
		"kjv" : "trump(-et)",
		"def" : "a trumpet",
		"derivation" : "perhaps from G4535 (σάλος) (through the idea of quavering or reverberation)"
	},
	"G2850" : {
		"translit" : "kolakeía",
		"lemma" : "κολακεία",
		"kjv" : "X flattering",
		"def" : "flattery",
		"derivation" : "from a derivative of (a fawner)"
	},
	"G4192" : {
		"translit" : "pónos",
		"lemma" : "πόνος",
		"kjv" : "pain",
		"def" : "toil, i.e. (by implication) anguish",
		"derivation" : "from the base of G3993 (πένης)"
	},
	"G532" : {
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G3903 (παρασκευάζω)",
		"def" : "unready",
		"kjv" : "unprepared",
		"lemma" : "ἀπαρασκεύαστος",
		"translit" : "aparaskeúastos"
	},
	"G5447" : {
		"translit" : "physikōs",
		"lemma" : "φυσικῶς",
		"kjv" : "naturally",
		"def" : "\"physically\", i.e. (by implication) instinctively",
		"derivation" : "adverb from G5446 (φυσικός)"
	},
	"G3175" : {
		"translit" : "megistânes",
		"lemma" : "μεγιστᾶνες",
		"kjv" : "great men, lords",
		"def" : "grandees",
		"derivation" : "plural from G3176 (μέγιστος)"
	},
	"G3418" : {
		"lemma" : "μνῆμα",
		"translit" : "mnēma",
		"kjv" : "grave, sepulchre, tomb",
		"def" : "a memorial, i.e. sepulchral monument (burial-place)",
		"derivation" : "from G3415 (μνάομαι)"
	},
	"G2414" : {
		"derivation" : "of Hebrew origin (H3389)",
		"def" : "Hierosolyma (i.e. Jerushalaim), the capitol of Palestine",
		"kjv" : "Jerusalem",
		"translit" : "Hierosólyma",
		"lemma" : "Ἱεροσόλυμα"
	},
	"G3883" : {
		"derivation" : "from a compound of G3844 (παρά) and G236 (ἀλλάσσω)",
		"def" : "transmutation (of phase or orbit), i.e. (figuratively) fickleness",
		"kjv" : "variableness",
		"translit" : "parallagḗ",
		"lemma" : "παραλλαγή"
	},
	"G253" : {
		"def" : "more without grief",
		"derivation" : "comparative of a compound of G1 (Α) (as a negative particle) and G3077 (λύπη)",
		"lemma" : "ἀλυπότερος",
		"translit" : "alypóteros",
		"kjv" : "less sorrowful"
	},
	"G4936" : {
		"def" : "to rush together (hastily assemble) or headlong (figuratively)",
		"derivation" : "from G4862 (σύν) and G5143 (τρέχω) (including its alternate)",
		"lemma" : "συντρέχω",
		"translit" : "syntréchō",
		"kjv" : "run (together, with)"
	},
	"G3431" : {
		"lemma" : "μοιχεύω",
		"translit" : "moicheúō",
		"kjv" : "commit adultery",
		"def" : "to commit adultery",
		"derivation" : "from G3432 (μοιχός)"
	},
	"G4106" : {
		"derivation" : "feminine of G4108 (πλάνος) (as abstractly)",
		"def" : "objectively, fraudulence; subjectively, a straying from orthodoxy or piety",
		"kjv" : "deceit, to deceive, delusion, error",
		"lemma" : "πλάνη",
		"translit" : "plánē"
	},
	"G4642" : {
		"def" : "dry, i.e. hard or tough (figuratively, harsh, severe)",
		"derivation" : "from the base of G4628 (σκέλος)",
		"lemma" : "σκληρός",
		"translit" : "sklērós",
		"kjv" : "fierce, hard"
	},
	"G1444" : {
		"lemma" : "Ἑβραϊκός",
		"translit" : "Hebraïkós",
		"kjv" : "Hebrew",
		"def" : "Hebraic or the Jewish language",
		"derivation" : "from G1443 (Ἐβέρ)"
	},
	"G3141" : {
		"derivation" : "from G3144 (μάρτυς)",
		"def" : "evidence given (judicially or genitive case)",
		"kjv" : "record, report, testimony, witness",
		"lemma" : "μαρτυρία",
		"translit" : "martyría"
	},
	"G2303" : {
		"derivation" : "probably neuter of G2304 (θεῖος) (in its original sense of flashing)",
		"def" : "sulphur",
		"kjv" : "brimstone",
		"lemma" : "θεῖον",
		"translit" : "theîon"
	},
	"G2938" : {
		"def" : "an original formation (concretely), i.e. product (created thing)",
		"derivation" : "from G2936 (κτίζω)",
		"translit" : "ktísma",
		"lemma" : "κτίσμα",
		"kjv" : "creature"
	},
	"G2749" : {
		"def" : "to lie outstretched (literally or figuratively)",
		"derivation" : "middle voice of a primary verb",
		"translit" : "keîmai",
		"lemma" : "κεῖμαι",
		"kjv" : "be (appointed, laid up, made, set), lay, lie"
	},
	"G4358" : {
		"kjv" : "draw to the shore",
		"translit" : "prosormízō",
		"lemma" : "προσορμίζω",
		"derivation" : "from G4314 (πρός) and a derivative of the same as G3730 (ὁρμή) (meaning to tie (anchor) or lull)",
		"def" : "to moor to, i.e. (by implication) land at"
	},
	"G826" : {
		"kjv" : "shine",
		"translit" : "augázō",
		"lemma" : "αὐγάζω",
		"derivation" : "from G827 (αὐγή)",
		"def" : "to beam forth (figuratively)"
	},
	"G2431" : {
		"lemma" : "ἱλαρός",
		"translit" : "hilarós",
		"kjv" : "cheerful",
		"def" : "propitious or merry (\"hilarious\"), i.e. prompt or willing",
		"derivation" : "from the same as G2436 (ἵλεως)"
	},
	"G5620" : {
		"translit" : "hṓste",
		"lemma" : "ὥστε",
		"kjv" : "(insomuch) as, so that (then), (insomuch) that, therefore, to, wherefore",
		"def" : "so too, i.e. thus therefore (in various relations of consecution, as follow)",
		"derivation" : "from G5613 (ὡς) and G5037 (τέ)"
	},
	"G17" : {
		"lemma" : "ἀγαθοποιός",
		"translit" : "agathopoiós",
		"kjv" : "them that do well",
		"def" : "a well-doer, i.e. virtuous",
		"derivation" : "from G18 (ἀγαθός) and G4160 (ποιέω)"
	},
	"G4700" : {
		"derivation" : "of uncertain derivation",
		"def" : "ashes",
		"kjv" : "ashes",
		"translit" : "spodós",
		"lemma" : "σποδός"
	},
	"G1722" : {
		"def" : "\"in,\" at, (up-)on, by, etc.",
		"derivation" : "a primary preposition denoting (fixed) position (in place, time or state), and (by implication) instrumentality (medially or constructively), i.e. a relation of rest (intermediate between G1519 (εἰς) and G1537 (ἐκ))",
		"translit" : "en",
		"lemma" : "ἐν",
		"kjv" : "about, after, against, + almost, X altogether, among, X as, at, before, between, (here-)by (+ all means), for (… sake of), + give self wholly to, (here-)in(-to, -wardly), X mightily, (because) of, (up-)on, (open-)ly, X outwardly, one, X quickly, X shortly, (speedi-)ly, X that, X there(-in, -on), through(-out), (un-)to(-ward), under, when, where(-with), while, with(-in)"
	},
	"G2070" : {
		"def" : "we are",
		"derivation" : "first person plural indicative of G1510 (εἰμί)",
		"lemma" : "ἐσμέν",
		"translit" : "esmén",
		"kjv" : "are, be, have our being, X have hope, + (the gospel) was (preached unto) us"
	},
	"G3808" : {
		"derivation" : "neuter of a presumed derivative of G3816 (παῖς)",
		"def" : "a little boy",
		"kjv" : "child, lad",
		"translit" : "paidárion",
		"lemma" : "παιδάριον"
	},
	"G4162" : {
		"kjv" : "deed",
		"lemma" : "ποίησις",
		"translit" : "poíēsis",
		"derivation" : "from G4160 (ποιέω)",
		"def" : "action, i.e. performance (of the law)"
	},
	"G4333" : {
		"kjv" : "gain",
		"translit" : "prosergázomai",
		"lemma" : "προσεργάζομαι",
		"derivation" : "from G4314 (πρός) and G2038 (ἐργάζομαι)",
		"def" : "to work additionally, i.e. (by implication) acquire besides"
	},
	"G2189" : {
		"def" : "hostility; by implication, a reason for opposition",
		"derivation" : "feminine of G2190 (ἐχθρός)",
		"lemma" : "ἔχθρα",
		"translit" : "échthra",
		"kjv" : "enmity, hatred"
	},
	"G3766" : {
		"def" : "is it not therefore that, i.e. (affirmatively) hence or so",
		"derivation" : "from G3756 (οὐ) and G3767 (οὖν)",
		"lemma" : "οὐκοῦν",
		"translit" : "oukoûn",
		"kjv" : "then"
	},
	"G2621" : {
		"kjv" : "keep, lie, sit at meat (down)",
		"lemma" : "κατάκειμαι",
		"translit" : "katákeimai",
		"derivation" : "from G2596 (κατά) and G2749 (κεῖμαι)",
		"def" : "to lie down, i.e. (by implication) be sick; specially, to recline at a meal"
	},
	"G175" : {
		"kjv" : "without fruit, unfruitful",
		"lemma" : "ἄκαρπος",
		"translit" : "ákarpos",
		"derivation" : "from G1 (Α) (as a negative particle) and G2590 (καρπός)",
		"def" : "barren (literally or figuratively)"
	},
	"G4243" : {
		"derivation" : "from the base of G4245 (πρεσβύτερος)",
		"def" : "to be a senior, i.e. (by implication) act as a representative (figuratively, preacher)",
		"kjv" : "be an ambassador",
		"translit" : "presbeúō",
		"lemma" : "πρεσβεύω"
	},
	"G3170" : {
		"kjv" : "enlarge, magnify, shew great",
		"translit" : "megalýnō",
		"lemma" : "μεγαλύνω",
		"derivation" : "from G3173 (μέγας)",
		"def" : "to make (or declare) great, i.e. increase or (figuratively) extol"
	},
	"G2506" : {
		"derivation" : "from G2507 (καθαιρέω)",
		"def" : "demolition; figuratively, extinction",
		"kjv" : "destruction, pulling down",
		"translit" : "kathaíresis",
		"lemma" : "καθαίρεσις"
	},
	"G5119" : {
		"derivation" : "from (the neuter of) G3588 (ὁ) and G3753 (ὅτε)",
		"def" : "the when, i.e. at the time that (of the past or future, also in consecution)",
		"kjv" : "that time, then",
		"translit" : "tóte",
		"lemma" : "τότε"
	},
	"G1497" : {
		"kjv" : "idol",
		"lemma" : "εἴδωλον",
		"translit" : "eídōlon",
		"derivation" : "from G1491 (εἶδος)",
		"def" : "an image (i.e. for worship); by implication, a heathen god, or (plural) the worship of such"
	},
	"G3804" : {
		"translit" : "páthēma",
		"lemma" : "πάθημα",
		"kjv" : "affection, affliction, motion, suffering",
		"def" : "something undergone, i.e. hardship or pain; subjectively, an emotion or influence",
		"derivation" : "from a presumed derivative of G3806 (πάθος)"
	},
	"G5009" : {
		"derivation" : "neuter contraction of a presumed derivative of (a dispenser or distributor",
		"def" : "akin to , to cut); a dispensary or magazine, i.e. a chamber on the ground-floor or interior of an Oriental house (generally used for storage or privacy, a spot for retirement)",
		"kjv" : "secret chamber, closet, storehouse",
		"lemma" : "ταμεῖον",
		"translit" : "tameîon"
	},
	"G5296" : {
		"kjv" : "form, pattern",
		"translit" : "hypotýpōsis",
		"lemma" : "ὑποτύπωσις",
		"derivation" : "from a compound of G5259 (ὑπό) and a derivative of G5179 (τύπος)",
		"def" : "typification under (after), i.e. (concretely) a sketch (figuratively) for imitation"
	},
	"G4223" : {
		"derivation" : "of Latin origin",
		"def" : "little wells, i.e. mineral springs; Potioli (i.e. Puteoli), a place in Italy",
		"kjv" : "Puteoli",
		"translit" : "Potíoloi",
		"lemma" : "Ποτίολοι"
	},
	"G4489" : {
		"lemma" : "ῥήτωρ",
		"translit" : "rhḗtōr",
		"kjv" : "orator",
		"def" : "a speaker, i.e. (by implication) a forensic advocate",
		"derivation" : "from G4483 (ῥέω)"
	},
	"G1012" : {
		"derivation" : "from G1014 (βούλομαι)",
		"def" : "volition, i.e. (objectively) advice, or (by implication) purpose",
		"kjv" : "+ advise, counsel, will",
		"translit" : "boulḗ",
		"lemma" : "βουλή"
	},
	"G3067" : {
		"derivation" : "from G3068 (λούω)",
		"def" : "a bath, i.e. (figuratively), baptism",
		"kjv" : "washing",
		"translit" : "loutrón",
		"lemma" : "λουτρόν"
	},
	"G1782" : {
		"kjv" : "(from) hence, on either side",
		"lemma" : "ἐντεῦθεν",
		"translit" : "enteûthen",
		"derivation" : "from the same as G1759 (ἐνθάδε)",
		"def" : "hence (literally or figuratively); (repeated) on both sides"
	},
	"G5298" : {
		"kjv" : "go aside, withdraw self",
		"lemma" : "ὑποχωρέω",
		"translit" : "hypochōréō",
		"derivation" : "from G5259 (ὑπό) and G5562 (χωρέω)",
		"def" : "to vacate down, i.e. retire quietly"
	},
	"G2209" : {
		"translit" : "zēmía",
		"lemma" : "ζημία",
		"kjv" : "damage, loss",
		"def" : "detriment",
		"derivation" : "probably akin to the base of G1150 (δαμάζω) (through the idea of violence)"
	},
	"G5554" : {
		"lemma" : "χρυσοδακτύλιος",
		"translit" : "chrysodaktýlios",
		"kjv" : "with a gold ring",
		"def" : "gold-ringed, i.e. wearing a golden finger-ring or similar jewelry",
		"derivation" : "from G5557 (χρυσός) and G1146 (δακτύλιος)"
	},
	"G2363" : {
		"translit" : "Thyáteira",
		"lemma" : "Θυάτειρα",
		"kjv" : "Thyatira",
		"def" : "Thyatira, a place in Asia Minor",
		"derivation" : "of uncertain derivation"
	},
	"G1310" : {
		"kjv" : "blaze abroad, commonly report, spread abroad, fame",
		"lemma" : "διαφημίζω",
		"translit" : "diaphēmízō",
		"derivation" : "from G1223 (διά) and a derivative of G5345 (φήμη)",
		"def" : "to report thoroughly, i.e. divulgate"
	},
	"G649" : {
		"derivation" : "from G575 (ἀπό) and G4724 (στέλλω)",
		"def" : "set apart, i.e. (by implication) to send out (properly, on a mission) literally or figuratively",
		"kjv" : "put in, send (away, forth, out), set (at liberty)",
		"lemma" : "ἀποστέλλω",
		"translit" : "apostéllō"
	},
	"G503" : {
		"def" : "to face",
		"derivation" : "from a compound of G473 (ἀντί) and G3788 (ὀφθαλμός)",
		"translit" : "antophthalméō",
		"lemma" : "ἀντοφθαλμέω",
		"kjv" : "bear up into"
	},
	"G2687" : {
		"lemma" : "καταστέλλω",
		"translit" : "katastéllō",
		"kjv" : "appease, quiet",
		"def" : "to put down, i.e. quell",
		"derivation" : "from G2596 (κατά) and G4724 (στέλλω)"
	},
	"G818" : {
		"def" : "to render infamous, i.e. (by implication) contemn or maltreat",
		"derivation" : "from G820 (ἄτιμος)",
		"lemma" : "ἀτιμάζω",
		"translit" : "atimázō",
		"kjv" : "despise, dishonour, suffer shame, entreat shamefully"
	},
	"G5178" : {
		"kjv" : "torture",
		"translit" : "tympanízō",
		"lemma" : "τυμπανίζω",
		"derivation" : "from a derivative of G5180 (τύπτω) (meaning a drum, \"tympanum\")",
		"def" : "to stretch on an instrument of torture resembling a drum, and thus beat to death"
	},
	"G4714" : {
		"kjv" : "dissension, insurrection, X standing, uproar",
		"translit" : "stásis",
		"lemma" : "στάσις",
		"derivation" : "from the base of G2476 (ἵστημι)",
		"def" : "a standing (properly, the act), i.e. (by analogy) position (existence); by implication, a popular uprising; figuratively, controversy"
	},
	"G1163" : {
		"translit" : "deî",
		"lemma" : "δεῖ",
		"kjv" : "behoved, be meet, must (needs), (be) need(-ful), ought, should",
		"def" : "also deon deh-on'; neuter active participle of the same; both used impersonally; it is (was, etc.) necessary (as binding)",
		"derivation" : "3rd person singular active present of G1210 (δέω)"
	},
	"G4686" : {
		"derivation" : "of immediate Latin origin, but ultimately a derivative of G138 (αἱρέομαι) in the sense of its cognate G1507 (εἱλίσσω)",
		"def" : "a coil (spira, \"spire\"), i.e. (figuratively) a mass of men (a Roman military cohort; also (by analogy) a squad of Levitical janitors)",
		"kjv" : "band",
		"translit" : "speîra",
		"lemma" : "σπεῖρα"
	},
	"G1555" : {
		"def" : "to narrate through wholly",
		"derivation" : "from G1537 (ἐκ) and a compound of G1223 (διά) and G2233 (ἡγέομαι)",
		"lemma" : "ἐκδιηγέομαι",
		"translit" : "ekdiēgéomai",
		"kjv" : "declare"
	},
	"G5177" : {
		"lemma" : "τυγχάνω",
		"translit" : "tynchánō",
		"kjv" : "be, chance, enjoy, little, obtain, X refresh…self, + special",
		"def" : "akin to the base of G5088 (τίκτω) through the idea of effecting; properly, to affect; or (specially), to hit or light upon (as a mark to be reached), i.e. (transitively) to attain or secure an object or end, or (intransitively) to happen (as if meeting with); but in the latter application only impersonal (with G1487 (εἰ)), i.e. perchance; or (present participle) as adjective, usual (as if commonly met with, with G3756 (οὐ), extraordinary), neuter (as adverb) perhaps; or (with another verb) as adverb, by accident (as it were)",
		"derivation" : "probably for an obsolete (for which the middle voice of another alternate (to make ready or bring to pass) is used in certain tenses"
	},
	"G4189" : {
		"translit" : "ponēría",
		"lemma" : "πονηρία",
		"kjv" : "iniquity, wickedness",
		"def" : "depravity, i.e. (specially), malice; plural (concretely) plots, sins",
		"derivation" : "from G4190 (πονηρός)"
	},
	"G2035" : {
		"derivation" : "from G2034 (ἑπτάκις) and G5507 (χίλιοι)",
		"def" : "seven times a thousand",
		"kjv" : "seven thousand",
		"lemma" : "ἑπτακισχίλιοι",
		"translit" : "heptakischílioi"
	},
	"G1193" : {
		"translit" : "dermátinos",
		"lemma" : "δερμάτινος",
		"kjv" : "leathern, of a skin",
		"def" : "made of hide",
		"derivation" : "from G1192 (δέρμα)"
	},
	"G3339" : {
		"translit" : "metamorphóō",
		"lemma" : "μεταμορφόω",
		"kjv" : "change, transfigure, transform",
		"def" : "to transform (literally or figuratively, \"metamorphose\")",
		"derivation" : "from G3326 (μετά) and G3445 (μορφόω)"
	},
	"G4086" : {
		"derivation" : "from a compound of a derivative of G3982 (πείθω) and G3056 (λόγος)",
		"def" : "persuasive language",
		"kjv" : "enticing words",
		"lemma" : "πιθανολογία",
		"translit" : "pithanología"
	},
	"G223" : {
		"translit" : "Aléxandros",
		"lemma" : "Ἀλέξανδρος",
		"kjv" : "Alexander",
		"def" : "man-defender; Alexander, the name of three Israelites and one other man",
		"derivation" : "from the same as (the first part of) G220 (ἀλέκτωρ) and G435 (ἀνήρ)"
	},
	"G4094" : {
		"derivation" : "apparently a form of G4109 (πλάξ)",
		"def" : "a plate",
		"kjv" : "charger, platter",
		"lemma" : "πίναξ",
		"translit" : "pínax"
	},
	"G4435" : {
		"kjv" : "oft",
		"translit" : "pygmḗ",
		"lemma" : "πυγμή",
		"derivation" : "from a primary (the fist as a weapon)",
		"def" : "the clenched hand, i.e. (only in dative case as adverb) with the fist (hard scrubbing)"
	},
	"G57" : {
		"translit" : "ágnōstos",
		"lemma" : "ἄγνωστος",
		"kjv" : "unknown",
		"def" : "unknown",
		"derivation" : "from G1 (Α) (as negative particle) and G1110 (γνωστός)"
	},
	"G229" : {
		"derivation" : "from the same as G224 (ἄλευρον)",
		"def" : "to grind",
		"kjv" : "grind",
		"lemma" : "ἀλήθω",
		"translit" : "alḗthō"
	},
	"G692" : {
		"kjv" : "barren, idle, slow",
		"translit" : "argós",
		"lemma" : "ἀργός",
		"derivation" : "from G1 (Α) (as a negative particle) and G2041 (ἔργον)",
		"def" : "inactive, i.e. unemployed; (by implication) lazy, useless"
	},
	"G3670" : {
		"kjv" : "con- (pro-)fess, confession is made, give thanks, promise",
		"translit" : "homologéō",
		"lemma" : "ὁμολογέω",
		"derivation" : "from a compound of the base of G3674 (ὁμοῦ) and G3056 (λόγος)",
		"def" : "to assent, i.e. covenant, acknowledge"
	},
	"G664" : {
		"kjv" : "sharply(-ness)",
		"translit" : "apotómōs",
		"lemma" : "ἀποτόμως",
		"derivation" : "adverb from a derivative of a comparative of G575 (ἀπό) and (to cut)",
		"def" : "abruptly, i.e. peremptorily"
	},
	"G1678" : {
		"derivation" : "of Hebrew origin (perhaps for H486)",
		"def" : "Elmodam, an Israelite",
		"kjv" : "Elmodam",
		"lemma" : "Ἐλμωδάμ",
		"translit" : "Elmōdám"
	},
	"G4439" : {
		"derivation" : "apparently a primary word",
		"def" : "a gate, i.e. the leaf or wing of a folding entrance (literally or figuratively)",
		"kjv" : "gate",
		"lemma" : "πύλη",
		"translit" : "pýlē"
	},
	"G4153" : {
		"kjv" : "spiritually",
		"lemma" : "πνευματικῶς",
		"translit" : "pneumatikōs",
		"derivation" : "adverb from G4152 (πνευματικός)",
		"def" : "non-physically, i.e. divinely, figuratively"
	},
	"G4738" : {
		"derivation" : "from G2476 (ἵστημι) (as standing prominently)",
		"def" : "the (entire external) bosom, i.e. chest",
		"kjv" : "breast",
		"lemma" : "στῆθος",
		"translit" : "stēthos"
	},
	"G4230" : {
		"derivation" : "from G4231 (πραγματεύομαι)",
		"def" : "a transaction, i.e. negotiation",
		"kjv" : "affair",
		"translit" : "pragmateía",
		"lemma" : "πραγματεία"
	},
	"G1671" : {
		"kjv" : "Greece",
		"lemma" : "Ἑλλάς",
		"translit" : "Hellás",
		"derivation" : "of uncertain affinity",
		"def" : "Hellas (or Greece), a country of Europe"
	},
	"G181" : {
		"kjv" : "commotion, confusion, tumult",
		"lemma" : "ἀκαταστασία",
		"translit" : "akatastasía",
		"derivation" : "from G182 (ἀκατάστατος)",
		"def" : "instability, i.e. disorder"
	},
	"G3947" : {
		"translit" : "paroxýnō",
		"lemma" : "παροξύνω",
		"kjv" : "easily provoke, stir",
		"def" : "to sharpen alongside, i.e. (figuratively) to exasperate",
		"derivation" : "from G3844 (παρά) and a derivative of G3691 (ὀξύς)"
	},
	"G1212" : {
		"translit" : "dēlos",
		"lemma" : "δῆλος",
		"kjv" : "+ bewray, certain, evident, manifest",
		"def" : "clear",
		"derivation" : "of uncertain derivation"
	},
	"G3757" : {
		"def" : "at which place, i.e. where",
		"derivation" : "genitive case of G3739 (ὅς) as adverb",
		"translit" : "hoû",
		"lemma" : "οὗ",
		"kjv" : "where(-in), whither(-soever)"
	},
	"G3201" : {
		"def" : "to blame",
		"derivation" : "middle voice of an apparently primary verb",
		"translit" : "mémphomai",
		"lemma" : "μέμφομαι",
		"kjv" : "find fault"
	},
	"G5568" : {
		"kjv" : "psalm",
		"translit" : "psalmós",
		"lemma" : "ψαλμός",
		"derivation" : "from G5567 (ψάλλω)",
		"def" : "a set piece of music, i.e. a sacred ode (accompanied with the voice, harp or other instrument; a \"psalm\"); collectively, the book of the Psalms"
	},
	"G3369" : {
		"kjv" : "not yet",
		"lemma" : "μηδέπω",
		"translit" : "mēdépō",
		"derivation" : "from G3366 (μηδέ) and G4452 (-πω)",
		"def" : "not even yet"
	},
	"G1488" : {
		"def" : "thou art",
		"derivation" : "second person singular present of G1510 (εἰμί)",
		"translit" : "eî",
		"lemma" : "εἶ",
		"kjv" : "art, be"
	},
	"G1907" : {
		"lemma" : "ἐπέχω",
		"translit" : "epéchō",
		"kjv" : "give (take) heed unto, hold forth, mark, stay",
		"def" : "to hold upon, i.e. (by implication) to retain; (by extension) to detain; (with implication, of G3563 (νοῦς)) to pay attention to",
		"derivation" : "from G1909 (ἐπί) and G2192 (ἔχω)"
	},
	"G1763" : {
		"kjv" : "year",
		"lemma" : "ἐνιαυτός",
		"translit" : "eniautós",
		"derivation" : "prolongation from a primary (a year)",
		"def" : "a year"
	},
	"G351" : {
		"kjv" : "examination",
		"translit" : "anákrisis",
		"lemma" : "ἀνάκρισις",
		"derivation" : "from G350 (ἀνακρίνω)",
		"def" : "a (judicial) investigation"
	},
	"G1357" : {
		"lemma" : "διόρθωσις",
		"translit" : "diórthōsis",
		"kjv" : "reformation",
		"def" : "rectification, i.e. (specially) the Messianic restauration",
		"derivation" : "from a compound of G1223 (διά) and a derivative of G3717 (ὀρθός), meaning to straighten thoroughly"
	},
	"G2130" : {
		"lemma" : "εὐμετάδοτος",
		"translit" : "eumetádotos",
		"kjv" : "ready to distribute",
		"def" : "good at imparting, i.e. liberal",
		"derivation" : "from G2095 (εὖ) and a presumed derivative of G3330 (μεταδίδωμι)"
	},
	"G602" : {
		"derivation" : "from G601 (ἀποκαλύπτω)",
		"def" : "disclosure",
		"kjv" : "appearing, coming, lighten, manifestation, be revealed, revelation",
		"translit" : "apokálypsis",
		"lemma" : "ἀποκάλυψις"
	},
	"G473" : {
		"kjv" : "for, in the room of",
		"lemma" : "ἀντί",
		"translit" : "antí",
		"derivation" : "a primary particle",
		"def" : "opposite, i.e. instead or because of (rarely in addition to)"
	},
	"G3112" : {
		"def" : "at a distance (literally or figuratively)",
		"derivation" : "feminine accusative case singular of G3117 (μακρός) (G3598 (ὁδός) being implied)",
		"translit" : "makrán",
		"lemma" : "μακράν",
		"kjv" : "(a-)far (off), good (great) way off"
	},
	"G120" : {
		"derivation" : "from a compound of G1 (Α) (as a negative particle) and G2372 (θυμός)",
		"def" : "to be spiritless, i.e. disheartened",
		"kjv" : "be dismayed",
		"translit" : "athyméō",
		"lemma" : "ἀθυμέω"
	},
	"G2483" : {
		"def" : "Italic, i.e. belonging to Italia",
		"derivation" : "from G2482 (Ἰταλία)",
		"lemma" : "Ἰταλικός",
		"translit" : "Italikós",
		"kjv" : "Italian"
	},
	"G4085" : {
		"derivation" : "another form for G4084 (πιάζω)",
		"def" : "to pack",
		"kjv" : "press down",
		"translit" : "piézō",
		"lemma" : "πιέζω"
	},
	"G3400" : {
		"lemma" : "μίλιον",
		"translit" : "mílion",
		"kjv" : "mile",
		"def" : "a thousand paces, i.e. a \"mile\"",
		"derivation" : "of Latin origin"
	},
	"G794" : {
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of (to cherish affectionately)",
		"def" : "hard-hearted towards kindred",
		"kjv" : "without natural affection",
		"translit" : "ástorgos",
		"lemma" : "ἄστοργος"
	},
	"G2249" : {
		"kjv" : "us, we (ourselves)",
		"lemma" : "ἡμεῖς",
		"translit" : "hēmeîs",
		"derivation" : "nominative plural of G1473 (ἐγώ)",
		"def" : "we (only used when emphatic)"
	},
	"G1909" : {
		"def" : "properly, meaning superimposition (of time, place, order, etc.), as a relation of distribution (with the genitive case), i.e. over, upon, etc.; of rest (with the dative case) at, on, etc.; of direction (with the accusative case) towards, upon, etc.",
		"derivation" : "a primary preposition",
		"lemma" : "ἐπί",
		"translit" : "epí",
		"kjv" : "about (the times), above, after, against, among, as long as (touching), at, beside, X have charge of, (be-, (where-))fore, in (a place, as much as, the time of, -to), (because) of, (up-)on (behalf of), over, (by, for) the space of, through(-out), (un-)to(-ward), with"
	},
	"G1685" : {
		"translit" : "embállō",
		"lemma" : "ἐμβάλλω",
		"kjv" : "cast into",
		"def" : "to throw on, i.e. (figuratively) subject to (eternal punishment)",
		"derivation" : "from G1722 (ἐν) and G906 (βάλλω)"
	},
	"G5084" : {
		"translit" : "tḗrēsis",
		"lemma" : "τήρησις",
		"kjv" : "hold",
		"def" : "a watching, i.e. (figuratively) observance, or (concretely) a prison",
		"derivation" : "from G5083 (τηρέω)"
	},
	"G2460" : {
		"translit" : "hippeús",
		"lemma" : "ἱππεύς",
		"kjv" : "horseman",
		"def" : "an equestrian, i.e. member of a cavalry corps",
		"derivation" : "from G2462 (ἵππος)"
	},
	"G3972" : {
		"derivation" : "of Latin origin",
		"def" : "(little; but remotely from a derivative of G3973 (παύω), meaning the same); Paulus, the name of a Roman and of an apostle",
		"kjv" : "Paul, Paulus",
		"translit" : "Paûlos",
		"lemma" : "Παῦλος"
	},
	"G27" : {
		"translit" : "agapētós",
		"lemma" : "ἀγαπητός",
		"kjv" : "(dearly, well) beloved, dear",
		"def" : "beloved",
		"derivation" : "from G25 (ἀγαπάω)"
	},
	"G2099" : {
		"kjv" : "evangelist",
		"lemma" : "εὐαγγελιστής",
		"translit" : "euangelistḗs",
		"derivation" : "from G2097 (εὐαγγελίζω)",
		"def" : "a preacher of the gospel"
	},
	"G2039" : {
		"def" : "occupation; by implication, profit, pains",
		"derivation" : "from G2040 (ἐργάτης)",
		"translit" : "ergasía",
		"lemma" : "ἐργασία",
		"kjv" : "craft, diligence, gain, work"
	},
	"G4576" : {
		"def" : "to revere, i.e. adore",
		"derivation" : "middle voice of an apparently primary verb",
		"translit" : "sébomai",
		"lemma" : "σέβομαι",
		"kjv" : "devout, religious, worship"
	},
	"G488" : {
		"lemma" : "ἀντιμετρέω",
		"translit" : "antimetréō",
		"kjv" : "measure again",
		"def" : "to mete in return",
		"derivation" : "from G473 (ἀντί) and G3354 (μετρέω)"
	},
	"G2396" : {
		"kjv" : "behold, lo, see",
		"translit" : "íde",
		"lemma" : "ἴδε",
		"derivation" : "second person singular imperative active of G1492 (εἴδω)",
		"def" : "used as an interjection to denote surprise; lo!"
	},
	"G518" : {
		"derivation" : "from G575 (ἀπό) and the base of G32 (ἄγγελος)",
		"def" : "to announce",
		"kjv" : "bring word (again), declare, report, shew (again), tell",
		"translit" : "apangéllō",
		"lemma" : "ἀπαγγέλλω"
	},
	"G3618" : {
		"kjv" : "(be in) build(-er, -ing, up), edify, embolden",
		"lemma" : "οἰκοδομέω",
		"translit" : "oikodoméō",
		"derivation" : "from the same as G3619 (οἰκοδομή)",
		"def" : "to be a house-builder, i.e. construct or (figuratively) confirm"
	},
	"G708" : {
		"lemma" : "Ἀρίσταρχος",
		"translit" : "Arístarchos",
		"kjv" : "Aristarchus",
		"def" : "best ruling; Aristarchus, a Macedonian",
		"derivation" : "from the same as G712 (ἄριστον) and G757 (ἄρχω)"
	},
	"G4716" : {
		"kjv" : "cross",
		"lemma" : "σταυρός",
		"translit" : "staurós",
		"derivation" : "from the base of G2476 (ἵστημι)",
		"def" : "a stake or post (as set upright), i.e. (specially), a pole or cross (as an instrument of capital punishment); figuratively, exposure to death, i.e. self-denial; by implication, the atonement of Christ"
	},
	"G2995" : {
		"kjv" : "throat",
		"lemma" : "λάρυγξ",
		"translit" : "lárynx",
		"derivation" : "of uncertain derivation",
		"def" : "the throat (\"larynx\")"
	},
	"G2459" : {
		"translit" : "Ioûstos",
		"lemma" : "Ἰοῦστος",
		"kjv" : "Justus",
		"def" : "Justus, the name of three Christian",
		"derivation" : "of Latin origin (\"just\")"
	},
	"G5621" : {
		"def" : "an earlet, i.e. one of the ears, or perhaps the lobe of the ear",
		"derivation" : "diminutive of G3775 (οὖς)",
		"translit" : "ōtíon",
		"lemma" : "ὠτίον",
		"kjv" : "ear"
	},
	"G4679" : {
		"derivation" : "from G4680 (σοφός)",
		"def" : "to render wise; in a sinister acceptation, to form \"sophisms\", i.e. continue plausible error",
		"kjv" : "cunningly devised, make wise",
		"translit" : "sophízō",
		"lemma" : "σοφίζω"
	},
	"G2176" : {
		"def" : "properly, well-named (good-omened), i.e. the left (which was the lucky side among the pagan Greeks); neuter as adverbial, at the left hand",
		"derivation" : "from G2095 (εὖ) and G3686 (ὄνομα)",
		"translit" : "euṓnymos",
		"lemma" : "εὐώνυμος",
		"kjv" : "(on the) left"
	},
	"G2790" : {
		"derivation" : "from G2788 (κιθάρα) and a derivative of the same as G5603 (ᾠδή)",
		"def" : "a lyre-singer(-player), i.e. harpist",
		"kjv" : "harper",
		"lemma" : "κιθαρῳδός",
		"translit" : "kitharōidós"
	},
	"G4426" : {
		"lemma" : "πτύρω",
		"translit" : "ptýrō",
		"kjv" : "terrify",
		"def" : "to frighten",
		"derivation" : "from a presumed derivative of G4429 (πτύω) (and thus akin to G4422 (πτοέω))"
	},
	"G2772" : {
		"def" : "a clipping (bit), i.e. (specially) a coin",
		"derivation" : "from G2751 (κείρω)",
		"lemma" : "κέρμα",
		"translit" : "kérma",
		"kjv" : "money"
	},
	"G5038" : {
		"lemma" : "τεῖχος",
		"translit" : "teîchos",
		"kjv" : "wall",
		"def" : "a wall (as formative of a house)",
		"derivation" : "akin to the base of G5088 (τίκτω)"
	},
	"G5486" : {
		"kjv" : "(free) gift",
		"lemma" : "χάρισμα",
		"translit" : "chárisma",
		"derivation" : "from G5483 (χαρίζομαι)",
		"def" : "a (divine) gratuity, i.e. deliverance (from danger or passion); (specially), a (spiritual) endowment, i.e. (subjectively) religious qualification, or (objectively) miraculous faculty"
	},
	"G5053" : {
		"derivation" : "from a presumed derivative of G5055 (τελέω)",
		"def" : "to finish life (by implication, of G979 (βίος)), i.e. expire (demise)",
		"kjv" : "be dead, decease, die",
		"translit" : "teleutáō",
		"lemma" : "τελευτάω"
	},
	"G3547" : {
		"derivation" : "from G3551 (νόμος) and G1320 (διδάσκαλος)",
		"def" : "an expounder of the (Jewish) law, i.e. a Rabbi",
		"kjv" : "doctor (teacher) of the law",
		"lemma" : "νομοδιδάσκαλος",
		"translit" : "nomodidáskalos"
	},
	"G2472" : {
		"kjv" : "like precious",
		"lemma" : "ἰσότιμος",
		"translit" : "isótimos",
		"derivation" : "from G2470 (ἴσος) and G5092 (τιμή)",
		"def" : "of equal value or honor"
	},
	"G4161" : {
		"def" : "a product, i.e. fabric (literally or figuratively)",
		"derivation" : "from G4160 (ποιέω)",
		"translit" : "poíēma",
		"lemma" : "ποίημα",
		"kjv" : "thing that is made, workmanship"
	},
	"G2801" : {
		"def" : "a piece (bit)",
		"derivation" : "from G2806 (κλάω)",
		"lemma" : "κλάσμα",
		"translit" : "klásma",
		"kjv" : "broken, fragment"
	},
	"G5433" : {
		"derivation" : "akin to G1032 (βρύω), G1031 (βρύχω)",
		"def" : "to snort (as a spirited horse), i.e. (figuratively) to make a tumult",
		"kjv" : "rage",
		"lemma" : "φρυάσσω",
		"translit" : "phryássō"
	},
	"G4873" : {
		"derivation" : "from G4862 (σύν) and G345 (ἀνακεῖμαι)",
		"def" : "to recline in company with (at a meal)",
		"kjv" : "sit (down, at the table, together) with (at meat)",
		"lemma" : "συνανάκειμαι",
		"translit" : "synanákeimai"
	},
	"G1227" : {
		"kjv" : "see clearly",
		"translit" : "diablépō",
		"lemma" : "διαβλέπω",
		"derivation" : "from G1223 (διά) and G991 (βλέπω)",
		"def" : "to look through, i.e. recover full vision"
	},
	"G1011" : {
		"lemma" : "βουλεύω",
		"translit" : "bouleúō",
		"kjv" : "consult, take counsel, determine, be minded, purpose",
		"def" : "to advise, i.e. (reflexively) deliberate, or (by implication) resolve",
		"derivation" : "from G1012 (βουλή)"
	},
	"G3702" : {
		"lemma" : "ὀπτός",
		"translit" : "optós",
		"kjv" : "broiled",
		"def" : "cooked, i.e. roasted",
		"derivation" : "from an obsolete verb akin to (to \"steep\")"
	},
	"G4957" : {
		"kjv" : "crucify with",
		"translit" : "systauróō",
		"lemma" : "συσταυρόω",
		"derivation" : "from G4862 (σύν) and G4717 (σταυρόω)",
		"def" : "to impale in company with (literally or figuratively)"
	},
	"G5194" : {
		"derivation" : "perhaps from the same as G5205 (ὑετός) (as being transparent like rain)",
		"def" : "glass",
		"kjv" : "glass",
		"lemma" : "ὕαλος",
		"translit" : "hýalos"
	},
	"G562" : {
		"kjv" : "endless",
		"lemma" : "ἀπέραντος",
		"translit" : "apérantos",
		"derivation" : "from G1 (Α) (as a negative particle) and a secondary derivative of G4008 (πέραν)",
		"def" : "unfinished, i.e. (by implication) interminable"
	},
	"G2056" : {
		"kjv" : "goat, kid",
		"translit" : "ériphos",
		"lemma" : "ἔριφος",
		"derivation" : "perhaps from the same as G2053 (ἔριον) (through the idea of hairiness)",
		"def" : "a kid or (genitive case) goat"
	},
	"G781" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G4680 (σοφός)",
		"def" : "unwise",
		"kjv" : "fool",
		"lemma" : "ἄσοφος",
		"translit" : "ásophos"
	},
	"G793" : {
		"def" : "unfixed, i.e. (figuratively) vacillating",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G4741 (στηρίζω)",
		"lemma" : "ἀστήρικτος",
		"translit" : "astḗriktos",
		"kjv" : "unstable"
	},
	"G5211" : {
		"kjv" : "Hymenæus",
		"lemma" : "Ὑμεναῖος",
		"translit" : "Hymenaîos",
		"derivation" : "from (the god of weddings)",
		"def" : "\"hymenæal\"; Hymenæus, an opponent of Christianity"
	},
	"G560" : {
		"def" : "to hope out, i.e. fully expect",
		"derivation" : "from G575 (ἀπό) and G1679 (ἐλπίζω)",
		"lemma" : "ἀπελπίζω",
		"translit" : "apelpízō",
		"kjv" : "hope for again"
	},
	"G502" : {
		"def" : "a baling-vessel",
		"derivation" : "from G501 (ἀντλέω)",
		"lemma" : "ἄντλημα",
		"translit" : "ántlēma",
		"kjv" : "thing to draw with"
	},
	"G913" : {
		"derivation" : "of Hebrew origin (H1301)",
		"def" : "Barak, an Israelite",
		"kjv" : "Barak",
		"lemma" : "Βαράκ",
		"translit" : "Barák"
	},
	"G2401" : {
		"derivation" : "of Hebrew origin (H123)",
		"def" : "Idumæa (i.e. Edom), a region East (and South) of Palestine",
		"kjv" : "Idumæa",
		"translit" : "Idoumaía",
		"lemma" : "Ἰδουμαία"
	},
	"G1963" : {
		"def" : "attention of the mind, i.e. (by implication) purpose",
		"derivation" : "from G1909 (ἐπί) and G3563 (νοῦς)",
		"translit" : "epínoia",
		"lemma" : "ἐπίνοια",
		"kjv" : "thought"
	},
	"G4022" : {
		"derivation" : "from G4012 (περί) and G2064 (ἔρχομαι) (including its alternate)",
		"def" : "to come all around, i.e. stroll, vacillate, veer",
		"kjv" : "fetch a compass, vagabond, wandering about",
		"lemma" : "περιέρχομαι",
		"translit" : "periérchomai"
	},
	"G2835" : {
		"def" : "a quadrans, i.e. the fourth part of an as",
		"derivation" : "of Latin origin",
		"lemma" : "κοδράντης",
		"translit" : "kodrántēs",
		"kjv" : "farthing"
	},
	"G2091" : {
		"def" : "preparation",
		"derivation" : "from G2090 (ἑτοιμάζω)",
		"translit" : "hetoimasía",
		"lemma" : "ἑτοιμασία",
		"kjv" : "preparation"
	},
	"G4442" : {
		"derivation" : "a primary word",
		"def" : "\"fire\" (literally or figuratively, specially, lightning)",
		"kjv" : "fiery, fire",
		"lemma" : "πῦρ",
		"translit" : "pŷr"
	},
	"G1463" : {
		"translit" : "enkombóomai",
		"lemma" : "ἐγκομβόομαι",
		"kjv" : "be clothed with",
		"def" : "to engirdle oneself (for labor), i.e. figuratively (the apron as being a badge of servitude) to wear (in token of mutual deference)",
		"derivation" : "middle voice from G1722 (ἐν) and (to gird)"
	},
	"G1700" : {
		"kjv" : "me, mine, my",
		"lemma" : "ἐμοῦ",
		"translit" : "emoû",
		"derivation" : "a prolonged form of G3449 (μόχθος)",
		"def" : "of me"
	},
	"G4817" : {
		"derivation" : "from G4862 (σύν) and G3049 (λογίζομαι)",
		"def" : "to reckon together (with oneself), i.e. deliberate",
		"kjv" : "reason with",
		"lemma" : "συλλογίζομαι",
		"translit" : "syllogízomai"
	},
	"G5012" : {
		"derivation" : "from a compound of G5011 (ταπεινός) and the base of G5424 (φρήν)",
		"def" : "humiliation of mind, i.e. modesty",
		"kjv" : "humbleness of mind, humility (of mind, loneliness (of mind)",
		"lemma" : "ταπεινοφροσύνη",
		"translit" : "tapeinophrosýnē"
	},
	"G1591" : {
		"translit" : "ekmássō",
		"lemma" : "ἐκμάσσω",
		"kjv" : "wipe",
		"def" : "to knead out, i.e. (by analogy) to wipe dry",
		"derivation" : "from G1537 (ἐκ) and the base of G3145 (μασσάομαι)"
	},
	"G1029" : {
		"derivation" : "of uncertain derivation",
		"def" : "a noose",
		"kjv" : "snare",
		"translit" : "bróchos",
		"lemma" : "βρόχος"
	},
	"G4064" : {
		"translit" : "periphérō",
		"lemma" : "περιφέρω",
		"kjv" : "bear (carry) about",
		"def" : "to convey around, i.e. transport hither and thither",
		"derivation" : "from G4012 (περί) and G5342 (φέρω)"
	},
	"G808" : {
		"def" : "an indecency; by implication, the pudenda",
		"derivation" : "from G809 (ἀσχήμων)",
		"lemma" : "ἀσχημοσύνη",
		"translit" : "aschēmosýnē",
		"kjv" : "shame, that which is unseemly"
	},
	"G1927" : {
		"lemma" : "ἐπιδημέω",
		"translit" : "epidēméō",
		"kjv" : "(be) dwelling (which were) there, stranger",
		"def" : "to make oneself at home, i.e. (by extension) to reside (in a foreign country)",
		"derivation" : "from a compound of G1909 (ἐπί) and G1218 (δῆμος)"
	},
	"G1893" : {
		"kjv" : "because, else, for that (then, -asmuch as), otherwise, seeing that, since, when",
		"lemma" : "ἐπεί",
		"translit" : "epeí",
		"derivation" : "from G1909 (ἐπί) and G1487 (εἰ)",
		"def" : "thereupon, i.e. since (of time or cause)"
	},
	"G5303" : {
		"derivation" : "from G5302 (ὑστερέω)",
		"def" : "a deficit; specially, poverty",
		"kjv" : "that which is behind, (that which was) lack(-ing), penury, want",
		"lemma" : "ὑστέρημα",
		"translit" : "hystérēma"
	},
	"G5071" : {
		"def" : "four hundred",
		"derivation" : "plural from G5064 (τέσσαρες) and G1540 (ἑκατόν)",
		"lemma" : "τετρακόσιοι",
		"translit" : "tetrakósioi",
		"kjv" : "four hundred"
	},
	"G3714" : {
		"lemma" : "ὀρεινός",
		"translit" : "oreinós",
		"kjv" : "hill country",
		"def" : "mountainous, i.e. (feminine by implication, of G5561 (χώρα)) the Highlands (of Judæa)",
		"derivation" : "from G3735 (ὄρος)"
	},
	"G1538" : {
		"kjv" : "any, both, each (one), every (man, one, woman), particularly",
		"lemma" : "ἕκαστος",
		"translit" : "hékastos",
		"derivation" : "as if a superlative of (afar)",
		"def" : "each or every"
	},
	"G1028" : {
		"lemma" : "βροχή",
		"translit" : "brochḗ",
		"kjv" : "rain",
		"def" : "rain",
		"derivation" : "from G1026 (βρέχω)"
	},
	"G3831" : {
		"lemma" : "πανήγυρις",
		"translit" : "panḗgyris",
		"kjv" : "general assembly",
		"def" : "a mass-meeting, i.e. (figuratively) universal companionship",
		"derivation" : "from G3956 (πᾶς) and a derivative of G58 (ἀγορά)"
	},
	"G3329" : {
		"kjv" : "turn about",
		"translit" : "metágō",
		"lemma" : "μετάγω",
		"derivation" : "from G3326 (μετά) and G718 (ἁρμόζω)",
		"def" : "to lead over, i.e. transfer (direct)"
	},
	"G78" : {
		"kjv" : "Addi",
		"lemma" : "Ἀδδί",
		"translit" : "Addí",
		"derivation" : "probably of Hebrew origin (compare H5716)",
		"def" : "Addi, an Israelite"
	},
	"G1843" : {
		"kjv" : "confess, profess, promise",
		"lemma" : "ἐξομολογέω",
		"translit" : "exomologéō",
		"derivation" : "from G1537 (ἐκ) and G3670 (ὁμολογέω)",
		"def" : "to acknowledge or (by implication, of assent) agree fully"
	},
	"G4662" : {
		"derivation" : "from G4663 (σκώληξ) and a derivative of G977 (βιβρώσκω)",
		"def" : "worm-eaten, i.e. diseased with maggots",
		"kjv" : "eaten of worms",
		"lemma" : "σκωληκόβρωτος",
		"translit" : "skōlēkóbrōtos"
	},
	"G1617" : {
		"def" : "more intently",
		"derivation" : "neuter of the comparative of G1618 (ἐκτενής)",
		"lemma" : "ἐκτενέστερον",
		"translit" : "ektenésteron",
		"kjv" : "more earnestly"
	},
	"G1100" : {
		"derivation" : "of uncertain affinity",
		"def" : "the tongue; by implication, a language (specially, one naturally unacquired)",
		"kjv" : "tongue",
		"lemma" : "γλῶσσα",
		"translit" : "glōssa"
	},
	"G530" : {
		"derivation" : "probably from G537 (ἅπας)",
		"def" : "one (or a single) time (numerically or conclusively)",
		"kjv" : "once",
		"lemma" : "ἅπαξ",
		"translit" : "hápax"
	},
	"G2033" : {
		"kjv" : "seven",
		"translit" : "heptá",
		"lemma" : "ἑπτά",
		"derivation" : "a primary number",
		"def" : "seven"
	},
	"G286" : {
		"lemma" : "ἀμνός",
		"translit" : "amnós",
		"kjv" : "lamb",
		"def" : "a lamb",
		"derivation" : "apparently a primary word"
	},
	"G672" : {
		"derivation" : "from G575 (ἀπό) and G5562 (χωρέω)",
		"def" : "to go away",
		"kjv" : "depart",
		"translit" : "apochōréō",
		"lemma" : "ἀποχωρέω"
	},
	"G5307" : {
		"derivation" : "from to weave",
		"def" : "woven, i.e. (perhaps) knitted",
		"kjv" : "woven",
		"lemma" : "ὑφαντός",
		"translit" : "hyphantós"
	},
	"G5584" : {
		"translit" : "psēlapháō",
		"lemma" : "ψηλαφάω",
		"kjv" : "feel after, handle, touch",
		"def" : "to manipulate, i.e. verify by contact; figuratively, to search for",
		"derivation" : "from the base of G5567 (ψάλλω) (compare G5586 (ψῆφος))"
	},
	"G1897" : {
		"derivation" : "from G1893 (ἐπεί) and G4007 (περ)",
		"def" : "since indeed (of cause)",
		"kjv" : "seeing",
		"translit" : "epeíper",
		"lemma" : "ἐπείπερ"
	},
	"G5528" : {
		"def" : "a \"court\" or \"garden\", i.e. (by implication, of pasture) herbage or vegetation",
		"derivation" : "apparently a primary word",
		"lemma" : "χόρτος",
		"translit" : "chórtos",
		"kjv" : "blade, grass, hay"
	},
	"G5457" : {
		"derivation" : "from an obsolete (to shine or make manifest, especially by rays",
		"def" : "compare G5316 (φαίνω), G5346 (φημί)); luminousness (in the widest application, natural or artificial, abstract or concrete, literal or figurative)",
		"kjv" : "fire, light",
		"lemma" : "φῶς",
		"translit" : "phōs"
	},
	"G4068" : {
		"derivation" : "middle voice from (braggart",
		"def" : "perhaps by reduplication of the base of G4008 (πέραν)); to boast",
		"kjv" : "vaunt itself",
		"lemma" : "περπερεύομαι",
		"translit" : "perpereúomai"
	},
	"G1552" : {
		"kjv" : "manifest",
		"translit" : "ékdēlos",
		"lemma" : "ἔκδηλος",
		"derivation" : "from G1537 (ἐκ) and G1212 (δῆλος)",
		"def" : "wholly evident"
	},
	"G5107" : {
		"kjv" : "such",
		"lemma" : "τοιόσδε",
		"translit" : "toiósde",
		"derivation" : "(including the other inflections); from a derivative of 5104 and G1161 (δέ)",
		"def" : "such-like then, i.e. so great"
	},
	"G4193" : {
		"def" : "a Pontican, i.e. native of Pontus",
		"derivation" : "from G4195 (Πόντος)",
		"translit" : "Pontikós",
		"lemma" : "Ποντικός",
		"kjv" : "born in Pontus"
	},
	"G4990" : {
		"kjv" : "saviour",
		"lemma" : "σωτήρ",
		"translit" : "sōtḗr",
		"derivation" : "from G4982 (σώζω)",
		"def" : "a deliverer, i.e. God or Christ"
	},
	"G4903" : {
		"kjv" : "help (work) with, work(-er) together",
		"lemma" : "συνεργέω",
		"translit" : "synergéō",
		"derivation" : "from G4904 (συνεργός)",
		"def" : "to be a fellow-worker, i.e. co-operate"
	},
	"G5304" : {
		"lemma" : "ὑστέρησις",
		"translit" : "hystérēsis",
		"kjv" : "want",
		"def" : "a falling short, i.e. (specially), penury",
		"derivation" : "from G5302 (ὑστερέω)"
	},
	"G4952" : {
		"def" : "to rend completely, i.e. (by analogy) to convulse violently",
		"derivation" : "from G4862 (σύν) and G4682 (σπαράσσω)",
		"lemma" : "συσπαράσσω",
		"translit" : "sysparássō",
		"kjv" : "throw down"
	},
	"G1052" : {
		"derivation" : "from G1053 (Γαλατία)",
		"def" : "a Galatian or inhabitant of Galatia",
		"kjv" : "Galatian",
		"translit" : "Galátēs",
		"lemma" : "Γαλάτης"
	},
	"G3088" : {
		"kjv" : "candle, light",
		"translit" : "lýchnos",
		"lemma" : "λύχνος",
		"derivation" : "from the base of G3022 (λευκός)",
		"def" : "a portable lamp or other illuminator (literally or figuratively)"
	},
	"G4221" : {
		"def" : "a drinking-vessel; by extension, the contents thereof, i.e. a cupful (draught); figuratively, a lot or fate",
		"derivation" : "neuter of a derivative of the alternate of G4095 (πίνω)",
		"translit" : "potḗrion",
		"lemma" : "ποτήριον",
		"kjv" : "cup"
	},
	"G935" : {
		"derivation" : "probably from G939 (βάσις) (through the notion of a foundation of power)",
		"def" : "a sovereign (abstractly, relatively, or figuratively)",
		"kjv" : "king",
		"translit" : "basileús",
		"lemma" : "βασιλεύς"
	},
	"G650" : {
		"translit" : "aposteréō",
		"lemma" : "ἀποστερέω",
		"kjv" : "defraud, destitute, kept back by fraud",
		"def" : "to despoil",
		"derivation" : "from G575 (ἀπό) and (to deprive)"
	},
	"G922" : {
		"translit" : "báros",
		"lemma" : "βάρος",
		"kjv" : "burden(-some), weight",
		"def" : "compare G899 (βάθος)); weight; in the New Testament only, figuratively, a load, abundance, authority",
		"derivation" : "probably from the same as G939 (βάσις) (through the notion of going down"
	},
	"G5019" : {
		"lemma" : "Ταρσός",
		"translit" : "Tarsós",
		"kjv" : "Tarsus",
		"def" : "Tarsus, a place in Asia Minor",
		"derivation" : "perhaps the same as (a flat basket)"
	},
	"G4346" : {
		"def" : "a leaning towards, i.e. (figuratively) proclivity (favoritism)",
		"derivation" : "from a compound of G4314 (πρός) and G2827 (κλίνω)",
		"translit" : "prósklisis",
		"lemma" : "πρόσκλισις",
		"kjv" : "partiality"
	},
	"G3969" : {
		"translit" : "Patrobâs",
		"lemma" : "Πατροβᾶς",
		"kjv" : "Patrobas",
		"def" : "father's life; Patrobas, a Christian",
		"derivation" : "perhaps a contraction for (a compound of G3962 (πατήρ) and G979 (βίος))"
	},
	"G678" : {
		"translit" : "aprosōpolḗptōs",
		"lemma" : "ἀπροσωπολήπτως",
		"kjv" : "without respect of persons",
		"def" : "in a way not accepting the person, i.e. impartially",
		"derivation" : "adverb from a compound of G1 (Α) (as a negative particle) and a presumed derivative of a presumed compound of G4383 (πρόσωπον) and G2983 (λαμβάνω) (compare G4381 (προσωπολήπτης))"
	},
	"G3441" : {
		"def" : "remaining, i.e. sole or single; by implication, mere",
		"derivation" : "probably from G3306 (μένω)",
		"lemma" : "μόνος",
		"translit" : "mónos",
		"kjv" : "alone, only, by themselves"
	},
	"G491" : {
		"derivation" : "from G490 (Ἀντιόχεια)",
		"def" : "an Antiochian or inhabitant of Antiochia",
		"kjv" : "of Antioch",
		"translit" : "Antiocheús",
		"lemma" : "Ἀντιοχεύς"
	},
	"G1134" : {
		"derivation" : "from G1135 (γυνή)",
		"def" : "feminine",
		"kjv" : "wife",
		"translit" : "gynaikeîos",
		"lemma" : "γυναικεῖος"
	},
	"G1727" : {
		"kjv" : "(over) against, contrary",
		"lemma" : "ἐναντίος",
		"translit" : "enantíos",
		"derivation" : "from G1725 (ἔναντι)",
		"def" : "opposite; figuratively, antagonistic"
	},
	"G1917" : {
		"def" : "a plan against someone, i.e. a plot",
		"derivation" : "from a presumed compound of G1909 (ἐπί) and G1014 (βούλομαι)",
		"lemma" : "ἐπιβουλή",
		"translit" : "epiboulḗ",
		"kjv" : "laying (lying) in wait"
	},
	"G2825" : {
		"translit" : "klínē",
		"lemma" : "κλίνη",
		"kjv" : "bed, table",
		"def" : "a couch (for sleep, sickness, sitting or eating)",
		"derivation" : "from G2827 (κλίνω)"
	},
	"G3459" : {
		"def" : "a mill-house",
		"derivation" : "from G3458 (μύλος)",
		"lemma" : "μύλων",
		"translit" : "mýlōn",
		"kjv" : "mill"
	},
	"G2874" : {
		"derivation" : "from (ordure",
		"def" : "perhaps akin to G2875 (κόπτω)); manure",
		"kjv" : "dung(-hill)",
		"translit" : "kopría",
		"lemma" : "κοπρία"
	},
	"G2569" : {
		"def" : "to do well, i.e. live virtuously",
		"derivation" : "from G2570 (καλός) and G4160 (ποιέω)",
		"lemma" : "καλοποιέω",
		"translit" : "kalopoiéō",
		"kjv" : "well doing"
	},
	"G5331" : {
		"def" : "medication (\"pharmacy\"), i.e. (by extension) magic (literally or figuratively)",
		"derivation" : "from G5332 (φαρμακεύς)",
		"translit" : "pharmakeía",
		"lemma" : "φαρμακεία",
		"kjv" : "sorcery, witchcraft"
	},
	"G2208" : {
		"kjv" : "Zelotes",
		"translit" : "Zēlōtḗs",
		"lemma" : "Ζηλωτής",
		"derivation" : "the same as G2207 (ζηλωτής)",
		"def" : "a Zealot, i.e. (specially) partisan for Jewish political independence"
	},
	"G498" : {
		"lemma" : "ἀντιτάσσομαι",
		"translit" : "antitássomai",
		"kjv" : "oppose themselves, resist",
		"def" : "to range oneself against, i.e. oppose",
		"derivation" : "from G473 (ἀντί) and the middle voice of G5021 (τάσσω)"
	},
	"G2381" : {
		"def" : "the twin; Thomas, a Christian",
		"derivation" : "of Chaldee origin (compare H8380)",
		"lemma" : "Θωμᾶς",
		"translit" : "Thōmâs",
		"kjv" : "Thomas"
	},
	"G715" : {
		"def" : "a bear (as obstructing by ferocity)",
		"derivation" : "probably from G714 (ἀρκέω)",
		"translit" : "árktos",
		"lemma" : "ἄρκτος",
		"kjv" : "bear"
	},
	"G2327" : {
		"kjv" : "reaper",
		"lemma" : "θεριστής",
		"translit" : "theristḗs",
		"derivation" : "from G2325 (θερίζω)",
		"def" : "a harvester"
	},
	"G2378" : {
		"def" : "sacrifice (the act or the victim, literally or figuratively)",
		"derivation" : "from G2380 (θύω)",
		"translit" : "thysía",
		"lemma" : "θυσία",
		"kjv" : "sacrifice"
	},
	"G1124" : {
		"derivation" : "from G1125 (γράφω)",
		"def" : "a document, i.e. holy Writ (or its contents or a statement in it)",
		"kjv" : "scripture",
		"lemma" : "γραφή",
		"translit" : "graphḗ"
	},
	"G734" : {
		"lemma" : "Ἀρτεμάς",
		"translit" : "Artemás",
		"kjv" : "Artemas",
		"def" : "gift of Artemis; Artemas (or Artemidorus), a Christian",
		"derivation" : "contracted from a compound of G735 (Ἄρτεμις) and G1435 (δῶρον)"
	},
	"G5086" : {
		"def" : "probably pertaining to the river Tiberis or Tiber; Tiberius, a Roman emperor",
		"derivation" : "of Latin origin",
		"translit" : "Tibérios",
		"lemma" : "Τιβέριος",
		"kjv" : "Tiberius"
	},
	"G3101" : {
		"kjv" : "disciple",
		"translit" : "mathētḗs",
		"lemma" : "μαθητής",
		"derivation" : "from G3129 (μανθάνω)",
		"def" : "a learner, i.e. pupil"
	},
	"G3163" : {
		"kjv" : "fighting, strive, striving",
		"translit" : "máchē",
		"lemma" : "μάχη",
		"derivation" : "from G3164 (μάχομαι)",
		"def" : "a battle, i.e. (figuratively) controversy"
	},
	"G191" : {
		"kjv" : "give (in the) audience (of), come (to the ears), (shall) hear(-er, -ken), be noised, be reported, understand",
		"lemma" : "ἀκούω",
		"translit" : "akoúō",
		"derivation" : "a primary verb",
		"def" : "to hear (in various senses)"
	},
	"G5468" : {
		"def" : "to be a bit-leader, i.e. to curb (figuratively)",
		"derivation" : "from a compound of G5469 (χαλινός) and the reduplicated form of G71 (ἄγω)",
		"translit" : "chalinagōgéō",
		"lemma" : "χαλιναγωγέω",
		"kjv" : "bridle"
	},
	"G3936" : {
		"derivation" : "from G3844 (παρά) and G2476 (ἵστημι)",
		"def" : "to stand beside, i.e. (transitively) to exhibit, proffer, (specially), recommend, (figuratively) substantiate; or (intransitively) to be at hand (or ready), aid",
		"kjv" : "assist, bring before, command, commend, give presently, present, prove, provide, shew, stand (before, by, here, up, with), yield",
		"translit" : "parístēmi",
		"lemma" : "παρίστημι"
	},
	"G5330" : {
		"derivation" : "of Hebrew origin (compare H6567)",
		"def" : "a separatist, i.e. exclusively religious; a Pharisean, i.e. Jewish sectary",
		"kjv" : "Pharisee",
		"lemma" : "Φαρισαῖος",
		"translit" : "Pharisaîos"
	},
	"G2439" : {
		"kjv" : "clothe",
		"lemma" : "ἱματίζω",
		"translit" : "himatízō",
		"derivation" : "from G2440 (ἱμάτιον)",
		"def" : "to dress"
	},
	"G2649" : {
		"lemma" : "καταμαρτυρέω",
		"translit" : "katamartyréō",
		"kjv" : "witness against",
		"def" : "to testify against",
		"derivation" : "from G2596 (κατά) and G3140 (μαρτυρέω)"
	},
	"G2301" : {
		"def" : "to expose as a spectacle",
		"derivation" : "from G2302 (θέατρον)",
		"lemma" : "θεατρίζω",
		"translit" : "theatrízō",
		"kjv" : "make a gazing stock"
	},
	"G2222" : {
		"kjv" : "life(-time)",
		"translit" : "zōḗ",
		"lemma" : "ζωή",
		"derivation" : "from G2198 (ζάω)",
		"def" : "life (literally or figuratively)"
	},
	"G4845" : {
		"kjv" : "(fully) come, fill up",
		"translit" : "symplēróō",
		"lemma" : "συμπληρόω",
		"derivation" : "from G4862 (σύν) and G4137 (πληρόω)",
		"def" : "to implenish completely, i.e. (of space) to swamp (a boat), or (of time) to accomplish (passive, be complete)"
	},
	"G5174" : {
		"kjv" : "Troas",
		"lemma" : "Τρωάς",
		"translit" : "Trōás",
		"derivation" : "from (a Trojan)",
		"def" : "the Troad (or plain of Troy), i.e. Troas, a place in Asia Minor"
	},
	"G1746" : {
		"def" : "to invest with clothing (literally or figuratively)",
		"derivation" : "from G1722 (ἐν) and G1416 (δύνω) (in the sense of sinking into a garment)",
		"lemma" : "ἐνδύω",
		"translit" : "endýō",
		"kjv" : "array, clothe (with), endue, have (put) on"
	},
	"G844" : {
		"lemma" : "αὐτόματος",
		"translit" : "autómatos",
		"kjv" : "of own accord, of self",
		"def" : "self-moved (\"automatic\"), i.e. spontaneous",
		"derivation" : "from G846 (αὐτός) and the same as G3155 (μάτην)"
	},
	"G26" : {
		"def" : "love, i.e. affection or benevolence; specially (plural) a love-feast",
		"derivation" : "from G25 (ἀγαπάω)",
		"translit" : "agápē",
		"lemma" : "ἀγάπη",
		"kjv" : "(feast of) charity(-ably), dear, love"
	},
	"G1630" : {
		"lemma" : "ἔκφοβος",
		"translit" : "ékphobos",
		"kjv" : "sore afraid, exceedingly fear",
		"def" : "frightened out of one's wits",
		"derivation" : "from G1537 (ἐκ) and G5401 (φόβος)"
	},
	"G2654" : {
		"derivation" : "from G2596 (κατά) and G355 (ἀναλίσκω)",
		"def" : "to consume utterly",
		"kjv" : "consume",
		"lemma" : "καταναλίσκω",
		"translit" : "katanalískō"
	},
	"G5420" : {
		"def" : "to fence or inclose, i.e. (specially), to block up (figuratively, to silence)",
		"derivation" : "apparently a strengthening form of the base of G5424 (φρήν)",
		"lemma" : "φράσσω",
		"translit" : "phrássō",
		"kjv" : "stop"
	},
	"G3087" : {
		"def" : "a lamp-stand (literally or figuratively)",
		"derivation" : "from G3088 (λύχνος)",
		"lemma" : "λυχνία",
		"translit" : "lychnía",
		"kjv" : "candlestick"
	},
	"G4589" : {
		"derivation" : "of Hebrew origin (H8352)",
		"def" : "Seth (i.e. Sheth), a patriarch",
		"kjv" : "Seth",
		"translit" : "Sḗth",
		"lemma" : "Σήθ"
	},
	"G1964" : {
		"derivation" : "from G1965 (ἐπίορκος)",
		"def" : "to commit perjury",
		"kjv" : "forswear self",
		"lemma" : "ἐπιορκέω",
		"translit" : "epiorkéō"
	},
	"G4959" : {
		"derivation" : "from G4862 (σύν) and G4727 (στενάζω)",
		"def" : "to moan jointly, i.e. (figuratively) experience a common calamity",
		"kjv" : "groan together",
		"translit" : "systenázō",
		"lemma" : "συστενάζω"
	},
	"G1088" : {
		"def" : "aged",
		"derivation" : "of uncertain affinity (compare G1094 (γῆρας))",
		"translit" : "gérōn",
		"lemma" : "γέρων",
		"kjv" : "old"
	},
	"G5356" : {
		"derivation" : "from G5351 (φθείρω)",
		"def" : "decay, i.e. ruin (spontaneous or inflicted, literally or figuratively)",
		"kjv" : "corruption, destroy, perish",
		"lemma" : "φθορά",
		"translit" : "phthorá"
	},
	"G3349" : {
		"kjv" : "be of doubtful mind",
		"lemma" : "μετεωρίζω",
		"translit" : "meteōrízō",
		"derivation" : "from a compound of G3326 (μετά) and a collateral form of G142 (αἴρω) or perhaps rather G109 (ἀήρ) (compare \"meteor\")",
		"def" : "to raise in mid-air, i.e. (figuratively) suspend (passively, fluctuate or be anxious)"
	},
	"G1154" : {
		"kjv" : "Damascus",
		"lemma" : "Δαμασκός",
		"translit" : "Damaskós",
		"derivation" : "of Hebrew origin (H1834)",
		"def" : "Damascus, a city of Syria"
	},
	"G1408" : {
		"derivation" : "from the alternate of G5143 (τρέχω)",
		"def" : "a race, i.e. (figuratively) career",
		"kjv" : "course",
		"translit" : "drómos",
		"lemma" : "δρόμος"
	},
	"G3824" : {
		"translit" : "palingenesía",
		"lemma" : "παλιγγενεσία",
		"kjv" : "regeneration",
		"def" : "(spiritual) rebirth (the state or the act), i.e. (figuratively) spiritual renovation; specially, Messianic restoration",
		"derivation" : "from G3825 (πάλιν) and G1078 (γένεσις)"
	},
	"G2737" : {
		"lemma" : "κατώτερος",
		"translit" : "katṓteros",
		"kjv" : "lower",
		"def" : "inferior (locally, of Hades)",
		"derivation" : "comparative from G2736 (κάτω)"
	},
	"G943" : {
		"def" : "a bath, or measure for liquids",
		"derivation" : "of Hebrew origin (H1324)",
		"lemma" : "βάτος",
		"translit" : "bátos",
		"kjv" : "measure"
	},
	"G3035" : {
		"kjv" : "of stone",
		"translit" : "líthinos",
		"lemma" : "λίθινος",
		"derivation" : "from G3037 (λίθος)",
		"def" : "stony, i.e. made of stone"
	},
	"G939" : {
		"derivation" : "from (to walk)",
		"def" : "a pace (\"base\"), i.e. (by implication) the foot",
		"kjv" : "foot",
		"translit" : "básis",
		"lemma" : "βάσις"
	},
	"G2987" : {
		"lemma" : "λαμπρότης",
		"translit" : "lamprótēs",
		"kjv" : "brightness",
		"def" : "brilliancy",
		"derivation" : "from G2986 (λαμπρός)"
	},
	"G1743" : {
		"def" : "to empower",
		"derivation" : "from G1722 (ἐν) and G1412 (δυναμόω)",
		"translit" : "endynamóō",
		"lemma" : "ἐνδυναμόω",
		"kjv" : "enable, (increase in) strength(-en), be (make) strong"
	},
	"G1361" : {
		"def" : "Jove-nourished; Diotrephes, an opponent of Christianity",
		"derivation" : "from the alternate of G2203 (Ζεύς) and G5142 (τρέφω)",
		"lemma" : "Διοτρεφής",
		"translit" : "Diotrephḗs",
		"kjv" : "Diotrephes"
	},
	"G2149" : {
		"kjv" : "broad",
		"lemma" : "εὐρύχωρος",
		"translit" : "eurýchōros",
		"derivation" : "from (wide) and G5561 (χώρα)",
		"def" : "spacious"
	},
	"G230" : {
		"def" : "truly",
		"derivation" : "adverb from G227 (ἀληθής)",
		"translit" : "alēthōs",
		"lemma" : "ἀληθῶς",
		"kjv" : "indeed, surely, of a surety, truly, of a (in) truth, verily, very"
	},
	"G4953" : {
		"def" : "a sign in common, i.e. preconcerted signal",
		"derivation" : "neuter of a compound of G4862 (σύν) and the base of G4591 (σημαίνω)",
		"translit" : "sýssēmon",
		"lemma" : "σύσσημον",
		"kjv" : "token"
	},
	"G61" : {
		"lemma" : "ἄγρα",
		"translit" : "ágra",
		"kjv" : "draught",
		"def" : "(abstractly) a catching (of fish); also (concretely) a haul (of fish)",
		"derivation" : "from G71 (ἄγω)"
	},
	"G4829" : {
		"translit" : "symmerízomai",
		"lemma" : "συμμερίζομαι",
		"kjv" : "be partaker with",
		"def" : "to share jointly, i.e. participate in",
		"derivation" : "middle voice from G4862 (σύν) and G3307 (μερίζω)"
	},
	"G954" : {
		"def" : "dung-god; Beelzebul, a name of Satan",
		"derivation" : "of Chaldee origin (by parody on H1176)",
		"translit" : "Beelzeboúl",
		"lemma" : "Βεελζεβούλ",
		"kjv" : "Beelzebub"
	},
	"G2946" : {
		"derivation" : "from G2947 (κυλιόω)",
		"def" : "a wallow (the effect of rolling), i.e. filth",
		"kjv" : "wallowing",
		"translit" : "kýlisma",
		"lemma" : "κύλισμα"
	},
	"G5146" : {
		"kjv" : "brier, thistle",
		"lemma" : "τρίβολος",
		"translit" : "tríbolos",
		"derivation" : "from G5140 (τρεῖς) and G956 (βέλος)",
		"def" : "properly, a crow-foot (three-pronged obstruction in war), i.e. (by analogy) a thorny plant (caltrop)"
	},
	"G4167" : {
		"translit" : "poímnē",
		"lemma" : "ποίμνη",
		"kjv" : "flock, fold",
		"def" : "a flock (literally or figuratively)",
		"derivation" : "contraction from G4165 (ποιμαίνω)"
	},
	"G5063" : {
		"kjv" : "(+ full, of) forty years (old)",
		"translit" : "tessarakontaetḗs",
		"lemma" : "τεσσαρακονταετής",
		"derivation" : "from G5062 (τεσσαράκοντα) and G2094 (ἔτος)",
		"def" : "of forty years of age"
	},
	"G4288" : {
		"derivation" : "from G4289 (πρόθυμος)",
		"def" : "predisposition, i.e. alacrity",
		"kjv" : "forwardness of mind, readiness (of mind), ready (willing) mind",
		"translit" : "prothymía",
		"lemma" : "προθυμία"
	},
	"G1589" : {
		"derivation" : "from G1586 (ἐκλέγομαι)",
		"def" : "(divine) selection (abstractly or concretely)",
		"kjv" : "chosen, election",
		"lemma" : "ἐκλογή",
		"translit" : "eklogḗ"
	},
	"G710" : {
		"derivation" : "apparently a comparative of the same as G712 (ἄριστον)",
		"def" : "the left hand (as second-best)",
		"kjv" : "left (hand)",
		"translit" : "aristerós",
		"lemma" : "ἀριστερός"
	},
	"G4909" : {
		"kjv" : "allow, assent, be pleased, have pleasure",
		"translit" : "syneudokéō",
		"lemma" : "συνευδοκέω",
		"derivation" : "from G4862 (σύν) and G2106 (εὐδοκέω)",
		"def" : "to think well of in common, i.e. assent to, feel gratified with"
	},
	"G4376" : {
		"kjv" : "offering (up)",
		"lemma" : "προσφορά",
		"translit" : "prosphorá",
		"derivation" : "from G4374 (προσφέρω)",
		"def" : "presentation; concretely, an oblation (bloodless) or sacrifice"
	},
	"G5519" : {
		"kjv" : "swine",
		"lemma" : "χοῖρος",
		"translit" : "choîros",
		"derivation" : "of uncertain derivation",
		"def" : "a hog"
	},
	"G2180" : {
		"derivation" : "from G2181 (Ἔφεσος)",
		"def" : "an Ephesian or inhabitant of Ephesus",
		"kjv" : "Ephesian, of Ephesus",
		"lemma" : "Ἐφέσιος",
		"translit" : "Ephésios"
	},
	"G4449" : {
		"kjv" : "be red",
		"translit" : "pyrrházō",
		"lemma" : "πυῤῥάζω",
		"derivation" : "from G4450 (πυῤῥός)",
		"def" : "to redden (intransitively)"
	},
	"G2136" : {
		"derivation" : "from the same as G2137 (εὐοδόω)",
		"def" : "fine travelling; Euodia, a Christian woman",
		"kjv" : "Euodias",
		"translit" : "Euodía",
		"lemma" : "Εὐοδία"
	},
	"G4512" : {
		"translit" : "rhytís",
		"lemma" : "ῥυτίς",
		"kjv" : "wrinkle",
		"def" : "a fold (as drawing together), i.e. a wrinkle (especially on the face)",
		"derivation" : "from G4506 (ῥύομαι)"
	},
	"G3193" : {
		"def" : "relating to honey, i.e. bee (comb)",
		"derivation" : "from G3192 (μέλι)",
		"translit" : "melíssios",
		"lemma" : "μελίσσιος",
		"kjv" : "honeycomb"
	},
	"G1566" : {
		"derivation" : "from G1563 (ἐκεῖ)",
		"def" : "thither",
		"kjv" : "there",
		"lemma" : "ἐκεῖσε",
		"translit" : "ekeîse"
	},
	"G401" : {
		"lemma" : "ἀνάχυσις",
		"translit" : "anáchysis",
		"kjv" : "excess",
		"def" : "properly, effusion, i.e. (figuratively) license",
		"derivation" : "from a comparative of G303 (ἀνά) and (to pour)"
	},
	"G5274" : {
		"def" : "to take from below, i.e. carry upward; figuratively, to take up, i.e. continue a discourse or topic; mentally, to assume (presume)",
		"derivation" : "from G5259 (ὑπό) and G2983 (λαμβάνω)",
		"lemma" : "ὑπολαμβάνω",
		"translit" : "hypolambánō",
		"kjv" : "answer, receive, suppose"
	},
	"G2518" : {
		"derivation" : "from G2596 (κατά) and (to sleep)",
		"def" : "to lie down to rest, i.e. (by implication) to fall asleep (literally or figuratively)",
		"kjv" : "(be a-)sleep",
		"lemma" : "καθεύδω",
		"translit" : "katheúdō"
	},
	"G2861" : {
		"def" : "a diving-place, i.e. pond for bathing (or swimming)",
		"derivation" : "from G2860 (κολυμβάω)",
		"lemma" : "κολυμβήθρα",
		"translit" : "kolymbḗthra",
		"kjv" : "pool"
	},
	"G1152" : {
		"kjv" : "Damaris",
		"translit" : "Dámaris",
		"lemma" : "Δάμαρις",
		"derivation" : "probably from the base of G1150 (δαμάζω)",
		"def" : "perhaps gentle; Damaris, an Athenian woman"
	},
	"G291" : {
		"lemma" : "Ἀμπλίας",
		"translit" : "Amplías",
		"kjv" : "Amplias",
		"def" : "Amplias, a Roman Christian",
		"derivation" : "contracted for Latin ampliatus (enlarged)"
	},
	"G4696" : {
		"kjv" : "spot",
		"lemma" : "σπίλος",
		"translit" : "spílos",
		"derivation" : "of uncertain derivation",
		"def" : "a stain or blemish, i.e. (figuratively) defect, disgrace"
	},
	"G3488" : {
		"translit" : "Nárkissos",
		"lemma" : "Νάρκισσος",
		"kjv" : "Narcissus",
		"def" : "Narcissus, a Roman",
		"derivation" : "a flower of the same name, from (stupefaction, as a \"narcotic\")"
	},
	"G2509" : {
		"def" : "exactly as",
		"derivation" : "from G2505 (καθά) and G4007 (περ)",
		"lemma" : "καθάπερ",
		"translit" : "katháper",
		"kjv" : "(even, as well) as"
	},
	"G2409" : {
		"derivation" : "from G2413 (ἱερός)",
		"def" : "a priest (literally or figuratively)",
		"kjv" : "(high) priest",
		"translit" : "hiereús",
		"lemma" : "ἱερεύς"
	},
	"G2087" : {
		"kjv" : "altered, else, next (day), one, (an-)other, some, strange",
		"translit" : "héteros",
		"lemma" : "ἕτερος",
		"derivation" : "of uncertain affinity",
		"def" : "(an-, the) other or different"
	},
	"G4695" : {
		"def" : "to stain or soil (literally or figuratively)",
		"derivation" : "from G4696 (σπίλος)",
		"lemma" : "σπιλόω",
		"translit" : "spilóō",
		"kjv" : "defile, spot"
	},
	"G4497" : {
		"lemma" : "Ῥοβοάμ",
		"translit" : "Rhoboám",
		"kjv" : "Roboam",
		"def" : "Roboam (i.e. Rechobam), an Israelite",
		"derivation" : "of Hebrew origin (H7346)"
	},
	"G3573" : {
		"kjv" : "slumber",
		"translit" : "nystázō",
		"lemma" : "νυστάζω",
		"derivation" : "from a presumed derivative of G3506 (νεύω)",
		"def" : "to nod, i.e. (by implication) to fall asleep; figuratively, to delay"
	},
	"G1895" : {
		"kjv" : "forasmuch",
		"translit" : "epeidḗper",
		"lemma" : "ἐπειδήπερ",
		"derivation" : "from G1894 (ἐπειδή) and G4007 (περ)",
		"def" : "since indeed (of cause)"
	},
	"G1009" : {
		"translit" : "bótrys",
		"lemma" : "βότρυς",
		"kjv" : "(vine) cluster (of the vine)",
		"def" : "a bunch (of grapes)",
		"derivation" : "of uncertain derivation"
	},
	"G1325" : {
		"lemma" : "δίδωμι",
		"translit" : "dídōmi",
		"kjv" : "adventure, bestow, bring forth, commit, deliver (up), give, grant, hinder, make, minister, number, offer, have power, put, receive, set, shew, smite (+ with the hand), strike (+ with the palm of the hand), suffer, take, utter, yield",
		"def" : "to give (used in a very wide application, properly, or by implication, literally or figuratively; greatly modified by the connection)",
		"derivation" : "a prolonged form of a primary verb (which is used as an alternative in most of the tenses)"
	},
	"G4363" : {
		"kjv" : "beat upon, fall (down) at (before)",
		"translit" : "prospíptō",
		"lemma" : "προσπίπτω",
		"derivation" : "from G4314 (πρός) and G4098 (πίπτω)",
		"def" : "to fall towards, i.e. (gently) prostrate oneself (in supplication or homage), or (violently) to rush upon (in storm)"
	},
	"G4937" : {
		"def" : "to crush completely, i.e. to shatter (literally or figuratively)",
		"derivation" : "from G4862 (σύν) and the base of G5147 (τρίβος)",
		"lemma" : "συντρίβω",
		"translit" : "syntríbō",
		"kjv" : "break (in pieces), broken to shivers (+ -hearted), bruise"
	},
	"G5196" : {
		"derivation" : "from G5228 (ὑπέρ)",
		"def" : "insolence (as over-bearing), i.e. insult, injury",
		"kjv" : "harm, hurt, reproach",
		"translit" : "hýbris",
		"lemma" : "ὕβρις"
	},
	"G3711" : {
		"def" : "irascible",
		"derivation" : "from G3709 (ὀργή)",
		"translit" : "orgílos",
		"lemma" : "ὀργίλος",
		"kjv" : "soon angry"
	},
	"G45" : {
		"kjv" : "anchor",
		"lemma" : "ἄγκυρα",
		"translit" : "ánkyra",
		"derivation" : "from the same as G43 (ἀγκάλη)",
		"def" : "an \"anchor\" (as crooked)"
	},
	"G2338" : {
		"def" : "female",
		"derivation" : "from the same as G2337 (θηλάζω)",
		"translit" : "thēlys",
		"lemma" : "θῆλυς",
		"kjv" : "female, woman"
	},
	"G5308" : {
		"kjv" : "high(-er, -ly) (esteemed)",
		"lemma" : "ὑψηλός",
		"translit" : "hypsēlós",
		"derivation" : "from G5311 (ὕψος)",
		"def" : "lofty (in place or character)"
	},
	"G4929" : {
		"derivation" : "from G4862 (σύν) and G5021 (τάσσω)",
		"def" : "to arrange jointly, i.e. (figuratively) to direct",
		"kjv" : "appoint",
		"translit" : "syntássō",
		"lemma" : "συντάσσω"
	},
	"G3878" : {
		"translit" : "parakoúō",
		"lemma" : "παρακούω",
		"kjv" : "neglect to hear",
		"def" : "to mishear, i.e. (by implication) to disobey",
		"derivation" : "from G3844 (παρά) and G191 (ἀκούω)"
	},
	"G1800" : {
		"derivation" : "of Hebrew origin (H583)",
		"def" : "Enos (i.e. Enosh), a patriarch",
		"kjv" : "Enos",
		"translit" : "Enṓs",
		"lemma" : "Ἐνώς"
	},
	"G2207" : {
		"translit" : "zēlōtḗs",
		"lemma" : "ζηλωτής",
		"kjv" : "zealous",
		"def" : "a \"zealot\"",
		"derivation" : "from G2206 (ζηλόω)"
	},
	"G5144" : {
		"def" : "thirty",
		"derivation" : "the decade of G5140 (τρεῖς)",
		"lemma" : "τριάκοντα",
		"translit" : "triákonta",
		"kjv" : "thirty"
	},
	"G2944" : {
		"def" : "to encircle, i.e. surround",
		"derivation" : "from the same as G2945 (κύκλῳ)",
		"lemma" : "κυκλόω",
		"translit" : "kyklóō",
		"kjv" : "compass (about), come (stand) round about"
	},
	"G4126" : {
		"kjv" : "sail",
		"translit" : "pléō",
		"lemma" : "πλέω",
		"derivation" : "probably a form of G4150 (πλύνω) (through the idea of plunging through the water)",
		"def" : "to pass in a vessel"
	},
	"G5407" : {
		"kjv" : "kill, do murder, slay",
		"translit" : "phoneúō",
		"lemma" : "φονεύω",
		"derivation" : "from G5406 (φονεύς)",
		"def" : "to be a murderer (of)"
	},
	"G1104" : {
		"def" : "genuinely, i.e. really",
		"derivation" : "adverb from G1103 (γνήσιος)",
		"translit" : "gnēsíōs",
		"lemma" : "γνησίως",
		"kjv" : "naturally"
	},
	"G4508" : {
		"derivation" : "from G4509 (ῥύπος)",
		"def" : "dirty, i.e. (relatively) cheap or shabby; morally, wicked",
		"kjv" : "vile",
		"translit" : "rhyparós",
		"lemma" : "ῥυπαρός"
	},
	"G5454" : {
		"translit" : "phōleós",
		"lemma" : "φωλεός",
		"kjv" : "hole",
		"def" : "a burrow or lurking-place",
		"derivation" : "of uncertain derivative"
	},
	"G1036" : {
		"derivation" : "from G1037 (βυθός)",
		"def" : "to sink; by implication, to drown",
		"kjv" : "begin to sink, drown",
		"lemma" : "βυθίζω",
		"translit" : "bythízō"
	},
	"G1262" : {
		"def" : "to dissolve utterly",
		"derivation" : "from G1223 (διά) and G3089 (λύω)",
		"translit" : "dialýō",
		"lemma" : "διαλύω",
		"kjv" : "scatter"
	},
	"G1871" : {
		"translit" : "epaitéō",
		"lemma" : "ἐπαιτέω",
		"kjv" : "beg",
		"def" : "to ask for",
		"derivation" : "from G1909 (ἐπί) and G154 (αἰτέω)"
	},
	"G974" : {
		"def" : "a booklet",
		"derivation" : "a diminutive of G975 (βιβλίον)",
		"lemma" : "βιβλιαρίδιον",
		"translit" : "bibliarídion",
		"kjv" : "little book"
	},
	"G5534" : {
		"kjv" : "ought",
		"translit" : "chrḗ",
		"lemma" : "χρή",
		"derivation" : "third person singular of the same as G5530 (χράομαι) or G5531 (χράω) used impersonally",
		"def" : "it needs (must or should) be"
	},
	"G4964" : {
		"derivation" : "from G4862 (σύν) and a derivative of G4976 (σχῆμα)",
		"def" : "to fashion alike, i.e. conform to the same pattern (figuratively)",
		"kjv" : "conform to, fashion self according to",
		"lemma" : "συσχηματίζω",
		"translit" : "syschēmatízō"
	},
	"G5480" : {
		"derivation" : "from the same as G5482 (χάραξ)",
		"def" : "a scratch or etching, i.e. stamp (as a badge of servitude), or scupltured figure (statue)",
		"kjv" : "graven, mark",
		"lemma" : "χάραγμα",
		"translit" : "cháragma"
	},
	"G4656" : {
		"def" : "to obscure or blind (literally or figuratively)",
		"derivation" : "from G4655 (σκότος)",
		"translit" : "skotóō",
		"lemma" : "σκοτόω",
		"kjv" : "be full of darkness"
	},
	"G4427" : {
		"def" : "saliva",
		"derivation" : "from G4429 (πτύω)",
		"translit" : "ptýsma",
		"lemma" : "πτύσμα",
		"kjv" : "spittle"
	},
	"G1611" : {
		"kjv" : "+ be amazed, amazement, astonishment, trance",
		"translit" : "ékstasis",
		"lemma" : "ἔκστασις",
		"derivation" : "from G1839 (ἐξίστημι)",
		"def" : "a displacement of the mind, i.e. bewilderment, \"ecstasy\""
	},
	"G975" : {
		"translit" : "biblíon",
		"lemma" : "βιβλίον",
		"kjv" : "bill, book, scroll, writing",
		"def" : "a roll",
		"derivation" : "a diminutive of G976 (βίβλος)"
	},
	"G238" : {
		"kjv" : "be an allegory (the Greek word itself)",
		"translit" : "allēgoréō",
		"lemma" : "ἀλληγορέω",
		"derivation" : "from G243 (ἄλλος) and (to harangue (compare G58 (ἀγορά)))",
		"def" : "to allegorize"
	},
	"G1921" : {
		"kjv" : "(ac-, have, take)know(-ledge, well), perceive",
		"translit" : "epiginṓskō",
		"lemma" : "ἐπιγινώσκω",
		"derivation" : "from G1909 (ἐπί) and G1097 (γινώσκω)",
		"def" : "to know upon some mark, i.e. recognize; by implication, to become fully acquainted with, to acknowledge"
	},
	"G4913" : {
		"lemma" : "συνήδομαι",
		"translit" : "synḗdomai",
		"kjv" : "delight",
		"def" : "to rejoice in with oneself, i.e. feel satisfaction concerning",
		"derivation" : "middle voice from G4862 (σύν) and the base of G2237 (ἡδονή)"
	},
	"G4300" : {
		"derivation" : "from G4253 (πρό) and G2964 (κυρόω)",
		"def" : "to ratify previously",
		"kjv" : "confirm before",
		"translit" : "prokyróō",
		"lemma" : "προκυρόω"
	},
	"G2543" : {
		"kjv" : "although",
		"lemma" : "καίτοι",
		"translit" : "kaítoi",
		"derivation" : "from G2532 (καί) and G5104 (τοί)",
		"def" : "and yet, i.e. nevertheless"
	},
	"G1283" : {
		"def" : "to seize asunder, i.e. plunder",
		"derivation" : "from G1223 (διά) and G726 (ἁρπάζω)",
		"lemma" : "διαρπάζω",
		"translit" : "diarpázō",
		"kjv" : "spoil"
	},
	"G4532" : {
		"translit" : "Salḗm",
		"lemma" : "Σαλήμ",
		"kjv" : "Salem",
		"def" : "Salem (i.e. Shalem), a place in Palestine",
		"derivation" : "of Hebrew origin (H8004)"
	},
	"G4984" : {
		"derivation" : "from G4983 (σῶμα)",
		"def" : "corporeal or physical",
		"kjv" : "bodily",
		"lemma" : "σωματικός",
		"translit" : "sōmatikós"
	},
	"G561" : {
		"def" : "from in front, i.e. opposite, before or against",
		"derivation" : "from G575 (ἀπό) and G1725 (ἔναντι)",
		"translit" : "apénanti",
		"lemma" : "ἀπέναντι",
		"kjv" : "before, contrary, over against, in the presence of"
	},
	"G831" : {
		"kjv" : "usurp authority over",
		"lemma" : "αὐθεντέω",
		"translit" : "authentéō",
		"derivation" : "from a compound of G846 (αὐτός) and an obsolete (a worker)",
		"def" : "to act of oneself, i.e. (figuratively) dominate"
	},
	"G3870" : {
		"def" : "to call near, i.e. invite, invoke (by imploration, hortation or consolation)",
		"derivation" : "from G3844 (παρά) and G2564 (καλέω)",
		"translit" : "parakaléō",
		"lemma" : "παρακαλέω",
		"kjv" : "beseech, call for, (be of good) comfort, desire, (give) exhort(-ation), intreat, pray"
	},
	"G2111" : {
		"def" : "well placed, i.e. (figuratively) appropriate",
		"derivation" : "from G2095 (εὖ) and a derivative of G5087 (τίθημι)",
		"lemma" : "εὔθετος",
		"translit" : "eúthetos",
		"kjv" : "fit, meet"
	},
	"G3741" : {
		"kjv" : "holy, mercy, shalt be",
		"translit" : "hósios",
		"lemma" : "ὅσιος",
		"derivation" : "of uncertain affinity; properly, right (by intrinsic or divine character; thus distinguished from 1342, which refers rather to human statutes and relations; from G2413 (ἱερός), which denotes formal consecration",
		"def" : "and from G40 (ἅγιος), which relates to purity from defilement), i.e. hallowed (pious, sacred, sure)"
	},
	"G1156" : {
		"derivation" : "from (a gift)",
		"def" : "probably akin to the base of G1325 (δίδωμι); a loan",
		"kjv" : "debt",
		"lemma" : "δάνειον",
		"translit" : "dáneion"
	},
	"G1275" : {
		"translit" : "diapantós",
		"lemma" : "διαπαντός",
		"kjv" : "alway(-s), continually",
		"def" : "through all time, i.e. (adverbially) constantly",
		"derivation" : "from G1223 (διά) and the genitive case of G3956 (πᾶς)"
	},
	"G1210" : {
		"def" : "to bind (in various applications, literally or figuratively)",
		"derivation" : "a primary verb",
		"translit" : "déō",
		"lemma" : "δέω",
		"kjv" : "bind, be in bonds, knit, tie, wind"
	},
	"G1139" : {
		"lemma" : "δαιμονίζομαι",
		"translit" : "daimonízomai",
		"kjv" : "have a (be vexed with, be possessed with) devil(-s)",
		"def" : "to be exercised by a dæmon",
		"derivation" : "middle voice from G1142 (δαίμων)"
	},
	"G2695" : {
		"derivation" : "from G2596 (κατά) and G4969 (σφάζω)",
		"def" : "to kill down, i.e. slaughter",
		"kjv" : "slay",
		"lemma" : "κατασφάττω",
		"translit" : "kataspháttō"
	},
	"G2259" : {
		"kjv" : "when",
		"lemma" : "ἡνίκα",
		"translit" : "hēníka",
		"derivation" : "of uncertain affinity",
		"def" : "at which time"
	},
	"G2799" : {
		"translit" : "klaíō",
		"lemma" : "κλαίω",
		"kjv" : "bewail, weep",
		"def" : "to sob, i.e. wail aloud (whereas 1145 is rather to cry silently)",
		"derivation" : "of uncertain affinity"
	},
	"G2658" : {
		"kjv" : "attain, come",
		"translit" : "katantáō",
		"lemma" : "καταντάω",
		"derivation" : "from G2596 (κατά) and a derivative of G473 (ἀντί)",
		"def" : "to meet against, i.e. arrive at (literally or figuratively)"
	},
	"G1755" : {
		"def" : "an effect",
		"derivation" : "from G1754 (ἐνεργέω)",
		"translit" : "enérgēma",
		"lemma" : "ἐνέργημα",
		"kjv" : "operation, working"
	},
	"G333" : {
		"kjv" : "behold, consider",
		"lemma" : "ἀναθεωρέω",
		"translit" : "anatheōréō",
		"derivation" : "from G303 (ἀνά) and G2334 (θεωρέω)",
		"def" : "to look again (i.e. attentively) at (literally or figuratively)"
	},
	"G2644" : {
		"kjv" : "reconcile",
		"lemma" : "καταλλάσσω",
		"translit" : "katallássō",
		"derivation" : "from G2596 (κατά) and G236 (ἀλλάσσω)",
		"def" : "to change mutually, i.e. (figuratively) to compound a difference"
	},
	"G2481" : {
		"kjv" : "it may be",
		"translit" : "ísōs",
		"lemma" : "ἴσως",
		"derivation" : "adverb from G2470 (ἴσος)",
		"def" : "likely, i.e. perhaps"
	},
	"G2009" : {
		"def" : "properly, esteem, i.e. citizenship; used (in the sense of G2008 (ἐπιτιμάω)) of a penalty",
		"derivation" : "from a compound of G1909 (ἐπί) and G5092 (τιμή)",
		"lemma" : "ἐπιτιμία",
		"translit" : "epitimía",
		"kjv" : "punishment"
	},
	"G4858" : {
		"kjv" : "music",
		"translit" : "symphōnía",
		"lemma" : "συμφωνία",
		"derivation" : "from G4859 (σύμφωνος)",
		"def" : "unison of sound (\"symphony\"), i.e. a concert of instruments (harmonious note)"
	},
	"G5572" : {
		"derivation" : "from G5571 (ψευδής) and G1320 (διδάσκαλος)",
		"def" : "a spurious teacher, i.e. propagator of erroneous Christian doctrine",
		"kjv" : "false teacher",
		"translit" : "pseudodidáskalos",
		"lemma" : "ψευδοδιδάσκαλος"
	},
	"G3521" : {
		"kjv" : "fast(-ing)",
		"translit" : "nēsteía",
		"lemma" : "νηστεία",
		"derivation" : "from G3522 (νηστεύω)",
		"def" : "abstinence (from lack of food, or voluntary and religious); specially, the fast of the Day of Atonement"
	},
	"G2453" : {
		"def" : "Judæan, i.e. belonging to Jehudah",
		"derivation" : "from G2448 (Ἰουδά) (in the sense of G2455 (Ἰούδας) as a country)",
		"translit" : "Ioudaîos",
		"lemma" : "Ἰουδαῖος",
		"kjv" : "Jew(-ess), of Judæa"
	},
	"G2129" : {
		"def" : "fine speaking, i.e. elegance of language; commendation (\"eulogy\"), i.e. (reverentially) adoration; religiously, benediction; by implication, consecration; by extension, benefit or largess",
		"derivation" : "from the same as G2127 (εὐλογέω)",
		"lemma" : "εὐλογία",
		"translit" : "eulogía",
		"kjv" : "blessing (a matter of) bounty (X -tifully), fair speech"
	},
	"G250" : {
		"translit" : "alóē",
		"lemma" : "ἀλόη",
		"kjv" : "aloes",
		"def" : "aloes (the gum)",
		"derivation" : "of foreign origin (compare G174 (ἀκάνθινος))"
	},
	"G3690" : {
		"derivation" : "from G3691 (ὀξύς)",
		"def" : "vinegar, i.e. sour wine",
		"kjv" : "vinegar",
		"translit" : "óxos",
		"lemma" : "ὄξος"
	},
	"G526" : {
		"def" : "to estrange away, i.e. (passively and figuratively) to be non-participant",
		"derivation" : "from G575 (ἀπό) and a derivative of G245 (ἀλλότριος)",
		"lemma" : "ἀπαλλοτριόω",
		"translit" : "apallotrióō",
		"kjv" : "alienate, be alien"
	},
	"G4658" : {
		"lemma" : "Σκύθης",
		"translit" : "Skýthēs",
		"kjv" : "Scythian",
		"def" : "a Scythene or Scythian, i.e. (by implication) a savage",
		"derivation" : "probably of foreign origin"
	},
	"G23" : {
		"derivation" : "from (much) and (grief",
		"def" : "akin to the base of G43 (ἀγκάλη)); to be greatly afflicted, i.e. (figuratively) indignant",
		"kjv" : "be much (sore) displeased, have (be moved with, with) indignation",
		"lemma" : "ἀγανακτέω",
		"translit" : "aganaktéō"
	},
	"G1520" : {
		"derivation" : "a primary numeral",
		"def" : "one",
		"kjv" : "a(-n, -ny, certain), + abundantly, man, one (another), only, other, some",
		"lemma" : "εἷς",
		"translit" : "heîs"
	},
	"G3771" : {
		"lemma" : "οὐρανόθεν",
		"translit" : "ouranóthen",
		"kjv" : "from heaven",
		"def" : "from the sky",
		"derivation" : "from G3772 (οὐρανός) and the enclitic of source"
	},
	"G5320" : {
		"derivation" : "adverb from G5318 (φανερός)",
		"def" : "plainly, i.e. clearly or publicly",
		"kjv" : "evidently, openly",
		"lemma" : "φανερῶς",
		"translit" : "phanerōs"
	},
	"G92" : {
		"kjv" : "evil doing, iniquity, matter of wrong",
		"translit" : "adíkēma",
		"lemma" : "ἀδίκημα",
		"derivation" : "from G91 (ἀδικέω)",
		"def" : "a wrong done"
	},
	"G5206" : {
		"derivation" : "from a presumed compound of G5207 (υἱός) and a derivative of G5087 (τίθημι)",
		"def" : "the placing as a son, i.e. adoption (figuratively, Christian sonship in respect to God)",
		"kjv" : "adoption (of children, of sons)",
		"translit" : "huiothesía",
		"lemma" : "υἱοθεσία"
	},
	"G71" : {
		"derivation" : "a primary verb",
		"def" : "properly, to lead; by implication, to bring, drive, (reflexively) go, (specially) pass (time), or (figuratively) induce",
		"kjv" : "be, bring (forth), carry, (let) go, keep, lead away, be open",
		"lemma" : "ἄγω",
		"translit" : "ágō"
	},
	"G1717" : {
		"def" : "apparent in self",
		"derivation" : "from a compound of G1722 (ἐν) and G5316 (φαίνω)",
		"lemma" : "ἐμφανής",
		"translit" : "emphanḗs",
		"kjv" : "manifest, openly"
	},
	"G4800" : {
		"translit" : "syzáō",
		"lemma" : "συζάω",
		"kjv" : "live with",
		"def" : "to continue to live in common with, i.e. co-survive (literally or figuratively)",
		"derivation" : "from G4862 (σύν) and G2198 (ζάω)"
	},
	"G1437" : {
		"derivation" : "from G1487 (εἰ) and G302 (ἄν)",
		"def" : "a conditional particle; in case that, provided, etc.; often used in connection with other particles to denote indefiniteness or uncertainty",
		"kjv" : "before, but, except, (and) if, (if) so, (what-, whither-)soever, though, when (-soever), whether (or), to whom, (who-)so(-ever)",
		"lemma" : "ἐάν",
		"translit" : "eán"
	},
	"G1148" : {
		"translit" : "Dalmanouthá",
		"lemma" : "Δαλμανουθά",
		"kjv" : "Dalmanutha",
		"def" : "Dalmanutha, a place in Palestine",
		"derivation" : "probably of Chaldee origin"
	},
	"G118" : {
		"kjv" : "strive",
		"lemma" : "ἀθλέω",
		"translit" : "athléō",
		"derivation" : "from (a contest in the public lists)",
		"def" : "to contend in the competitive games"
	},
	"G265" : {
		"lemma" : "ἁμάρτημα",
		"translit" : "hamártēma",
		"kjv" : "sin",
		"def" : "a sin (properly concrete)",
		"derivation" : "from G264 (ἁμαρτάνω)"
	},
	"G3913" : {
		"lemma" : "παραφρονία",
		"translit" : "paraphronía",
		"kjv" : "madness",
		"def" : "insanity, i.e. foolhardiness",
		"derivation" : "from G3912 (παραφρονέω)"
	},
	"G5092" : {
		"def" : "a value, i.e. money paid, or (concretely and collectively) valuables; by analogy, esteem (especially of the highest degree), or the dignity itself",
		"derivation" : "from G5099 (τίνω)",
		"lemma" : "τιμή",
		"translit" : "timḗ",
		"kjv" : "honour, precious, price, some"
	},
	"G4651" : {
		"def" : "a \"scorpion\" (from its sting)",
		"derivation" : "probably from an obsolete (perhaps strengthened from the base of G4649 (σκοπός) and meaning to pierce)",
		"translit" : "skorpíos",
		"lemma" : "σκορπίος",
		"kjv" : "scorpion"
	},
	"G5476" : {
		"kjv" : "on (to) the ground",
		"lemma" : "χαμαί",
		"translit" : "chamaí",
		"derivation" : "adverb perhaps from the base of G5490 (χάσμα) through the idea of a fissure in the soil",
		"def" : "earthward, i.e. prostrate"
	},
	"G5117" : {
		"def" : "a spot (general in space, but limited by occupancy; whereas G5561 (χώρα) is a large but participle locality), i.e. location (as a position, home, tract, etc.); figuratively, condition, opportunity; specially, a scabbard",
		"derivation" : "apparently a primary word",
		"translit" : "tópos",
		"lemma" : "τόπος",
		"kjv" : "coast, licence, place, X plain, quarter, + rock, room, where"
	},
	"G2951" : {
		"derivation" : "of foreign origin (compare H3646)",
		"def" : "dill or fennel (\"cummin\")",
		"kjv" : "cummin",
		"translit" : "kýminon",
		"lemma" : "κύμινον"
	},
	"G2640" : {
		"def" : "a remainder, i.e. (by implication) a few",
		"derivation" : "from G2641 (καταλείπω)",
		"translit" : "katáleimma",
		"lemma" : "κατάλειμμα",
		"kjv" : "remnant"
	},
	"G235" : {
		"kjv" : "and, but (even), howbeit, indeed, nay, nevertheless, no, notwithstanding, save, therefore, yea, yet",
		"translit" : "allá",
		"lemma" : "ἀλλά",
		"derivation" : "neuter plural of G243 (ἄλλος)",
		"def" : "properly, other things, i.e. (adverbially) contrariwise (in many relations)"
	},
	"G3031" : {
		"derivation" : "from G3030 (λίβανος)",
		"def" : "frankincense, i.e. (by extension) a censer for burning it",
		"kjv" : "censer",
		"lemma" : "λιβανωτός",
		"translit" : "libanōtós"
	},
	"G5504" : {
		"def" : "\"yesterday\"; by extension, in time past or hitherto",
		"derivation" : "of uncertain derivation",
		"translit" : "chthés",
		"lemma" : "χθές",
		"kjv" : "yesterday"
	},
	"G2266" : {
		"def" : "Herodias, a woman of the Heodian family",
		"derivation" : "from G2264 (Ἡρώδης)",
		"translit" : "Hērōdiás",
		"lemma" : "Ἡρωδιάς",
		"kjv" : "Herodias"
	},
	"G2158" : {
		"kjv" : "comely, honourable",
		"translit" : "euschḗmōn",
		"lemma" : "εὐσχήμων",
		"derivation" : "from G2095 (εὖ) and G4976 (σχῆμα)",
		"def" : "well-formed, i.e. (figuratively) decorous, noble (in rank)"
	},
	"G2814" : {
		"def" : "a limb or shoot (as if broken off)",
		"derivation" : "from G2806 (κλάω)",
		"lemma" : "κλῆμα",
		"translit" : "klēma",
		"kjv" : "branch"
	},
	"G4276" : {
		"translit" : "proelpízō",
		"lemma" : "προελπίζω",
		"kjv" : "first trust",
		"def" : "to hope in advance of other confirmation",
		"derivation" : "from G4253 (πρό) and G1679 (ἐλπίζω)"
	},
	"G4290" : {
		"derivation" : "adverb from G4289 (πρόθυμος)",
		"def" : "with alacrity",
		"kjv" : "willingly",
		"lemma" : "προθύμως",
		"translit" : "prothýmōs"
	},
	"G2988" : {
		"derivation" : "adverb from G2986 (λαμπρός)",
		"def" : "brilliantly, i.e. figuratively, luxuriously",
		"kjv" : "sumptuously",
		"translit" : "lamprōs",
		"lemma" : "λαμπρῶς"
	},
	"G4503" : {
		"derivation" : "of Hebrew origin (H7327)",
		"def" : "Ruth, a Moabitess",
		"kjv" : "Ruth",
		"lemma" : "Ῥούθ",
		"translit" : "Rhoúth"
	},
	"G1855" : {
		"lemma" : "ἔξωθεν",
		"translit" : "éxōthen",
		"kjv" : "out(-side, -ward, - wardly), (from) without",
		"def" : "external(-ly)",
		"derivation" : "from G1854 (ἔξω)"
	},
	"G4143" : {
		"translit" : "ploîon",
		"lemma" : "πλοῖον",
		"kjv" : "ship(-ing)",
		"def" : "a sailer, i.e. vessel",
		"derivation" : "from G4126 (πλέω)"
	},
	"G4624" : {
		"def" : "to entrap, i.e. trip up (figuratively, stumble (transitively) or entice to sin, apostasy or displeasure)",
		"derivation" : "from G4625 (σκάνδαλον)",
		"translit" : "skandalízō",
		"lemma" : "σκανδαλίζω",
		"kjv" : "(make to) offend"
	},
	"G2824" : {
		"kjv" : "part, region",
		"lemma" : "κλίμα",
		"translit" : "klíma",
		"derivation" : "from G2827 (κλίνω)",
		"def" : "a slope, i.e. (specially) a \"clime\" or tract of country"
	},
	"G4571" : {
		"def" : "thee",
		"derivation" : "accusative case singular of G4771 (σύ)",
		"lemma" : "σέ",
		"translit" : "sé",
		"kjv" : "thee, thou, X thy house"
	},
	"G1194" : {
		"derivation" : "a primary verb",
		"def" : "properly, to flay, i.e. (by implication) to scourge, or (by analogy) to thrash",
		"kjv" : "beat, smite",
		"lemma" : "δέρω",
		"translit" : "dérō"
	},
	"G4399" : {
		"translit" : "prophthánō",
		"lemma" : "προφθάνω",
		"kjv" : "prevent",
		"def" : "to get an earlier start of, i.e. anticipate",
		"derivation" : "from G4253 (πρό) and G5348 (φθάνω)"
	},
	"G1934" : {
		"kjv" : "desire, enquire, seek (after, for)",
		"lemma" : "ἐπιζητέω",
		"translit" : "epizētéō",
		"derivation" : "from G1909 (ἐπί) and G2212 (ζητέω)",
		"def" : "to search (inquire) for; intensively, to demand, to crave"
	},
	"G4429" : {
		"lemma" : "πτύω",
		"translit" : "ptýō",
		"kjv" : "spit",
		"def" : "to spit",
		"derivation" : "a primary verb (compare G4428 (πτύσσω))"
	},
	"G3109" : {
		"derivation" : "from G3110 (Μακεδών)",
		"def" : "Macedonia, a region of Greece",
		"kjv" : "Macedonia",
		"lemma" : "Μακεδονία",
		"translit" : "Makedonía"
	},
	"G5205" : {
		"derivation" : "from a primary (to rain)",
		"def" : "rain, especially a shower",
		"kjv" : "rain",
		"lemma" : "ὑετός",
		"translit" : "hyetós"
	},
	"G3919" : {
		"kjv" : "privily bring in",
		"lemma" : "παρεισάγω",
		"translit" : "pareiságō",
		"derivation" : "from G3844 (παρά) and G1521 (εἰσάγω)",
		"def" : "to lead in aside, i.e. introduce surreptitiously"
	},
	"G5541" : {
		"derivation" : "middle voice from G5543 (χρηστός)",
		"def" : "to show oneself useful, i.e. act benevolently",
		"kjv" : "be kind",
		"translit" : "chrēsteúomai",
		"lemma" : "χρηστεύομαι"
	},
	"G704" : {
		"translit" : "arḗn",
		"lemma" : "ἀρήν",
		"kjv" : "lamb",
		"def" : "a lamb (as a male)",
		"derivation" : "perhaps the same as G730 (ἄῤῥην)"
	},
	"G2592" : {
		"derivation" : "from G2593 (καρποφόρος)",
		"def" : "to be fertile (literally or figuratively)",
		"kjv" : "be (bear, bring forth) fruit(-ful)",
		"translit" : "karpophoréō",
		"lemma" : "καρποφορέω"
	},
	"G2337" : {
		"derivation" : "from (the nipple)",
		"def" : "to suckle, (by implication) to suck",
		"kjv" : "(give) suck(-ling)",
		"translit" : "thēlázō",
		"lemma" : "θηλάζω"
	},
	"G2423" : {
		"translit" : "Iechonías",
		"lemma" : "Ἰεχονίας",
		"kjv" : "Jechonias",
		"def" : "Jechonias (i.e. Jekonjah), an Israelite",
		"derivation" : "of Hebrew origin (H3204)"
	},
	"G1276" : {
		"def" : "to cross entirely",
		"derivation" : "from G1223 (διά) and a derivative of the base of G4008 (πέραν)",
		"translit" : "diaperáō",
		"lemma" : "διαπεράω",
		"kjv" : "go over, pass (over), sail over"
	},
	"G2796" : {
		"derivation" : "from G2795 (κινέω)",
		"def" : "a stirring",
		"kjv" : "moving",
		"translit" : "kínēsis",
		"lemma" : "κίνησις"
	},
	"G3553" : {
		"def" : "an ailment",
		"derivation" : "from G3552 (νοσέω)",
		"translit" : "nósēma",
		"lemma" : "νόσημα",
		"kjv" : "disease"
	},
	"G1292" : {
		"kjv" : "space",
		"lemma" : "διάστημα",
		"translit" : "diástēma",
		"derivation" : "from G1339 (διΐστημι)",
		"def" : "an interval"
	},
	"G3760" : {
		"translit" : "oudamōs",
		"lemma" : "οὐδαμῶς",
		"kjv" : "not",
		"def" : "by no means",
		"derivation" : "adverb from (the feminine) of G3762 (οὐδείς)"
	},
	"G416" : {
		"def" : "to toss with the wind",
		"derivation" : "from G417 (ἄνεμος)",
		"translit" : "anemízō",
		"lemma" : "ἀνεμίζω",
		"kjv" : "drive with the wind"
	},
	"G2694" : {
		"translit" : "katasýrō",
		"lemma" : "κατασύρω",
		"kjv" : "hale",
		"def" : "to drag down, i.e. arrest judicially",
		"derivation" : "from G2596 (κατά) and G4951 (σύρω)"
	},
	"G593" : {
		"def" : "to disapprove, i.e. (by implication) to repudiate",
		"derivation" : "from G575 (ἀπό) and G1381 (δοκιμάζω)",
		"lemma" : "ἀποδοκιμάζω",
		"translit" : "apodokimázō",
		"kjv" : "disallow, reject"
	},
	"G5164" : {
		"def" : "a wheel (as a runner), i.e. (figuratively) a circuit of physical effects",
		"derivation" : "from G5143 (τρέχω)",
		"lemma" : "τροχός",
		"translit" : "trochós",
		"kjv" : "course"
	},
	"G5425" : {
		"kjv" : "tremble",
		"lemma" : "φρίσσω",
		"translit" : "phríssō",
		"derivation" : "apparently a primary verb",
		"def" : "to \"bristle\" or chill, i.e. shudder (fear)"
	},
	"G644" : {
		"lemma" : "ἀποσκίασμα",
		"translit" : "aposkíasma",
		"kjv" : "shadow",
		"def" : "a shading off, i.e. obscuration",
		"derivation" : "from a compound of G575 (ἀπό) and a derivative of G4639 (σκιά)"
	},
	"G4245" : {
		"kjv" : "elder(-est), old",
		"lemma" : "πρεσβύτερος",
		"translit" : "presbýteros",
		"derivation" : "comparative of (elderly)",
		"def" : "older; as noun, a senior; specially, an Israelite Sanhedrist (also figuratively, member of the celestial council) or Christian \"presbyter\""
	},
	"G3640" : {
		"derivation" : "from G3641 (ὀλίγος) and G4102 (πίστις)",
		"def" : "incredulous, i.e. lacking confidence (in Christ)",
		"kjv" : "of little faith",
		"lemma" : "ὀλιγόπιστος",
		"translit" : "oligópistos"
	},
	"G1287" : {
		"lemma" : "διασκορπίζω",
		"translit" : "diaskorpízō",
		"kjv" : "disperse, scatter (abroad), strew, waste",
		"def" : "to dissipate, i.e. (genitive case) to rout or separate; specially, to winnow; figuratively, to squander",
		"derivation" : "from G1223 (διά) and G4650 (σκορπίζω)"
	},
	"G4173" : {
		"kjv" : "ruler of the city",
		"lemma" : "πολιτάρχης",
		"translit" : "politárchēs",
		"derivation" : "from G4172 (πόλις) and G757 (ἄρχω)",
		"def" : "a town-officer, i.e. magistrate"
	},
	"G5341" : {
		"def" : "a mantle (surtout)",
		"derivation" : "by transposition for a derivative probably of G5316 (φαίνω) (as showing outside the other garments)",
		"translit" : "phelónēs",
		"lemma" : "φελόνης",
		"kjv" : "cloke"
	},
	"G3735" : {
		"kjv" : "hill, mount(-ain)",
		"lemma" : "ὄρος",
		"translit" : "óros",
		"derivation" : "probably from an obsolete (to rise or \"rear\"",
		"def" : "perhaps akin to G142 (αἴρω); compare G3733 (ὄρνις)); a mountain (as lifting itself above the plain)"
	},
	"G1725" : {
		"derivation" : "from G1722 (ἐν) and G473 (ἀντί)",
		"def" : "in front (i.e. figuratively, presence) of",
		"kjv" : "before",
		"lemma" : "ἔναντι",
		"translit" : "énanti"
	},
	"G671" : {
		"def" : "the act of using up, i.e. consumption",
		"derivation" : "from a compound of G575 (ἀπό) and G5530 (χράομαι)",
		"translit" : "apóchrēsis",
		"lemma" : "ἀπόχρησις",
		"kjv" : "using"
	},
	"G133" : {
		"translit" : "aínesis",
		"lemma" : "αἴνεσις",
		"kjv" : "praise",
		"def" : "a praising (the act), i.e. (specially) a thank(-offering)",
		"derivation" : "from G134 (αἰνέω)"
	},
	"G2764" : {
		"def" : "made of clay, i.e. earthen",
		"derivation" : "from G2766 (κέραμος)",
		"translit" : "keramikós",
		"lemma" : "κεραμικός",
		"kjv" : "of a potter"
	},
	"G3423" : {
		"derivation" : "from a derivative of G3415 (μνάομαι)",
		"def" : "to give a souvenir (engagement present), i.e. betroth",
		"kjv" : "espouse",
		"translit" : "mnēsteúō",
		"lemma" : "μνηστεύω"
	},
	"G196" : {
		"kjv" : "most straitest",
		"lemma" : "ἀκριβέστατος",
		"translit" : "akribéstatos",
		"derivation" : "superlative of (a derivative of the same as G206 (ἄκρον))",
		"def" : "most exact"
	},
	"G3956" : {
		"derivation" : "including all the forms of declension; apparently a primary word",
		"def" : "all, any, every, the whole",
		"kjv" : "all (manner of, means), alway(-s), any (one), X daily, + ever, every (one, way), as many as, + no(-thing), X thoroughly, whatsoever, whole, whosoever",
		"translit" : "pâs",
		"lemma" : "πᾶς"
	},
	"G5596" : {
		"def" : "a crumb or morsel (as if rubbed off), i.e. a mouthful",
		"derivation" : "diminutive from a derivative of the base of G5597 (ψώχω)",
		"translit" : "psōmíon",
		"lemma" : "ψωμίον",
		"kjv" : "sop"
	},
	"G328" : {
		"kjv" : "gird up",
		"translit" : "anazṓnnymi",
		"lemma" : "ἀναζώννυμι",
		"derivation" : "from G303 (ἀνά) and G2224 (ζώννυμι)",
		"def" : "to gird afresh"
	},
	"G1377" : {
		"kjv" : "ensue, follow (after), given to, (suffer) persecute(-ion), press forward",
		"translit" : "diṓkō",
		"lemma" : "διώκω",
		"derivation" : "a prolonged (and causative) form of a primary verb (to flee",
		"def" : "compare the base of G1169 (δειλός) and G1249 (διάκονος)); to pursue (literally or figuratively); by implication, to persecute"
	},
	"G2288" : {
		"def" : "(properly, an adjective used as a noun) death (literally or figuratively)",
		"derivation" : "from G2348 (θνήσκω)",
		"lemma" : "θάνατος",
		"translit" : "thánatos",
		"kjv" : "X deadly, (be…) death"
	},
	"G5590" : {
		"kjv" : "heart (+ -ily), life, mind, soul, + us, + you",
		"translit" : "psychḗ",
		"lemma" : "ψυχή",
		"derivation" : "from G5594 (ψύχω)",
		"def" : "breath, i.e. (by implication) spirit, abstractly or concretely (the animal sentient principle only; thus distinguished on the one hand from G4151 (πνεῦμα), which is the rational and immortal soul; and on the other from G2222 (ζωή), which is mere vitality, even of plants: these terms thus exactly correspond respectively to the Hebrew H5315, H7307 and H2416)"
	},
	"G2343" : {
		"def" : "to amass or reserve (literally or figuratively)",
		"derivation" : "from G2344 (θησαυρός)",
		"translit" : "thēsaurízō",
		"lemma" : "θησαυρίζω",
		"kjv" : "lay up (treasure), (keep) in store, (heap) treasure (together, up)"
	},
	"G3144" : {
		"def" : "a witness (literally (judicially) or figuratively (genitive case)); by analogy, a \"martyr\"",
		"derivation" : "of uncertain affinity",
		"lemma" : "μάρτυς",
		"translit" : "mártys",
		"kjv" : "martyr, record, witness"
	},
	"G5157" : {
		"translit" : "tropḗ",
		"lemma" : "τροπή",
		"kjv" : "turning",
		"def" : "a turn (\"trope\"), i.e. revolution (figuratively, variation)",
		"derivation" : "from an apparently primary to turn"
	},
	"G3624" : {
		"lemma" : "οἶκος",
		"translit" : "oîkos",
		"kjv" : "home, house(-hold), temple",
		"def" : "a dwelling (more or less extensive, literal or figurative); by implication, a family (more or less related, literally or figuratively)",
		"derivation" : "of uncertain affinity"
	},
	"G4622" : {
		"translit" : "Siṓn",
		"lemma" : "Σιών",
		"kjv" : "Sion",
		"def" : "Sion (i.e. Tsijon), a hill of Jerusalem; figuratively, the Church (militant or triumphant)",
		"derivation" : "of Hebrew origin (H6726)"
	},
	"G174" : {
		"kjv" : "of thorns",
		"translit" : "akánthinos",
		"lemma" : "ἀκάνθινος",
		"derivation" : "from G173 (ἄκανθα)",
		"def" : "thorny"
	},
	"G3315" : {
		"lemma" : "μεσιτεύω",
		"translit" : "mesiteúō",
		"kjv" : "confirm",
		"def" : "to interpose (as arbiter), i.e (by implication) to ratify (as surety)",
		"derivation" : "from G3316 (μεσίτης)"
	},
	"G4266" : {
		"derivation" : "from G4253 (πρό) and G1096 (γίνομαι)",
		"def" : "to be already, i.e. have previousy transpired",
		"kjv" : "be past",
		"translit" : "progínomai",
		"lemma" : "προγίνομαι"
	},
	"G2765" : {
		"lemma" : "κεράμιον",
		"translit" : "kerámion",
		"kjv" : "pitcher",
		"def" : "an earthenware vessel, i.e. jar",
		"derivation" : "neuter of a presumed derivative of G2766 (κέραμος)"
	},
	"G5384" : {
		"lemma" : "φίλος",
		"translit" : "phílos",
		"kjv" : "friend",
		"def" : "actively, fond, i.e. friendly (still as a noun, an associate, neighbor, etc.)",
		"derivation" : "properly, dear, i.e. a friend"
	},
	"G4032" : {
		"derivation" : "from G4012 (περί) and G2928 (κρύπτω)",
		"def" : "to conceal all around, i.e. entirely",
		"kjv" : "hide",
		"translit" : "perikrýptō",
		"lemma" : "περικρύπτω"
	},
	"G2641" : {
		"def" : "to leave down, i.e. behind; by implication, to abandon, have remaining",
		"derivation" : "from G2596 (κατά) and G3007 (λείπω)",
		"lemma" : "καταλείπω",
		"translit" : "kataleípō",
		"kjv" : "forsake, leave, reserve"
	},
	"G1848" : {
		"translit" : "exouthenéō",
		"lemma" : "ἐξουθενέω",
		"kjv" : "contemptible, despise, least esteemed, set at nought",
		"derivation" : "a variation of G1847 (ἐξουδενόω) and meaning the same"
	},
	"G5406" : {
		"translit" : "phoneús",
		"lemma" : "φονεύς",
		"kjv" : "murderer",
		"def" : "a murderer (always of criminal (or at least intentional) homicide; which G443 (ἀνθρωποκτόνος) does not necessarily imply; while G4607 (σικάριος) is a special term for a public bandit)",
		"derivation" : "from G5408 (φόνος)"
	},
	"G4879" : {
		"derivation" : "from G4862 (σύν) and G520 (ἀπάγω)",
		"def" : "to take off together, i.e. transport with (seduce, passively, yield)",
		"kjv" : "carry (lead) away with, condescend",
		"lemma" : "συναπάγω",
		"translit" : "synapágō"
	},
	"G4534" : {
		"derivation" : "perhaps of similar origin to G4529 (Σαλαμίς)",
		"def" : "Salmone, a place in Crete",
		"kjv" : "Salmone",
		"lemma" : "Σαλμώνη",
		"translit" : "Salmṓnē"
	},
	"G462" : {
		"kjv" : "unholy",
		"lemma" : "ἀνόσιος",
		"translit" : "anósios",
		"derivation" : "from G1 (Α) (as a negative particle) and G3741 (ὅσιος)",
		"def" : "wicked"
	},
	"G4601" : {
		"kjv" : "keep close (secret, silence), hold peace",
		"lemma" : "σιγάω",
		"translit" : "sigáō",
		"derivation" : "from G4602 (σιγή)",
		"def" : "to keep silent (transitively or intransitively)"
	},
	"G4168" : {
		"kjv" : "flock",
		"lemma" : "ποίμνιον",
		"translit" : "poímnion",
		"derivation" : "neuter of a presumed derivative of G4167 (ποίμνη)",
		"def" : "a flock, i.e. (figuratively) group (of believers)"
	},
	"G4968" : {
		"derivation" : "neuter of a derivative of G4967 (σφαγή)",
		"def" : "a victim (in sacrifice)",
		"kjv" : "slain beast",
		"lemma" : "σφάγιον",
		"translit" : "sphágion"
	},
	"G5364" : {
		"kjv" : "courteously",
		"translit" : "philanthrṓpōs",
		"lemma" : "φιλανθρώπως",
		"derivation" : "adverb from a compound of G5384 (φίλος) and G444 (ἄνθρωπος)",
		"def" : "fondly to man (\"philanthropically\"), i.e. humanely"
	},
	"G1039" : {
		"kjv" : "fine linen",
		"translit" : "býssinos",
		"lemma" : "βύσσινος",
		"derivation" : "from G1040 (βύσσος)",
		"def" : "made of linen (neuter a linen cloth)"
	},
	"G4842" : {
		"kjv" : "send with",
		"translit" : "sympémpō",
		"lemma" : "συμπέμπω",
		"derivation" : "from G4862 (σύν) and G3992 (πέμπω)",
		"def" : "to despatch in company"
	},
	"G4823" : {
		"kjv" : "consult, (give, take) counsel (together)",
		"lemma" : "συμβουλεύω",
		"translit" : "symbouleúō",
		"derivation" : "from G4862 (σύν) and G1011 (βουλεύω)",
		"def" : "to give (or take) advice jointly, i.e. recommend, deliberate or determine"
	},
	"G5209" : {
		"translit" : "hymâs",
		"lemma" : "ὑμᾶς",
		"kjv" : "ye, you (+ -ward), your (+ own)",
		"def" : "you (as the objective of a verb or preposition)",
		"derivation" : "accusative case of G5210 (ὑμεῖς)"
	},
	"G4150" : {
		"derivation" : "a prolonged form of an obsolete (to \"flow\")",
		"def" : "to \"plunge\", i.e. launder clothing",
		"kjv" : "wash",
		"lemma" : "πλύνω",
		"translit" : "plýnō"
	},
	"G4042" : {
		"def" : "a being held around, i.e. (concretely) a passage (of Scripture, as circumscribed)",
		"derivation" : "from G4023 (περιέχω)",
		"lemma" : "περιοχή",
		"translit" : "periochḗ",
		"kjv" : "place"
	},
	"G4776" : {
		"kjv" : "(make) sit (down) together",
		"lemma" : "συγκαθίζω",
		"translit" : "synkathízō",
		"derivation" : "from G4862 (σύν) and G2523 (καθίζω)",
		"def" : "to give (or take) a seat in company with"
	},
	"G369" : {
		"derivation" : "adverb from G368 (ἀναντίῤῥητος)",
		"def" : "promptly",
		"kjv" : "without gainsaying",
		"translit" : "anantirrhḗtōs",
		"lemma" : "ἀναντιῤῥήτως"
	},
	"G5121" : {
		"derivation" : "contraction for the neuter of G3588 (ὁ) and G1726 (ἐναντίον)",
		"def" : "on the contrary",
		"kjv" : "contrariwise",
		"translit" : "tounantíon",
		"lemma" : "τοὐναντίον"
	},
	"G5233" : {
		"def" : "to transcend, i.e. (figuratively) to overreach",
		"derivation" : "from G5228 (ὑπέρ) and the base of G939 (βάσις)",
		"lemma" : "ὑπερβαίνω",
		"translit" : "hyperbaínō",
		"kjv" : "go beyond"
	},
	"G5079" : {
		"def" : "an artisan; figuratively, a founder (Creator)",
		"derivation" : "from G5078 (τέχνη)",
		"lemma" : "τεχνίτης",
		"translit" : "technítēs",
		"kjv" : "builder, craftsman"
	},
	"G2856" : {
		"def" : "to dock, i.e. (figuratively) abridge",
		"derivation" : "from a derivative of the base of G2849 (κολάζω)",
		"translit" : "kolobóō",
		"lemma" : "κολοβόω",
		"kjv" : "shorten"
	},
	"G4120" : {
		"derivation" : "a primary word",
		"def" : "to twine or braid",
		"kjv" : "plait",
		"lemma" : "πλέκω",
		"translit" : "plékō"
	},
	"G3887" : {
		"def" : "to stay near, i.e. remain (literally, tarry; or figuratively, be permanent, persevere)",
		"derivation" : "from G3844 (παρά) and G3306 (μένω)",
		"translit" : "paraménō",
		"lemma" : "παραμένω",
		"kjv" : "abide, continue"
	},
	"G5618" : {
		"kjv" : "(even, like) as",
		"lemma" : "ὥσπερ",
		"translit" : "hṓsper",
		"derivation" : "from G5613 (ὡς) and G4007 (περ)",
		"def" : "just as, i.e. exactly like"
	},
	"G5482" : {
		"kjv" : "trench",
		"translit" : "chárax",
		"lemma" : "χάραξ",
		"derivation" : "from (to sharpen to a point",
		"def" : "akin to G1125 (γράφω) through the idea of scratching); a stake, i.e. (by implication) a palisade or rampart (military mound for circumvallation in a siege)"
	},
	"G1438" : {
		"lemma" : "ἑαυτοῦ",
		"translit" : "heautoû",
		"kjv" : "alone, her (own, -self), (he) himself, his (own), itself, one (to) another, our (thine) own(-selves), + that she had, their (own, own selves), (of) them(-selves), they, thyself, you, your (own, own conceits, own selves, -selves)",
		"def" : "him- (her-, it-, them-, also (in conjunction with the personal pronoun of the other persons) my-, thy-, our-, your-) self (selves), etc.",
		"derivation" : "from a reflexive pronoun otherwise obsolete and the genitive case (dative case or accusative case) of G846 (αὐτός)"
	},
	"G1103" : {
		"kjv" : "own, sincerity, true",
		"translit" : "gnḗsios",
		"lemma" : "γνήσιος",
		"derivation" : "from the same as G1077 (γενέσια)",
		"def" : "legitimate (of birth), i.e. genuine"
	},
	"G5490" : {
		"lemma" : "χάσμα",
		"translit" : "chásma",
		"kjv" : "gulf",
		"def" : "a \"chasm\" or vacancy (impassable interval)",
		"derivation" : "from a form of an obsolete primary (to \"gape\" or \"yawn\")"
	},
	"G2557" : {
		"def" : "a wrong-doer, i.e. criminal",
		"derivation" : "from G2556 (κακός) and the base of G2041 (ἔργον)",
		"lemma" : "κακοῦργος",
		"translit" : "kakoûrgos",
		"kjv" : "evil-doer, malefactor"
	},
	"G5017" : {
		"def" : "a disturbance, i.e. (popular) tumult",
		"derivation" : "masculine from G5015 (ταράσσω)",
		"translit" : "tárachos",
		"lemma" : "τάραχος",
		"kjv" : "stir"
	},
	"G4926" : {
		"derivation" : "from G4862 (σύν) and G3656 (ὁμιλέω)",
		"def" : "to converse mutually",
		"kjv" : "talk with",
		"lemma" : "συνομιλέω",
		"translit" : "synomiléō"
	},
	"G960" : {
		"derivation" : "perhaps a provincial from a derivative of G4008 (πέραν) (Perœa, i.e. the region beyond the coast-line)",
		"def" : "Berœa, a place in Macedonia",
		"kjv" : "Berea",
		"translit" : "Béroia",
		"lemma" : "Βέροια"
	},
	"G5242" : {
		"translit" : "hyperéchō",
		"lemma" : "ὑπερέχω",
		"kjv" : "better, excellency, higher, pass, supreme",
		"def" : "to hold oneself above, i.e. (figuratively) to excel; participle (as adjective, or neuter as noun) superior, superiority",
		"derivation" : "from G5228 (ὑπέρ) and G2192 (ἔχω)"
	},
	"G3848" : {
		"def" : "a violator",
		"derivation" : "from G3845 (παραβαίνω)",
		"lemma" : "παραβάτης",
		"translit" : "parabátēs",
		"kjv" : "breaker, transgress(-or)"
	},
	"G748" : {
		"def" : "high-priestly",
		"derivation" : "from G746 (ἀρχή) and a derivative of G2413 (ἱερός)",
		"lemma" : "ἀρχιερατικός",
		"translit" : "archieratikós",
		"kjv" : "of the high-priest"
	},
	"G4973" : {
		"derivation" : "probably strengthened from G5420 (φράσσω)",
		"def" : "a signet (as fencing in or protecting from misappropriation); by implication, the stamp impressed (as a mark of privacy, or genuineness), literally or figuratively",
		"kjv" : "seal",
		"translit" : "sphragís",
		"lemma" : "σφραγίς"
	},
	"G2514" : {
		"derivation" : "from G2513 (καθαρός)",
		"def" : "cleanness (ceremonially)",
		"kjv" : "purification",
		"lemma" : "καθαρότης",
		"translit" : "katharótēs"
	},
	"G3522" : {
		"def" : "to abstain from food (religiously)",
		"derivation" : "from G3523 (νῆστις)",
		"lemma" : "νηστεύω",
		"translit" : "nēsteúō",
		"kjv" : "fast"
	},
	"G202" : {
		"kjv" : "hearer",
		"translit" : "akroatḗs",
		"lemma" : "ἀκροατής",
		"derivation" : "from (to listen",
		"def" : "apparently an intensive of G191 (ἀκούω)); a hearer (merely)"
	},
	"G3800" : {
		"def" : "rations for a soldier, i.e. (by extension) his stipend or pay",
		"derivation" : "neuter of a presumed derivative of the same as G3795 (ὀψάριον)",
		"lemma" : "ὀψώνιον",
		"translit" : "opsṓnion",
		"kjv" : "wages"
	},
	"G3487" : {
		"derivation" : "of foreign origin (compare H5373)",
		"def" : "\"nard\"",
		"kjv" : "(spike-)nard",
		"translit" : "nárdos",
		"lemma" : "νάρδος"
	},
	"G2411" : {
		"translit" : "hierón",
		"lemma" : "ἱερόν",
		"kjv" : "temple",
		"def" : "a sacred place, i.e. the entire precincts (whereas G3485 (ναός) denotes the central sanctuary itself) of the Temple (at Jerusalem or elsewhere)",
		"derivation" : "neuter of G2413 (ἱερός)"
	},
	"G3443" : {
		"kjv" : "be desolate",
		"lemma" : "μονόω",
		"translit" : "monóō",
		"derivation" : "from G3441 (μόνος)",
		"def" : "to isolate, i.e. bereave"
	},
	"G5" : {
		"derivation" : "of Chaldee origin (H2)",
		"def" : "father as a vocative",
		"kjv" : "Abba",
		"translit" : "Abbâ",
		"lemma" : "Ἀββᾶ"
	},
	"G4051" : {
		"translit" : "perísseuma",
		"lemma" : "περίσσευμα",
		"kjv" : "abundance, that was left, over and above",
		"def" : "a surplus, or superabundance",
		"derivation" : "from G4052 (περισσεύω)"
	},
	"G1518" : {
		"def" : "pacificatory, i.e. (subjectively) peaceable",
		"derivation" : "from G1515 (εἰρήνη) and G4160 (ποιέω)",
		"translit" : "eirēnopoiós",
		"lemma" : "εἰρηνοποιός",
		"kjv" : "peacemaker"
	},
	"G1835" : {
		"translit" : "hexḗkonta",
		"lemma" : "ἑξήκοντα",
		"kjv" : "sixty(-fold), threescore",
		"def" : "sixty",
		"derivation" : "the tenth multiple of G1803 (ἕξ)"
	},
	"G2399" : {
		"derivation" : "from G2398 (ἴδιος)",
		"def" : "a private person, i.e. (by implication) an ignoramus (compare \"idiot\")",
		"kjv" : "ignorant, rude, unlearned",
		"translit" : "idiṓtēs",
		"lemma" : "ἰδιώτης"
	},
	"G775" : {
		"derivation" : "from G773 (Ἀσία) and G746 (ἀρχή)",
		"def" : "an Asiarch or president of the public festivities in a city of Asia Minor",
		"kjv" : "chief of Asia",
		"lemma" : "Ἀσιάρχης",
		"translit" : "Asiárchēs"
	},
	"G5085" : {
		"translit" : "Tiberiás",
		"lemma" : "Τιβεριάς",
		"kjv" : "Tiberias",
		"def" : "Tiberias, the name of a town and a lake in Palestine",
		"derivation" : "from G5086 (Τιβέριος)"
	},
	"G614" : {
		"def" : "secret; by implication, treasured",
		"derivation" : "from G613 (ἀποκρύπτω)",
		"translit" : "apókryphos",
		"lemma" : "ἀπόκρυφος",
		"kjv" : "hid, kept secret"
	},
	"G2566" : {
		"kjv" : "very well",
		"translit" : "kallíon",
		"lemma" : "καλλίον",
		"derivation" : "neuter of the (irregular) comparative of G2570 (καλός)",
		"def" : "(adverbially) better than many"
	},
	"G5286" : {
		"kjv" : "footstool",
		"translit" : "hypopódion",
		"lemma" : "ὑποπόδιον",
		"derivation" : "neuter of a compound of G5259 (ὑπό) and G4228 (πούς)",
		"def" : "something under the feet, i.e. a foot-rest (figuratively)"
	},
	"G4401" : {
		"kjv" : "choose before",
		"lemma" : "προχειροτονέω",
		"translit" : "procheirotonéō",
		"derivation" : "from G4253 (πρό) and G5500 (χειροτονέω)",
		"def" : "to elect in advance"
	},
	"G4050" : {
		"kjv" : "abundance(-ant, (-ly)), superfluity",
		"lemma" : "περισσεία",
		"translit" : "perisseía",
		"derivation" : "from G4052 (περισσεύω)",
		"def" : "surplusage, i.e. superabundance"
	},
	"G4335" : {
		"kjv" : "X pray earnestly, prayer",
		"translit" : "proseuchḗ",
		"lemma" : "προσευχή",
		"derivation" : "from G4336 (προσεύχομαι)",
		"def" : "prayer (worship); by implication, an oratory (chapel)"
	},
	"G3510" : {
		"def" : "a kidney (plural), i.e. (figuratively) the inmost mind",
		"derivation" : "of uncertain affinity",
		"lemma" : "νεφρός",
		"translit" : "nephrós",
		"kjv" : "reins"
	},
	"G2043" : {
		"translit" : "ereídō",
		"lemma" : "ἐρείδω",
		"kjv" : "stick fast",
		"def" : "to prop, i.e. (reflexively) get fast",
		"derivation" : "of obscure affinity"
	},
	"G3671" : {
		"derivation" : "from the same as G3670 (ὁμολογέω)",
		"def" : "acknowledgment",
		"kjv" : "con- (pro-)fession, professed",
		"lemma" : "ὁμολογία",
		"translit" : "homología"
	},
	"G548" : {
		"lemma" : "ἄπειμι",
		"translit" : "ápeimi",
		"kjv" : "be absent",
		"def" : "to be away",
		"derivation" : "from G575 (ἀπό) and G1510 (εἰμί)"
	},
	"G1233" : {
		"derivation" : "from G1231 (διαγινώσκω)",
		"def" : "(magisterial) examination (\"diagnosis\")",
		"kjv" : "hearing",
		"lemma" : "διάγνωσις",
		"translit" : "diágnōsis"
	},
	"G3005" : {
		"def" : "a remainder",
		"derivation" : "from G3007 (λείπω)",
		"translit" : "leîmma",
		"lemma" : "λεῖμμα",
		"kjv" : "remnant"
	},
	"G2387" : {
		"lemma" : "Ἰαμβρῆς",
		"translit" : "Iambrēs",
		"kjv" : "Jambres",
		"def" : "Jambres, an Egyptian",
		"derivation" : "of Egyptian origin"
	},
	"G2669" : {
		"derivation" : "from G2596 (κατά) and a derivative of G4192 (πόνος)",
		"def" : "to labor down, i.e. wear with toil (figuratively, harass)",
		"kjv" : "oppress, vex",
		"lemma" : "καταπονέω",
		"translit" : "kataponéō"
	},
	"G1238" : {
		"def" : "a \"diadem\" (as bound about the head)",
		"derivation" : "from a compound of G1223 (διά) and G1210 (δέω)",
		"lemma" : "διάδημα",
		"translit" : "diádēma",
		"kjv" : "crown"
	},
	"G4256" : {
		"translit" : "proaitiáomai",
		"lemma" : "προαιτιάομαι",
		"kjv" : "prove before",
		"def" : "to accuse already, i.e. previously charge",
		"derivation" : "from G4253 (πρό) and a derivative of G156 (αἰτία)"
	},
	"G1696" : {
		"kjv" : "continue",
		"translit" : "emménō",
		"lemma" : "ἐμμένω",
		"derivation" : "from G1722 (ἐν) and G3306 (μένω)",
		"def" : "to stay in the same place, i.e. (figuratively) persevere"
	},
	"G2881" : {
		"def" : "a Corinthian, i.e. inhabitant of Corinth",
		"derivation" : "from G2882 (Κόρινθος)",
		"translit" : "Korínthios",
		"lemma" : "Κορίνθιος",
		"kjv" : "Corinthian"
	},
	"G1144" : {
		"def" : "a tear",
		"derivation" : "of uncertain affinity",
		"translit" : "dákry",
		"lemma" : "δάκρυ",
		"kjv" : "tear"
	},
	"G383" : {
		"kjv" : "move, stir up",
		"lemma" : "ἀνασείω",
		"translit" : "anaseíō",
		"derivation" : "from G303 (ἀνά) and G4579 (σείω)",
		"def" : "figuratively, to excite"
	},
	"G1646" : {
		"translit" : "eláchistos",
		"lemma" : "ἐλάχιστος",
		"kjv" : "least, very little (small), smallest",
		"def" : "used as equivalent to G3398 (μικρός); least (in size, amount, dignity, etc.)",
		"derivation" : "superlative of (short)"
	},
	"G2635" : {
		"def" : "to be a traducer, i.e. to slander",
		"derivation" : "from G2637 (κατάλαλος)",
		"translit" : "katalaléō",
		"lemma" : "καταλαλέω",
		"kjv" : "speak against (evil of)"
	},
	"G3318" : {
		"kjv" : "Mesopotamia",
		"lemma" : "Μεσοποταμία",
		"translit" : "Mesopotamía",
		"derivation" : "from G3319 (μέσος) and G4215 (ποταμός)",
		"def" : "Mesopotamia (as lying between the Euphrates and the Tigris; compare 0763), a region of Asia"
	},
	"G1799" : {
		"def" : "in the face of (literally or figuratively)",
		"derivation" : "neuter of a compound of G1722 (ἐν) and a derivative of G3700 (ὀπτάνομαι)",
		"lemma" : "ἐνώπιον",
		"translit" : "enṓpion",
		"kjv" : "before, in the presence (sight) of, to"
	},
	"G3548" : {
		"def" : "legislation (specially, the institution of the Mosaic code)",
		"derivation" : "from G3550 (νομοθέτης)",
		"translit" : "nomothesía",
		"lemma" : "νομοθεσία",
		"kjv" : "giving of the law"
	},
	"G1992" : {
		"translit" : "epistolḗ",
		"lemma" : "ἐπιστολή",
		"kjv" : "\"epistle,\" letter",
		"def" : "a written message",
		"derivation" : "from G1989 (ἐπιστέλλω)"
	},
	"G10" : {
		"kjv" : "Abiud",
		"translit" : "Abioúd",
		"lemma" : "Ἀβιούδ",
		"derivation" : "of Hebrew origin (H31)",
		"def" : "Abihud, an Israelite"
	},
	"G5152" : {
		"translit" : "trístegon",
		"lemma" : "τρίστεγον",
		"kjv" : "third loft",
		"def" : "a third roof (story)",
		"derivation" : "neuter of a compound of G5140 (τρεῖς) and G4721 (στέγη) as noun"
	},
	"G4637" : {
		"derivation" : "from G4636 (σκῆνος)",
		"def" : "to tent or encamp, i.e. (figuratively) to occupy (as a mansion) or (specially), to reside (as God did in the Tabernacle of old, a symbol of protection and communion)",
		"kjv" : "dwell",
		"translit" : "skēnóō",
		"lemma" : "σκηνόω"
	},
	"G4164" : {
		"translit" : "poikílos",
		"lemma" : "ποικίλος",
		"kjv" : "divers, manifold",
		"def" : "motley, i.e. various in character",
		"derivation" : "of uncertain derivation"
	},
	"G5564" : {
		"lemma" : "χωρίον",
		"translit" : "chōríon",
		"kjv" : "field, land, parcel of ground, place, possession",
		"def" : "a spot or plot of ground",
		"derivation" : "diminutive of G5561 (χώρα)"
	},
	"G2759" : {
		"kjv" : "prick, sting",
		"translit" : "kéntron",
		"lemma" : "κέντρον",
		"derivation" : "from (to prick)",
		"def" : "a point (\"centre\"), i.e. a sting (figuratively, poison) or goad (figuratively, divine impulse)"
	},
	"G3478" : {
		"derivation" : "of uncertain derivation",
		"def" : "Nazareth or Nazaret, a place in Palestine",
		"kjv" : "Nazareth",
		"translit" : "Nazaréth",
		"lemma" : "Ναζαρέθ"
	},
	"G2991" : {
		"derivation" : "from a compound of (a stone) and the base of G3584 (ξηρός) (in its original sense of scraping)",
		"def" : "rock-quarried",
		"kjv" : "hewn in stone",
		"translit" : "laxeutós",
		"lemma" : "λαξευτός"
	},
	"G3928" : {
		"derivation" : "from G3844 (παρά) and G2064 (ἔρχομαι)",
		"def" : "to come near or aside, i.e. to approach (arrive), go by (or away), (figuratively) perish or neglect, (causative) avert",
		"kjv" : "come (forth), go, pass (away, by, over), past, transgress",
		"lemma" : "παρέρχομαι",
		"translit" : "parérchomai"
	},
	"G2322" : {
		"derivation" : "from G2323 (θεραπεύω)",
		"def" : "attendance (specially, medical, i.e. cure); figuratively and collectively, domestics",
		"kjv" : "healing, household",
		"lemma" : "θεραπεία",
		"translit" : "therapeía"
	},
	"G4928" : {
		"def" : "restraint, i.e. (figuratively) anxiety",
		"derivation" : "from G4912 (συνέχω)",
		"translit" : "synochḗ",
		"lemma" : "συνοχή",
		"kjv" : "anguish, distress"
	},
	"G4177" : {
		"derivation" : "from G4172 (πόλις)",
		"def" : "a townsman",
		"kjv" : "citizen",
		"lemma" : "πολίτης",
		"translit" : "polítēs"
	},
	"G4304" : {
		"kjv" : "meditate before",
		"translit" : "promeletáō",
		"lemma" : "προμελετάω",
		"derivation" : "from G4253 (πρό) and G3191 (μελετάω)",
		"def" : "to premeditate"
	},
	"G5567" : {
		"def" : "compare G5597 (ψώχω)); to twitch or twang, i.e. to play on a stringed instrument (celebrate the divine worship with music and accompanying odes)",
		"derivation" : "probably strengthened from (to rub or touch the surface",
		"translit" : "psállō",
		"lemma" : "ψάλλω",
		"kjv" : "make melody, sing (psalms)"
	},
	"G1420" : {
		"derivation" : "from G1418 (δυσ-) and a comparative of G1787 (ἐντός) (meaning a bowel)",
		"def" : "a \"dysentery\"",
		"kjv" : "bloody flux",
		"lemma" : "δυσεντερία",
		"translit" : "dysentería"
	},
	"G1382" : {
		"translit" : "dokimḗ",
		"lemma" : "δοκιμή",
		"kjv" : "experience(-riment), proof, trial",
		"def" : "test (abstractly or concretely); by implication, trustiness",
		"derivation" : "from the same as G1384 (δόκιμος)"
	},
	"G1216" : {
		"derivation" : "from (Ceres)",
		"def" : "Demetrius, the name of an Ephesian and of a Christian",
		"kjv" : "Demetrius",
		"translit" : "Dēmḗtrios",
		"lemma" : "Δημήτριος"
	},
	"G3654" : {
		"derivation" : "adverb from G3650 (ὅλος)",
		"def" : "completely, i.e. altogether; (by analogy), everywhere; (negatively) not by any means",
		"kjv" : "at all, commonly, utterly",
		"translit" : "hólōs",
		"lemma" : "ὅλως"
	},
	"G2678" : {
		"def" : "to sway downward, i.e. make a signal",
		"derivation" : "from G2596 (κατά) and G4579 (σείω)",
		"lemma" : "κατασείω",
		"translit" : "kataseíō",
		"kjv" : "beckon"
	},
	"G2229" : {
		"kjv" : "surely",
		"lemma" : "ἦ",
		"translit" : "ē",
		"derivation" : "an adverb of confirmation; perhaps intensive of G2228 (ἤ); used only (in the New Testament) before G3303 (μέν)",
		"def" : "assuredly"
	},
	"G4505" : {
		"derivation" : "prolongation from G4506 (ῥύομαι) in its original sense",
		"def" : "an alley or avenue (as crowded)",
		"kjv" : "lane, street",
		"translit" : "rhýmē",
		"lemma" : "ῥύμη"
	},
	"G3360" : {
		"def" : "as far as, i.e. up to a certain point (as a preposition, of extent (denoting the terminus, whereas G891 (ἄχρι) refers especially to the space of time or place intervening) or conjunction)",
		"derivation" : "from G3372 (μῆκος)",
		"translit" : "méchri",
		"lemma" : "μέχρι",
		"kjv" : "till, (un-)to, until"
	},
	"G4424" : {
		"lemma" : "Πτολεμαΐς",
		"translit" : "Ptolemaḯs",
		"kjv" : "Ptolemais",
		"def" : "Ptolemais, a place in Palestine",
		"derivation" : "from (Ptolemy, after whom it was named)"
	},
	"G2699" : {
		"def" : "a cutting down (off), i.e. mutilation (ironically)",
		"derivation" : "from a compound of G2596 (κατά) and (to cut)",
		"translit" : "katatomḗ",
		"lemma" : "κατατομή",
		"kjv" : "concision"
	},
	"G2309" : {
		"kjv" : "desire, be disposed (forward), intend, list, love, mean, please, have rather, (be) will (have, -ling, - ling(-ly))",
		"translit" : "thélō",
		"lemma" : "θέλω",
		"derivation" : "apparently strengthened from the alternate form of G138 (αἱρέομαι)",
		"def" : "to determine (as an active option from subjective impulse; whereas G1014 (βούλομαι) properly denotes rather a passive acquiescence in objective considerations), i.e. choose or prefer (literally or figuratively); by implication, to wish, i.e. be inclined to (sometimes adverbially, gladly); impersonally for the future tense, to be about to; by Hebraism, to delight in"
	},
	"G5193" : {
		"lemma" : "ὑάλινος",
		"translit" : "hyálinos",
		"kjv" : "of glass",
		"def" : "glassy, i.e. transparent",
		"derivation" : "from G5194 (ὕαλος)"
	},
	"G3388" : {
		"kjv" : "womb",
		"lemma" : "μήτρα",
		"translit" : "mḗtra",
		"derivation" : "from G3384 (μήτηρ)",
		"def" : "the matrix"
	},
	"G4860" : {
		"def" : "to compute jointly",
		"derivation" : "from G4862 (σύν) and G5585 (ψηφίζω)",
		"lemma" : "συμψηφίζω",
		"translit" : "sympsēphízō",
		"kjv" : "reckon"
	},
	"G3587" : {
		"def" : "to shave or \"shear\" the hair",
		"derivation" : "from a derivative of the same as G3586 (ξύλον) (meaning a razor)",
		"lemma" : "ξυράω",
		"translit" : "xyráō",
		"kjv" : "shave"
	},
	"G4672" : {
		"kjv" : "Solomon",
		"translit" : "Solomṓn",
		"lemma" : "Σολομών",
		"derivation" : "of Hebrew origin (H8010)",
		"def" : "Solomon (i.e. Shelomoh), the son of David"
	},
	"G2463" : {
		"translit" : "îris",
		"lemma" : "ἶρις",
		"kjv" : "rainbow",
		"def" : "a rainbow (\"iris\")",
		"derivation" : "perhaps from G2046 (ἐρέω) (as a symbol of the female messenger of the pagan deities)"
	},
	"G3545" : {
		"kjv" : "lawfully",
		"translit" : "nomímōs",
		"lemma" : "νομίμως",
		"derivation" : "adverb from a derivative of G3551 (νόμος)",
		"def" : "legitimately (specially, agreeably to the rules of the lists)"
	},
	"G723" : {
		"derivation" : "from (to till)",
		"def" : "a plow",
		"kjv" : "plough",
		"lemma" : "ἄροτρον",
		"translit" : "árotron"
	},
	"G891" : {
		"derivation" : "akin to G206 (ἄκρον) (through the idea of a terminus)",
		"def" : "(of time) until or (of place) up to",
		"kjv" : "as far as, for, in(-to), till, (even, un-)to, until, while",
		"translit" : "áchri",
		"lemma" : "ἄχρι"
	},
	"G403" : {
		"lemma" : "ἀνάψυξις",
		"translit" : "anápsyxis",
		"kjv" : "revival",
		"def" : "properly, a recovery of breath, i.e. (figuratively) revival",
		"derivation" : "from G404 (ἀναψύχω)"
	},
	"G4430" : {
		"translit" : "ptōma",
		"lemma" : "πτῶμα",
		"kjv" : "dead body, carcase, corpse",
		"def" : "a ruin, i.e. (specially), lifeless body (corpse, carrion)",
		"derivation" : "from the alternate of G4098 (πίπτω)"
	},
	"G4659" : {
		"lemma" : "σκυθρωπός",
		"translit" : "skythrōpós",
		"kjv" : "of a sad countenance",
		"def" : "angry-visaged, i.e. gloomy or affecting a mournful appearance",
		"derivation" : "from (sullen) and a derivative of G3700 (ὀπτάνομαι)"
	},
	"G3178" : {
		"translit" : "méthē",
		"lemma" : "μέθη",
		"kjv" : "drunkenness",
		"def" : "an intoxicant, i.e. (by implication) intoxication",
		"derivation" : "apparently a primary word"
	},
	"G3122" : {
		"derivation" : "neuter plural of the superlative of an apparently primary adverb (very)",
		"def" : "(adverbially) most (in the greatest degree) or particularly",
		"kjv" : "chiefly, most of all, (e-)specially",
		"translit" : "málista",
		"lemma" : "μάλιστα"
	},
	"G3925" : {
		"kjv" : "army, camp, castle",
		"lemma" : "παρεμβολή",
		"translit" : "parembolḗ",
		"derivation" : "from a compound of G3844 (παρά) and G1685 (ἐμβάλλω)",
		"def" : "a throwing in beside (juxtaposition), i.e. (specially), battle-array, encampment or barracks (tower Antonia)"
	},
	"G3694" : {
		"kjv" : "after, back(-ward), (+ get) behind, + follow",
		"lemma" : "ὀπίσω",
		"translit" : "opísō",
		"derivation" : "from the same as G3693 (ὄπισθεν) with enclitic of direction",
		"def" : "to the back, i.e. aback (as adverb or preposition of time or place; or as noun)"
	},
	"G528" : {
		"kjv" : "meet",
		"translit" : "apantáō",
		"lemma" : "ἀπαντάω",
		"derivation" : "from G575 (ἀπό) and a derivative of G473 (ἀντί)",
		"def" : "to meet away, i.e. encounter"
	},
	"G2487" : {
		"kjv" : "step",
		"translit" : "íchnos",
		"lemma" : "ἴχνος",
		"derivation" : "from (to arrive",
		"def" : "compare G2240 (ἥκω)); a track (figuratively)"
	},
	"G1890" : {
		"derivation" : "from G1909 (ἐπί) and G875 (ἀφρίζω)",
		"def" : "to foam upon, i.e. (figuratively) to exhibit (a vile passion)",
		"kjv" : "foam out",
		"translit" : "epaphrízō",
		"lemma" : "ἐπαφρίζω"
	},
	"G909" : {
		"derivation" : "from G907 (βαπτίζω)",
		"def" : "ablution (ceremonial or Christian)",
		"kjv" : "baptism, washing",
		"lemma" : "βαπτισμός",
		"translit" : "baptismós"
	},
	"G2537" : {
		"kjv" : "new",
		"translit" : "kainós",
		"lemma" : "καινός",
		"derivation" : "of uncertain affinity; new (especially in freshness; while G3501 (νέος) is properly so with respect to age"
	},
	"G1007" : {
		"kjv" : "Bosor",
		"lemma" : "Βοσόρ",
		"translit" : "Bosór",
		"derivation" : "of Hebrew origin (H1160)",
		"def" : "Bosor (i.e. Beor), a Moabite"
	},
	"G2961" : {
		"kjv" : "have dominion over, lord, be lord of, exercise lordship over",
		"translit" : "kyrieúō",
		"lemma" : "κυριεύω",
		"derivation" : "from G2962 (κύριος)",
		"def" : "to rule"
	},
	"G929" : {
		"translit" : "basanismós",
		"lemma" : "βασανισμός",
		"kjv" : "torment",
		"def" : "torture",
		"derivation" : "from G928 (βασανίζω)"
	},
	"G3815" : {
		"kjv" : "play",
		"lemma" : "παίζω",
		"translit" : "paízō",
		"derivation" : "from G3816 (παῖς)",
		"def" : "to sport (as a boy)"
	},
	"G3793" : {
		"lemma" : "ὄχλος",
		"translit" : "óchlos",
		"kjv" : "company, multitude, number (of people), people, press",
		"def" : "a throng (as borne along); by implication, the rabble; by extension, a class of people; figuratively, a riot",
		"derivation" : "from a derivative of G2192 (ἔχω) (meaning a vehicle)"
	},
	"G4226" : {
		"def" : "as adverb of place; at (by implication, to) what locality",
		"derivation" : "genitive case of an interrogative pronoun (what) otherwise obsolete (perhaps the same as G4225 (πού) used with the rising slide of inquiry)",
		"lemma" : "ποῦ",
		"translit" : "poû",
		"kjv" : "where, whither"
	},
	"G4465" : {
		"lemma" : "ῥαβδοῦχος",
		"translit" : "rhabdoûchos",
		"kjv" : "serjeant",
		"def" : "a rod- (the Latin fasces) holder, i.e. a Roman lictor (constable or executioner)",
		"derivation" : "from G4464 (ῥάβδος) and G2192 (ἔχω)"
	},
	"G3331" : {
		"lemma" : "μετάθεσις",
		"translit" : "metáthesis",
		"kjv" : "change, removing, translation",
		"def" : "transposition, i.e. transferral (to heaven), disestablishment (of a law)",
		"derivation" : "from G3346 (μετατίθημι)"
	},
	"G1974" : {
		"derivation" : "from G1971 (ἐπιποθέω)",
		"def" : "intense longing",
		"kjv" : "great desire",
		"lemma" : "ἐπιποθία",
		"translit" : "epipothía"
	},
	"G2909" : {
		"derivation" : "comparative of a derivative of G2904 (κράτος)",
		"def" : "stronger, i.e. (figuratively) better, i.e. nobler",
		"kjv" : "best, better",
		"translit" : "kreíttōn",
		"lemma" : "κρείττων"
	},
	"G3838" : {
		"lemma" : "παντελής",
		"translit" : "pantelḗs",
		"kjv" : "+ in (no) wise, uttermost",
		"def" : "full-ended, i.e. entire (neuter as noun, completion)",
		"derivation" : "from G3956 (πᾶς) and G5056 (τέλος)"
	},
	"G856" : {
		"def" : "a place of sitting apart, i.e. a privy",
		"derivation" : "from a compound of G575 (ἀπό) and the base of G1476 (ἑδραῖος)",
		"translit" : "aphedrṓn",
		"lemma" : "ἀφεδρών",
		"kjv" : "draught"
	},
	"G179" : {
		"kjv" : "endless",
		"lemma" : "ἀκατάλυτος",
		"translit" : "akatálytos",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G2647 (καταλύω)",
		"def" : "indissoluble, i.e. (figuratively) permanent"
	},
	"G862" : {
		"lemma" : "ἄφθαρτος",
		"translit" : "áphthartos",
		"kjv" : "not (in-, un-)corruptible, immortal",
		"def" : "undecaying (in essence or continuance)",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G5351 (φθείρω)"
	},
	"G3098" : {
		"kjv" : "Magog",
		"lemma" : "Μαγώγ",
		"translit" : "Magṓg",
		"derivation" : "of Hebrew origin (H4031)",
		"def" : "Magog, a foreign nation, i.e. (figuratively) an Antichristian party"
	},
	"G1708" : {
		"derivation" : "from G1707 (ἐμπλέκω)",
		"def" : "elaborate braiding of the hair",
		"kjv" : "plaiting",
		"lemma" : "ἐμπλοκή",
		"translit" : "emplokḗ"
	},
	"G146" : {
		"kjv" : "given to (greedy of) filthy lucre",
		"lemma" : "αἰσχροκερδής",
		"translit" : "aischrokerdḗs",
		"derivation" : "from G150 (αἰσχρός) and (gain)",
		"def" : "sordid"
	},
	"G5478" : {
		"translit" : "Chanaanaîos",
		"lemma" : "Χανααναῖος",
		"kjv" : "of Canaan",
		"def" : "a Chanaanæan (i.e. Kenaanite), or native of gentile Palestine",
		"derivation" : "from G5477 (Χαναάν)"
	},
	"G5392" : {
		"def" : "to muzzle",
		"derivation" : "from (a muzzle)",
		"translit" : "phimóō",
		"lemma" : "φιμόω",
		"kjv" : "muzzle"
	},
	"G5602" : {
		"kjv" : "here, hither, (in) this place, there",
		"lemma" : "ὧδε",
		"translit" : "hōde",
		"derivation" : "from an adverb form of G3592 (ὅδε)",
		"def" : "in this same spot, i.e. here or hither"
	},
	"G1632" : {
		"translit" : "ekchéō",
		"lemma" : "ἐκχέω",
		"kjv" : "gush (pour) out, run greedily (out), shed (abroad, forth), spill",
		"def" : "to pour forth; figuratively, to bestow",
		"derivation" : "from G1537 (ἐκ) and (to pour)"
	},
	"G246" : {
		"derivation" : "from G243 (ἄλλος) and G5443 (φυλή)",
		"def" : "foreign, i.e. (specially) Gentile",
		"kjv" : "one of another nation",
		"lemma" : "ἀλλόφυλος",
		"translit" : "allóphylos"
	},
	"G915" : {
		"lemma" : "βάρβαρος",
		"translit" : "bárbaros",
		"kjv" : "barbarian(-rous)",
		"def" : "a foreigner (i.e. non-Greek)",
		"derivation" : "of uncertain derivation"
	},
	"G591" : {
		"def" : "to give away, i.e. up, over, back, etc. (in various applications)",
		"derivation" : "from G575 (ἀπό) and G1325 (δίδωμι)",
		"translit" : "apodídōmi",
		"lemma" : "ἀποδίδωμι",
		"kjv" : "deliver (again), give (again), (re-)pay(-ment be made), perform, recompense, render, requite, restore, reward, sell, yield"
	},
	"G3783" : {
		"translit" : "opheílēma",
		"lemma" : "ὀφείλημα",
		"kjv" : "debt",
		"def" : "something owed, i.e. (figuratively) a due; morally, a fault",
		"derivation" : "from (the alternate of) G3784 (ὀφείλω)"
	},
	"G510" : {
		"kjv" : "upper",
		"lemma" : "ἀνωτερικός",
		"translit" : "anōterikós",
		"derivation" : "from G511 (ἀνώτερος)",
		"def" : "superior, i.e. (locally) more remote"
	},
	"G3874" : {
		"lemma" : "παράκλησις",
		"translit" : "paráklēsis",
		"kjv" : "comfort, consolation, exhortation, intreaty",
		"def" : "imploration, hortation, solace",
		"derivation" : "from G3870 (παρακαλέω)"
	},
	"G2761" : {
		"def" : "vainly, i.e. to no purpose",
		"derivation" : "adverb from G2756 (κενός)",
		"translit" : "kenōs",
		"lemma" : "κενῶς",
		"kjv" : "in vain"
	},
	"G1246" : {
		"lemma" : "διακατελέγχομαι",
		"translit" : "diakatelénchomai",
		"kjv" : "convince",
		"def" : "to prove downright, i.e. confute",
		"derivation" : "middle voice from G1223 (διά) and a compound of G2596 (κατά) and G1651 (ἐλέγχω)"
	},
	"G5208" : {
		"kjv" : "matter",
		"lemma" : "ὕλη",
		"translit" : "hýlē",
		"derivation" : "perhaps akin to G3586 (ξύλον)",
		"def" : "a forest, i.e. (by implication) fuel"
	},
	"G1741" : {
		"lemma" : "ἔνδοξος",
		"translit" : "éndoxos",
		"kjv" : "glorious, gorgeous(-ly), honourable",
		"def" : "in glory, i.e. splendid, (figuratively) noble",
		"derivation" : "from G1722 (ἐν) and G1391 (δόξα)"
	},
	"G1724" : {
		"derivation" : "from G1722 (ἐν) and G251 (ἅλς)",
		"def" : "in the sea, i.e. marine",
		"kjv" : "thing in the sea",
		"translit" : "enálios",
		"lemma" : "ἐνάλιος"
	},
	"G1519" : {
		"kjv" : "(abundant-)ly, against, among, as, at, (back-)ward, before, by, concerning, + continual, + far more exceeding, for (intent, purpose), fore, + forth, in (among, at, unto, -so much that, -to), to the intent that, + of one mind, + never, of, (up-)on, + perish, + set at one again, (so) that, therefore(-unto), throughout, til, to (be, the end, -ward), (here-)until(-to), …ward, (where-)fore, with",
		"lemma" : "εἰς",
		"translit" : "eis",
		"derivation" : "a primary preposition",
		"def" : "to or into (indicating the point reached or entered), of place, time, or (figuratively) purpose (result, etc.); also in adverbial phrases"
	},
	"G4972" : {
		"kjv" : "(set a, set to) seal up, stop",
		"lemma" : "σφραγίζω",
		"translit" : "sphragízō",
		"derivation" : "from G4973 (σφραγίς)",
		"def" : "to stamp (with a signet or private mark) for security or preservation (literally or figuratively); by implication, to keep secret, to attest"
	},
	"G4956" : {
		"kjv" : "of commendation",
		"translit" : "systatikós",
		"lemma" : "συστατικός",
		"derivation" : "from a derivative of G4921 (συνιστάω)",
		"def" : "introductory, i.e. recommendatory"
	},
	"G3404" : {
		"kjv" : "hate(-ful)",
		"translit" : "miséō",
		"lemma" : "μισέω",
		"derivation" : "from a primary (hatred)",
		"def" : "to detest (especially to persecute); by extension, to love less"
	},
	"G3468" : {
		"kjv" : "stripe",
		"translit" : "mṓlōps",
		"lemma" : "μώλωψ",
		"derivation" : "from (\"moil\"",
		"def" : "probably akin to the base of G3433 (μόλις)) and probably (the face; from G3700 (ὀπτάνομαι)); a mole (\"black eye\") or blow-mark"
	},
	"G881" : {
		"def" : "Achaz, an Israelite",
		"derivation" : "of Hebrew origin (H271)",
		"translit" : "Acház",
		"lemma" : "Ἀχάζ",
		"kjv" : "Achaz"
	},
	"G1790" : {
		"lemma" : "ἔντρομος",
		"translit" : "éntromos",
		"kjv" : "X quake, X trembled",
		"def" : "terrified",
		"derivation" : "from G1722 (ἐν) and G5156 (τρόμος)"
	},
	"G4901" : {
		"def" : "to testify further jointly, i.e. unite in adding evidence",
		"derivation" : "from G4862 (σύν) and G1957 (ἐπιμαρτυρέω)",
		"translit" : "synepimartyréō",
		"lemma" : "συνεπιμαρτυρέω",
		"kjv" : "also bear witness"
	},
	"G2206" : {
		"lemma" : "ζηλόω",
		"translit" : "zēlóō",
		"kjv" : "affect, covet (earnestly), (have) desire, (move with) envy, be jealous over, (be) zealous(-ly affect)",
		"def" : "to have warmth of feeling for or against",
		"derivation" : "from G2205 (ζῆλος)"
	},
	"G714" : {
		"translit" : "arkéō",
		"lemma" : "ἀρκέω",
		"kjv" : "be content, be enough, suffice, be sufficient",
		"def" : "properly, to ward off, i.e. (by implication) to avail (figuratively, be satisfactory)",
		"derivation" : "apparently a primary verb (but probably akin to G142 (αἴρω) through the idea of raising a barrier)"
	},
	"G2415" : {
		"kjv" : "of Jerusalem",
		"lemma" : "Ἱεροσολυμίτης",
		"translit" : "Hierosolymítēs",
		"derivation" : "from G2414 (Ἱεροσόλυμα)",
		"def" : "a Hierosolymite, i.e. inhabitant of Hierosolyma"
	},
	"G2069" : {
		"translit" : "Eslí",
		"lemma" : "Ἐσλί",
		"kjv" : "Esli",
		"def" : "Esli, an Israelite",
		"derivation" : "of Hebrew origin (probably for H454)"
	},
	"G434" : {
		"derivation" : "from G1 (Α) (as a negative particle) and (lame)",
		"def" : "savage",
		"kjv" : "fierce",
		"lemma" : "ἀνήμερος",
		"translit" : "anḗmeros"
	},
	"G4201" : {
		"derivation" : "of Latin origin",
		"def" : "apparently swinish; Porcius, a Roman",
		"kjv" : "Porcius",
		"lemma" : "Πόρκιος",
		"translit" : "Pórkios"
	},
	"G90" : {
		"def" : "incorruptibleness, i.e. (figuratively) purity (of doctrine)",
		"derivation" : "from a derivative of a compound of G1 (Α) (as a negative particle) and a derivative of G1311 (διαφθείρω)",
		"translit" : "adiaphthoría",
		"lemma" : "ἀδιαφθορία",
		"kjv" : "uncorruptness"
	},
	"G4854" : {
		"lemma" : "σύμφυτος",
		"translit" : "sýmphytos",
		"kjv" : "planted together",
		"def" : "grown along with (connate), i.e. (figuratively) closely united to",
		"derivation" : "from G4862 (σύν) and a derivative of G5453 (φύω)"
	},
	"G1715" : {
		"def" : "in front of (in place (literally or figuratively) or time)",
		"derivation" : "from G1722 (ἐν) and G4314 (πρός)",
		"lemma" : "ἔμπροσθεν",
		"translit" : "émprosthen",
		"kjv" : "against, at, before, (in presence, sight) of"
	},
	"G1066" : {
		"def" : "Gedeon (i.e. Gid(e)on), an Israelite",
		"derivation" : "of Hebrew origin (H1439)",
		"lemma" : "Γεδεών",
		"translit" : "Gedeṓn",
		"kjv" : "Gedeon (in the King James Version)"
	},
	"G1270" : {
		"derivation" : "from a compound of G1223 (διά) and G3539 (νοιέω)",
		"def" : "something thought through, i.e. a sentiment",
		"kjv" : "thought",
		"lemma" : "διανόημα",
		"translit" : "dianóēma"
	},
	"G3578" : {
		"derivation" : "from G3581 (ξένος)",
		"def" : "hospitality, i.e. (by implication) a place of entertainment",
		"kjv" : "lodging",
		"translit" : "xenía",
		"lemma" : "ξενία"
	},
	"G5370" : {
		"translit" : "phílēma",
		"lemma" : "φίλημα",
		"kjv" : "kiss",
		"def" : "a kiss",
		"derivation" : "from G5368 (φιλέω)"
	},
	"G799" : {
		"translit" : "Asýnkritos",
		"lemma" : "Ἀσύγκριτος",
		"kjv" : "Asyncritos",
		"def" : "incomparable; Asyncritus, a Christian",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G4793 (συγκρίνω)"
	},
	"G5531" : {
		"lemma" : "χράω",
		"translit" : "chráō",
		"kjv" : "lend",
		"def" : "to loan",
		"derivation" : "probably the same as the base of G5530 (χράομαι)"
	},
	"G1185" : {
		"kjv" : "allure, beguile, entice",
		"translit" : "deleázō",
		"lemma" : "δελεάζω",
		"derivation" : "from the base of G1388 (δόλος)",
		"def" : "to entrap, i.e. (figuratively) delude"
	},
	"G5148" : {
		"translit" : "trietía",
		"lemma" : "τριετία",
		"kjv" : "space of three years",
		"def" : "a three years' period (triennium)",
		"derivation" : "from a compound of G5140 (τρεῖς) and G2094 (ἔτος)"
	},
	"G4824" : {
		"def" : "advisement; specially, a deliberative body, i.e. the provincial assessors or lay-court",
		"derivation" : "neuter of a presumed derivative of G4825 (σύμβουλος)",
		"lemma" : "συμβούλιον",
		"translit" : "symboúlion",
		"kjv" : "consultation, counsel, council"
	},
	"G3411" : {
		"translit" : "misthōtós",
		"lemma" : "μισθωτός",
		"kjv" : "hired servant, hireling",
		"def" : "a wage-worker (good or bad)",
		"derivation" : "from G3409 (μισθόω)"
	},
	"G1999" : {
		"translit" : "episýstasis",
		"lemma" : "ἐπισύστασις",
		"kjv" : "that which cometh upon, + raising up",
		"def" : "a conspiracy, i.e. concourse (riotous or friendly)",
		"derivation" : "from the middle voice of a compound of G1909 (ἐπί) and G4921 (συνιστάω)"
	},
	"G963" : {
		"derivation" : "of Chaldee origin",
		"def" : "date-house; Beth-any, a place in Palestine",
		"kjv" : "Bethany",
		"lemma" : "Βηθανία",
		"translit" : "Bēthanía"
	},
	"G1932" : {
		"derivation" : "from G1933 (ἐπιεικής)",
		"def" : "suitableness, i.e. (by implication) equity, mildness",
		"kjv" : "clemency, gentleness",
		"lemma" : "ἐπιείκεια",
		"translit" : "epieíkeia"
	},
	"G2239" : {
		"lemma" : "ἦθος",
		"translit" : "ēthos",
		"kjv" : "manners",
		"def" : "usage, i.e. (plural) moral habits",
		"derivation" : "a strengthened form of G1485 (ἔθος)"
	},
	"G4083" : {
		"derivation" : "of uncertain affinity",
		"def" : "the fore-arm, i.e. (as a measure) a cubit",
		"kjv" : "cubit",
		"lemma" : "πῆχυς",
		"translit" : "pēchys"
	},
	"G419" : {
		"def" : "not searched out, i.e. (by implication) inscrutable",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G1830 (ἐξερευνάω)",
		"lemma" : "ἀνεξερεύνητος",
		"translit" : "anexereúnētos",
		"kjv" : "unsearchable"
	},
	"G1794" : {
		"def" : "probably akin to G1507 (εἱλίσσω)); to entwine, i.e. wind up in",
		"derivation" : "from G1722 (ἐν) and (to twist",
		"lemma" : "ἐντυλίσσω",
		"translit" : "entylíssō",
		"kjv" : "wrap in (together)"
	},
	"G119" : {
		"kjv" : "fight",
		"translit" : "áthlēsis",
		"lemma" : "ἄθλησις",
		"derivation" : "from G118 (ἀθλέω)",
		"def" : "a struggle (figuratively)"
	},
	"G4215" : {
		"derivation" : "probably from a derivative of the alternate of G4095 (πίνω) (compare G4224 (πότος))",
		"def" : "a current, brook or freshet (as drinkable), i.e. running water",
		"kjv" : "flood, river, stream, water",
		"lemma" : "ποταμός",
		"translit" : "potamós"
	},
	"G3064" : {
		"def" : "remaining time",
		"derivation" : "genitive case singular of the same as G3062 (λοιποί)",
		"translit" : "loipoû",
		"lemma" : "λοιποῦ",
		"kjv" : "from henceforth"
	},
	"G722" : {
		"def" : "to plow",
		"derivation" : "from G723 (ἄροτρον)",
		"lemma" : "ἀροτριόω",
		"translit" : "arotrióō",
		"kjv" : "plough"
	},
	"G1691" : {
		"derivation" : "a prolonged form of G3165 (μέ)",
		"def" : "me",
		"kjv" : "I, me, my(-self)",
		"translit" : "emé",
		"lemma" : "ἐμέ"
	},
	"G5171" : {
		"kjv" : "live in pleasure",
		"lemma" : "τρυφάω",
		"translit" : "trypháō",
		"derivation" : "from G5172 (τρυφή)",
		"def" : "to indulge in luxury"
	},
	"G1534" : {
		"kjv" : "after that(-ward), furthermore, then",
		"lemma" : "εἶτα",
		"translit" : "eîta",
		"derivation" : "of uncertain affinity",
		"def" : "a particle of succession (in time or logical enumeration), then, moreover"
	},
	"G2630" : {
		"def" : "to precipitate down",
		"derivation" : "from G2596 (κατά) and a derivative of G2911 (κρημνός)",
		"translit" : "katakrēmnízō",
		"lemma" : "κατακρημνίζω",
		"kjv" : "cast down headlong"
	},
	"G3627" : {
		"kjv" : "have compassion on",
		"translit" : "oikteírō",
		"lemma" : "οἰκτείρω",
		"derivation" : "from (pity)",
		"def" : "to exercise pity"
	},
	"G5076" : {
		"derivation" : "from G5064 (τέσσαρες) and G757 (ἄρχω)",
		"def" : "the ruler of a fourth part of a country (\"tetrarch\")",
		"kjv" : "tetrarch",
		"lemma" : "τετράρχης",
		"translit" : "tetrárchēs"
	},
	"G2978" : {
		"kjv" : "storm, tempest",
		"translit" : "laîlaps",
		"lemma" : "λαῖλαψ",
		"derivation" : "of uncertain derivation",
		"def" : "a whirlwind (squall)"
	},
	"G3195" : {
		"translit" : "méllō",
		"lemma" : "μέλλω",
		"kjv" : "about, after that, be (almost), (that which is, things, + which was for) to come, intend, was to (be), mean, mind, be at the point, (be) ready, + return, shall (begin), (which, that) should (after, afterwards, hereafter) tarry, which was for, will, would, be yet",
		"def" : "to intend, i.e. be about to be, do, or suffer something (of persons or things, especially events; in the sense of purpose, duty, necessity, probability, possibility, or hesitation)",
		"derivation" : "a strengthened form of G3199 (μέλω) (through the idea of expectation)"
	},
	"G1128" : {
		"derivation" : "from G1131 (γυμνός)",
		"def" : "to practise naked (in the games), i.e. train (figuratively)",
		"kjv" : "exercise",
		"lemma" : "γυμνάζω",
		"translit" : "gymnázō"
	},
	"G4527" : {
		"derivation" : "of Hebrew origin (H7974)",
		"def" : "Sala (i.e. Shelach), a patriarch ",
		"kjv" : "Sala",
		"translit" : "Salá",
		"lemma" : "Σαλά"
	},
	"G1167" : {
		"derivation" : "from G1169 (δειλός)",
		"def" : "timidity",
		"kjv" : "fear",
		"translit" : "deilía",
		"lemma" : "δειλία"
	},
	"G2437" : {
		"derivation" : "neuter of an adjective from a name of uncertain derivation",
		"def" : "(the) Illyrican (shore), i.e. (as a name itself) Illyricum, a region of Europe",
		"kjv" : "Illyricum",
		"translit" : "Illyrikón",
		"lemma" : "Ἰλλυρικόν"
	},
	"G5142" : {
		"derivation" : "a primary verb (properly, ",
		"def" : "but perhaps strengthened from the base of G5157 (τροπή) through the idea of convolution); properly, to stiffen, i.e. fatten (by implication, to cherish (with food, etc.), pamper, rear)",
		"kjv" : "bring up, feed, nourish",
		"translit" : "tréphō",
		"lemma" : "τρέφω"
	},
	"G2930" : {
		"derivation" : "from a derivative of (frost)",
		"def" : "ice, i.e. (by analogy) rock \"crystal\"",
		"kjv" : "crystal",
		"translit" : "krýstallos",
		"lemma" : "κρύσταλλος"
	},
	"G5613" : {
		"kjv" : "about, after (that), (according) as (it had been, it were), as soon (as), even as (like), for, how (greatly), like (as, unto), since, so (that), that, to wit, unto, when(-soever), while, X with all speed",
		"translit" : "hōs",
		"lemma" : "ὡς",
		"derivation" : "probably adverb of comparative from G3739 (ὅς)",
		"def" : "which how, i.e. in that manner (very variously used, as follows)"
	},
	"G3006" : {
		"kjv" : "smooth",
		"lemma" : "λεῖος",
		"translit" : "leîos",
		"derivation" : "apparently a primary word",
		"def" : "smooth, i.e. \"level\""
	},
	"G2671" : {
		"kjv" : "curse(-d, ing)",
		"lemma" : "κατάρα",
		"translit" : "katára",
		"derivation" : "from G2596 (κατά) (intensive) and G685 (ἀρά)",
		"def" : "imprecation, execration"
	},
	"G4463" : {
		"derivation" : "from G4464 (ῥάβδος)",
		"def" : "to strike with a stick, i.e. bastinado",
		"kjv" : "beat (with rods)",
		"translit" : "rhabdízō",
		"lemma" : "ῥαβδίζω"
	},
	"G5387" : {
		"kjv" : "kindly affectioned",
		"translit" : "philóstorgos",
		"lemma" : "φιλόστοργος",
		"derivation" : "from G5384 (φίλος) and (cherishing one's kindred, especially parents or children)",
		"def" : "fond of natural relatives, i.e. fraternal towards fellow Christian"
	},
	"G1368" : {
		"derivation" : "from G1223 (διά) and (to filter)",
		"def" : "to strain out",
		"kjv" : "strain at (probably by misprint)",
		"translit" : "diÿlízō",
		"lemma" : "διϋλίζω"
	},
	"G1010" : {
		"lemma" : "βουλευτής",
		"translit" : "bouleutḗs",
		"kjv" : "counsellor",
		"def" : "an adviser, i.e. (specially) a councillor or member of the Jewish Sanhedrin",
		"derivation" : "from G1011 (βουλεύω)"
	},
	"G1379" : {
		"derivation" : "from G1378 (δόγμα)",
		"def" : "to prescribe by statute, i.e. (reflexively) to submit to, ceremonially rule",
		"kjv" : "be subject to ordinances",
		"lemma" : "δογματίζω",
		"translit" : "dogmatízō"
	},
	"G1374" : {
		"def" : "two-spirited, i.e. vacillating (in opinion or purpose)",
		"derivation" : "from G1364 (δίς) and G5590 (ψυχή)",
		"lemma" : "δίψυχος",
		"translit" : "dípsychos",
		"kjv" : "double minded"
	},
	"G1698" : {
		"kjv" : "I, me, mine, my",
		"translit" : "emoí",
		"lemma" : "ἐμοί",
		"derivation" : "a prolonged form of G3427 (μοί)",
		"def" : "to me"
	},
	"G190" : {
		"lemma" : "ἀκολουθέω",
		"translit" : "akolouthéō",
		"kjv" : "follow, reach",
		"def" : "properly, to be in the same way with, i.e. to accompany (specially, as a disciple)",
		"derivation" : "from G1 (Α) (as a particle of union) and (a road)"
	},
	"G4690" : {
		"lemma" : "σπέρμα",
		"translit" : "spérma",
		"kjv" : "issue, seed",
		"def" : "something sown, i.e. seed (including the male \"sperm\"); by implication, offspring; specially, a remnant (figuratively, as if kept over for planting)",
		"derivation" : "from G4687 (σπείρω)"
	},
	"G350" : {
		"kjv" : "ask, question, discern, examine, judge, search",
		"lemma" : "ἀνακρίνω",
		"translit" : "anakrínō",
		"derivation" : "from G303 (ἀνά) and G2919 (κρίνω)",
		"def" : "properly, to scrutinize, i.e. (by implication) investigate, interrogate, determine"
	},
	"G2377" : {
		"def" : "a gate- warden",
		"derivation" : "from G2374 (θύρα) and (a watcher)",
		"lemma" : "θυρωρός",
		"translit" : "thyrōrós",
		"kjv" : "that kept the door, porter"
	},
	"G2958" : {
		"def" : "Cyrenius (i.e. Quirinus), a Roman",
		"derivation" : "of Latin origin",
		"lemma" : "Κυρήνιος",
		"translit" : "Kyrḗnios",
		"kjv" : "Cyrenius"
	},
	"G122" : {
		"def" : "belonging to a goat",
		"derivation" : "from (a goat)",
		"translit" : "aígeios",
		"lemma" : "αἴγειος",
		"kjv" : "goat"
	},
	"G1852" : {
		"def" : "to waken",
		"derivation" : "from G1853 (ἔξυπνος)",
		"translit" : "exypnízō",
		"lemma" : "ἐξυπνίζω",
		"kjv" : "awake out of sleep"
	},
	"G1126" : {
		"def" : "crone-like, i.e. silly",
		"derivation" : "from (an old woman) and G1491 (εἶδος)",
		"translit" : "graṓdēs",
		"lemma" : "γραώδης",
		"kjv" : "old wives'"
	},
	"G1626" : {
		"def" : "a miscarriage (abortion), i.e. (by analogy) untimely birth",
		"derivation" : "from a comparative of G1537 (ἐκ) and (to wound)",
		"translit" : "éktrōma",
		"lemma" : "ἔκτρωμα",
		"kjv" : "born out of due time"
	},
	"G1836" : {
		"lemma" : "ἑξῆς",
		"translit" : "hexēs",
		"kjv" : "after, following, X morrow, next",
		"def" : "successive",
		"derivation" : "from G2192 (ἔχω) (in the sense of taking hold of, i.e. adjoining)"
	},
	"G2689" : {
		"derivation" : "from G2687 (καταστέλλω)",
		"def" : "a deposit, i.e. (specially) costume",
		"kjv" : "apparel",
		"translit" : "katastolḗ",
		"lemma" : "καταστολή"
	},
	"G2619" : {
		"kjv" : "cover, hide",
		"translit" : "katakalýptō",
		"lemma" : "κατακαλύπτω",
		"derivation" : "from G2596 (κατά) and G2572 (καλύπτω)",
		"def" : "to cover wholly, i.e. veil"
	},
	"G2094" : {
		"def" : "a year",
		"derivation" : "apparently a primary word",
		"translit" : "étos",
		"lemma" : "ἔτος",
		"kjv" : "year"
	},
	"G3070" : {
		"kjv" : "Lydia",
		"lemma" : "Λυδία",
		"translit" : "Lydía",
		"derivation" : "properly, feminine of Ludios (of foreign origin) (a Lydian, in Asia Minor)",
		"def" : "Lydia, a Christian woman"
	},
	"G4163" : {
		"derivation" : "from G4160 (ποιέω)",
		"def" : "a performer; specially, a \"poet\"; ",
		"kjv" : "--doer, poet",
		"lemma" : "ποιητής",
		"translit" : "poiētḗs"
	},
	"G5216" : {
		"kjv" : "ye, you, your (own, -selves)",
		"translit" : "hymōn",
		"lemma" : "ὑμῶν",
		"derivation" : "genitive case of G5210 (ὑμεῖς)",
		"def" : "of (from or concerning) you"
	},
	"G109" : {
		"kjv" : "air",
		"lemma" : "ἀήρ",
		"translit" : "aḗr",
		"derivation" : "from (to breathe unconsciously, i.e. respire",
		"def" : "by analogy, to blow); \"air\" (as naturally circumambient)"
	},
	"G4577" : {
		"derivation" : "probably from G4951 (σύρω) through its congener (to fasten",
		"def" : "akin to G138 (αἱρέομαι)); a chain (as binding or drawing)",
		"kjv" : "chain",
		"translit" : "seirá",
		"lemma" : "σειρά"
	},
	"G1229" : {
		"derivation" : "from G1223 (διά) and the base of G32 (ἄγγελος)",
		"def" : "to herald thoroughly",
		"kjv" : "declare, preach, signify",
		"lemma" : "διαγγέλλω",
		"translit" : "diangéllō"
	},
	"G2203" : {
		"kjv" : "Jupiter",
		"translit" : "Zeús",
		"lemma" : "Ζεύς",
		"derivation" : "of uncertain affinity",
		"def" : "in the oblique cases there is used instead of it a (probably cognate) name , which is otherwise obsolete; Zeus or Dis (among the Latins, Jupiter or Jove), the supreme deity of the Greeks"
	},
	"G5349" : {
		"derivation" : "from G5351 (φθείρω)",
		"def" : "decayed, i.e. (by implication) perishable",
		"kjv" : "corruptible",
		"translit" : "phthartós",
		"lemma" : "φθαρτός"
	},
	"G971" : {
		"translit" : "biázō",
		"lemma" : "βιάζω",
		"kjv" : "press, suffer violence",
		"def" : "to force, i.e. (reflexively) to crowd oneself (into), or (passively) to be seized",
		"derivation" : "from G979 (βίος)"
	},
	"G4214" : {
		"kjv" : "how great (long, many), what",
		"lemma" : "πόσος",
		"translit" : "pósos",
		"derivation" : "from an absolute (who, what) and G3739 (ὅς)",
		"def" : "interrogative pronoun (of amount) how much (large, long or (plural) many)"
	},
	"G3872" : {
		"kjv" : "that (thing) which is committed (un-)to (trust)",
		"translit" : "parakatathḗkē",
		"lemma" : "παρακαταθήκη",
		"derivation" : "from a compound of G3844 (παρά) and G2698 (κατατίθημι)",
		"def" : "something put down alongside, i.e. a deposit (sacred trust)"
	},
	"G4206" : {
		"def" : "forwards, i.e. at a distance",
		"derivation" : "adverb from G4253 (πρό)",
		"lemma" : "πόῤῥω",
		"translit" : "pórrhō",
		"kjv" : "far, a great way off"
	},
	"G2608" : {
		"derivation" : "from G2596 (κατά) and the base of G4486 (ῥήγνυμι)",
		"def" : "to rend in pieces, i.e. crack apart",
		"kjv" : "break",
		"lemma" : "κατάγνυμι",
		"translit" : "katágnymi"
	},
	"G5227" : {
		"derivation" : "from G5259 (ὑπό) and G1727 (ἐναντίος)",
		"def" : "under (covertly) contrary to, i.e. opposed or (as noun) an opponent",
		"kjv" : "adversary, against",
		"translit" : "hypenantíos",
		"lemma" : "ὑπεναντίος"
	},
	"G4887" : {
		"lemma" : "συνδέω",
		"translit" : "syndéō",
		"kjv" : "be bound with",
		"def" : "to bind with, i.e. (passively) be a fellow-prisoner (figuratively)",
		"derivation" : "from G4862 (σύν) and G1210 (δέω)"
	},
	"G4332" : {
		"lemma" : "προσεδρεύω",
		"translit" : "prosedreúō",
		"kjv" : "wait at",
		"def" : "to sit near, i.e. attend as a servant",
		"derivation" : "from a compound of G4314 (πρός) and the base of G1476 (ἑδραῖος)"
	},
	"G4174" : {
		"translit" : "politeía",
		"lemma" : "πολιτεία",
		"kjv" : "commonwealth, freedom",
		"def" : "citizenship; concretely, a community",
		"derivation" : "from G4177 (πολίτης) (\"polity\")"
	},
	"G1074" : {
		"lemma" : "γενεά",
		"translit" : "geneá",
		"kjv" : "age, generation, nation, time",
		"def" : "a generation; by implication, an age (the period or the persons)",
		"derivation" : "from (a presumed derivative of) G1085 (γένος)"
	},
	"G478" : {
		"def" : "to set down (troops) against, i.e. withstand",
		"derivation" : "from G473 (ἀντί) and G2525 (καθίστημι)",
		"lemma" : "ἀντικαθίστημι",
		"translit" : "antikathístēmi",
		"kjv" : "resist"
	},
	"G195" : {
		"lemma" : "ἀκρίβεια",
		"translit" : "akríbeia",
		"kjv" : "perfect manner",
		"def" : "exactness",
		"derivation" : "from the same as G196 (ἀκριβέστατος)"
	},
	"G3018" : {
		"kjv" : "Levi",
		"lemma" : "Λευΐς",
		"translit" : "Leuḯs",
		"derivation" : "a form of G3017 (Λευΐ)",
		"def" : "Lewis (i.e. Levi), a Christian"
	},
	"G2529" : {
		"derivation" : "from G2596 (κατά) and G3708 (ὁράω)",
		"def" : "to behold fully, i.e. (figuratively) distinctly apprehend",
		"kjv" : "clearly see",
		"translit" : "kathoráō",
		"lemma" : "καθοράω"
	},
	"G2077" : {
		"def" : "be thou; also , third person of the same; let them be",
		"derivation" : "second person singular present imperative of G1510 (εἰμί)",
		"lemma" : "ἔστω",
		"translit" : "éstō",
		"kjv" : "be"
	},
	"G864" : {
		"kjv" : "come abroad",
		"translit" : "aphiknéomai",
		"lemma" : "ἀφικνέομαι",
		"derivation" : "from G575 (ἀπό) and the base of G2425 (ἱκανός)",
		"def" : "to go (i.e. spread) forth (by rumor)"
	},
	"G3810" : {
		"kjv" : "which corrected, instructor",
		"lemma" : "παιδευτής",
		"translit" : "paideutḗs",
		"derivation" : "from G3811 (παιδεύω)",
		"def" : "a trainer, i.e. teacher or (by implication) discipliner"
	},
	"G5335" : {
		"def" : "to assert",
		"derivation" : "prolongation from the same as G5346 (φημί)",
		"lemma" : "φάσκω",
		"translit" : "pháskō",
		"kjv" : "affirm, profess, say"
	},
	"G4701" : {
		"def" : "a sowing, i.e. (by implication) parentage",
		"derivation" : "from G4687 (σπείρω)",
		"translit" : "sporá",
		"lemma" : "σπορά",
		"kjv" : "seed"
	},
	"G2921" : {
		"kjv" : "Crispus",
		"lemma" : "Κρίσπος",
		"translit" : "Kríspos",
		"derivation" : "of Latin origin",
		"def" : "\"crisp\"; Crispus, a Corinthian"
	},
	"G2539" : {
		"def" : "and indeed, i.e. nevertheless or notwithstanding",
		"derivation" : "from G2532 (καί) and G4007 (περ)",
		"translit" : "kaíper",
		"lemma" : "καίπερ",
		"kjv" : "and yet, although"
	},
	"G556" : {
		"lemma" : "ἀπελαύνω",
		"translit" : "apelaúnō",
		"kjv" : "drive",
		"def" : "to dismiss",
		"derivation" : "from G575 (ἀπό) and G1643 (ἐλαύνω)"
	},
	"G1660" : {
		"def" : "an advent",
		"derivation" : "from the alternate of G2064 (ἔρχομαι)",
		"translit" : "éleusis",
		"lemma" : "ἔλευσις",
		"kjv" : "coming"
	},
	"G1970" : {
		"lemma" : "ἐπιπνίγω",
		"translit" : "epipnígō",
		"kjv" : "choke",
		"def" : "to throttle upon, i.e. (figuratively) overgrow",
		"derivation" : "from G1909 (ἐπί) and G4155 (πνίγω)"
	},
	"G3160" : {
		"def" : "Mattatha (i.e. Mattithjah), an Israelite",
		"derivation" : "probably a shortened form of G3161 (Ματταθίας) (compare G4992 (σωτήριον))",
		"lemma" : "Ματταθά",
		"translit" : "Mattathá",
		"kjv" : "Mattatha"
	},
	"G3580" : {
		"derivation" : "from a compound of G3581 (ξένος) and G1209 (δέχομαι)",
		"def" : "to be hospitable",
		"kjv" : "lodge strangers",
		"lemma" : "ξενοδοχέω",
		"translit" : "xenodochéō"
	},
	"G896" : {
		"kjv" : "Baal",
		"translit" : "Báal",
		"lemma" : "Βάαλ",
		"derivation" : "of Hebrew origin (H1168)",
		"def" : "Baal, a Phœnician deity (used as a symbol of idolatry)"
	},
	"G4604" : {
		"def" : "iron",
		"derivation" : "of uncertain derivation",
		"lemma" : "σίδηρος",
		"translit" : "sídēros",
		"kjv" : "iron"
	},
	"G5527" : {
		"lemma" : "χόρτασμα",
		"translit" : "chórtasma",
		"kjv" : "sustenance",
		"def" : "forage, i.e. food",
		"derivation" : "from G5526 (χορτάζω)"
	},
	"G1453" : {
		"def" : "to waken (transitively or intransitively), i.e. rouse (literally, from sleep, from sitting or lying, from disease, from death; or figuratively, from obscurity, inactivity, ruins, nonexistence)",
		"derivation" : "probably akin to the base of G58 (ἀγορά) (through the idea of collecting one's faculties)",
		"lemma" : "ἐγείρω",
		"translit" : "egeírō",
		"kjv" : "awake, lift (up), raise (again, up), rear up, (a-)rise (again, up), stand, take up"
	},
	"G2922" : {
		"derivation" : "neuter of a presumed derivative of G2923 (κριτής)",
		"def" : "a rule of judging (\"criterion\"), i.e. (by implication) a tribunal",
		"kjv" : "to judge, judgment (seat)",
		"translit" : "kritḗrion",
		"lemma" : "κριτήριον"
	},
	"G3172" : {
		"derivation" : "from G3173 (μέγας)",
		"def" : "greatness, i.e. (figuratively) divinity (often God himself)",
		"kjv" : "majesty",
		"lemma" : "μεγαλωσύνη",
		"translit" : "megalōsýnē"
	},
	"G224" : {
		"translit" : "áleuron",
		"lemma" : "ἄλευρον",
		"kjv" : "meal",
		"def" : "flour",
		"derivation" : "from (to grind)"
	},
	"G3706" : {
		"derivation" : "from G3708 (ὁράω)",
		"def" : "the act of gazing, i.e. (externally) an aspect or (internally) an inspired appearance",
		"kjv" : "sight, vision",
		"translit" : "hórasis",
		"lemma" : "ὅρασις"
	},
	"G417" : {
		"def" : "wind; (plural) by implication, (the four) quarters (of the earth)",
		"derivation" : "from the base of G109 (ἀήρ)",
		"translit" : "ánemos",
		"lemma" : "ἄνεμος",
		"kjv" : "wind"
	},
	"G3907" : {
		"kjv" : "obervation",
		"lemma" : "παρατήρησις",
		"translit" : "paratḗrēsis",
		"derivation" : "from G3906 (παρατηρέω)",
		"def" : "inspection, i.e. ocular evidence"
	},
	"G1208" : {
		"derivation" : "as the comparative of G1417 (δύο)",
		"def" : "(ordinal) second (in time, place, or rank; also adverb)",
		"kjv" : "afterward, again, second(-arily, time)",
		"translit" : "deúteros",
		"lemma" : "δεύτερος"
	},
	"G2412" : {
		"derivation" : "from G2413 (ἱερός) and the same as G4241 (πρέπω)",
		"def" : "reverent",
		"kjv" : "as becometh holiness",
		"lemma" : "ἱεροπρεπής",
		"translit" : "hieroprepḗs"
	},
	"G2666" : {
		"def" : "to drink down, i.e. gulp entire (literally or figuratively)",
		"derivation" : "from G2596 (κατά) and G4095 (πίνω)",
		"lemma" : "καταπίνω",
		"translit" : "katapínō",
		"kjv" : "devour, drown, swallow (up)"
	},
	"G4250" : {
		"lemma" : "πρίν",
		"translit" : "prín",
		"kjv" : "before (that), ere",
		"def" : "prior, sooner",
		"derivation" : "adverb from G4253 (πρό)"
	},
	"G2888" : {
		"lemma" : "κοσμοκράτωρ",
		"translit" : "kosmokrátōr",
		"kjv" : "ruler",
		"def" : "a world-ruler, an epithet of Satan",
		"derivation" : "from G2889 (κόσμος) and G2902 (κρατέω)"
	},
	"G4155" : {
		"def" : "to wheeze, i.e. (causative, by implication) to throttle or strangle (drown)",
		"derivation" : "strengthened from G4154 (πνέω)",
		"lemma" : "πνίγω",
		"translit" : "pnígō",
		"kjv" : "choke, take by the throat"
	},
	"G241" : {
		"derivation" : "from G243 (ἄλλος) and G1085 (γένος)",
		"def" : "foreign, i.e. not a Jew",
		"kjv" : "stranger",
		"translit" : "allogenḗs",
		"lemma" : "ἀλλογενής"
	},
	"G3896" : {
		"derivation" : "from G3844 (παρά) and G4126 (πλέω)",
		"def" : "to sail near",
		"kjv" : "sail by",
		"lemma" : "παραπλέω",
		"translit" : "parapléō"
	},
	"G2117" : {
		"translit" : "euthýs",
		"lemma" : "εὐθύς",
		"kjv" : "anon, by and by, forthwith, immediately, straightway",
		"def" : "straight, i.e. (literally) level, or (figuratively) true; adverbially (of time) at once",
		"derivation" : "perhaps from G2095 (εὖ) and G5087 (τίθημι)"
	},
	"G4553" : {
		"def" : "a basket (as interwoven or wicker-work",
		"derivation" : "apparently of Hebrew origin (H8276)",
		"lemma" : "σαργάνη",
		"translit" : "sargánē",
		"kjv" : "basket"
	},
	"G4398" : {
		"def" : "a female foreteller or an inspired woman",
		"derivation" : "feminine of G4396 (προφήτης)",
		"lemma" : "προφῆτις",
		"translit" : "prophētis",
		"kjv" : "prophetess"
	},
	"G5268" : {
		"def" : "an animal under the yoke (draught-beast), i.e. (specially), a donkey",
		"derivation" : "neuter of a compound of G5259 (ὑπό) and G2218 (ζυγός)",
		"lemma" : "ὑποζύγιον",
		"translit" : "hypozýgion",
		"kjv" : "ass"
	},
	"G2530" : {
		"def" : "and G3739 (ὅς) and G5100 (τὶς); according to which certain thing, i.e. as far (or inasmuch) as",
		"derivation" : "from G2596 (κατά)",
		"translit" : "kathóti",
		"lemma" : "καθότι",
		"kjv" : "(according, forasmuch) as, because (that)"
	},
	"G1778" : {
		"lemma" : "ἔνταλμα",
		"translit" : "éntalma",
		"kjv" : "commandment",
		"def" : "an injunction, i.e. religious precept",
		"derivation" : "from G1781 (ἐντέλλομαι)"
	},
	"G5436" : {
		"derivation" : "probably from G5343 (φεύγω)",
		"def" : "fugitive; Phygellus, an apostate Christian",
		"kjv" : "Phygellus",
		"lemma" : "Φύγελλος",
		"translit" : "Phýgellos"
	},
	"G3084" : {
		"translit" : "lytróō",
		"lemma" : "λυτρόω",
		"kjv" : "redeem",
		"def" : "to ransom (literally or figuratively)",
		"derivation" : "from G3083 (λύτρον)"
	},
	"G2184" : {
		"derivation" : "from G1909 (ἐπί) and G2250 (ἡμέρα)",
		"def" : "for a day (\"ephemeral\"), i.e. diurnal",
		"kjv" : "daily",
		"translit" : "ephḗmeros",
		"lemma" : "ἐφήμερος"
	},
	"G3113" : {
		"lemma" : "μακρόθεν",
		"translit" : "makróthen",
		"kjv" : "afar off, from far",
		"def" : "from a distance or afar",
		"derivation" : "adverb from G3117 (μακρός)"
	},
	"G2556" : {
		"kjv" : "bad, evil, harm, ill, noisome, wicked",
		"translit" : "kakós",
		"lemma" : "κακός",
		"derivation" : "apparently a primary word",
		"def" : "worthless (intrinsically, such; whereas G4190 (πονηρός) properly refers to effects), i.e. (subjectively) depraved, or (objectively) injurious"
	},
	"G3341" : {
		"derivation" : "from G3340 (μετανοέω)",
		"def" : "(subjectively) compunction (for guilt, including reformation); by implication, reversal (of (another's) decision)",
		"kjv" : "repentance",
		"lemma" : "μετάνοια",
		"translit" : "metánoia"
	},
	"G4082" : {
		"kjv" : "scrip",
		"lemma" : "πήρα",
		"translit" : "pḗra",
		"derivation" : "of uncertain affinity",
		"def" : "a wallet or leather pouch for food"
	},
	"G4514" : {
		"derivation" : "from G4516 (Ῥώμη)",
		"def" : "Romæan, i.e. Roman (as noun)",
		"kjv" : "Roman, of Rome",
		"lemma" : "Ῥωμαῖος",
		"translit" : "Rhōmaîos"
	},
	"G4646" : {
		"derivation" : "from the base of G4628 (σκέλος)",
		"def" : "warped, i.e. winding; figuratively, perverse",
		"kjv" : "crooked, froward, untoward",
		"translit" : "skoliós",
		"lemma" : "σκολιός"
	},
	"G302" : {
		"derivation" : "a primary particle, denoting a supposition, wish, possibility or uncertainty",
		"kjv" : "(what-, where-, wither-, who-)soever",
		"translit" : "án",
		"lemma" : "ἄν"
	},
	"G2181" : {
		"def" : "Ephesus, a city of Asia Minor",
		"derivation" : "probably of foreign origin",
		"translit" : "Éphesos",
		"lemma" : "Ἔφεσος",
		"kjv" : "Ephesus"
	},
	"G2875" : {
		"derivation" : "a primary verb",
		"def" : "to \"chop\"; specially, to beat the breast in grief",
		"kjv" : "cut down, lament, mourn, (be-)wail",
		"translit" : "kóptō",
		"lemma" : "κόπτω"
	},
	"G1675" : {
		"derivation" : "from a derivative of G1672 (Ἕλλην)",
		"def" : "a Hellenist or Greek-speaking Jew",
		"kjv" : "Grecian",
		"translit" : "Hellēnistḗs",
		"lemma" : "Ἑλληνιστής"
	},
	"G1272" : {
		"kjv" : "open",
		"translit" : "dianoígō",
		"lemma" : "διανοίγω",
		"derivation" : "from G1223 (διά) and G455 (ἀνοίγω)",
		"def" : "to open thoroughly, literally (as a first-born) or figuratively (to expound)"
	},
	"G2348" : {
		"derivation" : "a strengthened form of a simpler primary (which is used for it only in certain tenses)",
		"def" : "to die (literally or figuratively)",
		"kjv" : "be dead, die",
		"lemma" : "θνήσκω",
		"translit" : "thnḗskō"
	},
	"G541" : {
		"lemma" : "ἀπαύγασμα",
		"translit" : "apaúgasma",
		"kjv" : "brightness",
		"def" : "an off-flash, i.e. effulgence",
		"derivation" : "from a compound of G575 (ἀπό) and G826 (αὐγάζω)"
	},
	"G3480" : {
		"lemma" : "Ναζωραῖος",
		"translit" : "Nazōraîos",
		"kjv" : "Nazarene, of Nazareth",
		"def" : "a Nazoræan, i.e. inhabitant of Nazareth; by extension, a Christian",
		"derivation" : "from G3478 (Ναζαρέθ)"
	},
	"G3021" : {
		"kjv" : "make white, whiten",
		"lemma" : "λευκαίνω",
		"translit" : "leukaínō",
		"derivation" : "from G3022 (λευκός)",
		"def" : "to whiten"
	},
	"G4105" : {
		"kjv" : "go astray, deceive, err, seduce, wander, be out of the way",
		"lemma" : "πλανάω",
		"translit" : "planáō",
		"derivation" : "from G4106 (πλάνη)",
		"def" : "to (properly, cause to) roam (from safety, truth, or virtue)"
	},
	"G5187" : {
		"lemma" : "τυφόω",
		"translit" : "typhóō",
		"kjv" : "high-minded, be lifted up with pride, be proud",
		"def" : "to envelop with smoke, i.e. (figuratively) to inflate with self-conceit",
		"derivation" : "from a derivative of G5188 (τύφω)"
	},
	"G5556" : {
		"def" : "a greenish-yellow gem (\"chrysoprase\")",
		"derivation" : "from G5557 (χρυσός) and (a leek)",
		"translit" : "chrysóprasos",
		"lemma" : "χρυσόπρασος",
		"kjv" : "chrysoprase"
	},
	"G4692" : {
		"derivation" : "probably strengthened from G4228 (πούς)",
		"def" : "to \"speed\" (\"study\"), i.e. urge on (diligently or earnestly); by implication, to await eagerly",
		"kjv" : "(make, with) haste unto",
		"lemma" : "σπεύδω",
		"translit" : "speúdō"
	},
	"G3013" : {
		"lemma" : "λεπίς",
		"translit" : "lepís",
		"kjv" : "scale",
		"def" : "a flake",
		"derivation" : "from (to peel)"
	},
	"G2941" : {
		"def" : "pilotage, i.e. (figuratively) directorship (in the church)",
		"derivation" : "from (of Latin origin, to steer)",
		"translit" : "kybérnēsis",
		"lemma" : "κυβέρνησις",
		"kjv" : "government"
	},
	"G5036" : {
		"derivation" : "of uncertain affinity",
		"def" : "fleet, i.e. (figuratively) prompt or ready",
		"kjv" : "swift",
		"lemma" : "ταχύς",
		"translit" : "tachýs"
	},
	"G3351" : {
		"derivation" : "from the same as G3350 (μετοικεσία)",
		"def" : "to transfer as a settler or captive, i.e colonize or exile",
		"kjv" : "carry away, remove into",
		"lemma" : "μετοικίζω",
		"translit" : "metoikízō"
	},
	"G2777" : {
		"kjv" : "volume",
		"translit" : "kephalís",
		"lemma" : "κεφαλίς",
		"derivation" : "from G2776 (κεφαλή)",
		"def" : "properly, a knob, i.e. (by implication) a roll (by extension from the end of a stick on which the manuscript was rolled)"
	},
	"G1948" : {
		"kjv" : "give sentence",
		"translit" : "epikrínō",
		"lemma" : "ἐπικρίνω",
		"derivation" : "from G1909 (ἐπί) and G2919 (κρίνω)",
		"def" : "to adjudge"
	},
	"G2677" : {
		"kjv" : "perfecting",
		"lemma" : "καταρτισμός",
		"translit" : "katartismós",
		"derivation" : "from G2675 (καταρτίζω)",
		"def" : "complete furnishing (objectively)"
	},
	"G5593" : {
		"translit" : "psychrós",
		"lemma" : "ψυχρός",
		"kjv" : "cold",
		"def" : "chilly (literally or figuratively)",
		"derivation" : "from G5592 (ψύχος)"
	},
	"G5360" : {
		"translit" : "philadelphía",
		"lemma" : "φιλαδελφία",
		"kjv" : "brotherly love (kindness), love of the brethren",
		"def" : "fraternal affection",
		"derivation" : "from G5361 (φιλάδελφος)"
	},
	"G426" : {
		"derivation" : "from G303 (ἀνά) and (to test)",
		"def" : "to investigate (judicially)",
		"kjv" : "(should have) examined(-d)",
		"lemma" : "ἀνετάζω",
		"translit" : "anetázō"
	},
	"G5207" : {
		"def" : "a \"son\" (sometimes of animals), used very widely of immediate, remote or figuratively, kinship",
		"derivation" : "apparently a primary word",
		"lemma" : "υἱός",
		"translit" : "huiós",
		"kjv" : "child, foal, son"
	},
	"G5186" : {
		"def" : "to make blind, i.e. (figuratively) to obscure",
		"derivation" : "from G5185 (τυφλός)",
		"lemma" : "τυφλόω",
		"translit" : "typhlóō",
		"kjv" : "blind"
	},
	"G1273" : {
		"derivation" : "from G1223 (διά) and a derivative of G3571 (νύξ)",
		"def" : "to sit up the whole night",
		"kjv" : "continue all night",
		"translit" : "dianyktereúō",
		"lemma" : "διανυκτερεύω"
	},
	"G2732" : {
		"derivation" : "from a derivative of G2730 (κατοικέω)",
		"def" : "a dwelling-place",
		"kjv" : "habitation",
		"translit" : "katoikētḗrion",
		"lemma" : "κατοικητήριον"
	},
	"G4406" : {
		"def" : "dawning, i.e. (by analogy) autumnal (showering, the first of the rainy season)",
		"derivation" : "from G4404 (πρωΐ)",
		"lemma" : "πρώϊμος",
		"translit" : "prṓïmos",
		"kjv" : "early"
	},
	"G697" : {
		"translit" : "Áreios Págos",
		"lemma" : "Ἄρειος Πάγος",
		"kjv" : "Areopagus, Mars' Hill",
		"def" : "rock of Ares, a place in Athens",
		"derivation" : "from (the name of the Greek deity of war) and a derivative of G4078 (πήγνυμι)"
	},
	"G2073" : {
		"derivation" : "feminine of an adjective hesperos (evening); the eve (G5610 (ὥρα) being implied)",
		"lemma" : "ἑσπέρα",
		"translit" : "hespéra",
		"kjv" : "evening(-tide)"
	},
	"G2710" : {
		"derivation" : "from G2596 (κατά) and G5530 (χράομαι)",
		"def" : "to overuse, i.e. misuse",
		"kjv" : "abuse",
		"lemma" : "καταχράομαι",
		"translit" : "katachráomai"
	},
	"G2102" : {
		"def" : "quite agreeably",
		"derivation" : "adverb from G2101 (εὐάρεστος)",
		"lemma" : "εὐαρέστως",
		"translit" : "euaréstōs",
		"kjv" : "acceptably, + please well"
	},
	"G5032" : {
		"def" : "more swiftly, i.e. (in manner) more rapidly, or (in time) more speedily",
		"derivation" : "neuter singular of the comparative of G5036 (ταχύς) (as adverb)",
		"lemma" : "τάχιον",
		"translit" : "táchion",
		"kjv" : "out (run), quickly, shortly, sooner"
	},
	"G598" : {
		"lemma" : "ἀποθλίβω",
		"translit" : "apothlíbō",
		"kjv" : "press",
		"def" : "to crowd (from every side)",
		"derivation" : "from G575 (ἀπό) and G2346 (θλίβω)"
	},
	"G1260" : {
		"derivation" : "from G1223 (διά) and G3049 (λογίζομαι)",
		"def" : "to reckon thoroughly, i.e. (genitive case) to deliberate (by reflection or discussion)",
		"kjv" : "cast in mind, consider, dispute, muse, reason, think",
		"lemma" : "διαλογίζομαι",
		"translit" : "dialogízomai"
	},
	"G2625" : {
		"translit" : "kataklínō",
		"lemma" : "κατακλίνω",
		"kjv" : "(make) sit down (at meat)",
		"def" : "to recline down, i.e. (specially) to take a place at table",
		"derivation" : "from G2596 (κατά) and G2827 (κλίνω)"
	},
	"G2153" : {
		"kjv" : "godly",
		"translit" : "eusebōs",
		"lemma" : "εὐσεβῶς",
		"derivation" : "adverb from G2152 (εὐσεβής)",
		"def" : "piously"
	},
	"G5002" : {
		"lemma" : "τακτός",
		"translit" : "taktós",
		"kjv" : "set",
		"def" : "arranged, i.e. appointed or stated",
		"derivation" : "from G5021 (τάσσω)"
	},
	"G482" : {
		"translit" : "antilambánomai",
		"lemma" : "ἀντιλαμβάνομαι",
		"kjv" : "help, partaker, support",
		"def" : "to take hold of in turn, i.e. succor; also to participate",
		"derivation" : "from G473 (ἀντί) and the middle voice of G2983 (λαμβάνω)"
	},
	"G2082" : {
		"def" : "interior",
		"derivation" : "comparative of G2080 (ἔσω)",
		"translit" : "esṓteros",
		"lemma" : "ἐσώτερος",
		"kjv" : "inner, within"
	},
	"G4258" : {
		"kjv" : "sin already, heretofore sin",
		"lemma" : "προαμαρτάνω",
		"translit" : "proamartánō",
		"derivation" : "from G4253 (πρό) and G264 (ἁμαρτάνω)",
		"def" : "to sin previously (to conversion)"
	},
	"G2482" : {
		"kjv" : "Italy",
		"lemma" : "Ἰταλία",
		"translit" : "Italía",
		"derivation" : "probably of foreign origin",
		"def" : "Italia, a region of Europe"
	},
	"G1350" : {
		"def" : "a seine (for fishing)",
		"derivation" : "probably from a primary verb (to cast)",
		"translit" : "díktyon",
		"lemma" : "δίκτυον",
		"kjv" : "net"
	},
	"G160" : {
		"kjv" : "sudden, unawares",
		"translit" : "aiphnídios",
		"lemma" : "αἰφνίδιος",
		"derivation" : "from a compound of G1 (Α) (as a negative particle) and G5316 (φαίνω) (compare G1810 (ἐξαίφνης)) (meaning non-apparent)",
		"def" : "unexpected, i.e. (adverbially) suddenly"
	},
	"G3561" : {
		"lemma" : "νουμηνία",
		"translit" : "noumēnía",
		"kjv" : "new moon",
		"def" : "the festival of new moon",
		"derivation" : "feminine of a compound of G3501 (νέος) and G3376 (μήν) (as noun by implication, of G2250 (ἡμέρα))"
	},
	"G5342" : {
		"translit" : "phérō",
		"lemma" : "φέρω",
		"kjv" : "be, bear, bring (forth), carry, come, + let her drive, be driven, endure, go on, lay, lead, move, reach, rushing, uphold",
		"def" : "to \"bear\" or carry (in a very wide application, literally and figuratively, as follows)",
		"derivation" : "a primary verb (for which other, and apparently not cognate ones are used in certain tenses only; namely, ; and "
	},
	"G3128" : {
		"translit" : "Manassēs",
		"lemma" : "Μανασσῆς",
		"kjv" : "Manasses",
		"def" : "Mannasses (i.e. Menashsheh), an Israelite",
		"derivation" : "of Hebrew origin (H4519)"
	},
	"G522" : {
		"def" : "to lift off, i.e. remove",
		"derivation" : "from G575 (ἀπό) and G142 (αἴρω)",
		"translit" : "apaírō",
		"lemma" : "ἀπαίρω",
		"kjv" : "take (away)"
	},
	"G176" : {
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G2607 (καταγινώσκω)",
		"def" : "unblamable",
		"kjv" : "that cannot be condemned",
		"lemma" : "ἀκατάγνωστος",
		"translit" : "akatágnōstos"
	},
	"G1509" : {
		"lemma" : "εἰ μή τι",
		"translit" : "ei mḗ ti",
		"kjv" : "except",
		"def" : "if not somewhat",
		"derivation" : "from G1508 (εἰ μή) and the neuter of G5100 (τὶς)"
	},
	"G1688" : {
		"derivation" : "from G1722 (ἐν) and (to mount",
		"def" : "causative of G1684 (ἐμβαίνω)); to place on, i.e. transfer (aboard a vessel)",
		"kjv" : "put in",
		"translit" : "embibázō",
		"lemma" : "ἐμβιβάζω"
	},
	"G2405" : {
		"kjv" : "office of the priesthood, priest's office",
		"lemma" : "ἱερατεία",
		"translit" : "hierateía",
		"derivation" : "from G2407 (ἱερατεύω)",
		"def" : "priestliness, i.e. the sacerdotal function"
	},
	"G4279" : {
		"lemma" : "προεπαγγέλλομαι",
		"translit" : "proepangéllomai",
		"kjv" : "promise before",
		"def" : "to promise of old",
		"derivation" : "middle voice from G4253 (πρό) and G1861 (ἐπαγγέλλω)"
	},
	"G1351" : {
		"lemma" : "δίλογος",
		"translit" : "dílogos",
		"kjv" : "double-tongued",
		"def" : "equivocal, i.e. telling a different story",
		"derivation" : "from G1364 (δίς) and G3056 (λόγος)"
	},
	"G2804" : {
		"kjv" : "Claudius",
		"lemma" : "Κλαύδιος",
		"translit" : "Klaúdios",
		"derivation" : "of Latin origin",
		"def" : "Claudius, the name of two Romans"
	},
	"G5105" : {
		"def" : "truly for then, i.e. consequently",
		"derivation" : "from G5104 (τοί) and G1063 (γάρ) and G3767 (οὖν)",
		"lemma" : "τοιγαροῦν",
		"translit" : "toigaroûn",
		"kjv" : "there-(where-)fore"
	},
	"G1182" : {
		"def" : "tenth",
		"derivation" : "ordinal from G1176 (δέκα)",
		"lemma" : "δέκατος",
		"translit" : "dékatos",
		"kjv" : "tenth"
	},
	"G4682" : {
		"kjv" : "rend, tear",
		"lemma" : "σπαράσσω",
		"translit" : "sparássō",
		"derivation" : "prolongation from (to grasp",
		"def" : "apparently strengthened from G4685 (σπάω) through the idea of spasmodic contraction); to mangle, i.e. convluse with epilepsy"
	},
	"G1285" : {
		"derivation" : "from G1223 (διά) and (clear)",
		"def" : "to clear thoroughly, i.e. (figuratively) declare",
		"kjv" : "tell unto",
		"translit" : "diasaphéō",
		"lemma" : "διασαφέω"
	},
	"G56" : {
		"derivation" : "from G1 (Α) (as negative particle) and G1108 (γνῶσις)",
		"def" : "ignorance (properly, the state)",
		"kjv" : "ignorance, not the knowledge",
		"translit" : "agnōsía",
		"lemma" : "ἀγνωσία"
	},
	"G4076" : {
		"def" : "rue (from its thick or fleshy leaves)",
		"derivation" : "from G4078 (πήγνυμι)",
		"lemma" : "πήγανον",
		"translit" : "pḗganon",
		"kjv" : "rue"
	},
	"G1014" : {
		"kjv" : "be disposed, minded, intend, list, (be, of own) will (-ing)",
		"lemma" : "βούλομαι",
		"translit" : "boúlomai",
		"derivation" : "middle voice of a primary verb",
		"def" : "to \"will,\" i.e. (reflexively) be willing"
	},
	"G4366" : {
		"kjv" : "beat vehemently against (upon)",
		"translit" : "prosrḗgnymi",
		"lemma" : "προσρήγνυμι",
		"derivation" : "from G4314 (πρός) and G4486 (ῥήγνυμι)",
		"def" : "to tear towards, i.e. burst upon (as a tempest or flood)"
	},
	"G2432" : {
		"def" : "alacrity",
		"derivation" : "from G2431 (ἱλαρός)",
		"translit" : "hilarótēs",
		"lemma" : "ἱλαρότης",
		"kjv" : "cheerfulness"
	},
	"G736" : {
		"kjv" : "mainsail",
		"translit" : "artémōn",
		"lemma" : "ἀρτέμων",
		"derivation" : "from a derivative of G737 (ἄρτι)",
		"def" : "properly, something ready (or else more remotely from G142 (αἴρω) (compare G740 (ἄρτος)); something hung up), i.e. (specially) the topsail (rather foresail or jib) of a vessel"
	},
	"G1385" : {
		"kjv" : "beam",
		"translit" : "dokós",
		"lemma" : "δοκός",
		"derivation" : "from G1209 (δέχομαι) (through the idea of holding up)",
		"def" : "a stick of timber"
	},
	"G4585" : {
		"def" : "fine wheaten flour",
		"derivation" : "probably of foreign origin",
		"lemma" : "σεμίδαλις",
		"translit" : "semídalis",
		"kjv" : "fine flour"
	},
	"G4039" : {
		"translit" : "perioikéō",
		"lemma" : "περιοικέω",
		"kjv" : "dwell round about",
		"def" : "to reside around, i.e. be a neighbor",
		"derivation" : "from G4012 (περί) and G3611 (οἰκέω)"
	},
	"G801" : {
		"def" : "unintelligent; by implication, wicked",
		"derivation" : "from G1 (Α) (as a negative particle) and G4908 (συνετός)",
		"translit" : "asýnetos",
		"lemma" : "ἀσύνετος",
		"kjv" : "foolish, without understanding"
	},
	"G1111" : {
		"def" : "to grumble",
		"derivation" : "of uncertain derivation",
		"lemma" : "γογγύζω",
		"translit" : "gongýzō",
		"kjv" : "murmur"
	},
	"G3149" : {
		"lemma" : "μαστός",
		"translit" : "mastós",
		"kjv" : "pap",
		"def" : "a (properly, female) breast (as if kneaded up)",
		"derivation" : "from the base of G3145 (μασσάομαι)"
	},
	"G5039" : {
		"kjv" : "infallible proof",
		"translit" : "tekmḗrion",
		"lemma" : "τεκμήριον",
		"derivation" : "neuter of a presumed derivative of (a goal or fixed limit)",
		"def" : "a token (as defining a fact), i.e. criterion of certainty"
	},
	"G1568" : {
		"def" : "to astonish utterly",
		"derivation" : "from G1569 (ἔκθαμβος)",
		"translit" : "ekthambéō",
		"lemma" : "ἐκθαμβέω",
		"kjv" : "affright, greatly (sore) amaze"
	},
	"G5570" : {
		"derivation" : "from G5571 (ψευδής) and G652 (ἀπόστολος)",
		"def" : "a spurious apostle, i.e. pretended pracher",
		"kjv" : "false teacher",
		"lemma" : "ψευδαπόστολος",
		"translit" : "pseudapóstolos"
	},
	"G5395" : {
		"translit" : "phlóx",
		"lemma" : "φλόξ",
		"kjv" : "flame(-ing)",
		"def" : "a blaze",
		"derivation" : "from a primary (to \"flash\" or \"flame\")"
	},
	"G2845" : {
		"kjv" : "bed, chambering, X conceive",
		"translit" : "koítē",
		"lemma" : "κοίτη",
		"derivation" : "from G2749 (κεῖμαι)",
		"def" : "a couch; by extension, cohabitation; by implication, the male sperm"
	},
	"G5465" : {
		"derivation" : "from the base of G5490 (χάσμα)",
		"def" : "to lower (as into a void)",
		"kjv" : "let down, strike",
		"translit" : "chaláō",
		"lemma" : "χαλάω"
	},
	"G5059" : {
		"def" : "a prodigy or omen",
		"derivation" : "of uncertain affinity",
		"lemma" : "τέρας",
		"translit" : "téras",
		"kjv" : "wonder"
	},
	"G3536" : {
		"def" : "a Ninevite, i.e. inhabitant of Nineveh",
		"derivation" : "from G3535 (Νινευΐ)",
		"translit" : "Nineuḯtēs",
		"lemma" : "Νινευΐτης",
		"kjv" : "of Nineve, Ninevite"
	},
	"G534" : {
		"derivation" : "from G575 (ἀπό) and G737 (ἄρτι)",
		"def" : "from now, i.e. henceforth (already)",
		"kjv" : "from henceforth",
		"lemma" : "ἀπάρτι",
		"translit" : "apárti"
	},
	"G888" : {
		"def" : "useless, i.e. (euphemistically) unmeritorious",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G5534 (χρή) (compare G5532 (χρεία))",
		"translit" : "achreîos",
		"lemma" : "ἀχρεῖος",
		"kjv" : "unprofitable"
	},
	"G4733" : {
		"derivation" : "from G4732 (στερεόω)",
		"def" : "something established, i.e. (abstractly) confirmation (stability)",
		"kjv" : "stedfastness",
		"translit" : "steréōma",
		"lemma" : "στερέωμα"
	},
	"G4235" : {
		"derivation" : "a form of G4239 (πραΰς), used in certain parts",
		"def" : "gentle, i.e. humble",
		"kjv" : "meek",
		"translit" : "prâios",
		"lemma" : "πρᾷος"
	},
	"G2705" : {
		"lemma" : "καταφιλέω",
		"translit" : "kataphiléō",
		"kjv" : "kiss",
		"def" : "to kiss earnestly",
		"derivation" : "from 2596 and 5368"
	},
	"G2488" : {
		"derivation" : "of Hebrew origin (H3147)",
		"def" : "Joatham (i.e. Jotham), an Israelite",
		"kjv" : "Joatham",
		"translit" : "Iōátham",
		"lemma" : "Ἰωάθαμ"
	},
	"G1729" : {
		"def" : "deficient in",
		"derivation" : "from a compound of G1722 (ἐν) and G1210 (δέω) (in the sense of lacking)",
		"translit" : "endeḗs",
		"lemma" : "ἐνδεής",
		"kjv" : "lacking"
	},
	"G51" : {
		"lemma" : "ἀγνόημα",
		"translit" : "agnóēma",
		"kjv" : "error",
		"def" : "a thing ignored, i.e. shortcoming",
		"derivation" : "from G50 (ἀγνοέω)"
	},
	"G5415" : {
		"derivation" : "of Latin origin",
		"def" : "\"fortunate\"; Fortunatus, a Christian",
		"kjv" : "Fortunatus",
		"lemma" : "Φορτουνᾶτος",
		"translit" : "Phortounâtos"
	},
	"G43" : {
		"translit" : "ankálē",
		"lemma" : "ἀγκάλη",
		"kjv" : "arm",
		"def" : "an arm (as curved)",
		"derivation" : "from (a bend, \"ache\")"
	},
	"G2119" : {
		"def" : "to have good time, i.e. opportunity or leisure",
		"derivation" : "from G2121 (εὔκαιρος)",
		"lemma" : "εὐκαιρέω",
		"translit" : "eukairéō",
		"kjv" : "have leisure (convenient time), spend time"
	},
	"G1851" : {
		"derivation" : "from a compound of G1537 (ἐκ) and G2192 (ἔχω) (meaning to stand out)",
		"def" : "prominence (figuratively)",
		"kjv" : "principal",
		"translit" : "exochḗ",
		"lemma" : "ἐξοχή"
	},
	"G2286" : {
		"derivation" : "from G2288 (θάνατος)",
		"def" : "fatal, i.e. poisonous",
		"kjv" : "deadly",
		"translit" : "thanásimos",
		"lemma" : "θανάσιμος"
	},
	"G440" : {
		"def" : "a live coal",
		"derivation" : "of uncertain derivation",
		"translit" : "ánthrax",
		"lemma" : "ἄνθραξ",
		"kjv" : "coal of fire"
	},
	"G2603" : {
		"translit" : "katabrabeúō",
		"lemma" : "καταβραβεύω",
		"kjv" : "beguile of reward",
		"def" : "to award the price against, i.e. (figuratively) to defraud (of salvation)",
		"derivation" : "from G2596 (κατά) and G1018 (βραβεύω) (in its original sense)"
	},
	"G314" : {
		"derivation" : "from G303 (ἀνά) and G1097 (γινώσκω)",
		"def" : "to know again, i.e. (by extension) to read",
		"kjv" : "read",
		"translit" : "anaginṓskō",
		"lemma" : "ἀναγινώσκω"
	},
	"G1253" : {
		"lemma" : "διάκρισις",
		"translit" : "diákrisis",
		"kjv" : "discern(-ing), disputation",
		"def" : "judicial estimation",
		"derivation" : "from G1252 (διακρίνω)"
	},
	"G3704" : {
		"kjv" : "because, how, (so) that, to, when",
		"lemma" : "ὅπως",
		"translit" : "hópōs",
		"derivation" : "from G3739 (ὅς) and G4459 (πῶς)",
		"def" : "what(-ever) how, i.e. in the manner that (as adverb or conjunction of coincidence, intentional or actual)"
	},
	"G94" : {
		"def" : "unjust; by extension wicked; by implication, treacherous; specially, heathen",
		"derivation" : "from G1 (Α) (as a negative particle) and G1349 (δίκη)",
		"lemma" : "ἄδικος",
		"translit" : "ádikos",
		"kjv" : "unjust, unrighteous"
	},
	"G2219" : {
		"translit" : "zýmē",
		"lemma" : "ζύμη",
		"kjv" : "leaven",
		"def" : "ferment (as if boiling up)",
		"derivation" : "probably from G2204 (ζέω)"
	},
	"G4996" : {
		"translit" : "sōphrónōs",
		"lemma" : "σωφρόνως",
		"kjv" : "soberly",
		"def" : "with sound mind, i.e. moderately",
		"derivation" : "adverb from G4998 (σώφρων)"
	},
	"G5169" : {
		"derivation" : "from a derivative of the base of G5168 (τρυμαλιά)",
		"def" : "an aperture, i.e. a needle's eye",
		"kjv" : "eye",
		"lemma" : "τρύπημα",
		"translit" : "trýpēma"
	},
	"G2489" : {
		"derivation" : "feminine of the same as G2491 (Ἰωάννης)",
		"def" : "Joanna, a Christian",
		"kjv" : "Joanna",
		"translit" : "Iōánna",
		"lemma" : "Ἰωάννα"
	},
	"G4056" : {
		"kjv" : "more abundant(-ly), X the more earnest, (more) exceedingly, more frequent, much more, the rather",
		"lemma" : "περισσοτέρως",
		"translit" : "perissotérōs",
		"derivation" : "adverb from G4055 (περισσότερος)",
		"def" : "more superabundantly"
	},
	"G956" : {
		"translit" : "bélos",
		"lemma" : "βέλος",
		"kjv" : "dart",
		"def" : "a missile, i.e. spear or arrow",
		"derivation" : "from G906 (βάλλω)"
	},
	"G4486" : {
		"translit" : "rhḗgnymi",
		"lemma" : "ῥήγνυμι",
		"kjv" : "break (forth), burst, rend, tear",
		"def" : "G2608 (κατάγνυμι) being its intensive (with the preposition in composition), and G2352 (θραύω) a shattering to minute fragments; but not a reduction to the constituent particles, like G3089 (λύω)) or disrupt, lacerate; by implication, to convulse (with spasms); figuratively, to give vent to joyful emotions",
		"derivation" : "both prolonged forms of (which appears only in certain forms, and is itself probably a strengthened form of (see in G2608 (κατάγνυμι))) to \"break,\" \"wreck\" or \"crack\", i.e. (especially) to sunder (by separation of the parts"
	},
	"G2963" : {
		"derivation" : "from G2962 (κύριος)",
		"def" : "mastery, i.e. (concretely and collectively) rulers",
		"kjv" : "dominion, government",
		"lemma" : "κυριότης",
		"translit" : "kyriótēs"
	},
	"G5481" : {
		"kjv" : "express image",
		"lemma" : "χαρακτήρ",
		"translit" : "charaktḗr",
		"derivation" : "from the same as G5482 (χάραξ)",
		"def" : "a graver (the tool or the person), i.e. (by implication) engraving ((\"character\"), the figure stamped, i.e. an exact copy or (figuratively) representation)"
	},
	"G4111" : {
		"derivation" : "a primary verb",
		"def" : "to mould, i.e. shape or fabricate",
		"kjv" : "form",
		"lemma" : "πλάσσω",
		"translit" : "plássō"
	},
	"G5377" : {
		"translit" : "philótheos",
		"lemma" : "φιλόθεος",
		"kjv" : "lover of God",
		"def" : "fond of God, i.e. pious",
		"derivation" : "from G5384 (φίλος) and G2316 (θεός)"
	},
	"G1684" : {
		"derivation" : "from G1722 (ἐν) and the base of G939 (βάσις)",
		"def" : "to walk on, i.e. embark (aboard a vessel), reach (a pool)",
		"kjv" : "come (get) into, enter (into), go (up) into, step in, take ship",
		"lemma" : "ἐμβαίνω",
		"translit" : "embaínō"
	},
	"G2317" : {
		"kjv" : "godliness",
		"lemma" : "θεοσέβεια",
		"translit" : "theosébeia",
		"derivation" : "from G2318 (θεοσεβής)",
		"def" : "devoutness, i.e. piety"
	},
	"G1312" : {
		"kjv" : "corruption",
		"translit" : "diaphthorá",
		"lemma" : "διαφθορά",
		"derivation" : "from G1311 (διαφθείρω)",
		"def" : "decay"
	},
	"G3826" : {
		"kjv" : "all at once",
		"lemma" : "παμπληθεί",
		"translit" : "pamplētheí",
		"derivation" : "dative case (adverb) of a compound of G3956 (πᾶς) and G4128 (πλῆθος)",
		"def" : "in full multitude, i.e. concertedly or simultaneously"
	},
	"G1513" : {
		"translit" : "eí pōs",
		"lemma" : "εἴ πως",
		"kjv" : "if by any means",
		"def" : "if somehow",
		"derivation" : "from G1487 (εἰ) and G4458 (-πώς)"
	},
	"G2780" : {
		"translit" : "kēpourós",
		"lemma" : "κηπουρός",
		"kjv" : "gardener",
		"def" : "a garden-keeper, i.e. gardener",
		"derivation" : "from G2779 (κῆπος) and (a warden)"
	},
	"G760" : {
		"translit" : "Asá",
		"lemma" : "Ἀσά",
		"kjv" : "Asa",
		"def" : "Asa, an Israelite",
		"derivation" : "of Hebrew origin (H609)"
	},
	"G4405" : {
		"kjv" : "early, morning",
		"translit" : "prōḯa",
		"lemma" : "πρωΐα",
		"derivation" : "feminine of a derivative of G4404 (πρωΐ) as noun",
		"def" : "day-dawn"
	},
	"G732" : {
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G4517 (ῥώννυμι)",
		"def" : "infirm",
		"kjv" : "sick (folk, -ly)",
		"translit" : "árrhōstos",
		"lemma" : "ἄῤῥωστος"
	},
	"G2527" : {
		"derivation" : "from G2596 (κατά) and G3650 (ὅλος)",
		"def" : "on the whole, i.e. entirely",
		"kjv" : "at all",
		"translit" : "kathólou",
		"lemma" : "καθόλου"
	},
	"G1002" : {
		"def" : "a missile, i.e. javelin",
		"derivation" : "from G906 (βάλλω)",
		"lemma" : "βολίς",
		"translit" : "bolís",
		"kjv" : "dart"
	},
	"G5031" : {
		"derivation" : "from G5034 (τάχος)",
		"def" : "curt, i.e. impending",
		"kjv" : "shortly, swift",
		"lemma" : "ταχινός",
		"translit" : "tachinós"
	},
	"G902" : {
		"derivation" : "a diminutive of a derivative probably of the base of G939 (βάσις)",
		"def" : "a palm twig (as going out far)",
		"kjv" : "branch",
		"translit" : "baḯon",
		"lemma" : "βαΐον"
	},
	"G2545" : {
		"translit" : "kaíō",
		"lemma" : "καίω",
		"kjv" : "burn, light",
		"def" : "to set on fire, i.e. kindle or (by implication) consume",
		"derivation" : "apparently a primary verb"
	},
	"G3317" : {
		"derivation" : "neuter of compound of G3319 (μέσος) and G3571 (νύξ)",
		"def" : "midnight (especially as a watch)",
		"kjv" : "midnight",
		"translit" : "mesonýktion",
		"lemma" : "μεσονύκτιον"
	},
	"G4922" : {
		"kjv" : "journey with",
		"translit" : "synodeúō",
		"lemma" : "συνοδεύω",
		"derivation" : "from G4862 (σύν) and G3593 (ὁδεύω)",
		"def" : "to travel in company with"
	},
	"G1129" : {
		"derivation" : "from G1128 (γυμνάζω)",
		"def" : "training, i.e. (figuratively) asceticism",
		"kjv" : "exercise",
		"translit" : "gymnasía",
		"lemma" : "γυμνασία"
	},
	"G3731" : {
		"kjv" : "violence",
		"translit" : "hórmēma",
		"lemma" : "ὅρμημα",
		"derivation" : "from G3730 (ὁρμή)",
		"def" : "an attack, i.e. (abstractly) precipitancy"
	},
	"G2454" : {
		"derivation" : "from G2450 (Ἰουδαΐζω)",
		"def" : "\"Judaism\", i.e. the Jewish faith and usages",
		"kjv" : "Jews' religion",
		"lemma" : "Ἰουδαϊσμός",
		"translit" : "Ioudaïsmós"
	},
	"G1958" : {
		"def" : "carefulness, i.e. kind attention (hospitality)",
		"derivation" : "from G1959 (ἐπιμελέομαι)",
		"translit" : "epiméleia",
		"lemma" : "ἐπιμέλεια",
		"kjv" : "+ refresh self"
	},
	"G40" : {
		"derivation" : "from (an awful thing) (compare G53 (ἁγνός), G2282 (θάλπω))",
		"def" : "sacred (physically, pure, morally blameless or religious, ceremonially, consecrated)",
		"kjv" : "(most) holy (one, thing), saint",
		"lemma" : "ἅγιος",
		"translit" : "hágios"
	},
	"G3319" : {
		"lemma" : "μέσος",
		"translit" : "mésos",
		"kjv" : "among, X before them, between, + forth, mid(-day, -night), midst, way",
		"def" : "middle (as an adjective or (neuter) noun)",
		"derivation" : "from G3326 (μετά)"
	},
	"G2307" : {
		"kjv" : "desire, pleasure, will",
		"lemma" : "θέλημα",
		"translit" : "thélēma",
		"derivation" : "from the prolonged form of G2309 (θέλω)",
		"def" : "a determination (properly, the thing), i.e. (actively) choice (specially, purpose, decree; abstractly, volition) or (passively) inclination"
	},
	"G3906" : {
		"def" : "to inspect alongside, i.e. note insidiously or scrupulously",
		"derivation" : "from G3844 (παρά) and G5083 (τηρέω)",
		"lemma" : "παρατηρέω",
		"translit" : "paratēréō",
		"kjv" : "observe, watch"
	},
	"G1455" : {
		"lemma" : "ἐγκάθετος",
		"translit" : "enkáthetos",
		"kjv" : "spy",
		"def" : "subinduced, i.e. surreptitiously suborned as a lier-in-wait",
		"derivation" : "from G1722 (ἐν) and a derivative of G2524 (καθίημι)"
	},
	"G3742" : {
		"def" : "piety",
		"derivation" : "from G3741 (ὅσιος)",
		"translit" : "hosiótēs",
		"lemma" : "ὁσιότης",
		"kjv" : "holiness"
	},
	"G410" : {
		"kjv" : "blameless",
		"translit" : "anénklētos",
		"lemma" : "ἀνέγκλητος",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G1458 (ἐγκαλέω)",
		"def" : "unaccused, i.e. (by implication) irreproachable"
	},
	"G767" : {
		"kjv" : "mean",
		"lemma" : "ἄσημος",
		"translit" : "ásēmos",
		"derivation" : "from G1 (Α) (as a negative particle) and the base of G4591 (σημαίνω)",
		"def" : "unmarked, i.e. (figuratively) ignoble"
	},
	"G810" : {
		"def" : "properly, unsavedness, i.e. (by implication) profligacy",
		"derivation" : "from a compound of G1 (Α) (as a negative particle) and a presumed derivative of G4982 (σώζω)",
		"lemma" : "ἀσωτία",
		"translit" : "asōtía",
		"kjv" : "excess, riot"
	},
	"G5332" : {
		"kjv" : "sorcerer",
		"lemma" : "φαρμακεύς",
		"translit" : "pharmakeús",
		"derivation" : "from (a drug, i.e. spell-giving potion)",
		"def" : "a druggist (\"pharmacist\") or poisoner, i.e. (by extension) a magician"
	},
	"G3073" : {
		"derivation" : "probably remotely from G3074 (λύκος)",
		"def" : "Lycia, a province of Asia Minor",
		"kjv" : "Lycia",
		"translit" : "Lykía",
		"lemma" : "Λυκία"
	},
	"G2166" : {
		"lemma" : "Εὐφράτης",
		"translit" : "Euphrátēs",
		"kjv" : "Euphrates",
		"def" : "Euphrates, a river of Asia",
		"derivation" : "of foreign origin (compare H6578)"
	},
	"G813" : {
		"kjv" : "unruly",
		"lemma" : "ἄτακτος",
		"translit" : "átaktos",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G5021 (τάσσω)",
		"def" : "unarranged, i.e. (by implication) insubordinate (religiously)"
	},
	"G406" : {
		"kjv" : "Andrew",
		"lemma" : "Ἀνδρέας",
		"translit" : "Andréas",
		"derivation" : "from G435 (ἀνήρ)",
		"def" : "manly; Andreas, an Israelite"
	},
	"G1810" : {
		"derivation" : "from G1537 (ἐκ) and the base of G160 (αἰφνίδιος)",
		"def" : "of a sudden (unexpectedly)",
		"kjv" : "suddenly",
		"lemma" : "ἐξαίφνης",
		"translit" : "exaíphnēs"
	},
	"G24" : {
		"derivation" : "from G23 (ἀγανακτέω)",
		"def" : "indignation",
		"kjv" : "indignation",
		"translit" : "aganáktēsis",
		"lemma" : "ἀγανάκτησις"
	},
	"G3367" : {
		"derivation" : "from G3361 (μή) and G1520 (εἷς)",
		"def" : "not even one (man, woman, thing)",
		"kjv" : "any (man, thing), no (man), none, not (at all, any man, a whit), nothing, + without delay",
		"lemma" : "μηδείς",
		"translit" : "mēdeís"
	},
	"G4233" : {
		"def" : "a practiser, i.e. (specially), an official collector",
		"derivation" : "from a derivative of G4238 (πράσσω)",
		"lemma" : "πράκτωρ",
		"translit" : "práktōr",
		"kjv" : "officer"
	},
	"G1821" : {
		"derivation" : "from G1537 (ἐκ) and G649 (ἀποστέλλω)",
		"def" : "to send away forth, i.e. (on a mission) to despatch, or (peremptorily) to dismiss",
		"kjv" : "send (away, forth, out)",
		"lemma" : "ἐξαποστέλλω",
		"translit" : "exapostéllō"
	},
	"G2927" : {
		"def" : "concealed, i.e. private",
		"derivation" : "from G2928 (κρύπτω)",
		"translit" : "kryptós",
		"lemma" : "κρυπτός",
		"kjv" : "hid(-den), inward(-ly), secret"
	},
	"G423" : {
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G1949 (ἐπιλαμβάνομαι)",
		"def" : "not arrested, i.e. (by implication) inculpable",
		"kjv" : "blameless, unrebukeable",
		"translit" : "anepílēptos",
		"lemma" : "ἀνεπίληπτος"
	},
	"G5139" : {
		"translit" : "Trachōnîtis",
		"lemma" : "Τραχωνῖτις",
		"kjv" : "Trachonitis",
		"def" : "rough district; Trachonitis, a region of Syria",
		"derivation" : "from a derivative of G5138 (τραχύς)"
	},
	"G5363" : {
		"def" : "fondness of mankind, i.e. benevolence (\"philanthropy\")",
		"derivation" : "from the same as G5364 (φιλανθρώπως)",
		"lemma" : "φιλανθρωπία",
		"translit" : "philanthrōpía",
		"kjv" : "kindness, love towards man"
	},
	"G5313" : {
		"kjv" : "height, high thing",
		"translit" : "hýpsōma",
		"lemma" : "ὕψωμα",
		"derivation" : "from G5312 (ὑψόω)",
		"def" : "an elevated place or thing, i.e. (abstractly) altitude, or (by implication) a barrier (figuratively)"
	},
	"G3497" : {
		"lemma" : "Νεεμάν",
		"translit" : "Neemán",
		"kjv" : "Naaman",
		"def" : "Neeman (i.e. Naaman), a Syrian",
		"derivation" : "of Hebrew origin (H5283)"
	},
	"G3053" : {
		"derivation" : "from G3049 (λογίζομαι)",
		"def" : "computation, i.e. (figuratively) reasoning (conscience, conceit)",
		"kjv" : "imagination, thought",
		"lemma" : "λογισμός",
		"translit" : "logismós"
	},
	"G3910" : {
		"derivation" : "from G3844 (παρά) and a derivative of G846 (αὐτός)",
		"def" : "at the very instant, i.e. momentary",
		"kjv" : "but for a moment",
		"translit" : "parautíka",
		"lemma" : "παραυτίκα"
	},
	"G802" : {
		"def" : "properly, not agreed, i.e. treacherous to compacts",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G4934 (συντίθεμαι)",
		"translit" : "asýnthetos",
		"lemma" : "ἀσύνθετος",
		"kjv" : "covenant-breaker"
	},
	"G2444" : {
		"lemma" : "ἱνατί",
		"translit" : "hinatí",
		"kjv" : "wherefore, why",
		"def" : "for what reason ?, i.e. why?",
		"derivation" : "from G2443 (ἵνα) and G5101 (τίς)"
	},
	"G2542" : {
		"derivation" : "from G2541 (Καῖσαρ)",
		"def" : "Cæsaria, the name of two places in Palestine",
		"kjv" : "Cæsarea",
		"translit" : "Kaisáreia",
		"lemma" : "Καισάρεια"
	},
	"G5493" : {
		"def" : "a storm-runlet, i.e. winter-torrent",
		"derivation" : "from the base of G5494 (χειμών) and G4482 (ῥέω)",
		"translit" : "cheímarrhos",
		"lemma" : "χείμαῤῥος",
		"kjv" : "brook"
	},
	"G2230" : {
		"lemma" : "ἡγεμονεύω",
		"translit" : "hēgemoneúō",
		"kjv" : "be governor",
		"def" : "to act as ruler",
		"derivation" : "from G2232 (ἡγεμών)"
	},
	"G1977" : {
		"derivation" : "from G1909 (ἐπί) and G4496 (ῥίπτω)",
		"def" : "to throw upon (literally or figuratively)",
		"kjv" : "cast upon",
		"translit" : "epirrhíptō",
		"lemma" : "ἐπιῤῥίπτω"
	},
	"G4627" : {
		"def" : "a \"skiff\" (as if dug out), or yawl (carried aboard a large vessel for landing)",
		"kjv" : "boat",
		"translit" : "skáphē",
		"lemma" : "σκάφη"
	},
	"G877" : {
		"lemma" : "ἀφροσύνη",
		"translit" : "aphrosýnē",
		"kjv" : "folly, foolishly(-ness)",
		"def" : "senselessness, i.e. (euphemistically) egotism; (morally) recklessness",
		"derivation" : "from G878 (ἄφρων)"
	},
	"G1581" : {
		"lemma" : "ἐκκόπτω",
		"translit" : "ekkóptō",
		"kjv" : "cut down (off, out), hew down, hinder",
		"def" : "to exscind; figuratively, to frustrate",
		"derivation" : "from G1537 (ἐκ) and G2875 (κόπτω)"
	},
	"G334" : {
		"def" : "a votive offering",
		"derivation" : "from G394 (ἀνατίθεμαι) (like G331 (ἀνάθεμα), but in a good sense)",
		"lemma" : "ἀνάθημα",
		"translit" : "anáthēma",
		"kjv" : "gift"
	},
	"G779" : {
		"translit" : "askós",
		"lemma" : "ἀσκός",
		"kjv" : "bottle",
		"def" : "a leathern (or skin) bag used as a bottle",
		"derivation" : "from the same as G778 (ἀσκέω)"
	},
	"G4334" : {
		"def" : "to approach, i.e. (literally) come near, visit, or (figuratively) worship, assent to",
		"derivation" : "from G4314 (πρός) and G2064 (ἔρχομαι) (including its alternate)",
		"lemma" : "προσέρχομαι",
		"translit" : "prosérchomai",
		"kjv" : "(as soon as he) come (unto), come thereunto, consent, draw near, go (near, to, unto)"
	},
	"G2302" : {
		"kjv" : "spectacle, theatre",
		"lemma" : "θέατρον",
		"translit" : "théatron",
		"derivation" : "from G2300 (θεάομαι)",
		"def" : "a place for public show (\"theatre\"), i.e. general audience-room; by implication, a show itself (figuratively)"
	},
	"G4023" : {
		"def" : "to hold all around, i.e. include, clasp (figuratively)",
		"derivation" : "from G4012 (περί) and G2192 (ἔχω)",
		"lemma" : "περιέχω",
		"translit" : "periéchō",
		"kjv" : "+ astonished, contain, after (this manner)"
	},
	"G1926" : {
		"kjv" : "receive",
		"translit" : "epidéchomai",
		"lemma" : "ἐπιδέχομαι",
		"derivation" : "from G1909 (ἐπί) and G1209 (δέχομαι)",
		"def" : "to admit (as a guest or (figuratively) teacher)"
	},
	"G1824" : {
		"translit" : "exautēs",
		"lemma" : "ἐξαυτῆς",
		"kjv" : "by and by, immediately, presently, straightway",
		"def" : "from that hour, i.e. instantly",
		"derivation" : "from G1537 (ἐκ) and the genitive case singular feminine of G846 (αὐτός) (G5610 (ὥρα) being understood)"
	},
	"G1505" : {
		"derivation" : "from G1506 (εἰλικρινής)",
		"def" : "clearness, i.e. (by implication) purity (figuratively)",
		"kjv" : "sincerity",
		"translit" : "eilikríneia",
		"lemma" : "εἰλικρίνεια"
	},
	"G4309" : {
		"kjv" : "determine before, ordain, predestinate",
		"lemma" : "προορίζω",
		"translit" : "proorízō",
		"derivation" : "from G4253 (πρό) and G3724 (ὁρίζω)",
		"def" : "to limit in advance, i.e. (figuratively) predetermine"
	},
	"G1498" : {
		"def" : "might (could, would, or should) be",
		"derivation" : "optative (i.e. English subjunctive) present of G1510 (εἰμί) (including the other person)",
		"translit" : "eíēn",
		"lemma" : "εἴην",
		"kjv" : "mean, + perish, should be, was, were"
	},
	"G3681" : {
		"derivation" : "probably akin to the base of G3686 (ὄνομα)",
		"def" : "notoriety, i.e. a taunt (disgrace)",
		"kjv" : "reproach",
		"translit" : "óneidos",
		"lemma" : "ὄνειδος"
	},
	"G1623" : {
		"def" : "sixth",
		"derivation" : "ordinal from G1803 (ἕξ)",
		"lemma" : "ἕκτος",
		"translit" : "héktos",
		"kjv" : "sixth"
	},
	"G4615" : {
		"kjv" : "mustard",
		"translit" : "sínapi",
		"lemma" : "σίναπι",
		"derivation" : "perhaps from (to hurt, i.e. sting)",
		"def" : "mustard (the plant)"
	},
	"G1119" : {
		"derivation" : "of uncertain affinity",
		"def" : "the \"knee\"",
		"kjv" : "knee(X -l)",
		"translit" : "góny",
		"lemma" : "γόνυ"
	},
	"G2341" : {
		"kjv" : "fight with wild beasts",
		"lemma" : "θηριομαχέω",
		"translit" : "thēriomachéō",
		"derivation" : "from a compound of G2342 (θηρίον) and G3164 (μάχομαι)",
		"def" : "to be a beast-fighter (in the gladiatorial show), i.e. (figuratively) to encounter (furious men)"
	},
	"G4216" : {
		"kjv" : "carried away of the flood",
		"lemma" : "ποταμοφόρητος",
		"translit" : "potamophórētos",
		"derivation" : "from G4215 (ποταμός) and a derivative of G5409 (φορέω)",
		"def" : "river-borne, i.e. overwhelmed by a stream"
	},
	"G2524" : {
		"def" : "and (to send); to lower",
		"derivation" : "from G2596 (κατά)",
		"translit" : "kathíēmi",
		"lemma" : "καθίημι",
		"kjv" : "let down"
	},
	"G16" : {
		"derivation" : "from G17 (ἀγαθοποιός)",
		"def" : "well-doing, i.e. virtue",
		"kjv" : "well-doing",
		"translit" : "agathopoiḯa",
		"lemma" : "ἀγαθοποιΐα"
	},
	"G1902" : {
		"kjv" : "be clothed upon",
		"translit" : "ependýomai",
		"lemma" : "ἐπενδύομαι",
		"derivation" : "middle voice from G1909 (ἐπί) and G1746 (ἐνδύω)",
		"def" : "to invest upon oneself"
	},
	"G4321" : {
		"derivation" : "from G4314 (πρός) and G355 (ἀναλίσκω)",
		"def" : "to expend further",
		"kjv" : "spend",
		"translit" : "prosanalískō",
		"lemma" : "προσαναλίσκω"
	},
	"G2475" : {
		"kjv" : "Israelite",
		"translit" : "Israēlítēs",
		"lemma" : "Ἰσραηλίτης",
		"derivation" : "from G2474 (Ἰσραήλ)",
		"def" : "an \"Israelite\", i.e. descendant of Israel (literally or figuratively)"
	},
	"G1996" : {
		"kjv" : "gather (together)",
		"lemma" : "ἐπισυνάγω",
		"translit" : "episynágō",
		"derivation" : "from G1909 (ἐπί) and G4863 (συνάγω)",
		"def" : "to collect upon the same place"
	},
	"G2351" : {
		"def" : "a disturbance",
		"derivation" : "from the base of G2360 (θροέω)",
		"lemma" : "θόρυβος",
		"translit" : "thórybos",
		"kjv" : "tumult, uproar"
	},
	"G1582" : {
		"def" : "to hang upon the lips of a speaker, i.e. listen closely",
		"derivation" : "middle voice from G1537 (ἐκ) and G2910 (κρεμάννυμι)",
		"translit" : "ekkrémamai",
		"lemma" : "ἐκκρέμαμαι",
		"kjv" : "be very attentive"
	},
	"G4761" : {
		"derivation" : "from a derivative of G4762 (στρέφω)",
		"def" : "to wrench, i.e. (specially), to torture (by the rack), but only figuratively, to pervert",
		"kjv" : "wrest",
		"translit" : "streblóō",
		"lemma" : "στρεβλόω"
	},
	"G5291" : {
		"derivation" : "from G5259 (ὑπό) and G4766 (στρώννυμι)",
		"def" : "to strew underneath (the feet as a carpet)",
		"kjv" : "spread",
		"translit" : "hypostrṓnnymi",
		"lemma" : "ὑποστρώννυμι"
	},
	"G2248" : {
		"translit" : "hēmâs",
		"lemma" : "ἡμᾶς",
		"kjv" : "our, us, we",
		"def" : "us",
		"derivation" : "accusative case plural of G1473 (ἐγώ)"
	},
	"G303" : {
		"derivation" : "a primary preposition and adverb",
		"def" : "properly, up; but (by extension) used (distributively) severally, or (locally) at (etc.)",
		"kjv" : "and, apiece, by, each, every (man), in, through",
		"lemma" : "ἀνά",
		"translit" : "aná"
	},
	"G5003" : {
		"translit" : "talaipōréō",
		"lemma" : "ταλαιπωρέω",
		"kjv" : "be afflicted",
		"def" : "to be wretched, i.e. realize one's own misery",
		"derivation" : "from G5005 (ταλαίπωρος)"
	},
	"G2296" : {
		"derivation" : "from G2295 (θαῦμα)",
		"def" : "to wonder; by implication, to admire",
		"kjv" : "admire, have in admiration, marvel, wonder",
		"translit" : "thaumázō",
		"lemma" : "θαυμάζω"
	},
	"G2783" : {
		"kjv" : "preacher",
		"lemma" : "κῆρυξ",
		"translit" : "kēryx",
		"derivation" : "from G2784 (κηρύσσω)",
		"def" : "a herald, i.e. of divine truth (especially of the gospel)"
	},
	"G5235" : {
		"kjv" : "exceeding, excel, pass",
		"lemma" : "ὑπερβάλλω",
		"translit" : "hyperbállō",
		"derivation" : "from G5228 (ὑπέρ) and G906 (βάλλω)",
		"def" : "to throw beyod the usual mark, i.e. (figuratively) to surpass (only active participle supereminent)"
	},
	"G1363" : {
		"kjv" : "double",
		"lemma" : "διπλόω",
		"translit" : "diplóō",
		"derivation" : "from G1362 (διπλοῦς)",
		"def" : "to render two-fold"
	},
	"G4199" : {
		"lemma" : "πορθέω",
		"translit" : "porthéō",
		"kjv" : "destroy, waste",
		"def" : "to ravage (figuratively)",
		"derivation" : "prolongation from (to sack)"
	},
	"G3775" : {
		"kjv" : "ear",
		"translit" : "oûs",
		"lemma" : "οὖς",
		"derivation" : "apparently a primary word",
		"def" : "the ear (physically or mentally)"
	},
	"G1300" : {
		"derivation" : "from G1223 (διά) and G5055 (τελέω)",
		"def" : "to accomplish thoroughly, i.e. (subjectively) to persist",
		"kjv" : "continue",
		"lemma" : "διατελέω",
		"translit" : "diateléō"
	},
	"G4981" : {
		"kjv" : "school",
		"translit" : "scholḗ",
		"lemma" : "σχολή",
		"derivation" : "probably feminine of a presumed derivative of the alternate of G2192 (ἔχω)",
		"def" : "properly, loitering (as a withholding of oneself from work) or leisure, i.e. (by implication) a \"school\" (as vacation from physical employment)"
	},
	"G1256" : {
		"def" : "to say thoroughly, i.e. discuss (in argument or exhortation)",
		"derivation" : "middle voice from G1223 (διά) and G3004 (λέγω)",
		"lemma" : "διαλέγομαι",
		"translit" : "dialégomai",
		"kjv" : "dispute, preach (unto), reason (with), speak"
	},
	"G4411" : {
		"lemma" : "πρωτοκλισία",
		"translit" : "prōtoklisía",
		"kjv" : "chief (highest, uppermost) room",
		"def" : "a reclining first (in the place of honor) at the dinner-bed, i.e. preeminence at meals",
		"derivation" : "from G4413 (πρῶτος) and G2828 (κλισία)"
	},
	"G4119" : {
		"lemma" : "πλείων",
		"translit" : "pleíōn",
		"kjv" : "X above, + exceed, more excellent, further, (very) great(-er), long(-er), (very) many, greater (more) part, + yet but",
		"def" : "more in quantity, number, or quality; also (in plural) the major portion",
		"derivation" : "comparative of G4183 (πολύς)"
	},
	"G4428" : {
		"translit" : "ptýssō",
		"lemma" : "πτύσσω",
		"kjv" : "close",
		"def" : "and thus apparently allied to G4072 (πέτομαι) through the idea of expansion, and to 4429 through that of flattening; compare G3961 (πατέω)); to fold, i.e. furl a scroll",
		"derivation" : "probably akin to (to spread"
	},
	"G4418" : {
		"derivation" : "of uncertain derivation",
		"def" : "the heel (figuratively)",
		"kjv" : "heel",
		"lemma" : "πτέρνα",
		"translit" : "ptérna"
	},
	"G1150" : {
		"lemma" : "δαμάζω",
		"translit" : "damázō",
		"kjv" : "tame",
		"def" : "to tame",
		"derivation" : "a variation of an obsolete primary of the same meaning"
	},
	"G5434" : {
		"def" : "akin to the base of G5395 (φλόξ)); something desiccated, i.e. a dry twig",
		"derivation" : "neuter of a presumed derivative of (to roast or parch",
		"translit" : "phrýganon",
		"lemma" : "φρύγανον",
		"kjv" : "stick"
	},
	"G4573" : {
		"translit" : "sebázomai",
		"lemma" : "σεβάζομαι",
		"kjv" : "worship",
		"def" : "to venerate, i.e. adore",
		"derivation" : "middle voice from a derivative of G4576 (σέβομαι)"
	},
	"G3575" : {
		"lemma" : "Νῶε",
		"translit" : "Nōe",
		"kjv" : "Noe",
		"def" : "Noë, (i.e. Noäch), a patriarch",
		"derivation" : "of Hebrew origin (H5146)"
	},
	"G5284" : {
		"kjv" : "sail under",
		"lemma" : "ὑποπλέω",
		"translit" : "hypopléō",
		"derivation" : "from G5259 (ὑπό) and G4126 (πλέω)",
		"def" : "to sail under the lee of"
	},
	"G1554" : {
		"derivation" : "from G1537 (ἐκ) and G1325 (δίδωμι)",
		"def" : "to give forth, i.e. (specially) to lease",
		"kjv" : "let forth (out)",
		"lemma" : "ἐκδίδωμι",
		"translit" : "ekdídōmi"
	},
	"G4944" : {
		"derivation" : "from G4862 (σύν) and G5605 (ὠδίνω)",
		"def" : "to have (parturition) pangs in company (concert, simultaneously) with, i.e. (figuratively) to sympathize (in expectation of relief from suffering)",
		"kjv" : "travail in pain together",
		"translit" : "synōdínō",
		"lemma" : "συνωδίνω"
	},
	"G4515" : {
		"kjv" : "Latin",
		"lemma" : "Ῥωμαϊστί",
		"translit" : "Rhōmaïstí",
		"derivation" : "adverb from a presumed derivative of G4516 (Ῥώμη)",
		"def" : "Romaistically, i.e. in the Latin language"
	},
	"G584" : {
		"translit" : "apodeíknymi",
		"lemma" : "ἀποδείκνυμι",
		"kjv" : "(ap-)prove, set forth, shew",
		"def" : "to show off, i.e. exhibit; figuratively, to demonstrate, i.e. accredit",
		"derivation" : "from G575 (ἀπό) and G1166 (δεικνύω)"
	},
	"G5067" : {
		"def" : "fourth",
		"derivation" : "ordinal from G5064 (τέσσαρες)",
		"translit" : "tétartos",
		"lemma" : "τέταρτος",
		"kjv" : "four(-th)"
	},
	"G111" : {
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of (statute",
		"def" : "from the base of G5087 (τίθημι)); illegal; by implication, flagitious",
		"kjv" : "abominable, unlawful thing",
		"lemma" : "ἀθέμιτος",
		"translit" : "athémitos"
	},
	"G2755" : {
		"lemma" : "κενόδοξος",
		"translit" : "kenódoxos",
		"kjv" : "desirous of vain-glory",
		"def" : "vainly glorifying, i.e. self-conceited",
		"derivation" : "from G2756 (κενός) and G1391 (δόξα)"
	},
	"G5477" : {
		"translit" : "Chanaán",
		"lemma" : "Χαναάν",
		"kjv" : "Chanaan",
		"def" : "Chanaan (i.e. Kenaan), the early name of Palestine",
		"derivation" : "of Hebrew origin (H3667)"
	},
	"G1860" : {
		"derivation" : "from G1861 (ἐπαγγέλλω)",
		"def" : "an announcement (for information, assent or pledge; especially a divine assurance of good)",
		"kjv" : "message, promise",
		"lemma" : "ἐπαγγελία",
		"translit" : "epangelía"
	},
	"G839" : {
		"kjv" : "(to-)morrow, next day",
		"lemma" : "αὔριον",
		"translit" : "aúrion",
		"derivation" : "from a derivative of the same as G109 (ἀήρ) (meaning a breeze, i.e. the morning air)",
		"def" : "properly, fresh, i.e. (adverb with ellipsis of G2250 (ἡμέρα)) to-morrow"
	},
	"G4228" : {
		"kjv" : "foot(-stool)",
		"translit" : "poús",
		"lemma" : "πούς",
		"derivation" : "a primary word",
		"def" : "a \"foot\" (figuratively or literally)"
	},
	"G709" : {
		"kjv" : "dine",
		"translit" : "aristáō",
		"lemma" : "ἀριστάω",
		"derivation" : "from G712 (ἄριστον)",
		"def" : "to take the principle meal"
	},
	"G2324" : {
		"kjv" : "servant",
		"lemma" : "θεράπων",
		"translit" : "therápōn",
		"derivation" : "apparently a participle from an otherwise obsolete derivative of the base of G2330 (θέρος)",
		"def" : "a menial attendant (as if cherishing)"
	},
	"G2321" : {
		"kjv" : "Theophilus",
		"lemma" : "Θεόφιλος",
		"translit" : "Theóphilos",
		"derivation" : "from G2316 (θεός) and G5384 (φίλος)",
		"def" : "friend of God; Theophilus, a Christian"
	},
	"G3658" : {
		"lemma" : "ὅμιλος",
		"translit" : "hómilos",
		"kjv" : "company",
		"def" : "association together, i.e. a multitude",
		"derivation" : "from the base of G3674 (ὁμοῦ) and a derivative of the alternate of G138 (αἱρέομαι) (meaning a crowd)"
	},
	"G5080" : {
		"def" : "to liquefy",
		"derivation" : "apparently a primary verb",
		"lemma" : "τήκω",
		"translit" : "tḗkō",
		"kjv" : "melt"
	},
	"G2614" : {
		"kjv" : "follow after",
		"lemma" : "καταδιώκω",
		"translit" : "katadiṓkō",
		"derivation" : "from G2596 (κατά) and G1377 (διώκω)",
		"def" : "to hunt down, i.e. search for"
	},
	"G1906" : {
		"def" : "an inquiry",
		"derivation" : "from G1905 (ἐπερωτάω)",
		"lemma" : "ἐπερώτημα",
		"translit" : "eperṓtēma",
		"kjv" : "answer"
	},
	"G4480" : {
		"kjv" : "chariot",
		"translit" : "rhéda",
		"lemma" : "ῥέδα",
		"derivation" : "of Latin origin",
		"def" : "a rheda, i.e. four-wheeled carriage (wagon for riding)"
	},
	"G2939" : {
		"translit" : "ktístēs",
		"lemma" : "κτίστης",
		"kjv" : "Creator",
		"def" : "a founder, i.e. God (as author of all things)",
		"derivation" : "from G2936 (κτίζω)"
	},
	"G3366" : {
		"def" : "but not, not even; in a continued negation, nor",
		"derivation" : "from G3361 (μή) and G1161 (δέ)",
		"translit" : "mēdé",
		"lemma" : "μηδέ",
		"kjv" : "neither, nor (yet), (no) not (once, so much as)"
	},
	"G3413" : {
		"def" : "Michael, an archangel",
		"derivation" : "of Hebrew origin (H4317)",
		"lemma" : "Μιχαήλ",
		"translit" : "Michaḗl",
		"kjv" : "Michael"
	},
	"G4943" : {
		"kjv" : "help together",
		"lemma" : "συνυπουργέω",
		"translit" : "synypourgéō",
		"derivation" : "from G4862 (σύν) and a derivative of a compound of G5259 (ὑπό) and the base of G2041 (ἔργον)",
		"def" : "to be a co-auxiliary, i.e. assist"
	},
	"G1987" : {
		"derivation" : "apparently a middle voice of G2186 (ἐφίστημι) (with G3563 (νοῦς) implied)",
		"def" : "to put the mind upon, i.e. comprehend, or be acquainted with",
		"kjv" : "know, understand",
		"lemma" : "ἐπίσταμαι",
		"translit" : "epístamai"
	},
	"G2836" : {
		"derivation" : "from (\"hollow\")",
		"def" : "a cavity, i.e. (especially) the abdomen; by implication, the matrix; figuratively, the heart",
		"kjv" : "belly, womb",
		"lemma" : "κοιλία",
		"translit" : "koilía"
	},
	"G2736" : {
		"derivation" : "(compare G2737 (κατώτερος)); adverb from G2596 (κατά)",
		"def" : "downwards",
		"kjv" : "beneath, bottom, down, under",
		"lemma" : "κάτω",
		"translit" : "kátō"
	},
	"G1353" : {
		"def" : "to travel through",
		"derivation" : "from G1223 (διά) and G3593 (ὁδεύω)",
		"lemma" : "διοδεύω",
		"translit" : "diodeúō",
		"kjv" : "go throughout, pass through"
	},
	"G3114" : {
		"translit" : "makrothyméō",
		"lemma" : "μακροθυμέω",
		"kjv" : "bear (suffer) long, be longsuffering, have (long) patience, be patient, patiently endure",
		"def" : "to be long-spirited, i.e. (objectively) forbearing or (subjectively) patient",
		"derivation" : "from the same as G3116 (μακροθυμώς)"
	},
	"G2914" : {
		"kjv" : "Crete",
		"translit" : "Krḗtē",
		"lemma" : "Κρήτη",
		"derivation" : "of uncertain derivation",
		"def" : "Crete, an island in the Mediterranean"
	},
	"G4955" : {
		"lemma" : "συστασιαστής",
		"translit" : "systasiastḗs",
		"kjv" : "make insurrection with",
		"def" : "a fellow-insurgent",
		"derivation" : "from a compound of G4862 (σύν) and a derivative of G4714 (στάσις)"
	},
	"G5281" : {
		"kjv" : "enduring, patience, patient continuance (waiting)",
		"translit" : "hypomonḗ",
		"lemma" : "ὑπομονή",
		"derivation" : "from G5278 (ὑπομένω)",
		"def" : "cheerful (or hopeful) endurance, constancy"
	},
	"G1768" : {
		"kjv" : "ninety and nine",
		"lemma" : "ἐννενηκονταεννέα",
		"translit" : "ennenēkontaennéa",
		"derivation" : "from a (tenth) multiple of G1767 (ἐννέα) and G1767 (ἐννέα) itself",
		"def" : "ninety-nine"
	},
	"G3739" : {
		"def" : "the relatively (sometimes demonstrative) pronoun, who, which, what, that",
		"derivation" : "probably a primary word (or perhaps a form of the article G3588 (ὁ))",
		"lemma" : "ὅς",
		"translit" : "hós",
		"kjv" : "one, (an-, the) other, some, that, what, which, who(-m, -se), etc."
	},
	"G1816" : {
		"translit" : "exanatéllō",
		"lemma" : "ἐξανατέλλω",
		"kjv" : "spring up",
		"def" : "to start up out of the ground, i.e. germinate",
		"derivation" : "from G1537 (ἐκ) and G393 (ἀνατέλλω)"
	},
	"G442" : {
		"derivation" : "from G444 (ἄνθρωπος)",
		"def" : "human",
		"kjv" : "human, common to man, man(-kind), (man-)kind, men's, after the manner of men",
		"translit" : "anthrṓpinos",
		"lemma" : "ἀνθρώπινος"
	},
	"G5099" : {
		"def" : "to pay a price, i.e. as a penalty",
		"derivation" : "strengthened for a primary (which is only used as an alternate in certain tenses)",
		"translit" : "tínō",
		"lemma" : "τίνω",
		"kjv" : "be punished with"
	},
	"G918" : {
		"def" : "son of Tolmai; Bar-tholomæus, a Christian apostle",
		"derivation" : "of Chaldee origin (H1247 and H8526)",
		"lemma" : "Βαρθολομαῖος",
		"translit" : "Bartholomaîos",
		"kjv" : "Bartholomeus"
	},
	"G5333" : {
		"derivation" : "the same as 5332",
		"kjv" : "sorcerer",
		"lemma" : "φάρμακος",
		"translit" : "phármakos"
	},
	"G5030" : {
		"derivation" : "adverb from G5036 (ταχύς)",
		"def" : "briefly, i.e. (in time) speedily, or (in manner) rapidly",
		"kjv" : "hastily, quickly, shortly, soon, suddenly",
		"translit" : "tachéōs",
		"lemma" : "ταχέως"
	},
	"G1235" : {
		"lemma" : "διαγρηγορέω",
		"translit" : "diagrēgoréō",
		"kjv" : "be awake",
		"def" : "to waken thoroughly",
		"derivation" : "from G1223 (διά) and G1127 (γρηγορεύω)"
	},
	"G186" : {
		"kjv" : "without wavering",
		"translit" : "aklinḗs",
		"lemma" : "ἀκλινής",
		"derivation" : "from G1 (Α) (as a negative particle) and G2827 (κλίνω)",
		"def" : "not leaning, i.e. (figuratively) firm"
	},
	"G2523" : {
		"derivation" : "another (active) form for G2516 (καθέζομαι)",
		"def" : "to seat down, i.e. set (figuratively, appoint); intransitively, to sit (down); figuratively, to settle (hover, dwell)",
		"kjv" : "continue, set, sit (down), tarry",
		"translit" : "kathízō",
		"lemma" : "καθίζω"
	},
	"G3068" : {
		"translit" : "loúō",
		"lemma" : "λούω",
		"kjv" : "wash",
		"def" : "to bathe (the whole person; whereas G3538 (νίπτω) means to wet a part only, and G4150 (πλύνω) to wash, cleanse garments exclusively)",
		"derivation" : "a primary verb"
	},
	"G607" : {
		"translit" : "apokephalízō",
		"lemma" : "ἀποκεφαλίζω",
		"kjv" : "behead",
		"def" : "to decapitate",
		"derivation" : "from G575 (ἀπό) and G2776 (κεφαλή)"
	},
	"G4121" : {
		"kjv" : "abound, abundant, make to increase, have over",
		"translit" : "pleonázō",
		"lemma" : "πλεονάζω",
		"derivation" : "from G4119 (πλείων)",
		"def" : "to do, make or be more, i.e. increase (transitively or intransitively); by extension, to superabound"
	},
	"G1328" : {
		"kjv" : "interpreter",
		"lemma" : "διερμηνευτής",
		"translit" : "diermēneutḗs",
		"derivation" : "from G1329 (διερμηνεύω)",
		"def" : "an explainer"
	},
	"G4754" : {
		"kjv" : "soldier, (go to) war(-fare)",
		"lemma" : "στρατεύομαι",
		"translit" : "strateúomai",
		"derivation" : "middle voice from the base of G4756 (στρατιά)",
		"def" : "to serve in a military campaign; figuratively, to execute the apostolate (with its arduous duties and functions), to contend with carnal inclinations"
	},
	"G1975" : {
		"translit" : "epiporeúomai",
		"lemma" : "ἐπιπορεύομαι",
		"kjv" : "come",
		"def" : "to journey further, i.e. travel on (reach)",
		"derivation" : "from G1909 (ἐπί) and G4198 (πορεύομαι)"
	},
	"G2046" : {
		"def" : "an alternate for G2036 (ἔπω) in certain tenses; to utter, i.e. speak or say",
		"derivation" : "probably a fuller form of G4483 (ῥέω)",
		"lemma" : "ἐρέω",
		"translit" : "eréō",
		"kjv" : "call, say, speak (of), tell"
	},
	"G3200" : {
		"kjv" : "parchment",
		"lemma" : "μεμβράνα",
		"translit" : "membrána",
		"derivation" : "of Latin origin (\"membrane\")",
		"def" : "a (written) sheep-skin"
	},
	"G3698" : {
		"def" : "what(-ever) then, i.e. (of time) as soon as",
		"derivation" : "from G3739 (ὅς) and G4218 (ποτέ)",
		"lemma" : "ὁπότε",
		"translit" : "hopóte",
		"kjv" : "when"
	},
	"G4561" : {
		"lemma" : "σάρξ",
		"translit" : "sárx",
		"kjv" : "carnal(-ly, + -ly minded), flesh(-ly)",
		"def" : "flesh (as stripped of the skin), i.e. (strictly) the meat of an animal (as food), or (by extension) the body (as opposed to the soul (or spirit), or as the symbol of what is external, or as the means of kindred), or (by implication) human nature (with its frailties (physically or morally) and passions), or (specially), a human being (as such)",
		"derivation" : "probably from the base of G4563 (σαρόω)"
	},
	"G2501" : {
		"translit" : "Iōsḗph",
		"lemma" : "Ἰωσήφ",
		"kjv" : "Joseph",
		"def" : "Joseph, the name of seven Israelites",
		"derivation" : "of Hebrew origin (H3130)"
	},
	"G553" : {
		"translit" : "apekdéchomai",
		"lemma" : "ἀπεκδέχομαι",
		"kjv" : "look (wait) for",
		"def" : "to expect fully",
		"derivation" : "from G575 (ἀπό) and G1551 (ἐκδέχομαι)"
	},
	"G2584" : {
		"translit" : "Kapernaoúm",
		"lemma" : "Καπερναούμ",
		"kjv" : "Capernaum",
		"def" : "Capernaum (i.e. Caphanachum), a place in Palestine",
		"derivation" : "of Hebrew origin (probably H3723 and H5151)"
	},
	"G2496" : {
		"translit" : "Iōrám",
		"lemma" : "Ἰωράμ",
		"kjv" : "Joram",
		"def" : "Joram, an Israelite",
		"derivation" : "of Hebrew origin (H3141)"
	},
	"G658" : {
		"def" : "to complete entirely, i.e. consummate",
		"derivation" : "from G575 (ἀπό) and G5055 (τελέω)",
		"lemma" : "ἀποτελέω",
		"translit" : "apoteléō",
		"kjv" : "finish"
	},
	"G1161" : {
		"kjv" : "also, and, but, moreover, now (often unexpressed in English)",
		"lemma" : "δέ",
		"translit" : "dé",
		"derivation" : "a primary particle (adversative or continuative)",
		"def" : "but, and, etc."
	},
	"G5518" : {
		"kjv" : "measure",
		"translit" : "choînix",
		"lemma" : "χοῖνιξ",
		"derivation" : "of uncertain derivation",
		"def" : "a chœnix or certain dry measure"
	},
	"G4709" : {
		"translit" : "spoudaíōs",
		"lemma" : "σπουδαίως",
		"kjv" : "diligently, instantly",
		"def" : "earnestly, promptly",
		"derivation" : "adverb from G4705 (σπουδαῖος)"
	},
	"G1473" : {
		"derivation" : "a primary pronoun of the first person I (only expressed when emphatic)",
		"translit" : "egṓ",
		"lemma" : "ἐγώ",
		"kjv" : "I, me"
	},
	"G578" : {
		"lemma" : "ἀποβλέπω",
		"translit" : "apoblépō",
		"kjv" : "have respect",
		"def" : "to look away from everything else, i.e. (figuratively) intently regard",
		"derivation" : "from G575 (ἀπό) and G991 (βλέπω)"
	},
	"G2607" : {
		"derivation" : "from G2596 (κατά) and G1097 (γινώσκω)",
		"def" : "to note against, i.e. find fault with",
		"kjv" : "blame, condemn",
		"lemma" : "καταγινώσκω",
		"translit" : "kataginṓskō"
	},
	"G5265" : {
		"def" : "to bind under one's feet, i.e. put on shoes or sandals",
		"derivation" : "from G5259 (ὑπό) and G1210 (δέω)",
		"lemma" : "ὑποδέω",
		"translit" : "hypodéō",
		"kjv" : "bind on, (be) shod"
	},
	"G2655" : {
		"translit" : "katanarkáō",
		"lemma" : "καταναρκάω",
		"kjv" : "be burdensome (chargeable)",
		"def" : "to grow utterly torpid, i.e. (by implication) slothful (figuratively, expensive)",
		"derivation" : "from G2596 (κατά) and (to be numb)"
	},
	"G3535" : {
		"lemma" : "Νινευΐ",
		"translit" : "Nineuḯ",
		"kjv" : "Nineve",
		"def" : "Ninevi (i.e. Nineveh), the capital of Assyria",
		"derivation" : "of Hebrew origin (H5210)"
	},
	"G3902" : {
		"lemma" : "παράσημος",
		"translit" : "parásēmos",
		"kjv" : "sign",
		"def" : "side-marked, i.e. labelled (with a badge (figure-head) of a ship)",
		"derivation" : "from G3844 (παρά) and the base of G4591 (σημαίνω)"
	},
	"G4698" : {
		"lemma" : "σπλάγχνον",
		"translit" : "splánchnon",
		"kjv" : "bowels, inward affection, + tender mercy",
		"def" : "an intestine (plural); figuratively, pity or sympathy",
		"derivation" : "probably strengthened from (the \"spleen\")"
	},
	"G2897" : {
		"translit" : "kraipálē",
		"lemma" : "κραιπάλη",
		"kjv" : "surfeiting",
		"def" : "properly, a headache (as a seizure of pain) from drunkenness, i.e. (by implication) a debauch (by analogy, a glut)",
		"derivation" : "probably from the same as G726 (ἁρπάζω)"
	},
	"G2531" : {
		"derivation" : "from G2596 (κατά) and G5613 (ὡς)",
		"def" : "just (or inasmuch) as, that",
		"kjv" : "according to, (according, even) as, how, when",
		"lemma" : "καθώς",
		"translit" : "kathṓs"
	},
	"G947" : {
		"kjv" : "abominable",
		"lemma" : "βδελυκτός",
		"translit" : "bdelyktós",
		"derivation" : "from G948 (βδελύσσω)",
		"def" : "detestable, i.e. (specially) idolatrous"
	},
	"G504" : {
		"def" : "waterless, i.e. dry",
		"derivation" : "from G1 (Α) (as a negative particle) and G5204 (ὕδωρ)",
		"translit" : "ánydros",
		"lemma" : "ἄνυδρος",
		"kjv" : "dry, without water"
	},
	"G58" : {
		"derivation" : "from (to gather",
		"def" : "probably akin to G1453 (ἐγείρω)); properly, the town-square (as a place of public resort); by implication, a market or thoroughfare",
		"kjv" : "market(-place), street",
		"translit" : "agorá",
		"lemma" : "ἀγορά"
	},
	"G1883" : {
		"def" : "up above, i.e. over or on (of place, amount, rank, etc.)",
		"derivation" : "from G1909 (ἐπί) and G507 (ἄνω)",
		"translit" : "epánō",
		"lemma" : "ἐπάνω",
		"kjv" : "above, more than, (up-)on, over"
	},
	"G4774" : {
		"kjv" : "permission",
		"translit" : "syngnṓmē",
		"lemma" : "συγγνώμη",
		"derivation" : "from a compound of G4862 (σύν) and G1097 (γινώσκω)",
		"def" : "fellow knowledge, i.e. concession"
	},
	"G2106" : {
		"def" : "to think well of, i.e. approve (an act); specially, to approbate (a person or thing)",
		"derivation" : "from G2095 (εὖ) and G1380 (δοκέω)",
		"translit" : "eudokéō",
		"lemma" : "εὐδοκέω",
		"kjv" : "think good, (be well) please(-d), be the good (have, take) pleasure, be willing"
	},
	"G1503" : {
		"derivation" : "apparently a primary verb (perhaps akin to G1502 (εἴκω) through the idea of faintness as a copy)",
		"def" : "to resemble",
		"kjv" : "be like",
		"translit" : "eíkō",
		"lemma" : "εἴκω"
	},
	"G4433" : {
		"def" : "to be a beggar, i.e. (by implication) to become indigent (figuratively)",
		"derivation" : "from G4434 (πτωχός)",
		"lemma" : "πτωχεύω",
		"translit" : "ptōcheúō",
		"kjv" : "become poor"
	},
	"G1831" : {
		"derivation" : "from G1537 (ἐκ) and G2064 (ἔρχομαι)",
		"def" : "to issue (literally or figuratively)",
		"kjv" : "come (forth, out), depart (out of), escape, get out, go (abroad, away, forth, out, thence), proceed (forth), spread abroad",
		"translit" : "exérchomai",
		"lemma" : "ἐξέρχομαι"
	},
	"G4142" : {
		"lemma" : "πλοιάριον",
		"translit" : "ploiárion",
		"kjv" : "boat, little (small) ship",
		"def" : "a boat",
		"derivation" : "neuter of a presumed derivative of G4143 (πλοῖον)"
	},
	"G1297" : {
		"kjv" : "commandment",
		"lemma" : "διάταγμα",
		"translit" : "diátagma",
		"derivation" : "from G1299 (διατάσσω)",
		"def" : "an arrangement, i.e. (authoritative) edict"
	},
	"G5122" : {
		"kjv" : "named",
		"lemma" : "τοὔνομα",
		"translit" : "toúnoma",
		"derivation" : "contraction for the neuter of G3588 (ὁ) and G3686 (ὄνομα)",
		"def" : "the name (is)"
	},
	"G1556" : {
		"def" : "to vindicate, retaliate, punish",
		"derivation" : "from G1558 (ἔκδικος)",
		"lemma" : "ἐκδικέω",
		"translit" : "ekdikéō",
		"kjv" : "a (re-)venge"
	},
	"G1296" : {
		"def" : "arrangement, i.e. institution",
		"derivation" : "from G1299 (διατάσσω)",
		"translit" : "diatagḗ",
		"lemma" : "διαταγή",
		"kjv" : "instrumentality"
	},
	"G2093" : {
		"kjv" : "ready",
		"lemma" : "ἑτοίμως",
		"translit" : "hetoímōs",
		"derivation" : "adverb from G2092 (ἕτοιμος)",
		"def" : "in readiness"
	},
	"G3124" : {
		"kjv" : "Malchus",
		"lemma" : "Μάλχος",
		"translit" : "Málchos",
		"derivation" : "of Hebrew origin (H4429)",
		"def" : "Malchus, an Israelite"
	},
	"G5029" : {
		"kjv" : "peradventure(-haps)",
		"translit" : "tácha",
		"lemma" : "τάχα",
		"derivation" : "as if neuter plural of G5036 (ταχύς) (adverbially)",
		"def" : "shortly, i.e. (figuratively) possibly"
	},
	"G3685" : {
		"translit" : "onínēmi",
		"lemma" : "ὀνίνημι",
		"kjv" : "have joy",
		"def" : "for which another prolonged form () is used as an alternate in some tenses (unless indeed it be identical with the base of G3686 (ὄνομα) through the idea of notoriety); to gratify, i.e. (middle voice) to derive pleasure or advantage from",
		"derivation" : "a prolonged form of an apparently primary verb (, to slur)"
	},
	"G3720" : {
		"derivation" : "from G3722 (ὄρθρος)",
		"def" : "relating to the dawn, i.e. matutinal (as an epithet of Venus, especially brilliant in the early day)",
		"kjv" : "morning",
		"lemma" : "ὀρθρινός",
		"translit" : "orthrinós"
	},
	"G2339" : {
		"derivation" : "from (a wild animal, as game)",
		"def" : "hunting, i.e. (figuratively) destruction",
		"kjv" : "trap",
		"lemma" : "θήρα",
		"translit" : "thḗra"
	},
	"G3095" : {
		"lemma" : "μαγεία",
		"translit" : "mageía",
		"kjv" : "sorcery",
		"def" : "\"magic\"",
		"derivation" : "from G3096 (μαγεύω)"
	},
	"G4531" : {
		"kjv" : "move, shake (together), which can(-not) be shaken, stir up",
		"lemma" : "σαλεύω",
		"translit" : "saleúō",
		"derivation" : "from G4535 (σάλος)",
		"def" : "to waver, i.e. agitate, rock, topple or (by implication) destroy; figuratively, to disturb, incite"
	},
	"G2103" : {
		"translit" : "Eúboulos",
		"lemma" : "Εὔβουλος",
		"kjv" : "Eubulus",
		"def" : "good-willer; Eubulus, a Christian",
		"derivation" : "from G2095 (εὖ) and G1014 (βούλομαι)"
	},
	"G4855" : {
		"derivation" : "from G4862 (σύν) and G5453 (φύω)",
		"def" : "passive, to grow jointly",
		"kjv" : "spring up with",
		"lemma" : "συμφύω",
		"translit" : "symphýō"
	},
	"G405" : {
		"translit" : "andrapodistḗs",
		"lemma" : "ἀνδραποδιστής",
		"kjv" : "menstealer",
		"def" : "an enslaver (as bringing men to his feet)",
		"derivation" : "from a derivative of a compound of G435 (ἀνήρ) and G4228 (πούς)"
	},
	"G2225" : {
		"kjv" : "live, preserve",
		"translit" : "zōogonéō",
		"lemma" : "ζωογονέω",
		"derivation" : "from the same as G2226 (ζῶον) and a derivative of G1096 (γίνομαι)",
		"def" : "to engender alive, i.e. (by analogy) to rescue (passively, be saved) from death"
	},
	"G2192" : {
		"derivation" : "a primary verb",
		"def" : "to hold (used in very various applications, literally or figuratively, direct or remote; such as possession; ability, contiuity, relation, or condition)",
		"kjv" : "be (able, X hold, possessed with), accompany, + begin to amend, can(+ -not), X conceive, count, diseased, do + eat, + enjoy, + fear, following, have, hold, keep, + lack, + go to law, lie, + must needs, + of necessity, + need, next, + recover, + reign, + rest, + return, X sick, take for, + tremble, + uncircumcised, use",
		"translit" : "échō",
		"lemma" : "ἔχω"
	},
	"G4620" : {
		"kjv" : "portion of meat",
		"translit" : "sitómetron",
		"lemma" : "σιτόμετρον",
		"derivation" : "from G4621 (σῖτος) and G3358 (μέτρον)",
		"def" : "a grain-measure, i.e. (by implication) ration (allowance of food)"
	},
	"G2005" : {
		"def" : "to fulfill further (or completely), i.e. execute; by implication, to terminate, undergo",
		"derivation" : "from G1909 (ἐπί) and G5055 (τελέω)",
		"translit" : "epiteléō",
		"lemma" : "ἐπιτελέω",
		"kjv" : "accomplish, do, finish, (make) (perfect), perform(X -ance)"
	},
	"G193" : {
		"kjv" : "incontinent",
		"translit" : "akratḗs",
		"lemma" : "ἀκρατής",
		"derivation" : "from G1 (Α) (as a negative particle) and G2904 (κράτος)",
		"def" : "powerless, i.e. without self-control"
	},
	"G4483" : {
		"kjv" : "command, make, say, speak (of)",
		"lemma" : "ῥέω",
		"translit" : "rhéō",
		"derivation" : "perhaps akin (or identical) with G4482 (ῥέω) (through the idea of pouring forth)",
		"def" : "to utter, i.e. speak or say"
	},
	"G2551" : {
		"kjv" : "curse, speak evil of",
		"translit" : "kakologéō",
		"lemma" : "κακολογέω",
		"derivation" : "from a compound of G2556 (κακός) and G3056 (λόγος)",
		"def" : "to revile"
	},
	"G1196" : {
		"derivation" : "from G1199 (δεσμόν)",
		"def" : "to tie, i.e. shackle",
		"kjv" : "bind",
		"lemma" : "δεσμέω",
		"translit" : "desméō"
	},
	"G2371" : {
		"def" : "to be in a furious fight, i.e. (figuratively) to be exasperated",
		"derivation" : "from a presumed compound of G2372 (θυμός) and G3164 (μάχομαι)",
		"lemma" : "θυμομαχέω",
		"translit" : "thymomachéō",
		"kjv" : "be highly displeased"
	},
	"G1130" : {
		"derivation" : "from a derivative of G1131 (γυμνός)",
		"def" : "to strip, i.e. (reflexively) go poorly clad",
		"kjv" : "be naked",
		"lemma" : "γυμνητεύω",
		"translit" : "gymnēteúō"
	},
	"G4706" : {
		"lemma" : "σπουδαιότερον",
		"translit" : "spoudaióteron",
		"kjv" : "very diligently",
		"def" : "more earnestly than others), i.e. very promptly",
		"derivation" : "neuter of G4707 (σπουδαιότερος) as adverb"
	},
	"G2261" : {
		"kjv" : "gentle",
		"translit" : "ḗpios",
		"lemma" : "ἤπιος",
		"derivation" : "probably from G2031 (ἔπος)",
		"def" : "properly, affable, i.e. mild or kind"
	},
	"G5257" : {
		"derivation" : "from G5259 (ὑπό) and a derivative of (to row)",
		"def" : "an under-oarsman, i.e. (generally) subordinate (assistant, sexton, constable)",
		"kjv" : "minister, officer, servant",
		"lemma" : "ὑπηρέτης",
		"translit" : "hypērétēs"
	},
	"G3662" : {
		"def" : "to resemble",
		"derivation" : "from G3664 (ὅμοιος)",
		"translit" : "homoiázō",
		"lemma" : "ὁμοιάζω",
		"kjv" : "agree"
	},
	"G274" : {
		"def" : "faultlessly",
		"derivation" : "adverb from G273 (ἄμεμπτος)",
		"lemma" : "ἀμέμπτως",
		"translit" : "amémptōs",
		"kjv" : "blameless, unblamably"
	},
	"G132" : {
		"derivation" : "of uncertain derivation",
		"def" : "Ænĕas, an Israelite",
		"kjv" : "Æneas",
		"lemma" : "Αἰνέας",
		"translit" : "Ainéas"
	},
	"G4026" : {
		"derivation" : "from G4012 (περί) and G2476 (ἵστημι)",
		"def" : "to stand all around, i.e. (near) to be a bystander, or (aloof) to keep away from",
		"kjv" : "avoid, shun, stand by (round about)",
		"translit" : "periḯstēmi",
		"lemma" : "περιΐστημι"
	},
	"G5056" : {
		"derivation" : "from a primary (to set out for a definite point or goal)",
		"def" : "properly, the point aimed at as a limit, i.e. (by implication) the conclusion of an act or state (termination (literally, figuratively or indefinitely), result (immediate, ultimate or prophetic), purpose); specially, an impost or levy (as paid)",
		"kjv" : "+ continual, custom, end(-ing), finally, uttermost",
		"translit" : "télos",
		"lemma" : "τέλος"
	},
	"G1701" : {
		"lemma" : "ἐμπαιγμός",
		"translit" : "empaigmós",
		"kjv" : "mocking",
		"def" : "derision",
		"derivation" : "from G1702 (ἐμπαίζω)"
	},
	"G3090" : {
		"kjv" : "Lois",
		"lemma" : "Λωΐς",
		"translit" : "Lōḯs",
		"derivation" : "of uncertain origin",
		"def" : "Lois, a Christian woman"
	},
	"G668" : {
		"kjv" : "escape",
		"translit" : "apopheúgō",
		"lemma" : "ἀποφεύγω",
		"derivation" : "from G575 (ἀπό) and G5343 (φεύγω)",
		"def" : "(figuratively) to escape"
	},
	"G3736" : {
		"lemma" : "ὀρύσσω",
		"translit" : "orýssō",
		"kjv" : "dig",
		"def" : "to \"burrow\" in the ground, i.e. dig",
		"derivation" : "apparently a primary verb"
	},
	"G3881" : {
		"derivation" : "from G3844 (παρά) and the middle voice of G3004 (λέγω) (in its original sense)",
		"def" : "(specially), to lay one's course near, i.e. sail past",
		"kjv" : "pass, sail by",
		"lemma" : "παραλέγομαι",
		"translit" : "paralégomai"
	},
	"G1546" : {
		"derivation" : "from G1544 (ἐκβάλλω)",
		"def" : "ejection, i.e. (specially) a throwing overboard of the cargo",
		"kjv" : "+ lighten the ship",
		"translit" : "ekbolḗ",
		"lemma" : "ἐκβολή"
	},
	"G5300" : {
		"translit" : "hŷs",
		"lemma" : "ὗς",
		"kjv" : "sow",
		"def" : "a hog (\"swine\")",
		"derivation" : "apparently a primary word"
	},
	"G5016" : {
		"kjv" : "trouble(-ing)",
		"lemma" : "ταραχή",
		"translit" : "tarachḗ",
		"derivation" : "feminine from G5015 (ταράσσω)",
		"def" : "disturbance, i.e. (of water) roiling, or (of a mob) sedition"
	},
	"G2262" : {
		"derivation" : "of Hebrew origin (H6147)",
		"def" : "Er, an Israelite",
		"kjv" : "Er",
		"lemma" : "Ἤρ",
		"translit" : "Ḗr"
	},
	"G830" : {
		"def" : "self-chosen, i.e. (by implication) voluntary",
		"derivation" : "from G846 (αὐτός) and the same as G140 (αἱρετίζω)",
		"lemma" : "αὐθαίρετος",
		"translit" : "authaíretos",
		"kjv" : "of own accord, willing of self"
	},
	"G4778" : {
		"def" : "to maltreat in company with, i.e. (passively) endure persecution together",
		"derivation" : "from G4862 (σύν) and G2558 (κακουχέω)",
		"translit" : "synkakouchéō",
		"lemma" : "συγκακουχέω",
		"kjv" : "suffer affliction with"
	},
	"G3153" : {
		"derivation" : "from G3152 (μάταιος)",
		"def" : "inutility; figuratively, transientness; morally, depravity",
		"kjv" : "vanity",
		"translit" : "mataiótēs",
		"lemma" : "ματαιότης"
	},
	"G3985" : {
		"derivation" : "from G3984 (πεῖρα)",
		"def" : "to test (objectively), i.e. endeavor, scrutinize, entice, discipline",
		"kjv" : "assay, examine, go about, prove, tempt(-er), try",
		"lemma" : "πειράζω",
		"translit" : "peirázō"
	},
	"G932" : {
		"def" : "properly, royalty, i.e. (abstractly) rule, or (concretely) a realm (literally or figuratively)",
		"derivation" : "from G935 (βασιλεύς)",
		"lemma" : "βασιλεία",
		"translit" : "basileía",
		"kjv" : "kingdom, + reign"
	},
	"G4394" : {
		"translit" : "prophēteía",
		"lemma" : "προφητεία",
		"kjv" : "prophecy, prophesying",
		"def" : "prediction (scriptural or other)",
		"derivation" : "from G4396 (προφήτης) (\"prophecy\")"
	},
	"G4676" : {
		"derivation" : "of Latin origin",
		"def" : "a sudarium (sweat-cloth), i.e. towel (for wiping the perspiration from the face, or binding the face of a corpse)",
		"kjv" : "handkerchief, napkin",
		"translit" : "soudárion",
		"lemma" : "σουδάριον"
	},
	"G5267" : {
		"translit" : "hypódikos",
		"lemma" : "ὑπόδικος",
		"kjv" : "guilty",
		"def" : "under sentence, i.e. (by implication) condemned",
		"derivation" : "from G5259 (ὑπό) and G1349 (δίκη)"
	},
	"G52" : {
		"def" : "ignorance (properly, the quality)",
		"derivation" : "from G50 (ἀγνοέω)",
		"translit" : "ágnoia",
		"lemma" : "ἄγνοια",
		"kjv" : "ignorance"
	},
	"G2612" : {
		"kjv" : "far more evident",
		"translit" : "katádēlos",
		"lemma" : "κατάδηλος",
		"derivation" : "from G2596 (κατά) intensive and G1212 (δῆλος)",
		"def" : "manifest"
	},
	"G4731" : {
		"def" : "stiff, i.e. solid, stable (literally or figuratively)",
		"derivation" : "from G2476 (ἵστημι)",
		"lemma" : "στερεός",
		"translit" : "stereós",
		"kjv" : "stedfast, strong, sure"
	},
	"G3626" : {
		"def" : "be \"ware\"); a stayer at home, i.e. domestically inclined (a \"good housekeeper\")",
		"derivation" : "from G3624 (οἶκος) and (a guard",
		"translit" : "oikourós",
		"lemma" : "οἰκουρός",
		"kjv" : "keeper at home"
	},
	"G2380" : {
		"lemma" : "θύω",
		"translit" : "thýō",
		"kjv" : "kill, (do) sacrifice, slay",
		"def" : "properly, to rush (breathe hard, blow, smoke), i.e. (by implication) to sacrifice (properly, by fire, but genitive case); by extension to immolate (slaughter for any purpose)",
		"derivation" : "a primary verb"
	},
	"G5453" : {
		"lemma" : "φύω",
		"translit" : "phýō",
		"kjv" : "spring (up)",
		"def" : "probably originally, to \"puff\" or blow, i.e. to swell up; but only used in the implied sense, to germinate or grow (sprout, produce), literally or figuratively",
		"derivation" : "a primary verb"
	},
	"G2616" : {
		"kjv" : "oppress",
		"translit" : "katadynasteúō",
		"lemma" : "καταδυναστεύω",
		"derivation" : "from G2596 (κατά) and a derivative of G1413 (δυνάστης)",
		"def" : "to exercise dominion against, i.e. oppress"
	},
	"G5375" : {
		"kjv" : "Philippi",
		"translit" : "Phílippoi",
		"lemma" : "Φίλιπποι",
		"derivation" : "plural of G5376 (Φίλιππος)",
		"def" : "Philippi, a place in Macedonia"
	},
	"G2872" : {
		"derivation" : "from a derivative of G2873 (κόπος)",
		"def" : "to feel fatigue; by implication, to work hard",
		"kjv" : "(bestow) labour, toil, be wearied",
		"translit" : "kopiáō",
		"lemma" : "κοπιάω"
	},
	"G525" : {
		"lemma" : "ἀπαλλάσσω",
		"translit" : "apallássō",
		"kjv" : "deliver, depart",
		"def" : "to change away, i.e. release, (reflexively) remove",
		"derivation" : "from G575 (ἀπό) and G236 (ἀλλάσσω)"
	},
	"G4799" : {
		"kjv" : "confusion",
		"translit" : "sýnchysis",
		"lemma" : "σύγχυσις",
		"derivation" : "from G4797 (συγχέω)",
		"def" : "commixture, i.e. (figuratively) riotous disturbance"
	},
	"G1487" : {
		"derivation" : "a primary particle of conditionality",
		"def" : "if, whether, that, etc.",
		"kjv" : "forasmuch as, if, that, (al-)though, whether",
		"translit" : "ei",
		"lemma" : "εἰ"
	},
	"G327" : {
		"def" : "to search out",
		"derivation" : "from G303 (ἀνά) and G2212 (ζητέω)",
		"lemma" : "ἀναζητέω",
		"translit" : "anazētéō",
		"kjv" : "seek"
	},
	"G134" : {
		"def" : "to praise (God)",
		"derivation" : "from G136 (αἶνος)",
		"translit" : "ainéō",
		"lemma" : "αἰνέω",
		"kjv" : "praise"
	},
	"G1228" : {
		"kjv" : "false accuser, devil, slanderer",
		"lemma" : "διάβολος",
		"translit" : "diábolos",
		"derivation" : "from G1225 (διαβάλλω)",
		"def" : "a traducer; specially, Satan (compare H7854)"
	},
	"G3419" : {
		"derivation" : "from G3420 (μνήμη)",
		"def" : "a remembrance, i.e. cenotaph (place of interment)",
		"kjv" : "grave, sepulchre, tomb",
		"lemma" : "μνημεῖον",
		"translit" : "mnēmeîon"
	},
	"G5181" : {
		"def" : "a \"tyrant\"; Tyrannus, an Ephesian",
		"derivation" : "a provincial form of the derivative of the base of G2962 (κύριος)",
		"translit" : "Týrannos",
		"lemma" : "Τύραννος",
		"kjv" : "Tyrannus"
	},
	"G4607" : {
		"def" : "a dagger-man or assassin; a freebooter (Jewish fanatic outlawed by the Romans)",
		"derivation" : "of Latin origin",
		"translit" : "sikários",
		"lemma" : "σικάριος",
		"kjv" : "murderer"
	},
	"G5088" : {
		"translit" : "tíktō",
		"lemma" : "τίκτω",
		"kjv" : "bear, be born, bring forth, be delivered, be in travail",
		"def" : "to produce (from seed, as a mother, a plant, the earth, etc.), literally or figuratively",
		"derivation" : "a strengthened form of a primary (which is used only as alternate in certain tenses)"
	},
	"G3744" : {
		"def" : "fragrance (literally or figuratively)",
		"derivation" : "from G3605 (ὄζω)",
		"translit" : "osmḗ",
		"lemma" : "ὀσμή",
		"kjv" : "odour, savour"
	},
	"G501" : {
		"lemma" : "ἀντλέω",
		"translit" : "antléō",
		"kjv" : "draw (out)",
		"def" : "to bale up (properly, bilge water), i.e. dip water (with a bucket, pitcher, etc.)",
		"derivation" : "from (the hold of a ship)"
	},
	"G4113" : {
		"derivation" : "feminine of G4116 (πλατύς)",
		"def" : "a wide \"plat\" or \"place\", i.e. open square",
		"kjv" : "street",
		"lemma" : "πλατεῖα",
		"translit" : "plateîa"
	},
	"G571" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G4103 (πιστός)",
		"def" : "(actively) disbelieving, i.e. without Christian faith (specially, a heathen); (passively) untrustworthy (person), or incredible (thing)",
		"kjv" : "that believeth not, faithless, incredible thing, infidel, unbeliever(-ing)",
		"translit" : "ápistos",
		"lemma" : "ἄπιστος"
	},
	"G2165" : {
		"lemma" : "εὐφραίνω",
		"translit" : "euphraínō",
		"kjv" : "fare, make glad, be (make) merry, rejoice",
		"def" : "to put (middle voice or passively, be) in a good frame of mind, i.e. rejoice",
		"derivation" : "from G2095 (εὖ) and G5424 (φρήν)"
	},
	"G1165" : {
		"lemma" : "δειγματίζω",
		"translit" : "deigmatízō",
		"kjv" : "make a shew",
		"def" : "to exhibit",
		"derivation" : "from G1164 (δεῖγμα)"
	},
	"G1056" : {
		"translit" : "Galilaía",
		"lemma" : "Γαλιλαία",
		"kjv" : "Galilee",
		"def" : "Galilæa (i.e. the heathen circle), a region of Palestine",
		"derivation" : "of Hebrew origin (H1551)"
	},
	"G3458" : {
		"derivation" : "probably ultimately from the base of G3433 (μόλις) (through the idea of hardship)",
		"def" : "a \"mill\", i.e. (by implication), a grinder (millstone)",
		"kjv" : "millstone",
		"lemma" : "μύλος",
		"translit" : "mýlos"
	},
	"G2100" : {
		"derivation" : "from G2101 (εὐάρεστος)",
		"def" : "to gratify entirely",
		"kjv" : "please (well)",
		"translit" : "euarestéō",
		"lemma" : "εὐαρεστέω"
	},
	"G1268" : {
		"kjv" : "spread",
		"translit" : "dianémō",
		"lemma" : "διανέμω",
		"derivation" : "from G1223 (διά) and the base of G3551 (νόμος)",
		"def" : "to distribute, i.e. (of information) to disseminate"
	},
	"G5131" : {
		"kjv" : "goat",
		"translit" : "trágos",
		"lemma" : "τράγος",
		"derivation" : "from the base of G5176 (τρώγω)",
		"def" : "a he-goat (as a gnawer)"
	},
	"G4931" : {
		"def" : "to complete entirely; generally, to execute (literally or figuratively)",
		"derivation" : "from G4862 (σύν) and G5055 (τελέω)",
		"lemma" : "συντελέω",
		"translit" : "synteléō",
		"kjv" : "end, finish, fulfil, make"
	},
	"G1190" : {
		"def" : "a Derbæan or inhabitant of Derbe",
		"derivation" : "from G1191 (Δέρβη)",
		"translit" : "Derbaîos",
		"lemma" : "Δερβαῖος",
		"kjv" : "of Derbe"
	},
	"G1891" : {
		"def" : "Epaphroditus, a Christian",
		"derivation" : "from G1909 (ἐπί) (in the sense of devoted to) and (Venus)",
		"lemma" : "Ἐπαφρόδιτος",
		"translit" : "Epaphróditos",
		"kjv" : "Epaphroditus"
	},
	"G5273" : {
		"kjv" : "hypocrite",
		"translit" : "hypokritḗs",
		"lemma" : "ὑποκριτής",
		"derivation" : "from G5271 (ὑποκρίνομαι)",
		"def" : "an actor under an assumed character (stage-player), i.e. (figuratively) a dissembler (\"hypocrite\""
	},
	"G3079" : {
		"translit" : "Lysías",
		"lemma" : "Λυσίας",
		"kjv" : "Lysias",
		"def" : "Lysias, a Roman",
		"derivation" : "of uncertain affinity"
	},
	"G683" : {
		"kjv" : "cast away, put away (from), thrust away (from)",
		"translit" : "apōthéomai",
		"lemma" : "ἀπωθέομαι",
		"derivation" : "from G575 (ἀπό) and the middle voice of or (to shove)",
		"def" : "to push off, figuratively, to reject"
	},
	"G5276" : {
		"kjv" : "winefat",
		"translit" : "hypolḗnion",
		"lemma" : "ὑπολήνιον",
		"derivation" : "neuter of a presumed compound of G5259 (ὑπό) and G3025 (ληνός)",
		"def" : "vessel or receptacle under the press, i.e. lower wine-vat"
	},
	"G1170" : {
		"derivation" : "probably from the same as G1171 (δεινῶς) (through the idea of forgetting the name as fearful, i.e. strange)",
		"def" : "so and so (when the person is not specified)",
		"kjv" : "such a man",
		"lemma" : "δεῖνα",
		"translit" : "deîna"
	},
	"G5203" : {
		"kjv" : "have the dropsy",
		"translit" : "hydrōpikós",
		"lemma" : "ὑδρωπικός",
		"derivation" : "from a compound of G5204 (ὕδωρ) and a derivative of G3700 (ὀπτάνομαι) (as if looking watery)",
		"def" : "to be \"dropsical\""
	},
	"G3407" : {
		"derivation" : "from G3408 (μισθός)",
		"def" : "a wage-earner",
		"kjv" : "hired servant",
		"lemma" : "μίσθιος",
		"translit" : "místhios"
	},
	"G3332" : {
		"def" : "to betake oneself, i.e. remove (locally)",
		"derivation" : "from G3326 (μετά) and G142 (αἴρω)",
		"translit" : "metaírō",
		"lemma" : "μεταίρω",
		"kjv" : "depart"
	},
	"G4395" : {
		"derivation" : "from G4396 (προφήτης)",
		"def" : "to foretell events, divine, speak under inspiration, exercise the prophetic office",
		"kjv" : "prophesy",
		"translit" : "prophēteúō",
		"lemma" : "προφητεύω"
	},
	"G769" : {
		"def" : "feebleness (of mind or body); by implication, malady; morally, frailty",
		"derivation" : "from G772 (ἀσθενής)",
		"translit" : "asthéneia",
		"lemma" : "ἀσθένεια",
		"kjv" : "disease, infirmity, sickness, weakness"
	},
	"G378" : {
		"def" : "to complete; by implication, to occupy, supply; figuratively, to accomplish (by coincidence ot obedience)",
		"derivation" : "from G303 (ἀνά) and G4137 (πληρόω)",
		"translit" : "anaplēróō",
		"lemma" : "ἀναπληρόω",
		"kjv" : "fill up, fulfill, occupy, supply"
	},
	"G2558" : {
		"def" : "to maltreat",
		"derivation" : "from a presumed compound of G2556 (κακός) and G2192 (ἔχω)",
		"lemma" : "κακουχέω",
		"translit" : "kakouchéō",
		"kjv" : "which suffer adversity, torment"
	},
	"G2362" : {
		"kjv" : "seat, throne",
		"translit" : "thrónos",
		"lemma" : "θρόνος",
		"derivation" : "from (to sit)",
		"def" : "a stately seat (\"throne\"); by implication, power or (concretely) a potentate"
	},
	"G1358" : {
		"def" : "to penetrate burglariously",
		"derivation" : "from G1223 (διά) and G3736 (ὀρύσσω)",
		"lemma" : "διορύσσω",
		"translit" : "diorýssō",
		"kjv" : "break through (up)"
	},
	"G1663" : {
		"derivation" : "of Hebrew origin (H461)",
		"def" : "Eliezer, an Israelite",
		"kjv" : "Eliezer",
		"lemma" : "Ἐλιέζερ",
		"translit" : "Eliézer"
	},
	"G2898" : {
		"kjv" : "Calvary, skull",
		"lemma" : "κρανίον",
		"translit" : "kraníon",
		"derivation" : "diminutive of a derivative of the base of G2768 (κέρας)",
		"def" : "a skull (\"cranium\")"
	},
	"G659" : {
		"kjv" : "cast off, lay apart (aside, down), put away (off)",
		"lemma" : "ἀποτίθημι",
		"translit" : "apotíthēmi",
		"derivation" : "from G575 (ἀπό) and G5087 (τίθημι)",
		"def" : "to put away (literally or figuratively)"
	},
	"G1333" : {
		"kjv" : "two years",
		"lemma" : "διετία",
		"translit" : "dietía",
		"derivation" : "from G1332 (διετής)",
		"def" : "a space of two years (biennium)"
	},
	"G3823" : {
		"derivation" : "from (to vibrate",
		"def" : "another form for G906 (βάλλω)); wrestling",
		"kjv" : "+ wrestle",
		"translit" : "pálē",
		"lemma" : "πάλη"
	},
	"G3649" : {
		"translit" : "ololýzō",
		"lemma" : "ὀλολύζω",
		"kjv" : "howl",
		"def" : "to \"howl\" or \"halloo\", i.e. shriek",
		"derivation" : "a reduplicated primary verb"
	},
	"G756" : {
		"kjv" : "(rehearse from the) begin(-ning)",
		"translit" : "árchomai",
		"lemma" : "ἄρχομαι",
		"derivation" : "middle voice of G757 (ἄρχω) (through the implication, of precedence)",
		"def" : "to commence (in order of time)"
	},
	"G103" : {
		"def" : "to sing",
		"derivation" : "a primary verb",
		"translit" : "áidō",
		"lemma" : "ᾄδω",
		"kjv" : "sing"
	},
	"G5145" : {
		"def" : "three hundred",
		"derivation" : "plural from G5140 (τρεῖς) and G1540 (ἑκατόν)",
		"translit" : "triakósioi",
		"lemma" : "τριακόσιοι",
		"kjv" : "three hundred"
	},
	"G5179" : {
		"derivation" : "from G5180 (τύπτω)",
		"def" : "a die (as struck), i.e. (by implication) a stamp or scar; by analogy, a shape, i.e. a statue, (figuratively) style or resemblance; specially, a sampler (\"type\"), i.e. a model (for imitation) or instance (for warning)",
		"kjv" : "en-(ex-)ample, fashion, figure, form, manner, pattern, print",
		"lemma" : "τύπος",
		"translit" : "týpos"
	},
	"G2855" : {
		"kjv" : "(money-)changer",
		"translit" : "kollybistḗs",
		"lemma" : "κολλυβιστής",
		"derivation" : "from a presumed derivative of (a small coin",
		"def" : "probably akin to G2854 (κολλούριον)); a coin-dealer"
	},
	"G4078" : {
		"def" : "to fix (\"peg\"), i.e. (specially) to set up (a tent)",
		"derivation" : "a prolonged form of a primary verb (which in its simpler form occurs only as an alternate in certain tenses)",
		"lemma" : "πήγνυμι",
		"translit" : "pḗgnymi",
		"kjv" : "pitch"
	},
	"G3470" : {
		"kjv" : "blemish",
		"translit" : "mōmos",
		"lemma" : "μῶμος",
		"derivation" : "perhaps from G3201 (μέμφομαι)",
		"def" : "a flaw or blot, i.e. (figuratively) disgraceful person"
	},
	"G643" : {
		"lemma" : "ἀποσκευάζω",
		"translit" : "aposkeuázō",
		"kjv" : "take up… carriages",
		"def" : "to pack up (one's) baggage",
		"derivation" : "from G575 (ἀπό) and a derivative of G4632 (σκεῦος)"
	},
	"G210" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G1635 (ἑκών)",
		"def" : "unwilling",
		"kjv" : "against the will",
		"lemma" : "ἄκων",
		"translit" : "ákōn"
	},
	"G2382" : {
		"lemma" : "θώραξ",
		"translit" : "thṓrax",
		"kjv" : "breast-plate",
		"def" : "the chest (\"thorax\"), i.e. (by implication) a corslet",
		"derivation" : "of uncertain affinity"
	},
	"G737" : {
		"derivation" : "adverb from a derivative of G142 (αἴρω) (compare G740 (ἄρτος)) through the idea of suspension",
		"def" : "just now",
		"kjv" : "this day (hour), hence(-forth), here(-after), hither(-to), (even) now, (this) present",
		"translit" : "árti",
		"lemma" : "ἄρτι"
	},
	"G2308" : {
		"def" : "determination (properly, the act), i.e. option",
		"derivation" : "from G2309 (θέλω)",
		"lemma" : "θέλησις",
		"translit" : "thélēsis",
		"kjv" : "will"
	},
	"G1207" : {
		"kjv" : "second … after the first",
		"translit" : "deuteróprōtos",
		"lemma" : "δευτερόπρωτος",
		"derivation" : "from G1208 (δεύτερος) and G4413 (πρῶτος)",
		"def" : "second-first, i.e. (specially) a designation of the Sabbath immediately after the Paschal week (being the second after Passover day, and the first of the seven Sabbaths intervening before Pentecost)"
	},
	"G1804" : {
		"lemma" : "ἐξαγγέλλω",
		"translit" : "exangéllō",
		"kjv" : "shew forth",
		"def" : "to publish, i.e. celebrate",
		"derivation" : "from G1537 (ἐκ) and the base of G32 (ἄγγελος)"
	},
	"G993" : {
		"kjv" : "Boanerges",
		"translit" : "Boanergés",
		"lemma" : "Βοανεργές",
		"derivation" : "of Chaldee origin (H1123 and H7266)",
		"def" : "sons of commotion; Boanerges, an epithet of two of the apostles"
	},
	"G950" : {
		"lemma" : "βεβαιόω",
		"translit" : "bebaióō",
		"kjv" : "confirm, (e-)stablish",
		"def" : "to stabilitate (figuratively)",
		"derivation" : "from G949 (βέβαιος)"
	},
	"G2923" : {
		"derivation" : "from G2919 (κρίνω)",
		"def" : "a judge (genitive case or specially)",
		"kjv" : "judge",
		"lemma" : "κριτής",
		"translit" : "kritḗs"
	},
	"G969" : {
		"def" : "a \"beryl\"",
		"derivation" : "of uncertain derivation",
		"lemma" : "βήρυλλος",
		"translit" : "bḗryllos",
		"kjv" : "beryl"
	},
	"G3812" : {
		"kjv" : "of a child",
		"lemma" : "παιδιόθεν",
		"translit" : "paidióthen",
		"derivation" : "adverb (of source) from G3813 (παιδίον)",
		"def" : "from infancy"
	},
	"G2632" : {
		"kjv" : "condemn, damn",
		"translit" : "katakrínō",
		"lemma" : "κατακρίνω",
		"derivation" : "from G2596 (κατά) and G2919 (κρίνω)",
		"def" : "to judge against, i.e. sentence"
	},
	"G3794" : {
		"kjv" : "stronghold",
		"lemma" : "ὀχύρωμα",
		"translit" : "ochýrōma",
		"derivation" : "from a remote derivative of G2192 (ἔχω) (meaning to fortify, through the idea of holding safely)",
		"def" : "a castle (figuratively, argument)"
	},
	"G20" : {
		"def" : "exultation; specially, welcome",
		"derivation" : "from G21 (ἀγαλλιάω)",
		"lemma" : "ἀγαλλίασις",
		"translit" : "agallíasis",
		"kjv" : "gladness, (exceeding) joy"
	},
	"G1120" : {
		"def" : "to fall on the knee",
		"derivation" : "from a compound of G1119 (γόνυ) and the alternate of G4098 (πίπτω)",
		"translit" : "gonypetéō",
		"lemma" : "γονυπετέω",
		"kjv" : "bow the knee, kneel down"
	},
	"G4040" : {
		"def" : "housed around, i.e. neighboring (used elliptically as a noun)",
		"derivation" : "from G4012 (περί) and G3624 (οἶκος)",
		"lemma" : "περίοικος",
		"translit" : "períoikos",
		"kjv" : "neighbour"
	},
	"G5389" : {
		"lemma" : "φιλοτιμέομαι",
		"translit" : "philotiméomai",
		"kjv" : "labour, strive, study",
		"def" : "to be fond of honor, i.e. emulous (eager or earnest to do something)",
		"derivation" : "middle voice from a compound of G5384 (φίλος) and G5092 (τιμή)"
	},
	"G2762" : {
		"lemma" : "κεραία",
		"translit" : "keraía",
		"kjv" : "tittle",
		"def" : "something horn-like, i.e. (specially) the apex of a Hebrew letter (figuratively, the least particle)",
		"derivation" : "feminine of a presumed derivative of the base of G2768 (κέρας)"
	},
	"G2730" : {
		"translit" : "katoikéō",
		"lemma" : "κατοικέω",
		"kjv" : "dwell(-er), inhabitant(-ter)",
		"def" : "to house permanently, i.e. reside (literally or figuratively)",
		"derivation" : "from G2596 (κατά) and G3611 (οἰκέω)"
	},
	"G3661" : {
		"kjv" : "with one accord (mind)",
		"lemma" : "ὁμοθυμαδόν",
		"translit" : "homothymadón",
		"derivation" : "adverb from a compound of the base of G3674 (ὁμοῦ) and G2372 (θυμός)",
		"def" : "unanimously"
	},
	"G28" : {
		"derivation" : "of Hebrew origin (H1904)",
		"def" : "Hagar, the concubine of Abraham",
		"kjv" : "Hagar",
		"translit" : "Ágar",
		"lemma" : "Ἄγαρ"
	},
	"G1736" : {
		"derivation" : "from a compound of G1722 (ἐν) and G1218 (δῆμος)",
		"def" : "to be in one's own country, i.e. home (figuratively)",
		"kjv" : "be at home (present)",
		"translit" : "endēméō",
		"lemma" : "ἐνδημέω"
	},
	"G1791" : {
		"derivation" : "from G1788 (ἐντρέπω)",
		"def" : "confusion",
		"kjv" : "shame",
		"lemma" : "ἐντροπή",
		"translit" : "entropḗ"
	},
	"G4239" : {
		"derivation" : "apparently a primary word",
		"def" : "mild, i.e. (by implication) humble",
		"kjv" : "meek",
		"lemma" : "πραΰς",
		"translit" : "praÿs"
	},
	"G1426" : {
		"def" : "defamation",
		"derivation" : "from a compound of G1418 (δυσ-) and G5345 (φήμη)",
		"lemma" : "δυσφημία",
		"translit" : "dysphēmía",
		"kjv" : "evil report"
	},
	"G833" : {
		"kjv" : "court, (sheep-)fold, hall, palace",
		"translit" : "aulḗ",
		"lemma" : "αὐλή",
		"derivation" : "from the same as G109 (ἀήρ)",
		"def" : "a yard (as open to the wind); by implication, a mansion"
	},
	"G4997" : {
		"kjv" : "soberness, sobriety",
		"translit" : "sōphrosýnē",
		"lemma" : "σωφροσύνη",
		"derivation" : "from G4998 (σώφρων)",
		"def" : "soundness of mind, i.e. (literally) sanity or (figuratively) self-control"
	},
	"G201" : {
		"derivation" : "from G202 (ἀκροατής)",
		"def" : "an audience-room",
		"kjv" : "place of hearing",
		"lemma" : "ἀκροατήριον",
		"translit" : "akroatḗrion"
	},
	"G3908" : {
		"def" : "to place alongside, i.e. present (food, truth); by implication, to deposit (as a trust or for protection)",
		"derivation" : "from G3844 (παρά) and G5087 (τίθημι)",
		"translit" : "paratíthēmi",
		"lemma" : "παρατίθημι",
		"kjv" : "allege, commend, commit (the keeping of), put forth, set before"
	},
	"G1336" : {
		"kjv" : "+ continually, for ever",
		"translit" : "diēnekḗs",
		"lemma" : "διηνεκής",
		"derivation" : "neuter of a compound of G1223 (διά) and a derivative of an alternate of G5342 (φέρω)",
		"def" : "carried through, i.e. (adverbially with G1519 (εἰς) and G3588 (ὁ) prefixed) perpetually"
	},
	"G4436" : {
		"def" : "a Python, i.e. (by analogy, with the supposed diviner there) inspiration (soothsaying)",
		"derivation" : "from (the name of the region where Delphi, the seat of the famous oracle, was located)",
		"lemma" : "Πύθων",
		"translit" : "Pýthōn",
		"kjv" : "divination"
	},
	"G5565" : {
		"derivation" : "adverb from G5561 (χώρα)",
		"def" : "at a space, i.e. separately or apart from (often as preposition)",
		"kjv" : "beside, by itself, without",
		"translit" : "chōrís",
		"lemma" : "χωρίς"
	},
	"G3884" : {
		"kjv" : "beguile, deceive",
		"lemma" : "παραλογίζομαι",
		"translit" : "paralogízomai",
		"derivation" : "from G3844 (παρά) and G3049 (λογίζομαι)",
		"def" : "to misreckon, i.e. delude"
	},
	"G757" : {
		"derivation" : "a primary verb",
		"def" : "to be first (in political rank or power)",
		"kjv" : "reign (rule) over",
		"lemma" : "ἄρχω",
		"translit" : "árchō"
	},
	"G2844" : {
		"def" : "a sharer, i.e. associate",
		"derivation" : "from G2839 (κοινός)",
		"translit" : "koinōnós",
		"lemma" : "κοινωνός",
		"kjv" : "companion, X fellowship, partaker, partner"
	},
	"G3816" : {
		"derivation" : "perhaps from G3817 (παίω)",
		"def" : "a boy (as often beaten with impunity), or (by analogy), a girl, and (genitive case) a child; specially, a slave or servant (especially a minister to a king; and by eminence to God)",
		"kjv" : "child, maid(-en), (man) servant, son, young man",
		"lemma" : "παῖς",
		"translit" : "paîs"
	},
	"G38" : {
		"translit" : "hagiasmós",
		"lemma" : "ἁγιασμός",
		"kjv" : "holiness, sanctification",
		"def" : "properly, purification, i.e. (the state) purity; concretely (by Hebraism) a purifier",
		"derivation" : "from G37 (ἁγιάζω)"
	},
	"G3012" : {
		"kjv" : "towel",
		"lemma" : "λέντιον",
		"translit" : "léntion",
		"derivation" : "of Latin origin",
		"def" : "a \"linen\" cloth, i.e. apron"
	},
	"G2865" : {
		"lemma" : "κομίζω",
		"translit" : "komízō",
		"kjv" : "bring, receive",
		"def" : "properly, to provide for, i.e. (by implication) to carry off (as if from harm; genitive case obtain)",
		"derivation" : "from a primary (to tend, i.e. take care of)"
	},
	"G2142" : {
		"lemma" : "εὐπορία",
		"translit" : "euporía",
		"kjv" : "wealth",
		"def" : "pecuniary resources",
		"derivation" : "from the same as G2141 (εὐπορέω)"
	},
	"G3072" : {
		"derivation" : "adverb from a derivative of G3071 (Λυκαονία)",
		"def" : "Lycaonistically, i.e. in the language of the Lycaonians",
		"kjv" : "in the speech of Lycaonia",
		"lemma" : "Λυκαονιστί",
		"translit" : "Lykaonistí"
	},
	"G4816" : {
		"def" : "to collect",
		"derivation" : "from G4862 (σύν) and G3004 (λέγω) in its original sense",
		"translit" : "syllégō",
		"lemma" : "συλλέγω",
		"kjv" : "gather (together, up)"
	},
	"G815" : {
		"lemma" : "ἄτεκνος",
		"translit" : "áteknos",
		"kjv" : "childless, without children",
		"def" : "childless",
		"derivation" : "from G1 (Α) (as a negative particle) and G5043 (τέκνον)"
	},
	"G2688" : {
		"kjv" : "behaviour",
		"translit" : "katástēma",
		"lemma" : "κατάστημα",
		"derivation" : "from G2525 (καθίστημι)",
		"def" : "properly, a position or condition, i.e. (subjectively) demeanor"
	},
	"G97" : {
		"translit" : "ádolos",
		"lemma" : "ἄδολος",
		"kjv" : "sincere",
		"def" : "and G1388 (δόλος); undeceitful, i.e. (figuratively) unadulterated",
		"derivation" : "from G1 (Α) (as a negative particle)"
	},
	"G1925" : {
		"kjv" : "shew",
		"translit" : "epideíknymi",
		"lemma" : "ἐπιδείκνυμι",
		"derivation" : "from G1909 (ἐπί) and G1166 (δεικνύω)",
		"def" : "to exhibit (physically or mentally)"
	},
	"G3584" : {
		"derivation" : "from the base of G3582 (ξέστης) (through the idea of scorching)",
		"def" : "arid; by implication, shrunken, earth (as opposed to water)",
		"kjv" : "dry land, withered",
		"translit" : "xērós",
		"lemma" : "ξηρός"
	},
	"G3000" : {
		"translit" : "latreúō",
		"lemma" : "λατρεύω",
		"kjv" : "serve, do the service, worship(-per)",
		"def" : "to minister (to God), i.e. render religious homage",
		"derivation" : "from (a hired menial)"
	},
	"G3129" : {
		"def" : "to learn (in any way)",
		"derivation" : "prolongation from a primary verb, another form of which, , is used as an alternate in certain tenses",
		"translit" : "manthánō",
		"lemma" : "μανθάνω",
		"kjv" : "learn, understand"
	},
	"G3320" : {
		"derivation" : "from G3319 (μέσος) and G5109 (τοῖχος)",
		"def" : "a partition (figuratively)",
		"kjv" : "middle wall",
		"lemma" : "μεσότοιχον",
		"translit" : "mesótoichon"
	},
	"G2791" : {
		"derivation" : "probably of foreign origin",
		"def" : "Cilicia, a region of Asia Minor",
		"kjv" : "Cilicia",
		"translit" : "Kilikía",
		"lemma" : "Κιλικία"
	},
	"G3348" : {
		"derivation" : "from G3326 (μετά) and G2192 (ἔχω)",
		"def" : "to share or participate; by implication, belong to, eat (or drink)",
		"kjv" : "be partaker, pertain, take part, use",
		"lemma" : "μετέχω",
		"translit" : "metéchō"
	},
	"G5151" : {
		"def" : "three times",
		"derivation" : "adverb from G5140 (τρεῖς)",
		"translit" : "trís",
		"lemma" : "τρίς",
		"kjv" : "three times, thrice"
	},
	"G1303" : {
		"def" : "to put apart, i.e. (figuratively) dispose (by assignment, compact, or bequest)",
		"derivation" : "middle voice from G1223 (διά) and G5087 (τίθημι)",
		"lemma" : "διατίθεμαι",
		"translit" : "diatíthemai",
		"kjv" : "appoint, make, testator"
	},
	"G2648" : {
		"def" : "to learn thoroughly, i.e. (by implication) to note carefully",
		"derivation" : "from G2596 (κατά) and G3129 (μανθάνω)",
		"translit" : "katamanthánō",
		"lemma" : "καταμανθάνω",
		"kjv" : "consider"
	},
	"G1416" : {
		"translit" : "dýnō",
		"lemma" : "δύνω",
		"kjv" : "set",
		"def" : "to go \"down\"",
		"derivation" : "prolonged forms of an obsolete primary (to sink)"
	},
	"G495" : {
		"lemma" : "ἀντιπέραν",
		"translit" : "antipéran",
		"kjv" : "over against",
		"def" : "on the opposite side",
		"derivation" : "from G473 (ἀντί) and G4008 (πέραν)"
	},
	"G1712" : {
		"derivation" : "neuter from G1713 (ἔμπορος)",
		"def" : "a mart (\"emporium\")",
		"kjv" : "merchandise",
		"lemma" : "ἐμπόριον",
		"translit" : "empórion"
	},
	"G1255" : {
		"translit" : "dialaléō",
		"lemma" : "διαλαλέω",
		"kjv" : "commune, noise abroad",
		"def" : "to talk throughout a company, i.e. converse or (genitive case) publish",
		"derivation" : "from G1223 (διά) and G2980 (λαλέω)"
	},
	"G3773" : {
		"derivation" : "of Latin origin",
		"def" : "Urbanus (of the city, \"urbane\"), a Christian",
		"kjv" : "Urbanus",
		"translit" : "Ourbanós",
		"lemma" : "Οὐρβανός"
	},
	"G194" : {
		"lemma" : "ἄκρατος",
		"translit" : "ákratos",
		"kjv" : "without mixture",
		"def" : "undiluted",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G2767 (κεράννυμι)"
	},
	"G2962" : {
		"derivation" : "from (supremacy)",
		"def" : "supreme in authority, i.e. (as noun) controller; by implication, Master (as a respectful title)",
		"kjv" : "God, Lord, master, Sir",
		"lemma" : "κύριος",
		"translit" : "kýrios"
	},
	"G4563" : {
		"derivation" : "from a derivative of (to brush off",
		"def" : "akin to G4951 (σύρω)); meaning a broom; to sweep",
		"kjv" : "sweep",
		"translit" : "saróō",
		"lemma" : "σαρόω"
	},
	"G1528" : {
		"kjv" : "call in",
		"translit" : "eiskaléō",
		"lemma" : "εἰσκαλέω",
		"derivation" : "from G1519 (εἰς) and G2564 (καλέω)",
		"def" : "to invite in"
	},
	"G5048" : {
		"lemma" : "τελειόω",
		"translit" : "teleióō",
		"kjv" : "consecrate, finish, fulfil, make) perfect",
		"def" : "to complete, i.e. (literally) accomplish, or (figuratively) consummate (in character)",
		"derivation" : "from G5046 (τέλειος)"
	},
	"G3987" : {
		"kjv" : "assay",
		"translit" : "peiráō",
		"lemma" : "πειράω",
		"derivation" : "from G3984 (πεῖρα)",
		"def" : "to test (subjectively), i.e. (reflexively) to attempt"
	},
	"G4980" : {
		"def" : "to take a holiday, i.e. be at leisure for (by implication, devote oneself wholly to); figuratively, to be vacant (of a house)",
		"derivation" : "from G4981 (σχολή)",
		"translit" : "scholázō",
		"lemma" : "σχολάζω",
		"kjv" : "empty, give self"
	},
	"G1643" : {
		"translit" : "elaúnō",
		"lemma" : "ἐλαύνω",
		"kjv" : "carry, drive, row",
		"def" : "to push (as wind, oars or dæmonical power)",
		"derivation" : "a prolonged form of a primary verb (obsolete except in certain tenses as an alternative of this) of uncertain affinity"
	},
	"G3811" : {
		"derivation" : "from G3816 (παῖς)",
		"def" : "to train up a child, i.e. educate, or (by implication), discipline (by punishment)",
		"kjv" : "chasten(-ise), instruct, learn, teach",
		"lemma" : "παιδεύω",
		"translit" : "paideúō"
	},
	"G2913" : {
		"translit" : "Krḗskēs",
		"lemma" : "Κρήσκης",
		"kjv" : "Crescens",
		"def" : "growing; Cresces (i.e. Crescens), a Christian",
		"derivation" : "of Latin origin"
	},
	"G2168" : {
		"derivation" : "from G2170 (εὐχάριστος)",
		"def" : "to be grateful, i.e. (actively) to express gratitude (towards); specially, to say grace at a meal",
		"kjv" : "(give) thank(-ful, -s)",
		"translit" : "eucharistéō",
		"lemma" : "εὐχαριστέω"
	},
	"G3583" : {
		"derivation" : "from G3584 (ξηρός)",
		"def" : "to desiccate; by implication, to shrivel, to mature",
		"kjv" : "dry up, pine away, be ripe, wither (away)",
		"translit" : "xēraínō",
		"lemma" : "ξηραίνω"
	},
	"G3471" : {
		"def" : "to become insipid; figuratively, to make (passively, act) as a simpleton",
		"derivation" : "from G3474 (μωρός)",
		"lemma" : "μωραίνω",
		"translit" : "mōraínō",
		"kjv" : "become fool, make foolish, lose savour"
	},
	"G1013" : {
		"lemma" : "βούλημα",
		"translit" : "boúlēma",
		"kjv" : "purpose, will",
		"def" : "a resolve",
		"derivation" : "from G1014 (βούλομαι)"
	},
	"G1231" : {
		"translit" : "diaginṓskō",
		"lemma" : "διαγινώσκω",
		"kjv" : "(would) enquire, know the uttermost",
		"def" : "to know thoroughly, i.e. ascertain exactly",
		"derivation" : "from G1223 (διά) and G1097 (γινώσκω)"
	},
	"G2163" : {
		"kjv" : "of good report",
		"lemma" : "εὔφημος",
		"translit" : "eúphēmos",
		"derivation" : "from G2095 (εὖ) and G5345 (φήμη)",
		"def" : "well spoken of, i.e. reputable"
	},
	"G1657" : {
		"kjv" : "liberty",
		"translit" : "eleuthería",
		"lemma" : "ἐλευθερία",
		"derivation" : "from G1658 (ἐλεύθερος)",
		"def" : "freedom (legitimate or licentious, chiefly moral or ceremonial)"
	},
	"G76" : {
		"lemma" : "Ἀδάμ",
		"translit" : "Adám",
		"kjv" : "Adam",
		"def" : "Adam, the first man; typically (of Jesus) man (as his representative)",
		"derivation" : "of Hebrew origin (H121)"
	},
	"G946" : {
		"derivation" : "from G948 (βδελύσσω)",
		"def" : "a detestation, i.e. (specially) idolatry",
		"kjv" : "abomination",
		"lemma" : "βδέλυγμα",
		"translit" : "bdélygma"
	},
	"G3181" : {
		"derivation" : "from G3326 (μετά) and G3725 (ὅριον)",
		"def" : "bounded alongside, i.e. contiguous (neuter plural as noun, frontier)",
		"kjv" : "border",
		"lemma" : "μεθόριος",
		"translit" : "methórios"
	},
	"G1149" : {
		"lemma" : "Δαλματία",
		"translit" : "Dalmatía",
		"kjv" : "Dalmatia",
		"def" : "Dalmatia, a region of Europe",
		"derivation" : "probably of foreign derivation"
	},
	"G1017" : {
		"translit" : "brabeîon",
		"lemma" : "βραβεῖον",
		"kjv" : "prize",
		"def" : "an award (of arbitration), i.e. (specially) a prize in the public games",
		"derivation" : "from (an umpire of uncertain derivation)"
	},
	"G2609" : {
		"def" : "to lead down; specially, to moor a vessel",
		"derivation" : "from G2596 (κατά) and G71 (ἄγω)",
		"translit" : "katágō",
		"lemma" : "κατάγω",
		"kjv" : "bring (down, forth), (bring to) land, touch"
	},
	"G4557" : {
		"def" : "hence the \"onyx\" stone); a \"sardonyx\", i.e. the gem so called",
		"derivation" : "from the base of G4556 (σάρδιος) and (the nail of a finger",
		"lemma" : "σαρδόνυξ",
		"translit" : "sardónyx",
		"kjv" : "sardonyx"
	},
	"G4569" : {
		"def" : "Saulus (i.e. Shaul), the Jewish name of Paul",
		"derivation" : "of Hebrew origin, the same as G4549 (Σαούλ)",
		"translit" : "Saûlos",
		"lemma" : "Σαῦλος",
		"kjv" : "Saul"
	},
	"G3046" : {
		"derivation" : "of Latin origin (libra)",
		"def" : "a pound in weight",
		"kjv" : "pound",
		"lemma" : "λίτρα",
		"translit" : "lítra"
	},
	"G476" : {
		"kjv" : "adversary",
		"translit" : "antídikos",
		"lemma" : "ἀντίδικος",
		"derivation" : "from G473 (ἀντί) and G1349 (δίκη)",
		"def" : "an opponent (in a lawsuit); specially, Satan (as the arch-enemy)"
	},
	"G1752" : {
		"derivation" : "of uncertain affinity",
		"def" : "on account of",
		"kjv" : "because, for (cause, sake), (where-)fore, by reason of, that",
		"translit" : "héneka",
		"lemma" : "ἕνεκα"
	},
	"G1289" : {
		"kjv" : "scatter abroad",
		"lemma" : "διασπείρω",
		"translit" : "diaspeírō",
		"derivation" : "from G1223 (διά) and G4687 (σπείρω)",
		"def" : "to sow throughout, i.e. (figuratively) distribute in foreign lands"
	},
	"G6" : {
		"kjv" : "Abel",
		"translit" : "Ábel",
		"lemma" : "Ἄβελ",
		"derivation" : "of Hebrew origin (H1893)",
		"def" : "Abel, the son of Adam"
	},
	"G1317" : {
		"def" : "instructive (\"didactic\")",
		"derivation" : "from G1318 (διδακτός)",
		"lemma" : "διδακτικός",
		"translit" : "didaktikós",
		"kjv" : "apt to teach"
	},
	"G2502" : {
		"def" : "Josias (i.e. Joshiah), an Israelite",
		"derivation" : "of Hebrew origin (H2977)",
		"translit" : "Iōsías",
		"lemma" : "Ἰωσίας",
		"kjv" : "Josias"
	},
	"G2052" : {
		"lemma" : "ἐριθεία",
		"translit" : "eritheía",
		"kjv" : "contention(-ious), strife",
		"def" : "properly, intrigue, i.e. (by implication) faction",
		"derivation" : "perhaps as the same as G2042 (ἐρεθίζω)"
	},
	"G4713" : {
		"derivation" : "from the base of G2476 (ἵστημι) (as stationary)",
		"def" : "a jar or earthen tank",
		"kjv" : "pot",
		"lemma" : "στάμνος",
		"translit" : "stámnos"
	},
	"G3059" : {
		"def" : "slander or vituperation",
		"derivation" : "from G3060 (λοίδορος)",
		"lemma" : "λοιδορία",
		"translit" : "loidoría",
		"kjv" : "railing, reproach(-fully)"
	},
	"G5050" : {
		"derivation" : "from G5448 (φυσιόω)",
		"def" : "(the act) completion, i.e. (of prophecy) verification, or (of expiation) absolution",
		"kjv" : "perfection, performance",
		"translit" : "teleíōsis",
		"lemma" : "τελείωσις"
	},
	"G4848" : {
		"kjv" : "go with, resort",
		"translit" : "symporeúomai",
		"lemma" : "συμπορεύομαι",
		"derivation" : "from G4862 (σύν) and G4198 (πορεύομαι)",
		"def" : "to journey together; by implication, to assemble"
	},
	"G1479" : {
		"def" : "voluntary (arbitrary and unwarranted) piety, i.e. sanctimony",
		"derivation" : "from G2309 (θέλω) and G2356 (θρησκεία)",
		"lemma" : "ἐθελοθρησκεία",
		"translit" : "ethelothrēskeía",
		"kjv" : "will worship"
	},
	"G2395" : {
		"derivation" : "from G2390 (ἰάομαι)",
		"def" : "a physician",
		"kjv" : "physician",
		"translit" : "iatrós",
		"lemma" : "ἰατρός"
	},
	"G1041" : {
		"def" : "properly, a stand, i.e. (specially) an altar",
		"derivation" : "from the base of G939 (βάσις)",
		"lemma" : "βωμός",
		"translit" : "bōmós",
		"kjv" : "altar"
	},
	"G1261" : {
		"lemma" : "διαλογισμός",
		"translit" : "dialogismós",
		"kjv" : "dispute, doubtful(-ing), imagination, reasoning, thought",
		"def" : "discussion, i.e. (internal) consideration (by implication, purpose), or (external) debate",
		"derivation" : "from G1260 (διαλογίζομαι)"
	},
	"G1873" : {
		"kjv" : "hear",
		"lemma" : "ἐπακούω",
		"translit" : "epakoúō",
		"derivation" : "from G1909 (ἐπί) and G191 (ἀκούω)",
		"def" : "to hearken (favorably) to"
	},
	"G1865" : {
		"def" : "to accumulate",
		"derivation" : "from G1909 (ἐπί) and (to assemble)",
		"lemma" : "ἐπαθροίζω",
		"translit" : "epathroízō",
		"kjv" : "gather thick together"
	},
	"G516" : {
		"kjv" : "as becometh, after a godly sort, worthily(-thy)",
		"lemma" : "ἀξίως",
		"translit" : "axíōs",
		"derivation" : "adverb from G514 (ἄξιος)",
		"def" : "appropriately"
	},
	"G5346" : {
		"lemma" : "φημί",
		"translit" : "phēmí",
		"kjv" : "affirm, say",
		"def" : "to show or make known one's thoughts, i.e. speak or say",
		"derivation" : "properly, the same as the base of G5457 (φῶς) and G5316 (φαίνω)"
	},
	"G4107" : {
		"derivation" : "from G4108 (πλάνος)",
		"def" : "a rover (\"planet\"), i.e. (figuratively) an erratic teacher",
		"kjv" : "wandering",
		"translit" : "planḗtēs",
		"lemma" : "πλανήτης"
	},
	"G3929" : {
		"translit" : "páresis",
		"lemma" : "πάρεσις",
		"kjv" : "remission",
		"def" : "praetermission, i.e. toleration",
		"derivation" : "from G2935 (κτήτωρ)"
	},
	"G4396" : {
		"derivation" : "from a compound of G4253 (πρό) and G5346 (φημί)",
		"def" : "a foreteller (\"prophet\"); by analogy, an inspired speaker; by extension, a poet",
		"kjv" : "prophet",
		"lemma" : "προφήτης",
		"translit" : "prophḗtēs"
	},
	"G4643" : {
		"derivation" : "from G4642 (σκληρός)",
		"def" : "callousness, i.e. (figuratively) stubbornness",
		"kjv" : "hardness",
		"lemma" : "σκληρότης",
		"translit" : "sklērótēs"
	},
	"G5429" : {
		"derivation" : "from G5424 (φρήν)",
		"def" : "thoughtful, i.e. sagacious or discreet (implying a cautious character; while G4680 (σοφός) denotes practical skill or acumen; and G4908 (συνετός) indicates rather intelligence or mental acquirement); in a bad sense conceited (also in the comparative)",
		"kjv" : "wise(-r)",
		"translit" : "phrónimos",
		"lemma" : "φρόνιμος"
	},
	"G5619" : {
		"lemma" : "ὡσπερεί",
		"translit" : "hōspereí",
		"kjv" : "as",
		"def" : "just as if, i.e. as it were",
		"derivation" : "from G5618 (ὥσπερ) and G1487 (εἰ)"
	},
	"G5560" : {
		"kjv" : "cripple, halt, lame",
		"lemma" : "χωλός",
		"translit" : "chōlós",
		"derivation" : "apparently a primary word",
		"def" : "\"halt\", i.e. limping"
	},
	"G2663" : {
		"translit" : "katápausis",
		"lemma" : "κατάπαυσις",
		"kjv" : "rest",
		"def" : "reposing down, i.e. (by Hebraism) abode",
		"derivation" : "from G2664 (καταπαύω)"
	},
	"G4675" : {
		"derivation" : "genitive case of G4771 (σύ)",
		"def" : "of thee, thy",
		"kjv" : "X home, thee, thine (own), thou, thy",
		"lemma" : "σοῦ",
		"translit" : "soû"
	},
	"G4003" : {
		"derivation" : "from G4002 (πέντε) and G2532 (καί) and G1182 (δέκατος)",
		"def" : "five and tenth",
		"kjv" : "fifteenth",
		"lemma" : "πεντεκαιδέκατος",
		"translit" : "pentekaidékatos"
	},
	"G5027" : {
		"def" : "burial (the act)",
		"derivation" : "feminine from G2290 (θάπτω)",
		"lemma" : "ταφή",
		"translit" : "taphḗ",
		"kjv" : "X bury"
	},
	"G4038" : {
		"translit" : "périx",
		"lemma" : "πέριξ",
		"kjv" : "round about",
		"def" : "all around, i.e. (as an adjective) circumjacent",
		"derivation" : "adverb from G4012 (περί)"
	},
	"G685" : {
		"kjv" : "curse",
		"lemma" : "ἀρά",
		"translit" : "ará",
		"derivation" : "probably from G142 (αἴρω)",
		"def" : "properly, prayer (as lifted to Heaven), i.e. (by implication) imprecation"
	},
	"G296" : {
		"derivation" : "from the base of G297 (ἀμφότερος) and G3598 (ὁδός)",
		"def" : "a fork in the road",
		"kjv" : "where two ways meet",
		"lemma" : "ἄμφοδον",
		"translit" : "ámphodon"
	},
	"G4477" : {
		"kjv" : "Rachab",
		"translit" : "Rhacháb",
		"lemma" : "Ῥαχάβ",
		"derivation" : "from the same as G4460 (Ῥαάβ)",
		"def" : "Rachab, a Canaanitess"
	},
	"G149" : {
		"derivation" : "neuter of G150 (αἰσχρός)",
		"def" : "a shameful thing, i.e. indecorum",
		"kjv" : "shame",
		"translit" : "aischrón",
		"lemma" : "αἰσχρόν"
	},
	"G4777" : {
		"def" : "to suffer hardship in company with",
		"derivation" : "from G4862 (σύν) and G2553 (κακοπαθέω)",
		"lemma" : "συγκακοπαθέω",
		"translit" : "synkakopathéō",
		"kjv" : "be partaker of afflictions"
	},
	"G1726" : {
		"lemma" : "ἐναντίον",
		"translit" : "enantíon",
		"kjv" : "before, in the presence of",
		"def" : "(adverbially) in the presence (view) of",
		"derivation" : "neuter of G1727 (ἐναντίος)"
	},
	"G1065" : {
		"derivation" : "a primary particle of emphasis or qualification (often used with other particles prefixed)",
		"kjv" : "and besides, doubtless, at least, yet",
		"translit" : "gé",
		"lemma" : "γέ"
	},
	"G5133" : {
		"def" : "a money-broker or banker",
		"derivation" : "from G5132 (τράπεζα)",
		"lemma" : "τραπεζίτης",
		"translit" : "trapezítēs",
		"kjv" : "exchanger"
	},
	"G4872" : {
		"lemma" : "συναναβαίνω",
		"translit" : "synanabaínō",
		"kjv" : "come up with",
		"def" : "to ascend in company with",
		"derivation" : "from G4862 (σύν) and G305 (ἀναβαίνω)"
	},
	"G1544" : {
		"def" : "to eject (literally or figuratively)",
		"derivation" : "from G1537 (ἐκ) and G906 (βάλλω)",
		"translit" : "ekbállō",
		"lemma" : "ἐκβάλλω",
		"kjv" : "bring forth, cast (forth, out), drive (out), expel, leave, pluck (pull, take, thrust) out, put forth (out), send away (forth, out)"
	},
	"G3637" : {
		"derivation" : "from G3638 (ὀκτώ) and G2250 (ἡμέρα)",
		"def" : "an eight-day old person or act",
		"kjv" : "the eighth day",
		"translit" : "oktaḗmeros",
		"lemma" : "ὀκταήμερος"
	},
	"G984" : {
		"translit" : "bláptō",
		"lemma" : "βλάπτω",
		"kjv" : "hurt",
		"def" : "properly, to hinder, i.e. (by implication) to injure",
		"derivation" : "a primary verb"
	},
	"G1550" : {
		"kjv" : "spend",
		"lemma" : "ἐκδαπανάω",
		"translit" : "ekdapanáō",
		"derivation" : "from G1537 (ἐκ) and G1159 (δαπανάω)",
		"def" : "to expend (wholly), i.e. (figuratively) exhaust"
	},
	"G1758" : {
		"lemma" : "ἐνέχω",
		"translit" : "enéchō",
		"kjv" : "entangle with, have a quarrel against, urge",
		"def" : "to hold in or upon, i.e. ensnare; by implication, to keep a grudge",
		"derivation" : "from G1722 (ἐν) and G2192 (ἔχω)"
	},
	"G4074" : {
		"derivation" : "apparently a primary word",
		"def" : "a (piece of) rock (larger than G3037 (λίθος)); as a name, Petrus, an apostle",
		"kjv" : "Peter, rock",
		"translit" : "Pétros",
		"lemma" : "Πέτρος"
	},
	"G5496" : {
		"derivation" : "from G5497 (χειραγωγός)",
		"def" : "to be a hand-leader, i.e. to guide (a blind person)",
		"kjv" : "lead by the hand",
		"lemma" : "χειραγωγέω",
		"translit" : "cheiragōgéō"
	},
	"G2114" : {
		"derivation" : "from G2115 (εὔθυμος)",
		"def" : "to cheer up, i.e. (intransitively) be cheerful; neuter comparative (adverbially) more cheerfully",
		"kjv" : "be of good cheer (merry)",
		"lemma" : "εὐθυμέω",
		"translit" : "euthyméō"
	},
	"G1441" : {
		"translit" : "hebdomēkontákis",
		"lemma" : "ἑβδομηκοντάκις",
		"kjv" : "seventy times",
		"def" : "seventy times",
		"derivation" : "multiple adverb from G1440 (ἑβδομήκοντα)"
	},
	"G726" : {
		"def" : "to seize (in various applications)",
		"derivation" : "from a derivative of G138 (αἱρέομαι)",
		"lemma" : "ἁρπάζω",
		"translit" : "harpázō",
		"kjv" : "catch (away, up), pluck, pull, take (by force)"
	},
	"G3820" : {
		"derivation" : "from G3819 (πάλαι)",
		"def" : "antique, i.e. not recent, worn out",
		"kjv" : "old",
		"lemma" : "παλαιός",
		"translit" : "palaiós"
	},
	"G3665" : {
		"def" : "resemblance",
		"derivation" : "from G3664 (ὅμοιος)",
		"lemma" : "ὁμοιότης",
		"translit" : "homoiótēs",
		"kjv" : "like as, similitude"
	},
	"G3450" : {
		"def" : "of me",
		"derivation" : "the simpler form of G1700 (ἐμοῦ)",
		"translit" : "moû",
		"lemma" : "μοῦ",
		"kjv" : "I, me, mine (own), my"
	},
	"G3508" : {
		"derivation" : "of Hebrew origin (H5321)",
		"def" : "Nephthaleim (i.e. Naphthali), a tribe in Palestine",
		"kjv" : "Nephthalim",
		"translit" : "Nephthaleím",
		"lemma" : "Νεφθαλείμ"
	},
	"G4843" : {
		"def" : "to take by enclosing altogether, i.e. earnestly throw the arms about one",
		"derivation" : "from G4862 (σύν) and a compound of G4012 (περί) and G2983 (λαμβάνω)",
		"translit" : "symperilambánō",
		"lemma" : "συμπεριλαμβάνω",
		"kjv" : "embrace"
	},
	"G2636" : {
		"lemma" : "καταλαλία",
		"translit" : "katalalía",
		"kjv" : "backbiting, evil speaking",
		"def" : "defamation",
		"derivation" : "from G2637 (κατάλαλος)"
	},
	"G3058" : {
		"derivation" : "from G3060 (λοίδορος)",
		"def" : "to reproach, i.e. vilify",
		"kjv" : "revile",
		"translit" : "loidoréō",
		"lemma" : "λοιδορέω"
	},
	"G1767" : {
		"derivation" : "a primary number",
		"def" : "nine",
		"kjv" : "nine",
		"translit" : "ennéa",
		"lemma" : "ἐννέα"
	},
	"G2672" : {
		"def" : "to execrate; by analogy, to doom",
		"derivation" : "middle voice from G2671 (κατάρα)",
		"lemma" : "καταράομαι",
		"translit" : "kataráomai",
		"kjv" : "curse"
	},
	"G4460" : {
		"kjv" : "Rahab",
		"lemma" : "Ῥαάβ",
		"translit" : "Rhaáb",
		"derivation" : "of Hebrew origin (G7343)",
		"def" : "Raab (i.e. Rachab), a Canaanitess"
	},
	"G2457" : {
		"derivation" : "of Latin origin",
		"def" : "Julius, a centurion",
		"kjv" : "Julius",
		"lemma" : "Ἰούλιος",
		"translit" : "Ioúlios"
	},
	"G3107" : {
		"kjv" : "blessed, happy(X -ier)",
		"translit" : "makários",
		"lemma" : "μακάριος",
		"derivation" : "a prolonged form of the poetical (meaning the same)",
		"def" : "supremely blest; by extension, fortunate, well off"
	},
	"G1026" : {
		"kjv" : "(send) rain, wash",
		"lemma" : "βρέχω",
		"translit" : "bréchō",
		"derivation" : "a primary verb",
		"def" : "to moisten (especially by a shower)"
	},
	"G632" : {
		"kjv" : "give",
		"translit" : "aponémō",
		"lemma" : "ἀπονέμω",
		"derivation" : "from G575 (ἀπό) and the base of G3551 (νόμος)",
		"def" : "to apportion, i.e. bestow"
	},
	"G115" : {
		"derivation" : "from G114 (ἀθετέω)",
		"def" : "cancellation (literally or figuratively)",
		"kjv" : "disannulling, put away",
		"lemma" : "ἀθέτησις",
		"translit" : "athétēsis"
	},
	"G3852" : {
		"def" : "a mandate",
		"derivation" : "from G3853 (παραγγέλλω)",
		"translit" : "parangelía",
		"lemma" : "παραγγελία",
		"kjv" : "charge, command"
	},
	"G4504" : {
		"lemma" : "Ῥοῦφος",
		"translit" : "Rhoûphos",
		"kjv" : "Rufus",
		"def" : "red; Rufus, a Christian",
		"derivation" : "of Latin origin"
	},
	"G4803" : {
		"kjv" : "disputation(-ting), reasoning",
		"lemma" : "συζήτησις",
		"translit" : "syzḗtēsis",
		"derivation" : "from G4802 (συζητέω)",
		"def" : "mutual questioning, i.e. discussion"
	},
	"G1446" : {
		"def" : "the Hebraistic (Hebrew) or Jewish (Chaldee) language",
		"derivation" : "from G1443 (Ἐβέρ)",
		"translit" : "Hebraḯs",
		"lemma" : "Ἑβραΐς",
		"kjv" : "Hebrew"
	},
	"G3646" : {
		"def" : "a wholly-consumed sacrifice (\"holocaust\")",
		"derivation" : "from a derivative of a compound of G3650 (ὅλος) and a derivative of G2545 (καίω)",
		"lemma" : "ὁλοκαύτωμα",
		"translit" : "holokaútōma",
		"kjv" : "(whole) burnt offering"
	},
	"G2903" : {
		"derivation" : "superlative of a derivative of G2904 (κράτος)",
		"def" : "strongest, i.e. (in dignity) very honorable",
		"kjv" : "most excellent (noble)",
		"lemma" : "κράτιστος",
		"translit" : "krátistos"
	},
	"G1486" : {
		"def" : "to be used (by habit or conventionality); neuter perfect participle usage",
		"derivation" : "a primary verb",
		"lemma" : "ἔθω",
		"translit" : "éthō",
		"kjv" : "be custom (manner, wont)"
	},
	"G3631" : {
		"def" : "\"wine\" (literally or figuratively)",
		"derivation" : "a primary word (or perhaps of Hebrew origin (H3196))",
		"translit" : "oînos",
		"lemma" : "οἶνος",
		"kjv" : "wine"
	},
	"G1853" : {
		"kjv" : "X out of sleep",
		"lemma" : "ἔξυπνος",
		"translit" : "éxypnos",
		"derivation" : "from G1537 (ἐκ) and G5258 (ὕπνος)",
		"def" : "awake"
	},
	"G2526" : {
		"derivation" : "from G2596 (κατά) and G3739 (ὅς)",
		"def" : "according to which thing, i.e. precisely as, in proportion as",
		"kjv" : "according to that, (inasmuch) as",
		"lemma" : "καθό",
		"translit" : "kathó"
	},
	"G2579" : {
		"kjv" : "and (also) if (so much as), if but, at the least, though, yet",
		"lemma" : "κἄν",
		"translit" : "kán",
		"derivation" : "from G2532 (καί) and G1437 (ἐάν)",
		"def" : "and (or even) if"
	},
	"G746" : {
		"derivation" : "from G756 (ἄρχομαι)",
		"def" : "(properly abstract) a commencement, or (concretely) chief (in various applications of order, time, place, or rank)",
		"kjv" : "beginning, corner, (at the, the) first (estate), magistrate, power, principality, principle, rule",
		"lemma" : "ἀρχή",
		"translit" : "archḗ"
	},
	"G1095" : {
		"derivation" : "from G1094 (γῆρας)",
		"def" : "to be senescent",
		"kjv" : "be (wax) old",
		"lemma" : "γηράσκω",
		"translit" : "gēráskō"
	},
	"G3676" : {
		"derivation" : "adverb from the base of G3674 (ὁμοῦ)",
		"def" : "at the same time, i.e. (conjunctionally) notwithstanding, yet still",
		"kjv" : "and even, nevertheless, though but",
		"translit" : "hómōs",
		"lemma" : "ὅμως"
	},
	"G3132" : {
		"derivation" : "from a derivative of G3105 (μαίνομαι) (meaning a prophet, as supposed to rave through inspiration)",
		"def" : "to divine, i.e. utter spells (under pretense of foretelling",
		"kjv" : "by soothsaying",
		"translit" : "manteúomai",
		"lemma" : "μαντεύομαι"
	},
	"G2735" : {
		"derivation" : "from a compound of G2596 (κατά) and a derivative of G3717 (ὀρθός) (compare G1357 (διόρθωσις))",
		"def" : "something made fully upright, i.e. (figuratively) rectification (specially, good public administration)",
		"kjv" : "very worthy deed",
		"translit" : "katórthōma",
		"lemma" : "κατόρθωμα"
	},
	"G4305" : {
		"derivation" : "from G4253 (πρό) and G3309 (μεριμνάω)",
		"def" : "to care (anxiously) in advance",
		"kjv" : "take thought beforehand",
		"lemma" : "προμεριμνάω",
		"translit" : "promerimnáō"
	},
	"G169" : {
		"kjv" : "foul, unclean",
		"translit" : "akáthartos",
		"lemma" : "ἀκάθαρτος",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G2508 (καθαίρω) (meaning cleansed)",
		"def" : "impure (ceremonially, morally (lewd) or specially, (demonic))"
	},
	"G1813" : {
		"def" : "to smear out, i.e. obliterate (erase tears, figuratively, pardon sin)",
		"derivation" : "from G1537 (ἐκ) and G218 (ἀλείφω)",
		"translit" : "exaleíphō",
		"lemma" : "ἐξαλείφω",
		"kjv" : "blot out, wipe away"
	},
	"G1862" : {
		"kjv" : "promise",
		"translit" : "epángelma",
		"lemma" : "ἐπάγγελμα",
		"derivation" : "from G1861 (ἐπαγγέλλω)",
		"def" : "a self-committal (by assurance of conferring some good)"
	},
	"G944" : {
		"derivation" : "of uncertain derivation",
		"def" : "a frog",
		"kjv" : "frog",
		"lemma" : "βάτραχος",
		"translit" : "bátrachos"
	},
	"G157" : {
		"lemma" : "αἰτίαμα",
		"translit" : "aitíama",
		"kjv" : "complaint",
		"def" : "a thing charged",
		"derivation" : "from a derivative of G156 (αἰτία)"
	},
	"G790" : {
		"kjv" : "have no certain dwelling-place",
		"lemma" : "ἀστατέω",
		"translit" : "astatéō",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G2476 (ἵστημι)",
		"def" : "to be non-stationary, i.e. (figuratively) homeless"
	},
	"G616" : {
		"derivation" : "from G575 (ἀπό) and the base of G2949 (κῦμα)",
		"def" : "to breed forth, i.e. (by transference) to generate (figuratively)",
		"kjv" : "beget, produce",
		"lemma" : "ἀποκυέω",
		"translit" : "apokyéō"
	},
	"G636" : {
		"derivation" : "from G575 (ἀπό) and G4126 (πλέω)",
		"def" : "to set sail",
		"kjv" : "sail away",
		"translit" : "apopléō",
		"lemma" : "ἀποπλέω"
	},
	"G957" : {
		"kjv" : "very well",
		"lemma" : "βελτίον",
		"translit" : "beltíon",
		"derivation" : "neuter of a compound of a derivative of G906 (βάλλω) (used for the comparative of G18 (ἀγαθός))",
		"def" : "better"
	},
	"G621" : {
		"lemma" : "ἀπολείχω",
		"translit" : "apoleíchō",
		"kjv" : "lick",
		"def" : "to lick clean",
		"derivation" : "from G575 (ἀπό) and (to \"lick\")"
	},
	"G1609" : {
		"kjv" : "reject",
		"lemma" : "ἐκπτύω",
		"translit" : "ekptýō",
		"derivation" : "from G1537 (ἐκ) and G4429 (πτύω)",
		"def" : "to spit out, i.e. (figuratively) spurn"
	},
	"G2565" : {
		"kjv" : "good olive tree",
		"translit" : "kalliélaios",
		"lemma" : "καλλιέλαιος",
		"derivation" : "from the base of G2566 (καλλίον) and G1636 (ἐλαία)",
		"def" : "a cultivated olive tree, i.e. a domesticated or improved one"
	},
	"G375" : {
		"kjv" : "send (again)",
		"translit" : "anapémpō",
		"lemma" : "ἀναπέμπω",
		"derivation" : "from G303 (ἀνά) and G3992 (πέμπω)",
		"def" : "to send up or back"
	},
	"G1823" : {
		"derivation" : "from G1537 (ἐκ) and G797 (ἀστράπτω)",
		"def" : "to lighten forth, i.e. (figuratively) to be radiant (of very white garments)",
		"kjv" : "glistening",
		"translit" : "exastráptō",
		"lemma" : "ἐξαστράπτω"
	},
	"G2417" : {
		"def" : "a temple-despoiler",
		"derivation" : "from G2411 (ἱερόν) and G4813 (συλάω)",
		"translit" : "hierósylos",
		"lemma" : "ἱερόσυλος",
		"kjv" : "robber of churches"
	},
	"G1766" : {
		"derivation" : "ordinal from G1767 (ἐννέα)",
		"def" : "ninth",
		"kjv" : "ninth",
		"translit" : "énnatos",
		"lemma" : "ἔννατος"
	},
	"G1320" : {
		"def" : "an instructor (genitive case or specially)",
		"derivation" : "from G1321 (διδάσκω)",
		"lemma" : "διδάσκαλος",
		"translit" : "didáskalos",
		"kjv" : "doctor, master, teacher"
	},
	"G5443" : {
		"kjv" : "kindred, tribe",
		"lemma" : "φυλή",
		"translit" : "phylḗ",
		"derivation" : "from G5453 (φύω) (compare G5444 (φύλλον))",
		"def" : "an offshoot, i.e. race or clan"
	},
	"G2622" : {
		"lemma" : "κατακλάω",
		"translit" : "katakláō",
		"kjv" : "break",
		"def" : "to break down, i.e. divide",
		"derivation" : "from G2596 (κατά) and G2806 (κλάω)"
	},
	"G3659" : {
		"kjv" : "eye",
		"translit" : "ómma",
		"lemma" : "ὄμμα",
		"derivation" : "from G3700 (ὀπτάνομαι)",
		"def" : "a sight, i.e. (by implication) the eye"
	},
	"G1220" : {
		"kjv" : "pence, penny(-worth)",
		"lemma" : "δηνάριον",
		"translit" : "dēnárion",
		"derivation" : "of Latin origin",
		"def" : "a denarius (or ten asses)"
	},
	"G326" : {
		"kjv" : "(be a-)live again, revive",
		"translit" : "anazáō",
		"lemma" : "ἀναζάω",
		"derivation" : "from G303 (ἀνά) and G2198 (ζάω)",
		"def" : "to recover life (literally or figuratively)"
	},
	"G5069" : {
		"derivation" : "neuter of a presumed derivative of (a tetrad",
		"def" : "from G5064 (τέσσαρες)); a quaternion or squad (picket) of four Roman soldiers",
		"kjv" : "quaternion",
		"lemma" : "τετράδιον",
		"translit" : "tetrádion"
	},
	"G1892" : {
		"kjv" : "raise, stir up",
		"lemma" : "ἐπεγείρω",
		"translit" : "epegeírō",
		"derivation" : "from G1909 (ἐπί) and G1453 (ἐγείρω)",
		"def" : "to rouse upon, i.e. (figuratively) to excite against"
	},
	"G3817" : {
		"def" : "to hit (as if by a single blow and less violently than G5180 (τύπτω)); specially, to sting (as a scorpion)",
		"derivation" : "a primary verb",
		"lemma" : "παίω",
		"translit" : "paíō",
		"kjv" : "smite, strike"
	},
	"G1757" : {
		"def" : "to confer a benefit on",
		"derivation" : "from G1722 (ἐν) and G2127 (εὐλογέω)",
		"lemma" : "ἐνευλογέω",
		"translit" : "eneulogéō",
		"kjv" : "bless"
	},
	"G3914" : {
		"def" : "to winter near, i.e. stay with over the rainy season",
		"derivation" : "from G3844 (παρά) and G5492 (χειμάζω)",
		"lemma" : "παραχειμάζω",
		"translit" : "paracheimázō",
		"kjv" : "winter"
	},
	"G653" : {
		"lemma" : "ἀποστοματίζω",
		"translit" : "apostomatízō",
		"kjv" : "provoke to speak",
		"def" : "to speak off-hand (properly, dictate), i.e. to catechize (in an invidious manner)",
		"derivation" : "from G575 (ἀπό) and a (presumed) derivative of G4750 (στόμα)"
	},
	"G285" : {
		"derivation" : "perhaps from G260 (ἅμα)",
		"def" : "sand (as heaped on the beach)",
		"kjv" : "sand",
		"lemma" : "ἄμμος",
		"translit" : "ámmos"
	},
	"G1584" : {
		"derivation" : "from G1537 (ἐκ) and G2989 (λάμπω)",
		"def" : "to be resplendent",
		"kjv" : "shine forth",
		"lemma" : "ἐκλάμπω",
		"translit" : "eklámpō"
	},
	"G4" : {
		"def" : "weightless, i.e. (figuratively) not burdensome",
		"derivation" : "from G1 (Α) (as a negative particle) and G922 (βάρος)",
		"lemma" : "ἀβαρής",
		"translit" : "abarḗs",
		"kjv" : "from being burdensome"
	},
	"G4814" : {
		"kjv" : "commune (confer, talk) with, speak among",
		"translit" : "syllaléō",
		"lemma" : "συλλαλέω",
		"derivation" : "from G4862 (σύν) and G2980 (λαλέω)",
		"def" : "to talk together, i.e. converse"
	},
	"G4277" : {
		"def" : "to say already, to predict",
		"derivation" : "from G4253 (πρό) and G2036 (ἔπω)",
		"translit" : "proépō",
		"lemma" : "προέπω",
		"kjv" : "forewarn, say (speak, tell) before"
	},
	"G2504" : {
		"def" : "so also the dative case , and accusative case and (or also, even, etc.) I, (to) me",
		"derivation" : "from G2532 (καί) and G1473 (ἐγώ)",
		"translit" : "kagṓ",
		"lemma" : "κἀγώ",
		"kjv" : "(and, even, even so, so) I (also, in like wise), both me, me also"
	},
	"G1637" : {
		"lemma" : "ἔλαιον",
		"translit" : "élaion",
		"kjv" : "oil",
		"def" : "olive oil",
		"derivation" : "neuter of the same as G1636 (ἐλαία)"
	},
	"G3039" : {
		"derivation" : "from , the equivalent of (a winnowing fan or basket)",
		"def" : "to winnow, i.e. (by analogy), to triturate",
		"kjv" : "grind to powder",
		"lemma" : "λικμάω",
		"translit" : "likmáō"
	},
	"G4030" : {
		"def" : "encirclement of the head, i.e. a helmet",
		"derivation" : "feminine of a compound of G4012 (περί) and G2776 (κεφαλή)",
		"lemma" : "περικεφαλαία",
		"translit" : "perikephalaía",
		"kjv" : "helmet"
	},
	"G1665" : {
		"translit" : "Elisábet",
		"lemma" : "Ἐλισάβετ",
		"kjv" : "Elisabeth",
		"def" : "Elisabet, an Israelitess",
		"derivation" : "of Hebrew origin (H472)"
	},
	"G1699" : {
		"kjv" : "of me, mine (own), my",
		"lemma" : "ἐμός",
		"translit" : "emós",
		"derivation" : "from the oblique cases of G1473 (ἐγώ) (G1698 (ἐμοί), G1700 (ἐμοῦ), G1691 (ἐμέ))",
		"def" : "my"
	},
	"G3880" : {
		"derivation" : "from G3844 (παρά) and G2983 (λαμβάνω)",
		"def" : "to receive near, i.e. associate with oneself (in any familiar or intimate act or relation); by analogy, to assume an office; figuratively, to learn",
		"kjv" : "receive, take (unto, with)",
		"lemma" : "παραλαμβάνω",
		"translit" : "paralambánō"
	},
	"G5397" : {
		"lemma" : "φλύαρος",
		"translit" : "phlýaros",
		"kjv" : "tattler",
		"def" : "a garrulous person, i.e. prater",
		"derivation" : "from (to bubble)"
	},
	"G102" : {
		"kjv" : "could not do, impossible, impotent, not possible, weak",
		"translit" : "adýnatos",
		"lemma" : "ἀδύνατος",
		"derivation" : "from G1 (Α) (as a negative particle) and G1415 (δυνατός)",
		"def" : "unable, i.e. weak (literally or figuratively); passively, impossible"
	},
	"G62" : {
		"lemma" : "ἀγράμματος",
		"translit" : "agrámmatos",
		"kjv" : "unlearned",
		"def" : "unlettered, i.e. illiterate",
		"derivation" : "from G1 (Α) (as negative particle) and G1121 (γράμμα)"
	},
	"G117" : {
		"lemma" : "Ἀθηναῖος",
		"translit" : "Athēnaîos",
		"kjv" : "Athenian",
		"def" : "an Athenæan or inhabitant of Athenæ",
		"derivation" : "from G116 (Ἀθῆναι)"
	},
	"G763" : {
		"derivation" : "from G765 (ἀσεβής)",
		"def" : "impiety, i.e. (by implication) wickedness",
		"kjv" : "ungodly(-liness)",
		"translit" : "asébeia",
		"lemma" : "ἀσέβεια"
	},
	"G1880" : {
		"translit" : "epanérchomai",
		"lemma" : "ἐπανέρχομαι",
		"kjv" : "come again, return",
		"def" : "to come up on, i.e. return",
		"derivation" : "from G1909 (ἐπί) and G424 (ἀνέρχομαι)"
	},
	"G129" : {
		"derivation" : "of uncertain derivation",
		"def" : "blood, literally (of men or animals), figuratively (the juice of grapes) or specially (the atoning blood of Christ); by implication, bloodshed, also kindred",
		"kjv" : "blood",
		"translit" : "haîma",
		"lemma" : "αἷμα"
	},
	"G5055" : {
		"translit" : "teléō",
		"lemma" : "τελέω",
		"kjv" : "accomplish, make an end, expire, fill up, finish, go over, pay, perform",
		"def" : "to end, i.e. complete, execute, conclude, discharge (a debt)",
		"derivation" : "from G5056 (τέλος)"
	},
	"G3397" : {
		"kjv" : "a (little) (while)",
		"translit" : "mikrón",
		"lemma" : "μικρόν",
		"derivation" : "masculine or neuter singular of G3398 (μικρός) (as noun)",
		"def" : "a small space of time or degree"
	},
	"G98" : {
		"derivation" : "from (a place in Asia Minor)",
		"def" : "Adramyttene or belonging to Adramyttium",
		"kjv" : "of Adramyttium",
		"translit" : "Adramyttēnós",
		"lemma" : "Ἀδραμυττηνός"
	},
	"G1186" : {
		"derivation" : "probably from (an oak)",
		"def" : "a tree",
		"kjv" : "tree",
		"translit" : "déndron",
		"lemma" : "δένδρον"
	},
	"G3043" : {
		"translit" : "línon",
		"lemma" : "λίνον",
		"kjv" : "linen",
		"def" : "flax, i.e. (by implication) \"linen\"",
		"derivation" : "probably a primary word"
	},
	"G3809" : {
		"kjv" : "chastening, chastisement, instruction, nurture",
		"translit" : "paideía",
		"lemma" : "παιδεία",
		"derivation" : "from G3811 (παιδεύω)",
		"def" : "tutorage, i.e. education or training; by implication, disciplinary correction"
	},
	"G3700" : {
		"derivation" : "a (middle voice) prolonged form of the primary (middle voice) ; which is used for it in certain tenses; and both as alternate of G3708 (ὁράω)",
		"def" : "to gaze (i.e. with wide-open eyes, as at something remarkable; and thus differing from G991 (βλέπω), which denotes simply voluntary observation; and from G1492 (εἴδω), which expresses merely mechanical, passive or casual vision; while G2300 (θεάομαι), and still more emphatically its intensive G2334 (θεωρέω), signifies an earnest but more continued inspection; and G4648 (σκοπέω) a watching from a distance)",
		"kjv" : "appear, look, see, shew self",
		"translit" : "optánomai",
		"lemma" : "ὀπτάνομαι"
	},
	"G85" : {
		"kjv" : "be full of heaviness, be very heavy",
		"translit" : "adēmonéō",
		"lemma" : "ἀδημονέω",
		"derivation" : "from a derivative of (to be sated to loathing)",
		"def" : "to be in distress (of mind)"
	},
	"G4516" : {
		"def" : "strength; Roma, the capital of Italy",
		"derivation" : "from the base of G4517 (ῥώννυμι)",
		"lemma" : "Ῥώμη",
		"translit" : "Rhṓmē",
		"kjv" : "Rome"
	},
	"G4711" : {
		"derivation" : "from G4687 (σπείρω) (as woven)",
		"def" : "a hamper or lunch-receptacle",
		"kjv" : "basket",
		"lemma" : "σπυρίς",
		"translit" : "spyrís"
	},
	"G2956" : {
		"def" : "i.e. Cyrenæan, i.e. inhabitant of Cyrene",
		"derivation" : "from G2957 (Κυρήνη)",
		"translit" : "Kyrēnaîos",
		"lemma" : "Κυρηναῖος",
		"kjv" : "of Cyrene, Cyrenian"
	},
	"G3644" : {
		"kjv" : "destroyer",
		"lemma" : "ὀλοθρευτής",
		"translit" : "olothreutḗs",
		"derivation" : "from G3645 (ὀλοθρεύω)",
		"def" : "a ruiner, i.e. (specially), a venomous serpent"
	},
	"G1176" : {
		"translit" : "déka",
		"lemma" : "δέκα",
		"kjv" : "(eight-)een, ten",
		"def" : "ten",
		"derivation" : "a primary number"
	},
	"G1595" : {
		"translit" : "hekoúsion",
		"lemma" : "ἑκούσιον",
		"kjv" : "willingly",
		"def" : "voluntariness",
		"derivation" : "neuter of a derivative from G1635 (ἑκών)"
	},
	"G2328" : {
		"kjv" : "(be) warm(-ed, self)",
		"translit" : "thermaínō",
		"lemma" : "θερμαίνω",
		"derivation" : "from G2329 (θέρμη)",
		"def" : "to heat (oneself)"
	},
	"G5509" : {
		"translit" : "chitṓn",
		"lemma" : "χιτών",
		"kjv" : "clothes, coat, garment",
		"def" : "a tunic or shirt",
		"derivation" : "of foreign origin (H3801)"
	},
	"G4719" : {
		"kjv" : "ear (of corn)",
		"translit" : "stáchys",
		"lemma" : "στάχυς",
		"derivation" : "from the base of G2476 (ἵστημι)",
		"def" : "a head of grain (as standing out from the stalk)"
	},
	"G345" : {
		"derivation" : "from G303 (ἀνά) and G2749 (κεῖμαι)",
		"def" : "to recline (as a corpse or at a meal)",
		"kjv" : "guest, lean, lie, sit (down, at meat), at the table",
		"lemma" : "ἀνακεῖμαι",
		"translit" : "anakeîmai"
	},
	"G4169" : {
		"lemma" : "ποῖος",
		"translit" : "poîos",
		"kjv" : "what (manner of), which",
		"def" : "individualizing interrogative (of character) what sort of, or (of number) which one",
		"derivation" : "from the base of G4226 (ποῦ) and G3634 (οἷος)"
	},
	"G2896" : {
		"def" : "properly, to \"croak\" (as a raven) or scream, i.e. (genitive case) to call aloud (shriek, exclaim, intreat)",
		"derivation" : "a primary verb",
		"translit" : "krázō",
		"lemma" : "κράζω",
		"kjv" : "cry (out)"
	},
	"G669" : {
		"derivation" : "from G575 (ἀπό) and G5350 (φθέγγομαι)",
		"def" : "to enunciate plainly, i.e. declare",
		"kjv" : "say, speak forth, utterance",
		"lemma" : "ἀποφθέγγομαι",
		"translit" : "apophthéngomai"
	},
	"G2771" : {
		"lemma" : "κέρδος",
		"translit" : "kérdos",
		"kjv" : "gain, lucre",
		"def" : "gain (pecuniary or genitive case)",
		"derivation" : "of uncertain affinity"
	},
	"G1946" : {
		"def" : "an Epicurean or follower of Epicurus",
		"derivation" : "from (compare G1947 (ἐπικουρία)) (a noted philosopher)",
		"translit" : "Epikoúreios",
		"lemma" : "Ἐπικούρειος",
		"kjv" : "Epicurean"
	},
	"G3966" : {
		"derivation" : "from G3965 (πατριά) and G757 (ἄρχω)",
		"def" : "a progenitor (\"patriarch\")",
		"kjv" : "patriarch",
		"lemma" : "πατριάρχης",
		"translit" : "patriárchēs"
	},
	"G150" : {
		"kjv" : "filthy",
		"lemma" : "αἰσχρός",
		"translit" : "aischrós",
		"derivation" : "from the same as G153 (αἰσχύνομαι)",
		"def" : "shameful, i.e. base (specially, venal)"
	},
	"G422" : {
		"kjv" : "--that needeth not to be ashamed",
		"translit" : "anepaíschyntos",
		"lemma" : "ἀνεπαίσχυντος",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of a compound of G1909 (ἐπί) and G153 (αἰσχύνομαι)",
		"def" : "not ashamed, i.e. irreprehensible: "
	},
	"G1961" : {
		"derivation" : "from G1909 (ἐπί) and G3306 (μένω)",
		"def" : "to stay over, i.e. remain (figuratively, persevere)",
		"kjv" : "abide (in), continue (in), tarry",
		"translit" : "epiménō",
		"lemma" : "ἐπιμένω"
	},
	"G2368" : {
		"def" : "an aroma, i.e. fragrant powder burnt in religious service; by implication, the burning itself",
		"derivation" : "from G2370 (θυμιάω)",
		"translit" : "thymíama",
		"lemma" : "θυμίαμα",
		"kjv" : "incense, odour"
	},
	"G1969" : {
		"kjv" : "rebuke",
		"translit" : "epiplḗssō",
		"lemma" : "ἐπιπλήσσω",
		"derivation" : "from G1909 (ἐπί) and G4141 (πλήσσω)",
		"def" : "to chastise, i.e. (with words) to upbraid"
	},
	"G3405" : {
		"def" : "requital (good or bad)",
		"derivation" : "from G3406 (μισθαποδότης)",
		"lemma" : "μισθαποδοσία",
		"translit" : "misthapodosía",
		"kjv" : "recompence of reward"
	},
	"G780" : {
		"lemma" : "ἀσμένως",
		"translit" : "asménōs",
		"kjv" : "gladly",
		"def" : "with pleasure",
		"derivation" : "adverb from a derivative of the base of G2237 (ἡδονή)"
	},
	"G4479" : {
		"derivation" : "of Hebrew origin (H7259)",
		"def" : "Rebecca (i.e. Ribkah), the wife of Isaac",
		"kjv" : "Rebecca",
		"translit" : "Rhebékka",
		"lemma" : "Ῥεβέκκα"
	},
	"G4225" : {
		"kjv" : "about, a certain place",
		"translit" : "poú",
		"lemma" : "πού",
		"derivation" : "genitive case of an indefinite pronoun (some) otherwise obsolete (compare G4214 (πόσος))",
		"def" : "as adverb of place, somewhere, i.e. nearly"
	},
	"G59" : {
		"def" : "properly, to go to market, i.e. (by implication) to purchase; specially, to redeem",
		"derivation" : "from G58 (ἀγορά)",
		"translit" : "agorázō",
		"lemma" : "ἀγοράζω",
		"kjv" : "buy, redeem"
	},
	"G2756" : {
		"kjv" : "empty, (in) vain",
		"lemma" : "κενός",
		"translit" : "kenós",
		"derivation" : "apparently a primary word",
		"def" : "empty (literally or figuratively)"
	},
	"G1257" : {
		"def" : "to leave off in the middle, i.e. intermit",
		"derivation" : "from G1223 (διά) and G3007 (λείπω)",
		"translit" : "dialeípō",
		"lemma" : "διαλείπω",
		"kjv" : "cease"
	},
	"G3097" : {
		"kjv" : "sorcerer, wise man",
		"translit" : "mágos",
		"lemma" : "μάγος",
		"derivation" : "of foreign origin (H7248)",
		"def" : "a Magian, i.e. Oriental scientist; by implication, a magician"
	},
	"G1410" : {
		"def" : "to be able or possible",
		"derivation" : "of uncertain affinity",
		"lemma" : "δύναμαι",
		"translit" : "dýnamai",
		"kjv" : "be able, can (do, + -not), could, may, might, be possible, be of power"
	},
	"G1419" : {
		"translit" : "dysbástaktos",
		"lemma" : "δυσβάστακτος",
		"kjv" : "grievous to be borne",
		"def" : "oppressive",
		"derivation" : "from G1418 (δυσ-) and a derivative of G941 (βαστάζω)"
	},
	"G391" : {
		"def" : "behavior",
		"derivation" : "from G390 (ἀναστρέφω)",
		"lemma" : "ἀναστροφή",
		"translit" : "anastrophḗ",
		"kjv" : "conversation"
	},
	"G2228" : {
		"kjv" : "and, but (either), (n-)either, except it be, (n-)or (else), rather, save, than, that, what, yea",
		"translit" : "ḗ",
		"lemma" : "ἤ",
		"derivation" : "a primary particle of distinction between two connected terms",
		"def" : "disjunctive, or; comparative, than"
	},
	"G3041" : {
		"def" : "a pond (large or small)",
		"derivation" : "probably from G3040 (λιμήν) (through the idea of nearness of shore)",
		"translit" : "límnē",
		"lemma" : "λίμνη",
		"kjv" : "lake"
	},
	"G4255" : {
		"kjv" : "purpose",
		"translit" : "proairéomai",
		"lemma" : "προαιρέομαι",
		"derivation" : "from G4253 (πρό) and G138 (αἱρέομαι)",
		"def" : "to choose for oneself before another thing (prefer), i.e. (by implication) to propose (intend)"
	},
	"G5277" : {
		"derivation" : "a prolonged form for G5275 (ὑπολείπω)",
		"def" : "to leave behind, i.e. bequeath",
		"kjv" : "leave",
		"translit" : "hypolimpánō",
		"lemma" : "ὑπολιμπάνω"
	},
	"G5198" : {
		"def" : "to have sound health, i.e. be well (in body); figuratively, to be uncorrupt (true in doctrine)",
		"derivation" : "from G5199 (ὑγιής)",
		"translit" : "hygiaínō",
		"lemma" : "ὑγιαίνω",
		"kjv" : "be in health, (be safe and) sound, (be) whole(-some)"
	},
	"G2418" : {
		"kjv" : "minister",
		"lemma" : "ἱερουργέω",
		"translit" : "hierourgéō",
		"derivation" : "from a compound of G2411 (ἱερόν) and the base of G2041 (ἔργον)",
		"def" : "to be a temple-worker, i.e. officiate as a priest (figuratively)"
	},
	"G1304" : {
		"derivation" : "from G1223 (διά) and the base of G5147 (τρίβος)",
		"def" : "to wear through (time), i.e. remain",
		"kjv" : "abide, be, continue, tarry",
		"translit" : "diatríbō",
		"lemma" : "διατρίβω"
	},
	"G3799" : {
		"lemma" : "ὄψις",
		"translit" : "ópsis",
		"kjv" : "appearance, countenance, face",
		"def" : "properly, sight (the act), i.e. (by implication) the visage, an external show",
		"derivation" : "from G3700 (ὀπτάνομαι)"
	},
	"G549" : {
		"derivation" : "from G575 (ἀπό) and (to go)",
		"def" : "to go away",
		"kjv" : "go",
		"lemma" : "ἄπειμι",
		"translit" : "ápeimi"
	},
	"G637" : {
		"derivation" : "from G575 (ἀπό) and G4150 (πλύνω)",
		"def" : "to rinse off",
		"kjv" : "wash",
		"lemma" : "ἀποπλύνω",
		"translit" : "apoplýnō"
	},
	"G2079" : {
		"kjv" : "point of death",
		"translit" : "eschátōs",
		"lemma" : "ἐσχάτως",
		"derivation" : "adverb from G2078 (ἔσχατος)",
		"def" : "finally, i.e. (with G2192 (ἔχω)) at the extremity of life"
	},
	"G1299" : {
		"lemma" : "διατάσσω",
		"translit" : "diatássō",
		"kjv" : "appoint, command, give, (set in) order, ordain",
		"def" : "to arrange thoroughly, i.e. (specially) institute, prescribe, etc.",
		"derivation" : "from G1223 (διά) and G5021 (τάσσω)"
	},
	"G1178" : {
		"translit" : "dekapénte",
		"lemma" : "δεκαπέντε",
		"kjv" : "fifteen",
		"def" : "ten and five, i.e. fifteen",
		"derivation" : "from G1176 (δέκα) and G4002 (πέντε)"
	},
	"G4140" : {
		"lemma" : "πλησμονή",
		"translit" : "plēsmonḗ",
		"kjv" : "satisfying",
		"def" : "a filling up, i.e. (figuratively) gratification",
		"derivation" : "from a presumed derivative of G4130 (πλήθω)"
	},
	"G2838" : {
		"def" : "sleeping, i.e. (by implication) repose",
		"derivation" : "from G2837 (κοιμάω)",
		"translit" : "koímēsis",
		"lemma" : "κοίμησις",
		"kjv" : "taking of rest"
	},
	"G1832" : {
		"kjv" : "be lawful, let, X may(-est)",
		"lemma" : "ἔξεστι",
		"translit" : "éxesti",
		"derivation" : "third person singular present indicative of a compound of G1537 (ἐκ) and G1510 (εἰμί)",
		"def" : "so also neuter present participle of the same (with or without some form of G1510 (εἰμί) expressed); impersonally, it is right (through the figurative idea of being out in public)"
	},
	"G3916" : {
		"def" : "at the thing itself, i.e. instantly",
		"derivation" : "from G3844 (παρά) and G5536 (χρῆμα) (in its original sense)",
		"translit" : "parachrēma",
		"lemma" : "παραχρῆμα",
		"kjv" : "forthwith, immediately, presently, straightway, soon"
	},
	"G5236" : {
		"def" : "a throwing beyond others, i.e. (figuratively) supereminence; adverbially (with G1519 (εἰς) or G2596 (κατά)) pre- eminently",
		"derivation" : "from G5235 (ὑπερβάλλω)",
		"translit" : "hyperbolḗ",
		"lemma" : "ὑπερβολή",
		"kjv" : "abundance, (far more) exceeding, excellency, more excellent, beyond (out of) measure"
	},
	"G5516" : {
		"kjv" : "six hundred threescore and six",
		"translit" : "chx stigma",
		"lemma" : "χξϛ",
		"derivation" : "the 22nd, 14th and an obsolete letter (G4742 (στίγμα) as a cross) of the Greek alphabet (intermediate between the 5th and 6th), used as numbers; denoting respectively 600, 60 and 6",
		"def" : "666 as a numeral"
	},
	"G3684" : {
		"kjv" : "millstone",
		"lemma" : "ὀνικός",
		"translit" : "onikós",
		"derivation" : "from G3688 (ὄνος)",
		"def" : "belonging to a ass, i.e. large (so as to be turned by a ass)"
	},
	"G1570" : {
		"translit" : "ékthetos",
		"lemma" : "ἔκθετος",
		"kjv" : "cast out",
		"def" : "put out, i.e. exposed to perish",
		"derivation" : "from G1537 (ἐκ) and a derivative of G5087 (τίθημι)"
	},
	"G1638" : {
		"translit" : "elaiṓn",
		"lemma" : "ἐλαιών",
		"kjv" : "Olivet",
		"def" : "an olive-orchard, i.e. (specially) the Mount of Olives",
		"derivation" : "from G1636 (ἐλαία)"
	},
	"G5114" : {
		"def" : "more comprehensive or decisive than G2875 (κόπτω), as if by a single stroke; whereas that implies repeated blows, like hacking); more keen",
		"derivation" : "comparative of a derivative of the primary (to cut",
		"translit" : "tomṓteros",
		"lemma" : "τομώτερος",
		"kjv" : "sharper"
	},
	"G879" : {
		"lemma" : "ἀφυπνόω",
		"translit" : "aphypnóō",
		"kjv" : "fall asleep",
		"def" : "properly, to become awake, i.e. (by implication) to drop (off) in slumber",
		"derivation" : "from a compound of G575 (ἀπό) and G5258 (ὕπνος)"
	},
	"G5013" : {
		"def" : "to depress; figuratively, to humiliate (in condition or heart)",
		"derivation" : "from G5011 (ταπεινός)",
		"lemma" : "ταπεινόω",
		"translit" : "tapeinóō",
		"kjv" : "abase, bring low, humble (self)"
	},
	"G1656" : {
		"kjv" : "(+ tender) mercy",
		"translit" : "éleos",
		"lemma" : "ἔλεος",
		"derivation" : "of uncertain affinity",
		"def" : "compassion (human or divine, especially active)"
	},
	"G123" : {
		"kjv" : "shore",
		"translit" : "aigialós",
		"lemma" : "αἰγιαλός",
		"derivation" : "from (to rush) and G251 (ἅλς) (in the sense of the sea",
		"def" : "a beach (on which the waves dash)"
	},
	"G2571" : {
		"kjv" : "vail",
		"translit" : "kályma",
		"lemma" : "κάλυμα",
		"derivation" : "from G2572 (καλύπτω)",
		"def" : "a cover, i.e. veil"
	},
	"G2786" : {
		"def" : "the Rock; Cephas (i.e. Kepha), a surname of Peter",
		"derivation" : "of Chaldee origin (compare H3710)",
		"translit" : "Kēphâs",
		"lemma" : "Κηφᾶς",
		"kjv" : "Cephas"
	},
	"G2122" : {
		"kjv" : "conveniently, in season",
		"translit" : "eukaírōs",
		"lemma" : "εὐκαίρως",
		"derivation" : "adverb from G2121 (εὔκαιρος)",
		"def" : "opportunely"
	},
	"G4807" : {
		"derivation" : "of Hebrew origin (H8256) in imitation of G4809 (συκομωραία)",
		"def" : "a sycamore-fig tree",
		"kjv" : "sycamine tree",
		"translit" : "sykáminos",
		"lemma" : "συκάμινος"
	},
	"G4375" : {
		"kjv" : "lovely",
		"lemma" : "προσφιλής",
		"translit" : "prosphilḗs",
		"derivation" : "from a presumed compound of G4314 (πρός) and G5368 (φιλέω)",
		"def" : "friendly towards, i.e. acceptable"
	},
	"G1985" : {
		"derivation" : "from G1909 (ἐπί) and G4649 (σκοπός) (in the sense of G1983 (ἐπισκοπέω))",
		"def" : "a superintendent, i.e. Christian officer in genitive case charge of a (or the) church (literally or figuratively)",
		"kjv" : "bishop, overseer",
		"translit" : "epískopos",
		"lemma" : "ἐπίσκοπος"
	},
	"G466" : {
		"derivation" : "from G473 (ἀντί) and G378 (ἀναπληρόω)",
		"def" : "to supplement",
		"kjv" : "fill up",
		"lemma" : "ἀνταναπληρόω",
		"translit" : "antanaplēróō"
	},
	"G3591" : {
		"def" : "a mass (as bending or bulging by its load), i.e. burden (hindrance)",
		"derivation" : "probably from the same as G43 (ἀγκάλη)",
		"translit" : "ónkos",
		"lemma" : "ὄγκος",
		"kjv" : "weight"
	},
	"G311" : {
		"derivation" : "from G306 (ἀναβάλλομαι)",
		"def" : "a putting off",
		"kjv" : "delay",
		"translit" : "anabolḗ",
		"lemma" : "ἀναβολή"
	},
	"G1998" : {
		"lemma" : "ἐπισυντρέχω",
		"translit" : "episyntréchō",
		"kjv" : "come running together",
		"def" : "to hasten together upon one place (or a particular occasion)",
		"derivation" : "from G1909 (ἐπί) and G4936 (συντρέχω)"
	},
	"G1807" : {
		"kjv" : "deliver, pluck out, rescue",
		"translit" : "exairéō",
		"lemma" : "ἐξαιρέω",
		"derivation" : "from G1537 (ἐκ) and G138 (αἱρέομαι)",
		"def" : "actively, to tear out; middle voice, to select; figuratively, to release"
	},
	"G1789" : {
		"kjv" : "nourish up in",
		"lemma" : "ἐντρέφω",
		"translit" : "entréphō",
		"derivation" : "from G1722 (ἐν) and G5142 (τρέφω)",
		"def" : "(figuratively) to educate"
	},
	"G355" : {
		"lemma" : "ἀναλίσκω",
		"translit" : "analískō",
		"kjv" : "consume",
		"def" : "properly, to use up, i.e. destroy",
		"derivation" : "from G303 (ἀνά) and a form of the alternate of G138 (αἱρέομαι)"
	},
	"G1414" : {
		"translit" : "dynatéō",
		"lemma" : "δυνατέω",
		"kjv" : "be mighty",
		"def" : "to be efficient (figuratively)",
		"derivation" : "from G1415 (δυνατός)"
	},
	"G5141" : {
		"derivation" : "strengthened from a primary (to \"dread\", \"terrify\")",
		"def" : "to \"tremble\" or fear",
		"kjv" : "be afraid, trembling",
		"lemma" : "τρέμω",
		"translit" : "trémō"
	},
	"G3384" : {
		"derivation" : "apparently a primary word",
		"def" : "a \"mother\" (literally or figuratively, immediate or remote)",
		"kjv" : "mother",
		"lemma" : "μήτηρ",
		"translit" : "mḗtēr"
	},
	"G5419" : {
		"kjv" : "declare",
		"translit" : "phrázō",
		"lemma" : "φράζω",
		"derivation" : "probably akin to G5420 (φράσσω) through the idea of defining",
		"def" : "to indicate (by word or act), i.e. (specially), to expound"
	},
	"G3390" : {
		"kjv" : "chiefest city",
		"lemma" : "μητρόπολις",
		"translit" : "mētrópolis",
		"derivation" : "from G3384 (μήτηρ) and G4172 (πόλις)",
		"def" : "a mother city, i.e. \"metropolis\""
	},
	"G5195" : {
		"translit" : "hybrízō",
		"lemma" : "ὑβρίζω",
		"kjv" : "use despitefully, reproach, entreat shamefully (spitefully)",
		"def" : "to exercise violence, i.e. abuse",
		"derivation" : "from G5196 (ὕβρις)"
	},
	"G5247" : {
		"def" : "prominence, i.e. (figuratively) superiority (in rank or character)",
		"derivation" : "from G5242 (ὑπερέχω)",
		"translit" : "hyperochḗ",
		"lemma" : "ὑπεροχή",
		"kjv" : "authority, excellency"
	},
	"G145" : {
		"def" : "properly, an organ of perception, i.e. (figuratively) judgment",
		"derivation" : "from a derivative of G143 (αἰσθάνομαι)",
		"lemma" : "αἰσθητήριον",
		"translit" : "aisthētḗrion",
		"kjv" : "senses"
	},
	"G5558" : {
		"lemma" : "χρυσόω",
		"translit" : "chrysóō",
		"kjv" : "deck",
		"def" : "to gild, i.e. bespangle with golden ornaments",
		"derivation" : "from G5557 (χρυσός)"
	},
	"G5310" : {
		"def" : "highest, i.e. (masculine singular) the Supreme (God), or (neuter plural) the heavens",
		"derivation" : "superlative from the base of G5311 (ὕψος)",
		"lemma" : "ὕψιστος",
		"translit" : "hýpsistos",
		"kjv" : "most high, highest"
	},
	"G5542" : {
		"def" : "fair speech, i.e. plausibility",
		"derivation" : "from a compound of G5543 (χρηστός) and G3004 (λέγω)",
		"translit" : "chrēstología",
		"lemma" : "χρηστολογία",
		"kjv" : "good words"
	},
	"G688" : {
		"def" : "Arabia, a region of Asia",
		"derivation" : "of Hebrew origin (H6152)",
		"lemma" : "Ἀραβία",
		"translit" : "Arabía",
		"kjv" : "Arabia"
	},
	"G4522" : {
		"derivation" : "from a derivative of (to equip) meaning furniture, especially a pack-saddle (which in the East is merely a bag of netted rope)",
		"def" : "a \"seine\" for fishing",
		"kjv" : "net",
		"translit" : "sagḗnē",
		"lemma" : "σαγήνη"
	},
	"G577" : {
		"kjv" : "cast away",
		"translit" : "apobállō",
		"lemma" : "ἀποβάλλω",
		"derivation" : "from G575 (ἀπό) and G906 (βάλλω)",
		"def" : "to throw off; figuratively, to lose"
	},
	"G5201" : {
		"derivation" : "from G5204 (ὕδωρ)",
		"def" : "a water-jar, i.e. receptacle for family supply",
		"kjv" : "water-pot",
		"lemma" : "ὑδρία",
		"translit" : "hydría"
	},
	"G497" : {
		"kjv" : "war against",
		"translit" : "antistrateúomai",
		"lemma" : "ἀντιστρατεύομαι",
		"derivation" : "from G473 (ἀντί) and G4754 (στρατεύομαι)",
		"def" : "(figuratively) to attack, i.e. (by implication) destroy"
	},
	"G588" : {
		"kjv" : "accept, receive (gladly)",
		"lemma" : "ἀποδέχομαι",
		"translit" : "apodéchomai",
		"derivation" : "from G575 (ἀπό) and G1209 (δέχομαι)",
		"def" : "to take fully, i.e. welcome (persons), approve (things)"
	},
	"G4694" : {
		"derivation" : "of uncertain derivation",
		"def" : "a ledge or reef of rock in the sea",
		"kjv" : "spot (by confusion with G4696 (σπίλος))",
		"translit" : "spilás",
		"lemma" : "σπιλάς"
	},
	"G906" : {
		"kjv" : "arise, cast (out), X dung, lay, lie, pour, put (up), send, strike, throw (down), thrust",
		"translit" : "bállō",
		"lemma" : "βάλλω",
		"derivation" : "a primary verb",
		"def" : "to throw (in various applications, more or less violent or intense)"
	},
	"G4048" : {
		"def" : "to tear all around, i.e. completely away",
		"derivation" : "from G4012 (περί) and G4486 (ῥήγνυμι)",
		"translit" : "perirrhḗgnymi",
		"lemma" : "περιῤῥήγνυμι",
		"kjv" : "rend off"
	},
	"G2915" : {
		"def" : "barley",
		"derivation" : "of uncertain derivation",
		"translit" : "krithḗ",
		"lemma" : "κριθή",
		"kjv" : "barley"
	},
	"G1786" : {
		"def" : "a resident",
		"derivation" : "from G1722 (ἐν) and G5117 (τόπος)",
		"translit" : "entópios",
		"lemma" : "ἐντόπιος",
		"kjv" : "of that place"
	},
	"G5507" : {
		"lemma" : "χίλιοι",
		"translit" : "chílioi",
		"kjv" : "thousand",
		"def" : "a thousand",
		"derivation" : "plural of uncertain affinity"
	},
	"G4546" : {
		"derivation" : "of Hebrew origin (H8123)",
		"def" : "Sampson (i.e. Shimshon), an Israelite",
		"kjv" : "Samson",
		"lemma" : "Σαμψών",
		"translit" : "Sampsṓn"
	},
	"G1242" : {
		"kjv" : "covenant, testament",
		"translit" : "diathḗkē",
		"lemma" : "διαθήκη",
		"derivation" : "from G1303 (διατίθεμαι)",
		"def" : "properly, a disposition, i.e. (specially) a contract (especially a devisory will)"
	},
	"G3598" : {
		"translit" : "hodós",
		"lemma" : "ὁδός",
		"kjv" : "journey, (high-)way",
		"def" : "a road; by implication, a progress (the route, act or distance); figuratively, a mode or means",
		"derivation" : "apparently a primary word"
	},
	"G4264" : {
		"lemma" : "προβιβάζω",
		"translit" : "probibázō",
		"kjv" : "draw, before instruct",
		"def" : "to force forward, i.e. bring to the front, instigate",
		"derivation" : "from G4253 (πρό) and a reduplicated form of G971 (βιάζω)"
	},
	"G1366" : {
		"kjv" : "with two edges, two-edged",
		"lemma" : "δίστομος",
		"translit" : "dístomos",
		"derivation" : "from G1364 (δίς) and G4750 (στόμα)",
		"def" : "double-edged"
	},
	"G4240" : {
		"derivation" : "from G4239 (πραΰς)",
		"def" : "mildness, i.e. (by implication) humility",
		"kjv" : "meekness",
		"translit" : "praÿtēs",
		"lemma" : "πραΰτης"
	},
	"G3137" : {
		"def" : "Maria or Mariam (i.e. Mirjam), the name of six Christian females",
		"derivation" : "of Hebrew origin (H4813)",
		"translit" : "María",
		"lemma" : "Μαρία",
		"kjv" : "Mary"
	},
	"G2159" : {
		"kjv" : "mightily, vehemently",
		"lemma" : "εὐτόνως",
		"translit" : "eutónōs",
		"derivation" : "adverb from a compound of G2095 (εὖ) and a derivative of (to stretch)",
		"def" : "in a well-strung manner, i.e. (figuratively) intensely (in a good sense, cogently; in a bad one, fiercely)"
	},
	"G1596" : {
		"derivation" : "adverb from the same as G1595 (ἑκούσιον)",
		"def" : "voluntarily",
		"kjv" : "wilfully, willingly",
		"translit" : "hekousíōs",
		"lemma" : "ἑκουσίως"
	},
	"G2285" : {
		"lemma" : "θάμβος",
		"translit" : "thámbos",
		"kjv" : "X amazed, + astonished, wonder",
		"def" : "stupefaction (by surprise), i.e. astonishment",
		"derivation" : "akin to an obsolete (to dumbfound)"
	},
	"G348" : {
		"kjv" : "hinder",
		"translit" : "anakóptō",
		"lemma" : "ἀνακόπτω",
		"derivation" : "from G303 (ἀνά) and G2875 (κόπτω)",
		"def" : "to beat back, i.e. check"
	},
	"G1616" : {
		"translit" : "ekténeia",
		"lemma" : "ἐκτένεια",
		"kjv" : "X instantly",
		"def" : "intentness",
		"derivation" : "from G1618 (ἐκτενής)"
	},
	"G3477" : {
		"def" : "Nangæ (i.e. perhaps Nogach), an Israelite",
		"derivation" : "probably of Hebrew origin (compare H5052)",
		"lemma" : "Ναγγαί",
		"translit" : "Nangaí",
		"kjv" : "Nagge"
	},
	"G596" : {
		"kjv" : "barn, garner",
		"lemma" : "ἀποθήκη",
		"translit" : "apothḗkē",
		"derivation" : "from G659 (ἀποτίθημι)",
		"def" : "a repository, i.e. granary"
	},
	"G4180" : {
		"kjv" : "much speaking",
		"lemma" : "πολυλογία",
		"translit" : "polylogía",
		"derivation" : "from a compound of G4183 (πολύς) and G3056 (λόγος)",
		"def" : "loquacity, i.e. prolixity"
	},
	"G1423" : {
		"translit" : "dyskólōs",
		"lemma" : "δυσκόλως",
		"kjv" : "hardly",
		"def" : "impracticably",
		"derivation" : "adverb from G1422 (δύσκολος)"
	},
	"G2864" : {
		"derivation" : "apparently from the same as G2865 (κομίζω)",
		"def" : "the hair of the head (locks, as ornamental, and thus differing from G2359 (θρίξ); which properly denotes merely the scalp)",
		"kjv" : "hair",
		"lemma" : "κόμη",
		"translit" : "kómē"
	},
	"G2297" : {
		"translit" : "thaumásios",
		"lemma" : "θαυμάσιος",
		"kjv" : "wonderful thing",
		"def" : "wondrous, i.e. (neuter as noun) a miracle",
		"derivation" : "from G2295 (θαῦμα)"
	},
	"G4043" : {
		"def" : "to tread all around, i.e. walk at large (especially as proof of ability); figuratively, to live, deport oneself, follow (as a companion or votary)",
		"derivation" : "from G4012 (περί) and G3961 (πατέω)",
		"translit" : "peripatéō",
		"lemma" : "περιπατέω",
		"kjv" : "go, be occupied with, walk (about)"
	},
	"G1830" : {
		"def" : "to explore (figuratively)",
		"derivation" : "from G1537 (ἐκ) and G2045 (ἐρευνάω)",
		"translit" : "exereunáō",
		"lemma" : "ἐξερευνάω",
		"kjv" : "search diligently"
	},
	"G3410" : {
		"lemma" : "μίσθωμα",
		"translit" : "místhōma",
		"kjv" : "hired house",
		"def" : "a rented building",
		"derivation" : "from G3409 (μισθόω)"
	},
	"G2347" : {
		"kjv" : "afflicted(-tion), anguish, burdened, persecution, tribulation, trouble",
		"translit" : "thlîpsis",
		"lemma" : "θλῖψις",
		"derivation" : "from G2346 (θλίβω)",
		"def" : "pressure (literally or figuratively)"
	},
	"G3136" : {
		"lemma" : "Μάρθα",
		"translit" : "Mártha",
		"kjv" : "Martha",
		"def" : "Martha, a Christian woman",
		"derivation" : "probably of Chaldee origin (meaning mistress)"
	},
	"G1944" : {
		"translit" : "epikatáratos",
		"lemma" : "ἐπικατάρατος",
		"kjv" : "accursed",
		"def" : "imprecated, i.e. execrable",
		"derivation" : "from G1909 (ἐπί) and a derivative of G2672 (καταράομαι)"
	},
	"G4715" : {
		"derivation" : "from the base of G2746 (καύχησις)",
		"def" : "a stander (standard of value), i.e. (specially), a stater or certain coin",
		"kjv" : "piece of money",
		"translit" : "statḗr",
		"lemma" : "στατήρ"
	},
	"G2244" : {
		"def" : "maturity (in years or size)",
		"derivation" : "from the same as G2245 (ἡλίκος)",
		"lemma" : "ἡλικία",
		"translit" : "hēlikía",
		"kjv" : "age, stature"
	},
	"G4004" : {
		"derivation" : "multiplicative of G4002 (πέντε)",
		"def" : "fifty",
		"kjv" : "fifty",
		"lemma" : "πεντήκοντα",
		"translit" : "pentḗkonta"
	},
	"G3790" : {
		"kjv" : "brow",
		"translit" : "ophrŷs",
		"lemma" : "ὀφρῦς",
		"derivation" : "perhaps from G3700 (ὀπτάνομαι) (through the idea of the shading or proximity to the organ of vision)",
		"def" : "the eye-\"brow\" or forehead, i.e. (figuratively) the brink of a precipice"
	},
	"G373" : {
		"derivation" : "from G303 (ἀνά) and G3973 (παύω)",
		"def" : "(reflexively) to repose (literally or figuratively (be exempt), remain); by implication, to refresh",
		"kjv" : "take ease, refresh, (give, take) rest",
		"lemma" : "ἀναπαύω",
		"translit" : "anapaúō"
	},
	"G3495" : {
		"derivation" : "from the same as G3494 (νεανίας)",
		"def" : "a youth (under forty)",
		"kjv" : "young man",
		"lemma" : "νεανίσκος",
		"translit" : "neanískos"
	},
	"G2340" : {
		"def" : "to hunt (an animal), i.e. (figuratively) to carp at",
		"derivation" : "from G2339 (θήρα)",
		"lemma" : "θηρεύω",
		"translit" : "thēreúō",
		"kjv" : "catch"
	},
	"G3519" : {
		"kjv" : "island",
		"translit" : "nēsíon",
		"lemma" : "νησίον",
		"derivation" : "diminutive of G3520 (νῆσος)",
		"def" : "an islet"
	},
	"G5001" : {
		"derivation" : "from G5021 (τάσσω)",
		"def" : "something orderly in arrangement (a troop), i.e. (figuratively) a series or succession",
		"kjv" : "order",
		"lemma" : "τάγμα",
		"translit" : "tágma"
	},
	"G4217" : {
		"def" : "interrogatively, whatever, i.e. of what possible sort",
		"derivation" : "apparently from G4219 (πότε) and the base of G4226 (ποῦ)",
		"lemma" : "ποταπός",
		"translit" : "potapós",
		"kjv" : "what (manner of)"
	},
	"G2361" : {
		"kjv" : "great drop",
		"translit" : "thrómbos",
		"lemma" : "θρόμβος",
		"derivation" : "perhaps from G5142 (τρέφω) (in the sense of thickening)",
		"def" : "a clot"
	},
	"G2562" : {
		"derivation" : "feminine of G2563 (κάλαμος)",
		"def" : "a stalk of grain, i.e. (collectively) stubble",
		"kjv" : "stubble",
		"lemma" : "καλάμη",
		"translit" : "kalámē"
	},
	"G3077" : {
		"kjv" : "grief, grievous, + grudgingly, heaviness, sorrow",
		"lemma" : "λύπη",
		"translit" : "lýpē",
		"derivation" : "apparently a primary word",
		"def" : "sadness"
	},
	"G4523" : {
		"kjv" : "Sadducee",
		"translit" : "Saddoukaîos",
		"lemma" : "Σαδδουκαῖος",
		"derivation" : "probably from G4524 (Σαδώκ)",
		"def" : "a Sadducæan (i.e. Tsadokian), or follower of a certain heretical Israelite"
	},
	"G4379" : {
		"lemma" : "προσψαύω",
		"translit" : "prospsaúō",
		"kjv" : "touch",
		"def" : "to impinge, i.e. lay a finger on (in order to relieve)",
		"derivation" : "from G4314 (πρός) and (to touch)"
	},
	"G2942" : {
		"lemma" : "κυβερνήτης",
		"translit" : "kybernḗtēs",
		"kjv" : "(ship) master",
		"def" : "helmsman, i.e. (by implication) captain",
		"derivation" : "from the same as G2941 (κυβέρνησις)"
	},
	"G3980" : {
		"def" : "to be persuaded by a ruler, i.e. (genitive case) to submit to authority; by analogy, to conform to advice",
		"derivation" : "from a compound of G3982 (πείθω) and G757 (ἄρχω)",
		"translit" : "peitharchéō",
		"lemma" : "πειθαρχέω",
		"kjv" : "hearken, obey (magistrates)"
	},
	"G3492" : {
		"def" : "a boatman, i.e. seaman",
		"derivation" : "from G3491 (ναῦς)",
		"lemma" : "ναύτης",
		"translit" : "naútēs",
		"kjv" : "sailor, shipman"
	},
	"G2702" : {
		"derivation" : "from G2596 (κατά) and G5342 (φέρω) (including its alternate)",
		"def" : "to bear down, i.e. (figuratively) overcome (with drowsiness); specially, to cast a vote",
		"kjv" : "fall, give, sink down",
		"lemma" : "καταφέρω",
		"translit" : "kataphérō"
	},
	"G4760" : {
		"lemma" : "στρατόπεδον",
		"translit" : "stratópedon",
		"kjv" : "army",
		"def" : "a camping-ground, i.e. (by implication) a body of troops",
		"derivation" : "from the base of G4756 (στρατιά) and the same as G3977 (πεδινός)"
	},
	"G4389" : {
		"kjv" : "exhort",
		"translit" : "protrépomai",
		"lemma" : "προτρέπομαι",
		"derivation" : "middle voice from G4253 (πρό) and the base of G5157 (τροπή)",
		"def" : "to turn forward for oneself, i.e. encourage"
	},
	"G2555" : {
		"def" : "a bad-doer; (specially), a criminal",
		"derivation" : "from G2556 (κακός) and G4160 (ποιέω)",
		"translit" : "kakopoiós",
		"lemma" : "κακοποιός",
		"kjv" : "evil-doer, malefactor"
	},
	"G74" : {
		"kjv" : "agony",
		"translit" : "agōnía",
		"lemma" : "ἀγωνία",
		"derivation" : "from G73 (ἀγών)",
		"def" : "a struggle (properly, the state), i.e. (figuratively) anguish"
	},
	"G1436" : {
		"derivation" : "apparent imperative of G1439 (ἐάω)",
		"def" : "properly, let it be, i.e. (as interjection) aha!",
		"kjv" : "let alone",
		"translit" : "éa",
		"lemma" : "ἔα"
	},
	"G1269" : {
		"def" : "to nod (or express by signs) across an intervening space",
		"derivation" : "from G1223 (διά) and G3506 (νεύω)",
		"lemma" : "διανεύω",
		"translit" : "dianeúō",
		"kjv" : "beckon"
	},
	"G2104" : {
		"translit" : "eugenḗs",
		"lemma" : "εὐγενής",
		"kjv" : "more noble, nobleman",
		"def" : "well born, i.e. (literally) high in rank, or (figuratively) generous",
		"derivation" : "from G2095 (εὖ) and G1096 (γίνομαι)"
	},
	"G4852" : {
		"derivation" : "from G4862 (σύν) and G5346 (φημί)",
		"def" : "to say jointly, i.e. assent to",
		"kjv" : "consent unto",
		"translit" : "sýmphēmi",
		"lemma" : "σύμφημι"
	},
	"G2999" : {
		"kjv" : "(divine) service",
		"lemma" : "λατρεία",
		"translit" : "latreía",
		"derivation" : "from G3000 (λατρεύω)",
		"def" : "ministration of God, i.e. worship"
	},
	"G3973" : {
		"derivation" : "a primary verb (\"pause\")",
		"def" : "to stop (transitively or intransitively), i.e. restrain, quit, desist, come to an end",
		"kjv" : "cease, leave, refrain",
		"translit" : "paúō",
		"lemma" : "παύω"
	},
	"G536" : {
		"translit" : "aparchḗ",
		"lemma" : "ἀπαρχή",
		"kjv" : "first-fruits",
		"def" : "a beginning of sacrifice, i.e. the (Jewish) first-fruit (figuratively)",
		"derivation" : "from a compound of G575 (ἀπό) and G756 (ἄρχομαι)"
	},
	"G3645" : {
		"def" : "to spoil, i.e. slay",
		"derivation" : "from G3639 (ὄλεθρος)",
		"translit" : "olothreúō",
		"lemma" : "ὀλοθρεύω",
		"kjv" : "destroy"
	},
	"G1929" : {
		"lemma" : "ἐπιδίδωμι",
		"translit" : "epidídōmi",
		"kjv" : "deliver unto, give, let (+ (her drive)), offer",
		"def" : "to give over (by hand or surrender)",
		"derivation" : "from G1909 (ἐπί) and G1325 (δίδωμι)"
	},
	"G2191" : {
		"kjv" : "viper",
		"translit" : "échidna",
		"lemma" : "ἔχιδνα",
		"derivation" : "of uncertain origin",
		"def" : "an adder or other poisonous snake (literally or figuratively)"
	},
	"G3142" : {
		"translit" : "martýrion",
		"lemma" : "μαρτύριον",
		"kjv" : "to be testified, testimony, witness",
		"def" : "something evidential, i.e. (genitive case) evidence given or (specially), the Decalogue (in the sacred Tabernacle)",
		"derivation" : "neuter of a presumed derivative of G3144 (μάρτυς)"
	},
	"G2588" : {
		"derivation" : "prolonged from a primary (Latin cor, \"heart\")",
		"def" : "the heart, i.e. (figuratively) the thoughts or feelings (mind); also (by analogy) the middle",
		"kjv" : "(+ broken-)heart(-ed)",
		"translit" : "kardía",
		"lemma" : "καρδία"
	},
	"G4614" : {
		"translit" : "Sinâ",
		"lemma" : "Σινᾶ",
		"kjv" : "Sina",
		"def" : "Sina (i.e. Sinai), a mountain in Arabia",
		"derivation" : "of Hebrew origin (H5514)"
	},
	"G2020" : {
		"derivation" : "a form of G2017 (ἐπιφαύω)",
		"def" : "to begin to grow light",
		"kjv" : "begin to dawn, X draw on",
		"lemma" : "ἐπιφώσκω",
		"translit" : "epiphṓskō"
	},
	"G4018" : {
		"def" : "something thrown around one, i.e. a mantle, veil",
		"derivation" : "neuter of a presumed derivative of G4016 (περιβάλλω)",
		"lemma" : "περιβόλαιον",
		"translit" : "peribólaion",
		"kjv" : "covering, vesture"
	},
	"G5559" : {
		"def" : "the body (properly, its surface or skin)",
		"derivation" : "probably akin to the base of G5530 (χράομαι) through the idea of handling",
		"lemma" : "χρώς",
		"translit" : "chrṓs",
		"kjv" : "body"
	},
	"G82" : {
		"kjv" : "appear not, uncertain",
		"lemma" : "ἄδηλος",
		"translit" : "ádēlos",
		"derivation" : "from G1 (Α) (as a negative particle) and G1212 (δῆλος)",
		"def" : "hidden, figuratively, indistinct"
	},
	"G742" : {
		"def" : "Arphaxad, a post-diluvian patriarch",
		"derivation" : "of Hebrew origin (H775)",
		"translit" : "Arphaxád",
		"lemma" : "Ἀρφαξάδ",
		"kjv" : "Arphaxad"
	},
	"G1735" : {
		"derivation" : "third person singular present of a compound of G1722 (ἐν) and G1209 (δέχομαι)",
		"def" : "(impersonally) it is accepted in, i.e. admitted (possible)",
		"kjv" : "can (+ not) be",
		"lemma" : "ἐνδέχεται",
		"translit" : "endéchetai"
	},
	"G3486" : {
		"kjv" : "Naum",
		"translit" : "Naoúm",
		"lemma" : "Ναούμ",
		"derivation" : "of Hebrew origin (H5151)",
		"def" : "Naum (i.e. Nachum), an Israelite"
	},
	"G1232" : {
		"kjv" : "make known",
		"translit" : "diagnōrízō",
		"lemma" : "διαγνωρίζω",
		"derivation" : "from G1123 (γραπτός) and G1107 (γνωρίζω)",
		"def" : "to tell abroad"
	},
	"G4591" : {
		"derivation" : "from (a mark",
		"def" : "of uncertain derivation); to indicate",
		"kjv" : "signify",
		"translit" : "sēmaínō",
		"lemma" : "σημαίνω"
	},
	"G1586" : {
		"def" : "to select",
		"derivation" : "middle voice from G1537 (ἐκ) and G3004 (λέγω) (in its primary sense)",
		"lemma" : "ἐκλέγομαι",
		"translit" : "eklégomai",
		"kjv" : "make choice, choose (out), chosen"
	},
	"G382" : {
		"lemma" : "ἀναρίθμητος",
		"translit" : "anaríthmētos",
		"kjv" : "innumerable",
		"def" : "unnumbered, i.e. without number",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G705 (ἀριθμέω)"
	},
	"G5404" : {
		"translit" : "phoînix",
		"lemma" : "φοῖνιξ",
		"kjv" : "palm (tree)",
		"def" : "a palm-tree",
		"derivation" : "of uncertain derivation"
	},
	"G5451" : {
		"kjv" : "plant",
		"translit" : "phyteía",
		"lemma" : "φυτεία",
		"derivation" : "from G5452 (φυτεύω)",
		"def" : "trans-planting, i.e. (concretely) a shrub or vegetable"
	},
	"G3901" : {
		"def" : "to flow by, i.e. (figuratively) carelessly pass (miss)",
		"derivation" : "from G3844 (παρά) and the alternate of G4482 (ῥέω)",
		"translit" : "pararrhyéō",
		"lemma" : "παραῤῥυέω",
		"kjv" : "let slip"
	},
	"G3730" : {
		"kjv" : "assault",
		"lemma" : "ὁρμή",
		"translit" : "hormḗ",
		"derivation" : "of uncertain affinity",
		"def" : "a violent impulse, i.e. onset"
	},
	"G3524" : {
		"kjv" : "sober",
		"translit" : "nēpháleos",
		"lemma" : "νηφάλεος",
		"derivation" : "from G3525 (νήφω)",
		"def" : "sober, i.e. (figuratively) circumspect"
	},
	"G1474" : {
		"kjv" : "lay even with the ground",
		"lemma" : "ἐδαφίζω",
		"translit" : "edaphízō",
		"derivation" : "from G1475 (ἔδαφος)",
		"def" : "to raze"
	},
	"G5623" : {
		"kjv" : "advantage, better, prevail, profit",
		"lemma" : "ὠφελέω",
		"translit" : "ōpheléō",
		"derivation" : "from the same as G5622 (ὠφέλεια)",
		"def" : "to be useful, i.e. to benefit"
	},
	"G1184" : {
		"translit" : "dektós",
		"lemma" : "δεκτός",
		"kjv" : "accepted(-table)",
		"def" : "approved; (figuratively) propitious",
		"derivation" : "from G1209 (δέχομαι)"
	},
	"G2183" : {
		"lemma" : "ἐφημερία",
		"translit" : "ephēmería",
		"kjv" : "course",
		"def" : "diurnality, i.e. (specially) the quotidian rotation or class of the Jewish priests' service at the Temple, as distributed by families",
		"derivation" : "from G2184 (ἐφήμερος)"
	},
	"G3494" : {
		"derivation" : "from a derivative of G3501 (νέος)",
		"def" : "a youth (up to about forty years)",
		"kjv" : "young man",
		"translit" : "neanías",
		"lemma" : "νεανίας"
	},
	"G50" : {
		"def" : "not to know (through lack of information or intelligence); by implication, to ignore (through disinclination)",
		"derivation" : "from G1 (Α) (as a negative particle) and G3539 (νοιέω)",
		"lemma" : "ἀγνοέω",
		"translit" : "agnoéō",
		"kjv" : "(be) ignorant(-ly), not know, not understand, unknown"
	},
	"G288" : {
		"kjv" : "vine",
		"translit" : "ámpelos",
		"lemma" : "ἄμπελος",
		"derivation" : "probably from the base of G297 (ἀμφότερος) and that of G257 (ἅλων)",
		"def" : "a vine (as coiling about a support)"
	},
	"G2891" : {
		"def" : "cumi (i.e. rise!)",
		"derivation" : "of Chaldee origin (H6966)",
		"translit" : "koûmi",
		"lemma" : "κοῦμι",
		"kjv" : "cumi"
	},
	"G1559" : {
		"kjv" : "persecute",
		"lemma" : "ἐκδιώκω",
		"translit" : "ekdiṓkō",
		"derivation" : "from G1537 (ἐκ) and G1377 (διώκω)",
		"def" : "to pursue out, i.e. expel or persecute implacably"
	},
	"G3534" : {
		"translit" : "nîkos",
		"lemma" : "νῖκος",
		"kjv" : "victory",
		"def" : "a conquest (concretely), i.e. (by implication) triumph",
		"derivation" : "from G3529 (νίκη)"
	},
	"G1545" : {
		"kjv" : "end, way to escape",
		"lemma" : "ἔκβασις",
		"translit" : "ékbasis",
		"derivation" : "from a compound of G1537 (ἐκ) and the base of G939 (βάσις) (meaning to go out)",
		"def" : "an exit (literally or figuratively)"
	},
	"G1564" : {
		"kjv" : "from that place, (from) thence, there",
		"translit" : "ekeîthen",
		"lemma" : "ἐκεῖθεν",
		"derivation" : "from G1563 (ἐκεῖ)",
		"def" : "thence"
	},
	"G4543" : {
		"kjv" : "Samothracia",
		"translit" : "Samothráikē",
		"lemma" : "Σαμοθρᾴκη",
		"derivation" : "from G4544 (Σάμος) and (Thrace)",
		"def" : "Samo-thrace (Samos of Thrace), an island in the Mediterranean"
	},
	"G1529" : {
		"def" : "an entrance (literally or figuratively)",
		"derivation" : "from G1519 (εἰς) and G3598 (ὁδός)",
		"translit" : "eísodos",
		"lemma" : "εἴσοδος",
		"kjv" : "coming, enter(-ing) in (to)"
	},
	"G3434" : {
		"kjv" : "Moloch",
		"translit" : "Molóch",
		"lemma" : "Μολόχ",
		"derivation" : "of Hebrew origin (H4432)",
		"def" : "Moloch (i.e. Molek), an idol"
	},
	"G251" : {
		"kjv" : "salt",
		"translit" : "háls",
		"lemma" : "ἅλς",
		"derivation" : "a primary word",
		"def" : "\"salt\""
	},
	"G1313" : {
		"kjv" : "differing, divers, more excellent",
		"translit" : "diáphoros",
		"lemma" : "διάφορος",
		"derivation" : "from G1308 (διαφέρω)",
		"def" : "varying; also surpassing"
	},
	"G3525" : {
		"kjv" : "be sober, watch",
		"translit" : "nḗphō",
		"lemma" : "νήφω",
		"derivation" : "of uncertain affinity",
		"def" : "to abstain from wine (keep sober), i.e. (figuratively) be discreet"
	},
	"G5266" : {
		"derivation" : "from G5265 (ὑποδέω)",
		"def" : "something bound under the feet, i.e. a shoe or sandal",
		"kjv" : "shoe",
		"lemma" : "ὑπόδημα",
		"translit" : "hypódēma"
	},
	"G3550" : {
		"kjv" : "lawgiver",
		"translit" : "nomothétēs",
		"lemma" : "νομοθέτης",
		"derivation" : "from G3551 (νόμος) and a derivative of G5087 (τίθημι)",
		"def" : "a legislator"
	},
	"G1032" : {
		"derivation" : "a primary verb",
		"def" : "to swell out, i.e. (by implication) to gush",
		"kjv" : "send forth",
		"translit" : "brýō",
		"lemma" : "βρύω"
	},
	"G37" : {
		"kjv" : "hallow, be holy, sanctify",
		"lemma" : "ἁγιάζω",
		"translit" : "hagiázō",
		"derivation" : "from G40 (ἅγιος)",
		"def" : "to make holy, i.e. (ceremonially) purify or consecrate; (mentally) to venerate"
	},
	"G2570" : {
		"derivation" : "of uncertain affinity; properly, beautiful, but chiefly (figuratively) good (literally or morally), i.e. valuable or virtuous (for appearance or use, and thus distinguished from G18 (ἀγαθός), which is properly intrinsic)",
		"kjv" : "X better, fair, good(-ly), honest, meet, well, worthy",
		"translit" : "kalós",
		"lemma" : "καλός"
	},
	"G1252" : {
		"lemma" : "διακρίνω",
		"translit" : "diakrínō",
		"kjv" : "contend, make (to) differ(-ence), discern, doubt, judge, be partial, stagger, waver",
		"def" : "to separate thoroughly, i.e. (literally and reflexively) to withdraw from, or (by implication) oppose; figuratively, to discriminate (by implication, decide), or (reflexively) hesitate",
		"derivation" : "from G1223 (διά) and G2919 (κρίνω)"
	},
	"G2745" : {
		"def" : "a boast (properly, the object; by implication, the act) in a good or a bad sense",
		"derivation" : "from G2744 (καυχάομαι)",
		"translit" : "kaúchēma",
		"lemma" : "καύχημα",
		"kjv" : "boasting, (whereof) to glory (of), glorying, rejoice(-ing)"
	},
	"G592" : {
		"def" : "to disjoin (by a boundary, figuratively, a party)",
		"derivation" : "from G575 (ἀπό) and a compound of G223 (Ἀλέξανδρος) and G3724 (ὁρίζω)",
		"translit" : "apodiorízō",
		"lemma" : "ἀποδιορίζω",
		"kjv" : "separate"
	},
	"G4197" : {
		"def" : "travel (by land); figuratively (plural) proceedings, i.e. career",
		"derivation" : "from G4198 (πορεύομαι)",
		"translit" : "poreía",
		"lemma" : "πορεία",
		"kjv" : "journey(-ing), ways"
	},
	"G1405" : {
		"kjv" : "take",
		"lemma" : "δράσσομαι",
		"translit" : "drássomai",
		"derivation" : "perhaps akin to the base of G1404 (δράκων) (through the idea of capturing)",
		"def" : "to grasp, i.e. (figuratively) entrap"
	},
	"G339" : {
		"derivation" : "from G303 (ἀνά) and G2523 (καθίζω)",
		"def" : "properly, to set up, i.e. (reflexively) to sit up",
		"kjv" : "sit up",
		"lemma" : "ἀνακαθίζω",
		"translit" : "anakathízō"
	},
	"G1997" : {
		"def" : "a complete collection; especially a Christian meeting (for worship)",
		"derivation" : "from G1996 (ἐπισυνάγω)",
		"lemma" : "ἐπισυναγωγή",
		"translit" : "episynagōgḗ",
		"kjv" : "assembling (gathering) together"
	},
	"G3429" : {
		"kjv" : "commit adultery",
		"translit" : "moicháō",
		"lemma" : "μοιχάω",
		"derivation" : "from G3432 (μοιχός)",
		"def" : "(middle voice) to commit adultery"
	},
	"G2495" : {
		"def" : "Jonas (i.e. Jonah), the name of two Israelites",
		"derivation" : "of Hebrew origin (H3124)",
		"lemma" : "Ἰωνᾶς",
		"translit" : "Iōnâs",
		"kjv" : "Jonas"
	},
	"G4673" : {
		"kjv" : "bier",
		"translit" : "sorós",
		"lemma" : "σορός",
		"derivation" : "probably akin to the base of G4987 (σωρεύω)",
		"def" : "a funereal receptacle (urn, coffin), i.e. (by analogy) a bier"
	},
	"G1500" : {
		"kjv" : "without a cause, (in) vain(-ly)",
		"translit" : "eikē",
		"lemma" : "εἰκῆ",
		"derivation" : "probably from G1502 (εἴκω) (through the idea of failure)",
		"def" : "idly, i.e. without reason (or effect)"
	},
	"G3827" : {
		"lemma" : "πάμπολυς",
		"translit" : "pámpolys",
		"kjv" : "very great",
		"def" : "full many, i.e. immense",
		"derivation" : "from G3956 (πᾶς) and G4183 (πολύς)"
	},
	"G468" : {
		"kjv" : "recompense",
		"lemma" : "ἀνταπόδομα",
		"translit" : "antapódoma",
		"derivation" : "from G467 (ἀνταποδίδωμι)",
		"def" : "a requital (properly, the thing)"
	},
	"G2133" : {
		"derivation" : "from the same as G2132 (εὐνοέω)",
		"def" : "kindness; euphemistically, conjugal duty",
		"kjv" : "benevolence, good will",
		"translit" : "eúnoia",
		"lemma" : "εὔνοια"
	},
	"G3857" : {
		"derivation" : "of Oriental origin (compare H6508)",
		"def" : "a park, i.e. (specially), an Eden (place of future happiness, \"paradise\")",
		"kjv" : "paradise",
		"lemma" : "παράδεισος",
		"translit" : "parádeisos"
	},
	"G3638" : {
		"derivation" : "a primary numeral",
		"def" : "\"eight\"",
		"kjv" : "eight",
		"lemma" : "ὀκτώ",
		"translit" : "oktṓ"
	},
	"G2118" : {
		"def" : "rectitude",
		"derivation" : "from G2117 (εὐθύς)",
		"translit" : "euthýtēs",
		"lemma" : "εὐθύτης",
		"kjv" : "righteousness"
	},
	"G3151" : {
		"def" : "an idle (i.e. senseless or mischievous) talker, i.e. a wrangler",
		"derivation" : "from G3152 (μάταιος) and G3004 (λέγω)",
		"lemma" : "ματαιολόγος",
		"translit" : "mataiológos",
		"kjv" : "vain talker"
	},
	"G2237" : {
		"derivation" : "from (to please)",
		"def" : "sensual delight; by implication, desire",
		"kjv" : "lust, pleasure",
		"lemma" : "ἡδονή",
		"translit" : "hēdonḗ"
	},
	"G3526" : {
		"kjv" : "Niger",
		"translit" : "Níger",
		"lemma" : "Νίγερ",
		"derivation" : "of Latin origin",
		"def" : "black; Niger, a Christian"
	},
	"G5155" : {
		"derivation" : "from G2359 (θρίξ)",
		"def" : "hairy, i.e. made of hair (mohair)",
		"kjv" : "of hair",
		"lemma" : "τρίχινος",
		"translit" : "tríchinos"
	},
	"G914" : {
		"derivation" : "of Hebrew origin (H1296)",
		"def" : "Barachias (i.e. Berechijah), an Israelite",
		"kjv" : "Barachias",
		"lemma" : "Βαραχίας",
		"translit" : "Barachías"
	},
	"G4691" : {
		"def" : "a seed-picker (as the crow), i.e. (figuratively) a sponger, loafer (specially, a gossip or trifler in talk)",
		"derivation" : "from G4690 (σπέρμα) and G3004 (λέγω)",
		"translit" : "spermológos",
		"lemma" : "σπερμολόγος",
		"kjv" : "babbler"
	},
	"G2352" : {
		"lemma" : "θραύω",
		"translit" : "thraúō",
		"kjv" : "bruise",
		"def" : "to crush",
		"derivation" : "a primary verb"
	},
	"G364" : {
		"def" : "recollection",
		"derivation" : "from G363 (ἀναμιμνήσκω)",
		"lemma" : "ἀνάμνησις",
		"translit" : "anámnēsis",
		"kjv" : "remembrance (again)"
	},
	"G3169" : {
		"def" : "befitting greatness or magnificence (majestic)",
		"derivation" : "from G3173 (μέγας) and G4241 (πρέπω)",
		"translit" : "megaloprepḗs",
		"lemma" : "μεγαλοπρεπής",
		"kjv" : "excellent"
	},
	"G3445" : {
		"translit" : "morphóō",
		"lemma" : "μορφόω",
		"kjv" : "form",
		"def" : "to fashion (figuratively)",
		"derivation" : "from the same as G3444 (μορφή)"
	},
	"G2115" : {
		"lemma" : "εὔθυμος",
		"translit" : "eúthymos",
		"kjv" : "of good cheer, the more cheerfully",
		"def" : "in fine spirits, i.e. cheerful",
		"derivation" : "from G2095 (εὖ) and G2372 (θυμός)"
	},
	"G5090" : {
		"lemma" : "Τιμαῖος",
		"translit" : "Timaîos",
		"kjv" : "Timæus",
		"def" : "Timæus (i.e. Timay), an Israelite",
		"derivation" : "probably of Chaldee origin (compare H2931)"
	},
	"G4732" : {
		"lemma" : "στερεόω",
		"translit" : "stereóō",
		"kjv" : "establish, receive strength, make strong",
		"def" : "to solidify, i.e. confirm (literally or figuratively)",
		"derivation" : "from G4731 (στερεός)"
	},
	"G3030" : {
		"derivation" : "of foreign origin (H3828)",
		"def" : "the incense-tree, i.e. (by implication) incense itself",
		"kjv" : "frankincense",
		"translit" : "líbanos",
		"lemma" : "λίβανος"
	},
	"G4966" : {
		"def" : "Sychem (i.e. Shekem), the name of a Canaanite and of a place in Palestine",
		"derivation" : "of Hebrew origin (H7927)",
		"translit" : "Sychém",
		"lemma" : "Συχέμ",
		"kjv" : "Sychem"
	},
	"G611" : {
		"def" : "to conclude for oneself, i.e. (by implication) to respond; by Hebraism (compare H6030) to begin to speak (where an address is expected)",
		"derivation" : "from G575 (ἀπό) and ",
		"lemma" : "ἀποκρίνομαι",
		"translit" : "apokrínomai",
		"kjv" : "answer"
	},
	"G2029" : {
		"derivation" : "from G1909 (ἐπί) and a derivative of G3700 (ὀπτάνομαι)",
		"def" : "to inspect, i.e. watch",
		"kjv" : "behold",
		"lemma" : "ἐποπτεύω",
		"translit" : "epopteúō"
	},
	"G5120" : {
		"derivation" : "properly, the genitive case of G3588 (ὁ)",
		"def" : "sometimes used for G5127 (τούτου); of this person",
		"kjv" : "his",
		"translit" : "toû",
		"lemma" : "τοῦ"
	},
	"G4010" : {
		"def" : "fortified; Pergamus, a place in Asia Minor",
		"derivation" : "from G4444 (πύργος)",
		"translit" : "Pérgamos",
		"lemma" : "Πέργαμος",
		"kjv" : "Pergamos"
	},
	"G4948" : {
		"derivation" : "from the same as G4947 (Συρία)",
		"def" : "a Syran (i.e. probably Tyrian), a native of Syria",
		"kjv" : "Syrian",
		"lemma" : "Σύρος",
		"translit" : "Sýros"
	},
	"G1205" : {
		"def" : "come hither!",
		"derivation" : "from G1204 (δεῦρο) and an imperative form of (to go)",
		"lemma" : "δεῦτε",
		"translit" : "deûte",
		"kjv" : "come, X follow"
	},
	"G4729" : {
		"def" : "to hem in closely, i.e. (figuratively) cramp",
		"derivation" : "from the same as G4730 (στενοχωρία)",
		"lemma" : "στενοχωρέω",
		"translit" : "stenochōréō",
		"kjv" : "distress, straiten"
	},
	"G2461" : {
		"kjv" : "horse(-men)",
		"lemma" : "ἱππικόν",
		"translit" : "hippikón",
		"derivation" : "neuter of a derivative of G2462 (ἵππος)",
		"def" : "the cavalry force"
	},
	"G575" : {
		"derivation" : "a primary particle",
		"def" : "\"off,\" i.e. away (from something near), in various senses (of place, time, or relation; literal or figurative)",
		"kjv" : "(X here-)after, ago, at, because of, before, by (the space of), for(-th), from, in, (out) of, off, (up-)on(-ce), since, with",
		"lemma" : "ἀπό",
		"translit" : "apó"
	},
	"G907" : {
		"def" : "to immerse, submerge; to make whelmed (i.e. fully wet); used only (in the New Testament) of ceremonial ablution, especially (technically) of the ordinance of Christian baptism",
		"derivation" : "from a derivative of G911 (βάπτω)",
		"translit" : "baptízō",
		"lemma" : "βαπτίζω",
		"kjv" : "Baptist, baptize, wash"
	},
	"G3416" : {
		"kjv" : "Mnason",
		"lemma" : "Μνάσων",
		"translit" : "Mnásōn",
		"derivation" : "of uncertain origin",
		"def" : "Mnason, a Christian"
	},
	"G1569" : {
		"def" : "utterly astounded",
		"derivation" : "from G1537 (ἐκ) and G2285 (θάμβος)",
		"translit" : "ékthambos",
		"lemma" : "ἔκθαμβος",
		"kjv" : "greatly wondering"
	},
	"G3475" : {
		"derivation" : "of Hebrew origin; (H4872)",
		"def" : "Moseus, Moses, or Mouses (i.e. Mosheh), the Hebrew lawgiver",
		"kjv" : "Moses",
		"translit" : "Mōseús",
		"lemma" : "Μωσεύς"
	},
	"G4487" : {
		"kjv" : "+ evil, + nothing, saying, word",
		"translit" : "rhēma",
		"lemma" : "ῥῆμα",
		"derivation" : "from G4483 (ῥέω)",
		"def" : "an utterance (individually, collectively or specially),; by implication, a matter or topic (especially of narration, command or dispute); with a negative naught whatever"
	},
	"G885" : {
		"lemma" : "Ἀχείμ",
		"translit" : "Acheím",
		"kjv" : "Achim",
		"def" : "Achim, an Israelite",
		"derivation" : "probably of Hebrew origin (compare H3137)"
	},
	"G3143" : {
		"def" : "to be adduced as a witness, i.e. (figuratively) to obtest (in affirmation or exhortation)",
		"derivation" : "middle voice from G3144 (μάρτυς)",
		"translit" : "martýromai",
		"lemma" : "μαρτύρομαι",
		"kjv" : "take to record, testify"
	},
	"G356" : {
		"def" : "proportion",
		"derivation" : "from a compound of G303 (ἀνά) and G3056 (λόγος)",
		"lemma" : "ἀναλογία",
		"translit" : "analogía",
		"kjv" : "proportion"
	},
	"G1075" : {
		"derivation" : "from G1074 (γενεά) and G3056 (λόγος)",
		"def" : "to reckon by generations, i.e. trace in genealogy",
		"kjv" : "count by descent",
		"lemma" : "γενεαλογέω",
		"translit" : "genealogéō"
	},
	"G3131" : {
		"derivation" : "of Hebrew origin (H4478)",
		"def" : "manna (i.e. man), an edible gum",
		"kjv" : "manna",
		"translit" : "mánna",
		"lemma" : "μάννα"
	},
	"G1760" : {
		"def" : "to be inspirited, i.e. ponder",
		"derivation" : "from a compound of G1722 (ἐν) and G2372 (θυμός)",
		"translit" : "enthyméomai",
		"lemma" : "ἐνθυμέομαι",
		"kjv" : "think"
	},
	"G3020" : {
		"def" : "Levitic, i.e. relating to the Levites",
		"derivation" : "from G3019 (Λευΐτης)",
		"translit" : "Leuïtikós",
		"lemma" : "Λευϊτικός",
		"kjv" : "Levitical"
	},
	"G3078" : {
		"def" : "grief-dispelling; Lysanias, a governor of Abilene",
		"derivation" : "from G3080 (λύσις) and (trouble)",
		"translit" : "Lysanías",
		"lemma" : "Λυσανίας",
		"kjv" : "Lysanias"
	},
	"G3903" : {
		"translit" : "paraskeuázō",
		"lemma" : "παρασκευάζω",
		"kjv" : "prepare self, be (make) ready",
		"def" : "to furnish aside, i.e. get ready",
		"derivation" : "from G3844 (παρά) and a derivative of G4632 (σκεῦος)"
	},
	"G2851" : {
		"translit" : "kólasis",
		"lemma" : "κόλασις",
		"kjv" : "punishment, torment",
		"def" : "penal infliction",
		"derivation" : "from G2849 (κολάζω)"
	},
	"G1607" : {
		"kjv" : "come (forth, out of), depart, go (forth, out), issue, proceed (out of)",
		"lemma" : "ἐκπορεύομαι",
		"translit" : "ekporeúomai",
		"derivation" : "from G1537 (ἐκ) and G4198 (πορεύομαι)",
		"def" : "to depart, be discharged, proceed, project"
	},
	"G5287" : {
		"derivation" : "from a compound of G5259 (ὑπό) and G2476 (ἵστημι)",
		"def" : "a setting under (support), i.e. (figuratively) concretely, essence, or abstractly, assurance (objectively or subjectively)",
		"kjv" : "confidence, confident, person, substance",
		"lemma" : "ὑπόστασις",
		"translit" : "hypóstasis"
	},
	"G1740" : {
		"kjv" : "glorify",
		"lemma" : "ἐνδοξάζω",
		"translit" : "endoxázō",
		"derivation" : "from G1741 (ἔνδοξος)",
		"def" : "to glorify"
	},
	"G1599" : {
		"def" : "to despatch",
		"derivation" : "from G1537 (ἐκ) and G3992 (πέμπω)",
		"lemma" : "ἐκπέμπω",
		"translit" : "ekpémpō",
		"kjv" : "send away (forth)"
	},
	"G3375" : {
		"translit" : "mḗn",
		"lemma" : "μήν",
		"kjv" : "+ surely",
		"def" : "a particle of affirmation (only with G2229 (ἦ)); assuredly",
		"derivation" : "a stronger form of G3303 (μέν)"
	},
	"G3563" : {
		"def" : "the intellect, i.e. mind (divine or human; in thought, feeling, or will); by implication, meaning",
		"derivation" : "probably from the base of G1097 (γινώσκω)",
		"translit" : "noûs",
		"lemma" : "νοῦς",
		"kjv" : "mind, understanding"
	},
	"G3461" : {
		"derivation" : "from G3463 (μύριοι)",
		"def" : "a ten-thousand; by extension, a \"myriad\" or indefinite number",
		"kjv" : "ten thousand",
		"translit" : "myriás",
		"lemma" : "μυριάς"
	},
	"G1673" : {
		"derivation" : "from G1672 (Ἕλλην)",
		"def" : "Hellenic, i.e. Grecian (in language)",
		"kjv" : "Greek",
		"lemma" : "Ἑλληνικός",
		"translit" : "Hellēnikós"
	},
	"G2096" : {
		"def" : "Eua (or Eva, i.e. Chavvah), the first woman",
		"derivation" : "of Hebrew origin (H2332)",
		"lemma" : "Εὖα",
		"translit" : "Eûa",
		"kjv" : "Eve"
	},
	"G3071" : {
		"lemma" : "Λυκαονία",
		"translit" : "Lykaonía",
		"kjv" : "Lycaonia",
		"def" : "Lycaonia, a region of Asia Minor",
		"derivation" : "perhaps remotely from G3074 (λύκος)"
	},
	"G1720" : {
		"def" : "to blow at or on",
		"derivation" : "from G1722 (ἐν) and (to puff) (compare G5453 (φύω))",
		"translit" : "emphysáō",
		"lemma" : "ἐμφυσάω",
		"kjv" : "breathe on"
	},
	"G5272" : {
		"kjv" : "condemnation, dissimulation, hypocrisy",
		"lemma" : "ὑπόκρισις",
		"translit" : "hypókrisis",
		"derivation" : "from G5271 (ὑποκρίνομαι)",
		"def" : "acting under a feigned part, i.e. (figuratively) deceit (\"hypocrisy\")"
	},
	"G707" : {
		"derivation" : "of Hebrew origin (H7414)",
		"def" : "Arimathæa (or Ramah), a place in Palestine",
		"kjv" : "Arimathæa",
		"lemma" : "Ἀριμαθαία",
		"translit" : "Arimathaía"
	},
	"G2535" : {
		"translit" : "Káïn",
		"lemma" : "Κάϊν",
		"kjv" : "Cain",
		"def" : "Cain, (i.e. Cajin), the son of Adam",
		"derivation" : "of Hebrew origin (H7014)"
	},
	"G2065" : {
		"kjv" : "ask, beseech, desire, intreat, pray",
		"lemma" : "ἐρωτάω",
		"translit" : "erōtáō",
		"derivation" : "apparently from G2046 (ἐρέω) (compare G2045 (ἐρευνάω))",
		"def" : "to interrogate; by implication, to request"
	},
	"G4282" : {
		"translit" : "proetoimázō",
		"lemma" : "προετοιμάζω",
		"kjv" : "ordain before, prepare afore",
		"def" : "to fit up in advance (literally or figuratively)",
		"derivation" : "from G4253 (πρό) and G2090 (ἑτοιμάζω)"
	},
	"G394" : {
		"translit" : "anatíthemai",
		"lemma" : "ἀνατίθεμαι",
		"kjv" : "communicate, declare",
		"def" : "to set forth (for oneself), i.e propound",
		"derivation" : "from G303 (ἀνά) and the middle voice of G5087 (τίθημι)"
	},
	"G4789" : {
		"def" : "a co-heir, i.e. (by analogy) participant in common",
		"derivation" : "from G4862 (σύν) and G2818 (κληρονόμος)",
		"translit" : "synklēronómos",
		"lemma" : "συγκληρονόμος",
		"kjv" : "fellow (joint)-heir, heir together, heir with"
	},
	"G1393" : {
		"kjv" : "Dorcas",
		"translit" : "Dorkás",
		"lemma" : "Δορκάς",
		"def" : "gazelle; Dorcas, a Christian woman"
	},
	"G3491" : {
		"kjv" : "ship",
		"lemma" : "ναῦς",
		"translit" : "naûs",
		"derivation" : "from or (to float)",
		"def" : "a boat (of any size)"
	},
	"G1137" : {
		"kjv" : "corner, quarter",
		"translit" : "gōnía",
		"lemma" : "γωνία",
		"derivation" : "probably akin to G1119 (γόνυ)",
		"def" : "an angle"
	},
	"G2375" : {
		"lemma" : "θυρεός",
		"translit" : "thyreós",
		"kjv" : "shield",
		"def" : "a large shield (as door-shaped)",
		"derivation" : "from G2374 (θύρα)"
	},
	"G2982" : {
		"def" : "lama (i.e. why)",
		"derivation" : "of Hebrew origin (H4100 with prepositional prefix)",
		"lemma" : "λαμά",
		"translit" : "lamá",
		"kjv" : "lama"
	},
	"G4668" : {
		"lemma" : "Σμυρναῖος",
		"translit" : "Smyrnaîos",
		"kjv" : "in Smyrna",
		"def" : "a Smyrnæan",
		"derivation" : "from G4667 (Σμύρνα)"
	},
	"G2041" : {
		"derivation" : "from a primary (but obsolete) (to work)",
		"def" : "toil (as an effort or occupation); by implication, an act",
		"kjv" : "deed, doing, labour, work",
		"translit" : "érgon",
		"lemma" : "ἔργον"
	},
	"G4720" : {
		"kjv" : "Stachys",
		"translit" : "Stáchys",
		"lemma" : "Στάχυς",
		"derivation" : "the same as G4719 (στάχυς)",
		"def" : "Stachys, a Christian"
	},
	"G3435" : {
		"def" : "to soil (figuratively)",
		"derivation" : "probably from G3189 (μέλας)",
		"translit" : "molýnō",
		"lemma" : "μολύνω",
		"kjv" : "defile"
	},
	"G262" : {
		"def" : "\"amaranthine\", i.e. (by implication) fadeless",
		"derivation" : "from G263 (ἀμάραντος)",
		"translit" : "amarántinos",
		"lemma" : "ἀμαράντινος",
		"kjv" : "that fadeth not away"
	},
	"G2925" : {
		"derivation" : "apparently a primary verb",
		"def" : "to rap",
		"kjv" : "knock",
		"lemma" : "κρούω",
		"translit" : "kroúō"
	},
	"G1164" : {
		"lemma" : "δεῖγμα",
		"translit" : "deîgma",
		"kjv" : "example",
		"def" : "a specimen (as shown)",
		"derivation" : "from the base of G1166 (δεικνύω)"
	},
	"G2469" : {
		"def" : "inhabitant of Kerioth; Iscariotes (i.e. Keriothite), an epithet of Judas the traitor",
		"derivation" : "of Hebrew origin (probably H377 and H7149)",
		"lemma" : "Ἰσκαριώτης",
		"translit" : "Iskariṓtēs",
		"kjv" : "Iscariot"
	},
	"G2652" : {
		"kjv" : "curse",
		"translit" : "katanáthema",
		"lemma" : "κατανάθεμα",
		"derivation" : "from G2596 (κατά) (intensive) and G331 (ἀνάθεμα)",
		"def" : "an imprecation"
	},
	"G2806" : {
		"def" : "to break (specially, of bread)",
		"derivation" : "a primary verb",
		"lemma" : "κλάω",
		"translit" : "kláō",
		"kjv" : "break"
	},
	"G4152" : {
		"kjv" : "spiritual",
		"lemma" : "πνευματικός",
		"translit" : "pneumatikós",
		"derivation" : "from G4151 (πνεῦμα)",
		"def" : "non-carnal, i.e. (humanly) ethereal (as opposed to gross), or (dæmoniacally) a spirit (concretely), or (divinely) supernatural, regenerate, religious"
	},
	"G5421" : {
		"def" : "a hole in the ground (dug for obtaining or holding water or other purposes), i.e. a cistern or well; figuratively, an abyss (as a prison)",
		"derivation" : "of uncertain derivation",
		"translit" : "phréar",
		"lemma" : "φρέαρ",
		"kjv" : "well, pit"
	},
	"G5126" : {
		"def" : "this (person, as objective of verb or preposition)",
		"derivation" : "accusative case singular masculine of G3778 (οὗτος)",
		"translit" : "toûton",
		"lemma" : "τοῦτον",
		"kjv" : "him, the same, that, this"
	},
	"G3620" : {
		"derivation" : "from the same as G3619 (οἰκοδομή)",
		"def" : "confirmation",
		"kjv" : "edifying",
		"lemma" : "οἰκοδομία",
		"translit" : "oikodomía"
	},
	"G1373" : {
		"kjv" : "thirst",
		"lemma" : "δίψος",
		"translit" : "dípsos",
		"derivation" : "of uncertain affinity",
		"def" : "thirst"
	},
	"G5583" : {
		"def" : "a falsifier",
		"derivation" : "from G5574 (ψεύδομαι)",
		"lemma" : "ψεύστης",
		"translit" : "pseústēs",
		"kjv" : "liar"
	},
	"G4452" : {
		"def" : "an enclitic particle of indefiniteness; yet, even; used only in the comparative. See G3369 (μηδέπω), G3380 (μήπω), G3764 (οὐδέπω), G3768 (οὔπω), G4455 (πώποτε)",
		"derivation" : "another form of the base of G4458 (-πώς)",
		"lemma" : "-πω",
		"translit" : "-pō"
	},
	"G2139" : {
		"def" : "well standing around, i.e. (a competitor) thwarting (a racer) in every direction (figuratively, of sin in genitive case)",
		"derivation" : "from G2095 (εὖ) and a derivative of a presumed compound of G4012 (περί) and G2476 (ἵστημι)",
		"translit" : "euperístatos",
		"lemma" : "εὐπερίστατος",
		"kjv" : "which doth so easily beset"
	},
	"G2668" : {
		"derivation" : "from G2596 (κατά) and G4126 (πλέω)",
		"def" : "to sail down upon a place, i.e. to land at",
		"kjv" : "arrive",
		"lemma" : "καταπλέω",
		"translit" : "katapléō"
	},
	"G5566" : {
		"lemma" : "χῶρος",
		"translit" : "chōros",
		"kjv" : "north west",
		"def" : "the north-west wind",
		"derivation" : "of Latin origin"
	},
	"G1639" : {
		"derivation" : "of Hebrew origin (H5867)",
		"def" : "an Elamite or Persian",
		"kjv" : "Elamite",
		"translit" : "Elamítēs",
		"lemma" : "Ἐλαμίτης"
	},
	"G3621" : {
		"derivation" : "from G3623 (οἰκονόμος)",
		"def" : "to manage (a house, i.e. an estate)",
		"kjv" : "be steward",
		"translit" : "oikonoméō",
		"lemma" : "οἰκονομέω"
	},
	"G144" : {
		"def" : "perception, i.e. (figuratively) discernment",
		"derivation" : "from G143 (αἰσθάνομαι)",
		"translit" : "aísthēsis",
		"lemma" : "αἴσθησις",
		"kjv" : "judgment"
	},
	"G787" : {
		"kjv" : "farthing",
		"lemma" : "ἀσσάριον",
		"translit" : "assárion",
		"derivation" : "of Latin origin",
		"def" : "an assarius or as, a Roman coin"
	},
	"G1155" : {
		"kjv" : "borrow, lend",
		"lemma" : "δανείζω",
		"translit" : "daneízō",
		"derivation" : "from G1156 (δάνειον)",
		"def" : "to loan on interest; reflexively, to borrow"
	},
	"G1959" : {
		"def" : "to care for (physically or otherwise)",
		"derivation" : "middle voice from G1909 (ἐπί) and the same as G3199 (μέλω)",
		"translit" : "epimeléomai",
		"lemma" : "ἐπιμελέομαι",
		"kjv" : "take care of"
	},
	"G3024" : {
		"def" : "forgetfulness",
		"derivation" : "from G2990 (λανθάνω)",
		"translit" : "lḗthē",
		"lemma" : "λήθη",
		"kjv" : "+ forget"
	},
	"G257" : {
		"kjv" : "floor",
		"translit" : "hálōn",
		"lemma" : "ἅλων",
		"derivation" : "probably from the base of G1507 (εἱλίσσω)",
		"def" : "a threshing-floor (as rolled hard), i.e. (figuratively) the grain (and chaff, as just threshed)"
	},
	"G5328" : {
		"def" : "Pharao (i.e. Pharoh), an Egyptian king",
		"derivation" : "of foreign origin (H6547)",
		"translit" : "Pharaṓ",
		"lemma" : "Φαραώ",
		"kjv" : "Pharaoh"
	},
	"G2536" : {
		"lemma" : "Καϊνάν",
		"translit" : "Kaïnán",
		"kjv" : "Cainan",
		"def" : "Cainan (i.e. Kenan), the name of two patriarchs",
		"derivation" : "of Hebrew origin (H7018)"
	},
	"G585" : {
		"lemma" : "ἀπόδειξις",
		"translit" : "apódeixis",
		"kjv" : "demonstration",
		"def" : "manifestation",
		"derivation" : "from G584 (ἀποδείκνυμι)"
	},
	"G728" : {
		"translit" : "arrhabṓn",
		"lemma" : "ἀῤῥαβών",
		"kjv" : "earnest",
		"def" : "a pledge, i.e. part of the purchase-money or property given in advance as security for the rest",
		"derivation" : "of Hebrew origin (H6162)"
	},
	"G4598" : {
		"derivation" : "from G4597 (σής) and a derivative of G977 (βιβρώσκω)",
		"def" : "moth-eaten",
		"kjv" : "motheaten",
		"lemma" : "σητόβρωτος",
		"translit" : "sētóbrōtos"
	},
	"G4024" : {
		"lemma" : "περιζώννυμι",
		"translit" : "perizṓnnymi",
		"kjv" : "gird (about, self)",
		"def" : "to gird all around, i.e. (middle voice or passive) to fasten on one's belt (literally or figuratively)",
		"derivation" : "from G4012 (περί) and G2224 (ζώννυμι)"
	},
	"G260" : {
		"derivation" : "a primary particle",
		"def" : "properly, at the \"same\" time, but freely used as a preposition or adverb denoting close association",
		"kjv" : "also, and, together, with(-al)",
		"lemma" : "ἅμα",
		"translit" : "háma"
	},
	"G2959" : {
		"lemma" : "Κυρία",
		"translit" : "Kyría",
		"kjv" : "lady",
		"def" : "Cyria, a Christian woman",
		"derivation" : "feminine of G2962 (κύριος)"
	},
	"G1641" : {
		"derivation" : "from G1640 (ἐλάσσων)",
		"def" : "to diminish, i.e. fall short",
		"kjv" : "have lack",
		"translit" : "elattonéō",
		"lemma" : "ἐλαττονέω"
	},
	"G2350" : {
		"kjv" : "make ado (a noise), trouble self, set on an uproar",
		"translit" : "thorybéō",
		"lemma" : "θορυβέω",
		"derivation" : "from G2351 (θόρυβος)",
		"def" : "to be in tumult, i.e. disturb, clamor"
	},
	"G3623" : {
		"kjv" : "chamberlain, governor, steward",
		"lemma" : "οἰκονόμος",
		"translit" : "oikonómos",
		"derivation" : "from G3624 (οἶκος) and the base of G3551 (νόμος)",
		"def" : "a house-distributor (i.e. manager), or overseer, i.e. an employee in that capacity; by extension, a fiscal agent (treasurer); figuratively, a preacher (of the Gospel)"
	},
	"G3830" : {
		"derivation" : "from the same as G3829 (πανδοχεῖον)",
		"def" : "an innkeeper (warden of a caravanserai)",
		"kjv" : "host",
		"lemma" : "πανδοχεύς",
		"translit" : "pandocheús"
	},
	"G1553" : {
		"kjv" : "be absent",
		"lemma" : "ἐκδημέω",
		"translit" : "ekdēméō",
		"derivation" : "from a compound of G1537 (ἐκ) and G1218 (δῆμος)",
		"def" : "to emigrate, i.e. (figuratively) vacate or quit"
	},
	"G2574" : {
		"derivation" : "of Hebrew origin (H1581)",
		"def" : "a \"camel\"",
		"kjv" : "camel",
		"lemma" : "κάμηλος",
		"translit" : "kámēlos"
	},
	"G2647" : {
		"kjv" : "destroy, dissolve, be guest, lodge, come to nought, overthrow, throw down",
		"lemma" : "καταλύω",
		"translit" : "katalýō",
		"derivation" : "from G2596 (κατά) and G3089 (λύω)",
		"def" : "to loosen down (disintegrate), i.e. (by implication) to demolish (literally or figuratively); specially (compare G2646 (κατάλυμα)) to halt for the night"
	},
	"G1" : {
		"def" : "the first letter of the alphabet; figuratively, only (from its use as a numeral) the first: ",
		"derivation" : "of Hebrew origin",
		"lemma" : "Α",
		"translit" : "A",
		"kjv" : "--Alpha"
	},
	"G4722" : {
		"kjv" : "(for-)bear, suffer",
		"lemma" : "στέγω",
		"translit" : "stégō",
		"derivation" : "from G4721 (στέγη)",
		"def" : "to roof over, i.e. (figuratively) to cover with silence (endure patiently)"
	},
	"G4325" : {
		"def" : "to expend additionally",
		"derivation" : "from G4314 (πρός) and G1159 (δαπανάω)",
		"translit" : "prosdapanáō",
		"lemma" : "προσδαπανάω",
		"kjv" : "spend more"
	},
	"G1082" : {
		"derivation" : "of Hebrew origin (compare H3672)",
		"def" : "Gennesaret (i.e. Kinnereth), a lake and plain in Palestine",
		"kjv" : "Gennesaret",
		"translit" : "Gennēsarét",
		"lemma" : "Γεννησαρέτ"
	},
	"G5512" : {
		"kjv" : "mock",
		"lemma" : "χλευάζω",
		"translit" : "chleuázō",
		"derivation" : "from a derivative probably of G5491 (χεῖλος)",
		"def" : "to throw out the lip, i.e. jeer at"
	},
	"G4757" : {
		"def" : "a camper-out, i.e. a (common) warrior (literally or figuratively)",
		"derivation" : "from a presumed derivative of the same as G4756 (στρατιά)",
		"lemma" : "στρατιώτης",
		"translit" : "stratiṓtēs",
		"kjv" : "soldier"
	},
	"G2560" : {
		"derivation" : "from G2556 (κακός)",
		"def" : "badly (physically or morally)",
		"kjv" : "amiss, diseased, evil, grievously, miserably, sick, sore",
		"translit" : "kakōs",
		"lemma" : "κακῶς"
	},
	"G1614" : {
		"def" : "to extend",
		"derivation" : "from G1537 (ἐκ) and teino (to stretch)",
		"lemma" : "ἐκτείνω",
		"translit" : "ekteínō",
		"kjv" : "cast, put forth, stretch forth (out)"
	},
	"G4182" : {
		"derivation" : "from G4183 (πολύς) and G4164 (ποικίλος)",
		"def" : "much variegated, i.e. multifarious",
		"kjv" : "manifold",
		"translit" : "polypoíkilos",
		"lemma" : "πολυποίκιλος"
	},
	"G1236" : {
		"kjv" : "lead life, living",
		"lemma" : "διάγω",
		"translit" : "diágō",
		"derivation" : "from G1223 (διά) and G71 (ἄγω)",
		"def" : "to pass time or life"
	},
	"G3774" : {
		"derivation" : "of Hebrew origin (H223)",
		"def" : "Urias (i.e. Urijah), a Hittite",
		"kjv" : "Urias",
		"lemma" : "Οὐρίας",
		"translit" : "Ourías"
	},
	"G2062" : {
		"derivation" : "neuter of a derivative of (to creep)",
		"def" : "a reptile, i.e. (by Hebraism (compare H7431)) a small animal",
		"kjv" : "creeping thing, serpent",
		"lemma" : "ἑρπετόν",
		"translit" : "herpetón"
	},
	"G552" : {
		"def" : "inexperienced, i.e. ignorant",
		"derivation" : "from G1 (Α) (as a negative particle) and G3984 (πεῖρα)",
		"lemma" : "ἄπειρος",
		"translit" : "ápeiros",
		"kjv" : "unskilful"
	},
	"G804" : {
		"def" : "secure (literally or figuratively)",
		"derivation" : "from G1 (Α) (as a negative particle) and sphallo (to \"fail\")",
		"lemma" : "ἀσφαλής",
		"translit" : "asphalḗs",
		"kjv" : "certain(-ty), safe, sure"
	},
	"G5319" : {
		"def" : "to render apparent (literally or figuratively)",
		"derivation" : "from G5318 (φανερός)",
		"translit" : "phaneróō",
		"lemma" : "φανερόω",
		"kjv" : "appear, manifestly declare, (make) manifest (forth), shew (self)"
	},
	"G4588" : {
		"def" : "Sergius, a Roman",
		"derivation" : "of Latin origin",
		"lemma" : "Σέργιος",
		"translit" : "Sérgios",
		"kjv" : "Sergius"
	},
	"G3408" : {
		"def" : "pay for service (literally or figuratively), good or bad",
		"derivation" : "apparently a primary word",
		"translit" : "misthós",
		"lemma" : "μισθός",
		"kjv" : "hire, reward, wages"
	},
	"G2116" : {
		"def" : "to straighten (level); technically, to steer",
		"derivation" : "from G2117 (εὐθύς)",
		"lemma" : "εὐθύνω",
		"translit" : "euthýnō",
		"kjv" : "governor, make straight"
	},
	"G2095" : {
		"kjv" : "good, well (done)",
		"lemma" : "εὖ",
		"translit" : "eû",
		"derivation" : "neuter of a primary (good)",
		"def" : "(adverbially) well"
	},
	"G4787" : {
		"lemma" : "συγκινέω",
		"translit" : "synkinéō",
		"kjv" : "stir up",
		"def" : "to move together, i.e. (specially), to excite as a mass (to sedition)",
		"derivation" : "from G4682 (σπαράσσω) and G2795 (κινέω)"
	},
	"G4832" : {
		"translit" : "sýmmorphos",
		"lemma" : "σύμμορφος",
		"kjv" : "conformed to, fashioned like unto",
		"def" : "jointly formed, i.e. (figuratively) similar",
		"derivation" : "from G4862 (σύν) and G3444 (μορφή)"
	},
	"G388" : {
		"derivation" : "from G303 (ἀνά) and G4717 (σταυρόω)",
		"def" : "to recrucify (figuratively)",
		"kjv" : "crucify afresh",
		"lemma" : "ἀνασταυρόω",
		"translit" : "anastauróō"
	},
	"G852" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G5316 (φαίνω)",
		"def" : "non-apparent)",
		"kjv" : "that is not manifest",
		"lemma" : "ἀφανής",
		"translit" : "aphanḗs"
	},
	"G2063" : {
		"translit" : "erythrós",
		"lemma" : "ἐρυθρός",
		"kjv" : "red",
		"derivation" : "of uncertain affinity; red, i.e. (with G2281 (θάλασσα)) the Red Sea"
	},
	"G1703" : {
		"def" : "a derider, i.e. (by implication) a false teacher",
		"derivation" : "from G1702 (ἐμπαίζω)",
		"translit" : "empaíktēs",
		"lemma" : "ἐμπαίκτης",
		"kjv" : "mocker, scoffer"
	},
	"G4500" : {
		"derivation" : "adverb from a derivative of (a whir)",
		"def" : "whizzingly, i.e. with a crash",
		"kjv" : "with a great noise",
		"lemma" : "ῥοιζηδόν",
		"translit" : "rhoizēdón"
	},
	"G647" : {
		"derivation" : "neuter of a (presumed) adjective from a derivative of G868 (ἀφίστημι)",
		"def" : "properly, something separative, i.e. (specially) divorce",
		"kjv" : "(writing of) divorcement",
		"lemma" : "ἀποστάσιον",
		"translit" : "apostásion"
	},
	"G5028" : {
		"derivation" : "masculine from G2290 (θάπτω)",
		"def" : "a grave (the place of interment)",
		"kjv" : "sepulchre, tomb",
		"lemma" : "τάφος",
		"translit" : "táphos"
	},
	"G5245" : {
		"translit" : "hypernikáō",
		"lemma" : "ὑπερνικάω",
		"kjv" : "more than conquer",
		"def" : "to vanquish beyond, i.e. gain a decisive victory",
		"derivation" : "from G5228 (ὑπέρ) and G3528 (νικάω)"
	},
	"G2289" : {
		"kjv" : "become dead, (cause to be) put to death, kill, mortify",
		"translit" : "thanatóō",
		"lemma" : "θανατόω",
		"derivation" : "from G2288 (θάνατος) to kill (literally or figuratively)"
	},
	"G1371" : {
		"translit" : "dichotoméō",
		"lemma" : "διχοτομέω",
		"kjv" : "cut asunder (in sunder)",
		"def" : "to bisect, i.e. (by extension) to flog severely",
		"derivation" : "from a compound of a derivative of G1364 (δίς) and a derivative of (to cut)"
	},
	"G1054" : {
		"derivation" : "from G1053 (Γαλατία)",
		"def" : "Galatic or relating to Galatia",
		"kjv" : "of Galatia",
		"lemma" : "Γαλατικός",
		"translit" : "Galatikós"
	},
	"G3463" : {
		"kjv" : "ten thousand",
		"translit" : "mýrioi",
		"lemma" : "μύριοι",
		"derivation" : "plural of an apparently primary word (properly, meaning very many)",
		"def" : "ten thousand; by extension, innumerably many"
	},
	"G800" : {
		"def" : "inharmonious (figuratively)",
		"derivation" : "from G1 (Α) (as a negative particle) and G4859 (σύμφωνος)",
		"translit" : "asýmphōnos",
		"lemma" : "ἀσύμφωνος",
		"kjv" : "agree not"
	},
	"G3453" : {
		"kjv" : "instruct",
		"translit" : "myéō",
		"lemma" : "μυέω",
		"derivation" : "from the base of G3466 (μυστήριον)",
		"def" : "to initiate, i.e. (by implication) to teach"
	},
	"G3089" : {
		"translit" : "lýō",
		"lemma" : "λύω",
		"kjv" : "break (up), destroy, dissolve, (un-)loose, melt, put off",
		"def" : "to \"loosen\" (literally or figuratively)",
		"derivation" : "a primary verb"
	},
	"G3921" : {
		"kjv" : "creep in unawares",
		"lemma" : "παρεισδύνω",
		"translit" : "pareisdýnō",
		"derivation" : "from G3844 (παρά) and a compound of G1519 (εἰς) and G1416 (δύνω)",
		"def" : "to settle in alongside, i.e. lodge stealthily"
	},
	"G5492" : {
		"derivation" : "from the same as G5494 (χειμών)",
		"def" : "to storm, i.e. (passively) to labor under a gale",
		"kjv" : "be tossed with tempest",
		"translit" : "cheimázō",
		"lemma" : "χειμάζω"
	},
	"G1409" : {
		"translit" : "Droúsilla",
		"lemma" : "Δρούσιλλα",
		"kjv" : "Drusilla",
		"def" : "Drusilla, a member of the Herodian family",
		"derivation" : "a feminine diminutive of Drusus (a Roman name)"
	},
	"G2026" : {
		"kjv" : "build thereon (thereupon, on, upon)",
		"lemma" : "ἐποικοδομέω",
		"translit" : "epoikodoméō",
		"derivation" : "from G1909 (ἐπί) and G3618 (οἰκοδομέω)",
		"def" : "to build upon, i.e. (figuratively) to rear up"
	},
	"G3828" : {
		"translit" : "Pamphylía",
		"lemma" : "Παμφυλία",
		"kjv" : "Pamphylia",
		"def" : "every-tribal, i.e. heterogeneous (G5561 (χώρα) being implied); Pamphylia, a region of Asia Minor",
		"derivation" : "from a compound of G3956 (πᾶς) and G4443 (πυρά)"
	},
	"G2172" : {
		"def" : "to wish; by implication, to pray to God",
		"derivation" : "middle voice of a primary verb",
		"translit" : "eúchomai",
		"lemma" : "εὔχομαι",
		"kjv" : "pray, will, wish"
	},
	"G3417" : {
		"translit" : "mneía",
		"lemma" : "μνεία",
		"kjv" : "mention, remembrance",
		"def" : "recollection; by implication, recital",
		"derivation" : "from G3415 (μνάομαι) or G3403 (μιμνήσκω)"
	},
	"G1271" : {
		"translit" : "diánoia",
		"lemma" : "διάνοια",
		"kjv" : "imagination, mind, understanding",
		"def" : "deep thought, properly, the faculty (mind or its disposition), by implication, its exercise",
		"derivation" : "from G1223 (διά) and G3563 (νοῦς)"
	},
	"G75" : {
		"translit" : "agōnízomai",
		"lemma" : "ἀγωνίζομαι",
		"kjv" : "fight, labor fervently, strive",
		"def" : "to struggle, literally (to compete for a prize), figuratively (to contend with an adversary), or genitive case (to endeavor to accomplish something)",
		"derivation" : "from G73 (ἀγών)"
	},
	"G3633" : {
		"kjv" : "suppose, think",
		"lemma" : "οἴομαι",
		"translit" : "oíomai",
		"derivation" : "middle voice apparently from G3634 (οἷος)",
		"def" : "to make like (oneself), i.e. imagine (be of the opinion)"
	},
	"G2740" : {
		"kjv" : "be burned",
		"lemma" : "καῦσις",
		"translit" : "kaûsis",
		"derivation" : "from G2545 (καίω)",
		"def" : "burning (the act)"
	},
	"G5154" : {
		"lemma" : "τρίτος",
		"translit" : "trítos",
		"kjv" : "third(-ly)",
		"def" : "third; neuter (as noun) a third part, or (as adverb) a (or the) third time, thirdly",
		"derivation" : "ordinal from G5140 (τρεῖς)"
	},
	"G4236" : {
		"kjv" : "meekness",
		"translit" : "praiótēs",
		"lemma" : "πρᾳότης",
		"derivation" : "from G4235 (πρᾷος)",
		"def" : "gentleness, by implication, humility"
	},
	"G5532" : {
		"derivation" : "from the base of G5530 (χράομαι) or G5534 (χρή)",
		"def" : "employment, i.e. an affair; also (by implication) occasion, demand, requirement or destitution",
		"kjv" : "business, lack, necessary(-ity), need(-ful), use, want",
		"translit" : "chreía",
		"lemma" : "χρεία"
	},
	"G1516" : {
		"lemma" : "εἰρηνικός",
		"translit" : "eirēnikós",
		"kjv" : "peaceable",
		"def" : "pacific; by implication, salutary",
		"derivation" : "from G1515 (εἰρήνη)"
	},
	"G5253" : {
		"derivation" : "neuter of a derivative of G5228 (ὑπέρ)",
		"def" : "a higher part of the house, i.e. apartment in the third story",
		"kjv" : "upper chamber (room)",
		"translit" : "hyperōion",
		"lemma" : "ὑπερῷον"
	},
	"G2384" : {
		"translit" : "Iakṓb",
		"lemma" : "Ἰακώβ",
		"kjv" : "also an Israelite:--Jacob",
		"def" : "Jacob (i.e. Ja`akob), the progenitor of the Israelites",
		"derivation" : "of Hebrew origin (H3290)"
	},
	"G1153" : {
		"kjv" : "Damascene",
		"translit" : "Damaskēnós",
		"lemma" : "Δαμασκηνός",
		"derivation" : "from G1154 (Δαμασκός)",
		"def" : "a Damascene or inhabitant of Damascus"
	},
	"G3628" : {
		"lemma" : "οἰκτιρμός",
		"translit" : "oiktirmós",
		"kjv" : "mercy",
		"def" : "pity",
		"derivation" : "from G3627 (οἰκτείρω)"
	},
	"G1122" : {
		"kjv" : "scribe, town-clerk",
		"translit" : "grammateús",
		"lemma" : "γραμματεύς",
		"derivation" : "from G1121 (γράμμα). a writer, i.e. (professionally) scribe or secretary"
	},
	"G5390" : {
		"def" : "with friendliness of mind, i.e. kindly",
		"derivation" : "adverb from G5391 (φιλόφρων)",
		"translit" : "philophrónōs",
		"lemma" : "φιλοφρόνως",
		"kjv" : "courteously"
	},
	"G1593" : {
		"derivation" : "from G1537 (ἐκ) and G3506 (νεύω)",
		"def" : "(by analogy) to slip off, i.e. quietly withdraw",
		"kjv" : "convey self away",
		"translit" : "ekneúō",
		"lemma" : "ἐκνεύω"
	},
	"G112" : {
		"kjv" : "without God",
		"lemma" : "ἄθεος",
		"translit" : "átheos",
		"derivation" : "from G1 (Α) (as a negative particle) and G2316 (θεός)",
		"def" : "godless"
	},
	"G1742" : {
		"lemma" : "ἔνδυμα",
		"translit" : "éndyma",
		"kjv" : "clothing, garment, raiment",
		"def" : "apparel (especially the outer robe)",
		"derivation" : "from G1746 (ἐνδύω)"
	},
	"G2582" : {
		"def" : "Candacè, an Egyptian queen",
		"derivation" : "of foreign origin",
		"lemma" : "Κανδάκη",
		"translit" : "Kandákē",
		"kjv" : "Candace"
	},
	"G1347" : {
		"def" : "aquittal (for Christ's sake)",
		"derivation" : "from G1344 (δικαιόω)",
		"lemma" : "δικαίωσις",
		"translit" : "dikaíōsis",
		"kjv" : "justification"
	},
	"G304" : {
		"kjv" : "stairs",
		"lemma" : "ἀναβαθμός",
		"translit" : "anabathmós",
		"derivation" : "from G305 (ἀναβαίνω) (compare G898 (βαθμός))",
		"def" : "a stairway"
	},
	"G2906" : {
		"kjv" : "clamour, cry(-ing)",
		"lemma" : "κραυγή",
		"translit" : "kraugḗ",
		"derivation" : "from G2896 (κράζω)",
		"def" : "an outcry (in notification, tumult or grief)"
	},
	"G1094" : {
		"derivation" : "akin to G1088 (γέρων)",
		"def" : "senility",
		"kjv" : "old age",
		"translit" : "gēras",
		"lemma" : "γῆρας"
	},
	"G1386" : {
		"kjv" : "deceitful",
		"lemma" : "δόλιος",
		"translit" : "dólios",
		"derivation" : "from G1388 (δόλος)",
		"def" : "guileful"
	},
	"G931" : {
		"lemma" : "βάσανος",
		"translit" : "básanos",
		"kjv" : "torment",
		"def" : "a touch-stone, i.e. (by analogy) torture",
		"derivation" : "perhaps remotely from the same as G939 (βάσις) (through the notion of going to the bottom)"
	},
	"G177" : {
		"translit" : "akatakályptos",
		"lemma" : "ἀκατακάλυπτος",
		"kjv" : "uncovered",
		"def" : "unveiled",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of a compound of G2596 (κατά) and G2572 (καλύπτω)"
	},
	"G4526" : {
		"def" : "\"sack\"-cloth, i.e. mohair (the material or garments made of it, worn as a sign of grief)",
		"derivation" : "of Hebrew origin (H8242)",
		"lemma" : "σάκκος",
		"translit" : "sákkos",
		"kjv" : "sackcloth"
	},
	"G2919" : {
		"def" : "by implication, to try, condemn, punish",
		"derivation" : "properly, to distinguish, i.e. decide (mentally or judicially)",
		"translit" : "krínō",
		"lemma" : "κρίνω",
		"kjv" : "avenge, conclude, condemn, damn, decree, determine, esteem, judge, go to (sue at the) law, ordain, call in question, sentence to, think"
	},
	"G2060" : {
		"lemma" : "Ἑρμῆς",
		"translit" : "Hermēs",
		"kjv" : "Hermes, Mercury",
		"def" : "Hermes, the name of the messenger of the Greek deities; also of a Christian",
		"derivation" : "perhaps from G2046 (ἐρέω)"
	},
	"G2066" : {
		"kjv" : "apparel, clothing, raiment, robe",
		"lemma" : "ἐσθής",
		"translit" : "esthḗs",
		"derivation" : "from (to clothe)",
		"def" : "dress"
	},
	"G4826" : {
		"kjv" : "Simeon, Simon",
		"lemma" : "Συμεών",
		"translit" : "Symeṓn",
		"derivation" : "from the same as G4613 (Σίμων)",
		"def" : "Symeon (i.e. Shimon), the name of five Israelites"
	},
	"G3398" : {
		"derivation" : "apparently a primary word",
		"def" : "small (in size, quantity, number or (figuratively) dignity)",
		"kjv" : "least, less, little, small",
		"translit" : "mikrós",
		"lemma" : "μικρός"
	},
	"G3402" : {
		"kjv" : "follower",
		"lemma" : "μιμητής",
		"translit" : "mimētḗs",
		"derivation" : "from G3401 (μιμέομαι)",
		"def" : "an imitator"
	},
	"G2528" : {
		"derivation" : "from G2596 (κατά)",
		"def" : "and G3695 (ὁπλίζω); to equip fully with armor",
		"kjv" : "arm",
		"translit" : "kathoplízō",
		"lemma" : "καθοπλίζω"
	},
	"G3428" : {
		"lemma" : "μοιχαλίς",
		"translit" : "moichalís",
		"kjv" : "adulteress(-ous, -y)",
		"def" : "an adulteress (literally or figuratively)",
		"derivation" : "a prolonged form of the feminine of G3432 (μοιχός)"
	},
	"G5446" : {
		"kjv" : "natural",
		"translit" : "physikós",
		"lemma" : "φυσικός",
		"derivation" : "from G5449 (φύσις)",
		"def" : "\"physical\", i.e. (by implication) instinctive"
	},
	"G1826" : {
		"def" : "to issue, i.e. leave (a place), escape (to the shore)",
		"derivation" : "from G1537 (ἐκ) and (to go)",
		"translit" : "éxeimi",
		"lemma" : "ἔξειμι",
		"kjv" : "depart, get (to land), go out"
	},
	"G4920" : {
		"derivation" : "from G4862 (σύν) and (to send)",
		"def" : "to put together, i.e. (mentally) to comprehend; by implication, to act piously",
		"kjv" : "consider, understand, be wise",
		"lemma" : "συνίημι",
		"translit" : "syníēmi"
	},
	"G4484" : {
		"translit" : "Rhḗgion",
		"lemma" : "Ῥήγιον",
		"kjv" : "Rhegium",
		"def" : "Rhegium, a place in Italy",
		"derivation" : "of Latin origin"
	},
	"G5382" : {
		"derivation" : "from G5384 (φίλος) and G3581 (ξένος)",
		"def" : "fond of guests, i.e. hospitable",
		"kjv" : "given to (lover of, use) hospitality",
		"translit" : "philóxenos",
		"lemma" : "φιλόξενος"
	},
	"G5045" : {
		"derivation" : "from the base of G5098 (τιμωρία)",
		"def" : "an artificer (as producer of fabrics), i.e. (specially), a craftsman in wood",
		"kjv" : "carpenter",
		"translit" : "téktōn",
		"lemma" : "τέκτων"
	},
	"G2332" : {
		"def" : "Thessalonice, a place in Asia Minor",
		"derivation" : "from (a Thessalian) and G3529 (νίκη)",
		"translit" : "Thessaloníkē",
		"lemma" : "Θεσσαλονίκη",
		"kjv" : "Thessalonica"
	},
	"G155" : {
		"derivation" : "from G154 (αἰτέω)",
		"def" : "a thing asked or (abstractly) an asking",
		"kjv" : "petition, request, required",
		"translit" : "aítēma",
		"lemma" : "αἴτημα"
	},
	"G2613" : {
		"derivation" : "from G2596 (κατά) and a derivative of G1349 (δίκη)",
		"def" : "to adjudge against, i.e. pronounce guilty",
		"kjv" : "condemn",
		"lemma" : "καταδικάζω",
		"translit" : "katadikázō"
	},
	"G1773" : {
		"lemma" : "ἔννυχον",
		"translit" : "énnychon",
		"kjv" : "before day",
		"def" : "(adverbially) by night",
		"derivation" : "neuter of a compound of G1722 (ἐν) and G3571 (νύξ)"
	},
	"G5474" : {
		"kjv" : "fine brass",
		"lemma" : "χαλκολίβανον",
		"translit" : "chalkolíbanon",
		"derivation" : "neuter of a compound of G5475 (χαλκός) and G3030 (λίβανος) (in the implied mean of whiteness or brilliancy)",
		"def" : "burnished copper, an alloy of copper (or gold) and silver having a brilliant lustre"
	},
	"G2707" : {
		"lemma" : "καταφροντής",
		"translit" : "kataphrontḗs",
		"kjv" : "despiser",
		"def" : "a contemner",
		"derivation" : "from G2706 (καταφρονέω)"
	},
	"G3855" : {
		"lemma" : "παράγω",
		"translit" : "parágō",
		"kjv" : "depart, pass (away, by, forth)",
		"def" : "to lead near, i.e. (reflexively or intransitively) to go along or away",
		"derivation" : "from G3844 (παρά) and G71 (ἄγω)"
	},
	"G1845" : {
		"lemma" : "ἐξορκιστής",
		"translit" : "exorkistḗs",
		"kjv" : "exorcist",
		"def" : "one that binds by an oath (or spell), i.e. (by implication) an \"exorcist\" (conjurer)",
		"derivation" : "from G1844 (ἐξορκίζω)"
	},
	"G5371" : {
		"def" : "friendly; Philemon, a Christian",
		"derivation" : "from G5368 (φιλέω)",
		"lemma" : "Φιλήμων",
		"translit" : "Philḗmōn",
		"kjv" : "Philemon"
	},
	"G4069" : {
		"kjv" : "Persis",
		"translit" : "Persís",
		"lemma" : "Περσίς",
		"def" : "a Persian woman; Persis, a Christian female"
	},
	"G31" : {
		"derivation" : "from G32 (ἄγγελος)",
		"def" : "an announcement, i.e. (by implication) precept",
		"kjv" : "message",
		"translit" : "angelía",
		"lemma" : "ἀγγελία"
	},
	"G1605" : {
		"def" : "to strike with astonishment",
		"derivation" : "from G1537 (ἐκ) and G4141 (πλήσσω)",
		"translit" : "ekplḗssō",
		"lemma" : "ἐκπλήσσω",
		"kjv" : "amaze, astonish"
	},
	"G4241" : {
		"lemma" : "πρέπω",
		"translit" : "prépō",
		"kjv" : "become, comely",
		"def" : "to tower up (be conspicuous), i.e. (by implication) to be suitable or proper (third person singular present indicative, often used impersonally, it is fit or right)",
		"derivation" : "apparently a primary verb"
	},
	"G409" : {
		"lemma" : "ἀνδροφόνος",
		"translit" : "androphónos",
		"kjv" : "manslayer",
		"def" : "a murderer",
		"derivation" : "from G435 (ἀνήρ) and G5408 (φόνος)"
	},
	"G3307" : {
		"def" : "to part, i.e. (literally) to apportion, bestow, share, or (figuratively) to disunite, differ",
		"derivation" : "from G3313 (μέρος)",
		"translit" : "merízō",
		"lemma" : "μερίζω",
		"kjv" : "deal, be difference between, distribute, divide, give participle "
	},
	"G4365" : {
		"def" : "to journey towards, i.e. approach (not the same as G4313 (προπορεύομαι))",
		"derivation" : "from G4314 (πρός) and G4198 (πορεύομαι)",
		"lemma" : "προσπορεύομαι",
		"translit" : "prosporeúomai",
		"kjv" : "go before"
	},
	"G4915" : {
		"derivation" : "from G4862 (σύν) and a derivative of G2244 (ἡλικία)",
		"def" : "a co-aged person, i.e. alike in years",
		"kjv" : "equal",
		"lemma" : "συνηλικιώτης",
		"translit" : "synēlikiṓtēs"
	},
	"G1020" : {
		"def" : "to sail slowly",
		"derivation" : "from G1021 (βραδύς) and a prolonged form of G4126 (πλέω)",
		"translit" : "bradyploéō",
		"lemma" : "βραδυπλοέω",
		"kjv" : "sail slowly"
	},
	"G3467" : {
		"derivation" : "from a compound of the base of G3466 (μυστήριον) and (the face",
		"def" : "from G3700 (ὀπτάνομαι)); to shut the eyes, i.e. blink (see indistinctly)",
		"kjv" : "cannot see far off",
		"translit" : "myōpázō",
		"lemma" : "μυωπάζω"
	},
	"G2948" : {
		"derivation" : "from the same as G2947 (κυλιόω)",
		"def" : "rocking about, i.e. crippled (maimed, in feet or hands)",
		"kjv" : "maimed",
		"lemma" : "κυλλός",
		"translit" : "kyllós"
	},
	"G2227" : {
		"lemma" : "ζωοποιέω",
		"translit" : "zōopoiéō",
		"kjv" : "make alive, give life, quicken",
		"def" : "to (re-)vitalize (literally or figuratively)",
		"derivation" : "from the same as G2226 (ζῶον) and G4160 (ποιέω)"
	},
	"G3606" : {
		"derivation" : "from G3739 (ὅς) with the directive enclitic of source",
		"def" : "from which place or source or cause (adverb or conjunction)",
		"kjv" : "from thence, (from) whence, where(-by, -fore, -upon)",
		"translit" : "hóthen",
		"lemma" : "ὅθεν"
	},
	"G3527" : {
		"translit" : "Nikánōr",
		"lemma" : "Νικάνωρ",
		"kjv" : "Nicanor",
		"def" : "victorious; Nicanor, a Christian",
		"derivation" : "probably from G3528 (νικάω)"
	},
	"G1024" : {
		"translit" : "brachýs",
		"lemma" : "βραχύς",
		"kjv" : "few words, little (space, while)",
		"def" : "short (of time, place, quantity, or number)",
		"derivation" : "of uncertain affinity"
	},
	"G4630" : {
		"derivation" : "apparently of Latin origin",
		"def" : "left-handed; Scevas (i.e. Scævus), an Israelite",
		"kjv" : "Sceva",
		"translit" : "Skeuâs",
		"lemma" : "Σκευᾶς"
	},
	"G3677" : {
		"derivation" : "of uncertain derivation",
		"def" : "a dream",
		"kjv" : "dream",
		"translit" : "ónar",
		"lemma" : "ὄναρ"
	},
	"G3682" : {
		"derivation" : "from G3685 (ὀνίνημι)",
		"def" : "profitable; Onesimus, a Christian",
		"kjv" : "Onesimus",
		"lemma" : "Ὀνήσιμος",
		"translit" : "Onḗsimos"
	},
	"G1081" : {
		"derivation" : "from G1080 (γεννάω)",
		"def" : "offspring; by analogy, produce (literally or figuratively)",
		"kjv" : "fruit, generation",
		"translit" : "génnēma",
		"lemma" : "γέννημα"
	},
	"G5350" : {
		"kjv" : "speak",
		"translit" : "phthéngomai",
		"lemma" : "φθέγγομαι",
		"derivation" : "probably akin to G5338 (φέγγος) and thus to G5346 (φημί)",
		"def" : "to utter a clear sound, i.e. (generally) to proclaim"
	},
	"G3340" : {
		"derivation" : "from G3326 (μετά) and G3539 (νοιέω)",
		"def" : "to think differently or afterwards, i.e. reconsider (morally, feel compunction)",
		"kjv" : "repent",
		"translit" : "metanoéō",
		"lemma" : "μετανοέω"
	},
	"G3047" : {
		"kjv" : "southwest",
		"translit" : "líps",
		"lemma" : "λίψ",
		"derivation" : "probably from (to pour a \"libation\")",
		"def" : "the south(- west) wind (as bringing rain, i.e. (by extension) the south quarter)"
	},
	"G2484" : {
		"kjv" : "Ituræa",
		"translit" : "Itouraḯa",
		"lemma" : "Ἰτουραΐα",
		"derivation" : "of Hebrew origin (H3195)",
		"def" : "Ituræa (i.e. Jetur), a region of Palestine"
	},
	"G3668" : {
		"kjv" : "likewise, so",
		"lemma" : "ὁμοίως",
		"translit" : "homoíōs",
		"derivation" : "adverb from G3664 (ὅμοιος)",
		"def" : "similarly"
	},
	"G837" : {
		"kjv" : "grow (up), (give the) increase",
		"translit" : "auxánō",
		"lemma" : "αὐξάνω",
		"derivation" : "a prolonged form of a primary verb",
		"def" : "to grow (\"wax\"), i.e. enlarge (literal or figurative, active or passive)"
	},
	"G2234" : {
		"translit" : "hēdéōs",
		"lemma" : "ἡδέως",
		"kjv" : "gladly",
		"def" : "sweetly, i.e. (figuratively) with pleasure",
		"derivation" : "adverb from a derivative of the base of G2237 (ἡδονή)"
	},
	"G840" : {
		"kjv" : "austere",
		"translit" : "austērós",
		"lemma" : "αὐστηρός",
		"derivation" : "from a (presumed) derivative of the same as G109 (ἀήρ) (meaning blown)",
		"def" : "rough (properly as a gale), i.e. (figuratively) severe"
	},
	"G5398" : {
		"translit" : "phoberós",
		"lemma" : "φοβερός",
		"kjv" : "fearful, terrible",
		"def" : "frightful, i.e. (objectively) formidable",
		"derivation" : "from G5401 (φόβος)"
	},
	"G47" : {
		"lemma" : "ἁγνεία",
		"translit" : "hagneía",
		"kjv" : "purity",
		"def" : "cleanliness (the quality), i.e. (specially) chastity",
		"derivation" : "from G53 (ἁγνός)"
	},
	"G5411" : {
		"def" : "a load (as borne), i.e. (figuratively) a tax (properly, an individual assessment on persons or property; whereas G5056 (τέλος) is usually a general toll on goods or travel)",
		"derivation" : "from G5342 (φέρω)",
		"lemma" : "φόρος",
		"translit" : "phóros",
		"kjv" : "tribute"
	},
	"G547" : {
		"lemma" : "ἀπειλή",
		"translit" : "apeilḗ",
		"kjv" : "X straitly, threatening",
		"def" : "a menace",
		"derivation" : "from G546 (ἀπειλέω)"
	},
	"G2305" : {
		"derivation" : "from G2304 (θεῖος)",
		"def" : "divinity (abstractly)",
		"kjv" : "godhead",
		"translit" : "theiótēs",
		"lemma" : "θειότης"
	},
	"G270" : {
		"def" : "properly, to collect, i.e. (by implication) reap",
		"derivation" : "from G260 (ἅμα)",
		"lemma" : "ἀμάω",
		"translit" : "amáō",
		"kjv" : "reap down"
	},
	"G5183" : {
		"derivation" : "from G5184 (Τύρος)",
		"def" : "a Tyrian, i.e. inhabitant of Tyrus",
		"kjv" : "of Tyre",
		"translit" : "Týrios",
		"lemma" : "Τύριος"
	},
	"G322" : {
		"kjv" : "appoint, shew",
		"lemma" : "ἀναδείκνυμι",
		"translit" : "anadeíknymi",
		"derivation" : "from G303 (ἀνά) and G1166 (δεικνύω)",
		"def" : "to exhibit, i.e. (by implication) to indicate, appoint"
	},
	"G4339" : {
		"kjv" : "proselyte",
		"lemma" : "προσήλυτος",
		"translit" : "prosḗlytos",
		"derivation" : "from the alternate of G4334 (προσέρχομαι)",
		"def" : "an arriver from a foreign region, i.e. (specially), an acceder (convert) to Judaism (\"proselyte\")"
	},
	"G5231" : {
		"derivation" : "from G5228 (ὑπέρ) and G507 (ἄνω)",
		"def" : "above upward, i.e. greatly higher (in place or rank)",
		"kjv" : "far above, over",
		"translit" : "hyperánō",
		"lemma" : "ὑπεράνω"
	},
	"G5378" : {
		"lemma" : "Φιλόλογος",
		"translit" : "Philólogos",
		"kjv" : "Philologus",
		"def" : "fond of words, i.e. talkative (argumentative, learned, \"philological\"); Philologus, a Christian",
		"derivation" : "from G5384 (φίλος) and G3056 (λόγος)"
	},
	"G1706" : {
		"derivation" : "from G1722 (ἐν) and G4098 (πίπτω)",
		"def" : "to fall on, i.e. (literally) to be entrapped by, or (figuratively) be overwhelmed with",
		"kjv" : "fall among (into)",
		"lemma" : "ἐμπίπτω",
		"translit" : "empíptō"
	},
	"G4897" : {
		"derivation" : "from G4862 (σύν) and G1525 (εἰσέρχομαι)",
		"def" : "to enter in company with",
		"kjv" : "go in with, go with into",
		"translit" : "syneisérchomai",
		"lemma" : "συνεισέρχομαι"
	},
	"G2108" : {
		"def" : "beneficence (genitive case or specially)",
		"derivation" : "from G2110 (εὐεργέτης)",
		"translit" : "euergesía",
		"lemma" : "εὐεργεσία",
		"kjv" : "benefit, good deed done"
	},
	"G1811" : {
		"def" : "to follow out, i.e. (figuratively) to imitate, obey, yield to",
		"derivation" : "from G1537 (ἐκ) and G190 (ἀκολουθέω)",
		"lemma" : "ἐξακολουθέω",
		"translit" : "exakolouthéō",
		"kjv" : "follow"
	},
	"G2719" : {
		"lemma" : "κατεσθίω",
		"translit" : "katesthíō",
		"kjv" : "devour",
		"def" : "to eat down, i.e. devour (literally or figuratively)",
		"derivation" : "from G2596 (κατά) and G2068 (ἐσθίω) (including its alternate)"
	},
	"G5473" : {
		"def" : "a copper dish",
		"derivation" : "diminutive from G5475 (χαλκός)",
		"translit" : "chalkíon",
		"lemma" : "χαλκίον",
		"kjv" : "brazen vessel"
	},
	"G3851" : {
		"kjv" : "not (to) regard(-ing)",
		"translit" : "parabouleúomai",
		"lemma" : "παραβουλεύομαι",
		"derivation" : "from G3844 (παρά) and the middle voice of G1011 (βουλεύω)",
		"def" : "to misconsult, i.e. disregard"
	},
	"G5020" : {
		"kjv" : "cast down to hell",
		"translit" : "tartaróō",
		"lemma" : "ταρταρόω",
		"derivation" : "from (the deepest abyss of Hades)",
		"def" : "to incarcerate in eternal torment"
	},
	"G752" : {
		"kjv" : "(chief) ruler of the synagogue",
		"lemma" : "ἀρχισυνάγωγος",
		"translit" : "archisynágōgos",
		"derivation" : "from G746 (ἀρχή) and G4864 (συναγωγή)",
		"def" : "director of the synagogue services"
	},
	"G5113" : {
		"def" : "a daring (audacious) man",
		"derivation" : "from G5111 (τολμάω)",
		"lemma" : "τολμητής",
		"translit" : "tolmētḗs",
		"kjv" : "presumptuous"
	},
	"G2132" : {
		"lemma" : "εὐνοέω",
		"translit" : "eunoéō",
		"kjv" : "agree",
		"def" : "to be well- minded, i.e. reconcile",
		"derivation" : "from a compound of G2095 (εὖ) and G3563 (νοῦς)"
	},
	"G1872" : {
		"translit" : "epakolouthéō",
		"lemma" : "ἐπακολουθέω",
		"kjv" : "follow (after)",
		"def" : "to accompany",
		"derivation" : "from G1909 (ἐπί) and G190 (ἀκολουθέω)"
	},
	"G3581" : {
		"def" : "foreign (literally, alien, or figuratively, novel); by implication, a guest or (vice-versa) entertainer",
		"derivation" : "apparently a primary word",
		"translit" : "xénos",
		"lemma" : "ξένος",
		"kjv" : "host, strange(-r)"
	},
	"G903" : {
		"def" : "Balaam, a Mesopotamian (symbolic of a false teacher)",
		"derivation" : "of Hebrew origin (H1109)",
		"lemma" : "Βαλαάμ",
		"translit" : "Balaám",
		"kjv" : "Balaam"
	},
	"G4041" : {
		"kjv" : "peculiar",
		"lemma" : "περιούσιος",
		"translit" : "perioúsios",
		"derivation" : "from the present participle feminine of a compound of 4012 and G1510 (εἰμί)",
		"def" : "being beyond usual, i.e. special (one's own)"
	},
	"G3038" : {
		"lemma" : "λιθόστρωτος",
		"translit" : "lithóstrōtos",
		"kjv" : "Pavement",
		"def" : "stone-strewed, i.e. a tessellated mosaic on which the Roman tribunal was placed",
		"derivation" : "from G3037 (λίθος) and a derivative of G4766 (στρώννυμι)"
	},
	"G739" : {
		"lemma" : "ἄρτιος",
		"translit" : "ártios",
		"kjv" : "perfect",
		"def" : "fresh, i.e. (by implication) complete",
		"derivation" : "from G737 (ἄρτι)"
	},
	"G4739" : {
		"lemma" : "στήκω",
		"translit" : "stḗkō",
		"kjv" : "stand (fast)",
		"def" : "to be stationary, i.e. (figuratively) to persevere",
		"derivation" : "from the perfect tense of G2476 (ἵστημι)"
	},
	"G3507" : {
		"derivation" : "from G3509 (νέφος)",
		"def" : "properly, cloudiness, i.e. (concretely) a cloud",
		"kjv" : "cloud",
		"lemma" : "νεφέλη",
		"translit" : "nephélē"
	},
	"G3899" : {
		"kjv" : "go, pass (by)",
		"lemma" : "παραπορεύομαι",
		"translit" : "paraporeúomai",
		"derivation" : "from G3844 (παρά) and G4198 (πορεύομαι)",
		"def" : "to travel near"
	},
	"G3464" : {
		"derivation" : "probably of foreign origin (compare H4753, G4666 (σμύρνα))",
		"def" : "\"myrrh\", i.e. (by implication) perfumed oil",
		"kjv" : "ointment",
		"lemma" : "μύρον",
		"translit" : "mýron"
	},
	"G3115" : {
		"kjv" : "longsuffering, patience",
		"lemma" : "μακροθυμία",
		"translit" : "makrothymía",
		"derivation" : "from the same as G3116 (μακροθυμώς)",
		"def" : "longanimity, i.e. (objectively) forbearance or (subjectively) fortitude"
	},
	"G1070" : {
		"translit" : "geláō",
		"lemma" : "γελάω",
		"kjv" : "laugh",
		"def" : "to laugh (as a sign of joy or satisfaction)",
		"derivation" : "of uncertain affinity"
	},
	"G4134" : {
		"def" : "replete, or covered over; by analogy, complete",
		"derivation" : "from G4130 (πλήθω)",
		"translit" : "plḗrēs",
		"lemma" : "πλήρης",
		"kjv" : "full"
	},
	"G1876" : {
		"derivation" : "neuter of a presumed compound of G1909 (ἐπί) and G318 (ἀνάγκη)",
		"def" : "(adverbially) on necessity, i.e. necessarily",
		"kjv" : "necessary",
		"lemma" : "ἐπάναγκες",
		"translit" : "epánankes"
	},
	"G496" : {
		"kjv" : "resist",
		"lemma" : "ἀντιπίπτω",
		"translit" : "antipíptō",
		"derivation" : "from G473 (ἀντί) and G4098 (πίπτω) (including its alternate)",
		"def" : "to oppose"
	},
	"G390" : {
		"kjv" : "abide, behave self, have conversation, live, overthrow, pass, return, be used",
		"translit" : "anastréphō",
		"lemma" : "ἀναστρέφω",
		"derivation" : "from G303 (ἀνά) and G4762 (στρέφω)",
		"def" : "to overturn; also to return; by implication, to busy oneself, i.e. remain, live"
	},
	"G1206" : {
		"def" : "secondary, i.e. (specially) on the second day",
		"derivation" : "from G1208 (δεύτερος)",
		"lemma" : "δευτεραῖος",
		"translit" : "deuteraîos",
		"kjv" : "next day"
	},
	"G4538" : {
		"kjv" : "trumpeter",
		"translit" : "salpistḗs",
		"lemma" : "σαλπιστής",
		"derivation" : "from G4537 (σαλπίζω)",
		"def" : "a trumpeter"
	},
	"G3734" : {
		"kjv" : "bound",
		"translit" : "horothesía",
		"lemma" : "ὁροθεσία",
		"derivation" : "from a compound of the base of G3725 (ὅριον) and a derivative of G5087 (τίθημι)",
		"def" : "a limit-placing, i.e. (concretely) boundary-line"
	},
	"G1391" : {
		"kjv" : "dignity, glory(-ious), honour, praise, worship",
		"lemma" : "δόξα",
		"translit" : "dóxa",
		"derivation" : "from the base of G1380 (δοκέω)",
		"def" : "glory (as very apparent), in a wide application (literal or figurative, objective or subjective)"
	},
	"G3427" : {
		"derivation" : "the simpler form of G1698 (ἐμοί)",
		"def" : "to me",
		"kjv" : "I, me, mine, my",
		"lemma" : "μοί",
		"translit" : "moí"
	},
	"G4501" : {
		"lemma" : "ῥομφαία",
		"translit" : "rhomphaía",
		"kjv" : "sword",
		"def" : "a sabre, i.e. a long and broad cutlass (any weapon of the kind, literally or figuratively)",
		"derivation" : "probably of foreign origin"
	},
	"G5136" : {
		"def" : "to seize by the throat or neck, i.e. to expose the gullet of a victim for killing (generally, to lay bare)",
		"derivation" : "from G5137 (τράχηλος)",
		"translit" : "trachēlízō",
		"lemma" : "τραχηλίζω",
		"kjv" : "opened"
	},
	"G535" : {
		"lemma" : "ἀπαρτισμός",
		"translit" : "apartismós",
		"kjv" : "finishing",
		"def" : "completion",
		"derivation" : "from a derivative of G534 (ἀπάρτι)"
	},
	"G5471" : {
		"translit" : "chalkeús",
		"lemma" : "χαλκεύς",
		"kjv" : "coppersmith",
		"def" : "a copper-worker or brazier",
		"derivation" : "from G5475 (χαλκός)"
	},
	"G2726" : {
		"def" : "demureness, i.e. (by implication) sadness",
		"derivation" : "from a compound of G2596 (κατά) and perhaps a derivative of the base of G5316 (φαίνω) (meaning downcast in look)",
		"lemma" : "κατήφεια",
		"translit" : "katḗpheia",
		"kjv" : "heaviness"
	},
	"G5484" : {
		"lemma" : "χάριν",
		"translit" : "chárin",
		"kjv" : "be-(for) cause of, for sake of, +…fore, X reproachfully",
		"def" : "through favor of, i.e. on account of",
		"derivation" : "accusative case of G5485 (χάρις) as preposition"
	},
	"G5234" : {
		"def" : "excessively",
		"derivation" : "adverb from present participle active of G5235 (ὑπερβάλλω)",
		"lemma" : "ὑπερβαλλόντως",
		"translit" : "hyperballóntōs",
		"kjv" : "beyond measure"
	},
	"G898" : {
		"lemma" : "βαθμός",
		"translit" : "bathmós",
		"kjv" : "degree",
		"def" : "a step, i.e. (figuratively) grade (of dignity)",
		"derivation" : "from the same as G899 (βάθος)"
	},
	"G1055" : {
		"derivation" : "of uncertain derivation",
		"def" : "tranquillity",
		"kjv" : "calm",
		"translit" : "galḗnē",
		"lemma" : "γαλήνη"
	},
	"G3186" : {
		"def" : "still larger (figuratively)",
		"derivation" : "continued comparative of G3187 (μείζων)",
		"translit" : "meizóteros",
		"lemma" : "μειζότερος",
		"kjv" : "greater"
	},
	"G2782" : {
		"derivation" : "from G2784 (κηρύσσω)",
		"def" : "a proclamation (especially of the gospel; by implication, the gospel itself)",
		"kjv" : "preaching",
		"lemma" : "κήρυγμα",
		"translit" : "kḗrygma"
	},
	"G4863" : {
		"def" : "to lead together, i.e. collect or convene; specially, to entertain (hospitably)",
		"derivation" : "from G4862 (σύν) and G71 (ἄγω)",
		"translit" : "synágō",
		"lemma" : "συνάγω",
		"kjv" : "+ accompany, assemble (selves, together), bestow, come together, gather (selves together, up, together), lead into, resort, take in"
	},
	"G2023" : {
		"translit" : "epichorēgéō",
		"lemma" : "ἐπιχορηγέω",
		"kjv" : "add, minister (nourishment, unto)",
		"def" : "to furnish besides, i.e. fully supply, (figuratively) aid or contribute",
		"derivation" : "from G1909 (ἐπί) and G5524 (χορηγέω)"
	},
	"G2050" : {
		"lemma" : "ἐρήμωσις",
		"translit" : "erḗmōsis",
		"kjv" : "desolation",
		"def" : "despoliation",
		"derivation" : "from G2049 (ἐρημόω)"
	},
	"G2822" : {
		"def" : "invited, i.e. appointed, or (specially), a saint",
		"derivation" : "from the same as G2821 (κλῆσις)",
		"translit" : "klētós",
		"lemma" : "κλητός",
		"kjv" : "called"
	},
	"G1930" : {
		"kjv" : "set in order",
		"translit" : "epidiorthóō",
		"lemma" : "ἐπιδιορθόω",
		"derivation" : "from G1909 (ἐπί) and a derivative of G3717 (ὀρθός)",
		"def" : "to straighten further, i.e. (figuratively) arrange additionally"
	},
	"G1121" : {
		"translit" : "grámma",
		"lemma" : "γράμμα",
		"kjv" : "bill, learning, letter, scripture, writing, written",
		"def" : "a writing, i.e. a letter, note, epistle, book, etc.; plural learning",
		"derivation" : "from G1125 (γράφω)"
	},
	"G3599" : {
		"derivation" : "perhaps from the base of G2068 (ἐσθίω)",
		"def" : "a \"tooth\"",
		"kjv" : "tooth",
		"lemma" : "ὀδούς",
		"translit" : "odoús"
	},
	"G2849" : {
		"derivation" : "from (dwarf)",
		"def" : "properly, to curtail, i.e. (figuratively) to chastise (or reserve for infliction)",
		"kjv" : "punish",
		"translit" : "kolázō",
		"lemma" : "κολάζω"
	},
	"G4099" : {
		"lemma" : "Πισιδία",
		"translit" : "Pisidía",
		"kjv" : "Pisidia",
		"def" : "Pisidia, a region of Asia Minor",
		"derivation" : "probably of foreign origin"
	},
	"G3948" : {
		"kjv" : "contention, provoke unto",
		"lemma" : "παροξυσμός",
		"translit" : "paroxysmós",
		"derivation" : "from G3947 (παροξύνω) (\"paroxysm\")",
		"def" : "incitement (to good), or dispute (in anger)"
	},
	"G1839" : {
		"derivation" : "from G1537 (ἐκ) and G2476 (ἵστημι)",
		"def" : "to put (stand) out of wits, i.e. astound, or (reflexively) become astounded, insane",
		"kjv" : "amaze, be (make) astonished, be beside self (selves), bewitch, wonder",
		"lemma" : "ἐξίστημι",
		"translit" : "exístēmi"
	},
	"G4898" : {
		"def" : "a co-absentee from home, i.e. fellow-traveller",
		"derivation" : "from G4862 (σύν) and the base of G1553 (ἐκδημέω)",
		"lemma" : "συνέκδημος",
		"translit" : "synékdēmos",
		"kjv" : "companion in travel, travel with"
	},
	"G4414" : {
		"kjv" : "ringleader",
		"lemma" : "πρωτοστάτης",
		"translit" : "prōtostátēs",
		"derivation" : "from G4413 (πρῶτος) and G2476 (ἵστημι)",
		"def" : "one standing first in the ranks, i.e. a captain (champion)"
	},
	"G212" : {
		"def" : "braggadocio, i.e. (by implication) self-confidence",
		"derivation" : "from G213 (ἀλαζών)",
		"lemma" : "ἀλαζονεία",
		"translit" : "alazoneía",
		"kjv" : "boasting, pride"
	},
	"G4660" : {
		"def" : "to flay, i.e. (figuratively) to harass",
		"derivation" : "apparently a primary verb",
		"translit" : "skýllō",
		"lemma" : "σκύλλω",
		"kjv" : "trouble(self)"
	},
	"G481" : {
		"translit" : "antikrý",
		"lemma" : "ἀντικρύ",
		"kjv" : "over against",
		"def" : "opposite",
		"derivation" : "prolonged from G473 (ἀντί)"
	},
	"G2586" : {
		"kjv" : "smoke",
		"translit" : "kapnós",
		"lemma" : "καπνός",
		"derivation" : "of uncertain affinity",
		"def" : "smoke"
	},
	"G2072" : {
		"kjv" : "glass",
		"translit" : "ésoptron",
		"lemma" : "ἔσοπτρον",
		"derivation" : "from G1519 (εἰς) and a presumed derivative of G3700 (ὀπτάνομαι)",
		"def" : "a mirror (for looking into)"
	},
	"G4408" : {
		"lemma" : "πρῶρα",
		"translit" : "prōra",
		"kjv" : "forepart(-ship)",
		"def" : "the prow, i.e. forward part of a vessel",
		"derivation" : "feminine of a presumed derivative of G4253 (πρό) as noun"
	},
	"G5252" : {
		"def" : "to esteem oneself overmuch, i.e. be vain or arrogant",
		"derivation" : "from G5228 (ὑπέρ) and G5426 (φρονέω)",
		"translit" : "hyperphronéō",
		"lemma" : "ὑπερφρονέω",
		"kjv" : "think more highly"
	},
	"G4587" : {
		"translit" : "semnótēs",
		"lemma" : "σεμνότης",
		"kjv" : "gravity, honesty",
		"def" : "venerableness, i.e. probity",
		"derivation" : "from G4586 (σεμνός)"
	},
	"G4730" : {
		"translit" : "stenochōría",
		"lemma" : "στενοχωρία",
		"kjv" : "anguish, distress",
		"def" : "narrowness of room, i.e. (figuratively) calamity",
		"derivation" : "from a compound of G4728 (στενός) and G5561 (χώρα)"
	},
	"G3389" : {
		"def" : "a mother-thresher, i.e. matricide",
		"derivation" : "from G3384 (μήτηρ) and the base of G257 (ἅλων)",
		"translit" : "mētralṓias",
		"lemma" : "μητραλῴας",
		"kjv" : "murderer of mothers"
	},
	"G1877" : {
		"translit" : "epanágō",
		"lemma" : "ἐπανάγω",
		"kjv" : "launch (thrust) out, return",
		"def" : "to lead up on, i.e. (technical) to put out (to sea); (intransitively) to return",
		"derivation" : "from G1909 (ἐπί) and G321 (ἀνάγω)"
	},
	"G5042" : {
		"lemma" : "τεκνογονία",
		"translit" : "teknogonía",
		"kjv" : "childbearing",
		"def" : "childbirth (parentage), i.e. (by implication) maternity (the performance of maternal duties)",
		"derivation" : "from the same as G5041 (τεκνογονέω)"
	},
	"G400" : {
		"translit" : "anaphōnéō",
		"lemma" : "ἀναφωνέω",
		"kjv" : "speak out",
		"def" : "to exclaim",
		"derivation" : "from G303 (ἀνά) and G5455 (φωνέω)"
	},
	"G5037" : {
		"kjv" : "also, and, both, even, then, whether",
		"translit" : "té",
		"lemma" : "τέ",
		"derivation" : "a primary particle (enclitic) of connection or addition",
		"def" : "both or also (properly, as correlation of G2532 (καί))"
	},
	"G2440" : {
		"translit" : "himátion",
		"lemma" : "ἱμάτιον",
		"kjv" : "apparel, cloke, clothes, garment, raiment, robe, vesture",
		"def" : "a dress (inner or outer)",
		"derivation" : "neuter of a presumed derivative of ennumi (to put on)"
	},
	"G395" : {
		"def" : "a rising of light, i.e. dawn (figuratively); by implication, the east (also in plural)",
		"derivation" : "from G393 (ἀνατέλλω)",
		"translit" : "anatolḗ",
		"lemma" : "ἀνατολή",
		"kjv" : "dayspring, east, rising"
	},
	"G3748" : {
		"lemma" : "ὅστις",
		"translit" : "hóstis",
		"kjv" : "X and (they), (such) as, (they) that, in that they, what(-soever), whereas ye, (they) which, who(-soever)",
		"def" : "which some, i.e. any that; also (definite) which same",
		"derivation" : "from G3739 (ὅς) and G5100 (τὶς)"
	},
	"G4994" : {
		"def" : "to make of sound mind, i.e. (figuratively) to discipline or correct",
		"derivation" : "from G4998 (σώφρων)",
		"lemma" : "σωφρονίζω",
		"translit" : "sōphronízō",
		"kjv" : "teach to be sober"
	},
	"G569" : {
		"derivation" : "from G571 (ἄπιστος)",
		"def" : "to be unbelieving, i.e. (transitively) disbelieve, or (by implication) disobey",
		"kjv" : "believe not",
		"translit" : "apistéō",
		"lemma" : "ἀπιστέω"
	},
	"G4112" : {
		"def" : "moulded, i.e. (by implication) artificial or (figuratively) fictitious (false)",
		"derivation" : "from G4111 (πλάσσω)",
		"translit" : "plastós",
		"lemma" : "πλαστός",
		"kjv" : "feigned"
	},
	"G1714" : {
		"lemma" : "ἐμπρήθω",
		"translit" : "emprḗthō",
		"kjv" : "burn up",
		"def" : "to enkindle, i.e. set on fire",
		"derivation" : "from G1722 (ἐν) and (to blow a flame)"
	},
	"G2633" : {
		"lemma" : "κατάκρισις",
		"translit" : "katákrisis",
		"kjv" : "condemn(-ation)",
		"def" : "sentencing adversely (the act)",
		"derivation" : "from G2632 (κατακρίνω)"
	},
	"G2602" : {
		"def" : "a deposition, i.e. founding; figuratively, conception",
		"derivation" : "from G2598 (καταβάλλω)",
		"lemma" : "καταβολή",
		"translit" : "katabolḗ",
		"kjv" : "conceive, foundation"
	},
	"G3770" : {
		"derivation" : "from G3772 (οὐρανός)",
		"def" : "celestial, i.e. belonging to or coming from the sky",
		"kjv" : "heavenly",
		"translit" : "ouránios",
		"lemma" : "οὐράνιος"
	},
	"G4037" : {
		"kjv" : "wait for",
		"translit" : "periménō",
		"lemma" : "περιμένω",
		"derivation" : "from G4012 (περί) and G3306 (μένω)",
		"def" : "to stay around, i.e. await"
	},
	"G806" : {
		"translit" : "asphalōs",
		"lemma" : "ἀσφαλῶς",
		"kjv" : "assuredly, safely",
		"def" : "securely (literally or figuratively)",
		"derivation" : "adverb from G804 (ἀσφαλής)"
	},
	"G2470" : {
		"kjv" : "+ agree, as much, equal, like",
		"translit" : "ísos",
		"lemma" : "ἴσος",
		"derivation" : "probably from G1492 (εἴδω) (through the idea of seeming)",
		"def" : "similar (in amount and kind)"
	},
	"G1689" : {
		"kjv" : "behold, gaze up, look upon, (could) see",
		"lemma" : "ἐμβλέπω",
		"translit" : "emblépō",
		"derivation" : "from G1722 (ἐν) and G991 (βλέπω)",
		"def" : "to look on, i.e. (relatively) to observe fixedly, or (absolutely) to discern clearly"
	},
	"G1329" : {
		"derivation" : "from G1223 (διά) and G2059 (ἑρμηνεύω)",
		"def" : "to explain thoroughly, by implication, to translate",
		"kjv" : "expound, interpret(-ation)",
		"translit" : "diermēneúō",
		"lemma" : "διερμηνεύω"
	},
	"G750" : {
		"derivation" : "from G746 (ἀρχή) and G4166 (ποιμήν)",
		"def" : "a head shepherd",
		"kjv" : "chief shepherd",
		"translit" : "archipoímēn",
		"lemma" : "ἀρχιποίμην"
	},
	"G4582" : {
		"kjv" : "moon",
		"translit" : "selḗnē",
		"lemma" : "σελήνη",
		"derivation" : "from (brilliancy",
		"def" : "probably akin to the alternate of G138 (αἱρέομαι), through the idea of attractiveness); the moon"
	},
	"G5445" : {
		"kjv" : "lump",
		"translit" : "phýrama",
		"lemma" : "φύραμα",
		"derivation" : "from a prolonged form of (to mix a liquid with a solid",
		"def" : "perhaps akin to G5453 (φύω) through the idea of swelling in bulk), mean to knead; a mass of dough"
	},
	"G874" : {
		"lemma" : "ἀφορμή",
		"translit" : "aphormḗ",
		"kjv" : "occasion",
		"def" : "a starting-point, i.e. (figuratively) an opportunity",
		"derivation" : "from a compound of G575 (ἀπό) and G3729 (ὁρμάω)"
	},
	"G33" : {
		"translit" : "áge",
		"lemma" : "ἄγε",
		"kjv" : "go to",
		"def" : "properly, lead, i.e. come on",
		"derivation" : "imperative of G71 (ἄγω)"
	},
	"G114" : {
		"kjv" : "cast off, despise, disannul, frustrate, bring to nought, reject",
		"lemma" : "ἀθετέω",
		"translit" : "athetéō",
		"derivation" : "from a compound of G1 (Α) (as a negative particle) and a derivative of G5087 (τίθημι)",
		"def" : "to set aside, i.e. (by implication) to disesteem, neutralize or violate"
	},
	"G3162" : {
		"kjv" : "sword",
		"lemma" : "μάχαιρα",
		"translit" : "máchaira",
		"derivation" : "probably feminine of a presumed derivative of G3163 (μάχη)",
		"def" : "a knife, i.e. dirk; figuratively, war, judicial punishment"
	},
	"G3778" : {
		"derivation" : "from the article G3588 (ὁ) and G846 (αὐτός)",
		"def" : "the he (she or it), i.e. this or that (often with article repeated)",
		"kjv" : "he (it was that), hereof, it, she, such as, the same, these, they, this (man, same, woman), which, who",
		"lemma" : "οὗτος",
		"translit" : "hoûtos"
	},
	"G1108" : {
		"derivation" : "from G1097 (γινώσκω)",
		"def" : "knowing (the act), i.e. (by implication) knowledge",
		"kjv" : "knowledge, science",
		"lemma" : "γνῶσις",
		"translit" : "gnōsis"
	},
	"G1590" : {
		"derivation" : "from G1537 (ἐκ) and G3089 (λύω)",
		"def" : "to relax (literally or figuratively)",
		"kjv" : "faint",
		"lemma" : "ἐκλύω",
		"translit" : "eklýō"
	},
	"G2606" : {
		"def" : "to laugh down, i.e. deride",
		"translit" : "katageláō",
		"lemma" : "καταγελάω",
		"kjv" : "laugh to scorn"
	},
	"G4888" : {
		"derivation" : "from G4862 (σύν) and G1392 (δοξάζω)",
		"def" : "to exalt to dignity in company (i.e. similarly) with",
		"kjv" : "glorify together",
		"lemma" : "συνδοξάζω",
		"translit" : "syndoxázō"
	},
	"G4946" : {
		"derivation" : "plural of uncertain derivation",
		"def" : "Syracuse, the capital of Sicily",
		"kjv" : "Syracuse",
		"lemma" : "Συράκουσαι",
		"translit" : "Syrákousai"
	},
	"G626" : {
		"derivation" : "middle voice from a compound of G575 (ἀπό) and G3056 (λόγος)",
		"def" : "to give an account (legal plea) of oneself, i.e. exculpate (self)",
		"kjv" : "answer (for self), make defence, excuse (self), speak for self",
		"lemma" : "ἀπολογέομαι",
		"translit" : "apologéomai"
	},
	"G2830" : {
		"def" : "a surge of the sea (literally or figuratively)",
		"derivation" : "from (to billow or dash over)",
		"lemma" : "κλύδων",
		"translit" : "klýdōn",
		"kjv" : "raging, wave"
	},
	"G477" : {
		"kjv" : "opposition",
		"lemma" : "ἀντίθεσις",
		"translit" : "antíthesis",
		"derivation" : "from a compound of G473 (ἀντί) and G5087 (τίθημι)",
		"def" : "opposition, i.e. a conflict (of theories)"
	},
	"G4976" : {
		"kjv" : "fashion",
		"translit" : "schēma",
		"lemma" : "σχῆμα",
		"derivation" : "from the alternate of G2192 (ἔχω)",
		"def" : "a figure (as a mode or circumstance), i.e. (by implication) external condition"
	},
	"G1858" : {
		"lemma" : "ἑορτάζω",
		"translit" : "heortázō",
		"kjv" : "keep the feast",
		"def" : "to observe a festival",
		"derivation" : "from G1859 (ἑορτή)"
	},
	"G5553" : {
		"kjv" : "gold",
		"lemma" : "χρυσίον",
		"translit" : "chrysíon",
		"derivation" : "diminutive of G5557 (χρυσός)",
		"def" : "a golden article, i.e. gold plating, ornament, or coin"
	},
	"G4234" : {
		"lemma" : "πρᾶξις",
		"translit" : "prâxis",
		"kjv" : "deed, office, work",
		"def" : "practice, i.e. (concretely) an act; by extension, a function",
		"derivation" : "from G4238 (πράσσω)"
	},
	"G795" : {
		"translit" : "astochéō",
		"lemma" : "ἀστοχέω",
		"kjv" : "err, swerve",
		"def" : "to miss the mark, i.e. (figuratively) deviate from truth",
		"derivation" : "from a compound of G1 (Α) (as a negative particle) and (an aim)"
	},
	"G4202" : {
		"translit" : "porneía",
		"lemma" : "πορνεία",
		"kjv" : "fornication",
		"def" : "harlotry (including adultery and incest); figuratively, idolatry",
		"derivation" : "from G4203 (πορνεύω)"
	},
	"G4724" : {
		"translit" : "stéllō",
		"lemma" : "στέλλω",
		"kjv" : "avoid, withdraw self",
		"def" : "properly, to set fast (\"stall\"), i.e. (figuratively) to repress (reflexively, abstain from associating with)",
		"derivation" : "probably strengthened from the base of G2476 (ἵστημι)"
	},
	"G1034" : {
		"kjv" : "meat",
		"translit" : "brṓsimos",
		"lemma" : "βρώσιμος",
		"derivation" : "from G1035 (βρῶσις)",
		"def" : "eatable"
	},
	"G1057" : {
		"derivation" : "from G1056 (Γαλιλαία)",
		"def" : "Galilean or belonging to Galilea",
		"kjv" : "Galilean, of Galilee",
		"translit" : "Galilaîos",
		"lemma" : "Γαλιλαῖος"
	},
	"G919" : {
		"kjv" : "Barjesus",
		"lemma" : "Βαριησοῦς",
		"translit" : "Bariēsoûs",
		"derivation" : "of Chaldee origin (H1247 and H3091)",
		"def" : "son of Jesus (or Joshua); Bar-jesus, an Israelite"
	},
	"G4548" : {
		"def" : "a plank",
		"derivation" : "of uncertain affinity",
		"translit" : "sanís",
		"lemma" : "σανίς",
		"kjv" : "board"
	},
	"G1532" : {
		"translit" : "eistréchō",
		"lemma" : "εἰστρέχω",
		"kjv" : "run in",
		"def" : "to hasten inward",
		"derivation" : "from G1519 (εἰς) and G5143 (τρέχω)"
	},
	"G1337" : {
		"kjv" : "where two seas meet",
		"translit" : "dithálassos",
		"lemma" : "διθάλασσος",
		"derivation" : "from G1364 (δίς) and G2281 (θάλασσα)",
		"def" : "having two seas, i.e. a sound with a double outlet"
	},
	"G713" : {
		"kjv" : "enough, suffice (-ient)",
		"lemma" : "ἀρκετός",
		"translit" : "arketós",
		"derivation" : "from G714 (ἀρκέω)",
		"def" : "satisfactory"
	},
	"G2789" : {
		"kjv" : "harp",
		"lemma" : "κιθαρίζω",
		"translit" : "kitharízō",
		"derivation" : "from G2788 (κιθάρα)",
		"def" : "to play on a lyre"
	},
	"G1465" : {
		"translit" : "enkóptō",
		"lemma" : "ἐγκόπτω",
		"kjv" : "hinder, be tedious unto",
		"def" : "to cut into, i.e. (figuratively) impede, detain",
		"derivation" : "from G1722 (ἐν) and G2875 (κόπτω)"
	},
	"G3338" : {
		"kjv" : "repent (self)",
		"lemma" : "μεταμέλλομαι",
		"translit" : "metaméllomai",
		"derivation" : "from G3326 (μετά) and the middle voice of G3199 (μέλω)",
		"def" : "to care afterwards, i.e. regret"
	},
	"G1237" : {
		"kjv" : "come after",
		"translit" : "diadéchomai",
		"lemma" : "διαδέχομαι",
		"derivation" : "from G1223 (διά) and G1209 (δέχομαι)",
		"def" : "to receive in turn, i.e. (figuratively) succeed to"
	},
	"G4302" : {
		"kjv" : "foretell, tell before",
		"translit" : "prolégō",
		"lemma" : "προλέγω",
		"derivation" : "from G4253 (πρό) and G3004 (λέγω)",
		"def" : "to say beforehand, i.e. predict, forewarn"
	},
	"G5188" : {
		"def" : "to make a smoke, i.e. slowly consume without flame",
		"derivation" : "apparently a primary verb",
		"translit" : "týphō",
		"lemma" : "τύφω",
		"kjv" : "smoke"
	},
	"G4248" : {
		"lemma" : "πρηνής",
		"translit" : "prēnḗs",
		"kjv" : "headlong",
		"def" : "leaning (falling) forward (\"prone\"), i.e. head foremost",
		"derivation" : "from G4253 (πρό)"
	},
	"G166" : {
		"kjv" : "eternal, for ever, everlasting, world (began)",
		"translit" : "aiṓnios",
		"lemma" : "αἰώνιος",
		"derivation" : "from G165 (αἰών)",
		"def" : "perpetual (also used of past time, or past and future as well)"
	},
	"G2549" : {
		"lemma" : "κακία",
		"translit" : "kakía",
		"kjv" : "evil, malice(-iousness), naughtiness, wickedness",
		"def" : "badness, i.e. (subjectively) depravity, or (actively) malignity, or (passively) trouble",
		"derivation" : "from G2556 (κακός)"
	},
	"G4764" : {
		"kjv" : "delicacy",
		"translit" : "strēnos",
		"lemma" : "στρῆνος",
		"derivation" : "akin to G4731 (στερεός)",
		"def" : "a \"straining\", \"strenuousness\" or \"strength\", i.e. (figuratively) luxury (voluptuousness)"
	},
	"G2567" : {
		"kjv" : "teacher of good things",
		"lemma" : "καλοδιδάσκαλος",
		"translit" : "kalodidáskalos",
		"derivation" : "from G2570 (καλός) and G1320 (διδάσκαλος)",
		"def" : "a teacher of the right"
	},
	"G397" : {
		"def" : "to rear (physically or mentally)",
		"derivation" : "from G303 (ἀνά) and G5142 (τρέφω)",
		"lemma" : "ἀνατρέφω",
		"translit" : "anatréphō",
		"kjv" : "bring up, nourish (up)"
	},
	"G4156" : {
		"def" : "throttled, i.e. (neuter concretely) an animal choked to death (not bled)",
		"derivation" : "from G4155 (πνίγω)",
		"lemma" : "πνικτός",
		"translit" : "pniktós",
		"kjv" : "strangled"
	},
	"G1417" : {
		"lemma" : "δύο",
		"translit" : "dýo",
		"kjv" : "both, twain, two",
		"def" : "\"two\"",
		"derivation" : "a primary numeral"
	},
	"G2816" : {
		"kjv" : "be heir, (obtain by) inherit(-ance)",
		"lemma" : "κληρονομέω",
		"translit" : "klēronoméō",
		"derivation" : "from G2818 (κληρονόμος)",
		"def" : "to be an heir to (literally or figuratively)"
	},
	"G4958" : {
		"kjv" : "short, wind up",
		"translit" : "systéllō",
		"lemma" : "συστέλλω",
		"derivation" : "from G4862 (σύν) and G4724 (στέλλω)",
		"def" : "to send (draw) together, i.e. enwrap (enshroud a corpse for burial), contract (an interval)"
	},
	"G1352" : {
		"def" : "through which thing, i.e. consequently",
		"derivation" : "from G1223 (διά) and G3739 (ὅς)",
		"translit" : "dió",
		"lemma" : "διό",
		"kjv" : "for which cause, therefore, wherefore"
	},
	"G3074" : {
		"def" : "a wolf",
		"derivation" : "perhaps akin to the base of G3022 (λευκός) (from the whitish hair)",
		"translit" : "lýkos",
		"lemma" : "λύκος",
		"kjv" : "wolf"
	},
	"G2030" : {
		"def" : "a looker-on",
		"derivation" : "from G1909 (ἐπί) and a presumed derivative of G3700 (ὀπτάνομαι)",
		"translit" : "epóptēs",
		"lemma" : "ἐπόπτης",
		"kjv" : "eye-witness"
	},
	"G3473" : {
		"lemma" : "μωρολογία",
		"translit" : "mōrología",
		"kjv" : "foolish talking",
		"def" : "silly talk, i.e. buffoonery",
		"derivation" : "from a compound of G3474 (μωρός) and G3004 (λέγω)"
	},
	"G5132" : {
		"derivation" : "probably contracted from G5064 (τέσσαρες) and G3979 (πεζῇ)",
		"def" : "a table or stool (as being four-legged), usually for food (figuratively, a meal); also a counter for money (figuratively, a broker's office for loans at interest)",
		"kjv" : "bank, meat, table",
		"translit" : "trápeza",
		"lemma" : "τράπεζα"
	},
	"G582" : {
		"def" : "an enrollment; by implication, an assessment",
		"derivation" : "from G583 (ἀπογράφω)",
		"translit" : "apographḗ",
		"lemma" : "ἀπογραφή",
		"kjv" : "taxing"
	},
	"G1627" : {
		"translit" : "ekphérō",
		"lemma" : "ἐκφέρω",
		"kjv" : "bear, bring forth, carry forth (out)",
		"def" : "to bear out (literally or figuratively)",
		"derivation" : "from G1537 (ἐκ) and G5342 (φέρω)"
	},
	"G2747" : {
		"def" : "Cenchreæ, a port of Corinth",
		"derivation" : "probably from kegchros (millet)",
		"translit" : "Kenchreaí",
		"lemma" : "Κεγχρεαί",
		"kjv" : "Cencrea"
	},
	"G1475" : {
		"lemma" : "ἔδαφος",
		"translit" : "édaphos",
		"kjv" : "ground",
		"def" : "a basis (bottom), i.e. the soil",
		"derivation" : "from the base of G1476 (ἑδραῖος)"
	},
	"G4653" : {
		"derivation" : "from G4655 (σκότος)",
		"def" : "dimness, obscurity (literally or figuratively)",
		"kjv" : "dark(-ness)",
		"translit" : "skotía",
		"lemma" : "σκοτία"
	},
	"G3391" : {
		"derivation" : "irregular feminine of G1520 (εἷς)",
		"def" : "one or first",
		"kjv" : "a (certain), + agree, first, one, X other",
		"translit" : "mía",
		"lemma" : "μία"
	},
	"G5530" : {
		"kjv" : "entreat, use",
		"lemma" : "χράομαι",
		"translit" : "chráomai",
		"derivation" : "middle voice of a primary verb (perhaps rather from G5495 (χείρ), to handle)",
		"def" : "to furnish what is needed; (give an oracle, \"graze\" (touch slightly), light upon, etc.), i.e. (by implication) to employ or (by extension) to act towards one in a given manner"
	},
	"G2882" : {
		"kjv" : "Corinth",
		"translit" : "Kórinthos",
		"lemma" : "Κόρινθος",
		"derivation" : "of uncertain derivation",
		"def" : "Corinthus, a city of Greece"
	},
	"G3932" : {
		"kjv" : "virginity",
		"translit" : "parthenía",
		"lemma" : "παρθενία",
		"derivation" : "from G3933 (παρθένος)",
		"def" : "maidenhood"
	},
	"G14" : {
		"def" : "to work good",
		"derivation" : "from G18 (ἀγαθός) and G2041 (ἔργον)",
		"lemma" : "ἀγαθοεργέω",
		"translit" : "agathoergéō",
		"kjv" : "do good"
	},
	"G1874" : {
		"derivation" : "from G1909 (ἐπί) and the base of G202 (ἀκροατής)",
		"def" : "to listen (intently) to",
		"kjv" : "hear",
		"lemma" : "ἐπακροάομαι",
		"translit" : "epakroáomai"
	},
	"G4680" : {
		"derivation" : "akin to (clear)",
		"def" : "wise (in a most general application)",
		"kjv" : "wise",
		"lemma" : "σοφός",
		"translit" : "sophós"
	},
	"G1979" : {
		"def" : "a provisioning, i.e. (concretely) food",
		"derivation" : "from a compound of G1909 (ἐπί) and a derivative of G4621 (σῖτος)",
		"translit" : "episitismós",
		"lemma" : "ἐπισιτισμός",
		"kjv" : "victuals"
	},
	"G4684" : {
		"def" : "to be voluptuous",
		"derivation" : "from (luxury)",
		"translit" : "spataláō",
		"lemma" : "σπαταλάω",
		"kjv" : "live in pleasure, be wanton"
	},
	"G4528" : {
		"translit" : "Salathiḗl",
		"lemma" : "Σαλαθιήλ",
		"kjv" : "Salathiel",
		"def" : "Salathiel (i.e. Shealtiel), an Israelite",
		"derivation" : "of Hebrew origin (H7597)"
	},
	"G4583" : {
		"derivation" : "middle voice or passive from a presumed derivative of G4582 (σελήνη)",
		"def" : "to be moon-struck, i.e. crazy",
		"kjv" : "be a lunatic",
		"lemma" : "σεληνιάζομαι",
		"translit" : "selēniázomai"
	},
	"G990" : {
		"derivation" : "from G991 (βλέπω)",
		"def" : "vision (properly concrete; by implication, abstract)",
		"kjv" : "seeing",
		"lemma" : "βλέμμα",
		"translit" : "blémma"
	},
	"G4995" : {
		"lemma" : "σωφρονισμός",
		"translit" : "sōphronismós",
		"kjv" : "sound mind",
		"def" : "discipline, i.e. self-control",
		"derivation" : "from G4994 (σωφρονίζω)"
	},
	"G2503" : {
		"derivation" : "of Hebrew origin (the tenth letter of the Hebrew alphabet)",
		"def" : "\"iota\", the name of the eighth letter of the Greek alphabet, put (figuratively) for a very small part of anything",
		"kjv" : "jot",
		"lemma" : "ἰῶτα",
		"translit" : "iōta"
	},
	"G4490" : {
		"lemma" : "ῥητῶς",
		"translit" : "rhētōs",
		"kjv" : "expressly",
		"def" : "out-spokenly, i.e. distinctly",
		"derivation" : "adverb from a derivative of G4483 (ῥέω)"
	},
	"G387" : {
		"kjv" : "trouble, turn upside down, make an uproar",
		"lemma" : "ἀναστατόω",
		"translit" : "anastatóō",
		"derivation" : "from a derivative of G450 (ἀνίστημι) (in the sense of removal)",
		"def" : "properly, to drive out of home, i.e. (by implication) to disturb (literally or figuratively)"
	},
	"G5550" : {
		"derivation" : "of uncertain derivation",
		"def" : "a space of time (in general, and thus properly distinguished from G2540 (καιρός), which designates a fixed or special occasion; and from G165 (αἰών), which denotes a particular period) or interval; by extension, an individual opportunity; by implication, delay",
		"kjv" : "+ years old, season, space, (X often-)time(-s), (a) while",
		"lemma" : "χρόνος",
		"translit" : "chrónos"
	},
	"G3751" : {
		"lemma" : "ὀσφῦς",
		"translit" : "osphŷs",
		"kjv" : "loin",
		"def" : "the loin (externally), i.e. the hip; internally (by extension) procreative power",
		"derivation" : "of uncertain affinity"
	},
	"G4545" : {
		"kjv" : "Samuel",
		"translit" : "Samouḗl",
		"lemma" : "Σαμουήλ",
		"derivation" : "of Hebrew origin (H8050)",
		"def" : "Samuel (i.e. Shemuel), an Israelite"
	},
	"G594" : {
		"def" : "acceptance",
		"derivation" : "from G588 (ἀποδέχομαι)",
		"lemma" : "ἀποδοχή",
		"translit" : "apodochḗ",
		"kjv" : "acceptation"
	},
	"G3630" : {
		"def" : "a tippler",
		"derivation" : "from G3631 (οἶνος) and a derivative of the alternate of G4095 (πίνω)",
		"lemma" : "οἰνοπότης",
		"translit" : "oinopótēs",
		"kjv" : "winebibber"
	},
	"G1127" : {
		"kjv" : "be vigilant, wake, (be) watch(-ful)",
		"translit" : "grēgoreúō",
		"lemma" : "γρηγορεύω",
		"derivation" : "from G1453 (ἐγείρω)",
		"def" : "to keep awake, i.e. watch (literally or figuratively)"
	},
	"G4743" : {
		"translit" : "stigmḗ",
		"lemma" : "στιγμή",
		"kjv" : "moment",
		"def" : "a point of time, i.e. an instant",
		"derivation" : "feminine of G4742 (στίγμα)"
	},
	"G2715" : {
		"translit" : "katexousiázō",
		"lemma" : "κατεξουσιάζω",
		"kjv" : "exercise authority",
		"def" : "to have (wield) full privilege over",
		"derivation" : "from G2596 (κατά) and G1850 (ἐξουσιάζω)"
	},
	"G2478" : {
		"translit" : "ischyrós",
		"lemma" : "ἰσχυρός",
		"kjv" : "boisterous, mighty(-ier), powerful, strong(-er, man), valiant",
		"def" : "forcible (literally or figuratively)",
		"derivation" : "from G2479 (ἰσχύς)"
	},
	"G4795" : {
		"kjv" : "chance",
		"lemma" : "συγκυρία",
		"translit" : "synkyría",
		"derivation" : "from a comparative of G4862 (σύν) and (to light or happen",
		"def" : "from the base of G2962 (κύριος)); concurrence, i.e. accident"
	},
	"G3745" : {
		"def" : "as (much, great, long, etc.) as",
		"derivation" : "by reduplication from G3739 (ὅς)",
		"translit" : "hósos",
		"lemma" : "ὅσος",
		"kjv" : "all (that), as (long, many, much) (as), how great (many, much), (in-)asmuch as, so many as, that (ever), the more, those things, what (great, -soever), wheresoever, wherewithsoever, which, X while, who(-soever)"
	},
	"G551" : {
		"kjv" : "not to be tempted",
		"translit" : "apeírastos",
		"lemma" : "ἀπείραστος",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G3987 (πειράω)",
		"def" : "untried, i.e. not temptable"
	},
	"G5497" : {
		"def" : "a hand-leader, i.e. personal conductor (of a blind person)",
		"derivation" : "from G5495 (χείρ) and a reduplicated form of G71 (ἄγω)",
		"lemma" : "χειραγωγός",
		"translit" : "cheiragōgós",
		"kjv" : "some to lead by the hand"
	},
	"G3371" : {
		"def" : "no further",
		"derivation" : "from G3361 (μή) and G2089 (ἔτι)",
		"translit" : "mēkéti",
		"lemma" : "μηκέτι",
		"kjv" : "any longer, (not) henceforth, hereafter, no henceforward (longer, more, soon), not any more"
	},
	"G3905" : {
		"translit" : "parateínō",
		"lemma" : "παρατείνω",
		"kjv" : "continue",
		"def" : "to extend along, i.e. prolong (in point of time)",
		"derivation" : "from G3844 (παρά) and teino (to stretch)"
	},
	"G2199" : {
		"lemma" : "Ζεβεδαῖος",
		"translit" : "Zebedaîos",
		"kjv" : "Zebedee",
		"def" : "Zebedæus, an Israelite",
		"derivation" : "of Hebrew origin (compare H2067)"
	},
	"G2344" : {
		"kjv" : "treasure",
		"lemma" : "θησαυρός",
		"translit" : "thēsaurós",
		"derivation" : "from G5087 (τίθημι)",
		"def" : "a deposit, i.e. wealth (literally or figuratively)"
	},
	"G1682" : {
		"derivation" : "of Chaldean origin (H426 with pronominal suffix) my God",
		"kjv" : "Eloi",
		"lemma" : "ἐλοΐ",
		"translit" : "eloḯ"
	},
	"G2541" : {
		"lemma" : "Καῖσαρ",
		"translit" : "Kaîsar",
		"kjv" : "Cæsar",
		"def" : "Cæsar, a title of the Roman emperor",
		"derivation" : "of Latin origin"
	},
	"G3861" : {
		"def" : "contrary to expectation, i.e. extraordinary (\"paradox\")",
		"derivation" : "from G3844 (παρά) and G1391 (δόξα) (in the sense of seeming)",
		"lemma" : "παράδοξος",
		"translit" : "parádoxos",
		"kjv" : "strange"
	},
	"G700" : {
		"derivation" : "probably from G142 (αἴρω) (through the idea of exciting emotion)",
		"def" : "to be agreeable (or by implication, to seek to be so)",
		"kjv" : "please",
		"translit" : "aréskō",
		"lemma" : "ἀρέσκω"
	},
	"G3568" : {
		"derivation" : "a primary particle of present time",
		"def" : "\"now\" (as adverb of date, a transition or emphasis); also as noun or adjective present or immediate",
		"kjv" : "henceforth, + hereafter, of late, soon, present, this (time)",
		"translit" : "nŷn",
		"lemma" : "νῦν"
	},
	"G2336" : {
		"derivation" : "from G5087 (τίθημι)",
		"def" : "a receptacle, i.e. scabbard",
		"kjv" : "sheath",
		"translit" : "thḗkē",
		"lemma" : "θήκη"
	},
	"G1005" : {
		"def" : "the north (properly, wind)",
		"derivation" : "of uncertain derivation",
		"lemma" : "βοῤῥᾶς",
		"translit" : "borrhâs",
		"kjv" : "north"
	},
	"G1565" : {
		"derivation" : "from G1563 (ἐκεῖ)",
		"def" : "that one (or (neuter) thing); often intensified by the article prefixed",
		"kjv" : "he, it, the other (same), selfsame, that (same, very), X their, X them, they, this, those",
		"lemma" : "ἐκεῖνος",
		"translit" : "ekeînos"
	},
	"G5470" : {
		"kjv" : "brass",
		"translit" : "chálkeos",
		"lemma" : "χάλκεος",
		"derivation" : "from G5475 (χαλκός)",
		"def" : "coppery"
	},
	"G4899" : {
		"kjv" : "elected together with",
		"lemma" : "συνεκλεκτός",
		"translit" : "syneklektós",
		"derivation" : "from a compound of G4862 (σύν) and G1586 (ἐκλέγομαι)",
		"def" : "chosen in company with, i.e. co-elect (fellow Christian)"
	},
	"G4052" : {
		"def" : "to superabound (in quantity or quality), be in excess, be superfluous; also (transitively) to cause to superabound or excel",
		"derivation" : "from G4053 (περισσός)",
		"lemma" : "περισσεύω",
		"translit" : "perisseúō",
		"kjv" : "(make, more) abound, (have, have more) abundance (be more) abundant, be the better, enough and to spare, exceed, excel, increase, be left, redound, remain (over and above)"
	},
	"G1396" : {
		"def" : "to be a slave-driver, i.e. to enslave (figuratively, subdue)",
		"derivation" : "from a presumed compound of G1401 (δοῦλος) and G71 (ἄγω)",
		"translit" : "doulagōgéō",
		"lemma" : "δουλαγωγέω",
		"kjv" : "bring into subjection"
	},
	"G1654" : {
		"def" : "compassionateness, i.e. (as exercised towards the poor) beneficence, or (concretely) a benefaction",
		"derivation" : "from G1656 (ἔλεος)",
		"lemma" : "ἐλεημοσύνη",
		"translit" : "eleēmosýnē",
		"kjv" : "alms(-deeds)"
	},
	"G2553" : {
		"kjv" : "be afflicted, endure afflictions (hardness), suffer trouble",
		"translit" : "kakopathéō",
		"lemma" : "κακοπαθέω",
		"derivation" : "from the same as G2552 (κακοπάθεια)",
		"def" : "to undergo hardship"
	},
	"G5591" : {
		"kjv" : "natural, sensual",
		"lemma" : "ψυχικός",
		"translit" : "psychikós",
		"derivation" : "from G5590 (ψυχή)",
		"def" : "sensitive, i.e. animate (in distinction on the one hand from G4152 (πνευματικός), which is the higher or renovated nature; and on the other from G5446 (φυσικός), which is the lower or bestial nature)"
	},
	"G2152" : {
		"kjv" : "devout, godly",
		"lemma" : "εὐσεβής",
		"translit" : "eusebḗs",
		"derivation" : "from G2095 (εὖ) and G4576 (σέβομαι)",
		"def" : "well-reverent, i.e. pious"
	},
	"G2784" : {
		"def" : "to herald (as a public crier), especially divine truth (the gospel)",
		"derivation" : "of uncertain affinity",
		"translit" : "kērýssō",
		"lemma" : "κηρύσσω",
		"kjv" : "preacher(-er), proclaim, publish"
	},
	"G4420" : {
		"kjv" : "wing",
		"translit" : "ptéryx",
		"lemma" : "πτέρυξ",
		"derivation" : "from a derivative of G4072 (πέτομαι) (meaning a feather)",
		"def" : "a wing"
	},
	"G324" : {
		"derivation" : "from G303 (ἀνά) and G1209 (δέχομαι)",
		"def" : "to entertain (as a guest)",
		"kjv" : "receive",
		"lemma" : "ἀναδέχομαι",
		"translit" : "anadéchomai"
	},
	"G5049" : {
		"def" : "completely, i.e. (of hope) without wavering",
		"derivation" : "adverb from G5046 (τέλειος)",
		"lemma" : "τελείως",
		"translit" : "teleíōs",
		"kjv" : "to the end"
	},
	"G1937" : {
		"kjv" : "covet, desire, would fain, lust (after)",
		"lemma" : "ἐπιθυμέω",
		"translit" : "epithyméō",
		"derivation" : "from G1909 (ἐπί) and G2372 (θυμός)",
		"def" : "to set the heart upon, i.e. long for (rightfully or otherwise)"
	},
	"G3382" : {
		"def" : "a thigh",
		"derivation" : "perhaps a primary word",
		"lemma" : "μηρός",
		"translit" : "mērós",
		"kjv" : "thigh"
	},
	"G1399" : {
		"derivation" : "feminine of G1401 (δοῦλος)",
		"def" : "a female slave (involuntarily or voluntarily)",
		"kjv" : "handmaid(-en)",
		"translit" : "doúlē",
		"lemma" : "δούλη"
	},
	"G3110" : {
		"lemma" : "Μακεδών",
		"translit" : "Makedṓn",
		"kjv" : "of Macedonia, Macedonian",
		"def" : "a Macedon (Macedonian), i.e. inhabitant of Macedonia",
		"derivation" : "of uncertain derivation"
	},
	"G15" : {
		"kjv" : "(when) do good (well)",
		"translit" : "agathopoiéō",
		"lemma" : "ἀγαθοποιέω",
		"derivation" : "from G17 (ἀγαθοποιός)",
		"def" : "to be a well-doer (as a favor or a duty)"
	},
	"G2188" : {
		"derivation" : "of Chaldee origin (H6606)",
		"def" : "be opened!",
		"kjv" : "Ephphatha",
		"lemma" : "ἐφφαθά",
		"translit" : "ephphathá"
	},
	"G5499" : {
		"lemma" : "χειροποίητος",
		"translit" : "cheiropoíētos",
		"kjv" : "made by (make with) hands",
		"def" : "manufactured, i.e. of human construction",
		"derivation" : "from G5495 (χείρ) and a derivative of G4160 (ποιέω)"
	},
	"G843" : {
		"kjv" : "condemned of self",
		"translit" : "autokatákritos",
		"lemma" : "αὐτοκατάκριτος",
		"derivation" : "from G846 (αὐτός) and a derivative or G2632 (κατακρίνω)",
		"def" : "self-condemned"
	},
	"G2235" : {
		"translit" : "ḗdē",
		"lemma" : "ἤδη",
		"kjv" : "already, (even) now (already), by this time",
		"def" : "even now",
		"derivation" : "apparently from G2228 (ἤ) (or possibly G2229 (ἦ)) and G1211 (δή)"
	},
	"G2793" : {
		"derivation" : "from G2794 (κίνδυνος)",
		"def" : "to undergo peril",
		"kjv" : "be in danger, be (stand) in jeopardy",
		"lemma" : "κινδυνεύω",
		"translit" : "kindyneúō"
	},
	"G1294" : {
		"derivation" : "from G1223 (διά) and G4762 (στρέφω)",
		"def" : "to distort, i.e. (figuratively) misinterpret, or (morally) corrupt",
		"kjv" : "perverse(-rt), turn away",
		"lemma" : "διαστρέφω",
		"translit" : "diastréphō"
	},
	"G2280" : {
		"kjv" : "Thaddæus",
		"lemma" : "Θαδδαῖος",
		"translit" : "Thaddaîos",
		"derivation" : "of uncertain origin",
		"def" : "Thaddæus, one of the Apostles"
	},
	"G3023" : {
		"derivation" : "a primary word",
		"def" : "a \"lion\"",
		"kjv" : "lion",
		"lemma" : "λέων",
		"translit" : "léōn"
	},
	"G3611" : {
		"kjv" : "dwell",
		"lemma" : "οἰκέω",
		"translit" : "oikéō",
		"derivation" : "from G3624 (οἶκος)",
		"def" : "to occupy a house, i.e. reside (figuratively, inhabit, remain, inhere); by implication, to cohabit"
	},
	"G2721" : {
		"derivation" : "from G2596 (κατά) and G2186 (ἐφίστημι)",
		"def" : "to stand over against, i.e. rush upon (assault)",
		"kjv" : "make insurrection against",
		"translit" : "katephístēmi",
		"lemma" : "κατεφίστημι"
	},
	"G5156" : {
		"lemma" : "τρόμος",
		"translit" : "trómos",
		"kjv" : "+ tremble(-ing)",
		"def" : "a \"trembling\", i.e. quaking with fear",
		"derivation" : "from G5141 (τρέμω)"
	},
	"G1472" : {
		"def" : "to rub in (oil), i.e. besmear",
		"derivation" : "from G1722 (ἐν) and G5548 (χρίω)",
		"translit" : "enchríō",
		"lemma" : "ἐγχρίω",
		"kjv" : "anoint"
	},
	"G357" : {
		"def" : "to estimate, i.e. (figuratively) contemplate",
		"derivation" : "middle voice from G356 (ἀναλογία)",
		"lemma" : "ἀναλογίζομαι",
		"translit" : "analogízomai",
		"kjv" : "consider"
	},
	"G4640" : {
		"def" : "to jump, i.e. sympathetically move (as the quickening of a fetus)",
		"derivation" : "akin to (to skip)",
		"translit" : "skirtáō",
		"lemma" : "σκιρτάω",
		"kjv" : "leap (for joy)"
	},
	"G1541" : {
		"derivation" : "from G1540 (ἑκατόν) and G2094 (ἔτος)",
		"def" : "centenarian",
		"kjv" : "hundred years old",
		"translit" : "hekatontaétēs",
		"lemma" : "ἑκατονταέτης"
	},
	"G494" : {
		"def" : "Antipatris, a place in Palestine",
		"derivation" : "from the same as G493 (Ἀντίπας)",
		"translit" : "Antipatrís",
		"lemma" : "Ἀντιπατρίς",
		"kjv" : "Antipatris"
	},
	"G272" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G3199 (μέλω)",
		"def" : "to be careless of",
		"kjv" : "make light of, neglect, be negligent, no regard",
		"translit" : "ameléō",
		"lemma" : "ἀμελέω"
	},
	"G32" : {
		"translit" : "ángelos",
		"lemma" : "ἄγγελος",
		"kjv" : "angel, messenger",
		"def" : "compare G34 (ἀγέλη)) (to bring tidings); a messenger; especially an \"angel\"; by implication, a pastor",
		"derivation" : "from (probably derived from G71 (ἄγω)"
	},
	"G2815" : {
		"kjv" : "Clement",
		"lemma" : "Κλήμης",
		"translit" : "Klḗmēs",
		"derivation" : "of Latin origin",
		"def" : "merciful; Clemes (i.e. Clemens), a Christian"
	},
	"G1434" : {
		"kjv" : "gift",
		"translit" : "dṓrēma",
		"lemma" : "δώρημα",
		"derivation" : "from G1433 (δωρέομαι)",
		"def" : "a bestowment"
	},
	"G4075" : {
		"derivation" : "from G4073 (πέτρα) and G1491 (εἶδος)",
		"def" : "rock-like, i.e. rocky",
		"kjv" : "stony",
		"translit" : "petrṓdēs",
		"lemma" : "πετρώδης"
	},
	"G3501" : {
		"kjv" : "new, young",
		"translit" : "néos",
		"lemma" : "νέος",
		"derivation" : "including the comparative ; a primary word",
		"def" : "\"new\", i.e. (of persons) youthful, or (of things) fresh; figuratively, regenerate"
	},
	"G2257" : {
		"kjv" : "our (company), us, we",
		"lemma" : "ἡμῶν",
		"translit" : "hēmōn",
		"derivation" : "genitive case plural of G1473 (ἐγώ)",
		"def" : "of (or from) us"
	},
	"G5191" : {
		"derivation" : "from G5192 (ὑάκινθος)",
		"def" : "\"hyacinthine\" or \"jacinthine\", i.e. deep blue",
		"kjv" : "jacinth",
		"translit" : "hyakínthinos",
		"lemma" : "ὑακίνθινος"
	},
	"G1983" : {
		"lemma" : "ἐπισκοπέω",
		"translit" : "episkopéō",
		"kjv" : "look diligently, take the oversight",
		"def" : "to oversee; by implication, to beware",
		"derivation" : "from G1909 (ἐπί) and G4648 (σκοπέω)"
	},
	"G2752" : {
		"lemma" : "κέλευμα",
		"translit" : "kéleuma",
		"kjv" : "shout",
		"def" : "a cry of incitement",
		"derivation" : "from G2753 (κελεύω)"
	},
	"G3150" : {
		"derivation" : "from G3151 (ματαιολόγος)",
		"def" : "random talk, i.e. babble",
		"kjv" : "vain jangling",
		"lemma" : "ματαιολογία",
		"translit" : "mataiología"
	},
	"G3062" : {
		"derivation" : "masculine plural of a derivative of G3007 (λείπω)",
		"def" : "remaining ones",
		"kjv" : "other, which remain, remnant, residue, rest",
		"lemma" : "λοιποί",
		"translit" : "loipoí"
	},
	"G604" : {
		"kjv" : "reconcile",
		"translit" : "apokatallássō",
		"lemma" : "ἀποκαταλλάσσω",
		"derivation" : "from G575 (ἀπό) and G2644 (καταλλάσσω)",
		"def" : "to reconcile fully"
	},
	"G2236" : {
		"kjv" : "most (very) gladly",
		"translit" : "hḗdista",
		"lemma" : "ἥδιστα",
		"derivation" : "neuter plural of the superlative of the same as G2234 (ἡδέως)",
		"def" : "with great pleasure"
	},
	"G5275" : {
		"kjv" : "be left",
		"lemma" : "ὑπολείπω",
		"translit" : "hypoleípō",
		"derivation" : "from G5295 (ὑποτρέχω) and G3007 (λείπω)",
		"def" : "to leave under (behind), i.e. (passively) to remain (survive)"
	},
	"G2513" : {
		"derivation" : "of uncertain affinity",
		"def" : "clean (literally or figuratively)",
		"kjv" : "clean, clear, pure",
		"lemma" : "καθαρός",
		"translit" : "katharós"
	},
	"G5325" : {
		"derivation" : "from a derivative of G5324 (φαντάζω)",
		"def" : "(properly abstract) a (vain) show (\"fantasy\")",
		"kjv" : "pomp",
		"lemma" : "φαντασία",
		"translit" : "phantasía"
	},
	"G4114" : {
		"kjv" : "breadth",
		"lemma" : "πλάτος",
		"translit" : "plátos",
		"derivation" : "from G4116 (πλατύς)",
		"def" : "width"
	},
	"G1067" : {
		"derivation" : "of Hebrew origin (H1516 and H2011)",
		"def" : "valley of (the son of) Hinnom; ge-henna (or Ge-Hinnom), a valley of Jerusalem, used (figuratively) as a name for the place (or state) of everlasting punishment",
		"kjv" : "hell",
		"translit" : "géenna",
		"lemma" : "γέεννα"
	},
	"G1043" : {
		"derivation" : "of Hebrew origin (H1403)",
		"def" : "Gabriel, an archangel",
		"kjv" : "Gabriel",
		"lemma" : "Γαβριήλ",
		"translit" : "Gabriḗl"
	},
	"G1264" : {
		"def" : "to fight fiercely (in altercation)",
		"derivation" : "from G1223 (διά) and G3164 (μάχομαι)",
		"lemma" : "διαμάχομαι",
		"translit" : "diamáchomai",
		"kjv" : "strive"
	},
	"G1023" : {
		"def" : "the arm, i.e. (figuratively) strength",
		"derivation" : "properly, comparative of G1024 (βραχύς), but apparently in the sense of (to wield)",
		"lemma" : "βραχίων",
		"translit" : "brachíōn",
		"kjv" : "arm"
	},
	"G3617" : {
		"translit" : "oikodespótēs",
		"lemma" : "οἰκοδεσπότης",
		"kjv" : "goodman (of the house), householder, master of the house",
		"def" : "the head of a family",
		"derivation" : "from G3624 (οἶκος) and G1203 (δεσπότης)"
	},
	"G5170" : {
		"kjv" : "Tryphena",
		"lemma" : "Τρύφαινα",
		"translit" : "Trýphaina",
		"derivation" : "from G5172 (τρυφή)",
		"def" : "luxurious; Tryphæna, a Christian woman"
	},
	"G5228" : {
		"lemma" : "ὑπέρ",
		"translit" : "hypér",
		"kjv" : "(+ exceeding, abundantly) above, in (on) behalf of, beyond, by, + very chiefest, concerning, exceeding (above, -ly), for, + very highly, more (than), of, over, on the part of, for sake of, in stead, than, to(-ward), very",
		"def" : "\"over\", i.e. (with the genitive case) of place, above, beyond, across, or causal, for the sake of, instead, regarding; with the accusative case superior to, more than",
		"derivation" : "a primary preposition"
	},
	"G869" : {
		"kjv" : "suddenly",
		"lemma" : "ἄφνω",
		"translit" : "áphnō",
		"derivation" : "adverb from G852 (ἀφανής) (contraction)",
		"def" : "unawares, i.e. unexpectedly"
	},
	"G4337" : {
		"kjv" : "(give) attend(-ance, -ance at, -ance to, unto), beware, be given to, give (take) heed (to unto); have regard",
		"lemma" : "προσέχω",
		"translit" : "proséchō",
		"derivation" : "from G4314 (πρός) and G2192 (ἔχω)",
		"def" : "(figuratively) to hold the mind (3563 implied) towards, i.e. pay attention to, be cautious about, apply oneself to, adhere to"
	},
	"G3931" : {
		"kjv" : "comfort",
		"lemma" : "παρηγορία",
		"translit" : "parēgoría",
		"derivation" : "from a compound of G3844 (παρά) and a derivative of G58 (ἀγορά) (meaning to harangue an assembly)",
		"def" : "an address alongside, i.e. (specially), consolation"
	},
	"G2748" : {
		"translit" : "Kedrṓn",
		"lemma" : "Κεδρών",
		"kjv" : "Cedron",
		"def" : "Cedron (i.e. Kidron), a brook near Jerusalem",
		"derivation" : "of Hebrew origin (H6939)"
	},
	"G3886" : {
		"lemma" : "παραλύω",
		"translit" : "paralýō",
		"kjv" : "feeble, sick of the (taken with) palsy",
		"def" : "to loosen beside, i.e. relax (perfect passive participle, paralyzed or enfeebled)",
		"derivation" : "from G3844 (παρά) and G3089 (λύω)"
	},
	"G1878" : {
		"lemma" : "ἐπαναμιμνήσκω",
		"translit" : "epanamimnḗskō",
		"kjv" : "put in mind",
		"def" : "to remind of",
		"derivation" : "from G1909 (ἐπί) and G363 (ἀναμιμνήσκω)"
	},
	"G2232" : {
		"derivation" : "from G2233 (ἡγέομαι)",
		"def" : "a leader, i.e. chief person (or figuratively, place) of a province",
		"kjv" : "governor, prince, ruler",
		"translit" : "hēgemṓn",
		"lemma" : "ἡγεμών"
	},
	"G3120" : {
		"kjv" : "effeminate, soft",
		"lemma" : "μαλακός",
		"translit" : "malakós",
		"derivation" : "of uncertain affinity",
		"def" : "soft, i.e. fine (clothing); figuratively, a catamite"
	},
	"G2465" : {
		"translit" : "isángelos",
		"lemma" : "ἰσάγγελος",
		"kjv" : "equal unto the angels",
		"def" : "like an angel, i.e. angelic",
		"derivation" : "from G2470 (ἴσος) and G32 (ἄγγελος)"
	},
	"G1692" : {
		"derivation" : "of uncertain affinity",
		"def" : "to vomit",
		"kjv" : "(will) spue",
		"translit" : "eméō",
		"lemma" : "ἐμέω"
	},
	"G916" : {
		"lemma" : "βαρέω",
		"translit" : "baréō",
		"kjv" : "burden, charge, heavy, press",
		"def" : "to weigh down (figuratively)",
		"derivation" : "from G926 (βαρύς)"
	},
	"G3962" : {
		"def" : "a \"father\" (literally or figuratively, near or more remote)",
		"derivation" : "apparently a primary word",
		"translit" : "patḗr",
		"lemma" : "πατήρ",
		"kjv" : "father, parent"
	},
	"G5005" : {
		"derivation" : "from the base of G5007 (τάλαντον) and a derivative of the base of G3984 (πεῖρα)",
		"def" : "enduring trial, i.e. miserable",
		"kjv" : "wretched",
		"lemma" : "ταλαίπωρος",
		"translit" : "talaípōros"
	},
	"G955" : {
		"translit" : "Belíal",
		"lemma" : "Βελίαλ",
		"kjv" : "Belial",
		"def" : "worthlessness; Belial, as an epithet of Satan",
		"derivation" : "of Hebrew origin (H1100)"
	},
	"G4979" : {
		"def" : "of uncertain derivation); a rushlet, i.e. grass-withe or tie (generally)",
		"derivation" : "diminutive of (a rush or flag-plant",
		"lemma" : "σχοινίον",
		"translit" : "schoiníon",
		"kjv" : "small cord, rope"
	},
	"G2758" : {
		"translit" : "kenóō",
		"lemma" : "κενόω",
		"kjv" : "make (of none effect, of no reputation, void), be in vain",
		"def" : "to make empty, i.e. (figuratively) to abase, neutralize, falsify",
		"derivation" : "from G2756 (κενός)"
	},
	"G143" : {
		"kjv" : "perceive",
		"translit" : "aisthánomai",
		"lemma" : "αἰσθάνομαι",
		"derivation" : "of uncertain derivation",
		"def" : "to apprehend (properly, by the senses)"
	},
	"G3374" : {
		"derivation" : "from (a sheep)",
		"def" : "a sheep-skin",
		"kjv" : "sheepskin",
		"lemma" : "μηλωτή",
		"translit" : "mēlōtḗ"
	},
	"G7" : {
		"lemma" : "Ἀβιά",
		"translit" : "Abiá",
		"kjv" : "Abia",
		"def" : "Abijah, the name of two Israelites",
		"derivation" : "of Hebrew origin (H29)"
	},
	"G5111" : {
		"def" : "probably itself from the base of G5056 (τέλος) through the idea of extreme conduct); to venture (objectively or in act; while G2292 (θαῤῥέω) is rather subjective or in feeling); by implication, to be courageous",
		"derivation" : "from (boldness",
		"lemma" : "τολμάω",
		"translit" : "tolmáō",
		"kjv" : "be bold, boldly, dare, durst"
	},
	"G817" : {
		"derivation" : "a particle probably akin to G427 (ἄνευ)",
		"def" : "aloof, i.e. apart from (literally or figuratively)",
		"kjv" : "in the absence of, without",
		"translit" : "áter",
		"lemma" : "ἄτερ"
	},
	"G4950" : {
		"derivation" : "from G4951 (σύρω)",
		"def" : "a shoal (from the sand drawn thither by the waves), i.e. the Syrtis Major or great bay on the north coast of Africa",
		"kjv" : "quicksands",
		"translit" : "sýrtis",
		"lemma" : "σύρτις"
	},
	"G86" : {
		"kjv" : "grave, hell",
		"lemma" : "ᾅδης",
		"translit" : "háidēs",
		"derivation" : "from G1 (Α) (as negative particle) and G1492 (εἴδω)",
		"def" : "properly, unseen, i.e. \"Hades\" or the place (state) of departed souls"
	},
	"G315" : {
		"def" : "to necessitate",
		"derivation" : "from G318 (ἀνάγκη)",
		"translit" : "anankázō",
		"lemma" : "ἀναγκάζω",
		"kjv" : "compel, constrain"
	},
	"G4278" : {
		"lemma" : "προενάρχομαι",
		"translit" : "proenárchomai",
		"kjv" : "begin (before)",
		"def" : "to commence already",
		"derivation" : "from G4253 (πρό) and G1728 (ἐνάρχομαι)"
	},
	"G4518" : {
		"kjv" : "sabachthani",
		"translit" : "sabachtháni",
		"lemma" : "σαβαχθάνι",
		"derivation" : "of Chaldee or (H7662 with pronominal suffix)",
		"def" : "thou hast left me; sabachthani (i.e. shebakthani), a cry of distress"
	},
	"G5159" : {
		"lemma" : "τροποφορέω",
		"translit" : "tropophoréō",
		"kjv" : "suffer the manners",
		"def" : "to endure one's habits",
		"derivation" : "from G5158 (τρόπος) and G5409 (φορέω)"
	},
	"G1469" : {
		"kjv" : "make of the number",
		"lemma" : "ἐγκρίνω",
		"translit" : "enkrínō",
		"derivation" : "from G1722 (ἐν) and G2919 (κρίνω)",
		"def" : "to judge in, i.e. count among"
	},
	"G5066" : {
		"def" : "pertaining to the fourth day",
		"derivation" : "from G5064 (τέσσαρες)",
		"lemma" : "τεταρταῖος",
		"translit" : "tetartaîos",
		"kjv" : "four days"
	},
	"G5305" : {
		"kjv" : "afterward, (at the) last (of all)",
		"lemma" : "ὕστερον",
		"translit" : "hýsteron",
		"derivation" : "neuter of G5306 (ὕστερος) as adverb",
		"def" : "more lately, i.e. eventually"
	},
	"G1015" : {
		"derivation" : "probably of foreign origin",
		"def" : "a hillock",
		"kjv" : "hill",
		"translit" : "bounós",
		"lemma" : "βουνός"
	},
	"G5127" : {
		"derivation" : "genitive case singular masculine or neuter of G3778 (οὗτος)",
		"def" : "of (from or concerning) this (person or thing)",
		"kjv" : "here(-by), him, it, + such manner of, that, thence(-forth), thereabout, this, thus",
		"translit" : "toútou",
		"lemma" : "τούτου"
	},
	"G580" : {
		"def" : "rejection; figuratively, loss",
		"derivation" : "from G577 (ἀποβάλλω)",
		"lemma" : "ἀποβολή",
		"translit" : "apobolḗ",
		"kjv" : "casting away, loss"
	},
	"G4889" : {
		"kjv" : "fellowservant",
		"translit" : "sýndoulos",
		"lemma" : "σύνδουλος",
		"derivation" : "from G4862 (σύν) and G1401 (δοῦλος)",
		"def" : "a co-slave, i.e. servitor or ministrant of the same master (human or divine)"
	},
	"G3328" : {
		"translit" : "metabállō",
		"lemma" : "μεταβάλλω",
		"kjv" : "change mind",
		"def" : "to throw over, i.e. (middle voice figuratively) to turn about in opinion",
		"derivation" : "from G3326 (μετά) and G906 (βάλλω)"
	},
	"G3709" : {
		"kjv" : "anger, indignation, vengeance, wrath",
		"translit" : "orgḗ",
		"lemma" : "ὀργή",
		"derivation" : "from G3713 (ὀρέγομαι)",
		"def" : "properly, desire (as a reaching forth or excitement of the mind), i.e. (by analogy), violent passion (ire, or (justifiable) abhorrence); by implication punishment"
	},
	"G3600" : {
		"kjv" : "sorrow, torment",
		"translit" : "odynáō",
		"lemma" : "ὀδυνάω",
		"derivation" : "from G3601 (ὀδύνη)",
		"def" : "to grieve"
	},
	"G420" : {
		"derivation" : "from G430 (ἀνέχομαι) and G2556 (κακός)",
		"def" : "enduring of ill, i.e. forbearing",
		"kjv" : "patient",
		"lemma" : "ἀνεξίκακος",
		"translit" : "anexíkakos"
	},
	"G101" : {
		"kjv" : "be impossible",
		"lemma" : "ἀδυνατέω",
		"translit" : "adynatéō",
		"derivation" : "from G102 (ἀδύνατος)",
		"def" : "to be unable, i.e. (passively) impossible"
	},
	"G1837" : {
		"kjv" : "sound forth",
		"lemma" : "ἐξηχέομαι",
		"translit" : "exēchéomai",
		"derivation" : "middle voice from G1537 (ἐκ) and G2278 (ἠχέω)",
		"def" : "to \"echo\" forth, i.e. resound (be generally reported)"
	},
	"G3161" : {
		"kjv" : "Mattathias",
		"translit" : "Mattathías",
		"lemma" : "Ματταθίας",
		"derivation" : "of Hebrew origin (H4993)",
		"def" : "Mattathias (i.e. Mattithjah), an Israelite and a Christian"
	},
	"G2011" : {
		"translit" : "epitropḗ",
		"lemma" : "ἐπιτροπή",
		"kjv" : "commission",
		"def" : "permission, i.e. (by implication) full power",
		"derivation" : "from G2010 (ἐπιτρέπω)"
	},
	"G480" : {
		"def" : "to lie opposite, i.e. be adverse (figuratively, repugnant) to",
		"derivation" : "from G473 (ἀντί) and G2749 (κεῖμαι)",
		"translit" : "antíkeimai",
		"lemma" : "ἀντίκειμαι",
		"kjv" : "adversary, be contrary, oppose"
	},
	"G4821" : {
		"kjv" : "reign with",
		"lemma" : "συμβασιλεύω",
		"translit" : "symbasileúō",
		"derivation" : "from G4862 (σύν) and G936 (βασιλεύω)",
		"def" : "to be co-regent (figuratively)"
	},
	"G1620" : {
		"def" : "to expose; figuratively, to declare",
		"derivation" : "from G1537 (ἐκ) and G5087 (τίθημι)",
		"lemma" : "ἐκτίθημι",
		"translit" : "ektíthēmi",
		"kjv" : "cast out, expound"
	},
	"G1774" : {
		"kjv" : "dwell in",
		"translit" : "enoikéō",
		"lemma" : "ἐνοικέω",
		"derivation" : "from G1722 (ἐν) and G3611 (οἰκέω)",
		"def" : "to inhabit (figuratively)"
	},
	"G4157" : {
		"kjv" : "breath, wind",
		"translit" : "pnoḗ",
		"lemma" : "πνοή",
		"derivation" : "from G4154 (πνέω)",
		"def" : "respiration, a breeze"
	},
	"G3609" : {
		"translit" : "oikeîos",
		"lemma" : "οἰκεῖος",
		"kjv" : "(those) of the (his own) house(-hold)",
		"def" : "domestic, i.e. (as noun), a relative, adherent",
		"derivation" : "from G3624 (οἶκος)"
	},
	"G361" : {
		"kjv" : "that is without sin",
		"translit" : "anamártētos",
		"lemma" : "ἀναμάρτητος",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G264 (ἁμαρτάνω)",
		"def" : "sinless"
	},
	"G2480" : {
		"lemma" : "ἰσχύω",
		"translit" : "ischýō",
		"kjv" : "be able, avail, can do(-not), could, be good, might, prevail, be of strength, be whole, + much work",
		"def" : "to have (or exercise) force (literally or figuratively)",
		"derivation" : "from G2479 (ἰσχύς)"
	},
	"G3868" : {
		"kjv" : "avoid, (make) excuse, intreat, refuse, reject",
		"translit" : "paraitéomai",
		"lemma" : "παραιτέομαι",
		"derivation" : "from G3844 (παρά) and the middle voice of G154 (αἰτέω)",
		"def" : "to beg off, i.e. deprecate, decline, shun"
	},
	"G3" : {
		"derivation" : "of Hebrew origin (H11)",
		"def" : "a destroying angel",
		"kjv" : "Abaddon",
		"lemma" : "Ἀβαδδών",
		"translit" : "Abaddṓn"
	},
	"G4638" : {
		"def" : "an encampment, i.e. (figuratively) the Temple (as God's residence), the body (as a tenement for the soul)",
		"derivation" : "from G4637 (σκηνόω)",
		"translit" : "skḗnōma",
		"lemma" : "σκήνωμα",
		"kjv" : "tabernacle"
	},
	"G5354" : {
		"def" : "to be jealous of",
		"derivation" : "from G5355 (φθόνος)",
		"translit" : "phthonéō",
		"lemma" : "φθονέω",
		"kjv" : "envy"
	},
	"G2766" : {
		"derivation" : "probably from the base of G2767 (κεράννυμι) (through the idea of mixing clay and water)",
		"def" : "earthenware, i.e. a tile (by analogy, a thin roof or awning)",
		"kjv" : "tiling",
		"lemma" : "κέραμος",
		"translit" : "kéramos"
	},
	"G5221" : {
		"kjv" : "(go to) meet",
		"translit" : "hypantáō",
		"lemma" : "ὑπαντάω",
		"derivation" : "from G5259 (ὑπό) and a derivative of G473 (ἀντί)",
		"def" : "to go opposite (meet) under (quietly), i.e. to encounter, fall in with"
	},
	"G2354" : {
		"kjv" : "lament, mourn",
		"lemma" : "θρηνέω",
		"translit" : "thrēnéō",
		"derivation" : "from G2355 (θρῆνος)",
		"def" : "to bewail"
	},
	"G3054" : {
		"lemma" : "λογομαχέω",
		"translit" : "logomachéō",
		"kjv" : "strive about words",
		"def" : "to be disputatious (on trifles)",
		"derivation" : "from a compound of G3056 (λόγος) and G3164 (μάχομαι)"
	},
	"G4005" : {
		"def" : "fiftieth (G2250 (ἡμέρα) being implied) from Passover, i.e. the festival of \"Pentecost\"",
		"derivation" : "feminine of the ordinal of G4004 (πεντήκοντα)",
		"translit" : "pentēkostḗ",
		"lemma" : "πεντηκοστή",
		"kjv" : "Pentecost"
	},
	"G2980" : {
		"def" : "to talk, i.e. utter words",
		"derivation" : "a prolonged form of an otherwise obsolete verb",
		"lemma" : "λαλέω",
		"translit" : "laléō",
		"kjv" : "preach, say, speak (after), talk, tell, utter"
	},
	"G5264" : {
		"kjv" : "receive",
		"translit" : "hypodéchomai",
		"lemma" : "ὑποδέχομαι",
		"derivation" : "from G5259 (ὑπό) and G1209 (δέχομαι)",
		"def" : "to admit under one's roof, i.e. entertain hospitably"
	},
	"G2657" : {
		"derivation" : "from G2596 (κατά) and G3539 (νοιέω)",
		"def" : "to observe fully",
		"kjv" : "behold, consider, discover, perceive",
		"translit" : "katanoéō",
		"lemma" : "κατανοέω"
	},
	"G5460" : {
		"derivation" : "from G5457 (φῶς)",
		"def" : "lustrous, i.e. transparent or well-illuminated (figuratively)",
		"kjv" : "bright, full of light",
		"lemma" : "φωτεινός",
		"translit" : "phōteinós"
	},
	"G483" : {
		"def" : "to dispute, refuse",
		"derivation" : "from G473 (ἀντί) and G3004 (λέγω)",
		"lemma" : "ἀντιλέγω",
		"translit" : "antilégō",
		"kjv" : "answer again, contradict, deny, gainsay(-er), speak against"
	},
	"G2282" : {
		"lemma" : "θάλπω",
		"translit" : "thálpō",
		"kjv" : "cherish",
		"def" : "to brood, i.e. (figuratively) to foster",
		"derivation" : "probably akin to (to warm)"
	},
	"G1913" : {
		"derivation" : "from G1909 (ἐπί) and a reduplicated derivative of the base of G939 (βάσις) (compare G307 (ἀναβιβάζω))",
		"def" : "to cause to mount (an animal)",
		"kjv" : "set on",
		"translit" : "epibibázō",
		"lemma" : "ἐπιβιβάζω"
	},
	"G2843" : {
		"def" : "communicative, i.e. (pecuniarily) liberal",
		"derivation" : "from G2844 (κοινωνός)",
		"translit" : "koinōnikós",
		"lemma" : "κοινωνικός",
		"kjv" : "willing to communicate"
	},
	"G550" : {
		"translit" : "apeipómēn",
		"lemma" : "ἀπειπόμην",
		"kjv" : "renounce",
		"def" : "to say off for oneself, i.e. disown",
		"derivation" : "reflexive past of a compound of G575 (ἀπό) and G2036 (ἔπω)"
	},
	"G2642" : {
		"kjv" : "stone",
		"lemma" : "καταλιθάζω",
		"translit" : "katalitházō",
		"derivation" : "from G2596 (κατά) and G3034 (λιθάζω)",
		"def" : "to stone down, i.e. to death"
	},
	"G4025" : {
		"derivation" : "from G4060 (περιτίθημι)",
		"def" : "a putting all around, i.e. decorating oneself with",
		"kjv" : "wearing",
		"lemma" : "περίθεσις",
		"translit" : "períthesis"
	},
	"G2190" : {
		"derivation" : "from a primary (to hate)",
		"def" : "hateful (passively, odious, or actively, hostile); usually as a noun, an adversary (especially Satan)",
		"kjv" : "enemy, foe",
		"lemma" : "ἐχθρός",
		"translit" : "echthrós"
	},
	"G3485" : {
		"derivation" : "from a primary (to dwell)",
		"def" : "a fane, shrine, temple ",
		"kjv" : "shrine, temple",
		"translit" : "naós",
		"lemma" : "ναός"
	},
	"G5074" : {
		"kjv" : "fourfooted beast",
		"lemma" : "τετράπους",
		"translit" : "tetrápous",
		"derivation" : "from G5064 (τέσσαρες) and G4228 (πούς)",
		"def" : "a quadruped"
	},
	"G1174" : {
		"lemma" : "δεισιδαιμονέστερος",
		"translit" : "deisidaimonésteros",
		"kjv" : "too superstitious",
		"def" : "more religious than others",
		"derivation" : "the compound of a derivative of the base of G1169 (δειλός) and G1142 (δαίμων)"
	},
	"G4749" : {
		"lemma" : "στολή",
		"translit" : "stolḗ",
		"kjv" : "long clothing (garment), (long) robe",
		"def" : "equipment, i.e. (specially), a \"stole\" or long-fitting gown (as a mark of dignity)",
		"derivation" : "from G4724 (στέλλω)"
	},
	"G1482" : {
		"kjv" : "heathen (man)",
		"translit" : "ethnikós",
		"lemma" : "ἐθνικός",
		"derivation" : "from G1484 (ἔθνος)",
		"def" : "national (\"ethnic\"), i.e. (specially) a Gentile"
	},
	"G4564" : {
		"derivation" : "of Hebrew origin (H8283)",
		"def" : "Sarra (i.e. Sarah), the wife of Abraham",
		"kjv" : "Sara, Sarah",
		"lemma" : "Σάῤῥα",
		"translit" : "Sárrha"
	},
	"G5624" : {
		"kjv" : "profit(-able)",
		"lemma" : "ὠφέλιμος",
		"translit" : "ōphélimos",
		"derivation" : "from a form of G3786 (ὄφελος)",
		"def" : "helpful or serviceable, i.e. advantageous"
	},
	"G1204" : {
		"translit" : "deûro",
		"lemma" : "δεῦρο",
		"kjv" : "come (hither), hither(-to)",
		"def" : "here; used also imperative hither!; and of time, hitherto",
		"derivation" : "of uncertain affinity"
	},
	"G3121" : {
		"kjv" : "Maleleel",
		"lemma" : "Μαλελεήλ",
		"translit" : "Maleleḗl",
		"derivation" : "of Hebrew origin (H4111)",
		"def" : "Maleleel (i.e. Mahalalel), an antediluvian"
	},
	"G5358" : {
		"kjv" : "love of good men",
		"lemma" : "φιλάγαθος",
		"translit" : "philágathos",
		"derivation" : "from G5384 (φίλος) and G18 (ἀγαθός)",
		"def" : "fond to good, i.e. a promoter of virtue"
	},
	"G893" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G5579 (ψεῦδος)",
		"def" : "veracious",
		"kjv" : "that cannot lie",
		"lemma" : "ἀψευδής",
		"translit" : "apseudḗs"
	},
	"G3747" : {
		"kjv" : "bone",
		"lemma" : "ὀστέον",
		"translit" : "ostéon",
		"derivation" : "of uncertain affinity",
		"def" : "a bone"
	},
	"G4752" : {
		"derivation" : "from G4754 (στρατεύομαι)",
		"def" : "military service, i.e. (figuratively) the apostolic career (as one of hardship and danger)",
		"kjv" : "warfare",
		"translit" : "strateía",
		"lemma" : "στρατεία"
	},
	"G451" : {
		"kjv" : "Anna",
		"lemma" : "Ἄννα",
		"translit" : "Ánna",
		"derivation" : "of Hebrew origin (H2584)",
		"def" : "Anna, an Israelitess"
	},
	"G372" : {
		"derivation" : "from G373 (ἀναπαύω)",
		"def" : "intermission; by implication, recreation",
		"kjv" : "rest",
		"translit" : "anápausis",
		"lemma" : "ἀνάπαυσις"
	},
	"G777" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G4621 (σῖτος)",
		"def" : "without (taking) food",
		"kjv" : "fasting",
		"lemma" : "ἄσιτος",
		"translit" : "ásitos"
	},
	"G4385" : {
		"derivation" : "from G4253 (πρό) and (to stretch)",
		"def" : "to protend, i.e. tie prostrate (for scourging)",
		"kjv" : "bind",
		"translit" : "proteínō",
		"lemma" : "προτείνω"
	},
	"G1943" : {
		"derivation" : "from G1909 (ἐπί) and G2572 (καλύπτω)",
		"def" : "to conceal, i.e. (figuratively) forgive",
		"kjv" : "cover",
		"lemma" : "ἐπικαλύπτω",
		"translit" : "epikalýptō"
	},
	"G3539" : {
		"translit" : "noiéō",
		"lemma" : "νοιέω",
		"kjv" : "consider, perceive, think, understand",
		"def" : "to exercise the mind (observe), i.e. (figuratively) to comprehend, heed",
		"derivation" : "from G3563 (νοῦς)"
	},
	"G3379" : {
		"derivation" : "from G3361 (μή) and G4218 (ποτέ)",
		"def" : "not ever; also if (or lest) ever (or perhaps)",
		"kjv" : "if peradventure, lest (at any time, haply), not at all, whether or not",
		"lemma" : "μήποτε",
		"translit" : "mḗpote"
	},
	"G2684" : {
		"kjv" : "spy out",
		"translit" : "kataskopéō",
		"lemma" : "κατασκοπέω",
		"derivation" : "from G2685 (κατάσκοπος)",
		"def" : "to be a sentinel, i.e. to inspect insidiously"
	},
	"G273" : {
		"lemma" : "ἄμεμπτος",
		"translit" : "ámemptos",
		"kjv" : "blameless, faultless, unblamable",
		"def" : "irreproachable",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G3201 (μέμφομαι)"
	},
	"G4219" : {
		"kjv" : "+ how long, when",
		"lemma" : "πότε",
		"translit" : "póte",
		"derivation" : "from the base of G4226 (ποῦ) and G5037 (τέ)",
		"def" : "interrogative adverb, at what time"
	},
	"G2774" : {
		"def" : "a principal thing, i.e. main point; specially, an amount (of money)",
		"derivation" : "neuter of a derivative of G2776 (κεφαλή)",
		"translit" : "kephálaion",
		"lemma" : "κεφάλαιον",
		"kjv" : "sum"
	},
	"G4596" : {
		"derivation" : "from (an Indian tribe from whom silk was procured",
		"def" : "hence the name of the silk-worm); Seric, i.e. silken (neuter as noun, a silky fabric)",
		"kjv" : "silk",
		"translit" : "sērikós",
		"lemma" : "σηρικός"
	},
	"G3040" : {
		"def" : "a harbor",
		"derivation" : "apparently a primary word",
		"translit" : "limḗn",
		"lemma" : "λιμήν",
		"kjv" : "haven"
	},
	"G5051" : {
		"lemma" : "τελειωτής",
		"translit" : "teleiōtḗs",
		"kjv" : "finisher",
		"def" : "a completer, i.e. consummater",
		"derivation" : "from G5048 (τελειόω)"
	},
	"G5548" : {
		"kjv" : "anoint",
		"lemma" : "χρίω",
		"translit" : "chríō",
		"derivation" : "probably akin to G5530 (χράομαι) through the idea of contact",
		"def" : "to smear or rub with oil, i.e. (by implication) to consecrate to an office or religious service"
	},
	"G1579" : {
		"derivation" : "from G1537 (ἐκ) and G2860 (κολυμβάω)",
		"def" : "to escape by swimming",
		"kjv" : "swim out",
		"translit" : "ekkolymbáō",
		"lemma" : "ἐκκολυμβάω"
	},
	"G2008" : {
		"derivation" : "from G1909 (ἐπί) and G5091 (τιμάω)",
		"def" : "to tax upon, i.e. censure or admonish; by implication, forbid",
		"kjv" : "(straitly) charge, rebuke",
		"translit" : "epitimáō",
		"lemma" : "ἐπιτιμάω"
	},
	"G3448" : {
		"def" : "a young bullock",
		"derivation" : "probably strengthened for (a shoot)",
		"lemma" : "μόσχος",
		"translit" : "móschos",
		"kjv" : "calf"
	},
	"G414" : {
		"lemma" : "ἀνεκτότερος",
		"translit" : "anektóteros",
		"kjv" : "more tolerable",
		"def" : "more endurable",
		"derivation" : "comparative of a derivative of G430 (ἀνέχομαι)"
	},
	"G978" : {
		"kjv" : "Bithynia",
		"lemma" : "Βιθυνία",
		"translit" : "Bithynía",
		"derivation" : "of uncertain derivation",
		"def" : "Bithynia, a region of Asia"
	},
	"G5140" : {
		"kjv" : "three",
		"lemma" : "τρεῖς",
		"translit" : "treîs",
		"derivation" : "a primary (plural) number",
		"def" : "\"three\""
	},
	"G1430" : {
		"derivation" : "from (to build)",
		"def" : "properly, an edifice, i.e. (specially) a roof",
		"kjv" : "housetop",
		"lemma" : "δῶμα",
		"translit" : "dōma"
	},
	"G2169" : {
		"kjv" : "thankfulness, (giving of) thanks(-giving)",
		"translit" : "eucharistía",
		"lemma" : "εὐχαριστία",
		"derivation" : "from G2170 (εὐχάριστος)",
		"def" : "gratitude; actively, grateful language (to God, as an act of worship)"
	},
	"G3869" : {
		"kjv" : "sit",
		"translit" : "parakathízō",
		"lemma" : "παρακαθίζω",
		"derivation" : "from G3844 (παρά) and G2523 (καθίζω)",
		"def" : "to sit down near"
	},
	"G1594" : {
		"kjv" : "awake",
		"translit" : "eknḗphō",
		"lemma" : "ἐκνήφω",
		"derivation" : "from G1537 (ἐκ) and G3525 (νήφω)",
		"def" : "(figuratively) to rouse (oneself) out of stupor"
	},
	"G3499" : {
		"derivation" : "from G3498 (νεκρός)",
		"def" : "to deaden, i.e. (figuratively) to subdue",
		"kjv" : "be dead, mortify",
		"translit" : "nekróō",
		"lemma" : "νεκρόω"
	},
	"G3970" : {
		"def" : "traditionary",
		"derivation" : "from G3962 (πατήρ) and a derivative of G3860 (παραδίδωμι) (in the sense of handing over or down)",
		"lemma" : "πατροπαράδοτος",
		"translit" : "patroparádotos",
		"kjv" : "received by tradition from fathers"
	},
	"G3365" : {
		"def" : "by no means",
		"derivation" : "adverb from a compound of G3361 (μή) and (somebody)",
		"translit" : "mēdamōs",
		"lemma" : "μηδαμῶς",
		"kjv" : "not so"
	},
	"G3719" : {
		"translit" : "orthrízō",
		"lemma" : "ὀρθρίζω",
		"kjv" : "come early in the morning",
		"def" : "to use the dawn, i.e. (by implication) to repair betimes",
		"derivation" : "from G3722 (ὄρθρος)"
	},
	"G1734" : {
		"def" : "eleventh",
		"derivation" : "ordinal from G1733 (ἕνδεκα)",
		"translit" : "hendékatos",
		"lemma" : "ἑνδέκατος",
		"kjv" : "eleventh"
	},
	"G5018" : {
		"derivation" : "from G5019 (Ταρσός)",
		"def" : "a Tarsean, i.e. native of Tarsus",
		"kjv" : "of Tarsus",
		"lemma" : "Ταρσεύς",
		"translit" : "Tarseús"
	},
	"G4734" : {
		"kjv" : "Stephanas",
		"lemma" : "Στεφανᾶς",
		"translit" : "Stephanâs",
		"derivation" : "probably contraction for (crowned",
		"def" : "from G4737 (στεφανόω)); Stephanas, a Christian"
	},
	"G1063" : {
		"def" : "properly, assigning a reason (used in argument, explanation or intensification; often with other particles)",
		"derivation" : "a primary particle",
		"translit" : "gár",
		"lemma" : "γάρ",
		"kjv" : "and, as, because (that), but, even, for, indeed, no doubt, seeing, then, therefore, verily, what, why, yet"
	},
	"G4644" : {
		"lemma" : "σκληροτράχηλος",
		"translit" : "sklērotráchēlos",
		"kjv" : "stiffnecked",
		"def" : "hardnaped, i.e. (figuratively) obstinate",
		"derivation" : "from G4642 (σκληρός) and G5137 (τράχηλος)"
	},
	"G2720" : {
		"kjv" : "guide, direct",
		"translit" : "kateuthýnō",
		"lemma" : "κατευθύνω",
		"derivation" : "from G2596 (κατά) and G2116 (εὐθύνω)",
		"def" : "to straighten fully, i.e. (figuratively) direct"
	},
	"G5464" : {
		"kjv" : "hail",
		"lemma" : "χάλαζα",
		"translit" : "chálaza",
		"derivation" : "probably from G5465 (χαλάω)",
		"def" : "hail"
	},
	"G5466" : {
		"lemma" : "Χαλδαῖος",
		"translit" : "Chaldaîos",
		"kjv" : "Chaldæan",
		"def" : "a Chaldæan (i.e. Kasdi), or native or the region of the lower Euphrates",
		"derivation" : "probably of Hebrew or (H3778)"
	},
	"G1806" : {
		"lemma" : "ἐξάγω",
		"translit" : "exágō",
		"kjv" : "bring forth (out), fetch (lead) out",
		"def" : "to lead forth",
		"derivation" : "from G1537 (ἐκ) and G71 (ἄγω)"
	},
	"G3119" : {
		"derivation" : "from G3120 (μαλακός)",
		"def" : "softness, i.e. enervation (debility)",
		"kjv" : "disease",
		"translit" : "malakía",
		"lemma" : "μαλακία"
	},
	"G2741" : {
		"derivation" : "from G2740 (καῦσις)",
		"def" : "to set on fire",
		"kjv" : "fervent heat",
		"translit" : "kausóō",
		"lemma" : "καυσόω"
	},
	"G1785" : {
		"translit" : "entolḗ",
		"lemma" : "ἐντολή",
		"kjv" : "commandment, precept",
		"def" : "injunction, i.e. an authoritative prescription",
		"derivation" : "from G1781 (ἐντέλλομαι)"
	},
	"G4794" : {
		"derivation" : "from G4862 (σύν) and G2955 (κύπτω)",
		"def" : "to stoop altogether, i.e. be completely overcome by",
		"kjv" : "bow together",
		"translit" : "synkýptō",
		"lemma" : "συγκύπτω"
	},
	"G4058" : {
		"derivation" : "of uncertain derivation",
		"def" : "a pigeon",
		"kjv" : "dove, pigeon",
		"lemma" : "περιστερά",
		"translit" : "peristerá"
	},
	"G3383" : {
		"kjv" : "neither, (n-)or, so as much",
		"lemma" : "μήτε",
		"translit" : "mḗte",
		"derivation" : "from G3361 (μή) and G5037 (τέ)",
		"def" : "not too, i.e. (in continued negation) neither or nor; also, not even"
	},
	"G214" : {
		"kjv" : "tinkle, wail",
		"lemma" : "ἀλαλάζω",
		"translit" : "alalázō",
		"derivation" : "from ( a shout, \"halloo\")",
		"def" : "to vociferate, i.e. (by implication) to wail; figuratively, to clang"
	},
	"G3057" : {
		"def" : "a \"lance\"",
		"derivation" : "perhaps a primary word",
		"lemma" : "λόγχη",
		"translit" : "lónchē",
		"kjv" : "spear"
	},
	"G5579" : {
		"derivation" : "from G5574 (ψεύδομαι)",
		"def" : "a falsehood",
		"kjv" : "lie, lying",
		"translit" : "pseûdos",
		"lemma" : "ψεῦδος"
	},
	"G4865" : {
		"derivation" : "from G4862 (σύν) and G75 (ἀγωνίζομαι)",
		"def" : "to struggle in company with, i.e. (figuratively) to be a partner (assistant)",
		"kjv" : "strive together with",
		"translit" : "synagōnízomai",
		"lemma" : "συναγωνίζομαι"
	},
	"G676" : {
		"def" : "inaccessible",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of a comparative of G4314 (πρός) and (to go)",
		"lemma" : "ἀπρόσιτος",
		"translit" : "aprósitos",
		"kjv" : "which no man can approach"
	},
	"G427" : {
		"derivation" : "a primary particle",
		"def" : "without",
		"kjv" : "without",
		"translit" : "áneu",
		"lemma" : "ἄνευ"
	},
	"G1640" : {
		"derivation" : "comparative of the same as G1646 (ἐλάχιστος)",
		"def" : "smaller (in size, quantity, age or quality)",
		"kjv" : "less, under, worse, younger",
		"lemma" : "ἐλάσσων",
		"translit" : "elássōn"
	},
	"G1670" : {
		"def" : "to drag (literally or figuratively)",
		"derivation" : "probably akin to G138 (αἱρέομαι)",
		"translit" : "helkýō",
		"lemma" : "ἑλκύω",
		"kjv" : "draw"
	},
	"G3619" : {
		"derivation" : "feminine (abstract) of a compound of G3624 (οἶκος) and the base of G1430 (δῶμα)",
		"def" : "architecture, i.e. (concretely) a structure; figuratively, confirmation",
		"kjv" : "building, edify(-ication, -ing)",
		"lemma" : "οἰκοδομή",
		"translit" : "oikodomḗ"
	},
	"G231" : {
		"translit" : "halieús",
		"lemma" : "ἁλιεύς",
		"kjv" : "fisher(-man)",
		"def" : "a sailor (as engaged on the salt water), i.e. (by implication) a fisher",
		"derivation" : "from G251 (ἅλς)"
	},
	"G3843" : {
		"lemma" : "πάντως",
		"translit" : "pántōs",
		"kjv" : "by all means, altogether, at all, needs, no doubt, in (no) wise, surely",
		"def" : "entirely; specially, at all events, (with negative, following) in no event",
		"derivation" : "adverb from G3956 (πᾶς)"
	},
	"G2575" : {
		"kjv" : "furnace",
		"translit" : "káminos",
		"lemma" : "κάμινος",
		"derivation" : "probably from G2545 (καίω)",
		"def" : "a furnace"
	},
	"G2017" : {
		"kjv" : "give light",
		"translit" : "epiphaúō",
		"lemma" : "ἐπιφαύω",
		"derivation" : "a form of G2014 (ἐπιφαίνω)",
		"def" : "to illuminate (figuratively)"
	},
	"G3350" : {
		"def" : "a change of abode, i.e. (specially), expatriation",
		"derivation" : "from a derivative of a compound of G3326 (μετά) and G3624 (οἶκος)",
		"translit" : "metoikesía",
		"lemma" : "μετοικεσία",
		"kjv" : "X brought, carried(-ying) away (in-)to"
	},
	"G2293" : {
		"def" : "to have courage",
		"derivation" : "from G2294 (θάρσος)",
		"lemma" : "θαρσέω",
		"translit" : "tharséō",
		"kjv" : "be of good cheer (comfort)"
	},
	"G3882" : {
		"kjv" : "sea coast",
		"translit" : "parálios",
		"lemma" : "παράλιος",
		"derivation" : "from G3844 (παρά) and G251 (ἅλς)",
		"def" : "beside the salt (sea), i.e. maritime"
	},
	"G1896" : {
		"lemma" : "ἐπεῖδον",
		"translit" : "epeîdon",
		"kjv" : "behold, look upon",
		"def" : "to regard (favorably or otherwise)",
		"derivation" : "and other moods and persons of the same tense; from G1909 (ἐπί) and G1492 (εἴδω)"
	},
	"G3117" : {
		"kjv" : "far, long",
		"lemma" : "μακρός",
		"translit" : "makrós",
		"derivation" : "from G3372 (μῆκος)",
		"def" : "long (in place (distant) or time (neuter plural))"
	},
	"G1286" : {
		"kjv" : "do violence to",
		"translit" : "diaseíō",
		"lemma" : "διασείω",
		"derivation" : "from G1223 (διά) and G4579 (σείω)",
		"def" : "to shake thoroughly, i.e. (figuratively) to intimidate"
	},
	"G3964" : {
		"kjv" : "murderer of fathers",
		"lemma" : "πατραλῴας",
		"translit" : "patralṓias",
		"derivation" : "from G3962 (πατήρ) and the same as the latter part of G3389 (μητραλῴας)",
		"def" : "a parricide"
	},
	"G2714" : {
		"translit" : "katenṓpion",
		"lemma" : "κατενώπιον",
		"kjv" : "before (the presence of), in the sight of",
		"def" : "directly in front of",
		"derivation" : "from G2596 (κατά) and G1799 (ἐνώπιον)"
	},
	"G4473" : {
		"kjv" : "sprinkling",
		"lemma" : "ῥαντισμός",
		"translit" : "rhantismós",
		"derivation" : "from G4472 (ῥαντίζω)",
		"def" : "aspersion (ceremonially or figuratively)"
	},
	"G418" : {
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of the same as G1735 (ἐνδέχεται)",
		"def" : "unadmitted, i.e. (by implication) not supposable",
		"kjv" : "impossible",
		"translit" : "anéndektos",
		"lemma" : "ἀνένδεκτος"
	},
	"G2546" : {
		"derivation" : "from G2532 (καί) and G1563 (ἐκεῖ)",
		"def" : "likewise in that place",
		"kjv" : "and there, there (thither) also",
		"lemma" : "κἀκεῖ",
		"translit" : "kakeî"
	},
	"G1449" : {
		"translit" : "engráphō",
		"lemma" : "ἐγγράφω",
		"kjv" : "write (in)",
		"def" : "to \"engrave\", i.e. inscribe",
		"derivation" : "from G1722 (ἐν) and G1125 (γράφω)"
	},
	"G5352" : {
		"derivation" : "from derivative of (to wane",
		"def" : "akin to the base of G5351 (φθείρω)) and G3703 (ὀπώρα) (meaning late autumn); autumnal (as stripped of leaves)",
		"kjv" : "whose fruit withereth",
		"lemma" : "φθινοπωρινός",
		"translit" : "phthinopōrinós"
	},
	"G3437" : {
		"kjv" : "quarrel",
		"translit" : "momphḗ",
		"lemma" : "μομφή",
		"derivation" : "from G3201 (μέμφομαι)",
		"def" : "blame, i.e. (by implication), a fault"
	},
	"G3111" : {
		"translit" : "mákellon",
		"lemma" : "μάκελλον",
		"kjv" : "shambles",
		"def" : "a butcher's stall, meat market or provision-shop",
		"derivation" : "of Latin origin (macellum)"
	},
	"G3780" : {
		"translit" : "ouchí",
		"lemma" : "οὐχί",
		"kjv" : "nay, not",
		"def" : "not indeed",
		"derivation" : "intensive of G3756 (οὐ)"
	},
	"G966" : {
		"derivation" : "of Chaldee origin (compare H1004 and H6719)",
		"def" : "fishing-house; Bethsaida, a place in Palestine",
		"kjv" : "Bethsaida",
		"translit" : "Bēthsaïdá",
		"lemma" : "Βηθσαϊδά"
	},
	"G4422" : {
		"kjv" : "frighten",
		"translit" : "ptoéō",
		"lemma" : "πτοέω",
		"derivation" : "probably akin to the alternate of G4098 (πίπτω) (through the idea of causing to fall) or to G4072 (πέτομαι) (through that of causing to fly away)",
		"def" : "to scare"
	},
	"G5116" : {
		"derivation" : "neuter of a presumed derivative (alternately) of (a \"topaz\"",
		"def" : "of uncertain origin); a gem, probably the chrysolite",
		"kjv" : "topaz",
		"lemma" : "τοπάζιον",
		"translit" : "topázion"
	},
	"G5344" : {
		"translit" : "Phēlix",
		"lemma" : "Φῆλιξ",
		"kjv" : "Felix",
		"def" : "happy; Phelix (i.e. Felix), a Roman",
		"derivation" : "of Latin origin"
	},
	"G4382" : {
		"translit" : "prosōpolēpsía",
		"lemma" : "προσωποληψία",
		"kjv" : "respect of persons",
		"def" : "partiality, i.e. favoritism",
		"derivation" : "from G4381 (προσωπολήπτης)"
	},
	"G2477" : {
		"lemma" : "ἱστορέω",
		"translit" : "historéō",
		"kjv" : "see",
		"def" : "to be knowing (learned), i.e. (by implication) to visit for information (interview)",
		"derivation" : "from a derivative of G1492 (εἴδω)"
	},
	"G1267" : {
		"derivation" : "from G1266 (διαμερίζω)",
		"def" : "disunion (of opinion and conduct)",
		"kjv" : "division",
		"lemma" : "διαμερισμός",
		"translit" : "diamerismós"
	},
	"G4751" : {
		"kjv" : "stomach",
		"lemma" : "στόμαχος",
		"translit" : "stómachos",
		"derivation" : "from G4750 (στόμα)",
		"def" : "an orifice (the gullet), i.e. (specially), the \"stomach\""
	},
	"G4096" : {
		"derivation" : "from (fat",
		"def" : "perhaps akin to the alternate of G4095 (πίνω) through the idea of repletion); plumpness, i.e. (by implication) richness (oiliness)",
		"kjv" : "fatness",
		"translit" : "piótēs",
		"lemma" : "πιότης"
	},
	"G4386" : {
		"lemma" : "πρότερον",
		"translit" : "próteron",
		"kjv" : "before, (at the) first, former",
		"def" : "previously",
		"derivation" : "neuter of G4387 (πρότερος) as adverb (with or without the article)"
	},
	"G2590" : {
		"derivation" : "probably from the base of G726 (ἁρπάζω)",
		"def" : "fruit (as plucked), literally or figuratively",
		"kjv" : "fruit",
		"lemma" : "καρπός",
		"translit" : "karpós"
	},
	"G1105" : {
		"derivation" : "akin to G3509 (νέφος)",
		"def" : "gloom (as of a storm)",
		"kjv" : "blackness",
		"lemma" : "γνόφος",
		"translit" : "gnóphos"
	},
	"G4808" : {
		"def" : "a fig-tree",
		"derivation" : "from G4810 (σῦκον)",
		"lemma" : "συκῆ",
		"translit" : "sykē",
		"kjv" : "fig tree"
	},
	"G2179" : {
		"kjv" : "of Ephesus",
		"lemma" : "Ἐφεσῖνος",
		"translit" : "Ephesînos",
		"derivation" : "from G2181 (Ἔφεσος)",
		"def" : "Ephesine, or situated at Ephesus"
	},
	"G3334" : {
		"def" : "to stir to a place elsewhere, i.e. remove (figuratively)",
		"derivation" : "from G3326 (μετά) and G2795 (κινέω)",
		"translit" : "metakinéō",
		"lemma" : "μετακινέω",
		"kjv" : "move away"
	},
	"G3930" : {
		"derivation" : "from G3844 (παρά) and G2192 (ἔχω)",
		"def" : "to hold near, i.e. present, afford, exhibit, furnish occasion",
		"kjv" : "bring, do, give, keep, minister, offer, shew, + trouble",
		"lemma" : "παρέχω",
		"translit" : "paréchō"
	},
	"G1378" : {
		"translit" : "dógma",
		"lemma" : "δόγμα",
		"kjv" : "decree, ordinance",
		"def" : "a law (civil, ceremonial or ecclesiastical)",
		"derivation" : "from the base of G1380 (δοκέω)"
	},
	"G4027" : {
		"derivation" : "from a compound of G4012 (περί) and G2508 (καθαίρω)",
		"def" : "something cleaned off all around, i.e. refuse (figuratively)",
		"kjv" : "filth",
		"lemma" : "περικάθαρμα",
		"translit" : "perikátharma"
	},
	"G3844" : {
		"translit" : "pará",
		"lemma" : "παρά",
		"kjv" : "above, against, among, at, before, by, contrary to, X friend, from, + give (such things as they), + that (she) had, X his, in, more than, nigh unto, (out) of, past, save, side…by, in the sight of, than, (there-)fore, with",
		"def" : "properly, near; i.e. (with genitive case) from beside (literally or figuratively), (with dative case) at (or in) the vicinity of (objectively or subjectively), (with accusative case) to the proximity with (local (especially beyond or opposed to) or causal (on account of)",
		"derivation" : "a primary preposition"
	},
	"G1335" : {
		"def" : "a recital",
		"derivation" : "from G1334 (διηγέομαι)",
		"translit" : "diḗgesis",
		"lemma" : "διήγεσις",
		"kjv" : "declaration"
	},
	"G2628" : {
		"def" : "to accompany closely",
		"derivation" : "from G2596 (κατά) and G190 (ἀκολουθέω)",
		"lemma" : "κατακολουθέω",
		"translit" : "katakolouthéō",
		"kjv" : "follow (after)"
	},
	"G2006" : {
		"lemma" : "ἐπιτήδειος",
		"translit" : "epitḗdeios",
		"kjv" : "things which are needful",
		"def" : "serviceable, i.e. (by implication) requisite",
		"derivation" : "from (enough)"
	},
	"G2757" : {
		"lemma" : "κενοφωνία",
		"translit" : "kenophōnía",
		"kjv" : "vain",
		"def" : "empty sounding, i.e. fruitless discussion",
		"derivation" : "from a presumed compound of G2756 (κενός) and G5456 (φωνή)"
	},
	"G4639" : {
		"derivation" : "apparently a primary word",
		"def" : "\"shade\" or a shadow (literally or figuratively (darkness of error or an adumbration))",
		"kjv" : "shadow",
		"translit" : "skiá",
		"lemma" : "σκιά"
	},
	"G1157" : {
		"def" : "a lender",
		"derivation" : "from G1155 (δανείζω)",
		"translit" : "daneistḗs",
		"lemma" : "δανειστής",
		"kjv" : "creditor"
	},
	"G2716" : {
		"derivation" : "from G2596 (κατά) and G2038 (ἐργάζομαι)",
		"def" : "to work fully, i.e. accomplish; by implication, to finish, fashion",
		"kjv" : "cause, to (deed), perform, work (out)",
		"translit" : "katergázomai",
		"lemma" : "κατεργάζομαι"
	},
	"G2593" : {
		"translit" : "karpophóros",
		"lemma" : "καρποφόρος",
		"kjv" : "fruitful",
		"def" : "fruitbearing (figuratively)",
		"derivation" : "from G2590 (καρπός) and G5342 (φέρω)"
	},
	"G4606" : {
		"kjv" : "of Sidon",
		"lemma" : "Σιδώνιος",
		"translit" : "Sidṓnios",
		"derivation" : "from G4605 (Σιδών)",
		"def" : "a Sidonian, i.e. inhabitant of Sidon"
	},
	"G3953" : {
		"derivation" : "from G3844 (παρά) and the base of G3795 (ὀψάριον)",
		"def" : "a side-dish (the receptacle)",
		"kjv" : "platter",
		"lemma" : "παροψίς",
		"translit" : "paropsís"
	},
	"G5357" : {
		"derivation" : "of uncertain affinity",
		"def" : "a broad shallow cup (\"phial\")",
		"kjv" : "vial",
		"translit" : "phiálē",
		"lemma" : "φιάλη"
	},
	"G5430" : {
		"def" : "prudently",
		"derivation" : "adverb from G5429 (φρόνιμος)",
		"lemma" : "φρονίμως",
		"translit" : "phronímōs",
		"kjv" : "wisely"
	},
	"G1402" : {
		"kjv" : "bring into (be under) bondage, X given, become (make) servant",
		"translit" : "doulóō",
		"lemma" : "δουλόω",
		"derivation" : "from G1401 (δοῦλος)",
		"def" : "to enslave (literally or figuratively)"
	},
	"G882" : {
		"kjv" : "Achaia",
		"lemma" : "Ἀχαΐα",
		"translit" : "Achaḯa",
		"derivation" : "of uncertain derivation",
		"def" : "Achaia (i.e. Greece), a country of Europe"
	},
	"G3337" : {
		"derivation" : "from G3326 (μετά) and G236 (ἀλλάσσω)",
		"def" : "to exchange",
		"kjv" : "change",
		"lemma" : "μεταλλάσσω",
		"translit" : "metallássō"
	},
	"G1819" : {
		"kjv" : "suddenly",
		"translit" : "exápina",
		"lemma" : "ἐξάπινα",
		"derivation" : "from G1537 (ἐκ) and a derivative of the same as G160 (αἰφνίδιος)",
		"def" : "of a sudden, i.e. unexpectedly"
	},
	"G2696" : {
		"translit" : "katasphragízō",
		"lemma" : "κατασφραγίζω",
		"kjv" : "seal",
		"def" : "to seal closely",
		"derivation" : "from G2596 (κατά) and G4972 (σφραγίζω)"
	},
	"G4151" : {
		"lemma" : "πνεῦμα",
		"translit" : "pneûma",
		"kjv" : "ghost, life, spirit(-ual, -ually), mind",
		"def" : "a current of air, i.e. breath (blast) or a breeze; by analogy or figuratively, a spirit, i.e. (human) the rational soul, (by implication) vital principle, mental disposition, etc., or (superhuman) an angel, demon, or (divine) God, Christ's spirit, the Holy Spirit",
		"derivation" : "from G4154 (πνέω)"
	},
	"G4978" : {
		"lemma" : "σχίσμα",
		"translit" : "schísma",
		"kjv" : "division, rent, schism",
		"def" : "a split or gap (\"schism\"), literally or figuratively",
		"derivation" : "from G4977 (σχίζω)"
	},
	"G4338" : {
		"def" : "to peg to, i.e. spike fast",
		"derivation" : "from G4314 (πρός) and a derivative of G2247 (ἧλος)",
		"lemma" : "προσηλόω",
		"translit" : "prosēlóō",
		"kjv" : "nail to"
	},
	"G2908" : {
		"def" : "(as noun) better, i.e. greater advantage",
		"derivation" : "neuter of an alternate form of G2909 (κρείττων)",
		"translit" : "kreîsson",
		"lemma" : "κρεῖσσον",
		"kjv" : "better"
	},
	"G4421" : {
		"kjv" : "bird",
		"translit" : "ptēnón",
		"lemma" : "πτηνόν",
		"derivation" : "contraction for G4071 (πετεινόν)",
		"def" : "a bird"
	},
	"G4001" : {
		"def" : "five hundred",
		"derivation" : "from G4002 (πέντε) and G1540 (ἑκατόν)",
		"translit" : "pentakósioi",
		"lemma" : "πεντακόσιοι",
		"kjv" : "five hundred"
	},
	"G4619" : {
		"kjv" : "fatling",
		"translit" : "sitistós",
		"lemma" : "σιτιστός",
		"derivation" : "from a derivative of G4621 (σῖτος)",
		"def" : "grained, i.e. fatted"
	},
	"G2013" : {
		"lemma" : "ἐπιτυγχάνω",
		"translit" : "epitynchánō",
		"kjv" : "obtain",
		"def" : "to chance upon, i.e. (by implication) to attain",
		"derivation" : "from G1909 (ἐπί) and G5177 (τυγχάνω)"
	},
	"G3306" : {
		"translit" : "ménō",
		"lemma" : "μένω",
		"kjv" : "abide, continue, dwell, endure, be present, remain, stand, tarry (for), X thine own",
		"def" : "to stay (in a given place, state, relation or expectancy)",
		"derivation" : "a primary verb"
	},
	"G568" : {
		"kjv" : "be, have, receive",
		"translit" : "apéchō",
		"lemma" : "ἀπέχω",
		"derivation" : "from G575 (ἀπό) and G2192 (ἔχω)",
		"def" : "(actively) to have out, i.e. receive in full; (intransitively) to keep (oneself) away, i.e. be distant (literally or figuratively)"
	},
	"G2028" : {
		"lemma" : "ἐπονομάζω",
		"translit" : "eponomázō",
		"kjv" : "call",
		"def" : "to name further, i.e. denominate",
		"derivation" : "from G1909 (ἐπί) and G3687 (ὀνομάζω)"
	},
	"G2853" : {
		"derivation" : "from (\"glue\")",
		"def" : "to glue, i.e. (passively or reflexively) to stick (figuratively)",
		"kjv" : "cleave, join (self), keep company",
		"lemma" : "κολλάω",
		"translit" : "kolláō"
	},
	"G2333" : {
		"kjv" : "Theudas",
		"lemma" : "Θευδᾶς",
		"translit" : "Theudâs",
		"derivation" : "of uncertain origin",
		"def" : "Theudas, an Israelite"
	},
	"G1847" : {
		"translit" : "exoudenóō",
		"lemma" : "ἐξουδενόω",
		"kjv" : "set at nought",
		"def" : "to make utterly nothing of, i.e. despise",
		"derivation" : "from G1537 (ἐκ) and a derivative of the neuter of G3762 (οὐδείς)"
	},
	"G872" : {
		"def" : "to consider attentively",
		"derivation" : "from G575 (ἀπό) and G3708 (ὁράω)",
		"lemma" : "ἀφοράω",
		"translit" : "aphoráō",
		"kjv" : "look"
	},
	"G4629" : {
		"derivation" : "from a derivative of (a covering",
		"def" : "perhaps akin to the base of G4649 (σκοπός) through the idea of noticeableness); clothing",
		"kjv" : "raiment",
		"lemma" : "σκέπασμα",
		"translit" : "sképasma"
	},
	"G579" : {
		"kjv" : "be refused",
		"lemma" : "ἀπόβλητος",
		"translit" : "apóblētos",
		"derivation" : "from G577 (ἀποβάλλω)",
		"def" : "cast off, i.e. (figuratively) such as to be rejected"
	},
	"G1721" : {
		"kjv" : "engrafted",
		"translit" : "émphytos",
		"lemma" : "ἔμφυτος",
		"derivation" : "from G1722 (ἐν) and a derivative of G5453 (φύω)",
		"def" : "implanted (figuratively)"
	},
	"G163" : {
		"derivation" : "from G164 (αἰχμάλωτος)",
		"def" : "to make captive",
		"kjv" : "lead away captive, bring into captivity",
		"lemma" : "αἰχμαλωτίζω",
		"translit" : "aichmalōtízō"
	},
	"G104" : {
		"def" : "\"ever,\" by qualification regularly; by implication, earnestly",
		"derivation" : "from an obsolete primary noun (apparently meaning continued duration)",
		"lemma" : "ἀεί",
		"translit" : "aeí",
		"kjv" : "always, ever"
	},
	"G2202" : {
		"kjv" : "band",
		"translit" : "zeuktēría",
		"lemma" : "ζευκτηρία",
		"derivation" : "feminine of a derivative (at the second stage) from the same as G2218 (ζυγός)",
		"def" : "a fastening (tiller-rope)"
	},
	"G761" : {
		"kjv" : "which cannot be moved, unmovable",
		"translit" : "asáleutos",
		"lemma" : "ἀσάλευτος",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G4531 (σαλεύω)",
		"def" : "unshaken, i.e. (by implication) immovable (figuratively)"
	},
	"G4616" : {
		"kjv" : "(fine) linen (cloth)",
		"lemma" : "σινδών",
		"translit" : "sindṓn",
		"derivation" : "of uncertain (perhaps foreign) origin",
		"def" : "byssos, i.e. bleached linen (the cloth or a garment of it)"
	},
	"G4868" : {
		"translit" : "synaírō",
		"lemma" : "συναίρω",
		"kjv" : "reckon, take",
		"def" : "to make up together, i.e. (figuratively) to compute (an account)",
		"derivation" : "from G4862 (σύν) and G142 (αἴρω)"
	},
	"G4654" : {
		"def" : "to obscure (literally or figuratively)",
		"derivation" : "from G4655 (σκότος)",
		"lemma" : "σκοτίζω",
		"translit" : "skotízō",
		"kjv" : "darken"
	},
	"G490" : {
		"kjv" : "Antioch",
		"lemma" : "Ἀντιόχεια",
		"translit" : "Antiócheia",
		"derivation" : "from (a Syrian king)",
		"def" : "Antiochia, a place in Syria"
	},
	"G172" : {
		"def" : "not bad, i.e. (objectively) innocent or (subjectively) unsuspecting",
		"derivation" : "from G1 (Α) (as a negative particle) and G2556 (κακός)",
		"lemma" : "ἄκακος",
		"translit" : "ákakos",
		"kjv" : "harmless, simple"
	},
	"G5082" : {
		"derivation" : "from a compound of G3588 (ὁ) with G2245 (ἡλίκος) and G3778 (οὗτος)",
		"def" : "such as this, i.e. (in (figurative) magnitude) so vast",
		"kjv" : "so great, so mighty",
		"lemma" : "τηλικοῦτος",
		"translit" : "tēlikoûtos"
	},
	"G768" : {
		"kjv" : "Aser",
		"translit" : "Asḗr",
		"lemma" : "Ἀσήρ",
		"derivation" : "of Hebrew origin (H836)",
		"def" : "Aser (i.e. Asher), an Israelite tribe"
	},
	"G3927" : {
		"lemma" : "παρεπίδημος",
		"translit" : "parepídēmos",
		"kjv" : "pilgrim, stranger",
		"def" : "an alien alongside, i.e. a resident foreigner",
		"derivation" : "from G3844 (παρά) and the base of G1927 (ἐπιδημέω)"
	},
	"G1281" : {
		"kjv" : "gain by trading",
		"translit" : "diapragmateúomai",
		"lemma" : "διαπραγματεύομαι",
		"derivation" : "from G1223 (διά) and G4231 (πραγματεύομαι)",
		"def" : "to thoroughly occupy oneself, i.e. (transitively and by implication) to earn in business"
	},
	"G1653" : {
		"def" : "to compassionate (by word or deed, specially, by divine grace)",
		"derivation" : "from G1656 (ἔλεος)",
		"lemma" : "ἐλεέω",
		"translit" : "eleéō",
		"kjv" : "have compassion (pity on), have (obtain, receive, shew) mercy (on)"
	},
	"G4542" : {
		"kjv" : "of Samaria",
		"lemma" : "Σαμαρεῖτις",
		"translit" : "Samareîtis",
		"derivation" : "feminine of G4541 (Σαμαρείτης)",
		"def" : "a Samaritess, i.e. woman of Samaria"
	},
	"G1507" : {
		"kjv" : "roll together",
		"lemma" : "εἱλίσσω",
		"translit" : "heilíssō",
		"derivation" : "a prolonged form of a primary but defective verb (of the same meaning)",
		"def" : "to coil or wrap"
	},
	"G1092" : {
		"kjv" : "husbandman",
		"lemma" : "γεωργός",
		"translit" : "geōrgós",
		"derivation" : "from G1093 (γῆ) and the base of G2041 (ἔργον)",
		"def" : "a land-worker, i.e. farmer"
	},
	"G1739" : {
		"derivation" : "from a compound of G1722 (ἐν) and a derivative of the base of G1218 (δῆμος)",
		"def" : "a housing in (residence), i.e. structure",
		"kjv" : "building",
		"translit" : "endómēsis",
		"lemma" : "ἐνδόμησις"
	},
	"G3588" : {
		"derivation" : "the definite article",
		"def" : "the (sometimes to be supplied, at others omitted, in English idiom)",
		"kjv" : "the, this, that, one, he, she, it, etc.",
		"translit" : "ho",
		"lemma" : "ὁ"
	},
	"G1415" : {
		"lemma" : "δυνατός",
		"translit" : "dynatós",
		"kjv" : "able, could, (that is) mighty (man), possible, power, strong",
		"def" : "powerful or capable (literally or figuratively); neuter possible",
		"derivation" : "from G1410 (δύναμαι)"
	},
	"G4079" : {
		"derivation" : "neuter of a (presumed) derivative of (the blade of an oar",
		"def" : "from the same as G3976 (πέδη)); a \"pedal\", i.e. helm",
		"kjv" : "rudder",
		"translit" : "pēdálion",
		"lemma" : "πηδάλιον"
	},
	"G4574" : {
		"derivation" : "from G4573 (σεβάζομαι)",
		"def" : "something adored, i.e. an object of worship (god, altar, etc)",
		"kjv" : "devotion, that is worshipped",
		"translit" : "sébasma",
		"lemma" : "σέβασμα"
	},
	"G4664" : {
		"def" : "consisting of emerald",
		"derivation" : "from G4665 (σμάραγδος)",
		"lemma" : "σμαράγδινος",
		"translit" : "smarágdinos",
		"kjv" : "emerald"
	},
	"G2533" : {
		"kjv" : "Caiaphas",
		"lemma" : "Καϊάφας",
		"translit" : "Kaïáphas",
		"derivation" : "of Chaldee origin",
		"def" : "the dell; Caiaphas (i.e. Cajepha), an Israelite"
	},
	"G3335" : {
		"def" : "to participate; genitive case, to accept (and use)",
		"derivation" : "from G3326 (μετά) and G2983 (λαμβάνω)",
		"translit" : "metalambánō",
		"lemma" : "μεταλαμβάνω",
		"kjv" : "eat, have, be partaker, receive, take"
	},
	"G2788" : {
		"kjv" : "harp",
		"lemma" : "κιθάρα",
		"translit" : "kithára",
		"derivation" : "of uncertain affinity",
		"def" : "a lyre"
	},
	"G912" : {
		"translit" : "Barabbâs",
		"lemma" : "Βαραββᾶς",
		"kjv" : "Barabbas",
		"def" : "son of Abba; Bar-abbas, an Israelite",
		"derivation" : "of Chaldee origin (H1347 and H5)"
	},
	"G5192" : {
		"kjv" : "jacinth",
		"lemma" : "ὑάκινθος",
		"translit" : "hyákinthos",
		"derivation" : "of uncertain derivation",
		"def" : "the \"hyacinth\" or \"jacinth\", i.e. some gem of a deep blue color, probably the zirkon"
	},
	"G3185" : {
		"lemma" : "μεῖζον",
		"translit" : "meîzon",
		"kjv" : "the more",
		"def" : "(adverbially) in greater degree",
		"derivation" : "neuter of G3187 (μείζων)"
	},
	"G3841" : {
		"def" : "the all-ruling, i.e. God (as absolute and universal sovereign)",
		"derivation" : "from G3956 (πᾶς) and G2904 (κράτος)",
		"translit" : "pantokrátōr",
		"lemma" : "παντοκράτωρ",
		"kjv" : "Almighty, Omnipotent"
	},
	"G2623" : {
		"derivation" : "from G2596 (κατά) and G2808 (κλείω)",
		"def" : "to shut down (in a dungeon), i.e. incarcerate",
		"kjv" : "shut up",
		"lemma" : "κατακλείω",
		"translit" : "katakleíō"
	},
	"G1940" : {
		"lemma" : "ἐπικαθίζω",
		"translit" : "epikathízō",
		"kjv" : "set on",
		"def" : "to seat upon",
		"derivation" : "from G1909 (ἐπί) and G2523 (καθίζω)"
	},
	"G5388" : {
		"translit" : "philóteknos",
		"lemma" : "φιλότεκνος",
		"kjv" : "love their children",
		"def" : "fond of one's children, i.e. maternal",
		"derivation" : "from G5384 (φίλος) and G5043 (τέκνον)"
	},
	"G1315" : {
		"def" : "to handle thoroughly, i.e. lay violent hands upon",
		"derivation" : "from G1223 (διά) and a derivative of G5495 (χείρ)",
		"lemma" : "διαχειρίζομαι",
		"translit" : "diacheirízomai",
		"kjv" : "kill, slay"
	},
	"G5498" : {
		"kjv" : "handwriting",
		"translit" : "cheirógraphon",
		"lemma" : "χειρόγραφον",
		"derivation" : "neuter of a compound of G5495 (χείρ) and G1125 (γράφω)",
		"def" : "something hand-written (\"chirograph\"), i.e. a manuscript (specially, a legal document or bond (figuratively))"
	},
	"G335" : {
		"kjv" : "importunity",
		"translit" : "anaídeia",
		"lemma" : "ἀναίδεια",
		"derivation" : "from a compound of G1 (Α) (as a negative particle (compare G427 (ἄνευ))) and G127 (αἰδώς)",
		"def" : "impudence, i.e. (by implication) importunity"
	},
	"G1512" : {
		"derivation" : "from G1487 (εἰ) and G4007 (περ)",
		"def" : "if perhaps",
		"kjv" : "if so be (that), seeing, though",
		"lemma" : "εἴ περ",
		"translit" : "eí per"
	},
	"G505" : {
		"kjv" : "without dissimulation (hypocrisy), unfeigned",
		"translit" : "anypókritos",
		"lemma" : "ἀνυπόκριτος",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G5271 (ὑποκρίνομαι)",
		"def" : "undissembled, i.e. sincere"
	},
	"G4367" : {
		"derivation" : "from G4314 (πρός) and G5021 (τάσσω)",
		"def" : "to arrange towards, i.e. (figuratively) enjoin",
		"kjv" : "bid, command",
		"lemma" : "προστάσσω",
		"translit" : "prostássō"
	},
	"G5510" : {
		"derivation" : "perhaps akin to the base of G5490 (χάσμα) (G5465 (χαλάω)) or G5494 (χειμών) (as descending or empty)",
		"def" : "snow",
		"kjv" : "snow",
		"translit" : "chiṓn",
		"lemma" : "χιών"
	},
	"G1922" : {
		"kjv" : "(ac-)knowledge(-ing, - ment)",
		"lemma" : "ἐπίγνωσις",
		"translit" : "epígnōsis",
		"derivation" : "from G1921 (ἐπιγινώσκω)",
		"def" : "recognition, i.e. (by implication) full discernment, acknowledgement"
	},
	"G412" : {
		"def" : "not spoken out, i.e. (by implication) unutterable",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G1583 (ἐκλαλέω)",
		"translit" : "aneklálētos",
		"lemma" : "ἀνεκλάλητος",
		"kjv" : "unspeakable"
	},
	"G2251" : {
		"kjv" : "our, your (by a different reading)",
		"translit" : "hēméteros",
		"lemma" : "ἡμέτερος",
		"derivation" : "from G2349 (θνητός)",
		"def" : "our"
	},
	"G5293" : {
		"translit" : "hypotássō",
		"lemma" : "ὑποτάσσω",
		"kjv" : "be under obedience (obedient), put under, subdue unto, (be, make) subject (to, unto), be (put) in subjection (to, under), submit self unto",
		"def" : "to subordinate; reflexively, to obey",
		"derivation" : "from G5259 (ὑπό) and G5021 (τάσσω)"
	},
	"G3500" : {
		"lemma" : "νέκρωσις",
		"translit" : "nékrōsis",
		"kjv" : "deadness, dying",
		"def" : "decease; figuratively, impotency",
		"derivation" : "from G3499 (νεκρόω)"
	},
	"G3085" : {
		"def" : "a ransoming (figuratively)",
		"derivation" : "from G3084 (λυτρόω)",
		"lemma" : "λύτρωσις",
		"translit" : "lýtrōsis",
		"kjv" : "+ redeemed, redemption"
	},
	"G2436" : {
		"kjv" : "be it far, merciful",
		"lemma" : "ἵλεως",
		"translit" : "híleōs",
		"derivation" : "perhaps from the alternate form of G138 (αἱρέομαι)",
		"def" : "cheerful (as attractive), i.e. propitious; adverbially (by Hebraism) God be gracious!, i.e. (in averting some calamity) far be it"
	},
	"G1981" : {
		"lemma" : "ἐπισκηνόω",
		"translit" : "episkēnóō",
		"kjv" : "rest upon",
		"def" : "to tent upon, i.e. (figuratively) abide with ",
		"derivation" : "from G1909 (ἐπί) and G4637 (σκηνόω)"
	},
	"G1765" : {
		"derivation" : "from G1722 (ἐν) and G2480 (ἰσχύω)",
		"def" : "to invigorate (transitively or reflexively)",
		"kjv" : "strengthen",
		"lemma" : "ἐνισχύω",
		"translit" : "enischýō"
	},
	"G2167" : {
		"derivation" : "from the same as G2165 (εὐφραίνω)",
		"def" : "joyfulness",
		"kjv" : "gladness, joy",
		"translit" : "euphrosýnē",
		"lemma" : "εὐφροσύνη"
	},
	"G5366" : {
		"def" : "fond of silver (money), i.e. avaricious",
		"derivation" : "from G5384 (φίλος) and G696 (ἄργυρος)",
		"lemma" : "φιλάργυρος",
		"translit" : "philárgyros",
		"kjv" : "covetous"
	},
	"G3754" : {
		"kjv" : "as concerning that, as though, because (that), for (that), how (that), (in) that, though, why",
		"translit" : "hóti",
		"lemma" : "ὅτι",
		"derivation" : "neuter of G3748 (ὅστις) as conjunction",
		"def" : "demonstrative, that (sometimes redundant); causative, because"
	},
	"G5598" : {
		"kjv" : "Omega",
		"translit" : "Ō",
		"lemma" : "Ω",
		"def" : "the last letter of the Greek alphabet, i.e. (figuratively) the finality"
	},
	"G539" : {
		"def" : "delusion",
		"derivation" : "from G538 (ἀπατάω)",
		"translit" : "apátē",
		"lemma" : "ἀπάτη",
		"kjv" : "deceit(-ful, -fulness), deceivableness(-ving)"
	},
	"G4061" : {
		"def" : "circumcision (the rite, the condition or the people, literally or figuratively)",
		"derivation" : "from G4059 (περιτέμνω)",
		"translit" : "peritomḗ",
		"lemma" : "περιτομή",
		"kjv" : "X circumcised, circumcision"
	},
	"G1278" : {
		"lemma" : "διαπονέω",
		"translit" : "diaponéō",
		"kjv" : "be grieved",
		"def" : "to toil through, i.e. (passively) be worried",
		"derivation" : "from G1223 (διά) and a derivative of G4192 (πόνος)"
	},
	"G5557" : {
		"kjv" : "gold",
		"translit" : "chrysós",
		"lemma" : "χρυσός",
		"derivation" : "perhaps from the base of G5530 (χράομαι) (through the idea of the utility of the metal)",
		"def" : "gold; by extension, a golden article, as an ornament or coin"
	},
	"G5294" : {
		"def" : "to place underneath, i.e. (figuratively) to hazard, (reflexively) to suggest",
		"derivation" : "from G5259 (ὑπό) and G5087 (τίθημι)",
		"lemma" : "ὑποτίθημι",
		"translit" : "hypotíthēmi",
		"kjv" : "lay down, put in remembrance"
	},
	"G1524" : {
		"derivation" : "from G1519 (εἰς) and (to go)",
		"def" : "to enter",
		"kjv" : "enter (go) into",
		"translit" : "eíseimi",
		"lemma" : "εἴσειμι"
	},
	"G4617" : {
		"def" : "to riddle (figuratively)",
		"derivation" : "from (a sieve)",
		"lemma" : "σινιάζω",
		"translit" : "siniázō",
		"kjv" : "sift"
	},
	"G2081" : {
		"translit" : "ésōthen",
		"lemma" : "ἔσωθεν",
		"kjv" : "inward(-ly), (from) within, without",
		"def" : "from inside; also used as equivalent to G2080 (ἔσω) (inside)",
		"derivation" : "from G2080 (ἔσω)"
	},
	"G2438" : {
		"derivation" : "perhaps from the same as G260 (ἅμα)",
		"def" : "a strap, i.e. (specially) the tie (of a sandal) or the lash (of a scourge)",
		"kjv" : "latchet, thong",
		"translit" : "himás",
		"lemma" : "ἱμάς"
	},
	"G3653" : {
		"kjv" : "untimely fig",
		"translit" : "ólynthos",
		"lemma" : "ὄλυνθος",
		"derivation" : "of uncertain derivation",
		"def" : "an unripe (because out of season) fig"
	},
	"G825" : {
		"derivation" : "from (a king of Pergamus)",
		"def" : "Attaleia, a place in Pamphylia",
		"kjv" : "Attalia",
		"translit" : "Attáleia",
		"lemma" : "Ἀττάλεια"
	},
	"G22" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G1062 (γάμος)",
		"def" : "unmarried",
		"kjv" : "unmarried",
		"lemma" : "ἄγαμος",
		"translit" : "ágamos"
	},
	"G2768" : {
		"def" : "a horn (literally or figuratively)",
		"derivation" : "from a primary (the hair of the head)",
		"translit" : "kéras",
		"lemma" : "κέρας",
		"kjv" : "horn"
	},
	"G3663" : {
		"lemma" : "ὁμοιοπαθής",
		"translit" : "homoiopathḗs",
		"kjv" : "of (subject to) like passions",
		"def" : "similarly affected",
		"derivation" : "from G3664 (ὅμοιος) and the alternate of G3958 (πάσχω)"
	},
	"G1788" : {
		"derivation" : "from G1722 (ἐν) and the base of G5157 (τροπή)",
		"def" : "to invert, i.e. (figuratively and reflexively) in a good sense, to respect; or in a bad one, to confound",
		"kjv" : "regard, (give) reference, shame",
		"translit" : "entrépō",
		"lemma" : "ἐντρέπω"
	},
	"G197" : {
		"def" : "(adverbially) more exactly",
		"derivation" : "neuter of the comparative of the same as G196 (ἀκριβέστατος)",
		"translit" : "akribésteron",
		"lemma" : "ἀκριβέστερον",
		"kjv" : "more perfect(-ly)"
	},
	"G2890" : {
		"def" : "Quartus, a Christian",
		"derivation" : "of Latin origin (fourth)",
		"lemma" : "Κούαρτος",
		"translit" : "Koúartos",
		"kjv" : "Quartus"
	},
	"G3865" : {
		"kjv" : "neglect",
		"translit" : "paratheōréō",
		"lemma" : "παραθεωρέω",
		"derivation" : "from G3844 (παρά) and G2334 (θεωρέω)",
		"def" : "to overlook or disregard"
	},
	"G2217" : {
		"kjv" : "blackness, darkness, mist",
		"translit" : "zóphos",
		"lemma" : "ζόφος",
		"derivation" : "akin to the base of G3509 (νέφος)",
		"def" : "gloom (as shrouding like a cloud)"
	},
	"G2027" : {
		"kjv" : "run aground",
		"translit" : "epokéllō",
		"lemma" : "ἐποκέλλω",
		"derivation" : "from G1909 (ἐπί) and (to urge)",
		"def" : "to drive upon the shore, i.e. to beach a vessel"
	},
	"G4470" : {
		"derivation" : "from G4486 (ῥήγνυμι)",
		"def" : "a \"rag,\" i.e. piece of cloth",
		"kjv" : "cloth",
		"lemma" : "ῥάκος",
		"translit" : "rhákos"
	},
	"G543" : {
		"derivation" : "from G545 (ἀπειθής)",
		"def" : "disbelief (obstinate and rebellious)",
		"kjv" : "disobedience, unbelief",
		"translit" : "apeítheia",
		"lemma" : "ἀπείθεια"
	},
	"G1230" : {
		"derivation" : "from G1223 (διά) and G1096 (γίνομαι)",
		"def" : "to elapse meanwhile",
		"kjv" : "X after, be past, be spent",
		"lemma" : "διαγίνομαι",
		"translit" : "diagínomai"
	},
	"G1953" : {
		"kjv" : "X forgetful",
		"translit" : "epilēsmonḗ",
		"lemma" : "ἐπιλησμονή",
		"derivation" : "from a derivative of G1950 (ἐπιλανθάνομαι)",
		"def" : "negligence"
	},
	"G4170" : {
		"kjv" : "fight, (make) war",
		"lemma" : "πολεμέω",
		"translit" : "poleméō",
		"derivation" : "from G4171 (πόλεμος)",
		"def" : "to be (engaged) in warfare, i.e. to battle (literally or figuratively)"
	},
	"G5297" : {
		"kjv" : "bear, endure",
		"lemma" : "ὑποφέρω",
		"translit" : "hypophérō",
		"derivation" : "from G5259 (ὑπό) and G5342 (φέρω)",
		"def" : "to bear from underneath, i.e. (figuratively) to undergo hardship"
	},
	"G1244" : {
		"def" : "to separate, i.e. distribute",
		"derivation" : "from G1223 (διά) and G138 (αἱρέομαι)",
		"translit" : "diairéō",
		"lemma" : "διαιρέω",
		"kjv" : "divide"
	},
	"G4021" : {
		"kjv" : "busybody, curious arts",
		"translit" : "períergos",
		"lemma" : "περίεργος",
		"derivation" : "from G4012 (περί) and G2041 (ἔργον)",
		"def" : "working all around, i.e. officious (meddlesome, neuter plural magic)"
	},
	"G3922" : {
		"def" : "to come in alongside, i.e. supervene additionally or steathily",
		"derivation" : "from G3844 (παρά) and G1525 (εἰσέρχομαι)",
		"lemma" : "παρεισέρχομαι",
		"translit" : "pareisérchomai",
		"kjv" : "come in privily, enter"
	},
	"G867" : {
		"kjv" : "departing",
		"lemma" : "ἄφιξις",
		"translit" : "áphixis",
		"derivation" : "from G864 (ἀφικνέομαι)",
		"def" : "properly, arrival, i.e. (by implication) departure"
	},
	"G4570" : {
		"lemma" : "σβέννυμι",
		"translit" : "sbénnymi",
		"kjv" : "go out, quench",
		"def" : "to extinguish (literally or figuratively)",
		"derivation" : "a prolonged form of an apparently primary verb"
	},
	"G5101" : {
		"kjv" : "every man, how (much), + no(-ne, thing), what (manner, thing), where (-by, -fore, -of, -unto, - with, -withal), whether, which, who(-m, -se), why",
		"translit" : "tís",
		"lemma" : "τίς",
		"derivation" : "probably emphatic of G5100 (τὶς)",
		"def" : "an interrogative pronoun, who, which or what (in direct or indirect questions)"
	},
	"G2162" : {
		"kjv" : "good report",
		"lemma" : "εὐφημία",
		"translit" : "euphēmía",
		"derivation" : "from G2163 (εὔφημος)",
		"def" : "good language (\"euphemy\"), i.e. praise (repute)"
	},
	"G3842" : {
		"derivation" : "from G3956 (πᾶς) and G3753 (ὅτε)",
		"def" : "every when, i.e. at all times",
		"kjv" : "alway(-s), ever(-more)",
		"lemma" : "πάντοτε",
		"translit" : "pántote"
	},
	"G5483" : {
		"def" : "to grant as a favor, i.e. gratuitously, in kindness, pardon or rescue",
		"derivation" : "middle voice from G5485 (χάρις)",
		"translit" : "charízomai",
		"lemma" : "χαρίζομαι",
		"kjv" : "deliver, (frankly) forgive, (freely) give, grant"
	},
	"G4135" : {
		"derivation" : "from G4134 (πλήρης) and G5409 (φορέω)",
		"def" : "to carry out fully (in evidence), i.e. completely assure (or convince), entirely accomplish",
		"kjv" : "most surely believe, fully know (persuade), make full proof of",
		"lemma" : "πληροφορέω",
		"translit" : "plērophoréō"
	},
	"G4885" : {
		"derivation" : "from G4862 (σύν) and G837 (αὐξάνω)",
		"def" : "to increase (grow up) together",
		"kjv" : "grow together",
		"lemma" : "συναυξάνω",
		"translit" : "synauxánō"
	},
	"G1971" : {
		"kjv" : "(earnestly) desire (greatly), (greatly) long (after), lust",
		"lemma" : "ἐπιποθέω",
		"translit" : "epipothéō",
		"derivation" : "from G1909 (ἐπί) and potheo (to yearn)",
		"def" : "to dote upon, i.e. intensely crave possession (lawfully or wrongfully)"
	},
	"G4446" : {
		"translit" : "pyretós",
		"lemma" : "πυρετός",
		"kjv" : "fever",
		"def" : "inflamed, i.e. (by implication) feverish (as noun, fever)",
		"derivation" : "from G4445 (πυρέσσω)"
	},
	"G3362" : {
		"derivation" : "i.e. G1437 (ἐάν) and G3361 (μή)",
		"def" : "if not, i.e. unless",
		"kjv" : "X before, but, except, if, no, (if, + whosoever) not",
		"lemma" : "ἐὰν μή",
		"translit" : "eàn mḗ"
	},
	"G3819" : {
		"kjv" : "any while, a great while ago, (of) old, in time past",
		"translit" : "pálai",
		"lemma" : "πάλαι",
		"derivation" : "probably another form for G3825 (πάλιν) (through the idea of retrocession)",
		"def" : "(adverbially) formerly, or (by relatively) sometime since; (elliptically as adjective) ancient"
	},
	"G705" : {
		"derivation" : "from G706 (ἀριθμός)",
		"def" : "to enumerate or count",
		"kjv" : "number",
		"translit" : "arithméō",
		"lemma" : "ἀριθμέω"
	},
	"G701" : {
		"derivation" : "from G700 (ἀρέσκω)",
		"def" : "agreeable; by implication, fit",
		"kjv" : "(things that) please(-ing), reason",
		"lemma" : "ἀρεστός",
		"translit" : "arestós"
	},
	"G5418" : {
		"derivation" : "from G5420 (φράσσω)",
		"def" : "a fence, or inclosing barrier (literally or figuratively)",
		"kjv" : "hedge (+ round about), partition",
		"translit" : "phragmós",
		"lemma" : "φραγμός"
	},
	"G889" : {
		"kjv" : "become unprofitable",
		"translit" : "achreióō",
		"lemma" : "ἀχρειόω",
		"derivation" : "from G888 (ἀχρεῖος)",
		"def" : "to render useless, i.e. spoil"
	},
	"G1135" : {
		"kjv" : "wife, woman",
		"lemma" : "γυνή",
		"translit" : "gynḗ",
		"derivation" : "probably from the base of G1096 (γίνομαι)",
		"def" : "a woman; specially, a wife"
	},
	"G4175" : {
		"kjv" : "conversation",
		"lemma" : "πολίτευμα",
		"translit" : "políteuma",
		"derivation" : "from G4176 (πολιτεύομαι)",
		"def" : "a community, i.e. (abstractly) citizenship (figuratively)"
	},
	"G719" : {
		"def" : "an articulation (of the body)",
		"derivation" : "from the same as G716 (ἅρμα)",
		"translit" : "harmós",
		"lemma" : "ἁρμός",
		"kjv" : "joint"
	},
	"G1622" : {
		"def" : "the exterior; figuratively (as a preposition) aside from, besides",
		"derivation" : "from G1537 (ἐκ)",
		"lemma" : "ἐκτός",
		"translit" : "ektós",
		"kjv" : "but, except(-ed), other than, out of, outside, unless, without"
	},
	"G2419" : {
		"kjv" : "Jerusalem",
		"lemma" : "Ἱερουσαλήμ",
		"translit" : "Hierousalḗm",
		"derivation" : "of Hebrew origin (H3389)",
		"def" : "Hierusalem (i.e. Jerushalem), the capitol of Palestine"
	},
	"G1450" : {
		"def" : "pledged (as if articulated by a member), i.e. a bondsman",
		"derivation" : "from G1722 (ἐν) and (a limb)",
		"lemma" : "ἔγγυος",
		"translit" : "éngyos",
		"kjv" : "surety"
	},
	"G1776" : {
		"def" : "to crowd in, i.e. (figuratively) to annoy",
		"derivation" : "from G1722 (ἐν) and G3791 (ὀχλέω)",
		"lemma" : "ἐνοχλέω",
		"translit" : "enochléō",
		"kjv" : "trouble"
	},
	"G3678" : {
		"kjv" : "young ass",
		"translit" : "onárion",
		"lemma" : "ὀνάριον",
		"derivation" : "neuter of a presumed derivative of G3688 (ὄνος)",
		"def" : "a little ass"
	},
	"G1202" : {
		"lemma" : "δεσμώτης",
		"translit" : "desmṓtēs",
		"kjv" : "prisoner",
		"def" : "(passively) a captive",
		"derivation" : "from the same as G1201 (δεσμωτήριον)"
	},
	"G538" : {
		"translit" : "apatáō",
		"lemma" : "ἀπατάω",
		"kjv" : "deceive",
		"def" : "to cheat, i.e. delude",
		"derivation" : "of uncertain derivation"
	},
	"G3722" : {
		"def" : "dawn (as sun-rise, rising of light); by extension, morn",
		"derivation" : "from the same as G3735 (ὄρος)",
		"lemma" : "ὄρθρος",
		"translit" : "órthros",
		"kjv" : "early in the morning"
	},
	"G5607" : {
		"derivation" : "present participle of G1510 (εἰμί)",
		"def" : "being",
		"kjv" : "be, come, have",
		"lemma" : "ὤν",
		"translit" : "ṓn"
	},
	"G656" : {
		"def" : "excommunicated",
		"derivation" : "from G575 (ἀπό) and G4864 (συναγωγή)",
		"translit" : "aposynágōgos",
		"lemma" : "ἀποσυνάγωγος",
		"kjv" : "(put) out of the synagogue(-s)"
	},
	"G5444" : {
		"def" : "a sprout, i.e. leaf",
		"derivation" : "from the same as G5443 (φυλή)",
		"translit" : "phýllon",
		"lemma" : "φύλλον",
		"kjv" : "leaf"
	},
	"G4251" : {
		"kjv" : "Prisca",
		"lemma" : "Πρίσκα",
		"translit" : "Príska",
		"derivation" : "of Latin origin",
		"def" : "feminine of Priscus, ancient; Priska, a Christian woman"
	},
	"G5197" : {
		"derivation" : "from G5195 (ὑβρίζω)",
		"def" : "an insulter, i.e. maltreater",
		"kjv" : "despiteful, injurious",
		"lemma" : "ὑβριστής",
		"translit" : "hybristḗs"
	},
	"G2673" : {
		"translit" : "katargéō",
		"lemma" : "καταργέω",
		"kjv" : "abolish, cease, cumber, deliver, destroy, do away, become (make) of no (none, without) effect, fail, loose, bring (come) to nought, put away (down), vanish away, make void",
		"def" : "to be (render) entirely idle (useless), literally or figuratively",
		"derivation" : "from G2596 (κατά) and G691 (ἀργέω)"
	},
	"G5108" : {
		"kjv" : "like, such (an one)",
		"translit" : "toioûtos",
		"lemma" : "τοιοῦτος",
		"derivation" : "(including the other inflections); from G5104 (τοί) and G3778 (οὗτος)",
		"def" : "truly this, i.e. of this sort (to denote character or individuality)"
	},
	"G2884" : {
		"def" : "a cor, i.e. a specific measure",
		"derivation" : "of Hebrew origin (H3734)",
		"lemma" : "κόρος",
		"translit" : "kóros",
		"kjv" : "measure"
	},
	"G5163" : {
		"derivation" : "from G5164 (τροχός)",
		"def" : "a track (as a wheel-rut), i.e. (figuratively) a course of conduct",
		"kjv" : "path",
		"translit" : "trochiá",
		"lemma" : "τροχιά"
	},
	"G4404" : {
		"kjv" : "early (in the morning), (in the) morning",
		"translit" : "prōḯ",
		"lemma" : "πρωΐ",
		"derivation" : "adverb from G4253 (πρό)",
		"def" : "at dawn; by implication, the day-break watch"
	},
	"G3446" : {
		"kjv" : "form",
		"lemma" : "μόρφωσις",
		"translit" : "mórphōsis",
		"derivation" : "from G3445 (μορφόω)",
		"def" : "formation, i.e. (by implication), appearance (semblance or (concretely) formula)"
	},
	"G1338" : {
		"derivation" : "from G1223 (διά) and the base of G2425 (ἱκανός)",
		"def" : "to reach through, i.e. penetrate",
		"kjv" : "pierce",
		"translit" : "diïknéomai",
		"lemma" : "διϊκνέομαι"
	},
	"G4939" : {
		"def" : "a fellow-nursling, i.e. comrade",
		"derivation" : "from G4862 (σύν) and G5162 (τροφός) (in a passive sense)",
		"translit" : "sýntrophos",
		"lemma" : "σύντροφος",
		"kjv" : "brought up with"
	},
	"G4139" : {
		"kjv" : "near, neighbour",
		"translit" : "plēsíon",
		"lemma" : "πλησίον",
		"derivation" : "neuter of a derivative of (near)",
		"def" : "(adverbially) close by; as noun, a neighbor, i.e. fellow (as man, countryman, Christian or friend)"
	},
	"G4727" : {
		"derivation" : "from G4728 (στενός)",
		"def" : "to make (intransitively, be) in straits, i.e. (by implication) to sigh, murmur, pray inaudibly",
		"kjv" : "with grief, groan, grudge, sigh",
		"lemma" : "στενάζω",
		"translit" : "stenázō"
	},
	"G3614" : {
		"translit" : "oikía",
		"lemma" : "οἰκία",
		"kjv" : "home, house(-hold)",
		"def" : "properly, residence (abstractly), but usually (concretely) an abode (literally or figuratively); by implication, a family (especially domestics)",
		"derivation" : "from G3624 (οἶκος)"
	},
	"G3530" : {
		"lemma" : "Νικόδημος",
		"translit" : "Nikódēmos",
		"kjv" : "Nicodemus",
		"def" : "victorious among his people; Nicodemus, an Israelite",
		"derivation" : "from G3534 (νῖκος) and G1218 (δῆμος)"
	},
	"G313" : {
		"kjv" : "beget, (bear) X (again)",
		"translit" : "anagennáō",
		"lemma" : "ἀναγεννάω",
		"derivation" : "from G303 (ἀνά) and G1080 (γεννάω)",
		"def" : "to beget or (by extension) bear (again)"
	},
	"G1168" : {
		"translit" : "deiliáō",
		"lemma" : "δειλιάω",
		"kjv" : "be afraid",
		"def" : "to be timid",
		"derivation" : "from G1167 (δειλία)"
	},
	"G3796" : {
		"lemma" : "ὀψέ",
		"translit" : "opsé",
		"kjv" : "(at) even, in the end",
		"def" : "(adverbially) late in the day; by extension, after the close of the day",
		"derivation" : "from the same as G3694 (ὀπίσω) (through the idea of backwardness)"
	},
	"G2605" : {
		"def" : "to proclaim, promulgate",
		"derivation" : "from G2596 (κατά) and the base of G32 (ἄγγελος)",
		"lemma" : "καταγγέλλω",
		"translit" : "katangéllō",
		"kjv" : "declare, preach, shew, speak of, teach"
	},
	"G2498" : {
		"derivation" : "of Hebrew origin (H3092)",
		"def" : "Josaphat (i.e. Jehoshaphat), an Israelite",
		"kjv" : "Josaphat",
		"lemma" : "Ἰωσαφάτ",
		"translit" : "Iōsaphát"
	},
	"G362" : {
		"lemma" : "ἀναμένω",
		"translit" : "anaménō",
		"kjv" : "wait for",
		"def" : "to await",
		"derivation" : "from G303 (ἀνά) and G3306 (μένω)"
	},
	"G5251" : {
		"kjv" : "highly exalt",
		"lemma" : "ὑπερυψόω",
		"translit" : "hyperypsóō",
		"derivation" : "from G5228 (ὑπέρ) and G5312 (ὑψόω)",
		"def" : "to elevate above others, i.e. raise to the highest position"
	},
	"G2200" : {
		"def" : "boiled, i.e. (by implication) calid (figuratively, fervent)",
		"derivation" : "from G2204 (ζέω)",
		"translit" : "zestós",
		"lemma" : "ζεστός",
		"kjv" : "hot"
	},
	"G4013" : {
		"kjv" : "compass, go (round) about, lead about",
		"translit" : "periágō",
		"lemma" : "περιάγω",
		"derivation" : "from G4012 (περί) and G71 (ἄγω)",
		"def" : "to take around (as a companion); reflexively, to walk around"
	},
	"G4678" : {
		"kjv" : "wisdom",
		"translit" : "sophía",
		"lemma" : "σοφία",
		"derivation" : "from G4680 (σοφός)",
		"def" : "wisdom (higher or lower, worldly or spiritual)"
	},
	"G342" : {
		"def" : "renovation",
		"derivation" : "from G341 (ἀνακαινόω)",
		"lemma" : "ἀνακαίνωσις",
		"translit" : "anakaínōsis",
		"kjv" : "renewing"
	},
	"G1542" : {
		"def" : "a hundred times",
		"derivation" : "from G1540 (ἑκατόν) and a presumed derivative of G4111 (πλάσσω)",
		"translit" : "hekatontaplasíōn",
		"lemma" : "ἑκατονταπλασίων",
		"kjv" : "hundredfold"
	},
	"G2075" : {
		"translit" : "esté",
		"lemma" : "ἐστέ",
		"kjv" : "be, have been, belong",
		"def" : "ye are",
		"derivation" : "second person plural present indicative of G1510 (εἰμί)"
	},
	"G1133" : {
		"derivation" : "a diminutive from G1135 (γυνή)",
		"def" : "a little (i.e. foolish) woman",
		"kjv" : "silly woman",
		"lemma" : "γυναικάριον",
		"translit" : "gynaikárion"
	},
	"G2195" : {
		"def" : "Zacchæus, an Israelite",
		"derivation" : "of Hebrew origin (compare H2140)",
		"lemma" : "Ζακχαῖος",
		"translit" : "Zakchaîos",
		"kjv" : "Zacchæus"
	},
	"G4788" : {
		"kjv" : "conclude, inclose, shut up",
		"translit" : "synkleíō",
		"lemma" : "συγκλείω",
		"derivation" : "from G4862 (σύν) and G2808 (κλείω)",
		"def" : "to shut together, i.e. include or (figuratively) embrace in a common subjection to"
	},
	"G168" : {
		"def" : "impurity (the state), morally",
		"derivation" : "from G169 (ἀκάθαρτος)",
		"lemma" : "ἀκαθάρτης",
		"translit" : "akathártēs",
		"kjv" : "filthiness"
	},
	"G39" : {
		"derivation" : "neuter of G40 (ἅγιος)",
		"def" : "a sacred thing (i.e. spot)",
		"kjv" : "holiest (of all), holy place, sanctuary",
		"translit" : "hágion",
		"lemma" : "ἅγιον"
	},
	"G3557" : {
		"kjv" : "keep back, purloin",
		"translit" : "nosphízomai",
		"lemma" : "νοσφίζομαι",
		"derivation" : "middle voice from (apart or clandestinely)",
		"def" : "to sequestrate for oneself, i.e. embezzle"
	},
	"G3449" : {
		"kjv" : "painfulness, travail",
		"lemma" : "μόχθος",
		"translit" : "móchthos",
		"derivation" : "from the base of G3425 (μόγις)",
		"def" : "toil, i.e. (by implication) sadness"
	},
	"G3558" : {
		"translit" : "nótos",
		"lemma" : "νότος",
		"kjv" : "south (wind)",
		"def" : "the south(-west) wind; by extension, the southern quarter itself",
		"derivation" : "of uncertain affinity"
	},
	"G849" : {
		"lemma" : "αὐτόχειρ",
		"translit" : "autócheir",
		"kjv" : "with … own hands",
		"def" : "self-handed, i.e. doing personally",
		"derivation" : "from G846 (αὐτός) and G5495 (χείρ)"
	},
	"G1820" : {
		"def" : "to be utterly at a loss, i.e. despond",
		"derivation" : "middle voice from G1537 (ἐκ) and G639 (ἀπορέω)",
		"translit" : "exaporéomai",
		"lemma" : "ἐξαπορέομαι",
		"kjv" : "(in) despair"
	},
	"G1432" : {
		"translit" : "dōreán",
		"lemma" : "δωρεάν",
		"kjv" : "without a cause, freely, for naught, in vain",
		"def" : "gratuitously (literally or figuratively)",
		"derivation" : "accusative case of G1431 (δωρεά) as adverb"
	},
	"G292" : {
		"def" : "to ward off (for oneself), i.e. protect",
		"derivation" : "middle voice of a primary verb",
		"lemma" : "ἀμύνομαι",
		"translit" : "amýnomai",
		"kjv" : "defend"
	},
	"G774" : {
		"translit" : "Asianós",
		"lemma" : "Ἀσιανός",
		"kjv" : "of Asia",
		"def" : "an Asian (i.e. Asiatic) or an inhabitant of Asia",
		"derivation" : "from G773 (Ἀσία)"
	},
	"G4295" : {
		"def" : "to lie before the view, i.e. (figuratively) to be present (to the mind), to stand forth (as an example or reward)",
		"derivation" : "from G4253 (πρό) and G2749 (κεῖμαι)",
		"translit" : "prókeimai",
		"lemma" : "πρόκειμαι",
		"kjv" : "be first, set before (forth)"
	},
	"G1592" : {
		"derivation" : "from G1537 (ἐκ) and G3456 (μυκτηρίζω)",
		"def" : "to sneer outright at",
		"kjv" : "deride",
		"translit" : "ekmyktērízō",
		"lemma" : "ἐκμυκτηρίζω"
	},
	"G4341" : {
		"derivation" : "middle voice from G4314 (πρός) and G2564 (καλέω)",
		"def" : "to call toward oneself, i.e. summon, invite",
		"kjv" : "call (for, to, unto)",
		"translit" : "proskaléomai",
		"lemma" : "προσκαλέομαι"
	},
	"G1769" : {
		"translit" : "enneós",
		"lemma" : "ἐννεός",
		"kjv" : "speechless",
		"def" : "dumb (as making signs), i.e. silent from astonishment",
		"derivation" : "from G1770 (ἐννεύω)"
	},
	"G1295" : {
		"def" : "to save thoroughly, i.e. (by implication or analogy) to cure, preserve, rescue, etc.",
		"derivation" : "from G1223 (διά) and G4982 (σώζω)",
		"translit" : "diasṓzō",
		"lemma" : "διασώζω",
		"kjv" : "bring safe, escape (safe), heal, make perfectly whole, save"
	},
	"G5526" : {
		"def" : "to fodder, i.e. (generally) to gorge (supply food in abundance)",
		"derivation" : "from G5528 (χόρτος)",
		"translit" : "chortázō",
		"lemma" : "χορτάζω",
		"kjv" : "feed, fill, satisfy"
	},
	"G3482" : {
		"lemma" : "Ναθαναήλ",
		"translit" : "Nathanaḗl",
		"kjv" : "Nathanael",
		"def" : "Nathanaël (i.e. Nathanel), an Israelite and Christian",
		"derivation" : "of Hebrew origin (H5417)"
	},
	"G1201" : {
		"def" : "a place of bondage, i.e. a dungeon",
		"derivation" : "from a derivative of G1199 (δεσμόν) (equivalent to G1196 (δεσμέω))",
		"translit" : "desmōtḗrion",
		"lemma" : "δεσμωτήριον",
		"kjv" : "prison"
	},
	"G5232" : {
		"kjv" : "grow exceedingly",
		"translit" : "hyperauxánō",
		"lemma" : "ὑπεραυξάνω",
		"derivation" : "from G5228 (ὑπέρ) and G837 (αὐξάνω)",
		"def" : "to increase above ordinary degree"
	},
	"G629" : {
		"def" : "(the act) ransom in full, i.e. (figuratively) riddance, or (specially) Christian salvation",
		"derivation" : "from a compound of G575 (ἀπό) and G3083 (λύτρον)",
		"lemma" : "ἀπολύτρωσις",
		"translit" : "apolýtrōsis",
		"kjv" : "deliverance, redemption"
	},
	"G1610" : {
		"kjv" : "pluck up by the root, root up",
		"translit" : "ekrizóō",
		"lemma" : "ἐκριζόω",
		"derivation" : "from G1537 (ἐκ) and G4492 (ῥιζόω)",
		"def" : "to uproot"
	},
	"G1115" : {
		"kjv" : "Golgotha",
		"translit" : "Golgothâ",
		"lemma" : "Γολγοθᾶ",
		"derivation" : "of Chaldee origin (compare H1538)",
		"def" : "the skull; Golgotha, a knoll near Jerusalem"
	},
	"G267" : {
		"kjv" : "without witness",
		"translit" : "amártyros",
		"lemma" : "ἀμάρτυρος",
		"derivation" : "from G1 (Α) (as a negative particle) and a form of G3144 (μάρτυς)",
		"def" : "unattested"
	},
	"G5428" : {
		"derivation" : "from G5426 (φρονέω)",
		"def" : "mental action or activity, i.e. intellectual or moral insight",
		"kjv" : "prudence, wisdom",
		"translit" : "phrónēsis",
		"lemma" : "φρόνησις"
	},
	"G2201" : {
		"lemma" : "ζεῦγος",
		"translit" : "zeûgos",
		"kjv" : "yoke, pair",
		"def" : "a couple, i.e. a team (of oxen yoked together) or brace (of birds tied together)",
		"derivation" : "from the same as G2218 (ζυγός)"
	},
	"G365" : {
		"kjv" : "renew",
		"lemma" : "ἀνανεόω",
		"translit" : "ananeóō",
		"derivation" : "from G303 (ἀνά) and a derivative of G3501 (νέος)",
		"def" : "to renovate, i.e. reform"
	},
	"G3787" : {
		"derivation" : "from G3788 (ὀφθαλμός) and G1397 (δουλεία)",
		"def" : "sight-labor, i.e. that needs watching (remissness)",
		"kjv" : "eye-service",
		"translit" : "ophthalmodouleía",
		"lemma" : "ὀφθαλμοδουλεία"
	},
	"G2968" : {
		"lemma" : "κώμη",
		"translit" : "kṓmē",
		"kjv" : "town, village",
		"def" : "a hamlet (as if laid down)",
		"derivation" : "from G2749 (κεῖμαι)"
	},
	"G4361" : {
		"kjv" : "very hungry",
		"lemma" : "πρόσπεινος",
		"translit" : "próspeinos",
		"derivation" : "from G4314 (πρός) and the same as G3983 (πεινάω)",
		"def" : "hungering further, i.e. intensely hungry"
	},
	"G2434" : {
		"lemma" : "ἱλασμός",
		"translit" : "hilasmós",
		"kjv" : "propitiation",
		"def" : "atonement, i.e. (concretely) an expiator"
	},
	"G3833" : {
		"derivation" : "from a compound of G3956 (πᾶς) and G3696 (ὅπλον)",
		"def" : "full armor (\"panoply\")",
		"kjv" : "all (whole) armour",
		"lemma" : "πανοπλία",
		"translit" : "panoplía"
	},
	"G4890" : {
		"def" : "a running together, i.e. (riotous) concourse",
		"derivation" : "from (the alternate of) G4936 (συντρέχω)",
		"lemma" : "συνδρομή",
		"translit" : "syndromḗ",
		"kjv" : "run together"
	},
	"G1171" : {
		"def" : "terribly, i.e. excessively",
		"derivation" : "adverb from a derivative of the same as G1169 (δειλός)",
		"lemma" : "δεινῶς",
		"translit" : "deinōs",
		"kjv" : "grievously, vehemently"
	},
	"G3989" : {
		"derivation" : "of uncertain affinity",
		"def" : "deep or open sea, i.e. the main",
		"kjv" : "depth, sea",
		"lemma" : "πέλαγος",
		"translit" : "pélagos"
	},
	"G3009" : {
		"translit" : "leitourgía",
		"lemma" : "λειτουργία",
		"kjv" : "ministration(-try), service",
		"def" : "public function (as priest (\"liturgy\") or almsgiver)",
		"derivation" : "from G3008 (λειτουργέω)"
	},
	"G3959" : {
		"derivation" : "probably of foreign origin",
		"def" : "Patara, a place in Asia Minor",
		"kjv" : "Patara",
		"lemma" : "Πάταρα",
		"translit" : "Pátara"
	},
	"G5380" : {
		"kjv" : "contentious",
		"translit" : "philóneikos",
		"lemma" : "φιλόνεικος",
		"derivation" : "from G5384 (φίλος) and (a quarrel",
		"def" : "probably akin to G3534 (νῖκος)); fond of strife, i.e. disputatious"
	},
	"G2468" : {
		"kjv" : "+ agree, be, X give thyself wholly to",
		"lemma" : "ἴσθι",
		"translit" : "ísthi",
		"derivation" : "second person imperative present of G1510 (εἰμί)",
		"def" : "be thou"
	},
	"G5180" : {
		"kjv" : "beat, smite, strike, wound",
		"lemma" : "τύπτω",
		"translit" : "týptō",
		"derivation" : "a primary verb (in a strengthened form)",
		"def" : "to \"thump\", i.e. cudgel or pummel (properly, with a stick or bastinado), but in any case by repeated blows; thus differing from G3817 (παίω) and G3960 (πατάσσω), which denote a (usually single) blow with the hand or any instrument, or G4141 (πλήσσω) with the fist (or a hammer), or G4474 (ῥαπίζω) with the palm; as well as from G5177 (τυγχάνω), an accidental collision); by implication, to punish; figuratively, to offend (the conscience)"
	},
	"G4384" : {
		"def" : "to pre-arrange, i.e. prescribe",
		"derivation" : "from G4253 (πρό) and G5021 (τάσσω)",
		"translit" : "protássō",
		"lemma" : "προτάσσω",
		"kjv" : "before appoint"
	},
	"G2040" : {
		"def" : "a toiler; figuratively, a teacher",
		"derivation" : "from G2041 (ἔργον)",
		"translit" : "ergátēs",
		"lemma" : "ἐργάτης",
		"kjv" : "labourer, worker(-men)"
	},
	"G3651" : {
		"derivation" : "from G3650 (ὅλος) and G5056 (τέλος)",
		"def" : "complete to the end, i.e. absolutely perfect",
		"kjv" : "wholly",
		"lemma" : "ὁλοτελής",
		"translit" : "holotelḗs"
	},
	"G733" : {
		"lemma" : "ἀρσενοκοίτης",
		"translit" : "arsenokoítēs",
		"kjv" : "abuser of (that defile) self with mankind",
		"def" : "a sodomite",
		"derivation" : "from G730 (ἄῤῥην) and G2845 (κοίτη)"
	},
	"G3083" : {
		"derivation" : "from G3089 (λύω)",
		"def" : "something to loosen with, i.e. a redemption price (figuratively, atonement)",
		"kjv" : "ransom",
		"translit" : "lýtron",
		"lemma" : "λύτρον"
	},
	"G3305" : {
		"def" : "indeed though, i.e. however",
		"derivation" : "from G3203 and G5104 (τοί)",
		"lemma" : "μέντοι",
		"translit" : "méntoi",
		"kjv" : "also, but, howbeit, nevertheless, yet"
	},
	"G2127" : {
		"def" : "to speak well of, i.e. (religiously) to bless (thank or invoke a benediction upon, prosper)",
		"derivation" : "from a compound of G2095 (εὖ) and G3056 (λόγος)",
		"translit" : "eulogéō",
		"lemma" : "εὐλογέω",
		"kjv" : "bless, praise"
	},
	"G3502" : {
		"kjv" : "young",
		"lemma" : "νεοσσός",
		"translit" : "neossós",
		"derivation" : "from G3501 (νέος)",
		"def" : "a youngling (nestling)"
	},
	"G380" : {
		"derivation" : "from G303 (ἀνά) (in the sense of reversal) and G4428 (πτύσσω)",
		"def" : "to unroll (a scroll or volume)",
		"kjv" : "open",
		"translit" : "anaptýssō",
		"lemma" : "ἀναπτύσσω"
	},
	"G1833" : {
		"def" : "to test thoroughly (by questions), i.e. ascertain or interrogate",
		"derivation" : "from G1537 (ἐκ) and (to examine)",
		"translit" : "exetázō",
		"lemma" : "ἐξετάζω",
		"kjv" : "ask, enquire, search"
	},
	"G4510" : {
		"derivation" : "from G4509 (ῥύπος)",
		"def" : "to soil, i.e. (intransitively) to become dirty (morally)",
		"kjv" : "be filthy",
		"translit" : "rhypóō",
		"lemma" : "ῥυπόω"
	},
	"G1470" : {
		"kjv" : "hid in",
		"lemma" : "ἐγκρύπτω",
		"translit" : "enkrýptō",
		"derivation" : "from G1722 (ἐν) and G2928 (κρύπτω)",
		"def" : "to conceal in, i.e. incorporate with"
	},
	"G4474" : {
		"kjv" : "smite (with the palm of the hand)",
		"translit" : "rhapízō",
		"lemma" : "ῥαπίζω",
		"derivation" : "from a derivative of a primary (to let fall, \"rap\")",
		"def" : "to slap"
	},
	"G3544" : {
		"derivation" : "from G3551 (νόμος)",
		"def" : "according (or pertaining) to law, i.e. legal (ceremonially); as noun, an expert in the (Mosaic) law",
		"kjv" : "about the law, lawyer",
		"lemma" : "νομικός",
		"translit" : "nomikós"
	},
	"G5091" : {
		"lemma" : "τιμάω",
		"translit" : "timáō",
		"kjv" : "honour, value",
		"def" : "to prize, i.e. fix a valuation upon; by implication, to revere",
		"derivation" : "from G5093 (τίμιος)"
	},
	"G3179" : {
		"kjv" : "put out, remove, translate, turn away",
		"lemma" : "μεθίστημι",
		"translit" : "methístēmi",
		"derivation" : "from G3326 (μετά) and G2476 (ἵστημι)",
		"def" : "to transfer, i.e. carry away, depose or (figuratively) exchange, seduce"
	},
	"G519" : {
		"kjv" : "hang himself",
		"lemma" : "ἀπάγχομαι",
		"translit" : "apánchomai",
		"derivation" : "from G575 (ἀπό) and (to choke",
		"def" : "akin to the base of G43 (ἀγκάλη)); to strangle oneself off (i.e. to death)"
	},
	"G1994" : {
		"def" : "to revert (literally, figuratively or morally)",
		"derivation" : "from G1909 (ἐπί) and G4762 (στρέφω)",
		"translit" : "epistréphō",
		"lemma" : "ἐπιστρέφω",
		"kjv" : "come (go) again, convert, (re-)turn (about, again)"
	},
	"G1091" : {
		"derivation" : "neuter of a (presumed) derivative of G1092 (γεωργός)",
		"def" : "cultivable, i.e. a farm",
		"kjv" : "husbandry",
		"lemma" : "γεώργιον",
		"translit" : "geṓrgion"
	},
	"G759" : {
		"derivation" : "from G142 (αἴρω) (in the sense of sending off scent)",
		"def" : "an aromatic",
		"kjv" : "(sweet) spice",
		"translit" : "árōma",
		"lemma" : "ἄρωμα"
	},
	"G4918" : {
		"def" : "to compress, i.e. crowd on all sides",
		"derivation" : "from G4862 (σύν) and G2346 (θλίβω)",
		"lemma" : "συνθλίβω",
		"translit" : "synthlíbō",
		"kjv" : "throng"
	},
	"G5514" : {
		"def" : "\"green\"; Chloë, a Christian female",
		"derivation" : "feminine of apparently a primary word",
		"lemma" : "Χλόη",
		"translit" : "Chlóē",
		"kjv" : "Chloe"
	},
	"G2369" : {
		"translit" : "thymiastḗrion",
		"lemma" : "θυμιαστήριον",
		"kjv" : "censer",
		"def" : "a place of fumigation, i.e. the alter of incense (in the Temple)",
		"derivation" : "from a derivative of G2370 (θυμιάω)"
	},
	"G4137" : {
		"kjv" : "accomplish, X after, (be) complete, end, expire, fill (up), fulfil, (be, make) full (come), fully preach, perfect, supply",
		"lemma" : "πληρόω",
		"translit" : "plēróō",
		"derivation" : "from G4134 (πλήρης)",
		"def" : "to make replete, i.e. (literally) to cram (a net), level up (a hollow), or (figuratively) to furnish (or imbue, diffuse, influence), satisfy, execute (an office), finish (a period or task), verify (or coincide with a prediction), etc."
	},
	"G1908" : {
		"translit" : "epēreázō",
		"lemma" : "ἐπηρεάζω",
		"kjv" : "use despitefully, falsely accuse",
		"def" : "to insult, slander",
		"derivation" : "from a comparative of G1909 (ἐπί) and (probably) (threats)"
	},
	"G773" : {
		"derivation" : "of uncertain derivation",
		"def" : "Asia, i.e. Asia Minor, or (usually) only its western shore",
		"kjv" : "Asia",
		"translit" : "Asía",
		"lemma" : "Ἀσία"
	},
	"G2430" : {
		"kjv" : "Iconium",
		"lemma" : "Ἰκόνιον",
		"translit" : "Ikónion",
		"derivation" : "perhaps from G1504 (εἰκών)",
		"def" : "image-like; Iconium, a place in Asia Minor"
	},
	"G3806" : {
		"translit" : "páthos",
		"lemma" : "πάθος",
		"kjv" : "(inordinate) affection, lust",
		"def" : "properly, suffering (\"pathos\"), i.e. (subjectively) a passion (especially concupiscence)",
		"derivation" : "from the alternate of G3958 (πάσχω)"
	},
	"G80" : {
		"translit" : "adelphós",
		"lemma" : "ἀδελφός",
		"kjv" : "brother",
		"def" : "a brother (literally or figuratively) near or remote (much like G1 (Α))",
		"derivation" : "from G1 (Α) (as a connective particle) and (the womb)"
	},
	"G2629" : {
		"derivation" : "from G2596 (κατά) and G2875 (κόπτω)",
		"def" : "to chop down, i.e. mangle",
		"kjv" : "cut",
		"translit" : "katakóptō",
		"lemma" : "κατακόπτω"
	},
	"G4912" : {
		"def" : "to hold together, i.e. to compress (the ears, with a crowd or siege) or arrest (a prisoner); figuratively, to compel, perplex, afflict, preoccupy",
		"derivation" : "from G4862 (σύν) and G2192 (ἔχω)",
		"translit" : "synéchō",
		"lemma" : "συνέχω",
		"kjv" : "constrain, hold, keep in, press, lie sick of, stop, be in a strait, straiten, be taken with, throng"
	},
	"G1059" : {
		"derivation" : "of Hebrew origin (H1583)",
		"def" : "Gamaliel (i.e. Gamliel), an Israelite",
		"kjv" : "Gamaliel",
		"lemma" : "Γαμαλιήλ",
		"translit" : "Gamaliḗl"
	},
	"G1359" : {
		"def" : "sons of Jupiter, i.e. the twins Dioscuri",
		"derivation" : "from the alternate of G2203 (Ζεύς) and a form of the base of G2877 (κοράσιον)",
		"translit" : "Dióskouroi",
		"lemma" : "Διόσκουροι",
		"kjv" : "Castor and Pollux"
	},
	"G1400" : {
		"translit" : "doûlon",
		"lemma" : "δοῦλον",
		"kjv" : "servant",
		"def" : "subservient",
		"derivation" : "neuter of G1401 (δοῦλος)"
	},
	"G347" : {
		"translit" : "anaklínō",
		"lemma" : "ἀνακλίνω",
		"kjv" : "lay, (make) sit down",
		"def" : "to lean back",
		"derivation" : "from G303 (ἀνά) and G2827 (κλίνω)"
	},
	"G5261" : {
		"lemma" : "ὑπογραμμός",
		"translit" : "hypogrammós",
		"kjv" : "example",
		"def" : "an underwriting, i.e. copy for imitation (figuratively)",
		"derivation" : "from a compound of G5259 (ὑπό) and G1125 (γράφω)"
	},
	"G2331" : {
		"def" : "a Thessalonican, i.e. inhabitant of Thessalonice",
		"derivation" : "from G2332 (Θεσσαλονίκη)",
		"translit" : "Thessalonikeús",
		"lemma" : "Θεσσαλονικεύς",
		"kjv" : "Thessalonian"
	},
	"G5097" : {
		"lemma" : "τιμωρέω",
		"translit" : "timōréō",
		"kjv" : "punish",
		"def" : "properly, to protect one's honor, i.e. to avenge (inflict a penalty)",
		"derivation" : "from a comparative of G5092 (τιμή) and ouros (a guard)"
	},
	"G4917" : {
		"derivation" : "from G4862 (σύν) and thlao (to crush)",
		"def" : "to dash together, i.e. shatter",
		"kjv" : "break",
		"translit" : "synthláō",
		"lemma" : "συνθλάω"
	},
	"G2683" : {
		"kjv" : "shadow",
		"translit" : "kataskiázō",
		"lemma" : "κατασκιάζω",
		"derivation" : "from G2596 (κατά) and a derivative of G4639 (σκιά)",
		"def" : "to overshade, i.e. cover"
	},
	"G4188" : {
		"kjv" : "drink",
		"translit" : "póma",
		"lemma" : "πόμα",
		"derivation" : "from the alternate of G4095 (πίνω)",
		"def" : "a beverage"
	},
	"G673" : {
		"kjv" : "depart (asunder)",
		"translit" : "apochōrízō",
		"lemma" : "ἀποχωρίζω",
		"derivation" : "from G575 (ἀπό) and G5563 (χωρίζω)",
		"def" : "to rend apart; reflexively, to separate"
	},
	"G4567" : {
		"derivation" : "of Chaldee origin corresponding to H4566 (with the definite affix)",
		"def" : "the accuser, i.e. the devil",
		"kjv" : "Satan",
		"lemma" : "Σατανᾶς",
		"translit" : "Satanâs"
	},
	"G868" : {
		"kjv" : "depart, draw (fall) away, refrain, withdraw self",
		"lemma" : "ἀφίστημι",
		"translit" : "aphístēmi",
		"derivation" : "from G575 (ἀπό) and G2476 (ἵστημι)",
		"def" : "to remove, i.e. (actively) instigate to revolt; usually (reflexively) to desist, desert, etc."
	},
	"G5379" : {
		"lemma" : "φιλονεικία",
		"translit" : "philoneikía",
		"kjv" : "strife",
		"def" : "quarrelsomeness, i.e. a dispute",
		"derivation" : "from G5380 (φιλόνεικος)"
	},
	"G2637" : {
		"derivation" : "from G2596 (κατά) and the base of G2980 (λαλέω)",
		"def" : "talkative against, i.e. a slanderer",
		"kjv" : "backbiter",
		"lemma" : "κατάλαλος",
		"translit" : "katálalos"
	},
	"G690" : {
		"derivation" : "from G688 (Ἀραβία)",
		"def" : "an Arab or native of Arabia",
		"kjv" : "Arabian",
		"lemma" : "Ἄραψ",
		"translit" : "Áraps"
	},
	"G2330" : {
		"def" : "properly, heat, i.e. summer",
		"derivation" : "from a primary (to heat)",
		"translit" : "théros",
		"lemma" : "θέρος",
		"kjv" : "summer"
	},
	"G628" : {
		"def" : "to wash fully, i.e. (figuratively) have remitted (reflexively)",
		"derivation" : "from G575 (ἀπό) and G3068 (λούω)",
		"translit" : "apoloúō",
		"lemma" : "ἀπολούω",
		"kjv" : "wash (away)"
	},
	"G1022" : {
		"lemma" : "βραδύτης",
		"translit" : "bradýtēs",
		"kjv" : "slackness",
		"def" : "tardiness",
		"derivation" : "from G1021 (βραδύς)"
	},
	"G4877" : {
		"kjv" : "meet",
		"lemma" : "συνάντησις",
		"translit" : "synántēsis",
		"derivation" : "from G4876 (συναντάω)",
		"def" : "a meeting with"
	},
	"G4840" : {
		"kjv" : "be here present with",
		"lemma" : "συμπάρειμι",
		"translit" : "sympáreimi",
		"derivation" : "from G4862 (σύν) and G3918 (πάρειμι)",
		"def" : "to be at hand together, i.e. now present"
	},
	"G2902" : {
		"def" : "to use strength, i.e. seize or retain (literally or figuratively)",
		"derivation" : "from G2904 (κράτος)",
		"translit" : "kratéō",
		"lemma" : "κρατέω",
		"kjv" : "hold (by, fast), keep, lay hand (hold) on, obtain, retain, take (by)"
	},
	"G4780" : {
		"def" : "to conceal altogether",
		"derivation" : "from G4862 (σύν) and G2572 (καλύπτω)",
		"lemma" : "συγκαλύπτω",
		"translit" : "synkalýptō",
		"kjv" : "cover"
	},
	"G234" : {
		"derivation" : "from (to soil)",
		"def" : "(ceremonially) defilement",
		"kjv" : "pollution",
		"lemma" : "ἀλίσγεμα",
		"translit" : "alísgema"
	},
	"G83" : {
		"kjv" : "X uncertain",
		"lemma" : "ἀδηλότης",
		"translit" : "adēlótēs",
		"derivation" : "from G82 (ἄδηλος)",
		"def" : "uncertainty"
	},
	"G4849" : {
		"kjv" : "company",
		"lemma" : "συμπόσιον",
		"translit" : "sympósion",
		"derivation" : "neuter of a derivative of the alternate of G4844 (συμπίνω)",
		"def" : "a drinking-party (\"symposium\"), i.e. (by extension) a room of guests"
	},
	"G2098" : {
		"derivation" : "from the same as G2097 (εὐαγγελίζω)",
		"def" : "a good message, i.e. the gospel",
		"kjv" : "gospel",
		"lemma" : "εὐαγγέλιον",
		"translit" : "euangélion"
	},
	"G4758" : {
		"kjv" : "choose to be a soldier",
		"translit" : "stratologéō",
		"lemma" : "στρατολογέω",
		"derivation" : "from a compound of the base of G4756 (στρατιά) and G3004 (λέγω) (in its original sense)",
		"def" : "to gather (or select) as a warrior, i.e. enlist in the army"
	},
	"G5166" : {
		"lemma" : "τρυγάω",
		"translit" : "trygáō",
		"kjv" : "gather",
		"def" : "to collect the vintage",
		"derivation" : "from a derivative of (to dry) meaning ripe fruit (as if dry)"
	},
	"G3050" : {
		"derivation" : "from G3056 (λόγος)",
		"def" : "rational (\"logical\")",
		"kjv" : "reasonable, of the word",
		"translit" : "logikós",
		"lemma" : "λογικός"
	},
	"G4009" : {
		"def" : "an extremity",
		"derivation" : "from the same as G4008 (πέραν)",
		"lemma" : "πέρας",
		"translit" : "péras",
		"kjv" : "end, ut-(ter-)most participle "
	},
	"G5103" : {
		"derivation" : "of Latin origin but uncertain significance",
		"def" : "Titus, a Christian",
		"kjv" : "Titus",
		"translit" : "Títos",
		"lemma" : "Τίτος"
	},
	"G1493" : {
		"derivation" : "neuter of a presumed derivative of G1497 (εἴδωλον)",
		"def" : "an image-fane",
		"kjv" : "idol's temple",
		"translit" : "eidōleîon",
		"lemma" : "εἰδωλεῖον"
	},
	"G1572" : {
		"kjv" : "burn",
		"lemma" : "ἐκκαίω",
		"translit" : "ekkaíō",
		"derivation" : "from G1537 (ἐκ) and G2545 (καίω)",
		"def" : "to inflame deeply"
	},
	"G4657" : {
		"def" : "what is thrown to the dogs, i.e. refuse (ordure)",
		"derivation" : "neuter of a presumed derivative of G1519 (εἰς) and G2965 (κύων) and G906 (βάλλω)",
		"lemma" : "σκύβαλον",
		"translit" : "skýbalon",
		"kjv" : "dung"
	},
	"G4594" : {
		"kjv" : "this (to-)day",
		"lemma" : "σήμερον",
		"translit" : "sḗmeron",
		"derivation" : "neuter (as adverb) of a presumed compound of the article G3588 (ὁ) (t changed to s) and G2250 (ἡμέρα)",
		"def" : "on the (i.e. this) day (or night current or just passed); generally, now (i.e. at present, hitherto)"
	},
	"G1076" : {
		"translit" : "genealogía",
		"lemma" : "γενεαλογία",
		"kjv" : "genealogy",
		"def" : "tracing by generations, i.e. \"genealogy\"",
		"derivation" : "from the same as G1075 (γενεαλογέω)"
	},
	"G287" : {
		"translit" : "amoibḗ",
		"lemma" : "ἀμοιβή",
		"kjv" : "requite",
		"def" : "requital",
		"derivation" : "from (to exchange)"
	},
	"G4392" : {
		"lemma" : "πρόφασις",
		"translit" : "próphasis",
		"kjv" : "cloke, colour, pretence, show",
		"def" : "an outward showing, i.e. pretext",
		"derivation" : "from a compound of G4253 (πρό) and G5316 (φαίνω)"
	},
	"G3866" : {
		"def" : "a deposit, i.e. (figuratively) trust",
		"derivation" : "from G3908 (παρατίθημι)",
		"lemma" : "παραθήκη",
		"translit" : "parathḗkē",
		"kjv" : "committed unto"
	},
	"G970" : {
		"translit" : "bía",
		"lemma" : "βία",
		"kjv" : "violence",
		"def" : "force",
		"derivation" : "probably akin to G979 (βίος) (through the idea of vital activity)"
	},
	"G3352" : {
		"def" : "participation, i.e. intercourse",
		"derivation" : "from G3348 (μετέχω)",
		"lemma" : "μετοχή",
		"translit" : "metochḗ",
		"kjv" : "fellowship"
	},
	"G1577" : {
		"kjv" : "assembly, church",
		"translit" : "ekklēsía",
		"lemma" : "ἐκκλησία",
		"derivation" : "from a compound of G1537 (ἐκ) and a derivative of G2564 (καλέω)",
		"def" : "a calling out, i.e. (concretely) a popular meeting, especially a religious congregation (Jewish synagogue, or Christian community of members on earth or saints in heaven or both)"
	},
	"G5259" : {
		"def" : "under, i.e. (with the genitive case) of place (beneath), or with verbs (the agency or means, through); (with the accusative case) of place (whither (underneath) or where (below) or time (when (at))",
		"derivation" : "a primary preposition",
		"translit" : "hypó",
		"lemma" : "ὑπό",
		"kjv" : "among, by, from, in, of, under, with"
	},
	"G3635" : {
		"def" : "to be slow (figuratively, loath)",
		"derivation" : "from (hesitation)",
		"lemma" : "ὀκνέω",
		"translit" : "oknéō",
		"kjv" : "delay"
	},
	"G1759" : {
		"def" : "properly, within, i.e. (of place) here, hither",
		"derivation" : "from a prolonged form of G1722 (ἐν)",
		"translit" : "entháde",
		"lemma" : "ἐνθάδε",
		"kjv" : "(t-)here, hither"
	},
	"G4864" : {
		"derivation" : "from (the reduplicated form of) G4863 (συνάγω)",
		"def" : "an assemblage of persons; specially, a Jewish \"synagogue\" (the meeting or the place); by analogy, a Christian church",
		"kjv" : "assembly, congregation, synagogue",
		"translit" : "synagōgḗ",
		"lemma" : "συναγωγή"
	},
	"G3357" : {
		"kjv" : "a little",
		"translit" : "metríōs",
		"lemma" : "μετρίως",
		"derivation" : "adverb from a derivative of G3358 (μέτρον)",
		"def" : "moderately, i.e. slightly"
	},
	"G2485" : {
		"def" : "a petty fish",
		"derivation" : "diminutive from G2486 (ἰχθύς)",
		"lemma" : "ἰχθύδιον",
		"translit" : "ichthýdion",
		"kjv" : "little (small) fish"
	},
	"G1302" : {
		"lemma" : "διατί",
		"translit" : "diatí",
		"kjv" : "wherefore, why",
		"def" : "through what cause ?, i.e. why?",
		"derivation" : "from G1223 (διά) and G5101 (τίς)"
	},
	"G3061" : {
		"kjv" : "pestilence(-t)",
		"translit" : "loimós",
		"lemma" : "λοιμός",
		"derivation" : "of uncertain affinity",
		"def" : "a plague (literally, the disease, or figuratively, a pest)"
	},
	"G1809" : {
		"def" : "to demand (for trial)",
		"derivation" : "middle voice from G1537 (ἐκ) and G154 (αἰτέω)",
		"lemma" : "ἐξαιτέομαι",
		"translit" : "exaitéomai",
		"kjv" : "desire"
	},
	"G5282" : {
		"lemma" : "ὑπονοέω",
		"translit" : "hyponoéō",
		"kjv" : "think, suppose, deem",
		"def" : "to think under (privately), i.e. to surmise or conjecture",
		"derivation" : "from G5259 (ὑπό) and G3539 (νοιέω)"
	},
	"G4951" : {
		"lemma" : "σύρω",
		"translit" : "sýrō",
		"kjv" : "drag, draw, hale",
		"def" : "to trail",
		"derivation" : "probably akin to G138 (αἱρέομαι)"
	},
	"G1629" : {
		"def" : "to frighten utterly",
		"derivation" : "from G1537 (ἐκ) and G5399 (φοβέω)",
		"lemma" : "ἐκφοβέω",
		"translit" : "ekphobéō",
		"kjv" : "terrify"
	},
	"G1613" : {
		"lemma" : "ἐκταράσσω",
		"translit" : "ektarássō",
		"kjv" : "exceedingly trouble",
		"def" : "to disturb wholly",
		"derivation" : "from G1537 (ἐκ) and G5015 (ταράσσω)"
	},
	"G1265" : {
		"derivation" : "from G1223 (διά) and G3306 (μένω)",
		"def" : "to stay constantly (in being or relation)",
		"kjv" : "continue, remain",
		"translit" : "diaménō",
		"lemma" : "διαμένω"
	},
	"G4908" : {
		"kjv" : "prudent",
		"lemma" : "συνετός",
		"translit" : "synetós",
		"derivation" : "from G4920 (συνίημι)",
		"def" : "mentally put (or putting) together, i.e. sagacious"
	},
	"G1381" : {
		"lemma" : "δοκιμάζω",
		"translit" : "dokimázō",
		"kjv" : "allow, discern, examine, X like, (ap-)prove, try",
		"def" : "to test (literally or figuratively); by implication, to approve",
		"derivation" : "from G1384 (δόκιμος)"
	},
	"G2150" : {
		"derivation" : "from G2152 (εὐσεβής)",
		"def" : "piety; specially, the gospel scheme",
		"kjv" : "godliness, holiness",
		"translit" : "eusébeia",
		"lemma" : "εὐσέβεια"
	},
	"G3432" : {
		"lemma" : "μοιχός",
		"translit" : "moichós",
		"kjv" : "adulterer",
		"def" : "a (male) paramour; figuratively, apostate",
		"derivation" : "perhaps a primary word"
	},
	"G3785" : {
		"lemma" : "ὄφελον",
		"translit" : "óphelon",
		"kjv" : "would (to God)",
		"def" : "I ought (wish), i.e. (interjection) oh that!",
		"derivation" : "first person singular of a past tense of G3784 (ὀφείλω)"
	},
	"G660" : {
		"kjv" : "shake off",
		"lemma" : "ἀποτινάσσω",
		"translit" : "apotinássō",
		"derivation" : "from G575 (ἀπό) and (to jostle)",
		"def" : "to brush off"
	},
	"G318" : {
		"def" : "constraint (literally or figuratively); by implication, distress",
		"derivation" : "from G303 (ἀνά) and the base of G43 (ἀγκάλη)",
		"lemma" : "ἀνάγκη",
		"translit" : "anánkē",
		"kjv" : "distress, must needs, (of) necessity(-sary), needeth, needful"
	},
	"G886" : {
		"def" : "unmanufactured, i.e. inartificial",
		"derivation" : "from G1 (Α) (as a negative particle) and G5499 (χειροποίητος)",
		"lemma" : "ἀχειροποίητος",
		"translit" : "acheiropoíētos",
		"kjv" : "made without (not made with) hands"
	},
	"G1143" : {
		"translit" : "dáknō",
		"lemma" : "δάκνω",
		"kjv" : "bite",
		"def" : "to bite, i.e. (figuratively) thwart",
		"derivation" : "a prolonged form of a primary root"
	},
	"G1916" : {
		"derivation" : "from G1909 (ἐπί) and G994 (βοάω)",
		"def" : "to exclaim against",
		"kjv" : "cry",
		"translit" : "epiboáō",
		"lemma" : "ἐπιβοάω"
	},
	"G3532" : {
		"def" : "victorious over the people; Nicolaus, a heretic",
		"derivation" : "from G3534 (νῖκος) and G2994 (Λαοδικεύς)",
		"translit" : "Nikólaos",
		"lemma" : "Νικόλαος",
		"kjv" : "Nicolaus"
	},
	"G2911" : {
		"def" : "overhanging, i.e. a precipice",
		"derivation" : "from G2910 (κρεμάννυμι)",
		"lemma" : "κρημνός",
		"translit" : "krēmnós",
		"kjv" : "steep place"
	},
	"G1172" : {
		"kjv" : "sup (X -er)",
		"lemma" : "δειπνέω",
		"translit" : "deipnéō",
		"derivation" : "from G1173 (δεῖπνον)",
		"def" : "to dine, i.e. take the principle (or evening) meal"
	},
	"G5033" : {
		"derivation" : "neuter plural of the superlative of G5036 (ταχύς) (as adverb)",
		"def" : "most quickly, i.e. (with G5613 (ὡς) prefixed) as soon as possible",
		"kjv" : "+ with all speed",
		"lemma" : "τάχιστα",
		"translit" : "táchista"
	},
	"G386" : {
		"lemma" : "ἀνάστασις",
		"translit" : "anástasis",
		"kjv" : "raised to life again, resurrection, rise from the dead, that should rise, rising again",
		"def" : "a standing up again, i.e. (literally) a resurrection from death (individual, genitive case or by implication, (its author)), or (figuratively) a (moral) recovery (of spiritual truth)",
		"derivation" : "from G450 (ἀνίστημι)"
	},
	"G1844" : {
		"derivation" : "from G1537 (ἐκ) and G3726 (ὁρκίζω)",
		"def" : "to exact an oath, i.e. conjure",
		"kjv" : "adjure",
		"translit" : "exorkízō",
		"lemma" : "ἐξορκίζω"
	},
	"G1905" : {
		"translit" : "eperōtáō",
		"lemma" : "ἐπερωτάω",
		"kjv" : "ask (after, questions), demand, desire, question",
		"def" : "to ask for, i.e. inquire, seek",
		"derivation" : "from G1909 (ἐπί) and G2065 (ἐρωτάω)"
	},
	"G5345" : {
		"derivation" : "from G5346 (φημί)",
		"def" : "a saying, i.e. rumor (\"fame\")",
		"kjv" : "fame",
		"translit" : "phḗmē",
		"lemma" : "φήμη"
	},
	"G2862" : {
		"kjv" : "colony",
		"lemma" : "κολωνία",
		"translit" : "kolōnía",
		"derivation" : "of Latin origin",
		"def" : "a Roman \"colony\" for veterans"
	},
	"G2703" : {
		"kjv" : "flee",
		"translit" : "katapheúgō",
		"lemma" : "καταφεύγω",
		"derivation" : "from G2596 (κατά) and G5343 (φεύγω)",
		"def" : "to flee down (away)"
	},
	"G5147" : {
		"kjv" : "path",
		"lemma" : "τρίβος",
		"translit" : "tríbos",
		"derivation" : "from (to \"rub\"",
		"def" : "akin to , , and the base of G5131 (τράγος), G5134 (τραῦμα)); a rut or worn track"
	},
	"G4317" : {
		"kjv" : "bring, draw near",
		"translit" : "proságō",
		"lemma" : "προσάγω",
		"derivation" : "from G4314 (πρός) and G71 (ἄγω)",
		"def" : "to lead towards, i.e. (transitively) to conduct near (summon, present), or (intransitively) to approach"
	},
	"G5523" : {
		"kjv" : "Chorazin",
		"translit" : "Chorazín",
		"lemma" : "Χοραζίν",
		"derivation" : "of uncertain derivation",
		"def" : "Chorazin, a place in Palestine"
	},
	"G1332" : {
		"kjv" : "two years old",
		"translit" : "dietḗs",
		"lemma" : "διετής",
		"derivation" : "from G1364 (δίς) and G2094 (ἔτος)",
		"def" : "of two years (in age)"
	},
	"G4896" : {
		"kjv" : "gather together",
		"translit" : "sýneimi",
		"lemma" : "σύνειμι",
		"derivation" : "from G4862 (σύν) and (to go)",
		"def" : "to assemble"
	},
	"G3355" : {
		"derivation" : "from G3354 (μετρέω)",
		"def" : "a measurer, i.e. (specially), a certain standard measure of capacity for liquids",
		"kjv" : "firkin",
		"lemma" : "μετρητής",
		"translit" : "metrētḗs"
	},
	"G4046" : {
		"kjv" : "purchase",
		"translit" : "peripoiéomai",
		"lemma" : "περιποιέομαι",
		"derivation" : "middle voice from G4012 (περί) and G4160 (ποιέω)",
		"def" : "to make around oneself, i.e. acquire (buy)"
	},
	"G1567" : {
		"kjv" : "en- (re-)quire, seek after (carefully, diligently)",
		"translit" : "ekzētéō",
		"lemma" : "ἐκζητέω",
		"derivation" : "from G1537 (ἐκ) and G2212 (ζητέω)",
		"def" : "to search out, i.e. (figuratively)investigate, crave, demand, (by Hebraism) worship"
	},
	"G2525" : {
		"def" : "to place down (permanently), i.e. (figuratively) to designate, constitute, convoy",
		"derivation" : "from G2596 (κατά) and G2476 (ἵστημι)",
		"lemma" : "καθίστημι",
		"translit" : "kathístēmi",
		"kjv" : "appoint, be, conduct, make, ordain, set"
	},
	"G3465" : {
		"derivation" : "of uncertain origin",
		"def" : "Mysia, a region of Asia Minor",
		"kjv" : "Mysia",
		"lemma" : "Μυσία",
		"translit" : "Mysía"
	},
	"G3479" : {
		"def" : "a Nazarene, i.e. inhabitant of Nazareth",
		"derivation" : "from G3478 (Ναζαρέθ)",
		"lemma" : "Ναζαρηνός",
		"translit" : "Nazarēnós",
		"kjv" : "of Nazareth"
	},
	"G2600" : {
		"lemma" : "κατάβασις",
		"translit" : "katábasis",
		"kjv" : "descent",
		"def" : "a declivity",
		"derivation" : "from G2597 (καταβαίνω)"
	},
	"G3489" : {
		"translit" : "nauagéō",
		"lemma" : "ναυαγέω",
		"kjv" : "make (suffer) shipwreck",
		"def" : "to be shipwrecked (stranded, \"navigate\"), literally or figuratively",
		"derivation" : "from a compound of G3491 (ναῦς) and G71 (ἄγω)"
	},
	"G2775" : {
		"kjv" : "wound in the head",
		"lemma" : "κεφαλαιόω",
		"translit" : "kephalaióō",
		"derivation" : "from the same as G2774 (κεφάλαιον)",
		"def" : "(specially) to strike on the head"
	},
	"G1086" : {
		"def" : "a Gergesene (i.e. Girgashite) or one of the aborigines of Palestine",
		"derivation" : "of Hebrew origin (H1622)",
		"lemma" : "Γεργεσηνός",
		"translit" : "Gergesēnós",
		"kjv" : "Gergesene"
	},
	"G4792" : {
		"def" : "to convey together, i.e. collect or bear away in company with others",
		"derivation" : "from G4862 (σύν) and G2865 (κομίζω)",
		"lemma" : "συγκομίζω",
		"translit" : "synkomízō",
		"kjv" : "carry"
	},
	"G1223" : {
		"def" : "through (in very wide applications, local, causal, or occasional)",
		"derivation" : "a primary preposition denoting the channel of an act",
		"lemma" : "διά",
		"translit" : "diá",
		"kjv" : "after, always, among, at, to avoid, because of (that), briefly, by, for (cause) … fore, from, in, by occasion of, of, by reason of, for sake, that, thereby, therefore, X though, through(-out), to, wherefore, with (-in)"
	},
	"G3415" : {
		"def" : "to bear in mind, i.e. recollect; by implication, to reward or punish",
		"derivation" : "middle voice of a derivative of G3306 (μένω) or perhaps of the base of G3145 (μασσάομαι) (through the idea of fixture in the mind or of mental grasp)",
		"lemma" : "μνάομαι",
		"translit" : "mnáomai",
		"kjv" : "be mindful, remember, come (have) in remembrance"
	},
	"G5093" : {
		"derivation" : "from G5092 (τιμή)",
		"def" : "valuable, i.e. (objectively) costly, or (subjectively) honored, esteemed, or (figuratively) beloved",
		"kjv" : "dear, honourable, (more, most) precious, had in reputation",
		"translit" : "tímios",
		"lemma" : "τίμιος"
	},
	"G3189" : {
		"translit" : "mélas",
		"lemma" : "μέλας",
		"kjv" : "black",
		"def" : "black",
		"derivation" : "apparently a primary word"
	},
	"G5023" : {
		"translit" : "taûta",
		"lemma" : "ταῦτα",
		"kjv" : "+ afterward, follow, + hereafter, X him, the same, so, such, that, then, these, they, this, those, thus",
		"def" : "these things",
		"derivation" : "nominative or accusative case neuter plural of G3778 (οὗτος)"
	},
	"G1397" : {
		"kjv" : "bondage",
		"lemma" : "δουλεία",
		"translit" : "douleía",
		"derivation" : "from G1398 (δουλεύω)",
		"def" : "slavery (ceremonially or figuratively)"
	},
	"G3890" : {
		"derivation" : "neuter of G3889 (παραμυθία)",
		"def" : "consolation (properly, concretely)",
		"kjv" : "comfort",
		"lemma" : "παραμύθιον",
		"translit" : "paramýthion"
	},
	"G2171" : {
		"def" : "properly, a wish, expressed as a petition to God, or in votive obligation",
		"derivation" : "from G2172 (εὔχομαι)",
		"translit" : "euchḗ",
		"lemma" : "εὐχή",
		"kjv" : "prayer, vow"
	},
	"G5438" : {
		"def" : "a guarding or (concretely, guard), the act, the person; figuratively, the place, the condition, or (specially), the time (as a division of day or night), literally or figuratively",
		"derivation" : "from G5442 (φυλάσσω)",
		"lemma" : "φυλακή",
		"translit" : "phylakḗ",
		"kjv" : "cage, hold, (im-)prison(-ment), ward, watch"
	},
	"G4274" : {
		"kjv" : "forerunner",
		"lemma" : "πρόδρομος",
		"translit" : "pródromos",
		"derivation" : "from the alternate of G4390 (προτρέχω)",
		"def" : "a runner ahead, i.e. scout (figuratively, precursor)"
	},
	"G1536" : {
		"derivation" : "from G1487 (εἰ) and G5100 (τὶς)",
		"def" : "if any",
		"kjv" : "he that, if a(-ny) man('s thing, from any, ought), whether any, whosoever",
		"translit" : "eí tis",
		"lemma" : "εἴ τις"
	},
	"G1404" : {
		"translit" : "drákōn",
		"lemma" : "δράκων",
		"kjv" : "dragon",
		"def" : "a fabulous kind of serpent (perhaps as supposed to fascinate)",
		"derivation" : "probably from an alternate form of (to look)"
	},
	"G1501" : {
		"def" : "a score",
		"derivation" : "of uncertain affinity",
		"lemma" : "εἴκοσι",
		"translit" : "eíkosi",
		"kjv" : "twenty"
	},
	"G4988" : {
		"kjv" : "Sosthenes",
		"lemma" : "Σωσθένης",
		"translit" : "Sōsthénēs",
		"derivation" : "from the base of G4982 (σώζω) and that of G4599 (σθενόω)",
		"def" : "of safe strength; Sosthenes, a Christian"
	},
	"G5347" : {
		"def" : "festal; Phestus (i.e. Festus), a Roman",
		"derivation" : "of Latin derivation",
		"lemma" : "Φῆστος",
		"translit" : "Phēstos",
		"kjv" : "Festus"
	},
	"G289" : {
		"translit" : "ampelourgós",
		"lemma" : "ἀμπελουργός",
		"kjv" : "vine-dresser",
		"def" : "a vine-worker, i.e. pruner",
		"derivation" : "from G288 (ἄμπελος) and G2041 (ἔργον)"
	},
	"G182" : {
		"lemma" : "ἀκατάστατος",
		"translit" : "akatástatos",
		"kjv" : "unstable",
		"def" : "inconstant",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G2525 (καθίστημι)"
	},
	"G12" : {
		"kjv" : "deep, (bottomless) pit",
		"lemma" : "ἄβυσσος",
		"translit" : "ábyssos",
		"derivation" : "from G1 (Α) (as a negative particle) and a variation of G1037 (βυθός)",
		"def" : "depthless, i.e. (specially) (infernal) \"abyss\""
	},
	"G4911" : {
		"kjv" : "rise up together",
		"translit" : "synephístēmi",
		"lemma" : "συνεφίστημι",
		"derivation" : "from G4862 (σύν) and G2186 (ἐφίστημι)",
		"def" : "to stand up together, i.e. to resist (or assault) jointly"
	},
	"G2246" : {
		"def" : "perhaps akin to the alternate of G138 (αἱρέομαι)); the sun; by implication, light",
		"derivation" : "from (a ray",
		"translit" : "hḗlios",
		"lemma" : "ἥλιος",
		"kjv" : "+ east, sun"
	},
	"G2993" : {
		"lemma" : "Λαοδίκεια",
		"translit" : "Laodíkeia",
		"kjv" : "Laodicea",
		"def" : "Laodicia, a place in Asia Minor",
		"derivation" : "from a compound of G2992 (λαός) and G1349 (δίκη)"
	},
	"G3045" : {
		"lemma" : "λιπαρός",
		"translit" : "liparós",
		"kjv" : "dainty",
		"def" : "fat, i.e. (figuratively) sumptuous",
		"derivation" : "from (grease)"
	},
	"G4878" : {
		"derivation" : "from G4862 (σύν) and G482 (ἀντιλαμβάνομαι)",
		"def" : "to take hold of opposite together, i.e. co-operate (assist)",
		"kjv" : "help",
		"translit" : "synantilambánomai",
		"lemma" : "συναντιλαμβάνομαι"
	},
	"G4198" : {
		"derivation" : "middle voice from a derivative of the same as G3984 (πεῖρα)",
		"def" : "to traverse, i.e. travel (literally or figuratively; especially to remove (figuratively, die), live, etc.); ",
		"kjv" : "--depart, go (away, forth, one's way, up), (make a, take a) journey, walk",
		"translit" : "poreúomai",
		"lemma" : "πορεύομαι"
	},
	"G4306" : {
		"derivation" : "from G4253 (πρό) and G3539 (νοιέω)",
		"def" : "to consider in advance, i.e. look out for beforehand (actively, by way of maintenance for others; middle voice by way of circumspection for oneself)",
		"kjv" : "provide (for)",
		"lemma" : "προνοέω",
		"translit" : "pronoéō"
	},
	"G3323" : {
		"def" : "the Messias (i.e. Mashiach), or Christ",
		"derivation" : "of Hebrew origin (H4899)",
		"lemma" : "Μεσσίας",
		"translit" : "Messías",
		"kjv" : "Messias"
	},
	"G474" : {
		"def" : "to bandy",
		"derivation" : "from G473 (ἀντί) and G906 (βάλλω)",
		"translit" : "antibállō",
		"lemma" : "ἀντιβάλλω",
		"kjv" : "have"
	},
	"G4128" : {
		"translit" : "plēthos",
		"lemma" : "πλῆθος",
		"kjv" : "bundle, company, multitude",
		"def" : "a fulness, i.e. a large number, throng, populace",
		"derivation" : "from G4130 (πλήθω)"
	},
	"G1093" : {
		"kjv" : "country, earth(-ly), ground, land, world",
		"translit" : "gē",
		"lemma" : "γῆ",
		"derivation" : "contracted from a primary word",
		"def" : "soil; by extension a region, or the solid part or the whole of the terrene globe (including the occupants in each application)"
	},
	"G60" : {
		"def" : "relating to the market-place, i.e. forensic (times); by implication, vulgar",
		"derivation" : "from G58 (ἀγορά)",
		"translit" : "agoraîos",
		"lemma" : "ἀγοραῖος",
		"kjv" : "baser sort, low"
	},
	"G4324" : {
		"def" : "to menace additionally",
		"derivation" : "from G4314 (πρός) and G546 (ἀπειλέω)",
		"lemma" : "προσαπειλέω",
		"translit" : "prosapeiléō",
		"kjv" : "i"
	},
	"G5506" : {
		"derivation" : "from G5507 (χίλιοι) and G757 (ἄρχω)",
		"def" : "the commander of a thousand soldiers (\"chiliarch\"; i.e. colonel",
		"kjv" : "(chief, high) captain",
		"lemma" : "χιλίαρχος",
		"translit" : "chilíarchos"
	},
	"G1945" : {
		"kjv" : "impose, be instant, (be) laid (there-, up-)on, (when) lay (on), lie (on), press upon",
		"lemma" : "ἐπίκειμαι",
		"translit" : "epíkeimai",
		"derivation" : "from G1909 (ἐπί) and G2749 (κεῖμαι)",
		"def" : "to rest upon (literally or figuratively)"
	},
	"G610" : {
		"derivation" : "from G611 (ἀποκρίνομαι) (in its original sense of judging)",
		"def" : "a judicial decision",
		"kjv" : "sentence",
		"translit" : "apókrima",
		"lemma" : "ἀπόκριμα"
	},
	"G2216" : {
		"derivation" : "of Hebrew origin (H2216)",
		"def" : "Zorobabel (i.e. Zerubbabel), an Israelite",
		"kjv" : "Zorobabel",
		"lemma" : "Ζοροβάβελ",
		"translit" : "Zorobábel"
	},
	"G962" : {
		"lemma" : "Βηθαβαρά",
		"translit" : "Bēthabará",
		"kjv" : "Bethabara",
		"def" : "ferry-house; Bethabara (i.e. Bethabarah), a place on the Jordan",
		"derivation" : "of Hebrew origin (H1004 and H5679)"
	},
	"G131" : {
		"def" : "to flow blood, i.e. have a hæmorrhage",
		"derivation" : "from G129 (αἷμα) and G4482 (ῥέω)",
		"lemma" : "αἱμοῤῥέω",
		"translit" : "haimorrhéō",
		"kjv" : "diseased with an issue of blood"
	},
	"G4717" : {
		"translit" : "stauróō",
		"lemma" : "σταυρόω",
		"kjv" : "crucify",
		"def" : "to impale on the cross; figuratively, to extinguish (subdue) passion or selfishness",
		"derivation" : "from G4716 (σταυρός)"
	},
	"G2143" : {
		"derivation" : "from a compound of G2095 (εὖ) and G4241 (πρέπω)",
		"def" : "good suitableness, i.e. gracefulness",
		"kjv" : "grace",
		"translit" : "euprépeia",
		"lemma" : "εὐπρέπεια"
	},
	"G5432" : {
		"lemma" : "φρουρέω",
		"translit" : "phrouréō",
		"kjv" : "keep (with a garrison)",
		"def" : "to be a watcher in advance, i.e. to mount guard as a sentinel (post spies at gates); figuratively, to hem in, protect",
		"derivation" : "from a compound of G4253 (πρό) and G3708 (ὁράω)"
	},
	"G65" : {
		"derivation" : "from G66 (ἄγριος) and G1636 (ἐλαία)",
		"def" : "an oleaster",
		"kjv" : "olive tree (which is) wild",
		"translit" : "agriélaios",
		"lemma" : "ἀγριέλαιος"
	},
	"G1537" : {
		"def" : "literal or figurative; direct or remote)",
		"derivation" : "a primary preposition denoting origin (the point whence action or motion proceeds), from, out (of place, time, or cause",
		"translit" : "ek",
		"lemma" : "ἐκ",
		"kjv" : "after, among, X are, at, betwixt(-yond), by (the means of), exceedingly, (+ abundantly above), for(- th), from (among, forth, up), + grudgingly, + heartily, X heavenly, X hereby, + very highly, in, …ly, (because, by reason) of, off (from), on, out among (from, of), over, since, X thenceforth, through, X unto, X vehemently, with(-out)"
	},
	"G9" : {
		"derivation" : "of foreign origin (compare H58)",
		"def" : "Abilene, a region of Syria",
		"kjv" : "Abilene",
		"lemma" : "Ἀβιληνή",
		"translit" : "Abilēnḗ"
	},
	"G1861" : {
		"def" : "to announce upon (reflexively), i.e. (by implication) to engage to do something, to assert something respecting oneself",
		"derivation" : "from G1909 (ἐπί) and the base of G32 (ἄγγελος)",
		"translit" : "epangéllō",
		"lemma" : "ἐπαγγέλλω",
		"kjv" : "profess, (make) promise"
	},
	"G5467" : {
		"kjv" : "fierce, perilous",
		"lemma" : "χαλεπός",
		"translit" : "chalepós",
		"derivation" : "perhaps from G5465 (χαλάω) through the idea of reducing the strength",
		"def" : "difficult, i.e. dangerous, or (by implication) furious"
	},
	"G2204" : {
		"def" : "to be hot (boil, of liquids; or glow, of solids), i.e. (figuratively) be fervid (earnest)",
		"derivation" : "a primary verb",
		"translit" : "zéō",
		"lemma" : "ζέω",
		"kjv" : "be fervent"
	},
	"G446" : {
		"derivation" : "from G473 (ἀντί) and a superlative of G5228 (ὑπέρ)",
		"def" : "instead of the highest officer, i.e. (specially) a Roman proconsul",
		"kjv" : "deputy",
		"lemma" : "ἀνθύπατος",
		"translit" : "anthýpatos"
	},
	"G2870" : {
		"lemma" : "κοπετός",
		"translit" : "kopetós",
		"kjv" : "lamentation",
		"def" : "mourning (properly, by beating the breast)",
		"derivation" : "from G2875 (κόπτω)"
	},
	"G4178" : {
		"kjv" : "oft(-en, -entimes, -times)",
		"lemma" : "πολλάκις",
		"translit" : "pollákis",
		"derivation" : "multiplicative adverb from G4183 (πολύς)",
		"def" : "many times, i.e. frequently"
	},
	"G374" : {
		"def" : "to incite",
		"derivation" : "from G303 (ἀνά) and G3982 (πείθω)",
		"lemma" : "ἀναπείθω",
		"translit" : "anapeíthō",
		"kjv" : "persuade"
	},
	"G4697" : {
		"translit" : "splanchnízomai",
		"lemma" : "σπλαγχνίζομαι",
		"kjv" : "have (be moved with) compassion",
		"def" : "to have the bowels yearn, i.e. (figuratively) feel sympathy, to pity",
		"derivation" : "middle voice from G4698 (σπλάγχνον)"
	},
	"G509" : {
		"lemma" : "ἄνωθεν",
		"translit" : "ánōthen",
		"kjv" : "from above, again, from the beginning (very first), the top",
		"def" : "from above; by analogy, from the first; by implication, anew",
		"derivation" : "from G507 (ἄνω)"
	},
	"G4882" : {
		"derivation" : "from G4862 (σύν) and G649 (ἀποστέλλω)",
		"def" : "to despatch (on an errand) in company with",
		"kjv" : "send with",
		"lemma" : "συναποστέλλω",
		"translit" : "synapostéllō"
	},
	"G587" : {
		"derivation" : "from G588 (ἀποδέχομαι)",
		"def" : "accepted, i.e. agreeable",
		"kjv" : "acceptable",
		"translit" : "apódektos",
		"lemma" : "ἀπόδεκτος"
	},
	"G4802" : {
		"derivation" : "from G4862 (σύν) and G2212 (ζητέω)",
		"def" : "to investigate jointly, i.e. discuss, controvert, cavil",
		"kjv" : "dispute (with), enquire, question (with), reason (together)",
		"lemma" : "συζητέω",
		"translit" : "syzētéō"
	},
	"G977" : {
		"lemma" : "βιβρώσκω",
		"translit" : "bibrṓskō",
		"kjv" : "eat",
		"def" : "to eat",
		"derivation" : "a reduplicated and prolonged form of an obsolete primary verb (perhaps causative of G1006 (βόσκω))"
	},
	"G4759" : {
		"derivation" : "from G4760 (στρατόπεδον) and G757 (ἄρχω)",
		"def" : "a ruler of an army, i.e. (specially), a Prætorian præfect",
		"kjv" : "captain of the guard",
		"lemma" : "στρατοπεδάρχης",
		"translit" : "stratopedárchēs"
	},
	"G5489" : {
		"derivation" : "from the same as G5482 (χάραξ)",
		"def" : "a sheet (\"chart\") of writing-material (as to be scribbled over)",
		"kjv" : "paper",
		"lemma" : "χάρτης",
		"translit" : "chártēs"
	},
	"G4924" : {
		"derivation" : "from G4862 (σύν) and G3611 (οἰκέω)",
		"def" : "to reside together (as a family)",
		"kjv" : "dwell together",
		"lemma" : "συνοικέω",
		"translit" : "synoikéō"
	},
	"G4482" : {
		"def" : "for some tenses of which a prolonged form is used to flow (\"run\"; as water)",
		"derivation" : "a primary verb",
		"lemma" : "ῥέω",
		"translit" : "rhéō",
		"kjv" : "flow"
	},
	"G3354" : {
		"lemma" : "μετρέω",
		"translit" : "metréō",
		"kjv" : "figuratively, to estimate:--measure, mete",
		"def" : "to measure (i.e. ascertain in size by a fixed standard); by implication, to admeasure (i.e. allot by rule)",
		"derivation" : "from G3358 (μέτρον)"
	},
	"G2559" : {
		"derivation" : "from G2556 (κακός)",
		"def" : "to injure; figuratively, to exasperate",
		"kjv" : "make evil affected, entreat evil, harm, hurt, vex",
		"lemma" : "κακόω",
		"translit" : "kakóō"
	},
	"G3309" : {
		"translit" : "merimnáō",
		"lemma" : "μεριμνάω",
		"kjv" : "(be, have) care(-ful), take thought",
		"def" : "to be anxious about",
		"derivation" : "from G3308 (μέριμνα)"
	},
	"G3184" : {
		"derivation" : "from another form of G3178 (μέθη)",
		"def" : "to drink to intoxication, i.e. get drunk",
		"kjv" : "drink well, make (be) drunk(-en)",
		"lemma" : "μεθύω",
		"translit" : "methýō"
	},
	"G5260" : {
		"kjv" : "suborn",
		"translit" : "hypobállō",
		"lemma" : "ὑποβάλλω",
		"derivation" : "from G5259 (ὑπό) and G906 (βάλλω)",
		"def" : "to throw in stealthily, i.e. introduce by collusion"
	},
	"G2920" : {
		"def" : "decision (subjectively or objectively, for or against); by extension, a tribunal; by implication, justice (especially, divine law)",
		"translit" : "krísis",
		"lemma" : "κρίσις",
		"kjv" : "accusation, condemnation, damnation, judgment"
	},
	"G2479" : {
		"def" : "compare , a form of G2192 (ἔχω)); forcefulness (literally or figuratively)",
		"derivation" : "from a derivative of (force",
		"lemma" : "ἰσχύς",
		"translit" : "ischýs",
		"kjv" : "ability, might(-ily), power, strength"
	},
	"G301" : {
		"lemma" : "Ἀμώς",
		"translit" : "Amṓs",
		"kjv" : "Amos",
		"def" : "Amos, an Israelite",
		"derivation" : "of Hebrew origin (H531)"
	},
	"G2847" : {
		"derivation" : "from G2848 (κόκκος) (from the kernel-shape of the insect)",
		"def" : "crimson-colored",
		"kjv" : "scarlet (colour, coloured)",
		"lemma" : "κόκκινος",
		"translit" : "kókkinos"
	},
	"G1395" : {
		"kjv" : "giver",
		"lemma" : "δότης",
		"translit" : "dótēs",
		"derivation" : "from the base of G1325 (δίδωμι)",
		"def" : "a giver"
	},
	"G1633" : {
		"kjv" : "depart out",
		"translit" : "ekchōréō",
		"lemma" : "ἐκχωρέω",
		"derivation" : "from G1537 (ἐκ) and G5562 (χωρέω)",
		"def" : "to depart"
	},
	"G2674" : {
		"derivation" : "from G2596 (κατά) and G705 (ἀριθμέω)",
		"def" : "to reckon among",
		"kjv" : "number with",
		"lemma" : "καταριθμέω",
		"translit" : "katarithméō"
	},
	"G4002" : {
		"lemma" : "πέντε",
		"translit" : "pénte",
		"kjv" : "five",
		"def" : "\"five\"",
		"derivation" : "a primary number"
	},
	"G1418" : {
		"kjv" : "+ hard, + grievous, etc.",
		"translit" : "dys-",
		"lemma" : "δυσ-",
		"derivation" : "a primary inseparable particle of uncertain derivation",
		"def" : "used only in composition as a prefix; hard, i.e. with difficulty"
	},
	"G1110" : {
		"derivation" : "from G1097 (γινώσκω)",
		"def" : "well-known",
		"kjv" : "acquaintance, (which may be) known, notable",
		"lemma" : "γνωστός",
		"translit" : "gnōstós"
	},
	"G1098" : {
		"translit" : "gleûkos",
		"lemma" : "γλεῦκος",
		"kjv" : "new wine",
		"def" : "sweet wine, i.e. (properly) must (fresh juice), but used of the more saccharine (and therefore highly inebriating) fermented wine",
		"derivation" : "akin to G1099 (γλυκύς)"
	},
	"G1266" : {
		"lemma" : "διαμερίζω",
		"translit" : "diamerízō",
		"kjv" : "cloven, divide, part",
		"def" : "to partition thoroughly (literally in distribution, figuratively in dissension)",
		"derivation" : "from G1223 (διά) and G3307 (μερίζω)"
	},
	"G4254" : {
		"derivation" : "from G4253 (πρό) and G71 (ἄγω)",
		"def" : "to lead forward (magisterially); intransitively, to precede (in place or time (participle, previous))",
		"kjv" : "bring (forth, out), go before",
		"translit" : "proágō",
		"lemma" : "προάγω"
	},
	"G4767" : {
		"kjv" : "hateful",
		"translit" : "stygnētós",
		"lemma" : "στυγνητός",
		"derivation" : "from a derivative of an obsolete apparently primary (to hate)",
		"def" : "hated, i.e. odious"
	},
	"G850" : {
		"kjv" : "dark",
		"lemma" : "αὐχμηρός",
		"translit" : "auchmērós",
		"derivation" : "from (probably from a base akin to that of G109 (ἀήρ)) (dust, as dried by wind)",
		"def" : "properly, dirty, i.e. (by implication) obscure"
	},
	"G4742" : {
		"kjv" : "mark",
		"translit" : "stígma",
		"lemma" : "στίγμα",
		"derivation" : "from a primary (to \"stick\", i.e. prick)",
		"def" : "a mark incised or punched (for recognition of ownership), i.e. (figuratively) scar of service"
	},
	"G4669" : {
		"kjv" : "mingle with myrrh",
		"translit" : "smyrnízō",
		"lemma" : "σμυρνίζω",
		"derivation" : "from G4667 (Σμύρνα)",
		"def" : "to tincture with myrrh, i.e. embitter (as a narcotic)"
	},
	"G4298" : {
		"kjv" : "increase, proceed, profit, be far spent, wax",
		"lemma" : "προκόπτω",
		"translit" : "prokóptō",
		"derivation" : "from G4253 (πρό) and G2875 (κόπτω)",
		"def" : "to drive forward (as if by beating), i.e. (figuratively and intransitively) to advance (in amount, to grow; in time, to be well along)"
	},
	"G5210" : {
		"lemma" : "ὑμεῖς",
		"translit" : "hymeîs",
		"kjv" : "ye (yourselves), you",
		"def" : "you (as subjective of verb)",
		"derivation" : "irregular plural of G4771 (σύ)"
	},
	"G4464" : {
		"derivation" : "from the base of G4474 (ῥαπίζω)",
		"def" : "a stick or wand (as a cudgel, a cane or a baton of royalty)",
		"kjv" : "rod, sceptre, staff",
		"translit" : "rhábdos",
		"lemma" : "ῥάβδος"
	},
	"G1849" : {
		"translit" : "exousía",
		"lemma" : "ἐξουσία",
		"kjv" : "authority, jurisdiction, liberty, power, right, strength",
		"def" : "privilege, i.e. (subjectively) force, capacity, competency, freedom, or (objectively) mastery (concretely, magistrate, superhuman, potentate, token of control), delegated influence",
		"derivation" : "from G1832 (ἔξεστι) (in the sense of ability)"
	},
	"G1574" : {
		"kjv" : "pierce",
		"translit" : "ekkentéō",
		"lemma" : "ἐκκεντέω",
		"derivation" : "from G1537 (ἐκ) and the base of G2759 (κέντρον)",
		"def" : "to transfix"
	},
	"G1754" : {
		"def" : "to be active, efficient",
		"derivation" : "from G1756 (ἐνεργής)",
		"translit" : "energéō",
		"lemma" : "ἐνεργέω",
		"kjv" : "do, (be) effectual (fervent), be mighty in, shew forth self, work (effectually in)"
	},
	"G894" : {
		"derivation" : "of uncertain derivation",
		"def" : "wormwood (as a type of bitterness, i.e. (figuratively) calamity)",
		"kjv" : "wormwood",
		"lemma" : "ἄψινθος",
		"translit" : "ápsinthos"
	},
	"G4431" : {
		"lemma" : "πτῶσις",
		"translit" : "ptōsis",
		"kjv" : "fall",
		"def" : "a crash, i.e. downfall (literally or figuratively)",
		"derivation" : "from the alternate of G4098 (πίπτω)"
	},
	"G2032" : {
		"def" : "above the sky",
		"derivation" : "from G1909 (ἐπί) and G3772 (οὐρανός)",
		"lemma" : "ἐπουράνιος",
		"translit" : "epouránios",
		"kjv" : "celestial, (in) heaven(-ly), high"
	},
	"G227" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G2990 (λανθάνω)",
		"def" : "true (as not concealing)",
		"kjv" : "true, truly, truth",
		"lemma" : "ἀληθής",
		"translit" : "alēthḗs"
	},
	"G3835" : {
		"def" : "all-working, i.e. adroit (shrewd)",
		"derivation" : "from G3956 (πᾶς) and G2041 (ἔργον)",
		"lemma" : "πανοῦργος",
		"translit" : "panoûrgos",
		"kjv" : "crafty"
	},
	"G475" : {
		"def" : "to set oneself opposite, i.e. be disputatious",
		"derivation" : "from G473 (ἀντί) and G1303 (διατίθεμαι)",
		"translit" : "antidiatíthemai",
		"lemma" : "ἀντιδιατίθεμαι",
		"kjv" : "that oppose themselves"
	},
	"G4509" : {
		"translit" : "rhýpos",
		"lemma" : "ῥύπος",
		"kjv" : "filth",
		"def" : "dirt, i.e. (morally) depravity",
		"derivation" : "of uncertain affinity"
	},
	"G252" : {
		"kjv" : "salt",
		"translit" : "halykós",
		"lemma" : "ἁλυκός",
		"derivation" : "from G251 (ἅλς)",
		"def" : "briny"
	},
	"G1606" : {
		"translit" : "ekpnéō",
		"lemma" : "ἐκπνέω",
		"kjv" : "give up the ghost",
		"def" : "to expire",
		"derivation" : "from G1537 (ἐκ) and G4154 (πνέω)"
	},
	"G4999" : {
		"kjv" : "taverns",
		"translit" : "Tabérnai",
		"lemma" : "Ταβέρναι",
		"derivation" : "plural of Latin origin",
		"def" : "huts or wooden-walled buildings; Tabernæ"
	},
	"G2128" : {
		"def" : "adorable",
		"derivation" : "from G2127 (εὐλογέω)",
		"translit" : "eulogētós",
		"lemma" : "εὐλογητός",
		"kjv" : "blessed"
	},
	"G764" : {
		"derivation" : "from G765 (ἀσεβής)",
		"def" : "to be (by implied act) impious or wicked",
		"kjv" : "commit (live, that after should live) ungodly",
		"translit" : "asebéō",
		"lemma" : "ἀσεβέω"
	},
	"G4677" : {
		"derivation" : "of Hebrew origin (H7799 feminine)",
		"def" : "lily; Susannah (i.e. Shoshannah), an Israelitess",
		"kjv" : "Susanna",
		"translit" : "Sousánna",
		"lemma" : "Σουσάννα"
	},
	"G3399" : {
		"def" : "Miletus, a city of Asia Minor",
		"derivation" : "of uncertain origin",
		"translit" : "Mílētos",
		"lemma" : "Μίλητος",
		"kjv" : "Miletus"
	},
	"G3135" : {
		"derivation" : "from (a pearl-oyster)",
		"def" : "a pearl",
		"kjv" : "pearl",
		"lemma" : "μαργαρίτης",
		"translit" : "margarítēs"
	},
	"G2589" : {
		"derivation" : "from G2588 (καρδία) and G1097 (γινώσκω)",
		"def" : "a heart-knower",
		"kjv" : "which knowest the hearts",
		"lemma" : "καρδιογνώστης",
		"translit" : "kardiognṓstēs"
	},
	"G2964" : {
		"kjv" : "confirm",
		"lemma" : "κυρόω",
		"translit" : "kyróō",
		"derivation" : "from the same as G2962 (κύριος)",
		"def" : "to make authoritative, i.e. ratify"
	},
	"G5549" : {
		"translit" : "chronízō",
		"lemma" : "χρονίζω",
		"kjv" : "delay, tarry",
		"def" : "to take time, i.e. linger",
		"derivation" : "from G5550 (χρόνος)"
	},
	"G3934" : {
		"kjv" : "Parthian",
		"translit" : "Párthos",
		"lemma" : "Πάρθος",
		"derivation" : "probably of foreign origin",
		"def" : "a Parthian, i.e. inhabitant of Parthia"
	},
	"G344" : {
		"derivation" : "from G303 (ἀνά) and G2578 (κάμπτω)",
		"def" : "to turn back",
		"kjv" : "(re-)turn",
		"translit" : "anakámptō",
		"lemma" : "ἀνακάμπτω"
	},
	"G1079" : {
		"translit" : "genetḗ",
		"lemma" : "γενετή",
		"kjv" : "birth",
		"def" : "birth",
		"derivation" : "feminine of a presumed derivative of the base of G1074 (γενεά)"
	},
	"G1694" : {
		"lemma" : "Ἐμμανουήλ",
		"translit" : "Emmanouḗl",
		"kjv" : "Emmanuel",
		"def" : "God with us; Emmanuel, a name of Christ",
		"derivation" : "of Hebrew origin (H6005)"
	},
	"G5200" : {
		"def" : "wet (as if with rain), i.e. (by implication) sappy (fresh)",
		"derivation" : "from the base of G5205 (ὑετός)",
		"translit" : "hygrós",
		"lemma" : "ὑγρός",
		"kjv" : "green"
	},
	"G3483" : {
		"lemma" : "ναί",
		"translit" : "naí",
		"kjv" : "even so, surely, truth, verily, yea, yes",
		"def" : "yes",
		"derivation" : "a primary particle of strong affirmation"
	},
	"G4443" : {
		"lemma" : "πυρά",
		"translit" : "pyrá",
		"kjv" : "fire",
		"def" : "a fire (concretely)",
		"derivation" : "from G4442 (πῦρ)"
	},
	"G1080" : {
		"kjv" : "bear, beget, be born, bring forth, conceive, be delivered of, gender, make, spring",
		"translit" : "gennáō",
		"lemma" : "γεννάω",
		"derivation" : "from a variation of G1085 (γένος)",
		"def" : "to procreate (properly, of the father, but by extension of the mother); figuratively, to regenerate"
	},
	"G1464" : {
		"translit" : "enkopḗ",
		"lemma" : "ἐγκοπή",
		"kjv" : "X hinder",
		"def" : "a hindrance",
		"derivation" : "from G1465 (ἐγκόπτω)"
	},
	"G5021" : {
		"derivation" : "a prolonged form of a primary verb (which latter appears only in certain tenses)",
		"def" : "to arrange in an orderly manner, i.e. assign or dispose (to a certain position or lot)",
		"kjv" : "addict, appoint, determine, ordain, set",
		"translit" : "tássō",
		"lemma" : "τάσσω"
	},
	"G3721" : {
		"lemma" : "ὄρθριος",
		"translit" : "órthrios",
		"kjv" : "early",
		"def" : "in the dawn, i.e. up at day-break",
		"derivation" : "from G3722 (ὄρθρος)"
	},
	"G152" : {
		"translit" : "aischýnē",
		"lemma" : "αἰσχύνη",
		"kjv" : "dishonesty, shame",
		"def" : "shame or disgrace (abstractly or concretely)",
		"derivation" : "from G153 (αἰσχύνομαι)"
	},
	"G5118" : {
		"def" : "apparently from G3588 (ὁ) and G3739 (ὅς)) and G3778 (οὗτος) (including its variations); so vast as this, i.e. such (in quantity, amount, number of space)",
		"derivation" : "from (so much",
		"lemma" : "τοσοῦτος",
		"translit" : "tosoûtos",
		"kjv" : "as large, so great (long, many, much), these many"
	},
	"G5391" : {
		"def" : "friendly of mind, i.e. kind",
		"derivation" : "from G5384 (φίλος) and G5424 (φρήν)",
		"lemma" : "φιλόφρων",
		"translit" : "philóphrōn",
		"kjv" : "courteous"
	},
	"G4342" : {
		"derivation" : "from G4314 (πρός) and G2594 (καρτερέω)",
		"def" : "to be earnest towards, i.e. (to a thing) to persevere, be constantly diligent, or (in a place) to attend assiduously all the exercises, or (to a person) to adhere closely to (as a servitor)",
		"kjv" : "attend (give self) continually (upon), continue (in, instant in, with), wait on (continually)",
		"lemma" : "προσκαρτερέω",
		"translit" : "proskarteréō"
	},
	"G4093" : {
		"def" : "a tablet (for writing on)",
		"derivation" : "diminutive of G4094 (πίναξ)",
		"translit" : "pinakídion",
		"lemma" : "πινακίδιον",
		"kjv" : "writing table"
	},
	"G271" : {
		"translit" : "améthystos",
		"lemma" : "ἀμέθυστος",
		"kjv" : "amethyst",
		"def" : "the \"amethyst\" (supposed to prevent intoxication)",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G3184 (μεθύω)"
	},
	"G786" : {
		"kjv" : "implacable, truce-breaker",
		"lemma" : "ἄσπονδος",
		"translit" : "áspondos",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G4689 (σπένδω)",
		"def" : "literally, without libation (which usually accompanied a treaty), i.e. (by implication) truceless"
	},
	"G4791" : {
		"def" : "a co-participant",
		"derivation" : "from G4862 (σύν) and G2844 (κοινωνός)",
		"lemma" : "συγκοινωνός",
		"translit" : "synkoinōnós",
		"kjv" : "companion, partake(-r, -r with)"
	},
	"G4723" : {
		"def" : "\"sterile\"",
		"derivation" : "a contraction from G4731 (στερεός) (as stiff and unnatural)",
		"lemma" : "στείρος",
		"translit" : "steíros",
		"kjv" : "barren"
	},
	"G5230" : {
		"derivation" : "from G5228 (ὑπέρ) and the base of G188 (ἀκμήν)",
		"def" : "beyond the \"acme\", i.e. figuratively (of a daughter) past the bloom (prime) of youth",
		"kjv" : "+ pass the flower of (her) age",
		"lemma" : "ὑπέρακμος",
		"translit" : "hypérakmos"
	},
	"G1583" : {
		"def" : "to divulge",
		"derivation" : "from G1537 (ἐκ) and G2980 (λαλέω)",
		"lemma" : "ἐκλαλέω",
		"translit" : "eklaléō",
		"kjv" : "tell"
	},
	"G4993" : {
		"translit" : "sōphronéō",
		"lemma" : "σωφρονέω",
		"kjv" : "be in right mind, be sober (minded), soberly",
		"def" : "to be of sound mind, i.e. sane, (figuratively) moderate",
		"derivation" : "from G4998 (σώφρων)"
	},
	"G4495" : {
		"def" : "to toss up",
		"derivation" : "from a derivative of G4496 (ῥίπτω)",
		"lemma" : "ῥιπτέω",
		"translit" : "rhiptéō",
		"kjv" : "cast off"
	},
	"G4771" : {
		"lemma" : "σύ",
		"translit" : "sý",
		"kjv" : "thou",
		"def" : "thou",
		"derivation" : "the personal pronoun of the second person singular"
	},
	"G934" : {
		"def" : "kingly (in nature)",
		"derivation" : "from G935 (βασιλεύς)",
		"translit" : "basíleios",
		"lemma" : "βασίλειος",
		"kjv" : "royal"
	},
	"G3696" : {
		"derivation" : "probably from a primary (to be busy about)",
		"def" : "an implement or utensil or tool (literally or figuratively, especially, offensive for war)",
		"kjv" : "armour, instrument, weapon",
		"translit" : "hóplon",
		"lemma" : "ὅπλον"
	},
	"G2929" : {
		"derivation" : "from G2930 (κρύσταλλος)",
		"def" : "to make (i.e. intransitively, resemble) ice (\"crystallize\")",
		"kjv" : "be clear as crystal",
		"lemma" : "κρυσταλλίζω",
		"translit" : "krystallízō"
	},
	"G3667" : {
		"translit" : "homoíōma",
		"lemma" : "ὁμοίωμα",
		"kjv" : "made like to, likeness, shape, similitude",
		"def" : "a form; abstractly, resemblance",
		"derivation" : "from G3666 (ὁμοιόω)"
	},
	"G630" : {
		"kjv" : "(let) depart, dismiss, divorce, forgive, let go, loose, put (send) away, release, set at liberty",
		"lemma" : "ἀπολύω",
		"translit" : "apolýō",
		"derivation" : "from G575 (ἀπό) and G3089 (λύω)",
		"def" : "to free fully, i.e. (literally) relieve, release, dismiss (reflexively, depart), or (figuratively) let die, pardon or (specially) divorce"
	},
	"G2936" : {
		"def" : "to fabricate, i.e. found (form originally)",
		"derivation" : "probably akin to G2932 (κτάομαι) (through the idea of proprietorship of the manufacturer)",
		"translit" : "ktízō",
		"lemma" : "κτίζω",
		"kjv" : "create, Creator, make"
	},
	"G5175" : {
		"lemma" : "Τρωγύλλιον",
		"translit" : "Trōgýllion",
		"kjv" : "Trogyllium",
		"def" : "Trogyllium, a place in Asia Minor",
		"derivation" : "of uncertain derivation"
	},
	"G449" : {
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G3538 (νίπτω)",
		"def" : "without ablution",
		"kjv" : "unwashen",
		"translit" : "ániptos",
		"lemma" : "ἄνιπτος"
	},
	"G2160" : {
		"def" : "witticism, i.e. (in a vulgar sense) ribaldry",
		"derivation" : "from a compound of G2095 (εὖ) and a derivative of the base of G5157 (τροπή) (meaning well-turned, i.e. ready at repartee, jocose)",
		"lemma" : "εὐτραπελία",
		"translit" : "eutrapelía",
		"kjv" : "jesting"
	},
	"G216" : {
		"kjv" : "dumb",
		"translit" : "álalos",
		"lemma" : "ἄλαλος",
		"derivation" : "from G1 (Α) (as a negative particle) and G2980 (λαλέω)",
		"def" : "mute"
	},
	"G523" : {
		"translit" : "apaitéō",
		"lemma" : "ἀπαιτέω",
		"kjv" : "ask again, require",
		"def" : "to demand back",
		"derivation" : "from G575 (ἀπό) and G154 (αἰτέω)"
	},
	"G4886" : {
		"def" : "a joint tie, i.e. ligament, (figuratively) uniting principle, control",
		"derivation" : "from G4862 (σύν) and G1199 (δεσμόν)",
		"translit" : "sýndesmos",
		"lemma" : "σύνδεσμος",
		"kjv" : "band, bond"
	},
	"G173" : {
		"lemma" : "ἄκανθα",
		"translit" : "ákantha",
		"kjv" : "thorn",
		"def" : "a thorn",
		"derivation" : "probably from the same as G188 (ἀκμήν)"
	},
	"G1898" : {
		"lemma" : "ἐπεισαγωγή",
		"translit" : "epeisagōgḗ",
		"kjv" : "bringing in",
		"def" : "a superintroduction",
		"derivation" : "from a compound of G1909 (ἐπί) and G1521 (εἰσάγω)"
	},
	"G3197" : {
		"derivation" : "of Hebrew origin (H4428 with pronominal suffix, my king)",
		"def" : "Melchi (i.e. Malki), the name of two Israelites",
		"kjv" : "Melchi",
		"translit" : "Melchí",
		"lemma" : "Μελχί"
	},
	"G460" : {
		"derivation" : "adverb from G459 (ἄνομος)",
		"def" : "lawlessly, i.e. (specially) not amenable to (the Jewish) law",
		"kjv" : "without law",
		"lemma" : "ἀνόμως",
		"translit" : "anómōs"
	},
	"G1551" : {
		"translit" : "ekdéchomai",
		"lemma" : "ἐκδέχομαι",
		"kjv" : "expect, look (tarry) for, wait (for)",
		"def" : "to accept from some source, i.e. (by implication) to await",
		"derivation" : "from G1537 (ἐκ) and G1209 (δέχομαι)"
	},
	"G1334" : {
		"kjv" : "declare, shew, tell",
		"lemma" : "διηγέομαι",
		"translit" : "diēgéomai",
		"derivation" : "from G1223 (διά) and G2233 (ἡγέομαι)",
		"def" : "to relate fully"
	},
	"G4472" : {
		"lemma" : "ῥαντίζω",
		"translit" : "rhantízō",
		"kjv" : "sprinkle",
		"def" : "to render besprinkled, i.e. asperse (ceremonially or figuratively)",
		"derivation" : "from a derivative of (to sprinkle)"
	},
	"G4437" : {
		"kjv" : "often(-er)",
		"lemma" : "πυκνός",
		"translit" : "pyknós",
		"derivation" : "from the same as G4635 (σκηνοποιός)",
		"def" : "clasped (thick), i.e. (figuratively) frequent; neuter plural (as adverb) frequently"
	},
	"G1466" : {
		"translit" : "enkráteia",
		"lemma" : "ἐγκράτεια",
		"kjv" : "temperance",
		"def" : "self-control (especially continence)",
		"derivation" : "from G1468 (ἐγκρατής)"
	},
	"G4666" : {
		"translit" : "smýrna",
		"lemma" : "σμύρνα",
		"kjv" : "myrrh",
		"def" : "myrrh",
		"derivation" : "apparently strengthened for G3464 (μύρον)"
	},
	"G1239" : {
		"def" : "to give throughout a crowd, i.e. deal out; also to deliver over (as to a successor)",
		"derivation" : "from G1223 (διά) and G1325 (δίδωμι)",
		"lemma" : "διαδίδωμι",
		"translit" : "diadídōmi",
		"kjv" : "(make) distribute(-ion), divide, give"
	},
	"G3336" : {
		"lemma" : "μετάλημψις",
		"translit" : "metálēmpsis",
		"kjv" : "taking",
		"def" : "participation",
		"derivation" : "from G3335 (μεταλαμβάνω)"
	},
	"G4196" : {
		"def" : "apparently \"popular\"; Poplius (i.e. Publius), a Roman",
		"derivation" : "of Latin origin",
		"lemma" : "Πόπλιος",
		"translit" : "Póplios",
		"kjv" : "Publius"
	},
	"G4340" : {
		"derivation" : "from G4314 (πρός) and G2540 (καιρός)",
		"def" : "for the occasion only, i.e. temporary",
		"kjv" : "dur-(eth) for awhile, endure for a time, for a season, temporal",
		"translit" : "próskairos",
		"lemma" : "πρόσκαιρος"
	},
	"G3066" : {
		"kjv" : "Lucius",
		"translit" : "Loúkios",
		"lemma" : "Λούκιος",
		"derivation" : "of Latin origin",
		"def" : "illuminative; Lucius, a Christian"
	},
	"G2685" : {
		"def" : "a reconnoiterer",
		"derivation" : "from G2596 (κατά) (intensive) and G4649 (σκοπός) (in the sense of a watcher)",
		"lemma" : "κατάσκοπος",
		"translit" : "katáskopos",
		"kjv" : "spy"
	},
	"G4055" : {
		"derivation" : "comparative of G4053 (περισσός)",
		"def" : "more superabundant (in number, degree or character)",
		"kjv" : "more abundant, greater (much) more, overmuch",
		"lemma" : "περισσότερος",
		"translit" : "perissóteros"
	},
	"G2512" : {
		"kjv" : "cleansing, + purge, purification(-fying)",
		"translit" : "katharismós",
		"lemma" : "καθαρισμός",
		"derivation" : "from G2511 (καθαρίζω)",
		"def" : "a washing off, i.e. (ceremonially) ablution, (morally) expiation"
	},
	"G4144" : {
		"translit" : "plóos",
		"lemma" : "πλόος",
		"kjv" : "course, sailing, voyage",
		"def" : "a sail, i.e. navigation",
		"derivation" : "from G4126 (πλέω)"
	},
	"G4381" : {
		"translit" : "prosōpolḗptēs",
		"lemma" : "προσωπολήπτης",
		"kjv" : "respecter of persons",
		"def" : "an accepter of a face (individual), i.e. (specially), one exhibiting partiality",
		"derivation" : "from G4383 (πρόσωπον) and G2983 (λαμβάνω)"
	},
	"G2924" : {
		"kjv" : "discerner",
		"translit" : "kritikós",
		"lemma" : "κριτικός",
		"derivation" : "from G2923 (κριτής)",
		"def" : "decisive (\"critical\"), i.e. discriminative"
	},
	"G4551" : {
		"kjv" : "Sapphira",
		"translit" : "Sappheírē",
		"lemma" : "Σαπφείρη",
		"derivation" : "feminine of G4552 (σάπφειρος)",
		"def" : "Sapphire, an Israelitess"
	},
	"G4914" : {
		"def" : "mutual habituation, i.e. usage",
		"derivation" : "from a compound of G4862 (σύν) and G2239 (ἦθος)",
		"lemma" : "συνήθεια",
		"translit" : "synḗtheia",
		"kjv" : "custom"
	},
	"G4773" : {
		"translit" : "syngenḗs",
		"lemma" : "συγγενής",
		"kjv" : "cousin, kin(-sfolk, -sman)",
		"def" : "a relative (by blood); by extension, a fellow countryman",
		"derivation" : "from G4862 (σύν) and G1085 (γένος)"
	},
	"G4927" : {
		"def" : "to border together, i.e. adjoin",
		"derivation" : "from G4862 (σύν) and a derivative of a compound of the base of 3674 and the base of G3725 (ὅριον)",
		"translit" : "synomoréō",
		"lemma" : "συνομορέω",
		"kjv" : "join hard"
	},
	"G1308" : {
		"translit" : "diaphérō",
		"lemma" : "διαφέρω",
		"kjv" : "be better, carry, differ from, drive up and down, be (more) excellent, make matter, publish, be of more value",
		"def" : "to bear through, i.e. (literally) transport; usually to bear apart, i.e. (objectively) to toss about (figuratively, report); subjectively, to \"differ\", or (by implication) surpass",
		"derivation" : "from G1223 (διά) and G5342 (φέρω)"
	},
	"G847" : {
		"kjv" : "(t-)here",
		"translit" : "autoû",
		"lemma" : "αὐτοῦ",
		"derivation" : "genitive (i.e. possessive) of G846 (αὐτός), used as an adverb of location",
		"def" : "properly, belonging to the same spot, i.e. in this (or that) place"
	},
	"G2147" : {
		"lemma" : "εὑρίσκω",
		"translit" : "heurískō",
		"kjv" : "find, get, obtain, perceive, see",
		"def" : "to find (literally or figuratively)",
		"derivation" : "a prolonged form of a primary , which (together with another cognate form) is used for it in all the tenses except the present and imperfect"
	},
	"G3762" : {
		"lemma" : "οὐδείς",
		"translit" : "oudeís",
		"kjv" : "any (man), aught, man, neither any (thing), never (man), no (man), none (+ of these things), not (any, at all, -thing), nought",
		"def" : "not even one (man, woman or thing), i.e. none, nobody, nothing",
		"derivation" : "from G3761 (οὐδέ) and G1520 (εἷς)"
	},
	"G3652" : {
		"derivation" : "probably a contraction from (Olympian-bestowed, i.e. heaven-descended)",
		"def" : "Olympas, a Christian",
		"kjv" : "Olympas",
		"translit" : "Olympâs",
		"lemma" : "Ὀλυμπᾶς"
	},
	"G136" : {
		"def" : "properly, a story, but used in the sense of G1868 (ἔπαινος); praise (of God)",
		"derivation" : "apparently a prime word",
		"translit" : "aînos",
		"lemma" : "αἶνος",
		"kjv" : "praise"
	},
	"G901" : {
		"kjv" : "deep, very early",
		"translit" : "bathýs",
		"lemma" : "βαθύς",
		"derivation" : "from the base of G939 (βάσις)",
		"def" : "profound (as going down), literally or figuratively"
	},
	"G4820" : {
		"lemma" : "συμβάλλω",
		"translit" : "symbállō",
		"kjv" : "confer, encounter, help, make, meet with, ponder",
		"def" : "to combine, i.e. (in speaking) to converse, consult, dispute, (mentally) to consider, (by implication) to aid, (personally) to join, attack",
		"derivation" : "from G4862 (σύν) and G906 (βάλλω)"
	},
	"G4535" : {
		"derivation" : "probably from the base of G4525 (σαίνω)",
		"def" : "a vibration, i.e. (specially), billow",
		"kjv" : "wave",
		"lemma" : "σάλος",
		"translit" : "sálos"
	},
	"G5128" : {
		"def" : "these (persons, as objective of verb or preposition)",
		"derivation" : "accusative case plural masculine of G3778 (οὗτος)",
		"lemma" : "τούτους",
		"translit" : "toútous",
		"kjv" : "such, them, these, this"
	},
	"G1777" : {
		"def" : "liable to (a condition, penalty or imputation)",
		"derivation" : "from G1758 (ἐνέχω)",
		"translit" : "énochos",
		"lemma" : "ἔνοχος",
		"kjv" : "in danger of, guilty of, subject to"
	},
	"G1166" : {
		"def" : "to show (literally or figuratively)",
		"derivation" : "a prolonged form of an obsolete primary of the same meaning",
		"lemma" : "δεικνύω",
		"translit" : "deiknýō",
		"kjv" : "shew"
	},
	"G5189" : {
		"derivation" : "from a derivative of G5188 (τύφω)",
		"def" : "stormy (as if smoky)",
		"kjv" : "tempestuous",
		"translit" : "typhōnikós",
		"lemma" : "τυφωνικός"
	},
	"G2397" : {
		"kjv" : "countenance",
		"lemma" : "ἰδέα",
		"translit" : "idéa",
		"derivation" : "from G1492 (εἴδω)",
		"def" : "a sight (comparative figuratively \"idea\"), i.e. aspect"
	},
	"G895" : {
		"lemma" : "ἄψυχος",
		"translit" : "ápsychos",
		"kjv" : "without life",
		"def" : "lifeless, i.e. inanimate (mechanical)",
		"derivation" : "from G1 (Α) (as a negative particle) and G5590 (ψυχή)"
	},
	"G1805" : {
		"kjv" : "redeem",
		"lemma" : "ἐξαγοράζω",
		"translit" : "exagorázō",
		"derivation" : "from G1537 (ἐκ) and G59 (ἀγοράζω)",
		"def" : "to buy up, i.e. ransom; figuratively, to rescue from loss (improve opportunity)"
	},
	"G1440" : {
		"kjv" : "seventy, three score and ten",
		"translit" : "hebdomḗkonta",
		"lemma" : "ἑβδομήκοντα",
		"derivation" : "from G1442 (ἕβδομος) and a modified form of G1176 (δέκα)",
		"def" : "seventy"
	},
	"G1241" : {
		"lemma" : "διαζώννυμι",
		"translit" : "diazṓnnymi",
		"kjv" : "gird",
		"def" : "to gird tightly",
		"derivation" : "from G1223 (διά) and G2224 (ζώννυμι)"
	},
	"G1949" : {
		"def" : "to seize (for help, injury, attainment, or any other purpose; literally or figuratively)",
		"derivation" : "middle voice from G1909 (ἐπί) and G2983 (λαμβάνω)",
		"lemma" : "ἐπιλαμβάνομαι",
		"translit" : "epilambánomai",
		"kjv" : "catch, lay hold (up-)on, take (by, hold of, on)"
	},
	"G2631" : {
		"derivation" : "from G2632 (κατακρίνω)",
		"def" : "an adverse sentence (the verdict)",
		"kjv" : "condemnation",
		"lemma" : "κατάκριμα",
		"translit" : "katákrima"
	},
	"G269" : {
		"kjv" : "not a brawler",
		"lemma" : "ἄμαχος",
		"translit" : "ámachos",
		"derivation" : "from G1 (Α) (as a negative particle) and G3163 (μάχη)",
		"def" : "peaceable"
	},
	"G2976" : {
		"def" : "Lazarus (i.e. Elazar), the name of two Israelites (one imaginary)",
		"derivation" : "probably of Hebrew origin (H499)",
		"translit" : "Lázaros",
		"lemma" : "Λάζαρος",
		"kjv" : "Lazarus"
	},
	"G1365" : {
		"kjv" : "doubt",
		"translit" : "distázō",
		"lemma" : "διστάζω",
		"derivation" : "from G1364 (δίς)",
		"def" : "properly, to duplicate, i.e. (mentally) to waver (in opinion)"
	},
	"G2918" : {
		"derivation" : "perhaps a prim word",
		"def" : "a lily",
		"kjv" : "lily",
		"lemma" : "κρίνον",
		"translit" : "krínon"
	},
	"G2753" : {
		"kjv" : "bid, (at, give) command(-ment)",
		"translit" : "keleúō",
		"lemma" : "κελεύω",
		"derivation" : "from a primary (to urge on)",
		"def" : "\"hail\"; to incite by word, i.e. order"
	},
	"G5317" : {
		"translit" : "Phálek",
		"lemma" : "Φάλεκ",
		"kjv" : "Phalec",
		"def" : "Phalek (i.e. Peleg), a patriarch",
		"derivation" : "of Hebrew origin (H6389)"
	},
	"G5381" : {
		"def" : "hospitableness",
		"derivation" : "from G5382 (φιλόξενος)",
		"translit" : "philonexía",
		"lemma" : "φιλονεξία",
		"kjv" : "entertain stranger, hospitality"
	},
	"G5427" : {
		"def" : "(mental) inclination or purpose",
		"derivation" : "from G5426 (φρονέω)",
		"lemma" : "φρόνημα",
		"translit" : "phrónēma",
		"kjv" : "(be, + be carnally, + be spiritually) mind(-ed)"
	},
	"G464" : {
		"def" : "to struggle against (figuratively) (\"antagonize\")",
		"derivation" : "from G473 (ἀντί) and G75 (ἀγωνίζομαι)",
		"translit" : "antagōnízomai",
		"lemma" : "ἀνταγωνίζομαι",
		"kjv" : "strive against"
	},
	"G4626" : {
		"def" : "to dig",
		"derivation" : "apparently a primary verb",
		"lemma" : "σκάπτω",
		"translit" : "skáptō",
		"kjv" : "dig"
	},
	"G4705" : {
		"def" : "prompt, energetic, earnest",
		"derivation" : "from G4710 (σπουδή)",
		"translit" : "spoudaîos",
		"lemma" : "σπουδαῖος",
		"kjv" : "diligent"
	},
	"G1674" : {
		"derivation" : "feminine of G1672 (Ἕλλην)",
		"def" : "a Grecian (i.e. non-Jewish) woman",
		"kjv" : "Greek",
		"translit" : "Hellēnís",
		"lemma" : "Ἑλληνίς"
	},
	"G4269" : {
		"derivation" : "from G4266 (προγίνομαι)",
		"def" : "an ancestor, (grand-)parent",
		"kjv" : "forefather, parent",
		"translit" : "prógonos",
		"lemma" : "πρόγονος"
	},
	"G458" : {
		"derivation" : "from G459 (ἄνομος)",
		"def" : "illegality, i.e. violation of law or (genitive case) wickedness",
		"kjv" : "iniquity, X transgress(-ion of) the law, unrighteousness",
		"translit" : "anomía",
		"lemma" : "ἀνομία"
	},
	"G393" : {
		"lemma" : "ἀνατέλλω",
		"translit" : "anatéllō",
		"kjv" : "(a-, make to) rise, at the rising of, spring (up), be up",
		"def" : "to (cause to) arise",
		"derivation" : "from G303 (ἀνά) and the base of G5056 (τέλος)"
	},
	"G3127" : {
		"def" : "Manaën, a Christian",
		"derivation" : "of uncertain origin",
		"lemma" : "Μαναήν",
		"translit" : "Manaḗn",
		"kjv" : "Manaen"
	},
	"G3358" : {
		"translit" : "métron",
		"lemma" : "μέτρον",
		"kjv" : "measure",
		"def" : "a measure (\"metre\"), literally or figuratively; by implication, a limited portion (degree)",
		"derivation" : "an apparently primary word"
	},
	"G5062" : {
		"def" : "forty",
		"derivation" : "the decade of G5064 (τέσσαρες)",
		"translit" : "tessarákonta",
		"lemma" : "τεσσαράκοντα",
		"kjv" : "forty"
	},
	"G2491" : {
		"derivation" : "of Hebrew origin (H3110)",
		"def" : "Joannes (i.e. Jochanan), the name of four Israelites",
		"kjv" : "John",
		"translit" : "Iōánnēs",
		"lemma" : "Ἰωάννης"
	},
	"G513" : {
		"derivation" : "probably from (to break",
		"def" : "compare G4486 (ῥήγνυμι)); an axe",
		"kjv" : "axe",
		"translit" : "axínē",
		"lemma" : "ἀξίνη"
	},
	"G785" : {
		"def" : "a buckler (or round shield); used of a serpent (as coiling itself), probably the \"asp\"",
		"derivation" : "of uncertain derivation",
		"lemma" : "ἀσπίς",
		"translit" : "aspís",
		"kjv" : "asp"
	},
	"G776" : {
		"translit" : "asitía",
		"lemma" : "ἀσιτία",
		"kjv" : "abstinence",
		"def" : "fasting (the state)",
		"derivation" : "from G777 (ἄσιτος)"
	},
	"G2435" : {
		"kjv" : "mercyseat, propitiation",
		"lemma" : "ἱλαστήριον",
		"translit" : "hilastḗrion",
		"derivation" : "neuter of a derivative of G2433 (ἱλάσκομαι)",
		"def" : "an expiatory (place or thing), i.e. (concretely) an atoning victim, or (specially) the lid of the Ark (in the Temple)"
	},
	"G2306" : {
		"derivation" : "from G2303 (θεῖον) and 1491",
		"def" : "sulphur-like, i.e. sulphurous",
		"kjv" : "brimstone",
		"lemma" : "θειώδης",
		"translit" : "theiṓdēs"
	},
	"G1560" : {
		"derivation" : "from G1537 (ἐκ) and a derivative of G1325 (δίδωμι)",
		"def" : "given out or over, i.e. surrendered",
		"kjv" : "delivered",
		"translit" : "ékdotos",
		"lemma" : "ἔκδοτος"
	},
	"G213" : {
		"translit" : "alazṓn",
		"lemma" : "ἀλαζών",
		"kjv" : "boaster",
		"def" : "braggart",
		"derivation" : "from (vagrancy)"
	},
	"G3430" : {
		"derivation" : "from G3431 (μοιχεύω)",
		"def" : "adultery",
		"kjv" : "adultery",
		"translit" : "moicheía",
		"lemma" : "μοιχεία"
	},
	"G1903" : {
		"derivation" : "from G1902 (ἐπενδύομαι)",
		"def" : "a wrapper, i.e. outer garment",
		"kjv" : "fisher's coat",
		"lemma" : "ἐπενδύτης",
		"translit" : "ependýtēs"
	},
	"G284" : {
		"def" : "Aminadab, an Israelite",
		"derivation" : "of Hebrew origin (H5992)",
		"translit" : "Aminadáb",
		"lemma" : "Ἀμιναδάβ",
		"kjv" : "Aminadab"
	},
	"G1910" : {
		"def" : "to walk upon, i.e. mount, ascend, embark, arrive",
		"derivation" : "from G1909 (ἐπί) and the base of G939 (βάσις)",
		"lemma" : "ἐπιβαίνω",
		"translit" : "epibaínō",
		"kjv" : "come (into), enter into, go abroad, sit upon, take ship"
	},
	"G4893" : {
		"derivation" : "from a prolonged form of G4894 (συνείδω)",
		"def" : "co-perception, i.e. moral consciousness",
		"kjv" : "conscience",
		"translit" : "syneídēsis",
		"lemma" : "συνείδησις"
	},
	"G4610" : {
		"kjv" : "Silvanus",
		"lemma" : "Σιλουανός",
		"translit" : "Silouanós",
		"derivation" : "of Latin origin",
		"def" : "\"silvan\"; Silvanus, a Christian"
	},
	"G622" : {
		"translit" : "apóllymi",
		"lemma" : "ἀπόλλυμι",
		"kjv" : "destroy, die, lose, mar, perish",
		"def" : "to destroy fully (reflexively, to perish, or lose), literally or figuratively",
		"derivation" : "from G575 (ἀπό) and the base of G3639 (ὄλεθρος)"
	},
	"G2561" : {
		"translit" : "kákōsis",
		"lemma" : "κάκωσις",
		"kjv" : "affliction",
		"def" : "maltreatment",
		"derivation" : "from G2559 (κακόω)"
	},
	"G3839" : {
		"derivation" : "adverb (of manner) from G3956 (πᾶς)",
		"def" : "wholly",
		"kjv" : "always",
		"translit" : "pántē",
		"lemma" : "πάντη"
	},
	"G2996" : {
		"kjv" : "Lasea",
		"lemma" : "Λασαία",
		"translit" : "Lasaía",
		"derivation" : "of uncertain origin",
		"def" : "Lasæa, a place in Crete"
	},
	"G3597" : {
		"lemma" : "ὁδοιπορία",
		"translit" : "hodoiporía",
		"kjv" : "journey(-ing)",
		"def" : "travel",
		"derivation" : "from the same as G3596 (ὁδοιπορέω)"
	},
	"G4987" : {
		"lemma" : "σωρεύω",
		"translit" : "sōreúō",
		"kjv" : "heap, load",
		"def" : "to pile up (literally or figuratively)",
		"derivation" : "from another form of G4673 (σορός)"
	},
	"G2319" : {
		"kjv" : "hater of God",
		"translit" : "theostygḗs",
		"lemma" : "θεοστυγής",
		"derivation" : "from G2316 (θεός) and the base of G4767 (στυγνητός)",
		"def" : "hateful to God, i.e. impious"
	},
	"G3984" : {
		"derivation" : "from the base of G4008 (πέραν) (through the idea of piercing)",
		"def" : "a test, i.e. attempt, experience",
		"kjv" : "assaying, trial",
		"translit" : "peîra",
		"lemma" : "πεῖρα"
	},
	"G3569" : {
		"kjv" : "(but) now",
		"lemma" : "τανῦν",
		"translit" : "tanŷn",
		"derivation" : "from neuter plural of G3588 (ὁ) and G3568 (νῦν)",
		"def" : "the things now, i.e. (adverbially) at present"
	},
	"G1814" : {
		"kjv" : "leap up",
		"lemma" : "ἐξάλλομαι",
		"translit" : "exállomai",
		"derivation" : "from G1537 (ἐκ) and G242 (ἅλλομαι)",
		"def" : "to spring forth "
	},
	"G4506" : {
		"kjv" : "deliver(-er)",
		"lemma" : "ῥύομαι",
		"translit" : "rhýomai",
		"derivation" : "middle voice of an obsolete verb, akin to G4482 (ῥέω) (through the idea of a current",
		"def" : "compare G4511 (ῥύσις)); to rush or draw (for oneself), i.e. rescue"
	},
	"G4813" : {
		"lemma" : "συλάω",
		"translit" : "syláō",
		"kjv" : "rob",
		"def" : "probably akin to G138 (αἱρέομαι); compare G4661 (σκῦλον)); to despoil",
		"derivation" : "from a derivative of (to strip"
	},
	"G3472" : {
		"derivation" : "from G3474 (μωρός)",
		"def" : "silliness, i.e. absurdity",
		"kjv" : "foolishness",
		"translit" : "mōría",
		"lemma" : "μωρία"
	},
	"G928" : {
		"translit" : "basanízō",
		"lemma" : "βασανίζω",
		"kjv" : "pain, toil, torment, toss, vex",
		"def" : "to torture",
		"derivation" : "from G931 (βάσανος)"
	},
	"G1854" : {
		"derivation" : "adverb from G1537 (ἐκ)",
		"def" : "out(-side, of doors), literally or figuratively",
		"kjv" : "away, forth, (with-)out (of, -ward), strange",
		"lemma" : "ἔξω",
		"translit" : "éxō"
	},
	"G1522" : {
		"lemma" : "εἰσακούω",
		"translit" : "eisakoúō",
		"kjv" : "hear",
		"def" : "to listen to",
		"derivation" : "from G1519 (εἰς) and G191 (ἀκούω)"
	},
	"G5004" : {
		"translit" : "talaipōría",
		"lemma" : "ταλαιπωρία",
		"kjv" : "misery",
		"def" : "wretchedness, i.e. calamity",
		"derivation" : "from G5005 (ταλαίπωρος)"
	},
	"G2250" : {
		"translit" : "hēméra",
		"lemma" : "ἡμέρα",
		"kjv" : "age, + alway, (mid-)day (by day, (-ly)), + for ever, judgment, (day) time, while, years",
		"def" : "day, i.e. (literally) the time space between dawn and dark, or the whole 24 hours (but several days were usually reckoned by the Jews as inclusive of the parts of both extremes); figuratively, a period (always defined more or less clearly by the context)",
		"derivation" : "feminine (with G5610 (ὥρα) implied) of a derivative of (to sit; akin to the base of G1476 (ἑδραῖος)) meaning tame, i.e. gentle"
	},
	"G1650" : {
		"derivation" : "from G1651 (ἐλέγχω)",
		"def" : "proof, conviction",
		"kjv" : "evidence, reproof",
		"lemma" : "ἔλεγχος",
		"translit" : "élenchos"
	},
	"G3333" : {
		"derivation" : "from G3326 (μετά) and G2564 (καλέω)",
		"def" : "to call elsewhere, i.e. summon",
		"kjv" : "call (for, hither)",
		"translit" : "metakaléō",
		"lemma" : "μετακαλέω"
	},
	"G2971" : {
		"def" : "a mosquito (from its stinging proboscis)",
		"derivation" : "apparently a derivative of the base of G2759 (κέντρον) and a derivative of G3700 (ὀπτάνομαι)",
		"translit" : "kṓnōps",
		"lemma" : "κώνωψ",
		"kjv" : "gnat"
	},
	"G5089" : {
		"derivation" : "perhaps akin to the alternate of G138 (αἱρέομαι), and thus to G4951 (σύρω)",
		"def" : "to pull off",
		"kjv" : "pluck",
		"translit" : "tíllō",
		"lemma" : "τίλλω"
	},
	"G2751" : {
		"lemma" : "κείρω",
		"translit" : "keírō",
		"kjv" : "shear(-er)",
		"def" : "to shear",
		"derivation" : "a primary verb"
	},
	"G4902" : {
		"kjv" : "accompany",
		"translit" : "synépomai",
		"lemma" : "συνέπομαι",
		"derivation" : "middle voice from G4862 (σύν) and a primary (to follow)",
		"def" : "to attend (travel) in company with"
	},
	"G5359" : {
		"translit" : "Philadélpheia",
		"lemma" : "Φιλαδέλφεια",
		"kjv" : "Philadelphia",
		"def" : "Philadelphia, a place in Asia Minor",
		"derivation" : "from (the same as G5361 (φιλάδελφος)), a king of Pergamos"
	},
	"G2997" : {
		"kjv" : "burst asunder",
		"translit" : "láschō",
		"lemma" : "λάσχω",
		"derivation" : "a strengthened form of a primary verb, which only occurs in this and another prolonged form as alternate in certain tenses",
		"def" : "to crack open (from a fall)"
	},
	"G1846" : {
		"derivation" : "from G1537 (ἐκ) and G3736 (ὀρύσσω)",
		"def" : "to dig out, i.e. (by extension) to extract (an eye), remove (roofing)",
		"kjv" : "break up, pluck out",
		"lemma" : "ἐξορύσσω",
		"translit" : "exorýssō"
	},
	"G3027" : {
		"kjv" : "robber, thief",
		"lemma" : "λῃστής",
		"translit" : "lēistḗs",
		"derivation" : "from (to plunder)",
		"def" : "a brigand"
	},
	"G2974" : {
		"kjv" : "deaf, dumb, speechless",
		"lemma" : "κωφός",
		"translit" : "kōphós",
		"derivation" : "from G2875 (κόπτω)",
		"def" : "blunted, i.e. (figuratively) of hearing (deaf) or speech (dumb)"
	},
	"G1850" : {
		"translit" : "exousiázō",
		"lemma" : "ἐξουσιάζω",
		"kjv" : "exercise authority upon, bring under the (have) power of",
		"def" : "to control",
		"derivation" : "from G1849 (ἐξουσία)"
	},
	"G1411" : {
		"def" : "force (literally or figuratively); specially, miraculous power (usually by implication, a miracle itself)",
		"derivation" : "from G1410 (δύναμαι)",
		"lemma" : "δύναμις",
		"translit" : "dýnamis",
		"kjv" : "ability, abundance, meaning, might(-ily, -y, -y deed), (worker of) miracle(-s), power, strength, violence, mighty (wonderful) work"
	},
	"G5323" : {
		"kjv" : "Phanuel",
		"translit" : "Phanouḗl",
		"lemma" : "Φανουήλ",
		"derivation" : "of Hebrew origin (H6439)",
		"def" : "Phanuel (i.e. Penuel), an Israelite"
	},
	"G703" : {
		"derivation" : "from the same as G730 (ἄῤῥην)",
		"def" : "properly, manliness (valor), i.e. excellence (intrinsic or attributed)",
		"kjv" : "praise, virtue",
		"lemma" : "ἀρέτη",
		"translit" : "arétē"
	},
	"G2711" : {
		"lemma" : "καταψύχω",
		"translit" : "katapsýchō",
		"kjv" : "cool",
		"def" : "to cool down (off), i.e. refresh",
		"derivation" : "from G2596 (κατά) and G5594 (ψύχω)"
	},
	"G766" : {
		"derivation" : "from a compound of G1 (Α) (as a negative particle) and a presumed (of uncertain derivation, but apparently meaning continent)",
		"def" : "licentiousness (sometimes including other vices)",
		"kjv" : "filthy, lasciviousness, wantonness",
		"lemma" : "ἀσέλγεια",
		"translit" : "asélgeia"
	},
	"G2832" : {
		"def" : "Clopas, an Israelite",
		"derivation" : "of Chaldee origin (corresponding to H256)",
		"translit" : "Klōpâs",
		"lemma" : "Κλωπᾶς",
		"kjv" : "Cleophas"
	},
	"G3387" : {
		"derivation" : "from G3361 (μή) and G5100 (τὶς)",
		"def" : "whether any",
		"kjv" : "any (sometimes unexpressed except by the simple interrogative form of the sentence)",
		"lemma" : "μήτις",
		"translit" : "mḗtis"
	},
	"G4707" : {
		"def" : "more prompt, more earnest",
		"derivation" : "comparative of G4705 (σπουδαῖος)",
		"lemma" : "σπουδαιότερος",
		"translit" : "spoudaióteros",
		"kjv" : "more diligent (forward)"
	},
	"G4851" : {
		"lemma" : "συμφέρω",
		"translit" : "symphérō",
		"kjv" : "be better for, bring together, be expedient (for), be good, (be) profit(-able for)",
		"def" : "to bear together (contribute), i.e. (literally) to collect, or (figuratively) to conduce; especially (neuter participle as a noun) advantage",
		"derivation" : "from G4862 (σύν) and G5342 (φέρω) (including its alternate)"
	},
	"G2233" : {
		"derivation" : "middle voice of a (presumed) strengthened form of G71 (ἄγω)",
		"def" : "to lead, i.e. command (with official authority); figuratively, to deem, i.e. consider",
		"kjv" : "account, (be) chief, count, esteem, governor, judge, have the rule over, suppose, think",
		"translit" : "hēgéomai",
		"lemma" : "ἡγέομαι"
	},
	"G2080" : {
		"def" : "inside (as preposition or adjective)",
		"derivation" : "from G1519 (εἰς)",
		"translit" : "ésō",
		"lemma" : "ἔσω",
		"kjv" : "(with-)in(-ner, -to, -ward)"
	},
	"G1038" : {
		"lemma" : "βυρσεύς",
		"translit" : "byrseús",
		"kjv" : "tanner",
		"def" : "a tanner",
		"derivation" : "from (a hide)"
	},
	"G3353" : {
		"derivation" : "from G3348 (μετέχω)",
		"def" : "participant, i.e. (as noun) a sharer; by implication, an associate",
		"kjv" : "fellow, partaker, partner",
		"translit" : "métochos",
		"lemma" : "μέτοχος"
	},
	"G5622" : {
		"derivation" : "from a derivative of the base of G5624 (ὠφέλιμος)",
		"def" : "usefulness, i.e. benefit",
		"kjv" : "advantage, profit",
		"lemma" : "ὠφέλεια",
		"translit" : "ōphéleia"
	},
	"G2144" : {
		"kjv" : "acceptable(-ted)",
		"translit" : "euprósdektos",
		"lemma" : "εὐπρόσδεκτος",
		"derivation" : "from G2095 (εὖ) and a derivative of G4327 (προσδέχομαι)",
		"def" : "well-received, i.e. approved, favorable"
	},
	"G3381" : {
		"kjv" : "lest (by any means, by some means, haply, perhaps)",
		"translit" : "mḗpōs",
		"lemma" : "μήπως",
		"derivation" : "from G3361 (μή) and G4458 (-πώς)",
		"def" : "lest somehow"
	},
	"G1841" : {
		"def" : "an exit, i.e. (figuratively) death",
		"derivation" : "from G1537 (ἐκ) and G3598 (ὁδός)",
		"translit" : "éxodos",
		"lemma" : "ἔξοδος",
		"kjv" : "decease, departing"
	},
	"G3426" : {
		"def" : "a modius, i.e. certain measure for things dry (the quantity or the utensil)",
		"derivation" : "of Latin origin",
		"lemma" : "μόδιος",
		"translit" : "módios",
		"kjv" : "bushel"
	},
	"G731" : {
		"translit" : "árrhētos",
		"lemma" : "ἄῤῥητος",
		"kjv" : "unspeakable",
		"def" : "unsaid, i.e. (by implication) inexpressible",
		"derivation" : "from G1 (Α) (as a negative particle) and the same as G4490 (ῥητῶς)"
	},
	"G1425" : {
		"derivation" : "from G1418 (δυσ-) and a derivative of G3539 (νοιέω)",
		"def" : "difficult of perception",
		"kjv" : "hard to be understood",
		"lemma" : "δυσνόητος",
		"translit" : "dysnóētos"
	},
	"G4857" : {
		"lemma" : "συμφώνησις",
		"translit" : "symphṓnēsis",
		"kjv" : "concord",
		"def" : "accordance",
		"derivation" : "from G4856 (συμφωνέω)"
	},
	"G1191" : {
		"def" : "Derbe, a place in Asia Minor",
		"derivation" : "of foreign origin",
		"translit" : "Dérbē",
		"lemma" : "Δέρβη",
		"kjv" : "Derbe"
	},
	"G3022" : {
		"kjv" : "white",
		"lemma" : "λευκός",
		"translit" : "leukós",
		"derivation" : "from (\"light\")",
		"def" : "white"
	},
	"G1540" : {
		"derivation" : "of uncertain affinity",
		"def" : "a hundred",
		"kjv" : "hundred",
		"lemma" : "ἑκατόν",
		"translit" : "hekatón"
	},
	"G3749" : {
		"def" : "earthen-ware, i.e. clayey; by implication, frail",
		"derivation" : "from (\"oyster\") (a tile, i.e. terra cotta)",
		"translit" : "ostrákinos",
		"lemma" : "ὀστράκινος",
		"kjv" : "of earth, earthen"
	},
	"G5365" : {
		"derivation" : "from G5366 (φιλάργυρος)",
		"def" : "avarice",
		"kjv" : "love of money",
		"translit" : "philargyría",
		"lemma" : "φιλαργυρία"
	},
	"G3493" : {
		"kjv" : "Nachor",
		"translit" : "Nachṓr",
		"lemma" : "Ναχώρ",
		"derivation" : "of Hebrew origin (H5152)",
		"def" : "Nachor, the grandfather of Abraham"
	},
	"G233" : {
		"translit" : "halízō",
		"lemma" : "ἁλίζω",
		"kjv" : "salt",
		"def" : "to salt",
		"derivation" : "from G251 (ἅλς)"
	},
	"G4613" : {
		"def" : "Simon (i.e. Shimon), the name of nine Israelites",
		"derivation" : "of Hebrew origin (H8095)",
		"translit" : "Símōn",
		"lemma" : "Σίμων",
		"kjv" : "Simon"
	},
	"G1102" : {
		"derivation" : "by variation for a derivative from (to tease cloth)",
		"def" : "a cloth-dresser",
		"kjv" : "fuller",
		"lemma" : "γναφεύς",
		"translit" : "gnapheús"
	},
	"G1064" : {
		"kjv" : "belly, + with child, womb",
		"lemma" : "γαστήρ",
		"translit" : "gastḗr",
		"derivation" : "of uncertain derivation",
		"def" : "the stomach; by analogy, the matrix; figuratively, a gourmand"
	},
	"G4316" : {
		"kjv" : "call",
		"translit" : "prosagoreúō",
		"lemma" : "προσαγορεύω",
		"derivation" : "from G4314 (πρός) and a derivative of G58 (ἀγορά) (mean to harangue)",
		"def" : "to address, i.e. salute by name"
	},
	"G1667" : {
		"derivation" : "a form of G1507 (εἱλίσσω)",
		"def" : "to coil or wrap",
		"kjv" : "fold up",
		"lemma" : "ἑλίσσω",
		"translit" : "helíssō"
	},
	"G682" : {
		"derivation" : "probably of foreign origin",
		"def" : "Apphia, a woman of Collosæ",
		"kjv" : "Apphia",
		"lemma" : "Ἀπφία",
		"translit" : "Apphía"
	},
	"G5367" : {
		"kjv" : "lover of own self",
		"lemma" : "φίλαυτος",
		"translit" : "phílautos",
		"derivation" : "from G5384 (φίλος) and G846 (αὐτός)",
		"def" : "fond of self, i.e. selfish"
	},
	"G5448" : {
		"def" : "to inflate, i.e. (figuratively) make proud (haughty)",
		"derivation" : "from G5449 (φύσις) in the primary sense of blowing",
		"lemma" : "φυσιόω",
		"translit" : "physióō",
		"kjv" : "puff up"
	},
	"G1000" : {
		"def" : "a throw (as a measure of distance)",
		"derivation" : "from G906 (βάλλω)",
		"lemma" : "βολή",
		"translit" : "bolḗ",
		"kjv" : "cast"
	},
	"G325" : {
		"kjv" : "deliver",
		"translit" : "anadídōmi",
		"lemma" : "ἀναδίδωμι",
		"derivation" : "from G303 (ἀνά) and G1325 (δίδωμι)",
		"def" : "to hand over"
	},
	"G2313" : {
		"def" : "to resist deity",
		"derivation" : "from G2314 (θεομάχος)",
		"lemma" : "θεομαχέω",
		"translit" : "theomachéō",
		"kjv" : "fight against God"
	},
	"G2221" : {
		"kjv" : "take captive, catch",
		"lemma" : "ζωγρέω",
		"translit" : "zōgréō",
		"derivation" : "from the same as G2226 (ζῶον) and G64 (ἀγρεύω)",
		"def" : "to take alive (make a prisoner of war), i.e. (figuratively) to capture or ensnare"
	},
	"G5223" : {
		"kjv" : "goods, substance",
		"lemma" : "ὕπαρξις",
		"translit" : "hýparxis",
		"derivation" : "from G5225 (ὑπάρχω)",
		"def" : "existency or proprietorship, i.e. (concretely) property, wealth"
	},
	"G1990" : {
		"kjv" : "endued with knowledge",
		"translit" : "epistḗmōn",
		"lemma" : "ἐπιστήμων",
		"derivation" : "from G1987 (ἐπίσταμαι)",
		"def" : "intelligent"
	},
	"G4554" : {
		"def" : "Sardis, a place in Asia Minor",
		"derivation" : "plural of uncertain derivation",
		"lemma" : "Σάρδεις",
		"translit" : "Sárdeis",
		"kjv" : "Sardis"
	},
	"G1412" : {
		"kjv" : "strengthen",
		"lemma" : "δυναμόω",
		"translit" : "dynamóō",
		"derivation" : "from G1411 (δύναμις)",
		"def" : "to enable"
	},
	"G1530" : {
		"def" : "to rush in",
		"derivation" : "from G1519 (εἰς) and (to leap)",
		"translit" : "eispēdáō",
		"lemma" : "εἰσπηδάω",
		"kjv" : "run (spring) in"
	},
	"G743" : {
		"translit" : "archángelos",
		"lemma" : "ἀρχάγγελος",
		"kjv" : "archangel",
		"def" : "a chief angel",
		"derivation" : "from G757 (ἄρχω) and G32 (ἄγγελος)"
	},
	"G2442" : {
		"kjv" : "be affectionately desirous",
		"translit" : "himeíromai",
		"lemma" : "ἱμείρομαι",
		"derivation" : "middle voice from (a yearning",
		"def" : "of uncertain affinity); to long for"
	},
	"G5537" : {
		"translit" : "chrēmatízō",
		"lemma" : "χρηματίζω",
		"kjv" : "be called, be admonished (warned) of God, reveal, speak",
		"def" : "to utter an oracle (compare the original sense of G5530 (χράομαι)), i.e. divinely intimate; by implication, (compare the secular sense of G5532 (χρεία)) to constitute a firm for business, i.e. (generally) bear as a title",
		"derivation" : "from G5536 (χρῆμα)"
	},
	"G1803" : {
		"translit" : "héx",
		"lemma" : "ἕξ",
		"kjv" : "six",
		"def" : "six",
		"derivation" : "a primary numeral"
	},
	"G3769" : {
		"kjv" : "tail",
		"lemma" : "οὐρά",
		"translit" : "ourá",
		"derivation" : "apparently a primary word",
		"def" : "a tail"
	},
	"G2511" : {
		"kjv" : "(make) clean(-se), purge, purify",
		"translit" : "katharízō",
		"lemma" : "καθαρίζω",
		"derivation" : "from G2513 (καθαρός)",
		"def" : "to cleanse (literally or figuratively)"
	},
	"G1562" : {
		"kjv" : "strip, take off from, unclothe",
		"translit" : "ekdýō",
		"lemma" : "ἐκδύω",
		"derivation" : "from G1537 (ἐκ) and the base of G1416 (δύνω)",
		"def" : "to cause to sink out of, i.e. (specially as of clothing) to divest"
	},
	"G2240" : {
		"kjv" : "come",
		"translit" : "hḗkō",
		"lemma" : "ἥκω",
		"derivation" : "a primary verb",
		"def" : "to arrive, i.e. be present (literally or figuratively)"
	},
	"G520" : {
		"derivation" : "from G575 (ἀπό) and G71 (ἄγω)",
		"def" : "to take off (in various senses)",
		"kjv" : "bring, carry away, lead (away), put to death, take away",
		"translit" : "apágō",
		"lemma" : "ἀπάγω"
	},
	"G428" : {
		"def" : "not well set, i.e. inconvenient",
		"derivation" : "from G1 (Α) (as a negative particle) and G2111 (εὔθετος)",
		"lemma" : "ἀνεύθετος",
		"translit" : "aneúthetos",
		"kjv" : "not commodious"
	},
	"G4608" : {
		"def" : "an intoxicant, i.e. intensely fermented liquor",
		"derivation" : "of Hebrew origin (H7941)",
		"translit" : "síkera",
		"lemma" : "σίκερα",
		"kjv" : "strong drink"
	},
	"G2818" : {
		"kjv" : "heir",
		"translit" : "klēronómos",
		"lemma" : "κληρονόμος",
		"derivation" : "from G2819 (κλῆρος) and the base of G3551 (νόμος) (in its original sense of partitioning, i.e. (reflexively) getting by apportionment)",
		"def" : "a sharer by lot, i.e. inheritor (literally or figuratively); by implication, a possessor"
	},
	"G2905" : {
		"derivation" : "from G2906 (κραυγή)",
		"def" : "to clamor",
		"kjv" : "cry out",
		"translit" : "kraugázō",
		"lemma" : "κραυγάζω"
	},
	"G4327" : {
		"kjv" : "accept, allow, look (wait) for, take",
		"lemma" : "προσδέχομαι",
		"translit" : "prosdéchomai",
		"derivation" : "from G4314 (πρός) and G1209 (δέχομαι)",
		"def" : "to admit (to intercourse, hospitality, credence, or (figuratively) endurance); by implication, to await (with confidence or patience)"
	},
	"G2174" : {
		"derivation" : "from a compound of G2095 (εὖ) and G5590 (ψυχή)",
		"def" : "to be in good spirits, i.e. feel encouraged",
		"kjv" : "be of good comfort",
		"lemma" : "εὐψυχέω",
		"translit" : "eupsychéō"
	},
	"G3632" : {
		"def" : "an overflow (or surplus) of wine, i.e. vinolency (drunkenness)",
		"derivation" : "from G3631 (οἶνος) and a form of the base of G5397 (φλύαρος)",
		"translit" : "oinophlygía",
		"lemma" : "οἰνοφλυγία",
		"kjv" : "excess of wine"
	},
	"G4015" : {
		"def" : "to flash all around, i.e. envelop in light",
		"derivation" : "from G4012 (περί) and G797 (ἀστράπτω)",
		"translit" : "periastráptō",
		"lemma" : "περιαστράπτω",
		"kjv" : "shine round (about)"
	},
	"G2392" : {
		"kjv" : "cure, heal(-ing)",
		"translit" : "íasis",
		"lemma" : "ἴασις",
		"derivation" : "from G2390 (ἰάομαι)",
		"def" : "curing (the act)"
	},
	"G2490" : {
		"derivation" : "a form of G2491 (Ἰωάννης)",
		"def" : "Joannas, an Israelite",
		"kjv" : "Joannas",
		"translit" : "Iōannâs",
		"lemma" : "Ἰωαννᾶς"
	},
	"G4351" : {
		"kjv" : "roll (to)",
		"translit" : "proskylíō",
		"lemma" : "προσκυλίω",
		"derivation" : "from G4314 (πρός) and G2947 (κυλιόω)",
		"def" : "to roll towards, i.e. block against"
	},
	"G2552" : {
		"translit" : "kakopátheia",
		"lemma" : "κακοπάθεια",
		"kjv" : "suffering affliction",
		"def" : "hardship",
		"derivation" : "from a compound of G2256 (ἡμιώριον) and G3806 (πάθος)"
	},
	"G4623" : {
		"def" : "properly, muteness, i.e. involuntary stillness, or inability to speak; and thus differing from G4602 (σιγή), which is rather a voluntary refusal or indisposition to speak, although the terms are often used synonymously); to be dumb (but not deaf also, like 2974 properly); figuratively, to be calm (as quiet water)",
		"derivation" : "from (silence, i.e. a hush",
		"lemma" : "σιωπάω",
		"translit" : "siōpáō",
		"kjv" : "dumb, (hold) peace"
	},
	"G4985" : {
		"def" : "corporeally or physically",
		"derivation" : "adverb from G4984 (σωματικός)",
		"lemma" : "σωματικῶς",
		"translit" : "sōmatikōs",
		"kjv" : "bodily"
	},
	"G5597" : {
		"derivation" : "prolongation from the same base as G5567 (ψάλλω)",
		"def" : "to triturate, i.e. (by analogy) to rub out (kernels from husks with the fingers or hand)",
		"kjv" : "rub",
		"translit" : "psṓchō",
		"lemma" : "ψώχω"
	},
	"G997" : {
		"derivation" : "from G998 (βοηθός)",
		"def" : "to aid or relieve",
		"kjv" : "help, succor",
		"lemma" : "βοηθέω",
		"translit" : "boēthéō"
	},
	"G5165" : {
		"kjv" : "dish",
		"translit" : "trýblion",
		"lemma" : "τρύβλιον",
		"derivation" : "neuter of a presumed derivative of uncertain affinity",
		"def" : "a bowl"
	},
	"G557" : {
		"kjv" : "nought",
		"lemma" : "ἀπελεγμός",
		"translit" : "apelegmós",
		"derivation" : "from a compound of G575 (ἀπό) and G1651 (ἐλέγχω)",
		"def" : "refutation, i.e. (by implication) contempt"
	},
	"G4793" : {
		"def" : "to judge of one thing in connection with another, i.e. combine (spiritual ideas with appropriate expressions) or collate (one person with another by way of contrast or resemblance)",
		"derivation" : "from G4862 (σύν) and G2919 (κρίνω)",
		"lemma" : "συγκρίνω",
		"translit" : "synkrínō",
		"kjv" : "compare among (with)"
	},
	"G3103" : {
		"def" : "Mathusala (i.e. Methushelach), an antediluvian",
		"derivation" : "of Hebrew origin (H4968)",
		"lemma" : "Μαθουσάλα",
		"translit" : "Mathousála",
		"kjv" : "Mathusala"
	},
	"G3451" : {
		"def" : "\"musical\", i.e. (as noun) a minstrel",
		"derivation" : "from (a Muse)",
		"lemma" : "μουσικός",
		"translit" : "mousikós",
		"kjv" : "musician"
	},
	"G2359" : {
		"def" : "of uncertain derivation; hair",
		"derivation" : "genitive case , etc.",
		"translit" : "thríx",
		"lemma" : "θρίξ",
		"kjv" : "hair"
	},
	"G4103" : {
		"def" : "objectively, trustworthy; subjectively, trustful",
		"derivation" : "from G3982 (πείθω)",
		"translit" : "pistós",
		"lemma" : "πιστός",
		"kjv" : "believe(-ing, -r), faithful(-ly), sure, true"
	},
	"G2326" : {
		"kjv" : "harvest",
		"lemma" : "θερισμός",
		"translit" : "therismós",
		"derivation" : "from G2325 (θερίζω)",
		"def" : "reaping, i.e. the crop"
	},
	"G3156" : {
		"derivation" : "a shorter form of G3164 (μάχομαι)",
		"def" : "Matthæus (i.e. Matthitjah), an Israelite and a Christian",
		"kjv" : "Matthew",
		"lemma" : "Ματθαῖος",
		"translit" : "Matthaîos"
	},
	"G887" : {
		"def" : "dimness of sight, i.e. (probably) a cataract",
		"derivation" : "of uncertain derivation",
		"translit" : "achlýs",
		"lemma" : "ἀχλύς",
		"kjv" : "mist"
	},
	"G1965" : {
		"derivation" : "from G1909 (ἐπί) and G3727 (ὅρκος)",
		"def" : "on oath, i.e. (falsely) a forswearer",
		"kjv" : "perjured person",
		"lemma" : "ἐπίορκος",
		"translit" : "epíorkos"
	},
	"G5160" : {
		"derivation" : "from G5142 (τρέφω)",
		"def" : "nourishment (literally or figuratively); by implication, rations (wages)",
		"kjv" : "food, meat",
		"lemma" : "τροφή",
		"translit" : "trophḗ"
	},
	"G4935" : {
		"translit" : "syntómōs",
		"lemma" : "συντόμως",
		"kjv" : "a few words",
		"def" : "concisely (briefly)",
		"derivation" : "adverb from a derivative of G4932 (συντέμνω)"
	},
	"G4130" : {
		"lemma" : "πλήθω",
		"translit" : "plḗthō",
		"kjv" : "accomplish, full (…come), furnish",
		"def" : "to \"fill\" (literally or figuratively (imbue, influence, supply)); specially, to fulfil (time)",
		"derivation" : "a prolonged form of a primary (which appears only as an alternate in certain tenses and in the reduplicated form pimplemi)"
	},
	"G542" : {
		"lemma" : "ἀπείδω",
		"translit" : "apeídō",
		"kjv" : "see",
		"def" : "to see fully",
		"derivation" : "from G575 (ἀπό) and the same as G1492 (εἴδω)"
	},
	"G99" : {
		"derivation" : "from (a place near its shore)",
		"def" : "the Adriatic sea (including the Ionian)",
		"kjv" : "Adria",
		"lemma" : "Ἀδρίας",
		"translit" : "Adrías"
	},
	"G5373" : {
		"kjv" : "friendship",
		"translit" : "philía",
		"lemma" : "φιλία",
		"derivation" : "from G5384 (φίλος)",
		"def" : "fondness"
	},
	"G4299" : {
		"translit" : "prókrima",
		"lemma" : "πρόκριμα",
		"kjv" : "prefer one before another",
		"def" : "a prejudgment (prejudice), i.e. prepossession",
		"derivation" : "from a compound of G4253 (πρό) and G2919 (κρίνω)"
	},
	"G2841" : {
		"def" : "to share with others (objectively or subjectively)",
		"derivation" : "from G2844 (κοινωνός)",
		"translit" : "koinōnéō",
		"lemma" : "κοινωνέω",
		"kjv" : "communicate, distribute, be partaker"
	},
	"G1042" : {
		"lemma" : "γαββαθά",
		"translit" : "gabbathá",
		"kjv" : "Gabbatha",
		"def" : "the knoll; gabbatha, a vernacular term for the Roman tribunal in Jerusalem",
		"derivation" : "of Chaldee origin (compare H1355)"
	},
	"G3797" : {
		"derivation" : "from G3796 (ὀψέ)",
		"def" : "later, i.e. vernal (showering)",
		"kjv" : "latter",
		"lemma" : "ὄψιμος",
		"translit" : "ópsimos"
	},
	"G3725" : {
		"derivation" : "neuter of a derivative of an apparently primary (a bound or limit)",
		"def" : "a boundary-line, i.e. (by implication) a frontier (region)",
		"kjv" : "border, coast",
		"lemma" : "ὅριον",
		"translit" : "hórion"
	},
	"G1069" : {
		"def" : "a neighbour (as adjoining one's ground); by implication, a friend",
		"derivation" : "from G1093 (γῆ)",
		"lemma" : "γείτων",
		"translit" : "geítōn",
		"kjv" : "neighbour"
	},
	"G125" : {
		"kjv" : "Egypt",
		"lemma" : "Αἴγυπτος",
		"translit" : "Aígyptos",
		"derivation" : "of uncertain derivation",
		"def" : "Ægyptus, the land of the Nile"
	},
	"G4312" : {
		"kjv" : "heady, rash(-ly)",
		"lemma" : "προπετής",
		"translit" : "propetḗs",
		"derivation" : "from a compound of G4253 (πρό) and G4098 (πίπτω)",
		"def" : "falling forward, i.e. headlong (figuratively, precipitate)"
	},
	"G5158" : {
		"kjv" : "(even) as, conversation, (+ like) manner, (+ by any) means, way",
		"translit" : "trópos",
		"lemma" : "τρόπος",
		"derivation" : "from the same as G5157 (τροπή)",
		"def" : "a turn, i.e. (by implication) mode or style (especially with preposition or relative prefix as adverb, like); figuratively, deportment or character"
	},
	"G1624" : {
		"derivation" : "from G1537 (ἐκ) and the base of G5157 (τροπή)",
		"def" : "to deflect, i.e. turn away (literally or figuratively)",
		"kjv" : "avoid, turn (aside, out of the way)",
		"translit" : "ektrépō",
		"lemma" : "ἐκτρέπω"
	},
	"G3541" : {
		"derivation" : "of uncertain affinity",
		"def" : "a spurious or illegitimate son",
		"kjv" : "bastard",
		"lemma" : "νόθος",
		"translit" : "nóthos"
	},
	"G838" : {
		"derivation" : "from G837 (αὐξάνω)",
		"def" : "growth",
		"kjv" : "increase",
		"translit" : "aúxēsis",
		"lemma" : "αὔξησις"
	},
	"G3873" : {
		"translit" : "parákeimai",
		"lemma" : "παράκειμαι",
		"kjv" : "be present",
		"def" : "to lie near, i.e. be at hand (figuratively, be prompt or easy)",
		"derivation" : "from G3844 (παρά) and G2749 (κεῖμαι)"
	},
	"G2373" : {
		"derivation" : "from G2372 (θυμός)",
		"def" : "to put in a passion, i.e. enrage",
		"kjv" : "be wroth",
		"translit" : "thymóō",
		"lemma" : "θυμόω"
	},
	"G4186" : {
		"kjv" : "very costly, of great price",
		"lemma" : "πολύτιμος",
		"translit" : "polýtimos",
		"derivation" : "from G4183 (πολύς) and G5092 (τιμή)",
		"def" : "extremely valuable"
	},
	"G1783" : {
		"derivation" : "from G1793 (ἐντυγχάνω)",
		"def" : "an interview, i.e. (specially) supplication",
		"kjv" : "intercession, prayer",
		"translit" : "énteuxis",
		"lemma" : "ἔντευξις"
	},
	"G3183" : {
		"def" : "tipsy, i.e. (as noun) a sot",
		"derivation" : "from G3184 (μεθύω)",
		"translit" : "méthysos",
		"lemma" : "μέθυσος",
		"kjv" : "drunkard"
	},
	"G1141" : {
		"derivation" : "from G1140 (δαιμόνιον) and G1142 (δαίμων)",
		"def" : "dæmon-like",
		"kjv" : "devilish",
		"lemma" : "δαιμονιώδης",
		"translit" : "daimoniṓdēs"
	},
	"G846" : {
		"derivation" : "from the particle (perhaps akin to the base of G109 (ἀήρ) through the idea of a baffling wind) (backward)",
		"def" : "the reflexive pronoun self, used (alone or in the comparative G1438 (ἑαυτοῦ)) of the third person , and (with the proper personal pronoun) of the other persons",
		"kjv" : "her, it(-self), one, the other, (mine) own, said, (self-), the) same, ((him-, my-, thy- )self, (your-)selves, she, that, their(-s), them(-selves), there(-at, - by, -in, -into, -of, -on, -with), they, (these) things, this (man), those, together, very, which",
		"lemma" : "αὐτός",
		"translit" : "autós"
	},
	"G1291" : {
		"translit" : "diastéllomai",
		"lemma" : "διαστέλλομαι",
		"kjv" : "charge, that which was (give) commanded(-ment)",
		"def" : "to set (oneself) apart (figuratively, distinguish), i.e. (by implication) to enjoin",
		"derivation" : "middle voice from G1223 (διά) and G4724 (στέλλω)"
	},
	"G798" : {
		"derivation" : "neuter from G792 (ἀστήρ)",
		"def" : "properly, a constellation; put for a single star (natural or artificial)",
		"kjv" : "star",
		"lemma" : "ἄστρον",
		"translit" : "ástron"
	},
	"G5422" : {
		"def" : "to be a mind-misleader, i.e. delude",
		"derivation" : "from G5423 (φρεναπάτης)",
		"lemma" : "φρεναπατάω",
		"translit" : "phrenapatáō",
		"kjv" : "deceive"
	},
	"G5469" : {
		"kjv" : "bit, bridle",
		"translit" : "chalinós",
		"lemma" : "χαλινός",
		"derivation" : "from G5465 (χαλάω)",
		"def" : "a curb or head-stall (as curbing the spirit)"
	},
	"G317" : {
		"lemma" : "ἀναγκαστῶς",
		"translit" : "anankastōs",
		"kjv" : "by constraint",
		"def" : "compulsorily",
		"derivation" : "adverb from a derivative of G315 (ἀναγκάζω)"
	},
	"G3015" : {
		"def" : "scaly, i.e. leprous (a leper)",
		"derivation" : "from the same as G3014 (λέπρα)",
		"translit" : "leprós",
		"lemma" : "λεπρός",
		"kjv" : "leper"
	},
	"G3192" : {
		"translit" : "méli",
		"lemma" : "μέλι",
		"kjv" : "honey",
		"def" : "honey",
		"derivation" : "apparently a primary word"
	},
	"G295" : {
		"derivation" : "from the base of G297 (ἀμφότερος) and G4172 (πόλις)",
		"def" : "a city surrounded by a river; Amphipolis, a place in Macedonia",
		"kjv" : "Amphipolis",
		"translit" : "Amphípolis",
		"lemma" : "Ἀμφίπολις"
	},
	"G3081" : {
		"translit" : "lysiteleî",
		"lemma" : "λυσιτελεῖ",
		"kjv" : "it is better",
		"def" : "impersonally, it answers the purpose, i.e. is advantageous",
		"derivation" : "third person singular present indicative active of a derivative of a compound of G3080 (λύσις) and G5056 (τέλος)"
	},
	"G2024" : {
		"lemma" : "ἐπιχορηγία",
		"translit" : "epichorēgía",
		"kjv" : "supply",
		"def" : "contribution",
		"derivation" : "from G2023 (ἐπιχορηγέω)"
	},
	"G3689" : {
		"derivation" : "adverb of the oblique cases of G5607 (ὤν)",
		"def" : "really",
		"kjv" : "certainly, clean, indeed, of a truth, verily",
		"lemma" : "ὄντως",
		"translit" : "óntōs"
	},
	"G4253" : {
		"def" : "\"fore\", i.e. in front of, prior (figuratively, superior) to",
		"derivation" : "a primary preposition",
		"translit" : "pró",
		"lemma" : "πρό",
		"kjv" : "above, ago, before, or ever"
	},
	"G3608" : {
		"def" : "a linen bandage",
		"derivation" : "neuter of a presumed derivative of G3607 (ὀθόνη)",
		"lemma" : "ὀθόνιον",
		"translit" : "othónion",
		"kjv" : "linen clothes"
	},
	"G421" : {
		"def" : "not tracked out, i.e. (by implication) untraceable",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of a compound of G1537 (ἐκ) and a derivative of G2487 (ἴχνος)",
		"lemma" : "ἀνεξιχνίαστος",
		"translit" : "anexichníastos",
		"kjv" : "past finding out; unsearchable"
	},
	"G1288" : {
		"kjv" : "pluck asunder, pull in pieces",
		"translit" : "diaspáō",
		"lemma" : "διασπάω",
		"derivation" : "from G1223 (διά) and G4685 (σπάω)",
		"def" : "to draw apart, i.e. sever or dismember"
	},
	"G4352" : {
		"def" : "to fawn or crouch to, i.e. (literally or figuratively) prostrate oneself in homage (do reverence to, adore)",
		"derivation" : "from G4314 (πρός) and a probable derivative of G2965 (κύων) (meaning to kiss, like a dog licking his master's hand)",
		"translit" : "proskynéō",
		"lemma" : "προσκυνέω",
		"kjv" : "worship"
	},
	"G2422" : {
		"lemma" : "Ἰεφθάε",
		"translit" : "Iephtháe",
		"kjv" : "Jephthah",
		"def" : "Jephthaë (i.e. Jiphtach), an Israelite",
		"derivation" : "of Hebrew origin (H3316)"
	},
	"G2086" : {
		"derivation" : "from a compound of G2087 (ἕτερος) and G2218 (ζυγός)",
		"def" : "to yoke up differently, i.e. (figuratively) to associate discordantly",
		"kjv" : "unequally yoke together with",
		"translit" : "heterozygéō",
		"lemma" : "ἑτεροζυγέω"
	},
	"G3564" : {
		"kjv" : "Nymphas",
		"lemma" : "Νυμφᾶς",
		"translit" : "Nymphâs",
		"derivation" : "probably contracted for a compound of G3565 (νύμφη) and G1435 (δῶρον)",
		"def" : "nymph-given (i.e. -born); Nymphas, a Christian"
	},
	"G4080" : {
		"derivation" : "a quantitative form (the feminine) of the base of G4225 (πού)",
		"def" : "how much (as an indefinite), i.e. in size or (figuratively) dignity",
		"kjv" : "how great (large)",
		"translit" : "pēlíkos",
		"lemma" : "πηλίκος"
	},
	"G1882" : {
		"def" : "a straightening up again, i.e. (figuratively) rectification (reformation)",
		"derivation" : "from a compound of G1909 (ἐπί) and G461 (ἀνορθόω)",
		"translit" : "epanórthōsis",
		"lemma" : "ἐπανόρθωσις",
		"kjv" : "correction"
	},
	"G1112" : {
		"translit" : "gongysmós",
		"lemma" : "γογγυσμός",
		"kjv" : "grudging, murmuring",
		"def" : "a grumbling",
		"derivation" : "from G1111 (γογγύζω)"
	},
	"G1290" : {
		"translit" : "diasporá",
		"lemma" : "διασπορά",
		"kjv" : "(which are) scattered (abroad)",
		"def" : "dispersion, i.e. (specially and concretely) the (converted) Israelite resident in Gentile countries",
		"derivation" : "from G1289 (διασπείρω)"
	},
	"G2279" : {
		"translit" : "ēchos",
		"lemma" : "ἦχος",
		"kjv" : "fame, sound",
		"def" : "a loud or confused noise (\"echo\"), i.e. roar; figuratively, a rumor",
		"derivation" : "of uncertain affinity"
	},
	"G1348" : {
		"derivation" : "from a derivative of G1349 (δίκη)",
		"def" : "a judger",
		"kjv" : "judge",
		"lemma" : "δικαστής",
		"translit" : "dikastḗs"
	},
	"G845" : {
		"derivation" : "from G846 (αὐτός) and G3700 (ὀπτάνομαι)",
		"def" : "self-seeing, i.e. an eye-witness",
		"kjv" : "eye-witness",
		"lemma" : "αὐτόπτης",
		"translit" : "autóptēs"
	},
	"G2510" : {
		"derivation" : "from G2596 (κατά) and G680 (ἅπτομαι)",
		"def" : "to seize upon",
		"kjv" : "fasten on",
		"lemma" : "καθάπτω",
		"translit" : "katháptō"
	},
	"G5520" : {
		"kjv" : "be angry",
		"translit" : "choláō",
		"lemma" : "χολάω",
		"derivation" : "from G5521 (χολή)",
		"def" : "to be bilious, i.e. (by implication) irritable (enraged, \"choleric\")"
	},
	"G2342" : {
		"translit" : "thēríon",
		"lemma" : "θηρίον",
		"kjv" : "(venomous, wild) beast",
		"def" : "a dangerous animal",
		"derivation" : "diminutive from the same as G2339 (θήρα)"
	},
	"G3941" : {
		"def" : "having a home near, i.e. (as noun) a by-dweller (alien resident)",
		"derivation" : "from G3844 (παρά) and G3624 (οἶκος)",
		"translit" : "pároikos",
		"lemma" : "πάροικος",
		"kjv" : "foreigner, sojourn, stranger"
	},
	"G3729" : {
		"def" : "to start, spur or urge on, i.e. (reflexively) to dash or plunge",
		"derivation" : "from G3730 (ὁρμή)",
		"lemma" : "ὁρμάω",
		"translit" : "hormáō",
		"kjv" : "run (violently), rush"
	},
	"G2004" : {
		"def" : "to arrange upon, i.e. order",
		"derivation" : "from G1909 (ἐπί) and G5021 (τάσσω)",
		"lemma" : "ἐπιτάσσω",
		"translit" : "epitássō",
		"kjv" : "charge, command, injoin"
	},
	"G4932" : {
		"derivation" : "from G4862 (σύν) and the base of G5114 (τομώτερος)",
		"def" : "to contract by cutting, i.e. (figuratively) do concisely (speedily)",
		"kjv" : "(cut) short",
		"translit" : "syntémnō",
		"lemma" : "συντέμνω"
	},
	"G3708" : {
		"lemma" : "ὁράω",
		"translit" : "horáō",
		"kjv" : "behold, perceive, see, take heed",
		"def" : "by extension, to attend to; by Hebraism, to experience; passively, to appear",
		"derivation" : "properly, to stare at (compare G3700 (ὀπτάνομαι)), i.e. (by implication) to discern clearly (physically or mentally)"
	},
	"G486" : {
		"def" : "to rail in reply",
		"derivation" : "from G473 (ἀντί) and G3058 (λοιδορέω)",
		"translit" : "antiloidoréō",
		"lemma" : "ἀντιλοιδορέω",
		"kjv" : "revile again"
	},
	"G2970" : {
		"derivation" : "from G2749 (κεῖμαι)",
		"def" : "a carousal (as if letting loose)",
		"kjv" : "revelling, rioting",
		"lemma" : "κῶμος",
		"translit" : "kōmos"
	},
	"G1634" : {
		"translit" : "ekpsýchō",
		"lemma" : "ἐκψύχω",
		"kjv" : "give (yield) up the ghost",
		"def" : "to expire",
		"derivation" : "from G1537 (ἐκ) and G5594 (ψύχω)"
	},
	"G204" : {
		"def" : "belonging to the extreme corner",
		"derivation" : "from G206 (ἄκρον) and G1137 (γωνία)",
		"lemma" : "ἀκρογωνιαῖος",
		"translit" : "akrogōniaîos",
		"kjv" : "chief corner"
	},
	"G941" : {
		"lemma" : "βαστάζω",
		"translit" : "bastázō",
		"kjv" : "bear, carry, take up",
		"def" : "to lift, literally or figuratively (endure, declare, sustain, receive, etc.)",
		"derivation" : "perhaps remotely derived from the base of G939 (βάσις) (through the idea of removal)"
	},
	"G2975" : {
		"kjv" : "his lot be, cast lots, obtain",
		"lemma" : "λαγχάνω",
		"translit" : "lanchánō",
		"derivation" : "a prolonged form of a primary verb, which is only used as an alternate in certain tenses",
		"def" : "to lot, i.e. determine (by implication, receive) especially by lot"
	},
	"G2709" : {
		"kjv" : "under the earth",
		"translit" : "katachthónios",
		"lemma" : "καταχθόνιος",
		"derivation" : "from G2596 (κατά) and (the ground)",
		"def" : "subterranean, i.e. infernal (belonging to the world of departed spirits)"
	},
	"G1306" : {
		"translit" : "diaugázō",
		"lemma" : "διαυγάζω",
		"kjv" : "dawn",
		"def" : "to glimmer through, i.e. break (as day)",
		"derivation" : "from G1223 (διά) and G826 (αὐγάζω)"
	},
	"G2088" : {
		"kjv" : "otherwise",
		"lemma" : "ἑτέρως",
		"translit" : "hetérōs",
		"derivation" : "adverb from G2087 (ἕτερος)",
		"def" : "differently"
	},
	"G3559" : {
		"def" : "calling attention to, i.e. (by implication) mild rebuke or warning",
		"derivation" : "from G3563 (νοῦς) and a derivative of G5087 (τίθημι)",
		"lemma" : "νουθεσία",
		"translit" : "nouthesía",
		"kjv" : "admonition"
	},
	"G727" : {
		"kjv" : "extortion, ravening",
		"lemma" : "ἅρπαξ",
		"translit" : "hárpax",
		"derivation" : "from G726 (ἁρπάζω)",
		"def" : "rapacious"
	},
	"G2610" : {
		"def" : "to struggle against, i.e. (by implication) to overcome",
		"derivation" : "from G2596 (κατά) and G75 (ἀγωνίζομαι)",
		"lemma" : "καταγωνίζομαι",
		"translit" : "katagōnízomai",
		"kjv" : "subdue"
	},
	"G3159" : {
		"kjv" : "Matthias",
		"lemma" : "Ματθίας",
		"translit" : "Matthías",
		"derivation" : "apparently a shortened form of G3161 (Ματταθίας)",
		"def" : "Matthias (i.e. Mattithjah), an Israelite"
	},
	"G5396" : {
		"def" : "to be a babbler or trifler, i.e. (by implication) to berate idly or mischievously",
		"derivation" : "from G5397 (φλύαρος)",
		"lemma" : "φλυαρέω",
		"translit" : "phlyaréō",
		"kjv" : "prate against"
	},
	"G1662" : {
		"def" : "Eliakim, an Israelite",
		"derivation" : "of Hebrew origin (H471)",
		"lemma" : "Ἐλιακείμ",
		"translit" : "Eliakeím",
		"kjv" : "Eliakim"
	},
	"G4507" : {
		"derivation" : "from G4508 (ῥυπαρός)",
		"def" : "dirtiness (morally)",
		"kjv" : "turpitude",
		"lemma" : "ῥυπαρία",
		"translit" : "rhyparía"
	},
	"G834" : {
		"kjv" : "minstrel, piper",
		"lemma" : "αὐλητής",
		"translit" : "aulētḗs",
		"derivation" : "from G832 (αὐλέω)",
		"def" : "a flute-player"
	},
	"G976" : {
		"translit" : "bíblos",
		"lemma" : "βίβλος",
		"kjv" : "book",
		"derivation" : "properly, the inner bark of the papyrus plant, i.e. (by implication) a sheet or scroll of writing"
	},
	"G5315" : {
		"lemma" : "φάγω",
		"translit" : "phágō",
		"kjv" : "eat, meat",
		"def" : "to eat (literally or figuratively)",
		"derivation" : "a primary verb (used as an alternate of G2068 (ἐσθίω) in certain tenses)"
	},
	"G411" : {
		"def" : "not expounded in full, i.e. indescribable",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G1555 (ἐκδιηγέομαι)",
		"translit" : "anekdiḗgētos",
		"lemma" : "ἀνεκδιήγητος",
		"kjv" : "unspeakable"
	},
	"G2268" : {
		"translit" : "Hēsaḯas",
		"lemma" : "Ἡσαΐας",
		"kjv" : "Esaias",
		"def" : "Hesaias (i.e. Jeshajah), an Israelite",
		"derivation" : "of Hebrew origin (H3470)"
	},
	"G4343" : {
		"derivation" : "from G4342 (προσκαρτερέω)",
		"def" : "persistancy",
		"kjv" : "perseverance",
		"lemma" : "προσκαρτέρησις",
		"translit" : "proskartérēsis"
	},
	"G1131" : {
		"derivation" : "of uncertain affinity",
		"def" : "nude (absolute or relative, literal or figurative)",
		"kjv" : "naked",
		"translit" : "gymnós",
		"lemma" : "γυμνός"
	},
	"G2819" : {
		"derivation" : "probably from G2806 (κλάω) (through the idea of using bits of wood, etc., for the purpose",
		"def" : "a die (for drawing chances); by implication, a portion (as if so secured); by extension, an acquisition (especially a patrimony, figuratively)",
		"kjv" : "heritage, inheritance, lot, part",
		"lemma" : "κλῆρος",
		"translit" : "klēros"
	},
	"G4371" : {
		"def" : "something eaten in addition to bread, i.e. a relish (specially, fish; compare G3795 (ὀψάριον))",
		"derivation" : "neuter of a presumed derivative of a compound of G4314 (πρός) and G5315 (φάγω)",
		"translit" : "prosphágion",
		"lemma" : "προσφάγιον",
		"kjv" : "meat"
	},
	"G332" : {
		"kjv" : "(bind under a) curse, bind with an oath",
		"lemma" : "ἀναθεματίζω",
		"translit" : "anathematízō",
		"derivation" : "from G331 (ἀνάθεμα)",
		"def" : "to declare or vow under penalty of execration"
	},
	"G2294" : {
		"lemma" : "θάρσος",
		"translit" : "thársos",
		"kjv" : "courage",
		"def" : "boldness (subjectively)",
		"derivation" : "akin (by transposition) to (daring)"
	},
	"G108" : {
		"kjv" : "Azotus",
		"lemma" : "Ἄζωτος",
		"translit" : "Ázōtos",
		"derivation" : "of Hebrew origin (H795)",
		"def" : "Azotus (i.e. Ashdod), a place in Palestine"
	},
	"G945" : {
		"def" : "to stutter, i.e. (by implication) to prate tediously",
		"derivation" : "from (a proverbial stammerer) and G3056 (λόγος)",
		"translit" : "battologéō",
		"lemma" : "βαττολογέω",
		"kjv" : "use vain repetitions"
	},
	"G3602" : {
		"derivation" : "from a derivative of the base of G1416 (δύνω)",
		"def" : "moaning, i.e. lamentation",
		"kjv" : "mourning",
		"lemma" : "ὀδυρμός",
		"translit" : "odyrmós"
	},
	"G1263" : {
		"derivation" : "from G1223 (διά) and G3140 (μαρτυρέω)",
		"def" : "to attest or protest earnestly, or (by implication) hortatively",
		"kjv" : "charge, testify (unto), witness",
		"lemma" : "διαμαρτύρομαι",
		"translit" : "diamartýromai"
	},
	"G4466" : {
		"derivation" : "of Hebrew origin (H7466)",
		"def" : "Ragau (i.e. Reu), a patriarch",
		"kjv" : "Ragau",
		"lemma" : "Ῥαγαῦ",
		"translit" : "Rhagaû"
	},
	"G930" : {
		"translit" : "basanistḗs",
		"lemma" : "βασανιστής",
		"kjv" : "tormentor",
		"def" : "a torturer",
		"derivation" : "from G928 (βασανίζω)"
	},
	"G992" : {
		"translit" : "blētéos",
		"lemma" : "βλητέος",
		"kjv" : "must be put",
		"def" : "fit to be cast (i.e. applied)",
		"derivation" : "from G906 (βάλλω)"
	},
	"G5586" : {
		"def" : "a pebble (as worn smooth by handling), i.e. (by implication, of use as a counter or ballot) a verdict (of acquittal) or ticket (of admission); a vote",
		"derivation" : "from the same as G5584 (ψηλαφάω)",
		"lemma" : "ψῆφος",
		"translit" : "psēphos",
		"kjv" : "stone, voice"
	},
	"G1107" : {
		"derivation" : "from a derivative of G1097 (γινώσκω)",
		"def" : "to make known; subjectively, to know",
		"kjv" : "certify, declare, make known, give to understand, do to wit, wot",
		"lemma" : "γνωρίζω",
		"translit" : "gnōrízō"
	},
	"G2145" : {
		"kjv" : "X attend upon",
		"translit" : "euprósedros",
		"lemma" : "εὐπρόσεδρος",
		"derivation" : "from G2095 (εὖ) and the same as G4332 (προσεδρεύω)",
		"def" : "sitting well towards, i.e. (figuratively) assiduous (neuter, diligent service)"
	},
	"G4634" : {
		"def" : "the Festival of Tabernacles (so called from the custom of erecting booths for temporary homes)",
		"derivation" : "from G4636 (σκῆνος) and G4078 (πήγνυμι)",
		"lemma" : "σκηνοπηγία",
		"translit" : "skēnopēgía",
		"kjv" : "tabernacles"
	},
	"G5336" : {
		"derivation" : "from (to eat)",
		"def" : "a crib (for fodder)",
		"kjv" : "manager, stall",
		"lemma" : "φάτνη",
		"translit" : "phátnē"
	},
	"G2398" : {
		"derivation" : "of uncertain affinity",
		"def" : "pertaining to self, i.e. one's own; by implication, private or separate",
		"kjv" : "X his acquaintance, when they were alone, apart, aside, due, his (own, proper, several), home, (her, our, thine, your) own (business), private(-ly), proper, severally, their (own)",
		"lemma" : "ἴδιος",
		"translit" : "ídios"
	},
	"G4992" : {
		"def" : "defender or (by implication) defence",
		"derivation" : "neuter of the same as G4991 (σωτηρία) as (properly, concretely) noun",
		"translit" : "sōtḗrion",
		"lemma" : "σωτήριον",
		"kjv" : "salvation"
	},
	"G4541" : {
		"def" : "a Samarite, i.e. inhabitant of Samaria",
		"derivation" : "from G4540 (Σαμάρεια)",
		"translit" : "Samareítēs",
		"lemma" : "Σαμαρείτης",
		"kjv" : "Samaritan"
	},
	"G4036" : {
		"def" : "grieved all around, i.e. intensely sad",
		"derivation" : "from G4012 (περί) and G3077 (λύπη)",
		"translit" : "perílypos",
		"lemma" : "περίλυπος",
		"kjv" : "exceeding (very) sorry(-owful)"
	},
	"G4940" : {
		"translit" : "syntynchánō",
		"lemma" : "συντυγχάνω",
		"kjv" : "come at",
		"def" : "to chance together, i.e. meet with (reach)",
		"derivation" : "from G4862 (σύν) and G5177 (τυγχάνω)"
	},
	"G4028" : {
		"lemma" : "περικαλύπτω",
		"translit" : "perikalýptō",
		"kjv" : "blindfold, cover, overlay",
		"def" : "to cover all around, i.e. entirely (the face, a surface)",
		"derivation" : "from G4012 (περί) and G2572 (καλύπτω)"
	},
	"G3968" : {
		"def" : "a father-land, i.e. native town; (figuratively) heavenly home",
		"derivation" : "from G3902 (παράσημος)",
		"translit" : "patrís",
		"lemma" : "πατρίς",
		"kjv" : "(own) country"
	},
	"G89" : {
		"def" : "uninterruptedly, i.e. without omission (on an appropriate occasion)",
		"derivation" : "adverb from G88 (ἀδιάλειπτος)",
		"lemma" : "ἀδιαλείπτως",
		"translit" : "adialeíptōs",
		"kjv" : "without ceasing"
	},
	"G1936" : {
		"kjv" : "laying (putting) on",
		"translit" : "epíthesis",
		"lemma" : "ἐπίθεσις",
		"derivation" : "from G2007 (ἐπιτίθημι)",
		"def" : "an imposition (of hands officially)"
	},
	"G360" : {
		"lemma" : "ἀναλύω",
		"translit" : "analýō",
		"kjv" : "depart, return",
		"def" : "to break up, i.e. depart (literally or figuratively)",
		"derivation" : "from G303 (ἀνά) and G3089 (λύω)"
	},
	"G1490" : {
		"kjv" : "(or) else, if (not, otherwise), otherwise",
		"lemma" : "εἰ δὲ μή(γε)",
		"translit" : "ei dè mḗ(ge)",
		"derivation" : "from G1487 (εἰ), G1161 (δέ), and G3361 (μή) (sometimes with G1065 (γέ) added)",
		"def" : "but if not"
	},
	"G4982" : {
		"def" : "to save, i.e. deliver or protect (literally or figuratively)",
		"derivation" : "from a primary (contraction for obsolete , \"safe\")",
		"lemma" : "σώζω",
		"translit" : "sṓzō",
		"kjv" : "heal, preserve, save (self), do well, be (make) whole"
	},
	"G2626" : {
		"def" : "to dash (wash) down, i.e. (by implication) to deluge",
		"derivation" : "from G2596 (κατά) and the base of G2830 (κλύδων)",
		"lemma" : "κατακλύζω",
		"translit" : "kataklýzō",
		"kjv" : "overflow"
	},
	"G4172" : {
		"def" : "a town (properly, with walls, of greater or less size)",
		"derivation" : "probably from the same as G4171 (πόλεμος), or perhaps from G4183 (πολύς)",
		"translit" : "pólis",
		"lemma" : "πόλις",
		"kjv" : "city"
	},
	"G892" : {
		"translit" : "áchyron",
		"lemma" : "ἄχυρον",
		"kjv" : "chaff",
		"def" : "chaff (as diffusive)",
		"derivation" : "perhaps remotely from (to shed forth)"
	},
	"G2662" : {
		"kjv" : "trample, tread (down, underfoot)",
		"translit" : "katapatéō",
		"lemma" : "καταπατέω",
		"derivation" : "from G2596 (κατά) and G3961 (πατέω)",
		"def" : "to trample down; figuratively, to reject with disdain"
	},
	"G3693" : {
		"def" : "from G3700 (ὀπτάνομαι)) with enclitic of source; from the rear (as a secure aspect), i.e. at the back (adverb and preposition of place or time)",
		"derivation" : "from (regard",
		"translit" : "ópisthen",
		"lemma" : "ὄπισθεν",
		"kjv" : "after, backside, behind"
	},
	"G674" : {
		"def" : "to breathe out, i.e. faint",
		"derivation" : "from G575 (ἀπό) and G5594 (ψύχω)",
		"translit" : "apopsýchō",
		"lemma" : "ἀποψύχω",
		"kjv" : "hearts failing"
	},
	"G2940" : {
		"derivation" : "from (a \"cube\", i.e. die for playing)",
		"def" : "gambling, i.e. (figuratively) artifice or fraud",
		"kjv" : "sleight",
		"translit" : "kybeía",
		"lemma" : "κυβεία"
	},
	"G1652" : {
		"derivation" : "from G1656 (ἔλεος)",
		"def" : "pitiable",
		"kjv" : "miserable",
		"translit" : "eleeinós",
		"lemma" : "ἐλεεινός"
	},
	"G463" : {
		"kjv" : "forbearance",
		"translit" : "anochḗ",
		"lemma" : "ἀνοχή",
		"derivation" : "from G430 (ἀνέχομαι)",
		"def" : "self-restraint, i.e. tolerance"
	},
	"G2370" : {
		"kjv" : "burn incense",
		"lemma" : "θυμιάω",
		"translit" : "thymiáō",
		"derivation" : "from a derivative of G2380 (θύω) (in the sense of smoking)",
		"def" : "to fumigate, i.e. offer aromatic fumes"
	},
	"G4721" : {
		"derivation" : "strengthened from a primary (a \"thatch\" or \"deck\" of a building)",
		"def" : "a roof",
		"kjv" : "roof",
		"lemma" : "στέγη",
		"translit" : "stégē"
	},
	"G1960" : {
		"derivation" : "adverb from a derivative of G1959 (ἐπιμελέομαι)",
		"def" : "carefully",
		"kjv" : "diligently",
		"lemma" : "ἐπιμελῶς",
		"translit" : "epimelōs"
	},
	"G567" : {
		"translit" : "apéchomai",
		"lemma" : "ἀπέχομαι",
		"kjv" : "abstain",
		"def" : "to hold oneself off, i.e. refrain",
		"derivation" : "middle voice (reflexively) of G568 (ἀπέχω)"
	},
	"G4315" : {
		"kjv" : "day before the sabbath",
		"lemma" : "προσάββατον",
		"translit" : "prosábbaton",
		"derivation" : "from G4253 (πρό) and G4521 (σάββατον)",
		"def" : "a fore-sabbath, i.e. the Sabbath-eve"
	},
	"G5226" : {
		"def" : "to surrender",
		"derivation" : "from G5259 (ὑπό) and (to yield, be \"weak\")",
		"translit" : "hypeíkō",
		"lemma" : "ὑπείκω",
		"kjv" : "submit self"
	},
	"G4961" : {
		"translit" : "systratiṓtēs",
		"lemma" : "συστρατιώτης",
		"kjv" : "fellowsoldier",
		"def" : "a co-campaigner, i.e. (figuratively) an associate in Christian toil",
		"derivation" : "from G4862 (σύν) and G4757 (στρατιώτης)"
	},
	"G2002" : {
		"lemma" : "ἐπισωρεύω",
		"translit" : "episōreúō",
		"kjv" : "heap",
		"def" : "to accumulate further, i.e. (figuratively) seek additionally",
		"derivation" : "from G1909 (ἐπί) and G4987 (σωρεύω)"
	},
	"G3199" : {
		"kjv" : "(take) care",
		"lemma" : "μέλω",
		"translit" : "mélō",
		"derivation" : "a primary verb",
		"def" : "to be of interest to, i.e. to concern (only third person singular present indicative used impersonally, it matters)"
	},
	"G620" : {
		"lemma" : "ἀπολείπω",
		"translit" : "apoleípō",
		"kjv" : "leave, remain",
		"def" : "to leave behind (passively, remain); by implication, to forsake",
		"derivation" : "from G575 (ἀπό) and G3007 (λείπω)"
	},
	"G1817" : {
		"lemma" : "ἐξανίστημι",
		"translit" : "exanístēmi",
		"kjv" : "raise (rise) up",
		"def" : "objectively, to produce, i.e. (figuratively) beget; subjectively, to arise, i.e. (figuratively) object",
		"derivation" : "from G1537 (ἐκ) and G450 (ἀνίστημι)"
	},
	"G5603" : {
		"derivation" : "from G103 (ᾄδω)",
		"def" : "a chant or \"ode\" (the general term for any words sung; while G5215 (ὕμνος) denotes especially a religious metrical composition, and G5568 (ψαλμός) still more specially, a Hebrew cantillation)",
		"kjv" : "song",
		"lemma" : "ᾠδή",
		"translit" : "ōidḗ"
	},
	"G2954" : {
		"def" : "Cyprus, an island in the Mediterranean",
		"derivation" : "of uncertain origin",
		"lemma" : "Κύπρος",
		"translit" : "Kýpros",
		"kjv" : "Cyprus"
	},
	"G2828" : {
		"translit" : "klisía",
		"lemma" : "κλισία",
		"kjv" : "company",
		"def" : "properly, reclination, i.e. (concretely and specially), a party at a meal",
		"derivation" : "from a derivative of G2827 (κλίνω)"
	},
	"G4049" : {
		"derivation" : "from G4012 (περί) and G4685 (σπάω)",
		"def" : "to drag all around, i.e. (figuratively) to distract (with care)",
		"kjv" : "cumber",
		"lemma" : "περισπάω",
		"translit" : "perispáō"
	},
	"G4231" : {
		"translit" : "pragmateúomai",
		"lemma" : "πραγματεύομαι",
		"kjv" : "occupy",
		"def" : "to busy oneself with, i.e. to trade",
		"derivation" : "from G4229 (πρᾶγμα)"
	},
	"G3325" : {
		"derivation" : "from G3324 (μεστός)",
		"def" : "to replenish, i.e. (by implication) to intoxicate",
		"kjv" : "fill",
		"translit" : "mestóō",
		"lemma" : "μεστόω"
	},
	"G2863" : {
		"derivation" : "from G2864 (κόμη)",
		"def" : "to wear tresses of hair",
		"kjv" : "have long hair",
		"translit" : "komáō",
		"lemma" : "κομάω"
	},
	"G472" : {
		"derivation" : "from G473 (ἀντί) and the middle voice of G2192 (ἔχω)",
		"def" : "to hold oneself opposite to, i.e. (by implication) adhere to; by extension to care for",
		"kjv" : "hold fast, hold to, support",
		"translit" : "antéchomai",
		"lemma" : "ἀντέχομαι"
	},
	"G1525" : {
		"kjv" : "X arise, come (in, into), enter in(-to), go in (through)",
		"lemma" : "εἰσέρχομαι",
		"translit" : "eisérchomai",
		"derivation" : "from G1519 (εἰς) and G2064 (ἔρχομαι)",
		"def" : "to enter (literally or figuratively)"
	},
	"G1587" : {
		"def" : "to omit, i.e. (by implication) cease (die)",
		"derivation" : "from G1537 (ἐκ) and G3007 (λείπω)",
		"lemma" : "ἐκλείπω",
		"translit" : "ekleípō",
		"kjv" : "fail"
	},
	"G4511" : {
		"derivation" : "from G4506 (ῥύομαι) in the sense of its congener G4482 (ῥέω)",
		"def" : "a flux (of blood)",
		"kjv" : "issue",
		"translit" : "rhýsis",
		"lemma" : "ῥύσις"
	},
	"G4017" : {
		"derivation" : "from G4012 (περί) and G991 (βλέπω)",
		"def" : "to look all around",
		"kjv" : "look (round) about (on)",
		"lemma" : "περιβλέπω",
		"translit" : "periblépō"
	},
	"G3056" : {
		"derivation" : "from G3004 (λέγω)",
		"def" : "something said (including the thought); by implication, a topic (subject of discourse), also reasoning (the mental faculty) or motive; by extension, a computation; specially, (with the article in John) the Divine Expression (i.e. Christ)",
		"kjv" : "account, cause, communication, X concerning, doctrine, fame, X have to do, intent, matter, mouth, preaching, question, reason, + reckon, remove, say(-ing), shew, X speaker, speech, talk, thing, + none of these things move me, tidings, treatise, utterance, word, work",
		"lemma" : "λόγος",
		"translit" : "lógos"
	},
	"G3605" : {
		"kjv" : "stink",
		"lemma" : "ὄζω",
		"translit" : "ózō",
		"derivation" : "a primary verb (in a strengthened form)",
		"def" : "to scent (usually an ill \"odor\")"
	},
	"G4089" : {
		"lemma" : "πικρός",
		"translit" : "pikrós",
		"kjv" : "bitter",
		"def" : "sharp (pungent), i.e. acrid (literally or figuratively)",
		"derivation" : "perhaps from G4078 (πήγνυμι) (through the idea of piercing)"
	},
	"G2821" : {
		"kjv" : "calling",
		"lemma" : "κλῆσις",
		"translit" : "klēsis",
		"derivation" : "from a shorter form of G2564 (καλέω)",
		"def" : "an invitation (figuratively)"
	},
	"G4053" : {
		"lemma" : "περισσός",
		"translit" : "perissós",
		"kjv" : "exceeding abundantly above, more abundantly, advantage, exceedingly, very highly, beyond measure, more, superfluous, vehement(-ly)",
		"def" : "superabundant (in quantity) or superior (in quality); by implication, excessive; adverbially (with G1537 (ἐκ)) violently; neuter (as noun) preeminence",
		"derivation" : "from G4012 (περί) (in the sense of beyond)"
	},
	"G4301" : {
		"lemma" : "προλαμβάνω",
		"translit" : "prolambánō",
		"kjv" : "come aforehand, overtake, take before",
		"def" : "to take in advance, i.e. (literally) eat before others have an opportunity; (figuratively) to anticipate, surprise",
		"derivation" : "from G4253 (πρό) and G2983 (λαμβάνω)"
	},
	"G589" : {
		"lemma" : "ἀποδημέω",
		"translit" : "apodēméō",
		"kjv" : "go (travel) into a far country, journey",
		"def" : "to go abroad, i.e. visit a foreign land",
		"derivation" : "from G590 (ἀπόδημος)"
	},
	"G3026" : {
		"kjv" : "idle tale",
		"lemma" : "λῆρος",
		"translit" : "lēros",
		"derivation" : "apparently a primary word",
		"def" : "twaddle, i.e. an incredible story"
	},
	"G4513" : {
		"derivation" : "from G4514 (Ῥωμαῖος)",
		"def" : "Romaic, i.e. Latin",
		"kjv" : "Latin",
		"translit" : "Rhōmaïkós",
		"lemma" : "Ῥωμαϊκός"
	},
	"G2427" : {
		"kjv" : "make able (meet)",
		"translit" : "hikanóō",
		"lemma" : "ἱκανόω",
		"derivation" : "from G2425 (ἱκανός)",
		"def" : "to enable, i.e. qualify"
	},
	"G1392" : {
		"derivation" : "from G1391 (δόξα)",
		"def" : "to render (or esteem) glorious (in a wide application)",
		"kjv" : "(make) glorify(-ious), full of (have) glory, honour, magnify",
		"lemma" : "δοξάζω",
		"translit" : "doxázō"
	},
	"G3705" : {
		"def" : "something gazed at, i.e. a spectacle (especially supernatural)",
		"derivation" : "from G3708 (ὁράω)",
		"lemma" : "ὅραμα",
		"translit" : "hórama",
		"kjv" : "sight, vision"
	},
	"G3976" : {
		"derivation" : "ultimately from G4228 (πούς)",
		"def" : "a shackle for the feet",
		"kjv" : "fetter",
		"lemma" : "πέδη",
		"translit" : "pédē"
	},
	"G3406" : {
		"derivation" : "from G3409 (μισθόω) and G591 (ἀποδίδωμι)",
		"def" : "a renumerator",
		"kjv" : "rewarder",
		"translit" : "misthapodótēs",
		"lemma" : "μισθαποδότης"
	},
	"G613" : {
		"derivation" : "from G575 (ἀπό) and G2928 (κρύπτω)",
		"def" : "to conceal away (i.e. fully); figuratively, to keep secret",
		"kjv" : "hide",
		"lemma" : "ἀποκρύπτω",
		"translit" : "apokrýptō"
	},
	"G1327" : {
		"lemma" : "διέξοδος",
		"translit" : "diéxodos",
		"kjv" : "highway",
		"def" : "an outlet through, i.e. probably an open square (from which roads diverge)",
		"derivation" : "from G1223 (διά) and G1841 (ἔξοδος)"
	},
	"G712" : {
		"derivation" : "apparently neuter of a superlative from the same as G730 (ἄῤῥην)",
		"def" : "the best meal (or breakfast; perhaps from (\"early\")), i.e. luncheon",
		"kjv" : "dinner",
		"translit" : "áriston",
		"lemma" : "ἄριστον"
	},
	"G2243" : {
		"def" : "Helias (i.e. Elijah), an Israelite",
		"derivation" : "of Hebrew origin (H452)",
		"lemma" : "Ἡλίας",
		"translit" : "Hēlías",
		"kjv" : "Elias"
	},
	"G2901" : {
		"def" : "to empower, i.e. (passively) increase in vigor",
		"derivation" : "from G2900 (κραταιός)",
		"lemma" : "κραταιόω",
		"translit" : "krataióō",
		"kjv" : "be strengthened, be (wax) strong"
	},
	"G3666" : {
		"kjv" : "be (make) like, (in the) liken(-ess), resemble",
		"lemma" : "ὁμοιόω",
		"translit" : "homoióō",
		"derivation" : "from G3664 (ὅμοιος)",
		"def" : "to assimilate, i.e. compare; passively, to become similar"
	},
	"G247" : {
		"kjv" : "otherwise",
		"translit" : "állōs",
		"lemma" : "ἄλλως",
		"derivation" : "adverb from G243 (ἄλλος)",
		"def" : "differently"
	},
	"G4045" : {
		"lemma" : "περιπίπτω",
		"translit" : "peripíptō",
		"kjv" : "fall among (into)",
		"def" : "to fall into something that is all around, i.e. light among or upon, be surrounded with",
		"derivation" : "from G4012 (περί) and G4098 (πίπτω)"
	},
	"G2320" : {
		"def" : "divinity (abstractly)",
		"derivation" : "from G2316 (θεός)",
		"translit" : "theótēs",
		"lemma" : "θεότης",
		"kjv" : "godhead"
	},
	"G1797" : {
		"derivation" : "middle voice from G1798 (ἐνύπνιον)",
		"def" : "to dream",
		"kjv" : "dream(-er)",
		"translit" : "enypniázomai",
		"lemma" : "ἐνυπνιάζομαι"
	},
	"G1676" : {
		"kjv" : "Greek",
		"lemma" : "Ἑλληνιστί",
		"translit" : "Hellēnistí",
		"derivation" : "adverb from the same as G1675 (Ἑλληνιστής)",
		"def" : "Hellenistically, i.e. in the Grecian language"
	},
	"G832" : {
		"derivation" : "from G836 (αὐλός)",
		"def" : "to play the flute",
		"kjv" : "pipe",
		"translit" : "auléō",
		"lemma" : "αὐλέω"
	},
	"G3859" : {
		"translit" : "paradiatribḗ",
		"lemma" : "παραδιατριβή",
		"kjv" : "perverse disputing",
		"def" : "misemployment, i.e. meddlesomeness",
		"derivation" : "from a compound of G3844 (παρά) and G1304 (διατρίβω)"
	},
	"G3692" : {
		"kjv" : "cave, place",
		"lemma" : "ὀπή",
		"translit" : "opḗ",
		"derivation" : "probably from G3700 (ὀπτάνομαι)",
		"def" : "a hole (as if for light), i.e. cavern; by analogy, a spring (of water)"
	},
	"G5007" : {
		"derivation" : "neuter of a presumed derivative of the original form of (to bear",
		"def" : "equivalent to G5342 (φέρω)); a balance (as supporting weights), i.e. (by implication) a certain weight (and thence a coin or rather sum of money) or \"talent\"",
		"kjv" : "talent",
		"lemma" : "τάλαντον",
		"translit" : "tálanton"
	},
	"G298" : {
		"lemma" : "ἀμώμητος",
		"translit" : "amṓmētos",
		"kjv" : "blameless",
		"def" : "unblamable",
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G3469 (μωμάομαι)"
	},
	"G1784" : {
		"def" : "valued (figuratively)",
		"derivation" : "from G1722 (ἐν) and G5092 (τιμή)",
		"translit" : "éntimos",
		"lemma" : "ἔντιμος",
		"kjv" : "dear, more honourable, precious, in reputation"
	},
	"G1407" : {
		"derivation" : "from (to pluck)",
		"def" : "a gathering hook (especially for harvesting)",
		"kjv" : "sickle",
		"lemma" : "δρέπανον",
		"translit" : "drépanon"
	},
	"G3032" : {
		"def" : "a Roman freedman",
		"derivation" : "of Latin origin",
		"lemma" : "Λιβερτῖνος",
		"translit" : "Libertînos",
		"kjv" : "Libertine"
	},
	"G84" : {
		"def" : "uncertainly",
		"derivation" : "adverb from G82 (ἄδηλος)",
		"lemma" : "ἀδήλως",
		"translit" : "adḗlōs",
		"kjv" : "uncertainly"
	},
	"G2767" : {
		"def" : "to mingle, i.e. (by implication) to pour out (for drinking)",
		"derivation" : "prolonged form of a more primary (which is used in certain tenses)",
		"lemma" : "κεράννυμι",
		"translit" : "keránnymi",
		"kjv" : "fill, pour out"
	},
	"G363" : {
		"def" : "to remind; (reflexively) to recollect",
		"derivation" : "from G303 (ἀνά) and G3403 (μιμνήσκω)",
		"translit" : "anamimnḗskō",
		"lemma" : "ἀναμιμνήσκω",
		"kjv" : "call to mind, (bring to , call to, put in), remember(-brance)"
	},
	"G4492" : {
		"def" : "to root (figuratively, become stable)",
		"derivation" : "from G4491 (ῥίζα)",
		"translit" : "rhizóō",
		"lemma" : "ῥιζόω",
		"kjv" : "root"
	},
	"G4323" : {
		"lemma" : "προσανατίθημι",
		"translit" : "prosanatíthēmi",
		"kjv" : "in conference add, confer",
		"def" : "to lay up in addition, i.e. (middle voice and figuratively) to impart or (by implication) to consult",
		"derivation" : "from G4314 (πρός) and G394 (ἀνατίθεμαι)"
	},
	"G431" : {
		"def" : "properly, akin, i.e. (specially) a cousin",
		"derivation" : "from G1 (Α) (as a particle of union) and an obsolete (a brood)",
		"lemma" : "ἀνεψιός",
		"translit" : "anepsiós",
		"kjv" : "sister's son"
	},
	"G1311" : {
		"derivation" : "from G1225 (διαβάλλω) and G5351 (φθείρω)",
		"def" : "to rot thoroughly, i.e. (by implication) to ruin (passively, decay utterly, figuratively, pervert)",
		"kjv" : "corrupt, destroy, perish",
		"translit" : "diaphtheírō",
		"lemma" : "διαφθείρω"
	},
	"G4229" : {
		"kjv" : "business, matter, thing, work",
		"translit" : "prâgma",
		"lemma" : "πρᾶγμα",
		"derivation" : "from G4238 (πράσσω)",
		"def" : "a deed; by implication, an affair; by extension, an object (material)"
	},
	"G158" : {
		"kjv" : "cause, fault",
		"lemma" : "αἴτιον",
		"translit" : "aítion",
		"derivation" : "neuter of G159 (αἴτιος)",
		"def" : "a reason or crime (like G156 (αἰτία))"
	},
	"G35" : {
		"derivation" : "from G1 (Α) (as negative particle) and G1075 (γενεαλογέω)",
		"def" : "unregistered as to birth",
		"kjv" : "without descent",
		"lemma" : "ἀγενεαλόγητος",
		"translit" : "agenealógētos"
	},
	"G3326" : {
		"kjv" : "after(-ward), X that he again, against, among, X and, + follow, hence, hereafter, in, of, (up-)on, + our, X and setting, since, (un-)to, + together, when, with (+ -out)",
		"lemma" : "μετά",
		"translit" : "metá",
		"derivation" : "a primary preposition (often used adverbially)",
		"def" : "properly, denoting accompaniment; \"amid\" (local or causal); modified variously according to the case (genitive association, or accusative succession) with which it is joined; occupying an intermediate position between G575 (ἀπό) or G1537 (ἐκ) and G1519 (εἰς) or G4314 (πρός); less intimate than G1722 (ἐν) and less close than G4862 (σύν))"
	},
	"G4246" : {
		"def" : "an old man",
		"derivation" : "from the same as G4245 (πρεσβύτερος)",
		"translit" : "presbýtēs",
		"lemma" : "πρεσβύτης",
		"kjv" : "aged (man), old man"
	},
	"G4930" : {
		"kjv" : "end",
		"lemma" : "συντέλεια",
		"translit" : "syntéleia",
		"derivation" : "from G4931 (συντελέω)",
		"def" : "entire completion, i.e. consummation (of a dispensation)"
	},
	"G448" : {
		"derivation" : "from G1 (Α) (as a negative particle) and G2436 (ἵλεως)",
		"def" : "inexorable",
		"kjv" : "without mercy",
		"translit" : "aníleōs",
		"lemma" : "ἀνίλεως"
	},
	"G5611" : {
		"translit" : "hōraîos",
		"lemma" : "ὡραῖος",
		"kjv" : "beautiful",
		"def" : "belonging to the right hour or season (timely), i.e. (by implication) flourishing (beauteous (figuratively))",
		"derivation" : "from G5610 (ὥρα)"
	},
	"G1485" : {
		"kjv" : "custom, manner, be wont",
		"lemma" : "ἔθος",
		"translit" : "éthos",
		"derivation" : "from G1486 (ἔθω)",
		"def" : "a usage (prescribed by habit or law)"
	},
	"G4971" : {
		"def" : "very much",
		"derivation" : "adverb from the same as G4970 (σφόδρα)",
		"translit" : "sphodrōs",
		"lemma" : "σφοδρῶς",
		"kjv" : "exceedingly"
	},
	"G545" : {
		"def" : "unpersuadable, i.e. contumacious",
		"derivation" : "from G1 (Α) (as a negative particle) and G3982 (πείθω)",
		"translit" : "apeithḗs",
		"lemma" : "ἀπειθής",
		"kjv" : "disobedient"
	},
	"G2860" : {
		"def" : "to plunge into water",
		"derivation" : "from (a diver)",
		"translit" : "kolymbáō",
		"lemma" : "κολυμβάω",
		"kjv" : "swim"
	},
	"G4525" : {
		"kjv" : "move",
		"translit" : "saínō",
		"lemma" : "σαίνω",
		"derivation" : "akin to G4579 (σείω)",
		"def" : "to wag (as a dog its tail fawningly), i.e. (generally) to shake (figuratively, disturb)"
	},
	"G809" : {
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G2192 (ἔχω) (in the sense of its congener G4976 (σχῆμα))",
		"def" : "properly, shapeless, i.e. (figuratively) inelegant",
		"kjv" : "uncomely",
		"translit" : "aschḗmōn",
		"lemma" : "ἀσχήμων"
	},
	"G4138" : {
		"kjv" : "which is put in to fill up, piece that filled up, fulfilling, full, fulness",
		"translit" : "plḗrōma",
		"lemma" : "πλήρωμα",
		"derivation" : "from G4137 (πληρόω)",
		"def" : "repletion or completion, i.e. (subjectively) what fills (as contents, supplement, copiousness, multitude), or (objectively) what is filled (as container, performance, period)"
	},
	"G1748" : {
		"def" : "to lurk, i.e. (figuratively) plot assassination",
		"derivation" : "from G1747 (ἐνέδρα)",
		"lemma" : "ἐνεδρεύω",
		"translit" : "enedreúō",
		"kjv" : "lay wait for"
	},
	"G3554" : {
		"def" : "a malady (rarely figuratively, of moral disability)",
		"derivation" : "of uncertain affinity",
		"lemma" : "νόσος",
		"translit" : "nósos",
		"kjv" : "disease, infirmity, sickness"
	},
	"G4222" : {
		"lemma" : "ποτίζω",
		"translit" : "potízō",
		"kjv" : "give (make) to drink, feed, water",
		"def" : "to furnish drink, irrigate",
		"derivation" : "from a derivative of the alternate of G4095 (πίνω)"
	},
	"G456" : {
		"def" : "to rebuild",
		"derivation" : "from G303 (ἀνά) and G3618 (οἰκοδομέω)",
		"translit" : "anoikodoméō",
		"lemma" : "ἀνοικοδομέω",
		"kjv" : "build again"
	},
	"G2795" : {
		"kjv" : "(re-)move(-r), way",
		"lemma" : "κινέω",
		"translit" : "kinéō",
		"derivation" : "from (poetic for , to go)",
		"def" : "to stir (transitively), literally or figuratively"
	},
	"G2053" : {
		"kjv" : "wool",
		"translit" : "érion",
		"lemma" : "ἔριον",
		"derivation" : "of obscure affinity",
		"def" : "wool"
	},
	"G1931" : {
		"kjv" : "go down",
		"translit" : "epidýō",
		"lemma" : "ἐπιδύω",
		"derivation" : "from G1909 (ἐπί) and G1416 (δύνω)",
		"def" : "to set fully (as the sun)"
	},
	"G3813" : {
		"derivation" : "neuter diminutive of G3816 (παῖς)",
		"def" : "a childling (of either sex), i.e. (properly), an infant, or (by extension) a half-grown boy or girl; figuratively, an immature Christian",
		"kjv" : "(little, young) child, damsel",
		"translit" : "paidíon",
		"lemma" : "παιδίον"
	},
	"G5006" : {
		"def" : "talent-like in weight",
		"derivation" : "from G5007 (τάλαντον)",
		"translit" : "talantiaîos",
		"lemma" : "ταλαντιαῖος",
		"kjv" : "weight of a talent"
	},
	"G4370" : {
		"def" : "to run towards, i.e. hasten to meet or join",
		"derivation" : "from G4314 (πρός) and G5143 (τρέχω) (including its alternate)",
		"lemma" : "προστρέχω",
		"translit" : "prostréchō",
		"kjv" : "run (thither to, to)"
	},
	"G1160" : {
		"derivation" : "from (to devour)",
		"def" : "expense (as consuming)",
		"kjv" : "cost",
		"translit" : "dapánē",
		"lemma" : "δαπάνη"
	},
	"G5545" : {
		"kjv" : "anointing, unction",
		"lemma" : "χρῖσμα",
		"translit" : "chrîsma",
		"derivation" : "from G5548 (χρίω)",
		"def" : "an unguent or smearing, i.e. (figuratively) the special endowment (\"chrism\") of the Holy Spirit"
	},
	"G3543" : {
		"kjv" : "suppose, thing, be wont",
		"lemma" : "νομίζω",
		"translit" : "nomízō",
		"derivation" : "from G3551 (νόμος)",
		"def" : "properly, to do by law (usage), i.e. to accustom (passively, be usual); by extension, to deem or regard"
	},
	"G972" : {
		"lemma" : "βίαιος",
		"translit" : "bíaios",
		"kjv" : "mighty",
		"def" : "violent",
		"derivation" : "from G970 (βία)"
	},
	"G5606" : {
		"derivation" : "perhaps from the alternate of G5342 (φέρω)",
		"def" : "the shoulder (as that on which burdens are borne)",
		"kjv" : "shoulder",
		"translit" : "ōmos",
		"lemma" : "ὦμος"
	},
	"G4286" : {
		"lemma" : "πρόθεσις",
		"translit" : "próthesis",
		"kjv" : "purpose, shew(-bread)",
		"def" : "a setting forth, i.e. (figuratively) proposal (intention); specially, the show-bread (in the Temple) as exposed before God",
		"derivation" : "from G4388 (προτίθεμαι)"
	},
	"G5409" : {
		"translit" : "phoréō",
		"lemma" : "φορέω",
		"kjv" : "bear, wear",
		"def" : "to have a burden, i.e. (by analogy) to wear as clothing or a constant accompaniment",
		"derivation" : "from G5411 (φόρος)"
	},
	"G3695" : {
		"def" : "to equip (with weapons (middle voice and figuratively))",
		"derivation" : "from G3696 (ὅπλον)",
		"translit" : "hoplízō",
		"lemma" : "ὁπλίζω",
		"kjv" : "arm self"
	},
	"G696" : {
		"def" : "silver (the metal, in the articles or coin)",
		"derivation" : "from (shining)",
		"translit" : "árgyros",
		"lemma" : "ἄργυρος",
		"kjv" : "silver"
	},
	"G3728" : {
		"def" : "asseveration on oath",
		"derivation" : "from a compound of G3727 (ὅρκος) and a derivative of G3660 (ὀμνύω)",
		"translit" : "horkōmosía",
		"lemma" : "ὁρκωμοσία",
		"kjv" : "oath"
	},
	"G1866" : {
		"translit" : "Epaínetos",
		"lemma" : "Ἐπαίνετος",
		"kjv" : "Epenetus",
		"def" : "praised; Epænetus, a Christian",
		"derivation" : "from G1867 (ἐπαινέω)"
	},
	"G3093" : {
		"kjv" : "Magdala",
		"lemma" : "Μαγδαλά",
		"translit" : "Magdalá",
		"derivation" : "of Chaldee origin (compare H4026)",
		"def" : "the tower; Magdala (i.e. Migdala), a place in Palestine"
	},
	"G4556" : {
		"derivation" : "properly, an adjective from an uncertain base",
		"def" : "sardian (3037 being implied), i.e. (as noun) the gem so called",
		"kjv" : "sardius",
		"translit" : "sárdios",
		"lemma" : "σάρδιος"
	},
	"G1158" : {
		"def" : "Daniel, an Israelite",
		"derivation" : "of Hebrew origin (H1840)",
		"translit" : "Daniḗl",
		"lemma" : "Δανιήλ",
		"kjv" : "Daniel"
	},
	"G2428" : {
		"translit" : "hiketēría",
		"lemma" : "ἱκετηρία",
		"kjv" : "supplication",
		"def" : "intreaty",
		"derivation" : "from a derivative of the base of G2425 (ἱκανός) (through the idea of approaching for a favor)"
	},
	"G2109" : {
		"kjv" : "do good",
		"lemma" : "εὐεργετέω",
		"translit" : "euergetéō",
		"derivation" : "from G2110 (εὐεργέτης)",
		"def" : "to be philanthropic"
	},
	"G3376" : {
		"def" : "a month",
		"derivation" : "a primary word",
		"translit" : "mḗn",
		"lemma" : "μήν",
		"kjv" : "month"
	},
	"G744" : {
		"derivation" : "from G746 (ἀρχή)",
		"def" : "original or primeval",
		"kjv" : "(them of) old (time)",
		"lemma" : "ἀρχαῖος",
		"translit" : "archaîos"
	},
	"G4891" : {
		"derivation" : "from G4862 (σύν) and G1453 (ἐγείρω)",
		"def" : "to rouse (from death) in company with, i.e. (figuratively) to revivify (spirtually) in resemblance to",
		"kjv" : "raise up together, rise with",
		"lemma" : "συνεγείρω",
		"translit" : "synegeírō"
	},
	"G5248" : {
		"def" : "to super-abound",
		"derivation" : "from G5228 (ὑπέρ) and G4052 (περισσεύω)",
		"translit" : "hyperperisseúō",
		"lemma" : "ὑπερπερισσεύω",
		"kjv" : "abound much more, exceeding"
	},
	"G967" : {
		"def" : "fig-house; Beth-phage, a place in Palestine",
		"derivation" : "of Chaldee origin (compare H1004 and H6291)",
		"lemma" : "Βηθφαγή",
		"translit" : "Bēthphagḗ",
		"kjv" : "Bethphage"
	},
	"G1618" : {
		"def" : "intent",
		"derivation" : "from G1614 (ἐκτείνω)",
		"lemma" : "ἐκτενής",
		"translit" : "ektenḗs",
		"kjv" : "without ceasing, fervent"
	},
	"G5452" : {
		"translit" : "phyteúō",
		"lemma" : "φυτεύω",
		"kjv" : "plant",
		"def" : "to set out in the earth, i.e. implant; figuratively, to instil doctrine",
		"derivation" : "from a derivative of G5453 (φύω)"
	},
	"G3456" : {
		"derivation" : "from a derivative of the base of G3455 (μυκάομαι) (meaning snout, as that whence lowing proceeds)",
		"def" : "to make mouths at, i.e. ridicule",
		"kjv" : "mock",
		"translit" : "myktērízō",
		"lemma" : "μυκτηρίζω"
	},
	"G1838" : {
		"kjv" : "use",
		"translit" : "héxis",
		"lemma" : "ἕξις",
		"derivation" : "from G2192 (ἔχω)",
		"def" : "habit, i.e. (by implication) practice"
	},
	"G693" : {
		"derivation" : "from G696 (ἄργυρος)",
		"def" : "made of silver",
		"kjv" : "(of) silver",
		"lemma" : "ἀργύρεος",
		"translit" : "argýreos"
	},
	"G3359" : {
		"kjv" : "forehead",
		"translit" : "métōpon",
		"lemma" : "μέτωπον",
		"derivation" : "from G3326 (μετά) and ops (the face)",
		"def" : "the forehead (as opposite the countenance)"
	},
	"G586" : {
		"kjv" : "(give, pay, take) tithe",
		"translit" : "apodekatóō",
		"lemma" : "ἀποδεκατόω",
		"derivation" : "from G575 (ἀπό) and G1183 (δεκατόω)",
		"def" : "to tithe (as debtor or creditor)"
	},
	"G699" : {
		"kjv" : "pleasing",
		"translit" : "areskeía",
		"lemma" : "ἀρεσκεία",
		"derivation" : "from a derivative of G700 (ἀρέσκω)",
		"def" : "complaisance"
	},
	"G5576" : {
		"derivation" : "from G5575 (ψευδομάρτυρ)",
		"def" : "to be an untrue testifier, i.e. offer falsehood in evidence",
		"kjv" : "be a false witness",
		"lemma" : "ψευδομαρτυρέω",
		"translit" : "pseudomartyréō"
	},
	"G2931" : {
		"lemma" : "κρυφῆ",
		"translit" : "kryphē",
		"kjv" : "in secret",
		"def" : "privately",
		"derivation" : "adverb from G2928 (κρύπτω)"
	},
	"G1384" : {
		"def" : "properly, acceptable (current after assayal), i.e. approved",
		"derivation" : "from G1380 (δοκέω)",
		"lemma" : "δόκιμος",
		"translit" : "dókimos",
		"kjv" : "approved, tried"
	},
	"G2265" : {
		"def" : "Herodians, i.e. partisans of Herod",
		"derivation" : "plural of a derivative of G2264 (Ἡρώδης)",
		"lemma" : "Ἡρωδιανοί",
		"translit" : "Hērōdianoí",
		"kjv" : "Herodians"
	},
	"G694" : {
		"derivation" : "neuter of a presumed derivative of G696 (ἄργυρος)",
		"def" : "silvery, i.e. (by implication) cash; specially, a silverling (i.e. drachma or shekel)",
		"kjv" : "money, (piece of) silver (piece)",
		"lemma" : "ἀργύριον",
		"translit" : "argýrion"
	},
	"G2547" : {
		"derivation" : "from G2532 (καί) and G1564 (ἐκεῖθεν)",
		"def" : "likewise from that place (or time)",
		"kjv" : "and afterward (from) (thence), thence also",
		"lemma" : "κἀκεῖθεν",
		"translit" : "kakeîthen"
	},
	"G1539" : {
		"kjv" : "always",
		"lemma" : "ἑκάστοτε",
		"translit" : "hekástote",
		"derivation" : "as if from G1538 (ἕκαστος) and G5119 (τότε)",
		"def" : "at every time"
	},
	"G3316" : {
		"def" : "a go-between, i.e. (simply) an internunciator, or (by implication) a reconciler (intercessor)",
		"derivation" : "from G3319 (μέσος)",
		"translit" : "mesítēs",
		"lemma" : "μεσίτης",
		"kjv" : "mediator"
	},
	"G5095" : {
		"kjv" : "Timotheus, Timothy",
		"lemma" : "Τιμόθεος",
		"translit" : "Timótheos",
		"derivation" : "from G5092 (τιμή) and G2316 (θεός)",
		"def" : "dear to God; Timotheus, a Christian"
	},
	"G603" : {
		"def" : "intense anticipation",
		"derivation" : "from a comparative of G575 (ἀπό) and a compound of (the head) and G1380 (δοκέω) (in the sense of watching)",
		"translit" : "apokaradokía",
		"lemma" : "ἀποκαραδοκία",
		"kjv" : "earnest expectation"
	},
	"G2520" : {
		"derivation" : "from G2596 (κατά) and G2240 (ἥκω)",
		"def" : "to reach to, i.e. (neuter of present active participle, figuratively as adjective) becoming",
		"kjv" : "convenient, fit",
		"translit" : "kathḗkō",
		"lemma" : "καθήκω"
	},
	"G5034" : {
		"derivation" : "from the same as G5036 (ταχύς)",
		"def" : "a brief space (of time), i.e. (with G1722 (ἐν) prefixed) in haste",
		"kjv" : "+ quickly, + shortly, + speedily",
		"translit" : "táchos",
		"lemma" : "τάχος"
	},
	"G1941" : {
		"kjv" : "appeal (unto), call (on, upon), surname",
		"translit" : "epikaléomai",
		"lemma" : "ἐπικαλέομαι",
		"derivation" : "middle voice from G1909 (ἐπί) and G2564 (καλέω)",
		"def" : "to entitle; by implication, to invoke (for aid, worship, testimony, decision, etc.)"
	},
	"G5592" : {
		"derivation" : "from G5594 (ψύχω)",
		"def" : "coolness",
		"kjv" : "cold",
		"lemma" : "ψύχος",
		"translit" : "psýchos"
	},
	"G3572" : {
		"def" : "to prick (\"nudge\")",
		"derivation" : "apparently a primary word",
		"translit" : "nýssō",
		"lemma" : "νύσσω",
		"kjv" : "pierce"
	},
	"G3462" : {
		"def" : "to apply (perfumed) unguent to",
		"derivation" : "from G3464 (μύρον)",
		"translit" : "myrízō",
		"lemma" : "μυρίζω",
		"kjv" : "anoint"
	},
	"G559" : {
		"derivation" : "of Latin origin",
		"def" : "Apelles, a Christian",
		"kjv" : "Apelles",
		"lemma" : "Ἀπελλῆς",
		"translit" : "Apellēs"
	},
	"G3616" : {
		"derivation" : "from G3617 (οἰκοδεσπότης)",
		"def" : "to be the head of (i.e. rule) a family",
		"kjv" : "guide the house",
		"translit" : "oikodespotéō",
		"lemma" : "οἰκοδεσποτέω"
	},
	"G4287" : {
		"kjv" : "time appointed",
		"translit" : "prothésmios",
		"lemma" : "προθέσμιος",
		"derivation" : "from G4253 (πρό) and a derivative of G5087 (τίθημι)",
		"def" : "fixed beforehand, i.e. (feminine with G2250 (ἡμέρα) implied) a designated day"
	},
	"G2596" : {
		"derivation" : "a primary particle",
		"def" : "(prepositionally) down (in place or time), in varied relations (according to the case (genitive, dative or accusative) with which it is joined)",
		"kjv" : "about, according as (to), after, against, (when they were) X alone, among, and, X apart, (even, like) as (concerning, pertaining to touching), X aside, at, before, beyond, by, to the charge of, (charita-)bly, concerning, + covered, (dai-)ly, down, every, (+ far more) exceeding, X more excellent, for, from … to, godly, in(-asmuch, divers, every, -to, respect of), … by, after the manner of, + by any means, beyond (out of) measure, X mightily, more, X natural, of (up-)on (X part), out (of every), over against, (+ your) X own, + particularly, so, through(-oughout, -oughout every), thus, (un-)to(-gether, -ward), X uttermost, where(-by), with",
		"lemma" : "κατά",
		"translit" : "katá"
	},
	"G952" : {
		"translit" : "bébēlos",
		"lemma" : "βέβηλος",
		"kjv" : "profane (person)",
		"def" : "accessible (as by crossing the door-way), i.e. (by implication, of Jewish notions) heathenish, wicked",
		"derivation" : "from the base of G939 (βάσις) and (a threshold)"
	},
	"G3303" : {
		"kjv" : "even, indeed, so, some, truly, verily",
		"lemma" : "μέν",
		"translit" : "mén",
		"derivation" : "a primary particle",
		"def" : "properly, indicative of affirmation or concession (in fact); usually followed by a contrasted clause with G1161 (δέ) (this one, the former, etc.)"
	},
	"G2858" : {
		"def" : "a Colossæan, (i.e. inhabitant of Colossæ",
		"derivation" : "from G2857 (Κολοσσαί)",
		"translit" : "Kolossaeús",
		"lemma" : "Κολοσσαεύς",
		"kjv" : "Colossian"
	},
	"G576" : {
		"def" : "literally, to disembark; figuratively, to eventuate",
		"derivation" : "from G575 (ἀπό) and the base of G939 (βάσις)",
		"translit" : "apobaínō",
		"lemma" : "ἀποβαίνω",
		"kjv" : "become, go out, turn"
	},
	"G3957" : {
		"derivation" : "of Chaldee origin (compare H6453)",
		"def" : "the Passover (the meal, the day, the festival or the special sacrifices connected with it)",
		"kjv" : "Easter, Passover",
		"translit" : "páscha",
		"lemma" : "πάσχα"
	},
	"G4440" : {
		"def" : "a gate-way, door-way of a building or city; by implication, a portal or vestibule",
		"derivation" : "from G4439 (πύλη)",
		"lemma" : "πυλών",
		"translit" : "pylṓn",
		"kjv" : "gate, porch"
	},
	"G320" : {
		"derivation" : "from G314 (ἀναγινώσκω)",
		"def" : "(the act of) reading",
		"kjv" : "reading",
		"translit" : "anágnōsis",
		"lemma" : "ἀνάγνωσις"
	},
	"G2857" : {
		"derivation" : "apparently feminine plural of kolossos (\"colossal\")",
		"def" : "Colossæ, a place in Asia Minor",
		"kjv" : "Colosse",
		"lemma" : "Κολοσσαί",
		"translit" : "Kolossaí"
	},
	"G4291" : {
		"derivation" : "from G4253 (πρό) and G2476 (ἵστημι)",
		"def" : "to stand before, i.e. (in rank) to preside, or (by implication) to practise",
		"kjv" : "maintain, be over, rule",
		"lemma" : "προΐστημι",
		"translit" : "proḯstēmi"
	},
	"G221" : {
		"derivation" : "from (the city so called)",
		"def" : "an Alexandreian or inhabitant of Alexandria",
		"kjv" : "of Alexandria, Alexandrian",
		"lemma" : "Ἀλεξανδρεύς",
		"translit" : "Alexandreús"
	},
	"G5538" : {
		"def" : "a divine response or revelation",
		"derivation" : "from G5537 (χρηματίζω)",
		"lemma" : "χρηματισμός",
		"translit" : "chrēmatismós",
		"kjv" : "answer of God"
	},
	"G3853" : {
		"kjv" : "(give in) charge, (give) command(-ment), declare",
		"lemma" : "παραγγέλλω",
		"translit" : "parangéllō",
		"derivation" : "from G3844 (παρά) and the base of G32 (ἄγγελος)",
		"def" : "to transmit a message, i.e. (by implication) to enjoin"
	},
	"G2014" : {
		"kjv" : "appear, give light",
		"lemma" : "ἐπιφαίνω",
		"translit" : "epiphaínō",
		"derivation" : "from G1909 (ἐπί) and G5316 (φαίνω)",
		"def" : "to shine upon, i.e. become (literally) visible or (figuratively) known"
	},
	"G2140" : {
		"def" : "well-doing, i.e. beneficence",
		"derivation" : "from a compound of G2095 (εὖ) and G4160 (ποιέω)",
		"translit" : "eupoiḯa",
		"lemma" : "εὐποιΐα",
		"kjv" : "to do good"
	},
	"G312" : {
		"kjv" : "declare, rehearse, report, show, speak, tell",
		"translit" : "anangéllō",
		"lemma" : "ἀναγγέλλω",
		"derivation" : "from G303 (ἀνά) and the base of G32 (ἄγγελος)",
		"def" : "to announce (in detail)"
	},
	"G702" : {
		"kjv" : "Aretas",
		"lemma" : "Ἀρέτας",
		"translit" : "Arétas",
		"derivation" : "of foreign origin",
		"def" : "Aretas, an Arabian"
	},
	"G323" : {
		"def" : "(the act of) exhibition",
		"derivation" : "from G322 (ἀναδείκνυμι)",
		"lemma" : "ἀνάδειξις",
		"translit" : "anádeixis",
		"kjv" : "shewing"
	},
	"G1495" : {
		"kjv" : "idolatry",
		"lemma" : "εἰδωλολατρεία",
		"translit" : "eidōlolatreía",
		"derivation" : "from G1497 (εἴδωλον) and G2999 (λατρεία)",
		"def" : "image-worship (literally or figuratively)"
	},
	"G1048" : {
		"translit" : "Gáza",
		"lemma" : "Γάζα",
		"kjv" : "Gaza",
		"def" : "Gazah (i.e. Azzah), a place in Palestine",
		"derivation" : "of Hebrew origin (H5804)"
	},
	"G239" : {
		"def" : "praise ye Jah!, an adoring exclamation",
		"derivation" : "of Hebrew origin (imperative of H1984 and H3050)",
		"lemma" : "ἀλληλουϊα",
		"translit" : "allēlouïa",
		"kjv" : "alleluiah"
	},
	"G805" : {
		"kjv" : "make fast (sure)",
		"lemma" : "ἀσφαλίζω",
		"translit" : "asphalízō",
		"derivation" : "from G804 (ἀσφαλής)",
		"def" : "to render secure"
	},
	"G3055" : {
		"def" : "disputation about trifles (\"logomachy\")",
		"derivation" : "from the same as G3054 (λογομαχέω)",
		"translit" : "logomachía",
		"lemma" : "λογομαχία",
		"kjv" : "strife of words"
	},
	"G2675" : {
		"lemma" : "καταρτίζω",
		"translit" : "katartízō",
		"kjv" : "fit, frame, mend, (make) perfect(-ly join together), prepare, restore",
		"def" : "to complete thoroughly, i.e. repair (literally or figuratively) or adjust",
		"derivation" : "from G2596 (κατά) and a derivative of G739 (ἄρτιος)"
	},
	"G1280" : {
		"kjv" : "(be in) doubt, be (much) perplexed",
		"translit" : "diaporéō",
		"lemma" : "διαπορέω",
		"derivation" : "from G1223 (διά) and G639 (ἀπορέω)",
		"def" : "to be thoroughly nonplussed"
	},
	"G2912" : {
		"def" : "a Cretan, i.e. inhabitant of Crete",
		"derivation" : "from G2914 (Κρήτη)",
		"translit" : "Krḗs",
		"lemma" : "Κρής",
		"kjv" : "Crete, Cretian"
	},
	"G1097" : {
		"def" : "to \"know\" (absolutely) in a great variety of applications and with many implications (as follow, with others not thus clearly expressed)",
		"derivation" : "a prolonged form of a primary verb",
		"lemma" : "γινώσκω",
		"translit" : "ginṓskō",
		"kjv" : "allow, be aware (of), feel, (have) know(-ledge), perceived, be resolved, can speak, be sure, understand"
	},
	"G4054" : {
		"translit" : "perissóteron",
		"lemma" : "περισσότερον",
		"kjv" : "more abundantly, a great deal, far more",
		"def" : "in a more superabundant way",
		"derivation" : "neuter of G4055 (περισσότερος) (as adverb)"
	},
	"G5068" : {
		"kjv" : "foursquare",
		"translit" : "tetrágōnos",
		"lemma" : "τετράγωνος",
		"derivation" : "from G5064 (τέσσαρες) and G1137 (γωνία)",
		"def" : "four-cornered, i.e. square"
	},
	"G3342" : {
		"def" : "betwixt (of place or person); (of time) as adjective, intervening, or (by implication) adjoining",
		"derivation" : "from G3326 (μετά) and a form of G4862 (σύν)",
		"translit" : "metaxý",
		"lemma" : "μεταξύ",
		"kjv" : "between, mean while, next"
	},
	"G5563" : {
		"def" : "to place room between, i.e. part; reflexively, to go away",
		"derivation" : "from G5561 (χώρα)",
		"lemma" : "χωρίζω",
		"translit" : "chōrízō",
		"kjv" : "depart, put asunder, separate"
	},
	"G3460" : {
		"def" : "Myra, a place in Asia Minor",
		"derivation" : "of uncertain derivation",
		"translit" : "Mýra",
		"lemma" : "Μύρα",
		"kjv" : "Myra"
	},
	"G1914" : {
		"kjv" : "look upon, regard, have respect to",
		"translit" : "epiblépō",
		"lemma" : "ἐπιβλέπω",
		"derivation" : "from G1909 (ἐπί) and G991 (βλέπω)",
		"def" : "to gaze at (with favor, pity or partiality)"
	},
	"G4661" : {
		"kjv" : "spoil",
		"translit" : "skŷlon",
		"lemma" : "σκῦλον",
		"derivation" : "neuter from G4660 (σκύλλω)",
		"def" : "something stripped (as a hide), i.e. booty"
	},
	"G566" : {
		"def" : "it is sufficient",
		"derivation" : "third person singular present indicative active of G568 (ἀπέχω) used impersonally",
		"lemma" : "ἀπέχει",
		"translit" : "apéchei",
		"kjv" : "it is enough"
	},
	"G2466" : {
		"translit" : "Isachár",
		"lemma" : "Ἰσαχάρ",
		"kjv" : "Issachar",
		"def" : "Isachar (i.e. Jissaskar), a son of Jacob (figuratively, his descendant)",
		"derivation" : "of Hebrew origin (H3485)"
	},
	"G3911" : {
		"def" : "to bear along or aside, i.e. carry off (literally or figuratively); by implication, to avert",
		"derivation" : "from G3844 (παρά) and G5342 (φέρω) (including its alternate forms)",
		"translit" : "paraphérō",
		"lemma" : "παραφέρω",
		"kjv" : "remove, take away"
	},
	"G1180" : {
		"translit" : "dekatéssares",
		"lemma" : "δεκατέσσαρες",
		"kjv" : "fourteen",
		"def" : "ten and four, i.e. fourteen",
		"derivation" : "from G1176 (δέκα) and G5064 (τέσσαρες)"
	},
	"G2135" : {
		"def" : "a castrated person (such being employed in Oriental bed-chambers); by extension an impotent or unmarried man; by implication, a chamberlain (state-officer)",
		"derivation" : "from (a bed) and G2192 (ἔχω)",
		"lemma" : "εὐνοῦχος",
		"translit" : "eunoûchos",
		"kjv" : "eunuch"
	},
	"G4517" : {
		"translit" : "rhṓnnymi",
		"lemma" : "ῥώννυμι",
		"kjv" : "farewell",
		"def" : "probably akin to G4506 (ῥύομαι)); to strengthen, i.e. (impersonal passive) have health (as a parting exclamation, good-bye)",
		"derivation" : "prolongation from (to dart"
	},
	"G4195" : {
		"def" : "a sea; Pontus, a region of Asia Minor",
		"derivation" : "of Latin origin",
		"lemma" : "Πόντος",
		"translit" : "Póntos",
		"kjv" : "Pontus"
	},
	"G3191" : {
		"kjv" : "imagine, (pre-)meditate",
		"lemma" : "μελετάω",
		"translit" : "meletáō",
		"derivation" : "from a presumed derivative of G3199 (μέλω)",
		"def" : "to take care of, i.e. (by implication) revolve in the mind"
	},
	"G1864" : {
		"translit" : "epagōnízomai",
		"lemma" : "ἐπαγωνίζομαι",
		"kjv" : "earnestly contend for",
		"def" : "to struggle for",
		"derivation" : "from G1909 (ἐπί) and G75 (ἀγωνίζομαι)"
	},
	"G1461" : {
		"kjv" : "graff in(-to)",
		"lemma" : "ἐγκεντρίζω",
		"translit" : "enkentrízō",
		"derivation" : "from G1722 (ἐν) and a derivative of G2759 (κέντρον)",
		"def" : "to prick in, i.e. ingraft"
	},
	"G1030" : {
		"def" : "a grating (of the teeth)",
		"derivation" : "from G1031 (βρύχω)",
		"translit" : "brygmós",
		"lemma" : "βρυγμός",
		"kjv" : "gnashing"
	},
	"G5585" : {
		"derivation" : "from G5586 (ψῆφος)",
		"def" : "to use pebbles in enumeration, i.e. (generally) to compute",
		"kjv" : "count",
		"lemma" : "ψηφίζω",
		"translit" : "psēphízō"
	},
	"G4218" : {
		"kjv" : "afore-(any, some-)time(-s), at length (the last), (+ n- )ever, in the old time, in time past, once, when",
		"translit" : "poté",
		"lemma" : "ποτέ",
		"derivation" : "from the base of G4225 (πού) and G5037 (τέ)",
		"def" : "indefinite adverb, at some time, ever"
	},
	"G1284" : {
		"lemma" : "διαῤῥήσσω",
		"translit" : "diarrhḗssō",
		"kjv" : "break, rend",
		"def" : "to tear asunder",
		"derivation" : "from G1223 (διά) and G4486 (ῥήγνυμι)"
	},
	"G3347" : {
		"translit" : "metépeita",
		"lemma" : "μετέπειτα",
		"kjv" : "afterward",
		"def" : "thereafter",
		"derivation" : "from G3326 (μετά) and G1899 (ἔπειτα)"
	},
	"G3134" : {
		"kjv" : "Maran-atha",
		"lemma" : "μαρὰν ἀθά",
		"translit" : "maràn athá",
		"derivation" : "of Chaldee origin (meaning our Lord has come)",
		"def" : "maranatha, i.e. an exclamation of the approaching divine judgment"
	},
	"G48" : {
		"kjv" : "purify (self)",
		"lemma" : "ἁγνίζω",
		"translit" : "hagnízō",
		"derivation" : "from G53 (ἁγνός)",
		"def" : "to make clean, i.e. (figuratively) sanctify (ceremonially or morally)"
	},
	"G4970" : {
		"kjv" : "exceeding(-ly), greatly, sore, very",
		"translit" : "sphódra",
		"lemma" : "σφόδρα",
		"derivation" : "neuter plural of (violent",
		"def" : "of uncertain derivation) as adverb; vehemently, i.e. in a high degree, much"
	},
	"G3004" : {
		"def" : "properly, to \"lay\" forth, i.e. (figuratively) relate (in words (usually of systematic or set discourse; whereas G2036 (ἔπω) and G5346 (φημί) generally refer to an individual expression or speech respectively; while G4483 (ῥέω) is properly to break silence merely, and G2980 (λαλέω) means an extended or random harangue)); by implication, to mean",
		"derivation" : "a primary verb",
		"translit" : "légō",
		"lemma" : "λέγω",
		"kjv" : "ask, bid, boast, call, describe, give out, name, put forth, say(-ing, on), shew, speak, tell, utter"
	},
	"G1421" : {
		"def" : "difficult of explanation",
		"derivation" : "from G1418 (δυσ-) and a presumed derivative of G2059 (ἑρμηνεύω)",
		"lemma" : "δυσερμήνευτος",
		"translit" : "dysermḗneutos",
		"kjv" : "hard to be uttered"
	},
	"G349" : {
		"def" : "to scream up (aloud)",
		"derivation" : "from G303 (ἀνά) and G2896 (κράζω)",
		"lemma" : "ἀνακράζω",
		"translit" : "anakrázō",
		"kjv" : "cry out"
	},
	"G2238" : {
		"def" : "a sweet-scented plant, i.e. mint",
		"derivation" : "neuter of the compound of the same as G2234 (ἡδέως) and G3744 (ὀσμή)",
		"lemma" : "ἡδύοσμον",
		"translit" : "hēdýosmon",
		"kjv" : "mint"
	},
	"G3029" : {
		"kjv" : "exceeding, great(-ly), sore, very (+ chiefest)",
		"lemma" : "λίαν",
		"translit" : "lían",
		"derivation" : "of uncertain affinity",
		"def" : "much (adverbially)"
	},
	"G819" : {
		"derivation" : "from G820 (ἄτιμος)",
		"def" : "infamy, i.e. (subjectively) comparative indignity, (objectively) disgrace",
		"kjv" : "dishonour, reproach, shame, vile",
		"lemma" : "ἀτιμία",
		"translit" : "atimía"
	},
	"G2634" : {
		"derivation" : "from G2596 (κατά) and G2961 (κυριεύω)",
		"def" : "to lord against, i.e. control, subjugate",
		"kjv" : "exercise dominion over (lordship), be lord over, overcome",
		"translit" : "katakyrieúō",
		"lemma" : "κατακυριεύω"
	},
	"G2051" : {
		"lemma" : "ἐρίζω",
		"translit" : "erízō",
		"kjv" : "strive",
		"def" : "to wrangle",
		"derivation" : "from G2054 (ἔρις)"
	},
	"G5112" : {
		"lemma" : "τολμηρότερον",
		"translit" : "tolmēróteron",
		"kjv" : "the more boldly",
		"def" : "more daringly, i.e. with greater confidence than otherwise",
		"derivation" : "neuter of the compound of a derivative of the base of 5111 (as adverb)"
	},
	"G1113" : {
		"def" : "a grumbler",
		"derivation" : "from G1111 (γογγύζω)",
		"lemma" : "γογγυστής",
		"translit" : "gongystḗs",
		"kjv" : "murmurer"
	},
	"G4687" : {
		"kjv" : "sow(- er), receive seed",
		"lemma" : "σπείρω",
		"translit" : "speírō",
		"derivation" : "probably strengthened from G4685 (σπάω) (through the idea of extending)",
		"def" : "to scatter, i.e. sow (literally or figuratively)"
	},
	"G4812" : {
		"kjv" : "spoil",
		"translit" : "sylagōgéō",
		"lemma" : "συλαγωγέω",
		"derivation" : "from the base of G4813 (συλάω) and (the reduplicated form of) G71 (ἄγω)",
		"def" : "to lead away as booty, i.e. (figuratively) seduce"
	},
	"G2211" : {
		"lemma" : "Ζηνᾶς",
		"translit" : "Zēnâs",
		"kjv" : "Zenas",
		"def" : "Jove-given; Zenas, a Christian",
		"derivation" : "probably contracted from a poetic form of G2203 (Ζεύς) and G1435 (δῶρον)"
	},
	"G2185" : {
		"kjv" : "reach",
		"lemma" : "ἐφικνέομαι",
		"translit" : "ephiknéomai",
		"derivation" : "from G1909 (ἐπί) and a cognate of G2240 (ἥκω)",
		"def" : "to arrive upon, i.e. extend to"
	},
	"G8" : {
		"def" : "Abiathar, an Israelite",
		"derivation" : "of Hebrew origin (H54)",
		"translit" : "Abiathár",
		"lemma" : "Ἀβιαθάρ",
		"kjv" : "Abiathar"
	},
	"G4559" : {
		"kjv" : "carnal, fleshly",
		"lemma" : "σαρκικός",
		"translit" : "sarkikós",
		"derivation" : "from G4561 (σάρξ)",
		"def" : "pertaining to flesh, i.e. (by extension) bodily, temporal, or (by implication) animal, unregenerate"
	},
	"G1527" : {
		"def" : "severally",
		"derivation" : "from G1520 (εἷς) repeated with G2596 (κατά) inserted",
		"translit" : "heîs kath’ heîs",
		"lemma" : "εἷς καθ’ εἷς",
		"kjv" : "one by one"
	},
	"G46" : {
		"def" : "properly, unfulled, i.e. (by implication) new (cloth)",
		"derivation" : "from G1 (Α) (as a negative particle) and the same as G1102 (γναφεύς)",
		"translit" : "ágnaphos",
		"lemma" : "ἄγναφος",
		"kjv" : "new"
	},
	"G4735" : {
		"def" : "a chaplet (as a badge of royalty, a prize in the public games or a symbol of honor generally; but more conspicuous and elaborate than the simple fillet, G1238 (διάδημα)), literally or figuratively",
		"derivation" : "from an apparently primary (to twine or wreathe)",
		"translit" : "stéphanos",
		"lemma" : "στέφανος",
		"kjv" : "crown"
	},
	"G3512" : {
		"kjv" : "youthful",
		"lemma" : "νεωτερικός",
		"translit" : "neōterikós",
		"derivation" : "from the comparative of G3501 (νέος)",
		"def" : "appertaining to younger persons, i.e. juvenile"
	},
	"G4595" : {
		"derivation" : "apparently a primary verb",
		"def" : "to putrefy, i.e. (figuratively) perish",
		"kjv" : "be corrupted",
		"lemma" : "σήπω",
		"translit" : "sḗpō"
	},
	"G1526" : {
		"lemma" : "εἰσί",
		"translit" : "eisí",
		"kjv" : "agree, are, be, dure, X is, were",
		"def" : "they are",
		"derivation" : "3rd person plural present indicative of G1510 (εἰμί)"
	},
	"G4834" : {
		"def" : "to feel \"sympathy\" with, i.e. (by implication) to commiserate",
		"derivation" : "from G4835 (συμπαθής)",
		"lemma" : "συμπαθέω",
		"translit" : "sympathéō",
		"kjv" : "have compassion, be touched with a feeling of"
	},
	"G2178" : {
		"kjv" : "(at) once (for all)",
		"lemma" : "ἐφάπαξ",
		"translit" : "ephápax",
		"derivation" : "from G1909 (ἐπί) and G530 (ἅπαξ)",
		"def" : "upon one occasion (only)"
	},
	"G1428" : {
		"derivation" : "from G1427 (δώδεκα)",
		"def" : "twelfth",
		"kjv" : "twelfth",
		"lemma" : "δωδέκατος",
		"translit" : "dōdékatos"
	},
	"G1362" : {
		"derivation" : "from G1364 (δίς) and (probably) the base of G4119 (πλείων)",
		"def" : "two-fold",
		"kjv" : "double, two-fold more",
		"lemma" : "διπλοῦς",
		"translit" : "diploûs"
	},
	"G2388" : {
		"def" : "Janna, an Israelite",
		"derivation" : "probably of Hebrew origin (compare H3238)",
		"lemma" : "Ἰαννά",
		"translit" : "Ianná",
		"kjv" : "Janna"
	},
	"G3862" : {
		"def" : "transmission, i.e. (concretely) a precept; specially, the Jewish traditionary law",
		"derivation" : "from G3860 (παραδίδωμι)",
		"translit" : "parádosis",
		"lemma" : "παράδοσις",
		"kjv" : "ordinance, tradition"
	},
	"G3943" : {
		"derivation" : "from G3844 (παρά) and G3631 (οἶνος)",
		"def" : "staying near wine, i.e. tippling (a toper)",
		"kjv" : "given to wine",
		"lemma" : "πάροινος",
		"translit" : "pároinos"
	},
	"G1792" : {
		"kjv" : "sporting selves",
		"translit" : "entrypháō",
		"lemma" : "ἐντρυφάω",
		"derivation" : "from G1722 (ἐν) and G5171 (τρυφάω)",
		"def" : "to revel in"
	},
	"G5479" : {
		"def" : "cheerfulness, i.e. calm delight",
		"derivation" : "from G5463 (χαίρω)",
		"lemma" : "χαρά",
		"translit" : "chará",
		"kjv" : "gladness, X greatly, (X be exceeding) joy(-ful, -fully, -fulness, -ous)"
	},
	"G2283" : {
		"def" : "Thamar (i.e. Tamar), an Israelitess",
		"derivation" : "of Hebrew origin (H8559)",
		"translit" : "Thámar",
		"lemma" : "Θάμαρ",
		"kjv" : "Thamar"
	},
	"G5237" : {
		"def" : "to overlook, i.e. not punish",
		"derivation" : "from G5228 (ὑπέρ) and G1492 (εἴδω)",
		"translit" : "hypereídō",
		"lemma" : "ὑπερείδω",
		"kjv" : "wink at"
	},
	"G3520" : {
		"kjv" : "island, isle",
		"translit" : "nēsos",
		"lemma" : "νῆσος",
		"derivation" : "probably from the base of G3491 (ναῦς)",
		"def" : "an island"
	},
	"G1796" : {
		"derivation" : "from G1722 (ἐν) and G5195 (ὑβρίζω)",
		"def" : "to insult",
		"kjv" : "do despite unto",
		"lemma" : "ἐνυβρίζω",
		"translit" : "enybrízō"
	},
	"G4989" : {
		"def" : "Sosipatrus, a Christian",
		"derivation" : "prolongation for G4986 (Σώπατρος)",
		"translit" : "Sōsípatros",
		"lemma" : "Σωσίπατρος",
		"kjv" : "Sosipater"
	},
	"G3875" : {
		"def" : "an intercessor, consoler",
		"lemma" : "παράκλητος",
		"translit" : "paráklētos",
		"kjv" : "advocate, comforter"
	},
	"G5616" : {
		"def" : "as if",
		"derivation" : "from G5613 (ὡς) and G1487 (εἰ)",
		"translit" : "hōseí",
		"lemma" : "ὡσεί",
		"kjv" : "about, as (it had been, it were), like (as)"
	},
	"G5423" : {
		"derivation" : "from G5424 (φρήν) and G539 (ἀπάτη)",
		"def" : "a mind-misleader, i.e. seducer",
		"kjv" : "deceiver",
		"translit" : "phrenapátēs",
		"lemma" : "φρεναπάτης"
	},
	"G4183" : {
		"derivation" : "including the forms from the alternate ",
		"def" : "(singular) much (in any respect) or (plural) many; neuter (singular) as adverbial, largely; neuter (plural) as adverb or noun often, mostly, largely",
		"kjv" : "abundant, + altogether, common, + far (passed, spent), (+ be of a) great (age, deal, -ly, while), long, many, much, oft(-en (-times)), plenteous, sore, straitly",
		"lemma" : "πολύς",
		"translit" : "polýs"
	},
	"G2599" : {
		"kjv" : "burden",
		"translit" : "katabaréō",
		"lemma" : "καταβαρέω",
		"derivation" : "from G2596 (κατά) and G916 (βαρέω)",
		"def" : "to impose upon"
	},
	"G4602" : {
		"derivation" : "apparently from (to hiss, i.e. hist or hush)",
		"def" : "silence",
		"kjv" : "silence",
		"translit" : "sigḗ",
		"lemma" : "σιγή"
	},
	"G3506" : {
		"lemma" : "νεύω",
		"translit" : "neúō",
		"kjv" : "beckon",
		"def" : "to \"nod\", i.e. (by analogy), signal",
		"derivation" : "apparently a primary verb"
	},
	"G1812" : {
		"def" : "six hundred",
		"derivation" : "plural ordinal from G1803 (ἕξ) and G1540 (ἑκατόν)",
		"lemma" : "ἑξακόσιοι",
		"translit" : "hexakósioi",
		"kjv" : "six hundred"
	},
	"G3396" : {
		"kjv" : "mingle",
		"translit" : "mígnymi",
		"lemma" : "μίγνυμι",
		"derivation" : "a primary verb",
		"def" : "to mix"
	},
	"G3849" : {
		"derivation" : "from G3844 (παρά) and the middle voice of G971 (βιάζω)",
		"def" : "to force contrary to (nature), i.e. compel (by entreaty)",
		"kjv" : "constrain",
		"lemma" : "παραβιάζομαι",
		"translit" : "parabiázomai"
	},
	"G1915" : {
		"translit" : "epíblēma",
		"lemma" : "ἐπίβλημα",
		"kjv" : "piece",
		"def" : "a patch",
		"derivation" : "from G1911 (ἐπιβάλλω)"
	},
	"G3596" : {
		"derivation" : "from a compound of G3598 (ὁδός) and G4198 (πορεύομαι)",
		"def" : "to be a wayfarer, i.e. travel",
		"kjv" : "go on a journey",
		"translit" : "hodoiporéō",
		"lemma" : "ὁδοιπορέω"
	},
	"G876" : {
		"def" : "froth, i.e. slaver",
		"derivation" : "apparently a primary word",
		"lemma" : "ἀφρός",
		"translit" : "aphrós",
		"kjv" : "foaming"
	},
	"G3710" : {
		"lemma" : "ὀργίζω",
		"translit" : "orgízō",
		"kjv" : "be angry (wroth)",
		"def" : "to provoke or enrage, i.e. (passively) become exasperated",
		"derivation" : "from G3709 (ὀργή)"
	},
	"G1060" : {
		"kjv" : "marry (a wife)",
		"lemma" : "γαμέω",
		"translit" : "gaméō",
		"derivation" : "from G1062 (γάμος)",
		"def" : "to wed (of either sex)"
	},
	"G4122" : {
		"def" : "to be covetous, i.e. (by implication) to over-reach",
		"derivation" : "from G4123 (πλεονέκτης)",
		"lemma" : "πλεονεκτέω",
		"translit" : "pleonektéō",
		"kjv" : "get an advantage, defraud, make a gain"
	},
	"G5025" : {
		"derivation" : "dative case and accusative case feminine plural respectively of G3778 (οὗτος)",
		"def" : "(to or with or by, etc.) these",
		"kjv" : "hence, that, then, these, those",
		"translit" : "taútais",
		"lemma" : "ταύταις"
	},
	"G1342" : {
		"kjv" : "just, meet, right(-eous)",
		"lemma" : "δίκαιος",
		"translit" : "díkaios",
		"derivation" : "from G1349 (δίκη)",
		"def" : "equitable (in character or act); by implication, innocent, holy (absolutely or relatively)"
	},
	"G717" : {
		"derivation" : "of Hebrew origin (H2022 and H4023)",
		"def" : "Armageddon (or Har-Meggiddon), a symbolic name",
		"kjv" : "Armageddon",
		"lemma" : "Ἀρμαγεδδών",
		"translit" : "Armageddṓn"
	},
	"G1747" : {
		"lemma" : "ἐνέδρα",
		"translit" : "enédra",
		"kjv" : "lay wait",
		"def" : "an ambuscade, i.e. (figuratively) murderous purpose",
		"derivation" : "feminine from G1722 (ἐν) and the base of G1476 (ἑδραῖος)"
	},
	"G368" : {
		"def" : "indisputable",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of a compound of G473 (ἀντί) and G4483 (ῥέω)",
		"lemma" : "ἀναντίῤῥητος",
		"translit" : "anantírrhētos",
		"kjv" : "cannot be spoken against"
	},
	"G2604" : {
		"lemma" : "καταγγελεύς",
		"translit" : "katangeleús",
		"kjv" : "setter forth",
		"def" : "a proclaimer",
		"derivation" : "from G2605 (καταγγέλλω)"
	},
	"G2973" : {
		"translit" : "Kōsám",
		"lemma" : "Κωσάμ",
		"kjv" : "Cosam",
		"def" : "Cosam (i.e. Kosam) an Israelite",
		"derivation" : "of Hebrew origin (compare H7081)"
	},
	"G2792" : {
		"translit" : "kinámōmon",
		"lemma" : "κινάμωμον",
		"kjv" : "cinnamon",
		"def" : "cinnamon",
		"derivation" : "of foreign origin (compare H7076)"
	},
	"G5072" : {
		"translit" : "tetrámēnon",
		"lemma" : "τετράμηνον",
		"kjv" : "four months",
		"def" : "a four months' space",
		"derivation" : "neuter of a compound of G5064 (τέσσαρες) and G3376 (μήν)"
	},
	"G2137" : {
		"kjv" : "(have a) prosper(-ous journey)",
		"lemma" : "εὐοδόω",
		"translit" : "euodóō",
		"derivation" : "from a compound of G2095 (εὖ) and G3598 (ὁδός)",
		"def" : "to help on the road, i.e. (passively) succeed in reaching; figuratively, to succeed in business affairs"
	},
	"G1214" : {
		"kjv" : "Demas",
		"lemma" : "Δημᾶς",
		"translit" : "Dēmâs",
		"derivation" : "probably for G1216 (Δημήτριος)",
		"def" : "Demas, a Christian"
	},
	"G986" : {
		"kjv" : "Blastus",
		"translit" : "Blástos",
		"lemma" : "Βλάστος",
		"derivation" : "perhaps the same as the base of G985 (βλαστάνω)",
		"def" : "Blastus, an officer of Herod Agrippa"
	},
	"G4402" : {
		"kjv" : "Prochorus",
		"lemma" : "Πρόχορος",
		"translit" : "Próchoros",
		"derivation" : "from G4253 (πρό) and G5525 (χορός)",
		"def" : "before the dance; Prochorus, a Christian"
	},
	"G5008" : {
		"translit" : "talithá",
		"lemma" : "ταλιθά",
		"kjv" : "talitha",
		"def" : "the fresh, i.e. young girl; talitha (O maiden)",
		"derivation" : "of Chaldee origin (compare H2924)"
	},
	"G1681" : {
		"derivation" : "of foreign origin",
		"def" : "Elymas, a wizard",
		"kjv" : "Elymas",
		"lemma" : "Ἐλύμας",
		"translit" : "Elýmas"
	},
	"G4176" : {
		"kjv" : "let conversation be, live",
		"translit" : "politeúomai",
		"lemma" : "πολιτεύομαι",
		"derivation" : "middle voice of a derivative of G4177 (πολίτης)",
		"def" : "to behave as a citizen (figuratively)"
	},
	"G1298" : {
		"def" : "to disturb wholly, i.e. agitate (with alarm)",
		"derivation" : "from G1223 (διά) and G5015 (ταράσσω)",
		"lemma" : "διαταράσσω",
		"translit" : "diatarássō",
		"kjv" : "trouble"
	},
	"G5137" : {
		"derivation" : "probably from G5143 (τρέχω) (through the idea of mobility)",
		"def" : "the throat (neck), i.e. (figuratively) life",
		"kjv" : "neck",
		"lemma" : "τράχηλος",
		"translit" : "tráchēlos"
	},
	"G4702" : {
		"derivation" : "from G4703 (σπόρος)",
		"def" : "sown, i.e. (neuter plural) a planted field",
		"kjv" : "corn(-field)",
		"translit" : "spórimos",
		"lemma" : "σπόριμος"
	},
	"G3118" : {
		"lemma" : "μακροχρόνιος",
		"translit" : "makrochrónios",
		"kjv" : "live long",
		"def" : "long-timed, i.e. long-lived",
		"derivation" : "from G3117 (μακρός) and G5550 (χρόνος)"
	},
	"G5098" : {
		"kjv" : "punishment",
		"translit" : "timōría",
		"lemma" : "τιμωρία",
		"derivation" : "from G5097 (τιμωρέω)",
		"def" : "vindication, i.e. (by implication) a penalty"
	},
	"G3788" : {
		"derivation" : "from G3700 (ὀπτάνομαι)",
		"def" : "the eye (literally or figuratively); by implication, vision; figuratively, envy (from the jealous side-glance)",
		"kjv" : "eye, sight",
		"translit" : "ophthalmós",
		"lemma" : "ὀφθαλμός"
	},
	"G2868" : {
		"lemma" : "κονιορτός",
		"translit" : "koniortós",
		"kjv" : "dust",
		"def" : "pulverulence (as blown about)",
		"derivation" : "from the base of G2867 (κονιάω) and ornumi (to \"rouse\")"
	},
	"G4755" : {
		"translit" : "stratēgós",
		"lemma" : "στρατηγός",
		"kjv" : "captain, magistrate",
		"def" : "a general, i.e. (by implication or analogy) a (military) governor (prætor), the chief (præfect) of the (Levitical) temple-wardens",
		"derivation" : "from the base of G4756 (στρατιά) and G71 (ἄγω) or G2233 (ἡγέομαι)"
	},
	"G3036" : {
		"kjv" : "stone, cast stones",
		"translit" : "lithoboléō",
		"lemma" : "λιθοβολέω",
		"derivation" : "from a compound of G3037 (λίθος) and G906 (βάλλω)",
		"def" : "to throw stones, i.e. lapidate"
	},
	"G2003" : {
		"def" : "an injunction or decree; by implication, authoritativeness",
		"derivation" : "from G2004 (ἐπιτάσσω)",
		"lemma" : "ἐπιταγή",
		"translit" : "epitagḗ",
		"kjv" : "authority, commandment"
	},
	"G254" : {
		"derivation" : "of uncertain derivation",
		"def" : "a fetter or manacle",
		"kjv" : "bonds, chain",
		"lemma" : "ἅλυσις",
		"translit" : "hálysis"
	},
	"G2651" : {
		"def" : "according to sole places, i.e. (adverbially) separately",
		"derivation" : "from G2596 (κατά) and accusative case plural feminine of G3441 (μόνος) (with G5561 (χώρα) implied)",
		"translit" : "katamónas",
		"lemma" : "καταμόνας",
		"kjv" : "alone"
	},
	"G5270" : {
		"derivation" : "from G5259 (ὑπό) and G2736 (κάτω)",
		"def" : "down under, i.e. beneath",
		"kjv" : "under",
		"translit" : "hypokátō",
		"lemma" : "ὑποκάτω"
	},
	"G1183" : {
		"derivation" : "from G1181 (δεκάτη)",
		"def" : "to tithe, i.e. to give or take a tenth",
		"kjv" : "pay (receive) tithes",
		"translit" : "dekatóō",
		"lemma" : "δεκατόω"
	},
	"G1125" : {
		"lemma" : "γράφω",
		"translit" : "gráphō",
		"kjv" : "describe, write(-ing, -ten)",
		"def" : "to \"grave\", especially to write; figuratively, to describe",
		"derivation" : "a primary verb"
	},
	"G2413" : {
		"def" : "sacred",
		"derivation" : "of uncertain affinity",
		"lemma" : "ἱερός",
		"translit" : "hierós",
		"kjv" : "holy"
	},
	"G96" : {
		"translit" : "adókimos",
		"lemma" : "ἀδόκιμος",
		"kjv" : "castaway, rejected, reprobate",
		"def" : "unapproved, i.e. rejected; by implication, worthless (literally or morally)",
		"derivation" : "from G1 (Α) (as a negative particle) and G1384 (δόκιμος)"
	},
	"G4763" : {
		"def" : "to be luxurious",
		"derivation" : "from a presumed derivative of G4764 (στρῆνος)",
		"lemma" : "στρηνιάω",
		"translit" : "strēniáō",
		"kjv" : "live deliciously"
	},
	"G4232" : {
		"derivation" : "of Latin origin",
		"def" : "the prætorium or governor's courtroom (sometimes including the whole edifice and camp)",
		"kjv" : "(common, judgment) hall (of judgment), palace, prætorium",
		"lemma" : "πραιτώριον",
		"translit" : "praitṓrion"
	},
	"G942" : {
		"def" : "a brier shrub",
		"derivation" : "of uncertain derivation",
		"translit" : "bátos",
		"lemma" : "βάτος",
		"kjv" : "bramble, bush"
	},
	"G2937" : {
		"derivation" : "from G2936 (κτίζω)",
		"def" : "original formation (properly, the act; by implication, the thing, literally or figuratively)",
		"kjv" : "building, creation, creature, ordinance",
		"translit" : "ktísis",
		"lemma" : "κτίσις"
	},
	"G829" : {
		"def" : "self-pleasing, i.e. arrogant",
		"derivation" : "from G846 (αὐτός) and the base of G2237 (ἡδονή)",
		"lemma" : "αὐθάδης",
		"translit" : "authádēs",
		"kjv" : "self-willed"
	},
	"G3915" : {
		"kjv" : "winter in",
		"translit" : "paracheimasía",
		"lemma" : "παραχειμασία",
		"derivation" : "from G3914 (παραχειμάζω)",
		"def" : "a wintering over"
	},
	"G5052" : {
		"kjv" : "bring fruit to perfection",
		"lemma" : "τελεσφορέω",
		"translit" : "telesphoréō",
		"derivation" : "from a compound of G5056 (τέλος) and G5342 (φέρω)",
		"def" : "to be a bearer to completion (maturity), i.e. to ripen fruit (figuratively)"
	},
	"G958" : {
		"def" : "Benjamin, an Israelite",
		"derivation" : "of Hebrew origin (H1144)",
		"lemma" : "Βενιαμίν",
		"translit" : "Beniamín",
		"kjv" : "Benjamin"
	},
	"G2015" : {
		"kjv" : "appearing, brightness",
		"translit" : "epipháneia",
		"lemma" : "ἐπιφάνεια",
		"derivation" : "from G2016 (ἐπιφανής)",
		"def" : "a manifestation, i.e. (specially) the advent of Christ (past or future)"
	},
	"G741" : {
		"lemma" : "ἀρτύω",
		"translit" : "artýō",
		"kjv" : "season",
		"def" : "to prepare, i.e. spice (with stimulating condiments)",
		"derivation" : "from a presumed derivative of G142 (αἴρω)"
	},
	"G4110" : {
		"derivation" : "from G4111 (πλάσσω)",
		"def" : "something moulded",
		"kjv" : "thing formed",
		"translit" : "plásma",
		"lemma" : "πλάσμα"
	},
	"G4960" : {
		"def" : "to file together (as soldiers in ranks), i.e. (figuratively) to correspond to",
		"derivation" : "from G4862 (σύν) and G4748 (στοιχέω)",
		"translit" : "systoichéō",
		"lemma" : "συστοιχέω",
		"kjv" : "answer to"
	},
	"G1177" : {
		"kjv" : "twelve",
		"lemma" : "δεκαδύο",
		"translit" : "dekadýo",
		"derivation" : "from G1176 (δέκα) and G1417 (δύο)",
		"def" : "two and ten, i.e. twelve"
	},
	"G765" : {
		"kjv" : "ungodly (man)",
		"lemma" : "ἀσεβής",
		"translit" : "asebḗs",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G4576 (σέβομαι)",
		"def" : "irreverent, i.e. (by extension) impious or wicked"
	},
	"G3182" : {
		"derivation" : "a prolonged (transitive) form of G3184 (μεθύω)",
		"def" : "to intoxicate",
		"kjv" : "be drunk(-en)",
		"lemma" : "μεθύσκω",
		"translit" : "methýskō"
	},
	"G3576" : {
		"kjv" : "dull, slothful",
		"lemma" : "νωθρός",
		"translit" : "nōthrós",
		"derivation" : "from a derivative of G3541 (νόθος)",
		"def" : "sluggish, i.e. (literally) lazy, or (figuratively) stupid"
	},
	"G4441" : {
		"kjv" : "ask, demand, enquire, understand",
		"translit" : "pynthánomai",
		"lemma" : "πυνθάνομαι",
		"derivation" : "middle voice prolonged from a primary (which occurs only as an alternate in certain tenses)",
		"def" : "to question, i.e. ascertain by inquiry (as a matter of information merely; and thus differing from G2065 (ἐρωτάω), which properly means a request as a favor; and from G154 (αἰτέω), which is strictly a demand for something due; as well as from G2212 (ζητέω), which implies a search for something hidden; and from G1189 (δέομαι), which involves the idea of urgent need); by implication, to learn (by casual intelligence)"
	},
	"G2376" : {
		"translit" : "thyrís",
		"lemma" : "θυρίς",
		"kjv" : "window",
		"def" : "an aperture, i.e. window",
		"derivation" : "from G2374 (θύρα)"
	},
	"G381" : {
		"lemma" : "ἀνάπτω",
		"translit" : "anáptō",
		"kjv" : "kindle, light",
		"def" : "to enkindle",
		"derivation" : "from G303 (ἀνά) and G681 (ἅπτω)"
	},
	"G3314" : {
		"def" : "midday; by implication, the south",
		"derivation" : "from G3319 (μέσος) and G2250 (ἡμέρα)",
		"lemma" : "μεσημβρία",
		"translit" : "mesēmbría",
		"kjv" : "noon, south"
	},
	"G1372" : {
		"derivation" : "from a variation of G1373 (δίψος)",
		"def" : "to thirst for (literally or figuratively)",
		"kjv" : "(be, be a-)thirst(-y)",
		"translit" : "dipsáō",
		"lemma" : "διψάω"
	},
	"G4445" : {
		"derivation" : "from G4443 (πυρά)",
		"def" : "to be on fire, i.e. (specially), to have a fever",
		"kjv" : "be sick of a fever",
		"translit" : "pyréssō",
		"lemma" : "πυρέσσω"
	},
	"G5123" : {
		"lemma" : "τουτέστι",
		"translit" : "toutésti",
		"kjv" : "that is (to say)",
		"def" : "that is",
		"derivation" : "contraction for G5124 (τοῦτο) and G2076 (ἐστί)"
	},
	"G306" : {
		"def" : "to put off (for oneself)",
		"derivation" : "middle voice from G303 (ἀνά) and G906 (βάλλω)",
		"lemma" : "ἀναβάλλομαι",
		"translit" : "anabállomai",
		"kjv" : "defer"
	},
	"G5340" : {
		"def" : "abstemiously, i.e. stingily",
		"derivation" : "adverb from participle of G5339 (φείδομαι)",
		"translit" : "pheidoménōs",
		"lemma" : "φειδομένως",
		"kjv" : "sparingly"
	},
	"G1383" : {
		"def" : "a testing; by implication, trustworthiness",
		"derivation" : "neuter of a presumed derivative of G1382 (δοκιμή)",
		"translit" : "dokímion",
		"lemma" : "δοκίμιον",
		"kjv" : "trial, trying"
	},
	"G4271" : {
		"def" : "plain before all men, i.e. obvious",
		"derivation" : "from G4253 (πρό) and G1212 (δῆλος)",
		"translit" : "pródēlos",
		"lemma" : "πρόδηλος",
		"kjv" : "evident, manifest (open) beforehand"
	},
	"G1018" : {
		"def" : "to arbitrate, i.e. (genitive case) to govern (figuratively, prevail)",
		"derivation" : "from the same as G1017 (βραβεῖον)",
		"translit" : "brabeúō",
		"lemma" : "βραβεύω",
		"kjv" : "rule"
	},
	"G430" : {
		"translit" : "anéchomai",
		"lemma" : "ἀνέχομαι",
		"kjv" : "bear with, endure, forbear, suffer",
		"def" : "to hold oneself up against, i.e. (figuratively) put up with",
		"derivation" : "middle voice from G303 (ἀνά) and G2192 (ἔχω)"
	},
	"G215" : {
		"derivation" : "from G1 (Α) (as a negative particle) and a derivative of G2980 (λαλέω)",
		"def" : "unspeakable",
		"kjv" : "unutterable, which cannot be uttered",
		"lemma" : "ἀλάλητος",
		"translit" : "alálētos"
	},
	"G64" : {
		"def" : "to hunt, i.e. (figuratively) to entrap",
		"derivation" : "from G61 (ἄγρα)",
		"translit" : "agreúō",
		"lemma" : "ἀγρεύω",
		"kjv" : "catch"
	},
	"G4129" : {
		"def" : "to increase (transitively or intransitively)",
		"derivation" : "from another form of G4128 (πλῆθος)",
		"translit" : "plēthýnō",
		"lemma" : "πληθύνω",
		"kjv" : "abound, multiply"
	},
	"G3080" : {
		"lemma" : "λύσις",
		"translit" : "lýsis",
		"kjv" : "to be loosed",
		"def" : "a loosening, i.e. (specially), divorce",
		"derivation" : "from G3089 (λύω)"
	},
	"G153" : {
		"kjv" : "be ashamed",
		"lemma" : "αἰσχύνομαι",
		"translit" : "aischýnomai",
		"derivation" : "from (disfigurement, i.e. disgrace)",
		"def" : "to feel shame (for oneself)"
	},
	"G479" : {
		"def" : "to invite in return",
		"derivation" : "from G473 (ἀντί) and G2564 (καλέω)",
		"translit" : "antikaléō",
		"lemma" : "ἀντικαλέω",
		"kjv" : "bid again"
	},
	"G4494" : {
		"def" : "to breeze up, i.e. (by analogy) to agitate (into waves)",
		"derivation" : "from a derivative of G4496 (ῥίπτω) (meaning a fan or bellows)",
		"translit" : "rhipízō",
		"lemma" : "ῥιπίζω",
		"kjv" : "toss"
	},
	"G279" : {
		"lemma" : "ἀμετανόητος",
		"translit" : "ametanóētos",
		"kjv" : "impenitent",
		"def" : "unrepentant",
		"derivation" : "from G1 (Α) (as a negative particle) and a presumed derivative of G3340 (μετανοέω)"
	},
	"G910" : {
		"derivation" : "from G907 (βαπτίζω)",
		"def" : "a baptizer, as an epithet of Christ's forerunner",
		"kjv" : "Baptist",
		"lemma" : "Βαπτιστής",
		"translit" : "Baptistḗs"
	},
	"G352" : {
		"def" : "to unbend, i.e. rise; figuratively, be elated",
		"derivation" : "from G303 (ἀνά) (in the sense of reversal) and G2955 (κύπτω)",
		"translit" : "anakýptō",
		"lemma" : "ἀνακύπτω",
		"kjv" : "lift up, look up"
	},
	"G5412" : {
		"kjv" : "lade, by heavy laden",
		"lemma" : "φορτίζω",
		"translit" : "phortízō",
		"derivation" : "from G5414 (φόρτος)",
		"def" : "to load up (properly, as a vessel or animal), i.e. (figuratively) to overburden with ceremony (or spiritual anxiety)"
	},
	"G370" : {
		"def" : "unfit",
		"derivation" : "from G1 (Α) (as a negative particle) and G514 (ἄξιος)",
		"lemma" : "ἀνάξιος",
		"translit" : "anáxios",
		"kjv" : "unworthy"
	},
	"G1084" : {
		"def" : "born",
		"derivation" : "from G1080 (γεννάω)",
		"translit" : "gennētós",
		"lemma" : "γεννητός",
		"kjv" : "they that are born"
	},
	"G5353" : {
		"derivation" : "from G5350 (φθέγγομαι)",
		"def" : "utterance, i.e. a musical note (vocal or instrumental)",
		"kjv" : "sound",
		"lemma" : "φθόγγος",
		"translit" : "phthóngos"
	},
	"G1731" : {
		"kjv" : "do, show (forth)",
		"lemma" : "ἐνδείκνυμι",
		"translit" : "endeíknymi",
		"derivation" : "from G1722 (ἐν) and G1166 (δεικνύω)",
		"def" : "to indicate (by word or act)"
	},
	"G5070" : {
		"translit" : "tetrakischílioi",
		"lemma" : "τετρακισχίλιοι",
		"kjv" : "four thousand",
		"def" : "four times a thousand",
		"derivation" : "from the multiplicative adverb of G5064 (τέσσαρες) and G5507 (χίλιοι)"
	},
	"G489" : {
		"derivation" : "from a compound of G473 (ἀντί) and G3408 (μισθός)",
		"def" : "requital, correspondence",
		"kjv" : "recompense",
		"translit" : "antimisthía",
		"lemma" : "ἀντιμισθία"
	}	
}

export default Strongs;