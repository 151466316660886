const COLORS = {
  text: "black",
  primary: "#4A4453",
  secondary: "#AFA8BA",
  highlight: "#8E78BE",
  lightGrey: "#e3e3e3",
  underlines: [
    "#8E78BE",
    "#78B7BF",
    "#BD79BE",
    "#91BE79",
    "#BE7986",
    "#9C9C9C",
  ],
};

export default COLORS;
