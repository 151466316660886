import React from "react";
// import InterestForm from "./interestForm";
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import COLORS from "./constants";

import Genesis from "./data/bible-eng/genesis_intro";
// import GospelJohn from "./data/bible-eng/gospel-john";
import PassagePro from "./passagePro";
import ContextMenu from "./contextMenu";
import Landing from "./landing";

import Strongs from "./data/strongs";
import { transliterate } from "hebrew-transliteration";
const grc = require("greek-transliteration");
const text = Genesis[0].concat(Genesis[1]).concat(Genesis[2]);
// const text = GospelJohn[0];

// let text = [];
// for (const chapter of Genesis) {
//   text = text.concat(chapter);
// }

class AppPro extends React.Component {
  constructor() {
    super();

    let explainerOpen = true;
    if (localStorage.getItem("explainerOpen") != null) {
      explainerOpen = localStorage.getItem("explainerOpen") === "true";
    }
    this.state = {
      explainerOpen: explainerOpen,
      preferences: {
        underlineIndex: 0,
        see: JSON.parse(localStorage.getItem("seePrefs")) || {},
        switch: JSON.parse(localStorage.getItem("switchPrefs")) || {},
      },
      popup: {
        visible: false,
        englishWord: "",
        transliteratedWord: "",
        lemma: "",
        strongsIndex: "",
        index: "",
      },
    };
  }

  componentDidMount() {
    localStorage.setItem(
      "seePrefs",
      JSON.stringify(this.state.preferences.see)
    );
    localStorage.setItem(
      "switchPrefs",
      JSON.stringify(this.state.preferences.switch)
    );
    localStorage.setItem("explainerOpen", this.state.explainerOpen);
  }
  componentDidUpdate() {
    localStorage.setItem(
      "seePrefs",
      JSON.stringify(this.state.preferences.see)
    );
    localStorage.setItem(
      "switchPrefs",
      JSON.stringify(this.state.preferences.switch)
    );
    localStorage.setItem("explainerOpen", this.state.explainerOpen);
  }

  setSeePreferences = (strongsIndex, enabled) => {
    let prefs = this.state.preferences;
    prefs.see[strongsIndex] = {
      enabled: enabled,
      underlineColor: enabled ? COLORS.underlines[prefs.underlineIndex++] : "",
    };
    if (prefs.underlineIndex === COLORS.underlines.length) {
      prefs.underlineIndex = 0;
    }
    this.setState({
      preferences: prefs,
    });
  };

  setSwitchPreferences = (data, useOriginal, useSymbols) => {
    let prefs = this.state.preferences;
    prefs.switch[data.strongsIndex] = useOriginal
      ? { useSymbols: useSymbols }
      : null;

    this.setState({
      preferences: prefs,
    });
  };

  translit(key) {
    let k = key;
    if (key.split(" ").length === 2) {
      k = key.split(" ")[1];
    }

    if (Strongs[k] != null) {
      const lemma = Strongs[k].lemma;
      if (k.startsWith("H")) {
        return transliterate(lemma, { isSimple: true }).toUpperCase();
      } else if (k.startsWith("G")) {
        return grc.transliterate(lemma).toUpperCase();
      }
    }
  }

  lemma(key) {
    let k = key;
    if (key.split(" ").length === 2) {
      k = key.split(" ")[1];
    }

    if (Strongs[k] != null) {
      return Strongs[k].lemma;
    }
  }

  strongsIndex(index) {
    let i = index;
    if (index.split(" ").length === 2) {
      i = index.split(" ")[1];
    }

    if (Strongs[i] != null) {
      return i;
    }
  }

  showPopup = (e, index, visible) => {
    if (visible) {
      const el = e.target.offsetParent;
      const elParent = el.offsetParent;
      let offsetX =
        el.offsetLeft +
        elParent.offsetLeft +
        e.target.clientWidth / 2 -
        280 / 2;
      if (window.innerWidth < 600) {
        offsetX = (window.innerWidth - 280) / 2;
      }
      let offsetY =
        el.offsetTop + elParent.offsetTop + e.target.clientHeight - 3;
      let wordData = text[index.split(",")[0]][index.split(",")[1]];
      this.setState({
        popup: {
          visible: true,
          x: offsetX,
          y: offsetY,
          englishWord: wordData[0],
          transliteratedWord: this.translit(wordData[1]),
          lemma: this.lemma(wordData[1]),
          strongsIndex: this.strongsIndex(wordData[1]),
          index: index,
        },
      });
    } else {
      this.setState({
        popup: {
          visible: false,
          englishWord: "",
          transliteratedWord: "",
          lemma: "",
          index: "",
        },
      });
    }
  };

  toggleExplainer = (explainerOpen) => {
    this.setState({
      explainerOpen: explainerOpen,
    });
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div
        css={css`
          position: relative;
          min-height: 100vh;
          text-align: center;
        `}
        onClick={(e) => this.showPopup(e, null, false)}
      >
        <Landing
          explainerOpen={this.state.explainerOpen}
          toggleExplainer={this.toggleExplainer}
        />
        <div
          css={css`
            display: ${this.state.explainerOpen ? "none" : "block"};

            margin: 0 auto;
            max-width: 633px;
            min-width: 300px;

            padding: 35px 20px 100px 20px;
            font-family: "Nunito Sans", sans-serif;
          `}
        >
          <PassagePro
            text={text}
            preferences={this.state.preferences}
            title="Genesis"
            showPopup={this.showPopup}
            openPopupIndex={this.state.popup.index}
          />
          <ContextMenu
            popupData={this.state.popup}
            preferences={this.state.preferences}
            setSeePreferences={this.setSeePreferences}
            setSwitchPreferences={this.setSwitchPreferences}
          />
          <button
            css={css`
              margin: 77px auto 77px;
              padding: 15px 33px;

              border: none;
              cursor: pointer;

              font-weight: 700;
              font-size: 14px;

              border-radius: 3px;

              background-color: ${COLORS.primary};
              color: white;
              &:hover {
                background-color: ${COLORS.highlight};
              }
            `}
            onClick={(e) => this.toggleExplainer(true)}
          >
            Get updates
          </button>
        </div>
        <footer
          css={css`
            position: absolute;
            bottom: 0;
            margin: 50px 0 0 0;
            height: 50px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 12px;

            background-color: ${COLORS.primary};
            color: white;
          `}
        >
          <p>©{new Date().getFullYear()} duna // greenville, sc</p>
        </footer>
      </div>
    );
  }
}

export default AppPro;
