import React from "react";
import WordPro from "./wordPro";
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Strongs from "./data/strongs";
import { transliterate } from "hebrew-transliteration";
const grc = require("greek-transliteration");

class Verse extends React.Component {
  isSwitchable(strongsIndex) {
    return (
      strongsIndex != null &&
      strongsIndex !== "added" &&
      strongsIndex.split(" ").length < 3
    );
  }

  isPopupOpen(openIndex, compareIndex) {
    return openIndex === compareIndex;
  }

  shouldUnderline(strongsIndex) {
    if (strongsIndex == null) {
      return false;
    }
    let k = strongsIndex;
    if (strongsIndex.split(" ").length === 2) {
      k = strongsIndex.split(" ")[1];
    }

    return (
      this.props.preferences.see != null &&
      this.props.preferences.see[k] != null &&
      this.props.preferences.see[k].enabled
    );
  }

  getUnderlineColor(strongsIndex) {
    let k = strongsIndex;
    if (strongsIndex.split(" ").length === 2) {
      k = strongsIndex.split(" ")[1];
    }

    return this.props.preferences.see[k].underlineColor;
  }

  translit(k) {
    if (Strongs[k] != null) {
      const lemma = Strongs[k].lemma;
      if (k.startsWith("H")) {
        return transliterate(lemma, { isSimple: true }).toUpperCase();
      } else if (k.startsWith("G")) {
        return grc.transliterate(lemma).toUpperCase();
      }
    }
  }

  getDisplayWord(wordData) {
    let dw = wordData[0];
    let k = wordData[1];
    if (k != null && k.split(" ").length === 2) {
      k = k.split(" ")[1];
    }

    const switches = this.props.preferences.switch;
    if (switches && switches[k] != null) {
      dw = switches[k].useSymbols ? Strongs[k].lemma : this.translit(k);
    }
    return dw;
  }

  render() {
    return (
      <span
        css={css`
          padding: 4px 3px;
          display: contents;
          user-select: none;
        `}
      >
        {this.props.verses.map((wordData, index) => {
          let shouldUnderline = this.shouldUnderline(wordData[1]);
          let underlineColor = null;
          if (shouldUnderline) {
            underlineColor = this.getUnderlineColor(wordData[1]);
          }
          return (
            <WordPro
              key={this.props.index + "," + index}
              index={this.props.index + "," + index}
              switchable={this.isSwitchable(wordData[1])}
              open={this.isPopupOpen(
                this.props.openPopupIndex,
                this.props.index + "," + index
              )}
              underline={shouldUnderline}
              underlineColor={underlineColor}
              displayWord={this.getDisplayWord(wordData)}
              showPopup={this.props.showPopup}
            />
          );
        })}
      </span>
    );
  }
}

export default Verse;
