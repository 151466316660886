// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import COLORS from "./constants";
import { useLongPress } from "use-long-press";

const ContextMenu = (props) => {
  const bind = useLongPress(
    () => {
      props.setSwitchPreferences(props.popupData, true, true);
    },
    { detect: "touch" }
  );

  const isSeeEnabled = (strongsKey) => {
    return (
      props.preferences.see != null &&
      props.preferences.see[strongsKey] != null &&
      props.preferences.see[strongsKey].enabled
    );
  };

  const isEnglishWordUsed = () => {
    return (
      props.preferences.switch != null &&
      props.preferences.switch[props.popupData.strongsIndex] == null
    );
  };

  return (
    <div
      hidden={!props.popupData.visible}
      css={css`
        display: ${props.popupData.visible ? "inline" : "none"};
        position: absolute;
        top: ${props.popupData.y}px;
        left: ${props.popupData.x}px;
        z-index: 99999998;
        padding: 15px;
        width: 250px;
        text-align: left;

        background-color: white;
        border-top: 2px solid ${COLORS.primary};
        border-radius: 7px;
        box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.2);
      `}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: auto auto;
          row-gap: 3px;
        `}
      >
        <div
          css={css`
            grid-column: 1;
            grid-row: 1;
            justify-self: start;
          `}
        >
          <div
            css={css`
              font-size: 10px;
              font-weight: bold;
              color: #aeaeae;
            `}
          >
            English
          </div>
          <div css={css``}>{props.popupData.englishWord}</div>
        </div>
        <div
          css={css`
            grid-column: 2;
            grid-row: 1;
            justify-self: end;
            align-self: center;
          `}
        >
          <button
            {...(isEnglishWordUsed() ? { hidden: "hidden" } : {})}
            css={css`
              user-select: none;
              border: none;
              background-color: ${COLORS.primary};
              cursor: pointer;
              color: white;
              border-radius: 3px;
              font-size: 10px;
              font-weight: 600;
              padding: 7px 12px;
              &: hover {
                background-color: ${COLORS.highlight};
              }
            `}
            onClick={(e) => {
              props.setSwitchPreferences(props.popupData, false);
            }}
          >
            use English
          </button>
        </div>

        <div
          css={css`
            grid-column: 1 / span 2;
            grid-row: 2;
          `}
        >
          <hr
            css={css`
              border-top-width: 0;
            `}
            color={COLORS.lightGrey}
          ></hr>
        </div>

        <div
          css={css`
            grid-column: 1;
            grid-row: 3;
            justify-self: start;
          `}
        >
          <div
            css={css`
              font-size: 10px;
              font-weight: bold;
              color: #aeaeae;
            `}
          >
            Hebrew
          </div>
          <div>
            <span>{props.popupData.lemma}</span>
            <span> ({props.popupData.transliteratedWord})</span>
          </div>
        </div>
        <div
          css={css`
            grid-column: 2;
            grid-row: 3;
            justify-self: end;
            align-self: center;
          `}
        >
          <button
            {...(isEnglishWordUsed() ? {} : { hidden: "hidden" })}
            css={css`
              user-select: none;
              border: none;
              background-color: ${COLORS.primary};
              cursor: pointer;
              color: white;
              border-radius: 3px;
              font-size: 10px;
              font-weight: 600;
              padding: 7px 12px;
              &: hover {
                background-color: ${COLORS.highlight};
              }
            `}
            {...bind}
            onClick={(e) => {
              props.setSwitchPreferences(props.popupData, true, e.altKey);
            }}
          >
            use Hebrew
          </button>
        </div>

        <div
          css={css`
            grid-column: 1 / span 2;
            grid-row: 4;
          `}
        >
          <hr
            css={css`
              border-top-width: 0;
            `}
            color={COLORS.lightGrey}
          ></hr>
        </div>

        <div
          css={css`
            grid-column: 1 / span 2;
            grid-row: 5;
            justify-self: end;
            align-self: center;

            font-size: 12px;
            color: ${COLORS.primary} !important;
          `}
        >
          <span>
            {isSeeEnabled(props.popupData.strongsIndex)
              ? "underlined everywhere - "
              : ""}
          </span>
          <button
            onClick={(e) => {
              props.setSeePreferences(
                props.popupData.strongsIndex,
                !isSeeEnabled(props.popupData.strongsIndex)
              );
            }}
            css={css`
              font-size: 12px;
              color: ${COLORS.primary} !important;
              background: none !important;
              border: none;
              padding: 0 !important;
              text-decoration: underline;
              cursor: pointer;
            `}
          >
            {isSeeEnabled(props.popupData.strongsIndex)
              ? "hide"
              : "underline everywhere"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContextMenu;
