import React from "react";
import Verse from "./verse";
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

class PassagePro extends React.Component {
  render() {
    return (
      <div
        css={css`
          text-align: center;
          position: relative;
          padding: 10px;
          @media screen and (min-width: 480px) {
            padding: 25px;
          }

          font-family: "Lora", serif;
          font-size: 16px;
        `}
      >
        <div
          css={css`
            font-size: 30px;
            margin-top: 33px;
            margin-bottom: 63px;
            text-align: center;
          `}
        >
          {this.props.title}
        </div>
        <div
          css={css`
            text-align: left;
          `}
        >
          {this.props.text.map((verseData, index) => {
            return verseData === "p" ? (
              <div
                key={index}
                css={css`
                  margin-top: 21px;
                `}
              ></div>
            ) : (
              <Verse
                key={index}
                index={index}
                verses={verseData}
                wordLibrary={this.props.wordLibrary}
                showPopup={this.props.showPopup}
                openPopupIndex={this.props.openPopupIndex}
                preferences={this.props.preferences}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default PassagePro;
